export var jsonLinhasDeTransmissoBaseExistente22 = {
  type: 'FeatureCollection',
  name: 'Linhas_de_Transmisso__Base_Existente_22',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Delfina - Juazeiro II C1',
        Concession: 'EOL DELFINA A - ENEL GREEN POWER DELFINA A EÓLICA S.A.',
        Tensao: 230.0,
        Extensao: 90.86573,
        Ano_Opera: 2017.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.8131,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.476891213999977, -9.48206283199994],
            [-40.477556810999943, -9.482365448999929],
            [-40.481328256999973, -9.483614665999937],
            [-40.501772486999982, -9.48692776799993],
            [-40.513800661999937, -9.488163897999925],
            [-40.561689693999938, -9.501555503999953],
            [-40.58754253799998, -9.548748305999936],
            [-40.59341802199998, -9.568395515999953],
            [-40.592747965999934, -9.570936422999978],
            [-40.606834701999958, -9.617752547999942],
            [-40.607572810999955, -9.669319393999956],
            [-40.637043387999938, -9.850789224999971],
            [-40.634194169999944, -9.869831873999942],
            [-40.63521832999993, -9.890862640999956],
            [-40.672804171999985, -9.918110443999979],
            [-40.706649228999936, -9.935107131999928],
            [-40.870961242999954, -9.977998700999933],
            [-40.89939163799994, -9.981056308999939],
            [-40.92897428699996, -10.006758452999975],
            [-40.931200516999979, -10.007663805999925],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Ribeiro Gonçalves - São João do Piauí C1',
        Concession: 'TAESA - TRANSMISSORA ALIANÇA DE ENERGIA ELÉTRICA S A',
        Tensao: 500.0,
        Extensao: 343.46775,
        Ano_Opera: 2006.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 3.11098,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-43.213321391609071, -8.175465373718573],
            [-43.204439666999974, -8.175552130999961],
            [-43.185845579999977, -8.175618848999932],
            [-43.157814128999973, -8.175885254999969],
            [-43.139123462999976, -8.175992330999975],
            [-43.117208626999968, -8.175980433999939],
            [-43.104407125999955, -8.179799468999931],
            [-43.090653841999938, -8.183856449999951],
            [-43.067333452999947, -8.190684915999952],
            [-43.050332000999958, -8.196900296999956],
            [-43.039900967999984, -8.200528504999966],
            [-43.029462301999956, -8.204256907999934],
            [-43.018750331999968, -8.20805261199996],
            [-43.014493533999939, -8.209507335999945],
            [-43.009793890999958, -8.210179049999965],
            [-43.005345261999935, -8.210623177999935],
            [-43.000195909999945, -8.211254922999956],
            [-42.995896879999975, -8.211835349999944],
            [-42.988626735999958, -8.212791605999939],
            [-42.98246643899995, -8.213708963999977],
            [-42.977728271999979, -8.214306121999925],
            [-42.964423207999971, -8.216093372999978],
            [-42.955173073999958, -8.217359880999936],
            [-42.932395889999952, -8.220309791999966],
            [-42.918323591999979, -8.222048818999951],
            [-42.905198650999978, -8.223867085999927],
            [-42.89720819799993, -8.224426410999968],
            [-42.869558077999955, -8.225671442999953],
            [-42.841353521999963, -8.227126442999975],
            [-42.808238475999985, -8.228730690999953],
            [-42.78016953499997, -8.230297359999952],
            [-42.733852782999975, -8.232426602999965],
            [-42.677210594999963, -8.235312293999925],
            [-42.663004598999976, -8.236012693999953],
            [-42.60439908799998, -8.242307908999976],
            [-42.595440416999963, -8.243283487999975],
            [-42.571609846999934, -8.24588635799995],
            [-42.562306422999939, -8.24686457699994],
            [-42.557452321999961, -8.24795912899998],
            [-42.506841186999964, -8.259202081999945],
            [-42.494121525999958, -8.262180597999929],
            [-42.437761415999944, -8.274585099999967],
            [-42.382175777999976, -8.286860881999928],
            [-42.352700562999985, -8.293505406999941],
            [-42.308382241999936, -8.303388672999972],
            [-42.27026327599998, -8.311954732999936],
            [-42.250278599999945, -8.325061047999952],
            [-42.248434901999985, -8.329116289999945],
            [-42.243070656999976, -8.340607697999928],
            [-42.239950504999968, -8.347341369999981],
            [-42.236935176999964, -8.354010202999973],
            [-42.232083116999945, -8.357389417999968],
            [-42.230431098999929, -8.357985405999955],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Bongi - Joairam, C3',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 6.21197,
        Ano_Opera: 2006.0,
        created_us: null,
        created_da: null,
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-04-26',
        Shape_Leng: 0.05597,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.980687340999964, -8.080939355999931],
            [-34.979778385999964, -8.080625266999959],
            [-34.977294228999938, -8.07880259999996],
            [-34.97377987699997, -8.077704866999966],
            [-34.961575509999932, -8.073962775999973],
            [-34.947372506999955, -8.069710679999957],
            [-34.943596301999946, -8.068111681999937],
            [-34.939320410999983, -8.066341362999935],
            [-34.931011332999958, -8.06282213999998],
            [-34.929512867999961, -8.063332533999926],
            [-34.928273948999959, -8.063773816999969],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Bongi - Joairam, C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 6.20553,
        Ano_Opera: 2006.0,
        created_us: null,
        created_da: null,
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-04-26',
        Shape_Leng: 0.0559,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.980654027999947, -8.081063087999951],
            [-34.979745073999936, -8.08072044599993],
            [-34.977251398999954, -8.078888260999975],
            [-34.973724387999937, -8.077793321999934],
            [-34.961532678999959, -8.074053195999966],
            [-34.94732253799998, -8.069817754999974],
            [-34.943524918999969, -8.068218757999944],
            [-34.939284718999943, -8.066462714999943],
            [-34.931005978999963, -8.062922076999939],
            [-34.92955748199995, -8.063421763999941],
            [-34.928327485999944, -8.063898738999967],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Bongi - Joairam, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 6.19207,
        Ano_Opera: 2006.0,
        created_us: null,
        created_da: null,
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-04-26',
        Shape_Leng: 0.05579,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.980615956999941, -8.08117254299998],
            [-34.979545198999972, -8.080825141999981],
            [-34.977028428999972, -8.079033092999964],
            [-34.960361984999963, -8.073924704999968],
            [-34.947251154999947, -8.070096151999962],
            [-34.942882463999979, -8.068225895999944],
            [-34.941197805999934, -8.067483503999938],
            [-34.937956979999967, -8.066262840999968],
            [-34.932581776999939, -8.063864343999967],
            [-34.930997055999967, -8.063150505999943],
            [-34.929506918999948, -8.063680530999932],
            [-34.928345331999935, -8.064059351999958],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Jaguarari - Juazeiro II, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 87.92711,
        Ano_Opera: 2012.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.7909,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-39.858416620999947, -9.866118751999977],
            [-39.858369031999985, -9.867298964999975],
            [-39.857955005999941, -9.868088945999943],
            [-39.858024009999951, -9.875372475999939],
            [-39.858059701999935, -9.883324634999951],
            [-39.858073978999983, -9.892004908999979],
            [-39.861000715999978, -9.895031582999934],
            [-39.865455066999971, -9.89658775099997],
            [-39.873906911999939, -9.89561692999996],
            [-39.882487248999951, -9.894638971999939],
            [-39.891338843999961, -9.893625321999934],
            [-39.900090500999966, -9.892618809999931],
            [-39.908806466999977, -9.891605158999937],
            [-39.917222620999951, -9.890734275999932],
            [-39.919642531999955, -9.878527641999938],
            [-39.93428335599998, -9.869561831999931],
            [-39.951800947999971, -9.85883998099996],
            [-39.95874659499998, -9.854621196999972],
            [-39.966326040999945, -9.849961839999935],
            [-39.977927429999966, -9.842914248999932],
            [-39.989213212999971, -9.836032846999956],
            [-40.027646266999966, -9.812511874999927],
            [-40.038603684999941, -9.805816071999971],
            [-40.060361476999958, -9.792545817999951],
            [-40.083182886999964, -9.77863310999993],
            [-40.097866540999973, -9.769667299999981],
            [-40.113049881999984, -9.760394540999926],
            [-40.127483691999942, -9.751578637999955],
            [-40.138491078999948, -9.744818588999976],
            [-40.153431714999954, -9.735731427999951],
            [-40.180172096999968, -9.719320284999981],
            [-40.195205531999932, -9.710104632999958],
            [-40.206876787999931, -9.702973387999975],
            [-40.221646102999955, -9.693964748999974],
            [-40.235651609999934, -9.685405826999954],
            [-40.254832444999977, -9.673591802999965],
            [-40.265597126999978, -9.667003075999958],
            [-40.27711133899993, -9.659964629999934],
            [-40.288582719999965, -9.65297615299994],
            [-40.300104070999964, -9.645930568999972],
            [-40.311703942999941, -9.638785047999932],
            [-40.323232430999951, -9.631753740999955],
            [-40.334011389999944, -9.625172150999958],
            [-40.345425663999947, -9.618140843999981],
            [-40.356996982999931, -9.611059567999973],
            [-40.368561163999971, -9.603992568999956],
            [-40.371495038999967, -9.602193696999962],
            [-40.385336363999954, -9.585632647999944],
            [-40.393302798999969, -9.576081490999968],
            [-40.399070612999935, -9.569164397999941],
            [-40.407750885999974, -9.558756635999941],
            [-40.416245561999972, -9.548584439999956],
            [-40.421863469999948, -9.541810114999976],
            [-40.43042952899998, -9.531530842999928],
            [-40.436147373999972, -9.524727963999965],
            [-40.44195801799998, -9.517760901999964],
            [-40.447547371999974, -9.511043683999958],
            [-40.453136725999968, -9.50429791199997],
            [-40.458911677999936, -9.497416510999926],
            [-40.464736597999945, -9.490463725999973],
            [-40.470504411999968, -9.483632292999971],
            [-40.472474604999945, -9.481155273999946],
            [-40.474230646999956, -9.480541372999937],
            [-40.475598251999941, -9.481204222999963],
            [-40.47631505499993, -9.481490777999966],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Mirueira II - Pau Ferro, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 23.60644,
        Ano_Opera: 2016.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.2082,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.020079218999967, -7.859007788999975],
            [-35.019671786999936, -7.858075806999977],
            [-35.018597409999984, -7.855352936999964],
            [-35.014950401999954, -7.854343665999977],
            [-34.984992281999951, -7.845882681999967],
            [-34.981137554999975, -7.847510233999969],
            [-34.97418001099993, -7.850365586999942],
            [-34.962520651999967, -7.855210169999964],
            [-34.960539167999968, -7.859792503999927],
            [-34.95896158499994, -7.862954807999927],
            [-34.958861647999981, -7.864396760999966],
            [-34.958400838999978, -7.869230064999954],
            [-34.956613306999941, -7.875852670999961],
            [-34.955142799999976, -7.880785292999974],
            [-34.952066156999933, -7.891528559999927],
            [-34.950959707999971, -7.895654544999957],
            [-34.948439857999972, -7.904256296999961],
            [-34.946798029999968, -7.910352475999957],
            [-34.946012807999978, -7.917619349999939],
            [-34.945249000999979, -7.925464432999945],
            [-34.94443522499995, -7.933516528999974],
            [-34.941851130999964, -7.936471818999962],
            [-34.933320762999983, -7.94622998899996],
            [-34.93135056899996, -7.94846430299998],
            [-34.92410511099996, -7.949784903999955],
            [-34.919884201999935, -7.952443427999981],
            [-34.913190522999969, -7.953504000999942],
            [-34.905643219999945, -7.954752633999931],
            [-34.902777388999937, -7.955226993999929],
            [-34.899158023999973, -7.954630178999935],
            [-34.895669518999966, -7.954018082999937],
            [-34.89300067399995, -7.953581748999966],
            [-34.891703073999963, -7.954362689999925],
            [-34.891639789999942, -7.954257982999934],
            [-34.890542515999982, -7.954837405999967],
            [-34.891199413999971, -7.95609987399996],
            [-34.891603127999929, -7.956060978999972],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Recife II - Joairam C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 7.45879,
        Ano_Opera: 2006.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.0665,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.034415838999962, -8.113118535999945],
            [-35.034233636999943, -8.112534718999939],
            [-35.034192560999941, -8.10876748499993],
            [-35.033635656999934, -8.106791655999928],
            [-35.031323621999945, -8.10489673099994],
            [-35.027309118999938, -8.100920722999945],
            [-35.022421101999953, -8.096037291999949],
            [-35.017752823999956, -8.091326991999949],
            [-35.014397783999982, -8.09027526999995],
            [-35.010381253999981, -8.089033191999931],
            [-35.000182883999969, -8.085849472999939],
            [-34.994177124999965, -8.084022046999962],
            [-34.98749678799993, -8.082113718999949],
            [-34.983570676999932, -8.080943023999964],
            [-34.981989436999982, -8.081726107999941],
            [-34.981607621999956, -8.081628308999939],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Recife II - Joairam C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 7.43395,
        Ano_Opera: 2006.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.06624,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.033847395999942, -8.113232339999968],
            [-35.033694502999936, -8.112744071999941],
            [-35.033261384999946, -8.108731784999975],
            [-35.033033029999956, -8.106982432999928],
            [-35.031859820999955, -8.105071294999959],
            [-35.029104316999963, -8.102335228999948],
            [-35.027108225999939, -8.100341023999931],
            [-35.022382686999947, -8.095693867999955],
            [-35.017852761999961, -8.091117599999961],
            [-35.014788015999954, -8.090175332999934],
            [-35.010566851999954, -8.088871387999973],
            [-34.999906866999936, -8.085530624999933],
            [-34.994334168999956, -8.083841207999967],
            [-34.987578878999955, -8.081881721999935],
            [-34.983420770999942, -8.080807394999965],
            [-34.98198027799998, -8.081561215999955],
            [-34.981646882999939, -8.08149267999994],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Recife II - Joairam C3',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 7.43847,
        Ano_Opera: 2006.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.06628,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.033971378999979, -8.113180384999964],
            [-35.033804009999983, -8.112719617999971],
            [-35.033359129999951, -8.108714402999965],
            [-35.03311400299998, -8.10694770799995],
            [-35.031953303999956, -8.10502747299995],
            [-35.02717927499998, -8.100303570999927],
            [-35.022456989999966, -8.095644304999951],
            [-35.017886073999932, -8.091036697999925],
            [-35.01484512299993, -8.090065877999962],
            [-35.010652512999968, -8.08878096899997],
            [-34.999925901999973, -8.085444964999965],
            [-34.99437699899994, -8.083760305999931],
            [-34.987611001999937, -8.081810337999968],
            [-34.983392217999949, -8.080718164999951],
            [-34.981950119999965, -8.081411732999925],
            [-34.981668297999931, -8.081364188999942],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV São João do Piauí - Picos C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 170.14117,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.51638,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-42.228301706999957, -8.355651556999931],
            [-42.227960253999981, -8.354935338999951],
            [-42.226794317999975, -8.351828952999938],
            [-42.219123269999955, -8.325968809999949],
            [-42.187631102999944, -8.292918096999927],
            [-42.15613893699998, -8.259641334999969],
            [-42.154889719999971, -8.257963814999925],
            [-42.146978011999977, -8.247065882999948],
            [-42.138602308999964, -8.235466010999971],
            [-42.130738190999978, -8.224651360999928],
            [-42.118733809999981, -8.208078414999932],
            [-42.111024355999973, -8.197394634999966],
            [-42.09578390799993, -8.176407788999938],
            [-42.07852091899997, -8.152601280999932],
            [-42.057843402999936, -8.104571860999954],
            [-42.055785168999932, -8.099765349999927],
            [-42.055277777999947, -8.095555555999965],
            [-42.054298005999954, -8.08577411899995],
            [-42.053976778999981, -8.081919391999975],
            [-42.051861110999937, -8.079472221999936],
            [-42.04781397499994, -8.07346040799996],
            [-42.034715041999959, -8.053413448999947],
            [-42.011415359999944, -8.017845265999938],
            [-41.976751371999967, -7.964811838999935],
            [-41.972722221999959, -7.958722221999949],
            [-41.967757008999968, -7.951039518999949],
            [-41.949406605999968, -7.923028503999944],
            [-41.946237163999967, -7.915918674999944],
            [-41.942401472999961, -7.907409721999954],
            [-41.937328461999982, -7.896016862999943],
            [-41.933216752999954, -7.886879731999954],
            [-41.929885507999984, -7.879436777999956],
            [-41.926306797999985, -7.871470342999942],
            [-41.921081501999936, -7.859744358999933],
            [-41.917835916999934, -7.852529832999949],
            [-41.912534477999941, -7.840718188999972],
            [-41.909450695999965, -7.833817751999959],
            [-41.907805555999971, -7.832555555999932],
            [-41.814957540999956, -7.757817766999949],
            [-41.808777777999978, -7.752833332999955],
            [-41.804638888999932, -7.749],
            [-41.802111110999931, -7.747444443999939],
            [-41.724507089999975, -7.684911081999928],
            [-41.72316666699993, -7.681166666999957],
            [-41.721002143999954, -7.675193363999938],
            [-41.720807027999967, -7.673168441999962],
            [-41.719664886999965, -7.670896056999936],
            [-41.709266641999932, -7.651800881999975],
            [-41.702782610999975, -7.639951166999936],
            [-41.689291066999942, -7.61516907999993],
            [-41.677619810999943, -7.593706341999962],
            [-41.666733776999934, -7.573742663999951],
            [-41.657311110999956, -7.561607412999933],
            [-41.649694443999977, -7.552027777999967],
            [-41.64644444399994, -7.547805555999958],
            [-41.604879687999983, -7.494459022999933],
            [-41.603416666999976, -7.490666666999971],
            [-41.57985965499995, -7.428631234999955],
            [-41.575172116999966, -7.416543572999956],
            [-41.573388888999943, -7.412555555999973],
            [-41.571757590999937, -7.408274945999949],
            [-41.564440747999981, -7.390631241999927],
            [-41.554472221999958, -7.366444443999967],
            [-41.49812516999998, -7.23011280299994],
            [-41.494472221999956, -7.221388888999968],
            [-41.49258333299997, -7.216694443999927],
            [-41.47816149199997, -7.181893025999955],
            [-41.469773891999978, -7.168889270999955],
            [-41.462564124999972, -7.157491652999965],
            [-41.452891615999931, -7.142382075999933],
            [-41.445448661999933, -7.130924970999956],
            [-41.442305555999951, -7.128694443999962],
            [-41.435583332999954, -7.124333332999981],
            [-41.420131196999932, -7.114061730999936],
            [-41.415509152999959, -7.111079407999966],
            [-41.414988906999952, -7.110977177999928],
            [-41.414777409999942, -7.11082804299997],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Milagres - Quixadá C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 277.37694,
        Ano_Opera: 2003.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 2.42894,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.93843521499997, -7.306107293999958],
            [-38.93995155999994, -7.304729768999948],
            [-38.93963888899998, -7.299333332999936],
            [-38.939047364999965, -7.291749809999942],
            [-38.93763888899997, -7.28886111099996],
            [-38.858179002999975, -7.135845144999962],
            [-38.855716260999941, -7.131145709999942],
            [-38.854861110999934, -7.126333332999934],
            [-38.836073809999959, -7.022570903999963],
            [-38.829149578999932, -6.983857072999967],
            [-38.827543442999968, -6.970258453999975],
            [-38.82615145799997, -6.958610991999933],
            [-38.825021213999946, -6.949188325999955],
            [-38.824057531999983, -6.940919698999949],
            [-38.823070055999949, -6.932436920999976],
            [-38.821654276999936, -6.920694280999953],
            [-38.820428853999942, -6.910545879999972],
            [-38.819453274999944, -6.902289149999945],
            [-38.817597294999985, -6.886786961999974],
            [-38.816086337999934, -6.874009255999965],
            [-38.815098860999967, -6.865776320999942],
            [-38.814515892999964, -6.859078137999973],
            [-38.814916666999977, -6.856222221999928],
            [-38.836561599999982, -6.710385619999954],
            [-38.842831478999983, -6.666698715999928],
            [-38.852361220999967, -6.600525904999927],
            [-38.850850262999984, -6.582346822999966],
            [-38.851916666999955, -6.577361110999959],
            [-38.859797035999975, -6.537065679999955],
            [-38.859777777999966, -6.533944443999928],
            [-38.859833332999983, -6.531277777999946],
            [-38.859777777999966, -6.528277777999961],
            [-38.85975, -6.517555555999934],
            [-38.85975, -6.498305555999934],
            [-38.861444443999972, -6.49352777799993],
            [-38.861527777999981, -6.488333332999957],
            [-38.861805555999979, -6.463694443999941],
            [-38.86194444399996, -6.45894444399994],
            [-38.862378751999984, -6.424255433999974],
            [-38.864444443999957, -6.418722221999928],
            [-38.891241613999966, -6.338939859999925],
            [-38.898867785999983, -6.316346876999944],
            [-38.900497716999951, -6.31175451799993],
            [-38.905910990999985, -6.293515948999925],
            [-38.907184279030005, -6.289108792304777],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Paulo Afonso IV - Luiz Gonzaga C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 37.83886,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.33425,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.304223393999962, -9.144966616999966],
            [-38.301583752999932, -9.146440134999978],
            [-38.299459955999964, -9.149692117999962],
            [-38.292164285999945, -9.154113264999978],
            [-38.285273365999956, -9.158310634999964],
            [-38.278801232999967, -9.163992787999973],
            [-38.271719956999959, -9.170322153999962],
            [-38.265390589999981, -9.175966234999976],
            [-38.261145631999966, -9.180030354999928],
            [-38.255749013999946, -9.185893346999933],
            [-38.251684894999983, -9.190252518999955],
            [-38.245127099999934, -9.19730524199997],
            [-38.243618521999963, -9.201102860999981],
            [-38.240203995999934, -9.209371487999931],
            [-38.234267239999951, -9.223850508999931],
            [-38.223559665999971, -9.249829463999959],
            [-38.218315333999954, -9.262621445999969],
            [-38.213042447999953, -9.275470535999943],
            [-38.211376824999945, -9.279077798999936],
            [-38.207322223999938, -9.287586750999935],
            [-38.206503688999931, -9.291365334999966],
            [-38.20085008999996, -9.30510910199996],
            [-38.195482025999979, -9.31816758399998],
            [-38.186211645999947, -9.340734391999945],
            [-38.179501565999942, -9.357162190999929],
            [-38.168472763999944, -9.383436198999959],
            [-38.163761430999955, -9.395036071999925],
            [-38.168103947999953, -9.402923984999973],
            [-38.172446444999935, -9.404384106999942],
            [-38.184272384999929, -9.407920852999951],
            [-38.198477766999929, -9.412382342999933],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Paulo Afonso IV - Xingó C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 54.47006,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.4891,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-37.796469211999977, -9.633937793999962],
            [-37.79664807499995, -9.634765577999929],
            [-37.797530324999968, -9.636646459999952],
            [-37.79974137399995, -9.640821306999953],
            [-37.805154647999984, -9.645937147999973],
            [-37.806963037999935, -9.645758687999944],
            [-37.820799603999944, -9.642724874999942],
            [-37.835468980999963, -9.635836335999954],
            [-37.837564422999947, -9.635698472999934],
            [-37.84932934099993, -9.634658502999969],
            [-37.858073859999934, -9.63395656199998],
            [-37.872303036999938, -9.624533895999946],
            [-37.888400090999937, -9.613969088999966],
            [-37.903009981999958, -9.604248990999963],
            [-37.920046921999983, -9.596932147999951],
            [-37.93297929299996, -9.591340414999934],
            [-37.944067580999956, -9.58651010899996],
            [-37.961889743999961, -9.58341680999996],
            [-37.967255428999977, -9.58248881999998],
            [-37.990655047999951, -9.578379490999964],
            [-37.999601821999931, -9.57681856499994],
            [-38.015077835999932, -9.565796900999942],
            [-38.026175641999941, -9.54711813199998],
            [-38.033299747999934, -9.535239862999958],
            [-38.036283840999943, -9.532367081999951],
            [-38.049289725999984, -9.519721017999927],
            [-38.066793041999972, -9.502674559999946],
            [-38.083753839999929, -9.486070680999944],
            [-38.097016954999958, -9.473121653999954],
            [-38.110479945999941, -9.45995847599994],
            [-38.12467105099995, -9.451192540999955],
            [-38.136463659999947, -9.443882836999933],
            [-38.154295340999965, -9.432818342999951],
            [-38.173954446999971, -9.420540324999934],
            [-38.188345427999934, -9.411574515999973],
            [-38.193713491999972, -9.413144959999954],
            [-38.198171271999968, -9.413768214999948],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Luiz Gonzaga - Milagres C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 236.99149,
        Ano_Opera: 2002.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 2.08529,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.303940096999952, -9.144350738999947],
            [-38.300745669999969, -9.146016361999955],
            [-38.297727680999969, -9.147567977999927],
            [-38.285283099999958, -9.14778212899995],
            [-38.281916666999962, -9.146],
            [-38.281310792999932, -9.139818433999949],
            [-38.278846657999964, -9.133505362999927],
            [-38.277194443999974, -9.129527777999954],
            [-38.275166666999951, -9.124527777999958],
            [-38.271922425999946, -9.116063913999938],
            [-38.268162877999941, -9.106486583999981],
            [-38.264450918999955, -9.09713530199997],
            [-38.260857931999965, -9.08810524699993],
            [-38.257241151999949, -9.079003808999971],
            [-38.253707651999946, -9.070223597999927],
            [-38.248829756999953, -9.058016962999943],
            [-38.245653176999951, -9.049938692999945],
            [-38.242512287999944, -9.041943703999948],
            [-38.239454680999984, -9.034150968999938],
            [-38.234683860999951, -9.022039512999982],
            [-38.23338705499998, -9.018815342999972],
            [-38.22435700099993, -9.010760866999931],
            [-38.213958755999954, -9.001528558999951],
            [-38.210163515999966, -8.998066442999971],
            [-38.20268011099995, -8.991427746999932],
            [-38.199515427999984, -8.98866757199994],
            [-38.194934964999959, -8.975473460999979],
            [-38.189581177999969, -8.959162254999967],
            [-38.200607599999955, -8.943376910999973],
            [-38.206201144999966, -8.935643807999952],
            [-38.210876785999972, -8.928719575999935],
            [-38.216159189999985, -8.920891149999932],
            [-38.228590061999967, -8.902478734999931],
            [-38.237118049999935, -8.890985938999961],
            [-38.250347852999937, -8.87326847199995],
            [-38.267064755999968, -8.849623767999958],
            [-38.275297691999981, -8.838000100999977],
            [-38.286493055999983, -8.822057711999946],
            [-38.292834318999951, -8.813099041999976],
            [-38.300722231999941, -8.801986958999976],
            [-38.379722221999941, -8.690444443999979],
            [-38.390570679999939, -8.675030816999936],
            [-38.396150515999977, -8.667095313999937],
            [-38.401504302999967, -8.659695190999969],
            [-38.406120457999975, -8.653032699999926],
            [-38.411890650999965, -8.644942531999959],
            [-38.414246316999936, -8.641587491999928],
            [-38.419742871999972, -8.633711475999974],
            [-38.425501167999982, -8.625680794999937],
            [-38.431675868999946, -8.616995762999977],
            [-38.443894400999966, -8.59960190299995],
            [-38.455292018999955, -8.583433464999928],
            [-38.460562524999943, -8.57603334199996],
            [-38.465844928999957, -8.568538038999975],
            [-38.480930711999974, -8.547218068999939],
            [-38.486439163999933, -8.539496717999953],
            [-38.491590696999936, -8.532203669999944],
            [-38.494305555999972, -8.528333332999978],
            [-38.495361110999966, -8.526083332999974],
            [-38.498455441999965, -8.518985763999979],
            [-38.505724694999969, -8.501056524999967],
            [-38.509484243999964, -8.491836113999966],
            [-38.522119181999983, -8.461271936999935],
            [-38.525843037999948, -8.452682082999956],
            [-38.531898765999983, -8.438333932999967],
            [-38.545842407999942, -8.405556857999954],
            [-38.557870582999954, -8.377086606999967],
            [-38.563664570999947, -8.363369013999943],
            [-38.599083332999953, -8.276666666999972],
            [-38.601854919999937, -8.269642044999955],
            [-38.609600065999985, -8.250927583999953],
            [-38.612800440999933, -8.24337279599996],
            [-38.617499875999954, -8.23208225299993],
            [-38.621652035999944, -8.221969543999933],
            [-38.631217468999978, -8.198853079999935],
            [-38.636297617999958, -8.186598855999932],
            [-38.639583332999962, -8.178722221999976],
            [-38.643376514999943, -8.169204995999962],
            [-38.649027733999958, -8.155523094999978],
            [-38.646517402999962, -8.140461106999965],
            [-38.639379019999978, -8.097916343999941],
            [-38.634739070999956, -8.070148033999942],
            [-38.63099141999993, -8.047269516999961],
            [-38.628945083999952, -8.034622681999963],
            [-38.628159860999972, -8.029863759999955],
            [-38.627219973999956, -8.020928883999943],
            [-38.625583332999952, -8.00225],
            [-38.625720913999942, -7.997514986999931],
            [-38.62660131399997, -7.993124881999961],
            [-38.627848151999956, -7.990048238999975],
            [-38.633016340999973, -7.978041477999966],
            [-38.63608108699998, -7.970905474999938],
            [-38.641744203999963, -7.957806541999958],
            [-38.645182524999939, -7.94916909899996],
            [-38.650488722999967, -7.93656985299998],
            [-38.656711013999939, -7.922590518999925],
            [-38.660815583999977, -7.912727652999934],
            [-38.668025350999983, -7.895833479999965],
            [-38.67292704099998, -7.885304364999968],
            [-38.682171246999985, -7.864472183999965],
            [-38.685990281999977, -7.855977507999967],
            [-38.692652772999963, -7.840748957999949],
            [-38.697055555999953, -7.830972221999957],
            [-38.700290841999959, -7.823462173999928],
            [-38.708452393999949, -7.804961863999949],
            [-38.716649636999932, -7.786556732999941],
            [-38.727440491999971, -7.762524176999932],
            [-38.732913252999936, -7.75012718499994],
            [-38.738362217999963, -7.737813474999939],
            [-38.758049404999952, -7.69508252199995],
            [-38.762846871999955, -7.687011981999945],
            [-38.766314253999951, -7.681379142999958],
            [-38.768257766999966, -7.67811755699995],
            [-38.772026832999984, -7.671845297999937],
            [-38.778261020999935, -7.661506539999948],
            [-38.785411300999954, -7.64954974799997],
            [-38.79036057999997, -7.641352504999929],
            [-38.797308605999945, -7.629728837999949],
            [-38.803435717999946, -7.619592333999947],
            [-38.80964611099995, -7.609182191999935],
            [-38.811383117999981, -7.606267351999975],
            [-38.816082552999944, -7.595512188999976],
            [-38.821507723999957, -7.583246066999948],
            [-38.826290440999969, -7.572431416999962],
            [-38.833357439999929, -7.556381951999981],
            [-38.840805152999963, -7.539321216999952],
            [-38.847086929999932, -7.525222910999958],
            [-38.853202144999955, -7.511184090999961],
            [-38.857901579999975, -7.500607385999956],
            [-38.865848979999953, -7.48265435299993],
            [-38.873653611999941, -7.464808395999967],
            [-38.879066885999976, -7.452494684999976],
            [-38.886003014999972, -7.436683166999956],
            [-38.892094434999933, -7.422941778999927],
            [-38.900315472999978, -7.404393880999976],
            [-38.905812027999957, -7.392056374999981],
            [-38.911177711999983, -7.379659383999979],
            [-38.916329244999929, -7.368071407999935],
            [-38.917863997999973, -7.364763957999969],
            [-38.923075016999974, -7.351415180999936],
            [-38.926537132999954, -7.342801532999943],
            [-38.932021790999954, -7.329000658999973],
            [-38.933651721999979, -7.324860396999952],
            [-38.934365559999947, -7.320839106999927],
            [-38.935835464999968, -7.309448673999952],
            [-38.936055564999947, -7.308241097999939],
            [-38.936424380999938, -7.307943664999925],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Luiz Gonzaga - Sobradinho C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 317.97983,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 2.87015,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.827265628999953, -9.437684759999968],
            [-40.82747984599996, -9.438517554999976],
            [-40.827396564999958, -9.441170653999961],
            [-40.825017103999983, -9.442764892999946],
            [-40.820305770999937, -9.444656563999956],
            [-40.815903767999941, -9.447166894999953],
            [-40.78833771199993, -9.463228256999969],
            [-40.780461695999975, -9.465881355999954],
            [-40.767243789999952, -9.470307153999954],
            [-40.752360261999968, -9.47525643299997],
            [-40.733348367999952, -9.481550106999975],
            [-40.711730964999958, -9.488759873999982],
            [-40.693337730999986, -9.494863190999979],
            [-40.670209370999942, -9.502644028999953],
            [-40.666307053999958, -9.503917039999976],
            [-40.656765415999985, -9.50260833699997],
            [-40.533437951999929, -9.486249542999929],
            [-40.518330130999971, -9.484130278999942],
            [-40.50004221699993, -9.481740463999927],
            [-40.497258246999934, -9.480110532999959],
            [-40.489436958999931, -9.475465824999958],
            [-40.485888888999966, -9.471277777999944],
            [-40.480911349999985, -9.465786177999973],
            [-40.478995883999971, -9.463561381999966],
            [-40.471441094999932, -9.451937714999929],
            [-40.467122373999985, -9.445287120999978],
            [-40.464719117999948, -9.441682237999942],
            [-40.462137402999929, -9.438113045999955],
            [-40.459222562999969, -9.434127448999959],
            [-40.45382118699996, -9.426751119999949],
            [-40.451251368999976, -9.423229517999971],
            [-40.443149303999974, -9.421337845999972],
            [-40.432905723999966, -9.419017871999927],
            [-40.417451124999957, -9.415412987999957],
            [-40.398367847999964, -9.411046676999945],
            [-40.383460524999975, -9.407679739999935],
            [-40.368862530999934, -9.404312802999925],
            [-40.364938172999985, -9.403342039999927],
            [-40.350564475999931, -9.400910173999932],
            [-40.336097352999957, -9.398340355999949],
            [-40.31901282299998, -9.395437412999968],
            [-40.309209443999976, -9.393569535999973],
            [-40.304997797999931, -9.392974670999934],
            [-40.292339064999965, -9.390595209999958],
            [-40.278145580999933, -9.38814436499996],
            [-40.271411705999981, -9.386930839999934],
            [-40.238099251999984, -9.371238294999955],
            [-40.219741709999937, -9.362731721999978],
            [-40.178041655999948, -9.34312496299998],
            [-40.151582049999945, -9.330763662999971],
            [-40.127668466999978, -9.319544503999964],
            [-40.105634657999985, -9.309241437999958],
            [-40.078306547999944, -9.296368553999969],
            [-40.052513190999946, -9.284316583999953],
            [-40.024756777999983, -9.271336623999957],
            [-39.998606501999973, -9.259046707999971],
            [-39.987696672999959, -9.253918969999972],
            [-39.968851341999937, -9.233550783999931],
            [-39.943510081999932, -9.205937138999957],
            [-39.926734881999948, -9.187698569999952],
            [-39.914266505999933, -9.174218923999945],
            [-39.898609652999937, -9.157205776999945],
            [-39.886343530999966, -9.143857000999958],
            [-39.87322080399997, -9.129604029999939],
            [-39.854089936999969, -9.10880753999993],
            [-39.835470654999938, -9.088510737999968],
            [-39.816756193999936, -9.06807116799996],
            [-39.803062395999973, -9.053211433999934],
            [-39.786192017999952, -9.035091837999971],
            [-39.773818819999974, -9.021778753999968],
            [-39.759304107999981, -9.006133797999951],
            [-39.750975994999976, -8.996937180999964],
            [-39.735378627999978, -8.980209569999943],
            [-39.720078693999938, -8.963696110999933],
            [-39.70461219699996, -8.946813834999944],
            [-39.689110008999933, -8.929943456999979],
            [-39.667409323999948, -8.906386792999967],
            [-39.650503253999943, -8.888636013999928],
            [-39.638130056999955, -8.875667950999969],
            [-39.624424360999967, -8.861129443999971],
            [-39.621426239999948, -8.857988555999952],
            [-39.615916666999965, -8.858138888999974],
            [-39.560805555999934, -8.859194443999968],
            [-39.544200832999934, -8.859475718999931],
            [-39.520013611999957, -8.859868329999927],
            [-39.511257195999974, -8.860058686999935],
            [-39.488973542999929, -8.857738711999957],
            [-39.469533346999981, -8.855692375999979],
            [-39.448832035999942, -8.853515168999934],
            [-39.428285389999985, -8.85136175699995],
            [-39.406941624999945, -8.849125063999963],
            [-39.386287903999971, -8.84693595899995],
            [-39.367264112999976, -8.844889622999972],
            [-39.348168937999958, -8.842855183999973],
            [-39.331810143999974, -8.841130074999967],
            [-39.31204871999995, -8.842522058999975],
            [-39.268932886999949, -8.845401206999952],
            [-39.237880920999942, -8.847507029999974],
            [-39.20306940599994, -8.849827004999952],
            [-39.18443822699993, -8.851123810999979],
            [-39.164296088999947, -8.852408719999971],
            [-39.160138888999938, -8.852694443999951],
            [-39.155527777999964, -8.853055555999958],
            [-39.113911001999952, -8.855799451999928],
            [-39.089747575999979, -8.857488868999951],
            [-39.055923537999945, -8.859844534999979],
            [-39.025621101999945, -8.861867076999943],
            [-39.003813341999944, -8.863342342999943],
            [-38.982790803999933, -8.864758121999955],
            [-38.973106396999981, -8.865388679999967],
            [-38.967823993999957, -8.865745598999979],
            [-38.949383170999965, -8.866994815999931],
            [-38.928313043999935, -8.868446286999927],
            [-38.909729452999954, -8.869707400999971],
            [-38.906041288999972, -8.869969141999945],
            [-38.902777777999972, -8.872083332999978],
            [-38.661472221999929, -9.026888888999963],
            [-38.644596012999955, -9.037551417999964],
            [-38.620601146999945, -9.053056768999966],
            [-38.599269278999941, -9.066786258999969],
            [-38.573023823999961, -9.083597150999935],
            [-38.550740170999973, -9.097873916999959],
            [-38.546722221999971, -9.099388888999954],
            [-38.531192898999961, -9.105571472999941],
            [-38.50887355499998, -9.114280300999951],
            [-38.491705743999944, -9.120871407999971],
            [-38.474407061999955, -9.127676665999957],
            [-38.452111512999977, -9.136385493999967],
            [-38.436276199999952, -9.142512605999968],
            [-38.417704506999939, -9.149686680999935],
            [-38.399156607999942, -9.156932138999935],
            [-38.38339267899994, -9.163094942999976],
            [-38.370448410999984, -9.157122495999943],
            [-38.355981287999953, -9.150555183999927],
            [-38.351995690999956, -9.14878248499997],
            [-38.313053431999947, -9.158984423999925],
            [-38.308877477999943, -9.157384236999974],
            [-38.307116676999954, -9.15461811299997],
            [-38.303821122999977, -9.149377350999941],
            [-38.303755687999967, -9.146355434999975],
            [-38.304737215999978, -9.145790312999964],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Xingó - Messias C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 219.27457,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.99362,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-37.795828385999982, -9.633877015999929],
            [-37.795935461999932, -9.634781210999961],
            [-37.79561423499996, -9.638766807999957],
            [-37.792306783999948, -9.645881396999926],
            [-37.79155725399994, -9.64858208499993],
            [-37.787535963999972, -9.649843198999974],
            [-37.782205971999929, -9.650830675999941],
            [-37.77135562999996, -9.652543886999979],
            [-37.764166666999984, -9.653888888999973],
            [-37.750130836999972, -9.656172565999952],
            [-37.737246055999947, -9.658385463999934],
            [-37.729227271999946, -9.659741756999949],
            [-37.720472221999955, -9.65727777799998],
            [-37.715805555999964, -9.653833332999966],
            [-37.705111110999951, -9.644138888999976],
            [-37.700685637999982, -9.640099305999968],
            [-37.694344373999968, -9.636827547999928],
            [-37.689083332999985, -9.636138888999938],
            [-37.668658092999976, -9.633746145999964],
            [-37.659805555999981, -9.632833332999951],
            [-37.65125, -9.631833332999975],
            [-37.632085776999929, -9.629617780999979],
            [-37.617178453999941, -9.627952157999971],
            [-37.602361110999936, -9.626305555999977],
            [-37.596722221999983, -9.625694443999976],
            [-37.579611110999963, -9.62375],
            [-37.570862244999944, -9.622705445999941],
            [-37.553777777999983, -9.62083333299995],
            [-37.549173457999984, -9.620254601999932],
            [-37.537, -9.618888888999948],
            [-37.52686111099996, -9.617805555999951],
            [-37.4375, -9.607555555999966],
            [-37.431944443999953, -9.606888888999947],
            [-37.414330391999954, -9.604672482999945],
            [-37.375722647999964, -9.60037420499998],
            [-37.370690087999947, -9.599862620999943],
            [-37.361576751999962, -9.599029808999944],
            [-37.347752083999978, -9.597495056999946],
            [-37.327705124999966, -9.595163184999933],
            [-37.30333944399996, -9.592248345999963],
            [-37.299305555999979, -9.591888888999961],
            [-37.284410831999935, -9.590118727999936],
            [-37.268456545999982, -9.588191364999943],
            [-37.251110274999974, -9.585954670999968],
            [-37.229671331999953, -9.583587107999961],
            [-37.216048916999966, -9.582004765999955],
            [-37.206661943999961, -9.581017289999977],
            [-37.115326332999985, -9.570262125999932],
            [-37.061217389999968, -9.563920861999975],
            [-37.009749647999968, -9.557817544999978],
            [-37.000504206999949, -9.556661512999938],
            [-36.990999494999983, -9.555616542999928],
            [-36.976948777999951, -9.554010406999964],
            [-36.963076520999948, -9.552273400999979],
            [-36.95393938999996, -9.551131258999931],
            [-36.938211152999941, -9.549168203999955],
            [-36.923827310999968, -9.54739550599993],
            [-36.91358373199995, -9.547252737999941],
            [-36.899283170999979, -9.545468141999947],
            [-36.865258204999975, -9.541120382999964],
            [-36.849577556999975, -9.539097840999943],
            [-36.828923835999944, -9.536480433999941],
            [-36.810149887999955, -9.534053382999957],
            [-36.785903180999981, -9.530864905999977],
            [-36.757004626999958, -9.527212432999931],
            [-36.728582591999952, -9.523780812999973],
            [-36.707583221999982, -9.521002039999928],
            [-36.693452455999932, -9.519332839999947],
            [-36.678791742999977, -9.517420950999963],
            [-36.660196255999949, -9.514982002999943],
            [-36.64142230799996, -9.512602541999968],
            [-36.627276412999947, -9.510841740999979],
            [-36.60539726899998, -9.508426587999963],
            [-36.547148062999952, -9.500681442999962],
            [-36.517821205999951, -9.497147942999959],
            [-36.497772547999944, -9.494842748999929],
            [-36.449590161999936, -9.489081569999939],
            [-36.432124917999943, -9.486821081999949],
            [-36.413767376999942, -9.484453518999942],
            [-36.394422358999975, -9.481931289999977],
            [-36.374006582999982, -9.479242498999952],
            [-36.354816229999983, -9.476791653999953],
            [-36.331902020999962, -9.47380543099996],
            [-36.300647799999979, -9.470450390999929],
            [-36.271237661999976, -9.467285707999963],
            [-36.236426147999964, -9.463585645999956],
            [-36.228276493999942, -9.462729039999942],
            [-36.213155018999942, -9.464358969999978],
            [-36.203601482999943, -9.465334548999977],
            [-36.191085517999966, -9.46481106799996],
            [-36.174203241999976, -9.464109126999972],
            [-36.154644072999929, -9.463216828999975],
            [-36.13428778399998, -9.462324530999979],
            [-36.118940260999977, -9.457732171999965],
            [-36.089006640999969, -9.448761603999969],
            [-35.969935232999944, -9.412688531999947],
            [-35.961044744999981, -9.407924698999977],
            [-35.952788755999961, -9.407493950999935],
            [-35.948625579999941, -9.406232703999933],
            [-35.925009429999932, -9.399106217999929],
            [-35.912457772999971, -9.395287182999937],
            [-35.897050762999982, -9.396548297999971],
            [-35.891042623999965, -9.396250864999956],
            [-35.884903613999938, -9.397547670999927],
            [-35.873517893999974, -9.398523249999926],
            [-35.862941188999969, -9.399320369999941],
            [-35.855541065999944, -9.39991523499998],
            [-35.851519776999965, -9.399106217999929],
            [-35.845963734999941, -9.400605278999933],
            [-35.84364385799995, -9.400915532999932],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Milagres - Coremas, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 120.09547,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.08099,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-37.956036371999971, -7.016130505999968],
            [-37.956357598999944, -7.016109090999976],
            [-37.960198049999974, -7.015887800999963],
            [-37.965843649999954, -7.012926684999968],
            [-37.969456531999981, -7.011105083999951],
            [-37.975181514999974, -7.008056994999947],
            [-37.978793009999947, -7.006116024999926],
            [-37.982787303999942, -7.004018742999961],
            [-37.987143881999941, -7.001754308999978],
            [-37.995077355999967, -7.002112574999956],
            [-38.003029985999945, -7.002427541999964],
            [-38.017781074999959, -7.003015651999931],
            [-38.026220953999939, -7.003317107999976],
            [-38.035843493999948, -7.003688303999979],
            [-38.039962340999978, -7.003952424999966],
            [-38.049763340999959, -7.004316481999979],
            [-38.057375121999939, -7.004626256999927],
            [-38.067373731999965, -7.005008904999954],
            [-38.073148683999932, -7.009020676999967],
            [-38.080586878999952, -7.014210280999976],
            [-38.08843909999996, -7.01961403699994],
            [-38.09539188499997, -7.024489552999967],
            [-38.101445233999982, -7.028644090999933],
            [-38.10547842099993, -7.031413783999938],
            [-38.113123628999972, -7.036760432999927],
            [-38.119441097999982, -7.041236198999968],
            [-38.127614545999961, -7.046939766999969],
            [-38.137115733999963, -7.053471387999934],
            [-38.14530345999998, -7.059153539999954],
            [-38.153441215999976, -7.064842831999954],
            [-38.160391575999938, -7.069707369999946],
            [-38.164006022999956, -7.072238196999933],
            [-38.17245786899997, -7.078127362999965],
            [-38.181302324999933, -7.084294924999938],
            [-38.191246092999961, -7.086928988999944],
            [-38.20160388599993, -7.089634435999926],
            [-38.206957673999966, -7.091426169999977],
            [-38.211875051999982, -7.092770084999927],
            [-38.21623190899993, -7.093505979999975],
            [-38.225246210999956, -7.095916212999953],
            [-38.228981849999968, -7.096900068999958],
            [-38.235032933999946, -7.098471753999945],
            [-38.242204889999982, -7.100372892999928],
            [-38.249695309999936, -7.102349137999965],
            [-38.251574777999963, -7.102863919999947],
            [-38.25787715599995, -7.104445434999946],
            [-38.263737438999954, -7.106081703999962],
            [-38.270531629999937, -7.107901553999966],
            [-38.273783551999941, -7.10872771499993],
            [-38.277085678999981, -7.109618732999934],
            [-38.281214917999932, -7.110670894999942],
            [-38.286145335999947, -7.111973457999966],
            [-38.290423216999955, -7.113086403999944],
            [-38.301576940999951, -7.116019088999963],
            [-38.30987241899993, -7.118255148999935],
            [-38.317669567999985, -7.120260799999926],
            [-38.323324809999974, -7.121771486999933],
            [-38.328105833999984, -7.121608556999945],
            [-38.339283957999953, -7.121105410999974],
            [-38.343982843999981, -7.120905966999942],
            [-38.348538608999945, -7.120692924999958],
            [-38.360676728999977, -7.120177240999965],
            [-38.369960418999938, -7.119802043999925],
            [-38.38185477299993, -7.119335616999933],
            [-38.394390612999985, -7.11878534799996],
            [-38.407371601999955, -7.118202201999964],
            [-38.421568399999956, -7.117544323999937],
            [-38.423559070999943, -7.117528397999934],
            [-38.428625860999944, -7.119124240999952],
            [-38.43728049799995, -7.121870691999959],
            [-38.446123787999966, -7.124707884999964],
            [-38.450161763999972, -7.125979137999934],
            [-38.455326888999934, -7.127628503999972],
            [-38.464959870999962, -7.130708906999928],
            [-38.468267723999929, -7.131752886999948],
            [-38.47232951999996, -7.133286725999938],
            [-38.479953127999977, -7.136186424999948],
            [-38.483212054999967, -7.137053016999971],
            [-38.487078432999965, -7.138034465999965],
            [-38.490466628999968, -7.138985942999966],
            [-38.49429360299996, -7.140052906999927],
            [-38.506446727999958, -7.143893610999953],
            [-38.510944672999983, -7.145345876999954],
            [-38.516804854999975, -7.147211341999935],
            [-38.52407145799998, -7.149503055999958],
            [-38.534411958999954, -7.152736551999965],
            [-38.542306089999954, -7.157051177999961],
            [-38.545018660999972, -7.158521277999967],
            [-38.548563353999953, -7.162962243999971],
            [-38.551100664999979, -7.166076272999931],
            [-38.554258595999954, -7.169850955999948],
            [-38.558485752999957, -7.175156985999934],
            [-38.561616472999958, -7.178946368999959],
            [-38.565342572999953, -7.183417321999968],
            [-38.571204592999948, -7.190675086999931],
            [-38.573779396999953, -7.193929078999929],
            [-38.575787859999934, -7.196387385999969],
            [-38.589331749999985, -7.210675641999956],
            [-38.593850777999933, -7.215651565999963],
            [-38.597488989999931, -7.219544847999941],
            [-38.600513385999932, -7.222806919999925],
            [-38.60365427399995, -7.226169097999957],
            [-38.608822462999967, -7.231679929999927],
            [-38.614797289999956, -7.23806164399997],
            [-38.618195159999971, -7.241687942999931],
            [-38.621621583999968, -7.245378486999925],
            [-38.624769610999977, -7.248726388999955],
            [-38.626772686999971, -7.250974311999926],
            [-38.635113127999944, -7.252131396999971],
            [-38.642636983999978, -7.25320215499994],
            [-38.651602792999938, -7.254544170999964],
            [-38.65590009899995, -7.255136655999934],
            [-38.672318379999979, -7.257506599999942],
            [-38.68036333799995, -7.258934275999934],
            [-38.691906102999951, -7.260961576999932],
            [-38.697586641999976, -7.261343847999967],
            [-38.702099713999985, -7.261575477999941],
            [-38.706140038999933, -7.261768213999972],
            [-38.711199068999974, -7.26211051699994],
            [-38.716161708999948, -7.26239527499996],
            [-38.722314392999976, -7.26270629499993],
            [-38.72773144599995, -7.263023713999928],
            [-38.735978614999965, -7.263434847999974],
            [-38.745995669999957, -7.264003854999942],
            [-38.75647966899993, -7.264593084999944],
            [-38.760539085999937, -7.264800226999967],
            [-38.764567351999972, -7.265082811999946],
            [-38.773780818999967, -7.265547501999947],
            [-38.781996080999932, -7.265977783999972],
            [-38.786603726999942, -7.266193303999955],
            [-38.794441719999952, -7.266629045999935],
            [-38.800821798999948, -7.266902675999972],
            [-38.807519354999954, -7.267186246999927],
            [-38.814800504999937, -7.267607411999961],
            [-38.827199876999941, -7.268214173999979],
            [-38.831982592999964, -7.268464017999975],
            [-38.836922353999967, -7.268828074999931],
            [-38.841362428999958, -7.269099333999975],
            [-38.847347184999933, -7.269508489999964],
            [-38.858180458999982, -7.270013046999964],
            [-38.861821033999945, -7.270234336999977],
            [-38.876162045999934, -7.271019558999967],
            [-38.883564548999971, -7.271440722999955],
            [-38.888618956999949, -7.273767030999977],
            [-38.890643836999971, -7.274752482999929],
            [-38.897027538999964, -7.277843852999979],
            [-38.906228914999929, -7.28224109699994],
            [-38.912910441999941, -7.28555330699993],
            [-38.918828288999975, -7.288320024999962],
            [-38.924727636999933, -7.291160459999958],
            [-38.929285191999952, -7.293355886999962],
            [-38.938696762999939, -7.297768996999935],
            [-38.94021485899998, -7.299796297999933],
            [-38.940417933999981, -7.303047489999926],
            [-38.940795446999971, -7.305754467999975],
            [-38.940957250999929, -7.306396922999966],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV São João do Piauí - Boa Esperança C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 236.64657,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 2.11764,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-43.213321391609071, -7.130510109719521],
            [-43.193383942999958, -7.156554939999978],
            [-43.137587108999981, -7.229407506999962],
            [-43.119517645999963, -7.253146895999976],
            [-43.080773664999981, -7.303713089999974],
            [-43.028647349999972, -7.37207288899998],
            [-42.986816997999938, -7.427220159999933],
            [-42.937643360999971, -7.491770188999965],
            [-42.917121041999962, -7.518282084999953],
            [-42.892084460999968, -7.546774265999943],
            [-42.850628552999979, -7.593474919999949],
            [-42.82211762299994, -7.625876245999962],
            [-42.797216272999947, -7.653945064999959],
            [-42.755692865999947, -7.701056217999962],
            [-42.741736766999963, -7.717011645999946],
            [-42.729127207999966, -7.73125583999996],
            [-42.702178760999971, -7.761677994999957],
            [-42.679088319999948, -7.787832263999974],
            [-42.629051874999959, -7.844593531999976],
            [-42.453981918999943, -8.042490621999946],
            [-42.416651433999959, -8.084818495999968],
            [-42.391341910999984, -8.113423215999944],
            [-42.378129009999952, -8.128498844999967],
            [-42.349637382999958, -8.160658717999979],
            [-42.287005285999953, -8.231422038999938],
            [-42.274543929999936, -8.245384551999962],
            [-42.258603308999966, -8.284161175999941],
            [-42.248333485999979, -8.309177014999932],
            [-42.244346995999933, -8.318839367999942],
            [-42.240475863999961, -8.32840740599994],
            [-42.238049008999951, -8.334238968999955],
            [-42.23403570499994, -8.344642759999942],
            [-42.23327708599993, -8.349650126999961],
            [-42.232109359999981, -8.356650625999976],
            [-42.230792749999978, -8.357063180999944],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV São João do Piauí - Eliseu Martins C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 173.38589,
        Ano_Opera: 2006.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.56865,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-43.213321391609071, -8.174820735782285],
            [-43.209533043999954, -8.174528699999939],
            [-43.168669927999986, -8.171409145999974],
            [-43.137896464999983, -8.169081930999937],
            [-43.129078874999948, -8.168401524999979],
            [-43.116056453999931, -8.170381814999928],
            [-43.107940615999951, -8.171535032999941],
            [-43.097220628999935, -8.171706867999944],
            [-43.091684933999943, -8.171801072999926],
            [-43.075024191999944, -8.165809852999928],
            [-43.070186575999969, -8.164065620999963],
            [-43.061372225999946, -8.160882146999938],
            [-43.056858711999951, -8.159256472999971],
            [-43.053325203999975, -8.157919116999949],
            [-43.013523249999935, -8.143561047999981],
            [-43.002240892999964, -8.144416103999959],
            [-42.99835690599997, -8.142468339999937],
            [-42.988059937999935, -8.13734379999994],
            [-42.96897964599998, -8.127830026999959],
            [-42.951863441999933, -8.129690256999936],
            [-42.937059381999973, -8.131174722999958],
            [-42.933416808999937, -8.131562287999941],
            [-42.927266200999952, -8.138679942999943],
            [-42.912158606999981, -8.155832565999958],
            [-42.892362368999954, -8.178585885999951],
            [-42.872970275999933, -8.200886407999974],
            [-42.86670167699998, -8.208010019999961],
            [-42.847628165999936, -8.209153847999971],
            [-42.829166726999972, -8.210263765999969],
            [-42.809804560999964, -8.211488877999955],
            [-42.788526575999981, -8.213616367999975],
            [-42.769296316999942, -8.215757706999966],
            [-42.729978152999934, -8.220288154999935],
            [-42.705397998999956, -8.223053032999928],
            [-42.668720801999939, -8.227263663999963],
            [-42.595258017999981, -8.198811737999961],
            [-42.536248842999953, -8.222462251999957],
            [-42.488981748999947, -8.241491353999947],
            [-42.461200137999981, -8.241536050999969],
            [-42.440547648999939, -8.241800738999927],
            [-42.42330123499994, -8.253223631999958],
            [-42.404779853999969, -8.265394248999939],
            [-42.346091752999939, -8.304123514999958],
            [-42.319350336999946, -8.30774490899995],
            [-42.286542563999944, -8.312088505999952],
            [-42.277000769999972, -8.313278964999938],
            [-42.268676332999974, -8.317189880999933],
            [-42.256466451999984, -8.322884021999926],
            [-42.245851658999982, -8.327732906999927],
            [-42.231600058999959, -8.334336919999942],
            [-42.227907703999961, -8.34131035799993],
            [-42.225361882999948, -8.346032585999978],
            [-42.227144299999964, -8.352128052999944],
            [-42.228076847999944, -8.35490202699998],
            [-42.228185112999938, -8.355218494999974],
            [-42.228051862999962, -8.355335088999936],
            [-42.228143472999932, -8.35570985399994],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Sobradinho - São João do Piauí C2',
        Concession: 'TAESA - TRANSMISSORA ALIANÇA DE ENERGIA ELÉTRICA S A',
        Tensao: 500.0,
        Extensao: 214.33757,
        Ano_Opera: 2006.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.92792,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-42.227243921999957, -8.358922156999938],
            [-42.226104717999931, -8.359162665999975],
            [-42.220986180999944, -8.366998395999929],
            [-42.218520257999955, -8.370903596999938],
            [-42.212439252999957, -8.378432609999948],
            [-42.204949152999973, -8.387719489999938],
            [-42.199785482999971, -8.394204769999931],
            [-42.19379138599993, -8.401618018999955],
            [-42.187791087999983, -8.409142857999939],
            [-42.179620900999964, -8.419399212999963],
            [-42.171200462999934, -8.429962605999947],
            [-42.166435714999977, -8.43584905299997],
            [-42.158457120999969, -8.445750429999975],
            [-42.152913437999985, -8.452657374999944],
            [-42.147252949999938, -8.459632145999933],
            [-42.14172233499994, -8.467107986999963],
            [-42.135997698999972, -8.474450545999957],
            [-42.13017849199997, -8.480778390999944],
            [-42.078902434999975, -8.545159411999975],
            [-42.045831714999963, -8.560911320999935],
            [-42.013884391999966, -8.57626929099996],
            [-41.993860507999955, -8.585876610999946],
            [-41.950137712999947, -8.606958284999962],
            [-41.920893937999949, -8.620978282999943],
            [-41.901777926999955, -8.630171609999934],
            [-41.850272464999932, -8.654880760999959],
            [-41.582173736999948, -8.783571456999937],
            [-41.408628373999932, -8.866706934999968],
            [-41.257428769999933, -8.925088407999965],
            [-41.093861155999946, -8.988135714999942],
            [-41.009144188999983, -9.02069234399994],
            [-40.939440398999977, -9.085009861999936],
            [-40.843046145999949, -9.173841438999943],
            [-40.831085628999972, -9.199053555999967],
            [-40.812480009999945, -9.238305778999973],
            [-40.802607155999965, -9.259315137999977],
            [-40.778830788999983, -9.309046027999955],
            [-40.776968156999942, -9.313346165999974],
            [-40.779712334999942, -9.33596674599994],
            [-40.780293986999936, -9.340672330999951],
            [-40.781470044999935, -9.350063529999943],
            [-40.783253851999973, -9.364202239999941],
            [-40.784307961999957, -9.373029834999954],
            [-40.785398915999963, -9.381890000999931],
            [-40.794389511999952, -9.392210771999942],
            [-40.803102346999935, -9.402227688999972],
            [-40.809202806999963, -9.409220773999948],
            [-40.814985993999983, -9.415446913999972],
            [-40.816391050999982, -9.418784907999964],
            [-40.817858301999934, -9.421481899999947],
            [-40.820709855999951, -9.426414415999943],
            [-40.825490192999951, -9.434850794999932],
            [-40.826614487999962, -9.434759185999951],
            [-40.826697769999953, -9.435358809999968],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Sobradinho - São João do Piauí C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 214.4206,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.92867,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-42.226966561999973, -8.358117338999932],
            [-42.22565633399995, -8.358496295999942],
            [-42.219937619999939, -8.367378937999945],
            [-42.218044365999958, -8.370510985999942],
            [-42.21493679799994, -8.374210945999948],
            [-42.204307350999954, -8.387562861999982],
            [-42.164991990999965, -8.436557233999963],
            [-42.135180959999957, -8.473822106999933],
            [-42.13018845199997, -8.479918831999953],
            [-42.118634228999952, -8.494471233999946],
            [-42.096966731999942, -8.52136532399993],
            [-42.078466613999979, -8.544583060999969],
            [-42.029319060999967, -8.568191949999971],
            [-41.978489355999955, -8.592577580999944],
            [-41.872916008999937, -8.643354802999966],
            [-41.801188441999955, -8.677783251999927],
            [-41.71898327699995, -8.717313736999927],
            [-41.601007395999943, -8.773983758999975],
            [-41.408580327999971, -8.86593309899996],
            [-41.213235542999939, -8.941322858999968],
            [-41.008694469999966, -9.020062636999967],
            [-40.96492451599994, -9.060594820999938],
            [-40.842407413999979, -9.173495616999958],
            [-40.795312824999939, -9.272983738999926],
            [-40.776386206999973, -9.313237349999952],
            [-40.78467758599993, -9.382250449999958],
            [-40.80065581599996, -9.400448819999951],
            [-40.812278478999986, -9.413463849999971],
            [-40.814513571999953, -9.415909855999928],
            [-40.816775966999955, -9.418379718999972],
            [-40.819281604999958, -9.421062313999926],
            [-40.821567651999942, -9.425106901999982],
            [-40.823874538999974, -9.429112724999982],
            [-40.825889942999936, -9.432635516999937],
            [-40.826256379999961, -9.434892434999938],
            [-40.826381300999969, -9.435367137999947],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Curral Novo do Piauí II - Milagres C1',
        Concession: 'IRACEMA - IRACEMA TRANSMISSORA DE ENRGIA S.A',
        Tensao: 500.0,
        Extensao: 213.39447,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.92192,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.637312218999966, -8.033892088999949],
            [-40.635978816999966, -8.033665103999965],
            [-40.635163811999973, -8.038361499999951],
            [-40.631187675999968, -8.037783655999931],
            [-40.584357413999953, -8.030213814999968],
            [-40.559561335999945, -8.026201951999951],
            [-40.539784099999963, -8.023441071999969],
            [-40.501710784999943, -8.017077799999981],
            [-40.461469086999955, -8.010252403999971],
            [-40.412878944999932, -8.002183074999948],
            [-40.354708591999952, -7.993373724999969],
            [-40.297050021999951, -7.982228087999943],
            [-40.258591518999935, -7.976513846999978],
            [-40.236906550999947, -7.970491752999976],
            [-40.213105472999985, -7.963851312999964],
            [-40.182458206999968, -7.955122744999926],
            [-40.125870625999937, -7.939163150999946],
            [-40.092367775999946, -7.929550672999937],
            [-40.090869383999973, -7.926685808999935],
            [-40.055835157999979, -7.917205773999967],
            [-40.013526809999973, -7.904872707999971],
            [-39.984499043999961, -7.896119247999934],
            [-39.972373601999948, -7.893046306999963],
            [-39.949049718999959, -7.886362091999956],
            [-39.933478961999981, -7.881320784999957],
            [-39.912083134999932, -7.87461095499998],
            [-39.894915668999943, -7.868907774999968],
            [-39.880839857999945, -7.864514367999959],
            [-39.845690640999976, -7.853133285999945],
            [-39.819748944999958, -7.844810608999978],
            [-39.787369608999938, -7.834565304999956],
            [-39.741544896999983, -7.819747252999946],
            [-39.505160461999935, -7.743477383999959],
            [-39.480858184999931, -7.735486950999928],
            [-39.45972408199998, -7.727198960999942],
            [-39.446187407999957, -7.721854123999947],
            [-39.426582843999938, -7.713976943999967],
            [-39.408808272999977, -7.706888783999943],
            [-39.39551845699998, -7.701500017999933],
            [-39.377854136999929, -7.694379941999955],
            [-39.365759152999942, -7.689578013999949],
            [-39.361847159999968, -7.687985135999952],
            [-39.353046995999932, -7.683170702999973],
            [-39.346778828999959, -7.67960065699998],
            [-39.342186443999935, -7.677817651999931],
            [-39.325054602999955, -7.67109510399996],
            [-39.310865947999957, -7.665535191999936],
            [-39.301096787999938, -7.660878443999934],
            [-39.284965872999976, -7.653243543999963],
            [-39.257203429999947, -7.64005691999995],
            [-39.249221630999955, -7.636245411999937],
            [-39.236554923999961, -7.629316519999975],
            [-39.224954661999959, -7.62298554299997],
            [-39.216949475999968, -7.617838738999978],
            [-39.212414792999937, -7.615021089999971],
            [-39.201810696999985, -7.609331845999975],
            [-39.186463851999974, -7.600851684999952],
            [-39.167841331999966, -7.590128412999945],
            [-39.150756854999941, -7.580081050999979],
            [-39.143024520999973, -7.575645169999973],
            [-39.136417215999984, -7.571811976999925],
            [-39.128294952999966, -7.562115075999941],
            [-39.112750841999969, -7.543201651999937],
            [-39.10415392799996, -7.532747620999942],
            [-39.080523313999947, -7.504220499999974],
            [-39.06750442799995, -7.488625175999971],
            [-39.06036637699998, -7.479960605999963],
            [-39.039248180999948, -7.454420346999939],
            [-39.026366099999962, -7.439615106999952],
            [-39.01719328799993, -7.428955213999927],
            [-39.010850567999967, -7.421835588999954],
            [-39.003271984999969, -7.411699084999952],
            [-38.998322705999954, -7.405036594999956],
            [-38.959075748999965, -7.357681312999944],
            [-38.949154323999949, -7.345622169999956],
            [-38.94501235599995, -7.340058825999961],
            [-38.941029088999983, -7.334732732999953],
            [-38.938528934999965, -7.330203672999971],
            [-38.934393907999947, -7.322504537999976],
            [-38.933640259999947, -7.320326167999951],
            [-38.935246547999952, -7.309359443999938],
            [-38.935567774999981, -7.307836588999976],
            [-38.935948488999941, -7.307443977999981],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Picos - Tauá II C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 185.52817,
        Ano_Opera: 2013.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.65627,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.504685168774174, -6.289108792304777],
            [-40.515326224999967, -6.300966043999949],
            [-40.599830714999939, -6.394059793999929],
            [-40.64128369499997, -6.439877715999955],
            [-40.668752382999969, -6.470246274999965],
            [-40.682380018999936, -6.48522887699994],
            [-40.699133539999934, -6.503790479999964],
            [-40.720084930999974, -6.52690737599994],
            [-40.752199251999969, -6.536595057999932],
            [-40.770195733999969, -6.54211277099995],
            [-40.772706064999966, -6.545217966999928],
            [-40.788339123999947, -6.564610573999971],
            [-40.801188212999932, -6.580552962999946],
            [-40.903469729999983, -6.70733241399995],
            [-40.953806841999949, -6.76970821599997],
            [-40.956947730999957, -6.773574839999981],
            [-40.968510241999979, -6.787968921999948],
            [-41.062786155999959, -6.904599859999962],
            [-41.066450525999983, -6.905575438999961],
            [-41.09413555499998, -6.912428286999955],
            [-41.11876297699996, -6.918686268999977],
            [-41.133444250999958, -6.922314946999961],
            [-41.24349639899998, -6.94981102099996],
            [-41.284480746999975, -6.959997531999932],
            [-41.307709320999948, -6.985842675999947],
            [-41.326877752999962, -7.007413121999946],
            [-41.373670603999983, -7.059409069999958],
            [-41.38622208399994, -7.073429713999928],
            [-41.396617395999954, -7.087773993999974],
            [-41.398131879999937, -7.089897311999948],
            [-41.404154372999983, -7.093384893999939],
            [-41.411100652999949, -7.099676683999974],
            [-41.415441367999961, -7.103507786999955],
            [-41.418784296999945, -7.106604187999949],
            [-41.419328904999929, -7.108915076999949],
            [-41.41737554599996, -7.111137160999931],
            [-41.414963418999946, -7.11079719199995],
            [-41.414820239999983, -7.110699551999971],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Milagres - Tauá II C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 211.09978,
        Ano_Opera: 2007.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.87952,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-39.971209427725896, -6.289108792304777],
            [-39.964778713999976, -6.294741809999948],
            [-39.942761207999979, -6.313621575999946],
            [-39.926146624999944, -6.328072658999929],
            [-39.907662606999963, -6.344100849999961],
            [-39.894740154999965, -6.355303509999942],
            [-39.876777604999972, -6.370902593999972],
            [-39.809537985999953, -6.429162735999967],
            [-39.775972334999949, -6.45818573899993],
            [-39.752036797999949, -6.478982142999939],
            [-39.731975733999946, -6.49633384599997],
            [-39.715901403999965, -6.510230981999939],
            [-39.705644484999937, -6.519242628999962],
            [-39.694985631999941, -6.528434442999981],
            [-39.669038237999985, -6.550958062999939],
            [-39.652920280999979, -6.564741394999942],
            [-39.640600581999934, -6.575478440999973],
            [-39.635399974999984, -6.579953695999961],
            [-39.628322788999981, -6.590919644999929],
            [-39.625080812999954, -6.595957022999926],
            [-39.622203552999963, -6.600392538999927],
            [-39.619055604999971, -6.605194598999958],
            [-39.616492621999953, -6.608214182999973],
            [-39.613283744999933, -6.612146883999969],
            [-39.609982855999931, -6.616078855999945],
            [-39.601401875999954, -6.626233732999935],
            [-39.589911865999966, -6.640053363999925],
            [-39.580558603999975, -6.651138167999932],
            [-39.568365901999982, -6.665589033999936],
            [-39.564978073999953, -6.669699497999943],
            [-39.55685333699995, -6.678855059999933],
            [-39.548118581999972, -6.687236927999948],
            [-39.545416005999982, -6.690021835999971],
            [-39.539997175999929, -6.694405201999928],
            [-39.530226049999953, -6.703566783999975],
            [-39.523250538999946, -6.709997301999977],
            [-39.519568058999937, -6.71350214399996],
            [-39.512822396999979, -6.71977013399993],
            [-39.503029583999933, -6.728805435999959],
            [-39.487741466999978, -6.74301917799994],
            [-39.483843364999984, -6.746549134999952],
            [-39.478802375999976, -6.751262270999973],
            [-39.476291520999951, -6.753599700999928],
            [-39.473767326999962, -6.75589047699998],
            [-39.466009697999937, -6.759986158999936],
            [-39.462340428999937, -6.762116056999957],
            [-39.45875981599994, -6.764104147999944],
            [-39.45605454799994, -6.765485159999969],
            [-39.446561333999966, -6.770917382999926],
            [-39.443251578999934, -6.77270494399994],
            [-39.440510383999936, -6.774226462999934],
            [-39.438398314999972, -6.776723760999971],
            [-39.434577377999972, -6.781593407999935],
            [-39.430859428999952, -6.785996886999953],
            [-39.426269884999954, -6.791725626999948],
            [-39.422917792999954, -6.795768625999926],
            [-39.417818433999969, -6.80203008899997],
            [-39.41188912299998, -6.809335234999935],
            [-39.40405859699996, -6.818838109999945],
            [-39.396574946999976, -6.827908499999978],
            [-39.391901899999937, -6.833707862999972],
            [-39.387186593999957, -6.83793787999997],
            [-39.383984082999973, -6.840717747999975],
            [-39.380915779999953, -6.843486665999933],
            [-39.377682444999948, -6.846318946999929],
            [-39.375588060999974, -6.848094132999961],
            [-39.368996557999935, -6.854064296999979],
            [-39.361675616999946, -6.860709046999943],
            [-39.355098206999969, -6.866377195999974],
            [-39.347932126999979, -6.872811024999976],
            [-39.343530322999982, -6.876703167999949],
            [-39.339478259999964, -6.880381953999972],
            [-39.333703935999949, -6.885499201999949],
            [-39.328964334999966, -6.889662863999945],
            [-39.315616202999934, -6.90021834099997],
            [-39.30986362799996, -6.904880134999928],
            [-39.303295336999952, -6.909989380999946],
            [-39.296168310999974, -6.91555032399998],
            [-39.283017818999951, -6.926038110999968],
            [-39.27853319299993, -6.929644994999933],
            [-39.270825553999941, -6.935693690999926],
            [-39.264778486999944, -6.940610996999965],
            [-39.258367172999954, -6.945561297999973],
            [-39.25219141499997, -6.950468895999961],
            [-39.243999706999944, -6.956963025999926],
            [-39.237255546999961, -6.962209399999949],
            [-39.231106282999974, -6.96714622099995],
            [-39.228615006999974, -6.969151495999938],
            [-39.224180374999946, -6.974230453999951],
            [-39.220140837999963, -6.978956015999927],
            [-39.213229138999964, -6.987171583999952],
            [-39.207932959999937, -6.993342062999943],
            [-39.199096807999979, -7.003600522999932],
            [-39.191523707999977, -7.011816020999959],
            [-39.185066812999935, -7.018338812999957],
            [-39.171373050999932, -7.032176380999942],
            [-39.162223025999936, -7.04134860299996],
            [-39.15304520199993, -7.050735525999926],
            [-39.148256771999968, -7.055451742999935],
            [-39.136068056999932, -7.067771681999943],
            [-39.117834443999982, -7.086188653999955],
            [-39.103801507999947, -7.10034957299996],
            [-39.095307274999982, -7.108993083999962],
            [-39.08865110399995, -7.115645082999947],
            [-39.07968740299998, -7.125025519999951],
            [-39.072146976999932, -7.13374208099998],
            [-39.06551746599996, -7.141521311999952],
            [-39.062006906999954, -7.145575883999925],
            [-39.058391461999975, -7.149797643999932],
            [-39.051057562999972, -7.158336496999937],
            [-39.041259725999964, -7.169687281999927],
            [-39.034440551999978, -7.177626817999965],
            [-39.027704458999949, -7.185526150999976],
            [-39.019207707999954, -7.195389481999939],
            [-39.01243325799993, -7.203195367999967],
            [-39.006118335999929, -7.210660349999955],
            [-39.003766063999933, -7.213332099999946],
            [-38.995555237999952, -7.222876106999934],
            [-38.989260860999934, -7.230856500999948],
            [-38.982749122999962, -7.23776531599998],
            [-38.973001439999962, -7.249126133999937],
            [-38.955717100999948, -7.269203454999968],
            [-38.951157079999973, -7.276181004999955],
            [-38.947021853999956, -7.282497199999966],
            [-38.944518956999957, -7.286245427999972],
            [-38.941613110999981, -7.290781121999942],
            [-38.940708237999957, -7.292238248999979],
            [-38.940842087999954, -7.294185152999944],
            [-38.941181337999978, -7.299707677999947],
            [-38.941405668999948, -7.303411249999954],
            [-38.941512773999932, -7.305870603999949],
            [-38.941523179999933, -7.306316883999955],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Milagres - Coremas, C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 120.23177,
        Ano_Opera: 2009.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.08208,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-37.956007818999979, -7.016330380999932],
            [-37.956379014999982, -7.016330380999932],
            [-37.960276571999941, -7.016273273999957],
            [-37.966207573999952, -7.013126211999975],
            [-37.969949080999982, -7.011226436999948],
            [-37.975324282999964, -7.008456743999943],
            [-37.983064079999963, -7.00431458099996],
            [-37.987209985999982, -7.002128981999931],
            [-37.995549524999944, -7.002484744999947],
            [-38.003449511999975, -7.002810282999974],
            [-38.01250698299998, -7.00315358499995],
            [-38.021793620999972, -7.00352917999993],
            [-38.030615675999968, -7.003886459999933],
            [-38.040197658999944, -7.004274590999955],
            [-38.049819468999942, -7.004659986999968],
            [-38.05929096999995, -7.005078439999977],
            [-38.067043828999942, -7.00535774399998],
            [-38.069643737999968, -7.007071897999936],
            [-38.07300930699995, -7.009408718999964],
            [-38.090714349999985, -7.021710058999929],
            [-38.09771692299995, -7.026577956999972],
            [-38.105182155999955, -7.031672845999935],
            [-38.109811054999966, -7.034911339999951],
            [-38.116967294999938, -7.039962104999972],
            [-38.127518097999939, -7.047244724999928],
            [-38.13373798899994, -7.051635903999966],
            [-38.141084727999953, -7.056732330999978],
            [-38.149008703999982, -7.06223517899997],
            [-38.156279835999953, -7.067327429999978],
            [-38.163806147999935, -7.072552284999972],
            [-38.172357930999965, -7.078491419999978],
            [-38.181166695999934, -7.084616152999956],
            [-38.191167569999948, -7.087293045999957],
            [-38.20148967199998, -7.089984216999937],
            [-38.206886289999943, -7.091818780999972],
            [-38.211561930999949, -7.09351057799995],
            [-38.216429338999944, -7.095226658999934],
            [-38.224957327999959, -7.096187960999941],
            [-38.22887488899994, -7.097229674999937],
            [-38.238535940999952, -7.099785312999927],
            [-38.246978725999952, -7.102012480999974],
            [-38.25176384699995, -7.103276818999973],
            [-38.259845952999967, -7.105357913999967],
            [-38.266936916999953, -7.107273022999948],
            [-38.274007321999932, -7.109201944999938],
            [-38.281223030999968, -7.111025789999928],
            [-38.290250705999938, -7.113419527999952],
            [-38.302189651999981, -7.116542570999968],
            [-38.317590712999959, -7.120617397999979],
            [-38.323379941999974, -7.122127165999927],
            [-38.335508054999934, -7.121634616999927],
            [-38.344238296999947, -7.121227728999941],
            [-38.353225520999956, -7.120877948999976],
            [-38.364754009999956, -7.120385399999975],
            [-38.385950869999931, -7.119558980999955],
            [-38.399103980999939, -7.119008520999955],
            [-38.421589814999948, -7.117922657999941],
            [-38.429784678999965, -7.123933176999969],
            [-38.433475222999959, -7.12674388399995],
            [-38.437081088999946, -7.125231238999959],
            [-38.44112824299998, -7.123503011999958],
            [-38.450062070999934, -7.126351156999931],
            [-38.455316461999985, -7.128030170999978],
            [-38.465112907999981, -7.131144802999927],
            [-38.468157831999974, -7.132108870999957],
            [-38.472205172999963, -7.133611217999942],
            [-38.479828014999953, -7.136508280999976],
            [-38.486848317999943, -7.138384832999975],
            [-38.494275096999957, -7.140416398999946],
            [-38.501238848999947, -7.142661345999954],
            [-38.506786477999981, -7.144434188999981],
            [-38.511630019999984, -7.14594449599997],
            [-38.519587503999958, -7.148497720999956],
            [-38.52431017899994, -7.149997710999969],
            [-38.530835082999943, -7.154952144999982],
            [-38.537163966999969, -7.159659555999951],
            [-38.541265920999933, -7.162717820999944],
            [-38.547722265999937, -7.167531366999981],
            [-38.553924953999967, -7.172152245999939],
            [-38.558256596999968, -7.175498906999962],
            [-38.561358829999961, -7.17927872599995],
            [-38.56749836399996, -7.18674380899995],
            [-38.570933971999978, -7.191010730999949],
            [-38.573598778999951, -7.194173146999958],
            [-38.575662077999937, -7.196780778999937],
            [-38.578207546999977, -7.199521102999938],
            [-38.580952636999939, -7.202416342999925],
            [-38.584187903999975, -7.20586039899996],
            [-38.587194064999949, -7.209122770999954],
            [-38.590498233999938, -7.212613308999948],
            [-38.593699664999974, -7.216152785999952],
            [-38.600419541999941, -7.223281317999977],
            [-38.603533106999976, -7.226646054999947],
            [-38.608740809999972, -7.232142130999932],
            [-38.614659980999932, -7.238501898999971],
            [-38.621336048999979, -7.245592637999948],
            [-38.624591151999937, -7.249026200999936],
            [-38.626696842999934, -7.251280329999929],
            [-38.635148819999984, -7.252459762999933],
            [-38.642708367999944, -7.253573350999943],
            [-38.651460024999949, -7.254851120999945],
            [-38.658774834999974, -7.255955856999947],
            [-38.66755276799995, -7.257233305999932],
            [-38.672391417999961, -7.257914433999929],
            [-38.680249123999943, -7.259341163999977],
            [-38.691641164999965, -7.261372009999945],
            [-38.702078298999936, -7.261910981999961],
            [-38.706229195999981, -7.262173959999927],
            [-38.711382754999931, -7.262489820999974],
            [-38.716314134999948, -7.262723623999932],
            [-38.722560467999983, -7.263070330999938],
            [-38.728004114999976, -7.263373952999927],
            [-38.732067315999984, -7.263543467999966],
            [-38.736257893999948, -7.263767429999973],
            [-38.741041096999936, -7.264036064999971],
            [-38.746060734999958, -7.264295358999959],
            [-38.750984900999981, -7.264578774999961],
            [-38.756617730999949, -7.264894896999976],
            [-38.760841131999939, -7.26510378699993],
            [-38.764770362999968, -7.265397252999946],
            [-38.770576476999963, -7.265675711999961],
            [-38.777829355999984, -7.266017010999974],
            [-38.786707698999976, -7.266480082999976],
            [-38.79459544599996, -7.266934841999955],
            [-38.800974887999985, -7.267284798999981],
            [-38.814800396999942, -7.268032348999952],
            [-38.824160051999968, -7.268492530999936],
            [-38.833383692999973, -7.268996251999965],
            [-38.836878170999967, -7.269233160999931],
            [-38.839135908999936, -7.269704690999959],
            [-38.842564276999951, -7.270376771999963],
            [-38.847295004999978, -7.269800576999955],
            [-38.851596799999982, -7.269969507999974],
            [-38.855409754999982, -7.270196168999973],
            [-38.858159043999933, -7.270405657999959],
            [-38.861678266999945, -7.270584116999942],
            [-38.865915726999958, -7.271715331999928],
            [-38.869350607999934, -7.27151688999993],
            [-38.87262854599993, -7.271169464999957],
            [-38.879924728999981, -7.271585835999929],
            [-38.883491701999958, -7.271765569999957],
            [-38.890027022999959, -7.274874466999961],
            [-38.896784833999959, -7.27815080299996],
            [-38.900329251999949, -7.279782634999947],
            [-38.905764919999967, -7.282440971999961],
            [-38.912757544999977, -7.285769676999962],
            [-38.925367869999945, -7.291850543999942],
            [-38.929471650999972, -7.293817907999937],
            [-38.938463575999947, -7.298102121999932],
            [-38.940024501999972, -7.300357850999944],
            [-38.940248170999951, -7.303170373999933],
            [-38.940705027999968, -7.305740191999973],
            [-38.940819241999975, -7.306396922999966],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Xingó - Angelim II C1',
        Concession: 'TAESA - TRANSMISSORA ALIANÇA DE ENERGIA ELÉTRICA S A',
        Tensao: 500.0,
        Extensao: 195.07319,
        Ano_Opera: 2004.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.75368,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.298383698999942, -8.896000300999958],
            [-36.312142404999975, -8.895492365999928],
            [-36.326448679999942, -8.896328624999967],
            [-36.329172258999961, -8.896502228999964],
            [-36.34109151399997, -8.893578586999979],
            [-36.354175917999953, -8.894354494999959],
            [-36.361272696999947, -8.894716707999976],
            [-36.37299225199996, -8.895449097999972],
            [-36.375796732999959, -8.895582394999963],
            [-36.385720499999934, -8.898653832999969],
            [-36.393209021999951, -8.900896028999966],
            [-36.399119808999956, -8.902769304999936],
            [-36.407757285999935, -8.907821150999951],
            [-36.416500638999935, -8.912901114999954],
            [-36.425102912999932, -8.918010075999973],
            [-36.432818490999978, -8.923176124999941],
            [-36.440303873999937, -8.928712330999929],
            [-36.447957547999977, -8.934427905999939],
            [-36.452478779999979, -8.937741750999976],
            [-36.460566349999965, -8.943719659999942],
            [-36.468055812999978, -8.949325561999956],
            [-36.476018360999944, -8.955849809999961],
            [-36.484627461999935, -8.962932680999927],
            [-36.49371836499995, -8.970044652999945],
            [-36.504055309999956, -8.977492852999944],
            [-36.51419698899997, -8.98481955699998],
            [-36.523906663999981, -8.991816744999937],
            [-36.529247877999978, -8.994690403999925],
            [-36.540037444999939, -9.000615159999938],
            [-36.555599285999961, -9.00900428999995],
            [-36.567364022999982, -9.015512525999952],
            [-36.573824137999964, -9.019078293999939],
            [-36.581745665999961, -9.022805540999968],
            [-36.597084514999949, -9.030242535999946],
            [-36.610158067999976, -9.03645359199993],
            [-36.620148511999957, -9.041232583999943],
            [-36.624486914999977, -9.043991560999928],
            [-36.63322756599996, -9.049932971999965],
            [-36.641583475999937, -9.056063965999954],
            [-36.652793872999951, -9.063278652999941],
            [-36.66958531399996, -9.074504957999977],
            [-36.679932417999964, -9.081358704999957],
            [-36.687239746999978, -9.086196951999966],
            [-36.697410208999941, -9.089003263999928],
            [-36.71182429199996, -9.09489495899993],
            [-36.724516200999972, -9.100196048999976],
            [-36.737345584999957, -9.105336966999971],
            [-36.74117623199993, -9.107028028999935],
            [-36.755384903999982, -9.108824457999958],
            [-36.773291871999959, -9.111073742999963],
            [-36.786402252999949, -9.112706446999937],
            [-36.799776966999957, -9.114392435999946],
            [-36.811039388999973, -9.115775950999932],
            [-36.823295793999932, -9.117352621999942],
            [-36.833974008999974, -9.118659626999943],
            [-36.846932227999957, -9.120262341999933],
            [-36.859773209999958, -9.121930328999952],
            [-36.868680406999943, -9.123097917999928],
            [-36.877288023999938, -9.124199325999939],
            [-36.887875098999984, -9.125453039999968],
            [-36.897278566999944, -9.126603940999928],
            [-36.909261349999952, -9.128103032999945],
            [-36.92158227799996, -9.129698021999957],
            [-36.929995491999932, -9.130705047999982],
            [-36.943520090999982, -9.132377693999956],
            [-36.956012274999978, -9.133952276999935],
            [-36.964571992999936, -9.135045131999959],
            [-36.982046867999941, -9.14731365199998],
            [-36.995560341999976, -9.156710159999932],
            [-37.002366196999958, -9.161523949999946],
            [-37.009334912999975, -9.16632139799998],
            [-37.016362350999941, -9.171372669999926],
            [-37.024815283999942, -9.175108886999965],
            [-37.028006445999949, -9.176544089999936],
            [-37.032532114999981, -9.178522939999937],
            [-37.036722, -9.180372377999959],
            [-37.045030420999979, -9.18397092999993],
            [-37.048285272999976, -9.185487765999937],
            [-37.052582365999967, -9.186949388999949],
            [-37.061031140999944, -9.189626876999966],
            [-37.068767000999969, -9.191631108999957],
            [-37.077522674999955, -9.193940657999974],
            [-37.085832763999974, -9.196078007999972],
            [-37.093432679999978, -9.197766932999969],
            [-37.101271170999951, -9.199084657999947],
            [-37.109667481999963, -9.200587802999962],
            [-37.113449939999953, -9.201199717999941],
            [-37.121978016999947, -9.203396980999969],
            [-37.129621369999938, -9.205344869999976],
            [-37.138347587999931, -9.207591718999936],
            [-37.144224942999983, -9.209156552999957],
            [-37.152734258999942, -9.212868569999955],
            [-37.161416525999982, -9.21672213599993],
            [-37.169210646999943, -9.220146087999979],
            [-37.177597254999966, -9.223890986999947],
            [-37.185283301999959, -9.227198807999969],
            [-37.193229183999961, -9.230814613999939],
            [-37.200232519999929, -9.233868407999978],
            [-37.208372187999942, -9.237413920999927],
            [-37.216543976999958, -9.240996956999936],
            [-37.222778926999979, -9.243714],
            [-37.22763482199997, -9.246969828999966],
            [-37.234460031999959, -9.251732712999967],
            [-37.241869161999944, -9.256680277999976],
            [-37.251993938999931, -9.263259237999932],
            [-37.260374651999939, -9.268134506999957],
            [-37.271056255999952, -9.274500287999956],
            [-37.280484528999978, -9.279851676999954],
            [-37.287590587999944, -9.283901083999979],
            [-37.291756490999944, -9.290272161999951],
            [-37.296389589999933, -9.297644841999954],
            [-37.302243625999949, -9.306670609999969],
            [-37.311110184999961, -9.320410782999943],
            [-37.316988719999983, -9.329807910999932],
            [-37.325217934999955, -9.342476632999933],
            [-37.331770228999972, -9.352596894999976],
            [-37.341115533999982, -9.367078431999971],
            [-37.346462170999985, -9.375320207999948],
            [-37.357587811999963, -9.390527399999939],
            [-37.371259186999964, -9.40924971499993],
            [-37.378822843999956, -9.419299441999954],
            [-37.386239475999957, -9.429381158999945],
            [-37.391620536999937, -9.436606590999929],
            [-37.405913856999973, -9.45316128099995],
            [-37.417657307999946, -9.466359795999949],
            [-37.42393862199998, -9.473300722999966],
            [-37.42996450499993, -9.480199852999931],
            [-37.438693421999972, -9.489814238999941],
            [-37.450342706999947, -9.502987252999958],
            [-37.462652222999964, -9.517337782999959],
            [-37.46531445599993, -9.520509879999963],
            [-37.470690398999977, -9.528256390999957],
            [-37.476063164999971, -9.535710121999955],
            [-37.483867804999932, -9.542084910999961],
            [-37.491704862999939, -9.548509612999965],
            [-37.505153693999944, -9.555287322999959],
            [-37.516538074999971, -9.560929291999969],
            [-37.532006231999958, -9.568506012999933],
            [-37.546860079999931, -9.575848375999954],
            [-37.561207259999946, -9.582880004999936],
            [-37.568738653999958, -9.586482300999933],
            [-37.580536292999966, -9.592315145999976],
            [-37.59362394599998, -9.598058074999926],
            [-37.609573313999931, -9.604618900999981],
            [-37.628483152999934, -9.61237652799997],
            [-37.638444486999958, -9.616523672999961],
            [-37.651597651999964, -9.62097442399994],
            [-37.661218223999981, -9.624314793999929],
            [-37.673651351999979, -9.628278011999953],
            [-37.687691427999937, -9.632938469999942],
            [-37.696161117999964, -9.63565813799994],
            [-37.702454004999936, -9.639330864999977],
            [-37.715523132999976, -9.640878078999947],
            [-37.733900178999932, -9.64317965999993],
            [-37.747346608999976, -9.644771854999931],
            [-37.761970208999969, -9.646573501999967],
            [-37.772224609999967, -9.64791298199998],
            [-37.778386136999984, -9.648628342999928],
            [-37.783735597999964, -9.647800717999928],
            [-37.78756058099998, -9.647259390999977],
            [-37.789609244999951, -9.644602247999956],
            [-37.794336095999938, -9.638152003999949],
            [-37.794935056999975, -9.63456864799997],
            [-37.794947984999965, -9.633936501999926],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Paulo Afonso IV - Angelim II C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 222.77847,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 2.01667,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.298383748999981, -8.89571458599994],
            [-36.299626198999931, -8.895598135999933],
            [-36.306377841999961, -8.893611897999961],
            [-36.313775909999947, -8.89334383399995],
            [-36.323406000999967, -8.89252933299997],
            [-36.333708964999971, -8.892015610999977],
            [-36.345454444999973, -8.891477791999932],
            [-36.357579027999975, -8.891164335999974],
            [-36.371089346999952, -8.891128037999977],
            [-36.380695465999963, -8.890971116999935],
            [-36.39517637299997, -8.890900243999965],
            [-36.405347418999952, -8.890735715999938],
            [-36.418214600999931, -8.89065192299995],
            [-36.430601298999932, -8.890441804999966],
            [-36.441207850999945, -8.890317599999946],
            [-36.447806336999975, -8.895050156999957],
            [-36.455330968999931, -8.900417911999966],
            [-36.460715169999958, -8.904225617999941],
            [-36.468199846999937, -8.909528787999932],
            [-36.474245338999935, -8.913760555999943],
            [-36.476272125999969, -8.916457044999959],
            [-36.480783727999949, -8.922356038999965],
            [-36.483545764999974, -8.926136588999952],
            [-36.485678438999969, -8.92885724599995],
            [-36.488045304999957, -8.931948526999975],
            [-36.490781161999962, -8.935524871999974],
            [-36.49906942299998, -8.937831769999946],
            [-36.506948320999982, -8.940025118999927],
            [-36.510127876999945, -8.940981835999935],
            [-36.513950000999955, -8.94319139299995],
            [-36.517604335999977, -8.945425375999946],
            [-36.524276073999943, -8.949457094999957],
            [-36.531389983999929, -8.954310481999926],
            [-36.537665578999963, -8.958765301999961],
            [-36.54649251099994, -8.965010534999976],
            [-36.550088264999943, -8.967584876999979],
            [-36.556715345999976, -8.972280852999972],
            [-36.563489374999961, -8.977036661999932],
            [-36.56854440099994, -8.980630715999951],
            [-36.574418282999943, -8.984845175999965],
            [-36.583247427999936, -8.991081249999979],
            [-36.586585262999961, -8.993426469999974],
            [-36.590607621999936, -8.996202796999967],
            [-36.593781024999942, -8.99845550799995],
            [-36.597659687999965, -9.001178967999977],
            [-36.600753661999931, -9.00336213199995],
            [-36.603322856999966, -9.005218419999949],
            [-36.609015562999957, -9.009248868999975],
            [-36.617134012999941, -9.014972240999953],
            [-36.625922537999941, -9.021208391999949],
            [-36.631344798999976, -9.025032459999977],
            [-36.636871762999931, -9.029057266999928],
            [-36.639715569999964, -9.031078668999953],
            [-36.644869967999966, -9.034560648999957],
            [-36.648449877999951, -9.037133457999971],
            [-36.652021207999951, -9.03971101999997],
            [-36.659466933999965, -9.044890768999949],
            [-36.663042123999958, -9.047447415999955],
            [-36.669709117999957, -9.05218016799995],
            [-36.678150774999949, -9.058164878999946],
            [-36.685913796999955, -9.06370758199995],
            [-36.693311994999931, -9.068825550999975],
            [-36.697212140999966, -9.071657934999962],
            [-36.701050076999934, -9.074308260999942],
            [-36.704526741999985, -9.076827665999929],
            [-36.711902822999946, -9.082035298999926],
            [-36.718532614999958, -9.086757519999935],
            [-36.724228016999973, -9.090709038999933],
            [-36.728868209999973, -9.094009176999975],
            [-36.735740856999939, -9.098928711999974],
            [-36.741872192999949, -9.10325279999995],
            [-36.74464889799998, -9.10543307699993],
            [-36.747015716999954, -9.107333823999966],
            [-36.756764080999972, -9.10851301799994],
            [-36.765181779999978, -9.109563173999959],
            [-36.773915065999972, -9.110706043999926],
            [-36.781644593999943, -9.111690263999947],
            [-36.789109386999939, -9.112619744999961],
            [-36.798064597999939, -9.113696067999967],
            [-36.806177866999974, -9.114708475999976],
            [-36.815099422999936, -9.115888520999931],
            [-36.822882870999933, -9.116875167999979],
            [-36.831657080999946, -9.117975471999955],
            [-36.840411867999933, -9.119004820999976],
            [-36.848590228999967, -9.120039434999967],
            [-36.856858830999954, -9.121101883999927],
            [-36.865634083999964, -9.122249712999974],
            [-36.873042838999936, -9.123171663999926],
            [-36.882631255999968, -9.124326838999934],
            [-36.892712605999975, -9.125598047999972],
            [-36.901381471999969, -9.12670901599995],
            [-36.913974681999946, -9.128291935999925],
            [-36.925539535999974, -9.129755777999947],
            [-36.935921124999936, -9.130998598999952],
            [-36.944493954999984, -9.132083815999977],
            [-36.952279612999973, -9.13304188099994],
            [-36.960627826999939, -9.134070484999938],
            [-36.969168206999939, -9.135169],
            [-36.979059788999962, -9.136391019999962],
            [-36.992637076999983, -9.138148502999968],
            [-37.000315308999973, -9.139080398999965],
            [-37.009811591999949, -9.140240515999949],
            [-37.017079783999975, -9.141210347999959],
            [-37.030654438999932, -9.142833546999952],
            [-37.043452430999935, -9.144460727999956],
            [-37.052422491999948, -9.145595468999943],
            [-37.062797642999953, -9.146954875999938],
            [-37.070512335999979, -9.147854220999932],
            [-37.077751474999957, -9.14874623399993],
            [-37.090105096999935, -9.150308193999933],
            [-37.09285863599996, -9.150626788999944],
            [-37.099976872999946, -9.153368179999973],
            [-37.105549207999957, -9.155454618999954],
            [-37.114386527999955, -9.158594258999926],
            [-37.121779659999959, -9.161269387999937],
            [-37.12815282899993, -9.163682487999949],
            [-37.136095630999932, -9.16653570699998],
            [-37.143164835999983, -9.169025671999975],
            [-37.150697995999963, -9.171817659999931],
            [-37.159225248999974, -9.174960881999937],
            [-37.16643107799996, -9.17753483499996],
            [-37.176885873999936, -9.181429680999941],
            [-37.183215680999979, -9.183664031999967],
            [-37.19253678299998, -9.187076001999969],
            [-37.201157429999967, -9.190234206999946],
            [-37.205686614999934, -9.191935471999955],
            [-37.216459742999973, -9.195898794999948],
            [-37.22601581899994, -9.199539537999954],
            [-37.234669658999962, -9.20282216399994],
            [-37.242010832999938, -9.205669157999978],
            [-37.250766729999953, -9.209003989999928],
            [-37.258087200999967, -9.210100232999935],
            [-37.265384023999957, -9.211272213999962],
            [-37.272465153999974, -9.212387920999959],
            [-37.281235617999982, -9.213783638999928],
            [-37.289842828999952, -9.215178110999943],
            [-37.298153260999982, -9.216475867999975],
            [-37.30861693099996, -9.218152832999976],
            [-37.321015715999977, -9.220127661999982],
            [-37.336308386999974, -9.222536178999974],
            [-37.349972527999967, -9.224570064999966],
            [-37.36342510399993, -9.226654717999963],
            [-37.374983207999946, -9.228531337999925],
            [-37.386283155999934, -9.230305877999967],
            [-37.399531386999968, -9.232380317999969],
            [-37.411886673999959, -9.234328454999968],
            [-37.426570990999949, -9.236621765999928],
            [-37.440149467999959, -9.238769839999975],
            [-37.453703651999945, -9.240894914999956],
            [-37.460662382999942, -9.242017808999947],
            [-37.465027804999977, -9.242633172999945],
            [-37.473907734999955, -9.244012813999973],
            [-37.480205334999937, -9.245040185999926],
            [-37.487023712999985, -9.246067365999977],
            [-37.494383136999943, -9.247231284999941],
            [-37.504465860999971, -9.248831768999935],
            [-37.517675255999961, -9.250892960999977],
            [-37.52602100799993, -9.252163222999968],
            [-37.535014451999984, -9.253585959999953],
            [-37.54227401199995, -9.254713133999928],
            [-37.550116861999982, -9.255910989999961],
            [-37.564523768999948, -9.258326970999974],
            [-37.573573278999959, -9.259698854999954],
            [-37.581242458999952, -9.260881635999965],
            [-37.590743159999931, -9.262416062999932],
            [-37.599308364999956, -9.26377064899998],
            [-37.607692826999937, -9.265064885999948],
            [-37.614997754999933, -9.266206223999973],
            [-37.628010736999954, -9.268217256999947],
            [-37.64021183899996, -9.270158480999953],
            [-37.652361380999935, -9.272071270999959],
            [-37.665352553999981, -9.274134434999951],
            [-37.677443410999956, -9.276041110999927],
            [-37.690868312999953, -9.27813561399995],
            [-37.704565832999947, -9.28025519199997],
            [-37.716944430999945, -9.282164439999974],
            [-37.728916563999974, -9.284050086999969],
            [-37.741362735999985, -9.286010717999943],
            [-37.754066694999949, -9.288001368999971],
            [-37.765856355999972, -9.28983289699994],
            [-37.77686480999995, -9.291500331999941],
            [-37.789106467999943, -9.293469664999975],
            [-37.803002550999963, -9.295616399999972],
            [-37.815297533999967, -9.297549888999981],
            [-37.827562818999979, -9.299423057999945],
            [-37.840377823999972, -9.30136854899996],
            [-37.852534805999937, -9.303319530999943],
            [-37.863267877999931, -9.304980219999948],
            [-37.876337813999953, -9.306975583999929],
            [-37.888186921999932, -9.30883681499995],
            [-37.90191380899995, -9.310979280999959],
            [-37.918868589999931, -9.313634106999928],
            [-37.934419968999975, -9.316017194999972],
            [-37.945122859999969, -9.317693437999935],
            [-37.953878334999956, -9.319079732999967],
            [-37.959048339999981, -9.31999110199996],
            [-37.963731431999975, -9.321568815999967],
            [-37.971324956999979, -9.324122831999944],
            [-37.978493139999955, -9.326544399999932],
            [-37.986428689999968, -9.329208849999929],
            [-37.994603354999981, -9.33202655499997],
            [-38.004022840999937, -9.335177819999956],
            [-38.012604666999948, -9.338114501999939],
            [-38.021217491999948, -9.341050176999943],
            [-38.029060889999982, -9.343673128999967],
            [-38.037579845999971, -9.346549074999928],
            [-38.046003247999977, -9.349413966999975],
            [-38.05449592399998, -9.352269002999947],
            [-38.065551375999974, -9.356007467999973],
            [-38.068765744999951, -9.357119534999981],
            [-38.076803938999944, -9.359824636999974],
            [-38.080851279999933, -9.361180737999973],
            [-38.088740967999968, -9.363855804999957],
            [-38.101971524999954, -9.368280412999979],
            [-38.116149018999977, -9.373032765999938],
            [-38.123520483999982, -9.375597505999963],
            [-38.127804319999939, -9.377050833999931],
            [-38.13540900299995, -9.379581415999951],
            [-38.14317310499996, -9.382262751999974],
            [-38.15365443099995, -9.385744538999973],
            [-38.15847241299997, -9.387365629999977],
            [-38.160599272999946, -9.388989274999972],
            [-38.162536940999985, -9.395351037999944],
            [-38.165588033999938, -9.401059250999936],
            [-38.167275512999936, -9.404055538999955],
            [-38.170546119999983, -9.405116197999973],
            [-38.177623297999958, -9.407317664999937],
            [-38.184596240999952, -9.409330517999933],
            [-38.188312756999949, -9.410569805999955],
            [-38.192615021999984, -9.411896755999976],
            [-38.194839952999985, -9.412541474999955],
            [-38.197273164999956, -9.413304383999957],
            [-38.198302281999929, -9.41348284299994],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Garanhuns II - Luiz Gonzaga C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 240.76129,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 2.17107,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.402509485999929, -8.881206223999925],
            [-36.402438695999933, -8.880766016999928],
            [-36.401800121999941, -8.879042308999942],
            [-36.400699585999973, -8.878742015999933],
            [-36.399670858999968, -8.879816652999978],
            [-36.400706552999964, -8.886710725999933],
            [-36.401136141999984, -8.88828747499997],
            [-36.402390052999976, -8.890314597999975],
            [-36.423958471999981, -8.890125313999931],
            [-36.430634959999963, -8.890039186999957],
            [-36.437242245999983, -8.889943598999935],
            [-36.441367852999974, -8.88988141599998],
            [-36.446402043999967, -8.893512366999971],
            [-36.451613056999975, -8.897194026999955],
            [-36.455926947999956, -8.900310481999952],
            [-36.465393392999943, -8.906990274999941],
            [-36.470139533999941, -8.910324220999939],
            [-36.474520386999984, -8.913470928999971],
            [-36.47799456599995, -8.918031172999974],
            [-36.483452233999969, -8.92514340699995],
            [-36.488778676999971, -8.932145694999974],
            [-36.491056606999962, -8.935100554999963],
            [-36.498505189999946, -8.937189372999967],
            [-36.503427519999946, -8.93862835699997],
            [-36.510170756999969, -8.940539649999948],
            [-36.51786444399994, -8.945001312999977],
            [-36.52456047499993, -8.948990341999945],
            [-36.529426278999949, -8.95238741199995],
            [-36.537726898999949, -8.958254115999978],
            [-36.541447620999975, -8.960929620999934],
            [-36.546321783999929, -8.964356124999938],
            [-36.550635597999928, -8.967409053999972],
            [-36.55620026899993, -8.971348058999979],
            [-36.560250243999974, -8.974203920999969],
            [-36.566735037999933, -8.978803270999947],
            [-36.573773157999938, -8.983816298999955],
            [-36.577749867999955, -8.986619499999961],
            [-36.583557806999977, -8.990743392999946],
            [-36.586870165999983, -8.993073231999972],
            [-36.590626971999939, -8.99569817899993],
            [-36.593923953999933, -8.998061832999952],
            [-36.603935643999932, -9.005078461999972],
            [-36.608122833999971, -9.008098005999955],
            [-36.613034457999959, -9.011525608999932],
            [-36.618559471999959, -9.015408842999932],
            [-36.623707372999945, -9.019089012999927],
            [-36.629702810999959, -9.023344942999927],
            [-36.63704477899995, -9.02866411499997],
            [-36.644958480999946, -9.034145911999929],
            [-36.651555311999971, -9.038826210999957],
            [-36.656027097999981, -9.041966121999963],
            [-36.659944370999938, -9.044749053999965],
            [-36.665204006999943, -9.048463570999957],
            [-36.670245660999967, -9.052079055999968],
            [-36.676337555999964, -9.056376696999962],
            [-36.681065617999934, -9.059713236999926],
            [-36.686300130999939, -9.063389834999953],
            [-36.691463014999954, -9.067026913999939],
            [-36.696749220999948, -9.070790260999956],
            [-36.700848728999972, -9.07365213099996],
            [-36.70510120299997, -9.076660526999945],
            [-36.709744881999939, -9.07992079099995],
            [-36.71392373599997, -9.08290041999993],
            [-36.718676393999942, -9.086205715999938],
            [-36.722741117999931, -9.089107318999936],
            [-36.727890742999932, -9.092784637999955],
            [-36.734484309999971, -9.097491416999958],
            [-36.738774441999965, -9.100531561999958],
            [-36.743385795999984, -9.103808682999954],
            [-36.747865701999956, -9.106965745999958],
            [-36.754061933999935, -9.10774181499994],
            [-36.760891140999945, -9.108584311999948],
            [-36.769169452999961, -9.109658069999966],
            [-36.777674017999971, -9.110723251999957],
            [-36.786383370999943, -9.111820455999975],
            [-36.794516992999945, -9.112829100999932],
            [-36.802978801999984, -9.113918716999933],
            [-36.810585151999931, -9.114877326999931],
            [-36.822292510999944, -9.116361419999976],
            [-36.830635766999933, -9.117380468999954],
            [-36.835962502999962, -9.118047707999949],
            [-36.841505508999944, -9.118737695999926],
            [-36.849748247999969, -9.119782230999931],
            [-36.857743206999942, -9.120794190999959],
            [-36.86354701199997, -9.121497552999926],
            [-36.872625232999951, -9.122676486999978],
            [-36.880545269999971, -9.123666478999951],
            [-36.887230871999975, -9.124532056999954],
            [-36.892636818999961, -9.125190490999955],
            [-36.898352347999946, -9.125901790999933],
            [-36.90410904099997, -9.126613449999979],
            [-36.908581560999949, -9.127172795999968],
            [-36.914427348999936, -9.127921332999961],
            [-36.918698806999942, -9.128471712999954],
            [-36.925130037999963, -9.129250998999964],
            [-36.932560839999951, -9.130188643999929],
            [-36.938503623999964, -9.130922816999941],
            [-36.94379190099994, -9.131620488999943],
            [-36.949598060999961, -9.132299588999956],
            [-36.955526666999958, -9.133055620999926],
            [-36.963673110999935, -9.134070513999973],
            [-36.969893389999982, -9.134871087999954],
            [-36.97621765599996, -9.135729051999931],
            [-36.981761203999952, -9.136403756999925],
            [-36.989795575999949, -9.137371917999928],
            [-36.996156172999974, -9.138191976999963],
            [-37.001894510999932, -9.138860645999955],
            [-37.009263154999985, -9.139825299999927],
            [-37.017703643999937, -9.140833291999968],
            [-37.02563719699998, -9.141836855999941],
            [-37.033554809999941, -9.142809639999939],
            [-37.038987305999967, -9.143485660999943],
            [-37.05014197099996, -9.144964763999951],
            [-37.058651981999958, -9.145947477999925],
            [-37.067171627999983, -9.147031549999951],
            [-37.076151430999971, -9.148141926999926],
            [-37.092855282999949, -9.150239796999927],
            [-37.096173391999969, -9.151523232999978],
            [-37.103727116999949, -9.154287262999958],
            [-37.111159892999979, -9.156954184999961],
            [-37.118598614999939, -9.159713669999974],
            [-37.124603784999977, -9.161872363999976],
            [-37.129222045999938, -9.163583891999963],
            [-37.136655534999932, -9.16623945799995],
            [-37.144034685999941, -9.168934953999951],
            [-37.15145113899996, -9.171677751999937],
            [-37.166662982999981, -9.177167665999946],
            [-37.171683410999947, -9.179039398999976],
            [-37.177181814999983, -9.181022525999936],
            [-37.181556935999936, -9.182625811999969],
            [-37.189020201999938, -9.185340566999969],
            [-37.205759214999944, -9.191475871999955],
            [-37.216815707999956, -9.195614363999937],
            [-37.22166895099997, -9.197453009999947],
            [-37.230059188999974, -9.200664708999966],
            [-37.236070127999938, -9.202960080999958],
            [-37.242276917999959, -9.205281970999977],
            [-37.248228543999971, -9.207512541999961],
            [-37.250990034999973, -9.208600947999969],
            [-37.256917043999977, -9.209493694999935],
            [-37.262602985999933, -9.210426792999954],
            [-37.268008875999953, -9.211284337999928],
            [-37.273151205999966, -9.212082566999982],
            [-37.281361739999966, -9.213371201999962],
            [-37.287226964999945, -9.214281709999966],
            [-37.292561667999962, -9.215160069999968],
            [-37.298565943999961, -9.21611792899995],
            [-37.304765067999938, -9.21710571999995],
            [-37.312457079999945, -9.218300631999966],
            [-37.320546944999933, -9.219571530999929],
            [-37.328451512999948, -9.220794373999979],
            [-37.336556646999952, -9.222061752999934],
            [-37.345609108999952, -9.223462887999972],
            [-37.353871931999947, -9.224750298999936],
            [-37.361688405999985, -9.225970013999927],
            [-37.36989734399998, -9.22727084099995],
            [-37.377805359999968, -9.228497207999965],
            [-37.384905894999974, -9.229629488999933],
            [-37.390024452999967, -9.230441093999957],
            [-37.398493693999967, -9.23179504999996],
            [-37.403791402999957, -9.23256964899997],
            [-37.412092686999983, -9.233855440999946],
            [-37.419928287999937, -9.235150688999965],
            [-37.428230758999973, -9.236403842999948],
            [-37.435802595999974, -9.237606994999965],
            [-37.441152472999931, -9.238455915999964],
            [-37.448349115999974, -9.239586200999952],
            [-37.457172686999968, -9.240993145999937],
            [-37.465139214999965, -9.24224464699995],
            [-37.473730746999934, -9.243569424999976],
            [-37.482824717999961, -9.245007635999968],
            [-37.491206634999969, -9.246311111999944],
            [-37.500444423999966, -9.247746574999951],
            [-37.50799527099997, -9.248940441999935],
            [-37.515363402999981, -9.25012341799993],
            [-37.523304019999955, -9.251417580999941],
            [-37.531598642999938, -9.252624041999979],
            [-37.539589365999973, -9.253900059999978],
            [-37.54563845499996, -9.254856712999981],
            [-37.551763813999969, -9.255794825999942],
            [-37.568813898999963, -9.258535396999946],
            [-37.582760836999967, -9.260750108999957],
            [-37.589362152999968, -9.261761285999967],
            [-37.595055263999939, -9.262663320999934],
            [-37.602420721999977, -9.263825892999932],
            [-37.60862149999997, -9.264816531999941],
            [-37.615739574999964, -9.265903518999949],
            [-37.624384755999984, -9.267230325999947],
            [-37.632954597999969, -9.268567834999942],
            [-37.641169760999958, -9.269853974999933],
            [-37.64913942499993, -9.271107236999967],
            [-37.656566017999978, -9.27228093399998],
            [-37.662458662999938, -9.273239808999961],
            [-37.670985022999957, -9.274531614999944],
            [-37.678568184999961, -9.275761117999934],
            [-37.687011624999968, -9.277053609999939],
            [-37.696162284999957, -9.278493802999947],
            [-37.704284481999935, -9.279742185999964],
            [-37.711570033999976, -9.280861970999979],
            [-37.719380817999934, -9.282087308999962],
            [-37.727885921999984, -9.283413998999947],
            [-37.736799305999966, -9.284859381999979],
            [-37.745143752999979, -9.286118692999935],
            [-37.754035578999947, -9.287516378999953],
            [-37.762251874999947, -9.288796975999958],
            [-37.770641559999945, -9.290093178999939],
            [-37.778918007999948, -9.291398673999936],
            [-37.784172769999941, -9.29220307199995],
            [-37.793084386999965, -9.293590052999946],
            [-37.800037725999971, -9.294753723999975],
            [-37.806494561999955, -9.295672276999937],
            [-37.815160513999956, -9.297027536999963],
            [-37.823709762999954, -9.298372799999981],
            [-37.831284506999964, -9.299542870999971],
            [-37.836190533999968, -9.300316891999955],
            [-37.842509078999967, -9.301285979999932],
            [-37.849970495999969, -9.302469453999947],
            [-37.85823131199993, -9.303738511999939],
            [-37.86574350199993, -9.304906542999959],
            [-37.873789487999943, -9.306212091999953],
            [-37.879756337999936, -9.307051688999934],
            [-37.887650573999963, -9.308353291999936],
            [-37.895845005999945, -9.309589262999964],
            [-37.90436935699995, -9.310924602999933],
            [-37.912746058999971, -9.312223331999974],
            [-37.918476408999936, -9.313076162999948],
            [-37.924155257999985, -9.314001828999949],
            [-37.930231042999935, -9.314923534999934],
            [-37.937956244999953, -9.316118571999937],
            [-37.944061510999973, -9.317066451999949],
            [-37.95156084499996, -9.318250916999943],
            [-37.959055284999977, -9.319546626999966],
            [-37.964623703999962, -9.321416996999972],
            [-37.972436788999971, -9.324076593999962],
            [-37.97839771699995, -9.326100212999961],
            [-37.985166968999977, -9.328367085999957],
            [-37.990456879999954, -9.330169673999933],
            [-37.995622992999984, -9.331913712999949],
            [-38.001644860999932, -9.333940352999946],
            [-38.007230321999941, -9.335830742999974],
            [-38.011905018999983, -9.337419198999953],
            [-38.016984139999977, -9.339142002999949],
            [-38.02258530499995, -9.341034001999958],
            [-38.028532251999934, -9.343039144999977],
            [-38.033045551999976, -9.344591929999979],
            [-38.038320899999974, -9.346346541999935],
            [-38.04379034699997, -9.348251065999932],
            [-38.049052004999965, -9.349988134999933],
            [-38.054860094999981, -9.351939196999979],
            [-38.063227551999944, -9.354789307999965],
            [-38.068655960999934, -9.356622763999951],
            [-38.075900222999962, -9.359048493999978],
            [-38.081287075999967, -9.360866102999978],
            [-38.086547837999944, -9.362658922999969],
            [-38.091315082999984, -9.364289390999943],
            [-38.093951472999947, -9.365137584999957],
            [-38.099221407999948, -9.366914836999968],
            [-38.102253258999951, -9.36799028799993],
            [-38.107789570999955, -9.369863325999972],
            [-38.112832302999948, -9.371540514999936],
            [-38.118643992999978, -9.373515621999957],
            [-38.124503858999958, -9.375507870999968],
            [-38.129760432999944, -9.377236797999956],
            [-38.134999400999959, -9.37902601299993],
            [-38.140061375999949, -9.380728885999929],
            [-38.145004677999964, -9.382427977999953],
            [-38.150005331999978, -9.384065245999977],
            [-38.153044902999966, -9.385156410999969],
            [-38.156392040999947, -9.386235013999965],
            [-38.159091587999967, -9.387166368999942],
            [-38.164712838999947, -9.38905830899995],
            [-38.166407380999942, -9.387638674999948],
            [-38.16826634399996, -9.38322425399997],
            [-38.17068044399997, -9.377354427999933],
            [-38.172300865999944, -9.373373335999929],
            [-38.173812182999939, -9.369536851999953],
            [-38.175010885999939, -9.366482376999954],
            [-38.176094177999971, -9.363501074999931],
            [-38.177567946999943, -9.360267562999979],
            [-38.179377489999979, -9.356128577999925],
            [-38.18113952899995, -9.351925688999927],
            [-38.184789268999964, -9.343183095999962],
            [-38.186173574999941, -9.33965887599993],
            [-38.189105069999982, -9.332491938999965],
            [-38.19100425299996, -9.32808382099995],
            [-38.192464868999934, -9.324325628999929],
            [-38.195552891999967, -9.317028043999926],
            [-38.197166683999967, -9.312837590999948],
            [-38.201197804999936, -9.303217702999973],
            [-38.203212335999979, -9.298297181999942],
            [-38.20557271499996, -9.292613390999975],
            [-38.20613952399998, -9.29118791999997],
            [-38.20695544199998, -9.287516356999959],
            [-38.208308665999937, -9.284627782999962],
            [-38.210283722999975, -9.28050878099998],
            [-38.21217532199995, -9.276431346999971],
            [-38.213738431999957, -9.272683932999939],
            [-38.215036105999957, -9.26958514599994],
            [-38.216553590999979, -9.26588131799997],
            [-38.217931502999932, -9.262616932999947],
            [-38.22052516499997, -9.256190430999936],
            [-38.223609961999955, -9.248737062999965],
            [-38.225079438999956, -9.245054352999944],
            [-38.226912763999962, -9.240775286999963],
            [-38.228850031999968, -9.235926197999959],
            [-38.233100442999955, -9.225415482999949],
            [-38.234733719999952, -9.221538707999969],
            [-38.235304992999943, -9.220213604999969],
            [-38.236444347999964, -9.217363924999972],
            [-38.237816556999974, -9.213989414999958],
            [-38.23962191499993, -9.209490455999969],
            [-38.241945589999943, -9.203797316999953],
            [-38.244800215999931, -9.197055836999937],
            [-38.246668624999984, -9.194433592999928],
            [-38.248576902999957, -9.192587032999938],
            [-38.252345204999983, -9.189027479999936],
            [-38.25718521899995, -9.183736750999969],
            [-38.26122732999994, -9.179320985999937],
            [-38.265982582999982, -9.174753126999974],
            [-38.271567034999975, -9.169912711999928],
            [-38.277265731999933, -9.164816281999947],
            [-38.282816907999972, -9.159878130999971],
            [-38.285053719999951, -9.157879857999944],
            [-38.29097595099995, -9.154397702999972],
            [-38.298121622999929, -9.150090398999964],
            [-38.298891797999943, -9.149591437999959],
            [-38.301364646999957, -9.146167515999934],
            [-38.304111525999929, -9.144706338999981],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Olindina - Luiz Gonzaga C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 256.78179,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 2.24491,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.320345031938821, -10.310613660371555],
            [-38.319785122999974, -10.307310559999962],
            [-38.318714591999935, -10.300498414999936],
            [-38.317858454999964, -10.29500324299994],
            [-38.315728451999973, -10.282039298999962],
            [-38.31471780499993, -10.275776608999934],
            [-38.314099270999975, -10.271471952999946],
            [-38.313191506999942, -10.265986259999977],
            [-38.31269842599994, -10.26253703499998],
            [-38.311752174999981, -10.257058269999959],
            [-38.31109005199994, -10.252989648999971],
            [-38.310453990999974, -10.24892647799993],
            [-38.309540355999957, -10.243046246999938],
            [-38.308668178999937, -10.237925007999934],
            [-38.307879343999957, -10.23227706199998],
            [-38.306764825999949, -10.225990922999927],
            [-38.305823863999933, -10.219934028999944],
            [-38.30479242499996, -10.213801409999974],
            [-38.303715747999945, -10.207495205999976],
            [-38.302815133999957, -10.201730090999945],
            [-38.299025902999972, -10.17921446899993],
            [-38.288959426999952, -10.117768627999965],
            [-38.279275019999943, -10.057477844999937],
            [-38.276485769999965, -10.039479036999978],
            [-38.275387068999976, -10.032762853999941],
            [-38.274885232999964, -10.029723500999978],
            [-38.273441792999961, -10.020364147999942],
            [-38.271641282999951, -10.009042391999969],
            [-38.270783334999976, -10.003803689999927],
            [-38.269897606999962, -9.998011391999967],
            [-38.268365685999981, -9.987961527999971],
            [-38.267080982999971, -9.980292154999972],
            [-38.26623557399995, -9.97498669099997],
            [-38.26229518699995, -9.949869100999933],
            [-38.259837778999952, -9.933823651999944],
            [-38.258507084999962, -9.925827026999968],
            [-38.257269764999933, -9.917870108999978],
            [-38.254366822999941, -9.89920561699995],
            [-38.25226182199998, -9.885561899999971],
            [-38.247552045999953, -9.85568051599995],
            [-38.245857869999952, -9.84486824499993],
            [-38.244173211999964, -9.833932242999936],
            [-38.239461878999975, -9.804341265999938],
            [-38.236482793999983, -9.785372201999962],
            [-38.235635705999982, -9.780099316999951],
            [-38.233559851999985, -9.766460691999953],
            [-38.232465497999954, -9.759243454999933],
            [-38.23039485399994, -9.746092860999966],
            [-38.229171218999966, -9.738459338999974],
            [-38.227871530999948, -9.730233154999951],
            [-38.226220177999949, -9.719592002999946],
            [-38.224942407999947, -9.711468522999951],
            [-38.223671775999946, -9.703259382999931],
            [-38.222836584999982, -9.69535005399996],
            [-38.222015670999951, -9.68731937299998],
            [-38.221244724999963, -9.679688441999929],
            [-38.220687931999976, -9.674077672999942],
            [-38.220131137999942, -9.668895206999935],
            [-38.21913176399994, -9.659029960999931],
            [-38.218335322999963, -9.650985404999972],
            [-38.217746917999932, -9.645731153999975],
            [-38.216883172999985, -9.637429213999951],
            [-38.215498326999978, -9.624216066999963],
            [-38.21436188399997, -9.613084619999938],
            [-38.213456749999978, -9.605049508999969],
            [-38.211938824999947, -9.590143157999933],
            [-38.211336649999964, -9.584676563999949],
            [-38.210001771999941, -9.571106497999949],
            [-38.208850565999967, -9.559127736999926],
            [-38.207783829999983, -9.548746144999939],
            [-38.207162007999955, -9.543049748999977],
            [-38.206649446999961, -9.537511708999943],
            [-38.206050349999941, -9.531792163999967],
            [-38.205601995999984, -9.527110451999931],
            [-38.204979528999957, -9.521613632999959],
            [-38.204489315999979, -9.516324204999933],
            [-38.203826613999979, -9.510401949999959],
            [-38.203266627999938, -9.504589327999952],
            [-38.20272006099998, -9.49912461699995],
            [-38.202167567999936, -9.493993832999934],
            [-38.201672454999937, -9.489021771999944],
            [-38.201125925999975, -9.48341609299996],
            [-38.200518428999942, -9.477471254999955],
            [-38.199999003999949, -9.47224582399997],
            [-38.199438225999984, -9.466725614999973],
            [-38.198810205999962, -9.460997419999956],
            [-38.198297259999947, -9.454854394999927],
            [-38.19774747699995, -9.449959866999961],
            [-38.196645857999954, -9.439845910999964],
            [-38.195957054999951, -9.432058320999943],
            [-38.195344709999972, -9.426920158999962],
            [-38.195045875999938, -9.423947795999936],
            [-38.194580602999963, -9.419105163999973],
            [-38.194220562999931, -9.416116307999971],
            [-38.19518231099994, -9.414484338999955],
            [-38.196175697999934, -9.413421091999965],
            [-38.195823816999962, -9.412538297999959],
            [-38.194665875999931, -9.412193606999949],
            [-38.192742451999948, -9.411542422999958],
            [-38.176611305999984, -9.406371804999935],
            [-38.175182843999949, -9.403685412999948],
            [-38.170626175999985, -9.39467915299997],
            [-38.167128368999954, -9.387873893999938],
            [-38.169983721999984, -9.380913970999927],
            [-38.173006876999978, -9.373953508999932],
            [-38.175665874999936, -9.369126120999965],
            [-38.177350532999981, -9.366156553999929],
            [-38.178578334999941, -9.363520110999957],
            [-38.180643706999945, -9.358818295999981],
            [-38.182337882999946, -9.35395467799998],
            [-38.185288414999945, -9.346340401999953],
            [-38.187734500999966, -9.340239463999978],
            [-38.191246584999931, -9.331778100999941],
            [-38.194235187999936, -9.32457309299997],
            [-38.197166683999967, -9.317453745999956],
            [-38.20085008999996, -9.308449864999943],
            [-38.204390727999964, -9.299912358999961],
            [-38.20788377699995, -9.291422441999941],
            [-38.211443449999933, -9.283379863999926],
            [-38.213975196999968, -9.276688819999947],
            [-38.216687781999951, -9.270016810999948],
            [-38.219505063999975, -9.263230587999942],
            [-38.222569809999982, -9.255778115999931],
            [-38.224444824999978, -9.251171479999925],
            [-38.22792835599995, -9.242767223999977],
            [-38.232090033999953, -9.232595027999935],
            [-38.235266613999954, -9.224885573999927],
            [-38.239847075999933, -9.213797285999931],
            [-38.241786336999951, -9.208978876999936],
            [-38.245855214999949, -9.19908031999995],
            [-38.246916454999962, -9.196562849999964],
            [-38.250104932999932, -9.193107871999928],
            [-38.256310566999957, -9.186426345999962],
            [-38.261516827999969, -9.18073467499994],
            [-38.263429913999971, -9.178716891999954],
            [-38.265837928999929, -9.176565859999926],
            [-38.275431915999945, -9.168085460999976],
            [-38.27846810799997, -9.165344320999964],
            [-38.284197849999941, -9.161204058999942],
            [-38.29137430399993, -9.155997798999977],
            [-38.294543746999977, -9.153161480999927],
            [-38.299711935999937, -9.150049145999958],
            [-38.301796343999968, -9.146708382999975],
            [-38.304398996999964, -9.145211024999981],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Recife II - Goianinha C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 73.42628,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.63941,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.034291788999951, -8.113153116999968],
            [-35.034072959999946, -8.112558069999977],
            [-35.033816398999932, -8.109207432999938],
            [-35.033515975999933, -8.106936923999967],
            [-35.032272159999934, -8.105025291999937],
            [-35.029288104999978, -8.102076835999981],
            [-35.024629253999933, -8.097469711999963],
            [-35.021691008999937, -8.09451891599997],
            [-35.017931460999932, -8.090830750999942],
            [-35.012917132999974, -8.089266215999942],
            [-35.006949443999929, -8.087457825999934],
            [-35.000401167999939, -8.085416247999945],
            [-34.994928406999975, -8.08374110799997],
            [-34.987776936999978, -8.081604351999943],
            [-34.978283430999966, -8.07886741599998],
            [-34.977377983999929, -8.077827205999938],
            [-34.97840184699993, -8.074366082999973],
            [-34.979708082999935, -8.070038103999934],
            [-34.980229078999969, -8.067262115999938],
            [-34.980934083999955, -8.060521057999949],
            [-34.989405644999977, -8.061131411999952],
            [-34.993148929999961, -8.053811950999943],
            [-34.996899199999973, -8.046545998999932],
            [-34.99439771699997, -8.042250642999932],
            [-34.99604917399995, -8.038960027999963],
            [-34.996899744999951, -8.030297794999967],
            [-34.997958438999945, -8.02729551799996],
            [-35.000370739999937, -8.025164210999947],
            [-34.998384056999953, -8.020773230999964],
            [-35.000073983999982, -8.019501981999952],
            [-35.004435919999935, -8.016563620999932],
            [-35.008487733999971, -8.013823563999949],
            [-35.010964566999974, -8.012138095999944],
            [-35.011652898999955, -8.009642484999972],
            [-35.014343663999966, -7.999815241999954],
            [-35.015268760999959, -7.996001174999947],
            [-35.016833520999967, -7.990100536999933],
            [-35.012822330999938, -7.980865714999936],
            [-35.008032883999931, -7.969866048999961],
            [-35.004090652999935, -7.960830266999949],
            [-34.999713679999957, -7.950779739999973],
            [-34.996203393999963, -7.942741698999953],
            [-34.99352287399995, -7.936508991999972],
            [-34.990921989999947, -7.930528924999976],
            [-34.989257358999964, -7.92674625899997],
            [-34.987634272999969, -7.923269688999937],
            [-34.985846483999978, -7.919419116999961],
            [-34.983890007999946, -7.915192601999934],
            [-34.979967466999938, -7.906765443999973],
            [-34.976436346999947, -7.899155926999981],
            [-34.973257386999933, -7.892245972999945],
            [-34.968198652999945, -7.881450357999938],
            [-34.964922134999938, -7.874490434999927],
            [-34.959525516999975, -7.862869146999969],
            [-34.959125767999979, -7.858536148999974],
            [-34.957890827999961, -7.84298161199996],
            [-34.956815310999957, -7.830514425999979],
            [-34.956245806999959, -7.823084241999936],
            [-34.958830297999953, -7.816773117999958],
            [-34.971029001999966, -7.806993532999968],
            [-34.980123386999935, -7.799722534999944],
            [-35.00982604099994, -7.775834927999938],
            [-35.013999614999932, -7.772453713999937],
            [-35.01563192499998, -7.771168804999945],
            [-35.022849623999946, -7.757937811999966],
            [-35.029849600999967, -7.745160700999975],
            [-35.039590003999933, -7.727449103999959],
            [-35.044607394999957, -7.718318020999959],
            [-35.051257988999964, -7.706112575999953],
            [-35.056290707999949, -7.696964023999953],
            [-35.06131533599995, -7.687811427999975],
            [-35.063355723999962, -7.684099467999943],
            [-35.066579893999972, -7.678222199999936],
            [-35.071386404999942, -7.669489577999968],
            [-35.073878889999946, -7.661678996999967],
            [-35.076912702999948, -7.652292022999973],
            [-35.079274317999932, -7.644868104999944],
            [-35.081778700999962, -7.637093215999926],
            [-35.084062982999967, -7.629978627999947],
            [-35.085728605999975, -7.62480329999994],
            [-35.088631547999967, -7.615582888999938],
            [-35.088744572999929, -7.611763853999946],
            [-35.088827853999931, -7.608843064999974],
            [-35.08907174899997, -7.597457343999963],
            [-35.089143132999936, -7.593108878999942],
            [-35.089357283999959, -7.589703275999966],
            [-35.089191755999934, -7.588709827999935],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Aldeia - Pau Ferro C1',
        Concession: 'EPESA - CENTRAIS ELÉTRICAS DE PERNAMBUCO S.A.',
        Tensao: 230.0,
        Extensao: 0.49755,
        Ano_Opera: 2009.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.00435,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.020311965999952, -7.859672173999968],
            [-35.020664998999962, -7.860550709999927],
            [-35.019430058999944, -7.861028981999937],
            [-35.018684097999937, -7.859087341999953],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Jaguarari - Senhor do Bonfim II C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 82.08798,
        Ano_Opera: 2012.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.72934,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.129957698437906, -10.310613660371555],
            [-40.124915877999968, -10.298523182999929],
            [-40.123133612999936, -10.29427611999995],
            [-40.121447147999959, -10.290284933999942],
            [-40.119809879999934, -10.286266181999963],
            [-40.11851444399997, -10.283179755999981],
            [-40.116728397999964, -10.278913799999941],
            [-40.115239747999965, -10.275399326999946],
            [-40.113868563999972, -10.272230773999979],
            [-40.111964995999983, -10.267745489999982],
            [-40.108568901999945, -10.259562131999928],
            [-40.105302848999941, -10.251747771999931],
            [-40.102042642999947, -10.243938981999975],
            [-40.100189431999979, -10.239477160999968],
            [-40.098776832999931, -10.236170041999969],
            [-40.096415217999947, -10.233332534999931],
            [-40.093488080999975, -10.22979750899998],
            [-40.091150659999983, -10.227015065999979],
            [-40.086058613999967, -10.22085821099995],
            [-40.080663185999981, -10.214403922999963],
            [-40.074916787999939, -10.207443998999963],
            [-40.066318057999979, -10.197074866999969],
            [-40.06090771099997, -10.190526030999933],
            [-40.056517604999954, -10.185166295999977],
            [-40.051031130999945, -10.17840362499993],
            [-40.045499244999974, -10.171813757999928],
            [-40.040562582999939, -10.165828401999931],
            [-40.035444525999935, -10.159572755999932],
            [-40.030027523999934, -10.152966305999939],
            [-40.027168483999958, -10.149498325999957],
            [-40.022621690999983, -10.143890171999942],
            [-40.018277990999934, -10.138515862999952],
            [-40.013120145999949, -10.132188301999975],
            [-39.921219955999959, -10.020082172999935],
            [-39.917993406999983, -10.014057377999961],
            [-39.907804104999968, -9.994837295999957],
            [-39.89965252199994, -9.979564710999966],
            [-39.901261037999973, -9.970760705999965],
            [-39.90528708599993, -9.950363965999941],
            [-39.910493345999953, -9.924218447999976],
            [-39.916408844999978, -9.894589002999965],
            [-39.917201204999969, -9.89089132099997],
            [-39.908813604999978, -9.891755064999927],
            [-39.900126192999949, -9.89275443899993],
            [-39.891410227999984, -9.893753811999943],
            [-39.882608600999959, -9.894767462999937],
            [-39.874128201999952, -9.895731144999957],
            [-39.86544792899997, -9.896759071999952],
            [-39.860929331999955, -9.895188626999982],
            [-39.857902657999944, -9.892040600999962],
            [-39.857924072999936, -9.883346049999943],
            [-39.857902657999944, -9.87538675299993],
            [-39.857845550999969, -9.868055633999973],
            [-39.85828337099997, -9.867265652999947],
            [-39.85830240699994, -9.866080680999971],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Juazeiro II - Senhor do Bonfim II, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 150.61474,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.3456,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.130105070260498, -10.310613660371555],
            [-40.125075825999943, -10.298559509999961],
            [-40.12157801799998, -10.290189755999961],
            [-40.116938068999957, -10.279053877999957],
            [-40.11399348599997, -10.272135594999952],
            [-40.11210181499996, -10.267686002999938],
            [-40.108746774999929, -10.259601783999926],
            [-40.105492861999949, -10.251874484999973],
            [-40.102149718999954, -10.243867598999941],
            [-40.098913651999965, -10.236086760999967],
            [-40.096534190999932, -10.233255201999953],
            [-40.091257735999932, -10.226913938999928],
            [-40.086171637999939, -10.220768980999935],
            [-40.080776209999954, -10.21433253899994],
            [-40.074872178999954, -10.207178102999933],
            [-40.06649944399993, -10.197093343999938],
            [-40.061020734999943, -10.190466544999936],
            [-40.056606834999968, -10.185059219999971],
            [-40.051175714999943, -10.178343190999954],
            [-40.048308464999934, -10.174928664999982],
            [-40.043026061999967, -10.168575503999932],
            [-40.038171960999932, -10.162662542999954],
            [-40.033192938999946, -10.156618711999954],
            [-40.027416796999944, -10.149605250999969],
            [-40.022764950999942, -10.143829108999967],
            [-40.018470023999953, -10.138552653999966],
            [-40.01327784199998, -10.132174607999957],
            [-39.921381759999974, -10.019948923999948],
            [-39.918126656999959, -10.013943163999954],
            [-39.908941937999941, -9.996544544999949],
            [-39.904820710999957, -9.988825573999975],
            [-39.899890467999967, -9.979536157999974],
            [-39.903802301999974, -9.958882435999953],
            [-39.916573027999959, -9.894631833999938],
            [-39.919820991999984, -9.878563332999931],
            [-39.93439043199993, -9.869668907999937],
            [-39.951879469999938, -9.85898988699995],
            [-39.958882223999979, -9.85471399599993],
            [-39.966389172999982, -9.850095652999926],
            [-39.977984536999941, -9.843057015999932],
            [-39.989270319999946, -9.836168476999944],
            [-40.027774757999964, -9.812618950999934],
            [-40.038796421999962, -9.80591600899993],
            [-40.060432860999981, -9.792695723999941],
            [-40.08329710199996, -9.778725908999945],
            [-40.09793792499994, -9.769767237999929],
            [-40.113142680999943, -9.76048020199994],
            [-40.127562214999955, -9.75167143699997],
            [-40.138576739999962, -9.744932802999926],
            [-40.153560205999952, -9.735824226999966],
            [-40.180293449999965, -9.719413083999939],
            [-40.195305468999948, -9.710211707999974],
            [-40.206955310999945, -9.703094739999926],
            [-40.221767454999963, -9.694050408999942],
            [-40.235751547999939, -9.685520040999961],
            [-40.254925243999935, -9.673698878999971],
            [-40.265704202999984, -9.667103012999974],
            [-40.277189860999954, -9.660085982999931],
            [-40.288654103999932, -9.653076090999946],
            [-40.300182592999931, -9.646030506999978],
            [-40.311860986999932, -9.638877846999947],
            [-40.323346645999948, -9.63184653999997],
            [-40.334089911999968, -9.625243534999981],
            [-40.345518462999962, -9.61827647299998],
            [-40.357139750999977, -9.611152366999931],
            [-40.36866110099993, -9.604099644999962],
            [-40.371637806999956, -9.602265079999938],
            [-40.385500546999936, -9.585654062999936],
            [-40.393409874999975, -9.576174289999926],
            [-40.399199103999933, -9.569228643999963],
            [-40.407879376999972, -9.558842295999966],
            [-40.416331222999929, -9.548727207999946],
            [-40.421977683999955, -9.541917189999936],
            [-40.430536604999929, -9.531652195999982],
            [-40.436283002999971, -9.524792208999941],
            [-40.44210078499998, -9.517839423999931],
            [-40.447633031999942, -9.511157897999965],
            [-40.453293769999959, -9.504355018999945],
            [-40.459054444999936, -9.497502170999951],
            [-40.464836534999961, -9.490570800999933],
            [-40.470625763999976, -9.483667984999954],
            [-40.472574542999951, -9.481255210999961],
            [-40.474187816999972, -9.480669863999935],
            [-40.475565524999979, -9.481305179999936],
            [-40.476229394999962, -9.481626406999965],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Xingó - Jardim C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 164.7591,
        Ano_Opera: 2000.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.44783,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-37.463241299864642, -10.310613660371555],
            [-37.490913176999982, -10.263974042999962],
            [-37.496300276999932, -10.254865465999956],
            [-37.503000838999981, -10.24351067799995],
            [-37.513993948999939, -10.225027024999974],
            [-37.515849928999955, -10.222209742999951],
            [-37.521275099999968, -10.21402915699997],
            [-37.523261949999949, -10.210959651999929],
            [-37.526498016999938, -10.204784950999965],
            [-37.531183856999974, -10.195989678999979],
            [-37.536277601999984, -10.187367295999934],
            [-37.548385447999976, -10.166927240999939],
            [-37.553418008999984, -10.158492051999929],
            [-37.561091769999962, -10.145405016999973],
            [-37.564411117999953, -10.123954175999927],
            [-37.566029151999942, -10.113282292999941],
            [-37.567373546999931, -10.104454492999935],
            [-37.569580496999947, -10.090136085999973],
            [-37.574087236999958, -10.060542023999972],
            [-37.574943842999971, -10.055116852999959],
            [-37.576549978999935, -10.044575840999926],
            [-37.580464191999965, -10.019270272999961],
            [-37.58225080699998, -10.007048411999961],
            [-37.585496751999983, -9.986160072999951],
            [-37.588268824999943, -9.968171347999942],
            [-37.615012730999979, -9.923087563999957],
            [-37.630444770999929, -9.889173242999959],
            [-37.63490625999998, -9.879381760999934],
            [-37.638724354999965, -9.87096779899997],
            [-37.640753009999969, -9.866516812999976],
            [-37.647111256999949, -9.852544853999973],
            [-37.64992325399993, -9.84652157499994],
            [-37.654782944999965, -9.835908079999967],
            [-37.658972848999952, -9.826728116999959],
            [-37.662867798999969, -9.818166408999957],
            [-37.664640329999941, -9.814267579999978],
            [-37.668736333999959, -9.805321474999971],
            [-37.672748697999964, -9.796475383999962],
            [-37.676296104999949, -9.788640269999973],
            [-37.678337278999948, -9.78417731199994],
            [-37.68831952599993, -9.762304512999947],
            [-37.694990295999958, -9.753747921999945],
            [-37.69730390999996, -9.750772939999933],
            [-37.703215497999963, -9.743210503999933],
            [-37.710850289999939, -9.733362067999963],
            [-37.717726275999951, -9.724686408999958],
            [-37.720999301999939, -9.720499032999953],
            [-37.726778675999981, -9.713075303999972],
            [-37.73388960099993, -9.703967512999952],
            [-37.739931946999945, -9.696153442999957],
            [-37.743253787999947, -9.691944602999968],
            [-37.74963792099993, -9.683694887999934],
            [-37.757344977999935, -9.677416557999948],
            [-37.763027514999976, -9.672743777999926],
            [-37.776357684999937, -9.661777608999955],
            [-37.781231214999934, -9.657705224999972],
            [-37.792013950999944, -9.648852509999926],
            [-37.792786444999933, -9.64602347999994],
            [-37.79602551399995, -9.638818252999954],
            [-37.796144486999935, -9.637485754999943],
            [-37.796358657999974, -9.634869680999941],
            [-37.796209653999938, -9.633860552999977],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Ribeiro Gonçalves - São João do Piauí C2',
        Concession: 'IENNE - INTERLIGAÇÃO ELÉTRICA NORTE E NORDESTE S.A',
        Tensao: 500.0,
        Extensao: 343.59284,
        Ano_Opera: 2010.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 3.11206,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-42.230745966999962, -8.35858525499998],
            [-42.232352196999955, -8.358095514999945],
            [-42.237284834999969, -8.35452261699993],
            [-42.245335050999984, -8.337494171999936],
            [-42.248770791999959, -8.330307594999965],
            [-42.252315064999948, -8.326130708999926],
            [-42.257489067999984, -8.323870694999925],
            [-42.263612298999931, -8.323288468999976],
            [-42.26867933699998, -8.322924434999948],
            [-42.274284026999965, -8.322571559999972],
            [-42.278931432999968, -8.32223186799996],
            [-42.288216087999956, -8.321614863999969],
            [-42.334561688999941, -8.318607322999981],
            [-42.345141801999944, -8.312991172999944],
            [-42.348041247999959, -8.311307233999969],
            [-42.373153964999972, -8.297794027999942],
            [-42.397896916999969, -8.284462941999948],
            [-42.415298521999944, -8.280554311999936],
            [-42.436662795999951, -8.275805488999936],
            [-42.455064759999971, -8.271728354999937],
            [-42.469003352999948, -8.268589450999968],
            [-42.486336119999976, -8.26467413599994],
            [-42.499636493999958, -8.261723736999954],
            [-42.513506501999984, -8.258627357999956],
            [-42.526879036999958, -8.255641029999936],
            [-42.544133300999931, -8.251749177999955],
            [-42.562468216999946, -8.247649630999945],
            [-42.56684783299994, -8.247181008999974],
            [-42.582776346999935, -8.245464793999929],
            [-42.595040351999955, -8.244200526999975],
            [-42.611952905999942, -8.242379085999971],
            [-42.634557796999957, -8.239935483999943],
            [-42.653812967999954, -8.23784539299993],
            [-42.663165862999961, -8.236921828999925],
            [-42.667294344999959, -8.236660007999944],
            [-42.690968451999936, -8.235491971999977],
            [-42.709725638999942, -8.234567477999974],
            [-42.728714238999942, -8.233634105999954],
            [-42.74807399599996, -8.232661176999954],
            [-42.770335327999931, -8.231573085999969],
            [-42.793270888999984, -8.230406832999961],
            [-42.816680358999974, -8.229239099999972],
            [-42.830262015999949, -8.228568158999963],
            [-42.851386972999933, -8.227525944999968],
            [-42.864588247999961, -8.226859014999945],
            [-42.878488542999946, -8.226189989999966],
            [-42.892519040999957, -8.225490713999932],
            [-42.905352173999972, -8.224865835999935],
            [-42.914764164999951, -8.223604350999949],
            [-42.930665382999962, -8.221498596999936],
            [-42.942566436999982, -8.219918550999978],
            [-42.960497682999971, -8.217557921999969],
            [-42.97908599799996, -8.215089860999967],
            [-42.99710695999994, -8.212714247999941],
            [-43.014702012999976, -8.21038830599997],
            [-43.023488545999953, -8.207327060999944],
            [-43.031919196999979, -8.204367647999959],
            [-43.040769860999944, -8.201253691999966],
            [-43.048383350999984, -8.198530560999927],
            [-43.054919156999972, -8.196275268999955],
            [-43.059530870999936, -8.194635142999971],
            [-43.067182049999985, -8.191978146999929],
            [-43.070872723999969, -8.190762651999933],
            [-43.08379810699995, -8.187050089999957],
            [-43.095131400999946, -8.183813250999947],
            [-43.103156432999981, -8.181551913999954],
            [-43.120490629999949, -8.17664941299995],
            [-43.126217193999935, -8.176804635999929],
            [-43.137973790999979, -8.176913519999971],
            [-43.152255931999946, -8.176799371999948],
            [-43.166302798999936, -8.176700332999928],
            [-43.185372276999942, -8.176542563999931],
            [-43.199599679999949, -8.176471692999939],
            [-43.213321391609071, -8.176371826965882],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Campina Grande III - Garanhuns II C1',
        Concession: 'IEG - INTERLIGAÇÃO ELÉTRICA GARANHUNS',
        Tensao: 500.0,
        Extensao: 201.43786,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.74846,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.980669165999984, -7.251460836999968],
            [-35.980852970999933, -7.25035910899993],
            [-35.981953841999939, -7.249698441999954],
            [-35.983357718999969, -7.249582301999965],
            [-35.985713327999974, -7.252921131999926],
            [-35.981779298999982, -7.261780444999943],
            [-35.983730090999984, -7.264507703999925],
            [-35.991961189999984, -7.271615968999981],
            [-35.992164060999983, -7.276696606999963],
            [-35.988394721999953, -7.284346152999944],
            [-35.975842107999938, -7.29361649599997],
            [-35.973678702999962, -7.303556109999931],
            [-35.97023403299994, -7.319291],
            [-35.965721456999972, -7.340012844999933],
            [-35.962385572999949, -7.355363429999954],
            [-35.960004539999943, -7.366283278999958],
            [-35.96599292999997, -7.378515479999976],
            [-35.975306988999932, -7.397587098999963],
            [-35.983122900999945, -7.413052166999933],
            [-35.987992565999946, -7.422540673999947],
            [-35.993145741999967, -7.430982876999963],
            [-36.006850864999933, -7.45364479899996],
            [-36.013405969999951, -7.468989790999956],
            [-36.021188102999929, -7.487206771999979],
            [-36.030091961999972, -7.50801211299995],
            [-36.035735774999978, -7.521257742999978],
            [-36.039117124999962, -7.530057356999976],
            [-36.042266609999956, -7.538303773999928],
            [-36.045743982999966, -7.547410471999967],
            [-36.047368675999962, -7.551640360999954],
            [-36.049162473999957, -7.555538394999928],
            [-36.051399532999937, -7.560375653999927],
            [-36.05515513499995, -7.568447183999979],
            [-36.063313301999983, -7.586327960999938],
            [-36.06882380299993, -7.597683711999935],
            [-36.074294444999964, -7.609194937999973],
            [-36.080160123999974, -7.62154654099993],
            [-36.082310101999951, -7.633870036999951],
            [-36.084498419999932, -7.646477911999966],
            [-36.087208306999969, -7.662036101999945],
            [-36.08967116499997, -7.676144144999967],
            [-36.094013167999947, -7.70106508799995],
            [-36.097052366999947, -7.718638037999938],
            [-36.10555726299998, -7.767763427999967],
            [-36.108300202999942, -7.783687701999952],
            [-36.11077516499995, -7.798681891999934],
            [-36.112453040999981, -7.808621387999949],
            [-36.114863232999937, -7.823144046999971],
            [-36.121800316999952, -7.843207292999978],
            [-36.127246448999983, -7.858828865999953],
            [-36.128730926999935, -7.87307102699998],
            [-36.130764996999972, -7.892871072999981],
            [-36.132910530999936, -7.91331345499998],
            [-36.135133795999934, -7.933553859999961],
            [-36.13635385699996, -7.944381827999962],
            [-36.143595920999928, -7.959733722999943],
            [-36.145800515999952, -7.964425370999948],
            [-36.14494624799994, -7.977734634999933],
            [-36.149615410999957, -7.995748921999962],
            [-36.153895073999934, -8.012342414999978],
            [-36.154791707999948, -8.014986723999925],
            [-36.158935528999962, -8.027156801999979],
            [-36.162384012999951, -8.037462496999979],
            [-36.16854611399998, -8.055657998999948],
            [-36.17450942399995, -8.072740134999947],
            [-36.180000694999933, -8.088417582999966],
            [-36.187369871999977, -8.109240600999954],
            [-36.193930720999958, -8.127511982999977],
            [-36.199952885999949, -8.144155050999927],
            [-36.203592575999949, -8.154365086999974],
            [-36.207195813999931, -8.164446516999931],
            [-36.208356116999937, -8.167726273999961],
            [-36.209491217999982, -8.175282928999934],
            [-36.214004676999934, -8.206060983999976],
            [-36.216480650999983, -8.22283353499995],
            [-36.219769706999955, -8.243921829999977],
            [-36.224251834999961, -8.267887827999971],
            [-36.231475117999935, -8.306199725999932],
            [-36.235780902999977, -8.329301503999943],
            [-36.237220484999966, -8.336536810999974],
            [-36.239638732999936, -8.348223335999933],
            [-36.24172929599996, -8.35822160999993],
            [-36.245044696999969, -8.375087439999959],
            [-36.245945260999974, -8.380237802999943],
            [-36.24778454099993, -8.390036505999944],
            [-36.249171560999969, -8.397523179999951],
            [-36.249864463999984, -8.401532320999934],
            [-36.252447355999948, -8.415912033999973],
            [-36.254240745999937, -8.425958066999954],
            [-36.255842923999978, -8.435051142999953],
            [-36.257615170999941, -8.442564319999974],
            [-36.259444315999929, -8.450434647999941],
            [-36.262562295999942, -8.461315913999954],
            [-36.264684508999949, -8.468630141999938],
            [-36.266314008999984, -8.476530583999931],
            [-36.268456432999983, -8.486782021999943],
            [-36.270508028999984, -8.496436612999958],
            [-36.272637011999961, -8.506802982999943],
            [-36.274537445999954, -8.516065958999945],
            [-36.277724852999938, -8.531148157999951],
            [-36.279835066999965, -8.54128465499997],
            [-36.281781682999963, -8.550481054999977],
            [-36.284890627999971, -8.562843665999935],
            [-36.28853055299993, -8.576991526999961],
            [-36.291112093999971, -8.587155242999927],
            [-36.293699673999981, -8.596989391999955],
            [-36.292471773999978, -8.606104583999979],
            [-36.292010174999973, -8.609369255999979],
            [-36.293784277999976, -8.618406173999972],
            [-36.295667598999955, -8.627950982999948],
            [-36.298411572999953, -8.641940323999961],
            [-36.299342113999955, -8.646516420999944],
            [-36.300701844999935, -8.652498246999926],
            [-36.302472421999937, -8.659452231999978],
            [-36.302152902999978, -8.668660167999974],
            [-36.30165681099993, -8.682914266999944],
            [-36.301329868999971, -8.693180010999981],
            [-36.300965924999957, -8.703720417999932],
            [-36.300757965999935, -8.709162250999952],
            [-36.300449243999935, -8.719068660999937],
            [-36.300150844999962, -8.728851579999969],
            [-36.300623194999957, -8.733799902999976],
            [-36.301031796999951, -8.737728514999958],
            [-36.301580341999966, -8.742826831999935],
            [-36.302656830999979, -8.753506473999948],
            [-36.303680558999929, -8.763209446999952],
            [-36.304677285999958, -8.772985496999979],
            [-36.305684219999932, -8.782889565999938],
            [-36.306168371999945, -8.786999038999966],
            [-36.315327726999953, -8.797061594999946],
            [-36.321838884999977, -8.804087271999947],
            [-36.328436673999931, -8.81128431999997],
            [-36.33576198999998, -8.819204751999962],
            [-36.342910984999946, -8.826473262999968],
            [-36.356812246999937, -8.840197433999947],
            [-36.391240714999981, -8.871092371999964],
            [-36.403261001999965, -8.878002527999968],
            [-36.403466804999937, -8.88120915899998],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Curral Novo do Piauí II - São João do Piauí C1',
        Concession: 'IRACEMA - IRACEMA TRANSMISSORA DE ENRGIA S.A',
        Tensao: 500.0,
        Extensao: 180.9523,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.63964,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-42.228068664999967, -8.359361766999939],
            [-42.226621998999974, -8.359732515999951],
            [-42.20942888299993, -8.361094843999979],
            [-42.195873368999969, -8.362143699999933],
            [-42.180142011999976, -8.356687394999938],
            [-42.153582313999948, -8.347038679999969],
            [-42.131357715999968, -8.338910837999947],
            [-42.114602326999943, -8.332896179999977],
            [-42.097079144999952, -8.329588014999956],
            [-42.088701406999974, -8.328055750999965],
            [-42.069859110999971, -8.324680753999928],
            [-42.060079525999981, -8.323407741999972],
            [-42.043185352999956, -8.321147253999925],
            [-42.017363197999941, -8.317663611999933],
            [-41.876677527999959, -8.29904304799993],
            [-41.831807916999935, -8.29306663999995],
            [-41.824241230999974, -8.290223183999956],
            [-41.806276300999968, -8.283429822999949],
            [-41.790238732999967, -8.277314607999926],
            [-41.773725273999958, -8.271163701999967],
            [-41.765623208999955, -8.270604527999978],
            [-41.747777251999935, -8.26962894899998],
            [-41.729157968999971, -8.268570088999979],
            [-41.710074691999978, -8.26746363999996],
            [-41.690955722999945, -8.266404779999959],
            [-41.666875576999985, -8.265000897999926],
            [-41.647578148999969, -8.263906345999942],
            [-41.628780406999965, -8.262787998999954],
            [-41.614218104999964, -8.261967084999981],
            [-41.595313287999943, -8.26087253299994],
            [-41.576063447999957, -8.259777980999957],
            [-41.557682111999952, -8.258707222999931],
            [-41.541561263999938, -8.257803027999955],
            [-41.535077231999935, -8.256077918999949],
            [-41.52651117299996, -8.25380553399998],
            [-41.517921318999981, -8.251878169999941],
            [-41.499254446999942, -8.247702215999936],
            [-41.480313936999949, -8.243347802999949],
            [-41.462836795999976, -8.239338410999949],
            [-41.446216260999961, -8.235424196999929],
            [-41.435294534999969, -8.23116496199998],
            [-41.423451762999946, -8.226430102999927],
            [-41.323709710999935, -8.186526273999959],
            [-41.319962059999966, -8.185169980999945],
            [-41.308171830999981, -8.18141043299994],
            [-41.281657190999965, -8.17317286399998],
            [-41.230030330999966, -8.157116135999956],
            [-41.226104220999957, -8.155188771999974],
            [-41.209281430999965, -8.146301484999981],
            [-41.192589512999973, -8.137521273999937],
            [-41.17190009899997, -8.126623342999949],
            [-41.156397910999942, -8.118497483999931],
            [-41.152519388999963, -8.117985898999962],
            [-41.130437990999951, -8.115106751999974],
            [-41.11315120699993, -8.11291764799995],
            [-41.096542568999951, -8.110764234999976],
            [-41.07739980499997, -8.108313389999978],
            [-41.060005944999943, -8.106076696999935],
            [-41.043587663999972, -8.104006565999953],
            [-41.02764527599993, -8.102031612999951],
            [-41.010322798999937, -8.09843862699995],
            [-41.001221360999978, -8.096808695999925],
            [-40.992560122999976, -8.094869435999954],
            [-40.973893250999936, -8.090883838999957],
            [-40.959699766999961, -8.087814333999972],
            [-40.937118681999948, -8.082948335999959],
            [-40.918951496999966, -8.079034122999929],
            [-40.902568907999978, -8.075488725999946],
            [-40.885329712999976, -8.071764868999935],
            [-40.869292145999964, -8.068326547999959],
            [-40.856490644999951, -8.067612709999935],
            [-40.83763341699995, -8.066637130999936],
            [-40.823535110999956, -8.065875702999961],
            [-40.806105558999946, -8.065007199999968],
            [-40.789041168999972, -8.06410842799994],
            [-40.716585688999942, -8.060259530999929],
            [-40.685927326999945, -8.051436317999958],
            [-40.661894501999939, -8.043405837999956],
            [-40.658327121999946, -8.042322063999961],
            [-40.654495389999965, -8.041609864999941],
            [-40.640988929999935, -8.039273819999949],
            [-40.641688614999964, -8.034675773999936],
            [-40.640086292999968, -8.034353908999947],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Angelim II - Garanhuns II C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 13.33182,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.12043,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.298401960999968, -8.895367669999928],
            [-36.299613404999945, -8.895255319999933],
            [-36.302019961999974, -8.894352523999942],
            [-36.306309004999946, -8.893259683999929],
            [-36.316779533999977, -8.892750847999935],
            [-36.323325646999933, -8.892085400999974],
            [-36.337518327999931, -8.891410270999927],
            [-36.34544574499995, -8.891066321999972],
            [-36.357242234999944, -8.890891271999976],
            [-36.369380336999939, -8.890790115999948],
            [-36.385205523999957, -8.890611928999931],
            [-36.394532672999958, -8.890464714999951],
            [-36.40133543099995, -8.890328090999958],
            [-36.400663956999949, -8.888461790999941],
            [-36.400240564999933, -8.886835898999948],
            [-36.399262749999934, -8.879691786999956],
            [-36.400543010999968, -8.878231286999949],
            [-36.402135742999974, -8.878664538999942],
            [-36.402810325999951, -8.880794936999962],
            [-36.402850649999948, -8.881208267999966],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Campina Grande III - Extremoz II, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 199.02661,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.7291,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.548003481151603, -6.289108792304777],
            [-35.548809481999967, -6.290577827999925],
            [-35.552671386999975, -6.297582817999967],
            [-35.557396936999965, -6.306198506999976],
            [-35.564137962999951, -6.318452330999946],
            [-35.568812228999946, -6.326895642999943],
            [-35.570695949999958, -6.330433994999964],
            [-35.571521344999951, -6.333850279999979],
            [-35.57227648199995, -6.336939505999965],
            [-35.573439066999981, -6.341728600999943],
            [-35.574427042999957, -6.345744654999976],
            [-35.576601083999947, -6.354574642999978],
            [-35.579028269999981, -6.364578646999973],
            [-35.581562426999938, -6.374983877999966],
            [-35.583847894999963, -6.384340059999943],
            [-35.585042534999957, -6.389202581999939],
            [-35.586251229999959, -6.394161899999972],
            [-35.587476930999969, -6.399132422999969],
            [-35.588621447999969, -6.403928117999953],
            [-35.58983362999993, -6.408877823999944],
            [-35.590920367999956, -6.413365002999967],
            [-35.592189584999971, -6.418561122999961],
            [-35.593299396999953, -6.423084130999939],
            [-35.59558956099994, -6.432537796999952],
            [-35.597857258999966, -6.441788319999944],
            [-35.598827476999929, -6.445815567999944],
            [-35.600070646999939, -6.450870404999932],
            [-35.601199582999982, -6.455511415999979],
            [-35.603499687999943, -6.464947024999958],
            [-35.60601249299998, -6.475169518999962],
            [-35.607025564999958, -6.479416133999962],
            [-35.614670450999938, -6.494007615999976],
            [-35.618015499999956, -6.50037582899995],
            [-35.621534980999968, -6.507139000999928],
            [-35.623998038999957, -6.511821706999967],
            [-35.628870928999959, -6.521086686999979],
            [-35.632968231999939, -6.529002360999925],
            [-35.636809442999947, -6.536294236999936],
            [-35.64141230599995, -6.545102698999926],
            [-35.646089596999957, -6.554028251999966],
            [-35.650267431999964, -6.561955957999942],
            [-35.654126733999931, -6.569159694999939],
            [-35.657497176999982, -6.575830766999957],
            [-35.659722493999936, -6.579682835999961],
            [-35.662604795999982, -6.58285843799996],
            [-35.664053612999965, -6.587930140999958],
            [-35.666788168999972, -6.593653253999946],
            [-35.671405051999955, -6.602422378999961],
            [-35.673539432999974, -6.606485908999957],
            [-35.677697397999964, -6.614429407999978],
            [-35.681931894999934, -6.622509751999928],
            [-35.685484580999969, -6.629302296999981],
            [-35.688084169999968, -6.634310394999943],
            [-35.692501396999944, -6.642720276999967],
            [-35.69721930299994, -6.651635685999963],
            [-35.70223496899996, -6.66129110299994],
            [-35.706315593999932, -6.669058848999953],
            [-35.708896357999947, -6.674032616999966],
            [-35.714527790999966, -6.684774818999927],
            [-35.718927805999954, -6.693082108999931],
            [-35.724250941999969, -6.70326376099996],
            [-35.729240029999971, -6.712851348999948],
            [-35.731868584999972, -6.717693256999951],
            [-35.738587716999973, -6.730705719999946],
            [-35.742628433999982, -6.738438425999959],
            [-35.746075115999929, -6.745053226999971],
            [-35.75030891199998, -6.753104759999928],
            [-35.752173004999975, -6.756524107999951],
            [-35.754952977999949, -6.761847646999968],
            [-35.758857658999943, -6.769350189999955],
            [-35.76270806499997, -6.776662704999978],
            [-35.764935562999938, -6.780937969999968],
            [-35.769743433999963, -6.790177798999935],
            [-35.772185197999931, -6.794733981999968],
            [-35.776674360999948, -6.803561111999954],
            [-35.779284908999955, -6.808262982999963],
            [-35.782078832999957, -6.813613759999953],
            [-35.788740428999972, -6.826307812999971],
            [-35.793282949999934, -6.834979404999956],
            [-35.798670838999954, -6.84521862899993],
            [-35.802668787999949, -6.852975668999932],
            [-35.805080176999979, -6.857514347999938],
            [-35.807395737999968, -6.861927684999955],
            [-35.812290791999942, -6.871278727999936],
            [-35.816419573999951, -6.879143831999954],
            [-35.82053111099998, -6.886947070999952],
            [-35.824487568999984, -6.894532916999935],
            [-35.82833207699997, -6.901883991999966],
            [-35.832263908999948, -6.90941139499995],
            [-35.83494878099998, -6.914583270999969],
            [-35.836902988999952, -6.918311441999947],
            [-35.83890979399996, -6.922099878999973],
            [-35.843070075999947, -6.930054372999962],
            [-35.847458846999984, -6.938447506999978],
            [-35.851446780999936, -6.946018127999935],
            [-35.854340354999977, -6.951544737999939],
            [-35.857919692999928, -6.958407049999948],
            [-35.861470267999948, -6.965168672999937],
            [-35.866813228999945, -6.975282052999944],
            [-35.86870765499998, -6.978908077999961],
            [-35.871032663999983, -6.983351038999956],
            [-35.873739723999961, -6.988507838999965],
            [-35.876878227999953, -6.994471579999981],
            [-35.881870491999962, -7.003956657999936],
            [-35.883975317999955, -7.007956946999968],
            [-35.886635743999932, -7.013040331999946],
            [-35.88926896199996, -7.018073023999932],
            [-35.892987137999967, -7.02515858299995],
            [-35.894838904999972, -7.028622073999941],
            [-35.894381752999948, -7.032148832999951],
            [-35.893798223999966, -7.036373506999951],
            [-35.893260084999952, -7.04053960899995],
            [-35.891764223999985, -7.051775610999925],
            [-35.890769043999967, -7.059513988999981],
            [-35.890102782999975, -7.064980921999961],
            [-35.888896018999958, -7.074310170999979],
            [-35.888090070999965, -7.080756385999962],
            [-35.887777147999941, -7.083233762999953],
            [-35.88931056499996, -7.087791042999982],
            [-35.892113789999939, -7.096147641999949],
            [-35.89346636199997, -7.100140285999942],
            [-35.894888063999929, -7.104323101999967],
            [-35.896190563999937, -7.108291315999963],
            [-35.897664274999954, -7.112654704999954],
            [-35.899570142999949, -7.118327097999952],
            [-35.901001319999978, -7.122536503999982],
            [-35.902529753999943, -7.127152488999968],
            [-35.903319807999935, -7.129545329999928],
            [-35.906699162999985, -7.139440158999946],
            [-35.908067202999973, -7.143489579999937],
            [-35.909368674999939, -7.14725178499998],
            [-35.911716512999931, -7.154200509999953],
            [-35.912744133999979, -7.15710813499993],
            [-35.91386952199997, -7.160462355999925],
            [-35.915393688999984, -7.164880806999975],
            [-35.916988916999969, -7.169553314999973],
            [-35.918407993999949, -7.173661895999942],
            [-35.919487981999964, -7.176851871999929],
            [-35.921171069999957, -7.181732097999941],
            [-35.922537260999945, -7.185732801999961],
            [-35.923400984999944, -7.188193069999954],
            [-35.924901578999936, -7.192660855999975],
            [-35.926148074999958, -7.196235293999962],
            [-35.928268377999984, -7.198840527999948],
            [-35.931414429999961, -7.20277363699995],
            [-35.936242554999978, -7.208675213999925],
            [-35.937499947999981, -7.211589367999977],
            [-35.938983715999939, -7.216052746999935],
            [-35.940700511999978, -7.221202561999974],
            [-35.941606560999958, -7.223833715999945],
            [-35.943279918999963, -7.228828097999951],
            [-35.945033311999964, -7.234131862999959],
            [-35.946822846999964, -7.239450181999928],
            [-35.947400465999976, -7.241199768999934],
            [-35.946588417999976, -7.24394108499996],
            [-35.94514907599995, -7.24863473299996],
            [-35.944247707999978, -7.251684643999965],
            [-35.943365128999972, -7.254580661999967],
            [-35.942164018999961, -7.258341706999943],
            [-35.94190047799998, -7.259524299999953],
            [-35.942498372999978, -7.260002602999975],
            [-35.945735241999955, -7.260063227999979],
            [-35.951180008999984, -7.260162794999928],
            [-35.960420471999953, -7.260347536999973],
            [-35.969400767999957, -7.260539026999936],
            [-35.974638683999956, -7.26053636499995],
            [-35.977638368999976, -7.25762623199995],
            [-35.979335269999979, -7.255940963999933],
            [-35.979590971999983, -7.254723720999948],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Campina Grande III - Extremoz II, C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 199.04365,
        Ano_Opera: 2019.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.72925,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.979720915999962, -7.254739646999951],
            [-35.979405944999939, -7.256039757999929],
            [-35.974695711999971, -7.260636954999939],
            [-35.96941512799998, -7.260665392999954],
            [-35.960375210999985, -7.260480535999932],
            [-35.945751504999976, -7.260182102999977],
            [-35.942484774999969, -7.260104168999931],
            [-35.941794603999938, -7.259558780999953],
            [-35.942062322999959, -7.258330645999933],
            [-35.943236820999971, -7.254516819999935],
            [-35.945050338999977, -7.248519964999957],
            [-35.947275980999962, -7.241175668999972],
            [-35.944918586999961, -7.23416650799993],
            [-35.941495466999982, -7.223843373999955],
            [-35.937384446999943, -7.211645533999956],
            [-35.93613346799998, -7.208722885999975],
            [-35.931310899999971, -7.202805031999958],
            [-35.926032998999972, -7.196343694999939],
            [-35.918322177999983, -7.173741548999942],
            [-35.913774329999967, -7.160462462999931],
            [-35.906600049999952, -7.139507135999963],
            [-35.903225323999948, -7.129596997999954],
            [-35.899490010999955, -7.118465666999953],
            [-35.894780834999949, -7.104391016999955],
            [-35.887661914999967, -7.083226543999956],
            [-35.887974516999975, -7.080750974999944],
            [-35.889543077999974, -7.068553059999942],
            [-35.890660016999959, -7.059468248999963],
            [-35.891636023999979, -7.051781188999939],
            [-35.892479176999984, -7.045487623999975],
            [-35.893684834999931, -7.036341474999972],
            [-35.894698582999979, -7.028632871999946],
            [-35.883914605999962, -7.008132456999931],
            [-35.88179564099994, -7.004017312999963],
            [-35.879109367999945, -6.998937722999926],
            [-35.873070009999935, -6.987436093999975],
            [-35.868618463999951, -6.978948639999942],
            [-35.864801057999955, -6.971703675999947],
            [-35.85958390299993, -6.961832679999929],
            [-35.85135687099995, -6.946068345999947],
            [-35.842982806999942, -6.930106721999948],
            [-35.84078934799993, -6.92589428499997],
            [-35.832201448999967, -6.90952327399998],
            [-35.826632534999931, -6.898887956999943],
            [-35.822248888999979, -6.890491374999954],
            [-35.816303959999971, -6.879179253999951],
            [-35.809993061999933, -6.867161704999944],
            [-35.807283818999963, -6.861958258999948],
            [-35.802562834999947, -6.853077309999946],
            [-35.793143519999944, -6.835043698999925],
            [-35.788628959999983, -6.826405861999945],
            [-35.776504766999949, -6.803560948999973],
            [-35.76962735799998, -6.790251429999955],
            [-35.760832637999954, -6.773468092999963],
            [-35.750194456999964, -6.753186353999979],
            [-35.744763696999939, -6.742866739999954],
            [-35.738458642999944, -6.730769331999966],
            [-35.731707648999929, -6.717768518999947],
            [-35.724112408999929, -6.703358497999943],
            [-35.708779138999944, -6.674139716999946],
            [-35.695020085999943, -6.647858672999973],
            [-35.690108990999931, -6.638472362999948],
            [-35.681810076999966, -6.622602853999979],
            [-35.675073232999978, -6.609699135999961],
            [-35.668995304999953, -6.59815744399998],
            [-35.66669785299996, -6.593721808999931],
            [-35.663926311999944, -6.588019506999956],
            [-35.662472893999961, -6.582928324999955],
            [-35.65959670899997, -6.57976879499995],
            [-35.657343644999969, -6.575848553999947],
            [-35.653412827999944, -6.568077092999943],
            [-35.65013162799994, -6.562020846999928],
            [-35.643559728999946, -6.54950377199998],
            [-35.636678525999969, -6.53635326899996],
            [-35.630634907999934, -6.524832986999968],
            [-35.626346793999971, -6.516636911999967],
            [-35.619580037999981, -6.503704993999975],
            [-35.616252832999976, -6.497390996999968],
            [-35.606881232999967, -6.479462316999957],
            [-35.603370945999984, -6.46494617299993],
            [-35.601079831999982, -6.45549988199997],
            [-35.597748257999967, -6.441783465999947],
            [-35.595478446999948, -6.432516041999975],
            [-35.590795052999965, -6.413370405999956],
            [-35.588479931999984, -6.403916799999934],
            [-35.586137791999931, -6.394154890999971],
            [-35.583722084999977, -6.384313478999957],
            [-35.581459267999946, -6.374998610999967],
            [-35.578938640999979, -6.364559493999934],
            [-35.57648648199995, -6.354608905999953],
            [-35.57429403499998, -6.345733803999963],
            [-35.571404314999938, -6.33384624599995],
            [-35.570591906999937, -6.33047961699998],
            [-35.568718007999962, -6.32701911099997],
            [-35.564039498999932, -6.318528813999933],
            [-35.554999178999935, -6.302037072999951],
            [-35.547890652664975, -6.289108792304777],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Campina Grande II - Paraíso C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 123.12441,
        Ano_Opera: 2004.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.06338,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.014850556070577, -6.289108792304777],
            [-36.014203296999938, -6.291894266999975],
            [-36.012126834999947, -6.294585807999965],
            [-36.010120625999946, -6.297184541999968],
            [-36.008980754999982, -6.300767171999951],
            [-36.006520077999937, -6.31013708699993],
            [-36.00441402499996, -6.318214075999947],
            [-36.001833088999945, -6.328087619999962],
            [-35.999285393999969, -6.337804702999961],
            [-35.997476434999953, -6.344678129999977],
            [-35.995440858999984, -6.352541391999978],
            [-35.993503354999973, -6.359956477999958],
            [-35.991465139999946, -6.367691099999945],
            [-35.989999954999973, -6.373304959999928],
            [-35.987249061999933, -6.383842064999953],
            [-35.986006390999933, -6.39307397999994],
            [-35.984550221999939, -6.403688773999932],
            [-35.983561603999931, -6.411059694999949],
            [-35.982629155999973, -6.417894020999938],
            [-35.981825076999939, -6.423753038999962],
            [-35.98006265999993, -6.43665525199998],
            [-35.978735425999957, -6.446381721999956],
            [-35.977580596999928, -6.454831985999931],
            [-35.976467242999945, -6.463097576999928],
            [-35.975608486999931, -6.46925848099994],
            [-35.974629615999959, -6.476370395999936],
            [-35.973619131999953, -6.484335448999957],
            [-35.972272080999971, -6.49394584199996],
            [-35.970823128999939, -6.504663077999965],
            [-35.968948706999981, -6.518482497999969],
            [-35.967637447999948, -6.528264516999968],
            [-35.965980732999981, -6.540362013999925],
            [-35.96476469299995, -6.549326508999968],
            [-35.963060092999967, -6.56188605899996],
            [-35.961735875999977, -6.572160587999974],
            [-35.960679303999939, -6.579828418999966],
            [-35.959104392999961, -6.591460126999948],
            [-35.957966230999943, -6.599922004999939],
            [-35.956707091999931, -6.609151813999972],
            [-35.955770831999985, -6.615822733999948],
            [-35.955193291999933, -6.62032437299996],
            [-35.954155716999935, -6.627921921999928],
            [-35.953549280999937, -6.63270943699996],
            [-35.95243159499995, -6.640542730999925],
            [-35.951690481999947, -6.646200376999957],
            [-35.951143300999945, -6.650280943999974],
            [-35.950259089999975, -6.656694192999964],
            [-35.949092864999955, -6.664668128999949],
            [-35.947663821999981, -6.67415056599998],
            [-35.947157257999947, -6.67775854599995],
            [-35.944949134999945, -6.692297384999961],
            [-35.943362370999978, -6.702544460999945],
            [-35.942207756999949, -6.709897349999949],
            [-35.941172981999955, -6.716661746999932],
            [-35.924568104999935, -6.825184037999975],
            [-35.923483038999962, -6.832155872999977],
            [-35.922524659999965, -6.838478217999977],
            [-35.921253920999959, -6.846581062999974],
            [-35.919898129999979, -6.854962470999965],
            [-35.918860330999962, -6.861822020999966],
            [-35.91754747899995, -6.870473894999975],
            [-35.916540270999974, -6.876981075999936],
            [-35.915390717999969, -6.88461375199995],
            [-35.914675785999975, -6.889597812999966],
            [-35.913112052999963, -6.900365981999926],
            [-35.911861601999931, -6.908936410999956],
            [-35.909690756999964, -6.923796019999941],
            [-35.908191003999946, -6.934226355999954],
            [-35.90468524299996, -6.958927206999931],
            [-35.90346429899995, -6.967913239999973],
            [-35.902546342999983, -6.97525786999995],
            [-35.902134524999951, -6.978251211999975],
            [-35.899386187999937, -6.998631364999937],
            [-35.89856608499997, -7.004815135999934],
            [-35.897136071999967, -7.015621996999926],
            [-35.896222228999932, -7.022523884999941],
            [-35.895838688999959, -7.025456297999938],
            [-35.895392431999937, -7.028928229999963],
            [-35.894907301999979, -7.032607853999934],
            [-35.894363803999966, -7.03663499399994],
            [-35.893152837999935, -7.046038396999961],
            [-35.892050848999929, -7.054091860999961],
            [-35.891286109999953, -7.060309796999945],
            [-35.890329064999946, -7.067925824999975],
            [-35.889450905999979, -7.074853491999932],
            [-35.888746260999937, -7.080665017999934],
            [-35.888404898999966, -7.083188348999954],
            [-35.889995349999936, -7.08782983499998],
            [-35.890978366999946, -7.090773110999976],
            [-35.892825584999969, -7.09632066599994],
            [-35.894449485999985, -7.101076217999946],
            [-35.896509029999947, -7.107241177999981],
            [-35.897817613999962, -7.111170440999956],
            [-35.901457015999938, -7.121933798999976],
            [-35.903912329999969, -7.12921393299996],
            [-35.907127995999929, -7.138718646999962],
            [-35.909902263999982, -7.14682999799993],
            [-35.911136350999982, -7.15048715699993],
            [-35.914813245999937, -7.161174283999969],
            [-35.917439727999977, -7.168827779999958],
            [-35.91884837799995, -7.172988744999941],
            [-35.920124718999944, -7.176640923999969],
            [-35.921763302999977, -7.181438176999961],
            [-35.923135276999972, -7.18543008499995],
            [-35.926984571999981, -7.196588755999926],
            [-35.927435762999949, -7.199618778999934],
            [-35.928063203999955, -7.203971979999949],
            [-35.928669089999971, -7.208071440999959],
            [-35.929458505999946, -7.212911482999971],
            [-35.930543459999967, -7.22001765899995],
            [-35.926641495999945, -7.229318848999981],
            [-35.924884685999984, -7.233646289999967],
            [-35.922842844999934, -7.238665907999973],
            [-35.919452104999948, -7.246891304999963],
            [-35.918053573999941, -7.250267578999967],
            [-35.915267431999951, -7.257100481999942],
            [-35.914775439999971, -7.259433824999974],
            [-35.913741664999975, -7.261794228999975],
            [-35.912586786999952, -7.264668079999979],
            [-35.909377788999961, -7.265180197999939],
            [-35.908897975999935, -7.265933998999969],
            [-35.908939047999979, -7.266479082999979],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Campina Grande II - Paraíso C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 123.06459,
        Ano_Opera: 2009.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.06286,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.014729142879368, -6.289108792304777],
            [-36.01410414399993, -6.291836927999952],
            [-36.010014947999935, -6.297124527999927],
            [-36.008835802999954, -6.300738467999963],
            [-36.007940836999978, -6.304294127999981],
            [-36.006421736999982, -6.310092735999945],
            [-36.004301325999961, -6.318185268999969],
            [-36.001734423999949, -6.328039937999961],
            [-35.998180966999939, -6.34160383699998],
            [-35.995327234999934, -6.352540576999957],
            [-35.991386088999946, -6.367666802999963],
            [-35.988383485999975, -6.378923705999966],
            [-35.987128561999953, -6.383825992999959],
            [-35.985510266999938, -6.395876774999977],
            [-35.982495780999955, -6.41787943099996],
            [-35.980731488999936, -6.430808024999976],
            [-35.978078770999957, -6.45029716099998],
            [-35.976332310999965, -6.463061812999968],
            [-35.974959455999965, -6.473045390999971],
            [-35.973134757999958, -6.486604411999963],
            [-35.970677515999967, -6.504621175999944],
            [-35.96749544499994, -6.528277722999974],
            [-35.965318555999943, -6.544243760999962],
            [-35.962940058999948, -6.561833860999968],
            [-35.959364317999984, -6.588088509999977],
            [-35.956529602999979, -6.609159141999953],
            [-35.951880308999932, -6.643449250999936],
            [-35.94663505799997, -6.68010692799993],
            [-35.943197875999942, -6.702618814999937],
            [-35.941009194999936, -6.716680564999933],
            [-35.924399568999945, -6.825149770999928],
            [-35.920499200999984, -6.850183347999973],
            [-35.915590103999932, -6.882096702999945],
            [-35.910281176999945, -6.918847351999943],
            [-35.905957503999957, -6.948964500999978],
            [-35.904059958999937, -6.96289905499998],
            [-35.90245796399995, -6.975225149999972],
            [-35.896702201999972, -7.017966115999968],
            [-35.894818469999962, -7.032571619999942],
            [-35.891950454999971, -7.054089974999954],
            [-35.888932434999958, -7.078029527999945],
            [-35.888289142999952, -7.083207562999974],
            [-35.889875220999954, -7.087875896999947],
            [-35.893466269999976, -7.098563550999927],
            [-35.901363977999949, -7.121975621999979],
            [-35.907033569999953, -7.13876210799998],
            [-35.911045724999951, -7.150521537999964],
            [-35.916048366999973, -7.165088042999969],
            [-35.921671191999963, -7.181476679999946],
            [-35.926860671999975, -7.196624613999973],
            [-35.930386529999964, -7.220007624999937],
            [-35.919353527999931, -7.246850678999976],
            [-35.915170604999958, -7.257013637999933],
            [-35.914647747999936, -7.259430663999979],
            [-35.91349895999997, -7.261655029999929],
            [-35.912492304999944, -7.264556587999948],
            [-35.909329662999937, -7.265109045999964],
            [-35.908804569999973, -7.265878290999979],
            [-35.908789049999939, -7.266451448999931],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Pirapama II - Suape II C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 21.61212,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.18935,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.027965790999929, -8.270290199999977],
            [-35.028602064999973, -8.26971070899998],
            [-35.029836545999956, -8.268592650999949],
            [-35.028628828999956, -8.266616953999971],
            [-35.026918787999932, -8.265126484999939],
            [-35.02164887899994, -8.262715894999928],
            [-35.018091582999944, -8.264208788999952],
            [-35.01547452899996, -8.267348019999929],
            [-35.010032617999968, -8.274057067999934],
            [-35.003181167999969, -8.272013870999956],
            [-35.000452749999965, -8.275528699999938],
            [-34.99618252199997, -8.280375067999955],
            [-35.000853330999973, -8.292910272999961],
            [-35.002476666999939, -8.297284966999939],
            [-35.001753978999943, -8.305937390999929],
            [-35.006318952999948, -8.311465428999952],
            [-35.007407708999949, -8.312780390999933],
            [-35.009854194999946, -8.31680849299994],
            [-35.011288945999979, -8.319011129999978],
            [-35.014020718999973, -8.325840873999937],
            [-35.018826430999979, -8.327910412999927],
            [-35.023890861999973, -8.330860451999968],
            [-35.026219783999977, -8.337079076999942],
            [-35.027925190999952, -8.341607435999947],
            [-35.029957994999961, -8.346960639999963],
            [-35.031349564999971, -8.350563120999936],
            [-35.033494820999977, -8.35384838899995],
            [-35.036538679999978, -8.358473046999961],
            [-35.040473977999966, -8.365427942999929],
            [-35.040849480999952, -8.369095088999927],
            [-35.041283410999938, -8.37301167399994],
            [-35.039374872999929, -8.374357058999976],
            [-35.035566549999942, -8.374729970999965],
            [-35.032757114999981, -8.377956850999965],
            [-35.032721298999945, -8.382514082999933],
            [-35.032715619999976, -8.388408202999926],
            [-35.033050825999965, -8.388917491999962],
            [-35.034282725999958, -8.38992587599995],
            [-35.042102049999983, -8.389868474999957],
            [-35.044920414999979, -8.389894108999954],
            [-35.045453590999955, -8.389887657999964],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV UTE Suape II - Suape II C1',
        Concession: 'SUAPE II - Energética Suape II S.A.',
        Tensao: 230.0,
        Extensao: 5.7959,
        Ano_Opera: 2013.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.05081,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.026025441999934, -8.357663780999928],
            [-35.02751230299998, -8.357443453999963],
            [-35.029887292999945, -8.356829291999929],
            [-35.033324337999943, -8.355106343999978],
            [-35.034449743999971, -8.354471946999979],
            [-35.03520674799995, -8.355817549999927],
            [-35.036859024999956, -8.358280428999933],
            [-35.040839925999933, -8.365379090999966],
            [-35.041048598999964, -8.367361215999949],
            [-35.041698552999947, -8.372872496999946],
            [-35.042755986999964, -8.375369036999928],
            [-35.049145007999982, -8.379451866999943],
            [-35.050166734999948, -8.380196843999954],
            [-35.046765555999968, -8.388678652999943],
            [-35.045935867999958, -8.388740821999932],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV UTE Suape II - Suape II C2',
        Concession: 'SUAPE II - Energética Suape II S.A.',
        Tensao: 230.0,
        Extensao: 5.7095,
        Ano_Opera: 2013.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.05007,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.026018298999929, -8.357799783999951],
            [-35.027491186999953, -8.357561203999978],
            [-35.029892870999959, -8.35690429899995],
            [-35.03338615399997, -8.35528450299995],
            [-35.034309152999981, -8.354879684999958],
            [-35.035151341999949, -8.355853942999943],
            [-35.03677567699998, -8.358307743999944],
            [-35.040729725999938, -8.365413785999976],
            [-35.04094379999998, -8.367358572999933],
            [-35.041580139999951, -8.372895752999966],
            [-35.042673298999944, -8.375406605999956],
            [-35.049017671999934, -8.379492639999967],
            [-35.050047099999972, -8.38021254399996],
            [-35.049122790999945, -8.38258842099998],
            [-35.046694090999949, -8.388594525999963],
            [-35.045924832999958, -8.388607134999972],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Pirapama II - Suape II C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 21.66293,
        Ano_Opera: 2012.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.18979,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.02807262999994, -8.270375802999979],
            [-35.028646790999971, -8.269785944999967],
            [-35.02992274099995, -8.268644054999982],
            [-35.028697759999943, -8.266591335999976],
            [-35.026946768999949, -8.265070542999979],
            [-35.021646000999965, -8.262618962999966],
            [-35.018015982999941, -8.264136815999962],
            [-35.010003928999936, -8.27396641699994],
            [-35.003144220999957, -8.271907520999946],
            [-35.000406454999961, -8.275487637999959],
            [-34.996081642999968, -8.28035097999998],
            [-34.999170644999936, -8.288616567999952],
            [-35.00237263899993, -8.297298253999941],
            [-35.001670585999932, -8.305983788999981],
            [-35.007340237999983, -8.312872391999974],
            [-35.009760145999962, -8.316790334999951],
            [-35.011210711999979, -8.319055642999956],
            [-35.013931431999936, -8.325922828999978],
            [-35.018769737999946, -8.328023289999976],
            [-35.023793257999955, -8.330932565999944],
            [-35.026110010999957, -8.337108829999977],
            [-35.031272258999934, -8.35062530099998],
            [-35.036474089999956, -8.358505130999959],
            [-35.040411057999961, -8.365461499999981],
            [-35.041169755999931, -8.372931431999973],
            [-35.039328689999934, -8.374263606999932],
            [-35.035520972999961, -8.374640696999961],
            [-35.032660659999976, -8.377914372999953],
            [-35.032616965999978, -8.388421898999979],
            [-35.032993717999943, -8.389002712999968],
            [-35.034229763999974, -8.390011346999927],
            [-35.044899078999947, -8.390003280999963],
            [-35.045417912999937, -8.390030478999961],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Suape II -Suape III C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 3.65443,
        Ano_Opera: 2012.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.03295,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.045464513999946, -8.39016849099994],
            [-35.045178262999968, -8.390204138999934],
            [-35.041962895999973, -8.39016094599998],
            [-35.034084625999981, -8.390199038999981],
            [-35.029682195999953, -8.392506431999948],
            [-35.026856861999931, -8.394598918999975],
            [-35.017850058999954, -8.394566320999957],
            [-35.015688090999959, -8.395702752999966],
            [-35.015249582999957, -8.396108704999961],
            [-35.015850870999941, -8.396950954999966],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Suape II -Suape III C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 3.63204,
        Ano_Opera: 2012.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.03276,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.015953465999928, -8.39686334299995],
            [-35.015346917999977, -8.396115123999948],
            [-35.015735113999938, -8.395763387999978],
            [-35.017861899999957, -8.394634392999933],
            [-35.026899234999973, -8.39466706099995],
            [-35.029757549999943, -8.392546646999961],
            [-35.034102007999934, -8.390266491999967],
            [-35.041997651999964, -8.390237140999943],
            [-35.045184316999951, -8.390286740999954],
            [-35.045457135999982, -8.390320687999974],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Suape III - Termopernambuco C1',
        Concession: 'TERMOPERNAMBUCO - Termopernambuco S/A',
        Tensao: 230.0,
        Extensao: 5.75878,
        Ano_Opera: 2012.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.0517,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.015337950999935, -8.397476463999965],
            [-35.014564786999983, -8.396789679999927],
            [-35.012283174999936, -8.398941349999973],
            [-35.009081077999952, -8.40211151099993],
            [-35.005037262999963, -8.401783826999974],
            [-35.003048690999947, -8.400643125999977],
            [-34.994145641999978, -8.398777136999968],
            [-34.991952869999977, -8.398547039999926],
            [-34.98737054999998, -8.397563373999958],
            [-34.983609529999967, -8.396770913999944],
            [-34.981251735999933, -8.399666485999944],
            [-34.97642888699994, -8.401768245999961],
            [-34.971062831999973, -8.404126995999945],
            [-34.96995340899997, -8.403733656999975],
            [-34.969203020999942, -8.40403708499997],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Suape III - Termopernambuco C2',
        Concession: 'TERMOPERNAMBUCO - Termopernambuco S/A',
        Tensao: 230.0,
        Extensao: 5.74955,
        Ano_Opera: 2012.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.05161,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.96925551299995, -8.404222090999951],
            [-34.969968642999959, -8.40383800099994],
            [-34.971125754999946, -8.404237654999974],
            [-34.976565666999932, -8.401859000999934],
            [-34.981339441999978, -8.399750394999955],
            [-34.983656921999966, -8.396881477999955],
            [-34.991969682999979, -8.398625421999952],
            [-34.994134717999941, -8.398852776999945],
            [-34.998509648999971, -8.399766061999969],
            [-35.003000584999938, -8.400709983999946],
            [-35.005012267999973, -8.401858392999941],
            [-35.009094382999933, -8.402187649999973],
            [-35.01455871099995, -8.396878567999977],
            [-35.015242085999944, -8.397572292999939],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Abreu e Lima - Suape II C1',
        Concession: 'RNEST - Refinaria Abreu e Lima SA RNEST',
        Tensao: 230.0,
        Extensao: 3.22652,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.02838,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.045432996999978, -8.389749046999952],
            [-35.044962486999964, -8.389708827999925],
            [-35.041644081999948, -8.389606535999974],
            [-35.038435886999935, -8.389637672999982],
            [-35.034377187999951, -8.389690277999932],
            [-35.032977694999943, -8.387656797999966],
            [-35.032975337999972, -8.383304507999981],
            [-35.032988020999937, -8.380912773999967],
            [-35.032942680999952, -8.378450587999964],
            [-35.032934636999983, -8.376991472999975],
            [-35.033043540999984, -8.376152097999977],
            [-35.033136395999975, -8.373842226999955],
            [-35.032992837999984, -8.37359110899996],
            [-35.032257913999956, -8.373646869999959],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Abreu e Lima - Suape II C2',
        Concession: 'RNEST - Refinaria Abreu e Lima SA RNEST',
        Tensao: 230.0,
        Extensao: 3.228,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.02839,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.032240008999963, -8.373487977999957],
            [-35.032987382999977, -8.373500164999939],
            [-35.033219753999958, -8.373809270999971],
            [-35.033118184999978, -8.376166659999967],
            [-35.033001119999938, -8.376987374999942],
            [-35.033009399999969, -8.37845565799995],
            [-35.033064411999931, -8.380912320999926],
            [-35.033054530999948, -8.387635954999951],
            [-35.034422486999972, -8.389603989999955],
            [-35.041662677999966, -8.389526840999963],
            [-35.04496943099997, -8.389629492999973],
            [-35.045444342999929, -8.389604477999967],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Paulo Afonso IV - Paulo Afonso III C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 1.1898,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.01048,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.200137909999967, -9.411933489999967],
            [-38.200162392999971, -9.411360783999953],
            [-38.19825683199997, -9.408468723999931],
            [-38.196445020999931, -9.40558120999998],
            [-38.196157055999947, -9.404710451999961],
            [-38.195928889999948, -9.403773231999935],
            [-38.196305491999965, -9.402927563999981],
            [-38.19649101899995, -9.402803559999938],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Paulo Afonso IV - Paulo Afonso III C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 1.39304,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.0123,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.199736260999941, -9.411877686999958],
            [-38.19987763499995, -9.411382023999977],
            [-38.196200561999945, -9.405764379999937],
            [-38.194488724999985, -9.402687537999952],
            [-38.19465103999994, -9.402318523999952],
            [-38.19555778199998, -9.401619394999955],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Paulo Afonso III - Itabaiana C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 167.34317,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.47339,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-37.588096773613394, -10.310613660371555],
            [-37.680229242999985, -10.17947286499998],
            [-37.684797807999985, -10.173000730999945],
            [-37.69630250199998, -10.157772180999928],
            [-37.710353218999956, -10.139188589999947],
            [-37.720216084999947, -10.126089656999966],
            [-37.737871685999949, -10.102699555999948],
            [-37.745795290999979, -10.092229926999948],
            [-37.756371994999938, -10.078238696999961],
            [-37.76383160499995, -10.068316343999982],
            [-37.771410188999937, -10.058310710999933],
            [-37.780594907999955, -10.046115972999928],
            [-37.785865413999943, -10.039144151999949],
            [-37.793491668999934, -10.028892760999952],
            [-37.800903830999971, -10.019101350999961],
            [-37.805815334999977, -10.012627055999928],
            [-37.812292027999945, -10.00403328699997],
            [-37.818572047999965, -9.995660577999956],
            [-37.821005301999946, -9.992449767999972],
            [-37.854988755999955, -9.947475416999964],
            [-37.859771472999967, -9.941157947999955],
            [-37.867242980999947, -9.93122369799994],
            [-37.881888562999961, -9.911854885999958],
            [-37.896474658999978, -9.892497970999955],
            [-37.907051362999937, -9.878328279999948],
            [-37.917247353999983, -9.863789772999951],
            [-37.924659488999964, -9.85327317399998],
            [-37.933923154999945, -9.840033142999971],
            [-37.939060645999973, -9.832667528999934],
            [-37.946542453999939, -9.821962878999955],
            [-37.95159707199997, -9.814762080999969],
            [-37.957990216999974, -9.805629494999948],
            [-37.962936114999934, -9.79859562799993],
            [-37.969694079999954, -9.788957858999936],
            [-37.977225528999952, -9.778168602999926],
            [-37.984270684999956, -9.768133313999954],
            [-37.991687482999964, -9.757530236999969],
            [-37.996407447999957, -9.750807713999961],
            [-37.998711846999981, -9.747466963999955],
            [-38.001070824999942, -9.743864340999949],
            [-38.01083265099993, -9.727185737999946],
            [-38.019079758999965, -9.712836745999937],
            [-38.026589242999933, -9.699905131999969],
            [-38.035036331999947, -9.685190516999967],
            [-38.040140199999939, -9.676316998999937],
            [-38.048826264999946, -9.661288816999956],
            [-38.05529986099998, -9.650028830999929],
            [-38.061126649999949, -9.639954479999972],
            [-38.065024880999943, -9.633213123999951],
            [-38.071470358999932, -9.621985905999964],
            [-38.07757018999996, -9.611387236999974],
            [-38.083270509999977, -9.60149187899998],
            [-38.089497095999945, -9.590688969999974],
            [-38.095407562999981, -9.580451479999965],
            [-38.099658858999931, -9.573097380999968],
            [-38.107841984999936, -9.558801924999955],
            [-38.114263883999968, -9.547699377999948],
            [-38.122018785999956, -9.534271772999944],
            [-38.13011058799998, -9.520185784999967],
            [-38.136129392999976, -9.509752854999931],
            [-38.142279690999942, -9.499105830999952],
            [-38.148170584999946, -9.488855352999963],
            [-38.154511777999971, -9.477867461999949],
            [-38.160876461999976, -9.466851602999952],
            [-38.167282443999966, -9.455728528999941],
            [-38.171680237999965, -9.448869062999961],
            [-38.178513645999942, -9.43817923499995],
            [-38.182345392999935, -9.432221216999949],
            [-38.183887150999965, -9.42967680199996],
            [-38.185459298999945, -9.426938597999936],
            [-38.189277113999935, -9.419659940999964],
            [-38.193010157999936, -9.412266070999976],
            [-38.192802251999979, -9.409275729999933],
            [-38.192609113999936, -9.407513866999977],
            [-38.195224426999971, -9.402701171999979],
            [-38.195928804999937, -9.402192283999966],
            [-38.196132284999976, -9.402075103999948],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Paulo Afonso III - Itabaiana C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 167.34646,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.47342,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-37.587858069865412, -10.310613660371555],
            [-37.591332579999971, -10.305679476999956],
            [-37.628678219999983, -10.252593701999956],
            [-37.650390801999947, -10.22163691399993],
            [-37.657648157999972, -10.211250566999979],
            [-37.680050782999956, -10.179401480999957],
            [-37.684547964999979, -10.172905552999964],
            [-37.69617163199996, -10.157617515999959],
            [-37.710150964999968, -10.13908151499993],
            [-37.720085214999983, -10.12593499299993],
            [-37.737693225999976, -10.102616274999946],
            [-37.745672257999956, -10.09208422699993],
            [-37.756193535999955, -10.078179209999973],
            [-37.763724529999934, -10.068185473999961],
            [-37.771255523999969, -10.058215531999963],
            [-37.780464037999934, -10.045985102999964],
            [-37.785746440999958, -10.039048973999968],
            [-37.793295498999953, -10.028977773999941],
            [-37.795618548999983, -10.025932209999951],
            [-37.800826923999978, -10.019055546999937],
            [-37.805706317999977, -10.01255698999995],
            [-37.810267289999956, -10.006490086999975],
            [-37.815807436999933, -9.999180731999957],
            [-37.820893404999936, -9.992410473999939],
            [-37.896319993999953, -9.892450380999946],
            [-37.906872903999954, -9.878268793999951],
            [-37.917068893999954, -9.863718389999974],
            [-37.924559688999977, -9.853182722999975],
            [-37.931157157999962, -9.84371239799998],
            [-37.936347921999982, -9.836281998999937],
            [-37.941270998999983, -9.829269047999958],
            [-37.946443371999976, -9.821889325999962],
            [-37.951493677999963, -9.814671931999953],
            [-37.955923177999978, -9.808368930999961],
            [-37.960207120999939, -9.802250754999932],
            [-37.96520007099997, -9.795155458999943],
            [-37.969581801999936, -9.788887092999971],
            [-37.97462584699997, -9.781682428999943],
            [-37.979542101999982, -9.774615058999927],
            [-37.98417213099998, -9.768034506999982],
            [-37.98898782599997, -9.761126941999976],
            [-37.996290656999975, -9.750721929999941],
            [-37.998622220999948, -9.747387496999977],
            [-38.000978749999945, -9.743795297999952],
            [-38.006733069999939, -9.733998633999931],
            [-38.010715796999932, -9.727121428999965],
            [-38.014807852999979, -9.719981600999972],
            [-38.018970545999935, -9.712765077999961],
            [-38.022569332999979, -9.70656508299993],
            [-38.026504669999952, -9.699776459999953],
            [-38.030285778999939, -9.693158194999967],
            [-38.034908020999978, -9.685139224999944],
            [-38.037883363999981, -9.679974568999967],
            [-38.042138720999958, -9.672576492999951],
            [-38.046551055999942, -9.664914386999953],
            [-38.050813430999938, -9.657599943999969],
            [-38.055188560999966, -9.649964508999972],
            [-38.059421156999974, -9.642622037999956],
            [-38.064917733999948, -9.633110659999943],
            [-38.069364534999977, -9.625414804999934],
            [-38.077460954999935, -9.611336761999951],
            [-38.083153965999941, -9.601443668999934],
            [-38.089385501999971, -9.590666159999955],
            [-38.097301490999939, -9.576867017999973],
            [-38.103643623999972, -9.565903879999951],
            [-38.107728104999978, -9.558763231999933],
            [-38.111730558999966, -9.551828367999974],
            [-38.116177690999962, -9.544099423999967],
            [-38.121910759999935, -9.534165732999952],
            [-38.126429777999931, -9.526326897999979],
            [-38.131835588999934, -9.516898920999949],
            [-38.137927468999976, -9.506341931999941],
            [-38.142158580999933, -9.49902050399993],
            [-38.146240914999964, -9.491939703999947],
            [-38.15029098399998, -9.484935769999936],
            [-38.15852685699997, -9.470663756999954],
            [-38.162846345999981, -9.463218885999936],
            [-38.167172466999943, -9.455717869999944],
            [-38.171527939999976, -9.44889840299993],
            [-38.178388103999964, -9.438171139999952],
            [-38.182205301999943, -9.432197422999934],
            [-38.183784500999934, -9.429646402999936],
            [-38.185345998999935, -9.426948166999978],
            [-38.186623403999931, -9.424555092999981],
            [-38.189126165999937, -9.419674475999955],
            [-38.19134368899995, -9.415384848999963],
            [-38.192877609999982, -9.412259787999972],
            [-38.19267924899998, -9.409342404999961],
            [-38.192477318999977, -9.407510206999973],
            [-38.19396904599995, -9.404809938999961],
            [-38.195137007999961, -9.402673589999949],
            [-38.195795066999949, -9.402086470999961],
            [-38.195987110999965, -9.401944569999955],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Paulo Afonso III - Cícero Dantas C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 138.51991,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.20924,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.319677613730086, -10.310613660371555],
            [-38.319551464999961, -10.309833666999964],
            [-38.318037278999952, -10.300406309999971],
            [-38.315889402999971, -10.286999821999927],
            [-38.314658974999929, -10.279392660999974],
            [-38.312781412999982, -10.267717691999962],
            [-38.307252696999967, -10.232970165999973],
            [-38.298399521999954, -10.179315805999977],
            [-38.291640188999963, -10.138229980999938],
            [-38.291109046999964, -10.134317941999939],
            [-38.290380931999948, -10.129925456999956],
            [-38.289897255999961, -10.127494185999979],
            [-38.278708919999929, -10.058037762999959],
            [-38.275856862999944, -10.040017705999958],
            [-38.273858011999948, -10.027259443999981],
            [-38.271402499999965, -10.011680088999981],
            [-38.269281572999944, -9.998236799999972],
            [-38.267474915999969, -9.986796434999974],
            [-38.261663148999958, -9.949750148999954],
            [-38.259418482999934, -9.935354215999951],
            [-38.25709709399996, -9.920468076999953],
            [-38.255389565999963, -9.90956863599996],
            [-38.252068111999961, -9.888364299999978],
            [-38.249481265999975, -9.871864675999973],
            [-38.243851280999934, -9.836062374999926],
            [-38.24221532599995, -9.82560482599996],
            [-38.236132173999977, -9.787093674999937],
            [-38.233149720999961, -9.768060504999937],
            [-38.228898963999939, -9.740674622999961],
            [-38.225915071999964, -9.721581554999943],
            [-38.224607069999934, -9.71326062299994],
            [-38.223404136999932, -9.705512344999931],
            [-38.222983532999933, -9.702950243999965],
            [-38.222359, -9.696476584999971],
            [-38.221795609999958, -9.691097876999947],
            [-38.221033812999963, -9.683267868999963],
            [-38.220186954999974, -9.675123043999974],
            [-38.218798823999975, -9.66171501599996],
            [-38.21654930099993, -9.640036570999939],
            [-38.215717261999941, -9.632044263999944],
            [-38.21456401699993, -9.621204870999975],
            [-38.212467921999973, -9.601229276999959],
            [-38.210775998999964, -9.584723458999974],
            [-38.209328159999984, -9.57037226999995],
            [-38.208404696999935, -9.561338545999945],
            [-38.207034440999962, -9.547813033999944],
            [-38.205308830999968, -9.530877645999965],
            [-38.202061404999938, -9.499026431999937],
            [-38.200388559999965, -9.482379385999934],
            [-38.199260463999963, -9.471346908999976],
            [-38.198152098999969, -9.460644601999945],
            [-38.197617352999941, -9.455040564999933],
            [-38.19711848999998, -9.450619472999961],
            [-38.196589478999954, -9.445579676999955],
            [-38.196029702999965, -9.440077583999937],
            [-38.195278410999947, -9.432647679999945],
            [-38.194790308999984, -9.428001364999943],
            [-38.194327525999938, -9.423385952999979],
            [-38.193961575999936, -9.419467879999956],
            [-38.193489737999982, -9.41532462899994],
            [-38.193315445999929, -9.412646780999978],
            [-38.193043345999968, -9.410029407999957],
            [-38.192776309999942, -9.40753375099996],
            [-38.195375103999936, -9.402820186999975],
            [-38.196193093999966, -9.402215834999936],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Paulo Afonso III - Cícero Dantas C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 138.50691,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.20913,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.319949852144937, -10.310613660371555],
            [-38.318942940999932, -10.304290720999973],
            [-38.317415713999935, -10.294766780999964],
            [-38.315967637999961, -10.285731566999971],
            [-38.314346874999956, -10.27569650099997],
            [-38.312819078999951, -10.266139714999952],
            [-38.311698822999972, -10.259105969999951],
            [-38.309675413999969, -10.24649437599993],
            [-38.308262522999939, -10.237221892999969],
            [-38.307458853999947, -10.232341897999959],
            [-38.305686322999975, -10.221465414999955],
            [-38.303970850999974, -10.210966607999978],
            [-38.302588403999948, -10.202713081999946],
            [-38.298654379999959, -10.179245963999961],
            [-38.291966746999947, -10.138353610999957],
            [-38.291228019999949, -10.134308423999926],
            [-38.290509422999946, -10.129915938999943],
            [-38.28996362099997, -10.126053013999979],
            [-38.278176439999982, -10.052809643999979],
            [-38.276172636999945, -10.040283909999971],
            [-38.27508770399993, -10.033283105999942],
            [-38.273039235999931, -10.020387245999927],
            [-38.271969245999969, -10.013534723999953],
            [-38.27072416599998, -10.005676402999939],
            [-38.269201032999945, -9.996007083999928],
            [-38.267703363999942, -9.986447815999952],
            [-38.266524323999931, -9.978994721999982],
            [-38.264879616999963, -9.96850242499994],
            [-38.263690827999937, -9.961000983999952],
            [-38.262523481999949, -9.953341358999978],
            [-38.261358980999944, -9.945993400999953],
            [-38.260219149999955, -9.938651383999968],
            [-38.259090258999947, -9.931420694999929],
            [-38.257837920999975, -9.923413665999931],
            [-38.256687760999966, -9.916119615999946],
            [-38.255539253999984, -9.908777485999963],
            [-38.254315589999976, -9.900967132999938],
            [-38.253127725999946, -9.89334817799994],
            [-38.251853394999955, -9.885203923999939],
            [-38.250240344999952, -9.874951095999961],
            [-38.249104974999966, -9.867711290999978],
            [-38.247862896999948, -9.859793554999953],
            [-38.246816599999931, -9.85316107999995],
            [-38.245679161999931, -9.845917820999944],
            [-38.244480026999952, -9.838239108999971],
            [-38.24327948399997, -9.83062681399997],
            [-38.242048419999946, -9.822758223999926],
            [-38.240819543999976, -9.814959714999929],
            [-38.239649934999932, -9.807551168999964],
            [-38.238401430999943, -9.799658689999944],
            [-38.237130236999974, -9.791667427999926],
            [-38.235855786999934, -9.783531444999937],
            [-38.234164600999975, -9.772814116999939],
            [-38.232520615999931, -9.762256624999964],
            [-38.231131274999939, -9.75330258799994],
            [-38.229623505999939, -9.74348439299996],
            [-38.228793676999942, -9.738214852999931],
            [-38.227017463999971, -9.726870740999971],
            [-38.224884076999956, -9.713221750999935],
            [-38.223683361999974, -9.70546612399994],
            [-38.223243568999976, -9.702877489999935],
            [-38.22301224399996, -9.700311069999941],
            [-38.222542654999984, -9.695586295999931],
            [-38.221293665999951, -9.683234205999952],
            [-38.220456140999943, -9.675092517999929],
            [-38.219292586999984, -9.663806034999936],
            [-38.218009496999969, -9.651438676999931],
            [-38.216871258999959, -9.640463481999973],
            [-38.216083658999935, -9.633015887999932],
            [-38.214773399999956, -9.620479432999957],
            [-38.213937172999977, -9.61267201499993],
            [-38.213225897999962, -9.605830106999974],
            [-38.212055845999942, -9.594646261999969],
            [-38.211041998999974, -9.584685544999957],
            [-38.210274263999963, -9.577027370999929],
            [-38.209395503999929, -9.568331975999968],
            [-38.208716639999977, -9.561587343999975],
            [-38.207571495999957, -9.550379077999935],
            [-38.206487610999943, -9.539788226999974],
            [-38.205217920999985, -9.52729368699994],
            [-38.20387012499998, -9.51416844299996],
            [-38.202593923999984, -9.501554124999927],
            [-38.201385614999936, -9.489630376999969],
            [-38.200420809999969, -9.479937246999953],
            [-38.199689787999944, -9.472851395999953],
            [-38.198597664999966, -9.462281695999934],
            [-38.197856988999945, -9.455043157999967],
            [-38.197089073999962, -9.447710631999939],
            [-38.196270627999979, -9.439686919999929],
            [-38.195742822999932, -9.434468154999934],
            [-38.19472279699994, -9.424454276999938],
            [-38.194209804999957, -9.419170054999938],
            [-38.19379523799995, -9.415480471999956],
            [-38.193405457999972, -9.412650417999942],
            [-38.19315196499997, -9.410048308999933],
            [-38.19286587199997, -9.407565850999958],
            [-38.193982353999957, -9.405591343999959],
            [-38.19543428399993, -9.402891460999967],
            [-38.196307783999941, -9.402357070999926],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Bom Nome - Milagres C3',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 86.39695,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.75947,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.627734259999954, -7.996914217999972],
            [-38.627898441999946, -7.995803309999928],
            [-38.628061187999947, -7.993460173999949],
            [-38.628122014999974, -7.992015460999937],
            [-38.628834147999953, -7.990395342999932],
            [-38.629656274999945, -7.988464917999977],
            [-38.633176870999932, -7.980240983999977],
            [-38.637978627999985, -7.969001527999978],
            [-38.642728452999961, -7.957910008999932],
            [-38.647303023999939, -7.947208122999939],
            [-38.650593385999969, -7.939494974999945],
            [-38.659063498999956, -7.919718607999926],
            [-38.669001188999971, -7.896571181999946],
            [-38.675927987999955, -7.88102054899997],
            [-38.68498838599993, -7.860642349999978],
            [-38.694673431999945, -7.838788475999934],
            [-38.708162825999977, -7.808357720999936],
            [-38.713308027999972, -7.796725634999973],
            [-38.717499088999944, -7.787341797999943],
            [-38.723559827999964, -7.773690626999951],
            [-38.729627416999961, -7.75999483399994],
            [-38.733896985999934, -7.750397698999961],
            [-38.743459905999941, -7.729421239999965],
            [-38.745555589999981, -7.724860897999974],
            [-38.758731266999973, -7.695888537999963],
            [-38.762290563999954, -7.690012430999957],
            [-38.775752833999945, -7.667623008999954],
            [-38.785702780999941, -7.650990453999952],
            [-38.791792878999956, -7.641013680999947],
            [-38.799573715999941, -7.62808130999997],
            [-38.812303831999941, -7.606832723999958],
            [-38.816551169999968, -7.597124522999934],
            [-38.820358307999982, -7.588510873999951],
            [-38.827356257999952, -7.572862705999967],
            [-38.828425749999951, -7.570525154999928],
            [-38.836189671999932, -7.552562995999949],
            [-38.839427923999949, -7.545051740999952],
            [-38.844002286999967, -7.534710195999935],
            [-38.846249153999963, -7.52963871999998],
            [-38.848354865999966, -7.524835819999964],
            [-38.857543780999947, -7.503950051999936],
            [-38.862251567999976, -7.493269548999933],
            [-38.865821804999939, -7.485163289999946],
            [-38.869324328999937, -7.477222282999946],
            [-38.873739335999971, -7.467189872999938],
            [-38.879527164999956, -7.454067816999952],
            [-38.886436662999984, -7.43837732999998],
            [-38.892500100999939, -7.424594293999974],
            [-38.901166589999946, -7.404934837999974],
            [-38.90404373299998, -7.398353974999964],
            [-38.908818574999941, -7.387565244999962],
            [-38.914388654999982, -7.374885082999981],
            [-38.919643448999977, -7.362985081999966],
            [-38.924113727999952, -7.351633310999944],
            [-38.927284962999977, -7.343644744999949],
            [-38.930320009999946, -7.33600721199997],
            [-38.933496876999982, -7.328002260999938],
            [-38.935564833999933, -7.322774193999976],
            [-38.93952534999994, -7.312810382999942],
            [-38.941643195999973, -7.309988441999963],
            [-38.941383977999976, -7.306966975999956],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Bom Nome - Milagres C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 86.38425,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.75935,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.627484573999936, -7.996826638999948],
            [-38.627671374999977, -7.995829610999976],
            [-38.627774899999963, -7.993667941999945],
            [-38.627849124999955, -7.992015997999943],
            [-38.628625708999948, -7.990166715999976],
            [-38.630564246999938, -7.985671777999926],
            [-38.632891331999929, -7.980208441999935],
            [-38.636853439999982, -7.970946141999946],
            [-38.641399025999931, -7.960308194999925],
            [-38.643525506999936, -7.955340558999978],
            [-38.647905185999946, -7.945104043999947],
            [-38.652397637999968, -7.934599931999969],
            [-38.655670527999973, -7.926960590999954],
            [-38.658491338999966, -7.920358428999975],
            [-38.662494832999982, -7.91100548199995],
            [-38.668406832999949, -7.897252221999963],
            [-38.676992003999942, -7.877961846999938],
            [-38.682628868999984, -7.865260746999979],
            [-38.688951133999979, -7.85109443999994],
            [-38.696808561999944, -7.833355799999936],
            [-38.703276056999982, -7.818833411999947],
            [-38.706812526999954, -7.810805205999941],
            [-38.722668472999942, -7.775075736999952],
            [-38.726834397999937, -7.765688749999981],
            [-38.729955229999973, -7.758685453999931],
            [-38.733230851999963, -7.751293189999956],
            [-38.736567717999947, -7.743878006999978],
            [-38.742249663999985, -7.731506512999943],
            [-38.745332579999967, -7.724783246999948],
            [-38.750158049999982, -7.71401050299994],
            [-38.753476214999978, -7.706826827999976],
            [-38.756700931999944, -7.699762638999971],
            [-38.758542847999934, -7.695718706999969],
            [-38.761804794999932, -7.69032036699997],
            [-38.767042723999964, -7.681566206999946],
            [-38.770352005999939, -7.676152029999969],
            [-38.775486712999964, -7.667554093999968],
            [-38.777081212999974, -7.664920174999963],
            [-38.782148959999972, -7.656459190999954],
            [-38.785449720999964, -7.650904084999979],
            [-38.78831886599994, -7.646284186999935],
            [-38.793863009999939, -7.636980494999932],
            [-38.798038963999943, -7.629984878999949],
            [-38.800989494999953, -7.625142675999939],
            [-38.810162317999982, -7.609890330999974],
            [-38.812137269999937, -7.606559085999947],
            [-38.817657619999977, -7.593924147999928],
            [-38.82106024899997, -7.586202796999942],
            [-38.82452236499995, -7.578421958999968],
            [-38.827155553999944, -7.572637446999977],
            [-38.828115542999967, -7.57058531499996],
            [-38.833121879999965, -7.558876399999974],
            [-38.835809378999954, -7.552777781999964],
            [-38.838314392999962, -7.546932962999961],
            [-38.841707214999985, -7.53927129799996],
            [-38.846259269999962, -7.528953190999971],
            [-38.850611955999966, -7.519041460999972],
            [-38.855012295999984, -7.509079972999928],
            [-38.859097039999938, -7.49971828799994],
            [-38.862125169999956, -7.492886462999934],
            [-38.867921627999976, -7.479747352999937],
            [-38.870405264999931, -7.474060934999954],
            [-38.874632106999968, -7.464509764999946],
            [-38.879220888999953, -7.454082235999977],
            [-38.883944466999935, -7.443339956999978],
            [-38.887279149999983, -7.435765346999972],
            [-38.891658888999984, -7.425833901999965],
            [-38.894592469999964, -7.419140997999932],
            [-38.897553320999975, -7.41243493099995],
            [-38.90094241099996, -7.40475934799997],
            [-38.90546011899994, -7.394486230999973],
            [-38.90853051199997, -7.387518240999952],
            [-38.913977211999963, -7.375180349999937],
            [-38.916139695999959, -7.370286402999966],
            [-38.919334641999967, -7.36294055299993],
            [-38.922049499999957, -7.356173497999976],
            [-38.924224334999963, -7.350680281999928],
            [-38.926104061999979, -7.345929233999925],
            [-38.928136905999963, -7.34079798099998],
            [-38.930183405999969, -7.335655937999945],
            [-38.934288934999984, -7.32531621399994],
            [-38.939277145999938, -7.312750936999976],
            [-38.941370301999939, -7.30993531699994],
            [-38.941249402999972, -7.306974841999931],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Bom Nome - Milagres C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 86.60993,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.76138,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.62724266999993, -7.997338184999933],
            [-38.627158645999941, -7.99758219499995],
            [-38.62630358399997, -7.997405671999957],
            [-38.626020608999966, -7.996909966999965],
            [-38.626310846999957, -7.995623771999931],
            [-38.627026411999964, -7.99322069699997],
            [-38.628362103999962, -7.990107315999978],
            [-38.633247437999955, -7.978677453999978],
            [-38.636877867999942, -7.970189759999926],
            [-38.638269090999984, -7.966886830999954],
            [-38.641388046999964, -7.95965229199993],
            [-38.647979396999972, -7.944258564999927],
            [-38.651939755999933, -7.934997540999973],
            [-38.656147160999978, -7.92516124399998],
            [-38.662241299999948, -7.91090481699996],
            [-38.667493517999958, -7.898632007999936],
            [-38.673314051999967, -7.885591878999946],
            [-38.680695220999951, -7.868947000999981],
            [-38.683586763999983, -7.862473468999951],
            [-38.686434290999955, -7.856051197999932],
            [-38.69041260299997, -7.847076799999968],
            [-38.693094333999966, -7.841014953999945],
            [-38.696414747999938, -7.833543793999979],
            [-38.699651116999974, -7.826238073999946],
            [-38.702102686999979, -7.820746066999959],
            [-38.704608712999971, -7.815121489999967],
            [-38.706519229999969, -7.810800307999955],
            [-38.71086751799993, -7.80112407699994],
            [-38.713161688999946, -7.795910875999937],
            [-38.720267163999949, -7.779933410999945],
            [-38.724185451999972, -7.770987932999958],
            [-38.72782667599995, -7.762784517999933],
            [-38.73079839299993, -7.756112727999948],
            [-38.733707254999956, -7.749581577999948],
            [-38.73624055199997, -7.743869639999957],
            [-38.739102375999948, -7.737797197999953],
            [-38.742043817999956, -7.731336041999953],
            [-38.744923221999954, -7.725035726999977],
            [-38.747738953999942, -7.718789878999928],
            [-38.75026450799993, -7.713157348999971],
            [-38.753275727999949, -7.706583093999939],
            [-38.758326722999982, -7.695414239999934],
            [-38.761464137999951, -7.690294729999948],
            [-38.763727543999948, -7.68651523099993],
            [-38.766692617999979, -7.681556883999974],
            [-38.772683920999953, -7.671637018999945],
            [-38.775702715999955, -7.666704744999947],
            [-38.778718104999939, -7.661659227999962],
            [-38.78548141999994, -7.650316317999966],
            [-38.791233704999968, -7.640813805999926],
            [-38.799131135999971, -7.627645868999934],
            [-38.811812671999974, -7.606530928999973],
            [-38.814630349999959, -7.600159524999981],
            [-38.822061882999947, -7.583298901999967],
            [-38.826912492999952, -7.572497655999939],
            [-38.828091591999964, -7.569907962999935],
            [-38.833322438999971, -7.557748225999944],
            [-38.835387734999983, -7.553039697999964],
            [-38.838922933999982, -7.544837745999928],
            [-38.841902941999933, -7.53817924599997],
            [-38.844748812999967, -7.531732365999972],
            [-38.847504436999941, -7.525461689999929],
            [-38.850436135999985, -7.518807014999936],
            [-38.853282240999931, -7.51235104899996],
            [-38.856057497999984, -7.506025080999962],
            [-38.858956703999979, -7.499348435999934],
            [-38.862680166999951, -7.490967492999971],
            [-38.866580231999933, -7.482109975999947],
            [-38.869841200999929, -7.474705337999978],
            [-38.871984490999978, -7.469841698999971],
            [-38.874709894999967, -7.463640639999937],
            [-38.877946429999952, -7.456235426999967],
            [-38.881936048999933, -7.447144231999971],
            [-38.887071293999952, -7.435561139999947],
            [-38.888967319999949, -7.431223215999978],
            [-38.89288296899997, -7.422320247999949],
            [-38.89601500699996, -7.415232745999958],
            [-38.899120427999947, -7.408191122999938],
            [-38.900943490999964, -7.404058612999961],
            [-38.903061980999951, -7.399261962999958],
            [-38.905969563999975, -7.392661166999972],
            [-38.910693510999977, -7.381954973999939],
            [-38.916173814999979, -7.369482551999965],
            [-38.91915548999998, -7.362771566999982],
            [-38.924233350999941, -7.349877557999946],
            [-38.928915821999965, -7.338094553999952],
            [-38.933423556999969, -7.326701327999956],
            [-38.935048514999949, -7.322596206999947],
            [-38.937886778999939, -7.315533110999979],
            [-38.939067719999969, -7.312548977999938],
            [-38.941142697999965, -7.309732476999955],
            [-38.940965633999951, -7.306975833999957],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Paulo Afonso III - Zebu C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 5.77434,
        Ano_Opera: 2012.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.05093,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.200784194999983, -9.358427443999972],
            [-38.200202224999941, -9.359079005999945],
            [-38.198021385999937, -9.361887392999961],
            [-38.194921323999949, -9.365937351999946],
            [-38.193722909999963, -9.369338123999967],
            [-38.192243441999949, -9.373632291999968],
            [-38.191129685999954, -9.37682912899993],
            [-38.189686603999974, -9.380988801999933],
            [-38.188480591999962, -9.384455960999958],
            [-38.188168692999966, -9.388408392999963],
            [-38.187810008999975, -9.392581906999965],
            [-38.190228461999936, -9.394869502999939],
            [-38.192270631999975, -9.396792906999963],
            [-38.19519736899997, -9.398372189999975],
            [-38.19711609999996, -9.399357338999948],
            [-38.196959595999942, -9.400250670999981],
            [-38.195980900999984, -9.400779037999939],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Paulo Afonso III - Zebu C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 5.59385,
        Ano_Opera: 2012.0,
        created_us: null,
        created_da: null,
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-02-16',
        Shape_Leng: 0.04922,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.200715345999981, -9.358355046999975],
            [-38.200114944999939, -9.359048300999973],
            [-38.197925688999931, -9.36184813899996],
            [-38.194813273999955, -9.365908805999936],
            [-38.192157413999951, -9.373575344999949],
            [-38.188377080999942, -9.384399946999963],
            [-38.187676693999947, -9.392610631999958],
            [-38.192218633999971, -9.39685082099993],
            [-38.192859650999935, -9.399383669999963],
            [-38.194526384999961, -9.400949039999944],
            [-38.19491783999996, -9.400869389999968],
            [-38.195172401999969, -9.40070813799997],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Bom Nome - Floresta II C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 94.90484,
        Ano_Opera: 2014.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.83421,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.627511803999937, -7.997343262999948],
            [-38.62725850399994, -7.99893488999993],
            [-38.626980480999975, -8.000767669999959],
            [-38.626420788999951, -8.003668746999949],
            [-38.627763372999937, -8.018125551999958],
            [-38.628817646999948, -8.02978825799994],
            [-38.63082308099996, -8.041856416999963],
            [-38.633053728999982, -8.05508697099998],
            [-38.635210708999978, -8.068121045999931],
            [-38.638642555999979, -8.088699504999965],
            [-38.641168323999977, -8.10396095699997],
            [-38.643479714999955, -8.117878393999945],
            [-38.645753270999933, -8.131387991999929],
            [-38.649781612999959, -8.155647812999973],
            [-38.647742543999982, -8.160625555999957],
            [-38.644710167999961, -8.167976990999932],
            [-38.637967538999931, -8.184353825999949],
            [-38.632111436999935, -8.198531750999962],
            [-38.626906844999951, -8.211168856999961],
            [-38.624021969999944, -8.218165297999974],
            [-38.618504911999935, -8.23153270399996],
            [-38.613456086999975, -8.243821262999973],
            [-38.609018461999938, -8.254521216999933],
            [-38.598582919999956, -8.27994687599994],
            [-38.589237493999974, -8.302777759999969],
            [-38.57936919399998, -8.326957079999943],
            [-38.56753790199997, -8.355973303999974],
            [-38.562041629999953, -8.369154567999942],
            [-38.516102853999939, -8.478388725999935],
            [-38.514640639999982, -8.481876625999973],
            [-38.510704203999978, -8.491397445999951],
            [-38.507916416999933, -8.498023480999962],
            [-38.50552902499993, -8.503807553999934],
            [-38.502037884999936, -8.512168894999945],
            [-38.500098358999935, -8.516915727999958],
            [-38.49743708099993, -8.523322420999932],
            [-38.496189844999947, -8.526310369999976],
            [-38.495025677999934, -8.528583822999963],
            [-38.492998513999964, -8.531496624999932],
            [-38.487894528999959, -8.538710920999961],
            [-38.48310099899993, -8.545505600999945],
            [-38.474293797999962, -8.55797064099994],
            [-38.467617466999968, -8.567364342999952],
            [-38.464019475999976, -8.572470547999956],
            [-38.459592352999948, -8.578758455999946],
            [-38.453253530999973, -8.587706864999973],
            [-38.447805379999977, -8.595410751999964],
            [-38.443596755999977, -8.601356751999958],
            [-38.438194002999978, -8.60899640699995],
            [-38.43510581299995, -8.613353331999974],
            [-38.432205931999931, -8.617477937999979],
            [-38.429007995999939, -8.622015608999959],
            [-38.424824842999953, -8.62792755199996],
            [-38.418884506999973, -8.636343733999979],
            [-38.417112398999961, -8.638834737999957],
            [-38.347177405999958, -8.73771475999996],
            [-38.343118948999972, -8.74344438199995],
            [-38.340355021999983, -8.747368405999964],
            [-38.342116218999934, -8.748769821999929],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Tacaratu - Bom Nome C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 141.25836,
        Ano_Opera: 2014.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.24412,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.13175290099997, -9.059601750999946],
            [-38.131052152999985, -9.059590684999932],
            [-38.130326984999954, -9.059558897999977],
            [-38.128793143999985, -9.058352928999966],
            [-38.128893757999947, -9.053230163999956],
            [-38.13598524899993, -9.049195778999945],
            [-38.145499345999951, -9.043758680999929],
            [-38.15673092399993, -9.043862945999933],
            [-38.181785563999938, -9.044091991999949],
            [-38.183554514999969, -9.044102015999954],
            [-38.18357121799994, -9.04409947399995],
            [-38.183770266999943, -9.025336326999934],
            [-38.183936280999944, -9.011624150999978],
            [-38.184029137999971, -9.000881946999925],
            [-38.184095681999963, -8.99466971499993],
            [-38.184124271999963, -8.990120873999956],
            [-38.184202075999963, -8.982450529999937],
            [-38.18427180499998, -8.975317128999961],
            [-38.18432183799996, -8.971561937999979],
            [-38.18433211599995, -8.96821701999994],
            [-38.187315167999941, -8.964067609999972],
            [-38.190719573999957, -8.959216025999979],
            [-38.205146839999941, -8.938909817999956],
            [-38.218181337999965, -8.920496531999959],
            [-38.224648444999957, -8.911379135999937],
            [-38.227869633999944, -8.906852177999951],
            [-38.24365466699993, -8.884540128999959],
            [-38.257667376999962, -8.864746521999962],
            [-38.262427513999967, -8.858035547999975],
            [-38.275079647999974, -8.840172416999962],
            [-38.284846142999982, -8.826415493999946],
            [-38.297044126999936, -8.809142169999973],
            [-38.314416240999947, -8.784436555999946],
            [-38.318942401999948, -8.778068981999979],
            [-38.321863632999964, -8.773934632999953],
            [-38.326362846999984, -8.767611771999952],
            [-38.331219726999961, -8.76075259199996],
            [-38.335837927999933, -8.754222574999972],
            [-38.339094195999962, -8.749585364999973],
            [-38.340382899999952, -8.747660405999966],
            [-38.340530648999959, -8.747440926999957],
            [-38.341948737999985, -8.745530041999928],
            [-38.343889009999941, -8.74279719499998],
            [-38.34643267499996, -8.739238205999925],
            [-38.415331476999938, -8.641876774999957],
            [-38.42013792399996, -8.635022378999963],
            [-38.425078109999959, -8.628075156999955],
            [-38.431122020999965, -8.619517981999934],
            [-38.432922660999964, -8.616943935999927],
            [-38.437501384999962, -8.610470326999973],
            [-38.443926203999979, -8.601364521999926],
            [-38.447446631999981, -8.596405197999957],
            [-38.451486849999981, -8.590675136999948],
            [-38.456848784999977, -8.583112],
            [-38.462120801999959, -8.57568643299993],
            [-38.464731252999968, -8.571985906999942],
            [-38.468128649999983, -8.567199456999958],
            [-38.472481949999974, -8.561020814999949],
            [-38.478452819999973, -8.55258317299996],
            [-38.488055066999948, -8.539003599999944],
            [-38.492840152999975, -8.532233135999945],
            [-38.495279082999957, -8.528759467999976],
            [-38.496483233999982, -8.526430045999973],
            [-38.502203, -8.512482152999951],
            [-38.506050456999958, -8.503249363999942],
            [-38.507935558999975, -8.498713076999934],
            [-38.509749019999958, -8.494401369999935],
            [-38.511653622999972, -8.489837677999958],
            [-38.513841124999942, -8.484503957999948],
            [-38.517755661999956, -8.475136544999941],
            [-38.56056986699997, -8.373354468999935],
            [-38.562495360999947, -8.368792863999943],
            [-38.565169366999953, -8.362497777999977],
            [-38.568822092999937, -8.353544892999935],
            [-38.571779875999937, -8.346283249999942],
            [-38.577356257999952, -8.332637981999937],
            [-38.581841501999975, -8.321648599999946],
            [-38.589488223999979, -8.302912660999937],
            [-38.593872661999967, -8.292178217999947],
            [-38.598872903999961, -8.279950735999932],
            [-38.603140147999966, -8.269533170999978],
            [-38.614488742999981, -8.242043903999956],
            [-38.619987877999961, -8.228709055999957],
            [-38.623105521999946, -8.22111663499993],
            [-38.626838941999949, -8.21206647899993],
            [-38.629235784999935, -8.206264145999967],
            [-38.633354198999939, -8.196281746999944],
            [-38.636658429999954, -8.188270059999979],
            [-38.641281212999957, -8.177039307999962],
            [-38.644436221999968, -8.16939236099995],
            [-38.646579164999935, -8.164175900999965],
            [-38.650056149999955, -8.155697428999929],
            [-38.648660661999941, -8.147190183999953],
            [-38.645916152999973, -8.130804372999933],
            [-38.641004288999966, -8.101315426999975],
            [-38.637941245999968, -8.082937526999956],
            [-38.634350243999961, -8.061384329999953],
            [-38.631876917999932, -8.046652240999947],
            [-38.629076770999973, -8.029727235999928],
            [-38.627947993999953, -8.017686539999943],
            [-38.626656771999933, -8.003695234999952],
            [-38.627221577999933, -8.000973875999932],
            [-38.627397777999931, -7.998969283999941],
            [-38.627662757999929, -7.997343151999928],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Paulo Afonso III - Bom Nome C3',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 175.78804,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-02-16',
        Shape_Leng: 1.54327,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.627368050999962, -7.997331909999957],
            [-38.627120253999976, -7.998632942999961],
            [-38.626126056999965, -8.003643042999954],
            [-38.627068404999932, -8.013852387999975],
            [-38.627505939999935, -8.018353270999967],
            [-38.62851260399998, -8.029617300999973],
            [-38.629580949999934, -8.036039131999928],
            [-38.630530721999946, -8.041739087999929],
            [-38.632655114999977, -8.054504082999927],
            [-38.635544803999949, -8.072017204999952],
            [-38.638396326999953, -8.089000442999975],
            [-38.640458206999938, -8.101644696999927],
            [-38.642905806999977, -8.115951722999966],
            [-38.644492199999945, -8.125378585999954],
            [-38.645295130999955, -8.130279450999979],
            [-38.646094495999932, -8.135156810999945],
            [-38.647259167999948, -8.14204725299993],
            [-38.649515189999931, -8.155631784999969],
            [-38.646510499999977, -8.162890531999949],
            [-38.642842558999973, -8.171837224999933],
            [-38.631618282999966, -8.199028454999961],
            [-38.624474653999982, -8.216382410999927],
            [-38.61929207299994, -8.228884206999965],
            [-38.616474266999944, -8.23574952499996],
            [-38.609095775999947, -8.253664020999963],
            [-38.604719391999936, -8.264252710999926],
            [-38.600203689999944, -8.275052766999977],
            [-38.58907133799994, -8.302418941999974],
            [-38.587164371999961, -8.307148575999975],
            [-38.583404711999947, -8.31637451499995],
            [-38.579619984999965, -8.325621314999978],
            [-38.576584412999978, -8.333029207999971],
            [-38.575027659999932, -8.336924536999959],
            [-38.573326807999933, -8.341080395999938],
            [-38.571223277999934, -8.346244933999969],
            [-38.568545487999984, -8.35275556199997],
            [-38.564964507999946, -8.361573131999933],
            [-38.561879923999982, -8.368867799999975],
            [-38.515547545999937, -8.478967754999928],
            [-38.510156190999965, -8.491988594999953],
            [-38.507693223999979, -8.497848645999966],
            [-38.505468539999981, -8.503248050999957],
            [-38.501765030999934, -8.512114686999951],
            [-38.495946621999963, -8.526191134999976],
            [-38.494755970999961, -8.528448410999943],
            [-38.492171003999943, -8.532207169999936],
            [-38.483703233999961, -8.544179268999926],
            [-38.471756143999983, -8.561079567999968],
            [-38.463550947999977, -8.572708451999972],
            [-38.454965172999948, -8.584817396999938],
            [-38.446473113999957, -8.596804863999978],
            [-38.436450196999942, -8.610982074999981],
            [-38.425342873999966, -8.626730127999963],
            [-38.415821856999969, -8.640186936999953],
            [-38.346901319999972, -8.737621818999969],
            [-38.340287601999933, -8.746988739999949],
            [-38.330013199999939, -8.761528820999956],
            [-38.324998513999958, -8.768615323999938],
            [-38.315436560999956, -8.782218597999929],
            [-38.309049566999931, -8.791218433999973],
            [-38.303158418999942, -8.799522625999941],
            [-38.289620843999955, -8.818712522999931],
            [-38.282070251999983, -8.829381676999958],
            [-38.274312859999952, -8.840327154999954],
            [-38.265614852999931, -8.852546770999936],
            [-38.25419893399993, -8.868672850999928],
            [-38.248006327999974, -8.877433361999977],
            [-38.239830667999968, -8.888985575999925],
            [-38.221566722999967, -8.914856074999932],
            [-38.204528040999946, -8.938793647999944],
            [-38.190644304999978, -8.95844582999996],
            [-38.186600908999935, -8.964140231999977],
            [-38.183756114999937, -8.968111656999952],
            [-38.183731483999964, -8.976262603999942],
            [-38.183633413999985, -8.984632375999979],
            [-38.183547349999969, -8.992043843999966],
            [-38.183430628999929, -9.003348415999938],
            [-38.18330849299997, -9.017293102999929],
            [-38.183190180999929, -9.028463955999939],
            [-38.183070878999956, -9.039925012999959],
            [-38.182836380999959, -9.063408900999946],
            [-38.182755649999933, -9.071054712999967],
            [-38.182613153999966, -9.084578829999941],
            [-38.182540164999978, -9.091666737999958],
            [-38.182336224999972, -9.109774161999951],
            [-38.181319757999972, -9.112244210999961],
            [-38.181281535999972, -9.118163427999946],
            [-38.182116877999931, -9.126356485999963],
            [-38.181989413999929, -9.144399569999962],
            [-38.18181423599998, -9.161156593999976],
            [-38.181707511999946, -9.17157597399995],
            [-38.181610422999938, -9.180024525999954],
            [-38.181523350999953, -9.18873601699994],
            [-38.181399542999941, -9.199440970999945],
            [-38.181340345999956, -9.20633670199993],
            [-38.181257656999946, -9.210857949999934],
            [-38.181190250999975, -9.217320044999951],
            [-38.18112705599998, -9.223893284999974],
            [-38.181026337999981, -9.233205656999928],
            [-38.180932208999934, -9.240363626999965],
            [-38.180840089999947, -9.249726086999942],
            [-38.180735530999982, -9.258558670999946],
            [-38.180676499999947, -9.26527525399996],
            [-38.180571639999982, -9.274386119999974],
            [-38.180488873999934, -9.283074309999961],
            [-38.180441328999962, -9.287098813999933],
            [-38.180359954999972, -9.294458067999926],
            [-38.180316939999955, -9.299928899999941],
            [-38.180232208999939, -9.30874062099997],
            [-38.180136691999962, -9.318373427999973],
            [-38.180049311999937, -9.32586122999993],
            [-38.179921199999967, -9.340189131999978],
            [-38.179837186999976, -9.34651589899994],
            [-38.179772205999939, -9.353035926999951],
            [-38.179728226999941, -9.360336900999926],
            [-38.179526455999962, -9.377161108999928],
            [-38.179512447999969, -9.382097491999957],
            [-38.179489665999938, -9.385706565999953],
            [-38.184449882999957, -9.392095612999981],
            [-38.18960371299994, -9.398792193999952],
            [-38.19072797299998, -9.400238507999973],
            [-38.194127932999947, -9.40103011399998],
            [-38.195077706999939, -9.40056220699995],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Goianinha - Campina Grande II C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 99.46132,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.89334,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.089368899999954, -7.588683959999969],
            [-35.089380796999933, -7.589023032999933],
            [-35.089898329999983, -7.589278824999951],
            [-35.091530639999974, -7.589320465999947],
            [-35.093810163999933, -7.587650083999961],
            [-35.100025315999972, -7.58310055499993],
            [-35.105636084999958, -7.582429546999947],
            [-35.110304586999973, -7.582929233999948],
            [-35.114011787999971, -7.582058350999944],
            [-35.119417922999958, -7.580844825999975],
            [-35.122225686999968, -7.580235683999945],
            [-35.209861043999979, -7.546349674999931],
            [-35.213390818999983, -7.545013835999953],
            [-35.222616124999945, -7.541451371999926],
            [-35.229042599999957, -7.538970196999969],
            [-35.235389514999952, -7.53655213299993],
            [-35.252407357999971, -7.530061987999943],
            [-35.256580950999933, -7.528469364999978],
            [-35.261092710999947, -7.526997807999976],
            [-35.264315852999971, -7.525967485999956],
            [-35.268135556999937, -7.524405699999932],
            [-35.273063094999941, -7.522169915999939],
            [-35.283195448999948, -7.518285741999932],
            [-35.285645804999945, -7.517401527999937],
            [-35.291603081999938, -7.513116843999967],
            [-35.296006997999939, -7.509995923999952],
            [-35.299833056999944, -7.507278011999972],
            [-35.309655030999977, -7.50031050299998],
            [-35.31732448799994, -7.494850569999926],
            [-35.328609330999939, -7.486866835999933],
            [-35.333316519999983, -7.483542632999956],
            [-35.339715076999937, -7.479046681999932],
            [-35.428750365999974, -7.415998717999969],
            [-35.442313206999984, -7.406366401999946],
            [-35.452100280999957, -7.403044946999955],
            [-35.461481305999939, -7.399743444999956],
            [-35.472878923999929, -7.395805436999979],
            [-35.481516367999973, -7.392848956999956],
            [-35.493163495999966, -7.388734813999974],
            [-35.504543253999941, -7.38476560099997],
            [-35.511256645999936, -7.382405937999977],
            [-35.521265144999973, -7.378918079999949],
            [-35.532101746999956, -7.375088588999972],
            [-35.542820949999964, -7.371281430999943],
            [-35.554118938999977, -7.367361975999927],
            [-35.563938391999955, -7.363915248999945],
            [-35.577061011999945, -7.359351192999952],
            [-35.587654598999961, -7.355634771999974],
            [-35.597953559999951, -7.352052289999961],
            [-35.610770975999969, -7.347598233999975],
            [-35.619718890999934, -7.34450177399998],
            [-35.632049785999982, -7.340166311999951],
            [-35.636794065999936, -7.338478261999967],
            [-35.64047319499997, -7.337599918999956],
            [-35.644383694999931, -7.336663580999925],
            [-35.648678745999973, -7.335589652999943],
            [-35.651380338999957, -7.33494894699993],
            [-35.659576762999961, -7.332937157999936],
            [-35.667534736999983, -7.331003204999945],
            [-35.671173440999951, -7.330102572999976],
            [-35.678553010999963, -7.328282295999941],
            [-35.683280899999943, -7.327123649999976],
            [-35.68759862099995, -7.326090144999966],
            [-35.691228357999933, -7.325156607999929],
            [-35.701607254999942, -7.322677812999927],
            [-35.70341300399997, -7.322173015999965],
            [-35.707113065999977, -7.321274769999945],
            [-35.718451280999943, -7.31865494799996],
            [-35.721572289999983, -7.317842324999958],
            [-35.727409001999945, -7.316438022999932],
            [-35.748638076999953, -7.311317867999946],
            [-35.759530489999975, -7.308663133999971],
            [-35.777369654999973, -7.304359717999944],
            [-35.790243832999977, -7.301326788999972],
            [-35.797975779999945, -7.29948694899997],
            [-35.815314217999969, -7.295393557999944],
            [-35.831233837999946, -7.291652222999971],
            [-35.848916492999933, -7.287420619999978],
            [-35.862702482999964, -7.284086798999965],
            [-35.871637303999933, -7.281942753999942],
            [-35.882020171999955, -7.27944021199994],
            [-35.888045901999931, -7.278040672999964],
            [-35.895284971999956, -7.276401811999961],
            [-35.905593550999981, -7.273010263999936],
            [-35.908382947999939, -7.272083676999955],
            [-35.908405189999939, -7.270704988999967],
            [-35.908336471999974, -7.267797157999951],
            [-35.908382687999961, -7.267155603999981],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Campina Grande II - Campina Grande III C1',
        Concession: 'ETN - EXTREMOZ TRANSMISSORA DO NORDESTE',
        Tensao: 230.0,
        Extensao: 9.94375,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.08868,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.980170416999954, -7.25469275699993],
            [-35.979993329999957, -7.256170761999954],
            [-35.976397, -7.259864],
            [-35.971808864999957, -7.263787289999925],
            [-35.962579468999934, -7.26648578399994],
            [-35.955336130999967, -7.26862078399995],
            [-35.937900560999935, -7.278394591999927],
            [-35.930228513999964, -7.282716868999955],
            [-35.921829432999971, -7.281766071999925],
            [-35.918952944999944, -7.276655648999963],
            [-35.914800511999943, -7.273834677999957],
            [-35.910482864999949, -7.272800194999945],
            [-35.90903110499994, -7.27130187299997],
            [-35.908944966999968, -7.270226444999935],
            [-35.908639669999957, -7.267944395999962],
            [-35.908670135999955, -7.267155264999928],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Campina Grande II - Campina Grande III C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 10.65861,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.09473,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.910272145999954, -7.267691486999979],
            [-35.910291550999943, -7.26870073799995],
            [-35.910225814999933, -7.27162802099997],
            [-35.913673480999933, -7.275108170999943],
            [-35.916526873999942, -7.278021348999971],
            [-35.919685439999967, -7.281179425999937],
            [-35.923855140999933, -7.281607427999973],
            [-35.930024756999956, -7.282331656999929],
            [-35.934925822999958, -7.277367854999966],
            [-35.936854303999951, -7.27536835899997],
            [-35.939125137999952, -7.267827233999981],
            [-35.941567146999944, -7.260308745999964],
            [-35.942503662999968, -7.260241070999939],
            [-35.945861331999936, -7.260310172999937],
            [-35.955504272999974, -7.260507272999973],
            [-35.964171575999956, -7.260683136999944],
            [-35.969387284999982, -7.260777100999974],
            [-35.974765260999959, -7.260798353999974],
            [-35.979601205999984, -7.256031749999977],
            [-35.979890024999975, -7.254644103999965],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Campina Grande II - Campina Grande III C3',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 10.69892,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.09509,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.910128851999957, -7.267708352999932],
            [-35.910166462999939, -7.26869329799996],
            [-35.910108793999939, -7.271668409999961],
            [-35.913802543999964, -7.275425323999968],
            [-35.919626574999938, -7.281263470999932],
            [-35.930111652999983, -7.282435407999969],
            [-35.936949483999967, -7.275467221999975],
            [-35.938136549999967, -7.271392468999977],
            [-35.941632829999946, -7.260377204999941],
            [-35.942506445999982, -7.260323827999969],
            [-35.955504209999958, -7.260603078999964],
            [-35.969355881999945, -7.260892363999972],
            [-35.974802121999971, -7.260886508999931],
            [-35.979683115999933, -7.256098007999981],
            [-35.98001437299996, -7.254830789999971],
            [-35.980026578999968, -7.254656195999928],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Recife II - Goianinha C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 73.44819,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.6396,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.089092568999945, -7.588692004999928],
            [-35.08924425999993, -7.589718147999974],
            [-35.089017602999945, -7.59310871699995],
            [-35.088960526999983, -7.59744531399997],
            [-35.088694091999969, -7.608843136999951],
            [-35.088618771999961, -7.611758035999969],
            [-35.08850268499998, -7.615529595999931],
            [-35.085602276999964, -7.624751190999973],
            [-35.083923029999937, -7.629943845999946],
            [-35.081637462999936, -7.637073953999959],
            [-35.079129981999984, -7.644792346999964],
            [-35.076771347999966, -7.65221891799996],
            [-35.07373956899994, -7.661628684999926],
            [-35.071229310999968, -7.669463098999927],
            [-35.066484602999935, -7.678109113999938],
            [-35.063322094999933, -7.683859384999948],
            [-35.061309592999976, -7.687511437999945],
            [-35.05621849299996, -7.696816042999956],
            [-35.051213141999938, -7.705944907999935],
            [-35.044481738999934, -7.71819269599996],
            [-35.039489398999933, -7.72732760699995],
            [-35.029783830999975, -7.745008884999947],
            [-35.022775723999985, -7.757752019999941],
            [-35.015541505999977, -7.771092661999944],
            [-35.013952191999977, -7.772358785999927],
            [-35.009823478999976, -7.775653256999931],
            [-34.980124572999955, -7.799516996999955],
            [-34.970993741999962, -7.806847170999959],
            [-34.958785593999949, -7.816645880999943],
            [-34.956135210999946, -7.823019120999959],
            [-34.956683729999952, -7.830461466999964],
            [-34.957777504999967, -7.842974395999931],
            [-34.958976839999934, -7.858492138999964],
            [-34.959370203999981, -7.862913727999967],
            [-34.964813359999937, -7.874508859999935],
            [-34.96805261399993, -7.881498533999945],
            [-34.973094895999964, -7.89226532999993],
            [-34.976272005999931, -7.899151970999981],
            [-34.979888720999952, -7.906791786999975],
            [-34.983768624999982, -7.915210182999942],
            [-34.985752642999955, -7.919457696999928],
            [-34.989188939999963, -7.926814753999963],
            [-34.993424053999945, -7.936526873999981],
            [-34.997674762999964, -7.946307261999948],
            [-34.999638795999942, -7.950830192999945],
            [-35.001318652999942, -7.954719150999949],
            [-35.00399639099993, -7.960910200999933],
            [-35.006513533999964, -7.966612196999961],
            [-35.009333138999978, -7.97310400899994],
            [-35.012746369999945, -7.980950717999974],
            [-35.013769811999964, -7.983290478999947],
            [-35.016263912999932, -7.988987625999926],
            [-35.01672620599993, -7.990103637999937],
            [-35.016446559999963, -7.991223100999946],
            [-35.01513809599993, -7.996084128999939],
            [-35.014222144999962, -7.999929734999967],
            [-35.012821604999942, -8.005026219999934],
            [-35.010890916999983, -8.012103901999978],
            [-35.008394259999932, -8.013773147999927],
            [-35.004418265999959, -8.016475126999978],
            [-34.999993862999929, -8.019458673999964],
            [-34.998258943999929, -8.020739317999926],
            [-35.000261489999957, -8.025144052999963],
            [-34.99786030599995, -8.027221937999968],
            [-34.996785519999946, -8.030202154999927],
            [-34.996442029999969, -8.033717937999938],
            [-34.99592679899996, -8.038892258999965],
            [-34.994250810999972, -8.042275939999968],
            [-34.996742429999983, -8.046489],
            [-34.995669692999968, -8.048519825999961],
            [-34.99152277099995, -8.056695614999967],
            [-34.989317376999963, -8.060976537999977],
            [-34.980799350999973, -8.060329561999936],
            [-34.980079427999954, -8.067185130999974],
            [-34.979604331999951, -8.069959026999982],
            [-34.978951508999955, -8.072071023999968],
            [-34.977588728999933, -8.076748118999944],
            [-34.977265293999949, -8.07787084499995],
            [-34.978226416999973, -8.078962624999974],
            [-34.987709122999945, -8.081697150999958],
            [-34.994885576999934, -8.083826768999927],
            [-35.000391649999983, -8.085506667999937],
            [-35.006954202999964, -8.087553003999972],
            [-35.012898096999947, -8.089370912999925],
            [-35.017898147999972, -8.09094020699996],
            [-35.021633901999962, -8.094633129999977],
            [-35.024537642999974, -8.09752102799996],
            [-35.027310344999933, -8.100236544999973],
            [-35.03088470199998, -8.10379203399998],
            [-35.03219874499996, -8.105086219999976],
            [-35.03343702899997, -8.106961826999964],
            [-35.03372844699993, -8.109215147999976],
            [-35.033974609999973, -8.11261226299996],
            [-35.034154453999975, -8.113190992999932],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Recife II - Suape II C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 46.70919,
        Ano_Opera: 2012.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.41215,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.048869256999978, -8.390046327999926],
            [-35.04948315799993, -8.390196233999973],
            [-35.053697661999934, -8.390181638999934],
            [-35.05880244399998, -8.390168352999979],
            [-35.068715146999978, -8.387113107999937],
            [-35.079929861999972, -8.383628833999978],
            [-35.090872354999931, -8.380215921999934],
            [-35.09241764199993, -8.37807272799995],
            [-35.099944272999949, -8.367225108999946],
            [-35.101625620999982, -8.364777468999932],
            [-35.103200191999974, -8.36329047199996],
            [-35.110912264999968, -8.355631852999977],
            [-35.114868768999941, -8.351827205999939],
            [-35.119748794999964, -8.347126122999953],
            [-35.124239575999979, -8.342627300999936],
            [-35.126888219999955, -8.340014670999949],
            [-35.132357448999983, -8.332348356999944],
            [-35.134164344999931, -8.329824781999946],
            [-35.142393886999969, -8.318368920999944],
            [-35.151342941999928, -8.305971371999931],
            [-35.157544000999962, -8.29716882699995],
            [-35.158489835999944, -8.293699968999931],
            [-35.160893091999981, -8.28544323999995],
            [-35.165128531999983, -8.271047500999941],
            [-35.167043998999929, -8.26423034499993],
            [-35.173837359999936, -8.257793902999936],
            [-35.17737085899995, -8.256877809999935],
            [-35.183821577999936, -8.258657249999942],
            [-35.184696029999941, -8.256902397999966],
            [-35.18083297499993, -8.253230096999971],
            [-35.136534918999985, -8.210028362999935],
            [-35.130468081999936, -8.203849085999934],
            [-35.12786416299997, -8.201690731999975],
            [-35.123838114999955, -8.198150093999971],
            [-35.117629622999971, -8.192023451999944],
            [-35.113250649999941, -8.18772656699997],
            [-35.101361070999928, -8.176115014999937],
            [-35.095711378999965, -8.170593373999964],
            [-35.086754379999945, -8.161794445999931],
            [-35.07711057399996, -8.15225004499996],
            [-35.064034033999974, -8.139289469999937],
            [-35.056510615999969, -8.131860869999969],
            [-35.041873829999929, -8.125130869999964],
            [-35.036513442999933, -8.118028435999975],
            [-35.035893880999936, -8.11644976499997],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Messias - Suape II C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 181.94495,
        Ano_Opera: 2012.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.59303,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.841216709999969, -9.401212040999951],
            [-35.840355273999933, -9.401296101999947],
            [-35.838055237999981, -9.401326814999948],
            [-35.830497605999938, -9.39687620199993],
            [-35.826599367999961, -9.394732510999972],
            [-35.820522435999976, -9.391132659999926],
            [-35.816211953999982, -9.386533536999934],
            [-35.813626437999972, -9.383841371999949],
            [-35.810583980999979, -9.380475213999944],
            [-35.807028269999932, -9.376818753999942],
            [-35.803214754999942, -9.373295604999953],
            [-35.799369534999983, -9.368556861999934],
            [-35.798519607999935, -9.36759197799995],
            [-35.79772801799993, -9.358561578999968],
            [-35.79715233099995, -9.353094132999956],
            [-35.796406895999951, -9.345831569999973],
            [-35.796147383999937, -9.34289369399994],
            [-35.795129820999932, -9.333162151999943],
            [-35.792560898999966, -9.327688032999959],
            [-35.789821497999981, -9.321798542999943],
            [-35.787952558999962, -9.317520753999929],
            [-35.785565912999971, -9.312500136999972],
            [-35.783526507999966, -9.308836528999962],
            [-35.781733191999933, -9.305584103999934],
            [-35.779167993999977, -9.300839581999981],
            [-35.775768, -9.294869964999975],
            [-35.773298745999966, -9.290571185999966],
            [-35.77123536299996, -9.286881707999953],
            [-35.769521485999974, -9.283762112999966],
            [-35.76721157399993, -9.279551348999973],
            [-35.764059480999947, -9.27409615199997],
            [-35.762263373999929, -9.27072044099998],
            [-35.758678375999978, -9.264220437999938],
            [-35.753945126999952, -9.25620820499995],
            [-35.750424776999978, -9.249805728999945],
            [-35.747987086999956, -9.245321163999961],
            [-35.748704767999982, -9.238849767999966],
            [-35.749524933999965, -9.230770731999939],
            [-35.750393023999948, -9.223491771999932],
            [-35.750742543999934, -9.220379109999953],
            [-35.748725974999957, -9.214798511999959],
            [-35.746502742999951, -9.209528101999979],
            [-35.744727179999984, -9.204331061999937],
            [-35.742972174999977, -9.199668964999944],
            [-35.73966807499994, -9.190657715999976],
            [-35.737483538999982, -9.185282333999965],
            [-35.735532241999977, -9.179928361999941],
            [-35.733727094999949, -9.175001670999961],
            [-35.732554109999967, -9.171913250999978],
            [-35.729983546999961, -9.165122310999948],
            [-35.727592776999984, -9.16042682799997],
            [-35.725558425999964, -9.157171765999976],
            [-35.722921178999968, -9.153073931999927],
            [-35.721066166999947, -9.149957854999968],
            [-35.718122936999976, -9.145443331999957],
            [-35.716762853999967, -9.143234511999935],
            [-35.715196301999981, -9.140544272999932],
            [-35.710730029999979, -9.13360154399993],
            [-35.708303157999978, -9.129784627999982],
            [-35.705522060999954, -9.125662693999971],
            [-35.704037761999984, -9.123443928999961],
            [-35.700334163999969, -9.117819850999979],
            [-35.697833617999947, -9.114037804999953],
            [-35.695901509999942, -9.111102507999931],
            [-35.693718161999982, -9.107851008999944],
            [-35.690707646999954, -9.103052978999926],
            [-35.688601763999941, -9.100086783999927],
            [-35.686337888999958, -9.096571512999958],
            [-35.684055584999953, -9.093041893999953],
            [-35.682815396999956, -9.091003076999925],
            [-35.680543768999939, -9.087753218999978],
            [-35.677998320999961, -9.083754407999947],
            [-35.675766750999969, -9.080412745999979],
            [-35.673237212999936, -9.076633032999951],
            [-35.670870824999952, -9.072989777999965],
            [-35.667079402999946, -9.066822749999972],
            [-35.665875433999929, -9.064946130999942],
            [-35.662715024999954, -9.060008010999979],
            [-35.658652306999954, -9.053576972999963],
            [-35.654818280999962, -9.047538994999968],
            [-35.651238869999929, -9.041762061999975],
            [-35.647348238999939, -9.035616454999968],
            [-35.644072540999957, -9.030450068999926],
            [-35.64123824099994, -9.026046710999935],
            [-35.638248425999961, -9.021415557999944],
            [-35.635865980999938, -9.017425294999953],
            [-35.636506446999931, -9.004425998999977],
            [-35.637593989999971, -8.991096022999955],
            [-35.637583416999973, -8.989472377999959],
            [-35.63844727399993, -8.977832099999944],
            [-35.640016528999979, -8.955612692999978],
            [-35.641827298999942, -8.930673561999981],
            [-35.643254974999934, -8.910205438999981],
            [-35.644482776999951, -8.89297338199998],
            [-35.645696301999976, -8.875284468999951],
            [-35.646638568999947, -8.86214984399993],
            [-35.642955162999954, -8.848030122999944],
            [-35.637130242999945, -8.824687609999955],
            [-35.632471257999953, -8.790480478999939],
            [-35.630201251999949, -8.773134207999931],
            [-35.628802128999951, -8.767023751999943],
            [-35.620449427999972, -8.730966985999942],
            [-35.616566146999958, -8.714320276999956],
            [-35.61469589099994, -8.706025475999979],
            [-35.610036112999978, -8.686540069999978],
            [-35.598840748999976, -8.675023478999947],
            [-35.593867675999945, -8.669955226999946],
            [-35.578139437999937, -8.653810583999928],
            [-35.570941568999956, -8.646303383999964],
            [-35.557973505999939, -8.633061683999927],
            [-35.549084630999971, -8.624100403999932],
            [-35.54122285699998, -8.616003970999941],
            [-35.53456284899994, -8.609183325999936],
            [-35.526863759999969, -8.601212517999954],
            [-35.517889600999979, -8.59201484099998],
            [-35.510379199999932, -8.584248349999939],
            [-35.502756271999942, -8.576431301999946],
            [-35.495806762999962, -8.569349233999958],
            [-35.486798788999977, -8.560088745999963],
            [-35.477318446999959, -8.550352859999975],
            [-35.468526875999942, -8.541316852999955],
            [-35.461020927999982, -8.533572197999945],
            [-35.45351429699997, -8.525895602999981],
            [-35.449944062999975, -8.52235372399997],
            [-35.44701349199994, -8.519237440999973],
            [-35.443420633999949, -8.515627037999934],
            [-35.43717098299993, -8.509203227999933],
            [-35.43036157399996, -8.502179212999977],
            [-35.424935586999936, -8.496574573999965],
            [-35.418711606999977, -8.490172804999929],
            [-35.412257623999949, -8.483579207999981],
            [-35.403354451999974, -8.47439021699995],
            [-35.398127450999937, -8.468999022999981],
            [-35.391194384999949, -8.461843682999927],
            [-35.388400049999973, -8.459018875999959],
            [-35.383913328999938, -8.454431850999981],
            [-35.378223566999964, -8.448608930999967],
            [-35.371749177999959, -8.441901402999974],
            [-35.367269522999948, -8.437341606999951],
            [-35.361369471999978, -8.431276384999933],
            [-35.354658866999955, -8.424307434999946],
            [-35.351325385999928, -8.421026280999968],
            [-35.348125546999938, -8.41771418299993],
            [-35.342524478999962, -8.411845934999974],
            [-35.296759092999935, -8.366493761999948],
            [-35.284885982999981, -8.354901199999972],
            [-35.259492216999945, -8.330124527999942],
            [-35.24095218399998, -8.312131755999928],
            [-35.227951476999976, -8.299378957999977],
            [-35.210602277999953, -8.282414384999981],
            [-35.203588949999983, -8.275468141999966],
            [-35.189044494999962, -8.26125086299993],
            [-35.184785259999956, -8.259412728999962],
            [-35.177358961999971, -8.257329907999974],
            [-35.174015818999976, -8.258198410999967],
            [-35.167472301999965, -8.264492084999972],
            [-35.165616321999948, -8.270916629999931],
            [-35.161333291999938, -8.28557410999997],
            [-35.159203674999958, -8.293069411999966],
            [-35.157972303999941, -8.297323491999975],
            [-35.142704649999985, -8.31882817099995],
            [-35.13493912399997, -8.329633159999958],
            [-35.127255293999951, -8.34028486699998],
            [-35.122712603999958, -8.344787432999965],
            [-35.111757388999933, -8.355507925999973],
            [-35.101953753999965, -8.364989692999927],
            [-35.096791106999945, -8.372507588999952],
            [-35.090950559999953, -8.380637784999976],
            [-35.084805388999939, -8.382595324999954],
            [-35.082262580999952, -8.383396946999937],
            [-35.079868093999949, -8.384095111999954],
            [-35.068598130999931, -8.387605126999972],
            [-35.058798558999968, -8.390609818999962],
            [-35.051757473999942, -8.390652273999933],
            [-35.049492837999935, -8.390494024999953],
            [-35.04889067299996, -8.390367555999944],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Angelim II - Recife II C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 173.67936,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.54969,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.296334564999938, -8.895698308999954],
            [-36.291909287999943, -8.897185946999969],
            [-36.280681538999943, -8.894524265999962],
            [-36.268049571999939, -8.895146601999954],
            [-36.25855835599998, -8.895585590999929],
            [-36.246927331999984, -8.896143656999925],
            [-36.234893068999952, -8.896682802999976],
            [-36.215042323999967, -8.897603174999972],
            [-36.20129275499994, -8.898271042999966],
            [-36.184085434999929, -8.899052917999938],
            [-36.173612155999933, -8.899545281999963],
            [-36.163110562999975, -8.900050276999934],
            [-36.150857615999939, -8.900597822999941],
            [-36.142515139999944, -8.900994055999945],
            [-36.133146057999966, -8.901123651999967],
            [-36.118288735999954, -8.901301933999946],
            [-36.104808224999942, -8.901616139999931],
            [-36.091728620999959, -8.89821264699998],
            [-36.075984363999964, -8.894195794999973],
            [-36.067404414999942, -8.892005324999957],
            [-36.052132511999957, -8.888016190999963],
            [-36.035768952999945, -8.883773213999973],
            [-36.021382457999948, -8.880011883999941],
            [-36.002059600999985, -8.87501526899996],
            [-35.987688950999939, -8.871184205999953],
            [-35.968543107999949, -8.866016935999937],
            [-35.955692922999958, -8.862569497999971],
            [-35.935310912999967, -8.856985686999963],
            [-35.923813026999937, -8.853865374999941],
            [-35.915509833999977, -8.851595865999968],
            [-35.903458938999961, -8.846551861999956],
            [-35.88517636399996, -8.838897602999964],
            [-35.867398264999963, -8.831461518999959],
            [-35.859650348999935, -8.828221520999932],
            [-35.855110827999965, -8.824419106999926],
            [-35.847583805999932, -8.817642127999932],
            [-35.840814704999957, -8.811427407999929],
            [-35.819527405999963, -8.800567312999931],
            [-35.816753715999937, -8.794075458999941],
            [-35.80903980599993, -8.788260201999947],
            [-35.798366273999932, -8.780280918999949],
            [-35.792493862999947, -8.775851853999939],
            [-35.783648892999963, -8.771779822999974],
            [-35.775237687999947, -8.767879102999927],
            [-35.76338039999996, -8.762388001999966],
            [-35.751471626999944, -8.756885262999958],
            [-35.741632267999933, -8.752341240999954],
            [-35.73358395899993, -8.748595721999948],
            [-35.722554158999969, -8.745004631999961],
            [-35.717630103999966, -8.743411940999977],
            [-35.711839793999957, -8.739521415999945],
            [-35.707224434999944, -8.736399823999932],
            [-35.700287680999963, -8.733242920999942],
            [-35.692249519999962, -8.729538590999937],
            [-35.683006181999929, -8.72525560899993],
            [-35.670216685999947, -8.719364962999975],
            [-35.658621996999955, -8.714036963999945],
            [-35.649552273999973, -8.70987682599997],
            [-35.63710113999997, -8.704153216999941],
            [-35.625589913999931, -8.698811993999925],
            [-35.619975460999967, -8.696301583999968],
            [-35.617130933999931, -8.693371151999941],
            [-35.610504336999952, -8.68658938699997],
            [-35.606076737999956, -8.681957377999936],
            [-35.602178895999941, -8.67798156899994],
            [-35.595724578999977, -8.671381353999948],
            [-35.585265254999968, -8.660636923999959],
            [-35.578562210999962, -8.653721331999975],
            [-35.571084372999962, -8.64603380799997],
            [-35.562951479999981, -8.637692911999977],
            [-35.553482053999971, -8.627920763999953],
            [-35.546174034999979, -8.620464199999958],
            [-35.534227185999953, -8.608238997999933],
            [-35.522594313999946, -8.596272092999925],
            [-35.51475097399998, -8.58823191099998],
            [-35.503431244999945, -8.576603612999975],
            [-35.489670120999961, -8.562473567999973],
            [-35.462021844999981, -8.533994887999938],
            [-35.450897479999981, -8.522650786999975],
            [-35.434804630999963, -8.506089380999981],
            [-35.425819467999929, -8.496876825999948],
            [-35.416193265999937, -8.486926963999963],
            [-35.406840480999961, -8.477355508999949],
            [-35.393707971999959, -8.463829352999937],
            [-35.387867805999974, -8.457865152999943],
            [-35.37973534799994, -8.449491581999951],
            [-35.372274488999949, -8.441845766999961],
            [-35.359205241999973, -8.428426428999956],
            [-35.351569846999951, -8.420543832999954],
            [-35.34287498599997, -8.411600058999966],
            [-35.296980761999976, -8.366110203999938],
            [-35.25976603099997, -8.329816969999968],
            [-35.253594471999975, -8.323794035999981],
            [-35.235289727999941, -8.306038098999977],
            [-35.213325368999961, -8.284510405999981],
            [-35.184803105999947, -8.256432453999935],
            [-35.136523021999949, -8.209445394999932],
            [-35.131869686999948, -8.204719226999941],
            [-35.130655039999965, -8.203464599999961],
            [-35.11787048399998, -8.191161617999967],
            [-35.096095349999985, -8.169874209999932],
            [-35.089716231999944, -8.163664605999941],
            [-35.080214422999973, -8.154248688999928],
            [-35.068738774999929, -8.142850112999952],
            [-35.056603326999948, -8.130882002999954],
            [-35.042096775999937, -8.124769828999945],
            [-35.036677882999982, -8.117582758999959],
            [-35.036208596999984, -8.116340875999981],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Angelim II - Pau Ferro C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 226.99952,
        Ano_Opera: 2016.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 2.00846,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.296361898999976, -8.895397139999943],
            [-36.291921457999933, -8.89677295499996],
            [-36.280723451999961, -8.89411383099997],
            [-36.262960164999981, -8.894968528999925],
            [-36.250141392999979, -8.895550833999948],
            [-36.245694920999938, -8.895786179999959],
            [-36.234242464999966, -8.896327344999975],
            [-36.22510306199996, -8.896721556999978],
            [-36.215197632999946, -8.897210796999957],
            [-36.206608907999964, -8.897608748999971],
            [-36.196337149999977, -8.898090009999976],
            [-36.183905916999947, -8.898690100999943],
            [-36.173224490999985, -8.899177375999955],
            [-36.15715684099996, -8.89990434799995],
            [-36.142495423999947, -8.900584074999927],
            [-36.127875184999937, -8.900790653999934],
            [-36.115981017999957, -8.90095626599998],
            [-36.105517478999957, -8.901094889999968],
            [-36.10410946099995, -8.901039283999978],
            [-36.099428800999931, -8.899843410999949],
            [-36.090672813999959, -8.89757414099995],
            [-36.079395801999965, -8.894671563999964],
            [-36.071915677999982, -8.892757931999938],
            [-36.061785842999939, -8.890121535999981],
            [-36.05247355299997, -8.887704843999927],
            [-36.043354416999932, -8.885326977999966],
            [-36.036240057999976, -8.883472578999942],
            [-36.021253919999936, -8.879528440999934],
            [-36.002142908999929, -8.874604260999945],
            [-35.987763628999971, -8.870793117999938],
            [-35.974462913999957, -8.867236109999965],
            [-35.959830358999966, -8.863276423999935],
            [-35.946935983999936, -8.859733110999969],
            [-35.93476411599994, -8.856404610999959],
            [-35.926399035999964, -8.85415059099995],
            [-35.91554554399994, -8.851171941999951],
            [-35.908438693999983, -8.84818942499993],
            [-35.89921609299995, -8.84433689399998],
            [-35.885201655999936, -8.838466773999926],
            [-35.875388590999933, -8.83437039599994],
            [-35.863182794999943, -8.829244201999927],
            [-35.859939553999936, -8.827906765999955],
            [-35.855468041999984, -8.824269928999968],
            [-35.851061190999928, -8.82025065199997],
            [-35.847525613999949, -8.817080757999975],
            [-35.84596486099997, -8.815532781999934],
            [-35.841033008999943, -8.811092773999974],
            [-35.832674090999944, -8.806809747999978],
            [-35.82180899399998, -8.801309529999969],
            [-35.819823903999975, -8.80029891099997],
            [-35.81705947699993, -8.793797309999945],
            [-35.80961440599998, -8.788189044999967],
            [-35.798391381999977, -8.779783669999972],
            [-35.792687112999943, -8.775487060999978],
            [-35.775311896999938, -8.767458035999937],
            [-35.763864260999981, -8.762168374999931],
            [-35.755589038999972, -8.758356806999927],
            [-35.744367472999954, -8.75316288099998],
            [-35.733717021999951, -8.748237608999943],
            [-35.717813217999947, -8.74304897199994],
            [-35.707386191999944, -8.736051778999979],
            [-35.688330333999943, -8.727258720999941],
            [-35.674469425999973, -8.72090009599998],
            [-35.664459506999947, -8.716266516999951],
            [-35.650217646999977, -8.709790249999969],
            [-35.631472566999946, -8.701118120999979],
            [-35.623646161999943, -8.697513276999928],
            [-35.619869369999947, -8.695587309999951],
            [-35.612574154999947, -8.688126523999927],
            [-35.606164156999967, -8.681543904999955],
            [-35.601906939999935, -8.67717891999996],
            [-35.595945881999967, -8.671069078999949],
            [-35.589888393999956, -8.664759327999946],
            [-35.584526991999951, -8.659309596999947],
            [-35.57912476599995, -8.653733891999934],
            [-35.576137537999955, -8.650680020999971],
            [-35.568628699999977, -8.642998054999964],
            [-35.56349002099995, -8.637708883999949],
            [-35.553356359999952, -8.627313271999981],
            [-35.542233151999937, -8.61587194599997],
            [-35.534337389999962, -8.607782340999961],
            [-35.526688044999958, -8.599845166999955],
            [-35.517226103999974, -8.590219739999952],
            [-35.507161519999954, -8.579879565999931],
            [-35.501617067999973, -8.57415171599996],
            [-35.496067551999943, -8.568512085999942],
            [-35.492723017999936, -8.565020989999937],
            [-35.482349282999962, -8.554384091999964],
            [-35.475207786999931, -8.546996313999955],
            [-35.46828381399996, -8.53994150799997],
            [-35.458888262999949, -8.530263372999968],
            [-35.451669126999946, -8.52285711199994],
            [-35.443606215999978, -8.514575350999962],
            [-35.435032945999978, -8.505777699999953],
            [-35.425958820999938, -8.496454151999956],
            [-35.419344217999935, -8.489673076999964],
            [-35.412543833999962, -8.482716293999943],
            [-35.407211184999937, -8.477207628999963],
            [-35.401751332999936, -8.471576451999965],
            [-35.399095257999932, -8.468851617999974],
            [-35.394017153999982, -8.46362576599995],
            [-35.38851684399998, -8.458025316999965],
            [-35.380199825999966, -8.449428834999935],
            [-35.371606154999938, -8.440531633999967],
            [-35.362912080999934, -8.431608718999939],
            [-35.354922970999951, -8.423383116999958],
            [-35.348217538999961, -8.416489952999939],
            [-35.340498408999963, -8.408587488999956],
            [-35.292787608999959, -8.361508899999933],
            [-35.285998200999984, -8.35488264199995],
            [-35.278583042999969, -8.34764856299995],
            [-35.272783287999971, -8.342047782999941],
            [-35.26653730299995, -8.335976040999981],
            [-35.259985858999983, -8.329511528999944],
            [-35.253906049999955, -8.323633466999979],
            [-35.249427021999963, -8.319227503999969],
            [-35.244794701999979, -8.314721586999951],
            [-35.244904692999967, -8.314625790999969],
            [-35.244720284999971, -8.310740130999932],
            [-35.244427610999935, -8.30530306299994],
            [-35.244227735999971, -8.29760788599998],
            [-35.244013584999948, -8.29188290299993],
            [-35.243473446999928, -8.278636442999925],
            [-35.243247397999937, -8.271973952999929],
            [-35.243199808999975, -8.265287666999939],
            [-35.242997554999931, -8.260992739999949],
            [-35.242973759999984, -8.255365314999949],
            [-35.245205694999981, -8.231123365999963],
            [-35.245990916999972, -8.223371081999971],
            [-35.247118780999983, -8.211392874999945],
            [-35.248189538999952, -8.199400391999973],
            [-35.249381648999929, -8.187581608999949],
            [-35.250304879999931, -8.177135774999954],
            [-35.248544077999952, -8.171560697999951],
            [-35.247953971999948, -8.169623816999945],
            [-35.244227735999971, -8.157217306999939],
            [-35.24184351599996, -8.14949357699993],
            [-35.238359984999931, -8.138248243999954],
            [-35.239516402999982, -8.134357824999938],
            [-35.241029740999977, -8.12906114499998],
            [-35.242700121999974, -8.123245741999938],
            [-35.245020096999951, -8.117658767999956],
            [-35.245412707999947, -8.113739795999948],
            [-35.247756476999939, -8.10551875799996],
            [-35.250611829999968, -8.095548815999962],
            [-35.251837251999973, -8.091253888999972],
            [-35.251551716999984, -8.088529405999964],
            [-35.250754597999958, -8.081688455999938],
            [-35.249612455999966, -8.071504362999974],
            [-35.248803439999961, -8.06447305599994],
            [-35.247601811999971, -8.054039118999981],
            [-35.247244892999959, -8.050969613999939],
            [-35.246435875999964, -8.046305870999959],
            [-35.245115274999932, -8.03888195199994],
            [-35.24411590099993, -8.033123656999976],
            [-35.242247363999979, -8.022494765999966],
            [-35.237940543999969, -7.998023805999935],
            [-35.236234069999966, -7.988414746999979],
            [-35.236216562999971, -7.985783607999963],
            [-35.236068340999964, -7.973391039999967],
            [-35.235977842999944, -7.96373482499996],
            [-35.235866126999952, -7.954997531999936],
            [-35.235748175999959, -7.944230132999962],
            [-35.23561024199995, -7.931497400999945],
            [-35.235512533999952, -7.922545172999946],
            [-35.23393499499997, -7.919453674999943],
            [-35.220862223999973, -7.895002202999933],
            [-35.216979269999968, -7.887786925999933],
            [-35.211916549999955, -7.878266441999926],
            [-35.20968735699995, -7.874102002999962],
            [-35.207555482999965, -7.870178166999949],
            [-35.202356360999943, -7.860446171999968],
            [-35.200191050999933, -7.856032270999947],
            [-35.196883599999978, -7.84936978099995],
            [-35.19254108399997, -7.840720439999927],
            [-35.187913031999983, -7.831357260999937],
            [-35.184082099999955, -7.823647806999929],
            [-35.179156615999943, -7.813749248999954],
            [-35.173779033999949, -7.802946495999947],
            [-35.169438040999978, -7.794191025999964],
            [-35.165485281999963, -7.786235571999953],
            [-35.156894878999935, -7.778759306999973],
            [-35.15494322099994, -7.777024647999951],
            [-35.13565768899997, -7.777001027999972],
            [-35.118763515999945, -7.776905674999966],
            [-35.099192448999929, -7.77679859899996],
            [-35.095087878999948, -7.776762906999977],
            [-35.075388155999974, -7.780304392999938],
            [-35.06461173799994, -7.782412361999945],
            [-35.059280706999971, -7.783544604999975],
            [-35.055928018999964, -7.788514898999949],
            [-35.047028673999932, -7.795438173999969],
            [-35.035532639999929, -7.811707136999928],
            [-35.030153100999939, -7.819275563999952],
            [-35.026308080999968, -7.824759263999965],
            [-35.02168404899993, -7.831291819999933],
            [-35.022367996999947, -7.837036412999964],
            [-35.022040151999931, -7.840193807999981],
            [-35.023846756999944, -7.850542494999956],
            [-35.02086957299997, -7.854555627999957],
            [-35.021586663999983, -7.855839912999954],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Recife II - Pirapama II C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 28.42414,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.25181,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.028257696999958, -8.27069742599997],
            [-35.033428217999983, -8.265597481999976],
            [-35.051153640999985, -8.264072446999933],
            [-35.053100368999935, -8.262520298999959],
            [-35.061448523999957, -8.255774363999933],
            [-35.069148790999975, -8.249448987999926],
            [-35.078108291999968, -8.242151704999969],
            [-35.084211865999976, -8.23714954999997],
            [-35.097520163999945, -8.226173707999976],
            [-35.101573884999937, -8.222865867999928],
            [-35.104403318999971, -8.220572618999938],
            [-35.111187907999977, -8.215087553999979],
            [-35.119595745999959, -8.20828365899996],
            [-35.121479849999957, -8.206807018999939],
            [-35.125815244999956, -8.203282398999932],
            [-35.127483448999953, -8.201690731999975],
            [-35.12759766299996, -8.201129178999963],
            [-35.124114131999931, -8.197740825999972],
            [-35.117940158999943, -8.191676138999981],
            [-35.101699955999948, -8.175811870999951],
            [-35.095847072999959, -8.170099426999968],
            [-35.081283517999964, -8.155782704999979],
            [-35.056756685999972, -8.131525866999937],
            [-35.039146162999941, -8.114070079999976],
            [-35.036180469999977, -8.113536451999948],
            [-35.035959906999949, -8.113120368999944],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Recife II - Pau Ferro C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 34.65117,
        Ano_Opera: 2004.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.30115,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.020450713999935, -7.859657911999932],
            [-35.020929320999983, -7.860882001999926],
            [-35.022048782999946, -7.863856292999969],
            [-35.022886127999982, -7.86806753299993],
            [-35.023744295999961, -7.872743351999929],
            [-35.024416696999936, -7.876467857999955],
            [-35.025201974999959, -7.880462062999925],
            [-35.028932414999929, -7.883394559999942],
            [-35.035458457999937, -7.888496869999926],
            [-35.039246669999955, -7.891474742999947],
            [-35.041492568999956, -7.894927699999926],
            [-35.049315916999944, -7.893316875999972],
            [-35.058659546999934, -7.900581940999928],
            [-35.055751844999975, -7.910875488999977],
            [-35.056808325999953, -7.913811743999929],
            [-35.056061174999968, -7.918018630999939],
            [-35.05494282899997, -7.923577051999928],
            [-35.054352007999967, -7.925443378999944],
            [-35.052669041999934, -7.928211645999966],
            [-35.050726077999968, -7.931453462999968],
            [-35.045225109999933, -7.937101908999978],
            [-35.043469067999979, -7.944468719999975],
            [-35.041667498999971, -7.951961814999947],
            [-35.044192423999959, -7.957451058999936],
            [-35.047466561999954, -7.964418120999937],
            [-35.049146461999953, -7.96814911599995],
            [-35.052663304999953, -7.97576339099993],
            [-35.055580363999979, -7.978034437999952],
            [-35.058161040999948, -7.980148292999957],
            [-35.058654928999943, -7.98093066399997],
            [-35.060022539999977, -7.982141264999939],
            [-35.062245024999982, -7.984005071999945],
            [-35.06667067799998, -7.987669749999952],
            [-35.067545803999963, -7.99112019599994],
            [-35.069517721999944, -7.997771545999967],
            [-35.070843767999975, -8.002439070999969],
            [-35.074484256999938, -8.014664055999958],
            [-35.073425464999957, -8.018227924999962],
            [-35.070345352999936, -8.028020789999971],
            [-35.068300060999945, -8.034732990999942],
            [-35.06573745299994, -8.043117486999961],
            [-35.063162339999963, -8.051414067999929],
            [-35.061334457999976, -8.057225912999968],
            [-35.058680117999984, -8.065960650999955],
            [-35.055524832999936, -8.076293772999975],
            [-35.054163012999936, -8.08064193599995],
            [-35.052618824999968, -8.085629923999932],
            [-35.051428112999929, -8.089350406999927],
            [-35.050203806999946, -8.093493550999938],
            [-35.046891685999981, -8.097144123999954],
            [-35.044228486999941, -8.100018906999935],
            [-35.038970485999982, -8.105756403999976],
            [-35.035424961999979, -8.106434251999929],
            [-35.034928154999932, -8.10843267599995],
            [-35.034962814999972, -8.109963706999963],
            [-35.03480220299997, -8.111529190999931],
            [-35.034876500999928, -8.11244774499994],
            [-35.03511031599993, -8.112887378999972],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Recife II - Pau Ferro C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 34.64904,
        Ano_Opera: 2004.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.30113,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.020540275999963, -7.859619982999959],
            [-35.021024222999984, -7.860859657999981],
            [-35.022164, -7.863858852999954],
            [-35.022977234999985, -7.868035391999967],
            [-35.024537058999954, -7.876467033999973],
            [-35.025290239999947, -7.880404847999955],
            [-35.03547829799993, -7.888409220999961],
            [-35.039318442999956, -7.891434656999934],
            [-35.041525430999968, -7.894780344999958],
            [-35.049353830999962, -7.893208784999956],
            [-35.058778519999976, -7.900548628999957],
            [-35.055880335999973, -7.910880247999955],
            [-35.056936816999951, -7.913797467999927],
            [-35.056189665999966, -7.918051943999956],
            [-35.055080836999934, -7.923629399999982],
            [-35.05447232399996, -7.925555300999974],
            [-35.050822297999957, -7.931561699999975],
            [-35.045334564999962, -7.937154256999975],
            [-35.043583281999929, -7.944482996999966],
            [-35.041823303999934, -7.951959527999975],
            [-35.044320914999957, -7.957398710999939],
            [-35.047571257999948, -7.964375290999953],
            [-35.04926067599996, -7.968082490999961],
            [-35.052796554999929, -7.975711042999933],
            [-35.058255188999965, -7.980082633999928],
            [-35.058729557999982, -7.980850780999958],
            [-35.066775388999929, -7.987598609999964],
            [-35.072797779999973, -8.008607504999929],
            [-35.074621928999932, -8.014663929999926],
            [-35.068408328999965, -8.034789804999946],
            [-35.063280900999985, -8.051509751999959],
            [-35.057240759999956, -8.070994905999953],
            [-35.05429851599996, -8.080683177999958],
            [-35.05154030999995, -8.089342962999979],
            [-35.050290898999947, -8.093584143999976],
            [-35.03904816599993, -8.105866142999957],
            [-35.035500574999958, -8.106536837999954],
            [-35.03502289499994, -8.108441705999951],
            [-35.035063328999968, -8.109976260999929],
            [-35.034889361999944, -8.111542074999932],
            [-35.034963972999947, -8.11238369299997],
            [-35.035210955999958, -8.112848595999935],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Pau Ferro - UTE Pernambuco III C1',
        Concession: 'UTE PERNAMBUCO III - TERMELÉTRICA PERNAMBUCO III S.A.',
        Tensao: 230.0,
        Extensao: 0.51754,
        Ano_Opera: 2013.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.0045,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.02013696299997, -7.863506851999944],
            [-35.020770892999963, -7.863317448999965],
            [-35.021108822999963, -7.862675391999971],
            [-35.019986382999946, -7.859769899999947],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Pau Ferro - Campina Grande II C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 128.51177,
        Ano_Opera: 2003.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.14239,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.020286842999951, -7.858923702999959],
            [-35.019852101999959, -7.85773222499995],
            [-35.01687646299996, -7.850333963999958],
            [-35.013274749999937, -7.841540298999973],
            [-35.017473361999976, -7.835609112999975],
            [-35.01747684399993, -7.835609116999933],
            [-35.030110744999945, -7.817777059999969],
            [-35.034796974999949, -7.811053454999978],
            [-35.036831413999948, -7.807995847999962],
            [-35.039258464999932, -7.804777626999964],
            [-35.045875359999968, -7.795382459999928],
            [-35.051638208999975, -7.783681214999945],
            [-35.059922698999969, -7.782182107999972],
            [-35.075847133999957, -7.77926791799996],
            [-35.094842550999942, -7.775868257999946],
            [-35.165237598999965, -7.776158269999939],
            [-35.173855233999973, -7.776158830999975],
            [-35.187695190999932, -7.78015615399994],
            [-35.193233385999974, -7.779091344999927],
            [-35.197302264999962, -7.778187149999951],
            [-35.206308523999951, -7.776354964999939],
            [-35.210758116999955, -7.776360913999952],
            [-35.231215532999954, -7.776426348999962],
            [-35.244549481999968, -7.776360775999933],
            [-35.284292714999935, -7.775113603999955],
            [-35.317214792999948, -7.774157711999976],
            [-35.323027034999939, -7.769270119999931],
            [-35.327678010999932, -7.765838689999953],
            [-35.330540183999972, -7.763666488999945],
            [-35.336447194999948, -7.758443571999976],
            [-35.339878876999933, -7.755370121999931],
            [-35.360414107999929, -7.737305234999951],
            [-35.381449067999938, -7.718743789999962],
            [-35.386671839999963, -7.714825279999957],
            [-35.388673677999975, -7.712352674999977],
            [-35.392634885999939, -7.708835226999952],
            [-35.40464073499993, -7.698240638999948],
            [-35.415472645999955, -7.688717095999948],
            [-35.425914043999967, -7.679456139999957],
            [-35.436418166999943, -7.670622906999938],
            [-35.441426262999983, -7.666385336999952],
            [-35.450143337999975, -7.65726957399994],
            [-35.464377456999955, -7.645221882999977],
            [-35.479034621999972, -7.632897544999935],
            [-35.486957918999963, -7.62448825599995],
            [-35.502992823999932, -7.611084933999962],
            [-35.506653301999961, -7.609696389999954],
            [-35.52534641799997, -7.593981837999934],
            [-35.538576458999955, -7.582940476999966],
            [-35.588081145999979, -7.541311806999943],
            [-35.595570498999962, -7.535595151999928],
            [-35.607217960999947, -7.525773925999943],
            [-35.613841888999957, -7.519595809999942],
            [-35.653355709999971, -7.486381948999963],
            [-35.712580493999951, -7.436460857999975],
            [-35.798752673999957, -7.363887296999962],
            [-35.807628063999971, -7.354048225999975],
            [-35.810518569999942, -7.350859219999961],
            [-35.81653635999993, -7.344096846999946],
            [-35.819902839999941, -7.342904012999952],
            [-35.820923817999983, -7.341440538999962],
            [-35.822555801999954, -7.337530337999965],
            [-35.835356311999931, -7.323543680999933],
            [-35.841449137999973, -7.316755404999981],
            [-35.854396369999961, -7.302622124999971],
            [-35.865935957999966, -7.290007331999959],
            [-35.873362463999968, -7.28181004299995],
            [-35.886454474999937, -7.278749762999951],
            [-35.895235618999948, -7.276747549999925],
            [-35.898753544999977, -7.275585779999972],
            [-35.901984146999951, -7.27454383099996],
            [-35.905879524999932, -7.273304363999955],
            [-35.908964265999941, -7.272379617999945],
            [-35.908892862999949, -7.270295720999968],
            [-35.908578993999981, -7.26792654999997],
            [-35.908557578999933, -7.267159173999971],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Angelim - Recife II C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 173.91143,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.55149,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.294254943999931, -8.893312827999978],
            [-36.293830875999959, -8.893283122999946],
            [-36.292198194999969, -8.892918624999936],
            [-36.285560171999975, -8.893228760999932],
            [-36.278692343999978, -8.893556599999954],
            [-36.269944233999979, -8.893984303999957],
            [-36.254016827999976, -8.894702323999979],
            [-36.242398467999976, -8.895259882999937],
            [-36.234598881999943, -8.89562329599994],
            [-36.229222065999977, -8.895876377999969],
            [-36.225215048999985, -8.896055786999966],
            [-36.215599962999931, -8.896489258999964],
            [-36.203271584999982, -8.897085639999943],
            [-36.194869582999956, -8.897485551999978],
            [-36.184672629999966, -8.89797185599997],
            [-36.178487293999979, -8.898265118999973],
            [-36.165617149999946, -8.898860506999938],
            [-36.15372174099997, -8.899400626999977],
            [-36.141803449999941, -8.899934148999932],
            [-36.132839262999937, -8.900107252999931],
            [-36.118163128999981, -8.900272522999956],
            [-36.104564185999948, -8.900482463999936],
            [-36.100393623999935, -8.899396920999948],
            [-36.091102422999938, -8.897002176999933],
            [-36.079058152999949, -8.893914886999937],
            [-36.062670109999942, -8.889685709999981],
            [-36.052708859999939, -8.887093171999936],
            [-36.040985597999963, -8.884028812999929],
            [-36.022418414999947, -8.879186985999979],
            [-36.010801799999967, -8.876164291999942],
            [-35.995442579999974, -8.872136524999974],
            [-35.983955185999946, -8.869087543999967],
            [-35.968200779999961, -8.864835434999975],
            [-35.949793958999976, -8.859866687999954],
            [-35.931888403999949, -8.854984870999942],
            [-35.915922199999955, -8.85061553099996],
            [-35.899907748999965, -8.843914033999965],
            [-35.885976652999943, -8.83806085599997],
            [-35.868593120999947, -8.83078902099993],
            [-35.860266285999955, -8.827292631999967],
            [-35.851871698999958, -8.820097500999964],
            [-35.846377595999968, -8.815132428999959],
            [-35.841178879999973, -8.810313539999981],
            [-35.833903705999944, -8.803818834999959],
            [-35.829651097999943, -8.801368171999968],
            [-35.823700502999941, -8.79794114699996],
            [-35.817965710999943, -8.793626015999962],
            [-35.808847297999932, -8.786791611999945],
            [-35.799322811999957, -8.779643214999965],
            [-35.79311466799993, -8.775021913999979],
            [-35.784029310999983, -8.770747823999955],
            [-35.772285022999938, -8.76531431799998],
            [-35.764465199999961, -8.761703520999959],
            [-35.752001330999974, -8.75594444799998],
            [-35.74233765799994, -8.751482665999958],
            [-35.734441204999939, -8.747834868999973],
            [-35.725598240999943, -8.743743810999945],
            [-35.717155298999955, -8.739860946999954],
            [-35.707182830999955, -8.735238788999936],
            [-35.69787684299996, -8.730932353999947],
            [-35.674306288999958, -8.720048474999942],
            [-35.66444288699995, -8.71550280799994],
            [-35.654047048999985, -8.710786763999977],
            [-35.642629384999964, -8.705521670999929],
            [-35.630099634999965, -8.699720484999943],
            [-35.620268434999957, -8.695084875999953],
            [-35.613093298999956, -8.687682354999936],
            [-35.609197007999967, -8.683718326999951],
            [-35.604090934999931, -8.678403585999945],
            [-35.596590559999981, -8.67070973899996],
            [-35.586869116999935, -8.660744841999929],
            [-35.576441685999953, -8.650034352999967],
            [-35.569382601999962, -8.642800527999952],
            [-35.560705336999945, -8.633886159999975],
            [-35.550992337999958, -8.623895792999974],
            [-35.539500848999978, -8.612111959999936],
            [-35.529466959999979, -8.601782829999934],
            [-35.522859643999936, -8.59502512399996],
            [-35.507451876999937, -8.579226706999975],
            [-35.49809563599996, -8.569610483999952],
            [-35.488316691999955, -8.559539902999973],
            [-35.478514314999984, -8.549452156999962],
            [-35.462476850999963, -8.533000468999944],
            [-35.452958603999946, -8.523212416999968],
            [-35.441834243999949, -8.511810472999969],
            [-35.433663169999932, -8.503406125999959],
            [-35.420201564999957, -8.489547705999939],
            [-35.413078136999957, -8.482258262999949],
            [-35.402440423999963, -8.471310455999969],
            [-35.398226118999958, -8.466905620999967],
            [-35.39545340899997, -8.464098538999963],
            [-35.385589525999933, -8.453882440999962],
            [-35.378325351999933, -8.446478979999938],
            [-35.370654028999979, -8.438622903999942],
            [-35.363259997999933, -8.430995480999968],
            [-35.357023959999935, -8.424607443999946],
            [-35.351516526999944, -8.418895906999978],
            [-35.34448348199993, -8.411710128999971],
            [-35.341056029999947, -8.408162635999929],
            [-35.29108003999994, -8.358908721999967],
            [-35.267164596999976, -8.335532034999972],
            [-35.25812442299997, -8.326659176999954],
            [-35.252095679999968, -8.320795781999948],
            [-35.241658005999966, -8.310688521999964],
            [-35.232151988999931, -8.301331295999944],
            [-35.220254030999968, -8.289634246999981],
            [-35.214097440999979, -8.283727947999978],
            [-35.131740897999975, -8.203047273999971],
            [-35.118455998999934, -8.190264601999957],
            [-35.113386433999949, -8.185311860999946],
            [-35.106262529999981, -8.178301131999945],
            [-35.088549243999978, -8.160970045999932],
            [-35.07480254799998, -8.14736230799997],
            [-35.071418498999947, -8.144023326999957],
            [-35.059502301999942, -8.132247314999972],
            [-35.055338192999955, -8.127992492999965],
            [-35.048034537999968, -8.120824608999953],
            [-35.044752537999955, -8.117655160999959],
            [-35.041730813999948, -8.114654626999936],
            [-35.039466539999978, -8.112419399999965],
            [-35.036614838999981, -8.109999310999967],
            [-35.035291577999942, -8.11036661299994],
            [-35.034995237999965, -8.11146561399994],
            [-35.035321368999973, -8.112803181999936],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Ribeirão - Recife II, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 58.10462,
        Ano_Opera: 2002.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.5129,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.035608627999977, -8.112693021999974],
            [-35.035228852999978, -8.111521920999962],
            [-35.035476365999955, -8.110596813999962],
            [-35.036405259999981, -8.110325958999965],
            [-35.03946216199995, -8.112922383999944],
            [-35.041589189999968, -8.115044506999936],
            [-35.044675764999965, -8.118041604999974],
            [-35.048680298999955, -8.121994579999978],
            [-35.051262203999954, -8.12450615399996],
            [-35.05491941799994, -8.128132518999962],
            [-35.059372037999935, -8.132606173999932],
            [-35.072140524999952, -8.145278756999971],
            [-35.079407694999929, -8.152432953999948],
            [-35.088080745999946, -8.160946370999966],
            [-35.096302081999966, -8.169024728999943],
            [-35.105690274999972, -8.178213720999963],
            [-35.114938669999958, -8.187270406999971],
            [-35.118218849999948, -8.190471821999949],
            [-35.131359492999934, -8.203121515999953],
            [-35.13891437999996, -8.210694611999941],
            [-35.21385584799998, -8.283938452999962],
            [-35.224456720999967, -8.294357544999968],
            [-35.235702694999929, -8.305356988999961],
            [-35.251791571999945, -8.321064394999951],
            [-35.266862015999948, -8.335769289999973],
            [-35.278960449999943, -8.347558054999979],
            [-35.297489968999969, -8.365650367999933],
            [-35.33421303199998, -8.402001136999957],
            [-35.340780343999938, -8.408294810999962],
            [-35.351559301999941, -8.419454482999981],
            [-35.370392735999928, -8.438811398999974],
            [-35.382254348999936, -8.450982341999975],
            [-35.39517316599995, -8.464274096999929],
            [-35.394575631999942, -8.46499793299995],
            [-35.394790414999932, -8.467746416999944],
            [-35.395745085999977, -8.468303460999948],
            [-35.396388072999969, -8.468971228999976],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Angelim - Ribeirão, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 116.52581,
        Ano_Opera: 2002.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.04487,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.396468569999968, -8.468853981999928],
            [-35.395826235999948, -8.46824451599997],
            [-35.395846448999976, -8.468044734999978],
            [-35.395638369999972, -8.466855446999944],
            [-35.396670704999963, -8.466060049999953],
            [-35.39726595999997, -8.46643671299995],
            [-35.402267920999975, -8.471531029999937],
            [-35.404380792999973, -8.473700075999943],
            [-35.411173524999981, -8.480713188999971],
            [-35.423378322999952, -8.493263518999981],
            [-35.437644231999968, -8.507919174999927],
            [-35.447318046999953, -8.517880511999977],
            [-35.453205906999983, -8.523936955999943],
            [-35.46477749099995, -8.535816747999945],
            [-35.470013931999972, -8.541201069999943],
            [-35.480018745999985, -8.551488624999934],
            [-35.492971624999939, -8.564792742999941],
            [-35.517576375999965, -8.590066417999935],
            [-35.534456379999938, -8.607427654999981],
            [-35.553779776999932, -8.627264453999942],
            [-35.57605383899994, -8.65009542599995],
            [-35.601959755999928, -8.676722980999955],
            [-35.620075373999953, -8.695330528999932],
            [-35.625934616999984, -8.698165582999934],
            [-35.642398607999951, -8.705780198999946],
            [-35.683671558999947, -8.724753226999951],
            [-35.718829028999949, -8.741012885999965],
            [-35.751800651999929, -8.75621251299998],
            [-35.783885238999972, -8.77103791299993],
            [-35.792952389999982, -8.775227955999981],
            [-35.808490613999936, -8.786941799999965],
            [-35.823391536999964, -8.79811185799997],
            [-35.833527841999967, -8.804000861999953],
            [-35.84120138399993, -8.810782711999934],
            [-35.846030961999929, -8.815155557999958],
            [-35.847809240999936, -8.816863659999967],
            [-35.855727885999954, -8.823971769999957],
            [-35.860109523999938, -8.82758569799995],
            [-35.868680477999931, -8.83118830199993],
            [-35.899659618999976, -8.844133236999937],
            [-35.915836556999977, -8.850921008999933],
            [-35.951883426999984, -8.860752450999939],
            [-35.968714840999951, -8.865341624999928],
            [-35.99864832399993, -8.873345337999979],
            [-36.02175673499994, -8.879341365999949],
            [-36.071885720999944, -8.892392474999951],
            [-36.104171888999929, -8.900675169999943],
            [-36.105489819999946, -8.900757164999959],
            [-36.116040502999965, -8.900639858999966],
            [-36.132856406999963, -8.900380067999947],
            [-36.142496810999944, -8.900242546999948],
            [-36.163480609999965, -8.899282126999935],
            [-36.178800773999967, -8.898574756999949],
            [-36.210300348999965, -8.89709238599994],
            [-36.281471668999984, -8.893731714999944],
            [-36.28548669099996, -8.893514687999925],
            [-36.290241156999969, -8.89354813999995],
            [-36.292836440999963, -8.893912072999967],
            [-36.294105947999981, -8.893834830999936],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Recife II - Mirueira C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 32.60622,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.28627,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.034954786999947, -8.112925799999971],
            [-35.034609751999938, -8.111526310999977],
            [-35.034522524999943, -8.108948836999957],
            [-35.034605864999946, -8.106321970999943],
            [-35.034247515999937, -8.10280660199993],
            [-35.033734135999964, -8.097426446999975],
            [-35.034114267999939, -8.091440348999981],
            [-35.034336698999937, -8.08720234599997],
            [-35.031688091999968, -8.080177197999944],
            [-35.02810309299997, -8.070215856999937],
            [-35.02475450399993, -8.061026858999981],
            [-35.022824021999952, -8.055896961999963],
            [-35.020526559999951, -8.04968363699993],
            [-35.019692455999973, -8.047428865999962],
            [-35.018558023999958, -8.04430937099994],
            [-35.017315044999975, -8.040965762999974],
            [-35.014984295999966, -8.034636379999938],
            [-35.013021420999962, -8.029390914999965],
            [-35.008995420999952, -8.018427699999961],
            [-35.009803726999962, -8.015399233999972],
            [-35.010704829999952, -8.01202290699996],
            [-35.012583179999979, -8.004753754999967],
            [-35.013429400999939, -8.001770853999972],
            [-35.015690982999956, -7.993353461999959],
            [-35.016493970999932, -7.99014800499998],
            [-35.014551505999975, -7.985663139999929],
            [-35.012553780999951, -7.981016321999959],
            [-35.010317709999981, -7.975898462999965],
            [-35.007749167999975, -7.970008652999979],
            [-35.005690321999964, -7.965337657999953],
            [-35.003867827999954, -7.961068371999943],
            [-35.002432316999943, -7.957802726999944],
            [-34.999859218999973, -7.95190735999995],
            [-34.998642126999982, -7.949040671999967],
            [-34.997646367999948, -7.946802694999974],
            [-34.996612087999949, -7.944467911999936],
            [-34.995734615999936, -7.942379231999951],
            [-34.992440344999977, -7.94143294099996],
            [-34.989508167999929, -7.941962481999951],
            [-34.986219546999962, -7.942463349999969],
            [-34.97496343499995, -7.944338377999941],
            [-34.963618781999969, -7.946132773999977],
            [-34.956667720999974, -7.947241510999959],
            [-34.953568874999974, -7.947672337999961],
            [-34.942449003999968, -7.949492617999965],
            [-34.928138925999974, -7.951743587999943],
            [-34.920663869999942, -7.952926737999974],
            [-34.916327280999951, -7.953594808999981],
            [-34.910837414999946, -7.954384006999931],
            [-34.902690269999937, -7.955717063999941],
            [-34.898863483999946, -7.954890094999939],
            [-34.893048446999956, -7.953902514999982],
            [-34.891574170999945, -7.954714950999971],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Recife II - Mirueira C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 32.47486,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.2851,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.034665442999938, -8.113029145999974],
            [-35.034459193999965, -8.11249477299998],
            [-35.034547825999937, -8.111519426999962],
            [-35.034445434999952, -8.108953715999974],
            [-35.034415505999959, -8.106465518999926],
            [-35.034076437999943, -8.103586728999971],
            [-35.03345401699994, -8.097417094999969],
            [-35.034123895999983, -8.087208781999948],
            [-35.032923963999963, -8.084014077999939],
            [-35.031567917999951, -8.080262904999927],
            [-35.028067788999977, -8.070668029999979],
            [-35.024575152999944, -8.061289861999967],
            [-35.020272172999967, -8.04967000299996],
            [-35.018393647999972, -8.044569293999928],
            [-35.014881875999947, -8.035036116999947],
            [-35.012897419999945, -8.02969173799994],
            [-35.010353208999959, -8.022763831999953],
            [-35.008810621999942, -8.018460890999961],
            [-35.010540245999948, -8.011740220999968],
            [-35.011956560999977, -8.006335681999929],
            [-35.013678120999941, -7.999944899999946],
            [-35.01470631799998, -7.996016385999951],
            [-35.015869837999958, -7.99156149099997],
            [-35.015200969999967, -7.989355385999943],
            [-35.013570621999975, -7.98397380199998],
            [-35.011401169999942, -7.97900457399993],
            [-35.008801286999983, -7.973011829999962],
            [-35.00605834199996, -7.966751307999971],
            [-35.003474514999937, -7.960815269999955],
            [-35.000402526999949, -7.953774978999945],
            [-34.999237353999945, -7.951116474999935],
            [-34.997687841999948, -7.947486402999971],
            [-34.996246084999939, -7.944253635999928],
            [-34.994736745999944, -7.943163289999973],
            [-34.992739945999972, -7.941769929999964],
            [-34.986251163999952, -7.942694828999947],
            [-34.978446663999932, -7.943950438999934],
            [-34.968052463999982, -7.945681614999955],
            [-34.956568394999977, -7.947490590999962],
            [-34.942211057999941, -7.949716286999944],
            [-34.927091962999953, -7.952100506999955],
            [-34.920464686999935, -7.953195773999937],
            [-34.915047130999938, -7.953989798999942],
            [-34.910852834999957, -7.954644170999927],
            [-34.902678259999959, -7.955976790999955],
            [-34.898808533999954, -7.955111963999968],
            [-34.893109850999963, -7.954133785999943],
            [-34.891666549999968, -7.95484278999993],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Recife II - Mirueira C3',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 32.46132,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.28498,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.034536473999935, -8.113095819999955],
            [-35.034337928999946, -8.112493494999967],
            [-35.034276938999938, -8.108771264999973],
            [-35.034336168999971, -8.106463577999932],
            [-35.033359831999974, -8.097421944999951],
            [-35.034025697999937, -8.087227403999975],
            [-35.028922929999965, -8.073257686999966],
            [-35.027932728999929, -8.070652387999928],
            [-35.025648932999957, -8.064444665999929],
            [-35.019407795999939, -8.047532758999978],
            [-35.014785084999971, -8.035033411999962],
            [-35.012824824999939, -8.029723924999928],
            [-35.01029919399997, -8.022801965999975],
            [-35.00873082499993, -8.018462781999972],
            [-35.010455448999949, -8.011707064999939],
            [-35.011879765999936, -8.006297847999974],
            [-35.013589190999937, -7.999892433999946],
            [-35.015779735999956, -7.991572924999957],
            [-35.013485918999947, -7.984026425999957],
            [-35.008715338999934, -7.973044396999967],
            [-35.004772085999946, -7.963988598999947],
            [-35.00031652399997, -7.953776951999942],
            [-34.99762669699993, -7.947545205999972],
            [-34.996185044999947, -7.944326241999931],
            [-34.992714421999949, -7.941861134999954],
            [-34.986251742999968, -7.942778544999953],
            [-34.982635696999978, -7.943369575999952],
            [-34.968052677999935, -7.945769405999954],
            [-34.94219678099995, -7.949835259999929],
            [-34.927115756999967, -7.952200443999971],
            [-34.920480611999949, -7.95328333599997],
            [-34.915070925999942, -7.954084977999969],
            [-34.910893864999935, -7.954734143999929],
            [-34.902662852999981, -7.956063340999947],
            [-34.898782728999947, -7.95518911399995],
            [-34.893121100999963, -7.954229841999961],
            [-34.89171423099998, -7.954980390999935],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Campina Grande II - UTE Campina Grande II C1',
        Concession: 'BORBOREMA ENERGETICA - Borborema Energética S.A.',
        Tensao: 230.0,
        Extensao: 4.18599,
        Ano_Opera: 2011.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.03658,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.90838083999995, -7.295191228999954],
            [-35.908374123999977, -7.294654752999975],
            [-35.909096381999973, -7.291976493999925],
            [-35.908917343999974, -7.289278471999978],
            [-35.907845594999969, -7.286713445999965],
            [-35.90572453599998, -7.281572628999982],
            [-35.903577009999935, -7.276364684999976],
            [-35.902859822999972, -7.274618345999954],
            [-35.906868927999938, -7.273351016999925],
            [-35.909226247999982, -7.272549608999952],
            [-35.910516925999957, -7.271672646999946],
            [-35.910718471999985, -7.269833690999974],
            [-35.910971535999977, -7.267763557999956],
            [-35.911101538999958, -7.266685517999974],
            [-35.910134476999929, -7.266701946999945],
            [-35.910134475999939, -7.267165874999932],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Campina Grande II - Coteminas C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 2.4788,
        Ano_Opera: 2009.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.022,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.909718749999968, -7.267792649999933],
            [-35.90973489199996, -7.26861361999994],
            [-35.909705900999938, -7.270388152999942],
            [-35.909076647999939, -7.272460963999947],
            [-35.905937407999943, -7.273381301999962],
            [-35.89876097299998, -7.275674624999965],
            [-35.895332770999971, -7.276796267999941],
            [-35.895423865999931, -7.279031300999975],
            [-35.895647131999965, -7.279578997999977],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Angelim - Campina Grande II C1',
        Concession: 'TAESA - TRANSMISSORA ALIANÇA DE ENERGIA ELÉTRICA S A',
        Tensao: 230.0,
        Extensao: 195.96461,
        Ano_Opera: 2004.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.69754,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.29403962899994, -8.89554887099996],
            [-36.293594737999967, -8.895551844999943],
            [-36.291934626999932, -8.893499945999963],
            [-36.292044511999961, -8.891547086999935],
            [-36.292336250999938, -8.889271939999958],
            [-36.293342992999953, -8.881843648999961],
            [-36.29488831499998, -8.877937372999952],
            [-36.29624748699996, -8.874443294999935],
            [-36.298937261999981, -8.867362850999939],
            [-36.299707429999955, -8.864162758999953],
            [-36.301473347999945, -8.856437222999944],
            [-36.305390540999952, -8.839659213999937],
            [-36.306377754999971, -8.83535698999998],
            [-36.305758892999961, -8.824324961999935],
            [-36.305273658999965, -8.815706663999947],
            [-36.304358297999954, -8.799551344999941],
            [-36.303913279999961, -8.791194306999955],
            [-36.303233288999934, -8.779271545999961],
            [-36.302498438999976, -8.766465772999936],
            [-36.301519793999944, -8.758742861999963],
            [-36.299932437999985, -8.746282496999982],
            [-36.298934786999951, -8.738419075999957],
            [-36.297622628999932, -8.728146531999926],
            [-36.297914913999932, -8.724780891999956],
            [-36.298759331999975, -8.716145109999957],
            [-36.299068560999956, -8.71249759899996],
            [-36.299178196999947, -8.708471810999981],
            [-36.299463697999954, -8.695490383999982],
            [-36.299672292999958, -8.687029389999964],
            [-36.299947596999971, -8.67503077799995],
            [-36.300128135999955, -8.666378363999968],
            [-36.30031745499997, -8.658307639999975],
            [-36.299840982999967, -8.653342300999952],
            [-36.299471815999937, -8.649574250999933],
            [-36.298186353999938, -8.642924364999942],
            [-36.296731087999945, -8.635502886999973],
            [-36.295081420999963, -8.627260175999936],
            [-36.293218421999939, -8.617911319999962],
            [-36.29158463999994, -8.609438000999944],
            [-36.290237359999935, -8.602512897999929],
            [-36.287915514999952, -8.590848596999933],
            [-36.285189249999974, -8.577015023999934],
            [-36.283864189999974, -8.570301689999951],
            [-36.281628532999946, -8.558990267999945],
            [-36.280229081999948, -8.552575948999959],
            [-36.27827502599996, -8.54412857899996],
            [-36.276379599999984, -8.536069631999965],
            [-36.274481155999979, -8.528060984999968],
            [-36.272575675999974, -8.519836977999944],
            [-36.26963565099993, -8.50729455499993],
            [-36.268653512999947, -8.503060717999972],
            [-36.267233974999954, -8.494687866999925],
            [-36.264162401999954, -8.476227211999969],
            [-36.262814980999963, -8.468138826999962],
            [-36.261216862999959, -8.460011189999932],
            [-36.259249934999957, -8.451587598999936],
            [-36.257295324999973, -8.443178061999959],
            [-36.255410246999929, -8.435119054999973],
            [-36.253047905999949, -8.422460443999967],
            [-36.251480577999985, -8.413998331999949],
            [-36.248556964999977, -8.397106126999972],
            [-36.245681735999938, -8.382664634999969],
            [-36.242159968999943, -8.362634132999972],
            [-36.240593163999961, -8.354926044999957],
            [-36.237810718999981, -8.341558146999944],
            [-36.235354902999973, -8.329352111999981],
            [-36.233990534999975, -8.322083634999956],
            [-36.232799863999958, -8.315727425999967],
            [-36.229589075999968, -8.298664795999969],
            [-36.226169126999935, -8.280420643999946],
            [-36.223783873999935, -8.26783845999995],
            [-36.220788927999934, -8.252125415999956],
            [-36.217129093999972, -8.230196663999948],
            [-36.215133985999955, -8.217097980999938],
            [-36.211866624999971, -8.19446959499993],
            [-36.210017019999952, -8.181839301999958],
            [-36.207932817999961, -8.167863102999945],
            [-36.201404429999968, -8.14965945199998],
            [-36.194728035999958, -8.131066691999933],
            [-36.190281557999981, -8.118788103999975],
            [-36.18346805799996, -8.099859465999941],
            [-36.179978547999951, -8.089746042999934],
            [-36.17511542099993, -8.075801074999958],
            [-36.170551046999947, -8.062726621999957],
            [-36.163465524999935, -8.042051629999946],
            [-36.155264562999946, -8.017800809999926],
            [-36.153547480999976, -8.012527978999969],
            [-36.147561199999984, -7.989618502999974],
            [-36.14450233499997, -7.977763721999963],
            [-36.143594751999956, -7.969996181999932],
            [-36.142627561999973, -7.96145120999995],
            [-36.142154213999959, -7.957698801999925],
            [-36.136925248999944, -7.94672839499998],
            [-36.13585670599997, -7.944458402999942],
            [-36.134826669999939, -7.939202790999957],
            [-36.133205276999945, -7.931092219999925],
            [-36.13153721499998, -7.922724738999932],
            [-36.13017181999993, -7.915836055999932],
            [-36.127949452999928, -7.90459715399993],
            [-36.125750982999932, -7.891479895999964],
            [-36.124663813999973, -7.884773442999972],
            [-36.122621772999935, -7.872662038999977],
            [-36.120412874999943, -7.85933010399998],
            [-36.118891115999929, -7.850206297999932],
            [-36.117629227999942, -7.842581689999975],
            [-36.116292288999944, -7.834380096999951],
            [-36.115040832999966, -7.826868913999931],
            [-36.113795515999982, -7.819557204999967],
            [-36.111755437999932, -7.807224841999926],
            [-36.108584772999961, -7.787982793999959],
            [-36.105013088999954, -7.767384608999976],
            [-36.102946295999971, -7.755222377999928],
            [-36.095911982999951, -7.714726045999953],
            [-36.094663416999936, -7.70743555599995],
            [-36.089016433999973, -7.675034451999977],
            [-36.079751547999933, -7.621677761999933],
            [-36.072252234999951, -7.605982509999933],
            [-36.065722889999961, -7.592246086999978],
            [-36.062958607999974, -7.586533136999947],
            [-36.048147698999969, -7.554499944999975],
            [-36.041532561999929, -7.540204504999963],
            [-36.036617502999945, -7.529534130999934],
            [-36.030413123999949, -7.516053193999937],
            [-36.023705018999976, -7.501528466999957],
            [-36.012463127999979, -7.474767330999953],
            [-36.006839626999977, -7.460778382999933],
            [-36.002164909999976, -7.449289910999937],
            [-35.996568934999971, -7.438985540999965],
            [-35.989142516999948, -7.425466256999925],
            [-35.982000127999981, -7.411874979999936],
            [-35.976630581999984, -7.401369541999941],
            [-35.970030385999962, -7.387861989999976],
            [-35.965379545999951, -7.378334973999927],
            [-35.961729120999962, -7.370847376999961],
            [-35.959055947999957, -7.365348061999953],
            [-35.954822657999955, -7.356643474999942],
            [-35.953240822999931, -7.353366173999973],
            [-35.949578584999983, -7.345849313999963],
            [-35.943778304999967, -7.334036921999939],
            [-35.938286151999932, -7.322877435999942],
            [-35.934443881999982, -7.315062879999971],
            [-35.926878256999942, -7.29968317099997],
            [-35.919924598999955, -7.285544639999955],
            [-35.91821264899994, -7.282084740999949],
            [-35.916294507999964, -7.278206613999942],
            [-35.913090969999985, -7.274985966999964],
            [-35.909901928999943, -7.27176190199998],
            [-35.909171886999957, -7.271044273999962],
            [-35.909161918999985, -7.270161358999928],
            [-35.908918665999977, -7.267991156999926],
            [-35.908957147999956, -7.267179617999943],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Tacaimbó - Campina Grande II C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 130.00536,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.1269,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.223807024999928, -8.330614134999962],
            [-36.224871436999933, -8.330718242999978],
            [-36.229296892999969, -8.331023631999926],
            [-36.233522007999966, -8.331335300999967],
            [-36.234881575999964, -8.331240545999947],
            [-36.234687452999935, -8.329183763999936],
            [-36.233686033999959, -8.323902429999976],
            [-36.231092337999939, -8.310173844999952],
            [-36.228395691999935, -8.295811282999978],
            [-36.226759312999945, -8.287222486999951],
            [-36.224827693999941, -8.276860347999957],
            [-36.223406892999947, -8.269238860999963],
            [-36.221777324999948, -8.260937490999936],
            [-36.220728397999949, -8.255445121999969],
            [-36.219320825999944, -8.247718393999946],
            [-36.218323590999944, -8.242285205999963],
            [-36.216890759999956, -8.234389748999945],
            [-36.215526481999973, -8.227322553999954],
            [-36.214328902999966, -8.221049520999941],
            [-36.213053984999931, -8.214196447999939],
            [-36.212085039999977, -8.209091316999945],
            [-36.210908987999971, -8.202771998999935],
            [-36.20974082999993, -8.196642359999942],
            [-36.208771266999975, -8.191408614999943],
            [-36.207484436999948, -8.184426447999954],
            [-36.206015958999956, -8.176761074999945],
            [-36.204758493999975, -8.170072448999974],
            [-36.204561463999937, -8.16878068699998],
            [-36.203148832999943, -8.164777198999957],
            [-36.201187778999952, -8.15893744899995],
            [-36.198471278999932, -8.150957023999979],
            [-36.19611181199997, -8.14396538799997],
            [-36.194431284999951, -8.139054037999927],
            [-36.192613195999968, -8.133690863999959],
            [-36.191133611999931, -8.129263640999966],
            [-36.189397879999945, -8.124151713999936],
            [-36.187224732999937, -8.117805904999955],
            [-36.18563445999996, -8.113198853999961],
            [-36.181935553999949, -8.102243634999979],
            [-36.179177813999956, -8.093996348999951],
            [-36.176385940999978, -8.085726990999945],
            [-36.17336153499997, -8.07674729699994],
            [-36.17036378499995, -8.06796735599994],
            [-36.16842178099995, -8.062161920999927],
            [-36.166262788999973, -8.055768744999966],
            [-36.164738863999958, -8.051263837999954],
            [-36.162761903999979, -8.045453772999963],
            [-36.160703267999963, -8.039357171999939],
            [-36.158822842999939, -8.033864960999949],
            [-36.156893087999947, -8.028111962999958],
            [-36.154444529999978, -8.020872236999935],
            [-36.151464483999973, -8.012219743999935],
            [-36.147061706999978, -7.999151],
            [-36.144438461999982, -7.991464488999952],
            [-36.141478181999958, -7.982784233999951],
            [-36.140588943999944, -7.978921759999935],
            [-36.13958473699995, -7.974364022999964],
            [-36.138311407999936, -7.968702647999976],
            [-36.137381450999953, -7.96465435999994],
            [-36.136113291999948, -7.959060303999934],
            [-36.133518778999985, -7.953450200999953],
            [-36.127715433999981, -7.940758416999927],
            [-36.126048434999973, -7.937118373999965],
            [-36.124269119999951, -7.93190418599994],
            [-36.122205748999932, -7.925777222999955],
            [-36.120414862999951, -7.920484414999976],
            [-36.118412489999969, -7.914568685999939],
            [-36.116846789999954, -7.909928868999941],
            [-36.11502911499997, -7.904567172999975],
            [-36.112575781999965, -7.897259923999968],
            [-36.110870787999943, -7.892284595999968],
            [-36.108345124999971, -7.884795071999974],
            [-36.106376915999931, -7.878956333999952],
            [-36.104597196999975, -7.873684877999949],
            [-36.102900182999974, -7.868647515999953],
            [-36.100926411999978, -7.862893337999935],
            [-36.098507447999964, -7.855730294999944],
            [-36.096697976999963, -7.850335577999942],
            [-36.094879445999936, -7.844993614999964],
            [-36.093093694999936, -7.839748193999981],
            [-36.090310159999945, -7.831485137999948],
            [-36.088683271999969, -7.826661268999942],
            [-36.086702559999935, -7.820797812999956],
            [-36.084996073999946, -7.815808493999953],
            [-36.083248601999969, -7.810608502999969],
            [-36.08072440199993, -7.803149623999957],
            [-36.078898522999964, -7.797774441999934],
            [-36.075348664999979, -7.787269570999968],
            [-36.071184114999937, -7.775031903999945],
            [-36.067382948999978, -7.76378263099997],
            [-36.025616017999937, -7.640336],
            [-36.023593325999968, -7.634302614999967],
            [-36.021084599999938, -7.626877469999954],
            [-36.017307066999933, -7.615706348999936],
            [-36.012575585999969, -7.601748868999948],
            [-36.006289125999956, -7.58335419399998],
            [-36.005143315999931, -7.579472667999937],
            [-36.001559629999974, -7.569088983999961],
            [-35.999130271999945, -7.561936958999979],
            [-35.995400785999948, -7.55091942699994],
            [-35.992481461999944, -7.542318847999979],
            [-35.987697267999977, -7.52815108599998],
            [-35.984033612999951, -7.51732468299997],
            [-35.976740488999951, -7.49593466999994],
            [-35.972661803999983, -7.48377461299998],
            [-35.969346105999932, -7.473955603999968],
            [-35.96669157499997, -7.466125088999945],
            [-35.964507002999937, -7.459656247999931],
            [-35.962380416999963, -7.45338038899996],
            [-35.961631853999961, -7.451041489999966],
            [-35.959059017999948, -7.445914599999981],
            [-35.956003617999954, -7.439691988999925],
            [-35.954151612999965, -7.436017468999978],
            [-35.951463906999948, -7.428054350999957],
            [-35.949594426999965, -7.422625176999929],
            [-35.947711598999945, -7.417177853999931],
            [-35.945601808999982, -7.411113234999959],
            [-35.944851467999968, -7.407218601999944],
            [-35.943806819999963, -7.401966311999956],
            [-35.942866221999964, -7.397217371999943],
            [-35.942153391999966, -7.393499892999955],
            [-35.940506593999942, -7.388786216999961],
            [-35.938880105999942, -7.383951072999935],
            [-35.937563823999938, -7.380046854999932],
            [-35.936930523999933, -7.378094089999934],
            [-35.934912102999931, -7.374195938999947],
            [-35.932871820999935, -7.370276185999955],
            [-35.931392394999932, -7.365494631999979],
            [-35.93129066399996, -7.36142194599995],
            [-35.929872522999972, -7.357330410999964],
            [-35.928667705999942, -7.353756125999951],
            [-35.926088037999932, -7.34611493899996],
            [-35.923598953999942, -7.338761972999976],
            [-35.921733629999949, -7.333272514999976],
            [-35.919952459999934, -7.327994615999955],
            [-35.918165536999936, -7.322727004999933],
            [-35.916598051999983, -7.31809121799995],
            [-35.915472188999956, -7.314715270999955],
            [-35.913978570999973, -7.31034264799996],
            [-35.912177772999939, -7.305007350999972],
            [-35.910360581999953, -7.299642969999979],
            [-35.90960453699995, -7.297502283999961],
            [-35.90960199899996, -7.291961950999962],
            [-35.90955170999996, -7.286485503999927],
            [-35.909515489999933, -7.282022548999976],
            [-35.909485243999939, -7.277515816999937],
            [-35.909445690999974, -7.273977375999948],
            [-35.909396289999961, -7.272602257999949],
            [-35.909444076999932, -7.271816283999954],
            [-35.909879266999951, -7.270141697999975],
            [-35.909903819999954, -7.268638743999929],
            [-35.909885750999933, -7.267762208999955],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Tacaimbó - Campina Grande II C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 130.06114,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.12738,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.22377569799994, -8.330752296999947],
            [-36.224919206999971, -8.330827736999936],
            [-36.229340967999974, -8.331111508999982],
            [-36.233539746999952, -8.331413767999948],
            [-36.234931031999963, -8.331661586999928],
            [-36.235366648999957, -8.331367047999947],
            [-36.234150449999959, -8.325047543999972],
            [-36.231016198999953, -8.30840849599997],
            [-36.228738423999971, -8.296319537999977],
            [-36.225527654999951, -8.279087482999955],
            [-36.223607766999976, -8.268923614999949],
            [-36.21946778399996, -8.246911472999955],
            [-36.217331981999962, -8.235309771999937],
            [-36.21637272199996, -8.230397941999968],
            [-36.215643660999945, -8.226543272999947],
            [-36.214627070999938, -8.221127588999934],
            [-36.213255126999968, -8.213778455999943],
            [-36.211675773999957, -8.205388481999933],
            [-36.210120024999981, -8.197157369999957],
            [-36.208194806999984, -8.186898820999943],
            [-36.206607676999965, -8.17847385999994],
            [-36.205255509999972, -8.171281811999961],
            [-36.20476980899997, -8.168679734999955],
            [-36.203163526999958, -8.163943093999933],
            [-36.200554903999944, -8.156251810999947],
            [-36.197950440999932, -8.148567423999964],
            [-36.195424575999937, -8.141110778999973],
            [-36.19388443899993, -8.136576239999954],
            [-36.192282388999956, -8.131812754999942],
            [-36.189787671999966, -8.124437833999934],
            [-36.187913153999943, -8.11891815599995],
            [-36.186071132999984, -8.113507200999948],
            [-36.184771663999982, -8.109795825999981],
            [-36.179441486999963, -8.093961147999948],
            [-36.172782738999956, -8.074243181999975],
            [-36.167499028999941, -8.05865837999994],
            [-36.164967986999955, -8.051160100999937],
            [-36.160897260999946, -8.039107528999978],
            [-36.157095063999975, -8.027907611999979],
            [-36.154820699999959, -8.021141221999926],
            [-36.151694680999981, -8.012079635999953],
            [-36.146241772999929, -7.995925352999961],
            [-36.141736596999976, -7.982691641999963],
            [-36.140037939999957, -7.975144378999971],
            [-36.138550231999943, -7.96857479199997],
            [-36.137005, -7.961857896999959],
            [-36.136345309999967, -7.958972019999976],
            [-36.132706749999954, -7.951002181999968],
            [-36.130564512999968, -7.94633375199993],
            [-36.127715366999951, -7.940089330999967],
            [-36.126285937999967, -7.93702805099997],
            [-36.125164955999935, -7.933679459999951],
            [-36.122638496999969, -7.926192305999962],
            [-36.12089105299998, -7.921029846999943],
            [-36.118932694999955, -7.915244596999969],
            [-36.117273853999961, -7.910335136999947],
            [-36.116102964999982, -7.906883849999929],
            [-36.114459376999946, -7.902090412999939],
            [-36.112700812999947, -7.896842367999966],
            [-36.109341557999983, -7.886906955999962],
            [-36.106672113999934, -7.878980586999944],
            [-36.102720160999979, -7.867334059999962],
            [-36.099138164999943, -7.856743004999942],
            [-36.095898023999951, -7.847192580999945],
            [-36.093275874999961, -7.839412779999975],
            [-36.090465586999983, -7.831128554999964],
            [-36.087853225999936, -7.823349554999936],
            [-36.085503322999955, -7.816444408999928],
            [-36.08381905799996, -7.811492385999941],
            [-36.081075802999976, -7.80338891599996],
            [-36.079193818999954, -7.797821804999955],
            [-36.075853327999937, -7.787954098999933],
            [-36.073454856999945, -7.780839988999958],
            [-36.067584183999941, -7.763532263999934],
            [-36.026529210999968, -7.642152139999951],
            [-36.025082871999984, -7.637874175999968],
            [-36.021541137999975, -7.627372937999951],
            [-36.018798256999958, -7.619231264999939],
            [-36.013693943999954, -7.604248303999952],
            [-36.00950265299997, -7.59178944599995],
            [-36.006687919999933, -7.583623663999958],
            [-36.00546606599994, -7.579604452999945],
            [-36.003345181999975, -7.57357516299993],
            [-36.001796642999977, -7.56895756199998],
            [-35.999963286999957, -7.563558184999977],
            [-35.99816218999996, -7.558170419999954],
            [-35.996594046999974, -7.553574222999941],
            [-35.994877302999953, -7.548538725999947],
            [-35.992985874999931, -7.542955247999942],
            [-35.99030905099994, -7.535061062999944],
            [-35.989068661999966, -7.53137331299996],
            [-35.986713769999938, -7.524470295999947],
            [-35.983567155999935, -7.515111545999957],
            [-35.980540343999962, -7.506139142999928],
            [-35.978578570999957, -7.500416375999976],
            [-35.973834512999929, -7.48633388099995],
            [-35.971872090999966, -7.480621103999965],
            [-35.970120471999962, -7.475446212999941],
            [-35.96858113199994, -7.470883995999941],
            [-35.966621636999946, -7.465097066999931],
            [-35.96451893099993, -7.458905926999932],
            [-35.962532403999944, -7.452988001999927],
            [-35.961843726999973, -7.450866154999972],
            [-35.960466265999969, -7.44817758399995],
            [-35.957591569999977, -7.442344834999972],
            [-35.954429961999949, -7.435970553999937],
            [-35.951775793999957, -7.428114755999957],
            [-35.949229921999972, -7.420732269999974],
            [-35.947547120999957, -7.415814944999966],
            [-35.945864239999935, -7.410981120999963],
            [-35.945085994999943, -7.407014199999935],
            [-35.943892042999948, -7.401135484999941],
            [-35.942786779999949, -7.395389464999937],
            [-35.942361920999929, -7.393405369999925],
            [-35.940766440999937, -7.388657657999943],
            [-35.93891395299994, -7.38317081699995],
            [-35.937826117999975, -7.379955411999958],
            [-35.935534833999952, -7.373280714999964],
            [-35.934742317999962, -7.370211651999966],
            [-35.933213900999931, -7.365917433999925],
            [-35.930713299999979, -7.359001001999957],
            [-35.928756324999938, -7.35320580399997],
            [-35.927464899999961, -7.349398464999979],
            [-35.925107597999954, -7.342406510999979],
            [-35.924011905999976, -7.339146777999929],
            [-35.922001325999929, -7.333219983999925],
            [-35.920132816999967, -7.327673987999958],
            [-35.918471563999958, -7.322764065999934],
            [-35.916857137999955, -7.317995878999966],
            [-35.914731724999967, -7.31170562099993],
            [-35.912992327999973, -7.306598182999949],
            [-35.91133027199993, -7.301655680999943],
            [-35.909905349999974, -7.297427566999943],
            [-35.909905273999982, -7.294683844999952],
            [-35.909865249999939, -7.28961295299996],
            [-35.909812593999959, -7.284711656999946],
            [-35.90977268499995, -7.279340710999975],
            [-35.909720138999944, -7.274148578999927],
            [-35.909681085999978, -7.272494080999934],
            [-35.90970098799994, -7.271727006999981],
            [-35.909966193999935, -7.270171805999951],
            [-35.910005032999948, -7.268642326999952],
            [-35.910007556999972, -7.267713879999974],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Angelim - Tacaimbó C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 67.00927,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.5799,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.223767327999951, -8.33087514999994],
            [-36.224926901999936, -8.330970205999961],
            [-36.229753261999974, -8.331332801999963],
            [-36.234425463999969, -8.33237212399996],
            [-36.235087651999947, -8.332260075999955],
            [-36.235647558999972, -8.332215769999948],
            [-36.235776319999957, -8.333352807999972],
            [-36.236313898999981, -8.336351268999977],
            [-36.237446704999968, -8.342308120999974],
            [-36.239058210999929, -8.350803153999948],
            [-36.241155766999952, -8.361944446999928],
            [-36.244059354999933, -8.37720044699995],
            [-36.245200978999947, -8.383195254999976],
            [-36.246159133999981, -8.388300164999976],
            [-36.247213636999959, -8.393925859999968],
            [-36.248785903999931, -8.402253985999948],
            [-36.250444294999966, -8.41099516099996],
            [-36.253081132999966, -8.424868031999949],
            [-36.254043406999983, -8.429957443999967],
            [-36.256074972999954, -8.44070401099998],
            [-36.257615690999955, -8.448788818999958],
            [-36.259024111999963, -8.456256149999945],
            [-36.260481433999985, -8.463909587999979],
            [-36.26145226999995, -8.469034898999951],
            [-36.262549742999965, -8.474835875999929],
            [-36.264141373999962, -8.483171949999928],
            [-36.265035338999951, -8.487904894999929],
            [-36.266457885999955, -8.495400007999933],
            [-36.267436000999965, -8.499674098999947],
            [-36.268837891999965, -8.505621291999944],
            [-36.270554476999962, -8.51291581199996],
            [-36.273135178999951, -8.523931854999944],
            [-36.276294333999942, -8.537329510999939],
            [-36.277536795999936, -8.542604291999965],
            [-36.279089031999945, -8.549236205999932],
            [-36.279933982999978, -8.553791850999971],
            [-36.281165, -8.560303441999963],
            [-36.282755496999982, -8.568716301999928],
            [-36.283751509999945, -8.574108582999941],
            [-36.284560306999936, -8.578227024999933],
            [-36.285725468999942, -8.584376493999969],
            [-36.287208598999939, -8.592249249999952],
            [-36.290455724999958, -8.609438188999945],
            [-36.290916574999983, -8.611791345999961],
            [-36.292161967999959, -8.618282296999951],
            [-36.294250348999981, -8.629320162999932],
            [-36.295925520999958, -8.638170165999952],
            [-36.297398488999931, -8.645909031999963],
            [-36.297915838999984, -8.648616168999979],
            [-36.298045273999946, -8.650775505999945],
            [-36.298221791999936, -8.653560841999933],
            [-36.298466275999942, -8.656780237999953],
            [-36.298369887999968, -8.659075797999947],
            [-36.298285920999945, -8.664319108999962],
            [-36.298125505999963, -8.670122606999939],
            [-36.298003146999974, -8.675591966999946],
            [-36.297885871999938, -8.680993866999927],
            [-36.29777850499994, -8.686487560999979],
            [-36.29760308699997, -8.69446968699998],
            [-36.297392661999936, -8.702854498999955],
            [-36.297278711999979, -8.708440776999964],
            [-36.297152503999939, -8.714341599999955],
            [-36.297007752999946, -8.721366630999967],
            [-36.296865801999957, -8.727604073999942],
            [-36.296758487999966, -8.733135711999978],
            [-36.296639120999941, -8.73866006999998],
            [-36.296469592999983, -8.747025458999929],
            [-36.296347252999965, -8.751773026999956],
            [-36.296238690999985, -8.756695764999961],
            [-36.295977283999946, -8.767304001999946],
            [-36.295904939999957, -8.772268928999949],
            [-36.295704257999944, -8.780971697999973],
            [-36.295598649999931, -8.78637591599994],
            [-36.29541351599994, -8.794457610999928],
            [-36.295232062999958, -8.802450526999962],
            [-36.295141422999961, -8.807176903999959],
            [-36.29493055599994, -8.815334935999942],
            [-36.294764535999946, -8.823444142999961],
            [-36.294533135999984, -8.834614789999932],
            [-36.294327894999981, -8.84325671199997],
            [-36.294038396999952, -8.856549682999969],
            [-36.293834722999975, -8.865917722999939],
            [-36.294370160999961, -8.877213390999941],
            [-36.294800814999974, -8.886418755999955],
            [-36.295958114999962, -8.892222931999981],
            [-36.295787083999983, -8.892771573999937],
            [-36.295312434999971, -8.893039056999953],
            [-36.29489048399995, -8.893122998999957],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Angelim - Tacaimbó C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 66.86716,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.57865,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.223768779999943, -8.331034325999951],
            [-36.224910571999942, -8.331078583999954],
            [-36.229717529999959, -8.331446196999934],
            [-36.234394840999983, -8.332501599999944],
            [-36.235202954999977, -8.332732838999959],
            [-36.23537958299994, -8.332793639999977],
            [-36.235646759999952, -8.334206622999943],
            [-36.236139739999942, -8.33682923899994],
            [-36.236843815999976, -8.340703993999966],
            [-36.237750239999968, -8.34537073499996],
            [-36.239340421999941, -8.353827373999934],
            [-36.241271008999945, -8.364085343999932],
            [-36.242820130999974, -8.372119857999962],
            [-36.244425084999932, -8.380679138999938],
            [-36.24711154299996, -8.394776475999947],
            [-36.248640260999935, -8.402832802999967],
            [-36.252264415999946, -8.421932738999942],
            [-36.254809428999977, -8.435315511999931],
            [-36.257908702999941, -8.451713548999976],
            [-36.261268401999985, -8.469391391999977],
            [-36.26371648199995, -8.482395892999932],
            [-36.266191341999956, -8.495429007999974],
            [-36.26732494099997, -8.500226310999949],
            [-36.269723251999949, -8.510535953999977],
            [-36.272894270999984, -8.524049181999942],
            [-36.276057483999978, -8.53752697699997],
            [-36.279217612999958, -8.551433351999947],
            [-36.280329037999934, -8.557238783999935],
            [-36.28245540599994, -8.568549591999954],
            [-36.284809225999936, -8.580979311999954],
            [-36.288153026999964, -8.59856616899998],
            [-36.289535292999972, -8.605854233999935],
            [-36.293084959999931, -8.624546650999946],
            [-36.295719374999976, -8.638552481999966],
            [-36.297145919999934, -8.645949049999956],
            [-36.297717355999964, -8.648643410999966],
            [-36.297839918999955, -8.651016339999956],
            [-36.298217800999964, -8.656782260999933],
            [-36.298082590999968, -8.661588063999943],
            [-36.297949012999936, -8.66823326399998],
            [-36.297683083999971, -8.681118201999936],
            [-36.297481619999985, -8.689315335999936],
            [-36.297169212999961, -8.703008540999974],
            [-36.29696460699995, -8.713249013999928],
            [-36.29669722899996, -8.724667310999962],
            [-36.296477028999959, -8.735409822999941],
            [-36.296162643999935, -8.748814386999982],
            [-36.295945432999929, -8.759732622999934],
            [-36.295750503999955, -8.76805018899995],
            [-36.295570452999982, -8.776208568999948],
            [-36.295264295999971, -8.790025137999976],
            [-36.29501943799994, -8.802182470999981],
            [-36.294828655999936, -8.810393142999942],
            [-36.294577315999959, -8.821564592999948],
            [-36.294370451999953, -8.830832217999955],
            [-36.294239233999974, -8.838974543999939],
            [-36.294001789999982, -8.848273763999941],
            [-36.293841276999956, -8.856398338999952],
            [-36.293722745999958, -8.861084454999968],
            [-36.293598693999968, -8.865873372999943],
            [-36.29386773899995, -8.870950396999945],
            [-36.29421199799998, -8.878513514999952],
            [-36.294584922999945, -8.886415432999968],
            [-36.295081215999971, -8.888760562999948],
            [-36.295753096999931, -8.892184318999966],
            [-36.294920766999951, -8.892951456999981],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Angelim - Tacaimbó C3',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 66.91053,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.57906,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.294264329999976, -8.893046160999972],
            [-36.293801357999939, -8.89303377799996],
            [-36.292495793999933, -8.892752137999935],
            [-36.291761678999933, -8.891874690999941],
            [-36.292238848999943, -8.888499417999981],
            [-36.293101670999931, -8.881835541999976],
            [-36.293093155999941, -8.87678311999997],
            [-36.292956576999984, -8.865948513999967],
            [-36.292933356999981, -8.857219739999948],
            [-36.29287764299994, -8.850037243999964],
            [-36.29286854999998, -8.846249746999945],
            [-36.292819458999929, -8.838603129999967],
            [-36.292682925999941, -8.814902090999965],
            [-36.292615696999974, -8.802492149999978],
            [-36.292563607999966, -8.793671220999954],
            [-36.29255638799998, -8.789993465999942],
            [-36.292505290999941, -8.78526170799995],
            [-36.292484472999945, -8.77729362599996],
            [-36.293035243999952, -8.765296396999929],
            [-36.293199702999971, -8.757804940999961],
            [-36.293737657999941, -8.732659832999957],
            [-36.293909940999981, -8.72493007199995],
            [-36.294077430999948, -8.716863069999931],
            [-36.294304918999956, -8.706497690999981],
            [-36.294585242999972, -8.693832648999944],
            [-36.294773808999935, -8.685234709999975],
            [-36.294939935999935, -8.677443500999971],
            [-36.295099622999942, -8.669864067999981],
            [-36.295343572999968, -8.658592366999926],
            [-36.295515886999965, -8.650998254999934],
            [-36.295744098999933, -8.640129435999938],
            [-36.295104143999936, -8.636747638999964],
            [-36.292839190999985, -8.62473586699997],
            [-36.291319998999938, -8.616853185999958],
            [-36.28958786499993, -8.607716238999956],
            [-36.287704075999955, -8.597646692999945],
            [-36.285829986999943, -8.58783077399994],
            [-36.284377128999949, -8.580158328999971],
            [-36.283014715999968, -8.572941650999951],
            [-36.281863181999938, -8.566877345999956],
            [-36.280415304999963, -8.559238776999962],
            [-36.279170361999945, -8.552654970999981],
            [-36.277621527999941, -8.54542724199996],
            [-36.276681693999933, -8.541401165999957],
            [-36.274635732999968, -8.532746413999973],
            [-36.273759791999964, -8.52884931799997],
            [-36.271788016999949, -8.520549390999975],
            [-36.269104699999957, -8.509076687999936],
            [-36.26727843499998, -8.501344486999926],
            [-36.265891771999975, -8.495499752999933],
            [-36.264636917999951, -8.488790355999981],
            [-36.262803015999964, -8.479015954999966],
            [-36.26135501899995, -8.471426600999962],
            [-36.260167777999982, -8.465177583999946],
            [-36.258661657999937, -8.457322168999951],
            [-36.25715058399993, -8.449229499999944],
            [-36.255577436999943, -8.440974382999968],
            [-36.254235698999935, -8.433908282999937],
            [-36.252700140999934, -8.425748403999933],
            [-36.250496304999956, -8.41417880299997],
            [-36.246918893999975, -8.395363730999975],
            [-36.244898385999932, -8.384713508999937],
            [-36.243966780999983, -8.379695206999941],
            [-36.242909676999943, -8.374122882999927],
            [-36.240764059999947, -8.362937533999968],
            [-36.238697516999935, -8.351957329999948],
            [-36.237380371999961, -8.345061843999929],
            [-36.235847219999982, -8.336867092999967],
            [-36.235229706999974, -8.333611087999941],
            [-36.231424765999975, -8.332360183999981],
            [-36.228112923999959, -8.331633846999978],
            [-36.224903837999932, -8.33125339999998],
            [-36.223768449999966, -8.331183272999965],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Garanhuns II - Paulo Afonso III C3',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 210.18998,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.90253,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.403472550999936, -8.884533973999964],
            [-36.403522369999962, -8.887017433999972],
            [-36.40461460299997, -8.889578440999969],
            [-36.412116000999958, -8.889516592999939],
            [-36.418174181999973, -8.889430663999974],
            [-36.42621573699995, -8.889369540999951],
            [-36.433918920999929, -8.889259918999926],
            [-36.441497244999937, -8.889177824999933],
            [-36.446752033999928, -8.892907264999963],
            [-36.452353082999934, -8.896918354999968],
            [-36.457498423999937, -8.900570658999982],
            [-36.461839377999979, -8.903671164999935],
            [-36.467922853999937, -8.907955029999926],
            [-36.473477654999954, -8.911924180999961],
            [-36.478839439999945, -8.915686125999969],
            [-36.486443380999958, -8.921050082999955],
            [-36.496082343999944, -8.927922169999931],
            [-36.501614551999978, -8.931862851999938],
            [-36.507335316999956, -8.935905005999928],
            [-36.512592908999977, -8.939628414999959],
            [-36.519486493999977, -8.944509580999977],
            [-36.526754585999981, -8.949629654999967],
            [-36.534975912999982, -8.955454223999936],
            [-36.543218533999948, -8.961315206999927],
            [-36.548923534999972, -8.965335226999969],
            [-36.55736540099997, -8.971315331999961],
            [-36.564236425999979, -8.976162639999927],
            [-36.56969442299993, -8.980065297999943],
            [-36.575650153999959, -8.984251914999959],
            [-36.580415391999964, -8.987626707999937],
            [-36.587307605999968, -8.992486270999962],
            [-36.590948355999956, -8.995080048999966],
            [-36.595930229999965, -8.998630675999948],
            [-36.600601539999957, -9.001912714999946],
            [-36.606327320999981, -9.005955722999943],
            [-36.615711544999954, -9.012617725999974],
            [-36.622769138999956, -9.017609447999973],
            [-36.63111695799995, -9.023555490999968],
            [-36.63759373299996, -9.028108373999942],
            [-36.645452193999972, -9.033672640999953],
            [-36.655458993999957, -9.040710806999925],
            [-36.664591308999945, -9.047156149999978],
            [-36.671220793999964, -9.051844588999927],
            [-36.681922055999962, -9.059382236999966],
            [-36.690401447999932, -9.065394498999979],
            [-36.701344569999947, -9.073139611999977],
            [-36.712311607999936, -9.080909574999964],
            [-36.719998134999969, -9.086355441999956],
            [-36.725636936999933, -9.090356327999928],
            [-36.729671714999938, -9.09319933699993],
            [-36.736450479999974, -9.098006275999978],
            [-36.743117823999967, -9.102726609999934],
            [-36.748206547999985, -9.106377730999952],
            [-36.749717681999982, -9.106524872999955],
            [-36.759431249999977, -9.107779660999938],
            [-36.768815381999957, -9.108924133999949],
            [-36.777388009999981, -9.110023515999956],
            [-36.785518937999939, -9.111044384999957],
            [-36.793183176999946, -9.112000635999948],
            [-36.803183156999978, -9.113246099999969],
            [-36.806564024999943, -9.113679160999936],
            [-36.816026741999963, -9.114878640999962],
            [-36.824663449999946, -9.115953825999952],
            [-36.833595671999944, -9.117081192999933],
            [-36.841920177999953, -9.118108181999958],
            [-36.850702145999946, -9.119215317999931],
            [-36.859708632999968, -9.120339257999944],
            [-36.87105889999998, -9.121768820999932],
            [-36.883343312999955, -9.123293671999932],
            [-36.89679407899996, -9.12499681099996],
            [-36.910986053999977, -9.126776251999956],
            [-36.924881640999956, -9.128515555999968],
            [-36.938329495999938, -9.130197735999957],
            [-36.950599099999977, -9.131746858999975],
            [-36.964087291999931, -9.133426398999973],
            [-36.976240803999929, -9.134989398999949],
            [-36.987142121999966, -9.136335186999929],
            [-36.994826192999938, -9.137291975999972],
            [-37.003483500999948, -9.138384963999954],
            [-37.012456225999983, -9.139500215999931],
            [-37.030718981999939, -9.141788396999971],
            [-37.042295611999975, -9.143245822999972],
            [-37.053377458999933, -9.144602167999949],
            [-37.062743788999967, -9.145808082999963],
            [-37.074339423999959, -9.147221710999929],
            [-37.083518253999955, -9.148392437999973],
            [-37.093121890999953, -9.14957232699993],
            [-37.100881604999984, -9.152428192999935],
            [-37.11140443599993, -9.15633149699994],
            [-37.119358043999966, -9.159167861999947],
            [-37.127254819999962, -9.16207972899997],
            [-37.137282375999973, -9.165742037999962],
            [-37.149766832999944, -9.170305369999937],
            [-37.159714305999955, -9.173914842999977],
            [-37.169997211999942, -9.177676826999971],
            [-37.172917059999975, -9.178750894999951],
            [-37.180614509999941, -9.181538648999947],
            [-37.187680476999958, -9.184110757999974],
            [-37.194296563999956, -9.186520456999972],
            [-37.20141532699995, -9.189096821999954],
            [-37.213577303999955, -9.193634520999979],
            [-37.221029285999975, -9.196483167999929],
            [-37.228321241999936, -9.199250996999979],
            [-37.238326506999954, -9.20306226799994],
            [-37.247312595999972, -9.206447182999966],
            [-37.25117303299993, -9.207943156999931],
            [-37.263510977999942, -9.209879710999928],
            [-37.276342869999951, -9.21193144199998],
            [-37.291902364999942, -9.214353237999944],
            [-37.306082604999972, -9.216601627999978],
            [-37.322193255999935, -9.219126499999959],
            [-37.347684395999977, -9.22311551699994],
            [-37.364571803999979, -9.225778344999981],
            [-37.375871942999936, -9.227530386999945],
            [-37.394673887999943, -9.230497917999969],
            [-37.425065419999953, -9.235285900999941],
            [-37.449180120999984, -9.239064626999948],
            [-37.473282575999974, -9.242831065999951],
            [-37.494664629999932, -9.246169445999953],
            [-37.504338192999967, -9.247714281999947],
            [-37.513867921999974, -9.249218182999925],
            [-37.523580428999935, -9.250738902999956],
            [-37.529998279999973, -9.25175289799995],
            [-37.541304673999946, -9.25350635999996],
            [-37.549484160999953, -9.254791639999951],
            [-37.574547160999941, -9.258728134999956],
            [-37.607863284999951, -9.263968408999972],
            [-37.622714502999941, -9.266282800999932],
            [-37.641361256999971, -9.269201731999942],
            [-37.665779268999984, -9.273018485999955],
            [-37.679191331999959, -9.275116107999963],
            [-37.692570468999975, -9.277201623999929],
            [-37.705983302999982, -9.279280318999952],
            [-37.726821468999958, -9.282551858999966],
            [-37.746261382999933, -9.285566733999929],
            [-37.767008106999981, -9.288826385999926],
            [-37.785669970999948, -9.291729106999981],
            [-37.81429761399994, -9.296221615999968],
            [-37.836026990999983, -9.299570075999952],
            [-37.854926884999941, -9.302507871999978],
            [-37.867998776999968, -9.304561475999947],
            [-37.881119927999976, -9.306607438999947],
            [-37.894890915999952, -9.308749210999963],
            [-37.912912154999958, -9.311546739999926],
            [-37.926168248999943, -9.313618451999957],
            [-37.938605694999978, -9.315557019999972],
            [-37.952020214999948, -9.317632814999968],
            [-37.958425043999966, -9.318625660999942],
            [-37.982578169999954, -9.326792848999958],
            [-38.00767975499997, -9.335275086999957],
            [-38.047679365999954, -9.348812701999975],
            [-38.118890045999933, -9.372887299999945],
            [-38.150203793999935, -9.383472180999945],
            [-38.169517213999939, -9.389992376999942],
            [-38.181112800999983, -9.393911872999979],
            [-38.183607375999941, -9.395007800999963],
            [-38.187898121999979, -9.399382423999953],
            [-38.19010936899997, -9.401570854999932],
            [-38.192668420999951, -9.402232018999939],
            [-38.194424738999942, -9.401463917999934],
            [-38.195303115999934, -9.400992904999953],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Garanhuns II - Paulo Afonso III C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 210.22511,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.90285,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.40333760599998, -8.884553020999931],
            [-36.403399035999939, -8.887028800999929],
            [-36.404464271999984, -8.889682767999943],
            [-36.412117170999977, -8.889590625999972],
            [-36.430310026999962, -8.889407956999946],
            [-36.44146103199995, -8.889258627999936],
            [-36.457471079999948, -8.900671140999975],
            [-36.475830597999959, -8.913695648999976],
            [-36.496028380999974, -8.927995813999928],
            [-36.512516237999932, -8.93969961099998],
            [-36.526696968999943, -8.949713303999943],
            [-36.543178274999946, -8.961416977999932],
            [-36.557353430999967, -8.971429201999968],
            [-36.577749032999975, -8.985862701999963],
            [-36.595844502999967, -8.998695304999956],
            [-36.615678775999982, -9.012709385999926],
            [-36.631073021999953, -9.023636116999967],
            [-36.645303211999931, -9.033711178999965],
            [-36.671114931999966, -9.051947543999972],
            [-36.701231260999975, -9.073202346999949],
            [-36.725596152999969, -9.090443513999958],
            [-36.736410116999934, -9.098106034999944],
            [-36.748145974999943, -9.10643665799995],
            [-36.749683825999966, -9.106603885999959],
            [-36.764193614999954, -9.108469904999936],
            [-36.781255592999969, -9.110609971999963],
            [-36.798542640999983, -9.112782742999968],
            [-36.815990322999937, -9.114958361999982],
            [-36.833584713999983, -9.117179156999953],
            [-36.850676758999953, -9.119320066999933],
            [-36.867087788999982, -9.121374171999946],
            [-36.892505774999961, -9.124570223999967],
            [-36.915258045999963, -9.127424280999946],
            [-36.942751533999967, -9.13087446399993],
            [-36.980891145999976, -9.135689068999966],
            [-37.01241241699995, -9.139623671999971],
            [-37.049863728999981, -9.144270658999972],
            [-37.09309907599993, -9.149684065999963],
            [-37.111389177999968, -9.156418627999926],
            [-37.127201321999962, -9.162157743999956],
            [-37.149715106999963, -9.170392102999926],
            [-37.169962576999978, -9.177778005999926],
            [-37.190511854999954, -9.185255454999947],
            [-37.201363524999977, -9.189203046999978],
            [-37.213559400999941, -9.193730069999958],
            [-37.242009025999948, -9.204569645999982],
            [-37.251128392999931, -9.208030195999982],
            [-37.254848457999969, -9.20862342199996],
            [-37.276338437999982, -9.212024455999938],
            [-37.29702111499995, -9.215262975999963],
            [-37.32938375699996, -9.220349714999941],
            [-37.347651116999941, -9.22320033099993],
            [-37.360942165999973, -9.225275906999968],
            [-37.375850594999974, -9.227629386999979],
            [-37.386600377999969, -9.229318809999938],
            [-37.402313293999953, -9.231788832999939],
            [-37.420436378999966, -9.234651729999939],
            [-37.438564995999968, -9.237488205999966],
            [-37.46244510799994, -9.241243972999939],
            [-37.478983386999971, -9.243809208999949],
            [-37.494701299999974, -9.246267281999963],
            [-37.513851966999937, -9.24932410599996],
            [-37.529982105999977, -9.251832947999958],
            [-37.549518362999947, -9.254875726999956],
            [-37.565998455999932, -9.25748533899997],
            [-37.599931827999967, -9.262809009999955],
            [-37.622687040999949, -9.266397204999976],
            [-37.649171115999934, -9.270522938999932],
            [-37.688539120999963, -9.276679719999947],
            [-37.714975157999959, -9.280794044999936],
            [-37.746260338999946, -9.28569030999995],
            [-37.761198529999945, -9.288011971999936],
            [-37.781321685999956, -9.291175700999929],
            [-37.789094717999944, -9.292396718999953],
            [-37.810068894999972, -9.295650685999931],
            [-37.845317868999985, -9.301130021999938],
            [-37.867997014999958, -9.30465302999994],
            [-37.894893049999951, -9.308847477999961],
            [-37.908393720999982, -9.31093416799996],
            [-37.921598356999937, -9.313006494999968],
            [-37.943861787999936, -9.316439887999934],
            [-37.958385349999958, -9.318718123999929],
            [-37.968207765999978, -9.322035230999973],
            [-37.984718227999963, -9.327599060999944],
            [-37.998854822999931, -9.332381602999931],
            [-38.023453678999942, -9.340708277999966],
            [-38.03601038499994, -9.344942826999954],
            [-38.047695651999959, -9.34890816799998],
            [-38.070482365999965, -9.356597401999977],
            [-38.081769899999983, -9.360450746999959],
            [-38.096455569999932, -9.365387269999928],
            [-38.108803338999962, -9.369582688999969],
            [-38.121886348999965, -9.373991166999929],
            [-38.135654009999939, -9.378651477999938],
            [-38.156222387999946, -9.385602511999934],
            [-38.177076437999972, -9.392658257999926],
            [-38.181045636999954, -9.393983537999929],
            [-38.183502398999963, -9.395069086999968],
            [-38.190022833999933, -9.401649605999978],
            [-38.192679673999976, -9.402362645999972],
            [-38.194486563999931, -9.401536541999974],
            [-38.195406441999978, -9.401114398999937],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Garanhuns II - Paulo Afonso III C4',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 210.08539,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.9016,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.403612309999971, -8.884572068999944],
            [-36.403790633999961, -8.885755332999963],
            [-36.405354289999934, -8.889349607999975],
            [-36.411916968999947, -8.889271487999963],
            [-36.423698441999932, -8.889156313999933],
            [-36.434204983999962, -8.889039293999929],
            [-36.441577197999948, -8.88894875799997],
            [-36.449870193999971, -8.894842170999937],
            [-36.457339167999976, -8.90012995799998],
            [-36.467730698999958, -8.907518152999955],
            [-36.474690787999975, -8.912455246999968],
            [-36.480909754999971, -8.916864657999952],
            [-36.489518473999965, -8.922967696999933],
            [-36.49991010399998, -8.930326697999931],
            [-36.508165792999932, -8.936191425999937],
            [-36.519487340999945, -8.944212247999928],
            [-36.534827593999978, -8.955080939999959],
            [-36.549138230999972, -8.965207841999927],
            [-36.557517841999982, -8.971142522999969],
            [-36.56789256299993, -8.978489714999967],
            [-36.58384162599998, -8.989758268999935],
            [-36.596026713999947, -8.998392113999955],
            [-36.612608886999965, -9.010129075999942],
            [-36.63191358399996, -9.02373717699993],
            [-36.653858967999952, -9.039338327999928],
            [-36.669731467999952, -9.050552040999946],
            [-36.685415515999978, -9.061635971999976],
            [-36.699253287999966, -9.071428339999954],
            [-36.723104386999978, -9.088307583999949],
            [-36.730760333999967, -9.093743695999933],
            [-36.737029813999982, -9.098185827999941],
            [-36.748171639999953, -9.106107359999953],
            [-36.789170077999984, -9.111238198999956],
            [-36.830262850999929, -9.116397547999952],
            [-36.891746552999962, -9.124153636999949],
            [-36.933984328999941, -9.12943344599995],
            [-36.964589325999953, -9.133272025999929],
            [-37.006692347999945, -9.138564644999974],
            [-37.035903725999958, -9.142196749999925],
            [-37.058994842999937, -9.145091943999944],
            [-37.093225264999944, -9.149326092999956],
            [-37.105184101999953, -9.153755015999934],
            [-37.130508948999932, -9.163024504999953],
            [-37.174787183999968, -9.17916814199998],
            [-37.222051761999978, -9.196608469999944],
            [-37.251212600999963, -9.207774770999947],
            [-37.259129308999945, -9.208947726999952],
            [-37.271690984999964, -9.210946145999969],
            [-37.28560017999996, -9.213147851999963],
            [-37.295777699999974, -9.214749368999946],
            [-37.311760730999936, -9.217269496999961],
            [-37.320142779999969, -9.218572004999942],
            [-37.328482841999971, -9.219884409999963],
            [-37.335918412999945, -9.221049892999929],
            [-37.34451194199994, -9.22238611399996],
            [-37.354741796999974, -9.22398898199998],
            [-37.362020756999982, -9.225126404999969],
            [-37.371044031999929, -9.226540120999971],
            [-37.381493148999937, -9.228190462999976],
            [-37.393393074999949, -9.230062619999956],
            [-37.40576975099998, -9.232003553999959],
            [-37.418564550999974, -9.234012808999978],
            [-37.42590852099994, -9.235168850999969],
            [-37.443939370999942, -9.237990916999934],
            [-37.452536122999959, -9.239354401999947],
            [-37.466102433999936, -9.241485048999948],
            [-37.479627039999968, -9.243609268999933],
            [-37.495016798999984, -9.246014473999935],
            [-37.510508237999943, -9.248465502999977],
            [-37.52580848599996, -9.250860083999953],
            [-37.546286437999981, -9.254050187999951],
            [-37.549550273999955, -9.254552068999942],
            [-37.576767294999968, -9.258864034999931],
            [-37.597304879999967, -9.262101890999929],
            [-37.618589310999937, -9.26540749399993],
            [-37.642026881999982, -9.269066208999959],
            [-37.66079986099993, -9.272009643999979],
            [-37.683808132999957, -9.275589053999965],
            [-37.691911642999969, -9.276856463999934],
            [-37.705911431999937, -9.27903925399994],
            [-37.720810050999944, -9.281376873999932],
            [-37.729190789999961, -9.282672561999959],
            [-37.738200499999948, -9.284105345999933],
            [-37.751596871999936, -9.286171655999965],
            [-37.760244472999943, -9.287529393999932],
            [-37.767569711999954, -9.288672385999973],
            [-37.78212197299996, -9.290949772999966],
            [-37.794509822999942, -9.292894986999954],
            [-37.812753932999954, -9.295735127999933],
            [-37.827491626999972, -9.298024580999936],
            [-37.846349231999966, -9.300954506999972],
            [-37.862364137999975, -9.303456378999954],
            [-37.881234550999977, -9.306395385999963],
            [-37.891096670999957, -9.307930565999982],
            [-37.898827245999939, -9.309132483999974],
            [-37.909681257999978, -9.310814878999963],
            [-37.920264281999948, -9.312464588999944],
            [-37.936489241999936, -9.315004181999939],
            [-37.943701897999972, -9.316106755999954],
            [-37.952110879999964, -9.31742329399998],
            [-37.958506986999964, -9.318359246999933],
            [-37.963251432999982, -9.320019361999925],
            [-37.974395274999949, -9.323785187999931],
            [-37.986472466999942, -9.32786441899998],
            [-38.004811349999954, -9.334067798999968],
            [-38.019272334999982, -9.338964093999948],
            [-38.032396061999975, -9.343406874999971],
            [-38.04500631999997, -9.347671668999965],
            [-38.063822232999939, -9.354039657999976],
            [-38.073400878999962, -9.35726817799997],
            [-38.083752736999941, -9.360773349999931],
            [-38.096170651999955, -9.364985238999964],
            [-38.105653752999956, -9.368183958999964],
            [-38.118606142999965, -9.372569184999975],
            [-38.12917140299993, -9.376142375999962],
            [-38.149352596999961, -9.382947805999947],
            [-38.172259483999937, -9.390678281999953],
            [-38.183298797999953, -9.394420719999971],
            [-38.186766963999958, -9.397898168999973],
            [-38.190236241999969, -9.401420048999967],
            [-38.192672271999982, -9.402022715999976],
            [-38.194317895999973, -9.401265790999958],
            [-38.195199782999964, -9.400876727999957],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Messias - Rio Largo II C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 11.98001,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.1036,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.841757929999972, -9.403282961999935],
            [-35.842225921999955, -9.406250734999958],
            [-35.842046340999957, -9.40895060899993],
            [-35.840102704999936, -9.41233540199994],
            [-35.835491605999948, -9.420389839999928],
            [-35.833871478999981, -9.423254505999978],
            [-35.832290111999953, -9.431533650999938],
            [-35.828861393999944, -9.449557825999932],
            [-35.83074604199993, -9.458465377999971],
            [-35.832740402999946, -9.46789247199996],
            [-35.833747142999982, -9.472582977999934],
            [-35.834715081999946, -9.477268760999948],
            [-35.835691422999957, -9.481820895999931],
            [-35.836753043999977, -9.486784341999964],
            [-35.837997754999947, -9.492794022999931],
            [-35.838199963999955, -9.497288134999962],
            [-35.837753357999929, -9.502743584999962],
            [-35.837747435999972, -9.50321728199998],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Messias - Rio Largo II C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 11.87516,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.1026,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.837882576999959, -9.503208921999942],
            [-35.837921649999942, -9.502698686999963],
            [-35.838448368999934, -9.497287763999964],
            [-35.838213394999968, -9.492752281999969],
            [-35.836976657999969, -9.486744280999972],
            [-35.836038151999958, -9.482301339999935],
            [-35.834826992999979, -9.476620955999977],
            [-35.834061323999947, -9.473050944999954],
            [-35.833257471999957, -9.469322911999939],
            [-35.83187744199995, -9.462656073999938],
            [-35.83030350699994, -9.455333173999975],
            [-35.833481660999951, -9.447423934999961],
            [-35.836073000999932, -9.441025121999928],
            [-35.83724104199996, -9.43495567399998],
            [-35.838532885999939, -9.42821712999995],
            [-35.839936122999973, -9.42093258899996],
            [-35.841523282999958, -9.412582162999968],
            [-35.84241498199998, -9.409029223999937],
            [-35.842383973999972, -9.40634280699993],
            [-35.842202884999949, -9.404608530999951],
            [-35.841856518999975, -9.403268898999954],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Messias - Rio Largo II C3',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 11.87441,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.10259,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.838036858999942, -9.503206578999936],
            [-35.837988780999979, -9.502687481999942],
            [-35.838511033999964, -9.497293456999955],
            [-35.838285078999945, -9.492751582999972],
            [-35.837042722999968, -9.48670418599994],
            [-35.835600320999959, -9.479923772999939],
            [-35.83413287299993, -9.473040423999976],
            [-35.832771801999968, -9.466543361999982],
            [-35.830390643999976, -9.455352389999973],
            [-35.833556473999977, -9.447459606999928],
            [-35.836161173999983, -9.441048286999944],
            [-35.837953814999935, -9.431684383999936],
            [-35.839338692999945, -9.42472016399995],
            [-35.840809293999939, -9.416980020999972],
            [-35.841636133999941, -9.412610190999942],
            [-35.842509886999949, -9.409037759999933],
            [-35.842467256999953, -9.406348016999971],
            [-35.842269329999965, -9.404607400999964],
            [-35.842023104999953, -9.403253039999981],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Messias - Maceió C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 26.64822,
        Ano_Opera: 2002.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.23444,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.842173893999984, -9.40322053999995],
            [-35.842416254999932, -9.404621844999951],
            [-35.843416118999983, -9.408117157999982],
            [-35.845447299999933, -9.415132035999932],
            [-35.846181519999959, -9.420288466999978],
            [-35.848015755999938, -9.423583876999942],
            [-35.85032089799995, -9.42686695499998],
            [-35.85882087799996, -9.439046296999948],
            [-35.86332559899995, -9.445424461999949],
            [-35.865290835999929, -9.448201152999957],
            [-35.869926279999959, -9.45492674999997],
            [-35.87106128299996, -9.463185858999964],
            [-35.872060656999963, -9.470857240999976],
            [-35.872745941999938, -9.476034947999949],
            [-35.869167231999938, -9.487180343999967],
            [-35.851489723999975, -9.496001902999978],
            [-35.848483150999982, -9.498173597999937],
            [-35.847704607999958, -9.499695662999954],
            [-35.842255462999958, -9.504417133999937],
            [-35.839269469999977, -9.506965621999939],
            [-35.839586502999964, -9.517561041999954],
            [-35.840924758999961, -9.520838332999972],
            [-35.84524281299997, -9.529455241999926],
            [-35.840868334999982, -9.539441462999946],
            [-35.839165822999973, -9.543957727999953],
            [-35.837649515999942, -9.547443921999957],
            [-35.832454395999946, -9.550617490999969],
            [-35.823102731999938, -9.556302518999928],
            [-35.807340903999943, -9.565838499999927],
            [-35.804275074999964, -9.570152275999931],
            [-35.802145595999946, -9.573124376999942],
            [-35.800357867999935, -9.573916942999972],
            [-35.794047305999982, -9.573722587999953],
            [-35.786992720999933, -9.578021200999956],
            [-35.785806054999966, -9.578561006999962],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Messias - Maceió C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 26.67384,
        Ano_Opera: 2002.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.23467,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.842319183999962, -9.403199424999968],
            [-35.842489647999969, -9.404582102999939],
            [-35.843500972999948, -9.408092169999975],
            [-35.844441569999958, -9.411336182999946],
            [-35.84557463799996, -9.415146352999955],
            [-35.846297449999952, -9.420268141999941],
            [-35.848084848999974, -9.423459046999938],
            [-35.852830356999959, -9.430249291999928],
            [-35.86338092099993, -9.445351718999973],
            [-35.86537250799995, -9.448165056999926],
            [-35.870069047999948, -9.454862503999948],
            [-35.871232603999943, -9.463138269999945],
            [-35.872222459999932, -9.470828687999926],
            [-35.872907744999964, -9.476053983999975],
            [-35.869300481999971, -9.487246968999955],
            [-35.855891534999955, -9.493919871999935],
            [-35.851527404999956, -9.496070246999977],
            [-35.848552852999944, -9.498238049999941],
            [-35.847765420999963, -9.499752135999927],
            [-35.842304654999964, -9.504473392999955],
            [-35.839351040999929, -9.507004694999978],
            [-35.839414902999977, -9.509136993999959],
            [-35.839649709999946, -9.517532269999947],
            [-35.841016601999968, -9.520818443999929],
            [-35.845363339999949, -9.529448911999964],
            [-35.84279113499997, -9.535352723999949],
            [-35.840950372999941, -9.539482987999975],
            [-35.839262874999974, -9.54401151199994],
            [-35.837731303999931, -9.547498403999953],
            [-35.832505806999961, -9.550689483999975],
            [-35.826929264999933, -9.554062834999968],
            [-35.82117274899997, -9.557560390999981],
            [-35.807425827999964, -9.565893279999955],
            [-35.804349083999966, -9.570218724999961],
            [-35.802217962999975, -9.573190348999958],
            [-35.800393199999974, -9.573999207999975],
            [-35.794108372999972, -9.573801422999964],
            [-35.787040800999932, -9.578095324999936],
            [-35.785877868999933, -9.578678020999973],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Angelim - Messias C3',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 81.24411,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.71709,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.842988673999969, -9.40316966599994],
            [-35.843084814999941, -9.403783510999972],
            [-35.843695673999946, -9.404824265999935],
            [-35.844235853999976, -9.40503354599997],
            [-35.844711004999965, -9.40458433799995],
            [-35.844349413999964, -9.402207314999941],
            [-35.844089363999956, -9.400314354999978],
            [-35.843979813999965, -9.399361251999949],
            [-35.844754, -9.394494993999956],
            [-35.845630029999938, -9.389875513999925],
            [-35.846669604999931, -9.384310205999952],
            [-35.84757458699994, -9.379474155999958],
            [-35.854126944999962, -9.373081835999926],
            [-35.86076503399994, -9.366652578999947],
            [-35.862404132999984, -9.363486292999937],
            [-35.864055873999973, -9.360314101999961],
            [-35.866440868999973, -9.355823205999968],
            [-35.868339914999979, -9.352250930999958],
            [-35.871460753999941, -9.346328227999948],
            [-35.878793854999969, -9.340824767999948],
            [-35.888172686999951, -9.334019626999975],
            [-35.893162415999939, -9.330393327999957],
            [-35.897109941999929, -9.327495144999943],
            [-35.90154287799993, -9.324497023999982],
            [-35.909304777999978, -9.319191626999952],
            [-35.916092392999929, -9.314592268999945],
            [-35.927067235999971, -9.307233763999932],
            [-35.929586976999929, -9.305604911999978],
            [-35.936075220999953, -9.301381808999963],
            [-35.94397293399993, -9.296235223999929],
            [-35.945770599999946, -9.295037253999965],
            [-35.949229636999974, -9.291881689999968],
            [-35.951138704999948, -9.290130395999938],
            [-35.954316413999948, -9.285351513999956],
            [-35.979589476999934, -9.247308634999968],
            [-35.987474732999942, -9.232840185999976],
            [-35.989651939999931, -9.223638809999954],
            [-35.991614994999964, -9.215272624999955],
            [-35.994356133999929, -9.203944010999976],
            [-35.997011612999984, -9.192679642999963],
            [-35.999959764999971, -9.18063005199997],
            [-36.000461830999939, -9.176243515999943],
            [-36.001308621999954, -9.170883482999955],
            [-36.002057259999958, -9.165852409999957],
            [-36.003800214999956, -9.153761178999957],
            [-36.00676694699996, -9.150629232999961],
            [-36.012917852999976, -9.144157098999926],
            [-36.016903449999973, -9.139820530999941],
            [-36.021162685999968, -9.13942197199998],
            [-36.028580654999985, -9.139564738999979],
            [-36.035391862999973, -9.139648019999981],
            [-36.039889043999949, -9.139594482999939],
            [-36.043946024999968, -9.139106692999974],
            [-36.052625108999962, -9.137417275999951],
            [-36.056604756999945, -9.134139567999966],
            [-36.059317342999975, -9.132313331999967],
            [-36.063564680999946, -9.128476450999926],
            [-36.076999579999949, -9.117493772999978],
            [-36.079850498999974, -9.115158230999953],
            [-36.084156206999978, -9.111564968999971],
            [-36.088941695999949, -9.107635621999975],
            [-36.094459487999984, -9.103132807999941],
            [-36.101736424999956, -9.097186835999935],
            [-36.108915636999939, -9.091371046999939],
            [-36.112703993999958, -9.088300200999981],
            [-36.120547315999943, -9.081952011999931],
            [-36.124608253999952, -9.078660105999973],
            [-36.128561046999948, -9.074923277999972],
            [-36.133682369999974, -9.070075297999949],
            [-36.139592093999966, -9.064487442999962],
            [-36.146118762999947, -9.058327278999968],
            [-36.150168393999934, -9.054496197999981],
            [-36.154289565999932, -9.050598123999976],
            [-36.160108183999967, -9.045118768999941],
            [-36.163854528999934, -9.041565367999965],
            [-36.170612002999974, -9.035151912999936],
            [-36.175999840999964, -9.030044972999974],
            [-36.179576252999937, -9.026612647999968],
            [-36.185176944999967, -9.02130204499997],
            [-36.18967753499993, -9.017034340999942],
            [-36.194382498999971, -9.012564055999974],
            [-36.203749248999941, -9.003634973999965],
            [-36.20716054899998, -9.00040923399996],
            [-36.209882850999975, -8.997813195999981],
            [-36.214636162999966, -8.993094550999956],
            [-36.22230284799997, -8.985450132999972],
            [-36.228496600999961, -8.978898078999975],
            [-36.233401121999975, -8.973730344999979],
            [-36.24014023999996, -8.966626503999976],
            [-36.24381044699993, -8.962741774999927],
            [-36.249155682999969, -8.957077713999979],
            [-36.253730956999959, -8.952102886999967],
            [-36.258819691999975, -8.946564213999977],
            [-36.260731275999945, -8.944482996999966],
            [-36.264357857999983, -8.940524578999941],
            [-36.268412138999963, -8.936143422999976],
            [-36.269727460999945, -8.93473440799994],
            [-36.271407834999934, -8.932082796999964],
            [-36.275783183999977, -8.925191272999939],
            [-36.276771517999975, -8.923630165999953],
            [-36.278874580999968, -8.92033139199998],
            [-36.283409, -8.913160002999973],
            [-36.285495622999974, -8.909923942999967],
            [-36.288494638999964, -8.905198168999959],
            [-36.291399300999956, -8.900655038999957],
            [-36.29154996699998, -8.898103225999932],
            [-36.291690909999943, -8.896124918999931],
            [-36.292267262999928, -8.895013653999968],
            [-36.293411636999963, -8.894083347999981],
            [-36.294154551999952, -8.894003],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Angelim - Messias C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 80.16526,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.70767,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.842836231999968, -9.403219403999969],
            [-35.842960382999934, -9.403783683999961],
            [-35.843517241999962, -9.404982792999931],
            [-35.844359744999963, -9.405849517999968],
            [-35.845096977999958, -9.404545025999937],
            [-35.844588015999932, -9.40219858599994],
            [-35.844226252999931, -9.399678568999946],
            [-35.844926462999979, -9.394645571999945],
            [-35.845691404999968, -9.390603140999929],
            [-35.846637774999977, -9.385577552999962],
            [-35.847763610999948, -9.379542332999961],
            [-35.861113395999951, -9.366583480999964],
            [-35.86845438499995, -9.352368044999935],
            [-35.871635134999963, -9.346389368999951],
            [-35.878711302999932, -9.341125197999929],
            [-35.88285020099994, -9.338161039999932],
            [-35.885973386999979, -9.335908755999981],
            [-35.89317669299993, -9.330707416999928],
            [-35.89760962899993, -9.327488005999953],
            [-35.901314449999973, -9.324982433999935],
            [-35.909432437999953, -9.319444379999936],
            [-35.921408432999954, -9.311290265999958],
            [-35.929337910999948, -9.306028703999971],
            [-35.936035284999946, -9.301657450999926],
            [-35.942266717999985, -9.297609215999955],
            [-35.945825770999932, -9.295272214999954],
            [-35.949512811999966, -9.292888263999942],
            [-35.951242624999963, -9.290315359999965],
            [-35.954349725999975, -9.285665619999975],
            [-35.979563392999978, -9.247779054999967],
            [-35.987646053999981, -9.232897292999951],
            [-35.989858952999953, -9.22370305499993],
            [-35.991807730999938, -9.215379700999961],
            [-35.994563146999951, -9.203908318999936],
            [-35.997040165999977, -9.193807506999974],
            [-36.000131085999953, -9.18112260099997],
            [-36.000670033999938, -9.176558794999949],
            [-36.001493029999949, -9.170818046999955],
            [-36.006335232999959, -9.166808655999944],
            [-36.009397004999983, -9.164328067999975],
            [-36.016240532999973, -9.158733557999938],
            [-36.02155862799998, -9.155192919999934],
            [-36.029510786999936, -9.150688600999956],
            [-36.038562255999977, -9.14556324199998],
            [-36.046384971999942, -9.141188720999935],
            [-36.052839259999985, -9.137560042999951],
            [-36.056670192999945, -9.134425103999945],
            [-36.057431619999932, -9.134169310999937],
            [-36.059840823999934, -9.132182461999946],
            [-36.062666433999937, -9.129529361999971],
            [-36.067365869999946, -9.12566273799996],
            [-36.076990849999959, -9.117821126999957],
            [-36.084306699999956, -9.111737945999948],
            [-36.096234711999955, -9.101963568999963],
            [-36.101946862999966, -9.09727025199993],
            [-36.117912676999936, -9.08434589999996],
            [-36.124845919999984, -9.078743948999943],
            [-36.134016258999964, -9.070057315999975],
            [-36.150348364999957, -9.054643978999934],
            [-36.156787908999945, -9.048553953999942],
            [-36.163826298999936, -9.041871320999974],
            [-36.170844760999955, -9.035232973999939],
            [-36.175957706999952, -9.030397925999978],
            [-36.185666707999928, -9.021213760999956],
            [-36.194992942999932, -9.012394833999963],
            [-36.203730615999973, -9.004101258999981],
            [-36.207883416999948, -9.000124088999939],
            [-36.212838431999955, -8.99518607899995],
            [-36.22241072099996, -8.985652028999937],
            [-36.233445760999984, -8.974012956999957],
            [-36.240617990999965, -8.966435041999944],
            [-36.246729343999959, -8.959946634999937],
            [-36.259174537999968, -8.946486714999935],
            [-36.26988494099993, -8.934823648999952],
            [-36.27613791999994, -8.924993382999958],
            [-36.283754516999977, -8.913007929999935],
            [-36.288336836999974, -8.905876972999977],
            [-36.291636430999972, -8.900697419999972],
            [-36.292441309999958, -8.89513046999997],
            [-36.293462516999966, -8.894160721999981],
            [-36.294139680999933, -8.894143102999976],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Angelim - Messias C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 80.22743,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.70821,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.842601704999936, -9.403221082999949],
            [-35.843237208999938, -9.40528807499993],
            [-35.844360719999941, -9.406152707999979],
            [-35.845216255999958, -9.404557975999978],
            [-35.844826249999983, -9.40225157499998],
            [-35.84451900199997, -9.400162025999975],
            [-35.845008506999932, -9.394663022999964],
            [-35.846736308999937, -9.385614543999964],
            [-35.847855441999968, -9.379609755999979],
            [-35.857627517999958, -9.370153022999943],
            [-35.861214060999941, -9.366632736999975],
            [-35.866791041999932, -9.355800095999939],
            [-35.871731442999931, -9.346450387999937],
            [-35.878780026999948, -9.341223963999937],
            [-35.888379699999973, -9.334333714999957],
            [-35.897702427999945, -9.327587943999958],
            [-35.90142866399998, -9.325089508999952],
            [-35.909609250999949, -9.319493016999957],
            [-35.921435418999977, -9.311376092999978],
            [-35.945881940999982, -9.295341728999972],
            [-35.949605610999981, -9.292981062999957],
            [-35.951350322999929, -9.290370155999938],
            [-35.979701033999959, -9.247845066999957],
            [-35.981721195999967, -9.244133107999971],
            [-35.987795959999971, -9.232940122999935],
            [-35.990001719999952, -9.223717331999978],
            [-35.991950498999984, -9.215408253999954],
            [-35.994713052999941, -9.203894042999934],
            [-35.997197210999957, -9.193843198999957],
            [-36.000288129999944, -9.181151153999963],
            [-36.000806852999972, -9.176558794999949],
            [-36.001594156999943, -9.170919174999938],
            [-36.006388770999934, -9.166933576999952],
            [-36.009496941999942, -9.164423245999956],
            [-36.016333331999931, -9.158847771999945],
            [-36.021630011999946, -9.155292857999939],
            [-36.029589308999959, -9.150795675999973],
            [-36.038626501999943, -9.14567031699994],
            [-36.046438509999973, -9.141295796999941],
            [-36.052910643999951, -9.137643324999942],
            [-36.05669398699996, -9.134526230999938],
            [-36.057467311999972, -9.134276386999943],
            [-36.05991815699997, -9.132277639999927],
            [-36.062731868999947, -9.129630489999954],
            [-36.067437253999969, -9.12575791699993],
            [-36.077069751999943, -9.117885303999969],
            [-36.08918420699996, -9.107884733999981],
            [-36.102008186999967, -9.09733711299998],
            [-36.117979616999946, -9.084425580999948],
            [-36.124932203999947, -9.078794806999952],
            [-36.128671493999946, -9.075261369999964],
            [-36.135445249999975, -9.068846608999934],
            [-36.143261476999953, -9.061487673999977],
            [-36.154537653999967, -9.05078905299996],
            [-36.163897995999946, -9.041925238999966],
            [-36.17092207099995, -9.035287911999944],
            [-36.180086180999979, -9.026631962999943],
            [-36.195075617999976, -9.012441908999961],
            [-36.207960377999939, -9.000180874999955],
            [-36.217564246999984, -8.990611429999944],
            [-36.222470149999936, -8.985711109999954],
            [-36.228494625999929, -8.979351682999948],
            [-36.23351852299993, -8.974067863999949],
            [-36.24068043699998, -8.966489885999977],
            [-36.24920171399998, -8.957434502999945],
            [-36.259282568999936, -8.946540676999973],
            [-36.269959975999939, -8.934895185999949],
            [-36.27624747599998, -8.925027221999926],
            [-36.279806057999963, -8.919455324999944],
            [-36.283862153999962, -8.913055813999961],
            [-36.288425410999935, -8.90593211099997],
            [-36.291740190999974, -8.900727629999949],
            [-36.292561637999938, -8.895181269999966],
            [-36.293413631999954, -8.894354457999953],
            [-36.294142846999932, -8.894250765999971],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Mussuré II - Norfil C1',
        Concession: 'TAESA - TRANSMISSORA ALIANÇA DE ENERGIA ELÉTRICA S A',
        Tensao: 230.0,
        Extensao: 1.81898,
        Ano_Opera: 2014.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.01573,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.894182348999948, -7.182181012999934],
            [-34.894643103999954, -7.182517805999964],
            [-34.896293107999952, -7.187655463999931],
            [-34.897556833999943, -7.191487636999966],
            [-34.898884342999963, -7.195557118999943],
            [-34.899071618999983, -7.196048484999949],
            [-34.899936218999983, -7.195738228999971],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Epasa - Mussuré II C1',
        Concession: 'EPASA - CENTRAIS ELÉTRICAS DA PARAÍBA S.A',
        Tensao: 230.0,
        Extensao: 5.64538,
        Ano_Opera: 2010.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.04938,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.894151122999972, -7.18232119299995],
            [-34.894555859999969, -7.182635030999961],
            [-34.894993906999957, -7.182940317999964],
            [-34.896497554999939, -7.187562154999966],
            [-34.899105399999939, -7.195529597999951],
            [-34.900740255999949, -7.198605162999968],
            [-34.902704424999968, -7.20246547499994],
            [-34.904522400999952, -7.203607199999965],
            [-34.904938987999969, -7.205535577999967],
            [-34.907670767999946, -7.209509733999937],
            [-34.910194002999958, -7.211828100999981],
            [-34.914000671999929, -7.212526620999938],
            [-34.917362913999966, -7.213135441999952],
            [-34.918863001999966, -7.21337849799994],
            [-34.919772406999982, -7.213051576999931],
            [-34.92011671299997, -7.210990609999953],
            [-34.920451428999968, -7.208773630999929],
            [-34.921015238999985, -7.208208910999929],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Goianinha - Norfil C1',
        Concession: 'TAESA - TRANSMISSORA ALIANÇA DE ENERGIA ELÉTRICA S A',
        Tensao: 230.0,
        Extensao: 52.40092,
        Ano_Opera: 2014.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.45511,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.088300998999955, -7.588660122999954],
            [-35.08826059699993, -7.58932886599996],
            [-35.087626124999929, -7.589093052999942],
            [-35.08714353199997, -7.585498758999961],
            [-35.085591167999951, -7.582395515999963],
            [-35.08436340999998, -7.579886769999973],
            [-35.081912946999978, -7.578882711999938],
            [-35.080583154999943, -7.574120694999976],
            [-35.078718143999936, -7.570542811999928],
            [-35.074995226999931, -7.56327633799998],
            [-35.071830921999947, -7.557034616999943],
            [-35.067606200999933, -7.548722992999956],
            [-35.06170551799994, -7.538206287999969],
            [-35.05904688499993, -7.532696117999933],
            [-35.055529089999936, -7.526817703999939],
            [-35.048190954999939, -7.51293762399996],
            [-35.043587482999953, -7.504557589999933],
            [-35.039808982999944, -7.497732543999973],
            [-35.037478583999984, -7.493459966999978],
            [-35.033647443999939, -7.486697697999944],
            [-35.032083222999972, -7.483892124999954],
            [-35.030663417999961, -7.481402221999929],
            [-35.021508069999982, -7.465134539999951],
            [-35.020060866999984, -7.462515881999934],
            [-35.018632388999947, -7.45993513999997],
            [-35.017186404999961, -7.457347250999931],
            [-35.014537621999978, -7.452554530999976],
            [-35.008211563999964, -7.441032978999942],
            [-35.004084267999929, -7.434119734999967],
            [-34.995788886999946, -7.419735750999962],
            [-34.990512314999933, -7.410441747999926],
            [-34.983930620999956, -7.398789497999928],
            [-34.979589227999952, -7.391274452999937],
            [-34.969698537999932, -7.373617874999979],
            [-34.964774591999969, -7.365039637999928],
            [-34.957135990999973, -7.351656409999975],
            [-34.952403835999974, -7.343393427999956],
            [-34.949401641999941, -7.326005837999958],
            [-34.947584831999961, -7.315652975999967],
            [-34.945218143999966, -7.312129624999955],
            [-34.942705986999954, -7.309057909999979],
            [-34.940887240999984, -7.304883313999937],
            [-34.937831627999969, -7.300414712999952],
            [-34.932704987999955, -7.293298687999936],
            [-34.934099438999965, -7.288668013999938],
            [-34.938070990999961, -7.285790933999976],
            [-34.941779700999973, -7.283073814999966],
            [-34.940343183999971, -7.274718649999954],
            [-34.939763734999929, -7.271302395999953],
            [-34.938839856999948, -7.266352083999948],
            [-34.935635631999958, -7.262821899999949],
            [-34.932580915999949, -7.259490675999928],
            [-34.931109798999955, -7.255610673999968],
            [-34.927292490999946, -7.252991793999968],
            [-34.924009721999937, -7.250780176999967],
            [-34.917700497999931, -7.248135834999971],
            [-34.91662589699996, -7.244304040999964],
            [-34.916289522999932, -7.237897863999933],
            [-34.916029972999979, -7.232764915999951],
            [-34.915822563999939, -7.228919452999946],
            [-34.911860851999961, -7.221035289999975],
            [-34.907178481999949, -7.211656177999942],
            [-34.904917362999981, -7.207188946999963],
            [-34.902490258999933, -7.202591806999976],
            [-34.899150739999982, -7.196133661999966],
            [-34.899957301999962, -7.195890912999971],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV UHE Sobradinho - Juazeiro II C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 42.83341,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.38788,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.477087683999969, -9.481744059999926],
            [-40.477896624999971, -9.481752965999931],
            [-40.47935278999995, -9.481653148999953],
            [-40.48823783499995, -9.479421340999977],
            [-40.496042379999949, -9.481621599999926],
            [-40.512034480999944, -9.486130526999943],
            [-40.552675325999985, -9.497624496999947],
            [-40.58690427199997, -9.507230566999965],
            [-40.629350109999962, -9.519149698999968],
            [-40.668596519999937, -9.530199186999937],
            [-40.672278201999973, -9.531346609999957],
            [-40.684595586999933, -9.524282349999964],
            [-40.69981456599993, -9.515459710999949],
            [-40.715372001999981, -9.506434370999955],
            [-40.73828638699996, -9.493150862999926],
            [-40.776591335999967, -9.470979532999934],
            [-40.803700583999955, -9.455280379999977],
            [-40.813991242999975, -9.449342157999979],
            [-40.819149312999969, -9.446370550999973],
            [-40.827345667999964, -9.443758881999941],
            [-40.82845423699996, -9.442411724999943],
            [-40.828486131999966, -9.438539737999974],
            [-40.82855402499996, -9.437216054999965],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV UHE Sobradinho - Juazeiro II C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 42.79784,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.38756,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.477270766999936, -9.481321987999934],
            [-40.477887155999952, -9.481661582999948],
            [-40.479352914999936, -9.48156242999994],
            [-40.485881915999983, -9.479922182999928],
            [-40.488261178999949, -9.479316137999945],
            [-40.496064044999969, -9.481534593999982],
            [-40.503476182999975, -9.483619492999935],
            [-40.512054396999929, -9.486031916999934],
            [-40.529458915999953, -9.490936972999975],
            [-40.539776318999941, -9.493848640999943],
            [-40.55272152699996, -9.497503086999927],
            [-40.565786242999934, -9.501174461999938],
            [-40.59110708399993, -9.508301141999937],
            [-40.612427894999939, -9.514279466999938],
            [-40.625140560999967, -9.517856589999951],
            [-40.633729716999937, -9.520281451999949],
            [-40.655355462999978, -9.526365085999942],
            [-40.664988230999938, -9.529072959999951],
            [-40.668632129999935, -9.530091107999965],
            [-40.67225377799997, -9.531231690999959],
            [-40.684497657999941, -9.524190026999975],
            [-40.703616303999979, -9.513116980999939],
            [-40.726618303999942, -9.499707480999973],
            [-40.753571663999935, -9.48415777699995],
            [-40.768758434999938, -9.475377565999963],
            [-40.780342448999932, -9.468667756999935],
            [-40.792077223999968, -9.461902087999931],
            [-40.803651478999939, -9.455194258999938],
            [-40.813939993999952, -9.449276040999962],
            [-40.819093040999974, -9.446273058999964],
            [-40.827255464999951, -9.443627364999941],
            [-40.828332073999945, -9.442388935999929],
            [-40.828338747999965, -9.440214719999972],
            [-40.828347287999975, -9.438530696999976],
            [-40.82841775199995, -9.437238910999952],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Angelim - Garanhuns II C4',
        Concession: 'IEG - INTERLIGAÇÃO ELÉTRICA GARANHUNS',
        Tensao: 230.0,
        Extensao: 12.19657,
        Ano_Opera: 2016.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.11064,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.294939193999937, -8.89331157099997],
            [-36.29528124199993, -8.893219366999972],
            [-36.296233291999954, -8.89264299499996],
            [-36.298280873999943, -8.891185290999942],
            [-36.314345915999979, -8.889315626999974],
            [-36.331538024999929, -8.887405205999926],
            [-36.362354665999931, -8.883978771999978],
            [-36.383861253999953, -8.883769798999936],
            [-36.394133164999971, -8.887341435999929],
            [-36.401011551999943, -8.886376411999947],
            [-36.402785362999964, -8.88528906099998],
            [-36.402916216999984, -8.884532726999964],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Angelim - Garanhuns II C3',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 12.21853,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.11085,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.295034372999964, -8.894123561999947],
            [-36.296510423999962, -8.894087695999929],
            [-36.298791181999945, -8.893986369999936],
            [-36.301310030999957, -8.893099592999931],
            [-36.306672006999975, -8.892817686999933],
            [-36.314936929999931, -8.892453444999944],
            [-36.316629445999979, -8.892327442999942],
            [-36.318193288999964, -8.891404428999977],
            [-36.32264502299995, -8.891177601999971],
            [-36.329905451999934, -8.890843004999965],
            [-36.340151805999938, -8.890372312999943],
            [-36.345405980999942, -8.890114790999974],
            [-36.356113035999954, -8.889969849999943],
            [-36.362645868999948, -8.889896826999973],
            [-36.369184089999976, -8.88980023199997],
            [-36.376410219999968, -8.889740355999947],
            [-36.384439123999982, -8.889648871999952],
            [-36.398070412999971, -8.889432714999941],
            [-36.401426911999977, -8.886714607999977],
            [-36.402935010999954, -8.885768372999962],
            [-36.403059827999982, -8.884519740999963],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Paulo Afonso III - UHE Paulo Afonso III C4',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 0.7061,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.00633,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.201859319999983, -9.398469102999968],
            [-38.19992779699993, -9.400459753999939],
            [-38.197668128999965, -9.401311470999929],
            [-38.196703386999957, -9.401929509999945],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Paulo Afonso III - UHE Paulo Afonso III C3',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 0.71402,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.00641,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.202046529999961, -9.398657650999951],
            [-38.200111253999978, -9.400672885999938],
            [-38.198911663999979, -9.401052066999966],
            [-38.197735884999929, -9.401448285999948],
            [-38.196831749999944, -9.402097645999959],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Paulo Afonso III - UHE Paulo Afonso III C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 0.72543,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.00653,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.202461869999979, -9.399144542999977],
            [-38.200409033999961, -9.400961268999936],
            [-38.19781790299993, -9.401592491999963],
            [-38.19689455799994, -9.402223037999931],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Paulo Afonso III - UHE Paulo Afonso III C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 0.72095,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.00649,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.202569627999935, -9.39940404999993],
            [-38.200586767999937, -9.401150528999949],
            [-38.197943840999983, -9.401721588999976],
            [-38.196998335999979, -9.402363112999979],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV UHE Apolônio Sales - Paulo Afonso III C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 6.06795,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.05366,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.207542411999952, -9.357753105999961],
            [-38.206084316999977, -9.358553660999974],
            [-38.204166892999979, -9.359973459999935],
            [-38.199896109999941, -9.363140671999929],
            [-38.197201651999933, -9.365157636999982],
            [-38.195254813999952, -9.36655531699995],
            [-38.19339271299998, -9.37213057799994],
            [-38.191817718999971, -9.376614451999956],
            [-38.189249010999959, -9.384102587999962],
            [-38.188697366999975, -9.385634838999977],
            [-38.188591175999932, -9.387629781999976],
            [-38.188453743999958, -9.389368041999944],
            [-38.188246733999961, -9.392355345999931],
            [-38.193193055999984, -9.396362837999959],
            [-38.195466056999976, -9.398210877999929],
            [-38.196007320999968, -9.400021386999981],
            [-38.195626001999983, -9.400220777999948],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV UHE Apolônio Sales - Paulo Afonso III C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 6.08376,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.05381,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.208259412999951, -9.358116165999945],
            [-38.206279413999937, -9.358748227999968],
            [-38.204349946999969, -9.36011551799993],
            [-38.201821836999954, -9.362033715999928],
            [-38.200166039999942, -9.36327135099998],
            [-38.197481580999977, -9.365271770999925],
            [-38.195482173999949, -9.366724374999933],
            [-38.194499141999984, -9.369758741999931],
            [-38.193566160999978, -9.372463808999953],
            [-38.191910496999981, -9.37722905399994],
            [-38.190103199999953, -9.382482712999945],
            [-38.189359217999936, -9.384806609999941],
            [-38.190915268999959, -9.386683812999934],
            [-38.19051178999996, -9.389479871999981],
            [-38.189997439999956, -9.392820621999931],
            [-38.19420153599998, -9.396167138999942],
            [-38.196044625999946, -9.397645329999932],
            [-38.196055459999968, -9.399030680999942],
            [-38.196066317999964, -9.400046621999934],
            [-38.195751018999943, -9.400344122999968],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Juazeiro II - Juazeiro III C1',
        Concession: 'ODOYA - ODOYA TRANSMISSORA DE ENERGIA S A',
        Tensao: 230.0,
        Extensao: 4.51678,
        Ano_Opera: 2016.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.04105,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.517305710999949, -9.486253136999949],
            [-40.516599415999963, -9.486101022999947],
            [-40.513511205999976, -9.485557067999935],
            [-40.510434344999965, -9.487229455999966],
            [-40.503651226999978, -9.486177436999981],
            [-40.500816681999936, -9.485710953999956],
            [-40.486788735999937, -9.48348092599997],
            [-40.483830508999972, -9.483048307999979],
            [-40.480434210999931, -9.481665684999939],
            [-40.478446585999961, -9.480857655999955],
            [-40.477526940999951, -9.480636623999942],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Pau Ferro - Recife II C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 118.58417,
        Ano_Opera: 2016.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.03531,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.021884372999978, -7.855727541999954],
            [-35.021358151999948, -7.854805385999953],
            [-35.024290820999965, -7.850646472999927],
            [-35.023110655999972, -7.843643424999925],
            [-35.022514419999936, -7.840153974999964],
            [-35.022832950999941, -7.837003046999939],
            [-35.022137492999946, -7.831390488999943],
            [-35.024996151999972, -7.827386005999927],
            [-35.030205390999981, -7.820003116999942],
            [-35.035875924999971, -7.811971677999964],
            [-35.04216222499997, -7.803112854999938],
            [-35.047369713999956, -7.795736094999938],
            [-35.050517242999945, -7.793322785999976],
            [-35.05626788099994, -7.78880178299994],
            [-35.059540767999977, -7.783966514999975],
            [-35.065597027999956, -7.782657313999948],
            [-35.074420850999957, -7.780935295999939],
            [-35.095099775999984, -7.77721500499996],
            [-35.099073475999944, -7.777238799999964],
            [-35.118275726999968, -7.77734587499998],
            [-35.135455434999983, -7.777417258999947],
            [-35.154776658999936, -7.777476745999934],
            [-35.15905005299993, -7.781217436999952],
            [-35.16510711199993, -7.786521722999964],
            [-35.169120518999932, -7.794534401999954],
            [-35.173445909999941, -7.803267723999966],
            [-35.178716415999929, -7.813915811999948],
            [-35.183296877999965, -7.82312432599997],
            [-35.187413345999971, -7.831345362999969],
            [-35.19185103999996, -7.840304033999928],
            [-35.196788421999941, -7.850238283999943],
            [-35.199905515999944, -7.856496265999965],
            [-35.201963749999948, -7.860648425999955],
            [-35.206734568999934, -7.869571404999931],
            [-35.212028483999973, -7.879457418999948],
            [-35.216872099999932, -7.888538301999972],
            [-35.226735480999935, -7.907045755999945],
            [-35.235050862999969, -7.922527093999975],
            [-35.235622851999949, -7.973195347999933],
            [-35.23578945099996, -7.988453923999941],
            [-35.241914160999954, -8.023142259999929],
            [-35.243651906999958, -8.033135553999955],
            [-35.244579895999948, -8.038406059999943],
            [-35.246102750999967, -8.047091092999949],
            [-35.24681658999998, -8.051017203999947],
            [-35.247173508999936, -8.054253270999936],
            [-35.248387033999961, -8.064711001999967],
            [-35.249184152999931, -8.071706616999961],
            [-35.24990988899998, -8.078321518999928],
            [-35.251004440999964, -8.087898848999941],
            [-35.251397051999959, -8.091218196999932],
            [-35.250266807999935, -8.09518],
            [-35.247970627999962, -8.103044117999957],
            [-35.246019469999965, -8.110015938999936],
            [-35.245020096999951, -8.113504228999943],
            [-35.244677453999941, -8.117444615999943],
            [-35.242300372999978, -8.123126768999953],
            [-35.240565745999959, -8.12906114499998],
            [-35.239295112999969, -8.133508357999972],
            [-35.237924543999952, -8.138262520999945],
            [-35.241400935999934, -8.14949357699993],
            [-35.244341949999978, -8.15915894699998],
            [-35.247568498999954, -8.169966458999966],
            [-35.248239506999937, -8.172122250999962],
            [-35.249883714999953, -8.177278542999943],
            [-35.248941447999982, -8.187664889999951],
            [-35.24783261999994, -8.198329633999947],
            [-35.246633370999973, -8.211150169999939],
            [-35.245848148999983, -8.219887550999943],
            [-35.244763114999955, -8.230909213999951],
            [-35.242557353999985, -8.25467527099994],
            [-35.242521662999934, -8.260861869999928],
            [-35.242521662999934, -8.265561304999949],
            [-35.242819094999959, -8.27196205499996],
            [-35.243021348999946, -8.278457983999942],
            [-35.243537691999961, -8.292587222999941],
            [-35.243770879999943, -8.297664992999955],
            [-35.244013584999948, -8.305517213999963],
            [-35.244268186999932, -8.310799617999976],
            [-35.244711955999946, -8.314475884999979],
            [-35.244657222999933, -8.314587858999971],
            [-35.241055571999937, -8.31108448699996],
            [-35.231095245999938, -8.30124756999993],
            [-35.224295321999932, -8.294678758999964],
            [-35.217165100999978, -8.28761312599994],
            [-35.213503290999938, -8.284106422999969],
            [-35.18508269199998, -8.256140969999933],
            [-35.149229086999981, -8.221295152999971],
            [-35.13194142499998, -8.204182617999948],
            [-35.13091213499996, -8.20314523299993],
            [-35.11795735499993, -8.190633746999936],
            [-35.096333102999949, -8.169485372999929],
            [-35.083277257999953, -8.156698269999936],
            [-35.074492857999928, -8.147969988999932],
            [-35.06829003699994, -8.141847988999928],
            [-35.063569666999967, -8.137206377999973],
            [-35.060564036999949, -8.13423710099994],
            [-35.056739741999934, -8.130451619999974],
            [-35.052567684999929, -8.128714133999949],
            [-35.042305639999938, -8.124470868999936],
            [-35.039110906999952, -8.12024202899994],
            [-35.037017608999975, -8.117432362999978],
            [-35.036514122999961, -8.116237328999944],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Garanhuns II - Pau Ferro C1',
        Concession: 'IEG - INTERLIGAÇÃO ELÉTRICA GARANHUNS',
        Tensao: 500.0,
        Extensao: 213.36988,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.89271,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.403132070999959, -8.88119658599993],
            [-36.402821308999933, -8.878282782999975],
            [-36.390997044999949, -8.871481995999943],
            [-36.356456707999939, -8.840451887999961],
            [-36.341966079999963, -8.826171878999958],
            [-36.337404901999946, -8.821673231999966],
            [-36.332079006999948, -8.815853747999938],
            [-36.325193189999936, -8.808386674999952],
            [-36.314822625999966, -8.797136552999973],
            [-36.298222643999964, -8.790543970999977],
            [-36.296219798999971, -8.789769896999928],
            [-36.290983471999937, -8.787695151999969],
            [-36.283487731999969, -8.78471630699994],
            [-36.275998408999953, -8.781772296999975],
            [-36.268740450999928, -8.77711792499997],
            [-36.265382774999978, -8.774342875999935],
            [-36.254248024999981, -8.765237106999962],
            [-36.247164823999981, -8.759403384999928],
            [-36.238778714999967, -8.752553714999976],
            [-36.230614672999934, -8.745864748999963],
            [-36.22282944799997, -8.73949080899996],
            [-36.219903172999977, -8.736906892999968],
            [-36.211678043999939, -8.729781852999963],
            [-36.203788287999942, -8.722882825999932],
            [-36.19569661099996, -8.715838656999949],
            [-36.188047381999979, -8.709162735999939],
            [-36.17991795699993, -8.702084286999934],
            [-36.172392721999984, -8.695542683999975],
            [-36.165086570999961, -8.689062996999951],
            [-36.15752533899996, -8.682362539999929],
            [-36.134903587999929, -8.662262947999977],
            [-36.130815992999942, -8.658520876999944],
            [-36.120764860999941, -8.649412881999979],
            [-36.113802015999966, -8.643103745999952],
            [-36.105811213999971, -8.635865607999961],
            [-36.099293878999958, -8.629887886999938],
            [-36.09226128499995, -8.623520815999939],
            [-36.085401543999978, -8.617322659999957],
            [-36.080380083999955, -8.612742633999972],
            [-36.072276034999959, -8.605376901999932],
            [-36.063274876999969, -8.597169010999949],
            [-36.05530788599998, -8.589935194999953],
            [-36.029432332999932, -8.566441657999974],
            [-36.014950011999929, -8.55325202299997],
            [-36.001489803999959, -8.540987814999937],
            [-35.994018117999929, -8.534195408999949],
            [-35.984446881999929, -8.526126814999941],
            [-35.97803913599995, -8.520887071999937],
            [-35.975093726999944, -8.518417950999947],
            [-35.971556576999944, -8.514755383999955],
            [-35.957831956999939, -8.500355415999934],
            [-35.949388346999967, -8.491929737999953],
            [-35.938400199999933, -8.481115771999953],
            [-35.926798748999943, -8.469651430999932],
            [-35.919165645999954, -8.462166403999959],
            [-35.90518481099997, -8.44831321199996],
            [-35.898135397999965, -8.441515950999928],
            [-35.886853746999975, -8.43065166699995],
            [-35.87902298399996, -8.423109135999937],
            [-35.87258630599996, -8.416923707999956],
            [-35.862791997999977, -8.407493508999949],
            [-35.855506451999929, -8.40056214699996],
            [-35.850532738999959, -8.395179664999944],
            [-35.844816574999982, -8.388925387999961],
            [-35.83576403099994, -8.379257612999936],
            [-35.826579660999982, -8.369610996999938],
            [-35.819339627999966, -8.361965777999956],
            [-35.809349210999983, -8.351476337999941],
            [-35.800077424999984, -8.341703618999929],
            [-35.791721650999932, -8.33001900499994],
            [-35.783215319999954, -8.318327957999941],
            [-35.775692216999971, -8.307826668999951],
            [-35.773420613999974, -8.305178501999933],
            [-35.768487102999984, -8.29942493599998],
            [-35.758370045999982, -8.289825729999961],
            [-35.740119647999961, -8.273172874999943],
            [-35.719717988999946, -8.254443924999975],
            [-35.711756591999972, -8.247200156999952],
            [-35.712219321999953, -8.240153912999972],
            [-35.712250101999985, -8.234686841999974],
            [-35.711057323999967, -8.22749046399997],
            [-35.709922687999949, -8.220992470999931],
            [-35.70948335099996, -8.212504132999925],
            [-35.70873697899998, -8.197199071999933],
            [-35.687507903999972, -8.184652042999971],
            [-35.637858367999968, -8.155904355999951],
            [-35.63073425999994, -8.151742660999957],
            [-35.619534464999958, -8.14521028799993],
            [-35.605587207999974, -8.137139487999946],
            [-35.599523855999962, -8.131322435999948],
            [-35.593136647999984, -8.127662931999964],
            [-35.58158130299995, -8.120953542999928],
            [-35.570735126999978, -8.114743610999938],
            [-35.55513866299998, -8.105691890999935],
            [-35.536447821999957, -8.094779800999959],
            [-35.527203615999952, -8.089461705999952],
            [-35.517990184999974, -8.084247578999964],
            [-35.50198811599995, -8.07502213999993],
            [-35.490520907999951, -8.068370207999976],
            [-35.486097517999951, -8.065820180999935],
            [-35.482576824999967, -8.063894396999956],
            [-35.466804325999931, -8.055500465999955],
            [-35.457354537999947, -8.050533723999933],
            [-35.453276823999943, -8.048659186999942],
            [-35.437831778999964, -8.04155811399994],
            [-35.426914067999974, -8.033426730999963],
            [-35.415028653999968, -8.024626960999967],
            [-35.402340383999956, -8.015151037999942],
            [-35.395635707999929, -8.010105901999964],
            [-35.390219555999977, -8.006106386999932],
            [-35.381640766999965, -7.999703878999981],
            [-35.372973334999983, -7.993210288999933],
            [-35.36110617199995, -7.985323880999943],
            [-35.345009491999974, -7.974481778999973],
            [-35.327553730999966, -7.962831858999948],
            [-35.319772780999983, -7.957626662999928],
            [-35.316114956999968, -7.955667571999982],
            [-35.307867364999936, -7.952079305999973],
            [-35.292315551999934, -7.945638823999957],
            [-35.284056616999976, -7.942055813999957],
            [-35.273175716999958, -7.937158588999978],
            [-35.265402264999977, -7.933684288999928],
            [-35.256397450999941, -7.928483209999968],
            [-35.251855735999982, -7.925808409999945],
            [-35.231690737999941, -7.914280355999949],
            [-35.223244834999946, -7.89848976899998],
            [-35.216358740999965, -7.885638426999947],
            [-35.209625750999976, -7.873027463999961],
            [-35.207603071999984, -7.869309663999957],
            [-35.202784663999978, -7.860220122999976],
            [-35.200440894999929, -7.855532584999935],
            [-35.198204200999953, -7.851094889999956],
            [-35.195705766999936, -7.846014740999976],
            [-35.191815348999967, -7.838174416999948],
            [-35.187211090999938, -7.828930210999943],
            [-35.182928061999974, -7.82031656199996],
            [-35.179144718999964, -7.812726080999937],
            [-35.174561876999974, -7.803472356999976],
            [-35.169556505999935, -7.793365257999938],
            [-35.168552240999929, -7.791371],
            [-35.165831601999969, -7.785960633999935],
            [-35.15773672499995, -7.778871427999945],
            [-35.155097885999965, -7.776608241999952],
            [-35.134027756999956, -7.776549288999945],
            [-35.119120434999957, -7.776477371999931],
            [-35.098918810999976, -7.776370295999925],
            [-35.09502839299995, -7.776358398999946],
            [-35.075749497999936, -7.779779317999953],
            [-35.06522487899997, -7.781807094999976],
            [-35.058985984999936, -7.783168706999959],
            [-35.055589084999951, -7.788211080999929],
            [-35.046690646999934, -7.79510991899997],
            [-35.025969086999964, -7.824429094999971],
            [-35.021204959999977, -7.831165774999931],
            [-35.021903780999935, -7.837047134999978],
            [-35.021585859999959, -7.840181382999958],
            [-35.023321331999966, -7.850446604999945],
            [-35.020426139999984, -7.854376332999948],
            [-35.021298221999984, -7.855915600999936],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Arapiraca III - Penedo C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 91.2992,
        Ano_Opera: 2013.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.81583,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.561991775999957, -10.242768226999942],
            [-36.561173675999953, -10.242520872999933],
            [-36.557940087999953, -10.239161518999936],
            [-36.555258366999965, -10.236335654999948],
            [-36.548202338999943, -10.231637643999932],
            [-36.542449499999975, -10.227855847999933],
            [-36.537146378999978, -10.224343397999974],
            [-36.531042068999966, -10.220256867999979],
            [-36.52531717599993, -10.21644545099997],
            [-36.518996318999939, -10.212117647999946],
            [-36.515986718999955, -10.210114154999928],
            [-36.512148658999934, -10.205502582999941],
            [-36.508624561999966, -10.201329256999941],
            [-36.505034765999937, -10.197018990999936],
            [-36.499362628999961, -10.190230219999933],
            [-36.493003549999969, -10.182588463999934],
            [-36.489805023999963, -10.178806130999931],
            [-36.485047497999972, -10.173155294999958],
            [-36.479008866999948, -10.165828184999953],
            [-36.473990049999941, -10.159847106999962],
            [-36.464116914999977, -10.147996667999962],
            [-36.459305608999955, -10.142227796999975],
            [-36.454169148999938, -10.136078710999982],
            [-36.450016944999959, -10.131044207999935],
            [-36.441975358999969, -10.121617743999934],
            [-36.43358922699997, -10.113753000999964],
            [-36.429008886999952, -10.109416469999928],
            [-36.424131720999981, -10.104871217999971],
            [-36.419201781999959, -10.100230210999939],
            [-36.413210294999942, -10.09457901899998],
            [-36.405855031999977, -10.087619569999958],
            [-36.399206994999929, -10.081411019999962],
            [-36.394059076999952, -10.076524218999964],
            [-36.388317603999951, -10.071166236999943],
            [-36.382914932999938, -10.066074457999946],
            [-36.377603366999949, -10.061047279999968],
            [-36.374440877999973, -10.058322533999956],
            [-36.371760778999942, -10.055603874999974],
            [-36.366096175999985, -10.050260846999947],
            [-36.36014142099998, -10.044602202999954],
            [-36.353562289999957, -10.038480500999981],
            [-36.348632890999966, -10.033758690999946],
            [-36.343741053999963, -10.029551857999934],
            [-36.341546616999949, -10.027395126999977],
            [-36.335487415999978, -10.021449851999932],
            [-36.330803080999942, -10.015118665999978],
            [-36.328115264999951, -10.011605334999956],
            [-36.321718288999932, -10.001528196999971],
            [-36.316898388999959, -9.993910092999954],
            [-36.314684304999957, -9.990439092999964],
            [-36.309876322999969, -9.98285806399997],
            [-36.305771388999972, -9.976406261999955],
            [-36.302650264999954, -9.971510566999939],
            [-36.298764486999971, -9.965351171999941],
            [-36.296607338999934, -9.962067562999948],
            [-36.292748280999945, -9.955991893999965],
            [-36.291878080999936, -9.954426817999945],
            [-36.293150032999961, -9.954925067999966],
            [-36.305211421999957, -9.946726579999961],
            [-36.318926916999942, -9.941510070999925],
            [-36.332258292999938, -9.936427424999977],
            [-36.346354624999947, -9.931044547999932],
            [-36.357653475999939, -9.926747748999958],
            [-36.370845796999959, -9.921686664999982],
            [-36.397092984999972, -9.911665321999976],
            [-36.402829904999976, -9.909435013999939],
            [-36.417509270999972, -9.903874139999971],
            [-36.429512256999942, -9.900264939999943],
            [-36.432963571999949, -9.899126121999927],
            [-36.442185375999941, -9.89313689599993],
            [-36.45077202799996, -9.887761435999948],
            [-36.458363849999955, -9.882885378999958],
            [-36.464245366999933, -9.87915372599997],
            [-36.467818103999946, -9.876840158999926],
            [-36.47598448399998, -9.875498664999952],
            [-36.484698572999946, -9.874055374999955],
            [-36.491909988999964, -9.872865575999981],
            [-36.499181722999936, -9.871659984999951],
            [-36.506389542999955, -9.870471255999973],
            [-36.510781926999982, -9.869739795999976],
            [-36.518734239999958, -9.868435033999958],
            [-36.52212542999996, -9.867322587999979],
            [-36.53441939399994, -9.863214995999954],
            [-36.541644441999949, -9.860794562999956],
            [-36.554030874999967, -9.856699610999954],
            [-36.562648484999954, -9.853842036999936],
            [-36.574881925999932, -9.849750047999976],
            [-36.578896806999978, -9.848444103999952],
            [-36.589510968999946, -9.847559889999957],
            [-36.596284238999942, -9.84702944199995],
            [-36.608376896999971, -9.846070285999929],
            [-36.613445298999977, -9.845651606999979],
            [-36.616659599999934, -9.844082513999979],
            [-36.625991746999944, -9.839496509999947],
            [-36.628111421999961, -9.837169486999926],
            [-36.631933723999964, -9.834245443999976],
            [-36.634466479999958, -9.83426988399998],
            [-36.637709866999955, -9.829751499999929],
            [-36.639674186999969, -9.826976779999939],
            [-36.641874212999937, -9.81874974699997],
            [-36.64378447799993, -9.811498790999963],
            [-36.645628217999956, -9.804395469999974],
            [-36.648122862999969, -9.79503596099994],
            [-36.649051827999983, -9.791330258999949],
            [-36.649736466999968, -9.788824443999943],
            [-36.649738966999962, -9.787442165999948],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Arapiraca III - Rio Largo II C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 127.1123,
        Ano_Opera: 2013.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.13341,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.649594922999938, -9.787423438999951],
            [-36.649649355999941, -9.788798953999958],
            [-36.64895811599996, -9.791292120999969],
            [-36.647997723999936, -9.794989717999954],
            [-36.646629160999964, -9.800110537999956],
            [-36.644290111999965, -9.809046480999939],
            [-36.64270619399997, -9.815057372999945],
            [-36.640443363999964, -9.823595603999934],
            [-36.639561594999975, -9.826915616999941],
            [-36.634397484999965, -9.834135940999943],
            [-36.631885248999936, -9.834104023999942],
            [-36.628009479999946, -9.83705989799995],
            [-36.62588530499994, -9.839399629999946],
            [-36.616601128999946, -9.843961479999962],
            [-36.613404618999937, -9.845544359999963],
            [-36.608337191999965, -9.845932145999939],
            [-36.596267238999928, -9.846891763999963],
            [-36.589479234999942, -9.847443889999965],
            [-36.578884676999962, -9.848284667999962],
            [-36.566816290999952, -9.852309886999933],
            [-36.557984522999959, -9.855239107999978],
            [-36.549863814999981, -9.857928319999928],
            [-36.541604503999963, -9.860686334999968],
            [-36.534375524999973, -9.86311597699995],
            [-36.522058242999947, -9.867206212999974],
            [-36.518709698999942, -9.868313484999931],
            [-36.510751284999969, -9.86962824799997],
            [-36.502544439999951, -9.870977256999936],
            [-36.491887533999943, -9.872739456999966],
            [-36.480386781999982, -9.874656061999929],
            [-36.471908602999974, -9.876040272999944],
            [-36.467765794999934, -9.876719668999954],
            [-36.460787359999983, -9.881177448999949],
            [-36.453263340999968, -9.885947029999954],
            [-36.444938933999936, -9.89122890699997],
            [-36.442008851999958, -9.893034981999961],
            [-36.432880290999947, -9.899007148999942],
            [-36.429412724999963, -9.90010833599996],
            [-36.417339022999954, -9.903746721999937],
            [-36.402654152999958, -9.909291722999967],
            [-36.397038277999968, -9.911507866999955],
            [-36.370746686999951, -9.921493415999976],
            [-36.357510364999939, -9.926580307999927],
            [-36.34616043699998, -9.930899201999978],
            [-36.332184451999979, -9.936237994999942],
            [-36.318843684999933, -9.941307910999967],
            [-36.305058817999964, -9.946577333999926],
            [-36.293134001999931, -9.954826254999944],
            [-36.291862049999963, -9.954328004999979],
            [-36.286769317999983, -9.946538744999941],
            [-36.282716678999975, -9.940231751999931],
            [-36.274669576999941, -9.927492599999937],
            [-36.270211133999965, -9.920517638999968],
            [-36.264209993999941, -9.911047282999959],
            [-36.262130984999942, -9.907816786999945],
            [-36.254626257999973, -9.904790393999974],
            [-36.239934260999974, -9.899004840999964],
            [-36.225564965999979, -9.893323917999965],
            [-36.211616042999935, -9.887756895999928],
            [-36.20263089499997, -9.884188644999938],
            [-36.197180311999944, -9.877366227999971],
            [-36.195068060999972, -9.874751494999941],
            [-36.191054647999977, -9.869463492999955],
            [-36.18346472099995, -9.859623800999941],
            [-36.179275798999981, -9.854021354999929],
            [-36.177260646999969, -9.848239120999949],
            [-36.170087250999984, -9.840828],
            [-36.165982596999982, -9.836568941999928],
            [-36.158051612999941, -9.826085889999945],
            [-36.152105543999937, -9.817919669999981],
            [-36.144966431999933, -9.808292129999927],
            [-36.138433713999973, -9.79953373099994],
            [-36.133016261999956, -9.79213386999993],
            [-36.128132093999966, -9.785455377999938],
            [-36.121948783999983, -9.777079678999939],
            [-36.115625168999941, -9.768610652999939],
            [-36.110577940999974, -9.761715653999943],
            [-36.114419044999977, -9.751841566999929],
            [-36.117830615999935, -9.743038395999974],
            [-36.121684647999984, -9.733242048999955],
            [-36.125723370999935, -9.723095882999928],
            [-36.129600122999932, -9.713144305999947],
            [-36.124446893999959, -9.703306654999949],
            [-36.119256468999936, -9.693588862999945],
            [-36.115701282999964, -9.68690700999997],
            [-36.113123796999957, -9.682050674999971],
            [-36.102286704999983, -9.672751569999946],
            [-36.09243599399997, -9.664339863999942],
            [-36.082571032999965, -9.655857596999965],
            [-36.079772978999983, -9.653397641999959],
            [-36.072905550999963, -9.646667612999977],
            [-36.063524145999963, -9.637465324999937],
            [-36.053696908999939, -9.627745394999977],
            [-36.05070477199996, -9.624788936999948],
            [-36.041911316999972, -9.617508165999936],
            [-36.030689794999944, -9.608100291999961],
            [-36.020454472999972, -9.599540906999948],
            [-36.01115396199998, -9.591692270999943],
            [-36.003500217999942, -9.585200879999945],
            [-35.992867475999958, -9.576323517999981],
            [-35.984187343999963, -9.569265062999932],
            [-35.978649526999959, -9.560787097999935],
            [-35.971738647999985, -9.550182043999939],
            [-35.965574106999952, -9.540645150999978],
            [-35.962123696999981, -9.535378864999927],
            [-35.960072099999934, -9.532963934999941],
            [-35.952684362999946, -9.524351595999974],
            [-35.946289266999941, -9.51683963399995],
            [-35.943075848999968, -9.513153715999977],
            [-35.940130917999966, -9.51159161399994],
            [-35.924889864999955, -9.506294398999955],
            [-35.91690394799997, -9.500602814999979],
            [-35.914224412999943, -9.498683954999933],
            [-35.903533099999947, -9.49463923199994],
            [-35.896700343999953, -9.492019738999943],
            [-35.882663152999953, -9.486685442999942],
            [-35.879717897999967, -9.485565128999951],
            [-35.870584952999934, -9.489344220999953],
            [-35.857289327999979, -9.494783224999935],
            [-35.852585083999941, -9.496724951999965],
            [-35.847300907999966, -9.499005238999928],
            [-35.844426426999974, -9.500931825999942],
            [-35.841364359999943, -9.50291640599994],
            [-35.839140004999933, -9.502991065999936],
            [-35.83848121699998, -9.503041555999971],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Paulo Afonso III - Floresta II C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 81.41158,
        Ano_Opera: 2014.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.7139,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.195807824999974, -9.40051249399994],
            [-38.196780607999983, -9.40002524199997],
            [-38.196810735999975, -9.399633040999959],
            [-38.196497686999976, -9.398851382999965],
            [-38.192211510999982, -9.39523926499993],
            [-38.189841833999935, -9.393230686999971],
            [-38.187938921999944, -9.391801404999967],
            [-38.186124622999955, -9.39033584799995],
            [-38.183887726999956, -9.388632944999927],
            [-38.179697303999944, -9.385463394999931],
            [-38.179808246999983, -9.37658159199998],
            [-38.179905424999959, -9.367314208999971],
            [-38.179992658999936, -9.359395905999975],
            [-38.180045220999943, -9.35314774699998],
            [-38.180098616999942, -9.348034603999963],
            [-38.180149319999941, -9.342603285999928],
            [-38.180243214999962, -9.334179683999935],
            [-38.180322055999966, -9.325234763999958],
            [-38.180407976999959, -9.317591411999956],
            [-38.180525953999961, -9.306598243999929],
            [-38.180653915999983, -9.294202254999959],
            [-38.18069030199996, -9.28744510599995],
            [-38.180842488999929, -9.276613116999954],
            [-38.18102087099993, -9.260133645999929],
            [-38.181202097999972, -9.244617957999935],
            [-38.181357942999966, -9.228260360999968],
            [-38.181494807999968, -9.215259980999974],
            [-38.181694617999938, -9.198681525999973],
            [-38.181831287999955, -9.184786011999961],
            [-38.181946898999968, -9.173921532999941],
            [-38.182030097999984, -9.165724820999969],
            [-38.18212548799994, -9.156868476999932],
            [-38.182410410999978, -9.126376685999958],
            [-38.181565766999938, -9.118264010999951],
            [-38.18163500299994, -9.112303638999947],
            [-38.182573691999949, -9.10989501399996],
            [-38.182718815999976, -9.099301792999938],
            [-38.182801831999939, -9.091144821999933],
            [-38.182864457999983, -9.08516560299995],
            [-38.183141951999971, -9.063073768999971],
            [-38.183177327999942, -9.056488569999942],
            [-38.183364584999936, -9.038676574999954],
            [-38.183504285999959, -9.02494543399996],
            [-38.18368295199997, -9.006165298999974],
            [-38.183846951999953, -8.990058102999967],
            [-38.183945903999984, -8.98063484599993],
            [-38.184013750999952, -8.973036002999947],
            [-38.184030370999949, -8.968160325999975],
            [-38.187303331999942, -8.963627507999945],
            [-38.208078967999938, -8.934304862999966],
            [-38.243151601999955, -8.884799556999951],
            [-38.250940255999978, -8.87380744099994],
            [-38.257144629999971, -8.865035073999934],
            [-38.265022678999969, -8.853892917999929],
            [-38.27122133599994, -8.84513848399996],
            [-38.275994969999942, -8.838377629999968],
            [-38.283605763999958, -8.827637748999962],
            [-38.289082879999967, -8.819875842999977],
            [-38.294053573999975, -8.812894177999965],
            [-38.300620042999981, -8.803590307999968],
            [-38.308363413999984, -8.792627035999942],
            [-38.313365583999939, -8.785583462999966],
            [-38.319049617999951, -8.777501581999957],
            [-38.327138528999967, -8.766028662999929],
            [-38.333417843999939, -8.757175914999948],
            [-38.338143090999949, -8.750508746999969],
            [-38.340220861999967, -8.74755887699996],
            [-38.342019168999968, -8.748904995999965],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Paulo Afonso III - Tacaratu C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 49.05797,
        Ano_Opera: 2014.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.43045,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.195883112999979, -9.40063431599998],
            [-38.196879989999957, -9.400144093999927],
            [-38.196889038999984, -9.399641956999972],
            [-38.196549596999944, -9.39880014199997],
            [-38.194815951999942, -9.397350915999937],
            [-38.192270443999973, -9.395191657999931],
            [-38.189875724999979, -9.393148063999945],
            [-38.188572432999933, -9.39179022299993],
            [-38.184316259999946, -9.388616184999933],
            [-38.180017063999969, -9.385347491999937],
            [-38.18004781999997, -9.381987349999974],
            [-38.18008886299998, -9.376799161999941],
            [-38.180178138999963, -9.368830311999943],
            [-38.180208465999954, -9.363706219999926],
            [-38.180270656999937, -9.360777897999981],
            [-38.180291137999973, -9.358297558999936],
            [-38.180310713999972, -9.35594326699993],
            [-38.180346606999933, -9.352544000999956],
            [-38.180376524999929, -9.347183593999944],
            [-38.180453256999954, -9.339576779999959],
            [-38.180545073999951, -9.333968976999927],
            [-38.180614950999939, -9.325077983999961],
            [-38.180669835999936, -9.320779658999982],
            [-38.18073549199994, -9.315581161999944],
            [-38.180777, -9.307819172999928],
            [-38.180854120999982, -9.302395539999964],
            [-38.180897991999984, -9.296885214999975],
            [-38.180932886999983, -9.294182737999961],
            [-38.180973437999967, -9.287439900999971],
            [-38.18106641299994, -9.283270953999931],
            [-38.181128342999955, -9.275708668999926],
            [-38.181282506999935, -9.259408342999961],
            [-38.181373468999936, -9.254076443999963],
            [-38.181429582999954, -9.248897277999959],
            [-38.181527287999984, -9.239321773999961],
            [-38.181581790999985, -9.233876108999937],
            [-38.181595959999981, -9.231427382999925],
            [-38.181662148999976, -9.226110915999925],
            [-38.181710409999937, -9.223158149999961],
            [-38.181776919999947, -9.215129997999952],
            [-38.181851891999941, -9.209479513999952],
            [-38.181938926999976, -9.201281501999972],
            [-38.181983780999929, -9.195917682999948],
            [-38.182036872999959, -9.191147938999961],
            [-38.182137288999968, -9.182295984999939],
            [-38.182195170999933, -9.176175193999939],
            [-38.182257420999974, -9.170533835999947],
            [-38.182344605999958, -9.162147669999968],
            [-38.183559489999936, -9.04520501899998],
            [-38.18356988599993, -9.044225095999934],
            [-38.183547207999936, -9.044224850999967],
            [-38.181792821999977, -9.044184222999945],
            [-38.178340009999943, -9.044163951999963],
            [-38.170430610999972, -9.044074272999978],
            [-38.161786096999947, -9.043993000999933],
            [-38.153085881999971, -9.043914892999965],
            [-38.145536025999945, -9.04383831399997],
            [-38.141253932999973, -9.046276364999926],
            [-38.136031223999964, -9.049262795999937],
            [-38.128973943999938, -9.053278428999931],
            [-38.128875418999939, -9.058312615999967],
            [-38.130360253999982, -9.059491188999971],
            [-38.131066825999937, -9.05948929799996],
            [-38.131780313999968, -9.059455938999974],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Epasa - Termoparaíba C1',
        Concession: 'EPASA - CENTRAIS ELÉTRICAS DA PARAÍBA S.A',
        Tensao: 230.0,
        Extensao: 0.09998,
        Ano_Opera: 2011.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.00088,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.921110572999964, -7.208316338999964],
            [-34.920830986999931, -7.208625668999957],
            [-34.921143290999964, -7.208964741999978],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Epasa - Termonordeste C1',
        Concession: 'EPASA - CENTRAIS ELÉTRICAS DA PARAÍBA S.A',
        Tensao: 230.0,
        Extensao: 0.10116,
        Ano_Opera: 2010.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.00089,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.921592413999974, -7.207804754999927],
            [-34.921889846999932, -7.207525167999961],
            [-34.92225568899994, -7.20784342099995],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV UHE Xingó - Xingó C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 0.95598,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.0084,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-37.796937654999965, -9.624756599999955],
            [-37.794798133999961, -9.62633911699993],
            [-37.795106892999968, -9.63025818999995],
            [-37.795175588999939, -9.632059566999942],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV UHE Xingó - Xingó C3',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 0.93495,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.00821,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-37.797140807999938, -9.624925065999946],
            [-37.795076969999968, -9.626482526999951],
            [-37.795651954999983, -9.630435872999954],
            [-37.795584202999976, -9.632065542999953],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV UHE Xingó - Xingó C4',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 0.93335,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.0082,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-37.797358697999982, -9.625088023999979],
            [-37.795204706999982, -9.626506433999964],
            [-37.795781073999933, -9.630410004999931],
            [-37.795863460999954, -9.632087763999948],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV UHE Xingó - Xingó C5',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 0.81507,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.00711,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-37.797568840999929, -9.625239944999976],
            [-37.796329844999946, -9.627874745999975],
            [-37.796338155999933, -9.630435435999971],
            [-37.796271296999976, -9.632076273999928],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV UHE Xingó - Xingó C6',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 0.80221,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.00701,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-37.797784412999931, -9.625399142999981],
            [-37.796468727999979, -9.627891015999978],
            [-37.796494974999973, -9.630405064999934],
            [-37.796539717999963, -9.632078881999973],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV UHE Xingó - Xingó C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 0.96611,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.00848,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-37.796741126999962, -9.624587599999927],
            [-37.794667365999942, -9.626302948999978],
            [-37.794975202999979, -9.630323400999941],
            [-37.794905235999977, -9.632076083999948],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Campina Grande III - Ceará Mirim II C1',
        Concession: 'ETN - EXTREMOZ TRANSMISSORA DO NORDESTE',
        Tensao: 500.0,
        Extensao: 200.22413,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.73683,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.979905132999932, -7.251505883999926],
            [-35.979900006999969, -7.250095572999953],
            [-35.976764097999933, -7.24760570899997],
            [-35.970959051999955, -7.244591090999961],
            [-35.963676564999957, -7.240812913999946],
            [-35.95568045899995, -7.236660082999947],
            [-35.949780438999937, -7.233580436999944],
            [-35.944293138999967, -7.23072775299994],
            [-35.942420309999932, -7.225034303999962],
            [-35.942597705999958, -7.215785088999951],
            [-35.942671863999976, -7.208830693999971],
            [-35.942695915999934, -7.207857627999942],
            [-35.939434636999977, -7.205153548999931],
            [-35.933929500999966, -7.200544940999976],
            [-35.929485251999949, -7.196828061999952],
            [-35.927384672999949, -7.191227720999962],
            [-35.92606449699997, -7.187637004999942],
            [-35.92529768199995, -7.185538246999954],
            [-35.923531004999973, -7.180764667999938],
            [-35.922553629999982, -7.177965249999943],
            [-35.920145069999933, -7.174741617999928],
            [-35.919759147999969, -7.168351141999949],
            [-35.918798887999969, -7.165629164999928],
            [-35.917211879999968, -7.161112256999957],
            [-35.915658193999946, -7.156658630999971],
            [-35.914408800999979, -7.153120334999926],
            [-35.913157611999964, -7.149489489999951],
            [-35.910710023999968, -7.144330264999951],
            [-35.909045696999954, -7.140765580999926],
            [-35.907231488999969, -7.136862143999963],
            [-35.905285907999939, -7.131111263999969],
            [-35.903334554999958, -7.125318840999967],
            [-35.90187218799997, -7.120952682999928],
            [-35.899870533999945, -7.115184583999962],
            [-35.898725170999967, -7.111728397999968],
            [-35.897362807999968, -7.107915185999957],
            [-35.896630846999983, -7.105632904999936],
            [-35.895303157999933, -7.101763133999953],
            [-35.893865145999939, -7.097521299999926],
            [-35.893000940999968, -7.094997274999969],
            [-35.891834247999952, -7.091529854999976],
            [-35.890811871999972, -7.088498303999927],
            [-35.891211602999931, -7.085408207999933],
            [-35.891681645999938, -7.080733143999964],
            [-35.892196309999974, -7.076623386999927],
            [-35.892791583999951, -7.071146541999951],
            [-35.892638800999976, -7.064581663999945],
            [-35.892567799999938, -7.057732027999975],
            [-35.892506954999931, -7.054958752999937],
            [-35.893348066999977, -7.049133239999946],
            [-35.893681293999975, -7.046287938999967],
            [-35.894128573999978, -7.043054540999947],
            [-35.894924329999981, -7.036193948999937],
            [-35.895436396999969, -7.032267564999927],
            [-35.895858302999955, -7.028736065999965],
            [-35.894988464999983, -7.028139970999973],
            [-35.893359510999971, -7.02497819599995],
            [-35.891452974999936, -7.021331656999962],
            [-35.89009611299997, -7.018823495999925],
            [-35.887222017999932, -7.013292061999948],
            [-35.885055474999945, -7.009203567999975],
            [-35.881461519999959, -7.00236287499996],
            [-35.879204701999981, -6.998030829999948],
            [-35.875438753999958, -6.990851100999976],
            [-35.871867739999971, -6.983519143999956],
            [-35.868288356999983, -6.975836726999944],
            [-35.862252499999954, -6.965757627999949],
            [-35.858537630999933, -6.958719506999955],
            [-35.851484211999946, -6.945285567999974],
            [-35.847639968999943, -6.93794381999993],
            [-35.839746033999972, -6.922955616999957],
            [-35.835086192999938, -6.914096622999978],
            [-35.823545966999973, -6.892123664999929],
            [-35.81857219099993, -6.882627808999928],
            [-35.806524982999974, -6.859563058999981],
            [-35.805327416999944, -6.857296877999943],
            [-35.803163918999928, -6.852197257999933],
            [-35.799934780999934, -6.84448469199998],
            [-35.797064056999943, -6.837617903999956],
            [-35.79263395199996, -6.829611535999959],
            [-35.785952305999956, -6.817286645999957],
            [-35.781394636999948, -6.808994450999933],
            [-35.777807466999946, -6.80244119799994],
            [-35.770006946999956, -6.788382351999928],
            [-35.765389358999983, -6.780545204999953],
            [-35.761208245999967, -6.77293717699996],
            [-35.757470006999938, -6.765780052999958],
            [-35.742739858999983, -6.737578504999931],
            [-35.735404440999957, -6.723564805999956],
            [-35.730876919999957, -6.714941784999951],
            [-35.727457225999956, -6.708273936999944],
            [-35.696387146999939, -6.648973829999932],
            [-35.686291572999949, -6.629670574999977],
            [-35.681505368999979, -6.620511582999939],
            [-35.677226119999943, -6.612404882999954],
            [-35.671030074999976, -6.600523943999974],
            [-35.66634004399998, -6.591536641999937],
            [-35.664418221999938, -6.587806970999964],
            [-35.662965890999942, -6.582676662999972],
            [-35.660027014999969, -6.579447398999946],
            [-35.657826640999929, -6.575582535999956],
            [-35.654851270999984, -6.568791110999939],
            [-35.650437638999961, -6.561228964999941],
            [-35.645571704999952, -6.551921403999927],
            [-35.637345052999933, -6.536252053999931],
            [-35.631325832999948, -6.524825566999937],
            [-35.626829225999984, -6.516211093999971],
            [-35.618344006999962, -6.50010713599994],
            [-35.611196718999963, -6.486524456999973],
            [-35.60737594699998, -6.479273312999965],
            [-35.604500728999938, -6.467324682999958],
            [-35.602245030999939, -6.458141807999937],
            [-35.600414239999964, -6.450798286999941],
            [-35.598327420999965, -6.442028769999979],
            [-35.596704829999965, -6.435385920999977],
            [-35.595234369999957, -6.429481566999925],
            [-35.594600313999933, -6.426808244999961],
            [-35.593687522999971, -6.423065897999948],
            [-35.591685535999943, -6.414760262999948],
            [-35.588475249999931, -6.401684391999936],
            [-35.586931048999929, -6.392576442999939],
            [-35.585404774999972, -6.383622356999979],
            [-35.583947147999936, -6.375040045999981],
            [-35.582514144999948, -6.369852428999934],
            [-35.580325204999951, -6.36178111199996],
            [-35.576477056999977, -6.347885447999943],
            [-35.574007476999952, -6.338974980999978],
            [-35.572192774999962, -6.332279568999979],
            [-35.567323148999947, -6.323483303999978],
            [-35.563018172999932, -6.315637056999947],
            [-35.556079052999962, -6.302995439999961],
            [-35.54951867799997, -6.291075621999937],
            [-35.548437125815909, -6.289108792304777],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Campina Grande III - Ceará Mirim II C2',
        Concession: 'POTIGUAR SUL - POTIGUAR SUL TRANSMISSÃO DE ENERGIA SA',
        Tensao: 500.0,
        Extensao: 198.20678,
        Ano_Opera: 2016.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.71832,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.549053261922062, -6.289108792304777],
            [-35.552191194999978, -6.294809967999981],
            [-35.55353421999996, -6.297281801999929],
            [-35.560763365999946, -6.308861436999962],
            [-35.565778952999949, -6.316942415999961],
            [-35.570599861999938, -6.324758809999935],
            [-35.573266191999949, -6.328955682999947],
            [-35.575530424999954, -6.338243994999971],
            [-35.577607968999985, -6.346696191999968],
            [-35.579858112999943, -6.355987907999975],
            [-35.58305151999997, -6.368974055999956],
            [-35.585000298999944, -6.376694111999939],
            [-35.587030457999958, -6.389376497999933],
            [-35.588973898999939, -6.401286596999967],
            [-35.591794139999934, -6.412792735999972],
            [-35.595085582999957, -6.426389339999957],
            [-35.597334187999934, -6.435687240999926],
            [-35.598356893999949, -6.43989295199998],
            [-35.600591402999953, -6.447935920999953],
            [-35.603257201999952, -6.456372478999981],
            [-35.604815863999931, -6.46138270299997],
            [-35.610509817999969, -6.479556128999945],
            [-35.611836541999935, -6.483758166999962],
            [-35.61601116199995, -6.49191080199995],
            [-35.620351601999971, -6.500330622999968],
            [-35.622504165999942, -6.50453882599993],
            [-35.636969989999955, -6.532674483999926],
            [-35.641293628999961, -6.54111986199996],
            [-35.645459756999969, -6.549245969999959],
            [-35.647135098999968, -6.552510565999967],
            [-35.649102978999963, -6.556248239999945],
            [-35.657755828999939, -6.57006359899998],
            [-35.661995269999977, -6.576748326999962],
            [-35.667827752999983, -6.589637701999948],
            [-35.670928302999982, -6.596491086999947],
            [-35.672448820999932, -6.599834255999951],
            [-35.67891762499994, -6.614188114999934],
            [-35.685072948999959, -6.626135776999945],
            [-35.689405585999964, -6.634464943999944],
            [-35.693205846999945, -6.641795112999944],
            [-35.736714732999928, -6.724952024999936],
            [-35.745109628999955, -6.740696606999961],
            [-35.757130507999932, -6.761643313999969],
            [-35.764530631999946, -6.775467981999952],
            [-35.774506797999948, -6.794232239999928],
            [-35.789970650999976, -6.822301800999981],
            [-35.798057237999956, -6.837500530999932],
            [-35.801364688999968, -6.843544361999932],
            [-35.814071009999964, -6.869718432999946],
            [-35.833987098999955, -6.910787929999969],
            [-35.842529363999972, -6.927039647999948],
            [-35.860042196999984, -6.958805452999968],
            [-35.866871249999974, -6.970774141999925],
            [-35.87755502999994, -6.988834250999957],
            [-35.886168678999979, -7.003111016999981],
            [-35.896706514999948, -7.028388328999938],
            [-35.90822628199993, -7.056339559999969],
            [-35.915079129999981, -7.08203773799994],
            [-35.920956398999976, -7.10416672599996],
            [-35.928689646999942, -7.122726521999937],
            [-35.93561387799997, -7.141524263999941],
            [-35.939611372999934, -7.155467904999966],
            [-35.954530592999959, -7.185449113999937],
            [-35.958313935999968, -7.195942536999951],
            [-35.966761022999947, -7.207816046999937],
            [-35.970639543999937, -7.22223558099995],
            [-35.974089762999938, -7.23496569699995],
            [-35.975298884999972, -7.237884773999951],
            [-35.978383842999961, -7.244427188999964],
            [-35.980706432999966, -7.249352815999941],
            [-35.981234477999976, -7.250556485999937],
            [-35.981131605999963, -7.251564105999933],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Angelim - Garanhuns II C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 12.2619,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.11125,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.294933245999971, -8.893427568999925],
            [-36.295293690999983, -8.893301002999976],
            [-36.296390260999942, -8.892727202999936],
            [-36.298594934999983, -8.892613041999937],
            [-36.30181129999994, -8.892463659999976],
            [-36.306353510999941, -8.892213347999927],
            [-36.311173159999953, -8.891972763999945],
            [-36.319802774999971, -8.891572266999958],
            [-36.32784746599998, -8.891169659999946],
            [-36.337869135999938, -8.890708879999977],
            [-36.345403036999983, -8.890334468999981],
            [-36.356623730999956, -8.890200493999942],
            [-36.365738716999942, -8.890082897999946],
            [-36.376194439999949, -8.889980123999976],
            [-36.385198456999944, -8.889824773999976],
            [-36.394283911999935, -8.889727443999959],
            [-36.399030225999979, -8.889665788999935],
            [-36.403142293999963, -8.885874369999954],
            [-36.40320025099993, -8.884543497999971],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV São Clemente - Garanhuns II C1',
        Concession:
          'VENTOS S CLEMENTE 1 - VENTOS DE SAO CLEMENTE I ENERGIAS RENOVAVEIS S.A',
        Tensao: 230.0,
        Extensao: 43.96416,
        Ano_Opera: 2016.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.39477,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.404057288999979, -8.884529586999975],
            [-36.404183204999981, -8.884888659999945],
            [-36.405219140999975, -8.884925407999958],
            [-36.406067718999964, -8.881905845999938],
            [-36.407202198999983, -8.877763217999927],
            [-36.406829176999963, -8.873535391999951],
            [-36.408215447999964, -8.869516244999943],
            [-36.408980217999954, -8.867287700999952],
            [-36.411790863999954, -8.861156542999936],
            [-36.412378721999971, -8.85891929099995],
            [-36.414080223999974, -8.856066215999931],
            [-36.416851695999981, -8.851296391999938],
            [-36.418686134999973, -8.84712815499995],
            [-36.42063552999997, -8.84258652699998],
            [-36.42263466199995, -8.837998121999931],
            [-36.42445203699998, -8.833749798999975],
            [-36.426347587999942, -8.829378542999962],
            [-36.428274924999982, -8.824961723999934],
            [-36.430031860999975, -8.82119961199993],
            [-36.435534551999979, -8.809735488999934],
            [-36.447158246999948, -8.804742837999981],
            [-36.454692896999973, -8.801514195999971],
            [-36.467681762999973, -8.795944897999959],
            [-36.475712339999973, -8.792501372999936],
            [-36.481384594999952, -8.790057285999978],
            [-36.489015140999982, -8.791867259999947],
            [-36.498007038999958, -8.793947990999925],
            [-36.506954927999971, -8.796052316999976],
            [-36.510942153999963, -8.796990333999929],
            [-36.522804987999962, -8.799761091999926],
            [-36.532540300999983, -8.802025312999945],
            [-36.53436542399993, -8.802473167999949],
            [-36.543256028999963, -8.800229651999928],
            [-36.551278555999943, -8.798223853999957],
            [-36.55834413599996, -8.797082813999964],
            [-36.562203891999957, -8.796463676999963],
            [-36.570415971999978, -8.795157192999966],
            [-36.579572865999978, -8.793737540999928],
            [-36.583898022999961, -8.793053289999932],
            [-36.591809202999968, -8.793560692999961],
            [-36.600501037999948, -8.79408906499998],
            [-36.605234787999962, -8.794406616999936],
            [-36.613070669999956, -8.792504925999935],
            [-36.62648414399996, -8.789230287999942],
            [-36.630328714999962, -8.788281562999941],
            [-36.641837486999975, -8.783604516999958],
            [-36.645679958999949, -8.782057538999936],
            [-36.658449526999959, -8.778392559999929],
            [-36.664976068999977, -8.776461192999932],
            [-36.669533671999943, -8.775917783999944],
            [-36.676987973999928, -8.774996384999952],
            [-36.680586092999931, -8.774526841999943],
            [-36.687206510999943, -8.772704334999958],
            [-36.695693946999938, -8.770358697999939],
            [-36.707583385999953, -8.766973674999974],
            [-36.723491527999954, -8.762998337999932],
            [-36.730959625999958, -8.760594869999977],
            [-36.734402565999972, -8.759443021999971],
            [-36.735723894999978, -8.759440565999967],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Caetés II - Garanhuns II C1',
        Concession:
          'EOL SANTA BRIGIDA I - VENTOS DE SANTA BRIGIDA I ENERGIAS RENOVAVEIS S.A',
        Tensao: 230.0,
        Extensao: 46.43492,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.41741,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.403915196999947, -8.884535328999959],
            [-36.404108792999978, -8.885575828999947],
            [-36.405438416999971, -8.885568169999942],
            [-36.405881362999935, -8.883854657999962],
            [-36.406570261999946, -8.881476901999974],
            [-36.408571736999932, -8.874198691999936],
            [-36.410100693999937, -8.86868356399998],
            [-36.41096734499996, -8.865538969999932],
            [-36.411933549999958, -8.861998206999942],
            [-36.412734216999979, -8.859063245999948],
            [-36.415732763999983, -8.853940226999953],
            [-36.417192434999947, -8.85143321399994],
            [-36.418726333999984, -8.84792317299997],
            [-36.421988763999934, -8.840420859999938],
            [-36.425016156999959, -8.833448055999952],
            [-36.426726531999975, -8.829398026999968],
            [-36.428595049999956, -8.825107642999967],
            [-36.430148564999968, -8.821806065999965],
            [-36.434193063999942, -8.813413245999925],
            [-36.435797497999943, -8.809991150999963],
            [-36.438935082999933, -8.80863691299993],
            [-36.443189827999959, -8.806841006999946],
            [-36.448108183999977, -8.804731490999927],
            [-36.455988771999955, -8.801329034999981],
            [-36.463323229999958, -8.798222348999957],
            [-36.472017645999983, -8.794478697999978],
            [-36.481075587999953, -8.790562353999974],
            [-36.485585739999976, -8.791626128999951],
            [-36.494890620999968, -8.793810201999975],
            [-36.508062000999985, -8.796882146999963],
            [-36.517344139999977, -8.799043936999965],
            [-36.526128963999952, -8.80108024599997],
            [-36.534039165999957, -8.802958690999958],
            [-36.546481384999936, -8.806283166999947],
            [-36.55601446999998, -8.808824102999949],
            [-36.564325611999948, -8.811063803999957],
            [-36.573732442999983, -8.812916565999956],
            [-36.592440708999959, -8.816531106999946],
            [-36.601236333999964, -8.818242260999966],
            [-36.609451161999971, -8.819877384999927],
            [-36.612346868999964, -8.82011780299996],
            [-36.619645210999977, -8.820811781999964],
            [-36.627252405999968, -8.822500522999974],
            [-36.636974965999968, -8.824681925999926],
            [-36.648668033999968, -8.82728491499995],
            [-36.651911006999967, -8.827764588999969],
            [-36.659612453999955, -8.828977585999951],
            [-36.664183960999935, -8.82967804599997],
            [-36.672046328999954, -8.830913795999948],
            [-36.679128446999982, -8.832691117999957],
            [-36.686422272999948, -8.834512553999957],
            [-36.690025827999932, -8.835430293999934],
            [-36.69312881999997, -8.835845366999934],
            [-36.702588094999953, -8.837231196999937],
            [-36.716209291999974, -8.839182260999962],
            [-36.728668556999935, -8.84096681799997],
            [-36.735789758999942, -8.841986486999929],
            [-36.745076660999985, -8.843296364999958],
            [-36.753854383999965, -8.844108241999947],
            [-36.758591098999943, -8.844533418999958],
            [-36.759397113999967, -8.84409812499996],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Garanhuns II - Paranatama C1',
        Concession: 'SERRA DAS VACAS I - EOLICA SERRA DAS VACAS I S.A.',
        Tensao: 230.0,
        Extensao: 45.37978,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.40644,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.40377054399994, -8.88454853099995],
            [-36.403966326999978, -8.885783708999952],
            [-36.405683703999955, -8.885823297999934],
            [-36.409779868999976, -8.879062562999934],
            [-36.411047438999958, -8.876990286999956],
            [-36.41353629799994, -8.873863010999969],
            [-36.418137781999974, -8.868057079999971],
            [-36.418349003999936, -8.865361438999969],
            [-36.420197213999984, -8.860038749999944],
            [-36.421457550999946, -8.855332846999943],
            [-36.423266659999967, -8.848385520999955],
            [-36.424103248999984, -8.845170355999926],
            [-36.426689326999963, -8.836622191999936],
            [-36.427994326999965, -8.83234695799996],
            [-36.429428648999931, -8.827305727999942],
            [-36.430064271999981, -8.825405245999946],
            [-36.433247116999951, -8.817354959999932],
            [-36.436069869999983, -8.81027058899997],
            [-36.452014585999962, -8.803448714999945],
            [-36.456247008999981, -8.80162926099996],
            [-36.47507950499994, -8.801267440999936],
            [-36.480086529999937, -8.801147603999937],
            [-36.489292789999979, -8.80501443199995],
            [-36.497627247999958, -8.808482899999944],
            [-36.506660906999969, -8.812298276999968],
            [-36.515861295999969, -8.816139441999951],
            [-36.527951909999956, -8.821340654999972],
            [-36.540951563999954, -8.827059363999979],
            [-36.557480931999976, -8.834330117999968],
            [-36.567417343999978, -8.838641695999968],
            [-36.579974669999956, -8.844175520999954],
            [-36.589663680999934, -8.847040331999949],
            [-36.592969434999929, -8.849328662999937],
            [-36.600532978999979, -8.853798216999962],
            [-36.604611880999983, -8.856201847999955],
            [-36.611208352999938, -8.860050577999971],
            [-36.619095500999947, -8.864732889999971],
            [-36.622147408999979, -8.866512644999943],
            [-36.630843432999939, -8.87127178999998],
            [-36.644036566999944, -8.878500225999971],
            [-36.65281213399993, -8.881434628999955],
            [-36.66558213299993, -8.885673664999956],
            [-36.668641320999939, -8.886674348999975],
            [-36.676035580999951, -8.890230083999938],
            [-36.687917203999973, -8.895916216999979],
            [-36.697572236999974, -8.898505771999965],
            [-36.709377759999938, -8.901698176999957],
            [-36.713297451999949, -8.902686794999966],
            [-36.717541300999983, -8.90391150499994],
            [-36.725647981999941, -8.906232971999941],
            [-36.731999316999975, -8.90806364499997],
            [-36.732546461999959, -8.908186100999956],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Icó - Milagres C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 107.06134,
        Ano_Opera: 2012.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.93795,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.880826906999971, -6.409402118999935],
            [-38.880198728999972, -6.409506814999929],
            [-38.87795727699995, -6.410134992999929],
            [-38.874976957999934, -6.410583021999969],
            [-38.872332579999977, -6.411303508999936],
            [-38.867952518999971, -6.410305616999949],
            [-38.86782887399994, -6.410261000999981],
            [-38.866459721999945, -6.414346638999973],
            [-38.865179571999931, -6.418177570999944],
            [-38.863947010999937, -6.421794351999949],
            [-38.863033297999948, -6.424678257999972],
            [-38.862942877999956, -6.428580573999966],
            [-38.862885770999981, -6.431207498999981],
            [-38.86278107499993, -6.438284015999955],
            [-38.862722951999956, -6.443333103999976],
            [-38.862627773999975, -6.45056666499994],
            [-38.862526646999981, -6.456098911999959],
            [-38.862425519999931, -6.463968978999958],
            [-38.862389827999948, -6.469572609999943],
            [-38.862282751999942, -6.477966158999948],
            [-38.862223264999955, -6.484795211999938],
            [-38.862122137999961, -6.493658703999927],
            [-38.860474361999934, -6.498423574999947],
            [-38.860444617999974, -6.503259828999944],
            [-38.860474361999934, -6.510987128999943],
            [-38.860420823999959, -6.517631773999938],
            [-38.860455976999958, -6.526245249999931],
            [-38.860439482999936, -6.533888820999948],
            [-38.860424665999972, -6.537218801999927],
            [-38.859710819999975, -6.541080794999971],
            [-38.858762714999955, -6.546032644999968],
            [-38.857768618999955, -6.551155952999977],
            [-38.857054467999944, -6.554669414999978],
            [-38.855572671999937, -6.561973028999944],
            [-38.854725534999943, -6.566301639999949],
            [-38.853532283999982, -6.57264491199993],
            [-38.852225000999965, -6.579171112999973],
            [-38.851676304999955, -6.58194860499998],
            [-38.852300912999965, -6.589313035999965],
            [-38.853177530999972, -6.599533697999959],
            [-38.85230237299993, -6.605446995999955],
            [-38.851899336999963, -6.608474470999965],
            [-38.851146650999965, -6.614112793999936],
            [-38.850467912999932, -6.618980289999968],
            [-38.849338483999929, -6.626926365999964],
            [-38.847863217999929, -6.637318661999927],
            [-38.846744871999931, -6.645260112999949],
            [-38.845442116999948, -6.654218783999966],
            [-38.84469258699994, -6.659340573999941],
            [-38.844198847999962, -6.662856226999963],
            [-38.843080501999964, -6.670309888999952],
            [-38.841516005999949, -6.681380330999957],
            [-38.840421453999966, -6.688786403999927],
            [-38.839029468999968, -6.698601679999967],
            [-38.838428654999973, -6.702646763999951],
            [-38.837369794999972, -6.709916016999955],
            [-38.835329406999961, -6.724282012999936],
            [-38.83340799299998, -6.737125153999955],
            [-38.831605550999939, -6.749022458999946],
            [-38.830612125999949, -6.755684949999932],
            [-38.829416446999971, -6.763894089999951],
            [-38.828143434999959, -6.772299535999935],
            [-38.82541895199995, -6.790436977999946],
            [-38.824169734999941, -6.798568785999976],
            [-38.820648132999963, -6.822214679999945],
            [-38.819690399999956, -6.828912861999981],
            [-38.818542309999941, -6.836330831999931],
            [-38.817334733999985, -6.844617304999929],
            [-38.815139680999948, -6.859762573999944],
            [-38.816650638999931, -6.873325501999943],
            [-38.817310938999981, -6.878780415999927],
            [-38.820523210999966, -6.906275087999973],
            [-38.821588019999979, -6.915281347999951],
            [-38.822593341999948, -6.923657050999964],
            [-38.823955583999975, -6.935375895999925],
            [-38.824758651999957, -6.942544022999925],
            [-38.825621206999983, -6.949402818999943],
            [-38.826572990999978, -6.957427550999967],
            [-38.829023835999976, -6.978295423999953],
            [-38.829725776999965, -6.983803876999957],
            [-38.830915506999929, -6.990448520999962],
            [-38.832474053999931, -6.999091913999962],
            [-38.833324711999978, -7.003714016999936],
            [-38.834228906999954, -7.008794165999973],
            [-38.836755893999964, -7.022660474999952],
            [-38.838697534999937, -7.033334736999961],
            [-38.840177558999983, -7.041653332999942],
            [-38.842109681999943, -7.052199103999953],
            [-38.845236292999971, -7.069412124999928],
            [-38.846625898999946, -7.077269104999971],
            [-38.847787075999975, -7.083607988999972],
            [-38.849248064999983, -7.09161725499996],
            [-38.851437168999951, -7.103828648999979],
            [-38.853483504999929, -7.11505494599993],
            [-38.854730342999972, -7.12206959699995],
            [-38.855472734999978, -7.126100403999942],
            [-38.856143742999961, -7.129893263999975],
            [-38.859189452999942, -7.135761014999957],
            [-38.863991204999934, -7.145121814999925],
            [-38.869078492999961, -7.154939470999977],
            [-38.875588697999945, -7.167602961999933],
            [-38.87926734499996, -7.174727068999971],
            [-38.884616372999972, -7.185196696999981],
            [-38.888642420999929, -7.192939462999959],
            [-38.890179552999939, -7.195947101999934],
            [-38.894633903999932, -7.204636893999975],
            [-38.898693263999974, -7.212384418999932],
            [-38.904475354999931, -7.223596438999948],
            [-38.911754698999971, -7.23762166399996],
            [-38.916610605999949, -7.247019852999927],
            [-38.920250702999965, -7.254001169999981],
            [-38.925143352999953, -7.263442892999933],
            [-38.928156989999934, -7.269236840999952],
            [-38.932219869999983, -7.277072445999977],
            [-38.93479920599998, -7.281964616999971],
            [-38.93831935999998, -7.288821695999957],
            [-38.940148233999935, -7.292305754999973],
            [-38.940276724999933, -7.29461859099996],
            [-38.940809723999962, -7.303013328999953],
            [-38.940975397999978, -7.305812338999942],
            [-38.941237138999952, -7.306373891999954],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Paulo Afonso III - UHE Paulo Afonso II C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 0.71755,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.00642,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.196304686999952, -9.40079110299996],
            [-38.197055503999934, -9.400271083999939],
            [-38.198454454999933, -9.399411718999943],
            [-38.20140319199993, -9.396906436999927],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Paulo Afonso III - UHE Paulo Afonso II C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 0.73351,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.00658,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.196378505999974, -9.400915391999945],
            [-38.197097096999983, -9.400389117999964],
            [-38.198614279999958, -9.39984640299997],
            [-38.201699074999965, -9.397185278999928],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Paulo Afonso III - UHE Paulo Afonso I C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 0.77085,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.0069,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.196545223999976, -9.401624475999938],
            [-38.197469300999956, -9.400951539999937],
            [-38.199330018999945, -9.39993661099993],
            [-38.202002987999947, -9.397468040999968],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Chapada II - Picos C1',
        Concession: 'VSJIX - VENTOS DE SANTA JOANA IX ENERGIAS RENOVÁVEIS S.A.',
        Tensao: 230.0,
        Extensao: 85.1535,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.76613,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-41.414302930999952, -7.111043604999963],
            [-41.415073875999951, -7.111557568999956],
            [-41.417901532999963, -7.119459758999938],
            [-41.413694532999955, -7.125970582999969],
            [-41.391525094999963, -7.133765696999944],
            [-41.379982328999972, -7.135323053999969],
            [-41.366826401999958, -7.147192376999953],
            [-41.349309886999947, -7.159691113999941],
            [-41.32915203899995, -7.174023937999948],
            [-41.285213223999961, -7.204998635999971],
            [-41.216976011999975, -7.254395637999949],
            [-41.108795270999963, -7.332460184999945],
            [-40.855589389999977, -7.435707376999972],
            [-40.783246636999934, -7.465199606999931],
            [-40.76576632299998, -7.474734503999969],
            [-40.758842090999963, -7.476090795999937],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Curral Novo do Piauí II - São Basílio C1',
        Concession: 'EOL BRITE - Central Eólica Brite Ltda',
        Tensao: 230.0,
        Extensao: 87.56826,
        Ano_Opera: 2016.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.77719,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.639071021999939, -8.03198350699995],
            [-40.639137557999959, -8.031366412999944],
            [-40.639432262999946, -8.029579300999956],
            [-40.636972640999943, -8.027132546999951],
            [-40.636378718999936, -8.022713279999948],
            [-40.635787143999949, -8.018253582999932],
            [-40.635192400999983, -8.013775686999963],
            [-40.634578823999959, -8.009358536999969],
            [-40.633954887999948, -8.00489190199994],
            [-40.633343260999936, -8.000463543999956],
            [-40.632143305999932, -7.991698210999971],
            [-40.631043560999956, -7.983533580999961],
            [-40.628712834999931, -7.979264348999948],
            [-40.626354373999959, -7.97499849899998],
            [-40.624236877999977, -7.971159523999972],
            [-40.597280638999962, -7.921828791999928],
            [-40.613143315999935, -7.83429198999994],
            [-40.665697518999934, -7.809054950999951],
            [-40.683686244999933, -7.778088645999958],
            [-40.684171654999943, -7.774819265999952],
            [-40.687655185999972, -7.723394354999925],
            [-40.689711039999963, -7.713971688999948],
            [-40.715340251999976, -7.59682657299993],
            [-40.715844697999955, -7.58420591099997],
            [-40.716273000999934, -7.566274292999935],
            [-40.714198110999973, -7.55934530299993],
            [-40.718262230999983, -7.555176486999926],
            [-40.719594728999937, -7.548038103999943],
            [-40.720363007999936, -7.541107652999926],
            [-40.721772903999977, -7.529156081999929],
            [-40.722777099999973, -7.520656129999963],
            [-40.723821849999979, -7.511670058999925],
            [-40.724848514999962, -7.503094888999954],
            [-40.72637143399993, -7.490559843999961],
            [-40.722984588999964, -7.483214033999957],
            [-40.719470996999974, -7.475159971999972],
            [-40.715916838999931, -7.467451838999978],
            [-40.712722844999973, -7.460131296999975],
            [-40.705609507999952, -7.456971805999956],
            [-40.697752332999983, -7.453455074999965],
            [-40.691145892999941, -7.450375506999933],
            [-40.687043701999983, -7.447605813999928],
            [-40.683866139999964, -7.447155675999966],
            [-40.673404631999972, -7.442380517999936],
            [-40.665533427999947, -7.43888858899993],
            [-40.65771729599993, -7.435400590999961],
            [-40.649794559999975, -7.43182965799997],
            [-40.639083811999967, -7.42699435399993],
            [-40.629043012999944, -7.422461208999948],
            [-40.625330001999941, -7.420679832999951],
            [-40.60927339899996, -7.413446271999931],
            [-40.597042968999972, -7.407906886999967],
            [-40.581547918999945, -7.400987413999928],
            [-40.58067706199995, -7.398863009999957],
            [-40.580545559999962, -7.397907604999943],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Curral Novo do Piauí II - São Basílio C2',
        Concession: 'EOL BRITE - Central Eólica Brite Ltda',
        Tensao: 230.0,
        Extensao: 87.5673,
        Ano_Opera: 2016.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.77718,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.638927339999952, -8.031948260999968],
            [-40.639044703999957, -8.031350423999982],
            [-40.639333028999943, -8.029615941999964],
            [-40.636870801999976, -8.02718349099996],
            [-40.636277007999979, -8.022723118999977],
            [-40.635090048999984, -8.013804873999959],
            [-40.633235732999935, -8.000478518999955],
            [-40.632052521999981, -7.991691157999981],
            [-40.630944215999932, -7.983556600999975],
            [-40.626275672999952, -7.975029856999981],
            [-40.624163568999961, -7.971194981999929],
            [-40.597090281999954, -7.921834739999952],
            [-40.612957717999961, -7.834177775999933],
            [-40.665626135999958, -7.80889790699996],
            [-40.68350064699996, -7.778017261999935],
            [-40.68398605699997, -7.774719328999936],
            [-40.687483863999944, -7.723322970999959],
            [-40.689496887999951, -7.713943135999955],
            [-40.715159412999981, -7.596817054999974],
            [-40.715673376999973, -7.584186875999933],
            [-40.716082643999982, -7.566321882999944],
            [-40.714026789999934, -7.559269159999928],
            [-40.718138498999963, -7.555090826999958],
            [-40.719423407999955, -7.548047621999956],
            [-40.720144640999933, -7.54110325399995],
            [-40.722153807999973, -7.524878921999971],
            [-40.724187211999947, -7.507596723999939],
            [-40.726209629999971, -7.490597914999967],
            [-40.721090512999979, -7.479271632999939],
            [-40.719318710999971, -7.47522659699996],
            [-40.715784286999963, -7.467495510999981],
            [-40.712608630999966, -7.460245510999926],
            [-40.705525466999973, -7.457097775999955],
            [-40.697674966999955, -7.453595399999926],
            [-40.691079267999953, -7.450508755999977],
            [-40.686996112999964, -7.447767616999954],
            [-40.683795959999941, -7.447293023999976],
            [-40.680599344999962, -7.445866439999975],
            [-40.673309452999945, -7.442523284999936],
            [-40.665467760999945, -7.439038292999953],
            [-40.657641364999961, -7.435509863999926],
            [-40.653928322999946, -7.433843288999981],
            [-40.642007082999953, -7.428475910999964],
            [-40.62900442199998, -7.422578232999967],
            [-40.625263376999953, -7.420860671999947],
            [-40.612976813999978, -7.415364899999929],
            [-40.609206773999972, -7.413627110999926],
            [-40.600877752999963, -7.409903444999941],
            [-40.596938272999978, -7.408078207999949],
            [-40.589044676999947, -7.404561164999961],
            [-40.581416012999966, -7.401099631999955],
            [-40.580571156999952, -7.398895588999949],
            [-40.580413863999979, -7.397961761999966],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Chapada III - Curral Novo do Piauí C1',
        Concession:
          'VENTOS DE ST JOANA I - VENTOS DE SANTA JOANA I ENERGIAS RENOVAVEIS S.A',
        Tensao: 230.0,
        Extensao: 79.51745,
        Ano_Opera: 2016.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.70321,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.639355218999981, -8.032014269999934],
            [-40.639418765999949, -8.031477175999953],
            [-40.639779780999959, -8.029458361999957],
            [-40.637305870999967, -8.026987326999972],
            [-40.636609636999935, -8.021901629999945],
            [-40.635282686999972, -8.012051468999971],
            [-40.634143821999942, -8.003392613999949],
            [-40.633033645999944, -7.995264540999926],
            [-40.632467215999952, -7.991330190999975],
            [-40.631369065999934, -7.983390188999977],
            [-40.628355277999958, -7.97783090799993],
            [-40.628348768999956, -7.977833808999947],
            [-40.626893192999944, -7.975171115999956],
            [-40.624716784999976, -7.971319495999978],
            [-40.597665675999963, -7.921717670999953],
            [-40.613457364999931, -7.834579032999955],
            [-40.666005857999949, -7.809331047999933],
            [-40.684049706999986, -7.778189454999961],
            [-40.684559307999962, -7.774698785999931],
            [-40.688021423999942, -7.723389278999946],
            [-40.71578759099998, -7.596579108999947],
            [-40.716225411999972, -7.58343496599997],
            [-40.716606125999931, -7.566321882999944],
            [-40.719832674999964, -7.548161835999963],
            [-40.720270495999955, -7.544297590999975],
            [-40.720822530999953, -7.539729025999975],
            [-40.721300558999985, -7.535063294999929],
            [-40.722478635999948, -7.525785384999949],
            [-40.72294278399994, -7.521455052999954],
            [-40.723931482999944, -7.51318234799993],
            [-40.724991459999956, -7.504182635999939],
            [-40.725607458999946, -7.499161113999946],
            [-40.726666486999932, -7.490540807999935],
            [-40.725276881999946, -7.487437990999979],
            [-40.721412636999958, -7.478795788999946],
            [-40.717786337999939, -7.470734174999961],
            [-40.714102932999936, -7.462491721999982],
            [-40.712197934999949, -7.458482581999931],
            [-40.710345856999936, -7.454319894999969],
            [-40.706722755999976, -7.446241358999941],
            [-40.702982983999959, -7.437952251999945],
            [-40.699168124999971, -7.429424064999978],
            [-40.695395313999938, -7.420982523999953],
            [-40.691570931999934, -7.412514280999972],
            [-40.689850555999953, -7.408625235999978],
            [-40.687386450999952, -7.400941670999941],
            [-40.685396427999933, -7.394715089999977],
            [-40.684495634999962, -7.392049155999928],
            [-40.683471424999937, -7.391342842999961],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Chapada I - Curral Novo do Piauí C1',
        Concession:
          'VSJ II - VENTOS DE SANTA JOANA II ENERGIAS RENOVAVEIS S.A.',
        Tensao: 230.0,
        Extensao: 58.99717,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.52194,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.639225904999932, -8.031978698999978],
            [-40.639340174999973, -8.03147264699993],
            [-40.639660504999938, -8.02949128399996],
            [-40.637245447999931, -8.027027730999976],
            [-40.635817712999938, -8.016694265999945],
            [-40.634601096999972, -8.007596028999956],
            [-40.633477257999971, -7.999278966999952],
            [-40.631259869999951, -7.983443789999967],
            [-40.629940236999971, -7.980957359999934],
            [-40.626803633999941, -7.975203374999978],
            [-40.623850864999952, -7.969963104999977],
            [-40.597484836999968, -7.921736705999933],
            [-40.613314596999942, -7.834474335999971],
            [-40.665853571999946, -7.809216833999926],
            [-40.683871247999946, -7.778153762999978],
            [-40.684383227999945, -7.774646437999934],
            [-40.687840584999947, -7.723379760999933],
            [-40.715625787999954, -7.596579108999947],
            [-40.716054090999933, -7.58343496599997],
            [-40.716453839999986, -7.566321882999944],
            [-40.716301554999973, -7.565236847999927],
            [-40.715806626999949, -7.562647994999963],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Araripe III - Curral Novo do Piauí, C1',
        Concession:
          'V SANTO AUGUSTO I - VENTOS DE SANTO AUGUSTO I ENERGIA RENOVÁVEIS S.A.',
        Tensao: 230.0,
        Extensao: 34.84126,
        Ano_Opera: 2016.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.30898,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.638792579999972, -8.031906459999959],
            [-40.638899076999962, -8.030877256999929],
            [-40.638882524999985, -8.029740337999954],
            [-40.636243480999951, -8.027094380999927],
            [-40.633831752999981, -8.016827575999969],
            [-40.631311516999972, -8.006115343999966],
            [-40.628813925999964, -7.995519549999926],
            [-40.626556421999965, -7.985953067999958],
            [-40.625708631999942, -7.982348410999975],
            [-40.622756072999948, -7.976219169999979],
            [-40.620443314999932, -7.971436650999976],
            [-40.615223436999941, -7.960621008999965],
            [-40.612910600999953, -7.955824015999951],
            [-40.610833330999981, -7.951540985999941],
            [-40.600875286999951, -7.930932473999974],
            [-40.59896219999996, -7.92691356399996],
            [-40.596692193999957, -7.922252199999946],
            [-40.602629943999943, -7.889138597999931],
            [-40.612651238999945, -7.833978955999953],
            [-40.665368161999936, -7.808636244999946],
            [-40.680797281999958, -7.781900705999931],
            [-40.67967330099998, -7.77912674099997],
            [-40.676291474999971, -7.771531722999953],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Pedra Branca - Sobradinho C1',
        Concession: 'PEDRA BRANCA - PEDRA BRANCA SA',
        Tensao: 230.0,
        Extensao: 58.12721,
        Ano_Opera: 2013.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.51771,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.829720975999976, -9.437181884999973],
            [-40.829724776999967, -9.437564860999942],
            [-40.829470971999967, -9.438578758999938],
            [-40.829697427999974, -9.44240373699995],
            [-40.833051930999943, -9.450359791999972],
            [-40.835907437999936, -9.457137393999972],
            [-40.838873479999961, -9.464207086999977],
            [-40.841521010999941, -9.470485373999963],
            [-40.844262508999975, -9.476955547999978],
            [-40.848988264999946, -9.48228891399998],
            [-40.855318399999931, -9.489437038999938],
            [-40.861694753999984, -9.496646919999932],
            [-40.867696860999956, -9.503421371999934],
            [-40.869975118999946, -9.517698573999951],
            [-40.873086098999977, -9.53719676399993],
            [-40.875388996999959, -9.551558446999934],
            [-40.883299867999938, -9.568111353999939],
            [-40.889585325999974, -9.581403457999954],
            [-40.893454077999934, -9.589490327999954],
            [-40.899668468999948, -9.602279929999952],
            [-40.906048633999944, -9.61557318499996],
            [-40.910409698999956, -9.624668614999962],
            [-40.918854435999947, -9.642321016999972],
            [-40.929647351999961, -9.664817267999979],
            [-40.948594662999938, -9.704348396999933],
            [-40.968175629999962, -9.715718076999963],
            [-40.993663507999941, -9.730572593999966],
            [-41.002182019999964, -9.73553536999998],
            [-41.042926536999971, -9.759236149999936],
            [-41.055188950999934, -9.766328348999934],
            [-41.059507672999985, -9.768850577999956],
            [-41.063076863999981, -9.778761032999967],
            [-41.066527082999983, -9.788255081999978],
            [-41.070001095999942, -9.797737234999943],
            [-41.073249059999966, -9.806672110999955],
            [-41.076009234999958, -9.814322077999975],
            [-41.078906838999956, -9.822368515999926],
            [-41.082299553999974, -9.831731695999963],
            [-41.085273880999978, -9.83985755499998],
            [-41.087094167999965, -9.847864440999956],
            [-41.089128606999964, -9.856680343999926],
            [-41.089380829999982, -9.856896874999961],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Rio Largo II - Braskem C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 24.03307,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.20976,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.837931581999953, -9.503811067999948],
            [-35.838041890999932, -9.504324154999949],
            [-35.838409573999968, -9.506045116999928],
            [-35.838483090999944, -9.50875784699997],
            [-35.838737485999957, -9.517740114999981],
            [-35.841119187999936, -9.522450495999976],
            [-35.843376331999934, -9.526927247999936],
            [-35.844600742999944, -9.529403767999952],
            [-35.843050561999974, -9.532964748999973],
            [-35.842937759999984, -9.535339265999937],
            [-35.842257101999962, -9.539367839999954],
            [-35.840940480999961, -9.545858395999971],
            [-35.839812059999929, -9.551608976999944],
            [-35.838476282999977, -9.558274001999962],
            [-35.837907632999929, -9.561421805999942],
            [-35.837393912999971, -9.564199895999934],
            [-35.836286087999952, -9.568795113999954],
            [-35.835408598999948, -9.573286533999976],
            [-35.834783764999941, -9.576402584999926],
            [-35.83330101699994, -9.583616642999971],
            [-35.831098836999956, -9.594508679999933],
            [-35.829996380999944, -9.599966692999942],
            [-35.828668659999948, -9.606633944999942],
            [-35.826523960999964, -9.617213355999979],
            [-35.825894694999931, -9.62040499799997],
            [-35.823901184999954, -9.62246700299994],
            [-35.817505489999974, -9.629219067999941],
            [-35.81400750499995, -9.632944148999968],
            [-35.810125077999942, -9.637040863999971],
            [-35.808391325999935, -9.638842881999949],
            [-35.807107172999963, -9.641961290999973],
            [-35.803763530999959, -9.649794639999982],
            [-35.800561060999939, -9.657428427999946],
            [-35.798429384999963, -9.662279692999959],
            [-35.797101485999974, -9.666912132999926],
            [-35.793465531999971, -9.66862657799993],
            [-35.784877711999968, -9.672663691999958],
            [-35.774848580999958, -9.677301712999963],
            [-35.768560591999972, -9.680254968999975],
            [-35.767192820999981, -9.680910350999966],
            [-35.766694705999953, -9.68173364699993],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Bongi - Açonorte, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 6.02414,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-04-26',
        Shape_Leng: 0.0542,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.97606957499994, -8.074930067999958],
            [-34.975935135999976, -8.075720015999934],
            [-34.976728721999962, -8.076714839999966],
            [-34.975517015999969, -8.078018034999957],
            [-34.969360716999972, -8.076155476999929],
            [-34.964871047999964, -8.074801222999952],
            [-34.959561586999939, -8.073184694999952],
            [-34.951948931999937, -8.070886470999938],
            [-34.947435256999938, -8.069531070999972],
            [-34.944234624999979, -8.068188471999974],
            [-34.939116128999956, -8.066036770999972],
            [-34.932573073999947, -8.063296185999945],
            [-34.930865327999982, -8.062576193999973],
            [-34.929316823999955, -8.063200888999972],
            [-34.927956766999955, -8.06306058399997],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Chapadinha I - Curral Novo do Piauí II C1',
        Concession:
          'V SANTA ALBERTINA - VENTOS DE SANTA ALBERTINA ENERGIAS RENOVÁVEIS S.A.',
        Tensao: 230.0,
        Extensao: 3.81562,
        Ano_Opera: 2017.0,
        created_us: null,
        created_da: null,
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2022-05-12',
        Shape_Leng: 0.03383,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.638479942999936, -8.031873695999934],
            [-40.638635367999939, -8.031075084999941],
            [-40.637369493999984, -8.029047783999943],
            [-40.634152462999964, -8.028067445999966],
            [-40.628359744999955, -8.006009366999933],
            [-40.62546631999993, -8.004086761999929],
            [-40.625261467999962, -8.003130149999947],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Casa Nova II - Sobradinho C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 65.98098,
        Ano_Opera: 2017.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.58869,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.817137832999947, -9.418022131999976],
            [-40.815424620999977, -9.415071599999976],
            [-40.785911372999976, -9.381651276999946],
            [-40.777663047999965, -9.313379921999967],
            [-40.843458989999931, -9.174137205999955],
            [-40.862169930999983, -9.156903463999981],
            [-40.981559896999954, -9.143144266999968],
            [-41.000043350999931, -9.147894761999964],
            [-41.011887717999969, -9.161015556999928],
            [-41.045602186999929, -9.231723124999974],
            [-41.028974830999971, -9.285550539999974],
            [-41.02833237599998, -9.286128748999943],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Garanhuns II - Luiz Gonzaga C2',
        Concession: 'IEG - INTERLIGAÇÃO ELÉTRICA GARANHUNS',
        Tensao: 500.0,
        Extensao: 218.25512,
        Ano_Opera: 2015.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.97837,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.303631328999984, -9.144064495999942],
            [-38.302784920999954, -9.144526073999941],
            [-38.295933743999967, -9.145055102999947],
            [-38.287560176999932, -9.145673960999943],
            [-38.283903091999946, -9.145929961999968],
            [-38.248235116999979, -9.18995351999996],
            [-38.223434730999941, -9.201934367999968],
            [-38.125724029999958, -9.168045439999958],
            [-38.034242250999966, -9.136277549999932],
            [-37.946547953999982, -9.104475138999931],
            [-37.752363514999956, -9.066368728999976],
            [-37.683053255999937, -9.052021582999942],
            [-37.660582310999985, -9.046955767999975],
            [-37.614178428999935, -9.036476038999979],
            [-37.609737905999964, -9.035424187999979],
            [-37.578014137999958, -9.028095435999944],
            [-37.476153598999929, -9.004065447999949],
            [-37.466553633999979, -9.001564099999939],
            [-37.45671631099998, -8.999048571999936],
            [-37.441580777999945, -8.995131944999969],
            [-37.433277116999932, -8.99302490599996],
            [-37.408607995999944, -8.987448938999933],
            [-37.362928635999936, -8.977261169999963],
            [-37.341881452999985, -8.972572455999966],
            [-37.32305200299993, -8.968612425999936],
            [-37.295727090999947, -8.96326948899997],
            [-36.899556442999938, -8.881967948999943],
            [-36.634934844999975, -8.846124243999952],
            [-36.53970437199996, -8.849401396999951],
            [-36.516367655999943, -8.850063427999942],
            [-36.492942421999942, -8.847034303999976],
            [-36.46736466699997, -8.849672134999935],
            [-36.458805087999963, -8.850466076999965],
            [-36.406527903999972, -8.871211407999965],
            [-36.403742813999941, -8.880790968999975],
            [-36.403772855999932, -8.881199059999972],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Arcoverde II - Caetés II, C1',
        Concession: 'ARCOVERDE - ARCOVERDE TRANSMISSAO DE ENERGIA S.A.',
        Tensao: 230.0,
        Extensao: 49.16409,
        Ano_Opera: 2019.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.43277,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.759111767999968, -8.843929844999934],
            [-36.758749644999966, -8.844171605999975],
            [-36.757466348999969, -8.844063482999957],
            [-36.757038870999963, -8.843185652999978],
            [-36.757940924999957, -8.831174049999959],
            [-36.762121891999982, -8.81856695099998],
            [-36.766333628999973, -8.806978277999974],
            [-36.865383437999981, -8.638593266999976],
            [-36.882764177999945, -8.61352377299994],
            [-36.889134210999941, -8.608871143999977],
            [-36.89879814699998, -8.600399315999937],
            [-36.935904170999947, -8.569384305999961],
            [-36.986553915999934, -8.528398569999979],
            [-37.003669401999957, -8.501018773999931],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Garanhuns II - Arcoverde II, C1',
        Concession: 'ARCOVERDE - ARCOVERDE TRANSMISSAO DE ENERGIA S.A.',
        Tensao: 230.0,
        Extensao: 81.9027,
        Ano_Opera: 2019.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.73237,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.404372697999975, -8.884496210999941],
            [-36.404409716999965, -8.885213721999946],
            [-36.405444308999961, -8.886545852999973],
            [-36.408264243999952, -8.886211627999955],
            [-36.41580031899997, -8.876253396999971],
            [-36.41692464099998, -8.870350234999933],
            [-36.421236590999968, -8.864728803999981],
            [-36.438126448999981, -8.849880306999978],
            [-36.453748723999979, -8.834426228999973],
            [-36.466098951999982, -8.82919058899995],
            [-36.494362831999979, -8.801701154999932],
            [-36.516740063999976, -8.794683546999977],
            [-36.585467146999974, -8.753955175999977],
            [-36.616957303999982, -8.724384293999947],
            [-36.687586803999977, -8.687365588999967],
            [-36.787340904999951, -8.665684225999939],
            [-36.835096214999965, -8.642942845999926],
            [-36.877509531999976, -8.620481095999935],
            [-36.882494885999961, -8.61328371299993],
            [-36.888898467999979, -8.608593363999944],
            [-36.898569615999975, -8.600147490999973],
            [-36.935673924999946, -8.569113357999981],
            [-36.986290321999945, -8.528153594999935],
            [-37.003229438999938, -8.501038427999958],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Juazeiro III - Ourolândia II, C1',
        Concession: 'ETB - ETB EMPRESA DE TRANSMISSAO BAIANA S.A.',
        Tensao: 500.0,
        Extensao: 189.48264,
        Ano_Opera: 2020.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.68796,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.806569839947691, -10.310613660371555],
            [-40.751738930999977, -10.254077966999944],
            [-40.643515294999929, -10.136718904999952],
            [-40.611820132999981, -10.045103436999966],
            [-40.61286251699994, -9.956414792999965],
            [-40.568731165999964, -9.830924652999954],
            [-40.54152443199996, -9.594171858999971],
            [-40.523671422999939, -9.493654842999945],
            [-40.524197550999929, -9.488384676999942],
            [-40.523382456999968, -9.487153790999969],
            [-40.520660833999955, -9.486361805999934],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Jaboatão II - Pirapama II C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 34.84119,
        Ano_Opera: 2018.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.3092,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.028191025999945, -8.270574312999941],
            [-35.030162718999975, -8.268685427999969],
            [-35.033402485999943, -8.265483669999981],
            [-35.040147943999955, -8.264914327999975],
            [-35.04675104599994, -8.264340748999928],
            [-35.051097649999974, -8.263942516999975],
            [-35.053019485999982, -8.262457077999954],
            [-35.061362156999962, -8.25568586199995],
            [-35.069093279999947, -8.249360531999969],
            [-35.076314923999973, -8.243483970999932],
            [-35.08415797899994, -8.237033541999949],
            [-35.091802600999983, -8.230756263999979],
            [-35.101512914999944, -8.222798805999957],
            [-35.111118517999955, -8.215022318999956],
            [-35.119535577999955, -8.208203105999928],
            [-35.125744797999971, -8.203184048999958],
            [-35.127388270999973, -8.20162410699993],
            [-35.127464413999974, -8.201148213999943],
            [-35.124009435999938, -8.197836004999942],
            [-35.117864512999972, -8.191748402999963],
            [-35.10163397599996, -8.175887025999941],
            [-35.086950222999974, -8.161490494999953],
            [-35.078435637999974, -8.153077098999972],
            [-35.077687475999937, -8.152337749999958],
            [-35.067533522999952, -8.142404883999973],
            [-35.066698892999966, -8.142833691999954],
            [-35.064394729999947, -8.144254023999963],
            [-35.059033288999956, -8.147508817999949],
            [-35.054407181999977, -8.153454149999959],
            [-35.050832261999972, -8.158022504999963],
            [-35.046502520999979, -8.162597817999938],
            [-35.040119470999969, -8.16926775099995],
            [-35.035846490999973, -8.173723302999974],
            [-35.028683877999981, -8.181181316999925],
            [-35.026722406999966, -8.183258330999934],
            [-35.018158176999975, -8.186039981999954],
            [-35.011579716999961, -8.185759237999946],
            [-34.996475688999965, -8.184911350999926],
            [-34.988608238999973, -8.17838209599995],
            [-34.987166947999981, -8.17831519899994],
            [-34.986470416999964, -8.17826893299997],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Jaboatão II - Recife II C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 16.29206,
        Ano_Opera: 2018.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.14464,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.986468959999968, -8.178114592999975],
            [-34.987156365999965, -8.178225016999932],
            [-34.988636788999941, -8.178287098999931],
            [-34.996511516999931, -8.184813476999977],
            [-34.999595177999936, -8.185007786999961],
            [-35.008602608999979, -8.185450401999958],
            [-35.011605031999977, -8.185642672999961],
            [-35.018164161999948, -8.185897653999973],
            [-35.026648067999929, -8.183144854999966],
            [-35.028604195999947, -8.181115600999931],
            [-35.035699130999944, -8.173646100999974],
            [-35.040014179999957, -8.169178430999978],
            [-35.042431352999984, -8.166689890999976],
            [-35.046426096999937, -8.162522181999975],
            [-35.050746881999942, -8.157961329999978],
            [-35.054310096999984, -8.153377051999939],
            [-35.057445711999947, -8.149401395999973],
            [-35.05894838699993, -8.147427458999971],
            [-35.064338759999941, -8.144153797999934],
            [-35.066632718999983, -8.142767937999963],
            [-35.067417585999976, -8.142281945999969],
            [-35.061371582999982, -8.136218023999959],
            [-35.056698892999975, -8.131593330999976],
            [-35.050885708999942, -8.125814863999949],
            [-35.04394648799996, -8.118927048999979],
            [-35.040661876999934, -8.115646721999951],
            [-35.039107974999979, -8.114133700999957],
            [-35.036103216999948, -8.113601293999977],
            [-35.035811244999934, -8.11314256299994],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Campina Grande III - Pau Ferro, C1',
        Concession: 'RIALMA II - RIALMA TRANSMISSORA DE ENERGIA II S.A.',
        Tensao: 500.0,
        Extensao: 132.64805,
        Ano_Opera: 2020.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.18186,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.022147005999955, -7.854513216999976],
            [-35.025211750999972, -7.852286040999957],
            [-35.022940158999972, -7.832036827999957],
            [-35.029245730999946, -7.822994875999939],
            [-35.047937883999964, -7.796165461999976],
            [-35.063642326999968, -7.78521994099998],
            [-35.066170503999956, -7.783018939999977],
            [-35.068490478999934, -7.778170787999954],
            [-35.118809990999978, -7.762006930999974],
            [-35.130574046999982, -7.758218828999929],
            [-35.164783081999929, -7.743989651999925],
            [-35.173863104999953, -7.739801800999942],
            [-35.224695926999971, -7.71652551699998],
            [-35.272442190999982, -7.712290075999931],
            [-35.27632547199994, -7.711604791999946],
            [-35.277144005999958, -7.711262148999936],
            [-35.280418144999942, -7.709986757999957],
            [-35.290232739999965, -7.709322688999976],
            [-35.293373628999973, -7.708323315999962],
            [-35.30789853999994, -7.703900889999943],
            [-35.331336230999966, -7.700712411999973],
            [-35.368665214999965, -7.693766765999953],
            [-35.43406996799996, -7.685861203999934],
            [-35.451130703999979, -7.678699025999947],
            [-35.453153244999953, -7.677842419999934],
            [-35.466264075999959, -7.67232207099994],
            [-35.472849914999983, -7.669543851999947],
            [-35.510495976999948, -7.654837060999967],
            [-35.518419581999979, -7.652600366999934],
            [-35.526604927999983, -7.649221532999945],
            [-35.528770236999947, -7.648269747999962],
            [-35.53150661799998, -7.647175195999978],
            [-35.55681218899997, -7.620166965999942],
            [-35.561600853999948, -7.61528906999996],
            [-35.576145308999969, -7.600685128999942],
            [-35.59159705299993, -7.590147209999941],
            [-35.600472442999944, -7.582104630999936],
            [-35.650529639999945, -7.53693199199995],
            [-35.682081292999953, -7.507569442999966],
            [-35.709927763999929, -7.484136077999949],
            [-35.72734541899996, -7.469050295999978],
            [-35.747306240999933, -7.451566967999952],
            [-35.76118325799996, -7.439460269999927],
            [-35.781170729999928, -7.423470291999934],
            [-35.793302580999978, -7.41155910699996],
            [-35.838615573999959, -7.371925465999936],
            [-35.860696971999971, -7.352604242999973],
            [-35.903407845999936, -7.325918587999979],
            [-35.935017795999954, -7.285900811999966],
            [-35.94206694899998, -7.276888603999964],
            [-35.95396425399997, -7.269898936999937],
            [-35.969567569999981, -7.267117743999961],
            [-35.980779589999941, -7.256286436999972],
            [-35.980893803999948, -7.25558211699996],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Juazeiro III - Luiz Gonzaga, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 253.36116,
        Ano_Opera: 2019.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 2.30123,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.304600396999945, -9.145564263999972],
            [-38.301922030999947, -9.147080739999979],
            [-38.301339408999979, -9.149378602999946],
            [-38.301848946999939, -9.150594326999965],
            [-38.302531697999939, -9.151908714999934],
            [-38.306057816999953, -9.157824436999931],
            [-38.311337401999936, -9.166961312999945],
            [-38.318415670999968, -9.179067951999968],
            [-38.323742076999963, -9.188089640999976],
            [-38.325631529999953, -9.191354912999941],
            [-38.330568530999983, -9.199787330999982],
            [-38.334965185999977, -9.207334227999979],
            [-38.338947702999974, -9.214286150999953],
            [-38.340693603999966, -9.217257262999965],
            [-38.349143467999966, -9.218767592999939],
            [-38.363931737999962, -9.221564529999966],
            [-38.38847764999997, -9.226069697999947],
            [-38.428208441999971, -9.233509057999981],
            [-38.450068023999961, -9.237533453999959],
            [-38.467247911999948, -9.240719638999963],
            [-38.476476352999953, -9.242474856999934],
            [-38.501651804999938, -9.247077369999943],
            [-38.540827336999939, -9.25439486199997],
            [-38.56507404499996, -9.258903940999971],
            [-38.574960704999967, -9.260700433999943],
            [-38.599147925999944, -9.265221409999981],
            [-38.639075281999965, -9.272514457999932],
            [-38.666462877999948, -9.277582709999933],
            [-38.696116551999978, -9.282984262999946],
            [-38.732291571999951, -9.289659579999977],
            [-38.770768111999985, -9.296741432999966],
            [-38.927243499999975, -9.325286665999954],
            [-38.99492186699996, -9.337536606999947],
            [-39.421122061999938, -9.414978589999976],
            [-39.611941815999955, -9.449565395999969],
            [-39.801556067999968, -9.484290682999927],
            [-40.252893450999977, -9.480364924999947],
            [-40.256612321999967, -9.481401180999967],
            [-40.266657512999984, -9.479194889999974],
            [-40.294275163999941, -9.47305702899996],
            [-40.31912602999995, -9.467711271999974],
            [-40.348186098999975, -9.461284665999926],
            [-40.368928904999962, -9.456704693999939],
            [-40.39282987699994, -9.451477427999976],
            [-40.415115065999942, -9.464956005999966],
            [-40.428108364999957, -9.472703354999965],
            [-40.44252350599993, -9.481376405999981],
            [-40.470504105999964, -9.479359572999954],
            [-40.481865592999952, -9.478573974999961],
            [-40.485768717999974, -9.478269261999969],
            [-40.494961412999942, -9.480937691999941],
            [-40.499241101999985, -9.48213008099998],
            [-40.50881549199994, -9.48480645099994],
            [-40.519825236999964, -9.487896604999946],
            [-40.52045672099996, -9.487379071999953],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Nossa Senhora do Socorro - Penedo, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 112.35932,
        Ano_Opera: 2019.0,
        created_us: null,
        created_da: null,
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-03-12',
        Shape_Leng: 1.00108,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.63927697467517, -10.310613660371555],
            [-36.639172002999942, -10.310373629999958],
            [-36.634968116999971, -10.300501657999973],
            [-36.630838369999935, -10.291027671999927],
            [-36.626532628999939, -10.281228362999968],
            [-36.622704011999929, -10.272265053999945],
            [-36.620501715999978, -10.26744249099994],
            [-36.614399632999948, -10.26171412399998],
            [-36.607607313999949, -10.257997553999928],
            [-36.601035379999985, -10.254447286999948],
            [-36.596247005999942, -10.251824785999929],
            [-36.590674110999942, -10.252168561999952],
            [-36.586567722999973, -10.252191975999949],
            [-36.575099406999982, -10.252363763999938],
            [-36.566381536999984, -10.252485279999973],
            [-36.55680332999998, -10.246550132999971],
            [-36.556547660999968, -10.243719964999968],
            [-36.558669995999935, -10.242145585999936],
            [-36.561939084999949, -10.242935027999977],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Messias - Maceió II, C2 (CD)',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 20.12297,
        Ano_Opera: 2019.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.17747,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.750567266999951, -9.528639686999952],
            [-35.750859870999932, -9.528031111999951],
            [-35.751307887999928, -9.526903825999966],
            [-35.750157269999931, -9.522516516999929],
            [-35.747942727999941, -9.51391125899994],
            [-35.747141574999944, -9.510800482999969],
            [-35.751734137999961, -9.506621369999948],
            [-35.756740653999941, -9.502147992999937],
            [-35.764337161999947, -9.500635332999934],
            [-35.768321488999959, -9.499867036999945],
            [-35.773586383999941, -9.494117494999955],
            [-35.787780418999944, -9.478853031999961],
            [-35.790010285999983, -9.476505355999961],
            [-35.791755649999971, -9.470273979999945],
            [-35.79460354899993, -9.460140287999934],
            [-35.796473158999959, -9.453523317999952],
            [-35.803631308999968, -9.447728254999959],
            [-35.821745165999971, -9.437599644999977],
            [-35.824420764999957, -9.436027404999948],
            [-35.828556730999935, -9.433963644999949],
            [-35.830153024999959, -9.428944718999958],
            [-35.829264642999931, -9.425551413999926],
            [-35.829947072999971, -9.421602689999929],
            [-35.827426782999964, -9.418098854999926],
            [-35.82817231599995, -9.410407],
            [-35.832996063999929, -9.404754146999949],
            [-35.840584240999931, -9.404799745999981],
            [-35.841392903999974, -9.404333525999959],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Messias - Maceió II, C1 (CD)',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 20.13128,
        Ano_Opera: 2019.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.17755,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.750436148999938, -9.528573394999967],
            [-35.750774803999946, -9.527993955999932],
            [-35.751197717999958, -9.526898113999948],
            [-35.748776665999969, -9.517576004999967],
            [-35.747009865999985, -9.51076184599998],
            [-35.756679919999954, -9.502060861999951],
            [-35.76825895199994, -9.499776001999976],
            [-35.776139402999945, -9.491249061999952],
            [-35.787726481999982, -9.478799991999949],
            [-35.78996224499997, -9.476474098999972],
            [-35.794525575999955, -9.460112039999956],
            [-35.796399773999951, -9.453463327999941],
            [-35.803573600999982, -9.447655439999949],
            [-35.810573012999953, -9.443702302999952],
            [-35.821623594999949, -9.437504239999953],
            [-35.824380743999939, -9.435949924999932],
            [-35.828502798999978, -9.433880009999939],
            [-35.83005663299997, -9.428950008999948],
            [-35.829157208999959, -9.425553299999933],
            [-35.829830624999943, -9.421622204999949],
            [-35.827294004999942, -9.418125113999963],
            [-35.82807371499996, -9.410358803999941],
            [-35.832962013999975, -9.404664826999976],
            [-35.840552245999959, -9.404704526999978],
            [-35.841295393999928, -9.404248584999948],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Pau Ferro - Goianinha C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 43.3424,
        Ano_Opera: 2019.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.3789,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.020061792999968, -7.859114588999944],
            [-35.019469306999952, -7.858022415999926],
            [-35.018387841999981, -7.855462115999956],
            [-35.014919777999978, -7.854123668999932],
            [-34.984923815999935, -7.845736544999966],
            [-34.962467462999939, -7.855061557999932],
            [-34.959290881999948, -7.855789672999947],
            [-34.958508586999983, -7.855385799999965],
            [-34.957555323999941, -7.842767459999948],
            [-34.956410802999983, -7.829455565999979],
            [-34.95593845999997, -7.822978751999926],
            [-34.958643097999982, -7.816492631999949],
            [-34.961, -7.81463888899998],
            [-35.009030903999928, -7.776040353999974],
            [-35.015448754999966, -7.77097549399997],
            [-35.022706036999978, -7.75751190699998],
            [-35.039444443999969, -7.727027777999979],
            [-35.063070188999973, -7.683879367999964],
            [-35.071072513999979, -7.669365250999931],
            [-35.07360663999998, -7.661435696999945],
            [-35.07628353399997, -7.65310758399994],
            [-35.079489857999931, -7.643072206999932],
            [-35.081673012999943, -7.636344280999936],
            [-35.083802630999969, -7.629765070999952],
            [-35.086259423999934, -7.622061565999957],
            [-35.088305555999966, -7.615527777999944],
            [-35.088444443999947, -7.611611110999945],
            [-35.088810588999934, -7.595917731999975],
            [-35.088816537999946, -7.59315160899996],
            [-35.08875, -7.591111110999975],
            [-35.088998077999975, -7.589314144999946],
            [-35.088958723999951, -7.588689030999944],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Mirueira II - Pau Ferro, C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 23.96716,
        Ano_Opera: 2019.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.21142,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.891466840999954, -7.956129172999965],
            [-34.891188678999981, -7.956111542999963],
            [-34.890528351999933, -7.954833932999975],
            [-34.89164544199997, -7.954225662999932],
            [-34.89180552199997, -7.954458998999939],
            [-34.893014291999975, -7.953693692999934],
            [-34.895660043999953, -7.954099034999956],
            [-34.899086486999977, -7.954698673999928],
            [-34.902744079999934, -7.955320833999963],
            [-34.905237684999975, -7.954905814999961],
            [-34.910492842999929, -7.954051664999952],
            [-34.913314883999931, -7.95361384399996],
            [-34.91990698099994, -7.952547804999938],
            [-34.924146189999931, -7.949882848999948],
            [-34.931464782999967, -7.948585654999931],
            [-34.933470668999973, -7.946294233999936],
            [-34.944613684999979, -7.933573635999949],
            [-34.945456013999944, -7.925421601999972],
            [-34.946212682999942, -7.91766931799998],
            [-34.94701218199998, -7.910345336999967],
            [-34.948639732999936, -7.904299126999945],
            [-34.951116751999962, -7.895711651999932],
            [-34.955299843999967, -7.880849538999939],
            [-34.956770350999932, -7.875866946999963],
            [-34.958590638999965, -7.86921397499998],
            [-34.959297088999961, -7.863982734999979],
            [-34.958790263999958, -7.859114357999943],
            [-34.958519107999962, -7.85552506099998],
            [-34.959278405999953, -7.855916394999952],
            [-34.962451416999954, -7.855166864999944],
            [-34.984917970999959, -7.845817565999937],
            [-35.014832157999933, -7.854211907999968],
            [-35.018280297999979, -7.855545182999947],
            [-35.019327260999944, -7.858045996999977],
            [-35.019975663999958, -7.859158394999952],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Arizona - Sobradinho, C1',
        Concession: null,
        Tensao: 500.0,
        Extensao: 41.68942,
        Ano_Opera: 2020.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.37087,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-41.006187009999962, -9.748994937999953],
            [-40.982876104999946, -9.725229596999952],
            [-40.947536883999931, -9.704817494999929],
            [-40.898228166999957, -9.601574024999934],
            [-40.88832853599996, -9.583283448999964],
            [-40.874250096999958, -9.551919640999927],
            [-40.865233161999981, -9.504860254999926],
            [-40.849779026999954, -9.490026330999967],
            [-40.841102655999975, -9.477311783999937],
            [-40.838988386999972, -9.472893477999946],
            [-40.833939648999944, -9.456271916999981],
            [-40.828632289999973, -9.443251430999965],
            [-40.826093372999935, -9.441459886999951],
            [-40.825428082999963, -9.438523609999947],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Gilbués II - São João do Piauí, C1',
        Concession: 'SJTE - SÃO JOÃO TRANSMISSORA DE ENERGIA SA',
        Tensao: 500.0,
        Extensao: 415.04911,
        Ano_Opera: 2018.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 3.75228,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-42.231121306999967, -8.360031493999941],
            [-42.231837524999946, -8.35982329199993],
            [-42.232834437999941, -8.358431584999948],
            [-42.237698055999942, -8.354938535999963],
            [-42.245747867999967, -8.340303898999935],
            [-42.25438055199993, -8.337381920999974],
            [-42.282898511999974, -8.345550312999933],
            [-42.366456754999945, -8.333277647999978],
            [-43.213321391609078, -8.323274216564668],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Nova Olinda - São João do Piauí, C1',
        Concession:
          'N OLINDA NORTE SOLAR - ENEL GREEN POWER NOVA OLINDA NORTE SOLAR S.A.',
        Tensao: 500.0,
        Extensao: 43.28598,
        Ano_Opera: 2017.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.39043,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-42.556424772999947, -8.193809432999956],
            [-42.555529870999976, -8.193809829999964],
            [-42.553780966999966, -8.194430869999962],
            [-42.530786628999977, -8.208814591999953],
            [-42.52851845999993, -8.224444343999949],
            [-42.488827184999934, -8.240973183999927],
            [-42.440684026999975, -8.240728892999925],
            [-42.369021798999938, -8.288113478999946],
            [-42.365893799999981, -8.290062851999949],
            [-42.36491584099997, -8.29134776099994],
            [-42.345522916999982, -8.303623558999959],
            [-42.301264069999945, -8.30963050899993],
            [-42.277007570999956, -8.312831502999927],
            [-42.261326030999953, -8.320127921999926],
            [-42.258788335999952, -8.324503353999944],
            [-42.252961987999981, -8.326994649999961],
            [-42.24938803699996, -8.331012369999939],
            [-42.247340510999948, -8.33547243199996],
            [-42.246894956999938, -8.342855601999929],
            [-42.242519920999939, -8.350021744999935],
            [-42.23844390499994, -8.355455541999959],
            [-42.233103397999969, -8.359123729999965],
            [-42.231629321999947, -8.35890719899993],
            [-42.230854807999947, -8.359115401999929],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV UHE Paulo Afonso IV - SE Paulo Afonso IV, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 0.66329,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.006,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.206385221999938, -9.415072855999938],
            [-38.202631249999968, -9.413366093999969],
            [-38.200832157999969, -9.412828813999965],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV UHE Paulo Afonso IV - SE Paulo Afonso IV, C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 0.66562,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.00603,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.206394388999968, -9.415352520999932],
            [-38.202513818999932, -9.413649652999936],
            [-38.200771389999943, -9.413245981999978],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV UHE Paulo Afonso IV - SE Paulo Afonso IV, C3',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 0.68766,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.00622,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.206432861999929, -9.415915281999958],
            [-38.202453218999949, -9.413906391999944],
            [-38.200736687999949, -9.413519650999945],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV UHE Paulo Afonso IV - SE Paulo Afonso IV, C4',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 0.69242,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.00626,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.206437052999945, -9.416189202999931],
            [-38.202338552999947, -9.414148207999972],
            [-38.200668459999974, -9.413928351999971],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV UHE Paulo Afonso IV - SE Paulo Afonso IV, C5',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 0.70043,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.00633,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.206441578999943, -9.416494219999947],
            [-38.202252305999934, -9.414376030999961],
            [-38.200626130999979, -9.414165392999962],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV UHE Paulo Afonso IV - SE Paulo Afonso IV, C6',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 0.70344,
        Ano_Opera: 0.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.00636,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.206416265999962, -9.416794459999949],
            [-38.202175441999941, -9.414650202999951],
            [-38.200567960999933, -9.414563364999935],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Coremas - Rio Alto, C1',
        Concession: null,
        Tensao: 230.0,
        Extensao: 8.17045,
        Ano_Opera: 2018.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.07203,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-37.956038502999945, -7.01645059599997],
            [-37.956653494999955, -7.016591984999934],
            [-37.959513324999932, -7.016877077999936],
            [-37.961993862999975, -7.017013692999967],
            [-37.965444101999935, -7.01736398099996],
            [-37.971187824999959, -7.017956314999935],
            [-37.971560694999937, -7.016713462999974],
            [-37.972331877999977, -7.014399673999947],
            [-37.974667324999984, -7.007378913999958],
            [-37.978159351999977, -6.996911470999976],
            [-37.980171232999965, -6.990834320999966],
            [-37.982258162999983, -6.984540637999942],
            [-37.987156662999951, -6.979545229999928],
            [-37.989626710999971, -6.976971000999981],
            [-37.988941588999978, -6.971368544999962],
            [-37.988617021999971, -6.968821118999927],
            [-37.989269504999982, -6.966838819999964],
            [-37.989487333999932, -6.966136818999928],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Paulo Afonso IV - Luiz Gonzaga, C2',
        Concession: 'LEST - LINHAS DE ENERGIA DO SERTÃO TRANSMISSORA S.A',
        Tensao: 500.0,
        Extensao: 39.40158,
        Ano_Opera: 2020.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.34808,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.305623552999975, -9.14297870799993],
            [-38.305822273999979, -9.142898835999972],
            [-38.305431399999975, -9.142149135999944],
            [-38.303363612999931, -9.14137358399995],
            [-38.298975927999948, -9.141010663999964],
            [-38.293730582999956, -9.14053391799996],
            [-38.288900828999942, -9.142416731999958],
            [-38.284604962999936, -9.144065449999971],
            [-38.277001558999984, -9.153451476999976],
            [-38.26913286599995, -9.163171489999968],
            [-38.258317450999982, -9.176521799999932],
            [-38.250970059999929, -9.18560324799995],
            [-38.24214012799996, -9.196512293999945],
            [-38.239748993999967, -9.199436786999968],
            [-38.237178254999947, -9.208115279999959],
            [-38.233434209999984, -9.220628421999947],
            [-38.230851117999975, -9.229306320999967],
            [-38.227473243999953, -9.23750578399995],
            [-38.223815266999964, -9.246416885999963],
            [-38.219127356999934, -9.257803634999959],
            [-38.214669090999962, -9.268653894999943],
            [-38.209281645999965, -9.280593925999938],
            [-38.204430098999978, -9.290934266999955],
            [-38.203616615999977, -9.295006986999965],
            [-38.201408851999929, -9.300339938999969],
            [-38.197389905999955, -9.310085364999964],
            [-38.190960578999977, -9.325567235999927],
            [-38.185789816999943, -9.338071421999928],
            [-38.182367104999969, -9.346318685999961],
            [-38.179430897999964, -9.353150221999954],
            [-38.177797634999934, -9.355828157999952],
            [-38.174357282999949, -9.363836723999952],
            [-38.170814302999929, -9.371909634999952],
            [-38.167315215999963, -9.379943213999979],
            [-38.164286865999941, -9.386858616999973],
            [-38.16113503899993, -9.394051162999972],
            [-38.165916334999963, -9.402714450999952],
            [-38.174053532999949, -9.40526534299994],
            [-38.189165143999958, -9.410019324999951],
            [-38.195938805999958, -9.41212967599995],
            [-38.197392969999953, -9.413016033999952],
            [-38.198228147999941, -9.413135522999937],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Lagoa do Carro - Coteminas, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 98.39587,
        Ano_Opera: 2020.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.86905,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.339901131999966, -7.862415917999954],
            [-35.339902231999929, -7.862407117999965],
            [-35.339902231999929, -7.861921630999973],
            [-35.333309511999971, -7.857145125999978],
            [-35.330979987999967, -7.85268494099995],
            [-35.331307593999952, -7.847179089999941],
            [-35.33186068599997, -7.844668903999946],
            [-35.335518213999933, -7.840625620999958],
            [-35.336359726999945, -7.828787222999949],
            [-35.335802483999942, -7.82418639499997],
            [-35.333678292999934, -7.796980928999972],
            [-35.330416224999965, -7.790785859999971],
            [-35.317324817999975, -7.774252550999961],
            [-35.317311203999964, -7.774242826999966],
            [-35.323134110999945, -7.769353400999933],
            [-35.327806910999982, -7.765921028999969],
            [-35.330630245999942, -7.763749918999963],
            [-35.334311648999972, -7.760524413999974],
            [-35.339992947999974, -7.755467608999936],
            [-35.348414201999958, -7.748070844999972],
            [-35.357667208999942, -7.73991889499996],
            [-35.365661803999956, -7.732838319999928],
            [-35.371411462999959, -7.727746010999965],
            [-35.378628866999975, -7.721378873999981],
            [-35.381526883999982, -7.718843197999945],
            [-35.386760480999953, -7.714954105999936],
            [-35.388774674999979, -7.712459961999969],
            [-35.392714652999985, -7.708986609999954],
            [-35.401401034999935, -7.701329905999955],
            [-35.40817524199997, -7.695330044999935],
            [-35.41553993499997, -7.688850078999963],
            [-35.421937566999929, -7.683165728999938],
            [-35.426030762999972, -7.679605967999976],
            [-35.436499106999975, -7.670714196999938],
            [-35.441554683999982, -7.666468705999932],
            [-35.450220140999932, -7.657384170999933],
            [-35.456726815999957, -7.651852950999967],
            [-35.464468890999967, -7.64533576499997],
            [-35.475321350999934, -7.636280195999973],
            [-35.479148426999984, -7.633009425999944],
            [-35.487112592999949, -7.624564318999944],
            [-35.496530442999983, -7.616696440999931],
            [-35.503110818999971, -7.611239156999943],
            [-35.506744626999932, -7.609816527999953],
            [-35.511539380999977, -7.605780627999934],
            [-35.517524700999957, -7.600769527999944],
            [-35.525494246999983, -7.594072465999943],
            [-35.588122785999929, -7.541430779999928],
            [-35.595629985999949, -7.535702226999945],
            [-35.607313138999984, -7.525863155999957],
            [-35.613957409999955, -7.51966903999994],
            [-35.653456836999965, -7.486447384999963],
            [-35.798859749999963, -7.363988423999956],
            [-35.807717293999929, -7.354125557999964],
            [-35.810606886999949, -7.350945905999936],
            [-35.814052205999928, -7.347091776999946],
            [-35.816655050999941, -7.344231225999977],
            [-35.819996864999951, -7.342997299999979],
            [-35.821066637999934, -7.341469679999932],
            [-35.822666478999963, -7.337572661999957],
            [-35.828312904999962, -7.331434096999942],
            [-35.835434828999951, -7.323678199999961],
            [-35.841604887999949, -7.31682034399995],
            [-35.848862992999955, -7.308843648999925],
            [-35.854528257999959, -7.302632022999944],
            [-35.861209171999974, -7.295326415999966],
            [-35.866074029999936, -7.289995528999953],
            [-35.87133642799995, -7.284214221999946],
            [-35.873435829999949, -7.281914833999963],
            [-35.888109411999949, -7.278465770999958],
            [-35.895257900999979, -7.276835923999954],
            [-35.895268458999965, -7.27912080599998],
            [-35.89550877399995, -7.279664568999976],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Lagoa do Carro - Pau Ferro, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 51.04316,
        Ano_Opera: 2020.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.45443,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.020430977999979, -7.85892524999997],
            [-35.018740373999947, -7.854709148999973],
            [-35.01514840699997, -7.845718351999949],
            [-35.013387774999956, -7.841575990999957],
            [-35.017533356999934, -7.835676393999961],
            [-35.022487532999946, -7.828682552999965],
            [-35.034905995999964, -7.811128610999958],
            [-35.03691469499995, -7.808096974999955],
            [-35.03938119299994, -7.804819209999948],
            [-35.04256212699994, -7.800296246999949],
            [-35.045988713999975, -7.795438875999935],
            [-35.04816182299993, -7.791016860999946],
            [-35.050749586999984, -7.785789192999971],
            [-35.051693895999961, -7.783789377999938],
            [-35.059969783999975, -7.78229577299993],
            [-35.067942937999931, -7.780803396999943],
            [-35.075860740999929, -7.779377595999961],
            [-35.094882856999959, -7.775945879999938],
            [-35.161800569999969, -7.776283759999956],
            [-35.170235830999957, -7.776325757999928],
            [-35.173819826999932, -7.776327311999978],
            [-35.18771303699998, -7.780287023999961],
            [-35.193280974999936, -7.779222214999947],
            [-35.197337955999956, -7.778306122999936],
            [-35.206362061999982, -7.776497732999928],
            [-35.21086790499993, -7.776491284999963],
            [-35.236004425999965, -7.776575912999931],
            [-35.244468964999953, -7.776549587999966],
            [-35.262368625999954, -7.775944507999952],
            [-35.273552962999929, -7.775639671999954],
            [-35.284279253999955, -7.77528859499995],
            [-35.288935307999964, -7.775123593999979],
            [-35.30005333899993, -7.774784520999958],
            [-35.314873139999975, -7.774406971999952],
            [-35.317196133999971, -7.774283523999941],
            [-35.317255192999937, -7.774325709999971],
            [-35.330332064999936, -7.790840662999926],
            [-35.333580201999951, -7.797009272999958],
            [-35.335702952999952, -7.824196302999951],
            [-35.336259297999959, -7.828789711999946],
            [-35.335420910999972, -7.84058411999996],
            [-35.331768734999969, -7.844621486999927],
            [-35.331208238999977, -7.847165275999942],
            [-35.330878518999953, -7.852706667999939],
            [-35.333231773999955, -7.857212291999929],
            [-35.339802231999954, -7.861972666999975],
            [-35.339802231999954, -7.862400892999972],
            [-35.339801903999955, -7.862403513999936],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Angelim - Ribeirão, C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 116.33213,
        Ano_Opera: 2019.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.04317,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.294245219999937, -8.893454645999952],
            [-36.292197804999944, -8.893005512999935],
            [-36.285552162999977, -8.893299680999974],
            [-36.282720040999948, -8.893459659999962],
            [-36.278694426999948, -8.893649383999957],
            [-36.269920851999984, -8.894054683999968],
            [-36.263359174999948, -8.894363620999968],
            [-36.250775789999977, -8.894944000999942],
            [-36.242393683999978, -8.895345016999954],
            [-36.234561554999971, -8.895704703999968],
            [-36.215574137999965, -8.89658686599995],
            [-36.194839349999938, -8.897586699999977],
            [-36.174613312999952, -8.898521584999969],
            [-36.163173962999963, -8.899048424999933],
            [-36.141818982999951, -8.900021609999953],
            [-36.128223996999964, -8.900255586999947],
            [-36.118158543999982, -8.900371659999962],
            [-36.104536051999958, -8.900565069999971],
            [-36.100366998999959, -8.899498190999964],
            [-36.091047175999961, -8.897071160999928],
            [-36.068293524999945, -8.891237236999928],
            [-36.052636123999946, -8.887154134999946],
            [-36.05262326899998, -8.887149902999965],
            [-36.036826865999956, -8.883027814999934],
            [-35.998684250999929, -8.873099968999952],
            [-35.96006396599995, -8.862735705999967],
            [-35.941233548999946, -8.857637529999977],
            [-35.915850456999976, -8.85071092499993],
            [-35.893220257999928, -8.841206691999957],
            [-35.868548910999948, -8.830871793999961],
            [-35.860209613999984, -8.827380110999968],
            [-35.851785967999945, -8.820172360999948],
            [-35.841118730999938, -8.810412420999967],
            [-35.833833707999929, -8.80388284299994],
            [-35.823598870999945, -8.797995835999927],
            [-35.811492684999962, -8.788897262999967],
            [-35.793062352999982, -8.775079222999977],
            [-35.772221087999981, -8.765393882999945],
            [-35.756739335999953, -8.75822484899993],
            [-35.729630460999942, -8.745718194999938],
            [-35.708968852999931, -8.736176215999933],
            [-35.679467838999983, -8.722538262999933],
            [-35.653986012999951, -8.710862852999981],
            [-35.63005488999994, -8.699799599999949],
            [-35.620191961999979, -8.695155185999965],
            [-35.610570479999978, -8.685217573999978],
            [-35.596475494999936, -8.670724261999965],
            [-35.586778181999932, -8.660799397999938],
            [-35.576368353999953, -8.650078856999926],
            [-35.564443119999964, -8.637863412999934],
            [-35.547026935999952, -8.619950321999966],
            [-35.534635931999958, -8.607235159999959],
            [-35.517675607999934, -8.589867062999929],
            [-35.498016537999945, -8.569644013999948],
            [-35.485851636999939, -8.55714057199998],
            [-35.470677657999943, -8.54155941099998],
            [-35.462428660999933, -8.533085868999933],
            [-35.450917011999934, -8.521247134999953],
            [-35.439427070999955, -8.509465137999939],
            [-35.425647258999959, -8.495270180999967],
            [-35.415605959999937, -8.484962321999944],
            [-35.404343475999951, -8.473366541999951],
            [-35.398169011999983, -8.466962727999942],
            [-35.397680147999949, -8.466566244999967],
            [-35.396136837999961, -8.468297075999942],
            [-35.396572018999962, -8.468774785999926],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Ribeirão - Recife II, C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 58.13522,
        Ano_Opera: 2019.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.51311,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.396283615999948, -8.469081079999967],
            [-35.395657387999961, -8.468525995999926],
            [-35.394535149999967, -8.467898712999954],
            [-35.39343949299996, -8.463918865999972],
            [-35.393623055999967, -8.462390249999942],
            [-35.393065990999958, -8.461750534999965],
            [-35.388903782999932, -8.457463386999962],
            [-35.378257640999948, -8.446537182999975],
            [-35.372903170999962, -8.441036123999936],
            [-35.356968458999972, -8.424676620999946],
            [-35.340979501999982, -8.408222670999976],
            [-35.290981225999928, -8.358969388999981],
            [-35.267088060999981, -8.335595151999939],
            [-35.256155561999947, -8.324884526999938],
            [-35.235764667999945, -8.305081092999956],
            [-35.220149248999974, -8.289716953999971],
            [-35.213977946999933, -8.283779184999958],
            [-35.131673973999966, -8.203138091999961],
            [-35.118390314999942, -8.190311420999933],
            [-35.101227420999976, -8.173525015999928],
            [-35.077179328999932, -8.149867194999956],
            [-35.071320313999934, -8.144096862999959],
            [-35.057472890999975, -8.130350059999955],
            [-35.055204609999976, -8.127991674999976],
            [-35.044643099999973, -8.117703392999942],
            [-35.039409562999936, -8.11246872199996],
            [-35.036589828999979, -8.110080860999972],
            [-35.035339712999985, -8.110423451999964],
            [-35.035067228999935, -8.111465384999974],
            [-35.035449537999966, -8.112733610999953],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Juazeiro Solar - Juazeiro II, C1',
        Concession: null,
        Tensao: 230.0,
        Extensao: 6.72523,
        Ano_Opera: 2019.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 0.0597,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.476853946999938, -9.48222033199994],
            [-40.477600270999972, -9.482582094999941],
            [-40.476857534999965, -9.484087683999974],
            [-40.474866129999953, -9.488014530999976],
            [-40.470901282999932, -9.495854587999929],
            [-40.468952023999975, -9.499691469999959],
            [-40.47047136599997, -9.501914755999962],
            [-40.472412107999958, -9.505373874999975],
            [-40.476281732999951, -9.512337877999926],
            [-40.48209178999997, -9.522762670999953],
            [-40.485961318999955, -9.529695604999972],
            [-40.487871621999943, -9.533185200999981],
            [-40.489088171999981, -9.533185326999956],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Xingó - Jardim, C2',
        Concession:
          'LEST - LEST – LINHAS DE ENERGIA DO SERTÃO TRANSMISSORA S.A',
        Tensao: 500.0,
        Extensao: 166.48722,
        Ano_Opera: 2020.0,
        created_us: null,
        created_da: null,
        last_edite: null,
        last_edi_1: null,
        Shape_Leng: 1.46354,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-37.462461888927933, -10.310613660371555],
            [-37.513456820999977, -10.224614354999972],
            [-37.535760941999968, -10.186781058999941],
            [-37.560433239999952, -10.145156451999981],
            [-37.567401827999959, -10.099390676999974],
            [-37.567303718999938, -10.087458513999934],
            [-37.569917055999952, -10.083343076999938],
            [-37.573067402999982, -10.062670411999932],
            [-37.587644326999964, -9.967961954999964],
            [-37.614426986999945, -9.922783785999968],
            [-37.68773030899996, -9.761890844999925],
            [-37.749206835999985, -9.683283608999943],
            [-37.763960787999963, -9.671045250999953],
            [-37.776247766999973, -9.649757894999937],
            [-37.788371747999975, -9.647503874999927],
            [-37.79520974299993, -9.646810078999977],
            [-37.799702451999963, -9.639429038999936],
            [-37.797127215999978, -9.634724589999962],
            [-37.796949144999985, -9.633946373999947],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Nossa Senhora do Socorro - Penedo, C2',
        Concession: 'ETENE - Empresa Transmissora de Energia do Nordeste SA',
        Tensao: 230.0,
        Extensao: 112.8361,
        Ano_Opera: 2021.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2021-01-14',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-03-12',
        Shape_Leng: 1.00522,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.638918249371201, -10.310613660371555],
            [-36.63845631199996, -10.309279173999926],
            [-36.622448288999976, -10.272253967999973],
            [-36.619916542999931, -10.26656229699995],
            [-36.595036541999946, -10.253242431999979],
            [-36.581484320999948, -10.248125400999982],
            [-36.570428948999961, -10.248201741999935],
            [-36.558042267999951, -10.247655258999941],
            [-36.556643144999953, -10.246779616999959],
            [-36.556310020999945, -10.243524513999944],
            [-36.558622856999932, -10.241754194999942],
            [-36.561582905999956, -10.242182497999977],
            [-36.561925699999961, -10.242285010999979],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Pau Ferro - Santa Rita II, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 88.40299,
        Ano_Opera: 2021.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2021-04-13',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-04-13',
        Shape_Leng: 0.76423,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.019848235999973, -7.858181146999925],
            [-35.018519306999963, -7.854822537999951],
            [-35.016515403999961, -7.850523049999936],
            [-35.012987614999929, -7.841573420999964],
            [-35.045506022999973, -7.795301529999961],
            [-35.051420886999949, -7.783336965999979],
            [-35.054943678999962, -7.771546735999948],
            [-35.058698270999969, -7.762654987999952],
            [-35.063256920999947, -7.749697087999948],
            [-35.062855743999933, -7.739247446999968],
            [-35.069186378999973, -7.732804817999977],
            [-35.085210859999961, -7.68723552299997],
            [-35.08696452199996, -7.679884970999979],
            [-35.106138634999979, -7.635224464999965],
            [-35.107350374999953, -7.627597101999982],
            [-35.111224898999978, -7.623380316999942],
            [-35.123782773999949, -7.594076524999934],
            [-35.122656396999957, -7.582828742999936],
            [-35.114700748999951, -7.561320953999939],
            [-35.112511643999937, -7.55736153099997],
            [-35.111068104999958, -7.551476647999948],
            [-35.107514522999963, -7.541936341999929],
            [-35.073999813999933, -7.434314112999971],
            [-35.068942745999948, -7.400900769999964],
            [-35.061698476999936, -7.394812204999937],
            [-35.056156711999961, -7.376944832999925],
            [-35.049877895999941, -7.33796741499998],
            [-35.056180096999981, -7.314264830999946],
            [-35.050790497999969, -7.28672137999996],
            [-35.049588721999953, -7.271635894999974],
            [-35.048624147999931, -7.242301204999933],
            [-35.040154654999981, -7.223976678999975],
            [-35.042957698999942, -7.179633994999961],
            [-35.009708062999948, -7.156483683999966],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE N3 - Bom Nome, C1',
        Concession: null,
        Tensao: 230.0,
        Extensao: 65.18296,
        Ano_Opera: 0.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2021-04-14',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-04-14',
        Shape_Leng: 0.59022,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.628067180999949, -7.996885564999957],
            [-38.628294432999951, -7.996108490999973],
            [-38.629978186999949, -7.995425617999956],
            [-38.630990568999948, -7.99608227799996],
            [-38.632626381999955, -7.999094682999953],
            [-38.639368055999967, -8.000355915999933],
            [-38.648621988999935, -8.002059586999962],
            [-38.657550810999965, -8.003702424999972],
            [-38.666144756999984, -8.005292974999975],
            [-38.674160923999978, -8.006771594999975],
            [-38.679350942999974, -8.007741557999964],
            [-38.683427717999962, -8.008498041999928],
            [-38.695946363999951, -8.010786350999979],
            [-38.70473395099998, -8.012424843999952],
            [-38.713814968999941, -8.014083054999958],
            [-38.721044440999947, -8.015414960999976],
            [-38.728328463999958, -8.016731169999957],
            [-38.734652707999942, -8.017876583999964],
            [-38.741534746999946, -8.019128261999981],
            [-38.749872702999937, -8.020631935999972],
            [-38.752930140999979, -8.021179214999961],
            [-38.760074719999977, -8.024554196999929],
            [-38.762898303999975, -8.025874955999939],
            [-38.770795456999963, -8.026302551999947],
            [-38.778579817999969, -8.026699427999972],
            [-38.785833403999959, -8.027106127999957],
            [-38.793082513999934, -8.028409095999962],
            [-38.801646249999976, -8.029935756999976],
            [-38.812805598999944, -8.031924807999928],
            [-38.820805671999949, -8.033360267999967],
            [-38.82908150999998, -8.034844681999971],
            [-38.838286410999956, -8.036483039999951],
            [-38.849267155999939, -8.038383487999965],
            [-38.860007400999962, -8.040244231999964],
            [-38.867884450999952, -8.04162518399994],
            [-38.875040299999966, -8.043620559999965],
            [-38.880675040999961, -8.044633934999979],
            [-38.889191620999952, -8.046166009999979],
            [-38.894481477999932, -8.047142780999934],
            [-38.905475812999953, -8.049094701999934],
            [-38.913909272999945, -8.050627856999938],
            [-38.92094278899998, -8.05189926099996],
            [-38.934254084999964, -8.054295629999956],
            [-38.942598400999941, -8.055786558999955],
            [-38.954956340999956, -8.058021671999938],
            [-38.964309312999944, -8.059709808999969],
            [-38.971171074999972, -8.060943572999975],
            [-38.982923072999938, -8.06306049899996],
            [-38.987123291999978, -8.063833115999955],
            [-38.995115288999955, -8.065304810999976],
            [-38.999823692999939, -8.066136709999967],
            [-39.00699343499997, -8.068228923999925],
            [-39.013838900999929, -8.07022044699994],
            [-39.018802510999933, -8.07166424899998],
            [-39.02763596099993, -8.074243299999978],
            [-39.038221237999949, -8.078257951999944],
            [-39.054414092999934, -8.084323820999941],
            [-39.06084444499993, -8.086776789999931],
            [-39.069040677999965, -8.089875094999968],
            [-39.078111545999946, -8.08963198899994],
            [-39.08547944299994, -8.089415476999932],
            [-39.094937011999946, -8.089117367999961],
            [-39.103507992999937, -8.088906316999953],
            [-39.11448620699997, -8.088558902999978],
            [-39.122443722999947, -8.088323271999968],
            [-39.125409224999942, -8.089248737999981],
            [-39.130723688999979, -8.090900828999963],
            [-39.146862669999962, -8.095922386999973],
            [-39.154868868999984, -8.098352530999932],
            [-39.158534249999946, -8.099557457999936],
            [-39.162826675999952, -8.100871566999956],
            [-39.166703929999983, -8.102094579999971],
            [-39.173714287999985, -8.104285389999973],
            [-39.177388816999951, -8.104227842999933],
            [-39.181199878999962, -8.104188807999947],
            [-39.190188948999946, -8.104085727999973],
            [-39.193845207999971, -8.104048361999958],
            [-39.197511696999982, -8.103986308999936],
            [-39.19997193699993, -8.103961997999932],
            [-39.201639452999984, -8.10483396799998],
            [-39.201975439999956, -8.105339101999959],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE N1 - SE N2, C1',
        Concession: null,
        Tensao: 230.0,
        Extensao: 35.90971,
        Ano_Opera: 0.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2021-04-14',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-04-14',
        Shape_Leng: 0.31684,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-39.460193285999935, -8.524344692999932],
            [-39.460618367999984, -8.523805901999935],
            [-39.462108536999949, -8.522383813999966],
            [-39.463694821999979, -8.514710240999932],
            [-39.465275460999976, -8.506870826999943],
            [-39.465936246999945, -8.503551740999967],
            [-39.462294867999958, -8.496622584999955],
            [-39.457807702999958, -8.488208185999952],
            [-39.454039201999933, -8.48109935399998],
            [-39.450938488999952, -8.475315757999965],
            [-39.443422765999969, -8.461129149999977],
            [-39.439950287999977, -8.454601130999947],
            [-39.436094124999954, -8.448944431999962],
            [-39.428896699999939, -8.43838348099996],
            [-39.424577348999946, -8.431926304999934],
            [-39.420038563999981, -8.425254244999962],
            [-39.415158574999964, -8.418234042999927],
            [-39.40891882699998, -8.409069551999949],
            [-39.400692653999954, -8.39697538799993],
            [-39.392760290999945, -8.385352234999971],
            [-39.386796930999935, -8.376594342999965],
            [-39.383224505999976, -8.366475213999934],
            [-39.379168970999956, -8.35494745799997],
            [-39.376188552999963, -8.346528628999977],
            [-39.37261872199997, -8.336229372999981],
            [-39.370045005999941, -8.331727843999943],
            [-39.366072206999945, -8.324686915999962],
            [-39.361931714999969, -8.317428134999943],
            [-39.360278927999957, -8.312708451999981],
            [-39.353840821999938, -8.294003218999933],
            [-39.350897716999953, -8.285565102999954],
            [-39.348113783999963, -8.277476040999943],
            [-39.345285055999966, -8.26934947899997],
            [-39.342488156999934, -8.261297774999946],
            [-39.339648271999977, -8.253069898999968],
            [-39.336810419999949, -8.244878191999931],
            [-39.335670475999962, -8.241766022999968],
            [-39.335599398999932, -8.241213007999932],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE N2 - SE N3, C1',
        Concession: null,
        Tensao: 230.0,
        Extensao: 23.94356,
        Ano_Opera: 0.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2021-04-14',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-04-14',
        Shape_Leng: 0.21345,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-39.335666685999968, -8.240417997999941],
            [-39.335714244999963, -8.239910727999927],
            [-39.334903886999939, -8.238874038999938],
            [-39.333236901999953, -8.236634328999969],
            [-39.329060088999938, -8.234095789999969],
            [-39.321213887999932, -8.229348361999939],
            [-39.317339121999964, -8.227031917999966],
            [-39.311149019999959, -8.220970720999958],
            [-39.299579356999971, -8.20970200499994],
            [-39.291806599999973, -8.20844571899994],
            [-39.277101837999965, -8.20606840499994],
            [-39.263986665999937, -8.203948214999969],
            [-39.259134254999935, -8.203172348999942],
            [-39.250065036999956, -8.198708113999942],
            [-39.239440484999932, -8.193457591999959],
            [-39.232198962999973, -8.189887146999979],
            [-39.223943346999931, -8.18582258899994],
            [-39.217725185999939, -8.182749438999963],
            [-39.216917081999952, -8.17861441499997],
            [-39.215713411999957, -8.172620822999932],
            [-39.214068542999939, -8.16423019399997],
            [-39.212800595999965, -8.157809738999958],
            [-39.211181002999979, -8.149620418999973],
            [-39.209528534999947, -8.14130100899996],
            [-39.207926634999978, -8.133154568999942],
            [-39.206514610999932, -8.126002354999969],
            [-39.204871326999978, -8.117648204999966],
            [-39.204122328999972, -8.113915812999949],
            [-39.202749341999947, -8.107006875999957],
            [-39.202253602999974, -8.106027752999978],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE E1 - SE Floresta II, C1',
        Concession: null,
        Tensao: 230.0,
        Extensao: 3.57748,
        Ano_Opera: 0.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2021-04-14',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-04-14',
        Shape_Leng: 0.03197,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.366937921999977, -8.769286955999974],
            [-38.366693453999972, -8.768684865999944],
            [-38.36601008599996, -8.766928234999966],
            [-38.364999219999959, -8.765993203999926],
            [-38.362714266999944, -8.764913306999972],
            [-38.359052184999939, -8.763195950999943],
            [-38.355599274999975, -8.760263419999944],
            [-38.350964957999963, -8.75625625899994],
            [-38.345229118999953, -8.751372899999978],
            [-38.343476362999979, -8.749865106999948],
            [-38.342666718999965, -8.749307356999964],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Floresta II - SE E2, C1',
        Concession: null,
        Tensao: 230.0,
        Extensao: 7.0594,
        Ano_Opera: 0.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2021-04-14',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-04-14',
        Shape_Leng: 0.06308,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.342747801999963, -8.749185649999959],
            [-38.343301559999929, -8.749495322999962],
            [-38.343686859999934, -8.749271583999928],
            [-38.34365669999994, -8.748784214999944],
            [-38.34303721699996, -8.748045197999943],
            [-38.341199100999972, -8.747257778999938],
            [-38.339802521999957, -8.746027335999941],
            [-38.336689748999959, -8.74429041999997],
            [-38.332969807999973, -8.74142948399998],
            [-38.329495572999974, -8.738766928999951],
            [-38.304035373999966, -8.718520380999962],
            [-38.300723881999943, -8.715877312999964],
            [-38.297267183999963, -8.712514217999967],
            [-38.29781810299994, -8.711021310999968],
            [-38.297916894999958, -8.710153731999981],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE E2 - SE E3, C1',
        Concession: null,
        Tensao: 230.0,
        Extensao: 16.83149,
        Ano_Opera: 0.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2021-04-14',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-04-14',
        Shape_Leng: 0.15151,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.298017613999946, -8.709424557999967],
            [-38.29793452499996, -8.708043737999958],
            [-38.298100616999932, -8.704826878999938],
            [-38.297288957999967, -8.702260064999962],
            [-38.294634110999937, -8.699875464999934],
            [-38.291120357999944, -8.696683273999952],
            [-38.285126315999946, -8.691257221999933],
            [-38.281971379999959, -8.688378556999965],
            [-38.279238240999973, -8.685917748999941],
            [-38.270828015999939, -8.678868482999974],
            [-38.264404399999933, -8.673509063999973],
            [-38.254522978999944, -8.665231167999934],
            [-38.251309298999956, -8.663179621999973],
            [-38.242868505999979, -8.662763305999931],
            [-38.236247546999948, -8.662434060999942],
            [-38.231883379999942, -8.662243518999958],
            [-38.222826703999942, -8.663001202999965],
            [-38.218747628999949, -8.663328127999932],
            [-38.211388196999962, -8.662412847999974],
            [-38.207791795999981, -8.661971835999964],
            [-38.198809771999947, -8.661872979999941],
            [-38.190401007999981, -8.661781582999936],
            [-38.177192120999962, -8.661660759999961],
            [-38.173448726999936, -8.661593762999928],
            [-38.168990294999958, -8.660066058999973],
            [-38.168387606999943, -8.659155636999969],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE E3 - SE E4, C1',
        Concession: null,
        Tensao: 230.0,
        Extensao: 50.82976,
        Ano_Opera: 0.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2021-04-14',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-04-14',
        Shape_Leng: 0.45265,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.167933737999931, -8.658491205999951],
            [-38.164273413999979, -8.652714501999981],
            [-38.155389495999941, -8.636974143999964],
            [-38.150825604999966, -8.628977705999944],
            [-38.144258366999964, -8.617446929999971],
            [-38.135312369999951, -8.601659791999964],
            [-38.12653449499993, -8.586217556999941],
            [-38.124635477999959, -8.582964554999933],
            [-38.117724345999932, -8.577416764999953],
            [-38.108621551999931, -8.570109122999952],
            [-38.097993674999941, -8.561600220999935],
            [-38.087602562999962, -8.553300922999938],
            [-38.080189021999956, -8.547366446999945],
            [-38.070190821999972, -8.539390226999956],
            [-38.059412948999977, -8.530777015999945],
            [-38.043258869999931, -8.517832247999934],
            [-38.032193278999955, -8.508998493999968],
            [-38.021356175999983, -8.500308772999972],
            [-38.006221556999947, -8.488250259999973],
            [-37.99225114099994, -8.477045227999952],
            [-37.983585848999951, -8.470136192999973],
            [-37.971417562999932, -8.460381684999959],
            [-37.961460673999966, -8.452438120999943],
            [-37.951503776999971, -8.444448621999982],
            [-37.942151812999953, -8.436992853999925],
            [-37.931372566999983, -8.428239805999965],
            [-37.920276447999981, -8.419185083999935],
            [-37.911018963999936, -8.412131630999966],
            [-37.905293300999972, -8.407807369999944],
            [-37.881382747999965, -8.389240962999963],
            [-37.869866485999978, -8.378863638999974],
            [-37.862170030999948, -8.364658782999982],
            [-37.853676396999958, -8.358353678999947],
            [-37.844023590999939, -8.356849813999929],
            [-37.841679647999968, -8.355727284999944],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE E4 - SE E6, C1',
        Concession: null,
        Tensao: 230.0,
        Extensao: 67.10497,
        Ano_Opera: 0.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2021-04-14',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-04-14',
        Shape_Leng: 0.60044,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-37.840611389999935, -8.355540103999942],
            [-37.838552340999968, -8.354357680999954],
            [-37.829035826999984, -8.344311670999957],
            [-37.826739410999949, -8.340559767999935],
            [-37.822085149999964, -8.335743021999974],
            [-37.709180784999944, -8.216745173999925],
            [-37.708029426999929, -8.215467020999938],
            [-37.701658032999944, -8.208729199999937],
            [-37.69231515599995, -8.198867313999926],
            [-37.686218799999949, -8.192436935999979],
            [-37.671673236999936, -8.177071327999954],
            [-37.663297783999951, -8.168225939999957],
            [-37.653952240999956, -8.158360811999955],
            [-37.64839866799997, -8.152509395999971],
            [-37.641878213999973, -8.145616661999952],
            [-37.636443783999937, -8.13988335099998],
            [-37.63110876199994, -8.134264519999931],
            [-37.627491145999954, -8.133844904999933],
            [-37.619587517999946, -8.13295193099998],
            [-37.615919192999968, -8.132388470999956],
            [-37.609004298999935, -8.131336518999944],
            [-37.604622939999956, -8.130674803999966],
            [-37.600673963999952, -8.130072642999949],
            [-37.593158332999963, -8.128938975999972],
            [-37.578453166999964, -8.126684491999981],
            [-37.566286587999969, -8.124823402999937],
            [-37.555245213999967, -8.123127345999933],
            [-37.55133127199997, -8.122807295999962],
            [-37.541079374999981, -8.121949846999939],
            [-37.533555327999977, -8.121322959999929],
            [-37.521451212999978, -8.119574586999931],
            [-37.50893732399993, -8.117749044999925],
            [-37.496872217999965, -8.115999402999932],
            [-37.482469099999946, -8.113909427999943],
            [-37.474522030999935, -8.112732900999958],
            [-37.461696634999953, -8.110873154999979],
            [-37.442876051999974, -8.108136704999936],
            [-37.420278100999951, -8.10482647799995],
            [-37.407934144999956, -8.103021480999928],
            [-37.401286556999935, -8.102041242999974],
            [-37.397729063999975, -8.103084266999929],
            [-37.394844822999971, -8.102586471999928],
            [-37.394020575999946, -8.100114699999949],
            [-37.39404258299993, -8.097420708999948],
            [-37.388215154999955, -8.091984345999947],
            [-37.38636325799996, -8.08787465599994],
            [-37.37719175999996, -8.08359893599993],
            [-37.369693292999955, -8.080118660999972],
            [-37.368478630999959, -8.077892095999971],
            [-37.368000454999958, -8.075130032999937],
            [-37.367165392999937, -8.070265943999971],
            [-37.365030931999968, -8.06822621699996],
            [-37.361957989999951, -8.065961240999968],
            [-37.359665477999954, -8.064311632999932],
            [-37.359026521999965, -8.063938503999964],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Queimada Nova II - Buritirama, C2',
        Concession: 'EQUATORIAL 3 - Equatorial Transmissora 3 SPE SA',
        Tensao: 500.0,
        Extensao: 375.05248,
        Ano_Opera: 2021.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2021-06-01',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-06-01',
        Shape_Leng: 3.38179,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-43.213321391609078, -9.822497569045675],
            [-42.981548189999955, -9.71838179699995],
            [-42.734226525999929, -9.636315646999947],
            [-42.581611466999959, -9.572074813999961],
            [-42.50733321499996, -9.504544520999957],
            [-42.371047205999957, -9.383939415999976],
            [-42.157127712999966, -9.235806467999964],
            [-41.865633640999931, -9.022566219999931],
            [-41.674532699999929, -8.851121638999928],
            [-41.586068821999959, -8.779196785999943],
            [-41.56799919499997, -8.775302401999966],
            [-41.528272479999941, -8.737955005999936],
            [-41.493529225999964, -8.674771987999975],
            [-41.426785740999946, -8.591225146999932],
            [-41.426956987999972, -8.589967711999975],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Santa Luzia II - Campina Grande III, C1',
        Concession:
          'SANTA LUZIA - NEOENERGIA SANTA LUZIA TRANSMISSÃO DE ENERGIA S/A',
        Tensao: 500.0,
        Extensao: 124.57574,
        Ano_Opera: 2021.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2021-06-29',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-06-29',
        Shape_Leng: 1.11995,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.981563994999931, -7.251666739999962],
            [-35.981887600999983, -7.25088627699995],
            [-35.992223979999949, -7.247593102999929],
            [-36.180786270999931, -7.220706577999977],
            [-36.237875488999975, -7.20289631199995],
            [-36.356295494999983, -7.195870340999932],
            [-36.51199790499993, -7.150346492999972],
            [-36.664737886999944, -7.136181044999944],
            [-36.78372402399998, -7.098307758999965],
            [-36.798500476999948, -7.086862550999967],
            [-36.888479906999976, -7.024962323999944],
            [-36.962844407999967, -6.993131004999952],
            [-36.980761749999942, -6.966680439999948],
            [-36.977397191999955, -6.929047836999928],
            [-36.978453672999933, -6.914726336999934],
            [-36.980726057999959, -6.912751383999932],
            [-36.983688486999938, -6.913013124999964],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Milagres II - Queimada Nova II, C1',
        Concession: 'RIALMA III - RIALMA TRANSMISSORA DE ENERGIA III S/A',
        Tensao: 500.0,
        Extensao: 315.26506,
        Ano_Opera: 2021.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2021-08-02',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-08-02',
        Shape_Leng: 2.84002,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.92322375599997, -7.34578529199996],
            [-38.92280735099996, -7.346856049999928],
            [-38.922069717999932, -7.348735823999959],
            [-38.922155377999957, -7.351935008999931],
            [-38.92414817699995, -7.353154482999969],
            [-38.926896453999973, -7.354112215999976],
            [-38.953302125999983, -7.368348927999932],
            [-38.965359251999985, -7.388060778999943],
            [-38.969659175999936, -7.391661617999944],
            [-39.007799079999984, -7.423032906999936],
            [-39.009176787999934, -7.42520297599998],
            [-39.017623025999967, -7.438115717999949],
            [-39.049817743999938, -7.470120060999932],
            [-39.073853007999958, -7.498747020999929],
            [-39.097738647999961, -7.527276788999927],
            [-39.120498018999967, -7.556704589999981],
            [-39.133132344999979, -7.570834914999978],
            [-39.14712725499993, -7.579777873999944],
            [-39.154330459999983, -7.584350789999974],
            [-39.210514304999947, -7.616483922999976],
            [-39.270408356999951, -7.661676579999948],
            [-39.277306453999984, -7.666899201999968],
            [-39.299513245999947, -7.674657804999981],
            [-39.361744960999943, -7.689871539999956],
            [-39.512272850999977, -7.755525376999969],
            [-39.588123859999939, -7.777203171999929],
            [-39.623318507999954, -7.78723529399997],
            [-39.678190276999942, -7.804885446999947],
            [-39.777675710999972, -7.848125344999971],
            [-39.791029912999932, -7.853923669999972],
            [-39.844082915999934, -7.879502153999965],
            [-39.876075839999942, -7.893968295999969],
            [-39.927992740999969, -7.919784669999956],
            [-39.945499162999965, -7.928501336999943],
            [-39.981516172999932, -7.941168953999977],
            [-40.004408672999944, -7.951511704999973],
            [-40.039932406999981, -7.967162274999964],
            [-40.078219674999957, -7.984169404999932],
            [-40.108166882999967, -7.998132270999974],
            [-40.138795798999979, -8.011855702999981],
            [-40.153230371999939, -8.017927931999964],
            [-40.205941091999932, -8.042178107999973],
            [-40.233288517999938, -8.05452302599997],
            [-40.253674141999966, -8.063815674999944],
            [-40.25682558699998, -8.065248844999928],
            [-40.30469729999993, -8.087088089999952],
            [-40.338646539999957, -8.102053502999979],
            [-40.369655009999974, -8.11588186299997],
            [-40.42496110899998, -8.140075324999941],
            [-40.434636144999956, -8.144672789999959],
            [-40.461305143999937, -8.157307727999978],
            [-40.473858892999942, -8.162888841999973],
            [-40.489288876999979, -8.171496365999928],
            [-40.505873364999957, -8.179286929999932],
            [-40.53259335499996, -8.194933375999938],
            [-40.545408119999934, -8.200282285999947],
            [-40.56930542799995, -8.209276647999957],
            [-40.633272477999981, -8.234719629999972],
            [-40.644924103999983, -8.240082339999958],
            [-40.732334142999946, -8.298285125999939],
            [-40.762690543999952, -8.312323253999978],
            [-40.822314723999966, -8.339883610999948],
            [-40.83026498299995, -8.343549345999975],
            [-40.867707220999932, -8.361100136999937],
            [-40.892451901999948, -8.372660282999959],
            [-40.957133055999975, -8.402955894999934],
            [-40.969115612999985, -8.408837699999935],
            [-40.999637434999954, -8.423842581999963],
            [-41.040440590999935, -8.434765893999952],
            [-41.072984002999931, -8.443438552999964],
            [-41.080539735999935, -8.446007484999939],
            [-41.10005354499998, -8.452822353999977],
            [-41.128887162999945, -8.468565679999926],
            [-41.133563644999981, -8.470979953999972],
            [-41.168231827999932, -8.488877698999943],
            [-41.196158634999961, -8.503885687999968],
            [-41.303228753999974, -8.563098354999966],
            [-41.326775727999973, -8.579409679999969],
            [-41.415704988999948, -8.594328424999958],
            [-41.423045626999965, -8.594030991999944],
            [-41.424566794999976, -8.591419156999962],
            [-41.424996784999962, -8.589878831999954],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Milagres - Banabuiú C3',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 233.11186,
        Ano_Opera: 0.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2021-10-18',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-10-18',
        Shape_Leng: 2.03986,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.908891938746102, -6.289108792304777],
            [-38.907237001999931, -6.293392771999947],
            [-38.901831835999985, -6.308913232999942],
            [-38.898855984999955, -6.318081259999929],
            [-38.89663046499993, -6.324742577999928],
            [-38.892047622999939, -6.338305505999926],
            [-38.890006045999939, -6.344294608999974],
            [-38.88751474999998, -6.351640004999979],
            [-38.884973485999978, -6.359178137999947],
            [-38.882596403999969, -6.366188029999932],
            [-38.880119384999944, -6.373604809999961],
            [-38.878848752999943, -6.377466674999937],
            [-38.87643597999994, -6.384533673999954],
            [-38.872445622999976, -6.396411942999976],
            [-38.870175617999962, -6.402929286999949],
            [-38.868889333999959, -6.406725221999977],
            [-38.867775199999983, -6.410144957999933],
            [-38.867743503999975, -6.410242246999928],
            [-38.866364542999975, -6.414303807999943],
            [-38.86507011599997, -6.418177570999944],
            [-38.863866108999957, -6.421765797999967],
            [-38.862919083999941, -6.424678257999972],
            [-38.862838181999962, -6.428585332999944],
            [-38.862795351999978, -6.43119322299998],
            [-38.862685895999959, -6.438284015999955],
            [-38.862609927999983, -6.443345000999955],
            [-38.862520697999969, -6.450542870999925],
            [-38.862419570999975, -6.456104860999972],
            [-38.862312494999969, -6.463963030999935],
            [-38.862282751999942, -6.469620198999962],
            [-38.862181624999948, -6.477984004999939],
            [-38.86210429199997, -6.484795211999938],
            [-38.862015062999944, -6.493640857999935],
            [-38.860367285999985, -6.498417625999934],
            [-38.860343490999981, -6.503283623999948],
            [-38.860367285999985, -6.510981179999931],
            [-38.860317025999962, -6.517831156999932],
            [-38.860343490999981, -6.526209730999938],
            [-38.860331593999945, -6.533675288999973],
            [-38.860319696999966, -6.537184993999972],
            [-38.859623703999944, -6.540914799999939],
            [-38.85862433099993, -6.545893821999925],
            [-38.857654699999955, -6.551003713999933],
            [-38.856970604999958, -6.55449557299994],
            [-38.855447749999939, -6.561961131999965],
            [-38.854632784999978, -6.566244161999975],
            [-38.853401413999961, -6.572650860999943],
            [-38.852122452999936, -6.579075404999969],
            [-38.851563279999937, -6.581936706999954],
            [-38.852193836999959, -6.589301138999929],
            [-38.853074237999977, -6.599467385999958],
            [-38.852806548999979, -6.601341211999966],
            [-38.85179527799994, -6.608473645999936],
            [-38.851033849999965, -6.614071327999966],
            [-38.850349754999968, -6.618883787999948],
            [-38.849225459999957, -6.626902571999949],
            [-38.847750355999949, -6.637441535999926],
            [-38.846633696999959, -6.645258209999952],
            [-38.845343573999969, -6.654366901999936],
            [-38.844584414999929, -6.65948197299997],
            [-38.844069871999977, -6.662995762999969],
            [-38.842967476999945, -6.670309888999952],
            [-38.841414878999956, -6.681380330999957],
            [-38.84031437799996, -6.688798300999963],
            [-38.838902494999957, -6.698720124999966],
            [-38.838309681999931, -6.702664609999943],
            [-38.837256770999943, -6.709910068999932],
            [-38.835216382999931, -6.72427606499997],
            [-38.833294967999962, -6.737119204999942],
            [-38.831504423999945, -6.749004612999954],
            [-38.830506367999931, -6.755714702999967],
            [-38.829283868999937, -6.76400506899995],
            [-38.828018390999944, -6.772382186999948],
            [-38.825308578999966, -6.790453954999975],
            [-38.824045783999964, -6.798611094999956],
            [-38.820552954999982, -6.822202781999977],
            [-38.819575507999957, -6.828958690999968],
            [-38.818429285999969, -6.836324882999975],
            [-38.81724550399997, -6.844629201999965],
            [-38.815014758999951, -6.859750676999965],
            [-38.81652454999994, -6.873221052999952],
            [-38.81718006899996, -6.878780415999927],
            [-38.820428288999949, -6.906359476999967],
            [-38.821482471999957, -6.915299886999946],
            [-38.82248573499993, -6.923706791999962],
            [-38.823834936999958, -6.935377734999975],
            [-38.824623113999962, -6.942473341999971],
            [-38.825513877999981, -6.949447493999969],
            [-38.82646462699995, -6.957408390999944],
            [-38.828910653999969, -6.978245279999953],
            [-38.829612751999946, -6.983803876999957],
            [-38.830790585999978, -6.990454469999975],
            [-38.832376505999946, -6.999195554999972],
            [-38.833205737999947, -7.003719964999959],
            [-38.83412777999996, -7.008829857999956],
            [-38.836665474999961, -7.022684268999967],
            [-38.838607114999945, -7.033325218999948],
            [-38.840103838999937, -7.041780133999964],
            [-38.842019261999951, -7.052237174999959],
            [-38.845136355999955, -7.069464472999925],
            [-38.846535478999954, -7.07728814099994],
            [-38.84770638699996, -7.083696349999968],
            [-38.849169555999936, -7.091751777999946],
            [-38.851346748999958, -7.10386196099995],
            [-38.85338356799997, -7.115026391999947],
            [-38.854644681999957, -7.12213146299996],
            [-38.855377555999951, -7.126081367999973],
            [-38.856034286999943, -7.129893263999975],
            [-38.859114896999984, -7.13583164299996],
            [-38.863947217999964, -7.145241037999938],
            [-38.869034549999981, -7.155035851999969],
            [-38.875509920999946, -7.167644242999927],
            [-38.879219658999943, -7.174882298999933],
            [-38.884535470999936, -7.185253803999956],
            [-38.888572210999939, -7.192979480999952],
            [-38.890093891999982, -7.195989931999975],
            [-38.894538724999961, -7.204665446999968],
            [-38.898621879999951, -7.212455801999965],
            [-38.904384934999939, -7.223663063999936],
            [-38.911666085999968, -7.237640017999979],
            [-38.91652970399997, -7.24702937099994],
            [-38.920179796999946, -7.254082092999965],
            [-38.925057691999939, -7.263485722999974],
            [-38.928074848999984, -7.269263054999954],
            [-38.932138967999947, -7.277115275999961],
            [-38.934723062999979, -7.282002688999967],
            [-38.938235146999943, -7.288865053999928],
            [-38.940054438999937, -7.292338258999962],
            [-38.940208349999978, -7.294727437999939],
            [-38.94071377399996, -7.303008113999965],
            [-38.940889737999953, -7.305817097999977],
            [-38.941103888999976, -7.306383409999967],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Milagres - Banabuiú C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 233.01255,
        Ano_Opera: 0.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2021-10-18',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-10-18',
        Shape_Leng: 2.03896,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.909210922591946, -6.289108792304777],
            [-38.908865653999953, -6.289964375999944],
            [-38.907280932999981, -6.294575770999927],
            [-38.905171985999971, -6.300469316999965],
            [-38.900968316999979, -6.312784977999968],
            [-38.898486444999946, -6.320352471999968],
            [-38.893945629999962, -6.333579913999927],
            [-38.886522454999977, -6.355712538999967],
            [-38.882958627999983, -6.366178850999972],
            [-38.880454888999964, -6.373719023999968],
            [-38.878855890999944, -6.378544570999964],
            [-38.876978496999982, -6.384126785999968],
            [-38.875571502999946, -6.387930593999954],
            [-38.872938171999976, -6.396090715999946],
            [-38.870511121999982, -6.403107746999979],
            [-38.869254765999983, -6.406926781999971],
            [-38.868087127999956, -6.410173721999968],
            [-38.865936413999975, -6.41640911099995],
            [-38.863173499999959, -6.424751410999932],
            [-38.863057493999975, -6.431166918999963],
            [-38.862995225999953, -6.438402989999929],
            [-38.862835976999975, -6.450798662999944],
            [-38.862740797999948, -6.459085135999942],
            [-38.862574235999944, -6.469881939999937],
            [-38.862550440999939, -6.473927023999977],
            [-38.862544492999973, -6.477734160999944],
            [-38.862461211999971, -6.482070728999929],
            [-38.862330340999961, -6.491558829999974],
            [-38.86223516299998, -6.502379428999973],
            [-38.862141505999944, -6.510862630999952],
            [-38.862048952999942, -6.516315228999929],
            [-38.862069744999985, -6.527614891999974],
            [-38.861991288999945, -6.535680886999955],
            [-38.861499368999944, -6.541100855999957],
            [-38.860736994999968, -6.54657409899994],
            [-38.859601213999952, -6.554641186999959],
            [-38.859058483999945, -6.558630840999967],
            [-38.858425284999953, -6.563335192999944],
            [-38.856198782999968, -6.579749144999937],
            [-38.85497315799995, -6.587737448999974],
            [-38.853833202999965, -6.59596425999996],
            [-38.853166100999943, -6.601224251999952],
            [-38.85259220599994, -6.605421064999973],
            [-38.851591099999951, -6.612428137999927],
            [-38.850829253999962, -6.617793769999935],
            [-38.850065534999942, -6.623557699999935],
            [-38.849332094999966, -6.628370878999931],
            [-38.848596477999934, -6.633979617999955],
            [-38.847867408999946, -6.638908472999958],
            [-38.847054105999973, -6.644502019999948],
            [-38.846135336999964, -6.650810901999932],
            [-38.845250484999951, -6.65700007199996],
            [-38.844836929999929, -6.659946436999974],
            [-38.844363938999948, -6.663120862999961],
            [-38.84352619699996, -6.669166445999963],
            [-38.843099567999957, -6.671965911999962],
            [-38.841844633999983, -6.680531117999976],
            [-38.841461435999975, -6.683084080999947],
            [-38.839941524999972, -6.693902926999954],
            [-38.838624422999942, -6.702789095999947],
            [-38.83782840899994, -6.70807988599995],
            [-38.836963460999982, -6.713988072999939],
            [-38.835530817999938, -6.724161592999963],
            [-38.833577953999963, -6.737324288999957],
            [-38.832440403999954, -6.744717311999977],
            [-38.830854321999936, -6.755293294999944],
            [-38.829577817999962, -6.764098621999949],
            [-38.826975721999929, -6.781393304999938],
            [-38.824740489999954, -6.796600074999958],
            [-38.822703960999945, -6.810054],
            [-38.821763319999945, -6.816457521999951],
            [-38.820552339999949, -6.824463053999978],
            [-38.820138564999979, -6.827368718999935],
            [-38.819378855999958, -6.832384307999973],
            [-38.818247205999967, -6.839908201999947],
            [-38.817509525999981, -6.84474741899993],
            [-38.816769300999965, -6.850127952999969],
            [-38.816166195999983, -6.85440555699995],
            [-38.815378095999961, -6.859680584999978],
            [-38.816073778999964, -6.865906416999962],
            [-38.817395921999946, -6.876746709999964],
            [-38.818424289999939, -6.885891332999961],
            [-38.819003266999971, -6.890989979999972],
            [-38.820457955999984, -6.903542106999964],
            [-38.821400180999944, -6.911783576999937],
            [-38.822567182999933, -6.921271363999949],
            [-38.823347668999929, -6.927335951999964],
            [-38.823927763999961, -6.932599599999946],
            [-38.824496971999963, -6.937828673999945],
            [-38.825114141999961, -6.942796847999944],
            [-38.825887609999938, -6.949431427999968],
            [-38.826356877999956, -6.953263162999974],
            [-38.826850977999982, -6.957375301999946],
            [-38.82734675699993, -6.961897414999953],
            [-38.827812770999969, -6.965721406999933],
            [-38.828633922999984, -6.972522811999966],
            [-38.829219441999953, -6.977559953999958],
            [-38.829981568999983, -6.983791978999932],
            [-38.831224262999967, -6.990665813999954],
            [-38.832875791999982, -6.99988940399993],
            [-38.834344709999982, -7.008156568999937],
            [-38.836606165999967, -7.020497729999931],
            [-38.837559268999939, -7.025792588999934],
            [-38.838053281999976, -7.028595775999975],
            [-38.839585660999944, -7.036999082999955],
            [-38.84101725499994, -7.045009318999973],
            [-38.842083880999951, -7.050775590999933],
            [-38.842977191999978, -7.055624042999966],
            [-38.84371162399998, -7.059861222999928],
            [-38.84463061799994, -7.064816834999931],
            [-38.845525218999967, -7.069623369999931],
            [-38.846811618999936, -7.07700329499994],
            [-38.848074105999956, -7.084019993999959],
            [-38.849467347999962, -7.091515800999957],
            [-38.851699316999941, -7.104168175999973],
            [-38.853797593999957, -7.115349998999932],
            [-38.85458641699995, -7.120166267999934],
            [-38.856381688999932, -7.12980760399995],
            [-38.860774187999937, -7.138328266999963],
            [-38.864150540999958, -7.144931762999931],
            [-38.86730739799998, -7.151034664999941],
            [-38.868760983999948, -7.153829068999926],
            [-38.872757451999973, -7.161666871999955],
            [-38.875779054999953, -7.167403087999958],
            [-38.879866968999977, -7.175407594999967],
            [-38.883887012999935, -7.183180917999948],
            [-38.886499887999946, -7.188220359999946],
            [-38.89027441099995, -7.195628598999974],
            [-38.893192276999969, -7.201244349999968],
            [-38.897666016999949, -7.20984472799995],
            [-38.902214593999929, -7.218594535999955],
            [-38.904651336999962, -7.22331861899994],
            [-38.909227088999955, -7.232115575999956],
            [-38.911985702999971, -7.237472542999967],
            [-38.913785122999968, -7.240944328999944],
            [-38.916958486999931, -7.247027774999935],
            [-38.919665583999972, -7.252240520999976],
            [-38.921915424999952, -7.256731767999952],
            [-38.925241158999938, -7.263068631999943],
            [-38.929337545999942, -7.270996310999976],
            [-38.932305809999946, -7.276710936999962],
            [-38.935398480999936, -7.282652704999975],
            [-38.936704428999974, -7.285184870999956],
            [-38.938564558999985, -7.288886597999976],
            [-38.940371902999971, -7.292267683999967],
            [-38.940442164999979, -7.293942528999935],
            [-38.940763704999938, -7.299191226999937],
            [-38.940879808999966, -7.301014367999926],
            [-38.941194308999968, -7.305788543999938],
            [-38.941398941999978, -7.306345337999971],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Banabuiú - Icó C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 128.95999,
        Ano_Opera: 2012.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2021-10-18',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-10-18',
        Shape_Leng: 1.12819,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.909045207707997, -6.289108792304777],
            [-38.907445114999973, -6.293276584999944],
            [-38.899021822999941, -6.318075327999964],
            [-38.896751817999984, -6.324756854999976],
            [-38.892140421999954, -6.338391165999951],
            [-38.89015595199993, -6.344308885999965],
            [-38.887678932999961, -6.351689973999953],
            [-38.885130529999969, -6.359220967999931],
            [-38.882724894999967, -6.366216582999925],
            [-38.880247875999942, -6.373619085999962],
            [-38.878955828999949, -6.377509504999978],
            [-38.876564469999948, -6.384576503999938],
            [-38.872574113999974, -6.396454773999949],
            [-38.870311246999961, -6.402957840999932],
            [-38.869062029999952, -6.406691214999967],
            [-38.867849693999972, -6.410201514999926],
            [-38.867935444999944, -6.410232909999934],
            [-38.870975638999937, -6.410746521999954],
            [-38.875012308999942, -6.410310864999929],
            [-38.877900477999958, -6.409844337999971],
            [-38.880179693999935, -6.409397359999957],
            [-38.880777642999931, -6.409288244999971],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Paulo Afonso III - Santana II, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 107.66637,
        Ano_Opera: 2021.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2021-10-18',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-10-18',
        Shape_Leng: 0.97438,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.195466708999959, -9.401241898999956],
            [-38.19456391999995, -9.401700777999963],
            [-38.192597002999946, -9.402660131999937],
            [-38.189837609999984, -9.401928088999966],
            [-38.187983728999939, -9.400084638999942],
            [-38.183020985999974, -9.395180352999944],
            [-38.179060916999958, -9.393565726999952],
            [-38.167033439999955, -9.389494415999934],
            [-38.143575375999944, -9.381586471999981],
            [-38.124496973999953, -9.375130684999931],
            [-38.105323085999942, -9.368638449999935],
            [-38.084635193999929, -9.361634051999943],
            [-38.070639719999974, -9.356908898999961],
            [-38.055976552999937, -9.351975502999949],
            [-37.982119814999976, -9.326960886999927],
            [-37.96528380999996, -9.321288362999951],
            [-37.959065886999952, -9.319164570999931],
            [-37.95519904899993, -9.318454603999953],
            [-37.945318787999952, -9.31690985399996],
            [-37.923794876999978, -9.313604538999925],
            [-37.910703294999962, -9.311545336999927],
            [-37.898163106999959, -9.309591498999964],
            [-37.881569672999944, -9.307013367999957],
            [-37.86486899099998, -9.304410563999966],
            [-37.848711965999939, -9.301887154999974],
            [-37.832867265999937, -9.299410592999948],
            [-37.816682231999948, -9.29689781899998],
            [-37.799381724999932, -9.294252446999963],
            [-37.784262213999966, -9.291835474999971],
            [-37.770764901999939, -9.289731897999957],
            [-37.75580769499993, -9.28740692599996],
            [-37.737879751999969, -9.284591874999933],
            [-37.721462848999977, -9.282022233999953],
            [-37.706037486999946, -9.279622395999979],
            [-37.678708644999972, -9.275362618999964],
            [-37.655940253999972, -9.271787050999933],
            [-37.619324786999982, -9.266065972999968],
            [-37.585017850999975, -9.260695287999965],
            [-37.569280468999978, -9.258222907999937],
            [-37.550039582999943, -9.255191924999963],
            [-37.520531134999942, -9.250592694999966],
            [-37.499944647999939, -9.247339787999977],
            [-37.486313816999939, -9.245203702999959],
            [-37.453510214999937, -9.240058906999934],
            [-37.435165475999952, -9.237169966999943],
            [-37.434928428999967, -9.237808880999978],
            [-37.434534092999968, -9.238269096999943],
            [-37.433101065999949, -9.240958318999958],
            [-37.433641798999929, -9.249556869999935],
            [-37.37485645299995, -9.294136864999928],
            [-37.344347496999944, -9.305713080999965],
            [-37.332421926999984, -9.313824028999932],
            [-37.31511255199996, -9.335326091999946],
            [-37.304880151999953, -9.341241013999934],
            [-37.293854669999973, -9.35072987999996],
            [-37.29118283899993, -9.352995825999926],
            [-37.290620378999961, -9.353396230999977],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Santana II - Angelim, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 156.99758,
        Ano_Opera: 2021.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2021-10-18',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-10-18',
        Shape_Leng: 1.41663,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.295016526999973, -8.893826128999933],
            [-36.295465348999983, -8.89367512299998],
            [-36.29745653699996, -8.892937788999973],
            [-36.306521176999979, -8.892505891999974],
            [-36.314887675999955, -8.892141245999937],
            [-36.320109284999944, -8.891882794999958],
            [-36.326923325999928, -8.891545299999962],
            [-36.335702760999936, -8.891127306999977],
            [-36.345352944999945, -8.89064377099993],
            [-36.362320942999929, -8.890452240999934],
            [-36.376371277999965, -8.890259814999979],
            [-36.394550331999937, -8.89003022299994],
            [-36.402834216999963, -8.889947896999956],
            [-36.403627470999936, -8.88992723399997],
            [-36.404618636999942, -8.889930162999974],
            [-36.412265410999964, -8.889854167999943],
            [-36.428450483999939, -8.88967635299997],
            [-36.441404902999977, -8.889500625999972],
            [-36.448724102999961, -8.894690600999979],
            [-36.461126586999967, -8.903515889999937],
            [-36.473645017999957, -8.912420415999975],
            [-36.485532400999944, -8.920831387999954],
            [-36.500293726999985, -8.931291668999961],
            [-36.51155891999997, -8.939282915999968],
            [-36.533759195999949, -8.955019078999953],
            [-36.549763611999936, -8.96634456299995],
            [-36.560455690999959, -8.97390545199994],
            [-36.583372315999952, -8.990124446999971],
            [-36.595590150999953, -8.99877743899998],
            [-36.612415638999948, -9.010666593999929],
            [-36.629884712999967, -9.023038837999934],
            [-36.645040307999977, -9.033830170999977],
            [-36.669476805999977, -9.051009011999952],
            [-36.681220038999982, -9.059320584999966],
            [-36.693384274999971, -9.067928332999941],
            [-36.705275815999983, -9.076348522999979],
            [-36.730641710999976, -9.094295004999935],
            [-36.748038686999962, -9.106656224999938],
            [-36.76021389999994, -9.108173112999964],
            [-36.776586655999949, -9.110240338999972],
            [-36.789182358999938, -9.111851196999964],
            [-36.798858302999975, -9.113038200999938],
            [-36.810106461999965, -9.114479532999951],
            [-36.818624609999972, -9.115541613999937],
            [-36.827609810999945, -9.116670428999953],
            [-36.838655470999981, -9.11803679999997],
            [-36.849606981999955, -9.119413957999939],
            [-36.859662139999955, -9.12066787699996],
            [-36.868676334999975, -9.121807405999959],
            [-36.87954618599997, -9.12316736899993],
            [-36.891592325999966, -9.124683173999927],
            [-36.905120615999977, -9.126393058999952],
            [-36.915615129999935, -9.127698404999933],
            [-36.929194263999932, -9.129391660999943],
            [-36.934616511999934, -9.130073900999946],
            [-36.949313158999985, -9.131899269999963],
            [-36.962069846999952, -9.133518943999945],
            [-36.976198145999945, -9.135339031999933],
            [-36.987514620999946, -9.136701419999952],
            [-36.997981722999953, -9.138026760999935],
            [-37.007561433999967, -9.139223953999931],
            [-37.018751599999973, -9.140621],
            [-37.03366779299995, -9.14249450899996],
            [-37.048948797999969, -9.14436528799996],
            [-37.066414657999985, -9.146585664999975],
            [-37.07754048299995, -9.147961104999979],
            [-37.087092664999943, -9.149181228999964],
            [-37.092948603999957, -9.149909900999944],
            [-37.115942004999965, -9.158321066999974],
            [-37.138891915999977, -9.166675755999961],
            [-37.152423984999984, -9.171604538999929],
            [-37.173353753999947, -9.179221448999954],
            [-37.183566657999961, -9.182959758999971],
            [-37.205863964999935, -9.191077337999957],
            [-37.227013001999978, -9.199099947999969],
            [-37.236799105999978, -9.20281023299998],
            [-37.25109703399994, -9.208243399999958],
            [-37.257743242999936, -9.20928807699994],
            [-37.276340736999941, -9.212216780999938],
            [-37.296946098999967, -9.215462592999927],
            [-37.310489421999932, -9.217580496999972],
            [-37.323093803999939, -9.219585557999949],
            [-37.334498543999985, -9.221401600999968],
            [-37.353703996999968, -9.224371814999927],
            [-37.367257269999982, -9.226512795999952],
            [-37.375080791999949, -9.227743655999973],
            [-37.382861832999936, -9.22896265299994],
            [-37.394283779999967, -9.23077049699998],
            [-37.41316838399996, -9.233713861999945],
            [-37.42483985399997, -9.235569337999948],
            [-37.435071720999929, -9.237135181999975],
            [-37.434840880999957, -9.237757364999936],
            [-37.43445100699995, -9.238212373999943],
            [-37.432999483999936, -9.24093630699997],
            [-37.433538628999941, -9.249509605999947],
            [-37.374807506999957, -9.294048479999958],
            [-37.344301002999941, -9.30562376599994],
            [-37.332353370999954, -9.313749718999929],
            [-37.315046353999946, -9.335248852999939],
            [-37.304822008999963, -9.34115911799995],
            [-37.293789712999967, -9.350653848999968],
            [-37.291121390999933, -9.352916817999926],
            [-37.29056238499993, -9.353314764999936],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Santa Luzia II - Milagres II, C1',
        Concession:
          'SANTA LUZIA - NEOENERGIA SANTA LUZIA TRANSMISSÃO DE ENERGIA S/A',
        Tensao: 500.0,
        Extensao: 222.82804,
        Ano_Opera: 2021.0,
        created_us: 'GISUSER',
        created_da: '2021-11-09',
        last_edite: 'GISUSER',
        last_edi_1: '2021-11-09',
        Shape_Leng: 2.01176,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.986020358999951, -6.912489643999947],
            [-37.033332470999937, -6.917671415999962],
            [-37.036937353999974, -6.916410300999928],
            [-37.097491661999982, -6.916731528999946],
            [-37.148872553999979, -6.930915495999955],
            [-37.221533677999957, -6.950436593999939],
            [-37.302460435999933, -6.980964879999931],
            [-37.375334998999961, -6.986968259999969],
            [-37.557313320999981, -7.021495190999929],
            [-37.652234807999946, -7.045620964999955],
            [-37.741310723999959, -7.109301685999981],
            [-37.863996997999948, -7.176563628999929],
            [-38.044596184999932, -7.203590339999948],
            [-38.227913826999952, -7.235044434999963],
            [-38.296694970999965, -7.238999916999944],
            [-38.382992072999969, -7.26991308099997],
            [-38.469872539999983, -7.284175569999945],
            [-38.635259901999973, -7.301255638999976],
            [-38.691451666999967, -7.312796024999955],
            [-38.798354801999949, -7.33525044299995],
            [-38.819219105999935, -7.338153384999941],
            [-38.884868592999965, -7.33940688499996],
            [-38.922820995999984, -7.339234374999933],
            [-38.924427131999948, -7.341316402999951],
            [-38.923951239999951, -7.342922538999971],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Açu III - Milagres II, C2',
        Concession:
          'GIOVANNI - GIOVANNI SANGUINETTI TRANSMISSORA DE ENERGIA S A',
        Tensao: 500.0,
        Extensao: 298.03727,
        Ano_Opera: 2020.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2021-12-03',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2021-12-03',
        Shape_Leng: 2.64703,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-37.852184921595189, -6.289108792304777],
            [-37.865558420999946, -6.298268768999947],
            [-37.89153758499998, -6.31678261199994],
            [-37.934355957999969, -6.344189596999968],
            [-37.952143133999982, -6.355764532999956],
            [-37.961716189999947, -6.40755459899998],
            [-37.963905434999958, -6.426560048999931],
            [-38.037404937999952, -6.486485517999938],
            [-38.039435774999959, -6.486755006999942],
            [-38.044778746999953, -6.492421406999938],
            [-38.104763404999971, -6.540272519999974],
            [-38.16379399799996, -6.58600305899995],
            [-38.179226896999978, -6.59711522799995],
            [-38.186516983999979, -6.60283500099996],
            [-38.217910675999974, -6.628143156999954],
            [-38.231242825999971, -6.63691367399997],
            [-38.276793689999977, -6.678299912999933],
            [-38.33039538099996, -6.72978162499993],
            [-38.350471660999972, -6.746823509999956],
            [-38.359258039999929, -6.756574839999928],
            [-38.374881915999936, -6.772306183999945],
            [-38.37565916099993, -6.77391548099996],
            [-38.442569823999975, -6.835855716999959],
            [-38.46629784299995, -6.859201448999954],
            [-38.491505874999973, -6.884584037999957],
            [-38.505547583999942, -6.89690901299997],
            [-38.56375672799993, -6.938338386999931],
            [-38.581352558999981, -6.953715410999962],
            [-38.607636082999932, -6.974990996999963],
            [-38.623973173999957, -6.990542404999928],
            [-38.727395080999941, -7.088043838999965],
            [-38.789468863999957, -7.149772383999959],
            [-38.821389395999972, -7.180779056999938],
            [-38.846850358999973, -7.202436445999979],
            [-38.850581558999977, -7.206028494999941],
            [-38.875148838999962, -7.235211230999937],
            [-38.903316941999947, -7.268593858999964],
            [-38.91260370599997, -7.27787580599994],
            [-38.91627826499996, -7.30302891499997],
            [-38.916888964999941, -7.306851643999948],
            [-38.918313308999984, -7.312612896999951],
            [-38.924525471999971, -7.342475986999943],
            [-38.92412117799995, -7.343513645999963],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Curral Novo do Piauí II - Queimada Nova II, C1',
        Concession: 'SERTANEJA - TRANSMISSORA SERTANEJA DE ELETRICIDADE S A',
        Tensao: 500.0,
        Extensao: 110.5371,
        Ano_Opera: 2021.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2022-04-08',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2022-04-08',
        Shape_Leng: 0.99333,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.637615637999943, -8.035190645999933],
            [-40.636956299999952, -8.03505628299996],
            [-40.636498177999954, -8.03540213499997],
            [-40.636660760999973, -8.038120155999934],
            [-40.635517353999944, -8.046281457999953],
            [-40.64599699799993, -8.070043418999944],
            [-40.677509010999984, -8.105949616999965],
            [-40.805537971999968, -8.21634136299997],
            [-40.907688148999966, -8.319468815999926],
            [-40.958884052999963, -8.351607552999951],
            [-41.181917626999962, -8.494962751999935],
            [-41.239425984999968, -8.523065878999944],
            [-41.340701070999955, -8.580136249999953],
            [-41.415426436999951, -8.593306051999946],
            [-41.42512461299998, -8.590726031999964],
            [-41.425351564999971, -8.589319758999977],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Chapada I - Chapada II, C1',
        Concession: 'SIMOES - SIMÕES TRANSMISSORA DE ENERGIA ELÉTRICA S.A.',
        Tensao: 230.0,
        Extensao: 12.29445,
        Ano_Opera: 2022.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2022-05-10',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2022-05-10',
        Shape_Leng: 0.10908,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.714797071999953, -7.562037807999957],
            [-40.71485088999998, -7.560774093999953],
            [-40.718150854999976, -7.559103749999963],
            [-40.72010322899996, -7.548313732999929],
            [-40.724860729999932, -7.508355854999934],
            [-40.751992918999974, -7.474456176999979],
            [-40.754643258999977, -7.473526740999944],
            [-40.759414611999944, -7.474460274999956],
            [-40.759447535999982, -7.475510871999973],
            [-40.758753817999946, -7.475835067999981],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Chapada II - Chapada III, C1',
        Concession: 'SIMOES - SIMÕES TRANSMISSORA DE ENERGIA ELÉTRICA S.A.',
        Tensao: 230.0,
        Extensao: 14.79548,
        Ano_Opera: 2022.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2022-05-10',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2022-05-10',
        Shape_Leng: 0.13186,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.758777671999951, -7.475955316999944],
            [-40.759816932999968, -7.47572865799998],
            [-40.759767712999974, -7.474160764999965],
            [-40.754616128999942, -7.47315288599998],
            [-40.743890467999961, -7.477139486999931],
            [-40.71662803199996, -7.46756708199996],
            [-40.690165665999984, -7.408496122999964],
            [-40.684776468999985, -7.391858661999947],
            [-40.683502548999968, -7.391226733999929],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Campina Grande III - João Pessoa II, C1',
        Concession: 'Borborema Transmissão de Energia S.A',
        Tensao: 500.0,
        Extensao: 122.66298,
        Ano_Opera: 2022.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2022-07-22',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2022-07-22',
        Shape_Leng: 1.10606,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.980534496999951, -7.255561221999926],
            [-35.966709960999935, -7.294349237999938],
            [-35.900743399999953, -7.321084980999956],
            [-35.801504104999935, -7.322610937999968],
            [-35.757105637999985, -7.32382369499993],
            [-35.562237189999962, -7.311047575999964],
            [-35.519533337999974, -7.295481232999975],
            [-35.483835393999982, -7.284787368999957],
            [-35.458061886999928, -7.275976975999981],
            [-35.411976109999955, -7.26394109499995],
            [-35.363010777999932, -7.260954397999967],
            [-35.265378136999971, -7.235238462999973],
            [-35.237151988999983, -7.229803768999943],
            [-35.200187147999941, -7.219025209999927],
            [-35.19142420999998, -7.219526579999979],
            [-35.175732013999948, -7.216387211999972],
            [-35.130735196999979, -7.237362492999978],
            [-35.075599810999961, -7.236004155999979],
            [-35.011863989999938, -7.233005134999928],
            [-34.952945334999981, -7.213043650999964],
            [-34.929150487999948, -7.217979146999937],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Ventos do Piauí II - Curral Novo do Piauí II, C1',
        Concession: null,
        Tensao: 230.0,
        Extensao: 4.63457,
        Ano_Opera: 2022.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2022-09-14',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2022-09-14',
        Shape_Leng: 0.04162,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.638317843999971, -8.031854017999933],
            [-40.638471318999962, -8.030940304999945],
            [-40.638521287999936, -8.029780317999951],
            [-40.639613460999954, -8.027913630999933],
            [-40.641362839999942, -8.027916723999965],
            [-40.644567973999983, -8.034172326999965],
            [-40.649570394999955, -8.036760387999948],
            [-40.666301375999979, -8.043893416999936],
            [-40.670670066999946, -8.041973191999944],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Queimada Nova II - Oitis, C1',
        Concession: null,
        Tensao: 500.0,
        Extensao: 64.8744,
        Ano_Opera: 2022.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2022-09-14',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2022-09-14',
        Shape_Leng: 0.57923,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-41.735923514999968, -9.040338793999979],
            [-41.73755319299994, -9.040233393999927],
            [-41.743720425999982, -9.037797681999962],
            [-41.74475328099993, -9.032240282999965],
            [-41.731334302999983, -8.978626464999934],
            [-41.706746171999953, -8.922061394999957],
            [-41.679773154999964, -8.87809393799995],
            [-41.651956944999938, -8.862366792999978],
            [-41.624994980999929, -8.835824489999936],
            [-41.575783866999984, -8.796905760999948],
            [-41.569832675999976, -8.787306016999935],
            [-41.560238688999959, -8.785464313999967],
            [-41.540083209999977, -8.757598101999974],
            [-41.523447652999948, -8.744212835999974],
            [-41.515043218999949, -8.723459121999952],
            [-41.475321079999958, -8.66517465499993],
            [-41.467201953999961, -8.658537962999958],
            [-41.464474518999964, -8.635142134999967],
            [-41.436906998999973, -8.597455722999939],
            [-41.430374916999938, -8.594548307999958],
            [-41.426317008999945, -8.590467273999934],
            [-41.426555638999957, -8.589611727999966],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Ventos do Piauí III - Curral Novo do Piauí II, C1',
        Concession: null,
        Tensao: 230.0,
        Extensao: 9.93894,
        Ano_Opera: 2022.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2022-09-14',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2022-09-14',
        Shape_Leng: 0.08906,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.638048149999975, -8.031809958999929],
            [-40.638162364999971, -8.031174642999929],
            [-40.637726922999946, -8.030475080999963],
            [-40.632363617999943, -8.028418036999938],
            [-40.612959113999977, -8.025725378999937],
            [-40.608586853999952, -8.027902585999925],
            [-40.605059302999962, -8.029990562999956],
            [-40.599465090999956, -8.028388888999928],
            [-40.59118763999993, -8.01496753899994],
            [-40.591537420999941, -8.011748127999965],
            [-40.585655987999985, -8.004256989999931],
            [-40.580303786999934, -8.002433132999954],
            [-40.574048183999935, -7.990732133999927],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Mata Norte - Pau Ferro, C2 (CD)',
        Concession: 'ITAMARACA - ITAMARACÁ TRANSMISSORA SPE S.A.',
        Tensao: 230.0,
        Extensao: 33.31165,
        Ano_Opera: 2022.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2022-10-20',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2022-10-20',
        Shape_Leng: 0.29046,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.958602058999929, -7.627115061999973],
            [-34.957432208999933, -7.626932028999931],
            [-34.955764129999977, -7.627968672999941],
            [-34.951797864999946, -7.629011175999949],
            [-34.942797095999936, -7.63137785999993],
            [-34.940916131999984, -7.636380828999961],
            [-34.94111825799996, -7.641174914999965],
            [-34.941304660999947, -7.645081785999935],
            [-34.941394568999954, -7.647446070999933],
            [-34.940101734999985, -7.65511442899998],
            [-34.939464620999956, -7.658934790999979],
            [-34.938946632999944, -7.662090939999928],
            [-34.938146120999932, -7.666769822999981],
            [-34.937648177999961, -7.669684255999925],
            [-34.938987021999935, -7.67214278199998],
            [-34.941302737999933, -7.676334684999972],
            [-34.943199436999976, -7.679776937999975],
            [-34.946770766999975, -7.683774826999979],
            [-34.948538975999952, -7.685212925999963],
            [-34.951504272999955, -7.687618344999976],
            [-34.953416881999942, -7.689467959999945],
            [-34.954562890999966, -7.690590649999933],
            [-34.956671881999966, -7.691978234999965],
            [-34.958984891999933, -7.693442065999932],
            [-34.959766985999977, -7.696108548999973],
            [-34.960727908999957, -7.699373066999954],
            [-34.96168172199998, -7.702595454999937],
            [-34.966099956999983, -7.70856923599996],
            [-34.966584599999976, -7.711515159999976],
            [-34.967469363999953, -7.717057143999966],
            [-34.967881232999957, -7.719551819999936],
            [-34.966860119999978, -7.721651948999977],
            [-34.964752392999969, -7.725940142999946],
            [-34.964157026999942, -7.727142886999957],
            [-34.96346730099998, -7.734873816999936],
            [-34.963156739999931, -7.738457164999943],
            [-34.967883201999939, -7.745585071999926],
            [-34.963928557999964, -7.75260382099998],
            [-34.962086065999983, -7.755869924999956],
            [-34.962352366999937, -7.759845207999945],
            [-34.962638614999946, -7.763296464999939],
            [-34.963302684999974, -7.772364846999949],
            [-34.963730218999956, -7.777879114999962],
            [-34.966931330999955, -7.780402851999952],
            [-34.971991331999959, -7.781269183999939],
            [-34.976596733999941, -7.782063834999974],
            [-34.979868215999943, -7.784310094999967],
            [-34.984206767999979, -7.787265202999947],
            [-34.988898978999941, -7.790494679999938],
            [-34.993680622999932, -7.797035153999957],
            [-34.994471841999939, -7.797569820999968],
            [-34.99630888799993, -7.799911637999969],
            [-34.997293591999949, -7.80120261899998],
            [-34.99880320699998, -7.803177388999927],
            [-35.001125113999933, -7.806007501999943],
            [-35.004559031999975, -7.808813742999973],
            [-35.015795594999929, -7.817891584999927],
            [-35.019362850999983, -7.820769475999953],
            [-35.020211054999947, -7.830499862999943],
            [-35.020413576999943, -7.832498918999931],
            [-35.02076664599997, -7.836281067999948],
            [-35.021107470999937, -7.840223068999933],
            [-35.021620480999957, -7.843209476999959],
            [-35.022590077999951, -7.848761038999953],
            [-35.02065586599997, -7.850937782999949],
            [-35.018880810999974, -7.852911784999947],
            [-35.019711359999974, -7.855063307999956],
            [-35.020627589999947, -7.857465146999971],
            [-35.02074911099993, -7.858845795999969],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Mata Norte - Pau Ferro, C1 (CD)',
        Concession: 'ITAMARACA - ITAMARACÁ TRANSMISSORA SPE S.A.',
        Tensao: 230.0,
        Extensao: 33.28432,
        Ano_Opera: 2022.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2022-10-20',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2022-10-20',
        Shape_Leng: 0.29023,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.958562551999933, -7.627273052999954],
            [-34.957439098999942, -7.627033658999949],
            [-34.955804476999958, -7.628052529999934],
            [-34.95183635099994, -7.629104049999967],
            [-34.942868639999972, -7.631461040999966],
            [-34.941009157999929, -7.636403375999976],
            [-34.941210506999937, -7.641183686999966],
            [-34.941393381999944, -7.645061509999948],
            [-34.941489243999968, -7.647483277999982],
            [-34.940203870999937, -7.655150455999944],
            [-34.939550883999971, -7.658935071999963],
            [-34.939029984999934, -7.662088267999934],
            [-34.938225986999953, -7.666794315999937],
            [-34.937731780999968, -7.669678785999963],
            [-34.939099340999974, -7.672131591999971],
            [-34.941390202999969, -7.676290030999951],
            [-34.943270581999968, -7.679701525999974],
            [-34.946835195999938, -7.683721814999956],
            [-34.951573602999929, -7.687526361999971],
            [-34.953490233999958, -7.689386310999964],
            [-34.954636411999957, -7.690516393999928],
            [-34.956730414999981, -7.691892891999942],
            [-34.959060287999932, -7.693394463999937],
            [-34.960812887999964, -7.699330490999955],
            [-34.96175655199994, -7.702555070999949],
            [-34.966165555999964, -7.708547587999931],
            [-34.966658203999941, -7.711498106999954],
            [-34.967565492999938, -7.717048175999935],
            [-34.96797619299997, -7.719574039999941],
            [-34.96692477299996, -7.721705657999962],
            [-34.96482184499996, -7.725989304999928],
            [-34.964249630999973, -7.727165831999969],
            [-34.963551756999948, -7.734878198999979],
            [-34.963249204999954, -7.738443028999939],
            [-34.967994732999955, -7.745591305999938],
            [-34.96400831699998, -7.752652998999963],
            [-34.962180856999964, -7.755901420999976],
            [-34.962730491999935, -7.763292503999935],
            [-34.96305814699997, -7.767483264999953],
            [-34.963426354999967, -7.772362684999962],
            [-34.963834464999934, -7.777833433999945],
            [-34.967009133999966, -7.78031916499998],
            [-34.976655654999945, -7.781970895999962],
            [-34.979930792999937, -7.784243379999964],
            [-34.98425408099996, -7.787194070999931],
            [-34.988977269999964, -7.790405386999964],
            [-34.993761451999944, -7.796966701999963],
            [-34.994530556999962, -7.79750704199995],
            [-34.996386389999941, -7.799844828999937],
            [-34.998875692999945, -7.803122325999936],
            [-35.001187536999964, -7.805930234999948],
            [-35.015852922999954, -7.817821600999935],
            [-35.01945730999995, -7.820715786999926],
            [-35.02033253899998, -7.830486085999951],
            [-35.02050113599995, -7.83248967399993],
            [-35.020847057999958, -7.836272063999957],
            [-35.021199801999956, -7.840226068999925],
            [-35.021717437999939, -7.843183602999943],
            [-35.022352530999967, -7.846893760999933],
            [-35.022684536999975, -7.848787459999926],
            [-35.020718263999981, -7.850982129999977],
            [-35.018979903999934, -7.852926557999979],
            [-35.019784870999956, -7.855035197999939],
            [-35.02071839499996, -7.857431841999926],
            [-35.020888720999949, -7.858786984999938],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Mata Norte - FIAT, C1 (CD)',
        Concession: null,
        Tensao: 230.0,
        Extensao: 0.43479,
        Ano_Opera: 2022.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2022-10-20',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2022-10-20',
        Shape_Leng: 0.00378,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.960870313999976, -7.624894066999957],
            [-34.960647680999955, -7.625840834999963],
            [-34.960459087999936, -7.626628665999931],
            [-34.960360733999948, -7.627070862999972],
            [-34.960230782999929, -7.627645747999964],
            [-34.959590825999953, -7.627476842999954],
            [-34.959298970999953, -7.627421664999929],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Mata Norte - FIAT, C2 (CD)',
        Concession: null,
        Tensao: 230.0,
        Extensao: 0.4039,
        Ano_Opera: 2022.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2022-10-20',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2022-10-20',
        Shape_Leng: 0.00351,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.960754088999977, -7.624832491999939],
            [-34.960505147999982, -7.625805195999931],
            [-34.960313814999949, -7.626594601999955],
            [-34.960211847999972, -7.627030187999935],
            [-34.960115564999967, -7.627476739999963],
            [-34.959629771999971, -7.627346732999968],
            [-34.95934914399993, -7.627285219999976],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Termopernambuco - M&G Polímeros, C1',
        Concession: null,
        Tensao: 230.0,
        Extensao: 6.97033,
        Ano_Opera: 0.0,
        created_us: 'GISUSER',
        created_da: '2022-10-26',
        last_edite: 'GISUSER',
        last_edi_1: '2022-10-26',
        Shape_Leng: 0.06178,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.969319581999969, -8.404316685999959],
            [-34.970017523999957, -8.403997466999954],
            [-34.971018321999964, -8.403992832999961],
            [-34.971276583999952, -8.403881982999962],
            [-34.976439960999983, -8.401622762999978],
            [-34.981019720999939, -8.399627365999947],
            [-34.982684400999972, -8.398896949999937],
            [-34.984371475999978, -8.397158502999957],
            [-34.986370873999931, -8.39774676899998],
            [-34.987699121999981, -8.398049613999945],
            [-34.992190196999957, -8.399000610999963],
            [-34.993584975999966, -8.39893694899996],
            [-34.994514778999928, -8.398990853999976],
            [-34.995269037999947, -8.398784597999963],
            [-34.997042077999936, -8.399184368999954],
            [-34.999005461999957, -8.399575039999945],
            [-35.000784585999952, -8.399928372999966],
            [-35.003059846999975, -8.400383531999978],
            [-35.004565108999941, -8.400925199999961],
            [-35.005356389999974, -8.400704791999942],
            [-35.005907226999966, -8.399594565999962],
            [-35.00647491999996, -8.399099185999944],
            [-35.007219428999974, -8.398154169999941],
            [-35.007136725999942, -8.396809248999944],
            [-35.007044200999928, -8.395140566999942],
            [-35.006656232999944, -8.393542436999951],
            [-35.006330437999964, -8.392235404999951],
            [-35.007438921999949, -8.390809873999956],
            [-35.007683680999946, -8.38929914199997],
            [-35.007643424999969, -8.386147257999937],
            [-35.007207280999978, -8.383264495999981],
            [-35.006763407999983, -8.380492043999936],
            [-35.006546348999962, -8.378586972999926],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Queimada Nova II - Buritirama, C1',
        Concession: 'SERTANEJA - TRANSMISSORA SERTANEJA DE ELETRICIDADE S A',
        Tensao: 500.0,
        Extensao: 375.30632,
        Ano_Opera: 0.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2022-11-07',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2022-11-07',
        Shape_Leng: 3.3835,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-43.213321391609078, -9.717769277372721],
            [-43.113283357999933, -9.663238341999943],
            [-42.933385034999958, -9.570997574999978],
            [-42.773725972999955, -9.519005119999974],
            [-42.647064207999961, -9.499356292999948],
            [-42.573008323999943, -9.412869585999943],
            [-42.564227604999985, -9.403283381999927],
            [-42.557812387999945, -9.395333744999959],
            [-42.555229303999965, -9.392276539999955],
            [-42.552368871999931, -9.389755830999945],
            [-42.47968806199998, -9.32933703599997],
            [-42.325940541999955, -9.19445511899994],
            [-42.232865512999979, -9.119892722999964],
            [-42.22951614599998, -9.117052456999943],
            [-42.226402723999968, -9.11414605899995],
            [-42.222794095999973, -9.110808248999945],
            [-42.21923490599994, -9.107631785999956],
            [-42.215955247999943, -9.104970582999954],
            [-42.212392671999964, -9.102246828999967],
            [-42.208637513999975, -9.099561824999967],
            [-42.202083535999975, -9.09490339499996],
            [-42.195782879999967, -9.090236781999977],
            [-42.13491847399996, -9.041410867999957],
            [-41.958383288999983, -8.905282684999975],
            [-41.707470600999955, -8.73258429599997],
          ],
          [
            [-41.425843824999959, -8.589409481999951],
            [-41.425405888999933, -8.590946622999979],
            [-41.427831684999944, -8.593823540999949],
            [-41.49201940599994, -8.591196041999979],
            [-41.540321264999932, -8.590409674999933],
            [-41.566829754999958, -8.595921055999952],
            [-41.583196739999948, -8.607721611999978],
            [-41.596508415999949, -8.613251675999948],
            [-41.70754269899993, -8.732611947999942],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Santa Luzia II - Serra do Seridó, C1',
        Concession: 'Parque Eolico Serra do Serido II S.A.',
        Tensao: 500.0,
        Extensao: 26.60955,
        Ano_Opera: 2022.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2022-12-27',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2022-12-27',
        Shape_Leng: 0.23726,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.983814807999977, -6.912310902999934],
            [-36.982089698999971, -6.912168134999945],
            [-36.975736537999978, -6.911002198999938],
            [-36.936396908999939, -6.891678595999963],
            [-36.916214320999984, -6.899265269999944],
            [-36.87373903799994, -6.921633435999979],
            [-36.86179057499993, -6.969427884999959],
            [-36.815828905999979, -6.990460733999953],
            [-36.800633667999932, -6.991350651999937],
            [-36.800081632999934, -6.991017527999929],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Goianinha - João Pessoa II, C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 47.65477,
        Ano_Opera: 2022.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-01-11',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-01-11',
        Shape_Leng: 0.41276,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.088613535999968, -7.588657574999957],
            [-35.088618975999964, -7.589253080999981],
            [-35.088137820999975, -7.589584516999935],
            [-35.087447341999962, -7.589228832999936],
            [-35.086919636999937, -7.585555080999939],
            [-35.084149606999972, -7.58003727199997],
            [-35.080986906999954, -7.573901586999966],
            [-35.077267307999932, -7.566631886999971],
            [-35.073718690999954, -7.559678267999971],
            [-35.070500721999963, -7.55338769399998],
            [-35.068025402999979, -7.548510915999941],
            [-35.065359519999959, -7.543713069999967],
            [-35.06212136399995, -7.538048665999952],
            [-35.061662493999961, -7.536132896999959],
            [-35.058359913999936, -7.53002879099995],
            [-35.056188650999957, -7.52603000199997],
            [-35.053137812999978, -7.520502568999973],
            [-35.048730328999966, -7.512622448999934],
            [-35.046305179999933, -7.508315935999974],
            [-35.04251338499995, -7.501489483999933],
            [-35.03754334599995, -7.492694135999955],
            [-35.03304710499998, -7.484681997999928],
            [-35.027825879999966, -7.475315215999956],
            [-35.019371474999957, -7.460139051999931],
            [-35.012561285999936, -7.44797508299996],
            [-35.00854802799995, -7.440664392999963],
            [-35.004489714999977, -7.433830171999944],
            [-35.00025891599995, -7.42651959799997],
            [-34.996373938999966, -7.419690088999971],
            [-34.992203838999956, -7.41235755699995],
            [-34.987279852999961, -7.403765569999962],
            [-34.983742780999933, -7.397541546999946],
            [-34.979782191999959, -7.390639655999962],
            [-34.976565301999983, -7.385006306999969],
            [-34.972131561999959, -7.377233329999967],
            [-34.968787654999971, -7.371385516999965],
            [-34.965236226999934, -7.365177366999944],
            [-34.962228531999983, -7.359913031999952],
            [-34.956752006999977, -7.350312306999967],
            [-34.952730480999946, -7.343270245999975],
            [-34.952340266999954, -7.341007133999938],
            [-34.951270726999951, -7.334821419999969],
            [-34.950441610999974, -7.330161339999961],
            [-34.949753475999955, -7.325954315999979],
            [-34.948779092999985, -7.320507626999927],
            [-34.947841325999946, -7.315032555999949],
            [-34.94644018799994, -7.306910357999925],
            [-34.944370616999947, -7.295161745999962],
            [-34.942134329999931, -7.282273040999939],
            [-34.939295905999984, -7.265930900999933],
            [-34.937842599999954, -7.262267661999942],
            [-34.935100036999984, -7.255484493999973],
            [-34.932309030999932, -7.248470675999954],
            [-34.929553905999967, -7.241495689999965],
            [-34.928011205999951, -7.233354358999975],
            [-34.926949213999933, -7.22800000999996],
            [-34.923200735999956, -7.220952009999962],
            [-34.924890170999959, -7.21922895199998],
            [-34.925954349999984, -7.21909162399993],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Goianinha - João Pessoa II, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 47.70746,
        Ano_Opera: 2022.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-01-11',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-01-11',
        Shape_Leng: 0.41323,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.088762401999929, -7.588664315999949],
            [-35.088712630999964, -7.589305710999952],
            [-35.088143783999953, -7.589681622999933],
            [-35.087371096999959, -7.589284569999961],
            [-35.086834077999981, -7.585577541999953],
            [-35.085694982999939, -7.583357300999978],
            [-35.084055719999981, -7.580081251999957],
            [-35.067920286999936, -7.548576986999933],
            [-35.062015764999956, -7.538084244999936],
            [-35.061554607999938, -7.536157316999947],
            [-35.060205282999959, -7.533642393999969],
            [-35.056093897999972, -7.526062341999932],
            [-35.053059451999957, -7.520551355999942],
            [-35.047490869999933, -7.510604579999949],
            [-35.042404397999974, -7.501522320999925],
            [-35.037443229999951, -7.492741415999944],
            [-35.035152891999928, -7.488645390999977],
            [-35.032931725999958, -7.484709583999972],
            [-35.01706246599997, -7.456293538999944],
            [-35.012442585999963, -7.448039347999952],
            [-35.008463151999933, -7.44071538299994],
            [-35.000187661999973, -7.426571978999959],
            [-34.992148283999938, -7.412427978999972],
            [-34.983675816999948, -7.397610567999948],
            [-34.976499985999965, -7.385099235999974],
            [-34.970707850999929, -7.37493232199995],
            [-34.962153494999939, -7.359976394999933],
            [-34.952639049999959, -7.34329043799994],
            [-34.951154016999965, -7.334861880999938],
            [-34.94822757999998, -7.317878312999937],
            [-34.94710721499996, -7.311457455999971],
            [-34.944268113999954, -7.295186800999943],
            [-34.942030725999984, -7.282285637999962],
            [-34.939187966999953, -7.265967436999972],
            [-34.937752567999951, -7.262350760999936],
            [-34.935046628999942, -7.25555862799996],
            [-34.933542039999963, -7.251785282999947],
            [-34.929451360999963, -7.241537920999974],
            [-34.92790389399994, -7.233371442999953],
            [-34.926830074999941, -7.228022338999949],
            [-34.925443235999978, -7.22540534999996],
            [-34.923058453999943, -7.220948343999964],
            [-34.924865907999958, -7.219132097999932],
            [-34.925929700999973, -7.218953459999966],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV João Pessoa II - Santa Rita II, C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 13.33348,
        Ano_Opera: 2022.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-01-11',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-01-11',
        Shape_Leng: 0.11843,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.925830322999957, -7.218562372999941],
            [-34.924751919999949, -7.218713623999975],
            [-34.922039518999952, -7.215846386999942],
            [-34.92274843499996, -7.213794034999978],
            [-34.93745892499993, -7.210853928999938],
            [-34.965237050999974, -7.199061903999961],
            [-34.984411981999983, -7.190915177999955],
            [-34.993016911999973, -7.187249975999976],
            [-34.999717335999946, -7.176042544999973],
            [-35.001893954999957, -7.172437489999936],
            [-35.00843946599997, -7.161594506999961],
            [-35.010610127999939, -7.157937175999962],
            [-35.010243328999934, -7.157148852999967],
            [-35.00957540099995, -7.156700583999964],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV João Pessoa II - Mussuré II, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 5.71444,
        Ano_Opera: 2022.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-01-11',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-01-11',
        Shape_Leng: 0.0501,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.925852498999973, -7.218693554999959],
            [-34.924830743999962, -7.218875941999954],
            [-34.921645535999971, -7.21810033099996],
            [-34.921398522999937, -7.217513148999956],
            [-34.919237529999975, -7.213520434999964],
            [-34.919186603999947, -7.213412743999982],
            [-34.91795996999997, -7.211107557999981],
            [-34.916786162999983, -7.208875754999951],
            [-34.913667167999961, -7.202982439999971],
            [-34.911341085999936, -7.200350859999958],
            [-34.908843027999978, -7.197604432999981],
            [-34.905307122999943, -7.193652154999938],
            [-34.901230906999956, -7.189105043999973],
            [-34.89872907299997, -7.186589005999963],
            [-34.897100181999974, -7.184790788999976],
            [-34.894873621999977, -7.18230448099996],
            [-34.89424011899996, -7.181921164999949],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV João Pessoa II - Mussuré II, C2',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 5.7206,
        Ano_Opera: 2022.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-01-11',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-01-11',
        Shape_Leng: 0.05016,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.92590613699997, -7.218818462999934],
            [-34.92482814799996, -7.21897385799997],
            [-34.921601676999956, -7.218177526999966],
            [-34.919164068999976, -7.213501245999964],
            [-34.91360388499993, -7.20302923099996],
            [-34.905276087999937, -7.193742093999958],
            [-34.901185398999928, -7.189184551999972],
            [-34.898686707999957, -7.186679038999955],
            [-34.89707577799993, -7.184881545999929],
            [-34.894837884999959, -7.18237417499995],
            [-34.894206953999969, -7.182059668999955],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV João Pessoa II - Santa Rita II, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 230.0,
        Extensao: 13.33219,
        Ano_Opera: 2022.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-01-11',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-01-11',
        Shape_Leng: 0.11842,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.925811188999944, -7.218428423999967],
            [-34.924797262999959, -7.218590995999932],
            [-34.922164867999982, -7.215835803999937],
            [-34.92281679599995, -7.213870897999925],
            [-34.929684469999984, -7.212512852999964],
            [-34.937501332999943, -7.210960090999947],
            [-34.945876921999968, -7.207385811999927],
            [-34.95724998299994, -7.202554211999939],
            [-34.965272877999951, -7.199163043999931],
            [-34.975296209999954, -7.194921448999935],
            [-34.984437458999935, -7.191022798999938],
            [-34.993092177999984, -7.187324283999942],
            [-34.995398396999974, -7.183440818999941],
            [-34.999791930999947, -7.176091208999935],
            [-35.001995758999954, -7.172523230999957],
            [-35.008571939999968, -7.161636027999975],
            [-35.010753729999976, -7.157942795999929],
            [-35.010349537999957, -7.157116655999971],
            [-35.009591504999946, -7.156567156999927],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Jeremoabo - Paulo Afonso IV, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 219.85071,
        Ano_Opera: 0.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-02-16',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-02-16',
        Shape_Leng: 0.75806,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.298720088999971, -10.162083443999961],
            [-38.298616938999942, -10.161269439999955],
            [-38.297085613999968, -10.158229391999953],
            [-38.295444323999959, -10.154954780999958],
            [-38.293064491999985, -10.140432567999937],
            [-38.289587603999962, -10.118758483999954],
            [-38.286577625999939, -10.100346281999975],
            [-38.281311838999954, -10.067119420999973],
            [-38.279082158999984, -10.05396507599994],
            [-38.277485665999961, -10.04322011499994],
            [-38.271860619999984, -10.008080234999966],
            [-38.269252730999938, -9.991595328999949],
            [-38.266692430999967, -9.975177047999978],
            [-38.262219043999949, -9.946775800999944],
            [-38.258973458999947, -9.926207739999938],
            [-38.257767418999947, -9.917894307999973],
            [-38.254757071999961, -9.899110435999944],
            [-38.247861575999934, -9.855164247999937],
            [-38.243678283999941, -9.828154910999956],
            [-38.239747413999964, -9.803456105999942],
            [-38.235777212999949, -9.778368817999933],
            [-38.232856494999965, -9.759426559999952],
            [-38.230962443999942, -9.747386486999972],
            [-38.229655393999963, -9.739014090999945],
            [-38.22895417899997, -9.734746789999974],
            [-38.227754930999936, -9.72679938999994],
            [-38.226541405999967, -9.71885674899994],
            [-38.225292188999958, -9.711175848999972],
            [-38.224114354999983, -9.703252243999941],
            [-38.222843722999983, -9.690881425999976],
            [-38.222372589999964, -9.686819685999978],
            [-38.221558813999934, -9.678596268999968],
            [-38.221144787999947, -9.674248993999981],
            [-38.220281043999933, -9.666282557999978],
            [-38.218967580999958, -9.653119379999964],
            [-38.218139528999984, -9.645459894999931],
            [-38.21724009299993, -9.637029464999955],
            [-38.21597659899993, -9.625051257999928],
            [-38.196417428999951, -9.433192937999934],
            [-38.194805555999949, -9.41808333299997],
            [-38.194694443999936, -9.416277777999937],
            [-38.19542043499996, -9.414882984999963],
            [-38.197347798999942, -9.414181043999974],
            [-38.198109225999929, -9.414085865999937],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Jeremoabo - Olindina, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 219.85071,
        Ano_Opera: 0.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-02-16',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-02-16',
        Shape_Leng: 1.16006,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.320737778037582, -10.310613660371555],
            [-38.318302939999967, -10.295371597999974],
            [-38.31645221399998, -10.284262177999949],
            [-38.314010391999943, -10.268312366999965],
            [-38.310726735999935, -10.247887073999948],
            [-38.29959697399994, -10.180082012999947],
            [-38.299095930999954, -10.176541314999952],
            [-38.297871046999944, -10.169680095999979],
            [-38.298434186999941, -10.167402977999927],
            [-38.299130846999958, -10.164621712999974],
            [-38.298941637999974, -10.163788058999955],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Juazeiro III - Futura, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 32.93955,
        Ano_Opera: 2023.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-03-16',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-03-16',
        Shape_Leng: 0.29539,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.52072820199993, -9.487448104999942],
            [-40.52098598699996, -9.488215184999945],
            [-40.530341070999953, -9.490829616999974],
            [-40.545441686999936, -9.495100040999944],
            [-40.602995042999964, -9.51132535499994],
            [-40.622049520999951, -9.516600652999955],
            [-40.635911274999955, -9.520542473999967],
            [-40.649961563999966, -9.524394294999979],
            [-40.66001794999994, -9.527356960999953],
            [-40.672490828999969, -9.530780223999955],
            [-40.676726087999953, -9.531649581999943],
            [-40.679551325999967, -9.537869564999937],
            [-40.684223317999965, -9.556343065999954],
            [-40.678712485999938, -9.57442316199996],
            [-40.69228953399994, -9.651471848999961],
            [-40.687119963999976, -9.658876172999953],
            [-40.686665392999942, -9.659572113999957],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Futura - Sobradinho, C1',
        Concession: 'CHESF - CHESF - Companhia Hidro Elétrica do São Francisco',
        Tensao: 500.0,
        Extensao: 35.2122,
        Ano_Opera: 2023.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-03-16',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-03-16',
        Shape_Leng: 0.31473,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.686887143999968, -9.659754204999956],
            [-40.687462664999941, -9.659141394999949],
            [-40.692861153999957, -9.651598879999938],
            [-40.679294011999957, -9.574419987999931],
            [-40.684856709999963, -9.556338452999967],
            [-40.680384502999971, -9.538793473999931],
            [-40.67732416399997, -9.532292465999944],
            [-40.67802133899994, -9.527576655999951],
            [-40.698454191999929, -9.515739671999938],
            [-40.699495949999971, -9.515182096999979],
            [-40.716418403999967, -9.505308982999964],
            [-40.767481735999979, -9.475922681999975],
            [-40.776309536999975, -9.470699764999949],
            [-40.784681572999943, -9.465876875999982],
            [-40.788743618999945, -9.463526301999934],
            [-40.792588851999938, -9.46125538299998],
            [-40.79667048999994, -9.458971812999948],
            [-40.800599938999937, -9.456724597999937],
            [-40.804171547999943, -9.454638487999944],
            [-40.807904814999972, -9.452472288999957],
            [-40.811528375999956, -9.450390570999957],
            [-40.815435574999981, -9.448065245999942],
            [-40.81914795199998, -9.445949205999966],
            [-40.822206988999937, -9.444959647999951],
            [-40.82718314899995, -9.443258071999935],
            [-40.827804259999937, -9.442149468999958],
            [-40.827909825999939, -9.438493808999965],
            [-40.827646407999964, -9.437696640999945],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Bom Nome - Ciranda , C1',
        Concession: null,
        Tensao: 230.0,
        Extensao: 4.18089,
        Ano_Opera: 2023.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
        Shape_Leng: 0.03677,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.628596868999978, -7.996485717999974],
            [-38.628767618999973, -7.995480669999949],
            [-38.628810448999957, -7.991526005999958],
            [-38.635051774999965, -7.976873284999954],
            [-38.633401618999983, -7.97432607199994],
            [-38.630671186999962, -7.972677104999946],
            [-38.628155699999979, -7.968237426999963],
            [-38.627635787999964, -7.964407089999952],
            [-38.627641605999941, -7.963735433999943],
          ],
        ],
      },
    },
  ],
};
