import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { DashboardButton, ModalContent, ModalTitle } from './styles';
import { Energy } from './EnergyComponent';

interface DashboardProps {
  latitude: string | null;
  longitude: string | null;
  address: string | null;
  globalIrradiation: string | null;
  difuseIrradiation: string | null;
  directIrradiation: string | null;
  monthlyIrradiation: number[];
}

export const Dashboard: React.FC<DashboardProps> = ({
  latitude,
  longitude,
  address,
  globalIrradiation,
  difuseIrradiation,
  directIrradiation,
  monthlyIrradiation,
}) => {
  const [activeButton, setActiveButton] = useState('energia');
  const modalContentRef = useRef<HTMLDivElement>(null);

  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
  };

  useEffect(() => {
    if (modalContentRef.current) {
      const modalElement = modalContentRef.current;

      const disableScrollPropagation = (e: Event) => {
        e.stopPropagation();
      };

      modalElement.addEventListener('wheel', disableScrollPropagation);

      return () => {
        modalElement.removeEventListener('wheel', disableScrollPropagation);
      };
    }
  }, []);

  const renderContent = () => {
    switch (activeButton) {
      case 'energia':
        return (
          <Energy
            latitude={latitude}
            longitude={longitude}
            address={address}
            globalIrradiation={globalIrradiation}
            difuseIrradiation={difuseIrradiation}
            directIrradiation={directIrradiation}
            monthlyIrradiation={monthlyIrradiation}
          />
        );

      case 'mercado':
        return <div>Conteúdo relacionado ao Mercado</div>;
      case 'simulacoes':
        return <div>Conteúdo relacionado às Simulações</div>;
      default:
        return (
          <Energy
            latitude={latitude}
            longitude={longitude}
            address={address}
            globalIrradiation={globalIrradiation}
            difuseIrradiation={difuseIrradiation}
            directIrradiation={directIrradiation}
            monthlyIrradiation={monthlyIrradiation}
          />
        );
    }
  };

  return (
    <>
      <ModalContent ref={modalContentRef}>
        <ModalTitle>Dashboard</ModalTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          mt={3}
          mb={3}
          sx={{ gap: '4px', marginTop: '10px' }}
        >
          <DashboardButton
            isActive={activeButton === 'energia'}
            onClick={() => handleButtonClick('energia')}
          >
            ENERGIA
          </DashboardButton>
          <DashboardButton
            isActive={activeButton === 'mercado'}
            onClick={() => handleButtonClick('mercado')}
          >
            MERCADO
          </DashboardButton>
          <DashboardButton
            isActive={activeButton === 'simulacoes'}
            onClick={() => handleButtonClick('simulacoes')}
          >
            SIMULAÇÕES
          </DashboardButton>
        </Box>
        <Box mt={3}>{renderContent()}</Box>
      </ModalContent>
    </>
  );
};
