export var jsonReasPrioritriasDoCaaTingaPE3 = {
  type: 'FeatureCollection',
  name: 'REAS_PRIORITRIAS_DO_CAATINGA_PE_3',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '102',
        OBJECTID: 132.0,
        Nome_area: 'Pico do Jabre',
        COD_area: 'CA138',
        Import_bio: 'Extremamente Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Criação de UC de Proteção Integral',
        Area_ha: 85371.2532,
        Shape_Leng: 2.41667,
        Shape_Area: 0.0699,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.439999999066721, -7.360061176400601],
              [-37.43999999990001, -7.360833326496788],
              [-37.440833332392344, -7.360833326496788],
              [-37.440833332392344, -7.360169003062323],
              [-37.439999999066721, -7.360061176400601],
            ],
          ],
          [
            [
              [-37.43887784, -7.359915977999956],
              [-37.437500000624482, -7.359591900372926],
              [-37.437500000624482, -7.359999993105134],
              [-37.439527146821554, -7.359999993105134],
              [-37.43887784, -7.359915977999956],
            ],
          ],
          [
            [
              [-37.43416666705798, -7.358807876595177],
              [-37.43416666705798, -7.359166658814217],
              [-37.435692057256013, -7.359166659225761],
              [-37.43416666705798, -7.358807876595177],
            ],
          ],
          [
            [
              [-37.432317639, -7.357444466999936],
              [-37.432127511, -7.357272010999965],
              [-37.431822259999898, -7.356656260999959],
              [-37.430338252999903, -7.353662729999946],
              [-37.428762634999899, -7.352567715999943],
              [-37.428333333193869, -7.352613748070716],
              [-37.42833333151799, -7.354166659363784],
              [-37.429166667607603, -7.354166659363784],
              [-37.429166665808964, -7.354999992755438],
              [-37.430833332592158, -7.354999992755438],
              [-37.430833331692838, -7.356666660438009],
              [-37.431666665983812, -7.356666658639313],
              [-37.431666665983812, -7.358333326321883],
              [-37.433297792693203, -7.358333326321883],
              [-37.432317639, -7.357444466999936],
            ],
          ],
          [
            [
              [-37.420285939243541, -7.357499992930286],
              [-37.421666667083002, -7.357499992930286],
              [-37.421666667083002, -7.356666657739993],
              [-37.42249999957528, -7.356666660438009],
              [-37.42249999957528, -7.355833326147035],
              [-37.423333332067557, -7.355833326147035],
              [-37.423333332067557, -7.354999992755438],
              [-37.424999999750128, -7.354999992755438],
              [-37.424999999750128, -7.354166659363784],
              [-37.424166665459211, -7.354166659363784],
              [-37.424166665459211, -7.35409644813706],
              [-37.420285939243541, -7.357499992930286],
            ],
          ],
          [
            [
              [-37.415474877999898, -7.361719476999942],
              [-37.412651925, -7.361873576999933],
              [-37.410317171, -7.360736739999947],
              [-37.408140054999905, -7.358104249999933],
              [-37.406877827, -7.358134619999939],
              [-37.406712145, -7.35813860599995],
              [-37.401926784999908, -7.359022160999932],
              [-37.395394998, -7.358671585999952],
              [-37.394166668662486, -7.358420653952005],
              [-37.394166667857405, -7.359166658814217],
              [-37.395833332842017, -7.359166658814217],
              [-37.395833331942697, -7.359999993105134],
              [-37.398333332117545, -7.359999993105134],
              [-37.398333333916185, -7.360833326496788],
              [-37.401666667482687, -7.360833327396108],
              [-37.401666666583367, -7.361666658989066],
              [-37.402499999075644, -7.361666658989066],
              [-37.402500000874284, -7.362499994179359],
              [-37.405000000149869, -7.362499993280039],
              [-37.404999998351173, -7.361666658089746],
              [-37.406666666933063, -7.361666658989066],
              [-37.406666666033743, -7.362499993280039],
              [-37.407499999425397, -7.362499993280039],
              [-37.407499999425397, -7.361666658989066],
              [-37.408333331917675, -7.361666658089746],
              [-37.408333331917675, -7.363333325772317],
              [-37.409166667107968, -7.363333325772317],
              [-37.409166667107968, -7.364999994354207],
              [-37.410833333891219, -7.364999993454887],
              [-37.410833333891219, -7.364166659163971],
              [-37.413333331368108, -7.364166659163971],
              [-37.413333331368108, -7.364999993454887],
              [-37.415833332442276, -7.364999993454887],
              [-37.415833333341595, -7.364166659163971],
              [-37.417500000124846, -7.364166659163971],
              [-37.417499999225527, -7.363333325772317],
              [-37.418333330818541, -7.363333325772317],
              [-37.4183333335165, -7.362499993280039],
              [-37.419166666008778, -7.36249999238072],
              [-37.419166667807417, -7.360833326496788],
              [-37.420833331892709, -7.360833326496788],
              [-37.420833331892709, -7.358333326321883],
              [-37.419999999400432, -7.358333326321883],
              [-37.420000000657801, -7.357750771967666],
              [-37.415474877999898, -7.361719476999942],
            ],
          ],
          [
            [
              [-37.393333332622376, -7.358250414006752],
              [-37.393333332667112, -7.358333325422564],
              [-37.393739191751536, -7.358333325860559],
              [-37.393333332622376, -7.358250414006752],
            ],
          ],
          [
            [
              [-37.389166666499577, -7.357399217156388],
              [-37.389166666608332, -7.357499993829606],
              [-37.389659973824408, -7.357499993563422],
              [-37.389166666499577, -7.357399217156388],
            ],
          ],
          [
            [
              [-37.385713734, -7.356693826999957],
              [-37.379166667810225, -7.353674546139938],
              [-37.379166668606842, -7.354166659363784],
              [-37.3800000001998, -7.354166659363784],
              [-37.3800000001998, -7.354999992755438],
              [-37.380833331792758, -7.354999992755438],
              [-37.380833331792758, -7.357499992930286],
              [-37.383333331967663, -7.357499992030966],
              [-37.383333332866982, -7.358333326321883],
              [-37.38583333304183, -7.358333327221203],
              [-37.385833332142511, -7.359166658814217],
              [-37.387499999825081, -7.359166658814217],
              [-37.387499998925762, -7.357499992930286],
              [-37.388333331418039, -7.357499992030966],
              [-37.388333331418039, -7.357228977406959],
              [-37.385713734, -7.356693826999957],
            ],
          ],
          [
            [
              [-37.369999999500074, -7.349166401190387],
              [-37.369999999500351, -7.349166659014031],
              [-37.370000446020292, -7.349166659014031],
              [-37.369999999500074, -7.349166401190387],
            ],
          ],
          [
            [
              [-37.367500000465306, -7.347722885672242],
              [-37.367500001124085, -7.348333326521754],
              [-37.368557210875473, -7.348333325761137],
              [-37.367500000465306, -7.347722885672242],
            ],
          ],
          [
            [
              [-37.366666666896293, -7.347241713511001],
              [-37.366666667732488, -7.347499993130157],
              [-37.367113976807737, -7.347499993130157],
              [-37.366666666896293, -7.347241713511001],
            ],
          ],
          [
            [
              [-37.364638779, -7.346070797999976],
              [-37.357786925, -7.340666798999965],
              [-37.3514894099999, -7.338520645999944],
              [-37.349511778, -7.337028507999955],
              [-37.349376338999903, -7.335118159999983],
              [-37.355857838, -7.325726944999934],
              [-37.3572843009999, -7.323113764999963],
              [-37.357008236, -7.320989055999976],
              [-37.355457279999897, -7.319143085999937],
              [-37.352136933999901, -7.316807050999965],
              [-37.352121538999903, -7.316787254999946],
              [-37.351785913, -7.316355700999963],
              [-37.351780332, -7.316348524999964],
              [-37.346038732, -7.308965860999928],
              [-37.345819215999903, -7.308683602999962],
              [-37.345403012, -7.308148438999982],
              [-37.335197533, -7.295026029999977],
              [-37.3325932119999, -7.289731873999921],
              [-37.330585745, -7.288283504999977],
              [-37.328215406, -7.288058103999967],
              [-37.322886103, -7.289131237999924],
              [-37.320482216999899, -7.290493665999966],
              [-37.319390854999902, -7.290304210999972],
              [-37.313959359, -7.289361328999973],
              [-37.307811607, -7.289241888999918],
              [-37.307612291999902, -7.289238016999949],
              [-37.305656419999899, -7.289200007999964],
              [-37.30307297199991, -7.288697747999951],
              [-37.296827751, -7.288421684999943],
              [-37.291175229988824, -7.288333325922224],
              [-37.290833331792783, -7.288333325922224],
              [-37.290833332692102, -7.289999992705475],
              [-37.291666666083756, -7.289999992705475],
              [-37.291666666083756, -7.291666659488669],
              [-37.290833332692102, -7.291666659488669],
              [-37.290833333591422, -7.292499992880323],
              [-37.289999998401186, -7.292499992880323],
              [-37.289999998401186, -7.2933333253726],
              [-37.290833332692102, -7.29333332717124],
              [-37.290833333591422, -7.294166658764254],
              [-37.291666666983076, -7.294166659663574],
              [-37.291666666983076, -7.294999993055171],
              [-37.293333331967631, -7.294999993055171],
              [-37.29333333376627, -7.295833326446825],
              [-37.290833331792783, -7.295833326446825],
              [-37.290833331792783, -7.297499992330756],
              [-37.289999998401186, -7.297499993230076],
              [-37.290000000199825, -7.298333325722353],
              [-37.28750000092424, -7.298333325722353],
              [-37.287499998226281, -7.300833325897202],
              [-37.285833334140989, -7.300833324997882],
              [-37.28583333144303, -7.301666659288855],
              [-37.283333333066821, -7.301666659288855],
              [-37.283333333066821, -7.302499992680453],
              [-37.282500000574544, -7.302499992680453],
              [-37.282499998775904, -7.303333326072107],
              [-37.28166666718289, -7.303333326072107],
              [-37.28166666808221, -7.304166660363023],
              [-37.280833331992653, -7.304166659463704],
              [-37.280833331992653, -7.305833326246955],
              [-37.279999999500319, -7.305833326246955],
              [-37.279999998600999, -7.306666659638609],
              [-37.279166667008042, -7.306666658739289],
              [-37.279166667008042, -7.307499993030206],
              [-37.278333331817748, -7.307499993030206],
              [-37.278333332717068, -7.308333326421803],
              [-37.277500000224791, -7.308333326421803],
              [-37.27750000112411, -7.311666659088985],
              [-37.278333331817748, -7.311666659088985],
              [-37.278333332717068, -7.313333325872236],
              [-37.279166667008042, -7.313333325872236],
              [-37.279166667008042, -7.31416666016321],
              [-37.280833333791293, -7.31416665926389],
              [-37.280833332891973, -7.314999992655487],
              [-37.279999999500319, -7.314999994454126],
              [-37.280000000399639, -7.317499992830335],
              [-37.279166666108722, -7.317499993729655],
              [-37.279166666108722, -7.318333326221989],
              [-37.278333331817748, -7.318333326221989],
              [-37.278333332717068, -7.322499992280768],
              [-37.277500000224791, -7.322499993180088],
              [-37.277500000224791, -7.324166659064019],
              [-37.276666665034497, -7.324166659064019],
              [-37.276666667732513, -7.324999993354936],
              [-37.27750000112411, -7.324999993354936],
              [-37.277499998426151, -7.32583332584727],
              [-37.277500000224791, -7.328333326022118],
              [-37.278333332717068, -7.328333326022118],
              [-37.278333333616388, -7.329166660313092],
              [-37.284999999850072, -7.329166659413772],
              [-37.284999999850072, -7.328333326022118],
              [-37.285833332342349, -7.328333326022118],
              [-37.285833334140989, -7.327499993529841],
              [-37.286666666633323, -7.327499993529841],
              [-37.286666666633323, -7.325833324947951],
              [-37.290833331792783, -7.325833324947951],
              [-37.290833331792783, -7.326666659238867],
              [-37.291666666083756, -7.326666659238867],
              [-37.291666666083756, -7.327499993529841],
              [-37.292499999475353, -7.327499991731202],
              [-37.292499999475353, -7.329166659413772],
              [-37.29333333376627, -7.329166659413772],
              [-37.293333332866951, -7.328333326022118],
              [-37.294166668057244, -7.328333326022118],
              [-37.294166668057244, -7.327499993529841],
              [-37.297499998925787, -7.327499993529841],
              [-37.297499999825106, -7.328333326022118],
              [-37.301666666783206, -7.328333326022118],
              [-37.301666666783206, -7.329166659413772],
              [-37.302500000174803, -7.329166658514453],
              [-37.302499998376163, -7.331666658689301],
              [-37.303333333566457, -7.331666658689301],
              [-37.303333332667137, -7.332499992980217],
              [-37.304166666958054, -7.332499992980217],
              [-37.304166666058734, -7.334166658864149],
              [-37.304999999450388, -7.334166659763468],
              [-37.304999999450388, -7.3358333256474],
              [-37.305833331942665, -7.3358333256474],
              [-37.305833332841985, -7.336666659039054],
              [-37.306666667132959, -7.336666658139734],
              [-37.306666668032278, -7.33749999332997],
              [-37.309999999800084, -7.33749999332997],
              [-37.309999998900764, -7.338333325822305],
              [-37.311666666583335, -7.338333324922985],
              [-37.311666665684015, -7.336666659039054],
              [-37.310833333191738, -7.336666658139734],
              [-37.310833331393098, -7.3358333256474],
              [-37.311666665684015, -7.3358333256474],
              [-37.311666667482655, -7.334999993155122],
              [-37.314999998351198, -7.334999993155122],
              [-37.314999997451878, -7.334166658864149],
              [-37.315833332642171, -7.334166658864149],
              [-37.315833331742795, -7.334999993155122],
              [-37.316666666933088, -7.334999992255803],
              [-37.316666666933088, -7.336666659039054],
              [-37.319166666208616, -7.336666658139734],
              [-37.319166667107936, -7.337499992430651],
              [-37.323333333166772, -7.33749999332997],
              [-37.323333333166772, -7.338333325822305],
              [-37.324166666558369, -7.338333325822305],
              [-37.324166666558369, -7.339166659213902],
              [-37.32583333514026, -7.339166659213902],
              [-37.325833331542981, -7.339999993504875],
              [-37.326666665833898, -7.339999991706179],
              [-37.326666667632537, -7.34166665938875],
              [-37.327500001024191, -7.34166665938875],
              [-37.327500001024191, -7.345833326346906],
              [-37.32999999850108, -7.345833326346906],
              [-37.330000002098359, -7.346666658839183],
              [-37.332500000474624, -7.346666658839183],
              [-37.332500000474624, -7.347499993130157],
              [-37.333333333866221, -7.347499993130157],
              [-37.333333332966902, -7.349166659014031],
              [-37.334999999750153, -7.349166659014031],
              [-37.335000000649472, -7.350833326696602],
              [-37.334166666358499, -7.350833324897962],
              [-37.334166665459179, -7.352499993479853],
              [-37.335000001548792, -7.352499993479853],
              [-37.336666666533404, -7.352499994379173],
              [-37.336666667432723, -7.351666659188936],
              [-37.337499999025681, -7.351666659188936],
              [-37.337499999025681, -7.350833325797282],
              [-37.339166665808932, -7.350833325797282],
              [-37.339166667607572, -7.349166659014031],
              [-37.340000000099906, -7.349166659014031],
              [-37.339999999200586, -7.348333324723114],
              [-37.34166666598378, -7.348333326521754],
              [-37.34166666598378, -7.347499993130157],
              [-37.344166667058005, -7.347499993130157],
              [-37.344166666158685, -7.346666658839183],
              [-37.344999999550282, -7.346666658839183],
              [-37.344999998650962, -7.344999992055932],
              [-37.345833332941936, -7.344999993854572],
              [-37.345833333841256, -7.343333326172001],
              [-37.346666667232853, -7.343333326172001],
              [-37.348333333116784, -7.343333326172001],
              [-37.348333334016104, -7.342499992780404],
              [-37.349166665609062, -7.342499992780404],
              [-37.349166666508381, -7.343333326172001],
              [-37.350833332392313, -7.343333326172001],
              [-37.350833334190952, -7.342499992780404],
              [-37.351666666683286, -7.342499992780404],
              [-37.351666666683286, -7.343333325272681],
              [-37.354166666858134, -7.343333326172001],
              [-37.354166665958815, -7.344166658664335],
              [-37.355833334540705, -7.344166658664335],
              [-37.355833331842746, -7.344999992955252],
              [-37.356666666133719, -7.344999992955252],
              [-37.356666666133719, -7.345833326346906],
              [-37.358333332017594, -7.345833325447586],
              [-37.358333332916914, -7.344999992955252],
              [-37.359166667207887, -7.344999993854572],
              [-37.359166667207887, -7.345833326346906],
              [-37.360833332192499, -7.345833326346906],
              [-37.360833333091819, -7.346666659738503],
              [-37.365670740131009, -7.346666658992724],
              [-37.364638779, -7.346070797999976],
            ],
          ],
          [
            [
              [-37.279999999065865, -7.277097418776768],
              [-37.279999999500319, -7.277499992730441],
              [-37.280470760531649, -7.277499992730441],
              [-37.279999999065865, -7.277097418776768],
            ],
          ],
          [
            [
              [-37.278333331817748, -7.275672160185535],
              [-37.278333331817748, -7.276666659338787],
              [-37.279496278090939, -7.276666659338787],
              [-37.278333331817748, -7.275672160185535],
            ],
          ],
          [
            [
              [-37.255329326, -7.274665792999947],
              [-37.2529528929999, -7.274884698999933],
              [-37.252500000699072, -7.274779914942153],
              [-37.252500001174099, -7.274999993454912],
              [-37.253333333666376, -7.274999991656216],
              [-37.253333332767056, -7.27583332594719],
              [-37.256666665434238, -7.27583332594719],
              [-37.256666664534919, -7.274999993454912],
              [-37.258333332217489, -7.274999991656216],
              [-37.25833333287683, -7.274389030588401],
              [-37.255329326, -7.274665792999947],
            ],
          ],
          [
            [
              [-37.234178764, -7.273886872999978],
              [-37.233867847969094, -7.274166659163939],
              [-37.236666666733242, -7.274166659163939],
              [-37.236666666733242, -7.274999993454912],
              [-37.23749999922552, -7.274999993454912],
              [-37.237500001024159, -7.274166659163939],
              [-37.239166666908091, -7.274166659163939],
              [-37.239166666908091, -7.273333325772342],
              [-37.240833333691342, -7.273333325772342],
              [-37.240833333421584, -7.273083360451609],
              [-37.234178764, -7.273886872999978],
            ],
          ],
          [
            [
              [-37.23294179731532, -7.274999991867492],
              [-37.23333333316674, -7.274999991656216],
              [-37.233333332026042, -7.274647658181283],
              [-37.23294179731532, -7.274999991867492],
            ],
          ],
          [
            [
              [-37.207756233624963, -7.291666659782648],
              [-37.21, -7.291666660388046],
              [-37.20999999986195, -7.291538760680166],
              [-37.207756233624963, -7.291666659782648],
            ],
          ],
          [
            [
              [-37.196673984, -7.294670063999931],
              [-37.190950654999909, -7.300229255999968],
              [-37.188026533228637, -7.302499992680453],
              [-37.18916666520937, -7.302499992680453],
              [-37.18916666700801, -7.301666658389536],
              [-37.190833331992621, -7.301666659288855],
              [-37.190833332891941, -7.300833325897202],
              [-37.191666665384275, -7.300833325897202],
              [-37.191666667182915, -7.299999993404924],
              [-37.192499998775872, -7.299999994304244],
              [-37.192500000574512, -7.299166659114007],
              [-37.193333332167526, -7.299166659114007],
              [-37.193333333066846, -7.298333325722353],
              [-37.195833331443055, -7.298333325722353],
              [-37.195833332342374, -7.297499993230076],
              [-37.196666666633291, -7.297499993230076],
              [-37.196666667532611, -7.294999993055171],
              [-37.197499998226306, -7.294999993055171],
              [-37.197499998565966, -7.294370513570927],
              [-37.196673984, -7.294670063999931],
            ],
          ],
          [
            [
              [-37.1829815789999, -7.306417668999922],
              [-37.179280437622126, -7.308333325645262],
              [-37.179999998800838, -7.308333326421803],
              [-37.180000000599478, -7.309166658914137],
              [-37.180833332192492, -7.309166658914137],
              [-37.180833333991131, -7.308333327321179],
              [-37.185833332542245, -7.308333326421803],
              [-37.185833331642925, -7.307499993030206],
              [-37.186666665933842, -7.307499992130886],
              [-37.186666667732482, -7.306666658739289],
              [-37.187500001124135, -7.306666658739289],
              [-37.187499998426119, -7.304999991956038],
              [-37.186666666833162, -7.304999992855358],
              [-37.186666667732482, -7.304166659463704],
              [-37.187500000224759, -7.304166659463704],
              [-37.187500000224759, -7.302908873670683],
              [-37.1829815789999, -7.306417668999922],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '107',
        OBJECTID: 142.0,
        Nome_area: 'Jatobá',
        COD_area: 'CA148',
        Import_bio: 'Extremamente Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Recuperação de Áreas Degradadas',
        Area_ha: 68473.1337,
        Shape_Leng: 2.455,
        Shape_Area: 0.05609,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.012971206, -7.404288732999929],
              [-37.014166667026451, -7.405745671159792],
              [-37.014166666458436, -7.404166659263865],
              [-37.013333333966159, -7.404166659263865],
              [-37.013333333966159, -7.404042669984184],
              [-37.012971206, -7.404288732999929],
            ],
          ],
          [
            [
              [-37.014999999154981, -7.406761273802654],
              [-37.014999998950771, -7.406666659438713],
              [-37.014922365259636, -7.406666659438713],
              [-37.014999999154981, -7.406761273802654],
            ],
          ],
          [
            [
              [-37.015833330842533, -7.407776875908083],
              [-37.015833330543728, -7.407499992830367],
              [-37.01560614005475, -7.407499992830367],
              [-37.015833330842533, -7.407776875908083],
            ],
          ],
          [
            [
              [-37.023693325999901, -7.415807568999979],
              [-37.024560414954372, -7.41833332602215],
              [-37.02499999965022, -7.41833332602215],
              [-37.0249999987509, -7.417499993529816],
              [-37.025833332142497, -7.417499993529816],
              [-37.025833332142497, -7.415833325847245],
              [-37.0249999987509, -7.415833325847245],
              [-37.0249999987509, -7.414999992455648],
              [-37.023333332866969, -7.414999993354968],
              [-37.023333332866969, -7.414166659063994],
              [-37.021924867710112, -7.41416665855733],
              [-37.023693325999901, -7.415807568999979],
            ],
          ],
          [
            [
              [-37.0145081759999, -7.430368145999982],
              [-37.01500000015325, -7.430557116448155],
              [-37.014999998950771, -7.429999991706211],
              [-37.014702326652603, -7.429999992348697],
              [-37.0145081759999, -7.430368145999982],
            ],
          ],
          [
            [
              [-37.015833332437282, -7.430877302380706],
              [-37.015833332342368, -7.430833325997128],
              [-37.015718877252205, -7.430833325997128],
              [-37.015833332437282, -7.430877302380706],
            ],
          ],
          [
            [
              [-37.020833332692121, -7.432798420492913],
              [-37.020833332692121, -7.431666659388782],
              [-37.017887751289997, -7.431666658594076],
              [-37.020833332692121, -7.432798420492913],
            ],
          ],
          [
            [
              [-37.022800505, -7.436027214999972],
              [-37.021614924030594, -7.439166659776931],
              [-37.022499999475372, -7.439166659913383],
              [-37.022499999475372, -7.437499993130132],
              [-37.023333332866969, -7.437499993130132],
              [-37.023333331068329, -7.435833326346881],
              [-37.022873686205969, -7.435833326346881],
              [-37.022800505, -7.436027214999972],
            ],
          ],
          [
            [
              [-37.013177251999899, -7.44498371999992],
              [-37.013172650877237, -7.444999992697611],
              [-37.013333333066839, -7.444999992755413],
              [-37.013333333184576, -7.444890893323554],
              [-37.013177251999899, -7.44498371999992],
            ],
          ],
          [
            [
              [-37.003190712898117, -7.454999993454862],
              [-37.003333331468014, -7.454999993454862],
              [-37.003333331583001, -7.454893445957318],
              [-37.003190712898117, -7.454999993454862],
            ],
          ],
          [
            [
              [-36.997310400999901, -7.459393053999965],
              [-36.994062739, -7.464710780999947],
              [-36.995436846005148, -7.47249999288033],
              [-36.996666664335066, -7.47249999288033],
              [-36.996666667033026, -7.471666657690037],
              [-36.997499999525303, -7.471666659488676],
              [-36.997499999525303, -7.468333325922231],
              [-36.998333332017637, -7.468333325922231],
              [-36.998333332916957, -7.464166658964075],
              [-36.999999999700208, -7.464166658964075],
              [-37.000000000599528, -7.463333326471798],
              [-36.999166665409234, -7.463333326471798],
              [-36.999166668107193, -7.459999992905296],
              [-36.999999999700208, -7.459999992905296],
              [-36.999999999700208, -7.458333325222725],
              [-37.000833333091805, -7.458333326122045],
              [-37.000833333091805, -7.456761143610873],
              [-36.997310400999901, -7.459393053999965],
            ],
          ],
          [
            [
              [-36.993006211, -7.474148948999924],
              [-36.992499999269832, -7.474137538225349],
              [-36.99249999917555, -7.474166659663581],
              [-36.994999998451135, -7.474166658764261],
              [-36.994999998451135, -7.472878594103221],
              [-36.993006211, -7.474148948999924],
            ],
          ],
          [
            [
              [-36.990000000849179, -7.486095710887803],
              [-36.990000001698661, -7.485833326246961],
              [-36.989604823957379, -7.485833326246961],
              [-36.990000000849179, -7.486095710887803],
            ],
          ],
          [
            [
              [-36.997677356999901, -7.492521931999928],
              [-37.000833333091805, -7.494710405817885],
              [-37.000833333091805, -7.493333325872243],
              [-36.999166665409234, -7.493333325872243],
              [-36.999166668107193, -7.492499993379965],
              [-36.997655172222522, -7.492499993379965],
              [-36.997677356999901, -7.492521931999928],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '109',
        OBJECTID: 147.0,
        Nome_area: 'Pau Branco',
        COD_area: 'CA153',
        Import_bio: 'Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Criação de UC de categoria indefinida',
        Area_ha: 10132.6379,
        Shape_Leng: 0.72667,
        Shape_Area: 0.0083,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.717004642306996, -7.625833326147017],
              [-38.716666666833191, -7.625833326147017],
              [-38.716666666180721, -7.625228768521009],
              [-38.716600501, -7.625110414999946],
              [-38.716553391004702, -7.62499999275542],
              [-38.715833334340857, -7.62499999275542],
              [-38.71583333373372, -7.6233122332993],
              [-38.715486803471855, -7.622499992731954],
              [-38.714999999150621, -7.622499991681252],
              [-38.714999999265231, -7.622075174421671],
              [-38.714103099999903, -7.623311249999965],
              [-38.709104466, -7.623003796999968],
              [-38.70896371, -7.622995139999944],
              [-38.706327146, -7.621926317999953],
              [-38.70540719, -7.622043457999953],
              [-38.702101937, -7.625830245999963],
              [-38.70041291, -7.626740892999987],
              [-38.698548194999901, -7.630386227999963],
              [-38.698075540616813, -7.63083332649677],
              [-38.699166665609084, -7.63083332649677],
              [-38.699166665609084, -7.631666659888424],
              [-38.700833332392335, -7.631666658989047],
              [-38.700833332392335, -7.63083332649677],
              [-38.702500000074906, -7.63083332739609],
              [-38.702499999175586, -7.631666658989047],
              [-38.704999999350434, -7.631666658989047],
              [-38.705833330943449, -7.631666658989047],
              [-38.705833332742088, -7.632499993280021],
              [-38.70749999862602, -7.632499993280021],
              [-38.707500000424659, -7.633333325772298],
              [-38.708333333816256, -7.633333325772298],
              [-38.708333332916936, -7.634166659163952],
              [-38.709166665409214, -7.634166659163952],
              [-38.70916666720791, -7.633333325772298],
              [-38.709999998800868, -7.633333326671618],
              [-38.709999999700187, -7.632499993280021],
              [-38.711666665584119, -7.632499993280021],
              [-38.711666666483438, -7.631666658089728],
              [-38.713333334166009, -7.631666659888424],
              [-38.71333333146805, -7.63083332649677],
              [-38.71500000094926, -7.630833324698131],
              [-38.71500000004994, -7.629999993105173],
              [-38.715833332542218, -7.629999994004493],
              [-38.715833331642898, -7.629166658814199],
              [-38.716666667732511, -7.629166658814199],
              [-38.716666666833191, -7.628333325422602],
              [-38.717500001124108, -7.628333326321922],
              [-38.717499999382383, -7.626719400263625],
              [-38.717004642306996, -7.625833326147017],
            ],
          ],
          [
            [
              [-38.697194575933239, -7.631666658419336],
              [-38.697499999725153, -7.631666658089728],
              [-38.697499999413367, -7.631377748840283],
              [-38.697194575933239, -7.631666658419336],
            ],
          ],
          [
            [
              [-38.687345653053022, -7.634999993454869],
              [-38.687499999025704, -7.634999993454869],
              [-38.687499999025704, -7.634989705595062],
              [-38.687345653053022, -7.634999993454869],
            ],
          ],
          [
            [
              [-38.685325048222204, -7.638333326122051],
              [-38.68666666653337, -7.638333326122051],
              [-38.68666666653337, -7.635214465484069],
              [-38.685325048222204, -7.638333326122051],
            ],
          ],
          [
            [
              [-38.684608109912169, -7.639999992905302],
              [-38.684999999750119, -7.639999992905302],
              [-38.684999999258537, -7.639088967581138],
              [-38.684608109912169, -7.639999992905302],
            ],
          ],
          [
            [
              [-38.683891171988989, -7.641666658789234],
              [-38.684166665459202, -7.641666658789234],
              [-38.684166665459202, -7.641026219006084],
              [-38.683891171988989, -7.641666658789234],
            ],
          ],
          [
            [
              [-38.680075628999901, -7.645299013999936],
              [-38.678973121999903, -7.646558045999934],
              [-38.678300758, -7.647560917999942],
              [-38.677152387198461, -7.64999999198084],
              [-38.679166666008769, -7.649999992705432],
              [-38.679166666008769, -7.650833326996406],
              [-38.681666667982313, -7.650833326097086],
              [-38.681666666404595, -7.644254521166243],
              [-38.680075628999901, -7.645299013999936],
            ],
          ],
          [
            [
              [-38.676409072, -7.651578750999925],
              [-38.675073327999897, -7.653088381999964],
              [-38.674699242488614, -7.654999992880343],
              [-38.675833332442323, -7.654999993055185],
              [-38.675833333341643, -7.654166658764268],
              [-38.677500000124837, -7.654166661462227],
              [-38.678333331717852, -7.654166658764268],
              [-38.678333330818532, -7.651666659488683],
              [-38.677500001024157, -7.651666659488683],
              [-38.677500001024157, -7.650833326097086],
              [-38.676760034783356, -7.650833326097086],
              [-38.676409072, -7.651578750999925],
            ],
          ],
          [
            [
              [-38.66104710490071, -7.659999993404938],
              [-38.661666666583358, -7.659999993404938],
              [-38.661666666799917, -7.659397981065636],
              [-38.66104710490071, -7.659999993404938],
            ],
          ],
          [
            [
              [-38.658666433999898, -7.662313229999929],
              [-38.658663028, -7.662316539999946],
              [-38.658185771, -7.662853500999965],
              [-38.654409041, -7.667102693999943],
              [-38.651666666783228, -7.668186699163964],
              [-38.651666666783228, -7.668333326421816],
              [-38.652499998376186, -7.668333326421816],
              [-38.652499998376186, -7.669166658914151],
              [-38.653333332667103, -7.669166658914151],
              [-38.653333333566422, -7.669999993205067],
              [-38.654166666058757, -7.669999993205067],
              [-38.654166666958076, -7.66916665981347],
              [-38.658333333016856, -7.669166658914151],
              [-38.658333332117536, -7.667499993030219],
              [-38.65916666640851, -7.667499993030219],
              [-38.65916666640851, -7.666666657839926],
              [-38.659999999800107, -7.666666657839926],
              [-38.660000000699426, -7.664999992855314],
              [-38.65916666640851, -7.664999992855314],
              [-38.65916666640851, -7.661827167053049],
              [-38.658666433999898, -7.662313229999929],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '110',
        OBJECTID: 149.0,
        Nome_area: 'Serra da Matinha',
        COD_area: 'CA155',
        Import_bio: 'Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Criação de UC de Proteção Integral',
        Area_ha: 14545.1197,
        Shape_Leng: 0.71667,
        Shape_Area: 0.01192,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.731666665384239, -7.641666658789234],
              [-37.731666667182878, -7.64249999308015],
              [-37.728333331817794, -7.64249999308015],
              [-37.728333332717114, -7.643333326471804],
              [-37.727500000224779, -7.643333326471804],
              [-37.727500001124099, -7.644166659863401],
              [-37.726666665933863, -7.644166658964082],
              [-37.726666665933863, -7.645833325747333],
              [-37.725833332542209, -7.645833326646652],
              [-37.725833333441528, -7.647499993429903],
              [-37.724999999150612, -7.647499993429903],
              [-37.725000000049931, -7.648333325922181],
              [-37.724166666658277, -7.648333325922181],
              [-37.724166666658277, -7.649166659313835],
              [-37.723333331468041, -7.649166658414515],
              [-37.723333334166, -7.651666659488683],
              [-37.722499999875026, -7.651666657690043],
              [-37.722499998975707, -7.652499992880337],
              [-37.720833332192456, -7.652499992880337],
              [-37.720833333991152, -7.653333326271934],
              [-37.718333333816247, -7.653333326271934],
              [-37.718333332017608, -7.654166658764268],
              [-37.714166665958828, -7.654166658764268],
              [-37.714166666858148, -7.655833327346159],
              [-37.712499999175577, -7.655833326446839],
              [-37.712500000074897, -7.657499993230033],
              [-37.71166666578398, -7.657499993230033],
              [-37.7116666666833, -7.659166659113964],
              [-37.710833332392326, -7.659166659113964],
              [-37.710833334190966, -7.66333332697144],
              [-37.709999998101409, -7.66333332607212],
              [-37.709999999000729, -7.664166659463717],
              [-37.709166667407715, -7.664166659463717],
              [-37.709166667407715, -7.664999992855314],
              [-37.708333333116798, -7.664999992855314],
              [-37.708333332217478, -7.665833326246968],
              [-37.707499999725144, -7.665833326246968],
              [-37.707499998825824, -7.666666658739246],
              [-37.706666665434227, -7.666666657839926],
              [-37.706666668132186, -7.667499993030219],
              [-37.705833332941893, -7.667499993929539],
              [-37.705833332042573, -7.668333326421816],
              [-37.704166666158699, -7.668333325522497],
              [-37.704166666158699, -7.669999993205067],
              [-37.703333331867725, -7.669999993205067],
              [-37.703333333666365, -7.670833325697402],
              [-37.702499998476128, -7.670833325697402],
              [-37.702500001174087, -7.671666659088999],
              [-37.701666665983794, -7.671666659088999],
              [-37.701666665983794, -7.672499992480596],
              [-37.700000000099863, -7.672499993379915],
              [-37.699999999200543, -7.67333332587225],
              [-37.699166665808946, -7.67333332587225],
              [-37.699166666708265, -7.674166660163166],
              [-37.697499999025695, -7.674166659263847],
              [-37.697500000824334, -7.67499999445414],
              [-37.696666665634041, -7.674999992655501],
              [-37.696666667432737, -7.675833326946417],
              [-37.694166666358512, -7.675833326047098],
              [-37.694166665459193, -7.676666659438752],
              [-37.693333334765555, -7.676666660338071],
              [-37.693333332067596, -7.677499992830349],
              [-37.692500000474581, -7.677499991931029],
              [-37.692499999575261, -7.683333325672379],
              [-37.691666666183664, -7.683333324773059],
              [-37.691666666183664, -7.685833325847284],
              [-37.690833330993371, -7.685833325847284],
              [-37.69083333279201, -7.686666659238881],
              [-37.690000001199053, -7.686666659238881],
              [-37.689999999400413, -7.688333326022132],
              [-37.689166666908136, -7.688333326921452],
              [-37.689166666008759, -7.69083332619698],
              [-37.688333333516482, -7.69083332619698],
              [-37.688333330818523, -7.694166658864162],
              [-37.694166666358512, -7.694166657964843],
              [-37.694166665459193, -7.693333327271205],
              [-37.694999997951527, -7.693333326371885],
              [-37.694999998850847, -7.694166658864162],
              [-37.696666667432737, -7.694166658864162],
              [-37.696666665634041, -7.694999993155136],
              [-37.697499999025695, -7.694999993155136],
              [-37.697499999925014, -7.695833327446053],
              [-37.698333332417292, -7.695833325647413],
              [-37.698333334215931, -7.69666665903901],
              [-37.700000000099863, -7.69666665903901],
              [-37.700000000099863, -7.695833324748094],
              [-37.702499999375448, -7.695833325647413],
              [-37.702500002073407, -7.694999993155136],
              [-37.704166667957338, -7.694999993155136],
              [-37.704166667058018, -7.695833325647413],
              [-37.704999999550296, -7.695833325647413],
              [-37.704999998650976, -7.69666665903901],
              [-37.705833332042573, -7.69666665903901],
              [-37.705833332941893, -7.699166659213915],
              [-37.706666667232867, -7.699166659213915],
              [-37.706666665434227, -7.700833325997166],
              [-37.705833333841213, -7.700833325997166],
              [-37.705833332042573, -7.701666659388763],
              [-37.704166666158699, -7.701666660288083],
              [-37.704166667058018, -7.702499993679737],
              [-37.703333333666365, -7.702499991881098],
              [-37.703333332767045, -7.704166658664292],
              [-37.702499999375448, -7.704166660462988],
              [-37.702499999375448, -7.704999993854585],
              [-37.701666667782433, -7.704999992955265],
              [-37.701666665983794, -7.706666658839197],
              [-37.702499999375448, -7.706666658839197],
              [-37.702499999375448, -7.707499993130114],
              [-37.701666665983794, -7.707499993130114],
              [-37.701666667782433, -7.709999993305019],
              [-37.702499999375448, -7.709999992405699],
              [-37.702500001174087, -7.710833325797296],
              [-37.703333332767045, -7.710833325797296],
              [-37.703333333666365, -7.71166665918895],
              [-37.705000000449616, -7.71166665918895],
              [-37.704999999550296, -7.713333325972144],
              [-37.705833333841213, -7.713333325972144],
              [-37.705833333841213, -7.714166659363798],
              [-37.706666668132186, -7.714166658464478],
              [-37.706666665434227, -7.714999992755395],
              [-37.707500000624464, -7.714999992755395],
              [-37.707499998825824, -7.715833326147049],
              [-37.708333332217478, -7.715833326147049],
              [-37.708333333116798, -7.716666658639326],
              [-37.709999999000729, -7.716666658639326],
              [-37.709999999000729, -7.7174999929303],
              [-37.71166666758262, -7.71749999382962],
              [-37.71166666758262, -7.718333326321897],
              [-37.713333333466551, -7.718333327221217],
              [-37.713333331667855, -7.7174999929303],
              [-37.714166665958828, -7.7174999929303],
              [-37.714166666858148, -7.718333326321897],
              [-37.714999999350425, -7.718333326321897],
              [-37.714999998451106, -7.719166658814231],
              [-37.715833331842759, -7.719166658814231],
              [-37.715833332742079, -7.719999993105148],
              [-37.716666666133676, -7.719999993105148],
              [-37.716666666133676, -7.720833326496802],
              [-37.71749999862601, -7.720833326496802],
              [-37.71749999862601, -7.722499993279996],
              [-37.718333332017608, -7.722499993279996],
              [-37.718333332916927, -7.724166658264608],
              [-37.720000000599498, -7.724166660063247],
              [-37.719999999700178, -7.72333332577233],
              [-37.720833333091832, -7.72333332577233],
              [-37.727500000224779, -7.72333332577233],
              [-37.72749999842614, -7.724166659163927],
              [-37.729166666108711, -7.724166659163927],
              [-37.729166666108711, -7.724999993454901],
              [-37.731666668082198, -7.724999993454901],
              [-37.731666668082198, -7.725833325947178],
              [-37.732499999675213, -7.725833325947178],
              [-37.732499998775893, -7.727499992730429],
              [-37.733333330368851, -7.727499992730429],
              [-37.73333333306681, -7.72916665951368],
              [-37.734166666458464, -7.72916665951368],
              [-37.734166667357783, -7.731666658789209],
              [-37.734999999850061, -7.731666658789209],
              [-37.73500000074938, -7.73499999325503],
              [-37.735833331443075, -7.73499999325503],
              [-37.735833331443075, -7.739999992705464],
              [-37.738333331617923, -7.739999992705464],
              [-37.738333333416563, -7.74083332699638],
              [-37.740000001099133, -7.740833326097061],
              [-37.740000001099133, -7.741666659488715],
              [-37.740833334490731, -7.741666659488715],
              [-37.740833331792771, -7.742499992880312],
              [-37.741666667882384, -7.742499992880312],
              [-37.741666666983065, -7.744166658764243],
              [-37.742499999475342, -7.744166658764243],
              [-37.742499998576022, -7.744999993055217],
              [-37.743333332866996, -7.744999993055217],
              [-37.743333332866996, -7.747499993230065],
              [-37.744166665359273, -7.747499993230065],
              [-37.744166667157913, -7.748333325722342],
              [-37.744999999650247, -7.748333325722342],
              [-37.745000001448886, -7.749166659113996],
              [-37.748333331418053, -7.749166659113996],
              [-37.748333331418053, -7.749999993404913],
              [-37.75, -7.749999992505593],
              [-37.749999999100623, -7.750833325897247],
              [-37.750833331592958, -7.750833325897247],
              [-37.750833332492277, -7.751666659288844],
              [-37.752499998376209, -7.751666658389524],
              [-37.752500000174848, -7.754166659463692],
              [-37.753333331767806, -7.754166659463692],
              [-37.753333331767806, -7.754999992855346],
              [-37.757499999625225, -7.754999993754666],
              [-37.757499998725905, -7.755833326246943],
              [-37.758333332117559, -7.755833326246943],
              [-37.758333332117559, -7.758333326421848],
              [-37.759166666408476, -7.758333326421848],
              [-37.759166666408476, -7.759999994104419],
              [-37.760833333191727, -7.759999993205099],
              [-37.760833331393087, -7.760833325697376],
              [-37.7616666674827, -7.760833325697376],
              [-37.761666665684004, -7.761666659088974],
              [-37.764166666758229, -7.761666658189654],
              [-37.764166667657548, -7.764166660163198],
              [-37.764999998351186, -7.764166659263879],
              [-37.765000001049145, -7.764999994454115],
              [-37.764166666758229, -7.764999992655476],
              [-37.764166667657548, -7.76583332604713],
              [-37.765000000149826, -7.76583332604713],
              [-37.765000000149826, -7.767499992830381],
              [-37.76583333264216, -7.767499992830381],
              [-37.76583333264216, -7.766666659438727],
              [-37.766666666933077, -7.766666660338046],
              [-37.766666666933077, -7.7674999937297],
              [-37.767499999425411, -7.767499992830381],
              [-37.767499999425411, -7.768333326221978],
              [-37.768333333716328, -7.768333326221978],
              [-37.768333333716328, -7.770833326396826],
              [-37.769166667107982, -7.770833326396826],
              [-37.769166666208662, -7.772499993180077],
              [-37.768333332817008, -7.772499993180077],
              [-37.768333331917688, -7.775833325847259],
              [-37.769166667107982, -7.775833325847259],
              [-37.769166667107982, -7.776666659238913],
              [-37.769999999600259, -7.776666659238913],
              [-37.769999999600259, -7.77749999352983],
              [-37.770833333891176, -7.77749999352983],
              [-37.770833332991856, -7.778333326022107],
              [-37.773333331368121, -7.778333325122787],
              [-37.773333333166761, -7.779166659413761],
              [-37.77583333154297, -7.779166659413761],
              [-37.775833333341609, -7.779999991906038],
              [-37.776666666733263, -7.779999993704678],
              [-37.776666665833943, -7.782499992980263],
              [-37.77749999922554, -7.782499992080943],
              [-37.77750000012486, -7.78333332637186],
              [-37.778333332617137, -7.78333332637186],
              [-37.778333331717818, -7.784166658864194],
              [-37.779166666908111, -7.784166658864194],
              [-37.779166666008791, -7.785833325647388],
              [-37.780833333691362, -7.785833325647388],
              [-37.780833331892723, -7.786666659039042],
              [-37.779999998501069, -7.786666659039042],
              [-37.779999997601749, -7.788333324922974],
              [-37.780833332792042, -7.788333326721613],
              [-37.780833331892723, -7.78916665921389],
              [-37.78333333386621, -7.78916665921389],
              [-37.78333333386621, -7.788333325822293],
              [-37.784166665459225, -7.788333325822293],
              [-37.784166665459225, -7.78916665921389],
              [-37.784999999750141, -7.789166658314571],
              [-37.785000000649461, -7.789999993504864],
              [-37.785833333141795, -7.789999993504864],
              [-37.785833331343099, -7.790833325997141],
              [-37.786666667432712, -7.790833325997141],
              [-37.786666667432712, -7.789999993504864],
              [-37.792499999375423, -7.789999993504864],
              [-37.792500001174062, -7.790833326896461],
              [-37.794166667057993, -7.790833325997141],
              [-37.794166666158674, -7.791666659388795],
              [-37.795833332042605, -7.791666659388795],
              [-37.795833332042605, -7.792499992780392],
              [-37.799166666508427, -7.792499997276991],
              [-37.799999999000704, -7.792499992780392],
              [-37.799999999000704, -7.790833325997141],
              [-37.800833331493038, -7.790833325997141],
              [-37.800833332392358, -7.78916665921389],
              [-37.801666667582595, -7.78916665921389],
              [-37.801666666683275, -7.787499993329959],
              [-37.800833332392358, -7.787499993329959],
              [-37.800833334190997, -7.785833325647388],
              [-37.801666665783955, -7.785833325647388],
              [-37.801666667582595, -7.781666658689289],
              [-37.802500000974248, -7.781666658689289],
              [-37.802500000974248, -7.780833326197012],
              [-37.803333333466526, -7.780833326197012],
              [-37.803333334365846, -7.779999992805358],
              [-37.804166665958803, -7.779999992805358],
              [-37.804166667757443, -7.779166659413761],
              [-37.805833333641374, -7.779166660313081],
              [-37.805833333641374, -7.778333326921427],
              [-37.808333332916959, -7.778333325122787],
              [-37.808333332017639, -7.777499994429149],
              [-37.809166665409236, -7.77749999352983],
              [-37.809166664509917, -7.776666659238913],
              [-37.809999998800834, -7.776666659238913],
              [-37.809999998800834, -7.770833326396826],
              [-37.809166667207876, -7.770833326396826],
              [-37.809166667207876, -7.768333326221978],
              [-37.808333332916959, -7.768333326221978],
              [-37.808333333816279, -7.767499992830381],
              [-37.809166667207876, -7.767499992830381],
              [-37.809166665409236, -7.766666659438727],
              [-37.809999998800834, -7.766666659438727],
              [-37.81000000059953, -7.764999992655476],
              [-37.809166665409236, -7.764999992655476],
              [-37.809166667207876, -7.764166659263879],
              [-37.810833333991127, -7.764166659263879],
              [-37.810833333991127, -7.763333326771544],
              [-37.814166667557629, -7.763333325872225],
              [-37.814166666658309, -7.762499993379947],
              [-37.815833331642921, -7.762499993379947],
              [-37.81583333254224, -7.760833324798057],
              [-37.820833331992617, -7.760833327496016],
              [-37.820833332891937, -7.759166658914125],
              [-37.82166666808223, -7.759166658914125],
              [-37.82166666718291, -7.756666658739277],
              [-37.822499998775868, -7.756666658739277],
              [-37.822499999675188, -7.755833326246943],
              [-37.823333332167522, -7.755833325347623],
              [-37.823333334865481, -7.754999992855346],
              [-37.824166665559119, -7.754999992855346],
              [-37.824166669156398, -7.753333326072095],
              [-37.823333333066842, -7.753333326072095],
              [-37.823333332167522, -7.750833326796567],
              [-37.824166666458439, -7.750833325897247],
              [-37.824166666458439, -7.749166658214676],
              [-37.824999998950773, -7.749166659113996],
              [-37.824999999850093, -7.747499993230065],
              [-37.82583333234237, -7.747499993230065],
              [-37.82583333234237, -7.744999993055217],
              [-37.826666665733967, -7.744999993055217],
              [-37.826666667532663, -7.744166658764243],
              [-37.828333332517218, -7.744166658764243],
              [-37.828333332517218, -7.742499992880312],
              [-37.829166665908872, -7.742499992880312],
              [-37.829166667707511, -7.740833326097061],
              [-37.830000001998428, -7.74083332699638],
              [-37.829999998401149, -7.739999992705464],
              [-37.830833332692123, -7.739999992705464],
              [-37.830833332692123, -7.73916665931381],
              [-37.83166666698304, -7.73916665931381],
              [-37.83166666698304, -7.737499993429878],
              [-37.832499998576054, -7.737499993429878],
              [-37.832499999475374, -7.735833326646684],
              [-37.834166667157945, -7.735833325747365],
              [-37.834166668057264, -7.73499999325503],
              [-37.835000000549542, -7.73499999325503],
              [-37.834999998750902, -7.734166658064794],
              [-37.835833333041819, -7.734166658964114],
              [-37.835833332142499, -7.733333326471779],
              [-37.836666665534153, -7.733333326471779],
              [-37.836666666433473, -7.732499993080182],
              [-37.83749999892575, -7.732499992180863],
              [-37.837499998026431, -7.731666658789209],
              [-37.839166666608321, -7.731666658789209],
              [-37.839166666608321, -7.72916665951368],
              [-37.839999999100655, -7.72916665951368],
              [-37.84, -7.726666660238152],
              [-37.839166665709001, -7.726666658439513],
              [-37.839166666608321, -7.725833325947178],
              [-37.839999999100655, -7.725833326846498],
              [-37.84, -7.724999993454901],
              [-37.839166665709001, -7.724999993454901],
              [-37.839166666608321, -7.722499993279996],
              [-37.838333333216724, -7.722499993279996],
              [-37.838333334116044, -7.719999993105148],
              [-37.839166666608321, -7.719999993105148],
              [-37.839166666608321, -7.719166658814231],
              [-37.838333334116044, -7.719166658814231],
              [-37.838333331418085, -7.718333326321897],
              [-37.840833331592933, -7.718333326321897],
              [-37.840833331592933, -7.7174999929303],
              [-37.842499998376184, -7.7174999929303],
              [-37.842500000174823, -7.716666660437966],
              [-37.8433333326671, -7.716666658639326],
              [-37.8433333326671, -7.714999992755395],
              [-37.844166667857394, -7.714999991856075],
              [-37.844166666958074, -7.714166659363798],
              [-37.845000000349671, -7.714166659363798],
              [-37.844999999450351, -7.713333325972144],
              [-37.844166666058754, -7.713333325972144],
              [-37.844166667857394, -7.71166665918895],
              [-37.844166666958074, -7.709166659014045],
              [-37.843333330868461, -7.709166659913365],
              [-37.8433333326671, -7.708333326521768],
              [-37.842500001074143, -7.708333326521768],
              [-37.842499997476864, -7.705833325447543],
              [-37.841666667682546, -7.705833326346863],
              [-37.841666666783226, -7.704166658664292],
              [-37.840833331592933, -7.704166658664292],
              [-37.840833332492252, -7.702499992780417],
              [-37.839166666608321, -7.702499990981778],
              [-37.839166665709001, -7.701666659388763],
              [-37.838333331418085, -7.701666658489444],
              [-37.838333333216724, -7.700833325097847],
              [-37.836666666433473, -7.700833325997166],
              [-37.836666668232112, -7.699999991706193],
              [-37.835833333041819, -7.699999993504832],
              [-37.835833333041819, -7.699166657415276],
              [-37.835000000549542, -7.699166659213915],
              [-37.834999998750902, -7.694999993155136],
              [-37.835833332142499, -7.694999993155136],
              [-37.835833333041819, -7.6908333270963],
              [-37.836666668232112, -7.69083332619698],
              [-37.836666668232112, -7.689999992805383],
              [-37.835833333041819, -7.689999992805383],
              [-37.835833333041819, -7.688333325122812],
              [-37.834999999650222, -7.688333326022132],
              [-37.834999998750902, -7.682499993180102],
              [-37.835833333941139, -7.682499993180102],
              [-37.835833333041819, -7.681666658889128],
              [-37.835000000549542, -7.681666658889128],
              [-37.834999997851583, -7.680833325497531],
              [-37.834166665359248, -7.68083332729617],
              [-37.834166668057264, -7.679999993005254],
              [-37.833333331967651, -7.679999993005254],
              [-37.833333331967651, -7.67916665871428],
              [-37.830000000199789, -7.67916665781496],
              [-37.829999998401149, -7.678333325322683],
              [-37.829166667707511, -7.678333326222003],
              [-37.829166667707511, -7.677499992830349],
              [-37.827500000024941, -7.677499991931029],
              [-37.82750000092426, -7.676666659438752],
              [-37.826666667532663, -7.676666659438752],
              [-37.826666666633344, -7.675833326047098],
              [-37.824166666458439, -7.675833326047098],
              [-37.824166665559119, -7.674999991756181],
              [-37.82166666718291, -7.674999991756181],
              [-37.82166666718291, -7.674166659263847],
              [-37.815000000049906, -7.674166659263847],
              [-37.814999999150587, -7.67333332587225],
              [-37.813333331468016, -7.67333332497293],
              [-37.813333331468016, -7.674166658364527],
              [-37.811666666483404, -7.674166658364527],
              [-37.811666667382724, -7.67333332497293],
              [-37.809999998800834, -7.67333332497293],
              [-37.80999999970021, -7.674166658364527],
              [-37.806666666133708, -7.674166659263847],
              [-37.806666666133708, -7.674999992655501],
              [-37.805833331842734, -7.674999992655501],
              [-37.805833331842734, -7.675833326047098],
              [-37.798333333116773, -7.675833326047098],
              [-37.798333332217453, -7.676666659438752],
              [-37.796666667232842, -7.676666659438752],
              [-37.796666667232842, -7.675833326047098],
              [-37.794166667957313, -7.675833326946417],
              [-37.794166667057993, -7.674999992655501],
              [-37.793333332767077, -7.674999992655501],
              [-37.793333332767077, -7.675833326946417],
              [-37.791666666883145, -7.675833326047098],
              [-37.791666667782465, -7.676666659438752],
              [-37.790833331692852, -7.676666659438752],
              [-37.790833333491491, -7.677499993729668],
              [-37.790000000099894, -7.677499991931029],
              [-37.789999999200575, -7.679999993005254],
              [-37.78916666760756, -7.679999993904573],
              [-37.78916666670824, -7.680833325497531],
              [-37.788333334215963, -7.680833326396851],
              [-37.788333331518004, -7.684166658164713],
              [-37.78916666670824, -7.684166659963353],
              [-37.78916666670824, -7.684999994254269],
              [-37.788333331518004, -7.68499999245563],
              [-37.788333334215963, -7.685833325847284],
              [-37.78333333296689, -7.685833325847284],
              [-37.783333332067571, -7.68499999335495],
              [-37.781666666183639, -7.68499999335495],
              [-37.781666667982279, -7.683333325672379],
              [-37.779999998501069, -7.683333325672379],
              [-37.779999999400388, -7.682499993180102],
              [-37.779166665109472, -7.682499993180102],
              [-37.779166666908111, -7.680833326396851],
              [-37.774166664759719, -7.680833326396851],
              [-37.774166665659038, -7.681666658889128],
              [-37.773333334066081, -7.681666658889128],
              [-37.773333334066081, -7.682499992280782],
              [-37.772499998875787, -7.682499993180102],
              [-37.772499999775107, -7.683333325672379],
              [-37.769166664409966, -7.683333325672379],
              [-37.769166668007301, -7.675833326047098],
              [-37.768333331917688, -7.675833326047098],
              [-37.768333332817008, -7.672499994279292],
              [-37.76583333264216, -7.672499993379915],
              [-37.76583333174284, -7.671666658189679],
              [-37.760833331393087, -7.671666659088999],
              [-37.760833333191727, -7.670833324798082],
              [-37.759999999800129, -7.670833325697402],
              [-37.75999999890081, -7.668333326421816],
              [-37.759166666408476, -7.668333326421816],
              [-37.759166665509156, -7.669166658914151],
              [-37.757499999625225, -7.669166658914151],
              [-37.757499999625225, -7.669999993205067],
              [-37.756666668032267, -7.669999994104387],
              [-37.756666668032267, -7.668333326421816],
              [-37.755833333741293, -7.668333326421816],
              [-37.755833331942654, -7.667499993030219],
              [-37.754166666958042, -7.667499993929539],
              [-37.754166666958042, -7.666666658739246],
              [-37.753333332667125, -7.666666658739246],
              [-37.753333333566445, -7.665833326246968],
              [-37.750833332492277, -7.665833325347648],
              [-37.750833332492277, -7.664166659463717],
              [-37.750000000899263, -7.664166659463717],
              [-37.75, -7.66333332607212],
              [-37.748333333216692, -7.663333325172744],
              [-37.748333331418053, -7.662499992680466],
              [-37.747499998925775, -7.662499994479106],
              [-37.747499999825095, -7.660833325897215],
              [-37.746666666433441, -7.660833326796535],
              [-37.746666666433441, -7.659999993404938],
              [-37.74499999875087, -7.659999994304258],
              [-37.744999999650247, -7.659166659113964],
              [-37.744166665359273, -7.659166660013284],
              [-37.744166665359273, -7.658333325722367],
              [-37.743333331967676, -7.658333325722367],
              [-37.743333333766316, -7.657499993230033],
              [-37.742499998576022, -7.657499993230033],
              [-37.742500000374662, -7.656666658939116],
              [-37.741666666083745, -7.656666659838436],
              [-37.741666666083745, -7.657499992330713],
              [-37.740833334490731, -7.657499993230033],
              [-37.740833333591411, -7.656666658939116],
              [-37.740000000199814, -7.656666658939116],
              [-37.740000001099133, -7.655833326446839],
              [-37.73916666680816, -7.655833324648142],
              [-37.73916666680816, -7.654166659663588],
              [-37.740000000199814, -7.654166658764268],
              [-37.740000000199814, -7.651666659488683],
              [-37.740833333591411, -7.651666659488683],
              [-37.740833331792771, -7.649999992705432],
              [-37.741666666983065, -7.649999991806112],
              [-37.741666666983065, -7.649166659313835],
              [-37.742499999475342, -7.649166659313835],
              [-37.742500000374662, -7.648333325922181],
              [-37.741666666983065, -7.648333326821501],
              [-37.741666666083745, -7.647499993429903],
              [-37.742500000374662, -7.647499993429903],
              [-37.742500000374662, -7.644999994154375],
              [-37.741666666083745, -7.644999993255055],
              [-37.741666667882384, -7.644166659863401],
              [-37.740833331792771, -7.644166658964082],
              [-37.740833331792771, -7.643333326471804],
              [-37.73916666770748, -7.643333326471804],
              [-37.73916666500952, -7.64249999308015],
              [-37.738333331617923, -7.64249999308015],
              [-37.738333332517243, -7.641666658789234],
              [-37.737499999125589, -7.641666658789234],
              [-37.73500000074938, -7.641666658789234],
              [-37.734999998950741, -7.640833326296899],
              [-37.73333333306681, -7.640833326296899],
              [-37.733333333966129, -7.641666657889914],
              [-37.731666665384239, -7.641666658789234],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '112',
        OBJECTID: 152.0,
        Nome_area: 'Monteiro',
        COD_area: 'CA158',
        Import_bio: 'Muito Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Recuperação de Áreas Degradadas',
        Area_ha: 16820.0376,
        Shape_Leng: 1.05167,
        Shape_Area: 0.01379,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.143333333421225, -7.753930871662854],
              [-37.143333334066085, -7.753333326971415],
              [-37.142920710633732, -7.753333326860091],
              [-37.143333333421225, -7.753930871662854],
            ],
          ],
          [
            [
              [-37.143573266, -7.75427833299995],
              [-37.144166665659043, -7.754761105492904],
              [-37.144166665659043, -7.754166659463692],
              [-37.143496152540003, -7.7541666601873],
              [-37.143573266, -7.75427833299995],
            ],
          ],
          [
            [
              [-37.145833333341614, -7.756117057252859],
              [-37.145833333341614, -7.754999992855346],
              [-37.144460293986036, -7.754999992855346],
              [-37.145833333341614, -7.756117057252859],
            ],
          ],
          [
            [
              [-37.148328604, -7.758147135999923],
              [-37.149753819999901, -7.759678306999941],
              [-37.151668201999897, -7.762379688999943],
              [-37.153810925999899, -7.768120274999948],
              [-37.157032285999897, -7.772999460999932],
              [-37.157176529, -7.775454893999941],
              [-37.1567810069999, -7.776868321999939],
              [-37.151950733, -7.779844202999934],
              [-37.157199255, -7.790586797999945],
              [-37.158481261, -7.792332350999964],
              [-37.159812303, -7.793216263999963],
              [-37.163763059, -7.795839527999934],
              [-37.168254131, -7.795120340999975],
              [-37.168395603, -7.795122617999931],
              [-37.171366137999897, -7.795170425999965],
              [-37.171589461999901, -7.796536362999979],
              [-37.169728167, -7.797889630999984],
              [-37.1694540149999, -7.798797422999947],
              [-37.170667083, -7.800228535999954],
              [-37.17066807, -7.800229699999924],
              [-37.172486073999899, -7.799981010999939],
              [-37.17438297, -7.799721418999981],
              [-37.183035259, -7.790717242999966],
              [-37.187392545, -7.790734715999963],
              [-37.189032595999898, -7.791141725999988],
              [-37.191536767999899, -7.791169404999955],
              [-37.191538539, -7.791169424999954],
              [-37.194166665796175, -7.794341013375309],
              [-37.194166666458443, -7.792499992780392],
              [-37.193333333066846, -7.792499992780392],
              [-37.193333332167526, -7.790833325097822],
              [-37.192499998775872, -7.790833325097822],
              [-37.192499998775872, -7.789999991706225],
              [-37.191666667182915, -7.789999998900782],
              [-37.191666668082235, -7.784999993155111],
              [-37.190833331992621, -7.784999993155111],
              [-37.190833333791261, -7.78333332727118],
              [-37.18916666790733, -7.78333332637186],
              [-37.18916666610869, -7.781666658689289],
              [-37.188333332717093, -7.78166665778997],
              [-37.188333332717093, -7.780833327096332],
              [-37.186666667732482, -7.780833325297692],
              [-37.186666666833162, -7.779999993704678],
              [-37.184166666658314, -7.779999992805358],
              [-37.184166665758994, -7.779166659413761],
              [-37.18333333146802, -7.779166659413761],
              [-37.18333333146802, -7.778333326022107],
              [-37.182499998975743, -7.778333326022107],
              [-37.182499999875063, -7.77749999352983],
              [-37.181666666483409, -7.77749999352983],
              [-37.181666666483409, -7.776666659238913],
              [-37.180833333091812, -7.776666659238913],
              [-37.180833333091812, -7.774999993354982],
              [-37.179999999700158, -7.774999993354982],
              [-37.179999998800838, -7.773333325672411],
              [-37.17916666720788, -7.773333324773091],
              [-37.179166665409241, -7.768333326221978],
              [-37.179999998800838, -7.768333326221978],
              [-37.179999998800838, -7.767499992830381],
              [-37.17916666720788, -7.7674999937297],
              [-37.1791666681072, -7.766666659438727],
              [-37.178333332017587, -7.766666659438727],
              [-37.178333333816283, -7.759999993205099],
              [-37.17749999862599, -7.759999993205099],
              [-37.17749999952531, -7.758333326421848],
              [-37.178333332916964, -7.758333326421848],
              [-37.178333332017587, -7.757499992130874],
              [-37.17749999952531, -7.757499993030194],
              [-37.17749999952531, -7.756666658739277],
              [-37.178333332017587, -7.756666658739277],
              [-37.178333332916964, -7.755833327146263],
              [-37.178333332017587, -7.754999992855346],
              [-37.176666666133713, -7.754999991956026],
              [-37.176666667033032, -7.753333326072095],
              [-37.175833332742059, -7.753333326072095],
              [-37.175833332742059, -7.752499992680498],
              [-37.174166665958808, -7.752499992680498],
              [-37.174166665958808, -7.750833325897247],
              [-37.174999998451142, -7.750833325897247],
              [-37.175000001149101, -7.749999993404913],
              [-37.174166668656767, -7.749999993404913],
              [-37.174166666858127, -7.749166659113996],
              [-37.173333332567211, -7.749166659113996],
              [-37.173333331667891, -7.748333325722342],
              [-37.172499997376917, -7.748333325722342],
              [-37.172499999175557, -7.747499993230065],
              [-37.171666666683279, -7.747499993230065],
              [-37.171666667582599, -7.746666658939091],
              [-37.170833331492986, -7.746666658939091],
              [-37.170833331492986, -7.745833324648174],
              [-37.169999999900028, -7.745833324648174],
              [-37.169999999000709, -7.74499999215584],
              [-37.169166665609112, -7.744999993055217],
              [-37.166666667232846, -7.744999993055217],
              [-37.166666665434207, -7.745833324648174],
              [-37.16583333204261, -7.745833326446814],
              [-37.165833332941929, -7.746666658939091],
              [-37.164999997751636, -7.746666658939091],
              [-37.165000000449595, -7.747499994129385],
              [-37.16166666778247, -7.747499993230065],
              [-37.16166666598383, -7.746666658939091],
              [-37.160000000099899, -7.746666659838411],
              [-37.160000000999219, -7.745833326446814],
              [-37.158333332417328, -7.745833324648174],
              [-37.158333332417328, -7.744999993954536],
              [-37.157500000824314, -7.74499999215584],
              [-37.157499999025674, -7.744166658764243],
              [-37.156666666533397, -7.744166658764243],
              [-37.156666665634077, -7.744999993055217],
              [-37.154166665459172, -7.744999993055217],
              [-37.154166668157188, -7.745833326446814],
              [-37.153333332067575, -7.745833326446814],
              [-37.153333332067575, -7.746666658939091],
              [-37.152499998675978, -7.746666658939091],
              [-37.152499998675978, -7.747499993230065],
              [-37.150833332792047, -7.747499992330745],
              [-37.150833331892727, -7.748333325722342],
              [-37.150000002098352, -7.748333325722342],
              [-37.149999999400393, -7.749166659113996],
              [-37.149166666008796, -7.749166658214676],
              [-37.149166666908116, -7.749999993404913],
              [-37.149999999400393, -7.749999993404913],
              [-37.149999999400393, -7.750833325897247],
              [-37.149166666908116, -7.750833325897247],
              [-37.149166666008796, -7.751666659288844],
              [-37.148333332617142, -7.751666659288844],
              [-37.148333333516462, -7.750833325897247],
              [-37.147499999225545, -7.750833325897247],
              [-37.147500001024184, -7.751666659288844],
              [-37.146666665833891, -7.751666659288844],
              [-37.146666667632587, -7.752499992680498],
              [-37.145833331542974, -7.752499992680498],
              [-37.145833331542974, -7.753333326072095],
              [-37.146666666733267, -7.753333326072095],
              [-37.146666667632587, -7.754166660363012],
              [-37.147499999225545, -7.754166659463692],
              [-37.147500000124865, -7.754999992855346],
              [-37.146666666733267, -7.754999992855346],
              [-37.146666666087498, -7.756795032241653],
              [-37.148328604, -7.758147135999923],
            ],
          ],
          [
            [
              [-37.194999998950721, -7.795346668714919],
              [-37.194999998950721, -7.79499999295524],
              [-37.194712727165808, -7.79499999295524],
              [-37.194999998950721, -7.795346668714919],
            ],
          ],
          [
            [
              [-37.196666665733972, -7.797357979966355],
              [-37.196666665733972, -7.796666658839172],
              [-37.19609380438613, -7.796666658530061],
              [-37.196666665733972, -7.797357979966355],
            ],
          ],
          [
            [
              [-37.197957773, -7.798916070999947],
              [-37.217416027999903, -7.806685878999933],
              [-37.219230220842384, -7.805833326147024],
              [-37.218333333016858, -7.805833326147024],
              [-37.218333332117538, -7.804999992755427],
              [-37.217499998725941, -7.804999993654747],
              [-37.217500000524581, -7.804166659363773],
              [-37.21583333104337, -7.804166659363773],
              [-37.215833334640649, -7.803333325972176],
              [-37.211666665883854, -7.803333325972176],
              [-37.211666665883854, -7.802499993479842],
              [-37.207499999825075, -7.802499993479842],
              [-37.207500001623714, -7.801666659188925],
              [-37.205833333041824, -7.801666659188925],
              [-37.205833332142504, -7.800833325797328],
              [-37.205000001448866, -7.800833326696647],
              [-37.204999998750907, -7.799999994204313],
              [-37.204166667157892, -7.799999993304993],
              [-37.204166667157892, -7.799166659014077],
              [-37.199166665908876, -7.799166659014077],
              [-37.199166666808196, -7.798333326521742],
              [-37.198333331617903, -7.798333326521742],
              [-37.198333332517223, -7.797499993130145],
              [-37.19678434380576, -7.797499992294324],
              [-37.197957773, -7.798916070999947],
            ],
          ],
          [
            [
              [-37.227499998334643, -7.805655941430139],
              [-37.227499997626751, -7.804999992755427],
              [-37.225833332642139, -7.804999991856107],
              [-37.225833332571646, -7.805065309476733],
              [-37.227499998334643, -7.805655941430139],
            ],
          ],
          [
            [
              [-37.23000000036491, -7.8065418905601],
              [-37.229999999600238, -7.805833326147024],
              [-37.228000548690588, -7.805833326147024],
              [-37.23000000036491, -7.8065418905601],
            ],
          ],
          [
            [
              [-37.230561764, -7.806740967999928],
              [-37.230102937, -7.812793706999967],
              [-37.2301771039999, -7.813109070999926],
              [-37.230833332115104, -7.813395957662919],
              [-37.230833332092573, -7.813333326671625],
              [-37.231666668182129, -7.813333325772305],
              [-37.23166666548417, -7.812499993280028],
              [-37.230833332991892, -7.812499993280028],
              [-37.230833332092573, -7.810833326496777],
              [-37.23166666548417, -7.810833324698137],
              [-37.231666668182129, -7.808333326321929],
              [-37.230833334790532, -7.808333327221249],
              [-37.230833332991892, -7.806666658639358],
              [-37.230352075265053, -7.806666658639358],
              [-37.230561764, -7.806740967999928],
            ],
          ],
          [
            [
              [-37.225646019048924, -7.830833326097093],
              [-37.22583333174282, -7.830833326097093],
              [-37.22583333174282, -7.830341723946026],
              [-37.225646019048924, -7.830833326097093],
            ],
          ],
          [
            [
              [-37.222204540999897, -7.838660185999968],
              [-37.222092905, -7.842250017999958],
              [-37.222484612, -7.842830157999961],
              [-37.225867665, -7.844678460999946],
              [-37.227536061, -7.844690195999937],
              [-37.229271672367268, -7.844166659463724],
              [-37.228333331917668, -7.844166659463724],
              [-37.228333331917668, -7.84333332607207],
              [-37.22583333174282, -7.84333332607207],
              [-37.225833332642139, -7.841666659288876],
              [-37.224999998351223, -7.841666659288876],
              [-37.225000000149862, -7.839999994304264],
              [-37.223333332467291, -7.839999993404945],
              [-37.223333332467291, -7.839166659113971],
              [-37.222499999075637, -7.839166659113971],
              [-37.222499999075637, -7.838333325722374],
              [-37.22228577659876, -7.838333325722374],
              [-37.222204540999897, -7.838660185999968],
            ],
          ],
          [
            [
              [-37.238339928999899, -7.843931168999979],
              [-37.245833333141775, -7.846374529939906],
              [-37.245833333141775, -7.845833326246975],
              [-37.245000000649441, -7.845833327146295],
              [-37.244999998850801, -7.844999992855321],
              [-37.24333333206755, -7.844999992855321],
              [-37.24333333206755, -7.844166659463724],
              [-37.23916666780741, -7.844166660363044],
              [-37.239166666908091, -7.84333332607207],
              [-37.233333331368101, -7.84333332697139],
              [-37.233333331368101, -7.844020554565066],
              [-37.238339928999899, -7.843931168999979],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '113',
        OBJECTID: 154.0,
        Nome_area: 'Serra Talhada',
        COD_area: 'CA160',
        Import_bio: 'Extremamente Alta',
        Prior_acao: 'Muito Alta',
        Acao_princ: 'Criação de UC de Proteção Integral',
        Area_ha: 388825.862,
        Shape_Leng: 6.62667,
        Shape_Area: 0.3189,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.597737461999898, -7.742035388999965],
              [-38.597162714, -7.742854856999934],
              [-38.596960524999901, -7.744900738999949],
              [-38.597382579999902, -7.746722932999941],
              [-38.596844887672781, -7.749166659113996],
              [-38.597499999924992, -7.749166659113996],
              [-38.597499999025672, -7.747499993230065],
              [-38.598333331518006, -7.747499993230065],
              [-38.598333331518006, -7.746666658939091],
              [-38.597499999924992, -7.746666658939091],
              [-38.597499999924992, -7.745833326446814],
              [-38.598333332417326, -7.745833326446814],
              [-38.598333334215965, -7.74499999215584],
              [-38.599166665808923, -7.744999993055217],
              [-38.599166667265877, -7.741624867319126],
              [-38.597737461999898, -7.742035388999965],
            ],
          ],
          [
            [
              [-38.596661529377606, -7.749999993404913],
              [-38.596666665634075, -7.749999993404913],
              [-38.596666665659264, -7.74997664982163],
              [-38.596661529377606, -7.749999993404913],
            ],
          ],
          [
            [
              [-38.589957431999899, -7.755681464999955],
              [-38.586046075, -7.754905312999944],
              [-38.584322341, -7.753449106999949],
              [-38.582299573999897, -7.749063105999946],
              [-38.580967769, -7.747556885999925],
              [-38.579030401, -7.746599928999959],
              [-38.578018669, -7.746607372999952],
              [-38.576157028, -7.750287921999933],
              [-38.576183267, -7.752284814999943],
              [-38.575713009, -7.75368083299998],
              [-38.574827635, -7.754337306999959],
              [-38.574315753999898, -7.753178130999962],
              [-38.573966882, -7.752388097999927],
              [-38.573239147, -7.75166383799996],
              [-38.572104101, -7.751474760999931],
              [-38.568492459, -7.753208916999949],
              [-38.567700745, -7.754070670999962],
              [-38.5674374459999, -7.754357262999969],
              [-38.566961594, -7.754875212999927],
              [-38.565152401, -7.755960752999949],
              [-38.563932786999899, -7.756692537999981],
              [-38.562031244, -7.75689232299992],
              [-38.560129565999901, -7.757092122999942],
              [-38.558977784, -7.75682860299997],
              [-38.557826018999897, -7.756565085999972],
              [-38.552826677, -7.753699755999951],
              [-38.549212659, -7.752313670999975],
              [-38.547489631, -7.751084687999934],
              [-38.546189958999911, -7.749450443999929],
              [-38.543133327, -7.749001025999963],
              [-38.541218188, -7.751773244999982],
              [-38.538526504999901, -7.753257940999956],
              [-38.534249498, -7.754416238999979],
              [-38.53011636, -7.755043405999978],
              [-38.529682341999901, -7.75510926399994],
              [-38.524957108, -7.754644759999961],
              [-38.518381818, -7.749828292999956],
              [-38.516604867, -7.749329334999974],
              [-38.507429731999899, -7.749952092999932],
              [-38.500548695, -7.751482147999984],
              [-38.496575436999898, -7.750031891999976],
              [-38.495740094, -7.744483906999964],
              [-38.495799287, -7.738808436999926],
              [-38.490519933, -7.73466155099993],
              [-38.483333332270533, -7.73351563874583],
              [-38.483333331567962, -7.734166658964114],
              [-38.4799999998001, -7.734166658964114],
              [-38.48000000069942, -7.733333326471779],
              [-38.476412546811815, -7.733333326471779],
              [-38.47622318, -7.733370490999953],
              [-38.475016598735678, -7.733333326471779],
              [-38.474166667857389, -7.733333326471779],
              [-38.474166667857389, -7.734166658964114],
              [-38.472500001074138, -7.734166658964114],
              [-38.472499997476859, -7.733333326471779],
              [-38.470833332492248, -7.733333326471779],
              [-38.470833332194921, -7.733057814703329],
              [-38.468117145, -7.732428415999952],
              [-38.463975698, -7.730892146999961],
              [-38.462255281999902, -7.729539969999955],
              [-38.460952448999898, -7.728515995999934],
              [-38.457788272999899, -7.723436345999968],
              [-38.457083419, -7.718106981999944],
              [-38.456702236, -7.718009997999961],
              [-38.455017717, -7.717581408999924],
              [-38.450072011, -7.717372165999952],
              [-38.449728351, -7.717357626999957],
              [-38.449698861999899, -7.717356381999928],
              [-38.446666665933833, -7.717782645704232],
              [-38.446666665933833, -7.718333326321897],
              [-38.444994827535204, -7.718333326321897],
              [-38.443868366999901, -7.719264392999949],
              [-38.440018435, -7.721232207999951],
              [-38.438333332354901, -7.721510397938543],
              [-38.438333332017635, -7.721666658089759],
              [-38.437386802910893, -7.721666658430252],
              [-38.436735321999898, -7.721774209999975],
              [-38.436530504443489, -7.721666658738287],
              [-38.43583333274205, -7.721666658989079],
              [-38.435833333137126, -7.721300568765697],
              [-38.435475685, -7.721112764999932],
              [-38.434215993999899, -7.720451401999973],
              [-38.427625511, -7.730036702999943],
              [-38.418740505999899, -7.731116175999947],
              [-38.413966086999899, -7.729916322999931],
              [-38.412485878, -7.73010789999995],
              [-38.409507846999901, -7.730493282999941],
              [-38.406033453, -7.726880372999974],
              [-38.400824526, -7.720060750999956],
              [-38.403930934, -7.714442538999955],
              [-38.4017054729999, -7.713172843999926],
              [-38.388350948, -7.708890664999953],
              [-38.382253324, -7.707475195999967],
              [-38.379835910999901, -7.704942083999975],
              [-38.379063932999898, -7.703253229999964],
              [-38.375775738999899, -7.70067210499997],
              [-38.372581918, -7.699636432999976],
              [-38.370937042, -7.698415257999952],
              [-38.37016611, -7.69691589399996],
              [-38.370074065, -7.692649511999957],
              [-38.369853244, -7.692291808999927],
              [-38.369206176, -7.691243635999961],
              [-38.367472482, -7.69005109199998],
              [-38.366885131999901, -7.689647075999973],
              [-38.362821473999901, -7.687766770999934],
              [-38.361616542, -7.686302853999953],
              [-38.357495875, -7.67716191199997],
              [-38.355833332370885, -7.677996033670576],
              [-38.355833331642884, -7.678333326222003],
              [-38.355161052456587, -7.678333326222003],
              [-38.355000000078995, -7.678414128528002],
              [-38.355000000949246, -7.680833326396851],
              [-38.354194292169886, -7.680833326614228],
              [-38.35293576, -7.684701032999944],
              [-38.350609936999902, -7.685915996999936],
              [-38.350802595999902, -7.686993480999945],
              [-38.35031665, -7.688678879999944],
              [-38.349999999700174, -7.688800893299584],
              [-38.349999999700174, -7.689166659413729],
              [-38.349166667207896, -7.689166659413729],
              [-38.349166667207896, -7.689999992805383],
              [-38.348333332017603, -7.689999993704703],
              [-38.348333332017603, -7.692499992980231],
              [-38.346666666133672, -7.692499992980231],
              [-38.346666666133672, -7.693333326371885],
              [-38.345833330943435, -7.693333326371885],
              [-38.345833333641394, -7.694166658864162],
              [-38.345240879980459, -7.694166658864162],
              [-38.344691544999897, -7.694996722999969],
              [-38.341203264999898, -7.695928797999946],
              [-38.340833335052039, -7.695797888866254],
              [-38.340833335090281, -7.695833325647413],
              [-38.337499999725139, -7.695833327446053],
              [-38.337499999725139, -7.694999993155136],
              [-38.336666668132182, -7.694999994054456],
              [-38.336666668132182, -7.694166658864162],
              [-38.335833332941945, -7.694166658864162],
              [-38.335833332941945, -7.693113973731228],
              [-38.333075042, -7.693432295999976],
              [-38.330357917999898, -7.696147039999962],
              [-38.326685642, -7.693704509999982],
              [-38.324166666358508, -7.695517059519495],
              [-38.324166666358508, -7.695833325647413],
              [-38.323727138138331, -7.695833325647413],
              [-38.323333332373394, -7.69611669181745],
              [-38.323333332966911, -7.69666665903901],
              [-38.322569019740172, -7.696666659245232],
              [-38.320773024999902, -7.697958981999925],
              [-38.321057246, -7.702644797999943],
              [-38.318628753, -7.706670313999954],
              [-38.316204018999898, -7.709150677999966],
              [-38.316200039, -7.711587416999974],
              [-38.317552401999897, -7.713463976999951],
              [-38.317257289999901, -7.715524316999954],
              [-38.315803748999897, -7.716365150999921],
              [-38.312029692, -7.716313306999949],
              [-38.315882148, -7.72847294799993],
              [-38.312779909, -7.731562462999926],
              [-38.310929877, -7.737369559999936],
              [-38.3110237879999, -7.738841278999927],
              [-38.310876941999908, -7.739077745999966],
              [-38.308958280999903, -7.74216628299996],
              [-38.302157348, -7.745204358999954],
              [-38.303809688999898, -7.748993678999969],
              [-38.304175858, -7.750244710999939],
              [-38.305702129999901, -7.752598775999952],
              [-38.31675985, -7.764607493999967],
              [-38.315621666, -7.765123643999967],
              [-38.314483458, -7.765639804999971],
              [-38.313671859, -7.765726521999965],
              [-38.312860096999898, -7.765813213999927],
              [-38.310693375, -7.765372279999955],
              [-38.303339122999901, -7.76208393599995],
              [-38.301264911, -7.762342433999947],
              [-38.299817233999903, -7.763195613999937],
              [-38.299454446, -7.763980107999941],
              [-38.300984495, -7.767041933999965],
              [-38.301252362999897, -7.768703900999984],
              [-38.29984795, -7.771802516999974],
              [-38.295688513999899, -7.775203269999955],
              [-38.294601411, -7.777970216999964],
              [-38.295535861, -7.779587901999951],
              [-38.300235425, -7.783415556999956],
              [-38.3025747, -7.788398809999928],
              [-38.302524323999897, -7.791501231999972],
              [-38.298992461, -7.79743592799997],
              [-38.298220445, -7.800519464999986],
              [-38.297764040999901, -7.804363712999926],
              [-38.297746525, -7.813946935999978],
              [-38.293351102, -7.823045897999975],
              [-38.290595229, -7.824307083999942],
              [-38.2878828, -7.826137724999962],
              [-38.286526572, -7.82762008899994],
              [-38.286343839, -7.828999781999925],
              [-38.28679313, -7.830224215999985],
              [-38.285707432, -7.830922380999937],
              [-38.282321239999902, -7.830873934999929],
              [-38.280514971999899, -7.83148073199993],
              [-38.280000000199834, -7.831761661260247],
              [-38.280000000199834, -7.832499993779663],
              [-38.279166665908861, -7.832499992880344],
              [-38.2791666677075, -7.833333326271941],
              [-38.278333332517263, -7.833333326271941],
              [-38.278333333416583, -7.834166658764218],
              [-38.27749999912561, -7.834166658764218],
              [-38.277500000024929, -7.836666658939123],
              [-38.274166665559108, -7.836666658039803],
              [-38.274166665559108, -7.835833324648149],
              [-38.272500000574496, -7.835833326446789],
              [-38.272499998775857, -7.834999992155872],
              [-38.270833332891982, -7.834999993055192],
              [-38.270833332891982, -7.83257930804155],
              [-38.270756424785333, -7.832499992880344],
              [-38.269166667907371, -7.832499992880344],
              [-38.269166665209411, -7.83166665948869],
              [-38.268333333616397, -7.83166665948869],
              [-38.268333332717653, -7.830001063361113],
              [-38.268332294554078, -7.829999992705298],
              [-38.26166666558413, -7.829999991806119],
              [-38.26166666558413, -7.829166659313842],
              [-38.261336839538359, -7.829166659313842],
              [-38.259205231, -7.830456700999948],
              [-38.2570368619999, -7.831064261999933],
              [-38.24666666758543, -7.831159998727321],
              [-38.246666668132207, -7.83166665948869],
              [-38.245833332941913, -7.83166665948869],
              [-38.245833333480391, -7.831167691994858],
              [-38.245162616, -7.831173883999945],
              [-38.240148989875507, -7.830833325567039],
              [-38.239166665808909, -7.830833326097093],
              [-38.239166666708286, -7.831666660388009],
              [-38.23499999975013, -7.83166665948869],
              [-38.23499999975013, -7.830833326097093],
              [-38.234166666358533, -7.830833326097093],
              [-38.234166666358533, -7.829999992705439],
              [-38.23499999975013, -7.829999991806119],
              [-38.235000000648895, -7.825835900290336],
              [-38.228288439, -7.824066968999934],
              [-38.22359168, -7.821352437999963],
              [-38.221922827, -7.820388064999972],
              [-38.221251427996386, -7.819999992905309],
              [-38.220833332092582, -7.819999992905309],
              [-38.220833332222981, -7.819758331387079],
              [-38.218611258, -7.818473960999945],
              [-38.215284053999902, -7.815436797999953],
              [-38.212674175, -7.810712701999932],
              [-38.209192992522496, -7.809166658814206],
              [-38.209166668207104, -7.809166658814206],
              [-38.209166666408464, -7.8099999940045],
              [-38.206666668032256, -7.809999993105123],
              [-38.206666668032256, -7.810833326496777],
              [-38.205833331942699, -7.810833327396097],
              [-38.205833331942699, -7.812499993280028],
              [-38.205000002148324, -7.812499993280028],
              [-38.204999997651726, -7.813333325772305],
              [-38.204166667857407, -7.813333326671625],
              [-38.204166666058768, -7.81583332594721],
              [-38.203333333566434, -7.81583332594721],
              [-38.203333332667114, -7.818333326122058],
              [-38.202500000174837, -7.818333326122058],
              [-38.202500001074156, -7.819999992905309],
              [-38.201666666783183, -7.819999992905309],
              [-38.201666667682503, -7.820833326296906],
              [-38.2, -7.820833326296906],
              [-38.199999999100612, -7.82166665968856],
              [-38.199166667507654, -7.821666658789241],
              [-38.199166667507654, -7.822499993080157],
              [-38.198333333216738, -7.822499993080157],
              [-38.198333331418041, -7.821666658789241],
              [-38.197499999825084, -7.821666658789241],
              [-38.197499998925764, -7.820833326296906],
              [-38.196666668232126, -7.820833326296906],
              [-38.196666666433487, -7.819999992005989],
              [-38.194999998750916, -7.819999992905309],
              [-38.194999999650236, -7.819166659513655],
              [-38.194166665359262, -7.819166659513655],
              [-38.194166667157901, -7.817499992730461],
              [-38.193333331967665, -7.817499992730461],
              [-38.193333331068345, -7.816666659338807],
              [-38.19250000037465, -7.816666658439487],
              [-38.192499999475331, -7.81583332594721],
              [-38.190833333591456, -7.81583332594721],
              [-38.19083333179276, -7.814999993454876],
              [-38.189166666808205, -7.814999993454876],
              [-38.189166666808205, -7.81583332594721],
              [-38.188333332517232, -7.81583332504789],
              [-38.184166666458452, -7.81583332594721],
              [-38.184166666458452, -7.817499992730461],
              [-38.183333332167479, -7.817499992730461],
              [-38.183333333966175, -7.818333327021378],
              [-38.182500001473841, -7.818333326122058],
              [-38.182499998775882, -7.819999992905309],
              [-38.181666668082244, -7.819999992905309],
              [-38.181666668082244, -7.822499993080157],
              [-38.180833331992631, -7.822499993080157],
              [-38.18083333289195, -7.824166659863408],
              [-38.179166667008019, -7.824166658964089],
              [-38.179166667907339, -7.824999993255062],
              [-38.178333334515742, -7.824999993255062],
              [-38.178333332717102, -7.82583332574734],
              [-38.175833332542197, -7.82583332574734],
              [-38.175833332542197, -7.824999993255062],
              [-38.17500000094924, -7.824999993255062],
              [-38.1749999991506, -7.824166658964089],
              [-38.174166665758946, -7.824166658964089],
              [-38.174166666658323, -7.823333324673172],
              [-38.173333332367349, -7.823333326471811],
              [-38.173333334165989, -7.822499992180838],
              [-38.171666665584098, -7.822499993080157],
              [-38.171666665584098, -7.821666657889921],
              [-38.16916666540925, -7.821666658789241],
              [-38.16916666540925, -7.822499992180838],
              [-38.165833332742068, -7.822499993080157],
              [-38.165833333641388, -7.823333326471811],
              [-38.164999998451151, -7.823333324673172],
              [-38.164999998451151, -7.822499993080157],
              [-38.164166666858137, -7.822499993080157],
              [-38.164166665958817, -7.820833326296906],
              [-38.1633333316679, -7.820833326296906],
              [-38.16333333256722, -7.819166659513655],
              [-38.164166665958817, -7.819166657715016],
              [-38.164166666858137, -7.818333326122058],
              [-38.1633333316679, -7.818333326122058],
              [-38.1633333316679, -7.81583332594721],
              [-38.162500000974205, -7.81583332594721],
              [-38.162500000074886, -7.814166659163959],
              [-38.16333333256722, -7.814166658264639],
              [-38.16333333256722, -7.813333325772305],
              [-38.164166666858137, -7.813333325772305],
              [-38.164166665958817, -7.812499993280028],
              [-38.164999999350471, -7.812499993280028],
              [-38.164999998451151, -7.809999993105123],
              [-38.165833333641388, -7.809999993105123],
              [-38.165833333641388, -7.806666658639358],
              [-38.159999999900037, -7.806666657740038],
              [-38.159999998101398, -7.807499992930275],
              [-38.157499999725189, -7.807499992930275],
              [-38.15749999882587, -7.806666658639358],
              [-38.156666668132175, -7.806666660437998],
              [-38.156666668132175, -7.805833326147024],
              [-38.155833332042619, -7.805833327046344],
              [-38.155833332042619, -7.804999992755427],
              [-38.155000000449604, -7.804999993654747],
              [-38.154999999550284, -7.804166659363773],
              [-38.153333331867714, -7.804166659363773],
              [-38.153333331867714, -7.803333325972176],
              [-38.152500001174076, -7.803333325972176],
              [-38.152499999375436, -7.801666659188925],
              [-38.154166664360048, -7.801666658289605],
              [-38.154166666158687, -7.799166659014077],
              [-38.155000000449604, -7.799166658114757],
              [-38.154999998650965, -7.797499994029465],
              [-38.154166667058007, -7.797499992230826],
              [-38.154166667058007, -7.796666658839172],
              [-38.153333333666353, -7.796666658839172],
              [-38.153333331867714, -7.795833326346894],
              [-38.152499999375436, -7.795833326346894],
              [-38.152499999375436, -7.79499999295524],
              [-38.151666666883102, -7.79499999295524],
              [-38.151666666883102, -7.793333326172046],
              [-38.150000000999228, -7.793333327071366],
              [-38.150000000099908, -7.792499992780392],
              [-38.149166665808934, -7.792499992780392],
              [-38.149166665808934, -7.789999991706225],
              [-38.149999999200531, -7.789999991706225],
              [-38.149999999200531, -7.788333325822293],
              [-38.151666666883102, -7.788333325822293],
              [-38.151666666883102, -7.787499993329959],
              [-38.152500001174076, -7.787499994229279],
              [-38.152499998476117, -7.786666659039042],
              [-38.151666666883102, -7.786666659039042],
              [-38.151666665983782, -7.78333332547254],
              [-38.150833332592185, -7.78333332637186],
              [-38.150833332592185, -7.782499992980263],
              [-38.150651405594338, -7.782499992980263],
              [-38.150031472, -7.782874800999935],
              [-38.148473829, -7.783816541999952],
              [-38.148333331518018, -7.783836182116834],
              [-38.148333331518018, -7.784166658864194],
              [-38.147499999925003, -7.784166657964875],
              [-38.147499999925003, -7.784999993155111],
              [-38.144999999750155, -7.784999994054431],
              [-38.144999999750155, -7.784166658864194],
              [-38.144166668157141, -7.784166659763514],
              [-38.144166668157141, -7.783567607481136],
              [-38.143761944999902, -7.783406157999935],
              [-38.142316305, -7.784146110999929],
              [-38.140870628, -7.784886082999943],
              [-38.139196311, -7.78623808199995],
              [-38.139166666008805, -7.786304648853732],
              [-38.139166666008805, -7.788333324922974],
              [-38.138333332617151, -7.788333325822293],
              [-38.138333332617151, -7.789999993504864],
              [-38.137499999225554, -7.789999993504864],
              [-38.137499999225554, -7.790833325097822],
              [-38.13666666673322, -7.790833325997141],
              [-38.1366666658339, -7.791666659388795],
              [-38.135289330112435, -7.791666660131995],
              [-38.13499999938125, -7.791973002088604],
              [-38.134999999949969, -7.792499991881073],
              [-38.134502272921956, -7.792499992418211],
              [-38.134166666426005, -7.792855330927188],
              [-38.134166667457691, -7.793333326172046],
              [-38.133715214345358, -7.793333326172046],
              [-38.133333332294043, -7.793737661011668],
              [-38.133333331368078, -7.794166660462963],
              [-38.132928156087459, -7.794166659588444],
              [-38.132499999285891, -7.794619989906267],
              [-38.132499998875801, -7.79499999295524],
              [-38.132141097876399, -7.79499999295524],
              [-38.131666665669087, -7.795502319445306],
              [-38.131666666383524, -7.795833326346894],
              [-38.131443765501977, -7.795833326346894],
              [-38.131563153999899, -7.796327567999978],
              [-38.131101088, -7.799057],
              [-38.13065929895005, -7.801666659188925],
              [-38.13083333299187, -7.801666659188925],
              [-38.130833333891189, -7.802499993479842],
              [-38.130817007097065, -7.802499993479842],
              [-38.130898628999901, -7.802827190999952],
              [-38.131158243999899, -7.803867907999975],
              [-38.12943648200001, -7.805900569999956],
              [-38.125656385, -7.805344532999914],
              [-38.1224361989999, -7.807434479999929],
              [-38.124153970999899, -7.812980930999963],
              [-38.121666667641165, -7.816618744205665],
              [-38.121666667482657, -7.817499992730461],
              [-38.121064126418823, -7.817499992730461],
              [-38.120833332121656, -7.817837541642254],
              [-38.12083333319174, -7.818333327021378],
              [-38.120494346001692, -7.818333326838463],
              [-38.120055982999901, -7.818974455999975],
              [-38.118709773, -7.820943358999952],
              [-38.116666667132961, -7.822248191475037],
              [-38.116666667132961, -7.822499993080157],
              [-38.116272396338374, -7.822499993080157],
              [-38.1161781509999, -7.822560182999952],
              [-38.115833332765945, -7.822570452908349],
              [-38.115833331942667, -7.823333326471811],
              [-38.11499999945039, -7.823333326471811],
              [-38.11499999855107, -7.824166658964089],
              [-38.111666664984568, -7.824166658964089],
              [-38.111666667682528, -7.823333326471811],
              [-38.110833332492234, -7.823333326471811],
              [-38.110833332492234, -7.822719370627135],
              [-38.106996502999898, -7.822833644999952],
              [-38.106666665904811, -7.822843468669999],
              [-38.106666666433455, -7.823333324673172],
              [-38.105298241623011, -7.823333326149953],
              [-38.10499999983503, -7.823504589670687],
              [-38.105000000549524, -7.824166658964089],
              [-38.104166665359287, -7.824166659863408],
              [-38.104166667157926, -7.824999992355743],
              [-38.102499998576036, -7.824999993255062],
              [-38.102500000374675, -7.82583332574734],
              [-38.100944690371882, -7.82583332574734],
              [-38.100713449999901, -7.825966113999934],
              [-38.100711723, -7.825966790999935],
              [-38.099999998401188, -7.826245710781122],
              [-38.099999998401188, -7.826666659138937],
              [-38.098925856940824, -7.826666659138937],
              [-38.098333332915388, -7.826898865067743],
              [-38.098333331617937, -7.82749999342991],
              [-38.097499999125603, -7.82749999342991],
              [-38.097500000024922, -7.828333325922188],
              [-38.096666666633325, -7.828333326821507],
              [-38.096666666633325, -7.82749999342991],
              [-38.092499999675226, -7.82749999342991],
              [-38.092499998775907, -7.828333325922188],
              [-38.089999998601002, -7.828333325022868],
              [-38.089999999500321, -7.82749999342991],
              [-38.088440092046902, -7.82749999342991],
              [-38.085833333441542, -7.827787742971657],
              [-38.085833333441542, -7.828333325022868],
              [-38.082666075188286, -7.828333325877381],
              [-38.082499999047663, -7.828399989403705],
              [-38.08250000077436, -7.829999992705439],
              [-38.080833333091789, -7.829999992705439],
              [-38.080833333991109, -7.830833326996412],
              [-38.079999998800872, -7.830833325197773],
              [-38.079999998800872, -7.83166665769005],
              [-38.079166668107177, -7.83166665948869],
              [-38.079166665409218, -7.832499992880344],
              [-38.078333331118301, -7.832499992880344],
              [-38.078333332916941, -7.833333325372621],
              [-38.077499999525287, -7.833333325372621],
              [-38.077500000424664, -7.834166658764218],
              [-38.07666666703301, -7.834166658764218],
              [-38.07666666433505, -7.834999993055192],
              [-38.075000001149078, -7.834999993055192],
              [-38.074999999350439, -7.835833326446789],
              [-38.074166665958842, -7.835833326446789],
              [-38.074166667757481, -7.83333332717126],
              [-38.073333331667868, -7.833333325372621],
              [-38.073333333466508, -7.832499992880344],
              [-38.072500000074911, -7.832499992880344],
              [-38.072500000074911, -7.829999992705439],
              [-38.073333332567188, -7.829999992705439],
              [-38.073333331860553, -7.828035632844665],
              [-38.069376533, -7.826117067999973],
              [-38.069197157121486, -7.825833324864472],
              [-38.069166666508409, -7.82583332484802],
              [-38.069166666508409, -7.825785093729769],
              [-38.06867034406126, -7.824999993255062],
              [-38.067499999725158, -7.824999993255062],
              [-38.067499999725158, -7.824166658964089],
              [-38.066666665434241, -7.824166658964089],
              [-38.06666666723288, -7.823333326471811],
              [-38.065833332941907, -7.823333324673172],
              [-38.065833332941907, -7.822499993080157],
              [-38.0666666681322, -7.822499993080157],
              [-38.066666668854694, -7.821830508649066],
              [-38.066563087313661, -7.82166665968856],
              [-38.065833332941907, -7.82166665968856],
              [-38.065833332042587, -7.820833327196226],
              [-38.064166667957295, -7.820833326296906],
              [-38.064166667957295, -7.819999992905309],
              [-38.063333332767058, -7.819999992905309],
              [-38.063333332767058, -7.819166659513655],
              [-38.061666666883127, -7.819166657715016],
              [-38.061666667782447, -7.818333326122058],
              [-38.061666665983807, -7.814999991656236],
              [-38.062499997576765, -7.814999994354196],
              [-38.062499999375405, -7.814166659163959],
              [-38.063333332767058, -7.814166659163959],
              [-38.063333333666378, -7.813333325772305],
              [-38.064166667057975, -7.813333325772305],
              [-38.064166666158656, -7.812499993280028],
              [-38.067499998825838, -7.812499993280028],
              [-38.067499999725158, -7.811666658989054],
              [-38.068333332217492, -7.811666658989054],
              [-38.068333334016131, -7.810833326496777],
              [-38.070000000799382, -7.810833324698137],
              [-38.069999999000686, -7.809999993105123],
              [-38.07083333239234, -7.809999993105123],
              [-38.07083333149302, -7.808333326321929],
              [-38.071666667582576, -7.808333325422609],
              [-38.071666665783937, -7.807499992930275],
              [-38.07250000097423, -7.807499992930275],
              [-38.072499999175591, -7.806666658639358],
              [-38.073333331667868, -7.806666658639358],
              [-38.073333330768548, -7.804166659363773],
              [-38.074166665958842, -7.804166659363773],
              [-38.074166665958842, -7.800833325797328],
              [-38.075000001149078, -7.800833325797328],
              [-38.074999998689428, -7.797794420630573],
              [-38.07492338, -7.797578752999921],
              [-38.074895399562585, -7.797499993130145],
              [-38.074166666858162, -7.797499993130145],
              [-38.074166666858162, -7.796666658839172],
              [-38.071666665783937, -7.796666658839172],
              [-38.071666665783937, -7.795833325447575],
              [-38.07083333239234, -7.795833326346894],
              [-38.07083333149302, -7.792499992780392],
              [-38.071666665783937, -7.792499991881073],
              [-38.071666665783937, -7.789999993504864],
              [-38.072499999175591, -7.789999993504864],
              [-38.07250000097423, -7.788333325822293],
              [-38.073333331667868, -7.788333325822293],
              [-38.073333333466508, -7.787499993329959],
              [-38.074166665958842, -7.787499993329959],
              [-38.074166666858162, -7.786666659039042],
              [-38.075000001149078, -7.786666658139723],
              [-38.074999998451119, -7.785833325647388],
              [-38.074166665958842, -7.785833325647388],
              [-38.074166667757481, -7.784166658864194],
              [-38.073333332567188, -7.784166658864194],
              [-38.073333333466508, -7.781666658689289],
              [-38.072500000074911, -7.781666658689289],
              [-38.072499999175591, -7.778333326022107],
              [-38.07083333149302, -7.778333326022107],
              [-38.070833334190979, -7.77749999352983],
              [-38.069999999000686, -7.77749999352983],
              [-38.069999999900006, -7.775833325847259],
              [-38.069166667407728, -7.775833325847259],
              [-38.069166665609089, -7.774166658164688],
              [-38.068333333116811, -7.774166659064008],
              [-38.068333332217492, -7.772499993180077],
              [-38.069166666508409, -7.772499993180077],
              [-38.069166666112061, -7.772132729146051],
              [-38.068408668008416, -7.77166665888916],
              [-38.068333333116811, -7.77166665888916],
              [-38.068333333091815, -7.771620337712679],
              [-38.065833332941907, -7.770083162742861],
              [-38.065833332941907, -7.770833325497506],
              [-38.064166667957295, -7.770833326396826],
              [-38.064166666158656, -7.769999993005229],
              [-38.059999999200556, -7.769999993005229],
              [-38.059999999200556, -7.769166658714255],
              [-38.059166668506919, -7.769166658714255],
              [-38.059166665808959, -7.768333326221978],
              [-38.049999998501107, -7.768333325322658],
              [-38.050000001199066, -7.767499992830381],
              [-38.046666665833925, -7.767499992830381],
              [-38.046666666733245, -7.769166658714255],
              [-38.042499998875826, -7.769166658714255],
              [-38.042499998875826, -7.769999992105909],
              [-38.041666668182131, -7.769999992105909],
              [-38.041666665484172, -7.770833326396826],
              [-38.040833332092575, -7.770833326396826],
              [-38.040833332092575, -7.77166665978848],
              [-38.039999998700921, -7.77166665888916],
              [-38.039999998700921, -7.772499993180077],
              [-38.039166668007283, -7.772499993180077],
              [-38.039166667107963, -7.773333325672411],
              [-38.037499998526073, -7.773333324773091],
              [-38.037499999425393, -7.776666660138233],
              [-38.036666666933115, -7.776666659238913],
              [-38.036666666033796, -7.778333326022107],
              [-38.035833331742822, -7.778333326022107],
              [-38.035833332642142, -7.779166659413761],
              [-38.03416666765753, -7.779166659413761],
              [-38.034166665858891, -7.779999992805358],
              [-38.033333333366613, -7.779999992805358],
              [-38.033333332467294, -7.780833326197012],
              [-38.030833333191708, -7.780833326197012],
              [-38.030833331393069, -7.781666658689289],
              [-38.029999999800111, -7.781666658689289],
              [-38.030000000699431, -7.78333332547254],
              [-38.030833334091028, -7.78333332547254],
              [-38.030833334091028, -7.784166658864194],
              [-38.029166666408457, -7.784166658864194],
              [-38.029166666408457, -7.784999994054431],
              [-38.028333332117541, -7.784999992255791],
              [-38.028333332117541, -7.788333325822293],
              [-38.027499998725943, -7.788333325822293],
              [-38.027499998725943, -7.789999993504864],
              [-38.028333332117541, -7.789999993504864],
              [-38.02833333301686, -7.795833326346894],
              [-38.027499999625263, -7.795833327246214],
              [-38.027499998725943, -7.796666657939852],
              [-38.026666668032249, -7.796666658839172],
              [-38.026666667132929, -7.795833325447575],
              [-38.025833332842012, -7.795833326346894],
              [-38.025833333741332, -7.79499999295524],
              [-38.024999998551039, -7.79499999295524],
              [-38.024999998551039, -7.793333326172046],
              [-38.024166666958081, -7.793333326172046],
              [-38.024166666058761, -7.792499992780392],
              [-38.02249999837619, -7.792499992780392],
              [-38.02250000107415, -7.790833325997141],
              [-38.02249999837619, -7.789999993504864],
              [-38.023333332667107, -7.789999993504864],
              [-38.023333332667107, -7.78916665921389],
              [-38.024166666958081, -7.78916665921389],
              [-38.024166666058761, -7.787499993329959],
              [-38.024999998551039, -7.787499993329959],
              [-38.024999999450358, -7.784999993155111],
              [-38.024999998551039, -7.782499992080943],
              [-38.023333331767788, -7.782499992080943],
              [-38.023333333566427, -7.781666658689289],
              [-38.02250000017483, -7.78166665778997],
              [-38.02250000017483, -7.780833325297692],
              [-38.021666665883856, -7.780833326197012],
              [-38.021666665883856, -7.779999992805358],
              [-38.019999999100662, -7.779999993704678],
              [-38.019999999100662, -7.778333326022107],
              [-38.019166667507648, -7.778333325122787],
              [-38.019166666608328, -7.774999992455662],
              [-38.018333331418091, -7.774999994254301],
              [-38.018333333216731, -7.770833326396826],
              [-38.017499998925757, -7.770833326396826],
              [-38.017500000724397, -7.769999993005229],
              [-38.018333331418091, -7.769999992105909],
              [-38.018333334116051, -7.768333325322658],
              [-38.017499998026437, -7.768333326221978],
              [-38.017499999825077, -7.767499992830381],
              [-38.016666668232119, -7.767499991931061],
              [-38.016666668232119, -7.766666659438727],
              [-38.015833333041826, -7.766666657640087],
              [-38.015833333041826, -7.764166659263879],
              [-38.014999998750909, -7.764166660163198],
              [-38.014999998750909, -7.763333325872225],
              [-38.014827288634763, -7.763333325872225],
              [-38.014166667157895, -7.764024161561359],
              [-38.014166667157895, -7.764166659263879],
              [-38.014030401698292, -7.764166659263879],
              [-38.012463759, -7.765804953999973],
              [-38.009429156, -7.766553071999976],
              [-38.008146778, -7.766869214999936],
              [-38.006873051, -7.769206040999964],
              [-38.006863646999903, -7.773396627999944],
              [-38.005453823, -7.777762056999953],
              [-38.0039709549999, -7.778986407999979],
              [-38.003582326999897, -7.778983312999968],
              [-38.000719140999898, -7.778960512999959],
              [-37.998893829999901, -7.778945977999976],
              [-37.990267483, -7.778877285999948],
              [-37.988476252999902, -7.777696163999956],
              [-37.988324315206825, -7.77749999352983],
              [-37.987499999525312, -7.77749999352983],
              [-37.987500001248158, -7.776435702325364],
              [-37.987033449107926, -7.775833325847259],
              [-37.986666666133715, -7.775833325847259],
              [-37.986666666304068, -7.77535976391373],
              [-37.984790175999898, -7.772936982999968],
              [-37.982967856, -7.772648640999929],
              [-37.976416257, -7.774780236999965],
              [-37.972713749, -7.777530071999928],
              [-37.971363379, -7.777559985999972],
              [-37.971328131999897, -7.777560766999954],
              [-37.970599837999899, -7.777576900999962],
              [-37.970034819, -7.777128913999945],
              [-37.968333333055348, -7.775779856394976],
              [-37.96833333241733, -7.776666659238913],
              [-37.967500000824316, -7.776666659238913],
              [-37.967500000824316, -7.77749999352983],
              [-37.966666666533399, -7.77749999352983],
              [-37.966666665634079, -7.785833325647388],
              [-37.965833331343106, -7.785833325647388],
              [-37.965833334041065, -7.784999993155111],
              [-37.964166665459175, -7.784999993155111],
              [-37.964166665459175, -7.784166658864194],
              [-37.959166666908118, -7.784166658864194],
              [-37.959166666008798, -7.784999993155111],
              [-37.958333332617144, -7.784999993155111],
              [-37.958333332617144, -7.784166658864194],
              [-37.955833332442296, -7.784166658864194],
              [-37.955833332442296, -7.784999993155111],
              [-37.955000000849338, -7.784999993155111],
              [-37.955000000849338, -7.785833325647388],
              [-37.953333331368128, -7.785833324748069],
              [-37.953333334066087, -7.784999994054431],
              [-37.952499999775114, -7.784999993155111],
              [-37.952499999775114, -7.785833325647388],
              [-37.950000001398905, -7.785833325647388],
              [-37.950000001398905, -7.784999993155111],
              [-37.949166667107932, -7.784999993155111],
              [-37.949166667107932, -7.784166658864194],
              [-37.948333332817015, -7.784166659763514],
              [-37.948333331917695, -7.78333332637186],
              [-37.933333334465772, -7.78333332637186],
              [-37.933333333566452, -7.780833326197012],
              [-37.932500000174798, -7.780833326197012],
              [-37.932499998376159, -7.779166659413761],
              [-37.929166665709033, -7.779166660313081],
              [-37.929166666608353, -7.779999992805358],
              [-37.92833333141806, -7.779999992805358],
              [-37.928333333216699, -7.780833326197012],
              [-37.927499999825102, -7.780833326197012],
              [-37.927500000724422, -7.781666660487929],
              [-37.925833332142531, -7.781666658689289],
              [-37.925833333941171, -7.782499992980263],
              [-37.924999999650197, -7.782499992980263],
              [-37.924999999650197, -7.78333332637186],
              [-37.922499998576029, -7.78333332637186],
              [-37.922499998576029, -7.784166658864194],
              [-37.92000000109914, -7.784166658864194],
              [-37.919999997501861, -7.784999993155111],
              [-37.919166666808167, -7.784999993155111],
              [-37.919166665908847, -7.785833325647388],
              [-37.917500000024916, -7.785833325647388],
              [-37.917499999125596, -7.786666659039042],
              [-37.916666666633319, -7.786666659039042],
              [-37.916666666633319, -7.787499993329959],
              [-37.914999998950748, -7.787499993329959],
              [-37.914999998950748, -7.788333325822293],
              [-37.91416666735779, -7.788333325822293],
              [-37.914166664659831, -7.78916665921389],
              [-37.913333332167497, -7.78916666101253],
              [-37.913333333066817, -7.790833325997141],
              [-37.91416666645847, -7.790833325997141],
              [-37.914166665559151, -7.791666659388795],
              [-37.914999998950748, -7.791666659388795],
              [-37.914999998950748, -7.792499992780392],
              [-37.915833331443025, -7.792499991881073],
              [-37.915833332342345, -7.794166660462963],
              [-37.916666666633319, -7.794166658664324],
              [-37.916666665733999, -7.79499999295524],
              [-37.917500000024916, -7.79499999295524],
              [-37.917499999125596, -7.795833326346894],
              [-37.91833333341657, -7.795833326346894],
              [-37.91833333161793, -7.796666658839172],
              [-37.919166666808167, -7.796666658839172],
              [-37.919166666808167, -7.798333324723103],
              [-37.919999998401181, -7.798333327421062],
              [-37.92000000109914, -7.799166659014077],
              [-37.920833331792778, -7.799166659014077],
              [-37.920833332692098, -7.800833325797328],
              [-37.921666666983072, -7.800833324897951],
              [-37.921666667882391, -7.802499993479842],
              [-37.922499998576029, -7.802499993479842],
              [-37.922499998576029, -7.803333325972176],
              [-37.921666666983072, -7.803333326871496],
              [-37.921666667882391, -7.804166659363773],
              [-37.919166667707486, -7.804166659363773],
              [-37.919166665009527, -7.804999992755427],
              [-37.91833333341657, -7.804999993654747],
              [-37.91833333161793, -7.806666658639358],
              [-37.917499998226276, -7.806666658639358],
              [-37.917500000024916, -7.809166657914886],
              [-37.915833331443025, -7.809166658814206],
              [-37.915833335040361, -7.813333325772305],
              [-37.9124999987759, -7.813333325772305],
              [-37.9124999987759, -7.814166659163959],
              [-37.911666665384246, -7.814166659163959],
              [-37.911666665384246, -7.81583332504789],
              [-37.909999999500315, -7.81583332594721],
              [-37.909999999500315, -7.817499992730461],
              [-37.909166666108717, -7.817499992730461],
              [-37.909166666108717, -7.818333326122058],
              [-37.908333331817744, -7.818333326122058],
              [-37.908333332717064, -7.819999992905309],
              [-37.907500000224786, -7.819999992005989],
              [-37.907500001124106, -7.821666658789241],
              [-37.906666667732509, -7.821666658789241],
              [-37.906666666833189, -7.822499993080157],
              [-37.905833333441535, -7.822499993080157],
              [-37.905833330743576, -7.824166658964089],
              [-37.905000000049938, -7.824166658964089],
              [-37.904999999150618, -7.824999993255062],
              [-37.903333331468048, -7.824999993255062],
              [-37.903333331468048, -7.82749999432923],
              [-37.904166665758964, -7.82749999342991],
              [-37.904166666658284, -7.829166658414522],
              [-37.903333332367367, -7.829166659313842],
              [-37.903333331468048, -7.834999993954511],
              [-37.902499999875033, -7.834999992155872],
              [-37.902499998975713, -7.835833326446789],
              [-37.902500000774353, -7.836666658939123],
              [-37.901666665584116, -7.836666658939123],
              [-37.901666666483436, -7.838333325722374],
              [-37.900833333991102, -7.838333325722374],
              [-37.900833332192462, -7.840833325897222],
              [-37.900000001498825, -7.840833325897222],
              [-37.899999998800865, -7.844166659463724],
              [-37.899166667207908, -7.844166659463724],
              [-37.899166668107227, -7.844999992855321],
              [-37.899999999700185, -7.844999992855321],
              [-37.899999998800865, -7.845833326246975],
              [-37.900833332192462, -7.845833326246975],
              [-37.900833333091782, -7.847499993030226],
              [-37.899999998800865, -7.847499993030226],
              [-37.899999998800865, -7.848333326421823],
              [-37.899166665409211, -7.848333325522503],
              [-37.899166665409211, -7.849999993205074],
              [-37.899999998800865, -7.849999993205074],
              [-37.899999998800865, -7.850833325697351],
              [-37.900833333091782, -7.850833325697351],
              [-37.900833332192462, -7.851666659089005],
              [-37.901666665584116, -7.851666659089005],
              [-37.901666666483436, -7.852499993379922],
              [-37.903333334166007, -7.852499993379922],
              [-37.903333332367367, -7.853333325872256],
              [-37.906666665933869, -7.853333325872256],
              [-37.906666666833189, -7.854166660163173],
              [-37.907500000224786, -7.854166659263853],
              [-37.907500001124106, -7.853333325872256],
              [-37.908333332717064, -7.853333325872256],
              [-37.908333332717064, -7.852499992480602],
              [-37.909166666108717, -7.852499994279242],
              [-37.909166667008037, -7.851666659089005],
              [-37.910833331992649, -7.851666659089005],
              [-37.910833332891968, -7.850833325697351],
              [-37.9124999987759, -7.850833325697351],
              [-37.912499999675219, -7.849999993205074],
              [-37.913333333966136, -7.849999993205074],
              [-37.913333333066817, -7.848333326421823],
              [-37.914999998950748, -7.848333326421823],
              [-37.914999998950748, -7.847499993030226],
              [-37.917499999125596, -7.847499993030226],
              [-37.917500000924235, -7.846666658739252],
              [-37.91833333251725, -7.846666658739252],
              [-37.91833333251725, -7.844999993754641],
              [-37.919166666808167, -7.844999992855321],
              [-37.919166667707486, -7.844166659463724],
              [-37.92000000109914, -7.844166659463724],
              [-37.92000000109914, -7.84333332517275],
              [-37.928333334116019, -7.84333332607207],
              [-37.92833333141806, -7.840833325897222],
              [-37.927499999825102, -7.840833325897222],
              [-37.927500000724422, -7.839999993404945],
              [-37.928333334116019, -7.839999993404945],
              [-37.928333334116019, -7.839166659113971],
              [-37.93, -7.839166660013291],
              [-37.93, -7.838333325722374],
              [-37.932500001074175, -7.838333325722374],
              [-37.932500001074175, -7.83749999323004],
              [-37.934999998551064, -7.83749999323004],
              [-37.934999998551064, -7.838333325722374],
              [-37.939166666408482, -7.838333325722374],
              [-37.939166665509163, -7.83749999323004],
              [-37.943333331567942, -7.83749999323004],
              [-37.943333332467262, -7.838333327521013],
              [-37.948333331917695, -7.838333325722374],
              [-37.948333331917695, -7.839166659113971],
              [-37.949999999600266, -7.839166659113971],
              [-37.950000001398905, -7.839999993404945],
              [-37.950833332092543, -7.839999993404945],
              [-37.950833333891183, -7.840833325897222],
              [-37.952499999775114, -7.840833325897222],
              [-37.952500000674434, -7.841666659288876],
              [-37.953333334066087, -7.841666659288876],
              [-37.953333331368128, -7.842499992680473],
              [-37.954166667457685, -7.842499991781153],
              [-37.954166667457685, -7.84333332607207],
              [-37.954999999050699, -7.84333332607207],
              [-37.955000000849338, -7.844999992855321],
              [-37.954166667457685, -7.844999992855321],
              [-37.954166665659045, -7.848333326421823],
              [-37.955833331542976, -7.848333326421823],
              [-37.955833335140255, -7.849166658914157],
              [-37.956666664934573, -7.849166658914157],
              [-37.956666665833893, -7.849999993205074],
              [-37.958333333516464, -7.849999993205074],
              [-37.958333331717824, -7.852499993379922],
              [-37.959166666008798, -7.852499993379922],
              [-37.959166666008798, -7.854999992655507],
              [-37.959999998501075, -7.854999991756188],
              [-37.960000001199035, -7.856666659438758],
              [-37.959166666908118, -7.856666659438758],
              [-37.959166666908118, -7.857499992830355],
              [-37.957500000124867, -7.857499992830355],
              [-37.957500001024187, -7.858333326221953],
              [-37.956666665833893, -7.858333326221953],
              [-37.956666666733213, -7.859166658714287],
              [-37.955833332442296, -7.859166658714287],
              [-37.955833331542976, -7.861666658889135],
              [-37.955000000849338, -7.861666658889135],
              [-37.954999999950019, -7.863333325672386],
              [-37.956666666733213, -7.863333325672386],
              [-37.956666667632589, -7.864999993354957],
              [-37.957500001024187, -7.864999993354957],
              [-37.957500001024187, -7.866666659238888],
              [-37.958333331717824, -7.866666659238888],
              [-37.958333333516464, -7.867499993529805],
              [-37.957499999225547, -7.867499993529805],
              [-37.957500000124867, -7.86999999280539],
              [-37.956666665833893, -7.86999999280539],
              [-37.956666667632589, -7.870833326196987],
              [-37.956666665833893, -7.873333326371892],
              [-37.957499999225547, -7.873333326371892],
              [-37.957500001024187, -7.874166658864169],
              [-37.958333333516464, -7.874166658864169],
              [-37.958333333516464, -7.87583332564742],
              [-37.959166666908118, -7.87583332564742],
              [-37.959166667807438, -7.876666659039017],
              [-37.960833333691369, -7.876666659039017],
              [-37.960833332792049, -7.879166660113242],
              [-37.960000001199035, -7.879166659213922],
              [-37.959999999400395, -7.879999993504839],
              [-37.959166666908118, -7.879999993504839],
              [-37.959166665109478, -7.880833325997173],
              [-37.958333332617144, -7.880833325997173],
              [-37.958333332617144, -7.88166665938877],
              [-37.959166666008798, -7.881666658489451],
              [-37.959166666908118, -7.884166658664299],
              [-37.959999999400395, -7.884166658664299],
              [-37.959999998501075, -7.89333332687147],
              [-37.960833333691369, -7.893333325972151],
              [-37.960833333691369, -7.894166659363805],
              [-37.962499997776661, -7.894166659363805],
              [-37.96250000047462, -7.894999992755402],
              [-37.967500000824316, -7.894999992755402],
              [-37.967499998126357, -7.895833326147056],
              [-37.968333331518011, -7.895833326147056],
              [-37.96833333241733, -7.896666658639333],
              [-37.969999999200581, -7.896666658639333],
              [-37.969999999200581, -7.898333326321904],
              [-37.970833331692859, -7.898333326321904],
              [-37.970833331692859, -7.899166659713501],
              [-37.971666665983832, -7.899166658814181],
              [-37.971666667782472, -7.899999993105155],
              [-37.97249999847611, -7.899999993105155],
              [-37.973333332767027, -7.899999993105155],
              [-37.973333331867707, -7.900833326496752],
              [-37.974999998650958, -7.900833324698112],
              [-37.975000000449597, -7.901666658989086],
              [-37.975833332042612, -7.901666658989086],
              [-37.975833332042612, -7.902499993280003],
              [-37.977499999725183, -7.902499993280003],
              [-37.977499999725183, -7.903333325772337],
              [-37.97833333221746, -7.903333324873017],
              [-37.97833333221746, -7.904166659163934],
              [-37.979166665609114, -7.904166659163934],
              [-37.979166666508434, -7.904999993454908],
              [-37.979999999900031, -7.904999993454908],
              [-37.979999999000711, -7.905833325947185],
              [-37.981666666683282, -7.905833325947185],
              [-37.981666667582601, -7.906666659338839],
              [-37.982500000074879, -7.906666658439462],
              [-37.982500000074879, -7.907499992730436],
              [-37.983333332567213, -7.907499991831116],
              [-37.983333331667893, -7.908333326122033],
              [-37.984999998451144, -7.908333326122033],
              [-37.985000001149103, -7.909166659513687],
              [-37.987499999525312, -7.909166659513687],
              [-37.987499998625992, -7.909999992905284],
              [-37.988333332916909, -7.909999992905284],
              [-37.988333332916909, -7.910833327196258],
              [-37.990833333091814, -7.910833325397618],
              [-37.990833333091814, -7.911666658789215],
              [-37.992499999875065, -7.911666657889896],
              [-37.992499998076426, -7.913333326471786],
              [-37.993333331468023, -7.913333324673147],
              [-37.993333331468023, -7.91416665896412],
              [-37.995000000049913, -7.91416665896412],
              [-37.995000000049913, -7.914999993255037],
              [-37.997500001124138, -7.914999993255037],
              [-37.997499998426122, -7.915833326646634],
              [-37.999166667008012, -7.915833325747315],
              [-37.999166666108692, -7.916666659138968],
              [-37.999999998601027, -7.916666659138968],
              [-37.999999998601027, -7.917499993429885],
              [-38.001666665384278, -7.917499993429885],
              [-38.001666667182917, -7.91833332592222],
              [-38.002500000574514, -7.91833332592222],
              [-38.002500000574514, -7.919166659313817],
              [-38.003333332167529, -7.919166659313817],
              [-38.003333332167529, -7.920833326097068],
              [-38.004166665559126, -7.920833326097068],
              [-38.004166667357765, -7.921666660388041],
              [-38.008333332517225, -7.921666659488722],
              [-38.008333331617905, -7.922499992880319],
              [-38.012499998576004, -7.922499992880319],
              [-38.012499999475381, -7.921666659488722],
              [-38.015833333041826, -7.921666659488722],
              [-38.015833333941146, -7.922499992880319],
              [-38.01666666643348, -7.922499992880319],
              [-38.01666666553416, -7.923333326271916],
              [-38.018333333216731, -7.923333326271916],
              [-38.018333334116051, -7.92416665876425],
              [-38.02, -7.92416665876425],
              [-38.019999999100662, -7.924999993055167],
              [-38.024166666958081, -7.924999993055167],
              [-38.024166666058761, -7.925833324648181],
              [-38.025833332842012, -7.925833326446821],
              [-38.025833331942692, -7.926666658939098],
              [-38.026666666233609, -7.926666658039778],
              [-38.026666668032249, -7.92583332734614],
              [-38.027499999625263, -7.925833326446821],
              [-38.027499999625263, -7.921666659488722],
              [-38.029166668207154, -7.921666657690025],
              [-38.029166666408457, -7.920833326097068],
              [-38.029999998900792, -7.920833326996387],
              [-38.029999999800111, -7.921666659488722],
              [-38.030833331393069, -7.921666660388041],
              [-38.030833331393069, -7.920833326097068],
              [-38.035000000149864, -7.920833326097068],
              [-38.035000000149864, -7.919999992705471],
              [-38.036666666033796, -7.919999992705471],
              [-38.036666666033796, -7.920833326097068],
              [-38.042499999775146, -7.920833331492986],
              [-38.043333333166743, -7.920833326996387],
              [-38.043333333166743, -7.921666659488722],
              [-38.045833331542951, -7.921666659488722],
              [-38.045833331542951, -7.922499992880319],
              [-38.050000001199066, -7.922499993779638],
              [-38.049999998501107, -7.923333326271916],
              [-38.051666667082998, -7.923333326271916],
              [-38.051666667082998, -7.921666659488722],
              [-38.054999998850803, -7.921666659488722],
              [-38.054999998850803, -7.924999993055167],
              [-38.055833333141777, -7.924999993055167],
              [-38.055833333141777, -7.925833326446821],
              [-38.056666665634054, -7.925833326446821],
              [-38.056666666533374, -7.927499993230072],
              [-38.057500000824348, -7.927499993230072],
              [-38.057499999025708, -7.928333325722349],
              [-38.058333334215945, -7.928333325722349],
              [-38.058333334215945, -7.930833325897254],
              [-38.059166667607599, -7.930833326796574],
              [-38.059166666708279, -7.92999999340492],
              [-38.06083333259221, -7.9299999925056],
              [-38.06083333259221, -7.929166658214683],
              [-38.061666666883127, -7.929166660013323],
              [-38.061666667782447, -7.928333325722349],
              [-38.062499997576765, -7.928333325722349],
              [-38.062499998476085, -7.926666658939098],
              [-38.064999999550309, -7.926666658939098],
              [-38.065000000449629, -7.925833326446821],
              [-38.065833332042587, -7.925833324648181],
              [-38.065833332042587, -7.924999993954486],
              [-38.066666665434241, -7.924999993055167],
              [-38.066666665434241, -7.92416665876425],
              [-38.069166666508409, -7.92416665876425],
              [-38.069166667407728, -7.924999993055167],
              [-38.069999999000686, -7.924999992155847],
              [-38.069999999900006, -7.925833326446821],
              [-38.07083333149302, -7.925833326446821],
              [-38.070833334190979, -7.927499993230072],
              [-38.071666666683257, -7.927499993230072],
              [-38.071666667582576, -7.932499992680448],
              [-38.069999999000686, -7.932499992680448],
              [-38.069999999900006, -7.934166659463699],
              [-38.069166666508409, -7.934166659463699],
              [-38.069166667407728, -7.934999992855353],
              [-38.074166666858162, -7.934999992855353],
              [-38.074166667757481, -7.93583332714627],
              [-38.077499998625967, -7.93583332534763],
              [-38.077500000424664, -7.936666658739284],
              [-38.08249999987504, -7.936666658739284],
              [-38.08249999987504, -7.940833325697383],
              [-38.081666669181402, -7.940833325697383],
              [-38.081666665584123, -7.943333325872231],
              [-38.08249999987504, -7.943333325872231],
              [-38.08249999987504, -7.944999992655482],
              [-38.081666665584123, -7.944999992655482],
              [-38.081666669181402, -7.949166658714262],
              [-38.083333331468054, -7.949166658714262],
              [-38.083333334166014, -7.949999993005235],
              [-38.085000000049945, -7.949999993904555],
              [-38.084999999150625, -7.950833326396832],
              [-38.085833333441542, -7.950833326396832],
              [-38.085833332542222, -7.949999993005235],
              [-38.08833333271707, -7.949999992105916],
              [-38.08833333271707, -7.950833326396832],
              [-38.089166667008044, -7.950833326396832],
              [-38.089166667008044, -7.951666658889167],
              [-38.090000000399641, -7.951666658889167],
              [-38.089999999500321, -7.952499993180083],
              [-38.090833333791295, -7.952499993180083],
              [-38.090833332891975, -7.953333325672418],
              [-38.091666668082212, -7.953333325672418],
              [-38.091666665384253, -7.954166659064015],
              [-38.093333333066823, -7.954166659064015],
              [-38.093333333966143, -7.954999993354988],
              [-38.094999999850074, -7.954999993354988],
              [-38.095833331443032, -7.954999994254308],
              [-38.095833332342352, -7.954166659064015],
              [-38.096666665734006, -7.954166659064015],
              [-38.096666667532645, -7.953333325672418],
              [-38.097500000024922, -7.953333324773098],
              [-38.097500000924242, -7.952499993180083],
              [-38.100833333591424, -7.952499992280764],
              [-38.100833331792785, -7.951666658889167],
              [-38.103333331967633, -7.951666657989847],
              [-38.103333332866953, -7.952499993180083],
              [-38.104999999650204, -7.952499993180083],
              [-38.105000000549524, -7.951666658889167],
              [-38.108333333216706, -7.951666658889167],
              [-38.108333331418066, -7.950833327296152],
              [-38.109999999100637, -7.950833326396832],
              [-38.109999999100637, -7.949999993005235],
              [-38.111666665883888, -7.949999992105916],
              [-38.111666665883888, -7.949166658714262],
              [-38.112500001973444, -7.949166658714262],
              [-38.112500001074125, -7.949999993005235],
              [-38.114166666958056, -7.949999993005235],
              [-38.114166666058736, -7.950833326396832],
              [-38.115833332841987, -7.950833325497513],
              [-38.115833332841987, -7.951666658889167],
              [-38.120833331393101, -7.951666658889167],
              [-38.120833331393101, -7.952499993180083],
              [-38.122499999974991, -7.952499993180083],
              [-38.122499999075671, -7.949999993005235],
              [-38.123333331567949, -7.949999993005235],
              [-38.123333332467269, -7.950833326396832],
              [-38.124166666758242, -7.950833326396832],
              [-38.124166666758242, -7.949999993005235],
              [-38.126666666033771, -7.949999993005235],
              [-38.12666666693309, -7.950833326396832],
              [-38.133333333166775, -7.950833326396832],
              [-38.133333334066094, -7.949166658714262],
              [-38.138333331717831, -7.949166658714262],
              [-38.138333331717831, -7.948333326221984],
              [-38.139166666008805, -7.948333326221984],
              [-38.139999999400402, -7.948333326221984],
              [-38.140000001199041, -7.949166658714262],
              [-38.140833331892736, -7.949166658714262],
              [-38.140833333691376, -7.952499993180083],
              [-38.141666667082973, -7.952499993180083],
              [-38.141666667082973, -7.953333325672418],
              [-38.143333333866224, -7.953333327471057],
              [-38.143333332067584, -7.954166659064015],
              [-38.144166666358501, -7.954166659064015],
              [-38.144166665459181, -7.955833325847266],
              [-38.144999998850835, -7.955833325847266],
              [-38.144999999750155, -7.956666659238863],
              [-38.144166668157141, -7.956666659238863],
              [-38.144166665459181, -7.957499993529837],
              [-38.143333332966904, -7.957499994429156],
              [-38.143333332966904, -7.958333326022114],
              [-38.142500000474627, -7.958333325122794],
              [-38.142500000474627, -7.963333326371867],
              [-38.141666667082973, -7.963333326371867],
              [-38.141666667082973, -7.964999993155118],
              [-38.14249999957525, -7.964999994054438],
              [-38.142499997776611, -7.965833325647395],
              [-38.144166668157141, -7.965833325647395],
              [-38.144166665459181, -7.966666659938369],
              [-38.144999998850835, -7.966666659039049],
              [-38.145000000649475, -7.967499993329966],
              [-38.145833333141752, -7.967499993329966],
              [-38.145833334041072, -7.969166658314577],
              [-38.144999998850835, -7.969166659213897],
              [-38.145000001548794, -7.969999994404191],
              [-38.144166666358501, -7.969999993504871],
              [-38.144166665459181, -7.973333326171996],
              [-38.143333332966904, -7.973333327071316],
              [-38.143333332067584, -7.979166659014084],
              [-38.141666666183653, -7.979166659014084],
              [-38.141666667982292, -7.979999993305],
              [-38.140833333691376, -7.979999993305],
              [-38.140833332792056, -7.980833324897958],
              [-38.138333331717831, -7.980833326696597],
              [-38.138333332617151, -7.981666659188932],
              [-38.137499999225554, -7.981666659188932],
              [-38.137500001024193, -7.982499993479848],
              [-38.134999999949969, -7.982499993479848],
              [-38.134999999949969, -7.981666659188932],
              [-38.134166667457691, -7.981666659188932],
              [-38.134166665659052, -7.982499993479848],
              [-38.133333333166775, -7.982499993479848],
              [-38.133333333166775, -7.985833326147031],
              [-38.131666666383524, -7.985833326147031],
              [-38.131666668182163, -7.987499992030962],
              [-38.128333332817022, -7.987499992930282],
              [-38.128333333716341, -7.989166658814213],
              [-38.126666666033771, -7.989166658814213],
              [-38.12666666783241, -7.992499993280035],
              [-38.124166664959603, -7.992499994179354],
              [-38.124166667657562, -7.993333325772312],
              [-38.123333333366588, -7.993333326671632],
              [-38.123333333366588, -7.994166659163966],
              [-38.124166667657562, -7.994166659163966],
              [-38.124166667657562, -7.996666659338814],
              [-38.123333333366588, -7.996666659338814],
              [-38.123333333366588, -7.997499992730411],
              [-38.122500000874311, -7.997499992730411],
              [-38.122500000874311, -7.999166659513662],
              [-38.123333332467269, -7.999166659513662],
              [-38.123333333366588, -7.999999992905316],
              [-38.122499999974991, -7.999999992005996],
              [-38.122500000874311, -8.003333326471818],
              [-38.123333332467269, -8.003333327371138],
              [-38.123333332467269, -8.007499993429917],
              [-38.122499999075671, -8.007499993429917],
              [-38.122499998176352, -8.008333325922194],
              [-38.124166667657562, -8.008333325922194],
              [-38.124166665858922, -8.009166659313848],
              [-38.125000001049159, -8.009166658414529],
              [-38.125000000149839, -8.010833326097099],
              [-38.127499999425368, -8.010833326097099],
              [-38.127499999425368, -8.011666659488696],
              [-38.128333331917702, -8.011666659488696],
              [-38.128333333716341, -8.012499992880294],
              [-38.129166667107938, -8.012499992880294],
              [-38.129166666208619, -8.013333326271947],
              [-38.13083333209255, -8.013333326271947],
              [-38.13083333209255, -8.014166658764225],
              [-38.131666665484204, -8.014166658764225],
              [-38.132499999775121, -8.014166658764225],
              [-38.132499998875801, -8.013333325372628],
              [-38.134166668357011, -8.013333325372628],
              [-38.134166664759732, -8.014166658764225],
              [-38.134999999050649, -8.014166658764225],
              [-38.135000000849288, -8.014999992155879],
              [-38.1366666658339, -8.014999993055199],
              [-38.1366666658339, -8.015833324648156],
              [-38.137499999225554, -8.015833326446796],
              [-38.137500001024193, -8.01666665803981],
              [-38.139166666908125, -8.01666665893913],
              [-38.139166666008805, -8.024999992855328],
              [-38.138333331717831, -8.024999992855328],
              [-38.138333333516471, -8.025833326246982],
              [-38.137500000124874, -8.025833326246982],
              [-38.137499999225554, -8.027499993929553],
              [-38.136666667632539, -8.027499992130913],
              [-38.1366666658339, -8.026666658739259],
              [-38.135833331542983, -8.026666658739259],
              [-38.135833331542983, -8.024999992855328],
              [-38.135000000849288, -8.024999992855328],
              [-38.135000000849288, -8.020833325897229],
              [-38.116666667132961, -8.020833326796549],
              [-38.116666667132961, -8.021666659288826],
              [-38.115833333741307, -8.021666659288826],
              [-38.115833331942667, -8.02249999268048],
              [-38.11499999945039, -8.02249999268048],
              [-38.11499999945039, -8.024166659463731],
              [-38.114166666058736, -8.024166659463731],
              [-38.114166666058736, -8.025833326246982],
              [-38.113333332667139, -8.025833326246982],
              [-38.113333331767819, -8.026666657839939],
              [-38.112499998376165, -8.026666657839939],
              [-38.112499998376165, -8.030833324798039],
              [-38.110833331592914, -8.030833325697358],
              [-38.110833331592914, -8.031666659089012],
              [-38.107499999825109, -8.031666659089012],
              [-38.107499999825109, -8.033333324972944],
              [-38.106666665534135, -8.033333325872263],
              [-38.106666668232094, -8.03416665926386],
              [-38.105833332142538, -8.03416665926386],
              [-38.105833333041858, -8.034999994454154],
              [-38.104166667157926, -8.034999992655514],
              [-38.104166665359287, -8.035833326047111],
              [-38.102499998576036, -8.035833326047111],
              [-38.102500000374675, -8.039166658714294],
              [-38.103333331967633, -8.039166658714294],
              [-38.103333331967633, -8.041666658889142],
              [-38.102499998576036, -8.041666658889142],
              [-38.102499998576036, -8.042499993180115],
              [-38.101666666983078, -8.042499993180115],
              [-38.101666666083702, -8.048333326022146],
              [-38.100833332692105, -8.048333326022146],
              [-38.100833333591424, -8.049166659413743],
              [-38.100000001099147, -8.049166659413743],
              [-38.099999998401188, -8.048333326022146],
              [-38.097500000024922, -8.048333326022146],
              [-38.097500000024922, -8.049166659413743],
              [-38.095833332342352, -8.049166659413743],
              [-38.095833331443032, -8.052499992980245],
              [-38.096666665734006, -8.052499992980245],
              [-38.096666667532645, -8.053333326371842],
              [-38.097500000924242, -8.053333326371842],
              [-38.097499999125603, -8.054166658864176],
              [-38.096666666633325, -8.054166658864176],
              [-38.096666667532645, -8.054999994054413],
              [-38.095833332342352, -8.054999992255773],
              [-38.095833332342352, -8.055833325647427],
              [-38.094999999850074, -8.055833325647427],
              [-38.094999999850074, -8.057499993329998],
              [-38.094999999850074, -8.059999993504846],
              [-38.09416666645842, -8.059999993504846],
              [-38.09416666645842, -8.0608333268965],
              [-38.092500000574546, -8.060833325997123],
              [-38.09249999787653, -8.061666659388777],
              [-38.091666667182892, -8.061666659388777],
              [-38.091666668082212, -8.062499992780374],
              [-38.090833331992656, -8.062499992780374],
              [-38.090833333791295, -8.064166658664305],
              [-38.089999999500321, -8.064166658664305],
              [-38.089999998601002, -8.065833326346876],
              [-38.089166667907364, -8.065833326346876],
              [-38.089166665209405, -8.06666665883921],
              [-38.08833333271707, -8.06666665883921],
              [-38.08833333271707, -8.067499994029447],
              [-38.087500001124113, -8.067499993130127],
              [-38.087500002023432, -8.068333326521781],
              [-38.086666665933819, -8.068333326521781],
              [-38.086666666833139, -8.069166659014058],
              [-38.085833332542222, -8.069166659014058],
              [-38.085833331642903, -8.069999993304975],
              [-38.084999999150625, -8.069999993304975],
              [-38.085000000049945, -8.071666659188907],
              [-38.084166666658291, -8.071666659188907],
              [-38.083333331468054, -8.071666658289587],
              [-38.083333334166014, -8.070833325797309],
              [-38.08249999987504, -8.070833325797309],
              [-38.08249999897572, -8.069999993304975],
              [-38.080833333091789, -8.069999993304975],
              [-38.080833333091789, -8.069166659014058],
              [-38.080000000599512, -8.069166659014058],
              [-38.080000000599512, -8.068333324723085],
              [-38.078333333816261, -8.068333326521781],
              [-38.078333332916941, -8.069166659014058],
              [-38.077499999525287, -8.069166659014058],
              [-38.077499999525287, -8.07249999347988],
              [-38.07666666613369, -8.07249999347988],
              [-38.07666666703301, -8.073333325072838],
              [-38.074999999350439, -8.073333326871477],
              [-38.074166666858162, -8.073333325972158],
              [-38.074166666858162, -8.074166659363812],
              [-38.073333333466508, -8.074166659363812],
              [-38.073333333466508, -8.074999992755409],
              [-38.072499999175591, -8.074999992755409],
              [-38.072499999175591, -8.075833326147063],
              [-38.071666665783937, -8.075833327046382],
              [-38.071666666683257, -8.078333326321911],
              [-38.07083333239234, -8.078333326321911],
              [-38.07083333149302, -8.079999993105162],
              [-38.070000000799382, -8.079999993105162],
              [-38.069999999000686, -8.080833326496759],
              [-38.069166667407728, -8.080833326496759],
              [-38.069166666508409, -8.08249999328001],
              [-38.068333334016131, -8.082499994179329],
              [-38.068333334016131, -8.083333325772344],
              [-38.069999999900006, -8.083333325772344],
              [-38.070000001698702, -8.084999993454915],
              [-38.07083333149302, -8.084999993454915],
              [-38.07083333149302, -8.085833325947192],
              [-38.072500000074911, -8.085833325947192],
              [-38.072499999175591, -8.086666659338789],
              [-38.074166665958842, -8.086666658439469],
              [-38.074166668656801, -8.087499992730443],
              [-38.076666667932329, -8.087499992730443],
              [-38.07666666613369, -8.089166657715054],
              [-38.077499999525287, -8.089166659513694],
              [-38.077500000424664, -8.089999992905291],
              [-38.078333332916941, -8.089999993804611],
              [-38.078333332017621, -8.090833326296945],
              [-38.079166665409218, -8.090833326296945],
              [-38.079166665409218, -8.092499992180819],
              [-38.079999999700192, -8.092499993080196],
              [-38.079999998800872, -8.093333326471793],
              [-38.079166665409218, -8.093333326471793],
              [-38.079166665409218, -8.09416665986339],
              [-38.078333333816261, -8.09416665986339],
              [-38.078333332017621, -8.095833325747321],
              [-38.077499999525287, -8.095833325747321],
              [-38.077500000424664, -8.098333325922226],
              [-38.07666666703301, -8.098333325922226],
              [-38.07666666703301, -8.099999992705477],
              [-38.079999998800872, -8.099999992705477],
              [-38.079999999700192, -8.100833326097074],
              [-38.079166668107177, -8.100833326097074],
              [-38.079166665409218, -8.101666660388048],
              [-38.080833332192469, -8.101666659488671],
              [-38.080833333091789, -8.102499992880325],
              [-38.081666665584123, -8.102499992880325],
              [-38.081666665584123, -8.103333326271922],
              [-38.080833332192469, -8.103333326271922],
              [-38.080833333091789, -8.104999993055173],
              [-38.081666666483443, -8.104999993954493],
              [-38.081666665584123, -8.105833326446827],
              [-38.080833332192469, -8.105833326446827],
              [-38.080833333091789, -8.106666658939105],
              [-38.081666665584123, -8.106666658939105],
              [-38.081666666483443, -8.107499994129398],
              [-38.08249999987504, -8.107499993230078],
              [-38.08249999987504, -8.108333325722356],
              [-38.081666665584123, -8.108333327520995],
              [-38.081666666483443, -8.109166659113953],
              [-38.080833332192469, -8.109166659113953],
              [-38.080833333991109, -8.112499992680455],
              [-38.081666666483443, -8.112499992680455],
              [-38.081666665584123, -8.11499999285536],
              [-38.08249999987504, -8.11499999195604],
              [-38.08249999987504, -8.117499993929528],
              [-38.083333331468054, -8.117499993030208],
              [-38.083333331468054, -8.118333326421805],
              [-38.084999999150625, -8.118333326421805],
              [-38.084999999150625, -8.119166658914139],
              [-38.086666667732459, -8.119166658914139],
              [-38.086666668631835, -8.119999993205056],
              [-38.090833331992656, -8.119999993205056],
              [-38.090833332891975, -8.12083332569739],
              [-38.092499998775907, -8.12083332569739],
              [-38.09249999787653, -8.121666659088987],
              [-38.093333333066823, -8.121666659088987],
              [-38.093333333066823, -8.123333325872238],
              [-38.094166667357797, -8.123333325872238],
              [-38.094166665559101, -8.124166658364572],
              [-38.095833334140991, -8.124166659263892],
              [-38.095833331443032, -8.124999992655489],
              [-38.098333332517257, -8.124999992655489],
              [-38.098333332517257, -8.125833326047086],
              [-38.099166665009534, -8.125833326047086],
              [-38.099166665908854, -8.12666665943874],
              [-38.100000000199827, -8.12666665943874],
              [-38.100000000199827, -8.127499992830337],
              [-38.101666666083702, -8.127499992830337],
              [-38.101666666983078, -8.128333326221991],
              [-38.100833333591424, -8.128333327121311],
              [-38.100833331792785, -8.129999993005242],
              [-38.099999998401188, -8.129999993005242],
              [-38.100000001099147, -8.135833325847273],
              [-38.100833333591424, -8.135833325847273],
              [-38.100833331792785, -8.13666665923887],
              [-38.100000000199827, -8.13666665923887],
              [-38.099999998401188, -8.139999992805372],
              [-38.100833332692105, -8.139999992805372],
              [-38.100833331792785, -8.1424999920809],
              [-38.104999999650204, -8.14249999298022],
              [-38.104999999650204, -8.143333326371874],
              [-38.104999998750884, -8.144166658864151],
              [-38.103333331967633, -8.144166658864151],
              [-38.103333331967633, -8.144999993155125],
              [-38.101666666083702, -8.144999993155125],
              [-38.101666667882398, -8.145833327446041],
              [-38.100833331792785, -8.145833325647402],
              [-38.100833331792785, -8.146666659039056],
              [-38.098333332517257, -8.146666659039056],
              [-38.098333332517257, -8.147499992430653],
              [-38.099166665908854, -8.147499993329973],
              [-38.099166667707493, -8.149166659213904],
              [-38.097500000024922, -8.149166659213904],
              [-38.097499998226283, -8.149999994404197],
              [-38.096666666633325, -8.149999993504878],
              [-38.096666665734006, -8.150833325997155],
              [-38.090833331992656, -8.150833325997155],
              [-38.090833331992656, -8.152499992780406],
              [-38.089166666108724, -8.152499992780406],
              [-38.089166667008044, -8.154166658664337],
              [-38.088333331817751, -8.154166658664337],
              [-38.08833333361639, -8.155833326346908],
              [-38.087499998426154, -8.155833326346908],
              [-38.087499998426154, -8.156666658839185],
              [-38.083333334166014, -8.156666658839185],
              [-38.083333331468054, -8.157499994029479],
              [-38.081666667382763, -8.157499993130159],
              [-38.081666666483443, -8.158333326521756],
              [-38.079166665409218, -8.158333324723117],
              [-38.079166668107177, -8.157499993130159],
              [-38.077500000424664, -8.157499993130159],
              [-38.077499999525287, -8.158333326521756],
              [-38.075833332742093, -8.158333327421076],
              [-38.075833332742093, -8.159166659014033],
              [-38.07666666703301, -8.159166659014033],
              [-38.07666666703301, -8.161666659188938],
              [-38.077499998625967, -8.161666659188938],
              [-38.077499998625967, -8.162499993479855],
              [-38.079166667207858, -8.162499991681216],
              [-38.079166665409218, -8.163333325972189],
              [-38.079999999700192, -8.163333325972189],
              [-38.079999998800872, -8.164166659363786],
              [-38.080833334890428, -8.164166659363786],
              [-38.080833332192469, -8.16499999275544],
              [-38.081666667382763, -8.164999991856121],
              [-38.081666665584123, -8.165833326147037],
              [-38.084166665758971, -8.165833326147037],
              [-38.084166665758971, -8.166666658639315],
              [-38.085000000949265, -8.166666660437954],
              [-38.084999999150625, -8.167499992030969],
              [-38.089999998601002, -8.167499992930288],
              [-38.089999999500321, -8.169999993105137],
              [-38.090833332891975, -8.169999993105137],
              [-38.090833332891975, -8.170833326496791],
              [-38.091666665384253, -8.170833326496791],
              [-38.091666668082212, -8.171666658989068],
              [-38.092499998775907, -8.171666658989068],
              [-38.092500000574546, -8.172499993280042],
              [-38.093333333966143, -8.172499993280042],
              [-38.093333333966143, -8.173333325772319],
              [-38.09416666645842, -8.173333325772319],
              [-38.094166665559101, -8.175833325047847],
              [-38.094999998950755, -8.175833325947167],
              [-38.094999998950755, -8.176666658439501],
              [-38.095833334140991, -8.176666660238141],
              [-38.095833331443032, -8.177499991831098],
              [-38.098333331617937, -8.177499992730418],
              [-38.098333333416576, -8.179166659513669],
              [-38.100833332692105, -8.179166659513669],
              [-38.100833332692105, -8.179999992905323],
              [-38.101666667882398, -8.179999992905323],
              [-38.101666666083702, -8.18083332629692],
              [-38.102499998576036, -8.18083332629692],
              [-38.102500000374675, -8.181666658789197],
              [-38.104166668057246, -8.181666658789197],
              [-38.104166668057246, -8.183333327371088],
              [-38.104999999650204, -8.183333326471768],
              [-38.104999998750884, -8.184999993255019],
              [-38.106666666433455, -8.184999993255019],
              [-38.106666666433455, -8.185833325747353],
              [-38.107499998925789, -8.185833325747353],
              [-38.107499998925789, -8.18666665913895],
              [-38.107499999825109, -8.188333325022882],
              [-38.108333331418066, -8.188333325922201],
              [-38.108333334116026, -8.190833326097049],
              [-38.10916666660836, -8.190833326097049],
              [-38.10916666570904, -8.194166658764232],
              [-38.11, -8.194166658764232],
              [-38.109999999100637, -8.197499993230053],
              [-38.110833332492234, -8.197499993230053],
              [-38.110833333391554, -8.199166659113985],
              [-38.111666665883888, -8.199166659113985],
              [-38.111666666783208, -8.199999993404901],
              [-38.112500000174805, -8.199999993404901],
              [-38.112500001074125, -8.200833325897236],
              [-38.113333331767819, -8.200833324997916],
              [-38.113333331767819, -8.201666659288833],
              [-38.114166666058736, -8.201666658389513],
              [-38.114166666058736, -8.204999992855335],
              [-38.11499999945039, -8.204999993754654],
              [-38.115000002148349, -8.205833325347612],
              [-38.115833333741307, -8.205833326246989],
              [-38.115833331942667, -8.210833325697365],
              [-38.114166668756695, -8.210833325697365],
              [-38.114166666958056, -8.211666659089019],
              [-38.113333332667139, -8.211666659089019],
              [-38.113333331767819, -8.212499992480616],
              [-38.113333331767819, -8.214166659263867],
              [-38.114166666058736, -8.214166659263867],
              [-38.114166666058736, -8.214999992655464],
              [-38.11499999945039, -8.214999992655464],
              [-38.11499999855107, -8.216666659438715],
              [-38.115833331942667, -8.216666659438715],
              [-38.115833332841987, -8.217499992830369],
              [-38.116666666233641, -8.217499992830369],
              [-38.116666666233641, -8.218333326221966],
              [-38.121666668381977, -8.218333327121286],
              [-38.121666666583337, -8.217499992830369],
              [-38.122499999075671, -8.217499992830369],
              [-38.122499999974991, -8.215833326047118],
              [-38.127499998526048, -8.215833326047118],
              [-38.127499999425368, -8.216666659438715],
              [-38.131666665484204, -8.216666659438715],
              [-38.131666666383524, -8.215833326946438],
              [-38.132499998875801, -8.215833326946438],
              [-38.13250000067444, -8.216666660338035],
              [-38.140833331892736, -8.216666659438715],
              [-38.140833331892736, -8.217499992830369],
              [-38.14249999957525, -8.217499992830369],
              [-38.142500000474627, -8.220833326396871],
              [-38.145833333141752, -8.220833325497551],
              [-38.145833331343113, -8.219999993005217],
              [-38.147499999025683, -8.219999993005217],
              [-38.147499999025683, -8.220833326396871],
              [-38.149999999200531, -8.220833326396871],
              [-38.149999999200531, -8.221666658889148],
              [-38.152500001174076, -8.221666659788468],
              [-38.152500001174076, -8.223333325672399],
              [-38.153333331867714, -8.223333325672399],
              [-38.153333333666353, -8.22499999335497],
              [-38.154166667058007, -8.22499999335497],
              [-38.154166667957327, -8.225833325847248],
              [-38.154999998650965, -8.225833324947928],
              [-38.155000000449604, -8.226666659238902],
              [-38.157500000624509, -8.226666659238902],
              [-38.157499999725189, -8.227499993529818],
              [-38.158333333116786, -8.227499993529818],
              [-38.158333334016106, -8.228333326022153],
              [-38.159999999000718, -8.228333326022153],
              [-38.159999999000718, -8.229999992805404],
              [-38.160833331492995, -8.229999992805404],
              [-38.160833330593675, -8.230833326197001],
              [-38.161666666683288, -8.230833326197001],
              [-38.161666665783969, -8.231666658689278],
              [-38.1633333316679, -8.231666658689278],
              [-38.1633333316679, -8.232499992980252],
              [-38.164166666858137, -8.232499992980252],
              [-38.164166665958817, -8.233333326371849],
              [-38.164999999350471, -8.233333326371849],
              [-38.164999998451151, -8.234166657964863],
              [-38.166666667033041, -8.234166658864183],
              [-38.166666666133665, -8.2349999931551],
              [-38.167499999525319, -8.2349999931551],
              [-38.167500000424639, -8.235833324748114],
              [-38.170833333991141, -8.235833325647434],
              [-38.170833333991141, -8.236666659039031],
              [-38.169999999700167, -8.236666659039031],
              [-38.169999998800847, -8.237499993330005],
              [-38.16916666720789, -8.237499993330005],
              [-38.16916666540925, -8.238333325822282],
              [-38.168333332916916, -8.238333325822282],
              [-38.168333332916916, -8.241666659388784],
              [-38.16916666540925, -8.241666658489464],
              [-38.16916666540925, -8.242499992780381],
              [-38.170000000599487, -8.242499992780381],
              [-38.169999998800847, -8.243333326172035],
              [-38.170833330393862, -8.243333326172035],
              [-38.170833332192501, -8.244999992955286],
              [-38.172499999875072, -8.244999992955286],
              [-38.172499998076432, -8.245833326346883],
              [-38.173333331468029, -8.245833326346883],
              [-38.173333334165989, -8.248333326521731],
              [-38.172499998975752, -8.248333324723092],
              [-38.172499999875072, -8.249166659014065],
              [-38.171666667382738, -8.249166659014065],
              [-38.171666665584098, -8.250833325797316],
              [-38.169999998800847, -8.250833325797316],
              [-38.169999999700167, -8.249999993304982],
              [-38.16916666720789, -8.249999993304982],
              [-38.16916666540925, -8.251666659188913],
              [-38.168333332916916, -8.251666659188913],
              [-38.168333332916916, -8.252499991681248],
              [-38.166666667033041, -8.252499993479887],
              [-38.166666668831681, -8.253333325972164],
              [-38.166666666133665, -8.264166659163948],
              [-38.167499999525319, -8.264166658264628],
              [-38.167499998625999, -8.264999993454865],
              [-38.173333332367349, -8.264999993454865],
              [-38.173333332367349, -8.265833325947199],
              [-38.17500000004992, -8.265833325047879],
              [-38.1749999991506, -8.269999992905298],
              [-38.176666666833171, -8.269999993804618],
              [-38.176666666833171, -8.270833326296952],
              [-38.178333332717102, -8.270833326296952],
              [-38.178333331817782, -8.271666658789229],
              [-38.179166668806658, -8.271666658789229],
              [-38.179166667008019, -8.274999993255051],
              [-38.178333332717102, -8.274999993255051],
              [-38.178333331817782, -8.275833325747328],
              [-38.177500000224768, -8.275833325747328],
              [-38.177500001124088, -8.27916665931383],
              [-38.184166667357772, -8.27916665931383],
              [-38.184166666458452, -8.278333325022857],
              [-38.187499999125635, -8.278333325922233],
              [-38.187500000924274, -8.27916665931383],
              [-38.188333332517232, -8.27916665931383],
              [-38.188333333416551, -8.279999992705427],
              [-38.189166666808205, -8.279999992705427],
              [-38.189166666808205, -8.280833326097081],
              [-38.189999998401163, -8.280833326097081],
              [-38.190000000199802, -8.281666659488678],
              [-38.19083333269208, -8.281666659488678],
              [-38.19083333179276, -8.282499992880332],
              [-38.192499999475331, -8.282499992880332],
              [-38.192499999475331, -8.283333326271929],
              [-38.193333333766304, -8.283333326271929],
              [-38.193333332866985, -8.285833326446834],
              [-38.194999999650236, -8.285833326446834],
              [-38.194999997851596, -8.286666658939112],
              [-38.196666666433487, -8.286666658939112],
              [-38.196666665534167, -8.287499993230085],
              [-38.197499998925764, -8.287499993230085],
              [-38.197499998925764, -8.288333325722363],
              [-38.198333334116057, -8.288333325722363],
              [-38.198333333216738, -8.28916665911396],
              [-38.200833331592946, -8.28916665911396],
              [-38.200833332492266, -8.289999993404933],
              [-38.201666665883863, -8.289999993404933],
              [-38.201666666783183, -8.291666660188184],
              [-38.203333334465754, -8.291666659288865],
              [-38.203333331767794, -8.292499992680462],
              [-38.204166666958088, -8.292499992680462],
              [-38.204166668756727, -8.294999992855367],
              [-38.207499998725893, -8.294999992855367],
              [-38.207499999625213, -8.294166659463713],
              [-38.209999999800118, -8.294166659463713],
              [-38.210000000699438, -8.293333326072116],
              [-38.21333333336662, -8.293333326072116],
              [-38.21333333336662, -8.292499992680462],
              [-38.215000000149871, -8.292499994479101],
              [-38.215000001049191, -8.291666660188184],
              [-38.219999998700928, -8.291666659288865],
              [-38.219999999600248, -8.292499994479101],
              [-38.220833332991901, -8.292499994479101],
              [-38.220833333891221, -8.293333326072116],
              [-38.222499999775152, -8.293333326072116],
              [-38.222499998875833, -8.294166659463713],
              [-38.226666667632571, -8.294166659463713],
              [-38.226666666733252, -8.29499999195599],
              [-38.229166667807419, -8.294999992855367],
              [-38.229166667807419, -8.294166659463713],
              [-38.228333331717863, -8.294166659463713],
              [-38.228333333516503, -8.291666660188184],
              [-38.227500000124849, -8.291666660188184],
              [-38.227500001024168, -8.290833325897211],
              [-38.228333333516503, -8.290833325897211],
              [-38.228333332617183, -8.289999993404933],
              [-38.229166668706739, -8.289999993404933],
              [-38.2291666669081, -8.284166658764264],
              [-38.229999998501114, -8.284166658764264],
              [-38.229999999400434, -8.28333332537261],
              [-38.23083333459067, -8.283333326271929],
              [-38.230833331892711, -8.278333325922233],
              [-38.231666666183628, -8.278333325922233],
              [-38.231666666183628, -8.277499993429899],
              [-38.232499998675962, -8.277499993429899],
              [-38.232499998675962, -8.276666659138982],
              [-38.235833331343144, -8.276666659138982],
              [-38.235833333141784, -8.275833324848008],
              [-38.236666665634061, -8.275833325747328],
              [-38.236666666533381, -8.274999993255051],
              [-38.243333332767065, -8.274999993255051],
              [-38.243333333666385, -8.274166658964077],
              [-38.245833333841233, -8.274166658964077],
              [-38.245833332042594, -8.272499992180826],
              [-38.246666665434248, -8.272499993080146],
              [-38.246666665434248, -8.271666658789229],
              [-38.247500000624484, -8.271666658789229],
              [-38.247499998825845, -8.270833325397575],
              [-38.248333332217442, -8.270833325397575],
              [-38.248333333116761, -8.269999993804618],
              [-38.251666667582583, -8.269999992905298],
              [-38.251666665783944, -8.269166660413021],
              [-38.252500000974237, -8.269166660413021],
              [-38.252499999175598, -8.268333326122047],
              [-38.253333332567195, -8.268333325222727],
              [-38.253333333466514, -8.26749999273045],
              [-38.254999998451126, -8.26749999273045],
              [-38.255000001149085, -8.266666659338796],
              [-38.255833333641419, -8.266666659338796],
              [-38.255833332742043, -8.265833325947199],
              [-38.257500000424614, -8.265833325947199],
              [-38.257500000424614, -8.264999994354184],
              [-38.258333332916948, -8.264999993454865],
              [-38.258333332916948, -8.264166659163948],
              [-38.260833332192476, -8.264166659163948],
              [-38.260833332192476, -8.263333325772294],
              [-38.26166666558413, -8.263333324872974],
              [-38.26166666558413, -8.262499994179336],
              [-38.262499999875047, -8.262499994179336],
              [-38.262499999875047, -8.259999993105168],
              [-38.26166666648345, -8.259999993105168],
              [-38.26166666558413, -8.259166658814195],
              [-38.262499999875047, -8.259166658814195],
              [-38.262499998975727, -8.257499992930263],
              [-38.26333333416602, -8.257499992930263],
              [-38.263333331468004, -8.256666657740027],
              [-38.264166667557618, -8.256666660437986],
              [-38.264166667557618, -8.255833326147012],
              [-38.265000000949215, -8.255833326147012],
              [-38.268333332717077, -8.255833326147012],
              [-38.268333331817757, -8.254999992755415],
              [-38.269999998601008, -8.254999992755415],
              [-38.269999999500328, -8.254166658464442],
              [-38.271666668082219, -8.254166659363818],
              [-38.271666665384259, -8.253333325972164],
              [-38.272500000574496, -8.253333325972164],
              [-38.272499998775857, -8.252499993479887],
              [-38.27333333216751, -8.252499993479887],
              [-38.27333333216751, -8.251666659188913],
              [-38.274166666458427, -8.251666659188913],
              [-38.274166666458427, -8.250833325797316],
              [-38.274999999850081, -8.250833324897997],
              [-38.274999998950761, -8.249166659014065],
              [-38.277500000924249, -8.249166658114746],
              [-38.277500000024929, -8.248333327421051],
              [-38.278333333416583, -8.248333326521731],
              [-38.278333330718567, -8.24666665883916],
              [-38.281666666983028, -8.24666665883916],
              [-38.281666666083709, -8.247499993130134],
              [-38.284166668057253, -8.247499993130134],
              [-38.284166665359294, -8.248333326521731],
              [-38.28916666570899, -8.248333326521731],
              [-38.28916666570899, -8.247499993130134],
              [-38.290833332492241, -8.247499993130134],
              [-38.290833331592921, -8.245833326346883],
              [-38.291666666783215, -8.245833326346883],
              [-38.291666665883895, -8.244999992955286],
              [-38.297500000524565, -8.244999992955286],
              [-38.297499998725925, -8.245833326346883],
              [-38.298333333016842, -8.245833326346883],
              [-38.298333333016842, -8.246666659738537],
              [-38.299166668207135, -8.24666665883916],
              [-38.299166666408496, -8.247499993130134],
              [-38.299999998900773, -8.247499993130134],
              [-38.299999999800093, -8.248333327421051],
              [-38.301666667482664, -8.248333326521731],
              [-38.301666666583344, -8.247499993130134],
              [-38.304166666758249, -8.247499993130134],
              [-38.304166666758249, -8.248333326521731],
              [-38.304999998351207, -8.248333326521731],
              [-38.305000000149846, -8.249166658114746],
              [-38.305833331742804, -8.249166659014065],
              [-38.305833331742804, -8.250833325797316],
              [-38.307500001224014, -8.250833326696636],
              [-38.307499998526055, -8.249999993304982],
              [-38.308333333716348, -8.249999996002941],
              [-38.310833332991876, -8.249999994204302],
              [-38.310833332991876, -8.251666659188913],
              [-38.312499998875808, -8.251666659188913],
              [-38.312499998875808, -8.252499993479887],
              [-38.313333334066044, -8.252499993479887],
              [-38.313333334066044, -8.253333325972164],
              [-38.31583333244231, -8.253333325972164],
              [-38.31583333154299, -8.254166659363818],
              [-38.317499999225561, -8.254166659363818],
              [-38.3175000010242, -8.256666658639347],
              [-38.318333332617158, -8.256666660437986],
              [-38.318333331717838, -8.257499992930263],
              [-38.319166666008812, -8.257499992930263],
              [-38.319166666008812, -8.258333326321917],
              [-38.319999999400409, -8.258333326321917],
              [-38.319999999400409, -8.259166658814195],
              [-38.320833331892686, -8.259166657914875],
              [-38.320833333691326, -8.259999993105168],
              [-38.322499999575257, -8.259999994004488],
              [-38.322500000474577, -8.260833326496765],
              [-38.323333332966911, -8.260833327396085],
              [-38.323333332067591, -8.262499992380697],
              [-38.325833334041079, -8.262499993280016],
              [-38.325833333141759, -8.264166659163948],
              [-38.326666665634093, -8.264166658264628],
              [-38.326666665634093, -8.264999991656225],
              [-38.328333332417287, -8.264999994354184],
              [-38.328333332417287, -8.26749999273045],
              [-38.3291666685069, -8.26749999273045],
              [-38.329166666708261, -8.268333326122047],
              [-38.329999999200538, -8.268333326122047],
              [-38.330000000099858, -8.270833326296952],
              [-38.330833332592192, -8.270833327196272],
              [-38.330833332592192, -8.271666658789229],
              [-38.331666666883109, -8.271666658789229],
              [-38.331666665983789, -8.272499993080146],
              [-38.33333333276704, -8.272499993080146],
              [-38.33333333276704, -8.2733333264718],
              [-38.335833333841265, -8.2733333264718],
              [-38.335833333841265, -8.274166658964077],
              [-38.336666665434223, -8.274166658964077],
              [-38.336666667232862, -8.274999993255051],
              [-38.338333333116793, -8.274999993255051],
              [-38.338333332217474, -8.275833325747328],
              [-38.341666665783976, -8.275833325747328],
              [-38.341666667582615, -8.276666659138982],
              [-38.342499999175573, -8.276666660038302],
              [-38.342499999175573, -8.277499994329219],
              [-38.343333332567227, -8.277499993429899],
              [-38.343333332567227, -8.278333326821553],
              [-38.344166665059504, -8.278333325922233],
              [-38.344166666858143, -8.27916665931383],
              [-38.345000001149117, -8.27916665931383],
              [-38.344999999350421, -8.278333325922233],
              [-38.346666667032991, -8.278333325922233],
              [-38.346666666133672, -8.277499991631259],
              [-38.347500000424645, -8.277499993429899],
              [-38.347500000424645, -8.278333325922233],
              [-38.349999999700174, -8.278333325922233],
              [-38.349999997901534, -8.27916665931383],
              [-38.353333332367356, -8.27916665931383],
              [-38.353333334165995, -8.279999991806108],
              [-38.354166666658273, -8.279999991806108],
              [-38.354166666658273, -8.280833326097081],
              [-38.355000000049927, -8.280833326097081],
              [-38.355000000049927, -8.281666659488678],
              [-38.361666665384234, -8.281666659488678],
              [-38.361666667182874, -8.280833326097081],
              [-38.362499998775888, -8.280833326097081],
              [-38.362500000574528, -8.279999992705427],
              [-38.364166665559139, -8.279999992705427],
              [-38.364166666458459, -8.27916665931383],
              [-38.364999999850056, -8.27916665931383],
              [-38.367500000924281, -8.27916665931383],
              [-38.367500000924281, -8.277499993429899],
              [-38.368333331617919, -8.277499993429899],
              [-38.368333331617919, -8.276666659138982],
              [-38.370000000199809, -8.276666659138982],
              [-38.370833331792767, -8.276666659138982],
              [-38.370833332692087, -8.277499994329219],
              [-38.37166666698306, -8.277499993429899],
              [-38.37166666788238, -8.278333326821553],
              [-38.372499998576018, -8.278333325922233],
              [-38.372499998576018, -8.27916665931383],
              [-38.373333331967672, -8.27916665931383],
              [-38.373333331967672, -8.279999992705427],
              [-38.374166667157908, -8.279999992705427],
              [-38.374166665359269, -8.280833326097081],
              [-38.374999999650242, -8.280833326097081],
              [-38.375000000549562, -8.281666659488678],
              [-38.37583333214252, -8.281666659488678],
              [-38.37583333214252, -8.282499992880332],
              [-38.376666666433493, -8.282499992880332],
              [-38.376666665534117, -8.284166658764264],
              [-38.377499999825091, -8.284166659663583],
              [-38.377499999825091, -8.28499999305518],
              [-38.378333331418048, -8.28499999305518],
              [-38.378333333216688, -8.287499993230085],
              [-38.38, -8.287499993230085],
              [-38.379999999100619, -8.288333325722363],
              [-38.380833332492273, -8.288333325722363],
              [-38.380833331592953, -8.28916665911396],
              [-38.38166666678319, -8.28916665911396],
              [-38.381666667682509, -8.290833325897211],
              [-38.381666667682509, -8.291666659288865],
              [-38.380833332492273, -8.291666658389545],
              [-38.380833331592953, -8.292499992680462],
              [-38.379999999100619, -8.292499992680462],
              [-38.380000000899258, -8.293333326971435],
              [-38.379166666608342, -8.293333326971435],
              [-38.379166667507661, -8.294166659463713],
              [-38.378333333216688, -8.294166659463713],
              [-38.378333331418048, -8.294999992855367],
              [-38.377499999825091, -8.294999992855367],
              [-38.377499998925771, -8.295833326246964],
              [-38.376666666433493, -8.295833326246964],
              [-38.376666666433493, -8.296666658739241],
              [-38.37583333214252, -8.296666658739241],
              [-38.37583333214252, -8.297499993030215],
              [-38.374166665359269, -8.297499992130895],
              [-38.374166667157908, -8.298333325522492],
              [-38.372499999475338, -8.298333326421812],
              [-38.372499999475338, -8.299166658914146],
              [-38.36999999840117, -8.299166658914146],
              [-38.370000001099129, -8.298333326421812],
              [-38.369166665908836, -8.298333326421812],
              [-38.369166665908836, -8.299166659813466],
              [-38.366666666633307, -8.299166658914146],
              [-38.366666666633307, -8.299999993205063],
              [-38.364166665559139, -8.299999994104382],
              [-38.364166666458459, -8.300833325697397],
              [-38.363333330368846, -8.300833325697397],
              [-38.363333332167485, -8.301666659088994],
              [-38.360833331992637, -8.301666658189674],
              [-38.360833332891957, -8.302499993379968],
              [-38.360833332891957, -8.303333325872245],
              [-38.363333333966125, -8.303333326771565],
              [-38.363333333966125, -8.304166659263842],
              [-38.364166665559139, -8.304166659263842],
              [-38.364166666458459, -8.304999992655496],
              [-38.364999999850056, -8.304999991756176],
              [-38.364999999850056, -8.305833325147773],
              [-38.368333331617919, -8.305833326047093],
              [-38.368333331617919, -8.306666659438747],
              [-38.370833331792767, -8.306666659438747],
              [-38.370833333591406, -8.307499992830344],
              [-38.37166666608374, -8.307499992830344],
              [-38.37166666608374, -8.306666660338067],
              [-38.372499999475338, -8.306666659438747],
              [-38.372499998576018, -8.305833326047093],
              [-38.373333331967672, -8.305833325147773],
              [-38.373333333766311, -8.306666659438747],
              [-38.376666666433493, -8.306666659438747],
              [-38.376666666433493, -8.307499992830344],
              [-38.380000001798578, -8.307499992830344],
              [-38.38, -8.306666659438747],
              [-38.38166666588387, -8.306666659438747],
              [-38.38166666588387, -8.307499992830344],
              [-38.383333331767801, -8.307499992830344],
              [-38.383333332667121, -8.308333326221998],
              [-38.384999998551052, -8.308333326221998],
              [-38.384999999450372, -8.309999993904569],
              [-38.39333333246725, -8.309999992105929],
              [-38.393333333366627, -8.311666658889123],
              [-38.394166665858904, -8.311666658889123],
              [-38.394166666758224, -8.312499993180097],
              [-38.398333333716323, -8.312499993180097],
              [-38.398333331917684, -8.313333325672374],
              [-38.400833332092532, -8.313333325672374],
              [-38.400833332991908, -8.312499993180097],
              [-38.401666665484186, -8.312499993180097],
              [-38.401666669081465, -8.313333324773055],
              [-38.403333334066076, -8.313333325672374],
              [-38.403333331368117, -8.314166659064028],
              [-38.404166665659034, -8.314166659064028],
              [-38.404166666558353, -8.314999994254265],
              [-38.405833333341604, -8.314999993354945],
              [-38.405833333341604, -8.315833326746599],
              [-38.406666666733258, -8.315833325847279],
              [-38.406666667632578, -8.316666660138196],
              [-38.407500001024175, -8.316666659238876],
              [-38.407500000124855, -8.31749999442917],
              [-38.409999998501064, -8.31749999352985],
              [-38.409999999400384, -8.318333326022127],
              [-38.410833332792038, -8.318333326022127],
              [-38.410833333691357, -8.319166659413781],
              [-38.411666666183635, -8.319166659413781],
              [-38.411666667082955, -8.320833327096295],
              [-38.412499997776649, -8.320833326196976],
              [-38.412499998675969, -8.322499992980227],
              [-38.41416666635854, -8.322499993879546],
              [-38.41416666635854, -8.323333326371881],
              [-38.414999998850817, -8.323333326371881],
              [-38.414999999750137, -8.325833325647409],
              [-38.41583333404111, -8.325833325647409],
              [-38.41583333404111, -8.326666659039063],
              [-38.416666667432708, -8.326666659039063],
              [-38.416666666533388, -8.328333326721577],
              [-38.417499998126345, -8.328333325822257],
              [-38.417500000824361, -8.329999994404147],
              [-38.415833331343151, -8.329999993504828],
              [-38.415833331343151, -8.330833325997162],
              [-38.414999998850817, -8.330833325997162],
              [-38.415000000649457, -8.331666659388759],
              [-38.413333333866206, -8.331666659388759],
              [-38.413333332067566, -8.332499992780413],
              [-38.412499999575289, -8.332499992780413],
              [-38.412499998675969, -8.331666659388759],
              [-38.409999999400384, -8.331666659388759],
              [-38.41000000119908, -8.330833325997162],
              [-38.407499999225536, -8.330833325997162],
              [-38.407500001923495, -8.331666659388759],
              [-38.406666667632578, -8.331666660288079],
              [-38.406666666733258, -8.330833325997162],
              [-38.403333331368117, -8.330833325997162],
              [-38.403333333166756, -8.329999993504828],
              [-38.401666666383505, -8.329999993504828],
              [-38.401666665484186, -8.329166658314591],
              [-38.395833333541475, -8.329166659213911],
              [-38.395833334440795, -8.329999994404147],
              [-38.394166665858904, -8.329999991706188],
              [-38.394166665858904, -8.330833325997162],
              [-38.391666665684056, -8.330833325997162],
              [-38.391666666583376, -8.331666660288079],
              [-38.389166666408471, -8.331666659388759],
              [-38.389166665509151, -8.332499992780413],
              [-38.386666667132943, -8.332499991881093],
              [-38.386666667132943, -8.334166660462984],
              [-38.385833331942649, -8.334166660462984],
              [-38.385833331942649, -8.334999992955261],
              [-38.386666667132943, -8.334999992955261],
              [-38.386666666233623, -8.335833326346915],
              [-38.3874999987259, -8.335833327246235],
              [-38.38749999962522, -8.337499993130109],
              [-38.388333333016874, -8.337499993130109],
              [-38.388333333016874, -8.338333326521763],
              [-38.38750000052454, -8.338333326521763],
              [-38.3874999987259, -8.33916665901404],
              [-38.386666667132943, -8.33916665991336],
              [-38.386666667132943, -8.340833325797291],
              [-38.385833331942649, -8.340833325797291],
              [-38.385833332841969, -8.341666660088265],
              [-38.384999999450372, -8.341666660088265],
              [-38.385000002148331, -8.342499991681223],
              [-38.384166666058775, -8.342499993479862],
              [-38.384166666058775, -8.343333325972196],
              [-38.384999998551052, -8.343333325972196],
              [-38.384999998551052, -8.344166659363793],
              [-38.3874999987259, -8.344166659363793],
              [-38.38749999962522, -8.34499999275539],
              [-38.388333333016874, -8.344999991856071],
              [-38.388333333916194, -8.345833326147044],
              [-38.389999999800125, -8.345833326147044],
              [-38.390000000699445, -8.346666657740002],
              [-38.392499999974973, -8.346666658639322],
              [-38.392499999974973, -8.347499993829615],
              [-38.39333333246725, -8.347499992930295],
              [-38.39333333246725, -8.348333326321892],
              [-38.394166666758224, -8.348333326321892],
              [-38.394166665858904, -8.349166658814227],
              [-38.398333332817003, -8.349166659713546],
              [-38.398333332817003, -8.349999993105143],
              [-38.399999998700935, -8.349999994004463],
              [-38.399999998700935, -8.350833324698158],
              [-38.405833332442285, -8.350833326496797],
              [-38.405833332442285, -8.351666658989075],
              [-38.41000000119908, -8.351666658989075],
              [-38.409999999400384, -8.350833326496797],
              [-38.416666665634068, -8.350833326496797],
              [-38.416666667432708, -8.349999993105143],
              [-38.423333333666392, -8.349999993105143],
              [-38.423333332767072, -8.351666658989075],
              [-38.424166667057989, -8.351666658989075],
              [-38.424166667957309, -8.353333325772326],
              [-38.424999999550323, -8.353333325772326],
              [-38.424999998650947, -8.354166659163923],
              [-38.42583333384124, -8.354166659163923],
              [-38.429166665609102, -8.354166659163923],
              [-38.429166665609102, -8.353333325772326],
              [-38.430833334190993, -8.353333325772326],
              [-38.430833334190993, -8.352499993279991],
              [-38.432500000974244, -8.352499993279991],
              [-38.432500000974244, -8.351666658989075],
              [-38.433333333466521, -8.351666658989075],
              [-38.433333332567202, -8.352499993279991],
              [-38.43583333184273, -8.352499993279991],
              [-38.435833333641369, -8.351666658989075],
              [-38.436666666133704, -8.351666658989075],
              [-38.436666666133704, -8.350833326496797],
              [-38.439999999700206, -8.350833326496797],
              [-38.439999998800886, -8.351666658989075],
              [-38.446666665933833, -8.351666658989075],
              [-38.446666665933833, -8.350833326496797],
              [-38.448333333616404, -8.350833326496797],
              [-38.448333332717084, -8.351666659888394],
              [-38.449166667907377, -8.351666658989075],
              [-38.449166666108738, -8.350833324698158],
              [-38.449999998601015, -8.350833324698158],
              [-38.449999998601015, -8.349999993105143],
              [-38.450833331992612, -8.349999993105143],
              [-38.450833332891932, -8.349166658814227],
              [-38.451666665384266, -8.349166658814227],
              [-38.451666668082225, -8.345833326147044],
              [-38.453333332167517, -8.345833326147044],
              [-38.454166666458434, -8.345833326147044],
              [-38.454166667357754, -8.346666658639322],
              [-38.454999998051449, -8.346666658639322],
              [-38.454999999850088, -8.347499992930295],
              [-38.455833332342365, -8.347499993829615],
              [-38.455833335040325, -8.348333326321892],
              [-38.458333332517213, -8.348333326321892],
              [-38.458333331617894, -8.349166658814227],
              [-38.461666666083715, -8.349166658814227],
              [-38.461666666083715, -8.349999993105143],
              [-38.46249999857605, -8.349999992205824],
              [-38.462500000374689, -8.353333325772326],
              [-38.464166665359301, -8.353333325772326],
              [-38.46416666715794, -8.354999993454896],
              [-38.464999998750898, -8.354999993454896],
              [-38.464999998750898, -8.355833325047854],
              [-38.466666668232108, -8.355833325947174],
              [-38.466666665534149, -8.356666659338828],
              [-38.472500001074138, -8.356666659338828],
              [-38.472500000174819, -8.358333326122079],
              [-38.473333332667153, -8.358333327021398],
              [-38.473333332667153, -8.357499992730425],
              [-38.47416666695807, -8.357499992730425],
              [-38.47416666605875, -8.356666659338828],
              [-38.475833331942681, -8.356666659338828],
              [-38.475833332842001, -8.355833325947174],
              [-38.476666668032237, -8.355833325947174],
              [-38.476666664434958, -8.356666659338828],
              [-38.485833331742811, -8.356666659338828],
              [-38.485833331742811, -8.357499992730425],
              [-38.486666666933104, -8.357499994529064],
              [-38.486666667832424, -8.358333326122079],
              [-38.487499999425381, -8.358333326122079],
              [-38.487499998526062, -8.359166657715036],
              [-38.489166667107952, -8.359166659513676],
              [-38.489166667107952, -8.35999999290533],
              [-38.490000000499606, -8.35999999290533],
              [-38.48999999870091, -8.360833326296927],
              [-38.490833332991883, -8.360833326296927],
              [-38.490833332092564, -8.361666659688524],
              [-38.491666665484161, -8.361666658789204],
              [-38.492499999775134, -8.361666658789204],
              [-38.492500000674454, -8.360833326296927],
              [-38.494999999949982, -8.360833327196247],
              [-38.495000000849302, -8.35999999290533],
              [-38.497499999225568, -8.35999999290533],
              [-38.497500000124887, -8.360833326296927],
              [-38.498333332617165, -8.360833326296927],
              [-38.498333333516484, -8.35999999290533],
              [-38.499166666908081, -8.359999993804649],
              [-38.499166668706778, -8.359166659513676],
              [-38.499999999400416, -8.359166659513676],
              [-38.499999998501096, -8.358333325222759],
              [-38.500833331892693, -8.358333326122079],
              [-38.500833332792013, -8.357499992730425],
              [-38.503333333866237, -8.357499992730425],
              [-38.503333332067598, -8.356666660238147],
              [-38.504999999750169, -8.356666659338828],
              [-38.504999999750169, -8.355833325947174],
              [-38.505833331343126, -8.355833325947174],
              [-38.505833333141766, -8.357499992730425],
              [-38.507499999025697, -8.357499992730425],
              [-38.507500000824336, -8.358333326122079],
              [-38.509166667607587, -8.358333326122079],
              [-38.509999999200545, -8.358333326122079],
              [-38.509999999200545, -8.357499992730425],
              [-38.510833331692879, -8.357499992730425],
              [-38.510833332592199, -8.356666659338828],
              [-38.51249999937545, -8.356666660238147],
              [-38.51249999937545, -8.355833325947174],
              [-38.513333332767047, -8.355833325947174],
              [-38.513333331867727, -8.354166659163923],
              [-38.51416666795734, -8.354166659163923],
              [-38.514166667058021, -8.353333325772326],
              [-38.515833332941895, -8.353333325772326],
              [-38.515833333841215, -8.352499993279991],
              [-38.517500000624466, -8.352499993279991],
              [-38.517500000624466, -8.353333324873006],
              [-38.519999999900051, -8.353333325772326],
              [-38.520000000799371, -8.352499993279991],
              [-38.522500000074899, -8.352499993279991],
              [-38.522500000074899, -8.351666658989075],
              [-38.523333331667857, -8.351666658989075],
              [-38.523333333466496, -8.350833324698158],
              [-38.524999999350428, -8.350833327396117],
              [-38.524999999350428, -8.349999993105143],
              [-38.526666666133679, -8.349999992205824],
              [-38.526666666133679, -8.348333326321892],
              [-38.527499999525332, -8.348333326321892],
              [-38.527499999525332, -8.347499992930295],
              [-38.532499998975709, -8.347499992930295],
              [-38.532499999875029, -8.348333326321892],
              [-38.533333332367363, -8.348333326321892],
              [-38.533333334166002, -8.349166658814227],
              [-38.53416666575896, -8.349166658814227],
              [-38.53416666575896, -8.356666658439508],
              [-38.535833330743571, -8.356666659338828],
              [-38.535833331642891, -8.357499992730425],
              [-38.53999999860099, -8.357499992730425],
              [-38.53999999950031, -8.358333326122079],
              [-38.540833334690603, -8.358333326122079],
              [-38.540833331992644, -8.35999999290533],
              [-38.5416666680822, -8.35999999290533],
              [-38.5416666680822, -8.361666658789204],
              [-38.542499999675215, -8.361666658789204],
              [-38.542500000574535, -8.362499993080178],
              [-38.543333332167492, -8.362499993080178],
              [-38.543333333966132, -8.364999993255026],
              [-38.544166667357786, -8.364999993255026],
              [-38.544166667357786, -8.366666659138957],
              [-38.546666666633314, -8.366666659138957],
              [-38.547500000024911, -8.366666659138957],
              [-38.547500000024911, -8.36583332574736],
              [-38.548333332517245, -8.36583332574736],
              [-38.548333331617926, -8.364999993255026],
              [-38.549166665908842, -8.364999993255026],
              [-38.549166666808162, -8.364166658964109],
              [-38.549999998401177, -8.364166658964109],
              [-38.549999998401177, -8.363333326471775],
              [-38.550833332692093, -8.363333326471775],
              [-38.550833332692093, -8.362499993080178],
              [-38.555833332142527, -8.362499993080178],
              [-38.555833332142527, -8.35999999290533],
              [-38.556666666433443, -8.35999999290533],
              [-38.556666665534124, -8.358333327021398],
              [-38.557499998925778, -8.358333326122079],
              [-38.557499998925778, -8.357499991831105],
              [-38.559166666608348, -8.357499992730425],
              [-38.559166665709029, -8.356666660238147],
              [-38.56250000017485, -8.356666659338828],
              [-38.562499998376154, -8.355833325947174],
              [-38.564166667857364, -8.355833325947174],
              [-38.564166667857364, -8.353333325772326],
              [-38.566666667132949, -8.353333325772326],
              [-38.566666668931589, -8.352499993279991],
              [-38.577499999425413, -8.352499993279991],
              [-38.577500001224053, -8.351666658989075],
              [-38.579999999600261, -8.351666658089755],
              [-38.580000000499581, -8.350833326496797],
              [-38.579166668007304, -8.350833326496797],
              [-38.579166667107984, -8.346666660437961],
              [-38.580000000499581, -8.346666658639322],
              [-38.579999999600261, -8.345833326147044],
              [-38.585833333341611, -8.345833326147044],
              [-38.585833332442292, -8.346666658639322],
              [-38.594999998850824, -8.346666658639322],
              [-38.594999999750144, -8.347499993829615],
              [-38.595833331343101, -8.347499992930295],
              [-38.595833331343101, -8.348333327221212],
              [-38.598333331518006, -8.348333326321892],
              [-38.598333331518006, -8.349166658814227],
              [-38.604166667957315, -8.349166658814227],
              [-38.604166667057996, -8.348333326321892],
              [-38.603333332767022, -8.348333326321892],
              [-38.603333331867702, -8.347499993829615],
              [-38.602499997576786, -8.347499992930295],
              [-38.602499999375425, -8.34499999275539],
              [-38.603333333666399, -8.34499999275539],
              [-38.603333333666399, -8.344166658464474],
              [-38.602499998476105, -8.344166659363793],
              [-38.602499999375425, -8.342499993479862],
              [-38.601666667782467, -8.342499993479862],
              [-38.601666665983828, -8.337499993130109],
              [-38.600833331692854, -8.337499993130109],
              [-38.600833331692854, -8.338333326521763],
              [-38.597499999025672, -8.338333327421083],
              [-38.597499999025672, -8.33916665901404],
              [-38.595833334041117, -8.33916665901404],
              [-38.595833333141741, -8.337499992230789],
              [-38.594999999750144, -8.337499993130109],
              [-38.595000000649463, -8.332499993679733],
              [-38.594166668157186, -8.332499991881093],
              [-38.594166666358547, -8.331666659388759],
              [-38.593333333866212, -8.331666659388759],
              [-38.593333332966893, -8.330833326896482],
              [-38.592499999575296, -8.330833325997162],
              [-38.592499999575296, -8.329999993504828],
              [-38.591666664385002, -8.329999993504828],
              [-38.591666667982281, -8.329166659213911],
              [-38.590833331892725, -8.329166659213911],
              [-38.590833331892725, -8.328333325822257],
              [-38.586666667632585, -8.328333325822257],
              [-38.586666666733265, -8.32749999332998],
              [-38.585833333341611, -8.327499994229299],
              [-38.585833331542972, -8.326666658139743],
              [-38.584999999950014, -8.326666659039063],
              [-38.584999999050694, -8.325833325647409],
              [-38.583333333166763, -8.325833325647409],
              [-38.583333334066083, -8.324999993155132],
              [-38.574999998351188, -8.324999993155132],
              [-38.575000001049148, -8.324166658864158],
              [-38.5725000008743, -8.324166658864158],
              [-38.57249999997498, -8.323333326371881],
              [-38.571666665684006, -8.323333326371881],
              [-38.571666667482702, -8.322499992980227],
              [-38.568333332117561, -8.322499992980227],
              [-38.568333332117561, -8.321666660487949],
              [-38.56666666623363, -8.32166665868931],
              [-38.56666666623363, -8.320833326196976],
              [-38.564166666958045, -8.320833326196976],
              [-38.564166666958045, -8.319166659413781],
              [-38.563333331767808, -8.319166658514405],
              [-38.563333332667128, -8.31749999352985],
              [-38.559166666608348, -8.31749999352985],
              [-38.559166666608348, -8.316666659238876],
              [-38.558333334116014, -8.316666659238876],
              [-38.558333331418055, -8.315833325847279],
              [-38.557499999825097, -8.315833326746599],
              [-38.557499998925778, -8.314999993354945],
              [-38.554999999650192, -8.314999993354945],
              [-38.554999999650192, -8.314166659963348],
              [-38.554166668057235, -8.314166659064028],
              [-38.554166665359276, -8.313333327471014],
              [-38.551666666983067, -8.313333325672374],
              [-38.551666666083747, -8.312499993180097],
              [-38.550833332692093, -8.312499993180097],
              [-38.550833331792774, -8.311666658889123],
              [-38.550000001099136, -8.311666658889123],
              [-38.549999998401177, -8.309999993005249],
              [-38.549166667707482, -8.309999993005249],
              [-38.549166665908842, -8.307499992830344],
              [-38.548333332517245, -8.307499993729664],
              [-38.548333332517245, -8.303333325872245],
              [-38.549166666808162, -8.303333325872245],
              [-38.549166665908842, -8.301666659088994],
              [-38.550833331792774, -8.301666659088994],
              [-38.550833333591413, -8.300833325697397],
              [-38.552499998576025, -8.300833324798077],
              [-38.552499999475344, -8.299999993205063],
              [-38.553333333766318, -8.299999993205063],
              [-38.553333333766318, -8.299166658914146],
              [-38.552499998576025, -8.299166658914146],
              [-38.552499999475344, -8.298333326421812],
              [-38.551666667882387, -8.298333326421812],
              [-38.551666666983067, -8.297499993030215],
              [-38.550833330893454, -8.297499993030215],
              [-38.550833330893454, -8.296666658739241],
              [-38.551666666083747, -8.296666658739241],
              [-38.551666666983067, -8.294999992855367],
              [-38.550833333591413, -8.294999993754686],
              [-38.550833331792774, -8.291666658389545],
              [-38.551666667882387, -8.291666659288865],
              [-38.551666666983067, -8.290833325897211],
              [-38.550833331792774, -8.290833325897211],
              [-38.550833333591413, -8.28916665911396],
              [-38.551666666983067, -8.28916665911396],
              [-38.551666666083747, -8.286666658939112],
              [-38.547500001823607, -8.286666658939112],
              [-38.547500000024911, -8.28499999305518],
              [-38.546666665733994, -8.28499999305518],
              [-38.546666665733994, -8.283333326271929],
              [-38.545833331443021, -8.283333326271929],
              [-38.545833332342397, -8.281666660387998],
              [-38.544999998950743, -8.281666659488678],
              [-38.544999999850063, -8.27916665931383],
              [-38.545833334141037, -8.27916665931383],
              [-38.545833331443021, -8.277499993429899],
              [-38.544166665559146, -8.277499994329219],
              [-38.544166665559146, -8.275833325747328],
              [-38.542499998775895, -8.275833325747328],
              [-38.542499999675215, -8.274999993255051],
              [-38.540833331992644, -8.274999994154371],
              [-38.540833332891964, -8.2733333264718],
              [-38.539166667008033, -8.2733333264718],
              [-38.539166667008033, -8.272499993080146],
              [-38.537499998426142, -8.272499992180826],
              [-38.537500001124101, -8.271666658789229],
              [-38.536666666833185, -8.271666658789229],
              [-38.536666666833185, -8.270833326296952],
              [-38.535000000049934, -8.270833326296952],
              [-38.535000000049934, -8.269999992905298],
              [-38.532500000774348, -8.269999992905298],
              [-38.532499998975709, -8.268333325222727],
              [-38.527499998626013, -8.268333326122047],
              [-38.527499998626013, -8.267499994529089],
              [-38.526666667032998, -8.26749999273045],
              [-38.526666667932318, -8.266666660238116],
              [-38.526666667032998, -8.265833325947199],
              [-38.52833333291693, -8.265833325947199],
              [-38.52833333201761, -8.264999991656225],
              [-38.532499999875029, -8.264999993454865],
              [-38.532499999875029, -8.264166658264628],
              [-38.534166667557599, -8.264166659163948],
              [-38.53416666575896, -8.263333325772294],
              [-38.537499998426142, -8.263333325772294],
              [-38.537499998426142, -8.262499993280016],
              [-38.539166668806672, -8.262499993280016],
              [-38.539166666108713, -8.2616666589891],
              [-38.540833332891964, -8.2616666589891],
              [-38.540833332891964, -8.262499993280016],
              [-38.5416666680822, -8.262499993280016],
              [-38.541666665384241, -8.263333326671614],
              [-38.544166666458466, -8.263333324872974],
              [-38.544166669156425, -8.264166659163948],
              [-38.547500000924288, -8.264166659163948],
              [-38.547499999125591, -8.264999993454865],
              [-38.550833331792774, -8.264999993454865],
              [-38.550833332692093, -8.264166658264628],
              [-38.551666665184428, -8.264166659163948],
              [-38.551666666083747, -8.263333325772294],
              [-38.552500000374664, -8.263333325772294],
              [-38.552499999475344, -8.262499993280016],
              [-38.554166668057235, -8.262499993280016],
              [-38.554166667157915, -8.259999992205849],
              [-38.554999999650192, -8.259999992205849],
              [-38.554999999650192, -8.257499992930263],
              [-38.555833332142527, -8.257499992030944],
              [-38.555833333941166, -8.254999992755415],
              [-38.556666668232083, -8.254999992755415],
              [-38.556666666433443, -8.254166659363818],
              [-38.557499998925778, -8.254166658464442],
              [-38.557499999825097, -8.249166659913385],
              [-38.558333331418055, -8.249166659014065],
              [-38.558333334116014, -8.247499992230814],
              [-38.560000000899265, -8.247499993130134],
              [-38.560000000899265, -8.243333326172035],
              [-38.560833333391599, -8.243333326172035],
              [-38.56083333159296, -8.242499992780381],
              [-38.561666667682516, -8.242499992780381],
              [-38.561666665883877, -8.24083332599713],
              [-38.56083333159296, -8.24083332599713],
              [-38.56083333249228, -8.238333325822282],
              [-38.56083333159296, -8.237499993330005],
              [-38.561666666783196, -8.237499993330005],
              [-38.561666665883877, -8.236666659039031],
              [-38.563333333566447, -8.236666659039031],
              [-38.563333332667128, -8.237499993330005],
              [-38.564166666958045, -8.237499993330005],
              [-38.564166666058725, -8.238333325822282],
              [-38.568333332117561, -8.238333325822282],
              [-38.568333332117561, -8.239166659213879],
              [-38.57249999907566, -8.239166659213879],
              [-38.57249999907566, -8.238333325822282],
              [-38.573333331567937, -8.238333325822282],
              [-38.573333333366577, -8.237499993330005],
              [-38.574166666758231, -8.237499993330005],
              [-38.574166665858911, -8.236666659039031],
              [-38.575000001049148, -8.236666659039031],
              [-38.575000000149828, -8.235833325647434],
              [-38.57833333371633, -8.235833327446073],
              [-38.57833333191769, -8.2349999931551],
              [-38.579166667107984, -8.2349999931551],
              [-38.579166667107984, -8.232499992980252],
              [-38.580000001398901, -8.232499992980252],
              [-38.579999998700941, -8.231666658689278],
              [-38.580833332092539, -8.231666657789958],
              [-38.580833332092539, -8.229999992805404],
              [-38.585833333341611, -8.229999993704723],
              [-38.585833331542972, -8.227499991731179],
              [-38.584999999950014, -8.227499993529818],
              [-38.584999999050694, -8.226666659238902],
              [-38.585833331542972, -8.226666659238902],
              [-38.585833331542972, -8.225833325847248],
              [-38.585000000849334, -8.225833326746567],
              [-38.584999999950014, -8.22499999335497],
              [-38.585833333341611, -8.22499999335497],
              [-38.585833332442292, -8.223333325672399],
              [-38.584999999050694, -8.223333325672399],
              [-38.584999999950014, -8.219999993005217],
              [-38.584166665659041, -8.219999993005217],
              [-38.58416666655836, -8.2191666587143],
              [-38.582500000674429, -8.2191666587143],
              [-38.58249999887579, -8.218333326221966],
              [-38.579166666208607, -8.218333326221966],
              [-38.579166668007304, -8.217499991931049],
              [-38.576666666033759, -8.217499992830369],
              [-38.576666666033759, -8.216666659438715],
              [-38.574166666758231, -8.216666660338035],
              [-38.574166665858911, -8.214999992655464],
              [-38.574999998351188, -8.214999991756144],
              [-38.575000001049148, -8.210833325697365],
              [-38.575833332642162, -8.210833324798045],
              [-38.575833333541482, -8.209166658914114],
              [-38.576666667832399, -8.209166658914114],
              [-38.576666666933079, -8.205833326246989],
              [-38.577499998526093, -8.205833326246989],
              [-38.577500001224053, -8.206666658739266],
              [-38.57833333371633, -8.206666658739266],
              [-38.57833333281701, -8.205833326246989],
              [-38.579166667107984, -8.205833326246989],
              [-38.579166667107984, -8.204999992855335],
              [-38.579999999600261, -8.204999992855335],
              [-38.580000000499581, -8.204166659463738],
              [-38.580833332991858, -8.204166659463738],
              [-38.580833332092539, -8.202499994479126],
              [-38.581666669081471, -8.202499992680487],
              [-38.581666668182152, -8.199999992505582],
              [-38.58249999887579, -8.199999993404901],
              [-38.582499999775109, -8.198333325722331],
              [-38.58416666745768, -8.198333324823011],
              [-38.58416666745768, -8.197499993230053],
              [-38.586666665833945, -8.197499993230053],
              [-38.586666666733265, -8.198333325722331],
              [-38.589166666908113, -8.198333327521027],
              [-38.589166666908113, -8.196666658939137],
              [-38.589999998501071, -8.196666658939137],
              [-38.589999999400391, -8.195833324648163],
              [-38.589166666908113, -8.195833326446802],
              [-38.589166666908113, -8.193333326271954],
              [-38.589999999400391, -8.193333326271954],
              [-38.589999998501071, -8.189999992705452],
              [-38.589166666008794, -8.189999992705452],
              [-38.589166667807433, -8.187499993429924],
              [-38.58833333261714, -8.187499993429924],
              [-38.58833333261714, -8.18666665913895],
              [-38.589166666908113, -8.18666665913895],
              [-38.589166666908113, -8.184999993255019],
              [-38.58833333171782, -8.184999993255019],
              [-38.58833333261714, -8.181666658789197],
              [-38.587499999225543, -8.181666658789197],
              [-38.587499999225543, -8.18083332629692],
              [-38.590833330993405, -8.18083332629692],
              [-38.590833331892725, -8.181666658789197],
              [-38.592499999575296, -8.181666658789197],
              [-38.592500000474615, -8.182499993080171],
              [-38.595833333141741, -8.182499993080171],
              [-38.595833334041117, -8.183333327371088],
              [-38.598333332417326, -8.183333326471768],
              [-38.598333331518006, -8.182499993080171],
              [-38.601666668681787, -8.182499993080171],
              [-38.601666665983828, -8.184166658964102],
              [-38.603333332767022, -8.184166658964102],
              [-38.603333331867702, -8.184999993255019],
              [-38.604999999550273, -8.184999993255019],
              [-38.605000000449593, -8.185833325747353],
              [-38.607499998825858, -8.185833326646673],
              [-38.607499998825858, -8.18666665913895],
              [-38.609166666508429, -8.18666665913895],
              [-38.609166665609109, -8.187499993429924],
              [-38.611666664884638, -8.187500001523802],
              [-38.615833332742056, -8.187499993429924],
              [-38.615833331842737, -8.188333325922201],
              [-38.61666666613371, -8.188333325922201],
              [-38.61666666703303, -8.187499993429924],
              [-38.625000000049909, -8.187499992530604],
              [-38.625000000949228, -8.188333325922201],
              [-38.625833333441562, -8.188333325922201],
              [-38.625833330743603, -8.189166659313855],
              [-38.628333332717091, -8.189166659313855],
              [-38.628333331817771, -8.190833326097049],
              [-38.630833331992619, -8.19083332519773],
              [-38.630833332891939, -8.189999992705452],
              [-38.634999998950775, -8.189999992705452],
              [-38.634999998950775, -8.19083332519773],
              [-38.635833334141012, -8.190833326097049],
              [-38.635833331443052, -8.191666657690064],
              [-38.636666665733969, -8.191666660388023],
              [-38.636666666633289, -8.189999992705452],
              [-38.638333331617901, -8.189999992705452],
              [-38.63833333251722, -8.189166659313855],
              [-38.641666666983042, -8.189166658414536],
              [-38.641666666983042, -8.189999992705452],
              [-38.643333332866973, -8.189999992705452],
              [-38.643333331068334, -8.190833326097049],
              [-38.644999998750905, -8.190833326097049],
              [-38.644999998750905, -8.191666659488703],
              [-38.645833330343862, -8.191666657690064],
              [-38.645833332142502, -8.193333326271954],
              [-38.646666665534156, -8.193333325372635],
              [-38.646666665534156, -8.194166658764232],
              [-38.648333334116046, -8.194166658764232],
              [-38.648333333216726, -8.193333327171274],
              [-38.654166666058757, -8.193333326271954],
              [-38.654166666058757, -8.194166659663551],
              [-38.655833333741327, -8.194166658764232],
              [-38.655833331942688, -8.194999993055205],
              [-38.656666667132924, -8.194999993055205],
              [-38.656666667132924, -8.194166658764232],
              [-38.657499998725939, -8.194166658764232],
              [-38.657499998725939, -8.194999993055205],
              [-38.658333333916175, -8.194999993055205],
              [-38.658333333016856, -8.194166659663551],
              [-38.66666666783243, -8.194166657864912],
              [-38.666666666933111, -8.193333326271954],
              [-38.669999999600236, -8.193333326271954],
              [-38.669999998700916, -8.192499991980981],
              [-38.67083333389121, -8.1924999928803],
              [-38.67083333299189, -8.188333325922201],
              [-38.671666666383487, -8.188333325922201],
              [-38.671666665484167, -8.183333326471768],
              [-38.67083333209257, -8.183333326471768],
              [-38.67083333209257, -8.182499993080171],
              [-38.671666665484167, -8.182499993080171],
              [-38.671666665484167, -8.181666659688574],
              [-38.672499998875821, -8.181666658789197],
              [-38.672499998875821, -8.18083332629692],
              [-38.673333331368099, -8.18083332629692],
              [-38.673333331368099, -8.179999992905323],
              [-38.674166665659072, -8.179999992905323],
              [-38.674166666558392, -8.179166660412989],
              [-38.675000000849309, -8.179166659513669],
              [-38.674999999949989, -8.178333326122072],
              [-38.675833332442323, -8.178333325222752],
              [-38.675833332442323, -8.177499992730418],
              [-38.674999999949989, -8.177499991831098],
              [-38.674999999949989, -8.170833326496791],
              [-38.675833332442323, -8.170833326496791],
              [-38.675833332442323, -8.169999993105137],
              [-38.67666666583392, -8.169999994004456],
              [-38.67666666673324, -8.166666660437954],
              [-38.677500000124837, -8.166666658639315],
              [-38.677499999225518, -8.165833326147037],
              [-38.678333331717852, -8.165833326147037],
              [-38.678333332617171, -8.16499999275544],
              [-38.679166667807408, -8.16499999275544],
              [-38.679166666908088, -8.164166660263106],
              [-38.681666667082993, -8.164166659363786],
              [-38.681666666183673, -8.16333332507287],
              [-38.68250000047459, -8.163333325972189],
              [-38.682499998675951, -8.159999992405687],
              [-38.683333332966924, -8.159999994204327],
              [-38.683333332067605, -8.158333326521756],
              [-38.684166665459202, -8.158333326521756],
              [-38.684166666358522, -8.157499993130159],
              [-38.684999998850799, -8.157499994029479],
              [-38.685000000649495, -8.156666658839185],
              [-38.685833331343133, -8.156666658839185],
              [-38.685833331343133, -8.155833328145548],
              [-38.685000000649495, -8.155833326346908],
              [-38.684999999750119, -8.154999992955254],
              [-38.681666667082993, -8.154999993854574],
              [-38.681666666183673, -8.154166658664337],
              [-38.679166666008769, -8.154166658664337],
              [-38.679166666908088, -8.155833326346908],
              [-38.674999999949989, -8.155833326346908],
              [-38.674999999050669, -8.156666658839185],
              [-38.672499999775141, -8.156666658839185],
              [-38.672499999775141, -8.155833325447588],
              [-38.67083333209257, -8.155833326346908],
              [-38.67083333299189, -8.154999992955254],
              [-38.669166667107959, -8.154999992955254],
              [-38.669166666208639, -8.155833326346908],
              [-38.668333334615681, -8.155833325447588],
              [-38.668333333716362, -8.156666658839185],
              [-38.667499998526068, -8.156666658839185],
              [-38.667499999425388, -8.158333326521756],
              [-38.666666666033791, -8.158333326521756],
              [-38.666666666933111, -8.161666660088258],
              [-38.66500000014986, -8.161666658289619],
              [-38.66499999835122, -8.162499993479855],
              [-38.659999998900787, -8.162499993479855],
              [-38.660000000699426, -8.161666659188938],
              [-38.65916666640851, -8.161666659188938],
              [-38.659166668207149, -8.160833325797284],
              [-38.657499999625259, -8.160833326696604],
              [-38.657499998725939, -8.159999993305007],
              [-38.650833331592935, -8.159999993305007],
              [-38.650833333391574, -8.160833325797284],
              [-38.65, -8.160833325797284],
              [-38.649999999100658, -8.162499993479855],
              [-38.649166667507643, -8.162499994379175],
              [-38.649166665709004, -8.163333325972189],
              [-38.647499998925753, -8.163333325972189],
              [-38.647499998925753, -8.162499991681216],
              [-38.646666665534156, -8.162499993479855],
              [-38.646666665534156, -8.161666658289619],
              [-38.644999998750905, -8.161666659188938],
              [-38.644999998750905, -8.160833325797284],
              [-38.644166667157947, -8.160833325797284],
              [-38.644166665359251, -8.159999993305007],
              [-38.640000000199791, -8.159999993305007],
              [-38.640000000199791, -8.159166659014033],
              [-38.63833333341654, -8.159166659014033],
              [-38.63833333251722, -8.158333324723117],
              [-38.635833334141012, -8.158333326521756],
              [-38.635833332342372, -8.159166659014033],
              [-38.633333332167524, -8.159166659014033],
              [-38.633333332167524, -8.159999993305007],
              [-38.630833333791259, -8.159999993305007],
              [-38.630833333791259, -8.159166659014033],
              [-38.629999998601022, -8.159166659014033],
              [-38.629999998601022, -8.158333326521756],
              [-38.629166667907327, -8.158333324723117],
              [-38.629166667008008, -8.159166659014033],
              [-38.628333331817771, -8.159166659014033],
              [-38.628333331817771, -8.159999993305007],
              [-38.627500001124133, -8.159999993305007],
              [-38.627500001124133, -8.160833325797284],
              [-38.62666666593384, -8.160833325797284],
              [-38.62666666593384, -8.161666659188938],
              [-38.623333332367338, -8.161666659188938],
              [-38.623333334165977, -8.160833324897965],
              [-38.622499998076421, -8.160833325797284],
              [-38.62250000077438, -8.159166659014033],
              [-38.621666666483407, -8.159166659014033],
              [-38.621666665584087, -8.157499993130159],
              [-38.619166667207878, -8.157499992230782],
              [-38.619166667207878, -8.155833327246228],
              [-38.617500000424627, -8.155833326346908],
              [-38.617500000424627, -8.156666657939866],
              [-38.614166667757445, -8.156666659738505],
              [-38.614166667757445, -8.155833326346908],
              [-38.613333331667889, -8.155833326346908],
              [-38.613333332567208, -8.154999992955254],
              [-38.611666666683277, -8.154999992055934],
              [-38.611666666683277, -8.155833326346908],
              [-38.607499998825858, -8.155833325447588],
              [-38.607500000624498, -8.154999993854574],
              [-38.606666668132164, -8.154999992955254],
              [-38.606666665434204, -8.154166658664337],
              [-38.605833332042607, -8.154166658664337],
              [-38.605833332042607, -8.153333327071323],
              [-38.604166666158676, -8.153333326172003],
              [-38.604166666158676, -8.152499992780406],
              [-38.603333333666399, -8.152499992780406],
              [-38.603333334565718, -8.151666659388752],
              [-38.602499999375425, -8.151666658489432],
              [-38.602499998476105, -8.150833326896475],
              [-38.601666666883148, -8.150833325997155],
              [-38.601666666883148, -8.149999993504878],
              [-38.599999999200577, -8.149999993504878],
              [-38.599999999200577, -8.149166658314584],
              [-38.598333331518006, -8.149166659213904],
              [-38.598333334215965, -8.148333325822307],
              [-38.597500000824311, -8.148333325822307],
              [-38.597499999025672, -8.147499992430653],
              [-38.594999998850824, -8.147499993329973],
              [-38.595000000649463, -8.146666659039056],
              [-38.594166669056506, -8.146666659938376],
              [-38.594166665459227, -8.144999992255805],
              [-38.593333332966893, -8.144999993155125],
              [-38.593333332966893, -8.144166657964831],
              [-38.592499999575296, -8.144166658864151],
              [-38.592500000474615, -8.143333326371874],
              [-38.590833330993405, -8.143333326371874],
              [-38.590833330993405, -8.138333326022121],
              [-38.59000000119903, -8.138333326022121],
              [-38.59000000119903, -8.137499993529843],
              [-38.589166667807433, -8.137499993529843],
              [-38.589166666008794, -8.13666665923887],
              [-38.58833333261714, -8.13666665923887],
              [-38.588333333516459, -8.135833325847273],
              [-38.587499999225543, -8.135833325847273],
              [-38.587499999225543, -8.134999993354938],
              [-38.586666666733265, -8.134999993354938],
              [-38.586666666733265, -8.134166659064022],
              [-38.586666666733265, -8.133333325672368],
              [-38.587500000124862, -8.133333325672368],
              [-38.587499999225543, -8.127499992830337],
              [-38.58833333261714, -8.127499992830337],
              [-38.591666666183642, -8.127499992830337],
              [-38.591666667982281, -8.12666665943874],
              [-38.594166665459227, -8.12666665943874],
              [-38.594166668157186, -8.125833326047086],
              [-38.602499998476105, -8.125833326047086],
              [-38.602499998476105, -8.124999992655489],
              [-38.603333331867702, -8.124999992655489],
              [-38.603333331867702, -8.124166659263892],
              [-38.604166666158676, -8.124166659263892],
              [-38.604166667057996, -8.123333326771558],
              [-38.604999998650953, -8.123333325872238],
              [-38.605000000449593, -8.122499993379961],
              [-38.609999999000706, -8.122499993379961],
              [-38.609999999000706, -8.121666659088987],
              [-38.610833335090319, -8.121666659088987],
              [-38.610833335090319, -8.12083332749603],
              [-38.613333332567208, -8.12083332479807],
              [-38.613333332567208, -8.119999993205056],
              [-38.614166666858125, -8.119999994104376],
              [-38.614166666858125, -8.12083332749603],
              [-38.61499999755182, -8.12083332569739],
              [-38.61499999845114, -8.119999993205056],
              [-38.61666666613371, -8.119999993205056],
              [-38.61666666703303, -8.119166658914139],
              [-38.618333333816281, -8.119166658914139],
              [-38.618333332916961, -8.117499992130888],
              [-38.625000000949228, -8.117499993030208],
              [-38.624999999150589, -8.116666658739291],
              [-38.62666666593384, -8.116666658739291],
              [-38.62666666593384, -8.115833326246957],
              [-38.627499998426117, -8.115833326246957],
              [-38.627500002023453, -8.11499999285536],
              [-38.628333332717091, -8.11499999195604],
              [-38.628333333616411, -8.113333326072109],
              [-38.629166666108688, -8.113333326072109],
              [-38.629166667907327, -8.112499992680455],
              [-38.629999998601022, -8.112499991781135],
              [-38.629999999500342, -8.109166659113953],
              [-38.630833332891939, -8.109166659113953],
              [-38.630833332891939, -8.107499993230078],
              [-38.631666667182913, -8.107499993230078],
              [-38.631666665384273, -8.106666658939105],
              [-38.63249999967519, -8.106666658039785],
              [-38.63249999877587, -8.104999993055173],
              [-38.631666667182913, -8.104999992155854],
              [-38.631666665384273, -8.102499992880325],
              [-38.63249999877587, -8.102499991981006],
              [-38.63249999967519, -8.100833326097074],
              [-38.633333333066844, -8.100833326097074],
              [-38.633333332167524, -8.099999992705477],
              [-38.63249999877587, -8.099999992705477],
              [-38.63250000057451, -8.096666659138975],
              [-38.631666668082232, -8.096666660038295],
              [-38.631666667182913, -8.095833325747321],
              [-38.630833332891939, -8.095833325747321],
              [-38.630833332891939, -8.094999993255044],
              [-38.630000000399662, -8.094999993255044],
              [-38.629999998601022, -8.09416665896407],
              [-38.629166665209368, -8.09416665986339],
              [-38.629166667907327, -8.092499993979516],
              [-38.629999999500342, -8.092499993080196],
              [-38.629999999500342, -8.091666658789222],
              [-38.629999999500342, -8.090833326296945],
              [-38.630833331992619, -8.090833326296945],
              [-38.630833333791259, -8.089999992905291],
              [-38.631666667182913, -8.089999992905291],
              [-38.631666668981552, -8.089166659513694],
              [-38.63250000057451, -8.089166660413014],
              [-38.63249999877587, -8.08833332612204],
              [-38.634999999850095, -8.08833332522272],
              [-38.635000000749415, -8.087499992730443],
              [-38.635833334141012, -8.087499992730443],
              [-38.635833332342372, -8.084999993454915],
              [-38.636666667532666, -8.084999994354234],
              [-38.636666666633289, -8.084166659163941],
              [-38.637500000024943, -8.084166659163941],
              [-38.637499999125623, -8.08249999238069],
              [-38.640833333591445, -8.08249999328001],
              [-38.640833331792805, -8.081666658089773],
              [-38.640000001099111, -8.081666658989093],
              [-38.639999998401152, -8.079166658814188],
              [-38.639166665908874, -8.079166658814188],
              [-38.639166666808194, -8.074999992755409],
              [-38.640833333591445, -8.074999992755409],
              [-38.640833331792805, -8.074166659363812],
              [-38.641666666083722, -8.074166659363812],
              [-38.641666666083722, -8.073333326871477],
              [-38.642499999475376, -8.073333325972158],
              [-38.642499998576056, -8.065833326346876],
              [-38.643333332866973, -8.065833326346876],
              [-38.643333331967654, -8.064999992955279],
              [-38.644166667157947, -8.064999992055959],
              [-38.644166667157947, -8.064166658664305],
              [-38.645000000549544, -8.064166658664305],
              [-38.644999998750905, -8.061666659388777],
              [-38.646666668232115, -8.061666659388777],
              [-38.646666665534156, -8.060833325997123],
              [-38.647500000724392, -8.060833325997123],
              [-38.647500000724392, -8.059999991706206],
              [-38.648333334116046, -8.059999994404166],
              [-38.648333334116046, -8.059166660113249],
              [-38.649166667507643, -8.059166659213929],
              [-38.649166666608323, -8.056666659039024],
              [-38.648333331418087, -8.056666659039024],
              [-38.648333331418087, -8.055833325647427],
              [-38.649166665709004, -8.055833325647427],
              [-38.649166666608323, -8.054166658864176],
              [-38.650833332492255, -8.054166658864176],
              [-38.650833331592935, -8.053333326371842],
              [-38.651666667682548, -8.053333326371842],
              [-38.651666667682548, -8.052499992980245],
              [-38.652500000174825, -8.052499992080925],
              [-38.652500000174825, -8.050833326196994],
              [-38.650833333391574, -8.050833326196994],
              [-38.650833333391574, -8.049166659413743],
              [-38.651666667682548, -8.049166659413743],
              [-38.651666665883909, -8.047499993529811],
              [-38.651666665883909, -8.045833325847241],
              [-38.65, -8.045833325847241],
              [-38.649999999100658, -8.043333325672393],
              [-38.648333331418087, -8.043333325672393],
              [-38.648333331418087, -8.042499993180115],
              [-38.645833332142502, -8.042499992280796],
              [-38.645833333941141, -8.041666659788461],
              [-38.642499998576056, -8.041666658889142],
              [-38.642499999475376, -8.042499994079435],
              [-38.640000000199791, -8.042499993180115],
              [-38.639999998401152, -8.041666658889142],
              [-38.636666665733969, -8.041666658889142],
              [-38.636666665733969, -8.040833326396864],
              [-38.634166665559121, -8.040833325497545],
              [-38.634166666458441, -8.03999999300521],
              [-38.63250000057451, -8.03999999300521],
              [-38.63250000057451, -8.040833326396864],
              [-38.630833332891939, -8.040833326396864],
              [-38.630833331992619, -8.041666658889142],
              [-38.629999998601022, -8.041666658889142],
              [-38.629999999500342, -8.040833326396864],
              [-38.62666666593384, -8.040833326396864],
              [-38.62666666683316, -8.039166658714294],
              [-38.625833333441562, -8.039166658714294],
              [-38.625833331642923, -8.037499991931043],
              [-38.624166667557631, -8.037499992830362],
              [-38.624166665758992, -8.036666659438708],
              [-38.623333331468018, -8.036666659438708],
              [-38.623333332367338, -8.035833326047111],
              [-38.62250000077438, -8.035833326047111],
              [-38.62249999987506, -8.033333325872263],
              [-38.621666666483407, -8.033333326771583],
              [-38.621666665584087, -8.031666659089012],
              [-38.620833333091809, -8.031666659089012],
              [-38.620833333991129, -8.030833325697358],
              [-38.619999999700156, -8.030833325697358],
              [-38.619999998800836, -8.029999993205081],
              [-38.620833333991129, -8.029999993205081],
              [-38.62083333219249, -8.029166658014788],
              [-38.621666666483407, -8.029166658914107],
              [-38.621666665584087, -8.026666658739259],
              [-38.622499998975741, -8.026666658739259],
              [-38.62250000077438, -8.024166659463731],
              [-38.621666665584087, -8.024166659463731],
              [-38.621666666483407, -8.02249999268048],
              [-38.619999999700156, -8.02249999268048],
              [-38.620000000599532, -8.021666659288826],
              [-38.619166667207878, -8.021666659288826],
              [-38.619166664509919, -8.020833325897229],
              [-38.619999998800836, -8.020833324997909],
              [-38.619999999700156, -8.019999993404952],
              [-38.619166667207878, -8.019999992505575],
              [-38.619166667207878, -8.018333325722381],
              [-38.618333332017642, -8.01833332752102],
              [-38.618333333816281, -8.017499993230047],
              [-38.615833331842737, -8.017499993230047],
              [-38.615833333641376, -8.018333325722381],
              [-38.61499999845114, -8.018333325722381],
              [-38.614999999350459, -8.019166660013298],
              [-38.614166666858125, -8.019166659113978],
              [-38.614166666858125, -8.019999993404952],
              [-38.607499998825858, -8.019999993404952],
              [-38.607499998825858, -8.019166659113978],
              [-38.605833333841247, -8.019166659113978],
              [-38.605833332042607, -8.018333325722381],
              [-38.605000000449593, -8.01833332752102],
              [-38.604999998650953, -8.017499993230047],
              [-38.604166666158676, -8.017499993230047],
              [-38.604166667057996, -8.015833326446796],
              [-38.603333331867702, -8.015833326446796],
              [-38.603333332767022, -8.014999993055199],
              [-38.601666667782467, -8.014999993055199],
              [-38.601666667782467, -8.014166658764225],
              [-38.598333330618686, -8.014166658764225],
              [-38.598333332417326, -8.012499992880294],
              [-38.597500000824311, -8.012499992880294],
              [-38.597500000824311, -8.014166658764225],
              [-38.596666665634075, -8.014166658764225],
              [-38.596666667432714, -8.014999993055199],
              [-38.593333333866212, -8.014999993055199],
              [-38.593333333866212, -8.014166658764225],
              [-38.592499998675976, -8.014166658764225],
              [-38.592500000474615, -8.013333326271947],
              [-38.591666666183642, -8.013333326271947],
              [-38.591666667082961, -8.012499992880294],
              [-38.592499998675976, -8.012499991980974],
              [-38.592499998675976, -8.011666659488696],
              [-38.591666667082961, -8.011666660388016],
              [-38.591666666183642, -8.005833325747346],
              [-38.590833333691364, -8.005833325747346],
              [-38.590833332792045, -8.003333324673122],
              [-38.589999998501071, -8.003333326471818],
              [-38.589999998501071, -8.001666659688567],
              [-38.589166667807433, -8.001666658789247],
              [-38.589166666008794, -8.000833326296913],
              [-38.585833331542972, -8.000833326296913],
              [-38.585833333341611, -8.001666658789247],
              [-38.583333333166763, -8.001666657889928],
              [-38.583333331368124, -8.000833326296913],
              [-38.581666666383512, -8.000833326296913],
              [-38.581666665484192, -7.999999992005996],
              [-38.580000000499581, -7.999999993804636],
              [-38.580000001398901, -7.999166659513662],
              [-38.579166667107984, -7.999166659513662],
              [-38.579166667107984, -7.998333326122065],
              [-38.57833333191769, -7.998333326122065],
              [-38.57833333371633, -7.997499991831091],
              [-38.576666666033759, -7.997499992730411],
              [-38.576666667832399, -7.996666659338814],
              [-38.575833331742842, -7.996666659338814],
              [-38.575833331742842, -7.997499992730411],
              [-38.575000000149828, -7.997499992730411],
              [-38.574999998351188, -7.998333326122065],
              [-38.573333333366577, -7.998333326122065],
              [-38.573333332467257, -7.999166659513662],
              [-38.570833334990368, -7.999166659513662],
              [-38.570833334990368, -7.998333326122065],
              [-38.568333332117561, -7.998333326122065],
              [-38.5683333339162, -7.997499992730411],
              [-38.567500000524547, -7.997499994529051],
              [-38.567499999625227, -7.996666659338814],
              [-38.567499999625227, -7.992499993280035],
              [-38.566666667132949, -7.992499993280035],
              [-38.566666668032269, -7.990833326496784],
              [-38.567499998725907, -7.990833326496784],
              [-38.567499999625227, -7.986666660438004],
              [-38.568333332117561, -7.986666658639365],
              [-38.5683333339162, -7.985833326147031],
              [-38.567499999625227, -7.985833326147031],
              [-38.567499999625227, -7.98416665936378],
              [-38.566666667132949, -7.98416665936378],
              [-38.56666666443499, -7.982499993479848],
              [-38.565833332841976, -7.982499991681209],
              [-38.565833331942656, -7.981666659188932],
              [-38.56666666623363, -7.981666660088251],
              [-38.566666668032269, -7.980833325797278],
              [-38.569999999800132, -7.980833325797278],
              [-38.569999998900812, -7.979999993305],
              [-38.571666665684006, -7.979999993305],
              [-38.571666666583326, -7.980833325797278],
              [-38.57249999997498, -7.980833325797278],
              [-38.5725000008743, -7.981666659188932],
              [-38.573333332467257, -7.981666659188932],
              [-38.573333333366577, -7.982499993479848],
              [-38.574999998351188, -7.982499991681209],
              [-38.575000001049148, -7.98416665936378],
              [-38.576666666033759, -7.98416665936378],
              [-38.576666666933079, -7.984999992755434],
              [-38.583333333166763, -7.984999992755434],
              [-38.583333334066083, -7.98416665936378],
              [-38.58249999887579, -7.98416665936378],
              [-38.58249999887579, -7.982499993479848],
              [-38.583333331368124, -7.982499993479848],
              [-38.583333333166763, -7.979999993305],
              [-38.58416666655836, -7.979999993305],
              [-38.584166665659041, -7.977499994029472],
              [-38.584999999950014, -7.977499993130152],
              [-38.584999999950014, -7.976666658839179],
              [-38.585833333341611, -7.976666658839179],
              [-38.585833331542972, -7.975833326346901],
              [-38.586666665833945, -7.975833327246221],
              [-38.586666667632585, -7.974999993854567],
              [-38.587499999225543, -7.974999992955247],
              [-38.587499997426903, -7.97416665866433],
              [-38.58833333261714, -7.974166657765011],
              [-38.5883333308185, -7.973333326171996],
              [-38.591666666183642, -7.973333326171996],
              [-38.591666667082961, -7.972499992780399],
              [-38.594999998850824, -7.972499992780399],
              [-38.594999999750144, -7.971666659388745],
              [-38.595833331343101, -7.971666660288122],
              [-38.595833333141741, -7.970833325997148],
              [-38.596666665634075, -7.970833325997148],
              [-38.596666665634075, -7.969999993504871],
              [-38.599166666708243, -7.969999993504871],
              [-38.599166667607562, -7.969166659213897],
              [-38.601666666883148, -7.969166659213897],
              [-38.601666667782467, -7.9683333258223],
              [-38.602499999375425, -7.9683333258223],
              [-38.603333333666399, -7.9683333258223],
              [-38.603333333666399, -7.967499993329966],
              [-38.604166667057996, -7.967499993329966],
              [-38.604166667957315, -7.964999993155118],
              [-38.604999998650953, -7.964999993155118],
              [-38.605000000449593, -7.964166657964824],
              [-38.605833332941927, -7.964166658864144],
              [-38.605833332941927, -7.96249999298027],
              [-38.606666668132164, -7.96249999298027],
              [-38.606666667232844, -7.961666658689296],
              [-38.607499998825858, -7.961666660487936],
              [-38.607500000624498, -7.959999992805365],
              [-38.609166666508429, -7.959999992805365],
              [-38.609166669206388, -7.959166660313088],
              [-38.609999999000706, -7.959166659413768],
              [-38.609999999900026, -7.958333326022114],
              [-38.610833334191, -7.958333325122794],
              [-38.610833331492984, -7.957499991731197],
              [-38.609999999000706, -7.957499993529837],
              [-38.609999999000706, -7.956666659238863],
              [-38.610833335090319, -7.956666659238863],
              [-38.610833335090319, -7.955833325847266],
              [-38.611666665783957, -7.955833325847266],
              [-38.611666667582597, -7.954166659064015],
              [-38.612500000974251, -7.954166659963334],
              [-38.612500000074874, -7.953333325672418],
              [-38.611666665783957, -7.953333325672418],
              [-38.611666666683277, -7.952499993180083],
              [-38.610833331492984, -7.952499993180083],
              [-38.610833331492984, -7.950833326396832],
              [-38.609999999000706, -7.950833326396832],
              [-38.609999999000706, -7.949166658714262],
              [-38.61083333239236, -7.949166658714262],
              [-38.610833331492984, -7.94749999283033],
              [-38.611666667582597, -7.94749999283033],
              [-38.611666665783957, -7.944166659263885],
              [-38.611666666683277, -7.943333325872231],
              [-38.610833334191, -7.943333325872231],
              [-38.61083333239236, -7.942499993379954],
              [-38.610000000799346, -7.942499993379954],
              [-38.610000000799346, -7.94166665908898],
              [-38.609166665609109, -7.94166665908898],
              [-38.609166666508429, -7.939999993205049],
              [-38.608333334016095, -7.939999993205049],
              [-38.608333334915415, -7.937499993030201],
              [-38.607500000624498, -7.937499993929521],
              [-38.607499999725178, -7.936666658739284],
              [-38.606666665434204, -7.936666658739284],
              [-38.606666665434204, -7.93583332624695],
              [-38.605833333841247, -7.93583332624695],
              [-38.605833333841247, -7.934999992855353],
              [-38.600000000099897, -7.934999992855353],
              [-38.599999999200577, -7.934166659463699],
              [-38.599166667607562, -7.934166659463699],
              [-38.599166667607562, -7.933333326072102],
              [-38.597499999924992, -7.933333326971422],
              [-38.597499999025672, -7.932499992680448],
              [-38.595833333141741, -7.932499992680448],
              [-38.595833334041117, -7.931666659288851],
              [-38.595000000649463, -7.931666659288851],
              [-38.595000000649463, -7.929166659114003],
              [-38.594166665459227, -7.929166659114003],
              [-38.594166665459227, -7.928333324823029],
              [-38.593333332067573, -7.928333325722349],
              [-38.593333332966893, -7.926666658939098],
              [-38.592500000474615, -7.926666658939098],
              [-38.592500000474615, -7.924999993055167],
              [-38.591666666183642, -7.924999993055167],
              [-38.591666666183642, -7.92416665876425],
              [-38.590833333691364, -7.92416665876425],
              [-38.590833330993405, -7.922499992880319],
              [-38.589999998501071, -7.922499992880319],
              [-38.589999998501071, -7.921666659488722],
              [-38.589166666008794, -7.921666659488722],
              [-38.589166667807433, -7.920833326097068],
              [-38.58833333171782, -7.920833326097068],
              [-38.58833333261714, -7.919999992705471],
              [-38.587500001024182, -7.91999999450411],
              [-38.587500000124862, -7.919166659313817],
              [-38.586666665833945, -7.919166659313817],
              [-38.586666666733265, -7.916666658239649],
              [-38.585833332442292, -7.916666658239649],
              [-38.585833332442292, -7.915833326646634],
              [-38.585000001748654, -7.915833324847995],
              [-38.584999999050694, -7.914999994154357],
              [-38.584166665659041, -7.914999992355717],
              [-38.584166665659041, -7.909999992905284],
              [-38.586666664934569, -7.909999992905284],
              [-38.586666667632585, -7.909166659513687],
              [-38.587500001024182, -7.909166659513687],
              [-38.587499999225543, -7.908333326122033],
              [-38.58833333261714, -7.908333326122033],
              [-38.58833333171782, -7.907499992730436],
              [-38.59000000119903, -7.907499992730436],
              [-38.589999999400391, -7.904999991656268],
              [-38.590833332792045, -7.904999993454908],
              [-38.590833332792045, -7.904166659163934],
              [-38.589999999400391, -7.904166658264614],
              [-38.589999999400391, -7.902499993280003],
              [-38.589166666008794, -7.902499994179323],
              [-38.589166666908113, -7.899999993105155],
              [-38.58833333261714, -7.899999993105155],
              [-38.58833333171782, -7.899166658814181],
              [-38.587499999225543, -7.899166657914861],
              [-38.587500001024182, -7.897499992930307],
              [-38.586666666733265, -7.897499992930307],
              [-38.586666666733265, -7.895833326147056],
              [-38.585833331542972, -7.895833326147056],
              [-38.585833331542972, -7.894999992755402],
              [-38.584999999050694, -7.894999992755402],
              [-38.584999999950014, -7.894166659363805],
              [-38.58416666655836, -7.894166660263124],
              [-38.58416666655836, -7.893333325972151],
              [-38.583333334066083, -7.893333325972151],
              [-38.583333334066083, -7.8916666591889],
              [-38.582499999775109, -7.8916666591889],
              [-38.58249999887579, -7.890833325797303],
              [-38.581666666383512, -7.890833325797303],
              [-38.581666666383512, -7.889999993305025],
              [-38.580833333891178, -7.889999993305025],
              [-38.580833332092539, -7.889166659014052],
              [-38.579999999600261, -7.889166659014052],
              [-38.579999998700941, -7.886666659738523],
              [-38.579166666208607, -7.886666658839204],
              [-38.579166666208607, -7.885833326346869],
              [-38.576666667832399, -7.885833326346869],
              [-38.576666666933079, -7.884999992955272],
              [-38.574166665858911, -7.884999992955272],
              [-38.574166667657551, -7.884166658664299],
              [-38.57249999997498, -7.884166660462938],
              [-38.57249999997498, -7.883333326172021],
              [-38.571666666583326, -7.883333326172021],
              [-38.571666665684006, -7.880833325997173],
              [-38.57249999907566, -7.880833325097854],
              [-38.57249999907566, -7.879999993504839],
              [-38.573333331567937, -7.879999993504839],
              [-38.573333332467257, -7.879166659213922],
              [-38.574166666758231, -7.879166658314603],
              [-38.574166667657551, -7.878333325822268],
              [-38.575000001049148, -7.878333325822268],
              [-38.575000001049148, -7.876666659039017],
              [-38.574166666758231, -7.876666659039017],
              [-38.574166665858911, -7.874999993155086],
              [-38.573333333366577, -7.874999993155086],
              [-38.573333330668618, -7.874166658864169],
              [-38.572500001773619, -7.874166658864169],
              [-38.5725000008743, -7.873333326371892],
              [-38.57249999907566, -7.872499993879558],
              [-38.573333333366577, -7.872499992980238],
              [-38.573333331567937, -7.870833326196987],
              [-38.575000001049148, -7.870833326196987],
              [-38.574999998351188, -7.871666658689321],
              [-38.575833331742842, -7.871666658689321],
              [-38.575833332642162, -7.872499992980238],
              [-38.583333333166763, -7.872499992980238],
              [-38.583333331368124, -7.867499993529805],
              [-38.58416666655836, -7.867499993529805],
              [-38.58416666655836, -7.866666660138208],
              [-38.584999999950014, -7.866666659238888],
              [-38.584999999050694, -7.864999993354957],
              [-38.585833331542972, -7.864999993354957],
              [-38.585833331542972, -7.863333327471025],
              [-38.594166665459227, -7.863333325672386],
              [-38.595000000649463, -7.863333325672386],
              [-38.594999999750144, -7.860833326396858],
              [-38.59000000119903, -7.860833325497538],
              [-38.589999999400391, -7.856666659438758],
              [-38.590833331892725, -7.856666659438758],
              [-38.590833331892725, -7.852499994279242],
              [-38.589999999400391, -7.852499993379922],
              [-38.59000000119903, -7.851666659089005],
              [-38.594166668157186, -7.851666659988325],
              [-38.594166666358547, -7.852499993379922],
              [-38.594999998850824, -7.852499993379922],
              [-38.594999998850824, -7.853333325872256],
              [-38.598333332417326, -7.853333325872256],
              [-38.598333334215965, -7.852499993379922],
              [-38.599166665808923, -7.852499992480602],
              [-38.599166667607562, -7.851666659089005],
              [-38.604166667957315, -7.851666659089005],
              [-38.604166667057996, -7.852499993379922],
              [-38.604999998650953, -7.852499993379922],
              [-38.604999999550273, -7.853333324972937],
              [-38.605833332042607, -7.853333325872256],
              [-38.605833332042607, -7.848333326421823],
              [-38.606666667232844, -7.848333326421823],
              [-38.606666665434204, -7.847499993030226],
              [-38.607500000624498, -7.847499993030226],
              [-38.607500000624498, -7.846666658739252],
              [-38.608333333116775, -7.846666658739252],
              [-38.608333333116775, -7.84333332697139],
              [-38.609166666508429, -7.84333332607207],
              [-38.609166665609109, -7.842499992680473],
              [-38.611666667582597, -7.842499994479113],
              [-38.611666666683277, -7.840833325897222],
              [-38.610833334191, -7.840833325897222],
              [-38.610833331492984, -7.836666658939123],
              [-38.611666666683277, -7.836666658939123],
              [-38.612500000074874, -7.836666658039803],
              [-38.612499999175554, -7.835833326446789],
              [-38.613333331667889, -7.835833326446789],
              [-38.613333332567208, -7.834999993055192],
              [-38.615833332742056, -7.834999993055192],
              [-38.615833332742056, -7.835833326446789],
              [-38.622499998975741, -7.835833326446789],
              [-38.62250000077438, -7.836666658939123],
              [-38.629166667907327, -7.836666658939123],
              [-38.629166666108688, -7.83749999323004],
              [-38.630833331992619, -7.83749999323004],
              [-38.630833332891939, -7.839166659113971],
              [-38.631666665384273, -7.839166659113971],
              [-38.631666667182913, -7.839999994304264],
              [-38.63249999967519, -7.839999993404945],
              [-38.63249999877587, -7.840833325897222],
              [-38.633333333066844, -7.840833325897222],
              [-38.633333333066844, -7.842499991781153],
              [-38.634166665559121, -7.842499994479113],
              [-38.634166665559121, -7.84333332607207],
              [-38.635833331443052, -7.84333332607207],
              [-38.635833332342372, -7.844999992855321],
              [-38.636666667532666, -7.844999992855321],
              [-38.636666668431985, -7.846666658739252],
              [-38.637500000024943, -7.846666658739252],
              [-38.637500000924263, -7.848333326421823],
              [-38.639166666808194, -7.848333326421823],
              [-38.639166666808194, -7.849166658914157],
              [-38.639999998401152, -7.849166658914157],
              [-38.640000000199791, -7.850833325697351],
              [-38.640833332692125, -7.850833325697351],
              [-38.640833331792805, -7.851666659089005],
              [-38.641666666083722, -7.851666659089005],
              [-38.641666666083722, -7.850833324798032],
              [-38.645000000549544, -7.850833325697351],
              [-38.644999998750905, -7.851666659988325],
              [-38.649166666608323, -7.851666659089005],
              [-38.649166665709004, -7.852499993379922],
              [-38.651666666783228, -7.852499993379922],
              [-38.651666665883909, -7.853333325872256],
              [-38.652500000174825, -7.853333325872256],
              [-38.652500000174825, -7.854166659263853],
              [-38.653333333566422, -7.854166660163173],
              [-38.653333332667103, -7.855833326047104],
              [-38.654999998551034, -7.855833326047104],
              [-38.654999997651714, -7.856666659438758],
              [-38.655833332842008, -7.856666660338078],
              [-38.655833333741327, -7.858333326221953],
              [-38.657499998725939, -7.858333326221953],
              [-38.657499998725939, -7.859166657814967],
              [-38.65916666550919, -7.859166658714287],
              [-38.65916666640851, -7.859999993005204],
              [-38.660833331393064, -7.859999993005204],
              [-38.660833331393064, -7.860833326396858],
              [-38.661666664784718, -7.860833326396858],
              [-38.661666666583358, -7.861666659788455],
              [-38.662499999974955, -7.861666658889135],
              [-38.662499999075635, -7.864999993354957],
              [-38.661666667482677, -7.864999993354957],
              [-38.661666667482677, -7.86999999370471],
              [-38.662499999974955, -7.86999999280539],
              [-38.662499999974955, -7.87583332564742],
              [-38.661666665684038, -7.87583332564742],
              [-38.661666666583358, -7.879166659213922],
              [-38.66083333409108, -7.879166659213922],
              [-38.66083333409108, -7.879999993504839],
              [-38.659999999800107, -7.879999994404159],
              [-38.659999998001467, -7.882499992780367],
              [-38.659999998900787, -7.884999992955272],
              [-38.667499998526068, -7.884999992955272],
              [-38.667499998526068, -7.884166658664299],
              [-38.67083333209257, -7.884166658664299],
              [-38.67083333209257, -7.883333326172021],
              [-38.674999999050669, -7.883333326172021],
              [-38.675000000849309, -7.882499992780367],
              [-38.675833331542947, -7.882499991881048],
              [-38.675833335140283, -7.880833325997173],
              [-38.674999999050669, -7.880833325997173],
              [-38.675000000849309, -7.876666659039017],
              [-38.675833330643627, -7.876666659039017],
              [-38.675833332442323, -7.873333327271212],
              [-38.67666666583392, -7.873333326371892],
              [-38.67666666583392, -7.870833326196987],
              [-38.677499999225518, -7.870833326196987],
              [-38.677500000124837, -7.869166659413736],
              [-38.679166666008769, -7.869166659413736],
              [-38.679166666908088, -7.865833324947914],
              [-38.679999998501103, -7.865833325847291],
              [-38.679999999400422, -7.86416665906404],
              [-38.68083333279202, -7.86416665906404],
              [-38.68083333279202, -7.863333325672386],
              [-38.679999998501103, -7.863333325672386],
              [-38.679999998501103, -7.860833326396858],
              [-38.679166666908088, -7.860833326396858],
              [-38.679166666908088, -7.859999993005204],
              [-38.679999999400422, -7.859999993005204],
              [-38.679999998501103, -7.859166658714287],
              [-38.679166666008769, -7.859166658714287],
              [-38.679166667807408, -7.854999992655507],
              [-38.678333333516491, -7.854999992655507],
              [-38.678333331717852, -7.852499993379922],
              [-38.677499999225518, -7.852499993379922],
              [-38.677500001024157, -7.850833325697351],
              [-38.67666666583392, -7.850833327496048],
              [-38.67666666673324, -7.849999993205074],
              [-38.675833331542947, -7.849999993205074],
              [-38.675833331542947, -7.848333326421823],
              [-38.674999999949989, -7.848333326421823],
              [-38.674999999949989, -7.847499993030226],
              [-38.674166665659072, -7.847499993030226],
              [-38.674166665659072, -7.846666658739252],
              [-38.673333333166738, -7.846666658739252],
              [-38.673333334066058, -7.844166659463724],
              [-38.672499998875821, -7.844166659463724],
              [-38.672499998875821, -7.84333332607207],
              [-38.67083333299189, -7.84333332607207],
              [-38.67083333209257, -7.842499992680473],
              [-38.669166668007279, -7.842499992680473],
              [-38.669166667107959, -7.83749999233072],
              [-38.669999999600236, -7.83749999323004],
              [-38.669999999600236, -7.834999993055192],
              [-38.669166667107959, -7.834999993055192],
              [-38.669166666208639, -7.832499992880344],
              [-38.668333331917665, -7.832499992880344],
              [-38.668333332816985, -7.83166665948869],
              [-38.669166666208639, -7.83166665948869],
              [-38.669166668007279, -7.828333326821507],
              [-38.670000000499556, -7.828333326821507],
              [-38.669999999600236, -7.82749999342991],
              [-38.671666666383487, -7.82749999342991],
              [-38.671666666383487, -7.828333325922188],
              [-38.672499998875821, -7.828333325022868],
              [-38.672500000674461, -7.82749999432923],
              [-38.673333331368099, -7.82749999342991],
              [-38.673333331368099, -7.828333325922188],
              [-38.674166666558392, -7.828333325922188],
              [-38.674166666558392, -7.829166659313842],
              [-38.677500001024157, -7.829166659313842],
              [-38.677499999225518, -7.828333325922188],
              [-38.679166667807408, -7.828333325922188],
              [-38.679166667807408, -7.82749999342991],
              [-38.681666666183673, -7.82749999432923],
              [-38.681666667082993, -7.826666660038256],
              [-38.682499999575271, -7.826666659138937],
              [-38.682499999575271, -7.82583332574734],
              [-38.683333332966924, -7.82583332574734],
              [-38.684166666358522, -7.82583332574734],
              [-38.684166668157161, -7.824999994154382],
              [-38.684166668157161, -7.824166658964089],
              [-38.685833333141773, -7.824166658064769],
              [-38.685833331343133, -7.823333327371131],
              [-38.68666666653337, -7.823333326471811],
              [-38.68666666653337, -7.824166658064769],
              [-38.688333331517981, -7.824166658964089],
              [-38.68833333421594, -7.823333326471811],
              [-38.690000000999191, -7.823333324673172],
              [-38.689999999200552, -7.822499993979477],
              [-38.692500002073416, -7.822499993080157],
              [-38.692500001174096, -7.823333326471811],
              [-38.693333331867734, -7.823333326471811],
              [-38.693333332767054, -7.824166658964089],
              [-38.69416666795729, -7.824166658964089],
              [-38.694166666158651, -7.82583332574734],
              [-38.698333334016127, -7.82583332574734],
              [-38.698333332217487, -7.826666659138937],
              [-38.702499999175586, -7.826666659138937],
              [-38.702500000074906, -7.82583332574734],
              [-38.704166666858157, -7.82583332574734],
              [-38.704166666858157, -7.824166658964089],
              [-38.705000001149074, -7.824166658964089],
              [-38.704999999350434, -7.823333326471811],
              [-38.705833333641408, -7.823333326471811],
              [-38.705833331842769, -7.824999992355743],
              [-38.707499999525339, -7.824999993255062],
              [-38.707499999525339, -7.824166658964089],
              [-38.711666666483438, -7.824166658964089],
              [-38.711666667382758, -7.823333326471811],
              [-38.712500000774355, -7.823333326471811],
              [-38.712499998975716, -7.82166665968856],
              [-38.71333333236737, -7.821666658789241],
              [-38.71333333146805, -7.819999992905309],
              [-38.714999999150621, -7.819999992905309],
              [-38.71500000004994, -7.820833326296906],
              [-38.716666666833191, -7.820833327196226],
              [-38.717500001124108, -7.820833326296906],
              [-38.717500000224788, -7.821666658789241],
              [-38.718333331817746, -7.821666658789241],
              [-38.718333331817746, -7.822499993080157],
              [-38.719166667008039, -7.822499993080157],
              [-38.719166667907359, -7.824999993255062],
              [-38.721666667182888, -7.824999993255062],
              [-38.721666665384248, -7.82583332574734],
              [-38.722500000574541, -7.825833326646659],
              [-38.722500000574541, -7.826666659138937],
              [-38.723333333066819, -7.826666660038256],
              [-38.723333333966139, -7.82749999342991],
              [-38.724166666458473, -7.82749999432923],
              [-38.724166667357792, -7.828333325922188],
              [-38.72500000074939, -7.828333326821507],
              [-38.72500000074939, -7.829166659313842],
              [-38.725833331443027, -7.829166660213161],
              [-38.725833332342347, -7.83166665948869],
              [-38.726666664834681, -7.83166665948869],
              [-38.726666665734001, -7.832499992880344],
              [-38.7308333326921, -7.832499992880344],
              [-38.73083333359142, -7.833333326271941],
              [-38.731666666083754, -7.833333326271941],
              [-38.731666666083754, -7.834166659663538],
              [-38.732499999475351, -7.834166658764218],
              [-38.732500000374671, -7.835833326446789],
              [-38.733333331967629, -7.835833326446789],
              [-38.733333333766325, -7.83749999323004],
              [-38.734166665359282, -7.83749999323004],
              [-38.734166667157922, -7.839999993404945],
              [-38.73499999875088, -7.839999993404945],
              [-38.73499999875088, -7.846666658739252],
              [-38.734166668057242, -7.846666658739252],
              [-38.734166665359282, -7.847499993030226],
              [-38.73499999875088, -7.847499993030226],
              [-38.734999999650199, -7.849166658914157],
              [-38.735833333041853, -7.849166658914157],
              [-38.735833333041853, -7.849999993205074],
              [-38.73666666643345, -7.849999993205074],
              [-38.736666665534131, -7.850833325697351],
              [-38.737499999825104, -7.850833325697351],
              [-38.737499998925784, -7.851666658189686],
              [-38.738333331418062, -7.851666659089005],
              [-38.738333334116021, -7.852499992480602],
              [-38.739166667507675, -7.852499994279242],
              [-38.739166666608355, -7.853333324972937],
              [-38.739999999100633, -7.853333326771576],
              [-38.739999999100633, -7.854999992655507],
              [-38.741666666783203, -7.854999994454147],
              [-38.741666666783203, -7.854166659263853],
              [-38.7425000001748, -7.854166659263853],
              [-38.742499998376161, -7.853333324972937],
              [-38.746666667132956, -7.853333325872256],
              [-38.746666666233637, -7.852499992480602],
              [-38.747499997826594, -7.852499993379922],
              [-38.747499998725914, -7.851666659089005],
              [-38.749166668207124, -7.851666659089005],
              [-38.749166668207124, -7.847499993030226],
              [-38.748333333016888, -7.847499993030226],
              [-38.748333332117568, -7.846666658739252],
              [-38.749166666408485, -7.846666658739252],
              [-38.749166666408485, -7.845833326246975],
              [-38.749999998900762, -7.845833326246975],
              [-38.749999998900762, -7.844166659463724],
              [-38.750833334091055, -7.844166659463724],
              [-38.750833333191736, -7.84333332607207],
              [-38.751666665684013, -7.84333332697139],
              [-38.751666665684013, -7.842499992680473],
              [-38.752499999075667, -7.842499992680473],
              [-38.752499999075667, -7.841666659288876],
              [-38.753333333366584, -7.841666658389499],
              [-38.753333332467264, -7.840833326796542],
              [-38.754166668556877, -7.840833325897222],
              [-38.754166666758238, -7.839166659113971],
              [-38.755000000149835, -7.839166659113971],
              [-38.755000000149835, -7.838333327521013],
              [-38.755833332642169, -7.838333325722374],
              [-38.755833332642169, -7.834999993055192],
              [-38.756666667832405, -7.834999993055192],
              [-38.756666666933086, -7.834166658764218],
              [-38.757499999425363, -7.834166658764218],
              [-38.757499999425363, -7.833333326271941],
              [-38.758333333716337, -7.83333332717126],
              [-38.758333331917697, -7.832499992880344],
              [-38.759166667107934, -7.832499992880344],
              [-38.759166667107934, -7.83166665948869],
              [-38.760000000499588, -7.83166665769005],
              [-38.759999998700948, -7.829999992705439],
              [-38.760833332092545, -7.829999992705439],
              [-38.760833332991865, -7.829166659313842],
              [-38.762499999775116, -7.829166660213161],
              [-38.762499998875796, -7.82749999342991],
              [-38.76333333316677, -7.82749999342991],
              [-38.76333333406609, -7.826666659138937],
              [-38.764166665659047, -7.826666659138937],
              [-38.764166664759728, -7.82583332574734],
              [-38.765000000849341, -7.82583332574734],
              [-38.764999999950021, -7.824999993255062],
              [-38.764166666558367, -7.824999993255062],
              [-38.764166665659047, -7.822499993080157],
              [-38.767499999225549, -7.822499993080157],
              [-38.767500000124869, -7.819999992905309],
              [-38.768333331717827, -7.819999992905309],
              [-38.768333334415786, -7.818333327021378],
              [-38.769999998501078, -7.818333326122058],
              [-38.769999998501078, -7.817499991831085],
              [-38.770833332792051, -7.817499992730461],
              [-38.770833332792051, -7.816666658439487],
              [-38.771666667082968, -7.816666659338807],
              [-38.771666666183648, -7.81583332594721],
              [-38.770833332792051, -7.81583332594721],
              [-38.770833332792051, -7.814999993454876],
              [-38.769999999400397, -7.814999993454876],
              [-38.770000001199037, -7.81583332594721],
              [-38.768333333516466, -7.81583332594721],
              [-38.768333332617146, -7.814166660063279],
              [-38.767500001024189, -7.814166659163959],
              [-38.767500001024189, -7.813333326671625],
              [-38.766666666733215, -7.813333325772305],
              [-38.766666665833895, -7.812499994179348],
              [-38.765833332442298, -7.812499993280028],
              [-38.765833332442298, -7.808333325422609],
              [-38.766666666733215, -7.808333327221249],
              [-38.766666666733215, -7.804166659363773],
              [-38.765833332442298, -7.804166660263093],
              [-38.765833331542979, -7.803333326871496],
              [-38.764999999050644, -7.803333326871496],
              [-38.764999999050644, -7.801666659188925],
              [-38.76333333406609, -7.801666660088244],
              [-38.76333333406609, -7.800833325797328],
              [-38.762499998875796, -7.800833326696647],
              [-38.762500000674436, -7.799999993304993],
              [-38.761666666383519, -7.799999994204313],
              [-38.761666666383519, -7.799166659014077],
              [-38.760833332092545, -7.799166659913396],
              [-38.760833333891185, -7.795833326346894],
              [-38.761666666383519, -7.795833326346894],
              [-38.761666666383519, -7.79499999295524],
              [-38.760833333891185, -7.79499999295524],
              [-38.760833332092545, -7.793333326172046],
              [-38.761666666383519, -7.793333326172046],
              [-38.761666666383519, -7.789166658314571],
              [-38.762499999775116, -7.78916665921389],
              [-38.762500000674436, -7.788333325822293],
              [-38.766666667632535, -7.788333325822293],
              [-38.766666665833895, -7.786666659039042],
              [-38.767500001923509, -7.786666659039042],
              [-38.767500001024189, -7.785833325647388],
              [-38.769999998501078, -7.785833325647388],
              [-38.769999998501078, -7.784999993155111],
              [-38.773333332966899, -7.784999993155111],
              [-38.773333332966899, -7.785833325647388],
              [-38.774166668157193, -7.785833325647388],
              [-38.774166668157193, -7.784999993155111],
              [-38.77499999975015, -7.784999992255791],
              [-38.774999998850831, -7.784166658864194],
              [-38.77333333206758, -7.784166658864194],
              [-38.77333333206758, -7.778333326022107],
              [-38.771666666183648, -7.778333325122787],
              [-38.771666667082968, -7.775833325847259],
              [-38.770833332792051, -7.775833325847259],
              [-38.770833333691371, -7.774166659064008],
              [-38.769999998501078, -7.774166659064008],
              [-38.770000001199037, -7.77166665888916],
              [-38.770833333691371, -7.77166665888916],
              [-38.770833333691371, -7.770833326396826],
              [-38.769999999400397, -7.770833326396826],
              [-38.770000001199037, -7.764999992655476],
              [-38.76916666780744, -7.764999992655476],
              [-38.76916666690812, -7.762499994279267],
              [-38.768333333516466, -7.762499993379947],
              [-38.768333332617146, -7.760833325697376],
              [-38.767500000124869, -7.760833327496016],
              [-38.767500001024189, -7.759999993205099],
              [-38.766666664934576, -7.759999993205099],
              [-38.766666665833895, -7.759166658914125],
              [-38.767500001024189, -7.759166658914125],
              [-38.767500000124869, -7.755833326246943],
              [-38.766666666733215, -7.755833326246943],
              [-38.766666666733215, -7.754166659463692],
              [-38.765833331542979, -7.754166659463692],
              [-38.765833332442298, -7.753333326072095],
              [-38.764999999050644, -7.753333326072095],
              [-38.764999999950021, -7.752499992680498],
              [-38.764166665659047, -7.752499992680498],
              [-38.764166667457687, -7.751666658389524],
              [-38.760833333891185, -7.751666659288844],
              [-38.760833332991865, -7.750833325897247],
              [-38.759166668007254, -7.750833326796567],
              [-38.759166666208614, -7.749999993404913],
              [-38.754166667657557, -7.749999993404913],
              [-38.754166665858918, -7.750833324997927],
              [-38.753333332467264, -7.750833325897247],
              [-38.753333332467264, -7.752499994479138],
              [-38.752499999075667, -7.752499992680498],
              [-38.752499999974987, -7.751666660188164],
              [-38.751666666583333, -7.751666659288844],
              [-38.751666667482652, -7.752499992680498],
              [-38.749166665509165, -7.752499992680498],
              [-38.749166668207124, -7.751666659288844],
              [-38.748333332117568, -7.751666659288844],
              [-38.748333331218191, -7.750833324997927],
              [-38.745833332841983, -7.750833325897247],
              [-38.745833333741302, -7.748333324823022],
              [-38.744999999450386, -7.748333324823022],
              [-38.745000000349705, -7.744999993055217],
              [-38.744166666058732, -7.744999993055217],
              [-38.744166666058732, -7.744166658764243],
              [-38.743333331767815, -7.744166658764243],
              [-38.743333331767815, -7.743333326271966],
              [-38.741666666783203, -7.743333326271966],
              [-38.741666665883884, -7.742499992880312],
              [-38.74083333249223, -7.742499992880312],
              [-38.74083333159291, -7.740833326097061],
              [-38.74, -7.740833326097061],
              [-38.739999999100633, -7.739999992705464],
              [-38.739166666608355, -7.739999992705464],
              [-38.739166665709035, -7.73916665931381],
              [-38.737499998925784, -7.73916665931381],
              [-38.737500000724424, -7.738333325922213],
              [-38.732499999475351, -7.738333325922213],
              [-38.732499998576031, -7.737499993429878],
              [-38.73083333359142, -7.737499993429878],
              [-38.7308333326921, -7.736666659138962],
              [-38.730000000199823, -7.736666659138962],
              [-38.730000001998462, -7.735833325747365],
              [-38.729166666808169, -7.735833325747365],
              [-38.729166665908849, -7.73499999325503],
              [-38.728333332517252, -7.73499999325503],
              [-38.728333330718613, -7.734166658964114],
              [-38.726666666633321, -7.734166658964114],
              [-38.726666666633321, -7.733333326471779],
              [-38.723333332167499, -7.73333332467314],
              [-38.723333332167499, -7.732499992180863],
              [-38.721666665384248, -7.732499993080182],
              [-38.721666667182888, -7.731666658789209],
              [-38.720833331093331, -7.731666658789209],
              [-38.720833332891971, -7.729999992905277],
              [-38.719999999500317, -7.729999992005958],
              [-38.719999998600997, -7.72916665951368],
              [-38.718333332717066, -7.72916665951368],
              [-38.718333331817746, -7.729999992905277],
              [-38.715833333441537, -7.729999993804597],
              [-38.715833333441537, -7.72916665951368],
              [-38.71500000094926, -7.729166660413],
              [-38.71500000004994, -7.728333326122083],
              [-38.714166666658286, -7.728333327021403],
              [-38.714166666658286, -7.727499994529069],
              [-38.71333333146805, -7.727499992730429],
              [-38.71333333146805, -7.726666659338832],
              [-38.712499999875035, -7.726666659338832],
              [-38.712499998076396, -7.725833325947178],
              [-38.710833333091784, -7.725833325947178],
              [-38.710833332192465, -7.724999993454901],
              [-38.708333332017617, -7.724999993454901],
              [-38.708333333816256, -7.724166658264608],
              [-38.706666667033005, -7.724166659163927],
              [-38.706666667033005, -7.724999994354221],
              [-38.702500000074906, -7.724999993454901],
              [-38.701666666683252, -7.724999993454901],
              [-38.701666665783932, -7.725833326846498],
              [-38.700833331493016, -7.725833325947178],
              [-38.700833331493016, -7.727499992730429],
              [-38.697499999725153, -7.727499992730429],
              [-38.697499998825833, -7.728333326122083],
              [-38.696666665434236, -7.728333326122083],
              [-38.696666665434236, -7.729999992905277],
              [-38.697500000624473, -7.729999993804597],
              [-38.697500000624473, -7.730833326296931],
              [-38.696666668132195, -7.730833326296931],
              [-38.696666667232876, -7.731666658789209],
              [-38.694999999550305, -7.731666658789209],
              [-38.694999999550305, -7.732499992180863],
              [-38.694166666158651, -7.732499993080182],
              [-38.694166666158651, -7.735833325747365],
              [-38.693333331867734, -7.735833325747365],
              [-38.693333331867734, -7.738333326821532],
              [-38.69249999847608, -7.738333325922213],
              [-38.692500001174096, -7.73916665931381],
              [-38.691666665983803, -7.73916665931381],
              [-38.691666666883123, -7.740833326097061],
              [-38.690833332592206, -7.740833326097061],
              [-38.690833331692886, -7.742499992880312],
              [-38.689999999200552, -7.742499992880312],
              [-38.690000000099872, -7.744166658764243],
              [-38.689166667607594, -7.744166658764243],
              [-38.689166665808955, -7.744999993055217],
              [-38.68666666653337, -7.74499999215584],
              [-38.68666666653337, -7.745833324648174],
              [-38.684166665459202, -7.745833326446814],
              [-38.684166665459202, -7.746666658039771],
              [-38.68083333279202, -7.746666658939091],
              [-38.680833333691339, -7.747499993230065],
              [-38.679166666908088, -7.747499993230065],
              [-38.679166666908088, -7.748333325722342],
              [-38.678333333516491, -7.748333325722342],
              [-38.678333334415811, -7.749166659113996],
              [-38.677500001024157, -7.749166659113996],
              [-38.677500001024157, -7.750833325897247],
              [-38.67666666583392, -7.750833325897247],
              [-38.67666666763256, -7.751666660188164],
              [-38.675833332442323, -7.751666658389524],
              [-38.675833332442323, -7.752499992680498],
              [-38.674999999050669, -7.752499992680498],
              [-38.674999999050669, -7.755833326246943],
              [-38.674166666558392, -7.755833326246943],
              [-38.674166666558392, -7.756666659638597],
              [-38.673333334066058, -7.756666658739277],
              [-38.673333331368099, -7.757499992130874],
              [-38.672500000674461, -7.757499993030194],
              [-38.672499998875821, -7.758333326421848],
              [-38.671666666383487, -7.758333326421848],
              [-38.671666666383487, -7.759166658914125],
              [-38.67083333209257, -7.759166658914125],
              [-38.67083333389121, -7.759999993205099],
              [-38.669999999600236, -7.759999993205099],
              [-38.670000000499556, -7.760833325697376],
              [-38.669166667107959, -7.760833325697376],
              [-38.669166666208639, -7.762499992480627],
              [-38.668333331917665, -7.762499993379947],
              [-38.668333331917665, -7.763333325872225],
              [-38.667499998526068, -7.763333325872225],
              [-38.667500001224028, -7.764166659263879],
              [-38.665000001049179, -7.764166659263879],
              [-38.665000001049179, -7.764999992655476],
              [-38.664166666758206, -7.764999994454115],
              [-38.664166667657526, -7.76583332604713],
              [-38.663333333366609, -7.76583332604713],
              [-38.663333332467289, -7.768333326221978],
              [-38.662499999075635, -7.768333326221978],
              [-38.662499999075635, -7.769166658714255],
              [-38.65916666550919, -7.769166658714255],
              [-38.65916666640851, -7.769999993005229],
              [-38.658333333916175, -7.769999993005229],
              [-38.658333332117536, -7.770833327296145],
              [-38.656666668032244, -7.770833326396826],
              [-38.656666667132924, -7.77166665888916],
              [-38.655833331043368, -7.77166665888916],
              [-38.655833333741327, -7.772499993180077],
              [-38.653333333566422, -7.772499993180077],
              [-38.653333331767783, -7.773333325672411],
              [-38.652500001074145, -7.773333325672411],
              [-38.652499998376186, -7.776666659238913],
              [-38.651666666783228, -7.776666659238913],
              [-38.651666667682548, -7.777499994429149],
              [-38.650833333391574, -7.77749999352983],
              [-38.650833333391574, -7.778333325122787],
              [-38.650000000899297, -7.778333326022107],
              [-38.65, -7.779166659413761],
              [-38.649166667507643, -7.779166659413761],
              [-38.649166665709004, -7.774999993354982],
              [-38.648333331418087, -7.774999993354982],
              [-38.648333333216726, -7.774166659064008],
              [-38.647500000724392, -7.774166659064008],
              [-38.647500000724392, -7.773333325672411],
              [-38.646666666433475, -7.773333325672411],
              [-38.646666668232115, -7.772499993180077],
              [-38.644999999650224, -7.772499994079396],
              [-38.644999998750905, -7.773333324773091],
              [-38.644166668956586, -7.773333325672411],
              [-38.644166665359251, -7.772499992280757],
              [-38.641666666083722, -7.772499993180077],
              [-38.641666666083722, -7.77166665888916],
              [-38.639999998401152, -7.77166665888916],
              [-38.639999998401152, -7.769999993005229],
              [-38.63833333251722, -7.769999993904548],
              [-38.638333330718581, -7.769166658714255],
              [-38.636666667532666, -7.76916666500955],
              [-38.633333332167524, -7.769166658714255],
              [-38.633333333066844, -7.769999993904548],
              [-38.63249999877587, -7.769999992105909],
              [-38.63250000057451, -7.769166658714255],
              [-38.631666667182913, -7.769166658714255],
              [-38.631666665384273, -7.768333326221978],
              [-38.630833331992619, -7.768333326221978],
              [-38.630833332891939, -7.7674999937297],
              [-38.627499998426117, -7.767499992830381],
              [-38.627499997526797, -7.768333327121297],
              [-38.623333334165977, -7.768333326221978],
              [-38.623333332367338, -7.769166658714255],
              [-38.62249999987506, -7.769166657814935],
              [-38.62250000077438, -7.769999993005229],
              [-38.621666666483407, -7.769999993005229],
              [-38.621666666483407, -7.770833326396826],
              [-38.619999998800836, -7.770833327296145],
              [-38.619999998800836, -7.769999993005229],
              [-38.615833332742056, -7.769999993005229],
              [-38.615833332742056, -7.770833326396826],
              [-38.614999999350459, -7.770833325497506],
              [-38.614999999350459, -7.77166665888916],
              [-38.613333331667889, -7.77166665888916],
              [-38.613333333466528, -7.772499993180077],
              [-38.611666665783957, -7.772499993180077],
              [-38.611666665783957, -7.77166665888916],
              [-38.610833334191, -7.77166665888916],
              [-38.610833334191, -7.770833327296145],
              [-38.605833333841247, -7.770833325497506],
              [-38.605833333841247, -7.769999993005229],
              [-38.599166666708243, -7.769999993005229],
              [-38.599166666708243, -7.769166658714255],
              [-38.598333334215965, -7.769166658714255],
              [-38.598333335115285, -7.768333326221978],
              [-38.597499999025672, -7.768333326221978],
              [-38.597500000824311, -7.76583332604713],
              [-38.599166666708243, -7.76583332604713],
              [-38.599166665808923, -7.764166659263879],
              [-38.598333331518006, -7.764166660163198],
              [-38.598333331518006, -7.763333325872225],
              [-38.597499999025672, -7.763333325872225],
              [-38.597499999924992, -7.761666659088974],
              [-38.596666666533395, -7.761666659088974],
              [-38.596666665634075, -7.760833325697376],
              [-38.595000000649463, -7.760833325697376],
              [-38.594999999750144, -7.759999993205099],
              [-38.594166666358547, -7.759999993205099],
              [-38.594166665459227, -7.759166658914125],
              [-38.593333331168253, -7.759166659813445],
              [-38.593333333866212, -7.757499993030194],
              [-38.593333333318732, -7.754456146446804],
              [-38.589957431999899, -7.755681464999955],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '114',
        OBJECTID: 155.0,
        Nome_area: 'Pajeú',
        COD_area: 'CA161',
        Import_bio: 'Alta',
        Prior_acao: 'Muito Alta',
        Acao_princ: 'Criação de UC de Proteção Integral',
        Area_ha: 88073.1914,
        Shape_Leng: 4.14167,
        Shape_Area: 0.07223,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.113333331767819, -8.212499992480616],
              [-38.113333332667139, -8.211666659089019],
              [-38.114166666958056, -8.211666659089019],
              [-38.114166668756695, -8.210833325697365],
              [-38.115833331942667, -8.210833325697365],
              [-38.115833333741307, -8.205833326246989],
              [-38.115000002148349, -8.205833325347612],
              [-38.11499999945039, -8.204999993754654],
              [-38.114166666058736, -8.204999992855335],
              [-38.114166666058736, -8.201666658389513],
              [-38.113333331767819, -8.201666659288833],
              [-38.113333331767819, -8.200833324997916],
              [-38.112500001074125, -8.200833325897236],
              [-38.112500000174805, -8.199999993404901],
              [-38.111666666783208, -8.199999993404901],
              [-38.111666665883888, -8.199166659113985],
              [-38.110833333391554, -8.199166659113985],
              [-38.110833332492234, -8.197499993230053],
              [-38.109999999100637, -8.197499993230053],
              [-38.11, -8.194166658764232],
              [-38.10916666570904, -8.194166658764232],
              [-38.10916666660836, -8.190833326097049],
              [-38.108333334116026, -8.190833326097049],
              [-38.108333331418066, -8.188333325922201],
              [-38.107499999825109, -8.188333325022882],
              [-38.107499998925789, -8.18666665913895],
              [-38.107499998925789, -8.185833325747353],
              [-38.106666666433455, -8.185833325747353],
              [-38.106666666433455, -8.184999993255019],
              [-38.104999998750884, -8.184999993255019],
              [-38.104999999650204, -8.183333326471768],
              [-38.104166668057246, -8.183333327371088],
              [-38.104166668057246, -8.181666658789197],
              [-38.102500000374675, -8.181666658789197],
              [-38.102499998576036, -8.18083332629692],
              [-38.101666666083702, -8.18083332629692],
              [-38.101666667882398, -8.179999992905323],
              [-38.100833332692105, -8.179999992905323],
              [-38.100833332692105, -8.179166659513669],
              [-38.098333333416576, -8.179166659513669],
              [-38.098333331617937, -8.177499992730418],
              [-38.095833331443032, -8.177499991831098],
              [-38.095833334140991, -8.176666660238141],
              [-38.094999998950755, -8.176666658439501],
              [-38.094999998950755, -8.175833325947167],
              [-38.094166665559101, -8.175833325047847],
              [-38.09416666645842, -8.173333325772319],
              [-38.093333333966143, -8.173333325772319],
              [-38.093333333966143, -8.172499993280042],
              [-38.092500000574546, -8.172499993280042],
              [-38.092499998775907, -8.171666658989068],
              [-38.091666668082212, -8.171666658989068],
              [-38.091666665384253, -8.170833326496791],
              [-38.090833332891975, -8.170833326496791],
              [-38.090833332891975, -8.169999993105137],
              [-38.089999999500321, -8.169999993105137],
              [-38.089999998601002, -8.167499992930288],
              [-38.084999999150625, -8.167499992030969],
              [-38.085000000949265, -8.166666660437954],
              [-38.084166665758971, -8.166666658639315],
              [-38.084166665758971, -8.165833326147037],
              [-38.081666665584123, -8.165833326147037],
              [-38.081666667382763, -8.164999991856121],
              [-38.080833332192469, -8.16499999275544],
              [-38.080833334890428, -8.164166659363786],
              [-38.079999998800872, -8.164166659363786],
              [-38.079999999700192, -8.163333325972189],
              [-38.079166665409218, -8.163333325972189],
              [-38.079166667207858, -8.162499991681216],
              [-38.077499998625967, -8.162499993479855],
              [-38.077499998625967, -8.161666659188938],
              [-38.07666666703301, -8.161666659188938],
              [-38.07666666703301, -8.159166659014033],
              [-38.075833332742093, -8.159166659014033],
              [-38.075833332742093, -8.158333327421076],
              [-38.075833332742093, -8.157499993130159],
              [-38.075000001149078, -8.157499994029479],
              [-38.074999998451119, -8.156666658839185],
              [-38.074166666858162, -8.156666658839185],
              [-38.074166667757481, -8.155833326346908],
              [-38.071666664884617, -8.155833326346908],
              [-38.071666667582576, -8.154999992955254],
              [-38.07083333239234, -8.154999992955254],
              [-38.070833334190979, -8.154166658664337],
              [-38.069999999900006, -8.154166658664337],
              [-38.069999999900006, -8.152499992780406],
              [-38.069166666508409, -8.152499992780406],
              [-38.069166665609089, -8.151666659388752],
              [-38.068333332217492, -8.151666659388752],
              [-38.068333332217492, -8.150833325997155],
              [-38.067499998825838, -8.150833325997155],
              [-38.067500000624477, -8.149166659213904],
              [-38.0666666681322, -8.149166659213904],
              [-38.06666666723288, -8.148333325822307],
              [-38.064999999550309, -8.148333325822307],
              [-38.065000000449629, -8.146666659039056],
              [-38.064166666158656, -8.146666659039056],
              [-38.064166666158656, -8.145833324748082],
              [-38.06083333259221, -8.145833325647402],
              [-38.06083333259221, -8.144999993155125],
              [-38.059166665808959, -8.144999993155125],
              [-38.059166668506919, -8.145833325647402],
              [-38.056666666533374, -8.145833325647402],
              [-38.056666669231333, -8.144999993155125],
              [-38.053333332966872, -8.144999993155125],
              [-38.053333332067552, -8.144166658864151],
              [-38.054166665459206, -8.144166657964831],
              [-38.054166665459206, -8.14249999298022],
              [-38.054999998850803, -8.14249999298022],
              [-38.054999999750123, -8.140833326197026],
              [-38.055833333141777, -8.140833326197026],
              [-38.055833333141777, -8.139166659413775],
              [-38.056666666533374, -8.139166659413775],
              [-38.056666665634054, -8.138333326022121],
              [-38.057499999925028, -8.138333326022121],
              [-38.057499999925028, -8.137499994429163],
              [-38.058333332417305, -8.137499993529843],
              [-38.058333332417305, -8.13666665923887],
              [-38.061666665983807, -8.13666665923887],
              [-38.061666665983807, -8.135833325847273],
              [-38.062499998476085, -8.135833324947953],
              [-38.062499998476085, -8.134999993354938],
              [-38.063333332767058, -8.134999993354938],
              [-38.063333331867739, -8.134166659064022],
              [-38.064166666158656, -8.134166659064022],
              [-38.064166667057975, -8.133333324773048],
              [-38.064999999550309, -8.133333325672368],
              [-38.065000000449629, -8.131666658889174],
              [-38.065833332941907, -8.131666657989854],
              [-38.065833332042587, -8.129999993904562],
              [-38.0666666681322, -8.129999993005242],
              [-38.0666666681322, -8.129166658714269],
              [-38.068333330418795, -8.129166658714269],
              [-38.068333334016131, -8.128333326221991],
              [-38.069166664709769, -8.128333326221991],
              [-38.069166667407728, -8.127499992830337],
              [-38.069999999000686, -8.127499992830337],
              [-38.069999999000686, -8.126666657640101],
              [-38.07083333239234, -8.12666666033806],
              [-38.07083333149302, -8.125833326047086],
              [-38.071666667582576, -8.125833326047086],
              [-38.071666666683257, -8.122499994279281],
              [-38.072500000074911, -8.122499993379961],
              [-38.07250000097423, -8.12083332479807],
              [-38.073333334365827, -8.12083332749603],
              [-38.073333333466508, -8.119999993205056],
              [-38.072499999175591, -8.119999993205056],
              [-38.072500000074911, -8.117499993030208],
              [-38.073333331667868, -8.117499992130888],
              [-38.073333331667868, -8.116666658739291],
              [-38.074166666858162, -8.116666658739291],
              [-38.074166665059522, -8.115833326246957],
              [-38.07666666613369, -8.115833326246957],
              [-38.07666666613369, -8.11499999285536],
              [-38.075833332742093, -8.11499999285536],
              [-38.075833331842773, -8.114166659463706],
              [-38.074999999350439, -8.114166659463706],
              [-38.074999999350439, -8.113333326072109],
              [-38.070000000799382, -8.113333326072109],
              [-38.069999999900006, -8.112499992680455],
              [-38.069166665609089, -8.112499992680455],
              [-38.069166667407728, -8.111666659288858],
              [-38.068333333116811, -8.111666659288858],
              [-38.068333332217492, -8.110833325897204],
              [-38.067499999725158, -8.110833325897204],
              [-38.067500000624477, -8.109999993404926],
              [-38.06666666723288, -8.109999993404926],
              [-38.06666666723288, -8.109166659113953],
              [-38.065833332941907, -8.109166659113953],
              [-38.065833332042587, -8.109999993404926],
              [-38.063333333666378, -8.109999993404926],
              [-38.063333332767058, -8.109166659113953],
              [-38.062499999375405, -8.109166659113953],
              [-38.062499999375405, -8.108333324823036],
              [-38.057499999925028, -8.108333325722356],
              [-38.057500000824348, -8.107499993230078],
              [-38.056666667432694, -8.107499993230078],
              [-38.056666666533374, -8.108333325722356],
              [-38.055833334041097, -8.108333325722356],
              [-38.055833333141777, -8.107499993230078],
              [-38.051666667982317, -8.107499993230078],
              [-38.051666666183678, -8.108333325722356],
              [-38.050833332792024, -8.108333327520995],
              [-38.050833331892704, -8.109999992505607],
              [-38.050000001199066, -8.109999993404926],
              [-38.049999999400427, -8.111666659288858],
              [-38.049166667807413, -8.111666659288858],
              [-38.049166666908093, -8.112499992680455],
              [-38.048333330818537, -8.112499992680455],
              [-38.048333333516496, -8.113333326072109],
              [-38.047500000124842, -8.113333326072109],
              [-38.047499999225522, -8.114166660363026],
              [-38.046666665833925, -8.114166657665066],
              [-38.046666666733245, -8.118333326421805],
              [-38.045833333341591, -8.118333326421805],
              [-38.045833332442271, -8.119166658914139],
              [-38.041666666383492, -8.119166658914139],
              [-38.041666668182131, -8.119999993205056],
              [-38.039166666208644, -8.119999993205056],
              [-38.039166667107963, -8.119166658914139],
              [-38.039166667107963, -8.116666658739291],
              [-38.039999999600241, -8.116666658739291],
              [-38.039999999600241, -8.11499999285536],
              [-38.040833332092575, -8.11499999285536],
              [-38.040833332991895, -8.113333326072109],
              [-38.041666665484172, -8.113333326072109],
              [-38.041666665484172, -8.111666659288858],
              [-38.042500001573785, -8.111666659288858],
              [-38.042499999775146, -8.109166660013329],
              [-38.043333334066062, -8.109166659113953],
              [-38.043333333166743, -8.108333325722356],
              [-38.044166666558397, -8.108333324823036],
              [-38.044166665659077, -8.102499992880325],
              [-38.044999999949994, -8.102499992880325],
              [-38.044999999050674, -8.101666659488671],
              [-38.045833332442271, -8.101666659488671],
              [-38.045833333341591, -8.099999994504117],
              [-38.046666666733245, -8.099999992705477],
              [-38.046666665833925, -8.098333325922226],
              [-38.047500000124842, -8.098333325922226],
              [-38.047499999225522, -8.097499993429892],
              [-38.048333334415815, -8.097499993429892],
              [-38.048333333516496, -8.096666659138975],
              [-38.049166666908093, -8.096666659138975],
              [-38.049166666008773, -8.09416665896407],
              [-38.049999999400427, -8.09416665896407],
              [-38.049999999400427, -8.092499993080196],
              [-38.050833332792024, -8.092499993080196],
              [-38.050833332792024, -8.08833332612204],
              [-38.051666667082998, -8.08833332612204],
              [-38.051666666183678, -8.085833325947192],
              [-38.052499999575275, -8.085833326846512],
              [-38.052499999575275, -8.084999993454915],
              [-38.054166668157166, -8.084999993454915],
              [-38.054166665459206, -8.084166659163941],
              [-38.054999998850803, -8.084166659163941],
              [-38.054999999750123, -8.083333325772344],
              [-38.058333331517986, -8.083333325772344],
              [-38.058333332417305, -8.084166659163941],
              [-38.059166666708279, -8.084166659163941],
              [-38.059166666708279, -8.083333325772344],
              [-38.059999999200556, -8.083333325772344],
              [-38.060000000099876, -8.08249999238069],
              [-38.061666665983807, -8.08249999328001],
              [-38.061666665983807, -8.081666658989093],
              [-38.06666666723288, -8.081666658989093],
              [-38.0666666681322, -8.08249999328001],
              [-38.068333334016131, -8.082499994179329],
              [-38.069166666508409, -8.08249999328001],
              [-38.069166667407728, -8.080833326496759],
              [-38.069999999000686, -8.080833326496759],
              [-38.070000000799382, -8.079999993105162],
              [-38.07083333149302, -8.079999993105162],
              [-38.07083333239234, -8.078333326321911],
              [-38.071666666683257, -8.078333326321911],
              [-38.071666665783937, -8.075833327046382],
              [-38.072499999175591, -8.075833326147063],
              [-38.072499999175591, -8.074999992755409],
              [-38.073333333466508, -8.074999992755409],
              [-38.073333333466508, -8.074166659363812],
              [-38.074166666858162, -8.074166659363812],
              [-38.074166666858162, -8.073333325972158],
              [-38.074999999350439, -8.073333326871477],
              [-38.075000001149078, -8.072499991681241],
              [-38.074166666858162, -8.07249999347988],
              [-38.074166665958842, -8.071666659188907],
              [-38.073333333466508, -8.071666658289587],
              [-38.073333334365827, -8.070833325797309],
              [-38.07250000097423, -8.070833325797309],
              [-38.072500000074911, -8.069999993304975],
              [-38.071666665783937, -8.069999993304975],
              [-38.071666666683257, -8.069166659014058],
              [-38.070000000799382, -8.069166659014058],
              [-38.069999999000686, -8.068333324723085],
              [-38.069166666508409, -8.068333326521781],
              [-38.069166667407728, -8.067499993130127],
              [-38.068333332217492, -8.067499993130127],
              [-38.068333332217492, -8.06666665883921],
              [-38.0666666681322, -8.06666665883921],
              [-38.066666665434241, -8.065833326346876],
              [-38.064999999550309, -8.065833326346876],
              [-38.065000000449629, -8.064166658664305],
              [-38.064166666158656, -8.064166658664305],
              [-38.064166667057975, -8.063333325272708],
              [-38.063333331867739, -8.063333326172028],
              [-38.063333332767058, -8.062499992780374],
              [-38.062499998476085, -8.062499992780374],
              [-38.062500001174044, -8.063333326172028],
              [-38.061666666883127, -8.063333326172028],
              [-38.061666665983807, -8.064999992955279],
              [-38.06083333259221, -8.064999992955279],
              [-38.06083333259221, -8.067499993130127],
              [-38.060000000999196, -8.067499993130127],
              [-38.059999999200556, -8.068333326521781],
              [-38.059166665808959, -8.068333326521781],
              [-38.059166665808959, -8.069166659913378],
              [-38.058333331517986, -8.069166659014058],
              [-38.058333332417305, -8.069999993304975],
              [-38.057499999925028, -8.069999993304975],
              [-38.057499999925028, -8.070833325797309],
              [-38.055833331343138, -8.070833325797309],
              [-38.055833333141777, -8.07249999347988],
              [-38.054999998850803, -8.07249999347988],
              [-38.055000000649443, -8.073333325972158],
              [-38.054166666358526, -8.073333325072838],
              [-38.054166668157166, -8.075833327046382],
              [-38.053333332067552, -8.075833326147063],
              [-38.053333332966872, -8.07666665774002],
              [-38.050000001199066, -8.076666660437979],
              [-38.049999998501107, -8.075833326147063],
              [-38.046666666733245, -8.075833326147063],
              [-38.046666665833925, -8.07666665863934],
              [-38.045000000849313, -8.07666665863934],
              [-38.044999999050674, -8.075833326147063],
              [-38.044166667457716, -8.075833327046382],
              [-38.044166665659077, -8.074999992755409],
              [-38.041666668182131, -8.074999992755409],
              [-38.041666665484172, -8.074166658464492],
              [-38.040833332092575, -8.074166659363812],
              [-38.039999998700921, -8.074166659363812],
              [-38.04000000049956, -8.073333325972158],
              [-38.037500001224032, -8.073333325972158],
              [-38.037499998526073, -8.074166658464492],
              [-38.036666666033796, -8.074166659363812],
              [-38.036666666933115, -8.074999992755409],
              [-38.03416666675821, -8.074999992755409],
              [-38.034166665858891, -8.075833326147063],
              [-38.032500000874279, -8.075833326147063],
              [-38.032500000874279, -8.07666665863934],
              [-38.030833333191708, -8.07666665863934],
              [-38.030833333191708, -8.075833327046382],
              [-38.029999998900792, -8.075833326147063],
              [-38.029999999800111, -8.074999993654728],
              [-38.029166666408457, -8.074999991856089],
              [-38.029166665509138, -8.069999993304975],
              [-38.029999999800111, -8.069999993304975],
              [-38.029999999800111, -8.064999992955279],
              [-38.030833333191708, -8.064999992955279],
              [-38.030833331393069, -8.063333326172028],
              [-38.031666666583362, -8.063333326172028],
              [-38.031666667482682, -8.062499992780374],
              [-38.032499999974959, -8.062499992780374],
              [-38.03249999907564, -8.061666659388777],
              [-38.033333332467294, -8.061666659388777],
              [-38.033333331567974, -8.059999993504846],
              [-38.03249999907564, -8.059999993504846],
              [-38.032499999974959, -8.059166659213929],
              [-38.031666666583362, -8.059166659213929],
              [-38.031666667482682, -8.058333325822275],
              [-38.030833333191708, -8.058333325822275],
              [-38.030833331393069, -8.057499993329998],
              [-38.029166668207154, -8.057499993329998],
              [-38.029166668207154, -8.056666659039024],
              [-38.028333332117541, -8.056666659039024],
              [-38.028333332117541, -8.057499993329998],
              [-38.027499999625263, -8.057499992430678],
              [-38.027499999625263, -8.058333325822275],
              [-38.025833333741332, -8.058333325822275],
              [-38.025833333741332, -8.057499993329998],
              [-38.024166665159441, -8.057499993329998],
              [-38.024166665159441, -8.058333324922955],
              [-38.023333332667107, -8.058333325822275],
              [-38.023333331767788, -8.057499993329998],
              [-38.021666667682553, -8.057499993329998],
              [-38.021666664984537, -8.056666659039024],
              [-38.020833332492259, -8.056666659039024],
              [-38.020833332492259, -8.057499993329998],
              [-38.019999999100662, -8.057499993329998],
              [-38.02, -8.058333326721595],
              [-38.019166665709008, -8.058333325822275],
              [-38.019166665709008, -8.059166659213929],
              [-38.018333331418091, -8.059166659213929],
              [-38.018333334116051, -8.059999993504846],
              [-38.012499999475381, -8.059999993504846],
              [-38.012499999475381, -8.060833325997123],
              [-38.011666666983047, -8.060833325997123],
              [-38.011666666983047, -8.063333325272708],
              [-38.01083333269213, -8.063333326172028],
              [-38.01083333179281, -8.064166658664305],
              [-38.009166666808198, -8.064166658664305],
              [-38.009166666808198, -8.064999992955279],
              [-38.007500000024947, -8.064999992955279],
              [-38.007499999125628, -8.065833326346876],
              [-38.006666665733974, -8.065833326346876],
              [-38.006666666633294, -8.067499993130127],
              [-38.005833331443057, -8.067499993130127],
              [-38.005833331443057, -8.068333326521781],
              [-38.004999998950723, -8.068333326521781],
              [-38.005000000749419, -8.071666659188907],
              [-38.002499998775875, -8.071666659188907],
              [-38.002500000574514, -8.07249999347988],
              [-38.001666667182917, -8.07249999347988],
              [-38.001666667182917, -8.073333325972158],
              [-38.000833331992624, -8.073333325072838],
              [-38.000833331992624, -8.074999992755409],
              [-37.999166667907332, -8.074999992755409],
              [-37.999166665209373, -8.075833325247743],
              [-37.995833331642871, -8.075833326147063],
              [-37.995833331642871, -8.07666665774002],
              [-37.994999999150593, -8.07666665863934],
              [-37.995000000949233, -8.077499992930257],
              [-37.993333331468023, -8.077499992930257],
              [-37.993333332367342, -8.078333325422591],
              [-37.990833332192494, -8.078333326321911],
              [-37.990833333091814, -8.079166658814188],
              [-37.988333332916909, -8.079166658814188],
              [-37.988333332017589, -8.079999993105162],
              [-37.985833331842741, -8.079999992205842],
              [-37.985833333641381, -8.080833326496759],
              [-37.984999998451144, -8.080833326496759],
              [-37.984999998451144, -8.081666659888413],
              [-37.982499999175559, -8.081666658989093],
              [-37.982500000974198, -8.08249999328001],
              [-37.981666666683282, -8.08249999328001],
              [-37.981666667582601, -8.081666658989093],
              [-37.979999999000711, -8.081666658989093],
              [-37.979999999000711, -8.08249999328001],
              [-37.97833333221746, -8.08249999328001],
              [-37.97833333311678, -8.081666658089773],
              [-37.977499998825863, -8.081666658989093],
              [-37.977499997926543, -8.080833326496759],
              [-37.974999999550278, -8.080833327396078],
              [-37.975000000449597, -8.079999993105162],
              [-37.97416666795732, -8.079999994004481],
              [-37.974166667058, -8.079166658814188],
              [-37.973333331867707, -8.079166658814188],
              [-37.973333331867707, -8.078333326321911],
              [-37.97249999937543, -8.078333326321911],
              [-37.97249999847611, -8.079166658814188],
              [-37.969166666708247, -8.079166658814188],
              [-37.969166665808928, -8.079999994004481],
              [-37.967499999924996, -8.079999993105162],
              [-37.967500000824316, -8.080833326496759],
              [-37.966666665634079, -8.080833326496759],
              [-37.966666666533399, -8.079999993105162],
              [-37.964166666358494, -8.079999993105162],
              [-37.964166666358494, -8.079166658814188],
              [-37.961666667082966, -8.079166658814188],
              [-37.958333332617144, -8.079166657914868],
              [-37.958333331717824, -8.078333326321911],
              [-37.954999999950019, -8.078333326321911],
              [-37.954999999050699, -8.077499992930257],
              [-37.954166667457685, -8.077499992930257],
              [-37.954166666558365, -8.07666665863934],
              [-37.950833333891183, -8.07666665863934],
              [-37.950833332092543, -8.077499992930257],
              [-37.947499999425418, -8.077499992930257],
              [-37.947499999425418, -8.07666665863934],
              [-37.944166666758235, -8.07666665863934],
              [-37.944166666758235, -8.075833326147063],
              [-37.941666665684011, -8.075833325247743],
              [-37.941666665684011, -8.074999992755409],
              [-37.940833331393094, -8.074999992755409],
              [-37.940833334091053, -8.07249999347988],
              [-37.939999999800079, -8.07249999347988],
              [-37.939999999800079, -8.068333326521781],
              [-37.939166665509163, -8.068333326521781],
              [-37.939166665509163, -8.06666665883921],
              [-37.938333333016885, -8.06666665883921],
              [-37.938333333016885, -8.065833326346876],
              [-37.937499998725912, -8.065833326346876],
              [-37.937499999625231, -8.064999992955279],
              [-37.934166666958049, -8.064999993854599],
              [-37.934166666058729, -8.064166660462945],
              [-37.931666665883881, -8.064166657764986],
              [-37.931666667682521, -8.065833327246196],
              [-37.930833333391604, -8.065833326346876],
              [-37.930833332492284, -8.06666665883921],
              [-37.928333333216699, -8.06666665883921],
              [-37.92833333141806, -8.067499993130127],
              [-37.926666668232087, -8.067499993130127],
              [-37.926666665534128, -8.069166659014058],
              [-37.92416666535928, -8.069166659014058],
              [-37.92416666535928, -8.069999993304975],
              [-37.923333333766323, -8.069999993304975],
              [-37.923333332867003, -8.070833325797309],
              [-37.922499998576029, -8.070833325797309],
              [-37.922500000374669, -8.07249999347988],
              [-37.921666667882391, -8.07249999347988],
              [-37.921666666083752, -8.073333326871477],
              [-37.920833333591418, -8.073333325072838],
              [-37.920833333591418, -8.074166659363812],
              [-37.919999998401181, -8.074166659363812],
              [-37.920000000199821, -8.074999992755409],
              [-37.916666665733999, -8.074999992755409],
              [-37.916666666633319, -8.074166659363812],
              [-37.913333332167497, -8.074166659363812],
              [-37.913333332167497, -8.074999992755409],
              [-37.909999999500315, -8.074999992755409],
              [-37.909999998600995, -8.073333325972158],
              [-37.909166667008037, -8.073333325972158],
              [-37.909166667008037, -8.07249999347988],
              [-37.908333330918424, -8.07249999347988],
              [-37.908333331817744, -8.073333325972158],
              [-37.899999998800865, -8.073333325972158],
              [-37.899999998800865, -8.07249999347988],
              [-37.899166667207908, -8.0724999943792],
              [-37.899166667207908, -8.071666659188907],
              [-37.898333333816254, -8.071666659188907],
              [-37.898333331118295, -8.069999993304975],
              [-37.897499997726698, -8.069999993304975],
              [-37.897499998626017, -8.069166659014058],
              [-37.896666666133683, -8.069166659014058],
              [-37.896666667033003, -8.068333326521781],
              [-37.89166666668325, -8.068333326521781],
              [-37.89166666578393, -8.061666659388777],
              [-37.892500000074904, -8.061666659388777],
              [-37.892500000974223, -8.056666659039024],
              [-37.894166667757474, -8.056666659938344],
              [-37.894166667757474, -8.051666658689328],
              [-37.895833333641406, -8.051666658689328],
              [-37.895833331842766, -8.050833325297674],
              [-37.897500000424657, -8.050833326196994],
              [-37.897499998626017, -8.045833325847241],
              [-37.898333332916934, -8.045833325847241],
              [-37.898333332017614, -8.039166658714294],
              [-37.899166667207908, -8.039166658714294],
              [-37.899166665409211, -8.037499992830362],
              [-37.898333332017614, -8.037499991931043],
              [-37.898333332017614, -8.033333325872263],
              [-37.899166668107227, -8.033333325872263],
              [-37.899166665409211, -8.032499993379929],
              [-37.898333334715574, -8.032499993379929],
              [-37.898333332017614, -8.027499993030233],
              [-37.897499999525337, -8.027499993929553],
              [-37.897499999525337, -8.026666658739259],
              [-37.896666667033003, -8.026666658739259],
              [-37.896666667932323, -8.024166659463731],
              [-37.895833332742086, -8.024166659463731],
              [-37.895833334540725, -8.02249999268048],
              [-37.896666667033003, -8.02249999268048],
              [-37.896666667033003, -8.01666665893913],
              [-37.895833333641406, -8.01666665893913],
              [-37.895833331842766, -8.014999993055199],
              [-37.894999998451112, -8.014999993055199],
              [-37.894999998451112, -8.012499992880294],
              [-37.894166665958835, -8.012499992880294],
              [-37.894166666858155, -8.010833326097099],
              [-37.894999999350432, -8.010833326097099],
              [-37.894999999350432, -8.009166659313848],
              [-37.894166666858155, -8.009166659313848],
              [-37.894166665059515, -8.008333326821514],
              [-37.893333331667861, -8.008333326821514],
              [-37.893333331667861, -8.007499993429917],
              [-37.892500000074904, -8.007499993429917],
              [-37.892499999175584, -8.006666659138943],
              [-37.890833332392333, -8.006666659138943],
              [-37.889999999000736, -8.006666659138943],
              [-37.889999999000736, -8.008333325922194],
              [-37.889166666508402, -8.008333326821514],
              [-37.889166666508402, -8.009166658414529],
              [-37.888333334016124, -8.009166659313848],
              [-37.888333333116805, -8.009999992705445],
              [-37.887499998825831, -8.009999992705445],
              [-37.88750000062447, -8.011666659488696],
              [-37.886666667232873, -8.011666659488696],
              [-37.886666668132193, -8.013333326271947],
              [-37.8858333329419, -8.013333326271947],
              [-37.8858333329419, -8.014166658764225],
              [-37.8858333329419, -8.01666665893913],
              [-37.884999998650983, -8.01666665983845],
              [-37.884999998650983, -8.018333325722381],
              [-37.884166667057968, -8.018333325722381],
              [-37.884166667057968, -8.021666659288826],
              [-37.882499998476078, -8.021666659288826],
              [-37.882499998476078, -8.02249999268048],
              [-37.883333333666371, -8.02249999268048],
              [-37.883333332767052, -8.024166657665091],
              [-37.884166666158649, -8.024166660363051],
              [-37.884166667957345, -8.026666658739259],
              [-37.883333332767052, -8.026666658739259],
              [-37.883333331867732, -8.027499993030233],
              [-37.881666665983801, -8.027499993030233],
              [-37.88166666778244, -8.02833332642183],
              [-37.880833331692884, -8.02833332642183],
              [-37.880833331692884, -8.029166658914107],
              [-37.879166665808953, -8.029166658914107],
              [-37.879166666708272, -8.030833325697358],
              [-37.878333331517979, -8.030833325697358],
              [-37.878333332417299, -8.033333325872263],
              [-37.877499999925021, -8.033333325872263],
              [-37.877499999025702, -8.038333326221959],
              [-37.876666665634048, -8.03833332532264],
              [-37.876666665634048, -8.039166658714294],
              [-37.875833331343131, -8.039166658714294],
              [-37.87583333404109, -8.03999999390453],
              [-37.874999998850797, -8.03999999300521],
              [-37.874999999750173, -8.040833326396864],
              [-37.8741666654592, -8.040833326396864],
              [-37.874166666358519, -8.04416665906399],
              [-37.874999999750173, -8.04416665906399],
              [-37.874999999750173, -8.046666660138214],
              [-37.8741666654592, -8.046666659238895],
              [-37.8741666654592, -8.047499991731172],
              [-37.870833331892698, -8.047499994429131],
              [-37.870833331892698, -8.048333326022146],
              [-37.868333332617169, -8.048333326921465],
              [-37.868333332617169, -8.047499993529811],
              [-37.865833333341641, -8.047499993529811],
              [-37.865833331543001, -8.048333326022146],
              [-37.864999999050667, -8.048333326022146],
              [-37.864999999050667, -8.049166659413743],
              [-37.859999998700914, -8.049166659413743],
              [-37.859999999600234, -8.051666658689328],
              [-37.859166667107957, -8.051666658689328],
              [-37.859166666208637, -8.052499993879564],
              [-37.858333333716359, -8.052499992980245],
              [-37.858333331018343, -8.051666658689328],
              [-37.856666666933108, -8.051666658689328],
              [-37.856666666933108, -8.050833327096313],
              [-37.855000001049177, -8.050833326196994],
              [-37.855000001049177, -8.049999992805397],
              [-37.854166667657523, -8.049999992805397],
              [-37.854166667657523, -8.048333326022146],
              [-37.853333333366606, -8.048333326022146],
              [-37.853333331567967, -8.047499993529811],
              [-37.852499999075633, -8.047499993529811],
              [-37.852499999075633, -8.045833325847241],
              [-37.853333332467287, -8.045833325847241],
              [-37.853333331567967, -8.04416665816467],
              [-37.854166665858884, -8.04416665906399],
              [-37.854166666758204, -8.041666657989822],
              [-37.854999998351218, -8.041666658889142],
              [-37.855000000149857, -8.037499992830362],
              [-37.854166666758204, -8.037499992830362],
              [-37.854166665858884, -8.030833325697358],
              [-37.854999998351218, -8.030833325697358],
              [-37.854999998351218, -8.029999993205081],
              [-37.855833333541455, -8.029999994104401],
              [-37.855833331742815, -8.027499992130913],
              [-37.855000000149857, -8.027499993030233],
              [-37.855000001049177, -8.026666657839939],
              [-37.856666666033789, -8.026666658739259],
              [-37.856666666033789, -8.025833326246982],
              [-37.857500001224025, -8.025833326246982],
              [-37.857500001224025, -8.024166657665091],
              [-37.85833333281704, -8.024166659463731],
              [-37.858333333716359, -8.020833326796549],
              [-37.857499998526066, -8.020833324997909],
              [-37.857500001224025, -8.019166659113978],
              [-37.858333331917663, -8.019166659113978],
              [-37.85833333281704, -8.01666665893913],
              [-37.859166667107957, -8.01666665893913],
              [-37.859166667107957, -8.014166658764225],
              [-37.859999999600234, -8.014166658764225],
              [-37.859999999600234, -8.012499992880294],
              [-37.859166667107957, -8.012499992880294],
              [-37.859166667107957, -8.010833326097099],
              [-37.858333331917663, -8.01083332519778],
              [-37.85833333281704, -8.009166659313848],
              [-37.859166667107957, -8.009166659313848],
              [-37.859166668007276, -8.006666659138943],
              [-37.859999998700914, -8.006666659138943],
              [-37.859999998700914, -8.005833326646666],
              [-37.859999998700914, -8.004999993255012],
              [-37.858333331917663, -8.004999994154332],
              [-37.85833333281704, -8.002499993080164],
              [-37.857499999425386, -8.002499993080164],
              [-37.857499999425386, -8.001666658789247],
              [-37.856666666933108, -8.001666658789247],
              [-37.856666666033789, -7.999999992005996],
              [-37.854999998351218, -7.999999992905316],
              [-37.855000001049177, -7.999166659513662],
              [-37.854166665858884, -7.999166659513662],
              [-37.854166665858884, -7.998333326122065],
              [-37.853333331567967, -7.998333326122065],
              [-37.853333331567967, -7.997499992730411],
              [-37.852499999075633, -7.997499992730411],
              [-37.852500000874272, -7.996666659338814],
              [-37.848333332117534, -7.996666658439494],
              [-37.848333333916173, -7.99583332594716],
              [-37.844999999450351, -7.99583332594716],
              [-37.845000000349671, -7.994999993454883],
              [-37.844166666058754, -7.994999993454883],
              [-37.844166666058754, -7.994166659163966],
              [-37.842499998376184, -7.994166659163966],
              [-37.842500000174823, -7.993333325772312],
              [-37.839999999100655, -7.993333325772312],
              [-37.839999999100655, -7.992499994179354],
              [-37.83749999982507, -7.992499993280035],
              [-37.83750000072439, -7.990833324698144],
              [-37.836666668232112, -7.990833326496784],
              [-37.836666668232112, -7.98999999310513],
              [-37.835833333041819, -7.98999999220581],
              [-37.835833332142499, -7.989166658814213],
              [-37.834999999650222, -7.989166657914893],
              [-37.834999997851583, -7.985833326147031],
              [-37.834166667157945, -7.985833326147031],
              [-37.834166668057264, -7.984999992755434],
              [-37.832499998576054, -7.984999992755434],
              [-37.832499998576054, -7.98416665936378],
              [-37.830833331792803, -7.98416665936378],
              [-37.830833333591443, -7.983333326871502],
              [-37.826666667532663, -7.983333328670142],
              [-37.826666665733967, -7.981666659188932],
              [-37.828333330718579, -7.981666659188932],
              [-37.828333333416538, -7.980833325797278],
              [-37.829999998401149, -7.980833324897958],
              [-37.830000000199789, -7.979166659014084],
              [-37.830833331792803, -7.979166659014084],
              [-37.830833331792803, -7.978333326521749],
              [-37.83166666698304, -7.978333326521749],
              [-37.83166666698304, -7.975833326346901],
              [-37.833333331967651, -7.975833325447582],
              [-37.833333331967651, -7.97416665866433],
              [-37.834999998750902, -7.97416665866433],
              [-37.834999999650222, -7.973333326171996],
              [-37.838333333216724, -7.973333326171996],
              [-37.838333334116044, -7.971666658489426],
              [-37.839999999100655, -7.971666659388745],
              [-37.84, -7.970833325997148],
              [-37.841666666783226, -7.970833325997148],
              [-37.841666667682546, -7.971666659388745],
              [-37.843333331767781, -7.971666660288122],
              [-37.843333334465797, -7.972499992780399],
              [-37.844166666058754, -7.972499993679719],
              [-37.844166667857394, -7.971666660288122],
              [-37.845833331942686, -7.971666659388745],
              [-37.845833332842005, -7.970833325097828],
              [-37.848333332117534, -7.970833325997148],
              [-37.848333333016853, -7.969999993504871],
              [-37.849166665509188, -7.969999993504871],
              [-37.849166665509188, -7.970833325997148],
              [-37.849999999800104, -7.970833325997148],
              [-37.849999999800104, -7.971666659388745],
              [-37.850833331393062, -7.971666659388745],
              [-37.850833334091078, -7.970833325097828],
              [-37.852500000874272, -7.970833325997148],
              [-37.852499999075633, -7.969999991706231],
              [-37.854166665858884, -7.969999993504871],
              [-37.854166667657523, -7.969166660113217],
              [-37.857499998526066, -7.969166658314577],
              [-37.857499999425386, -7.969999993504871],
              [-37.862499998875819, -7.969999993504871],
              [-37.862499998875819, -7.969166659213897],
              [-37.863333331368096, -7.969166660113217],
              [-37.863333331368096, -7.969999993504871],
              [-37.86416666655839, -7.969999993504871],
              [-37.86416666745771, -7.9683333258223],
              [-37.868333332617169, -7.9683333258223],
              [-37.868333331717849, -7.969166659213897],
              [-37.871666666183671, -7.969166659213897],
              [-37.871666667082991, -7.969999993504871],
              [-37.873333332067602, -7.969999993504871],
              [-37.873333332966922, -7.969166659213897],
              [-37.875000000649493, -7.969166659213897],
              [-37.874999998850797, -7.96833332672162],
              [-37.877500000824341, -7.9683333258223],
              [-37.877499999025702, -7.967499993329966],
              [-37.879166666708272, -7.967499993329966],
              [-37.879166666708272, -7.966666659039049],
              [-37.880000000999189, -7.966666659039049],
              [-37.880000000099869, -7.967499993329966],
              [-37.880833331692884, -7.967499993329966],
              [-37.880833333491523, -7.9683333258223],
              [-37.881666665983801, -7.9683333258223],
              [-37.881666665983801, -7.969166659213897],
              [-37.884166666158649, -7.969166658314577],
              [-37.884166667057968, -7.96833332672162],
              [-37.884999998650983, -7.9683333258223],
              [-37.885000000449622, -7.965833324748075],
              [-37.884166666158649, -7.965833324748075],
              [-37.884166667957345, -7.964166658864144],
              [-37.882500001174094, -7.964166658864144],
              [-37.882499999375455, -7.96249999298027],
              [-37.88166666688312, -7.96249999298027],
              [-37.88166666688312, -7.958333326022114],
              [-37.880833332592204, -7.958333326022114],
              [-37.880833332592204, -7.957499994429156],
              [-37.87583333404109, -7.957499993529837],
              [-37.87583333404109, -7.955833325847266],
              [-37.8741666654592, -7.955833325847266],
              [-37.874166666358519, -7.954999993354988],
              [-37.873333333866242, -7.954999993354988],
              [-37.873333333866242, -7.954166659064015],
              [-37.869166667807406, -7.954166659064015],
              [-37.869166668706725, -7.953333325672418],
              [-37.868333332617169, -7.953333325672418],
              [-37.868333332617169, -7.952499993180083],
              [-37.865833331543001, -7.952499992280764],
              [-37.865833332442321, -7.950833326396832],
              [-37.864999999050667, -7.950833326396832],
              [-37.865000000849307, -7.949166658714262],
              [-37.86416666655839, -7.949166658714262],
              [-37.86416666745771, -7.948333326221984],
              [-37.863333333166736, -7.948333326221984],
              [-37.85833333281704, -7.948333327121304],
              [-37.85833333281704, -7.949166658714262],
              [-37.856666666933108, -7.949166658714262],
              [-37.856666666933108, -7.948333325322665],
              [-37.854166665858884, -7.948333326221984],
              [-37.854166667657523, -7.94749999283033],
              [-37.851666667482675, -7.947499993729707],
              [-37.851666667482675, -7.946666660338053],
              [-37.849999998900785, -7.946666657640094],
              [-37.849999998001465, -7.945833326946456],
              [-37.848333332117534, -7.945833325147817],
              [-37.848333333916173, -7.944999992655482],
              [-37.847499999625256, -7.944999992655482],
              [-37.847499999625256, -7.943333326771551],
              [-37.846666667132922, -7.943333324972912],
              [-37.846666667132922, -7.939999993205049],
              [-37.84333333356642, -7.939999994104426],
              [-37.843333331767781, -7.939166658914132],
              [-37.83749999892575, -7.939166658914132],
              [-37.83749999892575, -7.938333326421855],
              [-37.836666665534153, -7.938333325522535],
              [-37.836666668232112, -7.937499993030201],
              [-37.835833333041819, -7.937499992130881],
              [-37.835833333041819, -7.936666658739284],
              [-37.834999998750902, -7.936666658739284],
              [-37.834999999650222, -7.93583332624695],
              [-37.834166668057264, -7.93583332714627],
              [-37.832499997676734, -7.93583332624695],
              [-37.832499998576054, -7.936666658739284],
              [-37.83166666608372, -7.936666658739284],
              [-37.8316666651844, -7.937499993030201],
              [-37.830833331792803, -7.937499993030201],
              [-37.830833332692123, -7.938333326421855],
              [-37.830000001998428, -7.938333326421855],
              [-37.830000001099108, -7.94166665908898],
              [-37.828333331617898, -7.9416666599883],
              [-37.828333331617898, -7.942499993379954],
              [-37.826666668431983, -7.942499993379954],
              [-37.826666665733967, -7.944166660163205],
              [-37.82583333234237, -7.944166660163205],
              [-37.82583333144305, -7.946666659438733],
              [-37.824999998950773, -7.946666660338053],
              [-37.825000000749412, -7.950833326396832],
              [-37.824166666458439, -7.950833325497513],
              [-37.824166665559119, -7.951666657989847],
              [-37.82166666718291, -7.951666658889167],
              [-37.82166666808223, -7.950833326396832],
              [-37.820833332891937, -7.950833327296152],
              [-37.820833333791256, -7.951666658889167],
              [-37.820000000399659, -7.951666657989847],
              [-37.820000002198299, -7.952499993180083],
              [-37.819166667008005, -7.952499993180083],
              [-37.819166667008005, -7.954166659064015],
              [-37.818333333616408, -7.954166659064015],
              [-37.818333331817769, -7.954999993354988],
              [-37.817500000224811, -7.954999993354988],
              [-37.817500001124131, -7.955833326746585],
              [-37.815833331642921, -7.955833324947946],
              [-37.815833331642921, -7.956666659238863],
              [-37.814999999150587, -7.956666659238863],
              [-37.815000000049906, -7.957499993529837],
              [-37.811666665584085, -7.957499993529837],
              [-37.811666667382724, -7.958333326022114],
              [-37.807499998625985, -7.958333326022114],
              [-37.807499998625985, -7.959166659413768],
              [-37.806666667932348, -7.959166658514448],
              [-37.806666667932348, -7.960833326197019],
              [-37.805833331842734, -7.960833326197019],
              [-37.805833332742054, -7.958333325122794],
              [-37.804999998451137, -7.958333325122794],
              [-37.805000001149097, -7.954999993354988],
              [-37.804166666858123, -7.954999993354988],
              [-37.804166667757443, -7.954166659064015],
              [-37.803333331667886, -7.954166659064015],
              [-37.803333331667886, -7.953333324773098],
              [-37.802500000074929, -7.953333325672418],
              [-37.802500000074929, -7.952499993180083],
              [-37.801666667582595, -7.952499993180083],
              [-37.801666665783955, -7.950833325497513],
              [-37.800833331493038, -7.950833325497513],
              [-37.800833335090317, -7.949166658714262],
              [-37.799999999900024, -7.949166658714262],
              [-37.799999999000704, -7.947499991931011],
              [-37.797500000624495, -7.94749999283033],
              [-37.797499998825856, -7.946666659438733],
              [-37.794999999550271, -7.946666659438733],
              [-37.794999999550271, -7.945833326047136],
              [-37.7933333318677, -7.945833326047136],
              [-37.7933333318677, -7.944166659263885],
              [-37.791666666883145, -7.944166659263885],
              [-37.791666667782465, -7.943333324972912],
              [-37.789999999200575, -7.943333325872231],
              [-37.788333331518004, -7.943333325872231],
              [-37.788333334215963, -7.942499993379954],
              [-37.78749999812635, -7.942499993379954],
              [-37.787499999924989, -7.94166665908898],
              [-37.786666665634073, -7.941666658189661],
              [-37.786666666533392, -7.940833325697383],
              [-37.785833331343099, -7.940833325697383],
              [-37.785833331343099, -7.939999993205049],
              [-37.780833332792042, -7.939999993205049],
              [-37.780833332792042, -7.940833325697383],
              [-37.779166666008791, -7.940833325697383],
              [-37.779166667807431, -7.939999993205049],
              [-37.77749999922554, -7.939999993205049],
              [-37.77750000102418, -7.940833325697383],
              [-37.776666666733263, -7.940833325697383],
              [-37.776666666733263, -7.94166665908898],
              [-37.774166665659038, -7.941666658189661],
              [-37.774166667457678, -7.940833325697383],
              [-37.773333331368121, -7.940833325697383],
              [-37.773333334066081, -7.939999993205049],
              [-37.770833332991856, -7.939999993205049],
              [-37.770833332991856, -7.939166658914132],
              [-37.769166664409966, -7.939166658914132],
              [-37.769166664409966, -7.937499993030201],
              [-37.768333332817008, -7.937499993030201],
              [-37.768333333716328, -7.936666658739284],
              [-37.767499998526091, -7.936666657839965],
              [-37.767499998526091, -7.93583332624695],
              [-37.767499999425411, -7.934999993754673],
              [-37.762499999075658, -7.934999991956033],
              [-37.762500000874297, -7.933333326971422],
              [-37.759166668207115, -7.933333326072102],
              [-37.759166665509156, -7.932499992680448],
              [-37.758333332117559, -7.932499992680448],
              [-37.758333331218239, -7.931666659288851],
              [-37.757499999625225, -7.931666659288851],
              [-37.757499998725905, -7.932499992680448],
              [-37.754999998551057, -7.932499992680448],
              [-37.754999999450376, -7.931666660188171],
              [-37.754166666958042, -7.931666659288851],
              [-37.754166667857419, -7.930833325897254],
              [-37.753333332667125, -7.930833324997877],
              [-37.753333332667125, -7.92999999340492],
              [-37.752500000174848, -7.92999999340492],
              [-37.752500001074168, -7.929166660013323],
              [-37.751666665883874, -7.929166659114003],
              [-37.751666665883874, -7.928333325722349],
              [-37.750833333391597, -7.928333325722349],
              [-37.750833333391597, -7.924999992155847],
              [-37.749166665709026, -7.924999993055167],
              [-37.749166667507666, -7.92416665876425],
              [-37.748333331418053, -7.92416665876425],
              [-37.748333333216692, -7.924999993055167],
              [-37.747499998925775, -7.924999993055167],
              [-37.747499999825095, -7.92416665966357],
              [-37.746666665534121, -7.92416665876425],
              [-37.746666668232137, -7.924999993055167],
              [-37.745833332142524, -7.924999993055167],
              [-37.745833333041844, -7.92416665876425],
              [-37.74499999875087, -7.92416665786493],
              [-37.744999999650247, -7.923333325372596],
              [-37.743333333766316, -7.923333326271916],
              [-37.743333333766316, -7.922499992880319],
              [-37.742499999475342, -7.922499992880319],
              [-37.742500000374662, -7.921666659488722],
              [-37.740000000199814, -7.921666659488722],
              [-37.740000000199814, -7.920833326097068],
              [-37.738333332517243, -7.920833326097068],
              [-37.738333331617923, -7.919999992705471],
              [-37.736666667532631, -7.919999992705471],
              [-37.736666665733992, -7.919166658414497],
              [-37.735833332342395, -7.919166659313817],
              [-37.735833334141034, -7.9183333250229],
              [-37.734166666458464, -7.91833332592222],
              [-37.734166664659824, -7.916666659138968],
              [-37.738333333416563, -7.916666658239649],
              [-37.738333332517243, -7.91416665896412],
              [-37.737500000024966, -7.91416665896412],
              [-37.737500000924285, -7.912499993080189],
              [-37.738333331617923, -7.912499993080189],
              [-37.738333331617923, -7.909166657715048],
              [-37.73916666590884, -7.909166659513687],
              [-37.73916666680816, -7.907499992730436],
              [-37.740000000199814, -7.907499992730436],
              [-37.740000000199814, -7.906666659338839],
              [-37.742499999475342, -7.906666659338839],
              [-37.742500000374662, -7.904999993454908],
              [-37.743333331967676, -7.904999994354228],
              [-37.743333331967676, -7.904166659163934],
              [-37.745000000549567, -7.904166659163934],
              [-37.745000000549567, -7.904999993454908],
              [-37.745833333041844, -7.904999993454908],
              [-37.745833333941164, -7.905833325947185],
              [-37.747500000724415, -7.905833325947185],
              [-37.747499998925775, -7.904999993454908],
              [-37.748333334116012, -7.904999993454908],
              [-37.748333333216692, -7.904166659163934],
              [-37.75, -7.904166659163934],
              [-37.749999999100623, -7.902499993280003],
              [-37.749166665709026, -7.902499994179323],
              [-37.749166665709026, -7.901666658989086],
              [-37.746666668232137, -7.901666658989086],
              [-37.746666668232137, -7.900833326496752],
              [-37.745833332142524, -7.900833326496752],
              [-37.745833332142524, -7.899999992205835],
              [-37.74499999875087, -7.899999992205835],
              [-37.744999999650247, -7.899166658814181],
              [-37.744166667157913, -7.899166658814181],
              [-37.744166667157913, -7.897499992930307],
              [-37.743333332866996, -7.897499992030987],
              [-37.743333331967676, -7.896666658639333],
              [-37.744166665359273, -7.896666658639333],
              [-37.744166665359273, -7.895833326147056],
              [-37.745000000549567, -7.895833325247736],
              [-37.74499999875087, -7.894166659363805],
              [-37.746666666433441, -7.894166659363805],
              [-37.746666665534121, -7.892499993479873],
              [-37.747500000724415, -7.892499993479873],
              [-37.747499998925775, -7.888333326521774],
              [-37.748333331418053, -7.888333326521774],
              [-37.748333334116012, -7.88749999313012],
              [-37.749166665709026, -7.88749999313012],
              [-37.749166665709026, -7.886666659738523],
              [-37.750833331592958, -7.886666658839204],
              [-37.750833332492277, -7.885833326346869],
              [-37.751666665883874, -7.885833326346869],
              [-37.751666665883874, -7.884999993854592],
              [-37.752500000174848, -7.884999992955272],
              [-37.752500000174848, -7.884166658664299],
              [-37.753333333566445, -7.884166658664299],
              [-37.753333332667125, -7.88166665938877],
              [-37.754166666958042, -7.88166665938877],
              [-37.754166666058723, -7.880833325997173],
              [-37.755833332841974, -7.880833325997173],
              [-37.755833332841974, -7.879999993504839],
              [-37.756666664434988, -7.879999993504839],
              [-37.756666668032267, -7.879166659213922],
              [-37.758333332117559, -7.879166659213922],
              [-37.758333332117559, -7.878333325822268],
              [-37.759166666408476, -7.878333325822268],
              [-37.759166665509156, -7.876666659039017],
              [-37.758333332117559, -7.876666659039017],
              [-37.758333333016878, -7.874999994054463],
              [-37.757499998725905, -7.874999992255766],
              [-37.757499998725905, -7.874166658864169],
              [-37.756666666233627, -7.874166657964849],
              [-37.756666667132947, -7.872499992980238],
              [-37.755833331043334, -7.872499992980238],
              [-37.755833334640613, -7.870833326196987],
              [-37.755000000349696, -7.870833327096307],
              [-37.755000000349696, -7.868333326022139],
              [-37.754166666958042, -7.868333325122819],
              [-37.754166666958042, -7.867499993529805],
              [-37.754999998551057, -7.867499993529805],
              [-37.754999999450376, -7.865833325847291],
              [-37.755833331942654, -7.865833325847291],
              [-37.755833332841974, -7.86416665906404],
              [-37.755833333741293, -7.863333325672386],
              [-37.754999999450376, -7.863333327471025],
              [-37.754999998551057, -7.862499993180109],
              [-37.754166666958042, -7.862499993180109],
              [-37.754166666958042, -7.861666658889135],
              [-37.755000000349696, -7.861666658889135],
              [-37.754999999450376, -7.859166658714287],
              [-37.754166666058723, -7.859166658714287],
              [-37.754166666958042, -7.857499992830355],
              [-37.753333332667125, -7.857499992830355],
              [-37.753333331767806, -7.856666659438758],
              [-37.754166666058723, -7.856666659438758],
              [-37.754166667857419, -7.854166659263853],
              [-37.753333331767806, -7.854166659263853],
              [-37.753333332667125, -7.851666658189686],
              [-37.752500000174848, -7.851666659089005],
              [-37.752500000174848, -7.850833324798032],
              [-37.750833332492277, -7.850833325697351],
              [-37.750833333391597, -7.849999993205074],
              [-37.750000000899263, -7.849999993205074],
              [-37.749999999100623, -7.847499993030226],
              [-37.749166665709026, -7.847499992130906],
              [-37.749166667507666, -7.846666659638572],
              [-37.748333334116012, -7.846666657839933],
              [-37.748333334116012, -7.845833326246975],
              [-37.747499998925775, -7.845833326246975],
              [-37.744999999650247, -7.845833326246975],
              [-37.745000000549567, -7.846666659638572],
              [-37.744166668057233, -7.846666658739252],
              [-37.744166668057233, -7.845833326246975],
              [-37.742500000374662, -7.845833325347655],
              [-37.742499998576022, -7.847499993030226],
              [-37.741666666983065, -7.847499993030226],
              [-37.741666666083745, -7.848333326421823],
              [-37.742499999475342, -7.848333327321143],
              [-37.742499999475342, -7.849166658914157],
              [-37.743333331967676, -7.849166659813477],
              [-37.743333332866996, -7.850833325697351],
              [-37.742499998576022, -7.850833325697351],
              [-37.742500000374662, -7.855833326946424],
              [-37.743333332866996, -7.855833326047104],
              [-37.743333331967676, -7.856666660338078],
              [-37.742499999475342, -7.856666659438758],
              [-37.742499998576022, -7.857499992830355],
              [-37.741666666083745, -7.857499991931036],
              [-37.741666666083745, -7.858333326221953],
              [-37.740000000199814, -7.858333326221953],
              [-37.739999998401174, -7.862499993180109],
              [-37.73916666680816, -7.862499993180109],
              [-37.73916666680816, -7.864999993354957],
              [-37.738333335215202, -7.864999994254276],
              [-37.738333331617923, -7.866666659238888],
              [-37.737499999125589, -7.866666659238888],
              [-37.737500000024966, -7.867499993529805],
              [-37.738333331617923, -7.867499993529805],
              [-37.738333332517243, -7.869166658514416],
              [-37.737499999125589, -7.869166659413736],
              [-37.737500000024966, -7.86999999280539],
              [-37.738333333416563, -7.86999999280539],
              [-37.738333333416563, -7.871666660487961],
              [-37.736666665733992, -7.871666657790001],
              [-37.736666667532631, -7.872499992980238],
              [-37.735833334141034, -7.872499992980238],
              [-37.735833331443075, -7.873333326371892],
              [-37.734999999850061, -7.873333326371892],
              [-37.734999999850061, -7.874999993155086],
              [-37.734166665559144, -7.874999993155086],
              [-37.734166666458464, -7.876666659039017],
              [-37.73333333306681, -7.876666659039017],
              [-37.733333333966129, -7.878333325822268],
              [-37.732499999675213, -7.878333325822268],
              [-37.732500000574532, -7.879166659213922],
              [-37.731666668082198, -7.879166658314603],
              [-37.731666667182878, -7.879999993504839],
              [-37.730833333791281, -7.879999993504839],
              [-37.730833332891962, -7.880833325997173],
              [-37.729999998600988, -7.880833325997173],
              [-37.729999998600988, -7.879999993504839],
              [-37.72916666700803, -7.879999993504839],
              [-37.72916666700803, -7.88166665938877],
              [-37.728333331817794, -7.88166665938877],
              [-37.728333332717114, -7.883333326172021],
              [-37.725833332542209, -7.883333326172021],
              [-37.725833333441528, -7.885833326346869],
              [-37.725000000049931, -7.885833326346869],
              [-37.724999999150612, -7.88749999313012],
              [-37.724166665758958, -7.88749999402944],
              [-37.724166666658277, -7.888333326521774],
              [-37.722499998975707, -7.888333326521774],
              [-37.722499999875026, -7.889166659014052],
              [-37.721666667382749, -7.889166659014052],
              [-37.72166666558411, -7.890833325797303],
              [-37.720833333991152, -7.890833325797303],
              [-37.720833333091832, -7.889999992405706],
              [-37.719999999700178, -7.889999993305025],
              [-37.719999998800859, -7.889166659014052],
              [-37.719166667207901, -7.889166659014052],
              [-37.719166665409261, -7.888333326521774],
              [-37.716666666133676, -7.888333327421094],
              [-37.716666666133676, -7.889166658114732],
              [-37.715000001149065, -7.889166659014052],
              [-37.714999999350425, -7.889999993305025],
              [-37.712500000974217, -7.889999993305025],
              [-37.712499999175577, -7.890833325797303],
              [-37.710833331493006, -7.890833325797303],
              [-37.710833330593687, -7.892499993479873],
              [-37.708333334016118, -7.892499993479873],
              [-37.708333332217478, -7.89333332687147],
              [-37.707499997926504, -7.893333325972151],
              [-37.707499999725144, -7.894166659363805],
              [-37.705833333841213, -7.894166660263124],
              [-37.705833333841213, -7.894999993654721],
              [-37.704166666158699, -7.894999992755402],
              [-37.703333332767045, -7.894999992755402],
              [-37.703333332767045, -7.895833327046375],
              [-37.701666665983794, -7.895833326147056],
              [-37.701666666883114, -7.896666658639333],
              [-37.700833333491516, -7.896666658639333],
              [-37.700833332592197, -7.897499992930307],
              [-37.699999999200543, -7.897499992930307],
              [-37.700000000099863, -7.898333326321904],
              [-37.698333331517972, -7.898333326321904],
              [-37.698333331517972, -7.899999993105155],
              [-37.696666666533417, -7.899999993105155],
              [-37.696666667432737, -7.900833326496752],
              [-37.695833331343124, -7.900833326496752],
              [-37.695833331343124, -7.901666659888406],
              [-37.695000000649486, -7.901666658989086],
              [-37.695000000649486, -7.903333325772337],
              [-37.693333333866235, -7.903333325772337],
              [-37.693333333866235, -7.904166659163934],
              [-37.692500000474581, -7.904166659163934],
              [-37.692499998675942, -7.904999993454908],
              [-37.691666667082984, -7.904999993454908],
              [-37.690833331892691, -7.904999994354228],
              [-37.69083333279201, -7.904166659163934],
              [-37.689999999400413, -7.904166660063254],
              [-37.689999999400413, -7.903333325772337],
              [-37.689166666908136, -7.903333325772337],
              [-37.689166666908136, -7.902499993280003],
              [-37.688333332617162, -7.902499994179323],
              [-37.688333333516482, -7.901666658989086],
              [-37.687499999225565, -7.901666658989086],
              [-37.687500000124885, -7.900833327396072],
              [-37.686666667632551, -7.900833327396072],
              [-37.686666667632551, -7.899999993105155],
              [-37.685833332442314, -7.899999993105155],
              [-37.685833333341634, -7.898333326321904],
              [-37.68333333136809, -7.898333327221223],
              [-37.68333333136809, -7.897499992930307],
              [-37.68499999994998, -7.897499992930307],
              [-37.68499999905066, -7.893333325972151],
              [-37.684166665659063, -7.893333325972151],
              [-37.684166666558383, -7.892499993479873],
              [-37.68333333136809, -7.892499993479873],
              [-37.683333334066049, -7.8916666591889],
              [-37.682499998875812, -7.8916666591889],
              [-37.682500000674452, -7.890833325797303],
              [-37.67916666620863, -7.890833325797303],
              [-37.67916666620863, -7.889999993305025],
              [-37.67916666710795, -7.889166659014052],
              [-37.681666666383478, -7.889166659014052],
              [-37.681666668182174, -7.888333326521774],
              [-37.682499999775132, -7.888333326521774],
              [-37.682499999775132, -7.88749999313012],
              [-37.684166664759744, -7.88749999313012],
              [-37.684166667457703, -7.886666658839204],
              [-37.6850000008493, -7.886666658839204],
              [-37.68499999905066, -7.884166658664299],
              [-37.685833332442314, -7.884166658664299],
              [-37.685833331542995, -7.884999992955272],
              [-37.688333332617162, -7.884999992955272],
              [-37.688333332617162, -7.884166658664299],
              [-37.690000001199053, -7.884166658664299],
              [-37.689999999400413, -7.883333326172021],
              [-37.691666666183664, -7.883333326172021],
              [-37.691666668881624, -7.884166658664299],
              [-37.697500000824334, -7.884166658664299],
              [-37.697500000824334, -7.88166665938877],
              [-37.698333332417292, -7.88166665938877],
              [-37.698333334215931, -7.879999994404159],
              [-37.697500000824334, -7.879999993504839],
              [-37.697500000824334, -7.879166660113242],
              [-37.696666665634041, -7.879166658314603],
              [-37.696666666533417, -7.877499993329991],
              [-37.695833333141763, -7.877499993329991],
              [-37.695833333141763, -7.87583332564742],
              [-37.694166666358512, -7.87583332744606],
              [-37.694166666358512, -7.874999993155086],
              [-37.691666667082984, -7.874999993155086],
              [-37.691666667982304, -7.873333326371892],
              [-37.69083333369133, -7.873333326371892],
              [-37.690833330993371, -7.872499992980238],
              [-37.689166666008759, -7.872499992980238],
              [-37.689166667807456, -7.873333326371892],
              [-37.686666668531871, -7.873333327271212],
              [-37.686666666733231, -7.872499992980238],
              [-37.685833331542995, -7.872499993879558],
              [-37.685833331542995, -7.870833326196987],
              [-37.6850000008493, -7.870833326196987],
              [-37.68499999905066, -7.86999999280539],
              [-37.684166665659063, -7.86999999280539],
              [-37.684166666558383, -7.869166659413736],
              [-37.681666665484158, -7.869166658514416],
              [-37.681666668182174, -7.868333326022139],
              [-37.680000000499604, -7.868333326022139],
              [-37.679999998700964, -7.867499993529805],
              [-37.67916666620863, -7.867499993529805],
              [-37.67916666710795, -7.866666659238888],
              [-37.678333331917713, -7.866666659238888],
              [-37.678333331917713, -7.865833325847291],
              [-37.677500001224018, -7.865833325847291],
              [-37.677499999425379, -7.864999993354957],
              [-37.675833333541448, -7.864999993354957],
              [-37.675833331742808, -7.86416665906404],
              [-37.674166665858877, -7.86416665906404],
              [-37.674166665858877, -7.862499993180109],
              [-37.670833331393112, -7.862499994079428],
              [-37.670833330493792, -7.861666658889135],
              [-37.666666667132915, -7.861666658889135],
              [-37.666666667132915, -7.862499993180109],
              [-37.664999998551025, -7.862499993180109],
              [-37.664999999450345, -7.863333325672386],
              [-37.663333330868454, -7.863333327471025],
              [-37.66333333266715, -7.86416665906404],
              [-37.661666666783219, -7.86416665906404],
              [-37.661666667682539, -7.864999993354957],
              [-37.660833332492246, -7.864999993354957],
              [-37.660833332492246, -7.865833325847291],
              [-37.657500000724383, -7.865833325847291],
              [-37.657499998925744, -7.864999993354957],
              [-37.654166665359298, -7.864999993354957],
              [-37.654166667157938, -7.86416665816472],
              [-37.653333332866964, -7.86416665816472],
              [-37.653333331967644, -7.863333325672386],
              [-37.652499999475367, -7.863333325672386],
              [-37.652499998576047, -7.86416665906404],
              [-37.651666666083713, -7.86416665906404],
              [-37.651666666083713, -7.864999993354957],
              [-37.649166667707505, -7.864999993354957],
              [-37.649166666808185, -7.86416665816472],
              [-37.648333331617891, -7.86416665906404],
              [-37.648333332517211, -7.863333325672386],
              [-37.644166666458432, -7.863333325672386],
              [-37.644166665559112, -7.862499993180109],
              [-37.643333333966154, -7.862499993180109],
              [-37.643333332167515, -7.861666658889135],
              [-37.642499998775861, -7.861666658889135],
              [-37.642499998775861, -7.863333325672386],
              [-37.639166666108736, -7.863333324773066],
              [-37.639166665209416, -7.862499993180109],
              [-37.638333332717082, -7.862499993180109],
              [-37.638333333616401, -7.86416665906404],
              [-37.635833332542234, -7.86416665906404],
              [-37.635833331642914, -7.866666659238888],
              [-37.634166665758983, -7.866666659238888],
              [-37.634166666658302, -7.867499993529805],
              [-37.633333332367329, -7.867499994429181],
              [-37.633333331468009, -7.866666659238888],
              [-37.633333332367329, -7.865833325847291],
              [-37.632499999875051, -7.865833325847291],
              [-37.632500000774371, -7.862499993180109],
              [-37.633333331468009, -7.862499992280789],
              [-37.633333330568689, -7.858333326221953],
              [-37.634166665758983, -7.858333326221953],
              [-37.634166665758983, -7.857499991931036],
              [-37.635000000049899, -7.857499992830355],
              [-37.635000000049899, -7.856666659438758],
              [-37.635833331642914, -7.856666659438758],
              [-37.635833332542234, -7.855833326047104],
              [-37.63666666683315, -7.855833326047104],
              [-37.636666665933831, -7.854999992655507],
              [-37.638333332717082, -7.854999992655507],
              [-37.638333332717082, -7.854166659263853],
              [-37.639166667907375, -7.854166658364534],
              [-37.639166668806695, -7.853333326771576],
              [-37.64083333289193, -7.853333325872256],
              [-37.64083333289193, -7.854166659263853],
              [-37.641666668082223, -7.854166659263853],
              [-37.641666665384264, -7.853333324972937],
              [-37.642500000574501, -7.853333324972937],
              [-37.642500000574501, -7.852499993379922],
              [-37.643333332167515, -7.852499993379922],
              [-37.643333333966154, -7.851666659089005],
              [-37.644166664659792, -7.851666659089005],
              [-37.644166667357752, -7.850833325697351],
              [-37.646666666633337, -7.850833325697351],
              [-37.646666665734017, -7.849999993205074],
              [-37.647500001823573, -7.849999993205074],
              [-37.647499999125614, -7.848333326421823],
              [-37.648333333416588, -7.848333326421823],
              [-37.648333332517211, -7.846666657839933],
              [-37.649166665908865, -7.846666659638572],
              [-37.649166665908865, -7.842499992680473],
              [-37.650000000199782, -7.842499992680473],
              [-37.650000000199782, -7.839166660013291],
              [-37.650833333591436, -7.839166659113971],
              [-37.650833331792796, -7.838333325722374],
              [-37.652499999475367, -7.838333325722374],
              [-37.652499997676728, -7.839166658214651],
              [-37.654166668057258, -7.839166660013291],
              [-37.654166667157938, -7.839999994304264],
              [-37.655833332142549, -7.839999993404945],
              [-37.655833330343853, -7.839166659113971],
              [-37.656666665534146, -7.839166659113971],
              [-37.656666665534146, -7.839999992505625],
              [-37.66, -7.839999994304264],
              [-37.66, -7.83749999323004],
              [-37.660833332492246, -7.83749999323004],
              [-37.660833332492246, -7.836666658939123],
              [-37.661666665883899, -7.836666658939123],
              [-37.661666665883899, -7.835833324648149],
              [-37.664166666058748, -7.835833326446789],
              [-37.664166667857387, -7.834999993055192],
              [-37.665833331942679, -7.834999992155872],
              [-37.665833331942679, -7.834166658764218],
              [-37.666666667132915, -7.834166658764218],
              [-37.666666666233596, -7.833333326271941],
              [-37.66749999872593, -7.833333326271941],
              [-37.66749999962525, -7.832499992880344],
              [-37.669999999800098, -7.832499992880344],
              [-37.669999999800098, -7.830833325197773],
              [-37.670833331393112, -7.830833326996412],
              [-37.670833333191752, -7.829999992705439],
              [-37.671666665684029, -7.829999992705439],
              [-37.671666667482668, -7.828333325922188],
              [-37.672499999075626, -7.828333325922188],
              [-37.672500000874322, -7.82749999432923],
              [-37.671666667482668, -7.82749999342991],
              [-37.671666665684029, -7.824999992355743],
              [-37.670833333191752, -7.824999993255062],
              [-37.670833333191752, -7.823333327371131],
              [-37.669999998001458, -7.823333326471811],
              [-37.669999999800098, -7.822499993080157],
              [-37.66916666820714, -7.822499992180838],
              [-37.669166665509181, -7.821666658789241],
              [-37.668333333016847, -7.821666658789241],
              [-37.668333333916166, -7.820833326296906],
              [-37.66749999962525, -7.820833326296906],
              [-37.667500000524569, -7.819999992905309],
              [-37.666666667132915, -7.819999992905309],
              [-37.666666666233596, -7.817499992730461],
              [-37.665833332841999, -7.817499994529101],
              [-37.665833332841999, -7.814166659163959],
              [-37.664999999450345, -7.814166658264639],
              [-37.664999999450345, -7.813333326671625],
              [-37.664166666958067, -7.813333325772305],
              [-37.664166666058748, -7.807499992930275],
              [-37.665000000349721, -7.807499992930275],
              [-37.664999999450345, -7.805833326147024],
              [-37.666666666233596, -7.805833326147024],
              [-37.666666666233596, -7.804166658464453],
              [-37.66749999872593, -7.804166660263093],
              [-37.667500000524569, -7.802499993479842],
              [-37.668333333916166, -7.802499993479842],
              [-37.668333332117527, -7.799166659014077],
              [-37.669166666408501, -7.799166659014077],
              [-37.66916666820714, -7.798333326521742],
              [-37.669999998900778, -7.798333326521742],
              [-37.669999999800098, -7.797499993130145],
              [-37.670833334091071, -7.797499993130145],
              [-37.670833331393112, -7.79499999295524],
              [-37.671666667482668, -7.79499999295524],
              [-37.671666667482668, -7.794166658664324],
              [-37.672499999975003, -7.794166658664324],
              [-37.672499999075626, -7.793333326172046],
              [-37.67333333156796, -7.793333326172046],
              [-37.67333333156796, -7.791666659388795],
              [-37.674166665858877, -7.791666659388795],
              [-37.674166666758197, -7.790833325097822],
              [-37.675000000149851, -7.790833325997141],
              [-37.675000000149851, -7.787499994229279],
              [-37.674166666758197, -7.787499992430639],
              [-37.674166665858877, -7.785833325647388],
              [-37.675833333541448, -7.785833325647388],
              [-37.675833332642128, -7.78333332637186],
              [-37.67500000104917, -7.78333332727118],
              [-37.674999998351211, -7.781666658689289],
              [-37.675833333541448, -7.781666658689289],
              [-37.675833332642128, -7.779999992805358],
              [-37.676666666933102, -7.779999992805358],
              [-37.676666666033782, -7.774999993354982],
              [-37.677499999425379, -7.774999992455662],
              [-37.677499998526059, -7.773333325672411],
              [-37.67916666710795, -7.773333325672411],
              [-37.679166668007269, -7.772499993180077],
              [-37.679999999600284, -7.772499992280757],
              [-37.679999999600284, -7.770833326396826],
              [-37.680833332092561, -7.770833326396826],
              [-37.680833333891201, -7.769999993005229],
              [-37.681666666383478, -7.769999993005229],
              [-37.681666668182174, -7.768333326221978],
              [-37.68333333136809, -7.768333326221978],
              [-37.683333334066049, -7.766666659438727],
              [-37.684166665659063, -7.766666659438727],
              [-37.684166665659063, -7.76583332604713],
              [-37.684999998151341, -7.76583332604713],
              [-37.68499999905066, -7.763333325872225],
              [-37.684166666558383, -7.763333325872225],
              [-37.684166665659063, -7.762499993379947],
              [-37.683333334066049, -7.762499993379947],
              [-37.68333333136809, -7.761666659088974],
              [-37.681666668182174, -7.761666659988293],
              [-37.677499998526059, -7.761666659088974],
              [-37.677500002123338, -7.762499993379947],
              [-37.67333333156796, -7.762499992480627],
              [-37.67333333156796, -7.763333325872225],
              [-37.671666667482668, -7.763333325872225],
              [-37.671666665684029, -7.762499993379947],
              [-37.669999999800098, -7.762499993379947],
              [-37.669999998900778, -7.760833325697376],
              [-37.669166665509181, -7.760833325697376],
              [-37.669166666408501, -7.759999993205099],
              [-37.66749999962525, -7.759999993205099],
              [-37.66749999962525, -7.759166658914125],
              [-37.663333331767831, -7.759166658914125],
              [-37.66333333266715, -7.759999993205099],
              [-37.660833332492246, -7.759999993205099],
              [-37.660833331592926, -7.760833325697376],
              [-37.659999999100648, -7.760833325697376],
              [-37.66, -7.761666659988293],
              [-37.659166665708995, -7.761666659088974],
              [-37.659166664809675, -7.763333325872225],
              [-37.658333333216717, -7.763333325872225],
              [-37.658333333216717, -7.764166659263879],
              [-37.657499999825063, -7.764166659263879],
              [-37.657499998925744, -7.76583332604713],
              [-37.656666666433466, -7.76583332604713],
              [-37.656666666433466, -7.767499991931061],
              [-37.655833333041869, -7.767499992830381],
              [-37.655833333941189, -7.769166658714255],
              [-37.654999998750895, -7.769166658714255],
              [-37.654999998750895, -7.770833326396826],
              [-37.653333331967644, -7.770833326396826],
              [-37.653333333766284, -7.772499992280757],
              [-37.652500000374687, -7.772499993180077],
              [-37.652499998576047, -7.773333325672411],
              [-37.651666667882353, -7.773333325672411],
              [-37.651666666083713, -7.774166658164688],
              [-37.650833333591436, -7.774166659064008],
              [-37.650833332692116, -7.775833325847259],
              [-37.649166666808185, -7.775833325847259],
              [-37.649166665908865, -7.77749999352983],
              [-37.648333333416588, -7.77749999352983],
              [-37.648333331617891, -7.779166659413761],
              [-37.647500000024934, -7.779166659413761],
              [-37.647499999125614, -7.781666658689289],
              [-37.646666665734017, -7.781666658689289],
              [-37.646666667532656, -7.782499992980263],
              [-37.644166665559112, -7.782499992980263],
              [-37.644166667357752, -7.781666658689289],
              [-37.642500000574501, -7.781666658689289],
              [-37.642499998775861, -7.780833326197012],
              [-37.641666668082223, -7.780833327096332],
              [-37.641666667182903, -7.77749999352983],
              [-37.64083333199261, -7.77749999352983],
              [-37.64083333289193, -7.776666659238913],
              [-37.639999999500333, -7.776666659238913],
              [-37.639999999500333, -7.775833325847259],
              [-37.639166666108736, -7.775833325847259],
              [-37.639166666108736, -7.773333325672411],
              [-37.640000000399652, -7.773333325672411],
              [-37.640000000399652, -7.770833326396826],
              [-37.64083333199261, -7.770833326396826],
              [-37.64083333199261, -7.769999993005229],
              [-37.639999999500333, -7.769999993005229],
              [-37.639999998601013, -7.769166658714255],
              [-37.639166666108736, -7.769166658714255],
              [-37.639166666108736, -7.76583332604713],
              [-37.63666666773247, -7.76583332604713],
              [-37.63666666683315, -7.763333325872225],
              [-37.635833331642914, -7.763333326771544],
              [-37.635833332542234, -7.762499993379947],
              [-37.63499999915058, -7.762499994279267],
              [-37.635000000049899, -7.761666659988293],
              [-37.634166665758983, -7.761666659988293],
              [-37.634166665758983, -7.760833325697376],
              [-37.633333332367329, -7.760833325697376],
              [-37.633333332367329, -7.759999993205099],
              [-37.632499998975732, -7.759999993205099],
              [-37.632499998975732, -7.758333326421848],
              [-37.631666665584135, -7.758333326421848],
              [-37.631666667382774, -7.756666657839958],
              [-37.630833332192481, -7.756666658739277],
              [-37.630833332192481, -7.754999992855346],
              [-37.631666665584135, -7.754999993754666],
              [-37.631666669181413, -7.753333326072095],
              [-37.632499998076412, -7.753333326072095],
              [-37.632500001673691, -7.752499994479138],
              [-37.633333330568689, -7.752499992680498],
              [-37.633333332367329, -7.750833325897247],
              [-37.634166665758983, -7.750833325897247],
              [-37.634166666658302, -7.749999994304233],
              [-37.63499999915058, -7.749999993404913],
              [-37.635000000049899, -7.747499993230065],
              [-37.63499999915058, -7.746666659838411],
              [-37.633333332367329, -7.746666658039771],
              [-37.633333332367329, -7.745833326446814],
              [-37.632499998975732, -7.745833326446814],
              [-37.632499998975732, -7.742499992880312],
              [-37.628333332916952, -7.742499992880312],
              [-37.628333332017633, -7.743333326271966],
              [-37.627499999525298, -7.743333326271966],
              [-37.627500000424618, -7.744166657864923],
              [-37.626666667932341, -7.744166658764243],
              [-37.626666667033021, -7.745833326446814],
              [-37.624999998451131, -7.745833324648174],
              [-37.624999997551811, -7.746666658939091],
              [-37.624166667757493, -7.746666658939091],
              [-37.624166666858173, -7.747499994129385],
              [-37.623333333466519, -7.747499994129385],
              [-37.62333333166788, -7.748333324823022],
              [-37.622499999175602, -7.748333325722342],
              [-37.622500000074922, -7.749999993404913],
              [-37.621666667582588, -7.749999993404913],
              [-37.621666667582588, -7.750833325897247],
              [-37.620833332392351, -7.750833325897247],
              [-37.620833331493031, -7.751666659288844],
              [-37.619999999900017, -7.751666659288844],
              [-37.618333333116766, -7.751666658389524],
              [-37.618333332217446, -7.749999993404913],
              [-37.615833333841238, -7.749999993404913],
              [-37.615833332042598, -7.749166659113996],
              [-37.614999999550321, -7.749166659113996],
              [-37.614999999550321, -7.748333327520982],
              [-37.614166664360027, -7.748333324823022],
              [-37.614166667057987, -7.747499993230065],
              [-37.61333333186775, -7.747499993230065],
              [-37.61333333276707, -7.743333325372646],
              [-37.612500001174055, -7.743333326271966],
              [-37.612500001174055, -7.741666660388034],
              [-37.610833331692845, -7.741666657690075],
              [-37.610833331692845, -7.740833325197741],
              [-37.609999997401928, -7.740833326097061],
              [-37.609999999200568, -7.741666659488715],
              [-37.606666665634066, -7.741666657690075],
              [-37.606666667432705, -7.740833325197741],
              [-37.599999998501062, -7.740833326097061],
              [-37.599999999400382, -7.741666659488715],
              [-37.595833331542963, -7.741666660388034],
              [-37.595833333341602, -7.742499993779631],
              [-37.594999999950005, -7.742499991980992],
              [-37.595000000849325, -7.743333326271966],
              [-37.594166666558351, -7.743333326271966],
              [-37.594166667457671, -7.744166659663563],
              [-37.593333331368115, -7.744166658764243],
              [-37.593333334066074, -7.750833325897247],
              [-37.59249999887578, -7.750833325897247],
              [-37.5924999997751, -7.751666659288844],
              [-37.591666666383503, -7.751666659288844],
              [-37.591666665484183, -7.752499992680498],
              [-37.590833332092586, -7.752499992680498],
              [-37.590833332092586, -7.753333326072095],
              [-37.589166668007294, -7.753333326072095],
              [-37.589166666208655, -7.754166659463692],
              [-37.590000000499572, -7.754166659463692],
              [-37.589999998700932, -7.754999992855346],
              [-37.589166667107975, -7.754999992855346],
              [-37.589166666208655, -7.757499993030194],
              [-37.587499999425404, -7.757499993030194],
              [-37.587499999425404, -7.759999993205099],
              [-37.587499998526084, -7.760833325697376],
              [-37.589166668007294, -7.760833324798057],
              [-37.589166668007294, -7.761666659088974],
              [-37.589999999600252, -7.761666658189654],
              [-37.590000000499572, -7.763333325872225],
              [-37.590833332092586, -7.763333325872225],
              [-37.590833332092586, -7.764999992655476],
              [-37.591666665484183, -7.764999992655476],
              [-37.591666666383503, -7.76583332604713],
              [-37.593333333166754, -7.76583332604713],
              [-37.593333331368115, -7.766666659438727],
              [-37.594166666558351, -7.766666659438727],
              [-37.594166667457671, -7.767499992830381],
              [-37.594999999950005, -7.767499992830381],
              [-37.594999999950005, -7.769999993005229],
              [-37.595833333341602, -7.769999993005229],
              [-37.595833332442282, -7.770833326396826],
              [-37.596666666733256, -7.770833326396826],
              [-37.596666666733256, -7.772499993180077],
              [-37.598333331717868, -7.772499993180077],
              [-37.598333331717868, -7.773333325672411],
              [-37.596666667632576, -7.773333325672411],
              [-37.596666666733256, -7.774166659064008],
              [-37.595000000849325, -7.774166659064008],
              [-37.594999999950005, -7.774999993354982],
              [-37.593333331368115, -7.774999993354982],
              [-37.593333334066074, -7.775833325847259],
              [-37.5924999997751, -7.775833325847259],
              [-37.5924999997751, -7.776666658339536],
              [-37.591666666383503, -7.776666659238913],
              [-37.591666666383503, -7.77749999352983],
              [-37.589166668007294, -7.77749999173119],
              [-37.589166666208655, -7.778333326022107],
              [-37.587499998526084, -7.778333326022107],
              [-37.587499999425404, -7.785833325647388],
              [-37.58666666603375, -7.785833325647388],
              [-37.58666666693307, -7.787499993329959],
              [-37.584166667657541, -7.787499993329959],
              [-37.584166666758222, -7.788333325822293],
              [-37.583333333366625, -7.788333325822293],
              [-37.583333333366625, -7.790833326896461],
              [-37.584166665858902, -7.790833325997141],
              [-37.584166667657541, -7.791666659388795],
              [-37.583333333366625, -7.791666659388795],
              [-37.583333331567928, -7.792499992780392],
              [-37.581666665684054, -7.792499992780392],
              [-37.581666667482693, -7.793333326172046],
              [-37.582499999075651, -7.793333326172046],
              [-37.582499999075651, -7.794166660462963],
              [-37.581666666583374, -7.794166658664324],
              [-37.581666665684054, -7.796666658839172],
              [-37.58083333139308, -7.796666658839172],
              [-37.58083333139308, -7.797499993130145],
              [-37.579999998900803, -7.797499993130145],
              [-37.579999998900803, -7.798333326521742],
              [-37.579166665509149, -7.798333326521742],
              [-37.579166665509149, -7.799999993304993],
              [-37.577499998725898, -7.799999993304993],
              [-37.577500000524537, -7.800833326696647],
              [-37.576666666233621, -7.800833325797328],
              [-37.576666666233621, -7.802499991681202],
              [-37.577500000524537, -7.802499993479842],
              [-37.577499999625218, -7.804999993654747],
              [-37.57666666803226, -7.804999992755427],
              [-37.576666666233621, -7.805833326147024],
              [-37.575833333741343, -7.805833326147024],
              [-37.575833331942647, -7.806666658639358],
              [-37.57499999945037, -7.806666658639358],
              [-37.57499999855105, -7.805833326147024],
              [-37.574166666958092, -7.805833326147024],
              [-37.574166666958092, -7.804999992755427],
              [-37.573333331767799, -7.804999993654747],
              [-37.573333331767799, -7.804166659363773],
              [-37.570833331592951, -7.804166658464453],
              [-37.57083333339159, -7.803333325972176],
              [-37.570000000899256, -7.803333325972176],
              [-37.57, -7.801666659188925],
              [-37.56916666570902, -7.801666659188925],
              [-37.56916666570902, -7.799999993304993],
              [-37.568333333216685, -7.799999993304993],
              [-37.568333331418046, -7.798333326521742],
              [-37.567499998925769, -7.798333326521742],
              [-37.567499998925769, -7.797499993130145],
              [-37.566666666433491, -7.797499993130145],
              [-37.566666666433491, -7.795833325447575],
              [-37.567500000724408, -7.795833326346894],
              [-37.567500000724408, -7.79499999295524],
              [-37.566666666433491, -7.79499999385456],
              [-37.566666666433491, -7.794166658664324],
              [-37.565833333041837, -7.794166658664324],
              [-37.565833333041837, -7.793333326172046],
              [-37.56499999965024, -7.793333326172046],
              [-37.56499999875092, -7.792499992780392],
              [-37.564166665359267, -7.792499992780392],
              [-37.564166668057226, -7.791666659388795],
              [-37.563333332866989, -7.791666660288115],
              [-37.563333331967669, -7.790833325997141],
              [-37.562499998576016, -7.790833326896461],
              [-37.562499998576016, -7.788333325822293],
              [-37.561666666983058, -7.788333325822293],
              [-37.561666666983058, -7.787499993329959],
              [-37.560833332692084, -7.787499992430639],
              [-37.560833332692084, -7.786666658139723],
              [-37.560000001099127, -7.786666659039042],
              [-37.559999998401167, -7.785833324748069],
              [-37.55916666680821, -7.785833325647388],
              [-37.55916666680821, -7.784999993155111],
              [-37.558333331617916, -7.784999992255791],
              [-37.558333331617916, -7.784166658864194],
              [-37.557499999125639, -7.784166658864194],
              [-37.557500000924279, -7.78333332637186],
              [-37.555833332342388, -7.78333332637186],
              [-37.552499998775886, -7.78333332637186],
              [-37.552499999675206, -7.782499993879583],
              [-37.551666665384232, -7.782499992980263],
              [-37.551666665384232, -7.781666658689289],
              [-37.550833332891955, -7.78166665778997],
              [-37.550833332891955, -7.780833327096332],
              [-37.549999998601038, -7.780833326197012],
              [-37.550000000399677, -7.779999992805358],
              [-37.549166667008024, -7.779999991906038],
              [-37.549166667008024, -7.778333326921427],
              [-37.547500001124092, -7.778333325122787],
              [-37.547499998426133, -7.777499994429149],
              [-37.546666665933856, -7.77749999352983],
              [-37.546666666833175, -7.776666659238913],
              [-37.545000000049924, -7.776666659238913],
              [-37.545000000049924, -7.775833325847259],
              [-37.54416666755759, -7.775833325847259],
              [-37.544166665758951, -7.774999993354982],
              [-37.542499998975757, -7.774999993354982],
              [-37.542499998975757, -7.774166658164688],
              [-37.540833332192506, -7.774166659064008],
              [-37.540833333091825, -7.772499992280757],
              [-37.53833333291692, -7.772499993180077],
              [-37.53833333381624, -7.770833326396826],
              [-37.537499999525323, -7.770833326396826],
              [-37.537500000424643, -7.769999993005229],
              [-37.536666667032989, -7.769999993005229],
              [-37.536666667932309, -7.768333326221978],
              [-37.535833331842753, -7.768333326221978],
              [-37.535833331842753, -7.76583332604713],
              [-37.534999998451099, -7.76583332604713],
              [-37.534999999350418, -7.764166659263879],
              [-37.535833331842753, -7.764166659263879],
              [-37.535833333641392, -7.763333325872225],
              [-37.536666667032989, -7.763333326771544],
              [-37.536666667932309, -7.762499993379947],
              [-37.535833332742072, -7.762499993379947],
              [-37.535833331842753, -7.758333326421848],
              [-37.536666667932309, -7.758333326421848],
              [-37.536666667932309, -7.749999993404913],
              [-37.539166667207894, -7.749999994304233],
              [-37.539166667207894, -7.749166659113996],
              [-37.539999999700171, -7.749166659113996],
              [-37.539999999700171, -7.749999993404913],
              [-37.542499998975757, -7.749999993404913],
              [-37.542499998975757, -7.749166660013316],
              [-37.544166666658271, -7.749166659113996],
              [-37.544166665758951, -7.748333325722342],
              [-37.546666665933856, -7.748333325722342],
              [-37.546666667732495, -7.747499993230065],
              [-37.548333332717107, -7.747499992330745],
              [-37.548333331817787, -7.746666658939091],
              [-37.549166667907343, -7.746666658939091],
              [-37.549166667008024, -7.745833326446814],
              [-37.549999998601038, -7.745833326446814],
              [-37.549999999500358, -7.741666659488715],
              [-37.549166665209384, -7.741666659488715],
              [-37.549166666108704, -7.73916665931381],
              [-37.548333333616426, -7.73916665931381],
              [-37.548333333616426, -7.738333326821532],
              [-37.547499997526813, -7.738333325022893],
              [-37.547500000224773, -7.737499993429878],
              [-37.546666665933856, -7.737499991631239],
              [-37.546666666833175, -7.736666659138962],
              [-37.545833333441522, -7.736666658239642],
              [-37.545833332542202, -7.735833326646684],
              [-37.545000000049924, -7.735833325747365],
              [-37.545000000049924, -7.73499999325503],
              [-37.542499998975757, -7.73499999325503],
              [-37.542500000774396, -7.734166658964114],
              [-37.541666666483422, -7.734166658064794],
              [-37.541666667382742, -7.732499993979502],
              [-37.540833333991145, -7.732499993080182],
              [-37.540833332192506, -7.730833326296931],
              [-37.539999998800852, -7.730833326296931],
              [-37.539999999700171, -7.729999992905277],
              [-37.540833332192506, -7.729999992905277],
              [-37.540833333091825, -7.726666659338832],
              [-37.541666667382742, -7.726666659338832],
              [-37.541666665584103, -7.72333332577233],
              [-37.540833332192506, -7.72333332577233],
              [-37.540833333991145, -7.722499993279996],
              [-37.539166665409255, -7.722499993279996],
              [-37.539166667207894, -7.721666658989079],
              [-37.538333332017601, -7.721666658989079],
              [-37.538333331118281, -7.720833326496802],
              [-37.536666667032989, -7.720833326496802],
              [-37.53666666433503, -7.719166658814231],
              [-37.534999999350418, -7.719166657914911],
              [-37.531666665783973, -7.719166659713551],
              [-37.531666666683293, -7.719999993105148],
              [-37.530833331493, -7.719999993105148],
              [-37.530833332392319, -7.720833326496802],
              [-37.529999999900042, -7.720833326496802],
              [-37.529999999000722, -7.722499993279996],
              [-37.529166665609068, -7.722499993279996],
              [-37.529166666508388, -7.72333332577233],
              [-37.528333332217471, -7.72333332577233],
              [-37.528333332217471, -7.722499993279996],
              [-37.52666666543422, -7.722499993279996],
              [-37.52666666723286, -7.72333332577233],
              [-37.525833333841263, -7.72333332577233],
              [-37.525833332042623, -7.724999993454901],
              [-37.525000000449609, -7.724999993454901],
              [-37.525000000449609, -7.725833325947178],
              [-37.524166667058012, -7.725833325947178],
              [-37.524166666158692, -7.728333327021403],
              [-37.523333331867718, -7.728333325222707],
              [-37.523333332767038, -7.732499993080182],
              [-37.52250000117408, -7.732499993080182],
              [-37.522499998476121, -7.734166658964114],
              [-37.523333332767038, -7.734166658964114],
              [-37.523333333666358, -7.73499999325503],
              [-37.522499999375441, -7.73499999325503],
              [-37.522499998476121, -7.735833325747365],
              [-37.520000000099856, -7.735833325747365],
              [-37.520000000099856, -7.736666659138962],
              [-37.519166666708259, -7.736666659138962],
              [-37.519166666708259, -7.738333325922213],
              [-37.518333332417342, -7.738333325922213],
              [-37.518333331517965, -7.73916665931381],
              [-37.517499999025688, -7.73916665841449],
              [-37.517500000824327, -7.740833326097061],
              [-37.51666666743273, -7.740833326097061],
              [-37.51666666743273, -7.742499991980992],
              [-37.515833331343117, -7.742499992880312],
              [-37.515833333141757, -7.745833326446814],
              [-37.514166666358506, -7.745833326446814],
              [-37.514166666358506, -7.749999994304233],
              [-37.51499999885084, -7.749999993404913],
              [-37.51499999885084, -7.751666659288844],
              [-37.514166666358506, -7.751666659288844],
              [-37.514166668157145, -7.755833326246943],
              [-37.513333334765548, -7.755833326246943],
              [-37.513333331168269, -7.757499993929514],
              [-37.514166666358506, -7.757499993030194],
              [-37.514166666358506, -7.759999992305779],
              [-37.513333332067589, -7.759999993205099],
              [-37.513333332067589, -7.763333325872225],
              [-37.514166668157145, -7.763333325872225],
              [-37.514166665459186, -7.764166659263879],
              [-37.513333332966909, -7.764166659263879],
              [-37.513333332067589, -7.769166658714255],
              [-37.514166665459186, -7.769166658714255],
              [-37.514166665459186, -7.770833326396826],
              [-37.51499999885084, -7.770833326396826],
              [-37.51499999975016, -7.77166665978848],
              [-37.515833333141757, -7.77166665978848],
              [-37.515833331343117, -7.772499993180077],
              [-37.516666665634091, -7.772499993180077],
              [-37.516666666533411, -7.77333332747105],
              [-37.518333334215981, -7.773333325672411],
              [-37.518333332417342, -7.774166659064008],
              [-37.519166668506898, -7.774166659064008],
              [-37.519166667607578, -7.775833325847259],
              [-37.52083333349151, -7.775833325847259],
              [-37.52083333169287, -7.776666659238913],
              [-37.521666666883107, -7.776666659238913],
              [-37.521666666883107, -7.778333326022107],
              [-37.52250000117408, -7.778333326921427],
              [-37.522499999375441, -7.782499992980263],
              [-37.523333331867718, -7.782499992980263],
              [-37.523333332767038, -7.78333332727118],
              [-37.524166666158692, -7.78333332727118],
              [-37.524166667957331, -7.784166658864194],
              [-37.525000000449609, -7.784166658864194],
              [-37.524999998650969, -7.784999993155111],
              [-37.524166666158692, -7.784999993155111],
              [-37.524166667957331, -7.790833325997141],
              [-37.525000000449609, -7.790833325997141],
              [-37.524999999550289, -7.791666659388795],
              [-37.525833332042623, -7.791666658489476],
              [-37.525833332042623, -7.792499992780392],
              [-37.524999999550289, -7.792499992780392],
              [-37.524999998650969, -7.794166657765004],
              [-37.524166667058012, -7.794166658664324],
              [-37.524166667058012, -7.796666658839172],
              [-37.525000000449609, -7.796666659738491],
              [-37.525000000449609, -7.797499992230826],
              [-37.525833332042623, -7.797499994029465],
              [-37.525833332941943, -7.798333324723103],
              [-37.52666666723286, -7.798333326521742],
              [-37.52666666543422, -7.799166658114757],
              [-37.527499998825817, -7.799166659014077],
              [-37.527499998825817, -7.800833325797328],
              [-37.528333330418832, -7.800833325797328],
              [-37.528333333116791, -7.801666659188925],
              [-37.527499999725137, -7.801666659188925],
              [-37.527499998825817, -7.802499993479842],
              [-37.52666666723286, -7.802499994379218],
              [-37.526666668132179, -7.804166659363773],
              [-37.525833334740582, -7.804166659363773],
              [-37.525833332042623, -7.804999992755427],
              [-37.523333332767038, -7.804999992755427],
              [-37.523333332767038, -7.805833326147024],
              [-37.522499998476121, -7.805833326147024],
              [-37.522499998476121, -7.806666658639358],
              [-37.521666665983787, -7.806666658639358],
              [-37.521666665983787, -7.807499992930275],
              [-37.52083333079355, -7.807499992930275],
              [-37.52083333169287, -7.808333326321929],
              [-37.521666665983787, -7.808333326321929],
              [-37.521666666883107, -7.809999993105123],
              [-37.52250000117408, -7.8099999940045],
              [-37.522499999375441, -7.810833324698137],
              [-37.523333332767038, -7.810833326496777],
              [-37.523333331867718, -7.812499993280028],
              [-37.524166667058012, -7.812499993280028],
              [-37.524166667058012, -7.813333325772305],
              [-37.524999999550289, -7.813333324872985],
              [-37.524999998650969, -7.81583332594721],
              [-37.525833332042623, -7.81583332594721],
              [-37.525833332042623, -7.816666659338807],
              [-37.526666668132179, -7.816666659338807],
              [-37.526666668132179, -7.818333327021378],
              [-37.527499999725137, -7.818333326122058],
              [-37.527499998825817, -7.819999992905309],
              [-37.528333334016111, -7.819999992005989],
              [-37.528333332217471, -7.821666658789241],
              [-37.529166664709749, -7.821666658789241],
              [-37.529166665609068, -7.823333326471811],
              [-37.529999999900042, -7.823333326471811],
              [-37.529999999000722, -7.824166658964089],
              [-37.530833331493, -7.824166658964089],
              [-37.530833331493, -7.825833326646659],
              [-37.53250000097421, -7.82583332574734],
              [-37.53249999917557, -7.82749999432923],
              [-37.533333332567224, -7.82749999342991],
              [-37.533333333466544, -7.828333326821507],
              [-37.534166666858141, -7.828333325922188],
              [-37.534166666858141, -7.829166660213161],
              [-37.531666665783973, -7.829166659313842],
              [-37.531666666683293, -7.829999992705439],
              [-37.530833331493, -7.829999992705439],
              [-37.530833332392319, -7.83333332717126],
              [-37.530000000799362, -7.833333326271941],
              [-37.530000000799362, -7.834166658764218],
              [-37.527499999725137, -7.834166658764218],
              [-37.527500000624514, -7.834999993055192],
              [-37.525833332042623, -7.834999993055192],
              [-37.525833332941943, -7.836666658939123],
              [-37.524999999550289, -7.836666658939123],
              [-37.524999999550289, -7.838333325722374],
              [-37.524166666158692, -7.838333325722374],
              [-37.524166667058012, -7.840833324997902],
              [-37.525833332042623, -7.840833325897222],
              [-37.525833332941943, -7.841666659288876],
              [-37.526666668132179, -7.841666659288876],
              [-37.52666666543422, -7.840833325897222],
              [-37.529166666508388, -7.840833325897222],
              [-37.529166665609068, -7.841666659288876],
              [-37.530833331493, -7.841666659288876],
              [-37.530833334190959, -7.84333332607207],
              [-37.531666666683293, -7.84333332517275],
              [-37.531666666683293, -7.844166657665085],
              [-37.533333332567224, -7.844166659463724],
              [-37.533333333466544, -7.844999991956001],
              [-37.534999999350418, -7.844999992855321],
              [-37.534999998451099, -7.845833326246975],
              [-37.535833331842753, -7.845833326246975],
              [-37.535833331842753, -7.846666658739252],
              [-37.537499999525323, -7.846666658739252],
              [-37.537500000424643, -7.847499993030226],
              [-37.539166667207894, -7.847499993030226],
              [-37.539166668107214, -7.848333326421823],
              [-37.539999999700171, -7.848333326421823],
              [-37.539999998800852, -7.849999993205074],
              [-37.540833333091825, -7.849999993205074],
              [-37.540833332192506, -7.851666659089005],
              [-37.541666669181382, -7.851666659988325],
              [-37.541666666483422, -7.858333326221953],
              [-37.542499998975757, -7.858333326221953],
              [-37.542499998975757, -7.859166658714287],
              [-37.543333332367354, -7.859166658714287],
              [-37.543333332367354, -7.862499993180109],
              [-37.545000000949244, -7.862499993180109],
              [-37.545000000949244, -7.863333325672386],
              [-37.545833332542202, -7.863333327471025],
              [-37.545833333441522, -7.86416665816472],
              [-37.549999998601038, -7.86416665906404],
              [-37.549999999500358, -7.864999993354957],
              [-37.550833332891955, -7.864999993354957],
              [-37.550833331992635, -7.865833324947914],
              [-37.551666665384232, -7.865833325847291],
              [-37.551666665384232, -7.864999994254276],
              [-37.555833334141028, -7.864999994254276],
              [-37.555833335040347, -7.866666659238888],
              [-37.556666665733985, -7.866666659238888],
              [-37.556666667532625, -7.867499993529805],
              [-37.557499999125639, -7.867499993529805],
              [-37.557499999125639, -7.868333326022139],
              [-37.55916666770753, -7.868333325122819],
              [-37.559166665908833, -7.869166659413736],
              [-37.562500000374655, -7.869166659413736],
              [-37.562499999475335, -7.870833326196987],
              [-37.56333333106835, -7.870833326196987],
              [-37.563333332866989, -7.871666658689321],
              [-37.564999997851601, -7.871666658689321],
              [-37.56499999875092, -7.872499992980238],
              [-37.565833332142518, -7.872499992980238],
              [-37.565833332142518, -7.874166659763489],
              [-37.566666665534171, -7.874166658864169],
              [-37.566666665534171, -7.874999993155086],
              [-37.568333331418046, -7.874999993155086],
              [-37.568333333216685, -7.87583332564742],
              [-37.56916666570902, -7.87583332564742],
              [-37.569166666608339, -7.876666659938337],
              [-37.573333333566438, -7.876666659039017],
              [-37.573333331767799, -7.874999993155086],
              [-37.574166667857412, -7.874999993155086],
              [-37.574166667857412, -7.873333326371892],
              [-37.57499999945037, -7.873333326371892],
              [-37.57499999855105, -7.872499992980238],
              [-37.575833333741343, -7.872499992080918],
              [-37.575833332841967, -7.870833327096307],
              [-37.58083333319172, -7.870833326196987],
              [-37.58083333319172, -7.871666658689321],
              [-37.583333332467248, -7.871666658689321],
              [-37.583333332467248, -7.874166659763489],
              [-37.584166667657541, -7.874166658864169],
              [-37.584166666758222, -7.874999993155086],
              [-37.584166666758222, -7.877499993329991],
              [-37.583333333366625, -7.877499994229311],
              [-37.583333332467248, -7.879166659213922],
              [-37.582499999075651, -7.879166659213922],
              [-37.582499999075651, -7.882499992780367],
              [-37.581666665684054, -7.882499992780367],
              [-37.581666667482693, -7.885833326346869],
              [-37.58083333319172, -7.885833326346869],
              [-37.58083333319172, -7.888333326521774],
              [-37.579999999800123, -7.888333326521774],
              [-37.579999998900803, -7.889999993305025],
              [-37.579166666408469, -7.889999994204345],
              [-37.579166665509149, -7.8916666591889],
              [-37.578333334815511, -7.8916666591889],
              [-37.578333333016872, -7.892499993479873],
              [-37.577499999625218, -7.892499994379193],
              [-37.577499999625218, -7.897499992930307],
              [-37.57666666713294, -7.897499992930307],
              [-37.57666666803226, -7.898333326321904],
              [-37.57499999945037, -7.898333326321904],
              [-37.57499999945037, -7.899166658814181],
              [-37.574166666058773, -7.899166658814181],
              [-37.574166666958092, -7.899999993105155],
              [-37.572500001074161, -7.899999993105155],
              [-37.572499997476882, -7.900833324698112],
              [-37.57083333339159, -7.900833326496752],
              [-37.570833332492271, -7.901666659888406],
              [-37.57, -7.901666658989086],
              [-37.569999999100617, -7.904999993454908],
              [-37.570833332492271, -7.904999993454908],
              [-37.570833332492271, -7.908333326122033],
              [-37.56916666570902, -7.908333326122033],
              [-37.56916666570902, -7.909999992005964],
              [-37.568333333216685, -7.909999992905284],
              [-37.568333333216685, -7.911666659688535],
              [-37.567499999825088, -7.911666658789215],
              [-37.567499998925769, -7.91416665896412],
              [-37.565833333041837, -7.91416665896412],
              [-37.565833333041837, -7.914999993255037],
              [-37.56500000144888, -7.914999993255037],
              [-37.56500000054956, -7.916666659138968],
              [-37.564166665359267, -7.916666659138968],
              [-37.564166667157906, -7.91833332592222],
              [-37.562499998576016, -7.91833332592222],
              [-37.562499998576016, -7.919166659313817],
              [-37.561666666083738, -7.919166659313817],
              [-37.561666667882378, -7.920833326097068],
              [-37.560833332692084, -7.920833326097068],
              [-37.560833331792765, -7.921666659488722],
              [-37.559999998401167, -7.921666659488722],
              [-37.560000001998446, -7.922499992880319],
              [-37.559166665908833, -7.922499992880319],
              [-37.55916666770753, -7.92416665876425],
              [-37.557500000024959, -7.92416665876425],
              [-37.557499999125639, -7.924999993055167],
              [-37.556666665733985, -7.924999993954486],
              [-37.556666666633305, -7.925833326446821],
              [-37.555833331443068, -7.925833326446821],
              [-37.555833331443068, -7.926666658939098],
              [-37.554999998950734, -7.926666658939098],
              [-37.555000000749374, -7.927499993230072],
              [-37.554999999850054, -7.929166659114003],
              [-37.557500000024959, -7.929166659114003],
              [-37.557500000924279, -7.92999999340492],
              [-37.558333330718597, -7.92999999340492],
              [-37.558333333416556, -7.930833325897254],
              [-37.559166665908833, -7.930833326796574],
              [-37.55916666770753, -7.932499992680448],
              [-37.560000000199807, -7.932499991781128],
              [-37.560000000199807, -7.933333326072102],
              [-37.561666667882378, -7.933333326971422],
              [-37.561666667882378, -7.93416665766506],
              [-37.562499998576016, -7.934166659463699],
              [-37.562499998576016, -7.934999992855353],
              [-37.564166665359267, -7.934999992855353],
              [-37.564166668057226, -7.93583332624695],
              [-37.563333332866989, -7.93583332624695],
              [-37.563333333766309, -7.936666658739284],
              [-37.562499999475335, -7.936666658739284],
              [-37.562500000374655, -7.938333327321175],
              [-37.560000001099127, -7.938333326421855],
              [-37.559999998401167, -7.939166658914132],
              [-37.555833331443068, -7.939166658914132],
              [-37.555833332342388, -7.940833324798064],
              [-37.554999998950734, -7.940833325697383],
              [-37.554999999850054, -7.94166665908898],
              [-37.554166665559137, -7.94166665908898],
              [-37.554166666458457, -7.944999992655482],
              [-37.554999999850054, -7.944999992655482],
              [-37.554999999850054, -7.945833326047136],
              [-37.555833335040347, -7.945833326047136],
              [-37.555833334141028, -7.946666659438733],
              [-37.556666665733985, -7.946666659438733],
              [-37.556666667532625, -7.94749999283033],
              [-37.55916666770753, -7.94749999283033],
              [-37.55916666680821, -7.948333326221984],
              [-37.560000000199807, -7.948333326221984],
              [-37.560000000199807, -7.949166658714262],
              [-37.564166668057226, -7.949166658714262],
              [-37.564166667157906, -7.949999993005235],
              [-37.567499999825088, -7.949999993005235],
              [-37.567499998925769, -7.950833326396832],
              [-37.568333334116005, -7.950833326396832],
              [-37.568333334116005, -7.951666658889167],
              [-37.56916666570902, -7.951666658889167],
              [-37.569166666608339, -7.953333325672418],
              [-37.568333331418046, -7.953333325672418],
              [-37.568333333216685, -7.954166659064015],
              [-37.56916666570902, -7.954166658164695],
              [-37.56916666570902, -7.954999993354988],
              [-37.57083333339159, -7.954999993354988],
              [-37.570833331592951, -7.955833325847266],
              [-37.572499998376202, -7.955833325847266],
              [-37.572499998376202, -7.956666659238863],
              [-37.573333332667119, -7.956666659238863],
              [-37.573333332667119, -7.958333326022114],
              [-37.574166666958092, -7.958333326022114],
              [-37.574166666058773, -7.959166659413768],
              [-37.57499999855105, -7.959166658514448],
              [-37.57499999945037, -7.959999992805365],
              [-37.576666666233621, -7.959999992805365],
              [-37.576666664434981, -7.960833326197019],
              [-37.578333332117552, -7.960833326197019],
              [-37.578333334815511, -7.961666658689296],
              [-37.579166666408469, -7.961666660487936],
              [-37.579166665509149, -7.965833327446035],
              [-37.579999998900803, -7.965833325647395],
              [-37.579999998900803, -7.966666658139729],
              [-37.58083333319172, -7.966666659938369],
              [-37.58083333319172, -7.96833332492298],
              [-37.581666666583374, -7.9683333258223],
              [-37.581666666583374, -7.969166659213897],
              [-37.583333332467248, -7.969166659213897],
              [-37.583333332467248, -7.969999993504871],
              [-37.58666666603375, -7.969999991706231],
              [-37.58666666603375, -7.970833325997148],
              [-37.587500002123363, -7.970833325997148],
              [-37.587500001224043, -7.971666660288122],
              [-37.589166666208655, -7.971666659388745],
              [-37.589166667107975, -7.972499992780399],
              [-37.591666665484183, -7.972499992780399],
              [-37.591666668182143, -7.971666659388745],
              [-37.593333334965394, -7.971666659388745],
              [-37.593333334066074, -7.972499992780399],
              [-37.599999998501062, -7.972499992780399],
              [-37.599999998501062, -7.973333326171996],
              [-37.601666668881649, -7.973333326171996],
              [-37.601666666183633, -7.972499992780399],
              [-37.602499999575286, -7.972499992780399],
              [-37.602500000474606, -7.971666659388745],
              [-37.603333332067564, -7.971666659388745],
              [-37.603333332966884, -7.970833326896468],
              [-37.604166666358537, -7.970833325997148],
              [-37.604166668157177, -7.969166658314577],
              [-37.604999999750135, -7.969166659213897],
              [-37.605000000649454, -7.970833325997148],
              [-37.605833334940428, -7.970833325997148],
              [-37.605833331343149, -7.971666659388745],
              [-37.606666665634066, -7.971666659388745],
              [-37.606666667432705, -7.970833326896468],
              [-37.610000000999207, -7.970833325097828],
              [-37.609999999200568, -7.971666659388745],
              [-37.612499998476096, -7.971666660288122],
              [-37.612499999375416, -7.972499993679719],
              [-37.615833333841238, -7.972499992780399],
              [-37.615833332042598, -7.971666658489426],
              [-37.617499999725169, -7.971666659388745],
              [-37.617500000624489, -7.970833325997148],
              [-37.618333332217446, -7.970833325097828],
              [-37.618333334016086, -7.973333326171996],
              [-37.620833331493031, -7.973333326171996],
              [-37.620833332392351, -7.97416665866433],
              [-37.622499999175602, -7.97416665866433],
              [-37.622500000074922, -7.977499993130152],
              [-37.623333332567199, -7.977499993130152],
              [-37.623333332567199, -7.979999993305],
              [-37.624166665958796, -7.979999993305],
              [-37.624166666858173, -7.982499993479848],
              [-37.62333333166788, -7.982499991681209],
              [-37.623333332567199, -7.983333325972183],
              [-37.622500000074922, -7.983333325972183],
              [-37.622500000074922, -7.98416665936378],
              [-37.623333333466519, -7.98416665936378],
              [-37.62333333166788, -7.984999992755434],
              [-37.622500000974242, -7.984999992755434],
              [-37.622500000974242, -7.990833324698144],
              [-37.621666667582588, -7.990833326496784],
              [-37.621666665783948, -7.996666659338814],
              [-37.622500001873561, -7.996666659338814],
              [-37.622499997376906, -7.997499991831091],
              [-37.62333333166788, -7.997499994529051],
              [-37.623333332567199, -7.998333326122065],
              [-37.626666667033021, -7.998333326122065],
              [-37.626666667033021, -7.997499992730411],
              [-37.627499998625979, -7.997499992730411],
              [-37.627499999525298, -7.996666659338814],
              [-37.626666666133701, -7.996666659338814],
              [-37.626666666133701, -7.99583332594716],
              [-37.627499999525298, -7.99583332594716],
              [-37.627499998625979, -7.994999993454883],
              [-37.629166667207869, -7.994999994354202],
              [-37.62916666540923, -7.992499993280035],
              [-37.629999998800884, -7.992499993280035],
              [-37.629999998800884, -7.990833326496784],
              [-37.6308333330918, -7.990833326496784],
              [-37.630833332192481, -7.991666658989061],
              [-37.631666665584135, -7.991666658989061],
              [-37.631666665584135, -7.993333325772312],
              [-37.632500000774371, -7.993333325772312],
              [-37.632500000774371, -7.994166659163966],
              [-37.631666666483454, -7.994166659163966],
              [-37.631666666483454, -7.996666659338814],
              [-37.63083333399112, -7.996666659338814],
              [-37.630833330393841, -7.999166659513662],
              [-37.631666665584135, -7.999166659513662],
              [-37.631666667382774, -7.999999992905316],
              [-37.632500000774371, -7.999999992905316],
              [-37.632499998076412, -8.000833326296913],
              [-37.633333332367329, -8.000833326296913],
              [-37.633333332367329, -8.001666658789247],
              [-37.634166666658302, -8.001666658789247],
              [-37.634166667557622, -8.002499993080164],
              [-37.633333331468009, -8.002499993979484],
              [-37.633333331468009, -8.003333326471818],
              [-37.634166665758983, -8.003333326471818],
              [-37.634166665758983, -8.004166658964095],
              [-37.635000000049899, -8.004166658964095],
              [-37.635000000949219, -8.004999993255012],
              [-37.635833332542234, -8.004999994154332],
              [-37.635833333441553, -8.005833324848027],
              [-37.63666666773247, -8.005833325747346],
              [-37.63666666773247, -8.006666659138943],
              [-37.63666666773247, -8.007499993429917],
              [-37.635833331642914, -8.007499993429917],
              [-37.635833331642914, -8.009166659313848],
              [-37.633333332367329, -8.009166659313848],
              [-37.633333332367329, -8.008333325922194],
              [-37.631666667382774, -8.008333327720834],
              [-37.631666666483454, -8.009999992705445],
              [-37.629166667207869, -8.009999992705445],
              [-37.629166668107189, -8.009166659313848],
              [-37.626666666133701, -8.009166660213168],
              [-37.626666667932341, -8.008333325922194],
              [-37.625833332742047, -8.008333326821514],
              [-37.625833332742047, -8.009166658414529],
              [-37.62499999935045, -8.009166659313848],
              [-37.62500000114909, -8.009999992705445],
              [-37.624166665958796, -8.009999992705445],
              [-37.624166667757493, -8.010833326097099],
              [-37.622500000074922, -8.010833326097099],
              [-37.622500000974242, -8.011666659488696],
              [-37.619999999000697, -8.011666657690057],
              [-37.620000000799337, -8.013333326271947],
              [-37.61916666650842, -8.013333327171267],
              [-37.61916666740774, -8.014166658764225],
              [-37.6191666656091, -8.015833324648156],
              [-37.619999999900017, -8.015833324648156],
              [-37.619999999900017, -8.01666665893913],
              [-37.620833332392351, -8.01666665893913],
              [-37.620833332392351, -8.017499993230047],
              [-37.622499999175602, -8.017499992330727],
              [-37.622499999175602, -8.018333325722381],
              [-37.62333333166788, -8.018333325722381],
              [-37.62333333166788, -8.019166659113978],
              [-37.624166665958796, -8.019166658214658],
              [-37.624166666858173, -8.019999993404952],
              [-37.624999998451131, -8.019999993404952],
              [-37.62499999935045, -8.020833325897229],
              [-37.625833332742047, -8.020833325897229],
              [-37.625833331842728, -8.021666659288826],
              [-37.628333332916952, -8.021666659288826],
              [-37.628333332017633, -8.02249999268048],
              [-37.62916666540923, -8.022499994479119],
              [-37.62916666540923, -8.023333326072077],
              [-37.630833332192481, -8.023333326072077],
              [-37.630833332192481, -8.024166659463731],
              [-37.631666665584135, -8.024166659463731],
              [-37.631666665584135, -8.024999992855328],
              [-37.632500000774371, -8.024999992855328],
              [-37.632499998975732, -8.027499993030233],
              [-37.633333334165968, -8.027499993030233],
              [-37.633333331468009, -8.029166658914107],
              [-37.634166667557622, -8.029166658914107],
              [-37.634166666658302, -8.031666659089012],
              [-37.63499999915058, -8.031666659988332],
              [-37.635000000049899, -8.032499993379929],
              [-37.635833332542234, -8.032499994279249],
              [-37.635833331642914, -8.03416665926386],
              [-37.63666666773247, -8.034166658364541],
              [-37.63666666683315, -8.034999991756195],
              [-37.637500001124124, -8.034999992655514],
              [-37.637500001124124, -8.035833326047111],
              [-37.639166666108736, -8.035833326047111],
              [-37.639166666108736, -8.036666659438708],
              [-37.640000000399652, -8.036666659438708],
              [-37.639999998601013, -8.037499992830362],
              [-37.641666668082223, -8.037499993729682],
              [-37.641666665384264, -8.038333326221959],
              [-37.642499999675181, -8.038333326221959],
              [-37.642499999675181, -8.039166658714294],
              [-37.643333333966154, -8.039166658714294],
              [-37.643333333966154, -8.03999999390453],
              [-37.644166667357752, -8.03999999300521],
              [-37.644166665559112, -8.040833327296184],
              [-37.644999998950766, -8.040833326396864],
              [-37.644999998950766, -8.041666659788461],
              [-37.645833331443043, -8.041666658889142],
              [-37.645833331443043, -8.042499993180115],
              [-37.646666665734017, -8.042499993180115],
              [-37.646666667532656, -8.043333325672393],
              [-37.647499999125614, -8.043333325672393],
              [-37.648333333416588, -8.043333325672393],
              [-37.648333331617891, -8.04416665906399],
              [-37.650000001099102, -8.04416665906399],
              [-37.650000001099102, -8.044999993354963],
              [-37.656666666433466, -8.044999993354963],
              [-37.656666665534146, -8.045833325847241],
              [-37.660833332492246, -8.045833325847241],
              [-37.660833333391565, -8.046666659238895],
              [-37.664999999450345, -8.046666659238895],
              [-37.665000000349721, -8.045833325847241],
              [-37.665833331043359, -8.045833325847241],
              [-37.665833333741318, -8.044166659963366],
              [-37.666666667132915, -8.04416665906399],
              [-37.666666667132915, -8.044999994254283],
              [-37.669999998900778, -8.044999993354963],
              [-37.669999998900778, -8.045833325847241],
              [-37.671666667482668, -8.045833325847241],
              [-37.671666666583349, -8.044999993354963],
              [-37.672500000874322, -8.044999993354963],
              [-37.672499999975003, -8.043333325672393],
              [-37.67333333156796, -8.043333325672393],
              [-37.67333333246728, -8.042499994079435],
              [-37.675000000149851, -8.042499993180115],
              [-37.675000000149851, -8.043333325672393],
              [-37.675833332642128, -8.043333325672393],
              [-37.675833332642128, -8.04416665906399],
              [-37.676666666933102, -8.04416665906399],
              [-37.676666666933102, -8.044999993354963],
              [-37.677500001224018, -8.044999993354963],
              [-37.677499998526059, -8.045833325847241],
              [-37.680833332092561, -8.045833325847241],
              [-37.680833332092561, -8.043333324773073],
              [-37.68333333136809, -8.043333325672393],
              [-37.683333333166729, -8.042499993180115],
              [-37.684166666558383, -8.042499993180115],
              [-37.684166666558383, -8.040833326396864],
              [-37.68499999905066, -8.040833326396864],
              [-37.68499999994998, -8.03999999300521],
              [-37.684166666558383, -8.03999999300521],
              [-37.684166665659063, -8.038333326221959],
              [-37.68499999905066, -8.038333327121279],
              [-37.68499999905066, -8.035833326047111],
              [-37.685833331542995, -8.035833326047111],
              [-37.685833333341634, -8.034999992655514],
              [-37.686666667632551, -8.034999994454154],
              [-37.686666668531871, -8.033333325872263],
              [-37.69083333279201, -8.033333325872263],
              [-37.69083333279201, -8.031666659089012],
              [-37.691666667982304, -8.031666659089012],
              [-37.691666666183664, -8.029999993205081],
              [-37.693333332067596, -8.029999993205081],
              [-37.693333332067596, -8.029166658914107],
              [-37.694999999750166, -8.029166658914107],
              [-37.694999999750166, -8.029999993205081],
              [-37.698333334215931, -8.029999993205081],
              [-37.698333331517972, -8.02833332552251],
              [-37.699166666708265, -8.02833332642183],
              [-37.699166665808946, -8.027499993929553],
              [-37.700000000999182, -8.027499993030233],
              [-37.700000000099863, -8.026666658739259],
              [-37.700833331692877, -8.026666658739259],
              [-37.700833333491516, -8.025833326246982],
              [-37.701666666883114, -8.025833327146302],
              [-37.701666667782433, -8.024999992855328],
              [-37.702500001174087, -8.024999992855328],
              [-37.702499998476128, -8.019999993404952],
              [-37.700833331692877, -8.019999994304271],
              [-37.700833332592197, -8.018333325722381],
              [-37.699999999200543, -8.018333325722381],
              [-37.700000000999182, -8.017499993230047],
              [-37.707499998825824, -8.017499993230047],
              [-37.707499999725144, -8.01833332752102],
              [-37.709166667407715, -8.018333325722381],
              [-37.709166666508395, -8.01666665893913],
              [-37.710000001698688, -8.01666665893913],
              [-37.709999999900049, -8.015833326446796],
              [-37.710833331493006, -8.015833324648156],
              [-37.710833334190966, -8.014999993055199],
              [-37.71166666578398, -8.014999993055199],
              [-37.7116666666833, -8.013333326271947],
              [-37.712500000974217, -8.013333326271947],
              [-37.712500000074897, -8.012499991980974],
              [-37.713333332567174, -8.01249999377967],
              [-37.713333331667855, -8.011666660388016],
              [-37.714166667757468, -8.011666659488696],
              [-37.714166667757468, -8.010833326097099],
              [-37.714999999350425, -8.010833326097099],
              [-37.714999998451106, -8.009999994504085],
              [-37.718333332916927, -8.009999990906806],
              [-37.718333332017608, -8.010833326097099],
              [-37.720000000599498, -8.010833326097099],
              [-37.719999999700178, -8.011666657690057],
              [-37.720833332192456, -8.011666657690057],
              [-37.720833332192456, -8.012499992880294],
              [-37.721666666483429, -8.012499992880294],
              [-37.721666666483429, -8.013333326271947],
              [-37.722499998975707, -8.013333326271947],
              [-37.722500000774346, -8.014166658764225],
              [-37.723333332367361, -8.014166656965585],
              [-37.723333331468041, -8.014999992155879],
              [-37.725000000949251, -8.014999993954518],
              [-37.725000000049931, -8.014166658764225],
              [-37.728333331817794, -8.014166658764225],
              [-37.728333331817794, -8.014999993055199],
              [-37.730000000399627, -8.014999993954518],
              [-37.729999998600988, -8.014166658764225],
              [-37.73333333306681, -8.014166657864905],
              [-37.733333333966129, -8.014999993954518],
              [-37.734166666458464, -8.014999993055199],
              [-37.734166666458464, -8.015833324648156],
              [-37.735833331443075, -8.015833326446796],
              [-37.735833334141034, -8.01666665803981],
              [-37.73916666590884, -8.01666665893913],
              [-37.73916666590884, -8.017499993230047],
              [-37.739999998401174, -8.017499992330727],
              [-37.740000000199814, -8.018333324823061],
              [-37.740833330893452, -8.018333325722381],
              [-37.740833331792771, -8.019166659113978],
              [-37.741666666983065, -8.019166657315338],
              [-37.741666667882384, -8.019999992505575],
              [-37.744166665359273, -8.019999993404952],
              [-37.744166667157913, -8.021666659288826],
              [-37.746666668232137, -8.021666659288826],
              [-37.750000001798583, -8.021666659288826],
              [-37.749999999100623, -8.020833324997909],
              [-37.751666667682514, -8.020833325897229],
              [-37.751666666783194, -8.021666658389506],
              [-37.754999998551057, -8.021666658389506],
              [-37.754999998551057, -8.020833325897229],
              [-37.755833333741293, -8.020833324997909],
              [-37.755833333741293, -8.019999993404952],
              [-37.756666667132947, -8.019999994304271],
              [-37.756666664434988, -8.018333324823061],
              [-37.758333333916198, -8.018333325722381],
              [-37.758333333016878, -8.017499993230047],
              [-37.759166666408476, -8.017499993230047],
              [-37.759166668207115, -8.01666665893913],
              [-37.75999999890081, -8.01666665893913],
              [-37.760000000699449, -8.015833326446796],
              [-37.760833334990366, -8.015833327346115],
              [-37.760833334091046, -8.014999992155879],
              [-37.763333331567935, -8.014999992155879],
              [-37.763333333366575, -8.015833326446796],
              [-37.764166665858909, -8.015833324648156],
              [-37.764166665858909, -8.01666665803981],
              [-37.76750000122405, -8.01666665893913],
              [-37.767499999425411, -8.017499994129366],
              [-37.768333332817008, -8.017499992330727],
              [-37.768333331917688, -8.018333325722381],
              [-37.770833332991856, -8.018333324823061],
              [-37.770833333891176, -8.019166659113978],
              [-37.772499998875787, -8.019166658214658],
              [-37.772500000674427, -8.020833325897229],
              [-37.773333331368121, -8.020833325897229],
              [-37.773333334066081, -8.021666658389506],
              [-37.774166665659038, -8.021666659288826],
              [-37.774166666558358, -8.02249999268048],
              [-37.77750000012486, -8.02249999268048],
              [-37.777500001923499, -8.023333326072077],
              [-37.781666667082959, -8.023333326072077],
              [-37.781666667982279, -8.024999992855328],
              [-37.782500000474613, -8.024999992855328],
              [-37.782499998675974, -8.025833326246982],
              [-37.783333332067571, -8.025833326246982],
              [-37.78333333386621, -8.02833332732115],
              [-37.784166666358544, -8.02833332642183],
              [-37.784166665459225, -8.032499993379929],
              [-37.783333332067571, -8.032499993379929],
              [-37.78333333386621, -8.033333325872263],
              [-37.782500000474613, -8.033333325872263],
              [-37.782499997776654, -8.03416665926386],
              [-37.783333332067571, -8.03416665926386],
              [-37.783333332067571, -8.037499992830362],
              [-37.784166665459225, -8.037499992830362],
              [-37.784166668157184, -8.039166658714294],
              [-37.783333332067571, -8.039166658714294],
              [-37.78333333296689, -8.041666658889142],
              [-37.784166665459225, -8.041666658889142],
              [-37.784166666358544, -8.042499992280796],
              [-37.789999999200575, -8.042499993180115],
              [-37.789999999200575, -8.041666658889142],
              [-37.791666665983826, -8.041666657989822],
              [-37.791666665983826, -8.042499993180115],
              [-37.792500001174062, -8.042499993180115],
              [-37.792499999375423, -8.044999993354963],
              [-37.793333333666396, -8.044999994254283],
              [-37.793333332767077, -8.046666659238895],
              [-37.794166666158674, -8.046666658339575],
              [-37.794166667957313, -8.054166657964856],
              [-37.793333333666396, -8.054166658864176],
              [-37.7933333318677, -8.055833325647427],
              [-37.794166667057993, -8.055833325647427],
              [-37.794166667057993, -8.058333324922955],
              [-37.794999998650951, -8.058333325822275],
              [-37.795000000449591, -8.059166658314609],
              [-37.794166666158674, -8.059166659213929],
              [-37.794166666158674, -8.059999993504846],
              [-37.795000000449591, -8.059999993504846],
              [-37.794999998650951, -8.064999992955279],
              [-37.795833333841244, -8.064999992955279],
              [-37.795833332941925, -8.06666665973853],
              [-37.796666665434202, -8.06666665883921],
              [-37.796666665434202, -8.068333324723085],
              [-37.797500000624495, -8.068333326521781],
              [-37.797499998825856, -8.069166659014058],
              [-37.798333334016093, -8.069166659014058],
              [-37.798333333116773, -8.069999993304975],
              [-37.799166665609107, -8.069999993304975],
              [-37.799166665609107, -8.070833325797309],
              [-37.799999999000704, -8.070833325797309],
              [-37.800000000799344, -8.071666658289587],
              [-37.800833331493038, -8.071666659188907],
              [-37.800833334190997, -8.07249999347988],
              [-37.801666665783955, -8.07249999347988],
              [-37.801666666683275, -8.074166659363812],
              [-37.802500000974248, -8.074166659363812],
              [-37.802500001873568, -8.076666660437979],
              [-37.801666667582595, -8.07666665863934],
              [-37.801666666683275, -8.078333326321911],
              [-37.802499999175552, -8.078333326321911],
              [-37.802500000974248, -8.079999994004481],
              [-37.803333334365846, -8.079999993105162],
              [-37.803333331667886, -8.080833326496759],
              [-37.804999998451137, -8.080833326496759],
              [-37.804999999350457, -8.081666658989093],
              [-37.808333334715599, -8.081666658989093],
              [-37.809166667207876, -8.081666658989093],
              [-37.809166668107196, -8.080833326496759],
              [-37.809999998800834, -8.080833326496759],
              [-37.809999998800834, -8.079999993105162],
              [-37.811666666483404, -8.079999993105162],
              [-37.811666666483404, -8.079166658814188],
              [-37.813333332367336, -8.079166658814188],
              [-37.813333331468016, -8.078333326321911],
              [-37.814166665758989, -8.078333326321911],
              [-37.814166665758989, -8.07666665863934],
              [-37.815000000949226, -8.07666665863934],
              [-37.815000001848546, -8.075833327046382],
              [-37.81583333254224, -8.075833326147063],
              [-37.815833331642921, -8.07666665863934],
              [-37.816666666833157, -8.07666665863934],
              [-37.816666666833157, -8.077499992930257],
              [-37.820000000399659, -8.077499992930257],
              [-37.81999999860102, -8.078333326321911],
              [-37.820833331093297, -8.078333326321911],
              [-37.820833333791256, -8.076666660437979],
              [-37.82166666718291, -8.07666665863934],
              [-37.82166666808223, -8.075833326147063],
              [-37.822499999675188, -8.075833326147063],
              [-37.822499999675188, -8.074166659363812],
              [-37.824166666458439, -8.074166659363812],
              [-37.824166665559119, -8.074999992755409],
              [-37.826666666633344, -8.074999993654728],
              [-37.826666666633344, -8.074166659363812],
              [-37.829166665908872, -8.074166659363812],
              [-37.829166666808192, -8.073333325972158],
              [-37.830833333591443, -8.073333325972158],
              [-37.830833332692123, -8.07249999347988],
              [-37.831666667882359, -8.07249999347988],
              [-37.831666667882359, -8.073333325972158],
              [-37.832499999475374, -8.073333325972158],
              [-37.832499998576054, -8.078333326321911],
              [-37.833333333766291, -8.078333325422591],
              [-37.833333331967651, -8.079166658814188],
              [-37.834166667157945, -8.079166658814188],
              [-37.834166668057264, -8.078333326321911],
              [-37.838333334116044, -8.078333326321911],
              [-37.838333333216724, -8.079166658814188],
              [-37.839166666608321, -8.079166658814188],
              [-37.839166665709001, -8.079999993105162],
              [-37.840833331592933, -8.079999994004481],
              [-37.840833331592933, -8.080833324698119],
              [-37.841666665883906, -8.080833326496759],
              [-37.841666666783226, -8.081666658089773],
              [-37.847499998725937, -8.081666658989093],
              [-37.847500000524576, -8.08249999238069],
              [-37.848333333016853, -8.08249999328001],
              [-37.848333332117534, -8.083333324873024],
              [-37.849999998900785, -8.083333325772344],
              [-37.850000000699424, -8.08249999328001],
              [-37.850833334091078, -8.08249999328001],
              [-37.851666665684036, -8.08249999238069],
              [-37.851666666583355, -8.081666658989093],
              [-37.852500000874272, -8.081666659888413],
              [-37.852499999974953, -8.080833326496759],
              [-37.853333332467287, -8.080833326496759],
              [-37.853333331567967, -8.079999992205842],
              [-37.854166665858884, -8.079999993105162],
              [-37.854166667657523, -8.079166658814188],
              [-37.859166667107957, -8.079166658814188],
              [-37.859166667107957, -8.079999993105162],
              [-37.859999999600234, -8.079999993105162],
              [-37.860833332092568, -8.079999993105162],
              [-37.860833332991888, -8.080833326496759],
              [-37.863333333166736, -8.080833326496759],
              [-37.863333331368096, -8.079999994004481],
              [-37.86416666745771, -8.079999993105162],
              [-37.86416666745771, -8.079166658814188],
              [-37.866666665833918, -8.079166658814188],
              [-37.866666667632558, -8.078333326321911],
              [-37.870833332792017, -8.07833332722123],
              [-37.870833332792017, -8.077499992930257],
              [-37.8741666654592, -8.077499992930257],
              [-37.874166668157159, -8.074999992755409],
              [-37.873333332966922, -8.074999992755409],
              [-37.873333332067602, -8.074166658464492],
              [-37.874166666358519, -8.074166659363812],
              [-37.874166666358519, -8.073333325972158],
              [-37.876666665634048, -8.073333325972158],
              [-37.876666665634048, -8.074166659363812],
              [-37.877499999925021, -8.074166659363812],
              [-37.877499999925021, -8.073333326871477],
              [-37.883333333666371, -8.073333325972158],
              [-37.883333331867732, -8.074166659363812],
              [-37.884999998650983, -8.074166659363812],
              [-37.885000000449622, -8.074999992755409],
              [-37.885833333841219, -8.074999992755409],
              [-37.88583333204258, -8.078333326321911],
              [-37.886666667232873, -8.078333326321911],
              [-37.886666665434234, -8.079166657914868],
              [-37.887499999725151, -8.079166657914868],
              [-37.887499998825831, -8.079999993105162],
              [-37.888333333116805, -8.079999993105162],
              [-37.888333333116805, -8.081666658989093],
              [-37.887499999725151, -8.081666658989093],
              [-37.887499999725151, -8.083333325772344],
              [-37.886666665434234, -8.083333325772344],
              [-37.886666668132193, -8.084166659163941],
              [-37.88583333204258, -8.084166659163941],
              [-37.88583333114326, -8.086666659338789],
              [-37.886666667232873, -8.086666659338789],
              [-37.886666668132193, -8.08833332612204],
              [-37.88583333204258, -8.08833332702136],
              [-37.88583333204258, -8.089166659513694],
              [-37.886666667232873, -8.089166659513694],
              [-37.886666667232873, -8.089999992905291],
              [-37.887499999725151, -8.089999993804611],
              [-37.887499999725151, -8.091666658789222],
              [-37.888333333116805, -8.091666658789222],
              [-37.888333334016124, -8.093333326471793],
              [-37.889166667407721, -8.093333326471793],
              [-37.889166665609082, -8.09416665896407],
              [-37.890000000799375, -8.09416665896407],
              [-37.890000000799375, -8.095833326646641],
              [-37.890833334190972, -8.095833325747321],
              [-37.890833332392333, -8.097499993429892],
              [-37.89166666668325, -8.097499993429892],
              [-37.89166666668325, -8.099166659313823],
              [-37.892500000074904, -8.099166659313823],
              [-37.892500000974223, -8.100833326097074],
              [-37.894999997551793, -8.100833326996394],
              [-37.894999999350432, -8.103333326271922],
              [-37.895833332742086, -8.103333325372603],
              [-37.895833331842766, -8.105833326446827],
              [-37.896666666133683, -8.105833327346147],
              [-37.896666667033003, -8.107499993230078],
              [-37.897500000424657, -8.107499992330759],
              [-37.897499998626017, -8.108333324823036],
              [-37.896666667932323, -8.108333325722356],
              [-37.896666667932323, -8.11499999285536],
              [-37.897499999525337, -8.11499999285536],
              [-37.897499999525337, -8.117499993030208],
              [-37.898333332916934, -8.117499993030208],
              [-37.898333332017614, -8.118333326421805],
              [-37.899166665409211, -8.118333326421805],
              [-37.899166668107227, -8.12083332569739],
              [-37.899999998800865, -8.12083332569739],
              [-37.899999998800865, -8.123333325872238],
              [-37.900833333091782, -8.123333325872238],
              [-37.900833333091782, -8.129999992105923],
              [-37.900833332192462, -8.13083332549752],
              [-37.901666666483436, -8.13083332549752],
              [-37.901666665584116, -8.133333325672368],
              [-37.902499998975713, -8.133333325672368],
              [-37.902500000774353, -8.13666665923887],
              [-37.903333334166007, -8.13666665833955],
              [-37.903333331468048, -8.137499993529843],
              [-37.904166667557604, -8.137499993529843],
              [-37.904166665758964, -8.138333326022121],
              [-37.903333331468048, -8.138333326022121],
              [-37.903333332367367, -8.139999992805372],
              [-37.904166666658284, -8.139999992805372],
              [-37.904166666658284, -8.140833326197026],
              [-37.904999999150618, -8.140833326197026],
              [-37.904999999150618, -8.1424999920809],
              [-37.906666667732509, -8.142499993879539],
              [-37.906666665933869, -8.144166658864151],
              [-37.908333331817744, -8.144166658864151],
              [-37.908333332717064, -8.145833325647402],
              [-37.907499998426147, -8.145833325647402],
              [-37.907499998426147, -8.147499993329973],
              [-37.908333333616383, -8.147499993329973],
              [-37.908333333616383, -8.149999993504878],
              [-37.910833331992649, -8.149999993504878],
              [-37.910833331992649, -8.150833325097835],
              [-37.911666665384246, -8.150833325997155],
              [-37.911666668981525, -8.151666659388752],
              [-37.912500000574539, -8.151666659388752],
              [-37.912500000574539, -8.152499992780406],
              [-37.913333333966136, -8.152499992780406],
              [-37.913333333966136, -8.153333326172003],
              [-37.916666665733999, -8.153333326172003],
              [-37.916666666633319, -8.154166658664337],
              [-37.917500000924235, -8.154166658664337],
              [-37.917500000924235, -8.154999992955254],
              [-37.91833333161793, -8.154999992955254],
              [-37.91833333341657, -8.155833326346908],
              [-37.920000000199821, -8.155833326346908],
              [-37.920000000199821, -8.156666658839185],
              [-37.921666666983072, -8.156666657939866],
              [-37.921666667882391, -8.159166659014033],
              [-37.923333332867003, -8.159166659014033],
              [-37.923333332867003, -8.159999993305007],
              [-37.925000000549517, -8.159999993305007],
              [-37.924999999650197, -8.160833325797284],
              [-37.925833333041851, -8.160833324897965],
              [-37.925833333041851, -8.163333325972189],
              [-37.927499999825102, -8.163333325972189],
              [-37.927499999825102, -8.165833325247718],
              [-37.928333333216699, -8.165833327046357],
              [-37.928333333216699, -8.166666658639315],
              [-37.929166667507673, -8.166666658639315],
              [-37.929166666608353, -8.16916665881422],
              [-37.932499998376159, -8.16916665881422],
              [-37.932499998376159, -8.170833324698151],
              [-37.935000000349703, -8.170833326496791],
              [-37.934999998551064, -8.171666658989068],
              [-37.9358333337413, -8.171666658989068],
              [-37.9358333337413, -8.172499993280042],
              [-37.937500000524551, -8.172499993280042],
              [-37.937499998725912, -8.171666658989068],
              [-37.938333333916205, -8.171666658089748],
              [-37.938333333016885, -8.170833326496791],
              [-37.939166666408482, -8.17083332739611],
              [-37.939166668207122, -8.172499993280042],
              [-37.93999999890076, -8.172499993280042],
              [-37.939999999800079, -8.173333325772319],
              [-37.941666664784691, -8.173333330268918],
              [-37.943333332467262, -8.173333325772319],
              [-37.943333331567942, -8.174166659163916],
              [-37.944166666758235, -8.174166659163916],
              [-37.944166665858916, -8.17499999345489],
              [-37.945000000149832, -8.17499999345489],
              [-37.945000000149832, -8.175833325947167],
              [-37.946666666033764, -8.175833325947167],
              [-37.946666666033764, -8.176666659338821],
              [-37.949999999600266, -8.176666659338821],
              [-37.950833333891183, -8.176666659338821],
              [-37.950833332092543, -8.175833325947167],
              [-37.951666666383517, -8.175833325947167],
              [-37.951666668182156, -8.17499999345489],
              [-37.952499999775114, -8.17499999345489],
              [-37.952500000674434, -8.174166660063293],
              [-37.953333333166768, -8.174166659163916],
              [-37.953333333166768, -8.173333324872999],
              [-37.955833332442296, -8.173333325772319],
              [-37.955833333341616, -8.172499993280042],
              [-37.957499997426908, -8.172499994179361],
              [-37.957499999225547, -8.173333325772319],
              [-37.959166667807438, -8.173333325772319],
              [-37.959166668706757, -8.172499993280042],
              [-37.96083333099341, -8.172499993280042],
              [-37.960833332792049, -8.169999993105137],
              [-37.961666667082966, -8.169999993105137],
              [-37.961666667082966, -8.1691666579149],
              [-37.96249999867598, -8.1691666579149],
              [-37.9624999995753, -8.166666658639315],
              [-37.961666667082966, -8.166666658639315],
              [-37.961666667082966, -8.16499999275544],
              [-37.9624999995753, -8.16499999275544],
              [-37.96250000047462, -8.164166660263106],
              [-37.963333332067577, -8.164166659363786],
              [-37.963333333866217, -8.163333325972189],
              [-37.964166668157191, -8.163333325972189],
              [-37.964166665459175, -8.162499994379175],
              [-37.966666666533399, -8.162499993479855],
              [-37.966666665634079, -8.161666658289619],
              [-37.967499999924996, -8.161666659188938],
              [-37.967499999025677, -8.163333325972189],
              [-37.96833333421597, -8.163333325972189],
              [-37.96833333241733, -8.16499999275544],
              [-37.969166665808928, -8.16499999275544],
              [-37.969166665808928, -8.165833326147037],
              [-37.969999999200581, -8.165833326147037],
              [-37.970000000099901, -8.168333326321886],
              [-37.970833331692859, -8.168333326321886],
              [-37.970833332592179, -8.170833326496791],
              [-37.970000000099901, -8.170833326496791],
              [-37.969999997401942, -8.172499992380722],
              [-37.970833331692859, -8.172499994179361],
              [-37.970833332592179, -8.173333325772319],
              [-37.97249999847611, -8.173333325772319],
              [-37.97249999847611, -8.174166659163916],
              [-37.973333333666346, -8.174166660063293],
              [-37.973333332767027, -8.17499999345489],
              [-37.974166667058, -8.174999994354209],
              [-37.97416666795732, -8.175833325947167],
              [-37.975000000449597, -8.175833326846487],
              [-37.974999998650958, -8.176666659338821],
              [-37.975833333841251, -8.176666659338821],
              [-37.975833332042612, -8.177499992730418],
              [-37.977499999725183, -8.177499992730418],
              [-37.977500000624502, -8.178333326122072],
              [-37.97833333311678, -8.178333326122072],
              [-37.97833333311678, -8.179166660412989],
              [-37.979166666508434, -8.179166659513669],
              [-37.979166666508434, -8.179999992905323],
              [-37.979999999900031, -8.179999992905323],
              [-37.979999998101391, -8.1808333253976],
              [-37.980833331492988, -8.18083332629692],
              [-37.980833331492988, -8.181666658789197],
              [-37.982499999175559, -8.181666658789197],
              [-37.98416666595881, -8.181666658789197],
              [-37.98416666685813, -8.1808333253976],
              [-37.986666667932354, -8.18083332629692],
              [-37.986666664335075, -8.179999992905323],
              [-37.987499998625992, -8.179999992905323],
              [-37.987499999525312, -8.179166659513669],
              [-37.989166665409243, -8.179166659513669],
              [-37.989166668107202, -8.178333326122072],
              [-37.98999999970016, -8.178333326122072],
              [-37.99000000059948, -8.179166659513669],
              [-37.991666665584091, -8.179166660412989],
              [-37.991666667382731, -8.179999992905323],
              [-37.998333331817776, -8.179999992905323],
              [-37.998333332717095, -8.18083332629692],
              [-37.999166667008012, -8.18083332629692],
              [-37.999166667907332, -8.179999992905323],
              [-38.001666667182917, -8.179999992905323],
              [-38.001666665384278, -8.179166659513669],
              [-38.003333332167529, -8.179166659513669],
              [-38.003333333066848, -8.179999992905323],
              [-38.004166666458445, -8.179999992905323],
              [-38.004166665559126, -8.18083332629692],
              [-38.004999998950723, -8.18083332629692],
              [-38.004999999850043, -8.182499993979491],
              [-38.004166667357765, -8.182499993080171],
              [-38.004166665559126, -8.183333324673129],
              [-38.004166665559126, -8.188333325922201],
              [-38.003333332167529, -8.188333325922201],
              [-38.003333333066848, -8.189999992705452],
              [-38.002499999675194, -8.189999992705452],
              [-38.002499999675194, -8.190833326097049],
              [-38.003333333066848, -8.190833326097049],
              [-38.003333332167529, -8.1924999928803],
              [-38.004166665559126, -8.1924999928803],
              [-38.004166667357765, -8.193333326271954],
              [-38.005000000749419, -8.193333326271954],
              [-38.004999998950723, -8.196666658939137],
              [-38.005833332342377, -8.196666658039817],
              [-38.005833334141016, -8.198333325722331],
              [-38.006666666633294, -8.198333325722331],
              [-38.006666665733974, -8.199166660013304],
              [-38.005833332342377, -8.199166659113985],
              [-38.005833331443057, -8.203333326971403],
              [-38.006666667532613, -8.203333326072084],
              [-38.006666665733974, -8.206666658739266],
              [-38.005833331443057, -8.206666658739266],
              [-38.005833332342377, -8.207499993030183],
              [-38.006666666633294, -8.207499993030183],
              [-38.006666667532613, -8.209166658914114],
              [-38.014166665359255, -8.209166658914114],
              [-38.014166668057214, -8.209999993205088],
              [-38.015833332142506, -8.209999993205088],
              [-38.015833333041826, -8.209166658914114],
              [-38.017500000724397, -8.209166658914114],
              [-38.017499998925757, -8.208333326421837],
              [-38.018333331418091, -8.208333326421837],
              [-38.018333333216731, -8.206666658739266],
              [-38.020000000899302, -8.206666658739266],
              [-38.020000000899302, -8.205833326246989],
              [-38.021666665883856, -8.205833326246989],
              [-38.021666666783176, -8.204999992855335],
              [-38.024999998551039, -8.204999991956015],
              [-38.024999998551039, -8.204166659463738],
              [-38.026666668032249, -8.204166659463738],
              [-38.026666666233609, -8.203333326072084],
              [-38.027499999625263, -8.203333325172764],
              [-38.027500001423903, -8.201666659288833],
              [-38.026666667132929, -8.201666660188152],
              [-38.026666666233609, -8.199999992505582],
              [-38.027499998725943, -8.199999993404901],
              [-38.027499998725943, -8.198333325722331],
              [-38.026666668032249, -8.198333325722331],
              [-38.026666668032249, -8.197499993230053],
              [-38.027499997826567, -8.197499993230053],
              [-38.027499998725943, -8.194999993055205],
              [-38.02833333301686, -8.194999993055205],
              [-38.028333332117541, -8.193333326271954],
              [-38.027499999625263, -8.193333326271954],
              [-38.027499998725943, -8.192499991980981],
              [-38.028333331218221, -8.1924999928803],
              [-38.02833333301686, -8.190833326097049],
              [-38.029166666408457, -8.190833326097049],
              [-38.029166668207154, -8.189999992705452],
              [-38.031666666583362, -8.189999992705452],
              [-38.031666666583362, -8.189166659313855],
              [-38.032500000874279, -8.189166659313855],
              [-38.032500000874279, -8.188333325922201],
              [-38.033333332467294, -8.188333325922201],
              [-38.033333333366613, -8.187499993429924],
              [-38.03416666675821, -8.187499993429924],
              [-38.03416666765753, -8.18666665913895],
              [-38.035833331742822, -8.18666665913895],
              [-38.035833332642142, -8.185833325747353],
              [-38.037500001224032, -8.185833325747353],
              [-38.037500001224032, -8.184999993255019],
              [-38.039166664410004, -8.184999993255019],
              [-38.039166668007283, -8.184166658964102],
              [-38.043333333166743, -8.184166658964102],
              [-38.043333330468784, -8.183333326471768],
              [-38.044166665659077, -8.183333326471768],
              [-38.044166665659077, -8.182499992180851],
              [-38.044999999050674, -8.182499993979491],
              [-38.044999999050674, -8.181666658789197],
              [-38.054166664559887, -8.181666658789197],
              [-38.054166665459206, -8.18083332629692],
              [-38.054999998850803, -8.1808333253976],
              [-38.054999999750123, -8.181666658789197],
              [-38.057499999925028, -8.181666658789197],
              [-38.057500000824348, -8.18083332629692],
              [-38.060000001898516, -8.18083332629692],
              [-38.060000000099876, -8.181666657889878],
              [-38.060833331692834, -8.181666658789197],
              [-38.06083333349153, -8.183333326471768],
              [-38.061666666883127, -8.183333326471768],
              [-38.061666667782447, -8.188333325922201],
              [-38.062499998476085, -8.188333325922201],
              [-38.062499999375405, -8.189166659313855],
              [-38.063333331867739, -8.189166659313855],
              [-38.063333334565698, -8.189999992705452],
              [-38.064166667957295, -8.189999992705452],
              [-38.064166666158656, -8.191666659488703],
              [-38.062499999375405, -8.191666659488703],
              [-38.062499999375405, -8.195833326446802],
              [-38.064166667057975, -8.195833326446802],
              [-38.064166667057975, -8.197499993230053],
              [-38.06499999775167, -8.197499993230053],
              [-38.06499999865099, -8.199166659113985],
              [-38.063333331867739, -8.199166659113985],
              [-38.063333332767058, -8.201666659288833],
              [-38.061666667782447, -8.201666659288833],
              [-38.061666665084488, -8.202499992680487],
              [-38.06083333349153, -8.202499992680487],
              [-38.06083333259221, -8.205833326246989],
              [-38.060000000099876, -8.205833326246989],
              [-38.059999999200556, -8.206666658739266],
              [-38.058333334215945, -8.206666657839946],
              [-38.058333332417305, -8.210833325697365],
              [-38.059166667607599, -8.210833325697365],
              [-38.059166665808959, -8.211666659089019],
              [-38.059999999200556, -8.211666659089019],
              [-38.060000000999196, -8.216666659438715],
              [-38.06083333349153, -8.216666660338035],
              [-38.060833331692834, -8.218333326221966],
              [-38.061666666883127, -8.218333326221966],
              [-38.061666665983807, -8.2191666587143],
              [-38.062499997576765, -8.2191666587143],
              [-38.062499998476085, -8.219999993005217],
              [-38.063333331867739, -8.219999992105897],
              [-38.063333333666378, -8.22499999425429],
              [-38.069999999900006, -8.22499999245565],
              [-38.069999999900006, -8.225833325847248],
              [-38.071666666683257, -8.225833325847248],
              [-38.072499999175591, -8.225833325847248],
              [-38.072499999175591, -8.22499999245565],
              [-38.073333332567188, -8.22499999335497],
              [-38.073333332567188, -8.224166659063997],
              [-38.074166667757481, -8.224166658164677],
              [-38.074166665958842, -8.223333325672399],
              [-38.07666666613369, -8.22333332477308],
              [-38.07666666703301, -8.222499993180122],
              [-38.077499999525287, -8.222499993180122],
              [-38.077499999525287, -8.220833325497551],
              [-38.079166668107177, -8.220833325497551],
              [-38.079166665409218, -8.219999992105897],
              [-38.080833333991109, -8.219999992105897],
              [-38.080833333091789, -8.2191666587143],
              [-38.081666666483443, -8.2191666587143],
              [-38.081666666483443, -8.215833326047118],
              [-38.08249999987504, -8.215833326047118],
              [-38.08249999897572, -8.214999991756144],
              [-38.083333332367374, -8.214999991756144],
              [-38.083333332367374, -8.212499993379936],
              [-38.08249999987504, -8.212499993379936],
              [-38.08250000077436, -8.210833327496005],
              [-38.083333331468054, -8.210833324798045],
              [-38.083333334166014, -8.205833326246989],
              [-38.08250000077436, -8.205833325347612],
              [-38.08249999987504, -8.204999991956015],
              [-38.083333332367374, -8.204999991956015],
              [-38.083333334166014, -8.199999994304221],
              [-38.084166666658291, -8.199999992505582],
              [-38.084166666658291, -8.198333325722331],
              [-38.083333334166014, -8.198333324823011],
              [-38.083333334166014, -8.197499992330734],
              [-38.084166667557611, -8.197499994129373],
              [-38.084166667557611, -8.196666658939137],
              [-38.084999999150625, -8.196666658039817],
              [-38.085000000049945, -8.195833324648163],
              [-38.086666665933819, -8.195833324648163],
              [-38.086666666833139, -8.194166658764232],
              [-38.089166666108724, -8.194166659663551],
              [-38.089166666108724, -8.194999992155886],
              [-38.093333332167504, -8.194999992155886],
              [-38.093333333966143, -8.195833324648163],
              [-38.09416666645842, -8.195833326446802],
              [-38.09416666645842, -8.196666658939137],
              [-38.094999998950755, -8.196666658039817],
              [-38.094999998950755, -8.197499993230053],
              [-38.095833332342352, -8.197499992330734],
              [-38.095833332342352, -8.198333325722331],
              [-38.096666666633325, -8.198333325722331],
              [-38.096666667532645, -8.199166659113985],
              [-38.097500001823562, -8.199166658214665],
              [-38.097499999125603, -8.200833325897236],
              [-38.098333332517257, -8.200833325897236],
              [-38.098333332517257, -8.204166659463738],
              [-38.099166665908854, -8.204166657665098],
              [-38.099166666808173, -8.204999991956015],
              [-38.099999998401188, -8.204999992855335],
              [-38.099999998401188, -8.205833326246989],
              [-38.100833331792785, -8.205833326246989],
              [-38.100833332692105, -8.206666658739266],
              [-38.103333331967633, -8.206666659638586],
              [-38.103333332866953, -8.205833326246989],
              [-38.104999998750884, -8.205833325347612],
              [-38.104999998750884, -8.204999992855335],
              [-38.11, -8.204999991956015],
              [-38.109999999100637, -8.205833325347612],
              [-38.110833331592914, -8.205833325347612],
              [-38.110833331592914, -8.209166658914114],
              [-38.109999999100637, -8.209166658014794],
              [-38.109999999100637, -8.211666659089019],
              [-38.110833332492234, -8.211666659089019],
              [-38.110833332492234, -8.212499993379936],
              [-38.113333331767819, -8.212499992480616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '116',
        OBJECTID: 157.0,
        Nome_area: 'Serra do Capim',
        COD_area: 'CA163',
        Import_bio: 'Alta',
        Prior_acao: 'Muito Alta',
        Acao_princ: 'Criação de UC de categoria indefinida',
        Area_ha: 11311.192,
        Shape_Leng: 0.84333,
        Shape_Area: 0.00928,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.430833331692838, -7.939999993205049],
              [-37.430833331692838, -7.940833324798064],
              [-37.429999999200561, -7.940833325697383],
              [-37.429999999200561, -7.942499994279274],
              [-37.430000000099881, -7.944166658364566],
              [-37.430833331692838, -7.944166659263885],
              [-37.430833331692838, -7.94749999283033],
              [-37.430000000099881, -7.947499993729707],
              [-37.429999999200561, -7.948333326221984],
              [-37.429166667607603, -7.948333326221984],
              [-37.429166665808964, -7.949166658714262],
              [-37.42833333151799, -7.949166658714262],
              [-37.42833333241731, -7.949999993005235],
              [-37.427499999025713, -7.949999993005235],
              [-37.427499999025713, -7.953333325672418],
              [-37.426666665634059, -7.953333325672418],
              [-37.426666666533379, -7.954166659064015],
              [-37.425833333141782, -7.954166659064015],
              [-37.425833330443822, -7.956666658339543],
              [-37.424166666358531, -7.956666659238863],
              [-37.424166666358531, -7.955833325847266],
              [-37.42249999867596, -7.955833325847266],
              [-37.422500000474599, -7.954999993354988],
              [-37.418333332617181, -7.954999993354988],
              [-37.418333332617181, -7.954166659064015],
              [-37.41666666583393, -7.954166659064015],
              [-37.416666667632569, -7.953333325672418],
              [-37.410833333891219, -7.953333325672418],
              [-37.410833332092579, -7.954166659064015],
              [-37.405000001049189, -7.954166659064015],
              [-37.405000001049189, -7.954999993354988],
              [-37.404166667657535, -7.954999994254308],
              [-37.404166667657535, -7.957499993529837],
              [-37.403333332467298, -7.957499993529837],
              [-37.403333331567978, -7.959166658514448],
              [-37.400833333191713, -7.959166659413768],
              [-37.400833333191713, -7.958333326022114],
              [-37.398333333916185, -7.958333326022114],
              [-37.397499998725891, -7.958333326022114],
              [-37.397500000524587, -7.959166659413768],
              [-37.396666667132934, -7.959166660313088],
              [-37.396666667132934, -7.959999992805365],
              [-37.395833332842017, -7.959999991906045],
              [-37.395833331942697, -7.960833326197019],
              [-37.394999999450363, -7.960833326197019],
              [-37.395000000349683, -7.961666658689296],
              [-37.394166666058766, -7.961666658689296],
              [-37.394166666058766, -7.964166658864144],
              [-37.395000000349683, -7.964166658864144],
              [-37.394999999450363, -7.966666659938369],
              [-37.395833332842017, -7.966666659039049],
              [-37.395833331942697, -7.967499993329966],
              [-37.396666666233614, -7.967499993329966],
              [-37.396666666233614, -7.9683333258223],
              [-37.397499999625211, -7.9683333258223],
              [-37.397499999625211, -7.969999993504871],
              [-37.398333332117545, -7.969999991706231],
              [-37.398333332117545, -7.969166659213897],
              [-37.399999999800116, -7.969166659213897],
              [-37.400000000699436, -7.9683333258223],
              [-37.400833334990352, -7.9683333258223],
              [-37.400833333191713, -7.969166658314577],
              [-37.401666666583367, -7.969166658314577],
              [-37.401666665684047, -7.969999993504871],
              [-37.402500000874284, -7.969999993504871],
              [-37.402499999075644, -7.970833325997148],
              [-37.403333332467298, -7.970833325097828],
              [-37.403333332467298, -7.972499992780399],
              [-37.404166665858895, -7.972499992780399],
              [-37.404166666758215, -7.974999993854567],
              [-37.406666666033743, -7.974999992955247],
              [-37.406666666033743, -7.974166657765011],
              [-37.411666668182136, -7.97416665866433],
              [-37.411666669081455, -7.974999992955247],
              [-37.414166667457721, -7.974999992955247],
              [-37.414166667457721, -7.975833326346901],
              [-37.414999999949998, -7.975833326346901],
              [-37.414999999050679, -7.976666658839179],
              [-37.415833332442276, -7.976666658839179],
              [-37.415833335140235, -7.977499993130152],
              [-37.4183333335165, -7.977499993130152],
              [-37.418333332617181, -7.978333326521749],
              [-37.419166666008778, -7.978333326521749],
              [-37.419166667807417, -7.979999993305],
              [-37.419999999400432, -7.979999993305],
              [-37.419999998501112, -7.980833325797278],
              [-37.420833331892709, -7.980833325797278],
              [-37.420833333691348, -7.982499993479848],
              [-37.421666667083002, -7.982499994379168],
              [-37.421666666183683, -7.98416665936378],
              [-37.42249999957528, -7.98416665936378],
              [-37.422500000474599, -7.984999992755434],
              [-37.423333332966877, -7.984999992755434],
              [-37.423333332067557, -7.985833325247711],
              [-37.42416666815717, -7.985833325247711],
              [-37.424166665459211, -7.986666658639365],
              [-37.423333332067557, -7.986666658639365],
              [-37.423333333866196, -7.987499992930282],
              [-37.421666666183683, -7.987499992030962],
              [-37.421666666183683, -7.988333326321879],
              [-37.419999998501112, -7.988333326321879],
              [-37.419999999400432, -7.989166658814213],
              [-37.417499999225527, -7.989166658814213],
              [-37.417500001024166, -7.988333326321879],
              [-37.416666667632569, -7.988333326321879],
              [-37.416666666733249, -7.987499992030962],
              [-37.41249999977515, -7.98750000012484],
              [-37.410833332991899, -7.987499992930282],
              [-37.410833332092579, -7.988333326321879],
              [-37.409999999600245, -7.988333326321879],
              [-37.410000000499565, -7.989166657914893],
              [-37.404166667657535, -7.989166658814213],
              [-37.404166666758215, -7.98999999310513],
              [-37.403333332467298, -7.98999999310513],
              [-37.403333332467298, -7.990833326496784],
              [-37.402500000874284, -7.990833326496784],
              [-37.402500000874284, -7.991666658989061],
              [-37.401666666583367, -7.991666658989061],
              [-37.401666665684047, -7.992499993280035],
              [-37.400833334091033, -7.992499993280035],
              [-37.400833331393073, -7.991666658989061],
              [-37.397500000524587, -7.991666658989061],
              [-37.397499998725891, -7.992499993280035],
              [-37.394999998551043, -7.992499993280035],
              [-37.394999999450363, -7.993333325772312],
              [-37.393333331767792, -7.993333324872992],
              [-37.393333332667112, -7.994166658264646],
              [-37.390833332492264, -7.994166659163966],
              [-37.390833333391583, -7.994999993454883],
              [-37.38999999910061, -7.994999993454883],
              [-37.390000000899306, -7.99583332594716],
              [-37.388333333216735, -7.99583332594716],
              [-37.388333333216735, -7.996666659338814],
              [-37.386666666433484, -7.996666659338814],
              [-37.386666666433484, -7.99583332594716],
              [-37.383333333766302, -7.99583332594716],
              [-37.383333331967663, -7.994999993454883],
              [-37.378333332517229, -7.994999993454883],
              [-37.378333333416549, -7.99583332594716],
              [-37.377500000924272, -7.99583332594716],
              [-37.377500000024952, -7.996666659338814],
              [-37.377499999125632, -7.997499992730411],
              [-37.378333332517229, -7.997499992730411],
              [-37.378333333416549, -7.999999992905316],
              [-37.379166667707523, -7.999999992905316],
              [-37.379166666808203, -8.001666658789247],
              [-37.379999998401161, -8.001666658789247],
              [-37.379999997501841, -8.002499993080164],
              [-37.380833332692134, -8.002499993080164],
              [-37.380833333591454, -8.005833325747346],
              [-37.382500000374648, -8.005833325747346],
              [-37.382499998576009, -8.006666659138943],
              [-37.383333331967663, -8.006666659138943],
              [-37.383333332866982, -8.007499992530597],
              [-37.384999998750914, -8.007499993429917],
              [-37.384999999650233, -8.006666659138943],
              [-37.386666665534165, -8.006666659138943],
              [-37.386666666433484, -8.005833325747346],
              [-37.39, -8.005833325747346],
              [-37.38999999910061, -8.006666659138943],
              [-37.390833332492264, -8.006666659138943],
              [-37.390833332492264, -8.007499993429917],
              [-37.393333333566432, -8.007499993429917],
              [-37.393333332667112, -8.008333325922194],
              [-37.395833332842017, -8.008333325922194],
              [-37.395833333741336, -8.009999994504085],
              [-37.398333333016865, -8.009999992705445],
              [-37.398333333016865, -8.010833326097099],
              [-37.399166665509142, -8.010833326097099],
              [-37.399166666408462, -8.011666659488696],
              [-37.401666667482687, -8.011666659488696],
              [-37.401666667482687, -8.013333326271947],
              [-37.402500000874284, -8.013333326271947],
              [-37.402499999974964, -8.014166658764225],
              [-37.404166667657535, -8.014166658764225],
              [-37.404166665858895, -8.014999992155879],
              [-37.403333332467298, -8.014999992155879],
              [-37.403333332467298, -8.01666665893913],
              [-37.404166664959575, -8.01666665893913],
              [-37.404166667657535, -8.017499993230047],
              [-37.409999998700926, -8.017499992330727],
              [-37.410000000499565, -8.01666665893913],
              [-37.41083333119326, -8.01666665893913],
              [-37.410833332991899, -8.015833326446796],
              [-37.411666666383496, -8.015833326446796],
              [-37.411666665484177, -8.019999993404952],
              [-37.41250000067447, -8.019999993404952],
              [-37.41249999887583, -8.024166657665091],
              [-37.413333333166747, -8.024166659463731],
              [-37.413333331368108, -8.025833326246982],
              [-37.41250000067447, -8.025833326246982],
              [-37.41249999977515, -8.030833325697358],
              [-37.413333331368108, -8.030833327495998],
              [-37.413333334066067, -8.03416665926386],
              [-37.414166665659025, -8.03416665926386],
              [-37.414166667457721, -8.035833326047111],
              [-37.415000000849318, -8.035833326946431],
              [-37.414999999949998, -8.036666659438708],
              [-37.414166666558344, -8.036666659438708],
              [-37.414166666558344, -8.039999992105891],
              [-37.415833331542956, -8.03999999300521],
              [-37.415833332442276, -8.039166658714294],
              [-37.41666666583393, -8.039166658714294],
              [-37.416666666733249, -8.040833326396864],
              [-37.417500001923486, -8.040833326396864],
              [-37.417499999225527, -8.041666658889142],
              [-37.416666667632569, -8.041666659788461],
              [-37.41666666583393, -8.042499992280796],
              [-37.417500000124846, -8.042499993180115],
              [-37.417500001923486, -8.044999993354963],
              [-37.418333331717861, -8.044999993354963],
              [-37.418333331717861, -8.045833325847241],
              [-37.419166667807417, -8.045833325847241],
              [-37.419166666008778, -8.048333326022146],
              [-37.419999998501112, -8.048333326022146],
              [-37.419999998501112, -8.049999991906077],
              [-37.420833332792029, -8.049999991906077],
              [-37.420833331892709, -8.051666658689328],
              [-37.421666667083002, -8.051666658689328],
              [-37.421666667083002, -8.052499992980245],
              [-37.42249999867596, -8.052499992980245],
              [-37.42249999867596, -8.053333326371842],
              [-37.423333332966877, -8.053333326371842],
              [-37.423333332966877, -8.054999993155093],
              [-37.424166665459211, -8.054999993155093],
              [-37.424166665459211, -8.058333325822275],
              [-37.423333332966877, -8.058333325822275],
              [-37.423333332067557, -8.061666659388777],
              [-37.422500000474599, -8.061666660288097],
              [-37.42249999867596, -8.062499992780374],
              [-37.421666666183683, -8.062499992780374],
              [-37.421666667982322, -8.063333326172028],
              [-37.420833331892709, -8.063333326172028],
              [-37.420833332792029, -8.064166658664305],
              [-37.419999997601792, -8.064166658664305],
              [-37.419999997601792, -8.064999992955279],
              [-37.419166666908097, -8.064999992955279],
              [-37.419166666908097, -8.06666665883921],
              [-37.417500001024166, -8.06666665973853],
              [-37.417499999225527, -8.067499993130127],
              [-37.41666666583393, -8.067499993130127],
              [-37.41666666583393, -8.069999992405656],
              [-37.415833333341595, -8.069999993304975],
              [-37.415833332442276, -8.07249999347988],
              [-37.420000001199071, -8.07249999347988],
              [-37.419999998501112, -8.071666659188907],
              [-37.423333333866196, -8.071666659188907],
              [-37.423333333866196, -8.070833325797309],
              [-37.427499999025713, -8.070833326696629],
              [-37.427499999025713, -8.069999993304975],
              [-37.430833333491478, -8.069999993304975],
              [-37.430833332592158, -8.069166659014058],
              [-37.4300000009992, -8.069166659913378],
              [-37.4300000009992, -8.067499993130127],
              [-37.430833331692838, -8.067499993130127],
              [-37.430833331692838, -8.06666665973853],
              [-37.431666665983812, -8.06666665883921],
              [-37.431666666883132, -8.064999992955279],
              [-37.433333332767063, -8.064999992955279],
              [-37.433333331867743, -8.064166658664305],
              [-37.4341666679573, -8.064166658664305],
              [-37.43416666705798, -8.063333325272708],
              [-37.434999999550314, -8.063333326172028],
              [-37.434999999550314, -8.061666659388777],
              [-37.436666668132204, -8.061666659388777],
              [-37.436666665434245, -8.058333325822275],
              [-37.437499999725162, -8.058333325822275],
              [-37.437500000624482, -8.057499993329998],
              [-37.436666667232885, -8.057499993329998],
              [-37.436666665434245, -8.056666659938344],
              [-37.437500000624482, -8.056666659039024],
              [-37.437499998825842, -8.053333327271162],
              [-37.43999999990001, -8.053333326371842],
              [-37.44000000079933, -8.051666660487967],
              [-37.441666666683261, -8.051666658689328],
              [-37.443333333466512, -8.051666658689328],
              [-37.443333332567192, -8.047499993529811],
              [-37.442500000974235, -8.047499993529811],
              [-37.442499999175595, -8.045833325847241],
              [-37.440833334190984, -8.045833325847241],
              [-37.440833334190984, -8.043333325672393],
              [-37.43999999990001, -8.043333325672393],
              [-37.43999999990001, -8.039166658714294],
              [-37.440833332392344, -8.039166658714294],
              [-37.440833331493025, -8.036666659438708],
              [-37.441666666683261, -8.036666660338085],
              [-37.441666665783941, -8.035833326047111],
              [-37.440833332392344, -8.035833326946431],
              [-37.440833332392344, -8.03416665926386],
              [-37.445833331842721, -8.034166658364541],
              [-37.445833332742097, -8.034999992655514],
              [-37.446666666133694, -8.034999991756195],
              [-37.446666667033014, -8.035833326047111],
              [-37.447499998625972, -8.035833325147792],
              [-37.447499999525292, -8.036666659438708],
              [-37.449166665409223, -8.036666657640069],
              [-37.449166665409223, -8.037499991931043],
              [-37.450000000599516, -8.037499992830362],
              [-37.449999998800877, -8.03833332532264],
              [-37.452499999875045, -8.038333326221959],
              [-37.452499999875045, -8.039166658714294],
              [-37.453333331468002, -8.039166658714294],
              [-37.453333334166018, -8.037499991931043],
              [-37.455000000949269, -8.037499993729682],
              [-37.454999997351933, -8.036666659438708],
              [-37.455833331642907, -8.036666659438708],
              [-37.455833331642907, -8.034999991756195],
              [-37.456666665933824, -8.034999992655514],
              [-37.456666666833144, -8.03416665926386],
              [-37.457500000224798, -8.03416665926386],
              [-37.457500000224798, -8.033333325872263],
              [-37.458333332717075, -8.033333325872263],
              [-37.458333330918435, -8.032499993379929],
              [-37.459166666108729, -8.032499994279249],
              [-37.462499999675174, -8.032499994279249],
              [-37.462500000574551, -8.033333324972944],
              [-37.463333332167508, -8.033333325872263],
              [-37.463333332167508, -8.034166658364541],
              [-37.46666666663333, -8.034166658364541],
              [-37.46666666573401, -8.034999992655514],
              [-37.468333332517261, -8.034999992655514],
              [-37.468333331617941, -8.035833326047111],
              [-37.47083333179279, -8.035833326047111],
              [-37.47083333179279, -8.034166658364541],
              [-37.471666666983026, -8.03416665926386],
              [-37.471666666983026, -8.034999992655514],
              [-37.473333331967638, -8.034999992655514],
              [-37.473333333766277, -8.03416665926386],
              [-37.474999999650208, -8.03416665926386],
              [-37.475000001448848, -8.033333325872263],
              [-37.47666666553414, -8.033333325872263],
              [-37.47666666553414, -8.032499993379929],
              [-37.47833333321671, -8.032499992480609],
              [-37.478333331418071, -8.030833327495998],
              [-37.479166666608307, -8.030833325697358],
              [-37.479166666608307, -8.029999993205081],
              [-37.478333331418071, -8.029999993205081],
              [-37.47833333321671, -8.029166658914107],
              [-37.479166664809668, -8.029166658014788],
              [-37.479166665708988, -8.02833332642183],
              [-37.479999999100642, -8.02833332642183],
              [-37.480000000899281, -8.027499993030233],
              [-37.480833333391558, -8.027499993030233],
              [-37.480833331592919, -8.026666657839939],
              [-37.481666665883893, -8.026666658739259],
              [-37.481666665883893, -8.025833326246982],
              [-37.483333331767824, -8.025833326246982],
              [-37.483333333566463, -8.024999992855328],
              [-37.48416666695806, -8.024999992855328],
              [-37.48416666695806, -8.023333326072077],
              [-37.485000000349714, -8.023333326072077],
              [-37.484999998551075, -8.020833325897229],
              [-37.485833332841992, -8.020833325897229],
              [-37.485833331942672, -8.019999993404952],
              [-37.48833333211752, -8.019999993404952],
              [-37.48833333211752, -8.019166659113978],
              [-37.489999999800091, -8.019166658214658],
              [-37.489999999800091, -8.019999993404952],
              [-37.490833334091064, -8.019999992505575],
              [-37.490833331393105, -8.019166660013298],
              [-37.492500000874315, -8.019166659113978],
              [-37.492499999075676, -8.019999993404952],
              [-37.493333333366593, -8.019999993404952],
              [-37.493333331567953, -8.020833325897229],
              [-37.494166665858927, -8.020833325897229],
              [-37.494166666758247, -8.019999993404952],
              [-37.494999998351204, -8.019999992505575],
              [-37.495000000149844, -8.019166659113978],
              [-37.495833331742801, -8.019166659113978],
              [-37.495833332642121, -8.018333325722381],
              [-37.496666667832415, -8.018333325722381],
              [-37.496666666033775, -8.01666665893913],
              [-37.495833331742801, -8.01666665803981],
              [-37.495833332642121, -8.015833326446796],
              [-37.496666666933095, -8.015833327346115],
              [-37.496666666933095, -8.014999993055199],
              [-37.495833333541441, -8.014999993954518],
              [-37.495833332642121, -8.014166658764225],
              [-37.496666666033775, -8.014166657864905],
              [-37.496666666933095, -8.013333326271947],
              [-37.499999999600277, -8.013333326271947],
              [-37.499999998700957, -8.012499992880294],
              [-37.501666665484208, -8.01249999377967],
              [-37.501666665484208, -8.011666659488696],
              [-37.504999999050654, -8.011666659488696],
              [-37.504999999949973, -8.012499992880294],
              [-37.507499999225558, -8.012499992880294],
              [-37.507499999225558, -8.013333326271947],
              [-37.508333332617156, -8.013333326271947],
              [-37.508333333516475, -8.012499992880294],
              [-37.509166668706769, -8.012499992880294],
              [-37.510833332792004, -8.012499992880294],
              [-37.510833332792004, -8.013333326271947],
              [-37.512499998675935, -8.013333326271947],
              [-37.512500000474574, -8.014166658764225],
              [-37.513333332966909, -8.014166658764225],
              [-37.513333332966909, -8.014999993055199],
              [-37.514166665459186, -8.014999993055199],
              [-37.514166665459186, -8.015833326446796],
              [-37.515833334041076, -8.015833326446796],
              [-37.515833334041076, -8.01666665893913],
              [-37.516666665634091, -8.01666665893913],
              [-37.516666666533411, -8.017499993230047],
              [-37.517500000824327, -8.017499994129366],
              [-37.517500000824327, -8.01666665893913],
              [-37.518333331517965, -8.01666665983845],
              [-37.518333331517965, -8.015833326446796],
              [-37.519999999200536, -8.015833324648156],
              [-37.519999999200536, -8.014999992155879],
              [-37.52083333259219, -8.014999993055199],
              [-37.52083333259219, -8.014166659663545],
              [-37.521666665983787, -8.014166658764225],
              [-37.521666665084467, -8.013333325372628],
              [-37.522499999375441, -8.013333326271947],
              [-37.522499998476121, -8.012499992880294],
              [-37.524166666158692, -8.012499992880294],
              [-37.524166667058012, -8.011666659488696],
              [-37.524999999550289, -8.011666659488696],
              [-37.524999998650969, -8.010833326097099],
              [-37.524166667957331, -8.010833326097099],
              [-37.524166667957331, -8.009166660213168],
              [-37.524999998650969, -8.009166659313848],
              [-37.524999999550289, -8.001666658789247],
              [-37.524166666158692, -8.001666658789247],
              [-37.524166667058012, -7.999999992905316],
              [-37.522499999375441, -7.999999992905316],
              [-37.522499998476121, -7.999166659513662],
              [-37.52083333169287, -7.999166659513662],
              [-37.52083333349151, -7.998333326122065],
              [-37.519999999200536, -7.998333327021385],
              [-37.520000000999175, -7.996666659338814],
              [-37.519166665808939, -7.996666659338814],
              [-37.519166665808939, -7.994999993454883],
              [-37.518333332417342, -7.994999993454883],
              [-37.518333331517965, -7.994166659163966],
              [-37.519166667607578, -7.994166659163966],
              [-37.519166667607578, -7.992499993280035],
              [-37.519999999200536, -7.992499993280035],
              [-37.519999999200536, -7.989166658814213],
              [-37.519166666708259, -7.989166658814213],
              [-37.519166666708259, -7.988333326321879],
              [-37.518333331517965, -7.988333326321879],
              [-37.518333332417342, -7.985833325247711],
              [-37.519999999200536, -7.985833326147031],
              [-37.520000000099856, -7.984999992755434],
              [-37.523333333666358, -7.984999992755434],
              [-37.523333333666358, -7.985833326147031],
              [-37.527499998825817, -7.985833326147031],
              [-37.527499998825817, -7.986666658639365],
              [-37.533333331667905, -7.986666658639365],
              [-37.533333331667905, -7.985833326147031],
              [-37.534999998451099, -7.985833326147031],
              [-37.534999998451099, -7.986666658639365],
              [-37.537499998626004, -7.986666660438004],
              [-37.537499998626004, -7.987499992030962],
              [-37.542499999875076, -7.987499992930282],
              [-37.542499998975757, -7.986666658639365],
              [-37.549166667008024, -7.986666658639365],
              [-37.549166667907343, -7.981666659188932],
              [-37.548333331817787, -7.981666659188932],
              [-37.548333332717107, -7.980833325797278],
              [-37.547500001124092, -7.980833325797278],
              [-37.547500001124092, -7.97999999420432],
              [-37.546666666833175, -7.979999992405681],
              [-37.546666665933856, -7.978333326521749],
              [-37.545833331642882, -7.978333327421069],
              [-37.545833332542202, -7.977499994029472],
              [-37.545000000949244, -7.977499993130152],
              [-37.544999999150605, -7.976666658839179],
              [-37.543333332367354, -7.976666657939859],
              [-37.543333334165993, -7.975833327246221],
              [-37.539999998800852, -7.975833326346901],
              [-37.539999998800852, -7.974999992955247],
              [-37.536666667932309, -7.974999992055928],
              [-37.536666666133669, -7.97416665866433],
              [-37.535833332742072, -7.97416665866433],
              [-37.535833331842753, -7.971666659388745],
              [-37.530833331493, -7.971666659388745],
              [-37.530833332392319, -7.972499992780399],
              [-37.529166666508388, -7.972499992780399],
              [-37.529166664709749, -7.971666658489426],
              [-37.52666666543422, -7.971666659388745],
              [-37.52666666543422, -7.970833325997148],
              [-37.524166666158692, -7.970833327795788],
              [-37.523333332767038, -7.970833325097828],
              [-37.523333333666358, -7.969999994404191],
              [-37.52083333169287, -7.969999993504871],
              [-37.52083333259219, -7.969166659213897],
              [-37.516666666533411, -7.969166659213897],
              [-37.516666665634091, -7.969999993504871],
              [-37.514166669056465, -7.969999993504871],
              [-37.514166664559866, -7.970833325997148],
              [-37.513333333866228, -7.970833325997148],
              [-37.513333332966909, -7.969999993504871],
              [-37.512499998675935, -7.969999993504871],
              [-37.512499999575255, -7.969166659213897],
              [-37.511666667082977, -7.969166659213897],
              [-37.511666667082977, -7.9683333258223],
              [-37.510833331892684, -7.9683333258223],
              [-37.510833331892684, -7.967499993329966],
              [-37.508333331717836, -7.967499993329966],
              [-37.508333332617156, -7.966666659039049],
              [-37.505833331542988, -7.966666659039049],
              [-37.505833331542988, -7.967499992430646],
              [-37.495833331742801, -7.967499993329966],
              [-37.495833332642121, -7.96833332672162],
              [-37.495000001049164, -7.9683333258223],
              [-37.494999998351204, -7.969166659213897],
              [-37.494166666758247, -7.969166659213897],
              [-37.494166667657566, -7.970833325997148],
              [-37.495000001049164, -7.970833325997148],
              [-37.494999998351204, -7.971666659388745],
              [-37.494166665858927, -7.971666659388745],
              [-37.49416666495955, -7.972499992780399],
              [-37.493333333366593, -7.972499992780399],
              [-37.493333333366593, -7.975833326346901],
              [-37.492499999075676, -7.975833326346901],
              [-37.492500000874315, -7.976666658839179],
              [-37.491666664784702, -7.976666659738498],
              [-37.491666665684022, -7.977499992230833],
              [-37.490833331393105, -7.977499993130152],
              [-37.490833333191745, -7.976666658839179],
              [-37.490000000699411, -7.976666658839179],
              [-37.489999999800091, -7.975833326346901],
              [-37.48833333391616, -7.975833326346901],
              [-37.48833333301684, -7.974999992955247],
              [-37.487499998725923, -7.974999992955247],
              [-37.487499998725923, -7.97416665866433],
              [-37.486666668032285, -7.97416665866433],
              [-37.486666666233589, -7.973333326171996],
              [-37.483333331767824, -7.973333326171996],
              [-37.483333331767824, -7.972499992780399],
              [-37.481666666783212, -7.972499992780399],
              [-37.481666665883893, -7.973333326171996],
              [-37.480833332492239, -7.973333326171996],
              [-37.480833332492239, -7.972499992780399],
              [-37.479166667507684, -7.972499992780399],
              [-37.479166665708988, -7.971666659388745],
              [-37.476666668232099, -7.971666658489426],
              [-37.476666666433459, -7.970833325997148],
              [-37.474166665359292, -7.970833325997148],
              [-37.474166667157931, -7.969999993504871],
              [-37.471666666083706, -7.969999993504871],
              [-37.471666667882346, -7.969166659213897],
              [-37.471666666083706, -7.966666659039049],
              [-37.472499998576041, -7.966666659039049],
              [-37.47249999947536, -7.965833325647395],
              [-37.473333332866957, -7.965833325647395],
              [-37.473333333766277, -7.960833326197019],
              [-37.471666666083706, -7.960833326197019],
              [-37.471666667882346, -7.959999992805365],
              [-37.470833333591429, -7.959999993704685],
              [-37.470833332692109, -7.956666659238863],
              [-37.469999998401136, -7.956666659238863],
              [-37.470000000199832, -7.954999993354988],
              [-37.469166665908858, -7.954999993354988],
              [-37.469166667707498, -7.954166659064015],
              [-37.468333331617941, -7.954166659064015],
              [-37.468333333416581, -7.953333325672418],
              [-37.467499999125607, -7.953333325672418],
              [-37.467499999125607, -7.952499993180083],
              [-37.468333333416581, -7.952499993180083],
              [-37.468333331617941, -7.951666657989847],
              [-37.469166667707498, -7.951666658889167],
              [-37.469166666808178, -7.950833326396832],
              [-37.468333333416581, -7.950833327296152],
              [-37.463333332167508, -7.950833326396832],
              [-37.463333333066828, -7.949999993005235],
              [-37.454999999150573, -7.949999993005235],
              [-37.454999999150573, -7.950833326396832],
              [-37.453333332367379, -7.950833326396832],
              [-37.453333331468002, -7.949999993005235],
              [-37.452499999875045, -7.949999993005235],
              [-37.452499999875045, -7.950833326396832],
              [-37.451666665584128, -7.950833326396832],
              [-37.451666666483447, -7.949999993005235],
              [-37.448333331118306, -7.949999993005235],
              [-37.448333333816265, -7.948333327121304],
              [-37.446666667932334, -7.948333326221984],
              [-37.446666667033014, -7.947499991931011],
              [-37.444166666858166, -7.947499993729707],
              [-37.444166667757486, -7.946666659438733],
              [-37.440833334190984, -7.946666659438733],
              [-37.440833332392344, -7.94749999283033],
              [-37.438333332217439, -7.947499991931011],
              [-37.438333332217439, -7.946666659438733],
              [-37.437500000624482, -7.946666657640094],
              [-37.437499999725162, -7.944166659263885],
              [-37.436666668132204, -7.944166659263885],
              [-37.436666667232885, -7.943333324972912],
              [-37.435833332042591, -7.943333325872231],
              [-37.435833332042591, -7.941666658189661],
              [-37.43416666705798, -7.94166665908898],
              [-37.4341666679573, -7.940833325697383],
              [-37.432499999375409, -7.940833325697383],
              [-37.432499999375409, -7.939999993205049],
              [-37.431666667782451, -7.939999994104426],
              [-37.430833331692838, -7.939999993205049],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '121',
        OBJECTID: 163.0,
        Nome_area: 'Complexo de Serras Livramento',
        COD_area: 'CA169',
        Import_bio: 'Alta',
        Prior_acao: 'Muito Alta',
        Acao_princ: 'Criação de UC de Proteção Integral',
        Area_ha: 66802.4565,
        Shape_Leng: 2.10667,
        Shape_Area: 0.05483,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.181666666083743, -8.124166659263892],
              [-39.181666666983062, -8.124999992655489],
              [-39.180833333591409, -8.124999992655489],
              [-39.180833332692089, -8.125833326047086],
              [-39.179166665908838, -8.125833326047086],
              [-39.179166665908838, -8.127499992830337],
              [-39.177500000024963, -8.127499992830337],
              [-39.177500000024963, -8.128333325322671],
              [-39.176666666633309, -8.128333326221991],
              [-39.17666666573399, -8.129999993005242],
              [-39.173333332167488, -8.129999993005242],
              [-39.173333333066807, -8.130833326396839],
              [-39.171666668082196, -8.130833326396839],
              [-39.171666667182876, -8.131666658889174],
              [-39.170833333791279, -8.131666658889174],
              [-39.170833333791279, -8.133333325672368],
              [-39.169166666108708, -8.133333324773048],
              [-39.169166666108708, -8.134166659064022],
              [-39.168333332717111, -8.134166659064022],
              [-39.168333331817792, -8.134999994254258],
              [-39.16666666593386, -8.134999993354938],
              [-39.1666666677325, -8.134166659064022],
              [-39.16499999735197, -8.134166659064022],
              [-39.165000000049929, -8.134999993354938],
              [-39.163333331468039, -8.134999994254258],
              [-39.163333331468039, -8.134166659064022],
              [-39.16083333219251, -8.134166659064022],
              [-39.16083333309183, -8.134999993354938],
              [-39.159999999700176, -8.134999993354938],
              [-39.159999999700176, -8.135833326746592],
              [-39.159999998800856, -8.137499993529843],
              [-39.159166669006538, -8.137499993529843],
              [-39.159166668107218, -8.138333326022121],
              [-39.159166667207899, -8.14249999298022],
              [-39.159999998800856, -8.14249999298022],
              [-39.159999999700176, -8.144166658864151],
              [-39.16083333219251, -8.144166658864151],
              [-39.16083333219251, -8.147499993329973],
              [-39.161666666483427, -8.147499993329973],
              [-39.161666666483427, -8.148333325822307],
              [-39.162499998975704, -8.148333324922987],
              [-39.162500000774401, -8.151666659388752],
              [-39.16083333219251, -8.151666659388752],
              [-39.16083333219251, -8.152499992780406],
              [-39.159999999700176, -8.152499992780406],
              [-39.160000000599496, -8.153333326172003],
              [-39.159166664509939, -8.153333326172003],
              [-39.159166665409259, -8.154999992955254],
              [-39.158333331118286, -8.154999993854574],
              [-39.158333332017605, -8.156666658839185],
              [-39.157499998626008, -8.156666658839185],
              [-39.157500000424648, -8.159999993305007],
              [-39.155833332742077, -8.159999993305007],
              [-39.155833333641397, -8.160833325797284],
              [-39.154999998451103, -8.160833324897965],
              [-39.154999999350423, -8.163333325972189],
              [-39.154166665958826, -8.163333325972189],
              [-39.154166665958826, -8.16499999275544],
              [-39.153333331667852, -8.16499999275544],
              [-39.153333332567229, -8.164166659363786],
              [-39.151666666683298, -8.164166659363786],
              [-39.151666665783978, -8.165833326147037],
              [-39.150833332392324, -8.165833326147037],
              [-39.150833330593684, -8.166666658639315],
              [-39.150000000799366, -8.166666658639315],
              [-39.149999998101407, -8.167499992930288],
              [-39.148333332217476, -8.167499992930288],
              [-39.148333333116796, -8.169999993105137],
              [-39.149166666508393, -8.169999993105137],
              [-39.149166665609073, -8.17083332739611],
              [-39.149999999900047, -8.170833326496791],
              [-39.149999999000727, -8.171666658989068],
              [-39.150833332392324, -8.171666658989068],
              [-39.150833334190963, -8.172499994179361],
              [-39.151666667582617, -8.172499993280042],
              [-39.151666666683298, -8.173333325772319],
              [-39.152499997376935, -8.173333326671639],
              [-39.152500000074895, -8.174166659163916],
              [-39.153333331667852, -8.174166659163916],
              [-39.153333333466549, -8.175833325947167],
              [-39.152499999175575, -8.175833325947167],
              [-39.152499999175575, -8.18083332629692],
              [-39.151666666683298, -8.18083332719624],
              [-39.151666665783978, -8.179999992905323],
              [-39.150833332392324, -8.179999992905323],
              [-39.150833331493004, -8.18083332629692],
              [-39.149999999900047, -8.1808333253976],
              [-39.150000001698686, -8.181666658789197],
              [-39.149166665609073, -8.181666658789197],
              [-39.149166666508393, -8.18083332629692],
              [-39.148333333116796, -8.18083332629692],
              [-39.148333332217476, -8.179999992905323],
              [-39.147499998825822, -8.179999992905323],
              [-39.147499998825822, -8.179166659513669],
              [-39.145833332042571, -8.179166659513669],
              [-39.145833333841267, -8.178333326122072],
              [-39.145000000449613, -8.178333326122072],
              [-39.145000000449613, -8.177499992730418],
              [-39.141666667782431, -8.177499991831098],
              [-39.141666665983792, -8.178333325222752],
              [-39.140833333491514, -8.178333326122072],
              [-39.140833332592194, -8.182499993080171],
              [-39.137499999925012, -8.182499993080171],
              [-39.137499999025692, -8.183333326471768],
              [-39.136666667432735, -8.183333326471768],
              [-39.136666665634095, -8.184166659863422],
              [-39.133333332966913, -8.184166658964102],
              [-39.133333332067593, -8.184999993255019],
              [-39.131666666183662, -8.184999992355699],
              [-39.131666666183662, -8.184166658964102],
              [-39.129999997601772, -8.184166658964102],
              [-39.129999998501091, -8.184999993255019],
              [-39.129166667807453, -8.184999993255019],
              [-39.129166666008814, -8.184166658964102],
              [-39.12833333261716, -8.184166658964102],
              [-39.12833333261716, -8.184999993255019],
              [-39.124999999050658, -8.184999993255019],
              [-39.125000000849298, -8.184166658964102],
              [-39.120833333891198, -8.184166658964102],
              [-39.120833332092559, -8.183333327371088],
              [-39.119166668007267, -8.183333326471768],
              [-39.119166668007267, -8.182499993080171],
              [-39.117500001224016, -8.182499993080171],
              [-39.117499999425377, -8.181666658789197],
              [-39.116666666933099, -8.181666658789197],
              [-39.116666666933099, -8.18083332629692],
              [-39.115833331742806, -8.1808333253976],
              [-39.115833332642126, -8.183333324673129],
              [-39.115000000149848, -8.183333326471768],
              [-39.115000000149848, -8.184999993255019],
              [-39.111666666583346, -8.184999992355699],
              [-39.111666666583346, -8.187499994329244],
              [-39.110833333191749, -8.187499993429924],
              [-39.11083333139311, -8.188333325922201],
              [-39.109999998900776, -8.188333325922201],
              [-39.110000000699415, -8.189166659313855],
              [-39.111666665684027, -8.189166659313855],
              [-39.111666666583346, -8.190833326996369],
              [-39.112499999975, -8.190833326097049],
              [-39.112499999075681, -8.193333325372635],
              [-39.111666666583346, -8.193333326271954],
              [-39.111666666583346, -8.195833326446802],
              [-39.108333332117525, -8.195833326446802],
              [-39.108333333916164, -8.196666659838456],
              [-39.106666666233593, -8.196666658939137],
              [-39.106666667132913, -8.197499993230053],
              [-39.105833333741316, -8.197499993230053],
              [-39.105833332841996, -8.199999993404901],
              [-39.104999999450399, -8.199999993404901],
              [-39.104999998551023, -8.201666660188152],
              [-39.107499998725928, -8.201666659288833],
              [-39.107500000524567, -8.203333326072084],
              [-39.109166666408498, -8.203333326072084],
              [-39.109166666408498, -8.204166659463738],
              [-39.110000001598735, -8.204166659463738],
              [-39.109999999800095, -8.204999992855335],
              [-39.110833333191749, -8.204999992855335],
              [-39.110833334091069, -8.205833326246989],
              [-39.111666667482666, -8.205833326246989],
              [-39.111666665684027, -8.206666658739266],
              [-39.11250000087432, -8.206666658739266],
              [-39.112499999975, -8.207499993929503],
              [-39.114166665858875, -8.207499993030183],
              [-39.114166666758194, -8.209166658914114],
              [-39.11666666603378, -8.209166658914114],
              [-39.11666666603378, -8.210833325697365],
              [-39.117499998526057, -8.210833325697365],
              [-39.117499998526057, -8.211666659089019],
              [-39.120000000499601, -8.211666659089019],
              [-39.119999998700962, -8.212499993379936],
              [-39.120833333891198, -8.212499993379936],
              [-39.120833332092559, -8.21333332587227],
              [-39.122500000674449, -8.21333332497295],
              [-39.122500000674449, -8.214166659263867],
              [-39.123333330468768, -8.214166658364547],
              [-39.123333331368087, -8.214999992655464],
              [-39.124999999949978, -8.214999992655464],
              [-39.125000001748617, -8.215833326047118],
              [-39.125833332442312, -8.215833326047118],
              [-39.125833331542992, -8.216666659438715],
              [-39.127499999225563, -8.216666659438715],
              [-39.127499999225563, -8.217499991931049],
              [-39.129166666908134, -8.217499993729689],
              [-39.129166666008814, -8.218333326221966],
              [-39.129999998501091, -8.218333326221966],
              [-39.129999999400411, -8.220833326396871],
              [-39.126666667632549, -8.220833325497551],
              [-39.126666665833909, -8.221666658889148],
              [-39.124999999949978, -8.221666658889148],
              [-39.124999999949978, -8.222499993180122],
              [-39.1241666674577, -8.222499993180122],
              [-39.124166665659061, -8.223333325672399],
              [-39.123333334066047, -8.223333325672399],
              [-39.123333334066047, -8.22499999335497],
              [-39.120833332092559, -8.22499999335497],
              [-39.120833332991879, -8.224166659063997],
              [-39.119166668007267, -8.224166659063997],
              [-39.119166667107947, -8.223333325672399],
              [-39.11833333371635, -8.223333325672399],
              [-39.118333332817031, -8.222499993180122],
              [-39.117499999425377, -8.222499993180122],
              [-39.117499999425377, -8.221666658889148],
              [-39.116666666933099, -8.221666658889148],
              [-39.116666667832419, -8.220833326396871],
              [-39.115000000149848, -8.220833326396871],
              [-39.115000001049168, -8.219999993005217],
              [-39.113333330668638, -8.219999993904537],
              [-39.112499999075681, -8.219999993005217],
              [-39.11250000087432, -8.22499999335497],
              [-39.114166664959555, -8.22499999425429],
              [-39.114166665858875, -8.225833324947928],
              [-39.115000000149848, -8.225833325847248],
              [-39.115000000149848, -8.228333326022153],
              [-39.115833332642126, -8.228333326022153],
              [-39.115833333541445, -8.229999992805404],
              [-39.11666666603378, -8.229999993704723],
              [-39.11666666603378, -8.230833326197001],
              [-39.115833331742806, -8.230833326197001],
              [-39.115833333541445, -8.231666658689278],
              [-39.115000000149848, -8.231666658689278],
              [-39.114999998351209, -8.233333327271168],
              [-39.114166664959555, -8.233333326371849],
              [-39.114166666758194, -8.234166658864183],
              [-39.113333335165237, -8.234166658864183],
              [-39.113333332467278, -8.239999994404172],
              [-39.11250000087432, -8.239999991706213],
              [-39.112499999975, -8.242499992780381],
              [-39.111666666583346, -8.242499993679701],
              [-39.111666665684027, -8.243333326172035],
              [-39.109999999800095, -8.243333326172035],
              [-39.110000000699415, -8.244166660462952],
              [-39.109166666408498, -8.244166658664312],
              [-39.109166665509179, -8.248333326521731],
              [-39.107500001423887, -8.248333326521731],
              [-39.107499998725928, -8.251666659188913],
              [-39.106666667132913, -8.251666659188913],
              [-39.106666666233593, -8.252499993479887],
              [-39.105833331942677, -8.252499993479887],
              [-39.105833333741316, -8.259999993105168],
              [-39.104999998551023, -8.259999993105168],
              [-39.104999999450399, -8.260833324698126],
              [-39.105833332841996, -8.260833326496765],
              [-39.105833332841996, -8.2616666589891],
              [-39.106666666233593, -8.261666659888419],
              [-39.106666667132913, -8.262499993280016],
              [-39.107499998725928, -8.262499993280016],
              [-39.107500000524567, -8.264999993454865],
              [-39.106666668032233, -8.264999993454865],
              [-39.106666667132913, -8.26749999273045],
              [-39.107499998725928, -8.26749999273045],
              [-39.107499999625247, -8.270833326296952],
              [-39.106666667132913, -8.270833326296952],
              [-39.106666668032233, -8.271666658789229],
              [-39.105833331942677, -8.271666658789229],
              [-39.105833331942677, -8.272499993080146],
              [-39.104999999450399, -8.272499993080146],
              [-39.104999999450399, -8.274166658964077],
              [-39.104166666058745, -8.274166658964077],
              [-39.104166666058745, -8.274999993255051],
              [-39.103333333566468, -8.274999993255051],
              [-39.103333332667148, -8.275833325747328],
              [-39.101666667682537, -8.275833326646648],
              [-39.101666668581856, -8.276666660038302],
              [-39.100833332492243, -8.276666660038302],
              [-39.100833333391563, -8.277499993429899],
              [-39.099999999100646, -8.277499993429899],
              [-39.100000000899286, -8.27916666021315],
              [-39.099166667507632, -8.27916665931383],
              [-39.099166665708992, -8.280833326097081],
              [-39.094999999650213, -8.280833326097081],
              [-39.094999999650213, -8.281666659488678],
              [-39.094166665359296, -8.281666659488678],
              [-39.094166668057255, -8.282499992880332],
              [-39.093333333766282, -8.282499992880332],
              [-39.093333332866962, -8.283333326271929],
              [-39.094999997851573, -8.283333327171249],
              [-39.094999998750893, -8.284166658764264],
              [-39.095833333941187, -8.284166659663583],
              [-39.095833333041867, -8.284999992155861],
              [-39.097500000724438, -8.28499999305518],
              [-39.097499998925741, -8.285833326446834],
              [-39.098333331418075, -8.285833327346154],
              [-39.098333331418075, -8.289999994304253],
              [-39.1, -8.289999993404933],
              [-39.099999999100646, -8.290833325897211],
              [-39.101666667682537, -8.290833325897211],
              [-39.101666665883897, -8.291666659288865],
              [-39.103333333566468, -8.291666659288865],
              [-39.103333333566468, -8.292499992680462],
              [-39.105000000349719, -8.292499992680462],
              [-39.104999998551023, -8.297499993030215],
              [-39.106666667132913, -8.297499993030215],
              [-39.106666668032233, -8.296666658739241],
              [-39.108333333916164, -8.296666658739241],
              [-39.108333332117525, -8.297499993030215],
              [-39.11083333049379, -8.297499992130895],
              [-39.110833334091069, -8.298333326421812],
              [-39.112499999975, -8.298333326421812],
              [-39.112499999075681, -8.299166658914146],
              [-39.113333332467278, -8.299166658914146],
              [-39.113333333366597, -8.299999993205063],
              [-39.113333333366597, -8.300833325697397],
              [-39.112499999075681, -8.300833325697397],
              [-39.112499999975, -8.306666659438747],
              [-39.111666667482666, -8.306666659438747],
              [-39.111666666583346, -8.312499994079417],
              [-39.11083333139311, -8.312499993180097],
              [-39.11083333139311, -8.313333325672374],
              [-39.109999998900776, -8.313333325672374],
              [-39.109999998900776, -8.314166659064028],
              [-39.108333332117525, -8.314166659963348],
              [-39.108333333916164, -8.314999993354945],
              [-39.107499999625247, -8.314999993354945],
              [-39.107500000524567, -8.315833325847279],
              [-39.106666667132913, -8.315833325847279],
              [-39.106666667132913, -8.319999993704698],
              [-39.105833331942677, -8.319999992805378],
              [-39.105833331942677, -8.322499992980227],
              [-39.106666666233593, -8.322499992980227],
              [-39.106666666233593, -8.323333326371881],
              [-39.105833333741316, -8.323333326371881],
              [-39.105833332841996, -8.324166658864158],
              [-39.104166666058745, -8.324166658864158],
              [-39.104166667857385, -8.324999993155132],
              [-39.103333332667148, -8.324999993155132],
              [-39.103333332667148, -8.325833324748089],
              [-39.104999999450399, -8.325833325647409],
              [-39.104999999450399, -8.32749999243066],
              [-39.104166666958065, -8.32749999332998],
              [-39.104166666058745, -8.329166659213911],
              [-39.102500000174814, -8.329166658314591],
              [-39.102500000174814, -8.331666660288079],
              [-39.101666666783217, -8.331666659388759],
              [-39.101666665883897, -8.332499992780413],
              [-39.099166666608312, -8.332499992780413],
              [-39.099166666608312, -8.337499994029429],
              [-39.098333331418075, -8.337499993130109],
              [-39.098333333216715, -8.33916665901404],
              [-39.096666665534144, -8.33916665901404],
              [-39.096666668232103, -8.338333326521763],
              [-39.095833333041867, -8.338333326521763],
              [-39.095833333041867, -8.337499993130109],
              [-39.094999998750893, -8.337499993130109],
              [-39.094999999650213, -8.336666658839192],
              [-39.094166667157936, -8.336666658839192],
              [-39.094166665359296, -8.335833326346915],
              [-39.091666666983031, -8.335833326346915],
              [-39.091666666983031, -8.336666657939872],
              [-39.090833331792794, -8.336666658839192],
              [-39.090833333591434, -8.334999993854581],
              [-39.08999999840114, -8.334999992055941],
              [-39.09000000019978, -8.33333332617201],
              [-39.090833331792794, -8.33333332617201],
              [-39.090833333591434, -8.329999994404147],
              [-39.091666666083711, -8.329999993504828],
              [-39.091666666983031, -8.329166658314591],
              [-39.090833332692114, -8.329166659213911],
              [-39.090833332692114, -8.325833325647409],
              [-39.08999999840114, -8.325833325647409],
              [-39.08999999840114, -8.324999993155132],
              [-39.089166667707502, -8.324999993155132],
              [-39.089166666808183, -8.324166658864158],
              [-39.09000000019978, -8.324166658864158],
              [-39.090000001099156, -8.323333326371881],
              [-39.089166665908863, -8.323333325472561],
              [-39.089166665908863, -8.316666659238876],
              [-39.087499999125612, -8.316666659238876],
              [-39.087500000024932, -8.315833325847279],
              [-39.086666666633334, -8.315833326746599],
              [-39.086666666633334, -8.313333325672374],
              [-39.085833332342361, -8.313333325672374],
              [-39.085833331443041, -8.314166659064028],
              [-39.084999998950764, -8.314166659064028],
              [-39.084999999850083, -8.314999993354945],
              [-39.08416666555911, -8.314999993354945],
              [-39.084166669156389, -8.318333326022127],
              [-39.083333333966152, -8.318333326022127],
              [-39.083333332167513, -8.320833326196976],
              [-39.082499999675179, -8.320833325297656],
              [-39.082499998775859, -8.322499992980227],
              [-39.083333333966152, -8.322499992980227],
              [-39.083333333066832, -8.323333326371881],
              [-39.082499999675179, -8.323333326371881],
              [-39.082499999675179, -8.324166658864158],
              [-39.081666668082221, -8.324166658864158],
              [-39.081666667182901, -8.326666659039063],
              [-39.080833332891984, -8.326666659938383],
              [-39.080833333791304, -8.329166659213911],
              [-39.081666665384262, -8.329166659213911],
              [-39.081666667182901, -8.329999994404147],
              [-39.080833332891984, -8.329999994404147],
              [-39.080833332891984, -8.334166658664344],
              [-39.081666668082221, -8.334166658664344],
              [-39.081666667182901, -8.334999992955261],
              [-39.082499998775859, -8.334999993854581],
              [-39.082499998775859, -8.336666658839192],
              [-39.081666667182901, -8.336666658839192],
              [-39.081666664484942, -8.338333326521763],
              [-39.082499998775859, -8.338333326521763],
              [-39.082499997876539, -8.341666658289626],
              [-39.083333332167513, -8.341666659188945],
              [-39.083333334865472, -8.343333325972196],
              [-39.08416666555911, -8.343333325972196],
              [-39.08416666555911, -8.34499999275539],
              [-39.085000000749403, -8.34499999275539],
              [-39.084999998950764, -8.347499992930295],
              [-39.08416666555911, -8.347499992930295],
              [-39.08416666645843, -8.350833326496797],
              [-39.083333333066832, -8.350833326496797],
              [-39.083333333066832, -8.351666658989075],
              [-39.080833333791304, -8.351666658989075],
              [-39.080833333791304, -8.350833326496797],
              [-39.076666665933828, -8.350833327396117],
              [-39.076666666833148, -8.351666658089755],
              [-39.075833333441551, -8.351666658989075],
              [-39.075833331642912, -8.350833324698158],
              [-39.075000000049897, -8.350833326496797],
              [-39.075000001848537, -8.351666658989075],
              [-39.073333331468007, -8.351666658989075],
              [-39.073333331468007, -8.350833326496797],
              [-39.072499999875049, -8.350833326496797],
              [-39.072499999875049, -8.349166658814227],
              [-39.071666665584132, -8.349166658814227],
              [-39.071666667382772, -8.348333326321892],
              [-39.070833332192478, -8.348333326321892],
              [-39.070833333991118, -8.344166659363793],
              [-39.070000000599521, -8.344166659363793],
              [-39.070000000599521, -8.342499993479862],
              [-39.070833332192478, -8.342499993479862],
              [-39.070833332192478, -8.341666659188945],
              [-39.070000000599521, -8.341666659188945],
              [-39.069999998800881, -8.340833325797291],
              [-39.069166668107187, -8.340833325797291],
              [-39.069166665409227, -8.33916665901404],
              [-39.06833333291695, -8.33916665901404],
              [-39.06833333291695, -8.337499993130109],
              [-39.067500000424616, -8.337499993130109],
              [-39.067499998625976, -8.336666658839192],
              [-39.066666667033019, -8.336666658839192],
              [-39.066666667033019, -8.335833326346915],
              [-39.065833332742045, -8.335833326346915],
              [-39.065833333641365, -8.334166657765024],
              [-39.065000001149087, -8.334166658664344],
              [-39.064999999350448, -8.330833325997162],
              [-39.064166665958851, -8.330833325997162],
              [-39.06416666775749, -8.329166659213911],
              [-39.063333333466517, -8.329166659213911],
              [-39.063333334365836, -8.326666659039063],
              [-39.064166666858171, -8.326666659039063],
              [-39.064166665958851, -8.325833325647409],
              [-39.064999999350448, -8.325833325647409],
              [-39.065000001149087, -8.323333326371881],
              [-39.065833332742045, -8.323333326371881],
              [-39.065833332742045, -8.322499992080907],
              [-39.067499999525296, -8.322499992980227],
              [-39.067500000424616, -8.320833326196976],
              [-39.066666666133699, -8.320833326196976],
              [-39.066666667932338, -8.319999992805378],
              [-39.065833333641365, -8.319999992805378],
              [-39.065833331842725, -8.319166659413781],
              [-39.064999999350448, -8.319166659413781],
              [-39.064999999350448, -8.31749999442917],
              [-39.064166666858171, -8.31749999352985],
              [-39.064166666858171, -8.316666660138196],
              [-39.06250000007492, -8.316666658339557],
              [-39.0624999991756, -8.31583332494796],
              [-39.058333333116764, -8.315833325847279],
              [-39.058333334016083, -8.316666659238876],
              [-39.057499999725167, -8.316666659238876],
              [-39.057499999725167, -8.31749999352985],
              [-39.054999998650999, -8.317499991731211],
              [-39.054999998650999, -8.318333326022127],
              [-39.054166666158665, -8.318333326022127],
              [-39.054166666158665, -8.319166659413781],
              [-39.052500001174053, -8.319166659413781],
              [-39.052500001174053, -8.319999992805378],
              [-39.050833333491482, -8.319999992805378],
              [-39.050833332592163, -8.320833327096295],
              [-39.050833333491482, -8.32166665868931],
              [-39.050000000999205, -8.32166665778999],
              [-39.050000000099885, -8.324999993155132],
              [-39.050833332592163, -8.324999993155132],
              [-39.050833331692843, -8.325833325647409],
              [-39.051666665084497, -8.325833325647409],
              [-39.051666665983817, -8.326666658139743],
              [-39.050833331692843, -8.326666659039063],
              [-39.050833331692843, -8.328333325822257],
              [-39.049999999200566, -8.328333325822257],
              [-39.050000000999205, -8.329999993504828],
              [-39.049166665808912, -8.329999993504828],
              [-39.049166665808912, -8.331666659388759],
              [-39.050000000099885, -8.331666659388759],
              [-39.050000000999205, -8.332499992780413],
              [-39.050833332592163, -8.332499992780413],
              [-39.050833331692843, -8.334166657765024],
              [-39.050000000999205, -8.334166658664344],
              [-39.050000000099885, -8.33916665901404],
              [-39.049166665808912, -8.33916665901404],
              [-39.049166666708231, -8.342499993479862],
              [-39.048333334215954, -8.342499994379182],
              [-39.048333331517995, -8.34333332507282],
              [-39.049166666708231, -8.343333325972196],
              [-39.049166666708231, -8.344166659363793],
              [-39.048333332417315, -8.344166658464474],
              [-39.048333332417315, -8.345833326147044],
              [-39.049166665808912, -8.345833326147044],
              [-39.049166666708231, -8.347499993829615],
              [-39.049999999200566, -8.347499992930295],
              [-39.049999999200566, -8.349166658814227],
              [-39.050833331692843, -8.349166658814227],
              [-39.050833332592163, -8.351666658989075],
              [-39.050000000099885, -8.351666658989075],
              [-39.049999999200566, -8.352499993279991],
              [-39.050833331692843, -8.352499993279991],
              [-39.050833332592163, -8.354166659163923],
              [-39.051666665983817, -8.354166659163923],
              [-39.051666665983817, -8.354999993454896],
              [-39.052499998476094, -8.354999993454896],
              [-39.052500001174053, -8.355833325947174],
              [-39.053333332767068, -8.355833325947174],
              [-39.053333333666387, -8.356666659338828],
              [-39.054166666158665, -8.356666658439508],
              [-39.054166667957304, -8.361666658789204],
              [-39.052499999375414, -8.361666659688524],
              [-39.052500001174053, -8.362499993080178],
              [-39.051666666883136, -8.362499993080178],
              [-39.051666666883136, -8.363333326471775],
              [-39.050833331692843, -8.363333326471775],
              [-39.050833332592163, -8.367499993429931],
              [-39.052500001174053, -8.367499993429931],
              [-39.052499998476094, -8.369166659313805],
              [-39.053333331867748, -8.369166659313805],
              [-39.053333332767068, -8.370833326097056],
              [-39.052500001174053, -8.370833325197736],
              [-39.052499999375414, -8.37166665948871],
              [-39.050833331692843, -8.37166666038803],
              [-39.050833332592163, -8.372499993779627],
              [-39.049166665808912, -8.372499992880307],
              [-39.049166666708231, -8.37583332464817],
              [-39.048333331517995, -8.375833326446809],
              [-39.048333332417315, -8.376666658939087],
              [-39.046666667432703, -8.376666658939087],
              [-39.046666667432703, -8.378333325722338],
              [-39.045833331343147, -8.378333325722338],
              [-39.045833333141786, -8.379166659113991],
              [-39.045833331343147, -8.380833324997923],
              [-39.044999997951493, -8.380833325897243],
              [-39.044999999750132, -8.38166665928884],
              [-39.045833331343147, -8.38166665928884],
              [-39.045833333141786, -8.385833326246939],
              [-39.044999997951493, -8.385833326246939],
              [-39.044999999750132, -8.389166658914121],
              [-39.044166666358535, -8.389166658914121],
              [-39.044166668157175, -8.389999993205095],
              [-39.043333332067562, -8.389999992305775],
              [-39.043333333866201, -8.391666659089026],
              [-39.043333332067562, -8.392499993379943],
              [-39.045833333141786, -8.392499992480623],
              [-39.045833333141786, -8.391666659089026],
              [-39.053333332767068, -8.391666659089026],
              [-39.053333331867748, -8.390833325697372],
              [-39.054166667057984, -8.390833327496011],
              [-39.054166667957304, -8.391666658189649],
              [-39.055833332042596, -8.391666659089026],
              [-39.055833332042596, -8.392499992480623],
              [-39.055833333841235, -8.396666657640083],
              [-39.056666667232889, -8.396666659438722],
              [-39.056666665434193, -8.397499992830376],
              [-39.057499999725167, -8.397499993729696],
              [-39.057499998825847, -8.399999993005224],
              [-39.058333332217444, -8.399999992105904],
              [-39.058333334016083, -8.401666659788475],
              [-39.059166665609098, -8.401666658889155],
              [-39.059166667407737, -8.403333324773087],
              [-39.059999999000695, -8.403333325672406],
              [-39.059999999900015, -8.404999992455657],
              [-39.060833332392349, -8.404999992455657],
              [-39.060833332392349, -8.407499994429145],
              [-39.061666666683266, -8.407499993529825],
              [-39.061666665783946, -8.408333326022159],
              [-39.0624999991756, -8.408333326022159],
              [-39.06250000007492, -8.409166659413756],
              [-39.063333331667877, -8.409166659413756],
              [-39.063333332567197, -8.409999993704673],
              [-39.058333332217444, -8.409999993704673],
              [-39.058333332217444, -8.412499992980258],
              [-39.057499999725167, -8.412499992980258],
              [-39.057499998825847, -8.413333325472536],
              [-39.056666667232889, -8.413333326371855],
              [-39.056666665434193, -8.415833325647441],
              [-39.054999999550319, -8.415833325647441],
              [-39.054999998650999, -8.416666659039038],
              [-39.054166667957304, -8.416666659039038],
              [-39.054166667057984, -8.417499993329955],
              [-39.052500001174053, -8.417499993329955],
              [-39.052499999375414, -8.418333324922969],
              [-39.051666667782456, -8.418333325822289],
              [-39.051666666883136, -8.417499993329955],
              [-39.050833331692843, -8.417499993329955],
              [-39.050833332592163, -8.418333325822289],
              [-39.048333331517995, -8.418333325822289],
              [-39.048333331517995, -8.419166659213886],
              [-39.045833334041106, -8.419166660113206],
              [-39.045833333141786, -8.418333325822289],
              [-39.044166669056494, -8.418333324922969],
              [-39.044166666358535, -8.417499993329955],
              [-39.041666667982327, -8.417499993329955],
              [-39.041666664384991, -8.418333325822289],
              [-39.041666667982327, -8.419166660113206],
              [-39.042499998675964, -8.419166659213886],
              [-39.042499999575284, -8.420833325097817],
              [-39.043333332067562, -8.420833326896457],
              [-39.043333332067562, -8.422499992780388],
              [-39.044166668157175, -8.422499992780388],
              [-39.044166666358535, -8.423333326172042],
              [-39.043333332067562, -8.423333326172042],
              [-39.043333332966881, -8.427499993130141],
              [-39.045000000649452, -8.427499993130141],
              [-39.044999998850813, -8.428333326521738],
              [-39.046666666533383, -8.428333324723098],
              [-39.046666665634064, -8.427499994029461],
              [-39.047499999025717, -8.427499994029461],
              [-39.047500000824357, -8.428333326521738],
              [-39.049166665808912, -8.428333326521738],
              [-39.049166666708231, -8.430833325797323],
              [-39.049999999200566, -8.430833324898003],
              [-39.050000000999205, -8.432499993479894],
              [-39.051666666883136, -8.432499991681198],
              [-39.051666667782456, -8.43166665918892],
              [-39.054999997751679, -8.4316666582896],
              [-39.055000000449638, -8.430833325797323],
              [-39.056666668132209, -8.430833325797323],
              [-39.056666665434193, -8.429999993304989],
              [-39.059999999000695, -8.429999993304989],
              [-39.059999999900015, -8.429166659014072],
              [-39.061666665783946, -8.429166659014072],
              [-39.061666666683266, -8.432499991681198],
              [-39.063333332567197, -8.432499993479894],
              [-39.063333331667877, -8.434166658464449],
              [-39.064999999350448, -8.434166660263088],
              [-39.064999999350448, -8.434999992755422],
              [-39.065833331842725, -8.434999992755422],
              [-39.065833331842725, -8.4358333252477],
              [-39.066666666133699, -8.435833327046339],
              [-39.066666666133699, -8.436666658639353],
              [-39.070000000599521, -8.436666658639353],
              [-39.069999999700201, -8.43749999293027],
              [-39.070833333091798, -8.43749999293027],
              [-39.070833332192478, -8.438333326321924],
              [-39.072500000774369, -8.438333326321924],
              [-39.072499999875049, -8.439166658814202],
              [-39.07416666755762, -8.439166657914882],
              [-39.07416666755762, -8.439999993105175],
              [-39.074999997351938, -8.439999993105175],
              [-39.074999999150577, -8.440833326496772],
              [-39.075833331642912, -8.440833326496772],
              [-39.075833333441551, -8.444166659163955],
              [-39.076666665933828, -8.444166659163955],
              [-39.076666667732468, -8.444999993454871],
              [-39.080833332891984, -8.444999991656232],
              [-39.080833332891984, -8.445833325947206],
              [-39.083333332167513, -8.445833326846525],
              [-39.083333332167513, -8.446666659338803],
              [-39.08416666645843, -8.446666659338803],
              [-39.08416666555911, -8.447499992730457],
              [-39.085833334141, -8.447499992730457],
              [-39.085833334141, -8.448333326122054],
              [-39.087500000024932, -8.448333327021373],
              [-39.087500000024932, -8.449166659513651],
              [-39.089166666808183, -8.449166659513651],
              [-39.089166665908863, -8.448333326122054],
              [-39.090833331792794, -8.448333326122054],
              [-39.090833332692114, -8.445833325947206],
              [-39.091666666983031, -8.445833325947206],
              [-39.091666666083711, -8.444166659163955],
              [-39.092499999475365, -8.444166659163955],
              [-39.092500000374685, -8.439999993105175],
              [-39.091666666983031, -8.439999993105175],
              [-39.091666666083711, -8.438333326321924],
              [-39.093333332866962, -8.438333327221244],
              [-39.093333331967642, -8.43749999293027],
              [-39.095000000549533, -8.43749999293027],
              [-39.094999999650213, -8.436666657740034],
              [-39.096666668232103, -8.436666658639353],
              [-39.096666665534144, -8.435833326147019],
              [-39.098333334116035, -8.435833326147019],
              [-39.098333331418075, -8.434166659363768],
              [-39.097499998925741, -8.434166659363768],
              [-39.097500000724438, -8.429166659014072],
              [-39.100000000899286, -8.429166659014072],
              [-39.099999999100646, -8.428333327421058],
              [-39.100833333391563, -8.428333326521738],
              [-39.100833332492243, -8.427499993130141],
              [-39.101666664984577, -8.427499993130141],
              [-39.101666665883897, -8.426666657939847],
              [-39.104166666958065, -8.426666658839167],
              [-39.104166666958065, -8.42583332634689],
              [-39.104999999450399, -8.42583332544757],
              [-39.104999998551023, -8.427499993130141],
              [-39.105833331942677, -8.427499993130141],
              [-39.105833331942677, -8.428333326521738],
              [-39.106666666233593, -8.428333326521738],
              [-39.106666667132913, -8.429166659014072],
              [-39.107499998725928, -8.429166659014072],
              [-39.107499998725928, -8.430833325797323],
              [-39.108333332117525, -8.430833325797323],
              [-39.108333332117525, -8.43166665918892],
              [-39.109166665509179, -8.43166665918892],
              [-39.109166666408498, -8.432499991681198],
              [-39.110000000699415, -8.432499993479894],
              [-39.109999998900776, -8.433333325072851],
              [-39.109166666408498, -8.433333325972171],
              [-39.109166668207138, -8.435833326147019],
              [-39.109999998900776, -8.435833326147019],
              [-39.109999998900776, -8.440833324698133],
              [-39.110833333191749, -8.440833326496772],
              [-39.110833333191749, -8.442499993280023],
              [-39.111666666583346, -8.442499993280023],
              [-39.111666665684027, -8.443333325772301],
              [-39.11250000087432, -8.443333325772301],
              [-39.113333332467278, -8.443333324872981],
              [-39.113333332467278, -8.442499993280023],
              [-39.114166666758194, -8.442499993280023],
              [-39.114166665858875, -8.44333332667162],
              [-39.117500001224016, -8.443333325772301],
              [-39.117499998526057, -8.444166658264635],
              [-39.119166667107947, -8.444166659163955],
              [-39.119166668007267, -8.443333325772301],
              [-39.120833332092559, -8.443333325772301],
              [-39.120833333891198, -8.442499993280023],
              [-39.121666666383476, -8.442499992380704],
              [-39.121666666383476, -8.441666659888369],
              [-39.12249999977513, -8.441666659888369],
              [-39.12249999887581, -8.440833326496772],
              [-39.123333331368087, -8.440833324698133],
              [-39.123333334066047, -8.438333326321924],
              [-39.124166666558381, -8.438333326321924],
              [-39.124166665659061, -8.432499993479894],
              [-39.125000000849298, -8.432499993479894],
              [-39.124999999949978, -8.43166665918892],
              [-39.126666666733229, -8.43166665918892],
              [-39.126666667632549, -8.429999994204309],
              [-39.127499999225563, -8.429999993304989],
              [-39.127500000124883, -8.429166659014072],
              [-39.126666665833909, -8.429166659014072],
              [-39.126666667632549, -8.426666659738487],
              [-39.127499999225563, -8.426666658839167],
              [-39.127500000124883, -8.42583332634689],
              [-39.12833333171784, -8.42583332634689],
              [-39.12833333351648, -8.423333327071362],
              [-39.129166666008814, -8.423333326172042],
              [-39.129166666008814, -8.422499992780388],
              [-39.129999999400411, -8.422499992780388],
              [-39.129999999400411, -8.417499993329955],
              [-39.13416666635851, -8.417499992430635],
              [-39.13416666545919, -8.416666659039038],
              [-39.135833333141761, -8.416666659039038],
              [-39.135833334041081, -8.415833325647441],
              [-39.136666666533415, -8.415833325647441],
              [-39.136666666533415, -8.414999994054426],
              [-39.137499999925012, -8.414999993155106],
              [-39.137500000824332, -8.410833326197007],
              [-39.13833333061865, -8.410833326197007],
              [-39.138333334215986, -8.409999992805353],
              [-39.139999999200541, -8.409999992805353],
              [-39.14000000009986, -8.409166659413756],
              [-39.144166666158696, -8.409166659413756],
              [-39.144166667957336, -8.409999991906034],
              [-39.145000000449613, -8.409999992805353],
              [-39.144999998650974, -8.409166659413756],
              [-39.148333333116796, -8.409166659413756],
              [-39.148333333116796, -8.409999992805353],
              [-39.149999999900047, -8.409999992805353],
              [-39.149999999000727, -8.409166659413756],
              [-39.150833335090283, -8.409166659413756],
              [-39.150833332392324, -8.408333326022159],
              [-39.152500000074895, -8.408333326022159],
              [-39.152499999175575, -8.407499993529825],
              [-39.154999998451103, -8.407499993529825],
              [-39.154999999350423, -8.406666660138228],
              [-39.155833333641397, -8.406666659238908],
              [-39.155833332742077, -8.404166659064003],
              [-39.156666667032994, -8.404166659963323],
              [-39.156666666133674, -8.403333325672406],
              [-39.157500000424648, -8.403333325672406],
              [-39.157500000424648, -8.402499993180072],
              [-39.159166665409259, -8.402499993180072],
              [-39.159166667207899, -8.401666658889155],
              [-39.161666665584107, -8.401666658889155],
              [-39.161666666483427, -8.399999993005224],
              [-39.160833330393814, -8.399999993005224],
              [-39.16083333219251, -8.399166658714307],
              [-39.161666667382747, -8.399166658714307],
              [-39.161666666483427, -8.398333326221973],
              [-39.164166666658275, -8.398333326221973],
              [-39.164166667557595, -8.397499992830376],
              [-39.165000000949249, -8.397499992830376],
              [-39.165000000949249, -8.396666659438722],
              [-39.164166666658275, -8.396666659438722],
              [-39.164166664859636, -8.394999991756151],
              [-39.163333331468039, -8.394999992655471],
              [-39.163333332367358, -8.39333332587222],
              [-39.164166667557595, -8.39333332587222],
              [-39.164166666658275, -8.392499993379943],
              [-39.165833330743567, -8.392499993379943],
              [-39.165833331642887, -8.390833325697372],
              [-39.1666666677325, -8.390833325697372],
              [-39.16666666683318, -8.389999992305775],
              [-39.167500000224777, -8.389999992305775],
              [-39.167500001124097, -8.386666658739273],
              [-39.16666666593386, -8.386666658739273],
              [-39.16666666683318, -8.383333326072091],
              [-39.167500000224777, -8.383333326072091],
              [-39.167499998426138, -8.382499992680494],
              [-39.168333332717111, -8.382499992680494],
              [-39.168333332717111, -8.374999992155892],
              [-39.169999999500305, -8.374999993954532],
              [-39.170000000399682, -8.374166658764238],
              [-39.17249999967521, -8.374166658764238],
              [-39.172499998775891, -8.373333326271961],
              [-39.175833334141032, -8.373333326271961],
              [-39.175833331443073, -8.372499993779627],
              [-39.17666666573399, -8.372499992880307],
              [-39.17666666573399, -8.37166665948871],
              [-39.177500000024963, -8.37166665948871],
              [-39.177500000924283, -8.369166659313805],
              [-39.179166667707534, -8.369166659313805],
              [-39.179166665908838, -8.368333325022888],
              [-39.179999998401172, -8.368333325922208],
              [-39.179999998401172, -8.367499994329251],
              [-39.18249999857602, -8.367499992530611],
              [-39.18249999857602, -8.366666659138957],
              [-39.18416666805723, -8.366666660038277],
              [-39.184166667157911, -8.36583332574736],
              [-39.186666665534119, -8.36583332664668],
              [-39.186666665534119, -8.364999993255026],
              [-39.18833333321669, -8.364999992355706],
              [-39.18833333141805, -8.364166658064789],
              [-39.192499998376206, -8.364166658064789],
              [-39.192500000174846, -8.363333324673135],
              [-39.198333332117556, -8.363333327371095],
              [-39.198333333016876, -8.362499993080178],
              [-39.200833331393085, -8.362499993080178],
              [-39.200833333191724, -8.363333326471775],
              [-39.202499999075656, -8.363333327371095],
              [-39.202499999075656, -8.362499993080178],
              [-39.203333332467253, -8.362499993080178],
              [-39.203333335165269, -8.361666658789204],
              [-39.204166665858907, -8.361666658789204],
              [-39.204166666758226, -8.362499993080178],
              [-39.205833331742838, -8.362499993080178],
              [-39.205833331742838, -8.361666658789204],
              [-39.211666668182147, -8.361666658789204],
              [-39.21499999995001, -8.361666658789204],
              [-39.21499999905069, -8.360833326296927],
              [-39.219166668706748, -8.360833326296927],
              [-39.219166666908109, -8.35999999290533],
              [-39.220000001199026, -8.35999999290533],
              [-39.220000001199026, -8.359166657715036],
              [-39.221666666183637, -8.359166660412995],
              [-39.221666666183637, -8.358333326122079],
              [-39.222499999575291, -8.358333326122079],
              [-39.222500000474611, -8.359166659513676],
              [-39.22666666653339, -8.359166659513676],
              [-39.22666666743271, -8.360833326296927],
              [-39.227500000824364, -8.360833325397607],
              [-39.227499999025667, -8.361666658789204],
              [-39.228333331518002, -8.361666658789204],
              [-39.228333330618682, -8.362499993080178],
              [-39.230000000099892, -8.362499992180858],
              [-39.230000000099892, -8.363333326471775],
              [-39.23083333169285, -8.363333324673135],
              [-39.230833332592169, -8.364999993255026],
              [-39.231666667782463, -8.364999993255026],
              [-39.231666667782463, -8.36583332574736],
              [-39.233333331867755, -8.36583332574736],
              [-39.233333331867755, -8.366666659138957],
              [-39.238333333116771, -8.366666659138957],
              [-39.239999999900022, -8.366666659138957],
              [-39.240000000799341, -8.36583332574736],
              [-39.240833334190995, -8.36583332574736],
              [-39.240833331493036, -8.364999992355706],
              [-39.243333331667884, -8.364999993255026],
              [-39.243333332567204, -8.364166658964109],
              [-39.244166665958801, -8.364166658964109],
              [-39.244166666858121, -8.363333326471775],
              [-39.244999999350455, -8.363333326471775],
              [-39.244999998451135, -8.362499993080178],
              [-39.247499999525303, -8.362499993080178],
              [-39.247500000424623, -8.361666658789204],
              [-39.249166665409234, -8.361666658789204],
              [-39.249166665409234, -8.360833326296927],
              [-39.250000000599528, -8.360833326296927],
              [-39.249999999700208, -8.35999999290533],
              [-39.251666665584082, -8.35999999290533],
              [-39.251666666483402, -8.359166660412995],
              [-39.253333332367333, -8.359166657715036],
              [-39.253333331468014, -8.357499992730425],
              [-39.256666666833155, -8.357499992730425],
              [-39.256666665933835, -8.358333326122079],
              [-39.257500000224809, -8.358333326122079],
              [-39.257500000224809, -8.360833326296927],
              [-39.258333332717086, -8.360833326296927],
              [-39.258333333616406, -8.361666659688524],
              [-39.261666668082228, -8.361666658789204],
              [-39.261666668082228, -8.362499993080178],
              [-39.265833331443048, -8.362499993080178],
              [-39.265833332342368, -8.363333326471775],
              [-39.266666665734022, -8.363333326471775],
              [-39.266666667532661, -8.364166658964109],
              [-39.267500000024938, -8.364166658964109],
              [-39.267500000024938, -8.364999993255026],
              [-39.268333331617896, -8.364999993255026],
              [-39.268333333416535, -8.36583332664668],
              [-39.269999998401147, -8.36583332574736],
              [-39.270000000199786, -8.364999993255026],
              [-39.271666666983037, -8.364999992355706],
              [-39.271666666983037, -8.364166658964109],
              [-39.272500000374691, -8.364166659863429],
              [-39.274166668057262, -8.364166658964109],
              [-39.274166667157942, -8.363333326471775],
              [-39.275833332142497, -8.363333326471775],
              [-39.275833332142497, -8.364166658964109],
              [-39.276666666433471, -8.364166658964109],
              [-39.27666666823211, -8.364999994154346],
              [-39.277499998925748, -8.364999993255026],
              [-39.277500000724388, -8.36583332574736],
              [-39.279999999100653, -8.36583332574736],
              [-39.279999999100653, -8.364999993255026],
              [-39.28083333159293, -8.364999993255026],
              [-39.28083333249225, -8.364166658964109],
              [-39.283333332667098, -8.364166658964109],
              [-39.283333331767778, -8.363333326471775],
              [-39.284166666958072, -8.363333324673135],
              [-39.284166666058752, -8.362499993080178],
              [-39.284999999450349, -8.362499993080178],
              [-39.285000000349669, -8.361666658789204],
              [-39.285833333741323, -8.361666658789204],
              [-39.285833332842003, -8.360833326296927],
              [-39.287499999625254, -8.360833326296927],
              [-39.287499999625254, -8.35999999290533],
              [-39.289166666408505, -8.359999992005953],
              [-39.289166668207145, -8.359166660412995],
              [-39.29250000087427, -8.359166657715036],
              [-39.29249999997495, -8.358333325222759],
              [-39.301666665484163, -8.358333326122079],
              [-39.301666666383483, -8.357499992730425],
              [-39.302499999775137, -8.357499992730425],
              [-39.302500000674456, -8.356666659338828],
              [-39.302499998875817, -8.354999993454896],
              [-39.303333331368094, -8.354999993454896],
              [-39.303333333166734, -8.351666658989075],
              [-39.304166664759748, -8.351666658989075],
              [-39.304166665659068, -8.349999993105143],
              [-39.302500000674456, -8.349999993105143],
              [-39.302499999775137, -8.348333326321892],
              [-39.301666665484163, -8.348333326321892],
              [-39.301666665484163, -8.346666658639322],
              [-39.302500000674456, -8.346666660437961],
              [-39.302500000674456, -8.347499992930295],
              [-39.303333333166734, -8.347499993829615],
              [-39.303333330468774, -8.348333326321892],
              [-39.304166666558388, -8.348333325422573],
              [-39.304166667457707, -8.349166657914907],
              [-39.305000000849304, -8.349166658814227],
              [-39.304999999949985, -8.349999993105143],
              [-39.305833331542999, -8.349999993105143],
              [-39.305833331542999, -8.350833326496797],
              [-39.314166664559878, -8.350833326496797],
              [-39.314166665459197, -8.349999993105143],
              [-39.314999999750171, -8.349999993105143],
              [-39.314999999750171, -8.350833324698158],
              [-39.318333331517977, -8.350833326496797],
              [-39.318333334215936, -8.349999993105143],
              [-39.319999999200547, -8.349999993105143],
              [-39.320000001898507, -8.350833326496797],
              [-39.321666666883118, -8.350833324698158],
              [-39.321666666883118, -8.351666658089755],
              [-39.325833332042578, -8.351666658989075],
              [-39.325833333841217, -8.350833326496797],
              [-39.327499998825829, -8.350833327396117],
              [-39.327499999725148, -8.349999993105143],
              [-39.329999999000734, -8.349999992205824],
              [-39.329999999000734, -8.352499994179368],
              [-39.335833332742084, -8.352499993279991],
              [-39.335833331842764, -8.351666658989075],
              [-39.339166667207905, -8.351666658989075],
              [-39.339166665409266, -8.350833326496797],
              [-39.343333332367365, -8.350833326496797],
              [-39.343333331468045, -8.349999993105143],
              [-39.346666665933867, -8.349999993105143],
              [-39.346666666833187, -8.349166658814227],
              [-39.348333331817742, -8.349166658814227],
              [-39.348333333616438, -8.348333326321892],
              [-39.352499999675217, -8.348333326321892],
              [-39.352500000574537, -8.349166658814227],
              [-39.356666666633316, -8.349166658814227],
              [-39.356666666633316, -8.348333326321892],
              [-39.363333332867001, -8.348333326321892],
              [-39.363333332867001, -8.347499993829615],
              [-39.362500002173306, -8.347499993829615],
              [-39.362499999475347, -8.346666658639322],
              [-39.361666666983069, -8.346666658639322],
              [-39.361666667882389, -8.345833326147044],
              [-39.360833332692096, -8.345833327046364],
              [-39.360833332692096, -8.34499999275539],
              [-39.360000000199818, -8.34499999275539],
              [-39.360000000199818, -8.343333325972196],
              [-39.359166666808164, -8.343333325972196],
              [-39.359166666808164, -8.342499993479862],
              [-39.358333332517248, -8.342499993479862],
              [-39.358333332517248, -8.33916665901404],
              [-39.357499999125594, -8.33916665901404],
              [-39.357500000924233, -8.334999992955261],
              [-39.356666666633316, -8.334999992955261],
              [-39.356666665733997, -8.334166658664344],
              [-39.355833334141039, -8.334166660462984],
              [-39.355833334141039, -8.33333332617201],
              [-39.354999998950746, -8.33333332617201],
              [-39.354999999850065, -8.334166658664344],
              [-39.354166665559148, -8.334166657765024],
              [-39.354166666458468, -8.334999992955261],
              [-39.349166666108715, -8.334999992955261],
              [-39.349166666108715, -8.335833326346915],
              [-39.347500000224784, -8.335833327246235],
              [-39.347500000224784, -8.33333332617201],
              [-39.346666665933867, -8.33333332527269],
              [-39.346666665933867, -8.331666659388759],
              [-39.347500001124104, -8.331666660288079],
              [-39.347499998426144, -8.329999993504828],
              [-39.349166666108715, -8.329999993504828],
              [-39.349166668806674, -8.329166659213911],
              [-39.349999998600993, -8.329166659213911],
              [-39.349999998600993, -8.328333325822257],
              [-39.351666667182883, -8.328333325822257],
              [-39.351666668082203, -8.327499994229299],
              [-39.352499999675217, -8.32749999332998],
              [-39.352499998775897, -8.326666659039063],
              [-39.353333333066814, -8.326666659039063],
              [-39.353333332167495, -8.324166658864158],
              [-39.352499999675217, -8.324166659763478],
              [-39.352499997876578, -8.319999993704698],
              [-39.353333333966134, -8.319999992805378],
              [-39.353333333066814, -8.319166659413781],
              [-39.354166667357788, -8.319166658514405],
              [-39.354166667357788, -8.318333326022127],
              [-39.354999999850065, -8.318333326022127],
              [-39.355000000749385, -8.316666660138196],
              [-39.354166666458468, -8.316666659238876],
              [-39.352500000574537, -8.316666660138196],
              [-39.352499998775897, -8.315833325847279],
              [-39.350833333791286, -8.31583332494796],
              [-39.350833331992646, -8.314999993354945],
              [-39.349166666108715, -8.314999993354945],
              [-39.349166667907355, -8.314166659064028],
              [-39.348333331817742, -8.314166659064028],
              [-39.348333331817742, -8.313333325672374],
              [-39.347499998426144, -8.313333324773055],
              [-39.347500000224784, -8.312499994079417],
              [-39.348333331817742, -8.312499993180097],
              [-39.348333332717061, -8.311666658889123],
              [-39.349166666108715, -8.311666658889123],
              [-39.349166667008035, -8.312499993180097],
              [-39.350000000399632, -8.312499993180097],
              [-39.350000000399632, -8.311666658889123],
              [-39.355833331443023, -8.311666658889123],
              [-39.355833332342343, -8.310833326396846],
              [-39.355833332342343, -8.308333326221998],
              [-39.354999999850065, -8.308333326221998],
              [-39.355000000749385, -8.306666660338067],
              [-39.354166667357788, -8.306666659438747],
              [-39.354166666458468, -8.304999992655496],
              [-39.353333333066814, -8.304999992655496],
              [-39.353333333066814, -8.303333325872245],
              [-39.351666665384244, -8.303333325872245],
              [-39.351666665384244, -8.301666659088994],
              [-39.350833332891966, -8.301666659088994],
              [-39.350833332891966, -8.299999992305743],
              [-39.349999998600993, -8.299999993205063],
              [-39.349999998600993, -8.293333326072116],
              [-39.349166667907355, -8.293333326072116],
              [-39.349166666108715, -8.292499992680462],
              [-39.348333332717061, -8.292499992680462],
              [-39.348333332717061, -8.291666659288865],
              [-39.347500001124104, -8.291666659288865],
              [-39.347500000224784, -8.289999992505614],
              [-39.346666666833187, -8.289999993404933],
              [-39.346666665933867, -8.28916665911396],
              [-39.345833331642893, -8.28916665911396],
              [-39.345833331642893, -8.286666658939112],
              [-39.346666665933867, -8.286666658939112],
              [-39.346666665933867, -8.285833326446834],
              [-39.345833333441533, -8.285833326446834],
              [-39.345833331642893, -8.28499999305518],
              [-39.344166665758962, -8.28499999305518],
              [-39.344166665758962, -8.284166658764264],
              [-39.343333331468045, -8.284166658764264],
              [-39.343333332367365, -8.277499993429899],
              [-39.341666666483434, -8.277499993429899],
              [-39.341666665584114, -8.276666659138982],
              [-39.337500000424654, -8.276666660038302],
              [-39.337499999525335, -8.271666658789229],
              [-39.336666667033001, -8.271666658789229],
              [-39.336666667033001, -8.270833326296952],
              [-39.335833333641403, -8.270833327196272],
              [-39.335833331842764, -8.269999992905298],
              [-39.33499999845111, -8.269999992905298],
              [-39.335000002048389, -8.268333326122047],
              [-39.335833331842764, -8.268333326122047],
              [-39.335833333641403, -8.263333325772294],
              [-39.336666666133681, -8.263333325772294],
              [-39.336666666133681, -8.262499993280016],
              [-39.337499999525335, -8.262499993280016],
              [-39.337499999525335, -8.263333325772294],
              [-39.338333332017612, -8.263333325772294],
              [-39.338333332916932, -8.264166659163948],
              [-39.34083333219246, -8.264166659163948],
              [-39.34083333309178, -8.264999993454865],
              [-39.341666665584114, -8.264999994354184],
              [-39.341666666483434, -8.264166659163948],
              [-39.342499998975711, -8.264166659163948],
              [-39.342499998975711, -8.263333325772294],
              [-39.343333332367365, -8.263333324872974],
              [-39.343333332367365, -8.262499994179336],
              [-39.344166667557602, -8.262499994179336],
              [-39.344166665758962, -8.2616666589891],
              [-39.345000000049936, -8.2616666589891],
              [-39.345000000949256, -8.260833326496765],
              [-39.350000000399632, -8.260833326496765],
              [-39.349999999500312, -8.2616666589891],
              [-39.351666667182883, -8.2616666589891],
              [-39.351666665384244, -8.262499992380697],
              [-39.352499998775897, -8.262499992380697],
              [-39.352499998775897, -8.263333325772294],
              [-39.354166666458468, -8.263333325772294],
              [-39.354166665559148, -8.264166659163948],
              [-39.354999999850065, -8.264166658264628],
              [-39.354999998950746, -8.264999993454865],
              [-39.356666665733997, -8.264999993454865],
              [-39.356666666633316, -8.265833325047879],
              [-39.359166666808164, -8.265833325947199],
              [-39.359166666808164, -8.264999993454865],
              [-39.360833333591415, -8.264999993454865],
              [-39.360833331792776, -8.264166659163948],
              [-39.361666667882389, -8.264166659163948],
              [-39.362500002173306, -8.264166659163948],
              [-39.362500000374666, -8.262499993280016],
              [-39.363333331967681, -8.262499993280016],
              [-39.36333333376632, -8.2616666589891],
              [-39.364166667157917, -8.2616666589891],
              [-39.364166668057237, -8.260833326496765],
              [-39.364999999650195, -8.260833324698126],
              [-39.364999998750875, -8.259999994004488],
              [-39.365833332142529, -8.259999993105168],
              [-39.365833333041849, -8.259166658814195],
              [-39.368333333216697, -8.259166658814195],
              [-39.368333333216697, -8.258333326321917],
              [-39.369166666608351, -8.258333326321917],
              [-39.36916666750767, -8.256666658639347],
              [-39.368333331418057, -8.256666658639347],
              [-39.368333331418057, -8.255833326147012],
              [-39.365833332142529, -8.255833326147012],
              [-39.365833333041849, -8.254999992755415],
              [-39.363333332867001, -8.254999992755415],
              [-39.363333332867001, -8.253333325972164],
              [-39.362500000374666, -8.253333325972164],
              [-39.362500000374666, -8.251666659188913],
              [-39.36166666608375, -8.251666659188913],
              [-39.361666666983069, -8.250833325797316],
              [-39.360833331792776, -8.250833324897997],
              [-39.360833333591415, -8.249166659014065],
              [-39.359999998401179, -8.249166658114746],
              [-39.360000001099138, -8.248333327421051],
              [-39.359166665908845, -8.248333326521731],
              [-39.359166665908845, -8.247499993130134],
              [-39.358333332517248, -8.247499992230814],
              [-39.358333331617928, -8.24666665883916],
              [-39.357500000024913, -8.24666665883916],
              [-39.357500000024913, -8.244999992955286],
              [-39.356666666633316, -8.244999992955286],
              [-39.356666667532636, -8.243333326172035],
              [-39.355833332342343, -8.243333326172035],
              [-39.355833331443023, -8.237499993330005],
              [-39.354999999850065, -8.237499992430685],
              [-39.354999999850065, -8.232499992980252],
              [-39.354166667357788, -8.232499992980252],
              [-39.354166666458468, -8.231666658689278],
              [-39.353333333066814, -8.231666658689278],
              [-39.353333333066814, -8.229999992805404],
              [-39.352499999675217, -8.229999992805404],
              [-39.352500000574537, -8.22916665941375],
              [-39.351666668082203, -8.22916665941375],
              [-39.351666667182883, -8.228333326022153],
              [-39.350833332891966, -8.228333326022153],
              [-39.350833333791286, -8.229166660313069],
              [-39.345833331642893, -8.229166660313069],
              [-39.345833331642893, -8.228333326022153],
              [-39.345000000949256, -8.228333326921472],
              [-39.345000000049936, -8.22916665941375],
              [-39.343333331468045, -8.22916665941375],
              [-39.343333331468045, -8.228333326022153],
              [-39.3408333339911, -8.228333326022153],
              [-39.339999999700183, -8.228333326022153],
              [-39.339999999700183, -8.227499994429138],
              [-39.333333333466499, -8.227499993529818],
              [-39.333333331667859, -8.228333326022153],
              [-39.332499997376942, -8.228333326022153],
              [-39.332499999175582, -8.22916665941375],
              [-39.331666666683304, -8.22916665941375],
              [-39.331666666683304, -8.228333326022153],
              [-39.33083333419097, -8.228333326921472],
              [-39.330833331493011, -8.227499993529818],
              [-39.321666666883118, -8.227499993529818],
              [-39.321666665983798, -8.226666659238902],
              [-39.31916666580895, -8.226666659238902],
              [-39.31916666580895, -8.227499993529818],
              [-39.315833334041088, -8.227499994429138],
              [-39.315833334041088, -8.226666659238902],
              [-39.314999998850851, -8.226666659238902],
              [-39.314999998850851, -8.225833325847248],
              [-39.314166665459197, -8.225833325847248],
              [-39.314166666358517, -8.22499999335497],
              [-39.312499999575266, -8.22499999335497],
              [-39.312499999575266, -8.224166659063997],
              [-39.310000002098377, -8.224166659063997],
              [-39.309999999400418, -8.223333325672399],
              [-39.306666667632555, -8.223333325672399],
              [-39.306666666733236, -8.222499993180122],
              [-39.305833333341639, -8.222499993180122],
              [-39.305833332442319, -8.216666657640076],
              [-39.30750000012489, -8.216666659438715],
              [-39.307500001024209, -8.215833326047118],
              [-39.309999998501098, -8.215833326946438],
              [-39.309999998501098, -8.214999992655464],
              [-39.310833331892695, -8.214999992655464],
              [-39.310833332792015, -8.214166659263867],
              [-39.311666667982308, -8.214166659263867],
              [-39.311666667082989, -8.21333332677159],
              [-39.312499999575266, -8.21333332587227],
              [-39.312499998675946, -8.211666659089019],
              [-39.31333333296692, -8.211666659089019],
              [-39.3133333320676, -8.209166658914114],
              [-39.314166665459197, -8.209166658914114],
              [-39.314166669056476, -8.207499993030183],
              [-39.314999998850851, -8.207499993030183],
              [-39.314999998850851, -8.204999992855335],
              [-39.315833334041088, -8.204999992855335],
              [-39.315833334041088, -8.204166660363057],
              [-39.316666666533365, -8.204166660363057],
              [-39.316666666533365, -8.203333326072084],
              [-39.317499999925019, -8.203333325172764],
              [-39.31749999812638, -8.202499992680487],
              [-39.318333334215936, -8.202499994479126],
              [-39.318333334215936, -8.201666660188152],
              [-39.31916666580895, -8.201666659288833],
              [-39.31916666580895, -8.199999993404901],
              [-39.320000000999187, -8.199999994304221],
              [-39.320000000099867, -8.197499993230053],
              [-39.320833331692882, -8.197499993230053],
              [-39.320833331692882, -8.195833326446802],
              [-39.321666665983798, -8.195833326446802],
              [-39.321666665983798, -8.191666657690064],
              [-39.322499998476133, -8.191666659488703],
              [-39.322499999375452, -8.189166659313855],
              [-39.321666665983798, -8.189166659313855],
              [-39.321666666883118, -8.187499993429924],
              [-39.320000000999187, -8.187499993429924],
              [-39.320000000099867, -8.18666665913895],
              [-39.317499999925019, -8.18666665913895],
              [-39.317500000824339, -8.184999993255019],
              [-39.316666666533365, -8.184999993255019],
              [-39.316666665634045, -8.184166658964102],
              [-39.315833333141768, -8.184166659863422],
              [-39.315833331343129, -8.183333326471768],
              [-39.315000000649491, -8.183333326471768],
              [-39.314166664559878, -8.183333327371088],
              [-39.314166668157156, -8.184166658964102],
              [-39.3133333320676, -8.184166658064782],
              [-39.31333333296692, -8.184999992355699],
              [-39.312499999575266, -8.184999993255019],
              [-39.312499998675946, -8.185833325747353],
              [-39.310833331892695, -8.185833325747353],
              [-39.310833332792015, -8.18666665913895],
              [-39.310833333691335, -8.187499993429924],
              [-39.309999999400418, -8.187499993429924],
              [-39.310000001199057, -8.189166659313855],
              [-39.307499999225513, -8.189166659313855],
              [-39.307499999225513, -8.189999994504092],
              [-39.306666667632555, -8.189999992705452],
              [-39.306666665833916, -8.1924999928803],
              [-39.305833333341639, -8.1924999928803],
              [-39.305833332442319, -8.193333325372635],
              [-39.304999999050665, -8.193333326271954],
              [-39.304999999949985, -8.194166658764232],
              [-39.303333333166734, -8.194166658764232],
              [-39.303333333166734, -8.194999993055205],
              [-39.302499998875817, -8.194999993055205],
              [-39.302499998875817, -8.195833326446802],
              [-39.303333333166734, -8.195833326446802],
              [-39.303333334066053, -8.197499993230053],
              [-39.300833332991886, -8.197499993230053],
              [-39.300833333891205, -8.198333325722331],
              [-39.300000000499608, -8.198333325722331],
              [-39.300000000499608, -8.199166660013304],
              [-39.296666666933106, -8.199166659113985],
              [-39.296666666933106, -8.198333325722331],
              [-39.295000001049175, -8.198333325722331],
              [-39.295000001049175, -8.199166659113985],
              [-39.294166667657521, -8.199166659113985],
              [-39.294166665858882, -8.198333325722331],
              [-39.29250000087427, -8.198333325722331],
              [-39.292499999075631, -8.194166658764232],
              [-39.290833333191756, -8.194166658764232],
              [-39.290833333191756, -8.189166659313855],
              [-39.289999999800102, -8.189166659313855],
              [-39.289999999800102, -8.187499994329244],
              [-39.289166666408505, -8.187499993429924],
              [-39.289166668207145, -8.18666665913895],
              [-39.288333333016851, -8.18666665913895],
              [-39.288333333916171, -8.184999993255019],
              [-39.282500000174821, -8.184999993255019],
              [-39.282500000174821, -8.184166658964102],
              [-39.28083333159293, -8.184166658964102],
              [-39.28083333249225, -8.183333326471768],
              [-39.277499998925748, -8.183333326471768],
              [-39.277499999825068, -8.182499993080171],
              [-39.27666666823211, -8.182499993080171],
              [-39.276666666433471, -8.181666658789197],
              [-39.273333331967649, -8.181666658789197],
              [-39.273333333766288, -8.18083332629692],
              [-39.27083333359144, -8.18083332629692],
              [-39.27083333359144, -8.179999992905323],
              [-39.269166666808189, -8.179999992905323],
              [-39.26916666590887, -8.178333326122072],
              [-39.268333333416535, -8.178333326122072],
              [-39.268333333416535, -8.176666659338821],
              [-39.267500000024938, -8.176666659338821],
              [-39.267499999125619, -8.17499999345489],
              [-39.26499999985009, -8.174999994354209],
              [-39.26500000074941, -8.174166659163916],
              [-39.262499998775866, -8.174166659163916],
              [-39.262499999675185, -8.17499999345489],
              [-39.260000000399657, -8.17499999345489],
              [-39.259999998601018, -8.173333325772319],
              [-39.25916666790738, -8.173333326671639],
              [-39.25916666790738, -8.172499993280042],
              [-39.258333333616406, -8.172499993280042],
              [-39.258333332717086, -8.170833324698151],
              [-39.256666665933835, -8.170833326496791],
              [-39.256666665933835, -8.169999993105137],
              [-39.255833331642918, -8.169999993105137],
              [-39.255833332542238, -8.16916665881422],
              [-39.255000000049904, -8.16916665881422],
              [-39.254999999150584, -8.168333326321886],
              [-39.253333331468014, -8.168333326321886],
              [-39.253333334165973, -8.16916665881422],
              [-39.252499998975736, -8.16916665881422],
              [-39.252500000774376, -8.16499999365476],
              [-39.253333332367333, -8.16499999275544],
              [-39.253333331468014, -8.159166658114714],
              [-39.254166665758987, -8.159166658114714],
              [-39.254166666658307, -8.154999993854574],
              [-39.254999999150584, -8.154999993854574],
              [-39.254999999150584, -8.154166658664337],
              [-39.254166666658307, -8.154166658664337],
              [-39.254166666658307, -8.153333326172003],
              [-39.252499998076416, -8.153333326172003],
              [-39.252500000774376, -8.151666659388752],
              [-39.253333334165973, -8.151666659388752],
              [-39.253333331468014, -8.148333325822307],
              [-39.252499998975736, -8.148333325822307],
              [-39.252500000774376, -8.146666660837695],
              [-39.251666666483402, -8.146666659039056],
              [-39.251666665584082, -8.145833324748082],
              [-39.250833332192485, -8.145833325647402],
              [-39.250833333991125, -8.144999992255805],
              [-39.249166667207874, -8.144999993155125],
              [-39.249166667207874, -8.14249999298022],
              [-39.248333332017637, -8.14249999298022],
              [-39.248333332017637, -8.141666658689303],
              [-39.247499999525303, -8.141666658689303],
              [-39.247499999525303, -8.140833326197026],
              [-39.246666666133706, -8.140833326197026],
              [-39.246666667033026, -8.139999992805372],
              [-39.245833331842732, -8.139999992805372],
              [-39.245833332742052, -8.141666658689303],
              [-39.244166666858121, -8.141666658689303],
              [-39.244166666858121, -8.142499993879539],
              [-39.243333331667884, -8.1424999920809],
              [-39.243333331667884, -8.143333326371874],
              [-39.241666666683273, -8.143333326371874],
              [-39.241666667582592, -8.144166659763471],
              [-39.23833333401609, -8.144166658864151],
              [-39.23833333491541, -8.144999994054444],
              [-39.237499998825854, -8.144999993155125],
              [-39.237499998825854, -8.145833325647402],
              [-39.236666668132159, -8.145833325647402],
              [-39.2366666654342, -8.146666659039056],
              [-39.235833332941922, -8.146666659938376],
              [-39.235833333841242, -8.148333325822307],
              [-39.235000000449645, -8.148333325822307],
              [-39.235000000449645, -8.149999994404197],
              [-39.234166667957311, -8.149999993504878],
              [-39.234166667057991, -8.152499992780406],
              [-39.232499998476101, -8.152499992780406],
              [-39.23249999937542, -8.153333325272683],
              [-39.230833332592169, -8.153333326172003],
              [-39.23083333169285, -8.154166658664337],
              [-39.229166666708238, -8.154166658664337],
              [-39.229166666708238, -8.154999992955254],
              [-39.228333332417321, -8.154999992955254],
              [-39.228333332417321, -8.156666658839185],
              [-39.227499999924987, -8.156666659738505],
              [-39.227499999924987, -8.159999993305007],
              [-39.225833333141793, -8.159999993305007],
              [-39.225833333141793, -8.160833325797284],
              [-39.224999999750139, -8.160833325797284],
              [-39.224999998850819, -8.161666659188938],
              [-39.224166668157181, -8.161666660088258],
              [-39.224166665459222, -8.162499993479855],
              [-39.223333333866208, -8.162499993479855],
              [-39.223333332067568, -8.164166659363786],
              [-39.222499998675971, -8.164166659363786],
              [-39.222499998675971, -8.165833326147037],
              [-39.219999998501066, -8.165833327046357],
              [-39.219999997601747, -8.16499999275544],
              [-39.219166667807428, -8.16499999275544],
              [-39.219166666908109, -8.164166659363786],
              [-39.217499999225538, -8.164166659363786],
              [-39.217500000124858, -8.163333325972189],
              [-39.21666666763258, -8.163333325972189],
              [-39.21666666763258, -8.162499993479855],
              [-39.215833331542967, -8.162499993479855],
              [-39.215833331542967, -8.161666659188938],
              [-39.214166666558356, -8.161666660088258],
              [-39.214166665659036, -8.159999993305007],
              [-39.213333331368119, -8.159999993305007],
              [-39.213333331368119, -8.159166659014033],
              [-39.212499998875785, -8.159166659014033],
              [-39.212499998875785, -8.158333326521756],
              [-39.210833332092534, -8.158333326521756],
              [-39.210833332991854, -8.157499993130159],
              [-39.211666668182147, -8.157499993130159],
              [-39.211666666383508, -8.156666658839185],
              [-39.210833332092534, -8.156666658839185],
              [-39.210833332092534, -8.154999992955254],
              [-39.211666666383508, -8.154999992955254],
              [-39.211666666383508, -8.152499991881086],
              [-39.212499997976465, -8.152499992780406],
              [-39.212499998875785, -8.150833325997155],
              [-39.211666666383508, -8.150833325997155],
              [-39.211666666383508, -8.149166659213904],
              [-39.21083333479055, -8.149166659213904],
              [-39.210833332092534, -8.148333325822307],
              [-39.209999999600257, -8.148333325822307],
              [-39.209999998700937, -8.147499993329973],
              [-39.209166668007299, -8.147499993329973],
              [-39.209166668007299, -8.146666659039056],
              [-39.207499998526089, -8.146666659039056],
              [-39.207500001224048, -8.145833325647402],
              [-39.206666667832394, -8.145833325647402],
              [-39.206666666033755, -8.139166659413775],
              [-39.205833332642158, -8.139166659413775],
              [-39.205833333541477, -8.13666665923887],
              [-39.206666666933074, -8.13666665923887],
              [-39.206666666033755, -8.135833326746592],
              [-39.205833332642158, -8.135833325847273],
              [-39.205833331742838, -8.134166659064022],
              [-39.205000000149823, -8.134166659064022],
              [-39.204999998351184, -8.13249999318009],
              [-39.204166666758226, -8.13249999318009],
              [-39.204166665858907, -8.13083332549752],
              [-39.201666665684058, -8.130833326396839],
              [-39.201666666583378, -8.129999993005242],
              [-39.200833334091044, -8.129999993005242],
              [-39.200833331393085, -8.130833327296159],
              [-39.199166666408473, -8.130833326396839],
              [-39.199166668207113, -8.131666658889174],
              [-39.196666667132945, -8.131666658889174],
              [-39.196666666233625, -8.130833326396839],
              [-39.195000002148333, -8.130833326396839],
              [-39.194999999450374, -8.131666658889174],
              [-39.194166667857417, -8.131666658889174],
              [-39.19416666605872, -8.13083332549752],
              [-39.193333333566443, -8.130833326396839],
              [-39.193333331767803, -8.129999993005242],
              [-39.192499997476887, -8.129999993005242],
              [-39.192499998376206, -8.127499992830337],
              [-39.191666667682512, -8.127499992830337],
              [-39.191666666783192, -8.124999991756169],
              [-39.189166666608344, -8.124999992655489],
              [-39.189166667507664, -8.125833325147767],
              [-39.186666666433439, -8.125833326047086],
              [-39.186666666433439, -8.124999992655489],
              [-39.183333333766313, -8.124999992655489],
              [-39.183333331967674, -8.124166659263892],
              [-39.18249999947534, -8.124166659263892],
              [-39.181666666083743, -8.124166659263892],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '123',
        OBJECTID: 165.0,
        Nome_area: 'Serras do Almirante e Boqueirão',
        COD_area: 'CA171',
        Import_bio: 'Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Criação de UC de categoria indefinida',
        Area_ha: 100439.531,
        Shape_Leng: 4.16,
        Shape_Area: 0.08244,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.102499999075633, -8.177499994529057],
              [-40.102500000874272, -8.178333326122072],
              [-40.100833331393062, -8.178333326122072],
              [-40.100833331393062, -8.179166659513669],
              [-40.099999999800104, -8.179166660412989],
              [-40.099999999800104, -8.179999992006003],
              [-40.098333333016853, -8.179999992905323],
              [-40.098333332117534, -8.18083332629692],
              [-40.095833331942686, -8.18083332629692],
              [-40.095833332842005, -8.181666658789197],
              [-40.09, -8.181666658789197],
              [-40.089999999100655, -8.18083332629692],
              [-40.089166666608321, -8.18083332629692],
              [-40.089166666608321, -8.179999992006003],
              [-40.08749999892575, -8.179999992905323],
              [-40.08749999892575, -8.18083332629692],
              [-40.086666665534153, -8.18083332629692],
              [-40.086666668232112, -8.181666658789197],
              [-40.085833332142499, -8.181666658789197],
              [-40.085833332142499, -8.183333327371088],
              [-40.084166667157945, -8.183333326471768],
              [-40.084166667157945, -8.184166658064782],
              [-40.083333332866971, -8.184166658964102],
              [-40.083333331967651, -8.183333326471768],
              [-40.08166666608372, -8.183333326471768],
              [-40.08166666698304, -8.182499993080171],
              [-40.079999998401149, -8.182499993080171],
              [-40.080000000199789, -8.185833325747353],
              [-40.07583333144305, -8.185833325747353],
              [-40.075833334141009, -8.18666665913895],
              [-40.075000000749412, -8.18666665913895],
              [-40.074999999850093, -8.187499993429924],
              [-40.074166665559119, -8.187499993429924],
              [-40.074166665559119, -8.189999992705452],
              [-40.073333332167522, -8.189999992705452],
              [-40.073333333066842, -8.19083332519773],
              [-40.072499998775868, -8.190833326097049],
              [-40.072499999675188, -8.191666659488703],
              [-40.07166666718291, -8.191666659488703],
              [-40.07166666718291, -8.1924999928803],
              [-40.070833332891937, -8.19249999377962],
              [-40.070833332891937, -8.193333326271954],
              [-40.069166667008005, -8.193333326271954],
              [-40.069166667907382, -8.196666658939137],
              [-40.06999999860102, -8.196666658039817],
              [-40.06999999950034, -8.197499993230053],
              [-40.069166667008005, -8.197499993230053],
              [-40.069166667008005, -8.198333325722331],
              [-40.068333333616408, -8.198333325722331],
              [-40.068333331817769, -8.199166658214665],
              [-40.067499998426172, -8.199166659113985],
              [-40.067500001124131, -8.198333325722331],
              [-40.066666665933838, -8.198333325722331],
              [-40.066666667732477, -8.199999994304221],
              [-40.062499999875058, -8.199999993404901],
              [-40.062499999875058, -8.201666660188152],
              [-40.059166667207876, -8.201666659288833],
              [-40.059166668107196, -8.200833325897236],
              [-40.057499999525305, -8.200833325897236],
              [-40.057500000424625, -8.201666659288833],
              [-40.056666667033028, -8.201666658389513],
              [-40.056666666133708, -8.199166659113985],
              [-40.053333334365846, -8.199166659113985],
              [-40.053333331667886, -8.198333325722331],
              [-40.052499999175552, -8.198333324823011],
              [-40.052499999175552, -8.197499994129373],
              [-40.050833331493038, -8.197499993230053],
              [-40.050833331493038, -8.196666659838456],
              [-40.051666666683275, -8.196666658939137],
              [-40.051666667582595, -8.195833326446802],
              [-40.052499999175552, -8.195833326446802],
              [-40.052500000974248, -8.194166659663551],
              [-40.053333331667886, -8.194166658764232],
              [-40.053333332567206, -8.193333326271954],
              [-40.054166666858123, -8.193333326271954],
              [-40.054166666858123, -8.191666659488703],
              [-40.054999998451137, -8.191666659488703],
              [-40.055000001149097, -8.190833326097049],
              [-40.054166667757443, -8.190833326996369],
              [-40.054166667757443, -8.188333325922201],
              [-40.051666666683275, -8.188333325922201],
              [-40.051666665783955, -8.18666665913895],
              [-40.049166667407746, -8.18666665913895],
              [-40.049166666508427, -8.184999992355699],
              [-40.048333334016093, -8.184999993255019],
              [-40.048333332217453, -8.185833325747353],
              [-40.040833331692852, -8.185833325747353],
              [-40.040833332592172, -8.184999993255019],
              [-40.038333332417324, -8.184999993255019],
              [-40.038333332417324, -8.185833325747353],
              [-40.037499999924989, -8.185833325747353],
              [-40.037500000824309, -8.18666665913895],
              [-40.035833333141795, -8.18666665913895],
              [-40.035833333141795, -8.187499993429924],
              [-40.034999999750141, -8.187499993429924],
              [-40.035000000649461, -8.18666665913895],
              [-40.034166665459225, -8.18666665913895],
              [-40.034166668157184, -8.183333326471768],
              [-40.033333332067571, -8.183333326471768],
              [-40.033333332067571, -8.182499993080171],
              [-40.031666666183639, -8.182499992180851],
              [-40.031666666183639, -8.181666658789197],
              [-40.029166667807431, -8.181666658789197],
              [-40.029166666008791, -8.184166658964102],
              [-40.024999999950012, -8.184166658964102],
              [-40.024999999050692, -8.183333326471768],
              [-40.022499998875787, -8.183333326471768],
              [-40.022500000674427, -8.182499993979491],
              [-40.02166666548419, -8.182499992180851],
              [-40.02166666638351, -8.181666658789197],
              [-40.020833332991856, -8.181666657889878],
              [-40.020833332092536, -8.18083332629692],
              [-40.019166668007301, -8.18083332629692],
              [-40.019166666208662, -8.179999992905323],
              [-40.018333332817008, -8.179999992006003],
              [-40.018333334615647, -8.179166659513669],
              [-40.010000000699449, -8.179166659513669],
              [-40.010000000699449, -8.179999992905323],
              [-40.009166665509156, -8.179999993804643],
              [-40.009166665509156, -8.181666658789197],
              [-40.008333333016878, -8.181666658789197],
              [-40.008333333016878, -8.182499993080171],
              [-40.007499998725905, -8.182499993080171],
              [-40.007499998725905, -8.184166658964102],
              [-40.008333333916198, -8.184166658964102],
              [-40.008333332117559, -8.184999993255019],
              [-40.007500000524544, -8.184999993255019],
              [-40.007499999625225, -8.185833325747353],
              [-40.006666666233627, -8.185833325747353],
              [-40.006666667132947, -8.18666665913895],
              [-40.006666668032267, -8.187499992530604],
              [-40.005833332841974, -8.187499993429924],
              [-40.005833331942654, -8.188333325922201],
              [-40.005000000349696, -8.188333325922201],
              [-40.004999998551057, -8.189166658414536],
              [-40.004166667857419, -8.189166659313855],
              [-40.004166666958099, -8.189999992705452],
              [-40.003333330868486, -8.189999992705452],
              [-40.003333331767806, -8.191666657690064],
              [-40.004166666058723, -8.191666659488703],
              [-40.004166666058723, -8.194999993055205],
              [-40.004999999450376, -8.194999993055205],
              [-40.004999999450376, -8.196666658939137],
              [-40.004166666058723, -8.196666658939137],
              [-40.004166666058723, -8.199999993404901],
              [-40.004999999450376, -8.199999994304221],
              [-40.004999998551057, -8.200833324997916],
              [-40.009166666408476, -8.200833324997916],
              [-40.009166665509156, -8.199999993404901],
              [-40.013333331567935, -8.199999993404901],
              [-40.013333331567935, -8.199166659113985],
              [-40.01583333174284, -8.199166659113985],
              [-40.01583333264216, -8.202499992680487],
              [-40.01583333354148, -8.203333326072084],
              [-40.014166666758229, -8.203333326072084],
              [-40.014166666758229, -8.204999992855335],
              [-40.013333331567935, -8.204999992855335],
              [-40.013333331567935, -8.206666658739266],
              [-40.011666664784684, -8.206666658739266],
              [-40.0116666674827, -8.207499993030183],
              [-40.010833334091046, -8.207499993030183],
              [-40.010833333191727, -8.208333326421837],
              [-40.009166666408476, -8.208333325522517],
              [-40.009166668207115, -8.209166658914114],
              [-40.008333333016878, -8.209166658914114],
              [-40.008333332117559, -8.209999993205088],
              [-40.006666666233627, -8.209999992305768],
              [-40.006666666233627, -8.211666659089019],
              [-40.002499998376209, -8.211666659089019],
              [-40.002500000174848, -8.212499993379936],
              [-40.001666667682514, -8.212499994279256],
              [-40.001666666783194, -8.211666659089019],
              [-40.0, -8.211666659089019],
              [-40.0, -8.212499993379936],
              [-39.998333335015332, -8.212499993379936],
              [-39.998333333216692, -8.211666659089019],
              [-39.996666668232137, -8.211666659089019],
              [-39.996666666433441, -8.212499993379936],
              [-39.995833332142524, -8.212499994279256],
              [-39.995833333041844, -8.211666659089019],
              [-39.994999999650247, -8.211666659089019],
              [-39.995000000549567, -8.212499993379936],
              [-39.994166665359273, -8.212499993379936],
              [-39.994166668057233, -8.214166659263867],
              [-39.993333332866996, -8.214166659263867],
              [-39.993333331967676, -8.215833325147798],
              [-39.991666666983065, -8.215833326047118],
              [-39.991666666983065, -8.214999992655464],
              [-39.98916666680816, -8.214999992655464],
              [-39.98916666590884, -8.214166659263867],
              [-39.988333332517243, -8.214166659263867],
              [-39.988333332517243, -8.214999994454161],
              [-39.984999998950741, -8.214999992655464],
              [-39.9850000016487, -8.214166659263867],
              [-39.98333333216749, -8.214166659263867],
              [-39.98333333216749, -8.214999992655464],
              [-39.981666667182878, -8.214999991756144],
              [-39.981666665384239, -8.216666659438715],
              [-39.980833332891962, -8.216666659438715],
              [-39.980833332891962, -8.215833326047118],
              [-39.979999998600988, -8.215833326047118],
              [-39.979999998600988, -8.214999992655464],
              [-39.979166665209391, -8.214999992655464],
              [-39.978333331817794, -8.214999992655464],
              [-39.978333331817794, -8.216666659438715],
              [-39.97916666700803, -8.216666659438715],
              [-39.97916666700803, -8.21916666051294],
              [-39.979999998600988, -8.21916666141226],
              [-39.979999999500308, -8.219999993005217],
              [-39.976666665933863, -8.219999993005217],
              [-39.976666665933863, -8.219166657814981],
              [-39.975833333441528, -8.2191666587143],
              [-39.975833333441528, -8.218333326221966],
              [-39.973333334166, -8.218333326221966],
              [-39.973333332367361, -8.217499992830369],
              [-39.972499998975707, -8.217499992830369],
              [-39.970833333091832, -8.217499991931049],
              [-39.970833333091832, -8.2191666587143],
              [-39.969999998800859, -8.2191666587143],
              [-39.970000000599498, -8.219999993005217],
              [-39.969166667207901, -8.219999993005217],
              [-39.969166667207901, -8.221666658889148],
              [-39.968333332916927, -8.221666658889148],
              [-39.968333332017608, -8.224166658164677],
              [-39.96749999862601, -8.224166659063997],
              [-39.96750000042465, -8.226666659238902],
              [-39.968333332916927, -8.226666659238902],
              [-39.968333332017608, -8.227499991731179],
              [-39.969166665409261, -8.227499994429138],
              [-39.969166667207901, -8.229999992805404],
              [-39.969999997901539, -8.229999992805404],
              [-39.969999999700178, -8.230833326197001],
              [-39.970833333091832, -8.230833326197001],
              [-39.970833333091832, -8.231666658689278],
              [-39.972499999875026, -8.231666658689278],
              [-39.972500001673723, -8.2349999931551],
              [-39.971666666483429, -8.2349999931551],
              [-39.971666667382749, -8.235833325647434],
              [-39.972499998975707, -8.235833325647434],
              [-39.972499998975707, -8.237499993330005],
              [-39.973333331468041, -8.237499993330005],
              [-39.973333331468041, -8.238333326721602],
              [-39.974166665758958, -8.238333325822282],
              [-39.974166665758958, -8.239166659213879],
              [-39.975833331642889, -8.239166659213879],
              [-39.975833333441528, -8.239999993504853],
              [-39.97749999842614, -8.239999993504853],
              [-39.97749999842614, -8.24083332599713],
              [-39.978333332717114, -8.24083332599713],
              [-39.978333331817794, -8.241666659388784],
              [-39.979166666108711, -8.241666659388784],
              [-39.979166666108711, -8.244166657764993],
              [-39.978333331817794, -8.244166658664312],
              [-39.978333333616433, -8.244999992955286],
              [-39.977500000224779, -8.244999992955286],
              [-39.977500000224779, -8.24666665883916],
              [-39.976666666833182, -8.24666665883916],
              [-39.976666665933863, -8.250833325797316],
              [-39.975833331642889, -8.250833325797316],
              [-39.975833332542209, -8.251666659188913],
              [-39.975000000049931, -8.251666659188913],
              [-39.974999999150612, -8.254166661162458],
              [-39.971666667382749, -8.254166659363818],
              [-39.97166666558411, -8.254999992755415],
              [-39.970000000599498, -8.254999992755415],
              [-39.969999998800859, -8.255833327046332],
              [-39.969166667207901, -8.255833326147012],
              [-39.969166667207901, -8.253333325972164],
              [-39.968333333816247, -8.253333325972164],
              [-39.968333332017608, -8.251666659188913],
              [-39.96750000042465, -8.251666659188913],
              [-39.96749999862601, -8.250833325797316],
              [-39.963333331667855, -8.250833324897997],
              [-39.963333332567174, -8.249999994204302],
              [-39.962500000074897, -8.249999993304982],
              [-39.962500000074897, -8.250833325797316],
              [-39.960833334190966, -8.250833325797316],
              [-39.960833331493006, -8.252499993479887],
              [-39.959999999900049, -8.252499993479887],
              [-39.959999999000729, -8.253333325972164],
              [-39.957499998825824, -8.253333325972164],
              [-39.957499998825824, -8.254999991856096],
              [-39.956666665434227, -8.254999992755415],
              [-39.956666667232867, -8.255833326147012],
              [-39.955833332042573, -8.255833327046332],
              [-39.955833333841213, -8.256666658639347],
              [-39.954999999550296, -8.256666657740027],
              [-39.955000000449616, -8.257499992930263],
              [-39.954166666158699, -8.257499992930263],
              [-39.954166667058018, -8.256666658639347],
              [-39.953333332767045, -8.256666658639347],
              [-39.953333334565684, -8.251666659188913],
              [-39.950833332592197, -8.251666659188913],
              [-39.950833332592197, -8.252499994379207],
              [-39.949166667607585, -8.252499993479887],
              [-39.949166667607585, -8.251666659188913],
              [-39.948333332417292, -8.251666659188913],
              [-39.948333331517972, -8.252499993479887],
              [-39.947499999025695, -8.252499993479887],
              [-39.947499998126375, -8.251666659188913],
              [-39.946666667432737, -8.251666659188913],
              [-39.946666667432737, -8.250833325797316],
              [-39.945833333141763, -8.250833325797316],
              [-39.945833334041083, -8.249166659014065],
              [-39.944166666358512, -8.249166659014065],
              [-39.944166664559873, -8.24666665883916],
              [-39.943333332067596, -8.246666659738537],
              [-39.943333332067596, -8.245833326346883],
              [-39.942499998675942, -8.245833326346883],
              [-39.942500000474581, -8.244166658664312],
              [-39.943333332067596, -8.244166658664312],
              [-39.943333334765555, -8.242499992780381],
              [-39.942500000474581, -8.242499992780381],
              [-39.942500000474581, -8.241666659388784],
              [-39.941666667982304, -8.241666660288104],
              [-39.941666666183664, -8.242499991881061],
              [-39.940000001199053, -8.242499992780381],
              [-39.939999998501094, -8.243333326172035],
              [-39.939166666008759, -8.243333327071355],
              [-39.939166668706775, -8.244166658664312],
              [-39.9350000008493, -8.244166660462952],
              [-39.93499999905066, -8.244999992055966],
              [-39.933333333166729, -8.244999992955286],
              [-39.933333333166729, -8.244166658664312],
              [-39.931666665484158, -8.244166658664312],
              [-39.931666665484158, -8.243333326172035],
              [-39.928333332817033, -8.243333326172035],
              [-39.928333333716353, -8.244166658664312],
              [-39.927499998526059, -8.244166658664312],
              [-39.92749999762674, -8.243333326172035],
              [-39.926666667832421, -8.243333326172035],
              [-39.926666667832421, -8.244166658664312],
              [-39.925833331742808, -8.244166658664312],
              [-39.925833331742808, -8.242499991881061],
              [-39.924166666758197, -8.242499992780381],
              [-39.924166666758197, -8.241666659388784],
              [-39.92333333246728, -8.241666659388784],
              [-39.92333333246728, -8.239999993504853],
              [-39.922499999075626, -8.239999993504853],
              [-39.922499999975003, -8.239166659213879],
              [-39.919999998900778, -8.239166659213879],
              [-39.919999999800098, -8.238333325822282],
              [-39.915833331942679, -8.238333325822282],
              [-39.915833331942679, -8.237499993330005],
              [-39.914999999450345, -8.237499993330005],
              [-39.914166666058748, -8.237499993330005],
              [-39.914166666958067, -8.238333325822282],
              [-39.913333331767831, -8.238333325822282],
              [-39.91333333266715, -8.239999991706213],
              [-39.914166666058748, -8.239999994404172],
              [-39.914166666958067, -8.24083332599713],
              [-39.914999998551025, -8.24083332509781],
              [-39.915000000349721, -8.244999992055966],
              [-39.913333331767831, -8.244999992955286],
              [-39.913333330868454, -8.24666665883916],
              [-39.911666666783219, -8.24666665883916],
              [-39.911666667682539, -8.247499993130134],
              [-39.910833332492246, -8.247499993130134],
              [-39.910833333391565, -8.248333326521731],
              [-39.909999999100648, -8.248333326521731],
              [-39.91, -8.247499993130134],
              [-39.908333331418078, -8.247499992230814],
              [-39.908333331418078, -8.246666659738537],
              [-39.907499998925744, -8.246666659738537],
              [-39.907500000724383, -8.251666659188913],
              [-39.905833333041869, -8.251666659188913],
              [-39.905833332142549, -8.252499993479887],
              [-39.904166665359298, -8.252499993479887],
              [-39.904166668057258, -8.253333325972164],
              [-39.901666666083713, -8.253333325972164],
              [-39.901666666083713, -8.252499993479887],
              [-39.899166666808185, -8.252499993479887],
              [-39.899166667707505, -8.253333325072845],
              [-39.895833332342363, -8.253333325972164],
              [-39.895833332342363, -8.254166659363818],
              [-39.891666668082223, -8.254166659363818],
              [-39.891666667182903, -8.254999992755415],
              [-39.888333333616401, -8.254999992755415],
              [-39.888333332717082, -8.255833326147012],
              [-39.88499999915058, -8.255833327046332],
              [-39.885000000049899, -8.256666658639347],
              [-39.880833332192481, -8.256666658639347],
              [-39.88083333399112, -8.257499992030944],
              [-39.878333332017633, -8.257499992930263],
              [-39.878333332017633, -8.258333326321917],
              [-39.874166665958796, -8.258333326321917],
              [-39.874166665958796, -8.259166658814195],
              [-39.870833332392351, -8.259166658814195],
              [-39.870833331493031, -8.259999993105168],
              [-39.869999999900017, -8.259999994004488],
              [-39.868333332217446, -8.259999992205849],
              [-39.868333333116766, -8.260833326496765],
              [-39.865833333841238, -8.260833326496765],
              [-39.865833332941918, -8.2616666589891],
              [-39.864999998651001, -8.2616666589891],
              [-39.864999999550321, -8.263333325772294],
              [-39.860833331692845, -8.263333325772294],
              [-39.860833331692845, -8.264166659163948],
              [-39.858333332417317, -8.264166659163948],
              [-39.858333332417317, -8.264999993454865],
              [-39.858333331517997, -8.266666659338796],
              [-39.859166667607553, -8.266666659338796],
              [-39.859166667607553, -8.26749999273045],
              [-39.860000000099888, -8.26749999273045],
              [-39.859999999200568, -8.271666658789229],
              [-39.860833330793525, -8.271666658789229],
              [-39.860833332592165, -8.272499993979466],
              [-39.862499999375416, -8.272499993080146],
              [-39.862499998476096, -8.2733333264718],
              [-39.861666666883139, -8.27333332737112],
              [-39.861666665084499, -8.274999993255051],
              [-39.860833332592165, -8.274999993255051],
              [-39.860833331692845, -8.275833325747328],
              [-39.861666666883139, -8.275833325747328],
              [-39.861666665983819, -8.276666659138982],
              [-39.86333333276707, -8.276666659138982],
              [-39.86333333186775, -8.275833325747328],
              [-39.865000000449641, -8.275833325747328],
              [-39.864999998651001, -8.276666659138982],
              [-39.866666664534876, -8.276666659138982],
              [-39.866666668132211, -8.277499994329219],
              [-39.868333334016086, -8.277499993429899],
              [-39.868333333116766, -8.278333325922233],
              [-39.867499999725169, -8.278333325022857],
              [-39.867499999725169, -8.279999992705427],
              [-39.8691666656091, -8.279999992705427],
              [-39.8691666656091, -8.280833326097081],
              [-39.870000000799337, -8.280833325197761],
              [-39.869999999000697, -8.282499992880332],
              [-39.871666667582588, -8.282499992880332],
              [-39.871666666683268, -8.283333326271929],
              [-39.871666666683268, -8.284166659663583],
              [-39.870833331493031, -8.284166658764264],
              [-39.870833331493031, -8.285833326446834],
              [-39.869999999900017, -8.285833326446834],
              [-39.870000000799337, -8.286666658939112],
              [-39.868333333116766, -8.286666658939112],
              [-39.868333332217446, -8.288333325722363],
              [-39.8691666656091, -8.288333325722363],
              [-39.86916666650842, -8.292499992680462],
              [-39.868333332217446, -8.292499992680462],
              [-39.868333334915405, -8.293333326072116],
              [-39.867499998825849, -8.293333326072116],
              [-39.867499999725169, -8.294166659463713],
              [-39.865833332042598, -8.294166659463713],
              [-39.865833332941918, -8.295833326246964],
              [-39.864999998651001, -8.295833326246964],
              [-39.865000000449641, -8.296666657839921],
              [-39.861666665983819, -8.296666659638561],
              [-39.861666666883139, -8.297499993030215],
              [-39.860833331692845, -8.297499993030215],
              [-39.860833331692845, -8.298333326421812],
              [-39.85749999902572, -8.298333326421812],
              [-39.85749999902572, -8.297499993030215],
              [-39.853333332966884, -8.297499993030215],
              [-39.853333332067564, -8.298333327321131],
              [-39.851666667982272, -8.298333326421812],
              [-39.851666667982272, -8.301666658189674],
              [-39.849166667807424, -8.301666659088994],
              [-39.849166667807424, -8.302499993379968],
              [-39.847500001024173, -8.302499992480648],
              [-39.847500000124853, -8.303333325872245],
              [-39.845833332442282, -8.303333326771565],
              [-39.845833333341602, -8.304166660163162],
              [-39.842500000674477, -8.304166659263842],
              [-39.842500000674477, -8.304999992655496],
              [-39.841666666383503, -8.304999994454136],
              [-39.841666668182143, -8.305833326047093],
              [-39.839999998700932, -8.305833326047093],
              [-39.839999998700932, -8.306666659438747],
              [-39.838333333716321, -8.306666657640108],
              [-39.838333331917681, -8.307499992830344],
              [-39.837499998526084, -8.307499992830344],
              [-39.837499999425404, -8.308333326221998],
              [-39.83666666693307, -8.308333327121318],
              [-39.83666666693307, -8.307499993729664],
              [-39.831666666583374, -8.307499992830344],
              [-39.831666664784734, -8.308333326221998],
              [-39.830833334091039, -8.308333325322678],
              [-39.830833334091039, -8.307499992830344],
              [-39.829999999800123, -8.307499993729664],
              [-39.828333332117552, -8.307499992830344],
              [-39.828333333016872, -8.309166660512915],
              [-39.827500000524537, -8.309166658714275],
              [-39.827499997826578, -8.309999993005249],
              [-39.82666666713294, -8.309999993005249],
              [-39.826666666233621, -8.312499993180097],
              [-39.827499998725898, -8.312499993180097],
              [-39.827499999625218, -8.313333325672374],
              [-39.828333333916191, -8.313333325672374],
              [-39.828333333016872, -8.314166659963348],
              [-39.829166665509149, -8.314166659963348],
              [-39.829166666408469, -8.319999992805378],
              [-39.82666666713294, -8.319999992805378],
              [-39.826666666233621, -8.320833325297656],
              [-39.822500000174841, -8.320833326196976],
              [-39.822499998376202, -8.319999992805378],
              [-39.82, -8.319999992805378],
              [-39.820000000899256, -8.320833326196976],
              [-39.816666665534171, -8.320833326196976],
              [-39.816666668232131, -8.319999993704698],
              [-39.815833332142518, -8.319999992805378],
              [-39.815833333041837, -8.319166659413781],
              [-39.814166667157906, -8.319166659413781],
              [-39.814166665359267, -8.318333326022127],
              [-39.812500000374655, -8.318333325122808],
              [-39.812499999475335, -8.317499991731211],
              [-39.811666666083738, -8.317499991731211],
              [-39.811666666083738, -8.316666659238876],
              [-39.809999998401167, -8.316666659238876],
              [-39.810000000199807, -8.31749999352985],
              [-39.80916666590889, -8.31749999442917],
              [-39.80916666590889, -8.319166659413781],
              [-39.808333332517236, -8.319166659413781],
              [-39.808333331617916, -8.319999992805378],
              [-39.807500000024959, -8.319999992805378],
              [-39.807500000924279, -8.320833327096295],
              [-39.806666667532625, -8.320833326196976],
              [-39.806666665733985, -8.32166665868931],
              [-39.805833331443068, -8.32166665868931],
              [-39.805833331443068, -8.322499992980227],
              [-39.804999999850054, -8.322499992980227],
              [-39.805000000749374, -8.3233333272712],
              [-39.801666665384232, -8.323333326371881],
              [-39.796666666833175, -8.323333326371881],
              [-39.796666666833175, -8.322499992980227],
              [-39.794166666658271, -8.322499992980227],
              [-39.794166665758951, -8.32749999332998],
              [-39.793333331468034, -8.32749999332998],
              [-39.793333334165993, -8.328333325822257],
              [-39.792499999875076, -8.328333325822257],
              [-39.792499999875076, -8.329166659213911],
              [-39.791666665584103, -8.329166658314591],
              [-39.791666666483422, -8.329999993504828],
              [-39.790000000599491, -8.329999993504828],
              [-39.789999999700171, -8.330833325997162],
              [-39.788333332017601, -8.330833325997162],
              [-39.788333332017601, -8.331666659388759],
              [-39.787499999525323, -8.331666659388759],
              [-39.787500000424643, -8.33333332617201],
              [-39.784166667757461, -8.33333332617201],
              [-39.784166667757461, -8.334166658664344],
              [-39.781666665783973, -8.334166657765024],
              [-39.781666668481932, -8.33333332617201],
              [-39.780833332392319, -8.33333332617201],
              [-39.780833331493, -8.331666660288079],
              [-39.780000000799362, -8.331666659388759],
              [-39.779999999000722, -8.329166659213911],
              [-39.775833332941943, -8.329166659213911],
              [-39.775833332941943, -8.326666659039063],
              [-39.774999999550289, -8.326666659938383],
              [-39.774999999550289, -8.325833327446048],
              [-39.773333332767038, -8.325833327446048],
              [-39.773333333666358, -8.326666659039063],
              [-39.772499999375441, -8.326666659039063],
              [-39.77250000117408, -8.32749999332998],
              [-39.771666665983787, -8.32749999332998],
              [-39.771666665983787, -8.329999993504828],
              [-39.77083333169287, -8.329999993504828],
              [-39.77083333259219, -8.331666660288079],
              [-39.769999999200536, -8.331666659388759],
              [-39.769999999200536, -8.33333332617201],
              [-39.769166665808939, -8.33333332617201],
              [-39.769166665808939, -8.334166658664344],
              [-39.768333332417342, -8.334166658664344],
              [-39.768333332417342, -8.334999992955261],
              [-39.767499999025688, -8.334999992955261],
              [-39.767499999025688, -8.336666659738512],
              [-39.766666665634091, -8.336666657939872],
              [-39.766666665634091, -8.338333326521763],
              [-39.765833333141757, -8.338333326521763],
              [-39.765833333141757, -8.33916665991336],
              [-39.764166666358506, -8.33916665901404],
              [-39.764166665459186, -8.338333326521763],
              [-39.761666667982297, -8.338333326521763],
              [-39.761666667082977, -8.33916665991336],
              [-39.76083333369138, -8.33916665991336],
              [-39.760833331892684, -8.339999992405694],
              [-39.759999999400407, -8.339999993305014],
              [-39.759999998501087, -8.340833325797291],
              [-39.759166667807449, -8.340833325797291],
              [-39.759166666908129, -8.341666659188945],
              [-39.758333332617156, -8.341666659188945],
              [-39.758333331717836, -8.342499993479862],
              [-39.756666667632544, -8.342499993479862],
              [-39.756666666733224, -8.343333326871516],
              [-39.755833332442307, -8.343333325972196],
              [-39.755833335140267, -8.344166659363793],
              [-39.754166666558376, -8.344166659363793],
              [-39.754166666558376, -8.34499999275539],
              [-39.753333331368083, -8.34499999275539],
              [-39.753333333166722, -8.347499992930295],
              [-39.752499999775125, -8.347499992930295],
              [-39.752499999775125, -8.349166657914907],
              [-39.753333333166722, -8.349166659713546],
              [-39.753333331368083, -8.349999992205824],
              [-39.754166667457696, -8.349999994004463],
              [-39.754166665659056, -8.352499993279991],
              [-39.753333331368083, -8.352499994179368],
              [-39.753333333166722, -8.353333325772326],
              [-39.752499998875805, -8.353333324873006],
              [-39.752499997976486, -8.355833325947174],
              [-39.753333333166722, -8.355833325947174],
              [-39.753333331368083, -8.358333325222759],
              [-39.754166665659056, -8.358333325222759],
              [-39.754166665659056, -8.35999999290533],
              [-39.755000000849293, -8.35999999290533],
              [-39.754999999050654, -8.361666658789204],
              [-39.754166665659056, -8.361666658789204],
              [-39.754166665659056, -8.360833327196247],
              [-39.751666668182168, -8.360833326296927],
              [-39.751666666383528, -8.361666658789204],
              [-39.749999998700957, -8.361666659688524],
              [-39.750000000499597, -8.364166658964109],
              [-39.749166666208623, -8.364166658964109],
              [-39.749166668007263, -8.36583332484804],
              [-39.748333333716346, -8.36583332574736],
              [-39.748333332817026, -8.366666659138957],
              [-39.747500001224012, -8.366666659138957],
              [-39.747499999425372, -8.368333325922208],
              [-39.746666667832415, -8.368333325922208],
              [-39.746666666033775, -8.370833326097056],
              [-39.745833332642121, -8.370833326097056],
              [-39.745833332642121, -8.37166665948871],
              [-39.744999998351204, -8.37166665948871],
              [-39.745000001948483, -8.372499992880307],
              [-39.743333332467273, -8.372499993779627],
              [-39.743333331567953, -8.373333325372641],
              [-39.741666667482662, -8.373333326271961],
              [-39.741666665684022, -8.372499992880307],
              [-39.739999998900771, -8.372499992880307],
              [-39.739999998900771, -8.371666657690071],
              [-39.73833333301684, -8.37166665948871],
              [-39.73833333301684, -8.370833326097056],
              [-39.736666668032285, -8.370833326996376],
              [-39.736666666233589, -8.369999992705459],
              [-39.735833333741311, -8.369999994504099],
              [-39.735833331942672, -8.368333325922208],
              [-39.734166666058741, -8.368333325922208],
              [-39.734166666058741, -8.366666659138957],
              [-39.733333333566463, -8.366666659138957],
              [-39.733333331767824, -8.364999992355706],
              [-39.73249999837617, -8.364999992355706],
              [-39.732500000174809, -8.364166659863429],
              [-39.731666666783212, -8.364166658964109],
              [-39.731666665883893, -8.363333326471775],
              [-39.730833331592919, -8.363333324673135],
              [-39.730833332492239, -8.362499993080178],
              [-39.731666667682532, -8.362499993080178],
              [-39.731666665883893, -8.355833325947174],
              [-39.732500000174809, -8.355833326846493],
              [-39.732500000174809, -8.354999993454896],
              [-39.731666665883893, -8.354999993454896],
              [-39.731666666783212, -8.354166659163923],
              [-39.730833332492239, -8.354166659163923],
              [-39.730833331592919, -8.353333325772326],
              [-39.729999999100642, -8.353333325772326],
              [-39.729999998201322, -8.352499993279991],
              [-39.729166665708988, -8.352499993279991],
              [-39.729166665708988, -8.351666658989075],
              [-39.72833333321671, -8.351666658989075],
              [-39.72833333411603, -8.352499993279991],
              [-39.727499998925794, -8.352499994179368],
              [-39.727499999825113, -8.351666659888394],
              [-39.725833332142543, -8.351666658089755],
              [-39.725833332142543, -8.350833327396117],
              [-39.724999999650208, -8.350833326496797],
              [-39.724999999650208, -8.349166658814227],
              [-39.724166668057251, -8.349166658814227],
              [-39.724166667157931, -8.343333325972196],
              [-39.724999998750889, -8.34333332507282],
              [-39.725000000549528, -8.340833325797291],
              [-39.72249999947536, -8.340833325797291],
              [-39.72249999947536, -8.339999993305014],
              [-39.721666667882346, -8.339999994204334],
              [-39.721666667882346, -8.33916665901404],
              [-39.720000001099152, -8.339166658114721],
              [-39.719999998401136, -8.338333327421083],
              [-39.719166668606817, -8.338333326521763],
              [-39.717499999125607, -8.338333326521763],
              [-39.717499998226288, -8.336666659738512],
              [-39.718333332517261, -8.336666658839192],
              [-39.718333332517261, -8.334999992055941],
              [-39.717499999125607, -8.334999992955261],
              [-39.717500000024927, -8.33333332617201],
              [-39.719166668606817, -8.33333332617201],
              [-39.719166667707498, -8.334166658664344],
              [-39.721666666983026, -8.334166658664344],
              [-39.721666666983026, -8.33333332617201],
              [-39.72249999947536, -8.33333332617201],
              [-39.722499998576041, -8.329999991706188],
              [-39.721666666083706, -8.329999993504828],
              [-39.721666667882346, -8.329166659213911],
              [-39.719166665908858, -8.329166659213911],
              [-39.719166666808178, -8.328333325822257],
              [-39.718333331617941, -8.328333325822257],
              [-39.718333331617941, -8.329166660113231],
              [-39.714999998950759, -8.329166659213911],
              [-39.714999998950759, -8.325833325647409],
              [-39.715833334140996, -8.325833325647409],
              [-39.715833331443037, -8.324999993155132],
              [-39.714999999850079, -8.324999993155132],
              [-39.714999999850079, -8.324166657964838],
              [-39.713333332167508, -8.324166658864158],
              [-39.713333333066828, -8.322499992080907],
              [-39.712500000574551, -8.322499992080907],
              [-39.712499999675174, -8.320833326196976],
              [-39.709166667907368, -8.320833327096295],
              [-39.709166667008049, -8.32166665868931],
              [-39.706666667732463, -8.32166665868931],
              [-39.706666665933824, -8.322499992980227],
              [-39.702499998975725, -8.322499992980227],
              [-39.702499998975725, -8.323333326371881],
              [-39.700000000599516, -8.323333326371881],
              [-39.699999998800877, -8.324166658864158],
              [-39.694999998451124, -8.324166658864158],
              [-39.694999998451124, -8.32166665868931],
              [-39.693333332567192, -8.321666660487949],
              [-39.693333332567192, -8.320833326196976],
              [-39.692500000974235, -8.320833326196976],
              [-39.692500000974235, -8.319999992805378],
              [-39.690833332392344, -8.319999991906059],
              [-39.690833331493025, -8.319166660313101],
              [-39.69000000169865, -8.319166659413781],
              [-39.68999999900069, -8.316666659238876],
              [-39.690833331493025, -8.316666659238876],
              [-39.690833334190984, -8.315833325847279],
              [-39.691666667582581, -8.315833325847279],
              [-39.691666665783941, -8.314166658164709],
              [-39.692500000974235, -8.314166658164709],
              [-39.692500000974235, -8.310833326396846],
              [-39.693333331667873, -8.310833326396846],
              [-39.693333331667873, -8.308333326221998],
              [-39.694166667757486, -8.308333326221998],
              [-39.694166665958846, -8.307499991931024],
              [-39.695000001149083, -8.307499992830344],
              [-39.694999999350443, -8.306666659438747],
              [-39.696666666133694, -8.306666659438747],
              [-39.696666667033014, -8.305833326047093],
              [-39.697499999525292, -8.305833326047093],
              [-39.697499998625972, -8.300833325697397],
              [-39.696666666133694, -8.300833325697397],
              [-39.696666666133694, -8.299999993205063],
              [-39.695833332742097, -8.299999993205063],
              [-39.695833330943401, -8.295833327146283],
              [-39.697499999525292, -8.295833326246964],
              [-39.697500000424611, -8.294999992855367],
              [-39.696666666133694, -8.29499999195599],
              [-39.696666666133694, -8.294166659463713],
              [-39.695833333641417, -8.294166659463713],
              [-39.695833331842721, -8.292499992680462],
              [-39.694999998451124, -8.292499992680462],
              [-39.694999999350443, -8.291666660188184],
              [-39.694166667757486, -8.291666658389545],
              [-39.694166667757486, -8.286666659838431],
              [-39.695000001149083, -8.286666658939112],
              [-39.695000001149083, -8.274999993255051],
              [-39.694166667757486, -8.274999994154371],
              [-39.694166666858166, -8.27333332467316],
              [-39.693333332567192, -8.2733333264718],
              [-39.693333332567192, -8.266666659338796],
              [-39.694166666858166, -8.266666659338796],
              [-39.694166667757486, -8.265833325947199],
              [-39.694999998451124, -8.265833325047879],
              [-39.694999998451124, -8.262499993280016],
              [-39.695833333641417, -8.262499993280016],
              [-39.695833331842721, -8.2616666589891],
              [-39.696666666133694, -8.2616666589891],
              [-39.696666667932334, -8.260833327396085],
              [-39.697499999525292, -8.260833326496765],
              [-39.698333332916945, -8.260833326496765],
              [-39.698333332916945, -8.2616666589891],
              [-39.699166667207862, -8.2616666589891],
              [-39.699166668107182, -8.262499993280016],
              [-39.699999999700196, -8.262499993280016],
              [-39.700000000599516, -8.263333325772294],
              [-39.700833333991113, -8.263333325772294],
              [-39.700833332192474, -8.264166660063267],
              [-39.702499999875045, -8.264166659163948],
              [-39.702499999875045, -8.264999993454865],
              [-39.703333331468002, -8.264999993454865],
              [-39.703333331468002, -8.265833325947199],
              [-39.704166666658296, -8.265833325947199],
              [-39.704166666658296, -8.266666660238116],
              [-39.705000000049893, -8.266666659338796],
              [-39.705000000949269, -8.26749999273045],
              [-39.705833333441547, -8.26749999273045],
              [-39.705833331642907, -8.268333326122047],
              [-39.706666666833144, -8.268333326122047],
              [-39.706666665933824, -8.269166659513701],
              [-39.705833332542227, -8.269166660413021],
              [-39.705833331642907, -8.270833326296952],
              [-39.706666665933824, -8.270833326296952],
              [-39.706666667732463, -8.271666658789229],
              [-39.708333333616395, -8.271666658789229],
              [-39.708333332717075, -8.274166658964077],
              [-39.7108333337913, -8.274166658964077],
              [-39.7108333337913, -8.2733333264718],
              [-39.711666667182897, -8.2733333264718],
              [-39.713333332167508, -8.2733333264718],
              [-39.713333332167508, -8.271666658789229],
              [-39.714166666458425, -8.271666659688549],
              [-39.714166669156441, -8.270833327196272],
              [-39.714999999850079, -8.270833326296952],
              [-39.714999998950759, -8.269166659513701],
              [-39.715833334140996, -8.269166660413021],
              [-39.715833331443037, -8.26749999273045],
              [-39.71666666573401, -8.26749999273045],
              [-39.71666666573401, -8.264999993454865],
              [-39.717500000024927, -8.264999993454865],
              [-39.717500000924247, -8.264166659163948],
              [-39.71666666753265, -8.264166659163948],
              [-39.71666666573401, -8.262499993280016],
              [-39.717500000024927, -8.262499994179336],
              [-39.717499999125607, -8.259999993105168],
              [-39.718333331617941, -8.259999993105168],
              [-39.718333332517261, -8.258333326321917],
              [-39.719166666808178, -8.258333326321917],
              [-39.719166665908858, -8.254166659363818],
              [-39.718333332517261, -8.254166659363818],
              [-39.718333331617941, -8.253333325972164],
              [-39.717500000024927, -8.253333325972164],
              [-39.717500000024927, -8.249999993304982],
              [-39.71666666573401, -8.249999993304982],
              [-39.71666666663333, -8.247499993130134],
              [-39.715833332342356, -8.247499993130134],
              [-39.715833332342356, -8.244999992955286],
              [-39.714999999850079, -8.244999992955286],
              [-39.714999999850079, -8.244166658664312],
              [-39.713333333966148, -8.244166658664312],
              [-39.713333330368869, -8.243333326172035],
              [-39.712499998775854, -8.243333326172035],
              [-39.712499999675174, -8.239166660113199],
              [-39.711666667182897, -8.239166659213879],
              [-39.711666665384257, -8.237499993330005],
              [-39.71083333199266, -8.237499993330005],
              [-39.71083333199266, -8.235833325647434],
              [-39.711666665384257, -8.235833325647434],
              [-39.711666665384257, -8.2349999931551],
              [-39.713333333966148, -8.2349999931551],
              [-39.713333333966148, -8.234166658864183],
              [-39.714166667357745, -8.234166658864183],
              [-39.714166665559105, -8.232499993879571],
              [-39.711666665384257, -8.232499992980252],
              [-39.711666665384257, -8.231666658689278],
              [-39.710000000399646, -8.231666658689278],
              [-39.709999999500326, -8.230833326197001],
              [-39.709166666108729, -8.230833326197001],
              [-39.709166667907368, -8.231666658689278],
              [-39.705833332542227, -8.231666658689278],
              [-39.705833333441547, -8.230833326197001],
              [-39.705000000049893, -8.230833326197001],
              [-39.705000000049893, -8.229166660313069],
              [-39.704166666658296, -8.22916665941375],
              [-39.704166665758976, -8.228333326921472],
              [-39.703333331468002, -8.228333326022153],
              [-39.703333332367379, -8.225833327645887],
              [-39.702499999875045, -8.225833325847248],
              [-39.702499999875045, -8.22499999335497],
              [-39.700833332192474, -8.22499999425429],
              [-39.700833333091794, -8.225833325847248],
              [-39.699166665409223, -8.225833324947928],
              [-39.699166665409223, -8.226666659238902],
              [-39.695000001149083, -8.226666659238902],
              [-39.694999998451124, -8.227499993529818],
              [-39.694166666858166, -8.227499994429138],
              [-39.694166666858166, -8.228333326022153],
              [-39.690833331493025, -8.228333326022153],
              [-39.690833331493025, -8.22916665941375],
              [-39.687499999725162, -8.22916665941375],
              [-39.687499998825842, -8.229999992805404],
              [-39.686666665434245, -8.229999992805404],
              [-39.686666665434245, -8.230833325297681],
              [-39.680833331692838, -8.230833325297681],
              [-39.680833333491478, -8.231666658689278],
              [-39.679166666708284, -8.231666658689278],
              [-39.679166666708284, -8.230833326197001],
              [-39.676666665634059, -8.230833325297681],
              [-39.676666666533379, -8.229999992805404],
              [-39.674999998850808, -8.229999992805404],
              [-39.674999999750128, -8.231666658689278],
              [-39.67416666815717, -8.231666658689278],
              [-39.674166666358531, -8.232499993879571],
              [-39.670833331892709, -8.232499992980252],
              [-39.670833333691348, -8.233333327271168],
              [-39.670000001199071, -8.233333326371849],
              [-39.669999998501112, -8.232499992980252],
              [-39.6683333335165, -8.232499992980252],
              [-39.6683333335165, -8.231666660487917],
              [-39.663333334066067, -8.231666658689278],
              [-39.663333331368108, -8.230833326197001],
              [-39.66250000067447, -8.230833326197001],
              [-39.66249999887583, -8.227499993529818],
              [-39.661666666383496, -8.227499993529818],
              [-39.661666665484177, -8.226666659238902],
              [-39.66083333119326, -8.226666658339582],
              [-39.660833332991899, -8.224166659063997],
              [-39.661666665484177, -8.224166659063997],
              [-39.661666668182136, -8.223333325672399],
              [-39.660833333891219, -8.223333325672399],
              [-39.660833333891219, -8.222499993180122],
              [-39.658333332816994, -8.222499993180122],
              [-39.657499998526077, -8.222499993180122],
              [-39.657499998526077, -8.223333325672399],
              [-39.656666666933063, -8.223333325672399],
              [-39.656666666033743, -8.224166659063997],
              [-39.655000001049189, -8.224166659063997],
              [-39.655000000149869, -8.223333325672399],
              [-39.652499999974964, -8.223333327471039],
              [-39.652500000874284, -8.224166658164677],
              [-39.650833331393073, -8.224166659063997],
              [-39.650833331393073, -8.22499999335497],
              [-39.646666668032253, -8.22499999335497],
              [-39.646666666233614, -8.224166659063997],
              [-39.644166666958085, -8.224166659063997],
              [-39.644166666958085, -8.223333325672399],
              [-39.643333332667112, -8.223333325672399],
              [-39.643333331767792, -8.224166658164677],
              [-39.639166665709013, -8.224166659963316],
              [-39.639166666608332, -8.223333325672399],
              [-39.634166668057219, -8.223333327471039],
              [-39.634166667157899, -8.224166659063997],
              [-39.629999998401161, -8.224166659063997],
              [-39.6300000001998, -8.22499999425429],
              [-39.619999998601031, -8.22499999335497],
              [-39.619999998601031, -8.224166659063997],
              [-39.619166666108697, -8.224166659063997],
              [-39.619166667008017, -8.223333325672399],
              [-39.61833333361642, -8.223333325672399],
              [-39.61833333361642, -8.224166659063997],
              [-39.617500001124085, -8.224166659063997],
              [-39.617500001124085, -8.22333332477308],
              [-39.616666665933849, -8.223333325672399],
              [-39.616666665933849, -8.224166659063997],
              [-39.613333331468027, -8.224166659063997],
              [-39.613333332367347, -8.2191666587143],
              [-39.61249999897575, -8.21916666051294],
              [-39.61249999897575, -8.218333326221966],
              [-39.610833332192499, -8.218333326221966],
              [-39.610833333091819, -8.217499992830369],
              [-39.607499998625997, -8.217499992830369],
              [-39.607499999525317, -8.216666659438715],
              [-39.604166666858134, -8.216666657640076],
              [-39.604166665958815, -8.217499992830369],
              [-39.601666666683286, -8.217499992830369],
              [-39.601666667582606, -8.218333327121286],
              [-39.590000000099906, -8.218333326221966],
              [-39.590000001898545, -8.2191666587143],
              [-39.587499998126361, -8.2191666587143],
              [-39.587500000824321, -8.218333326221966],
              [-39.58583333404107, -8.218333325322646],
              [-39.58583333314175, -8.217499993729689],
              [-39.583333332067582, -8.217499992830369],
              [-39.583333332067582, -8.216666659438715],
              [-39.582500000474624, -8.216666659438715],
              [-39.582499999575305, -8.217499992830369],
              [-39.580833333691373, -8.217499993729689],
              [-39.580833331892734, -8.216666659438715],
              [-39.574166666558369, -8.216666659438715],
              [-39.57416666565905, -8.217499992830369],
              [-39.573333331368133, -8.217499992830369],
              [-39.573333333166772, -8.218333326221966],
              [-39.572499999775118, -8.218333326221966],
              [-39.572500000674438, -8.219166657814981],
              [-39.571666664584882, -8.2191666587143],
              [-39.571666665484202, -8.218333326221966],
              [-39.569166668007256, -8.218333326221966],
              [-39.569166667107936, -8.217499992830369],
              [-39.567500001224062, -8.217499993729689],
              [-39.567499998526046, -8.216666659438715],
              [-39.565000000149837, -8.216666660338035],
              [-39.565000000149837, -8.217499992830369],
              [-39.562499999075669, -8.217499992830369],
              [-39.562499999974989, -8.218333326221966],
              [-39.563333332467266, -8.218333327121286],
              [-39.563333331567947, -8.219999993005217],
              [-39.56416666765756, -8.219999992105897],
              [-39.56416666765756, -8.220833326396871],
              [-39.564999998351198, -8.220833326396871],
              [-39.565000000149837, -8.223333325672399],
              [-39.56416666765756, -8.223333325672399],
              [-39.56416666675824, -8.22499999335497],
              [-39.563333332467266, -8.22499999335497],
              [-39.563333332467266, -8.225833325847248],
              [-39.562499999075669, -8.225833325847248],
              [-39.562499999075669, -8.226666659238902],
              [-39.561666666583335, -8.226666659238902],
              [-39.561666667482655, -8.228333326022153],
              [-39.560833334091058, -8.228333326921472],
              [-39.560833331393098, -8.22916665851443],
              [-39.561666666583335, -8.22916665941375],
              [-39.561666667482655, -8.230833326197001],
              [-39.562499999075669, -8.230833326197001],
              [-39.562500000874309, -8.231666658689278],
              [-39.56416666585892, -8.231666658689278],
              [-39.56416666585892, -8.232499992980252],
              [-39.565000001049157, -8.232499992980252],
              [-39.565000001049157, -8.237499993330005],
              [-39.565833331742795, -8.237499993330005],
              [-39.565833332642171, -8.24083332599713],
              [-39.56999999960027, -8.24083332509781],
              [-39.56999999960027, -8.241666659388784],
              [-39.573333334066092, -8.241666660288104],
              [-39.573333333166772, -8.24083332599713],
              [-39.574999999050647, -8.24083332509781],
              [-39.575000000849343, -8.242499992780381],
              [-39.575833331542981, -8.242499992780381],
              [-39.575833331542981, -8.244166658664312],
              [-39.576666665833898, -8.244166658664312],
              [-39.576666666733217, -8.244999993854606],
              [-39.577500000124871, -8.244999993854606],
              [-39.577499999225552, -8.246666657939841],
              [-39.578333333516468, -8.246666659738537],
              [-39.578333331717829, -8.250833325797316],
              [-39.577499999225552, -8.250833325797316],
              [-39.577500001024191, -8.251666659188913],
              [-39.576666666733217, -8.251666659188913],
              [-39.576666667632537, -8.253333326871484],
              [-39.575833331542981, -8.253333325972164],
              [-39.575833331542981, -8.254166659363818],
              [-39.572499999775118, -8.254166659363818],
              [-39.572499999775118, -8.254999992755415],
              [-39.568333333716339, -8.254999992755415],
              [-39.568333333716339, -8.255833326147012],
              [-39.567500002123381, -8.255833326147012],
              [-39.567499999425365, -8.254166659363818],
              [-39.561666666583335, -8.254166659363818],
              [-39.561666665684015, -8.254999992755415],
              [-39.559166668207126, -8.254999992755415],
              [-39.559166668207126, -8.255833326147012],
              [-39.555833332841985, -8.255833326147012],
              [-39.555833331942665, -8.256666658639347],
              [-39.552500000174803, -8.256666658639347],
              [-39.552500001074122, -8.257499993829583],
              [-39.551666667682525, -8.257499992030944],
              [-39.551666666783206, -8.258333326321917],
              [-39.551666665883886, -8.259166658814195],
              [-39.552500001973442, -8.259166658814195],
              [-39.552499998376163, -8.259999993105168],
              [-39.554166666958054, -8.259999992205849],
              [-39.554166666958054, -8.260833326496765],
              [-39.555833331942665, -8.260833324698126],
              [-39.555833331942665, -8.2616666589891],
              [-39.556666666233639, -8.2616666589891],
              [-39.556666667132959, -8.264999993454865],
              [-39.557500000524556, -8.264999993454865],
              [-39.557499999625236, -8.266666659338796],
              [-39.555833331942665, -8.266666659338796],
              [-39.555833332841985, -8.26749999273045],
              [-39.554999999450388, -8.26749999273045],
              [-39.554999998551068, -8.268333326122047],
              [-39.554166666958054, -8.268333326122047],
              [-39.553333332667137, -8.268333326122047],
              [-39.553333332667137, -8.269166659513701],
              [-39.551666666783206, -8.269166659513701],
              [-39.551666665883886, -8.269999992905298],
              [-39.550833331592912, -8.269999992905298],
              [-39.550833333391552, -8.271666659688549],
              [-39.542499999475353, -8.271666658789229],
              [-39.542499998576034, -8.272499993080146],
              [-39.541666666083756, -8.272499993080146],
              [-39.541666667882396, -8.27333332467316],
              [-39.540000000199825, -8.2733333264718],
              [-39.540000001099145, -8.274166658964077],
              [-39.536666666633323, -8.274166658964077],
              [-39.536666665734003, -8.2733333264718],
              [-39.535833332342349, -8.2733333264718],
              [-39.535833332342349, -8.274166658964077],
              [-39.534999999850072, -8.274166658964077],
              [-39.534999998950752, -8.2733333264718],
              [-39.533333332167501, -8.2733333264718],
              [-39.533333332167501, -8.274166658964077],
              [-39.53166666538425, -8.274166658964077],
              [-39.53166666538425, -8.274999993255051],
              [-39.530833331992653, -8.274999993255051],
              [-39.530833332891973, -8.275833324848008],
              [-39.529166666108722, -8.275833324848008],
              [-39.529166666108722, -8.276666659138982],
              [-39.527499998426151, -8.276666659138982],
              [-39.52750000112411, -8.277499993429899],
              [-39.526666665933817, -8.277499993429899],
              [-39.526666665933817, -8.276666658239662],
              [-39.525833334340859, -8.276666659138982],
              [-39.523333332367372, -8.276666659138982],
              [-39.523333332367372, -8.277499993429899],
              [-39.519166669006552, -8.277499993429899],
              [-39.519166665409216, -8.278333325922233],
              [-39.515833332742091, -8.278333326821553],
              [-39.515833330943451, -8.27916665931383],
              [-39.513333331667866, -8.27916665931383],
              [-39.513333332567186, -8.279999992705427],
              [-39.510833331493018, -8.279999992705427],
              [-39.510833331493018, -8.280833326097081],
              [-39.508333332217489, -8.280833326097081],
              [-39.508333334016129, -8.279999992705427],
              [-39.507499999725155, -8.279999994504124],
              [-39.507500000624475, -8.27916665931383],
              [-39.504166667057973, -8.27916665931383],
              [-39.504166667957293, -8.278333325922233],
              [-39.502499999375402, -8.278333325922233],
              [-39.502499999375402, -8.277499993429899],
              [-39.500833331692888, -8.277499993429899],
              [-39.500833332592208, -8.275833325747328],
              [-39.500000000099874, -8.275833325747328],
              [-39.500000000099874, -8.272499993080146],
              [-39.497499999925026, -8.272499993979466],
              [-39.497499998126386, -8.2733333264718],
              [-39.494166668157163, -8.2733333264718],
              [-39.494166665459204, -8.274166658964077],
              [-39.491666666183676, -8.274166658964077],
              [-39.491666667082995, -8.274999993255051],
              [-39.490000001199064, -8.274999993255051],
              [-39.489999998501105, -8.275833325747328],
              [-39.48750000012484, -8.275833325747328],
              [-39.48750000012484, -8.276666658239662],
              [-39.484166666558394, -8.276666659138982],
              [-39.484166666558394, -8.277499994329219],
              [-39.480833332092573, -8.277499993429899],
              [-39.480833332991892, -8.278333326821553],
              [-39.479166667107961, -8.278333325922233],
              [-39.479166667107961, -8.27916665931383],
              [-39.476666667832433, -8.27916665931383],
              [-39.476666666933113, -8.278333325922233],
              [-39.4758333308435, -8.278333325922233],
              [-39.475833333541459, -8.277499993429899],
              [-39.475000000149862, -8.277499993429899],
              [-39.475000000149862, -8.276666659138982],
              [-39.474166666758208, -8.276666660038302],
              [-39.474166666758208, -8.275833325747328],
              [-39.469999999800109, -8.275833325747328],
              [-39.469999998900789, -8.274999993255051],
              [-39.469166665509135, -8.274999993255051],
              [-39.469166665509135, -8.27333332737112],
              [-39.468333333916178, -8.27333332467316],
              [-39.468333333016858, -8.270833326296952],
              [-39.467500000524581, -8.270833326296952],
              [-39.467499999625261, -8.269166659513701],
              [-39.46583333284201, -8.269166659513701],
              [-39.46583333284201, -8.268333326122047],
              [-39.464999998551036, -8.268333326122047],
              [-39.464999998551036, -8.266666659338796],
              [-39.455833333941143, -8.266666660238116],
              [-39.455833332142504, -8.265833326846519],
              [-39.455000001448866, -8.265833326846519],
              [-39.454999998750907, -8.266666658439476],
              [-39.440833332891941, -8.266666659338796],
              [-39.440833333791261, -8.26749999273045],
              [-39.436666667732482, -8.26749999183113],
              [-39.436666665933842, -8.268333326122047],
              [-39.435833333441565, -8.268333326122047],
              [-39.435833332542245, -8.272499993080146],
              [-39.436666667732482, -8.272499993080146],
              [-39.436666665933842, -8.2733333264718],
              [-39.434999999150591, -8.2733333264718],
              [-39.435000000949231, -8.281666659488678],
              [-39.432499998975743, -8.281666659488678],
              [-39.432499998975743, -8.282499992880332],
              [-39.429999999700158, -8.282499992880332],
              [-39.429999998800838, -8.283333326271929],
              [-39.428333333816283, -8.283333326271929],
              [-39.428333332017644, -8.284166657864944],
              [-39.42749999862599, -8.284166658764264],
              [-39.427500000424629, -8.28499999305518],
              [-39.425833333641378, -8.28499999305518],
              [-39.425833332742059, -8.285833326446834],
              [-39.426666667932352, -8.285833326446834],
              [-39.426666666133713, -8.28916665911396],
              [-39.427500000424629, -8.28916665911396],
              [-39.42749999952531, -8.291666659288865],
              [-39.426666667033032, -8.291666659288865],
              [-39.426666667033032, -8.293333326072116],
              [-39.427500000424629, -8.293333326072116],
              [-39.42749999772667, -8.296666658739241],
              [-39.425000001149101, -8.296666659638561],
              [-39.424999999350462, -8.298333326421812],
              [-39.424166665958808, -8.298333326421812],
              [-39.424166666858127, -8.299999993205063],
              [-39.422500000074876, -8.299999993205063],
              [-39.422500000974196, -8.300833325697397],
              [-39.419999999000709, -8.300833324798077],
              [-39.419999999900028, -8.301666659088994],
              [-39.419166666508431, -8.301666659088994],
              [-39.419166667407751, -8.302499994279287],
              [-39.41749999972518, -8.302499994279287],
              [-39.41749999972518, -8.303333325872245],
              [-39.414166666158678, -8.303333326771565],
              [-39.414166667957318, -8.304166659263842],
              [-39.412499998476108, -8.304166659263842],
              [-39.412499998476108, -8.304999992655496],
              [-39.410833331692857, -8.304999992655496],
              [-39.410833334390816, -8.305833325147773],
              [-39.406666665634077, -8.305833326047093],
              [-39.406666665634077, -8.306666659438747],
              [-39.404166666358549, -8.306666659438747],
              [-39.404166665459172, -8.307499991931024],
              [-39.401666667082964, -8.307499992830344],
              [-39.401666667082964, -8.308333327121318],
              [-39.399999999400393, -8.308333326221998],
              [-39.399999998501073, -8.309166657814956],
              [-39.398333331717822, -8.309166658714275],
              [-39.398333332617142, -8.309999993005249],
              [-39.396666665833891, -8.309999993005249],
              [-39.396666665833891, -8.310833326396846],
              [-39.394999999950016, -8.310833326396846],
              [-39.395000000849336, -8.311666658889123],
              [-39.392499998875792, -8.311666658889123],
              [-39.392499998875792, -8.312499993180097],
              [-39.391666665484195, -8.312499993180097],
              [-39.391666665484195, -8.313333325672374],
              [-39.390833332991861, -8.313333325672374],
              [-39.39083333389118, -8.314999993354945],
              [-39.391666668182154, -8.314999993354945],
              [-39.391666666383514, -8.31749999352985],
              [-39.390833332991861, -8.31749999352985],
              [-39.390833332991861, -8.319166659413781],
              [-39.390000001398903, -8.319166659413781],
              [-39.390000000499583, -8.32166665868931],
              [-39.389166667107986, -8.32166665868931],
              [-39.389166667107986, -8.324166658864158],
              [-39.387499998526096, -8.324166658864158],
              [-39.387499999425415, -8.324999993155132],
              [-39.386666666033761, -8.324999993155132],
              [-39.386666665134442, -8.325833325647409],
              [-39.385833334440804, -8.325833325647409],
              [-39.385833332642164, -8.327499994229299],
              [-39.384166666758233, -8.32749999332998],
              [-39.384166665858913, -8.330833325997162],
              [-39.38500000104915, -8.330833325997162],
              [-39.38500000104915, -8.332499992780413],
              [-39.385833332642164, -8.332499992780413],
              [-39.385833331742845, -8.340833325797291],
              [-39.38500000014983, -8.340833325797291],
              [-39.384999998351191, -8.341666659188945],
              [-39.384166667657553, -8.341666659188945],
              [-39.384166667657553, -8.342499993479862],
              [-39.38500000014983, -8.342499993479862],
              [-39.384999998351191, -8.343333325972196],
              [-39.388333331917693, -8.343333325972196],
              [-39.388333333716332, -8.345833326147044],
              [-39.38916666620861, -8.345833326147044],
              [-39.389166667107986, -8.347499992930295],
              [-39.390000000499583, -8.347499992030976],
              [-39.389999999600263, -8.349166658814227],
              [-39.390833332092541, -8.349166658814227],
              [-39.390833332991861, -8.349999993105143],
              [-39.38916666620861, -8.349999993105143],
              [-39.389166667107986, -8.354166658264603],
              [-39.388333331917693, -8.354166658264603],
              [-39.388333332817012, -8.358333326122079],
              [-39.387499998526096, -8.358333326122079],
              [-39.387499998526096, -8.359166659513676],
              [-39.385833333541484, -8.359166659513676],
              [-39.385833331742845, -8.35999999290533],
              [-39.385833331742845, -8.361666658789204],
              [-39.38500000014983, -8.361666658789204],
              [-39.384999997451871, -8.362499992180858],
              [-39.384166665858913, -8.362499992180858],
              [-39.384166666758233, -8.363333326471775],
              [-39.38333333156794, -8.363333326471775],
              [-39.383333332467259, -8.36583332484804],
              [-39.382500000874302, -8.36583332574736],
              [-39.382499999974982, -8.367499994329251],
              [-39.38333333156794, -8.367499993429931],
              [-39.383333333366579, -8.368333325922208],
              [-39.38500000014983, -8.368333325922208],
              [-39.384999998351191, -8.369166659313805],
              [-39.386666666933081, -8.369166659313805],
              [-39.386666667832401, -8.369999992705459],
              [-39.387499998526096, -8.369999992705459],
              [-39.387499998526096, -8.372499992880307],
              [-39.388333331917693, -8.372499992880307],
              [-39.388333331917693, -8.373333326271961],
              [-39.38916666620861, -8.373333327171281],
              [-39.389166668007306, -8.374166657864919],
              [-39.389999998700944, -8.374166658764238],
              [-39.389999999600263, -8.379166659113991],
              [-39.389166667107986, -8.379166659113991],
              [-39.389166668007306, -8.383333326072091],
              [-39.388333332817012, -8.383333326072091],
              [-39.388333331917693, -8.382499992680494],
              [-39.385833333541484, -8.382499992680494],
              [-39.385833331742845, -8.383333325172771],
              [-39.384999998351191, -8.383333325172771],
              [-39.38500000104915, -8.386666657839953],
              [-39.385833331742845, -8.386666658739273],
              [-39.385833332642164, -8.390833325697372],
              [-39.386666666933081, -8.390833327496011],
              [-39.386666666933081, -8.395833326047125],
              [-39.385833332642164, -8.395833326047125],
              [-39.385833333541484, -8.396666659438722],
              [-39.384166666758233, -8.396666660338042],
              [-39.384166665858913, -8.397499992830376],
              [-39.383333332467259, -8.397499992830376],
              [-39.383333332467259, -8.399166658714307],
              [-39.384166666758233, -8.399166658714307],
              [-39.384166667657553, -8.399999993904544],
              [-39.383333332467259, -8.399999993005224],
              [-39.383333332467259, -8.401666658889155],
              [-39.382500000874302, -8.401666658889155],
              [-39.382499999075662, -8.402499993180072],
              [-39.381666666583328, -8.402499994079392],
              [-39.381666665684008, -8.403333325672406],
              [-39.380833334990371, -8.403333325672406],
              [-39.380833333191731, -8.404999993354977],
              [-39.379999999800134, -8.404999994254297],
              [-39.379999998001438, -8.405833324947935],
              [-39.37916666820712, -8.405833325847254],
              [-39.37916666550916, -8.407499993529825],
              [-39.378333333916203, -8.407499994429145],
              [-39.378333332117563, -8.408333326022159],
              [-39.377499999625229, -8.408333326022159],
              [-39.377500000524549, -8.409999993704673],
              [-39.376666668931591, -8.409999993704673],
              [-39.376666664434993, -8.411666658689285],
              [-39.374166666058727, -8.411666658689285],
              [-39.374166666058727, -8.41416665886419],
              [-39.376666668032271, -8.41416665886419],
              [-39.376666667132952, -8.414999993155106],
              [-39.377499999625229, -8.414999992255787],
              [-39.377499999625229, -8.415833324748064],
              [-39.378333333916203, -8.415833325647441],
              [-39.378333333916203, -8.416666659039038],
              [-39.37916666550916, -8.416666658139718],
              [-39.37916666640848, -8.417499993329955],
              [-39.379999998900757, -8.417499993329955],
              [-39.380000000699454, -8.418333325822289],
              [-39.380833334091051, -8.418333325822289],
              [-39.380833333191731, -8.419166659213886],
              [-39.381666666583328, -8.419166658314566],
              [-39.381666667482648, -8.420833325997137],
              [-39.38333333156794, -8.420833325997137],
              [-39.383333333366579, -8.421666659388791],
              [-39.380833333191731, -8.421666659388791],
              [-39.380833331393092, -8.422499992780388],
              [-39.379999998900757, -8.422499992780388],
              [-39.380000000699454, -8.423333326172042],
              [-39.378333333016883, -8.423333326172042],
              [-39.378333333016883, -8.42583332634689],
              [-39.37916666640848, -8.42583332634689],
              [-39.37916666640848, -8.428333324723098],
              [-39.378333333016883, -8.428333326521738],
              [-39.378333333916203, -8.434166660263088],
              [-39.37916666640848, -8.434166659363768],
              [-39.37916666820712, -8.435833326147019],
              [-39.378333333916203, -8.435833326147019],
              [-39.378333333016883, -8.436666658639353],
              [-39.377499998725909, -8.436666658639353],
              [-39.377499999625229, -8.43749999293027],
              [-39.376666667132952, -8.43749999382959],
              [-39.376666666233632, -8.438333326321924],
              [-39.375833331942658, -8.438333326321924],
              [-39.375833331942658, -8.439166658814202],
              [-39.374166666958047, -8.439166658814202],
              [-39.374166666058727, -8.44166665898905],
              [-39.373333330868491, -8.44166665898905],
              [-39.37333333176781, -8.446666660238122],
              [-39.372500001074172, -8.446666659338803],
              [-39.372500000174853, -8.447499992730457],
              [-39.371666666783199, -8.447499992730457],
              [-39.371666665883879, -8.449166660413027],
              [-39.370833333391602, -8.449166659513651],
              [-39.370833332492282, -8.449999992905305],
              [-39.37, -8.449999992905305],
              [-39.369999999100628, -8.450833326296902],
              [-39.36916666750767, -8.450833327196221],
              [-39.36916666840699, -8.451666657889916],
              [-39.3674999998251, -8.451666658789236],
              [-39.36749999892578, -8.452499993080153],
              [-39.366666666433446, -8.452499993979472],
              [-39.366666666433446, -8.454166658964084],
              [-39.365833333041849, -8.454166658964084],
              [-39.365833333041849, -8.456666658239612],
              [-39.364999998750875, -8.456666659138989],
              [-39.364999998750875, -8.459166659313837],
              [-39.364166668956557, -8.459166659313837],
              [-39.364166667157917, -8.459999992705434],
              [-39.363333331967681, -8.459999992705434],
              [-39.363333331967681, -8.461666659488685],
              [-39.362499999475347, -8.461666659488685],
              [-39.362499998576027, -8.462499991981019],
              [-39.361666666983069, -8.462499992880339],
              [-39.361666666983069, -8.463333326271936],
              [-39.360833332692096, -8.463333326271936],
              [-39.360833333591415, -8.46416665876427],
              [-39.360833333591415, -8.467499993230035],
              [-39.361666666983069, -8.467499993230035],
              [-39.36166666608375, -8.468333325722369],
              [-39.362500000374666, -8.468333327521009],
              [-39.362499999475347, -8.46999999340494],
              [-39.363333331967681, -8.46999999340494],
              [-39.36333333376632, -8.470833325897217],
              [-39.364166667157917, -8.470833324997898],
              [-39.364166665359278, -8.46999999340494],
              [-39.3674999998251, -8.46999999340494],
              [-39.3674999998251, -8.469166660013286],
              [-39.37333333356645, -8.469166659113966],
              [-39.37333333356645, -8.468333325722369],
              [-39.374166666958047, -8.468333325722369],
              [-39.374166666058727, -8.469166659113966],
              [-39.374999998551061, -8.469166659113966],
              [-39.374999997651742, -8.468333325722369],
              [-39.375833331942658, -8.46833332482305],
              [-39.375833331942658, -8.467499993230035],
              [-39.377499998725909, -8.467499993230035],
              [-39.377500000524549, -8.466666658939118],
              [-39.378333333016883, -8.466666658939118],
              [-39.378333333016883, -8.465833326446784],
              [-39.380833333191731, -8.465833326446784],
              [-39.380833331393092, -8.466666658939118],
              [-39.38333333156794, -8.466666658039799],
              [-39.38333333156794, -8.46416665876427],
              [-39.384166665858913, -8.464166657864894],
              [-39.384166667657553, -8.463333325372616],
              [-39.385833332642164, -8.463333327171256],
              [-39.385833332642164, -8.461666659488685],
              [-39.388333332817012, -8.461666659488685],
              [-39.388333332817012, -8.459999992705434],
              [-39.389166667107986, -8.459999991806114],
              [-39.38916666620861, -8.459166659313837],
              [-39.389999999600263, -8.459166659313837],
              [-39.389999999600263, -8.456666659138989],
              [-39.391666664584875, -8.456666660038309],
              [-39.391666665484195, -8.457499993429906],
              [-39.393333333166765, -8.457499993429906],
              [-39.393333331368126, -8.456666659138989],
              [-39.394166666558363, -8.456666659138989],
              [-39.394166667457682, -8.455833325747335],
              [-39.395833331542974, -8.455833325747335],
              [-39.395833332442294, -8.454999993255058],
              [-39.396666666733267, -8.454999993255058],
              [-39.396666666733267, -8.454166658964084],
              [-39.397500001024184, -8.454166659863404],
              [-39.397500000124865, -8.453333327371126],
              [-39.400833333691367, -8.453333326471807],
              [-39.400833332792047, -8.452499993080153],
              [-39.401666667082964, -8.452499993080153],
              [-39.401666667982283, -8.453333327371126],
              [-39.402500001373937, -8.453333327371126],
              [-39.402499997776658, -8.454166658964084],
              [-39.404166665459172, -8.454166658964084],
              [-39.404166666358549, -8.454999993255058],
              [-39.404999999750146, -8.454999993255058],
              [-39.404999998850826, -8.455833325747335],
              [-39.406666667432717, -8.455833325747335],
              [-39.406666667432717, -8.457499993429906],
              [-39.405833334041063, -8.457499991631266],
              [-39.405833334940439, -8.458333325922183],
              [-39.405000000649466, -8.458333325922183],
              [-39.404999999750146, -8.460833326097088],
              [-39.404166666358549, -8.460833326097088],
              [-39.404166668157188, -8.461666659488685],
              [-39.403333332966895, -8.461666659488685],
              [-39.403333333866215, -8.463333326271936],
              [-39.402499999575298, -8.463333326271936],
              [-39.402499999575298, -8.46416665876427],
              [-39.401666667982283, -8.46416665876427],
              [-39.401666666183644, -8.467499993230035],
              [-39.402500000474618, -8.467499993230035],
              [-39.402500001373937, -8.468333325722369],
              [-39.403333332966895, -8.468333325722369],
              [-39.403333332067575, -8.46999999340494],
              [-39.405000000649466, -8.46999999340494],
              [-39.405000000649466, -8.469166659113966],
              [-39.405833330443784, -8.469166659113966],
              [-39.405833331343104, -8.46999999340494],
              [-39.407499999924994, -8.46999999430426],
              [-39.407499999025674, -8.470833325897217],
              [-39.409166666708245, -8.470833325897217],
              [-39.409166664909606, -8.473333326072066],
              [-39.408333332417328, -8.473333326072066],
              [-39.408333332417328, -8.474999992855317],
              [-39.409166665808925, -8.474999992855317],
              [-39.409166665808925, -8.477499993030222],
              [-39.410000000099899, -8.477499993030222],
              [-39.410000000099899, -8.482499993379918],
              [-39.409166666708245, -8.482499993379918],
              [-39.409166666708245, -8.4858333260471],
              [-39.410000001898538, -8.4858333260471],
              [-39.410000000099899, -8.486666659438754],
              [-39.410833331692857, -8.486666659438754],
              [-39.410833331692857, -8.488333326222005],
              [-39.412499999375427, -8.488333326222005],
              [-39.412499999375427, -8.489166660512922],
              [-39.414166667057998, -8.489166661412241],
              [-39.414166667957318, -8.488333326222005],
              [-39.418333330418818, -8.488333326222005],
              [-39.418333333116777, -8.487499992830351],
              [-39.420833332392306, -8.487499992830351],
              [-39.420833332392306, -8.488333326222005],
              [-39.421666666683279, -8.488333326222005],
              [-39.42166666578396, -8.489166658714282],
              [-39.425000001149101, -8.489166658714282],
              [-39.425000002048421, -8.487499992830351],
              [-39.425833331842739, -8.487499992830351],
              [-39.425833331842739, -8.486666659438754],
              [-39.424999998451142, -8.486666659438754],
              [-39.424999999350462, -8.484999991756183],
              [-39.425833331842739, -8.484999991756183],
              [-39.425833333641378, -8.483333325872252],
              [-39.426666667033032, -8.483333325872252],
              [-39.426666666133713, -8.47999999320507],
              [-39.42749999862599, -8.47999999320507],
              [-39.42749999862599, -8.478333326421819],
              [-39.428333332916964, -8.478333327321138],
              [-39.428333332916964, -8.475833326246971],
              [-39.4291666681072, -8.475833325347651],
              [-39.429166665409241, -8.474999992855317],
              [-39.430000000599478, -8.474999992855317],
              [-39.429999998800838, -8.473333326072066],
              [-39.430833333091812, -8.473333326072066],
              [-39.430833333091812, -8.471666659288871],
              [-39.431666667382729, -8.471666659288871],
              [-39.431666665584089, -8.46999999340494],
              [-39.432499999875063, -8.46999999340494],
              [-39.432500000774382, -8.469166659113966],
              [-39.43333333236734, -8.469166659113966],
              [-39.43333333416598, -8.468333325722369],
              [-39.435833332542245, -8.468333325722369],
              [-39.435833331642925, -8.466666658039799],
              [-39.436666666833162, -8.466666658939118],
              [-39.436666666833162, -8.465833327346161],
              [-39.438333332717093, -8.465833326446784],
              [-39.438333333616413, -8.464999993055187],
              [-39.440833331992621, -8.464999993055187],
              [-39.440833332891941, -8.46416665876427],
              [-39.442499998775872, -8.46416665876427],
              [-39.442499999675192, -8.463333325372616],
              [-39.443333333966166, -8.463333326271936],
              [-39.443333332167526, -8.460833326097088],
              [-39.444166665559123, -8.460833326097088],
              [-39.444166666458443, -8.459999992705434],
              [-39.445000000749417, -8.459999992705434],
              [-39.444999999850097, -8.456666659138989],
              [-39.444166665559123, -8.456666658239612],
              [-39.444166665559123, -8.454999993255058],
              [-39.442499999675192, -8.454999993255058],
              [-39.442499999675192, -8.454166658964084],
              [-39.435000000049911, -8.454166658964084],
              [-39.435000000049911, -8.453333324673167],
              [-39.437500000224816, -8.453333326471807],
              [-39.437500001124135, -8.452499993080153],
              [-39.438333331817773, -8.452499992180833],
              [-39.438333331817773, -8.449166659513651],
              [-39.437500001124135, -8.449166659513651],
              [-39.437499998426119, -8.448333326122054],
              [-39.436666667732482, -8.448333326122054],
              [-39.436666665933842, -8.447499992730457],
              [-39.435833331642925, -8.447499992730457],
              [-39.435833332542245, -8.446666659338803],
              [-39.439999998601024, -8.446666659338803],
              [-39.440000000399664, -8.445833325047886],
              [-39.441666665384275, -8.445833325947206],
              [-39.441666665384275, -8.444166659163955],
              [-39.443333332167526, -8.444166659163955],
              [-39.443333332167526, -8.443333325772301],
              [-39.445833334141014, -8.443333325772301],
              [-39.445833332342374, -8.442499992380704],
              [-39.446666666633291, -8.442499993280023],
              [-39.446666666633291, -8.44166665898905],
              [-39.450833333591447, -8.441666659888369],
              [-39.450833333591447, -8.440833326496772],
              [-39.453333331967656, -8.440833324698133],
              [-39.453333331967656, -8.439999994004495],
              [-39.456666668232117, -8.439999993105175],
              [-39.456666666433478, -8.439166658814202],
              [-39.457499999825075, -8.439166658814202],
              [-39.457499998925755, -8.438333326321924],
              [-39.460833331592937, -8.438333327221244],
              [-39.460833331592937, -8.439166658814202],
              [-39.462499998376188, -8.439166658814202],
              [-39.462500000174828, -8.438333326321924],
              [-39.465000000349676, -8.438333325422605],
              [-39.464999999450356, -8.43749999293027],
              [-39.467499998725941, -8.43749999293027],
              [-39.467499999625261, -8.436666657740034],
              [-39.469166666408512, -8.436666658639353],
              [-39.469166666408512, -8.435833326147019],
              [-39.470000000699429, -8.435833326147019],
              [-39.470000000699429, -8.434999991856102],
              [-39.476666666033793, -8.434999992755422],
              [-39.476666666033793, -8.435833326147019],
              [-39.47749999942539, -8.435833326147019],
              [-39.47750000122403, -8.434999992755422],
              [-39.479166668007281, -8.434999992755422],
              [-39.479166667107961, -8.43166665918892],
              [-39.478333331917668, -8.43166665918892],
              [-39.478333332816987, -8.430833325797323],
              [-39.47750000122403, -8.430833325797323],
              [-39.47750000122403, -8.429999993304989],
              [-39.476666666933113, -8.429999993304989],
              [-39.476666667832433, -8.429166659014072],
              [-39.472499999974957, -8.429166658114752],
              [-39.472500000874277, -8.427499994029461],
              [-39.470833331393067, -8.427499993130141],
              [-39.470833331393067, -8.426666658839167],
              [-39.469999998900789, -8.426666658839167],
              [-39.469999998900789, -8.42583332724621],
              [-39.469166665509135, -8.42583332544757],
              [-39.469166668207151, -8.424999992955236],
              [-39.468333331218219, -8.424999992955236],
              [-39.468333333016858, -8.423333326172042],
              [-39.467499999625261, -8.423333326172042],
              [-39.467499999625261, -8.422499992780388],
              [-39.465000000349676, -8.422499992780388],
              [-39.465000000349676, -8.421666659388791],
              [-39.461666665883854, -8.421666659388791],
              [-39.461666666783231, -8.420833325997137],
              [-39.460833332492257, -8.420833325997137],
              [-39.460833331592937, -8.421666659388791],
              [-39.460000000899299, -8.421666659388791],
              [-39.46, -8.422499991881068],
              [-39.458333333216729, -8.422499992780388],
              [-39.458333331418089, -8.421666659388791],
              [-39.457499998925755, -8.421666659388791],
              [-39.457499999825075, -8.420833325997137],
              [-39.456666665534158, -8.420833325997137],
              [-39.456666665534158, -8.419166659213886],
              [-39.455833332142504, -8.419166659213886],
              [-39.455833333941143, -8.418333325822289],
              [-39.454999999650227, -8.418333325822289],
              [-39.454999999650227, -8.417499994229331],
              [-39.454166665359253, -8.417499993329955],
              [-39.454166665359253, -8.41416665886419],
              [-39.454166665359253, -8.413333326371855],
              [-39.454999998750907, -8.413333326371855],
              [-39.455000000549546, -8.412499992980258],
              [-39.455833334840463, -8.412499992980258],
              [-39.455833333041824, -8.411666658689285],
              [-39.456666665534158, -8.411666660487924],
              [-39.456666665534158, -8.410833326197007],
              [-39.457500001623714, -8.410833326197007],
              [-39.457500000724394, -8.409999992805353],
              [-39.458333334116048, -8.409999991906034],
              [-39.458333333216729, -8.409166659413756],
              [-39.45999999910066, -8.409166659413756],
              [-39.460000001798619, -8.407499993529825],
              [-39.461666666783231, -8.407499994429145],
              [-39.461666665883854, -8.406666659238908],
              [-39.462499998376188, -8.406666659238908],
              [-39.462500001074147, -8.405833325847254],
              [-39.463333332667105, -8.405833324947935],
              [-39.463333332667105, -8.404166659064003],
              [-39.464166666958079, -8.404166659064003],
              [-39.464166667857398, -8.403333325672406],
              [-39.46583333374133, -8.403333327471046],
              [-39.46583333194269, -8.402499993180072],
              [-39.466666668032246, -8.402499993180072],
              [-39.466666666233607, -8.399999993904544],
              [-39.467499998725941, -8.399999993005224],
              [-39.467499998725941, -8.399166658714307],
              [-39.469166666408512, -8.399166658714307],
              [-39.469166666408512, -8.398333327121293],
              [-39.469999998900789, -8.398333327121293],
              [-39.469999999800109, -8.395833326047125],
              [-39.470833331393067, -8.395833326047125],
              [-39.470833333191706, -8.394999992655471],
              [-39.47166666568404, -8.394999992655471],
              [-39.47166666568404, -8.394166659263874],
              [-39.473333332467291, -8.394166659263874],
              [-39.473333331567972, -8.391666659089026],
              [-39.476666666033793, -8.391666659988346],
              [-39.476666667832433, -8.389999993205095],
              [-39.47749999942539, -8.389999993205095],
              [-39.477499998526071, -8.389166659813441],
              [-39.478333331917668, -8.389166658914121],
              [-39.478333332816987, -8.388333326421844],
              [-39.479166667107961, -8.388333326421844],
              [-39.479166668007281, -8.384166659463745],
              [-39.480000000499558, -8.384166659463745],
              [-39.479999998700919, -8.382499992680494],
              [-39.480833332991892, -8.382499992680494],
              [-39.480833333891212, -8.38166665928884],
              [-39.481666666383489, -8.38166665928884],
              [-39.48166666548417, -8.379166659113991],
              [-39.482500000674463, -8.379166659113991],
              [-39.482499999775143, -8.37749999323006],
              [-39.48333333406606, -8.37749999323006],
              [-39.483333331368101, -8.374166658764238],
              [-39.484166665659075, -8.374166658764238],
              [-39.484166667457714, -8.373333326271961],
              [-39.484999999050672, -8.373333326271961],
              [-39.484999999050672, -8.37166665948871],
              [-39.485833330643629, -8.37166665948871],
              [-39.485833332442269, -8.368333325922208],
              [-39.486666666733242, -8.368333325922208],
              [-39.486666666733242, -8.364166659863429],
              [-39.48750000012484, -8.364166658964109],
              [-39.48749999922552, -8.35999999290533],
              [-39.488333333516493, -8.35999999290533],
              [-39.488333331717854, -8.359166657715036],
              [-39.490833333691342, -8.359166659513676],
              [-39.490833331892702, -8.358333328820038],
              [-39.492499998675953, -8.358333326122079],
              [-39.492499997776633, -8.356666659338828],
              [-39.493333333866246, -8.356666659338828],
              [-39.493333333866246, -8.355833325947174],
              [-39.494166666358524, -8.355833325947174],
              [-39.494166666358524, -8.354166659163923],
              [-39.494999998850801, -8.354166659163923],
              [-39.494999999750121, -8.352499994179368],
              [-39.494166668157163, -8.352499993279991],
              [-39.494166666358524, -8.350833326496797],
              [-39.49333333206755, -8.350833326496797],
              [-39.493333332966927, -8.349166658814227],
              [-39.492499999575273, -8.349166658814227],
              [-39.492499998675953, -8.347499992930295],
              [-39.493333332966927, -8.347499992030976],
              [-39.49333333206755, -8.345833326147044],
              [-39.494166665459204, -8.345833326147044],
              [-39.494166666358524, -8.344999991856071],
              [-39.498333332417303, -8.34499999275539],
              [-39.498333334215943, -8.345833326147044],
              [-39.499166666708277, -8.345833326147044],
              [-39.499166667607597, -8.34499999275539],
              [-39.500000000099874, -8.34499999275539],
              [-39.499999999200554, -8.344166659363793],
              [-39.501666665983805, -8.344166659363793],
              [-39.504166667057973, -8.344166660263113],
              [-39.504166667057973, -8.346666658639322],
              [-39.505000000449627, -8.346666658639322],
              [-39.504999999550307, -8.347499993829615],
              [-39.505833333841224, -8.347499992930295],
              [-39.505833332941904, -8.349166659713546],
              [-39.508333333116809, -8.349166658814227],
              [-39.508333332217489, -8.349999993105143],
              [-39.509999999000684, -8.349999993105143],
              [-39.50999999990006, -8.351666658989075],
              [-39.513333331667866, -8.351666658989075],
              [-39.513333333466505, -8.350833326496797],
              [-39.515833331842771, -8.350833326496797],
              [-39.515833331842771, -8.351666658989075],
              [-39.517499998625965, -8.351666658989075],
              [-39.517500000424661, -8.353333325772326],
              [-39.518333333816258, -8.353333325772326],
              [-39.518333332916939, -8.354166659163923],
              [-39.519166667207855, -8.354166659163923],
              [-39.519166665409216, -8.357499992730425],
              [-39.51999999880087, -8.357499992730425],
              [-39.51999999880087, -8.358333326122079],
              [-39.520833333091787, -8.358333326122079],
              [-39.520833333991106, -8.359166659513676],
              [-39.52166666738276, -8.359166659513676],
              [-39.521666666483441, -8.358333326122079],
              [-39.525000000949262, -8.358333326122079],
              [-39.525000000049943, -8.35999999290533],
              [-39.527500000224791, -8.35999999290533],
              [-39.527499997526832, -8.360833326296927],
              [-39.528333331817748, -8.360833326296927],
              [-39.528333332717068, -8.35999999290533],
              [-39.529166666108722, -8.35999999290533],
              [-39.529166666108722, -8.359166659513676],
              [-39.53166666538425, -8.359166657715036],
              [-39.53166666808221, -8.35999999290533],
              [-39.532500000574544, -8.359999992005953],
              [-39.532500000574544, -8.359166659513676],
              [-39.534999998950752, -8.359166659513676],
              [-39.534999998950752, -8.35999999290533],
              [-39.536666666633323, -8.35999999290533],
              [-39.536666665734003, -8.361666658789204],
              [-39.538333331617935, -8.361666658789204],
              [-39.538333331617935, -8.362499993080178],
              [-39.539166666808171, -8.362499993080178],
              [-39.539166665908851, -8.363333327371095],
              [-39.540833331792783, -8.363333326471775],
              [-39.540833331792783, -8.366666658239637],
              [-39.541666667882396, -8.366666658239637],
              [-39.541666667882396, -8.367499993429931],
              [-39.542499999475353, -8.367499993429931],
              [-39.542499999475353, -8.370833326097056],
              [-39.543333331967631, -8.370833326996376],
              [-39.54333333376627, -8.374166658764238],
              [-39.544166668057244, -8.374166658764238],
              [-39.544166665359285, -8.375833326446809],
              [-39.544999998750882, -8.375833326446809],
              [-39.544999999650202, -8.376666658939087],
              [-39.546666668232092, -8.376666658939087],
              [-39.546666665534133, -8.37749999323006],
              [-39.548333331418064, -8.37749999323006],
              [-39.548333333216704, -8.384166659463745],
              [-39.549166667507677, -8.384166657665048],
              [-39.549166667507677, -8.386666658739273],
              [-39.548333333216704, -8.386666658739273],
              [-39.548333331418064, -8.388333326421844],
              [-39.549166666608357, -8.388333326421844],
              [-39.549166665709038, -8.389166658914121],
              [-39.55, -8.389166658914121],
              [-39.549999999100635, -8.390833325697372],
              [-39.550833331592912, -8.390833325697372],
              [-39.550833332492232, -8.394166659263874],
              [-39.551666665883886, -8.394166658364554],
              [-39.551666666783206, -8.394999992655471],
              [-39.552499998376163, -8.394999992655471],
              [-39.552499998376163, -8.395833326047125],
              [-39.554166666058734, -8.395833326047125],
              [-39.554166666058734, -8.397499992830376],
              [-39.554999998551068, -8.397499991931056],
              [-39.554999999450388, -8.398333326221973],
              [-39.555833331942665, -8.398333326221973],
              [-39.555833331942665, -8.399166658714307],
              [-39.556666666233639, -8.399166658714307],
              [-39.556666666233639, -8.398333326221973],
              [-39.560000000699404, -8.398333326221973],
              [-39.559999998900764, -8.394999992655471],
              [-39.561666666583335, -8.394999992655471],
              [-39.561666667482655, -8.39333332677154],
              [-39.563333332467266, -8.39333332587222],
              [-39.563333332467266, -8.38749999303019],
              [-39.5641666649596, -8.38749999303019],
              [-39.56416666765756, -8.385833326246939],
              [-39.565833331742795, -8.385833326246939],
              [-39.565833331742795, -8.386666657839953],
              [-39.566666666033768, -8.386666658739273],
              [-39.566666666933088, -8.385833327146258],
              [-39.567500001224062, -8.385833327146258],
              [-39.567500001224062, -8.383333325172771],
              [-39.569166667107936, -8.383333326072091],
              [-39.569166667107936, -8.382499992680494],
              [-39.56999999960027, -8.382499992680494],
              [-39.57000000139891, -8.38166665928884],
              [-39.570833332991867, -8.38166665928884],
              [-39.570833332991867, -8.379999993404908],
              [-39.571666665484202, -8.379999993404908],
              [-39.571666666383521, -8.379166659113991],
              [-39.572499998875799, -8.379166659113991],
              [-39.572499999775118, -8.37749999323006],
              [-39.571666668182161, -8.37749999412938],
              [-39.571666665484202, -8.373333326271961],
              [-39.570833332991867, -8.373333326271961],
              [-39.570833333891187, -8.372499992880307],
              [-39.56999999960027, -8.372499993779627],
              [-39.569999998700951, -8.368333326821528],
              [-39.571666665484202, -8.368333325922208],
              [-39.571666665484202, -8.366666659138957],
              [-39.572499998875799, -8.366666659138957],
              [-39.572499999775118, -8.364999993255026],
              [-39.573333334066092, -8.364999993255026],
              [-39.573333333166772, -8.363333326471775],
              [-39.574166667457689, -8.363333327371095],
              [-39.57416666565905, -8.361666658789204],
              [-39.574999999949966, -8.361666657889884],
              [-39.574999999949966, -8.360833326296927],
              [-39.57416666565905, -8.360833325397607],
              [-39.57416666565905, -8.351666658989075],
              [-39.574999999949966, -8.351666658989075],
              [-39.575000000849343, -8.350833326496797],
              [-39.575833332442301, -8.350833326496797],
              [-39.575833332442301, -8.346666657740002],
              [-39.576666665833898, -8.346666658639322],
              [-39.576666668531857, -8.345833326147044],
              [-39.577500000124871, -8.345833326147044],
              [-39.577500001024191, -8.34499999275539],
              [-39.578333331717829, -8.34499999275539],
              [-39.578333332617149, -8.343333325972196],
              [-39.57999999850108, -8.343333325972196],
              [-39.5799999994004, -8.341666659188945],
              [-39.582499999575305, -8.341666659188945],
              [-39.582500000474624, -8.340833324897972],
              [-39.584166665459179, -8.340833325797291],
              [-39.584166665459179, -8.341666658289626],
              [-39.586666667432723, -8.341666659188945],
              [-39.586666665634084, -8.343333325972196],
              [-39.589166665808932, -8.343333325972196],
              [-39.589166665808932, -8.344166659363793],
              [-39.591666667782476, -8.344166659363793],
              [-39.59166666598378, -8.345833326147044],
              [-39.593333333666351, -8.345833326147044],
              [-39.593333331867711, -8.346666658639322],
              [-39.596666668132173, -8.346666658639322],
              [-39.596666667232853, -8.347499992930295],
              [-39.598333333116784, -8.347499992930295],
              [-39.598333334016104, -8.348333326321892],
              [-39.596666667232853, -8.348333325422573],
              [-39.596666665434213, -8.356666659338828],
              [-39.597499999725187, -8.356666658439508],
              [-39.597499998825867, -8.35999999290533],
              [-39.596666665434213, -8.359999992005953],
              [-39.596666667232853, -8.364999993255026],
              [-39.597499999725187, -8.364999993255026],
              [-39.597499999725187, -8.36583332574736],
              [-39.599166664709742, -8.36583332574736],
              [-39.599166666508438, -8.367499993429931],
              [-39.600000000799355, -8.367499993429931],
              [-39.599999999900035, -8.368333325922208],
              [-39.599166665609062, -8.368333325922208],
              [-39.599166665609062, -8.369999992705459],
              [-39.598333334016104, -8.369999992705459],
              [-39.598333332217464, -8.372499991980987],
              [-39.597500000624507, -8.372499992880307],
              [-39.597500000624507, -8.376666658939087],
              [-39.596666668132173, -8.376666658939087],
              [-39.596666665434213, -8.38166665928884],
              [-39.597499999725187, -8.38166665928884],
              [-39.597499999725187, -8.382499992680494],
              [-39.598333332217464, -8.382499992680494],
              [-39.598333332217464, -8.383333326072091],
              [-39.599166665609062, -8.383333326072091],
              [-39.599166665609062, -8.384166659463745],
              [-39.599999999900035, -8.384166660363064],
              [-39.599999999000715, -8.384999993754661],
              [-39.601666667582606, -8.384999993754661],
              [-39.601666668481926, -8.385833327146258],
              [-39.604166665958815, -8.385833326246939],
              [-39.604166667757454, -8.386666658739273],
              [-39.609166668107207, -8.386666658739273],
              [-39.609999999700165, -8.386666658739273],
              [-39.610000000599484, -8.385833326246939],
              [-39.610833333091819, -8.385833325347619],
              [-39.610833332192499, -8.386666658739273],
              [-39.61249999987507, -8.386666658739273],
              [-39.61249999897575, -8.38749999213087],
              [-39.615833333441515, -8.38749999303019],
              [-39.615833331642875, -8.388333326421844],
              [-39.616666666833169, -8.388333326421844],
              [-39.616666665933849, -8.390833325697372],
              [-39.617500000224766, -8.390833325697372],
              [-39.617500001124085, -8.391666659089026],
              [-39.61833333181778, -8.391666659089026],
              [-39.61833333181778, -8.392499992480623],
              [-39.62000000219831, -8.392499993379943],
              [-39.620000000399671, -8.39333332587222],
              [-39.620833331992628, -8.39333332677154],
              [-39.620833332891948, -8.394166659263874],
              [-39.624999999850047, -8.394166659263874],
              [-39.624999998950727, -8.3933333249729],
              [-39.629166665908883, -8.39333332587222],
              [-39.629166665009564, -8.394166659263874],
              [-39.629999998401161, -8.394166659263874],
              [-39.6300000001998, -8.396666659438722],
              [-39.630833332692134, -8.396666659438722],
              [-39.630833331792758, -8.397499992830376],
              [-39.631666666083731, -8.397499992830376],
              [-39.631666666983051, -8.398333325322653],
              [-39.634166668057219, -8.398333326221973],
              [-39.63416666535926, -8.397499992830376],
              [-39.63583333394115, -8.397499993729696],
              [-39.63583333304183, -8.396666659438722],
              [-39.637499998925762, -8.396666657640083],
              [-39.637499999825081, -8.399166658714307],
              [-39.638333334116055, -8.399166658714307],
              [-39.638333333216735, -8.401666658889155],
              [-39.639166668406972, -8.401666658889155],
              [-39.639166665709013, -8.402499993180072],
              [-39.640833333391583, -8.402499993180072],
              [-39.640833333391583, -8.403333325672406],
              [-39.642499998376195, -8.403333325672406],
              [-39.642500001074154, -8.404166659064003],
              [-39.643333332667112, -8.404166658164684],
              [-39.643333334465751, -8.404999993354977],
              [-39.644166666958085, -8.404999993354977],
              [-39.644166666958085, -8.405833325847254],
              [-39.645833332842017, -8.405833325847254],
              [-39.645833332842017, -8.408333326022159],
              [-39.644999997651723, -8.408333326022159],
              [-39.644999998551043, -8.411666660487924],
              [-39.644166666958085, -8.411666660487924],
              [-39.644166666958085, -8.413333326371855],
              [-39.644999998551043, -8.413333325472536],
              [-39.645000000349683, -8.414999993155106],
              [-39.645833331942697, -8.414999993155106],
              [-39.645833333741336, -8.415833325647441],
              [-39.647500000524587, -8.415833325647441],
              [-39.647499999625268, -8.416666659039038],
              [-39.645833331043377, -8.416666659039038],
              [-39.645833332842017, -8.417499992430635],
              [-39.645000000349683, -8.417499993329955],
              [-39.644999999450363, -8.419166659213886],
              [-39.645833332842017, -8.419166659213886],
              [-39.645833331942697, -8.41999999350486],
              [-39.646666667132934, -8.41999999350486],
              [-39.646666666233614, -8.422499991881068],
              [-39.649166665509142, -8.422499992780388],
              [-39.649166665509142, -8.424166658664319],
              [-39.649999999800116, -8.424166658664319],
              [-39.649999998001476, -8.424999992055916],
              [-39.650833331393073, -8.424999993854613],
              [-39.650833333191713, -8.427499993130141],
              [-39.651666667482687, -8.427499993130141],
              [-39.651666666583367, -8.428333326521738],
              [-39.656666666933063, -8.428333326521738],
              [-39.656666666033743, -8.429999993304989],
              [-39.660000000499565, -8.429999993304989],
              [-39.659999998700926, -8.430833325797323],
              [-39.663333334066067, -8.430833325797323],
              [-39.663333333166747, -8.4316666582896],
              [-39.66249999977515, -8.43166665918892],
              [-39.66249999977515, -8.434166659363768],
              [-39.663333331368108, -8.434166659363768],
              [-39.663333333166747, -8.435833326147019],
              [-39.664166667457721, -8.4358333252477],
              [-39.664166666558344, -8.436666658639353],
              [-39.663333333166747, -8.436666658639353],
              [-39.663333331368108, -8.439166658814202],
              [-39.66250000067447, -8.439166658814202],
              [-39.66249999977515, -8.440833326496772],
              [-39.661666666383496, -8.440833326496772],
              [-39.661666665484177, -8.44166665808973],
              [-39.663333333166747, -8.44166665898905],
              [-39.663333331368108, -8.442499993280023],
              [-39.664166667457721, -8.442499993280023],
              [-39.664166666558344, -8.443333325772301],
              [-39.664999999050679, -8.443333325772301],
              [-39.664999999949998, -8.444999991656232],
              [-39.665833332442276, -8.444999993454871],
              [-39.665833331542956, -8.449999992905305],
              [-39.668333332617181, -8.449999992905305],
              [-39.6683333335165, -8.450833325397582],
              [-39.670833333691348, -8.450833326296902],
              [-39.670833332792029, -8.451666658789236],
              [-39.67249999957528, -8.451666658789236],
              [-39.67249999957528, -8.452499993080153],
              [-39.673333333866196, -8.452499993080153],
              [-39.673333331168237, -8.453333326471807],
              [-39.675833333141782, -8.453333326471807],
              [-39.675833331343142, -8.454166658964084],
              [-39.67833333241731, -8.454166658964084],
              [-39.67833333151799, -8.454999993255058],
              [-39.679166667607603, -8.454999994154377],
              [-39.680833332592158, -8.454999993255058],
              [-39.680833331692838, -8.454166658964084],
              [-39.681666666883132, -8.454166658964084],
              [-39.681666665983812, -8.452499993080153],
              [-39.682500001174049, -8.452499993080153],
              [-39.682500001174049, -8.451666658789236],
              [-39.683333332767063, -8.451666658789236],
              [-39.683333331867743, -8.449999992905305],
              [-39.68416666705798, -8.449999992905305],
              [-39.68416666705798, -8.449166659513651],
              [-39.685000000449634, -8.449166659513651],
              [-39.684999999550314, -8.448333325222734],
              [-39.685833332042591, -8.448333327021373],
              [-39.685833332941911, -8.447499992730457],
              [-39.686666665434245, -8.447499992730457],
              [-39.686666668132204, -8.446666659338803],
              [-39.687499998825842, -8.446666659338803],
              [-39.687500000624482, -8.445833326846525],
              [-39.688333332217439, -8.445833325947206],
              [-39.688333333116759, -8.444999993454871],
              [-39.689166665609093, -8.444999991656232],
              [-39.689166667407733, -8.44333332667162],
              [-39.691666666683261, -8.443333325772301],
              [-39.691666666683261, -8.439999993105175],
              [-39.692499999175595, -8.439999993105175],
              [-39.692499999175595, -8.438333326321924],
              [-39.693333333466512, -8.438333327221244],
              [-39.693333333466512, -8.435833326147019],
              [-39.695000001149083, -8.435833327046339],
              [-39.695000001149083, -8.436666657740034],
              [-39.698333332916945, -8.436666658639353],
              [-39.698333332916945, -8.435833326147019],
              [-39.699999998800877, -8.435833326147019],
              [-39.700000000599516, -8.434999992755422],
              [-39.700000000599516, -8.429166659014072],
              [-39.700833333091794, -8.429166659014072],
              [-39.700833333991113, -8.426666658839167],
              [-39.701666666483447, -8.426666658839167],
              [-39.701666665584128, -8.42583332634689],
              [-39.702500000774364, -8.42583332634689],
              [-39.702499999875045, -8.426666658839167],
              [-39.703333334166018, -8.426666659738487],
              [-39.703333332367379, -8.428333326521738],
              [-39.704166667557615, -8.428333326521738],
              [-39.704166665758976, -8.429166659014072],
              [-39.704999999150573, -8.429166658114752],
              [-39.704999999150573, -8.428333326521738],
              [-39.705833331642907, -8.428333327421058],
              [-39.705833332542227, -8.427499993130141],
              [-39.706666665933824, -8.427499993130141],
              [-39.706666667732463, -8.426666657939847],
              [-39.707500000224798, -8.426666658839167],
              [-39.707499998426158, -8.424999992955236],
              [-39.708333331817755, -8.424999992955236],
              [-39.708333333616395, -8.423333326172042],
              [-39.709166666108729, -8.423333326172042],
              [-39.709166666108729, -8.422499992780388],
              [-39.709999999500326, -8.422499992780388],
              [-39.709999999500326, -8.421666659388791],
              [-39.712499998775854, -8.421666659388791],
              [-39.712499998775854, -8.41999999350486],
              [-39.711666667182897, -8.41999999350486],
              [-39.711666664484937, -8.417499993329955],
              [-39.714999999850079, -8.417499993329955],
              [-39.714999998950759, -8.416666659938357],
              [-39.715833331443037, -8.416666659039038],
              [-39.715833332342356, -8.415833325647441],
              [-39.71666666663333, -8.415833325647441],
              [-39.71666666753265, -8.41416665886419],
              [-39.717500000924247, -8.41416665886419],
              [-39.717500000924247, -8.413333326371855],
              [-39.718333333416581, -8.413333326371855],
              [-39.718333331617941, -8.412499992980258],
              [-39.719999998401136, -8.412499993879578],
              [-39.720000000199832, -8.411666658689285],
              [-39.72083333179279, -8.411666658689285],
              [-39.720833332692109, -8.410833326197007],
              [-39.721666666083706, -8.410833326197007],
              [-39.721666666983026, -8.409999993704673],
              [-39.726666668232099, -8.409999992805353],
              [-39.72666666553414, -8.409166659413756],
              [-39.727500000724433, -8.409166659413756],
              [-39.727500000724433, -8.408333326022159],
              [-39.72833333321671, -8.408333326022159],
              [-39.72833333411603, -8.407499993529825],
              [-39.727499999825113, -8.407499993529825],
              [-39.727499999825113, -8.404999993354977],
              [-39.729166667507684, -8.404999992455657],
              [-39.729166666608307, -8.405833325847254],
              [-39.731666665883893, -8.405833325847254],
              [-39.731666665883893, -8.402499993180072],
              [-39.733333332667144, -8.402499993180072],
              [-39.733333333566463, -8.400833326396821],
              [-39.735833332841992, -8.400833326396821],
              [-39.735833332841992, -8.399999993005224],
              [-39.739999998900771, -8.399999993005224],
              [-39.739999998900771, -8.399166658714307],
              [-39.740833334091064, -8.399166657814988],
              [-39.740833333191745, -8.398333326221973],
              [-39.740000000699411, -8.398333326221973],
              [-39.739999999800091, -8.396666659438722],
              [-39.740833331393105, -8.396666659438722],
              [-39.740833333191745, -8.395833326047125],
              [-39.741666666583342, -8.395833325147805],
              [-39.741666664784702, -8.394166659263874],
              [-39.742499999075676, -8.394166659263874],
              [-39.742499998176356, -8.39333332587222],
              [-39.743333331567953, -8.39333332587222],
              [-39.743333331567953, -8.392499992480623],
              [-39.745000001948483, -8.392499994279262],
              [-39.744999998351204, -8.39333332587222],
              [-39.745833332642121, -8.39333332587222],
              [-39.745833332642121, -8.392499993379943],
              [-39.747500001224012, -8.392499993379943],
              [-39.747500001224012, -8.39333332587222],
              [-39.750833333891194, -8.39333332677154],
              [-39.750833332092554, -8.394166659263874],
              [-39.752500000674445, -8.394166659263874],
              [-39.752499999775125, -8.395833326047125],
              [-39.751666666383528, -8.395833326047125],
              [-39.751666668182168, -8.399999993005224],
              [-39.752499999775125, -8.399999992105904],
              [-39.752500000674445, -8.400833326396821],
              [-39.753333331368083, -8.400833326396821],
              [-39.753333333166722, -8.401666658889155],
              [-39.754166666558376, -8.401666658889155],
              [-39.754166668357016, -8.402499993180072],
              [-39.756666666733224, -8.402499994079392],
              [-39.756666665833905, -8.403333325672406],
              [-39.757500001024198, -8.403333325672406],
              [-39.757500001024198, -8.402499993180072],
              [-39.759166666008809, -8.402499993180072],
              [-39.759166666008809, -8.401666658889155],
              [-39.759999998501087, -8.401666657989836],
              [-39.759999998501087, -8.400833327296198],
              [-39.761666666183658, -8.400833326396821],
              [-39.761666666183658, -8.399999993005224],
              [-39.762499999575255, -8.399999993005224],
              [-39.762499999575255, -8.399166660512947],
              [-39.763333332067589, -8.399166658714307],
              [-39.763333332966909, -8.398333326221973],
              [-39.76499999885084, -8.398333325322653],
              [-39.76499999975016, -8.397499992830376],
              [-39.769166666708259, -8.397499992830376],
              [-39.769166665808939, -8.396666659438722],
              [-39.77083333259219, -8.396666659438722],
              [-39.770833334390829, -8.395833326047125],
              [-39.773333332767038, -8.395833326047125],
              [-39.773333333666358, -8.394999992655471],
              [-39.774999999550289, -8.394999992655471],
              [-39.774999999550289, -8.394166659263874],
              [-39.779166665609068, -8.394166659263874],
              [-39.779166665609068, -8.392499993379943],
              [-39.780833332392319, -8.392499993379943],
              [-39.780833331493, -8.391666659089026],
              [-39.781666667582613, -8.391666659089026],
              [-39.781666665783973, -8.390833327496011],
              [-39.78249999917557, -8.390833327496011],
              [-39.78249999917557, -8.39333332587222],
              [-39.784166666858141, -8.39333332587222],
              [-39.784166665958821, -8.394166659263874],
              [-39.784999999350418, -8.394166659263874],
              [-39.785000001149115, -8.39333332587222],
              [-39.786666666133669, -8.39333332587222],
              [-39.786666666133669, -8.394166659263874],
              [-39.789166667207894, -8.394166659263874],
              [-39.789166667207894, -8.394999992655471],
              [-39.789999998800852, -8.394999992655471],
              [-39.789999999700171, -8.396666659438722],
              [-39.790833333091825, -8.396666659438722],
              [-39.790833333991145, -8.398333325322653],
              [-39.791666665584103, -8.398333326221973],
              [-39.791666667382742, -8.399166657814988],
              [-39.793333332367354, -8.399166658714307],
              [-39.793333332367354, -8.399999993005224],
              [-39.795000000049924, -8.399999993005224],
              [-39.795000000049924, -8.400833326396821],
              [-39.795833331642882, -8.400833326396821],
              [-39.795833331642882, -8.401666658889155],
              [-39.797499998426133, -8.401666658889155],
              [-39.797500001124092, -8.400833326396821],
              [-39.801666668082248, -8.400833326396821],
              [-39.801666667182928, -8.401666658889155],
              [-39.802499999675206, -8.401666658889155],
              [-39.802499999675206, -8.402499993180072],
              [-39.803333333066803, -8.402499993180072],
              [-39.803333333066803, -8.404166659064003],
              [-39.804166665559137, -8.404166659064003],
              [-39.804166667357777, -8.404999993354977],
              [-39.808333332517236, -8.404999993354977],
              [-39.808333333416556, -8.405833325847254],
              [-39.809166668606849, -8.405833325847254],
              [-39.80916666590889, -8.406666659238908],
              [-39.810000000199807, -8.406666659238908],
              [-39.810000001099127, -8.405833325847254],
              [-39.810833331792765, -8.405833325847254],
              [-39.810833333591404, -8.404999993354977],
              [-39.812499999475335, -8.404999993354977],
              [-39.812499999475335, -8.404166658164684],
              [-39.816666665534171, -8.404166658164684],
              [-39.816666668232131, -8.404999993354977],
              [-39.817499999825088, -8.404999993354977],
              [-39.817499998925769, -8.405833325847254],
              [-39.819999999100617, -8.405833324947935],
              [-39.820000000899256, -8.404999993354977],
              [-39.820833332492271, -8.404999994254297],
              [-39.820833332492271, -8.403333325672406],
              [-39.822500001074161, -8.403333325672406],
              [-39.822500001074161, -8.402499994079392],
              [-39.823333331767799, -8.402499993180072],
              [-39.823333333566438, -8.401666658889155],
              [-39.824166667857412, -8.401666658889155],
              [-39.824166666058773, -8.400833326396821],
              [-39.82499999855105, -8.400833326396821],
              [-39.825000000349689, -8.398333326221973],
              [-39.824166667857412, -8.398333326221973],
              [-39.824166666058773, -8.396666659438722],
              [-39.825000000349689, -8.396666659438722],
              [-39.82499999855105, -8.394166659263874],
              [-39.824166666058773, -8.394166659263874],
              [-39.824166666058773, -8.391666659089026],
              [-39.823333332667119, -8.391666659988346],
              [-39.823333331767799, -8.389166658014801],
              [-39.822500001074161, -8.389166658914121],
              [-39.822499998376202, -8.386666658739273],
              [-39.820833331592951, -8.386666658739273],
              [-39.820833332492271, -8.384999992855342],
              [-39.82, -8.384999992855342],
              [-39.820000000899256, -8.383333326072091],
              [-39.819166666608339, -8.383333326072091],
              [-39.81916666570902, -8.382499991781174],
              [-39.821666665883868, -8.382499992680494],
              [-39.821666665883868, -8.380833325897243],
              [-39.822499998376202, -8.380833325897243],
              [-39.822500000174841, -8.379999993404908],
              [-39.823333331767799, -8.379999993404908],
              [-39.823333331767799, -8.379166659113991],
              [-39.824166666058773, -8.379166659113991],
              [-39.824166666058773, -8.375833326446809],
              [-39.825833333741343, -8.375833326446809],
              [-39.825833333741343, -8.374999993055212],
              [-39.827499999625218, -8.374999993055212],
              [-39.827499999625218, -8.373333326271961],
              [-39.829166669106428, -8.373333326271961],
              [-39.829166668207108, -8.372499993779627],
              [-39.829999998900803, -8.372499992880307],
              [-39.829999998900803, -8.37166665948871],
              [-39.83083333139308, -8.37166665948871],
              [-39.830833334091039, -8.369999994504099],
              [-39.831666666583374, -8.369999992705459],
              [-39.831666668382013, -8.369166659313805],
              [-39.832499999974971, -8.369166658414485],
              [-39.832499999974971, -8.367499993429931],
              [-39.833333331567928, -8.367499993429931],
              [-39.833333332467305, -8.366666659138957],
              [-39.834166667657541, -8.366666659138957],
              [-39.834166665858902, -8.364999993255026],
              [-39.835000000149819, -8.364999993255026],
              [-39.835000000149819, -8.364166663460708],
              [-39.839166666208655, -8.364166658964109],
              [-39.839166666208655, -8.36583332574736],
              [-39.840000000499572, -8.36583332664668],
              [-39.839999999600252, -8.366666659138957],
              [-39.840833332991906, -8.366666660038277],
              [-39.840833332991906, -8.367499993429931],
              [-39.844166666558351, -8.367499993429931],
              [-39.844166667457671, -8.366666659138957],
              [-39.844999999950005, -8.366666658239637],
              [-39.844999999950005, -8.36583332574736],
              [-39.845833333341602, -8.36583332664668],
              [-39.845833332442282, -8.364999993255026],
              [-39.846666667632576, -8.364999993255026],
              [-39.846666665833936, -8.364166658964109],
              [-39.848333332617187, -8.364166658964109],
              [-39.848333331717868, -8.364999993255026],
              [-39.849999999400382, -8.364999993255026],
              [-39.849999999400382, -8.366666659138957],
              [-39.850833332792035, -8.366666660038277],
              [-39.850833332792035, -8.367499993429931],
              [-39.851666667082952, -8.367499993429931],
              [-39.851666667982272, -8.368333325022888],
              [-39.852499999575286, -8.368333325922208],
              [-39.852500000474606, -8.369166659313805],
              [-39.853333332966884, -8.369166659313805],
              [-39.853333332966884, -8.368333325922208],
              [-39.855833334041108, -8.368333325922208],
              [-39.855833331343149, -8.369166659313805],
              [-39.858333331517997, -8.369166659313805],
              [-39.858333331517997, -8.368333325922208],
              [-39.859166667607553, -8.368333325922208],
              [-39.859166665808914, -8.367499993429931],
              [-39.859999999200568, -8.367499992530611],
              [-39.860000000999207, -8.369166659313805],
              [-39.860833331692845, -8.369166659313805],
              [-39.860833331692845, -8.369999994504099],
              [-39.861666665983819, -8.369999992705459],
              [-39.861666665983819, -8.370833326097056],
              [-39.86333333276707, -8.370833326097056],
              [-39.86333333276707, -8.37166665948871],
              [-39.864166667957306, -8.37166665948871],
              [-39.864166667957306, -8.372499992880307],
              [-39.864999997751625, -8.372499992880307],
              [-39.864999998651001, -8.373333326271961],
              [-39.866666668132211, -8.373333326271961],
              [-39.866666665434195, -8.372499992880307],
              [-39.8691666656091, -8.372499992880307],
              [-39.8691666656091, -8.37166665948871],
              [-39.870833332392351, -8.37166665948871],
              [-39.870833334190991, -8.369999994504099],
              [-39.871666665783948, -8.369999992705459],
              [-39.871666665783948, -8.369166659313805],
              [-39.874166666858173, -8.369166659313805],
              [-39.874166666858173, -8.369999991806139],
              [-39.875833331842728, -8.369999994504099],
              [-39.875833331842728, -8.373333326271961],
              [-39.879166667207869, -8.373333326271961],
              [-39.879166668107189, -8.37749999323006],
              [-39.878333332916952, -8.37749999233074],
              [-39.878333332017633, -8.378333325722338],
              [-39.877499998625979, -8.378333327520977],
              [-39.877499999525298, -8.379166659113991],
              [-39.878333332916952, -8.379166659113991],
              [-39.878333332017633, -8.382499992680494],
              [-39.880000001498843, -8.382499991781174],
              [-39.880000000599523, -8.383333326072091],
              [-39.880833332192481, -8.383333326072091],
              [-39.880833332192481, -8.384999993754661],
              [-39.881666666483454, -8.384999992855342],
              [-39.881666666483454, -8.385833326246939],
              [-39.882499998975732, -8.385833326246939],
              [-39.882499999875051, -8.38749999213087],
              [-39.883333332367329, -8.38749999303019],
              [-39.883333332367329, -8.388333326421844],
              [-39.885000000949219, -8.388333326421844],
              [-39.88499999735194, -8.390833327496011],
              [-39.885833331642914, -8.390833325697372],
              [-39.885833332542234, -8.389999993205095],
              [-39.886666665933831, -8.389999993205095],
              [-39.886666665933831, -8.390833324798052],
              [-39.887500000224804, -8.390833325697372],
              [-39.887499998426165, -8.391666658189649],
              [-39.89083333199261, -8.391666659089026],
              [-39.89083333199261, -8.392499993379943],
              [-39.891666667182903, -8.392499996077902],
              [-39.891666665384264, -8.391666659089026],
              [-39.892499999675181, -8.391666659089026],
              [-39.892500000574501, -8.390833327496011],
              [-39.893333333066835, -8.390833325697372],
              [-39.893333333066835, -8.389999993205095],
              [-39.894166666458432, -8.389999993205095],
              [-39.894166667357752, -8.389166658014801],
              [-39.895000000749405, -8.389166659813441],
              [-39.894999999850086, -8.384166659463745],
              [-39.895833331443043, -8.384166659463745],
              [-39.895833332342363, -8.383333326072091],
              [-39.896666665734017, -8.383333326072091],
              [-39.896666665734017, -8.38166665928884],
              [-39.897500000024934, -8.38166665928884],
              [-39.897500000024934, -8.380833325897243],
              [-39.898333332517211, -8.380833325897243],
              [-39.898333331617891, -8.379999994304228],
              [-39.899166666808185, -8.379999993404908],
              [-39.899166666808185, -8.379166659113991],
              [-39.901666666983033, -8.379166658214672],
              [-39.901666666983033, -8.374166658764238],
              [-39.902499998576047, -8.374166658764238],
              [-39.902500000374687, -8.374999993055212],
              [-39.903333333766284, -8.374999993055212],
              [-39.903333331967644, -8.375833327346129],
              [-39.904166668057258, -8.375833327346129],
              [-39.904166667157938, -8.376666658939087],
              [-39.904999999650215, -8.376666658939087],
              [-39.904999999650215, -8.37749999233074],
              [-39.905833332142549, -8.37749999323006],
              [-39.905833332142549, -8.378333325722338],
              [-39.907500000724383, -8.378333325722338],
              [-39.907499999825063, -8.379166659113991],
              [-39.909166666608314, -8.379166659113991],
              [-39.909166667507634, -8.379999993404908],
              [-39.910833332492246, -8.379999993404908],
              [-39.910833331592926, -8.380833325897243],
              [-39.911666667682539, -8.380833325897243],
              [-39.911666667682539, -8.381666660188159],
              [-39.913333331767831, -8.38166665928884],
              [-39.91333333266715, -8.380833325897243],
              [-39.916666666233596, -8.380833325897243],
              [-39.916666667132915, -8.381666660188159],
              [-39.918333331218207, -8.38166665928884],
              [-39.918333333916166, -8.382499992680494],
              [-39.924999998351211, -8.382499992680494],
              [-39.92500000104917, -8.383333326072091],
              [-39.927499998526059, -8.383333325172771],
              [-39.927500001224018, -8.384166659463745],
              [-39.930833333891201, -8.384166659463745],
              [-39.930833332991881, -8.384999992855342],
              [-39.931666668182174, -8.384999992855342],
              [-39.931666665484158, -8.385833326246939],
              [-39.932499998875812, -8.385833326246939],
              [-39.932499998875812, -8.386666659638593],
              [-39.934166667457703, -8.386666658739273],
              [-39.934166666558383, -8.38749999303019],
              [-39.935833332442314, -8.38749999303019],
              [-39.935833332442314, -8.386666658739273],
              [-39.938333331717843, -8.386666658739273],
              [-39.938333332617162, -8.389166658914121],
              [-39.939166666008759, -8.389166658914121],
              [-39.939166666908136, -8.389999993205095],
              [-39.939999998501094, -8.389999993205095],
              [-39.941666667082984, -8.389999993205095],
              [-39.941666667982304, -8.389166659813441],
              [-39.944999998850847, -8.389166658914121],
              [-39.944999998850847, -8.388333327321163],
              [-39.947499999925014, -8.388333326421844],
              [-39.947500000824334, -8.38749999303019],
              [-39.948333335115308, -8.38749999303019],
              [-39.948333335115308, -8.38333332697141],
              [-39.947500001723654, -8.383333325172771],
              [-39.947500000824334, -8.38166665928884],
              [-39.946666666533417, -8.38166665928884],
              [-39.946666666533417, -8.380833325897243],
              [-39.945000000649486, -8.380833325897243],
              [-39.945000000649486, -8.378333325722338],
              [-39.945833331343124, -8.378333325722338],
              [-39.945833333141763, -8.37749999412938],
              [-39.946666666533417, -8.37749999412938],
              [-39.946666666533417, -8.375833326446809],
              [-39.947499999025695, -8.37583332464817],
              [-39.947499999925014, -8.374999993954532],
              [-39.948333331517972, -8.374999993055212],
              [-39.948333332417292, -8.374166658764238],
              [-39.949166666708265, -8.374166658764238],
              [-39.949166667607585, -8.373333326271961],
              [-39.949999999200543, -8.373333325372641],
              [-39.949999999200543, -8.372499993779627],
              [-39.950833332592197, -8.372499992880307],
              [-39.950833331692877, -8.37166665948871],
              [-39.951666667782433, -8.37166665948871],
              [-39.951666665983794, -8.370833325197736],
              [-39.952499998476128, -8.370833326996376],
              [-39.952499999375448, -8.369999994504099],
              [-39.950833332592197, -8.369999992705459],
              [-39.950833333491516, -8.366666659138957],
              [-39.949999999200543, -8.366666659138957],
              [-39.950000000099863, -8.364999993255026],
              [-39.949166665808946, -8.364999993255026],
              [-39.949166666708265, -8.362499993080178],
              [-39.949999999200543, -8.362499993080178],
              [-39.949999999200543, -8.360833326296927],
              [-39.953333332767045, -8.360833326296927],
              [-39.953333331867725, -8.359999993804649],
              [-39.954166666158699, -8.35999999290533],
              [-39.954166667957338, -8.358333326122079],
              [-39.955000000449616, -8.358333326122079],
              [-39.954999998650976, -8.357499991831105],
              [-39.956666667232867, -8.357499992730425],
              [-39.956666667232867, -8.355833325947174],
              [-39.959166666508395, -8.355833325947174],
              [-39.959166665609075, -8.352499993279991],
              [-39.960000000799369, -8.352499993279991],
              [-39.959999999000729, -8.351666658989075],
              [-39.959999999000729, -8.350833324698158],
              [-39.960833332392326, -8.350833326496797],
              [-39.960833331493006, -8.349166659713546],
              [-39.962499999175577, -8.349166658814227],
              [-39.962500000074897, -8.346666658639322],
              [-39.963333333466551, -8.346666658639322],
              [-39.963333331667855, -8.342499993479862],
              [-39.964166665958828, -8.342499991681223],
              [-39.964166665958828, -8.343333325972196],
              [-39.966666666133676, -8.34333332507282],
              [-39.966666666133676, -8.344166659363793],
              [-39.96749999952533, -8.344166659363793],
              [-39.96749999952533, -8.34499999275539],
              [-39.968333332916927, -8.344999991856071],
              [-39.968333332017608, -8.347499993829615],
              [-39.975000000049931, -8.347499992930295],
              [-39.974999999150612, -8.345833326147044],
              [-39.976666666833182, -8.345833326147044],
              [-39.976666666833182, -8.344166659363793],
              [-39.978333332717114, -8.344166659363793],
              [-39.978333333616433, -8.343333325972196],
              [-39.979166666108711, -8.343333325972196],
              [-39.97916666790735, -8.344166660263113],
              [-39.979999999500308, -8.344166660263113],
              [-39.980000000399627, -8.341666659188945],
              [-39.980833333791281, -8.341666660088265],
              [-39.980833331093322, -8.340833325797291],
              [-39.980000000399627, -8.340833325797291],
              [-39.979999998600988, -8.33916665901404],
              [-39.980833331992642, -8.33916665901404],
              [-39.980833332891962, -8.338333327421083],
              [-39.982499999675213, -8.338333326521763],
              [-39.982499998775893, -8.337499993130109],
              [-39.984166665559144, -8.337499992230789],
              [-39.984166665559144, -8.336666659738512],
              [-39.985833331443075, -8.336666658839192],
              [-39.985833331443075, -8.334999992055941],
              [-39.986666666633312, -8.334999992955261],
              [-39.986666667532631, -8.332499992780413],
              [-39.984999999850061, -8.332499992780413],
              [-39.984999999850061, -8.329166659213911],
              [-39.985833332342395, -8.329166660113231],
              [-39.985833331443075, -8.328333325822257],
              [-39.986666667532631, -8.328333325822257],
              [-39.986666665733992, -8.32749999243066],
              [-39.987500000924285, -8.327499994229299],
              [-39.987500000924285, -8.326666659938383],
              [-39.986666666633312, -8.326666659039063],
              [-39.986666666633312, -8.324999993155132],
              [-39.988333333416563, -8.324999992255812],
              [-39.988333332517243, -8.324166658864158],
              [-39.988333330718604, -8.323333326371881],
              [-39.990833333591411, -8.323333326371881],
              [-39.990833331792771, -8.324166658864158],
              [-39.994999999650247, -8.324166658864158],
              [-39.99499999875087, -8.326666659039063],
              [-39.995833333041844, -8.326666658139743],
              [-39.995833332142524, -8.32749999332998],
              [-39.997499998925775, -8.327499994229299],
              [-39.997499998026456, -8.328333325822257],
              [-39.998333331418053, -8.328333326721577],
              [-39.998333331418053, -8.32749999332998],
              [-39.999166665709026, -8.32749999332998],
              [-39.999166665709026, -8.328333325822257],
              [-40.000833332492277, -8.328333325822257],
              [-40.000833332492277, -8.329166659213911],
              [-40.001666667682514, -8.329166659213911],
              [-40.001666667682514, -8.330833326896482],
              [-40.002500000174848, -8.330833325997162],
              [-40.002499998376209, -8.331666658489439],
              [-40.003333331767806, -8.331666660288079],
              [-40.003333331767806, -8.335833327246235],
              [-40.006666667132947, -8.335833326346915],
              [-40.006666666233627, -8.334999992955261],
              [-40.008333333916198, -8.334999992955261],
              [-40.008333333016878, -8.334166658664344],
              [-40.009166666408476, -8.334166658664344],
              [-40.009166665509156, -8.33333332617201],
              [-40.011666665684004, -8.33333332617201],
              [-40.0116666674827, -8.332499992780413],
              [-40.014999998351186, -8.332499992780413],
              [-40.014999998351186, -8.331666659388759],
              [-40.01583333174284, -8.331666659388759],
              [-40.01583333354148, -8.330833325097842],
              [-40.017499998526091, -8.330833325997162],
              [-40.01750000122405, -8.329999993504828],
              [-40.018333333716328, -8.329999993504828],
              [-40.018333332817008, -8.329166659213911],
              [-40.019999998700939, -8.329166659213911],
              [-40.019999998700939, -8.325833324748089],
              [-40.019166667107982, -8.325833325647409],
              [-40.019166668007301, -8.324999993155132],
              [-40.019999999600259, -8.324999993155132],
              [-40.019999998700939, -8.322499992980227],
              [-40.020833332092536, -8.322499992080907],
              [-40.020833333891176, -8.32166665868931],
              [-40.02166666548419, -8.32166665868931],
              [-40.021666669081469, -8.319166659413781],
              [-40.019999999600259, -8.319166659413781],
              [-40.019999999600259, -8.315833325847279],
              [-40.017499999425411, -8.315833325847279],
              [-40.01750000122405, -8.314999993354945],
              [-40.014166665858909, -8.314999993354945],
              [-40.014166667657548, -8.315833326746599],
              [-40.013333332467255, -8.315833325847279],
              [-40.013333333366575, -8.314999993354945],
              [-40.010833331393087, -8.314999993354945],
              [-40.010833333191727, -8.310833326396846],
              [-40.009166666408476, -8.310833326396846],
              [-40.009166666408476, -8.308333326221998],
              [-40.011666665684004, -8.308333326221998],
              [-40.011666664784684, -8.304999992655496],
              [-40.010833333191727, -8.304999992655496],
              [-40.010833333191727, -8.304166659263842],
              [-40.010000000699449, -8.304166659263842],
              [-40.009999999800129, -8.302499993379968],
              [-40.009166665509156, -8.302499993379968],
              [-40.009166666408476, -8.301666659088994],
              [-40.008333332117559, -8.301666659088994],
              [-40.008333332117559, -8.300833325697397],
              [-40.007500000524544, -8.300833325697397],
              [-40.007499999625225, -8.298333326421812],
              [-40.006666666233627, -8.298333326421812],
              [-40.006666666233627, -8.296666658739241],
              [-40.007499999625225, -8.296666658739241],
              [-40.007499998725905, -8.294999992855367],
              [-40.003333331767806, -8.294999992855367],
              [-40.003333331767806, -8.293333326072116],
              [-40.004166666958099, -8.293333326072116],
              [-40.004166666958099, -8.291666659288865],
              [-40.005000000349696, -8.291666658389545],
              [-40.005000000349696, -8.287499993230085],
              [-40.004166666058723, -8.287499993230085],
              [-40.004166666058723, -8.286666658939112],
              [-40.003333334465765, -8.286666658939112],
              [-40.003333331767806, -8.285833324648195],
              [-40.001666665883874, -8.285833326446834],
              [-40.001666666783194, -8.28499999305518],
              [-40.000833332492277, -8.28499999305518],
              [-40.000833332492277, -8.283333327171249],
              [-39.999166668406986, -8.283333326271929],
              [-39.999166667507666, -8.281666659488678],
              [-39.998333334116012, -8.281666657690039],
              [-39.998333334116012, -8.277499993429899],
              [-39.997499998925775, -8.277499993429899],
              [-39.997499998925775, -8.276666659138982],
              [-39.996666668232137, -8.276666659138982],
              [-39.996666666433441, -8.275833325747328],
              [-39.997499998925775, -8.275833325747328],
              [-39.997500000724415, -8.274166658964077],
              [-39.996666666433441, -8.274166658964077],
              [-39.996666666433441, -8.272499993080146],
              [-39.995833333041844, -8.272499993080146],
              [-39.995833333041844, -8.268333325222727],
              [-39.996666666433441, -8.268333327021367],
              [-39.996666665534121, -8.266666659338796],
              [-39.997500000724415, -8.266666659338796],
              [-39.997500000724415, -8.265833325947199],
              [-39.999166665709026, -8.265833325947199],
              [-39.999166664809707, -8.264166658264628],
              [-39.999999999100623, -8.264166659163948],
              [-39.999999999100623, -8.263333325772294],
              [-40.001666665883874, -8.263333325772294],
              [-40.001666665883874, -8.259999993105168],
              [-39.999999999100623, -8.259999993105168],
              [-39.999999999100623, -8.259166659713514],
              [-40.000833333391597, -8.259166658814195],
              [-40.000833332492277, -8.256666658639347],
              [-40.001666666783194, -8.256666660437986],
              [-40.002499998376209, -8.256666658639347],
              [-40.002499998376209, -8.254166659363818],
              [-40.003333332667125, -8.254166659363818],
              [-40.003333331767806, -8.253333325972164],
              [-40.004166666958099, -8.253333325972164],
              [-40.004166666958099, -8.252499993479887],
              [-40.004999999450376, -8.252499993479887],
              [-40.005000000349696, -8.250833325797316],
              [-40.005833333741293, -8.250833325797316],
              [-40.005833331942654, -8.249999993304982],
              [-40.008333333916198, -8.249999993304982],
              [-40.008333332117559, -8.250833325797316],
              [-40.009166668207115, -8.250833325797316],
              [-40.009166666408476, -8.249999994204302],
              [-40.00999999890081, -8.249999993304982],
              [-40.00999999890081, -8.249166659014065],
              [-40.011666665684004, -8.249166659014065],
              [-40.0116666674827, -8.247499993130134],
              [-40.012500000874297, -8.247499993130134],
              [-40.012499999075658, -8.24666665883916],
              [-40.016666666033757, -8.24666665883916],
              [-40.016666666033757, -8.245833326346883],
              [-40.018333331917688, -8.245833326346883],
              [-40.018333332817008, -8.244999992955286],
              [-40.020000000499579, -8.244999992955286],
              [-40.019999999600259, -8.244166658664312],
              [-40.020833332092536, -8.244166657764993],
              [-40.020833333891176, -8.239166659213879],
              [-40.025833332442289, -8.239166659213879],
              [-40.025833332442289, -8.238333325822282],
              [-40.02750000102418, -8.238333325822282],
              [-40.02749999922554, -8.237499994229324],
              [-40.028333332617137, -8.237499993330005],
              [-40.028333331717818, -8.236666659039031],
              [-40.034166664559905, -8.236666659039031],
              [-40.034166665459225, -8.234166658864183],
              [-40.034999998850822, -8.234166659763503],
              [-40.034999998850822, -8.232499992980252],
              [-40.035833333141795, -8.232499992080932],
              [-40.035833331343099, -8.22916665851443],
              [-40.036666667432712, -8.22916665851443],
              [-40.036666667432712, -8.228333326022153],
              [-40.040000000099894, -8.228333326022153],
              [-40.040000000999214, -8.22916665941375],
              [-40.044166667057993, -8.22916665941375],
              [-40.044166666158674, -8.229999992805404],
              [-40.050000000799344, -8.229999992805404],
              [-40.049999999000704, -8.231666657789958],
              [-40.050833331493038, -8.231666660487917],
              [-40.050833332392358, -8.232499992080932],
              [-40.051666666683275, -8.232499992980252],
              [-40.051666666683275, -8.231666658689278],
              [-40.053333331667886, -8.231666658689278],
              [-40.053333330768567, -8.230833325297681],
              [-40.054166666858123, -8.230833325297681],
              [-40.054166665958803, -8.229999992805404],
              [-40.054999999350457, -8.229999992805404],
              [-40.054999998451137, -8.226666660138221],
              [-40.055833331842734, -8.226666659238902],
              [-40.055833332742054, -8.227499993529818],
              [-40.059166668107196, -8.227499991731179],
              [-40.059166668107196, -8.226666658339582],
              [-40.05999999970021, -8.226666660138221],
              [-40.059999998800834, -8.225833325847248],
              [-40.059166668107196, -8.225833325847248],
              [-40.059166667207876, -8.222499993180122],
              [-40.05999999970021, -8.222499993180122],
              [-40.05999999970021, -8.220833326396871],
              [-40.06583333254224, -8.220833326396871],
              [-40.06583333344156, -8.221666658889148],
              [-40.069166666108686, -8.221666658889148],
              [-40.069166667008005, -8.222499994079442],
              [-40.070000002198299, -8.222499994079442],
              [-40.06999999950034, -8.223333325672399],
              [-40.070833331093297, -8.223333325672399],
              [-40.070833331093297, -8.222499993180122],
              [-40.072499999675188, -8.222499993180122],
              [-40.072500000574507, -8.223333325672399],
              [-40.073333333966161, -8.223333325672399],
              [-40.073333333966161, -8.222499993180122],
              [-40.074166667357758, -8.222499993180122],
              [-40.074166664659799, -8.22333332477308],
              [-40.076666665733967, -8.223333325672399],
              [-40.076666665733967, -8.222499993180122],
              [-40.077499999125621, -8.222499993180122],
              [-40.077500000024941, -8.221666658889148],
              [-40.078333332517218, -8.221666658889148],
              [-40.078333331617898, -8.222499993180122],
              [-40.080833333591443, -8.222499993180122],
              [-40.080833331792803, -8.221666658889148],
              [-40.08166666608372, -8.221666658889148],
              [-40.08166666608372, -8.224166659063997],
              [-40.080833333591443, -8.224166658164677],
              [-40.080833332692123, -8.225833325847248],
              [-40.08166666698304, -8.225833325847248],
              [-40.08166666698304, -8.226666659238902],
              [-40.082499999475374, -8.226666659238902],
              [-40.082500000374694, -8.225833325847248],
              [-40.083333333766291, -8.225833325847248],
              [-40.083333332866971, -8.226666659238902],
              [-40.084166665359248, -8.226666658339582],
              [-40.084166665359248, -8.225833325847248],
              [-40.085000000549542, -8.225833325847248],
              [-40.085000000549542, -8.223333325672399],
              [-40.085833333941139, -8.223333327471039],
              [-40.085833333041819, -8.224166658164677],
              [-40.086666666433473, -8.224166659963316],
              [-40.086666668232112, -8.223333327471039],
              [-40.090000000899295, -8.223333325672399],
              [-40.089999999100655, -8.221666658889148],
              [-40.092499998376184, -8.221666657989829],
              [-40.092499998376184, -8.220833326396871],
              [-40.095833332842005, -8.220833326396871],
              [-40.095833333741325, -8.2191666587143],
              [-40.099166666408507, -8.2191666587143],
              [-40.099166666408507, -8.217499992830369],
              [-40.099999999800104, -8.217499992830369],
              [-40.099999998900785, -8.214999991756144],
              [-40.100833333191758, -8.214999994454161],
              [-40.100833333191758, -8.214166659263867],
              [-40.101666665684036, -8.214166659263867],
              [-40.101666665684036, -8.212499993379936],
              [-40.103333331567967, -8.212499994279256],
              [-40.103333332467287, -8.211666659089019],
              [-40.107500001224025, -8.211666659988339],
              [-40.107500001224025, -8.210833325697365],
              [-40.111666665484165, -8.210833325697365],
              [-40.111666664584845, -8.211666659089019],
              [-40.11416666565907, -8.211666659089019],
              [-40.11416666565907, -8.212499993379936],
              [-40.116666667632558, -8.212499993379936],
              [-40.116666665833918, -8.21333332497295],
              [-40.118333332617169, -8.21333332497295],
              [-40.118333333516489, -8.214166659263867],
              [-40.1199999985011, -8.214166659263867],
              [-40.11999999940042, -8.214999992655464],
              [-40.121666667082991, -8.214999992655464],
              [-40.121666667082991, -8.215833326047118],
              [-40.122499999575268, -8.215833326946438],
              [-40.122499998675949, -8.216666659438715],
              [-40.124166668157159, -8.216666660338035],
              [-40.124166666358519, -8.217499992830369],
              [-40.1241666654592, -8.218333325322646],
              [-40.124999998850797, -8.218333326221966],
              [-40.124999998850797, -8.2191666587143],
              [-40.126666666533367, -8.21916666051294],
              [-40.126666666533367, -8.219999993005217],
              [-40.133333333666371, -8.219999993904537],
              [-40.133333332767052, -8.220833325497551],
              [-40.13583333204258, -8.220833326396871],
              [-40.135833333841219, -8.219999993005217],
              [-40.138333333116805, -8.219999993005217],
              [-40.138333333116805, -8.2191666587143],
              [-40.14166666578393, -8.2191666587143],
              [-40.14166666488461, -8.218333326221966],
              [-40.143333332567181, -8.218333326221966],
              [-40.143333332567181, -8.217499992830369],
              [-40.144166665958835, -8.217499992830369],
              [-40.144166666858155, -8.216666659438715],
              [-40.145000001149072, -8.216666660338035],
              [-40.145000001149072, -8.215833326047118],
              [-40.145833333641406, -8.215833326047118],
              [-40.145833331842766, -8.214999992655464],
              [-40.146666667033003, -8.214999994454161],
              [-40.146666667033003, -8.214166659263867],
              [-40.147500000424657, -8.214166659263867],
              [-40.147500000424657, -8.21333332587227],
              [-40.148333333816254, -8.21333332587227],
              [-40.148333332916934, -8.212499993379936],
              [-40.149166665409211, -8.212499993379936],
              [-40.149166664509892, -8.211666659089019],
              [-40.149999999700185, -8.211666659089019],
              [-40.149999999700185, -8.210833325697365],
              [-40.150833332192462, -8.210833325697365],
              [-40.150833332192462, -8.209999993205088],
              [-40.151666666483436, -8.209999994104408],
              [-40.151666665584116, -8.209166658914114],
              [-40.152499998975713, -8.209166658914114],
              [-40.152499998975713, -8.207499993929503],
              [-40.153333332367367, -8.207499993030183],
              [-40.153333332367367, -8.206666658739266],
              [-40.155000000949258, -8.206666658739266],
              [-40.154999999150618, -8.205833326246989],
              [-40.155833331642896, -8.205833326246989],
              [-40.155833332542215, -8.204999992855335],
              [-40.156666666833189, -8.204999992855335],
              [-40.156666666833189, -8.204166657665098],
              [-40.157500001124106, -8.204166657665098],
              [-40.157500000224786, -8.203333326072084],
              [-40.158333332717064, -8.203333326072084],
              [-40.158333331817744, -8.202499992680487],
              [-40.159166667907357, -8.202499992680487],
              [-40.159166666108717, -8.201666659288833],
              [-40.159999999500315, -8.201666659288833],
              [-40.159999998600995, -8.199999993404901],
              [-40.160000000399634, -8.199166659113985],
              [-40.158333333616383, -8.199166659113985],
              [-40.158333331817744, -8.198333325722331],
              [-40.155833331642896, -8.198333327521027],
              [-40.155833333441535, -8.197499993230053],
              [-40.154166664859645, -8.197499994129373],
              [-40.154166665758964, -8.195833326446802],
              [-40.153333332367367, -8.195833326446802],
              [-40.153333331468048, -8.194999993055205],
              [-40.152499998975713, -8.194999993055205],
              [-40.152499999875033, -8.194166658764232],
              [-40.150833333991102, -8.194166658764232],
              [-40.150833333991102, -8.193333326271954],
              [-40.148333332916934, -8.193333325372635],
              [-40.148333332916934, -8.19249999377962],
              [-40.147499999525337, -8.192499991980981],
              [-40.147499999525337, -8.190833326097049],
              [-40.145833332742086, -8.190833326996369],
              [-40.145833332742086, -8.191666659488703],
              [-40.144999998451112, -8.191666659488703],
              [-40.144999998451112, -8.190833326097049],
              [-40.140833332392333, -8.190833326996369],
              [-40.140833332392333, -8.189999992705452],
              [-40.139999999900056, -8.189999992705452],
              [-40.140000000799375, -8.189166659313855],
              [-40.138333332217485, -8.189166660213175],
              [-40.138333332217485, -8.187499993429924],
              [-40.137499999725151, -8.187499993429924],
              [-40.13750000062447, -8.185833325747353],
              [-40.135833333841219, -8.185833325747353],
              [-40.1358333329419, -8.184999993255019],
              [-40.130833331692884, -8.184999993255019],
              [-40.130833331692884, -8.184166658964102],
              [-40.128333330618659, -8.184166658964102],
              [-40.128333331517979, -8.183333326471768],
              [-40.127500000824341, -8.183333326471768],
              [-40.127499999925021, -8.181666658789197],
              [-40.12583333314177, -8.181666658789197],
              [-40.12583333494041, -8.18083332629692],
              [-40.1241666654592, -8.18083332629692],
              [-40.124166668157159, -8.181666658789197],
              [-40.120833331892698, -8.181666658789197],
              [-40.120833331892698, -8.18083332629692],
              [-40.120000002098379, -8.18083332629692],
              [-40.11999999940042, -8.181666658789197],
              [-40.11416666565907, -8.181666658789197],
              [-40.11416666475975, -8.182499993979491],
              [-40.113333331368096, -8.182499992180851],
              [-40.113333331368096, -8.181666658789197],
              [-40.112499998875819, -8.181666658789197],
              [-40.112500000674459, -8.182499993080171],
              [-40.109999997801594, -8.182499993979491],
              [-40.109999998700914, -8.184166658964102],
              [-40.109166667107957, -8.184166658964102],
              [-40.109166668007276, -8.183333324673129],
              [-40.107499998526066, -8.183333326471768],
              [-40.107499998526066, -8.181666658789197],
              [-40.106666666933108, -8.181666657889878],
              [-40.106666667832428, -8.18083332629692],
              [-40.105833332642135, -8.1808333253976],
              [-40.105833330843495, -8.179166659513669],
              [-40.106666666933108, -8.179166659513669],
              [-40.106666666933108, -8.178333326122072],
              [-40.105833333541455, -8.178333326122072],
              [-40.105833332642135, -8.177499992730418],
              [-40.103333331567967, -8.177499992730418],
              [-40.102499999075633, -8.177499994529057],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '125',
        OBJECTID: 168.0,
        Nome_area: 'Floresta',
        COD_area: 'CA174',
        Import_bio: 'Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Criação de UC de categoria indefinida',
        Area_ha: 16683.8736,
        Shape_Leng: 0.97833,
        Shape_Area: 0.0137,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.493333332267412, -8.448333326122054],
              [-38.490000000499606, -8.448333326122054],
              [-38.489999999600286, -8.449166658614331],
              [-38.489166668906591, -8.449166658614331],
              [-38.489166666208632, -8.449999993804624],
              [-38.488333331917715, -8.449999992905305],
              [-38.488333331917715, -8.450833325397582],
              [-38.489166666208632, -8.450833325397582],
              [-38.489166667107952, -8.451666658789236],
              [-38.48999999870091, -8.451666658789236],
              [-38.48999999870091, -8.453333326471807],
              [-38.490833332092564, -8.453333326471807],
              [-38.490833332991883, -8.455833324848015],
              [-38.49166666638348, -8.455833326646655],
              [-38.491666665484161, -8.458333325922183],
              [-38.492499998875815, -8.458333325922183],
              [-38.492500000674454, -8.462499992880339],
              [-38.493333330468772, -8.462499992880339],
              [-38.493333333166731, -8.463333326271936],
              [-38.494999999949982, -8.463333326271936],
              [-38.495000000849302, -8.46416665876427],
              [-38.495833332442317, -8.46416665876427],
              [-38.495833331542997, -8.464999992155867],
              [-38.497499997426871, -8.464999993055187],
              [-38.497499999225568, -8.466666658939118],
              [-38.499166666008762, -8.466666658939118],
              [-38.499166666008762, -8.467499993230035],
              [-38.501666666183667, -8.467499993230035],
              [-38.501666666183667, -8.468333327521009],
              [-38.502499999575264, -8.468333325722369],
              [-38.502499998675944, -8.469166659113966],
              [-38.503333333866237, -8.469166659113966],
              [-38.503333332067598, -8.46999999340494],
              [-38.504999998850849, -8.46999999340494],
              [-38.505000000649488, -8.470833325897217],
              [-38.505833331343126, -8.470833325897217],
              [-38.505833331343126, -8.472499992680468],
              [-38.506666666533363, -8.472499992680468],
              [-38.506666669231379, -8.474166659463719],
              [-38.507500000824336, -8.474166659463719],
              [-38.507500000824336, -8.475833326246971],
              [-38.510833332592199, -8.475833326246971],
              [-38.510833332592199, -8.477499993030222],
              [-38.511666665983796, -8.477499993030222],
              [-38.511666665983796, -8.478333325522499],
              [-38.51249999847613, -8.478333326421819],
              [-38.51249999847613, -8.47999999320507],
              [-38.51249999937545, -8.482499993379918],
              [-38.51416666795734, -8.482499993379918],
              [-38.514166667058021, -8.484166659263849],
              [-38.515833332941895, -8.484166659263849],
              [-38.515833333841215, -8.484999992655503],
              [-38.516666667232869, -8.484999994454142],
              [-38.516666665434229, -8.48583332514778],
              [-38.51833333401612, -8.4858333260471],
              [-38.5183333331168, -8.486666659438754],
              [-38.519999999000731, -8.486666659438754],
              [-38.519999999000731, -8.487499992830351],
              [-38.520833332392328, -8.487499992830351],
              [-38.520833332392328, -8.488333326222005],
              [-38.521666665783982, -8.488333326222005],
              [-38.521666666683302, -8.489166658714282],
              [-38.522500000974219, -8.489166658714282],
              [-38.522500000974219, -8.494999993354952],
              [-38.521666664884606, -8.494999993354952],
              [-38.521666666683302, -8.495833325847286],
              [-38.521666666683302, -8.498333326022134],
              [-38.525833333641401, -8.498333326022134],
              [-38.525833331842762, -8.499166659413731],
              [-38.526666667032998, -8.499166659413731],
              [-38.526666666133679, -8.498333326022134],
              [-38.529166667207903, -8.498333326022134],
              [-38.529166664509944, -8.497499993529857],
              [-38.530833332192458, -8.497499993529857],
              [-38.530833332192458, -8.496666659238883],
              [-38.531666666483432, -8.496666659238883],
              [-38.531666667382751, -8.497499993529857],
              [-38.53416666665828, -8.497499993529857],
              [-38.53416666575896, -8.496666659238883],
              [-38.534999999150614, -8.496666660138203],
              [-38.535000000949253, -8.495833325847286],
              [-38.536666667732504, -8.495833325847286],
              [-38.536666667732504, -8.494999993354952],
              [-38.538333331817739, -8.494999993354952],
              [-38.538333332717116, -8.494166659064035],
              [-38.539166666108713, -8.494166658164715],
              [-38.539166667907352, -8.493333325672381],
              [-38.540833332891964, -8.493333325672381],
              [-38.540833331992644, -8.492499993180104],
              [-38.541666667182881, -8.492499993180104],
              [-38.541666667182881, -8.493333325672381],
              [-38.542499998775895, -8.493333325672381],
              [-38.542499999675215, -8.494166659064035],
              [-38.543333333066812, -8.494166659064035],
              [-38.543333333066812, -8.494999993354952],
              [-38.545833332342397, -8.494999993354952],
              [-38.545833332342397, -8.496666659238883],
              [-38.548333333416565, -8.496666659238883],
              [-38.548333332517245, -8.495833325847286],
              [-38.550000001099136, -8.495833325847286],
              [-38.550000000199816, -8.496666659238883],
              [-38.551666666083747, -8.496666659238883],
              [-38.551666666983067, -8.499166659413731],
              [-38.552499999475344, -8.499166660313051],
              [-38.552499999475344, -8.505833325647416],
              [-38.553333331967679, -8.505833324748096],
              [-38.553333333766318, -8.506666659039013],
              [-38.552499998576025, -8.506666659039013],
              [-38.552500000374664, -8.507499993329986],
              [-38.551666666083747, -8.507499992430667],
              [-38.551666667882387, -8.508333325822264],
              [-38.550833330893454, -8.508333325822264],
              [-38.550833331792774, -8.509166659213918],
              [-38.549166666808162, -8.509166659213918],
              [-38.549166665908842, -8.509999993504834],
              [-38.546666665733994, -8.509999993504834],
              [-38.546666666633314, -8.511666659388766],
              [-38.542500000574535, -8.511666659388766],
              [-38.542499998775895, -8.514999992955268],
              [-38.5416666680822, -8.514999992955268],
              [-38.54166666898152, -8.515833326346865],
              [-38.53999999860099, -8.515833326346865],
              [-38.54000000039963, -8.516666659738519],
              [-38.539166667008033, -8.516666657939879],
              [-38.539166667907352, -8.517499993130116],
              [-38.53999999860099, -8.517499993130116],
              [-38.53999999950031, -8.522499993479869],
              [-38.540833331093324, -8.522499991681229],
              [-38.540833332891964, -8.5241666593638],
              [-38.53999999950031, -8.5241666593638],
              [-38.53999999950031, -8.526666658639328],
              [-38.540833334690603, -8.526666658639328],
              [-38.540833331992644, -8.529166658814233],
              [-38.541666667182881, -8.529166658814233],
              [-38.541666665384241, -8.530833324698108],
              [-38.540833333791284, -8.530833326496747],
              [-38.540833332891964, -8.532499994179318],
              [-38.539166667907352, -8.532499993279998],
              [-38.539166667907352, -8.533333325772333],
              [-38.53416666575896, -8.533333325772333],
              [-38.53416666665828, -8.53416665916393],
              [-38.533333332367363, -8.53416665916393],
              [-38.533333332367363, -8.537499992730432],
              [-38.532500000774348, -8.537499992730432],
              [-38.532499999875029, -8.539166657715043],
              [-38.533333331468043, -8.539166659513683],
              [-38.533333334166002, -8.541666658789211],
              [-38.532500000774348, -8.541666657889891],
              [-38.532500000774348, -8.544999993255033],
              [-38.531666667382751, -8.544999992355713],
              [-38.531666666483432, -8.547499993429881],
              [-38.530833333091778, -8.547499993429881],
              [-38.530833333991154, -8.549999992705466],
              [-38.530833333091778, -8.550833326097063],
              [-38.532500000774348, -8.550833325197743],
              [-38.532500000774348, -8.551666659488717],
              [-38.533333334166002, -8.551666659488717],
              [-38.533333331468043, -8.552499992880314],
              [-38.535833332542211, -8.552499992880314],
              [-38.535833333441531, -8.553333326271968],
              [-38.536666667732504, -8.553333326271968],
              [-38.536666665933865, -8.554166658764245],
              [-38.538333331817739, -8.554166658764245],
              [-38.538333333616436, -8.558333325722344],
              [-38.539166667008033, -8.558333325722344],
              [-38.539166666108713, -8.559999993404915],
              [-38.53999999950031, -8.559999993404915],
              [-38.54000000039963, -8.560833325897249],
              [-38.540833332891964, -8.560833325897249],
              [-38.540833331992644, -8.561666659288846],
              [-38.542500001473854, -8.561666659288846],
              [-38.542499999675215, -8.562499992680443],
              [-38.543333332167492, -8.56249999447914],
              [-38.543333333966132, -8.56666665873928],
              [-38.544999998950743, -8.56666665873928],
              [-38.545000000749383, -8.567499993929516],
              [-38.545833334141037, -8.567499993929516],
              [-38.545833334141037, -8.56833332732117],
              [-38.546666665733994, -8.56833332642185],
              [-38.546666665733994, -8.569999992305782],
              [-38.547499999125591, -8.569999992305782],
              [-38.547499999125591, -8.570833324798059],
              [-38.548333331617926, -8.570833325697379],
              [-38.548333332517245, -8.571666659088976],
              [-38.551666666983067, -8.571666659088976],
              [-38.554166665359276, -8.571666659988296],
              [-38.554166668057235, -8.57249999337995],
              [-38.554999999650192, -8.57249999337995],
              [-38.554999999650192, -8.573333325872227],
              [-38.555833333041846, -8.573333325872227],
              [-38.555833333041846, -8.574166658364561],
              [-38.556666665534124, -8.574166658364561],
              [-38.556666666433443, -8.575833326047132],
              [-38.557500001623737, -8.575833326047132],
              [-38.557499998925778, -8.576666659438729],
              [-38.555833332142527, -8.576666659438729],
              [-38.555833332142527, -8.577499992830383],
              [-38.554999998750873, -8.577499992830383],
              [-38.554999999650192, -8.57833332622198],
              [-38.554166665359276, -8.57833332622198],
              [-38.554166667157915, -8.579166660512954],
              [-38.553333331967679, -8.579166658714257],
              [-38.553333331967679, -8.579999993005231],
              [-38.552500000374664, -8.579999993005231],
              [-38.552500000374664, -8.580833327296148],
              [-38.550833333591413, -8.580833326396828],
              [-38.550833331792774, -8.581666658889162],
              [-38.549166665908842, -8.581666658889162],
              [-38.549166667707482, -8.582499994079399],
              [-38.547500000024911, -8.582499993180079],
              [-38.547500000924288, -8.585833325847261],
              [-38.548333332517245, -8.585833325847261],
              [-38.548333332517245, -8.587499993529832],
              [-38.546666667532634, -8.587499993529832],
              [-38.546666666633314, -8.589166659413763],
              [-38.544999999850063, -8.589166659413763],
              [-38.545000000749383, -8.58999999280536],
              [-38.541666665384241, -8.58999999280536],
              [-38.541666667182881, -8.591666660487931],
              [-38.540833334690603, -8.591666658689292],
              [-38.540833333791284, -8.594166658864197],
              [-38.538333332717116, -8.594166658864197],
              [-38.53833333091842, -8.601666658489478],
              [-38.539166667008033, -8.601666660288117],
              [-38.539166667907352, -8.602499992780395],
              [-38.540833331992644, -8.602499992780395],
              [-38.540833332891964, -8.603333325272672],
              [-38.541666665384241, -8.603333326171992],
              [-38.5416666680822, -8.604166657765006],
              [-38.542500000574535, -8.604166658664326],
              [-38.542500000574535, -8.604999992055923],
              [-38.543333333066812, -8.604999992955243],
              [-38.543333333966132, -8.605833326346897],
              [-38.544999998051424, -8.605833326346897],
              [-38.545000000749383, -8.606666658839174],
              [-38.547499998226272, -8.606666658839174],
              [-38.547499999125591, -8.607499993130148],
              [-38.548333333416565, -8.607499993130148],
              [-38.548333332517245, -8.608333326521745],
              [-38.549166665009523, -8.608333326521745],
              [-38.549166665009523, -8.609166659014079],
              [-38.551666666983067, -8.609166658114759],
              [-38.551666666083747, -8.609999993304996],
              [-38.554999999650192, -8.609999993304996],
              [-38.558333331418055, -8.609999993304996],
              [-38.558333334116014, -8.609166659913399],
              [-38.559166666608348, -8.609166659014079],
              [-38.559166666608348, -8.605833326346897],
              [-38.558333334116014, -8.605833326346897],
              [-38.558333334116014, -8.604166658664326],
              [-38.557499998925778, -8.604166658664326],
              [-38.557499998925778, -8.600833325997144],
              [-38.558333333216694, -8.600833325997144],
              [-38.558333331418055, -8.598333325822296],
              [-38.563333331767808, -8.598333326721615],
              [-38.563333333566447, -8.597499994229281],
              [-38.564166666058725, -8.597499993329961],
              [-38.564166666958045, -8.596666659039045],
              [-38.565000000349698, -8.596666659039045],
              [-38.564999999450379, -8.595833325647391],
              [-38.565833333741296, -8.595833325647391],
              [-38.565833333741296, -8.594999993155113],
              [-38.56666666623363, -8.594999993155113],
              [-38.56666666443499, -8.592499992980265],
              [-38.565833333741296, -8.592499992980265],
              [-38.565833331942656, -8.589999991906041],
              [-38.56666666623363, -8.58999999370468],
              [-38.566666668032269, -8.589166659413763],
              [-38.567500000524547, -8.589166659413763],
              [-38.567499998725907, -8.588333326022109],
              [-38.5683333339162, -8.588333326022109],
              [-38.568333333016881, -8.587499994429152],
              [-38.569166666408478, -8.587499993529832],
              [-38.569166666408478, -8.586666660138235],
              [-38.571666667482702, -8.586666659238858],
              [-38.571666666583326, -8.585833325847261],
              [-38.573333331567937, -8.585833325847261],
              [-38.573333333366577, -8.584999993354984],
              [-38.574166667657551, -8.584999992455664],
              [-38.574166665858911, -8.58416665906401],
              [-38.574999998351188, -8.58416665906401],
              [-38.575000001049148, -8.583333325672413],
              [-38.575833332642162, -8.583333325672413],
              [-38.575833331742842, -8.582499993180079],
              [-38.576666666933079, -8.582499994079399],
              [-38.576666666033759, -8.581666658889162],
              [-38.577500001224053, -8.581666658889162],
              [-38.577499998526093, -8.580833326396828],
              [-38.576666666933079, -8.580833326396828],
              [-38.576666667832399, -8.579999993005231],
              [-38.575833332642162, -8.579999993005231],
              [-38.575833331742842, -8.577499993729703],
              [-38.576666665134439, -8.577499992830383],
              [-38.576666668731718, -8.576666659438729],
              [-38.580833332991858, -8.576666659438729],
              [-38.580833333891178, -8.575833326047132],
              [-38.583333333166763, -8.575833326047132],
              [-38.583333331368124, -8.574999992655478],
              [-38.58416666745768, -8.574999992655478],
              [-38.584166665659041, -8.573333325872227],
              [-38.584999999050694, -8.573333325872227],
              [-38.584999999050694, -8.569166658914128],
              [-38.585833332442292, -8.569166658914128],
              [-38.585833331542972, -8.567499992130877],
              [-38.584999999950014, -8.567499993030196],
              [-38.585000000849334, -8.565833326246945],
              [-38.585833331542972, -8.565833326246945],
              [-38.585833331542972, -8.564999992855348],
              [-38.586666665833945, -8.564999992855348],
              [-38.586666666733265, -8.564166659463694],
              [-38.587499999225543, -8.564166659463694],
              [-38.587500000124862, -8.562499992680443],
              [-38.58833333261714, -8.562499992680443],
              [-38.58833333261714, -8.561666659288846],
              [-38.589166667807433, -8.561666659288846],
              [-38.589166666008794, -8.559999992505595],
              [-38.589999998501071, -8.559999993404915],
              [-38.589999999400391, -8.556666658939093],
              [-38.591666667082961, -8.556666658039774],
              [-38.591666667082961, -8.555833326446816],
              [-38.593333333866212, -8.555833326446816],
              [-38.593333332067573, -8.554999992155842],
              [-38.594166665459227, -8.554999993055162],
              [-38.594166665459227, -8.554166659663565],
              [-38.594999999750144, -8.554166658764245],
              [-38.594999998850824, -8.553333326271968],
              [-38.594166665459227, -8.553333326271968],
              [-38.594166665459227, -8.552499992880314],
              [-38.593333332966893, -8.552499992880314],
              [-38.593333333866212, -8.551666659488717],
              [-38.591666667982281, -8.551666659488717],
              [-38.591666667982281, -8.549999992705466],
              [-38.590833332792045, -8.549999992705466],
              [-38.590833331892725, -8.549166659313812],
              [-38.589999998501071, -8.549166659313812],
              [-38.589999999400391, -8.548333325922215],
              [-38.590833331892725, -8.548333325022895],
              [-38.590833332792045, -8.547499993429881],
              [-38.591666666183642, -8.547499993429881],
              [-38.591666667082961, -8.546666659138964],
              [-38.592499999575296, -8.546666659138964],
              [-38.592499999575296, -8.544999993255033],
              [-38.593333333866212, -8.544999993255033],
              [-38.593333333866212, -8.541666658789211],
              [-38.594166666358547, -8.541666658789211],
              [-38.594166666358547, -8.540833326296934],
              [-38.594999997951504, -8.540833326296934],
              [-38.594999999750144, -8.53999999200596],
              [-38.594166666358547, -8.53999999290528],
              [-38.594166666358547, -8.537499992730432],
              [-38.593333332966893, -8.537499992730432],
              [-38.593333332966893, -8.535833325947181],
              [-38.594166666358547, -8.535833325947181],
              [-38.594166668157186, -8.529166658814233],
              [-38.594999999750144, -8.529166658814233],
              [-38.594999999750144, -8.524999992755397],
              [-38.595833334041117, -8.524999991856077],
              [-38.595833334041117, -8.52416666026312],
              [-38.596666665634075, -8.5241666593638],
              [-38.596666665634075, -8.519166659014047],
              [-38.599166665808923, -8.519166659014047],
              [-38.599166667607562, -8.51833332652177],
              [-38.600833331692854, -8.51833332472313],
              [-38.600833332592174, -8.517499994029436],
              [-38.600000000999216, -8.517499992230796],
              [-38.600000000099897, -8.515833326346865],
              [-38.599166665808923, -8.515833327246185],
              [-38.599166667607562, -8.514166658664294],
              [-38.599999997401937, -8.514166658664294],
              [-38.599999999200577, -8.513333326172017],
              [-38.600833332592174, -8.513333326172017],
              [-38.600833331692854, -8.510833325997169],
              [-38.601666666883148, -8.510833325997169],
              [-38.601666665983828, -8.509999993504834],
              [-38.604166666158676, -8.509999993504834],
              [-38.604166666158676, -8.509166658314598],
              [-38.605833332941927, -8.509166659213918],
              [-38.605833332941927, -8.508333325822264],
              [-38.606666667232844, -8.508333325822264],
              [-38.606666667232844, -8.507499993329986],
              [-38.610000000799346, -8.507499993329986],
              [-38.610000000799346, -8.508333325822264],
              [-38.610833331492984, -8.508333325822264],
              [-38.610833330593664, -8.507499993329986],
              [-38.611666665783957, -8.507499992430667],
              [-38.611666667582597, -8.506666659039013],
              [-38.612500000074874, -8.506666659039013],
              [-38.612500000074874, -8.507499993329986],
              [-38.613333332567208, -8.507499993329986],
              [-38.613333332567208, -8.506666659039013],
              [-38.614166666858125, -8.506666659039013],
              [-38.614166665958805, -8.505833324748096],
              [-38.615833333641376, -8.505833325647416],
              [-38.615833331842737, -8.504999993155138],
              [-38.614999999350459, -8.504999993155138],
              [-38.61499999845114, -8.502499992980233],
              [-38.614166665958805, -8.502499992080914],
              [-38.614166665958805, -8.500833326196982],
              [-38.613333331667889, -8.500833325297663],
              [-38.613333331667889, -8.499166659413731],
              [-38.612499999175554, -8.499166659413731],
              [-38.612500000074874, -8.498333326022134],
              [-38.611666666683277, -8.498333326022134],
              [-38.611666667582597, -8.497499993529857],
              [-38.61083333239236, -8.497499994429177],
              [-38.61083333239236, -8.494999992455632],
              [-38.609999998101387, -8.494999993354952],
              [-38.609999999900026, -8.494166658164715],
              [-38.609166666508429, -8.494166659064035],
              [-38.609166667407749, -8.49166665888913],
              [-38.608333333116775, -8.49166665978845],
              [-38.608333333116775, -8.484166659263849],
              [-38.607499999725178, -8.484166659263849],
              [-38.607499998825858, -8.483333325872252],
              [-38.608333334016095, -8.483333325872252],
              [-38.608333334016095, -8.481666659089001],
              [-38.607499998825858, -8.481666659089001],
              [-38.607499998825858, -8.480833324798027],
              [-38.606666667232844, -8.480833325697404],
              [-38.606666667232844, -8.479166658914153],
              [-38.605833332042607, -8.479166658914153],
              [-38.605833333841247, -8.477499993030222],
              [-38.604166666158676, -8.477499993030222],
              [-38.604166667057996, -8.476666658739248],
              [-38.602499998476105, -8.476666658739248],
              [-38.602499999375425, -8.474166659463719],
              [-38.603333332767022, -8.474166659463719],
              [-38.603333331867702, -8.472499994479108],
              [-38.600000000099897, -8.472499992680468],
              [-38.599999999200577, -8.470833325897217],
              [-38.598333331518006, -8.470833325897217],
              [-38.598333334215965, -8.469166659113966],
              [-38.597500000824311, -8.469166659113966],
              [-38.597500000824311, -8.468333325722369],
              [-38.596666665634075, -8.468333325722369],
              [-38.596666666533395, -8.467499992330715],
              [-38.596666666533395, -8.464999993055187],
              [-38.597499999924992, -8.464999992155867],
              [-38.597499999025672, -8.463333326271936],
              [-38.598333331518006, -8.463333326271936],
              [-38.598333331518006, -8.461666659488685],
              [-38.597499999924992, -8.461666659488685],
              [-38.597499999025672, -8.460833325197768],
              [-38.596666667432714, -8.460833326097088],
              [-38.596666665634075, -8.459999992705434],
              [-38.597500000824311, -8.459999992705434],
              [-38.597499999025672, -8.459166659313837],
              [-38.598333332417326, -8.459166659313837],
              [-38.598333334215965, -8.458333325922183],
              [-38.597500000824311, -8.458333325922183],
              [-38.597499999924992, -8.457499993429906],
              [-38.596666665634075, -8.457499993429906],
              [-38.596666665634075, -8.456666659138989],
              [-38.595833331343101, -8.456666659138989],
              [-38.595833331343101, -8.454999993255058],
              [-38.599166665808923, -8.454999993255058],
              [-38.599166665808923, -8.454166658964084],
              [-38.600833332592174, -8.454166658964084],
              [-38.600833333491494, -8.452499993080153],
              [-38.599999999200577, -8.452499993080153],
              [-38.600000000099897, -8.449999992905305],
              [-38.599166665808923, -8.449999992905305],
              [-38.599166667607562, -8.448333326122054],
              [-38.600000000999216, -8.448333326122054],
              [-38.600000000099897, -8.446666659338803],
              [-38.600833333491494, -8.446666659338803],
              [-38.600833332592174, -8.444999993454871],
              [-38.601666667782467, -8.444999993454871],
              [-38.601666666883148, -8.442499993280023],
              [-38.600833332592174, -8.442499993280023],
              [-38.600833333491494, -8.44166665898905],
              [-38.599999999200577, -8.441666659888369],
              [-38.600000000999216, -8.439999993105175],
              [-38.600833332592174, -8.439999992205856],
              [-38.600833333491494, -8.439166659713521],
              [-38.607499998825858, -8.439166658814202],
              [-38.607500000624498, -8.438333326321924],
              [-38.608333332217455, -8.438333326321924],
              [-38.608333333116775, -8.434166658464449],
              [-38.609166665609109, -8.434166659363768],
              [-38.609166667407749, -8.433333325972171],
              [-38.610833330593664, -8.433333325072851],
              [-38.610833334191, -8.434166659363768],
              [-38.612500000974251, -8.434166659363768],
              [-38.612500000074874, -8.434999992755422],
              [-38.615000002048419, -8.434999992755422],
              [-38.614999999350459, -8.434166659363768],
              [-38.615833331842737, -8.434166659363768],
              [-38.615833331842737, -8.433333325972171],
              [-38.617500000424627, -8.433333325972171],
              [-38.617499997726668, -8.432499993479894],
              [-38.618333332916961, -8.432499993479894],
              [-38.618333333816281, -8.43166665918892],
              [-38.623333334165977, -8.43166665918892],
              [-38.623333330568698, -8.432499991681198],
              [-38.625833332542243, -8.432499993479894],
              [-38.625833331642923, -8.433333325972171],
              [-38.628333332717091, -8.433333325972171],
              [-38.628333333616411, -8.434166659363768],
              [-38.629166667008008, -8.434166659363768],
              [-38.629166667008008, -8.434999992755422],
              [-38.63249999967519, -8.434999992755422],
              [-38.63250000057451, -8.434166660263088],
              [-38.633333333066844, -8.434166659363768],
              [-38.633333333066844, -8.433333325972171],
              [-38.634166665559121, -8.433333325972171],
              [-38.634166667357761, -8.429166659014072],
              [-38.633333332167524, -8.429166658114752],
              [-38.633333333066844, -8.42583332634689],
              [-38.63249999967519, -8.42583332634689],
              [-38.63249999967519, -8.423333325272722],
              [-38.631666665384273, -8.423333326172042],
              [-38.631666665384273, -8.421666659388791],
              [-38.630833331992619, -8.421666659388791],
              [-38.630833333791259, -8.417499993329955],
              [-38.628333332717091, -8.417499993329955],
              [-38.628333331817771, -8.416666658139718],
              [-38.627500000224813, -8.416666659039038],
              [-38.627500001124133, -8.415833325647441],
              [-38.626666667732479, -8.415833325647441],
              [-38.62666666593384, -8.414999993155106],
              [-38.624999999150589, -8.414999993155106],
              [-38.625000000949228, -8.413333326371855],
              [-38.623333335065297, -8.413333326371855],
              [-38.623333330568698, -8.41416665886419],
              [-38.619999998800836, -8.41416665886419],
              [-38.619999998800836, -8.411666658689285],
              [-38.619166665409239, -8.411666660487924],
              [-38.619166667207878, -8.409999992805353],
              [-38.618333332017642, -8.409999992805353],
              [-38.618333332916961, -8.406666659238908],
              [-38.617499999525307, -8.406666658339589],
              [-38.617499999525307, -8.404166659064003],
              [-38.618333332017642, -8.404166658164684],
              [-38.618333331118265, -8.402499993180072],
              [-38.619166665409239, -8.402499993180072],
              [-38.619166667207878, -8.398333326221973],
              [-38.620000000599532, -8.398333326221973],
              [-38.619999999700156, -8.394999992655471],
              [-38.617499999525307, -8.394999992655471],
              [-38.617499998625988, -8.394166659263874],
              [-38.61666666613371, -8.394166659263874],
              [-38.61666666703303, -8.39333332587222],
              [-38.615833331842737, -8.39333332587222],
              [-38.615833332742056, -8.392499993379943],
              [-38.615000001149099, -8.392499993379943],
              [-38.614999999350459, -8.391666659089026],
              [-38.614166666858125, -8.391666659089026],
              [-38.614166666858125, -8.390833325697372],
              [-38.612499999175554, -8.390833324798052],
              [-38.612500000074874, -8.389166658914121],
              [-38.611666665783957, -8.389166659813441],
              [-38.611666665783957, -8.388333326421844],
              [-38.61083333239236, -8.388333326421844],
              [-38.610833334191, -8.38749999303019],
              [-38.608333332217455, -8.38749999303019],
              [-38.608333333116775, -8.388333325522524],
              [-38.607499998825858, -8.388333326421844],
              [-38.607499999725178, -8.389166658914121],
              [-38.606666667232844, -8.389166659813441],
              [-38.606666667232844, -8.389999993205095],
              [-38.605833333841247, -8.389999993205095],
              [-38.605833333841247, -8.391666659089026],
              [-38.604166666158676, -8.391666658189649],
              [-38.604166667057996, -8.392499993379943],
              [-38.599999999200577, -8.392499993379943],
              [-38.600000000999216, -8.391666659988346],
              [-38.598333331518006, -8.391666659089026],
              [-38.598333331518006, -8.392499993379943],
              [-38.596666665634075, -8.392499993379943],
              [-38.596666667432714, -8.39333332587222],
              [-38.590833332792045, -8.39333332587222],
              [-38.590833332792045, -8.392499992480623],
              [-38.588333333516459, -8.392499994279262],
              [-38.588333334415836, -8.394999992655471],
              [-38.587500000124862, -8.394999992655471],
              [-38.587499999225543, -8.395833326047125],
              [-38.586666665833945, -8.395833326047125],
              [-38.586666667632585, -8.396666657640083],
              [-38.585833331542972, -8.396666659438722],
              [-38.585833333341611, -8.395833326047125],
              [-38.58416666655836, -8.395833326047125],
              [-38.58416666745768, -8.394999992655471],
              [-38.580833333891178, -8.39499999445411],
              [-38.580833332991858, -8.395833326047125],
              [-38.57833333281701, -8.395833326047125],
              [-38.57833333281701, -8.394999992655471],
              [-38.575833332642162, -8.394999992655471],
              [-38.575833331742842, -8.397499992830376],
              [-38.576666666933079, -8.397499992830376],
              [-38.576666666033759, -8.399166660512947],
              [-38.577499998526093, -8.399166658714307],
              [-38.577500001224053, -8.401666658889155],
              [-38.576666666933079, -8.401666658889155],
              [-38.576666666933079, -8.404999993354977],
              [-38.575000000149828, -8.404999993354977],
              [-38.574999998351188, -8.408333326022159],
              [-38.574166665858911, -8.408333326022159],
              [-38.574166665858911, -8.409166658514437],
              [-38.573333331567937, -8.409166659413756],
              [-38.573333333366577, -8.409999992805353],
              [-38.57249999817634, -8.409999992805353],
              [-38.57249999907566, -8.410833326197007],
              [-38.571666667482702, -8.410833327096327],
              [-38.571666665684006, -8.413333326371855],
              [-38.570000000699451, -8.413333326371855],
              [-38.569999998900812, -8.41416665886419],
              [-38.569166668207117, -8.41416665886419],
              [-38.569166666408478, -8.414999993155106],
              [-38.565000000349698, -8.414999993155106],
              [-38.564999998551059, -8.415833325647441],
              [-38.563333331767808, -8.415833324748064],
              [-38.563333333566447, -8.416666659039038],
              [-38.561666666783196, -8.416666659039038],
              [-38.561666665883877, -8.418333325822289],
              [-38.56083333159296, -8.418333324922969],
              [-38.56083333159296, -8.419166659213886],
              [-38.559999999100626, -8.419166659213886],
              [-38.560000000899265, -8.41999999350486],
              [-38.559166665709029, -8.41999999350486],
              [-38.559166665709029, -8.420833325997137],
              [-38.558333334116014, -8.420833325997137],
              [-38.558333331418055, -8.41999999170622],
              [-38.554166665359276, -8.41999999170622],
              [-38.554166667157915, -8.419166659213886],
              [-38.552499999475344, -8.419166659213886],
              [-38.552499999475344, -8.41999999350486],
              [-38.549166667707482, -8.41999999350486],
              [-38.549166666808162, -8.420833325997137],
              [-38.547499999125591, -8.420833325997137],
              [-38.547500000024911, -8.421666659388791],
              [-38.543333333066812, -8.421666659388791],
              [-38.543333333066812, -8.422499992780388],
              [-38.542499998775895, -8.422499992780388],
              [-38.542500000574535, -8.421666659388791],
              [-38.539166667008033, -8.421666659388791],
              [-38.539166666108713, -8.422499991881068],
              [-38.538333333616436, -8.422499992780388],
              [-38.538333332717116, -8.423333325272722],
              [-38.537500002023421, -8.423333327071362],
              [-38.537500000224782, -8.424166658664319],
              [-38.536666667732504, -8.424166660462959],
              [-38.536666668631824, -8.424999992955236],
              [-38.534999999150614, -8.424999992955236],
              [-38.534999999150614, -8.42583332634689],
              [-38.531666665584112, -8.42583332634689],
              [-38.531666666483432, -8.426666658839167],
              [-38.530833332192458, -8.426666658839167],
              [-38.530833333091778, -8.427499992230821],
              [-38.529166664509944, -8.427499993130141],
              [-38.529166667207903, -8.428333326521738],
              [-38.527500000424652, -8.428333326521738],
              [-38.527500000424652, -8.429166659014072],
              [-38.526666666133679, -8.429166659014072],
              [-38.526666666133679, -8.429999993304989],
              [-38.524999998451108, -8.429999992405669],
              [-38.525000001149067, -8.430833325797323],
              [-38.52416666595883, -8.430833324898003],
              [-38.52416666775747, -8.43166665918892],
              [-38.522500000074899, -8.43166665918892],
              [-38.522499999175579, -8.432499991681198],
              [-38.521666666683302, -8.432499993479894],
              [-38.521666665783982, -8.433333325972171],
              [-38.520833332392328, -8.433333326871491],
              [-38.520833332392328, -8.435833326147019],
              [-38.520000000799371, -8.435833326147019],
              [-38.520000000799371, -8.43749999293027],
              [-38.519999999900051, -8.438333326321924],
              [-38.520833331493009, -8.438333326321924],
              [-38.520833332392328, -8.439166658814202],
              [-38.519999999900051, -8.439166658814202],
              [-38.519999999000731, -8.439999993105175],
              [-38.519166665609077, -8.439999993105175],
              [-38.519166665609077, -8.440833326496772],
              [-38.5183333331168, -8.440833326496772],
              [-38.51833333221748, -8.442499993280023],
              [-38.516666665434229, -8.442499993280023],
              [-38.516666665434229, -8.443333325772301],
              [-38.515833332042575, -8.443333325772301],
              [-38.515833333841215, -8.444166659163955],
              [-38.514999998650978, -8.444166659163955],
              [-38.515000000449618, -8.444999991656232],
              [-38.51416666795734, -8.444999993454871],
              [-38.514166666158701, -8.445833325947206],
              [-38.513333331867727, -8.445833325947206],
              [-38.513333331867727, -8.444999993454871],
              [-38.511666667782436, -8.444999994354191],
              [-38.511666665084476, -8.444166659163955],
              [-38.510000000099865, -8.444166660063274],
              [-38.510000000999185, -8.444999993454871],
              [-38.509166667607587, -8.444999993454871],
              [-38.509166665808948, -8.444166658264635],
              [-38.508333332417294, -8.444166659163955],
              [-38.508333331517974, -8.444999993454871],
              [-38.505833333141766, -8.444999991656232],
              [-38.505833331343126, -8.445833325047886],
              [-38.504999999750169, -8.445833325947206],
              [-38.505000000649488, -8.444999993454871],
              [-38.504166668157154, -8.444999993454871],
              [-38.504166666358515, -8.445833325947206],
              [-38.500833332792013, -8.445833325947206],
              [-38.500833332792013, -8.446666659338803],
              [-38.499999998501096, -8.446666659338803],
              [-38.499999998501096, -8.447499992730457],
              [-38.499166666008762, -8.447499992730457],
              [-38.499166666908081, -8.448333325222734],
              [-38.498333332617165, -8.448333326122054],
              [-38.498333331717845, -8.449166659513651],
              [-38.497499999225568, -8.449166660413027],
              [-38.497499999225568, -8.448333326122054],
              [-38.493333332267412, -8.448333326122054],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '126',
        OBJECTID: 169.0,
        Nome_area: 'Caboclo',
        COD_area: 'CA175',
        Import_bio: 'Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Criação de UC de Proteção Integral',
        Area_ha: 93542.5331,
        Shape_Leng: 2.99667,
        Shape_Area: 0.07682,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-41.112757959, -8.703974089999962],
              [-41.113709722999907, -8.703797227999939],
              [-41.116505002999901, -8.705044225999936],
              [-41.121429177, -8.705758662999934],
              [-41.126290952, -8.705809812999977],
              [-41.131324977, -8.704252868999951],
              [-41.132900029, -8.704324282999947],
              [-41.135843247, -8.705685585999952],
              [-41.136466779958205, -8.705833326147058],
              [-41.136666667932332, -8.705833326147058],
              [-41.136666667881222, -8.705880687671035],
              [-41.137499999896214, -8.706078137690097],
              [-41.137500000424609, -8.705833326147058],
              [-41.144999999150571, -8.705833325247738],
              [-41.144999999150571, -8.707321852075806],
              [-41.152614781, -8.707767032999932],
              [-41.155239712404686, -8.708333326321906],
              [-41.155833332342354, -8.708333326321906],
              [-41.155833332618769, -8.708461391825798],
              [-41.162898742, -8.709985657999939],
              [-41.163763107151418, -8.709999993105157],
              [-41.165835514763728, -8.709999993105157],
              [-41.166666667089586, -8.709647099879232],
              [-41.166666665534137, -8.709166658814183],
              [-41.167499999825111, -8.709166658814183],
              [-41.167499998925791, -8.708333326321906],
              [-41.168633726300733, -8.708333326321906],
              [-41.169166667337336, -8.708175482018127],
              [-41.169166665708985, -8.706666658639335],
              [-41.170833332492236, -8.706666658639335],
              [-41.17166666588389, -8.706666658639335],
              [-41.171666666160299, -8.707435042557552],
              [-41.172431162999899, -8.707208616999939],
              [-41.176044882276592, -8.710833326832264],
              [-41.176666667132963, -8.710833326496754],
              [-41.176666668032283, -8.709999993105157],
              [-41.178333332117518, -8.709999993105157],
              [-41.178333332117518, -8.709166657914864],
              [-41.179166668207131, -8.709166658814183],
              [-41.179166666408491, -8.707499992930309],
              [-41.183333330668631, -8.707499992930309],
              [-41.183333331225441, -8.708531898816753],
              [-41.184131678510248, -8.709166658814183],
              [-41.185000001049161, -8.709166658814183],
              [-41.1850000003041, -8.709857055024111],
              [-41.185179775698515, -8.709999993105157],
              [-41.188333333716344, -8.709999993105157],
              [-41.188333333716344, -8.708333326321906],
              [-41.189166667107941, -8.708333327221226],
              [-41.18916666800726, -8.706666658639335],
              [-41.190833333891192, -8.706666657740016],
              [-41.190833332092552, -8.705833326147058],
              [-41.194166667457694, -8.705833326147058],
              [-41.194166665659054, -8.706666658639335],
              [-41.195637615208973, -8.706666658639335],
              [-41.197561918, -8.706520021999957],
              [-41.19987514, -8.706937745999937],
              [-41.200701015749381, -8.707499992930309],
              [-41.200833333691378, -8.707499992930309],
              [-41.200833333594161, -8.70759007343227],
              [-41.201925086174832, -8.708333326321906],
              [-41.202499998675933, -8.708333326321906],
              [-41.20249999897819, -8.708613409210837],
              [-41.204039724195326, -8.709166658260301],
              [-41.204999999750157, -8.709166657914864],
              [-41.204999999750157, -8.708333325422586],
              [-41.205833331343115, -8.708333327221226],
              [-41.205833332958548, -8.709081776955594],
              [-41.209166666798389, -8.707583510476901],
              [-41.209166665808937, -8.706666658639335],
              [-41.211666666883104, -8.706666658639335],
              [-41.211666666883104, -8.705833326147058],
              [-41.212499999375439, -8.705833325247738],
              [-41.212500001174078, -8.704999993654724],
              [-41.215000000449606, -8.704999992755404],
              [-41.215000000449606, -8.705833326147058],
              [-41.215833332042621, -8.705833326147058],
              [-41.215833332042621, -8.704999992755404],
              [-41.218333332217469, -8.704999992755404],
              [-41.218333332217469, -8.705833327046378],
              [-41.219166665609066, -8.705833326147058],
              [-41.21916666624525, -8.706422833796083],
              [-41.220833332392317, -8.706149536798122],
              [-41.220833332392317, -8.705833326147058],
              [-41.221666665783971, -8.705833326147058],
              [-41.22166666848193, -8.704999992755404],
              [-41.224166667757459, -8.704999992755404],
              [-41.224166667757459, -8.705075313339005],
              [-41.225265706, -8.704669785999952],
              [-41.226978499, -8.704702090999939],
              [-41.229105155, -8.705415573999966],
              [-41.23016717, -8.706364390999967],
              [-41.232688106, -8.708617694999926],
              [-41.234682253, -8.709667731999959],
              [-41.239841043, -8.710863],
              [-41.240125317, -8.711927659999962],
              [-41.238240197, -8.716101352999946],
              [-41.237681923, -8.718763332999972],
              [-41.238567804999903, -8.719731159999974],
              [-41.239999999419929, -8.720074518043168],
              [-41.239999999500355, -8.719999992905286],
              [-41.240833331992633, -8.719999992905286],
              [-41.240833331992633, -8.720122935942427],
              [-41.241666667166015, -8.720007826611184],
              [-41.241666667182926, -8.719999992905286],
              [-41.241723379359478, -8.719999992905286],
              [-41.243333333726127, -8.719777608471752],
              [-41.243333333066801, -8.719166660413009],
              [-41.244999999850052, -8.719166659513689],
              [-41.244999999850052, -8.719730836367393],
              [-41.246902115286581, -8.719999993120362],
              [-41.247499999125637, -8.719999992905286],
              [-41.247499999419432, -8.720272232118509],
              [-41.248731126059191, -8.72083332629694],
              [-41.253333332866987, -8.72083332629694],
              [-41.253333332866987, -8.719999992905286],
              [-41.256666665534169, -8.719999993804606],
              [-41.256666665534169, -8.720348532803646],
              [-41.257739917, -8.720122998999944],
              [-41.260624595, -8.72071650099997],
              [-41.262989660999899, -8.721876776999972],
              [-41.265040043999903, -8.723857857999963],
              [-41.26572736928884, -8.724999993255039],
              [-41.267499998725896, -8.724999993255039],
              [-41.267499998725896, -8.725833325747317],
              [-41.27000000159876, -8.725833325747317],
              [-41.269999998900801, -8.727499993429888],
              [-41.271666667482691, -8.727499993429888],
              [-41.271666665684052, -8.728333325022902],
              [-41.273333331567983, -8.728333325922222],
              [-41.273333333366622, -8.729166659313819],
              [-41.274166666758219, -8.729166659313819],
              [-41.274166667657539, -8.73083332609707],
              [-41.275000000149817, -8.73083332609707],
              [-41.275000001049193, -8.731666659488724],
              [-41.275833332642151, -8.731666659488724],
              [-41.275833332642151, -8.733409916597902],
              [-41.278264836999902, -8.735313038999958],
              [-41.281537594, -8.736100377999948],
              [-41.283333333342831, -8.736056716783875],
              [-41.283333334066072, -8.735833326446823],
              [-41.284910513875793, -8.735833326446823],
              [-41.28583333244228, -8.735606318603022],
              [-41.28583333244228, -8.734999993055169],
              [-41.288298134018078, -8.734999992168511],
              [-41.288333331717865, -8.73499133374504],
              [-41.288333331717865, -8.734166659663572],
              [-41.289999999400436, -8.734166658764252],
              [-41.29000000100438, -8.734909786418354],
              [-41.290707528867124, -8.734999993055169],
              [-41.292500000474604, -8.734999993055169],
              [-41.292499999981345, -8.735228525102196],
              [-41.294166664983265, -8.735441017432795],
              [-41.294166665459215, -8.734999993954489],
              [-41.295000000649452, -8.734999993055169],
              [-41.294999998850813, -8.733333326271918],
              [-41.299038542224316, -8.733333326271918],
              [-41.299166665916125, -8.733233978005279],
              [-41.299166666708231, -8.732499992880321],
              [-41.299955946416283, -8.732499992880321],
              [-41.304166666601795, -8.728309124646129],
              [-41.304166666158665, -8.726666658239651],
              [-41.303333331867748, -8.726666658239651],
              [-41.303333331867748, -8.725833325747317],
              [-41.302499999375414, -8.725833325747317],
              [-41.302500001174053, -8.724166658964066],
              [-41.303333332767068, -8.724166658964066],
              [-41.303333333666387, -8.723333326471788],
              [-41.304166666158665, -8.723333324673149],
              [-41.304166666158665, -8.722499993080191],
              [-41.305833332941916, -8.722499993080191],
              [-41.305833332042596, -8.721666658789218],
              [-41.306666665434193, -8.721666658789218],
              [-41.306666665434193, -8.720833325397621],
              [-41.309166666508418, -8.72083332629694],
              [-41.309166666508418, -8.721666658789218],
              [-41.310833331493029, -8.721666658789218],
              [-41.310833331493029, -8.72083332629694],
              [-41.313333331667877, -8.72083332719626],
              [-41.313333332567197, -8.721666658789218],
              [-41.31416666865681, -8.721666659688537],
              [-41.314166666858171, -8.722499993080191],
              [-41.315833332742045, -8.722499993080191],
              [-41.315833333641365, -8.723333326471788],
              [-41.319166669006506, -8.723333326471788],
              [-41.319166667207867, -8.724166658964066],
              [-41.320833333091798, -8.724166658964066],
              [-41.320833333091798, -8.726666659138971],
              [-41.321666665584132, -8.726666659138971],
              [-41.321666666483452, -8.727499991631248],
              [-41.322499999875049, -8.727499993429888],
              [-41.322500000707223, -8.728271104046595],
              [-41.325030185047517, -8.728333325922222],
              [-41.326666667732468, -8.728333325922222],
              [-41.32666666768904, -8.728373570033201],
              [-41.329166666898274, -8.728435049601067],
              [-41.329166667008053, -8.728333325922222],
              [-41.330492067466366, -8.728333325922222],
              [-41.334351609, -8.727661531999955],
              [-41.343464754, -8.722009315999967],
              [-41.344999999021404, -8.720664011314055],
              [-41.344999999650213, -8.718333326122035],
              [-41.345833332142547, -8.718333326122035],
              [-41.345833333941187, -8.716666659338784],
              [-41.346666668232103, -8.716666659338784],
              [-41.346666668232103, -8.71499999345491],
              [-41.347499999825061, -8.71499999345491],
              [-41.347499998026422, -8.714166658264617],
              [-41.346666666433464, -8.714166659163936],
              [-41.346666665534144, -8.71333332487302],
              [-41.349166665708992, -8.713333325772339],
              [-41.349166665708992, -8.712499993280005],
              [-41.350833332492243, -8.712499993280005],
              [-41.350833331592924, -8.709999993105157],
              [-41.350833331063392, -8.709018635519937],
              [-41.347499999174104, -8.709539710610795],
              [-41.347499998925741, -8.709999993105157],
              [-41.345833333041867, -8.709999993105157],
              [-41.345833332826302, -8.709800248219246],
              [-41.345418423, -8.709865107999976],
              [-41.342399833999899, -8.709543451999926],
              [-41.33691677, -8.708357127999923],
              [-41.336891278835331, -8.708333326321906],
              [-41.33416666555911, -8.708333326321906],
              [-41.33416666645843, -8.706666658639335],
              [-41.333333332167513, -8.706666658639335],
              [-41.333333332167513, -8.705011190798908],
              [-41.333321338317752, -8.704999991869029],
              [-41.332500000574498, -8.704999992755404],
              [-41.332500000574498, -8.70425221416723],
              [-41.3323515558317, -8.704166659363807],
              [-41.331666665384262, -8.704166659363807],
              [-41.331666666236231, -8.70377192935449],
              [-41.33090565266744, -8.703333325972153],
              [-41.330833331992608, -8.703333325972153],
              [-41.330833332127554, -8.703291644674071],
              [-41.329580721999903, -8.702569713999944],
              [-41.329382384187113, -8.702499993479876],
              [-41.328333332717079, -8.702499993479876],
              [-41.328333332319112, -8.702131226471778],
              [-41.32701175118477, -8.701666658860074],
              [-41.32416666755762, -8.701666660088222],
              [-41.32416666575898, -8.700833325797305],
              [-41.322500000774369, -8.700833324897985],
              [-41.322500000774369, -8.700080669881952],
              [-41.322270495661741, -8.699999993305028],
              [-41.321666666483452, -8.699999993305028],
              [-41.321666666483452, -8.69978773279983],
              [-41.321281106, -8.699652198999967],
              [-41.32011276547896, -8.699166659014054],
              [-41.317499998625976, -8.699166659014054],
              [-41.317499999525296, -8.698333326521777],
              [-41.316666666133699, -8.698333326521777],
              [-41.316666667824059, -8.697550159080794],
              [-41.31658333299535, -8.697499993130123],
              [-41.314999999350448, -8.697499993130123],
              [-41.314999998451128, -8.696666657939886],
              [-41.31416666775749, -8.696666658839206],
              [-41.314166667618082, -8.696020751188884],
              [-41.308305899999901, -8.690982081999952],
              [-41.307943017999904, -8.690586891999947],
              [-41.304343529060851, -8.68666665903902],
              [-41.303333331867748, -8.68666665903902],
              [-41.303333331867748, -8.685833325647422],
              [-41.302499998476094, -8.685833325647422],
              [-41.302500001174053, -8.684999993155088],
              [-41.300833331692843, -8.684999993155088],
              [-41.300833331692843, -8.683333326371894],
              [-41.300000000999205, -8.683333326371894],
              [-41.300000000099885, -8.68249999298024],
              [-41.298333331517995, -8.68249999298024],
              [-41.298333335115274, -8.681666658689323],
              [-41.296666666533383, -8.681666658689323],
              [-41.296666665634064, -8.680833326196989],
              [-41.295833333141786, -8.680833326196989],
              [-41.295833334041106, -8.678333326921461],
              [-41.295833334940426, -8.674999993354959],
              [-41.296666665634064, -8.674999993354959],
              [-41.296666667396998, -8.674183201212299],
              [-41.296409341108124, -8.673333325672388],
              [-41.295833334041106, -8.673333325672388],
              [-41.295833334041106, -8.672499993180111],
              [-41.294999998850813, -8.672499993180111],
              [-41.295000000649452, -8.669999993904526],
              [-41.294166668157175, -8.669999993005206],
              [-41.294166665459215, -8.669166658714289],
              [-41.292500001373924, -8.669166658714289],
              [-41.292499999575284, -8.668333327121275],
              [-41.29166666708295, -8.668333325322635],
              [-41.29166666618363, -8.667499992830358],
              [-41.290833332792033, -8.667499992830358],
              [-41.290833331892713, -8.665833326047107],
              [-41.290000001199076, -8.665833326047107],
              [-41.290000001568075, -8.663781769060506],
              [-41.288997036, -8.661897642999973],
              [-41.287434022, -8.660839067999966],
              [-41.27999999870093, -8.660598366244121],
              [-41.27999999870093, -8.660833324798034],
              [-41.279166668007292, -8.660833325697354],
              [-41.279166666208653, -8.661666659089008],
              [-41.274207847794173, -8.661666659683616],
              [-41.272007053, -8.662039824999967],
              [-41.271666666219367, -8.662162696980921],
              [-41.271666666583371, -8.662499992480605],
              [-41.270833333191717, -8.662499992480605],
              [-41.270833331393078, -8.663333325872259],
              [-41.269166665509147, -8.663333326771578],
              [-41.269166668207106, -8.664166658364536],
              [-41.265833332842021, -8.664166659263856],
              [-41.265833331942645, -8.663333325872259],
              [-41.265833332165371, -8.662095093316442],
              [-41.264545256, -8.661951707999954],
              [-41.259471120999898, -8.662439281999978],
              [-41.253845382999899, -8.662175781999956],
              [-41.253213622, -8.662033996999975],
              [-41.252581861, -8.661892211999938],
              [-41.251318339, -8.661608642999973],
              [-41.249407544, -8.65955843799998],
              [-41.247406956, -8.658378762999975],
              [-41.243465821, -8.65670934999997],
              [-41.241632727, -8.656586067999969],
              [-41.239490503, -8.655826576999969],
              [-41.239186313999902, -8.654514950999953],
              [-41.2392352909999, -8.653748230999955],
              [-41.238200506999902, -8.652426949999951],
              [-41.237434188, -8.650805327999931],
              [-41.233788846, -8.647614319999953],
              [-41.23106667, -8.647146845999933],
              [-41.22699025, -8.647785912999952],
              [-41.225160869, -8.64705121499998],
              [-41.223331488, -8.646316516999955],
              [-41.221711721, -8.646177209999957],
              [-41.220932244, -8.646374345999964],
              [-41.217796197, -8.646020489999952],
              [-41.215043643999898, -8.645246425999972],
              [-41.213542855, -8.644155528999939],
              [-41.210292409, -8.640083918999951],
              [-41.206544139, -8.633295033999961],
              [-41.206649265, -8.62259902299996],
              [-41.206826373, -8.620237434999989],
              [-41.207994089, -8.618370056999929],
              [-41.208005801, -8.618351182999961],
              [-41.208186037, -8.618063517999934],
              [-41.20833333241734, -8.617335119239382],
              [-41.20833333241734, -8.614918916502175],
              [-41.208149626306692, -8.614166659363775],
              [-41.206666665634089, -8.614166659363775],
              [-41.206666667432728, -8.609999993304996],
              [-41.207499999925005, -8.609999992405676],
              [-41.207499999025686, -8.609166659014079],
              [-41.20833333241734, -8.609166659014079],
              [-41.20833333151802, -8.608333326521745],
              [-41.208878183018328, -8.608333326521745],
              [-41.209166667241654, -8.607839067831579],
              [-41.209166667607576, -8.607499993130148],
              [-41.209364575146637, -8.607499993130148],
              [-41.209419006, -8.607406736999955],
              [-41.21000000099923, -8.606891628649803],
              [-41.21000000099923, -8.605833326346897],
              [-41.210833333491507, -8.605833326346897],
              [-41.210833332592188, -8.604999992955243],
              [-41.211666667782424, -8.604999992955243],
              [-41.211666665983785, -8.604166658664326],
              [-41.212359781352426, -8.604166658664326],
              [-41.212499999336806, -8.603369126446164],
              [-41.212499999375439, -8.603333326171992],
              [-41.211666666883104, -8.603333326171992],
              [-41.211666665708393, -8.60170054335239],
              [-41.209944957999902, -8.60136896699993],
              [-41.209395263, -8.601015587999939],
              [-41.207485555999902, -8.600934200999966],
              [-41.204655299, -8.600259578999953],
              [-41.20418602657324, -8.599999993504866],
              [-41.202499999575252, -8.599999993504866],
              [-41.202499999575252, -8.600833325997144],
              [-41.200833333691378, -8.600833326896463],
              [-41.200833332473614, -8.594627257622795],
              [-41.200738084, -8.594410693999979],
              [-41.199703902, -8.592991745999937],
              [-41.198566168, -8.592477148999929],
              [-41.192500000426534, -8.592614853416523],
              [-41.192499998875803, -8.593333326371862],
              [-41.190833332991872, -8.593333326371862],
              [-41.190833332257334, -8.59265268744684],
              [-41.186666667565554, -8.592747272392536],
              [-41.186666666933093, -8.593333326371862],
              [-41.185833333541439, -8.593333326371862],
              [-41.185833333541439, -8.592766189406323],
              [-41.185246929999906, -8.592779500999944],
              [-41.184166666702247, -8.593038253742161],
              [-41.184166667657564, -8.593333327271182],
              [-41.182934769356734, -8.593333327005293],
              [-41.181647105, -8.593641757999924],
              [-41.180213172845328, -8.593333326417874],
              [-41.179999999800089, -8.593333326371862],
              [-41.179999998900769, -8.594166659763516],
              [-41.178333333916157, -8.594166658864197],
              [-41.178333334815534, -8.590833326197014],
              [-41.177499998725921, -8.590833326197014],
              [-41.17749999962524, -8.589166658514443],
              [-41.178333333016838, -8.589166659413763],
              [-41.178333333016838, -8.588333326022109],
              [-41.17749999962524, -8.588333326022109],
              [-41.177499997938291, -8.58755174125592],
              [-41.177429239729719, -8.587499993529832],
              [-41.175833332841989, -8.587499993529832],
              [-41.175833332841989, -8.586929262316866],
              [-41.175301141, -8.586950660999943],
              [-41.172921707999905, -8.586306567999939],
              [-41.171555917, -8.585630943999936],
              [-41.16993613625025, -8.58416665906401],
              [-41.169166665708985, -8.58416665906401],
              [-41.169166667210455, -8.583471029474943],
              [-41.169014369092245, -8.583333324855273],
              [-41.167499998026472, -8.583333325672413],
              [-41.167499998776783, -8.581595186950331],
              [-41.166739381, -8.58068225099993],
              [-41.165261459, -8.577886774999962],
              [-41.165145126580178, -8.577499992752074],
              [-41.164999999650206, -8.577499992830383],
              [-41.164999999650206, -8.577017474462775],
              [-41.164894485085149, -8.576666659438729],
              [-41.163333332866955, -8.576666659438729],
              [-41.163333331068316, -8.573333325872227],
              [-41.162500000374678, -8.573333325872227],
              [-41.162499999475358, -8.57249999337995],
              [-41.1616666678824, -8.57249999337995],
              [-41.161666666983024, -8.569999994104421],
              [-41.160833331792787, -8.569999992305782],
              [-41.160833332692107, -8.569166658914128],
              [-41.161666666083704, -8.569166658914128],
              [-41.161666666541656, -8.567893601492569],
              [-41.160900495799034, -8.566666658666797],
              [-41.160833331792787, -8.56666665873928],
              [-41.160833331908862, -8.566559102676191],
              [-41.160380117093879, -8.565833326246945],
              [-41.16000000019983, -8.565833326246945],
              [-41.16000000019983, -8.565224608541921],
              [-41.159339359031499, -8.564166660363014],
              [-41.159166665908856, -8.564166660363014],
              [-41.159166666207305, -8.563890110750872],
              [-41.157778220533984, -8.561666659288846],
              [-41.157500000924244, -8.561666659288846],
              [-41.157500000443427, -8.561221118725751],
              [-41.15725784122202, -8.560833325897249],
              [-41.156666666633328, -8.560833325897249],
              [-41.156666667532647, -8.559166659113998],
              [-41.155833334140993, -8.559166659113998],
              [-41.155833331443034, -8.558333324823025],
              [-41.156666667532647, -8.558333325722344],
              [-41.156666665942694, -8.553913447773242],
              [-41.156560266012541, -8.553333326271968],
              [-41.155833330543715, -8.553333326271968],
              [-41.155833332742063, -8.551805542984225],
              [-41.155093258, -8.550894143999926],
              [-41.154172025, -8.550468435999958],
              [-41.152650528, -8.549765342999935],
              [-41.152045852, -8.548305877999944],
              [-41.152317277, -8.546051133999978],
              [-41.152444967, -8.54499145499994],
              [-41.152499999844487, -8.544818620377466],
              [-41.152499998775852, -8.543333326471782],
              [-41.150833333791297, -8.543333324673142],
              [-41.150833332891978, -8.541666658789211],
              [-41.151666668082214, -8.541666658789211],
              [-41.151666665384255, -8.540833326296934],
              [-41.150833333791297, -8.540833326296934],
              [-41.150833331992658, -8.53999999200596],
              [-41.150000000399643, -8.53999999290528],
              [-41.150000000399643, -8.539542733531759],
              [-41.149239344770251, -8.539166659513683],
              [-41.147500001124115, -8.539166659513683],
              [-41.147500001124115, -8.536666659338835],
              [-41.148333332717073, -8.536666659338835],
              [-41.148333332717073, -8.533333325772333],
              [-41.147500000224795, -8.533333324873013],
              [-41.147500000224795, -8.532981217818421],
              [-41.147393498, -8.532975382999959],
              [-41.146666666986476, -8.533191244757136],
              [-41.146666666833141, -8.533333325772333],
              [-41.146188264097376, -8.533333325772333],
              [-41.144999999912287, -8.533686228703289],
              [-41.145000000949267, -8.53416665916393],
              [-41.143382336074076, -8.53416665916393],
              [-41.142989445, -8.534283343999952],
              [-41.142751307223214, -8.53416665916393],
              [-41.141666666483445, -8.53416665916393],
              [-41.141666666483445, -8.53363519738339],
              [-41.141050586692963, -8.533333325205444],
              [-41.139999999700194, -8.533333325772333],
              [-41.139999999700194, -8.532838483703504],
              [-41.139173829, -8.532666207999979],
              [-41.134627348, -8.533538644999958],
              [-41.13250296, -8.535528443999969],
              [-41.131270551999897, -8.537050994999955],
              [-41.129605923, -8.540855540999933],
              [-41.128791304999901, -8.541504433999929],
              [-41.128269641, -8.541770881999975],
              [-41.126732859, -8.54195586399992],
              [-41.118992557999903, -8.537525184999936],
              [-41.116184846999907, -8.537269064999984],
              [-41.114417851, -8.53490438899996],
              [-41.111463993, -8.533631088999924],
              [-41.108377464999897, -8.530732018999977],
              [-41.104072204, -8.522368652999935],
              [-41.102563066999899, -8.521798095999946],
              [-41.100011007, -8.521803644999977],
              [-41.097957583, -8.520751172999949],
              [-41.095584023, -8.518532964999959],
              [-41.094008394999904, -8.516314164999921],
              [-41.090489923, -8.51262553999994],
              [-41.085976629, -8.503555729999951],
              [-41.084684141, -8.500958376999963],
              [-41.083265976, -8.496622209999945],
              [-41.082254033, -8.491135101999932],
              [-41.081799697999898, -8.484692578999942],
              [-41.082194425999901, -8.480081499999928],
              [-41.079916633, -8.473650751999962],
              [-41.078926970999902, -8.472503234999982],
              [-41.07654944, -8.472490020999937],
              [-41.065403939, -8.475157954999926],
              [-41.057595173, -8.477657986999931],
              [-41.056676384, -8.478222180999984],
              [-41.055955156, -8.479389327999973],
              [-41.053232914, -8.481119140999967],
              [-41.052203630999898, -8.481218188999948],
              [-41.051098569, -8.480473777999986],
              [-41.0502605349999, -8.477944825999943],
              [-41.049967414, -8.475504121999952],
              [-41.050231704999902, -8.472383421999947],
              [-41.051595424, -8.465119737999947],
              [-41.051306347, -8.463358680999928],
              [-41.049673566, -8.459773387999961],
              [-41.047975361999903, -8.449045978999944],
              [-41.047925655, -8.448732057999964],
              [-41.047754629999908, -8.447651777999965],
              [-41.047753655, -8.447645624999943],
              [-41.047510525999897, -8.446110070999966],
              [-41.045087754999898, -8.439960640999972],
              [-41.04412726, -8.438811610999958],
              [-41.043717840999904, -8.436656713999978],
              [-41.038990571999904, -8.431829200999966],
              [-41.034029083999897, -8.430107411999925],
              [-41.023648641, -8.428475617999931],
              [-41.022106032999893, -8.427507315999945],
              [-41.020312783, -8.424686787999969],
              [-41.017680219999903, -8.424084833999933],
              [-41.015528522, -8.424172500999926],
              [-41.011042034999903, -8.422513685999949],
              [-41.007379832, -8.420929113999936],
              [-41.006925407, -8.417665742999924],
              [-41.005394789, -8.415594991999974],
              [-41.003097929, -8.413695426999938],
              [-41.002344854999897, -8.411790067999961],
              [-41.002206339999901, -8.410515964999945],
              [-41.002802318, -8.407668133999939],
              [-41.003356488999898, -8.405020079999927],
              [-41.003234693, -8.404002384999961],
              [-41.001666666419936, -8.401994907515212],
              [-41.001666664784693, -8.402499993180072],
              [-41.000833333191736, -8.402499994079392],
              [-41.000833331393096, -8.401666659788475],
              [-40.998333333916207, -8.401666658889155],
              [-40.998333332325416, -8.400192593188239],
              [-40.986962638, -8.399441706999937],
              [-40.983610578, -8.398227331999983],
              [-40.981379424999901, -8.397929560999968],
              [-40.97923002600001, -8.398248447999949],
              [-40.978545009999898, -8.398650711999959],
              [-40.972901039, -8.406933466999936],
              [-40.969290336, -8.410283897999925],
              [-40.966183109, -8.411595872999948],
              [-40.965558094, -8.412128800999939],
              [-40.964904463, -8.413423005999968],
              [-40.965508954, -8.416458741999977],
              [-40.964302055, -8.417430686999987],
              [-40.961957115, -8.417537434999936],
              [-40.960768188, -8.419101868999974],
              [-40.957878010999899, -8.419398238999968],
              [-40.957498767999901, -8.419782933999951],
              [-40.9580918139999, -8.422457440999958],
              [-40.957094356, -8.423466969999945],
              [-40.953581433, -8.424706392999948],
              [-40.952377834999901, -8.426964495999934],
              [-40.950199046, -8.429150377999974],
              [-40.949593641, -8.429558582999963],
              [-40.94051597, -8.433234470999926],
              [-40.938094024, -8.434980017999973],
              [-40.937034291, -8.43682660599994],
              [-40.935812987, -8.438954732999946],
              [-40.930506948999898, -8.44365044399996],
              [-40.926851149999898, -8.446344417999965],
              [-40.925149037, -8.446326064999944],
              [-40.923846856999901, -8.44505674099997],
              [-40.919956328, -8.439030128999955],
              [-40.916848148, -8.436160304999927],
              [-40.915346908999901, -8.436273757999974],
              [-40.914398601999899, -8.436239590999953],
              [-40.913368143999897, -8.436202626999931],
              [-40.912736106999901, -8.436179987999948],
              [-40.912080790999902, -8.435363968999983],
              [-40.910035176, -8.428925791999964],
              [-40.9090085999999, -8.427329499999923],
              [-40.903491397, -8.423853482999965],
              [-40.902850414999897, -8.423449670999952],
              [-40.902293702, -8.423098983999978],
              [-40.90153545, -8.421627004999946],
              [-40.901290512999893, -8.420261682999971],
              [-40.902452481, -8.418165066999959],
              [-40.902516297, -8.415793737999937],
              [-40.901509820999898, -8.410126106999947],
              [-40.901849182, -8.407957766999969],
              [-40.903369141, -8.405722776999943],
              [-40.906918903, -8.402389365999973],
              [-40.907787546999899, -8.401374136999948],
              [-40.90761549, -8.398987179999951],
              [-40.906413196, -8.397452357999946],
              [-40.902428345, -8.397085055999975],
              [-40.89947075700001, -8.395020621999947],
              [-40.8945190869999, -8.392502628999983],
              [-40.894108783, -8.389962552999986],
              [-40.890751466, -8.387899359999942],
              [-40.889870383999899, -8.386749867999969],
              [-40.889611531999897, -8.38259111899993],
              [-40.890398419999897, -8.380507028999945],
              [-40.892377732999897, -8.378032907999975],
              [-40.892769439999903, -8.376720914999957],
              [-40.892202751, -8.374421004999927],
              [-40.892666483, -8.371792843999966],
              [-40.897103976, -8.369047966999972],
              [-40.897746509999898, -8.367917787999939],
              [-40.897660085, -8.366532092999924],
              [-40.894833561, -8.358766962999937],
              [-40.893472304999896, -8.35731013999998],
              [-40.891634424, -8.356085759999926],
              [-40.889086082999903, -8.356174560999932],
              [-40.881525337, -8.357675590999969],
              [-40.872523037999898, -8.357410126999973],
              [-40.869981075999902, -8.358538706999976],
              [-40.869166667975861, -8.359756591354579],
              [-40.869166668107198, -8.35999999290533],
              [-40.869003903608906, -8.35999999290533],
              [-40.867527921, -8.362207210999937],
              [-40.866179401999901, -8.363446646999931],
              [-40.864507737999901, -8.364331682999923],
              [-40.862497634, -8.364991451999964],
              [-40.861789460999901, -8.370654578999963],
              [-40.859560496, -8.373573480999937],
              [-40.857345876999901, -8.375644477999966],
              [-40.858294983479666, -8.376666658939087],
              [-40.858333333116775, -8.376666658939087],
              [-40.858333333094485, -8.376707961201932],
              [-40.859263937, -8.377710214999977],
              [-40.859429239999905, -8.378482768999955],
              [-40.858591277, -8.379610767999967],
              [-40.856400731, -8.379910911999962],
              [-40.854011383999897, -8.379648162999962],
              [-40.853333332288067, -8.379805237473755],
              [-40.853333331867702, -8.379999993404908],
              [-40.852492618051606, -8.37999999363173],
              [-40.850212118999899, -8.380528283999981],
              [-40.845744715, -8.379214802999961],
              [-40.843901505, -8.379133760999924],
              [-40.839166666324466, -8.380585008074492],
              [-40.839166666908113, -8.38166665838952],
              [-40.83833333171782, -8.38166665928884],
              [-40.83833333171782, -8.382499992680494],
              [-40.837500001024182, -8.382499992680494],
              [-40.837500000124862, -8.383333326072091],
              [-40.83833333171782, -8.383333326072091],
              [-40.83833333261714, -8.384166657665048],
              [-40.837499999225543, -8.384166657665048],
              [-40.837500001024182, -8.386666658739273],
              [-40.83833333261714, -8.386666659638593],
              [-40.83833333261714, -8.388333326421844],
              [-40.837499997426903, -8.388333326421844],
              [-40.837499999225543, -8.391666659089026],
              [-40.83833333261714, -8.391666659089026],
              [-40.83833333261714, -8.394166659263874],
              [-40.839166667807433, -8.394166659263874],
              [-40.839166666008794, -8.394999992655471],
              [-40.839999999400391, -8.394999992655471],
              [-40.839999999400391, -8.395833326047125],
              [-40.840833332792045, -8.395833326047125],
              [-40.840833331892725, -8.396666659438722],
              [-40.841666667082961, -8.396666659438722],
              [-40.841666666183642, -8.397499992830376],
              [-40.843333333866212, -8.397499992830376],
              [-40.843333332966893, -8.399166658714307],
              [-40.844166665459227, -8.399166660512947],
              [-40.844166668157186, -8.400833325497501],
              [-40.845000000649463, -8.400833325497501],
              [-40.845000000649463, -8.401666658889155],
              [-40.845833331343101, -8.401666658889155],
              [-40.845833331343101, -8.402499993180072],
              [-40.847499999924992, -8.402499992280752],
              [-40.847499999924992, -8.403333325672406],
              [-40.848333331518006, -8.403333325672406],
              [-40.848333331518006, -8.405833326746574],
              [-40.849999999200577, -8.405833325847254],
              [-40.850000000999216, -8.406666659238908],
              [-40.850833333491494, -8.406666659238908],
              [-40.850833332592174, -8.407499993529825],
              [-40.851666665084508, -8.407499993529825],
              [-40.851666665084508, -8.408333326022159],
              [-40.850000000999216, -8.408333326022159],
              [-40.850000000999216, -8.413333326371855],
              [-40.850833331692854, -8.413333326371855],
              [-40.850833332592174, -8.41416665886419],
              [-40.851666666883148, -8.41416665886419],
              [-40.851666665983828, -8.416666659039038],
              [-40.852499999375425, -8.416666659938357],
              [-40.852500001174064, -8.417499992430635],
              [-40.853333331867702, -8.417499993329955],
              [-40.853333332767079, -8.420833325997137],
              [-40.854166666158676, -8.420833325997137],
              [-40.854166666158676, -8.422499992780388],
              [-40.855000000449593, -8.422499993679708],
              [-40.855000000449593, -8.423333326172042],
              [-40.855833333841247, -8.423333326172042],
              [-40.855833333841247, -8.424166657764999],
              [-40.857499999725178, -8.424166658664319],
              [-40.857499999725178, -8.424999992055916],
              [-40.858333334016095, -8.424999992955236],
              [-40.858333333116775, -8.428333326521738],
              [-40.857499998825858, -8.428333327421058],
              [-40.857500000624498, -8.429166658114752],
              [-40.856666665434204, -8.429166659014072],
              [-40.856666667232844, -8.434166659363768],
              [-40.857500000624498, -8.434166659363768],
              [-40.857499998825858, -8.435833326147019],
              [-40.855833332941927, -8.435833326147019],
              [-40.855833333841247, -8.436666658639353],
              [-40.854999999550273, -8.436666658639353],
              [-40.854999999550273, -8.438333327221244],
              [-40.854166666158676, -8.438333325422605],
              [-40.854166667957315, -8.439166657914882],
              [-40.853333331867702, -8.439166658814202],
              [-40.853333332767079, -8.439999993105175],
              [-40.852500001174064, -8.439999994004495],
              [-40.852499999375425, -8.440833324698133],
              [-40.851666666883148, -8.440833326496772],
              [-40.850833332592174, -8.440833326496772],
              [-40.850833332592174, -8.442499994179343],
              [-40.847499999025672, -8.442499993280023],
              [-40.847499999924992, -8.443333325772301],
              [-40.845000000649463, -8.443333324872981],
              [-40.844999998850824, -8.44166665898905],
              [-40.844166668157186, -8.44166665898905],
              [-40.844166665459227, -8.439166658814202],
              [-40.845000000649463, -8.439166659713521],
              [-40.844999999750144, -8.4358333252477],
              [-40.845833333141741, -8.435833327046339],
              [-40.845833330443782, -8.434999992755422],
              [-40.844999999750144, -8.434999992755422],
              [-40.844999999750144, -8.434166659363768],
              [-40.843333332067573, -8.434166658464449],
              [-40.843333332966893, -8.429166659014072],
              [-40.842499998675976, -8.429166659014072],
              [-40.841666666183642, -8.429166659014072],
              [-40.841666666183642, -8.430833325797323],
              [-40.840833331892725, -8.430833325797323],
              [-40.840833332792045, -8.43166665918892],
              [-40.83833333261714, -8.43166665918892],
              [-40.83833333261714, -8.430833324898003],
              [-40.837500001024182, -8.430833325797323],
              [-40.837500000124862, -8.43166665918892],
              [-40.83416666655836, -8.4316666582896],
              [-40.83416666655836, -8.432499993479894],
              [-40.832499999775109, -8.432499993479894],
              [-40.832500000674429, -8.434166659363768],
              [-40.831666665484192, -8.434166659363768],
              [-40.831666666383512, -8.436666658639353],
              [-40.830833332092539, -8.436666658639353],
              [-40.830833333891178, -8.43749999293027],
              [-40.829999998700941, -8.43749999293027],
              [-40.829999999600261, -8.438333326321924],
              [-40.823333331567937, -8.438333327221244],
              [-40.823333331567937, -8.439166658814202],
              [-40.82249999907566, -8.439166658814202],
              [-40.82249999907566, -8.439999993105175],
              [-40.819999998900812, -8.439999993105175],
              [-40.819999998900812, -8.440833326496772],
              [-40.819166666408478, -8.440833326496772],
              [-40.819166668207117, -8.44166665898905],
              [-40.8183333339162, -8.44166665898905],
              [-40.818333333016881, -8.443333325772301],
              [-40.817499999625227, -8.443333324872981],
              [-40.817499998725907, -8.444999993454871],
              [-40.81666666623363, -8.444999993454871],
              [-40.816666668032269, -8.445833325947206],
              [-40.815833331942656, -8.445833325947206],
              [-40.815833332841976, -8.446666659338803],
              [-40.815000000349698, -8.446666659338803],
              [-40.815000000349698, -8.448333326122054],
              [-40.814166666058725, -8.448333326122054],
              [-40.814166666958045, -8.449166659513651],
              [-40.813333331767808, -8.449166659513651],
              [-40.813333331767808, -8.450833327196221],
              [-40.81250000017485, -8.450833325397582],
              [-40.81250000017485, -8.451666657889916],
              [-40.81083333159296, -8.451666658789236],
              [-40.81083333159296, -8.452499993080153],
              [-40.81, -8.452499993080153],
              [-40.81, -8.453333326471807],
              [-40.809166667507668, -8.453333326471807],
              [-40.809166667507668, -8.454166658964084],
              [-40.808333333216694, -8.454166658964084],
              [-40.808333333216694, -8.456666659138989],
              [-40.806666665534124, -8.456666659138989],
              [-40.806666666433443, -8.457499994329226],
              [-40.805833333041846, -8.457499994329226],
              [-40.805833333041846, -8.456666659138989],
              [-40.804166665359276, -8.456666659138989],
              [-40.803333333766318, -8.456666659138989],
              [-40.803333332866998, -8.460833326097088],
              [-40.802500000374664, -8.460833326097088],
              [-40.802499999475344, -8.462499992880339],
              [-40.801666666083747, -8.462499992880339],
              [-40.801666666983067, -8.463333326271936],
              [-40.802499998576025, -8.463333325372616],
              [-40.802499999475344, -8.464166657864894],
              [-40.805000000549569, -8.46416665876427],
              [-40.804999999650249, -8.464999993055187],
              [-40.805833334840486, -8.464999993055187],
              [-40.805833332142527, -8.466666658939118],
              [-40.807499999825097, -8.466666658939118],
              [-40.807500001623737, -8.467499993230035],
              [-40.808333333216694, -8.467499992330715],
              [-40.808333333216694, -8.466666658939118],
              [-40.81083333159296, -8.466666658939118],
              [-40.81083333249228, -8.467499993230035],
              [-40.812499998376154, -8.467499993230035],
              [-40.81250000017485, -8.468333325722369],
              [-40.816666667132949, -8.468333327521009],
              [-40.81666666623363, -8.467499993230035],
              [-40.817499997826587, -8.467499992330715],
              [-40.817499999625227, -8.468333325722369],
              [-40.824166666758231, -8.468333325722369],
              [-40.824166666758231, -8.467499993230035],
              [-40.825000001049148, -8.467499993230035],
              [-40.824999998351188, -8.466666659838438],
              [-40.825833333541482, -8.466666658939118],
              [-40.825833332642162, -8.465833326446784],
              [-40.826666666033759, -8.465833326446784],
              [-40.826666668731718, -8.467499993230035],
              [-40.82833333281701, -8.467499993230035],
              [-40.82833333191769, -8.468333325722369],
              [-40.832499999775109, -8.468333325722369],
              [-40.83249999797647, -8.469166658214647],
              [-40.834999999950014, -8.469166659113966],
              [-40.834999999950014, -8.472499992680468],
              [-40.835833333341611, -8.472499992680468],
              [-40.835833331542972, -8.474166659463719],
              [-40.836666665833945, -8.474166659463719],
              [-40.836666667632585, -8.477499993030222],
              [-40.83833333261714, -8.477499992130902],
              [-40.83833333261714, -8.478333326421819],
              [-40.839166666008794, -8.478333326421819],
              [-40.839166666008794, -8.477499993929541],
              [-40.845000000649463, -8.477499993929541],
              [-40.844999999750144, -8.478333326421819],
              [-40.845833331343101, -8.478333327321138],
              [-40.845833333141741, -8.477499993030222],
              [-40.846666665634075, -8.477499993030222],
              [-40.846666665634075, -8.476666658739248],
              [-40.849166667607562, -8.476666658739248],
              [-40.849166665808923, -8.473333325172746],
              [-40.850000000099897, -8.473333326971442],
              [-40.849999999200577, -8.472499992680468],
              [-40.851666667782467, -8.472499992680468],
              [-40.851666666883148, -8.473333326072066],
              [-40.853333332767079, -8.473333326072066],
              [-40.853333332767079, -8.472499991781149],
              [-40.854166667057996, -8.472499992680468],
              [-40.854166667057996, -8.470833325897217],
              [-40.856666665434204, -8.470833325897217],
              [-40.856666665434204, -8.46999999340494],
              [-40.859999999000706, -8.46999999250562],
              [-40.859999999900026, -8.468333325722369],
              [-40.86083333239236, -8.468333325722369],
              [-40.860833334191, -8.467499993230035],
              [-40.862499999175554, -8.467499993230035],
              [-40.862499999175554, -8.465833326446784],
              [-40.863333333466528, -8.465833326446784],
              [-40.863333331667889, -8.46416665876427],
              [-40.864166665059486, -8.46416665876427],
              [-40.864166665958805, -8.463333326271936],
              [-40.865000001149099, -8.463333326271936],
              [-40.86499999845114, -8.461666657690046],
              [-40.86666666703303, -8.461666659488685],
              [-40.86666666703303, -8.460833326097088],
              [-40.869166668107198, -8.460833325197768],
              [-40.869166665409239, -8.456666658239612],
              [-40.868333333816281, -8.456666658239612],
              [-40.868333332017642, -8.454999994154377],
              [-40.868333332017642, -8.454166658964084],
              [-40.869166665409239, -8.454166658064764],
              [-40.869166668107198, -8.451666658789236],
              [-40.871666667382726, -8.451666657889916],
              [-40.871666666483407, -8.450833326296902],
              [-40.87250000077438, -8.450833327196221],
              [-40.87249999987506, -8.449999992905305],
              [-40.874166668456951, -8.449999993804624],
              [-40.874166666658311, -8.450833326296902],
              [-40.875000000049909, -8.450833326296902],
              [-40.874999999150589, -8.451666659688556],
              [-40.875833332542243, -8.451666658789236],
              [-40.875833330743603, -8.453333326471807],
              [-40.87666666683316, -8.453333326471807],
              [-40.87666666593384, -8.454166658964084],
              [-40.877500000224813, -8.454166658964084],
              [-40.877499997526797, -8.455833324848015],
              [-40.878333332717091, -8.455833325747335],
              [-40.878333331817771, -8.457499993429906],
              [-40.879999997701702, -8.457499993429906],
              [-40.879999997701702, -8.458333325922183],
              [-40.880833331992619, -8.458333325922183],
              [-40.880833331992619, -8.459166659313837],
              [-40.881666665384273, -8.459166659313837],
              [-40.881666667182913, -8.460833326097088],
              [-40.880833331093299, -8.460833326097088],
              [-40.880833332891939, -8.461666659488685],
              [-40.880000000399662, -8.461666659488685],
              [-40.879999998601022, -8.465833326446784],
              [-40.880833334690578, -8.465833326446784],
              [-40.880833332891939, -8.46999999250562],
              [-40.879999999500342, -8.46999999340494],
              [-40.879999998601022, -8.474166659463719],
              [-40.879166667008008, -8.474166659463719],
              [-40.879166667008008, -8.477499993030222],
              [-40.880833332891939, -8.477499993030222],
              [-40.880833331992619, -8.478333326421819],
              [-40.881666665384273, -8.478333326421819],
              [-40.881666668082232, -8.479166658914153],
              [-40.88249999877587, -8.479166658914153],
              [-40.88249999877587, -8.480833325697404],
              [-40.883333332167524, -8.480833325697404],
              [-40.883333333966164, -8.482499992480598],
              [-40.884166666458441, -8.482499994279294],
              [-40.884166665559121, -8.484166659263849],
              [-40.886666665733969, -8.484166659263849],
              [-40.889166668606833, -8.484166659263849],
              [-40.889166667707514, -8.483333325872252],
              [-40.893333333766293, -8.483333325872252],
              [-40.893333332866973, -8.484166659263849],
              [-40.897499998925753, -8.484166660163169],
              [-40.897499998925753, -8.483333325872252],
              [-40.898333333216726, -8.483333325872252],
              [-40.898333331418087, -8.482499993379918],
              [-40.899166666608323, -8.482499993379918],
              [-40.899166665709004, -8.481666659988321],
              [-40.899999999100658, -8.481666659089001],
              [-40.900000000899297, -8.480833325697404],
              [-40.901666665883909, -8.480833325697404],
              [-40.901666666783228, -8.478333326421819],
              [-40.904999998551034, -8.478333326421819],
              [-40.904999998551034, -8.477499993030222],
              [-40.905833332842008, -8.477499993030222],
              [-40.905833331942688, -8.473333326072066],
              [-40.906666667132924, -8.473333326072066],
              [-40.906666666233605, -8.474166659463719],
              [-40.909999998900787, -8.47416665766508],
              [-40.910000000699426, -8.474999992855317],
              [-40.914166665858886, -8.474999992855317],
              [-40.914166665858886, -8.475833326246971],
              [-40.915000001049179, -8.475833326246971],
              [-40.91499999835122, -8.474999992855317],
              [-40.915833332642137, -8.474999992855317],
              [-40.915833332642137, -8.474166660363039],
              [-40.918333332816985, -8.474166659463719],
              [-40.918333332816985, -8.473333326072066],
              [-40.919999999600236, -8.473333326072066],
              [-40.920000000499556, -8.474166659463719],
              [-40.92083333209257, -8.47416665766508],
              [-40.921666666383487, -8.474166660363039],
              [-40.921666665484167, -8.474999992855317],
              [-40.922499999775141, -8.474999992855317],
              [-40.922499999775141, -8.477499993030222],
              [-40.923333331368099, -8.477499993030222],
              [-40.923333334066058, -8.479166658914153],
              [-40.922499999775141, -8.479166658914153],
              [-40.922499999775141, -8.480833325697404],
              [-40.923333334066058, -8.480833325697404],
              [-40.923333331368099, -8.481666659089001],
              [-40.924166667457712, -8.481666659089001],
              [-40.924166667457712, -8.482499993379918],
              [-40.924999999949989, -8.482499993379918],
              [-40.924999999050669, -8.484166660163169],
              [-40.925833332442323, -8.484166659263849],
              [-40.925833332442323, -8.4858333260471],
              [-40.92666666763256, -8.4858333260471],
              [-40.92666666583392, -8.486666659438754],
              [-40.929166666008769, -8.486666659438754],
              [-40.929166667807408, -8.487499992830351],
              [-40.930000001199062, -8.487499993729671],
              [-40.930000001199062, -8.488333326222005],
              [-40.930833334590659, -8.488333326222005],
              [-40.93083333279202, -8.489166658714282],
              [-40.932499998675951, -8.489166658714282],
              [-40.932499999575271, -8.489999993005199],
              [-40.933333332067605, -8.489999993005199],
              [-40.933333332067605, -8.490833325497533],
              [-40.934166665459202, -8.490833326396853],
              [-40.934166666358522, -8.492499993180104],
              [-40.934999999750119, -8.492499993180104],
              [-40.934999998850799, -8.494166659064035],
              [-40.935833333141773, -8.494166659064035],
              [-40.935833334041092, -8.494999993354952],
              [-40.93666666653337, -8.494999993354952],
              [-40.93666666653337, -8.495833325847286],
              [-40.937499999025704, -8.495833325847286],
              [-40.937499999925024, -8.496666659238883],
              [-40.943333332767054, -8.496666659238883],
              [-40.943333331867734, -8.499166659413731],
              [-40.944166667057971, -8.499166659413731],
              [-40.944166666158651, -8.499999992805385],
              [-40.950833331493016, -8.499999992805385],
              [-40.950833331493016, -8.503333326371887],
              [-40.94416666795729, -8.503333326371887],
              [-40.944166667057971, -8.502499992080914],
              [-40.943333332767054, -8.502499992980233],
              [-40.943333331867734, -8.503333326371887],
              [-40.941666665983803, -8.503333327271207],
              [-40.941666665983803, -8.504166658864165],
              [-40.940833334390845, -8.504166658864165],
              [-40.940833332592206, -8.509166659213918],
              [-40.939999999200552, -8.509166659213918],
              [-40.939999999200552, -8.511666659388766],
              [-40.940833333491526, -8.511666658489446],
              [-40.940833331692886, -8.516666658839199],
              [-40.9424999993754, -8.516666658839199],
              [-40.94249999847608, -8.517499993130116],
              [-40.943333333666374, -8.517499992230796],
              [-40.94416666795729, -8.517499993130116],
              [-40.944166667057971, -8.51833332652177],
              [-40.945833333841222, -8.51833332652177],
              [-40.945833332941902, -8.519166658114727],
              [-40.947500000624473, -8.519166659014047],
              [-40.947500001523792, -8.519999993305021],
              [-40.950833331493016, -8.519999993305021],
              [-40.950833334190975, -8.520833325797298],
              [-40.952499999175586, -8.520833325797298],
              [-40.952500000074906, -8.521666659188952],
              [-40.955833332742088, -8.521666660088272],
              [-40.955833332742088, -8.520833325797298],
              [-40.961666665584119, -8.520833325797298],
              [-40.961666665584119, -8.519999993305021],
              [-40.962500000774355, -8.519999993305021],
              [-40.963333334166009, -8.519999993305021],
              [-40.96333333146805, -8.520833325797298],
              [-40.964166665758967, -8.520833324897978],
              [-40.964166666658286, -8.521666659188952],
              [-40.967500000224788, -8.521666659188952],
              [-40.967499998426149, -8.522499994379189],
              [-40.969999999500317, -8.522499993479869],
              [-40.969999998600997, -8.521666659188952],
              [-40.974166666458473, -8.521666659188952],
              [-40.974166665559153, -8.519999993305021],
              [-40.97499999985007, -8.519999994204341],
              [-40.97499999895075, -8.519166659014047],
              [-40.975833331443027, -8.519166659014047],
              [-40.975833331443027, -8.51833332652177],
              [-40.979166665908849, -8.51833332652177],
              [-40.979166666808169, -8.517499993130116],
              [-40.98083333359142, -8.517499993130116],
              [-40.98083333179278, -8.514999992955268],
              [-40.981666666083754, -8.514999992055948],
              [-40.981666666083754, -8.514166658664294],
              [-40.985000000549519, -8.51416666316095],
              [-40.984999999650199, -8.514999993854588],
              [-40.985833333941173, -8.514999992955268],
              [-40.985833333041853, -8.516666657939879],
              [-40.986666665534131, -8.516666658839199],
              [-40.98666666643345, -8.517499993130116],
              [-40.994999999450386, -8.517499993130116],
              [-40.994999998551066, -8.516666658839199],
              [-40.995833332841983, -8.516666658839199],
              [-40.995833332841983, -8.517499993130116],
              [-40.996666666233637, -8.517499992230796],
              [-40.996666667132956, -8.516666658839199],
              [-40.997499998725914, -8.516666658839199],
              [-40.997499999625234, -8.517499993130116],
              [-40.998333333916207, -8.517499993130116],
              [-40.998333333016888, -8.51833332652177],
              [-40.999999998900762, -8.51833332652177],
              [-40.999999999800082, -8.519166659014047],
              [-41.002499999974987, -8.519166659913367],
              [-41.002499999075667, -8.51833332652177],
              [-41.003333331567944, -8.51833332652177],
              [-41.003333331567944, -8.517499992230796],
              [-41.004166667657557, -8.517499993130116],
              [-41.004166666758238, -8.516666658839199],
              [-41.004999998351195, -8.516666658839199],
              [-41.004999998351195, -8.515833326346865],
              [-41.005833332642169, -8.515833326346865],
              [-41.005833333541489, -8.514999992955268],
              [-41.006666668731725, -8.514999992955268],
              [-41.006666666933086, -8.514166658664294],
              [-41.009166667107934, -8.514166657764974],
              [-41.009166664409975, -8.51833332652177],
              [-41.009999998700948, -8.51833332652177],
              [-41.009999999600268, -8.521666659188952],
              [-41.010833332092545, -8.521666659188952],
              [-41.010833333891185, -8.5241666593638],
              [-41.010833332991865, -8.524999992755397],
              [-41.009166666208614, -8.524999992755397],
              [-41.009166667107934, -8.525833326147051],
              [-41.008333332817017, -8.525833326147051],
              [-41.008333332817017, -8.526666658639328],
              [-41.005833331742849, -8.526666657740009],
              [-41.005833331742849, -8.525833327046371],
              [-41.002499999075667, -8.525833326147051],
              [-41.002499999974987, -8.524999992755397],
              [-41.001666666583333, -8.524999992755397],
              [-41.001666665684013, -8.525833326147051],
              [-41.000833333191736, -8.525833326147051],
              [-40.999166668207124, -8.525833326147051],
              [-40.999166665509165, -8.526666658639328],
              [-40.997500000524553, -8.526666658639328],
              [-40.997500000524553, -8.527499992930302],
              [-40.995000000349705, -8.527499992930302],
              [-40.994999998551066, -8.528333326321899],
              [-40.994166666058732, -8.528333326321899],
              [-40.994166666958051, -8.529166658814233],
              [-40.993333333566454, -8.529166659713553],
              [-40.993333331767815, -8.52999999310515],
              [-40.99250000107412, -8.52999999310515],
              [-40.992499997476841, -8.530833326496747],
              [-40.991666666783203, -8.530833326496747],
              [-40.991666665883884, -8.531666658989081],
              [-40.990833333391606, -8.531666658989081],
              [-40.99083333159291, -8.533333325772333],
              [-40.99, -8.533333325772333],
              [-40.99, -8.53416665916393],
              [-40.989166666608355, -8.53416665916393],
              [-40.989166665709035, -8.538333326122029],
              [-40.989999999100633, -8.538333326122029],
              [-40.990000000899272, -8.539166659513683],
              [-40.99083333159291, -8.539166657715043],
              [-40.990833333391606, -8.53999999290528],
              [-40.989999999100633, -8.53999999200596],
              [-40.990000000899272, -8.541666658789211],
              [-40.99083333249223, -8.541666658789211],
              [-40.990833333391606, -8.542499993080185],
              [-40.991666666783203, -8.542499993080185],
              [-40.991666666783203, -8.543333327371101],
              [-40.992499998376161, -8.543333326471782],
              [-40.992499998376161, -8.547499993429881],
              [-40.993333332667135, -8.547499993429881],
              [-40.993333332667135, -8.549999995403425],
              [-40.994166666958051, -8.549999992705466],
              [-40.994166666058732, -8.550833326097063],
              [-40.999166665509165, -8.550833326097063],
              [-40.999166668207124, -8.549999992705466],
              [-40.999999998900762, -8.549999992705466],
              [-40.999999999800082, -8.550833326097063],
              [-41.001666666583333, -8.550833326097063],
              [-41.001666666583333, -8.551666657690077],
              [-41.002500000874306, -8.551666657690077],
              [-41.002499999075667, -8.552499992880314],
              [-41.007499998526043, -8.552499992880314],
              [-41.007500001224059, -8.553333326271968],
              [-41.009999999600268, -8.553333326271968],
              [-41.009999998700948, -8.554166657864926],
              [-41.010833333891185, -8.554166658764245],
              [-41.010833332092545, -8.554999993055162],
              [-41.012500000674436, -8.554999993055162],
              [-41.012499998875796, -8.555833326446816],
              [-41.015833330643659, -8.555833326446816],
              [-41.015833332442298, -8.554999993055162],
              [-41.016666665833895, -8.554999993055162],
              [-41.016666666733215, -8.555833326446816],
              [-41.017500001024189, -8.555833326446816],
              [-41.017499999225549, -8.556666658939093],
              [-41.020000001199037, -8.556666658939093],
              [-41.019999999400397, -8.557499993230067],
              [-41.020833334590691, -8.557499993230067],
              [-41.020833331892732, -8.558333325722344],
              [-41.021666666183648, -8.558333325722344],
              [-41.021666667082968, -8.559999993404915],
              [-41.023333333866219, -8.559999993404915],
              [-41.02333333206758, -8.564166659463694],
              [-41.025833333141748, -8.564166659463694],
              [-41.025833334940387, -8.564999992855348],
              [-41.026666665634082, -8.564999991956029],
              [-41.026666666533401, -8.565833326246945],
              [-41.02916666580893, -8.565833327146265],
              [-41.02916666580893, -8.56666665783996],
              [-41.029999999200584, -8.56666665873928],
              [-41.030000000099903, -8.56833332642185],
              [-41.02916666670825, -8.56833332642185],
              [-41.029166667607569, -8.569999994104421],
              [-41.028333331518013, -8.569999993205101],
              [-41.028333331518013, -8.570833325697379],
              [-41.027499999924999, -8.570833325697379],
              [-41.027499999025679, -8.575833326047132],
              [-41.029166667607569, -8.575833326047132],
              [-41.02916666670825, -8.576666659438729],
              [-41.030000000099903, -8.576666659438729],
              [-41.029999999200584, -8.577499992830383],
              [-41.02916666580893, -8.577499992830383],
              [-41.02916666580893, -8.57833332532266],
              [-41.030000000099903, -8.57833332622198],
              [-41.030000000999223, -8.584999993354984],
              [-41.030000000099903, -8.585833325847261],
              [-41.031666665983778, -8.585833325847261],
              [-41.031666667782474, -8.586666660138235],
              [-41.032499998476112, -8.586666659238858],
              [-41.032499998476112, -8.587499993529832],
              [-41.033333333666349, -8.587499993529832],
              [-41.033333332767029, -8.589166659413763],
              [-41.034166667058003, -8.589166658514443],
              [-41.034166667058003, -8.58999999280536],
              [-41.033333333666349, -8.58999999280536],
              [-41.033333332767029, -8.590833326197014],
              [-41.032499999375432, -8.590833326197014],
              [-41.032499998476112, -8.591666658689292],
              [-41.031666666883154, -8.591666657789972],
              [-41.031666666883154, -8.592499992980265],
              [-41.030833331692861, -8.592499992980265],
              [-41.030833331692861, -8.593333326371862],
              [-41.029999999200584, -8.593333326371862],
              [-41.029999999200584, -8.594166658864197],
              [-41.030833333491501, -8.594166659763516],
              [-41.030833331692861, -8.594999993155113],
              [-41.031666667782474, -8.594999993155113],
              [-41.031666667782474, -8.596666659039045],
              [-41.032499999375432, -8.596666658139725],
              [-41.032500002073391, -8.597499993329961],
              [-41.033333331867709, -8.597499993329961],
              [-41.033333333666349, -8.598333325822296],
              [-41.035000001348919, -8.598333325822296],
              [-41.0350000004496, -8.599166659213893],
              [-41.035833333841254, -8.599166658314573],
              [-41.035833333841254, -8.602499991881075],
              [-41.036666667232851, -8.602499992780395],
              [-41.036666667232851, -8.604166658664326],
              [-41.038333332217462, -8.604166658664326],
              [-41.038333333116782, -8.605833326346897],
              [-41.039166666508436, -8.605833326346897],
              [-41.039166666508436, -8.606666658839174],
              [-41.039999999900033, -8.606666658839174],
              [-41.039999999000713, -8.609999993304996],
              [-41.04083333239231, -8.609999992405676],
              [-41.04083333239231, -8.610833325797273],
              [-41.041666666683284, -8.610833325797273],
              [-41.041666667582604, -8.611666659188927],
              [-41.042500000074881, -8.611666659188927],
              [-41.042499999175561, -8.614166660263095],
              [-41.043333332567215, -8.614166659363775],
              [-41.043333332567215, -8.613333326871498],
              [-41.044166667757452, -8.613333325972178],
              [-41.044166665059493, -8.612499993479844],
              [-41.049999997901523, -8.612499993479844],
              [-41.049999998800843, -8.613333325972178],
              [-41.051666665584094, -8.613333325972178],
              [-41.051666665584094, -8.614166659363775],
              [-41.052499998975748, -8.614166659363775],
              [-41.052499999875067, -8.614999992755429],
              [-41.053333331468025, -8.614999992755429],
              [-41.053333332367345, -8.615833326147026],
              [-41.055000000949235, -8.615833326147026],
              [-41.055000000049915, -8.614999993654749],
              [-41.056666666833166, -8.614999993654749],
              [-41.056666666833166, -8.613333325072858],
              [-41.057500000224763, -8.613333325972178],
              [-41.057500001124083, -8.612499994379164],
              [-41.058333332717098, -8.612499993479844],
              [-41.058333333616417, -8.611666659188927],
              [-41.059166667907334, -8.611666659188927],
              [-41.059166667907334, -8.610833325797273],
              [-41.060000002198308, -8.610833325797273],
              [-41.060000000399668, -8.609166659014079],
              [-41.060833332891946, -8.609166659014079],
              [-41.060833333791265, -8.608333326521745],
              [-41.061666667182919, -8.608333327421064],
              [-41.061666668082239, -8.607499993130148],
              [-41.06333333396617, -8.607499993130148],
              [-41.063333333066851, -8.606666658839174],
              [-41.064166666458448, -8.606666658839174],
              [-41.064166666458448, -8.604999992955243],
              [-41.064999998950725, -8.604999992955243],
              [-41.064999998051405, -8.603333325272672],
              [-41.065833332342379, -8.603333327071368],
              [-41.065833332342379, -8.601666659388798],
              [-41.06750000002495, -8.601666659388798],
              [-41.06750000002495, -8.600833325997144],
              [-41.06916666770752, -8.600833325997144],
              [-41.069166665908881, -8.601666659388798],
              [-41.071666666083729, -8.601666659388798],
              [-41.071666666083729, -8.604166658664326],
              [-41.072499998576006, -8.604166658664326],
              [-41.072499999475326, -8.604999992955243],
              [-41.071666666083729, -8.604999992955243],
              [-41.071666666983049, -8.606666659738494],
              [-41.070833332692132, -8.606666659738494],
              [-41.070833333591452, -8.609999993304996],
              [-41.070000000199798, -8.609999994204315],
              [-41.069999998401158, -8.612499993479844],
              [-41.06916666770752, -8.612499993479844],
              [-41.069166665908881, -8.614166659363775],
              [-41.069999998401158, -8.614166659363775],
              [-41.070000000199798, -8.614999992755429],
              [-41.070833332692132, -8.614999992755429],
              [-41.070833332692132, -8.615833326147026],
              [-41.071666667882369, -8.615833325247706],
              [-41.071666667882369, -8.61666665863936],
              [-41.070833331792812, -8.61666665863936],
              [-41.070833333591452, -8.618333327221251],
              [-41.069166665908881, -8.618333325422611],
              [-41.069166665908881, -8.619166658814208],
              [-41.06749999912563, -8.619166658814208],
              [-41.06750000002495, -8.619999994004445],
              [-41.066666666633296, -8.619999993105125],
              [-41.066666666633296, -8.620833326496779],
              [-41.065833332342379, -8.620833326496779],
              [-41.065833334141018, -8.621666658989056],
              [-41.065000000749365, -8.621666659888376],
              [-41.064999998950725, -8.624166659163961],
              [-41.064166665559128, -8.624166659163961],
              [-41.064166666458448, -8.624999993454878],
              [-41.063333333066851, -8.624999993454878],
              [-41.063333333066851, -8.630833326296909],
              [-41.062499999675197, -8.630833327196228],
              [-41.062499999675197, -8.631666659688563],
              [-41.063333333066851, -8.631666659688563],
              [-41.063333332167531, -8.633333326471814],
              [-41.064166666458448, -8.633333326471814],
              [-41.064166666458448, -8.635833325747342],
              [-41.064999998950725, -8.635833325747342],
              [-41.064999998950725, -8.636666659138939],
              [-41.065833334141018, -8.636666659138939],
              [-41.065833331443059, -8.639999992705441],
              [-41.068333332517227, -8.63999999450408],
              [-41.068333330718588, -8.641666659488692],
              [-41.069166666808201, -8.641666659488692],
              [-41.06916666770752, -8.644999993055194],
              [-41.070833331792812, -8.644999993055194],
              [-41.070833333591452, -8.645833326446791],
              [-41.071666666983049, -8.645833326446791],
              [-41.071666666083729, -8.647499993230042],
              [-41.072500000374703, -8.647499993230042],
              [-41.072499999475326, -8.649166659113973],
              [-41.0733333337663, -8.649166659113973],
              [-41.07333333196766, -8.649999993404947],
              [-41.072499998576006, -8.649999993404947],
              [-41.072499998576006, -8.650833325897224],
              [-41.07333333196766, -8.650833325897224],
              [-41.07333333196766, -8.659999993205076],
              [-41.072499999475326, -8.659999993205076],
              [-41.072499999475326, -8.660833325697354],
              [-41.071666666083729, -8.660833327495993],
              [-41.071666666083729, -8.661666659988327],
              [-41.070833332692132, -8.661666659089008],
              [-41.070833332692132, -8.662499993379924],
              [-41.070833332692132, -8.66499999265551],
              [-41.071666666083729, -8.66499999265551],
              [-41.071666666983049, -8.666666659438761],
              [-41.074166667157897, -8.666666659438761],
              [-41.074166667157897, -8.667499992830358],
              [-41.072499997676687, -8.667499992830358],
              [-41.072499998576006, -8.67083332639686],
              [-41.071666666083729, -8.67083332639686],
              [-41.071666667882369, -8.673333325672388],
              [-41.070833332692132, -8.673333324773068],
              [-41.070833332692132, -8.674166659064042],
              [-41.070000000199798, -8.674166659064042],
              [-41.070000001099118, -8.67666665923889],
              [-41.069999998401158, -8.677499993529807],
              [-41.070833332692132, -8.677499991731167],
              [-41.070833331792812, -8.679166659413738],
              [-41.072499999475326, -8.679166659413738],
              [-41.072499999475326, -8.679999992805392],
              [-41.07333333286698, -8.679999992805392],
              [-41.0733333337663, -8.680833326196989],
              [-41.074999998750911, -8.680833326196989],
              [-41.074999999650231, -8.681666658689323],
              [-41.075833333941148, -8.681666658689323],
              [-41.075833333941148, -8.683333326371894],
              [-41.076666666433482, -8.683333326371894],
              [-41.076666666433482, -8.684166659763491],
              [-41.078333333216733, -8.684166658864171],
              [-41.078333333216733, -8.684999993155088],
              [-41.07916666660833, -8.684999993155088],
              [-41.07916666750765, -8.685833325647422],
              [-41.08, -8.685833325647422],
              [-41.079999999100608, -8.687499993329993],
              [-41.080833331592942, -8.687499993329993],
              [-41.080833333391581, -8.690833326896495],
              [-41.081666666783178, -8.690833325997176],
              [-41.081666664984539, -8.691666659388773],
              [-41.082500001074152, -8.691666659388773],
              [-41.082500000174832, -8.69249999188105],
              [-41.084166666958083, -8.69249999278037],
              [-41.084166666958083, -8.693333326172024],
              [-41.08500000034968, -8.693333327071343],
              [-41.084999998551041, -8.69416666046294],
              [-41.087499999625265, -8.694166658664301],
              [-41.087499998725946, -8.693333326172024],
              [-41.091666667482684, -8.693333326172024],
              [-41.091666665684045, -8.694166658664301],
              [-41.092499999974962, -8.694166658664301],
              [-41.092499999075642, -8.694999992955275],
              [-41.095000000149867, -8.694999992955275],
              [-41.094999998351227, -8.694166657764981],
              [-41.096666667832437, -8.694166658664301],
              [-41.096666666933118, -8.694999992955275],
              [-41.097499999425395, -8.694999992955275],
              [-41.097500001224034, -8.695833326346872],
              [-41.100833332092577, -8.695833326346872],
              [-41.100833332991897, -8.696666658839206],
              [-41.102499999775148, -8.696666659738526],
              [-41.103333334066065, -8.696666658839206],
              [-41.103333334066065, -8.697499993130123],
              [-41.104999999050676, -8.697499992230803],
              [-41.104999999949996, -8.698333326521777],
              [-41.106666665833927, -8.698333326521777],
              [-41.106666665833927, -8.699166659014054],
              [-41.107500001024164, -8.699166659014054],
              [-41.107499999225524, -8.699999993305028],
              [-41.108333332617178, -8.699999993305028],
              [-41.108333331717859, -8.700833325797305],
              [-41.109166666008775, -8.700833325797305],
              [-41.109166666008775, -8.701666659188902],
              [-41.110833333691346, -8.701666658289582],
              [-41.110833331892707, -8.703333325972153],
              [-41.111666667083, -8.703333325972153],
              [-41.11166666618368, -8.704999993654724],
              [-41.112057286882823, -8.704999993233173],
              [-41.112757959, -8.703974089999962],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '127',
        OBJECTID: 172.0,
        Nome_area: 'Lajedo/Cachoeirinha',
        COD_area: 'CA179',
        Import_bio: 'Extremamente Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Sem ações recomendadas pela Oficina de Seleção',
        Area_ha: 6245.63552,
        Shape_Leng: 0.64333,
        Shape_Area: 0.00513,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.26833333221748, -8.505833324748096],
              [-36.266666665434229, -8.505833325647416],
              [-36.264999998650978, -8.505833325647416],
              [-36.264999998650978, -8.507499993329986],
              [-36.264166666158701, -8.507499993329986],
              [-36.26416666795734, -8.508333325822264],
              [-36.263333333666367, -8.508333325822264],
              [-36.263333332767047, -8.509999993504834],
              [-36.264166666158701, -8.509999993504834],
              [-36.264166667058021, -8.511666659388766],
              [-36.267499999725146, -8.511666659388766],
              [-36.267500000624466, -8.513333326172017],
              [-36.2683333331168, -8.513333326172017],
              [-36.26833333221748, -8.514166658664294],
              [-36.267499999725146, -8.514166658664294],
              [-36.267500000624466, -8.514999992955268],
              [-36.269166666508397, -8.514999992955268],
              [-36.269166666508397, -8.515833326346865],
              [-36.270833331493009, -8.515833326346865],
              [-36.270833331493009, -8.516666658839199],
              [-36.271666665783982, -8.516666658839199],
              [-36.271666666683302, -8.519166659014047],
              [-36.272499999175579, -8.519166659014047],
              [-36.272500000074899, -8.519999993305021],
              [-36.273333332567177, -8.519999993305021],
              [-36.273333332567177, -8.521666659188952],
              [-36.274999999350428, -8.521666659188952],
              [-36.275000001149067, -8.522499994379189],
              [-36.275833332742081, -8.522499993479869],
              [-36.275833331842762, -8.523333325972146],
              [-36.276666667932318, -8.523333325972146],
              [-36.276666666133679, -8.5241666593638],
              [-36.27833333201761, -8.5241666593638],
              [-36.27833333291693, -8.527499992930302],
              [-36.279999998800861, -8.527499993829622],
              [-36.2800000005995, -8.528333326321899],
              [-36.281666665584112, -8.528333326321899],
              [-36.281666667382751, -8.527499992930302],
              [-36.284166667557599, -8.527499993829622],
              [-36.28416666665828, -8.528333325422579],
              [-36.284999997351974, -8.528333327221219],
              [-36.284999999150614, -8.52999999220583],
              [-36.285833331642891, -8.52999999310515],
              [-36.285833331642891, -8.530833326496747],
              [-36.286666665933865, -8.530833326496747],
              [-36.286666665933865, -8.533333325772333],
              [-36.287499998426142, -8.533333325772333],
              [-36.287500000224782, -8.53416665916393],
              [-36.288333332717116, -8.53416665916393],
              [-36.288333331817739, -8.535833325947181],
              [-36.29000000039963, -8.535833325947181],
              [-36.28999999950031, -8.537499992730432],
              [-36.290833331093324, -8.537499992730432],
              [-36.290833331992644, -8.539166659513683],
              [-36.291666665384241, -8.539166657715043],
              [-36.291666665384241, -8.53999999200596],
              [-36.292499999675215, -8.53999999200596],
              [-36.292499999675215, -8.541666658789211],
              [-36.293333333066812, -8.541666658789211],
              [-36.293333333966132, -8.546666659138964],
              [-36.294166665559146, -8.546666659138964],
              [-36.294166666458466, -8.547499993429881],
              [-36.295833332342397, -8.547499993429881],
              [-36.295833334141037, -8.548333325922215],
              [-36.296666665733994, -8.548333325022895],
              [-36.296666666633314, -8.551666659488717],
              [-36.297500000024911, -8.551666659488717],
              [-36.297499998226272, -8.554166658764245],
              [-36.296666665733994, -8.554166658764245],
              [-36.296666667532634, -8.553333326271968],
              [-36.295833331443021, -8.553333326271968],
              [-36.295833334141037, -8.554166658764245],
              [-36.292499998775895, -8.554166658764245],
              [-36.292500000574535, -8.553333326271968],
              [-36.290833333791284, -8.553333326271968],
              [-36.290833333791284, -8.552499993779634],
              [-36.28999999950031, -8.552499993779634],
              [-36.28999999950031, -8.550833326097063],
              [-36.288333332717116, -8.550833326097063],
              [-36.288333334515755, -8.551666659488717],
              [-36.282499998975709, -8.551666659488717],
              [-36.282499999875029, -8.549999992705466],
              [-36.280833333991154, -8.549999992705466],
              [-36.280833333091778, -8.549166659313812],
              [-36.27833333291693, -8.549166659313812],
              [-36.27833333291693, -8.550833326097063],
              [-36.277500000424652, -8.550833326097063],
              [-36.277499998626013, -8.551666659488717],
              [-36.276666664335039, -8.551666659488717],
              [-36.276666667032998, -8.554166658764245],
              [-36.275833332742081, -8.554166659663565],
              [-36.275833333641401, -8.555833326446816],
              [-36.276666666133679, -8.555833324648177],
              [-36.276666667032998, -8.559166660013318],
              [-36.277499998626013, -8.559166659113998],
              [-36.277500000424652, -8.559999993404915],
              [-36.278333334715569, -8.559999994304235],
              [-36.278333333816249, -8.560833325897249],
              [-36.279166668107223, -8.560833326796569],
              [-36.279166668107223, -8.563333326072097],
              [-36.279999998800861, -8.563333326072097],
              [-36.279999998800861, -8.564166659463694],
              [-36.2800000005995, -8.565833326246945],
              [-36.280833333991154, -8.565833326246945],
              [-36.280833333091778, -8.568333325522531],
              [-36.281666665584112, -8.56833332642185],
              [-36.281666666483432, -8.570833325697379],
              [-36.282499998975709, -8.570833325697379],
              [-36.282499999875029, -8.571666659088976],
              [-36.283333332367363, -8.571666659088976],
              [-36.283333331468043, -8.57249999337995],
              [-36.28416666665828, -8.57249999337995],
              [-36.28416666665828, -8.574999991756158],
              [-36.285833331642891, -8.574999992655478],
              [-36.285833332542211, -8.576666659438729],
              [-36.286666665933865, -8.576666660338049],
              [-36.286666666833185, -8.577499992830383],
              [-36.287500000224782, -8.577499993729703],
              [-36.287500001124101, -8.580833326396828],
              [-36.286666665933865, -8.580833327296148],
              [-36.286666666833185, -8.582499993180079],
              [-36.287500000224782, -8.582499992280759],
              [-36.287499998426142, -8.583333325672413],
              [-36.289166667008033, -8.583333327471053],
              [-36.289166665209393, -8.584999993354984],
              [-36.28999999950031, -8.584999993354984],
              [-36.28999999950031, -8.585833325847261],
              [-36.290833333791284, -8.585833324947941],
              [-36.290833331992644, -8.586666659238858],
              [-36.28999999860099, -8.586666659238858],
              [-36.28999999950031, -8.587499993529832],
              [-36.289166667907352, -8.587499991731192],
              [-36.289166666108713, -8.589166658514443],
              [-36.28999999950031, -8.589166659413763],
              [-36.28999999860099, -8.593333326371862],
              [-36.290833331992644, -8.593333325472543],
              [-36.290833333791284, -8.594166658864197],
              [-36.2916666680822, -8.594166658864197],
              [-36.291666667182881, -8.595833325647391],
              [-36.292499999675215, -8.595833325647391],
              [-36.292499998775895, -8.597499993329961],
              [-36.290833332891964, -8.597499993329961],
              [-36.290833331093324, -8.606666658839174],
              [-36.292499999675215, -8.606666658839174],
              [-36.292499998775895, -8.607499993130148],
              [-36.293333333966132, -8.607499993130148],
              [-36.293333333966132, -8.608333324723105],
              [-36.294166665559146, -8.608333324723105],
              [-36.294166666458466, -8.609166659014079],
              [-36.294999998950743, -8.609166659014079],
              [-36.295000000749383, -8.609999993304996],
              [-36.295833334141037, -8.609999993304996],
              [-36.295833332342397, -8.610833325797273],
              [-36.296666666633314, -8.610833324897953],
              [-36.296666666633314, -8.611666659188927],
              [-36.297499999125591, -8.611666658289607],
              [-36.297500000924288, -8.612499993479844],
              [-36.298333332517245, -8.612499993479844],
              [-36.298333331617926, -8.613333325972178],
              [-36.299999998401177, -8.613333325972178],
              [-36.300000001099136, -8.615833326147026],
              [-36.300833332692093, -8.615833326147026],
              [-36.300833332692093, -8.61666665863936],
              [-36.301666666983067, -8.61666665863936],
              [-36.301666666083747, -8.617499992930277],
              [-36.302499999475344, -8.617499992930277],
              [-36.302499998576025, -8.618333326321931],
              [-36.304999998750873, -8.618333326321931],
              [-36.304999999650192, -8.619166658814208],
              [-36.305833333041846, -8.619166659713528],
              [-36.305833332142527, -8.619999992205805],
              [-36.306666665534124, -8.619999993105125],
              [-36.306666666433443, -8.62083332469814],
              [-36.310000000899265, -8.620833326496779],
              [-36.309999999100626, -8.62249999328003],
              [-36.31250000107417, -8.62249999328003],
              [-36.31250000107417, -8.624166659163961],
              [-36.314999998551059, -8.624166660063281],
              [-36.314999999450379, -8.624999991656239],
              [-36.315833332841976, -8.624999994354198],
              [-36.315833333741296, -8.627499994529103],
              [-36.31666666623363, -8.627499992730407],
              [-36.316666667132949, -8.62833332612206],
              [-36.317499999625227, -8.62833332612206],
              [-36.317499999625227, -8.629166659513658],
              [-36.316666667132949, -8.629166660412977],
              [-36.316666668032269, -8.629999992905312],
              [-36.315833332841976, -8.629999992905312],
              [-36.315833331942656, -8.631666658789243],
              [-36.317499999625227, -8.631666658789243],
              [-36.317499998725907, -8.63249999308016],
              [-36.318333331218241, -8.63249999308016],
              [-36.3183333339162, -8.633333326471814],
              [-36.319166665509158, -8.633333326471814],
              [-36.319166666408478, -8.636666659138939],
              [-36.319999998900812, -8.636666659138939],
              [-36.319999999800132, -8.642499992880346],
              [-36.319166665509158, -8.642499992880346],
              [-36.319166666408478, -8.645833326446791],
              [-36.3183333339162, -8.645833326446791],
              [-36.318333333016881, -8.646666659838445],
              [-36.319999998900812, -8.646666658939125],
              [-36.319999998900812, -8.647499993230042],
              [-36.320833331393089, -8.647499993230042],
              [-36.320833334091049, -8.648333325722376],
              [-36.323333331567937, -8.648333324823057],
              [-36.323333332467257, -8.649166659113973],
              [-36.324166666758231, -8.649166659113973],
              [-36.324166665858911, -8.649999992505627],
              [-36.324999998351188, -8.649999992505627],
              [-36.325000001049148, -8.651666659288821],
              [-36.325833331742842, -8.651666658389502],
              [-36.325833331742842, -8.653333326072072],
              [-36.326666666933079, -8.653333326072072],
              [-36.326666666933079, -8.654166659463726],
              [-36.327499998526093, -8.654166659463726],
              [-36.327500001224053, -8.654999993754643],
              [-36.329999999600261, -8.654999991956004],
              [-36.329999999600261, -8.656666658739255],
              [-36.330833333891178, -8.656666658739255],
              [-36.330833332092539, -8.657499993030228],
              [-36.332499999775109, -8.657499992130909],
              [-36.332500000674429, -8.658333326421825],
              [-36.333333331368124, -8.658333326421825],
              [-36.333333334066083, -8.659999993205076],
              [-36.33416666655836, -8.659999993205076],
              [-36.33416666745768, -8.661666659089008],
              [-36.335833331542972, -8.661666658189688],
              [-36.335833335140251, -8.662499993379924],
              [-36.336666666733265, -8.662499993379924],
              [-36.336666666733265, -8.663333325872259],
              [-36.337499999225543, -8.663333325872259],
              [-36.337500000124862, -8.664166659263856],
              [-36.339166666008794, -8.664166658364536],
              [-36.339166667807433, -8.661666659089008],
              [-36.339999999400391, -8.661666659089008],
              [-36.339999998501071, -8.659999994104396],
              [-36.340833331892725, -8.659999993205076],
              [-36.340833331892725, -8.657499993030228],
              [-36.339999999400391, -8.657499993030228],
              [-36.339999997601751, -8.654166659463726],
              [-36.339166667807433, -8.654166659463726],
              [-36.339166666908113, -8.652499992680475],
              [-36.34000000119903, -8.652499994479115],
              [-36.339999998501071, -8.649999992505627],
              [-36.339166666908113, -8.649999993404947],
              [-36.339166666008794, -8.648333324823057],
              [-36.34000000119903, -8.648333327521016],
              [-36.339999998501071, -8.646666658939125],
              [-36.341666667082961, -8.646666658939125],
              [-36.341666666183642, -8.64416665876422],
              [-36.340833333691364, -8.64416665876422],
              [-36.340833333691364, -8.641666657690052],
              [-36.341666666183642, -8.641666659488692],
              [-36.341666666183642, -8.640833326097095],
              [-36.342500000474615, -8.640833326097095],
              [-36.342499999575296, -8.63833332682151],
              [-36.343333333866212, -8.63833332592219],
              [-36.343333332966893, -8.631666659688563],
              [-36.342499998675976, -8.631666657889923],
              [-36.342499998675976, -8.630833326296909],
              [-36.341666667982281, -8.630833325397589],
              [-36.341666667082961, -8.629999992905312],
              [-36.340833331892725, -8.629999992005992],
              [-36.340833332792045, -8.629166659513658],
              [-36.339999999400391, -8.629166657715018],
              [-36.339999999400391, -8.62833332612206],
              [-36.339166666908113, -8.62833332612206],
              [-36.339166666908113, -8.626666659338809],
              [-36.33833333261714, -8.626666659338809],
              [-36.338333333516459, -8.625833325947212],
              [-36.337499999225543, -8.625833325947212],
              [-36.337500000124862, -8.624999993454878],
              [-36.336666665833945, -8.624999994354198],
              [-36.336666666733265, -8.623333325772307],
              [-36.335833332442292, -8.623333325772307],
              [-36.335833331542972, -8.62249999238071],
              [-36.334999999050694, -8.62249999328003],
              [-36.334999999950014, -8.621666658989056],
              [-36.334166665659041, -8.621666658989056],
              [-36.33416666745768, -8.619999993105125],
              [-36.333333331368124, -8.619999992205805],
              [-36.333333333166763, -8.619166658814208],
              [-36.33416666655836, -8.619166658814208],
              [-36.33416666655836, -8.617499992930277],
              [-36.334999999050694, -8.617499992930277],
              [-36.334999999950014, -8.615833327046346],
              [-36.335833332442292, -8.615833326147026],
              [-36.335833331542972, -8.614999992755429],
              [-36.336666666733265, -8.614999992755429],
              [-36.336666664934569, -8.613333325972178],
              [-36.337499999225543, -8.613333325972178],
              [-36.337499997426903, -8.612499993479844],
              [-36.339999999400391, -8.61249999617786],
              [-36.340833331892725, -8.612499993479844],
              [-36.340833333691364, -8.610833325797273],
              [-36.339999999400391, -8.610833325797273],
              [-36.34000000119903, -8.609166659014079],
              [-36.33833333261714, -8.609166659014079],
              [-36.33833333261714, -8.607499994029467],
              [-36.337500000124862, -8.607499993130148],
              [-36.337499999225543, -8.604999992955243],
              [-36.336666667632585, -8.604999992955243],
              [-36.336666665833945, -8.604166658664326],
              [-36.337500001024182, -8.604166657765006],
              [-36.337500001024182, -8.603333326171992],
              [-36.336666667632585, -8.603333326171992],
              [-36.336666665833945, -8.600833325997144],
              [-36.335000000849334, -8.600833325097824],
              [-36.334999999950014, -8.599166659213893],
              [-36.335833333341611, -8.599166659213893],
              [-36.335833331542972, -8.596666659938364],
              [-36.334999999050694, -8.596666659938364],
              [-36.334999999050694, -8.594999993155113],
              [-36.333333331368124, -8.594999993155113],
              [-36.333333333166763, -8.594166658864197],
              [-36.331666666383512, -8.594166658864197],
              [-36.331666665484192, -8.58999999280536],
              [-36.330833332092539, -8.58999999280536],
              [-36.330833333891178, -8.587499993529832],
              [-36.330000000499581, -8.587499994429152],
              [-36.329999998700941, -8.586666659238858],
              [-36.330833332991858, -8.586666659238858],
              [-36.330833333891178, -8.58416665906401],
              [-36.329999999600261, -8.58416665816469],
              [-36.329999998700941, -8.580833326396828],
              [-36.329166666208607, -8.580833325497508],
              [-36.329166667107984, -8.579999993005231],
              [-36.32833333281701, -8.579999993005231],
              [-36.32833333281701, -8.579166657814937],
              [-36.329166666208607, -8.579166658714257],
              [-36.329166668007304, -8.57833332622198],
              [-36.329999998700941, -8.57833332622198],
              [-36.329999999600261, -8.577499992830383],
              [-36.331666665484192, -8.577499993729703],
              [-36.331666665484192, -8.576666659438729],
              [-36.330833332092539, -8.576666659438729],
              [-36.330833332092539, -8.571666659088976],
              [-36.331666666383512, -8.571666659088976],
              [-36.331666665484192, -8.570833325697379],
              [-36.330833332991858, -8.570833325697379],
              [-36.330833332092539, -8.569166658914128],
              [-36.330000000499581, -8.569166658914128],
              [-36.330000000499581, -8.56833332642185],
              [-36.331666665484192, -8.56833332642185],
              [-36.331666665484192, -8.565833327146265],
              [-36.330833332991858, -8.565833326246945],
              [-36.330833331193219, -8.563333325172778],
              [-36.329999998700941, -8.563333326072097],
              [-36.330000000499581, -8.561666659288846],
              [-36.329166666208607, -8.561666659288846],
              [-36.329166667107984, -8.559166659113998],
              [-36.32833333191769, -8.559166659113998],
              [-36.32833333191769, -8.558333325722344],
              [-36.325833332642162, -8.558333325722344],
              [-36.325833331742842, -8.554999993055162],
              [-36.324999998351188, -8.554999993055162],
              [-36.324999998351188, -8.554166658764245],
              [-36.324166666758231, -8.554166658764245],
              [-36.324166665858911, -8.553333326271968],
              [-36.323333331567937, -8.553333326271968],
              [-36.323333335165216, -8.551666659488717],
              [-36.323333332467257, -8.550833326097063],
              [-36.323333331567937, -8.549999992705466],
              [-36.321666665684006, -8.549999992705466],
              [-36.321666664784686, -8.546666659138964],
              [-36.319999998900812, -8.546666660038284],
              [-36.319999999800132, -8.544999993255033],
              [-36.319166666408478, -8.544999993255033],
              [-36.319166666408478, -8.540833326296934],
              [-36.318333332117561, -8.540833326296934],
              [-36.318333333016881, -8.53999999290528],
              [-36.315833332841976, -8.53999999290528],
              [-36.315833332841976, -8.538333326122029],
              [-36.314999999450379, -8.538333326122029],
              [-36.314999998551059, -8.535833325947181],
              [-36.315833331942656, -8.535833325947181],
              [-36.315833332841976, -8.534999993454903],
              [-36.316666667132949, -8.534999994354223],
              [-36.31666666623363, -8.53416665916393],
              [-36.317499999625227, -8.53416665916393],
              [-36.317500000524547, -8.533333325772333],
              [-36.320833333191729, -8.533333325772333],
              [-36.320833334091049, -8.53416665916393],
              [-36.321666666583326, -8.53416665826461],
              [-36.321666666583326, -8.530833326496747],
              [-36.3225000008743, -8.530833326496747],
              [-36.3225000008743, -8.529166658814233],
              [-36.321666665684006, -8.529166658814233],
              [-36.321666666583326, -8.528333326321899],
              [-36.319999999800132, -8.528333326321899],
              [-36.319999998900812, -8.525833326147051],
              [-36.319166664609838, -8.525833326147051],
              [-36.319166666408478, -8.523333325972146],
              [-36.316666668032269, -8.523333326871466],
              [-36.316666667132949, -8.521666659188952],
              [-36.315833331942656, -8.521666659188952],
              [-36.315833333741296, -8.520833325797298],
              [-36.315833331942656, -8.519999992405701],
              [-36.314999998551059, -8.519999992405701],
              [-36.314999998551059, -8.51833332652177],
              [-36.315833333741296, -8.51833332652177],
              [-36.315833331043336, -8.517499993130116],
              [-36.315000000349698, -8.517499993130116],
              [-36.314999999450379, -8.516666658839199],
              [-36.312499998376154, -8.516666658839199],
              [-36.31250000017485, -8.515833326346865],
              [-36.310833333391599, -8.515833326346865],
              [-36.31083333249228, -8.514999992955268],
              [-36.309999999100626, -8.514999992955268],
              [-36.309999999100626, -8.514166658664294],
              [-36.308333335015334, -8.514166658664294],
              [-36.308333333216694, -8.514999993854588],
              [-36.307499999825097, -8.514999993854588],
              [-36.307499998925778, -8.514166658664294],
              [-36.305833333041846, -8.514166658664294],
              [-36.305833333041846, -8.515833327246185],
              [-36.303333332866998, -8.515833326346865],
              [-36.303333331967679, -8.517499993130116],
              [-36.300833333591413, -8.517499993130116],
              [-36.300833331792774, -8.519166659014047],
              [-36.299166667707482, -8.519166659014047],
              [-36.299166665908842, -8.515833326346865],
              [-36.300000000199816, -8.515833326346865],
              [-36.300000001099136, -8.514999992955268],
              [-36.298333331617926, -8.514999992955268],
              [-36.298333332517245, -8.513333327071336],
              [-36.296666665733994, -8.513333326172017],
              [-36.296666668431953, -8.51249999278042],
              [-36.294166666458466, -8.51249999278042],
              [-36.294166665559146, -8.511666659388766],
              [-36.293333333066812, -8.511666659388766],
              [-36.293333333066812, -8.512499993679739],
              [-36.292499998775895, -8.51249999278042],
              [-36.292500000574535, -8.513333326172017],
              [-36.291666665384241, -8.513333326172017],
              [-36.2916666680822, -8.514166658664294],
              [-36.289166667008033, -8.514166658664294],
              [-36.289166667907352, -8.514999992955268],
              [-36.288333331817739, -8.514999992955268],
              [-36.288333331817739, -8.515833327246185],
              [-36.286666666833185, -8.515833326346865],
              [-36.286666666833185, -8.517499993130116],
              [-36.285833333441531, -8.517499993130116],
              [-36.285833333441531, -8.51833332652177],
              [-36.28416666575896, -8.51833332652177],
              [-36.284166667557599, -8.519166659014047],
              [-36.283333332367363, -8.519166659014047],
              [-36.283333334166002, -8.520833325797298],
              [-36.281666665584112, -8.520833326696618],
              [-36.281666665584112, -8.517499992230796],
              [-36.280833332192458, -8.517499992230796],
              [-36.280833333091778, -8.516666658839199],
              [-36.279999999700181, -8.516666658839199],
              [-36.279999999700181, -8.515833326346865],
              [-36.279166665409264, -8.515833325447545],
              [-36.279166665409264, -8.514166658664294],
              [-36.27833333201761, -8.514166658664294],
              [-36.27833333201761, -8.510833325997169],
              [-36.279166665409264, -8.510833325997169],
              [-36.279166668107223, -8.509999993504834],
              [-36.27833333201761, -8.509999994404154],
              [-36.278333333816249, -8.509166659213918],
              [-36.274999997551788, -8.509166659213918],
              [-36.274999998451108, -8.507499993329986],
              [-36.272499999175579, -8.507499994229306],
              [-36.272500000074899, -8.508333326721583],
              [-36.270000000799371, -8.508333325822264],
              [-36.269999999000731, -8.507499993329986],
              [-36.269166667407717, -8.507499993329986],
              [-36.269166665609077, -8.506666659039013],
              [-36.2683333331168, -8.506666659039013],
              [-36.26833333221748, -8.505833324748096],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '128',
        OBJECTID: 173.0,
        Nome_area: 'Santa Maria da Boa Vista',
        COD_area: 'CA180',
        Import_bio: 'Muito Alta',
        Prior_acao: 'Muito Alta',
        Acao_princ: 'Recuperação de Áreas Degradadas',
        Area_ha: 44068.1421,
        Shape_Leng: 1.31667,
        Shape_Area: 0.0362,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.820833332492271, -8.454999993255058],
              [-39.82083333339159, -8.455833326646655],
              [-39.817499999825088, -8.455833325747335],
              [-39.817499998925769, -8.459166659313837],
              [-39.816666666433491, -8.459166659313837],
              [-39.816666666433491, -8.459999992705434],
              [-39.814166665359267, -8.459999992705434],
              [-39.814166665359267, -8.460833326097088],
              [-39.812499999475335, -8.460833326097088],
              [-39.812499999475335, -8.461666659488685],
              [-39.809999998401167, -8.461666659488685],
              [-39.810000000199807, -8.460833326097088],
              [-39.808333333416556, -8.460833326097088],
              [-39.808333331617916, -8.461666660388005],
              [-39.807500000924279, -8.461666659488685],
              [-39.807499999125639, -8.463333326271936],
              [-39.805833332342388, -8.463333326271936],
              [-39.805833332342388, -8.462499992880339],
              [-39.803333333966123, -8.462499992880339],
              [-39.803333333066803, -8.461666659488685],
              [-39.802499999675206, -8.461666659488685],
              [-39.802500000574526, -8.459999992705434],
              [-39.799999999500358, -8.459999992705434],
              [-39.799999999500358, -8.459166660213157],
              [-39.798333333616426, -8.459166659313837],
              [-39.798333333616426, -8.458333325922183],
              [-39.797500001124092, -8.458333325922183],
              [-39.797500001124092, -8.457499993429906],
              [-39.795000000049924, -8.457499995228545],
              [-39.794166667557647, -8.457499993429906],
              [-39.794166667557647, -8.458333326821503],
              [-39.793333330568714, -8.458333325922183],
              [-39.793333334165993, -8.459999992705434],
              [-39.786666667932309, -8.459999992705434],
              [-39.786666666133669, -8.460833326097088],
              [-39.785833331842753, -8.460833326097088],
              [-39.785833332742072, -8.461666659488685],
              [-39.784999999350418, -8.461666659488685],
              [-39.784999998451099, -8.462499992880339],
              [-39.784166665958821, -8.462499992880339],
              [-39.784166665958821, -8.463333326271936],
              [-39.783333331667905, -8.463333326271936],
              [-39.783333332567224, -8.465833326446784],
              [-39.78249999917557, -8.465833326446784],
              [-39.78250000097421, -8.469166659113966],
              [-39.780833331493, -8.469166658214647],
              [-39.780833332392319, -8.46999999340494],
              [-39.779999999900042, -8.46999999340494],
              [-39.779999999900042, -8.470833326796537],
              [-39.784999998451099, -8.470833326796537],
              [-39.784999999350418, -8.472499992680468],
              [-39.785833333641392, -8.472499992680468],
              [-39.785833331842753, -8.474166659463719],
              [-39.786666667932309, -8.474166659463719],
              [-39.786666667932309, -8.474999992855317],
              [-39.785833331842753, -8.474999992855317],
              [-39.785833331842753, -8.47583332714629],
              [-39.784999999350418, -8.475833325347651],
              [-39.784999998451099, -8.476666658739248],
              [-39.784166667757461, -8.476666658739248],
              [-39.784166665958821, -8.478333327321138],
              [-39.781666667582613, -8.478333326421819],
              [-39.781666666683293, -8.480833325697404],
              [-39.78250000097421, -8.480833325697404],
              [-39.78250000097421, -8.482499993379918],
              [-39.781666665783973, -8.482499993379918],
              [-39.781666666683293, -8.483333325872252],
              [-39.779166666508388, -8.483333325872252],
              [-39.779166664709749, -8.4858333260471],
              [-39.77833333491543, -8.4858333260471],
              [-39.778333333116791, -8.487499992830351],
              [-39.776666668132179, -8.487499992830351],
              [-39.776666668132179, -8.489166658714282],
              [-39.775833333841263, -8.489166658714282],
              [-39.775833332042623, -8.489999993005199],
              [-39.774999998650969, -8.489999993005199],
              [-39.775000000449609, -8.49166665888913],
              [-39.775833331143247, -8.49166665888913],
              [-39.775833332042623, -8.495833324947967],
              [-39.77666666723286, -8.495833325847286],
              [-39.77666666723286, -8.499166660313051],
              [-39.774999998650969, -8.499166658514412],
              [-39.775000000449609, -8.499999992805385],
              [-39.773333330968399, -8.499999992805385],
              [-39.773333333666358, -8.500833326196982],
              [-39.772499999375441, -8.500833326196982],
              [-39.77250000117408, -8.501666658689317],
              [-39.77083333169287, -8.501666658689317],
              [-39.77083333259219, -8.500833326196982],
              [-39.769166666708259, -8.500833327096302],
              [-39.769166665808939, -8.503333326371887],
              [-39.768333332417342, -8.503333326371887],
              [-39.768333334215981, -8.505833325647416],
              [-39.764166666358506, -8.505833325647416],
              [-39.764166666358506, -8.504999993155138],
              [-39.763333332966909, -8.504999994054458],
              [-39.763333332067589, -8.505833324748096],
              [-39.761666667082977, -8.505833325647416],
              [-39.761666667082977, -8.506666659039013],
              [-39.759999999400407, -8.506666658139693],
              [-39.759999999400407, -8.504999993155138],
              [-39.757500000124878, -8.504999993155138],
              [-39.757499999225558, -8.505833325647416],
              [-39.752500000674445, -8.505833325647416],
              [-39.752499998875805, -8.509999993504834],
              [-39.751666665484208, -8.509999993504834],
              [-39.751666665484208, -8.511666659388766],
              [-39.750833333891194, -8.511666659388766],
              [-39.750833333891194, -8.514999992955268],
              [-39.750000000499597, -8.514999992955268],
              [-39.749999998700957, -8.515833326346865],
              [-39.750833332092554, -8.515833326346865],
              [-39.750833332092554, -8.516666658839199],
              [-39.749166667107943, -8.516666658839199],
              [-39.749166668007263, -8.51833332652177],
              [-39.748333333716346, -8.51833332652177],
              [-39.748333332817026, -8.519166659014047],
              [-39.747499998526052, -8.519166659014047],
              [-39.747500001224012, -8.520833325797298],
              [-39.743333332467273, -8.520833324897978],
              [-39.743333332467273, -8.519166659014047],
              [-39.742499999075676, -8.519166659014047],
              [-39.742500001773635, -8.51833332652177],
              [-39.741666665684022, -8.51833332652177],
              [-39.741666665684022, -8.517499993130116],
              [-39.740833334091064, -8.517499993130116],
              [-39.740833333191745, -8.51833332652177],
              [-39.736666666233589, -8.51833332652177],
              [-39.736666667132965, -8.519999993305021],
              [-39.735833333741311, -8.519999993305021],
              [-39.735833332841992, -8.523333325072826],
              [-39.734166666058741, -8.523333325972146],
              [-39.73416666695806, -8.521666658289575],
              [-39.73249999837617, -8.521666659188952],
              [-39.73249999837617, -8.522499993479869],
              [-39.731666664984573, -8.522499994379189],
              [-39.731666667682532, -8.5241666593638],
              [-39.732500000174809, -8.5241666593638],
              [-39.732500000174809, -8.524999992755397],
              [-39.731666666783212, -8.524999992755397],
              [-39.731666665883893, -8.526666658639328],
              [-39.730833332492239, -8.526666657740009],
              [-39.730833332492239, -8.527499992930302],
              [-39.729166667507684, -8.527499992930302],
              [-39.729166665708988, -8.528333326321899],
              [-39.727499999825113, -8.528333325422579],
              [-39.727499998925794, -8.529166658814233],
              [-39.726666666433459, -8.529166658814233],
              [-39.72666666553414, -8.531666658989081],
              [-39.725833332142543, -8.531666658089762],
              [-39.725833333041862, -8.533333325772333],
              [-39.724999998750889, -8.533333325772333],
              [-39.724999998750889, -8.53416665826461],
              [-39.724166665359292, -8.53416665916393],
              [-39.724166667157931, -8.534999993454903],
              [-39.721666666083706, -8.534999991656264],
              [-39.721666667882346, -8.535833325947181],
              [-39.720833332692109, -8.535833325947181],
              [-39.720833332692109, -8.539166659513683],
              [-39.720833332692109, -8.540833327196253],
              [-39.720000001099152, -8.540833326296934],
              [-39.720000001099152, -8.542499992180865],
              [-39.719166665908858, -8.542499993080185],
              [-39.719166666808178, -8.544999993255033],
              [-39.720000000199832, -8.544999993255033],
              [-39.719999998401136, -8.547499993429881],
              [-39.720833332692109, -8.547499993429881],
              [-39.720833332692109, -8.552499992880314],
              [-39.721666667882346, -8.552499992880314],
              [-39.721666666083706, -8.553333326271968],
              [-39.722499998576041, -8.553333326271968],
              [-39.72250000037468, -8.554999993055162],
              [-39.723333331967638, -8.554999993055162],
              [-39.723333331967638, -8.555833324648177],
              [-39.72250000037468, -8.555833326446816],
              [-39.722499998576041, -8.559166658214679],
              [-39.722499998576041, -8.560833325897249],
              [-39.723333331068318, -8.56083332499793],
              [-39.723333331967638, -8.561666659288846],
              [-39.724166668057251, -8.561666659288846],
              [-39.724166668057251, -8.562499992680443],
              [-39.724999999650208, -8.562499992680443],
              [-39.724999997851569, -8.563333326072097],
              [-39.726666669131419, -8.563333326072097],
              [-39.726666669131419, -8.564166660363014],
              [-39.729166666608307, -8.564166659463694],
              [-39.729166667507684, -8.564999992855348],
              [-39.730000000899281, -8.564999991956029],
              [-39.730000000899281, -8.565833326246945],
              [-39.730833332492239, -8.565833326246945],
              [-39.730833332492239, -8.56833332642185],
              [-39.731666666783212, -8.56833332642185],
              [-39.731666667682532, -8.576666660338049],
              [-39.732500001074129, -8.576666659438729],
              [-39.732500000174809, -8.577499992830383],
              [-39.733333332667144, -8.577499992830383],
              [-39.733333332667144, -8.582499993180079],
              [-39.732500000174809, -8.582499993180079],
              [-39.73249999837617, -8.583333325672413],
              [-39.735833331942672, -8.583333325672413],
              [-39.735833331942672, -8.582499993180079],
              [-39.736666668032285, -8.582499992280759],
              [-39.736666667132965, -8.583333325672413],
              [-39.73833333211752, -8.583333325672413],
              [-39.73833333301684, -8.58416665906401],
              [-39.739166668207133, -8.58416665906401],
              [-39.739166666408494, -8.584999993354984],
              [-39.740833334091064, -8.584999993354984],
              [-39.740833333191745, -8.587499993529832],
              [-39.739999999800091, -8.587499993529832],
              [-39.740000000699411, -8.589166659413763],
              [-39.739166668207133, -8.589166659413763],
              [-39.739166666408494, -8.591666657789972],
              [-39.73833333301684, -8.591666657789972],
              [-39.73833333301684, -8.594166658864197],
              [-39.737499999625243, -8.594166658864197],
              [-39.737499999625243, -8.594999993155113],
              [-39.73833333301684, -8.594999992255794],
              [-39.73833333391616, -8.600833325997144],
              [-39.737499999625243, -8.600833325997144],
              [-39.737499998725923, -8.601666658489478],
              [-39.73833333211752, -8.601666659388798],
              [-39.73833333211752, -8.603333325272672],
              [-39.739166668207133, -8.603333325272672],
              [-39.739166668207133, -8.604166658664326],
              [-39.740000000699411, -8.604166658664326],
              [-39.739999998900771, -8.604999992955243],
              [-39.740833333191745, -8.604999992955243],
              [-39.740833331393105, -8.605833326346897],
              [-39.741666668381981, -8.605833326346897],
              [-39.741666665684022, -8.606666658839174],
              [-39.742500000874315, -8.606666658839174],
              [-39.742499999974996, -8.607499993130148],
              [-39.741666666583342, -8.607499993130148],
              [-39.741666665684022, -8.608333326521745],
              [-39.740833331393105, -8.608333324723105],
              [-39.740833333191745, -8.609999993304996],
              [-39.739999999800091, -8.609999993304996],
              [-39.739999999800091, -8.615833326147026],
              [-39.740833333191745, -8.615833326147026],
              [-39.740833334091064, -8.619166659713528],
              [-39.741666665684022, -8.619166658814208],
              [-39.741666667482662, -8.620833326496779],
              [-39.739999998900771, -8.620833326496779],
              [-39.739999998900771, -8.621666658989056],
              [-39.740833333191745, -8.621666658989056],
              [-39.740833334091064, -8.624166659163961],
              [-39.74000000159873, -8.624166659163961],
              [-39.740000000699411, -8.624999993454878],
              [-39.742499999974996, -8.624999993454878],
              [-39.742499999075676, -8.625833325947212],
              [-39.744166665858927, -8.625833326846532],
              [-39.744166666758247, -8.623333325772307],
              [-39.744999998351204, -8.623333324872988],
              [-39.744999998351204, -8.62249999328003],
              [-39.746666667832415, -8.62249999328003],
              [-39.746666667832415, -8.621666658989056],
              [-39.749999998700957, -8.621666658989056],
              [-39.749999998700957, -8.620833326496779],
              [-39.751666669081487, -8.620833326496779],
              [-39.751666666383528, -8.619999993105125],
              [-39.754166665659056, -8.619999993105125],
              [-39.754166666558376, -8.620833326496779],
              [-39.754999999050654, -8.620833326496779],
              [-39.754999999949973, -8.621666658989056],
              [-39.755833333341627, -8.621666658989056],
              [-39.755833331542988, -8.62083332469814],
              [-39.756666667632544, -8.620833326496779],
              [-39.756666667632544, -8.621666658989056],
              [-39.758333331717836, -8.621666658089737],
              [-39.758333331717836, -8.62249999328003],
              [-39.76083333369138, -8.62249999328003],
              [-39.760833331892684, -8.623333325772307],
              [-39.761666667082977, -8.623333325772307],
              [-39.761666667082977, -8.624166659163961],
              [-39.765000000649479, -8.624166659163961],
              [-39.76499999975016, -8.62249999328003],
              [-39.765833333141757, -8.62249999417935],
              [-39.765833331343117, -8.624999993454878],
              [-39.766666665634091, -8.624999993454878],
              [-39.766666665634091, -8.625833325947212],
              [-39.767499999025688, -8.625833325947212],
              [-39.767499999025688, -8.626666659338809],
              [-39.768333331517965, -8.62666665843949],
              [-39.768333332417342, -8.62833332702138],
              [-39.770000000099856, -8.62833332612206],
              [-39.770000000099856, -8.629166659513658],
              [-39.77083333259219, -8.629166659513658],
              [-39.77083333169287, -8.630833326296909],
              [-39.772499998476121, -8.630833326296909],
              [-39.772499998476121, -8.631666658789243],
              [-39.774166666158692, -8.631666658789243],
              [-39.774166667058012, -8.63249999308016],
              [-39.774999999550289, -8.63249999308016],
              [-39.774999999550289, -8.633333326471814],
              [-39.77666666723286, -8.633333326471814],
              [-39.77666666543422, -8.634166658964091],
              [-39.777499999725137, -8.634166658964091],
              [-39.777500000624514, -8.634999993255065],
              [-39.777499998825817, -8.635833325747342],
              [-39.775833332042623, -8.635833325747342],
              [-39.775833333841263, -8.637499993429913],
              [-39.775000000449609, -8.637499993429913],
              [-39.775000000449609, -8.63833332682151],
              [-39.77250000117408, -8.63833332592219],
              [-39.772499999375441, -8.639166659313844],
              [-39.771666667782426, -8.639166659313844],
              [-39.771666665983787, -8.639999991806121],
              [-39.771666666883107, -8.642499992880346],
              [-39.77083333169287, -8.642499992880346],
              [-39.77083333349151, -8.644999993055194],
              [-39.771666665983787, -8.644999993055194],
              [-39.771666665983787, -8.645833326446791],
              [-39.772499999375441, -8.645833326446791],
              [-39.77250000117408, -8.646666658939125],
              [-39.773333333666358, -8.646666658939125],
              [-39.773333332767038, -8.647499993230042],
              [-39.773333332767038, -8.648333325722376],
              [-39.775833332941943, -8.648333325722376],
              [-39.775833332042623, -8.649166659113973],
              [-39.77666666723286, -8.649166659113973],
              [-39.776666668132179, -8.649999993404947],
              [-39.778333332217471, -8.649999993404947],
              [-39.778333334016111, -8.650833325897224],
              [-39.779166665609068, -8.650833325897224],
              [-39.779166667407708, -8.651666659288821],
              [-39.780833332392319, -8.651666659288821],
              [-39.780833332392319, -8.653333326072072],
              [-39.783333332567224, -8.653333326072072],
              [-39.783333330768585, -8.652499992680475],
              [-39.785000001149115, -8.652499992680475],
              [-39.785000001149115, -8.653333326971392],
              [-39.785833332742072, -8.653333326072072],
              [-39.785833332742072, -8.654999992855323],
              [-39.786666667032989, -8.654999992855323],
              [-39.786666666133669, -8.656666658739255],
              [-39.787499999525323, -8.656666657839935],
              [-39.787500000424643, -8.657499993030228],
              [-39.789166667207894, -8.657499992130909],
              [-39.789166667207894, -8.659166658914103],
              [-39.789999998800852, -8.659166658914103],
              [-39.789999999700171, -8.660833325697354],
              [-39.791666666483422, -8.660833325697354],
              [-39.791666666483422, -8.661666659089008],
              [-39.792500000774396, -8.661666659089008],
              [-39.792499999875076, -8.662499994279244],
              [-39.794166665758951, -8.662499993379924],
              [-39.794166665758951, -8.663333326771578],
              [-39.795000000949244, -8.663333325872259],
              [-39.794999999150605, -8.66499999265551],
              [-39.795833332542202, -8.664999994454149],
              [-39.795833331642882, -8.666666659438761],
              [-39.794999999150605, -8.666666659438761],
              [-39.794999999150605, -8.669999993005206],
              [-39.795833332542202, -8.669999993005206],
              [-39.795833333441522, -8.67083332639686],
              [-39.794999999150605, -8.67083332549754],
              [-39.795000000049924, -8.674166659064042],
              [-39.795833331642882, -8.674166658164722],
              [-39.795833333441522, -8.674999993354959],
              [-39.796666666833175, -8.674999993354959],
              [-39.796666666833175, -8.67666665923889],
              [-39.796666665933856, -8.677499994429127],
              [-39.795833330743562, -8.677499993529807],
              [-39.795833331642882, -8.679166659413738],
              [-39.794999999150605, -8.679166659413738],
              [-39.795000000949244, -8.679999992805392],
              [-39.794166666658271, -8.679999993704712],
              [-39.794166666658271, -8.681666658689323],
              [-39.792499998975757, -8.681666658689323],
              [-39.792499999875076, -8.683333325472518],
              [-39.791666666483422, -8.683333325472518],
              [-39.791666666483422, -8.684166658864171],
              [-39.789999999700171, -8.684166658864171],
              [-39.789999998800852, -8.684999992255769],
              [-39.788333331118281, -8.684999993155088],
              [-39.788333332017601, -8.685833325647422],
              [-39.787499998626004, -8.685833325647422],
              [-39.787499998626004, -8.688333324922951],
              [-39.786666666133669, -8.688333325822271],
              [-39.786666666133669, -8.689166659213925],
              [-39.786666667032989, -8.689999994404161],
              [-39.787499998626004, -8.689999993504841],
              [-39.787499998626004, -8.691666658489453],
              [-39.788333332017601, -8.691666658489453],
              [-39.78833333291692, -8.69249999278037],
              [-39.789166667207894, -8.69249999278037],
              [-39.789166664509935, -8.698333326521777],
              [-39.789999998800852, -8.698333326521777],
              [-39.789999998800852, -8.700833325797305],
              [-39.788333332017601, -8.700833325797305],
              [-39.78833333291692, -8.701666660088222],
              [-39.787499998626004, -8.701666659188902],
              [-39.787499999525323, -8.704166659363807],
              [-39.78833333291692, -8.704166659363807],
              [-39.78833333291692, -8.706666660437975],
              [-39.789166665409255, -8.706666658639335],
              [-39.789166668107214, -8.709999993105157],
              [-39.789999998800852, -8.709999993105157],
              [-39.789999998800852, -8.710833326496754],
              [-39.794166665758951, -8.710833326496754],
              [-39.794166665758951, -8.715833325947187],
              [-39.794166666658271, -8.716666659338784],
              [-39.794999999150605, -8.716666659338784],
              [-39.794999999150605, -8.717499991831119],
              [-39.796666665933856, -8.717499991831119],
              [-39.796666666833175, -8.718333326122035],
              [-39.797500001124092, -8.718333326122035],
              [-39.797500002023412, -8.719166659513689],
              [-39.799166667008024, -8.71916665771505],
              [-39.799166666108704, -8.719999992905286],
              [-39.804999998950734, -8.719999992905286],
              [-39.804999999850054, -8.719166659513689],
              [-39.806666667532625, -8.719166659513689],
              [-39.806666666633305, -8.719999992905286],
              [-39.80916666770753, -8.719999992905286],
              [-39.80916666680821, -8.721666659688537],
              [-39.810000000199807, -8.721666658789218],
              [-39.809999998401167, -8.722499993979511],
              [-39.812499999475335, -8.722499993080191],
              [-39.812500000374655, -8.724166658964066],
              [-39.81500000054956, -8.724166658964066],
              [-39.81500000054956, -8.724999993255039],
              [-39.815833333041837, -8.724999993255039],
              [-39.815833333941157, -8.725833325747317],
              [-39.821666665883868, -8.725833325747317],
              [-39.821666666783187, -8.726666659138971],
              [-39.824166666058773, -8.726666660038291],
              [-39.824166666058773, -8.725833325747317],
              [-39.826666664434981, -8.725833325747317],
              [-39.826666666233621, -8.726666659138971],
              [-39.830000000699442, -8.726666659138971],
              [-39.829999999800123, -8.725833325747317],
              [-39.83083333319172, -8.725833325747317],
              [-39.83083333319172, -8.724999993255039],
              [-39.831666666583374, -8.724999993255039],
              [-39.831666665684054, -8.724166659863442],
              [-39.832499999974971, -8.724166658964066],
              [-39.83250000087429, -8.723333326471788],
              [-39.834999998351179, -8.723333326471788],
              [-39.835000001049139, -8.722499993979511],
              [-39.838333333716321, -8.722499993080191],
              [-39.838333332817001, -8.72083332719626],
              [-39.839999999600252, -8.72083332629694],
              [-39.839999999600252, -8.719999992905286],
              [-39.841666666383503, -8.719999992905286],
              [-39.841666669081462, -8.72083332629694],
              [-39.844166666558351, -8.72083332629694],
              [-39.844166665659031, -8.719999992905286],
              [-39.845833332442282, -8.719999993804606],
              [-39.845833330643643, -8.72083332629694],
              [-39.854999999750135, -8.72083332629694],
              [-39.854999998850815, -8.719999992905286],
              [-39.855833334041108, -8.719999992905286],
              [-39.856666665634066, -8.719999992905286],
              [-39.856666665634066, -8.718333326122035],
              [-39.856666666533386, -8.716666659338784],
              [-39.857499999925039, -8.716666660238161],
              [-39.857499999925039, -8.714999991656271],
              [-39.858333331517997, -8.714999991656271],
              [-39.858333334215956, -8.714166660063256],
              [-39.859166665808914, -8.714166659163936],
              [-39.859166665808914, -8.711666658989088],
              [-39.859999999200568, -8.711666658989088],
              [-39.860000000099888, -8.710833326496754],
              [-39.860833333491485, -8.710833326496754],
              [-39.860833331692845, -8.705833325247738],
              [-39.861666665983819, -8.705833326147058],
              [-39.861666666883139, -8.702499993479876],
              [-39.862499999375416, -8.702499993479876],
              [-39.862500001174055, -8.699999993305028],
              [-39.86333333276707, -8.699999993305028],
              [-39.86333333366639, -8.698333326521777],
              [-39.864166666158667, -8.698333326521777],
              [-39.864166667957306, -8.696666658839206],
              [-39.86333333276707, -8.696666658839206],
              [-39.86333333276707, -8.694999992955275],
              [-39.862500001174055, -8.694999992955275],
              [-39.862500001174055, -8.693333326172024],
              [-39.859999999200568, -8.693333326172024],
              [-39.859999999200568, -8.69249999188105],
              [-39.858333331517997, -8.69249999278037],
              [-39.858333334215956, -8.689999994404161],
              [-39.859166667607553, -8.689999993504841],
              [-39.859166666708234, -8.688333324922951],
              [-39.857500000824359, -8.688333325822271],
              [-39.85749999902572, -8.687499993329993],
              [-39.856666667432705, -8.687499993329993],
              [-39.856666667432705, -8.68666665903902],
              [-39.857500000824359, -8.68666665903902],
              [-39.857499999925039, -8.685833327446062],
              [-39.860000000099888, -8.685833325647422],
              [-39.860000000099888, -8.684999993155088],
              [-39.864166667057987, -8.684999993155088],
              [-39.864166666158667, -8.684166658864171],
              [-39.864999999550321, -8.684166658864171],
              [-39.864999998651001, -8.683333326371894],
              [-39.867499999725169, -8.683333326371894],
              [-39.867499998825849, -8.68249999298024],
              [-39.868333332217446, -8.68249999298024],
              [-39.868333334016086, -8.681666660487963],
              [-39.86916666650842, -8.681666658689323],
              [-39.8691666656091, -8.680833326196989],
              [-39.870833334190991, -8.680833326196989],
              [-39.870833331493031, -8.679999992805392],
              [-39.871666665783948, -8.679999992805392],
              [-39.871666666683268, -8.679166659413738],
              [-39.873333332567199, -8.679166659413738],
              [-39.873333333466519, -8.677499993529807],
              [-39.875833331842728, -8.677499993529807],
              [-39.875833333641367, -8.67666665923889],
              [-39.878333332017633, -8.67666665923889],
              [-39.878333333816272, -8.675833325847236],
              [-39.87916666540923, -8.675833326746613],
              [-39.87916666540923, -8.674999994254279],
              [-39.878333332017633, -8.674999993354959],
              [-39.878333333816272, -8.672499993180111],
              [-39.877499999525298, -8.672499993180111],
              [-39.877499999525298, -8.671666657989817],
              [-39.87916666540923, -8.671666659788457],
              [-39.87916666540923, -8.669166658714289],
              [-39.878333333816272, -8.669166658714289],
              [-39.878333332017633, -8.667499993729677],
              [-39.87916666540923, -8.667499992830358],
              [-39.87916666540923, -8.666666659438761],
              [-39.881666666483454, -8.666666659438761],
              [-39.881666666483454, -8.664999994454149],
              [-39.883333332367329, -8.66499999265551],
              [-39.883333332367329, -8.664166659263856],
              [-39.884166666658302, -8.664166660163175],
              [-39.884166665758983, -8.663333326771578],
              [-39.885000000049899, -8.663333325872259],
              [-39.88499999915058, -8.660833327495993],
              [-39.885833331642914, -8.660833325697354],
              [-39.885833332542234, -8.659166658914103],
              [-39.887500000224804, -8.659166658914103],
              [-39.887500000224804, -8.658333326421825],
              [-39.889166667008055, -8.658333326421825],
              [-39.889166667008055, -8.657499993030228],
              [-39.889999999500333, -8.657499992130909],
              [-39.889999999500333, -8.653333326072072],
              [-39.89083333199261, -8.653333326971392],
              [-39.89083333199261, -8.651666658389502],
              [-39.891666665384264, -8.651666658389502],
              [-39.891666665384264, -8.650833324997905],
              [-39.893333333066835, -8.650833325897224],
              [-39.893333333066835, -8.649999993404947],
              [-39.894166665559112, -8.649999993404947],
              [-39.894166666458432, -8.649166659113973],
              [-39.894999999850086, -8.649166659113973],
              [-39.894999999850086, -8.648333327521016],
              [-39.895833335040322, -8.648333327521016],
              [-39.895833334141003, -8.647499993230042],
              [-39.896666666633337, -8.647499992330722],
              [-39.896666665734017, -8.646666658939125],
              [-39.897500000024934, -8.646666658939125],
              [-39.897500000024934, -8.645833326446791],
              [-39.898333331617891, -8.645833326446791],
              [-39.898333331617891, -8.644999993055194],
              [-39.899166665908865, -8.644999992155874],
              [-39.899166666808185, -8.640833326996415],
              [-39.900000001099102, -8.640833326097095],
              [-39.899999998401142, -8.639999992705441],
              [-39.900833332692116, -8.639999992705441],
              [-39.900833331792796, -8.639166659313844],
              [-39.901666666983033, -8.639166659313844],
              [-39.901666666083713, -8.63833332592219],
              [-39.902499998576047, -8.63833332592219],
              [-39.902499998576047, -8.637499993429913],
              [-39.903333331967644, -8.637499991631273],
              [-39.903333332866964, -8.634999993255065],
              [-39.904166668057258, -8.634999993255065],
              [-39.904166665359298, -8.635833325747342],
              [-39.906666666433466, -8.635833325747342],
              [-39.906666665534146, -8.636666659138939],
              [-39.907499999825063, -8.636666659138939],
              [-39.907499998925744, -8.635833324848022],
              [-39.909999999100648, -8.635833325747342],
              [-39.910000000899288, -8.634999993255065],
              [-39.910833332492246, -8.634999993255065],
              [-39.910833333391565, -8.634166658964091],
              [-39.911666665883899, -8.634166658964091],
              [-39.911666667682539, -8.633333326471814],
              [-39.914166667857387, -8.633333326471814],
              [-39.914166666958067, -8.634166658964091],
              [-39.914999998551025, -8.634166658964091],
              [-39.914999999450345, -8.634999993255065],
              [-39.915833332841999, -8.634999992355688],
              [-39.915833332841999, -8.635833324848022],
              [-39.916666666233596, -8.635833325747342],
              [-39.916666666233596, -8.636666659138939],
              [-39.91749999962525, -8.636666659138939],
              [-39.917500000524569, -8.637499993429913],
              [-39.918333332117527, -8.637499993429913],
              [-39.918333333016847, -8.635833325747342],
              [-39.919166666408501, -8.635833325747342],
              [-39.919166665509181, -8.634999993255065],
              [-39.9233333333666, -8.634999993255065],
              [-39.92333333246728, -8.634166658964091],
              [-39.924166665858877, -8.634166658064771],
              [-39.924166665858877, -8.632499993979479],
              [-39.925000000149851, -8.63249999308016],
              [-39.925000000149851, -8.629999992905312],
              [-39.925833333541448, -8.629999993804631],
              [-39.925833331742808, -8.62833332612206],
              [-39.926666667832421, -8.62833332612206],
              [-39.926666666033782, -8.626666660238129],
              [-39.927499998526059, -8.626666659338809],
              [-39.927499998526059, -8.625833325947212],
              [-39.92916666710795, -8.625833325047893],
              [-39.92916666710795, -8.624999993454878],
              [-39.929999998700964, -8.624999993454878],
              [-39.929999999600284, -8.62249999238071],
              [-39.930833332092561, -8.62249999328003],
              [-39.930833332991881, -8.621666658989056],
              [-39.930000000499604, -8.621666659888376],
              [-39.929999998700964, -8.618333325422611],
              [-39.930833332991881, -8.618333326321931],
              [-39.930833332991881, -8.617499992930277],
              [-39.931666666383478, -8.617499992930277],
              [-39.931666668182174, -8.618333326321931],
              [-39.933333333166729, -8.618333326321931],
              [-39.933333334066049, -8.617499992030957],
              [-39.935833331542995, -8.617499992930277],
              [-39.935833333341634, -8.61666665863936],
              [-39.939999999400413, -8.61666665863936],
              [-39.939999999400413, -8.615833326147026],
              [-39.941666664385025, -8.615833325247706],
              [-39.941666667082984, -8.614999993654749],
              [-39.943333332067596, -8.614999992755429],
              [-39.943333331168276, -8.614166659363775],
              [-39.944166665459193, -8.614166660263095],
              [-39.944166665459193, -8.612499993479844],
              [-39.942499999575261, -8.612499993479844],
              [-39.942500000474581, -8.611666660088247],
              [-39.939999999400413, -8.611666659188927],
              [-39.939999999400413, -8.612499993479844],
              [-39.939166666008759, -8.612499993479844],
              [-39.939166667807456, -8.611666659188927],
              [-39.933333334066049, -8.611666659188927],
              [-39.93333333136809, -8.610833325797273],
              [-39.930833332991881, -8.610833325797273],
              [-39.930833332991881, -8.611666659188927],
              [-39.928333333716353, -8.611666660088247],
              [-39.928333332817033, -8.610833325797273],
              [-39.922499999975003, -8.610833325797273],
              [-39.922499999075626, -8.611666659188927],
              [-39.919166665509181, -8.611666659188927],
              [-39.919166665509181, -8.609999993304996],
              [-39.918333332117527, -8.609999993304996],
              [-39.918333333016847, -8.608333326521745],
              [-39.91749999962525, -8.608333326521745],
              [-39.91749999962525, -8.607499993130148],
              [-39.915833331942679, -8.607499993130148],
              [-39.915833332841999, -8.604999992955243],
              [-39.915000000349721, -8.604999992955243],
              [-39.914999999450345, -8.604166658664326],
              [-39.914166666058748, -8.604166658664326],
              [-39.914166666958067, -8.603333325272672],
              [-39.915833332841999, -8.603333327071368],
              [-39.915833332841999, -8.602499992780395],
              [-39.918333332117527, -8.602499992780395],
              [-39.918333333916166, -8.600833326896463],
              [-39.919166666408501, -8.600833325997144],
              [-39.91916666820714, -8.599999993504866],
              [-39.919999999800098, -8.599999993504866],
              [-39.919999998900778, -8.598333325822296],
              [-39.922499999975003, -8.598333325822296],
              [-39.922500000874322, -8.597499993329961],
              [-39.925833332642128, -8.597499993329961],
              [-39.925833332642128, -8.596666659039045],
              [-39.927500001224018, -8.596666659039045],
              [-39.927500001224018, -8.594999993155113],
              [-39.928333332817033, -8.594999993155113],
              [-39.928333331917713, -8.592499992980265],
              [-39.927499998526059, -8.592499993879585],
              [-39.927499998526059, -8.591666658689292],
              [-39.928333332817033, -8.591666658689292],
              [-39.928333331917713, -8.590833326197014],
              [-39.92916666620863, -8.590833325297695],
              [-39.929166664409991, -8.58999999280536],
              [-39.930833332991881, -8.58999999280536],
              [-39.930833332092561, -8.589166658514443],
              [-39.929999999600284, -8.589166659413763],
              [-39.929999998700964, -8.585833326746581],
              [-39.931666664584839, -8.585833325847261],
              [-39.931666666383478, -8.58416665906401],
              [-39.932500000674452, -8.58416665906401],
              [-39.932499998875812, -8.582499993180079],
              [-39.93499999994998, -8.582499994079399],
              [-39.93499999905066, -8.581666658889162],
              [-39.936666667632551, -8.581666658889162],
              [-39.936666666733231, -8.580833326396828],
              [-39.937500000124885, -8.580833326396828],
              [-39.937500000124885, -8.57833332622198],
              [-39.939166666008759, -8.5783333271213],
              [-39.939166666008759, -8.575833326047132],
              [-39.942499999575261, -8.575833326047132],
              [-39.942499998675942, -8.570833325697379],
              [-39.941666664385025, -8.570833325697379],
              [-39.941666667982304, -8.569999992305782],
              [-39.942499998675942, -8.569999993205101],
              [-39.942499999575261, -8.56666665873928],
              [-39.943333334765555, -8.56666665873928],
              [-39.943333332966915, -8.567499993030196],
              [-39.944166666358512, -8.567499993030196],
              [-39.944166665459193, -8.56666665873928],
              [-39.944999998850847, -8.56666665873928],
              [-39.944999998850847, -8.565833326246945],
              [-39.945833333141763, -8.565833326246945],
              [-39.945833333141763, -8.564999992855348],
              [-39.946666667432737, -8.564999992855348],
              [-39.946666666533417, -8.561666659288846],
              [-39.947499999025695, -8.561666659288846],
              [-39.947499999025695, -8.560833325897249],
              [-39.948333334215931, -8.560833325897249],
              [-39.948333331517972, -8.559166658214679],
              [-39.949166665808946, -8.559166659113998],
              [-39.949166666708265, -8.557499993230067],
              [-39.950000000999182, -8.557499993230067],
              [-39.949999999200543, -8.554999993055162],
              [-39.949166667607585, -8.554999993954539],
              [-39.949166665808946, -8.553333326271968],
              [-39.948333334215931, -8.553333326271968],
              [-39.948333331517972, -8.551666657690077],
              [-39.946666666533417, -8.551666659488717],
              [-39.946666666533417, -8.549999991806146],
              [-39.947499999925014, -8.549999994504105],
              [-39.947499999925014, -8.549166659313812],
              [-39.950833332592197, -8.549166659313812],
              [-39.950833332592197, -8.548333325922215],
              [-39.952500001174087, -8.548333325922215],
              [-39.952499999375448, -8.546666659138964],
              [-39.953333331867725, -8.546666660038284],
              [-39.953333331867725, -8.545833326646687],
              [-39.956666665434227, -8.545833325747367],
              [-39.956666665434227, -8.544999994154352],
              [-39.958333334016118, -8.544999994154352],
              [-39.958333334016118, -8.544166658964116],
              [-39.957499997926504, -8.544166658964116],
              [-39.957499998825824, -8.543333326471782],
              [-39.956666667232867, -8.543333326471782],
              [-39.956666667232867, -8.542499993080185],
              [-39.955833332941893, -8.542499993080185],
              [-39.955833332941893, -8.541666658789211],
              [-39.955000001348935, -8.541666658789211],
              [-39.954999998650976, -8.540833326296934],
              [-39.954166667058018, -8.540833326296934],
              [-39.954166666158699, -8.53999999290528],
              [-39.951666667782433, -8.53999999290528],
              [-39.951666666883114, -8.539166659513683],
              [-39.949166666708265, -8.539166659513683],
              [-39.949166667607585, -8.538333326122029],
              [-39.947499999025695, -8.538333327021405],
              [-39.947500000824334, -8.537499992730432],
              [-39.946666665634041, -8.537499994529071],
              [-39.946666665634041, -8.536666659338835],
              [-39.945833334041083, -8.536666660238154],
              [-39.945833334041083, -8.537499991831112],
              [-39.943333332067596, -8.537499992730432],
              [-39.943333332966915, -8.535833325947181],
              [-39.942499998675942, -8.535833325947181],
              [-39.942499998675942, -8.534999991656264],
              [-39.941666667082984, -8.534999993454903],
              [-39.941666666183664, -8.53416665916393],
              [-39.94083333279201, -8.53416665916393],
              [-39.940833331892691, -8.533333326671652],
              [-39.939166666008759, -8.533333324873013],
              [-39.939166666908136, -8.532499993279998],
              [-39.938333333516482, -8.532499993279998],
              [-39.938333331717843, -8.531666658089762],
              [-39.936666664934592, -8.531666658989081],
              [-39.936666665833911, -8.530833324698108],
              [-39.93499999994998, -8.530833326496747],
              [-39.93499999905066, -8.52999999310515],
              [-39.934166667457703, -8.52999999310515],
              [-39.934166665659063, -8.529166658814233],
              [-39.933333334066049, -8.529166658814233],
              [-39.93333333136809, -8.52416665846448],
              [-39.930833332991881, -8.5241666593638],
              [-39.930833333891201, -8.522499991681229],
              [-39.931666668182174, -8.522499993479869],
              [-39.931666665484158, -8.521666659188952],
              [-39.930833332092561, -8.521666660088272],
              [-39.930833332092561, -8.520833326696618],
              [-39.929999998700964, -8.520833325797298],
              [-39.929999999600284, -8.519999993305021],
              [-39.928333331917713, -8.519999992405701],
              [-39.928333332817033, -8.519166659014047],
              [-39.927499998526059, -8.519166659014047],
              [-39.927500001224018, -8.51833332652177],
              [-39.925833331742808, -8.51833332472313],
              [-39.925833333541448, -8.517499994029436],
              [-39.92500000104917, -8.517499993130116],
              [-39.925000000149851, -8.516666658839199],
              [-39.924166666758197, -8.516666658839199],
              [-39.924166665858877, -8.515833327246185],
              [-39.921666665684029, -8.515833326346865],
              [-39.921666665684029, -8.514999992955268],
              [-39.920833331393112, -8.514999992955268],
              [-39.920833334091071, -8.514166658664294],
              [-39.91749999872593, -8.514166658664294],
              [-39.91749999962525, -8.514999992955268],
              [-39.914999999450345, -8.514999992955268],
              [-39.914999998551025, -8.515833326346865],
              [-39.912499998376177, -8.515833326346865],
              [-39.912500000174816, -8.516666657939879],
              [-39.910833332492246, -8.516666658839199],
              [-39.910833331592926, -8.515833326346865],
              [-39.91, -8.515833326346865],
              [-39.91, -8.516666659738519],
              [-39.908333333216717, -8.516666657939879],
              [-39.908333331418078, -8.515833326346865],
              [-39.907499999825063, -8.515833325447545],
              [-39.907499998925744, -8.514166658664294],
              [-39.903333331967644, -8.514166658664294],
              [-39.903333333766284, -8.514999992955268],
              [-39.904166665359298, -8.514999992955268],
              [-39.904166667157938, -8.515833326346865],
              [-39.901666667882353, -8.515833326346865],
              [-39.901666666083713, -8.516666658839199],
              [-39.898333332517211, -8.516666658839199],
              [-39.898333332517211, -8.517499993130116],
              [-39.897500000924254, -8.517499993130116],
              [-39.897499999125614, -8.516666658839199],
              [-39.895833332342363, -8.516666658839199],
              [-39.895833331443043, -8.515833326346865],
              [-39.894166665559112, -8.515833326346865],
              [-39.894166666458432, -8.514999992955268],
              [-39.891666665384264, -8.514999992955268],
              [-39.891666667182903, -8.514166658664294],
              [-39.889999999500333, -8.514166658664294],
              [-39.890000000399652, -8.513333326172017],
              [-39.888333332717082, -8.513333326172017],
              [-39.888333331817762, -8.508333325822264],
              [-39.88666666773247, -8.508333325822264],
              [-39.886666665933831, -8.506666659039013],
              [-39.887499998426165, -8.506666659039013],
              [-39.887500001124124, -8.505833325647416],
              [-39.88666666773247, -8.505833325647416],
              [-39.88666666773247, -8.504166658864165],
              [-39.885833332542234, -8.504166658864165],
              [-39.885833332542234, -8.500833326196982],
              [-39.885000000049899, -8.500833326196982],
              [-39.88499999915058, -8.499166659413731],
              [-39.883333332367329, -8.499166659413731],
              [-39.883333332367329, -8.498333326022134],
              [-39.882499998975732, -8.498333326022134],
              [-39.882499998975732, -8.496666659238883],
              [-39.881666665584135, -8.496666659238883],
              [-39.881666665584135, -8.494999994254272],
              [-39.880833332192481, -8.494999993354952],
              [-39.8808333330918, -8.494166659064035],
              [-39.879999998800884, -8.494166659963355],
              [-39.880000000599523, -8.494999993354952],
              [-39.87916666540923, -8.494999993354952],
              [-39.879166668107189, -8.496666659238883],
              [-39.877499998625979, -8.496666659238883],
              [-39.877500000424618, -8.497499993529857],
              [-39.875833332742047, -8.497499993529857],
              [-39.875833331842728, -8.498333326022134],
              [-39.867499999725169, -8.498333326022134],
              [-39.867499999725169, -8.499166659413731],
              [-39.866666667232892, -8.499166659413731],
              [-39.866666667232892, -8.499999991906066],
              [-39.865000000449641, -8.499999992805385],
              [-39.864999999550321, -8.499166659413731],
              [-39.859999999200568, -8.499166658514412],
              [-39.859999999200568, -8.498333326022134],
              [-39.859166666708234, -8.498333325122815],
              [-39.859166666708234, -8.495833325847286],
              [-39.854166668157177, -8.495833325847286],
              [-39.854166665459218, -8.494166659064035],
              [-39.854999998850815, -8.494166659064035],
              [-39.854999998850815, -8.492499993180104],
              [-39.854166666358537, -8.492499993180104],
              [-39.854166664559898, -8.49166665888913],
              [-39.853333332966884, -8.49166665888913],
              [-39.853333332067564, -8.490833326396853],
              [-39.852499998675967, -8.490833326396853],
              [-39.852500000474606, -8.489999993005199],
              [-39.851666667082952, -8.489999993005199],
              [-39.851666667082952, -8.489166658714282],
              [-39.850833331892716, -8.489166658714282],
              [-39.850833331892716, -8.4858333260471],
              [-39.849166667807424, -8.48583332694642],
              [-39.849166666908104, -8.484999992655503],
              [-39.847500001024173, -8.484999992655503],
              [-39.847500000124853, -8.483333325872252],
              [-39.846666666733256, -8.483333325872252],
              [-39.846666667632576, -8.477499993030222],
              [-39.845833331542963, -8.477499993030222],
              [-39.845833332442282, -8.474999992855317],
              [-39.844999999950005, -8.474999993754636],
              [-39.844999999050685, -8.474166659463719],
              [-39.845833332442282, -8.474166659463719],
              [-39.845833332442282, -8.473333326072066],
              [-39.844999999950005, -8.473333326072066],
              [-39.844999999950005, -8.471666659288871],
              [-39.844166666558351, -8.471666659288871],
              [-39.844166666558351, -8.46999999340494],
              [-39.844999999050685, -8.46999999430426],
              [-39.845000000849325, -8.469166659113966],
              [-39.844166665659031, -8.469166659113966],
              [-39.844166664759712, -8.468333325722369],
              [-39.842500000674477, -8.468333325722369],
              [-39.84249999887578, -8.467499993230035],
              [-39.841666665484183, -8.467499993230035],
              [-39.841666668182143, -8.466666658939118],
              [-39.840833332991906, -8.466666658939118],
              [-39.840833332991906, -8.464999993055187],
              [-39.839999998700932, -8.464999993055187],
              [-39.839999999600252, -8.463333327171256],
              [-39.838333333716321, -8.463333326271936],
              [-39.838333331917681, -8.462499992880339],
              [-39.834999998351179, -8.462499992880339],
              [-39.835000000149819, -8.461666659488685],
              [-39.834166667657541, -8.461666659488685],
              [-39.834166665858902, -8.460833326097088],
              [-39.832499998176331, -8.460833326097088],
              [-39.83250000087429, -8.459999992705434],
              [-39.83083333139308, -8.459999994504074],
              [-39.83083333139308, -8.459166660213157],
              [-39.82666666803226, -8.459166659313837],
              [-39.82666666713294, -8.458333325022863],
              [-39.82499999855105, -8.458333325922183],
              [-39.825000000349689, -8.459166660213157],
              [-39.823333332667119, -8.459166659313837],
              [-39.823333332667119, -8.454999993255058],
              [-39.822499998376202, -8.454999993255058],
              [-39.820833332492271, -8.454999993255058],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '130',
        OBJECTID: 180.0,
        Nome_area: 'Iati-Santana do Ipanema',
        COD_area: 'CA189',
        Import_bio: 'Extremamente Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Criação de UC de Uso Sustentável',
        Area_ha: 165924.633,
        Shape_Leng: 6.3,
        Shape_Area: 0.1365,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.913692247, -9.296055646999951],
              [-36.913778521, -9.29617584799996],
              [-36.915820466, -9.299020803999953],
              [-36.918058425, -9.304786600999927],
              [-36.921414952, -9.309557829999962],
              [-36.921818566, -9.311254774999949],
              [-36.921763347, -9.312217614999941],
              [-36.921752711, -9.312403077999935],
              [-36.921683367, -9.313612214999976],
              [-36.921616318, -9.314770575999944],
              [-36.922320408, -9.318165834999945],
              [-36.926762623999899, -9.322891870999966],
              [-36.927315468, -9.325411127999926],
              [-36.926788174, -9.327205856999967],
              [-36.924970296, -9.328838840999934],
              [-36.92276681, -9.329982170999928],
              [-36.920943432999898, -9.332487430999956],
              [-36.921028491, -9.335517704999976],
              [-36.922270216999898, -9.339761860999941],
              [-36.925347001, -9.343632643999973],
              [-36.930029444999903, -9.34699894799996],
              [-36.930031178, -9.346999276999947],
              [-36.935058279, -9.348749364999945],
              [-36.936551463999898, -9.34988627799993],
              [-36.937877042, -9.352821311999945],
              [-36.938402712, -9.35567699399996],
              [-36.936767593999903, -9.361214151999954],
              [-36.930437174, -9.370036326999987],
              [-36.931099563, -9.371581502999952],
              [-36.933253733, -9.374418742999975],
              [-36.934945695917129, -9.375833326246948],
              [-36.934999998750925, -9.375833326246948],
              [-36.935000000549564, -9.374999992855351],
              [-36.937499998925773, -9.374999992855351],
              [-36.937499999825093, -9.375833327146267],
              [-36.93833333141805, -9.375833326246948],
              [-36.93833333321669, -9.376666659638602],
              [-36.939166665709024, -9.376666658739282],
              [-36.939166665709024, -9.377499993929518],
              [-36.940000000899261, -9.377499993929518],
              [-36.94, -9.37916665891413],
              [-36.940833331592955, -9.37916665981345],
              [-36.940833331592955, -9.379999993205104],
              [-36.94416666605872, -9.379999993205104],
              [-36.944166667857417, -9.37916665891413],
              [-36.945000000349694, -9.37916665891413],
              [-36.944999998551054, -9.378333326421853],
              [-36.949999999800127, -9.378333326421853],
              [-36.949999998001488, -9.379999993205104],
              [-36.951666665684058, -9.379999993205104],
              [-36.951666665684058, -9.380833325697381],
              [-36.954166667657546, -9.380833324798061],
              [-36.954166665858907, -9.378333326421853],
              [-36.955000001049143, -9.378333326421853],
              [-36.954999998351184, -9.377499993030199],
              [-36.955833331742838, -9.377499993030199],
              [-36.955833332642158, -9.375833326246948],
              [-36.956666666933074, -9.375833326246948],
              [-36.956666666933074, -9.3733333260721],
              [-36.957500001224048, -9.3733333260721],
              [-36.957499999425409, -9.372499992680446],
              [-36.959166667107979, -9.372499992680446],
              [-36.959166668007299, -9.371666659288849],
              [-36.962499999775105, -9.371666660188168],
              [-36.962499997976465, -9.372499991781126],
              [-36.964166665659036, -9.372499992680446],
              [-36.964166666558356, -9.371666659288849],
              [-36.96499999905069, -9.371666659288849],
              [-36.96499999995001, -9.370833325897252],
              [-36.967119591001719, -9.370833325897252],
              [-36.969287387, -9.369048670999977],
              [-36.976059273, -9.362183768999961],
              [-36.976172812999899, -9.362068734999983],
              [-36.976952914, -9.361388411999947],
              [-36.979081182999899, -9.35953071299997],
              [-36.979640393, -9.359328250999974],
              [-36.982430452, -9.358317710999927],
              [-36.982432117, -9.358317275999987],
              [-36.987501466, -9.357271009999984],
              [-36.991265772, -9.356494091999922],
              [-36.991267449, -9.356493652999967],
              [-37.003364641, -9.352613537999957],
              [-37.006827320999903, -9.346821188999971],
              [-37.01283976, -9.339330458999939],
              [-37.016742173, -9.329875972999965],
              [-37.018377979, -9.323927492999937],
              [-37.020597338, -9.319854901999976],
              [-37.024289812999903, -9.315715039999933],
              [-37.028511455999897, -9.31401695999994],
              [-37.028513274, -9.314017018999948],
              [-37.032282364, -9.314090313999932],
              [-37.032284127, -9.314090571999943],
              [-37.0397908529999, -9.316212151999924],
              [-37.039792615, -9.31621242399997],
              [-37.043408004, -9.316335015999929],
              [-37.043409774999901, -9.316334862999947],
              [-37.045529266, -9.31588183099996],
              [-37.047524886, -9.314891291999967],
              [-37.0508491759999, -9.311623384999963],
              [-37.053063204999901, -9.308322951999978],
              [-37.054452641999909, -9.307133003999972],
              [-37.054454184, -9.307133409999954],
              [-37.055340165, -9.307447565999951],
              [-37.0572547149999, -9.312943215999953],
              [-37.057584409999897, -9.317540183999942],
              [-37.057585951, -9.317536393999934],
              [-37.059368144, -9.313151156999938],
              [-37.060788783, -9.307958225999931],
              [-37.061519049, -9.304816674999937],
              [-37.061537933999901, -9.304735434999937],
              [-37.061671224, -9.304162028999933],
              [-37.061925537, -9.297368755999958],
              [-37.058332154, -9.291345623999963],
              [-37.057199610999902, -9.281788382999936],
              [-37.063254502999897, -9.274130427999982],
              [-37.0673033099999, -9.267240253999942],
              [-37.072557226, -9.262539685],
              [-37.078113426999899, -9.256264756999931],
              [-37.080034305, -9.25470764399995],
              [-37.080035951, -9.254706626999955],
              [-37.080236860999896, -9.25454312599993],
              [-37.080904819, -9.254001641999931],
              [-37.090672549, -9.246362097999953],
              [-37.098608406, -9.244083782999956],
              [-37.106271004, -9.239783441999986],
              [-37.108173747, -9.23871558399998],
              [-37.108175296, -9.238715895999972],
              [-37.116475556999902, -9.240930649999935],
              [-37.122520118, -9.248355899999977],
              [-37.126262148, -9.252952405999961],
              [-37.129426871, -9.259104227999954],
              [-37.129918932, -9.260060848999961],
              [-37.129920328, -9.260063411999969],
              [-37.14239394, -9.268079276999966],
              [-37.142395713, -9.268079538999933],
              [-37.143032650999899, -9.268241903999989],
              [-37.143064033, -9.268249808999936],
              [-37.150986181, -9.270268163999967],
              [-37.152582068999898, -9.270674813999959],
              [-37.156528250999898, -9.273415488999941],
              [-37.156530008999901, -9.273415778999956],
              [-37.164333948, -9.275705204999946],
              [-37.164335706, -9.275705494999944],
              [-37.1693881489999, -9.272434048999978],
              [-37.169102634999902, -9.265710216999933],
              [-37.16908508400001, -9.265701508999946],
              [-37.169080182999899, -9.265591970999939],
              [-37.169061347, -9.265487806999937],
              [-37.168612025, -9.263003023999945],
              [-37.169640152443122, -9.258333326122056],
              [-37.168333333116777, -9.258333326122056],
              [-37.168333333116777, -9.257499992730459],
              [-37.169166665609112, -9.257499991831139],
              [-37.169166665609112, -9.256666659338805],
              [-37.168333334016097, -9.256666658439485],
              [-37.168333333116777, -9.252499993280026],
              [-37.16749999972518, -9.252499993280026],
              [-37.167500000593726, -9.250890355220701],
              [-37.167452296538698, -9.250833326496775],
              [-37.166666667232846, -9.250833326496775],
              [-37.166666666219278, -9.249894129722767],
              [-37.166058142831979, -9.249166658814204],
              [-37.16583333204261, -9.249166658814204],
              [-37.16583333204261, -9.24889790446127],
              [-37.165361067107057, -9.248333326321927],
              [-37.164999998650956, -9.248333326321927],
              [-37.164999998806231, -9.247901680323862],
              [-37.164169425, -9.246908754999938],
              [-37.160980312183256, -9.244166660157344],
              [-37.160833331692857, -9.24416666026309],
              [-37.160833331829245, -9.244040282040961],
              [-37.160750912, -9.243969414999974],
              [-37.160392749792216, -9.243333325972173],
              [-37.159999999200579, -9.243333325972173],
              [-37.16000000070607, -9.242635811329524],
              [-37.159923525843666, -9.242499993479896],
              [-37.159166666708245, -9.242499993479896],
              [-37.159166666708245, -9.241666659188922],
              [-37.158333331518008, -9.241666659188922],
              [-37.158333334215968, -9.239999993304991],
              [-37.157499999025674, -9.239999993304991],
              [-37.157499999025674, -9.237499993130143],
              [-37.158333331518008, -9.237499993130143],
              [-37.158333332417328, -9.236666658839169],
              [-37.160000000099899, -9.236666658839169],
              [-37.160000000099899, -9.234999992955238],
              [-37.159166666708245, -9.234999992955238],
              [-37.159166666708245, -9.233333326172044],
              [-37.156666666533397, -9.233333326172044],
              [-37.156666666533397, -9.23249999278039],
              [-37.154166666358549, -9.23249999188107],
              [-37.154166668157188, -9.231666659388793],
              [-37.153333332067575, -9.231666659388793],
              [-37.153333332067575, -9.229999993504862],
              [-37.152500000474618, -9.229999993504862],
              [-37.152499998675978, -9.228333325822291],
              [-37.151666666183644, -9.228333325822291],
              [-37.151666667982283, -9.225833325647386],
              [-37.150833331892727, -9.225833325647386],
              [-37.150833331892727, -9.223333326371858],
              [-37.149999998501073, -9.223333326371858],
              [-37.149999998501073, -9.221666658689287],
              [-37.148333332617142, -9.221666658689287],
              [-37.148333332617142, -9.219999992805356],
              [-37.147499999225545, -9.219999992805356],
              [-37.147500000124865, -9.219166659413759],
              [-37.146666665833891, -9.219166659413759],
              [-37.146666666733267, -9.216666659238911],
              [-37.145833332442294, -9.216666659238911],
              [-37.145833330643654, -9.215833325847257],
              [-37.144166665659043, -9.215833325847257],
              [-37.144166667457682, -9.214166659064006],
              [-37.145000000849336, -9.214166659064006],
              [-37.144999999950016, -9.211666658889158],
              [-37.144166666558363, -9.211666658889158],
              [-37.144166666558363, -9.208333326221975],
              [-37.143333331368126, -9.208333326221975],
              [-37.143333333166765, -9.206666659438724],
              [-37.142499999775112, -9.206666659438724],
              [-37.142499998875792, -9.203333325872222],
              [-37.136666666933081, -9.203333325872222],
              [-37.136666665134442, -9.202499993379945],
              [-37.135833332642164, -9.202499993379945],
              [-37.135833332642164, -9.201666659988348],
              [-37.13333333156794, -9.201666659088971],
              [-37.133333333366579, -9.199999994104417],
              [-37.132499998176343, -9.199999993205097],
              [-37.132499999075662, -9.200833325697374],
              [-37.131666666583328, -9.200833325697374],
              [-37.131666664784689, -9.199999993205097],
              [-37.129999998900757, -9.199999993205097],
              [-37.130000000699454, -9.199166658914123],
              [-37.128333334815522, -9.199166658914123],
              [-37.128333333916203, -9.198333326421846],
              [-37.127499998725909, -9.198333326421846],
              [-37.127499999625229, -9.197499993030192],
              [-37.12333333266713, -9.197499993030192],
              [-37.12333333266713, -9.195833325347621],
              [-37.119999999100628, -9.195833327146261],
              [-37.119999999100628, -9.197499993030192],
              [-37.119166664809711, -9.197499993030192],
              [-37.119166665709031, -9.198333326421846],
              [-37.118333331418057, -9.198333326421846],
              [-37.118333334116016, -9.197499993030192],
              [-37.11749999892578, -9.197499993030192],
              [-37.11749999892578, -9.196666658739275],
              [-37.116666665534126, -9.196666658739275],
              [-37.116666666433446, -9.195833326246941],
              [-37.115833332142529, -9.195833326246941],
              [-37.115833333041849, -9.194999992855344],
              [-37.114999998750875, -9.194999992855344],
              [-37.114166667157917, -9.194999991956024],
              [-37.114166667157917, -9.199166658914123],
              [-37.113333332867001, -9.199166658914123],
              [-37.113333331967681, -9.200833325697374],
              [-37.112500000374666, -9.200833327496014],
              [-37.112499999475347, -9.201666659088971],
              [-37.110833332692096, -9.201666659088971],
              [-37.110833331792776, -9.202499993379945],
              [-37.11166666608375, -9.202499993379945],
              [-37.111666667882389, -9.203333326771542],
              [-37.112500000374666, -9.203333325872222],
              [-37.112499998576027, -9.204166659263876],
              [-37.11333333376632, -9.204166659263876],
              [-37.113333332867001, -9.205833325147807],
              [-37.114999998750875, -9.205833326047127],
              [-37.114999998750875, -9.212499993180074],
              [-37.114166667157917, -9.212499993180074],
              [-37.114166665359278, -9.217499993529827],
              [-37.11333333376632, -9.217499993529827],
              [-37.113333332867001, -9.218333326022105],
              [-37.110833332692096, -9.218333326022105],
              [-37.110833332692096, -9.22083332619701],
              [-37.105833331443023, -9.22083332619701],
              [-37.105833331443023, -9.221666658689287],
              [-37.104999998950746, -9.221666658689287],
              [-37.104999999850065, -9.220833327096329],
              [-37.104166667357788, -9.22083332619701],
              [-37.104166666458468, -9.219166659413759],
              [-37.103333333066814, -9.219166660313078],
              [-37.103333332167495, -9.216666659238911],
              [-37.104166667357788, -9.216666659238911],
              [-37.104166665559148, -9.214166659064006],
              [-37.104999998950746, -9.214166659064006],
              [-37.104999998950746, -9.213333325672409],
              [-37.104166667357788, -9.213333324773089],
              [-37.104166665559148, -9.212499994079394],
              [-37.103333332167495, -9.212499992280755],
              [-37.103333332167495, -9.20916665871431],
              [-37.102499998775897, -9.20916665871431],
              [-37.102499998775897, -9.208333325322656],
              [-37.098333332717061, -9.208333326221975],
              [-37.098333332717061, -9.207499992830378],
              [-37.095833332542213, -9.207499992830378],
              [-37.095833332542213, -9.206666659438724],
              [-37.095000000949256, -9.206666659438724],
              [-37.094999999150616, -9.207499992830378],
              [-37.092500000774351, -9.207499992830378],
              [-37.092499999875031, -9.208333326221975],
              [-37.089999998800863, -9.208333325322656],
              [-37.089999998800863, -9.20916665871431],
              [-37.085833331842764, -9.209166657814933],
              [-37.085833331842764, -9.208333325322656],
              [-37.083333333466499, -9.208333326221975],
              [-37.082499997376942, -9.208333326221975],
              [-37.082500000074901, -9.207499992830378],
              [-37.079166666508399, -9.207500002722895],
              [-37.075833332941897, -9.207499992830378],
              [-37.075833333841217, -9.206666659438724],
              [-37.0749999995503, -9.206666659438724],
              [-37.074999998650981, -9.205833326047127],
              [-37.074166667058023, -9.205833326047127],
              [-37.074166667058023, -9.204166659263876],
              [-37.073333332767049, -9.204166659263876],
              [-37.07333333186773, -9.203333326771542],
              [-37.072499999375452, -9.203333325872222],
              [-37.072500001174092, -9.201666659088971],
              [-37.071666665983798, -9.201666659088971],
              [-37.071666667782438, -9.199999993205097],
              [-37.069999999200547, -9.199999993205097],
              [-37.070000000099867, -9.199166658914123],
              [-37.067499999925019, -9.199166658914123],
              [-37.067500000824339, -9.199999993205097],
              [-37.065833334041088, -9.199999994104417],
              [-37.065833334041088, -9.199166659813443],
              [-37.064166665459197, -9.199166658914123],
              [-37.064166666358517, -9.198333326421846],
              [-37.059999999400418, -9.198333326421846],
              [-37.059999999400418, -9.196666658739275],
              [-37.059166666008764, -9.196666658739275],
              [-37.059166666908084, -9.194166660363067],
              [-37.05750000012489, -9.19416665766505],
              [-37.057499999225513, -9.194999992855344],
              [-37.056666666733236, -9.194999992855344],
              [-37.056666666733236, -9.19416665946369],
              [-37.055000000849304, -9.194166660363067],
              [-37.055000000849304, -9.193333326072093],
              [-37.054166667457707, -9.193333326072093],
              [-37.054166666558388, -9.192499992680496],
              [-37.053333331368094, -9.192499992680496],
              [-37.053333331368094, -9.190833325897245],
              [-37.054166666558388, -9.190833325897245],
              [-37.054166667457707, -9.189166659113994],
              [-37.052499999775137, -9.189166659113994],
              [-37.052499998875817, -9.188333327520979],
              [-37.051666664584843, -9.18833332572234],
              [-37.051666668182122, -9.187499994129382],
              [-37.050833332092566, -9.187499993230063],
              [-37.050833332991886, -9.185833326446811],
              [-37.048333331917718, -9.185833324648172],
              [-37.048333332817037, -9.184999992155895],
              [-37.045833331742813, -9.184999993055214],
              [-37.045833331742813, -9.185833327346131],
              [-37.044166667657521, -9.185833324648172],
              [-37.044166666758201, -9.184999992155895],
              [-37.04249999997495, -9.184999993055214],
              [-37.04250000087427, -9.184166658764241],
              [-37.041666667482673, -9.184166658764241],
              [-37.041666665684033, -9.183333326271963],
              [-37.040833333191756, -9.183333326271963],
              [-37.040833333191756, -9.182499992880309],
              [-37.034999998551029, -9.182499992880309],
              [-37.034999999450349, -9.181666659488712],
              [-37.033333331767778, -9.181666659488712],
              [-37.033333331767778, -9.180833326097058],
              [-37.027499999825068, -9.180833326996378],
              [-37.027499999825068, -9.181666659488712],
              [-37.025000000549539, -9.181666659488712],
              [-37.02499999965022, -9.180833326097058],
              [-37.023333332866969, -9.180833326996378],
              [-37.023333332866969, -9.179999992705461],
              [-37.021666667882357, -9.179999991806142],
              [-37.021666666083718, -9.179166660213127],
              [-37.019999998401147, -9.179166659313807],
              [-37.019999998401147, -9.179999992705461],
              [-37.019166666808189, -9.179999992705461],
              [-37.019166667707509, -9.179166659313807],
              [-37.016666665734022, -9.179166659313807],
              [-37.016666665734022, -9.179999992705461],
              [-37.014999998950771, -9.179999992705461],
              [-37.013333333966159, -9.179999992705461],
              [-37.013333333966159, -9.179166660213127],
              [-37.010000000399657, -9.179166660213127],
              [-37.009999999500337, -9.179999992705461],
              [-37.009166665209364, -9.179999992705461],
              [-37.00916666700806, -9.180833326097058],
              [-37.009999999500337, -9.180833326097058],
              [-37.009999999500337, -9.183333326271963],
              [-37.011666668082228, -9.183333326271963],
              [-37.011666665384269, -9.184166658764241],
              [-37.012499998775866, -9.184166658764241],
              [-37.012499998775866, -9.184999993055214],
              [-37.013333333966159, -9.184999993055214],
              [-37.013333333066839, -9.186666658939089],
              [-37.014166666458436, -9.186666658939089],
              [-37.014166665559117, -9.187499994129382],
              [-37.014166666458436, -9.189166659113994],
              [-37.013333333066839, -9.189166659113994],
              [-37.013333333066839, -9.189999993404911],
              [-37.012499999675185, -9.189999993404911],
              [-37.012499998775866, -9.190833325897245],
              [-37.011666668082228, -9.190833325897245],
              [-37.011666667182908, -9.191666659288842],
              [-37.010833332891934, -9.191666659288842],
              [-37.010833332891934, -9.190833325897245],
              [-37.002499998975736, -9.190833325897245],
              [-37.002500000774376, -9.189999993404911],
              [-36.999166667207874, -9.189999992505591],
              [-36.999166665409234, -9.189166659113994],
              [-36.998333332916957, -9.189166658214674],
              [-36.998333332017637, -9.18833332572234],
              [-36.995833333641372, -9.18833332572234],
              [-36.995833331842732, -9.187499993230063],
              [-36.994999999350455, -9.187499993230063],
              [-36.994999998451135, -9.186666658939089],
              [-36.99249999917555, -9.186666658939089],
              [-36.992500000974246, -9.185833326446811],
              [-36.986666668132159, -9.18583333274205],
              [-36.9866666654342, -9.184999993055214],
              [-36.985833332042603, -9.184999993055214],
              [-36.985833331143283, -9.180833326097058],
              [-36.984999999550269, -9.180833326097058],
              [-36.985000000449645, -9.179999994504101],
              [-36.983333332767074, -9.179999992705461],
              [-36.983333332767074, -9.179166658414488],
              [-36.98249999937542, -9.179166659313807],
              [-36.982499998476101, -9.178333325022891],
              [-36.977499999025667, -9.17833332592221],
              [-36.977499999025667, -9.179166659313807],
              [-36.974999999750139, -9.179166659313807],
              [-36.974999999750139, -9.17833332682153],
              [-36.97083333369136, -9.17833332592221],
              [-36.97083333279204, -9.177499993429933],
              [-36.969166666908109, -9.177499994329253],
              [-36.969166667807428, -9.176666659138959],
              [-36.968333331717815, -9.176666659138959],
              [-36.968333332617135, -9.175833325747362],
              [-36.967500000124858, -9.175833325747362],
              [-36.967500000124858, -9.174166658964111],
              [-36.968333331717815, -9.174166658964111],
              [-36.968333333516512, -9.173333326471777],
              [-36.967500001024177, -9.173333326471777],
              [-36.967500001024177, -9.169999992905275],
              [-36.966666666733261, -9.169999992905275],
              [-36.966666665833941, -9.168333326122081],
              [-36.968333333516512, -9.168333326122081],
              [-36.968333331717815, -9.169166657715039],
              [-36.969166666008789, -9.169166660412998],
              [-36.969166666908109, -9.168333326122081],
              [-36.970000001199026, -9.168333326122081],
              [-36.969999999400386, -9.167499992730427],
              [-36.965833332442287, -9.167499992730427],
              [-36.965833332442287, -9.16666665933883],
              [-36.965000000849329, -9.16666665933883],
              [-36.965000000849329, -9.165833325947176],
              [-36.963333334066078, -9.165833325947176],
              [-36.963333334066078, -9.164999993454899],
              [-36.962499999775105, -9.164999993454899],
              [-36.962499999775105, -9.164166659163925],
              [-36.961666668182147, -9.164166659163925],
              [-36.961666666383508, -9.163333325772328],
              [-36.963333330468799, -9.163333325772328],
              [-36.963333333166759, -9.1608333264968],
              [-36.964166665659036, -9.1608333264968],
              [-36.964166666558356, -9.158333325422575],
              [-36.96499999905069, -9.158333325422575],
              [-36.96499999815137, -9.156666658639324],
              [-36.966666666733261, -9.156666657740004],
              [-36.96666666763258, -9.155833327046366],
              [-36.967500001024177, -9.155833326147047],
              [-36.967500001024177, -9.154999992755393],
              [-36.968333332617135, -9.154999992755393],
              [-36.968333332617135, -9.153333325972142],
              [-36.969999998501066, -9.153333325972142],
              [-36.969999999400386, -9.149999993305016],
              [-36.97083333189272, -9.149999993305016],
              [-36.97083333189272, -9.147499993130111],
              [-36.969166666908109, -9.147499993130111],
              [-36.969166667807428, -9.146666658839194],
              [-36.968333332617135, -9.146666657939875],
              [-36.968333332617135, -9.14583332634686],
              [-36.967499999225538, -9.145833325447541],
              [-36.967499999225538, -9.144999992955263],
              [-36.966666665833941, -9.144999992955263],
              [-36.966666666733261, -9.144166658664346],
              [-36.964166665659036, -9.144166658664346],
              [-36.964166667457675, -9.142499992780415],
              [-36.963333333166759, -9.142499992780415],
              [-36.963333333166759, -9.140833326896484],
              [-36.962500000674424, -9.140833325997164],
              [-36.962499997976465, -9.139166659213913],
              [-36.961666666383508, -9.139166659213913],
              [-36.961666665484188, -9.138333326721579],
              [-36.960833332991854, -9.138333326721579],
              [-36.960833332092534, -9.135833325647411],
              [-36.961666665484188, -9.135833325647411],
              [-36.961666666383508, -9.132499992080909],
              [-36.962499998875785, -9.132499992980229],
              [-36.962499998875785, -9.131666658689312],
              [-36.961666664584868, -9.131666658689312],
              [-36.961666665484188, -9.130833326196978],
              [-36.962499998875785, -9.130833325297658],
              [-36.962499999775105, -9.129999992805381],
              [-36.963333331368119, -9.129999992805381],
              [-36.963333333166759, -9.12833332602213],
              [-36.962499999775105, -9.12833332602213],
              [-36.962499998875785, -9.127499993529852],
              [-36.961666665484188, -9.127499993529852],
              [-36.961666669081467, -9.126666659238879],
              [-36.960833332092534, -9.126666659238879],
              [-36.96083333389123, -9.125833325847282],
              [-36.959999998700937, -9.125833325847282],
              [-36.959999998700937, -9.124999993354947],
              [-36.959166668007299, -9.124999994254267],
              [-36.959166668007299, -9.122499993180099],
              [-36.958333331917686, -9.122499993180099],
              [-36.958333332817006, -9.121666658889126],
              [-36.957499999425409, -9.121666658889126],
              [-36.957499998526089, -9.120833326396848],
              [-36.955833331742838, -9.120833326396848],
              [-36.955833333541477, -9.119166658714278],
              [-36.954999998351184, -9.119166658714278],
              [-36.955000000149823, -9.118333325322681],
              [-36.953333331567933, -9.118333326222],
              [-36.953333331567933, -9.117499992830346],
              [-36.952499999075656, -9.117499992830346],
              [-36.952500000874295, -9.115833325147776],
              [-36.951666666583378, -9.115833326047095],
              [-36.951666666583378, -9.114999994454138],
              [-36.950833331393085, -9.114999992655498],
              [-36.950833334091044, -9.113333326771567],
              [-36.949999999800127, -9.113333326771567],
              [-36.949999999800127, -9.110833325697399],
              [-36.949166666408473, -9.110833325697399],
              [-36.949166668207113, -9.109166658914148],
              [-36.947500000524542, -9.109166658914148],
              [-36.947499998725903, -9.107499993030217],
              [-36.946666668032265, -9.107499993030217],
              [-36.946666667132945, -9.106666657839924],
              [-36.945833333741291, -9.106666658739243],
              [-36.945833331043332, -9.105833325347646],
              [-36.945000002148333, -9.105833326246966],
              [-36.944999998551054, -9.104999992855312],
              [-36.944166666958097, -9.104999992855312],
              [-36.94416666605872, -9.104166659463715],
              [-36.943333331767803, -9.104166659463715],
              [-36.943333331767803, -9.102499992680464],
              [-36.941666667682512, -9.102499992680464],
              [-36.941666665883872, -9.101666659288867],
              [-36.940833332492275, -9.101666659288867],
              [-36.940833331592955, -9.099999993404936],
              [-36.939999998201301, -9.099999993404936],
              [-36.940000000899261, -9.099166659113962],
              [-36.939166665709024, -9.099166659113962],
              [-36.939166668406983, -9.096666658939114],
              [-36.936666668232135, -9.096666659838434],
              [-36.936666668232135, -9.094999993055183],
              [-36.933333334665633, -9.094999993055183],
              [-36.933333332866994, -9.094166658764266],
              [-36.93250000037466, -9.094166658764266],
              [-36.93249999947534, -9.093333326271932],
              [-36.929166666808158, -9.093333326271932],
              [-36.929166665908838, -9.094166659663586],
              [-36.928333331617921, -9.094166658764266],
              [-36.92833333341656, -9.094999993055183],
              [-36.927500000024963, -9.094999993055183],
              [-36.927499999125644, -9.095833326446837],
              [-36.926666666633309, -9.09583332464814],
              [-36.925833332342393, -9.095833326446837],
              [-36.925833334141032, -9.094999993954502],
              [-36.924999998950739, -9.094999993055183],
              [-36.924999999850058, -9.094166658764266],
              [-36.923333333966127, -9.094166658764266],
              [-36.923333332167488, -9.093333326271932],
              [-36.922499998775891, -9.093333326271932],
              [-36.92250000057453, -9.092499991981015],
              [-36.92083333199264, -9.092499992880335],
              [-36.92083333199264, -9.093333326271932],
              [-36.919999998600986, -9.093333326271932],
              [-36.919999999500305, -9.094166658764266],
              [-36.919166666108708, -9.094166658764266],
              [-36.919166667008028, -9.093333326271932],
              [-36.914999999150609, -9.093333325372612],
              [-36.915000000949249, -9.094166658764266],
              [-36.912499998076385, -9.094166658764266],
              [-36.912499998975704, -9.093333326271932],
              [-36.911666667382747, -9.093333327171251],
              [-36.911666665584107, -9.092499992880335],
              [-36.906666664335035, -9.092499993779654],
              [-36.906666667032994, -9.091666659488681],
              [-36.905833332742077, -9.091666659488681],
              [-36.905833332742077, -9.090833326996403],
              [-36.905000001149062, -9.090833326097083],
              [-36.904999997551784, -9.086666659138984],
              [-36.904166666858146, -9.086666659138984],
              [-36.904166665958826, -9.08583332574733],
              [-36.903333331667852, -9.085833324848011],
              [-36.903333331667852, -9.084166658964079],
              [-36.904166666858146, -9.084166658964079],
              [-36.904166665958826, -9.084999993255053],
              [-36.904999998451103, -9.084999993255053],
              [-36.905000001149062, -9.08583332574733],
              [-36.906666666133674, -9.085833324848011],
              [-36.906666667932313, -9.086666659138984],
              [-36.91083333219251, -9.086666659138984],
              [-36.91083333309183, -9.08583332574733],
              [-36.915833331642887, -9.08583332574733],
              [-36.915833331642887, -9.084999994154373],
              [-36.91666666593386, -9.084999993255053],
              [-36.91666666593386, -9.084166658964079],
              [-36.917500000224777, -9.084166658964079],
              [-36.917499998426138, -9.083333327371122],
              [-36.919166667907348, -9.083333326471802],
              [-36.919166667008028, -9.082499993080148],
              [-36.920833333791279, -9.082499993080148],
              [-36.920833332891959, -9.081666658789231],
              [-36.92249999967521, -9.081666658789231],
              [-36.92250000057453, -9.080833326296897],
              [-36.923333333066807, -9.080833327196274],
              [-36.923333333966127, -9.079166659513703],
              [-36.924999999850058, -9.079166659513703],
              [-36.925000000749378, -9.078333327021369],
              [-36.925833331443073, -9.078333326122049],
              [-36.925833332342393, -9.076666659338798],
              [-36.926666666633309, -9.076666659338798],
              [-36.926666666633309, -9.075833326846521],
              [-36.928333332517241, -9.075833325947201],
              [-36.928333331617921, -9.074999993454867],
              [-36.930833332692089, -9.074999993454867],
              [-36.930833331792769, -9.07416665916395],
              [-36.931666667882382, -9.07416665916395],
              [-36.931666666083743, -9.073333324872976],
              [-36.93416666805723, -9.073333325772296],
              [-36.934166667157911, -9.072499993280019],
              [-36.935000000549564, -9.072499993280019],
              [-36.934999998750925, -9.071666658989102],
              [-36.936666668232135, -9.071666658989102],
              [-36.936666666433439, -9.070833326496768],
              [-36.937499998925773, -9.070833326496768],
              [-36.937500000724413, -9.069999993105171],
              [-36.93833333411601, -9.069999993105171],
              [-36.93833333141805, -9.069166658814197],
              [-36.939166666608344, -9.069166657914877],
              [-36.939166666608344, -9.068333327221239],
              [-36.940000000899261, -9.06833332632192],
              [-36.94, -9.067499992930266],
              [-36.939166665709024, -9.067499993829585],
              [-36.939166667507664, -9.066666658639349],
              [-36.93833333411601, -9.066666658639349],
              [-36.93833333411601, -9.067499993829585],
              [-36.933333333766313, -9.067499992930266],
              [-36.933333332866994, -9.066666660437988],
              [-36.93249999857602, -9.066666657740029],
              [-36.93249999947534, -9.065833326147015],
              [-36.931666666083743, -9.065833325247695],
              [-36.931666667882382, -9.063333325972167],
              [-36.930833331792769, -9.063333325972167],
              [-36.930833333591409, -9.062499993479889],
              [-36.929166665908838, -9.062499994379209],
              [-36.929166665908838, -9.061666659188916],
              [-36.928333332517241, -9.061666659188916],
              [-36.928333331617921, -9.060833325797319],
              [-36.927500000924283, -9.060833325797319],
              [-36.927499999125644, -9.059999993304984],
              [-36.924999999850058, -9.059999992405665],
              [-36.925000000749378, -9.059166659014068],
              [-36.924166667357781, -9.059166658114748],
              [-36.924166666458461, -9.057499993130136],
              [-36.925833332342393, -9.057499993130136],
              [-36.925833331443073, -9.056666657939843],
              [-36.927499999125644, -9.056666658839163],
              [-36.927499999125644, -9.054999992955288],
              [-36.929166667707477, -9.054999993854608],
              [-36.929166668606854, -9.054166658664315],
              [-36.929999997501852, -9.054166658664315],
              [-36.930000000199811, -9.053333326172037],
              [-36.930833332692089, -9.053333325272718],
              [-36.930833331792769, -9.052499993679703],
              [-36.931666666983062, -9.052499992780383],
              [-36.931666666083743, -9.051666658489466],
              [-36.93249999857602, -9.051666659388786],
              [-36.93249999857602, -9.050833325997132],
              [-36.930833332692089, -9.050833325997132],
              [-36.930833332692089, -9.051666659388786],
              [-36.929999998401172, -9.051666659388786],
              [-36.930000000199811, -9.052499991881064],
              [-36.929166666808158, -9.052499992780383],
              [-36.929166667707477, -9.053333326172037],
              [-36.927500000024963, -9.053333326172037],
              [-36.927500000024963, -9.052499992780383],
              [-36.926666666633309, -9.052499993679703],
              [-36.926666666633309, -9.049166660113201],
              [-36.925833331443073, -9.049166659213881],
              [-36.925833332342393, -9.047499993330007],
              [-36.925000000749378, -9.047499993330007],
              [-36.924999998950739, -9.045833325647436],
              [-36.924166665559142, -9.045833325647436],
              [-36.924166666458461, -9.044999993155102],
              [-36.923333333966127, -9.044999993155102],
              [-36.923333333066807, -9.044166658864185],
              [-36.92249999967521, -9.044166658864185],
              [-36.92249999967521, -9.042499992980254],
              [-36.921666667182876, -9.042499992980254],
              [-36.921666668082196, -9.04166665868928],
              [-36.920000000399682, -9.04166666048792],
              [-36.919999998600986, -9.039999991906029],
              [-36.91666666683318, -9.039999993704726],
              [-36.91666666593386, -9.038333326022155],
              [-36.915833331642887, -9.038333326022155],
              [-36.915833332542206, -9.037499993529821],
              [-36.914999999150609, -9.037499993529821],
              [-36.915000000949249, -9.036666659238904],
              [-36.914166665758955, -9.036666659238904],
              [-36.914166667557595, -9.03583332584725],
              [-36.913333334165998, -9.03583332584725],
              [-36.913333331468039, -9.034166659063999],
              [-36.912499999875024, -9.034166659063999],
              [-36.912500000774401, -9.033333325672402],
              [-36.911666667382747, -9.033333325672402],
              [-36.911666665584107, -9.032499993180068],
              [-36.910833330393814, -9.032499993180068],
              [-36.91083333309183, -9.031666657989831],
              [-36.908333331118286, -9.031666658889151],
              [-36.908333333816245, -9.030833326396873],
              [-36.904999999350423, -9.030833326396873],
              [-36.905000001149062, -9.0299999921059],
              [-36.901666667582617, -9.0299999921059],
              [-36.901666666683298, -9.029166658714303],
              [-36.900833331493004, -9.029166658714303],
              [-36.900833332392324, -9.028333326221968],
              [-36.899999999900047, -9.028333327121288],
              [-36.900000000799366, -9.029166657814983],
              [-36.897499998825822, -9.029166660512942],
              [-36.897499998825822, -9.028333326221968],
              [-36.895833332042571, -9.028333327121288],
              [-36.895833332042571, -9.027499992830371],
              [-36.894999999550294, -9.027499992830371],
              [-36.894999998650974, -9.028333326221968],
              [-36.893333332767043, -9.028333326221968],
              [-36.893333331867723, -9.027499992830371],
              [-36.890833332592194, -9.027499992830371],
              [-36.890833332592194, -9.026666659438717],
              [-36.889166666708263, -9.026666659438717],
              [-36.889166665808943, -9.027499992830371],
              [-36.887499999025692, -9.027499992830371],
              [-36.887499999925012, -9.028333327121288],
              [-36.884999998850844, -9.028333326221968],
              [-36.884999999750164, -9.029166658714303],
              [-36.883333332966913, -9.029166658714303],
              [-36.883333332067593, -9.029999993005219],
              [-36.882499999575259, -9.029999993005219],
              [-36.882500000474579, -9.030833326396873],
              [-36.881666667982302, -9.030833325497554],
              [-36.881666667982302, -9.031666658889151],
              [-36.880833331892688, -9.031666658889151],
              [-36.880833332792008, -9.032499993180068],
              [-36.879999998501091, -9.032499993180068],
              [-36.879999999400411, -9.033333324773082],
              [-36.879166666008814, -9.033333325672402],
              [-36.879166667807453, -9.034166659063999],
              [-36.87833333351648, -9.034166659963319],
              [-36.87833333351648, -9.034999993354973],
              [-36.877499999225563, -9.034999992455653],
              [-36.877500001024202, -9.03583332584725],
              [-36.87833333171784, -9.03583332584725],
              [-36.878333330818521, -9.040833326197003],
              [-36.877499999225563, -9.040833326197003],
              [-36.877500000124883, -9.04166665868928],
              [-36.875833331542992, -9.04166665868928],
              [-36.875833331542992, -9.042499992080934],
              [-36.874999999050658, -9.042499992980254],
              [-36.874999999050658, -9.043333326371851],
              [-36.872500000674449, -9.043333326371851],
              [-36.872500000674449, -9.042499992080934],
              [-36.871666665484156, -9.042499992980254],
              [-36.871666668182172, -9.043333327271171],
              [-36.870833332991879, -9.043333326371851],
              [-36.870833332092559, -9.044166658864185],
              [-36.870000000499601, -9.044166658864185],
              [-36.869999999600282, -9.044999993155102],
              [-36.869166666208628, -9.044999993155102],
              [-36.869166666208628, -9.045833325647436],
              [-36.868333332817031, -9.045833325647436],
              [-36.868333331917711, -9.046666658139713],
              [-36.867499997626737, -9.046666659039033],
              [-36.86666666603378, -9.046666659039033],
              [-36.86666666603378, -9.045833325647436],
              [-36.865833332642126, -9.045833325647436],
              [-36.865833333541445, -9.044999993155102],
              [-36.865000001049168, -9.044999993155102],
              [-36.865000000149848, -9.044166658864185],
              [-36.863333331567958, -9.044166658864185],
              [-36.863333331567958, -9.044999993155102],
              [-36.861666667482666, -9.044999993155102],
              [-36.861666666583346, -9.045833325647436],
              [-36.858333332117525, -9.045833325647436],
              [-36.858333332117525, -9.044999992255782],
              [-36.857499999625247, -9.044999993155102],
              [-36.857499999625247, -9.044166658864185],
              [-36.856666664434954, -9.044166658864185],
              [-36.856666667132913, -9.043333326371851],
              [-36.855000000349719, -9.043333326371851],
              [-36.854999998551023, -9.042499992980254],
              [-36.852499998376175, -9.042499992980254],
              [-36.852499998376175, -9.04166665868928],
              [-36.851666666783217, -9.04166665868928],
              [-36.851666665883897, -9.040833326197003],
              [-36.849166666608312, -9.040833326197003],
              [-36.849166667507632, -9.039999991906029],
              [-36.848333333216715, -9.039999992805349],
              [-36.848333333216715, -9.039166659413752],
              [-36.844999999650213, -9.039166659413752],
              [-36.844999998750893, -9.038333326022155],
              [-36.843333331967642, -9.038333325122835],
              [-36.843333331967642, -9.037499993529821],
              [-36.842500000374685, -9.037499991731181],
              [-36.842499999475365, -9.036666660138224],
              [-36.841666666083711, -9.036666658339584],
              [-36.841666666983031, -9.03583332674657],
              [-36.84000000019978, -9.03583332584725],
              [-36.84000000019978, -9.034999993354973],
              [-36.839166666808183, -9.034999992455653],
              [-36.839166667707502, -9.034166658164679],
              [-36.838333331617889, -9.034166659063999],
              [-36.838333333416585, -9.033333325672402],
              [-36.837499999125612, -9.033333325672402],
              [-36.837500000924251, -9.032499993180068],
              [-36.835833330543721, -9.032499993180068],
              [-36.835833334141, -9.030833325497554],
              [-36.836666665734015, -9.030833326396873],
              [-36.836666665734015, -9.029166658714303],
              [-36.835833331443041, -9.029166658714303],
              [-36.835833332342361, -9.027499992830371],
              [-36.834999999850083, -9.027499992830371],
              [-36.834999998950764, -9.026666659438717],
              [-36.834166667357749, -9.026666659438717],
              [-36.834166667357749, -9.024166659263869],
              [-36.833333332167513, -9.024166660163189],
              [-36.833333333966152, -9.021666659089021],
              [-36.831666668082221, -9.021666659089021],
              [-36.831666665384262, -9.020833325697367],
              [-36.830833332891984, -9.020833325697367],
              [-36.830833333791304, -9.019166658914116],
              [-36.827499998426163, -9.019166658014797],
              [-36.827499998426163, -9.018333326421839],
              [-36.825833332542231, -9.018333325522519],
              [-36.825833333441551, -9.019166658914116],
              [-36.824999999150577, -9.019166658914116],
              [-36.825000000949217, -9.018333326421839],
              [-36.8241666666583, -9.018333326421839],
              [-36.82416666575898, -9.017499993030185],
              [-36.823333332367326, -9.017499993030185],
              [-36.823333331468007, -9.016666658739268],
              [-36.822499999875049, -9.016666658739268],
              [-36.822499998975729, -9.01416665946374],
              [-36.821666665584132, -9.014166657665101],
              [-36.821666666483452, -9.012499992680489],
              [-36.820833333091798, -9.012499992680489],
              [-36.820833332192478, -9.006666658939139],
              [-36.82000000149884, -9.006666658939139],
              [-36.819999998800881, -9.004166658764234],
              [-36.81833333291695, -9.004166658764234],
              [-36.81833333291695, -9.003333326271957],
              [-36.815833332742045, -9.003333326271957],
              [-36.815833332742045, -9.002499992880303],
              [-36.814166665958851, -9.002499992880303],
              [-36.814166665958851, -9.001666659488706],
              [-36.813333331667877, -9.001666659488706],
              [-36.813333333466517, -9.000833326097052],
              [-36.81249999737696, -9.000833326996371],
              [-36.81249999737696, -8.999999992705455],
              [-36.807499998825847, -8.999999992705455],
              [-36.807499999725167, -8.999166660213177],
              [-36.806666664534873, -8.999166659313858],
              [-36.806666667232889, -8.998333325922204],
              [-36.804999998650999, -8.998333325922204],
              [-36.805000000449638, -8.997499993429926],
              [-36.805000000449638, -8.996666659138953],
              [-36.805833332941916, -8.996666659138953],
              [-36.805833332042596, -8.995833324848036],
              [-36.806666667232889, -8.995833324848036],
              [-36.806666667232889, -8.992499992180853],
              [-36.807499998825847, -8.992499993979493],
              [-36.807500000624486, -8.9916666587892],
              [-36.808333334915403, -8.991666659688519],
              [-36.808333334016083, -8.989999992905325],
              [-36.809166666508418, -8.989999992905325],
              [-36.809166667407737, -8.988333326122074],
              [-36.807500000624486, -8.988333327021394],
              [-36.807500000624486, -8.98749999273042],
              [-36.806666665434193, -8.98749999273042],
              [-36.806666664534873, -8.986666659338823],
              [-36.805833332941916, -8.986666660238143],
              [-36.805833332941916, -8.985833325947169],
              [-36.805000000449638, -8.985833325947169],
              [-36.804999998650999, -8.984999993454892],
              [-36.804166667957304, -8.984999994354212],
              [-36.804166666158665, -8.983333325772321],
              [-36.803333331867748, -8.983333325772321],
              [-36.803333331867748, -8.982499993280044],
              [-36.802499998476094, -8.982499993280044],
              [-36.802499999375414, -8.98166665898907],
              [-36.801666666883136, -8.98166665898907],
              [-36.801666665084497, -8.980833324698153],
              [-36.800833331692843, -8.980833326496793],
              [-36.800833332592163, -8.979166657914902],
              [-36.800000000999205, -8.979166658814222],
              [-36.800000000999205, -8.978333326321888],
              [-36.799166668506928, -8.978333326321888],
              [-36.799166665808912, -8.974999991856066],
              [-36.798333332417315, -8.974999992755443],
              [-36.798333332417315, -8.974166659363789],
              [-36.797500000824357, -8.974166659363789],
              [-36.797499999925037, -8.972499991681218],
              [-36.796666665634064, -8.972499993479857],
              [-36.796666666533383, -8.969166659014036],
              [-36.795833333141786, -8.969166659014036],
              [-36.795833333141786, -8.967499993130104],
              [-36.794999999750132, -8.967499993130104],
              [-36.794999998850813, -8.966666658839188],
              [-36.794166665459215, -8.966666658839188],
              [-36.794166665459215, -8.964999992955256],
              [-36.795833331343147, -8.964999992955256],
              [-36.795833331343147, -8.961666659388754],
              [-36.796666666533383, -8.961666659388754],
              [-36.796666666533383, -8.960833325997157],
              [-36.797500000824357, -8.960833325997157],
              [-36.797499999925037, -8.959999993504823],
              [-36.798333330618675, -8.959999993504823],
              [-36.798333330618675, -8.959166659213906],
              [-36.799999999200566, -8.959166659213906],
              [-36.799999999200566, -8.958333325822309],
              [-36.800833333491482, -8.958333325822309],
              [-36.800833331692843, -8.956666658139739],
              [-36.802500002073373, -8.956666659039058],
              [-36.802499999375414, -8.955833325647404],
              [-36.803333332767068, -8.955833325647404],
              [-36.803333331867748, -8.954999993155127],
              [-36.804166667057984, -8.954999993155127],
              [-36.804166666158665, -8.954166658864153],
              [-36.805000000449638, -8.954166658864153],
              [-36.804999998650999, -8.950833326197028],
              [-36.804166667057984, -8.950833325297651],
              [-36.804166667057984, -8.949999992805374],
              [-36.802499999375414, -8.949999992805374],
              [-36.802499999375414, -8.949166659413777],
              [-36.801666667782456, -8.949166659413777],
              [-36.801666666883136, -8.948333326022123],
              [-36.800833331692843, -8.948333326022123],
              [-36.800833330793523, -8.947499993529846],
              [-36.799999999200566, -8.947499993529846],
              [-36.800000000099885, -8.946666659238872],
              [-36.799166666708231, -8.946666658339552],
              [-36.799166665808912, -8.945833326746595],
              [-36.795833334041106, -8.945833325847275],
              [-36.795833334041106, -8.940833326396842],
              [-36.794999999750132, -8.940833326396842],
              [-36.794999999750132, -8.939999993005245],
              [-36.793333332966881, -8.939999993005245],
              [-36.793333332966881, -8.939166658714271],
              [-36.792499998675964, -8.939166658714271],
              [-36.792499999575284, -8.938333326221994],
              [-36.791666667982327, -8.938333326221994],
              [-36.79166666618363, -8.935833326047089],
              [-36.790000001199076, -8.935833326047089],
              [-36.78999999850106, -8.932499993379963],
              [-36.789166666908102, -8.932499994279283],
              [-36.789166666008782, -8.930833325697392],
              [-36.788333331717865, -8.930833325697392],
              [-36.788333332617185, -8.929999993205058],
              [-36.786666665833934, -8.929999994104378],
              [-36.786666667632574, -8.926666658739236],
              [-36.78583333244228, -8.926666658739236],
              [-36.7858333333416, -8.925833326246959],
              [-36.783333331368112, -8.925833326246959],
              [-36.783333331368112, -8.924999992855362],
              [-36.781666666383501, -8.924999992855362],
              [-36.781666665484181, -8.923333326072111],
              [-36.782499998875778, -8.923333325172791],
              [-36.782499999775098, -8.922499992680457],
              [-36.781666664584861, -8.922499992680457],
              [-36.781666665484181, -8.92166666018818],
              [-36.780833333891223, -8.92166665928886],
              [-36.780833332092584, -8.920833325897206],
              [-36.77999999870093, -8.920833325897206],
              [-36.77999999960025, -8.919999994304249],
              [-36.779166666208653, -8.919999993404929],
              [-36.779166666208653, -8.919166660013275],
              [-36.778333332816999, -8.919166659113955],
              [-36.778333331917679, -8.918333325722358],
              [-36.776666666033748, -8.918333325722358],
              [-36.776666666033748, -8.917499993230081],
              [-36.775833332642151, -8.917499993230081],
              [-36.775833331742831, -8.91583332644683],
              [-36.775000000149817, -8.91583332464819],
              [-36.774999998351177, -8.914999993055176],
              [-36.773333331567926, -8.914999993055176],
              [-36.773333331567926, -8.913333326271925],
              [-36.772499998176329, -8.913333325372605],
              [-36.772499999075649, -8.912499992880328],
              [-36.766666666233618, -8.912499991981008],
              [-36.766666668931578, -8.911666659488674],
              [-36.765833331942645, -8.911666659488674],
              [-36.765833333741341, -8.910833326097077],
              [-36.764999998551048, -8.910833325197757],
              [-36.765000000349687, -8.909166659313826],
              [-36.76416666785741, -8.909166659313826],
              [-36.76416666785741, -8.908333325922229],
              [-36.763333331767797, -8.908333325922229],
              [-36.763333333566436, -8.907499994329214],
              [-36.762500001074159, -8.907499993429894],
              [-36.7624999983762, -8.906666659138978],
              [-36.761666666783185, -8.906666659138978],
              [-36.761666667682505, -8.905833325747324],
              [-36.760000000899254, -8.905833325747324],
              [-36.759999999100614, -8.904999993255046],
              [-36.759166665709017, -8.904999993255046],
              [-36.759166666608337, -8.904166658964073],
              [-36.75833333411606, -8.904166658964073],
              [-36.758333333216683, -8.903333326471795],
              [-36.757499998925766, -8.903333326471795],
              [-36.757500000724406, -8.902499993080198],
              [-36.755833332142515, -8.902499993080198],
              [-36.755833333041835, -8.900833327196267],
              [-36.754999999650238, -8.900833326296947],
              [-36.754999998750918, -8.899999992905293],
              [-36.754166665359264, -8.899999992005974],
              [-36.754166665359264, -8.899166659513696],
              [-36.753333332866987, -8.899166657715057],
              [-36.753333332866987, -8.898333326122042],
              [-36.751666666983056, -8.898333326122042],
              [-36.751666666983056, -8.897499992730445],
              [-36.749999998401165, -8.897499994529085],
              [-36.749999998401165, -8.896666659338791],
              [-36.749166666808208, -8.896666659338791],
              [-36.749166665908888, -8.895833325047874],
              [-36.748333332517234, -8.895833325947194],
              [-36.748333333416554, -8.894999991656221],
              [-36.746666667532622, -8.89499999345486],
              [-36.746666666633303, -8.893333325772346],
              [-36.745833332342386, -8.893333325772346],
              [-36.745833331443066, -8.891666659888415],
              [-36.744999999850052, -8.891666658089775],
              [-36.744999998051412, -8.889999993105164],
              [-36.744166667357774, -8.889999994004484],
              [-36.744166665559135, -8.888333326321913],
              [-36.742499998775884, -8.888333327221233],
              [-36.742500000574523, -8.885833326147065],
              [-36.74333333396612, -8.885833326147065],
              [-36.743333332167481, -8.884166659363814],
              [-36.744166665559135, -8.884166659363814],
              [-36.744166665559135, -8.880833325797312],
              [-36.744166665559135, -8.879999993304978],
              [-36.744999999850052, -8.879999993304978],
              [-36.744999999850052, -8.87916665991338],
              [-36.745833332342386, -8.879166659014061],
              [-36.745833332342386, -8.877499993130129],
              [-36.746666665733983, -8.877499993130129],
              [-36.746666667532622, -8.874999992955281],
              [-36.745833334141025, -8.874999993854601],
              [-36.745833332342386, -8.874166658664308],
              [-36.744999998950732, -8.874166658664308],
              [-36.744999998950732, -8.873333325272711],
              [-36.744166666458455, -8.87333332617203],
              [-36.744166666458455, -8.868333325822277],
              [-36.744999999850052, -8.868333325822277],
              [-36.745000000749371, -8.86749999422932],
              [-36.745833331443066, -8.86749999333],
              [-36.745833332342386, -8.86333332996918],
              [-36.750000000199805, -8.863333326371844],
              [-36.750000000199805, -8.864166659763498],
              [-36.750833333591402, -8.864166658864178],
              [-36.750833332692082, -8.864999993155095],
              [-36.753333332866987, -8.864999993155095],
              [-36.753333332866987, -8.865833325647429],
              [-36.755833332142515, -8.865833325647429],
              [-36.755833333041835, -8.866666659039026],
              [-36.758333333216683, -8.866666659039026],
              [-36.758333331418044, -8.868333326721597],
              [-36.759166665709017, -8.868333325822277],
              [-36.759166666608337, -8.869166659213931],
              [-36.76, -8.869166659213931],
              [-36.76, -8.869999993504848],
              [-36.760833331592949, -8.869999993504848],
              [-36.760833332492268, -8.871666659388779],
              [-36.761666667682505, -8.871666659388779],
              [-36.761666665883865, -8.872499991881057],
              [-36.763333331767797, -8.872499992780376],
              [-36.763333331767797, -8.87333332707135],
              [-36.76416666695809, -8.87333332707135],
              [-36.76416666605877, -8.874166660462947],
              [-36.765833332842021, -8.874166658664308],
              [-36.765833332842021, -8.874999992955281],
              [-36.769166665509147, -8.874999993854601],
              [-36.769166665509147, -8.875833326346878],
              [-36.771666666583371, -8.875833326346878],
              [-36.771666665684052, -8.876666658839213],
              [-36.772499999974968, -8.876666659738532],
              [-36.772499999075649, -8.877499994029449],
              [-36.774166667657539, -8.877499993130129],
              [-36.774166666758219, -8.879166659014061],
              [-36.773333333366622, -8.879166659014061],
              [-36.773333331567926, -8.879999993304978],
              [-36.7741666658589, -8.879999993304978],
              [-36.774166666758219, -8.881666659188909],
              [-36.774999998351177, -8.881666659188909],
              [-36.775000001049193, -8.882499993479883],
              [-36.775833331742831, -8.882499993479883],
              [-36.77583333354147, -8.88333332597216],
              [-36.777499999425402, -8.88333332507284],
              [-36.777499999425402, -8.884166659363814],
              [-36.779166667107972, -8.884166659363814],
              [-36.779166666208653, -8.884999992755411],
              [-36.780833332991904, -8.884999992755411],
              [-36.780833332092584, -8.885833326147065],
              [-36.781666665484181, -8.885833326147065],
              [-36.781666666383501, -8.886666660437982],
              [-36.782499998875778, -8.886666660437982],
              [-36.782499998875778, -8.888333326321913],
              [-36.783333333166752, -8.888333327221233],
              [-36.783333331368112, -8.88916665881419],
              [-36.784166667457669, -8.88916665881419],
              [-36.784166665659029, -8.889999992205844],
              [-36.784999999950003, -8.889999993105164],
              [-36.785000000849323, -8.890833326496761],
              [-36.786666666733254, -8.890833327396081],
              [-36.786666665833934, -8.891666658089775],
              [-36.787500001024171, -8.891666658989095],
              [-36.787500000124851, -8.893333325772346],
              [-36.789166666008782, -8.893333325772346],
              [-36.789166666908102, -8.894166658264623],
              [-36.79166666618363, -8.894166659163943],
              [-36.79166666708295, -8.893333325772346],
              [-36.792499999575284, -8.89333332487297],
              [-36.792500000474604, -8.892499993280012],
              [-36.795000000649452, -8.892499993280012],
              [-36.795000001548772, -8.891666658989095],
              [-36.796666667432703, -8.891666658989095],
              [-36.796666664734744, -8.890833326496761],
              [-36.797499999025717, -8.890833326496761],
              [-36.797499999025717, -8.891666658989095],
              [-36.800833333491482, -8.891666658989095],
              [-36.800833331692843, -8.892499993280012],
              [-36.804166667057984, -8.892499992380692],
              [-36.804166668856624, -8.891666658989095],
              [-36.809166665609098, -8.891666658989095],
              [-36.809166665609098, -8.892499993280012],
              [-36.810833331493029, -8.892499992380692],
              [-36.810833331493029, -8.891666658989095],
              [-36.814999999350448, -8.891666658989095],
              [-36.814999999350448, -8.890833326496761],
              [-36.815833330943406, -8.890833326496761],
              [-36.815833333641365, -8.889999993105164],
              [-36.816666667932338, -8.889999993105164],
              [-36.816666666133699, -8.888333326321913],
              [-36.817499997726657, -8.888333326321913],
              [-36.817500000424616, -8.886666658639342],
              [-36.81833333291695, -8.886666658639342],
              [-36.81833333201763, -8.884999992755411],
              [-36.819166668107187, -8.884999992755411],
              [-36.819166668107187, -8.884166659363814],
              [-36.81833333291695, -8.884166659363814],
              [-36.81833333291695, -8.88333332597216],
              [-36.817500000424616, -8.88333332597216],
              [-36.817499998625976, -8.882499991681243],
              [-36.816666666133699, -8.882499991681243],
              [-36.816666667033019, -8.879999993304978],
              [-36.817499997726657, -8.879999993304978],
              [-36.817500000424616, -8.879166659014061],
              [-36.815833331842725, -8.879166659014061],
              [-36.815833333641365, -8.878333326521727],
              [-36.815000001149087, -8.878333326521727],
              [-36.814999999350448, -8.877499993130129],
              [-36.815833331842725, -8.877499993130129],
              [-36.815833332742045, -8.875833326346878],
              [-36.814999998451128, -8.875833325447559],
              [-36.814999998451128, -8.874999994753921],
              [-36.81416666865681, -8.874999992955281],
              [-36.814166665958851, -8.874166660462947],
              [-36.809999999000695, -8.874166660462947],
              [-36.809999999900015, -8.87333332617203],
              [-36.809166666508418, -8.87333332617203],
              [-36.809166667407737, -8.872499992780376],
              [-36.808333334016083, -8.872499992780376],
              [-36.808333332217444, -8.871666659388779],
              [-36.807499998825847, -8.871666659388779],
              [-36.807499998825847, -8.870833325997125],
              [-36.806666668132209, -8.870833326896445],
              [-36.806666667232889, -8.871666659388779],
              [-36.805833333841235, -8.871666659388779],
              [-36.805833332042596, -8.872499992780376],
              [-36.804999999550319, -8.872499992780376],
              [-36.804999998650999, -8.87333332707135],
              [-36.804166667057984, -8.87333332617203],
              [-36.804166667957304, -8.874166658664308],
              [-36.803333332767068, -8.874166658664308],
              [-36.803333331867748, -8.87333332617203],
              [-36.801666665983817, -8.87333332617203],
              [-36.801666665983817, -8.872499992780376],
              [-36.800833330793523, -8.872499992780376],
              [-36.800833334390802, -8.871666659388779],
              [-36.800000000999205, -8.871666660288099],
              [-36.799999999200566, -8.869999993504848],
              [-36.799166665808912, -8.869999993504848],
              [-36.799166665808912, -8.869166659213931],
              [-36.798333331517995, -8.869166659213931],
              [-36.798333331517995, -8.868333324922958],
              [-36.797500000824357, -8.868333325822277],
              [-36.797500000824357, -8.86749999333],
              [-36.796666666533383, -8.86749999333],
              [-36.796666665634064, -8.866666658139707],
              [-36.795833333141786, -8.866666659039026],
              [-36.795833331343147, -8.865833325647429],
              [-36.795000001548772, -8.865833325647429],
              [-36.795000000649452, -8.864999993155095],
              [-36.794166666358535, -8.864999993155095],
              [-36.794166666358535, -8.864166658864178],
              [-36.790833330993394, -8.864166658864178],
              [-36.790833332792033, -8.863333326371844],
              [-36.789999999400436, -8.863333326371844],
              [-36.790000001199076, -8.862499992980247],
              [-36.789166666008782, -8.862499992980247],
              [-36.789166667807422, -8.861666658689273],
              [-36.788333332617185, -8.861666658689273],
              [-36.788333331717865, -8.860833326196996],
              [-36.787499999225531, -8.860833326196996],
              [-36.787499999225531, -8.856666659238897],
              [-36.7858333333416, -8.856666659238897],
              [-36.78583333244228, -8.855833325847243],
              [-36.784999998151363, -8.855833324947923],
              [-36.784999999950003, -8.854999993354966],
              [-36.784999999950003, -8.854166659063992],
              [-36.78583333244228, -8.854166659063992],
              [-36.78583333244228, -8.853333325672395],
              [-36.786666667632574, -8.853333324773075],
              [-36.786666666733254, -8.852499993180118],
              [-36.787499999225531, -8.852499993180118],
              [-36.787499999225531, -8.851666658889144],
              [-36.788333331717865, -8.851666658889144],
              [-36.788333331717865, -8.850833326396867],
              [-36.789166666908102, -8.850833327296186],
              [-36.789166666008782, -8.849999993005213],
              [-36.790000001199076, -8.849999993005213],
              [-36.78999999850106, -8.849166658714296],
              [-36.79166666708295, -8.849166658714296],
              [-36.79166666618363, -8.848333326221962],
              [-36.792499999575284, -8.848333326221962],
              [-36.792500000474604, -8.847499992830365],
              [-36.794166665459215, -8.847499992830365],
              [-36.794166666358535, -8.846666659438711],
              [-36.794999999750132, -8.846666659438711],
              [-36.794999998850813, -8.847499992830365],
              [-36.795833331343147, -8.847499992830365],
              [-36.795833331343147, -8.846666659438711],
              [-36.802500001174053, -8.846666659438711],
              [-36.802499999375414, -8.847499992830365],
              [-36.804166666158665, -8.847499992830365],
              [-36.804166667957304, -8.848333327121281],
              [-36.806666668132209, -8.848333325322642],
              [-36.806666665434193, -8.846666659438711],
              [-36.807500000624486, -8.846666659438711],
              [-36.807499998825847, -8.844999992655517],
              [-36.808333333116764, -8.844999992655517],
              [-36.808333334016083, -8.844166660163182],
              [-36.807499999725167, -8.844166659263863],
              [-36.807499998825847, -8.839999993205083],
              [-36.809166667407737, -8.839999993205083],
              [-36.809166665609098, -8.838333326421832],
              [-36.810833334190988, -8.838333326421832],
              [-36.810833331493029, -8.837499993030235],
              [-36.813333331667877, -8.837499993030235],
              [-36.813333332567197, -8.838333326421832],
              [-36.81416666775749, -8.838333326421832],
              [-36.81416666775749, -8.837499993030235],
              [-36.814999998451128, -8.837499993030235],
              [-36.815000001149087, -8.836666658739261],
              [-36.816666667932338, -8.83666666413518],
              [-36.816666667932338, -8.829999994304274],
              [-36.815833333641365, -8.829999993404897],
              [-36.815833331842725, -8.8291666600133],
              [-36.814166665958851, -8.82916665911398],
              [-36.81416666775749, -8.828333325722383],
              [-36.814999999350448, -8.828333327521023],
              [-36.814999998451128, -8.824999992155881],
              [-36.81416666775749, -8.824999992155881],
              [-36.814166665958851, -8.824166658764227],
              [-36.813333332567197, -8.824166658764227],
              [-36.813333332567197, -8.82333332627195],
              [-36.81249999737696, -8.82333332537263],
              [-36.812500001873559, -8.822499992880296],
              [-36.811666665783946, -8.822499992880296],
              [-36.811666667582585, -8.821666657690059],
              [-36.810833331493029, -8.821666659488699],
              [-36.809166665609098, -8.821666659488699],
              [-36.809166667407737, -8.822499992880296],
              [-36.808333333116764, -8.822499992880296],
              [-36.808333333116764, -8.824166658764227],
              [-36.805000001348958, -8.824166658764227],
              [-36.804999999550319, -8.824999993055201],
              [-36.804166666158665, -8.824999993055201],
              [-36.804166667057984, -8.827499993230049],
              [-36.802500001174053, -8.827499993230049],
              [-36.802499998476094, -8.825833326446798],
              [-36.801666665983817, -8.825833326446798],
              [-36.801666666883136, -8.824999993055201],
              [-36.800833333491482, -8.824999993055201],
              [-36.800833332592163, -8.824166658764227],
              [-36.799999999200566, -8.824166658764227],
              [-36.800000000099885, -8.82333332537263],
              [-36.799166668506928, -8.82333332627195],
              [-36.799166667607608, -8.822499992880296],
              [-36.796666667432703, -8.822499992880296],
              [-36.796666667432703, -8.821666659488699],
              [-36.798333331517995, -8.821666659488699],
              [-36.798333335115274, -8.815833325747349],
              [-36.797499999925037, -8.815833325747349],
              [-36.797499999025717, -8.814999993255014],
              [-36.798333331517995, -8.814999993255014],
              [-36.798333334215954, -8.814166658964098],
              [-36.797500000824357, -8.814166658964098],
              [-36.797500000824357, -8.810833326296915],
              [-36.795000000649452, -8.810833326296915],
              [-36.795000000649452, -8.809166659513664],
              [-36.794166666358535, -8.809166659513664],
              [-36.794166665459215, -8.808333326122067],
              [-36.793333332966881, -8.808333326122067],
              [-36.793333332966881, -8.805833325947162],
              [-36.792499998675964, -8.805833325947162],
              [-36.792499998675964, -8.801666658989063],
              [-36.79166666618363, -8.801666658989063],
              [-36.79166666708295, -8.799999993105132],
              [-36.790833332792033, -8.799999993105132],
              [-36.790833333691353, -8.791666659188934],
              [-36.790000001199076, -8.791666659188934],
              [-36.789999999400436, -8.789999993305003],
              [-36.789166666908102, -8.789999993305003],
              [-36.789166666008782, -8.788333326521752],
              [-36.788333332617185, -8.788333324723112],
              [-36.788333332617185, -8.786666658839181],
              [-36.787500000124851, -8.786666658839181],
              [-36.787500000124851, -8.785833326346904],
              [-36.786666665833934, -8.785833326346904],
              [-36.786666666733254, -8.783333326171999],
              [-36.787499999225531, -8.783333325272679],
              [-36.787500001024171, -8.782499993679721],
              [-36.786666667632574, -8.782499991881082],
              [-36.786666665833934, -8.781666659388748],
              [-36.786666666733254, -8.77916665831458],
              [-36.78583333244228, -8.779166659213899],
              [-36.7858333333416, -8.778333325822302],
              [-36.784999999950003, -8.778333325822302],
              [-36.784999999950003, -8.776666659039051],
              [-36.784166667457669, -8.776666659039051],
              [-36.784166668356988, -8.775833327446037],
              [-36.783333333166752, -8.775833325647397],
              [-36.783333331368112, -8.77499999315512],
              [-36.781666665484181, -8.7749999922558],
              [-36.781666666383501, -8.775833325647397],
              [-36.779166666208653, -8.775833324748078],
              [-36.779166667107972, -8.773333326371869],
              [-36.778333332816999, -8.773333327271189],
              [-36.778333333716319, -8.771666658689298],
              [-36.777500001224041, -8.771666658689298],
              [-36.777500001224041, -8.766666659238865],
              [-36.779166668007292, -8.766666659238865],
              [-36.779166668007292, -8.767499994429159],
              [-36.780833332991904, -8.767499993529839],
              [-36.780833333891223, -8.766666659238865],
              [-36.781666666383501, -8.766666659238865],
              [-36.78166666818214, -8.764999993354991],
              [-36.783333331368112, -8.764999993354991],
              [-36.783333333166752, -8.764166659064017],
              [-36.784166667457669, -8.764166659963337],
              [-36.784166665659029, -8.76333332567242],
              [-36.785000000849323, -8.76333332567242],
              [-36.784999999050683, -8.764166659064017],
              [-36.7858333333416, -8.764166659064017],
              [-36.78583333244228, -8.764999993354991],
              [-36.786666666733254, -8.764999993354991],
              [-36.786666666733254, -8.765833325847268],
              [-36.792499998675964, -8.765833325847268],
              [-36.792499998675964, -8.766666659238865],
              [-36.793333332966881, -8.766666659238865],
              [-36.793333332966881, -8.767499993529839],
              [-36.794166665459215, -8.767499993529839],
              [-36.794166668157175, -8.769999993704687],
              [-36.793333332966881, -8.769999991906047],
              [-36.793333332067562, -8.772499992980272],
              [-36.796666665634064, -8.772499992080895],
              [-36.796666665634064, -8.771666660487938],
              [-36.797499999925037, -8.771666658689298],
              [-36.797500000824357, -8.770833326197021],
              [-36.798333332417315, -8.770833326197021],
              [-36.798333334215954, -8.769999993704687],
              [-36.799166667607608, -8.769999992805367],
              [-36.799166666708231, -8.76916666031309],
              [-36.806666667232889, -8.76916665941377],
              [-36.806666665434193, -8.769999992805367],
              [-36.807499998825847, -8.769999992805367],
              [-36.807499999725167, -8.770833326197021],
              [-36.809999999000695, -8.770833325297701],
              [-36.809999999900015, -8.771666658689298],
              [-36.810833331493029, -8.771666658689298],
              [-36.810833334190988, -8.772499992980272],
              [-36.812500000974239, -8.772499992980272],
              [-36.8124999991756, -8.773333326371869],
              [-36.813333331667877, -8.773333326371869],
              [-36.813333331667877, -8.774166658864146],
              [-36.815000001149087, -8.774166657964827],
              [-36.814999998451128, -8.7749999922558],
              [-36.81833333381627, -8.77499999315512],
              [-36.81833333291695, -8.776666659039051],
              [-36.821666666483452, -8.776666659039051],
              [-36.821666664684756, -8.777499993329968],
              [-36.823333331468007, -8.777499993329968],
              [-36.823333332367326, -8.776666659039051],
              [-36.82416666575898, -8.776666659039051],
              [-36.8241666666583, -8.775833325647397],
              [-36.824999999150577, -8.775833324748078],
              [-36.825000000949217, -8.776666659039051],
              [-36.825833331642912, -8.776666658139732],
              [-36.825833333441551, -8.775833325647397],
              [-36.827499998426163, -8.775833325647397],
              [-36.827499998426163, -8.77499999315512],
              [-36.82833333181776, -8.77499999315512],
              [-36.828333332717079, -8.772499992980272],
              [-36.829166667907373, -8.772499992980272],
              [-36.829166667008053, -8.771666658689298],
              [-36.82999999950033, -8.771666658689298],
              [-36.829999997701691, -8.770833326197021],
              [-36.832499999675179, -8.770833326197021],
              [-36.832499998775859, -8.769999992805367],
              [-36.833333333066832, -8.769999992805367],
              [-36.833333333966152, -8.768333326022116],
              [-36.834166667357749, -8.768333326022116],
              [-36.83416666645843, -8.767499993529839],
              [-36.833333333066832, -8.767499994429159],
              [-36.833333333966152, -8.764999993354991],
              [-36.834166669156389, -8.764999993354991],
              [-36.83416666555911, -8.764166659064017],
              [-36.834999998950764, -8.764166659064017],
              [-36.834999999850083, -8.761666657989849],
              [-36.83416666555911, -8.761666658889169],
              [-36.83416666645843, -8.760833325497515],
              [-36.833333333066832, -8.760833326396835],
              [-36.833333332167513, -8.759999993005238],
              [-36.832499999675179, -8.759999993005238],
              [-36.832499999675179, -8.759166658714264],
              [-36.826666666833148, -8.759166658714264],
              [-36.826666665933828, -8.756666659438736],
              [-36.825833333441551, -8.756666660338055],
              [-36.825833331642912, -8.755833326047139],
              [-36.825000000049897, -8.755833326047139],
              [-36.825000000949217, -8.754999992655485],
              [-36.822500000774369, -8.754999992655485],
              [-36.822499999875049, -8.754166659263888],
              [-36.821666667382772, -8.754166659263888],
              [-36.821666666483452, -8.752499994279276],
              [-36.816666667932338, -8.752499993379956],
              [-36.816666666133699, -8.751666659088983],
              [-36.815833331842725, -8.751666659088983],
              [-36.815833331842725, -8.752499992480637],
              [-36.815000001149087, -8.752499993379956],
              [-36.814999998451128, -8.751666659088983],
              [-36.813333333466517, -8.751666659988302],
              [-36.813333331667877, -8.750833325697386],
              [-36.810833334190988, -8.750833325697386],
              [-36.810833331493029, -8.749999993205051],
              [-36.809999999900015, -8.749999993205051],
              [-36.809999999000695, -8.749166658914135],
              [-36.808333332217444, -8.749166658914135],
              [-36.808333333116764, -8.748333326421857],
              [-36.806666668132209, -8.748333326421857],
              [-36.806666667232889, -8.747499992130884],
              [-36.801666666883136, -8.747499993030203],
              [-36.801666666883136, -8.745833326246952],
              [-36.796666666533383, -8.745833325347633],
              [-36.796666666533383, -8.744166659463701],
              [-36.794999999750132, -8.744166659463701],
              [-36.794999998850813, -8.743333326072104],
              [-36.794166665459215, -8.743333325172784],
              [-36.794166665459215, -8.739166658214685],
              [-36.793333332067562, -8.739166659114005],
              [-36.793333334765521, -8.738333324823031],
              [-36.78999999850106, -8.738333325722351],
              [-36.790000001199076, -8.737499993230074],
              [-36.787499999225531, -8.737499993230074],
              [-36.787500001024171, -8.7366666589391],
              [-36.78583333154296, -8.7366666589391],
              [-36.78583333244228, -8.734166659663572],
              [-36.784999999050683, -8.734166658764252],
              [-36.784999999950003, -8.732499992880321],
              [-36.785833330643641, -8.732499991981001],
              [-36.7858333333416, -8.731666659488724],
              [-36.782499999775098, -8.731666659488724],
              [-36.782499998875778, -8.73083332609707],
              [-36.781666665484181, -8.73083332609707],
              [-36.781666665484181, -8.727499991631248],
              [-36.780833333891223, -8.727499993429888],
              [-36.780833332092584, -8.726666659138971],
              [-36.78000000049957, -8.726666659138971],
              [-36.77999999870093, -8.724999993255039],
              [-36.778333332816999, -8.724999993255039],
              [-36.778333333716319, -8.722499993080191],
              [-36.777499999425402, -8.722499993080191],
              [-36.777500001224041, -8.721666659688537],
              [-36.776666667832387, -8.721666658789218],
              [-36.776666666033748, -8.72083332629694],
              [-36.77583333354147, -8.72083332629694],
              [-36.775833332642151, -8.719166659513689],
              [-36.774999998351177, -8.719166659513689],
              [-36.774999998351177, -8.717499991831119],
              [-36.774166667657539, -8.717499992730438],
              [-36.774166667657539, -8.716666659338784],
              [-36.773333332467303, -8.716666659338784],
              [-36.773333331567926, -8.715833325047868],
              [-36.772499999974968, -8.715833325947187],
              [-36.772499999075649, -8.716666659338784],
              [-36.771666666583371, -8.716666659338784],
              [-36.771666666583371, -8.717499994529078],
              [-36.770833333191717, -8.717499992730438],
              [-36.770833334091037, -8.719166659513689],
              [-36.769999998900801, -8.719166659513689],
              [-36.769999998900801, -8.719999992905286],
              [-36.769166665509147, -8.719999992005967],
              [-36.769166668207106, -8.721666658789218],
              [-36.768333333016869, -8.721666658789218],
              [-36.768333333016869, -8.722499993080191],
              [-36.767500000524535, -8.722499992180872],
              [-36.767499998725896, -8.723333326471788],
              [-36.766666668032258, -8.723333326471788],
              [-36.766666666233618, -8.724166658964066],
              [-36.762500000174839, -8.724166658964066],
              [-36.76249999747688, -8.724999993255039],
              [-36.761666666783185, -8.724999993255039],
              [-36.761666667682505, -8.726666659138971],
              [-36.760833331592949, -8.726666659138971],
              [-36.760833332492268, -8.727499993429888],
              [-36.76, -8.727499993429888],
              [-36.759999999100614, -8.728333325922222],
              [-36.758333333216683, -8.728333326821542],
              [-36.758333333216683, -8.729166659313819],
              [-36.754166665359264, -8.729166659313819],
              [-36.754166665359264, -8.728333325922222],
              [-36.751666666083736, -8.728333325922222],
              [-36.751666666983056, -8.729166659313819],
              [-36.750833331792762, -8.729166659313819],
              [-36.750833333591402, -8.729999992705473],
              [-36.748333331617914, -8.729999994504112],
              [-36.748333331617914, -8.73083332609707],
              [-36.745833331443066, -8.73083332609707],
              [-36.745833332342386, -8.729999992705473],
              [-36.745000000749371, -8.729999992705473],
              [-36.745000000749371, -8.729166659313819],
              [-36.741666668082246, -8.729166659313819],
              [-36.736666665933853, -8.729166658414499],
              [-36.735000000949242, -8.729166659313819],
              [-36.734999999150602, -8.729999992705473],
              [-36.733333330568712, -8.729999992705473],
              [-36.733333331468032, -8.729166659313819],
              [-36.732499999875074, -8.729166659313819],
              [-36.732500000774394, -8.729999992705473],
              [-36.73166666738274, -8.729999994504112],
              [-36.7316666655841, -8.729166659313819],
              [-36.729166665409252, -8.729166659313819],
              [-36.729166665409252, -8.728333325022902],
              [-36.728333332017598, -8.728333325922222],
              [-36.728333333816238, -8.727499993429888],
              [-36.724166665958819, -8.727499991631248],
              [-36.724166666858139, -8.726666659138971],
              [-36.720000000799359, -8.726666659138971],
              [-36.71999999900072, -8.727499993429888],
              [-36.719166665609066, -8.727499993429888],
              [-36.719166665609066, -8.728333325922222],
              [-36.717500000624511, -8.728333325922222],
              [-36.717499997926495, -8.729166658414499],
              [-36.712500001174078, -8.729166659313819],
              [-36.712500001174078, -8.728333325922222],
              [-36.705833334041074, -8.728333325922222],
              [-36.705833333141754, -8.729166660213139],
              [-36.704166666358503, -8.729166658414499],
              [-36.704166666358503, -8.729999992705473],
              [-36.703333333866226, -8.729999992705473],
              [-36.703333332067587, -8.733333326271918],
              [-36.702499999575252, -8.733333326271918],
              [-36.702499998675933, -8.735833326446823],
              [-36.698333332617153, -8.735833326446823],
              [-36.698333333516473, -8.739166659114005],
              [-36.697499999225556, -8.739166659114005],
              [-36.697500001024196, -8.74249999268045],
              [-36.698333331717834, -8.74249999268045],
              [-36.698333333516473, -8.743333326072104],
              [-36.694166665659054, -8.743333325172784],
              [-36.694166666558374, -8.74249999268045],
              [-36.692500000674443, -8.74249999268045],
              [-36.692500000674443, -8.743333326971424],
              [-36.691666666383526, -8.743333326971424],
              [-36.691666665484206, -8.74249999268045],
              [-36.689999999600275, -8.742499991781131],
              [-36.690000000499595, -8.741666660188173],
              [-36.686666666933093, -8.741666659288853],
              [-36.686666667832412, -8.740833325897199],
              [-36.682500000874313, -8.740833325897199],
              [-36.682499999974993, -8.739999993404922],
              [-36.679166666408491, -8.739999993404922],
              [-36.679166666408491, -8.740833325897199],
              [-36.670000000899279, -8.740833325897199],
              [-36.67, -8.74249999268045],
              [-36.668333331418069, -8.74249999268045],
              [-36.668333334116028, -8.741666659288853],
              [-36.666666665534137, -8.741666659288853],
              [-36.666666665534137, -8.740833325897199],
              [-36.664166668956568, -8.740833330393855],
              [-36.661666666083704, -8.740833325897199],
              [-36.661666666083704, -8.741666659288853],
              [-36.660833331792787, -8.741666659288853],
              [-36.660833332692107, -8.744166659463701],
              [-36.661666666983024, -8.744166659463701],
              [-36.661666666083704, -8.744999992855355],
              [-36.662499998576038, -8.744999992855355],
              [-36.662499998576038, -8.746666658739287],
              [-36.663333332866955, -8.746666658739287],
              [-36.663333332866955, -8.747499993929523],
              [-36.664999998750886, -8.747499993030203],
              [-36.664999999650206, -8.748333326421857],
              [-36.66583333304186, -8.748333326421857],
              [-36.66583333394118, -8.749999993205051],
              [-36.666666665534137, -8.749999993205051],
              [-36.666666665534137, -8.750833325697386],
              [-36.668333331418069, -8.750833325697386],
              [-36.668333333216708, -8.751666659088983],
              [-36.669166666608362, -8.751666659088983],
              [-36.669166665708985, -8.752499993379956],
              [-36.670833332492236, -8.752499993379956],
              [-36.670833332492236, -8.754166659263888],
              [-36.67166666678321, -8.754166659263888],
              [-36.67166666768253, -8.754999992655485],
              [-36.672499998376168, -8.754999992655485],
              [-36.672500000174807, -8.756666657640096],
              [-36.673333333566461, -8.756666659438736],
              [-36.673333331767822, -8.757499991931013],
              [-36.674166666058738, -8.757499992830333],
              [-36.674166666958058, -8.758333325322667],
              [-36.674999999450392, -8.758333327121306],
              [-36.674999998551073, -8.76333332567242],
              [-36.674166667857378, -8.76333332567242],
              [-36.674166667857378, -8.764166659064017],
              [-36.673333332667141, -8.764166659064017],
              [-36.673333334465781, -8.764999993354991],
              [-36.674166666958058, -8.76499999425431],
              [-36.674166666958058, -8.765833325847268],
              [-36.68166666568402, -8.765833325847268],
              [-36.681666668381979, -8.766666659238865],
              [-36.683333331567951, -8.766666659238865],
              [-36.683333331567951, -8.767499993529839],
              [-36.684999997451882, -8.767499994429159],
              [-36.685000000149842, -8.768333326022116],
              [-36.685833332642119, -8.768333326921436],
              [-36.685833331742799, -8.769999992805367],
              [-36.684999998351202, -8.769999992805367],
              [-36.685000000149842, -8.770833326197021],
              [-36.684166665858925, -8.770833327096341],
              [-36.684166665858925, -8.771666657789979],
              [-36.683333331567951, -8.771666658689298],
              [-36.683333332467271, -8.772499992980272],
              [-36.682499999075674, -8.772499992980272],
              [-36.682499999974993, -8.774166658864146],
              [-36.683333331567951, -8.774166658864146],
              [-36.683333332467271, -8.7749999922558],
              [-36.684166666758244, -8.77499999315512],
              [-36.684166667657564, -8.777499993329968],
              [-36.685833333541439, -8.777499992430648],
              [-36.685833331742799, -8.778333325822302],
              [-36.685833332642119, -8.779166659213899],
              [-36.686666666933093, -8.779166659213899],
              [-36.687500001224009, -8.779166659213899],
              [-36.687500001224009, -8.779999993504873],
              [-36.688333331917704, -8.779999993504873],
              [-36.688333332817024, -8.781666659388748],
              [-36.690000000499595, -8.781666659388748],
              [-36.689999999600275, -8.782499992780401],
              [-36.690833332991872, -8.782499992780401],
              [-36.690833332092552, -8.783333326171999],
              [-36.691666665484206, -8.783333326171999],
              [-36.691666666383526, -8.785833327246223],
              [-36.692499998875803, -8.785833326346904],
              [-36.692499997976483, -8.786666659738501],
              [-36.694166664759734, -8.786666658839181],
              [-36.694166666558374, -8.787499993130155],
              [-36.695833332442305, -8.787499993130155],
              [-36.695833332442305, -8.789166659014029],
              [-36.696666666733222, -8.789166659014029],
              [-36.696666665833902, -8.789999993305003],
              [-36.697500001024196, -8.789999993305003],
              [-36.697500000124876, -8.79083332579728],
              [-36.698333332617153, -8.79083332579728],
              [-36.698333330818514, -8.791666659188934],
              [-36.700833332792058, -8.791666659188934],
              [-36.700833331892682, -8.792499993479851],
              [-36.701666666183655, -8.792499993479851],
              [-36.701666667982295, -8.794166659363782],
              [-36.703333332067587, -8.794166659363782],
              [-36.703333332067587, -8.794999992755436],
              [-36.704166668157143, -8.794999992755436],
              [-36.704166669056463, -8.795833326147033],
              [-36.704999999750157, -8.795833325247713],
              [-36.704999998850838, -8.79666665863931],
              [-36.705833333141754, -8.79666665863931],
              [-36.705833331343115, -8.795833326147033],
              [-36.706666665634089, -8.795833326147033],
              [-36.706666665634089, -8.796666660438007],
              [-36.707499999025686, -8.79666665863931],
              [-36.707499999025686, -8.795833326147033],
              [-36.712499998476119, -8.795833326147033],
              [-36.712499999375439, -8.79666665863931],
              [-36.713333332767036, -8.79666665863931],
              [-36.713333330968396, -8.797499992930284],
              [-36.714166667957329, -8.797499992930284],
              [-36.71416666615869, -8.800833324698146],
              [-36.714999998650967, -8.800833326496786],
              [-36.715000000449606, -8.802499993280037],
              [-36.715833332941941, -8.802499993280037],
              [-36.715833332941941, -8.804166659163968],
              [-36.716666667232857, -8.804166659163968],
              [-36.716666665434218, -8.808333326122067],
              [-36.717499998825815, -8.808333326122067],
              [-36.717499999725192, -8.809166659513664],
              [-36.718333332217469, -8.809166659513664],
              [-36.718333332217469, -8.809999992905318],
              [-36.719166666508386, -8.809999992905318],
              [-36.719166665609066, -8.81166665878925],
              [-36.71999999900072, -8.81166665878925],
              [-36.720000001698679, -8.81333332647182],
              [-36.720833332392317, -8.81333332647182],
              [-36.720833332392317, -8.814166658064778],
              [-36.722500000074888, -8.814166658964098],
              [-36.722500000074888, -8.814999993255014],
              [-36.723333331667902, -8.814999993255014],
              [-36.723333332567222, -8.815833326646668],
              [-36.725000001149112, -8.815833325747349],
              [-36.724999998451096, -8.816666659138946],
              [-36.728333332017598, -8.816666660038265],
              [-36.728333333816238, -8.817499993429919],
              [-36.729166668107212, -8.817499994329239],
              [-36.729166664509933, -8.818333325922197],
              [-36.729999998800849, -8.818333325922197],
              [-36.729999999700169, -8.819999992705448],
              [-36.730833333091823, -8.819999992705448],
              [-36.730833332192503, -8.822499992880296],
              [-36.7316666655841, -8.822499992880296],
              [-36.73166666648342, -8.82333332627195],
              [-36.732499998975754, -8.82333332627195],
              [-36.732499998975754, -8.824999993055201],
              [-36.733333331468032, -8.824999993055201],
              [-36.733333332367351, -8.827499993230049],
              [-36.734166665758949, -8.827499993230049],
              [-36.734166665758949, -8.829999994304274],
              [-36.735000000049922, -8.829999993404897],
              [-36.735000000049922, -8.830833325897231],
              [-36.734999997351963, -8.831666659288828],
              [-36.733333331468032, -8.831666659288828],
              [-36.733333332367351, -8.833333326072079],
              [-36.732500000774394, -8.833333326072079],
              [-36.732499999875074, -8.834166659463733],
              [-36.730833333091823, -8.834166660363053],
              [-36.730833332192503, -8.835833325347664],
              [-36.73166666738274, -8.835833327146304],
              [-36.7316666655841, -8.837499993030235],
              [-36.730833330393864, -8.837499993030235],
              [-36.730833332192503, -8.838333326421832],
              [-36.729999998800849, -8.838333326421832],
              [-36.730000000599489, -8.83916665801479],
              [-36.729166665409252, -8.83916665891411],
              [-36.729166668107212, -8.840833327496],
              [-36.728333332017598, -8.840833324798041],
              [-36.728333333816238, -8.845833326047114],
              [-36.727499998626001, -8.845833326047114],
              [-36.727499998626001, -8.848333326221962],
              [-36.726666667932363, -8.848333326221962],
              [-36.726666666133667, -8.850833325497547],
              [-36.727499998626001, -8.850833326396867],
              [-36.727499998626001, -8.851666658889144],
              [-36.727499999525321, -8.853333325672395],
              [-36.726666666133667, -8.853333325672395],
              [-36.726666667032987, -8.854166659063992],
              [-36.724166666858139, -8.854166659063992],
              [-36.724166665958819, -8.854999993354966],
              [-36.723333333466542, -8.854999993354966],
              [-36.723333333466542, -8.855833325847243],
              [-36.71999999990004, -8.855833326746563],
              [-36.71999999900072, -8.857499993529814],
              [-36.719166666508386, -8.857499993529814],
              [-36.719166666508386, -8.859999992805399],
              [-36.717499999725192, -8.859999992805399],
              [-36.717499998825815, -8.860833326196996],
              [-36.716666665434218, -8.860833326196996],
              [-36.716666667232857, -8.861666658689273],
              [-36.715833332042621, -8.861666658689273],
              [-36.715833331143301, -8.860833326196996],
              [-36.714166667058009, -8.860833326196996],
              [-36.714166667957329, -8.859999992805399],
              [-36.713333333666355, -8.859999992805399],
              [-36.713333331867716, -8.859166659413745],
              [-36.709999999200534, -8.859166658514425],
              [-36.710000000099853, -8.858333325122828],
              [-36.707500000824325, -8.858333326022148],
              [-36.707499999925005, -8.859166660313065],
              [-36.706666667432728, -8.859166659413745],
              [-36.706666665634089, -8.862499992980247],
              [-36.707499999025686, -8.862499992980247],
              [-36.707499999025686, -8.868333325822277],
              [-36.708333334215979, -8.868333326721597],
              [-36.70833333241734, -8.869166659213931],
              [-36.709166666708256, -8.869166659213931],
              [-36.709166667607576, -8.870833325997125],
              [-36.709999999200534, -8.870833325997125],
              [-36.710000000099853, -8.871666659388779],
              [-36.710833331692868, -8.871666659388779],
              [-36.710833332592188, -8.872499992780376],
              [-36.711666666883104, -8.872499991881057],
              [-36.711666666883104, -8.87333332617203],
              [-36.713333333666355, -8.87333332617203],
              [-36.713333332767036, -8.874999992955281],
              [-36.714166667957329, -8.874999992955281],
              [-36.714166667058009, -8.875833326346878],
              [-36.714999998650967, -8.875833326346878],
              [-36.714999998650967, -8.876666658839213],
              [-36.715833332042621, -8.876666658839213],
              [-36.71583333384126, -8.878333326521727],
              [-36.716666665434218, -8.878333326521727],
              [-36.716666667232857, -8.879999993304978],
              [-36.717499998825815, -8.879999993304978],
              [-36.717499999725192, -8.881666659188909],
              [-36.716666668132177, -8.881666659188909],
              [-36.716666665434218, -8.88333332687148],
              [-36.717499999725192, -8.88333332597216],
              [-36.717500000624511, -8.884999992755411],
              [-36.717499998825815, -8.885833326147065],
              [-36.715833332941941, -8.885833326147065],
              [-36.71583333384126, -8.886666660437982],
              [-36.714999999550287, -8.886666658639342],
              [-36.715000000449606, -8.889999994004484],
              [-36.714166667957329, -8.889999993105164],
              [-36.714166667957329, -8.891666658989095],
              [-36.714999999550287, -8.891666658989095],
              [-36.714999998650967, -8.892499993280012],
              [-36.715833332042621, -8.892499993280012],
              [-36.715833332042621, -8.893333325772346],
              [-36.716666665434218, -8.893333325772346],
              [-36.716666665434218, -8.895833325947194],
              [-36.717499999725192, -8.895833326846514],
              [-36.717499999725192, -8.896666659338791],
              [-36.716666665434218, -8.896666659338791],
              [-36.716666665434218, -8.898333326122042],
              [-36.714166667058009, -8.898333326122042],
              [-36.71416666615869, -8.900833327196267],
              [-36.713333331867716, -8.900833325397628],
              [-36.713333330968396, -8.901666657889905],
              [-36.712500001174078, -8.901666658789225],
              [-36.712499999375439, -8.903333327371115],
              [-36.711666665983785, -8.903333324673156],
              [-36.711666665983785, -8.904166658964073],
              [-36.710833333491507, -8.904166658964073],
              [-36.710833331692868, -8.909166659313826],
              [-36.709999999200534, -8.909166658414506],
              [-36.71000000099923, -8.910833326097077],
              [-36.709166667607576, -8.910833326097077],
              [-36.709166666708256, -8.914166658764259],
              [-36.70833333151802, -8.914166658764259],
              [-36.70833333151802, -8.91583332644683],
              [-36.707499999025686, -8.91583332644683],
              [-36.702499998675933, -8.91583332644683],
              [-36.702499998675933, -8.914999993055176],
              [-36.700833333691378, -8.914999993055176],
              [-36.700833330993362, -8.914166658764259],
              [-36.699166666908127, -8.914166658764259],
              [-36.699166667807447, -8.912499992880328],
              [-36.697500000124876, -8.912499992880328],
              [-36.697500000124876, -8.911666659488674],
              [-36.69500000174861, -8.911666659488674],
              [-36.694999999050651, -8.910833326097077],
              [-36.694166666558374, -8.910833326097077],
              [-36.694166667457694, -8.911666659488674],
              [-36.693333334066097, -8.911666659488674],
              [-36.693333333166777, -8.912499992880328],
              [-36.692499999775123, -8.912499992880328],
              [-36.692499998875803, -8.913333326271925],
              [-36.690833332092552, -8.913333327171244],
              [-36.690833332991872, -8.914166658764259],
              [-36.68749999942537, -8.914166658764259],
              [-36.687500001224009, -8.916666658939107],
              [-36.686666666933093, -8.916666659838427],
              [-36.686666666033773, -8.917499993230081],
              [-36.685000000149842, -8.917499993230081],
              [-36.684999997451882, -8.918333325722358],
              [-36.684166665858925, -8.918333325722358],
              [-36.684166665858925, -8.919166659113955],
              [-36.683333333366591, -8.919166659113955],
              [-36.683333332467271, -8.919999993404929],
              [-36.682499999075674, -8.919999993404929],
              [-36.682499999974993, -8.920833325897206],
              [-36.680833334091062, -8.920833325897206],
              [-36.680833333191742, -8.919999993404929],
              [-36.67750000052456, -8.919999994304249],
              [-36.67750000052456, -8.920833326796526],
              [-36.676666668032283, -8.920833324997886],
              [-36.676666667132963, -8.92166665928886],
              [-36.67583333194267, -8.92166665928886],
              [-36.675833332841989, -8.922499992680457],
              [-36.674999998551073, -8.922499994479097],
              [-36.675000000349712, -8.923333326072111],
              [-36.674166666058738, -8.923333326072111],
              [-36.674166666958058, -8.924166659463708],
              [-36.672500000174807, -8.924166657665069],
              [-36.672500000174807, -8.924999991956042],
              [-36.67166666768253, -8.924999992855362],
              [-36.67166666678321, -8.926666658739236],
              [-36.670833332492236, -8.926666658739236],
              [-36.670833331592917, -8.92749999303021],
              [-36.67, -8.92749999303021],
              [-36.67, -8.928333326421807],
              [-36.669166666608362, -8.928333326421807],
              [-36.669166666608362, -8.929166658914141],
              [-36.668333333216708, -8.929166658914141],
              [-36.668333335015348, -8.929999993205058],
              [-36.667499999825111, -8.929999993205058],
              [-36.667499998925791, -8.931666659088989],
              [-36.666666666433457, -8.931666659088989],
              [-36.666666666433457, -8.932499993379963],
              [-36.66583333304186, -8.932499993379963],
              [-36.66583333394118, -8.93333332587224],
              [-36.665000000549526, -8.93333332587224],
              [-36.665000001448846, -8.934166659263894],
              [-36.664166668057248, -8.934166659263894],
              [-36.664166668057248, -8.934999992655491],
              [-36.662499998576038, -8.934999992655491],
              [-36.662499999475358, -8.934166658364518],
              [-36.660833333591427, -8.934166659263894],
              [-36.660833333591427, -8.93333332587224],
              [-36.65999999840119, -8.933333329469519],
              [-36.656666665734008, -8.93333332587224],
              [-36.656666668431967, -8.932499993379963],
              [-36.654166665559103, -8.932499993379963],
              [-36.654166667357742, -8.931666659088989],
              [-36.652499999675229, -8.931666659088989],
              [-36.652499998775852, -8.932499992480643],
              [-36.650833332891978, -8.932499993379963],
              [-36.650833332891978, -8.93333332587224],
              [-36.646666665933822, -8.93333332587224],
              [-36.646666666833141, -8.932499993379963],
              [-36.642499999875042, -8.932499993379963],
              [-36.642500000774362, -8.93333332587224],
              [-36.640833333091791, -8.933333324972921],
              [-36.640833333091791, -8.934166659263894],
              [-36.636666666133692, -8.934166659263894],
              [-36.636666667033012, -8.934999992655491],
              [-36.635000001149081, -8.934999992655491],
              [-36.634166666858164, -8.934999991756172],
              [-36.634166667757484, -8.935833326047089],
              [-36.63333333166787, -8.935833326047089],
              [-36.63333333166787, -8.936666659438742],
              [-36.632499999175593, -8.936666659438742],
              [-36.632500000074913, -8.93749999283034],
              [-36.631666666683259, -8.93749999283034],
              [-36.631666666683259, -8.938333326221994],
              [-36.630833331493022, -8.938333326221994],
              [-36.630833331493022, -8.940833326396842],
              [-36.629166666508411, -8.940833326396842],
              [-36.629166665609091, -8.941666657989856],
              [-36.628333332217437, -8.941666658889176],
              [-36.628333332217437, -8.942499993180093],
              [-36.629166666508411, -8.942499993180093],
              [-36.629166666508411, -8.944166659064024],
              [-36.630000000799328, -8.944166659064024],
              [-36.629999999900008, -8.953333327271196],
              [-36.629166666508411, -8.953333326371876],
              [-36.629166667407731, -8.956666659039058],
              [-36.628333334016133, -8.956666658139739],
              [-36.628333332217437, -8.961666659388754],
              [-36.62749999972516, -8.961666659388754],
              [-36.62749999882584, -8.962499993679728],
              [-36.626666668132202, -8.962499992780408],
              [-36.626666665434243, -8.963333326172005],
              [-36.625833332042589, -8.963333326172005],
              [-36.625833332042589, -8.966666658839188],
              [-36.62749999972516, -8.966666658839188],
              [-36.62749999882584, -8.967499992230785],
              [-36.629166667407731, -8.967499993130104],
              [-36.629166666508411, -8.96999999240569],
              [-36.630000000799328, -8.969999993305009],
              [-36.629999999900008, -8.970833325797287],
              [-36.629166666508411, -8.970833325797287],
              [-36.629166666508411, -8.972499993479857],
              [-36.628333334016133, -8.972499993479857],
              [-36.628333333116814, -8.973333325972192],
              [-36.629166667407731, -8.973333325072872],
              [-36.629166665609091, -8.97583332614704],
              [-36.629999999000688, -8.97583332614704],
              [-36.630000000799328, -8.976666660437957],
              [-36.629166667407731, -8.976666658639317],
              [-36.629166667407731, -8.980833324698153],
              [-36.629999999000688, -8.980833327396113],
              [-36.630000000799328, -8.983333325772321],
              [-36.630833331493022, -8.983333325772321],
              [-36.630833332392342, -8.984999993454892],
              [-36.631666666683259, -8.984999993454892],
              [-36.631666666683259, -8.986666659338823],
              [-36.632500000074913, -8.986666660238143],
              [-36.632499999175593, -8.989166659513671],
              [-36.631666665783939, -8.989166659513671],
              [-36.631666668481898, -8.994166658964104],
              [-36.632499999175593, -8.994166659863424],
              [-36.632500000074913, -8.995833324848036],
              [-36.63333333256719, -8.995833326646675],
              [-36.63333333346651, -8.996666658239633],
              [-36.634166667757484, -8.996666658239633],
              [-36.634166667757484, -8.999166659313858],
              [-36.634166665958844, -8.999999992705455],
              [-36.634999998451121, -8.999999992705455],
              [-36.634999999350441, -9.000833326097052],
              [-36.637499999525289, -9.000833326097052],
              [-36.637500000424609, -9.001666659488706],
              [-36.645833333441544, -9.001666659488706],
              [-36.645833332542225, -9.000833326097052],
              [-36.646666666833141, -9.000833325197732],
              [-36.646666666833141, -8.999999992705455],
              [-36.648333332717073, -9.000000003497291],
              [-36.654999999850077, -8.999999992705455],
              [-36.655000000749396, -8.997499993429926],
              [-36.655833331443034, -8.997499993429926],
              [-36.655833331443034, -8.996666659138953],
              [-36.656666666633328, -8.996666659138953],
              [-36.656666665734008, -8.995833325747356],
              [-36.659166665908856, -8.995833325747356],
              [-36.659166665908856, -8.994999993255021],
              [-36.661666666083704, -8.994999993255021],
              [-36.661666666983024, -8.994166658964104],
              [-36.662500000374678, -8.994166658964104],
              [-36.662499999475358, -8.99333332647177],
              [-36.663333332866955, -8.99333332647177],
              [-36.663333332866955, -8.992499993080173],
              [-36.664166667157929, -8.992499993080173],
              [-36.664166668057248, -8.9916666587892],
              [-36.66583333304186, -8.99166665788988],
              [-36.66583333304186, -8.990833326296922],
              [-36.666666668232097, -8.990833326296922],
              [-36.666666665534137, -8.992499992180853],
              [-36.667499998925791, -8.992499993080173],
              [-36.667499998925791, -8.993333324673131],
              [-36.668333331418069, -8.99333332647177],
              [-36.668333334116028, -8.994166658964104],
              [-36.670000000899279, -8.994166658964104],
              [-36.67, -8.994999993255021],
              [-36.670833331592917, -8.994999993255021],
              [-36.670833331592917, -8.996666658239633],
              [-36.67166666588389, -8.996666659138953],
              [-36.67166666588389, -8.998333325922204],
              [-36.672499998376168, -8.998333325922204],
              [-36.672500000174807, -8.999999992705455],
              [-36.674166666958058, -8.999999992705455],
              [-36.674166666058738, -9.000833326097052],
              [-36.67583333194267, -9.000833326097052],
              [-36.675833332841989, -9.001666659488706],
              [-36.676666666233643, -9.001666659488706],
              [-36.676666667132963, -9.002499991980983],
              [-36.67749999962524, -9.002499992880303],
              [-36.67749999962524, -9.004166658764234],
              [-36.678333333016838, -9.004166657864914],
              [-36.678333332117518, -9.005833326446805],
              [-36.68166666658334, -9.005833326446805],
              [-36.68166666568402, -9.006666658039762],
              [-36.683333331567951, -9.006666658939139],
              [-36.683333332467271, -9.008333324823013],
              [-36.684166665858925, -9.008333325722333],
              [-36.684166665858925, -9.009166659113987],
              [-36.684999998351202, -9.009166661811946],
              [-36.68749999942537, -9.009166658214667],
              [-36.68749999852605, -9.010833325897238],
              [-36.688333332817024, -9.010833325897238],
              [-36.688333332817024, -9.011666659288835],
              [-36.689166666208621, -9.011666659288835],
              [-36.689166666208621, -9.012499992680489],
              [-36.690833332092552, -9.012499992680489],
              [-36.690833333891192, -9.013333326072086],
              [-36.691666666383526, -9.013333326072086],
              [-36.691666668182165, -9.01416665946374],
              [-36.692499998875803, -9.01416665946374],
              [-36.692499999775123, -9.014999992855337],
              [-36.693333333166777, -9.014999992855337],
              [-36.693333334066097, -9.015833326246934],
              [-36.694166665659054, -9.015833326246934],
              [-36.694166665659054, -9.016666658739268],
              [-36.694999999949971, -9.016666658739268],
              [-36.694999998151332, -9.017499993030185],
              [-36.695833331542985, -9.017499993030185],
              [-36.695833333341625, -9.019166658914116],
              [-36.696666668531861, -9.019166658914116],
              [-36.696666665833902, -9.01999999320509],
              [-36.697499999225556, -9.01999999320509],
              [-36.697500001024196, -9.020833325697367],
              [-36.698333332617153, -9.020833325697367],
              [-36.698333331717834, -9.021666659988341],
              [-36.699166666908127, -9.021666659089021],
              [-36.699166666008807, -9.022499993379938],
              [-36.700000001199044, -9.022499994279258],
              [-36.699999998501085, -9.023333325872272],
              [-36.700833331892682, -9.023333326771592],
              [-36.700833331892682, -9.024166659263869],
              [-36.701666666183655, -9.024166659263869],
              [-36.701666666183655, -9.026666657640078],
              [-36.702500000474572, -9.026666659438717],
              [-36.702499998675933, -9.033333325672402],
              [-36.703333332067587, -9.033333325672402],
              [-36.703333333866226, -9.034166659063999],
              [-36.705000000649477, -9.034166659063999],
              [-36.705000000649477, -9.034999993354973],
              [-36.707499999925005, -9.034999992455653],
              [-36.707499999925005, -9.03583332584725],
              [-36.709166668506896, -9.03583332584725],
              [-36.709166664909617, -9.036666659238904],
              [-36.710833332592188, -9.036666659238904],
              [-36.710833331692868, -9.038333326022155],
              [-36.711666665983785, -9.038333326022155],
              [-36.711666665983785, -9.040833326197003],
              [-36.712499999375439, -9.040833326197003],
              [-36.712499999375439, -9.046666659938353],
              [-36.713333333666355, -9.046666659039033],
              [-36.713333331867716, -9.048333324922964],
              [-36.714166667058009, -9.048333325822284],
              [-36.714166667058009, -9.049166659213881],
              [-36.714999998650967, -9.049166660113201],
              [-36.714999998650967, -9.049999993504855],
              [-36.716666668132177, -9.049999994404175],
              [-36.716666665434218, -9.050833325997132],
              [-36.717499998825815, -9.050833325997132],
              [-36.717499999725192, -9.051666659388786],
              [-36.718333332217469, -9.051666659388786],
              [-36.718333332217469, -9.054166658664315],
              [-36.717499999725192, -9.054166658664315],
              [-36.717500000624511, -9.057499993130136],
              [-36.716666667232857, -9.057499993130136],
              [-36.716666665434218, -9.058333326521733],
              [-36.713333331867716, -9.058333326521733],
              [-36.713333332767036, -9.059166659014068],
              [-36.714166667957329, -9.059166659014068],
              [-36.714166667058009, -9.063333325972167],
              [-36.714999998650967, -9.063333325972167],
              [-36.715000000449606, -9.06416666026314],
              [-36.717499999725192, -9.064166659363764],
              [-36.717499998825815, -9.064999992755418],
              [-36.718333334016108, -9.064999992755418],
              [-36.718333333116789, -9.065833326147015],
              [-36.719166664709746, -9.065833326147015],
              [-36.719166665609066, -9.066666658639349],
              [-36.71999999900072, -9.066666657740029],
              [-36.720000000799359, -9.067499992930266],
              [-36.720833334190957, -9.067499992930266],
              [-36.720833331492997, -9.06833332632192],
              [-36.721666666683291, -9.06833332632192],
              [-36.721666665783971, -9.071666658089725],
              [-36.722499999175568, -9.071666658989102],
              [-36.722499999175568, -9.07416665916395],
              [-36.723333331667902, -9.07416665916395],
              [-36.723333333466542, -9.074999993454867],
              [-36.724166666858139, -9.074999993454867],
              [-36.724166667757459, -9.075833325947201],
              [-36.724999998451096, -9.075833325047881],
              [-36.725000001149112, -9.076666659338798],
              [-36.72583333274207, -9.076666659338798],
              [-36.72583333274207, -9.077499992730452],
              [-36.728333332017598, -9.077499992730452],
              [-36.728333332916918, -9.078333326122049],
              [-36.729166665409252, -9.078333325222729],
              [-36.729166667207892, -9.079166659513703],
              [-36.730833333091823, -9.079166659513703],
              [-36.730833333991143, -9.07999999200598],
              [-36.73583333164288, -9.0799999929053],
              [-36.7358333325422, -9.083333326471802],
              [-36.736666665933853, -9.083333326471802],
              [-36.736666665034534, -9.084999993255053],
              [-36.735833334340839, -9.084999993255053],
              [-36.73583333164288, -9.086666659138984],
              [-36.736666667732493, -9.086666659138984],
              [-36.736666667732493, -9.087499993429901],
              [-36.73750000112409, -9.087499993429901],
              [-36.737499998426131, -9.089999994504069],
              [-36.736666666833173, -9.08999999270543],
              [-36.736666665933853, -9.090833326097083],
              [-36.737499998426131, -9.090833326097083],
              [-36.737499998426131, -9.094166657864946],
              [-36.738333331817785, -9.094166658764266],
              [-36.738333332717104, -9.094999993055183],
              [-36.739999999500355, -9.094999993055183],
              [-36.739999998601036, -9.095833327346156],
              [-36.740833332891953, -9.095833326446837],
              [-36.740833331093313, -9.096666658939114],
              [-36.741666667182926, -9.096666659838434],
              [-36.74166666538423, -9.098333325722365],
              [-36.739999998601036, -9.098333325722365],
              [-36.740000000399675, -9.104999992855312],
              [-36.740833333791272, -9.104999992855312],
              [-36.744999999850052, -9.104999992855312],
              [-36.744999999850052, -9.104166659463715],
              [-36.746666667532622, -9.104166659463715],
              [-36.746666665733983, -9.104999992855312],
              [-36.749166665908888, -9.104999992855312],
              [-36.749166667707527, -9.104166659463715],
              [-36.750000001099124, -9.104166659463715],
              [-36.749999998401165, -9.103333326072118],
              [-36.756666665534169, -9.103333326072118],
              [-36.756666665534169, -9.102499992680464],
              [-36.759166665709017, -9.102499994479103],
              [-36.759166666608337, -9.101666659288867],
              [-36.760000000899254, -9.101666659288867],
              [-36.76, -9.100833325897213],
              [-36.7624999983762, -9.100833325897213],
              [-36.762500000174839, -9.099999993404936],
              [-36.764999998551048, -9.099999993404936],
              [-36.765000000349687, -9.098333325722365],
              [-36.768333334815509, -9.098333327521004],
              [-36.768333333916189, -9.099166660013282],
              [-36.769166665509147, -9.099166659113962],
              [-36.769166665509147, -9.099999993404936],
              [-36.770833331393078, -9.099999993404936],
              [-36.770833331393078, -9.100833325897213],
              [-36.773333332467303, -9.100833325897213],
              [-36.773333332467303, -9.099999993404936],
              [-36.775833331742831, -9.099999993404936],
              [-36.775833332642151, -9.099166659113962],
              [-36.776666666033748, -9.099166660013282],
              [-36.776666666033748, -9.097499993230031],
              [-36.777499998526082, -9.097499993230031],
              [-36.777499999425402, -9.096666658939114],
              [-36.776666666033748, -9.096666658939114],
              [-36.776666666933068, -9.094999993954502],
              [-36.775833332642151, -9.094999993055183],
              [-36.775833331742831, -9.093333326271932],
              [-36.774999998351177, -9.093333326271932],
              [-36.775000000149817, -9.092499992880335],
              [-36.774166667657539, -9.092499992880335],
              [-36.774166666758219, -9.090833326097083],
              [-36.774999998351177, -9.090833325197764],
              [-36.774999998351177, -9.087499993429901],
              [-36.775833332642151, -9.087499993429901],
              [-36.775833330843511, -9.086666660038304],
              [-36.777500001224041, -9.086666659138984],
              [-36.777500001224041, -9.087499993429901],
              [-36.779166667107972, -9.087499993429901],
              [-36.779166664410013, -9.089166659313832],
              [-36.780833332991904, -9.089166659313832],
              [-36.780833332092584, -9.088333325922179],
              [-36.781666666383501, -9.088333325922179],
              [-36.781666665484181, -9.084999992355733],
              [-36.782500000674474, -9.084999993255053],
              [-36.782500000674474, -9.083333326471802],
              [-36.783333333166752, -9.083333326471802],
              [-36.783333331368112, -9.082499993080148],
              [-36.784999999050683, -9.082499993080148],
              [-36.784999999050683, -9.081666658789231],
              [-36.786666667632574, -9.081666658789231],
              [-36.786666665833934, -9.080833326296897],
              [-36.787500000124851, -9.080833326296897],
              [-36.787499999225531, -9.0799999929053],
              [-36.789166666908102, -9.0799999929053],
              [-36.789166667807422, -9.079166659513703],
              [-36.789999999400436, -9.079166659513703],
              [-36.789999999400436, -9.078333326122049],
              [-36.790833333691353, -9.078333325222729],
              [-36.790833331892713, -9.076666659338798],
              [-36.79166666708295, -9.076666659338798],
              [-36.79166666618363, -9.074999993454867],
              [-36.790833331892713, -9.074999993454867],
              [-36.790833333691353, -9.073333325772296],
              [-36.78999999850106, -9.073333325772296],
              [-36.78999999850106, -9.072499993280019],
              [-36.789166666008782, -9.072499993280019],
              [-36.789166667807422, -9.071666658989102],
              [-36.788333333516505, -9.071666658989102],
              [-36.788333332617185, -9.070833326496768],
              [-36.787499999225531, -9.070833326496768],
              [-36.787500000124851, -9.069166658814197],
              [-36.786666667632574, -9.069166658814197],
              [-36.786666666733254, -9.067499992930266],
              [-36.7858333333416, -9.067499992930266],
              [-36.78583333244228, -9.065833326147015],
              [-36.784999999050683, -9.065833326147015],
              [-36.784999999050683, -9.06416666026314],
              [-36.784166666558349, -9.064166658464444],
              [-36.784166666558349, -9.062499993479889],
              [-36.783333333166752, -9.062499993479889],
              [-36.783333331368112, -9.059999993304984],
              [-36.782500000674474, -9.059999993304984],
              [-36.782499999775098, -9.057499993130136],
              [-36.781666665484181, -9.057499993130136],
              [-36.781666665484181, -9.056666658839163],
              [-36.77999999870093, -9.056666658839163],
              [-36.77999999960025, -9.054999992955288],
              [-36.779166667107972, -9.054999992955288],
              [-36.779166667107972, -9.054166658664315],
              [-36.778333331917679, -9.054166658664315],
              [-36.778333333716319, -9.054999992055969],
              [-36.775833331742831, -9.054999993854608],
              [-36.775833332642151, -9.054166658664315],
              [-36.775000000149817, -9.054166658664315],
              [-36.775000000149817, -9.053333326172037],
              [-36.774166667657539, -9.053333326172037],
              [-36.7741666658589, -9.051666659388786],
              [-36.774999998351177, -9.051666659388786],
              [-36.775000000149817, -9.049999993504855],
              [-36.7741666658589, -9.049999993504855],
              [-36.7741666658589, -9.045833325647436],
              [-36.775833331742831, -9.045833325647436],
              [-36.775833331742831, -9.040833326197003],
              [-36.776666666933068, -9.040833326197003],
              [-36.776666666033748, -9.039999992805349],
              [-36.777500002123361, -9.039999992805349],
              [-36.777499999425402, -9.037499993529821],
              [-36.776666666033748, -9.037499993529821],
              [-36.776666666933068, -9.03583332584725],
              [-36.775833331742831, -9.03583332584725],
              [-36.775833331742831, -9.034166659063999],
              [-36.774999998351177, -9.034166659063999],
              [-36.774999998351177, -9.02583332604712],
              [-36.77583333354147, -9.02583332604712],
              [-36.775833332642151, -9.024166659263869],
              [-36.776666666033748, -9.024166659263869],
              [-36.776666666033748, -9.023333325872272],
              [-36.778333331917679, -9.023333326771592],
              [-36.778333332816999, -9.022499993379938],
              [-36.780833333891223, -9.022499992480618],
              [-36.780833332991904, -9.021666659089021],
              [-36.784999999050683, -9.021666659089021],
              [-36.784999999950003, -9.022499992480618],
              [-36.78583333244228, -9.022499994279258],
              [-36.78583333244228, -9.023333325872272],
              [-36.786666666733254, -9.023333325872272],
              [-36.786666667632574, -9.024166659263869],
              [-36.788333332617185, -9.024166659263869],
              [-36.788333332617185, -9.023333325872272],
              [-36.789166666908102, -9.023333325872272],
              [-36.789166666008782, -9.022499992480618],
              [-36.78999999850106, -9.022499993379938],
              [-36.78999999850106, -9.021666659089021],
              [-36.79166666708295, -9.021666659089021],
              [-36.79166666618363, -9.022499993379938],
              [-36.792499998675964, -9.022499993379938],
              [-36.792500000474604, -9.026666659438717],
              [-36.793333332966881, -9.026666659438717],
              [-36.793333332966881, -9.028333326221968],
              [-36.794166665459215, -9.028333326221968],
              [-36.794166665459215, -9.029166658714303],
              [-36.794999999750132, -9.029166658714303],
              [-36.794999998850813, -9.030833327296193],
              [-36.795833333141786, -9.030833326396873],
              [-36.795833333141786, -9.032499993180068],
              [-36.796666667432703, -9.032499993180068],
              [-36.796666666533383, -9.037499993529821],
              [-36.797499999025717, -9.037499993529821],
              [-36.797499999925037, -9.038333326022155],
              [-36.798333331517995, -9.038333326921475],
              [-36.798333331517995, -9.039166659413752],
              [-36.799166666708231, -9.039166660313072],
              [-36.799166666708231, -9.040833326197003],
              [-36.800000000099885, -9.040833326197003],
              [-36.799999999200566, -9.046666659039033],
              [-36.800833331692843, -9.046666659039033],
              [-36.800833331692843, -9.047499994229327],
              [-36.802499998476094, -9.047499993330007],
              [-36.802499999375414, -9.049166659213881],
              [-36.801666665983817, -9.049166660113201],
              [-36.801666668681776, -9.050833325997132],
              [-36.803333332767068, -9.050833325097813],
              [-36.803333333666387, -9.052499992780383],
              [-36.805833332042596, -9.052499992780383],
              [-36.805833332941916, -9.054999992955288],
              [-36.810000000799334, -9.054999992955288],
              [-36.810833331493029, -9.054999992955288],
              [-36.810833331493029, -9.054166658664315],
              [-36.811666665783946, -9.054166658664315],
              [-36.811666665783946, -9.053333326172037],
              [-36.8124999991756, -9.053333326172037],
              [-36.81250000007492, -9.052499992780383],
              [-36.816666667033019, -9.052499992780383],
              [-36.816666666133699, -9.053333326172037],
              [-36.819166668107187, -9.053333326172037],
              [-36.819166665409227, -9.054166658664315],
              [-36.820833333991118, -9.054166660462954],
              [-36.820833333991118, -9.054999992955288],
              [-36.822499999875049, -9.054999992955288],
              [-36.822499999875049, -9.055833326346885],
              [-36.823333334166023, -9.055833326346885],
              [-36.823333334166023, -9.056666659738482],
              [-36.826666667732468, -9.056666658839163],
              [-36.826666665933828, -9.058333326521733],
              [-36.828333332717079, -9.058333326521733],
              [-36.82833333181776, -9.059166659014068],
              [-36.829999998601011, -9.059166659014068],
              [-36.829999998601011, -9.059999993304984],
              [-36.833333334865472, -9.059999993304984],
              [-36.833333334865472, -9.060833325797319],
              [-36.834999999850083, -9.060833326696638],
              [-36.835000000749403, -9.061666659188916],
              [-36.835833334141, -9.061666660088235],
              [-36.835833331443041, -9.065833325247695],
              [-36.836666666633334, -9.065833327046334],
              [-36.836666665734015, -9.07416665916395],
              [-36.837500000924251, -9.07416665916395],
              [-36.837500000924251, -9.074999991656227],
              [-36.839166665908863, -9.074999993454867],
              [-36.839166667707502, -9.075833325947201],
              [-36.83999999840114, -9.075833325947201],
              [-36.840000001998476, -9.077499992730452],
              [-36.840833333591434, -9.077499992730452],
              [-36.840833333591434, -9.079166659513703],
              [-36.841666666083711, -9.079166659513703],
              [-36.841666666983031, -9.0799999929053],
              [-36.842499998576045, -9.07999999380462],
              [-36.842500000374685, -9.080833325397577],
              [-36.841666666983031, -9.080833326296897],
              [-36.84166666788235, -9.081666659688551],
              [-36.840000001099156, -9.081666658789231],
              [-36.84000000019978, -9.082499993080148],
              [-36.839166665908863, -9.082499993080148],
              [-36.839166667707502, -9.081666658789231],
              [-36.838333332517266, -9.081666658789231],
              [-36.838333331617889, -9.080833326296897],
              [-36.837499999125612, -9.080833326296897],
              [-36.837500000024932, -9.081666658789231],
              [-36.835833334141, -9.081666658789231],
              [-36.835833334141, -9.082499993080148],
              [-36.834999999850083, -9.082499993080148],
              [-36.834999998950764, -9.083333326471802],
              [-36.834166667357749, -9.083333327371122],
              [-36.83416666645843, -9.084166658964079],
              [-36.833333333966152, -9.084166658964079],
              [-36.833333333066832, -9.089166659313832],
              [-36.832499999675179, -9.089166659313832],
              [-36.832499999675179, -9.08999999270543],
              [-36.831666665384262, -9.08999999270543],
              [-36.831666667182901, -9.090833326097083],
              [-36.830833332891984, -9.090833326097083],
              [-36.830833331992608, -9.091666659488681],
              [-36.829999998601011, -9.091666659488681],
              [-36.82999999950033, -9.093333326271932],
              [-36.829166667907373, -9.093333326271932],
              [-36.829166667907373, -9.094166659663586],
              [-36.828333332717079, -9.094166658764266],
              [-36.82833333181776, -9.094999993055183],
              [-36.827500000224802, -9.094999993055183],
              [-36.827500000224802, -9.095833326446837],
              [-36.825833331642912, -9.09583332464814],
              [-36.825833333441551, -9.096666658939114],
              [-36.825000000949217, -9.096666658939114],
              [-36.824999999150577, -9.099166659113962],
              [-36.82416666575898, -9.099166659113962],
              [-36.82416666575898, -9.100833326796533],
              [-36.823333332367326, -9.100833325897213],
              [-36.823333334166023, -9.103333326072118],
              [-36.822500000774369, -9.103333326072118],
              [-36.822499999875049, -9.105833326246966],
              [-36.821666665584132, -9.105833326246966],
              [-36.821666666483452, -9.109999994104385],
              [-36.820833333091798, -9.109999993205065],
              [-36.820833330393839, -9.111666659088996],
              [-36.819999999700201, -9.111666659088996],
              [-36.819999999700201, -9.115833326047095],
              [-36.820833330393839, -9.115833326047095],
              [-36.820833333991118, -9.117499992830346],
              [-36.821666667382772, -9.117499992830346],
              [-36.821666665584132, -9.122499993180099],
              [-36.820833333091798, -9.12249999228078],
              [-36.820833333991118, -9.124999993354947],
              [-36.821666665584132, -9.124999993354947],
              [-36.821666666483452, -9.125833325847282],
              [-36.819999998800881, -9.125833325847282],
              [-36.819999999700201, -9.126666659238879],
              [-36.819999999700201, -9.127499993529852],
              [-36.820833333091798, -9.127499993529852],
              [-36.820833333091798, -9.129166658514407],
              [-36.821666665584132, -9.129166659413727],
              [-36.821666665584132, -9.131666658689312],
              [-36.822499999875049, -9.131666658689312],
              [-36.822499998975729, -9.132499992980229],
              [-36.823333332367326, -9.132499992980229],
              [-36.823333332367326, -9.13416665976348],
              [-36.82416666575898, -9.13416665886416],
              [-36.8241666666583, -9.135833325647411],
              [-36.825000000949217, -9.135833325647411],
              [-36.825000000049897, -9.136666659039065],
              [-36.826666667732468, -9.136666659938385],
              [-36.826666665034509, -9.137499993329982],
              [-36.829166667008053, -9.137499993329982],
              [-36.829166666108733, -9.138333325822259],
              [-36.830833332891984, -9.138333326721579],
              [-36.830833332891984, -9.140833325997164],
              [-36.831666665384262, -9.140833325997164],
              [-36.831666668981541, -9.142499993679735],
              [-36.832500000574498, -9.142499992780415],
              [-36.832499999675179, -9.144166658664346],
              [-36.83416666555911, -9.144166658664346],
              [-36.83416666645843, -9.145833325447541],
              [-36.835000000749403, -9.145833327246237],
              [-36.834999999850083, -9.146666657939875],
              [-36.836666665734015, -9.146666658839194],
              [-36.836666666633334, -9.147499993130111],
              [-36.837500000024932, -9.147499993130111],
              [-36.837499999125612, -9.146666658839194],
              [-36.838333331617889, -9.146666658839194],
              [-36.838333330718569, -9.147499993130111],
              [-36.84000000019978, -9.147499993130111],
              [-36.83999999840114, -9.148333326521765],
              [-36.840833331792794, -9.148333326521765],
              [-36.840833332692114, -9.149999993305016],
              [-36.841666666983031, -9.149999993305016],
              [-36.841666666983031, -9.152499991681225],
              [-36.840833331792794, -9.152499991681225],
              [-36.840833331792794, -9.153333325972142],
              [-36.840000001099156, -9.153333325972142],
              [-36.83999999840114, -9.154166659363796],
              [-36.84000000019978, -9.163333325772328],
              [-36.840833333591434, -9.163333324873008],
              [-36.840833332692114, -9.168333326122081],
              [-36.843333333766282, -9.168333326122081],
              [-36.843333331967642, -9.169166659513678],
              [-36.844166668057255, -9.169166659513678],
              [-36.844166665359296, -9.169999992905275],
              [-36.844999998750893, -9.169999993804652],
              [-36.844999998750893, -9.170833326296929],
              [-36.844999999650213, -9.17249999308018],
              [-36.846666666433464, -9.1724999939795],
              [-36.846666666433464, -9.173333326471777],
              [-36.847499999825061, -9.173333326471777],
              [-36.847499999825061, -9.17249999308018],
              [-36.848333333216715, -9.17249999308018],
              [-36.848333333216715, -9.171666658789206],
              [-36.849166666608312, -9.171666658789206],
              [-36.849166667507632, -9.170833326296929],
              [-36.853333333566468, -9.170833327196249],
              [-36.853333331767828, -9.171666658789206],
              [-36.857499999625247, -9.171666658789206],
              [-36.857500000524567, -9.174999992355708],
              [-36.856666666233593, -9.174999993255028],
              [-36.856666666233593, -9.176666659138959],
              [-36.855833332841996, -9.176666659138959],
              [-36.855833333741316, -9.177499993429933],
              [-36.854999998551023, -9.177499993429933],
              [-36.855000000349719, -9.179166659313807],
              [-36.855833332841996, -9.179166659313807],
              [-36.855833332841996, -9.181666659488712],
              [-36.856666668032233, -9.181666659488712],
              [-36.856666666233593, -9.184999993055214],
              [-36.855833332841996, -9.184999992155895],
              [-36.855833331942677, -9.187499993230063],
              [-36.856666666233593, -9.187499993230063],
              [-36.856666667132913, -9.189999993404911],
              [-36.859166665509179, -9.189999993404911],
              [-36.859166665509179, -9.190833325897245],
              [-36.859999999800095, -9.190833325897245],
              [-36.859999999800095, -9.191666659288842],
              [-36.863333331567958, -9.191666659288842],
              [-36.863333331567958, -9.192499992680496],
              [-36.864166666758194, -9.192499992680496],
              [-36.864166667657571, -9.193333326072093],
              [-36.865833331742806, -9.193333326072093],
              [-36.865833331742806, -9.194999991956024],
              [-36.86666666603378, -9.194999992855344],
              [-36.866666666933099, -9.195833326246941],
              [-36.867499999425377, -9.195833326246941],
              [-36.867500001224016, -9.196666658739275],
              [-36.868333332817031, -9.196666658739275],
              [-36.868333331917711, -9.197499993030192],
              [-36.871666665484156, -9.197499993030192],
              [-36.871666665484156, -9.198333326421846],
              [-36.875833331542992, -9.198333326421846],
              [-36.875833332442312, -9.201666659088971],
              [-36.876666665833909, -9.201666659088971],
              [-36.876666666733229, -9.202499993379945],
              [-36.87833333171784, -9.202499993379945],
              [-36.879999999400411, -9.202499993379945],
              [-36.880000001199051, -9.203333325872222],
              [-36.882500000474579, -9.203333325872222],
              [-36.882499998675939, -9.204166659263876],
              [-36.883333332067593, -9.204166659263876],
              [-36.883333332067593, -9.204999992655473],
              [-36.88416666545919, -9.204999991756154],
              [-36.88416666545919, -9.205833326047127],
              [-36.887500000824332, -9.205833326946447],
              [-36.887499999025692, -9.206666657640085],
              [-36.888333334215986, -9.206666659438724],
              [-36.888333334215986, -9.207499992830378],
              [-36.893333331867723, -9.207499992830378],
              [-36.893333332767043, -9.208333325322656],
              [-36.895833332941891, -9.208333326221975],
              [-36.895833332941891, -9.20916665871431],
              [-36.898333333116796, -9.20916665871431],
              [-36.898333332217476, -9.209999993005226],
              [-36.899166665609073, -9.209999992105907],
              [-36.899166667407712, -9.210833326396823],
              [-36.900833331493004, -9.210833326396823],
              [-36.900833334190963, -9.211666658889158],
              [-36.901666665783978, -9.211666658889158],
              [-36.901666665783978, -9.212499992280755],
              [-36.902500000974214, -9.212499994079394],
              [-36.902499999175575, -9.214166659064006],
              [-36.903333333466549, -9.214166659064006],
              [-36.903333333466549, -9.214999993354979],
              [-36.905000001149062, -9.214999993354979],
              [-36.904999999350423, -9.214166659064006],
              [-36.906666667032994, -9.214166659064006],
              [-36.906666666133674, -9.213333325672409],
              [-36.907499999525328, -9.213333325672409],
              [-36.907500000424648, -9.212499994079394],
              [-36.909166667207899, -9.212499993180074],
              [-36.909166665409259, -9.214166659963325],
              [-36.908333333816245, -9.214166659064006],
              [-36.908333332017605, -9.214999993354979],
              [-36.906666667032994, -9.214999993354979],
              [-36.906666666133674, -9.215833325847257],
              [-36.905833332742077, -9.215833324947937],
              [-36.905833332742077, -9.216666659238911],
              [-36.905000001149062, -9.216666659238911],
              [-36.904999999350423, -9.218333326022105],
              [-36.904166667757465, -9.218333325122785],
              [-36.904166665958826, -9.219999992805356],
              [-36.902500000074895, -9.219999993704675],
              [-36.902499999175575, -9.22083332619701],
              [-36.901666665783978, -9.22083332619701],
              [-36.901666665783978, -9.223333327271177],
              [-36.902500000974214, -9.223333326371858],
              [-36.902499999175575, -9.225833325647386],
              [-36.901666666683298, -9.225833325647386],
              [-36.901666666683298, -9.227499992430637],
              [-36.902500000974214, -9.227499994229277],
              [-36.902500000974214, -9.22666665903904],
              [-36.904999998451103, -9.22666665903904],
              [-36.904999998451103, -9.225833327446082],
              [-36.905833331842757, -9.225833325647386],
              [-36.905833332742077, -9.224999993155109],
              [-36.906666666133674, -9.224999993155109],
              [-36.906666666133674, -9.224166659763512],
              [-36.907499998626008, -9.224166658864192],
              [-36.907499999525328, -9.223333326371858],
              [-36.910000000599496, -9.223333327271177],
              [-36.909999998800856, -9.222499992980261],
              [-36.91083333309183, -9.222499992980261],
              [-36.91083333219251, -9.221666658689287],
              [-36.911666665584107, -9.221666657789967],
              [-36.911666665584107, -9.220833327096329],
              [-36.914166665758955, -9.22083332619701],
              [-36.914166666658275, -9.219999992805356],
              [-36.915833332542206, -9.219999992805356],
              [-36.915833331642887, -9.22083332619701],
              [-36.918333332717111, -9.22083332619701],
              [-36.918333332717111, -9.221666658689287],
              [-36.919166667907348, -9.221666658689287],
              [-36.919166667907348, -9.222499992980261],
              [-36.920000000399682, -9.222499992980261],
              [-36.919999998600986, -9.223333326371858],
              [-36.929166665908838, -9.223333325472538],
              [-36.929166665908838, -9.222499992980261],
              [-36.930000000199811, -9.22249999387958],
              [-36.929999998401172, -9.221666658689287],
              [-36.930833332692089, -9.221666658689287],
              [-36.930833331792769, -9.22083332619701],
              [-36.93249999947534, -9.22083332619701],
              [-36.93250000037466, -9.216666659238911],
              [-36.931666666983062, -9.216666659238911],
              [-36.931666666983062, -9.215833325847257],
              [-36.930833331792769, -9.215833324947937],
              [-36.930833331792769, -9.214999993354979],
              [-36.929999998401172, -9.21499999245566],
              [-36.929999998401172, -9.213333325672409],
              [-36.930833334490728, -9.213333325672409],
              [-36.930833333591409, -9.208333325322656],
              [-36.931666666083743, -9.208333326221975],
              [-36.931666666983062, -9.207499992830378],
              [-36.933333333766313, -9.207499992830378],
              [-36.933333331967674, -9.206666659438724],
              [-36.936666668232135, -9.206666659438724],
              [-36.936666665534119, -9.207499992830378],
              [-36.940833331592955, -9.207499992830378],
              [-36.940833333391595, -9.206666659438724],
              [-36.942500000174846, -9.206666659438724],
              [-36.942500000174846, -9.207499991931059],
              [-36.943333332667123, -9.207499992830378],
              [-36.943333332667123, -9.208333326221975],
              [-36.944166666958097, -9.208333327121295],
              [-36.944166666958097, -9.211666658889158],
              [-36.944999999450374, -9.211666658889158],
              [-36.945000000349694, -9.213333325672409],
              [-36.945833333741291, -9.213333325672409],
              [-36.945833332841971, -9.214166658164686],
              [-36.946666668032265, -9.214166659064006],
              [-36.946666666233625, -9.216666659238911],
              [-36.947499999625222, -9.216666659238911],
              [-36.947499998725903, -9.217499993529827],
              [-36.948333333016876, -9.217499993529827],
              [-36.948333333016876, -9.218333326022105],
              [-36.950833331393085, -9.218333326921481],
              [-36.950833333191724, -9.219166658514439],
              [-36.952499999974975, -9.219166659413759],
              [-36.952499999075656, -9.219999992805356],
              [-36.954166665858907, -9.219999992805356],
              [-36.954166665858907, -9.221666660487926],
              [-36.956666666933074, -9.221666658689287],
              [-36.956666666933074, -9.222499992980261],
              [-36.957499998526089, -9.222499992980261],
              [-36.957499998526089, -9.223333326371858],
              [-36.959166667107979, -9.223333326371858],
              [-36.95916666620866, -9.224166658864192],
              [-36.960833332991854, -9.224166658864192],
              [-36.96083333389123, -9.224999993155109],
              [-36.962499999775105, -9.224999994054428],
              [-36.962500000674424, -9.22666665903904],
              [-36.963333333166759, -9.22666665903904],
              [-36.963333331368119, -9.227499993329957],
              [-36.964166666558356, -9.227499992430637],
              [-36.964166665659036, -9.228333325822291],
              [-36.964166666558356, -9.229166659213888],
              [-36.963333331368119, -9.229166659213888],
              [-36.963333333166759, -9.230833325997139],
              [-36.962499999775105, -9.230833325997139],
              [-36.962499999775105, -9.231666659388793],
              [-36.961666666383508, -9.231666660288113],
              [-36.961666666383508, -9.23249999278039],
              [-36.960833332092534, -9.23249999188107],
              [-36.960833332991854, -9.233333326172044],
              [-36.959999999600257, -9.233333326172044],
              [-36.959999999600257, -9.234166658664321],
              [-36.95916666620866, -9.234166657765002],
              [-36.95916666620866, -9.234999992955238],
              [-36.958333333716325, -9.234999992955238],
              [-36.958333333716325, -9.235833326346892],
              [-36.957499998526089, -9.235833327246212],
              [-36.957499999425409, -9.237499993130143],
              [-36.955833332642158, -9.237499993130143],
              [-36.955833331742838, -9.23833332742106],
              [-36.954999998351184, -9.238333324723101],
              [-36.954999998351184, -9.239999993304991],
              [-36.953333332467253, -9.239999993304991],
              [-36.953333331567933, -9.240833324898006],
              [-36.952500000874295, -9.240833324898006],
              [-36.952500000874295, -9.241666659188922],
              [-36.951666666583378, -9.241666660088242],
              [-36.951666667482698, -9.245833326147022],
              [-36.953333333366572, -9.245833326147022],
              [-36.953333331567933, -9.254166659163957],
              [-36.954166667657546, -9.254166659163957],
              [-36.954166665858907, -9.255833325947208],
              [-36.955000001049143, -9.255833325947208],
              [-36.955000001049143, -9.259166659513653],
              [-36.954166666758226, -9.259166660412973],
              [-36.954166665858907, -9.262499993080155],
              [-36.953333331567933, -9.262499993080155],
              [-36.953333333366572, -9.264166658964086],
              [-36.954166665858907, -9.264166658964086],
              [-36.954166666758226, -9.265833325747337],
              [-36.955000000149823, -9.265833325747337],
              [-36.954999998351184, -9.266666659138934],
              [-36.955833332642158, -9.266666658239615],
              [-36.955833333541477, -9.267499993429908],
              [-36.956666666033755, -9.267499991631269],
              [-36.956666667832394, -9.268333325922185],
              [-36.957499998526089, -9.268333325922185],
              [-36.957499998526089, -9.269166659313839],
              [-36.958333331917686, -9.26916665841452],
              [-36.958333332817006, -9.272499992880341],
              [-36.959166667107979, -9.272499992880341],
              [-36.959166668007299, -9.273333325372619],
              [-36.952500000874295, -9.273333326271938],
              [-36.952499999974975, -9.272499992880341],
              [-36.949166665509154, -9.272499991981022],
              [-36.949166665509154, -9.273333326271938],
              [-36.944999999450374, -9.273333326271938],
              [-36.944999998551054, -9.274999993055189],
              [-36.943333331767803, -9.274999993055189],
              [-36.943333332667123, -9.275833326446786],
              [-36.940833335190234, -9.275833324648147],
              [-36.940833332492275, -9.276666658939121],
              [-36.94, -9.276666658939121],
              [-36.939999999100621, -9.275833326446786],
              [-36.939166666608344, -9.275833326446786],
              [-36.939166666608344, -9.279999993404942],
              [-36.93833333321669, -9.279999993404942],
              [-36.93833333411601, -9.281666659288874],
              [-36.937499998925773, -9.281666659288874],
              [-36.937499998925773, -9.283333326072068],
              [-36.936666668232135, -9.283333326072068],
              [-36.936666665534119, -9.285833326246973],
              [-36.935833333041842, -9.285833327146293],
              [-36.935833333041842, -9.288333326421821],
              [-36.934999998750925, -9.288333326421821],
              [-36.934999999650245, -9.293333324972934],
              [-36.934166665359271, -9.293333325872254],
              [-36.93416666805723, -9.294166659263851],
              [-36.933333333766313, -9.294166659263851],
              [-36.933333331967674, -9.299999992105882],
              [-36.93250000037466, -9.299999993005201],
              [-36.93249999947534, -9.300833326396855],
              [-36.931666666083743, -9.300833326396855],
              [-36.931666667882382, -9.301666657989813],
              [-36.930833332692089, -9.301666658889133],
              [-36.930833332692089, -9.302499993180106],
              [-36.929999998401172, -9.302499993180106],
              [-36.929999998401172, -9.305833325847289],
              [-36.928333331617921, -9.305833325847289],
              [-36.928333332517241, -9.308333326022137],
              [-36.927499999125644, -9.308333326022137],
              [-36.927499999125644, -9.311666658689319],
              [-36.926666667532629, -9.311666660487958],
              [-36.92666666573399, -9.312499992080916],
              [-36.924166665559142, -9.312499992980236],
              [-36.924166665559142, -9.311666658689319],
              [-36.923333333066807, -9.311666658689319],
              [-36.923333333966127, -9.309999992805388],
              [-36.92249999967521, -9.309999992805388],
              [-36.922499998775891, -9.308333326022137],
              [-36.921666667182876, -9.308333326022137],
              [-36.921666667182876, -9.305833325847289],
              [-36.92083333199264, -9.305833325847289],
              [-36.92083333199264, -9.303333325672384],
              [-36.919999999500305, -9.303333325672384],
              [-36.919999998600986, -9.301666659788452],
              [-36.918333333616431, -9.301666658889133],
              [-36.918333331817792, -9.297499992830353],
              [-36.91666666593386, -9.297499992830353],
              [-36.91666666683318, -9.296666659438756],
              [-36.915833332542206, -9.296666659438756],
              [-36.915833331642887, -9.293333325872254],
              [-36.915000000949249, -9.293333325872254],
              [-36.915000000049929, -9.29249999337992],
              [-36.911666666483427, -9.29249999337992],
              [-36.911666665584107, -9.291666658189683],
              [-36.91083333309183, -9.291666659089003],
              [-36.91083333219251, -9.290833325697349],
              [-36.908582228027385, -9.290833325697349],
              [-36.913692247, -9.296055646999951],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '135',
        OBJECTID: 189.0,
        Nome_area: 'Juazeiro',
        COD_area: 'CA198',
        Import_bio: 'Extremamente Alta',
        Prior_acao: 'Muito Alta',
        Acao_princ: 'Criação de UC de Proteção Integral',
        Area_ha: 274868.382,
        Shape_Leng: 5.345,
        Shape_Area: 0.22628,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.07183997, -9.07296102199996],
              [-40.073539727, -9.075362060999955],
              [-40.077395979999899, -9.07822641399996],
              [-40.08527624, -9.082585303999958],
              [-40.092469718, -9.085915494999938],
              [-40.094472319, -9.087508647999922],
              [-40.0987211079999, -9.090888733999977],
              [-40.100031413000011, -9.092301145999954],
              [-40.100390885, -9.092688629999945],
              [-40.100600712999899, -9.092914808999984],
              [-40.101729685, -9.094131757999952],
              [-40.103054943, -9.094909340999946],
              [-40.107420552999898, -9.097470645999985],
              [-40.111282101, -9.098110658999925],
              [-40.119867566, -9.102096899999943],
              [-40.124054212, -9.107356496999964],
              [-40.127221398, -9.109764923999924],
              [-40.12902302, -9.110128601999959],
              [-40.12989492, -9.110304605999932],
              [-40.134291135, -9.109488796999935],
              [-40.13625209, -9.109124899999921],
              [-40.1363827489999, -9.109062465999944],
              [-40.137424833, -9.108564514999955],
              [-40.137499999935883, -9.108528597072038],
              [-40.137499999725151, -9.108333326421814],
              [-40.136666667232873, -9.108333326421814],
              [-40.136666667232873, -9.107499993030217],
              [-40.135833334740539, -9.107499993030217],
              [-40.1358333329419, -9.105833326246966],
              [-40.1358333329419, -9.100833325897213],
              [-40.135000000449622, -9.100833325897213],
              [-40.135000000449622, -9.099999993404936],
              [-40.134166667057968, -9.099999994304255],
              [-40.134166667957345, -9.099166739153645],
              [-40.134166667957345, -9.099166659113962],
              [-40.129166666708272, -9.099166659113962],
              [-40.129166666708272, -9.098333325722365],
              [-40.124999999750173, -9.098333325722365],
              [-40.124999998850797, -9.096666658939114],
              [-40.123333332067602, -9.096666658939114],
              [-40.123333333866242, -9.09583332464814],
              [-40.120833332792017, -9.095833326446837],
              [-40.120833332792017, -9.094999993055183],
              [-40.1199999985011, -9.094999993055183],
              [-40.11999999940042, -9.094166658764266],
              [-40.118333333516489, -9.094166658764266],
              [-40.118333332617169, -9.093333326271932],
              [-40.117500001024212, -9.093333326271932],
              [-40.117499997426876, -9.092499992880335],
              [-40.115833331543001, -9.092499992880335],
              [-40.115833331543001, -9.090833326097083],
              [-40.115000000849307, -9.090833326097083],
              [-40.115000001748626, -9.08999999270543],
              [-40.111666666383485, -9.08999999270543],
              [-40.10833333281704, -9.08999999270543],
              [-40.108333331917663, -9.089166659313832],
              [-40.107499998526066, -9.089166658414513],
              [-40.107500001224025, -9.083333326471802],
              [-40.106666666933108, -9.083333326471802],
              [-40.105833331742815, -9.083333326471802],
              [-40.105833331742815, -9.082499993080148],
              [-40.104999998351218, -9.082499993080148],
              [-40.104999997451898, -9.081666658789231],
              [-40.103333333366606, -9.081666658789231],
              [-40.103333333366606, -9.0799999929053],
              [-40.101666665684036, -9.0799999929053],
              [-40.101666665684036, -9.079166659513703],
              [-40.102499999974953, -9.079166659513703],
              [-40.102500000874272, -9.075833326846521],
              [-40.103333332467287, -9.075833325947201],
              [-40.103333332467287, -9.074999993454867],
              [-40.104166665858884, -9.074999993454867],
              [-40.104166666758204, -9.07416665826463],
              [-40.103333331567967, -9.07416665916395],
              [-40.103333331567967, -9.073333324872976],
              [-40.100833331393062, -9.073333325772296],
              [-40.100833331393062, -9.072499993280019],
              [-40.099166665509188, -9.072499993280019],
              [-40.099166666408507, -9.071666658989102],
              [-40.097499999625256, -9.071666658989102],
              [-40.097499998725937, -9.069166658814197],
              [-40.092500000174823, -9.069166657914877],
              [-40.092500000174823, -9.06833332632192],
              [-40.091666667682546, -9.06833332632192],
              [-40.091666665883906, -9.067499992930266],
              [-40.09, -9.067499992930266],
              [-40.089999999100655, -9.066666658639349],
              [-40.088333331418085, -9.066666657740029],
              [-40.088333333216724, -9.065833326147015],
              [-40.08749999892575, -9.065833326147015],
              [-40.08749999982507, -9.064999992755418],
              [-40.086666666433473, -9.064999993654737],
              [-40.085000000549542, -9.064999992755418],
              [-40.084999997851583, -9.066666658639349],
              [-40.084166667157945, -9.066666658639349],
              [-40.084166667157945, -9.067499992930266],
              [-40.084999997851583, -9.067499992930266],
              [-40.084999999650222, -9.06833332632192],
              [-40.083333332866971, -9.06833332632192],
              [-40.08333333466561, -9.067499992930266],
              [-40.08166666608372, -9.067499992930266],
              [-40.08166666608372, -9.066666658639349],
              [-40.078333331617898, -9.066666658639349],
              [-40.078333332517218, -9.06833332632192],
              [-40.077500000024941, -9.06833332632192],
              [-40.077500000024941, -9.069166658814197],
              [-40.076666666633344, -9.069166658814197],
              [-40.076666667532663, -9.069999993105171],
              [-40.07583333144305, -9.069999993105171],
              [-40.07583333234237, -9.072499993280019],
              [-40.074999998950773, -9.072499993280019],
              [-40.075000000749412, -9.073333325772296],
              [-40.074166665559119, -9.073333325772296],
              [-40.074166666458439, -9.07416666006327],
              [-40.073333333966161, -9.07416665916395],
              [-40.073333333966161, -9.073333325772296],
              [-40.072499999675188, -9.073333325772296],
              [-40.072500000574507, -9.072499993280019],
              [-40.071702149510209, -9.072499993280019],
              [-40.07183997, -9.07296102199996],
            ],
          ],
          [
            [
              [-40.159720784999898, -9.107873317999967],
              [-40.163086733999897, -9.107308969999956],
              [-40.1650015869999, -9.106487774999957],
              [-40.166343486999899, -9.105372710999974],
              [-40.167647316999897, -9.103161073999964],
              [-40.171082725, -9.093449032999963],
              [-40.174137050999903, -9.090559379999956],
              [-40.180583236, -9.087784014999945],
              [-40.184088698, -9.085295144999989],
              [-40.194313437999902, -9.074253593999968],
              [-40.19440804, -9.074151434999971],
              [-40.196503774999897, -9.071888279999941],
              [-40.201119252999902, -9.068465766999955],
              [-40.208827534, -9.063782297999976],
              [-40.209166666617762, -9.063576193002863],
              [-40.209166666908118, -9.062499994379209],
              [-40.205833332442296, -9.062499993479889],
              [-40.205833331542976, -9.061666659188916],
              [-40.204999999050699, -9.061666659188916],
              [-40.204999999950019, -9.060833325797319],
              [-40.203333333166768, -9.060833325797319],
              [-40.203333333166768, -9.059999993304984],
              [-40.202499999775114, -9.059999993304984],
              [-40.202499998875794, -9.059166659014068],
              [-40.201666668182156, -9.059166659014068],
              [-40.201666665484197, -9.058333326521733],
              [-40.202499999775114, -9.058333326521733],
              [-40.202499998875794, -9.056666658839163],
              [-40.203333333166768, -9.056666657939843],
              [-40.203333333166768, -9.055833325447566],
              [-40.200833332991863, -9.055833326346885],
              [-40.200833333891183, -9.054999992955288],
              [-40.198333332817015, -9.054999993854608],
              [-40.198333332817015, -9.054166658664315],
              [-40.197499999425418, -9.054166658664315],
              [-40.197499998526041, -9.053333326172037],
              [-40.198333331917695, -9.053333326172037],
              [-40.198333332817015, -9.051666660288106],
              [-40.197499998526041, -9.051666659388786],
              [-40.197499999425418, -9.050833325997132],
              [-40.195833333541486, -9.050833325997132],
              [-40.195833331742847, -9.049166659213881],
              [-40.195000001049152, -9.049166659213881],
              [-40.195000000149832, -9.047499993330007],
              [-40.19166666658333, -9.047499993330007],
              [-40.191666665684011, -9.049166659213881],
              [-40.190833334091053, -9.049166659213881],
              [-40.190833333191733, -9.049999993504855],
              [-40.189166664609843, -9.049999993504855],
              [-40.189166665509163, -9.051666659388786],
              [-40.188333333916205, -9.051666659388786],
              [-40.188333332117566, -9.052499991881064],
              [-40.189166666408482, -9.052499993679703],
              [-40.189166666408482, -9.053333325272718],
              [-40.18999999890076, -9.053333325272718],
              [-40.18999999890076, -9.054166658664315],
              [-40.19166666748265, -9.054166660462954],
              [-40.191666665684011, -9.053333326172037],
              [-40.193333332467262, -9.053333326172037],
              [-40.193333333366581, -9.054166658664315],
              [-40.195000000149832, -9.054166658664315],
              [-40.195000000149832, -9.054999992955288],
              [-40.195833332642167, -9.054999992955288],
              [-40.195833332642167, -9.058333324723094],
              [-40.196666666933083, -9.058333326521733],
              [-40.196666666933083, -9.059166659014068],
              [-40.195833333541486, -9.059166659014068],
              [-40.195833332642167, -9.059999993304984],
              [-40.194166666758235, -9.059999993304984],
              [-40.194166667657555, -9.061666659188916],
              [-40.19166666658333, -9.061666660088235],
              [-40.191666665684011, -9.06249999168125],
              [-40.189166666408482, -9.062499993479889],
              [-40.189166668207122, -9.063333325972167],
              [-40.187499998725912, -9.063333325972167],
              [-40.187499999625231, -9.064166658464444],
              [-40.186666666233634, -9.064166659363764],
              [-40.186666666233634, -9.064999992755418],
              [-40.184999998551064, -9.064999992755418],
              [-40.184999998551064, -9.065833326147015],
              [-40.184166666958049, -9.065833326147015],
              [-40.184166666958049, -9.066666658639349],
              [-40.183333332667132, -9.066666658639349],
              [-40.183333331767813, -9.067499992930266],
              [-40.180833332492284, -9.067499992930266],
              [-40.180833330693588, -9.069166658814197],
              [-40.182500001973494, -9.069166658814197],
              [-40.182500000174798, -9.069999993105171],
              [-40.183333331767813, -9.069999993105171],
              [-40.183333332667132, -9.070833327396087],
              [-40.184166667857369, -9.070833326496768],
              [-40.184166666958049, -9.073333325772296],
              [-40.1858333337413, -9.073333325772296],
              [-40.185833331942661, -9.07416665916395],
              [-40.187499997826592, -9.07416665916395],
              [-40.187500000524551, -9.074999991656227],
              [-40.188333333016885, -9.074999994354187],
              [-40.188333332117566, -9.077499992730452],
              [-40.187499998725912, -9.077499992730452],
              [-40.187499998725912, -9.078333326122049],
              [-40.18583333284198, -9.078333326122049],
              [-40.18583333284198, -9.079166659513703],
              [-40.184166667857369, -9.079166659513703],
              [-40.184166667857369, -9.0799999929053],
              [-40.182500000174798, -9.0799999929053],
              [-40.182499997476839, -9.080833326296897],
              [-40.181666666783201, -9.080833326296897],
              [-40.181666666783201, -9.081666657889912],
              [-40.180833331592908, -9.081666657889912],
              [-40.180833331592908, -9.082499993080148],
              [-40.179166665709033, -9.082499993979468],
              [-40.179166666608353, -9.083333326471802],
              [-40.17833333141806, -9.083333324673163],
              [-40.178333334116019, -9.08416665806476],
              [-40.176666668232087, -9.084166658964079],
              [-40.176666665534128, -9.084999993255053],
              [-40.17416666715792, -9.084999993255053],
              [-40.17416666535928, -9.08583332574733],
              [-40.172500000374669, -9.08583332574733],
              [-40.172499998576029, -9.086666659138984],
              [-40.169999998401181, -9.086666659138984],
              [-40.169999998401181, -9.087499993429901],
              [-40.169166665009527, -9.087499993429901],
              [-40.169166667707486, -9.088333325922179],
              [-40.166666667532638, -9.088333325922179],
              [-40.166666666633319, -9.08999999270543],
              [-40.164999998950748, -9.08999999270543],
              [-40.164999999850068, -9.090833326097083],
              [-40.1624999987759, -9.090833326097083],
              [-40.162500000574539, -9.091666657690041],
              [-40.1624999987759, -9.093333326271932],
              [-40.161666665384246, -9.093333326271932],
              [-40.161666667182885, -9.094166658764266],
              [-40.160833332891968, -9.094166658764266],
              [-40.160833332891968, -9.096666659838434],
              [-40.161666665384246, -9.096666658939114],
              [-40.161666667182885, -9.098333325722365],
              [-40.1624999987759, -9.098333325722365],
              [-40.162500000574539, -9.099999993404936],
              [-40.16416666645847, -9.099999993404936],
              [-40.16416666645847, -9.100833325897213],
              [-40.165000000749387, -9.100833325897213],
              [-40.165000000749387, -9.103333326971438],
              [-40.165833331443025, -9.103333326072118],
              [-40.165833332342345, -9.104166660363035],
              [-40.165000000749387, -9.104166659463715],
              [-40.164999998950748, -9.104999992855312],
              [-40.163333333066817, -9.104999992855312],
              [-40.163333333066817, -9.105833327146286],
              [-40.160833331992649, -9.105833325347646],
              [-40.160833331992649, -9.106666658739243],
              [-40.153333332367367, -9.106666658739243],
              [-40.153333332367367, -9.107323502057062],
              [-40.159720784999898, -9.107873317999967],
            ],
          ],
          [
            [
              [-40.305283043, -9.25776121699994],
              [-40.305288689, -9.258869719999947],
              [-40.305294696, -9.260049150999983],
              [-40.305309178999899, -9.262892556999924],
              [-40.307787122, -9.271320671999934],
              [-40.309535371, -9.275506664999973],
              [-40.311837941, -9.27903298399997],
              [-40.314254112999897, -9.282404272999941],
              [-40.319031351, -9.287269243999965],
              [-40.325613057999902, -9.305856004999955],
              [-40.331612350999897, -9.316014756999953],
              [-40.332531933, -9.317571898999965],
              [-40.336119588, -9.327168681999972],
              [-40.337119524999899, -9.33140228799993],
              [-40.337337557999902, -9.335067964999944],
              [-40.336586161, -9.341810020999961],
              [-40.334285490999903, -9.347116413999972],
              [-40.333754074, -9.350538338999973],
              [-40.33504333699991, -9.353901723999975],
              [-40.338865537999901, -9.359891761999949],
              [-40.348707541, -9.368664075999954],
              [-40.351102461, -9.371444061999973],
              [-40.351359628999901, -9.371742577999942],
              [-40.352611496, -9.373195724999968],
              [-40.353414766999897, -9.374128148999944],
              [-40.353794658, -9.374569119999924],
              [-40.3541982, -9.375037544999937],
              [-40.356397113, -9.37699426299997],
              [-40.359031017999897, -9.378129186999955],
              [-40.361261538999898, -9.378681442999943],
              [-40.36369945, -9.379285005999979],
              [-40.371415334999902, -9.378716737999975],
              [-40.378333332967969, -9.376435504380261],
              [-40.378333331018382, -9.375833326246948],
              [-40.377499998526048, -9.375833327146267],
              [-40.376666666033771, -9.375833326246948],
              [-40.37666666693309, -9.374999992855351],
              [-40.376666665134451, -9.371666659288849],
              [-40.376666666033771, -9.366666658039776],
              [-40.377499998526048, -9.366666658939096],
              [-40.377499999425368, -9.364166658764248],
              [-40.378333331917702, -9.364166657864928],
              [-40.378333332817022, -9.36333332627197],
              [-40.379999998700953, -9.36333332627197],
              [-40.379999998700953, -9.361666659488719],
              [-40.38083333209255, -9.361666660388039],
              [-40.380833333891189, -9.359999992705468],
              [-40.382499999775121, -9.359999992705468],
              [-40.382499998875801, -9.358333325922217],
              [-40.381666665484204, -9.358333326821537],
              [-40.381666665484204, -9.357499994329203],
              [-40.379999998700953, -9.357499993429883],
              [-40.380000000499592, -9.355833325747312],
              [-40.379166668007258, -9.355833325747312],
              [-40.379166666208619, -9.354166658964118],
              [-40.377499999425368, -9.354166658964118],
              [-40.377499998526048, -9.353333326471784],
              [-40.37666666783241, -9.353333326471784],
              [-40.37666666783241, -9.352499993080187],
              [-40.375833332642117, -9.352499993080187],
              [-40.375833332642117, -9.349166659513685],
              [-40.37666666693309, -9.349166659513685],
              [-40.376666666033771, -9.345833325947183],
              [-40.377499998526048, -9.345833325947183],
              [-40.377499998526048, -9.344166659163932],
              [-40.378333332817022, -9.344166659163932],
              [-40.378333332817022, -9.343333325772335],
              [-40.379166668007258, -9.343333325772335],
              [-40.379166668007258, -9.342499993280001],
              [-40.383333331368078, -9.342499993280001],
              [-40.383333331368078, -9.341666658989084],
              [-40.385833331542983, -9.341666658989084],
              [-40.385833332442303, -9.34083332649675],
              [-40.387499999225554, -9.34083332649675],
              [-40.387500000124874, -9.338333326321901],
              [-40.386666667632539, -9.338333326321901],
              [-40.38666666673322, -9.336666657740011],
              [-40.387500001024193, -9.336666658639331],
              [-40.387500001024193, -9.332499993479871],
              [-40.386666667632539, -9.332499993479871],
              [-40.3866666658339, -9.331666659188897],
              [-40.385833331542983, -9.331666659188897],
              [-40.385833332442303, -9.330833330293899],
              [-40.384999999949969, -9.3308333257973],
              [-40.384999999050649, -9.331666659188897],
              [-40.38083333299187, -9.331666658289578],
              [-40.38083333299187, -9.33083332669662],
              [-40.379999998700953, -9.3308333257973],
              [-40.379999997801633, -9.329999993305023],
              [-40.378333333716341, -9.329999993305023],
              [-40.378333332817022, -9.328333326521772],
              [-40.374166665858922, -9.328333326521772],
              [-40.374166667657562, -9.327499993130118],
              [-40.372500000874311, -9.327499993130118],
              [-40.372499999075671, -9.328333326521772],
              [-40.371666666583337, -9.328333326521772],
              [-40.371666665684018, -9.32916665811473],
              [-40.370833331393101, -9.329166659014049],
              [-40.37083333319174, -9.3308333257973],
              [-40.368333332117516, -9.3308333257973],
              [-40.368333332117516, -9.329166659014049],
              [-40.366666667132961, -9.329166659014049],
              [-40.366666666233641, -9.326666658839201],
              [-40.363333332667139, -9.326666658839201],
              [-40.363333331767819, -9.325833326346867],
              [-40.362500000174805, -9.325833325447547],
              [-40.362500001074125, -9.32499999295527],
              [-40.35916666660836, -9.32499999295527],
              [-40.35916666660836, -9.325833326346867],
              [-40.356666665534135, -9.325833326346867],
              [-40.356666666433455, -9.32499999295527],
              [-40.352500000374675, -9.32499999295527],
              [-40.352500000374675, -9.325833326346867],
              [-40.350833333591424, -9.325833326346867],
              [-40.350833331792785, -9.32499999295527],
              [-40.351666666983078, -9.32499999295527],
              [-40.351666666083702, -9.324166658664296],
              [-40.350833332692105, -9.324166658664296],
              [-40.350833332692105, -9.321666659388768],
              [-40.350000000199827, -9.321666659388768],
              [-40.349999998401188, -9.319999993504837],
              [-40.348333333416576, -9.319999993504837],
              [-40.348333332517257, -9.318333325822266],
              [-40.347499999125603, -9.318333325822266],
              [-40.347500001823562, -9.317499993329989],
              [-40.348333332517257, -9.317499993329989],
              [-40.348333332517257, -9.316666659039015],
              [-40.349166665908854, -9.316666659039015],
              [-40.349166665908854, -9.306666659238886],
              [-40.350000001099147, -9.306666659238886],
              [-40.349999998401188, -9.304166659064038],
              [-40.349166666808173, -9.304166659064038],
              [-40.349166666808173, -9.303333325672384],
              [-40.348333331617937, -9.303333325672384],
              [-40.348333330718617, -9.301666658889133],
              [-40.347499999125603, -9.301666658889133],
              [-40.347499999125603, -9.300833325497535],
              [-40.343333332167504, -9.300833325497535],
              [-40.343333333066823, -9.299999993005201],
              [-40.342499999675226, -9.299999993005201],
              [-40.342500000574546, -9.299166660512924],
              [-40.340833331992656, -9.299166658714284],
              [-40.340833331093336, -9.297499992830353],
              [-40.340000002198281, -9.297499993729673],
              [-40.339999999500321, -9.296666659438756],
              [-40.338333330918431, -9.296666659438756],
              [-40.33833333361639, -9.295833326047102],
              [-40.337500000224793, -9.295833326047102],
              [-40.337500001124113, -9.290833325697349],
              [-40.336666667732459, -9.290833325697349],
              [-40.336666665933819, -9.289999993205072],
              [-40.334999999150625, -9.289999993205072],
              [-40.334999999150625, -9.285833327146293],
              [-40.334166665758971, -9.285833327146293],
              [-40.334166665758971, -9.283333325172748],
              [-40.333333330568735, -9.283333326072068],
              [-40.333333332367374, -9.282499992680471],
              [-40.331666666483443, -9.282499992680471],
              [-40.331666665584123, -9.281666658389554],
              [-40.330000000599512, -9.281666659288874],
              [-40.329999998800872, -9.28083332589722],
              [-40.329166665409218, -9.28083332589722],
              [-40.329166668107177, -9.279999993404942],
              [-40.327499998625967, -9.279999993404942],
              [-40.327499999525287, -9.274166658764216],
              [-40.32666666433505, -9.274166658764216],
              [-40.32666666703301, -9.272499992880341],
              [-40.325833333641413, -9.272499992880341],
              [-40.325833331842773, -9.269999992705436],
              [-40.324999999350439, -9.269999992705436],
              [-40.324999999350439, -9.268333325922185],
              [-40.324166665958842, -9.268333325022866],
              [-40.324166665958842, -9.267499991631269],
              [-40.323333332567188, -9.267499991631269],
              [-40.323333333466508, -9.266666658239615],
              [-40.32083333239234, -9.266666659138934],
              [-40.32083333239234, -9.265833325747337],
              [-40.319999999900006, -9.265833325747337],
              [-40.319999999900006, -9.26499999325506],
              [-40.319166665609089, -9.26499999325506],
              [-40.319166665609089, -9.264166658964086],
              [-40.318333333116811, -9.264166658964086],
              [-40.318333334016131, -9.26499999325506],
              [-40.317499999725158, -9.26499999325506],
              [-40.317499998825838, -9.265833325747337],
              [-40.316666665434241, -9.265833325747337],
              [-40.31666666723288, -9.266666659138934],
              [-40.314999999550309, -9.266666659138934],
              [-40.31499999865099, -9.267499994329228],
              [-40.313333332767058, -9.267499993429908],
              [-40.313333333666378, -9.26499999325506],
              [-40.312499998476085, -9.26499999325506],
              [-40.312499997576765, -9.264166658964086],
              [-40.311666667782447, -9.264166658964086],
              [-40.311666665983807, -9.262499992180835],
              [-40.31083333259221, -9.262499992180835],
              [-40.310833331692834, -9.261666658789238],
              [-40.309999999200556, -9.261666657889918],
              [-40.309999999200556, -9.260833326296904],
              [-40.309166666708279, -9.260833325397584],
              [-40.309166665808959, -9.259999992005987],
              [-40.308333330618666, -9.259999992905307],
              [-40.308333334215945, -9.254166659163957],
              [-40.309166665808959, -9.254166659163957],
              [-40.309166666708279, -9.253333325772303],
              [-40.307499999025708, -9.253333325772303],
              [-40.307499999025708, -9.250833327396094],
              [-40.306666665634054, -9.250833326496775],
              [-40.306666665634054, -9.247499992030953],
              [-40.307499999925028, -9.247499992030953],
              [-40.307499999025708, -9.246666658639356],
              [-40.309166666708279, -9.246666658639356],
              [-40.311666665084488, -9.246666657740036],
              [-40.311666665084488, -9.244999992755425],
              [-40.311666668681767, -9.244166659363771],
              [-40.31083333439085, -9.244166659363771],
              [-40.310833331692834, -9.242499993479896],
              [-40.309999997401917, -9.242499993479896],
              [-40.309999997401917, -9.240833325797325],
              [-40.307499998126389, -9.240833324898006],
              [-40.307499999025708, -9.241666659188922],
              [-40.305833330443818, -9.241666659188922],
              [-40.305833331343138, -9.242499993479896],
              [-40.303333332067552, -9.242499993479896],
              [-40.303333331168233, -9.244166659363771],
              [-40.304166664559887, -9.244166659363771],
              [-40.304166666358526, -9.252499992380706],
              [-40.303333332966872, -9.252499993280026],
              [-40.303333332067552, -9.253333325772303],
              [-40.303034525747492, -9.253333325933536],
              [-40.305283043, -9.25776121699994],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '192',
        OBJECTID: 0.0,
        Nome_area: 'Serra do Mascarenhas',
        COD_area: 'CA152',
        Import_bio: 'Muito Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Criação de UC de Proteção Integral',
        Area_ha: 15023.226,
        Shape_Leng: 1.23387,
        Shape_Area: 0.01231,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.603446994945465, -7.654166658764268],
              [-35.605833333541455, -7.654166658764268],
              [-35.605833333303416, -7.653725510251184],
              [-35.603446994945465, -7.654166658764268],
            ],
          ],
          [
            [
              [-35.602430085, -7.654354648999973],
              [-35.59748866, -7.654335594999965],
              [-35.594166667288171, -7.653713060693121],
              [-35.594166668756714, -7.654166658764268],
              [-35.595000000349671, -7.654166657864948],
              [-35.594999998551032, -7.654999993055185],
              [-35.595833331942686, -7.654999992155865],
              [-35.595833331942686, -7.655833325547462],
              [-35.601666665684036, -7.655833325547462],
              [-35.601666666583355, -7.654999993055185],
              [-35.602500000874272, -7.654999993055185],
              [-35.602500000163879, -7.654341724189673],
              [-35.602430085, -7.654354648999973],
            ],
          ],
          [
            [
              [-35.591965703, -7.653264540999943],
              [-35.591666666783226, -7.653054623679717],
              [-35.591666666783226, -7.653333325372614],
              [-35.592291681013407, -7.653333325147779],
              [-35.591965703, -7.653264540999943],
            ],
          ],
          [
            [
              [-35.590746914, -7.652408975999955],
              [-35.589323002999897, -7.651452068999955],
              [-35.588469388, -7.650727080999956],
              [-35.5876859319999, -7.650224265999951],
              [-35.586598449, -7.650271038999954],
              [-35.585164616872234, -7.650833326097086],
              [-35.58749999892575, -7.650833326097086],
              [-35.58750000072439, -7.651666658589363],
              [-35.589166665709001, -7.651666658589363],
              [-35.589166665709001, -7.653333325372614],
              [-35.59, -7.653333324473294],
              [-35.589999999100655, -7.652499992880337],
              [-35.590876571445811, -7.652499992880337],
              [-35.590746914, -7.652408975999955],
            ],
          ],
          [
            [
              [-35.583333331967651, -7.651239578450095],
              [-35.583333331967651, -7.651666658589363],
              [-35.584166666258625, -7.651666658589363],
              [-35.584166665763888, -7.651208250007153],
              [-35.583333331967651, -7.651239578450095],
            ],
          ],
          [
            [
              [-35.5734434149999, -7.65471451799992],
              [-35.573006547143379, -7.655833325547462],
              [-35.573333333066842, -7.655833325547462],
              [-35.573333331268202, -7.654999993954505],
              [-35.574999998950773, -7.654999992155865],
              [-35.574999999441722, -7.654772527209082],
              [-35.5734434149999, -7.65471451799992],
            ],
          ],
          [
            [
              [-35.572484559, -7.657170123999951],
              [-35.571923276999897, -7.657965272999951],
              [-35.570467069809574, -7.658333325356717],
              [-35.572499998775868, -7.658333325722367],
              [-35.572499999208532, -7.657130582015857],
              [-35.572484559, -7.657170123999951],
            ],
          ],
          [
            [
              [-35.569676649, -7.658277716999972],
              [-35.569300375999902, -7.658225091999953],
              [-35.568654981999899, -7.658134826999976],
              [-35.568555832, -7.658087481999926],
              [-35.568370033, -7.657998760999929],
              [-35.567810135999899, -7.657731405999932],
              [-35.567614272999897, -7.657272440999982],
              [-35.567499999016718, -7.657213910586011],
              [-35.567500000224811, -7.658333324823047],
              [-35.570074247298301, -7.658333325286062],
              [-35.569676649, -7.658277716999972],
            ],
          ],
          [
            [
              [-35.561829361226373, -7.660833325178831],
              [-35.562499998975738, -7.660833324997895],
              [-35.562499999130829, -7.66054590883648],
              [-35.561829361226373, -7.660833325178831],
            ],
          ],
          [
            [
              [-35.550302246999898, -7.661189717999947],
              [-35.547729707, -7.658547017999983],
              [-35.547899581, -7.657337502999952],
              [-35.534612056, -7.655484049999932],
              [-35.532811955999897, -7.654817569999976],
              [-35.532109122999898, -7.653854941999942],
              [-35.531533666, -7.649460308999948],
              [-35.52971969, -7.646476053999948],
              [-35.528088673, -7.645678184999943],
              [-35.526950337, -7.645121327999962],
              [-35.52587892, -7.644597206999948],
              [-35.522074173, -7.643540175999923],
              [-35.522072136, -7.643540029999937],
              [-35.519771003, -7.643374334999979],
              [-35.519212, -7.643334139999987],
              [-35.515577662999902, -7.644078632999948],
              [-35.515576939, -7.644077446999965],
              [-35.515070382, -7.643247157999954],
              [-35.515063622999904, -7.642237246999967],
              [-35.516216429999901, -7.641222054999962],
              [-35.519631903999901, -7.640017506999917],
              [-35.524553274747127, -7.633707044896797],
              [-35.524473986485248, -7.633686599257089],
              [-35.524353117602232, -7.633655432352271],
              [-35.521100338110045, -7.633107571657035],
              [-35.520548506008709, -7.632963427420179],
              [-35.518573305213806, -7.632447477369112],
              [-35.51690890202417, -7.632271760633216],
              [-35.515219800753187, -7.63228156234419],
              [-35.51356412818194, -7.632496552473867],
              [-35.51229860759463, -7.632831364675667],
              [-35.508659343553802, -7.634187299504219],
              [-35.505159192942358, -7.635285533600438],
              [-35.501381894658664, -7.635924532491117],
              [-35.499895644468381, -7.636439799057428],
              [-35.498468196450062, -7.637140537305925],
              [-35.497607007457361, -7.637734024205827],
              [-35.496448110491883, -7.638805038521639],
              [-35.49546583408096, -7.640033802917628],
              [-35.494450073212249, -7.642164555849547],
              [-35.492798459281403, -7.647744585644091],
              [-35.491542368085788, -7.650580786573983],
              [-35.490192330111881, -7.652853573033894],
              [-35.489127490541193, -7.654118292325279],
              [-35.486485661883762, -7.656825049336533],
              [-35.485728924650118, -7.657837942267179],
              [-35.484982318279322, -7.659169528850612],
              [-35.484634980320038, -7.660174777543887],
              [-35.484472474624965, -7.661265629105458],
              [-35.484604616509785, -7.663471387294635],
              [-35.484913663134989, -7.664495340987173],
              [-35.485799778636817, -7.666388909420562],
              [-35.487271994906621, -7.668590773545304],
              [-35.489816013298309, -7.671543868354775],
              [-35.491419705954627, -7.673687005850581],
              [-35.492494577462764, -7.674804961179973],
              [-35.496862145771388, -7.67804330655332],
              [-35.502400084596161, -7.681661924857906],
              [-35.505391208137723, -7.684161077871408],
              [-35.50707026847806, -7.686165024596164],
              [-35.507962612684366, -7.687439342351695],
              [-35.508230047678524, -7.68793010958683],
              [-35.508333332117559, -7.688119645305335],
              [-35.508333331218239, -7.687499992630535],
              [-35.50999999800149, -7.687499992630535],
              [-35.50999999890081, -7.686666659238881],
              [-35.511666665684004, -7.686666659238881],
              [-35.5116666674827, -7.685833325847284],
              [-35.513333332467255, -7.685833324947964],
              [-35.513333331567935, -7.68499999245563],
              [-35.514166665858909, -7.68499999245563],
              [-35.514166666758229, -7.684166659064033],
              [-35.51583333084352, -7.684166659064033],
              [-35.51583333264216, -7.68499999245563],
              [-35.519166666208662, -7.68499999245563],
              [-35.519166665309285, -7.685833325847284],
              [-35.520833333891176, -7.685833324947964],
              [-35.520833332991856, -7.68499999245563],
              [-35.523333334066081, -7.68499999245563],
              [-35.523333331368121, -7.683333325672379],
              [-35.524166665659038, -7.683333325672379],
              [-35.524166665659038, -7.682499993180102],
              [-35.525000000849332, -7.682499993180102],
              [-35.524999999050692, -7.683333325672379],
              [-35.526666665833943, -7.683333325672379],
              [-35.526666666733263, -7.684166658164713],
              [-35.528333332617137, -7.684166659064033],
              [-35.528333332617137, -7.682499993180102],
              [-35.529166667807431, -7.682499993180102],
              [-35.529166666008791, -7.681666658889128],
              [-35.534166666358544, -7.681666658889128],
              [-35.534166665459225, -7.682499993180102],
              [-35.535833331343099, -7.682499993180102],
              [-35.535833332242419, -7.681666658889128],
              [-35.53749999902567, -7.681666658889128],
              [-35.537500000824309, -7.678333326222003],
              [-35.540833333491491, -7.678333326222003],
              [-35.540833332592172, -7.677499993729668],
              [-35.541666667782465, -7.677499992830349],
              [-35.541666665983826, -7.676666658539432],
              [-35.542500000274742, -7.676666658539432],
              [-35.542499998476103, -7.674999992655501],
              [-35.541666665983826, -7.674999992655501],
              [-35.541666665983826, -7.672499992480596],
              [-35.540833332592172, -7.672499992480596],
              [-35.540833333491491, -7.671666659088999],
              [-35.539999998301255, -7.671666659088999],
              [-35.540000000099894, -7.670833325697402],
              [-35.53916666670824, -7.670833325697402],
              [-35.53916666760756, -7.669166658914151],
              [-35.540000000099894, -7.669166658014831],
              [-35.540000000999214, -7.668333325522497],
              [-35.543333332767077, -7.668333325522497],
              [-35.543333332767077, -7.665833325347648],
              [-35.544999998650951, -7.665833327146288],
              [-35.544999999550271, -7.664999992855314],
              [-35.546666666333522, -7.664999992855314],
              [-35.546666667232842, -7.665833325347648],
              [-35.547499998825856, -7.665833325347648],
              [-35.547499999725176, -7.664999991955995],
              [-35.548333334016093, -7.664999993754634],
              [-35.548333332217453, -7.664166658564397],
              [-35.549999999900024, -7.664166658564397],
              [-35.550000000799344, -7.661666658389549],
              [-35.550833332392358, -7.661666658389549],
              [-35.550833332392358, -7.661208031244338],
              [-35.550302246999898, -7.661189717999947],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '193',
        OBJECTID: 0.0,
        Nome_area: 'Brejos Pernambucanos',
        COD_area: 'CA168',
        Import_bio: 'Extremamente Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Criação de UC de Proteção Integral',
        Area_ha: 430508.762,
        Shape_Leng: 8.49356,
        Shape_Area: 0.35293,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.821497904252595, -8.207499993030183],
              [-36.820833333991118, -8.207499993030183],
              [-36.820833333843062, -8.207362802728268],
              [-36.818451641, -8.20698485299995],
              [-36.815992179, -8.206810695999925],
              [-36.815661577255838, -8.206666658739266],
              [-36.813333332567197, -8.206666658739266],
              [-36.813333333466517, -8.205833325347612],
              [-36.81249999827628, -8.205833325347612],
              [-36.812500000075026, -8.205277737085874],
              [-36.812310159827568, -8.204999992957772],
              [-36.810833331493029, -8.204999993754654],
              [-36.810833331493029, -8.204166658564418],
              [-36.809999999000695, -8.204166659463738],
              [-36.809999999900015, -8.203333326072084],
              [-36.809166667407737, -8.203333326971403],
              [-36.809166665609098, -8.202499993579806],
              [-36.808333331318124, -8.202499992680487],
              [-36.808333332217444, -8.199999991606262],
              [-36.807499998825847, -8.199999992505582],
              [-36.807499998825847, -8.198333325722331],
              [-36.806666665434193, -8.198333324823011],
              [-36.806666665434193, -8.197499991431414],
              [-36.805833332042596, -8.197499993230053],
              [-36.805833332042596, -8.195833325547483],
              [-36.805000000449638, -8.195833325547483],
              [-36.804999998650999, -8.194999993055205],
              [-36.804166667057984, -8.194999992155886],
              [-36.804166667057984, -8.194176379253889],
              [-36.804154311034381, -8.194166659659107],
              [-36.801666665983817, -8.194166658764232],
              [-36.801666666883136, -8.193333324473315],
              [-36.800000000999205, -8.193333325372635],
              [-36.799999998301246, -8.1924999928803],
              [-36.799166665808912, -8.1924999928803],
              [-36.799166667607608, -8.191666658589384],
              [-36.798333332417315, -8.191666659488703],
              [-36.798333331682606, -8.190985862399366],
              [-36.798033198617574, -8.190833326097049],
              [-36.797499999925037, -8.190833326097049],
              [-36.797499999925037, -8.190562339103497],
              [-36.797395290999901, -8.190509122999973],
              [-36.794999998814582, -8.189733404545329],
              [-36.794999997951493, -8.189999991806133],
              [-36.78999999850106, -8.189999992705452],
              [-36.789999999400436, -8.190833326097049],
              [-36.787499999225531, -8.19083332519773],
              [-36.787499998326211, -8.191666658589384],
              [-36.786666666733254, -8.191666658589384],
              [-36.786666666733254, -8.19249999377962],
              [-36.783333332267432, -8.1924999928803],
              [-36.783333331368112, -8.190833326097049],
              [-36.77999999870093, -8.190833326097049],
              [-36.779999999654166, -8.190391677939342],
              [-36.775833331742831, -8.190688468257228],
              [-36.775833331742831, -8.191666658589384],
              [-36.773225019555177, -8.191666658589384],
              [-36.770831755, -8.192719633999959],
              [-36.767285423, -8.195980249999929],
              [-36.765833332216175, -8.19648622057033],
              [-36.765833331942645, -8.197499993230053],
              [-36.763333334465756, -8.197499993230053],
              [-36.763333331767797, -8.198333325722331],
              [-36.761666665883865, -8.198333325722331],
              [-36.761666665883865, -8.197499993230053],
              [-36.759166666608337, -8.197499993230053],
              [-36.759166665709017, -8.196666658939137],
              [-36.757500000724406, -8.196666658939137],
              [-36.757499998026447, -8.194999993055205],
              [-36.756666666433489, -8.194999993055205],
              [-36.756666666433489, -8.194166658764232],
              [-36.755833333041835, -8.194166658764232],
              [-36.755833333668221, -8.193005808556384],
              [-36.754854192, -8.192444570999957],
              [-36.753508186, -8.191478134999949],
              [-36.752832200936403, -8.189999992076539],
              [-36.751666666083736, -8.189999992705452],
              [-36.751666664285096, -8.189166658414536],
              [-36.749999999300485, -8.189166658414536],
              [-36.749999998401165, -8.184166658964102],
              [-36.749166665009511, -8.184166658964102],
              [-36.749166665908888, -8.182499993080171],
              [-36.747500000024957, -8.182499993080171],
              [-36.747500000024957, -8.178333326122072],
              [-36.748333331617914, -8.178333326122072],
              [-36.748333332517234, -8.177499992730418],
              [-36.749166667707527, -8.177499992730418],
              [-36.74916666616258, -8.175352608220486],
              [-36.744214294, -8.173569198999928],
              [-36.742607327, -8.172990510999966],
              [-36.740889857, -8.172372003999957],
              [-36.730695646, -8.172829359999971],
              [-36.730694066999909, -8.172829274999966],
              [-36.723971983999903, -8.17246867399996],
              [-36.723352245113453, -8.170833325597471],
              [-36.720833335090276, -8.170833325597471],
              [-36.720833332804908, -8.168009745476493],
              [-36.715781319, -8.167316204999954],
              [-36.716429040729452, -8.165833326147037],
              [-36.714999998650967, -8.165833326147037],
              [-36.715000000449606, -8.16499999275544],
              [-36.71416666525937, -8.16499999275544],
              [-36.71416666436005, -8.164166658464467],
              [-36.715000000449606, -8.164166659363786],
              [-36.714999998650967, -8.160833324897965],
              [-36.71583333474058, -8.160833326696604],
              [-36.715833332941941, -8.159166659014033],
              [-36.714999999550287, -8.159166659014033],
              [-36.715000000449606, -8.157499992230782],
              [-36.714166667058009, -8.157499993130159],
              [-36.714166665293654, -8.154230200059743],
              [-36.710000000131146, -8.155804327880126],
              [-36.710000000099853, -8.155833325447588],
              [-36.709923244512844, -8.155833325447588],
              [-36.708676839, -8.156304205999955],
              [-36.708660408, -8.156309954999939],
              [-36.707892586, -8.156578597999941],
              [-36.707802742, -8.156610031999957],
              [-36.705833333390615, -8.157299082306324],
              [-36.705833334041074, -8.157499993130159],
              [-36.705259100862619, -8.157499992923578],
              [-36.703694040999899, -8.158047570999937],
              [-36.703692788, -8.158046946999946],
              [-36.703333332569869, -8.157867895196112],
              [-36.703333332067587, -8.158333325622436],
              [-36.701666667982295, -8.158333325622436],
              [-36.701666665284336, -8.159166659014033],
              [-36.700000000299724, -8.159166659014033],
              [-36.700000000299724, -8.158333324723117],
              [-36.697499999225556, -8.158333325622436],
              [-36.696666665833902, -8.158333325622436],
              [-36.696666666733222, -8.157499994029479],
              [-36.695833331542985, -8.157499993130159],
              [-36.695833330643666, -8.154999992955254],
              [-36.695000000849291, -8.154999992955254],
              [-36.694999999050651, -8.156666658839185],
              [-36.689999999600275, -8.156666657939866],
              [-36.689999998700955, -8.155833326346908],
              [-36.688333332817024, -8.155833325447588],
              [-36.688333332817024, -8.155551783329182],
              [-36.6870359179999, -8.155679492999976],
              [-36.687195405533387, -8.154166658093731],
              [-36.686666667832412, -8.154166658664337],
              [-36.686666667832412, -8.152499992780406],
              [-36.687371110363763, -8.152499992400294],
              [-36.687393863, -8.152284169999966],
              [-36.687433991, -8.151903523999986],
              [-36.689999999600275, -8.149073970541647],
              [-36.689999999600275, -8.147499992430653],
              [-36.689166664409981, -8.147499992430653],
              [-36.689166666208621, -8.146666659039056],
              [-36.68916666800726, -8.145833325647402],
              [-36.690833333891192, -8.145833325647402],
              [-36.690833330293913, -8.144999993155125],
              [-36.691666665484206, -8.144999993155125],
              [-36.691666665484206, -8.144166658864151],
              [-36.693333331368081, -8.144166657964831],
              [-36.6933333322674, -8.141666658689303],
              [-36.694166667457694, -8.141666658689303],
              [-36.694166667582138, -8.141436028679246],
              [-36.6938622119999, -8.140772718999937],
              [-36.689825339, -8.138263834999989],
              [-36.689751355999903, -8.138217854999937],
              [-36.689030013, -8.13776954599995],
              [-36.686433656, -8.136155930999951],
              [-36.683232854733909, -8.134166659064022],
              [-36.678333333016838, -8.134166659064022],
              [-36.678333330318878, -8.133333325672368],
              [-36.676666665334267, -8.133333325672368],
              [-36.676666666233643, -8.131666659788493],
              [-36.675833333741309, -8.131666658889174],
              [-36.67583333194267, -8.13083332549752],
              [-36.674999998551073, -8.13083332549752],
              [-36.674999999450392, -8.129999993005242],
              [-36.673333331767822, -8.129999993005242],
              [-36.673333331767822, -8.129166658714269],
              [-36.67166666768253, -8.129166658714269],
              [-36.67166666678321, -8.128333326221991],
              [-36.670833331592917, -8.128333326221991],
              [-36.670833333391556, -8.127499992830337],
              [-36.669166667507682, -8.127499992830337],
              [-36.669166665708985, -8.12666665853942],
              [-36.668333331418069, -8.12666665853942],
              [-36.668333333216708, -8.125833326047086],
              [-36.667500000724431, -8.125833326047086],
              [-36.667499998925791, -8.124999992655489],
              [-36.666666666433457, -8.124999992655489],
              [-36.666666666433457, -8.124166658364572],
              [-36.66583333394118, -8.124166658364572],
              [-36.665833333210905, -8.123489969985766],
              [-36.665427138773936, -8.123333325872238],
              [-36.664999998750886, -8.123333325872238],
              [-36.664999998750886, -8.123221845931253],
              [-36.660833331883438, -8.122583989216125],
              [-36.660833332692107, -8.123333325872238],
              [-36.653333331268186, -8.123333325872238],
              [-36.653333331268186, -8.121666658189667],
              [-36.652499998775852, -8.121666659088987],
              [-36.652499998775852, -8.120694772163549],
              [-36.650833332891978, -8.120624043448338],
              [-36.650833332891978, -8.12083332569739],
              [-36.649166665209407, -8.12083332569739],
              [-36.649166665209407, -8.121666659088987],
              [-36.648333332717073, -8.121666659088987],
              [-36.648333332717073, -8.12083332569739],
              [-36.647499998426156, -8.12083332569739],
              [-36.647499998047643, -8.120482585887354],
              [-36.644190690999899, -8.120342147999956],
              [-36.641122538920584, -8.119999993556847],
              [-36.63916666720786, -8.119999993205056],
              [-36.639166665679113, -8.11929170237541],
              [-36.638890442728311, -8.119166658914139],
              [-36.638333332017623, -8.119166658914139],
              [-36.638333332561963, -8.118914460524003],
              [-36.637532106, -8.118551752999956],
              [-36.637386070875458, -8.118333325522485],
              [-36.636666667033012, -8.118333325522485],
              [-36.636666665234372, -8.117499993030208],
              [-36.634999998451121, -8.117499993030208],
              [-36.634999999561856, -8.115441527426588],
              [-36.634109587, -8.114684896999961],
              [-36.628612516, -8.111050372999959],
              [-36.628308750694636, -8.109999992505607],
              [-36.62749999882584, -8.109999992505607],
              [-36.62749999882584, -8.107203434858095],
              [-36.627325460999899, -8.106599905999925],
              [-36.62743340398773, -8.105833325547508],
              [-36.626666666333563, -8.105833325547508],
              [-36.626666667232882, -8.104999993055173],
              [-36.62749999882584, -8.104999993055173],
              [-36.62749999972516, -8.104166658764257],
              [-36.627668089064471, -8.104166658764257],
              [-36.627852359, -8.102858025999977],
              [-36.628333331604956, -8.102306133112224],
              [-36.628333332217437, -8.098333325922226],
              [-36.62749999972516, -8.098333325922226],
              [-36.62749999882584, -8.097499992530572],
              [-36.628333331318117, -8.097499992530572],
              [-36.628333331318117, -8.096666660038295],
              [-36.633248109137355, -8.096666659154304],
              [-36.63333333267272, -8.096568869187449],
              [-36.63333333346651, -8.095833325747321],
              [-36.634036142873121, -8.09583332498886],
              [-36.634166665121299, -8.095718845385122],
              [-36.634166666858164, -8.092499993080196],
              [-36.63333333166787, -8.092499993080196],
              [-36.63333333256719, -8.091666658789222],
              [-36.634166666858164, -8.091666658789222],
              [-36.634166666858164, -8.090833325397625],
              [-36.635833331842775, -8.090833325397625],
              [-36.635833332742095, -8.091666659688542],
              [-36.637499999525289, -8.091666658789222],
              [-36.637500000424609, -8.090833325397625],
              [-36.63916666630854, -8.090833325397625],
              [-36.63916666720786, -8.091666658789222],
              [-36.640000000599514, -8.091666658789222],
              [-36.640000000599514, -8.090833325397625],
              [-36.640833331293152, -8.090833325397625],
              [-36.640833331293152, -8.089999992905291],
              [-36.642499999875042, -8.089999992905291],
              [-36.642499998975723, -8.089166658614374],
              [-36.644166665758974, -8.089166658614374],
              [-36.644166666658293, -8.089999992905291],
              [-36.644999998251251, -8.089999992905291],
              [-36.645000000949267, -8.089166659513694],
              [-36.645833332542225, -8.089166658614374],
              [-36.645833330743585, -8.08833332612204],
              [-36.646666667732461, -8.08833332612204],
              [-36.646666666833141, -8.087499992730443],
              [-36.647138803850162, -8.087499992730443],
              [-36.646950558, -8.086142351999969],
              [-36.64833333226597, -8.083341332706169],
              [-36.648333332717073, -8.080833325597439],
              [-36.651666664484935, -8.080833325597439],
              [-36.651666666283575, -8.081666658989093],
              [-36.654166666458423, -8.081666658989093],
              [-36.654166666458423, -8.082488298285618],
              [-36.655000000486375, -8.082378132481322],
              [-36.654999998950757, -8.081666658989093],
              [-36.655833332342354, -8.081666658989093],
              [-36.655833331443034, -8.080833325597439],
              [-36.657500000924244, -8.080833326496759],
              [-36.657499998226285, -8.079999993105162],
              [-36.657500000024925, -8.07666665863934],
              [-36.657970158617793, -8.07666665863934],
              [-36.65783148, -8.076132616999937],
              [-36.657578249, -8.075560254999965],
              [-36.656961677279, -8.074166658464492],
              [-36.656666665734008, -8.074166658464492],
              [-36.656666666093805, -8.073499864072836],
              [-36.656474408999898, -8.073065317999987],
              [-36.655908603276842, -8.072499992580561],
              [-36.655833333241674, -8.072499992580561],
              [-36.655833333160516, -8.072424786359855],
              [-36.65507456188049, -8.071666659188907],
              [-36.653333333966145, -8.071666659188907],
              [-36.653333333966145, -8.07249999347988],
              [-36.652499998775852, -8.07249999347988],
              [-36.652499999675229, -8.071666659188907],
              [-36.649999998601004, -8.071666659188907],
              [-36.649999998601004, -8.07083332489799],
              [-36.649166667008046, -8.070833325797309],
              [-36.649166667777706, -8.067267380664845],
              [-36.646616359999904, -8.065548489999969],
              [-36.641639311277217, -8.062499992780374],
              [-36.640833331293152, -8.062499992780374],
              [-36.640833331293152, -8.062079625127534],
              [-36.639846293777765, -8.061666658122736],
              [-36.639166665409221, -8.061666658489457],
              [-36.63916666571609, -8.061382308277228],
              [-36.637854537547192, -8.060833326469162],
              [-36.636666667033012, -8.0608333268965],
              [-36.636666667033012, -8.060336332868935],
              [-36.635862776034308, -8.059999992605526],
              [-36.63333333166787, -8.059999992605526],
              [-36.633333331439488, -8.058941859696374],
              [-36.628824166999898, -8.05705610499996],
              [-36.627851888999899, -8.05574927299995],
              [-36.627774191999897, -8.051523997999945],
              [-36.628259182, -8.046860218999946],
              [-36.628333331037815, -8.04614718535629],
              [-36.628333330418798, -8.044999992455644],
              [-36.628452628704238, -8.044999992455644],
              [-36.628625946947253, -8.043333325672393],
              [-36.628333331318117, -8.043333325672393],
              [-36.628333332217437, -8.041666657989822],
              [-36.62750000062448, -8.041666658889142],
              [-36.62749999972516, -8.040833325497545],
              [-36.626666667232882, -8.040833324598225],
              [-36.626666666333563, -8.03999999300521],
              [-36.625833332042589, -8.03999999300521],
              [-36.625833332941909, -8.039166657814974],
              [-36.624999999550312, -8.039166658714294],
              [-36.624999998650992, -8.03833332442332],
              [-36.625833332042589, -8.038333326221959],
              [-36.625833332042589, -8.036666657640069],
              [-36.626666667232882, -8.036666657640069],
              [-36.626666665434243, -8.035833326946431],
              [-36.62750000062448, -8.035833326047111],
              [-36.62749999882584, -8.034999992655514],
              [-36.628333332217437, -8.034999992655514],
              [-36.628333332217437, -8.034166658364541],
              [-36.629166665609091, -8.03416666016318],
              [-36.629166665609091, -8.033333325872263],
              [-36.628333331318117, -8.033333325872263],
              [-36.628333334016133, -8.027499993030233],
              [-36.629166666508411, -8.027499993030233],
              [-36.629166666508411, -8.024999992855328],
              [-36.628333334016133, -8.024999993754648],
              [-36.628333332217437, -8.018333324823061],
              [-36.628597710644605, -8.018333325393685],
              [-36.628441379746683, -8.01666665893913],
              [-36.628333331318117, -8.01666665893913],
              [-36.628333331318117, -8.015833325547476],
              [-36.62750000062448, -8.015833326446796],
              [-36.62749999972516, -8.014999993055199],
              [-36.626666665434243, -8.014999993055199],
              [-36.626666665434243, -8.014166658764225],
              [-36.625833332941909, -8.014166658764225],
              [-36.625833332941909, -8.013333325372628],
              [-36.624999999550312, -8.013333325372628],
              [-36.624999998650992, -8.012499992880294],
              [-36.624166665259338, -8.012499992880294],
              [-36.623333331867741, -8.01249999377967],
              [-36.623333332767061, -8.013333325372628],
              [-36.621666666883129, -8.013333325372628],
              [-36.621666666883129, -8.012499992880294],
              [-36.619999998301239, -8.012499992880294],
              [-36.620000000999198, -8.011666658589377],
              [-36.619166665808962, -8.011666658589377],
              [-36.619166667607601, -8.009999992705445],
              [-36.61750000082435, -8.009999993604765],
              [-36.617499999025711, -8.010833326996419],
              [-36.612499998675958, -8.01083332519778],
              [-36.612499998675958, -8.008333325922194],
              [-36.611666667083, -8.008333325922194],
              [-36.611666665284304, -8.006666659138943],
              [-36.610833332792026, -8.006666659138943],
              [-36.610833332792026, -8.005833325747346],
              [-36.609166666008775, -8.005833325747346],
              [-36.609166666008775, -8.004999992355692],
              [-36.608333332617178, -8.004999992355692],
              [-36.608333333516498, -8.002499993080164],
              [-36.607499998326205, -8.002499993080164],
              [-36.607500001923484, -8.001666658789247],
              [-36.606666666733247, -8.001666658789247],
              [-36.606666665833927, -8.000833324498274],
              [-36.605833331542954, -8.000833325397593],
              [-36.605833332442273, -7.999999992905316],
              [-36.604166666558399, -7.999999992905316],
              [-36.604166667457719, -7.994999992555563],
              [-36.602500000674468, -7.994999992555563],
              [-36.602500000674468, -7.994166659163966],
              [-36.599999999600243, -7.994166659163966],
              [-36.599999999600243, -7.993333325772312],
              [-36.598333332816992, -7.993333324872992],
              [-36.598333331917672, -7.994166657365327],
              [-36.597499998526075, -7.994166659163966],
              [-36.597499999425395, -7.994999992555563],
              [-36.595833331742824, -7.994999992555563],
              [-36.595833331742824, -7.994166657365327],
              [-36.594166667657532, -7.994166659163966],
              [-36.594166664959573, -7.994999992555563],
              [-36.592499999974962, -7.994999992555563],
              [-36.592499997277002, -7.993333325772312],
              [-36.591666665684045, -7.993333325772312],
              [-36.591666667482684, -7.992499992380715],
              [-36.590833331393071, -7.992499992380715],
              [-36.590833332292391, -7.991666659888381],
              [-36.590000001598753, -7.991666659888381],
              [-36.589999999800114, -7.990833325597464],
              [-36.58916666550914, -7.990833326496784],
              [-36.58916666550914, -7.992499992380715],
              [-36.584999999450361, -7.992499992380715],
              [-36.584999998551041, -7.991666658089741],
              [-36.58333333266711, -7.991666658989061],
              [-36.58333333176779, -7.98999999220581],
              [-36.584166666058763, -7.98999999310513],
              [-36.584166666958083, -7.989166658814213],
              [-36.583333333566429, -7.989166658814213],
              [-36.58333333176779, -7.983333325972183],
              [-36.584166664260124, -7.983333325072863],
              [-36.584166666958083, -7.982499992580529],
              [-36.584166666058763, -7.979166659014084],
              [-36.58333333176779, -7.979166659014084],
              [-36.58333333176779, -7.976666658839179],
              [-36.584166666958083, -7.976666658839179],
              [-36.584166666058763, -7.975833324548262],
              [-36.583333333566429, -7.975833325447582],
              [-36.583333333566429, -7.97416665866433],
              [-36.582499999275512, -7.97416665866433],
              [-36.582499999275512, -7.971666658489426],
              [-36.580833333391581, -7.971666657590106],
              [-36.580833331592942, -7.969999992605551],
              [-36.579999999100608, -7.969999992605551],
              [-36.579999999100608, -7.969166659213897],
              [-36.57916666570901, -7.969166660113217],
              [-36.57916666570901, -7.9683333258223],
              [-36.574166665359257, -7.96833332672162],
              [-36.574166666258577, -7.967499992430646],
              [-36.570833333591452, -7.967499992430646],
              [-36.570833332692132, -7.966666659039049],
              [-36.567500000924269, -7.966666659039049],
              [-36.567500000924269, -7.967499991531326],
              [-36.566666665733976, -7.967499992430646],
              [-36.566666665733976, -7.959999993704685],
              [-36.567499997326991, -7.959999992805365],
              [-36.567500000924269, -7.959166657615128],
              [-36.569166666808201, -7.959166658514448],
              [-36.569166665908881, -7.958333326022114],
              [-36.570833333591452, -7.958333326022114],
              [-36.570833333591452, -7.957499993529837],
              [-36.571666666083729, -7.957499992630517],
              [-36.57166666428509, -7.959166658514448],
              [-36.572499999475326, -7.959166659413768],
              [-36.572499999475326, -7.959999992805365],
              [-36.57333333286698, -7.959999993704685],
              [-36.57333333196766, -7.959166658514448],
              [-36.574166668057217, -7.959166658514448],
              [-36.574166665359257, -7.958333326022114],
              [-36.5748231903952, -7.958333326022114],
              [-36.573482333999898, -7.957434271999936],
              [-36.573208292999901, -7.955951173999945],
              [-36.572859319, -7.954062529999927],
              [-36.574187857, -7.951674401999983],
              [-36.5758230879999, -7.95072829699996],
              [-36.577499998925759, -7.95016501098892],
              [-36.577499998925759, -7.946666658539414],
              [-36.576666665534162, -7.946666658539414],
              [-36.576666666433482, -7.945833326047136],
              [-36.574999997851592, -7.945833326047136],
              [-36.574999998750911, -7.944999992655482],
              [-36.574166666258577, -7.944999992655482],
              [-36.574166668057217, -7.943333325872231],
              [-36.5733333337663, -7.943333325872231],
              [-36.5733333337663, -7.942499992480634],
              [-36.575833333941148, -7.942499993379954],
              [-36.575833332347692, -7.94200781434394],
              [-36.574435593, -7.940088918999944],
              [-36.573647230495162, -7.938333325522535],
              [-36.5733333337663, -7.938333325522535],
              [-36.573333332257569, -7.937634309910937],
              [-36.573273015873632, -7.937499992195973],
              [-36.572499998576006, -7.937499993030201],
              [-36.572500000374646, -7.934999992855353],
              [-36.571666666983049, -7.934999992855353],
              [-36.571666666983049, -7.932499992680448],
              [-36.572499997676687, -7.932499992680448],
              [-36.572499999475326, -7.931666658389531],
              [-36.572545256277841, -7.931666658389531],
              [-36.5725343, -7.930567219999938],
              [-36.573333331988941, -7.929107492123856],
              [-36.5733333337663, -7.92416665966357],
              [-36.572499998576006, -7.92416665876425],
              [-36.572499999475326, -7.923333327171292],
              [-36.574999998750911, -7.923333324473276],
              [-36.574999998750911, -7.920833326097068],
              [-36.575833333041828, -7.920833326097068],
              [-36.575833333041828, -7.91833332592222],
              [-36.575000000549551, -7.91833332592222],
              [-36.574999998750911, -7.916666659138968],
              [-36.574166666258577, -7.916666660038288],
              [-36.574166665359257, -7.914999991456398],
              [-36.57333333196766, -7.914999992355717],
              [-36.57333333286698, -7.913333325572466],
              [-36.572500000374646, -7.913333325572466],
              [-36.572499998576006, -7.912499992180869],
              [-36.570833332692132, -7.912499993080189],
              [-36.570833332692132, -7.909999992905284],
              [-36.569166666808201, -7.909999992905284],
              [-36.569166666808201, -7.909166658614367],
              [-36.569999999300478, -7.909166658614367],
              [-36.569999999300478, -7.908333326122033],
              [-36.569166665908881, -7.908333326122033],
              [-36.569166666808201, -7.907499992730436],
              [-36.568333331617907, -7.907499992730436],
              [-36.568333333416547, -7.905833325947185],
              [-36.569166666808201, -7.905833325947185],
              [-36.569166666808201, -7.903360774627231],
              [-36.569105246421607, -7.903333325772337],
              [-36.568333332517227, -7.903333325772337],
              [-36.568333332144938, -7.902988355969105],
              [-36.565375867614421, -7.901666658989086],
              [-36.562499999675197, -7.901666658989086],
              [-36.562499997876557, -7.900833325597432],
              [-36.559166665209375, -7.900833325597432],
              [-36.55916666761496, -7.900090299151406],
              [-36.558697679719103, -7.899999993105155],
              [-36.550833330393857, -7.899999993105155],
              [-36.550833333991136, -7.899166658814181],
              [-36.549780175320208, -7.899166658814181],
              [-36.549535036999899, -7.899216196999932],
              [-36.543042526, -7.902317800999976],
              [-36.543040123, -7.902317541999923],
              [-36.541666667039813, -7.902169623048431],
              [-36.541666666683284, -7.902499992380683],
              [-36.537499999725185, -7.902499992380683],
              [-36.537499999725185, -7.901720879820938],
              [-36.536996541767024, -7.901666658225597],
              [-36.536178642492715, -7.901666658446263],
              [-36.5336306, -7.902177878999963],
              [-36.531473389, -7.902798559999943],
              [-36.52916666670825, -7.904561935549179],
              [-36.52916666670825, -7.904999992555588],
              [-36.528593631734402, -7.904999992555588],
              [-36.528333332632073, -7.90519897839544],
              [-36.528333333316652, -7.905833325947185],
              [-36.527503523957719, -7.90583332624569],
              [-36.526224538, -7.906811047999943],
              [-36.512854532999903, -7.914871524999977],
              [-36.49938876800001, -7.920314876999956],
              [-36.499386928, -7.920314412999963],
              [-36.497791494999902, -7.919911637999975],
              [-36.497500000292391, -7.919650505829554],
              [-36.497500001423873, -7.919999992705471],
              [-36.495833331043343, -7.91999999360479],
              [-36.495833331838725, -7.91815744008421],
              [-36.492479752999898, -7.915153173999945],
              [-36.490132834, -7.914779719999952],
              [-36.488116318, -7.915384662999939],
              [-36.486664398999899, -7.916354962999955],
              [-36.482872707, -7.920297068999955],
              [-36.481666666083754, -7.922265547301139],
              [-36.481666666083754, -7.923333325372596],
              [-36.481012463269899, -7.923333325372596],
              [-36.480833332376577, -7.923625699596251],
              [-36.48083333179278, -7.92416665876425],
              [-36.480501899263885, -7.92416665876425],
              [-36.480136377999898, -7.924763255999955],
              [-36.479999999048019, -7.924766031956291],
              [-36.479999999300503, -7.924999993055167],
              [-36.478333333416572, -7.924999993055167],
              [-36.478333332984818, -7.924799956489032],
              [-36.478122153, -7.924804254999974],
              [-36.478119754, -7.924803275999975],
              [-36.476576556999902, -7.924173787999958],
              [-36.474966517876688, -7.922499992880319],
              [-36.472499998775902, -7.922499992880319],
              [-36.472499998775902, -7.919935802002054],
              [-36.470446836999898, -7.917801336999957],
              [-36.468095502286403, -7.916666659138968],
              [-36.466666666833191, -7.916666659138968],
              [-36.466666666276126, -7.916150497698455],
              [-36.464099883, -7.915350070999975],
              [-36.462663176608181, -7.914999992355717],
              [-36.462499999875035, -7.914999992355717],
              [-36.462499999875035, -7.915833325747315],
              [-36.459999998800868, -7.915833325747315],
              [-36.459999999700187, -7.914999992355717],
              [-36.455833332742088, -7.914999992355717],
              [-36.455833333641408, -7.915833325747315],
              [-36.451666668481948, -7.915833326646634],
              [-36.451666666683252, -7.916666659138968],
              [-36.449166666508404, -7.916666659138968],
              [-36.449166666508404, -7.917499993429885],
              [-36.450000000799378, -7.917499992530566],
              [-36.449999999000738, -7.91833332592222],
              [-36.451666667582572, -7.91833332592222],
              [-36.451666666683252, -7.923333325372596],
              [-36.450833331493016, -7.923333327171292],
              [-36.450833332392335, -7.925833325547501],
              [-36.450000000799378, -7.925833326446821],
              [-36.449999999000738, -7.9299999925056],
              [-36.449166667407724, -7.9299999925056],
              [-36.449166666508404, -7.930833324997877],
              [-36.448333331318167, -7.930833325897254],
              [-36.448333332217487, -7.932499992680448],
              [-36.447499999725153, -7.932499993579768],
              [-36.447499999725153, -7.936666657839965],
              [-36.448333332217487, -7.936666658739284],
              [-36.448333334016127, -7.938333325522535],
              [-36.447499998825833, -7.938333325522535],
              [-36.447499999725153, -7.940833324798064],
              [-36.446666665434236, -7.940833325697383],
              [-36.446666666333556, -7.944166657465246],
              [-36.445833332941902, -7.944166658364566],
              [-36.445833332941902, -7.946666659438733],
              [-36.444999999550305, -7.946666658539414],
              [-36.445000000449625, -7.948333326221984],
              [-36.443333331867734, -7.948333326221984],
              [-36.443333332767054, -7.950833325497513],
              [-36.44249999847608, -7.950833325497513],
              [-36.44249999847608, -7.951666658889167],
              [-36.441666665983803, -7.951666657989847],
              [-36.441666666883123, -7.952499993180083],
              [-36.443333331867734, -7.952499991381444],
              [-36.443333331867734, -7.953333325672418],
              [-36.44416666795729, -7.953333325672418],
              [-36.444166665259331, -7.957499992630517],
              [-36.444999998650985, -7.957499993529837],
              [-36.444999997751665, -7.958333325122794],
              [-36.445833332941902, -7.958333326022114],
              [-36.445833332941902, -7.959166658514448],
              [-36.447499999725153, -7.959166658514448],
              [-36.447499997926514, -7.959999992805365],
              [-36.448333331318167, -7.959999992805365],
              [-36.448333332217487, -7.961666658689296],
              [-36.449166665609084, -7.961666659588616],
              [-36.449166666508404, -7.96249999298027],
              [-36.450000000799378, -7.96249999298027],
              [-36.450000001698697, -7.966666659039049],
              [-36.451666665783932, -7.966666659039049],
              [-36.451666665783932, -7.967499992430646],
              [-36.452500000074906, -7.967499992430646],
              [-36.452500000974226, -7.96833332672162],
              [-36.453333332567183, -7.9683333258223],
              [-36.453333333466503, -7.969166659213897],
              [-36.454166666858157, -7.969166659213897],
              [-36.454166665958837, -7.970833326896468],
              [-36.454999998451115, -7.970833326896468],
              [-36.454999998451115, -7.971666659388745],
              [-36.45749999862602, -7.971666657590106],
              [-36.457499999525339, -7.970833325997148],
              [-36.460833331293145, -7.970833325997148],
              [-36.460833331293145, -7.971666658489426],
              [-36.461666666483438, -7.971666658489426],
              [-36.461666666483438, -7.972499992780399],
              [-36.462499999875035, -7.972499992780399],
              [-36.462500000774355, -7.97416665866433],
              [-36.46333333236737, -7.97416665866433],
              [-36.463333333266689, -7.974999992955247],
              [-36.464166665758967, -7.974999992955247],
              [-36.464166665758967, -7.975833326346901],
              [-36.467499998426149, -7.975833325447582],
              [-36.467499999325469, -7.976666658839179],
              [-36.468333332717066, -7.976666658839179],
              [-36.468333331817746, -7.977499992230833],
              [-36.4691666652094, -7.977499993130152],
              [-36.4691666652094, -7.97833332472311],
              [-36.469999998600997, -7.97833332562243],
              [-36.470833332891971, -7.978333326521749],
              [-36.470833331992651, -7.977499993130152],
              [-36.471666668082207, -7.977499993130152],
              [-36.471666668082207, -7.971666659388745],
              [-36.472499999675222, -7.971666658489426],
              [-36.472499999675222, -7.969999992605551],
              [-36.47333333036886, -7.969999992605551],
              [-36.473333333966139, -7.969166660113217],
              [-36.474166666458473, -7.969166659213897],
              [-36.474166665559153, -7.9683333258223],
              [-36.477499998226278, -7.9683333258223],
              [-36.477499998226278, -7.967499992430646],
              [-36.478333331617932, -7.967499992430646],
              [-36.478333332517252, -7.966666659938369],
              [-36.481666666983074, -7.966666659039049],
              [-36.481666666083754, -7.965833325647395],
              [-36.483333332866948, -7.965833325647395],
              [-36.483333332866948, -7.964999991356478],
              [-36.490000000899272, -7.964999993155118],
              [-36.49, -7.969999993504871],
              [-36.488333332317382, -7.969999992605551],
              [-36.488333331418062, -7.970833325997148],
              [-36.487500000724424, -7.970833325997148],
              [-36.487499998925784, -7.971666658489426],
              [-36.48666666733277, -7.971666658489426],
              [-36.486666665534131, -7.973333326171996],
              [-36.485833333041853, -7.973333327071316],
              [-36.485833333041853, -7.97416665866433],
              [-36.48666666643345, -7.97416665866433],
              [-36.486666665534131, -7.976666658839179],
              [-36.487499999825104, -7.976666658839179],
              [-36.487499998925784, -7.977499993130152],
              [-36.489166666608355, -7.977499992230833],
              [-36.489166665709035, -7.97833332472311],
              [-36.49, -7.97833332472311],
              [-36.490000000899272, -7.979166659014084],
              [-36.49083333249223, -7.979166659014084],
              [-36.49083333249223, -7.980833325797278],
              [-36.491666667682523, -7.980833325797278],
              [-36.491666666783203, -7.98416665756514],
              [-36.494166666058732, -7.98416665846446],
              [-36.494166666058732, -7.984999992755434],
              [-36.494999999450386, -7.984999991856114],
              [-36.494999998551066, -7.988333325422559],
              [-36.495833333741302, -7.988333324523239],
              [-36.495833331942663, -7.98999999310513],
              [-36.498333331218191, -7.989999994004449],
              [-36.498333333016888, -7.990833325597464],
              [-36.495833332841983, -7.990833326496784],
              [-36.495833332841983, -7.992499992380715],
              [-36.496666666233637, -7.992499992380715],
              [-36.496666668032276, -7.998333326122065],
              [-36.495833332841983, -7.998333327021385],
              [-36.495833332841983, -8.003333325572498],
              [-36.496666665334317, -8.003333326471818],
              [-36.496666665334317, -8.004166658964095],
              [-36.497499998725914, -8.004166658964095],
              [-36.497499998725914, -8.004999992355692],
              [-36.499166667307804, -8.004999992355692],
              [-36.499166667307804, -8.006666660038263],
              [-36.500000000699401, -8.006666659138943],
              [-36.499999999800082, -8.007499992530597],
              [-36.500833331393096, -8.007499992530597],
              [-36.500833332292416, -8.008333326821514],
              [-36.503333330668625, -8.008333325922194],
              [-36.503333331567944, -8.009166658414529],
              [-36.501666665684013, -8.009166658414529],
              [-36.501666665684013, -8.010833326097099],
              [-36.499999999800082, -8.010833326097099],
              [-36.499999999800082, -8.011666658589377],
              [-36.499166667307804, -8.011666657690057],
              [-36.499166666408485, -8.012499992880294],
              [-36.496666665334317, -8.012499992880294],
              [-36.496666664434997, -8.013333325372628],
              [-36.494166666958051, -8.013333327171267],
              [-36.494166666958051, -8.012499992880294],
              [-36.493333331767815, -8.012499992880294],
              [-36.493333330868495, -8.013333324473308],
              [-36.485833333041853, -8.013333327171267],
              [-36.485833331243214, -8.014166657864905],
              [-36.485000000549519, -8.014166658764225],
              [-36.48499999875088, -8.014999993055199],
              [-36.484166666258602, -8.014999993954518],
              [-36.483333331967629, -8.014999993055199],
              [-36.483333333766325, -8.014166659663545],
              [-36.481666667882394, -8.014166657864905],
              [-36.481666666083754, -8.014999993055199],
              [-36.479999998401183, -8.014999993954518],
              [-36.480000001099143, -8.01666665803981],
              [-36.475833331443027, -8.01666665803981],
              [-36.475833333241667, -8.015833326446796],
              [-36.47500000074939, -8.015833324648156],
              [-36.475000001648709, -8.012499992880294],
              [-36.474166667357792, -8.012499992880294],
              [-36.474166666458473, -8.011666658589377],
              [-36.472499999675222, -8.011666658589377],
              [-36.472499998775902, -8.009999992705445],
              [-36.47083333379129, -8.009999992705445],
              [-36.470833332891971, -8.009166658414529],
              [-36.466666666833191, -8.009166658414529],
              [-36.466666666833191, -8.008333325922194],
              [-36.464999998251301, -8.008333325922194],
              [-36.464999998251301, -8.007499992530597],
              [-36.464166666658286, -8.007499992530597],
              [-36.464166664859647, -8.006666659138943],
              [-36.46333333236737, -8.006666659138943],
              [-36.46333333146805, -8.005833325747346],
              [-36.462499998975716, -8.005833325747346],
              [-36.462500000774355, -8.003333325572498],
              [-36.459999999700187, -8.003333325572498],
              [-36.460000000599507, -8.002499993080164],
              [-36.459166666308533, -8.002499993979484],
              [-36.45916666720791, -8.003333326471818],
              [-36.454999998451115, -8.003333326471818],
              [-36.454999998451115, -8.002499993979484],
              [-36.453333331667864, -8.002499993080164],
              [-36.453333331667864, -8.001666659688567],
              [-36.452499998276267, -8.001666658789247],
              [-36.452500000974226, -8.000833325397593],
              [-36.449999998101362, -8.000833325397593],
              [-36.449999999900058, -7.999999992005996],
              [-36.449166666508404, -7.999999992905316],
              [-36.449166665609084, -7.999166658614342],
              [-36.448333332217487, -7.999166658614342],
              [-36.448333331318167, -7.997499991831091],
              [-36.447500000624473, -7.997499992730411],
              [-36.447499999725153, -7.996666658439494],
              [-36.443333331867734, -7.996666658439494],
              [-36.443333332767054, -7.99583332594716],
              [-36.44249999847608, -7.99583332594716],
              [-36.442500000274777, -7.994999992555563],
              [-36.440833331692886, -7.994999992555563],
              [-36.440833333491526, -7.994166659163966],
              [-36.43666666653337, -7.994166659163966],
              [-36.43666666563405, -7.993333326671632],
              [-36.435833331343133, -7.993333325772312],
              [-36.435833334041092, -7.990833325597464],
              [-36.434999999750119, -7.990833325597464],
              [-36.435000000649495, -7.994166659163966],
              [-36.434166666358522, -7.994166659163966],
              [-36.434166666358522, -7.996666658439494],
              [-36.434999999750119, -7.996666657540175],
              [-36.434999999750119, -8.004166658964095],
              [-36.435833334041092, -8.004166659863415],
              [-36.435833332242453, -8.005833325747346],
              [-36.435000000649495, -8.005833325747346],
              [-36.434999998850799, -8.006666659138943],
              [-36.435833332242453, -8.006666659138943],
              [-36.435833331343133, -8.010833326097099],
              [-36.435000000649495, -8.010833326996419],
              [-36.434999998850799, -8.011666659488696],
              [-36.434166666358522, -8.011666658589377],
              [-36.434166666358522, -8.013333325372628],
              [-36.434166667257841, -8.014999993055199],
              [-36.433333332966924, -8.014999993055199],
              [-36.433333332067605, -8.015833325547476],
              [-36.434166667257841, -8.015833325547476],
              [-36.434166665459202, -8.019166659113978],
              [-36.434999999750119, -8.019166659113978],
              [-36.434999998850799, -8.020833325897229],
              [-36.435833332242453, -8.020833324997909],
              [-36.435833332242453, -8.021666657490186],
              [-36.436666667432689, -8.021666657490186],
              [-36.43666666653337, -8.025833324448342],
              [-36.437499999925024, -8.025833325347662],
              [-36.437499999925024, -8.034999991756195],
              [-36.438333331517981, -8.034999991756195],
              [-36.438333331517981, -8.036666658539389],
              [-36.439166665808955, -8.036666658539389],
              [-36.439166666708275, -8.038333326221959],
              [-36.439999998301232, -8.038333326221959],
              [-36.440000000099872, -8.039166658714294],
              [-36.440833331692886, -8.039166658714294],
              [-36.440833331692886, -8.03999999300521],
              [-36.442500000274777, -8.03999999300521],
              [-36.442500000274777, -8.039166657814974],
              [-36.443333331867734, -8.039166658714294],
              [-36.443333332767054, -8.04416665816467],
              [-36.441666667782442, -8.04416665906399],
              [-36.441666665983803, -8.047499992630492],
              [-36.438333332417301, -8.047499992630492],
              [-36.438333331517981, -8.044999992455644],
              [-36.437499999025704, -8.044999992455644],
              [-36.437499999925024, -8.04416665906399],
              [-36.43666666563405, -8.04416665906399],
              [-36.43666666563405, -8.043333325672393],
              [-36.435833332242453, -8.043333326571712],
              [-36.435833334041092, -8.041666658889142],
              [-36.434166666358522, -8.041666658889142],
              [-36.434166665459202, -8.042499993180115],
              [-36.429999998501103, -8.042499994079435],
              [-36.429999998501103, -8.041666658889142],
              [-36.428333332617171, -8.041666658889142],
              [-36.428333332617171, -8.040833326396864],
              [-36.427499998326198, -8.040833325497545],
              [-36.427499999225518, -8.03999999300521],
              [-36.423333334066058, -8.03999999300521],
              [-36.423333332267418, -8.039166658714294],
              [-36.422499998875821, -8.039166658714294],
              [-36.422499998875821, -8.038333326221959],
              [-36.42083333479053, -8.038333326221959],
              [-36.42083333209257, -8.037499993729682],
              [-36.420000000499556, -8.037499992830362],
              [-36.419999998700916, -8.036666659438708],
              [-36.419166668906598, -8.036666657640069],
              [-36.419166668007279, -8.03249999158129],
              [-36.418333332816985, -8.032499992480609],
              [-36.418333331917665, -8.031666658189692],
              [-36.417499998526068, -8.031666659089012],
              [-36.417500001224028, -8.030833325697358],
              [-36.416666666933111, -8.030833325697358],
              [-36.416666664235095, -8.029166658914107],
              [-36.415833332642137, -8.029166658014788],
              [-36.415833333541457, -8.027499993030233],
              [-36.41499999835122, -8.027499993929553],
              [-36.41499999835122, -8.026666658739259],
              [-36.413333332467289, -8.026666658739259],
              [-36.413333331567969, -8.025833325347662],
              [-36.41083333409108, -8.025833325347662],
              [-36.410833332292384, -8.024999992855328],
              [-36.409999998001467, -8.024999992855328],
              [-36.410000000699426, -8.024166658564411],
              [-36.40916666550919, -8.024166658564411],
              [-36.40916666640851, -8.023333325172757],
              [-36.408333331218216, -8.023333326072077],
              [-36.408333331218216, -8.02249999268048],
              [-36.407499998725939, -8.02249999268048],
              [-36.407499998725939, -8.021666658389506],
              [-36.406666665334285, -8.021666658389506],
              [-36.406666668032244, -8.020833325897229],
              [-36.404999999450354, -8.020833325897229],
              [-36.404999998551034, -8.019166659113978],
              [-36.404166666058757, -8.019166659113978],
              [-36.404166666058757, -8.01666665893913],
              [-36.401666666783228, -8.01666665893913],
              [-36.401666667682548, -8.013333325372628],
              [-36.400833331592935, -8.013333327171267],
              [-36.400833332492255, -8.011666658589377],
              [-36.4, -8.011666658589377],
              [-36.399999999100658, -8.010833326097099],
              [-36.399166665709004, -8.010833326097099],
              [-36.399166668406963, -8.009999992705445],
              [-36.398333331418087, -8.009999992705445],
              [-36.398333331418087, -8.009166658414529],
              [-36.397499999825072, -8.009166658414529],
              [-36.397499999825072, -8.008333325922194],
              [-36.390833331792805, -8.008333325022875],
              [-36.390833331792805, -8.007499993429917],
              [-36.391666666983042, -8.007499992530597],
              [-36.391666666083722, -8.004999992355692],
              [-36.390833331792805, -8.004999992355692],
              [-36.390833332692125, -8.002499993080164],
              [-36.389999998401152, -8.002499993080164],
              [-36.389999998401152, -8.000833325397593],
              [-36.389166665908874, -8.000833325397593],
              [-36.389166665908874, -7.997499992730411],
              [-36.390000001099111, -7.997499992730411],
              [-36.389999998401152, -7.99583332594716],
              [-36.391666666983042, -7.99583332594716],
              [-36.391666667882362, -7.994999992555563],
              [-36.390833331792805, -7.994999992555563],
              [-36.390833331792805, -7.990833325597464],
              [-36.389999998401152, -7.990833325597464],
              [-36.389999999300471, -7.989999994004449],
              [-36.387500000024943, -7.98999999310513],
              [-36.387500000024943, -7.989166657914893],
              [-36.386666666633289, -7.989166657914893],
              [-36.386666666633289, -7.985833326147031],
              [-36.385833332342372, -7.985833326147031],
              [-36.385833333241692, -7.98416665846446],
              [-36.384999999850095, -7.98416665846446],
              [-36.384999999850095, -7.982499992580529],
              [-36.384166667357761, -7.982499991681209],
              [-36.384166667357761, -7.980833326696597],
              [-36.383333331268204, -7.980833325797278],
              [-36.383333331268204, -7.979999992405681],
              [-36.38249999967519, -7.979999992405681],
              [-36.38250000057451, -7.97833332562243],
              [-36.381666665384273, -7.978333326521749],
              [-36.381666665384273, -7.977499993130152],
              [-36.379999999500342, -7.977499993130152],
              [-36.380000000399662, -7.97833332562243],
              [-36.378333331817771, -7.97833332562243],
              [-36.378333331817771, -7.979166659014084],
              [-36.375833333441562, -7.979166659014084],
              [-36.375833332542243, -7.979999992405681],
              [-36.374999998251269, -7.97999999420432],
              [-36.374999998251269, -7.980833324897958],
              [-36.373333332367338, -7.980833325797278],
              [-36.373333332367338, -7.981666659188932],
              [-36.372499998975741, -7.981666659188932],
              [-36.372499998975741, -7.982499991681209],
              [-36.371666666483407, -7.982499992580529],
              [-36.371666667382726, -7.98416665936378],
              [-36.369166665409239, -7.98416665756514],
              [-36.369166667207878, -7.983333325972183],
              [-36.368333332916961, -7.983333325972183],
              [-36.368333332916961, -7.981666660088251],
              [-36.366666665234391, -7.981666657390292],
              [-36.36666666703303, -7.980833325797278],
              [-36.361666667582597, -7.980833325797278],
              [-36.361666666683277, -7.979999991506361],
              [-36.36083333239236, -7.979999992405681],
              [-36.360833331492984, -7.976666658839179],
              [-36.359166667407749, -7.976666658839179],
              [-36.359166667407749, -7.975833325447582],
              [-36.357500000624498, -7.975833325447582],
              [-36.357499999725178, -7.974999992955247],
              [-36.355833332042607, -7.974999992955247],
              [-36.355833332042607, -7.974166657765011],
              [-36.355000000449593, -7.97416665866433],
              [-36.354999998650953, -7.973333326171996],
              [-36.351666665983828, -7.973333327071316],
              [-36.351666666883148, -7.974166657765011],
              [-36.348333331518006, -7.97416665956365],
              [-36.348333332417326, -7.971666658489426],
              [-36.347500001723631, -7.971666658489426],
              [-36.347499999924992, -7.969999992605551],
              [-36.346666666533395, -7.969999992605551],
              [-36.346666665634075, -7.969166659213897],
              [-36.345833331343101, -7.969166659213897],
              [-36.345833332242421, -7.9683333258223],
              [-36.345000000649463, -7.9683333258223],
              [-36.344999999750144, -7.965833325647395],
              [-36.343333330268933, -7.965833324748075],
              [-36.343333332966893, -7.966666659039049],
              [-36.342499998675976, -7.966666659039049],
              [-36.342499999575296, -7.967499992430646],
              [-36.341666667982281, -7.967499992430646],
              [-36.341666665284322, -7.9683333258223],
              [-36.339166666908113, -7.96833332672162],
              [-36.339166666908113, -7.967499992430646],
              [-36.338333333516459, -7.967499994229286],
              [-36.33833333261714, -7.96833332492298],
              [-36.33416666655836, -7.96833332492298],
              [-36.334166665659041, -7.969166659213897],
              [-36.332499999775109, -7.969166659213897],
              [-36.332500000674429, -7.969999993504871],
              [-36.331666665484192, -7.969999992605551],
              [-36.331666664584873, -7.969166660113217],
              [-36.32833333281701, -7.969166659213897],
              [-36.32833333191769, -7.9683333258223],
              [-36.325833333541482, -7.9683333258223],
              [-36.324999998351188, -7.9683333258223],
              [-36.324999998351188, -7.970833325997148],
              [-36.324166665858911, -7.970833325997148],
              [-36.324166667657551, -7.972499992780399],
              [-36.323333331567937, -7.972499992780399],
              [-36.323333332467257, -7.976666658839179],
              [-36.321666666583326, -7.976666658839179],
              [-36.321666667482702, -7.977499993130152],
              [-36.320833334091049, -7.977499993130152],
              [-36.320833331393089, -7.975833325447582],
              [-36.318333331218241, -7.975833325447582],
              [-36.318333333016881, -7.974999992955247],
              [-36.31666666533431, -7.974999992955247],
              [-36.31666666623363, -7.971666658489426],
              [-36.315833331942656, -7.971666657590106],
              [-36.315833331942656, -7.969166659213897],
              [-36.315000000349698, -7.969166659213897],
              [-36.315000000349698, -7.967499992430646],
              [-36.314166666058725, -7.967499994229286],
              [-36.314166667857364, -7.966666659039049],
              [-36.311666666783196, -7.966666659039049],
              [-36.311666667682516, -7.965833324748075],
              [-36.31083333249228, -7.965833325647395],
              [-36.31083333159296, -7.964999993155118],
              [-36.309166667507668, -7.964999993155118],
              [-36.309166666608348, -7.964166658864144],
              [-36.308333331418055, -7.964166658864144],
              [-36.308333332317375, -7.96249999387959],
              [-36.310000000899265, -7.96249999298027],
              [-36.309999999100626, -7.959999992805365],
              [-36.310833333391599, -7.959999992805365],
              [-36.310833333391599, -7.959166658514448],
              [-36.309166666608348, -7.959166659413768],
              [-36.309166665709029, -7.959999992805365],
              [-36.305833333941166, -7.959999993704685],
              [-36.305833333041846, -7.959166658514448],
              [-36.304999997851553, -7.959166659413768],
              [-36.304999998750873, -7.959999992805365],
              [-36.302499999475344, -7.959999992805365],
              [-36.302499999475344, -7.960833324398379],
              [-36.297499998226272, -7.960833326197019],
              [-36.297499998226272, -7.96249999298027],
              [-36.296666665733994, -7.96249999387959],
              [-36.296666667532634, -7.963333324573227],
              [-36.294166666458466, -7.963333326371867],
              [-36.294166666458466, -7.964166659763464],
              [-36.293333334865451, -7.964166658864144],
              [-36.293333333066812, -7.967499994229286],
              [-36.292499999675215, -7.967499992430646],
              [-36.292499998775895, -7.9683333258223],
              [-36.291666665384241, -7.9683333258223],
              [-36.291666665384241, -7.969999991706231],
              [-36.290833333791284, -7.969999991706231],
              [-36.290833331992644, -7.970833325997148],
              [-36.28999999860099, -7.970833325997148],
              [-36.28999999950031, -7.971666658489426],
              [-36.289166667907352, -7.971666657590106],
              [-36.289166665209393, -7.973333326171996],
              [-36.288333331817739, -7.973333326171996],
              [-36.288333333616436, -7.97416665956365],
              [-36.286666666833185, -7.97416665866433],
              [-36.286666667732504, -7.974999992955247],
              [-36.286666666833185, -7.977499993130152],
              [-36.287499998426142, -7.977499994029472],
              [-36.287499999325462, -7.97833332472311],
              [-36.289166667907352, -7.97833332562243],
              [-36.289166664310073, -7.979166659014084],
              [-36.28999999860099, -7.979166659913403],
              [-36.28999999860099, -7.981666659188932],
              [-36.289166665209393, -7.981666659188932],
              [-36.289166667008033, -7.985833326147031],
              [-36.288333331817739, -7.985833326147031],
              [-36.288333333616436, -7.988333325422559],
              [-36.289166667008033, -7.988333325422559],
              [-36.289166667008033, -7.991666658989061],
              [-36.28999999950031, -7.991666658989061],
              [-36.28999999950031, -7.992499992380715],
              [-36.290833332891964, -7.992499992380715],
              [-36.290833331992644, -7.994166659163966],
              [-36.291666666283561, -7.994166659163966],
              [-36.291666665384241, -7.99583332594716],
              [-36.292499999675215, -7.99583332594716],
              [-36.292500000574535, -7.996666658439494],
              [-36.293333331268173, -7.996666658439494],
              [-36.293333333066812, -7.998333326122065],
              [-36.294166666458466, -7.998333326122065],
              [-36.294166665559146, -7.999166658614342],
              [-36.296666665733994, -7.999166657715023],
              [-36.296666666633314, -7.999999992905316],
              [-36.299999998401177, -7.999999992905316],
              [-36.300000001099136, -8.000833325397593],
              [-36.304166666258595, -8.000833325397593],
              [-36.304166665359276, -8.001666658789247],
              [-36.305000000549569, -8.001666658789247],
              [-36.305000000549569, -8.002499993979484],
              [-36.308333332317375, -8.002499992180844],
              [-36.308333331418055, -8.003333325572498],
              [-36.31083333249228, -8.003333325572498],
              [-36.31083333159296, -8.004166658964095],
              [-36.311666666783196, -8.004166658964095],
              [-36.311666665883877, -8.005833324848027],
              [-36.312499998376154, -8.005833325747346],
              [-36.312499998376154, -8.006666659138943],
              [-36.313333331767808, -8.006666659138943],
              [-36.313333333566447, -8.007499992530597],
              [-36.314166666058725, -8.007499992530597],
              [-36.314166666058725, -8.008333325922194],
              [-36.315000000349698, -8.008333326821514],
              [-36.314999999450379, -8.009999992705445],
              [-36.319999999800132, -8.009999992705445],
              [-36.320000000699451, -8.010833326097099],
              [-36.323333332467257, -8.010833326097099],
              [-36.323333332467257, -8.01249999377967],
              [-36.3225000008743, -8.012499991980974],
              [-36.32249999997498, -8.014166658764225],
              [-36.321666665684006, -8.014166658764225],
              [-36.321666665684006, -8.017499993230047],
              [-36.32249999997498, -8.017499993230047],
              [-36.32249999907566, -8.021666658389506],
              [-36.323333332467257, -8.021666658389506],
              [-36.323333331567937, -8.02249999268048],
              [-36.325833331742842, -8.02249999268048],
              [-36.325833332642162, -8.023333326072077],
              [-36.327499998526093, -8.023333326072077],
              [-36.327499998526093, -8.024166658564411],
              [-36.329166668007304, -8.024166658564411],
              [-36.329166668007304, -8.024999993754648],
              [-36.331666667282832, -8.024999992855328],
              [-36.331666665484192, -8.025833324448342],
              [-36.33249999887579, -8.025833325347662],
              [-36.33249999887579, -8.026666658739259],
              [-36.33416666655836, -8.026666659638579],
              [-36.33416666655836, -8.027499993929553],
              [-36.334999999050694, -8.027499993030233],
              [-36.334999999950014, -8.02833332642183],
              [-36.335833331542972, -8.02833332552251],
              [-36.335833330643652, -8.029166658914107],
              [-36.335833331542972, -8.029999991406441],
              [-36.333333331368124, -8.029999993205081],
              [-36.333333334066083, -8.030833326596678],
              [-36.33249999887579, -8.030833325697358],
              [-36.33249999887579, -8.031666658189692],
              [-36.330833333891178, -8.031666659089012],
              [-36.330833333891178, -8.03249999158129],
              [-36.329999998700941, -8.03249999158129],
              [-36.329999998700941, -8.033333325872263],
              [-36.327499998526093, -8.033333325872263],
              [-36.327500000324733, -8.034166658364541],
              [-36.325000001049148, -8.03416666016318],
              [-36.324999999250508, -8.033333325872263],
              [-36.324166665858911, -8.033333325872263],
              [-36.324166667657551, -8.034166658364541],
              [-36.323333332467257, -8.034166658364541],
              [-36.323333332467257, -8.033333325872263],
              [-36.317499999625227, -8.033333325872263],
              [-36.317499999625227, -8.03333336004647],
              [-36.317499999625227, -8.03416666016318],
              [-36.318333331218241, -8.034166658364541],
              [-36.318333331218241, -8.036666657640069],
              [-36.316666668032269, -8.036666658539389],
              [-36.31666666623363, -8.037499992830362],
              [-36.315833331942656, -8.037499993729682],
              [-36.315833333741296, -8.038333326221959],
              [-36.314166666058725, -8.038333326221959],
              [-36.314166667857364, -8.042499993180115],
              [-36.313333333566447, -8.042499993180115],
              [-36.313333333566447, -8.043333325672393],
              [-36.311666667682516, -8.043333325672393],
              [-36.311666666783196, -8.04416665906399],
              [-36.310833333391599, -8.044166659963366],
              [-36.31083333249228, -8.045833325847241],
              [-36.309999999100626, -8.045833325847241],
              [-36.309999999100626, -8.046666659238895],
              [-36.309166665709029, -8.046666659238895],
              [-36.309166665709029, -8.047499992630492],
              [-36.307499998925778, -8.047499992630492],
              [-36.307499999825097, -8.048333326022146],
              [-36.306666666433443, -8.048333325122826],
              [-36.306666665534124, -8.049999992805397],
              [-36.304999998750873, -8.049999992805397],
              [-36.305000000549569, -8.050833324398354],
              [-36.303333331967679, -8.050833326196994],
              [-36.303333331967679, -8.052499992980245],
              [-36.302500000374664, -8.052499992980245],
              [-36.302499998576025, -8.054166658864176],
              [-36.301666666083747, -8.054166658864176],
              [-36.301666666983067, -8.054999993155093],
              [-36.300833332692093, -8.054999993155093],
              [-36.300833333591413, -8.055833325647427],
              [-36.299999998401177, -8.055833325647427],
              [-36.299999998401177, -8.057499992430678],
              [-36.299166665908842, -8.057499992430678],
              [-36.299166667707482, -8.058333325822275],
              [-36.298333332517245, -8.058333325822275],
              [-36.298333334315885, -8.060833325997123],
              [-36.299166666808162, -8.060833325097803],
              [-36.299166667707482, -8.062499992780374],
              [-36.300000001099136, -8.062499992780374],
              [-36.300000001099136, -8.064166658664305],
              [-36.299166665908842, -8.064166658664305],
              [-36.299166665908842, -8.064999992955279],
              [-36.297499998226272, -8.064999992955279],
              [-36.297500000024911, -8.06666665883921],
              [-36.296666665733994, -8.06666665883921],
              [-36.296666664834675, -8.067499992230807],
              [-36.292499999675215, -8.067499993130127],
              [-36.292499999675215, -8.07083332489799],
              [-36.293333331268173, -8.070833325797309],
              [-36.293333331268173, -8.072499992580561],
              [-36.294166666458466, -8.072499992580561],
              [-36.294166667357786, -8.073333325972158],
              [-36.295000000749383, -8.073333325972158],
              [-36.294999999850063, -8.074166658464492],
              [-36.295833333241717, -8.074166657565172],
              [-36.295833331443021, -8.074999992755409],
              [-36.294999999850063, -8.074999992755409],
              [-36.294999998950743, -8.078333325422591],
              [-36.295833332342397, -8.078333325422591],
              [-36.295833331443021, -8.080833325597439],
              [-36.296666665733994, -8.080833325597439],
              [-36.296666666633314, -8.084999993454915],
              [-36.295000000749383, -8.084999991656218],
              [-36.294999999850063, -8.085833325947192],
              [-36.293333331268173, -8.085833326846512],
              [-36.293333333066812, -8.086666660238109],
              [-36.292499999675215, -8.086666658439469],
              [-36.292499998775895, -8.087499992730443],
              [-36.290833333791284, -8.087499999025681],
              [-36.288333333616436, -8.087499992730443],
              [-36.288333333616436, -8.086666658439469],
              [-36.287500000224782, -8.086666658439469],
              [-36.287499999325462, -8.085833325947192],
              [-36.286666666833185, -8.085833325947192],
              [-36.286666665933865, -8.084999991656218],
              [-36.285000000049934, -8.084999992555538],
              [-36.285000000049934, -8.084166659163941],
              [-36.281666665584112, -8.084166659163941],
              [-36.281666665584112, -8.083333324873024],
              [-36.279999998800861, -8.083333325772344],
              [-36.279999999700181, -8.08249999148137],
              [-36.277499998626013, -8.08249999238069],
              [-36.277499999525332, -8.081666658989093],
              [-36.269999999900051, -8.081666658989093],
              [-36.269999999000731, -8.079999992205842],
              [-36.269166665609077, -8.079999992205842],
              [-36.269166665609077, -8.079166658814188],
              [-36.26833333221748, -8.079166658814188],
              [-36.26833333221748, -8.074999992755409],
              [-36.266666665434229, -8.074999992755409],
              [-36.266666665434229, -8.074166658464492],
              [-36.264999998650978, -8.074166658464492],
              [-36.264999998650978, -8.074999992755409],
              [-36.260833331692879, -8.074999992755409],
              [-36.260833332592199, -8.074166658464492],
              [-36.260000000999185, -8.074166658464492],
              [-36.259999998301225, -8.073333325972158],
              [-36.258333331517974, -8.073333325972158],
              [-36.258333331517974, -8.072499991681241],
              [-36.255833332242446, -8.07249999347988],
              [-36.255833332242446, -8.071666659188907],
              [-36.254999998850849, -8.071666659188907],
              [-36.254999998850849, -8.070833325797309],
              [-36.254166665459195, -8.070833325797309],
              [-36.254166669056474, -8.069999992405656],
              [-36.253333332067598, -8.069999992405656],
              [-36.253333332966918, -8.069166659014058],
              [-36.249166666008762, -8.069166659014058],
              [-36.249166667807458, -8.069999992405656],
              [-36.247499999225568, -8.069999991506336],
              [-36.247499998326191, -8.070833325797309],
              [-36.244166666558385, -8.07083332489799],
              [-36.244166667457705, -8.069999992405656],
              [-36.243333331368092, -8.069999992405656],
              [-36.243333332267412, -8.069166659014058],
              [-36.242499997976495, -8.069166658114739],
              [-36.242499999775134, -8.068333324723085],
              [-36.2416666672828, -8.068333325622405],
              [-36.2416666672828, -8.069166659014058],
              [-36.239166666208632, -8.069166659014058],
              [-36.239166668007272, -8.068333325622405],
              [-36.238333333716355, -8.068333325622405],
              [-36.238333333716355, -8.067499994029447],
              [-36.236666666933104, -8.067499992230807],
              [-36.23583333264213, -8.067499993130127],
              [-36.235833331742811, -8.066666657939891],
              [-36.231666665684031, -8.06666665883921],
              [-36.231666665684031, -8.065833324548237],
              [-36.2299999998001, -8.065833325447556],
              [-36.22999999800146, -8.064999992955279],
              [-36.226666666233598, -8.064999992055959],
              [-36.226666668032237, -8.064166659563625],
              [-36.22, -8.064166658664305],
              [-36.22000000089929, -8.063333326172028],
              [-36.219166665708997, -8.063333326172028],
              [-36.219166667507636, -8.062499992780374],
              [-36.217500000724385, -8.062499992780374],
              [-36.217499998925746, -8.061666658489457],
              [-36.216666666433468, -8.061666659388777],
              [-36.216666666433468, -8.059999992605526],
              [-36.215833331243175, -8.059999992605526],
              [-36.215833331243175, -8.059166659213929],
              [-36.214999998750898, -8.059166660113249],
              [-36.213333331967647, -8.059166659213929],
              [-36.213333333766286, -8.058333325822275],
              [-36.212499999475369, -8.058333325822275],
              [-36.212499999475369, -8.057499992430678],
              [-36.211666666083715, -8.057499991531358],
              [-36.211666664285076, -8.056666659039024],
              [-36.210000001099104, -8.056666658139704],
              [-36.210000001099104, -8.054999993155093],
              [-36.209166666808187, -8.054999991356453],
              [-36.209166666808187, -8.054166658864176],
              [-36.208333332517213, -8.054166657964856],
              [-36.208333330718574, -8.052499992980245],
              [-36.207499998226297, -8.052499992980245],
              [-36.207500000024936, -8.051666658689328],
              [-36.206666667532659, -8.051666658689328],
              [-36.206666666633339, -8.049999992805397],
              [-36.205833331443046, -8.049999992805397],
              [-36.205833332342365, -8.049166658514423],
              [-36.204166667357754, -8.049166659413743],
              [-36.204166665559114, -8.048333326022146],
              [-36.203333331268198, -8.048333326022146],
              [-36.203333333066837, -8.047499992630492],
              [-36.201666665384266, -8.047499992630492],
              [-36.201666665384266, -8.046666659238895],
              [-36.199166667008058, -8.046666659238895],
              [-36.199166667008058, -8.045833325847241],
              [-36.198333332717084, -8.045833325847241],
              [-36.198333334515723, -8.044999992455644],
              [-36.197500000224807, -8.044999992455644],
              [-36.197499999325487, -8.04416665906399],
              [-36.196666665933833, -8.04416665906399],
              [-36.196666666833153, -8.043333325672393],
              [-36.195833333441556, -8.043333325672393],
              [-36.195833332542236, -8.042499991381476],
              [-36.195000000949221, -8.042499993180115],
              [-36.195000000049902, -8.040833326396864],
              [-36.195833331642916, -8.040833326396864],
              [-36.195833331642916, -8.038333326221959],
              [-36.196666665933833, -8.038333326221959],
              [-36.196666667732472, -8.037499992830362],
              [-36.195833332542236, -8.037499993729682],
              [-36.195833331642916, -8.035833326047111],
              [-36.194999998251262, -8.035833326047111],
              [-36.195000000949221, -8.034999992655514],
              [-36.194166665758985, -8.034999992655514],
              [-36.194166665758985, -8.03249999158129],
              [-36.193333330568692, -8.032499992480609],
              [-36.193333332367331, -8.030833326596678],
              [-36.192500000774373, -8.030833325697358],
              [-36.192499998975734, -8.029999993205081],
              [-36.19166666558408, -8.029999993205081],
              [-36.19166666558408, -8.029166658914107],
              [-36.189166665409232, -8.029166658914107],
              [-36.189166667207871, -8.02833332552251],
              [-36.185833333641369, -8.02833332552251],
              [-36.18583333184273, -8.027499993030233],
              [-36.184166665958799, -8.027499993030233],
              [-36.184166667757495, -8.026666658739259],
              [-36.1799999990007, -8.026666658739259],
              [-36.180000000799339, -8.025833327146302],
              [-36.178333334915408, -8.025833325347662],
              [-36.178333332217449, -8.024999992855328],
              [-36.176666664534878, -8.024999992855328],
              [-36.176666665434198, -8.024166658564411],
              [-36.17583333294192, -8.024166658564411],
              [-36.17583333294192, -8.020833325897229],
              [-36.174999998650947, -8.020833325897229],
              [-36.174999999550323, -8.019999992505575],
              [-36.174166667057989, -8.019999992505575],
              [-36.174166665259349, -8.019166659113978],
              [-36.173333332767072, -8.019166659113978],
              [-36.173333332767072, -8.019999992505575],
              [-36.171666665983821, -8.019999992505575],
              [-36.171666666883141, -8.020833325897229],
              [-36.170833333491487, -8.020833325897229],
              [-36.16999999830125, -8.020833324997909],
              [-36.17000000009989, -8.019999991606255],
              [-36.169166666708236, -8.019999992505575],
              [-36.169166666708236, -8.019166659113978],
              [-36.168333333316639, -8.019166659113978],
              [-36.168333332417319, -8.018333324823061],
              [-36.166666667432708, -8.018333325722381],
              [-36.166666664734748, -8.017499991431407],
              [-36.165833332242471, -8.017499993230047],
              [-36.165833332242471, -8.01666665893913],
              [-36.164999999750137, -8.01666665893913],
              [-36.164999999750137, -8.015833325547476],
              [-36.164166669056499, -8.015833325547476],
              [-36.16416666635854, -8.014999993055199],
              [-36.163333332067566, -8.014999993055199],
              [-36.163333332067566, -8.013333325372628],
              [-36.16416666545922, -8.013333325372628],
              [-36.16416666635854, -8.009166658414529],
              [-36.165833331343151, -8.009166658414529],
              [-36.165833332242471, -8.008333325922194],
              [-36.166666666533388, -8.008333325922194],
              [-36.166666667432708, -8.009166658414529],
              [-36.167499999025665, -8.009166658414529],
              [-36.167499999025665, -8.009999992705445],
              [-36.169166666708236, -8.009999992705445],
              [-36.169166666708236, -8.010833326097099],
              [-36.17000000099921, -8.010833326996419],
              [-36.17000000009989, -8.011666659488696],
              [-36.170833333491487, -8.011666658589377],
              [-36.170833333491487, -8.010833326097099],
              [-36.172499999375418, -8.010833326097099],
              [-36.172499999375418, -8.009999992705445],
              [-36.174166667057989, -8.009999992705445],
              [-36.174166665259349, -8.005833324848027],
              [-36.175000000449643, -8.005833324848027],
              [-36.174999999550323, -8.004999992355692],
              [-36.17583333294192, -8.004999994154332],
              [-36.1758333320426, -8.004166658964095],
              [-36.177499998825851, -8.004166658964095],
              [-36.177499998825851, -8.003333325572498],
              [-36.179999999900019, -8.003333325572498],
              [-36.1799999990007, -8.002499993080164],
              [-36.179166666508422, -8.002499993080164],
              [-36.179166666508422, -8.001666658789247],
              [-36.178333332217449, -8.001666658789247],
              [-36.178333334915408, -8.000833325397593],
              [-36.177499999725171, -8.000833325397593],
              [-36.177499999725171, -7.997499992730411],
              [-36.176666665434198, -7.997499992730411],
              [-36.176666666333517, -7.99583332594716],
              [-36.17583333294192, -7.99583332594716],
              [-36.17583333294192, -7.992499992380715],
              [-36.176666665434198, -7.992499992380715],
              [-36.176666665434198, -7.990833326496784],
              [-36.17583333294192, -7.990833325597464],
              [-36.1758333320426, -7.98999999310513],
              [-36.176666667232837, -7.98999999310513],
              [-36.176666665434198, -7.988333325422559],
              [-36.177499999725171, -7.988333325422559],
              [-36.177499999725171, -7.98416665756514],
              [-36.176666666333517, -7.98416665846446],
              [-36.176666667232837, -7.983333325072863],
              [-36.174999998650947, -7.983333325972183],
              [-36.174999998650947, -7.982499992580529],
              [-36.174166667957309, -7.982499992580529],
              [-36.174166667957309, -7.97833332562243],
              [-36.173333333666392, -7.97833332562243],
              [-36.173333331867752, -7.977499993130152],
              [-36.174166667957309, -7.977499993130152],
              [-36.174166665259349, -7.975833325447582],
              [-36.173333332767072, -7.975833324548262],
              [-36.173333333666392, -7.974999993854567],
              [-36.171666666883141, -7.974999992055928],
              [-36.171666667782461, -7.973333327071316],
              [-36.172499998476098, -7.973333326171996],
              [-36.172499999375418, -7.972499992780399],
              [-36.174166667957309, -7.972499991881079],
              [-36.17416666436003, -7.971666658489426],
              [-36.17583333294192, -7.971666658489426],
              [-36.17583333384124, -7.970833325997148],
              [-36.176666666333517, -7.970833326896468],
              [-36.176666666333517, -7.96833332492298],
              [-36.177500000624491, -7.96833332492298],
              [-36.177499999725171, -7.966666659039049],
              [-36.176666669031533, -7.966666659938369],
              [-36.176666666333517, -7.963333325472547],
              [-36.1758333320426, -7.963333325472547],
              [-36.17583333294192, -7.961666658689296],
              [-36.174999998650947, -7.961666657789976],
              [-36.175000000449643, -7.957499991731197],
              [-36.17583333384124, -7.957499992630517],
              [-36.17583333294192, -7.956666659238863],
              [-36.176666664534878, -7.956666659238863],
              [-36.176666664534878, -7.955833325847266],
              [-36.179166666508422, -7.955833325847266],
              [-36.179166665609102, -7.954999992455669],
              [-36.180833332392353, -7.954999992455669],
              [-36.180833331493034, -7.955833324947946],
              [-36.181666665783951, -7.955833325847266],
              [-36.18166666758259, -7.954999992455669],
              [-36.182500000074924, -7.954999992455669],
              [-36.182499998276228, -7.954166659064015],
              [-36.183333332567202, -7.954166659064015],
              [-36.183333332567202, -7.953333325672418],
              [-36.184166667757495, -7.953333325672418],
              [-36.184166665958799, -7.952499993180083],
              [-36.184999999350453, -7.952499991381444],
              [-36.184999999350453, -7.951666657989847],
              [-36.185833333641369, -7.951666659788486],
              [-36.185833334540689, -7.950833326396832],
              [-36.186666667033023, -7.950833325497513],
              [-36.186666665234384, -7.949999993005235],
              [-36.185833333641369, -7.949999993005235],
              [-36.18583333184273, -7.948333324423345],
              [-36.184999998451133, -7.948333324423345],
              [-36.184999999350453, -7.946666658539414],
              [-36.184166667757495, -7.946666658539414],
              [-36.184166665958799, -7.945833326047136],
              [-36.183333332567202, -7.945833325147817],
              [-36.183333332567202, -7.944999993554802],
              [-36.182499998276228, -7.944999992655482],
              [-36.182499998276228, -7.944166658364566],
              [-36.18166666668327, -7.944166657465246],
              [-36.181666665783951, -7.942499992480634],
              [-36.180833331493034, -7.942499992480634],
              [-36.180833333291673, -7.94166665908898],
              [-36.181666665783951, -7.941666658189661],
              [-36.18166666758259, -7.940833324798064],
              [-36.182499998276228, -7.940833326596703],
              [-36.182499998276228, -7.939999993205049],
              [-36.184166666858118, -7.939999993205049],
              [-36.184166666858118, -7.938333325522535],
              [-36.185000000249772, -7.938333325522535],
              [-36.184999998451133, -7.937499993030201],
              [-36.185833333641369, -7.937499993030201],
              [-36.185833333641369, -7.936666658739284],
              [-36.186666667932343, -7.936666658739284],
              [-36.186666667033023, -7.933333325172782],
              [-36.18583333274205, -7.933333326072102],
              [-36.18583333274205, -7.932499991781128],
              [-36.184166666858118, -7.932499992680448],
              [-36.184166665958799, -7.931666658389531],
              [-36.182499998276228, -7.931666658389531],
              [-36.182499998276228, -7.929166658214683],
              [-36.181666665783951, -7.929166658214683],
              [-36.18166666758259, -7.926666658939098],
              [-36.182500000074924, -7.926666658939098],
              [-36.182500000074924, -7.920833326097068],
              [-36.183333331667882, -7.920833326097068],
              [-36.183333332567202, -7.9183333250229],
              [-36.182499998276228, -7.91833332592222],
              [-36.182499998276228, -7.916666659138968],
              [-36.18166666758259, -7.916666659138968],
              [-36.18166666758259, -7.914999992355717],
              [-36.180833331493034, -7.914999994154357],
              [-36.180833331493034, -7.91416665896412],
              [-36.180833332392353, -7.912499992180869],
              [-36.181666665783951, -7.912499993080189],
              [-36.181666665783951, -7.911666658789215],
              [-36.182500000974244, -7.911666658789215],
              [-36.182500000974244, -7.910833325397618],
              [-36.184166666858118, -7.910833325397618],
              [-36.188333333816274, -7.910833325397618],
              [-36.188333332017635, -7.909999992905284],
              [-36.192500000774373, -7.909999992905284],
              [-36.192499998975734, -7.909166658614367],
              [-36.194166667557624, -7.909166658614367],
              [-36.194166665758985, -7.908333326122033],
              [-36.197499997526847, -7.908333326122033],
              [-36.197500000224807, -7.909166658614367],
              [-36.198333332717084, -7.909166658614367],
              [-36.198333332717084, -7.908333327021353],
              [-36.199166667907377, -7.908333326122033],
              [-36.199166667008058, -7.907499992730436],
              [-36.199999998601015, -7.907499992730436],
              [-36.200833332891932, -7.907499991831116],
              [-36.200833331992612, -7.903333326671657],
              [-36.201666666283586, -7.903333325772337],
              [-36.201666665384266, -7.902499992380683],
              [-36.204166666458434, -7.902499994179323],
              [-36.204166666458434, -7.903333325772337],
              [-36.204999998950768, -7.903333325772337],
              [-36.204999998950768, -7.902499992380683],
              [-36.205833331443046, -7.902499992380683],
              [-36.205833332342365, -7.900833324698112],
              [-36.206666665734019, -7.900833326496752],
              [-36.206666668431978, -7.899999993105155],
              [-36.207500000024936, -7.899999993105155],
              [-36.207499998226297, -7.900833325597432],
              [-36.209999998401145, -7.900833325597432],
              [-36.210000001099104, -7.899166658814181],
              [-36.214166665359301, -7.899166658814181],
              [-36.214166665359301, -7.897499992930307],
              [-36.216666666433468, -7.897499992930307],
              [-36.216666666433468, -7.896666658639333],
              [-36.219999999100651, -7.896666658639333],
              [-36.22, -7.895833326147056],
              [-36.223333332667153, -7.895833326147056],
              [-36.223333333566472, -7.894166657565165],
              [-36.22416666605875, -7.894166659363805],
              [-36.22416666695807, -7.893333325972151],
              [-36.224999998551027, -7.893333325072831],
              [-36.224999999450347, -7.892499991681234],
              [-36.229166665509183, -7.892499992580554],
              [-36.229166665509183, -7.8916666591889],
              [-36.230833332292434, -7.8916666591889],
              [-36.230833331393114, -7.892499993479873],
              [-36.233333333366602, -7.892499991681234],
              [-36.233333331567962, -7.893333325072831],
              [-36.23583333354145, -7.893333325972151],
              [-36.23583333264213, -7.894166659363805],
              [-36.237499998526062, -7.894166658464485],
              [-36.237499998526062, -7.894999992755402],
              [-36.238333333716355, -7.894999992755402],
              [-36.238333331917715, -7.892499992580554],
              [-36.239166668007272, -7.892499992580554],
              [-36.239166666208632, -7.8916666591889],
              [-36.241666665484161, -7.8916666591889],
              [-36.2416666672828, -7.889999992405706],
              [-36.242499999775134, -7.889999992405706],
              [-36.242499999775134, -7.889166659913371],
              [-36.241666665484161, -7.889166658114732],
              [-36.241666665484161, -7.888333326521774],
              [-36.242499999775134, -7.888333325622455],
              [-36.242499998875815, -7.88749999313012],
              [-36.244166665659066, -7.88749999313012],
              [-36.244166667457705, -7.886666659738523],
              [-36.247499999225568, -7.886666658839204],
              [-36.247499999225568, -7.88749999313012],
              [-36.249999999400416, -7.88749999313012],
              [-36.249999998501096, -7.886666658839204],
              [-36.250833332792013, -7.886666658839204],
              [-36.250833332792013, -7.88583332454823],
              [-36.251666667082986, -7.88583332544755],
              [-36.251666667082986, -7.884999992955272],
              [-36.252499998675944, -7.884999992955272],
              [-36.252499998675944, -7.884166657764979],
              [-36.253333332067598, -7.884166657764979],
              [-36.253333332067598, -7.882499992780367],
              [-36.254166664559875, -7.882499992780367],
              [-36.254166666358515, -7.879999992605519],
              [-36.254999998850849, -7.879999992605519],
              [-36.254999999750169, -7.878333325822268],
              [-36.255833332242446, -7.878333325822268],
              [-36.255833330443807, -7.877499992430671],
              [-36.257499999925017, -7.877499992430671],
              [-36.257500000824336, -7.876666659039017],
              [-36.261666665084476, -7.876666659039017],
              [-36.261666668681755, -7.87583332654674],
              [-36.263333333666367, -7.87583332564742],
              [-36.263333332767047, -7.877499992430671],
              [-36.265833333841215, -7.877499992430671],
              [-36.265833333841215, -7.878333325822268],
              [-36.269999998101412, -7.878333324922949],
              [-36.269999999900051, -7.877499992430671],
              [-36.272500000974219, -7.877499992430671],
              [-36.272500000074899, -7.874999994054463],
              [-36.279999999700181, -7.874999993155086],
              [-36.2800000005995, -7.87583332564742],
              [-36.282500000774348, -7.87583332564742],
              [-36.282499999875029, -7.876666659039017],
              [-36.28416666575896, -7.876666659039017],
              [-36.28416666665828, -7.87583332564742],
              [-36.288333331817739, -7.87583332564742],
              [-36.288333333616436, -7.874999993155086],
              [-36.287499998426142, -7.874999993155086],
              [-36.287499998426142, -7.873333325472572],
              [-36.286666665933865, -7.873333325472572],
              [-36.286666665933865, -7.872499992980238],
              [-36.285833331642891, -7.872499992080918],
              [-36.285833331642891, -7.871666659588641],
              [-36.285000000049934, -7.871666658689321],
              [-36.284999997351974, -7.870833326196987],
              [-36.284166667557599, -7.870833327096307],
              [-36.28416666575896, -7.867499992630485],
              [-36.283333331468043, -7.867499992630485],
              [-36.283333332367363, -7.865833325847291],
              [-36.282499998975709, -7.865833324947914],
              [-36.282499998975709, -7.86416665906404],
              [-36.279166665409264, -7.86416665906404],
              [-36.279166665409264, -7.863333325672386],
              [-36.27833333201761, -7.863333325672386],
              [-36.27833333291693, -7.862499993180109],
              [-36.277499998626013, -7.862499993180109],
              [-36.277499999525332, -7.861666658889135],
              [-36.276666665234359, -7.861666658889135],
              [-36.276666664335039, -7.860833325497538],
              [-36.275833333641401, -7.860833325497538],
              [-36.275833333641401, -7.859999993005204],
              [-36.274999998451108, -7.859999993005204],
              [-36.274999999350428, -7.859166658714287],
              [-36.27416666685815, -7.859166658714287],
              [-36.27416666685815, -7.857499992830355],
              [-36.271666665783982, -7.857499992830355],
              [-36.271666665783982, -7.856666658539439],
              [-36.270833332392328, -7.856666658539439],
              [-36.270833331493009, -7.850833325697351],
              [-36.271666667582622, -7.850833325697351],
              [-36.271666667582622, -7.848333325522503],
              [-36.270833332392328, -7.848333325522503],
              [-36.270833332392328, -7.846666658739252],
              [-36.269166665609077, -7.846666658739252],
              [-36.269166665609077, -7.845833325347655],
              [-36.269999999000731, -7.845833324448336],
              [-36.270000000799371, -7.84333332607207],
              [-36.270833332392328, -7.84333332607207],
              [-36.270833332392328, -7.840833325897222],
              [-36.271666665783982, -7.840833325897222],
              [-36.271666666683302, -7.838333326621694],
              [-36.272500000074899, -7.838333326621694],
              [-36.272500000974219, -7.83749999323004],
              [-36.273333331667857, -7.83749999323004],
              [-36.273333333466496, -7.835833326446789],
              [-36.27416666685815, -7.835833325547469],
              [-36.27416666685815, -7.834999993055192],
              [-36.272500000074899, -7.834999993055192],
              [-36.272500000974219, -7.834166658764218],
              [-36.271666665783982, -7.834166658764218],
              [-36.271666666683302, -7.832499992880344],
              [-36.270833333291648, -7.832499992880344],
              [-36.270833333291648, -7.83166665858937],
              [-36.266666666333549, -7.83166665858937],
              [-36.266666667232869, -7.832499992880344],
              [-36.264166664360005, -7.832499992880344],
              [-36.264166665259324, -7.83166665858937],
              [-36.263988220565075, -7.83166665858937],
              [-36.263506026999899, -7.831741225999962],
              [-36.26133458, -7.832077022999957],
              [-36.261332895, -7.832076577999937],
              [-36.255261403, -7.830472146999969],
              [-36.253293717, -7.829952015999945],
              [-36.247495334, -7.826101512999962],
              [-36.24340675, -7.822178784999949],
              [-36.240012084999897, -7.817680998999953],
              [-36.239538361, -7.811211507999927],
              [-36.242305509999902, -7.807333511999955],
              [-36.251232683, -7.804574061999966],
              [-36.254336355, -7.801693300999944],
              [-36.254471955491979, -7.800833325512381],
              [-36.253333332067598, -7.800833324897951],
              [-36.253333333866237, -7.799999994204313],
              [-36.252499998675944, -7.799999992405674],
              [-36.252499997776624, -7.799166659913396],
              [-36.251666667082986, -7.799166659014077],
              [-36.251666667082986, -7.797499993130145],
              [-36.250833331892693, -7.797499993130145],
              [-36.250833331892693, -7.796666658839172],
              [-36.250000000299735, -7.796666659738491],
              [-36.249999998501096, -7.795833325447575],
              [-36.250833332792013, -7.795833325447575],
              [-36.250833331892693, -7.793333326172046],
              [-36.249999999400416, -7.793333326172046],
              [-36.249999998501096, -7.792499993679712],
              [-36.249166667807458, -7.792499992780392],
              [-36.249166666908081, -7.78916665921389],
              [-36.248333332617165, -7.78916665921389],
              [-36.248333332617165, -7.787744232245539],
              [-36.248129806859332, -7.787499991750962],
              [-36.247499999225568, -7.787499992430639],
              [-36.247499999769332, -7.786744193581778],
              [-36.246305441999901, -7.785310667999974],
              [-36.245876328569985, -7.784999993155111],
              [-36.245833332442317, -7.784999993155111],
              [-36.245833332453515, -7.784968864295275],
              [-36.24242325355538, -7.782499993225799],
              [-36.239999999600286, -7.782499993879583],
              [-36.239999999600286, -7.781666658689289],
              [-36.239166665309313, -7.781666658689289],
              [-36.239166665309313, -7.780833326197012],
              [-36.238333332817035, -7.780833326197012],
              [-36.238333332817035, -7.779999992805358],
              [-36.237499998526062, -7.779999992805358],
              [-36.2374999991004, -7.778935594829515],
              [-36.236668127136333, -7.778333326022107],
              [-36.236666666933104, -7.778333326022107],
              [-36.236666666932535, -7.778332268845955],
              [-36.234366076368758, -7.776666659238913],
              [-36.234166666758199, -7.776666659238913],
              [-36.234166666602398, -7.776522288124896],
              [-36.233942355999901, -7.776359888999924],
              [-36.233387840885598, -7.775833325847259],
              [-36.233333331567962, -7.775833325847259],
              [-36.233333331623825, -7.775781564284791],
              [-36.232510271944406, -7.774999993343897],
              [-36.232500000874325, -7.774999993354982],
              [-36.232500000853271, -7.774990239997257],
              [-36.231632701132376, -7.774166659064008],
              [-36.230833334990393, -7.774166659064008],
              [-36.230833331713669, -7.773407584206709],
              [-36.230755132158421, -7.773333326529535],
              [-36.229166665509183, -7.773333325672411],
              [-36.229166668207142, -7.772499993180077],
              [-36.227499999625252, -7.772499993180077],
              [-36.227499998725932, -7.77166665888916],
              [-36.226666666233598, -7.77166665888916],
              [-36.226666666233598, -7.770833325497506],
              [-36.225833332842001, -7.770833325497506],
              [-36.225833331942681, -7.769166657814935],
              [-36.224999998551027, -7.769166658714255],
              [-36.224999998551027, -7.768541404342897],
              [-36.224677960326851, -7.768333324655031],
              [-36.222500001074138, -7.768333326221978],
              [-36.222499999275499, -7.767499992830381],
              [-36.220833331592928, -7.7674999937297],
              [-36.220833332483693, -7.765849181775401],
              [-36.220808793084252, -7.76583332604713],
              [-36.219166666608317, -7.76583332604713],
              [-36.219166665708997, -7.766666657640087],
              [-36.21833333051876, -7.766666657640087],
              [-36.21833333141808, -7.767499992830381],
              [-36.216666666433468, -7.767499992830381],
              [-36.216666667332788, -7.766666658539407],
              [-36.215000000549537, -7.766666658539407],
              [-36.215000000549537, -7.76583332604713],
              [-36.21416666625862, -7.76583332604713],
              [-36.21416666625862, -7.766666658539407],
              [-36.213333331967647, -7.766666658539407],
              [-36.213333333766286, -7.767499992830381],
              [-36.211666666083715, -7.767499991931061],
              [-36.211666666083715, -7.769166658714255],
              [-36.210833330893479, -7.769166659613632],
              [-36.209999999300464, -7.769166658714255],
              [-36.209999998401145, -7.774999992455662],
              [-36.208333332517213, -7.774999992455662],
              [-36.208333331617894, -7.775833325847259],
              [-36.2045900871477, -7.775833325847259],
              [-36.20434868, -7.782962722999966],
              [-36.20095837799991, -7.789903159999941],
              [-36.197873156, -7.790380621999925],
              [-36.197871952, -7.790380101999969],
              [-36.192504153999899, -7.788063690999931],
              [-36.189999998945005, -7.786533111923665],
              [-36.189999998800886, -7.786666659039042],
              [-36.188333332916955, -7.786666659039042],
              [-36.188333332916955, -7.78749999153132],
              [-36.185000000249772, -7.787499994229279],
              [-36.184999998451133, -7.785833325647388],
              [-36.184166665958799, -7.785833325647388],
              [-36.184166665958799, -7.784999993155111],
              [-36.18166666668327, -7.784999993155111],
              [-36.181666665783951, -7.784166658864194],
              [-36.180833332392353, -7.784166658864194],
              [-36.180833332392353, -7.783762811494415],
              [-36.179851789999901, -7.783711992999937],
              [-36.178045654999899, -7.783618495999971],
              [-36.175869308999907, -7.783804975999939],
              [-36.172217374999903, -7.784117890999948],
              [-36.172213875999901, -7.784117734999941],
              [-36.16237012900001, -7.783678558999957],
              [-36.155097301, -7.782510807999929],
              [-36.143370235, -7.781562211999938],
              [-36.126819704, -7.784202341999955],
              [-36.120833332692087, -7.785350726738358],
              [-36.120833332692087, -7.786666659039042],
              [-36.120000001099129, -7.786666658139723],
              [-36.11999999840117, -7.785833325647388],
              [-36.116666665733987, -7.785833325647388],
              [-36.116666665733987, -7.785611657741763],
              [-36.115058573999903, -7.78515462999997],
              [-36.113567373820302, -7.784166658864194],
              [-36.113333331268166, -7.784166658864194],
              [-36.113333331551502, -7.784035384851093],
              [-36.111861516247139, -7.783333326173315],
              [-36.106666666833178, -7.78333332547254],
              [-36.106666665933858, -7.782499992980263],
              [-36.101666665584105, -7.782499992980263],
              [-36.101666667382744, -7.781666658689289],
              [-36.098333332916923, -7.781666658689289],
              [-36.097499998626006, -7.781666658689289],
              [-36.097499998626006, -7.780833324398372],
              [-36.096666665234352, -7.780833326197012],
              [-36.096666665234352, -7.778554588301647],
              [-36.094328735, -7.778479253999924],
              [-36.094111106999897, -7.778472240999969],
              [-36.08093159, -7.779055910999945],
              [-36.071831671, -7.78022141099997],
              [-36.068992007, -7.780973591999945],
              [-36.064065074, -7.785077955999945],
              [-36.059374616, -7.79226197799994],
              [-36.056179089, -7.799159965999935],
              [-36.053872496999901, -7.801360964999952],
              [-36.050935161, -7.801150458999927],
              [-36.050497359, -7.801119083999938],
              [-36.047331797, -7.798385154999955],
              [-36.045442476999902, -7.795107362999945],
              [-36.043245592999902, -7.79366253899996],
              [-36.043243604999901, -7.793662581999961],
              [-36.041992114, -7.793689520999973],
              [-36.036164520999897, -7.796015420999942],
              [-36.035409898, -7.796192019999981],
              [-36.033639408999903, -7.79660454499998],
              [-36.030071929, -7.799512774999934],
              [-36.027624585999902, -7.802514595999982],
              [-36.025889102, -7.806225273999925],
              [-36.021587325, -7.810239122999972],
              [-36.018731964, -7.811274527999957],
              [-36.015025237, -7.811798945999958],
              [-36.009207198, -7.81175862699996],
              [-36.003333333114043, -7.812826605953537],
              [-36.003333332567195, -7.813333324872985],
              [-36.002499998276278, -7.813333325772305],
              [-36.002499998276278, -7.814166659163959],
              [-36.001666667582583, -7.814166659163959],
              [-36.001666667582583, -7.817499992730461],
              [-35.994999998650997, -7.817499991831085],
              [-35.994999998650997, -7.818333326122058],
              [-35.993333331867746, -7.818333326122058],
              [-35.993333332767065, -7.819166658614336],
              [-35.990000000099883, -7.819166659513655],
              [-35.990000000099883, -7.820833325397587],
              [-35.989166666708286, -7.820833325397587],
              [-35.989166667607606, -7.822499992180838],
              [-35.988333331517993, -7.822499992180838],
              [-35.988333331517993, -7.824999992355743],
              [-35.987500000824355, -7.824999992355743],
              [-35.987500000824355, -7.82583332574734],
              [-35.986666665634061, -7.82583332574734],
              [-35.986666667432701, -7.82749999342991],
              [-35.985833334940423, -7.827499991631271],
              [-35.985833331343144, -7.829166658414522],
              [-35.98500000064945, -7.829166658414522],
              [-35.98499999885081, -7.829999992705439],
              [-35.984166667257853, -7.829999992705439],
              [-35.984166667257853, -7.83166665858937],
              [-35.983333332966879, -7.83166665858937],
              [-35.98333333026892, -7.832499992880344],
              [-35.982499999575282, -7.832499991981024],
              [-35.982499998675962, -7.834999993954511],
              [-35.981666665284308, -7.834999992155872],
              [-35.981666667082948, -7.835833324648149],
              [-35.980000000299754, -7.835833325547469],
              [-35.979999998501114, -7.836666659838443],
              [-35.9791666669081, -7.836666658939123],
              [-35.9791666669081, -7.83749999323004],
              [-35.979999998501114, -7.83749999323004],
              [-35.980000002098393, -7.839999992505625],
              [-35.980833333691351, -7.839999992505625],
              [-35.980833332792031, -7.841666660188196],
              [-35.979999998501114, -7.84166665749018],
              [-35.979999999400434, -7.842499992680473],
              [-35.97916666600878, -7.842499992680473],
              [-35.979166667807419, -7.84333332607207],
              [-35.978333331717863, -7.84333332697139],
              [-35.978333332617183, -7.849166658914157],
              [-35.975833332442278, -7.849166658914157],
              [-35.975833331542958, -7.850833325697351],
              [-35.974999999950001, -7.850833325697351],
              [-35.974999999950001, -7.854166658364534],
              [-35.974166665659027, -7.854166658364534],
              [-35.974166666558347, -7.854999993554827],
              [-35.97333333136811, -7.854999992655507],
              [-35.97333333136811, -7.855833325147785],
              [-35.970833333891221, -7.855833325147785],
              [-35.970833333891221, -7.858333326221953],
              [-35.971666665484179, -7.858333326221953],
              [-35.971666666383499, -7.860833324598218],
              [-35.972499998875776, -7.860833325497538],
              [-35.972500001573792, -7.861666657989815],
              [-35.971666665484179, -7.861666658889135],
              [-35.971666667282818, -7.862499993180109],
              [-35.970833332991901, -7.862499993180109],
              [-35.970833332092582, -7.863333325672386],
              [-35.969999998700928, -7.863333324773066],
              [-35.969999998700928, -7.86416665906404],
              [-35.96916666620865, -7.86416665906404],
              [-35.969166665309331, -7.865833326746611],
              [-35.968333332816997, -7.865833325847291],
              [-35.968333331917677, -7.869166658514416],
              [-35.96916666620865, -7.869166658514416],
              [-35.96916666620865, -7.86999999280539],
              [-35.969999999600248, -7.86999999370471],
              [-35.969999999600248, -7.870833327096307],
              [-35.96916666620865, -7.870833324398347],
              [-35.96916666800729, -7.873333325472572],
              [-35.970000000499567, -7.873333325472572],
              [-35.969999999600248, -7.8758333247481],
              [-35.96916666620865, -7.87583332564742],
              [-35.969166665309331, -7.876666659039017],
              [-35.968333331018357, -7.876666659039017],
              [-35.968333332816997, -7.877499992430671],
              [-35.966666667832385, -7.877499992430671],
              [-35.966666666033746, -7.878333325822268],
              [-35.964999998351175, -7.878333325822268],
              [-35.964999999250495, -7.879999992605519],
              [-35.964166666758217, -7.879999992605519],
              [-35.964166665858897, -7.884166659563618],
              [-35.964166666758217, -7.884999992955272],
              [-35.9633333324673, -7.884999992055953],
              [-35.963333331567981, -7.886666658839204],
              [-35.962499999974966, -7.886666658839204],
              [-35.962499999075646, -7.88749999313012],
              [-35.961666666583369, -7.88749999313012],
              [-35.961666666583369, -7.889166659014052],
              [-35.959999999800118, -7.889166659014052],
              [-35.959999999800118, -7.889999992405706],
              [-35.955000000349685, -7.889999992405706],
              [-35.954999999450365, -7.889166659014052],
              [-35.954166667857407, -7.889166659014052],
              [-35.954166666958088, -7.890833325797303],
              [-35.952499999275517, -7.890833325797303],
              [-35.952499999275517, -7.8916666591889],
              [-35.949166666608335, -7.8916666591889],
              [-35.949166667507654, -7.890833325797303],
              [-35.947499999825084, -7.890833325797303],
              [-35.947500000724403, -7.889999992405706],
              [-35.946666665534167, -7.889999994204345],
              [-35.946666666433487, -7.889166659014052],
              [-35.944999999650236, -7.889166659913371],
              [-35.944999998750916, -7.88749999313012],
              [-35.941666666083734, -7.88749999313012],
              [-35.941666666083734, -7.886666658839204],
              [-35.940000001099122, -7.886666658839204],
              [-35.939999998401163, -7.88749999313012],
              [-35.939166666808205, -7.88749999402944],
              [-35.939166665009566, -7.886666658839204],
              [-35.9366666666333, -7.886666658839204],
              [-35.93666666753262, -7.88583332544755],
              [-35.933333333066798, -7.885833328145509],
              [-35.933333331268159, -7.886666658839204],
              [-35.934166666458452, -7.886666658839204],
              [-35.934166666458452, -7.88749999313012],
              [-35.93499999895073, -7.88749999313012],
              [-35.93499999895073, -7.8916666591889],
              [-35.934166667357772, -7.8916666591889],
              [-35.934166666458452, -7.894999992755402],
              [-35.934999999850049, -7.894999992755402],
              [-35.934999999850049, -7.897499992930307],
              [-35.935833331443064, -7.897499992030987],
              [-35.935833333241703, -7.898333325422584],
              [-35.939999998401163, -7.898333325422584],
              [-35.939999998401163, -7.900833324698112],
              [-35.941666666083734, -7.900833325597432],
              [-35.941666667882373, -7.904166659163934],
              [-35.942499998576011, -7.904166659163934],
              [-35.942499998576011, -7.904999992555588],
              [-35.942499999475331, -7.905833325947185],
              [-35.943333331967665, -7.905833325947185],
              [-35.943333331967665, -7.907499992730436],
              [-35.942499998576011, -7.907499992730436],
              [-35.942499999475331, -7.91416665896412],
              [-35.940833333591456, -7.91416665896412],
              [-35.94083333179276, -7.914999992355717],
              [-35.939166666808205, -7.914999992355717],
              [-35.939166667707525, -7.915833325747315],
              [-35.937500000024954, -7.915833325747315],
              [-35.937499998226315, -7.916666659138968],
              [-35.936666665733981, -7.916666660038288],
              [-35.936666665733981, -7.91833332592222],
              [-35.937500000924274, -7.91833332592222],
              [-35.937500000924274, -7.921666658589402],
              [-35.936666665733981, -7.921666658589402],
              [-35.9366666666333, -7.92416665876425],
              [-35.937499998226315, -7.92416665876425],
              [-35.937499998226315, -7.925833324648181],
              [-35.9366666666333, -7.925833325547501],
              [-35.93666666753262, -7.926666658939098],
              [-35.935833332342384, -7.926666658939098],
              [-35.935833331443064, -7.927499994129391],
              [-35.934999999850049, -7.927499993230072],
              [-35.934999999850049, -7.929166659114003],
              [-35.935833333241703, -7.929166659114003],
              [-35.935833331443064, -7.9299999925056],
              [-35.935000000749369, -7.9299999925056],
              [-35.935000000749369, -7.930833325897254],
              [-35.93499999895073, -7.932499992680448],
              [-35.934166666458452, -7.932499992680448],
              [-35.934166666458452, -7.934999992855353],
              [-35.933333333066798, -7.934999992855353],
              [-35.933333331268159, -7.93583332534763],
              [-35.932499998775882, -7.93583332534763],
              [-35.932499999675201, -7.936666658739284],
              [-35.931666665384284, -7.936666657839965],
              [-35.931666668082244, -7.938333326421855],
              [-35.93083333289195, -7.938333325522535],
              [-35.93083333379127, -7.939166658014813],
              [-35.931666666283604, -7.939166658914132],
              [-35.931666665384284, -7.946666658539414],
              [-35.93083333289195, -7.946666658539414],
              [-35.93083333289195, -7.948333326221984],
              [-35.929999998601033, -7.948333326221984],
              [-35.929999999500353, -7.949166659613581],
              [-35.930833331992631, -7.949166658714262],
              [-35.930833331992631, -7.950833326396832],
              [-35.929999999500353, -7.950833325497513],
              [-35.929999998601033, -7.951666658889167],
              [-35.93083333289195, -7.951666658889167],
              [-35.930833331992631, -7.954166659064015],
              [-35.931666668082244, -7.954166659963334],
              [-35.931666666283604, -7.955833324947946],
              [-35.932499998775882, -7.955833325847266],
              [-35.932499999675201, -7.957499992630517],
              [-35.931666665384284, -7.957499992630517],
              [-35.931666665384284, -7.958333326022114],
              [-35.932499999675201, -7.958333326022114],
              [-35.932499999675201, -7.959999992805365],
              [-35.933333334865495, -7.959999992805365],
              [-35.933333331268159, -7.961666658689296],
              [-35.933333331268159, -7.96249999208095],
              [-35.934166665559133, -7.96249999298027],
              [-35.934166667357772, -7.963333325472547],
              [-35.934999999850049, -7.963333324573227],
              [-35.93499999895073, -7.964166658864144],
              [-35.935833333241703, -7.964166658864144],
              [-35.935833332342384, -7.965833325647395],
              [-35.9366666666333, -7.965833325647395],
              [-35.9366666666333, -7.966666659039049],
              [-35.937500000924274, -7.966666659039049],
              [-35.937500000924274, -7.967499992430646],
              [-35.937500000024954, -7.9683333258223],
              [-35.9366666666333, -7.9683333258223],
              [-35.936666665733981, -7.969166659213897],
              [-35.935833332342384, -7.969166657415258],
              [-35.935833332342384, -7.972499991881079],
              [-35.9366666666333, -7.972499993679719],
              [-35.9366666666333, -7.97416665866433],
              [-35.935833331443064, -7.97416665866433],
              [-35.935833333241703, -7.981666659188932],
              [-35.936666665733981, -7.981666659188932],
              [-35.9366666666333, -7.983333325972183],
              [-35.937499998226315, -7.983333325972183],
              [-35.937500000924274, -7.98416665846446],
              [-35.9366666666333, -7.98416665846446],
              [-35.936666664834661, -7.984999992755434],
              [-35.935833332342384, -7.984999992755434],
              [-35.935833333241703, -7.987499993829601],
              [-35.938333331617912, -7.987499992930282],
              [-35.938333333416551, -7.988333325422559],
              [-35.940000001998442, -7.988333325422559],
              [-35.940000001099122, -7.990833325597464],
              [-35.941666666983053, -7.990833324698144],
              [-35.941666666983053, -7.994166659163966],
              [-35.942499997676691, -7.994166660063286],
              [-35.942499998576011, -7.99583332594716],
              [-35.943333332866985, -7.99583332594716],
              [-35.943333332866985, -7.997499992730411],
              [-35.944166665359262, -7.997499992730411],
              [-35.944166668057221, -7.999166658614342],
              [-35.943333333766304, -7.999166659513662],
              [-35.943333332866985, -7.998333326122065],
              [-35.94083333269208, -7.998333326122065],
              [-35.940833333591456, -7.997499992730411],
              [-35.938333333416551, -7.997499992730411],
              [-35.938333331617912, -7.996666658439494],
              [-35.934166667357772, -7.996666658439494],
              [-35.934166666458452, -7.994999992555563],
              [-35.933333333066798, -7.994999992555563],
              [-35.933333331268159, -7.994166657365327],
              [-35.931666666283604, -7.994166659163966],
              [-35.931666665384284, -7.993333325772312],
              [-35.930000000399673, -7.993333325772312],
              [-35.929166667008019, -7.993333325772312],
              [-35.92916666520938, -7.996666660238134],
              [-35.929999999500353, -7.996666658439494],
              [-35.929999998601033, -7.998333325222745],
              [-35.930833331992631, -7.998333327021385],
              [-35.93083333289195, -7.999999992905316],
              [-35.930000000399673, -7.999999992905316],
              [-35.929999999500353, -8.002499993080164],
              [-35.929166667008019, -8.002499993080164],
              [-35.929166667008019, -8.004166663460694],
              [-35.930833331992631, -8.004166658964095],
              [-35.930833331992631, -8.004999992355692],
              [-35.932499999675201, -8.004999992355692],
              [-35.932499999675201, -8.007499992530597],
              [-35.933333331268159, -8.007499992530597],
              [-35.933333333966118, -8.008333325922194],
              [-35.934166667357772, -8.008333325922194],
              [-35.934166666458452, -8.009166660213168],
              [-35.934999999850049, -8.009166658414529],
              [-35.934999999850049, -8.009999992705445],
              [-35.935833332342384, -8.009999992705445],
              [-35.935833333241703, -8.011666658589377],
              [-35.936666665733981, -8.011666658589377],
              [-35.9366666666333, -8.01249999377967],
              [-35.938333331617912, -8.012499992880294],
              [-35.938333332517232, -8.013333325372628],
              [-35.939166667707525, -8.013333325372628],
              [-35.939166665908886, -8.014999993055199],
              [-35.939999999300483, -8.014999993954518],
              [-35.940000001099122, -8.015833326446796],
              [-35.94083333269208, -8.015833325547476],
              [-35.94083333269208, -8.017499993230047],
              [-35.941666667882373, -8.017499993230047],
              [-35.941666666083734, -8.018333325722381],
              [-35.943333333766304, -8.018333325722381],
              [-35.943333331967665, -8.019999992505575],
              [-35.944999999650236, -8.019999993404952],
              [-35.944999999650236, -8.020833326796549],
              [-35.945833331243193, -8.020833325897229],
              [-35.945833333041833, -8.023333326072077],
              [-35.946666665534167, -8.023333325172757],
              [-35.946666666433487, -8.026666658739259],
              [-35.947499999825084, -8.026666658739259],
              [-35.947499998026444, -8.027499993030233],
              [-35.946666667332806, -8.027499993030233],
              [-35.946666667332806, -8.02833332552251],
              [-35.945833333941152, -8.02833332552251],
              [-35.945833333041833, -8.029166658914107],
              [-35.946666665534167, -8.029166658914107],
              [-35.946666666433487, -8.029999993205081],
              [-35.948333331418041, -8.029999993205081],
              [-35.948333333216738, -8.031666659089012],
              [-35.949166665709015, -8.031666659089012],
              [-35.949166667507654, -8.032499992480609],
              [-35.953333331767794, -8.032499992480609],
              [-35.953333331767794, -8.031666659089012],
              [-35.954999998551045, -8.031666658189692],
              [-35.954999998551045, -8.030833325697358],
              [-35.955833331942699, -8.030833325697358],
              [-35.955833333741339, -8.031666659988332],
              [-35.957499998725893, -8.031666659089012],
              [-35.957499999625213, -8.032499992480609],
              [-35.958333333016867, -8.032499992480609],
              [-35.958333333916187, -8.033333325872263],
              [-35.959166666408464, -8.033333325872263],
              [-35.959166667307784, -8.034999992655514],
              [-35.959999998900798, -8.034999992655514],
              [-35.959999999800118, -8.036666658539389],
              [-35.960833332292395, -8.036666658539389],
              [-35.960833332292395, -8.037499992830362],
              [-35.962499999974966, -8.037499992830362],
              [-35.962499999974966, -8.038333327121279],
              [-35.964166665858897, -8.038333326221959],
              [-35.964166665858897, -8.039166658714294],
              [-35.964999999250495, -8.039166658714294],
              [-35.965000001049191, -8.03999999300521],
              [-35.966666666033746, -8.03999999300521],
              [-35.966666666033746, -8.042499994079435],
              [-35.968333332816997, -8.042499993180115],
              [-35.968333332816997, -8.043333325672393],
              [-35.96916666620865, -8.043333325672393],
              [-35.96916666620865, -8.042499993180115],
              [-35.970833332092582, -8.042499993180115],
              [-35.970833332991901, -8.04416665906399],
              [-35.971666664584859, -8.04416665906399],
              [-35.971666665484179, -8.044999991556324],
              [-35.975833331542958, -8.044999991556324],
              [-35.975833333341598, -8.045833325847241],
              [-35.978333332617183, -8.045833325847241],
              [-35.978333331717863, -8.046666659238895],
              [-35.9791666669081, -8.046666660138214],
              [-35.97916666600878, -8.047499992630492],
              [-35.979999999400434, -8.047499993529811],
              [-35.979999999400434, -8.048333326022146],
              [-35.982499998675962, -8.048333326022146],
              [-35.982499998675962, -8.049166658514423],
              [-35.984166666358533, -8.049166657615103],
              [-35.984166667257853, -8.049999992805397],
              [-35.98499999975013, -8.049999992805397],
              [-35.98499999885081, -8.051666658689328],
              [-35.985833332242464, -8.051666658689328],
              [-35.985833332242464, -8.052499992980245],
              [-35.986666667432701, -8.052499992980245],
              [-35.986666666533381, -8.055833325647427],
              [-35.987500000824355, -8.055833326546747],
              [-35.987499999925035, -8.056666658139704],
              [-35.988333331517993, -8.056666659039024],
              [-35.988333331517993, -8.057499991531358],
              [-35.989166667607606, -8.057499994229318],
              [-35.989166665808909, -8.058333325822275],
              [-35.989999998301244, -8.058333325822275],
              [-35.990000000999203, -8.059166659213929],
              [-35.991666665983814, -8.059166659213929],
              [-35.991666666883134, -8.059999993504846],
              [-35.992500000274731, -8.059999993504846],
              [-35.992499999375411, -8.060833325997123],
              [-35.996666665434191, -8.060833325997123],
              [-35.996666667232887, -8.059999993504846],
              [-35.997499999725164, -8.059999992605526],
              [-35.998333331318122, -8.059999992605526],
              [-35.998333334016081, -8.061666659388777],
              [-35.999166664709776, -8.061666658489457],
              [-35.999166667407735, -8.062499993679694],
              [-35.999999999900012, -8.062499992780374],
              [-36.000000000799332, -8.063333326172028],
              [-36.000833332392347, -8.063333326172028],
              [-36.000833332392347, -8.064166658664305],
              [-36.001666666683263, -8.064166658664305],
              [-36.001666666683263, -8.065833326346876],
              [-36.002500000074917, -8.065833325447556],
              [-36.002500000974237, -8.072499992580561],
              [-36.001666666683263, -8.072499992580561],
              [-36.001666665783944, -8.074999992755409],
              [-35.999999999900012, -8.074999992755409],
              [-35.999999999000693, -8.075833326147063],
              [-35.999166665609096, -8.075833324348366],
              [-35.999166666508415, -8.074166658464492],
              [-35.997499998825845, -8.074166658464492],
              [-35.997499998825845, -8.074999992755409],
              [-35.996666665434191, -8.074999992755409],
              [-35.996666666333567, -8.075833326147063],
              [-35.995833333841233, -8.075833326147063],
              [-35.995833332042594, -8.07666665774002],
              [-35.99083333349148, -8.07666665863934],
              [-35.990833331692841, -8.077499992930257],
              [-35.989999998301244, -8.077499992930257],
              [-35.989999998301244, -8.078333324523271],
              [-35.989166666708286, -8.078333324523271],
              [-35.989166665808909, -8.079166658814188],
              [-35.988333331517993, -8.079166658814188],
              [-35.988333330618673, -8.079999994004481],
              [-35.987499999925035, -8.079999993105162],
              [-35.987499999925035, -8.080833325597439],
              [-35.985833334041104, -8.080833325597439],
              [-35.985833331343144, -8.08249999238069],
              [-35.98500000064945, -8.08249999238069],
              [-35.984999997951491, -8.083333325772344],
              [-35.983333332966879, -8.083333325772344],
              [-35.983333332067559, -8.08249999238069],
              [-35.981666665284308, -8.08249999238069],
              [-35.981666667082948, -8.083333325772344],
              [-35.982499998675962, -8.083333324873024],
              [-35.982499998675962, -8.084999992555538],
              [-35.981666665284308, -8.084999992555538],
              [-35.981666665284308, -8.085833325947192],
              [-35.980833333691351, -8.085833325047872],
              [-35.980833332792031, -8.086666658439469],
              [-35.979999999400434, -8.086666658439469],
              [-35.979999999400434, -8.087499992730443],
              [-35.978333332617183, -8.087499992730443],
              [-35.978333331717863, -8.08833332612204],
              [-35.978333331717863, -8.089999993804611],
              [-35.977499998326209, -8.089999992005971],
              [-35.977499999225529, -8.091666658789222],
              [-35.976666665833932, -8.091666658789222],
              [-35.976666667632571, -8.092499993979516],
              [-35.975833331542958, -8.092499993080196],
              [-35.975833333341598, -8.094999992355724],
              [-35.976666665833932, -8.094999992355724],
              [-35.976666664934612, -8.098333325922226],
              [-35.975833333341598, -8.098333325922226],
              [-35.975833331542958, -8.100833325197755],
              [-35.974999999050681, -8.100833326097074],
              [-35.97500000084932, -8.103333325372603],
              [-35.975833332442278, -8.103333325372603],
              [-35.975833332442278, -8.104999993055173],
              [-35.974999999050681, -8.104999993055173],
              [-35.97500000084932, -8.106666659838424],
              [-35.974166665659027, -8.106666658039785],
              [-35.974166665659027, -8.107499993230078],
              [-35.97333333136811, -8.107499993230078],
              [-35.97333333226743, -8.108333325722356],
              [-35.972499998875776, -8.108333325722356],
              [-35.972499998875776, -8.109166659113953],
              [-35.971666666383499, -8.109166659113953],
              [-35.971666665484179, -8.109999992505607],
              [-35.970833332092582, -8.109999993404926],
              [-35.970833332092582, -8.111666658389538],
              [-35.969166665309331, -8.111666658389538],
              [-35.969166665309331, -8.11499999285536],
              [-35.968333332816997, -8.11499999285536],
              [-35.968333332816997, -8.115833325347637],
              [-35.967499999425399, -8.115833324448317],
              [-35.96749999852608, -8.12083332569739],
              [-35.966666667832385, -8.12083332569739],
              [-35.966666666933065, -8.121666659088987],
              [-35.965833332642148, -8.121666658189667],
              [-35.965833331742829, -8.122499992480641],
              [-35.964999999250495, -8.122499992480641],
              [-35.964999999250495, -8.124166660163212],
              [-35.964166665858897, -8.124166658364572],
              [-35.964166667657537, -8.124999992655489],
              [-35.9633333324673, -8.124999992655489],
              [-35.963333331567981, -8.125833326047086],
              [-35.962499999075646, -8.125833326047086],
              [-35.962499999075646, -8.133333326571744],
              [-35.971666665484179, -8.133333325672368],
              [-35.971666665484179, -8.134166659963341],
              [-35.972499999775152, -8.134166659064022],
              [-35.972499998875776, -8.134999992455619],
              [-35.974166665659027, -8.134999992455619],
              [-35.974166665659027, -8.134166658164702],
              [-35.978333332617183, -8.134166658164702],
              [-35.978333333516503, -8.134999992455619],
              [-35.981666667082948, -8.134999992455619],
              [-35.981666667082948, -8.134166659064022],
              [-35.982500000474602, -8.134166659963341],
              [-35.982499999575282, -8.134999992455619],
              [-35.98500000064945, -8.134999992455619],
              [-35.98499999885081, -8.133333325672368],
              [-35.985833331343144, -8.133333325672368],
              [-35.985833332242464, -8.129999993005242],
              [-35.986666666533381, -8.129999993904562],
              [-35.986666667432701, -8.129166659613588],
              [-35.987499999025715, -8.129166658714269],
              [-35.987499997227019, -8.128333326221991],
              [-35.988333331517993, -8.128333324423352],
              [-35.988333332417312, -8.127499992830337],
              [-35.989166666708286, -8.127499992830337],
              [-35.989166667607606, -8.125833326047086],
              [-35.988333331517993, -8.125833326047086],
              [-35.988333332417312, -8.124999992655489],
              [-35.989166667607606, -8.124999992655489],
              [-35.989166667607606, -8.122499992480641],
              [-35.990833331692841, -8.122499992480641],
              [-35.99083333349148, -8.121666659088987],
              [-35.993333331867746, -8.121666660887627],
              [-35.994166665259343, -8.121666659088987],
              [-35.994166667957302, -8.122499992480641],
              [-35.996666667232887, -8.122499992480641],
              [-35.996666665434191, -8.123333325872238],
              [-35.999999999900012, -8.123333325872238],
              [-35.999999999900012, -8.124166657465196],
              [-36.000833331493027, -8.124166658364572],
              [-36.000833331493027, -8.125833326047086],
              [-36.001666666683263, -8.125833326047086],
              [-36.001666665783944, -8.12666665853942],
              [-36.002500000974237, -8.12666665853942],
              [-36.002500000974237, -8.127499992830337],
              [-36.003333332567195, -8.127499992830337],
              [-36.003333333466514, -8.128333326221991],
              [-36.004999999350446, -8.128333326221991],
              [-36.004999998451126, -8.129166657814949],
              [-36.006666665234377, -8.129166658714269],
              [-36.006666667932336, -8.13083332549752],
              [-36.007499999525294, -8.130833326396839],
              [-36.007499998625974, -8.131666658889174],
              [-36.009166667207865, -8.131666658889174],
              [-36.009166665409225, -8.13249999318009],
              [-36.009999999700199, -8.13249999318009],
              [-36.009999998800879, -8.133333325672368],
              [-36.010833331293156, -8.133333325672368],
              [-36.010833333991116, -8.134166659064022],
              [-36.01166666558413, -8.134166659064022],
              [-36.01166666558413, -8.134999992455619],
              [-36.012499998975727, -8.134999992455619],
              [-36.012499998975727, -8.135833326746592],
              [-36.014166665758978, -8.135833324947953],
              [-36.015000000949215, -8.135833324947953],
              [-36.014999998251255, -8.134999993354938],
              [-36.016666667732466, -8.134999992455619],
              [-36.016666665933826, -8.135833325847273],
              [-36.018333331817757, -8.135833325847273],
              [-36.018333333616397, -8.13666665923887],
              [-36.019166667008051, -8.13666665923887],
              [-36.019166667907371, -8.139166659413775],
              [-36.019999999500328, -8.139166658514455],
              [-36.019999999500328, -8.139999993704691],
              [-36.020833332891982, -8.139999992805372],
              [-36.020833333791302, -8.140833326197026],
              [-36.02333333396615, -8.140833326197026],
              [-36.02333333306683, -8.139999992805372],
              [-36.024166666458427, -8.139999992805372],
              [-36.024166666458427, -8.140833326197026],
              [-36.024999999850081, -8.140833326197026],
              [-36.025000000749401, -8.14249999298022],
              [-36.025833332342359, -8.14249999298022],
              [-36.025833332342359, -8.143333325472554],
              [-36.026666665734012, -8.143333325472554],
              [-36.026666665734012, -8.144999993155125],
              [-36.027500000924249, -8.144999993155125],
              [-36.02749999822629, -8.145833325647402],
              [-36.026666665734012, -8.145833326546722],
              [-36.026666665734012, -8.146666659039056],
              [-36.025833332342359, -8.146666658139736],
              [-36.025833331443039, -8.147499992430653],
              [-36.024999999850081, -8.147499992430653],
              [-36.02333333306683, -8.147499992430653],
              [-36.02333333306683, -8.148333325822307],
              [-36.022499998775857, -8.148333325822307],
              [-36.022500000574496, -8.147499992430653],
              [-36.021666668082219, -8.147499992430653],
              [-36.021666668082219, -8.146666659039056],
              [-36.019166665209411, -8.146666659039056],
              [-36.019166667008051, -8.147499992430653],
              [-36.018333333616397, -8.147499992430653],
              [-36.018333333616397, -8.148333325822307],
              [-36.01749999932548, -8.148333325822307],
              [-36.01749999932548, -8.149166659213904],
              [-36.018333330918438, -8.149166659213904],
              [-36.018333331817757, -8.149999993504878],
              [-36.019166667008051, -8.149999992605501],
              [-36.019166665209411, -8.150833325997155],
              [-36.019999999500328, -8.150833325997155],
              [-36.019999998601008, -8.154999992955254],
              [-36.021666668082219, -8.154999992955254],
              [-36.021666665384259, -8.155833325447588],
              [-36.022499998775857, -8.155833325447588],
              [-36.022499998775857, -8.156666658839185],
              [-36.024166666458427, -8.156666658839185],
              [-36.024166666458427, -8.157499993130159],
              [-36.024999998950761, -8.157499993130159],
              [-36.024999999850081, -8.158333325622436],
              [-36.026666664834693, -8.158333325622436],
              [-36.026666664834693, -8.159166658114714],
              [-36.02749999822629, -8.159166659913353],
              [-36.02749999822629, -8.161666659188938],
              [-36.028333332517263, -8.161666659188938],
              [-36.028333332517263, -8.162499992580535],
              [-36.02916666680818, -8.162499992580535],
              [-36.029166665908861, -8.163333325972189],
              [-36.029999998401138, -8.163333325972189],
              [-36.029999998401138, -8.164166658464467],
              [-36.032500000374682, -8.164166659363786],
              [-36.032500000374682, -8.16499999275544],
              [-36.03333333286696, -8.16499999365476],
              [-36.03333333286696, -8.165833326147037],
              [-36.034166665359294, -8.165833326147037],
              [-36.034166666258614, -8.166666658639315],
              [-36.036666666433462, -8.166666658639315],
              [-36.036666667332781, -8.167499992930288],
              [-36.03916666570899, -8.167499992930288],
              [-36.039166667507629, -8.174166659163916],
              [-36.040833331592921, -8.174166659163916],
              [-36.040833332492241, -8.17499999255557],
              [-36.041666667682534, -8.17499999255557],
              [-36.041666666783215, -8.175833325947167],
              [-36.042499998376172, -8.175833325947167],
              [-36.042499999275492, -8.176666658439501],
              [-36.043333331767826, -8.176666658439501],
              [-36.043333332667146, -8.177499992730418],
              [-36.049999998900773, -8.177499992730418],
              [-36.049999998900773, -8.178333325222752],
              [-36.050833332292427, -8.178333326122072],
              [-36.050833331393108, -8.177499992730418],
              [-36.052499999974998, -8.177499991831098],
              [-36.052499999974998, -8.179166658614349],
              [-36.053333331567956, -8.179166658614349],
              [-36.053333331567956, -8.179999992905323],
              [-36.054166665858872, -8.179999992905323],
              [-36.054166665858872, -8.1808333253976],
              [-36.058333332817028, -8.1808333253976],
              [-36.058333332817028, -8.181666658789197],
              [-36.059166665309306, -8.181666658789197],
              [-36.059166665309306, -8.182499993080171],
              [-36.060833332991876, -8.182499993080171],
              [-36.060833332092557, -8.183333325572448],
              [-36.062499998875808, -8.183333325572448],
              [-36.062500000674447, -8.185833326646673],
              [-36.061666665484154, -8.185833324848033],
              [-36.061666665484154, -8.18666665913895],
              [-36.060833332991876, -8.18666665913895],
              [-36.060833332991876, -8.187499992530604],
              [-36.060000000499599, -8.187499993429924],
              [-36.05999999780164, -8.188333325922201],
              [-36.059166668007265, -8.188333325922201],
              [-36.059166666208625, -8.189166658414536],
              [-36.058333332817028, -8.189166660213175],
              [-36.058333332817028, -8.190833326097049],
              [-36.057500000324694, -8.190833326097049],
              [-36.057499999425374, -8.194999993055205],
              [-36.056666667832417, -8.194999993055205],
              [-36.056666667832417, -8.195833325547483],
              [-36.057499998526055, -8.195833325547483],
              [-36.057499999425374, -8.196666659838456],
              [-36.058333331917709, -8.196666658939137],
              [-36.058333332817028, -8.197499993230053],
              [-36.064999999050656, -8.197499993230053],
              [-36.065000000849295, -8.196666659838456],
              [-36.066666667632546, -8.196666658939137],
              [-36.066666666733227, -8.197499994129373],
              [-36.067499998326241, -8.197499991431414],
              [-36.0675000010242, -8.198333325722331],
              [-36.069166666908131, -8.198333325722331],
              [-36.069166666908131, -8.199166659113985],
              [-36.071666667982299, -8.199166660013304],
              [-36.07166666528434, -8.198333325722331],
              [-36.072499998675937, -8.198333325722331],
              [-36.072499998675937, -8.197499993230053],
              [-36.073333332966911, -8.197499993230053],
              [-36.073333332067591, -8.196666658939137],
              [-36.075833332242439, -8.196666658939137],
              [-36.075833332242439, -8.197499993230053],
              [-36.07749999992501, -8.197499993230053],
              [-36.07749999902569, -8.198333325722331],
              [-36.079166667607581, -8.198333326621707],
              [-36.079166666708261, -8.199166659113985],
              [-36.080000000099858, -8.199166659113985],
              [-36.079999998301219, -8.199999992505582],
              [-36.080833333491512, -8.199999993404901],
              [-36.080833331692872, -8.200833325897236],
              [-36.084166667058014, -8.200833325897236],
              [-36.084166664360055, -8.201666658389513],
              [-36.085000000449611, -8.201666658389513],
              [-36.084999998650972, -8.203333326072084],
              [-36.085833330243929, -8.203333326072084],
              [-36.085833332042569, -8.204166658564418],
              [-36.086666665434223, -8.204166658564418],
              [-36.086666665434223, -8.203333326072084],
              [-36.08749999882582, -8.203333326971403],
              [-36.087500000624459, -8.204166658564418],
              [-36.088333330418834, -8.204166659463738],
              [-36.088333331318154, -8.205833324448292],
              [-36.089166665609071, -8.205833324448292],
              [-36.08916666740771, -8.206666658739266],
              [-36.089999998101405, -8.206666657839946],
              [-36.089999999900044, -8.207499993030183],
              [-36.090833332392322, -8.207499993030183],
              [-36.090833333291641, -8.208333325522517],
              [-36.092499998276253, -8.208333326421837],
              [-36.092500000074892, -8.209166658914114],
              [-36.093333331667907, -8.209166658914114],
              [-36.093333331667907, -8.209999993205088],
              [-36.094166665958824, -8.209999993205088],
              [-36.094166667757463, -8.210833325697365],
              [-36.09500000024974, -8.210833326596685],
              [-36.094999998451101, -8.209999993205088],
              [-36.096666665234352, -8.209999993205088],
              [-36.096666667032991, -8.209166658914114],
              [-36.097499999525326, -8.209166658914114],
              [-36.097499999525326, -8.211666659089019],
              [-36.098333332017603, -8.211666659089019],
              [-36.098333332916923, -8.212499992480616],
              [-36.100833333991147, -8.212499993379936],
              [-36.100833331293188, -8.21333332497295],
              [-36.101666665584105, -8.21333332587227],
              [-36.101666666483425, -8.214166658364547],
              [-36.103333333266676, -8.214166658364547],
              [-36.103333332367356, -8.214999992655464],
              [-36.104166665758953, -8.214999992655464],
              [-36.104166666658273, -8.215833326047118],
              [-36.105000000049927, -8.215833326946438],
              [-36.105000000049927, -8.214999992655464],
              [-36.105833333441524, -8.214999992655464],
              [-36.105833332542204, -8.215833326047118],
              [-36.106666667732497, -8.215833326047118],
              [-36.106666665933858, -8.217499992830369],
              [-36.107499998426135, -8.217499992830369],
              [-36.107500000224775, -8.218333327121286],
              [-36.109999998600983, -8.218333326221966],
              [-36.109999998600983, -8.217499993729689],
              [-36.110833331992637, -8.217499992830369],
              [-36.110833333791277, -8.218333327121286],
              [-36.118333331617919, -8.218333327121286],
              [-36.118333333416558, -8.219166657814981],
              [-36.11999999840117, -8.2191666587143],
              [-36.119999999300489, -8.218333326221966],
              [-36.12166666608374, -8.218333326221966],
              [-36.12166666698306, -8.219999993005217],
              [-36.122500000374657, -8.219999993005217],
              [-36.122499998576018, -8.222499994079442],
              [-36.12166666608374, -8.222499993180122],
              [-36.12166666788238, -8.221666658889148],
              [-36.120000001099129, -8.221666658889148],
              [-36.119999999300489, -8.222499993180122],
              [-36.116666666633307, -8.222499993180122],
              [-36.116666665733987, -8.221666658889148],
              [-36.115000000749376, -8.221666658889148],
              [-36.114999998950736, -8.223333325672399],
              [-36.111666666283554, -8.223333325672399],
              [-36.111666666283554, -8.227499992630499],
              [-36.110833333791277, -8.227499992630499],
              [-36.110833333791277, -8.228333326022153],
              [-36.107499999325455, -8.228333326022153],
              [-36.107499999325455, -8.227499991731179],
              [-36.104999997351968, -8.227499992630499],
              [-36.105000000049927, -8.226666659238902],
              [-36.103333331468036, -8.226666660138221],
              [-36.103333333266676, -8.225833325847248],
              [-36.101666665584105, -8.225833324947928],
              [-36.101666665584105, -8.22499999245565],
              [-36.100833333091828, -8.22499999245565],
              [-36.100833331293188, -8.223333325672399],
              [-36.099166665409257, -8.223333325672399],
              [-36.099166666308577, -8.222499993180122],
              [-36.098333333816242, -8.222499993180122],
              [-36.098333332017603, -8.221666658889148],
              [-36.095833332742075, -8.221666658889148],
              [-36.095833334540714, -8.222499993180122],
              [-36.09500000024974, -8.222499994079442],
              [-36.094999999350421, -8.224166659063997],
              [-36.093333332567227, -8.224166659063997],
              [-36.093333334365866, -8.225833325847248],
              [-36.091666665783976, -8.225833325847248],
              [-36.091666667582615, -8.226666659238902],
              [-36.08916666740771, -8.226666659238902],
              [-36.089166665609071, -8.225833325847248],
              [-36.086666667232862, -8.225833326746567],
              [-36.086666667232862, -8.224166659063997],
              [-36.084999999550291, -8.224166659063997],
              [-36.084999998650972, -8.22499999245565],
              [-36.084166667957334, -8.224999991556331],
              [-36.084166667058014, -8.225833325847248],
              [-36.08333333366636, -8.225833327645887],
              [-36.081666667782429, -8.225833325847248],
              [-36.08166666508447, -8.22499999245565],
              [-36.080833331692872, -8.22499999245565],
              [-36.080833331692872, -8.223333325672399],
              [-36.080000000099858, -8.223333326571719],
              [-36.079999998301219, -8.220833325497551],
              [-36.078333333316664, -8.220833325497551],
              [-36.078333333316664, -8.219999993005217],
              [-36.076666666533413, -8.219999993904537],
              [-36.076666666533413, -8.220833325497551],
              [-36.075833331343119, -8.220833325497551],
              [-36.075833334041079, -8.221666658889148],
              [-36.075000000649482, -8.221666659788468],
              [-36.075000000649482, -8.223333325672399],
              [-36.070833330993366, -8.223333325672399],
              [-36.070833331892686, -8.222499994079442],
              [-36.070000000299729, -8.222499991381426],
              [-36.070000000299729, -8.223333325672399],
              [-36.06166666638353, -8.223333325672399],
              [-36.061666665484154, -8.222499993180122],
              [-36.059166665309306, -8.222499994079442],
              [-36.058333332817028, -8.222499991381426],
              [-36.058333332817028, -8.219999993005217],
              [-36.057499998526055, -8.219999993005217],
              [-36.057500000324694, -8.2191666587143],
              [-36.056666666033777, -8.2191666587143],
              [-36.056666666033777, -8.218333326221966],
              [-36.053333331567956, -8.218333326221966],
              [-36.053333332467275, -8.217499992830369],
              [-36.051666665684024, -8.217499992830369],
              [-36.051666667482664, -8.218333326221966],
              [-36.050000000699413, -8.218333324423327],
              [-36.049999998001454, -8.217499992830369],
              [-36.049166667307816, -8.217499991931049],
              [-36.049166666408496, -8.218333326221966],
              [-36.042500001973451, -8.218333326221966],
              [-36.042499998376172, -8.217499992830369],
              [-36.041666667682534, -8.217499992830369],
              [-36.041666666783215, -8.216666658539395],
              [-36.04, -8.216666658539395],
              [-36.040000000899283, -8.215833325147798],
              [-36.03916666570899, -8.215833325147798],
              [-36.03916666660831, -8.214999992655464],
              [-36.038333332317393, -8.214999992655464],
              [-36.038333331418073, -8.212499993379936],
              [-36.037499998925739, -8.212499993379936],
              [-36.037499999825116, -8.211666659089019],
              [-36.036666665534142, -8.211666659089019],
              [-36.036666665534142, -8.210833326596685],
              [-36.03500000054953, -8.210833325697365],
              [-36.034999999650211, -8.209999993205088],
              [-36.03333333196764, -8.209999993205088],
              [-36.03333333196764, -8.205833325347612],
              [-36.031666666083709, -8.205833325347612],
              [-36.031666666983028, -8.204999992855335],
              [-36.02916666680818, -8.204999992855335],
              [-36.02916666680818, -8.205833324448292],
              [-36.023333331268191, -8.205833325347612],
              [-36.02333333306683, -8.204999992855335],
              [-36.021666668082219, -8.204999992855335],
              [-36.021666666283579, -8.204166658564418],
              [-36.019166667008051, -8.204166658564418],
              [-36.019166667907371, -8.203333326072084],
              [-36.013333333266701, -8.203333326971403],
              [-36.013333333266701, -8.204166658564418],
              [-36.014166665758978, -8.204166658564418],
              [-36.014166665758978, -8.204999992855335],
              [-36.013333331468004, -8.204999991956015],
              [-36.013333333266701, -8.207499993030183],
              [-36.012499999875047, -8.207499993030183],
              [-36.012499998975727, -8.208333325522517],
              [-36.009166665409225, -8.208333325522517],
              [-36.009166664509905, -8.209166658914114],
              [-36.008333333816267, -8.209166658914114],
              [-36.008333330218989, -8.209999993205088],
              [-36.003333332567195, -8.209999994104408],
              [-36.003333331667875, -8.207499993030183],
              [-36.004166665958849, -8.207499993030183],
              [-36.004166666858168, -8.206666659638586],
              [-36.001666665783944, -8.206666658739266],
              [-36.001666667582583, -8.205833325347612],
              [-35.999166664709776, -8.205833325347612],
              [-35.999166665609096, -8.206666657839946],
              [-35.994999998650997, -8.206666658739266],
              [-35.994999999550316, -8.209166658914114],
              [-35.994166664360023, -8.209166658914114],
              [-35.994166667057982, -8.209999993205088],
              [-35.993333330968426, -8.209999993205088],
              [-35.993333333666385, -8.210833325697365],
              [-35.992499999375411, -8.210833325697365],
              [-35.992499998476092, -8.211666659089019],
              [-35.9908333343908, -8.211666659089019],
              [-35.99083333259216, -8.212499992480616],
              [-35.988333332417312, -8.212499992480616],
              [-35.988333333316632, -8.21333332587227],
              [-35.982499998675962, -8.21333332587227],
              [-35.982499998675962, -8.212499992480616],
              [-35.979999998501114, -8.212499992480616],
              [-35.979999999400434, -8.210833325697365],
              [-35.977500001024168, -8.210833325697365],
              [-35.977499999225529, -8.209999993205088],
              [-35.976666666733252, -8.209999993205088],
              [-35.976666665833932, -8.208333324623197],
              [-35.975833333341598, -8.208333325522517],
              [-35.975833330643638, -8.207499993030183],
              [-35.97500000084932, -8.207499993030183],
              [-35.974999999050681, -8.204999992855335],
              [-35.974166665659027, -8.204999992855335],
              [-35.974166666558347, -8.204166658564418],
              [-35.97333333136811, -8.204166658564418],
              [-35.97333333226743, -8.203333326072084],
              [-35.971666665484179, -8.203333325172764],
              [-35.971666666383499, -8.201666658389513],
              [-35.970833332991901, -8.201666660188152],
              [-35.970833332092582, -8.197499993230053],
              [-35.969999999600248, -8.197499993230053],
              [-35.969999999600248, -8.194166658764232],
              [-35.968333333716316, -8.194166659663551],
              [-35.968333333716316, -8.193333325372635],
              [-35.967499999425399, -8.193333325372635],
              [-35.966666667832385, -8.193333325372635],
              [-35.966666666033746, -8.1924999928803],
              [-35.964166667657537, -8.1924999928803],
              [-35.964166667657537, -8.193333325372635],
              [-35.959166664609825, -8.193333325372635],
              [-35.959166665509144, -8.194166658764232],
              [-35.957499999625213, -8.194166658764232],
              [-35.957499999625213, -8.194999993954525],
              [-35.956666668931575, -8.194999993954525],
              [-35.956666664434977, -8.195833324648163],
              [-35.955833332842019, -8.195833325547483],
              [-35.955833331942699, -8.196666658939137],
              [-35.954999999450365, -8.196666658939137],
              [-35.955000001249005, -8.197499993230053],
              [-35.954166666958088, -8.197499993230053],
              [-35.954166667857407, -8.198333325722331],
              [-35.953333333566434, -8.198333325722331],
              [-35.953333331767794, -8.197499993230053],
              [-35.951666666783183, -8.197499993230053],
              [-35.951666665883863, -8.196666658939137],
              [-35.950833332492266, -8.196666658939137],
              [-35.950833333391586, -8.195833325547483],
              [-35.949166667507654, -8.195833326446802],
              [-35.949166665709015, -8.194999993055205],
              [-35.948333330518722, -8.194999992155886],
              [-35.948333332317361, -8.194166658764232],
              [-35.946666666433487, -8.194166658764232],
              [-35.946666666433487, -8.193333324473315],
              [-35.944166665359262, -8.193333325372635],
              [-35.944166668057221, -8.188333325922201],
              [-35.943333334665624, -8.188333325922201],
              [-35.943333331967665, -8.187499992530604],
              [-35.940833334490776, -8.187499992530604],
              [-35.94083333269208, -8.18666665913895],
              [-35.938333333416551, -8.18666665913895],
              [-35.938333331617912, -8.185833325747353],
              [-35.937500000024954, -8.185833325747353],
              [-35.937500000024954, -8.184166658964102],
              [-35.9366666666333, -8.184166658964102],
              [-35.936666665733981, -8.183333324673129],
              [-35.935833331443064, -8.183333324673129],
              [-35.935833332342384, -8.181666658789197],
              [-35.93666666753262, -8.181666658789197],
              [-35.9366666666333, -8.179166658614349],
              [-35.935833332342384, -8.179166658614349],
              [-35.935833331443064, -8.178333326122072],
              [-35.934999999850049, -8.178333326122072],
              [-35.93499999895073, -8.177499992730418],
              [-35.934166665559133, -8.177499992730418],
              [-35.934166666458452, -8.174166659163916],
              [-35.932499999675201, -8.174166659163916],
              [-35.932500000574521, -8.173333325772319],
              [-35.931666666283604, -8.173333326671639],
              [-35.931666666283604, -8.172499992380722],
              [-35.930000000399673, -8.172499992380722],
              [-35.929999998601033, -8.171666658989068],
              [-35.92916666520938, -8.171666658989068],
              [-35.929166667008019, -8.170833326496791],
              [-35.927499999325448, -8.170833325597471],
              [-35.927499999325448, -8.169999993105137],
              [-35.92500000094924, -8.169999993105137],
              [-35.92499999825128, -8.170833325597471],
              [-35.924166667557643, -8.170833325597471],
              [-35.924166667557643, -8.171666658989068],
              [-35.923333332367349, -8.171666658989068],
              [-35.923333333266669, -8.174166657365276],
              [-35.922500000774392, -8.174166659163916],
              [-35.922500000774392, -8.17499999255557],
              [-35.921666665584098, -8.17499999255557],
              [-35.921666665584098, -8.182499993080171],
              [-35.919999998800847, -8.182499993080171],
              [-35.919999998800847, -8.183333325572448],
              [-35.91916666630857, -8.183333325572448],
              [-35.91916666630857, -8.187499992530604],
              [-35.916666665234345, -8.187499992530604],
              [-35.916666667033041, -8.183333324673129],
              [-35.914999998451151, -8.183333325572448],
              [-35.914999998451151, -8.184166658964102],
              [-35.914166666858137, -8.184166658964102],
              [-35.914166667757456, -8.184999992355699],
              [-35.91333333256722, -8.184999992355699],
              [-35.913333333466539, -8.188333325922201],
              [-35.909166665609064, -8.188333325022882],
              [-35.909166665609064, -8.189166658414536],
              [-35.90749999882587, -8.189166658414536],
              [-35.90749999882587, -8.188333325922201],
              [-35.906666665434216, -8.188333326821521],
              [-35.906666666333535, -8.187499992530604],
              [-35.905833332042619, -8.187499998825842],
              [-35.899166666708254, -8.187499992530604],
              [-35.899166667607574, -8.188333326821521],
              [-35.897499999025683, -8.188333325922201],
              [-35.897499999925003, -8.189166658414536],
              [-35.894166665459181, -8.189166658414536],
              [-35.894166666358501, -8.188333325022882],
              [-35.893333332966904, -8.188333325922201],
              [-35.893333332067584, -8.189999992705452],
              [-35.89249999957525, -8.189999992705452],
              [-35.892500000474627, -8.19083332519773],
              [-35.891666665284333, -8.190833326097049],
              [-35.891666667082973, -8.1924999928803],
              [-35.890833331892736, -8.1924999928803],
              [-35.890833332792056, -8.193333325372635],
              [-35.891666665284333, -8.193333325372635],
              [-35.891666667982292, -8.195833325547483],
              [-35.890833331892736, -8.195833325547483],
              [-35.890833332792056, -8.196666658939137],
              [-35.889999998501082, -8.196666658939137],
              [-35.889999999400402, -8.198333326621707],
              [-35.889166666908125, -8.198333325722331],
              [-35.889166666908125, -8.199166658214665],
              [-35.888333331717831, -8.199166659113985],
              [-35.888333330818512, -8.199999992505582],
              [-35.885833331542983, -8.199999991606262],
              [-35.885833331542983, -8.200833325897236],
              [-35.884999999949969, -8.200833325897236],
              [-35.883333332267455, -8.200833326796555],
              [-35.883333331368078, -8.199166659113985],
              [-35.881666666383524, -8.199166660013304],
              [-35.881666664584884, -8.199999992505582],
              [-35.879999998700953, -8.199999992505582],
              [-35.879999998700953, -8.199166659113985],
              [-35.878333331917702, -8.199166659113985],
              [-35.878333331917702, -8.194999993055205],
              [-35.875833331742797, -8.194999993055205],
              [-35.875833332642117, -8.195833325547483],
              [-35.875000001948479, -8.195833325547483],
              [-35.8749999983512, -8.196666658039817],
              [-35.871666665684018, -8.196666658939137],
              [-35.871666666583337, -8.197499993230053],
              [-35.869999999800086, -8.197499993230053],
              [-35.869999999800086, -8.198333325722331],
              [-35.867500000524558, -8.198333325722331],
              [-35.867499998725918, -8.199166659113985],
              [-35.866666666233641, -8.199166659113985],
              [-35.866666666233641, -8.200833325897236],
              [-35.865833332841987, -8.200833325897236],
              [-35.865833332841987, -8.201666658389513],
              [-35.86499999855107, -8.201666658389513],
              [-35.86499999855107, -8.202499991781167],
              [-35.864166666958056, -8.202499992680487],
              [-35.864166666958056, -8.204166658564418],
              [-35.86499999855107, -8.204166658564418],
              [-35.864999997651751, -8.204999992855335],
              [-35.865833331942667, -8.204999992855335],
              [-35.865833331942667, -8.208333325522517],
              [-35.86499999945039, -8.208333325522517],
              [-35.86499999855107, -8.209999993205088],
              [-35.863333332667139, -8.209999993205088],
              [-35.863333333566459, -8.211666659988339],
              [-35.862499999275485, -8.211666659089019],
              [-35.862500001074125, -8.214999992655464],
              [-35.861666666783208, -8.214999992655464],
              [-35.861666667682528, -8.216666658539395],
              [-35.860833332492234, -8.216666658539395],
              [-35.860833333391554, -8.217499992830369],
              [-35.86, -8.217499992830369],
              [-35.859999999100637, -8.218333326221966],
              [-35.858333331418066, -8.218333326221966],
              [-35.858333333216706, -8.220833325497551],
              [-35.857499999825109, -8.220833325497551],
              [-35.857499999825109, -8.222499993180122],
              [-35.856666665534135, -8.222499993180122],
              [-35.856666666433455, -8.223333325672399],
              [-35.854999998750884, -8.223333325672399],
              [-35.854999998750884, -8.22499999245565],
              [-35.855833333041858, -8.22499999245565],
              [-35.855833333041858, -8.228333326921472],
              [-35.854166665359287, -8.228333326022153],
              [-35.854166666258607, -8.231666657789958],
              [-35.852499999475356, -8.231666658689278],
              [-35.852499998576036, -8.230833326197001],
              [-35.850833331792785, -8.230833326197001],
              [-35.850833332692105, -8.229999992805404],
              [-35.849166667707493, -8.229999992805404],
              [-35.849166666808173, -8.230833324398361],
              [-35.844999998950755, -8.230833326197001],
              [-35.844999999850074, -8.231666658689278],
              [-35.842499999675226, -8.231666658689278],
              [-35.842499998775907, -8.230833326197001],
              [-35.840833334690615, -8.230833326197001],
              [-35.840833332891975, -8.229999992805404],
              [-35.839166667008044, -8.229999992805404],
              [-35.839166667008044, -8.228333325122833],
              [-35.83833333271707, -8.228333326022153],
              [-35.838333331817751, -8.227499992630499],
              [-35.837499998426154, -8.227499992630499],
              [-35.837499998426154, -8.228333326022153],
              [-35.836666667732459, -8.228333326921472],
              [-35.836666665933819, -8.229999992805404],
              [-35.834166666658291, -8.229999992805404],
              [-35.834166667557611, -8.230833324398361],
              [-35.83249999897572, -8.230833326197001],
              [-35.83249999897572, -8.231666659588598],
              [-35.831666664684803, -8.231666658689278],
              [-35.831666667382763, -8.230833326197001],
              [-35.826666667932329, -8.230833326197001],
              [-35.82666666703301, -8.229999992805404],
              [-35.824999998451119, -8.229999991906027],
              [-35.825000000249759, -8.22916665851443],
              [-35.822500000074911, -8.22916665851443],
              [-35.822499998276271, -8.228333326022153],
              [-35.821666665783937, -8.228333326022153],
              [-35.821666667582576, -8.227499992630499],
              [-35.82083333239234, -8.227499992630499],
              [-35.82083333149302, -8.225833324947928],
              [-35.822500000074911, -8.225833325847248],
              [-35.82250000097423, -8.22499999245565],
              [-35.823333331667868, -8.22499999245565],
              [-35.823333332567188, -8.221666658889148],
              [-35.822499998276271, -8.221666658889148],
              [-35.822499998276271, -8.220833325497551],
              [-35.822500000074911, -8.219999993005217],
              [-35.82083333239234, -8.219999993005217],
              [-35.82083333239234, -8.218333326221966],
              [-35.819999999900006, -8.218333326221966],
              [-35.819999999900006, -8.217499992830369],
              [-35.819166665609089, -8.217499992830369],
              [-35.819166666508409, -8.216666658539395],
              [-35.818333332217492, -8.216666658539395],
              [-35.818333334016131, -8.215833326047118],
              [-35.81666666633356, -8.215833326946438],
              [-35.81666666633356, -8.214999992655464],
              [-35.815833332042587, -8.214999992655464],
              [-35.815833332042587, -8.215833326047118],
              [-35.814166665259336, -8.215833326047118],
              [-35.814166665259336, -8.214999992655464],
              [-35.812500000274724, -8.214999992655464],
              [-35.812499999375405, -8.214166658364547],
              [-35.811666665983807, -8.214166658364547],
              [-35.811666665983807, -8.21333332587227],
              [-35.807499999925028, -8.21333332587227],
              [-35.807499999925028, -8.212499991581296],
              [-35.806666666533374, -8.212499992480616],
              [-35.806666664734735, -8.211666659089019],
              [-35.805833332242457, -8.211666659089019],
              [-35.805833332242457, -8.207499993030183],
              [-35.803333332067552, -8.207499993030183],
              [-35.803333333866249, -8.206666659638586],
              [-35.802499999575275, -8.206666658739266],
              [-35.802500000474595, -8.205833325347612],
              [-35.798333330818537, -8.205833325347612],
              [-35.798333334415815, -8.204999992855335],
              [-35.795833331542951, -8.204999992855335],
              [-35.795833331542951, -8.204166657665098],
              [-35.794166666558397, -8.204166658564418],
              [-35.794166665659077, -8.203333326072084],
              [-35.792500000674465, -8.203333326072084],
              [-35.791666665484172, -8.203333325172764],
              [-35.791666667282811, -8.202499992680487],
              [-35.790833332991895, -8.202499991781167],
              [-35.790833332092575, -8.201666661087472],
              [-35.789166666208644, -8.201666658389513],
              [-35.789166666208644, -8.197499993230053],
              [-35.788333333716309, -8.197499993230053],
              [-35.78833333191767, -8.196666658939137],
              [-35.787499998526073, -8.196666658939137],
              [-35.787499998526073, -8.195833325547483],
              [-35.786666667832435, -8.195833325547483],
              [-35.786666667832435, -8.194166659663551],
              [-35.785833332642142, -8.194166658764232],
              [-35.785833332642142, -8.193333327171274],
              [-35.785000001049184, -8.193333325372635],
              [-35.784999998351225, -8.1924999928803],
              [-35.78416666675821, -8.1924999928803],
              [-35.78416666765753, -8.191666658589384],
              [-35.783333332467294, -8.191666658589384],
              [-35.783333331567974, -8.189999992705452],
              [-35.78249999907564, -8.189999992705452],
              [-35.782499999974959, -8.189166658414536],
              [-35.781666667482682, -8.189166658414536],
              [-35.779999999800111, -8.189166657515159],
              [-35.779999998900792, -8.188333325922201],
              [-35.779166665509138, -8.188333325022882],
              [-35.779166665509138, -8.187499992530604],
              [-35.777499999625263, -8.187499992530604],
              [-35.777499998725943, -8.188333325922201],
              [-35.775833331942692, -8.188333325022882],
              [-35.775833331942692, -8.189166658414536],
              [-35.774999998551039, -8.189166658414536],
              [-35.774999998551039, -8.190833326097049],
              [-35.774166666058761, -8.190833326097049],
              [-35.774166666058761, -8.191666658589384],
              [-35.773333333566427, -8.191666658589384],
              [-35.773333333566427, -8.1924999928803],
              [-35.77249999927551, -8.1924999928803],
              [-35.77249999837619, -8.193333325372635],
              [-35.771666665883856, -8.193333325372635],
              [-35.771666666783176, -8.194999993055205],
              [-35.770833333391579, -8.194999993055205],
              [-35.770833331592939, -8.196666658939137],
              [-35.769166666608328, -8.196666658939137],
              [-35.769166665709008, -8.197499993230053],
              [-35.768333331418091, -8.197499993230053],
              [-35.768333332317411, -8.198333325722331],
              [-35.767499999825077, -8.198333326621707],
              [-35.767499999825077, -8.197499993230053],
              [-35.76666666643348, -8.197499993230053],
              [-35.76666666553416, -8.196666658939137],
              [-35.765833333041826, -8.196666659838456],
              [-35.765833331243186, -8.195833326446802],
              [-35.759999998401156, -8.195833324648163],
              [-35.760000001099115, -8.196666658939137],
              [-35.758333331617905, -8.196666658939137],
              [-35.758333331617905, -8.198333325722331],
              [-35.757499998226308, -8.198333325722331],
              [-35.757499998226308, -8.199166659113985],
              [-35.756666666633294, -8.199166660013304],
              [-35.756666666633294, -8.199999992505582],
              [-35.755833331443057, -8.199999992505582],
              [-35.755833331443057, -8.200833325897236],
              [-35.754999999850043, -8.200833326796555],
              [-35.754999999850043, -8.201666658389513],
              [-35.754166667357765, -8.201666658389513],
              [-35.754166665559126, -8.202499992680487],
              [-35.753333333966168, -8.202499992680487],
              [-35.753333331268152, -8.203333326072084],
              [-35.752500001473834, -8.203333326072084],
              [-35.752499999675194, -8.204166658564418],
              [-35.749999998601027, -8.204166658564418],
              [-35.750000000399666, -8.204999992855335],
              [-35.748333332717095, -8.204999992855335],
              [-35.748333333616415, -8.204166658564418],
              [-35.746666666833164, -8.204166658564418],
              [-35.746666665933844, -8.205833324448292],
              [-35.739166665409243, -8.205833325347612],
              [-35.739166666308563, -8.206666658739266],
              [-35.738333332916909, -8.206666658739266],
              [-35.738333332017589, -8.207499993030183],
              [-35.737499997726673, -8.207499993030183],
              [-35.737499998625992, -8.208333325522517],
              [-35.736666667033035, -8.208333325522517],
              [-35.736666667033035, -8.209166658914114],
              [-35.735833332742061, -8.209166658914114],
              [-35.735833331842741, -8.210833325697365],
              [-35.736666667932354, -8.210833326596685],
              [-35.736666665234395, -8.212499992480616],
              [-35.735833332742061, -8.212499992480616],
              [-35.735833331842741, -8.214999991756144],
              [-35.735000000249784, -8.214999992655464],
              [-35.734999999350464, -8.215833326946438],
              [-35.73416666595881, -8.215833326047118],
              [-35.73416666685813, -8.216666658539395],
              [-35.733333331667893, -8.216666658539395],
              [-35.733333333466533, -8.217499992830369],
              [-35.732500000074879, -8.217499992830369],
              [-35.732500000974198, -8.218333326221966],
              [-35.731666665783962, -8.218333326221966],
              [-35.731666666683282, -8.2191666587143],
              [-35.729999999000711, -8.2191666587143],
              [-35.729166666508434, -8.2191666587143],
              [-35.729166667407753, -8.219999993005217],
              [-35.727499998825863, -8.219999993005217],
              [-35.727500000624502, -8.220833326396871],
              [-35.726666665434209, -8.220833324598232],
              [-35.726666666333529, -8.221666657989829],
              [-35.725833332042612, -8.221666658889148],
              [-35.725000000449597, -8.221666658889148],
              [-35.725000000449597, -8.220833325497551],
              [-35.724166665259361, -8.220833325497551],
              [-35.724166665259361, -8.219999993005217],
              [-35.723333331867707, -8.219999993005217],
              [-35.723333331867707, -8.218333326221966],
              [-35.72249999847611, -8.218333326221966],
              [-35.72249999937543, -8.217499992830369],
              [-35.717499999025677, -8.217499992830369],
              [-35.717499999924996, -8.216666658539395],
              [-35.710833332792049, -8.216666658539395],
              [-35.710833333691369, -8.215833326946438],
              [-35.709166666008798, -8.215833326047118],
              [-35.709166666908118, -8.214999991756144],
              [-35.708333333516464, -8.214999992655464],
              [-35.708333331717824, -8.214166657465228],
              [-35.707499998326227, -8.214166658364547],
              [-35.707499997426908, -8.21333332587227],
              [-35.706666668531909, -8.21333332587227],
              [-35.706666665833893, -8.212499992480616],
              [-35.707500001024187, -8.212499993379936],
              [-35.707500001024187, -8.211666659089019],
              [-35.706666666733213, -8.211666659089019],
              [-35.706666665833893, -8.210833325697365],
              [-35.705833333341616, -8.210833325697365],
              [-35.705833331542976, -8.209999993205088],
              [-35.704999999950019, -8.209999994104408],
              [-35.704999999050699, -8.209166658914114],
              [-35.704166666558365, -8.209166658914114],
              [-35.704166666558365, -8.208333325522517],
              [-35.701666667282836, -8.208333325522517],
              [-35.701666667282836, -8.207499993030183],
              [-35.700833332991863, -8.207499993030183],
              [-35.700833332092543, -8.206666658739266],
              [-35.698333331917695, -8.206666658739266],
              [-35.698333332817015, -8.208333324623197],
              [-35.699166666208612, -8.208333326421837],
              [-35.699166665309292, -8.209166658914114],
              [-35.699999998700946, -8.209166658914114],
              [-35.699999999600266, -8.209999991406448],
              [-35.700833330293904, -8.209999993205088],
              [-35.700833332092543, -8.211666659089019],
              [-35.701666665484197, -8.211666659089019],
              [-35.701666665484197, -8.21333332587227],
              [-35.700833332991863, -8.21333332677159],
              [-35.700833332092543, -8.214999992655464],
              [-35.699999998700946, -8.214999992655464],
              [-35.699999998700946, -8.216666658539395],
              [-35.699166666208612, -8.216666657640076],
              [-35.699166665309292, -8.217499992830369],
              [-35.697499999425418, -8.217499992830369],
              [-35.697499998526041, -8.218333326221966],
              [-35.696666667832403, -8.218333324423327],
              [-35.696666666933083, -8.219999993005217],
              [-35.695833331742847, -8.219999993005217],
              [-35.695833331742847, -8.220833325497551],
              [-35.694166667657555, -8.220833325497551],
              [-35.694166665858916, -8.221666658889148],
              [-35.68999999890076, -8.221666658889148],
              [-35.690000000699456, -8.220833325497551],
              [-35.689166666408482, -8.220833325497551],
              [-35.689166666408482, -8.224166659063997],
              [-35.686666665334315, -8.224166659063997],
              [-35.686666666233634, -8.22499999245565],
              [-35.685833331942661, -8.22499999245565],
              [-35.685833331942661, -8.225833326746567],
              [-35.684999999450383, -8.225833325847248],
              [-35.685000000349703, -8.226336758234766],
              [-35.685893850021102, -8.226593587524007],
              [-35.686738596708835, -8.226836309148325],
              [-35.687847378953336, -8.227101288993254],
              [-35.688749380979118, -8.227196780806764],
              [-35.690230984372363, -8.227179562386823],
              [-35.691487129527388, -8.226972282145084],
              [-35.692140798159244, -8.226826130621362],
              [-35.695193480294847, -8.225918618449441],
              [-35.697624802842313, -8.226149313440033],
              [-35.700905717624721, -8.228037656812376],
              [-35.705139206908825, -8.232021541061215],
              [-35.707304866126606, -8.234725334806285],
              [-35.708350129254995, -8.237247956527938],
              [-35.708453281493689, -8.239965473927441],
              [-35.707276562663196, -8.24414147494906],
              [-35.707114660413197, -8.24760582624782],
              [-35.707951079378745, -8.251017613072349],
              [-35.708638184804329, -8.251997997309672],
              [-35.708956621251048, -8.252237382449209],
              [-35.709881093737465, -8.252906785619587],
              [-35.71089083274569, -8.253253442792129],
              [-35.71228195085132, -8.253390271044111],
              [-35.712758421563876, -8.253418408132973],
              [-35.714408690108939, -8.25316008686849],
              [-35.715105218630868, -8.252838722229228],
              [-35.71547061317824, -8.252650611936133],
              [-35.716007739565498, -8.252171936985235],
              [-35.716379512104766, -8.251840622246846],
              [-35.716694607570048, -8.251495086528962],
              [-35.717404937987453, -8.250303918291138],
              [-35.7174813156102, -8.250064969322807],
              [-35.717703422075203, -8.249227527828111],
              [-35.717756598088499, -8.248704533387468],
              [-35.717708454681372, -8.246684556247203],
              [-35.717798280765976, -8.24500997453066],
              [-35.718343974095092, -8.24363644356697],
              [-35.718885971210568, -8.242922309916651],
              [-35.719768739435722, -8.242301190447506],
              [-35.720113240033982, -8.24206729656845],
              [-35.720963434814621, -8.241828015750286],
              [-35.722439317620342, -8.241915216713835],
              [-35.723787153154547, -8.242456813631009],
              [-35.725398403713314, -8.243217047428743],
              [-35.726514185381291, -8.243388547243626],
              [-35.7274778620137, -8.243349458210901],
              [-35.728623589307176, -8.243113232389703],
              [-35.731403244464104, -8.24189709807257],
              [-35.732631694097336, -8.241824681064145],
              [-35.733679868331194, -8.24213266738866],
              [-35.734920212397867, -8.243039629175485],
              [-35.73593733394074, -8.24447882863501],
              [-35.736682107493209, -8.246330246743469],
              [-35.736759583188189, -8.248087057971304],
              [-35.736423500244314, -8.249259431277665],
              [-35.735619153800883, -8.250375193160608],
              [-35.73311271897677, -8.252392835660771],
              [-35.732404349081378, -8.253437522323736],
              [-35.732169775314844, -8.254743945876328],
              [-35.732531211046535, -8.256535206534522],
              [-35.733037035630673, -8.257382860730388],
              [-35.733834053498754, -8.258155319912021],
              [-35.736176790126706, -8.259858572015446],
              [-35.739092036967861, -8.261827696990565],
              [-35.740810353614904, -8.262935708516068],
              [-35.742696736465177, -8.264152092844768],
              [-35.744904948004887, -8.265896335147715],
              [-35.74671517986161, -8.267697788721932],
              [-35.748261944533056, -8.269578610164672],
              [-35.74905011577016, -8.270831572618931],
              [-35.749743064091092, -8.272316640200756],
              [-35.75050577102445, -8.274441743591524],
              [-35.75097427374368, -8.27574711493736],
              [-35.751290082371384, -8.276622717364489],
              [-35.751572696122992, -8.277039015338232],
              [-35.752087369136746, -8.277762831089717],
              [-35.752655412417312, -8.278275020176011],
              [-35.753619823795816, -8.278685829587346],
              [-35.754101986317551, -8.27872823891704],
              [-35.75484662137444, -8.278599756373637],
              [-35.754977924191792, -8.278571661552917],
              [-35.755136462078042, -8.278490542704333],
              [-35.755941008170964, -8.278078882534373],
              [-35.7561383257231, -8.277977922842808],
              [-35.756410576586802, -8.277765205299943],
              [-35.757833075635915, -8.276613762814861],
              [-35.759265437549857, -8.275589756062345],
              [-35.759759034052138, -8.275355767754434],
              [-35.760614885568486, -8.275219827133242],
              [-35.760881124464277, -8.275195623679053],
              [-35.761731417271051, -8.275320113232567],
              [-35.763037680744333, -8.275859782902614],
              [-35.764703190100079, -8.276770580297978],
              [-35.766484791140158, -8.277738399207692],
              [-35.768515472910678, -8.278555355944889],
              [-35.76924578626398, -8.278840412255192],
              [-35.770975472140265, -8.279210896965253],
              [-35.771578097950794, -8.279230334911972],
              [-35.772125642984008, -8.279236345980564],
              [-35.772875946467707, -8.27909285824876],
              [-35.773434744717633, -8.278920303529162],
              [-35.77381726955133, -8.278688972717873],
              [-35.774307669863049, -8.278253315937718],
              [-35.774701584610398, -8.277708475165923],
              [-35.774916055831284, -8.276806411086966],
              [-35.774936456052558, -8.276297034180743],
              [-35.774675374768663, -8.275222963968588],
              [-35.7740377338543, -8.274320043734974],
              [-35.772781878281023, -8.272451440481518],
              [-35.772761014009518, -8.27175594797859],
              [-35.77286908374117, -8.270806578660654],
              [-35.773429403644002, -8.269885234915591],
              [-35.774554754098574, -8.268700534601919],
              [-35.776350907068661, -8.26789823501548],
              [-35.779401458710311, -8.267343246293763],
              [-35.782045216413565, -8.267329492062345],
              [-35.783836198281904, -8.267607235085791],
              [-35.785735867716085, -8.268354831610168],
              [-35.78722037502024, -8.269427057312782],
              [-35.789477326217593, -8.271591517734294],
              [-35.79179185920691, -8.27403681214912],
              [-35.794193487039763, -8.276805468597445],
              [-35.796594876552263, -8.279850915687177],
              [-35.800163266821244, -8.283871339390771],
              [-35.802459142475414, -8.286350844915489],
              [-35.804878429395956, -8.289492606698388],
              [-35.807317106599157, -8.292987737294197],
              [-35.809679768606372, -8.296657810309625],
              [-35.810465767980759, -8.298204704483453],
              [-35.811464953444784, -8.301054551726111],
              [-35.811669384634342, -8.303133420970539],
              [-35.81114924554123, -8.30727708057276],
              [-35.810364383809201, -8.31133451407652],
              [-35.809915098702788, -8.313657148158825],
              [-35.809166668506919, -8.314999992455625],
              [-35.809166667607599, -8.31749999263053],
              [-35.811666665983807, -8.31749999263053],
              [-35.811666667782447, -8.318333326022127],
              [-35.812499998476085, -8.318333326022127],
              [-35.812499998476085, -8.319166658514405],
              [-35.813333333666378, -8.319166659413781],
              [-35.813333332767058, -8.323333325472561],
              [-35.815833332042587, -8.323333325472561],
              [-35.815833332042587, -8.324166658864158],
              [-35.816666665434241, -8.324166657964838],
              [-35.81666666633356, -8.324999993155132],
              [-35.82083333149302, -8.324999993155132],
              [-35.82083333239234, -8.325833325647409],
              [-35.825000000249759, -8.325833325647409],
              [-35.824999998451119, -8.326666659039063],
              [-35.834166586618608, -8.326666659039063],
              [-35.834147160363102, -8.325145330792338],
              [-35.832905638184627, -8.322988502017722],
              [-35.831712984266744, -8.320976263543685],
              [-35.829171978603881, -8.318410905139103],
              [-35.827959084544091, -8.316573995793249],
              [-35.827593350952327, -8.315337012493103],
              [-35.827582599557275, -8.314456309111335],
              [-35.827828676552144, -8.313594637182689],
              [-35.828298559730683, -8.312970592219017],
              [-35.828968020457751, -8.312552844538118],
              [-35.829693645645193, -8.312421928429217],
              [-35.830748753652017, -8.312663048360946],
              [-35.83156816014241, -8.313134887564956],
              [-35.832227464825621, -8.313703983052335],
              [-35.833117522955263, -8.314707926829328],
              [-35.834493139446806, -8.316720188685736],
              [-35.83524690172311, -8.318031269827202],
              [-35.837528356748237, -8.317794139288026],
              [-35.842333959644463, -8.317011644571721],
              [-35.843430334842026, -8.317066316157536],
              [-35.844922186010024, -8.31752802090142],
              [-35.845820847953746, -8.317985834278772],
              [-35.846616308196019, -8.318562460688952],
              [-35.847440345295638, -8.319622873796561],
              [-35.847753958678197, -8.320531636026146],
              [-35.847812006318975, -8.321033248185813],
              [-35.847821855694065, -8.323280796971176],
              [-35.847971403057784, -8.324347570983548],
              [-35.84861859567161, -8.325864907139703],
              [-35.850048686797436, -8.32758594693388],
              [-35.850896994800451, -8.328325650108638],
              [-35.851826418954033, -8.3289488182333],
              [-35.853181263804231, -8.329572644661766],
              [-35.854952122442455, -8.330183836514777],
              [-35.857708763948267, -8.330830234127973],
              [-35.859421152264304, -8.330957789470347],
              [-35.864076906523962, -8.330998684341751],
              [-35.866340465040366, -8.331232715817123],
              [-35.86851835683666, -8.331875518840093],
              [-35.870356679916824, -8.332783844898472],
              [-35.872126127518754, -8.334223961666453],
              [-35.874991345973797, -8.337127315872863],
              [-35.876510224467268, -8.338865536315382],
              [-35.879177092049133, -8.34234837947406],
              [-35.883408699951474, -8.346895563079727],
              [-35.88700203561541, -8.351893611900607],
              [-35.889010145301938, -8.355074495091856],
              [-35.890445897660015, -8.356621922563647],
              [-35.891300491924142, -8.357248370515833],
              [-35.8921408553162, -8.357684530916345],
              [-35.893042177454504, -8.357996328567651],
              [-35.894126998162392, -8.358199064535142],
              [-35.896466173475062, -8.358303841848794],
              [-35.897708800021064, -8.358069374202216],
              [-35.898726376620914, -8.357700116167393],
              [-35.899613855494977, -8.357151524323342],
              [-35.900788584125792, -8.356100004611449],
              [-35.905090121511876, -8.351484901506979],
              [-35.90615560050054, -8.35016165343751],
              [-35.906664067292809, -8.349157112510682],
              [-35.906801773283178, -8.348609920911088],
              [-35.906822665433594, -8.347494311013577],
              [-35.906507630222904, -8.346448541566872],
              [-35.905962203093168, -8.345570980416369],
              [-35.905290776448112, -8.34480440100225],
              [-35.90415024273949, -8.343848731933406],
              [-35.903281742258571, -8.343383418210124],
              [-35.902786239193517, -8.343251857287328],
              [-35.901786242540993, -8.343229847279588],
              [-35.899926820466362, -8.343619221349911],
              [-35.89832426095586, -8.343721619057419],
              [-35.89685359421793, -8.343539089957005],
              [-35.89495470989192, -8.342919394114688],
              [-35.893050827133948, -8.341943876108928],
              [-35.892135652738148, -8.341309340553551],
              [-35.890453096732983, -8.339798658481243],
              [-35.888943501941014, -8.3381139773781],
              [-35.888304208972045, -8.337199518842681],
              [-35.887810194285009, -8.336203302940021],
              [-35.887048144756022, -8.333913151470711],
              [-35.88599853790214, -8.329627503306597],
              [-35.88501627318243, -8.326969629844484],
              [-35.883304067428753, -8.323183542486447],
              [-35.882636328903402, -8.321109330830893],
              [-35.881556936503387, -8.318711954992693],
              [-35.880081077979298, -8.315999396454913],
              [-35.876185131238799, -8.310261732592835],
              [-35.872115216044335, -8.302380529238008],
              [-35.874742919050448, -8.298519910565346],
              [-35.875516115676135, -8.297713295132439],
              [-35.876494478237532, -8.29715908882082],
              [-35.877810850090611, -8.296613582550719],
              [-35.879193130558463, -8.296183846906956],
              [-35.880619649577113, -8.295937680879206],
              [-35.882064501275693, -8.296088890190106],
              [-35.883390718007206, -8.296632951249649],
              [-35.884456377759591, -8.297472930631216],
              [-35.886336453664342, -8.299620731111816],
              [-35.887033785280948, -8.300084132876407],
              [-35.887991693661718, -8.300505599253938],
              [-35.889667993083378, -8.300852831093209],
              [-35.891753139595664, -8.300627191191893],
              [-35.893001890524772, -8.300639779002552],
              [-35.894147767105721, -8.300900669630153],
              [-35.895139463015767, -8.301309116522475],
              [-35.895908907669934, -8.301830685537652],
              [-35.896874322691815, -8.302831667847158],
              [-35.899728297822662, -8.306658597016565],
              [-35.900288455847544, -8.307556701380634],
              [-35.901054823920958, -8.309266524830207],
              [-35.902666585294639, -8.313451142256383],
              [-35.904747256780411, -8.319797934926726],
              [-35.905492828930846, -8.321187655485858],
              [-35.906061357845317, -8.321845412435778],
              [-35.907151277709261, -8.322395980989711],
              [-35.908015017179252, -8.322353421473167],
              [-35.908816459711829, -8.321844661501871],
              [-35.909316653640587, -8.32095210415622],
              [-35.909484656891379, -8.319832463497335],
              [-35.909459138628279, -8.318030929883435],
              [-35.910416457053827, -8.31747088247522],
              [-35.911260192903512, -8.317333234041485],
              [-35.912159597687264, -8.317428903021437],
              [-35.912664113757785, -8.317602834603065],
              [-35.913570986511786, -8.318139335961519],
              [-35.914367892863936, -8.318835247548577],
              [-35.915385308485156, -8.320058434349221],
              [-35.91723402862749, -8.323273953130411],
              [-35.918284491635916, -8.324696270516426],
              [-35.919241962946217, -8.325504448971117],
              [-35.920104811188025, -8.325840000415667],
              [-35.920935490680336, -8.325908830028425],
              [-35.921743278829183, -8.325783631809088],
              [-35.923826401866904, -8.32489329758755],
              [-35.924937234565675, -8.324756883023667],
              [-35.926414933102649, -8.325014827472273],
              [-35.929273835618972, -8.326372297443527],
              [-35.93041761857819, -8.326551236450371],
              [-35.93167080676227, -8.326186225913546],
              [-35.934121339727994, -8.32470706238098],
              [-35.936825853830044, -8.323284903275635],
              [-35.937739010147141, -8.322951847449758],
              [-35.938725222890696, -8.322774581181932],
              [-35.939729157674435, -8.322734833845459],
              [-35.940729415130363, -8.322834273682759],
              [-35.941702761273348, -8.323113670559394],
              [-35.943057808471053, -8.323965856439088],
              [-35.944190983926717, -8.325088813797663],
              [-35.945122744518983, -8.326369127333294],
              [-35.946909746887286, -8.32967503337602],
              [-35.947695956703001, -8.330813030996524],
              [-35.949667634853427, -8.333009241083573],
              [-35.951423316532782, -8.334598799095261],
              [-35.95325249800436, -8.335803591162744],
              [-35.954973251814067, -8.336524574949124],
              [-35.956643039244796, -8.336802080551536],
              [-35.958934018090417, -8.336878620951552],
              [-35.961440378270822, -8.336062209203533],
              [-35.964234875838827, -8.335700582815605],
              [-35.964823874222361, -8.336878596669862],
              [-35.967374226539278, -8.338978983992888],
              [-35.968649654058254, -8.339791743594049],
              [-35.969647191064951, -8.340146069285595],
              [-35.971288971808576, -8.34026687161878],
              [-35.972870755181191, -8.339954757406076],
              [-35.975342148821085, -8.339032136623757],
              [-35.978241183583691, -8.337717627266613],
              [-35.982218602606395, -8.335412231389341],
              [-35.983133284173675, -8.335028684026497],
              [-35.983660959585563, -8.334932592365192],
              [-35.984568640830048, -8.334970858518261],
              [-35.985910396047871, -8.335425030540705],
              [-35.986657989874288, -8.335964892665629],
              [-35.987515534814065, -8.337051200852216],
              [-35.987930687950836, -8.338045289858201],
              [-35.98867502443278, -8.340538084664843],
              [-35.988852613557242, -8.34196982514726],
              [-35.988884552979698, -8.342227323532086],
              [-35.988978563609805, -8.346842413146817],
              [-35.989329558212489, -8.348506971919164],
              [-35.989866031691975, -8.349514356502311],
              [-35.990978404030102, -8.35074544834373],
              [-35.991898004888981, -8.351328350223127],
              [-35.99325500901142, -8.351625303664775],
              [-35.994107274031421, -8.351476900238708],
              [-35.994926924238143, -8.351027435267895],
              [-35.995651936087938, -8.350340325345712],
              [-35.995907489239187, -8.349975104367502],
              [-35.996202048685575, -8.349554136214351],
              [-35.996947371723763, -8.347781503213753],
              [-35.997641855187396, -8.345331258031081],
              [-35.998690769563325, -8.342908502425416],
              [-36.000231180524509, -8.340200337975148],
              [-36.000907322315015, -8.339011641074308],
              [-36.003812906840153, -8.334843308571806],
              [-36.006927066630794, -8.337959004404468],
              [-36.008477449274835, -8.340082698557637],
              [-36.010524384492726, -8.343801797215121],
              [-36.012213135927425, -8.346441381164766],
              [-36.013425817747247, -8.348798102250555],
              [-36.015424212957043, -8.353350713264774],
              [-36.015940375248078, -8.354193750341267],
              [-36.016569853016279, -8.354856706271903],
              [-36.017354578051311, -8.35535590285258],
              [-36.017880624790962, -8.355532533299709],
              [-36.018961644963895, -8.35560928324179],
              [-36.020016203484943, -8.355319253680591],
              [-36.020458370457391, -8.355044330031774],
              [-36.020837326781191, -8.35470349147289],
              [-36.021421701750114, -8.353858582907208],
              [-36.02185774344008, -8.352664727495153],
              [-36.022450226696549, -8.349679596136411],
              [-36.022772809017852, -8.34882562510245],
              [-36.023254984130176, -8.347950619825212],
              [-36.024170394148314, -8.346921052564483],
              [-36.026990489119726, -8.344675869895411],
              [-36.028066472189892, -8.343502904835134],
              [-36.028656600121963, -8.342626873431414],
              [-36.029253203172743, -8.341262984099387],
              [-36.029643769744155, -8.339816202455722],
              [-36.030061596565361, -8.337394758960897],
              [-36.030401586164203, -8.333371675962042],
              [-36.030975664795051, -8.330575376152638],
              [-36.031493140096302, -8.329294740659691],
              [-36.032030086619159, -8.328393056094626],
              [-36.032669643089491, -8.327562784894553],
              [-36.033458789191741, -8.326887365259665],
              [-36.033923585804814, -8.326633747449421],
              [-36.034841250423312, -8.32636827567535],
              [-36.035781497917753, -8.326314435962388],
              [-36.036714077792396, -8.3264382015617],
              [-36.037525615214918, -8.326714551535304],
              [-36.038327197142394, -8.327154217493103],
              [-36.041181133602379, -8.329712205054193],
              [-36.041891953251024, -8.330131001344569],
              [-36.042924423523289, -8.330552698847953],
              [-36.044838277064798, -8.33093162099749],
              [-36.050135106749735, -8.331184831414816],
              [-36.052640946222652, -8.331647312273617],
              [-36.054115946793445, -8.332165878452486],
              [-36.054760150060815, -8.332495548330826],
              [-36.055577614015647, -8.333124909187177],
              [-36.056269781725007, -8.33391635755379],
              [-36.057119178807, -8.335191949648731],
              [-36.059132691620391, -8.339474406118882],
              [-36.060846990995799, -8.344318142967893],
              [-36.061428444067417, -8.345424131018774],
              [-36.062041042460123, -8.346263753369499],
              [-36.063834254647077, -8.348217252624067],
              [-36.066183775760578, -8.350376404347514],
              [-36.067437536812804, -8.351290209975161],
              [-36.070170447008479, -8.353002253850889],
              [-36.071431951223531, -8.354003707405127],
              [-36.073396091256541, -8.355901812918262],
              [-36.074942763297827, -8.357784945618675],
              [-36.07592916489898, -8.359343942868634],
              [-36.076900423715529, -8.361225200482579],
              [-36.078161523235678, -8.364115563082748],
              [-36.079424516728011, -8.369725430584083],
              [-36.079584014190857, -8.372341199586629],
              [-36.079550036904664, -8.375162798224494],
              [-36.079737448424453, -8.377772339421881],
              [-36.08060370149957, -8.382967669822051],
              [-36.080684859918335, -8.383454416188329],
              [-36.08084177272957, -8.385682348061891],
              [-36.081002652450422, -8.39878271115316],
              [-36.080850216464285, -8.400375471650648],
              [-36.08050193781412, -8.401374042877706],
              [-36.079842123215371, -8.402184341933776],
              [-36.079407394535565, -8.402487172444978],
              [-36.0785175738269, -8.40281211728535],
              [-36.077120414975127, -8.402887448996523],
              [-36.075813620002521, -8.402606524171688],
              [-36.074461602620829, -8.401986832826026],
              [-36.073290001832049, -8.40105723780124],
              [-36.07230480532246, -8.399943890598024],
              [-36.070949578260411, -8.397901687612773],
              [-36.070023991513949, -8.396974811238522],
              [-36.069358889800867, -8.396596685888312],
              [-36.068423583181755, -8.396361848620359],
              [-36.067827922620495, -8.396405841656247],
              [-36.067359752650418, -8.396594812600483],
              [-36.06653289617617, -8.397380513399924],
              [-36.065725927309643, -8.39883926861728],
              [-36.064054855647043, -8.40547820695366],
              [-36.063257398010478, -8.407587042507942],
              [-36.061807980453864, -8.410428066501993],
              [-36.060653398435534, -8.412211723392261],
              [-36.059273076691113, -8.41379397081505],
              [-36.057678066089807, -8.415156482385726],
              [-36.055934490184541, -8.416098380136077],
              [-36.052475880157715, -8.413180135854532],
              [-36.05120906544596, -8.412267062275077],
              [-36.050240418260671, -8.411860673930903],
              [-36.04873714390402, -8.41169386138256],
              [-36.048046585981126, -8.411819931944251],
              [-36.047609496580947, -8.412024471951781],
              [-36.046905172735478, -8.412647086993445],
              [-36.045981553705701, -8.414328024218889],
              [-36.045164723772928, -8.416786759901015],
              [-36.044457677679645, -8.41812776688289],
              [-36.04344839462766, -8.419321840830264],
              [-36.042563335829243, -8.419912365363302],
              [-36.041848362212079, -8.420212685866716],
              [-36.0407574360068, -8.420412793115872],
              [-36.039108428311295, -8.420279562152018],
              [-36.037736661817462, -8.419737037134496],
              [-36.033565248237551, -8.417442331498364],
              [-36.033509009133411, -8.417427218391367],
              [-36.032482497769024, -8.417151360346963],
              [-36.03078726581964, -8.417002258147647],
              [-36.029091944837319, -8.417114596061708],
              [-36.02800092600188, -8.417357002923325],
              [-36.024145375908347, -8.418929758901186],
              [-36.023076209699298, -8.419223839908227],
              [-36.020108120493319, -8.419758122639223],
              [-36.01784838589424, -8.419889865225059],
              [-36.015617382835899, -8.419592052930852],
              [-36.011923361773711, -8.418229508984609],
              [-36.010217680601443, -8.417480218137484],
              [-36.008783215173196, -8.416607658116902],
              [-36.005464903882171, -8.414038406547149],
              [-36.004470239310081, -8.410672138008692],
              [-36.003697859268755, -8.408737348249986],
              [-36.000232086141807, -8.408641278172411],
              [-36.000047497593869, -8.40863616102996],
              [-35.997150609512971, -8.408199890912215],
              [-35.993467556582402, -8.408110125082146],
              [-35.991958839528763, -8.407942343064633],
              [-35.988333468214933, -8.406846496668436],
              [-35.987449894197198, -8.406455502019753],
              [-35.98632944414851, -8.405771526234901],
              [-35.985369291959216, -8.405008938012031],
              [-35.984706988936352, -8.40432613314448],
              [-35.983073972086117, -8.401974012090932],
              [-35.982252467477338, -8.40034033513831],
              [-35.980634083496113, -8.3956253479314],
              [-35.980038962528056, -8.394449118332091],
              [-35.97912533586549, -8.393419698560194],
              [-35.977152181927636, -8.391912305308608],
              [-35.975733449239272, -8.390525140622685],
              [-35.974791208847194, -8.389305140120086],
              [-35.973070601626944, -8.386650142689859],
              [-35.972017453242813, -8.385471239406115],
              [-35.970985883191929, -8.384630687156459],
              [-35.96941430352723, -8.383691267937593],
              [-35.967670101693784, -8.383033169245323],
              [-35.965562783295752, -8.382448434547598],
              [-35.960931352100204, -8.380577765551379],
              [-35.958687006699961, -8.379290838401403],
              [-35.956578467921929, -8.377520156030357],
              [-35.95281744017268, -8.37997680528531],
              [-35.949663273141482, -8.381539063476964],
              [-35.949027242013585, -8.381757615821243],
              [-35.949467171472747, -8.381189931370216],
              [-35.947253364351241, -8.378333325722338],
              [-35.946666666433487, -8.378333325722338],
              [-35.946666665534167, -8.386666658739273],
              [-35.944999999650236, -8.386666658739273],
              [-35.944999998750916, -8.38749999303019],
              [-35.944166666258582, -8.387499993929509],
              [-35.944166666258582, -8.388333326421844],
              [-35.942499999475331, -8.388333325522524],
              [-35.942499999475331, -8.389999994104414],
              [-35.939166666808205, -8.389999993205095],
              [-35.939166666808205, -8.390833325697372],
              [-35.939999998401163, -8.390833325697372],
              [-35.939999998401163, -8.39333332587222],
              [-35.940833333591456, -8.39333332587222],
              [-35.94083333269208, -8.394166657465234],
              [-35.940833333591456, -8.394999992655471],
              [-35.941666668781693, -8.394999992655471],
              [-35.941666666983053, -8.395833326047125],
              [-35.943333332866985, -8.395833325147805],
              [-35.943333331967665, -8.399166659613627],
              [-35.941666666983053, -8.399166658714307],
              [-35.941666666083734, -8.399999993005224],
              [-35.94250000037465, -8.399999993005224],
              [-35.942499999475331, -8.401666658889155],
              [-35.94083333269208, -8.401666658889155],
              [-35.94083333269208, -8.402499993180072],
              [-35.939999999300483, -8.402499993180072],
              [-35.940000001099122, -8.403333325672406],
              [-35.939166665908886, -8.403333324773087],
              [-35.939166665908886, -8.404999992455657],
              [-35.939999999300483, -8.404999991556338],
              [-35.939999998401163, -8.406666659238908],
              [-35.941666668781693, -8.406666659238908],
              [-35.941666666083734, -8.407499991731186],
              [-35.94083333269208, -8.407499992630505],
              [-35.940833333591456, -8.410833326197007],
              [-35.939999998401163, -8.410833326197007],
              [-35.939999998401163, -8.412499992980258],
              [-35.94083333269208, -8.412499992980258],
              [-35.94083333179276, -8.415833325647441],
              [-35.940833333591456, -8.416666659938357],
              [-35.938333333416551, -8.416666659039038],
              [-35.938333332517232, -8.417499992430635],
              [-35.936666665733981, -8.417499992430635],
              [-35.936666665733981, -8.419166659213886],
              [-35.937500000924274, -8.419166659213886],
              [-35.937499998226315, -8.420833326896457],
              [-35.938333332517232, -8.420833325997137],
              [-35.938333332517232, -8.42583332544757],
              [-35.937499998226315, -8.42583332544757],
              [-35.937499998226315, -8.429166659014072],
              [-35.937500000924274, -8.430833325797323],
              [-35.936666665733981, -8.430833325797323],
              [-35.93666666753262, -8.433333325972171],
              [-35.9366666666333, -8.434166658464449],
              [-35.937500000024954, -8.434166657565129],
              [-35.937500000924274, -8.434999992755422],
              [-35.939166665908886, -8.434999992755422],
              [-35.939166665908886, -8.435833326147019],
              [-35.938333332517232, -8.435833326147019],
              [-35.938333331617912, -8.436666658639353],
              [-35.939166665908886, -8.436666658639353],
              [-35.939166667707525, -8.43749999293027],
              [-35.942499999475331, -8.43749999293027],
              [-35.942499999475331, -8.438333324523285],
              [-35.943333333766304, -8.438333327221244],
              [-35.943333333766304, -8.44166665898905],
              [-35.942499998576011, -8.44166665898905],
              [-35.942499998576011, -8.442499991481384],
              [-35.941666667882373, -8.442499992380704],
              [-35.941666666083734, -8.447499992730457],
              [-35.94083333269208, -8.447499993629776],
              [-35.94083333179276, -8.448333325222734],
              [-35.939999998401163, -8.448333326122054],
              [-35.940000001998442, -8.449166658614331],
              [-35.939166666808205, -8.449166658614331],
              [-35.939166665908886, -8.450833324498262],
              [-35.938333332517232, -8.450833325397582],
              [-35.938333330718592, -8.451666658789236],
              [-35.939166665908886, -8.451666658789236],
              [-35.939166665908886, -8.453333325572487],
              [-35.939999998401163, -8.453333325572487],
              [-35.939999997501843, -8.454166658964084],
              [-35.939166667707525, -8.454166658964084],
              [-35.939166665009566, -8.455833325747335],
              [-35.938333333416551, -8.455833325747335],
              [-35.938333331617912, -8.456666659138989],
              [-35.937499997326995, -8.456666660038309],
              [-35.937499998226315, -8.458333325922183],
              [-35.938333333416551, -8.458333325922183],
              [-35.938333334315871, -8.459166658414517],
              [-35.937499998226315, -8.459166658414517],
              [-35.937500000024954, -8.459999993604754],
              [-35.936666665733981, -8.459999991806114],
              [-35.936666665733981, -8.461666658589365],
              [-35.935000000749369, -8.461666659488685],
              [-35.93499999895073, -8.462499992880339],
              [-35.932499998775882, -8.462499992880339],
              [-35.932499999675201, -8.463333325372616],
              [-35.931666668082244, -8.463333325372616],
              [-35.931666668082244, -8.46416665876427],
              [-35.93083333289195, -8.46416665876427],
              [-35.93083333379127, -8.464999993055187],
              [-35.929999999500353, -8.464999993055187],
              [-35.930000000399673, -8.465833325547464],
              [-35.929166667907339, -8.465833325547464],
              [-35.92916666520938, -8.466666658939118],
              [-35.926666666833171, -8.466666658039799],
              [-35.926666667732491, -8.467499993230035],
              [-35.925833331642878, -8.467499993230035],
              [-35.925833333441517, -8.468333326621689],
              [-35.92499999825128, -8.46833332482305],
              [-35.924999997351961, -8.469166658214647],
              [-35.924166665758946, -8.469166659113966],
              [-35.924166665758946, -8.470833325897217],
              [-35.923333332367349, -8.470833325897217],
              [-35.923333331468029, -8.471666658389552],
              [-35.924166665758946, -8.471666658389552],
              [-35.924166666658323, -8.472499992680468],
              [-35.92500000004992, -8.472499991781149],
              [-35.92499999825128, -8.473333326072066],
              [-35.923333333266669, -8.473333326072066],
              [-35.923333333266669, -8.47416665766508],
              [-35.91916666630857, -8.47416665766508],
              [-35.91916666540925, -8.473333326072066],
              [-35.918333330218957, -8.473333326072066],
              [-35.918333332017596, -8.4741666585644],
              [-35.916666665234345, -8.4741666585644],
              [-35.916666665234345, -8.474999992855317],
              [-35.917499999525319, -8.474999992855317],
              [-35.917499999525319, -8.476666658739248],
              [-35.918333334715555, -8.476666659638568],
              [-35.918333332916916, -8.477499992130902],
              [-35.91916666540925, -8.477499993030222],
              [-35.91916666540925, -8.47999999320507],
              [-35.918333332017596, -8.47999999320507],
              [-35.918333332017596, -8.480833325697404],
              [-35.917499999525319, -8.480833325697404],
              [-35.917499999525319, -8.481666659089001],
              [-35.918333332017596, -8.481666658189681],
              [-35.918333333816236, -8.484166660163169],
              [-35.91916666540925, -8.484166658364529],
              [-35.91916666720789, -8.4858333260471],
              [-35.919999999700167, -8.4858333260471],
              [-35.919999998800847, -8.489999993005199],
              [-35.920833330393862, -8.489999993005199],
              [-35.920833333091821, -8.490833325497533],
              [-35.919999999700167, -8.490833325497533],
              [-35.919999997901527, -8.49166665888913],
              [-35.91916666540925, -8.49166665888913],
              [-35.91916666630857, -8.493333325672381],
              [-35.919999998800847, -8.493333325672381],
              [-35.919999999700167, -8.494166659963355],
              [-35.920833331293181, -8.494166659064035],
              [-35.920833331293181, -8.494999992455632],
              [-35.921666667382738, -8.494999993354952],
              [-35.921666666483418, -8.496666659238883],
              [-35.922499998975752, -8.496666660138203],
              [-35.922500000774392, -8.497499992630537],
              [-35.923333331468029, -8.497499992630537],
              [-35.923333333266669, -8.496666659238883],
              [-35.92499999825128, -8.496666659238883],
              [-35.925833333441517, -8.496666659238883],
              [-35.925833331642878, -8.495833325847286],
              [-35.926666665933851, -8.495833325847286],
              [-35.926666666833171, -8.49166665888913],
              [-35.927499998426129, -8.49166665888913],
              [-35.927500000224768, -8.489999992105879],
              [-35.928333333616422, -8.489999993005199],
              [-35.928333331817782, -8.489166658714282],
              [-35.929166667008019, -8.489166657814962],
              [-35.92916666520938, -8.488333326222005],
              [-35.931666665384284, -8.488333326222005],
              [-35.931666665384284, -8.487499992830351],
              [-35.936666665733981, -8.487499992830351],
              [-35.93666666753262, -8.486666658539434],
              [-35.935833333241703, -8.486666657640114],
              [-35.935833331443064, -8.4858333260471],
              [-35.936666665733981, -8.4858333260471],
              [-35.936666665733981, -8.484999992655503],
              [-35.937500000024954, -8.484999992655503],
              [-35.937499998226315, -8.484166658364529],
              [-35.938333330718592, -8.484166658364529],
              [-35.938333331617912, -8.482499991581278],
              [-35.941666667882373, -8.482499992480598],
              [-35.941666667882373, -8.483333326771572],
              [-35.945833333041833, -8.483333325872252],
              [-35.945833333941152, -8.484166660163169],
              [-35.946666667332806, -8.484166658364529],
              [-35.946666667332806, -8.484999992655503],
              [-35.947500000724403, -8.484999992655503],
              [-35.947499999825084, -8.483333325872252],
              [-35.948333333216738, -8.483333324972932],
              [-35.948333333216738, -8.481666659988321],
              [-35.949166667507654, -8.481666659089001],
              [-35.949166665709015, -8.480833324798027],
              [-35.948333331418041, -8.480833325697404],
              [-35.948333332317361, -8.479166659813473],
              [-35.949166665709015, -8.479166658914153],
              [-35.949166667507654, -8.478333325522499],
              [-35.950833331592946, -8.478333324623179],
              [-35.950833331592946, -8.477499993030222],
              [-35.951666665883863, -8.477499993929541],
              [-35.951666666783183, -8.478333325522499],
              [-35.952499999275517, -8.478333325522499],
              [-35.952499998376197, -8.477499993030222],
              [-35.953333331767794, -8.477499993030222],
              [-35.953333332667114, -8.47583332714629],
              [-35.954166666058768, -8.475833325347651],
              [-35.954166666958088, -8.474999992855317],
              [-35.958333333916187, -8.474999992855317],
              [-35.958333333016867, -8.475833325347651],
              [-35.959999999800118, -8.475833325347651],
              [-35.960000000699438, -8.476666658739248],
              [-35.964166666758217, -8.476666658739248],
              [-35.964166667657537, -8.4741666585644],
              [-35.965833331742829, -8.47416665766508],
              [-35.965833333541468, -8.473333325172746],
              [-35.968333333716316, -8.473333326072066],
              [-35.968333331018357, -8.470833325897217],
              [-35.96916666620865, -8.470833326796537],
              [-35.969999999600248, -8.470833325897217],
              [-35.969999998700928, -8.46999999250562],
              [-35.976666666733252, -8.46999999250562],
              [-35.976666665833932, -8.470833325897217],
              [-35.978333331717863, -8.470833325897217],
              [-35.978333333516503, -8.471666658389552],
              [-35.97916666600878, -8.471666658389552],
              [-35.9791666669081, -8.472499992680468],
              [-35.979999999400434, -8.472499992680468],
              [-35.979999999400434, -8.471666658389552],
              [-35.982499998675962, -8.471666658389552],
              [-35.982499999575282, -8.472499992680468],
              [-35.983333332067559, -8.472499992680468],
              [-35.983333332067559, -8.473333326072066],
              [-35.98499999975013, -8.473333326072066],
              [-35.98499999885081, -8.472499992680468],
              [-35.986666666533381, -8.472499992680468],
              [-35.986666667432701, -8.46999999250562],
              [-35.988333331517993, -8.46999999250562],
              [-35.988333332417312, -8.470833326796537],
              [-35.989166666708286, -8.470833325897217],
              [-35.989166667607606, -8.46999999250562],
              [-35.991666666883134, -8.46999999250562],
              [-35.991666665983814, -8.469166659113966],
              [-35.992499998476092, -8.469166658214647],
              [-35.992499998476092, -8.468333325722369],
              [-35.994999999550316, -8.468333325722369],
              [-35.994999999550316, -8.466666658939118],
              [-35.999166667407735, -8.466666658939118],
              [-35.999166665609096, -8.464999993055187],
              [-35.999999999000693, -8.464999993055187],
              [-35.999999999900012, -8.46416665876427],
              [-36.000833331493027, -8.46416665966359],
              [-36.000833331493027, -8.464999992155867],
              [-36.003333332567195, -8.464999993055187],
              [-36.003333331667875, -8.46416665876427],
              [-36.005833331842723, -8.46416665876427],
              [-36.005833331842723, -8.463333325372616],
              [-36.007499998625974, -8.463333325372616],
              [-36.007499998625974, -8.462499993779659],
              [-36.006666667033016, -8.462499993779659],
              [-36.006666665234377, -8.459166658414517],
              [-36.008333332017628, -8.459166658414517],
              [-36.008333333816267, -8.458333325922183],
              [-36.009166667207865, -8.458333325022863],
              [-36.009166665409225, -8.455833325747335],
              [-36.009999998800879, -8.455833325747335],
              [-36.010000000599518, -8.452499993080153],
              [-36.01166666558413, -8.452499993080153],
              [-36.01166666648345, -8.451666658789236],
              [-36.012499999875047, -8.451666659688556],
              [-36.012499998076407, -8.450833325397582],
              [-36.0175000002248, -8.450833325397582],
              [-36.0175000002248, -8.446666658439483],
              [-36.020833333791302, -8.446666658439483],
              [-36.020833332891982, -8.445833325947206],
              [-36.021666665384259, -8.445833326846525],
              [-36.021666668082219, -8.444999992555552],
              [-36.024999999850081, -8.444999992555552],
              [-36.025000000749401, -8.444166660063274],
              [-36.02749999822629, -8.444166659163955],
              [-36.027500000024929, -8.443333325772301],
              [-36.028333331617944, -8.443333325772301],
              [-36.028333332517263, -8.44166665898905],
              [-36.032499998576043, -8.44166665898905],
              [-36.032499998576043, -8.442499992380704],
              [-36.03333333196764, -8.442499992380704],
              [-36.03333333196764, -8.443333325772301],
              [-36.034166665359294, -8.443333325772301],
              [-36.034166665359294, -8.444999992555552],
              [-36.03500000054953, -8.444999992555552],
              [-36.034999998750891, -8.447499992730457],
              [-36.036666667332781, -8.447499992730457],
              [-36.036666665534142, -8.448333326122054],
              [-36.03916666660831, -8.448333326122054],
              [-36.03916666660831, -8.449166658614331],
              [-36.040833331592921, -8.449166659513651],
              [-36.040833332492241, -8.449999992905305],
              [-36.043333332667146, -8.449999992905305],
              [-36.043333332667146, -8.450833325397582],
              [-36.044166666058743, -8.450833325397582],
              [-36.044166667857382, -8.449999992905305],
              [-36.044999998551077, -8.449999992005985],
              [-36.044999998551077, -8.449166658614331],
              [-36.045833332841994, -8.449166658614331],
              [-36.045833332841994, -8.447499992730457],
              [-36.046666666233591, -8.447499991831137],
              [-36.046666666233591, -8.444999992555552],
              [-36.047499998725925, -8.444999992555552],
              [-36.047500000524565, -8.445833325947206],
              [-36.049166667307816, -8.445833325947206],
              [-36.049166666408496, -8.446666658439483],
              [-36.049999999800093, -8.446666658439483],
              [-36.050000000699413, -8.447499991831137],
              [-36.051666666583344, -8.447499992730457],
              [-36.051666665684024, -8.449166658614331],
              [-36.053333331567956, -8.449166658614331],
              [-36.053333333366595, -8.449999992905305],
              [-36.054166665858872, -8.449999993804624],
              [-36.054166667657569, -8.450833325397582],
              [-36.055000001049166, -8.450833325397582],
              [-36.054999999250526, -8.451666657889916],
              [-36.056666666033777, -8.451666658789236],
              [-36.056666666033777, -8.452499993979472],
              [-36.057499999425374, -8.452499993080153],
              [-36.057500000324694, -8.453333325572487],
              [-36.058333332817028, -8.453333325572487],
              [-36.058333332817028, -8.454166659863404],
              [-36.05999999870096, -8.454166658964084],
              [-36.05999999870096, -8.456666659138989],
              [-36.061666665484154, -8.456666659138989],
              [-36.061666665484154, -8.457499992530586],
              [-36.063333334066044, -8.457499992530586],
              [-36.063333331368085, -8.458333325922183],
              [-36.064166667457698, -8.458333325922183],
              [-36.064166666558378, -8.459166658414517],
              [-36.06583333064367, -8.459166658414517],
              [-36.06583333154299, -8.459999992705434],
              [-36.066666666733227, -8.459999992705434],
              [-36.066666666733227, -8.461666658589365],
              [-36.068333331717838, -8.461666658589365],
              [-36.068333333516478, -8.462499992880339],
              [-36.071666667982299, -8.462499993779659],
              [-36.07166666528434, -8.463333325372616],
              [-36.076666666533413, -8.463333325372616],
              [-36.076666664734773, -8.461666657690046],
              [-36.07750000082433, -8.461666659488685],
              [-36.07749999992501, -8.460833326097088],
              [-36.078333332417287, -8.460833326097088],
              [-36.078333332417287, -8.459166658414517],
              [-36.079166666708261, -8.459166658414517],
              [-36.079166666708261, -8.456666659138989],
              [-36.080000000999178, -8.456666659138989],
              [-36.079999998301219, -8.457499991631266],
              [-36.080833333491512, -8.457499992530586],
              [-36.080833331692872, -8.456666659138989],
              [-36.084166667058014, -8.456666660038309],
              [-36.084166665259374, -8.457499991631266],
              [-36.086666665434223, -8.457499992530586],
              [-36.086666666333542, -8.458333326821503],
              [-36.08749999882582, -8.458333325922183],
              [-36.087499999725139, -8.459166658414517],
              [-36.08916666650839, -8.459166658414517],
              [-36.08916666740771, -8.459999992705434],
              [-36.092500000974212, -8.459999992705434],
              [-36.092500000074892, -8.459166658414517],
              [-36.093333332567227, -8.459166658414517],
              [-36.093333333466546, -8.459999992705434],
              [-36.094166666858143, -8.459999992705434],
              [-36.094166667757463, -8.459166658414517],
              [-36.094999999350421, -8.459166658414517],
              [-36.094999999350421, -8.458333325922183],
              [-36.095833331842755, -8.458333325022863],
              [-36.095833333641394, -8.456666657340293],
              [-36.099166666308577, -8.456666659138989],
              [-36.099166666308577, -8.455833325747335],
              [-36.100833331293188, -8.455833325747335],
              [-36.100833331293188, -8.454999992355738],
              [-36.102499998975702, -8.454999992355738],
              [-36.102499998975702, -8.455833325747335],
              [-36.104166665758953, -8.455833326646655],
              [-36.104166665758953, -8.456666659138989],
              [-36.105000000949246, -8.456666659138989],
              [-36.105000000949246, -8.458333325922183],
              [-36.105833331642884, -8.458333326821503],
              [-36.105833332542204, -8.459999991806114],
              [-36.106666665933858, -8.459999992705434],
              [-36.106666665933858, -8.461666658589365],
              [-36.107499998426135, -8.461666657690046],
              [-36.107499999325455, -8.463333325372616],
              [-36.108333331817789, -8.463333325372616],
              [-36.10833333091847, -8.465833325547464],
              [-36.109166667907346, -8.465833324648145],
              [-36.109166667907346, -8.466666658939118],
              [-36.108333331817789, -8.466666658939118],
              [-36.108333333616429, -8.467499993230035],
              [-36.109166665209386, -8.467499993230035],
              [-36.109166665209386, -8.468333325722369],
              [-36.109999998600983, -8.468333325722369],
              [-36.10999999950036, -8.467499993230035],
              [-36.110833331992637, -8.467499993230035],
              [-36.110833331992637, -8.466666659838438],
              [-36.112500000574528, -8.466666658939118],
              [-36.112499998775888, -8.465833325547464],
              [-36.113333333066805, -8.465833325547464],
              [-36.113333331268166, -8.464999993055187],
              [-36.114166665559139, -8.464999993055187],
              [-36.114166665559139, -8.463333324473297],
              [-36.114999998950736, -8.463333327171256],
              [-36.115000000749376, -8.459999991806114],
              [-36.115833330543751, -8.459999992705434],
              [-36.115833331443071, -8.456666659138989],
              [-36.116666666633307, -8.456666659138989],
              [-36.116666665733987, -8.455833325747335],
              [-36.117499998226322, -8.455833325747335],
              [-36.117500000024961, -8.454999992355738],
              [-36.123333331967672, -8.454999992355738],
              [-36.123333334665631, -8.454166658964084],
              [-36.124999999650242, -8.454166658964084],
              [-36.125000000549562, -8.453333325572487],
              [-36.1258333312432, -8.453333325572487],
              [-36.125833333941159, -8.452499993080153],
              [-36.127499999825091, -8.452499993080153],
              [-36.127499999825091, -8.454166658964084],
              [-36.129999999100619, -8.454166658964084],
              [-36.13, -8.453333325572487],
              [-36.132499998376204, -8.453333325572487],
              [-36.132499998376204, -8.452499993979472],
              [-36.13166666588387, -8.452499993080153],
              [-36.13166666588387, -8.451666659688556],
              [-36.132499998376204, -8.451666658789236],
              [-36.132499999275524, -8.449166658614331],
              [-36.133333332667121, -8.449166658614331],
              [-36.133333333566441, -8.448333326122054],
              [-36.134166666058775, -8.448333326122054],
              [-36.134166667857414, -8.444999992555552],
              [-36.134999999450372, -8.444999991656232],
              [-36.134999998551052, -8.444166657365315],
              [-36.135833332841969, -8.444166659163955],
              [-36.135833331942649, -8.443333325772301],
              [-36.133333331767801, -8.443333325772301],
              [-36.133333331767801, -8.442499991481384],
              [-36.13166666588387, -8.442499992380704],
              [-36.13166666588387, -8.44166665808973],
              [-36.129166665709022, -8.44166665898905],
              [-36.129166666608342, -8.440833325597453],
              [-36.123333332866991, -8.440833325597453],
              [-36.123333334665631, -8.44166665898905],
              [-36.122500000374657, -8.44166665898905],
              [-36.122499998576018, -8.442499992380704],
              [-36.119999999300489, -8.442499992380704],
              [-36.11999999840117, -8.44166665898905],
              [-36.119166665908836, -8.44166665898905],
              [-36.119166667707532, -8.440833325597453],
              [-36.118333330718599, -8.440833325597453],
              [-36.118333332517238, -8.439999992205856],
              [-36.116666665733987, -8.439999993105175],
              [-36.116666665733987, -8.438333325422605],
              [-36.11583333234239, -8.438333324523285],
              [-36.115833331443071, -8.436666658639353],
              [-36.115000001648696, -8.436666658639353],
              [-36.114999998950736, -8.433333325972171],
              [-36.11583333234239, -8.433333325072851],
              [-36.115833331443071, -8.432499992580574],
              [-36.11583333234239, -8.429166659014072],
              [-36.114999998950736, -8.429166659014072],
              [-36.115000001648696, -8.427499993130141],
              [-36.11583333324171, -8.427499993130141],
              [-36.115833331443071, -8.426666658839167],
              [-36.116666666633307, -8.426666657939847],
              [-36.116666665733987, -8.424166658664319],
              [-36.115833331443071, -8.424166659563639],
              [-36.11583333234239, -8.422499992780388],
              [-36.116666665733987, -8.422499992780388],
              [-36.116666667532627, -8.420833325997137],
              [-36.117499998226322, -8.420833325997137],
              [-36.117500000024961, -8.41999999260554],
              [-36.116666666633307, -8.41999999260554],
              [-36.116666665733987, -8.419166659213886],
              [-36.11583333324171, -8.419166659213886],
              [-36.11583333324171, -8.417499991531315],
              [-36.114999998950736, -8.417499991531315],
              [-36.114999998950736, -8.416666658139718],
              [-36.114166667357779, -8.416666659039038],
              [-36.114166666458459, -8.415833325647441],
              [-36.113333333066805, -8.415833325647441],
              [-36.113333333066805, -8.414999993155106],
              [-36.112500000574528, -8.414999993155106],
              [-36.112500000574528, -8.413333325472536],
              [-36.111666665384234, -8.413333324573216],
              [-36.111666666283554, -8.411666658689285],
              [-36.110833333791277, -8.411666658689285],
              [-36.110833332891957, -8.409999992805353],
              [-36.111666665384234, -8.409999992805353],
              [-36.11166666808225, -8.409166658514437],
              [-36.112499998775888, -8.409166658514437],
              [-36.112499998775888, -8.408333326022159],
              [-36.113333331268166, -8.408333326022159],
              [-36.113333333966125, -8.406666660138228],
              [-36.114166666458459, -8.406666659238908],
              [-36.114166667357779, -8.404999992455657],
              [-36.114999998051417, -8.404999992455657],
              [-36.115000000749376, -8.404166659064003],
              [-36.115833330543751, -8.404166659064003],
              [-36.11583333234239, -8.399166658714307],
              [-36.114999999850056, -8.399166658714307],
              [-36.115000000749376, -8.398333328020612],
              [-36.114166665559139, -8.398333326221973],
              [-36.114166665559139, -8.397499992830376],
              [-36.112499999675208, -8.397499992830376],
              [-36.112499998775888, -8.395833326047125],
              [-36.111666666283554, -8.395833326047125],
              [-36.11166666808225, -8.394166657465234],
              [-36.110833331093318, -8.394166658364554],
              [-36.110833332891957, -8.392499992480623],
              [-36.111666666283554, -8.392499992480623],
              [-36.111666666283554, -8.389999993205095],
              [-36.113333331268166, -8.389999993205095],
              [-36.113333331268166, -8.389166658914121],
              [-36.114999999850056, -8.389166658914121],
              [-36.114999998051417, -8.388333325522524],
              [-36.117499998226322, -8.388333325522524],
              [-36.117499998226322, -8.38749999213087],
              [-36.119166667707532, -8.38749999303019],
              [-36.119166665908836, -8.386666658739273],
              [-36.120833333591406, -8.386666659638593],
              [-36.120833332692087, -8.385833327146258],
              [-36.12166666608374, -8.385833325347619],
              [-36.12166666698306, -8.384999992855342],
              [-36.122499998576018, -8.384999992855342],
              [-36.122499997676698, -8.383333326072091],
              [-36.12166666698306, -8.383333326072091],
              [-36.12166666608374, -8.382499992680494],
              [-36.120833333591406, -8.382499993579813],
              [-36.120833331792767, -8.38166665838952],
              [-36.120000001099129, -8.38166665838952],
              [-36.119999999300489, -8.380833325897243],
              [-36.119166666808155, -8.380833325897243],
              [-36.119166665908836, -8.376666658939087],
              [-36.11999999840117, -8.376666658939087],
              [-36.11999999840117, -8.374166657864919],
              [-36.12166666788238, -8.374166658764238],
              [-36.12166666698306, -8.373333325372641],
              [-36.122500000374657, -8.373333325372641],
              [-36.122499999475338, -8.372499993779627],
              [-36.123333333766311, -8.372499993779627],
              [-36.123333332866991, -8.37166665858939],
              [-36.125000000549562, -8.37166665858939],
              [-36.124999998750923, -8.370833326097056],
              [-36.1258333312432, -8.370833326097056],
              [-36.1258333312432, -8.37166665858939],
              [-36.127499998925771, -8.37166665948871],
              [-36.128333332317368, -8.37166665858939],
              [-36.128333333216688, -8.370833325197736],
              [-36.129166664809702, -8.370833326996376],
              [-36.129166667507661, -8.369166658414485],
              [-36.129999999100619, -8.369166658414485],
              [-36.130000000899258, -8.367499992530611],
              [-36.130833331592953, -8.367499992530611],
              [-36.130833332492273, -8.366666659138957],
              [-36.131666667682509, -8.366666659138957],
              [-36.13166666678319, -8.36583332664668],
              [-36.132499999275524, -8.36583332574736],
              [-36.132499999275524, -8.364999992355706],
              [-36.134999999450372, -8.364999994154346],
              [-36.134999999450372, -8.36583332574736],
              [-36.136666668032262, -8.36583332574736],
              [-36.136666668032262, -8.366666660038277],
              [-36.138333331218234, -8.366666659138957],
              [-36.138333331218234, -8.367499992530611],
              [-36.139166666408471, -8.367499992530611],
              [-36.139166666408471, -8.368333325922208],
              [-36.140833332292402, -8.368333325922208],
              [-36.140833334091042, -8.369166658414485],
              [-36.141666665684056, -8.369166658414485],
              [-36.141666667482696, -8.369999992705459],
              [-36.143333331567931, -8.369999991806139],
              [-36.14333333246725, -8.370833326097056],
              [-36.144166666758224, -8.370833326097056],
              [-36.144166667657544, -8.37166665858939],
              [-36.145833332642155, -8.37166665858939],
              [-36.145833332642155, -8.372499991980987],
              [-36.146666666033752, -8.372499993779627],
              [-36.146666667832392, -8.373333325372641],
              [-36.148333333716323, -8.373333327171281],
              [-36.148333332817003, -8.374166659663558],
              [-36.149166665309338, -8.374166658764238],
              [-36.149166665309338, -8.374999993055212],
              [-36.150833332092532, -8.374999993055212],
              [-36.150833332991908, -8.374166658764238],
              [-36.151666667282825, -8.374166658764238],
              [-36.151666665484186, -8.373333327171281],
              [-36.152499999775102, -8.373333325372641],
              [-36.152500000674422, -8.37166665858939],
              [-36.154166667457673, -8.37166665858939],
              [-36.154166667457673, -8.369999992705459],
              [-36.155833333341604, -8.369999992705459],
              [-36.155833333341604, -8.369166660213125],
              [-36.158333331717813, -8.369166657515166],
              [-36.15833333261719, -8.367499992530611],
              [-36.160833332792038, -8.367499992530611],
              [-36.160833330993398, -8.366666659138957],
              [-36.161666667082955, -8.366666659138957],
              [-36.161666667982274, -8.36583332574736],
              [-36.162500000474608, -8.365833329344639],
              [-36.16416666635854, -8.365833332941918],
              [-36.165833332242471, -8.36583332574736],
              [-36.165833332242471, -8.364999992355706],
              [-36.166666666533388, -8.364999992355706],
              [-36.166666665634068, -8.364166658964109],
              [-36.167500000824361, -8.364166659863429],
              [-36.167499999924985, -8.362499993080178],
              [-36.169166666708236, -8.362499993979498],
              [-36.169166667607556, -8.361666658789204],
              [-36.16999999830125, -8.361666658789204],
              [-36.17000000009989, -8.360833325397607],
              [-36.174999998650947, -8.360833325397607],
              [-36.174999998650947, -8.361666657889884],
              [-36.1758333320426, -8.361666659688524],
              [-36.1758333320426, -8.360833325397607],
              [-36.179166665609102, -8.360833325397607],
              [-36.179166665609102, -8.35999999290533],
              [-36.1799999990007, -8.35999999290533],
              [-36.180000000799339, -8.359166658614356],
              [-36.180833333291673, -8.359166659513676],
              [-36.180833332392353, -8.358333326122079],
              [-36.181666665783951, -8.358333326122079],
              [-36.18166666668327, -8.355833325947174],
              [-36.182499998276228, -8.355833325947174],
              [-36.182499998276228, -8.354999992555577],
              [-36.183333332567202, -8.354999992555577],
              [-36.183333331667882, -8.354166659163923],
              [-36.184166665958799, -8.354166659163923],
              [-36.184166666858118, -8.354999992555577],
              [-36.186666667932343, -8.354999992555577],
              [-36.186666667033023, -8.354166659163923],
              [-36.191666667382776, -8.354166660063242],
              [-36.19166666558408, -8.357499992730425],
              [-36.190833331293163, -8.357499992730425],
              [-36.190833331293163, -8.358333325222759],
              [-36.190000000599525, -8.358333326122079],
              [-36.189999998800886, -8.359166658614356],
              [-36.192499999875054, -8.359166658614356],
              [-36.192499999875054, -8.35999999290533],
              [-36.193333333266651, -8.35999999290533],
              [-36.193333331468011, -8.361666658789204],
              [-36.194166666658305, -8.361666658789204],
              [-36.194166665758985, -8.362499993080178],
              [-36.195000000949221, -8.362499992180858],
              [-36.195000000049902, -8.363333325572455],
              [-36.195833331642916, -8.363333325572455],
              [-36.195833332542236, -8.364166658964109],
              [-36.198333331817764, -8.364166658964109],
              [-36.198333331817764, -8.364999991456386],
              [-36.199999998601015, -8.364999992355706],
              [-36.199999999500335, -8.36583332664668],
              [-36.202499999675183, -8.36583332484804],
              [-36.202499999675183, -8.364999992355706],
              [-36.203333333066837, -8.364999994154346],
              [-36.203333331268198, -8.364166658964109],
              [-36.205833332342365, -8.364166658964109],
              [-36.205833333241685, -8.364999992355706],
              [-36.207500000024936, -8.364999994154346],
              [-36.207499998226297, -8.36583332574736],
              [-36.208333333416533, -8.36583332574736],
              [-36.208333332517213, -8.366666659138957],
              [-36.209166667707507, -8.366666659138957],
              [-36.209166667707507, -8.367499992530611],
              [-36.210000001099104, -8.367499992530611],
              [-36.209999998401145, -8.368333325922208],
              [-36.210833331792799, -8.368333325922208],
              [-36.210833331792799, -8.367499992530611],
              [-36.212499999475369, -8.367499992530611],
              [-36.212499999475369, -8.366666659138957],
              [-36.215000000549537, -8.366666659138957],
              [-36.214999999650217, -8.364166658964109],
              [-36.216666666433468, -8.364166658964109],
              [-36.216666665534149, -8.363333324673135],
              [-36.217499998925746, -8.363333325572455],
              [-36.217499998925746, -8.364166658064789],
              [-36.220833331592928, -8.364166658964109],
              [-36.220833332492248, -8.364999992355706],
              [-36.22416666695807, -8.364999992355706],
              [-36.22416666605875, -8.36583332574736],
              [-36.225833331942681, -8.36583332574736],
              [-36.225833332842001, -8.364166658964109],
              [-36.227499999625252, -8.364166658964109],
              [-36.227499999625252, -8.364999992355706],
              [-36.229166665509183, -8.364999992355706],
              [-36.229166666408503, -8.36583332574736],
              [-36.2299999998001, -8.36583332574736],
              [-36.23000000069942, -8.364999992355706],
              [-36.232499999075628, -8.364999992355706],
              [-36.232500000874325, -8.36583332664668],
              [-36.233333332467282, -8.36583332574736],
              [-36.233333333366602, -8.366666660038277],
              [-36.234166667657519, -8.366666659138957],
              [-36.234166666758199, -8.368333326821528],
              [-36.236666667832424, -8.368333325922208],
              [-36.236666666933104, -8.369166658414485],
              [-36.237499999425381, -8.369166658414485],
              [-36.237499998526062, -8.367499992530611],
              [-36.238333332817035, -8.367499992530611],
              [-36.238333332817035, -8.364999991456386],
              [-36.239166668007272, -8.364999994154346],
              [-36.239166666208632, -8.362499993080178],
              [-36.238333331917715, -8.362499993080178],
              [-36.238333331917715, -8.361666657889884],
              [-36.240833332092564, -8.361666658789204],
              [-36.240833332092564, -8.362499993080178],
              [-36.248333332617165, -8.362499993080178],
              [-36.248333331717845, -8.363333325572455],
              [-36.249166666008762, -8.363333325572455],
              [-36.249166666908081, -8.364166658964109],
              [-36.249999999400416, -8.364166658964109],
              [-36.249999998501096, -8.364999991456386],
              [-36.250833331892693, -8.364999994154346],
              [-36.250833331892693, -8.36583332484804],
              [-36.253333332067598, -8.36583332574736],
              [-36.253333332966918, -8.366666659138957],
              [-36.254999999750169, -8.366666659138957],
              [-36.254999998850849, -8.368333325922208],
              [-36.255833331343126, -8.368333325922208],
              [-36.255833332242446, -8.369166658414485],
              [-36.257499999025697, -8.369166657515166],
              [-36.257499999025697, -8.369999992705459],
              [-36.260833331692879, -8.369999992705459],
              [-36.260833332592199, -8.369166658414485],
              [-36.261666667782436, -8.369166658414485],
              [-36.26250000027477, -8.369166657515166],
              [-36.26249999847613, -8.369999992705459],
              [-36.264166667058021, -8.369999992705459],
              [-36.264166667058021, -8.370833326097056],
              [-36.266666665434229, -8.370833326097056],
              [-36.266666665434229, -8.37166665858939],
              [-36.267499998825826, -8.37166665858939],
              [-36.267499998825826, -8.370833326097056],
              [-36.270833331493009, -8.370833325197736],
              [-36.270833332392328, -8.37166665858939],
              [-36.273333330768537, -8.371666657690071],
              [-36.273333332567177, -8.372499992880307],
              [-36.27416666775747, -8.372499991980987],
              [-36.27416666595883, -8.374166658764238],
              [-36.274999999350428, -8.374166658764238],
              [-36.274999998451108, -8.374999993055212],
              [-36.275833333641401, -8.374999993055212],
              [-36.275833333641401, -8.376666659838406],
              [-36.277499998626013, -8.376666658939087],
              [-36.277500000424652, -8.37749999323006],
              [-36.280833333991154, -8.37749999412938],
              [-36.280833331293138, -8.378333326621657],
              [-36.281666665584112, -8.378333325722338],
              [-36.281666665584112, -8.379999991606269],
              [-36.282499998975709, -8.379999992505589],
              [-36.282499999875029, -8.380833324997923],
              [-36.283333331468043, -8.380833325897243],
              [-36.283333333266683, -8.3816666574902],
              [-36.285833333441531, -8.38166665838952],
              [-36.285833332542211, -8.382499992680494],
              [-36.287499999325462, -8.382499992680494],
              [-36.287499999325462, -8.383333326072091],
              [-36.289166667008033, -8.383333325172771],
              [-36.289166665209393, -8.384166657665048],
              [-36.28999999860099, -8.384166657665048],
              [-36.28999999860099, -8.385833325347619],
              [-36.29000000039963, -8.386666658739273],
              [-36.2916666680822, -8.386666658739273],
              [-36.291666666283561, -8.388333326421844],
              [-36.292500000574535, -8.388333325522524],
              [-36.292499998775895, -8.389166658914121],
              [-36.294166666458466, -8.389166658914121],
              [-36.294166664659826, -8.389999993205095],
              [-36.294999998950743, -8.389999993205095],
              [-36.294999998950743, -8.391666659089026],
              [-36.295833331443021, -8.391666659089026],
              [-36.295833331443021, -8.392499992480623],
              [-36.296666667532634, -8.392499992480623],
              [-36.296666665733994, -8.39333332587222],
              [-36.295833332342397, -8.39333332587222],
              [-36.295833333241717, -8.394166658364554],
              [-36.294166666458466, -8.394166658364554],
              [-36.294166666458466, -8.395833326047125],
              [-36.293333331268173, -8.395833325147805],
              [-36.293333331268173, -8.396666658539402],
              [-36.292499997876575, -8.396666658539402],
              [-36.292499998775895, -8.397499992830376],
              [-36.291666665384241, -8.397499992830376],
              [-36.291666665384241, -8.399166658714307],
              [-36.288333331817739, -8.399166657814988],
              [-36.288333331817739, -8.398333326221973],
              [-36.287499998426142, -8.398333324423334],
              [-36.287499997526822, -8.402499993180072],
              [-36.286666666833185, -8.402499993180072],
              [-36.286666667732504, -8.403333325672406],
              [-36.287499998426142, -8.403333325672406],
              [-36.287499999325462, -8.404999993354977],
              [-36.286666667732504, -8.404999992455657],
              [-36.286666667732504, -8.406666659238908],
              [-36.285833333441531, -8.406666659238908],
              [-36.285833333441531, -8.407499991731186],
              [-36.285000000949253, -8.407499992630505],
              [-36.285000000049934, -8.408333326022159],
              [-36.28416666665828, -8.408333326022159],
              [-36.28416666665828, -8.409166658514437],
              [-36.283333332367363, -8.409166658514437],
              [-36.283333331468043, -8.409999991906034],
              [-36.28416666665828, -8.409999992805353],
              [-36.28416666665828, -8.410833326197007],
              [-36.283333331468043, -8.410833326197007],
              [-36.283333333266683, -8.411666659588604],
              [-36.28416666665828, -8.411666658689285],
              [-36.284166667557599, -8.416666659039038],
              [-36.283333331468043, -8.416666659039038],
              [-36.283333332367363, -8.418333325822289],
              [-36.282499999875029, -8.418333325822289],
              [-36.282500000774348, -8.419166659213886],
              [-36.279166666308583, -8.419166659213886],
              [-36.279166666308583, -8.420833325097817],
              [-36.27833333291693, -8.420833325997137],
              [-36.278333333816249, -8.421666658489471],
              [-36.276666665234359, -8.421666658489471],
              [-36.276666665234359, -8.422499992780388],
              [-36.274999997551788, -8.422499992780388],
              [-36.274999998451108, -8.423333327071362],
              [-36.27416666865679, -8.423333327071362],
              [-36.274166665059511, -8.424166658664319],
              [-36.273333332567177, -8.424166657764999],
              [-36.273333332567177, -8.424999992955236],
              [-36.272500001873539, -8.424999992955236],
              [-36.27249999827626, -8.42583332544757],
              [-36.271666666683302, -8.42583332454825],
              [-36.271666665783982, -8.427499993130141],
              [-36.270833333291648, -8.427499994029461],
              [-36.270833331493009, -8.428333324723098],
              [-36.269999999900051, -8.428333325622418],
              [-36.269999999000731, -8.429166659014072],
              [-36.269166666508397, -8.429166659014072],
              [-36.269166666508397, -8.43166665918892],
              [-36.269999999900051, -8.43166665918892],
              [-36.269999999900051, -8.432499992580574],
              [-36.270833332392328, -8.432499992580574],
              [-36.270833332392328, -8.434166658464449],
              [-36.269999999000731, -8.434166658464449],
              [-36.269999998101412, -8.438333325422605],
              [-36.269166666508397, -8.438333324523285],
              [-36.269166666508397, -8.439166658814202],
              [-36.26833333401612, -8.439166658814202],
              [-36.268333331318161, -8.440833326496772],
              [-36.266666665434229, -8.440833324698133],
              [-36.266666666333549, -8.443333325772301],
              [-36.267499999725146, -8.443333325772301],
              [-36.267500000624466, -8.445833325947206],
              [-36.268333331318161, -8.445833325947206],
              [-36.268333331318161, -8.448333326122054],
              [-36.267500000624466, -8.448333327021373],
              [-36.267499998825826, -8.449999992905305],
              [-36.268333331318161, -8.449999992905305],
              [-36.26833333401612, -8.449166658614331],
              [-36.269999999900051, -8.449166658614331],
              [-36.269999999000731, -8.456666659138989],
              [-36.270833331493009, -8.456666659138989],
              [-36.270833332392328, -8.458333325922183],
              [-36.271666667582622, -8.458333325922183],
              [-36.271666665783982, -8.459999992705434],
              [-36.272500000074899, -8.459999992705434],
              [-36.272500000974219, -8.460833326097088],
              [-36.273333332567177, -8.460833326996408],
              [-36.273333333466496, -8.462499992880339],
              [-36.27416666685815, -8.462499992880339],
              [-36.27416666685815, -8.46416665876427],
              [-36.275833331842762, -8.46416665876427],
              [-36.275833332742081, -8.466666658939118],
              [-36.274999999350428, -8.466666658939118],
              [-36.275000000249747, -8.470833326796537],
              [-36.275833332742081, -8.470833325897217],
              [-36.275833331842762, -8.472499991781149],
              [-36.276666667932318, -8.472499993579788],
              [-36.276666667032998, -8.473333325172746],
              [-36.279999998800861, -8.473333326072066],
              [-36.279999999700181, -8.471666658389552],
              [-36.280833333991154, -8.471666658389552],
              [-36.280833331293138, -8.470833324997898],
              [-36.282499998975709, -8.470833326796537],
              [-36.282499998975709, -8.46999999250562],
              [-36.283333333266683, -8.46999999250562],
              [-36.283333331468043, -8.469166659113966],
              [-36.28416666665828, -8.469166659113966],
              [-36.28416666575896, -8.468333325722369],
              [-36.285833332542211, -8.468333325722369],
              [-36.285833331642891, -8.467499993230035],
              [-36.286666667732504, -8.467499993230035],
              [-36.286666665933865, -8.463333325372616],
              [-36.287499999325462, -8.463333325372616],
              [-36.287499999325462, -8.460833326097088],
              [-36.288333331817739, -8.460833326097088],
              [-36.288333332717116, -8.459999992705434],
              [-36.289166667907352, -8.459999992705434],
              [-36.289166667907352, -8.459166658414517],
              [-36.28999999950031, -8.459166658414517],
              [-36.28999999950031, -8.457499992530586],
              [-36.289166667907352, -8.457499992530586],
              [-36.289166665209393, -8.455833325747335],
              [-36.291666665384241, -8.455833325747335],
              [-36.2916666680822, -8.451666658789236],
              [-36.292499998775895, -8.451666658789236],
              [-36.292499998775895, -8.450833325397582],
              [-36.295833332342397, -8.450833325397582],
              [-36.295833331443021, -8.449999992905305],
              [-36.297499998226272, -8.449999992905305],
              [-36.297500000024911, -8.449166658614331],
              [-36.300833333591413, -8.449166659513651],
              [-36.300833333591413, -8.449999992905305],
              [-36.308333330518735, -8.449999992905305],
              [-36.308333333216694, -8.450833325397582],
              [-36.309166665709029, -8.450833324498262],
              [-36.309166666608348, -8.449999992005985],
              [-36.311666665883877, -8.449999992905305],
              [-36.311666666783196, -8.450833325397582],
              [-36.313333331767808, -8.450833324498262],
              [-36.313333333566447, -8.451666658789236],
              [-36.314999998551059, -8.451666657889916],
              [-36.314999999450379, -8.449999992905305],
              [-36.317499998725907, -8.449999993804624],
              [-36.317500000524547, -8.449166659513651],
              [-36.321666666583326, -8.449166658614331],
              [-36.321666664784686, -8.448333326122054],
              [-36.32249999907566, -8.448333326122054],
              [-36.32249999997498, -8.444999992555552],
              [-36.323333334265897, -8.444999992555552],
              [-36.323333333366577, -8.443333325772301],
              [-36.32249999907566, -8.443333324872981],
              [-36.32249999907566, -8.44166665898905],
              [-36.323333334265897, -8.44166665898905],
              [-36.323333331567937, -8.435833327046339],
              [-36.324166666758231, -8.435833326147019],
              [-36.324166667657551, -8.434999992755422],
              [-36.324999999250508, -8.434999992755422],
              [-36.324999999250508, -8.433333325972171],
              [-36.325833332642162, -8.433333325972171],
              [-36.325833333541482, -8.43166665918892],
              [-36.326666666933079, -8.43166665918892],
              [-36.326666666933079, -8.430833325797323],
              [-36.327499999425413, -8.430833325797323],
              [-36.32833333371633, -8.430833325797323],
              [-36.32833333371633, -8.429999992405669],
              [-36.329166666208607, -8.429999992405669],
              [-36.329166668007304, -8.428333325622418],
              [-36.331666666383512, -8.428333325622418],
              [-36.331666667282832, -8.427499994029461],
              [-36.33249999887579, -8.427499993130141],
              [-36.332500000674429, -8.426666658839167],
              [-36.33416666655836, -8.426666659738487],
              [-36.33416666655836, -8.42583332544757],
              [-36.334999999950014, -8.42583332544757],
              [-36.335000000849334, -8.424999992955236],
              [-36.335833332442292, -8.424999992955236],
              [-36.335833331542972, -8.424166658664319],
              [-36.336666668531905, -8.424166658664319],
              [-36.336666665833945, -8.423333326172042],
              [-36.338333333516459, -8.423333324373402],
              [-36.33833333171782, -8.422499992780388],
              [-36.339166667807433, -8.422499992780388],
              [-36.339166666008794, -8.421666658489471],
              [-36.340833333691364, -8.421666658489471],
              [-36.340833333691364, -8.416666659039038],
              [-36.339999999400391, -8.416666659039038],
              [-36.339999999400391, -8.41416665886419],
              [-36.341666665284322, -8.41416665886419],
              [-36.341666665284322, -8.413333325472536],
              [-36.342499999575296, -8.413333325472536],
              [-36.342500001373935, -8.412499992980258],
              [-36.343333332067573, -8.412499992080939],
              [-36.343333332966893, -8.411666659588604],
              [-36.344166666358547, -8.411666658689285],
              [-36.344166667257866, -8.410833326197007],
              [-36.344999998850824, -8.410833326197007],
              [-36.345000000649463, -8.409999993704673],
              [-36.346666666533395, -8.409999992805353],
              [-36.346666665634075, -8.409166658514437],
              [-36.347499999025672, -8.409166657615117],
              [-36.347499999025672, -8.408333326022159],
              [-36.348333333316646, -8.408333326022159],
              [-36.348333333316646, -8.409166659413756],
              [-36.349166667607562, -8.409166658514437],
              [-36.349166665808923, -8.409999992805353],
              [-36.350000000099897, -8.409999992805353],
              [-36.349999998301257, -8.41416665886419],
              [-36.350833332592174, -8.41416665886419],
              [-36.350833332592174, -8.414999993155106],
              [-36.351666667782467, -8.414999994054426],
              [-36.351666666883148, -8.416666659039038],
              [-36.352499998476105, -8.416666659039038],
              [-36.352499998476105, -8.418333324922969],
              [-36.353333332767022, -8.418333324922969],
              [-36.353333332767022, -8.419166659213886],
              [-36.354166665259356, -8.419166659213886],
              [-36.354166667057996, -8.421666658489471],
              [-36.353333331867702, -8.421666658489471],
              [-36.353333333666399, -8.423333326172042],
              [-36.354166664360037, -8.423333326172042],
              [-36.354166665259356, -8.424166658664319],
              [-36.354999999550273, -8.424166658664319],
              [-36.354999999550273, -8.42583332544757],
              [-36.356666666333524, -8.42583332544757],
              [-36.356666667232844, -8.426666658839167],
              [-36.357500000624498, -8.426666658839167],
              [-36.357499999725178, -8.427499993130141],
              [-36.358333332217455, -8.427499992230821],
              [-36.358333334016095, -8.429999992405669],
              [-36.359166667407749, -8.429999992405669],
              [-36.359166665609109, -8.430833325797323],
              [-36.360833331492984, -8.430833325797323],
              [-36.360833331492984, -8.43166665918892],
              [-36.362499998276235, -8.43166665918892],
              [-36.362499997376915, -8.432499992580574],
              [-36.36499999845114, -8.432499992580574],
              [-36.365000000249779, -8.430833325797323],
              [-36.365833332742056, -8.430833324898003],
              [-36.365833331842737, -8.427499992230821],
              [-36.366666665234391, -8.427499992230821],
              [-36.36666666793235, -8.426666658839167],
              [-36.369999997901516, -8.426666657939847],
              [-36.369999999700156, -8.42583332544757],
              [-36.37083333129317, -8.42583332544757],
              [-36.370833333091809, -8.424999992955236],
              [-36.372499998975741, -8.424999992955236],
              [-36.372499998975741, -8.424166658664319],
              [-36.374166665758992, -8.424166658664319],
              [-36.374166665758992, -8.422499992780388],
              [-36.375833331642923, -8.422499992780388],
              [-36.375833333441562, -8.41999999260554],
              [-36.37666666593384, -8.41999999260554],
              [-36.376666667732479, -8.419166659213886],
              [-36.377500000224813, -8.419166659213886],
              [-36.377500000224813, -8.418333325822289],
              [-36.378333333616411, -8.418333326721609],
              [-36.378333331817771, -8.416666659039038],
              [-36.379166667907327, -8.416666658139718],
              [-36.379166665209368, -8.41583332654676],
              [-36.380833333791259, -8.415833325647441],
              [-36.380833333791259, -8.414999993155106],
              [-36.381666666283593, -8.414999993155106],
              [-36.381666665384273, -8.41416665886419],
              [-36.38250000057451, -8.41416665886419],
              [-36.38249999967519, -8.412499992980258],
              [-36.383333333066844, -8.412499992080939],
              [-36.383333331268204, -8.410833326197007],
              [-36.384166666458441, -8.410833326197007],
              [-36.384166665559121, -8.407499992630505],
              [-36.384999998950775, -8.407499991731186],
              [-36.385000001648734, -8.406666657440269],
              [-36.385833331443052, -8.406666659238908],
              [-36.385833331443052, -8.404166659064003],
              [-36.385000000749415, -8.404166659064003],
              [-36.385000000749415, -8.402499993180072],
              [-36.384166667357761, -8.402499993180072],
              [-36.384166665559121, -8.400833326396821],
              [-36.383333333066844, -8.400833325497501],
              [-36.383333331268204, -8.399999993904544],
              [-36.38249999967519, -8.399999993005224],
              [-36.38249999877587, -8.398333326221973],
              [-36.381666668082232, -8.398333326221973],
              [-36.381666665384273, -8.397499994629015],
              [-36.383333331268204, -8.397499992830376],
              [-36.383333333066844, -8.396666657640083],
              [-36.385833331443052, -8.396666658539402],
              [-36.385833332342372, -8.395833326047125],
              [-36.38833333341654, -8.395833326047125],
              [-36.38833333341654, -8.396666658539402],
              [-36.389166665908874, -8.396666658539402],
              [-36.389166666808194, -8.397499992830376],
              [-36.389999998401152, -8.397499992830376],
              [-36.389999999300471, -8.399166658714307],
              [-36.390833332692125, -8.399166659613627],
              [-36.390833330893486, -8.399999993005224],
              [-36.391666666083722, -8.399999993005224],
              [-36.391666667882362, -8.400833324598182],
              [-36.393333331967654, -8.400833326396821],
              [-36.393333332866973, -8.401666659788475],
              [-36.394999999650224, -8.401666658889155],
              [-36.394999998750905, -8.400833324598182],
              [-36.396666667332795, -8.400833326396821],
              [-36.396666667332795, -8.399999993005224],
              [-36.398333332317407, -8.399999993005224],
              [-36.398333332317407, -8.399166657814988],
              [-36.399999999100658, -8.399166658714307],
              [-36.399999997302018, -8.399999993005224],
              [-36.401666666783228, -8.399999993005224],
              [-36.401666666783228, -8.400833325497501],
              [-36.403333331767783, -8.400833325497501],
              [-36.403333332667103, -8.401666658889155],
              [-36.404166666058757, -8.401666658889155],
              [-36.404166667857396, -8.402499993180072],
              [-36.405833333741327, -8.402499993180072],
              [-36.405833332842008, -8.403333325672406],
              [-36.407499998725939, -8.403333325672406],
              [-36.407499999625259, -8.405833325847254],
              [-36.408333333016856, -8.405833325847254],
              [-36.408333333016856, -8.406666659238908],
              [-36.40916666640851, -8.406666659238908],
              [-36.40916666550919, -8.408333326022159],
              [-36.409999999800107, -8.408333326022159],
              [-36.410000000699426, -8.409166659413756],
              [-36.411666666583358, -8.409166658514437],
              [-36.411666665684038, -8.409999992805353],
              [-36.412499999075635, -8.409999992805353],
              [-36.412499999974955, -8.410833326197007],
              [-36.413333334265928, -8.410833326197007],
              [-36.41499999925054, -8.410833326197007],
              [-36.414999997451901, -8.409999992805353],
              [-36.415833331742817, -8.409999992805353],
              [-36.415833333541457, -8.410833326197007],
              [-36.41666666783243, -8.410833326197007],
              [-36.416666666933111, -8.411666658689285],
              [-36.42083333389121, -8.411666659588604],
              [-36.42083333209257, -8.410833326197007],
              [-36.42666666583392, -8.410833326197007],
              [-36.42666666583392, -8.409999992805353],
              [-36.427499999225518, -8.409999992805353],
              [-36.427499999225518, -8.409166658514437],
              [-36.429999999400422, -8.409166659413756],
              [-36.429999999400422, -8.408333326022159],
              [-36.433333332067605, -8.408333326022159],
              [-36.433333332067605, -8.407499992630505],
              [-36.434166665459202, -8.407499992630505],
              [-36.434166667257841, -8.406666657440269],
              [-36.434999999750119, -8.406666660138228],
              [-36.434999998850799, -8.405833325847254],
              [-36.435833332242453, -8.405833325847254],
              [-36.435833332242453, -8.404999992455657],
              [-36.436666667432689, -8.404999991556338],
              [-36.43666666563405, -8.403333325672406],
              [-36.437500000824343, -8.403333325672406],
              [-36.437499999925024, -8.402499993180072],
              [-36.438333332417301, -8.402499993180072],
              [-36.438333331517981, -8.400833325497501],
              [-36.439166665808955, -8.400833325497501],
              [-36.439166665808955, -8.399166658714307],
              [-36.439999998301232, -8.399166658714307],
              [-36.439999998301232, -8.397499992830376],
              [-36.440833332592206, -8.397499991931056],
              [-36.440833331692886, -8.396666658539402],
              [-36.441666665983803, -8.396666658539402],
              [-36.441666666883123, -8.394999992655471],
              [-36.442500000274777, -8.394999992655471],
              [-36.4424999993754, -8.394166660163194],
              [-36.44416666795729, -8.394166658364554],
              [-36.444166667057971, -8.392499992480623],
              [-36.443333332767054, -8.392499992480623],
              [-36.443333332767054, -8.388333324623204],
              [-36.44249999847608, -8.388333325522524],
              [-36.44249999847608, -8.38749999303019],
              [-36.441666665983803, -8.38749999213087],
              [-36.441666666883123, -8.386666657839953],
              [-36.439999998301232, -8.386666658739273],
              [-36.440000000099872, -8.385833325347619],
              [-36.439166667607594, -8.385833325347619],
              [-36.439166666708275, -8.384999992855342],
              [-36.437499999025704, -8.384999992855342],
              [-36.437499999025704, -8.383333325172771],
              [-36.439166665808955, -8.383333326072091],
              [-36.439166667607594, -8.380833324997923],
              [-36.440000000999191, -8.380833324997923],
              [-36.440000000099872, -8.378333325722338],
              [-36.440833332592206, -8.378333325722338],
              [-36.440833332592206, -8.375833325547489],
              [-36.441666666883123, -8.375833325547489],
              [-36.441666666883123, -8.374166658764238],
              [-36.441666667782442, -8.373333325372641],
              [-36.442500002073416, -8.373333327171281],
              [-36.4424999993754, -8.372499992880307],
              [-36.444999999550305, -8.372499992880307],
              [-36.444999998650985, -8.37166665858939],
              [-36.446666666333556, -8.371666657690071],
              [-36.446666667232876, -8.370833326996376],
              [-36.447499998825833, -8.370833326097056],
              [-36.447500001523792, -8.369999992705459],
              [-36.449166666508404, -8.369999991806139],
              [-36.449166666508404, -8.369166658414485],
              [-36.451666667582572, -8.369166658414485],
              [-36.451666666683252, -8.369999992705459],
              [-36.452500000974226, -8.369999991806139],
              [-36.452499998276267, -8.367499992530611],
              [-36.453333331667864, -8.367499992530611],
              [-36.453333332567183, -8.366666657340318],
              [-36.454166666858157, -8.366666659138957],
              [-36.454166665958837, -8.36583332574736],
              [-36.455000000249754, -8.36583332574736],
              [-36.454999999350434, -8.364999992355706],
              [-36.456666667033005, -8.364999992355706],
              [-36.456666665234366, -8.364166658964109],
              [-36.460833331293145, -8.364166658964109],
              [-36.460833330393825, -8.362499993080178],
              [-36.461666665584119, -8.362499993080178],
              [-36.461666667382758, -8.361666658789204],
              [-36.465833331642898, -8.361666659688524],
              [-36.465833333441537, -8.362499993080178],
              [-36.466666665933815, -8.362499993080178],
              [-36.466666665933815, -8.363333325572455],
              [-36.470000000399637, -8.363333325572455],
              [-36.469999998600997, -8.364166658964109],
              [-36.472499999675222, -8.364166658964109],
              [-36.472499998775902, -8.364999991456386],
              [-36.476666667532641, -8.364999992355706],
              [-36.476666666633321, -8.36583332484804],
              [-36.477499998226278, -8.36583332484804],
              [-36.477499998226278, -8.366666659138957],
              [-36.478333333416572, -8.366666659138957],
              [-36.478333332517252, -8.368333325922208],
              [-36.48083333179278, -8.368333325922208],
              [-36.48083333359142, -8.367499992530611],
              [-36.481666666983074, -8.367499992530611],
              [-36.481666666983074, -8.36583332574736],
              [-36.483333332866948, -8.36583332574736],
              [-36.483333332866948, -8.366666659138957],
              [-36.48666666733277, -8.366666659138957],
              [-36.48666666733277, -8.36583332664668],
              [-36.489166665709035, -8.36583332574736],
              [-36.489166667507675, -8.366666659138957],
              [-36.49083333159291, -8.366666660038277],
              [-36.49083333249223, -8.367499992530611],
              [-36.492499999275481, -8.367499992530611],
              [-36.49250000107412, -8.368333325922208],
              [-36.493333333566454, -8.368333325922208],
              [-36.493333331767815, -8.369166660213125],
              [-36.494166667857371, -8.36916666470978],
              [-36.499166666408485, -8.369166658414485],
              [-36.499166667307804, -8.367499992530611],
              [-36.500000001598778, -8.367499992530611],
              [-36.499999998900762, -8.366666659138957],
              [-36.500833331393096, -8.366666659138957],
              [-36.500833332292416, -8.36583332574736],
              [-36.500000000699401, -8.36583332574736],
              [-36.499999998900762, -8.364999991456386],
              [-36.500833332292416, -8.364999992355706],
              [-36.500833332292416, -8.364166658964109],
              [-36.502499999974987, -8.364166658964109],
              [-36.502499999974987, -8.363333324673135],
              [-36.503333333366584, -8.363333325572455],
              [-36.503333333366584, -8.362499993080178],
              [-36.502499999974987, -8.362499992180858],
              [-36.502499999974987, -8.361666658789204],
              [-36.503333332467264, -8.361666658789204],
              [-36.503333332467264, -8.359166659513676],
              [-36.504166666758238, -8.359166658614356],
              [-36.504166667657557, -8.358333326122079],
              [-36.504999998351195, -8.358333326122079],
              [-36.504999998351195, -8.357499992730425],
              [-36.505833331742849, -8.357499992730425],
              [-36.505833331742849, -8.356666658439508],
              [-36.510833332991865, -8.356666658439508],
              [-36.510833333891185, -8.355833325947174],
              [-36.511666667282839, -8.355833325047854],
              [-36.511666667282839, -8.356666658439508],
              [-36.512499999775116, -8.356666657540188],
              [-36.514166665659047, -8.356666658439508],
              [-36.514166666558367, -8.355833325947174],
              [-36.515833332442298, -8.355833325947174],
              [-36.515833332442298, -8.354999992555577],
              [-36.517499999225549, -8.354999992555577],
              [-36.517499999225549, -8.352499994179368],
              [-36.518333332617146, -8.352499992380672],
              [-36.518333331717827, -8.351666658089755],
              [-36.519166664210161, -8.351666658089755],
              [-36.51916666780744, -8.350833326496797],
              [-36.520000000299717, -8.350833325597478],
              [-36.520000000299717, -8.348333325422573],
              [-36.519166664210161, -8.348333324523253],
              [-36.5191666660088, -8.346666658639322],
              [-36.518333332617146, -8.346666658639322],
              [-36.518333332617146, -8.345833326147044],
              [-36.515833333341618, -8.345833326147044],
              [-36.515833331542979, -8.34499999275539],
              [-36.514999999950021, -8.34499999275539],
              [-36.515000000849341, -8.344166658464474],
              [-36.512499999775116, -8.344166658464474],
              [-36.512499999775116, -8.343333325972196],
              [-36.511666667282839, -8.343333326871516],
              [-36.511666667282839, -8.339999992405694],
              [-36.509999998700948, -8.339999992405694],
              [-36.509999998700948, -8.33916665901404],
              [-36.509166666208614, -8.33916665901404],
              [-36.509166665309294, -8.336666658839192],
              [-36.508333333716337, -8.336666658839192],
              [-36.508333332817017, -8.334999992955261],
              [-36.509166665309294, -8.334999992955261],
              [-36.509166665309294, -8.331666658489439],
              [-36.508333331917697, -8.331666658489439],
              [-36.508333331917697, -8.326666659039063],
              [-36.509166668007254, -8.326666659039063],
              [-36.509166665309294, -8.325833325647409],
              [-36.510833330293906, -8.325833325647409],
              [-36.510833332991865, -8.324166658864158],
              [-36.509999998700948, -8.324166658864158],
              [-36.509999997801629, -8.322499992980227],
              [-36.509166665309294, -8.322499992080907],
              [-36.509166665309294, -8.32166665778999],
              [-36.507499999425363, -8.321666664085228],
              [-36.507499999425363, -8.320833326196976],
              [-36.508333332817017, -8.320833324398336],
              [-36.508333331917697, -8.319999992805378],
              [-36.509166665309294, -8.319999992805378],
              [-36.509166666208614, -8.319166658514405],
              [-36.511666666383519, -8.319166659413781],
              [-36.511666666383519, -8.318333326022127],
              [-36.514166667457687, -8.318333326921447],
              [-36.514166665659047, -8.31749999263053],
              [-36.514999999050644, -8.31749999263053],
              [-36.514999999050644, -8.315833325847279],
              [-36.515833333341618, -8.315833325847279],
              [-36.515833331542979, -8.316666657440237],
              [-36.520000000299717, -8.316666659238876],
              [-36.520000000299717, -8.31749999263053],
              [-36.521666665284329, -8.31749999263053],
              [-36.521666667982288, -8.318333326022127],
              [-36.524166665459177, -8.318333326022127],
              [-36.524166667257816, -8.319166658514405],
              [-36.529999998301264, -8.319166658514405],
              [-36.529999998301264, -8.318333326022127],
              [-36.532499999375432, -8.318333326022127],
              [-36.532499999375432, -8.31749999263053],
              [-36.533333331867709, -8.31749999263053],
              [-36.533333332767029, -8.315833325847279],
              [-36.532499999375432, -8.315833325847279],
              [-36.532500000274752, -8.314999992455625],
              [-36.530833331692861, -8.314999991556306],
              [-36.530833332592181, -8.314166659064028],
              [-36.529999998301264, -8.314166658164709],
              [-36.529999997401887, -8.313333325672374],
              [-36.528333330618693, -8.313333324773055],
              [-36.528333331518013, -8.3116666597885],
              [-36.52916666580893, -8.311666658889123],
              [-36.52916666580893, -8.309166659613595],
              [-36.529999998301264, -8.309166659613595],
              [-36.529999998301264, -8.307499991931024],
              [-36.52916666670825, -8.307499992830344],
              [-36.52916666670825, -8.306666658539427],
              [-36.528333329719374, -8.306666658539427],
              [-36.528333332417333, -8.305833326047093],
              [-36.526666664734762, -8.305833326047093],
              [-36.526666664734762, -8.304999992655496],
              [-36.52499999975015, -8.304999992655496],
              [-36.524999998850831, -8.301666659088994],
              [-36.524166665459177, -8.301666658189674],
              [-36.524166667257816, -8.299999994104382],
              [-36.523333333866219, -8.299999991406423],
              [-36.523333332966899, -8.299166658914146],
              [-36.522499998675983, -8.299166658014826],
              [-36.522500001373942, -8.297499993929534],
              [-36.523333332966899, -8.297499993030215],
              [-36.52333333206758, -8.296666657839921],
              [-36.527499999924999, -8.296666658739241],
              [-36.527499999025679, -8.297499993030215],
              [-36.532499999375432, -8.297499993030215],
              [-36.532500000274752, -8.298333325522492],
              [-36.533333330968389, -8.298333325522492],
              [-36.533333332767029, -8.299166658914146],
              [-36.536666667232851, -8.299166658914146],
              [-36.536666666333531, -8.297499993030215],
              [-36.537500000624505, -8.297499993929534],
              [-36.537499998825865, -8.298333325522492],
              [-36.53916666470974, -8.298333326421812],
              [-36.539166665609059, -8.299166659813466],
              [-36.539999999900033, -8.299166658914146],
              [-36.539999999000713, -8.300833325697397],
              [-36.54083333239231, -8.300833326596717],
              [-36.54083333329163, -8.301666659088994],
              [-36.542499997376922, -8.301666659088994],
              [-36.542499998276242, -8.300833325697397],
              [-36.543333332567215, -8.300833325697397],
              [-36.543333331667895, -8.299166659813466],
              [-36.544166666858132, -8.299166658914146],
              [-36.544166666858132, -8.300833325697397],
              [-36.546666665234397, -8.300833325697397],
              [-36.546666667033037, -8.302499993379968],
              [-36.549166665409246, -8.302499992480648],
              [-36.550000001498802, -8.302499991581271],
              [-36.549999999700162, -8.301666659088994],
              [-36.550833330393857, -8.301666659088994],
              [-36.550833333991136, -8.300833325697397],
              [-36.553333332367345, -8.300833325697397],
              [-36.553333332367345, -8.299166658914146],
              [-36.555833331642873, -8.299166658914146],
              [-36.555833331642873, -8.299999993205063],
              [-36.557499999325444, -8.299999993205063],
              [-36.557499999325444, -8.300833326596717],
              [-36.559166667907334, -8.300833325697397],
              [-36.559166665209375, -8.299999993205063],
              [-36.562499999675197, -8.299999993205063],
              [-36.562499999675197, -8.300833325697397],
              [-36.564166665559128, -8.300833324798077],
              [-36.564166667357767, -8.299999991406423],
              [-36.565833331443059, -8.299999993205063],
              [-36.565833333241699, -8.289999991606294],
              [-36.566666665733976, -8.289999992505614],
              [-36.566666668431935, -8.288333325722363],
              [-36.564166666458448, -8.288333325722363],
              [-36.564166665559128, -8.285833325547515],
              [-36.563333330368835, -8.285833325547515],
              [-36.563333333066851, -8.28499999305518],
              [-36.562500000574516, -8.28499999305518],
              [-36.562499999675197, -8.284166658764264],
              [-36.56166666538428, -8.284166658764264],
              [-36.56166666538428, -8.28333332537261],
              [-36.560833331992626, -8.28333332537261],
              [-36.560833332891946, -8.282499992880332],
              [-36.559166667008014, -8.282499992880332],
              [-36.559166667907334, -8.281666659488678],
              [-36.559166667907334, -8.280833326097081],
              [-36.560000001298988, -8.280833326097081],
              [-36.559999998601029, -8.279999991806108],
              [-36.563333333066851, -8.279999991806108],
              [-36.563333331268154, -8.280833326097081],
              [-36.564999998950725, -8.280833326996401],
              [-36.565000000749365, -8.279999993604747],
              [-36.566666667532616, -8.279999992705427],
              [-36.566666665733976, -8.279166657515191],
              [-36.569999999300478, -8.27916665841451],
              [-36.569999998401158, -8.279999992705427],
              [-36.571666667882369, -8.279999992705427],
              [-36.571666666083729, -8.27916665841451],
              [-36.57333333286698, -8.27916665841451],
              [-36.57333333286698, -8.277499991631259],
              [-36.574166665359257, -8.277499992530579],
              [-36.574166666258577, -8.274999992355731],
              [-36.574999998750911, -8.274999991456411],
              [-36.574999998750911, -8.274166659863397],
              [-36.575833333041828, -8.274166658964077],
              [-36.575833333941148, -8.270833325397575],
              [-36.576666666433482, -8.270833325397575],
              [-36.576666665534162, -8.269999992905298],
              [-36.577499998925759, -8.269999992005978],
              [-36.577500000724399, -8.268333326122047],
              [-36.578333333216733, -8.268333326122047],
              [-36.578333332317413, -8.266666658439476],
              [-36.57916666750765, -8.266666660238116],
              [-36.57916666570901, -8.263333325772294],
              [-36.577499998925759, -8.263333325772294],
              [-36.577499999825079, -8.262499992380697],
              [-36.576666667332802, -8.262499992380697],
              [-36.576666666433482, -8.260833324698126],
              [-36.580000000899304, -8.260833325597446],
              [-36.58, -8.259999993105168],
              [-36.580833331592942, -8.259999993105168],
              [-36.580833333391581, -8.257499992930263],
              [-36.582499998376193, -8.257499993829583],
              [-36.582499998376193, -8.256666658639347],
              [-36.58333333176779, -8.256666658639347],
              [-36.583333333566429, -8.255833326147012],
              [-36.584166667857403, -8.255833326147012],
              [-36.584166667857403, -8.254999992755415],
              [-36.58333333176779, -8.254999993654735],
              [-36.583333333566429, -8.254166658464442],
              [-36.581666667682498, -8.254166658464442],
              [-36.581666668581875, -8.252499992580567],
              [-36.579999999100608, -8.252499992580567],
              [-36.58, -8.250833325797316],
              [-36.580833331592942, -8.250833324897997],
              [-36.580833332492261, -8.247499993130134],
              [-36.581666665883859, -8.247499993130134],
              [-36.581666665883859, -8.24666665883916],
              [-36.582499998376193, -8.24666665883916],
              [-36.582500001074152, -8.247499993130134],
              [-36.583333333566429, -8.247499992230814],
              [-36.58333333176779, -8.248333324723092],
              [-36.584166667857403, -8.248333325622411],
              [-36.584166666058763, -8.247499993130134],
              [-36.586666666233612, -8.247499992230814],
              [-36.586666665334292, -8.246666657939841],
              [-36.587499998725889, -8.24666665883916],
              [-36.593333333366616, -8.24666665883916],
              [-36.593333331567976, -8.247499993130134],
              [-36.592499999075642, -8.247499993130134],
              [-36.592500000874281, -8.249166659014065],
              [-36.591666665684045, -8.249166659014065],
              [-36.591666665684045, -8.249999992405662],
              [-36.592499999974962, -8.249999992405662],
              [-36.592499999974962, -8.251666659188913],
              [-36.593333331567976, -8.251666659188913],
              [-36.593333333366616, -8.252499993479887],
              [-36.594166666758213, -8.252499992580567],
              [-36.594166665858893, -8.253333325972164],
              [-36.596666666033741, -8.253333325972164],
              [-36.596666666933118, -8.252499991681248],
              [-36.604166667457719, -8.252499992580567],
              [-36.604166665659022, -8.253333325972164],
              [-36.607499998326205, -8.253333325972164],
              [-36.607499998326205, -8.254166658464442],
              [-36.614166667257848, -8.254166657565122],
              [-36.614166666358528, -8.254999992755415],
              [-36.617499999025711, -8.254999992755415],
              [-36.617499999025711, -8.254166658464442],
              [-36.619999998301239, -8.254166658464442],
              [-36.620000000099878, -8.254999992755415],
              [-36.62166666598381, -8.254999992755415],
              [-36.62166666598381, -8.255833326147012],
              [-36.624999998650992, -8.255833326147012],
              [-36.625000000449631, -8.256666658639347],
              [-36.625833332042589, -8.256666658639347],
              [-36.625833332042589, -8.255833326147012],
              [-36.626666665434243, -8.255833326147012],
              [-36.626666667232882, -8.254999992755415],
              [-36.628333331318117, -8.254999992755415],
              [-36.628333331318117, -8.254166658464442],
              [-36.631666666683259, -8.254166659363818],
              [-36.631666666683259, -8.254999992755415],
              [-36.635000000249761, -8.254999992755415],
              [-36.634999998451121, -8.254166658464442],
              [-36.635833332742095, -8.254166658464442],
              [-36.635833331842775, -8.254999992755415],
              [-36.639999998800874, -8.254999992755415],
              [-36.640000000599514, -8.255833326147012],
              [-36.641666666483445, -8.255833326147012],
              [-36.641666665584125, -8.256666657740027],
              [-36.644166666658293, -8.256666658639347],
              [-36.644166666658293, -8.257499992930263],
              [-36.647499998426156, -8.257499992930263],
              [-36.647499999325476, -8.258333325422598],
              [-36.649166667008046, -8.258333325422598],
              [-36.649166665209407, -8.259166658814195],
              [-36.649999998601004, -8.259166658814195],
              [-36.649999998601004, -8.258333325422598],
              [-36.651666666283575, -8.258333325422598],
              [-36.651666665384255, -8.259166658814195],
              [-36.653333333066826, -8.259166658814195],
              [-36.653333331268186, -8.259999993105168],
              [-36.653333333066826, -8.263333325772294],
              [-36.654166665559103, -8.263333325772294],
              [-36.654166665559103, -8.264166660063267],
              [-36.654999998051437, -8.264166660063267],
              [-36.654999999850077, -8.264999992555545],
              [-36.656666665734008, -8.264999992555545],
              [-36.656666665734008, -8.265833325947199],
              [-36.657500000024925, -8.265833325947199],
              [-36.657500000024925, -8.26749999273045],
              [-36.658333333416579, -8.26749999273045],
              [-36.658333332517259, -8.270833325397575],
              [-36.659166666808176, -8.270833325397575],
              [-36.659166665908856, -8.27333332467316],
              [-36.66416666445997, -8.2733333264718],
              [-36.664166665359289, -8.274166658964077],
              [-36.664999998750886, -8.274166658964077],
              [-36.664999999650206, -8.274999992355731],
              [-36.666666666433457, -8.274999992355731],
              [-36.666666665534137, -8.277499992530579],
              [-36.667500000724431, -8.277499992530579],
              [-36.667499999825111, -8.278333325922233],
              [-36.668333332317388, -8.278333325922233],
              [-36.668333330518749, -8.279999992705427],
              [-36.670000000899279, -8.279999992705427],
              [-36.67, -8.280833326097081],
              [-36.670833331592917, -8.280833325197761],
              [-36.672499999275487, -8.280833326097081],
              [-36.672499999275487, -8.281666658589359],
              [-36.674166666058738, -8.281666658589359],
              [-36.674166666058738, -8.282499992880332],
              [-36.67750000052456, -8.282499993779652],
              [-36.677499998725921, -8.281666658589359],
              [-36.682499999075674, -8.281666658589359],
              [-36.682499999974993, -8.282499992880332],
              [-36.683333332467271, -8.282499992880332],
              [-36.683333333366591, -8.284166658764264],
              [-36.684166667657564, -8.284166659663583],
              [-36.684166665858925, -8.28499999305518],
              [-36.68916666800726, -8.2849999939545],
              [-36.689166665309301, -8.285833325547515],
              [-36.690833332991872, -8.285833325547515],
              [-36.690833332991872, -8.286666658939112],
              [-36.691666665484206, -8.286666658939112],
              [-36.691666665484206, -8.288333325722363],
              [-36.692500000674443, -8.288333325722363],
              [-36.692499998875803, -8.28916665911396],
              [-36.691666666383526, -8.28916665911396],
              [-36.691666666383526, -8.294166658564393],
              [-36.692499998875803, -8.294166657665073],
              [-36.692500000674443, -8.294999992855367],
              [-36.6933333322674, -8.294999992855367],
              [-36.693333331368081, -8.295833325347644],
              [-36.694166665659054, -8.295833325347644],
              [-36.694166666558374, -8.296666658739241],
              [-36.694999999050651, -8.296666657839921],
              [-36.694999999949971, -8.298333325522492],
              [-36.695833331542985, -8.298333325522492],
              [-36.695833333341625, -8.299166658914146],
              [-36.696666666733222, -8.299166658914146],
              [-36.696666667632542, -8.301666659088994],
              [-36.697499999225556, -8.301666659088994],
              [-36.697499999225556, -8.302499992480648],
              [-36.701666667082975, -8.302499992480648],
              [-36.701666665284336, -8.301666659088994],
              [-36.702499997776613, -8.301666659088994],
              [-36.702499999575252, -8.300833325697397],
              [-36.702499998675933, -8.298333325522492],
              [-36.701666667082975, -8.298333325522492],
              [-36.701666665284336, -8.296666658739241],
              [-36.700833331892682, -8.296666658739241],
              [-36.700833330993362, -8.291666658389545],
              [-36.699166666908127, -8.291666657490225],
              [-36.699166666008807, -8.290833325897211],
              [-36.698333331717834, -8.290833325897211],
              [-36.698333332617153, -8.286666658939112],
              [-36.697499999225556, -8.286666658939112],
              [-36.697499998326236, -8.28499999305518],
              [-36.696666667632542, -8.28499999305518],
              [-36.696666666733222, -8.284166658764264],
              [-36.697499998326236, -8.284166658764264],
              [-36.697499997426917, -8.282499991981012],
              [-36.699166667807447, -8.282499992880332],
              [-36.699166666008807, -8.281666657690039],
              [-36.699999999400404, -8.281666658589359],
              [-36.700000000299724, -8.280833325197761],
              [-36.700833331892682, -8.280833326097081],
              [-36.700833332792058, -8.281666658589359],
              [-36.704166665459184, -8.281666658589359],
              [-36.704166666358503, -8.280833325197761],
              [-36.704999997951518, -8.280833326097081],
              [-36.705000001548797, -8.279999992705427],
              [-36.705833332242435, -8.279999991806108],
              [-36.705833331343115, -8.27916665841451],
              [-36.706666665634089, -8.27916665841451],
              [-36.706666666533408, -8.278333326821553],
              [-36.708333333316659, -8.278333325922233],
              [-36.70833333151802, -8.277499991631259],
              [-36.709166665808937, -8.277499992530579],
              [-36.709166667607576, -8.274166658964077],
              [-36.710000000099853, -8.274166659863397],
              [-36.710000000099853, -8.27333332557248],
              [-36.710833331692868, -8.27333332467316],
              [-36.710833333491507, -8.272499993080146],
              [-36.711666666883104, -8.272499992180826],
              [-36.711666667782424, -8.271666658789229],
              [-36.712499997576799, -8.271666658789229],
              [-36.712499997576799, -8.270833324498255],
              [-36.713333331867716, -8.270833325397575],
              [-36.71416666525937, -8.270833325397575],
              [-36.714166667058009, -8.271666658789229],
              [-36.716666666333538, -8.271666658789229],
              [-36.716666666333538, -8.27333332557248],
              [-36.719166666508386, -8.27333332557248],
              [-36.719166666508386, -8.274166658964077],
              [-36.71999999990004, -8.274166658964077],
              [-36.71999999990004, -8.274999992355731],
              [-36.72166666758261, -8.274999992355731],
              [-36.721666665783971, -8.275833325747328],
              [-36.724166666858139, -8.275833325747328],
              [-36.724166665059499, -8.276666659138982],
              [-36.724999999350473, -8.276666659138982],
              [-36.724999999350473, -8.277499992530579],
              [-36.72583333184275, -8.277499992530579],
              [-36.72583333364139, -8.278333325922233],
              [-36.726666667032987, -8.278333325922233],
              [-36.726666667032987, -8.279166657515191],
              [-36.729166666308572, -8.27916665841451],
              [-36.729166666308572, -8.278333325922233],
              [-36.730833333991143, -8.278333325922233],
              [-36.730833331293184, -8.27916665841451],
              [-36.735000000949242, -8.27916665841451],
              [-36.734999998251283, -8.279999991806108],
              [-36.7358333325422, -8.279999992705427],
              [-36.735833334340839, -8.280833326097081],
              [-36.736666665034534, -8.280833325197761],
              [-36.736666665933853, -8.28333332537261],
              [-36.737499999325451, -8.28333332537261],
              [-36.737499998426131, -8.282499991981012],
              [-36.738333331817785, -8.282499991981012],
              [-36.738333332717104, -8.281666659488678],
              [-36.740000000399675, -8.281666658589359],
              [-36.740000000399675, -8.280833326097081],
              [-36.742499999675204, -8.280833326097081],
              [-36.742499998775884, -8.279999992705427],
              [-36.744166666458455, -8.279999991806108],
              [-36.744166667357774, -8.27916665841451],
              [-36.745833331443066, -8.27916665841451],
              [-36.745833332342386, -8.278333325922233],
              [-36.746666665733983, -8.278333325022857],
              [-36.746666665733983, -8.277499992530579],
              [-36.749166665908888, -8.277499992530579],
              [-36.749166666808208, -8.275833326646648],
              [-36.749999999300485, -8.275833325747328],
              [-36.750000001099124, -8.274999992355731],
              [-36.751666667882375, -8.274999992355731],
              [-36.751666666083736, -8.274166658964077],
              [-36.752499998576013, -8.274166658964077],
              [-36.752500000374653, -8.27333332557248],
              [-36.754999998750918, -8.27333332557248],
              [-36.755000000549558, -8.272499993080146],
              [-36.756666666433489, -8.272499993080146],
              [-36.756666665534169, -8.271666659688549],
              [-36.757500000724406, -8.271666658789229],
              [-36.757500000724406, -8.270833325397575],
              [-36.760833331592949, -8.270833325397575],
              [-36.760833332492268, -8.269999992905298],
              [-36.761666667682505, -8.269999992905298],
              [-36.761666666783185, -8.269166657715061],
              [-36.762500001074159, -8.269166659513701],
              [-36.762499999275519, -8.269999992005978],
              [-36.763333331767797, -8.269999992905298],
              [-36.763333331767797, -8.270833325397575],
              [-36.765000000349687, -8.270833325397575],
              [-36.765000000349687, -8.269999992905298],
              [-36.766666665334299, -8.269999992005978],
              [-36.766666668032258, -8.270833325397575],
              [-36.769999998900801, -8.270833325397575],
              [-36.76999999980012, -8.269999992905298],
              [-36.771666667482691, -8.269999993804618],
              [-36.771666665684052, -8.270833324498255],
              [-36.772499999075649, -8.270833325397575],
              [-36.772499997277009, -8.271666658789229],
              [-36.777499998526082, -8.271666658789229],
              [-36.777499999425402, -8.27333332467316],
              [-36.778333333716319, -8.27333332557248],
              [-36.778333332816999, -8.269999992905298],
              [-36.77999999960025, -8.269999993804618],
              [-36.77999999960025, -8.269166658614381],
              [-36.780833332092584, -8.269166658614381],
              [-36.780833332092584, -8.266666660238116],
              [-36.781666666383501, -8.266666658439476],
              [-36.781666666383501, -8.265833325947199],
              [-36.782499998875778, -8.265833325947199],
              [-36.782499998875778, -8.264999992555545],
              [-36.783333331368112, -8.264999992555545],
              [-36.783333334066072, -8.265833325947199],
              [-36.784999999050683, -8.265833325947199],
              [-36.784999999050683, -8.264999992555545],
              [-36.78583333244228, -8.264999992555545],
              [-36.7858333333416, -8.264166659163948],
              [-36.788333332617185, -8.264166659163948],
              [-36.788333330818546, -8.262499994179336],
              [-36.790833333691353, -8.262499992380697],
              [-36.790833331892713, -8.26166665808978],
              [-36.79166666708295, -8.2616666589891],
              [-36.792499999575284, -8.2616666589891],
              [-36.792499999575284, -8.260833325597446],
              [-36.793333332966881, -8.260833325597446],
              [-36.793333332966881, -8.259999993105168],
              [-36.794166665459215, -8.259999994004488],
              [-36.794166665459215, -8.259166658814195],
              [-36.797499999025717, -8.259166659713514],
              [-36.797499999925037, -8.258333325422598],
              [-36.798333331517995, -8.258333325422598],
              [-36.798333331517995, -8.256666657740027],
              [-36.799166667607608, -8.256666659538666],
              [-36.799166666708231, -8.250833325797316],
              [-36.800000000099885, -8.250833325797316],
              [-36.800000000099885, -8.249166659014065],
              [-36.800833333491482, -8.249166659014065],
              [-36.800833331692843, -8.24666665883916],
              [-36.801666665983817, -8.24666665883916],
              [-36.801666667782456, -8.245833326346883],
              [-36.802499999375414, -8.245833324548244],
              [-36.802499998476094, -8.244166658664312],
              [-36.808333334016083, -8.244166658664312],
              [-36.808333331318124, -8.244999992955286],
              [-36.811666665783946, -8.244999992955286],
              [-36.811666665783946, -8.244166659563632],
              [-36.81249999827628, -8.244166658664312],
              [-36.812500000974239, -8.241666659388784],
              [-36.814166665958851, -8.241666658489464],
              [-36.815000000249768, -8.241666658489464],
              [-36.814999999350448, -8.24083332599713],
              [-36.814999999350448, -8.239999992605533],
              [-36.815833331842725, -8.239999992605533],
              [-36.815833332742045, -8.239166659213879],
              [-36.816666667033019, -8.239166659213879],
              [-36.816666667033019, -8.238333324922962],
              [-36.816666667033019, -8.237499992430685],
              [-36.81833333291695, -8.237499992430685],
              [-36.81833333201763, -8.236666658139711],
              [-36.819166669006506, -8.236666659039031],
              [-36.819166667207867, -8.235833325647434],
              [-36.819999999700201, -8.235833325647434],
              [-36.819999998800881, -8.2349999931551],
              [-36.820833333091798, -8.2349999931551],
              [-36.820833331293159, -8.232499992980252],
              [-36.819999998800881, -8.232499992980252],
              [-36.820000000599521, -8.229999992805404],
              [-36.820833333091798, -8.229999993704723],
              [-36.820833333991118, -8.22916665851443],
              [-36.819999998800881, -8.22916665941375],
              [-36.819999999700201, -8.225833324947928],
              [-36.819166665409227, -8.225833325847248],
              [-36.819166666308547, -8.224166659963316],
              [-36.81833333381627, -8.224166658164677],
              [-36.81833333291695, -8.223333325672399],
              [-36.817499999525296, -8.223333325672399],
              [-36.817500000424616, -8.222499993180122],
              [-36.816666665234379, -8.222499991381426],
              [-36.816666665234379, -8.220833325497551],
              [-36.815833330943406, -8.220833325497551],
              [-36.815833331842725, -8.2191666587143],
              [-36.816666667932338, -8.2191666587143],
              [-36.816666665234379, -8.217499992830369],
              [-36.817499998625976, -8.217499992830369],
              [-36.817499998625976, -8.214999992655464],
              [-36.819166667207867, -8.214999992655464],
              [-36.819166666308547, -8.21333332587227],
              [-36.819999998800881, -8.21333332587227],
              [-36.819999999700201, -8.211666659089019],
              [-36.820833333091798, -8.211666659089019],
              [-36.820833333091798, -8.210833324798045],
              [-36.821666667382772, -8.210833325697365],
              [-36.821666665584132, -8.209999993205088],
              [-36.823333333266646, -8.209999993205088],
              [-36.823333331468007, -8.208333325522517],
              [-36.823333331468007, -8.207955515800814],
              [-36.821497904252595, -8.207499993030183],
            ],
          ],
          [
            [
              [-36.714334857301438, -8.154166658664337],
              [-36.715000000449606, -8.154166658664337],
              [-36.715000000178421, -8.153915373795897],
              [-36.714334857301438, -8.154166658664337],
            ],
          ],
          [
            [
              [-35.63824714389051, -8.250843434177114],
              [-35.636580477107316, -8.250843434177114],
              [-35.636580478006636, -8.251676765770071],
              [-35.635747141917022, -8.251676765770071],
              [-35.635747143715662, -8.253343432553322],
              [-35.634913807626106, -8.253343432553322],
              [-35.634913809424745, -8.25584343272817],
              [-35.632413806551881, -8.25584343182885],
              [-35.632499999375455, -8.256666658639347],
              [-35.631580476757563, -8.256676764321185],
              [-35.631580474958923, -8.257510099511421],
              [-35.63074714066795, -8.257510097712782],
              [-35.63074714066795, -8.258343431104436],
              [-35.629913808175672, -8.258343430205116],
              [-35.629913807276353, -8.259176763596713],
              [-35.63074714066795, -8.259176763596713],
              [-35.63074713886931, -8.261676762872241],
              [-35.629913806377033, -8.261676762872241],
              [-35.629913806377033, -8.263343430554812],
              [-35.626580471011891, -8.263343429655492],
              [-35.626580471011891, -8.264176762147827],
              [-35.622413805852432, -8.264176760349187],
              [-35.622499998675949, -8.264999993454865],
              [-35.621580473360098, -8.265010094640104],
              [-35.623363262404609, -8.267097725079907],
              [-35.625623550986063, -8.268791850863181],
              [-35.62794776157989, -8.269562846847862],
              [-35.630173788689376, -8.269690412982129],
              [-35.63306931070008, -8.269856313617936],
              [-35.636002642008293, -8.270498737126957],
              [-35.639033298847608, -8.272167503827177],
              [-35.640589123289374, -8.273669507441753],
              [-35.641961758528282, -8.275323549542122],
              [-35.643533804041795, -8.277587428217203],
              [-35.644705765458639, -8.278841494139613],
              [-35.646882069951516, -8.280504009652304],
              [-35.650793595647883, -8.282174463480658],
              [-35.653189868917252, -8.282561685473354],
              [-35.655386002561897, -8.282287551429306],
              [-35.658747892301335, -8.2810464213552],
              [-35.659755258898031, -8.280838391978136],
              [-35.6631426956788, -8.28144876364945],
              [-35.664800963693494, -8.281632228044828],
              [-35.665630926426047, -8.281386584523204],
              [-35.665990639956021, -8.28107677257492],
              [-35.666332869766109, -8.280509218525594],
              [-35.666367630361833, -8.279581468010292],
              [-35.665925382450382, -8.278008435041215],
              [-35.664410785629229, -8.274811432401634],
              [-35.663890052983504, -8.272105131346677],
              [-35.664322851718168, -8.26886870062998],
              [-35.664799972640594, -8.266725940849426],
              [-35.664954861977264, -8.265038122910994],
              [-35.665010541703111, -8.263900909499341],
              [-35.664828030589092, -8.2627984899583],
              [-35.66402163638935, -8.261106077383545],
              [-35.66316750077948, -8.260072094451573],
              [-35.662293214060526, -8.259599551078395],
              [-35.661271604900605, -8.259277556014354],
              [-35.658665301262602, -8.25883986406842],
              [-35.656485049645255, -8.257623598450266],
              [-35.655747141517338, -8.256676773314382],
              [-35.655388966727401, -8.256091002597657],
              [-35.654999998251299, -8.255833326147012],
              [-35.654913809924324, -8.256676772415062],
              [-35.652413808850156, -8.256676771515743],
              [-35.652413807950836, -8.255843438124145],
              [-35.649080475283654, -8.255843437224826],
              [-35.649080477981613, -8.254176770441575],
              [-35.648247142791377, -8.254176770441575],
              [-35.648333332017614, -8.254999993654735],
              [-35.647413809399723, -8.255010103833172],
              [-35.647413809399723, -8.254176770441575],
              [-35.646580476907445, -8.254176769542255],
              [-35.646666667932323, -8.253333327770804],
              [-35.645747142616472, -8.253343435251281],
              [-35.645747143515791, -8.254176769542255],
              [-35.642413809050026, -8.254176768642935],
              [-35.642413809050026, -8.253343435251281],
              [-35.640747141367456, -8.253343434351962],
              [-35.640747143166095, -8.252510100960365],
              [-35.641580477457012, -8.252510101859684],
              [-35.641580477457012, -8.251676767568711],
              [-35.640747144065415, -8.251676767568711],
              [-35.640747142266775, -8.250010101684779],
              [-35.63824714299119, -8.25001009988614],
              [-35.63824714389051, -8.250843434177114],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '232',
        OBJECTID: 137.0,
        Nome_area: 'Gesseiro',
        COD_area: 'CA143',
        Import_bio: 'Extremamente Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Recuperação de Áreas Degradadas',
        Area_ha: 50056.1245,
        Shape_Leng: 2.40667,
        Shape_Area: 0.041,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.180499650999899, -7.362988933999928],
              [-40.180469069, -7.363000020999942],
              [-40.174952400988623, -7.364999992555568],
              [-40.175833330343892, -7.364999992555568],
              [-40.175833331243211, -7.365833325947165],
              [-40.179166666608353, -7.365833325947165],
              [-40.179166665709033, -7.366666658439499],
              [-40.18000000089927, -7.366666658439499],
              [-40.18, -7.36833332612207],
              [-40.180833331592908, -7.36833332612207],
              [-40.180833332492284, -7.367499992730416],
              [-40.182500001074175, -7.367499992730416],
              [-40.182499999275478, -7.366666657540179],
              [-40.183333332667132, -7.366666658439499],
              [-40.183333331767813, -7.364166659163971],
              [-40.185833331942661, -7.364166659163971],
              [-40.185833331942661, -7.361666658989066],
              [-40.185808872281235, -7.361666658962669],
              [-40.180499650999899, -7.362988933999928],
            ],
          ],
          [
            [
              [-40.127754320999898, -7.38191498999993],
              [-40.118333331986975, -7.382250614966031],
              [-40.118333331717849, -7.382499992880298],
              [-40.121666667082991, -7.382499993779618],
              [-40.121666665284351, -7.383333325372632],
              [-40.122499999575268, -7.383333327171272],
              [-40.122500000474588, -7.382499993779618],
              [-40.124166667257839, -7.382499992880298],
              [-40.1241666654592, -7.385833324648161],
              [-40.125833332242451, -7.38583332554748],
              [-40.125833331343131, -7.387499993230051],
              [-40.127499999025702, -7.387499993230051],
              [-40.127499999025702, -7.388333324823009],
              [-40.128333333316618, -7.388333325722385],
              [-40.128333332417299, -7.389166659113982],
              [-40.12999999830123, -7.389166659113982],
              [-40.130000000099869, -7.388333325722385],
              [-40.13166666688312, -7.388333325722385],
              [-40.13166666778244, -7.387499993230051],
              [-40.130833331692884, -7.387499993230051],
              [-40.130833333491523, -7.386666658039815],
              [-40.130000000099869, -7.386666658939134],
              [-40.12999999830123, -7.38583332554748],
              [-40.130833331692884, -7.3858333264468],
              [-40.130833333491523, -7.384999993954523],
              [-40.130000000099869, -7.384999993055203],
              [-40.130000000099869, -7.384166658764229],
              [-40.129166665808953, -7.384166658764229],
              [-40.129166665808953, -7.382499992880298],
              [-40.128333332417299, -7.382499993779618],
              [-40.128333332220414, -7.381770242572718],
              [-40.127754320999898, -7.38191498999993],
            ],
          ],
          [
            [
              [-40.087454495, -7.383093066999947],
              [-40.076450633, -7.382825740999986],
              [-40.060366184, -7.381688692999938],
              [-40.057307769, -7.381533237999976],
              [-40.057159618, -7.381525707999972],
              [-40.051146785999897, -7.381220083999946],
              [-40.045163712, -7.380744980999952],
              [-40.018333334223783, -7.37702976780229],
              [-40.018333331917688, -7.379166658414533],
              [-40.017499998526091, -7.379166658414533],
              [-40.017499999425411, -7.37999999270545],
              [-40.016666667832396, -7.37999999270545],
              [-40.016666666033757, -7.380833325197727],
              [-40.01583333264216, -7.380833326097047],
              [-40.01583333354148, -7.384166658764229],
              [-40.013333331567935, -7.384166658764229],
              [-40.013333331567935, -7.385833324648161],
              [-40.012499999974978, -7.38583332554748],
              [-40.012500001773617, -7.386666658939134],
              [-40.010833331393087, -7.386666658939134],
              [-40.010833332292407, -7.387499993230051],
              [-40.010000000699449, -7.387499993230051],
              [-40.00999999890081, -7.389166659113982],
              [-40.01166666658338, -7.389166659113982],
              [-40.011666665684004, -7.390833325897233],
              [-40.010833330493767, -7.390833325897233],
              [-40.010833331393087, -7.391666658389511],
              [-40.00999999890081, -7.391666658389511],
              [-40.009999999800129, -7.392499992680484],
              [-40.006666666233627, -7.392499992680484],
              [-40.006666665334308, -7.394166658564416],
              [-40.004999999450376, -7.394166658564416],
              [-40.005000000349696, -7.394999992855332],
              [-40.004166666958099, -7.394999992855332],
              [-40.004166666958099, -7.395833325347667],
              [-40.003333331767806, -7.395833325347667],
              [-40.003333332667125, -7.394999992855332],
              [-40.002499999275528, -7.394999992855332],
              [-40.002499998376209, -7.395833325347667],
              [-40.000833333391597, -7.39583332444829],
              [-40.000833333391597, -7.394999993754652],
              [-39.999166664809707, -7.394999991956013],
              [-39.999166666608346, -7.395833325347667],
              [-39.998333333216692, -7.395833325347667],
              [-39.998333332317372, -7.396666658739264],
              [-39.997500000724415, -7.396666658739264],
              [-39.997499999825095, -7.394999992855332],
              [-39.994166665359273, -7.394999991956013],
              [-39.994166668057233, -7.396666658739264],
              [-39.994166665359273, -7.398333325522515],
              [-39.994166666258593, -7.399166658914112],
              [-39.994999999650247, -7.399166658914112],
              [-39.99499999875087, -7.401666659089017],
              [-39.996666666433441, -7.401666658189697],
              [-39.996666667332761, -7.404166658364545],
              [-39.997499999825095, -7.404166658364545],
              [-39.997500000724415, -7.405833326047116],
              [-39.999166667507666, -7.405833326047116],
              [-39.999166665709026, -7.406666657640073],
              [-39.999999999100623, -7.406666658539393],
              [-40.000000000899263, -7.408333326221964],
              [-39.998333331418053, -7.408333326221964],
              [-39.998333331418053, -7.409166658714298],
              [-39.996666665534121, -7.409166658714298],
              [-39.996666666433441, -7.409999993005215],
              [-39.995833333941164, -7.409999993005215],
              [-39.995833333941164, -7.41249999318012],
              [-39.996666666433441, -7.412499991381424],
              [-39.996666666433441, -7.413333324773077],
              [-39.994166666258593, -7.413333325672397],
              [-39.994166668057233, -7.414999992455648],
              [-39.993333331967676, -7.414999992455648],
              [-39.993333332866996, -7.415833325847245],
              [-39.992499999475342, -7.415833325847245],
              [-39.992499998576022, -7.416666659238899],
              [-39.991666667882384, -7.41666665744026],
              [-39.991666667882384, -7.417499992630496],
              [-39.990833331792771, -7.417499992630496],
              [-39.990833332692091, -7.41833332602215],
              [-39.989999998401174, -7.41833332602215],
              [-39.990000001099133, -7.419166658514428],
              [-39.990833332692091, -7.419166659413747],
              [-39.990833331792771, -7.419999992805401],
              [-39.991666666983065, -7.419999992805401],
              [-39.991666667882384, -7.421666657789956],
              [-39.992500000374662, -7.421666658689276],
              [-39.992499998576022, -7.422499992980249],
              [-39.991666666083745, -7.422499992980249],
              [-39.991666666083745, -7.423333325472527],
              [-39.990833333591411, -7.423333326371846],
              [-39.990833332692091, -7.424166657964861],
              [-39.990000001099133, -7.424166658864181],
              [-39.989999998401174, -7.426666659039029],
              [-39.98916666680816, -7.426666659039029],
              [-39.98916666680816, -7.427499992430683],
              [-39.989999999300494, -7.427499992430683],
              [-39.989999999300494, -7.429999992605531],
              [-39.98916666680816, -7.429999992605531],
              [-39.98916666680816, -7.434999992955284],
              [-39.988333331617923, -7.434999992955284],
              [-39.988333332517243, -7.435833325447561],
              [-39.98916666770748, -7.435833325447561],
              [-39.98916666680816, -7.436666658839215],
              [-39.989999999300494, -7.436666658839215],
              [-39.989999998401174, -7.437499993130132],
              [-39.98916666680816, -7.437499994029451],
              [-39.98916666590884, -7.438333325622409],
              [-39.988333331617923, -7.438333325622409],
              [-39.988333331617923, -7.439166659014063],
              [-39.987499998226269, -7.439166659014063],
              [-39.987500000924285, -7.43999999240566],
              [-39.988333330718604, -7.43999999240566],
              [-39.988333332517243, -7.441666659188911],
              [-39.985833332342395, -7.441666659188911],
              [-39.985833333241715, -7.442499992580565],
              [-39.984999999850061, -7.442499992580565],
              [-39.984999999850061, -7.443333325972162],
              [-39.984166666458464, -7.443333325972162],
              [-39.984166666458464, -7.444999992755413],
              [-39.98333333126817, -7.444999992755413],
              [-39.983333333966129, -7.447499992930261],
              [-39.982499998775893, -7.447499992930261],
              [-39.982499998775893, -7.448333325422595],
              [-39.982499998775893, -7.449166658814192],
              [-39.981666668082198, -7.449166658814192],
              [-39.981666668981518, -7.449999993105166],
              [-39.980833332891962, -7.449999994004486],
              [-39.980833332891962, -7.452499991481375],
              [-39.981666665384239, -7.452499992380694],
              [-39.981666668082198, -7.453333325772292],
              [-39.980833331992642, -7.453333325772292],
              [-39.980833333791281, -7.454166659163945],
              [-39.980000000399627, -7.454166659163945],
              [-39.980000000399627, -7.455833325947196],
              [-39.979166665209391, -7.455833325047877],
              [-39.97916666700803, -7.456666658439474],
              [-39.978333331817794, -7.456666658439474],
              [-39.978333331817794, -7.457499992730448],
              [-39.97916666700803, -7.457499992730448],
              [-39.979166665209391, -7.458333326122045],
              [-39.978333331817794, -7.458333326122045],
              [-39.978333332717114, -7.459999992905296],
              [-39.976666665933863, -7.459999993804615],
              [-39.976666666833182, -7.459166658614379],
              [-39.975000000949251, -7.459166658614379],
              [-39.975000000949251, -7.462499993080144],
              [-39.974166666658277, -7.462499993080144],
              [-39.974166666658277, -7.464166658964075],
              [-39.973333332367361, -7.464166658964075],
              [-39.973333331468041, -7.464999994154368],
              [-39.972500000774346, -7.464999992355729],
              [-39.972499999875026, -7.46666665913898],
              [-39.975000000949251, -7.4666666600383],
              [-39.974999998251292, -7.467499991631257],
              [-39.97916666700803, -7.467499992530577],
              [-39.979166665209391, -7.469166658414508],
              [-39.979999998600988, -7.469166658414508],
              [-39.979999998600988, -7.469999992705425],
              [-39.98333333306681, -7.469999992705425],
              [-39.98333333126817, -7.471666658589356],
              [-39.982499998775893, -7.471666658589356],
              [-39.982500000574532, -7.473333325372607],
              [-39.981666665384239, -7.473333325372607],
              [-39.981666666283559, -7.475833326446832],
              [-39.982499999675213, -7.475833325547512],
              [-39.982499999675213, -7.476666659838429],
              [-39.981666666283559, -7.47666665803979],
              [-39.981666666283559, -7.47833332572236],
              [-39.979166665209391, -7.47833332572236],
              [-39.979166665209391, -7.479166659113957],
              [-39.978333333616433, -7.479166659113957],
              [-39.978333330918417, -7.482499992680459],
              [-39.976666666833182, -7.482499992680459],
              [-39.976666666833182, -7.48416665946371],
              [-39.975833331642889, -7.484166657665071],
              [-39.975833333441528, -7.484999992855364],
              [-39.975000000049931, -7.484999992855364],
              [-39.974999998251292, -7.485833325347642],
              [-39.972499999875026, -7.485833324448322],
              [-39.972499999875026, -7.486666658739239],
              [-39.970833331293136, -7.486666658739239],
              [-39.970833333991152, -7.487499993030212],
              [-39.969999998800859, -7.487499992130893],
              [-39.969999999700178, -7.489166658914144],
              [-39.969166666308581, -7.489166659813463],
              [-39.969166665409261, -7.48999999320506],
              [-39.968333332916927, -7.48999999320506],
              [-39.968333332916927, -7.490833324798075],
              [-39.96749999862601, -7.490833325697395],
              [-39.96749999862601, -7.491666659088992],
              [-39.965833331842759, -7.491666659088992],
              [-39.965833334540719, -7.492499991581326],
              [-39.964999999350425, -7.492499992480646],
              [-39.965000000249745, -7.493333325872243],
              [-39.9616666666833, -7.493333325872243],
              [-39.96166666758262, -7.495833326946411],
              [-39.962500000074897, -7.495833326047091],
              [-39.962499998276257, -7.496666658539425],
              [-39.966666667032996, -7.496666657640105],
              [-39.966666665234357, -7.497499992830342],
              [-39.969166666308581, -7.497499993729662],
              [-39.969166665409261, -7.496666658539425],
              [-39.969999998800859, -7.496666658539425],
              [-39.969999998800859, -7.497499992830342],
              [-39.971666666483429, -7.497499992830342],
              [-39.97166666558411, -7.496666658539425],
              [-39.972499998975707, -7.496666658539425],
              [-39.972500000774346, -7.494999992655494],
              [-39.973333331468041, -7.494999992655494],
              [-39.973333331468041, -7.493333325872243],
              [-39.975833333441528, -7.493333325872243],
              [-39.975833331642889, -7.491666659088992],
              [-39.977500000224779, -7.491666659088992],
              [-39.97749999932546, -7.490833325697395],
              [-39.978333332717114, -7.490833325697395],
              [-39.978333331817794, -7.48999999320506],
              [-39.979999999500308, -7.48999999320506],
              [-39.979999999500308, -7.48833332552249],
              [-39.981666665384239, -7.48833332552249],
              [-39.981666666283559, -7.487499993929532],
              [-39.984999999850061, -7.487499993030212],
              [-39.984999998950741, -7.48833332552249],
              [-39.986666665733992, -7.48833332552249],
              [-39.986666665733992, -7.486666658739239],
              [-39.988333332517243, -7.486666658739239],
              [-39.988333331617923, -7.487499993030212],
              [-39.990833332692091, -7.487499993929532],
              [-39.990833332692091, -7.48999999320506],
              [-39.993333333766316, -7.48999999320506],
              [-39.993333333766316, -7.490833325697395],
              [-39.994166666258593, -7.490833325697395],
              [-39.994166668057233, -7.491666659088992],
              [-39.996666666433441, -7.491666659988311],
              [-39.996666664634802, -7.492499991581326],
              [-39.997499998925775, -7.492499993379965],
              [-39.997499999825095, -7.493333324972923],
              [-39.999166665709026, -7.493333326771562],
              [-39.999166668406986, -7.492499992480646],
              [-39.999999999100623, -7.492499992480646],
              [-39.999999997301984, -7.491666659088992],
              [-40.001666665883874, -7.491666659088992],
              [-40.001666666783194, -7.493333325872243],
              [-40.005833333741293, -7.493333325872243],
              [-40.005833331942654, -7.49416665836452],
              [-40.007500000524544, -7.49416665836452],
              [-40.007499998725905, -7.494999992655494],
              [-40.009999999800129, -7.494999991756174],
              [-40.009999999800129, -7.49416665836452],
              [-40.010833331393087, -7.49416665836452],
              [-40.010833332292407, -7.493333325872243],
              [-40.011666665684004, -7.493333325872243],
              [-40.0116666674827, -7.49416665836452],
              [-40.01166666658338, -7.498333327121316],
              [-40.010833331393087, -7.498333326221996],
              [-40.010833334091046, -7.499999993005247],
              [-40.01166666658338, -7.499999992105927],
              [-40.01166666658338, -7.500833324598204],
              [-40.012499999075658, -7.500833325497524],
              [-40.012499999974978, -7.501666658889121],
              [-40.01166666658338, -7.501666658889121],
              [-40.01166666658338, -7.502499993180095],
              [-40.013333331567935, -7.502499993180095],
              [-40.013333331567935, -7.503333325672372],
              [-40.012499999974978, -7.503333325672372],
              [-40.012499999974978, -7.504999991556303],
              [-40.013333331567935, -7.504999992455623],
              [-40.013333331567935, -7.507499992630528],
              [-40.012500000874297, -7.507499992630528],
              [-40.012500000874297, -7.509166658514459],
              [-40.013333333366575, -7.509166657615083],
              [-40.013333333366575, -7.509999992805376],
              [-40.011666665684004, -7.509999992805376],
              [-40.01166666658338, -7.511666659588627],
              [-40.012499999974978, -7.511666658689307],
              [-40.012500000874297, -7.512499992980224],
              [-40.013333333366575, -7.512499992980224],
              [-40.013333332467255, -7.514166658864156],
              [-40.01583333264216, -7.514166658864156],
              [-40.01583333264216, -7.514999993155129],
              [-40.016666666033757, -7.514999993155129],
              [-40.016666664235117, -7.515833324748087],
              [-40.017499998526091, -7.515833326546726],
              [-40.017500000324731, -7.519999992605506],
              [-40.018333332817008, -7.519999993504825],
              [-40.018333333716328, -7.521666658489437],
              [-40.019166665309342, -7.521666659388757],
              [-40.019166665309342, -7.523333326172008],
              [-40.018333331917688, -7.523333326172008],
              [-40.018333331917688, -7.524166658664342],
              [-40.018333332817008, -7.524999993854578],
              [-40.019166668007301, -7.524999992955259],
              [-40.019166666208662, -7.525833324548216],
              [-40.018333331917688, -7.525833325447593],
              [-40.018333331018368, -7.527499993130107],
              [-40.016666666933077, -7.527499993130107],
              [-40.016666666033757, -7.528333325622441],
              [-40.01583333174284, -7.528333325622441],
              [-40.01583333354148, -7.529166659014038],
              [-40.015000001049145, -7.529166659014038],
              [-40.014999998351186, -7.529999994204331],
              [-40.014166665858909, -7.529999991506372],
              [-40.014166665858909, -7.533333325972194],
              [-40.012500000874297, -7.533333325972194],
              [-40.012499999974978, -7.535833327046362],
              [-40.011666665684004, -7.535833324348403],
              [-40.01166666658338, -7.536666658639319],
              [-40.010833331393087, -7.536666658639319],
              [-40.010833331393087, -7.537499992930293],
              [-40.009166667307795, -7.537499992930293],
              [-40.009166666408476, -7.539166658814224],
              [-40.008333334815518, -7.539166658814224],
              [-40.008333331218239, -7.540833325597475],
              [-40.006666668931587, -7.540833325597475],
              [-40.006666668032267, -7.541666658989072],
              [-40.005833333741293, -7.541666658989072],
              [-40.005833333741293, -7.544999992555574],
              [-40.004166667857419, -7.544999993454894],
              [-40.004166666058723, -7.54416665916392],
              [-40.003333333566445, -7.54416666006324],
              [-40.003333332667125, -7.544999992555574],
              [-40.001666666783194, -7.544999992555574],
              [-40.001666666783194, -7.547499992730422],
              [-40.000833331592958, -7.547499992730422],
              [-40.000833331592958, -7.548333326122076],
              [-40.001666665883874, -7.548333325222757],
              [-40.001666666783194, -7.549166658614354],
              [-40.002500001074168, -7.549166658614354],
              [-40.002500001074168, -7.552499992180856],
              [-40.003333331767806, -7.552499993979495],
              [-40.003333331767806, -7.553333325572453],
              [-40.002500001074168, -7.553333324673133],
              [-40.002500001074168, -7.554166658964107],
              [-40.001666665883874, -7.554166658964107],
              [-40.001666666783194, -7.555833324848038],
              [-39.999166666608346, -7.555833325747358],
              [-39.999166665709026, -7.557499993429929],
              [-39.998333333216692, -7.557499992530609],
              [-39.998333332317372, -7.559999992705457],
              [-39.997499999825095, -7.559999992705457],
              [-39.997500000724415, -7.560833326097054],
              [-39.998333331418053, -7.560833326996374],
              [-39.998333331418053, -7.562499992880305],
              [-39.998333331418053, -7.563333325372639],
              [-39.999999999100623, -7.563333325372639],
              [-39.999999999100623, -7.559999992705457],
              [-40.000833333391597, -7.559999992705457],
              [-40.000833333391597, -7.559166658414483],
              [-40.001666665883874, -7.559166658414483],
              [-40.001666665883874, -7.558333325922206],
              [-40.004166667857419, -7.558333325922206],
              [-40.004166666958099, -7.557499993429929],
              [-40.005833333741293, -7.557499992530609],
              [-40.005833333741293, -7.558333325922206],
              [-40.009166666408476, -7.558333325922206],
              [-40.009166665509156, -7.557499992530609],
              [-40.0116666674827, -7.557499992530609],
              [-40.011666665684004, -7.556666657340315],
              [-40.014166666758229, -7.556666659138955],
              [-40.014166666758229, -7.555833326646678],
              [-40.014999998351186, -7.555833325747358],
              [-40.014999998351186, -7.556666659138955],
              [-40.01583333264216, -7.556666659138955],
              [-40.01583333264216, -7.557499992530609],
              [-40.016666666033757, -7.557499992530609],
              [-40.016666667832396, -7.558333325922206],
              [-40.018333333716328, -7.558333325922206],
              [-40.018333333716328, -7.557499992530609],
              [-40.02166666548419, -7.557499992530609],
              [-40.02166666638351, -7.558333325922206],
              [-40.028333333516514, -7.558333325922206],
              [-40.028333331717818, -7.557499991631289],
              [-40.029999998501069, -7.557499992530609],
              [-40.030000000299708, -7.556666659138955],
              [-40.030833331892723, -7.556666659138955],
              [-40.030833332792042, -7.555833325747358],
              [-40.034166666358544, -7.555833326646678],
              [-40.034166666358544, -7.554999992355704],
              [-40.034999998850822, -7.554999991456384],
              [-40.034999999750141, -7.554166658964107],
              [-40.035833332242419, -7.554166659863427],
              [-40.035833334041115, -7.553333325572453],
              [-40.041666668681785, -7.553333325572453],
              [-40.041666666883145, -7.554166658964107],
              [-40.043333332767077, -7.554166658964107],
              [-40.043333331867757, -7.551666658789202],
              [-40.044166667057993, -7.551666658789202],
              [-40.044166665259354, -7.550833325397605],
              [-40.045000000449591, -7.550833325397605],
              [-40.044999998650951, -7.551666658789202],
              [-40.047499999725176, -7.551666658789202],
              [-40.047500000624495, -7.552499993080175],
              [-40.049999999000704, -7.552499993080175],
              [-40.049999999000704, -7.551666658789202],
              [-40.052500001873568, -7.551666659688522],
              [-40.052499998276232, -7.549999992905327],
              [-40.054999999350457, -7.549999992006008],
              [-40.055000000249777, -7.549166659513673],
              [-40.055833331842734, -7.549166658614354],
              [-40.055833332742054, -7.549999993804647],
              [-40.057500000424625, -7.549999992905327],
              [-40.057499999525305, -7.550833325397605],
              [-40.064999998251267, -7.550833325397605],
              [-40.065000000049906, -7.551666658789202],
              [-40.066666665933838, -7.551666657889882],
              [-40.066666666833157, -7.552499993080175],
              [-40.068333331817769, -7.552499993080175],
              [-40.068333333616408, -7.553333325572453],
              [-40.071666665384271, -7.553333325572453],
              [-40.071666665384271, -7.554166658964107],
              [-40.072499999675188, -7.554166659863427],
              [-40.074999999850093, -7.554166658964107],
              [-40.074999998950773, -7.552499993080175],
              [-40.076666665733967, -7.552499993080175],
              [-40.076666668431983, -7.551666658789202],
              [-40.077499998226301, -7.551666657889882],
              [-40.07750000182358, -7.549999992905327],
              [-40.078333331617898, -7.549999992905327],
              [-40.078333332517218, -7.549166658614354],
              [-40.079166667707511, -7.549166658614354],
              [-40.079166665009552, -7.548333326122076],
              [-40.079999999300469, -7.548333327021396],
              [-40.079999999300469, -7.546666658439506],
              [-40.080833332692123, -7.546666658439506],
              [-40.080833333591443, -7.544999992555574],
              [-40.08166666698304, -7.544999992555574],
              [-40.081666664285081, -7.54416665916392],
              [-40.08749999982507, -7.54416665916392],
              [-40.08750000162371, -7.543333325772323],
              [-40.090579053680358, -7.54333333656416],
              [-40.092500001074143, -7.543333325772323],
              [-40.092499999275503, -7.54416665916392],
              [-40.094166666058754, -7.54416665916392],
              [-40.094166666058754, -7.544999992555574],
              [-40.094999998551032, -7.544999992555574],
              [-40.095000000349671, -7.545833325947171],
              [-40.097499999625256, -7.545833326846491],
              [-40.098333333016853, -7.545833325947171],
              [-40.098333331218214, -7.544166657365281],
              [-40.099166667307827, -7.54416665916392],
              [-40.099166666408507, -7.543333325772323],
              [-40.099999998001465, -7.543333325772323],
              [-40.100000000699424, -7.542499992380669],
              [-40.100833331393062, -7.542499992380669],
              [-40.100833332292382, -7.541666658989072],
              [-40.102499999974953, -7.541666658989072],
              [-40.102499999974953, -7.539166658814224],
              [-40.103333333366606, -7.539166658814224],
              [-40.103333332467287, -7.537499992930293],
              [-40.102499999075633, -7.537499992930293],
              [-40.102499999974953, -7.536666658639319],
              [-40.101666666583355, -7.536666658639319],
              [-40.101666665684036, -7.534999991856068],
              [-40.100833334091078, -7.534999992755388],
              [-40.100833334091078, -7.533333326871514],
              [-40.099166666408507, -7.533333325972194],
              [-40.099166666408507, -7.531666659188943],
              [-40.097499997826617, -7.531666659188943],
              [-40.097499998725937, -7.528333324723121],
              [-40.0933333326671, -7.528333325622441],
              [-40.09333333356642, -7.527499993130107],
              [-40.092500001074143, -7.527499993130107],
              [-40.092499999275503, -7.52666665973851],
              [-40.091666666783226, -7.52666665793987],
              [-40.091666666783226, -7.525833325447593],
              [-40.09, -7.525833324548216],
              [-40.09, -7.524999992955259],
              [-40.089166667507641, -7.524999992955259],
              [-40.089166667507641, -7.524166658664342],
              [-40.088333332317404, -7.524166658664342],
              [-40.088333332317404, -7.523333326172008],
              [-40.08749999892575, -7.523333326172008],
              [-40.08750000072439, -7.522499992780411],
              [-40.085000000549542, -7.522499992780411],
              [-40.084999998750902, -7.519999992605506],
              [-40.084166668057264, -7.519999992605506],
              [-40.084166665359248, -7.519166659213909],
              [-40.083333332866971, -7.519166660113228],
              [-40.083333333766291, -7.517499992430658],
              [-40.082499997676734, -7.517499992430658],
              [-40.082499997676734, -7.515833324748087],
              [-40.08166666608372, -7.515833325647407],
              [-40.08166666608372, -7.51499999135649],
              [-40.080125884601841, -7.514999993155129],
              [-40.079999999300469, -7.514999993155129],
              [-40.079999999300469, -7.514166658864156],
              [-40.079166667707511, -7.514166658864156],
              [-40.079166667707511, -7.513333324573239],
              [-40.078333331617898, -7.513333325472558],
              [-40.078333333416538, -7.512499992980224],
              [-40.077500000024941, -7.512499992980224],
              [-40.07750000092426, -7.511666657789988],
              [-40.076666666633344, -7.511666658689307],
              [-40.076666667532663, -7.51083332709635],
              [-40.074999998950773, -7.510833326196973],
              [-40.074999999850093, -7.506666657440235],
              [-40.073333333066842, -7.506666659238874],
              [-40.073333331268202, -7.505833325847277],
              [-40.072499998775868, -7.505833325847277],
              [-40.072500000574507, -7.504999992455623],
              [-40.071666666283591, -7.504999992455623],
              [-40.071666665384271, -7.504166658164706],
              [-40.066666666833157, -7.504166659064026],
              [-40.066666665933838, -7.503333324773052],
              [-40.06583333344156, -7.503333325672372],
              [-40.06583333344156, -7.501666658889121],
              [-40.064166665758989, -7.501666658889121],
              [-40.064166666658309, -7.500833325497524],
              [-40.062499998975738, -7.500833325497524],
              [-40.062499998975738, -7.499999992105927],
              [-40.061666666483404, -7.499999993005247],
              [-40.061666667382724, -7.499166658714273],
              [-40.059999998800834, -7.499166658714273],
              [-40.059999997901514, -7.498333326221996],
              [-40.058333333816279, -7.498333326221996],
              [-40.058333333816279, -7.497499992830342],
              [-40.056666667033028, -7.497499992830342],
              [-40.056666664335069, -7.498333326221996],
              [-40.055833331842734, -7.498333327121316],
              [-40.055833332742054, -7.497499993729662],
              [-40.054999998451137, -7.497499992830342],
              [-40.054999998451137, -7.496666658539425],
              [-40.054166667757443, -7.496666658539425],
              [-40.054166665958803, -7.495833326047091],
              [-40.050833331493038, -7.495833326047091],
              [-40.050833332392358, -7.496666658539425],
              [-40.050000000799344, -7.496666658539425],
              [-40.049999999900024, -7.494999992655494],
              [-40.049166666508427, -7.494999992655494],
              [-40.049166666508427, -7.493333326771562],
              [-40.047499998825856, -7.493333325872243],
              [-40.047500001523815, -7.492499992480646],
              [-40.046666666333522, -7.492499992480646],
              [-40.046666665434202, -7.491666658189672],
              [-40.048333330418814, -7.491666659088992],
              [-40.048333332217453, -7.490833325697395],
              [-40.049166666508427, -7.490833325697395],
              [-40.049166668307066, -7.489999991406421],
              [-40.049999999900024, -7.48999999410438],
              [-40.049999999900024, -7.490833325697395],
              [-40.051666667582595, -7.490833325697395],
              [-40.051666666683275, -7.48999999320506],
              [-40.052500000974248, -7.48999999320506],
              [-40.052500000974248, -7.489166658914144],
              [-40.053333331667886, -7.489166658914144],
              [-40.053333330768567, -7.48833332552249],
              [-40.054166665958803, -7.48833332552249],
              [-40.054166666858123, -7.487499993030212],
              [-40.056666665234388, -7.487499992130893],
              [-40.056666667033028, -7.48833332552249],
              [-40.057500000424625, -7.48833332552249],
              [-40.057499998625985, -7.489166658914144],
              [-40.058333332916959, -7.489166658914144],
              [-40.058333332017639, -7.489999991406421],
              [-40.059166665409236, -7.489999991406421],
              [-40.059166666308556, -7.489166658914144],
              [-40.063333333266655, -7.489166658014824],
              [-40.063333331468016, -7.48999999320506],
              [-40.064999998251267, -7.489999991406421],
              [-40.065000000049906, -7.490833325697395],
              [-40.06583333254224, -7.490833325697395],
              [-40.06583333344156, -7.491666659088992],
              [-40.066666665933838, -7.491666659088992],
              [-40.066666665933838, -7.492499992480646],
              [-40.068333333616408, -7.492499992480646],
              [-40.068333333616408, -7.493333325872243],
              [-40.070833332891937, -7.493333325872243],
              [-40.070833333791256, -7.49416665836452],
              [-40.071666665384271, -7.49416665836452],
              [-40.071666665384271, -7.494999993554813],
              [-40.072499998775868, -7.494999992655494],
              [-40.072499998775868, -7.495833325147771],
              [-40.074166666458439, -7.495833326047091],
              [-40.074166667357758, -7.496666658539425],
              [-40.074999999850093, -7.496666659438745],
              [-40.075000000749412, -7.495833326047091],
              [-40.07750000092426, -7.495833325147771],
              [-40.07750000092426, -7.496666658539425],
              [-40.08166666608372, -7.496666658539425],
              [-40.08166666608372, -7.497499992830342],
              [-40.083333332866971, -7.497499992830342],
              [-40.083333332866971, -7.499166658714273],
              [-40.085833334840515, -7.499166658714273],
              [-40.08583333124318, -7.499999993005247],
              [-40.086666665534153, -7.499999993005247],
              [-40.086666665534153, -7.501666658889121],
              [-40.08749999982507, -7.501666658889121],
              [-40.08750000072439, -7.502499993180095],
              [-40.09, -7.502499993180095],
              [-40.09, -7.503333325672372],
              [-40.092499998376184, -7.503333326571692],
              [-40.092499998376184, -7.504166658164706],
              [-40.103333333366606, -7.504166659064026],
              [-40.103333331567967, -7.504999992455623],
              [-40.104166667657523, -7.504999991556303],
              [-40.104166665858884, -7.505833325847277],
              [-40.104999999250538, -7.505833325847277],
              [-40.104999998351218, -7.506666659238874],
              [-40.105070119390462, -7.506666659238874],
              [-40.105833332642135, -7.506666657440235],
              [-40.105833331742815, -7.507499992630528],
              [-40.107500000324706, -7.507499992630528],
              [-40.107499997626746, -7.504711761538545],
              [-40.107499999425386, -7.504166658164706],
              [-40.108333331917663, -7.504166658164706],
              [-40.108333331917663, -7.503333325672372],
              [-40.109166665309317, -7.503333325672372],
              [-40.109166666208637, -7.502499993180095],
              [-40.111666667282805, -7.502499991381455],
              [-40.111666666383485, -7.501666658889121],
              [-40.10833333281704, -7.501666658889121],
              [-40.108333333716359, -7.500833325497524],
              [-40.107499998526066, -7.500833324598204],
              [-40.107500000324706, -7.499999993005247],
              [-40.106666666933108, -7.499999993005247],
              [-40.106666666033789, -7.499166658714273],
              [-40.105833332642135, -7.499166658714273],
              [-40.105833330843495, -7.497499993729662],
              [-40.105000001049177, -7.497499992830342],
              [-40.105000001049177, -7.496666659438745],
              [-40.104166665858884, -7.496666658539425],
              [-40.104166664959564, -7.495833326047091],
              [-40.104166666758204, -7.4941666574652],
              [-40.101666666583355, -7.49416665836452],
              [-40.101666664784716, -7.493333325872243],
              [-40.100833331393062, -7.493333325872243],
              [-40.100833331393062, -7.492499992480646],
              [-40.099999999800104, -7.492499992480646],
              [-40.099999998900785, -7.491666659088992],
              [-40.099166667307827, -7.491666659088992],
              [-40.099166665509188, -7.490833325697395],
              [-40.097499999625256, -7.490833326596714],
              [-40.097499997826617, -7.48999999320506],
              [-40.096666666233602, -7.48999999320506],
              [-40.096666668032242, -7.489166658914144],
              [-40.095833333741325, -7.489166659813463],
              [-40.095833332842005, -7.48833332552249],
              [-40.095000001248991, -7.48833332552249],
              [-40.095000000349671, -7.487499993030212],
              [-40.094166667857394, -7.487499993030212],
              [-40.094166666058754, -7.485833324448322],
              [-40.0933333326671, -7.485833325347642],
              [-40.0933333326671, -7.484999992855364],
              [-40.092499998376184, -7.484999992855364],
              [-40.092499998376184, -7.484166657665071],
              [-40.090833331592933, -7.484166658564391],
              [-40.090833333391572, -7.483333326072113],
              [-40.090000000899295, -7.483333326072113],
              [-40.089999999100655, -7.482499992680459],
              [-40.089166665709001, -7.482499992680459],
              [-40.089166667507641, -7.481666658389543],
              [-40.088333331418085, -7.481666658389543],
              [-40.088333331418085, -7.480833324997889],
              [-40.08749999982507, -7.480833325897208],
              [-40.08749999892575, -7.479166659113957],
              [-40.086666666433473, -7.479166659113957],
              [-40.086666667332793, -7.47833332662168],
              [-40.085000000549542, -7.478333324823041],
              [-40.085000000549542, -7.477499993230083],
              [-40.083333333766291, -7.477499993230083],
              [-40.083333332866971, -7.47666665803979],
              [-40.082499999475374, -7.476666658939109],
              [-40.082500000374694, -7.475833325547512],
              [-40.08166666698304, -7.475833325547512],
              [-40.081666667882359, -7.474166659663581],
              [-40.080833331792803, -7.474166658764261],
              [-40.080833331792803, -7.47249999288033],
              [-40.079999999300469, -7.47249999288033],
              [-40.079999998401149, -7.469999992705425],
              [-40.078333332517218, -7.469999992705425],
              [-40.078333333416538, -7.469166658414508],
              [-40.077500000024941, -7.469166658414508],
              [-40.077499998226301, -7.468333325922231],
              [-40.07583333144305, -7.468333325922231],
              [-40.07583333234237, -7.46666665734034],
              [-40.077500000024941, -7.46666665913898],
              [-40.077500000024941, -7.464166658964075],
              [-40.076666665733967, -7.464166658064755],
              [-40.076666667532663, -7.463333325572478],
              [-40.076666665733967, -7.462499993080144],
              [-40.074166664659799, -7.462499993080144],
              [-40.074166665559119, -7.458333325222725],
              [-40.073333333066842, -7.458333326122045],
              [-40.073333333066842, -7.455833325947196],
              [-40.074166666458439, -7.455833325947196],
              [-40.074166666458439, -7.454166659163945],
              [-40.072499999675188, -7.454166659163945],
              [-40.072499999675188, -7.453333325772292],
              [-40.071666665384271, -7.453333325772292],
              [-40.071666665384271, -7.454166659163945],
              [-40.070833333791256, -7.454166660063265],
              [-40.070833331992617, -7.453333325772292],
              [-40.070000000399659, -7.453333325772292],
              [-40.06999999950034, -7.452499992380694],
              [-40.06999999860102, -7.451666658989097],
              [-40.070833331992617, -7.451666658989097],
              [-40.070833333791256, -7.452499992380694],
              [-40.073333331268202, -7.452499994179334],
              [-40.073333333066842, -7.453333324872972],
              [-40.074166665559119, -7.453333325772292],
              [-40.074166666458439, -7.452499992380694],
              [-40.077500000024941, -7.452499992380694],
              [-40.077499998226301, -7.451666658989097],
              [-40.078333332517218, -7.451666658989097],
              [-40.078333332517218, -7.452499994179334],
              [-40.085833333941139, -7.452499992380694],
              [-40.08583333124318, -7.453333325772292],
              [-40.088333332317404, -7.453333325772292],
              [-40.088333333216724, -7.454166659163945],
              [-40.090833333391572, -7.454166659163945],
              [-40.090833333391572, -7.453333325772292],
              [-40.0933333326671, -7.453333325772292],
              [-40.0933333326671, -7.454999992555543],
              [-40.094166666058754, -7.454999992555543],
              [-40.094166666058754, -7.455833325947196],
              [-40.095833333741325, -7.455833325947196],
              [-40.095833331942686, -7.456666658439474],
              [-40.096666665334283, -7.456666658439474],
              [-40.096666666233602, -7.457499992730448],
              [-40.102500000874272, -7.457499992730448],
              [-40.102499999075633, -7.456666658439474],
              [-40.104166665858884, -7.456666658439474],
              [-40.104166666758204, -7.455833325947196],
              [-40.105000001049177, -7.455833325947196],
              [-40.104999998351218, -7.456666658439474],
              [-40.106666666033789, -7.456666658439474],
              [-40.106666666933108, -7.457499992730448],
              [-40.107499998526066, -7.457499992730448],
              [-40.107499999425386, -7.458333326122045],
              [-40.109166668906596, -7.458333326122045],
              [-40.109166666208637, -7.459166658614379],
              [-40.111666667282805, -7.459166658614379],
              [-40.111666667282805, -7.459999992905296],
              [-40.117500001024212, -7.459999992905296],
              [-40.117500001024212, -7.459166659513699],
              [-40.116666665833918, -7.459166657715059],
              [-40.116666665833918, -7.458333327021364],
              [-40.114999999050667, -7.458333325222725],
              [-40.115000000849307, -7.457499992730448],
              [-40.111666666383485, -7.457499993629767],
              [-40.111666665484165, -7.456666658439474],
              [-40.109999998700914, -7.456666660238113],
              [-40.109999999600234, -7.454999992555543],
              [-40.109166666208637, -7.454999992555543],
              [-40.109166665309317, -7.454166659163945],
              [-40.108333331917663, -7.454166659163945],
              [-40.10833333281704, -7.453333325772292],
              [-40.107499998526066, -7.453333325772292],
              [-40.107499998526066, -7.452499992380694],
              [-40.106666666933108, -7.452499992380694],
              [-40.106666667832428, -7.451666658989097],
              [-40.104166666758204, -7.451666659888417],
              [-40.104166665858884, -7.449166657914873],
              [-40.103333332467287, -7.449166658814192],
              [-40.103333333366606, -7.44583332614701],
              [-40.102499999075633, -7.44583332614701],
              [-40.102499999974953, -7.444999992755413],
              [-40.100833332292382, -7.444999992755413],
              [-40.100833331393062, -7.444166658464496],
              [-40.100000000699424, -7.444166658464496],
              [-40.100000000699424, -7.443333325972162],
              [-40.099166665509188, -7.443333325972162],
              [-40.099166667307827, -7.442499992580565],
              [-40.097499999625256, -7.442499992580565],
              [-40.097499998725937, -7.43999999240566],
              [-40.096666666233602, -7.43999999240566],
              [-40.096666668032242, -7.439166659014063],
              [-40.095833331942686, -7.439166659014063],
              [-40.095833332842005, -7.438333325622409],
              [-40.094166667857394, -7.438333325622409],
              [-40.094166666958074, -7.437499993130132],
              [-40.0933333326671, -7.437499993130132],
              [-40.0933333326671, -7.436666657939838],
              [-40.091666665883906, -7.436666658839215],
              [-40.091666665883906, -7.43416665866431],
              [-40.090833332492252, -7.43416665866431],
              [-40.090833333391572, -7.433333326172033],
              [-40.090000000899295, -7.433333327071352],
              [-40.089999999100655, -7.432499992780379],
              [-40.089166665709001, -7.432499993679698],
              [-40.089166667507641, -7.431666658489462],
              [-40.088333331418085, -7.431666658489462],
              [-40.088333333216724, -7.429166659213877],
              [-40.08749999982507, -7.429166659213877],
              [-40.08750000072439, -7.42833332582228],
              [-40.086666665534153, -7.42833332582228],
              [-40.086666665534153, -7.427499992430683],
              [-40.084999999650222, -7.427499992430683],
              [-40.084999998750902, -7.424999994054417],
              [-40.084166666258625, -7.424999993155097],
              [-40.084166665359248, -7.424166658864181],
              [-40.082499998576054, -7.424166657964861],
              [-40.082500000374694, -7.422499993879569],
              [-40.081666667882359, -7.422499992980249],
              [-40.081666667882359, -7.421666658689276],
              [-40.080833331792803, -7.421666659588595],
              [-40.080833332692123, -7.420833326196998],
              [-40.080000001099108, -7.420833326196998],
              [-40.079999998401149, -7.419166658514428],
              [-40.078333332517218, -7.419166658514428],
              [-40.078333332517218, -7.41833332602215],
              [-40.07750000092426, -7.41833332602215],
              [-40.077499998226301, -7.417499992630496],
              [-40.077499998226301, -7.41666665744026],
              [-40.079999999300469, -7.416666659238899],
              [-40.07999999750183, -7.417499993529816],
              [-40.08166666698304, -7.417499992630496],
              [-40.08166666608372, -7.41833332602215],
              [-40.083333331967651, -7.41833332602215],
              [-40.083333333766291, -7.419166658514428],
              [-40.084166668057264, -7.419166658514428],
              [-40.084166668057264, -7.419999992805401],
              [-40.085833333041819, -7.419999992805401],
              [-40.085833333041819, -7.420833326196998],
              [-40.08749999982507, -7.420833326196998],
              [-40.08750000072439, -7.421666658689276],
              [-40.089166667507641, -7.421666657789956],
              [-40.089166665709001, -7.422499992980249],
              [-40.089999999100655, -7.42249999208093],
              [-40.089999999100655, -7.421666658689276],
              [-40.091666667682546, -7.421666658689276],
              [-40.091666665883906, -7.422499992980249],
              [-40.092499998376184, -7.422499992980249],
              [-40.092499998376184, -7.424166657964861],
              [-40.095833333741325, -7.424166658864181],
              [-40.095833331942686, -7.424999993155097],
              [-40.096666666233602, -7.424999993155097],
              [-40.096666665334283, -7.426666659039029],
              [-40.098333331218214, -7.426666659039029],
              [-40.098333331218214, -7.427499992430683],
              [-40.099999999800104, -7.427499992430683],
              [-40.099999999800104, -7.426666658139709],
              [-40.100833331393062, -7.426666658139709],
              [-40.100833334091078, -7.427499992430683],
              [-40.101666666583355, -7.427499991531363],
              [-40.101666666583355, -7.42833332582228],
              [-40.104999999250538, -7.42833332582228],
              [-40.104999998351218, -7.429166660113253],
              [-40.105833332642135, -7.429166659213877],
              [-40.105833331742815, -7.429999992605531],
              [-40.106666666033789, -7.429999992605531],
              [-40.106666667832428, -7.430833325997128],
              [-40.107499999425386, -7.430833325997128],
              [-40.107499999425386, -7.432499992780379],
              [-40.108333331917663, -7.432499992780379],
              [-40.108333331917663, -7.43416665866431],
              [-40.110833333891208, -7.43416665866431],
              [-40.110833332092568, -7.434999992955284],
              [-40.111666664584845, -7.434999992055964],
              [-40.111666665484165, -7.435833325447561],
              [-40.112500000674459, -7.435833325447561],
              [-40.112499998875819, -7.439166659014063],
              [-40.113333334066056, -7.439166659014063],
              [-40.113333334066056, -7.43999999240566],
              [-40.115833331543001, -7.43999999240566],
              [-40.115833332442321, -7.440833325797314],
              [-40.118333331717849, -7.440833325797314],
              [-40.118333331717849, -7.43999999240566],
              [-40.11999999940042, -7.43999999240566],
              [-40.1199999985011, -7.439166659014063],
              [-40.119166666908086, -7.439166659014063],
              [-40.119166666008766, -7.438333325622409],
              [-40.118333331717849, -7.438333325622409],
              [-40.118333333516489, -7.436666658839215],
              [-40.117499998326196, -7.436666657939838],
              [-40.117499998326196, -7.435833325447561],
              [-40.116666665833918, -7.435833325447561],
              [-40.116666666733238, -7.434999992055964],
              [-40.115833333341641, -7.434999992955284],
              [-40.115833333341641, -7.43416665866431],
              [-40.114999999050667, -7.43416665866431],
              [-40.115000000849307, -7.433333326172033],
              [-40.11416666655839, -7.433333326172033],
              [-40.11416666565907, -7.432499993679698],
              [-40.112499999775139, -7.432499992780379],
              [-40.112499999775139, -7.430833325997128],
              [-40.113333334965375, -7.430833325997128],
              [-40.113333331368096, -7.429166657415237],
              [-40.112499999775139, -7.429166659213877],
              [-40.112499999775139, -7.427499992430683],
              [-40.113333331368096, -7.427499992430683],
              [-40.113333331368096, -7.42833332582228],
              [-40.115833332442321, -7.42833332582228],
              [-40.115833332442321, -7.430833325997128],
              [-40.116666664934598, -7.430833325997128],
              [-40.116666666733238, -7.431666657590142],
              [-40.117499998326196, -7.431666657590142],
              [-40.117499998326196, -7.432499992780379],
              [-40.1199999985011, -7.432499992780379],
              [-40.11999999940042, -7.429166659213877],
              [-40.118333332617169, -7.429166659213877],
              [-40.118333332617169, -7.42833332582228],
              [-40.116666666733238, -7.42833332582228],
              [-40.116666666733238, -7.427499991531363],
              [-40.115833332442321, -7.427499992430683],
              [-40.115833333341641, -7.426666659039029],
              [-40.116666667632558, -7.426666659039029],
              [-40.116666666733238, -7.425833325647432],
              [-40.115833332442321, -7.425833325647432],
              [-40.115833333341641, -7.424999993155097],
              [-40.115000000849307, -7.424999994054417],
              [-40.114999999050667, -7.424166658864181],
              [-40.11416666565907, -7.4241666597635],
              [-40.11416666745771, -7.422499992980249],
              [-40.112499998875819, -7.422499993879569],
              [-40.112499999775139, -7.421666658689276],
              [-40.110833332991888, -7.421666658689276],
              [-40.110833332092568, -7.420833326196998],
              [-40.109166666208637, -7.420833327096318],
              [-40.109166665309317, -7.419999992805401],
              [-40.108333331917663, -7.419999992805401],
              [-40.10833333281704, -7.419166659413747],
              [-40.107499999425386, -7.419166659413747],
              [-40.107499998526066, -7.417499992630496],
              [-40.106666664235149, -7.417499992630496],
              [-40.106666666033789, -7.416666659238899],
              [-40.105833334440774, -7.416666659238899],
              [-40.105833333541455, -7.415833325847245],
              [-40.104166666758204, -7.415833325847245],
              [-40.104166666758204, -7.414999992455648],
              [-40.102500000874272, -7.414999992455648],
              [-40.102499999974953, -7.413333325672397],
              [-40.100833332292382, -7.413333325672397],
              [-40.100833334091078, -7.41249999318012],
              [-40.099999999800104, -7.41249999318012],
              [-40.099999999800104, -7.409999992105895],
              [-40.099166665509188, -7.409999993005215],
              [-40.099166666408507, -7.409166657814978],
              [-40.097500000524576, -7.409166658714298],
              [-40.097499998725937, -7.406666658539393],
              [-40.098333333916173, -7.406666658539393],
              [-40.098333333916173, -7.404999991756142],
              [-40.097499999625256, -7.404999992655462],
              [-40.097499998725937, -7.404166658364545],
              [-40.096666664434963, -7.404166657465225],
              [-40.096666665334283, -7.401666659089017],
              [-40.095833331942686, -7.401666659089017],
              [-40.095833332842005, -7.399166658914112],
              [-40.094999998551032, -7.399166658914112],
              [-40.094999998551032, -7.397499993030181],
              [-40.094166666058754, -7.397499992130861],
              [-40.094166666058754, -7.396666658739264],
              [-40.09333333356642, -7.396666657839944],
              [-40.0933333326671, -7.395833325347667],
              [-40.092500001074143, -7.395833327146306],
              [-40.092500001074143, -7.394166660363055],
              [-40.091666666783226, -7.394166658564416],
              [-40.091666667682546, -7.392499992680484],
              [-40.090833331592933, -7.392499992680484],
              [-40.090833333391572, -7.391666658389511],
              [-40.089166665709001, -7.391666658389511],
              [-40.089166666608321, -7.389999992505579],
              [-40.09, -7.389999992505579],
              [-40.09, -7.387499993230051],
              [-40.089166665709001, -7.387499993230051],
              [-40.089166666608321, -7.386666658939134],
              [-40.088333332317404, -7.386666658939134],
              [-40.088333332317404, -7.384999993055203],
              [-40.089166666608321, -7.384999993954523],
              [-40.089166668406961, -7.384166658764229],
              [-40.088333331418085, -7.384166658764229],
              [-40.088333331418085, -7.383333327171272],
              [-40.08750000072439, -7.383333325372632],
              [-40.087500000203448, -7.383091965669538],
              [-40.087454495, -7.383093066999947],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '233',
        OBJECTID: 150.0,
        Nome_area: 'Ipubi-Trindade',
        COD_area: 'CA156',
        Import_bio: 'Muito Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Recuperação de Áreas Degradadas',
        Area_ha: 49955.0807,
        Shape_Leng: 1.72333,
        Shape_Area: 0.04095,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.225000000449597, -7.594999991556335],
              [-40.224166667058, -7.594999992455655],
              [-40.22249999847611, -7.594999992455655],
              [-40.22249999847611, -7.595833325847252],
              [-40.217499999924996, -7.595833325847252],
              [-40.217499999924996, -7.594999992455655],
              [-40.215000000649468, -7.594999992455655],
              [-40.214999998850828, -7.595833325847252],
              [-40.214166665459175, -7.595833325847252],
              [-40.214166667257871, -7.596666659238906],
              [-40.213333333866217, -7.596666659238906],
              [-40.213333332966897, -7.597499992630503],
              [-40.2124999995753, -7.597499991731183],
              [-40.2124999995753, -7.598333326022157],
              [-40.211666667082966, -7.598333326022157],
              [-40.211666665284326, -7.599999992805351],
              [-40.21249999867598, -7.599999993704671],
              [-40.21249999867598, -7.601666658689282],
              [-40.213333332966897, -7.601666658689282],
              [-40.213333332067577, -7.604166658864187],
              [-40.214166665459175, -7.604166658864187],
              [-40.214166666358551, -7.606666659039035],
              [-40.213333332067577, -7.606666658139716],
              [-40.213333333866217, -7.609166659213884],
              [-40.214166665459175, -7.609166660113203],
              [-40.214166665459175, -7.609999991706218],
              [-40.216666666533399, -7.609999992605537],
              [-40.216666666533399, -7.610833326896454],
              [-40.217500000824316, -7.610833325997135],
              [-40.217499999924996, -7.611666659388789],
              [-40.21833333331665, -7.611666658489469],
              [-40.21833333331665, -7.61333332617204],
              [-40.219166667607567, -7.613333327071359],
              [-40.219166666708247, -7.614166657764997],
              [-40.220000000999221, -7.614166658664317],
              [-40.220000000099901, -7.615833325447568],
              [-40.220833331692859, -7.615833325447568],
              [-40.220833333491498, -7.618333325622416],
              [-40.22249999847611, -7.618333325622416],
              [-40.22249999847611, -7.61916665901407],
              [-40.223333332767027, -7.61916665901407],
              [-40.223333333666346, -7.619999992405667],
              [-40.224166665259361, -7.619999992405667],
              [-40.22416666885664, -7.620833325797321],
              [-40.229999999900031, -7.620833325797321],
              [-40.229999999000711, -7.621666659188918],
              [-40.231666665783962, -7.621666659188918],
              [-40.231666667582601, -7.623333325972169],
              [-40.232499998276239, -7.623333325972169],
              [-40.232500000074879, -7.624166658464446],
              [-40.234166667757449, -7.624166658464446],
              [-40.23416666505949, -7.62499999275542],
              [-40.234999997551824, -7.62499999275542],
              [-40.235000000249784, -7.624166658464446],
              [-40.238333332916966, -7.624166659363766],
              [-40.238333332017589, -7.62499999275542],
              [-40.23999999880084, -7.62499999275542],
              [-40.23999999880084, -7.625833326147017],
              [-40.240833333091814, -7.625833326147017],
              [-40.240833333091814, -7.629999993105173],
              [-40.241666665584091, -7.629999993105173],
              [-40.241666666483411, -7.630821629014918],
              [-40.241666666483411, -7.631666658989047],
              [-40.242499999875065, -7.631666658989047],
              [-40.242499998975745, -7.632499992380701],
              [-40.243333333266662, -7.632499991481382],
              [-40.243333331468023, -7.633333325772298],
              [-40.244166665758996, -7.633333325772298],
              [-40.244166666658316, -7.634166659163952],
              [-40.244999998251274, -7.634166657365313],
              [-40.245000000949233, -7.635833325947203],
              [-40.245833332542247, -7.635833325947203],
              [-40.245833333441567, -7.638333326122051],
              [-40.240833333091814, -7.638333325222732],
              [-40.240833333091814, -7.639166658614329],
              [-40.237499998625992, -7.639166658614329],
              [-40.237500000424632, -7.639690582954017],
              [-40.237499999525312, -7.639999992005983],
              [-40.235000000249784, -7.639999992905302],
              [-40.234999998451144, -7.64083332539758],
              [-40.231666666683282, -7.64083332539758],
              [-40.231666666683282, -7.641666658789234],
              [-40.229999999900031, -7.641666657889914],
              [-40.229999999000711, -7.644166658964082],
              [-40.229166665609114, -7.644166658964082],
              [-40.229166665609114, -7.644999991456416],
              [-40.224999998650958, -7.644999992355736],
              [-40.224999999550278, -7.645833324848013],
              [-40.224166665259361, -7.645833324848013],
              [-40.224166667058, -7.644999994154375],
              [-40.223333332767027, -7.644999992355736],
              [-40.223333333666346, -7.645833325747333],
              [-40.220833333491498, -7.645833325747333],
              [-40.220833332592179, -7.644999992355736],
              [-40.219999998301262, -7.644999992355736],
              [-40.219999998301262, -7.644166658964082],
              [-40.217499999025677, -7.644166658964082],
              [-40.217499999924996, -7.64249999308015],
              [-40.216666665634079, -7.64249999308015],
              [-40.216666666533399, -7.64083332539758],
              [-40.213333332966897, -7.64083332449826],
              [-40.213333332067577, -7.641666658789234],
              [-40.208333332617144, -7.641666658789234],
              [-40.208333331717824, -7.642499992180831],
              [-40.205833332442296, -7.64249999308015],
              [-40.205833332442296, -7.643333324673165],
              [-40.204166666558365, -7.643333325572485],
              [-40.204166667457685, -7.644166659863401],
              [-40.203333332267448, -7.644166659863401],
              [-40.203333332267448, -7.645833325747333],
              [-40.202500000674434, -7.645833325747333],
              [-40.202500000674434, -7.647499992530584],
              [-40.201666666383517, -7.647499992530584],
              [-40.201666666383517, -7.649166657515195],
              [-40.199999999600266, -7.649166658414515],
              [-40.199999999600266, -7.649999992705432],
              [-40.196666666933083, -7.649999992705432],
              [-40.196666666933083, -7.649166658414515],
              [-40.195833332642167, -7.649166658414515],
              [-40.195833332642167, -7.648333325922181],
              [-40.194999999250513, -7.648333325922181],
              [-40.194999998351193, -7.647499991631264],
              [-40.193333332467262, -7.647499992530584],
              [-40.193333333366581, -7.646666659138987],
              [-40.188333333016885, -7.646666659138987],
              [-40.188333333916205, -7.645833325747333],
              [-40.187500000524551, -7.645833325747333],
              [-40.187499998725912, -7.644166659863401],
              [-40.185000000349703, -7.644166658964082],
              [-40.184999998551064, -7.643333325572485],
              [-40.183333332667132, -7.643333325572485],
              [-40.183333332667132, -7.644166658964082],
              [-40.17999999910063, -7.644166658064762],
              [-40.18, -7.644999992355736],
              [-40.17833333141806, -7.644999992355736],
              [-40.178333332317379, -7.644166658964082],
              [-40.177499999825102, -7.644166659863401],
              [-40.177500000724422, -7.645833325747333],
              [-40.176666665534128, -7.645833325747333],
              [-40.176666667332768, -7.646666659138987],
              [-40.173333332867003, -7.646666659138987],
              [-40.173333333766323, -7.647499992530584],
              [-40.171666667882391, -7.647499992530584],
              [-40.171666666983072, -7.649166658414515],
              [-40.170833332692098, -7.649166658414515],
              [-40.170833332692098, -7.649999992705432],
              [-40.17000000109914, -7.649999992705432],
              [-40.169999999300501, -7.650833326097086],
              [-40.169166666808167, -7.650833326097086],
              [-40.169166665908847, -7.651666658589363],
              [-40.167499998226276, -7.651666659488683],
              [-40.167499997326956, -7.652499993779657],
              [-40.166666666633319, -7.652499991981017],
              [-40.166666667532638, -7.656666658039796],
              [-40.165833332342345, -7.656666658939116],
              [-40.165833332342345, -7.657499993230033],
              [-40.162499999675219, -7.657499993230033],
              [-40.1624999987759, -7.656666658939116],
              [-40.161666665384246, -7.656666658039796],
              [-40.161666668082205, -7.657499993230033],
              [-40.160833333791288, -7.657499993230033],
              [-40.160833332891968, -7.658333325722367],
              [-40.158333331817744, -7.658333325722367],
              [-40.158333332717064, -7.657499993230033],
              [-40.157500002023426, -7.657499991431393],
              [-40.157500000224786, -7.656666658939116],
              [-40.155833330743576, -7.656666658939116],
              [-40.155833333441535, -7.655833324648142],
              [-40.154999998251299, -7.655833325547462],
              [-40.154999998251299, -7.654999993055185],
              [-40.151666667382756, -7.654999993055185],
              [-40.151666665584116, -7.654166658764268],
              [-40.150000001498825, -7.654166658764268],
              [-40.149999999700185, -7.652499992880337],
              [-40.149166665409211, -7.652499992880337],
              [-40.149166666308588, -7.651666658589363],
              [-40.146666667033003, -7.651666659488683],
              [-40.146666667932323, -7.652499992880337],
              [-40.145000000249752, -7.652499992880337],
              [-40.144999999350432, -7.653333325372614],
              [-40.144166666858155, -7.653333325372614],
              [-40.144166667757474, -7.654166658764268],
              [-40.143333332567181, -7.654166657864948],
              [-40.143333332567181, -7.658333325722367],
              [-40.142499998276264, -7.658333325722367],
              [-40.142500000074904, -7.664999992855314],
              [-40.143333333466501, -7.664999992855314],
              [-40.143333331667861, -7.668333325522497],
              [-40.144166667757474, -7.668333325522497],
              [-40.144166665958835, -7.670833324798082],
              [-40.143333331667861, -7.670833324798082],
              [-40.143333331667861, -7.672499992480596],
              [-40.142500000074904, -7.672499993379915],
              [-40.142499997376945, -7.67333332497293],
              [-40.141666667582626, -7.67333332497293],
              [-40.14166666668325, -7.674999992655501],
              [-40.142499998276264, -7.674999992655501],
              [-40.142500000974223, -7.675833326047098],
              [-40.143333333466501, -7.675833326047098],
              [-40.143333332567181, -7.676666658539432],
              [-40.147499998626017, -7.676666658539432],
              [-40.147499999525337, -7.677499992830349],
              [-40.146666667033003, -7.677499992830349],
              [-40.146666665234363, -7.678333326222003],
              [-40.145833332742086, -7.678333326222003],
              [-40.145833332742086, -7.6791666596136],
              [-40.146666667033003, -7.67916665871428],
              [-40.146666665234363, -7.679999993005254],
              [-40.147499999525337, -7.679999993005254],
              [-40.147499998626017, -7.680833325497531],
              [-40.148333332916934, -7.680833325497531],
              [-40.148333332916934, -7.682499993180102],
              [-40.149166666308588, -7.682499991381462],
              [-40.149166666308588, -7.686666657440242],
              [-40.150833333091782, -7.686666659238881],
              [-40.150833333991102, -7.689999993704703],
              [-40.149999999700185, -7.689999992805383],
              [-40.149999999700185, -7.694999993155136],
              [-40.150833333991102, -7.694999993155136],
              [-40.150833333091782, -7.695833324748094],
              [-40.151666666483436, -7.695833326546733],
              [-40.151666665584116, -7.697499992430664],
              [-40.152500001673673, -7.697499991531345],
              [-40.152499998975713, -7.699999992605512],
              [-40.151666664684797, -7.699999992605512],
              [-40.151666665584116, -7.701666658489444],
              [-40.150833333091782, -7.701666658489444],
              [-40.150833333091782, -7.704166657764972],
              [-40.149999998800865, -7.704166658664292],
              [-40.150000000599505, -7.704999992955265],
              [-40.150833331293143, -7.704999992955265],
              [-40.150833333091782, -7.705833325447543],
              [-40.151666666483436, -7.705833325447543],
              [-40.151666665584116, -7.706666658839197],
              [-40.153333333266687, -7.706666658839197],
              [-40.153333331468048, -7.707499993130114],
              [-40.154166665758964, -7.707499993130114],
              [-40.154166666658284, -7.714999992755395],
              [-40.153333331468048, -7.714999992755395],
              [-40.153333332367367, -7.715833326147049],
              [-40.152500000774353, -7.715833327046369],
              [-40.152499998975713, -7.716666658639326],
              [-40.153333332367367, -7.716666659538646],
              [-40.153333330568728, -7.718333325422577],
              [-40.154166665758964, -7.718333324523257],
              [-40.154166667557604, -7.721666658989079],
              [-40.154999998251299, -7.721666659888399],
              [-40.155000000949258, -7.722499991481357],
              [-40.155833332542215, -7.722499994179316],
              [-40.155833332542215, -7.72333332577233],
              [-40.155000000949258, -7.72333332577233],
              [-40.155000000949258, -7.724166659163927],
              [-40.155833333441535, -7.724166659163927],
              [-40.155833331642896, -7.725833325947178],
              [-40.154166666658284, -7.725833325047859],
              [-40.154166667557604, -7.729166658614361],
              [-40.153333333266687, -7.729166658614361],
              [-40.153333331468048, -7.730833325397612],
              [-40.152499998975713, -7.730833325397612],
              [-40.152500000774353, -7.737499992530559],
              [-40.153333332367367, -7.737499991631239],
              [-40.153333331468048, -7.738333325922213],
              [-40.156666666833189, -7.738333326821532],
              [-40.156666665933869, -7.739166660213129],
              [-40.157499998426147, -7.73916665841449],
              [-40.157499999325466, -7.739999993604783],
              [-40.158333333616383, -7.739999993604783],
              [-40.158333331817744, -7.740833325197741],
              [-40.159166667907357, -7.740833326097061],
              [-40.159166667907357, -7.743333325372646],
              [-40.158333333616383, -7.743333325372646],
              [-40.158333331817744, -7.744166659663563],
              [-40.156666665933869, -7.744166658764243],
              [-40.156666667732509, -7.744999993055217],
              [-40.154999998251299, -7.744999993055217],
              [-40.154999997351979, -7.745833325547494],
              [-40.154166667557604, -7.745833325547494],
              [-40.154166667557604, -7.746666658939091],
              [-40.153333332367367, -7.746666658939091],
              [-40.153333333266687, -7.747499993230065],
              [-40.150833333091782, -7.747499993230065],
              [-40.150833333991102, -7.748333325722342],
              [-40.149166665409211, -7.748333325722342],
              [-40.149166665409211, -7.749166660013316],
              [-40.147499998626017, -7.749166658214676],
              [-40.147499998626017, -7.749999992505593],
              [-40.146666667033003, -7.749999992505593],
              [-40.146666665234363, -7.750833325897247],
              [-40.145833332742086, -7.750833326796567],
              [-40.145833333641406, -7.751666658389524],
              [-40.14166666578393, -7.751666657490205],
              [-40.14166666668325, -7.750833326796567],
              [-40.139166666508402, -7.750833325897247],
              [-40.139166667407721, -7.752499993579818],
              [-40.137499998825831, -7.752499991781121],
              [-40.137499999725151, -7.753333326072095],
              [-40.136666665434234, -7.753333325172775],
              [-40.136666666333554, -7.754166658564372],
              [-40.134999999550303, -7.754166659463692],
              [-40.134999998650983, -7.753333326072095],
              [-40.134166667957345, -7.753333326072095],
              [-40.134166665259329, -7.751666658389524],
              [-40.133333331867732, -7.751666658389524],
              [-40.133333333666371, -7.750833325897247],
              [-40.131666665983801, -7.750833325897247],
              [-40.13166666688312, -7.749999992505593],
              [-40.130833332592204, -7.749999992505593],
              [-40.130833331692884, -7.751666657490205],
              [-40.130000000099869, -7.751666658389524],
              [-40.12999999830123, -7.753333326072095],
              [-40.129166665808953, -7.753333326971415],
              [-40.129166665808953, -7.754999991956026],
              [-40.128333331517979, -7.754999992855346],
              [-40.128333333316618, -7.757499993030194],
              [-40.127499999925021, -7.757499993030194],
              [-40.127500000824341, -7.758333325522528],
              [-40.126666664734728, -7.758333325522528],
              [-40.126666664734728, -7.759166658914125],
              [-40.124166666358519, -7.759166658914125],
              [-40.124166666358519, -7.759999993205099],
              [-40.123333332067602, -7.759999993205099],
              [-40.123333332067602, -7.760833325697376],
              [-40.122499998675949, -7.760833325697376],
              [-40.122499998675949, -7.761666659088974],
              [-40.121666665284351, -7.761666659088974],
              [-40.121666664385032, -7.762499992480627],
              [-40.120833332792017, -7.762499993379947],
              [-40.120833331892698, -7.763333325872225],
              [-40.1199999985011, -7.763333324972905],
              [-40.1199999985011, -7.764166658364559],
              [-40.117499998326196, -7.764166660163198],
              [-40.117499999225515, -7.764999991756156],
              [-40.116666664934598, -7.764999991756156],
              [-40.116666665833918, -7.76583332604713],
              [-40.114999999050667, -7.76583332604713],
              [-40.114999999949987, -7.766666658539407],
              [-40.11416666745771, -7.766666658539407],
              [-40.11416666745771, -7.767499992830381],
              [-40.113333334066056, -7.767499992830381],
              [-40.113333332267416, -7.768333327121297],
              [-40.112499998875819, -7.768333327121297],
              [-40.112500000674459, -7.769166658714255],
              [-40.111666665484165, -7.769166658714255],
              [-40.111666666383485, -7.769999993005229],
              [-40.110833332991888, -7.769999993005229],
              [-40.110833332991888, -7.770833325497506],
              [-40.109999999600234, -7.770833325497506],
              [-40.110000000499554, -7.77166665888916],
              [-40.108333333716359, -7.77166665888916],
              [-40.108333331917663, -7.772499993180077],
              [-40.106666666933108, -7.772499991381437],
              [-40.106666666933108, -7.77166665888916],
              [-40.104999999250538, -7.77166665888916],
              [-40.105000001049177, -7.770833325497506],
              [-40.102499999974953, -7.770833325497506],
              [-40.102499999075633, -7.769999993005229],
              [-40.099166666408507, -7.769999993005229],
              [-40.099166665509188, -7.77166665888916],
              [-40.096666666233602, -7.77166665978848],
              [-40.096666666233602, -7.772499991381437],
              [-40.095833331942686, -7.772499993180077],
              [-40.095833334640645, -7.773333325672411],
              [-40.094999999450351, -7.773333325672411],
              [-40.095000000349671, -7.774166659064008],
              [-40.094999998551032, -7.774999992455662],
              [-40.095833331942686, -7.774999992455662],
              [-40.095833331942686, -7.776666657440217],
              [-40.096666666233602, -7.776666659238913],
              [-40.096666668032242, -7.779166658514441],
              [-40.097499999625256, -7.779166658514441],
              [-40.097499999625256, -7.781666658689289],
              [-40.098333331218214, -7.78166665778997],
              [-40.098333333016853, -7.784166659763514],
              [-40.099166664609868, -7.784166658864194],
              [-40.099166667307827, -7.784999993155111],
              [-40.099999998900785, -7.784999993155111],
              [-40.099999998900785, -7.785833325647388],
              [-40.101666667482675, -7.785833325647388],
              [-40.101666665684036, -7.786666659039042],
              [-40.100833330493742, -7.786666659039042],
              [-40.100833334091078, -7.789999992605544],
              [-40.100000000699424, -7.789999993504864],
              [-40.100000001598744, -7.792499992780392],
              [-40.099166666408507, -7.792499992780392],
              [-40.099166667307827, -7.793333326172046],
              [-40.098333331218214, -7.793333326172046],
              [-40.098333333916173, -7.794166658664324],
              [-40.099166666408507, -7.794166658664324],
              [-40.099166665509188, -7.795833325447575],
              [-40.100000000699424, -7.795833325447575],
              [-40.099999999800104, -7.796666658839172],
              [-40.100833334091078, -7.796666658839172],
              [-40.100833332292382, -7.798333325622423],
              [-40.099999998900785, -7.798333326521742],
              [-40.100000000699424, -7.799166658114757],
              [-40.099166668207147, -7.799166659014077],
              [-40.099166665509188, -7.800833325797328],
              [-40.098333334815493, -7.800833325797328],
              [-40.098333331218214, -7.801666659188925],
              [-40.097499999625256, -7.801666659188925],
              [-40.097499997826617, -7.802499992580522],
              [-40.096666665334283, -7.802499992580522],
              [-40.096666668032242, -7.803333326871496],
              [-40.095833331942686, -7.803333325972176],
              [-40.095833331942686, -7.805833326147024],
              [-40.094999998551032, -7.805833326147024],
              [-40.094999997651712, -7.807499992930275],
              [-40.094166667857394, -7.807499992930275],
              [-40.094166667857394, -7.810833325597457],
              [-40.094999999450351, -7.810833324698137],
              [-40.094999999450351, -7.812499992380708],
              [-40.095833332842005, -7.812499992380708],
              [-40.095833332842005, -7.820833325397587],
              [-40.096666665334283, -7.820833325397587],
              [-40.096666666233602, -7.824166658964089],
              [-40.097499999625256, -7.824166659863408],
              [-40.097500001423896, -7.828333325922188],
              [-40.097499998725937, -7.829166658414522],
              [-40.098333333016853, -7.829166657515202],
              [-40.098333333016853, -7.829999992705439],
              [-40.099166667307827, -7.829999991806119],
              [-40.099166666408507, -7.830833326097093],
              [-40.100000000699424, -7.830833326097093],
              [-40.099999998900785, -7.83166665858937],
              [-40.100833332292382, -7.83166665858937],
              [-40.100833334091078, -7.832499992880344],
              [-40.101666666583355, -7.832499992880344],
              [-40.101666666583355, -7.833333325372621],
              [-40.103333331567967, -7.833333325372621],
              [-40.103333331567967, -7.834999993055192],
              [-40.104166667657523, -7.834999993055192],
              [-40.104166667657523, -7.838333325722374],
              [-40.103333332467287, -7.838333325722374],
              [-40.103333332467287, -7.839999992505625],
              [-40.102499999974953, -7.839999992505625],
              [-40.102499999974953, -7.841666658389499],
              [-40.101666666583355, -7.841666658389499],
              [-40.101666666583355, -7.84333332517275],
              [-40.101666665684036, -7.844166657665085],
              [-40.102500000874272, -7.844166658564404],
              [-40.102500000874272, -7.845833325347655],
              [-40.103333333366606, -7.845833325347655],
              [-40.103333331567967, -7.847499993030226],
              [-40.104166665858884, -7.847499993030226],
              [-40.104166666758204, -7.848333325522503],
              [-40.104999999250538, -7.848333325522503],
              [-40.105000001049177, -7.849166658914157],
              [-40.105833332642135, -7.849166658914157],
              [-40.105833332642135, -7.854999991756188],
              [-40.10833333281704, -7.854999993554827],
              [-40.108333331917663, -7.854166658364534],
              [-40.109166666208637, -7.854166658364534],
              [-40.109166666208637, -7.854999992655507],
              [-40.109999999600234, -7.854999992655507],
              [-40.109999998700914, -7.856666658539439],
              [-40.112499999775139, -7.856666658539439],
              [-40.112499998875819, -7.857499992830355],
              [-40.116666665833918, -7.857499992830355],
              [-40.116666667632558, -7.858333326221953],
              [-40.117499998326196, -7.858333326221953],
              [-40.117499998326196, -7.859166658714287],
              [-40.116666666733238, -7.859166658714287],
              [-40.116666665833918, -7.862499993180109],
              [-40.115833332442321, -7.862499993180109],
              [-40.115833334240961, -7.863333325672386],
              [-40.114999999949987, -7.863333325672386],
              [-40.114999999949987, -7.865833325847291],
              [-40.11416666655839, -7.865833325847291],
              [-40.11416666655839, -7.868333326022139],
              [-40.115833331543001, -7.868333326022139],
              [-40.115833333341641, -7.86999999280539],
              [-40.116666667632558, -7.86999999280539],
              [-40.116666666733238, -7.873333325472572],
              [-40.117499998326196, -7.873333325472572],
              [-40.117499998326196, -7.874999993155086],
              [-40.118333333516489, -7.874999994054463],
              [-40.118333333516489, -7.87583332564742],
              [-40.119166667807406, -7.87583332564742],
              [-40.119166666908086, -7.876666659039017],
              [-40.11999999940042, -7.876666659039017],
              [-40.1199999985011, -7.877499992430671],
              [-40.120833333691337, -7.877499992430671],
              [-40.120833331892698, -7.878333325822268],
              [-40.124166666358519, -7.878333325822268],
              [-40.1241666654592, -7.879166660113242],
              [-40.124999998850797, -7.879166659213922],
              [-40.125000000649493, -7.879999992605519],
              [-40.126666665634048, -7.879999992605519],
              [-40.126666667432687, -7.880833325997173],
              [-40.129166665808953, -7.880833325997173],
              [-40.129166666708272, -7.881666658489451],
              [-40.130833333491523, -7.881666658489451],
              [-40.130833332592204, -7.883333326172021],
              [-40.13166666688312, -7.883333326172021],
              [-40.13166666688312, -7.884166658664299],
              [-40.133333331867732, -7.884166658664299],
              [-40.133333333666371, -7.884999992955272],
              [-40.134999998650983, -7.884999992955272],
              [-40.134999998650983, -7.88583332544755],
              [-40.136666667232873, -7.885833326346869],
              [-40.136666666333554, -7.886666658839204],
              [-40.138333332217485, -7.886666658839204],
              [-40.138333334016124, -7.887499992230801],
              [-40.140833331493013, -7.88749999313012],
              [-40.140833332392333, -7.888333326521774],
              [-40.144166665958835, -7.888333324723135],
              [-40.144166666858155, -7.889999992405706],
              [-40.144999998451112, -7.889999992405706],
              [-40.144999998451112, -7.890833325797303],
              [-40.146666665234363, -7.890833324897983],
              [-40.146666665234363, -7.889999991506329],
              [-40.147500000424657, -7.889999992405706],
              [-40.147499999525337, -7.890833324897983],
              [-40.149999999700185, -7.890833325797303],
              [-40.149999998800865, -7.8916666591889],
              [-40.154166665758964, -7.89166665739026],
              [-40.154166665758964, -7.884999992955272],
              [-40.154999998251299, -7.884999992955272],
              [-40.155000000049938, -7.884166659563618],
              [-40.154166666658284, -7.884166658664299],
              [-40.154166665758964, -7.883333326172021],
              [-40.160833331992649, -7.883333326172021],
              [-40.160833333791288, -7.881666658489451],
              [-40.161666666283566, -7.881666657590131],
              [-40.161666668082205, -7.879999992605519],
              [-40.162499999675219, -7.879999992605519],
              [-40.162499999675219, -7.879166659213922],
              [-40.163333333066817, -7.879166659213922],
              [-40.163333331268177, -7.878333325822268],
              [-40.16416666735779, -7.878333325822268],
              [-40.164166664659831, -7.877499992430671],
              [-40.166666666633319, -7.877499992430671],
              [-40.166666664834679, -7.878333325822268],
              [-40.167499998226276, -7.878333325822268],
              [-40.167500000924235, -7.879166659213922],
              [-40.16833333251725, -7.879166657415283],
              [-40.16833333161793, -7.879999992605519],
              [-40.170833331792778, -7.879999992605519],
              [-40.170833332692098, -7.879166659213922],
              [-40.171666666983072, -7.879166659213922],
              [-40.171666667882391, -7.878333325822268],
              [-40.172500000374669, -7.878333324922949],
              [-40.172499999475349, -7.877499992430671],
              [-40.173333332867003, -7.877499992430671],
              [-40.173333333766323, -7.87583332564742],
              [-40.17416666535928, -7.87583332564742],
              [-40.1741666662586, -7.874999994054463],
              [-40.174999997851558, -7.874999993155086],
              [-40.174999998750877, -7.874166658864169],
              [-40.175833333041851, -7.874166658864169],
              [-40.175833333041851, -7.873333325472572],
              [-40.17833333141806, -7.873333325472572],
              [-40.17833333141806, -7.871666658689321],
              [-40.179166665709033, -7.871666657790001],
              [-40.179166667507673, -7.870833326196987],
              [-40.17999999910063, -7.870833326196987],
              [-40.18, -7.86999999280539],
              [-40.180833331592908, -7.86999999280539],
              [-40.180833331592908, -7.868333326022139],
              [-40.181666665883881, -7.868333326022139],
              [-40.181666667682521, -7.866666659238888],
              [-40.180833332492284, -7.866666659238888],
              [-40.180833331592908, -7.862499993180109],
              [-40.181666665883881, -7.862499993180109],
              [-40.181666666783201, -7.859999993005204],
              [-40.182499999275478, -7.859999993005204],
              [-40.182499999275478, -7.859166658714287],
              [-40.183333332667132, -7.859166658714287],
              [-40.183333332667132, -7.858333326221953],
              [-40.184166666058729, -7.858333326221953],
              [-40.184166667857369, -7.857499992830355],
              [-40.184999998551064, -7.857499992830355],
              [-40.184999999450383, -7.856666658539439],
              [-40.187499998725912, -7.856666658539439],
              [-40.187499999625231, -7.857499993729675],
              [-40.189166667307802, -7.857499992830355],
              [-40.189166665509163, -7.856666658539439],
              [-40.190833334091053, -7.856666657640119],
              [-40.190833332292414, -7.855833326047104],
              [-40.19166666658333, -7.855833326047104],
              [-40.19166666658333, -7.856666659438758],
              [-40.192500000874304, -7.856666658539439],
              [-40.192499999075665, -7.855833326047104],
              [-40.196666666033764, -7.855833326946424],
              [-40.196666666933083, -7.854166658364534],
              [-40.195833331742847, -7.854166658364534],
              [-40.195833331742847, -7.853333325872256],
              [-40.195000001049152, -7.853333325872256],
              [-40.194999998351193, -7.849999993205074],
              [-40.194166667657555, -7.849999993205074],
              [-40.194166666758235, -7.849166658914157],
              [-40.195833331742847, -7.849166658914157],
              [-40.195833331742847, -7.845833324448336],
              [-40.196666667832403, -7.845833324448336],
              [-40.196666667832403, -7.841666658389499],
              [-40.197499999425418, -7.841666658389499],
              [-40.197499998526041, -7.839166659113971],
              [-40.201666664584877, -7.839166659113971],
              [-40.201666666383517, -7.838333325722374],
              [-40.202499998875794, -7.838333324823054],
              [-40.202499999775114, -7.83749999323004],
              [-40.203333332267448, -7.83749999323004],
              [-40.203333331368128, -7.836666658939123],
              [-40.204166666558365, -7.836666658939123],
              [-40.204166666558365, -7.835833324648149],
              [-40.204999999950019, -7.835833326446789],
              [-40.204999999950019, -7.834999993954511],
              [-40.205833332442296, -7.834999993954511],
              [-40.205833332442296, -7.834166658764218],
              [-40.209166666908118, -7.834166658764218],
              [-40.210833331892729, -7.834166658764218],
              [-40.210833331892729, -7.834999993055192],
              [-40.214166667257871, -7.834999993055192],
              [-40.214166665459175, -7.83749999323004],
              [-40.214999998850828, -7.83749999323004],
              [-40.214999998850828, -7.844166658564404],
              [-40.214166665459175, -7.844166658564404],
              [-40.214166667257871, -7.847499993030226],
              [-40.214999999750148, -7.847499993030226],
              [-40.214999998850828, -7.851666659089005],
              [-40.215833330443786, -7.851666659089005],
              [-40.215833332242426, -7.853333325872256],
              [-40.216666667432719, -7.853333324972937],
              [-40.216666666533399, -7.855833326047104],
              [-40.217499999924996, -7.855833326047104],
              [-40.217499999025677, -7.856666658539439],
              [-40.21833333331665, -7.856666659438758],
              [-40.218333331518011, -7.857499993729675],
              [-40.219166667607567, -7.857499992830355],
              [-40.219166665808928, -7.858333326221953],
              [-40.220000000099901, -7.858333326221953],
              [-40.220000000099901, -7.859166657814967],
              [-40.220833332592179, -7.859166658714287],
              [-40.220833331692859, -7.859999993005204],
              [-40.222500000274749, -7.859999993005204],
              [-40.22249999937543, -7.860833326396858],
              [-40.223333331867707, -7.860833325497538],
              [-40.223333331867707, -7.861666658889135],
              [-40.224166665259361, -7.861666658889135],
              [-40.224166665259361, -7.86416665906404],
              [-40.224999998650958, -7.86416665906404],
              [-40.225000000449597, -7.864999992455637],
              [-40.227499999725183, -7.864999992455637],
              [-40.227500000624502, -7.86416665906404],
              [-40.22833333221746, -7.86416665906404],
              [-40.22833333131814, -7.863333325672386],
              [-40.229166667407753, -7.863333325672386],
              [-40.229166667407753, -7.862499993180109],
              [-40.22833333131814, -7.862499993180109],
              [-40.22833333221746, -7.861666658889135],
              [-40.229166666508434, -7.861666659788455],
              [-40.229166666508434, -7.860833325497538],
              [-40.230833332392308, -7.860833325497538],
              [-40.230833331492988, -7.858333326221953],
              [-40.231666665783962, -7.858333326221953],
              [-40.231666666683282, -7.859166659613606],
              [-40.232500000074879, -7.859166658714287],
              [-40.232499998276239, -7.859999993005204],
              [-40.233333333466533, -7.859999993005204],
              [-40.233333331667893, -7.863333325672386],
              [-40.23416666685813, -7.863333325672386],
              [-40.23416666685813, -7.86416665906404],
              [-40.234166667757449, -7.864999992455637],
              [-40.235833333641381, -7.864999992455637],
              [-40.235833331842741, -7.865833325847291],
              [-40.236666665234395, -7.865833325847291],
              [-40.236666667033035, -7.864999992455637],
              [-40.240833333091814, -7.864999992455637],
              [-40.240833333991134, -7.865833325847291],
              [-40.242499999875065, -7.865833325847291],
              [-40.242499999875065, -7.864999992455637],
              [-40.244999998251274, -7.864999992455637],
              [-40.244999998251274, -7.86416665906404],
              [-40.247499998426122, -7.86416665906404],
              [-40.247499998426122, -7.863333325672386],
              [-40.248333331817776, -7.863333325672386],
              [-40.248333333616415, -7.862499993180109],
              [-40.249999998601027, -7.862499993180109],
              [-40.249999999500346, -7.861666658889135],
              [-40.251666666283597, -7.861666658889135],
              [-40.251666668082237, -7.859999993005204],
              [-40.252499998775875, -7.859999993005204],
              [-40.252499998775875, -7.859166658714287],
              [-40.253333333966168, -7.859166658714287],
              [-40.253333331268209, -7.858333326221953],
              [-40.254166668257085, -7.858333324423313],
              [-40.254166667357765, -7.857499993729675],
              [-40.255833331443057, -7.857499992830355],
              [-40.255833332342377, -7.855833326047104],
              [-40.257500000924267, -7.855833326047104],
              [-40.257500000024947, -7.854999992655507],
              [-40.259166667707518, -7.854999992655507],
              [-40.259166665908879, -7.852499992480602],
              [-40.259999998401156, -7.852499992480602],
              [-40.259999998401156, -7.851666659089005],
              [-40.260833333591449, -7.851666659089005],
              [-40.26083333269213, -7.850833325697351],
              [-40.261666667882366, -7.850833326596671],
              [-40.261666664285087, -7.846666658739252],
              [-40.262499998576004, -7.846666658739252],
              [-40.262499998576004, -7.844999992855321],
              [-40.265833333041826, -7.844999992855321],
              [-40.265833331243186, -7.844166658564404],
              [-40.267500000724397, -7.844166658564404],
              [-40.267500000724397, -7.84333332607207],
              [-40.270833332492259, -7.84333332607207],
              [-40.27083333069362, -7.840833325897222],
              [-40.271666666783176, -7.840833324997902],
              [-40.271666665883856, -7.838333325722374],
              [-40.27249999837619, -7.838333325722374],
              [-40.274999998551039, -7.838333324823054],
              [-40.275000000349678, -7.83749999323004],
              [-40.275833332842012, -7.83749999323004],
              [-40.275833333741332, -7.836666658939123],
              [-40.276666668032249, -7.836666658939123],
              [-40.276666666233609, -7.835833324648149],
              [-40.278333331218221, -7.835833325547469],
              [-40.27833333391618, -7.834999993055192],
              [-40.281666666583362, -7.834999993055192],
              [-40.281666665684043, -7.833333325372621],
              [-40.283333332467294, -7.833333324473301],
              [-40.283333331567974, -7.832499992880344],
              [-40.28416666675821, -7.832499992880344],
              [-40.28416666765753, -7.83166665858937],
              [-40.286666666033796, -7.83166665858937],
              [-40.286666666033796, -7.830833326097093],
              [-40.289166665309324, -7.830833326097093],
              [-40.289166668007283, -7.829999992705439],
              [-40.290833332991895, -7.829999992705439],
              [-40.290833332092575, -7.829166658414522],
              [-40.293333332267423, -7.829166657515202],
              [-40.293333332267423, -7.826666659138937],
              [-40.294166665659077, -7.826666659138937],
              [-40.294166665659077, -7.82583332574734],
              [-40.295833331542951, -7.82583332574734],
              [-40.295833331542951, -7.826666659138937],
              [-40.296666666733245, -7.826666659138937],
              [-40.296666665833925, -7.82583332574734],
              [-40.297499999225522, -7.82583332574734],
              [-40.297499998326202, -7.826666659138937],
              [-40.298333331717856, -7.826666659138937],
              [-40.298333331717856, -7.827499992530591],
              [-40.299166666908093, -7.827499992530591],
              [-40.299166667807413, -7.82583332574734],
              [-40.300833333691344, -7.825833326646659],
              [-40.300833332792024, -7.824999992355743],
              [-40.301666664385039, -7.824999992355743],
              [-40.301666665284358, -7.824166658964089],
              [-40.302499998675955, -7.824166658064769],
              [-40.302500001373915, -7.822499993080157],
              [-40.303333332067552, -7.822499993080157],
              [-40.303333332966872, -7.821666658789241],
              [-40.308333331517986, -7.821666658789241],
              [-40.308333332417305, -7.822499993080157],
              [-40.309166667607599, -7.822499993080157],
              [-40.309166666708279, -7.819166658614336],
              [-40.311666665983807, -7.819166658614336],
              [-40.311666667782447, -7.817499992730461],
              [-40.313333331867739, -7.817499992730461],
              [-40.313333332767058, -7.818333327021378],
              [-40.314166667057975, -7.818333326122058],
              [-40.314166667057975, -7.816666658439487],
              [-40.315000000449629, -7.816666658439487],
              [-40.314999999550309, -7.814166659163959],
              [-40.31666666723288, -7.814166659163959],
              [-40.31666666723288, -7.813333325772305],
              [-40.317499999725158, -7.813333325772305],
              [-40.317499998825838, -7.811666658989054],
              [-40.31666666723288, -7.811666658989054],
              [-40.316666665434241, -7.810833324698137],
              [-40.318333332217492, -7.810833325597457],
              [-40.318333332217492, -7.809999993105123],
              [-40.319999999000686, -7.809999993105123],
              [-40.319999999000686, -7.809166657914886],
              [-40.321666667582576, -7.809166659713526],
              [-40.321666665783937, -7.808333325422609],
              [-40.322500000074911, -7.808333325422609],
              [-40.322499998276271, -7.807499992930275],
              [-40.328333332916941, -7.807499992930275],
              [-40.328333332916941, -7.806666659538678],
              [-40.329166666308538, -7.806666658639358],
              [-40.329166665409218, -7.805833326147024],
              [-40.33083333129315, -7.805833324348384],
              [-40.330833333091789, -7.804166658464453],
              [-40.33249999987504, -7.804166658464453],
              [-40.33249999987504, -7.796666658839172],
              [-40.333333333266694, -7.796666658839172],
              [-40.333333331468054, -7.794166658664324],
              [-40.329999998800872, -7.794166658664324],
              [-40.329999999700192, -7.793333326172046],
              [-40.329166666308538, -7.79333332437335],
              [-40.329166666308538, -7.792499991881073],
              [-40.330000000599512, -7.792499993679712],
              [-40.329999997901552, -7.791666658489476],
              [-40.330833333091789, -7.791666658489476],
              [-40.330833333091789, -7.788333325822293],
              [-40.329999998800872, -7.788333326721613],
              [-40.329999998800872, -7.787499992430639],
              [-40.329166665409218, -7.787499992430639],
              [-40.329166669006554, -7.786666659039042],
              [-40.328333332916941, -7.786666659039042],
              [-40.328333333816261, -7.784999993155111],
              [-40.325000000249759, -7.784999993155111],
              [-40.324999999350439, -7.78333332547254],
              [-40.324166666858162, -7.78333332547254],
              [-40.32083333149302, -7.78333332547254],
              [-40.32083333149302, -7.782499992980263],
              [-40.319166667407728, -7.782499992980263],
              [-40.319166666508409, -7.781666658689289],
              [-40.317500000624477, -7.781666658689289],
              [-40.317499998825838, -7.780833324398372],
              [-40.318333330418795, -7.780833326197012],
              [-40.318333332217492, -7.779166658514441],
              [-40.317499998825838, -7.779166659413761],
              [-40.317499998825838, -7.779999992805358],
              [-40.31666666633356, -7.779999991906038],
              [-40.31666666633356, -7.779166658514441],
              [-40.315833332941907, -7.779166658514441],
              [-40.315833332941907, -7.774166659064008],
              [-40.315000000449629, -7.774166659064008],
              [-40.31499999865099, -7.772499993180077],
              [-40.314166667057975, -7.772499993180077],
              [-40.314166667957295, -7.769999993005229],
              [-40.313333333666378, -7.769999993005229],
              [-40.313333330968419, -7.767499992830381],
              [-40.312500000274724, -7.767499992830381],
              [-40.312499999375405, -7.766666658539407],
              [-40.311666666883127, -7.766666658539407],
              [-40.311666665983807, -7.76583332604713],
              [-40.31083333259221, -7.76583332604713],
              [-40.31083333349153, -7.764999992655476],
              [-40.309999998301237, -7.764999992655476],
              [-40.310000000999196, -7.763333325872225],
              [-40.309166667607599, -7.763333325872225],
              [-40.309166667607599, -7.761666658189654],
              [-40.308333332417305, -7.761666659088974],
              [-40.308333332417305, -7.760833325697376],
              [-40.307499999025708, -7.760833324798057],
              [-40.307499999925028, -7.759999994104419],
              [-40.306666665634054, -7.759999993205099],
              [-40.305833331343138, -7.75999999140646],
              [-40.305833332242457, -7.761666659088974],
              [-40.306666667432694, -7.761666659088974],
              [-40.306666665634054, -7.764166658364559],
              [-40.309166665808959, -7.764166658364559],
              [-40.309166667607599, -7.764999992655476],
              [-40.307500001723668, -7.764999991756156],
              [-40.307500000824348, -7.76583332604713],
              [-40.306666667432694, -7.76583332604713],
              [-40.306666667432694, -7.766666658539407],
              [-40.305833334041097, -7.766666659438727],
              [-40.305833332242457, -7.767499991931061],
              [-40.304999999750123, -7.767499992830381],
              [-40.304999999750123, -7.768333326221978],
              [-40.304166665459206, -7.768333326221978],
              [-40.304166666358526, -7.769166658714255],
              [-40.303333330268913, -7.769166658714255],
              [-40.303333333866249, -7.769999993005229],
              [-40.302500001373915, -7.769999993005229],
              [-40.302499998675955, -7.770833325497506],
              [-40.299999999400427, -7.770833325497506],
              [-40.299999999400427, -7.769999993904548],
              [-40.296666666733245, -7.769999993005229],
              [-40.296666665833925, -7.769166658714255],
              [-40.294999999050674, -7.769166658714255],
              [-40.294999999050674, -7.768333326221978],
              [-40.293333332267423, -7.768333326221978],
              [-40.293333331368103, -7.767499992830381],
              [-40.290833333891214, -7.767499992830381],
              [-40.290833332991895, -7.766666659438727],
              [-40.28833333191767, -7.766666657640087],
              [-40.28833333191767, -7.767499992830381],
              [-40.287499999425393, -7.767499992830381],
              [-40.287499999425393, -7.768333327121297],
              [-40.286666666933115, -7.768333324423338],
              [-40.286666666033796, -7.7674999937297],
              [-40.284166665858891, -7.767499992830381],
              [-40.28416666675821, -7.766666658539407],
              [-40.283333331567974, -7.766666658539407],
              [-40.283333333366613, -7.767499992830381],
              [-40.282499999974959, -7.767499992830381],
              [-40.282500000874279, -7.766666658539407],
              [-40.281666665684043, -7.766666658539407],
              [-40.281666665684043, -7.764999992655476],
              [-40.28249999907564, -7.764999992655476],
              [-40.282500000874279, -7.764166658364559],
              [-40.281666665684043, -7.764166660163198],
              [-40.281666668382002, -7.763333325872225],
              [-40.280833331393069, -7.763333325872225],
              [-40.280833330493749, -7.760833325697376],
              [-40.280000001598751, -7.760833325697376],
              [-40.279999998900792, -7.758333325522528],
              [-40.278333331218221, -7.758333325522528],
              [-40.27833333391618, -7.756666658739277],
              [-40.277500000524583, -7.756666659638597],
              [-40.277499999625263, -7.755833325347623],
              [-40.275833332842012, -7.755833325347623],
              [-40.275833331942692, -7.754999992855346],
              [-40.273333331767788, -7.754999991956026],
              [-40.273333332667107, -7.754166658564372],
              [-40.271666665883856, -7.754166658564372],
              [-40.271666665883856, -7.752499993579818],
              [-40.270833332492259, -7.752499992680498],
              [-40.270833333391579, -7.748333325722342],
              [-40.270000000899302, -7.748333324823022],
              [-40.270000000899302, -7.745833325547494],
              [-40.27083333069362, -7.745833325547494],
              [-40.270833331592939, -7.74499999215584],
              [-40.271666664984537, -7.744999993055217],
              [-40.271666666783176, -7.744166658764243],
              [-40.273333331767788, -7.744166658764243],
              [-40.273333332667107, -7.743333325372646],
              [-40.274999999450358, -7.743333324473326],
              [-40.274999998551039, -7.741666658589395],
              [-40.277499999625263, -7.741666657690075],
              [-40.277500000524583, -7.742499992880312],
              [-40.280000000699431, -7.742499993779631],
              [-40.279999999800111, -7.740833326097061],
              [-40.280833331393069, -7.740833326097061],
              [-40.280833331393069, -7.739999992705464],
              [-40.281666667482682, -7.739999993604783],
              [-40.281666665684043, -7.73916665841449],
              [-40.28249999907564, -7.73916665841449],
              [-40.282499999974959, -7.738333325022893],
              [-40.281666665684043, -7.738333325922213],
              [-40.281666665684043, -7.731666659688528],
              [-40.280833332292389, -7.731666658789209],
              [-40.280833331393069, -7.729999992905277],
              [-40.279999998900792, -7.729999992905277],
              [-40.280000000699431, -7.727499992730429],
              [-40.278333331218221, -7.727499992730429],
              [-40.27833333301686, -7.725833325947178],
              [-40.27666666533429, -7.725833325047859],
              [-40.276666668032249, -7.724999991656261],
              [-40.275833333741332, -7.724999992555581],
              [-40.275833331942692, -7.724166659163927],
              [-40.275000000349678, -7.724166659163927],
              [-40.274999999450358, -7.722499994179316],
              [-40.275833331942692, -7.722499992380676],
              [-40.275833333741332, -7.721666658989079],
              [-40.274999999450358, -7.721666658989079],
              [-40.274999998551039, -7.719166658814231],
              [-40.274166666958081, -7.719166658814231],
              [-40.274166666058761, -7.718333327221217],
              [-40.273333331767788, -7.718333325422577],
              [-40.273333333566427, -7.716666658639326],
              [-40.27249999837619, -7.716666658639326],
              [-40.27250000107415, -7.712499992580547],
              [-40.271666665883856, -7.712499992580547],
              [-40.271666666783176, -7.709999992405699],
              [-40.271666666783176, -7.709166659014045],
              [-40.27083333069362, -7.709166658114725],
              [-40.270833333391579, -7.707499994029433],
              [-40.271666665883856, -7.707499993130114],
              [-40.271666667682553, -7.705833324548223],
              [-40.27249999927551, -7.705833325447543],
              [-40.27249999837619, -7.702499991881098],
              [-40.274166666058761, -7.702499993679737],
              [-40.274166666058761, -7.700833325997166],
              [-40.275000000349678, -7.700833325997166],
              [-40.274999998551039, -7.699999991706193],
              [-40.276666668032249, -7.699999992605512],
              [-40.276666668931568, -7.698333325822261],
              [-40.277499999625263, -7.698333324922942],
              [-40.277499997826624, -7.697499992430664],
              [-40.27833333391618, -7.697499994229304],
              [-40.27833333301686, -7.695833325647413],
              [-40.280833334091028, -7.695833326546733],
              [-40.280833331393069, -7.694999993155136],
              [-40.283333331567974, -7.694999991356497],
              [-40.283333331567974, -7.694166659763482],
              [-40.28416666765753, -7.694166658864162],
              [-40.284166665858891, -7.693333325472565],
              [-40.284999999250545, -7.693333325472565],
              [-40.285000001049184, -7.694166658864162],
              [-40.285833331742822, -7.694166658864162],
              [-40.285833333541461, -7.694999994054456],
              [-40.286666666033796, -7.694999993155136],
              [-40.286666666033796, -7.695833325647413],
              [-40.287499998526073, -7.695833325647413],
              [-40.287499999425393, -7.69666665903901],
              [-40.289166665309324, -7.69666665903901],
              [-40.289166666208644, -7.697499992430664],
              [-40.290833332991895, -7.697499992430664],
              [-40.290833332991895, -7.698333325822261],
              [-40.291666665484172, -7.698333325822261],
              [-40.291666667282811, -7.699166659213915],
              [-40.293333331368103, -7.699166659213915],
              [-40.293333331368103, -7.703333326172014],
              [-40.295833331542951, -7.703333326172014],
              [-40.295833333341591, -7.704166658664292],
              [-40.296666667632564, -7.704166659563668],
              [-40.296666666733245, -7.705833325447543],
              [-40.298333331717856, -7.705833324548223],
              [-40.298333332617176, -7.706666657939877],
              [-40.299166666908093, -7.706666658839197],
              [-40.299166666008773, -7.707499993130114],
              [-40.299999998501107, -7.707499992230794],
              [-40.299999998501107, -7.708333325622448],
              [-40.300833333691344, -7.708333325622448],
              [-40.300833334590664, -7.709166659014045],
              [-40.302499998675955, -7.709166659014045],
              [-40.302499998675955, -7.709999992405699],
              [-40.303333334765568, -7.709999992405699],
              [-40.303333332067552, -7.710833324897976],
              [-40.304166666358526, -7.710833325797296],
              [-40.304166667257846, -7.71166665918895],
              [-40.304999999750123, -7.71166665918895],
              [-40.305000000649443, -7.712499992580547],
              [-40.307499999925028, -7.712499992580547],
              [-40.307499999925028, -7.713333325072824],
              [-40.309166665808959, -7.713333325972144],
              [-40.309166667607599, -7.714166658464478],
              [-40.31083333259221, -7.714166658464478],
              [-40.310833330793514, -7.714999993654715],
              [-40.311666665983807, -7.714999992755395],
              [-40.311666665983807, -7.715833327046369],
              [-40.313333331867739, -7.715833326147049],
              [-40.313333333666378, -7.71749999203098],
              [-40.315000000449629, -7.7174999929303],
              [-40.31499999775167, -7.716666658639326],
              [-40.315833332941907, -7.716666658639326],
              [-40.315833332042587, -7.7174999929303],
              [-40.31666666723288, -7.7174999929303],
              [-40.31666666633356, -7.718333325422577],
              [-40.318333331318115, -7.718333325422577],
              [-40.318333332217492, -7.719166658814231],
              [-40.319999999000686, -7.719166657914911],
              [-40.319999999000686, -7.720833325597425],
              [-40.32083333239234, -7.720833325597425],
              [-40.32083333239234, -7.721666658989079],
              [-40.321666665783937, -7.721666658989079],
              [-40.321666665783937, -7.72333332667165],
              [-40.32250000097423, -7.72333332577233],
              [-40.322500000074911, -7.724166660063247],
              [-40.323333332567188, -7.724166659163927],
              [-40.323333333466508, -7.724999992555581],
              [-40.326666667932329, -7.724999992555581],
              [-40.32666666703301, -7.725833325947178],
              [-40.328333332916941, -7.725833325947178],
              [-40.328333333816261, -7.724999991656261],
              [-40.329999999700192, -7.724999992555581],
              [-40.329999998800872, -7.724166659163927],
              [-40.33083333129315, -7.724166659163927],
              [-40.330833333091789, -7.72333332577233],
              [-40.331666665584123, -7.72333332577233],
              [-40.331666666483443, -7.722499992380676],
              [-40.333333331468054, -7.722499992380676],
              [-40.333333331468054, -7.721666658989079],
              [-40.33249999987504, -7.721666658989079],
              [-40.33249999987504, -7.720833325597425],
              [-40.331666666483443, -7.720833325597425],
              [-40.331666664684803, -7.719999993105148],
              [-40.330833333991109, -7.719999993105148],
              [-40.330833333091789, -7.719166658814231],
              [-40.329999998800872, -7.719166658814231],
              [-40.329999998800872, -7.716666658639326],
              [-40.327499999525287, -7.716666658639326],
              [-40.327499999525287, -7.715833326147049],
              [-40.328333332916941, -7.715833326147049],
              [-40.328333330218982, -7.714999992755395],
              [-40.327499998625967, -7.714999992755395],
              [-40.327500000424664, -7.714166658464478],
              [-40.32833333471558, -7.714166658464478],
              [-40.328333333816261, -7.712499993479867],
              [-40.327499998625967, -7.712499992580547],
              [-40.327500000424664, -7.710833325797296],
              [-40.32666666703301, -7.710833326696616],
              [-40.326666667932329, -7.707499994029433],
              [-40.325833333641413, -7.707499992230794],
              [-40.325833333641413, -7.705833325447543],
              [-40.324999998451119, -7.705833325447543],
              [-40.325000000249759, -7.704166658664292],
              [-40.325833332742093, -7.704166658664292],
              [-40.325833332742093, -7.702499992780417],
              [-40.324999998451119, -7.702499991881098],
              [-40.324999997551799, -7.699999992605512],
              [-40.324999999350439, -7.698333325822261],
              [-40.324166665958842, -7.698333324922942],
              [-40.324166666858162, -7.697499994229304],
              [-40.321666665783937, -7.697499992430664],
              [-40.321666667582576, -7.69666665903901],
              [-40.32083333149302, -7.69666665903901],
              [-40.32083333239234, -7.695833325647413],
              [-40.320000000799382, -7.695833325647413],
              [-40.320000000799382, -7.694999993155136],
              [-40.319166665609089, -7.694999993155136],
              [-40.319166667407728, -7.691666658689314],
              [-40.318333332217492, -7.691666658689314],
              [-40.318333331318115, -7.690833324398341],
              [-40.317499999725158, -7.69083332619698],
              [-40.317499999725158, -7.688333326022132],
              [-40.315833332042587, -7.688333326022132],
              [-40.315833332941907, -7.687499992630535],
              [-40.314999999550309, -7.687499991731158],
              [-40.31499999865099, -7.685833325847284],
              [-40.315833332941907, -7.685833325847284],
              [-40.315833332042587, -7.683333325672379],
              [-40.31499999865099, -7.683333325672379],
              [-40.314999999550309, -7.677499992830349],
              [-40.314166667957295, -7.677499992830349],
              [-40.314166664360016, -7.676666658539432],
              [-40.313333333666378, -7.676666659438752],
              [-40.313333333666378, -7.675833326047098],
              [-40.312499998476085, -7.675833326047098],
              [-40.312499998476085, -7.674166658364527],
              [-40.311666667782447, -7.674166658364527],
              [-40.311666667782447, -7.67333332587225],
              [-40.310000000999196, -7.67333332587225],
              [-40.310000000099876, -7.671666658189679],
              [-40.309166665808959, -7.671666659088999],
              [-40.30916666490964, -7.669166658914151],
              [-40.308333334215945, -7.66916665981347],
              [-40.308333332417305, -7.664166658564397],
              [-40.307499999925028, -7.664166658564397],
              [-40.307499999025708, -7.66333332607212],
              [-40.306666665634054, -7.66333332607212],
              [-40.306666665634054, -7.661666658389549],
              [-40.305833332242457, -7.661666658389549],
              [-40.305833332242457, -7.660833324997895],
              [-40.304999998850803, -7.660833325897215],
              [-40.304999999750123, -7.658333325722367],
              [-40.304166667257846, -7.658333325722367],
              [-40.304166664559887, -7.657499993230033],
              [-40.303333332966872, -7.657499994129353],
              [-40.303333333866249, -7.655833325547462],
              [-40.302499999575275, -7.655833325547462],
              [-40.302499999575275, -7.654166658764268],
              [-40.301666667082998, -7.654166658764268],
              [-40.301666667082998, -7.652499992880337],
              [-40.302499999575275, -7.652499992880337],
              [-40.302499998675955, -7.651666658589363],
              [-40.303333333866249, -7.651666658589363],
              [-40.303333332966872, -7.649166658414515],
              [-40.304166666358526, -7.649166658414515],
              [-40.304166666358526, -7.648333325922181],
              [-40.305000000649443, -7.648333325022861],
              [-40.305000000649443, -7.647499992530584],
              [-40.306666666533374, -7.647499993429903],
              [-40.306666666533374, -7.646666659138987],
              [-40.307499999925028, -7.646666659138987],
              [-40.307499999025708, -7.643333325572485],
              [-40.310000000099876, -7.643333326471804],
              [-40.310000000999196, -7.639166658614329],
              [-40.310833331692834, -7.639166658614329],
              [-40.31083333259221, -7.636666658439481],
              [-40.309999998301237, -7.636666658439481],
              [-40.310000000999196, -7.634166660063272],
              [-40.31083333259221, -7.634166659163952],
              [-40.31083333259221, -7.632499992380701],
              [-40.310000000999196, -7.632499992380701],
              [-40.310000000999196, -7.631883647412337],
              [-40.310000000099876, -7.63083332649677],
              [-40.309166665808959, -7.63083332649677],
              [-40.309166665808959, -7.625833326147017],
              [-40.309999997401917, -7.625833326147017],
              [-40.309999998301237, -7.624166658464446],
              [-40.309166667607599, -7.624166658464446],
              [-40.309166665808959, -7.623333325072849],
              [-40.307500000824348, -7.623333325972169],
              [-40.307499999925028, -7.621666660088238],
              [-40.305833331343138, -7.621666659188918],
              [-40.305833332242457, -7.620833325797321],
              [-40.304999998850803, -7.620833325797321],
              [-40.304999998850803, -7.619999992405667],
              [-40.304166666358526, -7.619999991506347],
              [-40.304166666358526, -7.617499993130139],
              [-40.304999998850803, -7.617499993130139],
              [-40.304999998850803, -7.615833325447568],
              [-40.304166665459206, -7.615833325447568],
              [-40.304166665459206, -7.614999992955291],
              [-40.304999998850803, -7.61499999385461],
              [-40.304999998850803, -7.614166658664317],
              [-40.305833334041097, -7.614166658664317],
              [-40.305833332242457, -7.609999992605537],
              [-40.304999998850803, -7.609999992605537],
              [-40.304999997951484, -7.608333325822286],
              [-40.304166669056485, -7.608333325822286],
              [-40.304166667257846, -7.606666659938355],
              [-40.303333332067552, -7.606666659039035],
              [-40.303333333866249, -7.604999993155104],
              [-40.302499999575275, -7.604999993155104],
              [-40.302499999575275, -7.603333324573214],
              [-40.299166666008773, -7.603333326371853],
              [-40.299166666908093, -7.604999993155104],
              [-40.294999999949994, -7.604999993155104],
              [-40.294999999949994, -7.604166659763507],
              [-40.294166667457716, -7.604166658864187],
              [-40.294166665659077, -7.604999993155104],
              [-40.292499999775146, -7.604999993155104],
              [-40.292499999775146, -7.607499991531313],
              [-40.291666665484172, -7.607499992430633],
              [-40.291666665484172, -7.608333325822286],
              [-40.290833332092575, -7.608333325822286],
              [-40.290833332991895, -7.609166659213884],
              [-40.289999999600241, -7.609166659213884],
              [-40.29000000049956, -7.609999992605537],
              [-40.289166665309324, -7.609999992605537],
              [-40.289166665309324, -7.609166659213884],
              [-40.282499999974959, -7.609166659213884],
              [-40.282499999974959, -7.609999992605537],
              [-40.280833332292389, -7.609999992605537],
              [-40.280833332292389, -7.610833325997135],
              [-40.27833333301686, -7.610833325997135],
              [-40.278333331218221, -7.609999992605537],
              [-40.276666666233609, -7.609999993504857],
              [-40.27666666443497, -7.609166659213884],
              [-40.273333332667107, -7.609166659213884],
              [-40.273333332667107, -7.609999992605537],
              [-40.27250000107415, -7.609999992605537],
              [-40.27250000107415, -7.609166659213884],
              [-40.270000001798621, -7.609166657415244],
              [-40.270000000899302, -7.608333325822286],
              [-40.269166666608328, -7.608333324922967],
              [-40.269166666608328, -7.607499992430633],
              [-40.267499999825077, -7.607499992430633],
              [-40.267500000724397, -7.606666659039035],
              [-40.264999999650229, -7.606666659039035],
              [-40.265000000549549, -7.605833325647438],
              [-40.263333331068338, -7.605833325647438],
              [-40.263333332866978, -7.606666659039035],
              [-40.260833333591449, -7.606666659039035],
              [-40.26083333269213, -7.605833324748119],
              [-40.259166666808198, -7.605833325647438],
              [-40.259166667707518, -7.604999991356465],
              [-40.257500000924267, -7.604999993155104],
              [-40.257499997326988, -7.605833325647438],
              [-40.255833333241696, -7.605833326546758],
              [-40.255833331443057, -7.604999993155104],
              [-40.254166666458445, -7.604999991356465],
              [-40.254166667357765, -7.603333325472533],
              [-40.254163506240786, -7.603333325472533],
              [-40.251666665384278, -7.603333325472533],
              [-40.251666666283597, -7.602499992980256],
              [-40.250833331992624, -7.602499992980256],
              [-40.250833332891943, -7.601666658689282],
              [-40.250000000399666, -7.601666658689282],
              [-40.250000000399666, -7.600833326197005],
              [-40.23999999880084, -7.600833327096325],
              [-40.23999999880084, -7.599999992805351],
              [-40.238333332017589, -7.599999993704671],
              [-40.238333332017589, -7.599166658514434],
              [-40.232500000974198, -7.599166658514434],
              [-40.232500000074879, -7.599999992805351],
              [-40.229999999900031, -7.599999992805351],
              [-40.229999999000711, -7.599166658514434],
              [-40.229166665609114, -7.599166658514434],
              [-40.229166666508434, -7.597499992630503],
              [-40.22833333131814, -7.597499992630503],
              [-40.22833333131814, -7.596666659238906],
              [-40.225833333841251, -7.596666659238906],
              [-40.225833333841251, -7.595833324947932],
              [-40.224999998650958, -7.595833325847252],
              [-40.225000000449597, -7.594999991556335],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '234',
        OBJECTID: 145.0,
        Nome_area: 'Araripina',
        COD_area: 'CA151',
        Import_bio: 'Muito Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Recuperação de Áreas Degradadas',
        Area_ha: 19557.1897,
        Shape_Leng: 0.91833,
        Shape_Area: 0.01602,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.384999999050649, -7.454166659163945],
              [-40.384999999949969, -7.454999992555543],
              [-40.384166665659052, -7.454999991656223],
              [-40.384166665659052, -7.455833325947196],
              [-40.379166665309299, -7.455833325947196],
              [-40.379166666208619, -7.454999991656223],
              [-40.378333331917702, -7.454999992555543],
              [-40.378333333716341, -7.455833325947196],
              [-40.377499998526048, -7.455833325947196],
              [-40.377500000324687, -7.457499992730448],
              [-40.37666666693309, -7.457499992730448],
              [-40.376666666033771, -7.458333326122045],
              [-40.373333333366588, -7.458333326122045],
              [-40.373333333366588, -7.46083332539763],
              [-40.37083333229242, -7.460833332592188],
              [-40.368333333916212, -7.46083332539763],
              [-40.368333333016835, -7.462499993080144],
              [-40.367499998725918, -7.462499993080144],
              [-40.367500001423878, -7.464166658964075],
              [-40.365833331942667, -7.464166658964075],
              [-40.365833331942667, -7.465833325747326],
              [-40.364166666958056, -7.465833326646646],
              [-40.364166666058736, -7.46666665913898],
              [-40.363333332667139, -7.46666665913898],
              [-40.363333332667139, -7.467499992530577],
              [-40.362500001074125, -7.467499992530577],
              [-40.362499998376165, -7.470833326097079],
              [-40.361666665883888, -7.470833326097079],
              [-40.361666667682528, -7.47249999377965],
              [-40.360833332492234, -7.47249999288033],
              [-40.360833333391554, -7.473333325372607],
              [-40.36, -7.473333325372607],
              [-40.36, -7.475833325547512],
              [-40.35916666570904, -7.475833325547512],
              [-40.35916666660836, -7.47833332572236],
              [-40.36, -7.47833332572236],
              [-40.359999997301998, -7.479166659113957],
              [-40.360833331592914, -7.479166659113957],
              [-40.360833333391554, -7.479999992505611],
              [-40.361666667682528, -7.479999992505611],
              [-40.361666666783208, -7.480833325897208],
              [-40.360833331592914, -7.480833325897208],
              [-40.360833331592914, -7.481666657490223],
              [-40.357499999825109, -7.481666658389543],
              [-40.357500000724428, -7.480833326796528],
              [-40.353333331967633, -7.480833325897208],
              [-40.353333333766273, -7.481666660188182],
              [-40.351666666983078, -7.481666658389543],
              [-40.351666667882398, -7.483333326072113],
              [-40.349999998401188, -7.483333326072113],
              [-40.349999999300508, -7.484999991956045],
              [-40.349166665908854, -7.484999992855364],
              [-40.349166667707493, -7.486666659638558],
              [-40.348333331617937, -7.486666658739239],
              [-40.348333331617937, -7.48833332552249],
              [-40.347499998226283, -7.48833332552249],
              [-40.347500000024922, -7.489166658914144],
              [-40.346666666633325, -7.489166658014824],
              [-40.346666665734006, -7.492499992480646],
              [-40.347500000024922, -7.492499992480646],
              [-40.347500000024922, -7.49416665836452],
              [-40.347499998226283, -7.495833326047091],
              [-40.344999998950755, -7.495833325147771],
              [-40.344999998950755, -7.496666657640105],
              [-40.34416666645842, -7.496666658539425],
              [-40.344166665559101, -7.499999993005247],
              [-40.343333333066823, -7.499999993005247],
              [-40.343333333966143, -7.500833325497524],
              [-40.342499998775907, -7.500833325497524],
              [-40.342499998775907, -7.501666657989801],
              [-40.343333333066823, -7.501666659788498],
              [-40.343333331268184, -7.503333324773052],
              [-40.344999999850074, -7.503333325672372],
              [-40.344999998950755, -7.505833325847277],
              [-40.345833333241671, -7.505833325847277],
              [-40.345833333241671, -7.506666659238874],
              [-40.346666666633325, -7.506666659238874],
              [-40.346666667532645, -7.508333326022125],
              [-40.345833333241671, -7.508333325122805],
              [-40.345833333241671, -7.510833326196973],
              [-40.344166665559101, -7.510833326196973],
              [-40.344166664659781, -7.512499992980224],
              [-40.342499999675226, -7.512499993879544],
              [-40.342500000574546, -7.513333326371878],
              [-40.341666666283572, -7.513333324573239],
              [-40.341666665384253, -7.521666657590117],
              [-40.340833331992656, -7.521666657590117],
              [-40.340833333791295, -7.52249999367973],
              [-40.341666668082212, -7.522499992780411],
              [-40.341666668082212, -7.523333326172008],
              [-40.342499998775907, -7.523333326172008],
              [-40.342499999675226, -7.524999992955259],
              [-40.341666666283572, -7.524999992955259],
              [-40.341666666283572, -7.525833324548216],
              [-40.339166667008044, -7.525833326346913],
              [-40.339166665209405, -7.52666665973851],
              [-40.339999998601002, -7.52666665883919],
              [-40.339999998601002, -7.528333324723121],
              [-40.339166665209405, -7.528333325622441],
              [-40.339166667907364, -7.530833325797289],
              [-40.340000000399641, -7.530833325797289],
              [-40.339999998601002, -7.531666657390303],
              [-40.340833332891975, -7.531666659188943],
              [-40.340833331992656, -7.534999992755388],
              [-40.339999998601002, -7.534999993654765],
              [-40.339999998601002, -7.535833326147042],
              [-40.340833333791295, -7.535833327046362],
              [-40.340833333791295, -7.537499992030973],
              [-40.340000000399641, -7.537499992930293],
              [-40.340000000399641, -7.53833332542257],
              [-40.339166667008044, -7.53833332542257],
              [-40.339166665209405, -7.539166658814224],
              [-40.339999999500321, -7.539166658814224],
              [-40.339999999500321, -7.539999993105141],
              [-40.340833332891975, -7.539999993105141],
              [-40.340833333791295, -7.540833325597475],
              [-40.34416666645842, -7.540833325597475],
              [-40.34416666645842, -7.543333325772323],
              [-40.343333333966143, -7.543333324873004],
              [-40.343333333966143, -7.54416665916392],
              [-40.34416666645842, -7.54416665916392],
              [-40.34416666645842, -7.546666658439506],
              [-40.344999999850074, -7.546666660238145],
              [-40.344999998950755, -7.547499992730422],
              [-40.345833332342352, -7.547499992730422],
              [-40.345833333241671, -7.548333325222757],
              [-40.344999998950755, -7.548333326122076],
              [-40.345000000749394, -7.549166658614354],
              [-40.34416666645842, -7.549166658614354],
              [-40.34416666645842, -7.549999992905327],
              [-40.340833331992656, -7.549999992905327],
              [-40.340833332891975, -7.550833325397605],
              [-40.339166665209405, -7.550833325397605],
              [-40.339166667008044, -7.551666658789202],
              [-40.33833333271707, -7.551666658789202],
              [-40.33833333271707, -7.554166658964107],
              [-40.339999998601002, -7.554166658964107],
              [-40.339999998601002, -7.554999992355704],
              [-40.339166667008044, -7.554999992355704],
              [-40.339166665209405, -7.557499992530609],
              [-40.338333331817751, -7.557499992530609],
              [-40.33833333271707, -7.558333325922206],
              [-40.337499999325473, -7.558333325922206],
              [-40.337499999325473, -7.559166658414483],
              [-40.336666666833139, -7.559166658414483],
              [-40.3366666650345, -7.558333325922206],
              [-40.335000000049945, -7.558333325922206],
              [-40.335000000949265, -7.559166658414483],
              [-40.334166665758971, -7.559166658414483],
              [-40.334166666658291, -7.557499992530609],
              [-40.331666666483443, -7.557499992530609],
              [-40.331666665584123, -7.560833326097054],
              [-40.333333332367374, -7.560833326097054],
              [-40.333333331468054, -7.563333325372639],
              [-40.33249999987504, -7.563333325372639],
              [-40.33249999987504, -7.564166658764236],
              [-40.331666666483443, -7.564166658764236],
              [-40.331666666483443, -7.56499999305521],
              [-40.330833333091789, -7.56499999215589],
              [-40.33083333129315, -7.565833325547487],
              [-40.329166667207858, -7.565833326446807],
              [-40.329166665409218, -7.566666658939084],
              [-40.328333332916941, -7.566666658039765],
              [-40.328333332017621, -7.568333325722335],
              [-40.325833332742093, -7.568333325722335],
              [-40.325833331842773, -7.569999992505586],
              [-40.32083333239234, -7.569999992505586],
              [-40.32083333239234, -7.571666658389518],
              [-40.32083333149302, -7.574999992855339],
              [-40.319999999900006, -7.574999992855339],
              [-40.319999998101366, -7.575833324448297],
              [-40.317499999725158, -7.575833325347617],
              [-40.317499999725158, -7.574999992855339],
              [-40.314999999550309, -7.574999993754659],
              [-40.314999999550309, -7.575833327146256],
              [-40.314999999550309, -7.576666658739271],
              [-40.31666666633356, -7.576666658739271],
              [-40.31666666723288, -7.579166658914119],
              [-40.317500000624477, -7.579166658914119],
              [-40.317500000624477, -7.578333324623202],
              [-40.319166665609089, -7.578333325522522],
              [-40.319166666508409, -7.579166658014799],
              [-40.32083333149302, -7.579166658914119],
              [-40.32083333149302, -7.582499992480621],
              [-40.321666666683257, -7.582499992480621],
              [-40.321666665783937, -7.583333325872218],
              [-40.324166666858162, -7.583333324972898],
              [-40.324166666858162, -7.584166658364552],
              [-40.324999998451119, -7.584166658364552],
              [-40.325000000249759, -7.5866666585394],
              [-40.324166665958842, -7.58666665943872],
              [-40.324166666858162, -7.589166658714305],
              [-40.322500000074911, -7.589166658714305],
              [-40.322499998276271, -7.593333326571724],
              [-40.321666667582576, -7.593333325672404],
              [-40.321666665783937, -7.594166658164681],
              [-40.319999999000686, -7.594166659064001],
              [-40.319999999900006, -7.596666659238906],
              [-40.318333334016131, -7.596666659238906],
              [-40.318333331318115, -7.597499992630503],
              [-40.317499998825838, -7.597499993529823],
              [-40.317499999725158, -7.598333326022157],
              [-40.318333332217492, -7.598333326022157],
              [-40.318333334016131, -7.599166658514434],
              [-40.319999999900006, -7.599166658514434],
              [-40.319999999900006, -7.600833326197005],
              [-40.32083333149302, -7.600833326197005],
              [-40.32083333329166, -7.602499992980256],
              [-40.321666667582576, -7.602499992980256],
              [-40.321666665783937, -7.605833325647438],
              [-40.322499998276271, -7.605833325647438],
              [-40.322500000074911, -7.609166660113203],
              [-40.327499998625967, -7.609166660113203],
              [-40.327500001323983, -7.609999992605537],
              [-40.328333330218982, -7.609999992605537],
              [-40.328333330218982, -7.609166659213884],
              [-40.329166665409218, -7.609166659213884],
              [-40.329166666308538, -7.609999993504857],
              [-40.330833333991109, -7.609999992605537],
              [-40.330833333091789, -7.609166659213884],
              [-40.33249999897572, -7.609166657415244],
              [-40.33249999987504, -7.607499992430633],
              [-40.336666666833139, -7.607499992430633],
              [-40.336666665933819, -7.606666659039035],
              [-40.337499998426154, -7.606666659039035],
              [-40.337499998426154, -7.605833325647438],
              [-40.338333331817751, -7.605833325647438],
              [-40.33833333271707, -7.604999994054424],
              [-40.339999999500321, -7.604999993155104],
              [-40.339999998601002, -7.605833325647438],
              [-40.340833331992656, -7.605833325647438],
              [-40.340833332891975, -7.606666659938355],
              [-40.341666666283572, -7.606666659039035],
              [-40.341666664484933, -7.607499994229329],
              [-40.343333333066823, -7.607499992430633],
              [-40.343333333066823, -7.608333325822286],
              [-40.345833331443032, -7.608333325822286],
              [-40.345833333241671, -7.609166659213884],
              [-40.348333332517257, -7.609166659213884],
              [-40.348333332517257, -7.609999991706218],
              [-40.349999998401188, -7.609999992605537],
              [-40.349999998401188, -7.610525591482826],
              [-40.349999998401188, -7.610833325097815],
              [-40.350833332692105, -7.610833325997135],
              [-40.350833333591424, -7.612499992780386],
              [-40.351666666083702, -7.612499992780386],
              [-40.351666667882398, -7.61333332617204],
              [-40.352499999475356, -7.61333332617204],
              [-40.352499999475356, -7.614166658664317],
              [-40.353333333766273, -7.614166658664317],
              [-40.353333333766273, -7.614999992955291],
              [-40.354166668057246, -7.614999992955291],
              [-40.354166666258607, -7.615833325447568],
              [-40.354999998750884, -7.615833325447568],
              [-40.354999999650204, -7.617499993130139],
              [-40.355833333041858, -7.617499993130139],
              [-40.355833331243218, -7.620833325797321],
              [-40.357499998925789, -7.620833325797321],
              [-40.357499998925789, -7.623333326871489],
              [-40.358333333216706, -7.623333325972169],
              [-40.358333331418066, -7.6249999918561],
              [-40.360833332492234, -7.62499999275542],
              [-40.360833333391554, -7.625833326147017],
              [-40.361666665883888, -7.625833326147017],
              [-40.361666665883888, -7.626666658639351],
              [-40.363333333566459, -7.626666658639351],
              [-40.3633333308685, -7.627499992930268],
              [-40.365833331942667, -7.627499992030948],
              [-40.365833333741307, -7.626666657740031],
              [-40.366666665334321, -7.626666658639351],
              [-40.366666666233641, -7.627499992930268],
              [-40.367500000524558, -7.627499993829588],
              [-40.367500000524558, -7.628333325422602],
              [-40.368333333016835, -7.628333327221242],
              [-40.368333331218196, -7.62916665791488],
              [-40.369166666408489, -7.629166658814199],
              [-40.369166666408489, -7.631666658989047],
              [-40.369999998900767, -7.631666658989047],
              [-40.369999998900767, -7.632499992380701],
              [-40.37083333229242, -7.632499992380701],
              [-40.37083333409106, -7.634166659163952],
              [-40.372499999075671, -7.634166659163952],
              [-40.372500000874311, -7.634999992555549],
              [-40.374166665858922, -7.634999992555549],
              [-40.374166666758242, -7.635833325947203],
              [-40.373333331567949, -7.635833325947203],
              [-40.373333333366588, -7.637499992730454],
              [-40.374166665858922, -7.637499992730454],
              [-40.374166666758242, -7.639999993804622],
              [-40.37499999925052, -7.639999992905302],
              [-40.37499999925052, -7.640833327196219],
              [-40.374166666758242, -7.64083332449826],
              [-40.374166666758242, -7.64249999308015],
              [-40.375000001049159, -7.64249999308015],
              [-40.37499999925052, -7.647499992530584],
              [-40.374166666758242, -7.647499993429903],
              [-40.374166667657562, -7.650833326097086],
              [-40.375000001049159, -7.650833326097086],
              [-40.3749999983512, -7.659166659113964],
              [-40.375833333541493, -7.659166659113964],
              [-40.375833333541493, -7.659999992505618],
              [-40.376666666033771, -7.659999992505618],
              [-40.37666666693309, -7.661666658389549],
              [-40.380000000499592, -7.661666658389549],
              [-40.379999998700953, -7.662499992680466],
              [-40.385000001748665, -7.662499992680466],
              [-40.385000000849345, -7.659999992505618],
              [-40.385833331542983, -7.659999992505618],
              [-40.385833333341623, -7.658333324823047],
              [-40.386666667632539, -7.658333325722367],
              [-40.3866666658339, -7.657499993230033],
              [-40.387500001024193, -7.657499993230033],
              [-40.387499999225554, -7.656666658939116],
              [-40.389166666908125, -7.656666658939116],
              [-40.389999998501082, -7.656666658939116],
              [-40.389999999400402, -7.655833325547462],
              [-40.391666668881612, -7.655833325547462],
              [-40.391666667982292, -7.654999993055185],
              [-40.391666667982292, -7.652499992880337],
              [-40.389999998501082, -7.652499993779657],
              [-40.390000000299722, -7.651666658589363],
              [-40.389166666908125, -7.651666658589363],
              [-40.389166666008805, -7.650833326097086],
              [-40.388333332617151, -7.650833326097086],
              [-40.388333332617151, -7.649999992705432],
              [-40.387499998326234, -7.649999992705432],
              [-40.387500001024193, -7.646666659138987],
              [-40.388333332617151, -7.646666659138987],
              [-40.388333331717831, -7.64083332539758],
              [-40.387499998326234, -7.64083332539758],
              [-40.387500001024193, -7.639999992005983],
              [-40.388333333516471, -7.639999992905302],
              [-40.388333332617151, -7.639166658614329],
              [-40.387500001024193, -7.639166658614329],
              [-40.387499999225554, -7.638333326122051],
              [-40.388333333516471, -7.638333326122051],
              [-40.388333331717831, -7.635833325047884],
              [-40.387499999225554, -7.635833325047884],
              [-40.387499997426914, -7.632499992380701],
              [-40.389166666008805, -7.632499992380701],
              [-40.389166667807444, -7.631666658989047],
              [-40.389999999400402, -7.631666658989047],
              [-40.389999999400402, -7.626666658639351],
              [-40.389166666008805, -7.626666658639351],
              [-40.389166667807444, -7.623333325972169],
              [-40.388333333516471, -7.623333325972169],
              [-40.388333331717831, -7.622499992580572],
              [-40.389166666008805, -7.622499992580572],
              [-40.389166666908125, -7.621666659188918],
              [-40.389999999400402, -7.621666659188918],
              [-40.389999998501082, -7.619999992405667],
              [-40.389166667807444, -7.619999994204306],
              [-40.389166667807444, -7.617499993130139],
              [-40.389999999400402, -7.617499993130139],
              [-40.390000000299722, -7.616666658839165],
              [-40.391666665284333, -7.616666658839165],
              [-40.391666667082973, -7.615833324548248],
              [-40.392500000474627, -7.615833325447568],
              [-40.392500000474627, -7.614166658664317],
              [-40.393333332067584, -7.614166658664317],
              [-40.393333332966904, -7.612499992780386],
              [-40.394166666358501, -7.612499992780386],
              [-40.394166669056517, -7.609166659213884],
              [-40.393333333866224, -7.609166659213884],
              [-40.393333332067584, -7.608333325822286],
              [-40.39249999957525, -7.608333325822286],
              [-40.39249999867593, -7.606666659039035],
              [-40.395475887701991, -7.606666660837675],
              [-40.395833334041072, -7.606666659039035],
              [-40.395833332242432, -7.606742801039388],
              [-40.395833334041072, -7.607499992430633],
              [-40.397499999025683, -7.607499992430633],
              [-40.397499999925003, -7.608333325822286],
              [-40.401666665983782, -7.608333325822286],
              [-40.401666667782422, -7.609166659213884],
              [-40.402500000274756, -7.609166659213884],
              [-40.402499998476117, -7.609999992605537],
              [-40.404166665259368, -7.609999992605537],
              [-40.404166667957327, -7.611666658489469],
              [-40.404999999550284, -7.611666659388789],
              [-40.404999998650965, -7.614166657764997],
              [-40.405833332941938, -7.614166658664317],
              [-40.405833332042619, -7.614999992955291],
              [-40.408333331318147, -7.614999998351209],
              [-40.409999999900037, -7.614999992955291],
              [-40.409999999900037, -7.614166658664317],
              [-40.411069957512495, -7.614166663160916],
              [-40.413333333466539, -7.614166659563637],
              [-40.41333333256722, -7.615370326579239],
              [-40.4133333316679, -7.615833325447568],
              [-40.414166666858137, -7.615833324548248],
              [-40.414166665958817, -7.617499993130139],
              [-40.414999998451151, -7.617499992230819],
              [-40.414999999350471, -7.618333325622416],
              [-40.415833331842748, -7.618333325622416],
              [-40.415833333641388, -7.61916665901407],
              [-40.416666667033041, -7.61916665901407],
              [-40.416666665234345, -7.619999992405667],
              [-40.417499999525319, -7.619999992405667],
              [-40.417500000424639, -7.621666659188918],
              [-40.41916666540925, -7.621666659188918],
              [-40.41916666630857, -7.623333325972169],
              [-40.419999998800847, -7.623333326871489],
              [-40.420000000599487, -7.622499992580572],
              [-40.420833330393862, -7.622499991681252],
              [-40.420833333091821, -7.620833325797321],
              [-40.421666666483418, -7.620833325797321],
              [-40.421666666483418, -7.619999992405667],
              [-40.422499998975752, -7.619999992405667],
              [-40.422499999875072, -7.61916665811475],
              [-40.426666665933851, -7.61916665901407],
              [-40.426666665933851, -7.618333325622416],
              [-40.428333333616422, -7.618333325622416],
              [-40.428333331817782, -7.617499993130139],
              [-40.430833331992631, -7.617499993130139],
              [-40.43083333379127, -7.616666657939845],
              [-40.431666666283604, -7.616666659738485],
              [-40.431666666283604, -7.615833325447568],
              [-40.433333333966175, -7.615833325447568],
              [-40.433333331268159, -7.616666658839165],
              [-40.435833333241703, -7.616666658839165],
              [-40.435833333241703, -7.615833325447568],
              [-40.438333331617912, -7.615833325447568],
              [-40.438333332517232, -7.614999992955291],
              [-40.439999999300483, -7.614999992955291],
              [-40.439999999300483, -7.61333332617204],
              [-40.441666666083734, -7.61333332617204],
              [-40.441666666983053, -7.611666658489469],
              [-40.442499999475331, -7.611666658489469],
              [-40.442499998576011, -7.610833325997135],
              [-40.443333331967665, -7.610833325997135],
              [-40.443333331967665, -7.609999992605537],
              [-40.444166668057221, -7.609999992605537],
              [-40.444166666258582, -7.606666659938355],
              [-40.445000000549555, -7.606666659938355],
              [-40.444999997851596, -7.605833325647438],
              [-40.445833333041833, -7.605833325647438],
              [-40.445833331243193, -7.602499992980256],
              [-40.445000000549555, -7.602499992980256],
              [-40.444999999650236, -7.601666658689282],
              [-40.445833331243193, -7.601666658689282],
              [-40.445833333041833, -7.600833324398366],
              [-40.446666665534167, -7.600833326197005],
              [-40.446666666433487, -7.597499992630503],
              [-40.445833333041833, -7.597499992630503],
              [-40.445833333041833, -7.595756123546209],
              [-40.445833333941152, -7.59249999318007],
              [-40.444999999650236, -7.59249999318007],
              [-40.444999997851596, -7.590833325497499],
              [-40.444166665359262, -7.590833326396876],
              [-40.444166666258582, -7.588333326221971],
              [-40.443333331967665, -7.588333326221971],
              [-40.443333331967665, -7.5866666585394],
              [-40.444166665359262, -7.5866666585394],
              [-40.444166665359262, -7.582499992480621],
              [-40.444999999650236, -7.582499992480621],
              [-40.444999998750916, -7.581666659089024],
              [-40.445833333041833, -7.581666659089024],
              [-40.445833331243193, -7.58083332479805],
              [-40.448333333216738, -7.58083332569737],
              [-40.448333331418041, -7.579999993205092],
              [-40.446666668232126, -7.579999993205092],
              [-40.446666666433487, -7.579166658914119],
              [-40.445833331243193, -7.579166658914119],
              [-40.445833331243193, -7.576666658739271],
              [-40.444999998750916, -7.576666658739271],
              [-40.444999998750916, -7.575833325347617],
              [-40.444166665359262, -7.575833325347617],
              [-40.444166665359262, -7.572499991781172],
              [-40.443333331967665, -7.572499992680491],
              [-40.443333332866985, -7.571666658389518],
              [-40.44083333179276, -7.571666658389518],
              [-40.44083333179276, -7.57083332589724],
              [-40.439166667707525, -7.57083332589724],
              [-40.439166667707525, -7.569999993404906],
              [-40.438333331617912, -7.569999992505586],
              [-40.438333333416551, -7.568333325722335],
              [-40.437499998226315, -7.568333325722335],
              [-40.437499998226315, -7.567499993230058],
              [-40.4366666666333, -7.567499993230058],
              [-40.4366666666333, -7.566666658039765],
              [-40.435833331443064, -7.566666658939084],
              [-40.435833332342384, -7.56499999215589],
              [-40.43499999895073, -7.56499999305521],
              [-40.43499999895073, -7.564166657864916],
              [-40.432499998775882, -7.564166658764236],
              [-40.432500000574521, -7.563333324473319],
              [-40.431666666283604, -7.563333325372639],
              [-40.431666666283604, -7.559999993604777],
              [-40.430833331992631, -7.559999992705457],
              [-40.430833331992631, -7.557499992530609],
              [-40.430000000399673, -7.557499992530609],
              [-40.429999998601033, -7.555833325747358],
              [-40.429999999500353, -7.554999992355704],
              [-40.43083333289195, -7.554999992355704],
              [-40.430833331992631, -7.554166658964107],
              [-40.431666666283604, -7.554166658964107],
              [-40.431666668082244, -7.553333325572453],
              [-40.432499999675201, -7.553333325572453],
              [-40.432499998775882, -7.551666658789202],
              [-40.433333331268159, -7.551666658789202],
              [-40.433333330368839, -7.547499992730422],
              [-40.434166667357772, -7.547499992730422],
              [-40.434166665559133, -7.546666660238145],
              [-40.434999999850049, -7.546666660238145],
              [-40.434999999850049, -7.545833325947171],
              [-40.435833331443064, -7.545833325947171],
              [-40.435833332342384, -7.544999991656255],
              [-40.435000000749369, -7.544999992555574],
              [-40.435000001648689, -7.543333325772323],
              [-40.435833331443064, -7.543333325772323],
              [-40.435833333241703, -7.541666658989072],
              [-40.43666666753262, -7.541666658089753],
              [-40.4366666666333, -7.540833325597475],
              [-40.43666666753262, -7.539999993105141],
              [-40.438333332517232, -7.539999993105141],
              [-40.438333332517232, -7.53833332542257],
              [-40.439166665908886, -7.53833332542257],
              [-40.439166666808205, -7.536666658639319],
              [-40.439999998401163, -7.536666658639319],
              [-40.439999999300483, -7.535833327046362],
              [-40.440833333591456, -7.535833327046362],
              [-40.44083333179276, -7.534999992755388],
              [-40.443333331967665, -7.534999992755388],
              [-40.443333331967665, -7.535833326147042],
              [-40.444166666258582, -7.535833326147042],
              [-40.444166668057221, -7.534999992755388],
              [-40.447499998925764, -7.534999991856068],
              [-40.447500000724403, -7.534166657565152],
              [-40.448333331418041, -7.534166658464471],
              [-40.448333332317418, -7.533333326871514],
              [-40.449166665709015, -7.533333325972194],
              [-40.449166667507654, -7.531666659188943],
              [-40.45, -7.531666659188943],
              [-40.45, -7.530833325797289],
              [-40.450833332492266, -7.530833325797289],
              [-40.450833331592946, -7.529166658114718],
              [-40.451666665883863, -7.529166658114718],
              [-40.451666665883863, -7.527499993130107],
              [-40.450833333391586, -7.527499993130107],
              [-40.450833331592946, -7.52666665883919],
              [-40.451666667682503, -7.52666665883919],
              [-40.451666667682503, -7.524166658664342],
              [-40.454166666958088, -7.524166658664342],
              [-40.454166666958088, -7.521666658489437],
              [-40.454999998551045, -7.521666658489437],
              [-40.454999999450365, -7.52083332599716],
              [-40.454166666058768, -7.52083332599716],
              [-40.454166667857407, -7.518333326721631],
              [-40.453333331767794, -7.518333325822255],
              [-40.453333333566434, -7.514999993155129],
              [-40.454166666058768, -7.514999993155129],
              [-40.454166666958088, -7.514166658864156],
              [-40.453333333566434, -7.514166659763475],
              [-40.453333332667114, -7.512499992980224],
              [-40.453333331767794, -7.511666658689307],
              [-40.454999998551045, -7.511666659588627],
              [-40.455000000349685, -7.51083332709635],
              [-40.455833333741339, -7.510833326196973],
              [-40.455833332842019, -7.509166658514459],
              [-40.454166667857407, -7.509166658514459],
              [-40.454166667857407, -7.508333326022125],
              [-40.451666665883863, -7.508333326022125],
              [-40.451666667682503, -7.505833325847277],
              [-40.448333333216738, -7.505833325847277],
              [-40.448333332317418, -7.504166659064026],
              [-40.447499999825084, -7.504166659064026],
              [-40.447499999825084, -7.500833325497524],
              [-40.447499999825084, -7.499166657814953],
              [-40.444999998750916, -7.499166658714273],
              [-40.445000000549555, -7.499999993005247],
              [-40.444166665359262, -7.499999993005247],
              [-40.444166668057221, -7.499166659613593],
              [-40.442499999475331, -7.499166658714273],
              [-40.44250000127397, -7.497499993729662],
              [-40.441666667882373, -7.497499992830342],
              [-40.441666666983053, -7.496666658539425],
              [-40.44083333179276, -7.496666658539425],
              [-40.44083333179276, -7.495833326047091],
              [-40.438333331617912, -7.495833325147771],
              [-40.438333333416551, -7.494999992655494],
              [-40.437500000024954, -7.494999992655494],
              [-40.437499998226315, -7.494166660163216],
              [-40.438333334315871, -7.494166660163216],
              [-40.438333331617912, -7.493333325872243],
              [-40.439166666808205, -7.493333325872243],
              [-40.439166667707525, -7.492499992480646],
              [-40.437499998226315, -7.492499992480646],
              [-40.437499998226315, -7.491666659088992],
              [-40.43666666753262, -7.491666659088992],
              [-40.43666666753262, -7.490833325697395],
              [-40.432499999675201, -7.490833325697395],
              [-40.432499999675201, -7.491666659088992],
              [-40.430833331992631, -7.491666659088992],
              [-40.430833331992631, -7.48999999320506],
              [-40.429999998601033, -7.48999999320506],
              [-40.429999998601033, -7.489166658014824],
              [-40.428333331817782, -7.489166658914144],
              [-40.428333331817782, -7.487499993929532],
              [-40.427499998426129, -7.487499993030212],
              [-40.427500000224768, -7.486666659638558],
              [-40.425833332542197, -7.486666658739239],
              [-40.425833333441517, -7.485833325347642],
              [-40.42500000094924, -7.485833325347642],
              [-40.42499999825128, -7.484999992855364],
              [-40.424166667557643, -7.484999992855364],
              [-40.424166667557643, -7.484166658564391],
              [-40.423333331468029, -7.484166658564391],
              [-40.423333331468029, -7.483333326072113],
              [-40.421666667382738, -7.483333326072113],
              [-40.421666666483418, -7.482499992680459],
              [-40.420833333991141, -7.48249999178114],
              [-40.420833331293181, -7.483333326072113],
              [-40.419999998800847, -7.483333326072113],
              [-40.419999999700167, -7.482499992680459],
              [-40.41916666540925, -7.482499992680459],
              [-40.41916666540925, -7.481666658389543],
              [-40.418333332916916, -7.481666658389543],
              [-40.418333333816236, -7.480833324997889],
              [-40.415833331842748, -7.480833325897208],
              [-40.415833333641388, -7.481666658389543],
              [-40.414999999350471, -7.481666658389543],
              [-40.414999999350471, -7.480833325897208],
              [-40.414166666858137, -7.480833325897208],
              [-40.414166665958817, -7.476666659838429],
              [-40.414999998451151, -7.476666658939109],
              [-40.414999998451151, -7.475833325547512],
              [-40.413333333466539, -7.475833325547512],
              [-40.41333333076858, -7.474999993055178],
              [-40.408333332217467, -7.474999993055178],
              [-40.408333331318147, -7.474166658764261],
              [-40.405833332042619, -7.474166658764261],
              [-40.405833332941938, -7.473333325372607],
              [-40.406666665434216, -7.473333325372607],
              [-40.406666665434216, -7.47249999288033],
              [-40.405833332042619, -7.47249999288033],
              [-40.405833333841258, -7.469999992705425],
              [-40.404999998650965, -7.469999992705425],
              [-40.404999998650965, -7.469166658414508],
              [-40.403333332767033, -7.469166658414508],
              [-40.403333331867714, -7.468333325922231],
              [-40.402499999375436, -7.468333325022911],
              [-40.402499998476117, -7.467499993429897],
              [-40.400833332592185, -7.467499992530577],
              [-40.400833332592185, -7.46666665913898],
              [-40.399166667607574, -7.46666665913898],
              [-40.399166665808934, -7.465833324848006],
              [-40.398333331518018, -7.465833325747326],
              [-40.398333331518018, -7.463333325572478],
              [-40.397499999925003, -7.463333325572478],
              [-40.397500000824323, -7.462499993080144],
              [-40.396666665634086, -7.462499993080144],
              [-40.396666667432726, -7.461666658789227],
              [-40.394999998850835, -7.461666658789227],
              [-40.395000000649475, -7.462499993080144],
              [-40.393333332067584, -7.462499993080144],
              [-40.393333332067584, -7.461666658789227],
              [-40.39249999867593, -7.461666658789227],
              [-40.39249999957525, -7.46083332539763],
              [-40.391666667982292, -7.46083332539763],
              [-40.391666665284333, -7.459166658614379],
              [-40.389999998501082, -7.459166658614379],
              [-40.390000000299722, -7.458333326122045],
              [-40.388333332617151, -7.458333326122045],
              [-40.388333331717831, -7.457499992730448],
              [-40.387499999225554, -7.457499992730448],
              [-40.387500001024193, -7.456666658439474],
              [-40.3866666658339, -7.456666658439474],
              [-40.386666667632539, -7.454999993454862],
              [-40.385833332442303, -7.454999993454862],
              [-40.385833332442303, -7.454166659163945],
              [-40.384999999050649, -7.454166659163945],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '235',
        OBJECTID: 140.0,
        Nome_area: 'Borda do Araripe',
        COD_area: 'CA146',
        Import_bio: 'Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Recuperação de Áreas Degradadas',
        Area_ha: 36672.3052,
        Shape_Leng: 1.95833,
        Shape_Area: 0.03004,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.632104130999899, -7.617930953999965],
              [-40.636734998999898, -7.608517187999941],
              [-40.644376475999898, -7.59664278299994],
              [-40.645581503, -7.594769895999949],
              [-40.649118153, -7.59076423399995],
              [-40.651213887, -7.58855398099996],
              [-40.653826233, -7.586203188999951],
              [-40.653883569, -7.586155330999931],
              [-40.653921274, -7.586121453999967],
              [-40.654033668, -7.586027691999963],
              [-40.654073314, -7.58599461799998],
              [-40.659086347, -7.581812595999967],
              [-40.662657446, -7.579499928999946],
              [-40.665628618, -7.576227789999963],
              [-40.667603352999897, -7.573984344999929],
              [-40.667812077999898, -7.573747216999951],
              [-40.66786259, -7.573689831999952],
              [-40.66791896, -7.573625790999936],
              [-40.677320847, -7.562943215999952],
              [-40.6813362, -7.557738265999935],
              [-40.690988594760604, -7.543333325772323],
              [-40.689999999700206, -7.543333325772323],
              [-40.689999998800886, -7.542499992380669],
              [-40.689166665409232, -7.542499992380669],
              [-40.689166666308552, -7.543333325772323],
              [-40.688333332916955, -7.543333325772323],
              [-40.688333332017635, -7.544166657365281],
              [-40.686666665234384, -7.54416665916392],
              [-40.686666667033023, -7.544999992555574],
              [-40.685833333641369, -7.544999993454894],
              [-40.68583333274205, -7.546666657540186],
              [-40.684166666858118, -7.546666658439506],
              [-40.684166665958799, -7.547499992730422],
              [-40.684999998451133, -7.547499992730422],
              [-40.684999998451133, -7.549999992006008],
              [-40.684166667757495, -7.549999992905327],
              [-40.684166666858118, -7.550833325397605],
              [-40.683333331667882, -7.550833325397605],
              [-40.683333332567202, -7.551666658789202],
              [-40.682499998276285, -7.551666659688522],
              [-40.682499998276285, -7.550833325397605],
              [-40.681666664884631, -7.550833327196244],
              [-40.681666665783951, -7.553333325572453],
              [-40.682499998276285, -7.553333325572453],
              [-40.682499998276285, -7.554166658964107],
              [-40.68166666668327, -7.554166658964107],
              [-40.68166666668327, -7.554999994154343],
              [-40.6799999990007, -7.554999992355704],
              [-40.679999999900019, -7.554166658964107],
              [-40.679166667407742, -7.554166658964107],
              [-40.679166666508422, -7.554999992355704],
              [-40.678333331318129, -7.554999992355704],
              [-40.678333334016088, -7.556666659138955],
              [-40.677499999725171, -7.556666659138955],
              [-40.677499998825851, -7.557499992530609],
              [-40.676666665434198, -7.557499992530609],
              [-40.676666666333517, -7.558333326821526],
              [-40.674166667957309, -7.558333325022886],
              [-40.674166667057989, -7.559166658414483],
              [-40.672500000274738, -7.55916666021318],
              [-40.672500000274738, -7.562499992880305],
              [-40.670833331692847, -7.562499992880305],
              [-40.670833333491487, -7.564166658764236],
              [-40.671666665983821, -7.564166658764236],
              [-40.671666665983821, -7.566666658939084],
              [-40.669999997401931, -7.566666658939084],
              [-40.66999999830125, -7.567499993230058],
              [-40.669166667607556, -7.567499993230058],
              [-40.669166665808916, -7.568333325722335],
              [-40.666666665634068, -7.568333324823016],
              [-40.666666665634068, -7.569166659113989],
              [-40.66416666545922, -7.569166659113989],
              [-40.664166667257859, -7.571666658389518],
              [-40.662499999575289, -7.571666658389518],
              [-40.662499999575289, -7.573333326072088],
              [-40.659166667807426, -7.573333326072088],
              [-40.659166668706746, -7.574166658564423],
              [-40.65833333261719, -7.574166658564423],
              [-40.658333333516509, -7.574999992855339],
              [-40.659166666908106, -7.574999992855339],
              [-40.659166667807426, -7.578333324623202],
              [-40.65833333171787, -7.578333325522522],
              [-40.65833333171787, -7.579166658914119],
              [-40.657499998326216, -7.579166658914119],
              [-40.657499999225536, -7.579999993205092],
              [-40.655000000849327, -7.579999993205092],
              [-40.654999999050688, -7.579166658914119],
              [-40.654166667457673, -7.579166658914119],
              [-40.654166665659034, -7.579999993205092],
              [-40.653333334066076, -7.579999993205092],
              [-40.653333330468797, -7.58083332569737],
              [-40.651666667282825, -7.58083332569737],
              [-40.651666669081465, -7.582499992480621],
              [-40.650833333891228, -7.582499992480621],
              [-40.650833334790548, -7.583333326771594],
              [-40.648333332817003, -7.583333325872218],
              [-40.648333331917684, -7.584166658364552],
              [-40.647499999425406, -7.584166658364552],
              [-40.647499999425406, -7.584999993554788],
              [-40.646666666933072, -7.584999992655469],
              [-40.646666666933072, -7.583333325872218],
              [-40.645833331742836, -7.583333326771594],
              [-40.645833332642155, -7.582499992480621],
              [-40.644999998351182, -7.582499992480621],
              [-40.645000001049141, -7.581666659988343],
              [-40.644166666758224, -7.581666659089024],
              [-40.644166667657544, -7.58249999337994],
              [-40.643333333366627, -7.582499992480621],
              [-40.643333333366627, -7.584166658364552],
              [-40.642499999974973, -7.584166658364552],
              [-40.642499999974973, -7.584999992655469],
              [-40.639999999800125, -7.584999992655469],
              [-40.639999998900805, -7.585833326047123],
              [-40.640833331393083, -7.585833326047123],
              [-40.640833331393083, -7.587499992830374],
              [-40.639166666408471, -7.587499992830374],
              [-40.639166666408471, -7.589166658714305],
              [-40.638333331218234, -7.589166658714305],
              [-40.638333333016874, -7.591666658889153],
              [-40.635833331942649, -7.591666658889153],
              [-40.635833333741289, -7.59249999138143],
              [-40.630833332492273, -7.59249999138143],
              [-40.630833332492273, -7.591666658889153],
              [-40.63, -7.591666659788473],
              [-40.629999999100619, -7.589999993005222],
              [-40.629166666608342, -7.589999993005222],
              [-40.629166667507661, -7.589166658714305],
              [-40.628333332317368, -7.589166658714305],
              [-40.628333332317368, -7.587499992830374],
              [-40.62583333304184, -7.587499992830374],
              [-40.625833333941159, -7.584999992655469],
              [-40.624999999650242, -7.584999992655469],
              [-40.624999999650242, -7.584166658364552],
              [-40.623333333766311, -7.584166658364552],
              [-40.623333331967672, -7.583333325872218],
              [-40.622499999475338, -7.583333325872218],
              [-40.622499999475338, -7.584166658364552],
              [-40.62166666788238, -7.584166658364552],
              [-40.62166666608374, -7.583333325872218],
              [-40.617500000924281, -7.583333328570234],
              [-40.616666666633307, -7.583333325872218],
              [-40.616666668431947, -7.584166658364552],
              [-40.61583333324171, -7.584166658364552],
              [-40.61583333324171, -7.584999992655469],
              [-40.614999999850056, -7.584999991756149],
              [-40.614999998950736, -7.585833326047123],
              [-40.613333333966125, -7.585833326047123],
              [-40.613333333066805, -7.588333326221971],
              [-40.614166666458459, -7.588333324423331],
              [-40.614166667357779, -7.589166658714305],
              [-40.614999999850056, -7.589166658714305],
              [-40.615000001648696, -7.59249999407939],
              [-40.614166665559139, -7.59249999138143],
              [-40.614166665559139, -7.593333325672404],
              [-40.613333331268166, -7.593333325672404],
              [-40.613333333966125, -7.594999992455655],
              [-40.614166665559139, -7.594999992455655],
              [-40.614166665559139, -7.595833326746572],
              [-40.614999998950736, -7.595833325847252],
              [-40.614999998950736, -7.596666657440267],
              [-40.616666665733987, -7.596666659238906],
              [-40.616666667532627, -7.598333326022157],
              [-40.618333332517238, -7.598333326022157],
              [-40.618333331617919, -7.604166658864187],
              [-40.617500000024961, -7.604166658864187],
              [-40.617499998226322, -7.604999993155104],
              [-40.618333332517238, -7.604999993155104],
              [-40.618333332517238, -7.605833325647438],
              [-40.619166666808212, -7.605833325647438],
              [-40.619166667707532, -7.606666659039035],
              [-40.619999999300489, -7.606666659039035],
              [-40.619999999300489, -7.608333325822286],
              [-40.620833331792767, -7.608333325822286],
              [-40.620833333591406, -7.611666659388789],
              [-40.62166666608374, -7.611666658489469],
              [-40.62166666608374, -7.612499992780386],
              [-40.622499998576018, -7.612499992780386],
              [-40.622500000374657, -7.613333327071359],
              [-40.624166665359269, -7.61333332617204],
              [-40.624166666258589, -7.614166658664317],
              [-40.624999999650242, -7.614166658664317],
              [-40.624999998750923, -7.614999992955291],
              [-40.6258333312432, -7.614999992955291],
              [-40.62583333304184, -7.615833325447568],
              [-40.626666665534117, -7.615833325447568],
              [-40.626666666433493, -7.616666658839165],
              [-40.627499999825091, -7.616666658839165],
              [-40.627499999825091, -7.615833325447568],
              [-40.630833331592953, -7.615833325447568],
              [-40.630833331592953, -7.616666657939845],
              [-40.631666667682509, -7.616666658839165],
              [-40.63166666678319, -7.618333325622416],
              [-40.631941954583404, -7.618333325622416],
              [-40.632104130999899, -7.617930953999965],
            ],
          ],
          [
            [
              [-40.715141028999902, -7.481414650999959],
              [-40.715008611368901, -7.480833325007183],
              [-40.714999998750898, -7.480833324997889],
              [-40.714999998750898, -7.482499992680459],
              [-40.715283026064036, -7.48249999252774],
              [-40.715141028999902, -7.481414650999959],
            ],
          ],
          [
            [
              [-40.597794947, -7.411999235999919],
              [-40.589242657999897, -7.408651780999958],
              [-40.589161030999897, -7.408619836999954],
              [-40.587321511, -7.407899936999975],
              [-40.567499999532146, -7.400142037740362],
              [-40.5675000010242, -7.400833325697363],
              [-40.568333333516478, -7.400833325697363],
              [-40.568333331717838, -7.401666659089017],
              [-40.569999998501089, -7.401666659089017],
              [-40.569999999400409, -7.404999992655462],
              [-40.570833331892686, -7.404999992655462],
              [-40.570833331892686, -7.405833326047116],
              [-40.571666667982299, -7.405833326047116],
              [-40.57166666708298, -7.406666658539393],
              [-40.575000000649482, -7.406666658539393],
              [-40.574999999750162, -7.407499993729687],
              [-40.575833334041079, -7.407499992830367],
              [-40.575833334041079, -7.409166658714298],
              [-40.580000000099858, -7.409166658714298],
              [-40.579999998301219, -7.409999993005215],
              [-40.580833332592192, -7.409999993005215],
              [-40.580833332592192, -7.411666658889146],
              [-40.58333333366636, -7.411666658889146],
              [-40.58333333276704, -7.414999992455648],
              [-40.584999999550291, -7.414999992455648],
              [-40.584999997751652, -7.415833324947926],
              [-40.585833332941945, -7.415833325847245],
              [-40.585833332042569, -7.41666665744026],
              [-40.587499999725139, -7.416666659238899],
              [-40.587499999725139, -7.417499991731177],
              [-40.588333332217474, -7.417499992630496],
              [-40.588333334016113, -7.419999992805401],
              [-40.589999999900044, -7.419999992805401],
              [-40.589999999000725, -7.420833326196998],
              [-40.591666665783976, -7.420833326196998],
              [-40.591666665783976, -7.422499992980249],
              [-40.595833331842755, -7.422499992980249],
              [-40.595833332742075, -7.423333324573207],
              [-40.596666665234352, -7.423333325472527],
              [-40.596666667932311, -7.424166657964861],
              [-40.597500000424645, -7.4241666597635],
              [-40.597500000424645, -7.424999993155097],
              [-40.602499999875079, -7.424999991356458],
              [-40.602499998975702, -7.426666659039029],
              [-40.603333331468036, -7.426666659039029],
              [-40.603333331468036, -7.427499992430683],
              [-40.604166666658273, -7.427499992430683],
              [-40.604166667557593, -7.429999992605531],
              [-40.604999998251287, -7.429999992605531],
              [-40.605000000049927, -7.430833325997128],
              [-40.606666667732497, -7.430833326896447],
              [-40.606666666833178, -7.431666658489462],
              [-40.607499999325455, -7.431666658489462],
              [-40.607499999325455, -7.432499992780379],
              [-40.608333332717109, -7.432499993679698],
              [-40.608333331817789, -7.43416665866431],
              [-40.609166667008026, -7.43416665866431],
              [-40.609166665209386, -7.433333324373393],
              [-40.61166666808225, -7.433333326172033],
              [-40.611666665384234, -7.432499992780379],
              [-40.615000000749376, -7.432499992780379],
              [-40.614999998950736, -7.433333326172033],
              [-40.616666667532627, -7.433333327071352],
              [-40.616666666633307, -7.429999992605531],
              [-40.618333332517238, -7.42999999620281],
              [-40.620833331792767, -7.429999992605531],
              [-40.620833331792767, -7.430833325097808],
              [-40.622499998576018, -7.430833325997128],
              [-40.622499998576018, -7.431666658489462],
              [-40.623333333766311, -7.431666658489462],
              [-40.623333332866991, -7.432499991881059],
              [-40.624166665359269, -7.432499993679698],
              [-40.624166665359269, -7.433333326172033],
              [-40.626666667332813, -7.433333326172033],
              [-40.626666665534117, -7.432499992780379],
              [-40.629166666608342, -7.432499992780379],
              [-40.629166665709022, -7.429999992605531],
              [-40.630000000899258, -7.429999991706211],
              [-40.63, -7.429166659213877],
              [-40.63166666678319, -7.429166659213877],
              [-40.631666667682509, -7.42833332582228],
              [-40.632499999275524, -7.42833332582228],
              [-40.632499998376204, -7.427499992430683],
              [-40.633333333566441, -7.427499994229322],
              [-40.633333332667121, -7.426666659039029],
              [-40.635268204178296, -7.426666659039029],
              [-40.597794947, -7.411999235999919],
            ],
          ],
          [
            [
              [-40.566666666534537, -7.399815881308969],
              [-40.566666666733227, -7.399999993205086],
              [-40.567137074300625, -7.399999993205086],
              [-40.566666666534537, -7.399815881308969],
            ],
          ],
          [
            [
              [-40.564166667280041, -7.398837411912377],
              [-40.564166667457698, -7.399166659813432],
              [-40.565007898240879, -7.399166659510818],
              [-40.564166667280041, -7.398837411912377],
            ],
          ],
          [
            [
              [-40.562499999035772, -7.398185098169341],
              [-40.562499998875808, -7.398333325522515],
              [-40.562878721429875, -7.398333325522515],
              [-40.562499999035772, -7.398185098169341],
            ],
          ],
          [
            [
              [-40.544097393980707, -7.368333326871873],
              [-40.543333331767826, -7.36833332522275],
              [-40.543333331767826, -7.371666658789252],
              [-40.544166667857382, -7.371666658789252],
              [-40.544166666058743, -7.374999992355697],
              [-40.543333333566466, -7.374999994154336],
              [-40.543333332667146, -7.376666657340309],
              [-40.543333333566466, -7.378333325922199],
              [-40.544166666058743, -7.378333325922199],
              [-40.544166666958063, -7.379166660213173],
              [-40.545833331942674, -7.379166658414533],
              [-40.545833333741314, -7.37999999360477],
              [-40.546188702635447, -7.379999993221261],
              [-40.544097393980707, -7.368333326871873],
            ],
          ],
          [
            [
              [-40.54333333289803, -7.364070902144642],
              [-40.543333333566466, -7.364999992555568],
              [-40.54349987702976, -7.364999992555568],
              [-40.54333333289803, -7.364070902144642],
            ],
          ],
          [
            [
              [-40.542752980984616, -7.360833325460962],
              [-40.542499999275492, -7.360833325597469],
              [-40.542499998376172, -7.36249999238072],
              [-40.543051739417358, -7.36249999238072],
              [-40.542752980984616, -7.360833325460962],
            ],
          ],
          [
            [
              [-40.541818888527551, -7.354166659363784],
              [-40.541666665883895, -7.354166659363784],
              [-40.541666666783215, -7.355833326147035],
              [-40.542047574216689, -7.355833326147035],
              [-40.541818888527551, -7.354166659363784],
            ],
          ],
          [
            [
              [-40.540540502999903, -7.344849754999937],
              [-40.5404222559999, -7.34140328599995],
              [-40.541452417538089, -7.337499992893077],
              [-40.540833332492241, -7.33749999422929],
              [-40.540833331592921, -7.3358333256474],
              [-40.53916666660831, -7.3358333256474],
              [-40.53916666660831, -7.334999993155122],
              [-40.538333332317393, -7.334999993155122],
              [-40.538333331418073, -7.334166658864149],
              [-40.537500000724435, -7.334166659763468],
              [-40.537499999825116, -7.332499992080898],
              [-40.534999998750891, -7.332499992980217],
              [-40.534999998750891, -7.333333325472552],
              [-40.534166666258614, -7.333333325472552],
              [-40.534166666258614, -7.334166657964829],
              [-40.53333333196764, -7.334166658864149],
              [-40.53333333196764, -7.333333324573232],
              [-40.532499999475363, -7.333333325472552],
              [-40.532500000374682, -7.329166658514453],
              [-40.531666664285069, -7.329166658514453],
              [-40.531666667882348, -7.327499992630521],
              [-40.530833332692112, -7.327499992630521],
              [-40.530833333591431, -7.324999992455616],
              [-40.526666665734012, -7.324999992455616],
              [-40.525833332342359, -7.324999992455616],
              [-40.525833333241678, -7.329166658514453],
              [-40.526666665734012, -7.329166658514453],
              [-40.526666666633332, -7.334166658864149],
              [-40.527500000924249, -7.334166658864149],
              [-40.52749999822629, -7.3358333256474],
              [-40.528333332517263, -7.335833326546719],
              [-40.528333332517263, -7.337499992430651],
              [-40.529999998401138, -7.33749999422929],
              [-40.529999998401138, -7.338333324922985],
              [-40.531666666983028, -7.338333326721624],
              [-40.531666664285069, -7.339166659213902],
              [-40.532499998576043, -7.339166660113221],
              [-40.532499998576043, -7.34166665848943],
              [-40.531666667882348, -7.34166665848943],
              [-40.531666666083709, -7.342499992780404],
              [-40.533333333766279, -7.342499992780404],
              [-40.53333333286696, -7.343333327071321],
              [-40.534999998750891, -7.343333326172001],
              [-40.53500000054953, -7.344166658664335],
              [-40.535833331243225, -7.344166658664335],
              [-40.535833333041865, -7.344999992955252],
              [-40.534999998750891, -7.344999993854572],
              [-40.534999999650211, -7.346666657939863],
              [-40.537499998925739, -7.346666658839183],
              [-40.537499999825116, -7.349166659014031],
              [-40.538333330518753, -7.349166659014031],
              [-40.538333331418073, -7.350833325797282],
              [-40.53916666570899, -7.350833325797282],
              [-40.53916666570899, -7.351666659188936],
              [-40.540833331592921, -7.351666659188936],
              [-40.540833333391561, -7.352499992580533],
              [-40.541590202838407, -7.352499992580533],
              [-40.540540502999903, -7.344849754999937],
            ],
          ],
          [
            [
              [-40.541672351952791, -7.336666659039054],
              [-40.541666665883895, -7.336666659039054],
              [-40.541666665883895, -7.336688203615033],
              [-40.541672351952791, -7.336666659039054],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '236',
        OBJECTID: 129.0,
        Nome_area: 'Salitre 1',
        COD_area: 'CA135',
        Import_bio: 'Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Recuperação de Áreas Degradadas',
        Area_ha: 57902.7534,
        Shape_Leng: 2.59333,
        Shape_Area: 0.0474,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.541666665883895, -7.336688203615033],
              [-40.541666665883895, -7.336666659039054],
              [-40.541672351952791, -7.336666659039054],
              [-40.541894108, -7.335826422999953],
              [-40.541508194, -7.332269118999928],
              [-40.540224834999897, -7.330621165999951],
              [-40.536273395, -7.327125583999928],
              [-40.523723229, -7.318450455999936],
              [-40.523678614999902, -7.318461854999947],
              [-40.520000000399648, -7.319399959764264],
              [-40.520000000399648, -7.31999999300524],
              [-40.520833332891982, -7.31999999300524],
              [-40.520833331992662, -7.322499993180088],
              [-40.521666665384259, -7.322499993180088],
              [-40.521666668082219, -7.323333326571742],
              [-40.523333331268191, -7.323333325672422],
              [-40.523333331268191, -7.324166659064019],
              [-40.526666665734012, -7.3241666581647],
              [-40.526666665734012, -7.324999992455616],
              [-40.530833333591431, -7.324999992455616],
              [-40.530833332692112, -7.327499992630521],
              [-40.531666667882348, -7.327499992630521],
              [-40.531666664285069, -7.329166658514453],
              [-40.532500000374682, -7.329166658514453],
              [-40.532499999475363, -7.333333325472552],
              [-40.53333333196764, -7.333333324573232],
              [-40.53333333196764, -7.334166658864149],
              [-40.534166666258614, -7.334166657964829],
              [-40.534166666258614, -7.333333325472552],
              [-40.534999998750891, -7.333333325472552],
              [-40.534999998750891, -7.332499992980217],
              [-40.537499999825116, -7.332499992080898],
              [-40.537500000724435, -7.334166659763468],
              [-40.538333331418073, -7.334166658864149],
              [-40.538333332317393, -7.334999993155122],
              [-40.53916666660831, -7.334999993155122],
              [-40.53916666660831, -7.3358333256474],
              [-40.540833331592921, -7.3358333256474],
              [-40.540833332492241, -7.33749999422929],
              [-40.541452417538089, -7.337499992893077],
              [-40.541666665883895, -7.336688203615033],
            ],
          ],
          [
            [
              [-40.500468612, -7.327995335999955],
              [-40.487013777, -7.336459859999925],
              [-40.486940487, -7.336505966999943],
              [-40.482868072, -7.339290209999936],
              [-40.476246986, -7.344727266999946],
              [-40.476154498, -7.344783094999964],
              [-40.466737117, -7.350467591999973],
              [-40.45930838, -7.354951707999923],
              [-40.459073063999909, -7.355093748999936],
              [-40.458333332329097, -7.355539136459627],
              [-40.458333333916187, -7.357499992930286],
              [-40.458333331218228, -7.359166658814217],
              [-40.459999998900798, -7.359166658814217],
              [-40.459999999800118, -7.359999994004454],
              [-40.460833331393076, -7.359999993105134],
              [-40.460833334091035, -7.36249999238072],
              [-40.461666666583369, -7.36249999238072],
              [-40.461666667482689, -7.363333325772317],
              [-40.462499999974966, -7.363333325772317],
              [-40.462500000874286, -7.365833326846484],
              [-40.46333333336662, -7.365833325947165],
              [-40.463333331567981, -7.364999992555568],
              [-40.464166665858897, -7.364999992555568],
              [-40.464166665858897, -7.365833325947165],
              [-40.465833332642148, -7.365833325047845],
              [-40.465833332642148, -7.368333327021389],
              [-40.466666667832385, -7.368333327021389],
              [-40.466666667832385, -7.369166658614347],
              [-40.46916666890661, -7.369166658614347],
              [-40.469166665309331, -7.369999992905321],
              [-40.469999998700928, -7.369999992905321],
              [-40.469999998700928, -7.370833327196237],
              [-40.470833332991901, -7.370833325397598],
              [-40.470833333891221, -7.371666658789252],
              [-40.47333333226743, -7.371666658789252],
              [-40.47333333136811, -7.372499993080169],
              [-40.474166667457666, -7.372499993080169],
              [-40.474166667457666, -7.3741666589641],
              [-40.474999999950001, -7.3741666589641],
              [-40.47500000084932, -7.375833325747351],
              [-40.476666667632571, -7.375833325747351],
              [-40.476666666733252, -7.377499991631282],
              [-40.478333333516503, -7.377499992530602],
              [-40.478333333516503, -7.378333325022879],
              [-40.479999998501114, -7.378333325922199],
              [-40.479999998501114, -7.379166660213173],
              [-40.480833331892711, -7.379166658414533],
              [-40.480833332792031, -7.37999999270545],
              [-40.482499998675962, -7.37999999270545],
              [-40.482499999575282, -7.380833326097047],
              [-40.483333332966879, -7.380833326097047],
              [-40.483333333866199, -7.382499992880298],
              [-40.484166667257853, -7.382499992880298],
              [-40.484166665459213, -7.383333324473313],
              [-40.48499999975013, -7.383333325372632],
              [-40.48499999975013, -7.384166658764229],
              [-40.486666665634061, -7.384166658764229],
              [-40.486666666533381, -7.383333325372632],
              [-40.487499999925035, -7.383333325372632],
              [-40.487499999025715, -7.384166658764229],
              [-40.488333333316632, -7.384166658764229],
              [-40.488333332417312, -7.384999993055203],
              [-40.489166666708286, -7.384999993055203],
              [-40.489166665808909, -7.38583332554748],
              [-40.490000000999203, -7.38583332554748],
              [-40.489999998301244, -7.389166659113982],
              [-40.49083333349148, -7.389166659113982],
              [-40.49083333259216, -7.389999992505579],
              [-40.493333333666385, -7.389999992505579],
              [-40.493333332767065, -7.390833324997914],
              [-40.494166664360023, -7.390833325897233],
              [-40.494166667957302, -7.392499992680484],
              [-40.495833332042594, -7.392499991781165],
              [-40.495833332042594, -7.394999992855332],
              [-40.496666666333567, -7.394999992855332],
              [-40.496666666333567, -7.395833327146306],
              [-40.499166667407735, -7.395833325347667],
              [-40.499166666508415, -7.394999992855332],
              [-40.504166665958849, -7.394999991956013],
              [-40.504166666858168, -7.394166657665096],
              [-40.504999999350446, -7.394166659463735],
              [-40.505000000249765, -7.391666658389511],
              [-40.506666665234377, -7.391666658389511],
              [-40.506666667033016, -7.390833325897233],
              [-40.509166666308545, -7.390833325897233],
              [-40.509166665409225, -7.389999992505579],
              [-40.51166666648345, -7.389999992505579],
              [-40.51166666558413, -7.389166658214663],
              [-40.514166666658298, -7.389166660013302],
              [-40.514166666658298, -7.385833324648161],
              [-40.515833332542229, -7.38583332554748],
              [-40.515833331642909, -7.384999993055203],
              [-40.514999997351936, -7.384999993954523],
              [-40.514999998251255, -7.384166658764229],
              [-40.514166666658298, -7.384166658764229],
              [-40.514166666658298, -7.37999999270545],
              [-40.513333332367324, -7.37999999360477],
              [-40.513333331468004, -7.379166658414533],
              [-40.514166667557618, -7.379166658414533],
              [-40.514166664859658, -7.375833325747351],
              [-40.513333332367324, -7.375833325747351],
              [-40.513333332367324, -7.371666658789252],
              [-40.512499999875047, -7.371666658789252],
              [-40.512500001673686, -7.367499992730416],
              [-40.513333333266701, -7.367499992730416],
              [-40.513333332367324, -7.366666658439499],
              [-40.515000000949215, -7.366666658439499],
              [-40.515000000949215, -7.365833326846484],
              [-40.514166665758978, -7.365833325047845],
              [-40.514166665758978, -7.364999992555568],
              [-40.513333332367324, -7.364999992555568],
              [-40.513333332367324, -7.364166659163971],
              [-40.514166665758978, -7.36416666006329],
              [-40.514166665758978, -7.3624999914814],
              [-40.513333332367324, -7.36249999238072],
              [-40.513333332367324, -7.358333325422564],
              [-40.514166666658298, -7.358333325422564],
              [-40.514166665758978, -7.354166658464464],
              [-40.513333331468004, -7.354166658464464],
              [-40.513333331468004, -7.353333325972187],
              [-40.512499999875047, -7.353333325972187],
              [-40.512499999875047, -7.350833325797282],
              [-40.510833331293156, -7.350833325797282],
              [-40.510833331293156, -7.349999992405685],
              [-40.511666667382769, -7.349999992405685],
              [-40.511666667382769, -7.347499994029477],
              [-40.509999998800879, -7.347499993130157],
              [-40.509999998800879, -7.346666658839183],
              [-40.509166666308545, -7.346666658839183],
              [-40.509166666308545, -7.342499992780404],
              [-40.508333332916948, -7.342499992780404],
              [-40.508333332017628, -7.34166665759011],
              [-40.507499999525294, -7.34166665759011],
              [-40.507499998625974, -7.340833325997153],
              [-40.505833332742043, -7.340833325997153],
              [-40.505833332742043, -7.339999991706179],
              [-40.504999998451126, -7.339999992605556],
              [-40.504999998451126, -7.338333325822305],
              [-40.505833331842723, -7.338333325822305],
              [-40.505833332742043, -7.3358333256474],
              [-40.504999998451126, -7.3358333256474],
              [-40.504999997551806, -7.334166659763468],
              [-40.504166667757488, -7.334166658864149],
              [-40.504166666858168, -7.333333325472552],
              [-40.503333331667875, -7.333333325472552],
              [-40.503333330768555, -7.330833326197023],
              [-40.504166667757488, -7.330833326197023],
              [-40.504166666858168, -7.329166658514453],
              [-40.504999999350446, -7.329166658514453],
              [-40.505000000249765, -7.327499992630521],
              [-40.504166666858168, -7.327499992630521],
              [-40.504166666503266, -7.326184538582179],
              [-40.500468612, -7.327995335999955],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '237',
        OBJECTID: 134.0,
        Nome_area: 'Salitre 2',
        COD_area: 'CA140',
        Import_bio: 'Muito Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Recuperação de Áreas Degradadas',
        Area_ha: 20732.47,
        Shape_Leng: 1.075,
        Shape_Area: 0.01698,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.449055047999899, -7.361125528999931],
              [-40.448672319, -7.361275337999929],
              [-40.447942188, -7.361561126999963],
              [-40.433997631, -7.367019339999961],
              [-40.433591706999898, -7.367178227999941],
              [-40.43358491, -7.367178643999972],
              [-40.411026141, -7.368558363999968],
              [-40.400349563, -7.368532298999974],
              [-40.393878139, -7.368168385999975],
              [-40.387480495, -7.367034784999936],
              [-40.374522269, -7.362987701999947],
              [-40.372769567, -7.362440374999945],
              [-40.360983897, -7.355600699999974],
              [-40.358223607, -7.352492069999924],
              [-40.355455359, -7.347275731999953],
              [-40.354790400999903, -7.345784383999952],
              [-40.354125442999901, -7.344293035999953],
              [-40.3523435399999, -7.337686699999946],
              [-40.350793595, -7.334171399999956],
              [-40.346820148999903, -7.330237012999935],
              [-40.346085778, -7.329699633999949],
              [-40.3453514079999, -7.329162254999971],
              [-40.33801539400001, -7.32379409199996],
              [-40.337982355999898, -7.323774024999979],
              [-40.3293943749999, -7.318557856999961],
              [-40.326520748, -7.31483493799993],
              [-40.325523646, -7.312277783999948],
              [-40.321431444, -7.305891928999923],
              [-40.31856315999989, -7.303340395999944],
              [-40.318144554999897, -7.303399400999984],
              [-40.317021597, -7.303558],
              [-40.310740829999901, -7.300697384999978],
              [-40.308760812999893, -7.301234590999974],
              [-40.307499999025708, -7.301153584531908],
              [-40.307499999025708, -7.301666658389536],
              [-40.308333331517986, -7.301666658389536],
              [-40.308333333316625, -7.303333326072107],
              [-40.309999998301237, -7.303333326072107],
              [-40.310000000099876, -7.304166659463704],
              [-40.310833331692834, -7.304166658564384],
              [-40.310833331692834, -7.305833325347635],
              [-40.310833331692834, -7.308333325522483],
              [-40.311666665983807, -7.308333325522483],
              [-40.311666667782447, -7.309166658914137],
              [-40.314166667957295, -7.309166658014817],
              [-40.314166667957295, -7.311666659088985],
              [-40.315833332042587, -7.311666659088985],
              [-40.315833332042587, -7.312499992480639],
              [-40.317499998825838, -7.312499992480639],
              [-40.317500000624477, -7.313333325872236],
              [-40.318333331318115, -7.313333325872236],
              [-40.318333334016131, -7.314999992655487],
              [-40.319166666508409, -7.314999992655487],
              [-40.319166666508409, -7.317499992830335],
              [-40.318333331318115, -7.317499992830335],
              [-40.318333331318115, -7.319166658714266],
              [-40.319999999900006, -7.319166658714266],
              [-40.320000001698702, -7.31999999300524],
              [-40.323333333466508, -7.31999999300524],
              [-40.323333332567188, -7.320833325497517],
              [-40.32666666523437, -7.320833325497517],
              [-40.32666666703301, -7.321666659788491],
              [-40.327499998625967, -7.321666658889171],
              [-40.327499999525287, -7.322499994079408],
              [-40.329166666308538, -7.322499993180088],
              [-40.329166667207858, -7.324166659064019],
              [-40.330000001498831, -7.324166659064019],
              [-40.330000000599512, -7.326666659238867],
              [-40.330833333091789, -7.326666659238867],
              [-40.330833333091789, -7.328333326022118],
              [-40.33249999897572, -7.328333326022118],
              [-40.33249999897572, -7.329999993704689],
              [-40.333333333266694, -7.329999992805369],
              [-40.333333332367374, -7.330833326197023],
              [-40.33249999987504, -7.330833324398384],
              [-40.33249999987504, -7.333333326371871],
              [-40.333333332367374, -7.333333325472552],
              [-40.333333332367374, -7.336666659039054],
              [-40.335000000949265, -7.336666658139734],
              [-40.335000000949265, -7.337499992430651],
              [-40.335833332542222, -7.337499992430651],
              [-40.335833332542222, -7.339166659213902],
              [-40.337499999325473, -7.339166657415262],
              [-40.337500000224793, -7.339999992605556],
              [-40.33833333271707, -7.339999992605556],
              [-40.33833333361639, -7.340833325997153],
              [-40.339999998601002, -7.340833325997153],
              [-40.339999998601002, -7.34166665848943],
              [-40.342499999675226, -7.34166665848943],
              [-40.342500000574546, -7.343333326172001],
              [-40.344999999850074, -7.343333326172001],
              [-40.345000000749394, -7.344999992955252],
              [-40.347499998226283, -7.344999993854572],
              [-40.347500000924242, -7.345833325447586],
              [-40.348333332517257, -7.345833325447586],
              [-40.348333331617937, -7.346666658839183],
              [-40.350000001099147, -7.346666658839183],
              [-40.350000001998467, -7.347499994029477],
              [-40.350833331792785, -7.347499993130157],
              [-40.350833332692105, -7.348333326521754],
              [-40.353333333766273, -7.348333325622434],
              [-40.353333332866953, -7.349166659913351],
              [-40.354166666258607, -7.349166659014031],
              [-40.354166666258607, -7.349999992405685],
              [-40.355833333041858, -7.349999992405685],
              [-40.355833333041858, -7.354166658464464],
              [-40.356666666433455, -7.354166658464464],
              [-40.356666665534135, -7.355833326147035],
              [-40.357499999825109, -7.355833326147035],
              [-40.357499998925789, -7.356666659538632],
              [-40.356666665534135, -7.356666658639313],
              [-40.356666667332775, -7.359166658814217],
              [-40.357500000724428, -7.359166658814217],
              [-40.357500000724428, -7.359999993105134],
              [-40.360833333391554, -7.359999994004454],
              [-40.360833333391554, -7.360833325597469],
              [-40.361666665883888, -7.360833326496788],
              [-40.361666666783208, -7.359999993105134],
              [-40.363333331767819, -7.359999993105134],
              [-40.363333331767819, -7.360833325597469],
              [-40.36499999855107, -7.360833325597469],
              [-40.36499999945039, -7.361666658989066],
              [-40.365833331942667, -7.361666658989066],
              [-40.365833331043348, -7.363333325772317],
              [-40.366666666233641, -7.363333325772317],
              [-40.366666665334321, -7.367499992730416],
              [-40.365833331043348, -7.367499992730416],
              [-40.365833333741307, -7.369166658614347],
              [-40.367500000524558, -7.369166658614347],
              [-40.367500000524558, -7.370833324498278],
              [-40.369166665509169, -7.370833324498278],
              [-40.369166666408489, -7.371666658789252],
              [-40.371666667482657, -7.371666658789252],
              [-40.371666665684018, -7.372499993979488],
              [-40.372499999974991, -7.372499993080169],
              [-40.372500000874311, -7.3741666589641],
              [-40.373333332467269, -7.3741666589641],
              [-40.373333333366588, -7.374999992355697],
              [-40.374166666758242, -7.374999991456377],
              [-40.374166665858922, -7.375833325747351],
              [-40.375000001049159, -7.375833325747351],
              [-40.3749999983512, -7.376666659138948],
              [-40.375833331742797, -7.376666657340309],
              [-40.375833333541493, -7.378333326821519],
              [-40.377499999425368, -7.378333325922199],
              [-40.377499997626728, -7.379166658414533],
              [-40.379166666208619, -7.379166658414533],
              [-40.379166668007258, -7.378333326821519],
              [-40.379999998700953, -7.378333325922199],
              [-40.379999999600273, -7.379166657515214],
              [-40.38083333299187, -7.379166658414533],
              [-40.38083333209255, -7.382499992880298],
              [-40.38250000067444, -7.382499992880298],
              [-40.382499998875801, -7.384999993055203],
              [-40.383333330468759, -7.384999993954523],
              [-40.383333331368078, -7.38583332554748],
              [-40.384166667457691, -7.3858333264468],
              [-40.384166666558372, -7.386666658939134],
              [-40.385000001748665, -7.386666659838454],
              [-40.384999999050649, -7.38583332554748],
              [-40.3866666658339, -7.38583332554748],
              [-40.386666667632539, -7.386666658939134],
              [-40.387499999225554, -7.386666658939134],
              [-40.387499999225554, -7.387499993230051],
              [-40.388333331717831, -7.387499993230051],
              [-40.388333333516471, -7.386666658939134],
              [-40.389999998501082, -7.386666659838454],
              [-40.390000000299722, -7.388333325722385],
              [-40.391666665284333, -7.388333324823009],
              [-40.391666667082973, -7.389999992505579],
              [-40.39249999867593, -7.389999992505579],
              [-40.392500000474627, -7.390833325897233],
              [-40.394999998850835, -7.390833325897233],
              [-40.394999998850835, -7.392499992680484],
              [-40.395833332242432, -7.392499992680484],
              [-40.395833332242432, -7.393333326072081],
              [-40.398333332417337, -7.393333326072081],
              [-40.398333332417337, -7.392499992680484],
              [-40.400833332592185, -7.392499992680484],
              [-40.400833333491505, -7.393333326072081],
              [-40.401666666883102, -7.393333326072081],
              [-40.401666665983782, -7.398333325522515],
              [-40.402499997576797, -7.398333325522515],
              [-40.402499999375436, -7.399166659813432],
              [-40.403333331867714, -7.399166658914112],
              [-40.403333331867714, -7.399999994104405],
              [-40.404166665259368, -7.399999993205086],
              [-40.404166667058007, -7.400833326596683],
              [-40.404999998650965, -7.400833325697363],
              [-40.404999999550284, -7.402499992480614],
              [-40.404166667058007, -7.402499992480614],
              [-40.404166667957327, -7.403333325872268],
              [-40.406666666333535, -7.403333325872268],
              [-40.406666666333535, -7.404166658364545],
              [-40.407499999725189, -7.404166658364545],
              [-40.407500001523829, -7.404999992655462],
              [-40.410000000799357, -7.404999992655462],
              [-40.409999999900037, -7.404166658364545],
              [-40.409166665609064, -7.404166658364545],
              [-40.409166665609064, -7.403333325872268],
              [-40.409999999900037, -7.403333325872268],
              [-40.409999999000718, -7.402499992480614],
              [-40.410833331492995, -7.402499992480614],
              [-40.410833332392315, -7.401666659988337],
              [-40.411666666683288, -7.401666659089017],
              [-40.411666667582608, -7.400833325697363],
              [-40.410833331492995, -7.400833325697363],
              [-40.410833333291635, -7.399166658914112],
              [-40.412499998276246, -7.399166658914112],
              [-40.412500000074886, -7.398333325522515],
              [-40.41333333256722, -7.398333325522515],
              [-40.4133333316679, -7.397499993030181],
              [-40.412500000074886, -7.397499993030181],
              [-40.412500000074886, -7.396666657839944],
              [-40.410833331492995, -7.396666658739264],
              [-40.410833331492995, -7.395833325347667],
              [-40.411666666683288, -7.39583332444829],
              [-40.411666666683288, -7.394999992855332],
              [-40.410833331492995, -7.394999991956013],
              [-40.410833331492995, -7.394166658564416],
              [-40.411666665783969, -7.394166658564416],
              [-40.411666667582608, -7.392499992680484],
              [-40.412500000074886, -7.392499992680484],
              [-40.412500000074886, -7.390833325897233],
              [-40.414166665958817, -7.390833325897233],
              [-40.414166666858137, -7.38999999160626],
              [-40.415833333641388, -7.389999992505579],
              [-40.415833332742068, -7.389166659113982],
              [-40.416666667033041, -7.389166659113982],
              [-40.416666665234345, -7.386666658939134],
              [-40.417499998625999, -7.386666658939134],
              [-40.417500000424639, -7.38583332554748],
              [-40.416666667033041, -7.38583332554748],
              [-40.416666667932361, -7.382499992880298],
              [-40.421666665584098, -7.382499992880298],
              [-40.421666665584098, -7.381666658589381],
              [-40.422500000774392, -7.381666658589381],
              [-40.422500000774392, -7.380833326097047],
              [-40.423333332367349, -7.380833325197727],
              [-40.423333331468029, -7.37999999360477],
              [-40.424166666658323, -7.37999999270545],
              [-40.424166666658323, -7.382499992880298],
              [-40.42500000094924, -7.382499992880298],
              [-40.42499999825128, -7.383333325372632],
              [-40.425833332542197, -7.383333324473313],
              [-40.425833332542197, -7.384999993055203],
              [-40.426666667732491, -7.384999993055203],
              [-40.426666667732491, -7.384166658764229],
              [-40.427500000224768, -7.384166658764229],
              [-40.427499998426129, -7.381666658589381],
              [-40.428333331817782, -7.381666658589381],
              [-40.428333332717102, -7.380833326097047],
              [-40.429166667008019, -7.380833325197727],
              [-40.42916666431006, -7.379166658414533],
              [-40.431666665384284, -7.379166658414533],
              [-40.431666666283604, -7.380833326097047],
              [-40.432500000574521, -7.380833326097047],
              [-40.432500000574521, -7.37999999270545],
              [-40.433333331268159, -7.37999999180613],
              [-40.433333331268159, -7.377499992530602],
              [-40.432499997876562, -7.377499992530602],
              [-40.432499998775882, -7.376666659138948],
              [-40.433333331268159, -7.376666659138948],
              [-40.433333331268159, -7.375833325747351],
              [-40.434166666458452, -7.375833326646671],
              [-40.434166665559133, -7.3741666589641],
              [-40.434999999850049, -7.37416665806478],
              [-40.434999999850049, -7.373333325572446],
              [-40.435833331443064, -7.373333325572446],
              [-40.435833332342384, -7.374999992355697],
              [-40.436666665733981, -7.374999994154336],
              [-40.438333331617912, -7.374999994154336],
              [-40.438333331617912, -7.373333325572446],
              [-40.439166666808205, -7.373333325572446],
              [-40.439166667707525, -7.369166657715027],
              [-40.439999998401163, -7.369166658614347],
              [-40.440000001998442, -7.36833332612207],
              [-40.44083333179276, -7.36833332612207],
              [-40.44083333179276, -7.367499992730416],
              [-40.440833333591456, -7.366666658439499],
              [-40.442499999475331, -7.366666658439499],
              [-40.44250000037465, -7.365833325947165],
              [-40.443333332866985, -7.365833325947165],
              [-40.443333332866985, -7.364166659163971],
              [-40.444999999650236, -7.364166659163971],
              [-40.444999998750916, -7.363333325772317],
              [-40.447499998925764, -7.363333325772317],
              [-40.447499998925764, -7.36249999238072],
              [-40.449166667507654, -7.36249999238072],
              [-40.449166665709015, -7.361666658989066],
              [-40.449999999100612, -7.361666658089746],
              [-40.449999999100612, -7.360556580302879],
              [-40.449055047999899, -7.361125528999931],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '239',
        OBJECTID: 171.0,
        Nome_area: 'Serra do Açude Saco II',
        COD_area: 'CA177',
        Import_bio: 'Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Criação de UC de Proteção Integral',
        Area_ha: 35353.9217,
        Shape_Leng: 1.425,
        Shape_Area: 0.02903,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.156666666833189, -8.42583332544757],
              [-40.156666665933869, -8.426666658839167],
              [-40.155833332542215, -8.426666658839167],
              [-40.155833332542215, -8.427499993130141],
              [-40.154166666658284, -8.427499994029461],
              [-40.154166665758964, -8.428333324723098],
              [-40.153333331468048, -8.428333325622418],
              [-40.153333331468048, -8.429999992405669],
              [-40.152499998975713, -8.429999992405669],
              [-40.152499998975713, -8.430833324898003],
              [-40.151666666483436, -8.430833325797323],
              [-40.151666666483436, -8.43166665918892],
              [-40.149166665409211, -8.43166665918892],
              [-40.149166666308588, -8.432499991681198],
              [-40.148333332916934, -8.432499992580574],
              [-40.148333332916934, -8.433333325972171],
              [-40.147499998626017, -8.433333326871491],
              [-40.147499998626017, -8.435833326147019],
              [-40.143333331667861, -8.435833326147019],
              [-40.143333331667861, -8.434999991856102],
              [-40.142499998276264, -8.434999992755422],
              [-40.142500000074904, -8.433333325972171],
              [-40.14166666668325, -8.433333325972171],
              [-40.14166666578393, -8.432499992580574],
              [-40.139999999000736, -8.432499991681198],
              [-40.139999999900056, -8.43166665918892],
              [-40.136666665434234, -8.43166665918892],
              [-40.136666666333554, -8.430833325797323],
              [-40.1358333329419, -8.430833325797323],
              [-40.1358333329419, -8.429999991506349],
              [-40.134166665259329, -8.429999992405669],
              [-40.134166667057968, -8.429166659014072],
              [-40.132499999375455, -8.429166659014072],
              [-40.132499999375455, -8.428333325622418],
              [-40.129166666708272, -8.428333325622418],
              [-40.129166665808953, -8.429166659014072],
              [-40.128333332417299, -8.429166659014072],
              [-40.128333331517979, -8.429999992405669],
              [-40.126666666533367, -8.429999994204309],
              [-40.126666666533367, -8.430833325797323],
              [-40.125833331343131, -8.430833325797323],
              [-40.125833332242451, -8.43166665918892],
              [-40.124999999750173, -8.43166665918892],
              [-40.124999999750173, -8.433333325972171],
              [-40.121666665284351, -8.433333325972171],
              [-40.121666664385032, -8.434166658464449],
              [-40.120833332792017, -8.434166658464449],
              [-40.120833332792017, -8.434999992755422],
              [-40.1199999985011, -8.434999992755422],
              [-40.11999999940042, -8.435833326147019],
              [-40.119166666908086, -8.435833326147019],
              [-40.119166666908086, -8.436666658639353],
              [-40.118333331717849, -8.436666657740034],
              [-40.118333332617169, -8.435833326147019],
              [-40.117499998326196, -8.435833327945659],
              [-40.114999999949987, -8.435833326147019],
              [-40.114999999050667, -8.434999992755422],
              [-40.11416666655839, -8.434999992755422],
              [-40.11416666745771, -8.435833326147019],
              [-40.110833332991888, -8.435833326147019],
              [-40.110833332092568, -8.436666659538673],
              [-40.106666666933108, -8.436666658639353],
              [-40.106666666933108, -8.43749999293027],
              [-40.105833332642135, -8.43749999382959],
              [-40.105833331742815, -8.438333324523285],
              [-40.104166665858884, -8.438333325422605],
              [-40.104166665858884, -8.439166658814202],
              [-40.103333330668647, -8.439166659713521],
              [-40.103333331567967, -8.439999993105175],
              [-40.101666665684036, -8.439999993105175],
              [-40.101666665684036, -8.439166658814202],
              [-40.099999998900785, -8.439166658814202],
              [-40.099999999800104, -8.438333325422605],
              [-40.099166666408507, -8.438333325422605],
              [-40.099166665509188, -8.437499992030951],
              [-40.099999999800104, -8.43749999293027],
              [-40.099999998001465, -8.434999992755422],
              [-40.099166664609868, -8.434999992755422],
              [-40.099166666408507, -8.434166658464449],
              [-40.098333333016853, -8.434166658464449],
              [-40.098333331218214, -8.433333325972171],
              [-40.095833333741325, -8.433333326871491],
              [-40.095833331942686, -8.43166665918892],
              [-40.094999998551032, -8.43166665918892],
              [-40.094999998551032, -8.432499993479894],
              [-40.0933333326671, -8.432499992580574],
              [-40.0933333326671, -8.433333325972171],
              [-40.092499999275503, -8.433333325972171],
              [-40.092499999275503, -8.434166658464449],
              [-40.091666665883906, -8.434166658464449],
              [-40.091666666783226, -8.434999992755422],
              [-40.089999999100655, -8.434999992755422],
              [-40.089999999100655, -8.435833326147019],
              [-40.089166664809682, -8.435833326147019],
              [-40.089166666608321, -8.436666658639353],
              [-40.086666665534153, -8.436666658639353],
              [-40.086666664634834, -8.435833326147019],
              [-40.085833333041819, -8.435833326147019],
              [-40.085833333041819, -8.436666658639353],
              [-40.084166666258625, -8.436666659538673],
              [-40.084166665359248, -8.439166658814202],
              [-40.083333332866971, -8.439166658814202],
              [-40.083333333766291, -8.439999994004495],
              [-40.082499999475374, -8.439999993105175],
              [-40.082499999475374, -8.440833325597453],
              [-40.08166666698304, -8.440833325597453],
              [-40.08166666698304, -8.442499994179343],
              [-40.080833332692123, -8.442499992380704],
              [-40.080833332692123, -8.441666659888369],
              [-40.077499998226301, -8.44166665898905],
              [-40.077499998226301, -8.444999992555552],
              [-40.076666666633344, -8.444999992555552],
              [-40.076666667532663, -8.445833326846525],
              [-40.07583333234237, -8.445833325947206],
              [-40.07583333234237, -8.446666658439483],
              [-40.075000000749412, -8.446666658439483],
              [-40.074999999850093, -8.447499992730457],
              [-40.074166666458439, -8.447499992730457],
              [-40.074166666458439, -8.448333327021373],
              [-40.073333333066842, -8.448333326122054],
              [-40.073333333066842, -8.449166658614331],
              [-40.072499998775868, -8.449166658614331],
              [-40.072499999675188, -8.449999992905305],
              [-40.071666666283591, -8.449999993804624],
              [-40.071666666283591, -8.450833325397582],
              [-40.06999999950034, -8.450833325397582],
              [-40.06999999950034, -8.453333324673167],
              [-40.069166665209366, -8.453333325572487],
              [-40.069166665209366, -8.453565744563434],
              [-40.069166667008005, -8.454999992355738],
              [-40.068333331817769, -8.454999994154377],
              [-40.068333332717089, -8.455833325747335],
              [-40.067500000224811, -8.455833325747335],
              [-40.067499999325491, -8.457499992530586],
              [-40.066666666833157, -8.457499992530586],
              [-40.066666666833157, -8.458333326821503],
              [-40.06583333254224, -8.458333325022863],
              [-40.06583333254224, -8.459166658414517],
              [-40.064166666658309, -8.459166658414517],
              [-40.064166666658309, -8.459999992705434],
              [-40.060833333091807, -8.459999992705434],
              [-40.060833333091807, -8.460833326097088],
              [-40.05999999970021, -8.460833326097088],
              [-40.05999999970021, -8.462499992880339],
              [-40.059166666308556, -8.462499992880339],
              [-40.059166666308556, -8.463333325372616],
              [-40.058333332916959, -8.463333325372616],
              [-40.058333333816279, -8.465833325547464],
              [-40.055833332742054, -8.465833324648145],
              [-40.055833332742054, -8.466666658939118],
              [-40.054999999350457, -8.466666658939118],
              [-40.054999999350457, -8.463333325372616],
              [-40.054166666858123, -8.463333325372616],
              [-40.054166666858123, -8.462499992880339],
              [-40.053333332567206, -8.462499992880339],
              [-40.053333331667886, -8.459166658414517],
              [-40.052500000074929, -8.459166658414517],
              [-40.052500000074929, -8.458333325922183],
              [-40.047499999725176, -8.458333326821503],
              [-40.047499999725176, -8.457499992530586],
              [-40.045833332941925, -8.457499992530586],
              [-40.045833332042605, -8.456666659138989],
              [-40.041928390282919, -8.456666667232867],
              [-40.040833332592172, -8.456666659138989],
              [-40.040833332592172, -8.455833325747335],
              [-40.040000000099894, -8.455833325747335],
              [-40.039999998301255, -8.454999992355738],
              [-40.03916666670824, -8.454999992355738],
              [-40.03916666670824, -8.453333325572487],
              [-40.037499999924989, -8.453333324673167],
              [-40.037499999924989, -8.454166658964084],
              [-40.035833332242419, -8.454166658964084],
              [-40.035833331343099, -8.454999992355738],
              [-40.034999999750141, -8.454999992355738],
              [-40.034999999750141, -8.455833325747335],
              [-40.033333332067571, -8.455833326646655],
              [-40.03333333296689, -8.456666659138989],
              [-40.032500000474613, -8.456666659138989],
              [-40.032499999575293, -8.457499992530586],
              [-40.03166666528432, -8.457499992530586],
              [-40.03166666528432, -8.461666658589365],
              [-40.030833331892723, -8.461666658589365],
              [-40.030833331892723, -8.462499992880339],
              [-40.029999999400388, -8.462499992880339],
              [-40.029999999400388, -8.463333325372616],
              [-40.029166666908111, -8.463333325372616],
              [-40.029166666008791, -8.46416665876427],
              [-40.028333330818498, -8.46416665966359],
              [-40.028333332617137, -8.464999992155867],
              [-40.028333332617137, -8.465833325547464],
              [-40.029166667807431, -8.465833325547464],
              [-40.029166666908111, -8.466666658939118],
              [-40.029999998501069, -8.466666658939118],
              [-40.029999999400388, -8.467499993230035],
              [-40.030833332792042, -8.467499991431396],
              [-40.030833332792042, -8.472499993579788],
              [-40.029999998501069, -8.472499992680468],
              [-40.029999999400388, -8.478333324623179],
              [-40.029166666008791, -8.478333325522499],
              [-40.029166666908111, -8.480833325697404],
              [-40.028333332617137, -8.480833325697404],
              [-40.028333332617137, -8.482499992480598],
              [-40.02749999922554, -8.482499992480598],
              [-40.02749999922554, -8.483333325872252],
              [-40.026666666733263, -8.483333325872252],
              [-40.026666666733263, -8.48583332694642],
              [-40.027499998326221, -8.4858333260471],
              [-40.02749999922554, -8.487499991931031],
              [-40.028333332617137, -8.487499993729671],
              [-40.028333332617137, -8.489999993005199],
              [-40.029166666008791, -8.489999993005199],
              [-40.029166666908111, -8.492499993180104],
              [-40.029999999400388, -8.492499993180104],
              [-40.029999999400388, -8.493333325672381],
              [-40.030833332792042, -8.493333325672381],
              [-40.030833333691362, -8.494166659064035],
              [-40.03333333296689, -8.494166659064035],
              [-40.03333333296689, -8.494999992455632],
              [-40.034166666358544, -8.494999993354952],
              [-40.034166666358544, -8.495833325847286],
              [-40.034999999750141, -8.495833326746606],
              [-40.034999999750141, -8.499166658514412],
              [-40.035833331343099, -8.499166658514412],
              [-40.035833332242419, -8.499999992805385],
              [-40.034999999750141, -8.499999992805385],
              [-40.034999999750141, -8.503333325472568],
              [-40.034166666358544, -8.503333326371887],
              [-40.034166667257864, -8.504999993155138],
              [-40.034999999750141, -8.504999993155138],
              [-40.034999999750141, -8.505833325647416],
              [-40.034999999750141, -8.509166659213918],
              [-40.035833332242419, -8.509166657415278],
              [-40.035833332242419, -8.510833325997169],
              [-40.036666666533392, -8.510833325997169],
              [-40.036666666533392, -8.511666658489446],
              [-40.035833332242419, -8.511666658489446],
              [-40.035833332242419, -8.51249999278042],
              [-40.034999999750141, -8.5124999918811],
              [-40.034999999750141, -8.513333326172017],
              [-40.034166666358544, -8.513333326172017],
              [-40.034166665459225, -8.516666658839199],
              [-40.032499999575293, -8.516666658839199],
              [-40.032499999575293, -8.517499994029436],
              [-40.031666667082959, -8.517499993130116],
              [-40.031666667082959, -8.51833332562245],
              [-40.030833332792042, -8.51833332562245],
              [-40.030833332792042, -8.519166659014047],
              [-40.029166666908111, -8.519166659913367],
              [-40.029166666908111, -8.522499993479869],
              [-40.029999999400388, -8.522499992580549],
              [-40.029999999400388, -8.523333325972146],
              [-40.029166666908111, -8.523333325972146],
              [-40.029166666008791, -8.524999992755397],
              [-40.029999999400388, -8.524999992755397],
              [-40.029999999400388, -8.527499993829622],
              [-40.02749999922554, -8.527499992930302],
              [-40.02750000102418, -8.526666657740009],
              [-40.024166667457678, -8.526666658639328],
              [-40.024166666558358, -8.527499992030982],
              [-40.02166666638351, -8.527499992930302],
              [-40.02166666548419, -8.528333325422579],
              [-40.020833330293897, -8.528333325422579],
              [-40.020833332991856, -8.529166658814233],
              [-40.018333331917688, -8.529166658814233],
              [-40.018333332817008, -8.531666658989081],
              [-40.016666666933077, -8.531666658989081],
              [-40.016666667832396, -8.533333325772333],
              [-40.014166667657548, -8.533333325772333],
              [-40.014166665858909, -8.53416665916393],
              [-40.013333332467255, -8.534166660063249],
              [-40.013333331567935, -8.534999992555584],
              [-40.014166665858909, -8.534999992555584],
              [-40.014166666758229, -8.536666658439515],
              [-40.014999999250506, -8.536666658439515],
              [-40.014999998351186, -8.538333326122029],
              [-40.01583333264216, -8.538333326122029],
              [-40.01583333354148, -8.539166658614363],
              [-40.01583333174284, -8.539999993804599],
              [-40.017499999425411, -8.53999999290528],
              [-40.017499998526091, -8.540833327196253],
              [-40.019166665309342, -8.540833325397614],
              [-40.019166665309342, -8.541666658789211],
              [-40.019166666208662, -8.543333324673142],
              [-40.018333332817008, -8.543333325572462],
              [-40.018333332817008, -8.544166658964116],
              [-40.017499999425411, -8.544166658964116],
              [-40.017499998526091, -8.545833325747367],
              [-40.016666664235117, -8.545833325747367],
              [-40.016666666933077, -8.547499992530561],
              [-40.01583333264216, -8.547499992530561],
              [-40.01583333354148, -8.548333325922215],
              [-40.01583333264216, -8.549999992705466],
              [-40.016666666033757, -8.549999992705466],
              [-40.016666666933077, -8.555833325547496],
              [-40.017499998526091, -8.555833325547496],
              [-40.017500000324731, -8.563333326072097],
              [-40.017499999425411, -8.564166658564375],
              [-40.019166666208662, -8.564166658564375],
              [-40.019166666208662, -8.564999992855348],
              [-40.020833332991856, -8.564999992855348],
              [-40.020833332991856, -8.565833325347626],
              [-40.023333331368121, -8.565833325347626],
              [-40.023333331368121, -8.56666665873928],
              [-40.024999999950012, -8.56666665783996],
              [-40.024999999050692, -8.567499993030196],
              [-40.025669247537678, -8.567499993929516],
              [-40.026666666733263, -8.567499993030196],
              [-40.026666666733263, -8.568333325522531],
              [-40.029999999400388, -8.568333325522531],
              [-40.029999999400388, -8.567499993030196],
              [-40.030833332792042, -8.567499993030196],
              [-40.030833332792042, -8.568333325522531],
              [-40.03166666528432, -8.568333325522531],
              [-40.031666667082959, -8.569166658914128],
              [-40.032499999575293, -8.569166658014808],
              [-40.032499998675974, -8.570833325697379],
              [-40.03333333296689, -8.570833325697379],
              [-40.03333333296689, -8.571666659088976],
              [-40.034999999750141, -8.571666658189656],
              [-40.034999999750141, -8.57249999248063],
              [-40.035833332242419, -8.57249999248063],
              [-40.035833332242419, -8.574166658364561],
              [-40.034166666358544, -8.574166657465241],
              [-40.034166666358544, -8.574999992655478],
              [-40.032499999575293, -8.574999992655478],
              [-40.032499999575293, -8.575833326946452],
              [-40.03180290537972, -8.575833326946452],
              [-40.030833332792042, -8.575833325147812],
              [-40.030833332792042, -8.576666658539409],
              [-40.029999999400388, -8.576666658539409],
              [-40.029999999400388, -8.577499993729703],
              [-40.029166666908111, -8.577499992830383],
              [-40.029166666908111, -8.57833332442334],
              [-40.028333332617137, -8.57833332622198],
              [-40.028333332617137, -8.579166658714257],
              [-40.02749999922554, -8.579166658714257],
              [-40.02749999922554, -8.579999993904551],
              [-40.025833332442289, -8.579999992105911],
              [-40.025833332442289, -8.580833325497508],
              [-40.023333332267441, -8.580833325497508],
              [-40.023333332267441, -8.579166658714257],
              [-40.02166666638351, -8.579166659613577],
              [-40.02166666638351, -8.579999993005231],
              [-40.020833332991856, -8.579999993005231],
              [-40.020833332991856, -8.580833325497508],
              [-40.019166666208662, -8.580833326396828],
              [-40.019166666208662, -8.579999993005231],
              [-40.014999999250506, -8.579999993005231],
              [-40.014999999250506, -8.580833325497508],
              [-40.014166665858909, -8.580833325497508],
              [-40.014166666758229, -8.581666657989842],
              [-40.013333331567935, -8.581666657989842],
              [-40.013333332467255, -8.582499993180079],
              [-40.009999999800129, -8.582499991381439],
              [-40.009999999800129, -8.583333324773093],
              [-40.006666665334308, -8.583333325672413],
              [-40.006666666233627, -8.584999992455664],
              [-40.004166667857419, -8.584999992455664],
              [-40.004166667857419, -8.585833325847261],
              [-40.003333332667125, -8.585833324947941],
              [-40.003333331767806, -8.587499992630512],
              [-40.002499999275528, -8.587499992630512],
              [-40.002499998376209, -8.589166658514443],
              [-40.001666666783194, -8.589166658514443],
              [-40.001666666783194, -8.58999999280536],
              [-40.000833332492277, -8.58999999280536],
              [-40.000833331592958, -8.590833326197014],
              [-39.997499998925775, -8.590833326197014],
              [-39.997499999825095, -8.591666659588611],
              [-39.998333332317372, -8.591666658689292],
              [-39.998333331418053, -8.592499992980265],
              [-39.995833333041844, -8.592499992980265],
              [-39.995833333041844, -8.593333325472543],
              [-39.994999999650247, -8.593333326371862],
              [-39.99499999875087, -8.596666659039045],
              [-39.996666666433441, -8.596666659039045],
              [-39.996666666433441, -8.602499992780395],
              [-39.995833333041844, -8.602499992780395],
              [-39.995833333041844, -8.604166658664326],
              [-39.99499999875087, -8.604166658664326],
              [-39.99499999875087, -8.607499993130148],
              [-39.994166668057233, -8.607499993130148],
              [-39.994166665359273, -8.611666659188927],
              [-39.994166668057233, -8.612499992580524],
              [-39.99499999875087, -8.612499992580524],
              [-39.995833333041844, -8.612499992580524],
              [-39.995833333041844, -8.613333325972178],
              [-39.996666666433441, -8.613333325972178],
              [-39.996666666433441, -8.617499992930277],
              [-39.997499999825095, -8.617499992930277],
              [-39.997499998026456, -8.618333325422611],
              [-39.999166666608346, -8.618333325422611],
              [-39.999166666608346, -8.619166658814208],
              [-39.999999999100623, -8.619166658814208],
              [-40.0, -8.618333325422611],
              [-40.000833332492277, -8.618333324523235],
              [-40.000833332492277, -8.619166658814208],
              [-40.002499999275528, -8.619166658814208],
              [-40.002499999275528, -8.61666665863936],
              [-40.004166666958099, -8.61666665863936],
              [-40.004166666058723, -8.615833326147026],
              [-40.005833331942654, -8.615833326147026],
              [-40.005833333741293, -8.614166658464455],
              [-40.006666666233627, -8.614166658464455],
              [-40.006666666233627, -8.612499992580524],
              [-40.007499998725905, -8.612499992580524],
              [-40.007499998725905, -8.611666657390288],
              [-40.008333331218239, -8.611666660088247],
              [-40.008333333016878, -8.610833325797273],
              [-40.014166665858909, -8.610833325797273],
              [-40.014166664959589, -8.611666659188927],
              [-40.01583333354148, -8.611666659188927],
              [-40.01583333264216, -8.612499992580524],
              [-40.016666666033757, -8.612499992580524],
              [-40.017499998526091, -8.612499992580524],
              [-40.017499998526091, -8.610833325797273],
              [-40.018333331917688, -8.610833325797273],
              [-40.018333331917688, -8.609999992405676],
              [-40.019166665309342, -8.609999992405676],
              [-40.019166666208662, -8.604999992055923],
              [-40.020833332991856, -8.604999992955243],
              [-40.020833332991856, -8.604166658664326],
              [-40.024166666558358, -8.604166659563646],
              [-40.024166666558358, -8.602499992780395],
              [-40.024999999950012, -8.602499992780395],
              [-40.024999999950012, -8.599999992605547],
              [-40.027499998326221, -8.599999992605547],
              [-40.02750000102418, -8.599166659213893],
              [-40.030833331892723, -8.599166659213893],
              [-40.030833332792042, -8.598333325822296],
              [-40.03166666528432, -8.598333325822296],
              [-40.031666667082959, -8.599999992605547],
              [-40.030833332792042, -8.599999992605547],
              [-40.030833332792042, -8.600833325097824],
              [-40.031666667082959, -8.600833325097824],
              [-40.031666667082959, -8.603333324373352],
              [-40.032499998675974, -8.603333324373352],
              [-40.032500000474613, -8.605833324548257],
              [-40.031666667082959, -8.605833325447577],
              [-40.031666667082959, -8.607499992230828],
              [-40.032499998675974, -8.607499992230828],
              [-40.032499999575293, -8.609166658114759],
              [-40.03333333296689, -8.609166659014079],
              [-40.033333332067571, -8.611666657390288],
              [-40.034166665459225, -8.611666659188927],
              [-40.034166666358544, -8.613333325072858],
              [-40.034999998850822, -8.613333325072858],
              [-40.034999998850822, -8.614166658464455],
              [-40.035833332242419, -8.614166657565136],
              [-40.035833331343099, -8.617499991131638],
              [-40.036666666533392, -8.617499992930277],
              [-40.036666666533392, -8.620833325597459],
              [-40.039166665808921, -8.620833325597459],
              [-40.039166665808921, -8.621666658989056],
              [-40.040000000099894, -8.621666658089737],
              [-40.039999998301255, -8.622499991481391],
              [-40.040833331692852, -8.62249999238071],
              [-40.040833332592172, -8.623333325772307],
              [-40.042499999375423, -8.623333324872988],
              [-40.042499999375423, -8.624166657365322],
              [-40.044999999550271, -8.624166660063281],
              [-40.044999998650951, -8.624999991656239],
              [-40.045833332941925, -8.624999992555558],
              [-40.045833333841244, -8.625833325047893],
              [-40.049166666508427, -8.625833325947212],
              [-40.049166665609107, -8.62833332612206],
              [-40.048333331318133, -8.628333325222741],
              [-40.048333332217453, -8.629166658614338],
              [-40.047499998825856, -8.629166657715018],
              [-40.047499999725176, -8.629999992005992],
              [-40.044999999550271, -8.629999992905312],
              [-40.045000000449591, -8.63249999308016],
              [-40.044166667057993, -8.63249999308016],
              [-40.044166667057993, -8.637499992530593],
              [-40.043333331867757, -8.637499992530593],
              [-40.043333332767077, -8.63833332592219],
              [-40.044166665259354, -8.63833332502287],
              [-40.044166667957313, -8.639166658414524],
              [-40.044999998650951, -8.639166658414524],
              [-40.044999999550271, -8.640833326097095],
              [-40.044999999550271, -8.641666658589372],
              [-40.044999999550271, -8.642499992880346],
              [-40.044166667057993, -8.642499992880346],
              [-40.044166665259354, -8.643333325372623],
              [-40.043333332767077, -8.643333325372623],
              [-40.043333331867757, -8.64416665876422],
              [-40.042499998476103, -8.64416665876422],
              [-40.042499998476103, -8.644999993055194],
              [-40.042499999375423, -8.646666658939125],
              [-40.043333331867757, -8.646666658939125],
              [-40.043333331867757, -8.647499991431403],
              [-40.044166667057993, -8.647499991431403],
              [-40.044166665259354, -8.651666658389502],
              [-40.045833332042605, -8.651666658389502],
              [-40.045833332941925, -8.652499991781156],
              [-40.046666664534882, -8.652499992680475],
              [-40.046666666333522, -8.653333325172753],
              [-40.049166665609107, -8.653333326072072],
              [-40.049166666508427, -8.654166658564407],
              [-40.049999999000704, -8.654166658564407],
              [-40.049999999900024, -8.653333326072072],
              [-40.055833331842734, -8.653333326072072],
              [-40.055833332742054, -8.654166658564407],
              [-40.057499999525305, -8.654166658564407],
              [-40.057499998625985, -8.653333325172753],
              [-40.059166665409236, -8.653333326072072],
              [-40.059166665409236, -8.652499992680475],
              [-40.062499998975738, -8.652499992680475],
              [-40.062499998975738, -8.651666658389502],
              [-40.063333332367336, -8.651666658389502],
              [-40.063333332367336, -8.652499992680475],
              [-40.064166667557629, -8.652499992680475],
              [-40.064166666658309, -8.653333326072072],
              [-40.065833331642921, -8.653333325172753],
              [-40.065833331642921, -8.654166658564407],
              [-40.070000000399659, -8.654166659463726],
              [-40.06999999950034, -8.656666658739255],
              [-40.070833333791256, -8.656666658739255],
              [-40.070833331992617, -8.657499993030228],
              [-40.071666666283591, -8.657499993030228],
              [-40.071666665384271, -8.659999994104396],
              [-40.074166665559119, -8.659999993205076],
              [-40.074166666458439, -8.660833325697354],
              [-40.074999999850093, -8.660833325697354],
              [-40.074999998950773, -8.662499993379924],
              [-40.076666665733967, -8.662499992480605],
              [-40.076666665733967, -8.663333325872259],
              [-40.077499998226301, -8.663333325872259],
              [-40.077500000024941, -8.664166658364536],
              [-40.079999998401149, -8.664166658364536],
              [-40.079999999300469, -8.667499992830358],
              [-40.080833333591443, -8.667499992830358],
              [-40.080833331792803, -8.668333326221955],
              [-40.082499999475374, -8.668333324423315],
              [-40.082500000374694, -8.669166658714289],
              [-40.083333331967651, -8.669166658714289],
              [-40.083333332866971, -8.669999993005206],
              [-40.086666665534153, -8.669999993005206],
              [-40.086666666433473, -8.67083332549754],
              [-40.088333332317404, -8.67083332549754],
              [-40.088333331418085, -8.669999993005206],
              [-40.09, -8.669999993904526],
              [-40.090833333391572, -8.669999993005206],
              [-40.090833332492252, -8.669166658714289],
              [-40.094999999450351, -8.669166658714289],
              [-40.094999999450351, -8.668333326221955],
              [-40.095833333741325, -8.668333326221955],
              [-40.095833331942686, -8.66499999265551],
              [-40.096666258840742, -8.66499999265551],
              [-40.096666666233602, -8.66499999265551],
              [-40.096666666233602, -8.663333325872259],
              [-40.098333333016853, -8.663333325872259],
              [-40.098333333916173, -8.659166658914103],
              [-40.103333332467287, -8.659166658914103],
              [-40.103333332467287, -8.657499992130909],
              [-40.104166666758204, -8.657499993929548],
              [-40.104166666758204, -8.654999992855323],
              [-40.103333332467287, -8.654999992855323],
              [-40.103333332467287, -8.653333326072072],
              [-40.102499999075633, -8.653333326072072],
              [-40.102500000874272, -8.652499991781156],
              [-40.101666666583355, -8.652499992680475],
              [-40.101666666583355, -8.649999992505627],
              [-40.100833332292382, -8.649999992505627],
              [-40.100833332292382, -8.649166659113973],
              [-40.098333333916173, -8.649166659113973],
              [-40.098333333016853, -8.647499993230042],
              [-40.097499999625256, -8.647499993230042],
              [-40.097499999625256, -8.643333325372623],
              [-40.096666666233602, -8.643333327171263],
              [-40.096666668032242, -8.641666658589372],
              [-40.095833332842005, -8.641666658589372],
              [-40.095833332842005, -8.640833325197775],
              [-40.094999998551032, -8.640833326097095],
              [-40.094999998551032, -8.641666659488692],
              [-40.092500001074143, -8.641666658589372],
              [-40.092499999275503, -8.642499992880346],
              [-40.090833332492252, -8.642499992880346],
              [-40.090833332492252, -8.639999992705441],
              [-40.086666666433473, -8.639999992705441],
              [-40.086666666433473, -8.639166658414524],
              [-40.08583333124318, -8.639166658414524],
              [-40.085833333041819, -8.63833332592219],
              [-40.084999999650222, -8.63833332592219],
              [-40.084999999650222, -8.637499992530593],
              [-40.084166666258625, -8.637499993429913],
              [-40.084166666258625, -8.636666659138939],
              [-40.082499999475374, -8.636666659138939],
              [-40.082499999475374, -8.631666658789243],
              [-40.083333332866971, -8.631666658789243],
              [-40.084999999650222, -8.631666658789243],
              [-40.084999998750902, -8.630833325397589],
              [-40.089166666608321, -8.630833324498269],
              [-40.089166666608321, -8.629999992905312],
              [-40.09, -8.629999992905312],
              [-40.09, -8.629166658614338],
              [-40.0933333326671, -8.629166659513658],
              [-40.093333331767781, -8.62833332612206],
              [-40.094166666958074, -8.62833332612206],
              [-40.094166666958074, -8.627499992730407],
              [-40.097499999625256, -8.627499992730407],
              [-40.097499999625256, -8.62666665843949],
              [-40.099166667307827, -8.626666660238129],
              [-40.099166666408507, -8.625833325947212],
              [-40.099999999800104, -8.625833325947212],
              [-40.099999999800104, -8.624166657365322],
              [-40.100833332292382, -8.624166659163961],
              [-40.100833332292382, -8.623333326671627],
              [-40.099999999800104, -8.623333325772307],
              [-40.099999999800104, -8.621666658989056],
              [-40.098333333016853, -8.621666659888376],
              [-40.098333333016853, -8.620833325597459],
              [-40.097499999625256, -8.620833325597459],
              [-40.097499998725937, -8.619999993105125],
              [-40.098333333016853, -8.619999993105125],
              [-40.098333333016853, -8.619166657914889],
              [-40.099166666408507, -8.619166657914889],
              [-40.099166666408507, -8.615871775761775],
              [-40.099166666408507, -8.610833325797273],
              [-40.099999998900785, -8.610833325797273],
              [-40.099999999800104, -8.609999992405676],
              [-40.100833331393062, -8.609999992405676],
              [-40.100833331393062, -8.609166658114759],
              [-40.101666666583355, -8.609166659913399],
              [-40.101666665684036, -8.608333325622425],
              [-40.102499999974953, -8.608333325622425],
              [-40.102499999974953, -8.607499993130148],
              [-40.103333332467287, -8.607499993130148],
              [-40.103333332467287, -8.606666658839174],
              [-40.103333331567967, -8.606094161216504],
              [-40.103333331567967, -8.605833325447577],
              [-40.104999999250538, -8.605833325447577],
              [-40.104999999250538, -8.604999992955243],
              [-40.105833332642135, -8.604999992055923],
              [-40.105833332642135, -8.603333326171992],
              [-40.107499998526066, -8.603333326171992],
              [-40.107499999425386, -8.602499992780395],
              [-40.108333331917663, -8.602499993679714],
              [-40.10833333281704, -8.599999992605547],
              [-40.109166666208637, -8.599999991706227],
              [-40.109166666208637, -8.601666658489478],
              [-40.109999999600234, -8.601666658489478],
              [-40.109999999600234, -8.602499992780395],
              [-40.110833333891208, -8.602499992780395],
              [-40.110833332991888, -8.603333327071368],
              [-40.111666666383485, -8.603333326171992],
              [-40.111666666383485, -8.604166658664326],
              [-40.112736934262045, -8.604166658664326],
              [-40.113333332267416, -8.604166658664326],
              [-40.113333332267416, -8.604999992955243],
              [-40.11416666655839, -8.604999992955243],
              [-40.11416666745771, -8.612499992580524],
              [-40.114999999949987, -8.612499991681204],
              [-40.114999999949987, -8.613333325972178],
              [-40.116666666733238, -8.613333325972178],
              [-40.116666666733238, -8.614166658464455],
              [-40.120833332792017, -8.614166658464455],
              [-40.120833332792017, -8.614999992755429],
              [-40.124999999750173, -8.614999992755429],
              [-40.124999999750173, -8.615833326147026],
              [-40.130000000099869, -8.615833326147026],
              [-40.130000000099869, -8.614999992755429],
              [-40.132499998476078, -8.614999992755429],
              [-40.132499999375455, -8.614166657565136],
              [-40.133333332767052, -8.614166658464455],
              [-40.133333332767052, -8.612499992580524],
              [-40.1358333329419, -8.612499991681204],
              [-40.1358333329419, -8.611666660088247],
              [-40.136666667232873, -8.611666659188927],
              [-40.136666666333554, -8.610833325797273],
              [-40.139999999900056, -8.610833325797273],
              [-40.140000000799375, -8.611666657390288],
              [-40.140833331493013, -8.611666659188927],
              [-40.140833333291653, -8.609166659014079],
              [-40.141666667582626, -8.609166658114759],
              [-40.141666667582626, -8.607499993130148],
              [-40.142499998276264, -8.607499993130148],
              [-40.142500000074904, -8.606666657939854],
              [-40.14166666668325, -8.606666658839174],
              [-40.14166666668325, -8.605833325447577],
              [-40.140833331493013, -8.605833325447577],
              [-40.140833331493013, -8.604999992955243],
              [-40.139999999000736, -8.604999992955243],
              [-40.139999999000736, -8.604166657765006],
              [-40.140833331493013, -8.604166658664326],
              [-40.140833331493013, -8.603333327071368],
              [-40.141666667582626, -8.603333326171992],
              [-40.14166666668325, -8.601666658489478],
              [-40.140833331493013, -8.601666658489478],
              [-40.140833331493013, -8.600833325097824],
              [-40.139999999900056, -8.600833325997144],
              [-40.139999999000736, -8.599166659213893],
              [-40.139166665609082, -8.599166659213893],
              [-40.139166666508402, -8.597499992430642],
              [-40.140833331493013, -8.597499991531322],
              [-40.140833331493013, -8.596666658139725],
              [-40.14166666668325, -8.596666659039045],
              [-40.14166666668325, -8.595833325647391],
              [-40.143333332567181, -8.595833325647391],
              [-40.143333333466501, -8.594999993155113],
              [-40.144166666858155, -8.594999993155113],
              [-40.144166665958835, -8.593333325472543],
              [-40.145000000249752, -8.593333325472543],
              [-40.144999999350432, -8.592499992980265],
              [-40.150833333091782, -8.592499992980265],
              [-40.150833333091782, -8.591666658689292],
              [-40.153333331468048, -8.591666658689292],
              [-40.153333331468048, -8.590833326197014],
              [-40.158333332717064, -8.590833326197014],
              [-40.158333331817744, -8.589999991906041],
              [-40.159999998600995, -8.58999999280536],
              [-40.159999999500315, -8.589166658514443],
              [-40.161666666283566, -8.589166657615124],
              [-40.161666666283566, -8.58999999280536],
              [-40.16416666645847, -8.58999999370468],
              [-40.16416666645847, -8.589166658514443],
              [-40.167499998226276, -8.589166657615124],
              [-40.167499998226276, -8.588333326022109],
              [-40.16833333161793, -8.588333326022109],
              [-40.16833333161793, -8.587499991731192],
              [-40.169166665908847, -8.587499991731192],
              [-40.169166665908847, -8.588333326022109],
              [-40.170833332692098, -8.588333326022109],
              [-40.170833332692098, -8.589166658514443],
              [-40.171666666083752, -8.589166657615124],
              [-40.171666666983072, -8.58999999280536],
              [-40.173333331068307, -8.58999999280536],
              [-40.173333332867003, -8.591666658689292],
              [-40.174166668057239, -8.591666658689292],
              [-40.17416666535928, -8.592499992980265],
              [-40.17833333141806, -8.592499992980265],
              [-40.180833331592908, -8.592499995678224],
              [-40.180833332492284, -8.585833325847261],
              [-40.17999999910063, -8.585833325847261],
              [-40.18000000089927, -8.582499994079399],
              [-40.179166666608353, -8.582499991381439],
              [-40.179166665709033, -8.581666658889162],
              [-40.177499998925782, -8.581666658889162],
              [-40.177500000724422, -8.579999993005231],
              [-40.176666666433448, -8.579999993005231],
              [-40.176666666433448, -8.579166658714257],
              [-40.175833333041851, -8.579166658714257],
              [-40.175833331243211, -8.577499992830383],
              [-40.174999998750877, -8.577499992830383],
              [-40.174999999650197, -8.576666658539409],
              [-40.171666666083752, -8.576666658539409],
              [-40.171666666983072, -8.575833326047132],
              [-40.170833332692098, -8.575833326946452],
              [-40.170833332692098, -8.574999992655478],
              [-40.166666666633319, -8.574999992655478],
              [-40.166666666633319, -8.574166658364561],
              [-40.165833332342345, -8.574166658364561],
              [-40.165833332342345, -8.573333325872227],
              [-40.164999999850068, -8.573333325872227],
              [-40.164999999850068, -8.57249999248063],
              [-40.16416666645847, -8.57249999248063],
              [-40.16416666645847, -8.569999993205101],
              [-40.163333333066817, -8.569999993205101],
              [-40.163333333066817, -8.568333325522531],
              [-40.164166665559151, -8.568333324623211],
              [-40.16416666645847, -8.565833327146265],
              [-40.164999999850068, -8.565833325347626],
              [-40.164999998950748, -8.564999992855348],
              [-40.165833330543705, -8.564999991956029],
              [-40.165833331443025, -8.561666658389527],
              [-40.166666666633319, -8.561666658389527],
              [-40.166666666633319, -8.560833325897249],
              [-40.167500000924235, -8.560833325897249],
              [-40.167499998226276, -8.559999992505595],
              [-40.16833333341657, -8.559999992505595],
              [-40.16833333341657, -8.559166659113998],
              [-40.169166666808167, -8.559166659113998],
              [-40.169166667707486, -8.558333325722344],
              [-40.169999999300501, -8.558333325722344],
              [-40.169999998401181, -8.557499991431428],
              [-40.171666666983072, -8.557499993230067],
              [-40.171666666983072, -8.558333325722344],
              [-40.172500000374669, -8.558333325722344],
              [-40.172499999475349, -8.559166659113998],
              [-40.17416666535928, -8.559166659113998],
              [-40.1741666662586, -8.557499993230067],
              [-40.174999999650197, -8.557499993230067],
              [-40.174999998750877, -8.556666658939093],
              [-40.176666666433448, -8.556666659838413],
              [-40.176666666433448, -8.555833325547496],
              [-40.1741666662586, -8.555833325547496],
              [-40.1741666662586, -8.554999993055162],
              [-40.172499999475349, -8.554999993055162],
              [-40.172499999475349, -8.554166658764245],
              [-40.170833333591418, -8.554166658764245],
              [-40.170833332692098, -8.553333324473272],
              [-40.169166667707486, -8.553333325372648],
              [-40.169166666808167, -8.554166659663565],
              [-40.165833331443025, -8.554166658764245],
              [-40.165833332342345, -8.553333325372648],
              [-40.164999999850068, -8.553333324473272],
              [-40.164999999850068, -8.552499992880314],
              [-40.16416666645847, -8.552499992880314],
              [-40.16416666645847, -8.551666659488717],
              [-40.162499999675219, -8.551666657690077],
              [-40.162499999675219, -8.552499992880314],
              [-40.160833332891968, -8.552499992880314],
              [-40.160833331992649, -8.551666658589397],
              [-40.159999999500315, -8.551666658589397],
              [-40.159999999500315, -8.549999992705466],
              [-40.159999999500315, -8.545833325747367],
              [-40.160833332891968, -8.545833325747367],
              [-40.160833332891968, -8.546666659138964],
              [-40.161666666283566, -8.546666659138964],
              [-40.161666666283566, -8.545833325747367],
              [-40.162499999675219, -8.545833326646687],
              [-40.162499999675219, -8.546666659138964],
              [-40.165833332342345, -8.546666659138964],
              [-40.165833332342345, -8.545833325747367],
              [-40.165833332342345, -8.543333325572462],
              [-40.166666666633319, -8.543333325572462],
              [-40.166666666633319, -8.540833325397614],
              [-40.167500000024916, -8.540833325397614],
              [-40.167500000024916, -8.539166657715043],
              [-40.16833333251725, -8.539166659513683],
              [-40.16833333341657, -8.538333326122029],
              [-40.169166666808167, -8.538333326122029],
              [-40.169166666808167, -8.537499992730432],
              [-40.172499998576029, -8.537499992730432],
              [-40.172499999475349, -8.538333326122029],
              [-40.173333332867003, -8.538333327021405],
              [-40.173333332867003, -8.539166659513683],
              [-40.18, -8.539166658614363],
              [-40.18, -8.537499992730432],
              [-40.180833332492284, -8.537499992730432],
              [-40.180833331592908, -8.534999992555584],
              [-40.181666665883881, -8.534999991656264],
              [-40.181666666783201, -8.533333326671652],
              [-40.184999998551064, -8.533333324873013],
              [-40.184999999450383, -8.532499992380679],
              [-40.188333333016885, -8.532499992380679],
              [-40.188333331218246, -8.533333325772333],
              [-40.189166666408482, -8.533333325772333],
              [-40.189166666408482, -8.53416665916393],
              [-40.190833331393094, -8.53416665916393],
              [-40.190833331393094, -8.533333325772333],
              [-40.191666665684011, -8.533333326671652],
              [-40.19166666658333, -8.53416665916393],
              [-40.192499999974984, -8.534166660063249],
              [-40.192500000874304, -8.534999992555584],
              [-40.194166665858916, -8.534999992555584],
              [-40.194166666758235, -8.535833325947181],
              [-40.195833331742847, -8.535833325947181],
              [-40.195833332642167, -8.536666660238154],
              [-40.198333331917695, -8.536666658439515],
              [-40.198333332817015, -8.535833325947181],
              [-40.199166665309292, -8.535833325047861],
              [-40.199166665309292, -8.534999992555584],
              [-40.199999998700946, -8.534999992555584],
              [-40.199999998700946, -8.53416665916393],
              [-40.202500000674434, -8.53416665916393],
              [-40.202499999775114, -8.533333325772333],
              [-40.204166666558365, -8.533333325772333],
              [-40.204166665659045, -8.532499991481359],
              [-40.203333334066087, -8.532499992380679],
              [-40.203333332267448, -8.530833325597428],
              [-40.202499999775114, -8.530833324698108],
              [-40.202499998875794, -8.52999999400447],
              [-40.201666666383517, -8.52999999220583],
              [-40.201666666383517, -8.528333325422579],
              [-40.200833332991863, -8.528333325422579],
              [-40.200833330293904, -8.527499992930302],
              [-40.199999998700946, -8.527499992930302],
              [-40.199999999600266, -8.526666658639328],
              [-40.199166665309292, -8.526666658639328],
              [-40.199166665309292, -8.524999992755397],
              [-40.198333331917695, -8.524999992755397],
              [-40.198333332817015, -8.523333325072826],
              [-40.196666666933083, -8.523333325972146],
              [-40.196666667832403, -8.521666660088272],
              [-40.197499998526041, -8.521666659188952],
              [-40.197499999425418, -8.520833325797298],
              [-40.196666666933083, -8.520833325797298],
              [-40.196666666933083, -8.51833332472313],
              [-40.197499998526041, -8.51833332562245],
              [-40.197499999425418, -8.517499993130116],
              [-40.196666666933083, -8.517499994029436],
              [-40.196666666933083, -8.514999992955268],
              [-40.195833331742847, -8.514999992955268],
              [-40.195833332642167, -8.51249999278042],
              [-40.194999998351193, -8.51249999278042],
              [-40.194999999250513, -8.509999992605515],
              [-40.195833331742847, -8.509999992605515],
              [-40.195833332642167, -8.506666659039013],
              [-40.196666666933083, -8.506666659039013],
              [-40.196666666033764, -8.505833325647416],
              [-40.197499999425418, -8.505833325647416],
              [-40.197499999425418, -8.503333325472568],
              [-40.196666666033764, -8.503333325472568],
              [-40.196666666933083, -8.502499992080914],
              [-40.197499998526041, -8.502499992980233],
              [-40.197499999425418, -8.500833326196982],
              [-40.198333332817015, -8.500833326196982],
              [-40.198333331018375, -8.499999992805385],
              [-40.199166665309292, -8.499999992805385],
              [-40.199166666208612, -8.499166658514412],
              [-40.199999998700946, -8.499166658514412],
              [-40.199999999600266, -8.498333326022134],
              [-40.200833332092543, -8.498333326022134],
              [-40.200833332991863, -8.496666659238883],
              [-40.200000000499585, -8.496666659238883],
              [-40.199999999600266, -8.495833325847286],
              [-40.199166665309292, -8.495833325847286],
              [-40.199166668007308, -8.492499993180104],
              [-40.198333332817015, -8.492499993180104],
              [-40.198333332817015, -8.489999993005199],
              [-40.199166665309292, -8.489999992105879],
              [-40.199166665309292, -8.488333327121325],
              [-40.198333332817015, -8.488333326222005],
              [-40.198333333716334, -8.487499992830351],
              [-40.197499999425418, -8.487499992830351],
              [-40.197499998526041, -8.486666658539434],
              [-40.196666667832403, -8.486666658539434],
              [-40.196666666933083, -8.487499991931031],
              [-40.18999999890076, -8.487499992830351],
              [-40.189999999800079, -8.4858333260471],
              [-40.189166666408482, -8.4858333260471],
              [-40.189166666408482, -8.482499992480598],
              [-40.188333333016885, -8.482499992480598],
              [-40.188333331218246, -8.479999994104389],
              [-40.187499999625231, -8.47999999140643],
              [-40.187499999625231, -8.479166658914153],
              [-40.183333332667132, -8.479166658914153],
              [-40.183333332667132, -8.476666658739248],
              [-40.179166666608353, -8.476666658739248],
              [-40.179166666608353, -8.475833325347651],
              [-40.177499999825102, -8.475833325347651],
              [-40.177499999825102, -8.476666658739248],
              [-40.175833333041851, -8.476666658739248],
              [-40.175833333041851, -8.474999993754636],
              [-40.174999999650197, -8.474999992855317],
              [-40.174999999650197, -8.4741666585644],
              [-40.173333332867003, -8.47416665766508],
              [-40.173333332867003, -8.473333326072066],
              [-40.172500000374669, -8.473333326072066],
              [-40.172499999475349, -8.471776681448659],
              [-40.172499999475349, -8.471666658389552],
              [-40.171666666083752, -8.471666658389552],
              [-40.171666667882391, -8.470833325897217],
              [-40.170833332692098, -8.470833325897217],
              [-40.170833332692098, -8.468333325722369],
              [-40.169999999300501, -8.468333325722369],
              [-40.169999999300501, -8.467499993230035],
              [-40.17000000109914, -8.466666658939118],
              [-40.170833333591418, -8.466666658939118],
              [-40.170833333591418, -8.465833325547464],
              [-40.174166668057239, -8.465833326446784],
              [-40.1741666662586, -8.464999993055187],
              [-40.175506340445224, -8.464999993055187],
              [-40.175833331243211, -8.464999993055187],
              [-40.175833331243211, -8.46416665876427],
              [-40.177499999825102, -8.46416665966359],
              [-40.177499999825102, -8.463333325372616],
              [-40.178333332317379, -8.463333325372616],
              [-40.178333332317379, -8.462499992880339],
              [-40.180833332492284, -8.462499992880339],
              [-40.180833332492284, -8.464999993055187],
              [-40.181666665883881, -8.464999993055187],
              [-40.181666667682521, -8.46416665876427],
              [-40.185000000349703, -8.46416665876427],
              [-40.184999999450383, -8.463333325372616],
              [-40.185833331942661, -8.463333324473297],
              [-40.1858333337413, -8.462499992880339],
              [-40.189999999800079, -8.462499991981019],
              [-40.189999999800079, -8.461666659488685],
              [-40.190833332292414, -8.461666658589365],
              [-40.190833332292414, -8.457499992530586],
              [-40.19166666658333, -8.457499992530586],
              [-40.19166666658333, -8.456666659138989],
              [-40.192500000874304, -8.456666659138989],
              [-40.192499999974984, -8.455833325747335],
              [-40.193333331567942, -8.455833325747335],
              [-40.193333333366581, -8.454999992355738],
              [-40.192499999974984, -8.454999992355738],
              [-40.192499999075665, -8.454166658964084],
              [-40.189999999800079, -8.454166658964084],
              [-40.189999999800079, -8.453333325572487],
              [-40.189166665509163, -8.453333325572487],
              [-40.189166666408482, -8.451666658789236],
              [-40.188333333016885, -8.451666658789236],
              [-40.188333333016885, -8.450833325397582],
              [-40.187499999625231, -8.450833325397582],
              [-40.187499998725912, -8.449999992905305],
              [-40.185833331942661, -8.449999992905305],
              [-40.185833331942661, -8.448333326122054],
              [-40.185000000349703, -8.448333326122054],
              [-40.184999999450383, -8.447499991831137],
              [-40.184166666958049, -8.447499992730457],
              [-40.184166666958049, -8.446666658439483],
              [-40.183333332667132, -8.446666657540163],
              [-40.183333332667132, -8.444999993454871],
              [-40.181666665883881, -8.444999992555552],
              [-40.181666664984562, -8.444166659163955],
              [-40.18, -8.444166659163955],
              [-40.18, -8.443333325772301],
              [-40.175833333041851, -8.44333332667162],
              [-40.175833333041851, -8.442499992380704],
              [-40.176666665534128, -8.442499992380704],
              [-40.176666665534128, -8.440833325597453],
              [-40.175833331243211, -8.440833325597453],
              [-40.175833333041851, -8.438333325422605],
              [-40.174999999650197, -8.438333327221244],
              [-40.174999999650197, -8.436666658639353],
              [-40.1741666662586, -8.436666658639353],
              [-40.1741666662586, -8.434166659363768],
              [-40.173333331967626, -8.434166659363768],
              [-40.173333333766323, -8.433333326871491],
              [-40.171666666983072, -8.433333325072851],
              [-40.171666666983072, -8.434166658464449],
              [-40.169999999300501, -8.434166658464449],
              [-40.169999999300501, -8.434999992755422],
              [-40.16833333161793, -8.434999991856102],
              [-40.16833333341657, -8.434166658464449],
              [-40.166666665733999, -8.434166658464449],
              [-40.166666666633319, -8.433333325972171],
              [-40.165833332342345, -8.433333325972171],
              [-40.165833331443025, -8.43166665918892],
              [-40.164999998950748, -8.43166665918892],
              [-40.164999999850068, -8.430833325797323],
              [-40.163333333066817, -8.430833324898003],
              [-40.163333333066817, -8.429999992405669],
              [-40.1624999987759, -8.429999992405669],
              [-40.162499999675219, -8.429166659014072],
              [-40.161666666283566, -8.429166659913392],
              [-40.161666666283566, -8.428333325622418],
              [-40.160833332891968, -8.428333325622418],
              [-40.160833331992649, -8.427499993130141],
              [-40.159999999500315, -8.427499993130141],
              [-40.159999998600995, -8.426666658839167],
              [-40.159166667008037, -8.426666658839167],
              [-40.159166665209398, -8.42583332544757],
              [-40.156666666833189, -8.42583332544757],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '240',
        OBJECTID: 179.0,
        Nome_area: 'Curaça',
        COD_area: 'CA187',
        Import_bio: 'Muito Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Criação de UC de Proteção Integral',
        Area_ha: 491794.463,
        Shape_Leng: 7.56167,
        Shape_Area: 0.40452,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.332336653, -8.558286050999927],
              [-39.335928622, -8.558787077999966],
              [-39.338428006, -8.559135830999949],
              [-39.342307018, -8.559677089999985],
              [-39.344678721, -8.55879206699996],
              [-39.344911219, -8.558646324999927],
              [-39.345143717, -8.558500582999953],
              [-39.350264302, -8.553149899999934],
              [-39.353264409, -8.550014979999952],
              [-39.353933573999903, -8.549529293999971],
              [-39.3544384919999, -8.549162820999975],
              [-39.355612575, -8.548310661999947],
              [-39.356242445, -8.547853496999947],
              [-39.356628281, -8.547573453999973],
              [-39.356786008, -8.547458973999939],
              [-39.356874196, -8.547394965999954],
              [-39.357360888999899, -8.547041721999953],
              [-39.357055124999903, -8.546980548999956],
              [-39.356809138, -8.544896546999947],
              [-39.360472737999899, -8.540991182999969],
              [-39.3644564109999, -8.537849552999939],
              [-39.373719529, -8.534092502999952],
              [-39.379793561, -8.53282057099994],
              [-39.386365637, -8.533491102999957],
              [-39.400256585, -8.538659205999986],
              [-39.407628468, -8.538351348999987],
              [-39.409523279, -8.538900110999982],
              [-39.413745198999898, -8.541670628999952],
              [-39.4160408, -8.542127927999962],
              [-39.41870704, -8.546104415999931],
              [-39.424802761, -8.551396095999955],
              [-39.425338299, -8.55230568099995],
              [-39.426051469, -8.556435540999926],
              [-39.427842246999901, -8.558658097999979],
              [-39.431985128999898, -8.560611457999935],
              [-39.435490356999907, -8.563301842999982],
              [-39.439325883, -8.563410366999966],
              [-39.444453451, -8.561801597999935],
              [-39.452109575, -8.563199149999951],
              [-39.456782819, -8.568472799999942],
              [-39.457548308999897, -8.569336637999982],
              [-39.458702333, -8.572439518999932],
              [-39.45958024999991, -8.579185977999943],
              [-39.461246570999897, -8.582040166999953],
              [-39.469556387999909, -8.588732220999933],
              [-39.470672916, -8.589125609999932],
              [-39.471789444, -8.589518998999944],
              [-39.476111293, -8.592793202999934],
              [-39.482384470999904, -8.59395308099994],
              [-39.483315945999898, -8.594654088999967],
              [-39.485696748, -8.596445827999963],
              [-39.503013375, -8.600592346999973],
              [-39.514982947, -8.602510112999937],
              [-39.518612349, -8.603911062999934],
              [-39.520029735, -8.605291811999962],
              [-39.521259492999903, -8.608485123999976],
              [-39.523676077999902, -8.61084806999996],
              [-39.5262143859999, -8.612534739999935],
              [-39.529114155999899, -8.612873103999956],
              [-39.530721895999903, -8.613394420999947],
              [-39.531525775, -8.613655081999982],
              [-39.533887917, -8.615735398999965],
              [-39.535940771, -8.615735780999966],
              [-39.540532121, -8.618544097999964],
              [-39.545490511, -8.620680672999983],
              [-39.5506818429999, -8.62100878099994],
              [-39.555026771, -8.622133310999976],
              [-39.556658044, -8.623088631999963],
              [-39.557267348, -8.624889306999934],
              [-39.557515227, -8.628834270999956],
              [-39.56077506, -8.628378588999952],
              [-39.563146613, -8.629586833999948],
              [-39.564522193, -8.630287655999945],
              [-39.567544465, -8.634341114999938],
              [-39.568574941999898, -8.636651253999958],
              [-39.569057302, -8.636835323999955],
              [-39.56966698, -8.637067979999925],
              [-39.5773927709999, -8.640016172999932],
              [-39.580897632, -8.640465273999954],
              [-39.588575160999902, -8.646368442999977],
              [-39.590264047999902, -8.646708267999937],
              [-39.5928832929999, -8.649082096999964],
              [-39.595226292999897, -8.651205883999978],
              [-39.600180368999901, -8.652209902999978],
              [-39.604675041, -8.654827793999971],
              [-39.606645401, -8.655975416999981],
              [-39.610026263999899, -8.657210710999957],
              [-39.614497866, -8.657541532999966],
              [-39.622584823999901, -8.655498603999943],
              [-39.629289479, -8.655994925999956],
              [-39.633517864999902, -8.657792890999925],
              [-39.642687453, -8.659348764999953],
              [-39.646005653, -8.659692609999986],
              [-39.650624557999897, -8.660171327999937],
              [-39.652364040999899, -8.660351606999946],
              [-39.660871448, -8.657353931999978],
              [-39.66166666563602, -8.657510882746033],
              [-39.661666665484177, -8.656666658739255],
              [-39.661666666383496, -8.654166658564407],
              [-39.66249999887583, -8.654166658564407],
              [-39.66250000067447, -8.650833333091782],
              [-39.65666666783244, -8.650833326796544],
              [-39.656666666933063, -8.651666658389502],
              [-39.655000001049189, -8.651666658389502],
              [-39.655000001049189, -8.652499993579795],
              [-39.653333333366618, -8.652499991781156],
              [-39.653333331567978, -8.651666658389502],
              [-39.652499999974964, -8.651666658389502],
              [-39.652500000874284, -8.650833326796544],
              [-39.650833331393073, -8.650833325897224],
              [-39.650833331393073, -8.651666657490182],
              [-39.647499997826571, -8.651666658389502],
              [-39.647499998725891, -8.652499992680475],
              [-39.646666665334294, -8.652499992680475],
              [-39.646666668032253, -8.654166658564407],
              [-39.644166667857405, -8.654166658564407],
              [-39.644166666958085, -8.654999992855323],
              [-39.643333332667112, -8.654999992855323],
              [-39.643333331767792, -8.654166658564407],
              [-39.636666666433484, -8.654166659463726],
              [-39.636666666433484, -8.653333326072072],
              [-39.635833331243191, -8.653333326072072],
              [-39.63583333304183, -8.652499992680475],
              [-39.632499998576009, -8.652499994479115],
              [-39.632499998576009, -8.651666657490182],
              [-39.630833333591454, -8.651666658389502],
              [-39.630833332692134, -8.650833325897224],
              [-39.62999999930048, -8.650833325897224],
              [-39.629999998401161, -8.649999991606308],
              [-39.629166665908883, -8.649999992505627],
              [-39.629166667707523, -8.649166659113973],
              [-39.628333332517229, -8.649166658214654],
              [-39.628333332517229, -8.648333325722376],
              [-39.627500000924272, -8.648333325722376],
              [-39.627499998226313, -8.647499993230042],
              [-39.62999999930048, -8.647499993230042],
              [-39.629999998401161, -8.648333326621696],
              [-39.632499999475328, -8.648333325722376],
              [-39.632499999475328, -8.647499994129362],
              [-39.633333332866982, -8.647499994129362],
              [-39.633333332866982, -8.646666658939125],
              [-39.640000000899306, -8.646666658939125],
              [-39.63999999910061, -8.645833326446791],
              [-39.645833332842017, -8.645833326446791],
              [-39.645833332842017, -8.644999993055194],
              [-39.646666665334294, -8.644999993055194],
              [-39.646666665334294, -8.641666658589372],
              [-39.648333331218225, -8.641666658589372],
              [-39.648333333916185, -8.640833326097095],
              [-39.645833333741336, -8.640833326097095],
              [-39.645833334640656, -8.637499992530593],
              [-39.644999999450363, -8.637499992530593],
              [-39.644999998551043, -8.633333325572494],
              [-39.644166666058766, -8.633333325572494],
              [-39.644166666058766, -8.629999992005992],
              [-39.643333332667112, -8.629999992005992],
              [-39.643333332667112, -8.62833332612206],
              [-39.642499999275515, -8.62833332702138],
              [-39.642500001074154, -8.627499992730407],
              [-39.641666666783181, -8.627499992730407],
              [-39.641666665883861, -8.62666665843949],
              [-39.640833330693624, -8.62666665843949],
              [-39.640833332492264, -8.625833325947212],
              [-39.63999999910061, -8.625833326846532],
              [-39.63999999910061, -8.62249999417935],
              [-39.639166665709013, -8.62249999238071],
              [-39.639166665709013, -8.618333324523235],
              [-39.638333331418096, -8.618333325422611],
              [-39.638333331418096, -8.617499992930277],
              [-39.637499999825081, -8.617499992930277],
              [-39.637499999825081, -8.615833326147026],
              [-39.635833331243191, -8.615833324348387],
              [-39.635833331243191, -8.614999993654749],
              [-39.634999999650233, -8.614999991856109],
              [-39.635000000549553, -8.614166658464455],
              [-39.63583333304183, -8.614166658464455],
              [-39.635833331243191, -8.613333325972178],
              [-39.636666666433484, -8.613333325972178],
              [-39.636666665534165, -8.609999992405676],
              [-39.635833331243191, -8.609999992405676],
              [-39.63583333304183, -8.609166659014079],
              [-39.634999999650233, -8.609166659014079],
              [-39.634999999650233, -8.608333325622425],
              [-39.63416666535926, -8.608333325622425],
              [-39.63416666445994, -8.606666658839174],
              [-39.633333333766302, -8.606666658839174],
              [-39.633333333766302, -8.604999993854562],
              [-39.633333333766302, -8.603333326171992],
              [-39.632499999475328, -8.603333326171992],
              [-39.632499999475328, -8.602499991881075],
              [-39.631666667882371, -8.602499992780395],
              [-39.631666666983051, -8.601666658489478],
              [-39.630833331792758, -8.601666658489478],
              [-39.630833331792758, -8.600833325997144],
              [-39.627500000924272, -8.600833326896463],
              [-39.627500000024952, -8.601666658489478],
              [-39.626666666633298, -8.601666658489478],
              [-39.626666665733978, -8.602499992780395],
              [-39.625833331443062, -8.602499992780395],
              [-39.625833332342381, -8.603333326171992],
              [-39.625000000749367, -8.603333326171992],
              [-39.625000000749367, -8.604166658664326],
              [-39.61833333181778, -8.604166658664326],
              [-39.61833333181778, -8.604999992955243],
              [-39.617499998426126, -8.604999992955243],
              [-39.617499998426126, -8.605833325447577],
              [-39.616666666833169, -8.605833325447577],
              [-39.616666665933849, -8.606666658839174],
              [-39.615833333441515, -8.606666658839174],
              [-39.615833333441515, -8.607499993130148],
              [-39.614999998251278, -8.607499993130148],
              [-39.615000000949237, -8.608333325622425],
              [-39.61416666755764, -8.608333325622425],
              [-39.614166665759001, -8.609166659014079],
              [-39.612500000774389, -8.609166658114759],
              [-39.61249999987507, -8.609999992405676],
              [-39.611666665584096, -8.609999992405676],
              [-39.611666665584096, -8.614999992755429],
              [-39.610833333091819, -8.614999993654749],
              [-39.610833331293179, -8.61666665863936],
              [-39.609999999700165, -8.61666665863936],
              [-39.609999999700165, -8.617499993829597],
              [-39.606666667033039, -8.617499992930277],
              [-39.606666665234343, -8.618333325422611],
              [-39.605833331842746, -8.618333325422611],
              [-39.605833331842746, -8.624166659163961],
              [-39.604999999350468, -8.624166659163961],
              [-39.604999998451149, -8.62666665754017],
              [-39.603333332567217, -8.62666665843949],
              [-39.603333331667898, -8.627499992730407],
              [-39.602499997376924, -8.627499992730407],
              [-39.602499998276244, -8.628333325222741],
              [-39.601666667582606, -8.62833332612206],
              [-39.601666665783966, -8.630833325397589],
              [-39.602500000074883, -8.630833325397589],
              [-39.602499998276244, -8.63249999218084],
              [-39.599166664709742, -8.63249999308016],
              [-39.598333331318145, -8.632499993979479],
              [-39.598333331318145, -8.633333324673174],
              [-39.596666665434213, -8.633333325572494],
              [-39.596666665434213, -8.634166658964091],
              [-39.595833332042616, -8.634166658964091],
              [-39.595833332042616, -8.63833332592219],
              [-39.596666666333533, -8.63833332592219],
              [-39.596666667232853, -8.639999992705441],
              [-39.597499998825867, -8.639999991806121],
              [-39.597499998825867, -8.641666657690052],
              [-39.595833333841256, -8.641666658589372],
              [-39.595833332941936, -8.640833326097095],
              [-39.594166667058005, -8.640833326996415],
              [-39.594166664360046, -8.639999992705441],
              [-39.590833334390823, -8.639999992705441],
              [-39.590833332592183, -8.63833332592219],
              [-39.589999998301209, -8.63833332502287],
              [-39.589999998301209, -8.637499992530593],
              [-39.589166667607572, -8.637499992530593],
              [-39.589166667607572, -8.636666660038259],
              [-39.588333333316655, -8.636666659138939],
              [-39.588333332417335, -8.635833325747342],
              [-39.587499999025681, -8.635833325747342],
              [-39.587500000824321, -8.634166658964091],
              [-39.586666666533404, -8.634166658964091],
              [-39.586666665634084, -8.633333325572494],
              [-39.585833330443791, -8.633333325572494],
              [-39.58583333224243, -8.63249999308016],
              [-39.584166665459179, -8.63249999308016],
              [-39.584166666358499, -8.630833325397589],
              [-39.590000000099906, -8.630833325397589],
              [-39.590000000099906, -8.629999992005992],
              [-39.590833333491503, -8.629999993804631],
              [-39.590833331692863, -8.629166658614338],
              [-39.5916666668831, -8.629166658614338],
              [-39.591666667782476, -8.625833325947212],
              [-39.589166666708252, -8.625833325047893],
              [-39.589166665808932, -8.624999992555558],
              [-39.587499999025681, -8.624999992555558],
              [-39.587500000824321, -8.624166657365322],
              [-39.586666666533404, -8.624166659163961],
              [-39.586666665634084, -8.623333326671627],
              [-39.58583333224243, -8.623333325772307],
              [-39.58583333224243, -8.62249999417935],
              [-39.586666667432723, -8.62249999238071],
              [-39.586666666533404, -8.619999993105125],
              [-39.58583333134311, -8.619999993105125],
              [-39.58583333224243, -8.618333325422611],
              [-39.584999998850833, -8.618333325422611],
              [-39.585000000649472, -8.61666665863936],
              [-39.582499998675928, -8.61666665863936],
              [-39.582499998675928, -8.614166658464455],
              [-39.58166666708297, -8.614166658464455],
              [-39.581666665284331, -8.613333325972178],
              [-39.580833333691373, -8.613333325972178],
              [-39.580833331892734, -8.612499992580524],
              [-39.57999999850108, -8.612499992580524],
              [-39.580000000299719, -8.611666659188927],
              [-39.579166666908122, -8.611666659188927],
              [-39.579166666008803, -8.610833325797273],
              [-39.578333332617149, -8.610833325797273],
              [-39.578333331717829, -8.609999992405676],
              [-39.577500001024191, -8.609999992405676],
              [-39.577499998326232, -8.609166658114759],
              [-39.576666665833898, -8.609166659014079],
              [-39.576666665833898, -8.606666658839174],
              [-39.575833332442301, -8.606666658839174],
              [-39.575833331542981, -8.605833325447577],
              [-39.574999997252007, -8.605833325447577],
              [-39.574999999949966, -8.604999992955243],
              [-39.574166666558369, -8.604999992955243],
              [-39.574166666558369, -8.602499992780395],
              [-39.570833332092548, -8.602499992780395],
              [-39.570833332991867, -8.601666658489478],
              [-39.57000000049959, -8.601666658489478],
              [-39.56999999960027, -8.600833325997144],
              [-39.568333332817019, -8.600833325997144],
              [-39.568333332817019, -8.599999992605547],
              [-39.565833330843475, -8.599999992605547],
              [-39.565833332642171, -8.599166659213893],
              [-39.565000001049157, -8.599166659213893],
              [-39.564999999250517, -8.598333326721615],
              [-39.562499999974989, -8.598333325822296],
              [-39.562499999075669, -8.597499991531322],
              [-39.560833332292418, -8.597499992430642],
              [-39.560833334091058, -8.596666659039045],
              [-39.559999998900764, -8.596666659039045],
              [-39.559999998900764, -8.594999993155113],
              [-39.559166665509167, -8.594999993155113],
              [-39.559166666408487, -8.594166658864197],
              [-39.558333331218194, -8.59416665796482],
              [-39.558333331218194, -8.592499992980265],
              [-39.556666668931598, -8.592499992980265],
              [-39.556666666233639, -8.591666658689292],
              [-39.554999999450388, -8.591666658689292],
              [-39.554999999450388, -8.592499992980265],
              [-39.550833331592912, -8.592499992980265],
              [-39.550833331592912, -8.593333325472543],
              [-39.548333331418064, -8.593333326371862],
              [-39.548333331418064, -8.594166658864197],
              [-39.546666666433453, -8.594166658864197],
              [-39.546666666433453, -8.593333325472543],
              [-39.545833333941175, -8.593333325472543],
              [-39.545833333941175, -8.596666659039045],
              [-39.544999998750882, -8.596666659039045],
              [-39.544999999650202, -8.595833325647391],
              [-39.544166668057244, -8.595833325647391],
              [-39.544166666258604, -8.594999993155113],
              [-39.542499998576034, -8.594999993155113],
              [-39.542499999475353, -8.595833325647391],
              [-39.540833331792783, -8.595833325647391],
              [-39.540833331792783, -8.594999993155113],
              [-39.539999999300505, -8.594999993155113],
              [-39.539999998401186, -8.593333325472543],
              [-39.538333332517254, -8.593333325472543],
              [-39.538333332517254, -8.594166658864197],
              [-39.53750000092424, -8.594166658864197],
              [-39.537499998226281, -8.594999993155113],
              [-39.534999998950752, -8.594999993155113],
              [-39.535000000749392, -8.594166659763516],
              [-39.533333333966141, -8.594166658864197],
              [-39.533333333066821, -8.593333325472543],
              [-39.532499999675224, -8.593333325472543],
              [-39.532499999675224, -8.594166658864197],
              [-39.529999998600999, -8.594166658864197],
              [-39.529999998600999, -8.593333325472543],
              [-39.526666667732513, -8.593333325472543],
              [-39.526666666833137, -8.594166658864197],
              [-39.524166666658289, -8.594166658864197],
              [-39.524166665758969, -8.593333326371862],
              [-39.518333332017619, -8.593333325472543],
              [-39.518333333816258, -8.594166658864197],
              [-39.517500000424661, -8.594166658864197],
              [-39.517499998625965, -8.593333325472543],
              [-39.516666665234368, -8.593333325472543],
              [-39.516666665234368, -8.592499992980265],
              [-39.515000000249756, -8.592499992980265],
              [-39.514999998451117, -8.593333325472543],
              [-39.511666666683254, -8.593333325472543],
              [-39.511666665783935, -8.592499992980265],
              [-39.504999998650987, -8.592499992980265],
              [-39.504999998650987, -8.591666658689292],
              [-39.504166667957293, -8.591666658689292],
              [-39.504166665259334, -8.590833326197014],
              [-39.503333332767056, -8.590833326197014],
              [-39.503333332767056, -8.58999999280536],
              [-39.504166665259334, -8.58999999280536],
              [-39.504166665259334, -8.588333326022109],
              [-39.505833333841224, -8.588333326022109],
              [-39.505833333841224, -8.587499992630512],
              [-39.506666665434238, -8.587499993529832],
              [-39.506666665434238, -8.586666659238858],
              [-39.50833333131817, -8.586666659238858],
              [-39.508333332217489, -8.585833325847261],
              [-39.509166667407726, -8.585833325847261],
              [-39.509166667407726, -8.584999993354984],
              [-39.509999999000684, -8.584999992455664],
              [-39.509999999000684, -8.583333325672413],
              [-39.510833331493018, -8.583333325672413],
              [-39.510833333291657, -8.580833325497508],
              [-39.50999999990006, -8.580833325497508],
              [-39.50999999990006, -8.579166657814937],
              [-39.507499999725155, -8.579166658714257],
              [-39.507499998825836, -8.57833332622198],
              [-39.504166667057973, -8.57833332442334],
              [-39.504166667957293, -8.577499992830383],
              [-39.503333332767056, -8.577499992830383],
              [-39.503333332767056, -8.576666658539409],
              [-39.502499998476083, -8.576666657640089],
              [-39.502499999375402, -8.574999992655478],
              [-39.500833333491528, -8.574999992655478],
              [-39.500833332592208, -8.575833326047132],
              [-39.498333332417303, -8.575833326047132],
              [-39.498333332417303, -8.574999992655478],
              [-39.497499999025706, -8.574999992655478],
              [-39.497500000824346, -8.574166658364561],
              [-39.495833334041095, -8.574166658364561],
              [-39.495833334041095, -8.575833326047132],
              [-39.492499998675953, -8.575833326047132],
              [-39.492499998675953, -8.576666658539409],
              [-39.491666665284356, -8.576666658539409],
              [-39.491666667082995, -8.575833326047132],
              [-39.490833331892702, -8.575833325147812],
              [-39.490833333691342, -8.576666658539409],
              [-39.489999998501105, -8.576666658539409],
              [-39.489999999400425, -8.57833332622198],
              [-39.489166666908091, -8.57833332442334],
              [-39.48916666780741, -8.577499993729703],
              [-39.488333332617174, -8.577499992830383],
              [-39.488333331717854, -8.576666659438729],
              [-39.485833331542949, -8.576666658539409],
              [-39.485833332442269, -8.575833326047132],
              [-39.485000000849311, -8.575833326047132],
              [-39.484999999949991, -8.574999992655478],
              [-39.484166666558394, -8.574999992655478],
              [-39.484166666558394, -8.574166658364561],
              [-39.48166666548417, -8.574166660163201],
              [-39.48166666548417, -8.573333325872227],
              [-39.480833332991892, -8.573333325872227],
              [-39.480833333891212, -8.57249999248063],
              [-39.479166665309322, -8.57249999248063],
              [-39.479166664410002, -8.571666659088976],
              [-39.479999998700919, -8.571666659088976],
              [-39.479999999600238, -8.569999993205101],
              [-39.479166666208641, -8.569999991406405],
              [-39.479166668007281, -8.569166658914128],
              [-39.477499998526071, -8.569166658914128],
              [-39.47750000032471, -8.568333325522531],
              [-39.476666667832433, -8.568333324623211],
              [-39.476666667832433, -8.567499993929516],
              [-39.477499998526071, -8.567499993030196],
              [-39.47749999942539, -8.565833325347626],
              [-39.475000001049182, -8.565833325347626],
              [-39.474999998351223, -8.564999992855348],
              [-39.473333332467291, -8.564999993754668],
              [-39.473333333366611, -8.562499992680443],
              [-39.474166667657528, -8.562499991781124],
              [-39.474166666758208, -8.561666658389527],
              [-39.475833334440779, -8.561666658389527],
              [-39.475833334440779, -8.560833325897249],
              [-39.476666666933113, -8.560833325897249],
              [-39.476666666933113, -8.559999992505595],
              [-39.477499998526071, -8.559999992505595],
              [-39.47749999942539, -8.559166659113998],
              [-39.476666666033793, -8.559166659113998],
              [-39.476666666933113, -8.558333325722344],
              [-39.474999998351223, -8.558333325722344],
              [-39.474999998351223, -8.559166658214679],
              [-39.474166666758208, -8.559166658214679],
              [-39.474166665858888, -8.558333325722344],
              [-39.472500000874277, -8.558333325722344],
              [-39.472499999974957, -8.556666658939093],
              [-39.470833331393067, -8.556666658939093],
              [-39.470833332292386, -8.555833324648177],
              [-39.46583333284201, -8.555833324648177],
              [-39.46583333374133, -8.554999993055162],
              [-39.464999997651717, -8.554999993055162],
              [-39.464999999450356, -8.554166658764245],
              [-39.462500001074147, -8.554166658764245],
              [-39.462499998376188, -8.551666658589397],
              [-39.461666666783231, -8.551666658589397],
              [-39.461666665883854, -8.550833326996383],
              [-39.460833331592937, -8.550833326097063],
              [-39.460833332492257, -8.549999992705466],
              [-39.45999999910066, -8.549999992705466],
              [-39.46, -8.550833326097063],
              [-39.457499999825075, -8.550833326097063],
              [-39.457500001623714, -8.549999992705466],
              [-39.455833333041824, -8.549999992705466],
              [-39.455833333041824, -8.550833326097063],
              [-39.454999998750907, -8.550833326097063],
              [-39.454999999650227, -8.549999992705466],
              [-39.452499998576059, -8.549999991806146],
              [-39.452499998576059, -8.550833326097063],
              [-39.451666666083725, -8.550833326097063],
              [-39.451666666983044, -8.551666659488717],
              [-39.450000001099113, -8.551666658589397],
              [-39.450000001099113, -8.552499992880314],
              [-39.447500000024945, -8.552499992880314],
              [-39.447500000924265, -8.554166658764245],
              [-39.444999998950721, -8.554166658764245],
              [-39.444999999850097, -8.553333325372648],
              [-39.443333333966166, -8.553333325372648],
              [-39.443333333966166, -8.552499993779634],
              [-39.43916666790733, -8.552499992880314],
              [-39.43916666700801, -8.551666658589397],
              [-39.434999998251271, -8.551666657690077],
              [-39.434999998251271, -8.550833325197743],
              [-39.434166665758994, -8.550833325197743],
              [-39.434166667557633, -8.549999991806146],
              [-39.43333333146802, -8.549999992705466],
              [-39.43333333236734, -8.549166658414492],
              [-39.429999999700158, -8.549166658414492],
              [-39.428333332017644, -8.549166658414492],
              [-39.428333332916964, -8.548333326821535],
              [-39.42749999952531, -8.548333325022895],
              [-39.427500000424629, -8.547499992530561],
              [-39.428333332017644, -8.547499992530561],
              [-39.428333333816283, -8.546666659138964],
              [-39.430000000599478, -8.546666657340324],
              [-39.430000000599478, -8.545833325747367],
              [-39.431666667382729, -8.545833325747367],
              [-39.431666666483409, -8.544999992355713],
              [-39.43333333146802, -8.544999992355713],
              [-39.43333333236734, -8.545833325747367],
              [-39.435833330743549, -8.545833325747367],
              [-39.435833333441565, -8.546666659138964],
              [-39.437499998426119, -8.546666659138964],
              [-39.437500000224816, -8.548333326821535],
              [-39.441666665384275, -8.548333325922215],
              [-39.441666666283595, -8.547499992530561],
              [-39.444999999850097, -8.547499992530561],
              [-39.444999998950721, -8.548333325922215],
              [-39.448333332517223, -8.548333325922215],
              [-39.448333331617903, -8.547499992530561],
              [-39.449166665908876, -8.547499992530561],
              [-39.449166666808196, -8.546666657340324],
              [-39.450833331792808, -8.546666659138964],
              [-39.450833332692127, -8.545833325747367],
              [-39.451666666083725, -8.54583332484799],
              [-39.451666667882364, -8.544999992355713],
              [-39.449166667707516, -8.544999992355713],
              [-39.449166667707516, -8.544166658964116],
              [-39.448333333416542, -8.544166659863436],
              [-39.448333331617903, -8.543333325572462],
              [-39.447499998226306, -8.543333325572462],
              [-39.447500000024945, -8.541666658789211],
              [-39.446666666633291, -8.541666658789211],
              [-39.446666665733972, -8.53999999290528],
              [-39.445833332342374, -8.53999999200596],
              [-39.445833331443055, -8.537499992730432],
              [-39.444999998950721, -8.537499992730432],
              [-39.444999999850097, -8.536666658439515],
              [-39.443333333066846, -8.536666658439515],
              [-39.443333333966166, -8.535833325947181],
              [-39.442500000574512, -8.535833325947181],
              [-39.442499999675192, -8.534999992555584],
              [-39.441666666283595, -8.534999993454903],
              [-39.441666668082235, -8.53416665916393],
              [-39.440833332891941, -8.53416665916393],
              [-39.440833331093302, -8.532499992380679],
              [-39.43916666700801, -8.532499992380679],
              [-39.43916666790733, -8.531666658989081],
              [-39.438333331817773, -8.531666658989081],
              [-39.438333334515733, -8.52999999310515],
              [-39.43916666700801, -8.52999999310515],
              [-39.439166668806649, -8.529166658814233],
              [-39.438333331817773, -8.529166658814233],
              [-39.438333331817773, -8.528333327221219],
              [-39.4374999975268, -8.528333327221219],
              [-39.437500000224816, -8.527499992930302],
              [-39.436666665933842, -8.527499993829622],
              [-39.436666665933842, -8.526666658639328],
              [-39.434999997351952, -8.526666658639328],
              [-39.435000000949231, -8.525833326147051],
              [-39.434166666658314, -8.525833327046371],
              [-39.434166666658314, -8.524999992755397],
              [-39.43333333236734, -8.524999992755397],
              [-39.43333333326666, -8.522499992580549],
              [-39.432499999875063, -8.522499992580549],
              [-39.432499998975743, -8.520833326696618],
              [-39.431666667382729, -8.520833325797298],
              [-39.431666666483409, -8.51833332562245],
              [-39.430000000599478, -8.51833332562245],
              [-39.429999998800838, -8.517499993130116],
              [-39.42916666720788, -8.517499993130116],
              [-39.429166665409241, -8.516666658839199],
              [-39.428333332916964, -8.516666658839199],
              [-39.428333332916964, -8.515833325447545],
              [-39.429166666308561, -8.515833325447545],
              [-39.429166666308561, -8.514999992055948],
              [-39.42749999862599, -8.514999992955268],
              [-39.42749999862599, -8.513333326172017],
              [-39.426666667033032, -8.513333326172017],
              [-39.426666665234393, -8.514166658664294],
              [-39.423333331667891, -8.514166657764974],
              [-39.42333333346653, -8.51249999278042],
              [-39.420833333291682, -8.51249999278042],
              [-39.420833332392306, -8.513333326172017],
              [-39.417499998825861, -8.513333326172017],
              [-39.417499997926541, -8.514999992955268],
              [-39.415000000449595, -8.514999992055948],
              [-39.414999999550275, -8.515833325447545],
              [-39.413333332767024, -8.515833325447545],
              [-39.413333332767024, -8.516666658839199],
              [-39.41166666598383, -8.516666657939879],
              [-39.41166666598383, -8.515833326346865],
              [-39.410000000099899, -8.515833325447545],
              [-39.40999999740194, -8.516666658839199],
              [-39.408333333316648, -8.516666659738519],
              [-39.408333334215968, -8.517499993130116],
              [-39.407500000824314, -8.517499993130116],
              [-39.407499999025674, -8.516666659738519],
              [-39.406666666533397, -8.516666657939879],
              [-39.406666667432717, -8.51833332562245],
              [-39.404166667257869, -8.51833332562245],
              [-39.404166666358549, -8.516666658839199],
              [-39.403333332966895, -8.516666658839199],
              [-39.403333332966895, -8.515833325447545],
              [-39.402499999575298, -8.515833325447545],
              [-39.402499998675978, -8.514999992955268],
              [-39.399999999400393, -8.514999992055948],
              [-39.399999999400393, -8.515833325447545],
              [-39.399166666908116, -8.515833325447545],
              [-39.399166666008796, -8.51833332472313],
              [-39.395833332442294, -8.51833332562245],
              [-39.395833332442294, -8.519999992405701],
              [-39.393333334066085, -8.519999992405701],
              [-39.393333332267446, -8.519166659014047],
              [-39.391666667282834, -8.519166659913367],
              [-39.391666667282834, -8.517499993130116],
              [-39.392500000674431, -8.517499992230796],
              [-39.392499998875792, -8.516666658839199],
              [-39.391666665484195, -8.516666658839199],
              [-39.391666666383514, -8.515833325447545],
              [-39.390833332092541, -8.515833325447545],
              [-39.390833332092541, -8.514999992955268],
              [-39.389999999600263, -8.514999992055948],
              [-39.389999999600263, -8.515833324548225],
              [-39.389166668007306, -8.515833325447545],
              [-39.38916666620861, -8.514999992955268],
              [-39.388333333716332, -8.514999992955268],
              [-39.388333331917693, -8.515833325447545],
              [-39.387499999425415, -8.515833325447545],
              [-39.387500000324735, -8.516666658839199],
              [-39.38499999925051, -8.516666658839199],
              [-39.38499999925051, -8.519166659014047],
              [-39.377499998725909, -8.519166659014047],
              [-39.377499998725909, -8.519999994204341],
              [-39.374999999450381, -8.519999992405701],
              [-39.374999998551061, -8.520833325797298],
              [-39.372499999275476, -8.520833325797298],
              [-39.372499998376156, -8.521666659188952],
              [-39.368333331418057, -8.521666657390256],
              [-39.368333331418057, -8.522499992580549],
              [-39.362499999475347, -8.522499992580549],
              [-39.362499998576027, -8.521666659188952],
              [-39.360833333591415, -8.521666659188952],
              [-39.360833331792776, -8.52416665846448],
              [-39.359166665908845, -8.52416665846448],
              [-39.359166667707484, -8.526666658639328],
              [-39.358333332517248, -8.526666658639328],
              [-39.358333333416567, -8.52833332452326],
              [-39.356666665733997, -8.528333325422579],
              [-39.356666666633316, -8.52999999310515],
              [-39.354999998950746, -8.52999999220583],
              [-39.354999998950746, -8.529166658814233],
              [-39.350833333791286, -8.529166658814233],
              [-39.350833332891966, -8.52999999310515],
              [-39.349999999500312, -8.52999999220583],
              [-39.349999998600993, -8.531666658989081],
              [-39.349166667907355, -8.531666659888401],
              [-39.349166667907355, -8.532499992380679],
              [-39.348333333616438, -8.532499992380679],
              [-39.348333332717061, -8.533333325772333],
              [-39.347499999325464, -8.533333325772333],
              [-39.347499998426144, -8.53416665916393],
              [-39.343333331468045, -8.53416665916393],
              [-39.343333333266685, -8.534999993454903],
              [-39.341666667382754, -8.534999992555584],
              [-39.341666665584114, -8.535833325947181],
              [-39.34083333129314, -8.535833325947181],
              [-39.34083333309178, -8.536666658439515],
              [-39.339999998800863, -8.536666660238154],
              [-39.339999998800863, -8.537499991831112],
              [-39.337499999525335, -8.537499992730432],
              [-39.337499999525335, -8.536666658439515],
              [-39.336666667033001, -8.536666660238154],
              [-39.336666667033001, -8.537499993629751],
              [-39.335833332742084, -8.537499992730432],
              [-39.335833330943444, -8.539166658614363],
              [-39.335000002048389, -8.539166658614363],
              [-39.33499999935043, -8.540833325397614],
              [-39.335833333641403, -8.540833327196253],
              [-39.335833332742084, -8.543333325572462],
              [-39.336666667033001, -8.543333325572462],
              [-39.336666665234361, -8.544166658964116],
              [-39.337499998626015, -8.544166658964116],
              [-39.337499999525335, -8.546666659138964],
              [-39.339166665409266, -8.546666659138964],
              [-39.339166666308586, -8.547499992530561],
              [-39.339999998800863, -8.547499992530561],
              [-39.339999999700183, -8.549166658414492],
              [-39.3408333339911, -8.549166657515173],
              [-39.3408333339911, -8.549999992705466],
              [-39.341666665584114, -8.549999992705466],
              [-39.341666666483434, -8.550833325197743],
              [-39.34083333309178, -8.550833326097063],
              [-39.34083333129314, -8.551666658589397],
              [-39.340000000599503, -8.551666659488717],
              [-39.339999998800863, -8.552499991980994],
              [-39.338333333816252, -8.552499992880314],
              [-39.338333332017612, -8.551666658589397],
              [-39.335833333641403, -8.551666659488717],
              [-39.335833331842764, -8.552499992880314],
              [-39.334166667757472, -8.552499992880314],
              [-39.334166665958833, -8.553333325372648],
              [-39.333333332567179, -8.553333325372648],
              [-39.333333330768539, -8.552499992880314],
              [-39.332500000974221, -8.552499992880314],
              [-39.332500000974221, -8.553333325372648],
              [-39.33083333329165, -8.553333325372648],
              [-39.330833331493011, -8.552499992880314],
              [-39.32333333186773, -8.552499992880314],
              [-39.323333332767049, -8.553333325372648],
              [-39.320833332592201, -8.553333325372648],
              [-39.320833332592201, -8.554166658764245],
              [-39.320833331692882, -8.556666658939093],
              [-39.322500000274772, -8.556666658939093],
              [-39.322499998476133, -8.557499994129387],
              [-39.325833333841217, -8.557499993230067],
              [-39.325833332941897, -8.558333325722344],
              [-39.326666665434232, -8.558333325722344],
              [-39.326666665755866, -8.559525459808624],
              [-39.332336653, -8.558286050999927],
            ],
          ],
          [
            [
              [-39.682282533, -8.711469004999975],
              [-39.67964603, -8.722517648999938],
              [-39.678994278999902, -8.729334774999929],
              [-39.67797229, -8.732576047999938],
              [-39.675020002999901, -8.736667356999925],
              [-39.675147872, -8.740502480999956],
              [-39.673824037, -8.745461408999963],
              [-39.677469335, -8.755205696999942],
              [-39.678124655, -8.756957441999932],
              [-39.677501268, -8.760889045999933],
              [-39.676705274, -8.765909256999977],
              [-39.674903373999904, -8.772678649999936],
              [-39.671713559999901, -8.777581461999949],
              [-39.671838211, -8.780738319999955],
              [-39.673300375999901, -8.784903966999927],
              [-39.674629179, -8.787157204999966],
              [-39.675960352999901, -8.78828252699998],
              [-39.676795868999903, -8.78851710199995],
              [-39.679945050999898, -8.789401247999933],
              [-39.682954478999903, -8.791117787999957],
              [-39.686382030999901, -8.793072821999933],
              [-39.687024248999897, -8.793458826999936],
              [-39.688574358, -8.794390518999931],
              [-39.690650282, -8.795638251999957],
              [-39.693196365999903, -8.798675740999947],
              [-39.695128745, -8.799571361999934],
              [-39.700476889999898, -8.799952050999929],
              [-39.704185978999902, -8.800006252999934],
              [-39.7056649949999, -8.799460147999984],
              [-39.710163943999902, -8.797798977999943],
              [-39.713751373, -8.797358665999983],
              [-39.716864487999899, -8.797579530999936],
              [-39.721768380999897, -8.799015603999985],
              [-39.730277562, -8.796576993999954],
              [-39.738725248, -8.79487071299997],
              [-39.747785345, -8.794041011999974],
              [-39.749239293999899, -8.794846067999966],
              [-39.750351388999903, -8.796766060999969],
              [-39.757560084, -8.799187497999988],
              [-39.758576389, -8.799618103999933],
              [-39.758748470999898, -8.799691014999944],
              [-39.761751391, -8.800963346999941],
              [-39.762851816, -8.803439752999955],
              [-39.764421741, -8.804793576999936],
              [-39.775484352, -8.809112162999957],
              [-39.780841693, -8.813135615999943],
              [-39.782422627, -8.814322923999955],
              [-39.788796213, -8.819109593999933],
              [-39.792662682, -8.819890968999976],
              [-39.798333332717107, -8.819601096030082],
              [-39.798333332717107, -8.819166658414531],
              [-39.795833331642882, -8.819166658414531],
              [-39.795833331642882, -8.818333325922197],
              [-39.794999998251285, -8.818333325922197],
              [-39.794999998251285, -8.8174999925306],
              [-39.795833331642882, -8.8174999925306],
              [-39.795833331642882, -8.816666659138946],
              [-39.799166665209384, -8.816666659138946],
              [-39.799166664310064, -8.815833325747349],
              [-39.800833331992635, -8.815833325747349],
              [-39.800833331992635, -8.814999992355695],
              [-39.803333333966123, -8.814999991456375],
              [-39.803333333066803, -8.814166658964098],
              [-39.802500000574526, -8.814166659863417],
              [-39.802500000574526, -8.813333325572444],
              [-39.800833331992635, -8.813333325572444],
              [-39.800833332891955, -8.812499993080166],
              [-39.799999999500358, -8.812499992180847],
              [-39.799999999500358, -8.809999992905318],
              [-39.799166664310064, -8.809999992905318],
              [-39.799166667907343, -8.808333326122067],
              [-39.799999998601038, -8.808333326122067],
              [-39.804166667357777, -8.808333326122067],
              [-39.804166666458457, -8.809166658614345],
              [-39.806666665733985, -8.809166658614345],
              [-39.806666665733985, -8.809999992905318],
              [-39.808333332517236, -8.809999992905318],
              [-39.808333330718597, -8.810833325397596],
              [-39.810833333591404, -8.810833325397596],
              [-39.810833332692084, -8.81166665878925],
              [-39.813333333766309, -8.81166665788993],
              [-39.813333332866989, -8.810833327196235],
              [-39.814166665359267, -8.810833325397596],
              [-39.814166666258586, -8.809999992905318],
              [-39.81499999875092, -8.809999992905318],
              [-39.81499999965024, -8.809166658614345],
              [-39.815833333041837, -8.809166657715025],
              [-39.815833331243198, -8.808333326122067],
              [-39.816666665534171, -8.808333326122067],
              [-39.816666665534171, -8.807499992730413],
              [-39.819166667507659, -8.807499993629733],
              [-39.819166667507659, -8.806666658439497],
              [-39.820833331592951, -8.806666657540177],
              [-39.821666666783187, -8.806666657540177],
              [-39.821666666783187, -8.805833326846482],
              [-39.822500001074161, -8.805833325947162],
              [-39.822499998376202, -8.802499992380717],
              [-39.823333330868479, -8.802499992380717],
              [-39.823333331767799, -8.800833325597466],
              [-39.824166667857412, -8.800833325597466],
              [-39.824166666058773, -8.79666665863931],
              [-39.82499999945037, -8.79666665863931],
              [-39.825000000349689, -8.793333325972185],
              [-39.824166666058773, -8.793333325972185],
              [-39.824166666958092, -8.789166659014029],
              [-39.823333333566438, -8.789166659014029],
              [-39.823333333566438, -8.787499994029474],
              [-39.821666667682507, -8.787499993130155],
              [-39.821666665883868, -8.786666658839181],
              [-39.81916666570902, -8.786666658839181],
              [-39.81916666570902, -8.785833325447584],
              [-39.82, -8.785833324548264],
              [-39.820000000899256, -8.78499999295525],
              [-39.822500001074161, -8.78499999295525],
              [-39.822500001074161, -8.784166658664333],
              [-39.823333332667119, -8.784166658664333],
              [-39.823333332667119, -8.783333327071318],
              [-39.824166667857412, -8.783333326171999],
              [-39.824166666958092, -8.782499992780401],
              [-39.82499999855105, -8.782499991881082],
              [-39.825000000349689, -8.78083332599715],
              [-39.825833332841967, -8.78083332599715],
              [-39.825833331942647, -8.778333325822302],
              [-39.826666666233621, -8.778333325822302],
              [-39.826666665334301, -8.777499992430648],
              [-39.826666666233621, -8.776666659039051],
              [-39.825833333741343, -8.776666659039051],
              [-39.825833332841967, -8.775833325647397],
              [-39.82499999945037, -8.775833325647397],
              [-39.825000000349689, -8.77499999315512],
              [-39.824166664260076, -8.77499999315512],
              [-39.824166666058773, -8.773333325472549],
              [-39.823333331767799, -8.77333332457323],
              [-39.823333332667119, -8.772499992080895],
              [-39.820000000899256, -8.772499992980272],
              [-39.819999999100617, -8.774166658864146],
              [-39.818333331418046, -8.774166658864146],
              [-39.818333331418046, -8.773333325472549],
              [-39.815833331243198, -8.773333325472549],
              [-39.815833333041837, -8.772499992980272],
              [-39.813333331967669, -8.772499992980272],
              [-39.813333333766309, -8.771666658689298],
              [-39.810833330893445, -8.771666658689298],
              [-39.810833332692084, -8.770833324398382],
              [-39.80916666590889, -8.770833326197021],
              [-39.80916666680821, -8.769999992805367],
              [-39.806666666633305, -8.769999992805367],
              [-39.806666665733985, -8.764166659064017],
              [-39.805833333241708, -8.764166659064017],
              [-39.805833333241708, -8.7633333247731],
              [-39.804999998950734, -8.76333332567242],
              [-39.804999998950734, -8.762499993180086],
              [-39.802499999675206, -8.762499993180086],
              [-39.802499999675206, -8.76333332567242],
              [-39.799166667008024, -8.76333332567242],
              [-39.799166667008024, -8.764166659064017],
              [-39.796666665933856, -8.764166659064017],
              [-39.796666666833175, -8.76333332657174],
              [-39.794166665758951, -8.76333332567242],
              [-39.794166667557647, -8.764166659064017],
              [-39.791666667382742, -8.764166659064017],
              [-39.791666667382742, -8.76333332567242],
              [-39.789166665409255, -8.76333332567242],
              [-39.789166666308574, -8.762499993180086],
              [-39.787499997726684, -8.762499993180086],
              [-39.787499999525323, -8.76333332567242],
              [-39.785000000249795, -8.76333332567242],
              [-39.784999999350418, -8.762499993180086],
              [-39.784166665958821, -8.762499993180086],
              [-39.784166667757461, -8.761666658889169],
              [-39.783333333466544, -8.761666658889169],
              [-39.783333332567224, -8.759999993005238],
              [-39.781666665783973, -8.759999992105918],
              [-39.781666665783973, -8.759166658714264],
              [-39.778333331318152, -8.759166658714264],
              [-39.778333332217471, -8.758333326221987],
              [-39.777499997926498, -8.758333326221987],
              [-39.777499999725137, -8.756666658539416],
              [-39.778333331318152, -8.756666658539416],
              [-39.778333334016111, -8.754999992655485],
              [-39.778333334016111, -8.754166657465248],
              [-39.77666666633354, -8.754166658364568],
              [-39.77666666543422, -8.753333325872234],
              [-39.774999999550289, -8.753333325872234],
              [-39.775000000449609, -8.752499992480637],
              [-39.774166667058012, -8.752499992480637],
              [-39.774166667957331, -8.751666659088983],
              [-39.773333331867718, -8.751666659088983],
              [-39.773333331867718, -8.750833324798066],
              [-39.772499998476121, -8.750833325697386],
              [-39.772500000274761, -8.749999993205051],
              [-39.769166665808939, -8.749999993205051],
              [-39.769166664909619, -8.750833325697386],
              [-39.765000001548799, -8.750833324798066],
              [-39.765000000649479, -8.751666659088983],
              [-39.760833332792004, -8.751666659088983],
              [-39.760833332792004, -8.752499992480637],
              [-39.759166666908129, -8.752499992480637],
              [-39.759166666908129, -8.753333325872234],
              [-39.755833332442307, -8.753333325872234],
              [-39.755833331542988, -8.750833326596705],
              [-39.755000000849293, -8.750833325697386],
              [-39.754999999050654, -8.748333325522481],
              [-39.752499998875805, -8.748333325522481],
              [-39.752499999775125, -8.744166658564382],
              [-39.750833332991874, -8.744166658564382],
              [-39.750833332092554, -8.741666658389533],
              [-39.750000000499597, -8.741666658389533],
              [-39.749999998700957, -8.743333325172784],
              [-39.747500001224012, -8.743333326072104],
              [-39.747500000324692, -8.741666658389533],
              [-39.746666666933095, -8.741666658389533],
              [-39.746666666933095, -8.740833325897199],
              [-39.744166665858927, -8.740833325897199],
              [-39.744166667657566, -8.739999992505602],
              [-39.743333332467273, -8.739999992505602],
              [-39.743333331567953, -8.741666658389533],
              [-39.744166666758247, -8.741666658389533],
              [-39.744166665858927, -8.743333326072104],
              [-39.744999999250524, -8.743333325172784],
              [-39.744999999250524, -8.745833325347633],
              [-39.745833331742801, -8.745833325347633],
              [-39.745833331742801, -8.747499992130884],
              [-39.744999999250524, -8.747499993030203],
              [-39.745000001049164, -8.748333325522481],
              [-39.743333331567953, -8.748333326421857],
              [-39.743333334265913, -8.749999993205051],
              [-39.742500000874315, -8.749999993205051],
              [-39.742500000874315, -8.751666659088983],
              [-39.741666666583342, -8.751666659088983],
              [-39.741666665684022, -8.750833325697386],
              [-39.740833332292425, -8.750833325697386],
              [-39.740833331393105, -8.749999993205051],
              [-39.735833331942672, -8.749999993205051],
              [-39.735833332841992, -8.751666659088983],
              [-39.734999998551075, -8.751666658189663],
              [-39.734999999450395, -8.752499992480637],
              [-39.734166666058741, -8.752499992480637],
              [-39.734166666058741, -8.753333325872234],
              [-39.733333331767824, -8.753333324972914],
              [-39.733333333566463, -8.754999992655485],
              [-39.730000000899281, -8.754999992655485],
              [-39.73, -8.754166660163207],
              [-39.729166667507684, -8.754166657465248],
              [-39.729166667507684, -8.753333326771553],
              [-39.728333331418071, -8.753333324972914],
              [-39.72833333321671, -8.752499992480637],
              [-39.72666666553414, -8.752499992480637],
              [-39.726666666433459, -8.753333325872234],
              [-39.724166668057251, -8.753333325872234],
              [-39.724166665359292, -8.754166657465248],
              [-39.72249999947536, -8.754166658364568],
              [-39.72250000037468, -8.751666659088983],
              [-39.721666667882346, -8.751666659988302],
              [-39.721666664285067, -8.750833326596705],
              [-39.720833332692109, -8.750833325697386],
              [-39.720833333591429, -8.749999993205051],
              [-39.719999998401136, -8.749999993205051],
              [-39.720000001099152, -8.749166659813454],
              [-39.717500000024927, -8.749166658914135],
              [-39.717499998226288, -8.748333326421857],
              [-39.71666666573401, -8.748333325522481],
              [-39.71666666573401, -8.749166658914135],
              [-39.714999999850079, -8.749166658914135],
              [-39.715000000749399, -8.748333325522481],
              [-39.712499998775854, -8.748333325522481],
              [-39.712499999675174, -8.749166658914135],
              [-39.711666665384257, -8.749166658914135],
              [-39.711666666283577, -8.748333325522481],
              [-39.709166665209409, -8.748333325522481],
              [-39.709166665209409, -8.749166659813454],
              [-39.708333331817755, -8.749166659813454],
              [-39.708333333616395, -8.749999993205051],
              [-39.707499999325478, -8.749999993205051],
              [-39.707499998426158, -8.750833325697386],
              [-39.705833330743587, -8.750833325697386],
              [-39.705833331642907, -8.751666659088983],
              [-39.705000000949269, -8.751666659088983],
              [-39.705000000049893, -8.752499992480637],
              [-39.701666666483447, -8.752499992480637],
              [-39.701666666483447, -8.753333325872234],
              [-39.700833333991113, -8.753333325872234],
              [-39.700833331293154, -8.754166658364568],
              [-39.698333332916945, -8.754166658364568],
              [-39.698333332017626, -8.754999992655485],
              [-39.694166666858166, -8.754999992655485],
              [-39.694166665958846, -8.755833326047139],
              [-39.692500000074915, -8.755833326047139],
              [-39.692499998276276, -8.756666657640096],
              [-39.68999999900069, -8.756666658539416],
              [-39.68999999900069, -8.755833326047139],
              [-39.688333332217439, -8.755833326047139],
              [-39.68833333131812, -8.754999992655485],
              [-39.686666667232885, -8.754999992655485],
              [-39.686666667232885, -8.754166658364568],
              [-39.685833332941911, -8.754166658364568],
              [-39.685833332042591, -8.751666659088983],
              [-39.684999998650994, -8.751666659088983],
              [-39.684999998650994, -8.749999993205051],
              [-39.68416666525934, -8.749999993205051],
              [-39.68416666705798, -8.747499993030203],
              [-39.683333332767063, -8.747499993030203],
              [-39.683333331867743, -8.744166658564382],
              [-39.681666666883132, -8.744166658564382],
              [-39.681666665983812, -8.73666665803978],
              [-39.68249999757677, -8.7366666589391],
              [-39.682499998476089, -8.731666658589404],
              [-39.683333331867743, -8.731666658589404],
              [-39.683333334565702, -8.729999992705473],
              [-39.682500000274729, -8.729999992705473],
              [-39.682499998476089, -8.726666659138971],
              [-39.683333331867743, -8.726666657340331],
              [-39.683333331867743, -8.725833325747317],
              [-39.684999999550314, -8.725833325747317],
              [-39.685000000449634, -8.72499999235572],
              [-39.685833332042591, -8.72499999235572],
              [-39.685833332042591, -8.724166658964066],
              [-39.687499999725162, -8.724166658964066],
              [-39.687499999725162, -8.723333325572469],
              [-39.68999999990001, -8.723333326471788],
              [-39.68999999990001, -8.720833325397621],
              [-39.68999999900069, -8.71333332487302],
              [-39.689166665609093, -8.713333325772339],
              [-39.689166665609093, -8.712499991481366],
              [-39.688333332217439, -8.712499992380685],
              [-39.688333334016136, -8.711666658989088],
              [-39.687499998825842, -8.711666658989088],
              [-39.687500000624482, -8.710833325597434],
              [-39.684999998650994, -8.710833325597434],
              [-39.684999999550314, -8.709999993105157],
              [-39.68416666705798, -8.709999993105157],
              [-39.68416666525934, -8.709166658814183],
              [-39.683091591192174, -8.709166658814183],
              [-39.682282533, -8.711469004999975],
            ],
          ],
          [
            [
              [-39.886861025426356, -8.890833325597441],
              [-39.888333333616401, -8.890833325597441],
              [-39.888333331817762, -8.889999993105164],
              [-39.889166665209416, -8.889999993105164],
              [-39.889166665209416, -8.887499992930259],
              [-39.888660274439786, -8.887499993476748],
              [-39.886861025426356, -8.890833325597441],
            ],
          ],
          [
            [
              [-39.879883306999901, -8.946196321999937],
              [-39.88011110687026, -8.946666659238872],
              [-39.88083333399112, -8.946666659238872],
              [-39.8808333330918, -8.944999992455621],
              [-39.881666666483454, -8.944999992455621],
              [-39.881666665584135, -8.94333332567237],
              [-39.878232103835479, -8.94333332567237],
              [-39.879883306999901, -8.946196321999937],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '242',
        OBJECTID: 174.0,
        Nome_area: 'Serra do Recreio',
        COD_area: 'CA181',
        Import_bio: 'Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Criação de UC de Proteção Integral',
        Area_ha: 78964.5321,
        Shape_Leng: 2.16,
        Shape_Area: 0.06491,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.902938175, -8.858743592999929],
              [-39.901259274, -8.864158782999946],
              [-39.898333332517211, -8.869579452765519],
              [-39.898333332517211, -8.869999992605528],
              [-39.899166665908865, -8.869999990806889],
              [-39.899166665908865, -8.870833325997125],
              [-39.900000001099102, -8.870833325997125],
              [-39.899999999300462, -8.87166665848946],
              [-39.903333331967644, -8.87166665848946],
              [-39.903333331967644, -8.872499992780376],
              [-39.903333332866964, -8.87333332617203],
              [-39.904166664459979, -8.873333323474071],
              [-39.904166665359298, -8.877499993130129],
              [-39.903333333766284, -8.877499993130129],
              [-39.903333331967644, -8.878333325622407],
              [-39.902499999475367, -8.878333326521727],
              [-39.902499999475367, -8.882499992580563],
              [-39.901666664285074, -8.882499992580563],
              [-39.901666666983033, -8.88333332597216],
              [-39.901666666983033, -8.884166658464494],
              [-39.902499998576047, -8.884166658464494],
              [-39.902499999475367, -8.884999992755411],
              [-39.903333332866964, -8.884999992755411],
              [-39.903333333766284, -8.887499993829579],
              [-39.904999999650215, -8.887499992930259],
              [-39.905000000549535, -8.888333325422593],
              [-39.905833333041869, -8.888333325422593],
              [-39.905833334840509, -8.88916665881419],
              [-39.906666666433466, -8.88916665881419],
              [-39.906666665534146, -8.889999994004484],
              [-39.907499999825063, -8.889999993105164],
              [-39.907499999825063, -8.892499992380692],
              [-39.908333332317397, -8.892499991481372],
              [-39.908333333216717, -8.893333325772346],
              [-39.909166665708995, -8.893333325772346],
              [-39.909166665708995, -8.89499999255554],
              [-39.91, -8.89499999255554],
              [-39.91, -8.896666657540152],
              [-39.912499999275497, -8.896666658439472],
              [-39.912500001074136, -8.897499993629765],
              [-39.91333333266715, -8.897499992730445],
              [-39.913333330868454, -8.898333326122042],
              [-39.914999998551025, -8.898333326122042],
              [-39.914999998551025, -8.899166658614377],
              [-39.916666664434956, -8.899166658614377],
              [-39.916666665334276, -8.900833325397628],
              [-39.918333333016847, -8.900833325397628],
              [-39.918333333016847, -8.901666658789225],
              [-39.921666665684029, -8.901666657889905],
              [-39.921666666583349, -8.904166658964073],
              [-39.922499999075626, -8.904166658964073],
              [-39.922499999975003, -8.906666659138978],
              [-39.921666665684029, -8.906666659138978],
              [-39.921666666583349, -8.907499992530575],
              [-39.92333333246728, -8.907499992530575],
              [-39.92333333156796, -8.908333325922229],
              [-39.924166665858877, -8.908333325922229],
              [-39.924166666758197, -8.90999999270548],
              [-39.924999998351211, -8.90999999270548],
              [-39.924999997451891, -8.910833326097077],
              [-39.925833332642128, -8.910833326097077],
              [-39.925833331742808, -8.911666658589354],
              [-39.927499998526059, -8.911666657690034],
              [-39.927499999425379, -8.912499992880328],
              [-39.928333333716353, -8.912499992880328],
              [-39.928333332817033, -8.913333325372605],
              [-39.929166668007269, -8.913333325372605],
              [-39.92916666620863, -8.914999993954495],
              [-39.929999999600284, -8.914999993954495],
              [-39.929999998700964, -8.916666658039787],
              [-39.931666665484158, -8.916666658939107],
              [-39.931666665484158, -8.918333325722358],
              [-39.933333332267409, -8.918333325722358],
              [-39.933333334066049, -8.919166659113955],
              [-39.93499999994998, -8.919166659113955],
              [-39.93499999905066, -8.919999991606289],
              [-39.937499999225565, -8.919999993404929],
              [-39.937499997426869, -8.920833325897206],
              [-39.938333331717843, -8.920833325897206],
              [-39.938333331717843, -8.92166665838954],
              [-39.939166667807456, -8.92166665838954],
              [-39.939166666008759, -8.922499998975752],
              [-39.936666667632551, -8.922499992680457],
              [-39.936666667632551, -8.923333326971431],
              [-39.935833333341634, -8.923333326971431],
              [-39.935833332442314, -8.924166658564388],
              [-39.934166665659063, -8.924166658564388],
              [-39.934166665659063, -8.925833325347639],
              [-39.933333332267409, -8.925833325347639],
              [-39.93333333046877, -8.92749999303021],
              [-39.930833332092561, -8.92749999303021],
              [-39.930833332092561, -8.929166658914141],
              [-39.929999999600284, -8.929166658914141],
              [-39.930000000499604, -8.930833325697392],
              [-39.922499999975003, -8.930833325697392],
              [-39.922499999075626, -8.929999993205058],
              [-39.920833331393112, -8.929999993205058],
              [-39.920833331393112, -8.928333325522487],
              [-39.919999998900778, -8.928333325522487],
              [-39.920000000699417, -8.926666659638613],
              [-39.91749999872593, -8.926666657839917],
              [-39.91749999872593, -8.925833325347639],
              [-39.913333331767831, -8.925833325347639],
              [-39.913333330868454, -8.924999992855362],
              [-39.910833332492246, -8.924999992855362],
              [-39.910833333391565, -8.923333326971431],
              [-39.91, -8.923333326072111],
              [-39.909999999100648, -8.924166658564388],
              [-39.909166666608314, -8.924166658564388],
              [-39.909166666608314, -8.924999992855362],
              [-39.908333333216717, -8.924999992855362],
              [-39.908333331418078, -8.925833327146279],
              [-39.906666666433466, -8.92583332444832],
              [-39.906666667332786, -8.926666658739236],
              [-39.905833331243173, -8.926666658739236],
              [-39.905833333041869, -8.928333324623168],
              [-39.904166664459979, -8.928333325522487],
              [-39.904166665359298, -8.929999993205058],
              [-39.902500000374687, -8.929999991406419],
              [-39.902500000374687, -8.930833325697392],
              [-39.901666667882353, -8.930833325697392],
              [-39.901666666983033, -8.931666659088989],
              [-39.897500000924254, -8.931666659088989],
              [-39.897500000024934, -8.93333332587224],
              [-39.898333331617891, -8.93333332677156],
              [-39.898333333416588, -8.936666658539423],
              [-39.896666665734017, -8.936666658539423],
              [-39.896666666633337, -8.938333326221994],
              [-39.894166667357752, -8.938333326221994],
              [-39.894166665559112, -8.939999993005245],
              [-39.894166666458432, -8.940833325497522],
              [-39.895000000749405, -8.940833325497522],
              [-39.894999999850086, -8.941666659788496],
              [-39.894166665559112, -8.941666658889176],
              [-39.894166665559112, -8.942499993180093],
              [-39.893333331268195, -8.942499993180093],
              [-39.893333333066835, -8.94333332567237],
              [-39.89083333289193, -8.94333332567237],
              [-39.890833333791306, -8.944166659064024],
              [-39.889999999500333, -8.944166659963344],
              [-39.889999999500333, -8.945833325847275],
              [-39.891666668082223, -8.945833325847275],
              [-39.891666668082223, -8.946666659238872],
              [-39.892499998775861, -8.946666659238872],
              [-39.892500000574501, -8.948333326022123],
              [-39.894166667357752, -8.948333326022123],
              [-39.894166665559112, -8.947499992630526],
              [-39.894999998950766, -8.947499991731206],
              [-39.894999998950766, -8.948333326022123],
              [-39.896666667532656, -8.948333325122803],
              [-39.896666667532656, -8.949166658514457],
              [-39.895833332342363, -8.949166658514457],
              [-39.895833331443043, -8.953333325472556],
              [-39.897500000024934, -8.953333325472556],
              [-39.897499998226294, -8.952499992980222],
              [-39.898333334315907, -8.952499992980222],
              [-39.898333331617891, -8.951666658689305],
              [-39.899166666808185, -8.951666658689305],
              [-39.899166668606824, -8.950833326197028],
              [-39.899999998401142, -8.950833326197028],
              [-39.900000001099102, -8.951666658689305],
              [-39.901666666983033, -8.951666658689305],
              [-39.901666667882353, -8.950833326197028],
              [-39.902500001274007, -8.950833327096348],
              [-39.902500000374687, -8.949999992805374],
              [-39.903333331967644, -8.949999991906054],
              [-39.903333332866964, -8.949166659413777],
              [-39.905833333041869, -8.949166658514457],
              [-39.905833333941189, -8.949999992805374],
              [-39.906666665534146, -8.949999992805374],
              [-39.906666665534146, -8.950833327096348],
              [-39.907499998925744, -8.950833326197028],
              [-39.907499999825063, -8.951666658689305],
              [-39.908333333216717, -8.951666658689305],
              [-39.908333331418078, -8.952499992980222],
              [-39.909166665708995, -8.952499992980222],
              [-39.909166666608314, -8.953333326371876],
              [-39.911666665883899, -8.953333325472556],
              [-39.911666667682539, -8.954166658864153],
              [-39.91333333446579, -8.954166658864153],
              [-39.91333333266715, -8.953333325472556],
              [-39.914166666958067, -8.953333325472556],
              [-39.914166666058748, -8.951666658689305],
              [-39.914999997651705, -8.951666657789985],
              [-39.914999999450345, -8.952499992980222],
              [-39.918333331218207, -8.952499992980222],
              [-39.918333333916166, -8.950833326197028],
              [-39.919999998900778, -8.950833326197028],
              [-39.919999998900778, -8.952499992980222],
              [-39.920833334091071, -8.952499992980222],
              [-39.920833331393112, -8.951666657789985],
              [-39.922500000874322, -8.951666659588625],
              [-39.922499999075626, -8.950833326197028],
              [-39.925833332642128, -8.950833327096348],
              [-39.925833332642128, -8.949999992805374],
              [-39.926666666933102, -8.949999992805374],
              [-39.926666666933102, -8.949166658514457],
              [-39.931666667282798, -8.949166658514457],
              [-39.931666667282798, -8.949999993704694],
              [-39.93333333136809, -8.949999991906054],
              [-39.93333333136809, -8.949166658514457],
              [-39.934166665659063, -8.949166658514457],
              [-39.934166666558383, -8.948333326022123],
              [-39.935833331542995, -8.948333326022123],
              [-39.935833331542995, -8.945833325847275],
              [-39.939166666908136, -8.945833325847275],
              [-39.939166666908136, -8.944999992455621],
              [-39.943333333866235, -8.944999993354941],
              [-39.943333332966915, -8.944166659064024],
              [-39.944999999750166, -8.944166659064024],
              [-39.944999998850847, -8.942499991381453],
              [-39.948333332417292, -8.942499993180093],
              [-39.948333331517972, -8.94333332567237],
              [-39.949999998301223, -8.943333328370386],
              [-39.950000000099863, -8.944166659064024],
              [-39.949166665808946, -8.944166659064024],
              [-39.949166666708265, -8.946666657440232],
              [-39.946666666533417, -8.946666660138192],
              [-39.946666667432737, -8.947499992630526],
              [-39.945833331343124, -8.947499992630526],
              [-39.945833332242444, -8.948333326022123],
              [-39.945000000649486, -8.948333326022123],
              [-39.944999999750166, -8.949166658514457],
              [-39.942499997776622, -8.949166657615137],
              [-39.942499998675942, -8.949999992805374],
              [-39.940833331892691, -8.949999992805374],
              [-39.94083333279201, -8.950833326197028],
              [-39.940000000299733, -8.950833326197028],
              [-39.939999998501094, -8.951666657789985],
              [-39.939166666908136, -8.951666658689305],
              [-39.939166667807456, -8.952499992980222],
              [-39.938333332617162, -8.952499992980222],
              [-39.938333331717843, -8.954166658864153],
              [-39.937500001024205, -8.954166658864153],
              [-39.937500001024205, -8.958333326721629],
              [-39.936666665833911, -8.958333325822309],
              [-39.936666666733231, -8.959999991706184],
              [-39.935833331542995, -8.959999992605503],
              [-39.935833331542995, -8.960833325997157],
              [-39.9350000008493, -8.960833325997157],
              [-39.93499999905066, -8.962499992780408],
              [-39.934166665659063, -8.962499992780408],
              [-39.934166665659063, -8.96416665866434],
              [-39.933333332267409, -8.96416665866434],
              [-39.933333332267409, -8.964999992955256],
              [-39.932499999775132, -8.964999992955256],
              [-39.932499998875812, -8.965833325447591],
              [-39.929999998700964, -8.965833324548271],
              [-39.930000000499604, -8.966666658839188],
              [-39.928333332817033, -8.966666658839188],
              [-39.928333332817033, -8.967499993130104],
              [-39.927500000324699, -8.967499993130104],
              [-39.92749999762674, -8.968333324723119],
              [-39.925833331742808, -8.968333325622439],
              [-39.925833333541448, -8.969999994204329],
              [-39.92500000104917, -8.96999999240569],
              [-39.924999999250531, -8.970833325797287],
              [-39.9233333333666, -8.970833325797287],
              [-39.9233333333666, -8.972499992580538],
              [-39.922499999975003, -8.972499991681218],
              [-39.922499999975003, -8.973333325972192],
              [-39.921666665684029, -8.973333325972192],
              [-39.921666666583349, -8.97583332614704],
              [-39.922499999075626, -8.9758333243484],
              [-39.922499999075626, -8.979166658814222],
              [-39.92333333156796, -8.979166658814222],
              [-39.9233333333666, -8.980833325597473],
              [-39.924166665858877, -8.980833325597473],
              [-39.924166666758197, -8.98166665898907],
              [-39.92333333246728, -8.98166665898907],
              [-39.923333334265919, -8.984166659163918],
              [-39.922500000874322, -8.984166659163918],
              [-39.922499999975003, -8.985833325947169],
              [-39.921666667482668, -8.985833325947169],
              [-39.921666666583349, -8.986666658439503],
              [-39.920833334091071, -8.986666658439503],
              [-39.920833332292432, -8.98749999273042],
              [-39.922500000874322, -8.98749999273042],
              [-39.922499999075626, -8.986666660238143],
              [-39.924166665858877, -8.986666658439503],
              [-39.924166666758197, -8.985833325947169],
              [-39.924999999250531, -8.985833325947169],
              [-39.924999999250531, -8.984999992555572],
              [-39.926666666933102, -8.984999992555572],
              [-39.926666667832421, -8.984166659163918],
              [-39.928333331917713, -8.984166657365279],
              [-39.928333333716353, -8.984999992555572],
              [-39.929999999600284, -8.984999991656252],
              [-39.930000000499604, -8.986666658439503],
              [-39.928333333716353, -8.986666658439503],
              [-39.928333331018393, -8.98749999273042],
              [-39.927499999425379, -8.98749999273042],
              [-39.927499998526059, -8.988333326122074],
              [-39.926666666933102, -8.988333326122074],
              [-39.926666664235142, -8.989999993804645],
              [-39.925833332642128, -8.989999992905325],
              [-39.925833332642128, -8.992499993080173],
              [-39.924999998351211, -8.992499993080173],
              [-39.924999999250531, -8.994999992355702],
              [-39.924999998351211, -8.995833325747356],
              [-39.927499998526059, -8.995833325747356],
              [-39.927499998526059, -8.996666659138953],
              [-39.92916666530931, -8.996666659138953],
              [-39.92916666530931, -8.997499992530607],
              [-39.929999998700964, -8.997499992530607],
              [-39.930000000499604, -9.002499992880303],
              [-39.930833333891201, -9.002499992880303],
              [-39.930833332991881, -9.004166658764234],
              [-39.931666665484158, -9.004166658764234],
              [-39.931666666383478, -9.004999993954527],
              [-39.932500000674452, -9.004999993055208],
              [-39.932499998875812, -9.005833325547485],
              [-39.933333332267409, -9.005833325547485],
              [-39.933333332267409, -9.006666658939139],
              [-39.934166667457703, -9.006666658939139],
              [-39.934166666558383, -9.008333325722333],
              [-39.93499999994998, -9.008333325722333],
              [-39.93499999994998, -9.009999992505584],
              [-39.935833331542995, -9.009999991606264],
              [-39.935833330643675, -9.011666658389515],
              [-39.937499998326246, -9.011666658389515],
              [-39.937499998326246, -9.013333326072086],
              [-39.938333331717843, -9.013333326072086],
              [-39.938333333516482, -9.01416665856442],
              [-39.939166666008759, -9.01416666036306],
              [-39.939166667807456, -9.014999991956017],
              [-39.939999999400413, -9.014999992855337],
              [-39.939999998501094, -9.015833324448295],
              [-39.940000000299733, -9.016666657839949],
              [-39.94083333279201, -9.016666658739268],
              [-39.94083333369133, -9.019166658914116],
              [-39.941666667082984, -9.019166658914116],
              [-39.941666667982304, -9.022499992480618],
              [-39.939999998501094, -9.022499992480618],
              [-39.939999998501094, -9.02583332604712],
              [-39.940833331892691, -9.02583332604712],
              [-39.94083333369133, -9.026666658539398],
              [-39.941666667982304, -9.026666658539398],
              [-39.941666664385025, -9.027499992830371],
              [-39.948333333316612, -9.027499992830371],
              [-39.948333332417292, -9.026666658539398],
              [-39.949166665808946, -9.026666657640078],
              [-39.949166667607585, -9.027499992830371],
              [-39.950000000999182, -9.027499992830371],
              [-39.950000000099863, -9.029166658714303],
              [-39.950833332592197, -9.029166658714303],
              [-39.950833331692877, -9.030833325497554],
              [-39.951666667782433, -9.030833325497554],
              [-39.951666666883114, -9.031666658889151],
              [-39.952500000274767, -9.031666658889151],
              [-39.952499999375448, -9.032499994079444],
              [-39.953333333666365, -9.032499994079444],
              [-39.953333332767045, -9.033333326571721],
              [-39.955833332042573, -9.033333325672402],
              [-39.955833333841213, -9.034166659063999],
              [-39.956666665434227, -9.034166659063999],
              [-39.956666667232867, -9.034999992455653],
              [-39.956666666333547, -9.03583332584725],
              [-39.957499998825824, -9.03583332584725],
              [-39.957500000624464, -9.036666657440264],
              [-39.958333334016118, -9.036666659238904],
              [-39.958333331318158, -9.037499991731181],
              [-39.956666665434227, -9.037499992630501],
              [-39.956666666333547, -9.039166658514432],
              [-39.955833332042573, -9.039166657615112],
              [-39.955833333841213, -9.044999993155102],
              [-39.956666667232867, -9.044999993155102],
              [-39.956666666333547, -9.045833325647436],
              [-39.958333334915437, -9.045833325647436],
              [-39.958333331318158, -9.046666659039033],
              [-39.959166665609075, -9.046666659039033],
              [-39.959166667407715, -9.044999994054422],
              [-39.960833333291646, -9.044999993155102],
              [-39.960833333291646, -9.045833326546756],
              [-39.963333332567174, -9.045833325647436],
              [-39.963333332567174, -9.046666659039033],
              [-39.970000000599498, -9.046666659039033],
              [-39.969999999700178, -9.045833326546756],
              [-39.971666666483429, -9.045833325647436],
              [-39.971666666483429, -9.047499992430687],
              [-39.972499999875026, -9.047499992430687],
              [-39.972499999875026, -9.048333325822284],
              [-39.974166666658277, -9.048333326721604],
              [-39.974166665758958, -9.047499992430687],
              [-39.978333333616433, -9.047499994229327],
              [-39.981666666283559, -9.047499992430687],
              [-39.981666668082198, -9.048333325822284],
              [-39.982499998775893, -9.048333325822284],
              [-39.982499998775893, -9.049166659213881],
              [-39.984166666458464, -9.049166659213881],
              [-39.984166666458464, -9.049999992605535],
              [-39.986666666633312, -9.049999992605535],
              [-39.986666666633312, -9.049166659213881],
              [-39.988333333416563, -9.049166659213881],
              [-39.988333331617923, -9.048333324922964],
              [-39.990833331792771, -9.048333325822284],
              [-39.990833332692091, -9.047499992430687],
              [-39.991666666983065, -9.047499991531311],
              [-39.991666666083745, -9.046666659938353],
              [-39.992499998576022, -9.046666659039033],
              [-39.992499998576022, -9.045833325647436],
              [-39.991666666983065, -9.045833325647436],
              [-39.991666666083745, -9.044999993155102],
              [-39.992499999475342, -9.044999993155102],
              [-39.992499999475342, -9.044166658864185],
              [-39.993333333766316, -9.044166659763505],
              [-39.993333333766316, -9.044999991356462],
              [-39.994166668057233, -9.044999991356462],
              [-39.994166668057233, -9.046666659039033],
              [-39.995833331243205, -9.046666659039033],
              [-39.995833333041844, -9.047499992430687],
              [-39.998333331418053, -9.047499992430687],
              [-39.998333332317372, -9.048333325822284],
              [-40.004999999450376, -9.048333325822284],
              [-40.004999998551057, -9.049166659213881],
              [-40.006666666233627, -9.049166660113201],
              [-40.006666668032267, -9.049999992605535],
              [-40.009166666408476, -9.049999991706215],
              [-40.009166666408476, -9.049166657415242],
              [-40.010833332292407, -9.049166660113201],
              [-40.010833332292407, -9.049999992605535],
              [-40.014166668556868, -9.049999992605535],
              [-40.014166665858909, -9.049166659213881],
              [-40.017499998526091, -9.049166659213881],
              [-40.017499999425411, -9.048333325822284],
              [-40.018333334615647, -9.048333324922964],
              [-40.018333331917688, -9.044999991356462],
              [-40.017499999425411, -9.044999993155102],
              [-40.017500000324731, -9.044166658864185],
              [-40.019166666208662, -9.044166658864185],
              [-40.019166664409966, -9.043333325472531],
              [-40.02166666638351, -9.043333325472531],
              [-40.02166666638351, -9.044166658864185],
              [-40.022499998875787, -9.044166658864185],
              [-40.022499998875787, -9.043333325472531],
              [-40.024999999950012, -9.043333325472531],
              [-40.024999999050692, -9.045833325647436],
              [-40.029999997601749, -9.045833325647436],
              [-40.029999999400388, -9.046666659039033],
              [-40.030833333691362, -9.046666658139713],
              [-40.030833331892723, -9.047499992430687],
              [-40.031666667982279, -9.047499992430687],
              [-40.03166666528432, -9.048333325822284],
              [-40.030833332792042, -9.048333325822284],
              [-40.030833333691362, -9.051666658489466],
              [-40.033333332067571, -9.051666657590147],
              [-40.03333333296689, -9.052499992780383],
              [-40.034166666358544, -9.052499992780383],
              [-40.034166665459225, -9.053333326172037],
              [-40.034999998850822, -9.053333326172037],
              [-40.034999997951502, -9.054166658664315],
              [-40.035833332242419, -9.054166658664315],
              [-40.035833334041115, -9.054999992955288],
              [-40.037500000824309, -9.054999992055969],
              [-40.03749999902567, -9.054166658664315],
              [-40.039166665808921, -9.054166658664315],
              [-40.039166665808921, -9.053333326172037],
              [-40.039999998301255, -9.053333326172037],
              [-40.039999998301255, -9.052499993679703],
              [-40.040833333491491, -9.052499993679703],
              [-40.040833333491491, -9.050833325997132],
              [-40.042500000274742, -9.050833325097813],
              [-40.042499999375423, -9.051666658489466],
              [-40.044166667957313, -9.051666661187426],
              [-40.044999999550271, -9.051666658489466],
              [-40.045000001348967, -9.052499993679703],
              [-40.047499998825856, -9.052499992780383],
              [-40.047500000624495, -9.051666657590147],
              [-40.049166666508427, -9.051666659388786],
              [-40.049166665609107, -9.052499992780383],
              [-40.054166667757443, -9.052499992780383],
              [-40.054166666858123, -9.050833325997132],
              [-40.054999999350457, -9.050833325997132],
              [-40.054999998451137, -9.048333326721604],
              [-40.055833333641374, -9.048333325822284],
              [-40.055833332742054, -9.046666658139713],
              [-40.055000000249777, -9.046666659039033],
              [-40.054999998451137, -9.044999991356462],
              [-40.054166667757443, -9.044999993155102],
              [-40.054166665059483, -9.044166657964865],
              [-40.054999998451137, -9.044166658864185],
              [-40.055000000249777, -9.042499992980254],
              [-40.055833331842734, -9.042499992980254],
              [-40.055833331842734, -9.043333325472531],
              [-40.056666667033028, -9.043333325472531],
              [-40.056666665234388, -9.044166658864185],
              [-40.06000000059953, -9.044166658864185],
              [-40.059999997901514, -9.044999993155102],
              [-40.060833331293168, -9.044999994054422],
              [-40.060833333991127, -9.045833324748116],
              [-40.061666666483404, -9.045833324748116],
              [-40.061666666483404, -9.046666659039033],
              [-40.06583333254224, -9.046666659039033],
              [-40.065833331642921, -9.047499992430687],
              [-40.066666667732477, -9.047499992430687],
              [-40.066666666833157, -9.048333325822284],
              [-40.068333332717089, -9.048333325822284],
              [-40.068333332717089, -9.047499992430687],
              [-40.072499998775868, -9.047499992430687],
              [-40.072499998775868, -9.048333325822284],
              [-40.075000000749412, -9.048333325822284],
              [-40.074999999850093, -9.049166659213881],
              [-40.07583333234237, -9.049166659213881],
              [-40.07583333234237, -9.049999992605535],
              [-40.076666665733967, -9.049999992605535],
              [-40.076666665733967, -9.050833325997132],
              [-40.07750000092426, -9.050833325997132],
              [-40.077500000024941, -9.051666658489466],
              [-40.078333331617898, -9.051666658489466],
              [-40.079166665908872, -9.051666657590147],
              [-40.079166667707511, -9.053333326172037],
              [-40.078333331617898, -9.053333326172037],
              [-40.078333333416538, -9.054999992955288],
              [-40.076666667532663, -9.054999994753928],
              [-40.076666665733967, -9.055833325447566],
              [-40.077500000024941, -9.055833325447566],
              [-40.077500000024941, -9.056666658839163],
              [-40.078333332517218, -9.056666658839163],
              [-40.078333331617898, -9.058333325622414],
              [-40.082500000374694, -9.058333324723094],
              [-40.082499998576054, -9.057499994029456],
              [-40.083333332866971, -9.057499993130136],
              [-40.083333333766291, -9.056666658839163],
              [-40.084999999650222, -9.056666658839163],
              [-40.084999999650222, -9.055833324548246],
              [-40.086666666433473, -9.055833325447566],
              [-40.086666667332793, -9.056666658839163],
              [-40.089166666608321, -9.056666658839163],
              [-40.089166665709001, -9.054166658664315],
              [-40.089999999100655, -9.054166658664315],
              [-40.09, -9.053333326172037],
              [-40.090833332492252, -9.053333326172037],
              [-40.090833331592933, -9.051666658489466],
              [-40.09333333356642, -9.051666658489466],
              [-40.0933333326671, -9.050833325997132],
              [-40.094166666958074, -9.050833325997132],
              [-40.094166666058754, -9.049999992605535],
              [-40.0933333326671, -9.049999992605535],
              [-40.09333333356642, -9.047499992430687],
              [-40.094166666058754, -9.047499992430687],
              [-40.094166666958074, -9.045833325647436],
              [-40.095000000349671, -9.045833325647436],
              [-40.094999999450351, -9.044166657964865],
              [-40.094166666058754, -9.044166658864185],
              [-40.094166666958074, -9.039166659413752],
              [-40.0933333326671, -9.039166658514432],
              [-40.0933333326671, -9.037499992630501],
              [-40.092499998376184, -9.037499992630501],
              [-40.092500001074143, -9.036666659238904],
              [-40.093333331767781, -9.036666659238904],
              [-40.09333333356642, -9.03583332584725],
              [-40.094166666958074, -9.03583332584725],
              [-40.094166666958074, -9.034999992455653],
              [-40.094999999450351, -9.034999992455653],
              [-40.095000000349671, -9.034166659063999],
              [-40.096666666233602, -9.034166658164679],
              [-40.096666664434963, -9.032499993180068],
              [-40.097499998725937, -9.032499993180068],
              [-40.097499999625256, -9.031666658889151],
              [-40.098333331218214, -9.031666658889151],
              [-40.098333333916173, -9.028333324423329],
              [-40.097499998725937, -9.028333326221968],
              [-40.097499999625256, -9.027499992830371],
              [-40.098333333916173, -9.027499992830371],
              [-40.098333333016853, -9.026666658539398],
              [-40.099166667307827, -9.026666658539398],
              [-40.099166667307827, -9.02583332604712],
              [-40.099999998900785, -9.025833325147801],
              [-40.100000000699424, -9.023333325872272],
              [-40.100833334091078, -9.023333325872272],
              [-40.100833332292382, -9.022499992480618],
              [-40.098333331218214, -9.022499992480618],
              [-40.098333333916173, -9.018333325522519],
              [-40.099166665509188, -9.018333325522519],
              [-40.099166666408507, -9.017499993030185],
              [-40.098333333916173, -9.017499993030185],
              [-40.098333331218214, -9.015833324448295],
              [-40.097499999625256, -9.015833325347614],
              [-40.097499999625256, -9.014999992855337],
              [-40.096666665334283, -9.014999992855337],
              [-40.096666665334283, -9.01416665856442],
              [-40.094166666058754, -9.014166657665101],
              [-40.094166666958074, -9.011666658389515],
              [-40.093333331767781, -9.011666658389515],
              [-40.093333331767781, -9.010833325897238],
              [-40.092499999275503, -9.010833325897238],
              [-40.092500001074143, -9.009999992505584],
              [-40.091666665883906, -9.009999992505584],
              [-40.091666664984587, -9.009166659113987],
              [-40.090833331592933, -9.009166659113987],
              [-40.090833332492252, -9.008333325722333],
              [-40.09, -9.008333326621653],
              [-40.089999999100655, -9.007499995928015],
              [-40.090833333391572, -9.007499993230056],
              [-40.090833333391572, -9.006666658039762],
              [-40.094166666058754, -9.006666658939139],
              [-40.094166666958074, -9.004166658764234],
              [-40.094999999450351, -9.004166658764234],
              [-40.095000001248991, -9.002499992880303],
              [-40.095833331942686, -9.002499992880303],
              [-40.095833332842005, -9.001666659488706],
              [-40.096666666233602, -9.001666658589386],
              [-40.096666668931562, -8.999999992705455],
              [-40.099999998900785, -8.999999992705455],
              [-40.099999999800104, -8.999166658414481],
              [-40.102499999974953, -8.999166658414481],
              [-40.102499999075633, -8.998333325922204],
              [-40.103333333366606, -8.998333325922204],
              [-40.103333331567967, -8.996666659138953],
              [-40.104166665858884, -8.996666660038272],
              [-40.104166665858884, -8.995833325747356],
              [-40.104999999250538, -8.995833325747356],
              [-40.105000001049177, -8.994999992355702],
              [-40.105833333541455, -8.994999991456382],
              [-40.105833331742815, -8.99333332647177],
              [-40.107499999425386, -8.993333325572451],
              [-40.107500000324706, -8.989166658614351],
              [-40.109166666208637, -8.989166659513671],
              [-40.109166665309317, -8.988333326122074],
              [-40.109999999600234, -8.988333326122074],
              [-40.109999999600234, -8.98749999273042],
              [-40.112499999775139, -8.98749999273042],
              [-40.112499998875819, -8.988333326122074],
              [-40.113333334066056, -8.988333327021394],
              [-40.113333334066056, -8.98749999273042],
              [-40.11416666745771, -8.98749999362974],
              [-40.11416666655839, -8.983333325772321],
              [-40.11416666565907, -8.98166665898907],
              [-40.113333332267416, -8.98166665898907],
              [-40.113333331368096, -8.979999993105139],
              [-40.112500000674459, -8.979999993105139],
              [-40.112499999775139, -8.979166658814222],
              [-40.111666666383485, -8.979166659713542],
              [-40.111666665484165, -8.978333325422568],
              [-40.110833332092568, -8.978333327221208],
              [-40.110833330293929, -8.979166657914902],
              [-40.109999999600234, -8.979166658814222],
              [-40.11000000139893, -8.978333324523248],
              [-40.109166666208637, -8.978333325422568],
              [-40.109166665309317, -8.979166658814222],
              [-40.105833333541455, -8.979166657914902],
              [-40.105833332642135, -8.979999993105139],
              [-40.101666666583355, -8.979999993105139],
              [-40.101666667482675, -8.979166659713542],
              [-40.100833331393062, -8.979166657914902],
              [-40.100833332292382, -8.974166658464469],
              [-40.101666667482675, -8.974166658464469],
              [-40.101666666583355, -8.970833326696606],
              [-40.100833332292382, -8.970833325797287],
              [-40.100833332292382, -8.969166659014036],
              [-40.099999999800104, -8.969166659014036],
              [-40.100000001598744, -8.968333325622439],
              [-40.099166666408507, -8.968333325622439],
              [-40.099166665509188, -8.966666657939868],
              [-40.098333333016853, -8.966666658839188],
              [-40.098333333916173, -8.964999993854576],
              [-40.098333333016853, -8.96416665866434],
              [-40.099166666408507, -8.96416665866434],
              [-40.099166667307827, -8.959999992605503],
              [-40.099999998900785, -8.959999992605503],
              [-40.100000000699424, -8.957499992430655],
              [-40.100833334091078, -8.957499992430655],
              [-40.100833334091078, -8.956666659039058],
              [-40.101666666583355, -8.956666659039058],
              [-40.101666667482675, -8.955833325647404],
              [-40.102499999974953, -8.955833325647404],
              [-40.102499999075633, -8.954999993155127],
              [-40.104166665858884, -8.954999991356487],
              [-40.104166666758204, -8.953333325472556],
              [-40.104999999250538, -8.953333325472556],
              [-40.104999998351218, -8.952499992980222],
              [-40.108333331917663, -8.952499992980222],
              [-40.108333331018343, -8.951666658689305],
              [-40.109166665309317, -8.951666658689305],
              [-40.109166665309317, -8.950833326197028],
              [-40.109999998700914, -8.950833327096348],
              [-40.109999998700914, -8.949999992805374],
              [-40.111666665484165, -8.949999992805374],
              [-40.111666666383485, -8.949166658514457],
              [-40.114999999050667, -8.949166658514457],
              [-40.115000000849307, -8.948333326022123],
              [-40.115833332442321, -8.948333326022123],
              [-40.115833331543001, -8.947499993529846],
              [-40.116666665833918, -8.947499993529846],
              [-40.116666666733238, -8.946666659238872],
              [-40.118333332617169, -8.946666657440232],
              [-40.118333331717849, -8.945833325847275],
              [-40.120833331892698, -8.945833325847275],
              [-40.120833331892698, -8.946666659238872],
              [-40.123333332966922, -8.946666657440232],
              [-40.123333332966922, -8.947499992630526],
              [-40.124999998850797, -8.947499991731206],
              [-40.124999999750173, -8.948333326022123],
              [-40.125833332242451, -8.948333326022123],
              [-40.125833332242451, -8.949166658514457],
              [-40.128333332417299, -8.949166662111736],
              [-40.129166666708272, -8.949166658514457],
              [-40.129166666708272, -8.949999992805374],
              [-40.130000000099869, -8.949999992805374],
              [-40.12999999830123, -8.950833326197028],
              [-40.130833331692884, -8.950833326197028],
              [-40.130833332592204, -8.951666657789985],
              [-40.13166666688312, -8.951666658689305],
              [-40.13166666688312, -8.952499992980222],
              [-40.132499998476078, -8.952499992980222],
              [-40.132499998476078, -8.953333324573236],
              [-40.1358333329419, -8.953333325472556],
              [-40.135833333841219, -8.952499992980222],
              [-40.139999999000736, -8.952499992980222],
              [-40.139999999900056, -8.953333325472556],
              [-40.140833332392333, -8.953333325472556],
              [-40.140833332392333, -8.952499992980222],
              [-40.142499998276264, -8.952499992980222],
              [-40.142499998276264, -8.954166658864153],
              [-40.143333332567181, -8.954166657964834],
              [-40.143333332567181, -8.954999993155127],
              [-40.144166665059515, -8.954999993155127],
              [-40.144166666858155, -8.955833325647404],
              [-40.144999998451112, -8.955833325647404],
              [-40.144999998451112, -8.959999992605503],
              [-40.145833332742086, -8.959999992605503],
              [-40.145833331842766, -8.960833325997157],
              [-40.147499997726698, -8.960833325997157],
              [-40.147499999525337, -8.959999992605503],
              [-40.150833331293143, -8.959999991706184],
              [-40.150833333091782, -8.960833325997157],
              [-40.151666665584116, -8.960833325997157],
              [-40.151666666483436, -8.962499992780408],
              [-40.152499998076394, -8.962499992780408],
              [-40.152499998975713, -8.963333326172005],
              [-40.155833332542215, -8.963333324373366],
              [-40.155833332542215, -8.96416665866434],
              [-40.156666665933869, -8.96416665866434],
              [-40.156666665933869, -8.964999992955256],
              [-40.159166667008037, -8.964999992955256],
              [-40.159166667008037, -8.966666658839188],
              [-40.159999998600995, -8.966666657939868],
              [-40.159999999500315, -8.968333325622439],
              [-40.160833331992649, -8.968333325622439],
              [-40.160833333791288, -8.969166659913355],
              [-40.161666665384246, -8.969166659014036],
              [-40.161666666283566, -8.96999999240569],
              [-40.162499999675219, -8.96999999240569],
              [-40.162499999675219, -8.970833325797287],
              [-40.16416666645847, -8.970833325797287],
              [-40.164166665559151, -8.971666659188941],
              [-40.164999999850068, -8.971666659188941],
              [-40.164999998950748, -8.973333325972192],
              [-40.165833332342345, -8.973333325972192],
              [-40.165833332342345, -8.974999992755443],
              [-40.16833333251725, -8.974999992755443],
              [-40.16833333161793, -8.97583332614704],
              [-40.169999998401181, -8.97583332614704],
              [-40.169999999300501, -8.974999992755443],
              [-40.170833332692098, -8.974999992755443],
              [-40.170833332692098, -8.974166658464469],
              [-40.173333331967626, -8.974166658464469],
              [-40.173333332867003, -8.973333325972192],
              [-40.175833331243211, -8.973333325972192],
              [-40.175833333041851, -8.972499992580538],
              [-40.176666666433448, -8.972499992580538],
              [-40.176666666433448, -8.973333325972192],
              [-40.177499999825102, -8.973333325972192],
              [-40.177499998026462, -8.974166659363789],
              [-40.178333332317379, -8.974166658464469],
              [-40.178333332317379, -8.974999992755443],
              [-40.179166666608353, -8.974999992755443],
              [-40.179166666608353, -8.97583332614704],
              [-40.17999999910063, -8.97583332614704],
              [-40.17999999910063, -8.976666658639317],
              [-40.180833331592908, -8.976666658639317],
              [-40.180833332492284, -8.977499992930291],
              [-40.183333331767813, -8.977499992930291],
              [-40.183333332667132, -8.978333325422568],
              [-40.18583333284198, -8.978333325422568],
              [-40.18583333284198, -8.979999993105139],
              [-40.186666666233634, -8.979999993105139],
              [-40.186666666233634, -8.980833325597473],
              [-40.187499999625231, -8.980833325597473],
              [-40.187499998725912, -8.98166665898907],
              [-40.188333331218246, -8.98166665898907],
              [-40.188333333016885, -8.982499992380724],
              [-40.189166664609843, -8.982499992380724],
              [-40.189166667307802, -8.984999992555572],
              [-40.18999999890076, -8.984999992555572],
              [-40.18999999890076, -8.985833325947169],
              [-40.19166666748265, -8.985833325947169],
              [-40.192499999075665, -8.985833325947169],
              [-40.192499999075665, -8.984999992555572],
              [-40.194166666758235, -8.984999992555572],
              [-40.194166666758235, -8.984166657365279],
              [-40.196666666033764, -8.984166659163918],
              [-40.196666666933083, -8.983333325772321],
              [-40.198333331917695, -8.983333325772321],
              [-40.198333331917695, -8.98166665898907],
              [-40.199166666208612, -8.98166665898907],
              [-40.199166666208612, -8.979166658814222],
              [-40.200833332991863, -8.979166658814222],
              [-40.200833332092543, -8.977499992930291],
              [-40.201666666383517, -8.977499992930291],
              [-40.201666665484197, -8.974166658464469],
              [-40.200833332991863, -8.974166658464469],
              [-40.200833332991863, -8.972499992580538],
              [-40.201666666383517, -8.972499992580538],
              [-40.201666665484197, -8.970833325797287],
              [-40.202499999775114, -8.970833325797287],
              [-40.202500000674434, -8.969166659014036],
              [-40.203333332267448, -8.969166659014036],
              [-40.203333332267448, -8.968333325622439],
              [-40.209166666008798, -8.968333324723119],
              [-40.209166666008798, -8.967499994029481],
              [-40.210833331892729, -8.967499993130104],
              [-40.210833331892729, -8.966666658839188],
              [-40.211666665284326, -8.966666658839188],
              [-40.211666665284326, -8.965833324548271],
              [-40.2124999995753, -8.96583332634691],
              [-40.21250000047462, -8.963333326172005],
              [-40.211666667082966, -8.963333326172005],
              [-40.211666667082966, -8.959999992605503],
              [-40.210833331892729, -8.959999992605503],
              [-40.210833331892729, -8.957499992430655],
              [-40.21249999867598, -8.957499992430655],
              [-40.2124999995753, -8.955833325647404],
              [-40.214166666358551, -8.955833325647404],
              [-40.214166665459175, -8.954166658864153],
              [-40.216666666533399, -8.954166658864153],
              [-40.216666665634079, -8.951666658689305],
              [-40.219166665808928, -8.951666659588625],
              [-40.219166666708247, -8.94333332657169],
              [-40.216666665634079, -8.94333332567237],
              [-40.216666665634079, -8.942499993180093],
              [-40.215833334041065, -8.942499993180093],
              [-40.215833332242426, -8.941666659788496],
              [-40.214999999750148, -8.941666657989856],
              [-40.214999999750148, -8.939999993005245],
              [-40.214166666358551, -8.939999993005245],
              [-40.214166666358551, -8.939166658714271],
              [-40.213333332966897, -8.939166658714271],
              [-40.213333332966897, -8.936666658539423],
              [-40.2124999995753, -8.936666658539423],
              [-40.2124999995753, -8.924999992855362],
              [-40.213333332966897, -8.924999991956042],
              [-40.213333332966897, -8.924166658564388],
              [-40.2124999995753, -8.924166658564388],
              [-40.2124999995753, -8.919999993404929],
              [-40.211666667082966, -8.919999992505609],
              [-40.211666667082966, -8.919166660013275],
              [-40.2124999995753, -8.919166659113955],
              [-40.2124999995753, -8.917499993230081],
              [-40.213333332966897, -8.917499993230081],
              [-40.213333332966897, -8.916666658939107],
              [-40.214999999750148, -8.916666658939107],
              [-40.214999999750148, -8.91583332554751],
              [-40.215833332242426, -8.91583332554751],
              [-40.215833332242426, -8.914166658764259],
              [-40.216666666533399, -8.914166658764259],
              [-40.216666666533399, -8.912499992880328],
              [-40.217499999924996, -8.912499992880328],
              [-40.217499999924996, -8.908333326821548],
              [-40.216666666533399, -8.908333325922229],
              [-40.216666665634079, -8.905833325747324],
              [-40.215833332242426, -8.905833325747324],
              [-40.215833332242426, -8.904999992355727],
              [-40.214999999750148, -8.904999992355727],
              [-40.214999999750148, -8.904166658964073],
              [-40.214166666358551, -8.904166658964073],
              [-40.214166666358551, -8.903333325572476],
              [-40.213333332966897, -8.903333325572476],
              [-40.213333332966897, -8.901666658789225],
              [-40.2124999995753, -8.901666658789225],
              [-40.21250000047462, -8.900833325397628],
              [-40.213333332966897, -8.900833327196267],
              [-40.213333332067577, -8.899999992905293],
              [-40.214166665459175, -8.899999992905293],
              [-40.214166666358551, -8.894166659163943],
              [-40.213333332966897, -8.894166657365304],
              [-40.213333332966897, -8.888333325422593],
              [-40.2124999995753, -8.888333325422593],
              [-40.21249999867598, -8.885833326147065],
              [-40.211666667082966, -8.885833326147065],
              [-40.211666667082966, -8.884999991856091],
              [-40.2124999995753, -8.884999992755411],
              [-40.2124999995753, -8.88333332597216],
              [-40.213333332966897, -8.88333332597216],
              [-40.213333332966897, -8.881666659188909],
              [-40.214166667257871, -8.881666659188909],
              [-40.214166666358551, -8.880833325797312],
              [-40.213333332966897, -8.880833325797312],
              [-40.213333332966897, -8.879999992405658],
              [-40.21250000047462, -8.879999992405658],
              [-40.2124999995753, -8.876666658839213],
              [-40.214166666358551, -8.876666658839213],
              [-40.214166666358551, -8.875833325447559],
              [-40.215000000649468, -8.875833325447559],
              [-40.214999999750148, -8.874999992955281],
              [-40.214166666358551, -8.874999993854601],
              [-40.214166666358551, -8.869999992605528],
              [-40.215000000649468, -8.869999992605528],
              [-40.214999999750148, -8.868333325822277],
              [-40.215833332242426, -8.868333326721597],
              [-40.215833332242426, -8.86749999422932],
              [-40.216666666533399, -8.86749999243068],
              [-40.216666666533399, -8.864999993155095],
              [-40.217499999924996, -8.864999993155095],
              [-40.217499999924996, -8.864166658864178],
              [-40.216666666533399, -8.864166658864178],
              [-40.216666666533399, -8.861666658689273],
              [-40.215833332242426, -8.861666657789954],
              [-40.215833332242426, -8.858333326022148],
              [-40.214999999750148, -8.858333326022148],
              [-40.214999999750148, -8.857499991731174],
              [-40.214166666358551, -8.857499992630494],
              [-40.214166666358551, -8.855833325847243],
              [-40.214999998850828, -8.855833325847243],
              [-40.214999999750148, -8.854999992455646],
              [-40.214166666358551, -8.854999992455646],
              [-40.214166666358551, -8.852499991381478],
              [-40.214999999750148, -8.852499993180118],
              [-40.214999998850828, -8.851666658889144],
              [-40.215833332242426, -8.851666658889144],
              [-40.215833331343106, -8.849166659613616],
              [-40.216666666533399, -8.849166658714296],
              [-40.216666666533399, -8.845833326946433],
              [-40.215833332242426, -8.845833326047114],
              [-40.215833332242426, -8.844166658364543],
              [-40.216666666533399, -8.844166658364543],
              [-40.216666666533399, -8.843333325872266],
              [-40.215833332242426, -8.843333325872266],
              [-40.215833334041065, -8.841666659089015],
              [-40.214999999750148, -8.841666658189695],
              [-40.214999999750148, -8.840833325697361],
              [-40.215833332242426, -8.840833325697361],
              [-40.215833332242426, -8.839999993205083],
              [-40.214999998850828, -8.839999993205083],
              [-40.214999999750148, -8.837499993030235],
              [-40.215833332242426, -8.837499993929555],
              [-40.215833332242426, -8.835833325347664],
              [-40.214999999750148, -8.835833325347664],
              [-40.214999999750148, -8.83499999285533],
              [-40.214166667257871, -8.83499999285533],
              [-40.214166667257871, -8.834166658564413],
              [-40.215833332242426, -8.834166658564413],
              [-40.215833331343106, -8.833333326072079],
              [-40.216666666533399, -8.833333326072079],
              [-40.216666667432719, -8.830833325897231],
              [-40.217499999924996, -8.830833325897231],
              [-40.217499999924996, -8.829999992505577],
              [-40.21833333241733, -8.829999992505577],
              [-40.21833333241733, -8.82916665911398],
              [-40.220000000099901, -8.8291666600133],
              [-40.220000000099901, -8.828333325722383],
              [-40.220833332592179, -8.828333325722383],
              [-40.220833332592179, -8.827499993230049],
              [-40.221666667782472, -8.827499993230049],
              [-40.221666666883152, -8.826666659838452],
              [-40.222500000274749, -8.826666659838452],
              [-40.22249999937543, -8.827499993230049],
              [-40.223333332767027, -8.827499994129369],
              [-40.223333332767027, -8.826666658939132],
              [-40.224166667058, -8.826666658939132],
              [-40.224166665259361, -8.825833324648158],
              [-40.231666666683282, -8.825833325547478],
              [-40.231666665783962, -8.82333332537263],
              [-40.231666666683282, -8.820833326097102],
              [-40.230833332392308, -8.820833326097102],
              [-40.230833331492988, -8.819999992705448],
              [-40.229999999000711, -8.819999992705448],
              [-40.23000000079935, -8.819166658414531],
              [-40.229166667407753, -8.819166658414531],
              [-40.229166666508434, -8.819999992705448],
              [-40.22833333131814, -8.819999992705448],
              [-40.22833333221746, -8.819166658414531],
              [-40.227499999725183, -8.819166658414531],
              [-40.227499999725183, -8.818333325922197],
              [-40.226666665434209, -8.818333326821516],
              [-40.226666665434209, -8.8174999925306],
              [-40.225833332042612, -8.8174999925306],
              [-40.225833332941932, -8.818333325922197],
              [-40.22416666795732, -8.818333325922197],
              [-40.224166667058, -8.8174999925306],
              [-40.221666666883152, -8.8174999925306],
              [-40.219166665808928, -8.8174999925306],
              [-40.219166666708247, -8.818333325022877],
              [-40.214999999750148, -8.818333325922197],
              [-40.214999999750148, -8.819166658414531],
              [-40.2124999995753, -8.81916666021317],
              [-40.2124999995753, -8.818333325922197],
              [-40.209999999400395, -8.818333325022877],
              [-40.209999999400395, -8.819166658414531],
              [-40.209166666908118, -8.819166658414531],
              [-40.209166666908118, -8.819999992705448],
              [-40.207499999225547, -8.819999992705448],
              [-40.207499999225547, -8.819166658414531],
              [-40.205833331542976, -8.819166658414531],
              [-40.205833332442296, -8.819999992705448],
              [-40.204999999050699, -8.819999992705448],
              [-40.204999999050699, -8.819166658414531],
              [-40.199999999600266, -8.819166657515211],
              [-40.199999999600266, -8.818333325922197],
              [-40.199166666208612, -8.818333325022877],
              [-40.199166666208612, -8.8174999925306],
              [-40.194166666758235, -8.8174999925306],
              [-40.194166666758235, -8.816666659138946],
              [-40.192499999974984, -8.816666659138946],
              [-40.192500000874304, -8.8174999925306],
              [-40.19166666658333, -8.8174999925306],
              [-40.191666665684011, -8.816666659138946],
              [-40.190833332292414, -8.816666659138946],
              [-40.190833334091053, -8.815833325747349],
              [-40.189166666408482, -8.815833325747349],
              [-40.189166665509163, -8.814999992355695],
              [-40.185833331942661, -8.814999994154334],
              [-40.18583333284198, -8.814166658964098],
              [-40.184999999450383, -8.814166658964098],
              [-40.184999999450383, -8.813333325572444],
              [-40.184166666958049, -8.813333325572444],
              [-40.184166666958049, -8.812499993080166],
              [-40.183333333566452, -8.812499993080166],
              [-40.183333332667132, -8.813333325572444],
              [-40.182500001074175, -8.813333325572444],
              [-40.182499999275478, -8.812499993080166],
              [-40.18, -8.812499993080166],
              [-40.18, -8.81166665878925],
              [-40.178333332317379, -8.81166665878925],
              [-40.17833333141806, -8.810833324498276],
              [-40.176666666433448, -8.810833325397596],
              [-40.175833333041851, -8.810833325397596],
              [-40.175833333041851, -8.810377173068048],
              [-40.175833333041851, -8.808333326122067],
              [-40.174999999650197, -8.808333326122067],
              [-40.174999999650197, -8.807499991831094],
              [-40.1741666662586, -8.807499992730413],
              [-40.1741666662586, -8.805833326846482],
              [-40.174999999650197, -8.805833325947162],
              [-40.174999999650197, -8.801666658989063],
              [-40.17416666535928, -8.801666658989063],
              [-40.1741666662586, -8.800833325597466],
              [-40.174166668057239, -8.789999992405683],
              [-40.173333332867003, -8.789999992405683],
              [-40.173333332867003, -8.786666658839181],
              [-40.172499999475349, -8.786666658839181],
              [-40.172499999475349, -8.785833325447584],
              [-40.171666666983072, -8.785833325447584],
              [-40.171666666983072, -8.78083332689647],
              [-40.170833332692098, -8.78083332689647],
              [-40.170833332692098, -8.779999992605553],
              [-40.169999999300501, -8.779999992605553],
              [-40.169999998401181, -8.778333325822302],
              [-40.169166666808167, -8.778333325822302],
              [-40.169166666808167, -8.77499999315512],
              [-40.170833332692098, -8.77499999315512],
              [-40.170833332692098, -8.772499992980272],
              [-40.169999999300501, -8.772499993879592],
              [-40.169999999300501, -8.770833326197021],
              [-40.170833332692098, -8.770833326197021],
              [-40.170833332692098, -8.76916665851445],
              [-40.171666666983072, -8.76916665851445],
              [-40.171666666983072, -8.767499992630519],
              [-40.170833332692098, -8.767499992630519],
              [-40.170833332692098, -8.764999992455614],
              [-40.171666666983072, -8.764999992455614],
              [-40.171666666983072, -8.762499993180086],
              [-40.170833332692098, -8.762499993180086],
              [-40.170833332692098, -8.760833325497515],
              [-40.171666666983072, -8.760833325497515],
              [-40.171666666983072, -8.759999993005238],
              [-40.172499999475349, -8.759999993005238],
              [-40.172499999475349, -8.754999993554804],
              [-40.171666666983072, -8.754999991756165],
              [-40.171666666983072, -8.752499992480637],
              [-40.170833332692098, -8.752499993379956],
              [-40.170833332692098, -8.751666659088983],
              [-40.171666666983072, -8.751666659088983],
              [-40.171666666983072, -8.747499992130884],
              [-40.172499999475349, -8.747499993929523],
              [-40.172499999475349, -8.744166658564382],
              [-40.173333333766323, -8.744166658564382],
              [-40.173333332867003, -8.743907544997171],
              [-40.173333332867003, -8.74249999268045],
              [-40.1741666662586, -8.74249999268045],
              [-40.1741666662586, -8.740833325897199],
              [-40.172500000374669, -8.740833325897199],
              [-40.172499999475349, -8.739999992505602],
              [-40.17000000109914, -8.739999992505602],
              [-40.169999999300501, -8.738333325722351],
              [-40.16833333251725, -8.738333325722351],
              [-40.16833333341657, -8.737499991431434],
              [-40.167500000024916, -8.737499993230074],
              [-40.167500000024916, -8.73666665803978],
              [-40.166666666633319, -8.7366666589391],
              [-40.166666666633319, -8.734166658764252],
              [-40.165833332342345, -8.734166658764252],
              [-40.165833332342345, -8.733333325372598],
              [-40.163333333066817, -8.733333324473278],
              [-40.163333333066817, -8.732499992880321],
              [-40.162499999675219, -8.732499991981001],
              [-40.162500000574539, -8.731666658589404],
              [-40.160833332891968, -8.731666658589404],
              [-40.160833331992649, -8.73083332699639],
              [-40.159999999500315, -8.73083332609707],
              [-40.157499999325466, -8.73083332609707],
              [-40.157499999325466, -8.731666658589404],
              [-40.15729166148094, -8.731666658589404],
              [-40.155833332542215, -8.731666658589404],
              [-40.155833333441535, -8.73083332699639],
              [-40.152499999875033, -8.73083332609707],
              [-40.152499999875033, -8.729999992705473],
              [-40.148333332916934, -8.729999991806153],
              [-40.148333332916934, -8.73083332609707],
              [-40.147499998626017, -8.73083332609707],
              [-40.147499999525337, -8.731666658589404],
              [-40.145833332742086, -8.731666657690027],
              [-40.145833333641406, -8.732499991981001],
              [-40.143333332567181, -8.732499992880321],
              [-40.143333331667861, -8.733333325372598],
              [-40.141666667582626, -8.733333337963131],
              [-40.135833333841219, -8.733333325372598],
              [-40.1358333329419, -8.732499992880321],
              [-40.134166667057968, -8.732499992880321],
              [-40.134166667057968, -8.731666658589404],
              [-40.133333332767052, -8.731666658589404],
              [-40.133333332767052, -8.73083332609707],
              [-40.130833332592204, -8.73083332609707],
              [-40.130833332592204, -8.729166658414499],
              [-40.130000000099869, -8.729166658414499],
              [-40.12999999830123, -8.728333325022902],
              [-40.129166666708272, -8.728333325922222],
              [-40.129166666708272, -8.729166658414499],
              [-40.126666666533367, -8.729166658414499],
              [-40.126666666533367, -8.729058684011022],
              [-40.126666666533367, -8.728333326821542],
              [-40.124166666358519, -8.728333325922222],
              [-40.124166666358519, -8.727499991631248],
              [-40.123333333866242, -8.727499992530568],
              [-40.123333332966922, -8.726666659138971],
              [-40.122499999575268, -8.726666659138971],
              [-40.122499999575268, -8.725833325747317],
              [-40.118333332617169, -8.725833325747317],
              [-40.118333333516489, -8.72499999235572],
              [-40.117499999225515, -8.72499999235572],
              [-40.116666666733238, -8.72499999235572],
              [-40.116666666733238, -8.725833325747317],
              [-40.115833332442321, -8.725833325747317],
              [-40.115833333341641, -8.729166658414499],
              [-40.114999999949987, -8.729166658414499],
              [-40.115000000849307, -8.729999992705473],
              [-40.11416666655839, -8.729999992705473],
              [-40.11416666655839, -8.73083332699639],
              [-40.111666666383485, -8.73083332609707],
              [-40.111666666383485, -8.731666658589404],
              [-40.110833332991888, -8.731666658589404],
              [-40.110833332991888, -8.732499992880321],
              [-40.109166666208637, -8.732499992880321],
              [-40.109166666208637, -8.734999992155849],
              [-40.10833333281704, -8.734999993055169],
              [-40.10833333281704, -8.735833325547503],
              [-40.107499999425386, -8.735833325547503],
              [-40.107499999425386, -8.7366666589391],
              [-40.105833332642135, -8.7366666589391],
              [-40.105833332642135, -8.738333325722351],
              [-40.104999998351218, -8.738333326621671],
              [-40.104999999250538, -8.739166660013325],
              [-40.104166665858884, -8.739166659114005],
              [-40.104166666758204, -8.739999992505602],
              [-40.103333333366606, -8.739999992505602],
              [-40.103333332467287, -8.740833325897199],
              [-40.102500000874272, -8.740833325897199],
              [-40.102499999974953, -8.741666657490214],
              [-40.100833332292382, -8.741666658389533],
              [-40.100833332292382, -8.74249999268045],
              [-40.094999999450351, -8.74249999268045],
              [-40.094999999450351, -8.741666658389533],
              [-40.095833332842005, -8.741666658389533],
              [-40.095833332842005, -8.740833325897199],
              [-40.086666666433473, -8.740833326796576],
              [-40.086666666433473, -8.743333326072104],
              [-40.08583333124318, -8.743333326971424],
              [-40.085833333041819, -8.744166658564382],
              [-40.084999998750902, -8.744166658564382],
              [-40.084999999650222, -8.744999992855355],
              [-40.084166668057264, -8.744999992855355],
              [-40.084166665359248, -8.746666659638606],
              [-40.084166666258625, -8.747499993030203],
              [-40.084999999650222, -8.747499993030203],
              [-40.085000000549542, -8.748333325522481],
              [-40.085833333041819, -8.748333325522481],
              [-40.085833333041819, -8.749166658914135],
              [-40.09, -8.749166658914135],
              [-40.090000000899295, -8.749999993205051],
              [-40.0933333326671, -8.749999993205051],
              [-40.0933333326671, -8.750833325697386],
              [-40.095833333741325, -8.750833325697386],
              [-40.095833332842005, -8.751666659088983],
              [-40.099166667307827, -8.751666659088983],
              [-40.099166665509188, -8.752499992480637],
              [-40.099999999800104, -8.752499992480637],
              [-40.099999999800104, -8.753333325872234],
              [-40.103866696091131, -8.753333327670873],
              [-40.104166666758204, -8.753333325872234],
              [-40.104166667657523, -8.754166658364568],
              [-40.104999998351218, -8.754166658364568],
              [-40.104999999250538, -8.755833326047139],
              [-40.104166666758204, -8.755833326047139],
              [-40.104166666758204, -8.757499993729652],
              [-40.103333332467287, -8.757499991931013],
              [-40.103333332467287, -8.762499993180086],
              [-40.102499999974953, -8.762499993180086],
              [-40.102499999974953, -8.76333332567242],
              [-40.101666667482675, -8.76333332567242],
              [-40.101666666583355, -8.764166659064017],
              [-40.100833334091078, -8.764166658164697],
              [-40.100833334091078, -8.764999992455614],
              [-40.100000000699424, -8.764999992455614],
              [-40.099999999800104, -8.766666659238865],
              [-40.099166666408507, -8.766666659238865],
              [-40.099166665509188, -8.768333326022116],
              [-40.098333333916173, -8.768333326022116],
              [-40.098333333016853, -8.767499992630519],
              [-40.097499999625256, -8.767499992630519],
              [-40.097499999625256, -8.766666659238865],
              [-40.096666665334283, -8.766666659238865],
              [-40.096666666233602, -8.764166659064017],
              [-40.095000000349671, -8.764166659064017],
              [-40.095000000349671, -8.763007231498477],
              [-40.094999999450351, -8.762499994079405],
              [-40.094166666958074, -8.762499993180086],
              [-40.094166666958074, -8.761666658889169],
              [-40.094999999450351, -8.761666659788489],
              [-40.094999999450351, -8.759999993005238],
              [-40.094999999450351, -8.757499992830333],
              [-40.094166666958074, -8.757499992830333],
              [-40.094166667857394, -8.758333326221987],
              [-40.0933333326671, -8.758333326221987],
              [-40.0933333326671, -8.759166658714264],
              [-40.091666666783226, -8.759166658714264],
              [-40.091666666783226, -8.759999993904557],
              [-40.089166666608321, -8.759999993005238],
              [-40.089166666608321, -8.760833326396835],
              [-40.088333331418085, -8.760833325497515],
              [-40.088333332317404, -8.761666657989849],
              [-40.086666667332793, -8.761666657989849],
              [-40.086666665534153, -8.76333332567242],
              [-40.085833333041819, -8.76333332657174],
              [-40.085833333041819, -8.764999991556294],
              [-40.084999999650222, -8.764999992455614],
              [-40.084999999650222, -8.766666659238865],
              [-40.085833333041819, -8.766666660138185],
              [-40.085833333041819, -8.770833326197021],
              [-40.084999998750902, -8.770833324398382],
              [-40.084999998750902, -8.771666658689298],
              [-40.084166665359248, -8.771666658689298],
              [-40.084166666258625, -8.772499992980272],
              [-40.083333332866971, -8.772499992980272],
              [-40.083333332866971, -8.773333325472549],
              [-40.082500000374694, -8.773333325472549],
              [-40.082499999475374, -8.774166658864146],
              [-40.08166666698304, -8.774166658864146],
              [-40.081666667882359, -8.77499999315512],
              [-40.080833332692123, -8.77499999315512],
              [-40.080833332692123, -8.775833325647397],
              [-40.080833332692123, -8.776666659039051],
              [-40.08166666698304, -8.776666658139732],
              [-40.08166666698304, -8.778333325822302],
              [-40.083333331967651, -8.778333324922983],
              [-40.083333331967651, -8.779166659213899],
              [-40.084166666258625, -8.779166659213899],
              [-40.084166666258625, -8.779999993504873],
              [-40.084999999650222, -8.779999992605553],
              [-40.084999999650222, -8.788333325622432],
              [-40.085833333041819, -8.788333325622432],
              [-40.085833333941139, -8.789166659014029],
              [-40.086666666433473, -8.789166659014029],
              [-40.086666666433473, -8.7908333266966],
              [-40.08749999982507, -8.79083332579728],
              [-40.08749999892575, -8.792966840547194],
              [-40.08749999892575, -8.797499992930284],
              [-40.086666666433473, -8.797499992930284],
              [-40.086666665534153, -8.799166658814215],
              [-40.085833333041819, -8.799166658814215],
              [-40.085833333041819, -8.799999993105132],
              [-40.084999999650222, -8.799999993105132],
              [-40.084999999650222, -8.801666658089744],
              [-40.084999998750902, -8.802499992380717],
              [-40.083333333766291, -8.802499992380717],
              [-40.083333333766291, -8.803333325772314],
              [-40.076666666633344, -8.803333324872995],
              [-40.076666666633344, -8.804166659163968],
              [-40.074166666458439, -8.804166659163968],
              [-40.074166666458439, -8.804999991656246],
              [-40.072499999675188, -8.804999992555565],
              [-40.072499999675188, -8.805833325947162],
              [-40.070833332891937, -8.805833326846482],
              [-40.070833332891937, -8.806666657540177],
              [-40.069166665209366, -8.806666658439497],
              [-40.069166667008005, -8.807499992730413],
              [-40.067500000224811, -8.807499993629733],
              [-40.067499999325491, -8.806666658439497],
              [-40.066666666833157, -8.806666658439497],
              [-40.066666666833157, -8.805833325947162],
              [-40.065000000049906, -8.805833325947162],
              [-40.065000000049906, -8.804999993454885],
              [-40.064166666658309, -8.804999992555565],
              [-40.064166666658309, -8.805833325947162],
              [-40.061029820569729, -8.805833339437015],
              [-40.058333332916959, -8.805833325047843],
              [-40.058333332916959, -8.804999992555565],
              [-40.057499999525305, -8.804999992555565],
              [-40.057499999525305, -8.804166659163968],
              [-40.054999999350457, -8.804166659163968],
              [-40.055000000249777, -8.803333325772314],
              [-40.054166666858123, -8.803333325772314],
              [-40.054166666858123, -8.802499992380717],
              [-40.052500000074929, -8.802499992380717],
              [-40.052499998276232, -8.803333325772314],
              [-40.050833333291678, -8.803333325772314],
              [-40.050833332392358, -8.802499994179357],
              [-40.048333334016093, -8.802499992380717],
              [-40.048333332217453, -8.803333325772314],
              [-40.045833332042605, -8.803333325772314],
              [-40.045833332941925, -8.802499992380717],
              [-40.044999999550271, -8.802499992380717],
              [-40.044999999550271, -8.801666658989063],
              [-40.044166667057993, -8.801666658989063],
              [-40.044166667057993, -8.799999993105132],
              [-40.043333332767077, -8.799999993105132],
              [-40.043333332767077, -8.799166658814215],
              [-40.038333333316643, -8.799166658814215],
              [-40.038333332417324, -8.799999994004452],
              [-40.037499999924989, -8.799999993105132],
              [-40.037499999924989, -8.801666658989063],
              [-40.036666666533392, -8.801666658989063],
              [-40.036666666533392, -8.802499992380717],
              [-40.035833332242419, -8.802499994179357],
              [-40.035833332242419, -8.805833325947162],
              [-40.036666666533392, -8.805833325947162],
              [-40.036666666533392, -8.807499992730413],
              [-40.037499999924989, -8.807499992730413],
              [-40.037499999924989, -8.808333326122067],
              [-40.038333332417324, -8.808333326122067],
              [-40.038333332417324, -8.812499993080166],
              [-40.03916666670824, -8.812499993080166],
              [-40.03916666670824, -8.814166658964098],
              [-40.040000000099894, -8.814166658964098],
              [-40.040000000099894, -8.815833325747349],
              [-40.03916666670824, -8.815833325747349],
              [-40.03916666670824, -8.816666660038265],
              [-40.038333332417324, -8.816666659138946],
              [-40.038333332417324, -8.818333325922197],
              [-40.037499999924989, -8.818333325922197],
              [-40.037499999924989, -8.819166658414531],
              [-40.036666666533392, -8.819166657515211],
              [-40.036666666533392, -8.819999991806128],
              [-40.035833332242419, -8.819999992705448],
              [-40.035833332242419, -8.820833326097102],
              [-40.034999998850822, -8.820833326097102],
              [-40.034999998850822, -8.821666658589379],
              [-40.034166667257864, -8.821666658589379],
              [-40.034166666358544, -8.822499992880296],
              [-40.03333333296689, -8.822499992880296],
              [-40.03333333296689, -8.82333332537263],
              [-40.032499999575293, -8.82333332717127],
              [-40.032499998675974, -8.825833325547478],
              [-40.03166666528432, -8.825833325547478],
              [-40.031666667082959, -8.826666659838452],
              [-40.030833332792042, -8.826666658939132],
              [-40.030833332792042, -8.827499993230049],
              [-40.030000000299708, -8.827499993230049],
              [-40.030000000299708, -8.828333325722383],
              [-40.029166666908111, -8.828333326621703],
              [-40.028333332617137, -8.828333325722383],
              [-40.028333332617137, -8.82916665911398],
              [-40.02749999922554, -8.82916665821466],
              [-40.02749999922554, -8.82962063958081],
              [-40.02749999922554, -8.829999992505577],
              [-40.026714921656264, -8.830000001498831],
              [-40.020833332991856, -8.829999993404897],
              [-40.020833332991856, -8.82916665911398],
              [-40.019166668007301, -8.82916665911398],
              [-40.019166666208662, -8.828333326621703],
              [-40.018333332817008, -8.828333325722383],
              [-40.018333332817008, -8.827499993230049],
              [-40.017499999425411, -8.827499993230049],
              [-40.017499999425411, -8.826666658939132],
              [-40.01583333264216, -8.826666658939132],
              [-40.01583333174284, -8.825833325547478],
              [-40.014166666758229, -8.825833325547478],
              [-40.014166666758229, -8.824999993055201],
              [-40.012500000874297, -8.824999993055201],
              [-40.012499999974978, -8.824166658764227],
              [-40.009999999800129, -8.824166658764227],
              [-40.009999999800129, -8.82333332537263],
              [-40.008333333016878, -8.82333332537263],
              [-40.008333333016878, -8.824166658764227],
              [-40.007499999625225, -8.824166657864907],
              [-40.007499999625225, -8.824999992155881],
              [-40.004999999450376, -8.824999993055201],
              [-40.004999998551057, -8.824166658764227],
              [-40.003333332667125, -8.824166659663547],
              [-40.003333332667125, -8.82333332537263],
              [-40.002499999275528, -8.82333332717127],
              [-40.002499999275528, -8.821666658589379],
              [-40.000833332492277, -8.821666659488699],
              [-40.000833331592958, -8.820833326097102],
              [-39.998333332317372, -8.820833326097102],
              [-39.998333332317372, -8.821666657690059],
              [-39.996666666433441, -8.821666658589379],
              [-39.996666666433441, -8.822499992880296],
              [-39.994999999650247, -8.822499992880296],
              [-39.994999999650247, -8.82333332537263],
              [-39.993333332866996, -8.82333332537263],
              [-39.993333332866996, -8.824166658764227],
              [-39.991666666983065, -8.824166658764227],
              [-39.991666666983065, -8.824999993055201],
              [-39.989999999300494, -8.824999993055201],
              [-39.989999999300494, -8.82333332537263],
              [-39.988333332517243, -8.82333332537263],
              [-39.988333332517243, -8.824166659663547],
              [-39.986666666633312, -8.824166658764227],
              [-39.986666666633312, -8.82333332537263],
              [-39.984999999850061, -8.82333332447331],
              [-39.984999998950741, -8.824166658764227],
              [-39.98333333306681, -8.824166658764227],
              [-39.98333333306681, -8.824999993055201],
              [-39.982499999675213, -8.824999993055201],
              [-39.982499999675213, -8.825833325547478],
              [-39.981666666283559, -8.825833325547478],
              [-39.981666666283559, -8.827499993230049],
              [-39.97916666700803, -8.827499993230049],
              [-39.97916666700803, -8.828333325722383],
              [-39.975833332542209, -8.828333325722383],
              [-39.975833331642889, -8.82916665911398],
              [-39.976666666833182, -8.82916665911398],
              [-39.976666666833182, -8.829999992505577],
              [-39.97749999932546, -8.829999992505577],
              [-39.97749999932546, -8.830833325897231],
              [-39.978333332717114, -8.830833324997911],
              [-39.978333332717114, -8.832499992680482],
              [-39.97749999842614, -8.832499991781162],
              [-39.97749999932546, -8.833333325172759],
              [-39.976666665933863, -8.833333326072079],
              [-39.976666666833182, -8.834166657665094],
              [-39.975833332542209, -8.834166658564413],
              [-39.975833332542209, -8.83499999285533],
              [-39.974999997351972, -8.83499999285533],
              [-39.974999998251292, -8.835833327146304],
              [-39.974166666658277, -8.835833325347664],
              [-39.974166665758958, -8.83499999285533],
              [-39.972499999875026, -8.83499999285533],
              [-39.972499999875026, -8.834166658564413],
              [-39.970833333091832, -8.834166658564413],
              [-39.970833333091832, -8.83499999195601],
              [-39.969166665409261, -8.83499999285533],
              [-39.969166666308581, -8.834166658564413],
              [-39.968333332017608, -8.834166658564413],
              [-39.968333332017608, -8.833333326072079],
              [-39.96749999952533, -8.833333325172759],
              [-39.96749999862601, -8.832499992680482],
              [-39.966666665234357, -8.832499991781162],
              [-39.966666667032996, -8.831666660188148],
              [-39.964999998451106, -8.831666658389508],
              [-39.964999998451106, -8.830833325897231],
              [-39.964166666858148, -8.830833325897231],
              [-39.964166666858148, -8.82916665911398],
              [-39.963333331667855, -8.82916665911398],
              [-39.963333331667855, -8.827499993230049],
              [-39.964999998451106, -8.827499991431409],
              [-39.964999998451106, -8.826666658939132],
              [-39.962499998276257, -8.826666658939132],
              [-39.962500000074897, -8.824999992155881],
              [-39.9616666666833, -8.824999993055201],
              [-39.9616666666833, -8.824166658764227],
              [-39.959999999000729, -8.824166658764227],
              [-39.959999999000729, -8.82333332537263],
              [-39.956666665434227, -8.82333332537263],
              [-39.956666666333547, -8.822499992880296],
              [-39.955833333841213, -8.822499992880296],
              [-39.955833332941893, -8.821666658589379],
              [-39.953333333666365, -8.821666657690059],
              [-39.953333332767045, -8.820833326996421],
              [-39.952499998476128, -8.820833326097102],
              [-39.952499999375448, -8.819999992705448],
              [-39.949166665808946, -8.819999992705448],
              [-39.949166665808946, -8.819166658414531],
              [-39.947499999925014, -8.819166658414531],
              [-39.947500000824334, -8.818333325922197],
              [-39.946666666533417, -8.818333325922197],
              [-39.946666666533417, -8.8174999925306],
              [-39.945833331343124, -8.8174999925306],
              [-39.945833331343124, -8.82333332537263],
              [-39.944999999750166, -8.82333332537263],
              [-39.944999999750166, -8.825833325547478],
              [-39.944166665459193, -8.825833325547478],
              [-39.944166666358512, -8.826666658939132],
              [-39.941666668881624, -8.826666658939132],
              [-39.941666665284345, -8.825833325547478],
              [-39.939166666008759, -8.825833326446798],
              [-39.939166666908136, -8.826666658939132],
              [-39.938333332617162, -8.826666658939132],
              [-39.938333332617162, -8.827499993230049],
              [-39.935833333341634, -8.827499994129369],
              [-39.935833331542995, -8.826666658939132],
              [-39.932499997976493, -8.826666658939132],
              [-39.932499998875812, -8.827499993230049],
              [-39.931666665484158, -8.827499993230049],
              [-39.931666664584839, -8.828333324823063],
              [-39.929999999600284, -8.828333325722383],
              [-39.929999998700964, -8.82916665911398],
              [-39.924999999250531, -8.82916665911398],
              [-39.924999999250531, -8.829999992505577],
              [-39.924166665858877, -8.829999993404897],
              [-39.924166666758197, -8.831666658389508],
              [-39.92333333156796, -8.831666658389508],
              [-39.9233333333666, -8.834166658564413],
              [-39.924166666758197, -8.834166658564413],
              [-39.924166665858877, -8.835833325347664],
              [-39.92500000104917, -8.835833325347664],
              [-39.924999998351211, -8.83916665891411],
              [-39.924166666758197, -8.83916665891411],
              [-39.924166666758197, -8.839999993205083],
              [-39.9233333333666, -8.839999993205083],
              [-39.92333333066864, -8.840833325697361],
              [-39.921666667482668, -8.840833325697361],
              [-39.921666666583349, -8.842499992480612],
              [-39.920833331393112, -8.842499992480612],
              [-39.920833332292432, -8.841666659089015],
              [-39.920000000699417, -8.841666659089015],
              [-39.920000000699417, -8.842499993379931],
              [-39.919166664609861, -8.842499991581292],
              [-39.919166665509181, -8.843333325872266],
              [-39.914999999450345, -8.843333324972946],
              [-39.914999999450345, -8.842499993379931],
              [-39.910833333391565, -8.842499992480612],
              [-39.910833330693606, -8.850833325497547],
              [-39.91, -8.850833324598227],
              [-39.91, -8.851666658889144],
              [-39.909166667507634, -8.851666658889144],
              [-39.909166665708995, -8.854166659063992],
              [-39.908333332317397, -8.854166659063992],
              [-39.908333332317397, -8.853333324773075],
              [-39.905833331243173, -8.853333325672395],
              [-39.905833333041869, -8.854166659963312],
              [-39.903103919102165, -8.854166659717849],
              [-39.902938175, -8.858743592999929],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '243',
        OBJECTID: 181.0,
        Nome_area: 'Petrolina',
        COD_area: 'CA190',
        Import_bio: 'Muito Alta',
        Prior_acao: 'Muito Alta',
        Acao_princ: 'Recuperação de Áreas Degradadas',
        Area_ha: 166599.37,
        Shape_Leng: 4.65357,
        Shape_Area: 0.13705,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.722178590999903, -9.44594853999997],
              [-40.729783061, -9.444803801999965],
              [-40.730527160999898, -9.444691787999936],
              [-40.7453177209999, -9.443350582999926],
              [-40.749835436, -9.443847168999975],
              [-40.755039983, -9.446482359999944],
              [-40.758049657999898, -9.448805964999957],
              [-40.7627623119999, -9.450313946999925],
              [-40.767456465999899, -9.452650075999932],
              [-40.767499998882847, -9.452658513422019],
              [-40.767499998825826, -9.452499992880348],
              [-40.768333331318161, -9.452499992880348],
              [-40.768333331663534, -9.452820027659831],
              [-40.776666665234359, -9.454435171155113],
              [-40.776666665234359, -9.454369296920488],
              [-40.769593432, -9.447443357999935],
              [-40.767288935, -9.445704125999955],
              [-40.767159283, -9.445411926999951],
              [-40.767071905, -9.445215363999976],
              [-40.764668797, -9.439804987999919],
              [-40.760628043999908, -9.432236281999959],
              [-40.759595295999901, -9.42907629699994],
              [-40.7600836, -9.425767506999929],
              [-40.7647885969999, -9.415949936999937],
              [-40.764975564999901, -9.413216385999924],
              [-40.763229990999903, -9.411423492999972],
              [-40.760788433999899, -9.41054506199993],
              [-40.756701227999898, -9.410352341999943],
              [-40.750595061999903, -9.406464660999971],
              [-40.747430052, -9.405870102999929],
              [-40.745231524, -9.404623216999942],
              [-40.74308888, -9.402945492999946],
              [-40.742671533, -9.400712881999933],
              [-40.743492784999901, -9.398281368999951],
              [-40.7441132719999, -9.396445546999935],
              [-40.743753522, -9.391075504999955],
              [-40.743409960999898, -9.388877681999929],
              [-40.74318558600001, -9.387442309999926],
              [-40.742540415999898, -9.383315037999974],
              [-40.742648953, -9.382880755999958],
              [-40.742771401, -9.382390814999956],
              [-40.74351688, -9.37940798899996],
              [-40.745867265, -9.374813099999937],
              [-40.746478937999903, -9.371052246999964],
              [-40.744528913999893, -9.364831664999933],
              [-40.73973969, -9.358291735999954],
              [-40.738102645, -9.355244273999975],
              [-40.73820883, -9.353199255999932],
              [-40.740935801, -9.348366732999983],
              [-40.741403080999902, -9.346783581999938],
              [-40.740672731, -9.343158205999933],
              [-40.737648502, -9.338753834999983],
              [-40.737286465, -9.336870765999958],
              [-40.739347395, -9.33134388599996],
              [-40.742306503999899, -9.327209877999959],
              [-40.751629823, -9.318284733999963],
              [-40.755011796, -9.317053968999975],
              [-40.756421944, -9.31503225899996],
              [-40.755592024999899, -9.313548823999954],
              [-40.755589203999897, -9.313543781999954],
              [-40.755487113999898, -9.313361301999974],
              [-40.753746062999909, -9.310249265999975],
              [-40.751588655, -9.308027819999952],
              [-40.749063706999898, -9.307458517999981],
              [-40.7474589609999, -9.306621901999961],
              [-40.743981936999901, -9.306225285999966],
              [-40.742897102, -9.304277262999955],
              [-40.742450394999899, -9.302243926999944],
              [-40.742619815999902, -9.300508855999965],
              [-40.741840967, -9.298870812999958],
              [-40.739441826, -9.296588726999977],
              [-40.737448876, -9.296780168999925],
              [-40.734140812, -9.299866732999932],
              [-40.730431128, -9.301476967999964],
              [-40.72864847599989, -9.303118202999936],
              [-40.725013531999899, -9.304463901999942],
              [-40.723247386, -9.305673692999958],
              [-40.720088161, -9.305896696999982],
              [-40.716820213999902, -9.303875731999941],
              [-40.714066072, -9.30038276799996],
              [-40.708290917999904, -9.29607768899996],
              [-40.707314852, -9.294550765999922],
              [-40.707063311999903, -9.292941709999978],
              [-40.707408766, -9.291654020999946],
              [-40.708689664, -9.286875976999976],
              [-40.70758889, -9.285346332999953],
              [-40.706038554, -9.285041556999936],
              [-40.701288838000011, -9.282332458999974],
              [-40.692252475, -9.281226476999963],
              [-40.687233981, -9.278333138999935],
              [-40.685987868, -9.276368183999976],
              [-40.685623346999897, -9.274578739999971],
              [-40.685444117, -9.273698894999939],
              [-40.684900367, -9.271029606999953],
              [-40.683271737, -9.267259788999979],
              [-40.681147918, -9.263482650999944],
              [-40.680088543999901, -9.259211747999919],
              [-40.680483785, -9.257691528999942],
              [-40.682703284999903, -9.257310028999942],
              [-40.686661474999902, -9.258019182999925],
              [-40.689236525999902, -9.256985901999929],
              [-40.690258139, -9.252638582999969],
              [-40.6900952189999, -9.25144627299994],
              [-40.6874317689999, -9.246676610999945],
              [-40.686455630999902, -9.24355456899997],
              [-40.687056873, -9.242263852999931],
              [-40.689457263, -9.241125387999944],
              [-40.690298251999899, -9.238873216999927],
              [-40.690350480999903, -9.238733348999974],
              [-40.690849183999902, -9.237397819999961],
              [-40.69100664, -9.234062851999964],
              [-40.694500379, -9.233455570999974],
              [-40.694441212685902, -9.233333324669644],
              [-40.694166665758985, -9.233333324373348],
              [-40.694166666983328, -9.232766072760114],
              [-40.694037886253376, -9.23249999278039],
              [-40.692499998975734, -9.23249999278039],
              [-40.692500000774373, -9.231666658489473],
              [-40.692499999875054, -9.230833326896459],
              [-40.69166666558408, -9.230833325997139],
              [-40.691666666383, -9.2286125677116],
              [-40.691480162154349, -9.228333325822291],
              [-40.690833333091803, -9.228333325822291],
              [-40.690833331293163, -9.227499991531317],
              [-40.689999998800886, -9.227499992430637],
              [-40.69000000029321, -9.226117165912262],
              [-40.689872924, -9.225926901999969],
              [-40.689810424847416, -9.225833325647386],
              [-40.689166667207871, -9.225833325647386],
              [-40.689166666167687, -9.224869463151677],
              [-40.688697266889022, -9.224166658864192],
              [-40.688333332017635, -9.224166658864192],
              [-40.688333332017635, -9.22362176031065],
              [-40.688140688048648, -9.223333325680436],
              [-40.687499998625981, -9.223333326371858],
              [-40.68750000042462, -9.222499992980261],
              [-40.686666665234384, -9.222499992980261],
              [-40.686666665234384, -9.221666658689287],
              [-40.68583333274205, -9.221666658689287],
              [-40.68583333184273, -9.22083332619701],
              [-40.684999998451133, -9.22083332619701],
              [-40.685000000035643, -9.218630955861126],
              [-40.684801214491884, -9.218333325807578],
              [-40.684166667757495, -9.218333325122785],
              [-40.684166666858118, -9.217499993529827],
              [-40.683333331667882, -9.217499991731188],
              [-40.683333332404125, -9.216135551180109],
              [-40.683131477697543, -9.215833325847257],
              [-40.682499998276285, -9.215833325847257],
              [-40.682499998276285, -9.21499999245566],
              [-40.68166666668327, -9.21499999245566],
              [-40.681666665783951, -9.214166659064006],
              [-40.680833331493034, -9.214166659064006],
              [-40.680833331875974, -9.212392445534737],
              [-40.6807039729999, -9.212198763999931],
              [-40.680833331961686, -9.211995326441086],
              [-40.680833332392353, -9.209999993005226],
              [-40.68166666668327, -9.209999993005226],
              [-40.68166666668327, -9.204166658364556],
              [-40.680833331493034, -9.204166658364556],
              [-40.680833332392353, -9.202499992480625],
              [-40.680000000799339, -9.202499992480625],
              [-40.679999999095415, -9.199342191866023],
              [-40.679897631924199, -9.199166659592496],
              [-40.679166666508422, -9.199166658014803],
              [-40.679166667184752, -9.197913250896502],
              [-40.67892566322309, -9.197499993669425],
              [-40.678333334016088, -9.197499993030192],
              [-40.678333331318129, -9.196666658739275],
              [-40.677499999725171, -9.196666659638595],
              [-40.677499998825851, -9.194999992855344],
              [-40.676666665434198, -9.194999992855344],
              [-40.676666665434198, -9.193462928676697],
              [-40.675472315, -9.193926037999974],
              [-40.674358760548593, -9.193333325795436],
              [-40.673333331867752, -9.193333326072093],
              [-40.673333332487445, -9.192759105280595],
              [-40.673161296138304, -9.192499992680496],
              [-40.672499998476098, -9.192499992680496],
              [-40.672499998476098, -9.191666658389522],
              [-40.671666666883141, -9.191666658389522],
              [-40.671666667782461, -9.190833325897245],
              [-40.670833331692847, -9.190833325897245],
              [-40.670833332592167, -9.189999992505591],
              [-40.66999999830125, -9.189999992505591],
              [-40.66999999830125, -9.18833332662166],
              [-40.669166666708236, -9.18833332482302],
              [-40.669166666708236, -9.183333327171283],
              [-40.668333333316639, -9.183333327171283],
              [-40.668333332417319, -9.182499992880309],
              [-40.667499997227026, -9.182499993779629],
              [-40.667499999925042, -9.180833326097058],
              [-40.67000000009989, -9.180833325197739],
              [-40.66999999830125, -9.179999992705461],
              [-40.670833333491487, -9.179999992705461],
              [-40.670833333491487, -9.17605211710077],
              [-40.670739032712518, -9.17583332584913],
              [-40.66999999830125, -9.175833326646682],
              [-40.67000000009989, -9.173333325572457],
              [-40.671594822754571, -9.173333326433012],
              [-40.671666665983821, -9.173196643655507],
              [-40.671666665983821, -9.171075876469526],
              [-40.671047263321505, -9.169166658614358],
              [-40.670833332592167, -9.169166658614358],
              [-40.670833332947979, -9.168507249616392],
              [-40.670506551174427, -9.167499992730427],
              [-40.67000000009989, -9.167499992730427],
              [-40.66999999897655, -9.16593861987945],
              [-40.669695786, -9.16500092799993],
              [-40.669695273515273, -9.164999993126044],
              [-40.669166665808916, -9.164999992555579],
              [-40.669166665808916, -9.164035707720085],
              [-40.668781629897246, -9.163333325772328],
              [-40.668333331517999, -9.163333325772328],
              [-40.668333332400536, -9.162515542133521],
              [-40.667867986374247, -9.161666658591951],
              [-40.667499999025665, -9.161666658989077],
              [-40.667500000474583, -9.160995379251185],
              [-40.66741116493931, -9.16083332559748],
              [-40.666666667432708, -9.16083332559748],
              [-40.666666666533388, -9.159999993105146],
              [-40.666666665634068, -9.158333325422575],
              [-40.665833332242471, -9.158333325422575],
              [-40.665833332242471, -9.154999991856073],
              [-40.666666666533388, -9.154999992755393],
              [-40.666666665634068, -9.153333325972142],
              [-40.664999999750137, -9.153333325972142],
              [-40.664999998850817, -9.152499992580545],
              [-40.664166667257859, -9.152499993479864],
              [-40.66416666545922, -9.151666659188948],
              [-40.663333332067566, -9.151666659188948],
              [-40.663333332067566, -9.149999992405697],
              [-40.662500000474608, -9.149999992405697],
              [-40.662499998675969, -9.148333326521765],
              [-40.661666667082955, -9.148333325622445],
              [-40.661666665284315, -9.147499993130111],
              [-40.660833332792038, -9.147499992230792],
              [-40.660833333691357, -9.146666658839194],
              [-40.659999998501064, -9.146666658839194],
              [-40.659999998501064, -9.144999992955263],
              [-40.659166667807426, -9.144999992955263],
              [-40.659166666908106, -9.144166658664346],
              [-40.658333333516509, -9.144166658664346],
              [-40.658333334415829, -9.142499992780415],
              [-40.656666667632578, -9.142499992780415],
              [-40.656666667632578, -9.141666658489441],
              [-40.654999999050688, -9.141666658489441],
              [-40.654999999050688, -9.13999999170619],
              [-40.653333332267437, -9.13999999260551],
              [-40.653333331368117, -9.138333324922939],
              [-40.654166665659034, -9.138333325822259],
              [-40.654166666558353, -9.135833325647411],
              [-40.652499999775102, -9.135833325647411],
              [-40.652499998875783, -9.136666659938385],
              [-40.646666666033752, -9.136666659938385],
              [-40.646666666033752, -9.135833325647411],
              [-40.645833332642155, -9.135833325647411],
              [-40.645833332642155, -9.134999993155134],
              [-40.645000001049141, -9.134999993155134],
              [-40.644999998351182, -9.133333325472563],
              [-40.644166666758224, -9.133333325472563],
              [-40.644166666758224, -9.132499992980229],
              [-40.643333331567931, -9.132499992980229],
              [-40.643333333366627, -9.133333326371883],
              [-40.639166666408471, -9.133333325472563],
              [-40.639166664609832, -9.132499992980229],
              [-40.638333333916194, -9.132499992980229],
              [-40.638333333916194, -9.131666657789992],
              [-40.6374999987259, -9.131666658689312],
              [-40.63750000052454, -9.130833324398338],
              [-40.636666666233623, -9.130833326196978],
              [-40.636666668032262, -9.129166658514407],
              [-40.635833331942649, -9.129166658514407],
              [-40.635833331942649, -9.12833332602213],
              [-40.636666665334303, -9.12833332602213],
              [-40.636666666233623, -9.127499992630533],
              [-40.63750000052454, -9.127499992630533],
              [-40.6374999987259, -9.125833325847282],
              [-40.639166665509151, -9.125833325847282],
              [-40.639166665509151, -9.124999991556308],
              [-40.640833331393083, -9.124999992455628],
              [-40.640833334091042, -9.121666657989806],
              [-40.641666667482696, -9.121666659788445],
              [-40.641666666583376, -9.119999993005251],
              [-40.640833331393083, -9.119999993005251],
              [-40.640833334091042, -9.118333326222],
              [-40.639999999800125, -9.118333326222],
              [-40.639999999800125, -9.114166658364525],
              [-40.639166666408471, -9.114166658364525],
              [-40.639166665509151, -9.11249999248065],
              [-40.638333333916194, -9.112499991581274],
              [-40.638333333016874, -9.110833326596719],
              [-40.63749999962522, -9.110833326596719],
              [-40.63750000052454, -9.109166658914148],
              [-40.636666665334303, -9.109166658914148],
              [-40.636666668032262, -9.108333325522494],
              [-40.635833333741289, -9.108333325522494],
              [-40.635833332841969, -9.105833325347646],
              [-40.634999998551052, -9.105833325347646],
              [-40.634999998551052, -9.104999992855312],
              [-40.634166666958095, -9.104999992855312],
              [-40.634166664260079, -9.103333326072118],
              [-40.634999998551052, -9.103333326072118],
              [-40.634999998551052, -9.102499991781144],
              [-40.634166666958095, -9.102499991781144],
              [-40.634166667857414, -9.101666657490227],
              [-40.633333333566441, -9.101666657490227],
              [-40.633333333566441, -9.095833325547517],
              [-40.632499998376204, -9.095833325547517],
              [-40.632499998376204, -9.094999993055183],
              [-40.63166666588387, -9.094999993055183],
              [-40.63166666678319, -9.095833325547517],
              [-40.629999999100619, -9.09583332464814],
              [-40.630000000899258, -9.094999993055183],
              [-40.626666666433493, -9.094999993954502],
              [-40.626666667332813, -9.096666658939114],
              [-40.625833333941159, -9.096666658939114],
              [-40.62583333304184, -9.098333325722365],
              [-40.62166666698306, -9.098333325722365],
              [-40.62166666608374, -9.097499993230031],
              [-40.61999999840117, -9.097499993230031],
              [-40.620000001099129, -9.098333325722365],
              [-40.619166667707532, -9.098333325722365],
              [-40.619166667707532, -9.097499994129407],
              [-40.618333332517238, -9.097499994129407],
              [-40.618333332517238, -9.096666658939114],
              [-40.617500000024961, -9.096666659838434],
              [-40.617500000024961, -9.097499993230031],
              [-40.616666665733987, -9.097499993230031],
              [-40.616666666633307, -9.098333325722365],
              [-40.615833331443071, -9.098333325722365],
              [-40.615833331443071, -9.099166660013282],
              [-40.615000000749376, -9.099166659113962],
              [-40.614999999850056, -9.099999992505616],
              [-40.614166666458459, -9.099999992505616],
              [-40.614166665559139, -9.099166659113962],
              [-40.613333333066805, -9.099166659113962],
              [-40.613333333066805, -9.096666658939114],
              [-40.614166666458459, -9.096666658939114],
              [-40.614166665559139, -9.095833325547517],
              [-40.615000000749376, -9.095833325547517],
              [-40.615000000749376, -9.094166658764266],
              [-40.614166665559139, -9.094166658764266],
              [-40.614166666458459, -9.093333325372612],
              [-40.613333333066805, -9.093333325372612],
              [-40.613333331268166, -9.092499992880335],
              [-40.612499998775888, -9.092499992880335],
              [-40.612500000574528, -9.091666658589361],
              [-40.611666666283554, -9.091666658589361],
              [-40.611666665384234, -9.089166660213152],
              [-40.610833332891957, -9.089166658414513],
              [-40.610833333791277, -9.08583332664665],
              [-40.611666666283554, -9.085833324848011],
              [-40.611666665384234, -9.084999992355733],
              [-40.612499998775888, -9.084999992355733],
              [-40.612499999675208, -9.084166658964079],
              [-40.613333330368846, -9.084166658964079],
              [-40.613333330368846, -9.083333325572482],
              [-40.61416666465982, -9.083333324673163],
              [-40.614166665559139, -9.079166658614383],
              [-40.614999999850056, -9.079166658614383],
              [-40.614999999850056, -9.074999993454867],
              [-40.614166665559139, -9.074999993454867],
              [-40.614166665559139, -9.074762186625549],
              [-40.61520660380171, -9.074072389529078],
              [-40.616347597963227, -9.075643433197854],
              [-40.617231935505401, -9.078424135165676],
              [-40.621294956497024, -9.075781357723429],
              [-40.624020925725119, -9.072737238033085],
              [-40.627548844703369, -9.068856382815795],
              [-40.626900093564416, -9.067921396355302],
              [-40.628415762377472, -9.067849669126872],
              [-40.632652700561664, -9.067461462376514],
              [-40.634307464817596, -9.067237408879294],
              [-40.633488752603796, -9.064999992755418],
              [-40.625672025243716, -9.043635824133958],
              [-40.623531462506946, -9.038889431701875],
              [-40.623187503300585, -9.039051108221997],
              [-40.620946486291245, -9.039904990223079],
              [-40.618681696602948, -9.041902366500949],
              [-40.615775391720888, -9.043983531714559],
              [-40.615767623377053, -9.043914107649925],
              [-40.614671562042929, -9.036016431413145],
              [-40.616764172928185, -9.029932271357211],
              [-40.615811582238678, -9.028177036640898],
              [-40.615286763073641, -9.027207486536213],
              [-40.615366853097726, -9.026334445378325],
              [-40.615238384044119, -9.025766164676725],
              [-40.615114258716005, -9.025430460347422],
              [-40.613978438354195, -9.022797011572777],
              [-40.613869746291698, -9.022578184035922],
              [-40.609801756545778, -9.0214817162082],
              [-40.60793809596629, -9.018702216633926],
              [-40.610622962579612, -9.015913826361952],
              [-40.613058097353246, -9.013927338176074],
              [-40.61306573259742, -9.013931861765968],
              [-40.613093248254756, -9.013949973212675],
              [-40.613135735825495, -9.013977948423531],
              [-40.613165696739486, -9.013992741371851],
              [-40.613178755794877, -9.014002414479819],
              [-40.613229004514892, -9.014039635620634],
              [-40.613286161826863, -9.014062214899241],
              [-40.613309908425492, -9.01407263534378],
              [-40.613316873674762, -9.014076074351294],
              [-40.613330741220693, -9.014088058716879],
              [-40.613376618336247, -9.014122039600409],
              [-40.613411300690927, -9.01414107015421],
              [-40.613454109319662, -9.014170656050908],
              [-40.613487958902112, -9.014185521844297],
              [-40.613515622947602, -9.014203727719803],
              [-40.613528578580997, -9.014210135389362],
              [-40.613598555728743, -9.01425737767579],
              [-40.613624583008061, -9.014271659809197],
              [-40.613656250835277, -9.014295985571209],
              [-40.613705969854607, -9.014323262908192],
              [-40.613745197382912, -9.014342628009842],
              [-40.613784612869551, -9.014362088439611],
              [-40.613804367377611, -9.014370759702729],
              [-40.61382378104264, -9.014383542666337],
              [-40.613856145844409, -9.014403526501496],
              [-40.61388870489975, -9.014422811563463],
              [-40.613909870444104, -9.014436746558545],
              [-40.613968470268617, -9.014481746835088],
              [-40.614069909298848, -9.01450907183613],
              [-40.614089851765186, -9.014518024587119],
              [-40.614117534696447, -9.014531693382878],
              [-40.614159689518033, -9.014554813154064],
              [-40.614228750256586, -9.014579623650661],
              [-40.614263933533664, -9.014586566416881],
              [-40.614293398921177, -9.014597153235968],
              [-40.61434155671742, -9.014611411087685],
              [-40.614386017400761, -9.014621170530575],
              [-40.614423359950081, -9.01462731739673],
              [-40.614444819572725, -9.014633096440207],
              [-40.614508262246488, -9.01465502640832],
              [-40.614592317381494, -9.014684075409718],
              [-40.614651696918258, -9.01469579807258],
              [-40.614707285812528, -9.014701283037709],
              [-40.614745980942246, -9.014701284836406],
              [-40.61477084989474, -9.014703739086258],
              [-40.61481632681199, -9.014708226703249],
              [-40.614865392923491, -9.014708231199847],
              [-40.614900563610092, -9.014708228501888],
              [-40.614925384898527, -9.014708229401208],
              [-40.614951174756868, -9.014710773583261],
              [-40.614979745318976, -9.014713338449781],
              [-40.615003992839945, -9.014718123742398],
              [-40.615049157692454, -9.01472705221164],
              [-40.615096187738857, -9.014731691814063],
              [-40.615148707246988, -9.014736006761268],
              [-40.615196070941863, -9.014736008559908],
              [-40.615234709414267, -9.01473600136535],
              [-40.615276978449742, -9.014736005861948],
              [-40.615315621418745, -9.014735999566653],
              [-40.615354334534857, -9.01473600046603],
              [-40.615389489932966, -9.014735999566653],
              [-40.615428230028726, -9.01473600136535],
              [-40.615466877494384, -9.014735995969374],
              [-40.615505619388784, -9.014735995969374],
              [-40.615544267753705, -9.014735989674136],
              [-40.615582957487447, -9.014735990573456],
              [-40.615621680496133, -9.014735992372096],
              [-40.615663905464828, -9.014735987875497],
              [-40.615692202632999, -9.014735983378898],
              [-40.615717081478067, -9.014738444823308],
              [-40.615745470377135, -9.014741246211486],
              [-40.615797939523247, -9.014749884199773],
              [-40.615844413788636, -9.014749876105839],
              [-40.615874466433411, -9.01475257497134],
              [-40.615915759704478, -9.014755973509295],
              [-40.615959565681351, -9.014760297449698],
              [-40.616008570638996, -9.0147602884565],
              [-40.616050807298848, -9.014760292953099],
              [-40.616085986978646, -9.014760292053779],
              [-40.616121211624602, -9.01476029115446],
              [-40.616135768051208, -9.014760285758541],
              [-40.616145877330325, -9.014762285850793],
              [-40.616211447800026, -9.014778470050317],
              [-40.616279657779955, -9.014795308956309],
              [-40.61631367733429, -9.014804467652027],
              [-40.616354713399289, -9.014816627385414],
              [-40.616432923840307, -9.014832882631367],
              [-40.616463004364107, -9.014838283959591],
              [-40.616481341540634, -9.014843716764062],
              [-40.616498878320556, -9.014850013817011],
              [-40.616515238787201, -9.014858991749009],
              [-40.616638044809861, -9.014927817764544],
              [-40.616658629392191, -9.014941370547774],
              [-40.616670229747285, -9.014949962670585],
              [-40.616692761361833, -9.014972210099359],
              [-40.616720900249277, -9.014996534962052],
              [-40.616733561804381, -9.015009034639206],
              [-40.616744226864512, -9.015023077552939],
              [-40.616776887543267, -9.015068232512874],
              [-40.616971920817434, -9.01534834794785],
              [-40.617107664487094, -9.015623121409874],
              [-40.617456083431478, -9.016033438892066],
              [-40.618333001566725, -9.017287423875473],
              [-40.618359469513848, -9.017324498426831],
              [-40.618399827490009, -9.017394236354903],
              [-40.61847667096157, -9.017519226830814],
              [-40.618554427244987, -9.017596013645061],
              [-40.618583710070141, -9.017621318768818],
              [-40.618633697087432, -9.017670682555888],
              [-40.618667928881791, -9.017704485373656],
              [-40.618708412762999, -9.017739462706004],
              [-40.61873768029966, -9.017761139964591],
              [-40.618765583564766, -9.017785254385956],
              [-40.618798542818467, -9.017810574798148],
              [-40.618829231283939, -9.017837087711371],
              [-40.618858495223321, -9.017858755976761],
              [-40.618881856912139, -9.017878953850584],
              [-40.618921466652296, -9.017913174853106],
              [-40.618972858410643, -9.017947007348482],
              [-40.619005808671091, -9.017965081922966],
              [-40.61902844730497, -9.017979984588578],
              [-40.619160144924706, -9.018073917876961],
              [-40.619695252333997, -9.018427087939699],
              [-40.619817308322069, -9.018245659609477],
              [-40.622499998576018, -9.018325335046143],
              [-40.625341108904991, -9.018409688756094],
              [-40.625345513784396, -9.017934669549334],
              [-40.625923446010177, -9.017221715809512],
              [-40.626142711516877, -9.015889461929078],
              [-40.626017877522997, -9.015160298808723],
              [-40.625435906441908, -9.014756518498473],
              [-40.625834125344568, -9.014209668641229],
              [-40.626045198926761, -9.013855713470377],
              [-40.626280842886558, -9.012728640814089],
              [-40.62593433050489, -9.011919209603775],
              [-40.633474415611772, -9.009109097107114],
              [-40.63215891070513, -9.007084549612216],
              [-40.630687636025527, -9.004961590205198],
              [-40.63149822286465, -9.003935613038038],
              [-40.63334368386387, -9.001756428016677],
              [-40.632964601635024, -8.999199954913934],
              [-40.63279698149546, -8.998071643891194],
              [-40.632519680938458, -8.997499999725164],
              [-40.630830543694572, -8.994017857138374],
              [-40.631476064468757, -8.993874182347611],
              [-40.641718284970807, -8.989008414806449],
              [-40.642499999974973, -8.988875504900591],
              [-40.643952755815008, -8.988628493510134],
              [-40.646326881480377, -8.986278529030869],
              [-40.645971492790125, -8.985833325947169],
              [-40.645833330843516, -8.985660247822125],
              [-40.644166667657544, -8.983572352082319],
              [-40.643975868391578, -8.983333326671641],
              [-40.643706986388281, -8.982996482901342],
              [-40.64333333246725, -8.982817734550736],
              [-40.639185228415499, -8.980833326496793],
              [-40.639166665509151, -8.980824445691553],
              [-40.638866722721048, -8.980680952563887],
              [-40.636236417976249, -8.979394150419637],
              [-40.634765506622784, -8.977934766576197],
              [-40.63373822184144, -8.977499993829611],
              [-40.633333332667121, -8.977328633409684],
              [-40.631953313094868, -8.976744564210207],
              [-40.630817211245244, -8.977314474483364],
              [-40.630858179861093, -8.976666658639317],
              [-40.631035731213956, -8.973859032768075],
              [-40.633411700489546, -8.972404221977229],
              [-40.632832603641702, -8.969724338507376],
              [-40.63200729130341, -8.966090199702933],
              [-40.631383298500396, -8.963298642918005],
              [-40.630584169024132, -8.959787983728404],
              [-40.63028742602387, -8.958608104680252],
              [-40.62971423232591, -8.957898735638082],
              [-40.629677581355224, -8.957736733563365],
              [-40.624492009498624, -8.960833326896477],
              [-40.622939352469075, -8.961760468570731],
              [-40.621490676853227, -8.960216997216605],
              [-40.621133217923614, -8.960482980704967],
              [-40.620859016430416, -8.963044879417623],
              [-40.619694996926512, -8.96412145244301],
              [-40.623288436911764, -8.968770540927665],
              [-40.623266827102327, -8.972067487924164],
              [-40.625861917093744, -8.972096948815079],
              [-40.625859595044233, -8.974166658464469],
              [-40.625854611001444, -8.978607105133733],
              [-40.624877865421126, -8.978596110022409],
              [-40.624847558268129, -8.983668387086368],
              [-40.627393124393109, -8.983697240934873],
              [-40.627372384228124, -8.98550182864642],
              [-40.628271480544356, -8.98551202066318],
              [-40.628887740077118, -8.985802929761348],
              [-40.628851262675596, -8.988967381439465],
              [-40.627403882083456, -8.988950977805359],
              [-40.620601534233401, -8.988873802483965],
              [-40.617420646545554, -8.988837673120088],
              [-40.617178476205652, -8.988457709555632],
              [-40.617164570888178, -8.985416816632721],
              [-40.615577570548396, -8.98539877803114],
              [-40.615597156883268, -8.983696038541325],
              [-40.614114362787575, -8.983727415887472],
              [-40.607567020401632, -8.983652882774265],
              [-40.607540241289087, -8.985976434165082],
              [-40.605000000049927, -8.98594748858568],
              [-40.60435748391069, -8.985940163607609],
              [-40.604361204406018, -8.985634578472968],
              [-40.60405915540565, -8.985631268068516],
              [-40.604063557587097, -8.985249602087436],
              [-40.599082027513532, -8.985192773028018],
              [-40.599046224603512, -8.987577731027443],
              [-40.598906989765737, -8.989871355678474],
              [-40.604999997351968, -8.990533345737276],
              [-40.606480973018449, -8.990694230854047],
              [-40.605833332542204, -8.992366495916997],
              [-40.604490656418591, -8.995833325747356],
              [-40.60416790682342, -8.996666659138953],
              [-40.604166665758953, -8.996669865222032],
              [-40.604144626073605, -8.996726772522493],
              [-40.603926148373034, -8.997290880369803],
              [-40.603845161724792, -8.997499993429926],
              [-40.602563190738636, -9.00081001117303],
              [-40.602047048232635, -9.000091417784631],
              [-40.601385050979275, -8.999166658414481],
              [-40.600999170775083, -8.998627610176015],
              [-40.599934891482008, -8.997039115162977],
              [-40.599844425979427, -8.996926230461099],
              [-40.59969500631945, -8.996729475884536],
              [-40.599672729213012, -8.996696463570913],
              [-40.599643758452657, -8.996644010612556],
              [-40.599552810913451, -8.996519252161704],
              [-40.599484314049789, -8.996437100891455],
              [-40.599452241527672, -8.996400139654668],
              [-40.599408258384358, -8.996356697003932],
              [-40.599382591733161, -8.99633451342703],
              [-40.599362189713247, -8.996302851895052],
              [-40.599327934536518, -8.996244838428481],
              [-40.599302389293769, -8.996209519353783],
              [-40.599283785018599, -8.99618194883783],
              [-40.599266480263793, -8.996139244530468],
              [-40.599228770791115, -8.996077170624858],
              [-40.599188843590241, -8.996026459653365],
              [-40.599159741528808, -8.995993615512873],
              [-40.599145744480495, -8.995972874448569],
              [-40.599134884267471, -8.995944267014238],
              [-40.599105328947758, -8.99588587942975],
              [-40.599067344282446, -8.995826930668272],
              [-40.599008054677938, -8.995743968209524],
              [-40.598976766364615, -8.995704240658142],
              [-40.598959723312532, -8.995678992191699],
              [-40.59894881273749, -8.995652052100468],
              [-40.598934925406468, -8.995606323373067],
              [-40.598911996291577, -8.995549707452994],
              [-40.598888851339382, -8.995503985020832],
              [-40.598874743674457, -8.995469150680719],
              [-40.598862623511195, -8.995429237868962],
              [-40.598850599575485, -8.995389661403692],
              [-40.598841682797399, -8.995345618905162],
              [-40.598826084056498, -8.995289138782709],
              [-40.598811696702398, -8.995241769691859],
              [-40.59879604310288, -8.995190234042013],
              [-40.598777526961271, -8.995144511609851],
              [-40.598767220730622, -8.995110578390438],
              [-40.598583341747769, -8.99421431763659],
              [-40.598573828719168, -8.99416734694546],
              [-40.598564291408877, -8.994124950206242],
              [-40.59854604326523, -8.994042844801413],
              [-40.598507900319305, -8.993887279174544],
              [-40.598498253291723, -8.993839643884428],
              [-40.598486774345133, -8.99378295421991],
              [-40.598476574234496, -8.993672169435001],
              [-40.598472039852709, -8.993627381398483],
              [-40.598468254606246, -8.993589993883063],
              [-40.598459935877315, -8.993544760682141],
              [-40.598447409220512, -8.993462288353896],
              [-40.59842049251165, -8.993364818031864],
              [-40.598400344100583, -8.993255324673441],
              [-40.598397251332017, -8.99323852713627],
              [-40.598358620054228, -8.993169153433655],
              [-40.598334842878671, -8.993116338048537],
              [-40.598314133290557, -8.993065198999659],
              [-40.598267014211274, -8.992942491003134],
              [-40.598126982574172, -8.992644199370261],
              [-40.598086860220405, -8.992569333507902],
              [-40.598059900344083, -8.992524957360843],
              [-40.598019272571321, -8.992444696465498],
              [-40.597998909222213, -8.992408486162674],
              [-40.597982048732547, -8.992366858343814],
              [-40.597936648257644, -8.992272196604517],
              [-40.59718078786301, -8.991030677124002],
              [-40.596603742368757, -8.990755060097911],
              [-40.596517558538437, -8.990721443439838],
              [-40.596496948775098, -8.990713445768847],
              [-40.596480810440994, -8.990717135687191],
              [-40.596377674390112, -8.990740466799082],
              [-40.595696094399216, -8.991130150236188],
              [-40.59560670448576, -8.991174333028994],
              [-40.595514387279081, -8.991212103655585],
              [-40.595419610426632, -8.99124326966114],
              [-40.595322847871103, -8.991267676362156],
              [-40.595224587944358, -8.991285199652168],
              [-40.595125327072139, -8.991295753196425],
              [-40.595025562579622, -8.991299279438124],
              [-40.594925796288408, -8.991295764887582],
              [-40.594826532718287, -8.991285225732554],
              [-40.594728271892166, -8.991267715032961],
              [-40.594631506638677, -8.991243320023159],
              [-40.594536724390252, -8.991212165708816],
              [-40.594444402686975, -8.991174407672702],
              [-40.594355008276978, -8.99113023477247],
              [-40.594340951873392, -8.991122500602899],
              [-40.59430117665795, -8.991100614701566],
              [-40.594261379858779, -8.991078719807035],
              [-40.594226469975581, -8.991059512086792],
              [-40.594221597448723, -8.991056832107063],
              [-40.594181820434642, -8.99103494440709],
              [-40.594142022736094, -8.99101304951256],
              [-40.594102247520652, -8.990991164510604],
              [-40.59406245252012, -8.990969267817434],
              [-40.594022675506039, -8.99094738371474],
              [-40.593982880505507, -8.99092548792089],
              [-40.593943104390689, -8.990903603818197],
              [-40.593903308490837, -8.990881708024347],
              [-40.593863533275396, -8.99085982212307],
              [-40.593823737375544, -8.99083792722854],
              [-40.593783961260783, -8.990816040427887],
              [-40.593744166260194, -8.990794145533357],
              [-40.593704389246113, -8.990772260531344],
              [-40.593664589748983, -8.990750362039535],
              [-40.59362481992946, -8.990728479735481],
              [-40.593585033922125, -8.990706588438286],
              [-40.593545238921592, -8.990684692644379],
              [-40.593505463706151, -8.990662808541742],
              [-40.59346566690698, -8.990640912747892],
              [-40.593425890792162, -8.990619027745879],
              [-40.59338609489231, -8.990597132851349],
              [-40.593346319676868, -8.990575246950016],
              [-40.593306524676336, -8.990553352055485],
              [-40.593266747662256, -8.990531466154209],
              [-40.593226952661666, -8.99050956946104],
              [-40.593187175647586, -8.990487684459026],
              [-40.593147380647054, -8.990465788665176],
              [-40.593107598237054, -8.990443900965204],
              [-40.59306781043108, -8.990422008768633],
              [-40.593028034316262, -8.99040012196798],
              [-40.59298823841641, -8.990378227972769],
              [-40.59294845960369, -8.990356341172173],
              [-40.592941193980892, -8.990352342786366],
              [-40.592935104671312, -8.990349153790362],
              [-40.592927140275265, -8.990345493549626],
              [-40.592922350486049, -8.990343645442863],
              [-40.592917450979542, -8.990342095910933],
              [-40.592912469634712, -8.990340855745842],
              [-40.592904891047851, -8.99033946179668],
              [-40.592895517414149, -8.99033826389973],
              [-40.592847508006059, -8.990333274461022],
              [-40.592802281100376, -8.990328576402646],
              [-40.592757055993275, -8.990323877444951],
              [-40.59271183088623, -8.990319178487255],
              [-40.592666607577826, -8.990314480428879],
              [-40.592621381571462, -8.990309780571863],
              [-40.592576155565098, -8.990305081614167],
              [-40.592530927760038, -8.990300382656471],
              [-40.592485702652993, -8.990295683698776],
              [-40.592440477545949, -8.990290985640456],
              [-40.592395253338225, -8.990286285783441],
              [-40.592350027331861, -8.990281586825745],
              [-40.592304788734964, -8.99027688606941],
              [-40.592259576218453, -8.990272188011033],
              [-40.592214352909991, -8.990267489053338],
              [-40.592169126004308, -8.990262790095642],
              [-40.592123899098624, -8.990258091137946],
              [-40.59207867399158, -8.99025339307957],
              [-40.592033447985216, -8.990248693222554],
              [-40.591988223777435, -8.990243993365539],
              [-40.591942997771071, -8.990239294407843],
              [-40.591897772664026, -8.990234595450147],
              [-40.591852590724443, -8.990229900989107],
              [-40.591807317953339, -8.990225201132091],
              [-40.591762092846238, -8.990220502174395],
              [-40.591716866839874, -8.990215803216699],
              [-40.591671639934191, -8.990211103359684],
              [-40.591626416625786, -8.990206404401988],
              [-40.591581189720102, -8.990201704544972],
              [-40.591535967311017, -8.990197005587277],
              [-40.591490739505957, -8.990192306629581],
              [-40.591445512600274, -8.990187606772565],
              [-40.591400287493229, -8.990182907814869],
              [-40.591355062386185, -8.99017820885723],
              [-40.591309836379821, -8.990173509899535],
              [-40.591264611272777, -8.990168810941839],
              [-40.591219387964316, -8.990164111984143],
              [-40.591174158360673, -8.990159413026447],
              [-40.591128935052268, -8.990154714068751],
              [-40.591083709945224, -8.990150014211736],
              [-40.59103848393886, -8.99014531435472],
              [-40.590993257932439, -8.990140615397024],
              [-40.590948032825395, -8.990135916439328],
              [-40.590902805919711, -8.990131217481633],
              [-40.590857584409946, -8.990126518523937],
              [-40.59081234131645, -8.990121817767601],
              [-40.590767129699259, -8.990117118809962],
              [-40.590721904592158, -8.990112419852267],
              [-40.590676679485114, -8.990107720894571],
              [-40.59063145437807, -8.990103021936875],
              [-40.590586229271025, -8.990098322979179],
              [-40.590541005063301, -8.990093623122164],
              [-40.590495776358921, -8.990088922365828],
              [-40.590450550352557, -8.990084225206772],
              [-40.590405325245513, -8.990079525349756],
              [-40.590360101937108, -8.99007482639206],
              [-40.590314875031424, -8.990070126535045],
              [-40.5902696508237, -8.990065427577349],
              [-40.590224425716656, -8.99006072861971],
              [-40.590179197911596, -8.990056029662014],
              [-40.590133974603191, -8.990051329804999],
              [-40.590088746798187, -8.990046630847303],
              [-40.590043521691143, -8.990041931889607],
              [-40.589998296584099, -8.990037231133272],
              [-40.589953071476998, -8.990032532175576],
              [-40.589907844571314, -8.99002783321788],
              [-40.58986262126291, -8.990023135159504],
              [-40.589817394357226, -8.990018434403169],
              [-40.589772167451542, -8.990013733646833],
              [-40.589726942344498, -8.990009035588457],
              [-40.589681717237397, -8.990004336630761],
              [-40.589636490331714, -8.989999636773746],
              [-40.589591267023309, -8.989994937816107],
              [-40.589546043714904, -8.989990237959091],
              [-40.589500815010581, -8.989985538102076],
              [-40.589455590802856, -8.9899808400437],
              [-40.589410365695755, -8.989976141086004],
              [-40.589365139689392, -8.989971441228988],
              [-40.589319899293855, -8.989966738674013],
              [-40.589274688575983, -8.989962041514957],
              [-40.589229462569619, -8.989957340758622],
              [-40.589184237462518, -8.989952641800926],
              [-40.589139011456155, -8.98994794284323],
              [-40.589093785449791, -8.989943243885534],
              [-40.589048560342746, -8.989938544927838],
              [-40.589003335235702, -8.989933845070823],
              [-40.588958117323216, -8.989929147012504],
              [-40.588934282591026, -8.989926668480905],
              [-40.588922750584402, -8.989926097411399],
              [-40.588917754850456, -8.989926163061909],
              [-40.588912771706987, -8.989926534481924],
              [-40.588906203958118, -8.989927440099223],
              [-40.588894770876948, -8.989929690202985],
              [-40.588852482955701, -8.989939178949896],
              [-40.588808117600479, -8.989949133545622],
              [-40.588763769332445, -8.989959082745429],
              [-40.588719413869796, -8.989969035542515],
              [-40.588675047615311, -8.98997899103756],
              [-40.588630702944556, -8.989988940237367],
              [-40.588586333992055, -8.989998895732413],
              [-40.588541992019259, -8.99000884493222],
              [-40.588497620368855, -8.990018799527945],
              [-40.58845327659742, -8.990028748727752],
              [-40.588408922034091, -8.990038701524782],
              [-40.588364550383631, -8.990048658818523],
              [-40.588320207511515, -8.990058607118954],
              [-40.58827584035771, -8.990068560815359],
              [-40.588231494787635, -8.990078510914486],
              [-40.588187140224306, -8.990088463711572],
              [-40.588142768573846, -8.990098419206618],
              [-40.588098423003771, -8.990108367507105],
              [-40.588054058547925, -8.990118321203511],
              [-40.588009712977851, -8.990128272201957],
              [-40.58796534222671, -8.990138226797683],
              [-40.587921002951873, -8.99014817509817],
              [-40.587876643891946, -8.990158128794576],
              [-40.587832271342222, -8.990168083390301],
              [-40.587787933866025, -8.990178031690789],
              [-40.587743560416925, -8.990187988085154],
              [-40.58769921664549, -8.990197936385584],
              [-40.5876548638808, -8.990207887384031],
              [-40.587610490431757, -8.990217844677716],
              [-40.587566147559642, -8.990227792978203],
              [-40.587521781305099, -8.990237748473248],
              [-40.587477435735025, -8.990247697673055],
              [-40.587433079373056, -8.990257650470141],
              [-40.587388710420612, -8.990267605065867],
              [-40.587344365749857, -8.990277553366354],
              [-40.587299999495315, -8.990287509760719],
              [-40.58725565302592, -8.990297458061207],
              [-40.587211282274836, -8.990307412656932],
              [-40.587166943899319, -8.990317362756059],
              [-40.587122583940072, -8.990327313754506],
              [-40.587078214088251, -8.99033727104819],
              [-40.587033873914095, -8.990347217549981],
              [-40.586989500465052, -8.990357173944403],
              [-40.586945158492256, -8.990367121345514],
              [-40.586900804828247, -8.9903770741426],
              [-40.586856431379147, -8.990387029637645],
              [-40.586812087607711, -8.990396978837452],
              [-40.586767722252546, -8.990406933433178],
              [-40.586723375783151, -8.990416883532305],
              [-40.586679005032011, -8.99042683902735],
              [-40.586634664857854, -8.990436786428518],
              [-40.586590304898607, -8.990446740124924],
              [-40.586545935046843, -8.990456694720649],
              [-40.586501594872686, -8.990466643021136],
              [-40.586457225020865, -8.990476598516182],
              [-40.58641288035011, -8.990486547715932],
              [-40.586368526686101, -8.990496498714379],
              [-40.586324149639779, -8.99050645600812],
              [-40.586298121461084, -8.990512294406813],
              [-40.586252586087937, -8.990521706711377],
              [-40.586171365615996, -8.990534464493919],
              [-40.586084516287372, -8.990542621344844],
              [-40.585997316223143, -8.990545371471683],
              [-40.58591010536702, -8.990542699585887],
              [-40.585823236253304, -8.990534617378614],
              [-40.585737058718223, -8.99052115992356],
              [-40.585651870437403, -8.990502373086031],
              [-40.58556803473698, -8.990478332409054],
              [-40.585485875372797, -8.990449134120183],
              [-40.585405711604267, -8.990414889735348],
              [-40.585327848301517, -8.990375734152735],
              [-40.585252595730651, -8.990331814861293],
              [-40.585185390293475, -8.990286757028173],
              [-40.585153657715068, -8.99026335576923],
              [-40.58512947674393, -8.990245008700128],
              [-40.585093323997683, -8.990217577579074],
              [-40.585057173050075, -8.990190150055355],
              [-40.585021032894304, -8.990162729726137],
              [-40.584984877450097, -8.990135295007804],
              [-40.584948739092965, -8.990107874678642],
              [-40.584912582749439, -8.990080440859629],
              [-40.584876442593725, -8.990053019631091],
              [-40.584840285350822, -8.990025586711397],
              [-40.584804146993747, -8.989998164583596],
              [-40.58476799244886, -8.989970732563222],
              [-40.584731842400572, -8.989943305938766],
              [-40.58469570404344, -8.989915884710285],
              [-40.584659546800594, -8.989888450891272],
              [-40.58462340844352, -8.989861030562054],
              [-40.584587250301297, -8.98983359674304],
              [-40.584551111044902, -8.989806175514559],
              [-40.584514964593893, -8.989778748890103],
              [-40.584478807351047, -8.989751315970409],
              [-40.584442661799358, -8.989723889345953],
              [-40.584406513549709, -8.989696460023538],
              [-40.584370373393995, -8.989669041493016],
              [-40.584334215251772, -8.989641607674002],
              [-40.584298078693337, -8.989614185546202],
              [-40.58426192234981, -8.989586752626508],
              [-40.584225783093359, -8.989559330498651],
              [-40.58418963484371, -8.989531904773514],
              [-40.584153478500184, -8.9895044709545],
              [-40.584117332948495, -8.989477045229421],
              [-40.584081182900206, -8.989449616806326],
              [-40.584045043643812, -8.989422195577845],
              [-40.584008887300286, -8.989394761758774],
              [-40.583972748043834, -8.989367341429613],
              [-40.583936591700308, -8.989339908509919],
              [-40.583900451544537, -8.989312485482799],
              [-40.583877454980495, -8.989295036836438],
              [-40.583868002206486, -8.989288500563816],
              [-40.583864914833896, -8.989286639866464],
              [-40.583861716844694, -8.989284972523421],
              [-40.583858419930095, -8.98928350842715],
              [-40.583855040277854, -8.989282252973567],
              [-40.583851583283888, -8.989281207961312],
              [-40.583848082223199, -8.989280382383697],
              [-40.583844529001794, -8.989279774441968],
              [-40.583840944304086, -8.989279389532157],
              [-40.583837353311139, -8.989279228553528],
              [-40.583833756022955, -8.989279290606703],
              [-40.583830166828704, -8.989279575691796],
              [-40.583826608211382, -8.989280082909431],
              [-40.583823084667586, -8.989280809561649],
              [-40.583819619579742, -8.989281753849809],
              [-40.583816230034927, -8.989282907679979],
              [-40.583810642547064, -8.9892852054478],
              [-40.583802617896481, -8.989289038358379],
              [-40.583801248228951, -8.989289732635029],
              [-40.583760735569456, -8.989310303727507],
              [-40.583720243594314, -8.989330863128771],
              [-40.583679748921213, -8.989351423429412],
              [-40.583639251550153, -8.989371986428011],
              [-40.583598765870306, -8.989392540433357],
              [-40.583558258606729, -8.989413109727195],
              [-40.583517761235669, -8.989433670927099],
              [-40.583477264763928, -8.989454231227739],
              [-40.583436779983344, -8.989474787931044],
              [-40.583396271820448, -8.989495355426243],
              [-40.583355769053469, -8.989515920223482],
              [-40.583315277977647, -8.989536478725483],
              [-40.58327479139848, -8.989557033630149],
              [-40.583234286832806, -8.989577602024667],
              [-40.583193787663106, -8.989598165023267],
              [-40.583153292090742, -8.989618724424531],
              [-40.583112795619002, -8.989639286523811],
              [-40.583072309939098, -8.989659841428477],
              [-40.583031800876881, -8.989680410722315],
              [-40.582991304405141, -8.989700971922275],
              [-40.582950803436802, -8.989721535820195],
              [-40.582910322253554, -8.989742088926221],
              [-40.5828698140906, -8.989762656421419],
              [-40.582829317618859, -8.989783217621323],
              [-40.582788822046439, -8.989803779720603],
              [-40.582748324675379, -8.989824340021244],
              [-40.582707838995532, -8.989844895825229],
              [-40.582667331731955, -8.989865462421108],
              [-40.582626834360894, -8.989886025419707],
              [-40.582586337889154, -8.989906586619611],
              [-40.582545845914012, -8.989927146020932],
              [-40.582505344046353, -8.989947710818171],
              [-40.582464847574613, -8.989968272018075],
              [-40.582424351102873, -8.989988833218035],
              [-40.582383864523706, -8.990009389022021],
              [-40.582343357260072, -8.990029956517219],
              [-40.582302860788332, -8.990050517717179],
              [-40.582262363417271, -8.99007107891714],
              [-40.582221866945588, -8.990091639217724],
              [-40.582181376769086, -8.990112198619045],
              [-40.582140856015656, -8.990132772409481],
              [-40.582119158072658, -8.990143789104536],
              [-40.582073326822524, -8.990165973580758],
              [-40.581996837683846, -8.990198368959511],
              [-40.581914443596645, -8.990227365800251],
              [-40.581830380367705, -8.990251192438507],
              [-40.581744984343516, -8.990269751747576],
              [-40.581658595467786, -8.990282971781653],
              [-40.581571538395735, -8.99029080217872],
              [-40.581484155769147, -8.990293210563152],
              [-40.581396789330313, -8.990290188841072],
              [-40.581309785318297, -8.990281746905055],
              [-40.581223477381513, -8.990267918929248],
              [-40.581138207262427, -8.990248757074426],
              [-40.581054305012117, -8.990224339581573],
              [-40.58097210337985, -8.990194756382834],
              [-40.580891918027532, -8.990160129786091],
              [-40.580814053825463, -8.990120583897806],
              [-40.580745244897116, -8.99008005864772],
              [-40.580718271530941, -8.990062637880385],
              [-40.58068967578788, -8.99004375571468],
              [-40.580651790947343, -8.990018738374033],
              [-40.58061391150278, -8.989993729127264],
              [-40.580576033856858, -8.989968715383839],
              [-40.580538162506173, -8.989943710633668],
              [-40.580500275866996, -8.98991869509166],
              [-40.580462395523114, -8.989893680448972],
              [-40.580424516977814, -8.989868668504187],
              [-40.580386639331891, -8.989843656559458],
              [-40.580348760786649, -8.989818644614729],
              [-40.580310881342029, -8.989793632669944],
              [-40.580273003696107, -8.989768621624535],
              [-40.580235122452905, -8.989743609679806],
              [-40.580197244806982, -8.989718597735077],
              [-40.580159365362363, -8.989693585790292],
              [-40.580121496709637, -8.989668581040121],
              [-40.580083610070517, -8.989643561900834],
              [-40.580045729726578, -8.989618550855369],
              [-40.580007857476573, -8.989593542507919],
              [-40.579969970837453, -8.989568526965911],
              [-40.579932091392834, -8.989543515021182],
              [-40.579894214646231, -8.989518502177077],
              [-40.579856334302349, -8.989493491131668],
              [-40.579818457555746, -8.9894684773883],
              [-40.579780579010446, -8.989443465443514],
              [-40.579742698666564, -8.989418454398105],
              [-40.579704821020641, -8.989393443352697],
              [-40.579666952367916, -8.989368437703206],
              [-40.579629063030779, -8.989343419463182],
              [-40.579591185384857, -8.989318407518454],
              [-40.579553305940294, -8.989293395573725],
              [-40.579515426495675, -8.98926838272962],
              [-40.579477548849752, -8.989243370784891],
              [-40.57943966760655, -8.989218358840162],
              [-40.57940179085989, -8.989193345996057],
              [-40.579363912314648, -8.989168334051328],
              [-40.579326040963963, -8.989143327502518],
              [-40.579288155224162, -8.98911831196051],
              [-40.579250283873478, -8.989093305411643],
              [-40.579212395435661, -8.989068288070996],
              [-40.579174517789738, -8.989043275226948],
              [-40.579136639244439, -8.989018262382842],
              [-40.579098761598516, -8.988993249538794],
              [-40.579060883952593, -8.988968238493385],
              [-40.579023003608711, -8.988943227447919],
              [-40.578985125962731, -8.988918214603871],
              [-40.578947245618849, -8.988893202659142],
              [-40.578909368872246, -8.988868190714356],
              [-40.578871489427627, -8.988843178769628],
              [-40.578833610882384, -8.988818166824899],
              [-40.578795742229659, -8.988793161175408],
              [-40.578757852892579, -8.988768142935385],
              [-40.57871997344796, -8.988743130990656],
              [-40.578682096701357, -8.988718118146608],
              [-40.578646431387654, -8.988694568499511],
              [-40.578637216933998, -8.988688829026216],
              [-40.578632608807823, -8.988686315421091],
              [-40.578629305597929, -8.988684773083776],
              [-40.578625913355154, -8.988683438489829],
              [-40.578622447367991, -8.988682319733243],
              [-40.578618915730317, -8.988681415914584],
              [-40.578615336428584, -8.988680732429827],
              [-40.578611726549923, -8.988680276473531],
              [-40.578608095986795, -8.988680044448472],
              [-40.578604461826387, -8.988680039052497],
              [-40.578600826766717, -8.988680258487079],
              [-40.57859721508936, -8.988680702752163],
              [-40.578590837097408, -8.988681896152514],
              [-40.578583137102044, -8.988683768541023],
              [-40.578579517330809, -8.988684738909512],
              [-40.578535598938686, -8.988696506538474],
              [-40.578491703928933, -8.988708266972878],
              [-40.57844778553681, -8.988720034601897],
              [-40.578403883332498, -8.988731797734204],
              [-40.578359989222065, -8.988743558168608],
              [-40.578333333316664, -8.988750699684999],
              [-40.578316071729262, -8.988755324898307],
              [-40.578272175820189, -8.988767087131293],
              [-40.578228261025401, -8.988778852961673],
              [-40.578184353425115, -8.988790616094036],
              [-40.578140462912017, -8.988802375629064],
              [-40.578096540922559, -8.988814145956042],
              [-40.578052637818928, -8.988825908189085],
              [-40.578008743708494, -8.988837667724113],
              [-40.577964826215691, -8.988849434453812],
              [-40.577920933903954, -8.988861193988839],
              [-40.577877012813872, -8.988872962517178],
              [-40.577833109710184, -8.988884723850902],
              [-40.577789215599751, -8.988896483385929],
              [-40.577745299006324, -8.988908252813587],
              [-40.57770140579521, -8.988920011449295],
              [-40.577657483805808, -8.988931780876953],
              [-40.57761358160144, -8.988943543109997],
              [-40.577569688390383, -8.988955301745705],
              [-40.57752577089758, -8.988967069374723],
              [-40.577481874089187, -8.988978830708447],
              [-40.577437958395024, -8.98899059653877],
              [-40.577394053492753, -8.989002361469772],
              [-40.57735016028164, -8.989014121004857],
              [-40.577306241889517, -8.989025887734499],
              [-40.577262346879763, -8.989037649967543],
              [-40.57721842938696, -8.989049415797865],
              [-40.57717452448469, -8.989061178030909],
              [-40.577130633072215, -8.989072937565993],
              [-40.577086713780773, -8.989084703396315],
              [-40.577042805281224, -8.989096470125958],
              [-40.576998915667389, -8.989108228761722],
              [-40.576954995476626, -8.989119994592045],
              [-40.576911102265512, -8.989131755925769],
              [-40.576867186571349, -8.989143522655411],
              [-40.57682327627316, -8.989155286687094],
              [-40.576779389357284, -8.989167044423539],
              [-40.576761816604517, -8.989171754173071],
              [-40.576691575056088, -8.989190572486905],
              [-40.576664439812021, -8.989197843505622],
              [-40.576559858550581, -8.989225862783314],
              [-40.576473281716517, -8.989249059896167],
              [-40.57556722734563, -8.989496088373755],
              [-40.570155050615028, -8.991647660611022],
              [-40.570144104067083, -8.991649735346982],
              [-40.569496099365438, -8.991908338099279],
              [-40.569497215424121, -8.991909171770828],
              [-40.566518788511246, -8.993093176009211],
              [-40.564406350272805, -8.993933527710112],
              [-40.563519535098408, -8.994287489176259],
              [-40.563094055945896, -8.994398567140138],
              [-40.562564480266531, -8.994361206604367],
              [-40.55999999870096, -8.993503659865951],
              [-40.559973855409055, -8.993494917556347],
              [-40.559462486504515, -8.993333325572451],
              [-40.559377814434526, -8.993306569842275],
              [-40.559309033385148, -8.993294661919037],
              [-40.559166666208625, -8.99283544290131],
              [-40.559018926482224, -8.992358893048447],
              [-40.556724637232207, -8.991935048863354],
              [-40.556524196334578, -8.991938074182713],
              [-40.554574244006119, -8.991920042775689],
              [-40.552189184383337, -8.988614137632283],
              [-40.551948590554957, -8.987623287085],
              [-40.551871828921719, -8.98749999362974],
              [-40.551666667482664, -8.987170467642898],
              [-40.551352999241487, -8.986666660238143],
              [-40.550834174957174, -8.985833325947169],
              [-40.550530151045564, -8.98534500037141],
              [-40.549531735401217, -8.985300432668794],
              [-40.549166665509176, -8.985286080388278],
              [-40.548560666341643, -8.985262256447925],
              [-40.549214746863015, -8.990504615095858],
              [-40.549564010270842, -8.993277412022906],
              [-40.549574084476376, -8.993360212603704],
              [-40.551312473991459, -8.993133170659235],
              [-40.553191500387413, -8.992887753766752],
              [-40.553484828661851, -8.992849747517766],
              [-40.553805660902469, -8.992818811738744],
              [-40.553836873672765, -8.992818144441742],
              [-40.5539975708312, -8.992824570997129],
              [-40.554072360251155, -8.992829135955787],
              [-40.554149859328504, -8.994097004672994],
              [-40.547606915526671, -9.000212398183578],
              [-40.549656086459152, -9.003806331896669],
              [-40.549569004206148, -9.003372539411146],
              [-40.549545995950893, -9.002902826204433],
              [-40.549636263602622, -9.002412894439487],
              [-40.549766567273196, -9.002029877676648],
              [-40.549944870459342, -9.0017379739283],
              [-40.550025265353668, -9.001616676068693],
              [-40.55013872742029, -9.001469884227731],
              [-40.550384996870093, -9.001210311106945],
              [-40.550623369372943, -9.001032520534352],
              [-40.550770427413227, -9.0009387886941],
              [-40.551117412838266, -9.000760947759488],
              [-40.551350886733985, -9.000692843000252],
              [-40.551575448348217, -9.000632872608946],
              [-40.551872632915661, -9.00057592483904],
              [-40.552285919959218, -9.000523711100357],
              [-40.552584357282228, -9.000463077009329],
              [-40.552730549275395, -8.999798308944776],
              [-40.553454635722801, -8.996410841587021],
              [-40.554822839102712, -8.996430986400867],
              [-40.556090337299167, -8.996449645534653],
              [-40.559999999600279, -8.996433119592723],
              [-40.562808177655256, -8.99642121796478],
              [-40.562823083018827, -8.999894160487088],
              [-40.561178744603353, -8.999896804493858],
              [-40.561131969065059, -9.003120377603295],
              [-40.55999999870096, -9.003103437973209],
              [-40.559249550426387, -9.003092204541588],
              [-40.559270244725951, -9.001666658589386],
              [-40.559282341506787, -9.000833326996371],
              [-40.559295482400557, -8.999928018163473],
              [-40.557058417012286, -8.999895914165052],
              [-40.557012482340156, -9.003058867572634],
              [-40.554937511656817, -9.003044538674487],
              [-40.554920983016927, -9.004166659663554],
              [-40.554869472548091, -9.007663561923778],
              [-40.557685404557731, -9.007651654899917],
              [-40.557678163216622, -9.00596085301089],
              [-40.561666665484154, -9.005943955649002],
              [-40.564408296405702, -9.005932308529168],
              [-40.564410006916262, -9.006330142522017],
              [-40.566112295845755, -9.006322904778187],
              [-40.567819373665145, -9.007789463412962],
              [-40.567833796092771, -9.011134897357579],
              [-40.57166666708298, -9.011118550380729],
              [-40.573333332067591, -9.011111426850846],
              [-40.576685261815328, -9.011097070073674],
              [-40.576735265020432, -9.015833325347614],
              [-40.576740733797806, -9.016351212538382],
              [-40.57749999902569, -9.016347478553257],
              [-40.578443055102866, -9.016342835353498],
              [-40.578447815214474, -9.017499993929505],
              [-40.578470887321544, -9.02310707802917],
              [-40.58012847454944, -9.02512456494668],
              [-40.586666666333542, -9.02511561579297],
              [-40.586678626417438, -9.025115599605215],
              [-40.586679628262175, -9.025833325147801],
              [-40.586685704081958, -9.030186917092522],
              [-40.586695943762777, -9.037499993529821],
              [-40.586702527699458, -9.042203326422339],
              [-40.589924466740683, -9.042816241376386],
              [-40.594378587601682, -9.043663499870604],
              [-40.599563464282426, -9.048156725052763],
              [-40.603570616690547, -9.052218284646074],
              [-40.602659652021259, -9.052764994209042],
              [-40.602205975525294, -9.053042485422338],
              [-40.602031665329037, -9.053149101849499],
              [-40.601409670819635, -9.053584184862189],
              [-40.601291309246506, -9.053701101224817],
              [-40.601261723349808, -9.053730334587215],
              [-40.601241999418676, -9.053756316900433],
              [-40.601236952423392, -9.053762965588362],
              [-40.601222274588281, -9.053782297415069],
              [-40.601202549757829, -9.053811525381548],
              [-40.600994528474644, -9.054105147734333],
              [-40.60093480179961, -9.054193639225048],
              [-40.600916542864127, -9.054214256182945],
              [-40.600893536407511, -9.054240233999565],
              [-40.600870520058436, -9.054266220809382],
              [-40.600850791630705, -9.054295448775918],
              [-40.600834356520295, -9.054324679440356],
              [-40.600811346466401, -9.054353903809556],
              [-40.600773869018781, -9.054420562458915],
              [-40.600666696810549, -9.054535787196755],
              [-40.600617382486178, -9.054610487583886],
              [-40.600597663950964, -9.054639715550366],
              [-40.600595444424187, -9.054642271423631],
              [-40.600553003618188, -9.054700934200696],
              [-40.600541773783789, -9.054720909941977],
              [-40.600522048953337, -9.054746891355933],
              [-40.600478148547666, -9.054811933923588],
              [-40.60046287176408, -9.05483132870279],
              [-40.600415345291935, -9.054885010135081],
              [-40.600308349350826, -9.054977493716308],
              [-40.600304311394837, -9.054982620751275],
              [-40.60013944677803, -9.055112892046282],
              [-40.600111067771536, -9.055126912476965],
              [-40.600081480076199, -9.055143156031761],
              [-40.600051890582222, -9.055159397787918],
              [-40.600022299289662, -9.055175638644755],
              [-40.599992700802432, -9.055198370808114],
              [-40.599967295853958, -9.055217883398541],
              [-40.599547461745942, -9.055919540754132],
              [-40.599400341652427, -9.056230079354634],
              [-40.599350183763931, -9.056332488753299],
              [-40.59933011539249, -9.056358923425535],
              [-40.599249843705252, -9.056517518868361],
              [-40.599229805011475, -9.0565373219398],
              [-40.599206394759278, -9.056567053526635],
              [-40.599186325488461, -9.056593480104993],
              [-40.599021778332371, -9.056810168154698],
              [-40.598819769017098, -9.056965440602539],
              [-40.598649730600528, -9.057133092218407],
              [-40.598636408943094, -9.057148140574157],
              [-40.598619266965557, -9.057161308447576],
              [-40.598605934516229, -9.057172595838608],
              [-40.598590703598063, -9.05718576461129],
              [-40.598573568815084, -9.057200812067776],
              [-40.598560239063715, -9.057213980840515],
              [-40.59854500544759, -9.057229027397625],
              [-40.598531673897583, -9.05724407845139],
              [-40.598516439382138, -9.057257248123449],
              [-40.598499296505281, -9.057270415097548],
              [-40.598480252461627, -9.057281700689884],
              [-40.598395519237783, -9.057318902045665],
              [-40.598323488038488, -9.057338304019481],
              [-40.598207120262373, -9.057367035560219],
              [-40.597646671756479, -9.057468787554456],
              [-40.597352510709754, -9.057527006066437],
              [-40.597209886327164, -9.05755518182616],
              [-40.597180847218283, -9.057559579510951],
              [-40.597189889002152, -9.057588573653732],
              [-40.597822020669696, -9.060271008500195],
              [-40.598070276721842, -9.061037005153594],
              [-40.598672233436787, -9.062322526663252],
              [-40.598869440372312, -9.062506025232835],
              [-40.599638265393594, -9.063600352674086],
              [-40.599841745100434, -9.064027414633415],
              [-40.599409095653186, -9.064219625834994],
              [-40.602474286459085, -9.068255088901196],
              [-40.602955714234781, -9.070758958858789],
              [-40.602997842976038, -9.070833327396087],
              [-40.602499998975702, -9.070833325597448],
              [-40.602500000774398, -9.071666658989102],
              [-40.601666666483425, -9.071666658989102],
              [-40.601666665584105, -9.072499992380699],
              [-40.597500000424645, -9.072499992380699],
              [-40.597499999525326, -9.073333325772296],
              [-40.594999998451101, -9.073333325772296],
              [-40.594999999350421, -9.072499992380699],
              [-40.594166667757463, -9.072499992380699],
              [-40.594166665958824, -9.071666658989102],
              [-40.593333333466546, -9.071666658989102],
              [-40.593333333466546, -9.072499991481379],
              [-40.592500000974212, -9.072499992380699],
              [-40.592500000074892, -9.074999992555547],
              [-40.591666666683295, -9.074999992555547],
              [-40.591666667582615, -9.075833325947201],
              [-40.589999999900044, -9.075833325947201],
              [-40.590000000799364, -9.076666660238118],
              [-40.585833332941945, -9.076666658439478],
              [-40.585833334740585, -9.074999992555547],
              [-40.585000000449611, -9.074999992555547],
              [-40.584999999550291, -9.07416665916395],
              [-40.584166665259374, -9.07416665916395],
              [-40.584166665259374, -9.073333325772296],
              [-40.582499999375443, -9.073333324872976],
              [-40.582499999375443, -9.072499991481379],
              [-40.581666665983789, -9.072499992380699],
              [-40.581666665983789, -9.071666658089725],
              [-40.574999998850842, -9.071666658989102],
              [-40.575000000649482, -9.072499992380699],
              [-40.573333333866231, -9.072499992380699],
              [-40.573333332067591, -9.071666658989102],
              [-40.570833332792006, -9.071666658989102],
              [-40.570833333691326, -9.072499992380699],
              [-40.569166664210115, -9.072499992380699],
              [-40.568333330818518, -9.072499992380699],
              [-40.568333331717838, -9.069166658814197],
              [-40.567499999225561, -9.069166657914877],
              [-40.5675000010242, -9.067499992030946],
              [-40.566666666733227, -9.067499992930266],
              [-40.566666667632546, -9.065833326147015],
              [-40.56583333244231, -9.065833326147015],
              [-40.56583333244231, -9.064999991856098],
              [-40.564999999050656, -9.064999991856098],
              [-40.564999999050656, -9.064166658464444],
              [-40.564166666558378, -9.064166657565124],
              [-40.564166665659059, -9.063333325072847],
              [-40.562500000674447, -9.063333325972167],
              [-40.562499999775127, -9.06249999168125],
              [-40.561666664584834, -9.06249999258057],
              [-40.56166666638353, -9.063333325972167],
              [-40.558333332817028, -9.063333325972167],
              [-40.558333331917709, -9.06249999168125],
              [-40.555833331742804, -9.06249999168125],
              [-40.555833331742804, -9.061666657390276],
              [-40.555000001948486, -9.061666657390276],
              [-40.554999999250526, -9.060833324897999],
              [-40.554166666758249, -9.060833325797319],
              [-40.554166667657569, -9.059166659014068],
              [-40.553333332467275, -9.059166659014068],
              [-40.553333332467275, -9.058333325622414],
              [-40.553333331567956, -9.056666658839163],
              [-40.554166666758249, -9.056666657939843],
              [-40.554166665858872, -9.055833324548246],
              [-40.555000001049166, -9.055833325447566],
              [-40.555000001049166, -9.054999993854608],
              [-40.556666666033777, -9.054999992955288],
              [-40.556666667832417, -9.054166658664315],
              [-40.560833332092557, -9.054166658664315],
              [-40.560833332991876, -9.053333326172037],
              [-40.564166667457698, -9.053333326172037],
              [-40.564166665659059, -9.052499992780383],
              [-40.564999999050656, -9.052499992780383],
              [-40.564999999050656, -9.051666658489466],
              [-40.56583333154299, -9.051666658489466],
              [-40.56583333154299, -9.049999991706215],
              [-40.566666665833907, -9.049999991706215],
              [-40.566666666733227, -9.048333325822284],
              [-40.568333331717838, -9.048333325822284],
              [-40.568333332617158, -9.046666659039033],
              [-40.569166667807451, -9.046666659039033],
              [-40.569166666008812, -9.044999993155102],
              [-40.569999998501089, -9.044999993155102],
              [-40.569999998501089, -9.043333326371851],
              [-40.570833332792006, -9.043333325472531],
              [-40.570833330993366, -9.04166665778996],
              [-40.569999998501089, -9.04166665868928],
              [-40.570000000299729, -9.040833324398363],
              [-40.566666667632546, -9.040833326197003],
              [-40.566666665833907, -9.039166658514432],
              [-40.564999999949976, -9.039166658514432],
              [-40.564999999949976, -9.039999992805349],
              [-40.564166666558378, -9.039999992805349],
              [-40.564166666558378, -9.04166665868928],
              [-40.560833332991876, -9.04166665868928],
              [-40.560833333891196, -9.040833327096323],
              [-40.560000000499599, -9.040833326197003],
              [-40.559999999600279, -9.04166665778996],
              [-40.559166665309306, -9.04166665868928],
              [-40.559166666208625, -9.039999992805349],
              [-40.558333331917709, -9.039999992805349],
              [-40.558333331917709, -9.039166658514432],
              [-40.556666666933097, -9.039166658514432],
              [-40.556666666033777, -9.038333326022155],
              [-40.554166666758249, -9.038333326022155],
              [-40.554166665858872, -9.037499992630501],
              [-40.551666665684024, -9.037499992630501],
              [-40.551666665684024, -9.036666659238904],
              [-40.546666666233591, -9.036666659238904],
              [-40.546666668032287, -9.03583332584725],
              [-40.545833332841994, -9.03583332584725],
              [-40.545833331942674, -9.034999992455653],
              [-40.543333333566466, -9.034999992455653],
              [-40.543333332667146, -9.034166659063999],
              [-40.542499998376172, -9.034166659063999],
              [-40.542499999275492, -9.033333325672402],
              [-40.541666665883895, -9.033333325672402],
              [-40.541666665883895, -9.032499993180068],
              [-40.540833332492241, -9.032499993180068],
              [-40.540833331592921, -9.031666658889151],
              [-40.54, -9.031666658889151],
              [-40.54, -9.029999993005219],
              [-40.535833331243225, -9.029999993005219],
              [-40.534166666258614, -9.029999993005219],
              [-40.534166665359294, -9.029166659613622],
              [-40.530000001099154, -9.029166658714303],
              [-40.529999998401138, -9.029999993005219],
              [-40.52916666680818, -9.029999993005219],
              [-40.5291666677075, -9.031666658889151],
              [-40.528333331617944, -9.031666658889151],
              [-40.528333332517263, -9.034166659063999],
              [-40.525833331443039, -9.034166659063999],
              [-40.525833331443039, -9.034999992455653],
              [-40.52333333396615, -9.034999992455653],
              [-40.523333331268191, -9.03583332584725],
              [-40.521666666283579, -9.03583332494793],
              [-40.521666666283579, -9.034999992455653],
              [-40.520833332891982, -9.034999992455653],
              [-40.520833332891982, -9.034166659063999],
              [-40.521666666283579, -9.034166659063999],
              [-40.521666668082219, -9.033333325672402],
              [-40.520833332891982, -9.033333325672402],
              [-40.520833332891982, -9.031666658889151],
              [-40.521666665384259, -9.03166665978847],
              [-40.521666666283579, -9.026666658539398],
              [-40.520833332891982, -9.026666659438717],
              [-40.520833331992662, -9.02583332604712],
              [-40.519166667008051, -9.02583332604712],
              [-40.519166667907371, -9.024999992655466],
              [-40.518333333616397, -9.024999992655466],
              [-40.518333331817757, -9.02416665836455],
              [-40.51749999932548, -9.02416665836455],
              [-40.517499997526841, -9.023333325872272],
              [-40.515833333441549, -9.023333326771592],
              [-40.515833332542229, -9.022499992480618],
              [-40.514166665758978, -9.022499992480618],
              [-40.514166666658298, -9.021666659089021],
              [-40.513333331468004, -9.021666659089021],
              [-40.513333332367324, -9.020833325697367],
              [-40.512499998076407, -9.020833325697367],
              [-40.512500000774367, -9.01999999320509],
              [-40.511666667382769, -9.01999999320509],
              [-40.51166666648345, -9.019166658914116],
              [-40.510833331293156, -9.019166658914116],
              [-40.510833333091796, -9.018333325522519],
              [-40.509166665409225, -9.018333325522519],
              [-40.509166665409225, -9.017499993030185],
              [-40.505833331842723, -9.017499993030185],
              [-40.505833331842723, -9.016666658739268],
              [-40.503333333466514, -9.016666658739268],
              [-40.503333332567195, -9.018333326421839],
              [-40.501666666683263, -9.018333325522519],
              [-40.501666667582583, -9.016666659638588],
              [-40.499999999000693, -9.016666658739268],
              [-40.499999999900012, -9.015833325347614],
              [-40.499166665609096, -9.015833324448295],
              [-40.499166666508415, -9.014999993754657],
              [-40.497499998825845, -9.014999991956017],
              [-40.497499999725164, -9.01416665946374],
              [-40.496666669031526, -9.01416665856442],
              [-40.496666666333567, -9.011666657490196],
              [-40.497499998825845, -9.011666657490196],
              [-40.497499998825845, -9.010833324098598],
              [-40.498333334016081, -9.010833325897238],
              [-40.498333331318122, -9.008333324823013],
              [-40.497500000624484, -9.008333324823013],
              [-40.497500000624484, -9.007499993230056],
              [-40.498333331318122, -9.007499993230056],
              [-40.498333332217442, -9.006666658039762],
              [-40.499166665609096, -9.006666658939139],
              [-40.499166666508415, -9.004999993055208],
              [-40.500833331493027, -9.004999993954527],
              [-40.500833331493027, -9.004166657864914],
              [-40.502499998276278, -9.004166658764234],
              [-40.502499998276278, -9.003333324473317],
              [-40.501666665783944, -9.003333324473317],
              [-40.501666664884624, -9.001666658589386],
              [-40.504166665958849, -9.001666657690066],
              [-40.504166668656808, -9.000833325197732],
              [-40.504999998451126, -9.000833326097052],
              [-40.504999998451126, -8.999999992705455],
              [-40.504166667757488, -8.999999991806135],
              [-40.504166665958849, -8.999166658414481],
              [-40.504999998451126, -8.999166658414481],
              [-40.504999999350446, -8.996750826689265],
              [-40.504983053425121, -8.996666659138953],
              [-40.503446246047361, -8.989033751406453],
              [-40.503333332567195, -8.988472926084626],
              [-40.502801900687132, -8.985833325947169],
              [-40.502500000074917, -8.984333774783806],
              [-40.5024663546385, -8.984166657365279],
              [-40.500833331493027, -8.984166660063238],
              [-40.500833332392347, -8.984999992555572],
              [-40.499999999000693, -8.984999993454892],
              [-40.500000000799332, -8.985833325047849],
              [-40.499166667407735, -8.985833325947169],
              [-40.499166666508415, -8.986666658439503],
              [-40.498333334016081, -8.986666658439503],
              [-40.498333332217442, -8.9874999918311],
              [-40.496666666333567, -8.9874999918311],
              [-40.496666664534871, -8.988333326122074],
              [-40.495833333841233, -8.988333325222754],
              [-40.495833333841233, -8.989166658614351],
              [-40.495000001348956, -8.989166658614351],
              [-40.495000000449636, -8.989999992905325],
              [-40.494166665259343, -8.989999992905325],
              [-40.494166667057982, -8.990833325397602],
              [-40.492499999375411, -8.990833325397602],
              [-40.492500000274731, -8.994166658964104],
              [-40.487500000824355, -8.994166658964104],
              [-40.487500000824355, -8.994999992355702],
              [-40.48500000064945, -8.994999991456382],
              [-40.48499999885081, -8.996666659138953],
              [-40.484166665459213, -8.996666660038272],
              [-40.484166669056492, -8.997499991631287],
              [-40.483333332966879, -8.997499992530607],
              [-40.483333332966879, -8.998333325922204],
              [-40.482499998675962, -8.998333325922204],
              [-40.482499999575282, -8.999166658414481],
              [-40.480000000299754, -8.999166657515161],
              [-40.479999998501114, -9.000833326097052],
              [-40.478333333516503, -9.000833326097052],
              [-40.478333331717863, -9.002499992880303],
              [-40.477500001024168, -9.002499992880303],
              [-40.477499998326209, -9.004166657864914],
              [-40.476666665833932, -9.004166658764234],
              [-40.476666667632571, -9.004999993055208],
              [-40.475833333341598, -9.004999993055208],
              [-40.475833332442278, -9.005833326446805],
              [-40.474999999050681, -9.005833324648165],
              [-40.474999999050681, -9.006666658039762],
              [-40.47333333226743, -9.006666658939139],
              [-40.473333334066069, -9.005833325547485],
              [-40.472499998875833, -9.005833324648165],
              [-40.472499999775152, -9.004999992155888],
              [-40.471666667282818, -9.004999992155888],
              [-40.471666665484179, -9.004166657864914],
              [-40.468333332816997, -9.004166658764234],
              [-40.468333331917677, -9.004999992155888],
              [-40.46749999762676, -9.004999993055208],
              [-40.467499999425399, -9.005833325547485],
              [-40.464166667657537, -9.005833324648165],
              [-40.464166667657537, -9.006666658939139],
              [-40.462500000874286, -9.006666658939139],
              [-40.462499999075646, -9.007499993230056],
              [-40.459999998900798, -9.007499991431416],
              [-40.459166665509144, -9.007499993230056],
              [-40.459166665509144, -9.006666658939139],
              [-40.458333333016867, -9.006666658939139],
              [-40.458333333016867, -9.005833325547485],
              [-40.457499998725893, -9.005833324648165],
              [-40.457500001423909, -9.003333325372637],
              [-40.455833331043323, -9.003333325372637],
              [-40.455833331942699, -9.001666658589386],
              [-40.455000000349685, -9.001666658589386],
              [-40.454999998551045, -9.000833326097052],
              [-40.453333333566434, -9.000833326097052],
              [-40.453333331767794, -8.998333325922204],
              [-40.454166667857407, -8.998333325922204],
              [-40.454166666058768, -8.997499993429926],
              [-40.455833331043323, -8.997499992530607],
              [-40.455833331942699, -8.994999992355702],
              [-40.456666668032256, -8.994999992355702],
              [-40.456666666233616, -8.9916666587892],
              [-40.455833332842019, -8.9916666587892],
              [-40.455833332842019, -8.989166658614351],
              [-40.456666668032256, -8.989166658614351],
              [-40.456666666233616, -8.98749999273042],
              [-40.455833331942699, -8.98749999273042],
              [-40.455833332842019, -8.986666658439503],
              [-40.456666665334296, -8.986666658439503],
              [-40.456666665334296, -8.985833325047849],
              [-40.455833331942699, -8.985833325947169],
              [-40.455833331942699, -8.983333326671641],
              [-40.455000000349685, -8.983333325772321],
              [-40.454999999450365, -8.982499992380724],
              [-40.454166666058768, -8.982499992380724],
              [-40.454166667857407, -8.98166665898907],
              [-40.454999998551045, -8.98166665898907],
              [-40.454999998551045, -8.980833325597473],
              [-40.455833332842019, -8.980833324698153],
              [-40.455833331942699, -8.979999994004459],
              [-40.456666666233616, -8.979999993105139],
              [-40.456666665334296, -8.97583332614704],
              [-40.455833332842019, -8.97583332614704],
              [-40.454999999450365, -8.97583332614704],
              [-40.454999999450365, -8.974999992755443],
              [-40.454166666058768, -8.974999992755443],
              [-40.454166667857407, -8.971666659188941],
              [-40.452499998376197, -8.971666659188941],
              [-40.452499998376197, -8.970833325797287],
              [-40.448333332317418, -8.970833326696606],
              [-40.448333333216738, -8.971666659188941],
              [-40.446666667332806, -8.971666659188941],
              [-40.446666665534167, -8.972499992580538],
              [-40.444999997851596, -8.972499992580538],
              [-40.445000000549555, -8.973333325972192],
              [-40.444166665359262, -8.973333325972192],
              [-40.441666666083734, -8.973333325972192],
              [-40.441666666083734, -8.972499992580538],
              [-40.44083333269208, -8.972499993479857],
              [-40.44083333179276, -8.971666659188941],
              [-40.439166665908886, -8.971666659188941],
              [-40.439166665009566, -8.972499992580538],
              [-40.43666666753262, -8.972499992580538],
              [-40.43666666753262, -8.974166658464469],
              [-40.433333331268159, -8.974166658464469],
              [-40.433333331268159, -8.97583332614704],
              [-40.432499998775882, -8.97583332614704],
              [-40.432500000574521, -8.976666658639317],
              [-40.429999998601033, -8.976666658639317],
              [-40.429999999500353, -8.977499992930291],
              [-40.424166665759003, -8.977499992930291],
              [-40.424166667557643, -8.979166658814222],
              [-40.422500000774392, -8.979166658814222],
              [-40.422500000774392, -8.978333325422568],
              [-40.420833333091821, -8.978333324523248],
              [-40.420833333091821, -8.977499993829611],
              [-40.419999999700167, -8.977499992930291],
              [-40.419999999700167, -8.97583332614704],
              [-40.418333332017596, -8.975833327046359],
              [-40.418333333816236, -8.974166658464469],
              [-40.421666664684778, -8.974166658464469],
              [-40.421666667382738, -8.973333325972192],
              [-40.422499998975752, -8.973333325972192],
              [-40.422499998975752, -8.972499992580538],
              [-40.423333332367349, -8.972499991681218],
              [-40.423333331468029, -8.970833325797287],
              [-40.424166665759003, -8.970833325797287],
              [-40.424166666658323, -8.969166659014036],
              [-40.426666666833171, -8.969166659014036],
              [-40.426666666833171, -8.968333325622439],
              [-40.428333330918463, -8.968333325622439],
              [-40.428333333616422, -8.966666658839188],
              [-40.429166667907339, -8.966666657939868],
              [-40.429166667907339, -8.964999992055937],
              [-40.429999999500353, -8.964999993854576],
              [-40.429999998601033, -8.96416665866434],
              [-40.431666665384284, -8.96416665866434],
              [-40.431666664484908, -8.963333326172005],
              [-40.433333331268159, -8.963333326172005],
              [-40.433333333066798, -8.962499992780408],
              [-40.434166667357772, -8.962499992780408],
              [-40.434166666458452, -8.959166659213906],
              [-40.435833332342384, -8.959166659213906],
              [-40.435833333241703, -8.958333324922933],
              [-40.436666665733981, -8.958333325822309],
              [-40.4366666666333, -8.959166657415267],
              [-40.439166667707525, -8.959166659213906],
              [-40.439166666808205, -8.958333325822309],
              [-40.439999998401163, -8.958333324922933],
              [-40.440000001099122, -8.954999991356487],
              [-40.44083333179276, -8.954999993155127],
              [-40.44083333269208, -8.954166658864153],
              [-40.441666666983053, -8.954166659763473],
              [-40.441666667882373, -8.953333325472556],
              [-40.44250000037465, -8.953333325472556],
              [-40.44250000037465, -8.952499992980222],
              [-40.443333331967665, -8.952499991181583],
              [-40.443333332866985, -8.951666658689305],
              [-40.444166666258582, -8.951666658689305],
              [-40.444166668057221, -8.950833326197028],
              [-40.445000001448875, -8.950833326197028],
              [-40.445000000549555, -8.949166658514457],
              [-40.445833333041833, -8.949166658514457],
              [-40.445833331243193, -8.948333326022123],
              [-40.446666665534167, -8.948333326022123],
              [-40.446666665534167, -8.947499990831886],
              [-40.447499998925764, -8.947499991731206],
              [-40.447499998925764, -8.944999992455621],
              [-40.448333333216738, -8.944999992455621],
              [-40.448333333216738, -8.944166658164704],
              [-40.448595044925355, -8.944166659064024],
              [-40.448482565817699, -8.944032066526574],
              [-40.448224476578275, -8.943716437763328],
              [-40.44794185293415, -8.94336515987419],
              [-40.447641525236179, -8.9429870354233],
              [-40.447332409363185, -8.942593403962348],
              [-40.447259855657762, -8.942499994079412],
              [-40.447023425690475, -8.942195604143762],
              [-40.446723490996249, -8.941804971921897],
              [-40.446666666433487, -8.941729978355795],
              [-40.446618687602324, -8.941666658889176],
              [-40.445833331243193, -8.941666658889176],
              [-40.445833333041833, -8.940833325497522],
              [-40.444999999650236, -8.940833326396842],
              [-40.445000000549555, -8.939999992105925],
              [-40.444166665359262, -8.939999993005245],
              [-40.444166666258582, -8.939166657814951],
              [-40.442499999475331, -8.939166658714271],
              [-40.442499999475331, -8.938333326221994],
              [-40.441666666983053, -8.938333327121313],
              [-40.440833333591456, -8.938333324423354],
              [-40.44083333269208, -8.939166658714271],
              [-40.440000001099122, -8.939166658714271],
              [-40.439999998401163, -8.939999993005245],
              [-40.439166665908886, -8.939999993005245],
              [-40.439166666808205, -8.940833326396842],
              [-40.438333332517232, -8.940833325497522],
              [-40.438333331617912, -8.94333332567237],
              [-40.437499998226315, -8.94333332567237],
              [-40.437499998226315, -8.944166658164704],
              [-40.434166665559133, -8.944166659064024],
              [-40.434166666458452, -8.94333332567237],
              [-40.432499999675201, -8.94333332657169],
              [-40.432499999675201, -8.942499991381453],
              [-40.429166667907339, -8.942499991381453],
              [-40.429166667907339, -8.94333332567237],
              [-40.425833332542197, -8.94333332477305],
              [-40.425833333441517, -8.942499993180093],
              [-40.424166666658323, -8.942499993180093],
              [-40.424166667557643, -8.940833325497522],
              [-40.423333333266669, -8.940833325497522],
              [-40.423333331468029, -8.939999993005245],
              [-40.422499999875072, -8.939999993005245],
              [-40.422499998975752, -8.939166658714271],
              [-40.421666665584098, -8.939166658714271],
              [-40.421666667382738, -8.938333326221994],
              [-40.420833333091821, -8.938333327121313],
              [-40.420833333991141, -8.936666657640103],
              [-40.419999999700167, -8.936666658539423],
              [-40.419999999700167, -8.934999992655491],
              [-40.41916666630857, -8.934999992655491],
              [-40.41916666630857, -8.93333332587224],
              [-40.418333332017596, -8.93333332677156],
              [-40.418333333816236, -8.929999993205058],
              [-40.415833332742068, -8.929999993205058],
              [-40.415833331842748, -8.92749999303021],
              [-40.414166665958817, -8.92749999303021],
              [-40.414166668656776, -8.926666658739236],
              [-40.413333333466539, -8.926666658739236],
              [-40.4133333316679, -8.925833325347639],
              [-40.412499998276246, -8.92583332444832],
              [-40.412500000974205, -8.923333326072111],
              [-40.409166666508384, -8.923333325172791],
              [-40.409166665609064, -8.922499993579777],
              [-40.408333332217467, -8.922499992680457],
              [-40.408333332217467, -8.92166666018818],
              [-40.406666665434216, -8.92166665838954],
              [-40.406666666333535, -8.919166659113955],
              [-40.405833333841258, -8.919166659113955],
              [-40.405833332941938, -8.917499993230081],
              [-40.404999999550284, -8.917499993230081],
              [-40.404999999550284, -8.914999993055176],
              [-40.404166665259368, -8.914999993055176],
              [-40.404166667058007, -8.911666658589354],
              [-40.403333331867714, -8.911666658589354],
              [-40.403333331867714, -8.909999993604799],
              [-40.402499998476117, -8.90999999270548],
              [-40.402500000274756, -8.908333325922229],
              [-40.400833332592185, -8.908333325922229],
              [-40.400833333491505, -8.906666659138978],
              [-40.401666665983782, -8.906666659138978],
              [-40.401666666883102, -8.904999992355727],
              [-40.402499998476117, -8.904999992355727],
              [-40.402499998476117, -8.904166658964073],
              [-40.403333332767033, -8.904166658964073],
              [-40.403333331867714, -8.902499993979518],
              [-40.402499999375436, -8.902499993979518],
              [-40.402499999375436, -8.901666658789225],
              [-40.403333332767033, -8.901666658789225],
              [-40.403333331867714, -8.900833325397628],
              [-40.402500000274756, -8.900833325397628],
              [-40.402500000274756, -8.897499992730445],
              [-40.401666666883102, -8.897499993629765],
              [-40.401666666883102, -8.896666658439472],
              [-40.400833330793546, -8.896666658439472],
              [-40.400833333491505, -8.89499999255554],
              [-40.399999998301212, -8.89499999255554],
              [-40.400000000999228, -8.891666658989095],
              [-40.399166665808934, -8.891666658989095],
              [-40.399166666708254, -8.887499992930259],
              [-40.400000000099908, -8.887499992930259],
              [-40.400000000999228, -8.886666657740022],
              [-40.399166667607574, -8.886666658639342],
              [-40.399166667607574, -8.885833326147065],
              [-40.398333332417337, -8.885833326147065],
              [-40.398333332417337, -8.884999992755411],
              [-40.397499999025683, -8.884999992755411],
              [-40.397499999025683, -8.884166657565174],
              [-40.396666667432726, -8.884166658464494],
              [-40.396666665634086, -8.88333332507284],
              [-40.395000000649475, -8.88333332597216],
              [-40.394999999750155, -8.881666660088229],
              [-40.394166665459181, -8.881666657390269],
              [-40.394166666358501, -8.880833325797312],
              [-40.393333332067584, -8.880833325797312],
              [-40.393333332966904, -8.879999992405658],
              [-40.391666665284333, -8.879999992405658],
              [-40.391666665284333, -8.876666658839213],
              [-40.392500000474627, -8.876666658839213],
              [-40.39249999867593, -8.872499992780376],
              [-40.391666667082973, -8.872499992780376],
              [-40.391666667082973, -8.87166665848946],
              [-40.391666667082973, -8.870833325997125],
              [-40.389166666908125, -8.870833325097806],
              [-40.389166666008805, -8.87166665848946],
              [-40.38666666673322, -8.871666659388779],
              [-40.38666666673322, -8.870833325997125],
              [-40.379999999600273, -8.870833325997125],
              [-40.379999998700953, -8.87166665848946],
              [-40.378333331917702, -8.87166665848946],
              [-40.378333331917702, -8.874166658664308],
              [-40.3749999983512, -8.874166659563627],
              [-40.37499999925052, -8.874999992055962],
              [-40.374166665858922, -8.874999992055962],
              [-40.374166666758242, -8.875833325447559],
              [-40.373333332467269, -8.875833325447559],
              [-40.373333333366588, -8.876666658839213],
              [-40.372499999075671, -8.876666658839213],
              [-40.372499999974991, -8.877499993130129],
              [-40.371666666583337, -8.87749999223081],
              [-40.371666665684018, -8.879166659014061],
              [-40.370833331393101, -8.879166659014061],
              [-40.37083333229242, -8.882499992580563],
              [-40.369166667307809, -8.882499992580563],
              [-40.369166665509169, -8.884166658464494],
              [-40.367499998725918, -8.884166658464494],
              [-40.367499998725918, -8.884999992755411],
              [-40.366666666233641, -8.884999992755411],
              [-40.366666666233641, -8.885833326147065],
              [-40.364166666958056, -8.885833326147065],
              [-40.363333332667139, -8.885833326147065],
              [-40.363333331767819, -8.884999992755411],
              [-40.362500001074125, -8.884999992755411],
              [-40.362500001074125, -8.884166658464494],
              [-40.361666666783208, -8.884166658464494],
              [-40.361666667682528, -8.88333332597216],
              [-40.357499999825109, -8.883333329569439],
              [-40.356666667332775, -8.88333332597216],
              [-40.356666667332775, -8.884166659363814],
              [-40.355833333041858, -8.884166657565174],
              [-40.355833334840497, -8.884999992755411],
              [-40.354166665359287, -8.884999992755411],
              [-40.354166665359287, -8.884166658464494],
              [-40.350833330893465, -8.884166657565174],
              [-40.350833332692105, -8.886666659538662],
              [-40.348333332517257, -8.886666658639342],
              [-40.348333331617937, -8.887499992930259],
              [-40.347500000024922, -8.887499992930259],
              [-40.347499998226283, -8.888333327221233],
              [-40.344166665559101, -8.888333325422593],
              [-40.344166668257117, -8.88916665881419],
              [-40.341666666283572, -8.88916665881419],
              [-40.341666668082212, -8.890833325597441],
              [-40.340833332891975, -8.890833325597441],
              [-40.340833333791295, -8.892499992380692],
              [-40.339999999500321, -8.892499992380692],
              [-40.339999999500321, -8.894166659163943],
              [-40.338333331817751, -8.894166657365304],
              [-40.338333331817751, -8.89499999255554],
              [-40.336666667732459, -8.89499999255554],
              [-40.336666667732459, -8.895833325947194],
              [-40.33249999897572, -8.895833325947194],
              [-40.33249999897572, -8.896666658439472],
              [-40.331666666483443, -8.896666660238111],
              [-40.331666666483443, -8.897499992730445],
              [-40.33083333129315, -8.897499991831125],
              [-40.330833333991109, -8.896666657540152],
              [-40.324166666858162, -8.896666658439472],
              [-40.324166665059522, -8.897499992730445],
              [-40.323333334365827, -8.897499993629765],
              [-40.323333333466508, -8.898333326122042],
              [-40.31666666633356, -8.898333326122042],
              [-40.31666666903152, -8.897499992730445],
              [-40.315833332941907, -8.897499992730445],
              [-40.315833332042587, -8.896666658439472],
              [-40.315000000449629, -8.896666657540152],
              [-40.315000000449629, -8.89499999255554],
              [-40.314166667957295, -8.89499999255554],
              [-40.314166667057975, -8.892499992380692],
              [-40.313333332767058, -8.892499992380692],
              [-40.313333331867739, -8.891666658989095],
              [-40.312499999375405, -8.891666658989095],
              [-40.312500000274724, -8.889999994004484],
              [-40.311666666883127, -8.889999993105164],
              [-40.311666665983807, -8.886666658639342],
              [-40.310000000099876, -8.886666658639342],
              [-40.310000000099876, -8.887499992930259],
              [-40.308333331517986, -8.887499992930259],
              [-40.308333331517986, -8.888333325422593],
              [-40.307499999025708, -8.888333325422593],
              [-40.307499999025708, -8.890833325597441],
              [-40.306666666533374, -8.890833324698121],
              [-40.306666667432694, -8.896666658439472],
              [-40.307499999025708, -8.896666658439472],
              [-40.307499999025708, -8.899166657715057],
              [-40.308333331517986, -8.899166658614377],
              [-40.308333331517986, -8.900833325397628],
              [-40.307500000824348, -8.900833327196267],
              [-40.307499999025708, -8.905833326646643],
              [-40.306666666533374, -8.905833325747324],
              [-40.306666667432694, -8.908333325922229],
              [-40.305833334041097, -8.908333325922229],
              [-40.305833331343138, -8.909166658414506],
              [-40.306666667432694, -8.909166658414506],
              [-40.306666665634054, -8.912499992880328],
              [-40.305833332242457, -8.912499992880328],
              [-40.305833334041097, -8.913333325372605],
              [-40.304999999750123, -8.913333328070564],
              [-40.305000000649443, -8.916666658939107],
              [-40.304166666358526, -8.916666658939107],
              [-40.304166666358526, -8.918333326621678],
              [-40.303333332067552, -8.918333324823038],
              [-40.303333332067552, -8.919166659113955],
              [-40.302499999575275, -8.919166659113955],
              [-40.302499999575275, -8.919999992505609],
              [-40.301666667082998, -8.919999992505609],
              [-40.301666667082998, -8.92166665838954],
              [-40.300833331892704, -8.92166665838954],
              [-40.300833331892704, -8.922499992680457],
              [-40.299999998501107, -8.922499992680457],
              [-40.300000000299747, -8.929166658914141],
              [-40.299166666008773, -8.929166658914141],
              [-40.299166666908093, -8.929999994104378],
              [-40.298333331717856, -8.929999991406419],
              [-40.298333331717856, -8.930833325697392],
              [-40.297499998326202, -8.930833325697392],
              [-40.297500001024162, -8.935833326047089],
              [-40.298333331717856, -8.935833326047089],
              [-40.298333332617176, -8.939166658714271],
              [-40.297500001024162, -8.939166658714271],
              [-40.297499999225522, -8.940833325497522],
              [-40.296666667632564, -8.940833325497522],
              [-40.296666667632564, -8.941666658889176],
              [-40.295833331542951, -8.941666658889176],
              [-40.295833330643632, -8.94333332567237],
              [-40.295833331542951, -8.944999992455621],
              [-40.295000000849313, -8.944999992455621],
              [-40.294999997252035, -8.945833326746595],
              [-40.294166665659077, -8.945833325847275],
              [-40.294166666558397, -8.946666657440232],
              [-40.29000000049956, -8.946666660138192],
              [-40.29000000049956, -8.952499992980222],
              [-40.289166666208644, -8.952499992980222],
              [-40.289166666208644, -8.953333325472556],
              [-40.28833333191767, -8.953333324573236],
              [-40.28833333191767, -8.952499992980222],
              [-40.286666666033796, -8.952499992980222],
              [-40.286666666933115, -8.954166658864153],
              [-40.283333331567974, -8.954166657964834],
              [-40.283333331567974, -8.953333326371876],
              [-40.280833330493749, -8.953333325472556],
              [-40.280833331393069, -8.952499992980222],
              [-40.279999999800111, -8.952499992980222],
              [-40.280000000699431, -8.951666658689305],
              [-40.279166666408457, -8.951666658689305],
              [-40.279166665509138, -8.952499992980222],
              [-40.27833333391618, -8.952499992980222],
              [-40.278333330318901, -8.951666658689305],
              [-40.275833331942692, -8.951666658689305],
              [-40.275833332842012, -8.950833326197028],
              [-40.274166667857401, -8.950833326197028],
              [-40.274166667857401, -8.949999993704694],
              [-40.273333332667107, -8.949999992805374],
              [-40.273333331767788, -8.951666658689305],
              [-40.27250000107415, -8.951666658689305],
              [-40.27249999837619, -8.953333324573236],
              [-40.271666667682553, -8.953333325472556],
              [-40.271666667682553, -8.954999993155127],
              [-40.27249999927551, -8.954999993155127],
              [-40.27249999927551, -8.957499992430655],
              [-40.273333331767788, -8.957499992430655],
              [-40.273333332667107, -8.960833326896477],
              [-40.274166666958081, -8.960833325997157],
              [-40.274166666958081, -8.962499991881089],
              [-40.275000000349678, -8.962499993679728],
              [-40.274999998551039, -8.96416665866434],
              [-40.275833333741332, -8.96416665866434],
              [-40.275833331942692, -8.964999992955256],
              [-40.27666666533429, -8.964999992955256],
              [-40.276666668032249, -8.966666658839188],
              [-40.277499998725943, -8.966666658839188],
              [-40.277499997826624, -8.968333325622439],
              [-40.27833333301686, -8.968333325622439],
              [-40.27833333301686, -8.96999999240569],
              [-40.277500000524583, -8.96999999240569],
              [-40.277500000524583, -8.970833325797287],
              [-40.276666668032249, -8.970833326696606],
              [-40.276666666233609, -8.974999992755443],
              [-40.275833333741332, -8.974999992755443],
              [-40.275833332842012, -8.97583332614704],
              [-40.274999998551039, -8.97583332614704],
              [-40.274999998551039, -8.978333324523248],
              [-40.274166666058761, -8.978333325422568],
              [-40.274166666058761, -8.979166658814222],
              [-40.273333332667107, -8.979166658814222],
              [-40.271666666783176, -8.979166658814222],
              [-40.271666667682553, -8.979999992205819],
              [-40.270000000899302, -8.979999993105139],
              [-40.27, -8.983333325772321],
              [-40.269166666608328, -8.983333325772321],
              [-40.269166667507648, -8.984166659163918],
              [-40.2666666673328, -8.984166659163918],
              [-40.26666666643348, -8.984999992555572],
              [-40.264166664459935, -8.984999993454892],
              [-40.264166668057214, -8.985833325947169],
              [-40.263333331967658, -8.985833325947169],
              [-40.263333333766298, -8.986666658439503],
              [-40.26083333269213, -8.986666658439503],
              [-40.26083333269213, -8.98749999273042],
              [-40.259999999300476, -8.98749999273042],
              [-40.259999998401156, -8.988333326122074],
              [-40.259166665908879, -8.988333326122074],
              [-40.259166666808198, -8.989999992006005],
              [-40.257500000024947, -8.989999992905325],
              [-40.257500000024947, -8.990833325397602],
              [-40.255833332342377, -8.990833325397602],
              [-40.255833333241696, -8.993333325572451],
              [-40.256666665733974, -8.993333325572451],
              [-40.256666666633294, -8.994999992355702],
              [-40.257500000024947, -8.994999992355702],
              [-40.257499998226308, -8.996666659138953],
              [-40.256666667532613, -8.996666659138953],
              [-40.256666664834654, -8.997499992530607],
              [-40.255833332342377, -8.997499992530607],
              [-40.255833332342377, -8.999166657515161],
              [-40.256666667532613, -8.999166658414481],
              [-40.256666666633294, -9.000833326097052],
              [-40.255833332342377, -9.000833326097052],
              [-40.255833332342377, -9.001666658589386],
              [-40.256666667532613, -9.001666658589386],
              [-40.256666665733974, -9.002499992880303],
              [-40.257500000924267, -9.002499993779622],
              [-40.257500000024947, -9.003333325372637],
              [-40.256666665733974, -9.003333324473317],
              [-40.256666667532613, -9.010833325897238],
              [-40.258333332517225, -9.010833325897238],
              [-40.258333332517225, -9.011666658389515],
              [-40.259166667707518, -9.011666660188155],
              [-40.259166667707518, -9.012499991781169],
              [-40.259999998401156, -9.012499992680489],
              [-40.259999999300476, -9.01416665856442],
              [-40.258333331617905, -9.01416665856442],
              [-40.258333331617905, -9.015833325347614],
              [-40.257500001823587, -9.015833325347614],
              [-40.257500000024947, -9.016666658739268],
              [-40.258333332517225, -9.016666658739268],
              [-40.258333331617905, -9.017499993030185],
              [-40.259166665908879, -9.017499992130865],
              [-40.259166665908879, -9.01999999410441],
              [-40.259999998401156, -9.01999999320509],
              [-40.259999998401156, -9.020833324798048],
              [-40.261666666983047, -9.020833325697367],
              [-40.261666666083727, -9.021666659089021],
              [-40.262499999475381, -9.021666659089021],
              [-40.262499999475381, -9.022499992480618],
              [-40.263333333766298, -9.022499992480618],
              [-40.263333331967658, -9.023333324972896],
              [-40.264166666258575, -9.023333326771592],
              [-40.264166666258575, -9.02416665836455],
              [-40.265833331243186, -9.02416665836455],
              [-40.265833333041826, -9.024999993554786],
              [-40.267500000724397, -9.024999992655466],
              [-40.267499998925757, -9.02583332604712],
              [-40.269166665709008, -9.02583332604712],
              [-40.269166665709008, -9.024999992655466],
              [-40.269999999100662, -9.024999992655466],
              [-40.270000000899302, -9.02583332604712],
              [-40.271666665883856, -9.02583332604712],
              [-40.271666665883856, -9.026666657640078],
              [-40.273333332667107, -9.026666658539398],
              [-40.273333332667107, -9.027499992830371],
              [-40.274999998551039, -9.027499992830371],
              [-40.274999998551039, -9.029166658714303],
              [-40.275833331942692, -9.029166658714303],
              [-40.275833333741332, -9.029999993005219],
              [-40.27833333301686, -9.030000000199777],
              [-40.280833332292389, -9.029999993005219],
              [-40.280833331393069, -9.030833325497554],
              [-40.281666665684043, -9.030833325497554],
              [-40.281666665684043, -9.029999993005219],
              [-40.284999999250545, -9.029999993005219],
              [-40.287499999425393, -9.029999993005219],
              [-40.287499998526073, -9.031666658889151],
              [-40.289166666208644, -9.031666658889151],
              [-40.289166666208644, -9.032499993180068],
              [-40.290833332991895, -9.032499993180068],
              [-40.290833332092575, -9.033333325672402],
              [-40.291666666383492, -9.033333325672402],
              [-40.291666664584852, -9.034999992455653],
              [-40.292499998875826, -9.034999992455653],
              [-40.292499998875826, -9.03583332584725],
              [-40.295833333341591, -9.03583332494793],
              [-40.295833333341591, -9.036666657440264],
              [-40.296666664934605, -9.036666659238904],
              [-40.296666666733245, -9.037499992630501],
              [-40.297499999225522, -9.037499991731181],
              [-40.297500001024162, -9.038333326022155],
              [-40.300833332792024, -9.038333326022155],
              [-40.300833331892704, -9.039166658514432],
              [-40.301666665284358, -9.039166658514432],
              [-40.301666667082998, -9.040833326197003],
              [-40.303333332067552, -9.040833327096323],
              [-40.303333333866249, -9.039999993704726],
              [-40.304166669056485, -9.039999992805349],
              [-40.304166665459206, -9.039166658514432],
              [-40.304999998850803, -9.039166657615112],
              [-40.304999998850803, -9.038333326022155],
              [-40.305833330443818, -9.038333326022155],
              [-40.305833331343138, -9.039166659413752],
              [-40.306666665634054, -9.039166658514432],
              [-40.306666666533374, -9.039999992805349],
              [-40.308333333316625, -9.039999993704726],
              [-40.308333332417305, -9.040833326197003],
              [-40.310000000099876, -9.040833326197003],
              [-40.309999998301237, -9.04166665868928],
              [-40.311666665983807, -9.04166665868928],
              [-40.311666665983807, -9.042499992980254],
              [-40.312500000274724, -9.042499992980254],
              [-40.312500000274724, -9.043333325472531],
              [-40.313333331867739, -9.043333325472531],
              [-40.313333333666378, -9.044166658864185],
              [-40.314166667057975, -9.044166659763505],
              [-40.314166667957295, -9.044999993155102],
              [-40.315833332941907, -9.044999993155102],
              [-40.315833332941907, -9.044166658864185],
              [-40.318333331318115, -9.044166658864185],
              [-40.318333331318115, -9.044999993155102],
              [-40.319166666508409, -9.044999993155102],
              [-40.319166666508409, -9.045833325647436],
              [-40.319999999900006, -9.045833325647436],
              [-40.320000000799382, -9.049999992605535],
              [-40.32083333149302, -9.049999993504855],
              [-40.32083333149302, -9.051666658489466],
              [-40.319999999000686, -9.051666658489466],
              [-40.319999999900006, -9.053333327071357],
              [-40.320000000799382, -9.054999992955288],
              [-40.319166667407728, -9.054999992955288],
              [-40.319166666508409, -9.055833325447566],
              [-40.318333331318115, -9.055833325447566],
              [-40.318333331318115, -9.056666658839163],
              [-40.317499999725158, -9.056666657939843],
              [-40.317500000624477, -9.057499993130136],
              [-40.316666665434241, -9.057499993130136],
              [-40.31666666633356, -9.058333325622414],
              [-40.314166667057975, -9.058333325622414],
              [-40.314166665259336, -9.057499993130136],
              [-40.312500000274724, -9.057499993130136],
              [-40.312500000274724, -9.058333325622414],
              [-40.311666666883127, -9.058333325622414],
              [-40.311666667782447, -9.059166658114748],
              [-40.309999998301237, -9.059166659014068],
              [-40.309999998301237, -9.059999992405665],
              [-40.304999998850803, -9.059999992405665],
              [-40.304999999750123, -9.060833326696638],
              [-40.300833333691344, -9.060833325797319],
              [-40.300833333691344, -9.061666659188916],
              [-40.299999999400427, -9.061666659188916],
              [-40.300000002098386, -9.06249999258057],
              [-40.299166666908093, -9.06249999168125],
              [-40.299166667807413, -9.063333326871486],
              [-40.295833331542951, -9.063333325972167],
              [-40.295833332442271, -9.064166658464444],
              [-40.295000001748633, -9.064166658464444],
              [-40.294999999050674, -9.064999992755418],
              [-40.292499999775146, -9.064999991856098],
              [-40.292499998875826, -9.065833326147015],
              [-40.291666666383492, -9.065833326147015],
              [-40.291666665484172, -9.066666658639349],
              [-40.289166666208644, -9.066666658639349],
              [-40.289166665309324, -9.0683333254226],
              [-40.286666666033796, -9.0683333254226],
              [-40.286666666033796, -9.069166658814197],
              [-40.285833332642142, -9.069166658814197],
              [-40.285833332642142, -9.069999993105171],
              [-40.286666666933115, -9.06999999400449],
              [-40.286666666933115, -9.070833325597448],
              [-40.285833333541461, -9.070833325597448],
              [-40.285833332642142, -9.072499992380699],
              [-40.284999999250545, -9.072499992380699],
              [-40.284999998351225, -9.073333325772296],
              [-40.279166666408457, -9.073333324872976],
              [-40.279166665509138, -9.074999992555547],
              [-40.279999999800111, -9.074999992555547],
              [-40.279999999800111, -9.077499992730452],
              [-40.279166667307834, -9.077499992730452],
              [-40.279166665509138, -9.078333326122049],
              [-40.281666666583362, -9.078333326122049],
              [-40.281666664784723, -9.077499992730452],
              [-40.28416666765753, -9.077499992730452],
              [-40.284166665858891, -9.078333326122049],
              [-40.284999998351225, -9.078333326122049],
              [-40.285000001049184, -9.079166658614383],
              [-40.285833331742822, -9.079166658614383],
              [-40.285833333541461, -9.082499992180828],
              [-40.286666666033796, -9.082499993080148],
              [-40.286666666033796, -9.081666658789231],
              [-40.287499998526073, -9.081666658789231],
              [-40.287499999425393, -9.082499993979468],
              [-40.28833333281699, -9.082499993080148],
              [-40.28833333281699, -9.083333325572482],
              [-40.289166666208644, -9.083333325572482],
              [-40.289166665309324, -9.082499993080148],
              [-40.29000000049956, -9.082499993080148],
              [-40.29000000049956, -9.083333325572482],
              [-40.290833332092575, -9.083333324673163],
              [-40.290833332991895, -9.084166658964079],
              [-40.291666666383492, -9.084166658964079],
              [-40.291666667282811, -9.083333324673163],
              [-40.292499999775146, -9.083333324673163],
              [-40.292500000674465, -9.082499993080148],
              [-40.296666665833925, -9.082499992180828],
              [-40.296666666733245, -9.081666658789231],
              [-40.299999999400427, -9.081666658789231],
              [-40.299999999400427, -9.080833325397577],
              [-40.300833331892704, -9.080833325397577],
              [-40.300833332792024, -9.081666658789231],
              [-40.301666667082998, -9.081666659688551],
              [-40.301666665284358, -9.084999992355733],
              [-40.304166665459206, -9.084999991456414],
              [-40.304166667257846, -9.08583332574733],
              [-40.305000000649443, -9.085833324848011],
              [-40.304999999750123, -9.086666659138984],
              [-40.305833332242457, -9.086666659138984],
              [-40.305833332242457, -9.088333326821555],
              [-40.306666666533374, -9.088333325922179],
              [-40.306666667432694, -9.089166658414513],
              [-40.306666665634054, -9.090833326097083],
              [-40.307499999025708, -9.090833326097083],
              [-40.307499999025708, -9.091666658589361],
              [-40.308333330618666, -9.091666658589361],
              [-40.308333330618666, -9.092499992880335],
              [-40.309166665808959, -9.092499992880335],
              [-40.309166667607599, -9.093333325372612],
              [-40.31499999865099, -9.093333325372612],
              [-40.314999999550309, -9.094166658764266],
              [-40.31666666633356, -9.094166658764266],
              [-40.316666665434241, -9.094999993055183],
              [-40.318333332217492, -9.094999992155863],
              [-40.318333331318115, -9.095833325547517],
              [-40.318333332217492, -9.098333325722365],
              [-40.317499999725158, -9.098333325722365],
              [-40.317499998825838, -9.100833325897213],
              [-40.31666666723288, -9.100833326796533],
              [-40.316666665434241, -9.102499992680464],
              [-40.31499999865099, -9.102499992680464],
              [-40.31499999865099, -9.109166658914148],
              [-40.314166664360016, -9.109166658914148],
              [-40.314166665259336, -9.110833325697399],
              [-40.31083333259221, -9.110833325697399],
              [-40.31083333259221, -9.11249999248065],
              [-40.309999998301237, -9.11249999248065],
              [-40.310000000999196, -9.114166660163164],
              [-40.308333331517986, -9.114166658364525],
              [-40.308333333316625, -9.114999992655498],
              [-40.307499999025708, -9.114999992655498],
              [-40.307500000824348, -9.11666665853943],
              [-40.306666665634054, -9.11666665853943],
              [-40.306666665634054, -9.117499991931027],
              [-40.305833331343138, -9.117499991931027],
              [-40.305833331343138, -9.118333326222],
              [-40.304999998850803, -9.118333326222],
              [-40.304999999750123, -9.119166657814958],
              [-40.303333332966872, -9.119166658714278],
              [-40.303333332966872, -9.121666659788445],
              [-40.302499998675955, -9.121666658889126],
              [-40.302499998675955, -9.122499993180099],
              [-40.301666667982317, -9.122499993180099],
              [-40.301666665284358, -9.123333325672377],
              [-40.299166666908093, -9.123333325672377],
              [-40.299166666008773, -9.122499993180099],
              [-40.295833330643632, -9.122499993180099],
              [-40.295833333341591, -9.120833324598209],
              [-40.296666665833925, -9.120833325497529],
              [-40.296666666733245, -9.119999993904571],
              [-40.297499997426883, -9.119999993005251],
              [-40.297500001024162, -9.119166658714278],
              [-40.296666665833925, -9.119166658714278],
              [-40.296666665833925, -9.117499992830346],
              [-40.295833332442271, -9.117499992830346],
              [-40.295833333341591, -9.114999992655498],
              [-40.294166665659077, -9.114999992655498],
              [-40.294166666558397, -9.115833326047095],
              [-40.293333334066062, -9.115833326047095],
              [-40.293333332267423, -9.11666665853943],
              [-40.292500001573785, -9.11666665853943],
              [-40.292499999775146, -9.118333326222],
              [-40.293333332267423, -9.118333324423361],
              [-40.293333334066062, -9.119166658714278],
              [-40.292499998875826, -9.119166658714278],
              [-40.292500000674465, -9.119999993005251],
              [-40.293333334965382, -9.119999993005251],
              [-40.293333331368103, -9.122499993180099],
              [-40.292499999775146, -9.122499993180099],
              [-40.292499998875826, -9.123333325672377],
              [-40.290833332991895, -9.123333325672377],
              [-40.290833332092575, -9.124999991556308],
              [-40.29000000049956, -9.124999992455628],
              [-40.289999998700921, -9.125833325847282],
              [-40.289166665309324, -9.125833325847282],
              [-40.289166668007283, -9.126666657440239],
              [-40.294166667457716, -9.126666659238879],
              [-40.294166666558397, -9.127499992630533],
              [-40.294166665659077, -9.129166658514407],
              [-40.296666666733245, -9.129166657615087],
              [-40.296666666733245, -9.129999992805381],
              [-40.297499999225522, -9.129999991906061],
              [-40.297499999225522, -9.130833326196978],
              [-40.298333333516496, -9.130833326196978],
              [-40.298333332617176, -9.132499992980229],
              [-40.299166667807413, -9.132499992980229],
              [-40.299166667807413, -9.133333325472563],
              [-40.302499998675955, -9.133333325472563],
              [-40.302500000474595, -9.132499992980229],
              [-40.308333333316625, -9.132499993879549],
              [-40.310000000099876, -9.132499992980229],
              [-40.310000000999196, -9.133333325472563],
              [-40.31083333349153, -9.133333325472563],
              [-40.310833331692834, -9.13416665976348],
              [-40.314999999550309, -9.13416665886416],
              [-40.31499999865099, -9.133333326371883],
              [-40.318333331318115, -9.133333324573243],
              [-40.318333332217492, -9.132499993879549],
              [-40.319166666508409, -9.132499992980229],
              [-40.319166667407728, -9.131666658689312],
              [-40.32083333239234, -9.131666658689312],
              [-40.32083333239234, -9.132499992980229],
              [-40.32250000097423, -9.132499992080909],
              [-40.322500000074911, -9.133333325472563],
              [-40.325833331842773, -9.133333325472563],
              [-40.325833331842773, -9.129166658514407],
              [-40.326666667932329, -9.129166658514407],
              [-40.326666667932329, -9.12833332602213],
              [-40.328333330218982, -9.128333326921449],
              [-40.328333332916941, -9.125833325847282],
              [-40.33249999987504, -9.125833325847282],
              [-40.33249999987504, -9.126666659238879],
              [-40.334999998251249, -9.126666659238879],
              [-40.335000000049945, -9.125833325847282],
              [-40.336666665933819, -9.125833325847282],
              [-40.3366666650345, -9.124999992455628],
              [-40.338333331817751, -9.124999992455628],
              [-40.33833333361639, -9.125833325847282],
              [-40.339999999500321, -9.125833324947962],
              [-40.339999999500321, -9.126666659238879],
              [-40.340833331992656, -9.126666659238879],
              [-40.340833333791295, -9.12833332602213],
              [-40.341666665384253, -9.12833332602213],
              [-40.341666666283572, -9.130833324398338],
              [-40.342500000574546, -9.130833326196978],
              [-40.342499999675226, -9.131666657789992],
              [-40.344999999850074, -9.131666658689312],
              [-40.344999998950755, -9.132499993879549],
              [-40.346666665734006, -9.132499992980229],
              [-40.346666667532645, -9.133333326371883],
              [-40.347500000024922, -9.133333326371883],
              [-40.347500000924242, -9.13416665886416],
              [-40.346666667532645, -9.13416665976348],
              [-40.346666665734006, -9.13999999260551],
              [-40.347499997326963, -9.13999999260551],
              [-40.347499998226283, -9.142499992780415],
              [-40.346666666633325, -9.142499991881095],
              [-40.346666665734006, -9.146666658839194],
              [-40.345833332342352, -9.146666658839194],
              [-40.345833331443032, -9.147499993130111],
              [-40.344999998950755, -9.147499993130111],
              [-40.344999999850074, -9.148333326521765],
              [-40.344166667357797, -9.148333325622445],
              [-40.344166665559101, -9.151666659188948],
              [-40.339999999500321, -9.151666659188948],
              [-40.340000000399641, -9.152499992580545],
              [-40.33833333271707, -9.152499992580545],
              [-40.33833333271707, -9.158333325422575],
              [-40.337499999325473, -9.158333324523255],
              [-40.337499999325473, -9.159999993105146],
              [-40.338333331817751, -9.159999993105146],
              [-40.338333331817751, -9.16083332559748],
              [-40.339999998601002, -9.16083332559748],
              [-40.339999998601002, -9.161666658989077],
              [-40.340833331992656, -9.161666658089757],
              [-40.340833331992656, -9.162499992380674],
              [-40.34249999787653, -9.162499991481354],
              [-40.342499998775907, -9.163333325772328],
              [-40.34416666645842, -9.163333324873008],
              [-40.34416666645842, -9.164166659163925],
              [-40.345833333241671, -9.164166659163925],
              [-40.345833331443032, -9.164999991656259],
              [-40.346666665734006, -9.164999992555579],
              [-40.346666667532645, -9.165833325947176],
              [-40.347500000924242, -9.165833325047856],
              [-40.347500000024922, -9.164999992555579],
              [-40.358333332317386, -9.164999992555579],
              [-40.358333331418066, -9.165833326846496],
              [-40.360833331592914, -9.165833325947176],
              [-40.360833332492234, -9.16666665843951],
              [-40.361666666783208, -9.16666665843951],
              [-40.361666665883888, -9.167499992730427],
              [-40.364166666958056, -9.167499992730427],
              [-40.364166667857376, -9.168333326122081],
              [-40.36499999855107, -9.168333326122081],
              [-40.36499999945039, -9.17083332449829],
              [-40.365833331942667, -9.170833325397609],
              [-40.365833332841987, -9.171666658789206],
              [-40.367499998725918, -9.171666658789206],
              [-40.367500000524558, -9.1724999939795],
              [-40.368333333016835, -9.17249999308018],
              [-40.368333333016835, -9.173333324673138],
              [-40.369166667307809, -9.173333325572457],
              [-40.369166665509169, -9.174166658964111],
              [-40.37083333229242, -9.174166658964111],
              [-40.370833331393101, -9.174999992355708],
              [-40.371666665684018, -9.174999992355708],
              [-40.371666667482657, -9.175833325747362],
              [-40.373333330668629, -9.175833325747362],
              [-40.373333331567949, -9.176666659138959],
              [-40.374166666758242, -9.176666659138959],
              [-40.374166666758242, -9.177499991631237],
              [-40.3749999983512, -9.177499992530556],
              [-40.3749999983512, -9.17833332592221],
              [-40.375833331742797, -9.17833332682153],
              [-40.375833331742797, -9.179999992705461],
              [-40.37666666783241, -9.179999992705461],
              [-40.376666666033771, -9.180833326097058],
              [-40.378333332817022, -9.180833325197739],
              [-40.378333332817022, -9.179999992705461],
              [-40.385833332442303, -9.179999992705461],
              [-40.386666667632539, -9.179999992705461],
              [-40.3866666658339, -9.180833326097058],
              [-40.389166667807444, -9.180833326097058],
              [-40.389166666908125, -9.181666658589393],
              [-40.389999999400402, -9.181666658589393],
              [-40.389999998501082, -9.183333325372644],
              [-40.390833331892736, -9.183333325372644],
              [-40.390833331892736, -9.184166658764241],
              [-40.39249999957525, -9.184166658764241],
              [-40.39249999957525, -9.184999993055214],
              [-40.393333332966904, -9.184999993055214],
              [-40.393333333866224, -9.184166657864921],
              [-40.394999998850835, -9.184166658764241],
              [-40.394999998850835, -9.184999993055214],
              [-40.395833331343113, -9.184999993954534],
              [-40.395833334041072, -9.185833325547492],
              [-40.396666665634086, -9.185833325547492],
              [-40.396666665634086, -9.186666658939089],
              [-40.395833332242432, -9.186666658939089],
              [-40.395833332242432, -9.187499993230063],
              [-40.395000000649475, -9.187499993230063],
              [-40.394999998850835, -9.189999992505591],
              [-40.395833334041072, -9.189999992505591],
              [-40.395833332242432, -9.191666658389522],
              [-40.396666667432726, -9.191666658389522],
              [-40.396666666533406, -9.192499992680496],
              [-40.397499999025683, -9.192499992680496],
              [-40.397499999025683, -9.193333326971413],
              [-40.398333331518018, -9.193333326072093],
              [-40.398333332417337, -9.19416665856437],
              [-40.399999998301212, -9.19416665856437],
              [-40.400000000999228, -9.193333326072093],
              [-40.400833333491505, -9.193333326072093],
              [-40.400833333491505, -9.192499992680496],
              [-40.402499998476117, -9.192499992680496],
              [-40.402499998476117, -9.193333326072093],
              [-40.405000000449604, -9.193333325172773],
              [-40.404999999550284, -9.192499992680496],
              [-40.405833332042619, -9.192499992680496],
              [-40.405833332042619, -9.191666658389522],
              [-40.406666665434216, -9.191666658389522],
              [-40.406666666333535, -9.190833325897245],
              [-40.40749999882587, -9.190833324997925],
              [-40.407499997926493, -9.189999992505591],
              [-40.408333332217467, -9.189999992505591],
              [-40.408333334016106, -9.189166659113994],
              [-40.409166666508384, -9.189166659113994],
              [-40.40916666740776, -9.18833332572234],
              [-40.409999999900037, -9.18833332482302],
              [-40.409999999900037, -9.189166659113994],
              [-40.411666667582608, -9.189166659113994],
              [-40.411666666683288, -9.189999992505591],
              [-40.4133333316679, -9.189999992505591],
              [-40.41333333256722, -9.190833325897245],
              [-40.414166666858137, -9.190833325897245],
              [-40.414166665958817, -9.191666658389522],
              [-40.414999998451151, -9.191666658389522],
              [-40.414999998451151, -9.192499992680496],
              [-40.415833332742068, -9.192499992680496],
              [-40.415833331842748, -9.193333326072093],
              [-40.416666665234345, -9.193333326072093],
              [-40.416666667033041, -9.19416665856437],
              [-40.417500000424639, -9.19416665856437],
              [-40.417499999525319, -9.193333326971413],
              [-40.418333332916916, -9.193333326072093],
              [-40.418333332916916, -9.192499992680496],
              [-40.41916666720789, -9.192499992680496],
              [-40.41916666630857, -9.189999992505591],
              [-40.419999998800847, -9.189999992505591],
              [-40.419999999700167, -9.187499993230063],
              [-40.420833333091821, -9.187499993230063],
              [-40.420833331293181, -9.18833332662166],
              [-40.421666666483418, -9.18833332572234],
              [-40.422499998975752, -9.18833332572234],
              [-40.422499998975752, -9.189166659113994],
              [-40.423333331468029, -9.189166659113994],
              [-40.423333331468029, -9.191666658389522],
              [-40.422499999875072, -9.191666658389522],
              [-40.422499999875072, -9.192499992680496],
              [-40.421666665584098, -9.192499993579816],
              [-40.421666666483418, -9.193333326072093],
              [-40.420833330393862, -9.193333326072093],
              [-40.420833333991141, -9.19416665856437],
              [-40.421666665584098, -9.19416665856437],
              [-40.421666666483418, -9.194999992855344],
              [-40.422499999875072, -9.194999992855344],
              [-40.422500001673711, -9.195833325347621],
              [-40.421666667382738, -9.195833325347621],
              [-40.421666666483418, -9.200833325697374],
              [-40.41916666720789, -9.200833325697374],
              [-40.41916666720789, -9.205833326047127],
              [-40.418333333816236, -9.205833326047127],
              [-40.418333333816236, -9.206666658539405],
              [-40.417499998625999, -9.206666658539405],
              [-40.417500000424639, -9.207499992830378],
              [-40.416666665234345, -9.207499992830378],
              [-40.416666667932361, -9.209999993005226],
              [-40.415833333641388, -9.209999993005226],
              [-40.415833334540707, -9.210833326396823],
              [-40.417499999525319, -9.210833325497504],
              [-40.417499998625999, -9.211666658889158],
              [-40.418333333816236, -9.211666658889158],
              [-40.418333332017596, -9.21666666013823],
              [-40.41916666540925, -9.21666666013823],
              [-40.41916666540925, -9.218333326022105],
              [-40.419999998800847, -9.218333326921481],
              [-40.419999999700167, -9.219999992805356],
              [-40.420833333091821, -9.219999992805356],
              [-40.420833331293181, -9.22083332619701],
              [-40.421666667382738, -9.22083332619701],
              [-40.421666666483418, -9.223333325472538],
              [-40.422499999875072, -9.223333325472538],
              [-40.422500000774392, -9.224999993155109],
              [-40.421666665584098, -9.224999993155109],
              [-40.421666666483418, -9.23249999278039],
              [-40.419999999700167, -9.23249999278039],
              [-40.419999999700167, -9.234999992955238],
              [-40.41916666540925, -9.234999992955238],
              [-40.41916666720789, -9.237499992230823],
              [-40.41916666630857, -9.239166659014074],
              [-40.419999999700167, -9.239166659014074],
              [-40.419999999700167, -9.239999992405671],
              [-40.420833331293181, -9.239999992405671],
              [-40.420833333991141, -9.240833325797325],
              [-40.423333331468029, -9.240833324898006],
              [-40.423333333266669, -9.241666659188922],
              [-40.425833331642878, -9.241666660088242],
              [-40.425833333441517, -9.24249999258052],
              [-40.427499999325448, -9.24249999258052],
              [-40.427499999325448, -9.241666659188922],
              [-40.42916666520938, -9.241666659188922],
              [-40.42916666520938, -9.24249999258052],
              [-40.431666665384284, -9.24249999258052],
              [-40.431666666283604, -9.241666660088242],
              [-40.432499998775882, -9.241666659188922],
              [-40.432499998775882, -9.240833325797325],
              [-40.436666665733981, -9.240833325797325],
              [-40.436666665733981, -9.23833332562242],
              [-40.437500000924274, -9.23833332562242],
              [-40.437499998226315, -9.237499993130143],
              [-40.439166668606845, -9.237499993130143],
              [-40.439166667707525, -9.236666658839169],
              [-40.439999998401163, -9.236666658839169],
              [-40.439999999300483, -9.235833325447572],
              [-40.44083333179276, -9.235833325447572],
              [-40.44083333179276, -9.234999992955238],
              [-40.443333331967665, -9.234999993854558],
              [-40.443333331967665, -9.235833325447572],
              [-40.445833333941152, -9.235833325447572],
              [-40.445833333941152, -9.234999992955238],
              [-40.449166665709015, -9.234999992955238],
              [-40.449166666608335, -9.235833326346892],
              [-40.449999999100612, -9.235833325447572],
              [-40.450000000899252, -9.234999992955238],
              [-40.454166666058768, -9.234999992955238],
              [-40.454166666058768, -9.234166658664321],
              [-40.454999999450365, -9.234166658664321],
              [-40.454999998551045, -9.233333326172044],
              [-40.455833333741339, -9.233333326172044],
              [-40.455833331942699, -9.23249999367971],
              [-40.456666665334296, -9.23249999278039],
              [-40.456666668032256, -9.233333326172044],
              [-40.457499999625213, -9.233333326172044],
              [-40.457499998725893, -9.234166658664321],
              [-40.458333331218228, -9.234166659563641],
              [-40.458333333016867, -9.234999992955238],
              [-40.460833334091035, -9.234999992955238],
              [-40.460833331393076, -9.235833325447572],
              [-40.461666666583369, -9.235833325447572],
              [-40.461666667482689, -9.236666658839169],
              [-40.463333331567981, -9.236666658839169],
              [-40.46333333336662, -9.23833332652174],
              [-40.462499999075646, -9.238333324723101],
              [-40.462499999974966, -9.240833325797325],
              [-40.461666667482689, -9.240833326696645],
              [-40.461666666583369, -9.24249999258052],
              [-40.462499999974966, -9.24249999258052],
              [-40.462499999075646, -9.243333325972173],
              [-40.462500000874286, -9.244999992755425],
              [-40.4633333324673, -9.244999992755425],
              [-40.4633333324673, -9.245833327046341],
              [-40.464999998351175, -9.245833326147022],
              [-40.464999998351175, -9.246666658639356],
              [-40.465833331742829, -9.246666658639356],
              [-40.465833332642148, -9.248333325422607],
              [-40.466666666033746, -9.248333325422607],
              [-40.466666666033746, -9.249166658814204],
              [-40.468333333716316, -9.249166659713524],
              [-40.468333331917677, -9.249999992205801],
              [-40.46916666620865, -9.249999994004497],
              [-40.46916666620865, -9.250833324698135],
              [-40.470000000499567, -9.250833326496775],
              [-40.469999998700928, -9.251666658989052],
              [-40.470833332991901, -9.251666658089732],
              [-40.470833332092582, -9.255833325047888],
              [-40.470000000499567, -9.255833325947208],
              [-40.469999999600248, -9.261666658789238],
              [-40.47333333226743, -9.261666657889918],
              [-40.47333333226743, -9.262499993080155],
              [-40.47500000084932, -9.262499993080155],
              [-40.474999999050681, -9.264166658964086],
              [-40.47333333136811, -9.264166658964086],
              [-40.47333333136811, -9.26499999235574],
              [-40.474166667457666, -9.26499999415438],
              [-40.474166667457666, -9.265833325747337],
              [-40.475833332442278, -9.265833326646657],
              [-40.475833333341598, -9.266666660038311],
              [-40.477499998326209, -9.266666659138934],
              [-40.477499999225529, -9.265833325747337],
              [-40.4791666669081, -9.265833325747337],
              [-40.4791666669081, -9.26499999235574],
              [-40.479999998501114, -9.26499999235574],
              [-40.479999998501114, -9.263333325572489],
              [-40.480833332792031, -9.263333325572489],
              [-40.480833332792031, -9.262499993979475],
              [-40.493333332767065, -9.262499993979475],
              [-40.493333332767065, -9.261666658789238],
              [-40.494166665259343, -9.261666658789238],
              [-40.494166665259343, -9.259999992905307],
              [-40.494999999550316, -9.259999992905307],
              [-40.495000000449636, -9.259166658614333],
              [-40.495833333841233, -9.259166658614333],
              [-40.495833332941913, -9.259999992905307],
              [-40.496666666333567, -9.259999992905307],
              [-40.496666666333567, -9.260833325397584],
              [-40.499166667407735, -9.260833324498265],
              [-40.499166667407735, -9.261666658789238],
              [-40.503333331667875, -9.261666658789238],
              [-40.503333332567195, -9.260833325397584],
              [-40.504999999350446, -9.260833327196224],
              [-40.504999997551806, -9.263333325572489],
              [-40.505833333641419, -9.263333325572489],
              [-40.505833331842723, -9.262499993080155],
              [-40.509166665409225, -9.262499993080155],
              [-40.509166667207865, -9.263333325572489],
              [-40.510833333091796, -9.263333325572489],
              [-40.510833331293156, -9.262499993080155],
              [-40.51166666558413, -9.262499993080155],
              [-40.51166666648345, -9.260833325397584],
              [-40.510833331293156, -9.260833325397584],
              [-40.510833331293156, -9.258333326122056],
              [-40.51166666648345, -9.258333326122056],
              [-40.51166666648345, -9.259166658614333],
              [-40.514166665758978, -9.259166658614333],
              [-40.514166666658298, -9.258333326122056],
              [-40.518333333616397, -9.258333326122056],
              [-40.518333332717077, -9.259166659513653],
              [-40.524166665559108, -9.259166659513653],
              [-40.524166666458427, -9.259999992905307],
              [-40.524999999850081, -9.259999992905307],
              [-40.524999999850081, -9.259166658614333],
              [-40.528333331617944, -9.259166658614333],
              [-40.528333332517263, -9.259999992905307],
              [-40.5291666677075, -9.259999992905307],
              [-40.52916666680818, -9.259166658614333],
              [-40.532499998576043, -9.259166657715014],
              [-40.532499999475363, -9.259999992905307],
              [-40.537499999825116, -9.259999992905307],
              [-40.537499998925739, -9.260833325397584],
              [-40.538333332317393, -9.260833325397584],
              [-40.538333331418073, -9.261666658789238],
              [-40.53916666660831, -9.261666658789238],
              [-40.539166667507629, -9.262499993080155],
              [-40.54, -9.262499993080155],
              [-40.54, -9.263333326471809],
              [-40.540833332492241, -9.263333326471809],
              [-40.541666667682534, -9.263333325572489],
              [-40.541666666783215, -9.262499993080155],
              [-40.542499999275492, -9.262499993080155],
              [-40.542499999275492, -9.261666658789238],
              [-40.544166666058743, -9.261666659688558],
              [-40.544166666958063, -9.260833325397584],
              [-40.544999999450397, -9.260833325397584],
              [-40.544999998551077, -9.259999992005987],
              [-40.546666668032287, -9.259999993804627],
              [-40.546666665334271, -9.259166658614333],
              [-40.550000000699413, -9.259166657715014],
              [-40.549999998900773, -9.259999992905307],
              [-40.549166667307816, -9.259999992905307],
              [-40.549166665509176, -9.264166658964086],
              [-40.549999998001454, -9.264166658964086],
              [-40.549999998900773, -9.266666659138934],
              [-40.550833334091067, -9.266666660038311],
              [-40.550833332292427, -9.267499992530588],
              [-40.551666665684024, -9.267499992530588],
              [-40.551666664784705, -9.268333325922185],
              [-40.553333333366595, -9.268333325922185],
              [-40.553333331567956, -9.26916665841452],
              [-40.554166666758249, -9.269166660213159],
              [-40.554166665858872, -9.269999991806117],
              [-40.554999999250526, -9.269999992705436],
              [-40.555000001049166, -9.270833325197771],
              [-40.555833331742804, -9.27083332699641],
              [-40.555833332642123, -9.273333325372619],
              [-40.556666666033777, -9.273333325372619],
              [-40.556666666033777, -9.275833325547467],
              [-40.555833332642123, -9.275833325547467],
              [-40.555833332642123, -9.278333326621691],
              [-40.556666667832417, -9.278333325722372],
              [-40.556666666033777, -9.28083332589722],
              [-40.555833332642123, -9.28083332589722],
              [-40.555833332642123, -9.282499991781151],
              [-40.556666666933097, -9.282499992680471],
              [-40.556666667832417, -9.283333326072068],
              [-40.558333331917709, -9.283333326072068],
              [-40.558333331917709, -9.284166658564402],
              [-40.560833332991876, -9.284166658564402],
              [-40.560833332991876, -9.283333325172748],
              [-40.561666665484154, -9.283333326072068],
              [-40.561666665484154, -9.282499992680471],
              [-40.562500000674447, -9.282499992680471],
              [-40.562499999775127, -9.281666658389554],
              [-40.565000000849295, -9.281666658389554],
              [-40.565000000849295, -9.28083332589722],
              [-40.56583333154299, -9.280833326796539],
              [-40.565833333341629, -9.279166659113969],
              [-40.566666666733227, -9.279166659113969],
              [-40.566666665833907, -9.28083332589722],
              [-40.5675000010242, -9.28083332589722],
              [-40.5675000010242, -9.281666658389554],
              [-40.568333333516478, -9.281666658389554],
              [-40.568333333516478, -9.28083332589722],
              [-40.569166666008812, -9.2808333249979],
              [-40.569166666908131, -9.281666657490177],
              [-40.569999999400409, -9.281666658389554],
              [-40.569999999400409, -9.280833326796539],
              [-40.570833332792006, -9.28083332589722],
              [-40.570833333691326, -9.281666660188193],
              [-40.573333332067591, -9.281666658389554],
              [-40.573333332966911, -9.28083332589722],
              [-40.574166665459188, -9.2808333249979],
              [-40.574166665459188, -9.279999992505623],
              [-40.575833332242439, -9.279999992505623],
              [-40.575833332242439, -9.279166659113969],
              [-40.576666667432733, -9.279166659113969],
              [-40.576666665634093, -9.278333325722372],
              [-40.578333332417287, -9.278333325722372],
              [-40.578333331517968, -9.277499993230037],
              [-40.579166665808941, -9.277499993230037],
              [-40.579166665808941, -9.275833325547467],
              [-40.581666666883109, -9.275833325547467],
              [-40.581666665983789, -9.276666658939121],
              [-40.582500000274763, -9.276666658939121],
              [-40.582500000274763, -9.275833325547467],
              [-40.58333333366636, -9.275833324648147],
              [-40.58333333276704, -9.274999993055189],
              [-40.582499998476123, -9.27499999215587],
              [-40.582500002073402, -9.274166659663592],
              [-40.583333331867721, -9.274166658764216],
              [-40.583333331867721, -9.271666657690048],
              [-40.584999999550291, -9.271666658589368],
              [-40.584999999550291, -9.266666659138934],
              [-40.585833332941945, -9.266666659138934],
              [-40.585833334740585, -9.263333325572489],
              [-40.587499999725139, -9.263333325572489],
              [-40.58749999882582, -9.262499993080155],
              [-40.588333334016113, -9.262499993080155],
              [-40.588333331318154, -9.259999992005987],
              [-40.58749999882582, -9.259999992905307],
              [-40.587499999725139, -9.258333327021376],
              [-40.588333334016113, -9.258333326122056],
              [-40.588333331318154, -9.256666657540165],
              [-40.589166665609071, -9.256666658439485],
              [-40.58916666740771, -9.255833325947208],
              [-40.589999999900044, -9.255833325947208],
              [-40.590000000799364, -9.254999992555554],
              [-40.590833332392322, -9.254999992555554],
              [-40.590833331493002, -9.254166659163957],
              [-40.592499998276253, -9.254166659163957],
              [-40.592499998276253, -9.253333325772303],
              [-40.594166666858143, -9.253333324872983],
              [-40.594166667757463, -9.252499994179345],
              [-40.596666665234352, -9.252499992380706],
              [-40.596666667032991, -9.251666658089732],
              [-40.598333332017603, -9.251666658989052],
              [-40.599166667207896, -9.251666658989052],
              [-40.599166665409257, -9.254166659163957],
              [-40.600000000599493, -9.254166659163957],
              [-40.599999998800854, -9.255833325947208],
              [-40.599166666308577, -9.255833325947208],
              [-40.599166669006536, -9.257499992730459],
              [-40.599999999700174, -9.257499992730459],
              [-40.599999999700174, -9.259166658614333],
              [-40.601666666483425, -9.259166658614333],
              [-40.601666667382744, -9.259999992905307],
              [-40.603333332367356, -9.259999992905307],
              [-40.603333331468036, -9.259166658614333],
              [-40.606666667732497, -9.259166658614333],
              [-40.606666665933858, -9.259999992905307],
              [-40.608333332717109, -9.259999992905307],
              [-40.608333333616429, -9.262499993080155],
              [-40.609166667008026, -9.262499993080155],
              [-40.609166665209386, -9.263333325572489],
              [-40.610833333791277, -9.263333325572489],
              [-40.610833333791277, -9.264166658964086],
              [-40.612499998775888, -9.264166658964086],
              [-40.612499999675208, -9.266666659138934],
              [-40.613333333066805, -9.266666659138934],
              [-40.613333330368846, -9.267499992530588],
              [-40.616666664834668, -9.267499992530588],
              [-40.616666665733987, -9.268333326821505],
              [-40.618333332517238, -9.268333325922185],
              [-40.618333332517238, -9.27083332609709],
              [-40.620000001099129, -9.27083332699641],
              [-40.61999999840117, -9.271666657690048],
              [-40.620833333591406, -9.271666658589368],
              [-40.620833333591406, -9.272499992880341],
              [-40.62166666788238, -9.272499992880341],
              [-40.62166666788238, -9.274166658764216],
              [-40.623333333766311, -9.274166658764216],
              [-40.623333332866991, -9.274999993055189],
              [-40.624999997851603, -9.27499999215587],
              [-40.624999998750923, -9.275833324648147],
              [-40.626666667332813, -9.275833326446786],
              [-40.626666666433493, -9.274999993055189],
              [-40.62750000072441, -9.274999993055189],
              [-40.627499998925771, -9.276666658039801],
              [-40.628333332317368, -9.276666658939121],
              [-40.628333331418048, -9.277499991431398],
              [-40.629999997301979, -9.277499994129357],
              [-40.630000000899258, -9.278333325722372],
              [-40.630833332492273, -9.278333325722372],
              [-40.630833331592953, -9.279166659113969],
              [-40.632499999275524, -9.279166659113969],
              [-40.632499999275524, -9.2808333249979],
              [-40.632500001074163, -9.281666658389554],
              [-40.630833331592953, -9.281666658389554],
              [-40.630833331592953, -9.28249999357979],
              [-40.629999997301979, -9.282499992680471],
              [-40.63, -9.28666665873925],
              [-40.630833332492273, -9.28666665783993],
              [-40.630833332492273, -9.287499993030224],
              [-40.630000000899258, -9.287499993030224],
              [-40.63, -9.288333325522501],
              [-40.627499999825091, -9.288333325522501],
              [-40.627499999825091, -9.289166658914155],
              [-40.626666665534117, -9.289166658914155],
              [-40.626666665534117, -9.289999993205072],
              [-40.624999999650242, -9.289999991406432],
              [-40.624999999650242, -9.290833325697349],
              [-40.624166666258589, -9.290833325697349],
              [-40.624166668057228, -9.293333324972934],
              [-40.622499999475338, -9.293333325872254],
              [-40.622499999475338, -9.294999992655505],
              [-40.619999999300489, -9.294999992655505],
              [-40.619999999300489, -9.295833326047102],
              [-40.618333332517238, -9.295833326047102],
              [-40.617500000024961, -9.295833326047102],
              [-40.617500000924281, -9.296666658539436],
              [-40.616666666633307, -9.296666657640117],
              [-40.616666666633307, -9.298333326222007],
              [-40.617500000924281, -9.298333327121327],
              [-40.617499998226322, -9.303333325672384],
              [-40.618333331617919, -9.303333332866998],
              [-40.624999999650242, -9.303333325672384],
              [-40.624999999650242, -9.302499991381467],
              [-40.6258333312432, -9.302499993180106],
              [-40.625833333941159, -9.301666658889133],
              [-40.626666667332813, -9.301666658889133],
              [-40.626666667332813, -9.302499993180106],
              [-40.630000000899258, -9.302499993180106],
              [-40.630000000899258, -9.301666657989813],
              [-40.632499998376204, -9.301666658889133],
              [-40.632500001074163, -9.302499993180106],
              [-40.634166666958095, -9.302499993180106],
              [-40.634166667857414, -9.301666658889133],
              [-40.635833332841969, -9.301666657989813],
              [-40.635833331942649, -9.299999993005201],
              [-40.636666665334303, -9.299999993005201],
              [-40.636666665334303, -9.299166658714284],
              [-40.638333333016874, -9.299166659613604],
              [-40.638333333016874, -9.299999993005201],
              [-40.643333333366627, -9.299999993005201],
              [-40.64333333246725, -9.300833325497535],
              [-40.644166665858904, -9.300833324598216],
              [-40.644166666758224, -9.301666658889133],
              [-40.644999998351182, -9.301666658889133],
              [-40.644999999250501, -9.302499993180106],
              [-40.646666666033752, -9.302499993180106],
              [-40.646666667832392, -9.303333325672384],
              [-40.648333332817003, -9.303333325672384],
              [-40.648333331917684, -9.304166659064038],
              [-40.649166668007297, -9.304166659064038],
              [-40.649166666208657, -9.306666659238886],
              [-40.649999999600254, -9.306666659238886],
              [-40.650000000499574, -9.308333325122817],
              [-40.648333333716323, -9.308333326022137],
              [-40.648333333716323, -9.309999992805388],
              [-40.647499999425406, -9.309999992805388],
              [-40.647499998526087, -9.310833326196985],
              [-40.646666666033752, -9.310833326196985],
              [-40.646666666933072, -9.31333332547257],
              [-40.647499998526087, -9.31333332547257],
              [-40.647499997626767, -9.314166658864167],
              [-40.649166666208657, -9.314166658864167],
              [-40.649166665309338, -9.314999993155141],
              [-40.649999998700935, -9.314999993155141],
              [-40.649999998700935, -9.316666658139695],
              [-40.650833332991908, -9.316666658139695],
              [-40.650833332991908, -9.317499992430669],
              [-40.651666665484186, -9.317499992430669],
              [-40.651666666383505, -9.31916665921392],
              [-40.652499998875783, -9.31916665921392],
              [-40.652499997976463, -9.320833325997171],
              [-40.653333334066076, -9.320833325997171],
              [-40.653333332267437, -9.322499992780422],
              [-40.654166667457673, -9.322499991881102],
              [-40.654166666558353, -9.323333326172019],
              [-40.654999999950007, -9.323333326172019],
              [-40.654999999050688, -9.328333325622452],
              [-40.650000000499574, -9.328333325622452],
              [-40.650000000499574, -9.329166659014049],
              [-40.650833332092532, -9.329166659014049],
              [-40.650833330293892, -9.329999992405703],
              [-40.649999999600254, -9.329999992405703],
              [-40.649999999600254, -9.331666657390258],
              [-40.649166665309338, -9.331666659188897],
              [-40.649166665309338, -9.332499992580551],
              [-40.648333331917684, -9.332499992580551],
              [-40.648333332817003, -9.334166658464483],
              [-40.647500001224046, -9.334166658464483],
              [-40.647500000324726, -9.335833326147053],
              [-40.646666666033752, -9.335833326147053],
              [-40.646666666033752, -9.336666658639331],
              [-40.645833332642155, -9.336666658639331],
              [-40.645833331742836, -9.338333325422582],
              [-40.647499999425406, -9.338333325422582],
              [-40.647500000324726, -9.34083332559743],
              [-40.646666666933072, -9.34083332559743],
              [-40.646666666933072, -9.346666658439517],
              [-40.645833333541475, -9.346666660238157],
              [-40.645833331742836, -9.349166658614365],
              [-40.645000001049141, -9.349166659513685],
              [-40.644999999250501, -9.350833325397616],
              [-40.645833332642155, -9.350833325397616],
              [-40.645833332642155, -9.351666658789213],
              [-40.646666666933072, -9.351666658789213],
              [-40.646666664235113, -9.352499993080187],
              [-40.647499998526087, -9.352499993080187],
              [-40.647499998526087, -9.353333324673144],
              [-40.647499998526087, -9.355833325747312],
              [-40.648333332817003, -9.355833325747312],
              [-40.648333333716323, -9.356666659138966],
              [-40.647499998526087, -9.356666657340327],
              [-40.647499998526087, -9.357499992530563],
              [-40.645833332642155, -9.357499992530563],
              [-40.645833333541475, -9.358333325922217],
              [-40.644999998351182, -9.358333325022897],
              [-40.644999999250501, -9.359166657515175],
              [-40.644166665858904, -9.359166658414495],
              [-40.644166667657544, -9.359999992705468],
              [-40.645000001049141, -9.359999993604788],
              [-40.644999999250501, -9.36166665769008],
              [-40.645833331742836, -9.3616666585894],
              [-40.645833332642155, -9.362499992880316],
              [-40.644999998351182, -9.362499992880316],
              [-40.644999998351182, -9.363333325372594],
              [-40.64333333246725, -9.363333325372594],
              [-40.643333334265947, -9.362499992880316],
              [-40.642499999974973, -9.362499992880316],
              [-40.642500000874293, -9.364166658764248],
              [-40.641666665684056, -9.364166658764248],
              [-40.641666664784736, -9.365833325547499],
              [-40.639999998900805, -9.365833324648179],
              [-40.639999999800125, -9.366666658939096],
              [-40.639166666408471, -9.366666658939096],
              [-40.639166666408471, -9.369166659114001],
              [-40.638333331218234, -9.369166658214681],
              [-40.638333331218234, -9.369999992505598],
              [-40.639166666408471, -9.369999991606278],
              [-40.639166666408471, -9.371666658389529],
              [-40.63750000052454, -9.371666658389529],
              [-40.637500001423859, -9.372499992680446],
              [-40.635833332841969, -9.372499992680446],
              [-40.635833332841969, -9.373333326971419],
              [-40.634166664260079, -9.37333332517278],
              [-40.634166666958095, -9.374166658564377],
              [-40.63166666588387, -9.374166658564377],
              [-40.631666667682509, -9.374999992855351],
              [-40.630833332492273, -9.374999992855351],
              [-40.630833332492273, -9.376666658739282],
              [-40.628333332317368, -9.376666658739282],
              [-40.628333331418048, -9.377499993030199],
              [-40.62750000072441, -9.377499993030199],
              [-40.627499999825091, -9.378333325522533],
              [-40.626666667332813, -9.378333325522533],
              [-40.626666665534117, -9.37916665891413],
              [-40.624999999650242, -9.37916665891413],
              [-40.625000000549562, -9.379999993205104],
              [-40.624166666258589, -9.379999993205104],
              [-40.624166665359269, -9.380833325697381],
              [-40.623333333766311, -9.380833325697381],
              [-40.623333332866991, -9.382499992480632],
              [-40.62166666698306, -9.382499992480632],
              [-40.62166666698306, -9.38499999265548],
              [-40.619999999300489, -9.38499999265548],
              [-40.61999999840117, -9.384166658364563],
              [-40.616666667532627, -9.384166658364563],
              [-40.616666665733987, -9.385833326047134],
              [-40.613333331268166, -9.385833325147814],
              [-40.613333333966125, -9.38499999265548],
              [-40.610833331093318, -9.38499999265548],
              [-40.610833331992637, -9.387499991931008],
              [-40.608333331817789, -9.387499993729705],
              [-40.608333332717109, -9.388333326221982],
              [-40.607499999325455, -9.388333326221982],
              [-40.607499999325455, -9.389166658714259],
              [-40.606666666833178, -9.389166658714259],
              [-40.606666667732497, -9.389999993005233],
              [-40.605833332542204, -9.389999993005233],
              [-40.605833332542204, -9.39083332549751],
              [-40.605000000949246, -9.39083332549751],
              [-40.604999998251287, -9.391666658889164],
              [-40.604166665758953, -9.391666658889164],
              [-40.604166666658273, -9.392499993180081],
              [-40.602499999875079, -9.392499993180081],
              [-40.602499998975702, -9.393333325672415],
              [-40.600833331293188, -9.393333325672415],
              [-40.600833333091828, -9.394166659064012],
              [-40.599166665409257, -9.394166658164693],
              [-40.599166665409257, -9.396666659238861],
              [-40.599999999700174, -9.396666659238861],
              [-40.599999998800854, -9.397499992630514],
              [-40.600833331293188, -9.397499992630514],
              [-40.600833333991147, -9.399999992805363],
              [-40.601666667382744, -9.399999992805363],
              [-40.601666665584105, -9.402499993879587],
              [-40.604166665758953, -9.402499992980268],
              [-40.604166666658273, -9.403333325472545],
              [-40.605833334340844, -9.403333325472545],
              [-40.605833332542204, -9.402499992980268],
              [-40.606666666833178, -9.402499992980268],
              [-40.606666666833178, -9.401666658689294],
              [-40.607500000224775, -9.401666658689294],
              [-40.607499998426135, -9.404166658864142],
              [-40.609999998600983, -9.404166658864142],
              [-40.61000000039968, -9.404999994054435],
              [-40.611666665384234, -9.404999993155116],
              [-40.611666665384234, -9.406666659039047],
              [-40.614999999850056, -9.406666659039047],
              [-40.614999998950736, -9.407499992430644],
              [-40.618333333416558, -9.407499992430644],
              [-40.618333333416558, -9.408333325822298],
              [-40.61999999840117, -9.408333325822298],
              [-40.619999999300489, -9.409166659213895],
              [-40.623333332866991, -9.409166659213895],
              [-40.623333334665631, -9.411666658489423],
              [-40.624166665359269, -9.411666658489423],
              [-40.624166666258589, -9.412499992780397],
              [-40.6258333312432, -9.412499992780397],
              [-40.6258333312432, -9.413333324373355],
              [-40.63, -9.413333326171994],
              [-40.63, -9.414999992955245],
              [-40.630833331592953, -9.414999992955245],
              [-40.630833332492273, -9.416666658839176],
              [-40.63166666588387, -9.416666659738496],
              [-40.631666667682509, -9.41749999223083],
              [-40.63166666678319, -9.418333325622427],
              [-40.629999997301979, -9.418333325622427],
              [-40.63, -9.419166659913401],
              [-40.629166666608342, -9.419166658114762],
              [-40.629166665709022, -9.419999992405678],
              [-40.626666666433493, -9.419999992405678],
              [-40.626666666433493, -9.420833325797275],
              [-40.62583333304184, -9.420833325797275],
              [-40.62583333304184, -9.421666659188929],
              [-40.624999997851603, -9.421666659188929],
              [-40.624999999650242, -9.422499992580526],
              [-40.624166668057228, -9.422499992580526],
              [-40.624166665359269, -9.42333332597218],
              [-40.62166666698306, -9.42333332597218],
              [-40.62166666698306, -9.424166658464458],
              [-40.620000001099129, -9.424166658464458],
              [-40.61999999840117, -9.424999992755431],
              [-40.619166666808212, -9.424999992755431],
              [-40.619166666808212, -9.425833326147028],
              [-40.618333331617919, -9.425833326147028],
              [-40.618333331617919, -9.429999994004447],
              [-40.617500000924281, -9.429999992205808],
              [-40.617500000024961, -9.430833325597462],
              [-40.616666667532627, -9.430833325597462],
              [-40.616666667532627, -9.431666658989059],
              [-40.614999998950736, -9.431666658089739],
              [-40.614999999850056, -9.432499992380713],
              [-40.614166665559139, -9.432499992380713],
              [-40.614166667357779, -9.433333326671629],
              [-40.614166666458459, -9.434999991656241],
              [-40.613333333066805, -9.434999992555561],
              [-40.613333333066805, -9.437499992730409],
              [-40.614999998950736, -9.437499992730409],
              [-40.614999999850056, -9.438333326122063],
              [-40.613333333966125, -9.438333326122063],
              [-40.613333333066805, -9.43916665861434],
              [-40.612499999675208, -9.43916665861434],
              [-40.612500000574528, -9.439999992905314],
              [-40.613333331268166, -9.439999992905314],
              [-40.613333333066805, -9.441666658789245],
              [-40.614166665559139, -9.441666658789245],
              [-40.614166666458459, -9.443333325572496],
              [-40.614999999850056, -9.443333324673176],
              [-40.614999998950736, -9.449999992705443],
              [-40.614166667357779, -9.449999992705443],
              [-40.614166667357779, -9.452499992880348],
              [-40.614999999850056, -9.452499992880348],
              [-40.614999998950736, -9.454999992155876],
              [-40.614166665559139, -9.454999993055196],
              [-40.614166666458459, -9.455833325547474],
              [-40.608333332717109, -9.455833325547474],
              [-40.608333331817789, -9.454999993055196],
              [-40.607499998426135, -9.454999993055196],
              [-40.607500000224775, -9.455833325547474],
              [-40.607499999325455, -9.456666658039808],
              [-40.606666665034538, -9.456666658939128],
              [-40.606666665933858, -9.457499993230044],
              [-40.606666665933858, -9.458333325722379],
              [-40.608333334515748, -9.458333325722379],
              [-40.608333333616429, -9.460833326796546],
              [-40.607499998426135, -9.460833325897227],
              [-40.607499999325455, -9.464999992855326],
              [-40.609166667008026, -9.464999993754645],
              [-40.609166665209386, -9.466666658739257],
              [-40.609999998600983, -9.466666658739257],
              [-40.609999998600983, -9.467499993030231],
              [-40.609166665209386, -9.467499993030231],
              [-40.609166665209386, -9.47166665908901],
              [-40.610000001298999, -9.47166665908901],
              [-40.60999999950036, -9.470833325697356],
              [-40.610833332891957, -9.470833325697356],
              [-40.610833332891957, -9.468333325522508],
              [-40.61166666898157, -9.468333325522508],
              [-40.611666666283554, -9.466666658739257],
              [-40.612499998775888, -9.466666658739257],
              [-40.612499998775888, -9.46583332534766],
              [-40.613333333966125, -9.46583332534766],
              [-40.613333333966125, -9.464999992855326],
              [-40.614166666458459, -9.464999992855326],
              [-40.614166666458459, -9.462499992680478],
              [-40.617499998226322, -9.462499992680478],
              [-40.617499998226322, -9.463333326971394],
              [-40.622499999475338, -9.463333326971394],
              [-40.622499999475338, -9.464166658564409],
              [-40.623333331967672, -9.464166658564409],
              [-40.623333331967672, -9.463333326072075],
              [-40.624166668057228, -9.463333326072075],
              [-40.624166665359269, -9.462499992680478],
              [-40.624999999650242, -9.462499992680478],
              [-40.625000000549562, -9.461666658389504],
              [-40.625833333941159, -9.461666660188143],
              [-40.625833333941159, -9.45999999250563],
              [-40.626666665534117, -9.45999999250563],
              [-40.628333331418048, -9.45999999250563],
              [-40.628333332317368, -9.459166659113976],
              [-40.629166666608342, -9.459166659113976],
              [-40.629166666608342, -9.458333325722379],
              [-40.630833331592953, -9.458333324823059],
              [-40.630833333391593, -9.457499993230044],
              [-40.63166666678319, -9.457499993230044],
              [-40.631666667682509, -9.456666658939128],
              [-40.632499999275524, -9.456666658939128],
              [-40.632499998376204, -9.455833325547474],
              [-40.633333331767801, -9.455833325547474],
              [-40.633333333566441, -9.454166658764223],
              [-40.634166664260079, -9.454166658764223],
              [-40.634166666958095, -9.453333324473306],
              [-40.636666668032262, -9.453333327171265],
              [-40.636666666233623, -9.454166658764223],
              [-40.638333331218234, -9.454166658764223],
              [-40.638333330318915, -9.453333325372625],
              [-40.63749999962522, -9.453333325372625],
              [-40.63749999962522, -9.452499991980972],
              [-40.636666665334303, -9.452499992880348],
              [-40.636666665334303, -9.451666657690055],
              [-40.638333333016874, -9.451666658589374],
              [-40.638333333016874, -9.448333325022872],
              [-40.64333333246725, -9.448333325022872],
              [-40.643333331567931, -9.447499993429915],
              [-40.644166667657544, -9.447499992530595],
              [-40.644166667657544, -9.445833325747344],
              [-40.643333333366627, -9.445833325747344],
              [-40.643333331567931, -9.44499999235569],
              [-40.642500001773612, -9.44499999235569],
              [-40.642499999075653, -9.442499993080162],
              [-40.643333331567931, -9.442499993080162],
              [-40.64333333246725, -9.441666658789245],
              [-40.644166667657544, -9.441666658789245],
              [-40.644166665858904, -9.439999992905314],
              [-40.645000001049141, -9.439999993804634],
              [-40.644999999250501, -9.440833327196231],
              [-40.645833332642155, -9.440833325397591],
              [-40.645833333541475, -9.439999992905314],
              [-40.646666667832392, -9.439999992905314],
              [-40.646666666033752, -9.43916665861434],
              [-40.647499998526087, -9.43916665861434],
              [-40.647499998526087, -9.437499992730409],
              [-40.646666666933072, -9.437499993629729],
              [-40.646666666033752, -9.436666658439492],
              [-40.645833331742836, -9.436666658439492],
              [-40.645833333541475, -9.434166659163964],
              [-40.646666666033752, -9.434166660063283],
              [-40.646666666033752, -9.429999994004447],
              [-40.647499999425406, -9.429999993105127],
              [-40.647500000324726, -9.429166658814211],
              [-40.646666667832392, -9.429166658814211],
              [-40.646666667832392, -9.424166658464458],
              [-40.647499998526087, -9.424166658464458],
              [-40.647499998526087, -9.42333332597218],
              [-40.648333332817003, -9.42333332597218],
              [-40.648333331917684, -9.422499992580526],
              [-40.649166666208657, -9.422499992580526],
              [-40.649166666208657, -9.42166665739029],
              [-40.649999999600254, -9.421666659188929],
              [-40.649999999600254, -9.420833325797275],
              [-40.650833332991908, -9.420833325797275],
              [-40.650833332092532, -9.41749999313015],
              [-40.651666666383505, -9.41749999313015],
              [-40.651666666383505, -9.415833325447579],
              [-40.654166665659034, -9.415833325447579],
              [-40.654166666558353, -9.414999992955245],
              [-40.654999999950007, -9.414999992055925],
              [-40.654999999050688, -9.413333326171994],
              [-40.654166665659034, -9.413333326171994],
              [-40.654166667457673, -9.410833325997146],
              [-40.654999999050688, -9.410833325097826],
              [-40.654999999050688, -9.409166659213895],
              [-40.654166667457673, -9.409166659213895],
              [-40.654166665659034, -9.406666659039047],
              [-40.652500000674422, -9.406666659039047],
              [-40.652499999775102, -9.405833325647393],
              [-40.651666667282825, -9.405833325647393],
              [-40.651666666383505, -9.400833326197017],
              [-40.654999999950007, -9.400833326197017],
              [-40.654999999050688, -9.399999991906043],
              [-40.655833333341604, -9.399999992805363],
              [-40.655833333341604, -9.399166659413766],
              [-40.657499999225536, -9.399166658514446],
              [-40.657500001923495, -9.398333326022112],
              [-40.659166666008787, -9.398333325122792],
              [-40.659166666008787, -9.397499992630514],
              [-40.659999999400384, -9.397499993529834],
              [-40.660833333691357, -9.397499991731195],
              [-40.660833333691357, -9.398333325122792],
              [-40.661666667982274, -9.398333325122792],
              [-40.661666667982274, -9.399999991906043],
              [-40.662500000474608, -9.399999991906043],
              [-40.662499998675969, -9.401666657789974],
              [-40.665000000649457, -9.401666659588614],
              [-40.664999998850817, -9.403333324573225],
              [-40.665833331343151, -9.403333324573225],
              [-40.665833331343151, -9.405833325647393],
              [-40.666666665634068, -9.405833325647393],
              [-40.666666665634068, -9.406666659039047],
              [-40.66583333404111, -9.406666659039047],
              [-40.665833331343151, -9.408333324922978],
              [-40.667499999025665, -9.408333324922978],
              [-40.667499999925042, -9.409166659213895],
              [-40.668333333316639, -9.409166659213895],
              [-40.668333332417319, -9.412499992780397],
              [-40.672500000274738, -9.412499992780397],
              [-40.672500000274738, -9.413333326171994],
              [-40.674166667957309, -9.413333326171994],
              [-40.674166667057989, -9.414999992055925],
              [-40.675000000449643, -9.414999992955245],
              [-40.675000000449643, -9.419999991506359],
              [-40.674166667057989, -9.419999992405678],
              [-40.674166667057989, -9.420833324897956],
              [-40.672499998476098, -9.420833325797275],
              [-40.672499999375418, -9.422499991681207],
              [-40.673333331867752, -9.422499992580526],
              [-40.673333331867752, -9.424166657565138],
              [-40.675000000449643, -9.424166659363777],
              [-40.674999999550323, -9.427499992930279],
              [-40.674166665259349, -9.42749999203096],
              [-40.674166665259349, -9.428333324523237],
              [-40.673333333666392, -9.428333324523237],
              [-40.673333332767072, -9.429166658814211],
              [-40.674166667957309, -9.429166658814211],
              [-40.674166665259349, -9.429999993105127],
              [-40.6758333320426, -9.429999993105127],
              [-40.67583333294192, -9.430833325597462],
              [-40.674999997751627, -9.430833324698142],
              [-40.674999999550323, -9.434166659163964],
              [-40.6758333320426, -9.434166657365324],
              [-40.6758333320426, -9.434999992555561],
              [-40.679166666508422, -9.43499999345488],
              [-40.679166667407742, -9.435833325947215],
              [-40.6799999990007, -9.435833326846534],
              [-40.679999999900019, -9.436666657540172],
              [-40.680833330593714, -9.436666657540172],
              [-40.680833332392353, -9.438333326122063],
              [-40.682499998276285, -9.438333325222743],
              [-40.682499997376908, -9.43916665771502],
              [-40.683333332567202, -9.43916665771502],
              [-40.683333332567202, -9.441666658789245],
              [-40.684166665059479, -9.441666658789245],
              [-40.684999998451133, -9.441666657889925],
              [-40.684999998451133, -9.442499992180842],
              [-40.68583333184273, -9.442499993080162],
              [-40.68583333274205, -9.443333325572496],
              [-40.686666665234384, -9.443333325572496],
              [-40.686666667033023, -9.444166658064773],
              [-40.689166665409232, -9.444166658964093],
              [-40.689166664509912, -9.44499999235569],
              [-40.688333333816274, -9.44499999235569],
              [-40.688333332017635, -9.446666659138941],
              [-40.687499999525301, -9.446666659138941],
              [-40.687499999525301, -9.447499992530595],
              [-40.684166665059479, -9.447499992530595],
              [-40.684166665958799, -9.446666657340302],
              [-40.68166666758259, -9.446666659138941],
              [-40.68166666758259, -9.445833325747344],
              [-40.680833332392353, -9.445833325747344],
              [-40.680833333291673, -9.444166658964093],
              [-40.677499999725171, -9.444166658964093],
              [-40.677499998825851, -9.44499999235569],
              [-40.676666665434198, -9.44499999235569],
              [-40.676666666333517, -9.444166658964093],
              [-40.674166667057989, -9.444166658964093],
              [-40.674166665259349, -9.442499993080162],
              [-40.673333332767072, -9.442499993979482],
              [-40.673333332767072, -9.441666659688565],
              [-40.670833333491487, -9.441666658789245],
              [-40.670833332592167, -9.440833325397591],
              [-40.669166665808916, -9.440833325397591],
              [-40.669166665808916, -9.439999992005994],
              [-40.668333331517999, -9.439999992905314],
              [-40.668333332417319, -9.438333326122063],
              [-40.667499999025665, -9.438333327021383],
              [-40.667499999925042, -9.437499992730409],
              [-40.666666665634068, -9.437499992730409],
              [-40.666666667432708, -9.436666660238131],
              [-40.664999999750137, -9.436666657540172],
              [-40.664999999750137, -9.437499992730409],
              [-40.66416666545922, -9.437499992730409],
              [-40.664166669056499, -9.436666657540172],
              [-40.663333332966886, -9.436666657540172],
              [-40.663333333866206, -9.435833325947215],
              [-40.662500000474608, -9.435833325947215],
              [-40.662499998675969, -9.440833325397591],
              [-40.663333332067566, -9.440833325397591],
              [-40.663333333866206, -9.442499993080162],
              [-40.6641666645599, -9.442499993080162],
              [-40.66416666545922, -9.443333325572496],
              [-40.665000000649457, -9.443333325572496],
              [-40.664999999750137, -9.444166659863413],
              [-40.667500000824361, -9.444166658964093],
              [-40.667499999025665, -9.445833325747344],
              [-40.668333333316639, -9.445833325747344],
              [-40.668333333316639, -9.446666657340302],
              [-40.66999999830125, -9.446666660038261],
              [-40.66999999830125, -9.448333325922192],
              [-40.670833331692847, -9.448333325922192],
              [-40.670833332592167, -9.450833326097097],
              [-40.672499998476098, -9.450833326097097],
              [-40.672500000274738, -9.452499992880348],
              [-40.673333332767072, -9.452499992880348],
              [-40.673333331867752, -9.454166658764223],
              [-40.67583333384124, -9.454166658764223],
              [-40.67583333384124, -9.454999993954516],
              [-40.676666665434198, -9.454999993055196],
              [-40.676666665434198, -9.456666658939128],
              [-40.677499998825851, -9.456666658939128],
              [-40.677499998825851, -9.458333325722379],
              [-40.678333332217449, -9.458333324823059],
              [-40.678333332217449, -9.459166659113976],
              [-40.679166666508422, -9.459166659113976],
              [-40.679166666508422, -9.45999999250563],
              [-40.680833331493034, -9.45999999250563],
              [-40.680833331493034, -9.460833325897227],
              [-40.681666665783951, -9.460833325897227],
              [-40.68166666848191, -9.461666658389504],
              [-40.684166665958799, -9.461666657490184],
              [-40.684166667757495, -9.460833325897227],
              [-40.684999999350453, -9.460833325897227],
              [-40.685000000249772, -9.459166659113976],
              [-40.685833333641369, -9.459166659113976],
              [-40.685833333641369, -9.458333325722379],
              [-40.686666667932343, -9.458333325722379],
              [-40.686666665234384, -9.457499993230044],
              [-40.688333332017635, -9.457499993230044],
              [-40.688333332017635, -9.456666658939128],
              [-40.692499999875054, -9.456666658939128],
              [-40.692499998975734, -9.455833326446793],
              [-40.693333332367331, -9.455833326446793],
              [-40.693333331468011, -9.454999993055196],
              [-40.696666665034513, -9.454999993055196],
              [-40.696666665933833, -9.454166658764223],
              [-40.699166667008058, -9.454166658764223],
              [-40.699166667907377, -9.453333325372625],
              [-40.700833332891932, -9.453333327171265],
              [-40.700833331992612, -9.452499992880348],
              [-40.702499998775863, -9.452499992880348],
              [-40.702500000574503, -9.451666658589374],
              [-40.704166666458434, -9.451666658589374],
              [-40.704166667357754, -9.450833326097097],
              [-40.704999998950768, -9.450833326097097],
              [-40.704999999850088, -9.449999992705443],
              [-40.706666666633339, -9.449999992705443],
              [-40.706666665734019, -9.449166658414526],
              [-40.707499998226297, -9.449166658414526],
              [-40.707499998226297, -9.448333325922192],
              [-40.708333332517213, -9.448333325022872],
              [-40.708333331617894, -9.447499992530595],
              [-40.709166666808187, -9.447499992530595],
              [-40.709166666808187, -9.446666659138941],
              [-40.710833332692118, -9.446666657340302],
              [-40.710833333591438, -9.445833325747344],
              [-40.711666666983035, -9.445833326646664],
              [-40.711666666083715, -9.444166658964093],
              [-40.715000000549537, -9.444166658964093],
              [-40.714999999650217, -9.443333325572496],
              [-40.715833334840511, -9.443333325572496],
              [-40.715833334840511, -9.442499992180842],
              [-40.716666665534149, -9.442499993080162],
              [-40.716666667332788, -9.443333325572496],
              [-40.71833333141808, -9.443333325572496],
              [-40.71833333141808, -9.445833325747344],
              [-40.719166665708997, -9.445833325747344],
              [-40.719166666670056, -9.447169139007817],
              [-40.722178590999903, -9.44594853999997],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '244',
        OBJECTID: 202.0,
        Nome_area: 'Boqueirão da Onca',
        COD_area: 'CA211',
        Import_bio: 'Extremamente Alta',
        Prior_acao: 'Muito Alta',
        Acao_princ: 'Criação de UC de Proteção Integral',
        Area_ha: 1196498.49,
        Shape_Leng: 17.52822,
        Shape_Area: 0.98667,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.499162466, -9.408909007999972],
              [-40.500000000799332, -9.409052421915201],
              [-40.500000000799332, -9.408333325822298],
              [-40.499166665609096, -9.408333325822298],
              [-40.498333331318122, -9.408333326721618],
              [-40.498333331318122, -9.40876703229738],
              [-40.499162466, -9.408909007999972],
            ],
          ],
          [
            [
              [-40.501159781, -9.409251014999947],
              [-40.501450125, -9.409300731999966],
              [-40.502162846365785, -9.409166659335179],
              [-40.500667148510182, -9.409166659873224],
              [-40.501159781, -9.409251014999947],
            ],
          ],
          [
            [
              [-40.552700861999902, -9.454742240999963],
              [-40.555758513999898, -9.457938111999976],
              [-40.55766607397964, -9.459166659653247],
              [-40.558333332817028, -9.459166660013295],
              [-40.558333332817028, -9.458333326621698],
              [-40.559166668007265, -9.458333325722379],
              [-40.559166665309306, -9.457499993230044],
              [-40.55999999870096, -9.457499993230044],
              [-40.55999999870096, -9.455833325547474],
              [-40.558333331917709, -9.455833324648154],
              [-40.558333332817028, -9.454999993055196],
              [-40.556666667832417, -9.454999993055196],
              [-40.556666667832417, -9.454166658764223],
              [-40.555833331742804, -9.454166658764223],
              [-40.555833333541443, -9.453333325372625],
              [-40.554999999250526, -9.453333325372625],
              [-40.553333331567956, -9.453333324473306],
              [-40.553333331567956, -9.454166658764223],
              [-40.552242964068476, -9.454166658371987],
              [-40.552700861999902, -9.454742240999963],
            ],
          ],
          [
            [
              [-40.595833333266029, -9.467673904364396],
              [-40.595833333641394, -9.467499993030231],
              [-40.595428145014296, -9.467499993030231],
              [-40.595833333266029, -9.467673904364396],
            ],
          ],
          [
            [
              [-40.616510256, -9.480975365999949],
              [-40.6206768549999, -9.48252546399992],
              [-40.623165672, -9.482897442999958],
              [-40.624600161124896, -9.482837074707263],
              [-40.628100649, -9.482689761999945],
              [-40.633333333457813, -9.481566002733414],
              [-40.633333332667121, -9.480833325497542],
              [-40.63166666588387, -9.480833325497542],
              [-40.63166666678319, -9.48166665798982],
              [-40.630000000899258, -9.481666658889139],
              [-40.63, -9.480833324598223],
              [-40.6258333312432, -9.480833325497542],
              [-40.6258333312432, -9.479999993005208],
              [-40.624166666258589, -9.479999993005208],
              [-40.624166668057228, -9.479166658714291],
              [-40.6216666687817, -9.479166658714291],
              [-40.62166666608374, -9.478333326221957],
              [-40.618333332517238, -9.478333328919973],
              [-40.617499998226322, -9.478333326221957],
              [-40.617500000024961, -9.479166658714291],
              [-40.616666666633307, -9.479166657814972],
              [-40.616666666633307, -9.479999993005208],
              [-40.615833330543751, -9.479999993005208],
              [-40.615833332547879, -9.48061901847878],
              [-40.616510256, -9.480975365999949],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '252',
        OBJECTID: 178.0,
        Nome_area: 'Águas Belas',
        COD_area: 'CA186',
        Import_bio: 'Extremamente Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Sem ações recomendadas pela Oficina de Seleção',
        Area_ha: 53853.9702,
        Shape_Leng: 3.02667,
        Shape_Area: 0.04428,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.995000000249775, -8.803333325772314],
              [-36.99249999827623, -8.803333327570954],
              [-36.99249999827623, -8.804166659163968],
              [-36.991666665783953, -8.804166659163968],
              [-36.991666665783953, -8.804999992555565],
              [-36.990833330593716, -8.804999992555565],
              [-36.990833331493036, -8.805833325047843],
              [-36.989999999900022, -8.805833325947162],
              [-36.989999999900022, -8.807499992730413],
              [-36.989166665609105, -8.807499992730413],
              [-36.989166665609105, -8.808333325222748],
              [-36.988333332217451, -8.808333326122067],
              [-36.988333331318131, -8.812499993080166],
              [-36.987500000624493, -8.812499993080166],
              [-36.987499998825854, -8.813333325572444],
              [-36.987499999725173, -8.816666659138946],
              [-36.98833333401609, -8.816666659138946],
              [-36.988333331318131, -8.8174999925306],
              [-36.989166665609105, -8.8174999925306],
              [-36.989166665609105, -8.819166658414531],
              [-36.990833332392356, -8.819166658414531],
              [-36.990833335090315, -8.819999992705448],
              [-36.991666664884633, -8.819999992705448],
              [-36.991666667582592, -8.820833326097102],
              [-36.992500000974246, -8.820833326097102],
              [-36.99249999827623, -8.821666658589379],
              [-36.993333333466524, -8.821666658589379],
              [-36.993333332567204, -8.822499992880296],
              [-36.992500000974246, -8.822499992880296],
              [-36.99249999737691, -8.824166658764227],
              [-36.991666667582592, -8.824166658764227],
              [-36.991666667582592, -8.826666658939132],
              [-36.99249999827623, -8.826666658939132],
              [-36.992500000974246, -8.828333325722383],
              [-36.993333332567204, -8.828333325722383],
              [-36.993333333466524, -8.82916665911398],
              [-36.994166665958801, -8.82916665911398],
              [-36.994166665958801, -8.829999992505577],
              [-36.994999998451135, -8.829999991606257],
              [-36.994999999350455, -8.830833325897231],
              [-36.995833331842732, -8.830833325897231],
              [-36.995833331842732, -8.834166658564413],
              [-36.996666665234386, -8.834166659463733],
              [-36.996666667932345, -8.836666658739261],
              [-36.997499999525303, -8.836666657839942],
              [-36.997499998625983, -8.837499993030235],
              [-36.998333332017637, -8.837499993030235],
              [-36.998333332916957, -8.840833325697361],
              [-36.999166665409234, -8.840833325697361],
              [-36.999166665409234, -8.842499992480612],
              [-37.000000000599528, -8.842499992480612],
              [-36.999999999700208, -8.843333325872266],
              [-36.999166667207874, -8.843333325872266],
              [-36.999166664509914, -8.844166658364543],
              [-36.999999998800888, -8.844166658364543],
              [-37.000000000599528, -8.844999993554836],
              [-37.000833331293165, -8.844999993554836],
              [-37.000833331293165, -8.845833326047114],
              [-37.001666667382779, -8.845833326047114],
              [-37.001666666483402, -8.847499991931045],
              [-37.002500000774376, -8.847499993729684],
              [-37.002499999875056, -8.848333324423322],
              [-37.001666666483402, -8.848333326221962],
              [-37.001666666483402, -8.849166658714296],
              [-37.002500000774376, -8.849166658714296],
              [-37.002499998975736, -8.849999993005213],
              [-37.003333332367333, -8.849999993005213],
              [-37.003333331468014, -8.851666658889144],
              [-37.004166666658307, -8.851666658889144],
              [-37.004166665758987, -8.853333324773075],
              [-37.005000000049904, -8.853333325672395],
              [-37.005000000049904, -8.856666659238897],
              [-37.005833332542238, -8.856666659238897],
              [-37.005833331642918, -8.857499992630494],
              [-37.006666667732475, -8.857499992630494],
              [-37.006666666833155, -8.859166659413745],
              [-37.007500000224809, -8.859166658514425],
              [-37.007500000224809, -8.859999992805399],
              [-37.006666667732475, -8.859999992805399],
              [-37.006666665933835, -8.861666658689273],
              [-37.005833332542238, -8.86166665958865],
              [-37.005833331642918, -8.862499993879567],
              [-37.004999997351945, -8.862499992080927],
              [-37.005000000049904, -8.864999994054415],
              [-37.005833331642918, -8.864999993155095],
              [-37.005833332542238, -8.865833325647429],
              [-37.004999998251265, -8.865833325647429],
              [-37.005000000049904, -8.868333325822277],
              [-37.004166664859667, -8.868333325822277],
              [-37.004166666658307, -8.869999992605528],
              [-37.003333333266653, -8.869999992605528],
              [-37.003333333266653, -8.870833325997125],
              [-37.002499999875056, -8.870833325997125],
              [-37.002500000774376, -8.872499992780376],
              [-37.003333335065292, -8.872499992780376],
              [-37.003333332367333, -8.874166659563627],
              [-37.001666666483402, -8.874166658664308],
              [-37.001666666483402, -8.87333332617203],
              [-36.999999999700208, -8.87333332617203],
              [-36.999999999700208, -8.872499992780376],
              [-36.998333333816277, -8.872499992780376],
              [-36.998333332017637, -8.87166665848946],
              [-36.997499999525303, -8.87166665759014],
              [-36.997499999525303, -8.874166658664308],
              [-36.996666665234386, -8.874166658664308],
              [-36.996666667932345, -8.878333325622407],
              [-36.997499999525303, -8.878333325622407],
              [-36.997499998625983, -8.882499991681243],
              [-36.996666665234386, -8.882499992580563],
              [-36.996666667033026, -8.884999992755411],
              [-36.995833331842732, -8.884999992755411],
              [-36.995833331842732, -8.88916665881419],
              [-36.994999999350455, -8.88916665881419],
              [-36.994999998451135, -8.890833325597441],
              [-36.995833334540691, -8.890833325597441],
              [-36.995833331842732, -8.893333325772346],
              [-36.994999999350455, -8.893333325772346],
              [-36.994999999350455, -8.894166659163943],
              [-36.994166665958801, -8.894166659163943],
              [-36.994166665958801, -8.894999991656221],
              [-36.992500000974246, -8.89499999255554],
              [-36.99249999827623, -8.895833325947194],
              [-36.990000000799341, -8.895833325947194],
              [-36.989999999000702, -8.894999991656221],
              [-36.988333332217451, -8.89499999255554],
              [-36.988333331318131, -8.894166659163943],
              [-36.987499999725173, -8.894166659163943],
              [-36.987499999725173, -8.893333325772346],
              [-36.9866666654342, -8.893333325772346],
              [-36.98666666633352, -8.892499991481372],
              [-36.984999999550269, -8.892499992380692],
              [-36.984999999550269, -8.891666658989095],
              [-36.98249999937542, -8.891666658089775],
              [-36.98250000027474, -8.890833325597441],
              [-36.978333331518002, -8.89083333009404],
              [-36.977499999025667, -8.890833325597441],
              [-36.977499999025667, -8.889999993105164],
              [-36.971666667082957, -8.889999992205844],
              [-36.971666667982277, -8.888333327221233],
              [-36.97083333189272, -8.888333325422593],
              [-36.97083333279204, -8.887499992930259],
              [-36.968333331717815, -8.887499992930259],
              [-36.968333332617135, -8.886666658639342],
              [-36.967500001024177, -8.886666658639342],
              [-36.967499999225538, -8.885833326147065],
              [-36.964166666558356, -8.885833326147065],
              [-36.964166666558356, -8.886666658639342],
              [-36.962499998875785, -8.886666658639342],
              [-36.962499998875785, -8.887499992930259],
              [-36.957499999425409, -8.887499993829579],
              [-36.957499998526089, -8.886666658639342],
              [-36.956666666933074, -8.886666659538662],
              [-36.956666666033755, -8.885833326147065],
              [-36.947500000524542, -8.885833326147065],
              [-36.947499999625222, -8.884999992755411],
              [-36.944999999450374, -8.884999992755411],
              [-36.944999998551054, -8.884166658464494],
              [-36.943333332667123, -8.884166663860412],
              [-36.940833331592955, -8.884166658464494],
              [-36.940833333391595, -8.884999992755411],
              [-36.939999997301982, -8.884999992755411],
              [-36.940000000899261, -8.885833326147065],
              [-36.935833331243202, -8.885833326147065],
              [-36.935833331243202, -8.886666659538662],
              [-36.934999997851548, -8.886666658639342],
              [-36.935000000549564, -8.884999992755411],
              [-36.933333332866994, -8.884999992755411],
              [-36.933333332866994, -8.884166658464494],
              [-36.93250000037466, -8.884166657565174],
              [-36.93249999857602, -8.88333332597216],
              [-36.931666666083743, -8.88333332507284],
              [-36.931666664285103, -8.882499992580563],
              [-36.930833332692089, -8.882499992580563],
              [-36.930833332692089, -8.88333332597216],
              [-36.924166667357781, -8.88333332687148],
              [-36.924166667357781, -8.884166658464494],
              [-36.919999998600986, -8.884166657565174],
              [-36.919999999500305, -8.88333332687148],
              [-36.918333331817792, -8.88333332597216],
              [-36.918333333616431, -8.884166658464494],
              [-36.91666666683318, -8.884166659363814],
              [-36.91666666683318, -8.884999991856091],
              [-36.914166666658275, -8.884999993654731],
              [-36.914166665758955, -8.886666657740022],
              [-36.913333331468039, -8.886666658639342],
              [-36.913333335065317, -8.888333327221233],
              [-36.912499999875024, -8.888333325422593],
              [-36.912499999875024, -8.889999993105164],
              [-36.911666665584107, -8.889999993105164],
              [-36.911666667382747, -8.890833325597441],
              [-36.905833332742077, -8.890833325597441],
              [-36.905833331842757, -8.88916665971351],
              [-36.904999999350423, -8.88916665881419],
              [-36.904999998451103, -8.887499992930259],
              [-36.903333333466549, -8.887499993829579],
              [-36.903333332567229, -8.888333325422593],
              [-36.902500000974214, -8.888333325422593],
              [-36.902500000974214, -8.887499992930259],
              [-36.898333332217476, -8.887499992030939],
              [-36.898333332217476, -8.885833326147065],
              [-36.894166667058016, -8.885833326147065],
              [-36.894166665259377, -8.887499992930259],
              [-36.893333331867723, -8.887499992930259],
              [-36.893333331867723, -8.886666658639342],
              [-36.891666665983792, -8.886666658639342],
              [-36.891666666883111, -8.887499992930259],
              [-36.890833333491514, -8.887499992930259],
              [-36.890833332592194, -8.886666658639342],
              [-36.89000000009986, -8.886666659538662],
              [-36.89000000099918, -8.885833326147065],
              [-36.887499999025692, -8.885833324348368],
              [-36.887499997227053, -8.884999992755411],
              [-36.886666666533415, -8.884999992755411],
              [-36.886666667432735, -8.885833326147065],
              [-36.885833334041081, -8.885833326147065],
              [-36.885833331343122, -8.884166658464494],
              [-36.88416666725783, -8.884166658464494],
              [-36.879166667807453, -8.884166659363814],
              [-36.879166667807453, -8.881666659188909],
              [-36.87833333171784, -8.881666659188909],
              [-36.87833333261716, -8.880833325797312],
              [-36.875833331542992, -8.880833326696632],
              [-36.875833333341632, -8.879999992405658],
              [-36.874999999050658, -8.879999992405658],
              [-36.875000000849298, -8.879166659014061],
              [-36.873333331368087, -8.87916665991338],
              [-36.873333330468768, -8.878333325622407],
              [-36.87249999887581, -8.878333325622407],
              [-36.872500000674449, -8.877499993130129],
              [-36.870833333891198, -8.877499993130129],
              [-36.870833332092559, -8.876666658839213],
              [-36.869999999600282, -8.876666658839213],
              [-36.869999998700962, -8.875833325447559],
              [-36.869166664409988, -8.875833324548239],
              [-36.869166665309308, -8.874999992955281],
              [-36.867500000324696, -8.874999992955281],
              [-36.867499998526057, -8.874166658664308],
              [-36.864166664959555, -8.874166658664308],
              [-36.864166666758194, -8.87333332617203],
              [-36.862499999075681, -8.87333332617203],
              [-36.86250000087432, -8.874166658664308],
              [-36.863333333366597, -8.874166658664308],
              [-36.863333333366597, -8.875833325447559],
              [-36.861666665684027, -8.875833325447559],
              [-36.861666667482666, -8.876666657939893],
              [-36.859999998900776, -8.876666658839213],
              [-36.859999998900776, -8.875833325447559],
              [-36.859166667307818, -8.875833325447559],
              [-36.859166667307818, -8.880833326696632],
              [-36.859166667307818, -8.881666657390269],
              [-36.860000000699415, -8.881666659188909],
              [-36.859999998900776, -8.882499991681243],
              [-36.861666665684027, -8.882499992580563],
              [-36.861666665684027, -8.88333332597216],
              [-36.863333331567958, -8.88333332597216],
              [-36.863333331567958, -8.884166658464494],
              [-36.865833331742806, -8.884166658464494],
              [-36.865833332642126, -8.884999992755411],
              [-36.866666666933099, -8.884999992755411],
              [-36.86666666603378, -8.886666658639342],
              [-36.868333331917711, -8.886666658639342],
              [-36.86833333371635, -8.887499992930259],
              [-36.870000000499601, -8.887499992930259],
              [-36.870000000499601, -8.888333325422593],
              [-36.870833332092559, -8.888333327221233],
              [-36.870833332092559, -8.890833325597441],
              [-36.873333332267407, -8.890833325597441],
              [-36.873333332267407, -8.891666658989095],
              [-36.874999999949978, -8.891666659888415],
              [-36.874999999050658, -8.89333332487297],
              [-36.875833331542992, -8.893333325772346],
              [-36.875833332442312, -8.894166659163943],
              [-36.876666665833909, -8.894166659163943],
              [-36.876666664934589, -8.89499999255554],
              [-36.87833333261716, -8.89499999255554],
              [-36.87833333261716, -8.896666657540152],
              [-36.877499998326243, -8.896666658439472],
              [-36.877499999225563, -8.899166658614377],
              [-36.879166666908134, -8.899166658614377],
              [-36.879166666008814, -8.897499992730445],
              [-36.879999999400411, -8.897499992730445],
              [-36.879999999400411, -8.896666658439472],
              [-36.880833331892688, -8.896666660238111],
              [-36.880833333691328, -8.897499992730445],
              [-36.881666665284342, -8.897499992730445],
              [-36.881666665284342, -8.899166658614377],
              [-36.885000000649484, -8.899166658614377],
              [-36.884999998850844, -8.899999992905293],
              [-36.886666665634095, -8.899999992905293],
              [-36.886666665634095, -8.900833325397628],
              [-36.88833333061865, -8.900833325397628],
              [-36.88833333151797, -8.902499993080198],
              [-36.889166665808943, -8.902499992180822],
              [-36.889166666708263, -8.904166658964073],
              [-36.89000000009986, -8.904166658964073],
              [-36.89000000099918, -8.904999994154366],
              [-36.891666665983792, -8.904999991456407],
              [-36.891666668681751, -8.908333325922229],
              [-36.893333332767043, -8.908333325922229],
              [-36.893333331867723, -8.910833326097077],
              [-36.894166667957336, -8.910833326097077],
              [-36.894166665259377, -8.914999993055176],
              [-36.893333331867723, -8.914999993055176],
              [-36.893333332767043, -8.914166657864939],
              [-36.886666667432735, -8.914166658764259],
              [-36.886666665634095, -8.916666658939107],
              [-36.885833332242441, -8.916666658939107],
              [-36.885833331343122, -8.917499993230081],
              [-36.88416666725783, -8.917499993230081],
              [-36.88416666725783, -8.918333325722358],
              [-36.883333332067593, -8.918333325722358],
              [-36.883333332067593, -8.919166659113955],
              [-36.882499998675939, -8.919166659113955],
              [-36.882500000474579, -8.919999992505609],
              [-36.881666667082982, -8.919999992505609],
              [-36.881666667082982, -8.920833325897206],
              [-36.880833331892688, -8.920833325897206],
              [-36.880833332792008, -8.922499992680457],
              [-36.879999998501091, -8.922499992680457],
              [-36.879999998501091, -8.923333326072111],
              [-36.879166667807453, -8.923333326072111],
              [-36.879166666008814, -8.924166658564388],
              [-36.87833333351648, -8.924166657665069],
              [-36.87833333261716, -8.92749999303021],
              [-36.874999999949978, -8.92749999303021],
              [-36.874999999949978, -8.928333324623168],
              [-36.869999999600282, -8.928333325522487],
              [-36.869999999600282, -8.92749999213089],
              [-36.869166668007267, -8.92749999303021],
              [-36.869166665309308, -8.928333325522487],
              [-36.861666667482666, -8.928333325522487],
              [-36.861666666583346, -8.92749999303021],
              [-36.858333331218205, -8.92749999392953],
              [-36.858333333016844, -8.925833325347639],
              [-36.854999998551023, -8.925833325347639],
              [-36.855000000349719, -8.924999992855362],
              [-36.853333331767828, -8.924999992855362],
              [-36.853333332667148, -8.926666658739236],
              [-36.854999999450399, -8.926666658739236],
              [-36.854999999450399, -8.928333324623168],
              [-36.855833332841996, -8.928333325522487],
              [-36.855833331942677, -8.929166658914141],
              [-36.856666668032233, -8.929166659813461],
              [-36.856666666233593, -8.930833325697392],
              [-36.857499999625247, -8.930833325697392],
              [-36.857499999625247, -8.932499992480643],
              [-36.858333331218205, -8.932499992480643],
              [-36.858333331218205, -8.93333332587224],
              [-36.860000001598735, -8.933333324972921],
              [-36.859999998900776, -8.934166658364518],
              [-36.861666666583346, -8.934166658364518],
              [-36.861666665684027, -8.934999991756172],
              [-36.862499999975, -8.934999992655491],
              [-36.86250000087432, -8.935833326047089],
              [-36.863333331567958, -8.935833326047089],
              [-36.863333332467278, -8.93749999283034],
              [-36.864166665858875, -8.93749999283034],
              [-36.864166665858875, -8.938333326221994],
              [-36.864999998351209, -8.938333324423354],
              [-36.864999998351209, -8.939999993005245],
              [-36.865833331742806, -8.939999993005245],
              [-36.865833332642126, -8.940833325497522],
              [-36.867499998526057, -8.940833324598202],
              [-36.867499998526057, -8.941666658889176],
              [-36.869166665309308, -8.941666658889176],
              [-36.869166668007267, -8.942499993180093],
              [-36.869999999600282, -8.942499993180093],
              [-36.869999999600282, -8.94333332567237],
              [-36.870833333891198, -8.94333332567237],
              [-36.870833332991879, -8.944166659064024],
              [-36.87249999977513, -8.944166659064024],
              [-36.872500000674449, -8.944999992455621],
              [-36.873333334066047, -8.944999992455621],
              [-36.873333331368087, -8.946666657440232],
              [-36.872500000674449, -8.946666659238872],
              [-36.87249999887581, -8.948333326022123],
              [-36.873333332267407, -8.948333326022123],
              [-36.873333334066047, -8.949166659413777],
              [-36.874166665659061, -8.949166658514457],
              [-36.874166665659061, -8.949999993704694],
              [-36.874999999949978, -8.949999992805374],
              [-36.875000000849298, -8.950833326197028],
              [-36.875833331542992, -8.950833326197028],
              [-36.875833332442312, -8.951666657789985],
              [-36.876666666733229, -8.951666658689305],
              [-36.876666665833909, -8.954166658864153],
              [-36.877499998326243, -8.954166658864153],
              [-36.877499998326243, -8.954999993155127],
              [-36.879999998501091, -8.954999993155127],
              [-36.879999999400411, -8.955833325647404],
              [-36.880833333691328, -8.955833325647404],
              [-36.880833331892688, -8.958333325822309],
              [-36.881666667982302, -8.958333325822309],
              [-36.881666664385023, -8.960833326896477],
              [-36.882499999575259, -8.960833325997157],
              [-36.882499998675939, -8.961666658489435],
              [-36.883333332067593, -8.961666659388754],
              [-36.883333332067593, -8.963333326172005],
              [-36.88416666725783, -8.963333326172005],
              [-36.88416666635851, -8.96416665866434],
              [-36.884999998850844, -8.96416665866434],
              [-36.884999999750164, -8.967499994029481],
              [-36.885833330443802, -8.967499993130104],
              [-36.885833331343122, -8.96999999240569],
              [-36.88833333151797, -8.969999994204329],
              [-36.88833333151797, -8.969166659014036],
              [-36.89000000009986, -8.969166659014036],
              [-36.89000000009986, -8.968333325622439],
              [-36.890833331692875, -8.968333325622439],
              [-36.890833332592194, -8.967499993130104],
              [-36.891666668681751, -8.967499993130104],
              [-36.891666666883111, -8.966666658839188],
              [-36.894166665259377, -8.966666658839188],
              [-36.894166667058016, -8.965833325447591],
              [-36.895833332941891, -8.965833325447591],
              [-36.895833332042571, -8.969166659014036],
              [-36.895000000449613, -8.969166659913355],
              [-36.894999998650974, -8.970833325797287],
              [-36.895833332941891, -8.970833324897967],
              [-36.895833332941891, -8.973333325972192],
              [-36.896666666333545, -8.973333325972192],
              [-36.896666666333545, -8.974999991856066],
              [-36.897499999725142, -8.974999992755443],
              [-36.897499999725142, -8.979166658814222],
              [-36.898333334016115, -8.979166658814222],
              [-36.898333332217476, -8.979999994004459],
              [-36.900000000799366, -8.979999993105139],
              [-36.899999999000727, -8.980833325597473],
              [-36.904166666858146, -8.980833325597473],
              [-36.904166665958826, -8.979999993105139],
              [-36.904999998451103, -8.979999993105139],
              [-36.904999999350423, -8.979166658814222],
              [-36.905833332742077, -8.979166658814222],
              [-36.905833333641397, -8.978333325422568],
              [-36.906666664335035, -8.978333325422568],
              [-36.906666667032994, -8.977499992030971],
              [-36.907499998626008, -8.977499992930291],
              [-36.907499998626008, -8.97583332614704],
              [-36.909166666308579, -8.97583332614704],
              [-36.909166665409259, -8.974999991856066],
              [-36.909999999700176, -8.974999993654762],
              [-36.910000000599496, -8.974166658464469],
              [-36.910833331293134, -8.974166658464469],
              [-36.910833330393814, -8.973333325072872],
              [-36.914166666658275, -8.973333325972192],
              [-36.914166666658275, -8.972499992580538],
              [-36.915000000049929, -8.972499992580538],
              [-36.91499999825129, -8.971666659188941],
              [-36.918333333616431, -8.97166666008826],
              [-36.918333333616431, -8.972499991681218],
              [-36.920000000399682, -8.972499993479857],
              [-36.919999999500305, -8.971666659188941],
              [-36.92249999967521, -8.97166666008826],
              [-36.922499998775891, -8.970833325797287],
              [-36.923333331268168, -8.970833325797287],
              [-36.923333330368848, -8.964999992955256],
              [-36.924999999850058, -8.964999992955256],
              [-36.924999998950739, -8.96416665866434],
              [-36.925833331443073, -8.96416665776502],
              [-36.925833332342393, -8.963333324373366],
              [-36.926666667532629, -8.963333327071325],
              [-36.92666666573399, -8.962499992780408],
              [-36.927500000924283, -8.962499992780408],
              [-36.927500000024963, -8.96416665866434],
              [-36.928333332517241, -8.96416665866434],
              [-36.928333332517241, -8.967499993130104],
              [-36.929166665908838, -8.967499993130104],
              [-36.929166665908838, -8.969166659014036],
              [-36.929999999300492, -8.969166659014036],
              [-36.929999998401172, -8.969999994204329],
              [-36.931666666983062, -8.96999999240569],
              [-36.931666667882382, -8.970833326696606],
              [-36.93249999947534, -8.970833324897967],
              [-36.93249999857602, -8.972499992580538],
              [-36.933333333766313, -8.972499993479857],
              [-36.933333331967674, -8.974166657565149],
              [-36.935000001448884, -8.974166658464469],
              [-36.934999999650245, -8.974999992755443],
              [-36.941666664984552, -8.974999991856066],
              [-36.944166667857417, -8.974999992755443],
              [-36.944166667857417, -8.97583332614704],
              [-36.944999997651735, -8.97583332614704],
              [-36.944999998551054, -8.976666658639317],
              [-36.945833332841971, -8.976666658639317],
              [-36.945833333741291, -8.978333325422568],
              [-36.945000000349694, -8.978333325422568],
              [-36.944999999450374, -8.98166665898907],
              [-36.944999998551054, -8.983333325772321],
              [-36.945833332841971, -8.983333325772321],
              [-36.945833332841971, -8.984999992555572],
              [-36.946666665334305, -8.984999992555572],
              [-36.946666666233625, -8.985833325947169],
              [-36.947499998725903, -8.985833325947169],
              [-36.947499998725903, -8.986666658439503],
              [-36.950833331393085, -8.986666657540184],
              [-36.950833331393085, -8.98749999273042],
              [-36.951666667482698, -8.98749999273042],
              [-36.951666665684058, -8.988333326122074],
              [-36.952499999974975, -8.988333326122074],
              [-36.952499997277016, -8.990833325397602],
              [-36.950833332292405, -8.990833325397602],
              [-36.950833334091044, -8.9916666587892],
              [-36.949999998900807, -8.9916666587892],
              [-36.950000000699447, -8.992499993080173],
              [-36.949166667307793, -8.992499993979493],
              [-36.949166665509154, -8.993333325572451],
              [-36.948333333016876, -8.993333325572451],
              [-36.948333333016876, -8.995833325747356],
              [-36.947499999625222, -8.995833324848036],
              [-36.947499999625222, -9.002499992880303],
              [-36.948333333016876, -9.002499992880303],
              [-36.948333333916196, -9.004166658764234],
              [-36.949166666408473, -9.004166658764234],
              [-36.949166665509154, -9.004999993055208],
              [-36.950833334091044, -9.004999993055208],
              [-36.950833332292405, -9.005833324648165],
              [-36.951666667482698, -9.005833325547485],
              [-36.951666666583378, -9.006666658039762],
              [-36.950833334091044, -9.006666658939139],
              [-36.950833331393085, -9.007499993230056],
              [-36.950000000699447, -9.007499993230056],
              [-36.950000000699447, -9.008333326621653],
              [-36.949166667307793, -9.008333325722333],
              [-36.949166666408473, -9.009166659113987],
              [-36.948333333016876, -9.009166659113987],
              [-36.948333333916196, -9.009999993404904],
              [-36.947499999625222, -9.009999993404904],
              [-36.947499998725903, -9.011666657490196],
              [-36.945833333741291, -9.011666658389515],
              [-36.945833333741291, -9.012499993579809],
              [-36.94416666605872, -9.012499992680489],
              [-36.94416666605872, -9.013333326072086],
              [-36.943333332667123, -9.013333326971406],
              [-36.943333331767803, -9.01416665856442],
              [-36.941666666783192, -9.01416665856442],
              [-36.941666667682512, -9.014999992855337],
              [-36.940833332492275, -9.014999993754657],
              [-36.940833332492275, -9.015833325347614],
              [-36.939999999100621, -9.015833324448295],
              [-36.94, -9.016666658739268],
              [-36.939166665709024, -9.016666658739268],
              [-36.939166666608344, -9.017499993030185],
              [-36.93833333231737, -9.017499993030185],
              [-36.93833333231737, -9.018333325522519],
              [-36.937499999825093, -9.018333325522519],
              [-36.937499999825093, -9.019166658914116],
              [-36.936666667332815, -9.019166658914116],
              [-36.936666666433439, -9.020833325697367],
              [-36.937499998925773, -9.020833325697367],
              [-36.937499998925773, -9.023333325872272],
              [-36.936666665534119, -9.023333325872272],
              [-36.936666667332815, -9.02416665836455],
              [-36.937500000724413, -9.02416665836455],
              [-36.937499999825093, -9.02583332604712],
              [-36.93833333321669, -9.025833325147801],
              [-36.93833333321669, -9.029166658714303],
              [-36.937499998925773, -9.029166658714303],
              [-36.937499998925773, -9.031666658889151],
              [-36.936666666433439, -9.031666658889151],
              [-36.936666666433439, -9.034999992455653],
              [-36.937499999825093, -9.034999992455653],
              [-36.937499998925773, -9.037499992630501],
              [-36.936666665534119, -9.037499992630501],
              [-36.936666665534119, -9.038333326022155],
              [-36.937499999825093, -9.038333326921475],
              [-36.937499998925773, -9.039999992805349],
              [-36.936666666433439, -9.039999992805349],
              [-36.936666665534119, -9.044166658864185],
              [-36.937499999825093, -9.044166658864185],
              [-36.937499998925773, -9.046666659039033],
              [-36.937499998925773, -9.047499992430687],
              [-36.93833333321669, -9.047499992430687],
              [-36.93833333141805, -9.048333325822284],
              [-36.939166667507664, -9.048333325822284],
              [-36.939166667507664, -9.049166660113201],
              [-36.94, -9.049166660113201],
              [-36.939999999100621, -9.049999992605535],
              [-36.939166666608344, -9.049999992605535],
              [-36.939166665709024, -9.051666658489466],
              [-36.940833332492275, -9.051666658489466],
              [-36.940833332492275, -9.052499992780383],
              [-36.942499998376206, -9.052499992780383],
              [-36.942499998376206, -9.051666659388786],
              [-36.943333331767803, -9.051666658489466],
              [-36.943333332667123, -9.052499992780383],
              [-36.94416666605872, -9.052499992780383],
              [-36.944166666958097, -9.053333326172037],
              [-36.944999998551054, -9.053333326172037],
              [-36.944999999450374, -9.052499992780383],
              [-36.945833333741291, -9.052499992780383],
              [-36.945833332841971, -9.049999992605535],
              [-36.946666666233625, -9.049999992605535],
              [-36.946666666233625, -9.049166657415242],
              [-36.947499999625222, -9.049166660113201],
              [-36.947499998725903, -9.048333325822284],
              [-36.949166665509154, -9.048333325822284],
              [-36.949166665509154, -9.047499991531311],
              [-36.949999999800127, -9.047499992430687],
              [-36.949999999800127, -9.046666659039033],
              [-36.950833334091044, -9.046666659938353],
              [-36.950833331393085, -9.045833325647436],
              [-36.953333331567933, -9.045833325647436],
              [-36.953333332467253, -9.044166657964865],
              [-36.954166667657546, -9.044166658864185],
              [-36.954166665858907, -9.043333325472531],
              [-36.954999999250504, -9.043333326371851],
              [-36.955000001049143, -9.04166665868928],
              [-36.955833331742838, -9.04166665778996],
              [-36.955833331742838, -9.040833327096323],
              [-36.956666667832394, -9.040833326197003],
              [-36.956666666933074, -9.038333325122835],
              [-36.958333333716325, -9.038333326921475],
              [-36.958333332817006, -9.037499992630501],
              [-36.960833332991854, -9.037499992630501],
              [-36.960833332991854, -9.038333325122835],
              [-36.961666665484188, -9.038333326022155],
              [-36.961666665484188, -9.039166658514432],
              [-36.96499999905069, -9.039166658514432],
              [-36.96499999905069, -9.039999992805349],
              [-36.964166667457675, -9.039999992805349],
              [-36.964166666558356, -9.040833326197003],
              [-36.963333331368119, -9.040833326197003],
              [-36.963333334066078, -9.04166665868928],
              [-36.962499999775105, -9.04166665868928],
              [-36.962499998875785, -9.042499993879574],
              [-36.961666664584868, -9.042499992980254],
              [-36.961666665484188, -9.043333325472531],
              [-36.960833330293895, -9.043333325472531],
              [-36.960833332991854, -9.044166658864185],
              [-36.959999998700937, -9.044166658864185],
              [-36.959999999600257, -9.044999993155102],
              [-36.959166668007299, -9.044999993155102],
              [-36.95916666530934, -9.045833325647436],
              [-36.958333333716325, -9.045833325647436],
              [-36.958333332817006, -9.047499992430687],
              [-36.957499998526089, -9.047499991531311],
              [-36.957500000324728, -9.049166659213881],
              [-36.955833331742838, -9.049166659213881],
              [-36.955833332642158, -9.050833325997132],
              [-36.954999998351184, -9.050833325997132],
              [-36.954999998351184, -9.051666658489466],
              [-36.953333331567933, -9.051666658489466],
              [-36.953333332467253, -9.052499992780383],
              [-36.951666665684058, -9.052499992780383],
              [-36.951666667482698, -9.054999992955288],
              [-36.950833334091044, -9.054999992955288],
              [-36.950833331393085, -9.055833325447566],
              [-36.949999999800127, -9.055833325447566],
              [-36.949999998900807, -9.059166659014068],
              [-36.950833334091044, -9.059166659014068],
              [-36.950833331393085, -9.062499993479889],
              [-36.949999999800127, -9.06249999258057],
              [-36.949999998900807, -9.065833326147015],
              [-36.949999999800127, -9.067499992930266],
              [-36.949166665509154, -9.067499992930266],
              [-36.949166666408473, -9.069166659713517],
              [-36.949999999800127, -9.069166658814197],
              [-36.949999998900807, -9.070833325597448],
              [-36.950833331393085, -9.070833326496768],
              [-36.950833332292405, -9.071666658989102],
              [-36.951666666583378, -9.071666658989102],
              [-36.951666665684058, -9.072499992380699],
              [-36.950833334990364, -9.072499992380699],
              [-36.950833332292405, -9.074999992555547],
              [-36.951666666583378, -9.074999992555547],
              [-36.951666666583378, -9.075833326846521],
              [-36.950833331393085, -9.075833325947201],
              [-36.950833331393085, -9.078333326122049],
              [-36.951666666583378, -9.078333326122049],
              [-36.951666665684058, -9.079166658614383],
              [-36.952500001773615, -9.079166658614383],
              [-36.952499999075656, -9.0799999929053],
              [-36.957499998526089, -9.0799999929053],
              [-36.957499999425409, -9.079166658614383],
              [-36.95916666620866, -9.079166658614383],
              [-36.959166668007299, -9.078333326122049],
              [-36.960000000499576, -9.078333326122049],
              [-36.959999999600257, -9.076666658439478],
              [-36.96083333389123, -9.076666660238118],
              [-36.960833332991854, -9.078333326122049],
              [-36.961666667282827, -9.078333326122049],
              [-36.961666665484188, -9.081666658789231],
              [-36.960833332092534, -9.081666658789231],
              [-36.960833332092534, -9.083333325572482],
              [-36.961666667282827, -9.083333325572482],
              [-36.961666665484188, -9.084166658964079],
              [-36.960833332991854, -9.084166658964079],
              [-36.960833332092534, -9.084999992355733],
              [-36.961666665484188, -9.084999992355733],
              [-36.961666666383508, -9.086666659138984],
              [-36.960833332991854, -9.086666659138984],
              [-36.96083333389123, -9.087499992530581],
              [-36.959999998700937, -9.087499992530581],
              [-36.959999999600257, -9.089166658414513],
              [-36.95916666530934, -9.089166658414513],
              [-36.95916666530934, -9.08999999270543],
              [-36.958333333716325, -9.08999999270543],
              [-36.958333332817006, -9.090833326097083],
              [-36.95916666530934, -9.090833326097083],
              [-36.95916666620866, -9.091666657690041],
              [-36.963333331368119, -9.091666658589361],
              [-36.963333331368119, -9.092499992880335],
              [-36.964166667457675, -9.092499992880335],
              [-36.964166665659036, -9.093333325372612],
              [-36.966666665833941, -9.093333325372612],
              [-36.96666666763258, -9.091666658589361],
              [-36.967499999225538, -9.091666658589361],
              [-36.967499998326218, -9.089166658414513],
              [-36.968333331717815, -9.089166658414513],
              [-36.968333332617135, -9.088333325022859],
              [-36.969166666008789, -9.088333325922179],
              [-36.96916666421015, -9.087499992530581],
              [-36.969999999400386, -9.087499992530581],
              [-36.969999999400386, -9.08583332574733],
              [-36.971666665284317, -9.08583332574733],
              [-36.971666667082957, -9.084999992355733],
              [-36.972499998675971, -9.084999992355733],
              [-36.972499998675971, -9.08416665806476],
              [-36.973333333866208, -9.084166658964079],
              [-36.973333332966888, -9.083333325572482],
              [-36.977499999924987, -9.083333325572482],
              [-36.977499999924987, -9.082499993080148],
              [-36.978333334215961, -9.082499993080148],
              [-36.978333333316641, -9.081666658789231],
              [-36.979166665808918, -9.081666658789231],
              [-36.979166665808918, -9.080833325397577],
              [-36.981666665983823, -9.080833325397577],
              [-36.981666666883143, -9.0799999929053],
              [-36.98250000027474, -9.0799999929053],
              [-36.98249999937542, -9.079166658614383],
              [-36.983333331867755, -9.079166658614383],
              [-36.983333333666394, -9.078333327021369],
              [-36.984166665259352, -9.078333326122049],
              [-36.984166665259352, -9.077499992730452],
              [-36.989999998101382, -9.077499991831132],
              [-36.989999999900022, -9.076666658439478],
              [-36.991666666683273, -9.076666658439478],
              [-36.991666666683273, -9.077499992730452],
              [-36.992500000074926, -9.077499992730452],
              [-36.992500000074926, -9.078333326122049],
              [-36.993333331667884, -9.078333326122049],
              [-36.993333332567204, -9.079166658614383],
              [-36.99249999827623, -9.079166658614383],
              [-36.992500000074926, -9.081666658789231],
              [-36.985833332042603, -9.081666658789231],
              [-36.985833332941922, -9.082499993080148],
              [-36.984999998650949, -9.082499993080148],
              [-36.984999999550269, -9.083333325572482],
              [-36.984166665259352, -9.083333325572482],
              [-36.984166667957311, -9.084166658964079],
              [-36.983333332767074, -9.084166658964079],
              [-36.983333334565714, -9.084999992355733],
              [-36.982499998476101, -9.084999992355733],
              [-36.98249999937542, -9.08583332574733],
              [-36.981666667782463, -9.08583332574733],
              [-36.981666665983823, -9.086666659138984],
              [-36.98083333169285, -9.086666659138984],
              [-36.980833332592169, -9.088333325922179],
              [-36.979999998301253, -9.088333325922179],
              [-36.980000000099892, -9.08999999270543],
              [-36.979166665808918, -9.08999999270543],
              [-36.979166666708238, -9.090833326097083],
              [-36.978333331518002, -9.090833326097083],
              [-36.978333331518002, -9.093333324473292],
              [-36.977499999025667, -9.093333325372612],
              [-36.977499999025667, -9.094999993055183],
              [-36.97666666653339, -9.094999993055183],
              [-36.97666666653339, -9.100833325897213],
              [-36.977500000824307, -9.100833324997893],
              [-36.977499999924987, -9.107499993030217],
              [-36.978333332417321, -9.107499993030217],
              [-36.978333331518002, -9.109999991406426],
              [-36.979166667607558, -9.109999993205065],
              [-36.979166667607558, -9.110833325697399],
              [-36.979999998301253, -9.11083332479808],
              [-36.979999998301253, -9.111666659088996],
              [-36.979166666708238, -9.111666659088996],
              [-36.979166666708238, -9.11666665853943],
              [-36.980000000099892, -9.11666665853943],
              [-36.979999998301253, -9.119166658714278],
              [-36.98083333169285, -9.119166657814958],
              [-36.980833332592169, -9.124166659064031],
              [-36.981666665983823, -9.124166659064031],
              [-36.981666665983823, -9.124999992455628],
              [-36.98250000027474, -9.124999992455628],
              [-36.98249999937542, -9.125833325847282],
              [-36.983333332767074, -9.125833325847282],
              [-36.983333333666394, -9.127499992630533],
              [-36.984166664360032, -9.127499992630533],
              [-36.984166667957311, -9.126666659238879],
              [-36.984999997751629, -9.126666657440239],
              [-36.984999999550269, -9.125833325847282],
              [-36.985833332941922, -9.125833325847282],
              [-36.985833333841242, -9.124999992455628],
              [-36.9866666654342, -9.124999991556308],
              [-36.98666666453488, -9.124166658164711],
              [-36.987499998825854, -9.124166659064031],
              [-36.987499997926534, -9.123333325672377],
              [-36.988333331318131, -9.123333325672377],
              [-36.988333331318131, -9.122499993180099],
              [-36.989166665609105, -9.122499993180099],
              [-36.989166665609105, -9.121666658889126],
              [-36.993333331667884, -9.121666658889126],
              [-36.993333331667884, -9.120833325497529],
              [-36.994999999350455, -9.120833325497529],
              [-36.994999999350455, -9.119166658714278],
              [-36.996666667033026, -9.119166657814958],
              [-36.996666665234386, -9.120833325497529],
              [-36.995833333641372, -9.120833325497529],
              [-36.995833332742052, -9.121666657989806],
              [-36.994999999350455, -9.121666658889126],
              [-36.994999999350455, -9.126666659238879],
              [-36.994166665958801, -9.126666660138198],
              [-36.994166665958801, -9.127499992630533],
              [-36.99249999827623, -9.127499992630533],
              [-36.99249999827623, -9.129166658514407],
              [-36.991666665783953, -9.129166657615087],
              [-36.991666667582592, -9.129999992805381],
              [-36.990833332392356, -9.129999992805381],
              [-36.990833331493036, -9.134999993155134],
              [-36.990000000799341, -9.134999993155134],
              [-36.989999999000702, -9.138333325822259],
              [-36.988333332217451, -9.138333325822259],
              [-36.988333332217451, -9.140833325997164],
              [-36.987499998825854, -9.140833325997164],
              [-36.987500000624493, -9.141666658489441],
              [-36.98666666633352, -9.141666658489441],
              [-36.98666666633352, -9.142499992780415],
              [-36.987500000624493, -9.142499992780415],
              [-36.987499998825854, -9.143333326172012],
              [-36.9866666654342, -9.143333327071332],
              [-36.9866666654342, -9.144166658664346],
              [-36.984999998650949, -9.144166658664346],
              [-36.985000000449645, -9.144999992955263],
              [-36.983333332767074, -9.144999992955263],
              [-36.983333331867755, -9.144166658664346],
              [-36.981666667782463, -9.144166658664346],
              [-36.981666665983823, -9.145833325447541],
              [-36.983333330968378, -9.145833325447541],
              [-36.983333331867755, -9.147499993130111],
              [-36.984166668856631, -9.147499993130111],
              [-36.984166667057991, -9.148333326521765],
              [-36.993333332567204, -9.148333325622445],
              [-36.993333331667884, -9.147499993130111],
              [-36.994166666858121, -9.147499992230792],
              [-36.994166665958801, -9.148333325622445],
              [-36.997499998625983, -9.148333325622445],
              [-36.997499999525303, -9.149166659014043],
              [-37.004166666658307, -9.149166658114723],
              [-37.004166665758987, -9.148333325622445],
              [-37.007499998426169, -9.148333325622445],
              [-37.007499999325489, -9.147499993130111],
              [-37.010833331992615, -9.147499993130111],
              [-37.010833331093295, -9.148333325622445],
              [-37.013333333066839, -9.148333326521765],
              [-37.013333333966159, -9.149999992405697],
              [-37.01500000074941, -9.149999994204336],
              [-37.014999998950771, -9.151666659188948],
              [-37.015833332342368, -9.151666659188948],
              [-37.015833332342368, -9.152499992580545],
              [-37.016666665734022, -9.152499992580545],
              [-37.016666668431981, -9.155833326147047],
              [-37.017500000024938, -9.155833326147047],
              [-37.017500000924258, -9.157499992930298],
              [-37.018333332517216, -9.157499992030978],
              [-37.018333332517216, -9.158333325422575],
              [-37.017499998226299, -9.158333325422575],
              [-37.017499998226299, -9.164999992555579],
              [-37.016666667532661, -9.164999992555579],
              [-37.016666665734022, -9.167499992730427],
              [-37.017500000024938, -9.167499992730427],
              [-37.017500000924258, -9.168333326122081],
              [-37.01916666590887, -9.168333326122081],
              [-37.019166666808189, -9.169166658614358],
              [-37.020833331792801, -9.169166658614358],
              [-37.020833332692121, -9.168333326122081],
              [-37.022499999475372, -9.168333326122081],
              [-37.022499999475372, -9.16666665843951],
              [-37.024166668057262, -9.16666665754019],
              [-37.024166665359303, -9.165833325947176],
              [-37.025000000549539, -9.165833325947176],
              [-37.0249999987509, -9.164999992555579],
              [-37.025833331243177, -9.164999991656259],
              [-37.025833333041817, -9.163333325772328],
              [-37.026666666433471, -9.163333325772328],
              [-37.026666666433471, -9.162499992380674],
              [-37.027499999825068, -9.162499992380674],
              [-37.027499998925748, -9.161666658989077],
              [-37.028333331418082, -9.161666659888397],
              [-37.028333332317402, -9.159999993105146],
              [-37.031666666783224, -9.159999993105146],
              [-37.031666666783224, -9.1608333264968],
              [-37.034999998551029, -9.16083332559748],
              [-37.035000000349669, -9.161666658989077],
              [-37.035833331942683, -9.161666658989077],
              [-37.035833332842003, -9.162499992380674],
              [-37.039999999800102, -9.162499992380674],
              [-37.040000000699422, -9.161666658089757],
              [-37.04249999997495, -9.161666658989077],
              [-37.04250000087427, -9.16083332559748],
              [-37.043333332467284, -9.160833324698103],
              [-37.043333332467284, -9.158333325422575],
              [-37.044166666758201, -9.158333325422575],
              [-37.044166667657521, -9.154999992755393],
              [-37.043333333366604, -9.154999992755393],
              [-37.043333332467284, -9.153333325072822],
              [-37.044166667657521, -9.153333325972142],
              [-37.044166667657521, -9.152499992580545],
              [-37.044999998351216, -9.152499992580545],
              [-37.044999998351216, -9.151666659188948],
              [-37.046666666933106, -9.151666659188948],
              [-37.046666666933106, -9.150833325797294],
              [-37.047500000324703, -9.150833325797294],
              [-37.047499999425384, -9.149999992405697],
              [-37.052499998875817, -9.149999991506377],
              [-37.052499999775137, -9.151666659188948],
              [-37.056666666733236, -9.151666659188948],
              [-37.056666665833916, -9.152499992580545],
              [-37.059999998501098, -9.152499993479864],
              [-37.060000000299738, -9.153333325972142],
              [-37.060833333691335, -9.153333326871518],
              [-37.060833331892695, -9.154166658464476],
              [-37.06333333386624, -9.154166658464476],
              [-37.063333334765559, -9.155833327046366],
              [-37.067499999925019, -9.155833326147047],
              [-37.067499999025699, -9.156666657740004],
              [-37.06916666670827, -9.156666658639324],
              [-37.06916666670827, -9.157499992030978],
              [-37.069999998301228, -9.157499992930298],
              [-37.070000000999187, -9.159999993105146],
              [-37.070833331692882, -9.159999993105146],
              [-37.070833331692882, -9.161666658989077],
              [-37.071666666883118, -9.161666658989077],
              [-37.071666665084479, -9.162499992380674],
              [-37.073333332767049, -9.162499992380674],
              [-37.073333332767049, -9.163333325772328],
              [-37.075833333841217, -9.163333326671648],
              [-37.075833332941897, -9.164166657365286],
              [-37.076666666333551, -9.164166659163925],
              [-37.076666665434232, -9.162499992380674],
              [-37.077499999725148, -9.162499992380674],
              [-37.077499998825829, -9.161666658989077],
              [-37.078333332217483, -9.161666659888397],
              [-37.078333334016122, -9.16083332559748],
              [-37.079166666508399, -9.16083332559748],
              [-37.079166666508399, -9.159166658814229],
              [-37.080000000799373, -9.159166658814229],
              [-37.079999999900053, -9.158333325422575],
              [-37.079166667407719, -9.158333325422575],
              [-37.07916666560908, -9.154166658464476],
              [-37.078333332217483, -9.154166658464476],
              [-37.078333332217483, -9.152499992580545],
              [-37.077499998825829, -9.152499992580545],
              [-37.077499999725148, -9.149999992405697],
              [-37.080000001698693, -9.149999992405697],
              [-37.083333332567179, -9.149999992405697],
              [-37.083333332567179, -9.148333325622445],
              [-37.084166665958833, -9.148333325622445],
              [-37.084166667757472, -9.147499993130111],
              [-37.08499999935043, -9.147499993130111],
              [-37.08499999935043, -9.146666658839194],
              [-37.085833332742084, -9.146666658839194],
              [-37.085833332742084, -9.145833324548221],
              [-37.087499998626015, -9.145833325447541],
              [-37.087500000424654, -9.144999992955263],
              [-37.089999998800863, -9.144999992955263],
              [-37.089999998800863, -9.143333326172012],
              [-37.09083333309178, -9.143333326172012],
              [-37.09083333129314, -9.142499992780415],
              [-37.093333331468045, -9.142499992780415],
              [-37.093333333266685, -9.141666658489441],
              [-37.092500000774351, -9.141666658489441],
              [-37.092499998975711, -9.136666659039065],
              [-37.091666667382754, -9.136666659039065],
              [-37.091666664684794, -9.12833332602213],
              [-37.092499998975711, -9.12833332602213],
              [-37.09250000167367, -9.125833325847282],
              [-37.091666667382754, -9.125833325847282],
              [-37.091666666483434, -9.124999991556308],
              [-37.092500000774351, -9.124999991556308],
              [-37.092499998975711, -9.12416665996335],
              [-37.091666666483434, -9.124166659064031],
              [-37.091666667382754, -9.119999993904571],
              [-37.092499998975711, -9.119999993005251],
              [-37.092499999875031, -9.117499992830346],
              [-37.093333333266685, -9.117499992830346],
              [-37.093333332367365, -9.114166658364525],
              [-37.092499998975711, -9.114166658364525],
              [-37.092499998975711, -9.109999991406426],
              [-37.091666665584114, -9.109999993205065],
              [-37.091666666483434, -9.105833324448326],
              [-37.092499998975711, -9.105833325347646],
              [-37.092499998975711, -9.104166658564395],
              [-37.093333332367365, -9.104166658564395],
              [-37.093333332367365, -9.097499993230031],
              [-37.095000000049936, -9.097499993230031],
              [-37.095000000049936, -9.098333325722365],
              [-37.095833332542213, -9.098333325722365],
              [-37.095833331642893, -9.099166659113962],
              [-37.096666667732507, -9.099166659113962],
              [-37.096666665933867, -9.099999992505616],
              [-37.097500000224784, -9.099999992505616],
              [-37.097500000224784, -9.100833326796533],
              [-37.098333332717061, -9.100833325897213],
              [-37.098333332717061, -9.101666658389547],
              [-37.099999998600993, -9.101666658389547],
              [-37.099999998600993, -9.102499992680464],
              [-37.100833332891966, -9.102499992680464],
              [-37.100833331992646, -9.103333326072118],
              [-37.101666665384244, -9.103333326072118],
              [-37.101666665384244, -9.106666658739243],
              [-37.103333333066814, -9.106666658739243],
              [-37.103333330368855, -9.109166658914148],
              [-37.104166667357788, -9.109166658914148],
              [-37.104166665559148, -9.111666659088996],
              [-37.104999999850065, -9.111666659088996],
              [-37.105000000749385, -9.11249999248065],
              [-37.105833333241719, -9.11249999337997],
              [-37.105833332342343, -9.113333325872247],
              [-37.104999998950746, -9.113333325872247],
              [-37.104999998950746, -9.114999992655498],
              [-37.107500000924233, -9.114999992655498],
              [-37.107500000024913, -9.115833325147776],
              [-37.108333333416567, -9.115833326946415],
              [-37.108333332517248, -9.11666665853943],
              [-37.110833333591415, -9.11666665853943],
              [-37.110833331792776, -9.118333324423361],
              [-37.111666667882389, -9.11833332712132],
              [-37.11166666608375, -9.119166658714278],
              [-37.112499999475347, -9.119166658714278],
              [-37.112499999475347, -9.122499993180099],
              [-37.111666667882389, -9.122499993180099],
              [-37.111666666983069, -9.124999992455628],
              [-37.110833332692096, -9.124999993354947],
              [-37.110833331792776, -9.126666657440239],
              [-37.111666667882389, -9.126666660138198],
              [-37.11166666608375, -9.12833332602213],
              [-37.112500000374666, -9.12833332602213],
              [-37.112499999475347, -9.129999992805381],
              [-37.114166665359278, -9.129999992805381],
              [-37.114166665359278, -9.130833326196978],
              [-37.113333332867001, -9.130833326196978],
              [-37.113333331967681, -9.134999993155134],
              [-37.114999999650195, -9.134999993155134],
              [-37.114999998750875, -9.135833325647411],
              [-37.115833331243209, -9.135833324748091],
              [-37.115833333041849, -9.137499992430662],
              [-37.115000000549514, -9.137499992430662],
              [-37.114999999650195, -9.139166659213913],
              [-37.12, -9.139166659213913],
              [-37.119999999100628, -9.138333324922939],
              [-37.12333333176781, -9.138333324922939],
              [-37.12333333176781, -9.139166659213913],
              [-37.124999999450381, -9.139166659213913],
              [-37.124999998551061, -9.13999999170619],
              [-37.126666666233632, -9.13999999260551],
              [-37.126666665334312, -9.140833325997164],
              [-37.128333331218244, -9.140833326896484],
              [-37.128333333016883, -9.141666658489441],
              [-37.1291666673078, -9.141666658489441],
              [-37.129166664609841, -9.142499993679735],
              [-37.129999999800134, -9.142499992780415],
              [-37.129999998900757, -9.148333325622445],
              [-37.130833331393092, -9.148333325622445],
              [-37.130833334091051, -9.150833325797294],
              [-37.129999998900757, -9.150833326696613],
              [-37.130000000699454, -9.153333325972142],
              [-37.130833332292411, -9.153333325972142],
              [-37.130833334091051, -9.154166658464476],
              [-37.131666665684008, -9.154166658464476],
              [-37.131666667482648, -9.154999992755393],
              [-37.130833332292411, -9.154999992755393],
              [-37.130833331393092, -9.156666658639324],
              [-37.129999999800134, -9.156666658639324],
              [-37.129999999800134, -9.157499992930298],
              [-37.1291666673078, -9.157499992930298],
              [-37.12916666550916, -9.16083332559748],
              [-37.129999998900757, -9.16083332559748],
              [-37.130000000699454, -9.161666659888397],
              [-37.130833330493772, -9.161666658989077],
              [-37.130833331393092, -9.162499992380674],
              [-37.135833333541484, -9.162499992380674],
              [-37.135833332642164, -9.164999991656259],
              [-37.136666666933081, -9.164999993454899],
              [-37.136666666933081, -9.165833325947176],
              [-37.138333331917693, -9.165833325947176],
              [-37.138333333716332, -9.16666665754019],
              [-37.13916666530929, -9.16666665843951],
              [-37.13916666620861, -9.167499991831107],
              [-37.139999998700944, -9.167499992730427],
              [-37.139999998700944, -9.168333325222761],
              [-37.139166668007306, -9.168333326122081],
              [-37.13916666530929, -9.169999992905275],
              [-37.138333333716332, -9.169999993804652],
              [-37.138333332817012, -9.170833325397609],
              [-37.139166668007306, -9.170833325397609],
              [-37.13916666620861, -9.174166658964111],
              [-37.143333332267446, -9.174166658964111],
              [-37.143333334965405, -9.174999994154348],
              [-37.145000000849336, -9.174999992355708],
              [-37.144999999950016, -9.174166658964111],
              [-37.146666666733267, -9.174166658964111],
              [-37.146666666733267, -9.174999992355708],
              [-37.148333331717822, -9.174999992355708],
              [-37.148333331717822, -9.173333324673138],
              [-37.149166666008796, -9.173333325572457],
              [-37.149166664210156, -9.1724999939795],
              [-37.149999998501073, -9.17249999308018],
              [-37.149999999400393, -9.171666658789206],
              [-37.150833333691367, -9.171666658789206],
              [-37.150833333691367, -9.170833325397609],
              [-37.151666665284324, -9.170833325397609],
              [-37.151666664385004, -9.169999992905275],
              [-37.152499999575298, -9.169999992905275],
              [-37.152500000474618, -9.168333325222761],
              [-37.153333333866215, -9.168333326122081],
              [-37.153333332966895, -9.167499993629747],
              [-37.155833334041063, -9.167499992730427],
              [-37.155833332242423, -9.164999992555579],
              [-37.157499999025674, -9.164999992555579],
              [-37.157499999025674, -9.164166659163925],
              [-37.158333333316648, -9.164166659163925],
              [-37.158333333316648, -9.163333325772328],
              [-37.159999998301259, -9.163333324873008],
              [-37.159999998301259, -9.162499992380674],
              [-37.160833331692857, -9.162499992380674],
              [-37.160833332592176, -9.160833324698103],
              [-37.163333333666344, -9.16083332559748],
              [-37.163333331867705, -9.159166658814229],
              [-37.165000000449595, -9.159166658814229],
              [-37.165000000449595, -9.158333325422575],
              [-37.165833333841249, -9.158333325422575],
              [-37.16583333204261, -9.154999992755393],
              [-37.165833333841249, -9.154166658464476],
              [-37.165000000449595, -9.154166658464476],
              [-37.164999998650956, -9.153333325072822],
              [-37.164166665259359, -9.153333325972142],
              [-37.164166667057998, -9.151666659188948],
              [-37.163333333666344, -9.151666659188948],
              [-37.163333332767024, -9.150833324897974],
              [-37.162499999375427, -9.150833325797294],
              [-37.162500000274747, -9.149999992405697],
              [-37.16166666778247, -9.149999992405697],
              [-37.16166666778247, -9.149166659014043],
              [-37.160833333491496, -9.149166658114723],
              [-37.160833333491496, -9.148333325622445],
              [-37.160000000099899, -9.148333324723126],
              [-37.159999998301259, -9.147499993130111],
              [-37.159166667607565, -9.147499993130111],
              [-37.159166665808925, -9.146666659738514],
              [-37.157500000824314, -9.146666658839194],
              [-37.157500000824314, -9.145833325447541],
              [-37.156666665634077, -9.145833325447541],
              [-37.156666666533397, -9.144166658664346],
              [-37.155833332242423, -9.14416665776497],
              [-37.155833331343104, -9.141666658489441],
              [-37.155000000649466, -9.141666658489441],
              [-37.155000000649466, -9.139166660113233],
              [-37.153333332966895, -9.139166659213913],
              [-37.153333330268936, -9.136666659039065],
              [-37.152499998675978, -9.136666659938385],
              [-37.152499999575298, -9.134999993155134],
              [-37.150833333691367, -9.134999994054454],
              [-37.150833332792047, -9.13416665886416],
              [-37.149166666008796, -9.13416665796484],
              [-37.149166666908116, -9.133333325472563],
              [-37.148333332617142, -9.133333325472563],
              [-37.148333331717822, -9.132499992980229],
              [-37.146666665833891, -9.132499992980229],
              [-37.146666666733267, -9.131666658689312],
              [-37.145000000849336, -9.131666658689312],
              [-37.145000000849336, -9.129999993704701],
              [-37.143333331368126, -9.129999992805381],
              [-37.143333332267446, -9.128333326921449],
              [-37.144166666558363, -9.12833332602213],
              [-37.144166665659043, -9.127499992630533],
              [-37.143333332267446, -9.127499992630533],
              [-37.143333332267446, -9.124999992455628],
              [-37.142499998875792, -9.124999992455628],
              [-37.142500000674431, -9.124166659064031],
              [-37.139999999600263, -9.124166659064031],
              [-37.139999999600263, -9.123333325672377],
              [-37.138333331917693, -9.123333325672377],
              [-37.138333332817012, -9.122499993180099],
              [-37.137500000324735, -9.122499993180099],
              [-37.137500000324735, -9.120833325497529],
              [-37.135833331742845, -9.120833325497529],
              [-37.135833334440804, -9.119999993005251],
              [-37.13499999925051, -9.119999993005251],
              [-37.13499999925051, -9.118333326222],
              [-37.135833332642164, -9.118333326222],
              [-37.135833333541484, -9.115833326047095],
              [-37.137499998526096, -9.115833326047095],
              [-37.137499999425415, -9.11666665853943],
              [-37.140000000499583, -9.11666665853943],
              [-37.139999998700944, -9.117499993729666],
              [-37.141666666383514, -9.117499992830346],
              [-37.141666665484195, -9.119166659613597],
              [-37.144166665659043, -9.119166658714278],
              [-37.144166666558363, -9.119999993005251],
              [-37.144999999050697, -9.119999993005251],
              [-37.144999999950016, -9.119166658714278],
              [-37.146666667632587, -9.119166658714278],
              [-37.146666666733267, -9.120833325497529],
              [-37.148333333516462, -9.120833326396848],
              [-37.148333333516462, -9.121666658889126],
              [-37.150833332792047, -9.121666658889126],
              [-37.150833332792047, -9.122499993180099],
              [-37.152499998675978, -9.122499993180099],
              [-37.152499999575298, -9.121666658889126],
              [-37.153333332067575, -9.121666658889126],
              [-37.153333332966895, -9.120833325497529],
              [-37.154999998850826, -9.120833325497529],
              [-37.154999999750146, -9.119999992105932],
              [-37.16166666778247, -9.119999993005251],
              [-37.16166666688315, -9.119166658714278],
              [-37.163333332767024, -9.119166659613597],
              [-37.163333331867705, -9.119999993005251],
              [-37.164999999550275, -9.119999993005251],
              [-37.165000000449595, -9.120833325497529],
              [-37.166666666333526, -9.120833325497529],
              [-37.166666665434207, -9.121666659788445],
              [-37.169166667407751, -9.121666658889126],
              [-37.169166666508431, -9.122499993180099],
              [-37.17166666578396, -9.122499993180099],
              [-37.171666666683279, -9.123333325672377],
              [-37.172499998276237, -9.123333326571696],
              [-37.172500000974196, -9.122499993180099],
              [-37.173333331667891, -9.122499993180099],
              [-37.173333331667891, -9.123333325672377],
              [-37.174999998451142, -9.123333324773057],
              [-37.174999998451142, -9.124166659064031],
              [-37.178333332916964, -9.124166659064031],
              [-37.178333332916964, -9.12249999138146],
              [-37.179166665409241, -9.122499993180099],
              [-37.179166665409241, -9.121666658889126],
              [-37.180000000599478, -9.121666658889126],
              [-37.180000000599478, -9.120833325497529],
              [-37.180833333991131, -9.120833325497529],
              [-37.180833333091812, -9.119999993005251],
              [-37.181666667382729, -9.119999993005251],
              [-37.181666665584089, -9.116666659438749],
              [-37.182500000774382, -9.11666665853943],
              [-37.182499999875063, -9.11249999248065],
              [-37.184166666658314, -9.11249999248065],
              [-37.184166664859674, -9.109166658014828],
              [-37.18333333236734, -9.109166658914148],
              [-37.18333333146802, -9.102499992680464],
              [-37.182499999875063, -9.102499991781144],
              [-37.182499999875063, -9.101666658389547],
              [-37.181666667382729, -9.101666660188187],
              [-37.181666665584089, -9.100833325897213],
              [-37.180833331293172, -9.100833326796533],
              [-37.180833331293172, -9.099999992505616],
              [-37.181666665584089, -9.099999992505616],
              [-37.181666665584089, -9.099166658214642],
              [-37.185000000949231, -9.099166659113962],
              [-37.185000000949231, -9.098333325722365],
              [-37.185833331642925, -9.098333324823045],
              [-37.185833332542245, -9.096666658939114],
              [-37.186666667732482, -9.096666658939114],
              [-37.186666665933842, -9.093333325372612],
              [-37.187499999325439, -9.093333325372612],
              [-37.1874999975268, -9.092499991981015],
              [-37.186666666833162, -9.092499992880335],
              [-37.186666665933842, -9.089166658414513],
              [-37.187499999325439, -9.089166658414513],
              [-37.187499999325439, -9.086666659138984],
              [-37.185000000049911, -9.086666659138984],
              [-37.184999998251271, -9.083333325572482],
              [-37.185833333441565, -9.083333325572482],
              [-37.185833333441565, -9.081666659688551],
              [-37.186666666833162, -9.081666658789231],
              [-37.186666667732482, -9.079166658614383],
              [-37.188333331817773, -9.079166658614383],
              [-37.188333332717093, -9.078333326122049],
              [-37.18916666520937, -9.078333326122049],
              [-37.18916666520937, -9.076666658439478],
              [-37.189999999500344, -9.076666658439478],
              [-37.189999998601024, -9.075833325947201],
              [-37.190833333791261, -9.075833325947201],
              [-37.190833332891941, -9.07416665916395],
              [-37.191666665384275, -9.07416665916395],
              [-37.191666666283595, -9.072499992380699],
              [-37.192499999675192, -9.072499994179339],
              [-37.192500001473832, -9.071666658989102],
              [-37.193333333066846, -9.071666658989102],
              [-37.19333333036883, -9.070833325597448],
              [-37.192499999675192, -9.070833325597448],
              [-37.192499999675192, -9.067499992930266],
              [-37.190000000399664, -9.067499992930266],
              [-37.189999999500344, -9.064166657565124],
              [-37.190833331992621, -9.064166657565124],
              [-37.190833331992621, -9.058333325622414],
              [-37.191666666283595, -9.058333325622414],
              [-37.191666666283595, -9.057499993130136],
              [-37.192499999675192, -9.057499993130136],
              [-37.192499997876553, -9.055833325447566],
              [-37.191666665384275, -9.055833325447566],
              [-37.191666666283595, -9.054999992955288],
              [-37.190833333791261, -9.054999992955288],
              [-37.190833331992621, -9.054166658664315],
              [-37.190000000399664, -9.054166658664315],
              [-37.190000000399664, -9.052499992780383],
              [-37.18916666700801, -9.052499992780383],
              [-37.18916666520937, -9.049999992605535],
              [-37.188333331817773, -9.049999991706215],
              [-37.188333331817773, -9.049166659213881],
              [-37.18916666520937, -9.049166659213881],
              [-37.18916666790733, -9.045833325647436],
              [-37.187499999325439, -9.045833325647436],
              [-37.187499998426119, -9.044166658864185],
              [-37.188333331817773, -9.044166658864185],
              [-37.188333330918454, -9.043333325472531],
              [-37.18916666520937, -9.043333324573211],
              [-37.18916666520937, -9.039166658514432],
              [-37.187499999325439, -9.039166658514432],
              [-37.187500000224759, -9.040833326197003],
              [-37.186666665933842, -9.040833326197003],
              [-37.186666666833162, -9.04166665778996],
              [-37.184999998251271, -9.04166665868928],
              [-37.184999998251271, -9.040833326197003],
              [-37.180833331293172, -9.040833326197003],
              [-37.180833331293172, -9.04166665868928],
              [-37.179999998800838, -9.04166665868928],
              [-37.180000000599478, -9.042499992980254],
              [-37.178333332916964, -9.042499992980254],
              [-37.178333332017587, -9.04166665868928],
              [-37.174999999350462, -9.04166665868928],
              [-37.174999998451142, -9.040833326197003],
              [-37.170833333291682, -9.040833326197003],
              [-37.170833331492986, -9.039999992805349],
              [-37.168333332217458, -9.039999992805349],
              [-37.168333334915417, -9.039166658514432],
              [-37.166666667232846, -9.039166658514432],
              [-37.166666666333526, -9.039999992805349],
              [-37.165833332941929, -9.039999993704726],
              [-37.165833332941929, -9.040833326197003],
              [-37.165000000449595, -9.040833324398363],
              [-37.165000000449595, -9.04166665868928],
              [-37.164166667057998, -9.04166665868928],
              [-37.164166665259359, -9.042499992980254],
              [-37.162499999375427, -9.042499992980254],
              [-37.162499999375427, -9.043333325472531],
              [-37.16166666688315, -9.043333325472531],
              [-37.16166666778247, -9.042499992980254],
              [-37.158333332417328, -9.042499992080934],
              [-37.158333331518008, -9.0416666595886],
              [-37.157499999025674, -9.04166665778996],
              [-37.157499999924994, -9.042499992980254],
              [-37.151666665284324, -9.042499992980254],
              [-37.151666667082964, -9.043333325472531],
              [-37.150833331892727, -9.043333325472531],
              [-37.150833332792047, -9.044166658864185],
              [-37.149999998501073, -9.044166658864185],
              [-37.149999999400393, -9.045833325647436],
              [-37.149166666008796, -9.045833324748116],
              [-37.149166666008796, -9.042499992980254],
              [-37.148333332617142, -9.042499994778893],
              [-37.148333331717822, -9.046666659039033],
              [-37.147499998326225, -9.046666659938353],
              [-37.147500001024184, -9.047499992430687],
              [-37.146666666733267, -9.047499992430687],
              [-37.146666666733267, -9.050833325997132],
              [-37.143333332267446, -9.050833325997132],
              [-37.143333334066085, -9.051666658489466],
              [-37.142499999775112, -9.051666658489466],
              [-37.142499998875792, -9.052499992780383],
              [-37.13916666530929, -9.052499992780383],
              [-37.13916666620861, -9.047499991531311],
              [-37.139999997801624, -9.047499992430687],
              [-37.139999999600263, -9.046666659039033],
              [-37.140833332991861, -9.046666659039033],
              [-37.140833332991861, -9.045833325647436],
              [-37.142499998875792, -9.045833325647436],
              [-37.142499998875792, -9.044999993155102],
              [-37.143333334066085, -9.044999993155102],
              [-37.143333332267446, -9.044166658864185],
              [-37.144166667457682, -9.044166658864185],
              [-37.144166666558363, -9.043333325472531],
              [-37.139999998700944, -9.043333325472531],
              [-37.139999998700944, -9.042499992980254],
              [-37.138333331917693, -9.042499992980254],
              [-37.138333332817012, -9.0416666595886],
              [-37.137499999425415, -9.0416666595886],
              [-37.136666666933081, -9.04166665778996],
              [-37.136666666933081, -9.042499992080934],
              [-37.135833333541484, -9.042499992980254],
              [-37.135833333541484, -9.04166665868928],
              [-37.132500000874302, -9.04166665868928],
              [-37.132500000874302, -9.040833326197003],
              [-37.130833332292411, -9.040833326197003],
              [-37.130833332292411, -9.039999992805349],
              [-37.128333334815522, -9.039999992805349],
              [-37.128333333916203, -9.039166658514432],
              [-37.125000000349701, -9.039166658514432],
              [-37.124999997651742, -9.040833326197003],
              [-37.124166666058727, -9.040833326197003],
              [-37.124166666958047, -9.04166665868928],
              [-37.12333333356645, -9.04166665868928],
              [-37.12333333266713, -9.042499992980254],
              [-37.122500001074172, -9.042499992980254],
              [-37.122499998376156, -9.04166665868928],
              [-37.121666665883879, -9.04166665868928],
              [-37.121666666783199, -9.042499992980254],
              [-37.119166665709031, -9.042499992980254],
              [-37.11916666750767, -9.043333325472531],
              [-37.118333333216697, -9.043333325472531],
              [-37.118333335015336, -9.044999993155102],
              [-37.115833333041849, -9.044999993155102],
              [-37.115833333041849, -9.045833324748116],
              [-37.112499998576027, -9.045833325647436],
              [-37.112499998576027, -9.046666658139713],
              [-37.111666667882389, -9.046666659039033],
              [-37.111666666983069, -9.047499992430687],
              [-37.109999998401179, -9.047499994229327],
              [-37.109999998401179, -9.046666659039033],
              [-37.107499998226274, -9.046666658139713],
              [-37.107500000924233, -9.047499992430687],
              [-37.106666667532636, -9.047499994229327],
              [-37.106666668431956, -9.046666659039033],
              [-37.105833332342343, -9.046666659938353],
              [-37.104999998950746, -9.046666659938353],
              [-37.105000000749385, -9.047499992430687],
              [-37.103333334865454, -9.047499992430687],
              [-37.103333333966134, -9.046666659039033],
              [-37.098333333616438, -9.046666659039033],
              [-37.098333332717061, -9.045833324748116],
              [-37.097499999325464, -9.045833325647436],
              [-37.097500002023423, -9.046666659938353],
              [-37.095833331642893, -9.046666659039033],
              [-37.095833333441533, -9.047499992430687],
              [-37.092499998975711, -9.047499992430687],
              [-37.092500000774351, -9.048333324922964],
              [-37.09083333129314, -9.048333325822284],
              [-37.09083333129314, -9.049166659213881],
              [-37.089166667207905, -9.049166659213881],
              [-37.089166665409266, -9.049999992605535],
              [-37.088333332916932, -9.049999992605535],
              [-37.088333332017612, -9.053333326172037],
              [-37.087499998626015, -9.053333326172037],
              [-37.087499998626015, -9.054166658664315],
              [-37.086666667033001, -9.054166658664315],
              [-37.086666667033001, -9.058333325622414],
              [-37.085833330943444, -9.058333325622414],
              [-37.085833332742084, -9.059166659014068],
              [-37.08499999935043, -9.059166659014068],
              [-37.08499999845111, -9.059999992405665],
              [-37.084166666858152, -9.059999992405665],
              [-37.084166665958833, -9.061666659188916],
              [-37.083333333466499, -9.061666659188916],
              [-37.083333332567179, -9.06249999168125],
              [-37.082500000074901, -9.06249999168125],
              [-37.082499998276262, -9.063333325972167],
              [-37.081666666683304, -9.063333326871486],
              [-37.081666666683304, -9.06249999258057],
              [-37.079999999900053, -9.06249999258057],
              [-37.078333334016122, -9.06249999168125],
              [-37.078333331318163, -9.061666659188916],
              [-37.077499999725148, -9.061666659188916],
              [-37.077499998825829, -9.059166659014068],
              [-37.076666665434232, -9.059166659014068],
              [-37.076666664534912, -9.058333325622414],
              [-37.075833334740537, -9.058333325622414],
              [-37.075833333841217, -9.057499993130136],
              [-37.074999998650981, -9.057499993130136],
              [-37.074999998650981, -9.054999992955288],
              [-37.075833332042578, -9.054999992955288],
              [-37.075833332941897, -9.054166658664315],
              [-37.076666665434232, -9.054166658664315],
              [-37.076666665434232, -9.053333326172037],
              [-37.078333332217483, -9.053333326172037],
              [-37.078333331318163, -9.052499992780383],
              [-37.079166666508399, -9.052499992780383],
              [-37.07916666560908, -9.051666657590147],
              [-37.079999999000734, -9.051666657590147],
              [-37.079999999900053, -9.048333325822284],
              [-37.081666667582624, -9.048333326721604],
              [-37.081666667582624, -9.046666659039033],
              [-37.082499998276262, -9.046666658139713],
              [-37.082500000974221, -9.044999993155102],
              [-37.083333331667859, -9.044999993155102],
              [-37.083333330768539, -9.040833326197003],
              [-37.084166667757472, -9.040833326197003],
              [-37.084166666858152, -9.039166658514432],
              [-37.08499999845111, -9.039166658514432],
              [-37.08499999935043, -9.038333326022155],
              [-37.085833333641403, -9.038333326022155],
              [-37.085833331842764, -9.036666659238904],
              [-37.087499998626015, -9.036666659238904],
              [-37.087499998626015, -9.033333325672402],
              [-37.088333332017612, -9.033333325672402],
              [-37.088333332017612, -9.031666658889151],
              [-37.089166666308586, -9.031666658889151],
              [-37.089166667207905, -9.030833325497554],
              [-37.089999999700183, -9.030833325497554],
              [-37.090000000599503, -9.0299999921059],
              [-37.093333332367365, -9.029999993005219],
              [-37.093333332367365, -9.029166658714303],
              [-37.095000000049936, -9.029166658714303],
              [-37.095000000949256, -9.028333326221968],
              [-37.095833332542213, -9.028333326221968],
              [-37.095833332542213, -9.027499991931052],
              [-37.096666667732507, -9.027499992830371],
              [-37.096666665933867, -9.026666658539398],
              [-37.097499998426144, -9.026666658539398],
              [-37.097499998426144, -9.02416665836455],
              [-37.098333332717061, -9.02416665836455],
              [-37.098333332717061, -9.023333325872272],
              [-37.099999998600993, -9.023333329469551],
              [-37.099999999500312, -9.022499993379938],
              [-37.100833331992646, -9.022499992480618],
              [-37.100833331992646, -9.021666659089021],
              [-37.101666665384244, -9.021666659089021],
              [-37.101666665384244, -9.019166658914116],
              [-37.102499998775897, -9.019166658914116],
              [-37.102500000574537, -9.0183333246232],
              [-37.103333333066814, -9.018333326421839],
              [-37.103333331268175, -9.016666658739268],
              [-37.104166665559148, -9.016666658739268],
              [-37.104166666458468, -9.015833325347614],
              [-37.104999998950746, -9.015833325347614],
              [-37.104999998950746, -9.014999992855337],
              [-37.108333332517248, -9.014999992855337],
              [-37.108333332517248, -9.010833325897238],
              [-37.107500000024913, -9.010833324997918],
              [-37.107500000024913, -9.008333325722333],
              [-37.105833331443023, -9.008333325722333],
              [-37.105833331443023, -9.009166659113987],
              [-37.104166667357788, -9.009166659113987],
              [-37.104166665559148, -9.009999992505584],
              [-37.099999999500312, -9.009999992505584],
              [-37.099999998600993, -9.010833325897238],
              [-37.097499998426144, -9.010833325897238],
              [-37.097500000224784, -9.011666658389515],
              [-37.095000000049936, -9.011666658389515],
              [-37.095000000049936, -9.012499992680489],
              [-37.094166666658282, -9.012499992680489],
              [-37.094166666658282, -9.013333326072086],
              [-37.092500000774351, -9.013333326072086],
              [-37.092499999875031, -9.012499992680489],
              [-37.091666665584114, -9.012499992680489],
              [-37.091666665584114, -9.013333326072086],
              [-37.09083333309178, -9.013333326072086],
              [-37.0908333339911, -9.012499992680489],
              [-37.085833331842764, -9.012499992680489],
              [-37.085833332742084, -9.013333326072086],
              [-37.08499999935043, -9.013333325172766],
              [-37.08500000024975, -9.01416665856442],
              [-37.084166666858152, -9.01416665856442],
              [-37.084166666858152, -9.014999992855337],
              [-37.083333331667859, -9.014999993754657],
              [-37.083333333466499, -9.017499993030185],
              [-37.082499998276262, -9.017499993030185],
              [-37.082499998276262, -9.018333325522519],
              [-37.081666666683304, -9.018333325522519],
              [-37.081666666683304, -9.019166658914116],
              [-37.082499998276262, -9.019166658014797],
              [-37.082499997376942, -9.01999999320509],
              [-37.081666668481944, -9.01999999320509],
              [-37.081666665783928, -9.021666659089021],
              [-37.08083333329165, -9.021666659089021],
              [-37.080833330593691, -9.022499992480618],
              [-37.079999999000734, -9.022499992480618],
              [-37.079999999000734, -9.023333325872272],
              [-37.079166667407719, -9.023333325872272],
              [-37.07916666560908, -9.02416665836455],
              [-37.078333332217483, -9.02416665836455],
              [-37.078333331318163, -9.024999992655466],
              [-37.076666666333551, -9.024999992655466],
              [-37.076666665434232, -9.026666658539398],
              [-37.07500000044962, -9.026666658539398],
              [-37.074999998650981, -9.027499992830371],
              [-37.074166667058023, -9.027499992830371],
              [-37.074166665259327, -9.028333324423329],
              [-37.07333333186773, -9.028333326221968],
              [-37.07333333186773, -9.029999993005219],
              [-37.071666665084479, -9.029999993005219],
              [-37.071666665983798, -9.030833325497554],
              [-37.070833334390841, -9.030833325497554],
              [-37.070833332592201, -9.034166659063999],
              [-37.06916666760759, -9.034166659063999],
              [-37.06916666760759, -9.039166658514432],
              [-37.068333332417296, -9.039166658514432],
              [-37.068333331517977, -9.039999992805349],
              [-37.067500000824339, -9.039999992805349],
              [-37.067499999025699, -9.040833326197003],
              [-37.066666665634045, -9.040833326197003],
              [-37.066666665634045, -9.04166665868928],
              [-37.065833332242448, -9.0416666595886],
              [-37.065833332242448, -9.042499992980254],
              [-37.0633333320676, -9.042499992980254],
              [-37.0633333320676, -9.043333326371851],
              [-37.062499998675946, -9.043333325472531],
              [-37.062500000474586, -9.044166658864185],
              [-37.061666667982308, -9.044166658864185],
              [-37.061666665284349, -9.044999993155102],
              [-37.060833331892695, -9.044999994054422],
              [-37.059999999400418, -9.044999993155102],
              [-37.059999998501098, -9.044166658864185],
              [-37.057499997426874, -9.044166658864185],
              [-37.057500001024209, -9.043333324573211],
              [-37.056666665833916, -9.043333325472531],
              [-37.056666665833916, -9.042499992980254],
              [-37.053333332267414, -9.042499992980254],
              [-37.053333334066053, -9.04166665868928],
              [-37.052500000674456, -9.04166665778996],
              [-37.052499998875817, -9.040833326197003],
              [-37.050833332991886, -9.040833326197003],
              [-37.050833332991886, -9.039999991906029],
              [-37.049999999600232, -9.039999992805349],
              [-37.050000000499608, -9.038333326921475],
              [-37.049166666208635, -9.038333326022155],
              [-37.049166665309315, -9.036666659238904],
              [-37.047499999425384, -9.036666659238904],
              [-37.047500001224023, -9.034999992455653],
              [-37.046666666933106, -9.034999992455653],
              [-37.046666666933106, -9.031666658889151],
              [-37.047499998526064, -9.031666658889151],
              [-37.047500001224023, -9.030833325497554],
              [-37.048333331018341, -9.030833325497554],
              [-37.048333333716357, -9.0299999921059],
              [-37.049166665309315, -9.029999993005219],
              [-37.049166666208635, -9.028333326221968],
              [-37.048333332817037, -9.028333326221968],
              [-37.048333331917718, -9.027499992830371],
              [-37.047499999425384, -9.027499992830371],
              [-37.047499999425384, -9.026666658539398],
              [-37.045833332642133, -9.026666658539398],
              [-37.045833331742813, -9.02583332604712],
              [-37.045000001049175, -9.02583332604712],
              [-37.044999999250535, -9.024999992655466],
              [-37.044166665858882, -9.024999992655466],
              [-37.043333332467284, -9.024999992655466],
              [-37.043333332467284, -9.02583332604712],
              [-37.042499999075631, -9.02583332604712],
              [-37.042499999075631, -9.026666658539398],
              [-37.041666665684033, -9.026666658539398],
              [-37.041666666583353, -9.027499992830371],
              [-37.039999999800102, -9.027499992830371],
              [-37.039999999800102, -9.028333326221968],
              [-37.039166665509185, -9.028333326221968],
              [-37.039166667307825, -9.029166658714303],
              [-37.038333331218212, -9.029166657814983],
              [-37.038333333016851, -9.029999993005219],
              [-37.035833332842003, -9.029999993904539],
              [-37.035833333741323, -9.030833325497554],
              [-37.034999998551029, -9.030833324598177],
              [-37.035000000349669, -9.031666658889151],
              [-37.034166666058752, -9.031666658889151],
              [-37.034166666058752, -9.032499993180068],
              [-37.033333333566475, -9.032499993180068],
              [-37.033333332667098, -9.033333325672402],
              [-37.032499999275501, -9.033333325672402],
              [-37.032499999275501, -9.034166659963319],
              [-37.031666668581863, -9.034166658164679],
              [-37.031666665883904, -9.034999992455653],
              [-37.03083333339157, -9.034999992455653],
              [-37.03083333339157, -9.03583332674657],
              [-37.028333331418082, -9.03583332584725],
              [-37.028333332317402, -9.036666659238904],
              [-37.027499999825068, -9.036666660138224],
              [-37.027500000724388, -9.038333325122835],
              [-37.026666666433471, -9.038333325122835],
              [-37.026666666433471, -9.039166658514432],
              [-37.025833330343858, -9.039166658514432],
              [-37.025833331243177, -9.039999992805349],
              [-37.0249999987509, -9.039999991906029],
              [-37.02499999965022, -9.044166658864185],
              [-37.024166665359303, -9.044166657964865],
              [-37.024166665359303, -9.044999993155102],
              [-37.023333332866969, -9.044999994054422],
              [-37.023333331967649, -9.045833324748116],
              [-37.022499998576052, -9.045833325647436],
              [-37.022499999475372, -9.046666659039033],
              [-37.021666666083718, -9.046666659039033],
              [-37.021666667882357, -9.047499992430687],
              [-37.020833331792801, -9.047499991531311],
              [-37.020833331792801, -9.048333325822284],
              [-37.020000001099106, -9.048333325822284],
              [-37.019999998401147, -9.049166659213881],
              [-37.018333331617896, -9.049166657415242],
              [-37.018333331617896, -9.049999992605535],
              [-37.016666667532661, -9.049999993504855],
              [-37.016666665734022, -9.050833325997132],
              [-37.015833332342368, -9.050833325997132],
              [-37.015833331443048, -9.051666658489466],
              [-37.014999998950771, -9.051666658489466],
              [-37.01500000074941, -9.052499992780383],
              [-37.014166665559117, -9.052499992780383],
              [-37.014166665559117, -9.053333326172037],
              [-37.012499998775866, -9.053333326172037],
              [-37.012499999675185, -9.054166658664315],
              [-37.011666665384269, -9.054166658664315],
              [-37.011666665384269, -9.054999992955288],
              [-37.010833331992615, -9.054999992955288],
              [-37.010833331992615, -9.055833325447566],
              [-37.009999998601018, -9.055833325447566],
              [-37.009999999500337, -9.056666658839163],
              [-37.008333332717086, -9.056666658839163],
              [-37.008333332717086, -9.057499993130136],
              [-37.005833332542238, -9.057499993130136],
              [-37.005833333441558, -9.058333325622414],
              [-37.005000000049904, -9.058333325622414],
              [-37.005000000949224, -9.057499993130136],
              [-37.004166666658307, -9.057499994029456],
              [-37.004166665758987, -9.054999992955288],
              [-37.002499998975736, -9.054999992955288],
              [-37.002499999875056, -9.053333326172037],
              [-37.001666667382779, -9.053333326172037],
              [-37.001666666483402, -9.049166659213881],
              [-37.000833333991125, -9.049166659213881],
              [-37.000833331293165, -9.047499992430687],
              [-37.001666666483402, -9.047499992430687],
              [-37.001666665584082, -9.046666659039033],
              [-37.002499999875056, -9.046666659039033],
              [-37.002500000774376, -9.044999993155102],
              [-37.000833333991125, -9.044999993155102],
              [-37.000833333091805, -9.043333325472531],
              [-36.999999998800888, -9.043333325472531],
              [-36.999999998800888, -9.038333326022155],
              [-36.999166665409234, -9.038333326022155],
              [-36.999166665409234, -9.036666659238904],
              [-36.999999999700208, -9.036666659238904],
              [-37.000000000599528, -9.034999993354973],
              [-36.999166667207874, -9.034999992455653],
              [-36.999166667207874, -9.033333325672402],
              [-36.997499999525303, -9.033333325672402],
              [-36.997500000424623, -9.031666658889151],
              [-36.996666667033026, -9.031666658889151],
              [-36.996666667033026, -9.029166658714303],
              [-36.995833331842732, -9.029166658714303],
              [-36.995833332742052, -9.022499992480618],
              [-36.996666667033026, -9.022499992480618],
              [-36.996666665234386, -9.01999999320509],
              [-36.997499998625983, -9.01999999320509],
              [-36.997499999525303, -9.019166658914116],
              [-36.996666667033026, -9.019166658914116],
              [-36.996666667932345, -9.018333325522519],
              [-36.995833331842732, -9.018333325522519],
              [-36.995833331842732, -9.017499993030185],
              [-36.994999999350455, -9.017499993030185],
              [-36.994999998451135, -9.013333326072086],
              [-36.994166666858121, -9.013333326072086],
              [-36.994166666858121, -9.012499992680489],
              [-36.993333331667884, -9.012499992680489],
              [-36.993333333466524, -9.011666658389515],
              [-36.99249999827623, -9.011666658389515],
              [-36.99249999827623, -9.010833325897238],
              [-36.991666665783953, -9.010833325897238],
              [-36.991666667582592, -9.009999992505584],
              [-36.985833332042603, -9.009999992505584],
              [-36.985833333841242, -9.009166659113987],
              [-36.984166667957311, -9.009166659113987],
              [-36.984166667957311, -9.008333326621653],
              [-36.983333332767074, -9.008333325722333],
              [-36.983333333666394, -9.007499993230056],
              [-36.98249999937542, -9.007499994129375],
              [-36.982499998476101, -9.006666658939139],
              [-36.981666666883143, -9.006666659838459],
              [-36.981666665983823, -9.004999993055208],
              [-36.980833332592169, -9.004999993055208],
              [-36.980833332592169, -9.002499992880303],
              [-36.979999998301253, -9.002499992880303],
              [-36.979999998301253, -9.001666658589386],
              [-36.979166665808918, -9.001666658589386],
              [-36.979166666708238, -9.000833326996371],
              [-36.978333332417321, -9.000833325197732],
              [-36.978333331518002, -8.999999992705455],
              [-36.977499999924987, -8.999999991806135],
              [-36.977499999924987, -8.999166660213177],
              [-36.978333330618682, -8.999166658414481],
              [-36.978333332417321, -8.998333325922204],
              [-36.978333332417321, -8.995833326646675],
              [-36.979166665808918, -8.995833325747356],
              [-36.979166666708238, -8.994999992355702],
              [-36.980000000099892, -8.994999992355702],
              [-36.980000000099892, -8.994166658964104],
              [-36.98083333169285, -8.994166658964104],
              [-36.98083333169285, -8.993333325572451],
              [-36.982499998476101, -8.993333324673131],
              [-36.982499998476101, -8.992499992180853],
              [-36.984166667057991, -8.992499993080173],
              [-36.984166667957311, -8.990833324498283],
              [-36.984999998650949, -8.990833327196242],
              [-36.984999998650949, -8.989999992905325],
              [-36.9866666654342, -8.989999992905325],
              [-36.98666666633352, -8.989166658614351],
              [-36.987500000624493, -8.989166659513671],
              [-36.987500000624493, -8.98749999273042],
              [-36.988333331318131, -8.98749999273042],
              [-36.988333332217451, -8.984999992555572],
              [-36.989166665609105, -8.984999992555572],
              [-36.989166667407744, -8.984166659163918],
              [-36.989999999900022, -8.984166657365279],
              [-36.989999999000702, -8.983333325772321],
              [-36.993333332567204, -8.983333325772321],
              [-36.993333333466524, -8.982499994179364],
              [-36.996666665234386, -8.982499991481347],
              [-36.996666667033026, -8.979166658814222],
              [-36.997499999525303, -8.979166657914902],
              [-36.997499999525303, -8.978333325422568],
              [-36.998333332916957, -8.978333325422568],
              [-36.998333332916957, -8.97583332614704],
              [-36.999166667207874, -8.97583332614704],
              [-36.999166665409234, -8.974999992755443],
              [-36.999999998800888, -8.974999992755443],
              [-36.999999998800888, -8.974166658464469],
              [-37.000833333991125, -8.974166658464469],
              [-37.000833331293165, -8.972499992580538],
              [-37.001666667382779, -8.972499992580538],
              [-37.001666667382779, -8.971666659188941],
              [-37.002499999875056, -8.971666659188941],
              [-37.002500000774376, -8.970833326696606],
              [-37.003333331468014, -8.970833325797287],
              [-37.003333331468014, -8.969166658114716],
              [-37.004166666658307, -8.969166659913355],
              [-37.004166665758987, -8.967499993130104],
              [-37.005833331642918, -8.967499993130104],
              [-37.005833332542238, -8.965833325447591],
              [-37.005000000949224, -8.96583332634691],
              [-37.004999998251265, -8.964999992955256],
              [-37.005833331642918, -8.964999992955256],
              [-37.005833332542238, -8.96416665866434],
              [-37.005000000049904, -8.96416665866434],
              [-37.005000000949224, -8.961666658489435],
              [-37.005833333441558, -8.961666658489435],
              [-37.005833330743599, -8.959166659213906],
              [-37.006666667732475, -8.959166659213906],
              [-37.006666666833155, -8.958333325822309],
              [-37.007499999325489, -8.958333324922933],
              [-37.007499999325489, -8.957499992430655],
              [-37.011666666283588, -8.957499992430655],
              [-37.011666668981547, -8.959166659213906],
              [-37.012499998775866, -8.959166657415267],
              [-37.012500000574505, -8.959999992605503],
              [-37.013333333966159, -8.959999992605503],
              [-37.013333333066839, -8.960833325997157],
              [-37.014166666458436, -8.960833328695117],
              [-37.016666667532661, -8.960833326896477],
              [-37.016666666633341, -8.961666658489435],
              [-37.017499998226299, -8.961666658489435],
              [-37.017500000024938, -8.960833325997157],
              [-37.018333331617896, -8.960833325097838],
              [-37.018333332517216, -8.959999992605503],
              [-37.021666666083718, -8.959999991706184],
              [-37.021666666083718, -8.956666659039058],
              [-37.022499998576052, -8.956666658139739],
              [-37.022499998576052, -8.954999994054447],
              [-37.023333333766288, -8.954999993155127],
              [-37.023333332866969, -8.954166658864153],
              [-37.024166665359303, -8.954166658864153],
              [-37.024166668057262, -8.954999993155127],
              [-37.0249999987509, -8.954999993155127],
              [-37.025000000549539, -8.954166658864153],
              [-37.029166667507639, -8.954166657964834],
              [-37.029166666608319, -8.953333326371876],
              [-37.03083333159293, -8.953333325472556],
              [-37.03083333339157, -8.952499992980222],
              [-37.029999999100653, -8.952499992980222],
              [-37.030000000899292, -8.951666658689305],
              [-37.032499999275501, -8.951666658689305],
              [-37.032499998376181, -8.950833326197028],
              [-37.034166666958072, -8.950833326197028],
              [-37.034166666058752, -8.949999992805374],
              [-37.035000001248989, -8.949999992805374],
              [-37.034999998551029, -8.949166658514457],
              [-37.035833332842003, -8.949166657615137],
              [-37.035833332842003, -8.948333326022123],
              [-37.037499999625254, -8.948333326022123],
              [-37.037499998725934, -8.947499991731206],
              [-37.038333331218212, -8.947499992630526],
              [-37.038333331218212, -8.946666660138192],
              [-37.039999999800102, -8.946666659238872],
              [-37.040000000699422, -8.947499992630526],
              [-37.04083333139306, -8.947499992630526],
              [-37.040833334990396, -8.946666659238872],
              [-37.04249999997495, -8.946666660138192],
              [-37.04249999997495, -8.945833325847275],
              [-37.044166667657521, -8.945833324947955],
              [-37.044166665858882, -8.944999992455621],
              [-37.045833331742813, -8.944999992455621],
              [-37.045833333541452, -8.941666658889176],
              [-37.046666666933106, -8.941666658889176],
              [-37.046666666033786, -8.940833325497522],
              [-37.049999998700912, -8.940833325497522],
              [-37.049999998700912, -8.935833326047089],
              [-37.050833332092566, -8.935833325147769],
              [-37.050833332991886, -8.934999993554811],
              [-37.051666665484163, -8.934999992655491],
              [-37.051666665484163, -8.934166658364518],
              [-37.051666665484163, -8.93333332587224],
              [-37.052499999775137, -8.93333332587224],
              [-37.052499998875817, -8.932499992480643],
              [-37.053333331368094, -8.932499992480643],
              [-37.053333331368094, -8.931666659088989],
              [-37.054999999050665, -8.931666659088989],
              [-37.054999999050665, -8.930833325697392],
              [-37.055833332442319, -8.930833325697392],
              [-37.055833333341639, -8.929999993205058],
              [-37.056666667632555, -8.929999993205058],
              [-37.056666665833916, -8.929166658914141],
              [-37.055833331542999, -8.929166658914141],
              [-37.055833330643679, -8.928333325522487],
              [-37.056666666733236, -8.928333325522487],
              [-37.056666667632555, -8.926666658739236],
              [-37.055833332442319, -8.926666658739236],
              [-37.055833332442319, -8.922499992680457],
              [-37.061666667082989, -8.922499992680457],
              [-37.061666665284349, -8.92166665838954],
              [-37.060833333691335, -8.92166665838954],
              [-37.060833333691335, -8.916666658939107],
              [-37.059999999400418, -8.916666658939107],
              [-37.059999998501098, -8.91583332554751],
              [-37.059166666908084, -8.91583332464819],
              [-37.059166666008764, -8.914999993055176],
              [-37.058333333516487, -8.914999993055176],
              [-37.058333332617167, -8.914166659663579],
              [-37.057499999225513, -8.914166658764259],
              [-37.057500001024209, -8.912499992880328],
              [-37.056666667632555, -8.912499992880328],
              [-37.056666665833916, -8.911666658589354],
              [-37.054999999050665, -8.911666658589354],
              [-37.052499998875817, -8.911666658589354],
              [-37.052499998875817, -8.910833325197757],
              [-37.053333334066053, -8.910833326097077],
              [-37.053333332267414, -8.909999993604799],
              [-37.052499998875817, -8.909999991806103],
              [-37.052500000674456, -8.909166660213145],
              [-37.053333332267414, -8.909166658414506],
              [-37.053333334965373, -8.908333325922229],
              [-37.052499998875817, -8.908333325922229],
              [-37.052500000674456, -8.906666659138978],
              [-37.054999999050665, -8.906666659138978],
              [-37.055000001748624, -8.905833325747324],
              [-37.056666665833916, -8.905833325747324],
              [-37.056666665833916, -8.901666658789225],
              [-37.055833331542999, -8.901666658789225],
              [-37.055833332442319, -8.899166658614377],
              [-37.054999999949985, -8.899166658614377],
              [-37.054999999050665, -8.898333326122042],
              [-37.054166665659068, -8.898333327021362],
              [-37.054166666558388, -8.895833325047874],
              [-37.053333332267414, -8.895833325047874],
              [-37.053333332267414, -8.894166659163943],
              [-37.054999999050665, -8.894166659163943],
              [-37.055000000849304, -8.893333325772346],
              [-37.055833330643679, -8.893333325772346],
              [-37.055833331542999, -8.891666658989095],
              [-37.054166664759748, -8.891666658989095],
              [-37.054166664759748, -8.890833325597441],
              [-37.053333332267414, -8.890833325597441],
              [-37.053333331368094, -8.889999993105164],
              [-37.054166665659068, -8.889999993105164],
              [-37.054166666558388, -8.888333325422593],
              [-37.053333331368094, -8.888333325422593],
              [-37.053333332267414, -8.886666658639342],
              [-37.054166666558388, -8.886666658639342],
              [-37.054166667457707, -8.884166657565174],
              [-37.055833332442319, -8.884166658464494],
              [-37.055833332442319, -8.88333332597216],
              [-37.056666666733236, -8.88333332597216],
              [-37.056666665833916, -8.882499991681243],
              [-37.057499998326193, -8.882499992580563],
              [-37.057499998326193, -8.880833325797312],
              [-37.059166666008764, -8.880833324897992],
              [-37.059166666008764, -8.879999992405658],
              [-37.059999999400418, -8.879999992405658],
              [-37.059999998501098, -8.880833325797312],
              [-37.061666665284349, -8.880833325797312],
              [-37.061666665284349, -8.882499993479883],
              [-37.0633333320676, -8.882499992580563],
              [-37.06333333386624, -8.88333332597216],
              [-37.064166666358517, -8.88333332597216],
              [-37.064166666358517, -8.882499992580563],
              [-37.065833332242448, -8.882499992580563],
              [-37.065833331343129, -8.88333332507284],
              [-37.068333333316616, -8.88333332597216],
              [-37.068333332417296, -8.884166658464494],
              [-37.06916666670827, -8.884166658464494],
              [-37.06916666670827, -8.886666657740022],
              [-37.070000000099867, -8.886666659538662],
              [-37.070000000999187, -8.887499992930259],
              [-37.070833333491521, -8.887499992930259],
              [-37.070833332592201, -8.888333324523273],
              [-37.072499999375452, -8.888333325422593],
              [-37.072499999375452, -8.891666658989095],
              [-37.07333333096841, -8.891666658989095],
              [-37.073333333666369, -8.893333325772346],
              [-37.074166667058023, -8.893333325772346],
              [-37.074166664360007, -8.894166659163943],
              [-37.076666667232871, -8.894166659163943],
              [-37.076666666333551, -8.893333325772346],
              [-37.081666666683304, -8.893333325772346],
              [-37.081666667582624, -8.89499999255554],
              [-37.085833331842764, -8.89499999255554],
              [-37.085833333641403, -8.894166659163943],
              [-37.086666667033001, -8.894166660063263],
              [-37.086666667033001, -8.892499992380692],
              [-37.087500000424654, -8.892499992380692],
              [-37.087500000424654, -8.890833325597441],
              [-37.088333332916932, -8.890833326496761],
              [-37.088333332017612, -8.886666658639342],
              [-37.089166665409266, -8.886666658639342],
              [-37.089166665409266, -8.880833326696632],
              [-37.089999998800863, -8.880833325797312],
              [-37.089999998800863, -8.879166659014061],
              [-37.09083333129314, -8.879166659014061],
              [-37.0908333339911, -8.874999992955281],
              [-37.091666665584114, -8.874999993854601],
              [-37.091666665584114, -8.872499992780376],
              [-37.092499998975711, -8.872499993679696],
              [-37.092499999875031, -8.870833325997125],
              [-37.093333331468045, -8.870833325997125],
              [-37.093333331468045, -8.869166659213931],
              [-37.094166665758962, -8.869166659213931],
              [-37.094166665758962, -8.868333325822277],
              [-37.095000000049936, -8.868333325822277],
              [-37.095000000949256, -8.865833325647429],
              [-37.096666665933867, -8.865833325647429],
              [-37.096666665933867, -8.864166659763498],
              [-37.097500000224784, -8.864166658864178],
              [-37.097499998426144, -8.862499992980247],
              [-37.098333331817742, -8.862499992980247],
              [-37.098333332717061, -8.859999992805399],
              [-37.099166667907355, -8.859999992805399],
              [-37.099166667008035, -8.857499992630494],
              [-37.100000000399632, -8.857499992630494],
              [-37.099999999500312, -8.854999992455646],
              [-37.100833331093327, -8.854999991556326],
              [-37.100833332891966, -8.852499993180118],
              [-37.099999998600993, -8.852499991381478],
              [-37.099999999500312, -8.853333325672395],
              [-37.099166667907355, -8.853333325672395],
              [-37.099166665209395, -8.854166659963312],
              [-37.095833331642893, -8.854166659063992],
              [-37.095833332542213, -8.854999992455646],
              [-37.094999997351977, -8.854999992455646],
              [-37.095000000949256, -8.855833324947923],
              [-37.094166667557602, -8.855833324947923],
              [-37.094166665758962, -8.856666659238897],
              [-37.093333332367365, -8.856666659238897],
              [-37.093333333266685, -8.857499992630494],
              [-37.089999998800863, -8.857499992630494],
              [-37.090000000599503, -8.859166658514425],
              [-37.088333332017612, -8.859166658514425],
              [-37.088333332916932, -8.859999993704719],
              [-37.087499998626015, -8.859999992805399],
              [-37.087499998626015, -8.860833324398357],
              [-37.086666664335041, -8.860833326196996],
              [-37.086666667033001, -8.861666658689273],
              [-37.085833331842764, -8.861666658689273],
              [-37.085833331842764, -8.860833326196996],
              [-37.08083333329165, -8.860833326196996],
              [-37.080833331493011, -8.859999992805399],
              [-37.079999999900053, -8.859999992805399],
              [-37.079999999900053, -8.860833327096316],
              [-37.078333334016122, -8.860833326196996],
              [-37.078333332217483, -8.861666658689273],
              [-37.076666666333551, -8.86166665958865],
              [-37.076666667232871, -8.862499992980247],
              [-37.074999998650981, -8.862499992980247],
              [-37.074999998650981, -8.863333325472524],
              [-37.073333332767049, -8.863333325472524],
              [-37.073333333666369, -8.864166658864178],
              [-37.072499999375452, -8.864166658864178],
              [-37.072499998476133, -8.864999994054415],
              [-37.070000000999187, -8.864999993155095],
              [-37.069999998301228, -8.864166658864178],
              [-37.065833332242448, -8.864166658864178],
              [-37.065833330443809, -8.863333325472524],
              [-37.064999999750171, -8.863333325472524],
              [-37.065000000649491, -8.862499992980247],
              [-37.064166665459197, -8.862499992980247],
              [-37.064166665459197, -8.861666658689273],
              [-37.0633333320676, -8.861666657789954],
              [-37.06333333296692, -8.862499992980247],
              [-37.059166666008764, -8.862499992980247],
              [-37.059166666908084, -8.863333325472524],
              [-37.057499998326193, -8.863333325472524],
              [-37.057499998326193, -8.862499992980247],
              [-37.056666666733236, -8.862499993879567],
              [-37.056666666733236, -8.860833326196996],
              [-37.055833333341639, -8.860833326196996],
              [-37.055833331542999, -8.859999992805399],
              [-37.055000000849304, -8.859999992805399],
              [-37.054999999949985, -8.858333326022148],
              [-37.054166666558388, -8.858333326022148],
              [-37.054166665659068, -8.859166658514425],
              [-37.052499998875817, -8.859166657615106],
              [-37.052500000674456, -8.859999991906079],
              [-37.050833333891205, -8.859999992805399],
              [-37.050833332092566, -8.859166658514425],
              [-37.049166665309315, -8.859166658514425],
              [-37.049166668007274, -8.859999991906079],
              [-37.045833332642133, -8.859999993704719],
              [-37.045833331742813, -8.861666657789954],
              [-37.044166666758201, -8.861666658689273],
              [-37.044166665858882, -8.862499992980247],
              [-37.043333330668645, -8.862499992080927],
              [-37.043333332467284, -8.861666658689273],
              [-37.041666665684033, -8.861666658689273],
              [-37.041666665684033, -8.860833324398357],
              [-37.040833334990396, -8.860833324398357],
              [-37.040833332292436, -8.859999992805399],
              [-37.039999998900782, -8.859999992805399],
              [-37.039999998900782, -8.857499992630494],
              [-37.040833332292436, -8.857499992630494],
              [-37.04083333139306, -8.855833326746563],
              [-37.041666665684033, -8.855833325847243],
              [-37.041666665684033, -8.853333324773075],
              [-37.04083333139306, -8.853333325672395],
              [-37.04083333139306, -8.849999993005213],
              [-37.039999998900782, -8.849999993005213],
              [-37.039999998900782, -8.848333326221962],
              [-37.038333331218212, -8.848333326221962],
              [-37.038333331218212, -8.849166658714296],
              [-37.037499999625254, -8.849166658714296],
              [-37.037499998725934, -8.848333326221962],
              [-37.03666666803224, -8.848333326221962],
              [-37.03666666533428, -8.847499992830365],
              [-37.035833331942683, -8.847499992830365],
              [-37.035833331942683, -8.845833326047114],
              [-37.034999998551029, -8.845833326047114],
              [-37.034999998551029, -8.844999992655517],
              [-37.034166666058752, -8.844999993554836],
              [-37.034166666058752, -8.843333325872266],
              [-37.034999999450349, -8.843333325872266],
              [-37.034999999450349, -8.840833325697361],
              [-37.035833333741323, -8.840833325697361],
              [-37.035833331942683, -8.839999991406444],
              [-37.035833332842003, -8.83916665891411],
              [-37.035000000349669, -8.83916665801479],
              [-37.034999999450349, -8.838333325522512],
              [-37.032499998376181, -8.838333325522512],
              [-37.032500001074141, -8.836666657839942],
              [-37.033333332667098, -8.836666658739261],
              [-37.033333332667098, -8.835833325347664],
              [-37.032499999275501, -8.835833325347664],
              [-37.032499998376181, -8.83499999285533],
              [-37.027499999825068, -8.83499999285533],
              [-37.027499999825068, -8.833333325172759],
              [-37.026666665534151, -8.833333326072079],
              [-37.026666665534151, -8.831666657490189],
              [-37.023333331967649, -8.831666660188148],
              [-37.023333332866969, -8.828333325722383],
              [-37.024166666258623, -8.828333325722383],
              [-37.024166665359303, -8.827499993230049],
              [-37.023333332866969, -8.827499994129369],
              [-37.023333331967649, -8.826666658939132],
              [-37.022499999475372, -8.826666659838452],
              [-37.022499999475372, -8.825833325547478],
              [-37.02083333359144, -8.825833325547478],
              [-37.020833332692121, -8.824166658764227],
              [-37.020000001099106, -8.824166659663547],
              [-37.019999998401147, -8.821666658589379],
              [-37.018333333416535, -8.821666658589379],
              [-37.018333331617896, -8.820833326097102],
              [-37.017500000024938, -8.820833326097102],
              [-37.017499998226299, -8.819999992705448],
              [-37.016666667532661, -8.819999992705448],
              [-37.016666665734022, -8.8174999925306],
              [-37.015833333241687, -8.8174999925306],
              [-37.015833332342368, -8.816666660038265],
              [-37.016666666633341, -8.816666659138946],
              [-37.016666666633341, -8.815833325747349],
              [-37.014999998950771, -8.815833325747349],
              [-37.01500000074941, -8.814999992355695],
              [-37.014166665559117, -8.814999992355695],
              [-37.014166667357756, -8.814166658964098],
              [-37.0133333312682, -8.814166658964098],
              [-37.0133333312682, -8.813333325572444],
              [-37.012500001473825, -8.813333325572444],
              [-37.012499998775866, -8.812499993080166],
              [-37.011666665384269, -8.812499993080166],
              [-37.011666665384269, -8.81166665878925],
              [-37.009999999500337, -8.81166665878925],
              [-37.010000000399657, -8.810833325397596],
              [-37.007499998426169, -8.810833325397596],
              [-37.007499998426169, -8.809999992905318],
              [-37.006666666833155, -8.809999992905318],
              [-37.006666665933835, -8.809166657715025],
              [-37.005833332542238, -8.809166658614345],
              [-37.005833331642918, -8.808333325222748],
              [-37.005000000049904, -8.808333326122067],
              [-37.004999998251265, -8.807499991831094],
              [-37.002499999875056, -8.807499992730413],
              [-37.002499998975736, -8.806666658439497],
              [-37.001666667382779, -8.806666660238136],
              [-37.001666666483402, -8.805833325947162],
              [-37.000833333991125, -8.805833325947162],
              [-37.000833333091805, -8.804999992555565],
              [-36.999166665409234, -8.804999993454885],
              [-36.999166666308554, -8.804166659163968],
              [-36.998333333816277, -8.804166659163968],
              [-36.998333332017637, -8.803333325772314],
              [-36.997500000424623, -8.803333326671634],
              [-36.997499999525303, -8.802499992380717],
              [-36.996666665234386, -8.802499992380717],
              [-36.996666665234386, -8.801666658989063],
              [-36.995833334540691, -8.801666658989063],
              [-36.994999998451135, -8.801666658989063],
              [-36.995000000249775, -8.803333325772314],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '253',
        OBJECTID: 167.0,
        Nome_area: 'Serra da Canoa',
        COD_area: 'CA173',
        Import_bio: 'Muito Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Criação de UC de categoria indefinida',
        Area_ha: 62562.0493,
        Shape_Leng: 2.16396,
        Shape_Area: 0.05137,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.488333331917715, -8.450833325397582],
              [-38.488333331917715, -8.449999992905305],
              [-38.489166666208632, -8.449999993804624],
              [-38.489166668906591, -8.449166658614331],
              [-38.489999999600286, -8.449166658614331],
              [-38.490000000499606, -8.448333326122054],
              [-38.493333332267412, -8.448333326122054],
              [-38.493333334066051, -8.447499992730457],
              [-38.494999999050663, -8.447499992730457],
              [-38.494999999949982, -8.44333332667162],
              [-38.495833332442317, -8.443333325772301],
              [-38.495833333341636, -8.442499992380704],
              [-38.497499998326191, -8.442499992380704],
              [-38.497499997426871, -8.44166665898905],
              [-38.498333331717845, -8.44166665898905],
              [-38.498333332617165, -8.43749999293027],
              [-38.499166667807458, -8.43749999293027],
              [-38.499166666908081, -8.435833326147019],
              [-38.498333332617165, -8.435833326147019],
              [-38.498333332617165, -8.434166659363768],
              [-38.496666665833914, -8.434166658464449],
              [-38.496666665833914, -8.434999992755422],
              [-38.494166666558385, -8.434999991856102],
              [-38.494166665659066, -8.434166659363768],
              [-38.493333331368092, -8.434166658464449],
              [-38.493333332267412, -8.433333325972171],
              [-38.490833332991883, -8.433333325972171],
              [-38.490833332092564, -8.43166665918892],
              [-38.489166665309313, -8.43166665918892],
              [-38.489166666208632, -8.430833325797323],
              [-38.48583333264213, -8.430833325797323],
              [-38.485833331742811, -8.429999992405669],
              [-38.481666666583351, -8.429999992405669],
              [-38.481666666583351, -8.427499994029461],
              [-38.479166666408503, -8.427499993130141],
              [-38.479166665509183, -8.428333325622418],
              [-38.476666665334278, -8.428333325622418],
              [-38.476666665334278, -8.427499993130141],
              [-38.474999999450347, -8.427499993130141],
              [-38.474999999450347, -8.428333325622418],
              [-38.472499998376179, -8.428333324723098],
              [-38.472499998376179, -8.427499993130141],
              [-38.470833332492248, -8.427499993130141],
              [-38.470833332492248, -8.426666658839167],
              [-38.47, -8.426666658839167],
              [-38.47, -8.424999992955236],
              [-38.470833331592928, -8.424999992955236],
              [-38.470833332492248, -8.424166658664319],
              [-38.469999999100651, -8.424166658664319],
              [-38.469999999100651, -8.423333326172042],
              [-38.465833330343855, -8.423333324373402],
              [-38.465833333941191, -8.422499993679708],
              [-38.464166665359301, -8.422499992780388],
              [-38.464166665359301, -8.421666657590151],
              [-38.463333332866966, -8.421666658489471],
              [-38.463333331967647, -8.41999999260554],
              [-38.46416666805726, -8.41999999350486],
              [-38.46416666625862, -8.418333324922969],
              [-38.463333331967647, -8.418333325822289],
              [-38.463333331967647, -8.419166659213886],
              [-38.461666667882355, -8.419166659213886],
              [-38.461666666983035, -8.418333326721609],
              [-38.459166667707507, -8.418333325822289],
              [-38.459166665009548, -8.419166659213886],
              [-38.458333331617894, -8.419166659213886],
              [-38.458333331617894, -8.418333325822289],
              [-38.456666665734019, -8.418333325822289],
              [-38.456666667532659, -8.417499992430635],
              [-38.452499999675183, -8.417499992430635],
              [-38.452499999675183, -8.418333325822289],
              [-38.447499998426167, -8.418333325822289],
              [-38.447499998426167, -8.419166659213886],
              [-38.444999998251262, -8.419166659213886],
              [-38.445000000049902, -8.41999999350486],
              [-38.443333331468011, -8.41999999260554],
              [-38.443333331468011, -8.419166657415246],
              [-38.440000000599525, -8.419166659213886],
              [-38.439999998800886, -8.41999999260554],
              [-38.439166665409232, -8.41999999260554],
              [-38.439166666308552, -8.421666658489471],
              [-38.438333333816274, -8.421666658489471],
              [-38.438333330218995, -8.422499992780388],
              [-38.43583333274205, -8.422499992780388],
              [-38.43583333094341, -8.421666658489471],
              [-38.434999998451133, -8.421666658489471],
              [-38.435000000249772, -8.420833325997137],
              [-38.434166665958799, -8.420833325997137],
              [-38.434166665958799, -8.41999999260554],
              [-38.433333332567202, -8.41999999260554],
              [-38.433333332567202, -8.419166659213886],
              [-38.432500000074924, -8.419166659213886],
              [-38.432500000074924, -8.417499992430635],
              [-38.43166666668327, -8.417499991531315],
              [-38.43166666758259, -8.41583332654676],
              [-38.429166666508422, -8.415833325647441],
              [-38.429166665609102, -8.414999991356467],
              [-38.427499998825851, -8.414999993155106],
              [-38.427499999725171, -8.41416665886419],
              [-38.42583333294192, -8.41416665886419],
              [-38.42583333384124, -8.413333325472536],
              [-38.424999999550323, -8.413333325472536],
              [-38.424999998650947, -8.412499992980258],
              [-38.424166667957309, -8.412499992980258],
              [-38.424166665259349, -8.411666658689285],
              [-38.421666665983821, -8.411666658689285],
              [-38.421666665983821, -8.410833326197007],
              [-38.420833331692847, -8.410833326197007],
              [-38.420833331692847, -8.409999992805353],
              [-38.42000000009989, -8.409999992805353],
              [-38.42000000099921, -8.409166658514437],
              [-38.419166666708236, -8.409166658514437],
              [-38.419166667607556, -8.408333326022159],
              [-38.41999999830125, -8.408333326022159],
              [-38.42000000009989, -8.404166659064003],
              [-38.418333332417319, -8.404166659064003],
              [-38.418333331517999, -8.402499993180072],
              [-38.417499999025665, -8.402499993180072],
              [-38.417500000824361, -8.401666658889155],
              [-38.416666666533388, -8.401666658889155],
              [-38.416666665634068, -8.399166658714307],
              [-38.415833332242471, -8.399166659613627],
              [-38.415833331343151, -8.398333326221973],
              [-38.414999998850817, -8.398333327121293],
              [-38.415000000649457, -8.397499992830376],
              [-38.413333333866206, -8.397499992830376],
              [-38.413333332067566, -8.395833326047125],
              [-38.411666665284315, -8.395833326946445],
              [-38.411666667082955, -8.394999992655471],
              [-38.409166667807426, -8.394999992655471],
              [-38.409166668706746, -8.394166658364554],
              [-38.408333333516509, -8.394166658364554],
              [-38.408333331717813, -8.39333332587222],
              [-38.405833333341604, -8.39333332587222],
              [-38.405833331542965, -8.392499992480623],
              [-38.403333331368117, -8.392499993379943],
              [-38.403333331368117, -8.391666659988346],
              [-38.402499997976463, -8.391666663585625],
              [-38.396666666033752, -8.391666659089026],
              [-38.396666666033752, -8.395833326047125],
              [-38.394999998351182, -8.395833326047125],
              [-38.395000001049141, -8.394999992655471],
              [-38.394166665858904, -8.394999992655471],
              [-38.394166667657544, -8.394166658364554],
              [-38.39333333246725, -8.394166658364554],
              [-38.39333333246725, -8.39333332587222],
              [-38.392499999075653, -8.39333332587222],
              [-38.392499999075653, -8.392499992480623],
              [-38.391666667482696, -8.392499992480623],
              [-38.391666666583376, -8.391666659089026],
              [-38.389999999800125, -8.391666659089026],
              [-38.389999999800125, -8.389999991406455],
              [-38.389166665509151, -8.389999993205095],
              [-38.389166666408471, -8.389166658914121],
              [-38.388333333016874, -8.389166658914121],
              [-38.388333333916194, -8.388333325522524],
              [-38.3874999987259, -8.388333325522524],
              [-38.3874999987259, -8.386666658739273],
              [-38.386666668032262, -8.386666658739273],
              [-38.386666666233623, -8.385833325347619],
              [-38.385833332841969, -8.385833324448299],
              [-38.385833332841969, -8.384166658564368],
              [-38.38166666588387, -8.384166658564368],
              [-38.38166666678319, -8.384999993754661],
              [-38.380833333391593, -8.384999992855342],
              [-38.380833334290912, -8.385833325347619],
              [-38.378333332317368, -8.385833325347619],
              [-38.378333333216688, -8.384999992855342],
              [-38.374999998750923, -8.384999992855342],
              [-38.375000000549562, -8.384166658564368],
              [-38.369999999300489, -8.384166658564368],
              [-38.36999999840117, -8.384999992855342],
              [-38.369166665908836, -8.384999992855342],
              [-38.369166665908836, -8.383333325172771],
              [-38.367500000024961, -8.383333326072091],
              [-38.367500000024961, -8.382499991781174],
              [-38.365833331443071, -8.382499992680494],
              [-38.36583333324171, -8.38166665838952],
              [-38.363333331268166, -8.38166665838952],
              [-38.363333331268166, -8.380833326796562],
              [-38.362499999675208, -8.380833325897243],
              [-38.362499999675208, -8.379166659113991],
              [-38.361666665384234, -8.379166659113991],
              [-38.361666665384234, -8.378333325722338],
              [-38.362499998775888, -8.378333325722338],
              [-38.362499999675208, -8.37749999323006],
              [-38.361666665384234, -8.37749999323006],
              [-38.36166666808225, -8.376666658939087],
              [-38.360833331992637, -8.376666658939087],
              [-38.360833331992637, -8.375833325547489],
              [-38.358333333616429, -8.375833325547489],
              [-38.358333333616429, -8.37166665858939],
              [-38.357499999325455, -8.37166665858939],
              [-38.357499998426135, -8.370833326097056],
              [-38.356666667732497, -8.370833326097056],
              [-38.356666666833178, -8.369999992705459],
              [-38.355833333441524, -8.369999992705459],
              [-38.355833331642884, -8.369166658414485],
              [-38.354999998251287, -8.369166658414485],
              [-38.355000000049927, -8.368333325922208],
              [-38.350833333091828, -8.368333325922208],
              [-38.350833331293188, -8.367499992530611],
              [-38.344999998451101, -8.367499992530611],
              [-38.344999998451101, -8.366666659138957],
              [-38.344166665958824, -8.366666659138957],
              [-38.344166666858143, -8.364166658964109],
              [-38.343333332567227, -8.364166658964109],
              [-38.343333332567227, -8.362499993080178],
              [-38.342500000074892, -8.362499993080178],
              [-38.342499998276253, -8.361666658789204],
              [-38.341666667582615, -8.361666658789204],
              [-38.341666666683295, -8.360833325397607],
              [-38.340833333291641, -8.360833325397607],
              [-38.340833332392322, -8.359166657715036],
              [-38.339999999900044, -8.359166658614356],
              [-38.340000000799364, -8.354999992555577],
              [-38.33916666740771, -8.354999992555577],
              [-38.33916666740771, -8.353333325772326],
              [-38.338333331318154, -8.353333325772326],
              [-38.338333332217474, -8.352499991481352],
              [-38.337500000624459, -8.352499992380672],
              [-38.33749999882582, -8.348333324523253],
              [-38.337500000624459, -8.347499992930295],
              [-38.335833332941945, -8.347499992930295],
              [-38.335833332941945, -8.346666658639322],
              [-38.336666665434223, -8.346666658639322],
              [-38.336666666333542, -8.345833326147044],
              [-38.33749999882582, -8.345833326147044],
              [-38.337499999725139, -8.340833325797291],
              [-38.338333331318154, -8.340833325797291],
              [-38.338333331318154, -8.335833325447538],
              [-38.33916666830703, -8.335833325447538],
              [-38.339166665609071, -8.334999992955261],
              [-38.339999999900044, -8.334999992955261],
              [-38.340000000799364, -8.334166658664344],
              [-38.340833332392322, -8.334166658664344],
              [-38.340833331493002, -8.33333332617201],
              [-38.341666667582615, -8.33333332617201],
              [-38.341666667582615, -8.330833325997162],
              [-38.342500000074892, -8.330833325997162],
              [-38.342500000974212, -8.329166659213911],
              [-38.343333331667907, -8.329166659213911],
              [-38.343333332567227, -8.326666659039063],
              [-38.344999999350421, -8.326666659039063],
              [-38.344999998451101, -8.325833326546729],
              [-38.346666664335032, -8.325833325647409],
              [-38.346666665234352, -8.324166658864158],
              [-38.347499999525326, -8.324166658864158],
              [-38.347499998626006, -8.32166665868931],
              [-38.346666665234352, -8.32166665868931],
              [-38.346666665234352, -8.320833326196976],
              [-38.345833333641394, -8.320833326196976],
              [-38.345833332742075, -8.319999992805378],
              [-38.344999999350421, -8.319999992805378],
              [-38.34500000024974, -8.318333326022127],
              [-38.344166666858143, -8.318333326022127],
              [-38.344166667757463, -8.314999992455625],
              [-38.34500000024974, -8.314999993354945],
              [-38.34500000024974, -8.313333325672374],
              [-38.344166665958824, -8.313333325672374],
              [-38.344166665958824, -8.311666658889123],
              [-38.343333333466546, -8.311666658889123],
              [-38.343333334365866, -8.310833325497526],
              [-38.336666667232862, -8.310833326396846],
              [-38.336666665434223, -8.309999993005249],
              [-38.335833332042569, -8.309999993904569],
              [-38.335833332941945, -8.309166658714275],
              [-38.334166667058014, -8.309166658714275],
              [-38.334166665259374, -8.308333326221998],
              [-38.333333330968401, -8.308333326221998],
              [-38.333333331867721, -8.306666658539427],
              [-38.332499999375443, -8.306666659438747],
              [-38.332499997576804, -8.304999992655496],
              [-38.331666665983789, -8.304999992655496],
              [-38.330833333491512, -8.304999992655496],
              [-38.330833332592192, -8.305833326047093],
              [-38.32749999902569, -8.305833326047093],
              [-38.32750000082433, -8.306666659438747],
              [-38.326666665634093, -8.306666658539427],
              [-38.326666666533413, -8.307499992830344],
              [-38.325833332242439, -8.307499992830344],
              [-38.325833334041079, -8.309166658714275],
              [-38.324166665459188, -8.309166658714275],
              [-38.324166666358508, -8.309999993005249],
              [-38.323333332067591, -8.309999993005249],
              [-38.323333332966911, -8.310833325497526],
              [-38.322499998675937, -8.310833325497526],
              [-38.322500000474577, -8.311666658889123],
              [-38.319166666008812, -8.311666658889123],
              [-38.319166666908131, -8.312499993180097],
              [-38.318333331717838, -8.312499993180097],
              [-38.318333331717838, -8.313333325672374],
              [-38.317499999225561, -8.313333325672374],
              [-38.317499999225561, -8.314166659064028],
              [-38.316666664934587, -8.314166658164709],
              [-38.316666666733227, -8.31583332494796],
              [-38.314999999949976, -8.315833325847279],
              [-38.314999999050656, -8.314999992455625],
              [-38.313333330468765, -8.314999992455625],
              [-38.313333332267405, -8.314166659064028],
              [-38.310833332092557, -8.314166658164709],
              [-38.310833333891196, -8.313333324773055],
              [-38.310000000499599, -8.313333325672374],
              [-38.310000000499599, -8.312499991381458],
              [-38.308333331917709, -8.312499993180097],
              [-38.308333332817028, -8.311666658889123],
              [-38.305833331742804, -8.3116666597885],
              [-38.305833331742804, -8.313333324773055],
              [-38.305000001049166, -8.313333325672374],
              [-38.305000001049166, -8.312499991381458],
              [-38.304166667657569, -8.312499991381458],
              [-38.304166666758249, -8.311666657989804],
              [-38.303333332467275, -8.311666658889123],
              [-38.303333331567956, -8.310833324598207],
              [-38.300833334091067, -8.310833325497526],
              [-38.300833334091067, -8.311666658889123],
              [-38.299999999800093, -8.311666657989804],
              [-38.299999999800093, -8.310833325497526],
              [-38.295833331942674, -8.310833325497526],
              [-38.295833332841994, -8.311666658889123],
              [-38.294999999450397, -8.311666658889123],
              [-38.295000000349717, -8.314166659064028],
              [-38.294166667857382, -8.314166659064028],
              [-38.294166666058743, -8.314999992455625],
              [-38.292499998376172, -8.314999992455625],
              [-38.292499998376172, -8.315833325847279],
              [-38.290833332492241, -8.31583332494796],
              [-38.290833332492241, -8.316666657440237],
              [-38.289999999100644, -8.316666659238876],
              [-38.290000000899283, -8.31749999352985],
              [-38.28916666660831, -8.31749999263053],
              [-38.28916666660831, -8.318333326022127],
              [-38.286666665534142, -8.318333326022127],
              [-38.286666665534142, -8.319166658514405],
              [-38.285833333941184, -8.319166658514405],
              [-38.285833333041865, -8.318333326022127],
              [-38.28333333196764, -8.318333326022127],
              [-38.283333333766279, -8.31749999263053],
              [-38.281666667882348, -8.31749999263053],
              [-38.281666666983028, -8.316666659238876],
              [-38.276666666633332, -8.316666659238876],
              [-38.276666665734012, -8.315833325847279],
              [-38.275833333241678, -8.315833325847279],
              [-38.275833333241678, -8.314166658164709],
              [-38.274166667357747, -8.314166659064028],
              [-38.274166665559108, -8.313333325672374],
              [-38.270833331992662, -8.313333326571694],
              [-38.270833332891982, -8.312499993180097],
              [-38.269166667907371, -8.312499993180097],
              [-38.269166665209411, -8.313333325672374],
              [-38.268333333616397, -8.313333325672374],
              [-38.268333333616397, -8.312499993180097],
              [-38.264999997351936, -8.312499994079417],
              [-38.265000000049895, -8.314166659064028],
              [-38.263333330568685, -8.314166659064028],
              [-38.263333332367324, -8.314999992455625],
              [-38.262499998975727, -8.314999992455625],
              [-38.262499999875047, -8.315833325847279],
              [-38.26166666648345, -8.315833325847279],
              [-38.26166666558413, -8.316666659238876],
              [-38.262499998975727, -8.316666659238876],
              [-38.262499999875047, -8.31749999263053],
              [-38.26166666468481, -8.31749999263053],
              [-38.261666667382769, -8.319166658514405],
              [-38.260833333091796, -8.319166658514405],
              [-38.260833333091796, -8.324999993155132],
              [-38.259999998800879, -8.324999991356492],
              [-38.259999998800879, -8.325833325647409],
              [-38.257499999525294, -8.325833324748089],
              [-38.257499999525294, -8.326666659039063],
              [-38.256666665234377, -8.326666659039063],
              [-38.256666667932336, -8.325833325647409],
              [-38.255833332742043, -8.325833325647409],
              [-38.255833333641419, -8.324999993155132],
              [-38.253333332567195, -8.324999993155132],
              [-38.253333332567195, -8.324166658864158],
              [-38.251666667582583, -8.324166658864158],
              [-38.251666667582583, -8.323333325472561],
              [-38.249999999000693, -8.323333325472561],
              [-38.249999999900012, -8.324166658864158],
              [-38.249166665609096, -8.324166658864158],
              [-38.249166667407735, -8.324999993155132],
              [-38.247499998825845, -8.324999993155132],
              [-38.247500000624484, -8.327499994229299],
              [-38.248333331318122, -8.32749999243066],
              [-38.248333331318122, -8.328333325822257],
              [-38.247499998825845, -8.328333326721577],
              [-38.247499999725164, -8.330833325997162],
              [-38.248333331318122, -8.330833325997162],
              [-38.248333331318122, -8.332499992780413],
              [-38.247500000624484, -8.332499992780413],
              [-38.247499998825845, -8.335833325447538],
              [-38.248333334016081, -8.335833325447538],
              [-38.248333331318122, -8.336666658839192],
              [-38.247500000624484, -8.336666658839192],
              [-38.247499997926525, -8.337499993130109],
              [-38.248333334016081, -8.337499992230789],
              [-38.248333332217442, -8.33916665901404],
              [-38.249166666508415, -8.33916665901404],
              [-38.249166665609096, -8.339999992405694],
              [-38.250833331493027, -8.339999992405694],
              [-38.250833331493027, -8.340833324897972],
              [-38.251666666683263, -8.340833325797291],
              [-38.251666666683263, -8.341666659188945],
              [-38.252499998276278, -8.341666657390306],
              [-38.252499998276278, -8.342499992580542],
              [-38.253333331667875, -8.342499992580542],
              [-38.253333333466514, -8.343333325972196],
              [-38.254166667757488, -8.343333325972196],
              [-38.254166666858168, -8.344166658464474],
              [-38.255833332742043, -8.344166658464474],
              [-38.255833332742043, -8.34499999275539],
              [-38.257499998625974, -8.34499999275539],
              [-38.257499999525294, -8.347499992930295],
              [-38.256666667033016, -8.347499992930295],
              [-38.256666665234377, -8.349166658814227],
              [-38.255833332742043, -8.349166658814227],
              [-38.255833333641419, -8.350833325597478],
              [-38.253333331667875, -8.350833325597478],
              [-38.253333331667875, -8.349999993105143],
              [-38.250833332392347, -8.349999993105143],
              [-38.250833332392347, -8.349166658814227],
              [-38.249166666508415, -8.349166658814227],
              [-38.249166665609096, -8.347499992930295],
              [-38.248333332217442, -8.347499992930295],
              [-38.248333331318122, -8.345833326147044],
              [-38.247499998825845, -8.345833326147044],
              [-38.247499998825845, -8.344999991856071],
              [-38.246666666333567, -8.34499999275539],
              [-38.246666665434248, -8.344166658464474],
              [-38.244999998650997, -8.344166658464474],
              [-38.244999998650997, -8.343333325972196],
              [-38.242499999375411, -8.343333325972196],
              [-38.242499999375411, -8.342499992580542],
              [-38.239166665808909, -8.342499992580542],
              [-38.239166666708286, -8.341666657390306],
              [-38.238333333316632, -8.341666659188945],
              [-38.238333333316632, -8.340833325797291],
              [-38.237499999925035, -8.340833325797291],
              [-38.237499999025715, -8.339999991506374],
              [-38.236666665634061, -8.339999991506374],
              [-38.236666665634061, -8.339166658114721],
              [-38.233333332966879, -8.33916665901404],
              [-38.233333332966879, -8.338333325622443],
              [-38.228333331717863, -8.338333325622443],
              [-38.228333332617183, -8.33916665901404],
              [-38.220833332991901, -8.33916665901404],
              [-38.220833332991901, -8.340833326696611],
              [-38.21916666800729, -8.340833324897972],
              [-38.21916666620865, -8.341666659188945],
              [-38.213333331567981, -8.341666659188945],
              [-38.213333331567981, -8.343333325972196],
              [-38.212499999075646, -8.343333325972196],
              [-38.212499999075646, -8.344166658464474],
              [-38.210833331393076, -8.344166657565154],
              [-38.210833334091035, -8.34499999275539],
              [-38.211666665684049, -8.34499999275539],
              [-38.211666665684049, -8.348333325422573],
              [-38.210833334990355, -8.348333325422573],
              [-38.210833332292395, -8.349999993105143],
              [-38.209999999800118, -8.349999993105143],
              [-38.209999998900798, -8.350833325597478],
              [-38.209166666408464, -8.350833324698158],
              [-38.209166667307784, -8.351666658989075],
              [-38.208333331218228, -8.351666658989075],
              [-38.208333331218228, -8.352499994179368],
              [-38.207499998725893, -8.352499994179368],
              [-38.20750000052459, -8.353333325772326],
              [-38.206666666233616, -8.353333325772326],
              [-38.206666668032256, -8.354166659163923],
              [-38.205833332842019, -8.354166659163923],
              [-38.205833332842019, -8.354999992555577],
              [-38.20750000052459, -8.354999992555577],
              [-38.207499998725893, -8.358333326122079],
              [-38.206666668032256, -8.358333326122079],
              [-38.206666666233616, -8.357499992730425],
              [-38.205833332842019, -8.357499992730425],
              [-38.205833331942699, -8.358333326122079],
              [-38.204999999450365, -8.358333326122079],
              [-38.204999999450365, -8.359166657715036],
              [-38.203333331767794, -8.359166658614356],
              [-38.203333331767794, -8.359999993804649],
              [-38.200833331592946, -8.35999999290533],
              [-38.200833331592946, -8.359166658614356],
              [-38.200000000899252, -8.359166658614356],
              [-38.200000000899252, -8.35999999290533],
              [-38.199166667507654, -8.35999999290533],
              [-38.199166665709015, -8.360833325397607],
              [-38.198333333216738, -8.360833325397607],
              [-38.198333333216738, -8.361666657889884],
              [-38.196666665534167, -8.361666658789204],
              [-38.196666665534167, -8.362499993080178],
              [-38.193333331967665, -8.362499993080178],
              [-38.193333333766304, -8.363333325572455],
              [-38.189166665908886, -8.363333326471775],
              [-38.189166666808205, -8.36583332574736],
              [-38.190000001998442, -8.36583332574736],
              [-38.189999999300483, -8.368333325922208],
              [-38.187500000024954, -8.368333325922208],
              [-38.187500000024954, -8.369999992705459],
              [-38.182499998775882, -8.369999992705459],
              [-38.182500000574521, -8.372499992880307],
              [-38.181666665384284, -8.372499993779627],
              [-38.181666666283604, -8.373333325372641],
              [-38.180833331992631, -8.373333325372641],
              [-38.18083333379127, -8.374166658764238],
              [-38.180000000399673, -8.374166658764238],
              [-38.180000000399673, -8.374999993055212],
              [-38.179166667008019, -8.374999993954532],
              [-38.179166667008019, -8.375833325547489],
              [-38.178333331817782, -8.375833325547489],
              [-38.178333333616422, -8.376666658939087],
              [-38.176666666833171, -8.376666658939087],
              [-38.176666665034531, -8.37749999323006],
              [-38.175833332542197, -8.377499991431421],
              [-38.175833331642878, -8.379166659113991],
              [-38.17500000004992, -8.379166660013311],
              [-38.17500000094924, -8.379999992505589],
              [-38.174166666658323, -8.379999992505589],
              [-38.174166666658323, -8.383333326072091],
              [-38.173333332367349, -8.383333326072091],
              [-38.173333332367349, -8.384166658564368],
              [-38.172499998975752, -8.384166658564368],
              [-38.172499999875072, -8.386666658739273],
              [-38.171666666483418, -8.386666658739273],
              [-38.171666667382738, -8.38749999303019],
              [-38.170833333091821, -8.38749999303019],
              [-38.170833331293181, -8.388333324623204],
              [-38.170000000599487, -8.388333325522524],
              [-38.169999999700167, -8.389999993205095],
              [-38.16916666630857, -8.389999993205095],
              [-38.16916666540925, -8.390833325697372],
              [-38.168333332017596, -8.390833325697372],
              [-38.168333332017596, -8.392499992480623],
              [-38.167499999525319, -8.392499991581303],
              [-38.167499999525319, -8.396666658539402],
              [-38.163333333466539, -8.396666657640083],
              [-38.16333333256722, -8.395833326946445],
              [-38.160833332392315, -8.395833326047125],
              [-38.160833333291635, -8.394999992655471],
              [-38.159999999000718, -8.394999992655471],
              [-38.159999999900037, -8.39333332587222],
              [-38.158333334016106, -8.39333332587222],
              [-38.158333334016106, -8.389999993205095],
              [-38.156666667232855, -8.389999993205095],
              [-38.156666665434216, -8.388333325522524],
              [-38.155833332941938, -8.388333325522524],
              [-38.155833333841258, -8.386666658739273],
              [-38.156666666333535, -8.386666659638593],
              [-38.156666667232855, -8.384999992855342],
              [-38.155833333841258, -8.384999992855342],
              [-38.155833332042619, -8.384166658564368],
              [-38.155000000449604, -8.384166658564368],
              [-38.154999999550284, -8.383333326072091],
              [-38.154166665259368, -8.383333326072091],
              [-38.154166667957327, -8.380833326796562],
              [-38.153333333666353, -8.380833325897243],
              [-38.153333331867714, -8.379999992505589],
              [-38.152500000274756, -8.379999991606269],
              [-38.152499999375436, -8.378333325722338],
              [-38.151666665983782, -8.378333325722338],
              [-38.151666666883102, -8.37749999323006],
              [-38.150833332592185, -8.37749999412938],
              [-38.149166665808934, -8.37749999323006],
              [-38.149166666708254, -8.378333325722338],
              [-38.147499999925003, -8.378333325722338],
              [-38.147499999925003, -8.379166658214672],
              [-38.144999998850835, -8.379166659113991],
              [-38.144999999750155, -8.379999992505589],
              [-38.144166665459181, -8.379999992505589],
              [-38.144166665459181, -8.38166665838952],
              [-38.141666664385014, -8.38166665838952],
              [-38.141666667982292, -8.382499992680494],
              [-38.140000000299722, -8.382499992680494],
              [-38.139999999400402, -8.38333332697141],
              [-38.138333331717831, -8.383333326072091],
              [-38.138333331717831, -8.384166658564368],
              [-38.137499999225554, -8.384166658564368],
              [-38.137500001024193, -8.384999992855342],
              [-38.135833332442303, -8.384999992855342],
              [-38.135833331542983, -8.386666658739273],
              [-38.133333331368078, -8.386666658739273],
              [-38.133333331368078, -8.38749999303019],
              [-38.129999998700953, -8.38749999213087],
              [-38.129999998700953, -8.388333325522524],
              [-38.128333331917702, -8.388333325522524],
              [-38.128333332817022, -8.389999993205095],
              [-38.126666666033771, -8.389999993205095],
              [-38.126666666033771, -8.390833325697372],
              [-38.123333331567949, -8.390833325697372],
              [-38.123333331567949, -8.391666659089026],
              [-38.119166666408489, -8.391666659089026],
              [-38.119166665509169, -8.392499992480623],
              [-38.117499999625238, -8.392499992480623],
              [-38.117499998725918, -8.39333332587222],
              [-38.113333332667139, -8.39333332587222],
              [-38.113333332667139, -8.394166658364554],
              [-38.11, -8.394166657465234],
              [-38.11, -8.395833326047125],
              [-38.10916666570904, -8.395833326047125],
              [-38.109166667507679, -8.397499992830376],
              [-38.11, -8.397499991931056],
              [-38.11, -8.398333326221973],
              [-38.110833334290874, -8.398333326221973],
              [-38.110833333391554, -8.401666658889155],
              [-38.108333333216706, -8.401666658889155],
              [-38.108333333216706, -8.402499993180072],
              [-38.107499998925789, -8.402499993180072],
              [-38.107499998925789, -8.403333325672406],
              [-38.106666665534135, -8.403333325672406],
              [-38.106666667332775, -8.404166659064003],
              [-38.105833331243218, -8.404166659064003],
              [-38.105833333041858, -8.404999992455657],
              [-38.104166664459967, -8.404999992455657],
              [-38.104166666258607, -8.404166658164684],
              [-38.102499998576036, -8.404166659064003],
              [-38.102500001273995, -8.405833325847254],
              [-38.100833331792785, -8.405833324947935],
              [-38.100833332692105, -8.406666659238908],
              [-38.099999997501868, -8.406666659238908],
              [-38.099999999300508, -8.408333326022159],
              [-38.099166666808173, -8.408333326022159],
              [-38.099166666808173, -8.409166658514437],
              [-38.097499998226283, -8.409166658514437],
              [-38.097499998226283, -8.409999992805353],
              [-38.094999999850074, -8.409999992805353],
              [-38.095000000749394, -8.410833326197007],
              [-38.094166665559101, -8.410833326197007],
              [-38.094166667357797, -8.411666658689285],
              [-38.092500000574546, -8.411666658689285],
              [-38.092499998775907, -8.413333324573216],
              [-38.091666665384253, -8.413333324573216],
              [-38.091666668082212, -8.41416665886419],
              [-38.090833331992656, -8.41416665886419],
              [-38.090833331992656, -8.414999994054426],
              [-38.089999998601002, -8.414999993155106],
              [-38.090000000399641, -8.415833325647441],
              [-38.089166667907364, -8.415833325647441],
              [-38.089166665209405, -8.416666659039038],
              [-38.089166665209405, -8.418333324922969],
              [-38.089999998601002, -8.418333325822289],
              [-38.089999999500321, -8.41999999260554],
              [-38.090833333791295, -8.41999999170622],
              [-38.090833332891975, -8.421666658489471],
              [-38.091666668082212, -8.421666658489471],
              [-38.091666665384253, -8.423333326172042],
              [-38.094999998950755, -8.423333326172042],
              [-38.094999998950755, -8.422499992780388],
              [-38.099999998401188, -8.422499992780388],
              [-38.099999999300508, -8.423333326172042],
              [-38.110833331592914, -8.423333326172042],
              [-38.110833331592914, -8.424166658664319],
              [-38.111666666783208, -8.424166658664319],
              [-38.111666665883888, -8.42583332544757],
              [-38.112499998376165, -8.42583332544757],
              [-38.112499998376165, -8.429999994204309],
              [-38.113333331767819, -8.429999992405669],
              [-38.113333332667139, -8.430833326696643],
              [-38.112499998376165, -8.430833324898003],
              [-38.112499999275485, -8.433333325972171],
              [-38.119166665509169, -8.433333325972171],
              [-38.119166666408489, -8.434166658464449],
              [-38.127499997626728, -8.434166658464449],
              [-38.127499998526048, -8.433333325972171],
              [-38.130833334790509, -8.433333325972171],
              [-38.13083333299187, -8.434166658464449],
              [-38.133333334965414, -8.434166658464449],
              [-38.133333331368078, -8.434999992755422],
              [-38.138333331717831, -8.434999992755422],
              [-38.138333331717831, -8.435833326147019],
              [-38.143333332067584, -8.435833326147019],
              [-38.143333333866224, -8.434999992755422],
              [-38.144166666358501, -8.434999991856102],
              [-38.144166666358501, -8.43583332434838],
              [-38.144999999750155, -8.435833326147019],
              [-38.144999998850835, -8.436666658639353],
              [-38.148333331518018, -8.436666658639353],
              [-38.148333332417337, -8.43749999293027],
              [-38.155833333841258, -8.437499992030951],
              [-38.155833332941938, -8.438333325422605],
              [-38.156666664534896, -8.438333325422605],
              [-38.156666667232855, -8.439166658814202],
              [-38.157499999725189, -8.439166658814202],
              [-38.157499999725189, -8.439999993105175],
              [-38.155833333841258, -8.439999993105175],
              [-38.155833332042619, -8.440833325597453],
              [-38.154999999550284, -8.440833325597453],
              [-38.155000000449604, -8.44166665898905],
              [-38.154166667058007, -8.44166665898905],
              [-38.154166667957327, -8.445833325947206],
              [-38.153333332767033, -8.445833325947206],
              [-38.153333333666353, -8.449166658614331],
              [-38.153333333666353, -8.449999992905305],
              [-38.153333332767033, -8.450833325397582],
              [-38.152500000274756, -8.450833324498262],
              [-38.152499999375436, -8.451666658789236],
              [-38.151666665983782, -8.451666658789236],
              [-38.151666665983782, -8.452499993080153],
              [-38.150833331692866, -8.452499992180833],
              [-38.150833333491505, -8.454166658964084],
              [-38.150000000099908, -8.454166658964084],
              [-38.149999998301212, -8.454999992355738],
              [-38.150000000099908, -8.455833324848015],
              [-38.150833333491505, -8.455833325747335],
              [-38.150833332592185, -8.456666659138989],
              [-38.152500002073396, -8.456666659138989],
              [-38.152499999375436, -8.459999992705434],
              [-38.153333333666353, -8.459999992705434],
              [-38.153333333666353, -8.461666659488685],
              [-38.152500000274756, -8.461666658589365],
              [-38.152500000274756, -8.467499993230035],
              [-38.151666667782422, -8.467499993230035],
              [-38.151666667782422, -8.468333326621689],
              [-38.150833333491505, -8.46833332482305],
              [-38.150833332592185, -8.473333326072066],
              [-38.150000000099908, -8.473333326072066],
              [-38.150000000099908, -8.4741666585644],
              [-38.149166667607574, -8.474166659463719],
              [-38.149166666708254, -8.475833325347651],
              [-38.148333333316657, -8.475833325347651],
              [-38.148333331518018, -8.476666658739248],
              [-38.147500000824323, -8.476666659638568],
              [-38.147499999025683, -8.478333325522499],
              [-38.146666666533406, -8.478333325522499],
              [-38.146666665634086, -8.479166658914153],
              [-38.145833334041072, -8.479166659813473],
              [-38.145833331343113, -8.47999999320507],
              [-38.144999998850835, -8.47999999320507],
              [-38.145000000649475, -8.481666659089001],
              [-38.143333332067584, -8.481666659089001],
              [-38.143333333866224, -8.482499992480598],
              [-38.14249999957525, -8.482499992480598],
              [-38.142500000474627, -8.484999992655503],
              [-38.141666667982292, -8.484999992655503],
              [-38.141666667082973, -8.4858333260471],
              [-38.14249999957525, -8.4858333260471],
              [-38.142500000474627, -8.486666658539434],
              [-38.141666667082973, -8.486666659438754],
              [-38.141666667082973, -8.487499992830351],
              [-38.140000000299722, -8.487499992830351],
              [-38.139999999400402, -8.49166665888913],
              [-38.139166666908125, -8.49166665888913],
              [-38.139166667807444, -8.492499994079424],
              [-38.139999999400402, -8.492499993180104],
              [-38.139999998501082, -8.498333326022134],
              [-38.140833332792056, -8.498333326022134],
              [-38.140833332792056, -8.499166658514412],
              [-38.141666667082973, -8.499166658514412],
              [-38.141666665284333, -8.500833326196982],
              [-38.14249999867593, -8.500833326196982],
              [-38.142500000474627, -8.502499992980233],
              [-38.144166667257821, -8.502499992980233],
              [-38.144166665459181, -8.504166658864165],
              [-38.144999999750155, -8.504166658864165],
              [-38.145000000649475, -8.508333325822264],
              [-38.145833330443793, -8.508333325822264],
              [-38.145833334041072, -8.509999993504834],
              [-38.146666665634086, -8.509999992605515],
              [-38.146666665634086, -8.51249999278042],
              [-38.147499999025683, -8.51249999278042],
              [-38.147499999925003, -8.513333326172017],
              [-38.149166666708254, -8.513333327071336],
              [-38.149166665808934, -8.514166658664294],
              [-38.150833332592185, -8.514166658664294],
              [-38.151666667782422, -8.514166658664294],
              [-38.151666665084463, -8.513333326172017],
              [-38.153333331867714, -8.513333326172017],
              [-38.153333331867714, -8.51249999278042],
              [-38.154166667957327, -8.512499993679739],
              [-38.154166665259368, -8.511666658489446],
              [-38.155833332042619, -8.511666658489446],
              [-38.155833332042619, -8.510833325997169],
              [-38.156666666333535, -8.510833325997169],
              [-38.156666667232855, -8.509166659213918],
              [-38.157500000624509, -8.509166659213918],
              [-38.157500000624509, -8.508333326721583],
              [-38.159166665609064, -8.508333325822264],
              [-38.159166666508384, -8.502499993879553],
              [-38.159999999000718, -8.502499992980233],
              [-38.159999998101398, -8.501666658689317],
              [-38.160833333291635, -8.501666658689317],
              [-38.160833332392315, -8.499999992805385],
              [-38.161666665783969, -8.499999992805385],
              [-38.161666665783969, -8.499166658514412],
              [-38.163333333466539, -8.499166658514412],
              [-38.16333333256722, -8.498333326022134],
              [-38.16500000024979, -8.498333326022134],
              [-38.164999999350471, -8.497499992630537],
              [-38.165833331842748, -8.497499991731161],
              [-38.165833332742068, -8.498333326022134],
              [-38.168333330218957, -8.498333326921454],
              [-38.168333332017596, -8.499166657615092],
              [-38.16916666720789, -8.499166658514412],
              [-38.16916666630857, -8.490833326396853],
              [-38.168333332017596, -8.490833325497533],
              [-38.168333332017596, -8.486666658539434],
              [-38.16916666540925, -8.486666658539434],
              [-38.16916666540925, -8.4858333260471],
              [-38.169999999700167, -8.4858333260471],
              [-38.169999999700167, -8.484999992655503],
              [-38.171666665584098, -8.484999992655503],
              [-38.171666666483418, -8.482499991581278],
              [-38.172499999875072, -8.482499992480598],
              [-38.172499998975752, -8.481666659089001],
              [-38.173333335065308, -8.481666659089001],
              [-38.173333333266669, -8.480833325697404],
              [-38.174166667557643, -8.480833325697404],
              [-38.174166666658323, -8.479166658914153],
              [-38.17499999825128, -8.479166658914153],
              [-38.17500000004992, -8.478333325522499],
              [-38.176666665933851, -8.478333325522499],
              [-38.176666665933851, -8.477499993929541],
              [-38.177499998426129, -8.477499993030222],
              [-38.177499999325448, -8.476666658739248],
              [-38.17916666520938, -8.476666658739248],
              [-38.179999999500353, -8.476666658739248],
              [-38.179999997701714, -8.477499993030222],
              [-38.18083333289195, -8.477499993030222],
              [-38.18083333289195, -8.478333326421819],
              [-38.185000000749369, -8.478333325522499],
              [-38.185000000749369, -8.479166658914153],
              [-38.18666666753262, -8.479166658914153],
              [-38.186666665733981, -8.47999999320507],
              [-38.189999999300483, -8.47999999320507],
              [-38.189999998401163, -8.487499992830351],
              [-38.19083333269208, -8.487499992830351],
              [-38.190833333591456, -8.488333326222005],
              [-38.191666667882373, -8.488333326222005],
              [-38.191666666083734, -8.490833325497533],
              [-38.189999998401163, -8.490833325497533],
              [-38.190000001099122, -8.49166665888913],
              [-38.189166665908886, -8.49166665888913],
              [-38.189166666808205, -8.493333326571701],
              [-38.188333333416551, -8.493333325672381],
              [-38.188333332517232, -8.494166659064035],
              [-38.187499998226315, -8.494166659064035],
              [-38.187499998226315, -8.494999992455632],
              [-38.18666666753262, -8.494999992455632],
              [-38.18666666753262, -8.497499992630537],
              [-38.185833333241703, -8.497499993529857],
              [-38.185833332342384, -8.498333326022134],
              [-38.18666666753262, -8.498333326022134],
              [-38.186666665733981, -8.499166658514412],
              [-38.188333332517232, -8.499166658514412],
              [-38.188333331617912, -8.499999992805385],
              [-38.189166666808205, -8.499999992805385],
              [-38.189166665908886, -8.504166658864165],
              [-38.188333332517232, -8.504166659763484],
              [-38.188333333416551, -8.506666659039013],
              [-38.187500000024954, -8.506666659039013],
              [-38.187500000024954, -8.508333325822264],
              [-38.185833332342384, -8.508333325822264],
              [-38.185833333241703, -8.51249999278042],
              [-38.1866666666333, -8.51249999278042],
              [-38.1866666666333, -8.515833324548225],
              [-38.187500000924274, -8.515833325447545],
              [-38.187500000024954, -8.516666658839199],
              [-38.18666666843194, -8.516666658839199],
              [-38.186666665733981, -8.519999992405701],
              [-38.187500000024954, -8.519999992405701],
              [-38.187499998226315, -8.521666659188952],
              [-38.1866666666333, -8.521666659188952],
              [-38.186666665733981, -8.527499992930302],
              [-38.185833333241703, -8.527499992930302],
              [-38.185833332342384, -8.529166658814233],
              [-38.188333332517232, -8.529166658814233],
              [-38.188333331617912, -8.530833325597428],
              [-38.189166665908886, -8.530833325597428],
              [-38.189166666808205, -8.533333325772333],
              [-38.189166667707525, -8.53416665916393],
              [-38.191666666083734, -8.53416665916393],
              [-38.191666666983053, -8.534999992555584],
              [-38.192499999475331, -8.534999993454903],
              [-38.192499999475331, -8.535833325947181],
              [-38.193333331068345, -8.5358333268465],
              [-38.193333331967665, -8.536666658439515],
              [-38.194166665359262, -8.536666660238154],
              [-38.195000001448875, -8.536666658439515],
              [-38.194999999650236, -8.537499992730432],
              [-38.197499999825084, -8.537499992730432],
              [-38.197499998925764, -8.539166658614363],
              [-38.198333333216738, -8.539166658614363],
              [-38.198333331418041, -8.539999993804599],
              [-38.199999999100612, -8.53999999290528],
              [-38.2, -8.540833325397614],
              [-38.204166666058768, -8.540833325397614],
              [-38.204166666958088, -8.541666658789211],
              [-38.204999999450365, -8.541666659688531],
              [-38.205000000349685, -8.542499993080185],
              [-38.205833332842019, -8.542499993080185],
              [-38.205833332842019, -8.543333325572462],
              [-38.208333331218228, -8.543333325572462],
              [-38.208333333916187, -8.542499993080185],
              [-38.210833331393076, -8.542499993979504],
              [-38.210833334091035, -8.540833325397614],
              [-38.211666668382009, -8.540833325397614],
              [-38.211666667482689, -8.539166658614363],
              [-38.210833331393076, -8.539166659513683],
              [-38.210833331393076, -8.537499992730432],
              [-38.211666665684049, -8.537499991831112],
              [-38.211666666583369, -8.536666658439515],
              [-38.212499997277007, -8.536666660238154],
              [-38.212499997277007, -8.535833325947181],
              [-38.2133333324673, -8.535833325947181],
              [-38.213333331567981, -8.534999992555584],
              [-38.214166665858897, -8.534999992555584],
              [-38.214166665858897, -8.53416665916393],
              [-38.215000001049191, -8.53416665916393],
              [-38.215000001049191, -8.533333325772333],
              [-38.215833333541468, -8.533333325772333],
              [-38.215833333541468, -8.532499991481359],
              [-38.216666666933065, -8.532499994179318],
              [-38.216666666033746, -8.52999999310515],
              [-38.217500000324719, -8.52999999220583],
              [-38.217499999425399, -8.530833325597428],
              [-38.218333332816997, -8.530833325597428],
              [-38.218333333716316, -8.534999991656264],
              [-38.219166665309331, -8.534999992555584],
              [-38.21916666620865, -8.535833325947181],
              [-38.218333331917677, -8.535833325947181],
              [-38.218333333716316, -8.537499993629751],
              [-38.21916666620865, -8.537499992730432],
              [-38.21916666620865, -8.538333326122029],
              [-38.219999998700928, -8.538333326122029],
              [-38.219999999600248, -8.539166659513683],
              [-38.220833332092582, -8.539166658614363],
              [-38.220833333891221, -8.53999999290528],
              [-38.221666669081458, -8.53999999290528],
              [-38.221666665484179, -8.540833325397614],
              [-38.222499998875833, -8.540833325397614],
              [-38.222499998875833, -8.541666658789211],
              [-38.22333333136811, -8.541666658789211],
              [-38.223333334066069, -8.542499993080185],
              [-38.226666665833932, -8.542499993080185],
              [-38.226666666733252, -8.543333325572462],
              [-38.228333331717863, -8.543333324673142],
              [-38.228333332617183, -8.544166658964116],
              [-38.2291666669081, -8.544166658064796],
              [-38.2291666669081, -8.544999991456393],
              [-38.229999999400434, -8.544999992355713],
              [-38.230000000299754, -8.545833325747367],
              [-38.231666665284308, -8.545833325747367],
              [-38.231666664384989, -8.544999992355713],
              [-38.233333332067559, -8.544999992355713],
              [-38.233333333866199, -8.544166658964116],
              [-38.23500000064945, -8.544166658964116],
              [-38.23500000064945, -8.543333325572462],
              [-38.235833330443825, -8.543333325572462],
              [-38.235833332242464, -8.542499993080185],
              [-38.236666665634061, -8.542499993080185],
              [-38.236666665634061, -8.541666658789211],
              [-38.237499999925035, -8.541666658789211],
              [-38.237499999025715, -8.540833325397614],
              [-38.238333332417312, -8.540833325397614],
              [-38.238333331517993, -8.539166658614363],
              [-38.240000000999203, -8.539166658614363],
              [-38.240000000099883, -8.538333325222709],
              [-38.241666666883134, -8.538333327021405],
              [-38.241666665983814, -8.537499993629751],
              [-38.242499999375411, -8.537499992730432],
              [-38.242500000274731, -8.540833325397614],
              [-38.241666665983814, -8.540833325397614],
              [-38.241666666883134, -8.549166660213132],
              [-38.242500000274731, -8.549166658414492],
              [-38.242500000274731, -8.550833326097063],
              [-38.243333331867746, -8.550833326097063],
              [-38.243333332767065, -8.554166658764245],
              [-38.243333333666385, -8.555833325547496],
              [-38.244166667057982, -8.555833325547496],
              [-38.244166667957302, -8.556666658939093],
              [-38.245833332941913, -8.556666658939093],
              [-38.245833332941913, -8.557499994129387],
              [-38.248333332217442, -8.557499991431428],
              [-38.248333330418802, -8.556666658939093],
              [-38.249166665609096, -8.556666659838413],
              [-38.249166666508415, -8.555833325547496],
              [-38.250000000799332, -8.555833325547496],
              [-38.249999998101373, -8.554166658764245],
              [-38.252499998276278, -8.554166658764245],
              [-38.252499998276278, -8.553333324473272],
              [-38.254166666858168, -8.553333325372648],
              [-38.254166667757488, -8.552499993779634],
              [-38.254999998451126, -8.552499992880314],
              [-38.254999998451126, -8.551666658589397],
              [-38.256666665234377, -8.551666658589397],
              [-38.256666667932336, -8.549999992705466],
              [-38.258333333816267, -8.549999992705466],
              [-38.258333333816267, -8.548333325922215],
              [-38.260000001498838, -8.548333325922215],
              [-38.259999999700199, -8.544999992355713],
              [-38.260833333991116, -8.544999992355713],
              [-38.260833331293156, -8.544166658964116],
              [-38.262499999875047, -8.544166658964116],
              [-38.262499998975727, -8.542499992180865],
              [-38.263333333266701, -8.542499993080185],
              [-38.263333331468004, -8.540833325397614],
              [-38.265000000949215, -8.540833325397614],
              [-38.264999998251255, -8.539166659513683],
              [-38.269166667008051, -8.539166657715043],
              [-38.269166667008051, -8.530833325597428],
              [-38.268333333616397, -8.530833325597428],
              [-38.268333333616397, -8.52999999310515],
              [-38.266666665933826, -8.52999999310515],
              [-38.266666666833146, -8.529166658814233],
              [-38.26749999932548, -8.529166658814233],
              [-38.26749999842616, -8.52416665846448],
              [-38.268333331817757, -8.52416665846448],
              [-38.268333331817757, -8.523333325972146],
              [-38.269999998601008, -8.523333325972146],
              [-38.269999999500328, -8.522499992580549],
              [-38.268333333616397, -8.522499993479869],
              [-38.268333331817757, -8.519166658114727],
              [-38.26749999932548, -8.519166659014047],
              [-38.26749999932548, -8.51833332562245],
              [-38.266666666833146, -8.51833332472313],
              [-38.266666666833146, -8.516666658839199],
              [-38.265833332542229, -8.516666659738519],
              [-38.265833333441549, -8.514999992955268],
              [-38.266666667732466, -8.514999992955268],
              [-38.266666665933826, -8.514166658664294],
              [-38.26749999842616, -8.514166658664294],
              [-38.26749999932548, -8.510833325997169],
              [-38.268333332717077, -8.510833326896488],
              [-38.268333332717077, -8.509166659213918],
              [-38.269166667008051, -8.509166659213918],
              [-38.269166665209411, -8.507499994229306],
              [-38.269999999500328, -8.507499992430667],
              [-38.270000000399648, -8.505833325647416],
              [-38.275833333241678, -8.505833324748096],
              [-38.275833331443039, -8.503333325472568],
              [-38.274999999850081, -8.503333325472568],
              [-38.274999999850081, -8.502499992980233],
              [-38.274166666458427, -8.502499992980233],
              [-38.274166666458427, -8.499999993704705],
              [-38.274999999850081, -8.499999992805385],
              [-38.274999999850081, -8.498333326022134],
              [-38.275833331443039, -8.498333326022134],
              [-38.275833331443039, -8.494999992455632],
              [-38.278333331617944, -8.494999992455632],
              [-38.278333333416583, -8.494166659064035],
              [-38.279166665908861, -8.494166659064035],
              [-38.279166665908861, -8.492499993180104],
              [-38.282500000374682, -8.492499993180104],
              [-38.282499999475363, -8.49166665888913],
              [-38.285833331243225, -8.491666657989811],
              [-38.285833333941184, -8.490833325497533],
              [-38.286666665534142, -8.490833325497533],
              [-38.286666665534142, -8.489999993005199],
              [-38.288333332317393, -8.489999992105879],
              [-38.288333331418073, -8.490833325497533],
              [-38.289999999100644, -8.490833324598213],
              [-38.289999999100644, -8.492499993180104],
              [-38.290833331592921, -8.492499991381464],
              [-38.290833331592921, -8.49166665888913],
              [-38.293333331767826, -8.49166665888913],
              [-38.294999998551077, -8.491666657989811],
              [-38.294999999450397, -8.489999993005199],
              [-38.297499998725925, -8.489999993005199],
              [-38.297499998725925, -8.490833325497533],
              [-38.300000000699413, -8.490833325497533],
              [-38.299999999800093, -8.491666657989811],
              [-38.301666665684024, -8.49166665888913],
              [-38.301666666583344, -8.492499994079424],
              [-38.306666666933097, -8.492499993180104],
              [-38.306666667832417, -8.493333324773062],
              [-38.310833333891196, -8.493333324773062],
              [-38.310833333891196, -8.494166659064035],
              [-38.313333331368085, -8.494166659064035],
              [-38.313333332267405, -8.490833325497533],
              [-38.314166667457698, -8.490833325497533],
              [-38.314166666558378, -8.489999993005199],
              [-38.314999999050656, -8.489999993005199],
              [-38.315000000849295, -8.488333326222005],
              [-38.317499998326241, -8.488333324423309],
              [-38.317499999225561, -8.487499991931031],
              [-38.319166667807451, -8.487499992830351],
              [-38.319166666008812, -8.486666658539434],
              [-38.320000000299729, -8.486666658539434],
              [-38.319999999400409, -8.4858333260471],
              [-38.320833332792006, -8.4858333260471],
              [-38.320833331892686, -8.484999992655503],
              [-38.323333332067591, -8.484999992655503],
              [-38.323333332067591, -8.480833324798027],
              [-38.324166667257828, -8.480833326596724],
              [-38.324166665459188, -8.479166658914153],
              [-38.325833334041079, -8.479166658914153],
              [-38.325833332242439, -8.478333325522499],
              [-38.326666666533413, -8.478333325522499],
              [-38.326666667432733, -8.476666658739248],
              [-38.328333331517968, -8.476666658739248],
              [-38.328333333316664, -8.475833325347651],
              [-38.329166666708261, -8.475833325347651],
              [-38.3291666685069, -8.474999992855317],
              [-38.330000000099858, -8.474999992855317],
              [-38.330000000099858, -8.473333326971442],
              [-38.330833331692872, -8.473333326072066],
              [-38.330833332592192, -8.471666658389552],
              [-38.331666667782429, -8.471666660188191],
              [-38.331666666883109, -8.470833325897217],
              [-38.332500000274763, -8.470833325897217],
              [-38.332499998476123, -8.46999999250562],
              [-38.333333331867721, -8.469999991606301],
              [-38.33333333276704, -8.469166660013286],
              [-38.335833332941945, -8.469166659113966],
              [-38.335833333841265, -8.468333325722369],
              [-38.338227880412319, -8.468333332916927],
              [-38.336961322007369, -8.473144947781066],
              [-38.334869021388158, -8.478485947668275],
              [-38.332846171813344, -8.480923678784677],
              [-38.331496957618469, -8.482563899204536],
              [-38.330017946970656, -8.485178012555195],
              [-38.329664124000089, -8.485943495695722],
              [-38.329856641870492, -8.485991764108576],
              [-38.330047804462538, -8.486821496614709],
              [-38.329851606566365, -8.488895123710961],
              [-38.329095965606257, -8.490330130531049],
              [-38.328420488414793, -8.491636840967374],
              [-38.328419905654073, -8.49197149488856],
              [-38.328851969642642, -8.492195644613275],
              [-38.329365386303493, -8.492133215475462],
              [-38.330487813062064, -8.491895459008163],
              [-38.331770353319087, -8.491531347793398],
              [-38.331961166974224, -8.492041799389483],
              [-38.331671982775731, -8.492726896628938],
              [-38.330660607900711, -8.493890125628752],
              [-38.330322441227622, -8.494528104688243],
              [-38.329905677405065, -8.494909981110709],
              [-38.329600247853136, -8.495005330831304],
              [-38.329088066860777, -8.494876916636372],
              [-38.32839891997412, -8.494636044018193],
              [-38.327694105998148, -8.49452357750107],
              [-38.327389446265897, -8.494698520820009],
              [-38.327244548397175, -8.494953332030605],
              [-38.327163903491282, -8.495352067144154],
              [-38.327339405289251, -8.49578289996083],
              [-38.327931473058982, -8.496470419074512],
              [-38.330268838036375, -8.497671066166731],
              [-38.33153357801217, -8.498678110806111],
              [-38.331628722687356, -8.499348490640273],
              [-38.331323097982533, -8.49955509189391],
              [-38.33050693984336, -8.499202752804763],
              [-38.328873331239208, -8.4987214725179],
              [-38.327254645985079, -8.498543211599895],
              [-38.326324891780303, -8.498732448743453],
              [-38.32593947023031, -8.499370341468023],
              [-38.325953382742398, -8.50024770566705],
              [-38.325985392311964, -8.500646635034116],
              [-38.326224745075876, -8.500933766979699],
              [-38.32638535769803, -8.501046200221936],
              [-38.326480615687842, -8.501125958396244],
              [-38.326513015563194, -8.501301482677263],
              [-38.326464612252039, -8.501445210528004],
              [-38.326288024072994, -8.501636653708545],
              [-38.326271335353795, -8.501827469162322],
              [-38.326559578861406, -8.502211464387528],
              [-38.327039526353019, -8.502594887643909],
              [-38.327423392975163, -8.50285061256568],
              [-38.327679181748806, -8.50309074144451],
              [-38.32799950497315, -8.503314699613668],
              [-38.328191614551315, -8.503601749720929],
              [-38.328335516870538, -8.503921278843904],
              [-38.328526607516892, -8.504272545041886],
              [-38.32871880073202, -8.504512561505464],
              [-38.329054460095222, -8.504799859825596],
              [-38.329582481746115, -8.505231299684681],
              [-38.329887002982787, -8.505663258452557],
              [-38.330062345600709, -8.506189966193915],
              [-38.330108398983384, -8.506875637200835],
              [-38.329820250803891, -8.506954733474117],
              [-38.329484145376966, -8.506922499074051],
              [-38.329195504369011, -8.50676281365287],
              [-38.328731322892168, -8.506714075793923],
              [-38.328490419697061, -8.506793253905471],
              [-38.328425078554517, -8.50727160779843],
              [-38.328713494731971, -8.507559728998274],
              [-38.329145687323546, -8.507719661732949],
              [-38.329594231388626, -8.50787962234665],
              [-38.329914615766882, -8.508071923480429],
              [-38.330298626280523, -8.508248053005218],
              [-38.330587378804466, -8.508344424355641],
              [-38.330875388488323, -8.50834492078144],
              [-38.331164473761419, -8.508249544081139],
              [-38.331452704678554, -8.508122507647556],
              [-38.331805747037549, -8.507820115106199],
              [-38.331998470852682, -8.507756228167352],
              [-38.332191081353301, -8.507756559117865],
              [-38.332478677349059, -8.507996737459393],
              [-38.333007259277565, -8.508108894609734],
              [-38.333567805809537, -8.508126136412045],
              [-38.333873408031309, -8.507934910867391],
              [-38.334306043089384, -8.507839777883419],
              [-38.334642040597657, -8.507936226575566],
              [-38.334817968674258, -8.508128276798516],
              [-38.334817474946476, -8.508414995055944],
              [-38.334768851301419, -8.508686253967426],
              [-38.334640278825759, -8.508957377081231],
              [-38.334527893247639, -8.509324402099423],
              [-38.334286631222994, -8.509610707568072],
              [-38.334013928000331, -8.50969074003558],
              [-38.333757743524984, -8.509674020739396],
              [-38.333373563039459, -8.509593767937986],
              [-38.332988336642359, -8.509593107835599],
              [-38.33266836055634, -8.509688434173825],
              [-38.332139196766491, -8.50991093094467],
              [-38.331610059956233, -8.510117145489801],
              [-38.331161178645459, -8.510148030906862],
              [-38.330503386621956, -8.510146898660423],
              [-38.330247284884251, -8.510083144821238],
              [-38.330151257074817, -8.509922886531967],
              [-38.330119901312344, -8.509667771350507],
              [-38.330008482505434, -8.509475830844849],
              [-38.329719839698782, -8.509316145423725],
              [-38.329094840554433, -8.509267131472882],
              [-38.328774176487002, -8.509234921354505],
              [-38.328182081737623, -8.509073806910635],
              [-38.327765474397097, -8.508834305758569],
              [-38.327381575399329, -8.508593956545838],
              [-38.327044531080219, -8.508577998076078],
              [-38.326708035347508, -8.50876825954748],
              [-38.326451153897608, -8.509151311383846],
              [-38.326370116887347, -8.50977254506688],
              [-38.326401077847436, -8.510251970052309],
              [-38.326512050590622, -8.510698067961414],
              [-38.326735485354334, -8.511257421093035],
              [-38.326799408266027, -8.511592186530152],
              [-38.326798934323335, -8.511863528179219],
              [-38.326878442486077, -8.512118727896905],
              [-38.327230237485423, -8.512534490774101],
              [-38.327277862883022, -8.512837572196133],
              [-38.327261200244152, -8.513013011041608],
              [-38.327165245279787, -8.513332124677788],
              [-38.326988593248927, -8.513555223994331],
              [-38.32689187026358, -8.51379383751555],
              [-38.326891422401161, -8.514049802556315],
              [-38.327115252866577, -8.51438484678323],
              [-38.327610435772954, -8.514896730999283],
              [-38.327978618218594, -8.51529533661045],
              [-38.32823444386446, -8.515519184162997],
              [-38.329035213704117, -8.51585612775807],
              [-38.329900884018514, -8.515968872165672],
              [-38.330189092452599, -8.515858117957748],
              [-38.330542034087557, -8.515619039487035],
              [-38.330670140714403, -8.515619260720257],
              [-38.330975140390365, -8.515779877839066],
              [-38.33135807981148, -8.516578283361014],
              [-38.331693502652968, -8.517009390470946],
              [-38.332158276782991, -8.517248970763319],
              [-38.332974831523188, -8.517394186092758],
              [-38.333646176329921, -8.518496084026992],
              [-38.334094321995337, -8.51889572565716],
              [-38.334166664360055, -8.518922261952753],
              [-38.334750807303863, -8.519136536222106],
              [-38.33499152433933, -8.519168605146945],
              [-38.335665163316605, -8.518946352092428],
              [-38.336049517371293, -8.518931632888496],
              [-38.336433817466684, -8.518947666001964],
              [-38.336738907074846, -8.519060342061096],
              [-38.337363236224348, -8.519508217929854],
              [-38.337682945211725, -8.519572076989732],
              [-38.338083684014748, -8.51954110253979],
              [-38.338084450237147, -8.51909429236764],
              [-38.337877045888888, -8.518711345751967],
              [-38.337541587074554, -8.518295619746993],
              [-38.337397590326475, -8.518024031683694],
              [-38.337366284926077, -8.517737258567593],
              [-38.337494941937962, -8.517418198890709],
              [-38.337655264079103, -8.517178785872204],
              [-38.337655729927974, -8.516907444223136],
              [-38.337448078266164, -8.516668308195847],
              [-38.337015513355198, -8.516189101745624],
              [-38.336599940235033, -8.515869113069073],
              [-38.336344191031571, -8.51559733345016],
              [-38.336200306698743, -8.515262432215422],
              [-38.336169385308835, -8.514751351093935],
              [-38.336297797705129, -8.514576102005435],
              [-38.336489695942646, -8.514465177825628],
              [-38.336698666010591, -8.514465534856527],
              [-38.337162831299565, -8.51452963943126],
              [-38.337642804871507, -8.514913955714462],
              [-38.337979223262494, -8.515297122664037],
              [-38.338666520243692, -8.516111416508636],
              [-38.338859054301849, -8.516159682223531],
              [-38.339147072079697, -8.516160171454715],
              [-38.339323660258685, -8.515969627596178],
              [-38.339533207691318, -8.515634422390633],
              [-38.339662300874465, -8.515060299693005],
              [-38.339871482283058, -8.51440671919471],
              [-38.340289196689014, -8.514008552452708],
              [-38.340545411741289, -8.514008987724537],
              [-38.340865807810758, -8.514201280764439],
              [-38.341025359232901, -8.514408676119444],
              [-38.341297517466387, -8.514648823884045],
              [-38.341441737246328, -8.514791974370098],
              [-38.341649415887787, -8.515015732889708],
              [-38.341825568794945, -8.515080248454638],
              [-38.341921548940263, -8.515272161081327],
              [-38.341937332042221, -8.515606843780745],
              [-38.341727843964804, -8.515909489031628],
              [-38.341294953499244, -8.516148441597238],
              [-38.340910411486277, -8.51627532064947],
              [-38.340637514010041, -8.516465702630001],
              [-38.340428821832631, -8.516832566669564],
              [-38.340283485094744, -8.517343349216162],
              [-38.340282965286576, -8.517646347900609],
              [-38.340570081943667, -8.518173240002966],
              [-38.341018099006021, -8.518652468936239],
              [-38.341722441737261, -8.519068816372737],
              [-38.342171278981311, -8.519069577199161],
              [-38.342508551728258, -8.518957993816286],
              [-38.342876878964773, -8.518751491488104],
              [-38.342894324013798, -8.518112959345558],
              [-38.34289549223314, -8.517427368378321],
              [-38.342944654572079, -8.516836828556734],
              [-38.343170022878212, -8.516279146365491],
              [-38.343378575660722, -8.515991875924271],
              [-38.343554948002406, -8.515928859529197],
              [-38.343747563898887, -8.515929185083792],
              [-38.343987179264843, -8.516073402165716],
              [-38.344034656274346, -8.516471451995869],
              [-38.344002231217928, -8.516838614610322],
              [-38.344049841327092, -8.517157975560053],
              [-38.344321950997198, -8.517429777662073],
              [-38.344609914815692, -8.51746191943198],
              [-38.345379664339987, -8.517351967419302],
              [-38.345763964435378, -8.517367990640196],
              [-38.346100720971435, -8.517560307062467],
              [-38.346292157856738, -8.517719817115847],
              [-38.346387791763163, -8.518118852602925],
              [-38.346338958576041, -8.51851764437373],
              [-38.346450809956877, -8.518996302237497],
              [-38.347203695394171, -8.519189316533698],
              [-38.347636014790226, -8.519285918110597],
              [-38.34792443006836, -8.519589401529629],
              [-38.348115572875372, -8.519924379206714],
              [-38.34832373445272, -8.520404101867769],
              [-38.348755625771446, -8.520755763767454],
              [-38.349139685747843, -8.520915594878772],
              [-38.349796400383525, -8.521028851000608],
              [-38.350085169994543, -8.521124304143257],
              [-38.350389221785122, -8.521316562109632],
              [-38.350709277910823, -8.521715971714684],
              [-38.351044433653669, -8.522322532958469],
              [-38.351156048512792, -8.52294499961198],
              [-38.351426773226933, -8.523504420192808],
              [-38.351666666483425, -8.523771441498752],
              [-38.352259299926686, -8.524431090622272],
              [-38.352938164864383, -8.524999994554037],
              [-38.353250994937412, -8.525262150527794],
              [-38.353333333266676, -8.525345916980427],
              [-38.354130830473423, -8.526157240364284],
              [-38.35464338288574, -8.526636561927717],
              [-38.355282927664916, -8.527243624993275],
              [-38.355651417678757, -8.527483923843988],
              [-38.356003791841488, -8.527580384227292],
              [-38.356372738710888, -8.527548434912319],
              [-38.35667746499297, -8.527341813873591],
              [-38.356758089214395, -8.526943071565427],
              [-38.356710840632729, -8.526400306428968],
              [-38.356567975231826, -8.525985817891126],
              [-38.356328001036388, -8.525506046666692],
              [-38.355752644468907, -8.524548153574358],
              [-38.355369647491216, -8.523749767837444],
              [-38.355081785296136, -8.523111631396603],
              [-38.354842934353883, -8.522504330010747],
              [-38.354651197095052, -8.521977605182315],
              [-38.354411225597516, -8.521498735977843],
              [-38.354172188495681, -8.521003589044369],
              [-38.354028579355429, -8.520493223783205],
              [-38.35412542644724, -8.520174104751106],
              [-38.354333758895791, -8.520015262894049],
              [-38.35443036137201, -8.519839954450276],
              [-38.354510771554828, -8.51956874500155],
              [-38.354527875760823, -8.519121960010409],
              [-38.354464436684339, -8.518483292070243],
              [-38.354464702883661, -8.5183241048735],
              [-38.354577554310652, -8.518212136580814],
              [-38.354802159991664, -8.518101259165746],
              [-38.355138733965362, -8.517862129433695],
              [-38.355298989556672, -8.517655270973933],
              [-38.355299682934003, -8.517241019857124],
              [-38.355284746993448, -8.51693709037437],
              [-38.354996594317356, -8.51647442335593],
              [-38.354677258548577, -8.516186268881142],
              [-38.354372272362411, -8.516010294039802],
              [-38.353939900805756, -8.515946260511441],
              [-38.353715483083022, -8.515945888192164],
              [-38.353475646483844, -8.515929208466162],
              [-38.353315017673879, -8.515817690733797],
              [-38.353267184532911, -8.51562586174407],
              [-38.353363760928801, -8.515466833727373],
              [-38.353556859761227, -8.515179530910586],
              [-38.353620860913963, -8.514940854436816],
              [-38.353557796854773, -8.514621469205395],
              [-38.35326948769665, -8.514253770594962],
              [-38.352950583602535, -8.513710553099486],
              [-38.352758772599259, -8.513231763934698],
              [-38.352646914923127, -8.512753108768891],
              [-38.352599189700811, -8.512497965708519],
              [-38.352599564718105, -8.512274560622416],
              [-38.352664151329463, -8.512226731078727],
              [-38.352792368572921, -8.512162727228031],
              [-38.352953183542525, -8.512162993427353],
              [-38.35319280160644, -8.512307205113359],
              [-38.354441298926758, -8.513314156223259],
              [-38.355882151340154, -8.513938828014432],
              [-38.356154693584187, -8.513955559901149],
              [-38.35645914467375, -8.513908126059107],
              [-38.356876522733273, -8.513701692079394],
              [-38.357117559927417, -8.513541997665016],
              [-38.357309559788291, -8.513366845703331],
              [-38.357518901276251, -8.513143785057594],
              [-38.357711061216435, -8.512872759070547],
              [-38.357759586835414, -8.512650336943466],
              [-38.357759957356109, -8.512426930958043],
              [-38.357712152993429, -8.512218821541353],
              [-38.357616296954518, -8.511947318913599],
              [-38.357424908632595, -8.511756158120136],
              [-38.357408953760171, -8.511516444728102],
              [-38.357409430400821, -8.511229724672035],
              [-38.357602499555639, -8.51095870048357],
              [-38.35795536564757, -8.510751252967907],
              [-38.358324692030862, -8.510481422179851],
              [-38.358677955623136, -8.510035192070404],
              [-38.358966499504334, -8.509716386901687],
              [-38.359111464822206, -8.509413623839635],
              [-38.359256137860427, -8.509286331099247],
              [-38.359480603247221, -8.509254138967265],
              [-38.359640324641248, -8.509366557820329],
              [-38.359719904749738, -8.509590095106773],
              [-38.359783590240454, -8.510084950659916],
              [-38.359766573268701, -8.510483799088036],
              [-38.359830552837707, -8.510802280500798],
              [-38.360021597618584, -8.511201471570587],
              [-38.36011747973788, -8.511457596690661],
              [-38.360196242362633, -8.512174979591578],
              [-38.359955292402788, -8.512829427036365],
              [-38.359633510478091, -8.513466554437912],
              [-38.359439779422246, -8.514136455832727],
              [-38.359519310967357, -8.514391650154494],
              [-38.359711608503858, -8.514583716565198],
              [-38.360095853740631, -8.514632287150278],
              [-38.360384724975006, -8.514664419926987],
              [-38.360577341770863, -8.514664735589008],
              [-38.360720736872452, -8.514760847035404],
              [-38.360768493571072, -8.514999707870174],
              [-38.36068822188389, -8.515191325519197],
              [-38.360479840871903, -8.515382733626211],
              [-38.360158903410593, -8.51550973588553],
              [-38.359533622778429, -8.515619957694867],
              [-38.359293600019555, -8.515715436917844],
              [-38.359084388933354, -8.515858903965182],
              [-38.358844181813481, -8.516065633821881],
              [-38.358699134657286, -8.516416332547635],
              [-38.358698655318619, -8.516703956422418],
              [-38.358794458297552, -8.517007115186175],
              [-38.359033711236748, -8.517374728361006],
              [-38.359322028488805, -8.517741518656123],
              [-38.359513339469004, -8.51798152073053],
              [-38.359705561462476, -8.518220620784973],
              [-38.359721197974864, -8.518652084026428],
              [-38.359656212963841, -8.518938697063163],
              [-38.359367502708039, -8.519353377156563],
              [-38.359029515899408, -8.5198955073717],
              [-38.358900636754925, -8.520357483710882],
              [-38.35885144473832, -8.52097968416507],
              [-38.358946771975923, -8.521570466803553],
              [-38.359138701689687, -8.521985034481702],
              [-38.359506305871264, -8.522209047509477],
              [-38.360083151716026, -8.522274216881556],
              [-38.360340680677837, -8.522034954049843],
              [-38.360484633359079, -8.521796407977774],
              [-38.360709373039072, -8.521605028649105],
              [-38.361158294819404, -8.521557829530138],
              [-38.36171977304906, -8.521575033560907],
              [-38.362056083521395, -8.52149508742832],
              [-38.362297253815143, -8.521256700536242],
              [-38.362634196510896, -8.520794161221488],
              [-38.362778871347814, -8.520666865783198],
              [-38.362875262483328, -8.520619085702208],
              [-38.363018843744612, -8.520603944716242],
              [-38.363163204718091, -8.520667496207921],
              [-38.363323705824314, -8.520859508659328],
              [-38.36335503190918, -8.52114628087611],
              [-38.363257796310222, -8.52170508991793],
              [-38.363064411493326, -8.522166962835001],
              [-38.363031872223075, -8.522613721745813],
              [-38.363255817801701, -8.522901714342595],
              [-38.363640070233032, -8.522950282229715],
              [-38.364121084320516, -8.522727663151102],
              [-38.364458434409187, -8.522568121621475],
              [-38.364795244904599, -8.522186077925085],
              [-38.365181115216274, -8.521804112469795],
              [-38.365566316432307, -8.521278334627368],
              [-38.365726648466023, -8.521022627692048],
              [-38.365855127412146, -8.52079943214801],
              [-38.365999828329393, -8.52065676010136],
              [-38.366144291825549, -8.520656995723755],
              [-38.3663367017773, -8.520784840647821],
              [-38.366448064826272, -8.521023805803964],
              [-38.366527573888391, -8.521295279653373],
              [-38.366526837343599, -8.521742091624162],
              [-38.366590771946505, -8.522093134790282],
              [-38.366590351063792, -8.522348197811027],
              [-38.366493329503498, -8.522779477590746],
              [-38.366332579285086, -8.523289341929512],
              [-38.366235742985111, -8.523608465458267],
              [-38.366107158818295, -8.523894975972325],
              [-38.366042122545878, -8.524214151661738],
              [-38.365945286245903, -8.524533275190436],
              [-38.365720521384901, -8.5247400347248],
              [-38.365239269876383, -8.525106467089756],
              [-38.36488605125021, -8.525520141741083],
              [-38.364757464385434, -8.525807556073801],
              [-38.36466062628682, -8.526126678703235],
              [-38.364595971326992, -8.526764231484037],
              [-38.364658334814237, -8.527514147359966],
              [-38.364657386928798, -8.528088492190136],
              [-38.364496785997858, -8.528503384523503],
              [-38.364223178057159, -8.529125218953652],
              [-38.363966107749604, -8.529634925011749],
              [-38.363724401459933, -8.530193496632535],
              [-38.363354450947099, -8.530830547591677],
              [-38.363194007397453, -8.531149565899682],
              [-38.363016945275774, -8.531627746223478],
              [-38.362951824467075, -8.531994857576592],
              [-38.362999136900612, -8.532505061859069],
              [-38.36309486703442, -8.532856158085167],
              [-38.363398538411786, -8.533288092571354],
              [-38.36392706188434, -8.533464428940192],
              [-38.364728327250475, -8.533545336448071],
              [-38.365256929863392, -8.533673733555929],
              [-38.365705468532497, -8.533866216352749],
              [-38.365945806953391, -8.534137049884919],
              [-38.366217915724178, -8.534425118924048],
              [-38.366457394393194, -8.534665197440859],
              [-38.366842255665517, -8.534904608660725],
              [-38.367050278747342, -8.534936604740494],
              [-38.367226575545999, -8.534921516814507],
              [-38.367611158028467, -8.534778332154247],
              [-38.36785233371819, -8.534539036946967],
              [-38.36799795823913, -8.53383739757777],
              [-38.367902930475793, -8.533055771606541],
              [-38.367807772310812, -8.532353740132976],
              [-38.367633468409792, -8.531156827925201],
              [-38.367682544413867, -8.530598842662414],
              [-38.368004253493439, -8.530008741709992],
              [-38.368309421342701, -8.529530768230302],
              [-38.368502649677509, -8.529163862821974],
              [-38.368599023725949, -8.528573394046873],
              [-38.368599834914392, -8.528079548432345],
              [-38.368825489204937, -8.527330102002566],
              [-38.369018505299721, -8.527090726755603],
              [-38.369323591310661, -8.526660689838934],
              [-38.369613023722025, -8.526358158801941],
              [-38.369756817223276, -8.526214581137936],
              [-38.369901363457132, -8.52616687749935],
              [-38.370077630578123, -8.526167162584443],
              [-38.370238218019324, -8.526311236674189],
              [-38.370333123474836, -8.526614391840667],
              [-38.370332419305669, -8.527044922485118],
              [-38.370266309242709, -8.528576102804834],
              [-38.370265342471555, -8.529166728062023],
              [-38.370312296974873, -8.529901243047902],
              [-38.370471532734996, -8.530315753169418],
              [-38.370791984562402, -8.530491742399931],
              [-38.371480579265267, -8.530572452956278],
              [-38.371962454004006, -8.530382387537088],
              [-38.372347186673267, -8.530143321656908],
              [-38.372827996614717, -8.530048224645782],
              [-38.373164050780304, -8.530128363233302],
              [-38.373549117997356, -8.530241140016528],
              [-38.373916636743331, -8.530528452725889],
              [-38.374221305468836, -8.53091244435376],
              [-38.374492383616541, -8.531279197776712],
              [-38.374700767326487, -8.53164675249559],
              [-38.374780049759465, -8.532062037832759],
              [-38.374875481318384, -8.532603975592963],
              [-38.374874780746495, -8.533035412754032],
              [-38.374793706864011, -8.533720879614862],
              [-38.374776858964822, -8.534023854017619],
              [-38.374840980626686, -8.534263644751377],
              [-38.375032309593394, -8.534503640530545],
              [-38.375160190490362, -8.534646755942958],
              [-38.375417225724334, -8.534711386621154],
              [-38.37583333304184, -8.534668979090043],
              [-38.376186048047657, -8.534633029590623],
              [-38.376619042834534, -8.534329820464791],
              [-38.376812470818834, -8.533836284217102],
              [-38.376861765358115, -8.533134485667915],
              [-38.377151765241706, -8.532480105672278],
              [-38.377344516935864, -8.531842756138246],
              [-38.377297316917577, -8.53125205264007],
              [-38.376849471625746, -8.530629049990637],
              [-38.376610270847266, -8.530214413064641],
              [-38.376482570714018, -8.529958238481868],
              [-38.376418447253513, -8.529719352466088],
              [-38.376418731439287, -8.529543883943063],
              [-38.376435242992045, -8.52944803509871],
              [-38.376531787911631, -8.529304377395079],
              [-38.376627760862448, -8.528953593233666],
              [-38.376516776428048, -8.528474943463834],
              [-38.376181474095688, -8.527931715176464],
              [-38.375861408976789, -8.527516947848824],
              [-38.375557520862856, -8.527213458134554],
              [-38.375413441377191, -8.526973537898414],
              [-38.375462114484947, -8.526654333430713],
              [-38.375574132240388, -8.526495325199051],
              [-38.375750529763081, -8.526416014887104],
              [-38.375943152854177, -8.526416323354567],
              [-38.376375490236626, -8.52651198963656],
              [-38.376727666548504, -8.526735963094154],
              [-38.377207927903498, -8.526976422024234],
              [-38.377784554313678, -8.52718447298497],
              [-38.378360794914727, -8.527632210358149],
              [-38.379081065538685, -8.528351523204208],
              [-38.379736906932692, -8.529022793367176],
              [-38.380457567862436, -8.529502414404874],
              [-38.381482950577663, -8.529775395518016],
              [-38.381915242094692, -8.529903616358752],
              [-38.382572165373062, -8.529904663169646],
              [-38.382909233973919, -8.529921479592588],
              [-38.383213771398403, -8.529826088503114],
              [-38.383502965489413, -8.529666453444008],
              [-38.383583205700347, -8.529491112624726],
              [-38.383695245938782, -8.529315819469446],
              [-38.383919849821154, -8.529204925866566],
              [-38.384352550529741, -8.529078080089278],
              [-38.384658226495901, -8.528838876612838],
              [-38.384802244827711, -8.528552384085231],
              [-38.384802755642625, -8.528233103175126],
              [-38.384595399857801, -8.527785958455183],
              [-38.384259650562399, -8.527514080810136],
              [-38.38358701432918, -8.527114136108423],
              [-38.382242182530661, -8.52604289876075],
              [-38.381106310008136, -8.524988270991855],
              [-38.37985759864921, -8.524044712193643],
              [-38.379120411777535, -8.523677217729357],
              [-38.378255996916664, -8.523324896626605],
              [-38.377055348925182, -8.522445623166902],
              [-38.375726650964111, -8.52153358110985],
              [-38.374429806989951, -8.520589928782158],
              [-38.373357366349353, -8.519662914811647],
              [-38.372973736248866, -8.519231761836295],
              [-38.372829636978111, -8.519008122027174],
              [-38.372734963547714, -8.518561155372993],
              [-38.372735300793465, -8.518354029814589],
              [-38.372879114979128, -8.518195072844264],
              [-38.373023631535318, -8.518162744914719],
              [-38.37318354268632, -8.518163003020106],
              [-38.373889092307707, -8.518420108401244],
              [-38.374369266428459, -8.518707602773588],
              [-38.374817637823696, -8.518995949703253],
              [-38.375474205869864, -8.519204132864388],
              [-38.375922528701722, -8.519524135030736],
              [-38.376434194382512, -8.52000342781588],
              [-38.376626504509545, -8.520194583213424],
              [-38.376866112680887, -8.520355060937277],
              [-38.377203067967173, -8.520435195028199],
              [-38.37739483040707, -8.520403846460283],
              [-38.377587556920162, -8.520339937038443],
              [-38.377877028002388, -8.520005741771513],
              [-38.378085634744195, -8.519670513183598],
              [-38.378310362733032, -8.519480025982375],
              [-38.378566737864617, -8.519384562047833],
              [-38.378887494562207, -8.519368794234424],
              [-38.379031958957682, -8.519369024460843],
              [-38.379191766686631, -8.519433498657008],
              [-38.379463724371362, -8.519816528909644],
              [-38.379511414520096, -8.520104231025414],
              [-38.379511004429276, -8.520359294945479],
              [-38.379606853273629, -8.520646168785618],
              [-38.379943054927992, -8.520631329072557],
              [-38.380103273647137, -8.520439834630622],
              [-38.380104226029175, -8.51984920847417],
              [-38.380089139901827, -8.519625776408418],
              [-38.380185653345166, -8.519498400031068],
              [-38.380329465732189, -8.519339439463522],
              [-38.380586750078407, -8.51924397373034],
              [-38.38090662274243, -8.519212826610612],
              [-38.381050958534843, -8.519292652234071],
              [-38.38153154994103, -8.519325073693153],
              [-38.381980516687463, -8.519246194156494],
              [-38.382317780441156, -8.519135478619376],
              [-38.382637678286187, -8.519088050173309],
              [-38.382878427697221, -8.51910380899352],
              [-38.383070817863938, -8.519247927150047],
              [-38.383165887895359, -8.519455204693884],
              [-38.383229705586416, -8.519886744377686],
              [-38.383228939364017, -8.520365215182494],
              [-38.383163917480772, -8.520684394469185],
              [-38.383163508289215, -8.52093945838925],
              [-38.383259332851878, -8.521242612656408],
              [-38.383499777392785, -8.521450122225303],
              [-38.384332697995546, -8.521611536143439],
              [-38.385005707447419, -8.52177179173475],
              [-38.386046393026561, -8.522124378137505],
              [-38.386959469304031, -8.522731823414858],
              [-38.387487582685765, -8.523164096945493],
              [-38.388128163483941, -8.523723173985218],
              [-38.388832397397152, -8.524250692915075],
              [-38.389552458479159, -8.524539451734256],
              [-38.389857773817141, -8.524523649746584],
              [-38.390097894602093, -8.524364838466454],
              [-38.390242994818323, -8.523966188788563],
              [-38.390275679778767, -8.523407269130132],
              [-38.390276742777417, -8.522737046677321],
              [-38.390149843940094, -8.521971653468995],
              [-38.39005446723985, -8.521380874427791],
              [-38.39013490350294, -8.521077999849751],
              [-38.390199996432671, -8.52071088040276],
              [-38.390441149639287, -8.520472474624967],
              [-38.390664813730154, -8.520376950435889],
              [-38.390825634994997, -8.520377203145358],
              [-38.391194319262354, -8.520505313369483],
              [-38.391641824610474, -8.520777360087038],
              [-38.392122798228513, -8.521144430071331],
              [-38.392778826681479, -8.521704428016903],
              [-38.393306996720469, -8.522104129901606],
              [-38.394027591100382, -8.522631665918595],
              [-38.394411624996451, -8.522824015615754],
              [-38.394795759616557, -8.52295214652429],
              [-38.395276404982042, -8.522952895659557],
              [-38.39582171340129, -8.52285786969486],
              [-38.396014635966651, -8.522666418420442],
              [-38.396159627364852, -8.522331983933839],
              [-38.396208708764846, -8.521741430622455],
              [-38.396113900436092, -8.520784337926784],
              [-38.395858846408601, -8.519459774149198],
              [-38.395731863934316, -8.518741414584554],
              [-38.395732312696055, -8.518454693629167],
              [-38.395813403665613, -8.51831100714719],
              [-38.39600518948788, -8.518263366461156],
              [-38.396437724721181, -8.518232382118697],
              [-38.396822714596567, -8.518392170062555],
              [-38.397414287739139, -8.518919497436798],
              [-38.398183361872611, -8.519239973545893],
              [-38.399032590781417, -8.519432134385454],
              [-38.399850268774856, -8.519465959686272],
              [-38.400635287888178, -8.519467171972337],
              [-38.401163689052964, -8.5197230515775],
              [-38.40138729288924, -8.520249808781614],
              [-38.401241609912347, -8.521031059735549],
              [-38.400904214857576, -8.521812920429909],
              [-38.400662294529241, -8.522546085533349],
              [-38.400612896567907, -8.523343767101096],
              [-38.400833332991908, -8.524122608771336],
              [-38.400852359049111, -8.524189830396324],
              [-38.401043773451363, -8.524381877921257],
              [-38.40125267876806, -8.524429232622936],
              [-38.40168517173322, -8.52442989991988],
              [-38.402246048316442, -8.524255293846693],
              [-38.402888114794621, -8.523873682724229],
              [-38.40327264871371, -8.523746741618766],
              [-38.403706222664027, -8.523636155584086],
              [-38.404315279526486, -8.523445340130309],
              [-38.405100603509993, -8.523254791775173],
              [-38.405469568365845, -8.5232074190871],
              [-38.405757593338194, -8.523207859754905],
              [-38.40604642859978, -8.523272520110652],
              [-38.406190772486127, -8.523352335841594],
              [-38.406334134312829, -8.5234800881355],
              [-38.406350292432023, -8.52360764617589],
              [-38.406333665765999, -8.523783089517906],
              [-38.406141682092937, -8.523958267559976],
              [-38.405707860829068, -8.524228950005977],
              [-38.405098482009294, -8.524626894615437],
              [-38.404648894730656, -8.525104680136849],
              [-38.404424147856105, -8.525312367771505],
              [-38.404231741501633, -8.525757983643985],
              [-38.404214742516331, -8.526173117895041],
              [-38.404214223607482, -8.526508681030748],
              [-38.404357779687757, -8.527098626400459],
              [-38.404597034425535, -8.527497873228242],
              [-38.404901144672067, -8.527673809398721],
              [-38.405173530434013, -8.527801759543536],
              [-38.40543029497212, -8.528041842556945],
              [-38.405669774540399, -8.528297275199066],
              [-38.405813527572207, -8.528760591528055],
              [-38.405844788006505, -8.529111579835501],
              [-38.405892450276326, -8.529430032469975],
              [-38.406324062805197, -8.53000504279845],
              [-38.406708626401951, -8.530452447422476],
              [-38.406964214626782, -8.530867998059591],
              [-38.407203548504924, -8.531219303827754],
              [-38.407427410446587, -8.531586867539886],
              [-38.407667382843385, -8.532113647226993],
              [-38.40777860200086, -8.532464756942943],
              [-38.407907208650727, -8.532736299140822],
              [-38.4080187965302, -8.532847721545068],
              [-38.408243153098965, -8.532896001649135],
              [-38.40874016702503, -8.5328967597776],
              [-38.409012631927396, -8.532976769762058],
              [-38.409107889917152, -8.533072790376934],
              [-38.40915567809202, -8.533312551433028],
              [-38.409154770676082, -8.533902276468837],
              [-38.409202337617728, -8.534285851710649],
              [-38.409346099642732, -8.53474826422098],
              [-38.409505625883867, -8.535003571857317],
              [-38.409874109602413, -8.535275479179973],
              [-38.410034717728024, -8.535419537081907],
              [-38.410177862818045, -8.535691100863573],
              [-38.410273490429176, -8.536138063021156],
              [-38.410320546555909, -8.536856297580016],
              [-38.410448222407467, -8.537143213688296],
              [-38.41088038891877, -8.537367279776106],
              [-38.411425445527868, -8.53744770165008],
              [-38.411762572584621, -8.537432837655274],
              [-38.412275309357995, -8.537257239629866],
              [-38.412499999575289, -8.537128169829145],
              [-38.412579969989565, -8.53708223066036],
              [-38.413061268262879, -8.53666870439713],
              [-38.413495927694839, -8.535855322465125],
              [-38.41383352959366, -8.534930542410564],
              [-38.41429905465759, -8.534133486770941],
              [-38.414652923493577, -8.533862675721878],
              [-38.415165580227324, -8.533735915380134],
              [-38.415998594359621, -8.533864703693098],
              [-38.41691203845977, -8.534281238188555],
              [-38.417808150825465, -8.534745683166705],
              [-38.418641714443538, -8.535113250476059],
              [-38.419346689398139, -8.535193902576509],
              [-38.419827377031766, -8.535179245425809],
              [-38.420180166681291, -8.535019677815853],
              [-38.42042126502929, -8.534812911086931],
              [-38.420838402070558, -8.534159588694024],
              [-38.421127945098533, -8.533761140464208],
              [-38.42156109726676, -8.533331250137053],
              [-38.422106657496215, -8.533076093586828],
              [-38.422732028959899, -8.532917834490434],
              [-38.42324492221536, -8.532631873462151],
              [-38.423324677691653, -8.532499999575293],
              [-38.423389751735613, -8.532392400189053],
              [-38.423438483299321, -8.532009872657454],
              [-38.423438723418315, -8.531849778944093],
              [-38.42326276026813, -8.531642388985006],
              [-38.42291038520608, -8.531529708429275],
              [-38.422428840518535, -8.531513615960534],
              [-38.421483684524389, -8.531639739582261],
              [-38.420762144057676, -8.531702881882438],
              [-38.419977193292766, -8.531637490377818],
              [-38.419207763927147, -8.531524183893907],
              [-38.418342211423862, -8.531284100880498],
              [-38.417861914995342, -8.531043691413174],
              [-38.417333414005782, -8.530835770854083],
              [-38.416389323708302, -8.530260000598503],
              [-38.415796702855516, -8.52979601067733],
              [-38.415556353642785, -8.529508926395806],
              [-38.415509047504486, -8.528950785550251],
              [-38.415605917978667, -8.5285837083714],
              [-38.415798809967043, -8.528408528530747],
              [-38.416055182400726, -8.528313038515876],
              [-38.416664266242833, -8.528106826668647],
              [-38.416953639298981, -8.527819633569209],
              [-38.417194263704289, -8.52732524116692],
              [-38.417018886912047, -8.526735249931789],
              [-38.416731608377006, -8.526240062528814],
              [-38.415915098602852, -8.525424795617766],
              [-38.414986761729608, -8.524658200915212],
              [-38.414186662784232, -8.523811297653197],
              [-38.413851403619333, -8.523204784972791],
              [-38.413787670464558, -8.522693652589965],
              [-38.413804317814993, -8.522502830840949],
              [-38.413980853833323, -8.522326722000571],
              [-38.414188945263561, -8.522311660154969],
              [-38.414461378689623, -8.522407946069791],
              [-38.414717239409015, -8.522647117170663],
              [-38.415422637045026, -8.523031682566057],
              [-38.415951465387764, -8.523016198039045],
              [-38.416336431880779, -8.52260161597178],
              [-38.416530191714912, -8.521852085005662],
              [-38.416626125994867, -8.521501287354454],
              [-38.416819232021908, -8.521182292428819],
              [-38.417156875289493, -8.520816481495388],
              [-38.417445188944271, -8.520625161521934],
              [-38.417798059532799, -8.520402282539237],
              [-38.417942791926237, -8.520227028954196],
              [-38.418071627903259, -8.519748747007043],
              [-38.418231850219684, -8.519541858869673],
              [-38.418456514356592, -8.519382100603423],
              [-38.41874563830055, -8.519254999418649],
              [-38.419194555584227, -8.519207733749852],
              [-38.419562678674652, -8.519112407411626],
              [-38.419996267014085, -8.518985522963476],
              [-38.420380648048422, -8.518954438796243],
              [-38.420973957781882, -8.518955323729188],
              [-38.421341574553992, -8.519195561425988],
              [-38.421598107966247, -8.519594823542207],
              [-38.421965487317323, -8.51999425023439],
              [-38.422078082437508, -8.520041451151997],
              [-38.422206194460273, -8.520041641808291],
              [-38.4223180206601, -8.519994774539157],
              [-38.422446326037061, -8.519866527618092],
              [-38.422463085802747, -8.519596109572774],
              [-38.422399007308286, -8.519308386772593],
              [-38.422101492689706, -8.518973554785703],
              [-38.425785944965355, -8.515851017810178],
              [-38.427503650073334, -8.514305968145891],
              [-38.428418214728765, -8.513334985421181],
              [-38.430728712460052, -8.511503162640793],
              [-38.431354492215974, -8.511041882376901],
              [-38.431948049263042, -8.510866375183014],
              [-38.432796514647407, -8.510963488474147],
              [-38.434336643221457, -8.510280126926261],
              [-38.43526657908933, -8.509962193200636],
              [-38.436036582222471, -8.509660306078217],
              [-38.436742400741139, -8.509134914044978],
              [-38.438187205674978, -8.507142595554285],
              [-38.438428116064642, -8.507047066868608],
              [-38.439886916248156, -8.506666570006132],
              [-38.440737281000679, -8.506077159733081],
              [-38.441651053353382, -8.505616275170894],
              [-38.44290081961708, -8.505904791173123],
              [-38.444057109448011, -8.504231322817247],
              [-38.44381721439288, -8.503624968417512],
              [-38.443882941344611, -8.502779361078581],
              [-38.444829413946252, -8.502317610469277],
              [-38.445662992852817, -8.502015792594705],
              [-38.446753353384509, -8.501315456343207],
              [-38.44681815943045, -8.501108418918363],
              [-38.446898271937641, -8.500996375981913],
              [-38.447139655370734, -8.500566179885254],
              [-38.447428190258734, -8.50019936440907],
              [-38.447615889561632, -8.499506245216992],
              [-38.439648030232377, -8.490162023858261],
              [-38.439414264057007, -8.489913765108099],
              [-38.439166667207871, -8.489703778806302],
              [-38.439166666308552, -8.489166658714282],
              [-38.439999998800886, -8.489166658714282],
              [-38.439999999700206, -8.488333326222005],
              [-38.440833331293163, -8.488333326222005],
              [-38.440833333091803, -8.487499992830351],
              [-38.442499998975734, -8.487499992830351],
              [-38.442499998975734, -8.488333327121325],
              [-38.445000000049902, -8.488333324423309],
              [-38.445000000049902, -8.486666659438754],
              [-38.449166665209361, -8.486666658539434],
              [-38.449166667907377, -8.487499992830351],
              [-38.450833331992612, -8.487499992830351],
              [-38.450833332891932, -8.488333326222005],
              [-38.452500001473823, -8.488333326222005],
              [-38.452500000574503, -8.489166658714282],
              [-38.453333331268198, -8.489166659613602],
              [-38.453333333966157, -8.489999993904576],
              [-38.455833332342365, -8.489999993005199],
              [-38.455833331443046, -8.490833325497533],
              [-38.456666667532659, -8.490833325497533],
              [-38.456666666633339, -8.489999993005199],
              [-38.459166665908867, -8.489999993005199],
              [-38.459166666808187, -8.489166658714282],
              [-38.460833331792799, -8.489166658714282],
              [-38.460833332692118, -8.487499992830351],
              [-38.461666666983035, -8.487499992830351],
              [-38.461666667882355, -8.4858333260471],
              [-38.462500000374689, -8.4858333260471],
              [-38.46249999857605, -8.484166658364529],
              [-38.463333332866966, -8.484166658364529],
              [-38.463333331967647, -8.481666659089001],
              [-38.462499999475369, -8.481666659089001],
              [-38.46249999857605, -8.480833325697404],
              [-38.461666667882355, -8.480833326596724],
              [-38.461666667882355, -8.47999999320507],
              [-38.460000001099104, -8.47999999320507],
              [-38.459999998401145, -8.478333325522499],
              [-38.460833333591438, -8.478333325522499],
              [-38.460833331792799, -8.477499992130902],
              [-38.461666667882355, -8.477499993030222],
              [-38.461666667882355, -8.476666658739248],
              [-38.46249999857605, -8.476666658739248],
              [-38.462500000374689, -8.475833325347651],
              [-38.463333333766286, -8.475833325347651],
              [-38.463333331967647, -8.474999992855317],
              [-38.46416666625862, -8.474999992855317],
              [-38.46416666625862, -8.4741666585644],
              [-38.464999999650217, -8.4741666585644],
              [-38.464999998750898, -8.472499992680468],
              [-38.465833331243175, -8.472499992680468],
              [-38.465833331243175, -8.471666658389552],
              [-38.464999998750898, -8.471666658389552],
              [-38.464999998750898, -8.470833325897217],
              [-38.465833333941191, -8.470833325897217],
              [-38.465833333041871, -8.469999991606301],
              [-38.464999999650217, -8.46999999250562],
              [-38.464999998750898, -8.469166658214647],
              [-38.464166665359301, -8.469166659113966],
              [-38.464166665359301, -8.468333325722369],
              [-38.460000001099104, -8.468333325722369],
              [-38.459999998401145, -8.467499993230035],
              [-38.459166666808187, -8.467499993230035],
              [-38.459166665908867, -8.466666658939118],
              [-38.459166667707507, -8.465833325547464],
              [-38.459999999300464, -8.465833325547464],
              [-38.459999999300464, -8.464999993055187],
              [-38.461666666983035, -8.464999993055187],
              [-38.461666667882355, -8.463333325372616],
              [-38.462499999475369, -8.463333324473297],
              [-38.462500000374689, -8.462499992880339],
              [-38.46416666625862, -8.462499992880339],
              [-38.46416666625862, -8.461666658589365],
              [-38.465833331243175, -8.461666657690046],
              [-38.465833331243175, -8.462499992880339],
              [-38.467500000724385, -8.462499992880339],
              [-38.467499998925746, -8.461666658589365],
              [-38.470833333391568, -8.461666659488685],
              [-38.470833332492248, -8.460833326097088],
              [-38.471666665883902, -8.460833326097088],
              [-38.471666667682541, -8.459999992705434],
              [-38.472499997476859, -8.459999992705434],
              [-38.472499998376179, -8.459166658414517],
              [-38.473333330868456, -8.459166658414517],
              [-38.473333331767776, -8.457499992530586],
              [-38.47416666605875, -8.457499992530586],
              [-38.47416666605875, -8.454166658964084],
              [-38.475000000349667, -8.454166658964084],
              [-38.474999999450347, -8.453333325572487],
              [-38.475833331942681, -8.453333325572487],
              [-38.475833333741321, -8.452499993080153],
              [-38.476666668032237, -8.452499992180833],
              [-38.476666666233598, -8.451666657889916],
              [-38.481666665684031, -8.451666659688556],
              [-38.481666665684031, -8.452499993080153],
              [-38.486666667832424, -8.452499992180833],
              [-38.486666668731743, -8.451666658789236],
              [-38.487499998526062, -8.451666658789236],
              [-38.487499998526062, -8.450833325397582],
              [-38.488333331917715, -8.450833325397582],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '254',
        OBJECTID: 170.0,
        Nome_area: 'Ibimirim',
        COD_area: 'CA176',
        Import_bio: 'Muito Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Sem ações recomendadas pela Oficina de Seleção',
        Area_ha: 65365.5289,
        Shape_Leng: 1.725,
        Shape_Area: 0.05368,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.788333331518004, -8.369999992705459],
              [-37.788333331518004, -8.371666657690071],
              [-37.787499999924989, -8.37166665858939],
              [-37.78749999902567, -8.372499992880307],
              [-37.785833332242419, -8.372499992880307],
              [-37.785833331343099, -8.373333325372641],
              [-37.786666667432712, -8.373333325372641],
              [-37.786666668332032, -8.374999993055212],
              [-37.78749999902567, -8.374999993055212],
              [-37.787499999924989, -8.37749999323006],
              [-37.786666665634073, -8.37749999323006],
              [-37.786666665634073, -8.38166665838952],
              [-37.787500000824309, -8.38166665838952],
              [-37.787499999924989, -8.382499993579813],
              [-37.786666666533392, -8.382499992680494],
              [-37.786666665634073, -8.384166658564368],
              [-37.785833334041115, -8.384166658564368],
              [-37.785833331343099, -8.38749999303019],
              [-37.785000000649461, -8.387499993929509],
              [-37.784999999750141, -8.389166658914121],
              [-37.784166665459225, -8.389166658914121],
              [-37.784166666358544, -8.390833325697372],
              [-37.783333332067571, -8.390833324798052],
              [-37.78333333386621, -8.391666659089026],
              [-37.782500000474613, -8.391666659089026],
              [-37.782500000474613, -8.392499992480623],
              [-37.781666667082959, -8.392499992480623],
              [-37.781666667982279, -8.388333325522524],
              [-37.779166666008791, -8.388333325522524],
              [-37.779166666008791, -8.38749999303019],
              [-37.776666665833943, -8.38749999303019],
              [-37.776666667632583, -8.386666658739273],
              [-37.77583333154297, -8.386666658739273],
              [-37.77583333154297, -8.385833325347619],
              [-37.774999999050692, -8.385833325347619],
              [-37.774166665659038, -8.385833327146258],
              [-37.774166666558358, -8.386666658739273],
              [-37.773333331368121, -8.386666657839953],
              [-37.773333331368121, -8.38749999303019],
              [-37.772499999775107, -8.387499993929509],
              [-37.772499998875787, -8.388333325522524],
              [-37.771666669081469, -8.388333325522524],
              [-37.77166666728283, -8.389166658014801],
              [-37.770833332092536, -8.389166658014801],
              [-37.770833332991856, -8.390833325697372],
              [-37.770000000499579, -8.390833325697372],
              [-37.769999999600259, -8.391666659089026],
              [-37.768333332817008, -8.391666659089026],
              [-37.768333333716328, -8.392499992480623],
              [-37.767499999425411, -8.392499992480623],
              [-37.767499998526091, -8.395833326047125],
              [-37.766666666033757, -8.395833325147805],
              [-37.766666667832396, -8.397499992830376],
              [-37.767499998526091, -8.397499991931056],
              [-37.767499998526091, -8.400833325497501],
              [-37.768333332817008, -8.400833324598182],
              [-37.768333331917688, -8.403333325672406],
              [-37.767500000324731, -8.403333325672406],
              [-37.767499999425411, -8.402499993180072],
              [-37.764166666758229, -8.402499993180072],
              [-37.764166665858909, -8.401666657989836],
              [-37.762499999075658, -8.401666658889155],
              [-37.762499999974978, -8.402499993180072],
              [-37.75999999890081, -8.402499991381433],
              [-37.760000000699449, -8.404166659064003],
              [-37.758333333016878, -8.404166658164684],
              [-37.758333331218239, -8.405833325847254],
              [-37.754999998551057, -8.405833325847254],
              [-37.755000001249016, -8.407499992630505],
              [-37.753333332667125, -8.407499991731186],
              [-37.753333333566445, -8.409166658514437],
              [-37.752500001074168, -8.409166658514437],
              [-37.752500001074168, -8.408333326022159],
              [-37.751666664984555, -8.408333326022159],
              [-37.751666666783194, -8.409166658514437],
              [-37.750833331592958, -8.409166658514437],
              [-37.750833331592958, -8.411666658689285],
              [-37.748333332317372, -8.411666658689285],
              [-37.748333331418053, -8.410833326197007],
              [-37.743333333766316, -8.410833326197007],
              [-37.743333331967676, -8.411666658689285],
              [-37.741666666983065, -8.411666659588604],
              [-37.740833332692091, -8.411666657789965],
              [-37.740833332692091, -8.410833326197007],
              [-37.740000001099133, -8.410833324398368],
              [-37.739999998401174, -8.409999992805353],
              [-37.73916666590884, -8.409999991906034],
              [-37.73916666770748, -8.410833326197007],
              [-37.737499998226269, -8.410833326197007],
              [-37.737500000024966, -8.411666659588604],
              [-37.735833332342395, -8.411666657789965],
              [-37.735833332342395, -8.410833326197007],
              [-37.73500000074938, -8.410833326197007],
              [-37.734999998950741, -8.409999992805353],
              [-37.73333333126817, -8.409999992805353],
              [-37.73333333126817, -8.409166658514437],
              [-37.731666666283559, -8.409166658514437],
              [-37.731666668082198, -8.408333326022159],
              [-37.730833333791281, -8.408333326022159],
              [-37.730833332891962, -8.407499992630505],
              [-37.728333331817794, -8.407499992630505],
              [-37.728333332717114, -8.406666659238908],
              [-37.724999998251292, -8.406666657440269],
              [-37.725000000949251, -8.407499992630505],
              [-37.724166666658277, -8.407499992630505],
              [-37.724166665758958, -8.408333326022159],
              [-37.722499999875026, -8.408333326022159],
              [-37.722499999875026, -8.409166658514437],
              [-37.72166666558411, -8.409166658514437],
              [-37.721666666483429, -8.409999991906034],
              [-37.720833331293136, -8.409999992805353],
              [-37.720833333091832, -8.409166658514437],
              [-37.718333332017608, -8.409166661212396],
              [-37.718333333816247, -8.411666658689285],
              [-37.716666667932316, -8.411666658689285],
              [-37.716666667032996, -8.412499992980258],
              [-37.715833332742079, -8.412499992980258],
              [-37.715833332742079, -8.413333325472536],
              [-37.714166665958828, -8.413333325472536],
              [-37.714166665958828, -8.414166659763509],
              [-37.713333332567174, -8.414166659763509],
              [-37.713333331667855, -8.415833325647441],
              [-37.712500000974217, -8.415833325647441],
              [-37.712499998276257, -8.416666659938357],
              [-37.710833332392326, -8.416666659039038],
              [-37.710833331493006, -8.417499992430635],
              [-37.710000000799369, -8.417499992430635],
              [-37.709999999000729, -8.418333325822289],
              [-37.709166666508395, -8.418333325822289],
              [-37.709166666508395, -8.419166659213886],
              [-37.708333334016118, -8.419166659213886],
              [-37.708333334016118, -8.41999999260554],
              [-37.707499998825824, -8.41999999260554],
              [-37.707499998825824, -8.420833325997137],
              [-37.704999997751656, -8.420833325997137],
              [-37.705000000449616, -8.41999999260554],
              [-37.704166665259379, -8.41999999260554],
              [-37.704166665259379, -8.420833325997137],
              [-37.703333331867725, -8.420833325997137],
              [-37.703333331867725, -8.421666658489471],
              [-37.702500000274767, -8.421666658489471],
              [-37.702499998476128, -8.422499992780388],
              [-37.700833331692877, -8.422499992780388],
              [-37.700833331692877, -8.423333326172042],
              [-37.699999998301223, -8.423333326172042],
              [-37.699999998301223, -8.424166658664319],
              [-37.699166665808946, -8.424166658664319],
              [-37.699166666708265, -8.424999993854613],
              [-37.698333331517972, -8.424999992955236],
              [-37.698333331517972, -8.426666658839167],
              [-37.697499997227055, -8.426666659738487],
              [-37.697499999025695, -8.427499993130141],
              [-37.695000000649486, -8.427499993130141],
              [-37.694999999750166, -8.428333325622418],
              [-37.694166665459193, -8.428333326521738],
              [-37.694166666358512, -8.429166659014072],
              [-37.693333332067596, -8.429166659014072],
              [-37.693333333866235, -8.429999992405669],
              [-37.692499999575261, -8.429999992405669],
              [-37.692499999575261, -8.43166665918892],
              [-37.691666667982304, -8.43166665918892],
              [-37.691666665284345, -8.432499992580574],
              [-37.692500000474581, -8.432499992580574],
              [-37.692499999575261, -8.433333325972171],
              [-37.691666667082984, -8.433333325972171],
              [-37.691666667082984, -8.434999992755422],
              [-37.69083333279201, -8.434999992755422],
              [-37.69083333279201, -8.435833326147019],
              [-37.689166666908136, -8.435833326147019],
              [-37.689166667807456, -8.439999992205856],
              [-37.688333331717843, -8.439999993105175],
              [-37.688333332617162, -8.443333325772301],
              [-37.687499998326246, -8.443333325772301],
              [-37.687499998326246, -8.444166659163955],
              [-37.688333332617162, -8.444166659163955],
              [-37.688333331717843, -8.445833325947206],
              [-37.69083333369133, -8.445833325047886],
              [-37.69083333279201, -8.446666658439483],
              [-37.692499999575261, -8.446666658439483],
              [-37.692499999575261, -8.448333326122054],
              [-37.698333331517972, -8.448333325222734],
              [-37.698333331517972, -8.449166658614331],
              [-37.700000000999182, -8.449166659513651],
              [-37.699999998301223, -8.449999992905305],
              [-37.700833331692877, -8.449999993804624],
              [-37.700833331692877, -8.450833325397582],
              [-37.701666666883114, -8.450833325397582],
              [-37.701666666883114, -8.451666658789236],
              [-37.702499998476128, -8.451666657889916],
              [-37.702500000274767, -8.454999994154377],
              [-37.703333331867725, -8.454999992355738],
              [-37.703333333666365, -8.458333325922183],
              [-37.708333334016118, -8.458333325922183],
              [-37.708333332217478, -8.459166658414517],
              [-37.710833333291646, -8.459166658414517],
              [-37.710833332392326, -8.459999992705434],
              [-37.71166666578398, -8.459999992705434],
              [-37.7116666666833, -8.466666658939118],
              [-37.712499998276257, -8.466666658939118],
              [-37.712499998276257, -8.469166659113966],
              [-37.7116666666833, -8.469166659113966],
              [-37.7116666666833, -8.46999999250562],
              [-37.710833331493006, -8.46999999250562],
              [-37.710833331493006, -8.470833325897217],
              [-37.710000001698688, -8.470833324997898],
              [-37.709999999000729, -8.471666658389552],
              [-37.709166667407715, -8.471666658389552],
              [-37.709166665609075, -8.472499992680468],
              [-37.708333334016118, -8.472499991781149],
              [-37.708333334016118, -8.473333326072066],
              [-37.707499999725144, -8.473333326072066],
              [-37.707500000624464, -8.474999993754636],
              [-37.706666667232867, -8.474999991955997],
              [-37.706666667232867, -8.475833325347651],
              [-37.707499998825824, -8.475833325347651],
              [-37.707499999725144, -8.478333325522499],
              [-37.704999998650976, -8.478333325522499],
              [-37.704999999550296, -8.479166658914153],
              [-37.704166667957338, -8.479166658914153],
              [-37.704166665259379, -8.47999999320507],
              [-37.703333332767045, -8.47999999140643],
              [-37.703333332767045, -8.481666659089001],
              [-37.702499998476128, -8.481666659089001],
              [-37.702500000274767, -8.484166658364529],
              [-37.701666665983794, -8.484166658364529],
              [-37.701666665983794, -8.4858333260471],
              [-37.702499998476128, -8.4858333260471],
              [-37.702499997576808, -8.486666658539434],
              [-37.701666666883114, -8.486666658539434],
              [-37.701666665983794, -8.487499992830351],
              [-37.700833331692877, -8.487499992830351],
              [-37.700833333491516, -8.488333326222005],
              [-37.701666665983794, -8.488333326222005],
              [-37.701666666883114, -8.492499993180104],
              [-37.700833333491516, -8.492499994079424],
              [-37.700833332592197, -8.493333324773062],
              [-37.700000000099863, -8.493333325672381],
              [-37.700000000099863, -8.495833326746606],
              [-37.700833332592197, -8.495833325847286],
              [-37.700833332592197, -8.496666659238883],
              [-37.699999998301223, -8.496666659238883],
              [-37.700000000099863, -8.497499992630537],
              [-37.699166665808946, -8.497499992630537],
              [-37.699166665808946, -8.498333326022134],
              [-37.700000000099863, -8.498333326022134],
              [-37.699999998301223, -8.499166658514412],
              [-37.700833331692877, -8.499166658514412],
              [-37.700833333491516, -8.499999992805385],
              [-37.700000000099863, -8.499999992805385],
              [-37.700000000099863, -8.501666658689317],
              [-37.700833333491516, -8.501666657789997],
              [-37.700833331692877, -8.502499992980233],
              [-37.701666666883114, -8.502499992980233],
              [-37.701666665983794, -8.503333325472568],
              [-37.703333331867725, -8.503333325472568],
              [-37.703333331867725, -8.502499993879553],
              [-37.710833331493006, -8.502499992980233],
              [-37.710833331493006, -8.503333325472568],
              [-37.7116666666833, -8.503333325472568],
              [-37.71166666488466, -8.509166659213918],
              [-37.712500000974217, -8.509166659213918],
              [-37.712499998276257, -8.51249999278042],
              [-37.713333331667855, -8.5124999918811],
              [-37.713333332567174, -8.514166658664294],
              [-37.715833332742079, -8.514166658664294],
              [-37.715833333641399, -8.514999992955268],
              [-37.714166666858148, -8.514999992955268],
              [-37.714166666858148, -8.516666659738519],
              [-37.715000000249745, -8.516666658839199],
              [-37.715000000249745, -8.517499994029436],
              [-37.715833331842759, -8.517499993130116],
              [-37.715833332742079, -8.51833332562245],
              [-37.716666665234357, -8.51833332562245],
              [-37.716666667032996, -8.519166658114727],
              [-37.71749999862601, -8.519166659913367],
              [-37.71749999862601, -8.519999991506381],
              [-37.716666665234357, -8.519999992405701],
              [-37.716666667032996, -8.520833325797298],
              [-37.714166666858148, -8.520833325797298],
              [-37.712499998276257, -8.520833325797298],
              [-37.712499998276257, -8.522499992580549],
              [-37.71166666758262, -8.522499991681229],
              [-37.71166666578398, -8.523333325972146],
              [-37.710833332392326, -8.523333325972146],
              [-37.710833332392326, -8.527499992930302],
              [-37.71166666578398, -8.527499992930302],
              [-37.7116666666833, -8.528333325422579],
              [-37.712500000974217, -8.528333325422579],
              [-37.712500000974217, -8.529166658814233],
              [-37.713333332567174, -8.529166658814233],
              [-37.713333331667855, -8.531666659888401],
              [-37.714166667757468, -8.531666658989081],
              [-37.714166665958828, -8.532499992380679],
              [-37.714999998451106, -8.532499992380679],
              [-37.714999999350425, -8.539166658614363],
              [-37.715833332742079, -8.539166658614363],
              [-37.715833332742079, -8.53999999290528],
              [-37.715000000249745, -8.539999993804599],
              [-37.714999999350425, -8.541666658789211],
              [-37.714166666858148, -8.541666658789211],
              [-37.714166666858148, -8.547499993429881],
              [-37.713333331667855, -8.547499992530561],
              [-37.713333333466551, -8.550833326097063],
              [-37.714166665059508, -8.550833326097063],
              [-37.714166666858148, -8.551666658589397],
              [-37.714999998451106, -8.551666658589397],
              [-37.715000000249745, -8.552499992880314],
              [-37.715833331842759, -8.552499993779634],
              [-37.715833332742079, -8.553333325372648],
              [-37.716666667032996, -8.553333325372648],
              [-37.716666667032996, -8.554166659663565],
              [-37.71749999862601, -8.554166658764245],
              [-37.71750000042465, -8.555833325547496],
              [-37.718333333816247, -8.555833325547496],
              [-37.718333332916927, -8.558333325722344],
              [-37.719166666308581, -8.558333325722344],
              [-37.719166666308581, -8.559166659113998],
              [-37.719999999700178, -8.559166659113998],
              [-37.719999997901539, -8.559999992505595],
              [-37.720833331293136, -8.559999992505595],
              [-37.720833333991152, -8.561666660188166],
              [-37.72166666558411, -8.561666658389527],
              [-37.72166666558411, -8.562499992680443],
              [-37.722500000774346, -8.562499992680443],
              [-37.722499999875026, -8.563333325172778],
              [-37.723333332367361, -8.563333326072097],
              [-37.723333331468041, -8.564166657665055],
              [-37.724166667557597, -8.564166659463694],
              [-37.724166665758958, -8.564999991956029],
              [-37.720833331293136, -8.564999992855348],
              [-37.720833333991152, -8.564166658564375],
              [-37.715833333641399, -8.564166658564375],
              [-37.715833332742079, -8.564999992855348],
              [-37.714999998451106, -8.564999992855348],
              [-37.714999998451106, -8.565833325347626],
              [-37.714166666858148, -8.565833325347626],
              [-37.7116666666833, -8.565833325347626],
              [-37.71166666578398, -8.56666665873928],
              [-37.709166666508395, -8.56666665873928],
              [-37.709166666508395, -8.567499992130877],
              [-37.708333331318158, -8.567499993030196],
              [-37.708333334016118, -8.568333325522531],
              [-37.707500000624464, -8.56833332642185],
              [-37.707499998825824, -8.569166658914128],
              [-37.706666667232867, -8.569166658914128],
              [-37.706666667232867, -8.570833325697379],
              [-37.705833332042573, -8.570833325697379],
              [-37.705833332042573, -8.571666659088976],
              [-37.703333332767045, -8.571666659088976],
              [-37.703333333666365, -8.57249999248063],
              [-37.702499999375448, -8.57249999337995],
              [-37.702499998476128, -8.573333325872227],
              [-37.701666667782433, -8.573333325872227],
              [-37.701666665983794, -8.575833326047132],
              [-37.700833331692877, -8.575833325147812],
              [-37.700833332592197, -8.577499992830383],
              [-37.699166665808946, -8.577499992830383],
              [-37.699166666708265, -8.57833332622198],
              [-37.698333332417292, -8.57833332622198],
              [-37.698333331517972, -8.579166658714257],
              [-37.697500000824334, -8.579166659613577],
              [-37.697499999925014, -8.579999993904551],
              [-37.696666665634041, -8.579999992105911],
              [-37.696666667432737, -8.580833325497508],
              [-37.695833332242444, -8.580833325497508],
              [-37.695833332242444, -8.581666659788482],
              [-37.694166666358512, -8.581666658889162],
              [-37.694166666358512, -8.583333325672413],
              [-37.693333330268956, -8.583333325672413],
              [-37.693333332067596, -8.58416665906401],
              [-37.692500000474581, -8.58416665906401],
              [-37.692499999575261, -8.584999992455664],
              [-37.691666667082984, -8.584999992455664],
              [-37.691666667082984, -8.585833325847261],
              [-37.69083333369133, -8.585833325847261],
              [-37.69083333279201, -8.587499992630512],
              [-37.688333333516482, -8.587499991731192],
              [-37.688333333516482, -8.58999999280536],
              [-37.686666666733231, -8.589999991906041],
              [-37.686666665833911, -8.590833324398375],
              [-37.68499999905066, -8.590833326197014],
              [-37.6850000008493, -8.591666658689292],
              [-37.684166666558383, -8.591666658689292],
              [-37.684166665659063, -8.592499992080946],
              [-37.683333332267409, -8.592499992980265],
              [-37.683333332267409, -8.593333325472543],
              [-37.682499998875812, -8.593333326371862],
              [-37.682499999775132, -8.594166658864197],
              [-37.680833332092561, -8.594166658864197],
              [-37.680833332092561, -8.594999994054433],
              [-37.679999998700964, -8.594999993155113],
              [-37.679999999600284, -8.595833324748071],
              [-37.678333332817033, -8.595833325647391],
              [-37.678333333716353, -8.596666659039045],
              [-37.677499998526059, -8.596666659039045],
              [-37.677499999425379, -8.597499992430642],
              [-37.676666666933102, -8.597499991531322],
              [-37.676666666033782, -8.598333325822296],
              [-37.675833332642128, -8.598333325822296],
              [-37.675833331742808, -8.599166659213893],
              [-37.674999999250531, -8.599166659213893],
              [-37.674999999250531, -8.599999991706227],
              [-37.674166667657516, -8.599999992605547],
              [-37.674166667657516, -8.600833325997144],
              [-37.673333334265919, -8.600833325997144],
              [-37.67333333246728, -8.601666658489478],
              [-37.672499999975003, -8.601666657590101],
              [-37.672499999075626, -8.602499992780395],
              [-37.671666667482668, -8.602499992780395],
              [-37.671666667482668, -8.603333327071368],
              [-37.67333333156796, -8.603333326171992],
              [-37.6733333333666, -8.604166658664326],
              [-37.674166666758197, -8.604166658664326],
              [-37.674166666758197, -8.604999992955243],
              [-37.674999998351211, -8.604999992955243],
              [-37.674999998351211, -8.605833326346897],
              [-37.676666667832421, -8.605833325447577],
              [-37.676666666033782, -8.604999992055923],
              [-37.67916666530931, -8.604999992955243],
              [-37.67916666620863, -8.605833326346897],
              [-37.680000000499604, -8.605833325447577],
              [-37.679999998700964, -8.606666658839174],
              [-37.681666667282798, -8.606666658839174],
              [-37.681666665484158, -8.607499993130148],
              [-37.682499997976493, -8.607499993130148],
              [-37.682499999775132, -8.608333325622425],
              [-37.68333333136809, -8.608333325622425],
              [-37.683333332267409, -8.609166659913399],
              [-37.685833333341634, -8.609166658114759],
              [-37.685833331542995, -8.609999992405676],
              [-37.686666666733231, -8.609999992405676],
              [-37.686666665833911, -8.609166659014079],
              [-37.688333331717843, -8.609166659014079],
              [-37.688333331717843, -8.608333325622425],
              [-37.690833331892691, -8.608333325622425],
              [-37.690833331892691, -8.607499993130148],
              [-37.692499999575261, -8.607499993130148],
              [-37.692499998675942, -8.608333325622425],
              [-37.695833332242444, -8.608333326521745],
              [-37.695833331343124, -8.607499993130148],
              [-37.696666665634041, -8.607499992230828],
              [-37.696666665634041, -8.606666658839174],
              [-37.697499999025695, -8.606666658839174],
              [-37.697499999025695, -8.604999992955243],
              [-37.698333331517972, -8.604999992055923],
              [-37.698333330618652, -8.604166659563646],
              [-37.701666665983794, -8.604166658664326],
              [-37.701666666883114, -8.604999992955243],
              [-37.701666665983794, -8.606666658839174],
              [-37.700000000099863, -8.606666658839174],
              [-37.700000000099863, -8.607499993130148],
              [-37.699166666708265, -8.607499993130148],
              [-37.699166665808946, -8.608333325622425],
              [-37.697499999925014, -8.608333325622425],
              [-37.697499999025695, -8.609166659014079],
              [-37.694999998850847, -8.609166659014079],
              [-37.695000000649486, -8.610833325797273],
              [-37.694166666358512, -8.610833325797273],
              [-37.694166667257832, -8.611666660088247],
              [-37.693333332067596, -8.611666659188927],
              [-37.693333334765555, -8.613333325972178],
              [-37.692499998675942, -8.613333325972178],
              [-37.692500000474581, -8.614166658464455],
              [-37.690000000299733, -8.614166658464455],
              [-37.689999998501094, -8.614999992755429],
              [-37.689166667807456, -8.614999992755429],
              [-37.689166666908136, -8.615833326147026],
              [-37.686666667632551, -8.615833326147026],
              [-37.686666665833911, -8.61666665863936],
              [-37.685833332442314, -8.616666657740041],
              [-37.685833333341634, -8.618333325422611],
              [-37.68499999905066, -8.618333325422611],
              [-37.68499999905066, -8.619166658814208],
              [-37.682499998875812, -8.619166658814208],
              [-37.682500000674452, -8.619999994004445],
              [-37.680833332991881, -8.619999993105125],
              [-37.680833333891201, -8.620833325597459],
              [-37.679999998700964, -8.620833325597459],
              [-37.679999998700964, -8.621666658989056],
              [-37.675833332642128, -8.621666658989056],
              [-37.675833331742808, -8.62249999238071],
              [-37.674166665858877, -8.62249999238071],
              [-37.674166664959557, -8.623333325772307],
              [-37.67333333246728, -8.623333325772307],
              [-37.6733333333666, -8.624166659163961],
              [-37.672499999975003, -8.624166657365322],
              [-37.672500000874322, -8.624999992555558],
              [-37.670833332292432, -8.624999992555558],
              [-37.670833332292432, -8.62833332612206],
              [-37.671666665684029, -8.62833332612206],
              [-37.671666665684029, -8.630833325397589],
              [-37.672499997276987, -8.630833325397589],
              [-37.672499999075626, -8.63249999308016],
              [-37.67333333246728, -8.63249999308016],
              [-37.673333334265919, -8.633333325572494],
              [-37.675833332642128, -8.633333325572494],
              [-37.675833332642128, -8.63249999308016],
              [-37.679166668007269, -8.63249999308016],
              [-37.67916666620863, -8.633333325572494],
              [-37.680833332092561, -8.633333325572494],
              [-37.680833333891201, -8.63249999308016],
              [-37.684166664759744, -8.63249999308016],
              [-37.684166666558383, -8.631666658789243],
              [-37.686666667632551, -8.631666658789243],
              [-37.686666665833911, -8.630833325397589],
              [-37.688333332617162, -8.630833325397589],
              [-37.688333332617162, -8.631666658789243],
              [-37.689999999400413, -8.631666659688563],
              [-37.689999997601774, -8.63249999308016],
              [-37.691666667082984, -8.632499993979479],
              [-37.691666665284345, -8.631666658789243],
              [-37.694999999750166, -8.631666658789243],
              [-37.694999999750166, -8.63249999218084],
              [-37.703333333666365, -8.63249999218084],
              [-37.703333331867725, -8.633333325572494],
              [-37.704999999550296, -8.633333325572494],
              [-37.704999998650976, -8.634166658964091],
              [-37.705833332042573, -8.634166658964091],
              [-37.705833332042573, -8.633333325572494],
              [-37.707500000624464, -8.633333325572494],
              [-37.707499998825824, -8.63249999308016],
              [-37.708333332217478, -8.63249999308016],
              [-37.708333334915437, -8.633333325572494],
              [-37.709999999900049, -8.633333326471814],
              [-37.709999999000729, -8.63249999308016],
              [-37.714166667757468, -8.63249999308016],
              [-37.714166665958828, -8.633333325572494],
              [-37.713333333466551, -8.633333325572494],
              [-37.713333332567174, -8.634166658064771],
              [-37.712499998276257, -8.634166658964091],
              [-37.712499998276257, -8.637499991631273],
              [-37.7116666666833, -8.637499992530593],
              [-37.7116666666833, -8.63833332592219],
              [-37.710833333291646, -8.63833332592219],
              [-37.710833331493006, -8.640833326097095],
              [-37.710000000799369, -8.640833326097095],
              [-37.709999999000729, -8.641666659488692],
              [-37.709166665609075, -8.641666658589372],
              [-37.709166665609075, -8.642499992880346],
              [-37.708333331318158, -8.642499992880346],
              [-37.708333331318158, -8.64416665966354],
              [-37.707499999725144, -8.64416665876422],
              [-37.707499999725144, -8.644999993055194],
              [-37.706666664534907, -8.644999993055194],
              [-37.706666665434227, -8.646666659838445],
              [-37.705833332941893, -8.646666658939125],
              [-37.705833332042573, -8.647499991431403],
              [-37.704999998650976, -8.647499993230042],
              [-37.704999999550296, -8.648333326621696],
              [-37.704166665259379, -8.648333326621696],
              [-37.704166665259379, -8.649166659113973],
              [-37.703333333666365, -8.649166659113973],
              [-37.703333332767045, -8.650833325897224],
              [-37.702499997576808, -8.650833325897224],
              [-37.702499998476128, -8.652499992680475],
              [-37.701666666883114, -8.652499992680475],
              [-37.701666665983794, -8.653333326072072],
              [-37.700833331692877, -8.653333325172753],
              [-37.700833331692877, -8.654166658564407],
              [-37.699999998301223, -8.654166658564407],
              [-37.700000000999182, -8.654999993754643],
              [-37.699166666708265, -8.654999993754643],
              [-37.699166666708265, -8.655833325347658],
              [-37.697499999025695, -8.655833325347658],
              [-37.697499999025695, -8.656666658739255],
              [-37.696666665634041, -8.656666659638574],
              [-37.696666666533417, -8.657499993030228],
              [-37.695833334041083, -8.657499993030228],
              [-37.695833334041083, -8.658333325522506],
              [-37.693333333866235, -8.658333325522506],
              [-37.693333332966915, -8.659166658914103],
              [-37.692499999575261, -8.659166658914103],
              [-37.692499998675942, -8.661666658189688],
              [-37.691666664385025, -8.661666659089008],
              [-37.691666667082984, -8.663333325872259],
              [-37.69083333279201, -8.663333325872259],
              [-37.69083333369133, -8.664166658364536],
              [-37.689166666908136, -8.664166658364536],
              [-37.689166666008759, -8.66499999265551],
              [-37.688333333516482, -8.66499999265551],
              [-37.688333332617162, -8.666666658539441],
              [-37.687499999225565, -8.666666658539441],
              [-37.687499998326246, -8.667499993729677],
              [-37.685833333341634, -8.667499992830358],
              [-37.685833333341634, -8.668333326221955],
              [-37.684166667457703, -8.668333326221955],
              [-37.684166665659063, -8.669166658714289],
              [-37.683333334066049, -8.669166657814969],
              [-37.68333333136809, -8.67083332549754],
              [-37.684166666558383, -8.67083332549754],
              [-37.684166665659063, -8.672499993180111],
              [-37.683333334066049, -8.672499994079431],
              [-37.683333332267409, -8.674166659064042],
              [-37.682499998875812, -8.674166659064042],
              [-37.682499999775132, -8.674999992455639],
              [-37.681666666383478, -8.674999992455639],
              [-37.681666665484158, -8.67666665923889],
              [-37.680833332092561, -8.67666665923889],
              [-37.680833332092561, -8.677499992630487],
              [-37.679999999600284, -8.677499993529807],
              [-37.680000000499604, -8.679166658514418],
              [-37.67916666620863, -8.679166658514418],
              [-37.679166668007269, -8.679999992805392],
              [-37.67916666530931, -8.68249999298024],
              [-37.679999998700964, -8.68249999298024],
              [-37.679999999600284, -8.683333325472518],
              [-37.680833332991881, -8.683333325472518],
              [-37.680833332092561, -8.684166658864171],
              [-37.681666666383478, -8.684166658864171],
              [-37.681666666383478, -8.684999993155088],
              [-37.683333332267409, -8.684999993155088],
              [-37.683333332267409, -8.685833324748103],
              [-37.682499998875812, -8.685833325647422],
              [-37.682500000674452, -8.686666659938339],
              [-37.679999999600284, -8.68666665903902],
              [-37.679999998700964, -8.685833325647422],
              [-37.67916666530931, -8.685833324748103],
              [-37.67916666620863, -8.68666665903902],
              [-37.675833332642128, -8.68666665903902],
              [-37.675833331742808, -8.688333325822271],
              [-37.674166666758197, -8.688333325822271],
              [-37.674166666758197, -8.689166659213925],
              [-37.67333333246728, -8.689166659213925],
              [-37.67333333156796, -8.689999992605522],
              [-37.671666665684029, -8.689999991706202],
              [-37.671666667482668, -8.69249999278037],
              [-37.670833332292432, -8.692499993679746],
              [-37.670833331393112, -8.694166658664301],
              [-37.669166665509181, -8.694166658664301],
              [-37.66916666730782, -8.694999992955275],
              [-37.668333331218207, -8.694999992955275],
              [-37.668333333916166, -8.695833325447552],
              [-37.66749999962525, -8.695833325447552],
              [-37.66749999962525, -8.696666658839206],
              [-37.666666665334276, -8.696666659738526],
              [-37.666666665334276, -8.697499993130123],
              [-37.665833333741318, -8.697499992230803],
              [-37.665833332841999, -8.698333325622457],
              [-37.665833331942679, -8.699166659014054],
              [-37.666666666233596, -8.699166659014054],
              [-37.666666666233596, -8.699999992405651],
              [-37.668333333016847, -8.699999992405651],
              [-37.668333333016847, -8.700833326696625],
              [-37.669166665509181, -8.700833325797305],
              [-37.66916666730782, -8.702499993479876],
              [-37.669999998900778, -8.702499992580556],
              [-37.670000000699417, -8.704166658464487],
              [-37.671666666583349, -8.704166658464487],
              [-37.671666666583349, -8.704999992755404],
              [-37.674166665858877, -8.704999992755404],
              [-37.674166666758197, -8.705833326147058],
              [-37.678333331917713, -8.705833326147058],
              [-37.678333333716353, -8.704999992755404],
              [-37.680833333891201, -8.704999993654724],
              [-37.680833332991881, -8.704166658464487],
              [-37.682500000674452, -8.704166658464487],
              [-37.682500000674452, -8.704999992755404],
              [-37.685833332442314, -8.704999992755404],
              [-37.685833332442314, -8.705833327046378],
              [-37.686666665833911, -8.705833327046378],
              [-37.686666665833911, -8.709999994004477],
              [-37.687499999225565, -8.709999993105157],
              [-37.687499999225565, -8.710833325597434],
              [-37.688333331717843, -8.710833325597434],
              [-37.688333333516482, -8.709999993105157],
              [-37.689999999400413, -8.709999992205837],
              [-37.690000000299733, -8.709166659713503],
              [-37.69083333369133, -8.709166659713503],
              [-37.69083333279201, -8.709999993105157],
              [-37.692499998675942, -8.709999993105157],
              [-37.692499998675942, -8.710833326496754],
              [-37.697499997227055, -8.710833325597434],
              [-37.697499999925014, -8.711666659888408],
              [-37.698333331517972, -8.711666658989088],
              [-37.698333331517972, -8.710833324698115],
              [-37.699166667607585, -8.710833325597434],
              [-37.699166667607585, -8.711666658989088],
              [-37.699999998301223, -8.711666658989088],
              [-37.699999998301223, -8.712499992380685],
              [-37.701666666883114, -8.712499991481366],
              [-37.701666665983794, -8.71333332487302],
              [-37.702499999375448, -8.713333325772339],
              [-37.702499998476128, -8.714166659163936],
              [-37.704999998650976, -8.714166659163936],
              [-37.705000000449616, -8.71499999345491],
              [-37.705833332941893, -8.71499999255559],
              [-37.705833332042573, -8.714166659163936],
              [-37.708333331318158, -8.714166660063256],
              [-37.708333331318158, -8.71499999255559],
              [-37.71166666578398, -8.71499999255559],
              [-37.7116666666833, -8.714166659163936],
              [-37.713333332567174, -8.714166660063256],
              [-37.713333333466551, -8.713333325772339],
              [-37.714166665958828, -8.713333325772339],
              [-37.714999998451106, -8.71333332487302],
              [-37.714999998451106, -8.712499994179325],
              [-37.716666665234357, -8.712499992380685],
              [-37.716666667932316, -8.711666658989088],
              [-37.71749999862601, -8.711666658989088],
              [-37.71749999952533, -8.709166658814183],
              [-37.723333331468041, -8.709166658814183],
              [-37.723333331468041, -8.709999993105157],
              [-37.725000000949251, -8.709999993105157],
              [-37.724999998251292, -8.709166658814183],
              [-37.725833332542209, -8.709166658814183],
              [-37.725833332542209, -8.708333325422586],
              [-37.726666666833182, -8.708333325422586],
              [-37.726666667732502, -8.707499992030989],
              [-37.72749999932546, -8.707499992930309],
              [-37.727500000224779, -8.708333324523267],
              [-37.728333333616433, -8.708333325422586],
              [-37.728333333616433, -8.709166657914864],
              [-37.729999998600988, -8.709166658814183],
              [-37.730000000399627, -8.709999993105157],
              [-37.731666666283559, -8.709999993105157],
              [-37.731666665384239, -8.710833325597434],
              [-37.732499999675213, -8.710833325597434],
              [-37.732499997876573, -8.711666658989088],
              [-37.7350000016487, -8.711666658989088],
              [-37.734999999850061, -8.712499992380685],
              [-37.736666667532631, -8.712499991481366],
              [-37.736666666633312, -8.71333332487302],
              [-37.740000001099133, -8.713333328470299],
              [-37.740833331792771, -8.713333325772339],
              [-37.740833332692091, -8.711666659888408],
              [-37.742499998576022, -8.711666658989088],
              [-37.742499998576022, -8.710833325597434],
              [-37.743333333766316, -8.710833325597434],
              [-37.743333331967676, -8.709999993105157],
              [-37.744166665359273, -8.709999994004477],
              [-37.744166666258593, -8.709166658814183],
              [-37.744999999650247, -8.709166658814183],
              [-37.745000000549567, -8.707499992930309],
              [-37.745833331243205, -8.707499992930309],
              [-37.745833330343885, -8.706666658639335],
              [-37.750833332492277, -8.706666658639335],
              [-37.750833333391597, -8.707499992930309],
              [-37.756666668032267, -8.707499992030989],
              [-37.756666665334308, -8.706666658639335],
              [-37.764166667657548, -8.706666658639335],
              [-37.764166667657548, -8.705833324348419],
              [-37.770000000499579, -8.705833326147058],
              [-37.769999999600259, -8.706666658639335],
              [-37.77166666728283, -8.706666658639335],
              [-37.77166666728283, -8.707499992930309],
              [-37.774166665659038, -8.707499992930309],
              [-37.774166666558358, -8.706666659538655],
              [-37.774999999950012, -8.706666658639335],
              [-37.774999999950012, -8.705833326147058],
              [-37.77583333154297, -8.705833326147058],
              [-37.77583333154297, -8.706666658639335],
              [-37.781666667082959, -8.706666658639335],
              [-37.781666667082959, -8.707499992930309],
              [-37.785833332242419, -8.707499997426908],
              [-37.786666665634073, -8.707499992930309],
              [-37.786666665634073, -8.706666658639335],
              [-37.78749999902567, -8.706666658639335],
              [-37.787499999924989, -8.704166658464487],
              [-37.790833332592172, -8.704166658464487],
              [-37.790833331692852, -8.704999992755404],
              [-37.7933333318677, -8.704999991856084],
              [-37.7933333318677, -8.705833326147058],
              [-37.795833332941925, -8.705833327945697],
              [-37.795833332042605, -8.702499992580556],
              [-37.796666665434202, -8.702499992580556],
              [-37.796666666333522, -8.700833325797305],
              [-37.795833332941925, -8.700833325797305],
              [-37.795833332941925, -8.696666658839206],
              [-37.796666667232842, -8.696666658839206],
              [-37.796666665434202, -8.695833325447552],
              [-37.797499997926536, -8.695833325447552],
              [-37.797499999725176, -8.694999992955275],
              [-37.798333331318133, -8.694999992955275],
              [-37.798333332217453, -8.694166658664301],
              [-37.799166664709787, -8.694166657764981],
              [-37.799166667407746, -8.693333327071343],
              [-37.800000000799344, -8.693333326172024],
              [-37.799999999000704, -8.69249999278037],
              [-37.800833330593662, -8.69249999278037],
              [-37.800833331493038, -8.691666658489453],
              [-37.801666667582595, -8.691666658489453],
              [-37.801666667582595, -8.689166659213925],
              [-37.802499998276232, -8.689166657415285],
              [-37.802499998276232, -8.687499992430674],
              [-37.803333331667886, -8.687499992430674],
              [-37.803333331667886, -8.681666658689323],
              [-37.804166666858123, -8.681666658689323],
              [-37.804166667757443, -8.680833326196989],
              [-37.805000000249777, -8.680833326196989],
              [-37.805000000249777, -8.679999992805392],
              [-37.805833333641374, -8.679999993704712],
              [-37.805833333641374, -8.679166658514418],
              [-37.806666667932348, -8.679166658514418],
              [-37.806666665234388, -8.677499992630487],
              [-37.807499999525305, -8.677499992630487],
              [-37.807499999525305, -8.67666665923889],
              [-37.808333332916959, -8.67666665923889],
              [-37.808333332017639, -8.675833324947916],
              [-37.80999999970021, -8.675833325847236],
              [-37.80999999970021, -8.674999992455639],
              [-37.810833331293168, -8.674999992455639],
              [-37.810833333991127, -8.674166659064042],
              [-37.811666665584085, -8.674166659064042],
              [-37.811666666483404, -8.673333325672388],
              [-37.812500000774378, -8.673333325672388],
              [-37.812499998975738, -8.672499993180111],
              [-37.822500000574507, -8.672499993180111],
              [-37.822499998775868, -8.671666658889137],
              [-37.824166668257078, -8.671666659788457],
              [-37.824166665559119, -8.67083332549754],
              [-37.82583333144305, -8.67083332459822],
              [-37.829166665908872, -8.67083332549754],
              [-37.829166665908872, -8.669999993005206],
              [-37.829999998401149, -8.669999993005206],
              [-37.829999999300469, -8.669166658714289],
              [-37.830833331792803, -8.669166658714289],
              [-37.830833331792803, -8.668333324423315],
              [-37.832499998576054, -8.668333327121275],
              [-37.832499999475374, -8.666666658539441],
              [-37.834166666258625, -8.666666658539441],
              [-37.834166665359248, -8.665833326047107],
              [-37.834999998750902, -8.665833326047107],
              [-37.834999999650222, -8.664166658364536],
              [-37.835833333041819, -8.664166657465216],
              [-37.835833333041819, -8.662499992480605],
              [-37.836666667332793, -8.662499992480605],
              [-37.836666665534153, -8.660833325697354],
              [-37.838333332317404, -8.660833325697354],
              [-37.838333330518765, -8.657499993929548],
              [-37.84, -8.657499993030228],
              [-37.839999999100655, -8.656666658739255],
              [-37.840833332492252, -8.656666658739255],
              [-37.840833331592933, -8.654999992855323],
              [-37.841666665883906, -8.654999992855323],
              [-37.841666667682546, -8.653333326072072],
              [-37.842499998376184, -8.653333326072072],
              [-37.842500001074143, -8.650833325897224],
              [-37.843333331767781, -8.650833325897224],
              [-37.843333331767781, -8.649999992505627],
              [-37.844166666058754, -8.649999992505627],
              [-37.844166666058754, -8.649166659113973],
              [-37.844166666958074, -8.646666659838445],
              [-37.845833331942686, -8.646666658939125],
              [-37.845833332842005, -8.645833325547471],
              [-37.846666665334283, -8.645833326446791],
              [-37.846666665334283, -8.644999993055194],
              [-37.845833331942686, -8.644999993954514],
              [-37.845833334640645, -8.640833326996415],
              [-37.846666664434963, -8.640833326097095],
              [-37.846666666233602, -8.639999992705441],
              [-37.847499999625256, -8.639999992705441],
              [-37.847500000524576, -8.637499992530593],
              [-37.846666668032242, -8.637499992530593],
              [-37.846666665334283, -8.636666659138939],
              [-37.847499999625256, -8.636666659138939],
              [-37.847499999625256, -8.634999992355688],
              [-37.848333331218214, -8.634999992355688],
              [-37.848333333916173, -8.634166658964091],
              [-37.849166665509188, -8.634166658064771],
              [-37.849166664609868, -8.63249999308016],
              [-37.849999998001465, -8.63249999308016],
              [-37.849999998900785, -8.630833324498269],
              [-37.850833334091078, -8.630833325397589],
              [-37.850833331393062, -8.629999992905312],
              [-37.851666664784716, -8.629999992905312],
              [-37.851666667482675, -8.629166658614338],
              [-37.850833334990398, -8.629166658614338],
              [-37.850833331393062, -8.62833332612206],
              [-37.851666667482675, -8.62833332612206],
              [-37.851666667482675, -8.627499992730407],
              [-37.852499999974953, -8.627499992730407],
              [-37.852499999075633, -8.624999991656239],
              [-37.853333332467287, -8.624999992555558],
              [-37.853333331567967, -8.623333325772307],
              [-37.854166665858884, -8.623333325772307],
              [-37.854166666758204, -8.62249999238071],
              [-37.854999998351218, -8.622499991481391],
              [-37.855000001049177, -8.621666658989056],
              [-37.856666666933108, -8.621666658989056],
              [-37.856666666033789, -8.62083332469814],
              [-37.858333333716359, -8.620833325597459],
              [-37.858333334615679, -8.619166658814208],
              [-37.859166665309317, -8.619166658814208],
              [-37.859166666208637, -8.617499992930277],
              [-37.859999998700914, -8.617499992930277],
              [-37.859999999600234, -8.614166658464455],
              [-37.861666666383485, -8.614166658464455],
              [-37.861666665484165, -8.611666659188927],
              [-37.862499999775139, -8.611666659188927],
              [-37.862499999775139, -8.610833324897953],
              [-37.863333334066056, -8.610833324897953],
              [-37.863333331368096, -8.609999992405676],
              [-37.865000000849307, -8.609999992405676],
              [-37.865000000849307, -8.609166659014079],
              [-37.865833332442321, -8.609166659014079],
              [-37.865833331543001, -8.607499993130148],
              [-37.868333332617169, -8.607499993130148],
              [-37.868333333516489, -8.605833325447577],
              [-37.869166666908086, -8.605833325447577],
              [-37.869166666008766, -8.604166658664326],
              [-37.86999999940042, -8.604166658664326],
              [-37.86999999940042, -8.600833325997144],
              [-37.870833333691337, -8.600833326896463],
              [-37.870833331892698, -8.599999992605547],
              [-37.871666667982311, -8.599999992605547],
              [-37.871666667082991, -8.599166659213893],
              [-37.872499999575268, -8.599166659213893],
              [-37.872499999575268, -8.597499992430642],
              [-37.870833331892698, -8.597500000524576],
              [-37.866666665833918, -8.597499992430642],
              [-37.866666667632558, -8.596666659039045],
              [-37.864999999050667, -8.596666659039045],
              [-37.865000000849307, -8.59583332654671],
              [-37.861666666383485, -8.595833325647391],
              [-37.861666666383485, -8.594999993155113],
              [-37.859166666208637, -8.594999993155113],
              [-37.859166668007276, -8.594166658864197],
              [-37.85833333281704, -8.594166658864197],
              [-37.85833333281704, -8.594999991356474],
              [-37.855833332642135, -8.594999993155113],
              [-37.855833332642135, -8.594166658864197],
              [-37.854999998351218, -8.594166658864197],
              [-37.854999999250538, -8.576666658539409],
              [-37.856666666933108, -8.576666658539409],
              [-37.856666667832428, -8.574166657465241],
              [-37.857500000324706, -8.574166658364561],
              [-37.857499999425386, -8.573333326771547],
              [-37.85833333281704, -8.573333325872227],
              [-37.858333333716359, -8.57249999248063],
              [-37.859166666208637, -8.57249999158131],
              [-37.859166668007276, -8.571666659088976],
              [-37.860000000499554, -8.571666659988296],
              [-37.859999999600234, -8.570833325697379],
              [-37.860833332092568, -8.570833325697379],
              [-37.860833332991888, -8.569166658914128],
              [-37.861666666383485, -8.569166658914128],
              [-37.861666665484165, -8.56666665873928],
              [-37.862499998875819, -8.56666665873928],
              [-37.862499999775139, -8.565833327146265],
              [-37.863333331368096, -8.565833325347626],
              [-37.863333331368096, -8.564999992855348],
              [-37.86416666565907, -8.564999992855348],
              [-37.86416666655839, -8.564166658564375],
              [-37.864999999949987, -8.564166658564375],
              [-37.864999999050667, -8.563333326072097],
              [-37.865833333341641, -8.563333326072097],
              [-37.865833332442321, -8.561666658389527],
              [-37.865000000849307, -8.561666658389527],
              [-37.864999999050667, -8.559166659113998],
              [-37.86416666655839, -8.559166659113998],
              [-37.86416666655839, -8.558333325722344],
              [-37.864999999050667, -8.558333325722344],
              [-37.864999999050667, -8.556666659838413],
              [-37.865833331543001, -8.556666658939093],
              [-37.865833332442321, -8.555833325547496],
              [-37.866666667632558, -8.555833325547496],
              [-37.866666665833918, -8.554166658764245],
              [-37.867499998326196, -8.554166658764245],
              [-37.867499998326196, -8.553333325372648],
              [-37.866666666733238, -8.553333325372648],
              [-37.866666665833918, -8.551666657690077],
              [-37.867499999225515, -8.551666657690077],
              [-37.867499998326196, -8.549166658414492],
              [-37.868333333516489, -8.549166658414492],
              [-37.868333331717849, -8.548333325922215],
              [-37.87000000029974, -8.548333325922215],
              [-37.87000000029974, -8.545833326646687],
              [-37.870833331892698, -8.545833325747367],
              [-37.870833333691337, -8.544999992355713],
              [-37.871666667982311, -8.544999992355713],
              [-37.871666664385032, -8.543333325572462],
              [-37.872500000474588, -8.543333325572462],
              [-37.872499998675949, -8.542499993080185],
              [-37.8741666654592, -8.542499993080185],
              [-37.8741666654592, -8.540833325397614],
              [-37.876666666533367, -8.540833325397614],
              [-37.876666666533367, -8.541666658789211],
              [-37.877499999925021, -8.541666658789211],
              [-37.877499999925021, -8.540833324498294],
              [-37.879166664909633, -8.540833325397614],
              [-37.879166666708272, -8.539166658614363],
              [-37.880000000099869, -8.539166658614363],
              [-37.87999999830123, -8.536666658439515],
              [-37.879166665808953, -8.536666658439515],
              [-37.879166666708272, -8.535833325947181],
              [-37.87999999830123, -8.535833325947181],
              [-37.880000000099869, -8.534999992555584],
              [-37.88166666778244, -8.534999992555584],
              [-37.88166666688312, -8.53416665916393],
              [-37.884999999550303, -8.53416665916393],
              [-37.884999998650983, -8.530833325597428],
              [-37.8858333329419, -8.530833325597428],
              [-37.88583333204258, -8.527499992930302],
              [-37.886666665434234, -8.527499992930302],
              [-37.886666665434234, -8.525833326147051],
              [-37.887499998825831, -8.525833326147051],
              [-37.88750000152379, -8.52416665846448],
              [-37.886666666333554, -8.5241666593638],
              [-37.886666666333554, -8.521666659188952],
              [-37.885833333841219, -8.521666659188952],
              [-37.88583333204258, -8.520833325797298],
              [-37.889166666508402, -8.520833324897978],
              [-37.889166665609082, -8.513333326172017],
              [-37.888333331318165, -8.513333326172017],
              [-37.888333334016124, -8.5124999918811],
              [-37.889999999000736, -8.51249999278042],
              [-37.889999999000736, -8.511666658489446],
              [-37.890833333291653, -8.511666658489446],
              [-37.890833333291653, -8.509166659213918],
              [-37.89166666668325, -8.509166659213918],
              [-37.891666667582626, -8.507499992430667],
              [-37.892500000074904, -8.507499992430667],
              [-37.892500000974223, -8.504166658864165],
              [-37.89166666668325, -8.504166658864165],
              [-37.89166666668325, -8.503333325472568],
              [-37.892500000074904, -8.503333324573248],
              [-37.892499998276264, -8.502499992980233],
              [-37.893333333466501, -8.502499992980233],
              [-37.893333332567181, -8.500833326196982],
              [-37.894166665958835, -8.500833326196982],
              [-37.894166665958835, -8.499999992805385],
              [-37.898333332017614, -8.499999992805385],
              [-37.898333332017614, -8.499166658514412],
              [-37.899166667207908, -8.499166658514412],
              [-37.899166666308588, -8.498333326022134],
              [-37.899999998800865, -8.498333326022134],
              [-37.900000000599505, -8.497499992630537],
              [-37.900833333991102, -8.497499992630537],
              [-37.900833333091782, -8.496666659238883],
              [-37.902499998975713, -8.496666659238883],
              [-37.902500000774353, -8.497499992630537],
              [-37.903333330568728, -8.497499992630537],
              [-37.903333333266687, -8.496666659238883],
              [-37.904166665758964, -8.496666659238883],
              [-37.904166668456924, -8.495833325847286],
              [-37.904999997351979, -8.495833325847286],
              [-37.905000000049938, -8.494999992455632],
              [-37.905833332542215, -8.494999992455632],
              [-37.905833333441535, -8.494166659064035],
              [-37.905833333441535, -8.493333325672381],
              [-37.904999998251299, -8.493333325672381],
              [-37.905000000049938, -8.490833325497533],
              [-37.905833332542215, -8.490833326396853],
              [-37.905833332542215, -8.489999993005199],
              [-37.905000000949258, -8.489999993904576],
              [-37.905000000049938, -8.48583332694642],
              [-37.904166667557604, -8.4858333260471],
              [-37.904166665758964, -8.484999992655503],
              [-37.903333331468048, -8.484999992655503],
              [-37.903333331468048, -8.481666658189681],
              [-37.905000000049938, -8.481666659089001],
              [-37.905000000049938, -8.479166658014833],
              [-37.904166666658284, -8.479166658914153],
              [-37.904166664859645, -8.478333325522499],
              [-37.903333331468048, -8.478333324623179],
              [-37.903333333266687, -8.477499993929541],
              [-37.904166667557604, -8.477499993030222],
              [-37.904166666658284, -8.475833325347651],
              [-37.905000000949258, -8.475833325347651],
              [-37.905000000049938, -8.4741666585644],
              [-37.905833332542215, -8.4741666585644],
              [-37.905833333441535, -8.472499992680468],
              [-37.906666665933869, -8.472499992680468],
              [-37.906666666833189, -8.471666658389552],
              [-37.905833332542215, -8.471666660188191],
              [-37.905833331642896, -8.46833332482305],
              [-37.907499999325466, -8.468333325722369],
              [-37.907499998426147, -8.464999993954507],
              [-37.909999998600995, -8.464999993055187],
              [-37.909999998600995, -8.46416665876427],
              [-37.909999999500315, -8.459166658414517],
              [-37.908333331817744, -8.459166658414517],
              [-37.908333331817744, -8.456666659138989],
              [-37.907500000224786, -8.456666659138989],
              [-37.907499998426147, -8.455833325747335],
              [-37.906666666833189, -8.455833325747335],
              [-37.906666666833189, -8.454999992355738],
              [-37.904166667557604, -8.454999992355738],
              [-37.904166667557604, -8.454166658964084],
              [-37.905833332542215, -8.454166658964084],
              [-37.905833331642896, -8.453333325572487],
              [-37.906666666833189, -8.453333325572487],
              [-37.906666665933869, -8.452499993080153],
              [-37.907499998426147, -8.452499993080153],
              [-37.907499998426147, -8.449999992905305],
              [-37.906666665933869, -8.449999992905305],
              [-37.906666665933869, -8.449166659513651],
              [-37.905833330743576, -8.449166658614331],
              [-37.905833332542215, -8.448333327021373],
              [-37.904999998251299, -8.448333326122054],
              [-37.904999998251299, -8.447499993629776],
              [-37.903333333266687, -8.447499992730457],
              [-37.903333331468048, -8.446666657540163],
              [-37.902499999875033, -8.446666657540163],
              [-37.902500000774353, -8.444166659163955],
              [-37.901666665584116, -8.444166659163955],
              [-37.901666665584116, -8.443333325772301],
              [-37.900833330393823, -8.443333325772301],
              [-37.900833331293143, -8.442499992380704],
              [-37.900000000599505, -8.442499992380704],
              [-37.899999998800865, -8.44166665898905],
              [-37.899166665409211, -8.44166665898905],
              [-37.899166666308588, -8.440833325597453],
              [-37.898333332916934, -8.440833325597453],
              [-37.898333332017614, -8.439999993105175],
              [-37.897500000424657, -8.439999993105175],
              [-37.897499998626017, -8.438333325422605],
              [-37.896666665234363, -8.438333325422605],
              [-37.896666667932323, -8.43749999293027],
              [-37.895833332742086, -8.43749999293027],
              [-37.895833332742086, -8.436666657740034],
              [-37.896666665234363, -8.436666658639353],
              [-37.896666667932323, -8.435833326147019],
              [-37.895833333641406, -8.435833326147019],
              [-37.895833330943447, -8.43166665918892],
              [-37.895000000249752, -8.43166665918892],
              [-37.894999999350432, -8.430833326696643],
              [-37.895833333641406, -8.430833325797323],
              [-37.895833332742086, -8.429999992405669],
              [-37.894999999350432, -8.429999992405669],
              [-37.895000000249752, -8.426666659738487],
              [-37.893333331667861, -8.426666658839167],
              [-37.893333332567181, -8.424999992955236],
              [-37.894166667757474, -8.424999992955236],
              [-37.894166667757474, -8.419166659213886],
              [-37.894166667757474, -8.418333326721609],
              [-37.889999999900056, -8.418333326721609],
              [-37.889999999000736, -8.419166659213886],
              [-37.889166667407721, -8.419166659213886],
              [-37.889166667407721, -8.418333324922969],
              [-37.887499998825831, -8.418333325822289],
              [-37.887499999725151, -8.417499991531315],
              [-37.886666667232873, -8.417499992430635],
              [-37.886666667232873, -8.416666658139718],
              [-37.8858333329419, -8.416666659039038],
              [-37.88583333204258, -8.415833325647441],
              [-37.88166666868176, -8.415833324748064],
              [-37.88166666778244, -8.413333325472536],
              [-37.882499999375455, -8.413333325472536],
              [-37.882500000274774, -8.411666659588604],
              [-37.883333333666371, -8.411666658689285],
              [-37.883333332767052, -8.408333326022159],
              [-37.884166667957345, -8.408333326022159],
              [-37.884166667057968, -8.407499992630505],
              [-37.885000000449622, -8.407499992630505],
              [-37.884999998650983, -8.406666657440269],
              [-37.886666667232873, -8.406666659238908],
              [-37.886666665434234, -8.404999992455657],
              [-37.887499998825831, -8.404999991556338],
              [-37.88750000062447, -8.403333325672406],
              [-37.888333331318165, -8.403333324773087],
              [-37.888333332217485, -8.402499993180072],
              [-37.889166665609082, -8.402499993180072],
              [-37.889166667407721, -8.401666658889155],
              [-37.890000000799375, -8.401666658889155],
              [-37.889999999000736, -8.400833325497501],
              [-37.890833332392333, -8.400833325497501],
              [-37.890833331493013, -8.399166659613627],
              [-37.892499998276264, -8.399166658714307],
              [-37.892500000074904, -8.398333326221973],
              [-37.891666667582626, -8.398333326221973],
              [-37.89166666668325, -8.392499993379943],
              [-37.892500000974223, -8.392499992480623],
              [-37.892500000974223, -8.390833325697372],
              [-37.887499997926511, -8.390833325697372],
              [-37.887499998825831, -8.391666659089026],
              [-37.883333331867732, -8.391666659089026],
              [-37.883333332767052, -8.390833325697372],
              [-37.880833332592204, -8.390833325697372],
              [-37.880833332592204, -8.391666659089026],
              [-37.880000000099869, -8.391666659089026],
              [-37.87999999830123, -8.392499992480623],
              [-37.876666666533367, -8.392499992480623],
              [-37.876666665634048, -8.39333332587222],
              [-37.875000000649493, -8.39333332587222],
              [-37.874999999750173, -8.394166658364554],
              [-37.870833331892698, -8.394166658364554],
              [-37.870833333691337, -8.394999992655471],
              [-37.869166666008766, -8.394999992655471],
              [-37.869166666908086, -8.395833326047125],
              [-37.867499998326196, -8.395833326047125],
              [-37.867500001024212, -8.398333327121293],
              [-37.866666667632558, -8.398333326221973],
              [-37.866666665833918, -8.399166658714307],
              [-37.865000000849307, -8.399166658714307],
              [-37.865000000849307, -8.398333326221973],
              [-37.86416666565907, -8.398333326221973],
              [-37.86416666565907, -8.399166658714307],
              [-37.862499999775139, -8.399166658714307],
              [-37.862499999775139, -8.398333327121293],
              [-37.859999999600234, -8.398333326221973],
              [-37.859999998700914, -8.397499992830376],
              [-37.857500000324706, -8.397499992830376],
              [-37.857499999425386, -8.395833326047125],
              [-37.856666666033789, -8.395833326047125],
              [-37.856666666033789, -8.394999992655471],
              [-37.855833332642135, -8.394999992655471],
              [-37.855833331742815, -8.3933333249729],
              [-37.854999998351218, -8.39333332587222],
              [-37.854999999250538, -8.392499992480623],
              [-37.850833334990398, -8.392499992480623],
              [-37.850833331393062, -8.389999993205095],
              [-37.849166668207147, -8.389999993205095],
              [-37.849166667307827, -8.389166659813441],
              [-37.848333333916173, -8.389166658914121],
              [-37.848333333016853, -8.388333325522524],
              [-37.844999998551032, -8.388333325522524],
              [-37.844999999450351, -8.38749999303019],
              [-37.8433333326671, -8.38749999303019],
              [-37.843333331767781, -8.386666657839953],
              [-37.842499998376184, -8.386666658739273],
              [-37.842500001074143, -8.385833325347619],
              [-37.841666667682546, -8.385833325347619],
              [-37.841666665883906, -8.384999992855342],
              [-37.840833332492252, -8.384999992855342],
              [-37.840833331592933, -8.38166665838952],
              [-37.84, -8.381666660188159],
              [-37.840000000899295, -8.380833325897243],
              [-37.839166665709001, -8.380833327695882],
              [-37.836666666433473, -8.380833325897243],
              [-37.836666665534153, -8.379999992505589],
              [-37.834166666258625, -8.379999992505589],
              [-37.834166666258625, -8.380833325897243],
              [-37.828333331617898, -8.380833325897243],
              [-37.828333333416538, -8.379166660013311],
              [-37.82583333144305, -8.379166659113991],
              [-37.82583333324169, -8.378333325722338],
              [-37.825000000749412, -8.378333325722338],
              [-37.825000000749412, -8.37749999323006],
              [-37.823333331268202, -8.37749999323006],
              [-37.823333333966161, -8.376666658939087],
              [-37.822499998775868, -8.376666658939087],
              [-37.822500000574507, -8.375833325547489],
              [-37.821666665384271, -8.375833325547489],
              [-37.821666666283591, -8.374999993055212],
              [-37.820833331992617, -8.374999993055212],
              [-37.820833331992617, -8.374166658764238],
              [-37.81999999950034, -8.374166658764238],
              [-37.820000001298979, -8.373333324473322],
              [-37.819166667008005, -8.373333325372641],
              [-37.819166667907382, -8.372499992880307],
              [-37.816666667732477, -8.372499993779627],
              [-37.816666665933838, -8.37166665858939],
              [-37.815000000949226, -8.37166665858939],
              [-37.814999997351947, -8.372499992880307],
              [-37.814166668456949, -8.372499992880307],
              [-37.814166667557629, -8.373333325372641],
              [-37.811666665584085, -8.373333324473322],
              [-37.811666665584085, -8.376666659838406],
              [-37.809166665409236, -8.376666658939087],
              [-37.809166665409236, -8.37749999323006],
              [-37.808333332017639, -8.37749999323006],
              [-37.808333332916959, -8.376666658939087],
              [-37.807499999525305, -8.376666658939087],
              [-37.807500000424625, -8.375833325547489],
              [-37.806666665234388, -8.375833325547489],
              [-37.806666665234388, -8.374999993055212],
              [-37.805833332742054, -8.374999993954532],
              [-37.805833333641374, -8.373333325372641],
              [-37.804999999350457, -8.373333325372641],
              [-37.805000000249777, -8.372499992880307],
              [-37.802499998276232, -8.372499992880307],
              [-37.802500000974248, -8.37166665858939],
              [-37.798333334016093, -8.37166665948871],
              [-37.798333334915412, -8.372499992880307],
              [-37.796666665434202, -8.372499992880307],
              [-37.796666666333522, -8.371666657690071],
              [-37.794999999550271, -8.37166665858939],
              [-37.795000000449591, -8.370833326097056],
              [-37.792499998476103, -8.370833326996376],
              [-37.792500000274742, -8.369999992705459],
              [-37.791666666883145, -8.369999993604779],
              [-37.791666667782465, -8.369166658414485],
              [-37.790000000099894, -8.369166658414485],
              [-37.790000000099894, -8.369999991806139],
              [-37.788333331518004, -8.369999992705459],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '255',
        OBJECTID: 277.0,
        Nome_area: 'Catimbau',
        COD_area: 'CA178',
        Import_bio: 'Extremamente Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Criação de UC de Proteção Integral',
        Area_ha: 287909.462,
        Shape_Leng: 5.87751,
        Shape_Area: 0.23647,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.160225323, -8.170400441999957],
              [-37.159999999373127, -8.170496602688726],
              [-37.160000000099899, -8.170833326496791],
              [-37.159210988991703, -8.170833326283919],
              [-37.149999999058224, -8.174764272845236],
              [-37.149999999400393, -8.176666658439501],
              [-37.144999999950016, -8.176666660238141],
              [-37.144999999950016, -8.176193815014244],
              [-37.14119224, -8.177234077999977],
              [-37.133197434, -8.175880527999944],
              [-37.130493184115046, -8.17499999255557],
              [-37.130000000699454, -8.17499999255557],
              [-37.130000000526145, -8.174839406201096],
              [-37.126451610999901, -8.17368400899994],
              [-37.118260061999898, -8.175351880999937],
              [-37.11866176, -8.18039028299995],
              [-37.118948753, -8.183989965999954],
              [-37.119843679, -8.184734229999943],
              [-37.124493103, -8.188600915999935],
              [-37.124806604, -8.189834547999965],
              [-37.126099785, -8.191496279999971],
              [-37.1251989639999, -8.19890629499997],
              [-37.123389892, -8.207394076999954],
              [-37.122914846, -8.209066410999984],
              [-37.118551248, -8.214976162999962],
              [-37.114490969, -8.220087315999931],
              [-37.112807812, -8.223110650999963],
              [-37.110394269, -8.22554909999997],
              [-37.108588219, -8.22663652999996],
              [-37.105815607, -8.227828134999978],
              [-37.104275559, -8.228980903999961],
              [-37.102664314, -8.231099205999952],
              [-37.100428483, -8.233706838999973],
              [-37.100427107, -8.233706254999957],
              [-37.097226584999909, -8.232347097999925],
              [-37.089892692, -8.231165888999952],
              [-37.083006931999897, -8.222402679999959],
              [-37.080895352, -8.221891480999938],
              [-37.078333331318163, -8.223880638438571],
              [-37.078333331318163, -8.224166659063997],
              [-37.077964938779928, -8.224166659063997],
              [-37.075833333245875, -8.225821641412246],
              [-37.075833332941897, -8.226666659238902],
              [-37.0749999995503, -8.226666659238902],
              [-37.07500000044962, -8.22916665851443],
              [-37.07333333186773, -8.22916665851443],
              [-37.07333333186773, -8.229999992805404],
              [-37.072499999375452, -8.229999992805404],
              [-37.072499999375452, -8.22916665851443],
              [-37.071524974927698, -8.22916665851443],
              [-37.07133278, -8.229315878999969],
              [-37.067417651, -8.230102475999953],
              [-37.06666666569344, -8.232555032116785],
              [-37.066666666236507, -8.233058250581312],
              [-37.067299839331035, -8.233333326030193],
              [-37.068333331517977, -8.233333325472529],
              [-37.068333331517977, -8.233782315934684],
              [-37.074169880999897, -8.236317943999955],
              [-37.076898690585203, -8.238333325605975],
              [-37.077499998825829, -8.238333325822282],
              [-37.077499998945648, -8.238777426145532],
              [-37.080620413, -8.241082029999964],
              [-37.0815081699999, -8.245667209999963],
              [-37.0822268269999, -8.261887405999975],
              [-37.081203367999898, -8.26637351399995],
              [-37.081201576, -8.266374046999932],
              [-37.078780765, -8.267093177999929],
              [-37.077499999725148, -8.26747360209272],
              [-37.077499999725148, -8.268333326122047],
              [-37.0749999995503, -8.268333326122047],
              [-37.074999998650981, -8.269166659513701],
              [-37.074166665259327, -8.269166658614381],
              [-37.06916666580895, -8.269166658614381],
              [-37.06916666580895, -8.268333325222727],
              [-37.068333331517977, -8.268333325222727],
              [-37.068333332417296, -8.266666658439476],
              [-37.067499999025699, -8.266666658439476],
              [-37.067500000221621, -8.266112572777864],
              [-37.066283878237435, -8.265833328487043],
              [-37.060833332792015, -8.265833335839716],
              [-37.059166666008764, -8.265833326846519],
              [-37.059166666008764, -8.264999992555545],
              [-37.058333332617167, -8.264999993454865],
              [-37.058333332617167, -8.264166659163948],
              [-37.057674707798618, -8.264166659163948],
              [-37.056249507, -8.264633565999928],
              [-37.054977477, -8.265050292999945],
              [-37.049252839, -8.265277509999985],
              [-37.043333748, -8.264559861999938],
              [-37.0402836109999, -8.266179553999969],
              [-37.040112854, -8.266385152999923],
              [-37.031832650999903, -8.276311652999937],
              [-37.027857627999907, -8.283405300999956],
              [-37.025000000017677, -8.286478502986137],
              [-37.02499999965022, -8.287499993230085],
              [-37.024166665359303, -8.287499993230085],
              [-37.024166665359303, -8.28916665821464],
              [-37.023333331967649, -8.28916665911396],
              [-37.023333331967649, -8.289999992505614],
              [-37.022499998576052, -8.289999992505614],
              [-37.022500000374691, -8.290833325897211],
              [-37.022499999475372, -8.291666657490225],
              [-37.023333331967649, -8.291666660188184],
              [-37.023333331967649, -8.292499992680462],
              [-37.024166665359303, -8.292499992680462],
              [-37.024166666258623, -8.293333326072116],
              [-37.025833331243177, -8.293333326072116],
              [-37.025833333041817, -8.294166658564393],
              [-37.026666666433471, -8.294166658564393],
              [-37.026666665534151, -8.294999992855367],
              [-37.027500000724388, -8.294999992855367],
              [-37.027499999825068, -8.295833325347644],
              [-37.029166667507639, -8.295833325347644],
              [-37.029166664809679, -8.297499992130895],
              [-37.029166665708999, -8.298333325522492],
              [-37.028333331418082, -8.298333325522492],
              [-37.028333333216722, -8.299166658914146],
              [-37.027500000724388, -8.299166658914146],
              [-37.027499999825068, -8.303333326771565],
              [-37.029999999100653, -8.303333325872245],
              [-37.03, -8.304166658364522],
              [-37.03083333339157, -8.304166658364522],
              [-37.03083333159293, -8.304999992655496],
              [-37.031666667682543, -8.304999992655496],
              [-37.031666667682543, -8.305833326047093],
              [-37.032499999275501, -8.305833326047093],
              [-37.032500001074141, -8.306666658539427],
              [-37.033333333566475, -8.306666658539427],
              [-37.033333333566475, -8.307499992830344],
              [-37.034999998551029, -8.307499992830344],
              [-37.034999998551029, -8.308333326221998],
              [-37.035833332842003, -8.308333326221998],
              [-37.035833331942683, -8.311666658889123],
              [-37.03666666803224, -8.311666658889123],
              [-37.03666666803224, -8.312499993180097],
              [-37.038333331218212, -8.312499993180097],
              [-37.038333333016851, -8.313333326571694],
              [-37.038333333916171, -8.314166659064028],
              [-37.037499999625254, -8.314166659064028],
              [-37.037499999625254, -8.314999992455625],
              [-37.03666666533428, -8.314999992455625],
              [-37.03666666533428, -8.315833325847279],
              [-37.035833332842003, -8.315833325847279],
              [-37.035833331942683, -8.322499992980227],
              [-37.03666666533428, -8.322499992980227],
              [-37.0366666662336, -8.324166658864158],
              [-37.039999999800102, -8.324166658864158],
              [-37.039999998900782, -8.326666659938383],
              [-37.040833332292436, -8.326666659938383],
              [-37.04083333049374, -8.328333324922937],
              [-37.041666665684033, -8.328333325822257],
              [-37.041666664784714, -8.331666658489439],
              [-37.041666665684033, -8.332499992780413],
              [-37.038333331218212, -8.332499992780413],
              [-37.038333331218212, -8.33333332617201],
              [-37.037499998725934, -8.33333332437337],
              [-37.037499998725934, -8.334166658664344],
              [-37.038333333016851, -8.334166658664344],
              [-37.038333333016851, -8.335833325447538],
              [-37.039166666408505, -8.335833325447538],
              [-37.039166664609866, -8.339166658114721],
              [-37.037499999625254, -8.33916665901404],
              [-37.037499999625254, -8.340833325797291],
              [-37.0366666662336, -8.340833325797291],
              [-37.0366666662336, -8.345833326147044],
              [-37.034166666958072, -8.345833324348405],
              [-37.034166666958072, -8.346666658639322],
              [-37.032499998376181, -8.346666658639322],
              [-37.032499998376181, -8.350833325597478],
              [-37.031666666783224, -8.350833324698158],
              [-37.031666667682543, -8.351666658989075],
              [-37.03083333159293, -8.351666658989075],
              [-37.03083333159293, -8.352499992380672],
              [-37.031666666783224, -8.352499992380672],
              [-37.031666665883904, -8.354166659163923],
              [-37.035833332842003, -8.354166659163923],
              [-37.035833332842003, -8.354999992555577],
              [-37.03666666803224, -8.354999993454896],
              [-37.03666666533428, -8.356666658439508],
              [-37.039999998900782, -8.356666658439508],
              [-37.039999998900782, -8.355833325947174],
              [-37.04249999997495, -8.355833326846493],
              [-37.04250000087427, -8.354999992555577],
              [-37.043333331567965, -8.354999992555577],
              [-37.043333333366604, -8.354166659163923],
              [-37.049166668007274, -8.354166659163923],
              [-37.049166665309315, -8.356666657540188],
              [-37.049999998700912, -8.356666657540188],
              [-37.050000001398928, -8.362499993080178],
              [-37.050833332092566, -8.362499993080178],
              [-37.050833332092566, -8.363333325572455],
              [-37.052499999775137, -8.363333324673135],
              [-37.052499999775137, -8.364166658964109],
              [-37.053333331368094, -8.364166658064789],
              [-37.053333334066053, -8.364999992355706],
              [-37.054999999949985, -8.364999992355706],
              [-37.054999999949985, -8.36583332574736],
              [-37.056666665833916, -8.36583332574736],
              [-37.056666666733236, -8.366666659138957],
              [-37.060833331892695, -8.366666657340318],
              [-37.060833331892695, -8.367499992530611],
              [-37.062499998675946, -8.367499993429931],
              [-37.062499998675946, -8.368333325022888],
              [-37.064166665459197, -8.368333325922208],
              [-37.064166665459197, -8.367499992530611],
              [-37.064999999750171, -8.367499992530611],
              [-37.064999999750171, -8.366666657340318],
              [-37.066666666533365, -8.366666659138957],
              [-37.066666665634045, -8.36583332574736],
              [-37.067499999025699, -8.36583332574736],
              [-37.067499999925019, -8.364999992355706],
              [-37.068333333316616, -8.364999992355706],
              [-37.068333332417296, -8.364166658964109],
              [-37.06916666670827, -8.364166658964109],
              [-37.06916666670827, -8.363333325572455],
              [-37.070000000999187, -8.363333325572455],
              [-37.069999998301228, -8.362499993979498],
              [-37.070833331692882, -8.362499993080178],
              [-37.070833332592201, -8.363333325572455],
              [-37.072499999375452, -8.363333325572455],
              [-37.072499998476133, -8.364166658964109],
              [-37.07333333186773, -8.364166658964109],
              [-37.073333332767049, -8.363333325572455],
              [-37.074166667957343, -8.363333325572455],
              [-37.074166667058023, -8.362499993080178],
              [-37.0749999995503, -8.362499993080178],
              [-37.07500000044962, -8.361666658789204],
              [-37.075833333841217, -8.361666658789204],
              [-37.075833332042578, -8.360833325397607],
              [-37.076666667232871, -8.360833327196247],
              [-37.076666665434232, -8.359999993804649],
              [-37.077499997926509, -8.35999999290533],
              [-37.077499999725148, -8.360833325397607],
              [-37.079999999000734, -8.360833324498287],
              [-37.079999999000734, -8.359999993804649],
              [-37.080833332392331, -8.35999999290533],
              [-37.08083333329165, -8.359166658614356],
              [-37.082500000074901, -8.359166657715036],
              [-37.082500000074901, -8.358333326122079],
              [-37.084166665958833, -8.358333326122079],
              [-37.084166666858152, -8.357499991831105],
              [-37.08500000024975, -8.357499991831105],
              [-37.08499999935043, -8.356666658439508],
              [-37.085833332742084, -8.356666658439508],
              [-37.085833331842764, -8.358333325222759],
              [-37.08499999845111, -8.358333325222759],
              [-37.08499999935043, -8.359166658614356],
              [-37.084166665059513, -8.359166658614356],
              [-37.084166665958833, -8.35999999290533],
              [-37.083333332567179, -8.35999999290533],
              [-37.083333332567179, -8.361666658789204],
              [-37.082500000074901, -8.361666658789204],
              [-37.082499998276262, -8.362499993080178],
              [-37.081666666683304, -8.362499992180858],
              [-37.081666667582624, -8.364999992355706],
              [-37.080833331493011, -8.364999992355706],
              [-37.08083333329165, -8.36583332574736],
              [-37.07916666560908, -8.36583332574736],
              [-37.07916666560908, -8.366666659138957],
              [-37.081666665783928, -8.366666659138957],
              [-37.081666666683304, -8.367499991631234],
              [-37.082499998276262, -8.367499991631234],
              [-37.082500000974221, -8.368333325922208],
              [-37.081666666683304, -8.368333325922208],
              [-37.081666666683304, -8.369999992705459],
              [-37.080833331493011, -8.369999992705459],
              [-37.080833332392331, -8.37166665858939],
              [-37.080000000799373, -8.37166665858939],
              [-37.079999999000734, -8.373333324473322],
              [-37.079166667407719, -8.373333325372641],
              [-37.079166666508399, -8.374166659663558],
              [-37.078333334016122, -8.374166658764238],
              [-37.078333332217483, -8.374999992155892],
              [-37.076666666333551, -8.374999993055212],
              [-37.076666665434232, -8.375833325547489],
              [-37.075833332042578, -8.375833325547489],
              [-37.075833332042578, -8.379166660013311],
              [-37.074166665259327, -8.379166659113991],
              [-37.074166667058023, -8.378333325722338],
              [-37.070833331692882, -8.378333325722338],
              [-37.070833331692882, -8.379166659113991],
              [-37.070000000099867, -8.379166658214672],
              [-37.070000000099867, -8.378333325722338],
              [-37.06916666760759, -8.378333325722338],
              [-37.06916666580895, -8.379166658214672],
              [-37.068333332417296, -8.379166659113991],
              [-37.068333332417296, -8.378333325722338],
              [-37.067499999025699, -8.378333325722338],
              [-37.067499999025699, -8.379166659113991],
              [-37.066666666533365, -8.379166659113991],
              [-37.066666665634045, -8.379999991606269],
              [-37.06500000154881, -8.379999992505589],
              [-37.064999999750171, -8.379166658214672],
              [-37.0633333320676, -8.379166659113991],
              [-37.06333333296692, -8.378333325722338],
              [-37.059999999400418, -8.378333325722338],
              [-37.059999999400418, -8.37749999323006],
              [-37.056666666733236, -8.37749999323006],
              [-37.056666667632555, -8.376666658939087],
              [-37.049999999600232, -8.376666658939087],
              [-37.049999997801592, -8.375833326446809],
              [-37.046666666933106, -8.375833326446809],
              [-37.046666664235147, -8.376666658039767],
              [-37.044166665858882, -8.376666658939087],
              [-37.044166666758201, -8.37749999323006],
              [-37.043333331567965, -8.377499991431421],
              [-37.043333332467284, -8.378333325722338],
              [-37.04250000087427, -8.378333325722338],
              [-37.042499999075631, -8.379166659113991],
              [-37.041666665684033, -8.379166660013311],
              [-37.041666665684033, -8.379999992505589],
              [-37.04083333139306, -8.379999992505589],
              [-37.04083333139306, -8.380833325897243],
              [-37.039999998900782, -8.380833325897243],
              [-37.040000000699422, -8.38166665838952],
              [-37.039166667307825, -8.38166665838952],
              [-37.039166665509185, -8.382499992680494],
              [-37.038333331218212, -8.382499992680494],
              [-37.038333331218212, -8.385833324448299],
              [-37.037499998725934, -8.385833325347619],
              [-37.037500000524574, -8.386666658739273],
              [-37.035833331043364, -8.386666657839953],
              [-37.033333331767778, -8.386666658739273],
              [-37.033333332667098, -8.385833325347619],
              [-37.032499999275501, -8.385833325347619],
              [-37.032499998376181, -8.384999992855342],
              [-37.031666665883904, -8.384999992855342],
              [-37.031666665883904, -8.382499991781174],
              [-37.03083333159293, -8.382499992680494],
              [-37.03083333249225, -8.380833325897243],
              [-37.029999999100653, -8.380833324997923],
              [-37.030000000899292, -8.374999993055212],
              [-37.029166665708999, -8.374999993055212],
              [-37.029166665708999, -8.373333324473322],
              [-37.028333332317402, -8.373333325372641],
              [-37.028333332317402, -8.371666657690071],
              [-37.027499999825068, -8.37166665858939],
              [-37.027499998925748, -8.369166658414485],
              [-37.026666665534151, -8.369166660213125],
              [-37.026666665534151, -8.367499992530611],
              [-37.025833333041817, -8.367499992530611],
              [-37.025833331243177, -8.366666659138957],
              [-37.025000000549539, -8.366666659138957],
              [-37.025000000549539, -8.36583332574736],
              [-37.023333331967649, -8.36583332574736],
              [-37.023333333766288, -8.364999992355706],
              [-37.022499999475372, -8.364999992355706],
              [-37.022499998576052, -8.364166658964109],
              [-37.021666666983037, -8.364166658964109],
              [-37.021666666983037, -8.363333325572455],
              [-37.020833332692121, -8.363333325572455],
              [-37.020833331792801, -8.361666658789204],
              [-37.016666666633341, -8.361666658789204],
              [-37.016666666633341, -8.360833325397607],
              [-37.01500000074941, -8.360833325397607],
              [-37.01499999985009, -8.359999992005953],
              [-37.0133333312682, -8.35999999290533],
              [-37.013333333066839, -8.359166658614356],
              [-37.009999998601018, -8.359166658614356],
              [-37.010000000399657, -8.358333326122079],
              [-37.006666665933835, -8.358333326122079],
              [-37.006666665933835, -8.359166657715036],
              [-37.005833332542238, -8.359166658614356],
              [-37.005833331642918, -8.358333325222759],
              [-37.004999998251265, -8.358333326122079],
              [-37.005000000949224, -8.357499991831105],
              [-37.004166666658307, -8.357499992730425],
              [-37.004166665758987, -8.356666657540188],
              [-37.002500000774376, -8.356666658439508],
              [-37.002499998975736, -8.355833325047854],
              [-37.001666667382779, -8.355833325947174],
              [-37.001666667382779, -8.354166657365283],
              [-37.000833333991125, -8.354166659163923],
              [-37.000833333091805, -8.352499992380672],
              [-36.999999999700208, -8.352499994179368],
              [-37.000000000599528, -8.351666658989075],
              [-36.999166665409234, -8.351666658989075],
              [-36.999166664509914, -8.350833325597478],
              [-36.997499999525303, -8.350833325597478],
              [-36.997499999525303, -8.351666658989075],
              [-36.994999999350455, -8.351666659888394],
              [-36.994999998451135, -8.352499992380672],
              [-36.99416666775744, -8.352499992380672],
              [-36.994166666858121, -8.356666658439508],
              [-36.99249999827623, -8.356666658439508],
              [-36.992500000074926, -8.358333326122079],
              [-36.991666665783953, -8.358333326122079],
              [-36.991666665783953, -8.359166659513676],
              [-36.989999999000702, -8.359166658614356],
              [-36.989999999000702, -8.363333324673135],
              [-36.989166666508424, -8.363333325572455],
              [-36.989166664709785, -8.364999992355706],
              [-36.987500000624493, -8.364999992355706],
              [-36.987500000624493, -8.36583332574736],
              [-36.984166667957311, -8.36583332484804],
              [-36.984166665259352, -8.366666659138957],
              [-36.983333332767074, -8.366666659138957],
              [-36.983333333666394, -8.36583332574736],
              [-36.981666667782463, -8.36583332574736],
              [-36.981666666883143, -8.364999992355706],
              [-36.98083333169285, -8.364999994154346],
              [-36.980833332592169, -8.364166658964109],
              [-36.979999998301253, -8.364166658964109],
              [-36.980000000099892, -8.362499993080178],
              [-36.979166665808918, -8.362499993080178],
              [-36.979166665808918, -8.361666658789204],
              [-36.978333332417321, -8.361666658789204],
              [-36.978333334215961, -8.360833325397607],
              [-36.977499999924987, -8.360833325397607],
              [-36.977499999924987, -8.35999999290533],
              [-36.976666664734751, -8.35999999290533],
              [-36.97666666743271, -8.359166658614356],
              [-36.971666665284317, -8.359166658614356],
              [-36.971666665284317, -8.358333326122079],
              [-36.969166666908109, -8.358333326122079],
              [-36.96916666421015, -8.357499992730425],
              [-36.963333332267439, -8.357499991831105],
              [-36.963333331368119, -8.356666660238147],
              [-36.959999997801617, -8.356666658439508],
              [-36.959999999600257, -8.355833325947174],
              [-36.959166668007299, -8.355833325947174],
              [-36.95916666620866, -8.353333326671645],
              [-36.959999999600257, -8.353333325772326],
              [-36.959999999600257, -8.352499992380672],
              [-36.958333331917686, -8.352499994179368],
              [-36.958333333716325, -8.354166659163923],
              [-36.957499999425409, -8.354166659163923],
              [-36.957499998526089, -8.354999992555577],
              [-36.954999998351184, -8.354999992555577],
              [-36.954999999250504, -8.355833325947174],
              [-36.949999999800127, -8.355833326846493],
              [-36.949999998900807, -8.356666658439508],
              [-36.948333333016876, -8.356666658439508],
              [-36.948333331218237, -8.357499992730425],
              [-36.947499998725903, -8.357499993629744],
              [-36.947500000524542, -8.358333326122079],
              [-36.946666665334305, -8.358333326122079],
              [-36.946666665334305, -8.360833327196247],
              [-36.944999999450374, -8.360833325397607],
              [-36.945000000349694, -8.359166658614356],
              [-36.944166667857417, -8.359166658614356],
              [-36.94416666605872, -8.358333326122079],
              [-36.943333332667123, -8.358333325222759],
              [-36.943333332667123, -8.356666658439508],
              [-36.941666665883872, -8.356666658439508],
              [-36.941666666783192, -8.355833325947174],
              [-36.940833331592955, -8.355833325947174],
              [-36.940833332492275, -8.353333325772326],
              [-36.939999999100621, -8.353333325772326],
              [-36.939999999100621, -8.352499992380672],
              [-36.939166665709024, -8.352499992380672],
              [-36.939166666608344, -8.354166659163923],
              [-36.933333333766313, -8.354166659163923],
              [-36.933333331967674, -8.355833325947174],
              [-36.93249999947534, -8.355833325947174],
              [-36.93249999947534, -8.356666657540188],
              [-36.931666666083743, -8.356666657540188],
              [-36.931666666983062, -8.358333326122079],
              [-36.930833331792769, -8.358333326122079],
              [-36.930833332692089, -8.359166658614356],
              [-36.930000001099131, -8.359166658614356],
              [-36.929999998401172, -8.35999999290533],
              [-36.927500000024963, -8.35999999290533],
              [-36.927499998226267, -8.360833325397607],
              [-36.923333333066807, -8.360833325397607],
              [-36.923333331268168, -8.35999999290533],
              [-36.92250000147385, -8.35999999290533],
              [-36.922499998775891, -8.359166658614356],
              [-36.919166667008028, -8.359166658614356],
              [-36.919166667907348, -8.359999992005953],
              [-36.918333331817792, -8.35999999290533],
              [-36.918333331817792, -8.360833325397607],
              [-36.910833331293134, -8.360833325397607],
              [-36.910833331293134, -8.359166658614356],
              [-36.910000000599496, -8.359166657715036],
              [-36.910000000599496, -8.358333326122079],
              [-36.907499999525328, -8.358333326122079],
              [-36.907499998626008, -8.357499992730425],
              [-36.900833331493004, -8.357499991831105],
              [-36.900833333291644, -8.356666660238147],
              [-36.899999999900047, -8.356666658439508],
              [-36.899999999900047, -8.354999992555577],
              [-36.899999999900047, -8.350833325597478],
              [-36.899166665609073, -8.350833325597478],
              [-36.899166665609073, -8.349999993105143],
              [-36.898333334016115, -8.349999994004463],
              [-36.898333331318156, -8.349166658814227],
              [-36.897500000624461, -8.349166658814227],
              [-36.897499998825822, -8.348333325422573],
              [-36.896666666333545, -8.348333325422573],
              [-36.896666666333545, -8.347499992930295],
              [-36.895833333841267, -8.347499992930295],
              [-36.895833332042571, -8.346666658639322],
              [-36.894166667058016, -8.346666658639322],
              [-36.894166665259377, -8.345833326147044],
              [-36.892499998476126, -8.345833324348405],
              [-36.892499998476126, -8.34499999275539],
              [-36.891666667782431, -8.344999991856071],
              [-36.891666665983792, -8.344166658464474],
              [-36.890833330793555, -8.344166658464474],
              [-36.890833332592194, -8.338333325622443],
              [-36.889166666708263, -8.338333326521763],
              [-36.889166666708263, -8.337499994029429],
              [-36.88833333241729, -8.337499994029429],
              [-36.888333333316609, -8.335833325447538],
              [-36.886666666533415, -8.335833326346915],
              [-36.886666667432735, -8.334999992955261],
              [-36.885833331343122, -8.334999993854581],
              [-36.885833332242441, -8.335833324548219],
              [-36.884999999750164, -8.335833325447538],
              [-36.884999998850844, -8.336666658839192],
              [-36.88416666545919, -8.336666658839192],
              [-36.88416666635851, -8.335833325447538],
              [-36.883333332966913, -8.335833325447538],
              [-36.882500000474579, -8.335833325447538],
              [-36.882500000474579, -8.336666658839192],
              [-36.881666667082982, -8.336666658839192],
              [-36.881666665284342, -8.337499993130109],
              [-36.880833331892688, -8.337499993130109],
              [-36.880833333691328, -8.338333325622443],
              [-36.879999998501091, -8.338333324723123],
              [-36.879999998501091, -8.339166658114721],
              [-36.879166666908134, -8.33916665901404],
              [-36.879166666908134, -8.339999992405694],
              [-36.87833333351648, -8.339999994204334],
              [-36.87833333171784, -8.340833325797291],
              [-36.877499997426924, -8.340833325797291],
              [-36.877499998326243, -8.341666659188945],
              [-36.875833331542992, -8.341666659188945],
              [-36.875833332442312, -8.344166658464474],
              [-36.873333332267407, -8.344166658464474],
              [-36.873333332267407, -8.34499999275539],
              [-36.87249999887581, -8.34499999275539],
              [-36.87249999977513, -8.345833327046364],
              [-36.870833332092559, -8.345833326147044],
              [-36.870833332991879, -8.348333327221212],
              [-36.869999999600282, -8.348333325422573],
              [-36.870000000499601, -8.349166658814227],
              [-36.869166666208628, -8.349166659713546],
              [-36.86833333371635, -8.349166658814227],
              [-36.868333331917711, -8.348333325422573],
              [-36.866666667832419, -8.348333325422573],
              [-36.866666666933099, -8.347499992930295],
              [-36.865833331742806, -8.347499992930295],
              [-36.865833331742806, -8.346666658639322],
              [-36.864999998351209, -8.346666658639322],
              [-36.864999999250529, -8.347499992030976],
              [-36.864166666758194, -8.347499992030976],
              [-36.864166667657571, -8.346666657740002],
              [-36.863333332467278, -8.346666658639322],
              [-36.863333331567958, -8.345833324348405],
              [-36.861666665684027, -8.345833326147044],
              [-36.861666667482666, -8.34499999275539],
              [-36.858333331218205, -8.344999991856071],
              [-36.858333333916164, -8.344166658464474],
              [-36.856666665334274, -8.344166657565154],
              [-36.856666665334274, -8.343333325972196],
              [-36.854999999450399, -8.343333325972196],
              [-36.854999998551023, -8.341666660088265],
              [-36.853333333566468, -8.341666659188945],
              [-36.853333331767828, -8.340833325797291],
              [-36.851666665883897, -8.340833325797291],
              [-36.851666665883897, -8.339999992405694],
              [-36.849166666608312, -8.339999992405694],
              [-36.849166665708992, -8.338333325622443],
              [-36.848333330518756, -8.338333325622443],
              [-36.848333331418075, -8.334999992955261],
              [-36.847499998925741, -8.334999992955261],
              [-36.847500000724438, -8.334166658664344],
              [-36.846666667332784, -8.334166658664344],
              [-36.846666667332784, -8.33333332707133],
              [-36.845833331243227, -8.33333332437337],
              [-36.845833333041867, -8.332499993679733],
              [-36.837500000924251, -8.332499992780413],
              [-36.837499997326972, -8.331666658489439],
              [-36.834999998950764, -8.331666658489439],
              [-36.835000000749403, -8.330833325997162],
              [-36.83416666645843, -8.330833325997162],
              [-36.83416666555911, -8.331666658489439],
              [-36.832500000574498, -8.331666659388759],
              [-36.832499998775859, -8.330833325997162],
              [-36.831666668082221, -8.330833325997162],
              [-36.831666665384262, -8.331666658489439],
              [-36.827499999325482, -8.331666658489439],
              [-36.827499998426163, -8.330833325997162],
              [-36.8241666666583, -8.330833326896482],
              [-36.82416666575898, -8.329999992605508],
              [-36.821666666483452, -8.329999992605508],
              [-36.821666667382772, -8.329166659213911],
              [-36.820833331293159, -8.329166659213911],
              [-36.820833331293159, -8.328333326721577],
              [-36.819166667207867, -8.328333325822257],
              [-36.819166664509908, -8.329999992605508],
              [-36.816666665234379, -8.329999992605508],
              [-36.816666665234379, -8.330833325997162],
              [-36.814999998451128, -8.330833326896482],
              [-36.814999999350448, -8.329999992605508],
              [-36.811666665783946, -8.329999992605508],
              [-36.811666666683266, -8.330833325997162],
              [-36.809166664709778, -8.330833325997162],
              [-36.809166665609098, -8.331666658489439],
              [-36.802499998476094, -8.331666657590119],
              [-36.802499998476094, -8.330833325997162],
              [-36.801666667782456, -8.330833325997162],
              [-36.801666666883136, -8.331666658489439],
              [-36.799166664909592, -8.331666658489439],
              [-36.799166666708231, -8.330833325997162],
              [-36.798333332417315, -8.330833326896482],
              [-36.798333333316634, -8.329999992605508],
              [-36.797499999025717, -8.329999992605508],
              [-36.797499999925037, -8.329166660113231],
              [-36.796666667432703, -8.329166660113231],
              [-36.796666665634064, -8.328333325822257],
              [-36.794166667257855, -8.328333325822257],
              [-36.794166666358535, -8.32749999243066],
              [-36.791666667982327, -8.32749999243066],
              [-36.791666665284311, -8.326666659039063],
              [-36.790833332792033, -8.326666659039063],
              [-36.790833333691353, -8.325833325647409],
              [-36.78999999850106, -8.325833325647409],
              [-36.78999999850106, -8.323333324573241],
              [-36.789166668706741, -8.323333325472561],
              [-36.789166666908102, -8.322499992080907],
              [-36.787500001024171, -8.322499992980227],
              [-36.787499999225531, -8.323333325472561],
              [-36.777499998526082, -8.323333324573241],
              [-36.777499999425402, -8.324166658864158],
              [-36.77583333444079, -8.324166658864158],
              [-36.775833330843511, -8.324999993155132],
              [-36.774999999250497, -8.324999993155132],
              [-36.774999999250497, -8.325833325647409],
              [-36.772499999075649, -8.325833325647409],
              [-36.772500000874288, -8.326666658139743],
              [-36.769999998900801, -8.326666659039063],
              [-36.769999998900801, -8.327499994229299],
              [-36.770833331393078, -8.32749999243066],
              [-36.770833332292398, -8.332499992780413],
              [-36.771666665684052, -8.332499992780413],
              [-36.771666667482691, -8.33333332707133],
              [-36.772499999974968, -8.33333332707133],
              [-36.772499999075649, -8.334166657765024],
              [-36.773333331567926, -8.334166658664344],
              [-36.773333333366622, -8.334999992955261],
              [-36.7741666658589, -8.334999992955261],
              [-36.77416666495958, -8.335833324548219],
              [-36.77583333354147, -8.335833325447538],
              [-36.775833330843511, -8.341666659188945],
              [-36.776666666033748, -8.341666660088265],
              [-36.776666666033748, -8.342499991681223],
              [-36.779166665309333, -8.342499992580542],
              [-36.779166668007292, -8.343333325972196],
              [-36.780833332092584, -8.343333325972196],
              [-36.780833332092584, -8.344166658464474],
              [-36.781666665484181, -8.344166657565154],
              [-36.781666667282821, -8.345833326147044],
              [-36.783333332267432, -8.345833326147044],
              [-36.783333334066072, -8.34499999275539],
              [-36.787499998326211, -8.34499999275539],
              [-36.787499999225531, -8.345833326147044],
              [-36.788333332617185, -8.345833326147044],
              [-36.788333333516505, -8.346666658639322],
              [-36.790833334590673, -8.346666658639322],
              [-36.790833331892713, -8.347499992930295],
              [-36.791666667982327, -8.347499992930295],
              [-36.791666665284311, -8.348333325422573],
              [-36.795833332242466, -8.348333325422573],
              [-36.795833332242466, -8.347499992930295],
              [-36.796666665634064, -8.347499992930295],
              [-36.796666667432703, -8.346666658639322],
              [-36.797499997227021, -8.346666658639322],
              [-36.797499997227021, -8.347499992930295],
              [-36.802499999375414, -8.347499992930295],
              [-36.802499998476094, -8.348333325422573],
              [-36.801666665983817, -8.348333325422573],
              [-36.801666665983817, -8.350833325597478],
              [-36.802499999375414, -8.350833325597478],
              [-36.802499998476094, -8.35999999290533],
              [-36.803333333666387, -8.35999999290533],
              [-36.803333332767068, -8.360833325397607],
              [-36.804166665259345, -8.360833325397607],
              [-36.804166667057984, -8.361666658789204],
              [-36.804999998650999, -8.361666657889884],
              [-36.804999998650999, -8.362499993080178],
              [-36.805833332941916, -8.362499993080178],
              [-36.805833332941916, -8.364166658964109],
              [-36.806666665434193, -8.364166658964109],
              [-36.806666667232889, -8.364999992355706],
              [-36.807499999725167, -8.364999992355706],
              [-36.807499999725167, -8.366666660038277],
              [-36.806666665434193, -8.366666659138957],
              [-36.806666667232889, -8.367499991631234],
              [-36.805833332042596, -8.367499992530611],
              [-36.805833333841235, -8.368333325922208],
              [-36.804999999550319, -8.368333326821528],
              [-36.804999997751679, -8.369999992705459],
              [-36.804166667057984, -8.369999992705459],
              [-36.804166667957304, -8.370833326097056],
              [-36.803333333666387, -8.370833326097056],
              [-36.803333331867748, -8.372499992880307],
              [-36.804166667057984, -8.372499992880307],
              [-36.804166665259345, -8.380833325897243],
              [-36.803333332767068, -8.380833325897243],
              [-36.803333332767068, -8.382499992680494],
              [-36.802500000274733, -8.382499991781174],
              [-36.802499999375414, -8.384999992855342],
              [-36.801666665983817, -8.384999992855342],
              [-36.801666666883136, -8.385833325347619],
              [-36.805000001348958, -8.385833325347619],
              [-36.804999999550319, -8.386666658739273],
              [-36.805833332042596, -8.386666658739273],
              [-36.805833332941916, -8.38749999303019],
              [-36.80666666633357, -8.38749999303019],
              [-36.80666666633357, -8.389166658914121],
              [-36.807500000624486, -8.389166658914121],
              [-36.807499999725167, -8.389999993205095],
              [-36.809166666508418, -8.389999993205095],
              [-36.809166666508418, -8.390833325697372],
              [-36.810000000799334, -8.390833325697372],
              [-36.809999999900015, -8.392499992480623],
              [-36.811666665783946, -8.392499992480623],
              [-36.811666666683266, -8.39333332587222],
              [-36.814166666858171, -8.39333332587222],
              [-36.814166665958851, -8.394166658364554],
              [-36.815000002048407, -8.394166658364554],
              [-36.814999999350448, -8.394999992655471],
              [-36.815833332742045, -8.394999992655471],
              [-36.815833331842725, -8.395833326047125],
              [-36.816666667033019, -8.395833325147805],
              [-36.817499997726657, -8.395833326946445],
              [-36.817499999525296, -8.394999992655471],
              [-36.81833333201763, -8.394999991756151],
              [-36.81833333201763, -8.394166658364554],
              [-36.822500000774369, -8.394166658364554],
              [-36.822499998975729, -8.394999992655471],
              [-36.823333333266646, -8.394999992655471],
              [-36.823333332367326, -8.395833326047125],
              [-36.82416666575898, -8.395833326047125],
              [-36.82416666575898, -8.396666658539402],
              [-36.825833333441551, -8.396666658539402],
              [-36.825833331642912, -8.395833326047125],
              [-36.826666665933828, -8.395833326047125],
              [-36.826666665933828, -8.396666659438722],
              [-36.82833333181776, -8.396666658539402],
              [-36.82833333181776, -8.397499992830376],
              [-36.829166667008053, -8.397499992830376],
              [-36.829166667907373, -8.398333326221973],
              [-36.83000000039965, -8.398333326221973],
              [-36.82999999950033, -8.399999993005224],
              [-36.831666666283581, -8.399999993005224],
              [-36.831666666283581, -8.401666658889155],
              [-36.832499999675179, -8.401666658889155],
              [-36.832499999675179, -8.402499994079392],
              [-36.833333331268193, -8.402499993180072],
              [-36.833333333966152, -8.406666659238908],
              [-36.834166667357749, -8.406666659238908],
              [-36.83416666555911, -8.408333326921479],
              [-36.83416666555911, -8.409166658514437],
              [-36.830833332891984, -8.409166659413756],
              [-36.830833331992608, -8.408333326921479],
              [-36.829166667907373, -8.408333326022159],
              [-36.829166665209414, -8.407499993529825],
              [-36.823333332367326, -8.407499992630505],
              [-36.823333333266646, -8.407500034898646],
              [-36.823333333266646, -8.411666658689285],
              [-36.822499998975729, -8.411666658689285],
              [-36.822499998975729, -8.413333325472536],
              [-36.821666665584132, -8.413333325472536],
              [-36.821666665584132, -8.415833325647441],
              [-36.822499998975729, -8.415833325647441],
              [-36.822499998975729, -8.416666659039038],
              [-36.823333332367326, -8.416666659039038],
              [-36.823333331468007, -8.417499992430635],
              [-36.8241666666583, -8.417499992430635],
              [-36.82416666575898, -8.418333325822289],
              [-36.824999998251258, -8.418333325822289],
              [-36.824999997351938, -8.419166659213886],
              [-36.825833331642912, -8.419166659213886],
              [-36.825833333441551, -8.420833325997137],
              [-36.826666666833148, -8.420833325997137],
              [-36.826666666833148, -8.421666658489471],
              [-36.827499998426163, -8.421666659388791],
              [-36.827499998426163, -8.422499992780388],
              [-36.829166664310094, -8.422499992780388],
              [-36.829166665209414, -8.423333326172042],
              [-36.82999999950033, -8.423333326172042],
              [-36.83000000129897, -8.424166658664319],
              [-36.830833331093288, -8.424166658664319],
              [-36.830833332891984, -8.42583332544757],
              [-36.831666668082221, -8.42583332544757],
              [-36.831666666283581, -8.426666658839167],
              [-36.832499999675179, -8.426666657939847],
              [-36.832499999675179, -8.427499993130141],
              [-36.833333331268193, -8.427499993130141],
              [-36.833333331268193, -8.429166659014072],
              [-36.834166667357749, -8.429166659014072],
              [-36.834166667357749, -8.429999992405669],
              [-36.835000001648723, -8.429999992405669],
              [-36.835000000749403, -8.429166658114752],
              [-36.836666666633334, -8.429166659014072],
              [-36.836666665734015, -8.429999994204309],
              [-36.839166665908863, -8.429999992405669],
              [-36.839166666808183, -8.430833325797323],
              [-36.841666666983031, -8.430833326696643],
              [-36.841666666083711, -8.43166665918892],
              [-36.842500000374685, -8.43166666008824],
              [-36.842499999475365, -8.432499992580574],
              [-36.843333332866962, -8.432499992580574],
              [-36.843333331068322, -8.433333325072851],
              [-36.844166665359296, -8.433333325972171],
              [-36.844166666258616, -8.434166657565129],
              [-36.844999998750893, -8.434166658464449],
              [-36.844999997851573, -8.434999992755422],
              [-36.846666665534144, -8.434999992755422],
              [-36.846666667332784, -8.435833326147019],
              [-36.848333331418075, -8.435833326147019],
              [-36.848333332317395, -8.436666658639353],
              [-36.849166667507632, -8.436666658639353],
              [-36.849166665708992, -8.438333327221244],
              [-36.85, -8.438333327221244],
              [-36.849999999100646, -8.439166657914882],
              [-36.850833333391563, -8.439166658814202],
              [-36.850833333391563, -8.44166665898905],
              [-36.851666667682537, -8.44166665898905],
              [-36.851666668581856, -8.442499992380704],
              [-36.852500001074134, -8.442499992380704],
              [-36.852500001074134, -8.444166659163955],
              [-36.853333332667148, -8.444166659163955],
              [-36.853333332667148, -8.444999993454871],
              [-36.854166666958065, -8.444999992555552],
              [-36.854166666058745, -8.445833325947206],
              [-36.855833331942677, -8.445833325947206],
              [-36.855833332841996, -8.447499992730457],
              [-36.856666668032233, -8.447499993629776],
              [-36.856666666233593, -8.448333327021373],
              [-36.857500000524567, -8.448333326122054],
              [-36.857499999625247, -8.449166658614331],
              [-36.858333333916164, -8.449166658614331],
              [-36.858333333916164, -8.449999992905305],
              [-36.859166667307818, -8.449999992905305],
              [-36.859166666408498, -8.451666660587875],
              [-36.859999998900776, -8.451666659688556],
              [-36.859999999800095, -8.452499993080153],
              [-36.861666666583346, -8.452499993080153],
              [-36.861666665684027, -8.453333325572487],
              [-36.862499999975, -8.453333325572487],
              [-36.862499999975, -8.454166659863404],
              [-36.865833331742806, -8.454166658964084],
              [-36.865833332642126, -8.454999992355738],
              [-36.866666666933099, -8.454999992355738],
              [-36.866666666933099, -8.455833324848015],
              [-36.867499999425377, -8.455833324848015],
              [-36.867499998526057, -8.456666659138989],
              [-36.86833333371635, -8.456666659138989],
              [-36.869166666208628, -8.456666659138989],
              [-36.869166666208628, -8.455833325747335],
              [-36.870833332991879, -8.455833325747335],
              [-36.870833332092559, -8.454999991456418],
              [-36.87249999887581, -8.454999992355738],
              [-36.87249999887581, -8.454166658964084],
              [-36.8741666674577, -8.454166658064764],
              [-36.874166665659061, -8.453333325572487],
              [-36.874999999949978, -8.453333325572487],
              [-36.875000000849298, -8.451666658789236],
              [-36.874166666558381, -8.451666658789236],
              [-36.874166666558381, -8.449166658614331],
              [-36.87249999887581, -8.449166657715011],
              [-36.872500000674449, -8.448333326122054],
              [-36.871666667282852, -8.448333325222734],
              [-36.871666667282852, -8.447499992730457],
              [-36.870000001398921, -8.447499992730457],
              [-36.870000000499601, -8.444999992555552],
              [-36.869166668007267, -8.444999992555552],
              [-36.869166666208628, -8.444166659163955],
              [-36.86833333371635, -8.444166659163955],
              [-36.868333331917711, -8.44166665898905],
              [-36.869166666208628, -8.44166665808973],
              [-36.869166668007267, -8.440833325597453],
              [-36.869999998700962, -8.440833325597453],
              [-36.869999999600282, -8.439999993105175],
              [-36.870833332092559, -8.439999993105175],
              [-36.870833332991879, -8.439166659713521],
              [-36.875000000849298, -8.439166659713521],
              [-36.874999999949978, -8.438333325422605],
              [-36.875833332442312, -8.438333325422605],
              [-36.875833332442312, -8.43749999293027],
              [-36.876666668531868, -8.43749999293027],
              [-36.876666667632549, -8.436666659538673],
              [-36.877500001024202, -8.436666658639353],
              [-36.877499998326243, -8.435833326147019],
              [-36.87833333261716, -8.435833326147019],
              [-36.87833333171784, -8.434999991856102],
              [-36.879166666008814, -8.434999993654742],
              [-36.879166667807453, -8.434166663860424],
              [-36.880833332792008, -8.434166658464449],
              [-36.880833333691328, -8.434999992755422],
              [-36.892499999375445, -8.434999992755422],
              [-36.892500000274765, -8.434166658464449],
              [-36.894166665259377, -8.434166658464449],
              [-36.894166667957336, -8.434999992755422],
              [-36.894999999550294, -8.434999992755422],
              [-36.894999999550294, -8.435833326147019],
              [-36.895833332042571, -8.435833326147019],
              [-36.895833332941891, -8.43749999293027],
              [-36.896666665434225, -8.43749999293027],
              [-36.896666667232864, -8.44166665898905],
              [-36.898333334016115, -8.44166665898905],
              [-36.898333334016115, -8.440833326496772],
              [-36.899999999000727, -8.440833325597453],
              [-36.899999999900047, -8.44166665898905],
              [-36.902499998276255, -8.44166665808973],
              [-36.902500000074895, -8.442499991481384],
              [-36.903333332567229, -8.442499992380704],
              [-36.903333333466549, -8.443333325772301],
              [-36.904166666858146, -8.443333325772301],
              [-36.904166667757465, -8.444166659163955],
              [-36.904999999350423, -8.444166659163955],
              [-36.904999998451103, -8.445833325947206],
              [-36.912500000774401, -8.445833325947206],
              [-36.912499998975704, -8.449166658614331],
              [-36.913333331468039, -8.449166658614331],
              [-36.913333332367358, -8.449999992905305],
              [-36.912500000774401, -8.449999992905305],
              [-36.912499998975704, -8.451666658789236],
              [-36.91499999735197, -8.451666658789236],
              [-36.91499999825129, -8.452499993080153],
              [-36.917499999325457, -8.452499993080153],
              [-36.917500000224777, -8.453333325572487],
              [-36.918333331817792, -8.453333325572487],
              [-36.918333331817792, -8.461666658589365],
              [-36.92249999967521, -8.461666658589365],
              [-36.92249999967521, -8.462499992880339],
              [-36.923333331268168, -8.462499992880339],
              [-36.923333333066807, -8.463333325372616],
              [-36.924166665559142, -8.463333325372616],
              [-36.924166666458461, -8.462499992880339],
              [-36.924999999850058, -8.462499992880339],
              [-36.924999998950739, -8.461666658589365],
              [-36.925833332342393, -8.461666658589365],
              [-36.925833331443073, -8.459999992705434],
              [-36.926666667532629, -8.459999992705434],
              [-36.92666666573399, -8.459166658414517],
              [-36.927500000924283, -8.459166658414517],
              [-36.927500000024963, -8.458333325922183],
              [-36.928333332517241, -8.458333325022863],
              [-36.928333331617921, -8.454999992355738],
              [-36.929166665908838, -8.454999992355738],
              [-36.929166666808158, -8.454166658964084],
              [-36.930000001099131, -8.454166658964084],
              [-36.929999998401172, -8.452499992180833],
              [-36.930833333591409, -8.452499993080153],
              [-36.930833332692089, -8.451666658789236],
              [-36.929999999300492, -8.451666659688556],
              [-36.929999998401172, -8.449999992905305],
              [-36.930833333591409, -8.449999992905305],
              [-36.930833333591409, -8.449166657715011],
              [-36.93249999857602, -8.449166658614331],
              [-36.93249999947534, -8.448333326122054],
              [-36.93833333141805, -8.448333326122054],
              [-36.93833333231737, -8.447499992730457],
              [-36.939166667507664, -8.447499992730457],
              [-36.939166665709024, -8.446666658439483],
              [-36.94, -8.446666658439483],
              [-36.939999999100621, -8.444999992555552],
              [-36.939166665709024, -8.444999991656232],
              [-36.939166667507664, -8.444166660063274],
              [-36.939999999100621, -8.444166659163955],
              [-36.94, -8.442499992380704],
              [-36.940833332492275, -8.442499992380704],
              [-36.940833334290915, -8.441666659888369],
              [-36.943333333566443, -8.44166665898905],
              [-36.943333333566443, -8.442499992380704],
              [-36.944166667857417, -8.442499992380704],
              [-36.944166667857417, -8.443333325772301],
              [-36.948333333916196, -8.443333329369636],
              [-36.949166667307793, -8.443333325772301],
              [-36.949166665509154, -8.444166659163955],
              [-36.950000000699447, -8.444166659163955],
              [-36.949999999800127, -8.445833325947206],
              [-36.950833330493765, -8.445833325947206],
              [-36.950833331393085, -8.446666658439483],
              [-36.951666665684058, -8.446666658439483],
              [-36.951666665684058, -8.445833325947206],
              [-36.954166665858907, -8.445833325947206],
              [-36.954166665858907, -8.444999992555552],
              [-36.954999997451864, -8.444999992555552],
              [-36.954999999250504, -8.444166657365315],
              [-36.955833332642158, -8.444166659163955],
              [-36.955833330843518, -8.443333325772301],
              [-36.956666666033755, -8.443333325772301],
              [-36.956666666933074, -8.442499992380704],
              [-36.959999998700937, -8.442499992380704],
              [-36.959999998700937, -8.44333332667162],
              [-36.960833332092534, -8.443333325772301],
              [-36.960833332991854, -8.445833325947206],
              [-36.961666666383508, -8.445833325947206],
              [-36.961666665484188, -8.446666658439483],
              [-36.963333331368119, -8.446666658439483],
              [-36.963333332267439, -8.447499993629776],
              [-36.964166665659036, -8.447499993629776],
              [-36.964166665659036, -8.450833325397582],
              [-36.96499999995001, -8.450833325397582],
              [-36.965000000849329, -8.451666658789236],
              [-36.964166665659036, -8.451666659688556],
              [-36.964166667457675, -8.454999992355738],
              [-36.962500000674424, -8.454999992355738],
              [-36.962499998875785, -8.455833324848015],
              [-36.96083333389123, -8.455833325747335],
              [-36.96083333389123, -8.456666659138989],
              [-36.959999999600257, -8.456666659138989],
              [-36.959999998700937, -8.459166658414517],
              [-36.96083333389123, -8.459166658414517],
              [-36.96083333389123, -8.459999992705434],
              [-36.961666666383508, -8.459999992705434],
              [-36.961666666383508, -8.460833326097088],
              [-36.962499998875785, -8.460833326097088],
              [-36.962500000674424, -8.459999992705434],
              [-36.963333332267439, -8.459999991806114],
              [-36.963333334066078, -8.460833326097088],
              [-36.96499999905069, -8.460833326097088],
              [-36.96499999905069, -8.461666658589365],
              [-36.965833333341607, -8.461666659488685],
              [-36.965833330643648, -8.46416665876427],
              [-36.966666666733261, -8.46416665876427],
              [-36.966666666733261, -8.464999993055187],
              [-36.967499998326218, -8.464999993055187],
              [-36.967499998326218, -8.465833325547464],
              [-36.968333332617135, -8.465833325547464],
              [-36.968333331717815, -8.46833332482305],
              [-36.967500001024177, -8.468333325722369],
              [-36.967499999225538, -8.469166659113966],
              [-36.966666665833941, -8.469166659113966],
              [-36.966666665833941, -8.471666658389552],
              [-36.965833333341607, -8.471666658389552],
              [-36.965833332442287, -8.472499992680468],
              [-36.966666665833941, -8.472499992680468],
              [-36.966666666733261, -8.4741666585644],
              [-36.967499999225538, -8.4741666585644],
              [-36.967499999225538, -8.474999993754636],
              [-36.968333331717815, -8.474999992855317],
              [-36.968333332617135, -8.475833325347651],
              [-36.969166667807428, -8.47583332714629],
              [-36.969166666008789, -8.476666658739248],
              [-36.969999998501066, -8.476666658739248],
              [-36.969999999400386, -8.477499993030222],
              [-36.97083333189272, -8.477499993030222],
              [-36.970833330993401, -8.478333326421819],
              [-36.969999999400386, -8.478333325522499],
              [-36.969999997601747, -8.479166658014833],
              [-36.969166666008789, -8.479166658914153],
              [-36.969166667807428, -8.479999994104389],
              [-36.968333331717815, -8.47999999140643],
              [-36.968333331717815, -8.481666659089001],
              [-36.967500001024177, -8.481666659089001],
              [-36.967499998326218, -8.484999992655503],
              [-36.968333333516512, -8.484999992655503],
              [-36.968333332617135, -8.486666658539434],
              [-36.969166666908109, -8.486666658539434],
              [-36.969166666908109, -8.489166658714282],
              [-36.97083333189272, -8.489166659613602],
              [-36.97083333279204, -8.489999993005199],
              [-36.971666667082957, -8.489999993005199],
              [-36.971666665284317, -8.49166665888913],
              [-36.972499999575291, -8.49166665888913],
              [-36.972499998675971, -8.493333325672381],
              [-36.97666666743271, -8.493333325672381],
              [-36.97666666563407, -8.494166659064035],
              [-36.977499999025667, -8.494166659064035],
              [-36.977499999924987, -8.490833325497533],
              [-36.978333333316641, -8.490833325497533],
              [-36.978333332417321, -8.487499992830351],
              [-36.980833332592169, -8.487499992830351],
              [-36.98083333169285, -8.488333324423309],
              [-36.98249999937542, -8.488333326222005],
              [-36.982499998476101, -8.489166659613602],
              [-36.983333332767074, -8.489166658714282],
              [-36.983333332767074, -8.488333326222005],
              [-36.984166665259352, -8.488333324423309],
              [-36.984166665259352, -8.487499991931031],
              [-36.985000000449645, -8.487499992830351],
              [-36.985000000449645, -8.4858333260471],
              [-36.985833332042603, -8.4858333260471],
              [-36.985833332042603, -8.484166658364529],
              [-36.991666665783953, -8.484166658364529],
              [-36.991666666683273, -8.483333325872252],
              [-36.998333334715596, -8.483333325872252],
              [-36.998333332916957, -8.484166658364529],
              [-36.999166666308554, -8.484166658364529],
              [-36.999166666308554, -8.483333325872252],
              [-37.002499999875056, -8.483333326771572],
              [-37.002499998975736, -8.482499992480598],
              [-37.003333331468014, -8.482499991581278],
              [-37.003333331468014, -8.481666658189681],
              [-37.004166666658307, -8.481666659089001],
              [-37.004166667557627, -8.47999999320507],
              [-37.004999998251265, -8.47999999140643],
              [-37.004999998251265, -8.479166658914153],
              [-37.005833332542238, -8.479166659813473],
              [-37.005833332542238, -8.478333325522499],
              [-37.006666665933835, -8.478333325522499],
              [-37.006666667732475, -8.474999992855317],
              [-37.008333331817767, -8.474999992855317],
              [-37.008333332717086, -8.475833325347651],
              [-37.00916666790738, -8.475833325347651],
              [-37.00916666700806, -8.476666658739248],
              [-37.009999998601018, -8.476666657839928],
              [-37.009999998601018, -8.477499993030222],
              [-37.010833333791254, -8.477499993030222],
              [-37.010833333791254, -8.479166658914153],
              [-37.010833332891934, -8.47999999320507],
              [-37.011666668082228, -8.47999999320507],
              [-37.011666666283588, -8.481666659089001],
              [-37.013333333066839, -8.481666659089001],
              [-37.013333333066839, -8.482499992480598],
              [-37.014166666458436, -8.482499993379918],
              [-37.014166666458436, -8.483333325872252],
              [-37.01499999985009, -8.483333325872252],
              [-37.01499999985009, -8.484166660163169],
              [-37.015833331443048, -8.484166658364529],
              [-37.015833330543728, -8.4858333260471],
              [-37.016666665734022, -8.48583332694642],
              [-37.016666665734022, -8.486666658539434],
              [-37.017499998226299, -8.486666659438754],
              [-37.017500000924258, -8.487499992830351],
              [-37.018333331617896, -8.487499993729671],
              [-37.018333331617896, -8.489166658714282],
              [-37.017499998226299, -8.489166658714282],
              [-37.017499998226299, -8.490833325497533],
              [-37.017500000024938, -8.491666657989811],
              [-37.018333331617896, -8.49166665888913],
              [-37.018333331617896, -8.492499993180104],
              [-37.01916666590887, -8.492499991381464],
              [-37.019166666808189, -8.494166659064035],
              [-37.019999998401147, -8.494166659064035],
              [-37.020000001099106, -8.494999992455632],
              [-37.022499999475372, -8.494999993354952],
              [-37.022499999475372, -8.495833325847286],
              [-37.021666666083718, -8.495833325847286],
              [-37.021666666983037, -8.497499992630537],
              [-37.022500001274011, -8.497499992630537],
              [-37.022499998576052, -8.499166659413731],
              [-37.023333332866969, -8.499166658514412],
              [-37.023333332866969, -8.499999993704705],
              [-37.024166666258623, -8.499999993704705],
              [-37.024166665359303, -8.500833326196982],
              [-37.025000000549539, -8.500833326196982],
              [-37.0249999987509, -8.501666657789997],
              [-37.025833334840513, -8.501666658689317],
              [-37.025833330343858, -8.502499992980233],
              [-37.02666666733279, -8.502499993879553],
              [-37.026666665534151, -8.503333324573248],
              [-37.026666665534151, -8.504166658864165],
              [-37.025000000549539, -8.504166659763484],
              [-37.0249999987509, -8.504999993155138],
              [-37.021666667882357, -8.504999994054458],
              [-37.021666666983037, -8.505833325647416],
              [-37.020833331792801, -8.505833325647416],
              [-37.020833331792801, -8.506666659039013],
              [-37.019999998401147, -8.506666659039013],
              [-37.019999999300467, -8.507499992430667],
              [-37.01916666590887, -8.507499992430667],
              [-37.019166667707509, -8.508333325822264],
              [-37.018333331617896, -8.508333325822264],
              [-37.018333332517216, -8.509166659213918],
              [-37.017500000024938, -8.509166659213918],
              [-37.017499998226299, -8.509999992605515],
              [-37.016666665734022, -8.509999991706195],
              [-37.016666667532661, -8.510833325997169],
              [-37.016666666633341, -8.511666658489446],
              [-37.015833332342368, -8.511666659388766],
              [-37.015833331443048, -8.51249999278042],
              [-37.016666665734022, -8.512499993679739],
              [-37.016666666633341, -8.514166657764974],
              [-37.014166667357756, -8.514166658664294],
              [-37.014166666458436, -8.513333326172017],
              [-37.010833331992615, -8.513333326172017],
              [-37.010833332891934, -8.514166658664294],
              [-37.005833331642918, -8.514166658664294],
              [-37.005833332542238, -8.514999993854588],
              [-37.005000000949224, -8.514999992955268],
              [-37.004999998251265, -8.520833325797298],
              [-37.005833332542238, -8.520833325797298],
              [-37.005833331642918, -8.521666659188952],
              [-37.008333331817767, -8.521666657390256],
              [-37.008333334515726, -8.520833326696618],
              [-37.009999998601018, -8.520833325797298],
              [-37.009999999500337, -8.521666659188952],
              [-37.010833331992615, -8.521666659188952],
              [-37.010833331992615, -8.523333325972146],
              [-37.011666666283588, -8.523333325972146],
              [-37.011666665384269, -8.52416665846448],
              [-37.012499999675185, -8.52416665846448],
              [-37.012499998775866, -8.524999992755397],
              [-37.0133333312682, -8.524999992755397],
              [-37.013333333066839, -8.525833326147051],
              [-37.014166666458436, -8.525833326147051],
              [-37.014166665559117, -8.526666658639328],
              [-37.01500000074941, -8.526666658639328],
              [-37.01499999985009, -8.527499995628261],
              [-37.011666666283588, -8.527499992930302],
              [-37.011666668082228, -8.528333327221219],
              [-37.010833331992615, -8.528333325422579],
              [-37.010833331992615, -8.52999999310515],
              [-37.009999998601018, -8.52999999310515],
              [-37.009999999500337, -8.531666658089762],
              [-37.00916666790738, -8.531666658989081],
              [-37.00916666700806, -8.532499992380679],
              [-37.007500000224809, -8.532499992380679],
              [-37.007500000224809, -8.533333325772333],
              [-37.004166666658307, -8.533333325772333],
              [-37.004166665758987, -8.532499994179318],
              [-37.002500000774376, -8.532499992380679],
              [-37.002499998975736, -8.535833325947181],
              [-36.996666665234386, -8.535833325947181],
              [-36.996666665234386, -8.537499992730432],
              [-36.995833333641372, -8.537499991831112],
              [-36.995833331842732, -8.538333326122029],
              [-36.994999999350455, -8.538333326122029],
              [-36.994999999350455, -8.539166658614363],
              [-36.994166666858121, -8.539166658614363],
              [-36.994166666858121, -8.541666658789211],
              [-36.993333330768564, -8.541666658789211],
              [-36.993333332567204, -8.542499993979504],
              [-36.99249999737691, -8.542499993080185],
              [-36.99249999827623, -8.544166658964116],
              [-36.991666665783953, -8.544166658964116],
              [-36.991666666683273, -8.54583332484799],
              [-36.990833332392356, -8.545833325747367],
              [-36.990833332392356, -8.552499992880314],
              [-36.989999999900022, -8.552499992880314],
              [-36.990000000799341, -8.553333325372648],
              [-36.989166666508424, -8.553333325372648],
              [-36.989166665609105, -8.556666658939093],
              [-36.990000000799341, -8.556666658939093],
              [-36.989999999000702, -8.557499993230067],
              [-36.990833331493036, -8.557499993230067],
              [-36.990833333291675, -8.558333325722344],
              [-36.992500000074926, -8.558333325722344],
              [-36.992500000074926, -8.559166659113998],
              [-36.993333331667884, -8.559166659113998],
              [-36.993333331667884, -8.559999992505595],
              [-36.99416666775744, -8.559999991606276],
              [-36.99416666775744, -8.560833325897249],
              [-36.994999999350455, -8.560833325897249],
              [-36.994999998451135, -8.562499992680443],
              [-36.995833333641372, -8.562499992680443],
              [-36.995833333641372, -8.565833325347626],
              [-36.996666665234386, -8.565833324448306],
              [-36.996666665234386, -8.567499993030196],
              [-36.997500000424623, -8.567499993030196],
              [-36.997499998625983, -8.568333325522531],
              [-36.998333332916957, -8.568333325522531],
              [-36.998333332017637, -8.569166658914128],
              [-36.999166667207874, -8.569166658914128],
              [-36.999166666308554, -8.571666659088976],
              [-36.998333332017637, -8.571666659088976],
              [-36.998333332017637, -8.57249999248063],
              [-36.997500000424623, -8.57249999248063],
              [-36.997500000424623, -8.573333326771547],
              [-36.996666665234386, -8.573333325872227],
              [-36.996666667033026, -8.574999992655478],
              [-36.995833331842732, -8.574999992655478],
              [-36.995833330943412, -8.576666658539409],
              [-36.994999998451135, -8.576666658539409],
              [-36.994999997551815, -8.577499992830383],
              [-36.994166665958801, -8.577499992830383],
              [-36.994166666858121, -8.5783333271213],
              [-36.992500000074926, -8.57833332622198],
              [-36.992500000974246, -8.579166658714257],
              [-36.991666667582592, -8.579166658714257],
              [-36.991666666683273, -8.579999993005231],
              [-36.988333331318131, -8.579999993005231],
              [-36.988333331318131, -8.579166658714257],
              [-36.987499998825854, -8.579166658714257],
              [-36.987499998825854, -8.57833332622198],
              [-36.986666667232839, -8.57833332622198],
              [-36.98666666633352, -8.577499992830383],
              [-36.985000000449645, -8.577499992830383],
              [-36.984999999550269, -8.576666658539409],
              [-36.982499998476101, -8.576666658539409],
              [-36.982499998476101, -8.575833326047132],
              [-36.98083333169285, -8.575833326047132],
              [-36.98083333169285, -8.576666658539409],
              [-36.980833333491489, -8.577499993729703],
              [-36.979999998301253, -8.577499992830383],
              [-36.980000000099892, -8.579999993005231],
              [-36.979166665808918, -8.579999993904551],
              [-36.979166666708238, -8.582499993180079],
              [-36.979999998301253, -8.582499993180079],
              [-36.980000000099892, -8.583333325672413],
              [-36.979166666708238, -8.583333326571733],
              [-36.979166667607558, -8.58416665816469],
              [-36.980000000099892, -8.58416665906401],
              [-36.979999998301253, -8.584999992455664],
              [-36.979166665808918, -8.584999992455664],
              [-36.979166665808918, -8.585833325847261],
              [-36.978333331518002, -8.585833325847261],
              [-36.978333332417321, -8.589166658514443],
              [-36.979166666708238, -8.589166658514443],
              [-36.979166665808918, -8.590833326197014],
              [-36.978333332417321, -8.590833327096334],
              [-36.978333330618682, -8.591666658689292],
              [-36.977499999924987, -8.591666658689292],
              [-36.977499999924987, -8.592499992980265],
              [-36.97666666653339, -8.592499992980265],
              [-36.97666666653339, -8.593333325472543],
              [-36.975833334041113, -8.593333324573223],
              [-36.975833331343097, -8.594166658864197],
              [-36.974999998850819, -8.594166658864197],
              [-36.974999998850819, -8.594999993155113],
              [-36.974166665459222, -8.594999993155113],
              [-36.974166667257862, -8.595833325647391],
              [-36.973333333866208, -8.595833325647391],
              [-36.973333332966888, -8.596666659039045],
              [-36.974166669056501, -8.596666659039045],
              [-36.974166664559903, -8.597499992430642],
              [-36.973333334765528, -8.597499992430642],
              [-36.973333333866208, -8.600833325997144],
              [-36.974166667257862, -8.600833325997144],
              [-36.974166665459222, -8.601666658489478],
              [-36.974999999750139, -8.601666658489478],
              [-36.974999998850819, -8.604166658664326],
              [-36.975833334041113, -8.604166659563646],
              [-36.975833331343097, -8.605833325447577],
              [-36.97666666563407, -8.605833324548257],
              [-36.97666666563407, -8.608333325622425],
              [-36.977499999924987, -8.608333325622425],
              [-36.977499999025667, -8.609999992405676],
              [-36.978333332417321, -8.609999992405676],
              [-36.978333331518002, -8.611666659188927],
              [-36.979166665808918, -8.611666657390288],
              [-36.979166666708238, -8.613333325972178],
              [-36.980000000099892, -8.613333325972178],
              [-36.979999998301253, -8.614999992755429],
              [-36.98083333169285, -8.614999992755429],
              [-36.98083333169285, -8.619166658814208],
              [-36.981666665983823, -8.619166657914889],
              [-36.981666667782463, -8.620833325597459],
              [-36.982499998476101, -8.620833325597459],
              [-36.982499998476101, -8.630833324498269],
              [-36.981666666883143, -8.630833325397589],
              [-36.981666667782463, -8.631666658789243],
              [-36.98249999937542, -8.631666658789243],
              [-36.98249999937542, -8.634166658064771],
              [-36.983333331867755, -8.634166658964091],
              [-36.983333331867755, -8.635833324848022],
              [-36.984166667057991, -8.635833325747342],
              [-36.984166668856631, -8.63833332592219],
              [-36.983333332767074, -8.63833332592219],
              [-36.983333332767074, -8.639999992705441],
              [-36.984166667057991, -8.639999992705441],
              [-36.984166667957311, -8.642499992880346],
              [-36.984999999550269, -8.642499992880346],
              [-36.984999999550269, -8.643333325372623],
              [-36.984166667957311, -8.643333325372623],
              [-36.984166667957311, -8.645833325547471],
              [-36.983333333666394, -8.645833325547471],
              [-36.983333331867755, -8.648333325722376],
              [-36.984166667057991, -8.648333325722376],
              [-36.984166667057991, -8.650833325897224],
              [-36.983333332767074, -8.650833324997905],
              [-36.983333332767074, -8.656666658739255],
              [-36.984166665259352, -8.656666658739255],
              [-36.984166667057991, -8.657499993030228],
              [-36.983333332767074, -8.657499993030228],
              [-36.983333331867755, -8.658333325522506],
              [-36.984166665259352, -8.658333325522506],
              [-36.984166667057991, -8.659166658914103],
              [-36.983333333666394, -8.659166658014783],
              [-36.983333332767074, -8.662499992480605],
              [-36.984166667957311, -8.662499992480605],
              [-36.984166665259352, -8.66499999265551],
              [-36.984166668856631, -8.667499992830358],
              [-36.984999998650949, -8.667499992830358],
              [-36.985000000449645, -8.672499993180111],
              [-36.984166667957311, -8.672499993180111],
              [-36.984166667957311, -8.673333325672388],
              [-36.984999998650949, -8.673333325672388],
              [-36.984999998650949, -8.67666665923889],
              [-36.984166667057991, -8.67666665923889],
              [-36.984166667057991, -8.677499992630487],
              [-36.983333331867755, -8.677499992630487],
              [-36.983333333666394, -8.678333326022141],
              [-36.98250000027474, -8.678333326022141],
              [-36.982499998476101, -8.679166658514418],
              [-36.980833332592169, -8.679166659413738],
              [-36.980833332592169, -8.679999992805392],
              [-36.980000000099892, -8.679999992805392],
              [-36.979999998301253, -8.681666658689323],
              [-36.979166667607558, -8.681666659588643],
              [-36.979166667607558, -8.689166659213925],
              [-36.978333332417321, -8.689166659213925],
              [-36.978333333316641, -8.69249999278037],
              [-36.979166666708238, -8.69249999278037],
              [-36.979166665808918, -8.693333326172024],
              [-36.978333332417321, -8.693333324373384],
              [-36.978333332417321, -8.694166658664301],
              [-36.980000000099892, -8.694166658664301],
              [-36.980000000999212, -8.695833325447552],
              [-36.98083333169285, -8.695833324548232],
              [-36.980833332592169, -8.696666657939886],
              [-36.981666666883143, -8.696666657939886],
              [-36.981666665983823, -8.699999992405651],
              [-36.98250000207338, -8.699999994204347],
              [-36.982499998476101, -8.701666659188902],
              [-36.981666665983823, -8.701666659188902],
              [-36.981666665983823, -8.702499993479876],
              [-36.981666667782463, -8.703333325972153],
              [-36.98249999937542, -8.703333325972153],
              [-36.982499998476101, -8.704999991856084],
              [-36.983333332767074, -8.704999992755404],
              [-36.983333331867755, -8.705833326147058],
              [-36.984166667057991, -8.705833327046378],
              [-36.984166667957311, -8.706666657740016],
              [-36.984999999550269, -8.706666658639335],
              [-36.984999999550269, -8.707499992930309],
              [-36.985833332042603, -8.707499992930309],
              [-36.985833333841242, -8.709166658814183],
              [-36.986666667232839, -8.709166657914864],
              [-36.9866666654342, -8.709999993105157],
              [-36.988333330418811, -8.709999993105157],
              [-36.988333331318131, -8.710833325597434],
              [-36.990833331493036, -8.710833325597434],
              [-36.990833332392356, -8.711666659888408],
              [-36.992500000074926, -8.711666658989088],
              [-36.992500000974246, -8.713333325772339],
              [-36.995000000249775, -8.713333325772339],
              [-36.994999999350455, -8.714166659163936],
              [-36.995833333641372, -8.714166659163936],
              [-36.995833332742052, -8.71499999255559],
              [-36.997499997726663, -8.71499999255559],
              [-36.997500000424623, -8.715833325947187],
              [-36.999999998800888, -8.715833325947187],
              [-36.999999999700208, -8.716666658439465],
              [-37.000833333991125, -8.716666658439465],
              [-37.000833333091805, -8.718333326122035],
              [-37.001666666483402, -8.718333326122035],
              [-37.002499999875056, -8.718333326122035],
              [-37.002500000774376, -8.717499992730438],
              [-37.003333332367333, -8.717499992730438],
              [-37.003333332367333, -8.716666658439465],
              [-37.004166666658307, -8.716666658439465],
              [-37.004166665758987, -8.715833325947187],
              [-37.006666665933835, -8.715833325947187],
              [-37.006666666833155, -8.71499999255559],
              [-37.007499998426169, -8.71499999255559],
              [-37.007500000224809, -8.713333325772339],
              [-37.008333332717086, -8.71333332487302],
              [-37.00916666790738, -8.713333325772339],
              [-37.009166665209364, -8.714166659163936],
              [-37.008333333616406, -8.714166659163936],
              [-37.008333332717086, -8.717499992730438],
              [-37.010833333791254, -8.717499992730438],
              [-37.010833333791254, -8.716666658439465],
              [-37.017499998226299, -8.716666658439465],
              [-37.017499998226299, -8.717499992730438],
              [-37.01916666590887, -8.717499992730438],
              [-37.01916666590887, -8.716666658439465],
              [-37.019999999300467, -8.716666657540145],
              [-37.019999999300467, -8.715833325947187],
              [-37.020833332692121, -8.715833325947187],
              [-37.020833332692121, -8.71499999255559],
              [-37.024166666258623, -8.71499999345491],
              [-37.024166665359303, -8.714166659163936],
              [-37.025833333041817, -8.714166659163936],
              [-37.028333332317402, -8.714166659163936],
              [-37.028333332317402, -8.713333325772339],
              [-37.029166665708999, -8.71333332487302],
              [-37.029166665708999, -8.712499994179325],
              [-37.03, -8.712499994179325],
              [-37.029999999100653, -8.711666658989088],
              [-37.031666665883904, -8.711666658089769],
              [-37.031666665883904, -8.709999993105157],
              [-37.032499998376181, -8.709999993105157],
              [-37.032500001074141, -8.709166658814183],
              [-37.033333332667098, -8.709166658814183],
              [-37.033333332667098, -8.707499992930309],
              [-37.034999999450349, -8.707499992930309],
              [-37.034999999450349, -8.705833326147058],
              [-37.034166666058752, -8.705833324348419],
              [-37.034166666058752, -8.704999992755404],
              [-37.033333333566475, -8.704999991856084],
              [-37.033333332667098, -8.701666659188902],
              [-37.035833332842003, -8.701666659188902],
              [-37.035833333741323, -8.700833324897985],
              [-37.03666666533428, -8.700833325797305],
              [-37.0366666662336, -8.698333325622457],
              [-37.037500000524574, -8.698333325622457],
              [-37.037499998725934, -8.696666658839206],
              [-37.038333331218212, -8.696666658839206],
              [-37.038333331218212, -8.695833325447552],
              [-37.039166665509185, -8.695833325447552],
              [-37.039166666408505, -8.694166658664301],
              [-37.039999998900782, -8.694166658664301],
              [-37.039999999800102, -8.693333326172024],
              [-37.04250000087427, -8.693333326172024],
              [-37.042500001773647, -8.691666658489453],
              [-37.043333332467284, -8.691666657590133],
              [-37.043333333366604, -8.689999992605522],
              [-37.044166666758201, -8.689999992605522],
              [-37.044166665858882, -8.689166659213925],
              [-37.044999998351216, -8.689166659213925],
              [-37.044999999250535, -8.688333325822271],
              [-37.045833333541452, -8.68833332672159],
              [-37.045833331742813, -8.687499992430674],
              [-37.046666664235147, -8.687499992430674],
              [-37.046666666033786, -8.685833325647422],
              [-37.047499998526064, -8.685833325647422],
              [-37.047499998526064, -8.684166657964852],
              [-37.048333333716357, -8.684166658864171],
              [-37.048333331917718, -8.681666658689323],
              [-37.049166666208635, -8.681666658689323],
              [-37.049166668007274, -8.679999992805392],
              [-37.052499999775137, -8.679999991906072],
              [-37.052499998875817, -8.680833326196989],
              [-37.053333332267414, -8.680833326196989],
              [-37.053333334066053, -8.681666658689323],
              [-37.054166665659068, -8.681666658689323],
              [-37.054166665659068, -8.68249999208092],
              [-37.054999999949985, -8.68249999298024],
              [-37.055000000849304, -8.683333324573198],
              [-37.055833333341639, -8.683333325472518],
              [-37.055833332442319, -8.687499992430674],
              [-37.056666667632555, -8.687499992430674],
              [-37.056666665833916, -8.689166659213925],
              [-37.057499999225513, -8.689166659213925],
              [-37.057499998326193, -8.689999992605522],
              [-37.060833331892695, -8.689999992605522],
              [-37.060833332792015, -8.691666657590133],
              [-37.062500000474586, -8.691666658489453],
              [-37.062499999575266, -8.69249999278037],
              [-37.0633333320676, -8.69249999278037],
              [-37.06333333296692, -8.694166658664301],
              [-37.064166665459197, -8.694166658664301],
              [-37.064166665459197, -8.694999992055955],
              [-37.064999999750171, -8.694999992955275],
              [-37.064999998850851, -8.695833324548232],
              [-37.065833332242448, -8.695833326346872],
              [-37.065833332242448, -8.699166659913374],
              [-37.066666666533365, -8.699166659014054],
              [-37.066666666533365, -8.704999992755404],
              [-37.067499999025699, -8.704999992755404],
              [-37.067499999925019, -8.705833324348419],
              [-37.070000000999187, -8.705833326147058],
              [-37.069999998301228, -8.706666658639335],
              [-37.070833332592201, -8.706666658639335],
              [-37.070833331692882, -8.712499991481366],
              [-37.071666665983798, -8.712499992380685],
              [-37.071666665983798, -8.714166657365297],
              [-37.072499999375452, -8.714166659163936],
              [-37.072500000274772, -8.71499999255559],
              [-37.073333332767049, -8.71499999255559],
              [-37.07333333186773, -8.718333327021355],
              [-37.074166667957343, -8.718333326122035],
              [-37.074166667957343, -8.71916665861437],
              [-37.073333332767049, -8.71916665861437],
              [-37.071666665983798, -8.71916665861437],
              [-37.071666667782438, -8.719999992905286],
              [-37.070833331692882, -8.719999992905286],
              [-37.070833331692882, -8.720833325397621],
              [-37.069999998301228, -8.720833324498301],
              [-37.069999998301228, -8.721666658789218],
              [-37.06916666580895, -8.721666658789218],
              [-37.06916666670827, -8.72499999235572],
              [-37.070000000099867, -8.72499999235572],
              [-37.070000000999187, -8.725833326646637],
              [-37.070833333491521, -8.725833325747317],
              [-37.070833332592201, -8.728333325922222],
              [-37.071666666883118, -8.728333325922222],
              [-37.071666665983798, -8.73083332609707],
              [-37.072499998476133, -8.73083332609707],
              [-37.072499999375452, -8.731666658589404],
              [-37.07333333186773, -8.731666658589404],
              [-37.073333332767049, -8.732499992880321],
              [-37.074166665259327, -8.732499991981001],
              [-37.074166667058023, -8.733333325372598],
              [-37.07500000044962, -8.733333325372598],
              [-37.074999998650981, -8.734999993055169],
              [-37.075833332042578, -8.734999993055169],
              [-37.075833332941897, -8.737499993230074],
              [-37.074999998650981, -8.737499993230074],
              [-37.0749999995503, -8.739166659114005],
              [-37.074166667058023, -8.739166659114005],
              [-37.074166665259327, -8.739999992505602],
              [-37.073333332767049, -8.739999992505602],
              [-37.073333333666369, -8.740833325897199],
              [-37.071666665983798, -8.74083332499788],
              [-37.071666665983798, -8.741666657490214],
              [-37.070833332592201, -8.741666658389533],
              [-37.070833331692882, -8.743333326072104],
              [-37.069999998301228, -8.743333326072104],
              [-37.070000000099867, -8.744166658564382],
              [-37.06916666580895, -8.744166658564382],
              [-37.06916666760759, -8.743333326072104],
              [-37.067499999925019, -8.743333326072104],
              [-37.06749999722706, -8.744999992855355],
              [-37.065833334041088, -8.744999992855355],
              [-37.065833331343129, -8.747499992130884],
              [-37.064999998850851, -8.747499993030203],
              [-37.064999999750171, -8.749166658914135],
              [-37.064166667257837, -8.749166658014815],
              [-37.064166667257837, -8.752499992480637],
              [-37.065000000649491, -8.752499993379956],
              [-37.064999999750171, -8.753333325872234],
              [-37.065833332242448, -8.753333325872234],
              [-37.065833331343129, -8.754166658364568],
              [-37.064999999750171, -8.754166658364568],
              [-37.064999999750171, -8.755833326047139],
              [-37.064166666358517, -8.755833326047139],
              [-37.064166665459197, -8.759166658714264],
              [-37.0633333320676, -8.759166658714264],
              [-37.06333333296692, -8.759999993005238],
              [-37.064166666358517, -8.759999993005238],
              [-37.064166665459197, -8.760833325497515],
              [-37.064999999750171, -8.760833326396835],
              [-37.065000000649491, -8.76333332567242],
              [-37.064166665459197, -8.76333332567242],
              [-37.064166666358517, -8.764999991556294],
              [-37.062499999575266, -8.764999992455614],
              [-37.062499998675946, -8.765833325847268],
              [-37.061666665284349, -8.765833326746588],
              [-37.061666667982308, -8.767499991731199],
              [-37.060833331892695, -8.767499992630519],
              [-37.060833332792015, -8.768333326022116],
              [-37.059999998501098, -8.768333326921436],
              [-37.059999999400418, -8.769999992805367],
              [-37.059166666008764, -8.769999992805367],
              [-37.059166666008764, -8.771666658689298],
              [-37.058333331717847, -8.771666658689298],
              [-37.058333331717847, -8.772499992980272],
              [-37.057499998326193, -8.772499992980272],
              [-37.057499999225513, -8.773333325472549],
              [-37.056666666733236, -8.773333326371869],
              [-37.056666665833916, -8.776666659039051],
              [-37.054999999949985, -8.776666659039051],
              [-37.055000000849304, -8.777499992430648],
              [-37.054166665659068, -8.777499992430648],
              [-37.054166666558388, -8.778333325822302],
              [-37.053333332267414, -8.778333325822302],
              [-37.053333332267414, -8.779166659213899],
              [-37.054166665659068, -8.77916665741526],
              [-37.054166665659068, -8.782499992780401],
              [-37.053333331368094, -8.782499992780401],
              [-37.053333331368094, -8.783333326171999],
              [-37.054166668357027, -8.783333326171999],
              [-37.054166664759748, -8.784166658664333],
              [-37.054999999949985, -8.784166658664333],
              [-37.054999999949985, -8.78499999295525],
              [-37.054166665659068, -8.78499999295525],
              [-37.054166667457707, -8.786666657939861],
              [-37.054999999050665, -8.786666658839181],
              [-37.055000000849304, -8.787499992230835],
              [-37.059166667807403, -8.787499992230835],
              [-37.059166667807403, -8.788333325622432],
              [-37.061666667982308, -8.788333325622432],
              [-37.061666667982308, -8.787499993130155],
              [-37.062500000474586, -8.787499993130155],
              [-37.062499999575266, -8.786666658839181],
              [-37.064166667257837, -8.786666658839181],
              [-37.064166665459197, -8.785833325447584],
              [-37.064999998850851, -8.785833324548264],
              [-37.064999999750171, -8.78499999295525],
              [-37.066666664734726, -8.78499999295525],
              [-37.066666665634045, -8.784166658664333],
              [-37.067499999925019, -8.784166657765013],
              [-37.067499999025699, -8.782499992780401],
              [-37.068333332417296, -8.782499992780401],
              [-37.068333331517977, -8.78083332599715],
              [-37.06916666670827, -8.78083332599715],
              [-37.06916666670827, -8.779999993504873],
              [-37.070000000999187, -8.779999992605553],
              [-37.069999998301228, -8.77916665741526],
              [-37.070833331692882, -8.779166659213899],
              [-37.070833333491521, -8.778333326721622],
              [-37.071666667782438, -8.778333325822302],
              [-37.071666667782438, -8.776666659039051],
              [-37.07333333186773, -8.776666659039051],
              [-37.073333332767049, -8.77499999315512],
              [-37.074166665259327, -8.77499999315512],
              [-37.074166667957343, -8.774166658864146],
              [-37.076666665434232, -8.774166658864146],
              [-37.076666667232871, -8.773333325472549],
              [-37.077499999725148, -8.773333325472549],
              [-37.077500000624468, -8.772499992980272],
              [-37.078333334915442, -8.772499992980272],
              [-37.078333331318163, -8.769999992805367],
              [-37.07916666560908, -8.769999992805367],
              [-37.07916666560908, -8.76916665851445],
              [-37.084166666858152, -8.76916665851445],
              [-37.084166665958833, -8.769999992805367],
              [-37.08500000024975, -8.769999992805367],
              [-37.08499999845111, -8.770833326197021],
              [-37.086666667033001, -8.770833326197021],
              [-37.086666667033001, -8.769999991906047],
              [-37.087499998626015, -8.769999992805367],
              [-37.087500000424654, -8.76916665851445],
              [-37.086666665234361, -8.76916665851445],
              [-37.086666665234361, -8.767499992630519],
              [-37.087499998626015, -8.767499992630519],
              [-37.087499998626015, -8.766666660138185],
              [-37.088333332916932, -8.766666659238865],
              [-37.088333332916932, -8.765833325847268],
              [-37.090000001498822, -8.765833325847268],
              [-37.089999998800863, -8.764999992455614],
              [-37.090833334890476, -8.764999992455614],
              [-37.09083333309178, -8.764166659064017],
              [-37.092500000774351, -8.764166661761976],
              [-37.094166666658282, -8.764166659064017],
              [-37.094166665758962, -8.76333332567242],
              [-37.097499998426144, -8.76333332567242],
              [-37.097499998426144, -8.764166659963337],
              [-37.098333331817742, -8.764166659064017],
              [-37.098333332717061, -8.766666659238865],
              [-37.100833331992646, -8.766666659238865],
              [-37.100833332891966, -8.765833325847268],
              [-37.102499998775897, -8.765833324947948],
              [-37.102500000574537, -8.764166659064017],
              [-37.103333333066814, -8.764166659064017],
              [-37.103333331268175, -8.76333332567242],
              [-37.104999998950746, -8.76333332567242],
              [-37.104999999850065, -8.762499994079405],
              [-37.105833331443023, -8.762499993180086],
              [-37.105833332342343, -8.76333332567242],
              [-37.108333332517248, -8.76333332567242],
              [-37.108333332517248, -8.764166659064017],
              [-37.11166666608375, -8.764166659064017],
              [-37.111666667882389, -8.764999992455614],
              [-37.114166666258598, -8.764999992455614],
              [-37.114166666258598, -8.765833325847268],
              [-37.124999999450381, -8.765833325847268],
              [-37.124999999450381, -8.766666659238865],
              [-37.125833331942658, -8.766666661936824],
              [-37.128333333916203, -8.766666659238865],
              [-37.128333333916203, -8.765833325847268],
              [-37.12916666550916, -8.765833326746588],
              [-37.1291666673078, -8.764166659064017],
              [-37.129999999800134, -8.764166658164697],
              [-37.130000000699454, -8.7633333247731],
              [-37.131666666583328, -8.76333332567242],
              [-37.131666666583328, -8.762499993180086],
              [-37.134166665858913, -8.762499993180086],
              [-37.134166665858913, -8.759166658714264],
              [-37.134999998351191, -8.759166658714264],
              [-37.13500000104915, -8.756666658539416],
              [-37.142499998875792, -8.756666658539416],
              [-37.142499998875792, -8.757499992830333],
              [-37.144166665659043, -8.757499998226308],
              [-37.144166666558363, -8.760833324598195],
              [-37.144999999950016, -8.760833326396835],
              [-37.144999999950016, -8.764999993354991],
              [-37.143333332267446, -8.764999992455614],
              [-37.143333334066085, -8.767499993529839],
              [-37.142499999775112, -8.767499992630519],
              [-37.142499999775112, -8.769999992805367],
              [-37.143333334066085, -8.769999992805367],
              [-37.143333332267446, -8.771666657789979],
              [-37.144166664759723, -8.771666659588618],
              [-37.144166665659043, -8.773333325472549],
              [-37.148333333516462, -8.773333325472549],
              [-37.148333333516462, -8.774166658864146],
              [-37.149166666908116, -8.774166658864146],
              [-37.149166666008796, -8.77499999315512],
              [-37.149999999400393, -8.77499999315512],
              [-37.149999999400393, -8.775833325647397],
              [-37.150833332792047, -8.775833324748078],
              [-37.150833331892727, -8.776666659039051],
              [-37.152499999575298, -8.776666658139732],
              [-37.152500000474618, -8.777499992430648],
              [-37.154166666358549, -8.777499994229288],
              [-37.154166667257869, -8.778333325822302],
              [-37.156666667432717, -8.778333325822302],
              [-37.157500001723633, -8.778333325822302],
              [-37.157499999924994, -8.777499992430648],
              [-37.158333331518008, -8.777499992430648],
              [-37.158333331518008, -8.776666659039051],
              [-37.159166665808925, -8.776666659039051],
              [-37.159166667607565, -8.775833325647397],
              [-37.160833332592176, -8.775833326546717],
              [-37.160833331692857, -8.77499999315512],
              [-37.16166666688315, -8.77499999315512],
              [-37.16166666688315, -8.774166658864146],
              [-37.162500000274747, -8.774166658864146],
              [-37.162499998476108, -8.773333325472549],
              [-37.164166667057998, -8.773333325472549],
              [-37.164166665259359, -8.772499992080895],
              [-37.166666665434207, -8.772499992980272],
              [-37.166666666333526, -8.771666658689298],
              [-37.16749999972518, -8.771666658689298],
              [-37.16749999972518, -8.770833326197021],
              [-37.176666664335073, -8.770833327096341],
              [-37.176666667033032, -8.769999993704687],
              [-37.178333332916964, -8.769999992805367],
              [-37.178333332916964, -8.76916665851445],
              [-37.184166665758994, -8.76916665851445],
              [-37.184166667557633, -8.768333326022116],
              [-37.185000000049911, -8.768333326022116],
              [-37.185000000049911, -8.767499992630519],
              [-37.18500000184855, -8.766666660138185],
              [-37.184166666658314, -8.766666659238865],
              [-37.184166665758994, -8.765833325847268],
              [-37.18333333236734, -8.765833325847268],
              [-37.18333333236734, -8.764999992455614],
              [-37.182500000774382, -8.764999991556294],
              [-37.182499998975743, -8.764166659064017],
              [-37.181666667382729, -8.764166659064017],
              [-37.181666666483409, -8.76333332567242],
              [-37.180833331293172, -8.76333332567242],
              [-37.180833331293172, -8.760833325497515],
              [-37.179999998800838, -8.760833325497515],
              [-37.180000000599478, -8.759999993005238],
              [-37.179166665409241, -8.759999993005238],
              [-37.179166666308561, -8.759166658714264],
              [-37.181666667382729, -8.759166658714264],
              [-37.181666665584089, -8.758333326221987],
              [-37.182499998975743, -8.758333326221987],
              [-37.182499999875063, -8.757499992830333],
              [-37.184166666658314, -8.757499992830333],
              [-37.184166665758994, -8.756666658539416],
              [-37.185833331642925, -8.756666658539416],
              [-37.185833332542245, -8.757499992830333],
              [-37.186666665933842, -8.757499991931013],
              [-37.186666666833162, -8.756666658539416],
              [-37.196666667532611, -8.756666659438736],
              [-37.196666666633291, -8.755833325147819],
              [-37.197499998226306, -8.755833326047139],
              [-37.197500000024945, -8.754999993554804],
              [-37.201666666983044, -8.754999993554804],
              [-37.201666666983044, -8.754166658364568],
              [-37.206666666433478, -8.754166658364568],
              [-37.206666666433478, -8.753333325872234],
              [-37.207499999825075, -8.753333325872234],
              [-37.207499999825075, -8.752499992480637],
              [-37.208333332317409, -8.752499992480637],
              [-37.208333331418089, -8.749999993205051],
              [-37.209166666608326, -8.749999993205051],
              [-37.209166665709006, -8.749166658914135],
              [-37.208333332317409, -8.749166659813454],
              [-37.208333331418089, -8.74249999268045],
              [-37.209166666608326, -8.742499991781131],
              [-37.209166667507645, -8.741666660188173],
              [-37.210000000899299, -8.741666658389533],
              [-37.21, -8.739999992505602],
              [-37.210833332492257, -8.739999993404922],
              [-37.210833331592937, -8.739166659114005],
              [-37.219999998900789, -8.739166659114005],
              [-37.219999998900789, -8.739999992505602],
              [-37.22166666658336, -8.739999992505602],
              [-37.22166666748268, -8.740833325897199],
              [-37.222499999974957, -8.740833325897199],
              [-37.222499999974957, -8.739166658214685],
              [-37.22583333174282, -8.739166659114005],
              [-37.225833332642139, -8.738333324823031],
              [-37.226666666033793, -8.738333325722351],
              [-37.226666666933113, -8.735833325547503],
              [-37.22750000032471, -8.735833325547503],
              [-37.227499998526071, -8.734999993055169],
              [-37.228333332816987, -8.734999993055169],
              [-37.228333332816987, -8.732499992880321],
              [-37.227499998526071, -8.732499992880321],
              [-37.227499998526071, -8.725833325747317],
              [-37.228333331917668, -8.725833325747317],
              [-37.228333331917668, -8.723333325572469],
              [-37.229166665309322, -8.723333325572469],
              [-37.229166666208641, -8.721666658789218],
              [-37.231666666383489, -8.721666658789218],
              [-37.231666667282809, -8.719166659513689],
              [-37.230833333891212, -8.71916665861437],
              [-37.230833332092573, -8.718333326122035],
              [-37.231666666383489, -8.718333326122035],
              [-37.23166666548417, -8.717499992730438],
              [-37.232499998875824, -8.717499992730438],
              [-37.232500000674463, -8.716666660238161],
              [-37.234166666558394, -8.716666658439465],
              [-37.234166665659075, -8.715833325947187],
              [-37.234999999050672, -8.715833325047868],
              [-37.234999999050672, -8.71499999345491],
              [-37.240833331892702, -8.71499999255559],
              [-37.240833332792022, -8.714166657365297],
              [-37.242499999575273, -8.714166660063256],
              [-37.242499999575273, -8.712499992380685],
              [-37.243333332966927, -8.712499992380685],
              [-37.243333332966927, -8.710833325597434],
              [-37.244166666358524, -8.710833325597434],
              [-37.244166665459204, -8.709999993105157],
              [-37.244999998850801, -8.709999992205837],
              [-37.244999997951481, -8.709166658814183],
              [-37.247500001723665, -8.709166658814183],
              [-37.247499999025706, -8.708333325422586],
              [-37.251666666883125, -8.708333325422586],
              [-37.251666665983805, -8.704999991856084],
              [-37.252499999375402, -8.704999991856084],
              [-37.252499998476083, -8.704166658464487],
              [-37.254166667057973, -8.704166659363807],
              [-37.254166667057973, -8.701666659188902],
              [-37.254999998650987, -8.701666659188902],
              [-37.254999998650987, -8.700833325797305],
              [-37.256666669031517, -8.700833325797305],
              [-37.256666665434238, -8.699999992405651],
              [-37.257499999725155, -8.699999992405651],
              [-37.257499998825836, -8.699166659014054],
              [-37.259166666508406, -8.699166659014054],
              [-37.259166667407726, -8.697499994029442],
              [-37.259999998101364, -8.697499993130123],
              [-37.259999999000684, -8.695833325447552],
              [-37.260833332392338, -8.695833325447552],
              [-37.260833333291657, -8.694999992955275],
              [-37.261666665783935, -8.694999992955275],
              [-37.261666667582574, -8.694166658664301],
              [-37.26583333364141, -8.694166658664301],
              [-37.265833332742091, -8.693333326172024],
              [-37.267499998625965, -8.693333326172024],
              [-37.267499998625965, -8.694166658664301],
              [-37.269166666308536, -8.694166658664301],
              [-37.269166666308536, -8.694999992955275],
              [-37.272499998975718, -8.694999993854594],
              [-37.272500000774357, -8.695833325447552],
              [-37.274999998251303, -8.695833325447552],
              [-37.274999998251303, -8.694999992955275],
              [-37.27583333254222, -8.694999992055955],
              [-37.2758333316429, -8.69249999278037],
              [-37.277500000224791, -8.69249999188105],
              [-37.277499998426151, -8.691666659388773],
              [-37.278333331817748, -8.691666658489453],
              [-37.278333331817748, -8.690833325997176],
              [-37.279999998600999, -8.690833325997176],
              [-37.280000000399639, -8.691666658489453],
              [-37.283333333066821, -8.691666658489453],
              [-37.283333331268182, -8.693333327071343],
              [-37.282499999675224, -8.693333326172024],
              [-37.282499999675224, -8.694999992955275],
              [-37.281666664484931, -8.694999992955275],
              [-37.281666664484931, -8.696666657939886],
              [-37.280833333791293, -8.696666658839206],
              [-37.280833333791293, -8.698333326521777],
              [-37.28166666538425, -8.698333325622457],
              [-37.28166666538425, -8.699166659014054],
              [-37.282499999675224, -8.699166659014054],
              [-37.282499999675224, -8.699999992405651],
              [-37.283333333966141, -8.699999994204347],
              [-37.283333331268182, -8.700833326696625],
              [-37.284166666458475, -8.700833325797305],
              [-37.284166667357795, -8.701666659188902],
              [-37.284999999850072, -8.701666659188902],
              [-37.284999998051433, -8.704166658464487],
              [-37.28583333054371, -8.704166658464487],
              [-37.285833332342349, -8.709166658814183],
              [-37.286666664834684, -8.709166657914864],
              [-37.286666665734003, -8.712499992380685],
              [-37.285833332342349, -8.712499992380685],
              [-37.285833332342349, -8.713333325772339],
              [-37.286666665734003, -8.71333332487302],
              [-37.286666665734003, -8.716666658439465],
              [-37.287499996427641, -8.716666658439465],
              [-37.28750000002492, -8.719999993804606],
              [-37.286666665734003, -8.719999992905286],
              [-37.286666668431963, -8.720833325397621],
              [-37.28583333144303, -8.720833325397621],
              [-37.285833332342349, -8.721666658789218],
              [-37.285000000749392, -8.721666658789218],
              [-37.285000000749392, -8.722499993080191],
              [-37.284166667357795, -8.722499992180872],
              [-37.284166666458475, -8.729166660213139],
              [-37.283333333066821, -8.729166658414499],
              [-37.283333330368862, -8.729999991806153],
              [-37.282499999675224, -8.729999992705473],
              [-37.282500000574544, -8.73083332609707],
              [-37.281666664484931, -8.73083332609707],
              [-37.28166666628357, -8.731666657690027],
              [-37.280833332891973, -8.731666658589404],
              [-37.280833331093334, -8.732499992880321],
              [-37.28166666628357, -8.732499992880321],
              [-37.281666664484931, -8.733333325372598],
              [-37.282499998775904, -8.733333325372598],
              [-37.282499999675224, -8.734999993055169],
              [-37.283333331268182, -8.734999993055169],
              [-37.283333333066821, -8.739166659114005],
              [-37.282499999675224, -8.739166660013325],
              [-37.282499999675224, -8.739999992505602],
              [-37.28166666538425, -8.739999991606282],
              [-37.281666664484931, -8.741666658389533],
              [-37.280833331992653, -8.741666658389533],
              [-37.280833332891973, -8.744166658564382],
              [-37.28166666808221, -8.744166658564382],
              [-37.28166666628357, -8.743333325172784],
              [-37.283333333966141, -8.743333326971424],
              [-37.283333333066821, -8.74249999268045],
              [-37.284999998950752, -8.74249999268045],
              [-37.284999998950752, -8.741666658389533],
              [-37.28583333144303, -8.741666658389533],
              [-37.28583333144303, -8.739999992505602],
              [-37.286666665734003, -8.739999992505602],
              [-37.286666665734003, -8.74249999268045],
              [-37.289166666808171, -8.742499991781131],
              [-37.289166667707491, -8.743333326072104],
              [-37.295833331243216, -8.743333326971424],
              [-37.295833333941175, -8.74249999268045],
              [-37.296666667332772, -8.74249999268045],
              [-37.296666666433453, -8.741666658389533],
              [-37.298333333216704, -8.741666660188173],
              [-37.298333332317384, -8.740833325897199],
              [-37.299999999100635, -8.74083332499788],
              [-37.300000000899274, -8.739999992505602],
              [-37.303333331767817, -8.739999991606282],
              [-37.303333332667137, -8.739166659114005],
              [-37.305000000349708, -8.739166659114005],
              [-37.305000000349708, -8.738333325722351],
              [-37.306666668032278, -8.738333325722351],
              [-37.306666665334319, -8.737499993230074],
              [-37.30833333301689, -8.737499993230074],
              [-37.30833333391621, -8.7366666589391],
              [-37.311666665684015, -8.7366666589391],
              [-37.311666665684015, -8.737499993230074],
              [-37.312499999075669, -8.737499993230074],
              [-37.312500000874309, -8.738333325722351],
              [-37.313333332467266, -8.738333325722351],
              [-37.313333332467266, -8.739166659114005],
              [-37.315000001049157, -8.739166659114005],
              [-37.315000001049157, -8.739999992505602],
              [-37.317499999425365, -8.739999992505602],
              [-37.317499999425365, -8.740833325897199],
              [-37.318333332817019, -8.740833325897199],
              [-37.318333333716339, -8.741666657490214],
              [-37.32000000049959, -8.741666658389533],
              [-37.319999998700951, -8.743333326072104],
              [-37.320833333891187, -8.743333326072104],
              [-37.320833332092548, -8.744999992855355],
              [-37.321666665484202, -8.744999992855355],
              [-37.321666666383521, -8.746666658739287],
              [-37.322499999775118, -8.746666658739287],
              [-37.322499999775118, -8.747499993030203],
              [-37.323333331368133, -8.747499993030203],
              [-37.323333332267453, -8.748333325522481],
              [-37.324166668357009, -8.748333325522481],
              [-37.324166666558369, -8.749999993205051],
              [-37.326666665833898, -8.749999993205051],
              [-37.326666667632537, -8.749166658014815],
              [-37.327499999225552, -8.749166658914135],
              [-37.327499999225552, -8.748333326421857],
              [-37.328333332617149, -8.748333325522481],
              [-37.328333331717829, -8.747499993030203],
              [-37.332499999575305, -8.747499993030203],
              [-37.332499999575305, -8.745833327146272],
              [-37.335000000649472, -8.745833325347633],
              [-37.334999999750153, -8.744999992855355],
              [-37.336666666533404, -8.744999992855355],
              [-37.336666666533404, -8.744166658564382],
              [-37.338333331518015, -8.744166658564382],
              [-37.338333330618696, -8.743333326072104],
              [-37.339166667607572, -8.743333326072104],
              [-37.339166665808932, -8.74249999268045],
              [-37.339999998301209, -8.74249999268045],
              [-37.33999999740189, -8.740833325897199],
              [-37.34166666598378, -8.740833325897199],
              [-37.34166666598378, -8.739999992505602],
              [-37.342499999375434, -8.739999992505602],
              [-37.342499999375434, -8.739166660013325],
              [-37.344166665259365, -8.739166659114005],
              [-37.344166665259365, -8.738333325722351],
              [-37.345833332941936, -8.738333325722351],
              [-37.345833332941936, -8.737499993230074],
              [-37.347499998825867, -8.737499993230074],
              [-37.347499999725187, -8.7366666589391],
              [-37.348333334016104, -8.73666665983842],
              [-37.348333331318145, -8.735833325547503],
              [-37.349166666508381, -8.735833325547503],
              [-37.349999999000715, -8.735833324648183],
              [-37.349999999900035, -8.739999992505602],
              [-37.350833332392313, -8.739999992505602],
              [-37.350833333291632, -8.741666658389533],
              [-37.351666665783966, -8.741666658389533],
              [-37.351666665783966, -8.74249999268045],
              [-37.353333331667898, -8.742499991781131],
              [-37.353333334365857, -8.743333326072104],
              [-37.354166666858134, -8.743333326072104],
              [-37.354166667757454, -8.744166658564382],
              [-37.354999998451149, -8.744166657665062],
              [-37.355000000249788, -8.745833325347633],
              [-37.355833332742066, -8.745833325347633],
              [-37.355833331842746, -8.746666658739287],
              [-37.357499999525317, -8.746666658739287],
              [-37.357499998625997, -8.747499993030203],
              [-37.358333332017594, -8.747499992130884],
              [-37.358333332017594, -8.748333325522481],
              [-37.357499999525317, -8.748333325522481],
              [-37.357499998625997, -8.757499991931013],
              [-37.357499998625997, -8.758333326221987],
              [-37.356666667033039, -8.758333326221987],
              [-37.356666665234343, -8.759166658714264],
              [-37.355833331842746, -8.759166658714264],
              [-37.355833332742066, -8.76333332657174],
              [-37.354999999350468, -8.76333332567242],
              [-37.355000000249788, -8.764999992455614],
              [-37.354166665958815, -8.764999992455614],
              [-37.354166665958815, -8.767499992630519],
              [-37.354166666858134, -8.768333326022116],
              [-37.351666665783966, -8.768333326022116],
              [-37.351666667582606, -8.76916665851445],
              [-37.350000000799355, -8.76916665851445],
              [-37.349999999000715, -8.768333326022116],
              [-37.347500000624507, -8.768333326022116],
              [-37.347499998825867, -8.767499993529839],
              [-37.345833332042616, -8.767499991731199],
              [-37.345833333841256, -8.766666659238865],
              [-37.344166665259365, -8.766666659238865],
              [-37.344166665259365, -8.765833325847268],
              [-37.342499999375434, -8.765833325847268],
              [-37.342499998476114, -8.766666659238865],
              [-37.340833331692863, -8.766666659238865],
              [-37.340833332592183, -8.76916665941377],
              [-37.340000000099906, -8.76916665851445],
              [-37.340000000999225, -8.769999992805367],
              [-37.334999999750153, -8.769999992805367],
              [-37.335000000649472, -8.770833326197021],
              [-37.334166667257819, -8.770833326197021],
              [-37.334166666358499, -8.771666658689298],
              [-37.333333332966902, -8.771666658689298],
              [-37.333333332067582, -8.772499992980272],
              [-37.327499999225552, -8.772499993879592],
              [-37.327500001024191, -8.77333332457323],
              [-37.320833332991867, -8.773333325472549],
              [-37.320833332991867, -8.774166658864146],
              [-37.319166665309297, -8.774166659763466],
              [-37.319166668007256, -8.778333325822302],
              [-37.31999999960027, -8.778333324922983],
              [-37.319999998700951, -8.779166659213899],
              [-37.319166666208616, -8.779166659213899],
              [-37.319166668007256, -8.784166658664333],
              [-37.318333332817019, -8.784166658664333],
              [-37.318333332817019, -8.78499999295525],
              [-37.317499998526046, -8.78499999295525],
              [-37.317499998526046, -8.786666658839181],
              [-37.316666666033768, -8.786666657939861],
              [-37.316666666933088, -8.789166659014029],
              [-37.315833332642171, -8.789166659014029],
              [-37.315833331742795, -8.79083332489796],
              [-37.314999998351198, -8.79083332579728],
              [-37.314999999250517, -8.791666660088254],
              [-37.31416666585892, -8.791666660088254],
              [-37.31416666765756, -8.792499992580531],
              [-37.312499999075669, -8.792499992580531],
              [-37.312499999075669, -8.793333326871505],
              [-37.311666665684015, -8.793333325972185],
              [-37.311666667482655, -8.794999992755436],
              [-37.310833331393098, -8.794999992755436],
              [-37.310833334091058, -8.795833326147033],
              [-37.311666666583335, -8.795833326147033],
              [-37.311666665684015, -8.798333325422561],
              [-37.310833334091058, -8.798333325422561],
              [-37.310833331393098, -8.799166658814215],
              [-37.309999998900764, -8.799166658814215],
              [-37.310000000699404, -8.799999993105132],
              [-37.309166666408487, -8.799999992205812],
              [-37.309166666408487, -8.800833325597466],
              [-37.308333331218194, -8.800833325597466],
              [-37.30833333301689, -8.801666658989063],
              [-37.307499998725916, -8.801666658989063],
              [-37.307499998725916, -8.804166659163968],
              [-37.306666668032278, -8.804166659163968],
              [-37.306666665334319, -8.806666658439497],
              [-37.307499998725916, -8.806666658439497],
              [-37.307499999625236, -8.808333326122067],
              [-37.30833333391621, -8.808333326122067],
              [-37.30833333301689, -8.809166659513664],
              [-37.309166664609847, -8.809166658614345],
              [-37.309999998900764, -8.809166657715025],
              [-37.309999999800084, -8.808333326122067],
              [-37.310833334091058, -8.808333323424108],
              [-37.310833331393098, -8.807499992730413],
              [-37.311666667482655, -8.807499992730413],
              [-37.311666666583335, -8.806666658439497],
              [-37.31416666585892, -8.806666658439497],
              [-37.314999998351198, -8.806666658439497],
              [-37.314999999250517, -8.805833325947162],
              [-37.315833332642171, -8.805833325047843],
              [-37.315833332642171, -8.804999992555565],
              [-37.317500000324685, -8.804999992555565],
              [-37.317499998526046, -8.804166659163968],
              [-37.323333332267453, -8.804166657365272],
              [-37.323333332267453, -8.804999992555565],
              [-37.324999999949966, -8.804999992555565],
              [-37.324999999050647, -8.805833325047843],
              [-37.326666665833898, -8.805833326846482],
              [-37.326666666733217, -8.806666658439497],
              [-37.327499999225552, -8.806666658439497],
              [-37.327499999225552, -8.807499992730413],
              [-37.329166664210163, -8.807499994529053],
              [-37.32999999850108, -8.807499992730413],
              [-37.330000000299719, -8.805833325947162],
              [-37.330833331892734, -8.805833325947162],
              [-37.330833332792054, -8.804166659163968],
              [-37.332499998675928, -8.804166659163968],
              [-37.332499998675928, -8.803333325772314],
              [-37.333333332067582, -8.803333325772314],
              [-37.333333332067582, -8.802499991481398],
              [-37.335833330443791, -8.802499992380717],
              [-37.33583333134311, -8.801666658989063],
              [-37.340833332592183, -8.801666658989063],
              [-37.340833331692863, -8.800833324698146],
              [-37.34166666598378, -8.800833325597466],
              [-37.34166666598378, -8.799999993105132],
              [-37.342499999375434, -8.799999993105132],
              [-37.342500000274754, -8.799166658814215],
              [-37.343333332767031, -8.799166657914895],
              [-37.343333332767031, -8.799999993105132],
              [-37.344999999550282, -8.799999994004452],
              [-37.345000000449602, -8.799166658814215],
              [-37.347499998825867, -8.799166658814215],
              [-37.347499997926548, -8.799999993105132],
              [-37.349166666508381, -8.799999993105132],
              [-37.349166667407758, -8.798333325422561],
              [-37.349999999900035, -8.798333325422561],
              [-37.349999999000715, -8.79666665863931],
              [-37.350833331492993, -8.79666665863931],
              [-37.350833333291632, -8.795833326147033],
              [-37.351666667582606, -8.795833326147033],
              [-37.351666667582606, -8.794999991856116],
              [-37.353333333466537, -8.794999992755436],
              [-37.353333331667898, -8.793333325972185],
              [-37.354166665958815, -8.793333325972185],
              [-37.354166665958815, -8.792499993479851],
              [-37.354999999350468, -8.792499992580531],
              [-37.355000000249788, -8.791666659188934],
              [-37.356666665234343, -8.791666657390294],
              [-37.356666668831679, -8.7908333266966],
              [-37.357499998625997, -8.79083332579728],
              [-37.357500000424636, -8.791666660088254],
              [-37.358333332916914, -8.791666659188934],
              [-37.358333332916914, -8.794166658464462],
              [-37.359166665409248, -8.794166658464462],
              [-37.359166666308568, -8.795833326147033],
              [-37.359999997901525, -8.795833326147033],
              [-37.360000000599484, -8.796666659538687],
              [-37.360833333991138, -8.79666665863931],
              [-37.360833333091819, -8.799166657914895],
              [-37.361666666483416, -8.799166658814215],
              [-37.361666666483416, -8.799999993105132],
              [-37.36249999987507, -8.799999993105132],
              [-37.36249999897575, -8.801666658089744],
              [-37.361666666483416, -8.801666658989063],
              [-37.361666665584096, -8.802499992380717],
              [-37.360833333091819, -8.802499992380717],
              [-37.360833333991138, -8.803333324872995],
              [-37.360000000599484, -8.803333325772314],
              [-37.359999999700165, -8.804166659163968],
              [-37.36249999987507, -8.804166659163968],
              [-37.362500000774389, -8.804999991656246],
              [-37.363333330568707, -8.804999992555565],
              [-37.363333332367347, -8.806666658439497],
              [-37.36416666755764, -8.806666658439497],
              [-37.364166666658321, -8.807499992730413],
              [-37.365833332542195, -8.807499992730413],
              [-37.365833334340891, -8.809166658614345],
              [-37.366666665933849, -8.809166658614345],
              [-37.366666667732488, -8.809999992905318],
              [-37.367499998426126, -8.809999992905318],
              [-37.367500000224766, -8.810833325397596],
              [-37.36833333091846, -8.810833325397596],
              [-37.36833333361642, -8.81333332647182],
              [-37.369166667907336, -8.813333325572444],
              [-37.369166665209377, -8.815833325747349],
              [-37.370833332891948, -8.815833325747349],
              [-37.370833332891948, -8.816666659138946],
              [-37.371666665384282, -8.816666659138946],
              [-37.371666664484962, -8.819166658414531],
              [-37.370833331992628, -8.819166658414531],
              [-37.370833333791268, -8.819999991806128],
              [-37.369999999500351, -8.819999992705448],
              [-37.370000000399671, -8.820833326097102],
              [-37.370833331992628, -8.820833326097102],
              [-37.370833331992628, -8.821666658589379],
              [-37.372499998775879, -8.821666657690059],
              [-37.372499998775879, -8.822499992880296],
              [-37.373333333066796, -8.822499991980976],
              [-37.373333331268157, -8.82333332537263],
              [-37.375833332342381, -8.82333332537263],
              [-37.375833332342381, -8.824166658764227],
              [-37.377499998226313, -8.824166658764227],
              [-37.377500000024952, -8.82333332537263],
              [-37.379166666808203, -8.82333332537263],
              [-37.379166666808203, -8.822499993779616],
              [-37.381666667882371, -8.822499992880296],
              [-37.381666666083731, -8.82333332537263],
              [-37.38416666535926, -8.82333332537263],
              [-37.38416666535926, -8.824999993055201],
              [-37.385000000549553, -8.824999993055201],
              [-37.384999999650233, -8.825833325547478],
              [-37.386666664634845, -8.825833325547478],
              [-37.386666667332804, -8.828333325722383],
              [-37.387499998026442, -8.828333325722383],
              [-37.387500000724401, -8.829999993404897],
              [-37.385000000549553, -8.829999992505577],
              [-37.384999999650233, -8.830833325897231],
              [-37.38416666535926, -8.830833325897231],
              [-37.384166666258579, -8.831666658389508],
              [-37.383333333766302, -8.831666657490189],
              [-37.383333332866982, -8.832499992680482],
              [-37.381666666083731, -8.832499992680482],
              [-37.381666667882371, -8.834166658564413],
              [-37.380833332692134, -8.834166658564413],
              [-37.380833331792758, -8.83499999285533],
              [-37.37999999930048, -8.83499999285533],
              [-37.37999999930048, -8.835833327146304],
              [-37.378333332517229, -8.835833325347664],
              [-37.37833333161791, -8.836666658739261],
              [-37.377499998226313, -8.836666658739261],
              [-37.377500000024952, -8.837499993030235],
              [-37.376666665733978, -8.837499992130859],
              [-37.376666666633298, -8.838333325522512],
              [-37.375833333241701, -8.838333325522512],
              [-37.375833331443062, -8.840833325697361],
              [-37.374999999850047, -8.840833325697361],
              [-37.374999998051408, -8.841666659089015],
              [-37.37416666645845, -8.841666659089015],
              [-37.37416666555913, -8.843333325872266],
              [-37.373333331268157, -8.843333325872266],
              [-37.373333333066796, -8.844999992655517],
              [-37.372499998775879, -8.84499999175614],
              [-37.372499999675199, -8.846666658539391],
              [-37.371666666283602, -8.846666658539391],
              [-37.371666665384282, -8.847499992830365],
              [-37.370833332891948, -8.847499991931045],
              [-37.370833331992628, -8.848333326221962],
              [-37.369999998601031, -8.848333326221962],
              [-37.369999999500351, -8.849166658714296],
              [-37.3683333327171, -8.849166657814976],
              [-37.36833333181778, -8.849999993005213],
              [-37.367499999325446, -8.849999993005213],
              [-37.367499998426126, -8.853333325672395],
              [-37.369166665209377, -8.853333325672395],
              [-37.369166667008017, -8.854166659063992],
              [-37.369999998601031, -8.854166659063992],
              [-37.370000000399671, -8.859166658514425],
              [-37.370833331992628, -8.859166657615106],
              [-37.370833332891948, -8.860833326196996],
              [-37.371666665384282, -8.860833326196996],
              [-37.371666668082241, -8.861666658689273],
              [-37.372499999675199, -8.861666658689273],
              [-37.372499998775879, -8.863333325472524],
              [-37.373333333966173, -8.863333326371844],
              [-37.373333333966173, -8.864166659763498],
              [-37.374166664659811, -8.864166658864178],
              [-37.37416666555913, -8.864999993155095],
              [-37.374999999850047, -8.864999993155095],
              [-37.375000000749367, -8.865833326546749],
              [-37.375833332342381, -8.865833325647429],
              [-37.375833332342381, -8.866666659039026],
              [-37.376666666633298, -8.866666659039026],
              [-37.376666666633298, -8.86749999243068],
              [-37.37833333161791, -8.86749999243068],
              [-37.37833333161791, -8.866666659039026],
              [-37.381666667882371, -8.866666659039026],
              [-37.381666666983051, -8.86749999422932],
              [-37.391666666783181, -8.86749999243068],
              [-37.391666666783181, -8.868333325822277],
              [-37.394166666958085, -8.868333325822277],
              [-37.394166666058766, -8.869166659213931],
              [-37.395833332842017, -8.869166660113251],
              [-37.395833332842017, -8.869999991706209],
              [-37.396666665334294, -8.869999992605528],
              [-37.396666666233614, -8.870833325997125],
              [-37.399166667307782, -8.870833325997125],
              [-37.399166666408462, -8.869999992605528],
              [-37.399999998900796, -8.869999991706209],
              [-37.399999998900796, -8.872499992780376],
              [-37.403333333366618, -8.872499993679696],
              [-37.403333332467298, -8.87166665848946],
              [-37.407499999425397, -8.87166665848946],
              [-37.407499999425397, -8.872499991881057],
              [-37.408333331917675, -8.872499993679696],
              [-37.408333331917675, -8.874166658664308],
              [-37.409166666208648, -8.874166658664308],
              [-37.409166665309328, -8.876666658839213],
              [-37.410833333891219, -8.876666658839213],
              [-37.410833332991899, -8.879999991506338],
              [-37.411666666383496, -8.879999994204297],
              [-37.411666666383496, -8.880833325797312],
              [-37.41249999977515, -8.880833325797312],
              [-37.41249999977515, -8.881666659188909],
              [-37.413333332267428, -8.881666657390269],
              [-37.413333334066067, -8.882499992580563],
              [-37.414166666558344, -8.882499991681243],
              [-37.414166667457721, -8.881666659188909],
              [-37.415833331542956, -8.881666659188909],
              [-37.415833331542956, -8.882499992580563],
              [-37.4183333335165, -8.882499991681243],
              [-37.418333332617181, -8.88333332597216],
              [-37.419166666008778, -8.88333332507284],
              [-37.419166666908097, -8.884166658464494],
              [-37.420833333691348, -8.884166658464494],
              [-37.420833333691348, -8.884999991856091],
              [-37.422500000474599, -8.884999992755411],
              [-37.42249999777664, -8.885833326147065],
              [-37.424166665459211, -8.885833326147065],
              [-37.424166664559891, -8.887499993829579],
              [-37.425000000649447, -8.887499993829579],
              [-37.424999999750128, -8.888333325422593],
              [-37.425833332242462, -8.888333325422593],
              [-37.425833332242462, -8.88916665791487],
              [-37.426666666533379, -8.88916665971351],
              [-37.426666667432698, -8.889999993105164],
              [-37.427499999025713, -8.889999993105164],
              [-37.429999998301241, -8.889999993105164],
              [-37.429999998301241, -8.88916665791487],
              [-37.431666665983812, -8.88916665881419],
              [-37.431666667782451, -8.888333325422593],
              [-37.432499999375409, -8.888333325422593],
              [-37.432499999375409, -8.886666658639342],
              [-37.433333332767063, -8.886666659538662],
              [-37.433333333666383, -8.885833326147065],
              [-37.4341666679573, -8.885833326147065],
              [-37.43416666525934, -8.884999991856091],
              [-37.434999998650994, -8.884999992755411],
              [-37.434999998650994, -8.884166658464494],
              [-37.436666665434245, -8.884166657565174],
              [-37.436666666333565, -8.88333332597216],
              [-37.438333334016136, -8.88333332687148],
              [-37.438333334016136, -8.882499992580563],
              [-37.43999999990001, -8.882499992580563],
              [-37.43999999900069, -8.881666659188909],
              [-37.440833332392344, -8.881666659188909],
              [-37.440833332392344, -8.880833325797312],
              [-37.444166666858166, -8.880833325797312],
              [-37.444166666858166, -8.881666659188909],
              [-37.447500001323931, -8.881666659188909],
              [-37.447499998625972, -8.882499992580563],
              [-37.449166665409223, -8.882499993479883],
              [-37.449166667207862, -8.881666659188909],
              [-37.450833333991113, -8.881666659188909],
              [-37.450833333091794, -8.880833324897992],
              [-37.451666666483447, -8.880833325797312],
              [-37.451666666483447, -8.875833325447559],
              [-37.452499998975725, -8.875833325447559],
              [-37.452499999875045, -8.874999992955281],
              [-37.453333333266698, -8.874999992955281],
              [-37.453333333266698, -8.87333332617203],
              [-37.453333332367379, -8.872499992780376],
              [-37.452500000774364, -8.872499992780376],
              [-37.452500000774364, -8.87166665848946],
              [-37.450833331293154, -8.87166665848946],
              [-37.450833331293154, -8.870833325997125],
              [-37.449999998800877, -8.870833325997125],
              [-37.449999999700196, -8.869999992605528],
              [-37.448333333816265, -8.869999992605528],
              [-37.448333332916945, -8.868333325822277],
              [-37.449166665409223, -8.868333325822277],
              [-37.449166665409223, -8.866666659039026],
              [-37.449999998800877, -8.866666659039026],
              [-37.449999998800877, -8.865833325647429],
              [-37.451666665584128, -8.86583332474811],
              [-37.452500000774364, -8.865833326546749],
              [-37.452499999875045, -8.864999993155095],
              [-37.453333335065338, -8.864999993155095],
              [-37.453333333266698, -8.864166658864178],
              [-37.454166668456935, -8.864166658864178],
              [-37.454166666658296, -8.860833326196996],
              [-37.455000000049893, -8.860833326196996],
              [-37.454999998251253, -8.857499992630494],
              [-37.455833331642907, -8.857499992630494],
              [-37.455833331642907, -8.856666659238897],
              [-37.456666666833144, -8.856666659238897],
              [-37.456666665933824, -8.855833325847243],
              [-37.457499998426158, -8.855833324947923],
              [-37.457499998426158, -8.854999992455646],
              [-37.457500002023437, -8.854166659063992],
              [-37.459166667907368, -8.854166659063992],
              [-37.459166665209409, -8.853333325672395],
              [-37.459999998601006, -8.853333325672395],
              [-37.460000000399646, -8.850833325497547],
              [-37.46083333289198, -8.850833325497547],
              [-37.4608333337913, -8.849999993005213],
              [-37.461666664484937, -8.849999993005213],
              [-37.461666665384257, -8.849166658714296],
              [-37.462500000574551, -8.849166658714296],
              [-37.462499998775854, -8.848333326221962],
              [-37.463333333066828, -8.848333326221962],
              [-37.463333331268188, -8.847499992830365],
              [-37.464166667357745, -8.847499992830365],
              [-37.464166667357745, -8.844999993554836],
              [-37.465000000749399, -8.844999992655517],
              [-37.464999999850079, -8.844166658364543],
              [-37.479999999100642, -8.844166658364543],
              [-37.480000000899281, -8.843333325872266],
              [-37.480833333391558, -8.843333325872266],
              [-37.480833331592919, -8.842499992480612],
              [-37.484999999450395, -8.842499992480612],
              [-37.484999999450395, -8.843333325872266],
              [-37.486666668032285, -8.843333325872266],
              [-37.486666665334269, -8.844166658364543],
              [-37.4883333312182, -8.844166658364543],
              [-37.4883333312182, -8.84499999175614],
              [-37.489166665509174, -8.844999992655517],
              [-37.489166665509174, -8.845833325147794],
              [-37.489999998900771, -8.845833326946433],
              [-37.489999999800091, -8.846666658539391],
              [-37.490833331393105, -8.846666657640071],
              [-37.490833331393105, -8.847499992830365],
              [-37.492500000874315, -8.847499993729684],
              [-37.492499997277037, -8.848333324423322],
              [-37.494166665858927, -8.848333326221962],
              [-37.494166665858927, -8.847499991931045],
              [-37.497500000324692, -8.847499992830365],
              [-37.497499998526052, -8.841666659089015],
              [-37.498333332817026, -8.841666659089015],
              [-37.498333332817026, -8.840833325697361],
              [-37.500833332092554, -8.840833325697361],
              [-37.500833332092554, -8.841666659089015],
              [-37.502499998875805, -8.841666659089015],
              [-37.502499997976486, -8.842499992480612],
              [-37.504999999949973, -8.842499992480612],
              [-37.504999999050654, -8.843333325872266],
              [-37.505833331542988, -8.843333325872266],
              [-37.505833333341627, -8.842499992480612],
              [-37.506666665833905, -8.842499992480612],
              [-37.506666666733224, -8.841666659089015],
              [-37.508333332617156, -8.841666659089015],
              [-37.508333331717836, -8.840833325697361],
              [-37.509166666908129, -8.840833325697361],
              [-37.509166666908129, -8.839999993205083],
              [-37.510000000299726, -8.839999994104403],
              [-37.510000000299726, -8.839166659813429],
              [-37.511666665284338, -8.83916665891411],
              [-37.511666667082977, -8.838333325522512],
              [-37.512499999575255, -8.838333325522512],
              [-37.512499997776615, -8.837499993030235],
              [-37.513333330268949, -8.837499993030235],
              [-37.513333332966909, -8.829999992505577],
              [-37.514166665459186, -8.829999992505577],
              [-37.514166666358506, -8.82916665911398],
              [-37.51499999885084, -8.8291666600133],
              [-37.515000000649479, -8.828333325722383],
              [-37.516666665634091, -8.828333325722383],
              [-37.516666666533411, -8.827499993230049],
              [-37.518333333316662, -8.827499993230049],
              [-37.518333331517965, -8.826666658939132],
              [-37.524166665259372, -8.826666658939132],
              [-37.524166667957331, -8.825833325547478],
              [-37.524999999550289, -8.825833326446798],
              [-37.524999999550289, -8.826666658939132],
              [-37.52666666633354, -8.826666658939132],
              [-37.52666666543422, -8.827499993230049],
              [-37.528333332217471, -8.827499993230049],
              [-37.528333331318152, -8.826666658939132],
              [-37.530000000799362, -8.826666658939132],
              [-37.529999999000722, -8.827499993230049],
              [-37.531666665783973, -8.827499993230049],
              [-37.531666666683293, -8.826666658939132],
              [-37.53250000007489, -8.826666658939132],
              [-37.53250000007489, -8.825833325547478],
              [-37.533333332567224, -8.825833325547478],
              [-37.533333333466544, -8.824999993954521],
              [-37.535833332742072, -8.824999992155881],
              [-37.535833333641392, -8.821666657690059],
              [-37.536666667932309, -8.821666659488699],
              [-37.53666666523435, -8.820833326097102],
              [-37.537499998626004, -8.820833326097102],
              [-37.537499999525323, -8.821666659488699],
              [-37.538333332017601, -8.821666658589379],
              [-37.53833333381624, -8.822499993779616],
              [-37.539166669006534, -8.822499992880296],
              [-37.539166664509935, -8.82333332537263],
              [-37.539999999700171, -8.82333332537263],
              [-37.539999999700171, -8.824166659663547],
              [-37.540833331293186, -8.824166658764227],
              [-37.540833331293186, -8.824999993055201],
              [-37.542500000774396, -8.824999993055201],
              [-37.542499999875076, -8.825833325547478],
              [-37.543333331468034, -8.825833325547478],
              [-37.543333331468034, -8.826666658939132],
              [-37.544166665758951, -8.826666658939132],
              [-37.544166668456967, -8.828333326621703],
              [-37.545833331642882, -8.828333325722383],
              [-37.545833332542202, -8.8291666600133],
              [-37.546666667732495, -8.82916665911398],
              [-37.546666666833175, -8.829999992505577],
              [-37.548333333616426, -8.829999992505577],
              [-37.548333331817787, -8.830833325897231],
              [-37.550833331992635, -8.830833325897231],
              [-37.550833332891955, -8.831666658389508],
              [-37.551666666283552, -8.831666657490189],
              [-37.551666665384232, -8.832499992680482],
              [-37.552499998775886, -8.832499991781162],
              [-37.552500000574526, -8.833333326072079],
              [-37.554166666458457, -8.833333326072079],
              [-37.554166666458457, -8.834166658564413],
              [-37.555833333241708, -8.834166659463733],
              [-37.555833331443068, -8.83499999375465],
              [-37.556666666633305, -8.83499999285533],
              [-37.556666667532625, -8.835833325347664],
              [-37.557499998226319, -8.835833325347664],
              [-37.557500000924279, -8.836666658739261],
              [-37.558333332517236, -8.836666658739261],
              [-37.558333332517236, -8.838333325522512],
              [-37.55916666770753, -8.838333325522512],
              [-37.559166668606849, -8.83916665891411],
              [-37.559999998401167, -8.83916665801479],
              [-37.559999998401167, -8.840833325697361],
              [-37.560833331792765, -8.840833325697361],
              [-37.560833333591404, -8.842499992480612],
              [-37.561666666083738, -8.842499992480612],
              [-37.562499998576016, -8.842499992480612],
              [-37.562499999475335, -8.841666658189695],
              [-37.565833333941157, -8.841666659089015],
              [-37.565833333041837, -8.840833325697361],
              [-37.568333330518726, -8.840833325697361],
              [-37.568333332317366, -8.839999993205083],
              [-37.569999999100617, -8.839999993205083],
              [-37.569999999100617, -8.83916665891411],
              [-37.570833332492271, -8.83916665891411],
              [-37.570833332492271, -8.838333324623193],
              [-37.573333330868479, -8.838333325522512],
              [-37.573333333566438, -8.837499993929555],
              [-37.574166667857412, -8.837499993030235],
              [-37.574166666958092, -8.836666658739261],
              [-37.584166666758222, -8.836666659638581],
              [-37.584166666758222, -8.837499993030235],
              [-37.584999998351179, -8.837499993030235],
              [-37.584999999250499, -8.838333325522512],
              [-37.58666666603375, -8.838333325522512],
              [-37.58666666603375, -8.839999993205083],
              [-37.587500000324724, -8.839999994104403],
              [-37.587499998526084, -8.841666659089015],
              [-37.588333331917681, -8.841666659089015],
              [-37.588333332817001, -8.842499992480612],
              [-37.589166666208655, -8.842499991581292],
              [-37.589166668007294, -8.844166658364543],
              [-37.590833333891226, -8.844166658364543],
              [-37.590833332092586, -8.844999992655517],
              [-37.591666667282823, -8.844999992655517],
              [-37.591666665484183, -8.844166658364543],
              [-37.593333334066074, -8.844166658364543],
              [-37.593333334066074, -8.844999992655517],
              [-37.594166665659031, -8.844999992655517],
              [-37.594166666558351, -8.844166658364543],
              [-37.594999999050685, -8.844166658364543],
              [-37.594999999050685, -8.843333325872266],
              [-37.595833331542963, -8.843333326771585],
              [-37.595833332442282, -8.842499992480612],
              [-37.596666667632576, -8.842499992480612],
              [-37.596666667632576, -8.841666659089015],
              [-37.597499999225533, -8.841666659089015],
              [-37.597499998326214, -8.840833325697361],
              [-37.598333333516507, -8.840833325697361],
              [-37.598333332617187, -8.839999993205083],
              [-37.599999999400382, -8.839999991406444],
              [-37.599999998501062, -8.83916665891411],
              [-37.600833331892716, -8.83916665891411],
              [-37.600833332792035, -8.838333325522512],
              [-37.601666667982272, -8.838333325522512],
              [-37.601666667082952, -8.837499993030235],
              [-37.602499999575286, -8.837499993030235],
              [-37.602499999575286, -8.836666658739261],
              [-37.604166667257857, -8.836666658739261],
              [-37.604166665459218, -8.835833325347664],
              [-37.604999998850815, -8.835833325347664],
              [-37.604999999750135, -8.83499999285533],
              [-37.605833331343149, -8.83499999285533],
              [-37.605833331343149, -8.834166658564413],
              [-37.607499999025663, -8.834166658564413],
              [-37.607499999925039, -8.833333326072079],
              [-37.610000000999207, -8.833333326072079],
              [-37.610000000099888, -8.832499992680482],
              [-37.611666666883139, -8.832499992680482],
              [-37.611666665983819, -8.831666658389508],
              [-37.612500000274736, -8.831666658389508],
              [-37.612500000274736, -8.827499993230049],
              [-37.61333333366639, -8.827499993230049],
              [-37.61333333276707, -8.824999992155881],
              [-37.614166667957306, -8.824999993055201],
              [-37.614166665259347, -8.822499991980976],
              [-37.614999999550321, -8.822499992880296],
              [-37.614999999550321, -8.818333325922197],
              [-37.614166667057987, -8.818333325922197],
              [-37.614166665259347, -8.816666659138946],
              [-37.612500000274736, -8.816666659138946],
              [-37.612499999375416, -8.815833324848029],
              [-37.609999998301248, -8.815833325747349],
              [-37.609999998301248, -8.814999992355695],
              [-37.610833333491485, -8.814999992355695],
              [-37.610833333491485, -8.814166658964098],
              [-37.611666665983819, -8.814166658964098],
              [-37.611666667782458, -8.810833327196235],
              [-37.612499998476096, -8.810833325397596],
              [-37.612499999375416, -8.808333325222748],
              [-37.61333333186775, -8.808333326122067],
              [-37.61333333366639, -8.807499993629733],
              [-37.614166667957306, -8.807499992730413],
              [-37.614166667057987, -8.808333326122067],
              [-37.615000000449641, -8.808333326122067],
              [-37.614999998651001, -8.807499991831094],
              [-37.618333330418807, -8.807499992730413],
              [-37.618333332217446, -8.806666657540177],
              [-37.6191666656091, -8.806666658439497],
              [-37.61916666740774, -8.803333325772314],
              [-37.618333334016086, -8.803333325772314],
              [-37.618333330418807, -8.802499992380717],
              [-37.617499999725169, -8.802499992380717],
              [-37.617500000624489, -8.801666658989063],
              [-37.616666666333515, -8.801666658989063],
              [-37.616666665434195, -8.800833325597466],
              [-37.615833332042598, -8.800833325597466],
              [-37.615833332042598, -8.799999993105132],
              [-37.616666666333515, -8.799999993105132],
              [-37.616666665434195, -8.799166659713535],
              [-37.617499999725169, -8.799166658814215],
              [-37.617499998825849, -8.798333325422561],
              [-37.632499999875051, -8.798333325422561],
              [-37.632499998975732, -8.797499992930284],
              [-37.631666665584135, -8.797499993829604],
              [-37.631666665584135, -8.79666665863931],
              [-37.6308333330918, -8.79666665863931],
              [-37.630833331293161, -8.795833326147033],
              [-37.630000000599523, -8.795833326147033],
              [-37.629999999700203, -8.789166658114709],
              [-37.630833331293161, -8.789166659014029],
              [-37.6308333330918, -8.788333325622432],
              [-37.631666666483454, -8.788333325622432],
              [-37.631666665584135, -8.787499993130155],
              [-37.632499998076412, -8.787499993130155],
              [-37.632500000774371, -8.786666658839181],
              [-37.631666665584135, -8.786666658839181],
              [-37.631666665584135, -8.78499999295525],
              [-37.630833331293161, -8.78499999295525],
              [-37.63083333399112, -8.784166658664333],
              [-37.631666665584135, -8.784166658664333],
              [-37.631666665584135, -8.782499992780401],
              [-37.630833331293161, -8.782499992780401],
              [-37.630833331293161, -8.777499992430648],
              [-37.631666667382774, -8.777499994229288],
              [-37.631666666483454, -8.776666659039051],
              [-37.632499998975732, -8.776666658139732],
              [-37.632499999875051, -8.775833325647397],
              [-37.633333333266648, -8.775833325647397],
              [-37.633333331468009, -8.77499999315512],
              [-37.634166666658302, -8.77499999315512],
              [-37.634166665758983, -8.773333325472549],
              [-37.635000000949219, -8.773333326371869],
              [-37.635000000949219, -8.772499992980272],
              [-37.635833333441553, -8.772499992980272],
              [-37.635833332542234, -8.771666658689298],
              [-37.63499999825126, -8.771666658689298],
              [-37.63499999825126, -8.770833326197021],
              [-37.635833331642914, -8.770833324398382],
              [-37.635833333441553, -8.769999992805367],
              [-37.635000000049899, -8.769999992805367],
              [-37.635000000949219, -8.76916665941377],
              [-37.634166666658302, -8.769166657615131],
              [-37.634166664859663, -8.767499992630519],
              [-37.633333333266648, -8.767499993529839],
              [-37.633333333266648, -8.766666659238865],
              [-37.632499999875051, -8.766666660138185],
              [-37.632499998076412, -8.765833325847268],
              [-37.631666666483454, -8.765833325847268],
              [-37.631666666483454, -8.764166659064017],
              [-37.632499999875051, -8.764166658164697],
              [-37.632499999875051, -8.762499994079405],
              [-37.634166667557622, -8.762499993180086],
              [-37.634166665758983, -8.760833325497515],
              [-37.635833330743594, -8.760833325497515],
              [-37.635833333441553, -8.758333327121306],
              [-37.63666666683315, -8.758333326221987],
              [-37.63666666683315, -8.757499992830333],
              [-37.637499998426165, -8.757499992830333],
              [-37.637499998426165, -8.756666658539416],
              [-37.63666666773247, -8.756666658539416],
              [-37.63666666773247, -8.754166658364568],
              [-37.637499999325485, -8.754166658364568],
              [-37.637499998426165, -8.753333324972914],
              [-37.638333332717082, -8.753333325872234],
              [-37.638333331817762, -8.751666659088983],
              [-37.639166667008055, -8.751666658189663],
              [-37.639166665209416, -8.750833325697386],
              [-37.638333332717082, -8.750833325697386],
              [-37.638333333616401, -8.749999993205051],
              [-37.637499998426165, -8.749999993205051],
              [-37.637499999325485, -8.749166658914135],
              [-37.638333332717082, -8.749166658914135],
              [-37.638333330918442, -8.748333325522481],
              [-37.64083333199261, -8.748333325522481],
              [-37.64083333199261, -8.747499993030203],
              [-37.641666665384264, -8.747499993030203],
              [-37.641666665384264, -8.745833325347633],
              [-37.643333333066835, -8.745833328045592],
              [-37.643333334865474, -8.744999992855355],
              [-37.642499998775861, -8.744999992855355],
              [-37.642500000574501, -8.744166658564382],
              [-37.643333333066835, -8.744166658564382],
              [-37.643333331268195, -8.742499991781131],
              [-37.642499999675181, -8.74249999268045],
              [-37.642499998775861, -8.740833325897199],
              [-37.643333333966154, -8.740833326796576],
              [-37.643333331268195, -8.739166658214685],
              [-37.644166667357752, -8.739166658214685],
              [-37.644166665559112, -8.738333325722351],
              [-37.644166666458432, -8.737499993230074],
              [-37.645833331443043, -8.737499993230074],
              [-37.645833332342363, -8.73666665803978],
              [-37.646666668431976, -8.73666665983842],
              [-37.646666667532656, -8.734999993055169],
              [-37.647500000024934, -8.734999993055169],
              [-37.647499998226294, -8.734166658764252],
              [-37.646666665734017, -8.734166657864932],
              [-37.646666665734017, -8.733333327171238],
              [-37.645833331443043, -8.733333325372598],
              [-37.645833332342363, -8.73083332609707],
              [-37.647500000924254, -8.73083332609707],
              [-37.647500000024934, -8.729999992705473],
              [-37.646666666633337, -8.729999992705473],
              [-37.646666667532656, -8.729166660213139],
              [-37.648333332517211, -8.729166658414499],
              [-37.648333333416588, -8.728333325922222],
              [-37.649166667707505, -8.728333325922222],
              [-37.649166666808185, -8.727499993429888],
              [-37.648333331617891, -8.727499992530568],
              [-37.648333333416588, -8.725833325747317],
              [-37.649166665908865, -8.725833325747317],
              [-37.649166666808185, -8.721666658789218],
              [-37.648333332517211, -8.721666658789218],
              [-37.648333332517211, -8.720833325397621],
              [-37.646666666633337, -8.720833325397621],
              [-37.646666666633337, -8.71916665861437],
              [-37.647499998226294, -8.71916665861437],
              [-37.647499998226294, -8.718333326122035],
              [-37.646666667532656, -8.718333325222716],
              [-37.646666667532656, -8.713333326671659],
              [-37.647500000924254, -8.713333326671659],
              [-37.648333333416588, -8.713333325772339],
              [-37.648333331617891, -8.714166659163936],
              [-37.649999998401142, -8.714166659163936],
              [-37.649999998401142, -8.71333332487302],
              [-37.651666666083713, -8.713333325772339],
              [-37.651666666983033, -8.712499992380685],
              [-37.655833331243173, -8.712499992380685],
              [-37.655833333041869, -8.711666658089769],
              [-37.655833331243173, -8.710833325597434],
              [-37.654999999650215, -8.710833325597434],
              [-37.655000000549535, -8.708333325422586],
              [-37.655833331243173, -8.708333325422586],
              [-37.655833333941189, -8.706666658639335],
              [-37.655000000549535, -8.706666658639335],
              [-37.654999998750895, -8.704166658464487],
              [-37.653333331967644, -8.704166659363807],
              [-37.653333333766284, -8.700000001398905],
              [-37.649166667707505, -8.699999992405651],
              [-37.649166665009545, -8.694999992955275],
              [-37.649999998401142, -8.694999992955275],
              [-37.649999998401142, -8.694166658664301],
              [-37.650833331792796, -8.694166658664301],
              [-37.650833331792796, -8.693333326172024],
              [-37.652499998576047, -8.693333326172024],
              [-37.652500000374687, -8.691666659388773],
              [-37.653333333766284, -8.691666658489453],
              [-37.653333331967644, -8.689166659213925],
              [-37.654166668057258, -8.689166659213925],
              [-37.654166665359298, -8.688333325822271],
              [-37.653333331967644, -8.688333325822271],
              [-37.653333332866964, -8.68666665903902],
              [-37.652499998576047, -8.68666665903902],
              [-37.652499998576047, -8.685833325647422],
              [-37.650833332692116, -8.685833325647422],
              [-37.650833332692116, -8.681666658689323],
              [-37.649999998401142, -8.681666658689323],
              [-37.650000001099102, -8.679999992805392],
              [-37.649166667707505, -8.679999993704712],
              [-37.649166666808185, -8.679166658514418],
              [-37.645833331443043, -8.679166657615099],
              [-37.645833331443043, -8.67666665923889],
              [-37.646666666633337, -8.67666665923889],
              [-37.646666666633337, -8.675833325847236],
              [-37.645833332342363, -8.675833325847236],
              [-37.645833332342363, -8.674999992455639],
              [-37.644999998950766, -8.674999992455639],
              [-37.645000000749405, -8.669999993005206],
              [-37.643333331268195, -8.669999993005206],
              [-37.643333331268195, -8.668333324423315],
              [-37.642499999675181, -8.668333326221955],
              [-37.642499998775861, -8.667499992830358],
              [-37.639999999500333, -8.667499992830358],
              [-37.639999997701693, -8.666666658539441],
              [-37.639166667008055, -8.666666658539441],
              [-37.639166667907375, -8.66499999265551],
              [-37.639999998601013, -8.664999993554829],
              [-37.639999999500333, -8.663333325872259],
              [-37.64083333199261, -8.663333325872259],
              [-37.64083333199261, -8.661666662686287],
              [-37.642499999675181, -8.661666659089008],
              [-37.642500000574501, -8.660833325697354],
              [-37.643333333966154, -8.660833325697354],
              [-37.643333333966154, -8.659999994104396],
              [-37.644166666458432, -8.659999993205076],
              [-37.644166665559112, -8.658333325522506],
              [-37.643333333066835, -8.658333325522506],
              [-37.643333331268195, -8.657499993030228],
              [-37.644166667357752, -8.657499993030228],
              [-37.644166665559112, -8.656666658739255],
              [-37.644999998950766, -8.656666658739255],
              [-37.644999998950766, -8.654999992855323],
              [-37.647500001823573, -8.654999992855323],
              [-37.647499998226294, -8.654166658564407],
              [-37.648333331617891, -8.654166658564407],
              [-37.648333333416588, -8.653333326072072],
              [-37.649166665908865, -8.653333326072072],
              [-37.649166667707505, -8.651666658389502],
              [-37.649999998401142, -8.651666657490182],
              [-37.650000001099102, -8.650833324997905],
              [-37.649166666808185, -8.650833324997905],
              [-37.649166666808185, -8.649999991606308],
              [-37.648333333416588, -8.649999991606308],
              [-37.648333331617891, -8.648333325722376],
              [-37.647499998226294, -8.648333325722376],
              [-37.647500000924254, -8.646666658939125],
              [-37.646666665734017, -8.646666658939125],
              [-37.646666666633337, -8.643333325372623],
              [-37.645833332342363, -8.643333325372623],
              [-37.645833331443043, -8.642499992880346],
              [-37.644166666458432, -8.642499992880346],
              [-37.644166666458432, -8.641666657690052],
              [-37.643333333066835, -8.641666657690052],
              [-37.643333331268195, -8.640833326996415],
              [-37.64083333199261, -8.640833326097095],
              [-37.64083333109329, -8.634999991456368],
              [-37.639166667907375, -8.634999992355688],
              [-37.639166665209416, -8.633333324673174],
              [-37.637499999325485, -8.633333325572494],
              [-37.637499998426165, -8.63249999218084],
              [-37.63666666773247, -8.63249999308016],
              [-37.63666666683315, -8.631666658789243],
              [-37.634166666658302, -8.631666658789243],
              [-37.634166666658302, -8.630833325397589],
              [-37.631666667382774, -8.630833324498269],
              [-37.631666667382774, -8.629999992905312],
              [-37.630833331293161, -8.629999992905312],
              [-37.6308333330918, -8.62833332612206],
              [-37.629999999700203, -8.62833332612206],
              [-37.629999998800884, -8.627499992730407],
              [-37.629166667207869, -8.627499992730407],
              [-37.629166666308549, -8.620833325597459],
              [-37.629166666308549, -8.619166658814208],
              [-37.628333332916952, -8.619166659713528],
              [-37.628333332916952, -8.618333325422611],
              [-37.627499998625979, -8.618333325422611],
              [-37.627499998625979, -8.61666665863936],
              [-37.626666665234382, -8.61666665863936],
              [-37.626666667033021, -8.615833326147026],
              [-37.625833331842728, -8.615833326147026],
              [-37.625833334540687, -8.614166658464455],
              [-37.62499999935045, -8.614166658464455],
              [-37.62499999935045, -8.613333325072858],
              [-37.620833332392351, -8.613333325972178],
              [-37.620833331493031, -8.612499992580524],
              [-37.6191666656091, -8.612499992580524],
              [-37.61916666740774, -8.611666659188927],
              [-37.617499999725169, -8.611666659188927],
              [-37.617499999725169, -8.609999992405676],
              [-37.615833332042598, -8.609999992405676],
              [-37.615833332042598, -8.609166658114759],
              [-37.615000000449641, -8.609166659014079],
              [-37.614999999550321, -8.607499993130148],
              [-37.614166665259347, -8.607499993130148],
              [-37.614166665259347, -8.601666658489478],
              [-37.61333333366639, -8.601666658489478],
              [-37.61333333186775, -8.599999992605547],
              [-37.611666667782458, -8.599999992605547],
              [-37.611666665084499, -8.595833325647391],
              [-37.612500000274736, -8.595833325647391],
              [-37.612500000274736, -8.592499992980265],
              [-37.611666667782458, -8.592499993879585],
              [-37.611666667782458, -8.590833326197014],
              [-37.610833333491485, -8.590833326197014],
              [-37.610833333491485, -8.586666659238858],
              [-37.611666666883139, -8.586666659238858],
              [-37.611666667782458, -8.585833325847261],
              [-37.612499997576776, -8.585833326746581],
              [-37.612499999375416, -8.58416665906401],
              [-37.61333333096843, -8.58416665906401],
              [-37.61333333276707, -8.583333325672413],
              [-37.612499999375416, -8.583333325672413],
              [-37.612499998476096, -8.582499993180079],
              [-37.611666665084499, -8.582499991381439],
              [-37.611666666883139, -8.581666657989842],
              [-37.610833332592165, -8.581666658889162],
              [-37.610833331692845, -8.580833324598188],
              [-37.610000000999207, -8.580833325497508],
              [-37.609999998301248, -8.579999993005231],
              [-37.608333331517997, -8.579999993005231],
              [-37.608333331517997, -8.579166657814937],
              [-37.607499997227023, -8.579166658714257],
              [-37.607499999025663, -8.57833332442334],
              [-37.606666665634066, -8.57833332622198],
              [-37.606666665634066, -8.577499992830383],
              [-37.605000000649454, -8.577499992830383],
              [-37.605000000649454, -8.576666658539409],
              [-37.604166666358537, -8.576666658539409],
              [-37.604166665459218, -8.574166660163201],
              [-37.603333333866203, -8.574166658364561],
              [-37.603333333866203, -8.571666659088976],
              [-37.602499999575286, -8.571666659088976],
              [-37.602499998675967, -8.570833324798059],
              [-37.599166666908104, -8.570833325697379],
              [-37.599166666008784, -8.569999994104421],
              [-37.597500001024173, -8.569999993205101],
              [-37.597499999225533, -8.569166658914128],
              [-37.592499997976461, -8.569166658914128],
              [-37.59249999887578, -8.569999993205101],
              [-37.591666665484183, -8.569999993205101],
              [-37.591666664584864, -8.570833326596698],
              [-37.589166668007294, -8.570833325697379],
              [-37.589166666208655, -8.567499993030196],
              [-37.587499998526084, -8.567499992130877],
              [-37.587499998526084, -8.56666665873928],
              [-37.58666666603375, -8.56666665783996],
              [-37.58666666603375, -8.565833325347626],
              [-37.585833331742833, -8.565833325347626],
              [-37.585833331742833, -8.564999992855348],
              [-37.583333331567928, -8.564999992855348],
              [-37.583333332467248, -8.564166658564375],
              [-37.581666666583374, -8.564166658564375],
              [-37.581666665684054, -8.561666658389527],
              [-37.58083333139308, -8.561666657490207],
              [-37.580833334091039, -8.559999992505595],
              [-37.579999998900803, -8.559999993404915],
              [-37.580000000699442, -8.559166659113998],
              [-37.58083333139308, -8.559166659113998],
              [-37.5808333322924, -8.557499993230067],
              [-37.581666666583374, -8.557499994129387],
              [-37.581666667482693, -8.555833325547496],
              [-37.58250000177361, -8.555833325547496],
              [-37.582499999974971, -8.554999993954539],
              [-37.583333332467248, -8.554999993954539],
              [-37.583333333366625, -8.554166658764245],
              [-37.584166665858902, -8.554166658764245],
              [-37.584166666758222, -8.553333324473272],
              [-37.583333333366625, -8.553333325372648],
              [-37.583333331567928, -8.551666659488717],
              [-37.584166666758222, -8.551666658589397],
              [-37.584166666758222, -8.549999992705466],
              [-37.585833333541473, -8.549999992705466],
              [-37.585833331742833, -8.549166658414492],
              [-37.587499999425404, -8.549166657515173],
              [-37.587499998526084, -8.548333325022895],
              [-37.58666666693307, -8.548333325922215],
              [-37.58666666603375, -8.546666659138964],
              [-37.585833331742833, -8.546666657340324],
              [-37.585833332642153, -8.545833325747367],
              [-37.579166666408469, -8.545833325747367],
              [-37.579166665509149, -8.544999992355713],
              [-37.578333333016872, -8.544999992355713],
              [-37.578333331218232, -8.544166658964116],
              [-37.57666666803226, -8.544166658964116],
              [-37.576666664434981, -8.543333325572462],
              [-37.575833333741343, -8.543333325572462],
              [-37.575833331942647, -8.542499992180865],
              [-37.57499999855105, -8.542499993080185],
              [-37.57499999855105, -8.541666658789211],
              [-37.573333332667119, -8.541666658789211],
              [-37.573333332667119, -8.540833325397614],
              [-37.570833331592951, -8.540833325397614],
              [-37.570833331592951, -8.53999999290528],
              [-37.568333333216685, -8.53999999290528],
              [-37.568333332317366, -8.539166658614363],
              [-37.565833333941157, -8.539166658614363],
              [-37.565833331243198, -8.538333326122029],
              [-37.56499999965024, -8.538333327021405],
              [-37.564999997851601, -8.537499992730432],
              [-37.564166668956545, -8.537499992730432],
              [-37.564166668057226, -8.535833325947181],
              [-37.563333332866989, -8.535833325947181],
              [-37.563333332866989, -8.53416665916393],
              [-37.564166665359267, -8.534166660063249],
              [-37.564166666258586, -8.529166658814233],
              [-37.56499999875092, -8.529166658814233],
              [-37.56499999965024, -8.525833326147051],
              [-37.565833333941157, -8.525833326147051],
              [-37.565833331243198, -8.523333325972146],
              [-37.566666665534171, -8.523333325972146],
              [-37.566666666433491, -8.521666657390256],
              [-37.567499998925769, -8.521666659188952],
              [-37.567499999825088, -8.51833332472313],
              [-37.566666665534171, -8.51833332562245],
              [-37.566666666433491, -8.516666658839199],
              [-37.567499999825088, -8.516666658839199],
              [-37.567499998925769, -8.515833325447545],
              [-37.568333332317366, -8.515833325447545],
              [-37.568333332317366, -8.514999992955268],
              [-37.56916666570902, -8.514999992955268],
              [-37.56916666570902, -8.511666659388766],
              [-37.569166667507659, -8.510833325997169],
              [-37.568333332317366, -8.510833326896488],
              [-37.568333332317366, -8.509999992605515],
              [-37.566666667332811, -8.509999992605515],
              [-37.566666665534171, -8.508333325822264],
              [-37.565833331243198, -8.508333325822264],
              [-37.565833333941157, -8.507499992430667],
              [-37.564166665359267, -8.507499991531347],
              [-37.564166665359267, -8.506666659039013],
              [-37.563333332866989, -8.506666659039013],
              [-37.563333333766309, -8.505833326546735],
              [-37.562499998576016, -8.505833324748096],
              [-37.562499999475335, -8.504999993155138],
              [-37.561666667882378, -8.504999991356442],
              [-37.561666666983058, -8.504166658864165],
              [-37.560833332692084, -8.504166658864165],
              [-37.560833332692084, -8.503333325472568],
              [-37.558333333416556, -8.503333325472568],
              [-37.558333331617916, -8.502490851371647],
              [-37.558571487384199, -8.502373420596825],
              [-37.557381312897235, -8.497392422022585],
              [-37.557381131234195, -8.49739243011652],
              [-37.558568391917731, -8.50236125276956],
              [-37.556641947867547, -8.503311110419361],
              [-37.555871370067621, -8.50353425560138],
              [-37.555257232035615, -8.503839427047865],
              [-37.554562952718072, -8.504216123375329],
              [-37.553944972782233, -8.50452129572119],
              [-37.553639778852641, -8.504673893585277],
              [-37.552331314738353, -8.504822672927958],
              [-37.551328068835289, -8.505199370154742],
              [-37.550713906521537, -8.505585637067441],
              [-37.550019629002634, -8.506034812456562],
              [-37.549325349685091, -8.506186433656922],
              [-37.5493239296556, -8.506186270879653],
              [-37.548558613788941, -8.506111112737642],
              [-37.547250174855662, -8.505725800904941],
              [-37.547248451754626, -8.505726358484594],
              [-37.546327021674642, -8.506021448530987],
              [-37.545941710741317, -8.506408623758944],
              [-37.545323730805478, -8.507020898395695],
              [-37.544629450588616, -8.50731652623665],
              [-37.543916080462679, -8.507449098896757],
              [-37.543913682870141, -8.507449169043866],
              [-37.543321035037707, -8.507466258860688],
              [-37.541554801612733, -8.507233590757551],
              [-37.541553893297475, -8.507234034123314],
              [-37.541360271059602, -8.50732798090155],
              [-37.541358455328407, -8.507327585199846],
              [-37.53260557059059, -8.505448311490341],
              [-37.53260340592243, -8.505448660427305],
              [-37.513833432466868, -8.508513392578948],
              [-37.507592583530879, -8.527060478058559],
              [-37.508946797956298, -8.556066539539131],
              [-37.501382282766087, -8.561252616814784],
              [-37.503720650487594, -8.573472053492424],
              [-37.511586566266999, -8.583355962507596],
              [-37.493691827415887, -8.60483751064254],
              [-37.485295662364877, -8.608801947052143],
              [-37.48529400941095, -8.60880145782096],
              [-37.475877206467885, -8.606157406938735],
              [-37.475876275669521, -8.606158197442824],
              [-37.472302831210641, -8.609199621865002],
              [-37.472300595496051, -8.609199040003659],
              [-37.462708867220385, -8.606737202559827],
              [-37.462602068230808, -8.606575103358296],
              [-37.462533383408868, -8.606035356346581],
              [-37.462521927844648, -8.605471672079943],
              [-37.462476152352508, -8.604956695994701],
              [-37.462247279388407, -8.604536157717462],
              [-37.462190072613737, -8.603996362142368],
              [-37.462022202462606, -8.603779874641475],
              [-37.46145383902342, -8.603241058428068],
              [-37.460626054448824, -8.602733696003213],
              [-37.459866932213117, -8.602262586149379],
              [-37.459149767847464, -8.60191068592934],
              [-37.45805494847707, -8.601894456763716],
              [-37.45805436661567, -8.601894688788832],
              [-37.457608611348292, -8.602072804915963],
              [-37.457486538273088, -8.602131920951365],
              [-37.457101272305806, -8.602178649724863],
              [-37.456941040096865, -8.602284494533762],
              [-37.456939478873835, -8.602283656365614],
              [-37.455758513444607, -8.601634103930678],
              [-37.455476205462446, -8.601492007450133],
              [-37.454629330079456, -8.601489144908044],
              [-37.453500192579781, -8.601338478787738],
              [-37.452065883633566, -8.60058026486729],
              [-37.451024453415755, -8.599986151139944],
              [-37.450177601415135, -8.599420626860422],
              [-37.449559621479295, -8.599249914851669],
              [-37.448853910879222, -8.5991068399087],
              [-37.448743269086549, -8.598404017931045],
              [-37.448689834967865, -8.597645849876017],
              [-37.448441915262151, -8.597113690444417],
              [-37.448213020714263, -8.597031664179895],
              [-37.447827731364669, -8.596496664689255],
              [-37.44736998543641, -8.596033168495865],
              [-37.447064813989925, -8.595724155145604],
              [-37.446679525539651, -8.595035604509519],
              [-37.446298055610725, -8.594573085879176],
              [-37.44568768753669, -8.593504019494844],
              [-37.445153644025424, -8.592814511980123],
              [-37.444081712401044, -8.591661533452964],
              [-37.444079105266439, -8.591662301474003],
              [-37.443086057675373, -8.591961933096684],
              [-37.442922027629436, -8.5927239187738],
              [-37.442689360425618, -8.593338056805806],
              [-37.442456669839373, -8.593563158013296],
              [-37.442453689486115, -8.593563133731607],
              [-37.441842507525678, -8.593560293672567],
              [-37.44153351755773, -8.593252258784673],
              [-37.440926946421371, -8.591721603669043],
              [-37.440312785006995, -8.590950049205333],
              [-37.440309757888997, -8.590950467390087],
              [-37.439336221989038, -8.59107596418437],
              [-37.439336082594082, -8.591075754642304],
              [-37.438855588314709, -8.590410277012609],
              [-37.438241426900277, -8.589946804201588],
              [-37.437016898311185, -8.588414264106973],
              [-37.436711704381594, -8.587725712571512],
              [-37.436177659971008, -8.586955133872266],
              [-37.43594881038922, -8.586185511152394],
              [-37.435265939871158, -8.584274317805466],
              [-37.434659415499596, -8.581740441967781],
              [-37.434354244053054, -8.580744763859741],
              [-37.433896474742482, -8.579901752863577],
              [-37.432595647670951, -8.578829822138516],
              [-37.430142820534684, -8.576974903868688],
              [-37.429681232702706, -8.576286328950857],
              [-37.429608775224779, -8.575372723872078],
              [-37.429768986749309, -8.573681881513551],
              [-37.429780442313529, -8.571078319009189],
              [-37.430093226521137, -8.570238148072008],
              [-37.430497561713082, -8.569633508679431],
              [-37.430592951903179, -8.569118533493452],
              [-37.421491076252096, -8.568445231761984],
              [-37.411038828079427, -8.562144313509236],
              [-37.411036453869201, -8.562145011383109],
              [-37.404809434707602, -8.563986797752477],
              [-37.402833421824937, -8.569203424098703],
              [-37.396699397059479, -8.57301237582044],
              [-37.39669646257164, -8.57301237582044],
              [-37.395623646913748, -8.573008556399714],
              [-37.393624745385637, -8.572773979935164],
              [-37.392472676073112, -8.572533628024473],
              [-37.391625821374532, -8.572530788864754],
              [-37.390702670891528, -8.572373417399263],
              [-37.390168603997836, -8.571918535811733],
              [-37.389401890584736, -8.571219483792106],
              [-37.389398654823992, -8.571219763481281],
              [-37.388478740101732, -8.571296736455167],
              [-37.387631863819422, -8.571365352029318],
              [-37.387631722625883, -8.571365328646948],
              [-37.386865103641583, -8.570982950402765],
              [-37.386094526740976, -8.570599571213222],
              [-37.384404616080133, -8.570438429789647],
              [-37.384402240071267, -8.570438731961872],
              [-37.383172473830541, -8.570587184850638],
              [-37.382325621829921, -8.570655847189528],
              [-37.381326171965213, -8.57087806067392],
              [-37.38132505320857, -8.570877781884064],
              [-37.38040301968357, -8.570648232629765],
              [-37.380174122437779, -8.57057477420642],
              [-37.38017121223163, -8.570575100660335],
              [-37.378251522090011, -8.570793170068725],
              [-37.378018829705184, -8.57101822451142],
              [-37.376862963454982, -8.571555179128154],
              [-37.376016111454362, -8.571859373910911],
              [-37.374860267687154, -8.572080654798356],
              [-37.374859219976997, -8.57208042187392],
              [-37.374169783508648, -8.571924216829075],
              [-37.373322931508028, -8.571920421690038],
              [-37.373017760960863, -8.571764983766968],
              [-37.372403574365421, -8.570994405967042],
              [-37.372400594911483, -8.570995012110075],
              [-37.372018309297516, -8.571073544508408],
              [-37.372015957570341, -8.571073544508408],
              [-37.371865734815742, -8.571073544508408],
              [-37.371865478508994, -8.571073754949794],
              [-37.371095157915136, -8.571684842481432],
              [-37.370477177979353, -8.572369599777176],
              [-37.369935497425217, -8.573054331891854],
              [-37.369088620243588, -8.573122969949054],
              [-37.369085291852684, -8.573123227155179],
              [-37.36808917037888, -8.573200224410755],
              [-37.366624337543101, -8.57350159981894],
              [-37.365701187060097, -8.574184446055312],
              [-37.365159504707322, -8.574644102143566],
              [-37.364255398267971, -8.57495215951451],
              [-37.363694648489172, -8.575099008012785],
              [-37.363080463692427, -8.575548185200546],
              [-37.362386184374884, -8.575779967471476],
              [-37.362382855983981, -8.575780177912861],
              [-37.36146303389188, -8.575848583944946],
              [-37.360459788888079, -8.576305444040941],
              [-37.359765508671217, -8.576835670829496],
              [-37.359300126599521, -8.577529924966029],
              [-37.358685941802719, -8.577980058133107],
              [-37.358453250317211, -8.578213727181719],
              [-37.358449990274778, -8.578213865677299],
              [-37.356606946653244, -8.578278593482253],
              [-37.356606738010498, -8.578278687011732],
              [-37.355603678267073, -8.57866290436948],
              [-37.354989493470327, -8.578805025131771],
              [-37.353833650602439, -8.579260860899979],
              [-37.353063072802513, -8.579565100648836],
              [-37.352521391349114, -8.580023825039461],
              [-37.351212951516459, -8.580786742414205],
              [-37.35120997206252, -8.580786742414205],
              [-37.349980832649237, -8.580781947229013],
              [-37.348905084302146, -8.580696147409242],
              [-37.348903151659101, -8.580696473863156],
              [-37.347981885255763, -8.58084585755023],
              [-37.347054939633722, -8.581149144017729],
              [-37.345746498901747, -8.581912060493153],
              [-37.344743229616313, -8.582901990134701],
              [-37.343583568227075, -8.5835838803917],
              [-37.342854970780252, -8.5836782705357],
              [-37.342851571342919, -8.583678456695395],
              [-37.341584690980653, -8.583729797192348],
              [-37.340585240216626, -8.583571470646802],
              [-37.340583796804708, -8.583572006642726],
              [-37.339971033836093, -8.583794639211192],
              [-37.33919668338018, -8.584334411403916],
              [-37.339194145493366, -8.584334015702211],
              [-37.338197232616153, -8.58417613252243],
              [-37.337201600373533, -8.583936735691736],
              [-37.336278426508159, -8.58393291806965],
              [-37.33627756585696, -8.583933126712395],
              [-37.335351455705052, -8.584163676912112],
              [-37.334580879703765, -8.584612877482243],
              [-37.334578643989175, -8.584612411633429],
              [-37.333814143807672, -8.584455553680812],
              [-37.333812513336795, -8.584456088777415],
              [-37.333581428939738, -8.584535624819182],
              [-37.333578657229225, -8.584535624819182],
              [-37.332890969042921, -8.584532806343873],
              [-37.331120964760657, -8.584218066110793],
              [-37.330358024003544, -8.583445601533242],
              [-37.329514965343321, -8.582682637393759],
              [-37.328584223682924, -8.582248711808575],
              [-37.328581824291689, -8.582249385400758],
              [-37.313275819023772, -8.586534547931024],
              [-37.313272862052884, -8.586534618078133],
              [-37.302243727565951, -8.586771057937938],
              [-37.279958285346538, -8.573841114575714],
              [-37.279954769896676, -8.57384130073541],
              [-37.259900617619337, -8.57489209019775],
              [-37.252893033686519, -8.572579402617293],
              [-37.252891799816666, -8.572580380180341],
              [-37.248780773014232, -8.575875288413783],
              [-37.24735407682914, -8.578790681844453],
              [-37.244733378642479, -8.58701993002569],
              [-37.245225466981651, -8.588734661075705],
              [-37.242349189563413, -8.5959139354797],
              [-37.232633174981004, -8.601760949708023],
              [-37.221993986533221, -8.608597800048415],
              [-37.214944422247243, -8.60949238356659],
              [-37.214944212705234, -8.609492126360522],
              [-37.210107382649539, -8.603502316878348],
              [-37.212049098986711, -8.59726149222405],
              [-37.212186422765171, -8.586129025633966],
              [-37.212186422765171, -8.586128281894673],
              [-37.215311480540379, -8.586575130737629],
              [-37.215314460893637, -8.586575549821703],
              [-37.221772727229506, -8.5805912109156],
              [-37.222043578748128, -8.579856884787375],
              [-37.222322044826456, -8.578523670431025],
              [-37.222787427797527, -8.57722377595644],
              [-37.222791246318934, -8.576383605019316],
              [-37.222875181844131, -8.575235375811872],
              [-37.222112265368708, -8.573477734509765],
              [-37.222039784508411, -8.572473534426024],
              [-37.221814683300977, -8.571324372621632],
              [-37.221589629757545, -8.569949158127088],
              [-37.221440872897915, -8.569108032109966],
              [-37.221215771690481, -8.567876821558002],
              [-37.220380350972391, -8.566038108172108],
              [-37.219541158497634, -8.564044936224775],
              [-37.218320449329212, -8.561896301173306],
              [-37.216332980882328, -8.55967232271945],
              [-37.214414196358007, -8.55881403763766],
              [-37.211648537350072, -8.558114054819725],
              [-37.210572787204342, -8.557575213425309],
              [-37.210572089330412, -8.557576913143976],
              [-37.210263821518083, -8.558342903502137],
              [-37.209951013928105, -8.559715256353968],
              [-37.20932921457154, -8.560860598737577],
              [-37.208554818250207, -8.561932528563318],
              [-37.20770412344649, -8.562769859441403],
              [-37.205773908539015, -8.564361563235536],
              [-37.205564103900258, -8.565179823090318],
              [-37.204812572841945, -8.567890163000584],
              [-37.204778276296395, -8.575082777947785],
              [-37.203992447793212, -8.578827888596152],
              [-37.196164693845503, -8.592639004786179],
              [-37.196161691009195, -8.592638003840761],
              [-37.195489483752624, -8.592412993464848],
              [-37.194337459406199, -8.592172666735109],
              [-37.193494378262926, -8.591941883611014],
              [-37.192346196719541, -8.590968209215418],
              [-37.192269896438518, -8.590254838190162],
              [-37.19196854351344, -8.588797594733137],
              [-37.191751079348762, -8.586039548486326],
              [-37.191461159504854, -8.583857549486254],
              [-37.190999617538296, -8.58236503371927],
              [-37.189782703508911, -8.579909341342955],
              [-37.189244840576862, -8.579364795548827],
              [-37.18710477426589, -8.577211412076963],
              [-37.186414337751444, -8.576828962786351],
              [-37.185647577573604, -8.576210028669834],
              [-37.184957117676788, -8.575448065475769],
              [-37.184335319219542, -8.574853905883003],
              [-37.184121696958698, -8.5733728438816],
              [-37.183747864072643, -8.571147934629437],
              [-37.182920078598727, -8.568482391748944],
              [-37.184918981925421, -8.566212638702268],
              [-37.185636146291074, -8.558849311746314],
              [-37.189244841476182, -8.556115106526875],
              [-37.189504237430583, -8.552279448537661],
              [-37.188226297205802, -8.551115969526336],
              [-37.188149999622738, -8.550961464200157],
              [-37.187921150041007, -8.550734498698091],
              [-37.186776738455649, -8.548586795344306],
              [-37.186242671562013, -8.547589254740274],
              [-37.185250857840799, -8.545975690225873],
              [-37.18456421556607, -8.544896075693316],
              [-37.18387755080829, -8.543672503083485],
              [-37.182805620982606, -8.542899106808306],
              [-37.18219530057263, -8.542137097748821],
              [-37.182736982026029, -8.540990777802108],
              [-37.183049766233694, -8.539844456956075],
              [-37.18313370265821, -8.538615204228222],
              [-37.183377825426248, -8.535940114144921],
              [-37.183919530262074, -8.535020780384684],
              [-37.184613832961986, -8.534264546771396],
              [-37.18553696096194, -8.534033717781824],
              [-37.186463930865671, -8.533504422690896],
              [-37.187543497734168, -8.532822530635258],
              [-37.188085180086944, -8.532436310487299],
              [-37.189008330569948, -8.531834535435394],
              [-37.188935850608971, -8.530758787088303],
              [-37.189172360615885, -8.529765087488727],
              [-37.18971406455239, -8.52841655408065],
              [-37.189721680011473, -8.527624997795385],
              [-37.190263383048659, -8.527012769023997],
              [-37.190648625633514, -8.526707574195143],
              [-37.191110236847919, -8.526483450550757],
              [-37.191493336348344, -8.52640379939578],
              [-37.191495501915824, -8.526403356929336],
              [-37.192568619745941, -8.526562264436905],
              [-37.192571227779922, -8.526562661037929],
              [-37.193803392512507, -8.525880768082914],
              [-37.194268821348999, -8.525042459641725],
              [-37.194120019523211, -8.523893275354339],
              [-37.193971241079851, -8.523512783887838],
              [-37.193898760219554, -8.522518105825895],
              [-37.193955990376594, -8.522062222393629],
              [-37.192475883455188, -8.500494854653766],
              [-37.192903128876253, -8.50023638859841],
              [-37.193994150409651, -8.498948949734199],
              [-37.194844821830998, -8.497958091092983],
              [-37.195695493252344, -8.497347724817587],
              [-37.196542347051604, -8.496889955507015],
              [-37.197621914819479, -8.496127038132272],
              [-37.200475303592327, -8.494150977585491],
              [-37.201325999295364, -8.493313671888416],
              [-37.202485659785339, -8.492098712085863],
              [-37.203797919038664, -8.491110671020579],
              [-37.204412079553776, -8.490805499574094],
              [-37.205258933353036, -8.490421188686867],
              [-37.205953236052949, -8.489818458554964],
              [-37.206727584710222, -8.488900079874725],
              [-37.206807701714013, -8.488592999167508],
              [-37.206037124813406, -8.488435629500657],
              [-37.203660572777153, -8.487429496773814],
              [-37.203046409564081, -8.487200623809713],
              [-37.202046959699373, -8.48696122697902],
              [-37.200818613488195, -8.48672948967419],
              [-37.200814934361688, -8.486729700115518],
              [-37.199586494620974, -8.486796241853028],
              [-37.199582793011473, -8.486796499059153],
              [-37.198587045655586, -8.486873519697156],
              [-37.198585997945429, -8.486873519697156],
              [-37.196820835613039, -8.486864927574288],
              [-37.195973959330729, -8.486554028345665],
              [-37.195130925851515, -8.486395726981186],
              [-37.193749980876873, -8.485855931406036],
              [-37.192636117462825, -8.485406752419635],
              [-37.191754921252596, -8.484925142980899],
              [-37.190988163772715, -8.484233727104765],
              [-37.188459012973055, -8.482387424340118],
              [-37.187234508665597, -8.48145949935639],
              [-37.18623505790157, -8.480920635478924],
              [-37.185315700758963, -8.480383727626872],
              [-37.184472667279749, -8.479990802133784],
              [-37.183549492514999, -8.479687561531705],
              [-37.187276278577372, -8.467878793971124],
              [-37.1872764890187, -8.467878165344985],
              [-37.214193171777424, -8.470981035046691],
              [-37.214196780756765, -8.470981454130765],
              [-37.21442947044369, -8.469261017781719],
              [-37.214513406868207, -8.468113767036698],
              [-37.214673617493418, -8.467047539812086],
              [-37.215062702881369, -8.466054772809457],
              [-37.215600540632408, -8.465361447672649],
              [-37.215756980400272, -8.46513541566685],
              [-37.215837097404062, -8.464521230870048],
              [-37.215993465226177, -8.464142647764959],
              [-37.216222013535059, -8.46399025944288],
              [-37.216222362472024, -8.463990026518445],
              [-37.216528093296802, -8.46399098069918],
              [-37.216531375822285, -8.46399098069918],
              [-37.216916618407197, -8.463766882235802],
              [-37.217149355758124, -8.463460755709264],
              [-37.217305725378878, -8.46307260291826],
              [-37.217608825686739, -8.462921052764329],
              [-37.217610920207733, -8.462920005953492],
              [-37.217767335693964, -8.463002055600327],
              [-37.218919383422758, -8.463079309162765],
              [-37.219992060585071, -8.463238098859108],
              [-37.219995109286799, -8.463238564707922],
              [-37.220922080089906, -8.462175201824039],
              [-37.221238683718241, -8.460413765382896],
              [-37.221253956904548, -8.457041582003342],
              [-37.221410349907671, -8.456047859021453],
              [-37.221650677536672, -8.454602072027967],
              [-37.221883392404607, -8.453455752081254],
              [-37.222501348958019, -8.452761448482022],
              [-37.222962935890735, -8.452538327581692],
              [-37.224122574796922, -8.451241319031624],
              [-37.224359130669313, -8.450627134234821],
              [-37.224900813022032, -8.44956286303568],
              [-37.22528991909445, -8.448796080374791],
              [-37.22590787564792, -8.447650739789765],
              [-37.226144409037204, -8.446657925123077],
              [-37.226312233322858, -8.443747351159288],
              [-37.226155841219054, -8.44362718554612],
              [-37.226155841219054, -8.443490770982237],
              [-37.226251208926101, -8.44318371365739],
              [-37.22649023343746, -8.443003503009209],
              [-37.226491536555102, -8.443002524546841],
              [-37.22698362579365, -8.44316368935273],
              [-37.227378808585115, -8.443290023415784],
              [-37.227380369808202, -8.443290536928714],
              [-37.227609241872983, -8.443212327386959],
              [-37.22765881160484, -8.443030184095676],
              [-37.22763594724114, -8.442870905168093],
              [-37.227899141032481, -8.442724035985407],
              [-37.228231016947802, -8.442680146371629],
              [-37.228238676473723, -8.442381681169593],
              [-37.228086057025848, -8.442073622],
              [-37.226705135433633, -8.441840907132132],
              [-37.226399987369462, -8.441224858940018],
              [-37.226560199793369, -8.440150040491915],
              [-37.226636497376433, -8.440077584812627],
              [-37.226636496477113, -8.439996536111209],
              [-37.226716614380223, -8.438775849425838],
              [-37.226258845968914, -8.437752510869643],
              [-37.225881193662133, -8.436701582012347],
              [-37.225194528005034, -8.435939595435912],
              [-37.223817402451118, -8.43408854245223],
              [-37.223512254387003, -8.433399991816145],
              [-37.222829430633681, -8.432053366769424],
              [-37.222756974954393, -8.431028165717237],
              [-37.222531872847583, -8.429878980530475],
              [-37.222074104436331, -8.429108427911558],
              [-37.221612562469716, -8.428572427475501],
              [-37.221307391922551, -8.428037428884181],
              [-37.220933535654126, -8.425740037872401],
              [-37.22094114841525, -8.424664266142941],
              [-37.220944990319026, -8.423361576575473],
              [-37.220719913393225, -8.422294395170127],
              [-37.220571134050545, -8.42144372374878],
              [-37.220040907261989, -8.419605988825253],
              [-37.220044703300346, -8.418918391470527],
              [-37.220124819404873, -8.418304206673724],
              [-37.2203575117897, -8.417925624467955],
              [-37.221433237653741, -8.417242753949893],
              [-37.221997828436997, -8.416395923533003],
              [-37.222596740946813, -8.41517805442362],
              [-37.222756974954393, -8.413877229150785],
              [-37.223764037580224, -8.412046176190017],
              [-37.223691557619247, -8.411277531033193],
              [-37.223081191343852, -8.410587047754007],
              [-37.223313882829359, -8.409521821474812],
              [-37.223859405287271, -8.40852905357292],
              [-37.223939499808012, -8.40738177854621],
              [-37.223634328361527, -8.4066121567256],
              [-37.223485549918166, -8.406149589531879],
              [-37.223485549918166, -8.405689004443957],
              [-37.223489366640933, -8.404928928027573],
              [-37.225030524039425, -8.404321426092906],
              [-37.227338412837412, -8.403644329222345],
              [-37.228570530805314, -8.403342952914898],
              [-37.229493683986277, -8.40319321939154],
              [-37.231416331998105, -8.402975801991602],
              [-37.232034287652198, -8.402744042203722],
              [-37.232572151483566, -8.402438846475548],
              [-37.233422822904913, -8.401221954929213],
              [-37.233811930775914, -8.399993655482774],
              [-37.234048439883566, -8.398847335536061],
              [-37.234357428952137, -8.398242697042804],
              [-37.234898598691359, -8.39785680155012],
              [-37.234899109506273, -8.397856452613155],
              [-37.236663247510819, -8.397945069109596],
              [-37.23666531954882, -8.397945161739756],
              [-37.238206451766303, -8.397644739612986],
              [-37.239129604047946, -8.397495006988947],
              [-37.240281650877421, -8.397274702765287],
              [-37.241365036267382, -8.396357277366405],
              [-37.242292007070432, -8.395285346641401],
              [-37.244760110090965, -8.393234944537028],
              [-37.245606962091586, -8.392551143220658],
              [-37.246457678479032, -8.391940799428312],
              [-37.247613499763133, -8.391104447911914],
              [-37.248540468767601, -8.390340575457117],
              [-37.249230207408118, -8.390117593951743],
              [-37.24923092956368, -8.390117383510415],
              [-37.249925207981903, -8.390120247851087],
              [-37.251306128674855, -8.390506491381416],
              [-37.252072887953375, -8.390889870571016],
              [-37.253300419378832, -8.39104903348607],
              [-37.25330123506393, -8.391049127015549],
              [-37.255296665208903, -8.390832058552633],
              [-37.255300110511655, -8.390831686233298],
              [-37.255914272825407, -8.391603218213902],
              [-37.25667721358252, -8.392980320385448],
              [-37.257058684410708, -8.393668871920852],
              [-37.257669026404415, -8.394747507991042],
              [-37.258431968060847, -8.395663954028237],
              [-37.259042333436923, -8.396897073840819],
              [-37.259420008226755, -8.39827226675169],
              [-37.259725158988829, -8.398581255820261],
              [-37.259801434088843, -8.399042843652239],
              [-37.260491311224996, -8.399199119743514],
              [-37.260491916468709, -8.3991992357561],
              [-37.261182352983155, -8.399121028012985],
              [-37.261956772686858, -8.398663259601676],
              [-37.262956222551566, -8.397826908085278],
              [-37.263650501869108, -8.39729665791441],
              [-37.265115311322518, -8.396534694720344],
              [-37.265271727708011, -8.396382074373207],
              [-37.265733291258357, -8.39615797501051],
              [-37.267118030472659, -8.395630590079634],
              [-37.268045024658136, -8.394865740061789],
              [-37.268739278794612, -8.394254442088766],
              [-37.269124567244944, -8.393875812218937],
              [-37.271207380915826, -8.392664647555421],
              [-37.272054258097512, -8.391972278397873],
              [-37.273208028028023, -8.391525801874195],
              [-37.273210100066024, -8.391525009571467],
              [-37.274205731409324, -8.391909344740441],
              [-37.275281480655792, -8.39268276259935],
              [-37.275277639651279, -8.392836081719736],
              [-37.275277639651279, -8.392836314644171],
              [-37.276433505901537, -8.39268753620081],
              [-37.276895093733572, -8.392382387237319],
              [-37.277436773388331, -8.391697630840952],
              [-37.277593189773825, -8.39123699898829],
              [-37.277749582777005, -8.390315755967322],
              [-37.277909793402216, -8.389249529642029],
              [-37.278527797619745, -8.388555251223806],
              [-37.279607342005193, -8.387873405033588],
              [-37.280530515870566, -8.387651168166826],
              [-37.281294201266348, -8.387573285978306],
              [-37.281297251766716, -8.387572983806137],
              [-37.28166666538425, -8.387656677413702],
              [-37.28166666538425, -8.38749999303019],
              [-37.282500000574544, -8.38749999213087],
              [-37.282499998775904, -8.386666659638593],
              [-37.283333331268182, -8.386666658739273],
              [-37.283333330368862, -8.384166658564368],
              [-37.284166665559098, -8.384166658564368],
              [-37.284166667357795, -8.382499992680494],
              [-37.283333331268182, -8.382499992680494],
              [-37.283333331268182, -8.380833325897243],
              [-37.282500000574544, -8.380833325897243],
              [-37.282499999675224, -8.379166659113991],
              [-37.283333331268182, -8.379166659113991],
              [-37.283333331268182, -8.376666658939087],
              [-37.284166666458475, -8.376666659838406],
              [-37.284166665559098, -8.375833325547489],
              [-37.285000000749392, -8.375833325547489],
              [-37.285000000749392, -8.374999993055212],
              [-37.284999999850072, -8.373333325372641],
              [-37.284166667357795, -8.373333324473322],
              [-37.284166665559098, -8.372499992880307],
              [-37.28166666808221, -8.372499992880307],
              [-37.281666664484931, -8.369166658414485],
              [-37.282499999675224, -8.369166657515166],
              [-37.282499998775904, -8.367499992530611],
              [-37.28166666538425, -8.367499992530611],
              [-37.28166666628357, -8.366666659138957],
              [-37.279166667008042, -8.366666659138957],
              [-37.279166665209402, -8.36583332484804],
              [-37.277499999325471, -8.36583332574736],
              [-37.27750000202343, -8.364999992355706],
              [-37.27583333254222, -8.364999994154346],
              [-37.27583333344154, -8.364166658964109],
              [-37.273333333266692, -8.364166658064789],
              [-37.273333331468052, -8.363333324673135],
              [-37.272499999875038, -8.363333325572455],
              [-37.272499999875038, -8.361666658789204],
              [-37.269166665409216, -8.361666658789204],
              [-37.269166667207855, -8.360833325397607],
              [-37.268333334715578, -8.360833327196247],
              [-37.268333333816258, -8.35999999290533],
              [-37.267499998625965, -8.35999999290533],
              [-37.267500000424661, -8.358333326122079],
              [-37.265833332742091, -8.358333326122079],
              [-37.265833331842771, -8.357499992730425],
              [-37.264999999350437, -8.357499992730425],
              [-37.265000002048396, -8.356666658439508],
              [-37.263333332567186, -8.356666660238147],
              [-37.263333332567186, -8.354999992555577],
              [-37.262500000974228, -8.354999993454896],
              [-37.262499998276269, -8.354166659163923],
              [-37.263333332567186, -8.354166657365283],
              [-37.263333332567186, -8.353333325772326],
              [-37.269166665409216, -8.353333325772326],
              [-37.269166665409216, -8.352499992380672],
              [-37.26999999970019, -8.352499992380672],
              [-37.26999999970019, -8.349166658814227],
              [-37.271666665584121, -8.349166659713546],
              [-37.27166666738276, -8.348333325422573],
              [-37.275000000949262, -8.348333327221212],
              [-37.274999998251303, -8.34499999275539],
              [-37.274166666658289, -8.344999991856071],
              [-37.274166665758969, -8.343333325972196],
              [-37.274999998251303, -8.343333325972196],
              [-37.274999998251303, -8.342499992580542],
              [-37.27583333344154, -8.342499992580542],
              [-37.27583333254222, -8.341666659188945],
              [-37.276666666833137, -8.341666659188945],
              [-37.276666665933817, -8.339999992405694],
              [-37.277499998426151, -8.339999991506374],
              [-37.277499998426151, -8.338333325622443],
              [-37.278333333616388, -8.338333325622443],
              [-37.278333332717068, -8.33916665901404],
              [-37.28166666538425, -8.33916665991336],
              [-37.28166666628357, -8.338333325622443],
              [-37.282499998775904, -8.338333325622443],
              [-37.282499998775904, -8.336666657939872],
              [-37.283333330368862, -8.336666658839192],
              [-37.284166665559098, -8.336666658839192],
              [-37.284166667357795, -8.334999992955261],
              [-37.284999998950752, -8.334999992955261],
              [-37.285000001648712, -8.334166659563664],
              [-37.284166667357795, -8.334166658664344],
              [-37.284166667357795, -8.33333332707133],
              [-37.283333331268182, -8.33333332617201],
              [-37.283333331268182, -8.330833325097842],
              [-37.284166668257114, -8.330833325997162],
              [-37.284166665559098, -8.328333325822257],
              [-37.284999998950752, -8.328333325822257],
              [-37.284999998051433, -8.325833325647409],
              [-37.285833332342349, -8.325833325647409],
              [-37.28583333054371, -8.324999991356492],
              [-37.286666665734003, -8.324999993155132],
              [-37.286666667532643, -8.324166658864158],
              [-37.28750000002492, -8.324166658864158],
              [-37.287499998226281, -8.319166658514405],
              [-37.286666667532643, -8.319166658514405],
              [-37.286666667532643, -8.31749999263053],
              [-37.285833333241669, -8.31749999263053],
              [-37.285833332342349, -8.316666659238876],
              [-37.285000000749392, -8.316666659238876],
              [-37.284999999850072, -8.315833325847279],
              [-37.284166665559098, -8.315833325847279],
              [-37.284166667357795, -8.314999992455625],
              [-37.283333333966141, -8.314999992455625],
              [-37.283333333066821, -8.309999993005249],
              [-37.284166666458475, -8.309999993005249],
              [-37.284166667357795, -8.309166658714275],
              [-37.284999998950752, -8.309166658714275],
              [-37.285000000749392, -8.308333332517236],
              [-37.283333333066821, -8.308333326221998],
              [-37.283333333066821, -8.307499992830344],
              [-37.282499998775904, -8.307499992830344],
              [-37.282499998775904, -8.306666658539427],
              [-37.279166667008042, -8.306666658539427],
              [-37.279166665209402, -8.304999992655496],
              [-37.275000000049943, -8.304999993554816],
              [-37.274999998251303, -8.304166658364522],
              [-37.272499998975718, -8.304166657465203],
              [-37.272500000774357, -8.303333326771565],
              [-37.26999999880087, -8.303333325872245],
              [-37.26999999970019, -8.302499992480648],
              [-37.269166667207855, -8.302499992480648],
              [-37.269166666308536, -8.301666659088994],
              [-37.268333332916939, -8.301666659088994],
              [-37.268333334715578, -8.299999993205063],
              [-37.267499998625965, -8.299999993205063],
              [-37.267499998625965, -8.299166658914146],
              [-37.26583333364141, -8.299166658914146],
              [-37.26583333364141, -8.298333325522492],
              [-37.264999998451117, -8.298333325522492],
              [-37.265000000249756, -8.297499993030215],
              [-37.263333333466505, -8.297499993929534],
              [-37.263333330768546, -8.294999992855367],
              [-37.259166666508406, -8.294999992855367],
              [-37.259166666508406, -8.295833324448324],
              [-37.250833333491528, -8.295833325347644],
              [-37.250833331692888, -8.294999992855367],
              [-37.250000000999194, -8.29499999195599],
              [-37.250000000099874, -8.295833325347644],
              [-37.246666666533372, -8.295833325347644],
              [-37.245833332242455, -8.295833325347644],
              [-37.245833334041095, -8.294166658564393],
              [-37.245000000649441, -8.294166658564393],
              [-37.244999998850801, -8.292499992680462],
              [-37.244166665459204, -8.292499992680462],
              [-37.244166667257844, -8.290833325897211],
              [-37.244999999750121, -8.290833325897211],
              [-37.245000000649441, -8.28916665911396],
              [-37.245833331343135, -8.28916665911396],
              [-37.245833331343135, -8.288333325722363],
              [-37.246666666533372, -8.288333324823043],
              [-37.246666667432692, -8.287499993230085],
              [-37.247499999925026, -8.287499993230085],
              [-37.247499999925026, -8.286666658939112],
              [-37.250000000099874, -8.286666658939112],
              [-37.250000000099874, -8.285833325547515],
              [-37.252500000274722, -8.285833325547515],
              [-37.252499997576763, -8.28499999305518],
              [-37.253333333666376, -8.2849999939545],
              [-37.253333333666376, -8.282499992880332],
              [-37.254166665259334, -8.282499992880332],
              [-37.254166667057973, -8.280833326097081],
              [-37.251666665983805, -8.280833325197761],
              [-37.251666665983805, -8.279999992705427],
              [-37.250833333491528, -8.279999992705427],
              [-37.250833332592208, -8.27916665841451],
              [-37.248333332417303, -8.27916665841451],
              [-37.248333331517983, -8.278333325922233],
              [-37.247499999925026, -8.278333325922233],
              [-37.247500000824346, -8.277499992530579],
              [-37.244166667257844, -8.277499992530579],
              [-37.244166666358524, -8.276666659138982],
              [-37.243333332966927, -8.276666659138982],
              [-37.243333332966927, -8.275833325747328],
              [-37.240833331892702, -8.275833325747328],
              [-37.240833331892702, -8.274999992355731],
              [-37.239999999400425, -8.274999992355731],
              [-37.240000000299744, -8.274166658064757],
              [-37.239166666908091, -8.274166658964077],
              [-37.239166666908091, -8.270833325397575],
              [-37.2374999983262, -8.270833327196272],
              [-37.23749999742688, -8.269999992905298],
              [-37.236666666733242, -8.269999992905298],
              [-37.236666666733242, -8.269166658614381],
              [-37.234166666558394, -8.269166658614381],
              [-37.234166667457714, -8.268333326122047],
              [-37.233333331368101, -8.268333325222727],
              [-37.233333330468781, -8.26749999183113],
              [-37.232499999775143, -8.26749999273045],
              [-37.232499998875824, -8.266666658439476],
              [-37.231666666383489, -8.266666658439476],
              [-37.23166666458485, -8.265833325047879],
              [-37.230833333891212, -8.265833325947199],
              [-37.230833332991892, -8.264999991656225],
              [-37.23166666548417, -8.264999992555545],
              [-37.23166666458485, -8.260833325597446],
              [-37.229999998700919, -8.260833324698126],
              [-37.229999998700919, -8.259999993105168],
              [-37.228333332816987, -8.259999993105168],
              [-37.228333331917668, -8.256666658639347],
              [-37.22750000032471, -8.256666658639347],
              [-37.227499998526071, -8.254999992755415],
              [-37.226666666033793, -8.254999992755415],
              [-37.226666667832433, -8.253333325972164],
              [-37.225833332642139, -8.253333325972164],
              [-37.225833332642139, -8.250833325797316],
              [-37.224166666758208, -8.250833325797316],
              [-37.224166667657528, -8.249999992405662],
              [-37.223333331567972, -8.249999994204302],
              [-37.223333331567972, -8.249166659014065],
              [-37.222499999974957, -8.249166659913385],
              [-37.222499999075637, -8.248333325622411],
              [-37.22166666568404, -8.248333326521731],
              [-37.22166666568404, -8.244166658664312],
              [-37.220833334091026, -8.244166657764993],
              [-37.220833330493747, -8.242499992780381],
              [-37.219166666408512, -8.242499991881061],
              [-37.219166667307832, -8.241666658489464],
              [-37.218333333016858, -8.241666658489464],
              [-37.218333331218219, -8.24083332599713],
              [-37.217499999625261, -8.24083332509781],
              [-37.217500000524581, -8.239166659213879],
              [-37.21583333284201, -8.239166660113199],
              [-37.21583333194269, -8.238333325822282],
              [-37.215000000349676, -8.238333325822282],
              [-37.214999999450356, -8.237499992430685],
              [-37.214166666958079, -8.237499992430685],
              [-37.214166667857398, -8.235833325647434],
              [-37.211666665883854, -8.235833324748114],
              [-37.211666666783231, -8.234166658864183],
              [-37.210833333391577, -8.234166658864183],
              [-37.210833331592937, -8.232499992980252],
              [-37.21, -8.232499992980252],
              [-37.21, -8.229999991906027],
              [-37.209166667507645, -8.229999992805404],
              [-37.209166666608326, -8.22916665851443],
              [-37.205833331243184, -8.22916665851443],
              [-37.205833333941143, -8.225833325847248],
              [-37.204999998750907, -8.225833325847248],
              [-37.205000000549546, -8.22499999245565],
              [-37.205833333941143, -8.22499999245565],
              [-37.205833331243184, -8.222499993180122],
              [-37.206666666433478, -8.222499994079442],
              [-37.206666666433478, -8.218333327121286],
              [-37.204166666258573, -8.218333326221966],
              [-37.204166665359253, -8.217499992830369],
              [-37.202499999475378, -8.217499992830369],
              [-37.202499998576002, -8.216666658539395],
              [-37.201666666083725, -8.216666658539395],
              [-37.201666666983044, -8.215833326047118],
              [-37.200000001099113, -8.215833326946438],
              [-37.199999999300474, -8.21333332587227],
              [-37.199166666808196, -8.21333332587227],
              [-37.199166666808196, -8.212499991581296],
              [-37.198333330718583, -8.212499991581296],
              [-37.198333331617903, -8.21333332587227],
              [-37.194166666458443, -8.21333332587227],
              [-37.194166664659804, -8.214166658364547],
              [-37.190833331992621, -8.214166658364547],
              [-37.190833333791261, -8.212499992480616],
              [-37.189999999500344, -8.212499992480616],
              [-37.190000000399664, -8.210833326596685],
              [-37.18916666700801, -8.210833325697365],
              [-37.18916666520937, -8.207499993030183],
              [-37.188333332717093, -8.207499993030183],
              [-37.188333331817773, -8.201666660188152],
              [-37.18916666520937, -8.201666658389513],
              [-37.18916666700801, -8.199166659113985],
              [-37.188333331817773, -8.199166659113985],
              [-37.188333332717093, -8.196666658939137],
              [-37.186666666833162, -8.196666658939137],
              [-37.186666666833162, -8.195833326446802],
              [-37.185833332542245, -8.195833325547483],
              [-37.185833332542245, -8.192499991980981],
              [-37.18333333326666, -8.1924999928803],
              [-37.18333333326666, -8.191666658589384],
              [-37.182499998975743, -8.191666658589384],
              [-37.182499998975743, -8.190833326097049],
              [-37.180833333091812, -8.190833326097049],
              [-37.180833331293172, -8.189999992705452],
              [-37.178333333816283, -8.189999992705452],
              [-37.178333332017587, -8.189166657515159],
              [-37.17749999952531, -8.189166657515159],
              [-37.177500001323949, -8.188333325922201],
              [-37.176666665234393, -8.188333325922201],
              [-37.176666665234393, -8.187499991631285],
              [-37.17749999862599, -8.187499992530604],
              [-37.177500000424629, -8.18666665913895],
              [-37.176666667033032, -8.18666666003827],
              [-37.176666665234393, -8.184166658964102],
              [-37.17749999952531, -8.184166658964102],
              [-37.177500000424629, -8.181666658789197],
              [-37.178333332916964, -8.181666657889878],
              [-37.178333333816283, -8.180833328095559],
              [-37.17749999952531, -8.1808333253976],
              [-37.177500000424629, -8.179999992905323],
              [-37.176666667033032, -8.179999992905323],
              [-37.176666667033032, -8.179166657715029],
              [-37.174999998451142, -8.179166658614349],
              [-37.174999999350462, -8.178333325222752],
              [-37.174166666858127, -8.178333326122072],
              [-37.174166668656767, -8.175833326846487],
              [-37.17166666578396, -8.175833325947167],
              [-37.171666666683279, -8.17499999165625],
              [-37.170833333291682, -8.17499999255557],
              [-37.170833331492986, -8.174166659163916],
              [-37.166666665434207, -8.174166657365276],
              [-37.166666666333526, -8.173333325772319],
              [-37.165833332941929, -8.173333324872999],
              [-37.165833332941929, -8.172499992380722],
              [-37.164166667057998, -8.172499991481402],
              [-37.164166667057998, -8.171666658989068],
              [-37.162499999375427, -8.171666658989068],
              [-37.162499999375427, -8.170833325597471],
              [-37.16166666688315, -8.170833325597471],
              [-37.16166666688315, -8.169999992205817],
              [-37.160518042602085, -8.169999992825604],
              [-37.160225323, -8.170400441999957],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '256',
        OBJECTID: 185.0,
        Nome_area: 'Serra das Pias',
        COD_area: 'CA194',
        Import_bio: 'Extremamente Alta',
        Prior_acao: 'Extremamente Alta',
        Acao_princ: 'Sem ações recomendadas pela Oficina de Seleção',
        Area_ha: 34358.5126,
        Shape_Leng: 1.5,
        Shape_Area: 0.02827,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.606330172787786, -9.34083332559743],
              [-36.605833331542954, -9.34083332559743],
              [-36.605833331613454, -9.341094640205878],
              [-36.606330172787786, -9.34083332559743],
            ],
          ],
          [
            [
              [-36.607270024999899, -9.33872647299995],
              [-36.608302075, -9.337846357999979],
              [-36.607599985, -9.334731895999935],
              [-36.607719443, -9.333464325999962],
              [-36.608570629, -9.332858931999979],
              [-36.608573116999899, -9.332859312999965],
              [-36.610472165, -9.333226702999969],
              [-36.610473958999897, -9.33322691699993],
              [-36.611793379999902, -9.333981482999945],
              [-36.612845114, -9.333928731999949],
              [-36.614747775999902, -9.331026056999978],
              [-36.615073337999902, -9.330291444999935],
              [-36.615624346999901, -9.329048123999941],
              [-36.615653874, -9.32714835299994],
              [-36.613723427, -9.324847726999964],
              [-36.613824011, -9.323805291999973],
              [-36.613826711999899, -9.323805197999945],
              [-36.614278673999898, -9.323751940999946],
              [-36.614996229, -9.323667386999945],
              [-36.617937856, -9.325968085999929],
              [-36.619322605, -9.326568107999954],
              [-36.6196287409999, -9.328802350999981],
              [-36.619631179, -9.328800369999955],
              [-36.622356563, -9.326586095999977],
              [-36.62235929, -9.326586168999935],
              [-36.623431584999899, -9.32659390999993],
              [-36.624953785999899, -9.327751238999946],
              [-36.624535320999897, -9.331665802999954],
              [-36.624855448999902, -9.331831900999939],
              [-36.625796981, -9.332320678999961],
              [-36.627879688999897, -9.332335035999966],
              [-36.628517401, -9.331750679999979],
              [-36.628344001, -9.329218515999967],
              [-36.628729157, -9.328278521999952],
              [-36.628731883999897, -9.328278593999929],
              [-36.631888776, -9.328298921999936],
              [-36.633726313, -9.327722959999953],
              [-36.634616576, -9.326903547999951],
              [-36.635483656, -9.324294024999972],
              [-36.635697261, -9.323651169999957],
              [-36.635896505999902, -9.323051531999969],
              [-36.634844266, -9.321255253999935],
              [-36.635454234, -9.320784797999975],
              [-36.635456029, -9.320784910999976],
              [-36.639271353, -9.32104612699993],
              [-36.641807110999899, -9.319768151999938],
              [-36.641884892999897, -9.31764950699994],
              [-36.642590756999901, -9.316064309999932],
              [-36.644686394, -9.314371535999948],
              [-36.644689118, -9.314371666999941],
              [-36.646078216, -9.314439037999938],
              [-36.648789211999897, -9.315574353999979],
              [-36.6487908739999, -9.315574527999967],
              [-36.6497364169999, -9.315406008999968],
              [-36.651600045999899, -9.313570979999954],
              [-36.65161156100001, -9.313559635999983],
              [-36.651652563, -9.313519246999931],
              [-36.651694662999901, -9.313477775999962],
              [-36.65217975, -9.312999949999943],
              [-36.652239296999902, -9.312941295999964],
              [-36.652263628, -9.31291732899995],
              [-36.652275457, -9.312905676999948],
              [-36.652280270999903, -9.312900935999943],
              [-36.652285586999902, -9.312895699999951],
              [-36.652290364999899, -9.312890993999932],
              [-36.652300399999902, -9.312881109999989],
              [-36.652303774, -9.312877785999945],
              [-36.652341363999902, -9.312840758999936],
              [-36.652357337, -9.312825025999935],
              [-36.652366185999902, -9.312816310999946],
              [-36.652379197, -9.312803493999976],
              [-36.652410137999901, -9.312773017999969],
              [-36.654621887, -9.312669214999969],
              [-36.654624483, -9.312669177999963],
              [-36.655140284, -9.313583710999984],
              [-36.656146184, -9.314628299999981],
              [-36.657461591, -9.315595510999977],
              [-36.658931752999898, -9.316059772999948],
              [-36.660479290999902, -9.315672887999936],
              [-36.661794699, -9.314821741999943],
              [-36.663071418, -9.314125349999932],
              [-36.665895675999899, -9.313931906999921],
              [-36.668333049, -9.313506333999953],
              [-36.670422226, -9.313235514999963],
              [-36.671737633, -9.311997483999964],
              [-36.672163205999901, -9.310449945999952],
              [-36.672627468, -9.309598799999947],
              [-36.673439926, -9.309560110999934],
              [-36.673788122, -9.310256503999947],
              [-36.674484514, -9.310449945999958],
              [-36.674871399, -9.310217814999923],
              [-36.675761232999911, -9.308979783999972],
              [-36.677656968, -9.307084049999963],
              [-36.679127128999909, -9.306155526999934],
              [-36.681525813999897, -9.304956184999977],
              [-36.683808433, -9.30317651499996],
              [-36.685626790999898, -9.302286680999938],
              [-36.687445147999902, -9.300816518999977],
              [-36.688064164, -9.298611276999964],
              [-36.689224818, -9.29644472299997],
              [-36.689392213, -9.296390016999961],
              [-36.698627885999898, -9.293371696999939],
              [-36.699747484999897, -9.293005799999944],
              [-36.700148727999903, -9.29286639999998],
              [-36.721289902, -9.285521511999955],
              [-36.729663624999901, -9.282612303999946],
              [-36.760779532, -9.271801982999927],
              [-36.761772536, -9.271456992999966],
              [-36.774083377, -9.274605335999983],
              [-36.774552549, -9.274725320999941],
              [-36.776829542, -9.275307632999942],
              [-36.795177887523593, -9.279999992505623],
              [-36.795833331343147, -9.279999992505623],
              [-36.795833330443827, -9.278333325722372],
              [-36.796666665634064, -9.278333324823052],
              [-36.796666667432703, -9.275833326446786],
              [-36.797499999925037, -9.275833325547467],
              [-36.797499999025717, -9.269999991806117],
              [-36.796666665634064, -9.269999992705436],
              [-36.796666665634064, -9.265833326646657],
              [-36.797500000824357, -9.265833326646657],
              [-36.797499999925037, -9.26499999415438],
              [-36.800833331692843, -9.26499999235574],
              [-36.800833331692843, -9.263333325572489],
              [-36.801666665983817, -9.263333325572489],
              [-36.801666667782456, -9.259166658614333],
              [-36.802500000274733, -9.259166658614333],
              [-36.802499999375414, -9.258333326122056],
              [-36.802499998476094, -9.257499992730459],
              [-36.803333331867748, -9.257499991831139],
              [-36.803333331867748, -9.256666657540165],
              [-36.804166665259345, -9.256666658439485],
              [-36.804166665259345, -9.254999992555554],
              [-36.805000000449638, -9.254999992555554],
              [-36.805000000449638, -9.252499994179345],
              [-36.805833333841235, -9.252499992380706],
              [-36.805833332042596, -9.245833326147022],
              [-36.805833332941916, -9.244999992755425],
              [-36.807499999725167, -9.244999992755425],
              [-36.807500000624486, -9.243333325972173],
              [-36.808333332217444, -9.243333325972173],
              [-36.808333332217444, -9.24249999258052],
              [-36.810833331493029, -9.24249999258052],
              [-36.810833331493029, -9.241666659188922],
              [-36.811666665783946, -9.241666659188922],
              [-36.811666665783946, -9.240833325797325],
              [-36.81250000007492, -9.240833325797325],
              [-36.81250000007492, -9.239999992405671],
              [-36.811666667582585, -9.239999994204311],
              [-36.811666665783946, -9.237499992230823],
              [-36.81250000007492, -9.237499993130143],
              [-36.81250000007492, -9.236666658839169],
              [-36.814166665958851, -9.236666658839169],
              [-36.814166666858171, -9.234999993854558],
              [-36.814999998451128, -9.234999992955238],
              [-36.814999999350448, -9.230833325997139],
              [-36.815833333641365, -9.230833325997139],
              [-36.815833332742045, -9.229166659213888],
              [-36.814999999350448, -9.229166657415249],
              [-36.814999999350448, -9.227499992430637],
              [-36.815833332742045, -9.227499992430637],
              [-36.815833331842725, -9.224166658864192],
              [-36.816666665234379, -9.224166658864192],
              [-36.816666668831658, -9.220833327096329],
              [-36.817499998625976, -9.22083332619701],
              [-36.817500000424616, -9.219999992805356],
              [-36.81833333201763, -9.219999992805356],
              [-36.81833333381627, -9.219166658514439],
              [-36.819166665409227, -9.219166658514439],
              [-36.819166666308547, -9.216666657440214],
              [-36.819999999700201, -9.21666666013823],
              [-36.819999998800881, -9.21499999245566],
              [-36.819166664509908, -9.21499999245566],
              [-36.819166666308547, -9.212499994079394],
              [-36.81833333201763, -9.212499993180074],
              [-36.81833333291695, -9.211666659788477],
              [-36.819166667207867, -9.211666658889158],
              [-36.819166665409227, -9.20916665871431],
              [-36.81833333201763, -9.20916665871431],
              [-36.81833333291695, -9.208333326221975],
              [-36.819166666308547, -9.208333326221975],
              [-36.819166666308547, -9.206666658539405],
              [-36.81833333381627, -9.206666658539405],
              [-36.81833333381627, -9.204166660163196],
              [-36.817499999525296, -9.204166658364556],
              [-36.817500000424616, -9.199166658914123],
              [-36.81833333201763, -9.199166658914123],
              [-36.81833333201763, -9.198333325522526],
              [-36.819166665409227, -9.198333325522526],
              [-36.819166667207867, -9.197499992130872],
              [-36.819999998800881, -9.197499993030192],
              [-36.819999999700201, -9.194999991956024],
              [-36.819166665409227, -9.194999991956024],
              [-36.819166665409227, -9.191666658389522],
              [-36.81833333201763, -9.191666658389522],
              [-36.81833333291695, -9.190833325897245],
              [-36.817499999525296, -9.190833325897245],
              [-36.817500000424616, -9.189999992505591],
              [-36.816666665234379, -9.189999992505591],
              [-36.816666667033019, -9.187499993230063],
              [-36.815833333641365, -9.187499993230063],
              [-36.815833331842725, -9.186666658939089],
              [-36.814999998451128, -9.186666658939089],
              [-36.814999998451128, -9.185833325547492],
              [-36.811666666683266, -9.185833326446811],
              [-36.811666665783946, -9.186666658039769],
              [-36.810000000799334, -9.186666658939089],
              [-36.809999999900015, -9.187499993230063],
              [-36.806666667232889, -9.187499993230063],
              [-36.80666666633357, -9.186666658939089],
              [-36.804999998650999, -9.186666658939089],
              [-36.805000000449638, -9.185833325547492],
              [-36.804166665259345, -9.185833325547492],
              [-36.804166665259345, -9.183333324473324],
              [-36.803333332767068, -9.183333325372644],
              [-36.803333331867748, -9.181666658589393],
              [-36.802499999375414, -9.181666659488712],
              [-36.802499998476094, -9.179166658414488],
              [-36.801666667782456, -9.179166658414488],
              [-36.801666665983817, -9.174999992355708],
              [-36.800833331692843, -9.174999992355708],
              [-36.800833332592163, -9.173333324673138],
              [-36.801666665983817, -9.173333325572457],
              [-36.801666665983817, -9.169999992005955],
              [-36.800833332592163, -9.169999992905275],
              [-36.800833331692843, -9.169166658614358],
              [-36.802499998476094, -9.169166658614358],
              [-36.802499998476094, -9.164166659163925],
              [-36.800833332592163, -9.164166659163925],
              [-36.800833331692843, -9.163333325772328],
              [-36.799999998301246, -9.163333325772328],
              [-36.800000000099885, -9.16083332559748],
              [-36.798333330618675, -9.16083332559748],
              [-36.798333331517995, -9.159999993105146],
              [-36.797499999025717, -9.159999993105146],
              [-36.797500000824357, -9.159166658814229],
              [-36.796666664734744, -9.159166658814229],
              [-36.796666666533383, -9.156666658639324],
              [-36.794999998850813, -9.156666658639324],
              [-36.795000000649452, -9.157499992930298],
              [-36.794166666358535, -9.157499993829617],
              [-36.793333332966881, -9.157499992930298],
              [-36.793333332067562, -9.158333325422575],
              [-36.792499998675964, -9.158333324523255],
              [-36.792500000474604, -9.157499992930298],
              [-36.790000000299756, -9.157499992930298],
              [-36.790000000299756, -9.156666658639324],
              [-36.789166667807422, -9.156666658639324],
              [-36.789166666908102, -9.154999991856073],
              [-36.78999999760174, -9.154999992755393],
              [-36.790000000299756, -9.153333325972142],
              [-36.787499998326211, -9.153333325972142],
              [-36.787500001024171, -9.152499992580545],
              [-36.786666665833934, -9.152499991681225],
              [-36.786666667632574, -9.150833325797294],
              [-36.78583333154296, -9.150833325797294],
              [-36.78583333244228, -9.147499992230792],
              [-36.784999999050683, -9.147499993130111],
              [-36.784999999950003, -9.146666658839194],
              [-36.784166665659029, -9.146666658839194],
              [-36.784166665659029, -9.145833324548221],
              [-36.782500000674474, -9.145833325447541],
              [-36.782499998875778, -9.14416665776497],
              [-36.781666666383501, -9.144166658664346],
              [-36.781666665484181, -9.142499992780415],
              [-36.77999999870093, -9.142499992780415],
              [-36.77999999960025, -9.140833325997164],
              [-36.777500000324721, -9.140833325997164],
              [-36.777499998526082, -9.13999999170619],
              [-36.775833330843511, -9.13999999260551],
              [-36.775833331742831, -9.139166659213913],
              [-36.76999999980012, -9.139166659213913],
              [-36.76999999980012, -9.138333325822259],
              [-36.769166666408466, -9.138333325822259],
              [-36.769166666408466, -9.137499992430662],
              [-36.76833333121823, -9.137499994229302],
              [-36.767499998725896, -9.137499991531342],
              [-36.767499998725896, -9.138333325822259],
              [-36.76416666785741, -9.138333325822259],
              [-36.76416666695809, -9.139166657415274],
              [-36.763333330868477, -9.139166659213913],
              [-36.763333333566436, -9.138333325822259],
              [-36.7624999983762, -9.138333325822259],
              [-36.762500001074159, -9.139166659213913],
              [-36.761666667682505, -9.139166660113233],
              [-36.761666666783185, -9.141666658489441],
              [-36.762499999275519, -9.141666658489441],
              [-36.762499999275519, -9.143333326172012],
              [-36.761666665883865, -9.143333327071332],
              [-36.761666665883865, -9.144999992055943],
              [-36.760833332492268, -9.144999992955263],
              [-36.760833331592949, -9.148333324723126],
              [-36.760000000899254, -9.148333325622445],
              [-36.760000000899254, -9.149166659014043],
              [-36.760833333391588, -9.149166659014043],
              [-36.760833331592949, -9.156666658639324],
              [-36.76, -9.156666658639324],
              [-36.759999999100614, -9.158333325422575],
              [-36.759166666608337, -9.158333325422575],
              [-36.759166666608337, -9.159166658814229],
              [-36.757500000724406, -9.159166658814229],
              [-36.757499999825086, -9.159999993105146],
              [-36.756666665534169, -9.159999993105146],
              [-36.756666665534169, -9.16083332559748],
              [-36.755833333941155, -9.1608333264968],
              [-36.755833333041835, -9.162499991481354],
              [-36.754999998750918, -9.162499992380674],
              [-36.754999999650238, -9.163333325772328],
              [-36.754166665359264, -9.163333325772328],
              [-36.754166665359264, -9.164166659163925],
              [-36.753333332866987, -9.164166659163925],
              [-36.753333333766307, -9.16666666023815],
              [-36.751666666983056, -9.16666665843951],
              [-36.751666666983056, -9.167499992730427],
              [-36.749999999300485, -9.167499992730427],
              [-36.749999998401165, -9.168333326122081],
              [-36.749166666808208, -9.168333326122081],
              [-36.749166665908888, -9.170833325397609],
              [-36.749166665908888, -9.171666657889887],
              [-36.748333332517234, -9.171666658789206],
              [-36.748333332517234, -9.173333325572457],
              [-36.749166667707527, -9.173333326471777],
              [-36.749166666808208, -9.174999992355708],
              [-36.749999998401165, -9.174999992355708],
              [-36.749999998401165, -9.175833324848043],
              [-36.750833332692082, -9.175833326646682],
              [-36.750833332692082, -9.177499992530556],
              [-36.751666667882375, -9.177499992530556],
              [-36.751666666083736, -9.182499992880309],
              [-36.750833331792762, -9.182499992880309],
              [-36.750833331792762, -9.184166657864921],
              [-36.750000001099124, -9.184166658764241],
              [-36.749999998401165, -9.186666658939089],
              [-36.746666665733983, -9.186666658939089],
              [-36.746666666633303, -9.187499993230063],
              [-36.745833331443066, -9.187499991431423],
              [-36.745833332342386, -9.18833332572234],
              [-36.744166665559135, -9.18833332572234],
              [-36.744166667357774, -9.190833325897245],
              [-36.743333333066801, -9.190833325897245],
              [-36.743333333066801, -9.192499991781176],
              [-36.742500000574523, -9.192499992680496],
              [-36.742499998775884, -9.193333326072093],
              [-36.74166666538423, -9.193333326072093],
              [-36.741666668082246, -9.19416665856437],
              [-36.739999998601036, -9.19416665856437],
              [-36.739999998601036, -9.194999992855344],
              [-36.739166667008021, -9.194999992855344],
              [-36.739166667907341, -9.195833325347621],
              [-36.738333331817785, -9.195833324448301],
              [-36.738333332717104, -9.19416665856437],
              [-36.736666665034534, -9.19416665856437],
              [-36.736666666833173, -9.193333326072093],
              [-36.733333332367351, -9.193333326072093],
              [-36.733333331468032, -9.19416665856437],
              [-36.732500000774394, -9.19416665766505],
              [-36.732499998975754, -9.193333326072093],
              [-36.7316666655841, -9.193333326072093],
              [-36.73166666738274, -9.192499993579816],
              [-36.729166665409252, -9.192499992680496],
              [-36.729166665409252, -9.19416665856437],
              [-36.728333332916918, -9.19416665856437],
              [-36.728333332017598, -9.194999992855344],
              [-36.727499999525321, -9.194999992855344],
              [-36.727500000424641, -9.195833327146261],
              [-36.726666667932363, -9.195833325347621],
              [-36.726666667932363, -9.196666657839955],
              [-36.727500000424641, -9.196666659638595],
              [-36.727499999525321, -9.197499993030192],
              [-36.726666667032987, -9.197499993030192],
              [-36.726666667932363, -9.199166658914123],
              [-36.72583333184275, -9.199166658914123],
              [-36.72583333184275, -9.199999993205097],
              [-36.724166665958819, -9.199999993205097],
              [-36.724166665958819, -9.200833325697374],
              [-36.723333333466542, -9.200833325697374],
              [-36.723333331667902, -9.201666659088971],
              [-36.721666666683291, -9.201666659088971],
              [-36.721666666683291, -9.202499993379945],
              [-36.719166668307025, -9.202499992480625],
              [-36.719166665609066, -9.203333325872222],
              [-36.715833332941941, -9.203333325872222],
              [-36.715833332941941, -9.202499992480625],
              [-36.71416666525937, -9.202499992480625],
              [-36.714166667058009, -9.207499992830378],
              [-36.713333331867716, -9.207499991931059],
              [-36.713333333666355, -9.208333326221975],
              [-36.714999999550287, -9.208333326221975],
              [-36.714999999550287, -9.210833325497504],
              [-36.71416666436005, -9.210833325497504],
              [-36.71416666525937, -9.211666658889158],
              [-36.713333331867716, -9.211666657989838],
              [-36.713333331867716, -9.214166659064006],
              [-36.714166667058009, -9.214166659064006],
              [-36.71416666525937, -9.216666659238911],
              [-36.715000001348926, -9.216666659238911],
              [-36.714999998650967, -9.221666658689287],
              [-36.713333333666355, -9.221666658689287],
              [-36.713333331867716, -9.222499992980261],
              [-36.712499999375439, -9.222499992980261],
              [-36.712499999375439, -9.224166658864192],
              [-36.710833332592188, -9.224166657964872],
              [-36.710833333491507, -9.228333325822291],
              [-36.710000000099853, -9.228333325822291],
              [-36.709999998301214, -9.229999993504862],
              [-36.70833333151802, -9.229999992605542],
              [-36.70833333241734, -9.230833325997139],
              [-36.707500000824325, -9.230833325997139],
              [-36.707500000824325, -9.231666658489473],
              [-36.706666666533408, -9.231666657590154],
              [-36.706666667432728, -9.23249999278039],
              [-36.705833332242435, -9.23249999278039],
              [-36.705833332242435, -9.233333326172044],
              [-36.703333332966906, -9.233333326172044],
              [-36.703333332966906, -9.23833332562242],
              [-36.704166667257823, -9.23833332562242],
              [-36.704166667257823, -9.243333325972173],
              [-36.705000000649477, -9.243333325972173],
              [-36.704999998850838, -9.244166658464451],
              [-36.702499999575252, -9.244166658464451],
              [-36.702500000474572, -9.244999992755425],
              [-36.701666665284336, -9.244999992755425],
              [-36.698333331717834, -9.244999992755425],
              [-36.698333332617153, -9.245833326147022],
              [-36.697499998326236, -9.245833326147022],
              [-36.697499999225556, -9.244999992755425],
              [-36.695833332442305, -9.244999992755425],
              [-36.695833332442305, -9.244166659363771],
              [-36.690833333891192, -9.244166658464451],
              [-36.690833334790511, -9.243333326871493],
              [-36.687500001224009, -9.243333326871493],
              [-36.68749999942537, -9.244166658464451],
              [-36.686666666933093, -9.244166658464451],
              [-36.686666666033773, -9.244999992755425],
              [-36.684999999250522, -9.245000000849302],
              [-36.685000001049161, -9.245833327046341],
              [-36.684166665858925, -9.245833324348382],
              [-36.684166667657564, -9.246666658639356],
              [-36.683333332467271, -9.246666658639356],
              [-36.683333331567951, -9.249999993105178],
              [-36.682500000874313, -9.249999993105178],
              [-36.682499999974993, -9.250833326496775],
              [-36.683333331567951, -9.250833325597455],
              [-36.683333330668631, -9.254999992555554],
              [-36.684166665858925, -9.254999992555554],
              [-36.684166665858925, -9.255833325947208],
              [-36.684999998351202, -9.255833325947208],
              [-36.684999999250522, -9.256666658439485],
              [-36.685833331742799, -9.256666658439485],
              [-36.685833332642119, -9.257499992730459],
              [-36.68749999942537, -9.257499992730459],
              [-36.68750000032469, -9.259166658614333],
              [-36.688333332817024, -9.259166658614333],
              [-36.688333333716344, -9.262499993080155],
              [-36.68749999942537, -9.262499993979475],
              [-36.68750000032469, -9.264166658964086],
              [-36.686666666933093, -9.264166658964086],
              [-36.686666664235133, -9.266666659138934],
              [-36.685833331742799, -9.266666659138934],
              [-36.685833331742799, -9.267499992530588],
              [-36.683333333366591, -9.267499992530588],
              [-36.683333332467271, -9.268333325922185],
              [-36.682499999075674, -9.268333325922185],
              [-36.682499999075674, -9.26916665841452],
              [-36.680833331393103, -9.26916665841452],
              [-36.680833331393103, -9.269999992705436],
              [-36.679999998900769, -9.269999992705436],
              [-36.679999999800089, -9.271666658589368],
              [-36.679166666408491, -9.271666657690048],
              [-36.679166667307811, -9.272499992880341],
              [-36.676666666233643, -9.272499992880341],
              [-36.676666668032283, -9.273333325372619],
              [-36.674166667857378, -9.273333325372619],
              [-36.674166666058738, -9.279166658214649],
              [-36.673333331767822, -9.279166659113969],
              [-36.673333333566461, -9.282499992680471],
              [-36.672499998376168, -9.282499992680471],
              [-36.672499998376168, -9.283333326072068],
              [-36.67166666588389, -9.283333326971444],
              [-36.67166666678321, -9.284166658564402],
              [-36.67, -9.284166658564402],
              [-36.67, -9.284999992855319],
              [-36.669166665708985, -9.284999992855319],
              [-36.669166667507682, -9.28666665873925],
              [-36.668333331418069, -9.28666665873925],
              [-36.668333333216708, -9.287499993030224],
              [-36.669166666608362, -9.287499993030224],
              [-36.669166665708985, -9.288333325522501],
              [-36.668333331418069, -9.288333325522501],
              [-36.668333333216708, -9.289166658914155],
              [-36.66583333394118, -9.289166658914155],
              [-36.66583333304186, -9.289999993205072],
              [-36.661666664285065, -9.289999991406432],
              [-36.661666666083704, -9.291666659089003],
              [-36.660833333591427, -9.291666659089003],
              [-36.660833332692107, -9.294166658364531],
              [-36.65999999930051, -9.294166658364531],
              [-36.660000001099149, -9.294999992655505],
              [-36.657499998226285, -9.294999992655505],
              [-36.657499998226285, -9.295833326047102],
              [-36.654999998950757, -9.295833326047102],
              [-36.654999998950757, -9.297499992830353],
              [-36.654166665559103, -9.297499992830353],
              [-36.654166667357742, -9.299166658714284],
              [-36.653333333066826, -9.299166658714284],
              [-36.653333333966145, -9.299999993005201],
              [-36.652499998775852, -9.299999993005201],
              [-36.652500001473868, -9.300833325497535],
              [-36.651666666283575, -9.300833325497535],
              [-36.651666665384255, -9.301666658889133],
              [-36.650833332891978, -9.301666658889133],
              [-36.650833333791297, -9.302499993180106],
              [-36.650000000399643, -9.302499993180106],
              [-36.649999999500324, -9.305833326746608],
              [-36.650833331992658, -9.305833325847289],
              [-36.650833332891978, -9.308333326022137],
              [-36.649999997701684, -9.308333326022137],
              [-36.649999997701684, -9.309166659413734],
              [-36.649166665209407, -9.309166658514414],
              [-36.649166667008046, -9.309999992805388],
              [-36.648333332717073, -9.309999992805388],
              [-36.648333332717073, -9.310833326196985],
              [-36.647499998426156, -9.310833326196985],
              [-36.647499998426156, -9.311666657789999],
              [-36.646666667732461, -9.311666658689319],
              [-36.646666666833141, -9.312499992980236],
              [-36.645000000949267, -9.312499992980236],
              [-36.645000000049947, -9.31333332457325],
              [-36.644166665758974, -9.31333332547257],
              [-36.642500001673682, -9.31333332637189],
              [-36.642499998975723, -9.312499992980236],
              [-36.641666665584125, -9.312499993879555],
              [-36.641666667382765, -9.311666659588639],
              [-36.640833331293152, -9.311666658689319],
              [-36.640833331293152, -9.310833326196985],
              [-36.640000000599514, -9.310833326196985],
              [-36.639999999700194, -9.311666658689319],
              [-36.63916666630854, -9.311666658689319],
              [-36.63916666630854, -9.308333326022137],
              [-36.638333332916943, -9.308333326022137],
              [-36.638333333816263, -9.305833325847289],
              [-36.637499999525289, -9.305833324947969],
              [-36.637500000424609, -9.304999992455635],
              [-36.636666665234372, -9.304999991556315],
              [-36.636666667033012, -9.304166659963357],
              [-36.635833332742095, -9.304166659064038],
              [-36.635833332742095, -9.303333325672384],
              [-36.635000000249761, -9.303333325672384],
              [-36.634999998451121, -9.302499994079426],
              [-36.634166665958844, -9.302499993180106],
              [-36.634166665958844, -9.301666659788452],
              [-36.632500000974233, -9.301666658889133],
              [-36.632500000074913, -9.300833325497535],
              [-36.630833331493022, -9.300833325497535],
              [-36.630833332392342, -9.299999992105882],
              [-36.629166665609091, -9.299999994803898],
              [-36.628333331318117, -9.299999993005201],
              [-36.624999998650992, -9.299999993005201],
              [-36.625000000449631, -9.299166658714284],
              [-36.624166667957297, -9.299166658714284],
              [-36.624166665259338, -9.299999993005201],
              [-36.622500000274727, -9.299999993005201],
              [-36.622500000274727, -9.300833325497535],
              [-36.621666666883129, -9.300833325497535],
              [-36.621666666883129, -9.301666658889133],
              [-36.614999999750125, -9.301666658889133],
              [-36.615000000649445, -9.302499993180106],
              [-36.614166667257848, -9.302499993180106],
              [-36.614166667257848, -9.303333325672384],
              [-36.61166666798232, -9.303333325672384],
              [-36.611666665284304, -9.304999992455635],
              [-36.610833333691346, -9.304999992455635],
              [-36.610833333691346, -9.306666659238886],
              [-36.610833332792026, -9.314166658864167],
              [-36.61166666798232, -9.314166658864167],
              [-36.611666667083, -9.314999993155141],
              [-36.610833333691346, -9.31499999405446],
              [-36.610833333691346, -9.317499992430669],
              [-36.60999999850111, -9.317499994229308],
              [-36.60999999760179, -9.318333325822266],
              [-36.609166666908095, -9.318333325822266],
              [-36.609166666008775, -9.31916665921392],
              [-36.608333333516498, -9.31916665921392],
              [-36.608333333516498, -9.319999992605517],
              [-36.609166666908095, -9.319999992605517],
              [-36.609166666008775, -9.320833326896491],
              [-36.60999999850111, -9.320833325997171],
              [-36.609999999400429, -9.321666658489448],
              [-36.610833331892707, -9.321666658489448],
              [-36.610833331892707, -9.322499993679742],
              [-36.609999999400429, -9.322499992780422],
              [-36.60999999850111, -9.323333326172019],
              [-36.609166666008775, -9.323333326172019],
              [-36.609166667807415, -9.324166658664296],
              [-36.608333331717859, -9.324166658664296],
              [-36.608333331717859, -9.326666658839201],
              [-36.607499998326205, -9.326666658839201],
              [-36.607499999225524, -9.328333325622452],
              [-36.606666667632567, -9.328333325622452],
              [-36.606666665833927, -9.331666659188897],
              [-36.605833333341593, -9.331666659188897],
              [-36.605833331542954, -9.332499992580551],
              [-36.604999999050676, -9.332499992580551],
              [-36.604999999050676, -9.337499992930304],
              [-36.605833331542954, -9.337499992930304],
              [-36.605833332442273, -9.338333324523262],
              [-36.606666667632567, -9.338333327221221],
              [-36.606666667632567, -9.340400874461842],
              [-36.607270024999899, -9.33872647299995],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '272',
        OBJECTID: 175.0,
        Nome_area: 'Sertão de Itaparica',
        COD_area: 'CA183',
        Import_bio: 'Muito Alta',
        Prior_acao: 'Muito Alta',
        Acao_princ: 'Criação de UC de categoria indefinida',
        Area_ha: 670960.359,
        Shape_Leng: 11.0686,
        Shape_Area: 0.55157,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.659259482, -8.985874484999947],
              [-37.660944443999902, -8.986694443999966],
              [-37.661572684, -8.987310279999965],
              [-37.6616477009999, -8.987248114999941],
              [-37.666884215, -8.988950618999983],
              [-37.668922745, -8.99062492399997],
              [-37.67454393700001, -8.992980201999956],
              [-37.677678537, -8.994967194999962],
              [-37.678016451, -8.995145825999943],
              [-37.678021371999897, -8.995148427999929],
              [-37.682152836999897, -8.997332444999932],
              [-37.682270048, -8.997072426999974],
              [-37.683523572, -8.994291665999977],
              [-37.683537322999904, -8.994261162999969],
              [-37.683670513, -8.99395420199993],
              [-37.683958256, -8.993291046999964],
              [-37.68400079700001, -8.993193005999959],
              [-37.684214702, -8.992641323999978],
              [-37.684428608, -8.992300922999958],
              [-37.684642516999901, -8.99214832799993],
              [-37.6849515, -8.992089632999988],
              [-37.685246371999902, -8.992025241999952],
              [-37.685519703999901, -8.991661350999953],
              [-37.685923752999898, -8.991121388999941],
              [-37.686102005999899, -8.990769246999953],
              [-37.686102002, -8.990546227999962],
              [-37.685995041, -8.990182359999952],
              [-37.685908038, -8.989885972999964],
              [-37.685993141, -8.98952870399995],
              [-37.686000233, -8.989514694],
              [-37.68613498, -8.989101382999936],
              [-37.686138526, -8.989094377999944],
              [-37.686142071999903, -8.989087372999967],
              [-37.686347743999903, -8.988786143999965],
              [-37.686365827, -8.988765977999941],
              [-37.686567599999897, -8.988540957999929],
              [-37.686858368999907, -8.988008558999974],
              [-37.686858367999903, -8.988001553999933],
              [-37.686858367999903, -8.987994548999952],
              [-37.686929285, -8.987805407999936],
              [-37.687071128999897, -8.98773535299995],
              [-37.687085313, -8.987735352999959],
              [-37.687241345, -8.987798394999963],
              [-37.687482486, -8.98795250099994],
              [-37.687515412, -8.987976295999941],
              [-37.687773273999902, -8.988162646999973],
              [-37.688085338999898, -8.988393805999971],
              [-37.68846832, -8.988456839999976],
              [-37.689042793999903, -8.988610932999951],
              [-37.689071162999902, -8.988617936999958],
              [-37.689425776, -8.988680970999953],
              [-37.689482518, -8.988793050999963],
              [-37.689546358, -8.989059243999984],
              [-37.690035737, -8.989416488999948],
              [-37.6900521009999, -8.989426419999976],
              [-37.690531979999903, -8.989717642999954],
              [-37.690871479999899, -8.989853567999951],
              [-37.69118345, -8.989944180999935],
              [-37.691220126, -8.990069863999961],
              [-37.691220157, -8.990089178999943],
              [-37.691073356999901, -8.990243245999974],
              [-37.691057268999899, -8.990254722999955],
              [-37.690908201999903, -8.990361064999957],
              [-37.690981614999899, -8.990623874999933],
              [-37.691385345, -8.990859479999925],
              [-37.691706483, -8.990868527999963],
              [-37.691825756999897, -8.990723523999975],
              [-37.691945022, -8.990342896999953],
              [-37.691963359999903, -8.990261374999934],
              [-37.692000064, -8.990098208999934],
              [-37.692156038999897, -8.990007577999956],
              [-37.692486341999903, -8.989889752999932],
              [-37.692752418999902, -8.989808179999953],
              [-37.692963452, -8.989898794999929],
              [-37.693146969999901, -8.99021596899996],
              [-37.693578203, -8.990270323999944],
              [-37.693743348999902, -8.990152504999971],
              [-37.693789217, -8.990007505999923],
              [-37.6938075589999, -8.989826257999948],
              [-37.693789207999899, -8.989817196999978],
              [-37.693878259, -8.989650733999941],
              [-37.694081750999899, -8.989560276999947],
              [-37.694285249, -8.989595440999944],
              [-37.694366653, -8.989716034999963],
              [-37.694352076, -8.98987929399993],
              [-37.694351399, -8.989886881999956],
              [-37.694366671, -8.99008285199994],
              [-37.694475101, -8.990199930999943],
              [-37.694475101, -8.990214735999931],
              [-37.694317742, -8.99063666999996],
              [-37.694310248, -8.990658877999977],
              [-37.694370212, -8.990851332999972],
              [-37.694669989, -8.990932742999981],
              [-37.694677621, -8.990932741999934],
              [-37.694699966, -8.990932740999932],
              [-37.694962265, -8.990903118999945],
              [-37.69501472000001, -8.990799484999972],
              [-37.69499972499991, -8.990666245999922],
              [-37.695067168, -8.990555208999979],
              [-37.69526202, -8.990562600999974],
              [-37.695736827, -8.990789729999957],
              [-37.695741669, -8.990792045999969],
              [-37.695997433999899, -8.990905053999944],
              [-37.696002824999901, -8.990905832999934],
              [-37.696458838999902, -8.990971723999968],
              [-37.697077808, -8.991182887999971],
              [-37.697741784999899, -8.991205088999946],
              [-37.697927555, -8.991097141999933],
              [-37.697933093, -8.991093923999937],
              [-37.698248170999904, -8.990671516999949],
              [-37.698411425, -8.99023928799993],
              [-37.698230192, -8.989791859999961],
              [-37.69796994, -8.989633693999952],
              [-37.6979717729999, -8.989633838999952],
              [-37.697973605, -8.989633983999978],
              [-37.697725567, -8.989458898999933],
              [-37.697667205999899, -8.989225451999971],
              [-37.698104918999903, -8.988204120999967],
              [-37.698484269999902, -8.98798526499996],
              [-37.698921344, -8.986970124999967],
              [-37.69892988, -8.986950299999938],
              [-37.698933226999898, -8.986942526999945],
              [-37.698936574, -8.986934752999964],
              [-37.698977326, -8.986879540999952],
              [-37.699388876999897, -8.986321954999941],
              [-37.699553019999897, -8.985566899999972],
              [-37.699559526, -8.985542101999949],
              [-37.699585661999897, -8.985442488999981],
              [-37.699743231999904, -8.984841937999928],
              [-37.699746343, -8.984830082999959],
              [-37.6998484759999, -8.984330359999944],
              [-37.699830555999903, -8.984055555999927],
              [-37.699622324, -8.984027608999957],
              [-37.699071535, -8.984220931999973],
              [-37.698734497, -8.984458997999983],
              [-37.698619128, -8.984540488999931],
              [-37.698611936, -8.984545568999973],
              [-37.698603379, -8.984550489999954],
              [-37.698206432999903, -8.98477873299993],
              [-37.697882415, -8.984965043999985],
              [-37.697515482, -8.984938209999941],
              [-37.697482207, -8.984935776999979],
              [-37.697146667, -8.984870181999945],
              [-37.6967404869999, -8.984575007999988],
              [-37.695968493, -8.983808059999948],
              [-37.69581863, -8.983498069999936],
              [-37.695784325, -8.98342710899993],
              [-37.695819645, -8.982700525999975],
              [-37.695703593, -8.982309482999931],
              [-37.695352915999898, -8.98189068899995],
              [-37.695297412999899, -8.981532442999939],
              [-37.694974488, -8.98132304599994],
              [-37.694611196, -8.981179242999932],
              [-37.694328636, -8.981143922999928],
              [-37.693853078, -8.981186811999972],
              [-37.693730719, -8.981352058999965],
              [-37.693552858, -8.981430267999931],
              [-37.693516277, -8.98143152899995],
              [-37.693485693999897, -8.981390468999964],
              [-37.69348151, -8.981385686999936],
              [-37.693364798999902, -8.981252302999954],
              [-37.693174821, -8.981162494999973],
              [-37.693067743, -8.981176311999949],
              [-37.692746507, -8.981328293999976],
              [-37.692632521, -8.981338655999931],
              [-37.692480851, -8.981261068999974],
              [-37.692342372, -8.98114176999997],
              [-37.6922974679999, -8.981013966999972],
              [-37.692183482, -8.980951791999928],
              [-37.691869154, -8.981031237999954],
              [-37.691738749, -8.981097443999941],
              [-37.691420116, -8.98125921099996],
              [-37.691185233999903, -8.981293752999925],
              [-37.690905448, -8.981387013999948],
              [-37.690772098, -8.981400717999971],
              [-37.690638748, -8.981414421999936],
              [-37.690533484999904, -8.981275495999963],
              [-37.690140649999897, -8.980651619999943],
              [-37.690013279, -8.980531364999925],
              [-37.689861874, -8.980566059999935],
              [-37.689601992999897, -8.980627426999936],
              [-37.689582781, -8.980621022999969],
              [-37.689434080999902, -8.980521210999937],
              [-37.689285686999902, -8.980443260999948],
              [-37.689263286, -8.980429610999984],
              [-37.689233401, -8.980430322999958],
              [-37.688969409, -8.98045807399996],
              [-37.68852564, -8.980356301999958],
              [-37.688250013, -8.980172734999949],
              [-37.688091207, -8.979973012999947],
              [-37.68798104, -8.979872452999931],
              [-37.687829475999898, -8.979863202999971],
              [-37.687687163, -8.979834027999969],
              [-37.68762759, -8.979746179999951],
              [-37.687445941, -8.979708080999961],
              [-37.687296299, -8.979690322999936],
              [-37.687221179999902, -8.979603647999967],
              [-37.687186509999897, -8.979482302999932],
              [-37.687240441, -8.979197237999928],
              [-37.6872828159999, -8.979083596999942],
              [-37.687308818, -8.978848610999934],
              [-37.6872221429999, -8.978734970999939],
              [-37.687078648, -8.978675260999976],
              [-37.6870738329999, -8.978367082999966],
              [-37.687023753999902, -8.978192769999959],
              [-37.686906128, -8.978083925999952],
              [-37.686873536999897, -8.978083479999947],
              [-37.686759720999902, -8.978107159999972],
              [-37.68660542, -8.978272154999969],
              [-37.686602842, -8.978273187999946],
              [-37.686405287, -8.978352360999963],
              [-37.686375063, -8.978355382999943],
              [-37.68636693, -8.978351889999939],
              [-37.686157575, -8.978257274999972],
              [-37.685944827999897, -8.978099684999968],
              [-37.685901491, -8.97794603499996],
              [-37.685767539, -8.977768745999972],
              [-37.685227793, -8.977445685999946],
              [-37.685029816999901, -8.977251192999967],
              [-37.684972221999899, -8.977111110999942],
              [-37.684871597, -8.976968101999944],
              [-37.684776972999899, -8.976742458999972],
              [-37.684645981, -8.976504846999944],
              [-37.684345101, -8.976402271999968],
              [-37.683996992999901, -8.976374968999945],
              [-37.683819527, -8.976470527999945],
              [-37.683710317, -8.976647994999949],
              [-37.683334906, -8.976968799999945],
              [-37.6830891829999, -8.977043881999988],
              [-37.682857111, -8.977173568999946],
              [-37.68268647, -8.977166743999931],
              [-37.682495351999897, -8.977057532999932],
              [-37.682433922, -8.976859589999956],
              [-37.68231106, -8.976620691999932],
              [-37.682106291, -8.976572912999936],
              [-37.681744275, -8.976861184999954],
              [-37.681740991, -8.976863799999933],
              [-37.681737706, -8.976866415999947],
              [-37.68151246, -8.976982450999976],
              [-37.681504732999898, -8.976980417999933],
              [-37.681253086, -8.976914194999951],
              [-37.680782117, -8.977064358999947],
              [-37.680747370999903, -8.977076302999935],
              [-37.680563696, -8.977139440999961],
              [-37.6804203579999, -8.97709848699998],
              [-37.68007225, -8.977016579999933],
              [-37.679845239, -8.977018443999954],
              [-37.679826479, -8.977027102999944],
              [-37.679642235, -8.977112138999921],
              [-37.678775378, -8.977159917999938],
              [-37.678743644999898, -8.977152594999946],
              [-37.678509178, -8.97709848699998],
              [-37.678479739, -8.977087781999954],
              [-37.6782839319999, -8.97701657999994],
              [-37.678147419, -8.976777681999982],
              [-37.677840264999901, -8.976702599999944],
              [-37.677511028, -8.976674461999938],
              [-37.677473839, -8.976677702999947],
              [-37.677436649, -8.976680944999945],
              [-37.6771099219999, -8.976709425999955],
              [-37.677071337, -8.976698778999953],
              [-37.676889581, -8.976648627999962],
              [-37.676222589, -8.976265758999931],
              [-37.675635583, -8.97603368699993],
              [-37.6751714399999, -8.97596543099996],
              [-37.674825598, -8.975838541999964],
              [-37.674798058, -8.97583290099992],
              [-37.674298291, -8.97573052899997],
              [-37.673772184, -8.975528589999955],
              [-37.673533286, -8.975508112999931],
              [-37.672519413, -8.975083622999923],
              [-37.671916666999905, -8.9745],
              [-37.678406977999899, -8.967494584999933],
              [-37.689416666999897, -8.955611110999945],
              [-37.689467904, -8.955461138999958],
              [-37.690028675, -8.953819744999976],
              [-37.690299124, -8.952679997999942],
              [-37.690183217, -8.952158418999927],
              [-37.689990039999898, -8.951675475999934],
              [-37.689700274, -8.951501615999966],
              [-37.68926792, -8.951456889999962],
              [-37.689105626999897, -8.951307387999988],
              [-37.689062787999909, -8.951037989999971],
              [-37.689179825999901, -8.950730280999943],
              [-37.689427157999901, -8.950334549999985],
              [-37.689517846999898, -8.949765686999969],
              [-37.689719592, -8.949492570999951],
              [-37.690047993, -8.949183486999965],
              [-37.6904923009999, -8.948797131999982],
              [-37.691632048, -8.948275552999977],
              [-37.692462711, -8.948178964999954],
              [-37.693100195999897, -8.947947151999939],
              [-37.693537718, -8.947409363999952],
              [-37.693563821999902, -8.947377277999976],
              [-37.693814953, -8.947077852999932],
              [-37.694336532, -8.946556273999931],
              [-37.694800158, -8.946401731999963],
              [-37.695341054, -8.946479002999988],
              [-37.695727409, -8.946556273999926],
              [-37.696036493, -8.946440367999973],
              [-37.696055811, -8.94618923699994],
              [-37.695823998, -8.945918788999956],
              [-37.695881950999897, -8.94566765799993],
              [-37.696210353, -8.945609704999976],
              [-37.6965194369999, -8.945629022999976],
              [-37.696712614, -8.945474480999971],
              [-37.696766606, -8.945231517999956],
              [-37.696789884999902, -8.945126760999935],
              [-37.697060333, -8.944894948999945],
              [-37.697581912, -8.944798359999934],
              [-37.698238715999899, -8.944798359999929],
              [-37.698605753, -8.944875630999954],
              [-37.698841667, -8.944722221999942],
              [-37.698730704999903, -8.944368028999964],
              [-37.698476293999903, -8.944100227999961],
              [-37.698529854, -8.94337716599995],
              [-37.698717315, -8.943122754999919],
              [-37.698797655, -8.942721053999943],
              [-37.698735182, -8.942184021999946],
              [-37.698731967999898, -8.942156398999931],
              [-37.698663755, -8.941315099999942],
              [-37.698864605, -8.941060688999926],
              [-37.698892702, -8.941064520999921],
              [-37.699159186, -8.941100858999958],
              [-37.6995073269999, -8.940966958999972],
              [-37.6998166609999, -8.940616631999934],
              [-37.699895639, -8.940284066999936],
              [-37.699668007999897, -8.939788634999966],
              [-37.6994939369999, -8.93915930299994],
              [-37.698931555999899, -8.938342510999968],
              [-37.6986369749999, -8.937766738999935],
              [-37.698730704999903, -8.937204357999976],
              [-37.699172576, -8.936695535999947],
              [-37.699259809999901, -8.936171624999925],
              [-37.699079013, -8.935914702999987],
              [-37.699027289, -8.93584119999997],
              [-37.699015050999897, -8.935523012999976],
              [-37.699044254999897, -8.935346927999932],
              [-37.699128698, -8.934837788999973],
              [-37.699149668, -8.934115646999933],
              [-37.699210858, -8.933626128999954],
              [-37.699529045, -8.933430321999939],
              [-37.699751160999902, -8.933281665999976],
              [-37.699786042, -8.932842898999981],
              [-37.6997615659999, -8.931863861999942],
              [-37.699932897999901, -8.930958252999975],
              [-37.700104229, -8.930737969999941],
              [-37.700373463999902, -8.930713493999971],
              [-37.700948648999898, -8.930627827999968],
              [-37.701217884, -8.93039530699996],
              [-37.701205645999899, -8.929918025999948],
              [-37.700960887, -8.929391793999978],
              [-37.700985363, -8.928975702999939],
              [-37.701279074, -8.928571850999964],
              [-37.701768592, -8.928302614999952],
              [-37.702050065, -8.927788620999927],
              [-37.702030448999899, -8.927715061999944],
              [-37.701952161999898, -8.927421481999945],
              [-37.702147969, -8.927078818999963],
              [-37.702588536, -8.926491396999941],
              [-37.702710915, -8.925842784999922],
              [-37.70298015, -8.925132982999967],
              [-37.703278353, -8.924685678999943],
              [-37.703396241, -8.924508846999974],
              [-37.703689951999898, -8.924092756999929],
              [-37.703775618, -8.923627713999959],
              [-37.703677714, -8.923040291999945],
              [-37.703714428, -8.922306013999957],
              [-37.703922473, -8.921926636999956],
              [-37.704316583, -8.921825583999984],
              [-37.704399754, -8.921804257999959],
              [-37.704705702999902, -8.921816495999963],
              [-37.7048770339999, -8.921620687999955],
              [-37.704955213999902, -8.921288283999962],
              [-37.705195220999897, -8.92077626899993],
              [-37.705611312, -8.920396891999927],
              [-37.705856071, -8.919993038999959],
              [-37.70616202, -8.919748279999963],
              [-37.706516920999903, -8.919711565999936],
              [-37.707006439999901, -8.919479044999949],
              [-37.707068712, -8.919406940999986],
              [-37.707238961, -8.919209809999924],
              [-37.707557148, -8.918818194999963],
              [-37.707936525, -8.918671338999959],
              [-37.708217998, -8.918255248999969],
              [-37.708169046, -8.917814681999962],
              [-37.708083379999898, -8.917276211999919],
              [-37.708413805, -8.916798930999962],
              [-37.708854372, -8.916419553999956],
              [-37.709239442, -8.91596163299994],
              [-37.709307177, -8.915881083999983],
              [-37.709784457, -8.915428278999977],
              [-37.7101393579999, -8.915220233999944],
              [-37.710494259, -8.915501706999985],
              [-37.710947064, -8.915587372999942],
              [-37.711485534, -8.915207995999948],
              [-37.712087234, -8.914670424999933],
              [-37.712378905, -8.914008675999975],
              [-37.712954088999901, -8.913751678999967],
              [-37.713857187, -8.912823721999926],
              [-37.713891428999901, -8.912788536999924],
              [-37.714917688, -8.912169242999944],
              [-37.715034024, -8.912099039999925],
              [-37.716176619, -8.911320893999971],
              [-37.716747917, -8.911094343999928],
              [-37.716984316, -8.910848095999945],
              [-37.716915366, -8.910670795999986],
              [-37.716728217, -8.910493496999944],
              [-37.716393318, -8.910188147999968],
              [-37.716147068999902, -8.909971448999951],
              [-37.716156919, -8.909744899999959],
              [-37.716344068999902, -8.909666100999978],
              [-37.716678967, -8.90961685099994],
              [-37.717230565, -8.909784299999961],
              [-37.717604863, -8.909754749999932],
              [-37.717979161, -8.909449401999945],
              [-37.718511059, -8.908917503999934],
              [-37.718954306999898, -8.908375755999927],
              [-37.719387705, -8.908099956999923],
              [-37.719525604999902, -8.907735508999954],
              [-37.719555155, -8.907513884999965],
              [-37.719584704999903, -8.907292260999954],
              [-37.719732453999903, -8.906632313999976],
              [-37.720087051999897, -8.905913266999958],
              [-37.720168504999897, -8.905469801999978],
              [-37.720175701999899, -8.905430618999965],
              [-37.720215724, -8.905284382999982],
              [-37.720431801, -8.904494872999978],
              [-37.720632866, -8.903441676999929],
              [-37.72063865, -8.903411377999932],
              [-37.720894479, -8.902200621999947],
              [-37.721293611, -8.900311660999938],
              [-37.721633496, -8.898703097999931],
              [-37.721505265, -8.898089421999934],
              [-37.721495596, -8.898043150999966],
              [-37.721387246999903, -8.897363503999935],
              [-37.721377397, -8.897068004999937],
              [-37.721525146, -8.896959655999986],
              [-37.721860045, -8.896979355999964],
              [-37.722451041999904, -8.897038454999963],
              [-37.7225264799999, -8.897033854999954],
              [-37.723258739, -8.896989205999944],
              [-37.723719387, -8.896860223999964],
              [-37.723751235999899, -8.896851305999965],
              [-37.724214183999898, -8.896644456999985],
              [-37.724716531999903, -8.896339107999943],
              [-37.725149929999901, -8.896043609999944],
              [-37.725662128, -8.895531411999963],
              [-37.726065976, -8.895078313999925],
              [-37.726243275999899, -8.894654765999967],
              [-37.726538773999899, -8.894260766999929],
              [-37.726578173999897, -8.893758418999946],
              [-37.726676674, -8.89316742199998],
              [-37.726893372999903, -8.892586274999925],
              [-37.727218421, -8.892152875999944],
              [-37.727257821, -8.891758877999971],
              [-37.72745781, -8.891528637999945],
              [-37.727720769, -8.891039830999945],
              [-37.728075368, -8.89104968099997],
              [-37.728361016999898, -8.891236829999974],
              [-37.728883063999902, -8.891315629999943],
              [-37.729217962999897, -8.891187580999938],
              [-37.729720310999902, -8.891138330999983],
              [-37.730124159, -8.890990580999929],
              [-37.730537856999902, -8.891029980999971],
              [-37.730774256, -8.890882231999974],
              [-37.730833355999899, -8.890596582999931],
              [-37.730665906999903, -8.890389733999935],
              [-37.730764405999899, -8.890054835999926],
              [-37.730951554999898, -8.889729786999967],
              [-37.731158404, -8.889020589999969],
              [-37.731178104, -8.888321242999943],
              [-37.73108945500001, -8.887966644999951],
              [-37.730803806, -8.887769644999924],
              [-37.730232508, -8.887355946999957],
              [-37.729740010999897, -8.887149097999956],
              [-37.7293164619999, -8.886774799999955],
              [-37.728971714, -8.886390650999944],
              [-37.729030813999898, -8.885750403999955],
              [-37.729149013, -8.885021506999973],
              [-37.729375562, -8.884361559999954],
              [-37.729907459999907, -8.883484913999945],
              [-37.730202958999897, -8.882716616999973],
              [-37.730665906999903, -8.882184719999941],
              [-37.730920814999898, -8.881627674999955],
              [-37.731453902999903, -8.88057917699996],
              [-37.732005500999897, -8.879663129999939],
              [-37.7322222, -8.87949568099998],
              [-37.732429049, -8.879564630999937],
              [-37.732478299, -8.879810879999983],
              [-37.732310848999902, -8.88006697899992],
              [-37.732369949, -8.880401876999935],
              [-37.732606347999898, -8.880687525999965],
              [-37.733108695999903, -8.880904224999949],
              [-37.734182341, -8.880923924999932],
              [-37.735206736999899, -8.880776175999932],
              [-37.735709084999897, -8.880510226999954],
              [-37.736122783, -8.880145777999937],
              [-37.736250831999897, -8.879761629999953],
              [-37.736536481, -8.87964343099998],
              [-37.737048678999898, -8.879712379999946],
              [-37.737767726, -8.879860129999942],
              [-37.738122324, -8.879850279999966],
              [-37.738299624, -8.879584330999926],
              [-37.738319323, -8.879229731999937],
              [-37.738496622999897, -8.878559934999942],
              [-37.738900471, -8.878284135999925],
              [-37.739255069, -8.877998487999955],
              [-37.739452069, -8.877515839999944],
              [-37.739738831999901, -8.876925514999936],
              [-37.739946497999902, -8.876581258999977],
              [-37.740190814999899, -8.876176245999938],
              [-37.740476464, -8.876038345999977],
              [-37.740949262, -8.876156545999946],
              [-37.74143191, -8.876235344999936],
              [-37.741668309, -8.876077745999961],
              [-37.741697858999899, -8.875742846999971],
              [-37.741731575, -8.87541052699992],
              [-37.742062307, -8.874876050999934],
              [-37.7422199069999, -8.874472202999929],
              [-37.742190357, -8.874176704],
              [-37.742121407, -8.873733455999975],
              [-37.742220919, -8.873280972999963],
              [-37.742436606, -8.872925759999925],
              [-37.742584355, -8.872236262999929],
              [-37.742899554, -8.871389165999968],
              [-37.743007903, -8.870945917999968],
              [-37.742998053, -8.870384470999973],
              [-37.742919254, -8.86960632399996],
              [-37.742850303999901, -8.869153225999973],
              [-37.742554804999898, -8.868818326999936],
              [-37.742357806, -8.868414478999959],
              [-37.7423676559999, -8.867981080999932],
              [-37.742578276, -8.867853889999944],
              [-37.742909403999903, -8.867892430999969],
              [-37.743323102, -8.868050030999946],
              [-37.743667907, -8.867914424999981],
              [-37.744313614, -8.867288895999927],
              [-37.744495218999901, -8.866683545999935],
              [-37.744333792, -8.865654450999948],
              [-37.744010938, -8.864847316999938],
              [-37.744333792, -8.863656794999939],
              [-37.7453427089999, -8.862365380999961],
              [-37.745757249999897, -8.860398484999962],
              [-37.746492874, -8.859076310999964],
              [-37.748329102999897, -8.858854349999945],
              [-37.750225868, -8.858370069999978],
              [-37.750124975999903, -8.856957585999965],
              [-37.751113715, -8.85594866899994],
              [-37.752223522999898, -8.853164056999955],
              [-37.75240647, -8.852823397999931],
              [-37.752808694999899, -8.852074426999934],
              [-37.754362426999897, -8.850641764999979],
              [-37.754685281, -8.848825713999918],
              [-37.75536189, -8.846363684999977],
              [-37.755488107, -8.846384126999956],
              [-37.757052726, -8.847066475999942],
              [-37.7584493479999, -8.847284645999929],
              [-37.759417692999897, -8.84781578399998],
              [-37.760300555999898, -8.848021637999986],
              [-37.760999995, -8.848084568999974],
              [-37.761584515, -8.849022093999967],
              [-37.762039111, -8.849540920999972],
              [-37.762965763999901, -8.850375843999929],
              [-37.7637051909999, -8.850716810999934],
              [-37.764150789, -8.851604865999946],
              [-37.764591862, -8.852677516999954],
              [-37.765263075999897, -8.853894202999976],
              [-37.765903414, -8.854463660999947],
              [-37.767548346, -8.854087453999968],
              [-37.769530384999896, -8.853303722999955],
              [-37.77113084, -8.852834085999962],
              [-37.77262655, -8.85282369899994],
              [-37.773649551999902, -8.853032875999936],
              [-37.774472165, -8.853791373999966],
              [-37.775251464999897, -8.85441030399994],
              [-37.776591623999899, -8.85624947499997],
              [-37.778289333, -8.85753691999996],
              [-37.778737213999896, -8.858332662999947],
              [-37.779137254, -8.859173433999956],
              [-37.780203621999902, -8.859522181999978],
              [-37.780297036, -8.859524414999951],
              [-37.781651543, -8.859556804999951],
              [-37.783610203, -8.859557261999948],
              [-37.784828043999902, -8.859447854999921],
              [-37.785521920999898, -8.859741515999938],
              [-37.786198872999897, -8.860727436999973],
              [-37.786636622999907, -8.861938507999984],
              [-37.786726656, -8.86207919199995],
              [-37.786925217, -8.863515420999938],
              [-37.786532356999899, -8.864291042999984],
              [-37.786134981, -8.865251263999943],
              [-37.786439367, -8.866182068999928],
              [-37.787460192, -8.866483508999988],
              [-37.788950348999897, -8.866703797999952],
              [-37.790016738, -8.867052491999976],
              [-37.791347567999907, -8.868053961999975],
              [-37.791850073, -8.868527717999937],
              [-37.792297966, -8.869323403999946],
              [-37.79203169399991, -8.870656168999972],
              [-37.792141344, -8.871905556999932],
              [-37.792933569, -8.873910077999945],
              [-37.794702863999902, -8.874993098999942],
              [-37.796601186, -8.875731054999966],
              [-37.798415067999898, -8.876097561999932],
              [-37.80090201, -8.875695098999925],
              [-37.802205385, -8.875910880999978],
              [-37.803546446, -8.876496962999965],
              [-37.804377065, -8.876932347999972],
              [-37.806286499, -8.877208732999971],
              [-37.808298594999897, -8.877118248999968],
              [-37.80974793, -8.877106581999946],
              [-37.811405997, -8.87811580199997],
              [-37.813333333218758, -8.879977801259619],
              [-37.813333331468016, -8.879166659014061],
              [-37.814166666658309, -8.879166658114741],
              [-37.814166667557629, -8.878333325622407],
              [-37.814166666658309, -8.876666659738532],
              [-37.813333333266655, -8.876666660637852],
              [-37.813333332367336, -8.872499993679696],
              [-37.814166666658309, -8.872499993679696],
              [-37.814166665758989, -8.871666659388779],
              [-37.815000000049906, -8.871666659388779],
              [-37.814999998251267, -8.870833325997125],
              [-37.81583333254224, -8.870833325997125],
              [-37.815833331642921, -8.868333326721597],
              [-37.814999998251267, -8.868333326721597],
              [-37.814999998251267, -8.866666659938346],
              [-37.814166667557629, -8.866666658139707],
              [-37.814166665758989, -8.865833326546749],
              [-37.813333332367336, -8.865833326546749],
              [-37.813333331468016, -8.864166659763498],
              [-37.812499998975738, -8.864166659763498],
              [-37.812499999875058, -8.859166659413745],
              [-37.811666665584085, -8.859166659413745],
              [-37.811666666483404, -8.855833326746563],
              [-37.810833333091807, -8.855833326746563],
              [-37.810833333091807, -8.852499992280798],
              [-37.811666666483404, -8.852499993180118],
              [-37.811666667382724, -8.851666658889144],
              [-37.813333332367336, -8.851666658889144],
              [-37.813333331468016, -8.849166663210895],
              [-37.80999999970021, -8.849166659613616],
              [-37.809999998800834, -8.848333326221962],
              [-37.809166666308556, -8.848333326221962],
              [-37.809166667207876, -8.847499993729684],
              [-37.807499999525305, -8.847499993729684],
              [-37.807500001323945, -8.846666659438711],
              [-37.805833331842734, -8.846666659438711],
              [-37.805833333641374, -8.848333326221962],
              [-37.804999998451137, -8.848333326221962],
              [-37.804999999350457, -8.849166659613616],
              [-37.804166666858123, -8.849166659613616],
              [-37.804166666858123, -8.849999993005213],
              [-37.802499997376913, -8.849999993005213],
              [-37.802500000074929, -8.850833326396867],
              [-37.801666666683275, -8.850833326396867],
              [-37.801666666683275, -8.851666658889144],
              [-37.800833335090317, -8.851666658889144],
              [-37.800833332392358, -8.852499993180118],
              [-37.799999999000704, -8.852499993180118],
              [-37.799999999900024, -8.853333326571715],
              [-37.796666665434202, -8.853333326571715],
              [-37.796666665434202, -8.854999993354966],
              [-37.795833332042605, -8.854999993354966],
              [-37.795833332042605, -8.856666659238897],
              [-37.796666667232842, -8.856666659238897],
              [-37.796666666333522, -8.857499993529814],
              [-37.795833332941925, -8.857499993529814],
              [-37.795833332941925, -8.858333326022148],
              [-37.795000000449591, -8.858333326022148],
              [-37.794999999550271, -8.859999993704719],
              [-37.792499998476103, -8.859999993704719],
              [-37.792499999375423, -8.860833326196996],
              [-37.786666665634073, -8.860833326196996],
              [-37.786666665634073, -8.859999993704719],
              [-37.785833330443779, -8.859999993704719],
              [-37.785833334041115, -8.859166659413745],
              [-37.782499998675974, -8.859166659413745],
              [-37.782499998675974, -8.858333325122828],
              [-37.781666667982279, -8.858333326022148],
              [-37.781666668881599, -8.855833326746563],
              [-37.780833331892723, -8.855833326746563],
              [-37.780833333691362, -8.854999993354966],
              [-37.779999998501069, -8.854999993354966],
              [-37.779999998501069, -8.854166659063992],
              [-37.779166666008791, -8.854166659063992],
              [-37.779166666908111, -8.851666657989824],
              [-37.779999998501069, -8.851666660687783],
              [-37.780000000299708, -8.849166659613616],
              [-37.779166666008791, -8.849166659613616],
              [-37.779166666008791, -8.848333325322642],
              [-37.778333332617137, -8.848333326221962],
              [-37.778333333516457, -8.845833326047114],
              [-37.77749999922554, -8.845833326047114],
              [-37.77749999922554, -8.844166659263863],
              [-37.776666666733263, -8.844166659263863],
              [-37.776666665833943, -8.843333326771585],
              [-37.772499998875787, -8.843333326771585],
              [-37.772500000674427, -8.842499993379931],
              [-37.77166666728283, -8.842499993379931],
              [-37.77166666638351, -8.84083332659668],
              [-37.770833332991856, -8.84083332659668],
              [-37.770833332991856, -8.839999993205083],
              [-37.769166668007301, -8.839999993205083],
              [-37.769166665309285, -8.84083332659668],
              [-37.763333334265894, -8.84083332659668],
              [-37.763333331567935, -8.839999993205083],
              [-37.760833334990366, -8.839999993205083],
              [-37.760833331393087, -8.83916665801479],
              [-37.759166666408476, -8.83916665891411],
              [-37.759166665509156, -8.839999993205083],
              [-37.758333333016878, -8.839999993205083],
              [-37.758333331218239, -8.83916665891411],
              [-37.756666668032267, -8.83916665801479],
              [-37.756666665334308, -8.837499993030235],
              [-37.755833332841974, -8.837499993929555],
              [-37.755833331942654, -8.836666659638581],
              [-37.754166666958042, -8.836666660537901],
              [-37.754166666958042, -8.835833327146304],
              [-37.751666664984555, -8.835833326246984],
              [-37.751666665883874, -8.836666659638581],
              [-37.749166665709026, -8.836666659638581],
              [-37.749166666608346, -8.835833326246984],
              [-37.748333332317372, -8.835833326246984],
              [-37.748333331418053, -8.834166659463733],
              [-37.747500000724415, -8.834166659463733],
              [-37.747499999825095, -8.83499999375465],
              [-37.746666665534121, -8.83499999195601],
              [-37.746666667332761, -8.834166658564413],
              [-37.745833333041844, -8.834166660363053],
              [-37.744166665359273, -8.834166659463733],
              [-37.744166665359273, -8.833333326072079],
              [-37.740833330893452, -8.833333325172759],
              [-37.740833331792771, -8.831666659288828],
              [-37.73916666770748, -8.831666659288828],
              [-37.73916666590884, -8.830833325897231],
              [-37.738333331617923, -8.830833325897231],
              [-37.738333331617923, -8.829999994304274],
              [-37.735833333241715, -8.829999993404897],
              [-37.735833332342395, -8.82916665911398],
              [-37.734166665559144, -8.82916665911398],
              [-37.734166666458464, -8.829999994304274],
              [-37.732499999675213, -8.829999993404897],
              [-37.732499998775893, -8.830833325897231],
              [-37.731666665384239, -8.830833325897231],
              [-37.731666665384239, -8.829999993404897],
              [-37.729999998600988, -8.829999993404897],
              [-37.729999999500308, -8.82916665911398],
              [-37.72749999842614, -8.82916665911398],
              [-37.72749999932546, -8.827499993230049],
              [-37.725833331642889, -8.827499992330729],
              [-37.725833333441528, -8.826666660737772],
              [-37.722499998975707, -8.826666658939132],
              [-37.722499999875026, -8.825833326446798],
              [-37.72166666558411, -8.825833326446798],
              [-37.721666667382749, -8.824999993055201],
              [-37.716666667032996, -8.824999993055201],
              [-37.716666667032996, -8.825833326446798],
              [-37.715833333641399, -8.825833327346118],
              [-37.715833333641399, -8.826666658939132],
              [-37.714999998451106, -8.826666658939132],
              [-37.714999998451106, -8.827499993230049],
              [-37.713333332567174, -8.827499994129369],
              [-37.713333331667855, -8.826666658939132],
              [-37.712500000974217, -8.826666660737772],
              [-37.712499998276257, -8.828333326621703],
              [-37.7116666666833, -8.828333326621703],
              [-37.71166666578398, -8.831666659288828],
              [-37.712500000074897, -8.831666659288828],
              [-37.712499998276257, -8.832499993579802],
              [-37.71166666578398, -8.832499993579802],
              [-37.71166666578398, -8.838333326421832],
              [-37.71166666578398, -8.839999993205083],
              [-37.710833333291646, -8.839999993205083],
              [-37.710833333291646, -8.840833327496],
              [-37.709999999000729, -8.84083332659668],
              [-37.709999999000729, -8.842499993379931],
              [-37.710833331493006, -8.842499993379931],
              [-37.710833331493006, -8.846666659438711],
              [-37.709999999900049, -8.846666659438711],
              [-37.709999999000729, -8.847499994629004],
              [-37.709166665609075, -8.847499993729684],
              [-37.709166667407715, -8.850833326396867],
              [-37.709999999900049, -8.850833326396867],
              [-37.709999999000729, -8.853333326571715],
              [-37.709166666508395, -8.853333326571715],
              [-37.709166666508395, -8.857499993529814],
              [-37.708333331318158, -8.857499993529814],
              [-37.708333331318158, -8.859999993704719],
              [-37.707499999725144, -8.859999993704719],
              [-37.707499999725144, -8.861666658689273],
              [-37.706666665434227, -8.86166665958865],
              [-37.706666666333547, -8.862499992980247],
              [-37.705833332042573, -8.862499992980247],
              [-37.705833332042573, -8.86166665958865],
              [-37.704999998650976, -8.86166665958865],
              [-37.704999998650976, -8.859999993704719],
              [-37.705833332042573, -8.859999993704719],
              [-37.705833332042573, -8.858333326022148],
              [-37.706666665434227, -8.858333326022148],
              [-37.706666667232867, -8.855833326746563],
              [-37.705833333841213, -8.855833327645882],
              [-37.705833332941893, -8.854999993354966],
              [-37.704999998650976, -8.854999994254285],
              [-37.704999999550296, -8.852499993180118],
              [-37.705833332941893, -8.852499993180118],
              [-37.705833332042573, -8.850833326396867],
              [-37.704999999550296, -8.850833327296186],
              [-37.704999999550296, -8.849999993005213],
              [-37.703333331867725, -8.849999993005213],
              [-37.703333331867725, -8.849166659613616],
              [-37.702500000274767, -8.849166660512935],
              [-37.702499999375448, -8.848333327121281],
              [-37.700833332592197, -8.848333326221962],
              [-37.700833331692877, -8.846666659438711],
              [-37.700000000999182, -8.846666659438711],
              [-37.699999998301223, -8.845833326047114],
              [-37.699166666708265, -8.845833326946433],
              [-37.699166665808946, -8.843333326771585],
              [-37.697500000824334, -8.843333326771585],
              [-37.697499999925014, -8.844999992655517],
              [-37.696666666533417, -8.844999992655517],
              [-37.696666666533417, -8.846666659438711],
              [-37.695833331343124, -8.84666666033803],
              [-37.695833331343124, -8.847499991931045],
              [-37.694999998850847, -8.847499993729684],
              [-37.694999997951527, -8.848333326221962],
              [-37.693333333866235, -8.848333326221962],
              [-37.693333332966915, -8.849166659613616],
              [-37.691666667982304, -8.849166659613616],
              [-37.691666667082984, -8.849999993005213],
              [-37.688333333516482, -8.849999993005213],
              [-37.688333332617162, -8.850833326396867],
              [-37.687500001024205, -8.850833327296186],
              [-37.687499999225565, -8.851666657989824],
              [-37.686666666733231, -8.851666657989824],
              [-37.686666667632551, -8.853333326571715],
              [-37.685833332442314, -8.853333326571715],
              [-37.685833332442314, -8.854166659063992],
              [-37.68499999905066, -8.854166659063992],
              [-37.68499999994998, -8.855833326746563],
              [-37.684166665659063, -8.855833326746563],
              [-37.684166665659063, -8.858333326022148],
              [-37.682499999775132, -8.858333326022148],
              [-37.682500000674452, -8.859166659413745],
              [-37.681666665484158, -8.859166659413745],
              [-37.681666667282798, -8.860833327096316],
              [-37.680833332991881, -8.860833327096316],
              [-37.680833332092561, -8.859999993704719],
              [-37.679999997801588, -8.859999993704719],
              [-37.679999998700964, -8.859166660313065],
              [-37.679166668007269, -8.859166659413745],
              [-37.679166668007269, -8.857499993529814],
              [-37.678333332817033, -8.857499993529814],
              [-37.678333333716353, -8.855833326746563],
              [-37.677499998526059, -8.855833326746563],
              [-37.677499999425379, -8.854999993354966],
              [-37.678333331917713, -8.854999993354966],
              [-37.678333331917713, -8.854166659063992],
              [-37.67916666620863, -8.854166659063992],
              [-37.679166668007269, -8.850833326396867],
              [-37.678333333716353, -8.850833326396867],
              [-37.678333331917713, -8.849999993005213],
              [-37.677500000324699, -8.849999993904532],
              [-37.677499998526059, -8.849166659613616],
              [-37.676666666933102, -8.849166659613616],
              [-37.676666666033782, -8.848333326221962],
              [-37.675833333541448, -8.848333327121281],
              [-37.675833333541448, -8.847499993729684],
              [-37.674999999250531, -8.847499993729684],
              [-37.674999999250531, -8.846666659438711],
              [-37.674166665858877, -8.84666666033803],
              [-37.674166667657516, -8.845833326047114],
              [-37.67333333246728, -8.845833326946433],
              [-37.6733333333666, -8.84083332659668],
              [-37.671666665684029, -8.84083332659668],
              [-37.671666665684029, -8.841666658189695],
              [-37.670833331393112, -8.841666659089015],
              [-37.670833331393112, -8.842499993379931],
              [-37.66916666820714, -8.842499993379931],
              [-37.669166665509181, -8.844166659263863],
              [-37.668333333016847, -8.844166659263863],
              [-37.668333333916166, -8.844999993554836],
              [-37.66749999872593, -8.844999993554836],
              [-37.667500000524569, -8.845833326047114],
              [-37.666666668032235, -8.845833326047114],
              [-37.666666665334276, -8.846666659438711],
              [-37.664166666958067, -8.846666659438711],
              [-37.664166666058748, -8.847499991931045],
              [-37.66333333266715, -8.847499993729684],
              [-37.66333333266715, -8.849166659613616],
              [-37.662499998376177, -8.849166659613616],
              [-37.662500001074136, -8.849999993005213],
              [-37.661666665883899, -8.849999993005213],
              [-37.661666668581859, -8.852499993180118],
              [-37.654166666258618, -8.852499993180118],
              [-37.654166665359298, -8.851666658889144],
              [-37.650833333591436, -8.851666658889144],
              [-37.650833331792796, -8.850833326396867],
              [-37.648333332517211, -8.850833326396867],
              [-37.648333332517211, -8.849999993005213],
              [-37.644999998950766, -8.849999993005213],
              [-37.644999998950766, -8.850833326396867],
              [-37.644166667357752, -8.850833326396867],
              [-37.644166665559112, -8.851666658889144],
              [-37.643333333066835, -8.851666657989824],
              [-37.643333331268195, -8.853333326571715],
              [-37.642499998775861, -8.853333326571715],
              [-37.642499997876541, -8.854166658164672],
              [-37.643333333966154, -8.854166659063992],
              [-37.643333331268195, -8.854999993354966],
              [-37.644166666458432, -8.854999993354966],
              [-37.644166666458432, -8.855833326746563],
              [-37.645833331443043, -8.855833326746563],
              [-37.645833331443043, -8.858333326022148],
              [-37.645000000749405, -8.858333326022148],
              [-37.644999998051446, -8.859166659413745],
              [-37.644166667357752, -8.859166659413745],
              [-37.644166666458432, -8.859999993704719],
              [-37.643333333066835, -8.859999993704719],
              [-37.643333331268195, -8.86166665958865],
              [-37.642499999675181, -8.86166665958865],
              [-37.642499999675181, -8.864166659763498],
              [-37.64083333289193, -8.864166659763498],
              [-37.64083333199261, -8.864999993155095],
              [-37.639166667907375, -8.864999994054415],
              [-37.639166667907375, -8.866666659938346],
              [-37.638333332717082, -8.866666659039026],
              [-37.638333333616401, -8.86749999333],
              [-37.637500000224804, -8.86749999422932],
              [-37.637499999325485, -8.868333326721597],
              [-37.63666666683315, -8.868333326721597],
              [-37.63666666683315, -8.869166659213931],
              [-37.635833333441553, -8.869166659213931],
              [-37.635833331642914, -8.872499991881057],
              [-37.635000000949219, -8.872499993679696],
              [-37.63499999825126, -8.87333332617203],
              [-37.634166665758983, -8.87333332617203],
              [-37.634166665758983, -8.874999992955281],
              [-37.633333333266648, -8.874999992955281],
              [-37.633333331468009, -8.876666659738532],
              [-37.63083333399112, -8.876666659738532],
              [-37.630833330393841, -8.878333327421103],
              [-37.629999998800884, -8.878333325622407],
              [-37.629999999700203, -8.879166659014061],
              [-37.629166666308549, -8.879166659014061],
              [-37.629166666308549, -8.879999993304978],
              [-37.628333332916952, -8.879999993304978],
              [-37.628333332916952, -8.880833326696632],
              [-37.627500000424618, -8.880833326696632],
              [-37.627500000424618, -8.881666659188909],
              [-37.626666667033021, -8.881666659188909],
              [-37.626666667033021, -8.882499993479883],
              [-37.625833332742047, -8.882499993479883],
              [-37.625833331842728, -8.884999993654731],
              [-37.625833332742047, -8.886666659538662],
              [-37.626666665234382, -8.886666658639342],
              [-37.626666665234382, -8.888333326321913],
              [-37.627499999525298, -8.888333326321913],
              [-37.627499998625979, -8.88916665971351],
              [-37.628333333816272, -8.88916665971351],
              [-37.628333332916952, -8.889999993105164],
              [-37.62916666540923, -8.889999993105164],
              [-37.629166666308549, -8.891666659888415],
              [-37.629999998800884, -8.891666658989095],
              [-37.629999999700203, -8.895833325947194],
              [-37.6308333330918, -8.895833325947194],
              [-37.630833331293161, -8.897499993629765],
              [-37.631666667382774, -8.897499994529085],
              [-37.631666665584135, -8.899166659513696],
              [-37.632500000774371, -8.899166660413016],
              [-37.632499999875051, -8.900833325397628],
              [-37.632499999875051, -8.901666659688544],
              [-37.631666665584135, -8.901666659688544],
              [-37.631666665584135, -8.903333325572476],
              [-37.630833331293161, -8.903333325572476],
              [-37.63083333399112, -8.904166658964073],
              [-37.629999999700203, -8.904166658964073],
              [-37.629999998800884, -8.904999993255046],
              [-37.629166666308549, -8.904999992355727],
              [-37.629166666308549, -8.906666659138978],
              [-37.627499999525298, -8.906666659138978],
              [-37.627499999525298, -8.907499992530575],
              [-37.624166667757493, -8.907499994329214],
              [-37.624166667757493, -8.906666659138978],
              [-37.622500000974242, -8.906666659138978],
              [-37.622500000074922, -8.905833327545963],
              [-37.620833331493031, -8.905833326646643],
              [-37.620833330593712, -8.904999993255046],
              [-37.61916666650842, -8.904999993255046],
              [-37.6191666656091, -8.904166658064753],
              [-37.618333334016086, -8.904166658964073],
              [-37.618333332217446, -8.903333326471795],
              [-37.616666665434195, -8.903333326471795],
              [-37.616666666333515, -8.904166660762712],
              [-37.615833332941918, -8.904166658964073],
              [-37.615833332042598, -8.906666659138978],
              [-37.614999998651001, -8.906666660038297],
              [-37.615000000449641, -8.907499993429894],
              [-37.614166667957306, -8.907499993429894],
              [-37.614166667057987, -8.908333325922229],
              [-37.614166667057987, -8.909166659313826],
              [-37.614999998651001, -8.909166659313826],
              [-37.614999998651001, -8.909999993604799],
              [-37.616666667232892, -8.909999993604799],
              [-37.616666666333515, -8.914999993055176],
              [-37.617499998825849, -8.914999993055176],
              [-37.617499999725169, -8.917499992330761],
              [-37.616666665434195, -8.917499992330761],
              [-37.616666666333515, -8.919166659113955],
              [-37.615833333841238, -8.919166660013275],
              [-37.615833332042598, -8.919999993404929],
              [-37.614999998651001, -8.919999993404929],
              [-37.615000000449641, -8.925833327146279],
              [-37.614166667957306, -8.925833326246959],
              [-37.614166667057987, -8.926666659638613],
              [-37.612499998476096, -8.926666659638613],
              [-37.612500000274736, -8.92749999392953],
              [-37.611666667782458, -8.92749999392953],
              [-37.611666666883139, -8.929166658914141],
              [-37.612499999375416, -8.929166658914141],
              [-37.612499999375416, -8.930833326596712],
              [-37.61333333366639, -8.930833326596712],
              [-37.61333333366639, -8.931666659088989],
              [-37.614166667057987, -8.931666659088989],
              [-37.614166665259347, -8.932499994279283],
              [-37.614999999550321, -8.932499993379963],
              [-37.615000000449641, -8.934166659263894],
              [-37.615833333841238, -8.934166659263894],
              [-37.615833332941918, -8.934999993554811],
              [-37.616666665434195, -8.934999993554811],
              [-37.616666666333515, -8.936666659438742],
              [-37.617499999725169, -8.936666659438742],
              [-37.617499999725169, -8.937499993729659],
              [-37.618333331318127, -8.93749999193102],
              [-37.618333332217446, -8.938333326221994],
              [-37.619999999900017, -8.938333327121313],
              [-37.619999999000697, -8.940833326396842],
              [-37.620833332392351, -8.940833325497522],
              [-37.620833332392351, -8.941666658889176],
              [-37.625833332742047, -8.941666658889176],
              [-37.625833331842728, -8.942499993180093],
              [-37.628333332916952, -8.942499992280773],
              [-37.628333330218993, -8.94333332657169],
              [-37.62916666540923, -8.94333332567237],
              [-37.629166666308549, -8.944166659064024],
              [-37.629999999700203, -8.944166658164704],
              [-37.629999999700203, -8.945833326746595],
              [-37.63083333399112, -8.945833326746595],
              [-37.630833331293161, -8.947499993529846],
              [-37.631666667382774, -8.947499993529846],
              [-37.631666666483454, -8.949999991906054],
              [-37.629166667207869, -8.949999994604013],
              [-37.62916666540923, -8.950833327096348],
              [-37.627499999525298, -8.950833326197028],
              [-37.627499999525298, -8.951666659588625],
              [-37.624999998451131, -8.951666659588625],
              [-37.624999998451131, -8.959166659213906],
              [-37.624166665958796, -8.959166659213906],
              [-37.624166665958796, -8.959999993504823],
              [-37.622500000074922, -8.959999993504823],
              [-37.622499998276282, -8.961666659388754],
              [-37.621666664884629, -8.961666659388754],
              [-37.621666666683268, -8.962499993679728],
              [-37.622500000974242, -8.962499993679728],
              [-37.622500000074922, -8.963333326172005],
              [-37.62333333166788, -8.963333326172005],
              [-37.623333332567199, -8.964166659563659],
              [-37.627500000424618, -8.964166660462979],
              [-37.627499998625979, -8.964999992955256],
              [-37.629166666308549, -8.964999992955256],
              [-37.629166667207869, -8.966666659738507],
              [-37.630000000599523, -8.966666660637827],
              [-37.629999999700203, -8.967499993130104],
              [-37.631666665584135, -8.967499994029481],
              [-37.631666665584135, -8.966666659738507],
              [-37.633333332367329, -8.966666659738507],
              [-37.633333333266648, -8.96583332634691],
              [-37.634166667557622, -8.96583332634691],
              [-37.634166666658302, -8.964999992955256],
              [-37.63666666683315, -8.964999992955256],
              [-37.636666665933831, -8.964166659563659],
              [-37.638333333616401, -8.964166659563659],
              [-37.638333333616401, -8.963333325272686],
              [-37.639166667907375, -8.963333326172005],
              [-37.639166667008055, -8.962499993679728],
              [-37.643333331268195, -8.962499993679728],
              [-37.643333333066835, -8.961666659388754],
              [-37.644166666458432, -8.961666659388754],
              [-37.644166666458432, -8.960833325097838],
              [-37.646666666633337, -8.960833325997157],
              [-37.646666667532656, -8.959999993504823],
              [-37.647500000024934, -8.959999993504823],
              [-37.647500000024934, -8.960833325997157],
              [-37.652499998576047, -8.960833325997157],
              [-37.652499999475367, -8.959999993504823],
              [-37.656666667332786, -8.959999993504823],
              [-37.656666666433466, -8.960833325997157],
              [-37.657499998925744, -8.960833325997157],
              [-37.657499998925744, -8.961666659388754],
              [-37.658333333216717, -8.961666659388754],
              [-37.658333332317397, -8.962499993679728],
              [-37.660833331592926, -8.962499993679728],
              [-37.660833331592926, -8.963333326172005],
              [-37.661666667682539, -8.963333326172005],
              [-37.661666666783219, -8.964166659563659],
              [-37.661666667682539, -8.967499993130104],
              [-37.662500001074136, -8.967499993130104],
              [-37.662499998376177, -8.968333326521758],
              [-37.663333331767831, -8.968333326521758],
              [-37.66333333266715, -8.970833326696606],
              [-37.664166666958067, -8.970833326696606],
              [-37.664166666958067, -8.971666659188941],
              [-37.664999999450345, -8.971666659188941],
              [-37.664999998551025, -8.973333325972192],
              [-37.666666666233596, -8.973333325972192],
              [-37.666666668032235, -8.974166659363789],
              [-37.665833331942679, -8.974166659363789],
              [-37.665833331043359, -8.97583332524772],
              [-37.666666665334276, -8.97583332614704],
              [-37.666666666233596, -8.976666659538637],
              [-37.667500000524569, -8.976666659538637],
              [-37.667500000524569, -8.979166659713542],
              [-37.666666665334276, -8.979166659713542],
              [-37.666666666233596, -8.979999993105139],
              [-37.664999999450345, -8.979999993105139],
              [-37.664999999450345, -8.980833326496793],
              [-37.664166667857387, -8.980833326496793],
              [-37.664166667857387, -8.98166665898907],
              [-37.66333333266715, -8.98166665808975],
              [-37.663333331767831, -8.982499993280044],
              [-37.661666665883899, -8.982499993280044],
              [-37.661666665883899, -8.983333327570961],
              [-37.660833333391565, -8.983333325772321],
              [-37.660833332492246, -8.984999993454892],
              [-37.659166667507634, -8.984999993454892],
              [-37.659166664809675, -8.985833325047849],
              [-37.659166664866284, -8.985868297275509],
              [-37.659259482, -8.985874484999947],
            ],
          ],
          [
            [
              [-37.824066276999901, -8.893979738999972],
              [-37.823467478999902, -8.895535514999953],
              [-37.823174825, -8.896036509999925],
              [-37.822960291, -8.896761523999936],
              [-37.822434972999901, -8.896877614999935],
              [-37.822338375999898, -8.896877626999974],
              [-37.8223151239999, -8.896924130999926],
              [-37.822290106, -8.896974166999943],
              [-37.822290106, -8.897070706999953],
              [-37.822290106, -8.897215516999941],
              [-37.822048756, -8.897360326999939],
              [-37.822000486, -8.897505136999941],
              [-37.821903946, -8.897649946999932],
              [-37.821807405999898, -8.897649946999923],
              [-37.821807405999898, -8.898036106999934],
              [-37.821710865999897, -8.898084376999973],
              [-37.821662595, -8.898132646999953],
              [-37.821566054999899, -8.89822918699997],
              [-37.821517785, -8.898229186999966],
              [-37.821324704999903, -8.898277456999946],
              [-37.821228165, -8.898373996999966],
              [-37.821208997, -8.898450672999958],
              [-37.821179894999901, -8.898567076999939],
              [-37.821083355, -8.898711886999923],
              [-37.821083355, -8.898808427999944],
              [-37.820986815, -8.898953237999939],
              [-37.820890275, -8.899098047999924],
              [-37.820890275, -8.899194587999935],
              [-37.820745465, -8.899242857999978],
              [-37.820745465, -8.899339397999944],
              [-37.820745465, -8.899532477999959],
              [-37.820504114999899, -8.899677287999966],
              [-37.820504114999899, -8.899918637999971],
              [-37.820359305, -8.900208257999953],
              [-37.820359305, -8.900497877999943],
              [-37.820359305, -8.900690957999922],
              [-37.820359305, -8.901028847999942],
              [-37.820407574999898, -8.901318467999937],
              [-37.820407574999898, -8.901463277999934],
              [-37.820359305, -8.901463277999946],
              [-37.820359305, -8.901656357999977],
              [-37.820455845, -8.901801167999965],
              [-37.820552385, -8.90194597799996],
              [-37.820600655, -8.902090787999942],
              [-37.820697195, -8.902187327999972],
              [-37.820697195, -8.902235597999946],
              [-37.820697195, -8.902283867999923],
              [-37.820853570999901, -8.902508458999925],
              [-37.820938544999898, -8.902621757999951],
              [-37.820938544999898, -8.902863107999956],
              [-37.820938544999898, -8.903007917999954],
              [-37.820938544999898, -8.903152727999949],
              [-37.820890275, -8.903490617999974],
              [-37.820793734999903, -8.903780237999969],
              [-37.820793734999903, -8.904021587999985],
              [-37.820793734999903, -8.904359477999954],
              [-37.820793734999903, -8.904552557999976],
              [-37.820648924999901, -8.904745637999957],
              [-37.820600655, -8.90498698899995],
              [-37.820600655, -8.905373148999956],
              [-37.820552385, -8.905517958999948],
              [-37.820552385, -8.905614498999974],
              [-37.820648924999901, -8.90585584899998],
              [-37.820648924999901, -8.906145468999974],
              [-37.820648924999901, -8.906483358999942],
              [-37.820648924999901, -8.906676438999977],
              [-37.820552385, -8.906821248999952],
              [-37.820552385, -8.907014328999944],
              [-37.820455845, -8.90720740899997],
              [-37.820359305, -8.907497028999959],
              [-37.820359305, -8.907738378999962],
              [-37.820359305, -8.907979728999974],
              [-37.820359305, -8.908269348999971],
              [-37.820359305, -8.908462428999949],
              [-37.820359305, -8.908703778999955],
              [-37.820455845, -8.908896858999974],
              [-37.820600655, -8.908993398999948],
              [-37.820697195, -8.909041668999981],
              [-37.820793734999903, -8.909089938999953],
              [-37.820890275, -8.909331288999978],
              [-37.820986815, -8.909524368999932],
              [-37.821131625, -8.909813988999931],
              [-37.821324704999903, -8.910151878999949],
              [-37.821759136, -8.910538038999961],
              [-37.821903946, -8.910682848999954],
              [-37.822048756, -8.910779388999968],
              [-37.822290106, -8.910972468999939],
              [-37.822358182, -8.911074583999946],
              [-37.822386646, -8.911117278999933],
              [-37.822483185999907, -8.911262088999937],
              [-37.822579726, -8.911406898999935],
              [-37.822772805999897, -8.911648249999935],
              [-37.822869345999898, -8.91174478999994],
              [-37.822917616, -8.911841329999962],
              [-37.823207236, -8.912082679999971],
              [-37.823255506, -8.912179219999924],
              [-37.823303775999896, -8.912275759999943],
              [-37.823379307, -8.912401644999926],
              [-37.823448586, -8.91251710999995],
              [-37.823641666, -8.912565379999922],
              [-37.823738206, -8.912758459999965],
              [-37.823883016, -8.912951539999939],
              [-37.823979556, -8.913096349999922],
              [-37.824076095999899, -8.913337699999941],
              [-37.824124366, -8.913434239999956],
              [-37.824317446, -8.913627319999931],
              [-37.824462255999897, -8.913675589999974],
              [-37.824592353, -8.913805686999982],
              [-37.824607065999899, -8.913820399999967],
              [-37.824751875999901, -8.913916939999972],
              [-37.824751875999901, -8.913965209999965],
              [-37.824944956, -8.914061749999968],
              [-37.825138035999906, -8.914206559999979],
              [-37.825379386, -8.914447909999977],
              [-37.825572466, -8.914592719999971],
              [-37.825813815999901, -8.914785799999954],
              [-37.825958625999903, -8.914882339999954],
              [-37.826103436, -8.91502714999995],
              [-37.826103436, -8.915171959999945],
              [-37.826151706, -8.915268499999977],
              [-37.8263447859999, -8.915413309999964],
              [-37.826441326, -8.915509849999978],
              [-37.826586135999896, -8.915558119999965],
              [-37.826779216, -8.915606389999947],
              [-37.826803351, -8.915678794999955],
              [-37.826827486, -8.915751199999928],
              [-37.826819688999898, -8.915931926999974],
              [-37.826797369, -8.916027641999962],
              [-37.826864328999903, -8.916150400999983],
              [-37.826897808999902, -8.916250839999982],
              [-37.826931289, -8.916351279999958],
              [-37.827020568, -8.916418239999937],
              [-37.827098688, -8.916485198999956],
              [-37.827154488, -8.916574478999964],
              [-37.827187966999901, -8.916708397999976],
              [-37.827210287, -8.916842316999981],
              [-37.827243767, -8.91695391699994],
              [-37.827247704999898, -8.917106533999977],
              [-37.827279748, -8.917137922999927],
              [-37.827439636, -8.917294543999954],
              [-37.827419295, -8.917482674999976],
              [-37.827906613, -8.917509261999946],
              [-37.829488547, -8.919052773999972],
              [-37.830102347, -8.921232132999933],
              [-37.829335097999902, -8.922393294999946],
              [-37.832321051, -8.924558103999969],
              [-37.833637043, -8.926411767999982],
              [-37.832143883999898, -8.928255660999987],
              [-37.826233659, -8.932716848999968],
              [-37.825333841, -8.932428260999925],
              [-37.825998289, -8.934237176999934],
              [-37.826001057, -8.934244715999965],
              [-37.827313741, -8.937819635999965],
              [-37.831515671, -8.952943198999952],
              [-37.834205079999897, -8.959841911999934],
              [-37.839098619, -8.978381217999985],
              [-37.846008388, -8.984770909999956],
              [-37.848163633, -8.988311632999968],
              [-37.848166745999897, -8.98831529199998],
              [-37.850319768, -8.991852242999931],
              [-37.851779717999896, -8.996214465999966],
              [-37.852276076, -8.997696803999936],
              [-37.853093272, -9.000139771999953],
              [-37.863802742, -9.008660457999952],
              [-37.867151925, -9.01163109599997],
              [-37.8725415769999, -9.01184130099994],
              [-37.874486357, -9.01151501799994],
              [-37.877618321999897, -9.013286444999949],
              [-37.879598101999903, -9.015413861999955],
              [-37.879458056, -9.01709015299997],
              [-37.878527978, -9.018962191999945],
              [-37.875599243999901, -9.022669776999974],
              [-37.875227587, -9.024621980999939],
              [-37.877208612999901, -9.031100830999945],
              [-37.877569374, -9.03537603199992],
              [-37.878261114999908, -9.036955960999922],
              [-37.880147205, -9.039305402999952],
              [-37.880983441999902, -9.040346788999942],
              [-37.882707706, -9.040663518999949],
              [-37.883790646, -9.040055361999986],
              [-37.885565587, -9.037292001999964],
              [-37.887706554, -9.035073891999961],
              [-37.889767741999904, -9.034794266999942],
              [-37.89137357, -9.035323816999956],
              [-37.89282351, -9.036875038999932],
              [-37.896317849, -9.037867751999942],
              [-37.900285788, -9.038279529999953],
              [-37.90493262, -9.040235850999979],
              [-37.905735766, -9.040992758999955],
              [-37.906538650999899, -9.042953224999954],
              [-37.907093577, -9.051157955999948],
              [-37.907875116999897, -9.052218406999943],
              [-37.912375923, -9.055930466999937],
              [-37.917286563999902, -9.060799781999949],
              [-37.918460169999904, -9.061414855999944],
              [-37.920291969999901, -9.063793838999961],
              [-37.929791693, -9.071279864999948],
              [-37.930638793, -9.072324524999932],
              [-37.935704036999901, -9.078571358999971],
              [-37.93767665, -9.080115110999943],
              [-37.9394469759999, -9.083917287999949],
              [-37.942544423999898, -9.088116363999962],
              [-37.942731239999901, -9.089178711999978],
              [-37.942128516, -9.090232855999936],
              [-37.942165641, -9.090365860999944],
              [-37.942684336999903, -9.092218947999985],
              [-37.944563709, -9.092732617999962],
              [-37.947767718, -9.095769406999983],
              [-37.949222907, -9.096157871999948],
              [-37.950644467, -9.096537285999938],
              [-37.951596247999902, -9.097309474999971],
              [-37.951640825, -9.097426371999962],
              [-37.951685299, -9.097542439999936],
              [-37.951952503, -9.098241511999959],
              [-37.951764039, -9.099783857999963],
              [-37.953374051, -9.103374651999964],
              [-37.953267039, -9.106130942999936],
              [-37.954303708999902, -9.109525337999967],
              [-37.954850037, -9.111314602999936],
              [-37.957568223, -9.115629754999929],
              [-37.961582402, -9.120129921999924],
              [-37.962337331999898, -9.120577673999948],
              [-37.968790068, -9.122704769999929],
              [-37.969618162, -9.123267304999965],
              [-37.971200216999897, -9.124341775999945],
              [-37.971459637999899, -9.125692536999965],
              [-37.9710387599999, -9.128274463999956],
              [-37.971758384999902, -9.131041882999966],
              [-37.975540983, -9.134942284999966],
              [-37.976202312999902, -9.136189887999924],
              [-37.977049141999899, -9.140379745999944],
              [-37.977294902, -9.141595692999948],
              [-37.978038548, -9.143231917999973],
              [-37.978138101, -9.146093209999943],
              [-37.978562914, -9.147661992999927],
              [-37.979351312999903, -9.148363014999978],
              [-37.981340068999899, -9.149289671999952],
              [-37.9846521649999, -9.149789763999923],
              [-37.985514221, -9.151623727999946],
              [-37.987540247, -9.153685917999944],
              [-37.990986232, -9.15537622399995],
              [-37.993658389, -9.155333098999954],
              [-37.999482273999902, -9.152243597999934],
              [-38.005374714, -9.154436191999924],
              [-38.006653602, -9.154153239999969],
              [-38.009043501999898, -9.154865074999973],
              [-38.011012514999898, -9.156027893999978],
              [-38.012107247, -9.156043089999963],
              [-38.014925682999902, -9.156081945999963],
              [-38.020783617999903, -9.155215182999948],
              [-38.025787419, -9.155506332999945],
              [-38.027343227, -9.156573875999987],
              [-38.029211185, -9.158721698999946],
              [-38.029510549, -9.164513208999956],
              [-38.030292030999902, -9.164948102999968],
              [-38.032154816999899, -9.164860982999933],
              [-38.044541211, -9.168815417999953],
              [-38.046176761, -9.168908525999949],
              [-38.0527450229999, -9.167818934999957],
              [-38.053504405999902, -9.167693246999926],
              [-38.056981037, -9.167625641999951],
              [-38.058787201, -9.169275858999985],
              [-38.060537197, -9.171517170999946],
              [-38.063904519999902, -9.173780517999933],
              [-38.066097989999903, -9.174219911999963],
              [-38.0749286839999, -9.17131715899996],
              [-38.079262904, -9.170533215999962],
              [-38.082566583, -9.170585292999931],
              [-38.092287331, -9.172724977999957],
              [-38.095077047, -9.174980489999944],
              [-38.095156319999901, -9.175044683999943],
              [-38.097817393, -9.178699481999953],
              [-38.098678548999899, -9.179307009999958],
              [-38.099119269, -9.179408843999969],
              [-38.102578765, -9.180207653999965],
              [-38.10273774599991, -9.180537821999962],
              [-38.102880020678015, -9.180833326097058],
              [-38.103333331967633, -9.180833326097058],
              [-38.103333331967633, -9.179166659313807],
              [-38.104166665359287, -9.179166659313807],
              [-38.104166665359287, -9.180833325197739],
              [-38.104999999650204, -9.180833326097058],
              [-38.104999999650204, -9.181666659488712],
              [-38.105833331243218, -9.181666658589393],
              [-38.105833331243218, -9.180833326996378],
              [-38.108333332317386, -9.180833326097058],
              [-38.108333335015345, -9.179999993604781],
              [-38.10916666660836, -9.179999994504101],
              [-38.10916666570904, -9.180833325197739],
              [-38.112499999275485, -9.180833326097058],
              [-38.112500001074125, -9.179999993604781],
              [-38.113333332667139, -9.179999993604781],
              [-38.113333333566459, -9.179166659313807],
              [-38.114166666958056, -9.179166659313807],
              [-38.114166666958056, -9.17833332592221],
              [-38.117499998725918, -9.17833332592221],
              [-38.122500000874311, -9.17833332592221],
              [-38.122499999075671, -9.179166659313807],
              [-38.128333333716341, -9.179166659313807],
              [-38.128333332817022, -9.178333325022891],
              [-38.129166666208619, -9.17833332772085],
              [-38.129166666208619, -9.179166658414488],
              [-38.132499999775121, -9.179166659313807],
              [-38.132499998875801, -9.179999993604781],
              [-38.137499998326234, -9.179999993604781],
              [-38.137499999225554, -9.180833326097058],
              [-38.139166666908125, -9.180833326097058],
              [-38.139166666008805, -9.181666659488712],
              [-38.139166666908125, -9.18416665966356],
              [-38.138333333516471, -9.18416665966356],
              [-38.138333332617151, -9.184999993954534],
              [-38.137499999225554, -9.184999993954534],
              [-38.137499998326234, -9.189999993404911],
              [-38.138333333516471, -9.18999999430423],
              [-38.138333333516471, -9.193333326072093],
              [-38.139166666008805, -9.193333326072093],
              [-38.139166666908125, -9.19416665946369],
              [-38.139999999400402, -9.19416665946369],
              [-38.139999998501082, -9.194999993754664],
              [-38.140833331892736, -9.194999993754664],
              [-38.140833331892736, -9.195833326246941],
              [-38.142500000474627, -9.195833326246941],
              [-38.14249999957525, -9.196666659638595],
              [-38.144166666358501, -9.196666659638595],
              [-38.144166666358501, -9.197499993929512],
              [-38.145833330443793, -9.197499993030192],
              [-38.145833334041072, -9.198333326421846],
              [-38.146666666533406, -9.198333326421846],
              [-38.146666667432726, -9.199166658914123],
              [-38.150833332592185, -9.199166658914123],
              [-38.150833332592185, -9.199999994104417],
              [-38.153333332767033, -9.199999993205097],
              [-38.153333330968394, -9.200833326596694],
              [-38.154166667058007, -9.200833326596694],
              [-38.154166667058007, -9.201666659088971],
              [-38.154999999550284, -9.201666659088971],
              [-38.154999998650965, -9.200833325697374],
              [-38.158333331318147, -9.200833326596694],
              [-38.158333332217467, -9.201666659088971],
              [-38.159166665609064, -9.201666659088971],
              [-38.159166666508384, -9.202499993379945],
              [-38.159999999900037, -9.202499993379945],
              [-38.159999999000718, -9.203333326771542],
              [-38.161666666683288, -9.203333326771542],
              [-38.161666667582608, -9.204166659263876],
              [-38.162499998276246, -9.204166658364556],
              [-38.162500000074886, -9.204999993554793],
              [-38.16500000024979, -9.204999994454113],
              [-38.16500000204843, -9.205833326047127],
              [-38.166666667033041, -9.205833326047127],
              [-38.166666667033041, -9.204999993554793],
              [-38.167499999525319, -9.204999993554793],
              [-38.167499998625999, -9.204166659263876],
              [-38.168333333816236, -9.204166659263876],
              [-38.168333332916916, -9.203333326771542],
              [-38.16916666720789, -9.203333326771542],
              [-38.17500000094924, -9.203333326771542],
              [-38.17500000004992, -9.204166659263876],
              [-38.176666665933851, -9.204166658364556],
              [-38.176666665933851, -9.204999993554793],
              [-38.17916666431006, -9.204999993554793],
              [-38.179166667907339, -9.204166659263876],
              [-38.180833331992631, -9.204166658364556],
              [-38.18083333289195, -9.205833326047127],
              [-38.181666666283604, -9.205833326047127],
              [-38.182499998775882, -9.205833326047127],
              [-38.182499998775882, -9.206666659438724],
              [-38.184166666458452, -9.206666658539405],
              [-38.184166666458452, -9.205833326946447],
              [-38.189999998401163, -9.205833326047127],
              [-38.189999998401163, -9.206666659438724],
              [-38.19083333179276, -9.206666659438724],
              [-38.19083333269208, -9.209166659613629],
              [-38.191666666083734, -9.209166659613629],
              [-38.191666666983053, -9.207499993729698],
              [-38.193333333766304, -9.207499993729698],
              [-38.193333332866985, -9.206666659438724],
              [-38.195000000549555, -9.206666659438724],
              [-38.195000000549555, -9.205833326047127],
              [-38.195833331243193, -9.205833326047127],
              [-38.195833333041833, -9.204999992655473],
              [-38.194999999650236, -9.204999993554793],
              [-38.194999999650236, -9.199166658914123],
              [-38.195833333941152, -9.199166658914123],
              [-38.195833331243193, -9.198333326421846],
              [-38.196666665534167, -9.198333326421846],
              [-38.196666665534167, -9.197499993030192],
              [-38.197499998026444, -9.197499993030192],
              [-38.197499999825084, -9.196666659638595],
              [-38.200000000899252, -9.196666660537915],
              [-38.2, -9.195833326246941],
              [-38.200833331592946, -9.195833326246941],
              [-38.200833332492266, -9.194999993754664],
              [-38.203333333566434, -9.194999994653983],
              [-38.203333331767794, -9.19416665946369],
              [-38.204166667857407, -9.19416665946369],
              [-38.204166666058768, -9.193333325172773],
              [-38.206666668032256, -9.193333326072093],
              [-38.206666666233616, -9.192499993579816],
              [-38.208333331218228, -9.192499992680496],
              [-38.208333333916187, -9.191666659288842],
              [-38.209166666408464, -9.191666659288842],
              [-38.209166665509144, -9.190833325897245],
              [-38.210000000699438, -9.190833325897245],
              [-38.210000000699438, -9.189999993404911],
              [-38.212499999075646, -9.18999999520355],
              [-38.212499997277007, -9.185833326446811],
              [-38.213333330668604, -9.185833326446811],
              [-38.213333331567981, -9.18416665966356],
              [-38.214166666758217, -9.18416665966356],
              [-38.214166665858897, -9.18249999198099],
              [-38.214999998351175, -9.18249999198099],
              [-38.214999998351175, -9.181666659488712],
              [-38.218333333716316, -9.181666659488712],
              [-38.218333332816997, -9.179999993604781],
              [-38.217499999425399, -9.179999993604781],
              [-38.21749999852608, -9.179166659313807],
              [-38.216666666033746, -9.179166659313807],
              [-38.216666666033746, -9.17666665823964],
              [-38.21749999852608, -9.176666659138959],
              [-38.21749999852608, -9.175833326646682],
              [-38.217500000324719, -9.174999993255028],
              [-38.218333333716316, -9.174999993255028],
              [-38.218333331917677, -9.174166658064792],
              [-38.222500000674472, -9.174166658964111],
              [-38.222500000674472, -9.173333327371097],
              [-38.224166667457666, -9.173333326471777],
              [-38.224166667457666, -9.17249999308018],
              [-38.224999999050681, -9.17249999218086],
              [-38.22500000084932, -9.171666659688526],
              [-38.225833330643638, -9.171666659688526],
              [-38.225833331542958, -9.17249999218086],
              [-38.226666666733252, -9.17249999308018],
              [-38.226666666733252, -9.173333325572457],
              [-38.227500001024168, -9.173333327371097],
              [-38.227499998326209, -9.175833326646682],
              [-38.228333333516503, -9.175833326646682],
              [-38.22916666600878, -9.175833325747362],
              [-38.22916666600878, -9.176666659138959],
              [-38.231666667982324, -9.176666660038279],
              [-38.231666667083005, -9.175833326646682],
              [-38.235833332242464, -9.175833326646682],
              [-38.235833334041104, -9.174999993255028],
              [-38.236666666533381, -9.174999993255028],
              [-38.236666667432701, -9.1724999939795],
              [-38.237500001723674, -9.17249999308018],
              [-38.237499997227019, -9.169999992905275],
              [-38.238333333316632, -9.169999992905275],
              [-38.238333331517993, -9.169166659513678],
              [-38.237500000824355, -9.169166659513678],
              [-38.237499999025715, -9.165833325947176],
              [-38.237499999925035, -9.159166660612868],
              [-38.236666665634061, -9.159166659713549],
              [-38.236666666533381, -9.157499992930298],
              [-38.237499999925035, -9.157499992930298],
              [-38.237499999925035, -9.156666659538644],
              [-38.236666666533381, -9.156666659538644],
              [-38.23666666833202, -9.155833326147047],
              [-38.237499999025715, -9.155833326147047],
              [-38.237500000824355, -9.151666659188948],
              [-38.236666666533381, -9.151666658289628],
              [-38.236666666533381, -9.148333326521765],
              [-38.237500000824355, -9.148333326521765],
              [-38.237500000824355, -9.147499993130111],
              [-38.238333333316632, -9.147499994029431],
              [-38.238333331517993, -9.146666659738514],
              [-38.239166665808909, -9.146666659738514],
              [-38.239166665808909, -9.14583332634686],
              [-38.239999998301244, -9.14583332634686],
              [-38.239999998301244, -9.140833325997164],
              [-38.240833331692841, -9.140833325997164],
              [-38.240833331692841, -9.139166658314593],
              [-38.241666665983814, -9.139166660113233],
              [-38.241666667782454, -9.138333326721579],
              [-38.242500000274731, -9.138333326721579],
              [-38.242500002073371, -9.137499993329982],
              [-38.243333332767065, -9.137499992430662],
              [-38.243333331867746, -9.136666659039065],
              [-38.243333332767065, -9.135833326546731],
              [-38.242499999375411, -9.135833326546731],
              [-38.242499998476092, -9.133333326371883],
              [-38.243333331867746, -9.133333327271203],
              [-38.243333334565705, -9.132499992980229],
              [-38.244166667057982, -9.132499992980229],
              [-38.244166667057982, -9.133333326371883],
              [-38.244999999550316, -9.133333326371883],
              [-38.245000000449636, -9.1341666606628],
              [-38.246666665434248, -9.13416665976348],
              [-38.246666667232887, -9.134999993155134],
              [-38.248333332217442, -9.134999993155134],
              [-38.248333334016081, -9.135833326546731],
              [-38.249166666508415, -9.135833326546731],
              [-38.249166667407735, -9.136666659039065],
              [-38.249999999000693, -9.136666659039065],
              [-38.249999999900012, -9.137499993329982],
              [-38.252500000974237, -9.137499992430662],
              [-38.252500000074917, -9.138333326721579],
              [-38.253333332567195, -9.138333326721579],
              [-38.253333331667875, -9.137499993329982],
              [-38.254166666858168, -9.137499994229302],
              [-38.254166665958849, -9.136666659039065],
              [-38.254999999350446, -9.136666659039065],
              [-38.254999999350446, -9.135833326546731],
              [-38.255833333641419, -9.135833326546731],
              [-38.255833333641419, -9.136666659039065],
              [-38.256666667033016, -9.136666659039065],
              [-38.256666667932336, -9.137499993329982],
              [-38.259166665409225, -9.137499993329982],
              [-38.259166665409225, -9.138333327620899],
              [-38.26166666648345, -9.138333326721579],
              [-38.261666667382769, -9.139166659213913],
              [-38.262499998975727, -9.139166659213913],
              [-38.262499998975727, -9.143333325272692],
              [-38.263333331468004, -9.143333326172012],
              [-38.263333332367324, -9.144166659563666],
              [-38.264166665758978, -9.144166658664346],
              [-38.264166667557618, -9.144999992955263],
              [-38.265833331642909, -9.144999992955263],
              [-38.265833331642909, -9.145833325447541],
              [-38.267499997526841, -9.145833327246237],
              [-38.2675000002248, -9.146666659738514],
              [-38.269166665209411, -9.146666659738514],
              [-38.269166664310092, -9.147499993130111],
              [-38.271666666283579, -9.147499993130111],
              [-38.271666665384259, -9.148333326521765],
              [-38.27333333306683, -9.148333325622445],
              [-38.273333331268191, -9.149166659014043],
              [-38.275000000749401, -9.149166659014043],
              [-38.275000000749401, -9.149999993305016],
              [-38.275833333241678, -9.149999993305016],
              [-38.275833332342359, -9.150833326696613],
              [-38.279999998401138, -9.150833326696613],
              [-38.279999999300458, -9.151666659188948],
              [-38.284166668057253, -9.151666659188948],
              [-38.284166668057253, -9.152499994379184],
              [-38.284999998750891, -9.152499993479864],
              [-38.284999998750891, -9.153333327770838],
              [-38.286666667332781, -9.153333325972142],
              [-38.286666666433462, -9.154166660263115],
              [-38.287500000724435, -9.154166659363796],
              [-38.290000000899283, -9.154166659363796],
              [-38.289999999100644, -9.154999993654712],
              [-38.290833333391561, -9.154999993654712],
              [-38.290833332492241, -9.157499992930298],
              [-38.291666667682534, -9.157499994728937],
              [-38.291666665883895, -9.159999993105146],
              [-38.292499998376172, -9.159999993105146],
              [-38.292499998376172, -9.1608333264968],
              [-38.293333332667146, -9.16083332559748],
              [-38.293333332667146, -9.161666658089757],
              [-38.294166666058743, -9.161666658089757],
              [-38.294166666058743, -9.163333326671648],
              [-38.294999998551077, -9.163333325772328],
              [-38.294999998551077, -9.164166659163925],
              [-38.295833332841994, -9.164166659163925],
              [-38.295833331043355, -9.164999993454899],
              [-38.296666668032287, -9.164999993454899],
              [-38.296666666866734, -9.165539992814672],
              [-38.298659594, -9.163890819999944],
              [-38.301293750999911, -9.160787243999946],
              [-38.304073708, -9.15551277899992],
              [-38.3058448049999, -9.153551255999952],
              [-38.305924417, -9.153462977999936],
              [-38.311782595258627, -9.149166658626937],
              [-38.310833333891196, -9.149166658114723],
              [-38.310833332991876, -9.146666659738514],
              [-38.309999999600279, -9.146666659738514],
              [-38.30999999870096, -9.144999992955263],
              [-38.309166668007265, -9.144999992955263],
              [-38.309166665309306, -9.143333326172012],
              [-38.30999999870096, -9.143333327071332],
              [-38.30999999870096, -9.141666659388761],
              [-38.309166665309306, -9.141666659388761],
              [-38.309166668007265, -9.13999999440415],
              [-38.30999999780164, -9.13999999260551],
              [-38.310000000499599, -9.139166659213913],
              [-38.310833332092557, -9.139166659213913],
              [-38.310833332991876, -9.138333326721579],
              [-38.311666665484154, -9.138333324922939],
              [-38.31166666728285, -9.137499993329982],
              [-38.312499999775127, -9.137499993329982],
              [-38.312499998875808, -9.135833326546731],
              [-38.313333332267405, -9.135833326546731],
              [-38.313333334066044, -9.138333327620899],
              [-38.314166667457698, -9.138333327620899],
              [-38.314166667457698, -9.139166660113233],
              [-38.315833333341629, -9.139166659213913],
              [-38.31583333154299, -9.13999999350483],
              [-38.316666665833907, -9.13999999350483],
              [-38.316666666733227, -9.140833325997164],
              [-38.318709442265146, -9.140833325997164],
              [-38.319493381, -9.138229251999967],
              [-38.320068163, -9.133410313999947],
              [-38.3212952229999, -9.129902994999947],
              [-38.321012713, -9.127855181999962],
              [-38.321401830999903, -9.116880574999934],
              [-38.320736403, -9.115491167999959],
              [-38.3177315, -9.111824874999966],
              [-38.316472806, -9.108795996999968],
              [-38.314963045, -9.107237139999938],
              [-38.306123866999897, -9.10167448799996],
              [-38.304792636999899, -9.099392547999974],
              [-38.3044229569999, -9.098211150999939],
              [-38.304593062, -9.096787562999957],
              [-38.306054437999897, -9.095656826999967],
              [-38.307515813, -9.094526090999972],
              [-38.310042327, -9.093959765999953],
              [-38.314952938, -9.09409988199997],
              [-38.317761826, -9.093009223999967],
              [-38.318860936, -9.092253116999926],
              [-38.320260994, -9.091290328999953],
              [-38.323287267999902, -9.08755933799995],
              [-38.325406257999902, -9.08336465699996],
              [-38.326185462999902, -9.080956264999937],
              [-38.325866715, -9.077992612999937],
              [-38.324573062999903, -9.074245772999967],
              [-38.321042115999909, -9.070033667999958],
              [-38.316102105, -9.067533555999947],
              [-38.3124588969999, -9.066358046999937],
              [-38.303737141999903, -9.061643336999966],
              [-38.297046013999903, -9.055716742999943],
              [-38.289959274, -9.047161836999974],
              [-38.288880434, -9.040343033999934],
              [-38.289675766, -9.036334421999982],
              [-38.29113259, -9.033414332999946],
              [-38.296402488, -9.022851442999984],
              [-38.301506721, -9.015673419999933],
              [-38.302790345999902, -9.013868273999945],
              [-38.316935016999899, -9.00129419899997],
              [-38.316950316, -8.997157198999966],
              [-38.3174112519999, -8.995107458999932],
              [-38.319175043, -8.992060264999967],
              [-38.320127200999899, -8.991089435999978],
              [-38.320749647999897, -8.99074226399995],
              [-38.3235400249999, -8.990009184999961],
              [-38.331124458, -8.989900379999936],
              [-38.34023733, -8.990408375999959],
              [-38.34426759, -8.991470058999933],
              [-38.348287368999898, -8.993142389999948],
              [-38.356160718, -8.997142034999962],
              [-38.362441819, -9.000941237999941],
              [-38.376243006, -9.015922066999957],
              [-38.3858988429999, -9.024825678999955],
              [-38.396695752, -9.033041443999929],
              [-38.403515806, -9.037233121999975],
              [-38.408458245, -9.038501284999938],
              [-38.414016585999903, -9.038675159999968],
              [-38.415451415999897, -9.038569838999926],
              [-38.418404074, -9.038045585999949],
              [-38.420517186, -9.037401299999933],
              [-38.423098551, -9.036377096999956],
              [-38.430043623, -9.034495888999974],
              [-38.441162549, -9.030211530999967],
              [-38.449099651, -9.02635884699993],
              [-38.451345148, -9.024394692999977],
              [-38.456117611, -9.021621875999919],
              [-38.462405899, -9.017239716999937],
              [-38.468198473, -9.01320247199992],
              [-38.475833332122448, -9.007332960735656],
              [-38.475833331942681, -9.006666658939139],
              [-38.476666665334278, -9.006666658939139],
              [-38.476666665334278, -9.005833326446805],
              [-38.477784005017526, -9.005833326446805],
              [-38.478333332129708, -9.005411015881407],
              [-38.478333333016849, -9.004999993055208],
              [-38.478867976537543, -9.004999993055208],
              [-38.48404277, -9.001021725999978],
              [-38.485140133999899, -8.998556650999937],
              [-38.485680752999897, -8.99734237899998],
              [-38.488213007, -8.993963037999952],
              [-38.498402945, -8.984899894999963],
              [-38.50351271800001, -8.981411129999975],
              [-38.507907026999902, -8.974171701999989],
              [-38.511130959999903, -8.963627863999928],
              [-38.511871444, -8.962554207999975],
              [-38.512604035, -8.961491996999943],
              [-38.512606307999903, -8.961488700999954],
              [-38.514037953999903, -8.960042755999947],
              [-38.51404208, -8.960038587999973],
              [-38.514061881, -8.960018589999953],
              [-38.514081680999901, -8.959998591999973],
              [-38.514167223, -8.959912045999946],
              [-38.516154938999897, -8.957901003999959],
              [-38.516337499027799, -8.957499993329975],
              [-38.515833332941895, -8.957499993329975],
              [-38.515833333841215, -8.954999992255807],
              [-38.514999999550298, -8.954999993155127],
              [-38.514999999550298, -8.954166659763473],
              [-38.514166667058021, -8.954166659763473],
              [-38.514166665259324, -8.950833326197028],
              [-38.514166667058021, -8.949999991906054],
              [-38.515833332042575, -8.949999993704694],
              [-38.515833332042575, -8.949166659413777],
              [-38.516666665434229, -8.949166659413777],
              [-38.516666666333549, -8.948333326022123],
              [-38.517500000624466, -8.948333326022123],
              [-38.517499999725146, -8.947499993529846],
              [-38.519166666508397, -8.947499993529846],
              [-38.519166666508397, -8.946666659238872],
              [-38.519832844844458, -8.946666658519943],
              [-38.51990725, -8.943532637999965],
              [-38.519907281, -8.943531333999942],
              [-38.519897747, -8.94345647599993],
              [-38.519374683, -8.939349696999948],
              [-38.516798714999901, -8.933235430999956],
              [-38.513353842999898, -8.927872522999968],
              [-38.510960511, -8.925110808999928],
              [-38.505159638, -8.919976463999939],
              [-38.502046661, -8.916620727999943],
              [-38.492126207, -8.912602242999979],
              [-38.4895746239999, -8.911040746999966],
              [-38.484408093, -8.907047027999972],
              [-38.479318185, -8.901743714999954],
              [-38.476623973999899, -8.89480321299993],
              [-38.47329286, -8.889545678999985],
              [-38.469871425, -8.87468894199997],
              [-38.46980712, -8.865742274999956],
              [-38.469798349, -8.864417242999929],
              [-38.469797183, -8.864241142999965],
              [-38.469797516, -8.864239752999943],
              [-38.470792248, -8.861625256999949],
              [-38.473239759, -8.858707018999944],
              [-38.474240063, -8.856909956999973],
              [-38.475240388, -8.855113191999973],
              [-38.479834465, -8.850088899999955],
              [-38.480809824999902, -8.849326121999979],
              [-38.495600881, -8.839827217999927],
              [-38.4986584379999, -8.838157779999952],
              [-38.50243949, -8.835356244999959],
              [-38.506442444999898, -8.833235180999953],
              [-38.512406703, -8.830585949999941],
              [-38.51685308, -8.829283344999956],
              [-38.530932966, -8.826409947999926],
              [-38.541139577, -8.823486705999924],
              [-38.548214567999899, -8.82273510299996],
              [-38.55184273, -8.822629404999921],
              [-38.555067822, -8.823357782999949],
              [-38.562550944999899, -8.826346159999961],
              [-38.570428631, -8.830369085999953],
              [-38.572845387, -8.832348964999939],
              [-38.576965312, -8.837128043999943],
              [-38.580237982, -8.842712880999949],
              [-38.589950413999901, -8.856539234999964],
              [-38.592486067, -8.861535077999966],
              [-38.597297274, -8.868855340999962],
              [-38.601825947, -8.877634064999931],
              [-38.603824327, -8.880590640999966],
              [-38.606636750999897, -8.889707715999977],
              [-38.60758571400001, -8.893264037999929],
              [-38.607823442, -8.904378659999967],
              [-38.607158075, -8.910351331999953],
              [-38.60701231, -8.917507765999963],
              [-38.608938021, -8.934570917999963],
              [-38.6082880169999, -8.945391476999939],
              [-38.60833582, -8.949822148999937],
              [-38.609239816999903, -8.955120998999963],
              [-38.610276939, -8.957328344999956],
              [-38.6154342049999, -8.964057211999942],
              [-38.621791478, -8.976559361999932],
              [-38.624098519, -8.980046885999966],
              [-38.627712473, -8.983220855999953],
              [-38.630684936, -8.985167251999936],
              [-38.635376999, -8.986729250999927],
              [-38.640710322, -8.987268156999965],
              [-38.644912053, -8.986693336999961],
              [-38.653726692, -8.983900038999927],
              [-38.657597119999899, -8.982477732999941],
              [-38.663904053, -8.979118635999955],
              [-38.665756458, -8.978132037999952],
              [-38.666226289, -8.977881772999988],
              [-38.66898606, -8.976411896999929],
              [-38.672515768, -8.973975470999964],
              [-38.674382253, -8.971756770999956],
              [-38.676797173, -8.966346477999927],
              [-38.681022946, -8.961192056999957],
              [-38.689232623999899, -8.947395585999967],
              [-38.69014600500001, -8.945277011999963],
              [-38.692875436, -8.936228874999925],
              [-38.693800710999902, -8.931889167999946],
              [-38.6941689249999, -8.924670983999928],
              [-38.693628389, -8.918704380999941],
              [-38.693204814999902, -8.914022875999935],
              [-38.693123466, -8.913123142999952],
              [-38.693658807, -8.902968832999939],
              [-38.695616948999906, -8.89286711299996],
              [-38.69607078, -8.890525878999934],
              [-38.698381018, -8.881761951999975],
              [-38.698381533, -8.881760353999946],
              [-38.699104528, -8.879883608999936],
              [-38.699466175999902, -8.878945235999932],
              [-38.702374219, -8.873861926999957],
              [-38.704987119999899, -8.865409498999954],
              [-38.706543498, -8.862260616999949],
              [-38.708715285, -8.860155452999955],
              [-38.711509217, -8.85839460999995],
              [-38.711510253, -8.858393806999965],
              [-38.711511291, -8.858393003999943],
              [-38.711514986999902, -8.858390673999962],
              [-38.717096424, -8.853151762999969],
              [-38.725072739, -8.850271571999931],
              [-38.730187656999902, -8.847439933999951],
              [-38.739683651, -8.844847688999957],
              [-38.745437701, -8.838945277999926],
              [-38.750526078, -8.836342359999954],
              [-38.755109011426278, -8.832499993579802],
              [-38.748333330318872, -8.832499993579802],
              [-38.748333333016888, -8.833333326072079],
              [-38.74083333159291, -8.833333325172759],
              [-38.740833333391606, -8.834166659463733],
              [-38.740000000899272, -8.834166662161692],
              [-38.736666665534131, -8.834166659463733],
              [-38.73666666643345, -8.83499999375465],
              [-38.735833331243214, -8.83499999375465],
              [-38.735833333041853, -8.834166659463733],
              [-38.73499999875088, -8.834166659463733],
              [-38.73499999875088, -8.831666659288828],
              [-38.734166666258602, -8.831666658389508],
              [-38.734166666258602, -8.830833325897231],
              [-38.733333331068309, -8.830833325897231],
              [-38.733333332866948, -8.82916665911398],
              [-38.732499998576031, -8.82916665911398],
              [-38.732499997676712, -8.828333326621703],
              [-38.731666664285058, -8.828333326621703],
              [-38.731666667882394, -8.827499993230049],
              [-38.73083333179278, -8.827499993230049],
              [-38.73083333179278, -8.826666658939132],
              [-38.728333331617932, -8.826666658939132],
              [-38.728333331617932, -8.825833326446798],
              [-38.726666667532641, -8.825833326446798],
              [-38.726666665734001, -8.824999993055201],
              [-38.723333331268179, -8.824999993055201],
              [-38.723333333066819, -8.825833327346118],
              [-38.721666666283568, -8.825833326446798],
              [-38.721666666283568, -8.824999993055201],
              [-38.72083333379129, -8.824999993055201],
              [-38.72083333379129, -8.82333332627195],
              [-38.719999997701677, -8.82333332537263],
              [-38.719999998600997, -8.822499992880296],
              [-38.718333333616386, -8.822499992880296],
              [-38.718333333616386, -8.821666659488699],
              [-38.717500000224788, -8.821666659488699],
              [-38.717500000224788, -8.820833326097102],
              [-38.718333331817746, -8.820833326996421],
              [-38.718333333616386, -8.815833326646668],
              [-38.719166667907359, -8.815833326646668],
              [-38.7191666652094, -8.814999993255014],
              [-38.717499999325469, -8.814999993255014],
              [-38.717499998426149, -8.814166658964098],
              [-38.716666666833191, -8.814166658964098],
              [-38.716666667732511, -8.81333332647182],
              [-38.715833331642898, -8.81333332647182],
              [-38.715833334340857, -8.812499992180847],
              [-38.71500000004994, -8.812499993080166],
              [-38.714999998251301, -8.810833326296915],
              [-38.714166666658286, -8.810833325397596],
              [-38.714166667557606, -8.806666659338816],
              [-38.715833332542218, -8.806666659338816],
              [-38.715833331642898, -8.804999993454885],
              [-38.714166667557606, -8.804999993454885],
              [-38.714166665758967, -8.801666658989063],
              [-38.714999998251301, -8.801666658989063],
              [-38.714999998251301, -8.800833326496786],
              [-38.715833333441537, -8.800833326496786],
              [-38.715833331642898, -8.797499992030964],
              [-38.716666665933815, -8.797499992030964],
              [-38.716666666833191, -8.796666659538687],
              [-38.717499999325469, -8.796666660438007],
              [-38.717499998426149, -8.795833326147033],
              [-38.719166667008039, -8.795833326147033],
              [-38.7191666652094, -8.791666659188934],
              [-38.719999999500317, -8.791666659188934],
              [-38.719999998600997, -8.7908333266966],
              [-38.719166667008039, -8.7908333266966],
              [-38.7191666652094, -8.789999993305003],
              [-38.717499998426149, -8.789999993305003],
              [-38.717499998426149, -8.785833326346904],
              [-38.716666665933815, -8.785833326346904],
              [-38.716666665933815, -8.78499999295525],
              [-38.715833332542218, -8.78499999295525],
              [-38.715833331642898, -8.779999993504873],
              [-38.71500000094926, -8.779999994404193],
              [-38.71500000004994, -8.778333326721622],
              [-38.712499998975716, -8.778333324922983],
              [-38.712499999875035, -8.77499999315512],
              [-38.714166665758967, -8.77499999315512],
              [-38.714166667557606, -8.774166659763466],
              [-38.71500000094926, -8.774166659763466],
              [-38.714999998251301, -8.773333326371869],
              [-38.715833331642898, -8.773333326371869],
              [-38.715833332542218, -8.771666659588618],
              [-38.716666666833191, -8.771666659588618],
              [-38.716666667732511, -8.770833325297701],
              [-38.715833332542218, -8.770833326197021],
              [-38.715833332542218, -8.769999993704687],
              [-38.71500000004994, -8.769999993704687],
              [-38.71500000094926, -8.767499993529839],
              [-38.714166666658286, -8.767499993529839],
              [-38.714166664859647, -8.764999993354991],
              [-38.711666666483438, -8.76499999425431],
              [-38.711666665584119, -8.764166659064017],
              [-38.710833333991104, -8.764166659963337],
              [-38.710833331293145, -8.76333332657174],
              [-38.711666667382758, -8.76333332657174],
              [-38.711666666483438, -8.759999993005238],
              [-38.712500000774355, -8.759999993005238],
              [-38.712499998975716, -8.759166658714264],
              [-38.711666666483438, -8.759166659613584],
              [-38.711666666483438, -8.755833326047139],
              [-38.710833333091784, -8.755833326047139],
              [-38.710833333091784, -8.754999993554804],
              [-38.709999999700187, -8.754999993554804],
              [-38.709999999700187, -8.754166659263888],
              [-38.710833333091784, -8.754166658364568],
              [-38.710833333091784, -8.753333326771553],
              [-38.711666668282078, -8.753333326771553],
              [-38.711666665584119, -8.752499993379956],
              [-38.714166665758967, -8.752499993379956],
              [-38.714166665758967, -8.749999993205051],
              [-38.71500000094926, -8.749999993205051],
              [-38.71500000004994, -8.748333327321177],
              [-38.715833330743578, -8.748333326421857],
              [-38.715833333441537, -8.745833327146272],
              [-38.716666666833191, -8.745833326246952],
              [-38.716666665933815, -8.743333326072104],
              [-38.715833332542218, -8.743333326072104],
              [-38.715833332542218, -8.741666659288853],
              [-38.71500000004994, -8.741666658389533],
              [-38.714999998251301, -8.739999993404922],
              [-38.714166667557606, -8.739999993404922],
              [-38.714166666658286, -8.739166659114005],
              [-38.71333333236737, -8.739166659114005],
              [-38.71333333146805, -8.738333326621671],
              [-38.711666666483438, -8.738333327520991],
              [-38.711666665584119, -8.737499993230074],
              [-38.710833333991104, -8.737499993230074],
              [-38.710833331293145, -8.7366666589391],
              [-38.709166666308533, -8.7366666589391],
              [-38.709166664509894, -8.739999994304242],
              [-38.708333332017617, -8.739999993404922],
              [-38.708333332017617, -8.741666659288853],
              [-38.707500000424659, -8.741666659288853],
              [-38.70749999862602, -8.74249999357977],
              [-38.706666667033005, -8.74249999357977],
              [-38.706666667932325, -8.744166659463701],
              [-38.705833331842769, -8.744166659463701],
              [-38.705833331842769, -8.744999993754675],
              [-38.704166665958837, -8.744999993754675],
              [-38.704166667757477, -8.745833326246952],
              [-38.702499998276267, -8.745833326246952],
              [-38.702499998276267, -8.746666659638606],
              [-38.701666667582572, -8.746666659638606],
              [-38.701666665783932, -8.745833326246952],
              [-38.697500000624473, -8.745833327146272],
              [-38.697499998825833, -8.746666658739287],
              [-38.696666667232876, -8.746666659638606],
              [-38.696666667232876, -8.747499993030203],
              [-38.695833332042582, -8.747499993030203],
              [-38.695833333841222, -8.748333327321177],
              [-38.695000000449625, -8.748333325522481],
              [-38.694999999550305, -8.749166658914135],
              [-38.693333332767054, -8.749166658914135],
              [-38.693333332767054, -8.749999993205051],
              [-38.694166665259331, -8.749999993205051],
              [-38.694166667057971, -8.753333326771553],
              [-38.694999999550305, -8.753333326771553],
              [-38.694999998650985, -8.758333326221987],
              [-38.691666665983803, -8.758333326221987],
              [-38.691666666883123, -8.760833326396835],
              [-38.6924999993754, -8.760833326396835],
              [-38.692500000274777, -8.765833326746588],
              [-38.691666665983803, -8.765833326746588],
              [-38.691666665983803, -8.767499993529839],
              [-38.690833332592206, -8.767499994429159],
              [-38.690833333491526, -8.768333325122796],
              [-38.690000000099872, -8.768333325122796],
              [-38.689999998301232, -8.76916665941377],
              [-38.689166667607594, -8.76916666031309],
              [-38.687499999925024, -8.76916665941377],
              [-38.687500000824343, -8.768333326921436],
              [-38.68666666473473, -8.768333326022116],
              [-38.68666666563405, -8.76916665941377],
              [-38.685833334041092, -8.76916665941377],
              [-38.685833331343133, -8.769999993704687],
              [-38.684999999750119, -8.769999994604007],
              [-38.684999999750119, -8.770833326197021],
              [-38.681666667082993, -8.770833326197021],
              [-38.681666667982313, -8.771666659588618],
              [-38.679166666908088, -8.771666659588618],
              [-38.679166667807408, -8.773333326371869],
              [-38.677499998326198, -8.773333326371869],
              [-38.677499998326198, -8.774166659763466],
              [-38.675833332442323, -8.774166660662786],
              [-38.675833331542947, -8.77499999315512],
              [-38.673333334066058, -8.77499999405444],
              [-38.673333332267418, -8.775833327446037],
              [-38.672499998875821, -8.775833326546717],
              [-38.672499998875821, -8.776666659039051],
              [-38.671666665484167, -8.776666658139732],
              [-38.671666666383487, -8.777499993329968],
              [-38.67083333209257, -8.777499993329968],
              [-38.67083333299189, -8.778333327620942],
              [-38.669999998700916, -8.778333324922983],
              [-38.669999998700916, -8.78083332599715],
              [-38.669166666208639, -8.78083332599715],
              [-38.669166665309319, -8.781666659388748],
              [-38.668333332816985, -8.781666659388748],
              [-38.668333332816985, -8.782499993679721],
              [-38.66666666783243, -8.782499993679721],
              [-38.66666666783243, -8.783333326171999],
              [-38.663333332467289, -8.783333326171999],
              [-38.663333332467289, -8.784166659563653],
              [-38.657499998725939, -8.784166659563653],
              [-38.657499998725939, -8.78499999295525],
              [-38.654999999450354, -8.78499999295525],
              [-38.654999999450354, -8.785833325447584],
              [-38.654166666958076, -8.785833326346904],
              [-38.654166666958076, -8.787499994029474],
              [-38.653333333566422, -8.787499993130155],
              [-38.653333331767783, -8.788333326521752],
              [-38.652500001973465, -8.788333326521752],
              [-38.652499998376186, -8.789999993305003],
              [-38.651666666783228, -8.789999992405683],
              [-38.651666667682548, -8.796666660438007],
              [-38.650833333391574, -8.796666659538687],
              [-38.650833333391574, -8.797499992930284],
              [-38.650000000899297, -8.797499992930284],
              [-38.649999999100658, -8.798333326321881],
              [-38.649166666608323, -8.798333326321881],
              [-38.649166666608323, -8.799166657914895],
              [-38.648333332317407, -8.799166659713535],
              [-38.648333333216726, -8.799999993105132],
              [-38.647499999825072, -8.799999993105132],
              [-38.647499998925753, -8.799166659713535],
              [-38.645833333941141, -8.799166659713535],
              [-38.645833331243182, -8.798333326321881],
              [-38.644999998750905, -8.798333326321881],
              [-38.644999999650224, -8.796666659538687],
              [-38.644166665359251, -8.796666659538687],
              [-38.64416666625857, -8.795833326147033],
              [-38.643333331967654, -8.795833326147033],
              [-38.643333333766293, -8.796666659538687],
              [-38.639999998401152, -8.796666659538687],
              [-38.639999999300471, -8.795833325247713],
              [-38.63833333341654, -8.795833326147033],
              [-38.63833333251722, -8.796666659538687],
              [-38.637500000024943, -8.796666659538687],
              [-38.637500000024943, -8.795833326147033],
              [-38.636666665733969, -8.795833326147033],
              [-38.636666666633289, -8.794999993654756],
              [-38.633333333966164, -8.794999993654756],
              [-38.633333331268204, -8.794166659363782],
              [-38.63250000057451, -8.794166659363782],
              [-38.63249999967519, -8.794999993654756],
              [-38.628333332717091, -8.794999993654756],
              [-38.628333333616411, -8.794166659363782],
              [-38.625833333441562, -8.794166660263102],
              [-38.625833331642923, -8.793333325972185],
              [-38.625000000049909, -8.793333325972185],
              [-38.625000000049909, -8.792499993479851],
              [-38.624166667557631, -8.79249999437917],
              [-38.624166666658311, -8.787499993130155],
              [-38.624999997351949, -8.787499992230835],
              [-38.625000000949228, -8.782499994579041],
              [-38.621666665584087, -8.782499993679721],
              [-38.621666664684767, -8.783333326171999],
              [-38.618333330218945, -8.783333326171999],
              [-38.618333333816281, -8.782499993679721],
              [-38.616666665234391, -8.782499993679721],
              [-38.616666665234391, -8.781666659388748],
              [-38.615833332742056, -8.781666659388748],
              [-38.615833331842737, -8.78083332599715],
              [-38.615000000249779, -8.78083332599715],
              [-38.614999999350459, -8.778333326721622],
              [-38.614166666858125, -8.778333326721622],
              [-38.614166666858125, -8.776666659039051],
              [-38.613333332567208, -8.776666659039051],
              [-38.613333333466528, -8.774166657964827],
              [-38.612500000974251, -8.774166659763466],
              [-38.612500000074874, -8.771666659588618],
              [-38.611666666683277, -8.771666658689298],
              [-38.611666667582597, -8.767499994429159],
              [-38.61083333239236, -8.767499993529839],
              [-38.61083333329168, -8.765833326746588],
              [-38.611666666683277, -8.765833326746588],
              [-38.611666664884638, -8.764999992455614],
              [-38.612500000074874, -8.764999993354991],
              [-38.612500000074874, -8.761666658889169],
              [-38.613333333466528, -8.761666658889169],
              [-38.613333332567208, -8.759999993005238],
              [-38.610833335090319, -8.759999993005238],
              [-38.61083333329168, -8.759166659613584],
              [-38.609999999900026, -8.759166658714264],
              [-38.609999999000706, -8.758333326221987],
              [-38.606666665434204, -8.758333326221987],
              [-38.606666665434204, -8.759166659613584],
              [-38.600833331692854, -8.759166658714264],
              [-38.599999998301257, -8.759166659613584],
              [-38.600000000099897, -8.758333325322667],
              [-38.599166667607562, -8.758333326221987],
              [-38.599166665808923, -8.756666659438736],
              [-38.598333331518006, -8.756666658539416],
              [-38.598333331518006, -8.755833326047139],
              [-38.597499999025672, -8.755833326047139],
              [-38.597499999924992, -8.754999994454124],
              [-38.596666667432714, -8.754999994454124],
              [-38.596666666533395, -8.753333326771553],
              [-38.598333333316646, -8.753333326771553],
              [-38.598333332417326, -8.750833326596705],
              [-38.599166666708243, -8.750833326596705],
              [-38.599166665808923, -8.745833326246952],
              [-38.599999998301257, -8.745833326246952],
              [-38.600000000099897, -8.74249999268045],
              [-38.600833331692854, -8.74249999357977],
              [-38.600833331692854, -8.743333325172784],
              [-38.604166667957315, -8.743333326072104],
              [-38.604166667957315, -8.74249999357977],
              [-38.604999997751634, -8.74249999357977],
              [-38.604999998650953, -8.741666659288853],
              [-38.605833333841247, -8.741666659288853],
              [-38.605833332042607, -8.74249999357977],
              [-38.606666667232844, -8.74249999357977],
              [-38.606666665434204, -8.741666659288853],
              [-38.607500000624498, -8.741666659288853],
              [-38.607500000624498, -8.740833327695896],
              [-38.608333334016095, -8.740833325897199],
              [-38.608333332217455, -8.738333326621671],
              [-38.609166667407749, -8.738333327520991],
              [-38.609166666508429, -8.7366666589391],
              [-38.610833331492984, -8.7366666589391],
              [-38.610833331492984, -8.735833326446823],
              [-38.611666666683277, -8.735833326446823],
              [-38.611666667582597, -8.734999993055169],
              [-38.612500000074874, -8.734999993055169],
              [-38.612500000974251, -8.733333327171238],
              [-38.613333332567208, -8.733333326271918],
              [-38.613333332567208, -8.732499992880321],
              [-38.612499998276235, -8.732499992880321],
              [-38.612500000974251, -8.728333325922222],
              [-38.613333333466528, -8.728333325922222],
              [-38.613333331667889, -8.727499993429888],
              [-38.61499999845114, -8.727499994329207],
              [-38.614999999350459, -8.725833326646637],
              [-38.616666665234391, -8.725833326646637],
              [-38.61666666793235, -8.724999993255039],
              [-38.617499999525307, -8.724999993255039],
              [-38.617499998625988, -8.724166658964066],
              [-38.618333332916961, -8.724166660762762],
              [-38.618333330218945, -8.722499993080191],
              [-38.617500000424627, -8.722499993080191],
              [-38.617500000424627, -8.721666659688537],
              [-38.61666666703303, -8.721666659688537],
              [-38.61666666793235, -8.72083332629694],
              [-38.615833333641376, -8.720833325397621],
              [-38.615833333641376, -8.719999992905286],
              [-38.61499999845114, -8.719999992905286],
              [-38.614999999350459, -8.719166659513689],
              [-38.615833332742056, -8.719166659513689],
              [-38.615833332742056, -8.718333326122035],
              [-38.61666666703303, -8.718333326122035],
              [-38.616666665234391, -8.715833325947187],
              [-38.617499998625988, -8.715833325947187],
              [-38.617499999525307, -8.710833325597434],
              [-38.61666666703303, -8.710833326496754],
              [-38.61666666703303, -8.709999993105157],
              [-38.615833332742056, -8.709999992205837],
              [-38.615833333641376, -8.709166659713503],
              [-38.61499999845114, -8.709166659713503],
              [-38.613333332567208, -8.709166659713503],
              [-38.613333331667889, -8.709999993105157],
              [-38.609166667407749, -8.709999993105157],
              [-38.609166667407749, -8.709166659713503],
              [-38.599999998301257, -8.709166659713503],
              [-38.599999998301257, -8.708333325422586],
              [-38.599166667607562, -8.708333326321906],
              [-38.599166665808923, -8.707499992930309],
              [-38.597499999924992, -8.707499992930309],
              [-38.597499999924992, -8.708333327221226],
              [-38.596666665634075, -8.708333326321906],
              [-38.596666665634075, -8.709166659713503],
              [-38.595833332242421, -8.709166659713503],
              [-38.595833331343101, -8.709999993105157],
              [-38.594999998850824, -8.709999994004477],
              [-38.594999999750144, -8.710833326496754],
              [-38.594166665459227, -8.710833325597434],
              [-38.594166665459227, -8.711666658089769],
              [-38.592499999575296, -8.711666658989088],
              [-38.592500000474615, -8.710833326496754],
              [-38.586666666733265, -8.710833327396074],
              [-38.586666666733265, -8.711666658989088],
              [-38.585833332442292, -8.711666658089769],
              [-38.585833332442292, -8.712499993280005],
              [-38.584999999950014, -8.712499993280005],
              [-38.585000000849334, -8.713333327570979],
              [-38.583333334066083, -8.713333326671659],
              [-38.583333331368124, -8.714166659163936],
              [-38.58249999887579, -8.714166659163936],
              [-38.58249999887579, -8.71499999345491],
              [-38.580833332991858, -8.71499999255559],
              [-38.580833333891178, -8.717499993629758],
              [-38.579999998700941, -8.717499994529078],
              [-38.579999999600261, -8.719166659513689],
              [-38.579166666208607, -8.719166659513689],
              [-38.579166666208607, -8.719999992905286],
              [-38.579166665309288, -8.72083332629694],
              [-38.579999998700941, -8.72083332629694],
              [-38.580000000499581, -8.721666659688537],
              [-38.580833333891178, -8.721666659688537],
              [-38.580833333891178, -8.722499992180872],
              [-38.581666666383512, -8.722499993080191],
              [-38.581666667282832, -8.724166658064746],
              [-38.58249999887579, -8.724166658964066],
              [-38.58249999887579, -8.727499993429888],
              [-38.583333332267443, -8.727499993429888],
              [-38.583333332267443, -8.728333327720861],
              [-38.583333334066083, -8.732499992880321],
              [-38.582500000674429, -8.732499991981001],
              [-38.58249999887579, -8.733333325372598],
              [-38.581666667282832, -8.733333326271918],
              [-38.581666665484192, -8.734166659663572],
              [-38.582499999775109, -8.734166659663572],
              [-38.582499999775109, -8.738333326621671],
              [-38.581666667282832, -8.738333326621671],
              [-38.581666664584873, -8.743333325172784],
              [-38.580833332991858, -8.743333326072104],
              [-38.580833332991858, -8.74249999357977],
              [-38.579999999600261, -8.74249999357977],
              [-38.579999998700941, -8.745833326246952],
              [-38.580833332092539, -8.745833326246952],
              [-38.580833332092539, -8.746666659638606],
              [-38.580000000499581, -8.746666659638606],
              [-38.579999998700941, -8.748333327321177],
              [-38.579166665309288, -8.748333325522481],
              [-38.579166665309288, -8.751666659088983],
              [-38.57833333191769, -8.751666659088983],
              [-38.57833333281701, -8.752499993379956],
              [-38.577499999425413, -8.752499993379956],
              [-38.577500000324733, -8.753333326771553],
              [-38.575833332642162, -8.753333324972914],
              [-38.575833331742842, -8.754166659263888],
              [-38.574166666758231, -8.754166659263888],
              [-38.574166666758231, -8.756666659438736],
              [-38.570833334091049, -8.756666659438736],
              [-38.570833334091049, -8.759166659613584],
              [-38.569999998900812, -8.759166659613584],
              [-38.570000001598771, -8.759999993005238],
              [-38.569166665509158, -8.759999993005238],
              [-38.569166666408478, -8.761666658889169],
              [-38.5683333339162, -8.761666658889169],
              [-38.568333331218241, -8.76333332657174],
              [-38.567500001423866, -8.76333332657174],
              [-38.567499998725907, -8.764999993354991],
              [-38.56666666623363, -8.764999993354991],
              [-38.56666666623363, -8.766666659238865],
              [-38.564999998551059, -8.766666659238865],
              [-38.564999998551059, -8.765833326746588],
              [-38.564166666958045, -8.765833327645908],
              [-38.564166664260085, -8.764166659064017],
              [-38.559999999100626, -8.764166659064017],
              [-38.559999999100626, -8.76333332657174],
              [-38.558333331418055, -8.76333332657174],
              [-38.558333333216694, -8.764999993354991],
              [-38.557500000724417, -8.764999993354991],
              [-38.557499998925778, -8.765833326746588],
              [-38.554166666258595, -8.765833326746588],
              [-38.554166665359276, -8.766666658339545],
              [-38.553333332866998, -8.766666659238865],
              [-38.553333331967679, -8.765833326746588],
              [-38.552499999475344, -8.765833326746588],
              [-38.552499998576025, -8.764999992455614],
              [-38.551666667882387, -8.764999993354991],
              [-38.551666666983067, -8.765833326746588],
              [-38.550833331792774, -8.765833326746588],
              [-38.550833332692093, -8.764999993354991],
              [-38.549166665908842, -8.76499999425431],
              [-38.549166665908842, -8.764166659064017],
              [-38.548333331617926, -8.764166659064017],
              [-38.548333331617926, -8.76333332657174],
              [-38.547499998226272, -8.763333327471059],
              [-38.547500000924288, -8.762499993180086],
              [-38.545833333241717, -8.762499994079405],
              [-38.545833333241717, -8.761666658889169],
              [-38.544999998950743, -8.761666658889169],
              [-38.544999999850063, -8.759999993005238],
              [-38.543333333066812, -8.759999993005238],
              [-38.543333331268173, -8.759166659613584],
              [-38.542499999675215, -8.759166659613584],
              [-38.542499998775895, -8.756666659438736],
              [-38.541666666283561, -8.756666659438736],
              [-38.541666665384241, -8.755833325147819],
              [-38.540833332891964, -8.755833326047139],
              [-38.540833333791284, -8.754999992655485],
              [-38.539166665209393, -8.754999993554804],
              [-38.539166664310073, -8.754166659263888],
              [-38.538333332717116, -8.754166659263888],
              [-38.538333331817739, -8.752499993379956],
              [-38.537499998426142, -8.752499993379956],
              [-38.537499999325462, -8.751666659988302],
              [-38.536666667732504, -8.751666659088983],
              [-38.536666666833185, -8.750833326596705],
              [-38.535833331642891, -8.750833325697386],
              [-38.535833333441531, -8.749166658914135],
              [-38.534999998251294, -8.749166658914135],
              [-38.534999998251294, -8.748333326421857],
              [-38.53416666665828, -8.748333327321177],
              [-38.53416666665828, -8.746666659638606],
              [-38.533333331468043, -8.746666659638606],
              [-38.533333331468043, -8.744166659463701],
              [-38.532500000774348, -8.744166658564382],
              [-38.532499998975709, -8.74249999357977],
              [-38.531666665584112, -8.74249999357977],
              [-38.531666666483432, -8.739166660013325],
              [-38.530833333991154, -8.739166659114005],
              [-38.530833333991154, -8.7366666589391],
              [-38.531666666483432, -8.73666665803978],
              [-38.531666665584112, -8.73083332609707],
              [-38.530833333991154, -8.73083332609707],
              [-38.530833331293138, -8.729999993604793],
              [-38.529999998800861, -8.729999993604793],
              [-38.529999999700181, -8.727499992530568],
              [-38.530833333091778, -8.727499992530568],
              [-38.530833333091778, -8.726666660038291],
              [-38.531666666483432, -8.726666659138971],
              [-38.531666667382751, -8.725833326646637],
              [-38.532499999875029, -8.725833326646637],
              [-38.532500000774348, -8.724999993255039],
              [-38.533333332367363, -8.724999993255039],
              [-38.533333331468043, -8.724166660762762],
              [-38.53416666665828, -8.724166658964066],
              [-38.534166667557599, -8.723333326471788],
              [-38.535000000949253, -8.723333326471788],
              [-38.535000000049934, -8.719166659513689],
              [-38.535833333441531, -8.719166659513689],
              [-38.535833331642891, -8.717499993629758],
              [-38.536666665933865, -8.717499993629758],
              [-38.536666668631824, -8.714166659163936],
              [-38.537499999325462, -8.714166659163936],
              [-38.537499999325462, -8.712499993280005],
              [-38.539166665209393, -8.712499993280005],
              [-38.539166667907352, -8.709999993105157],
              [-38.540833331992644, -8.709999993105157],
              [-38.540833331992644, -8.709166659713503],
              [-38.5416666680822, -8.709166659713503],
              [-38.541666666283561, -8.708333327221226],
              [-38.542499999675215, -8.708333327221226],
              [-38.542499999675215, -8.706666659538655],
              [-38.543333331268173, -8.706666659538655],
              [-38.543333333966132, -8.704999993654724],
              [-38.544166665559146, -8.704999993654724],
              [-38.544166666458466, -8.704166658464487],
              [-38.544166665559146, -8.702499993479876],
              [-38.545000000749383, -8.702499993479876],
              [-38.544999998950743, -8.698333326521777],
              [-38.545833333241717, -8.698333326521777],
              [-38.545833331443021, -8.697499992230803],
              [-38.544999999850063, -8.697499993130123],
              [-38.544999999850063, -8.695833327246191],
              [-38.544166665559146, -8.695833326346872],
              [-38.544166666458466, -8.694999992955275],
              [-38.543333333966132, -8.694999992955275],
              [-38.543333333966132, -8.693333326172024],
              [-38.541666666283561, -8.693333327071343],
              [-38.5416666680822, -8.692499993679746],
              [-38.538333331817739, -8.692499993679746],
              [-38.538333331817739, -8.691666659388773],
              [-38.537500000224782, -8.691666659388773],
              [-38.537499999325462, -8.690833325997176],
              [-38.536666667732504, -8.690833325997176],
              [-38.536666667732504, -8.689999993504841],
              [-38.533333331468043, -8.689999993504841],
              [-38.533333332367363, -8.68833332672159],
              [-38.532499999875029, -8.68833332672159],
              [-38.532499998975709, -8.685833325647422],
              [-38.531666665584112, -8.685833326546742],
              [-38.531666665584112, -8.683333326371894],
              [-38.535833332542211, -8.683333326371894],
              [-38.535833331642891, -8.684166660662811],
              [-38.537499999325462, -8.684166659763491],
              [-38.537499998426142, -8.684999993155088],
              [-38.538333333616436, -8.684999994054408],
              [-38.538333332717116, -8.685833326546742],
              [-38.543333333966132, -8.685833326546742],
              [-38.543333333066812, -8.684166659763491],
              [-38.544166667357786, -8.684166659763491],
              [-38.544166666458466, -8.683333326371894],
              [-38.544999999850063, -8.683333327271214],
              [-38.544999998950743, -8.68249999298024],
              [-38.545833333241717, -8.68249999298024],
              [-38.545833331443021, -8.681666658689323],
              [-38.546666666633314, -8.681666660487963],
              [-38.546666667532634, -8.679999993704712],
              [-38.547500000024911, -8.679999993704712],
              [-38.547500000024911, -8.679166659413738],
              [-38.548333334315885, -8.679166659413738],
              [-38.548333332517245, -8.678333326022141],
              [-38.547499998226272, -8.678333326022141],
              [-38.547499998226272, -8.675833326746613],
              [-38.546666666633314, -8.675833326746613],
              [-38.546666667532634, -8.674999992455639],
              [-38.547500000924288, -8.674999992455639],
              [-38.547500000924288, -8.673333327471028],
              [-38.548333333416565, -8.673333326571708],
              [-38.548333331617926, -8.672499993180111],
              [-38.549166667707482, -8.672499994079431],
              [-38.549166666808162, -8.671666658889137],
              [-38.549999998401177, -8.671666658889137],
              [-38.549999998401177, -8.67083332639686],
              [-38.549166666808162, -8.67083332639686],
              [-38.549166665908842, -8.669166659613609],
              [-38.549999998401177, -8.669166660512929],
              [-38.549999999300496, -8.668333326221955],
              [-38.553333332866998, -8.668333326221955],
              [-38.553333332866998, -8.667499993729677],
              [-38.554166666258595, -8.667499993729677],
              [-38.554166666258595, -8.666666659438761],
              [-38.554999998750873, -8.666666659438761],
              [-38.554999998750873, -8.665833326047107],
              [-38.556666666433443, -8.665833326047107],
              [-38.556666667332763, -8.664999993554829],
              [-38.559166665709029, -8.664999994454149],
              [-38.559166666608348, -8.664166659263856],
              [-38.562499997476834, -8.664166660163175],
              [-38.562499998376154, -8.661666659089008],
              [-38.561666665883877, -8.661666659089008],
              [-38.561666666783196, -8.659999992305757],
              [-38.56083333159296, -8.659999993205076],
              [-38.560833333391599, -8.659166658914103],
              [-38.56, -8.659166658914103],
              [-38.560000000899265, -8.657499993030228],
              [-38.559166666608348, -8.657499993929548],
              [-38.559166665709029, -8.655833326246977],
              [-38.558333333216694, -8.655833326246977],
              [-38.558333331418055, -8.654999993754643],
              [-38.557499998925778, -8.654999993754643],
              [-38.557499999825097, -8.653333326072072],
              [-38.558333331418055, -8.653333326072072],
              [-38.558333332317375, -8.649999992505627],
              [-38.559999999100626, -8.649999993404947],
              [-38.559999999100626, -8.645833327346111],
              [-38.559166665709029, -8.645833327346111],
              [-38.559166666608348, -8.64416665966354],
              [-38.557499998925778, -8.64416665966354],
              [-38.557499998925778, -8.643333326271943],
              [-38.556666667332763, -8.643333326271943],
              [-38.556666666433443, -8.642499992880346],
              [-38.555833333041846, -8.642499992880346],
              [-38.555833331243207, -8.640833326097095],
              [-38.554999999650192, -8.640833326097095],
              [-38.554999999650192, -8.639999993604761],
              [-38.553333331967679, -8.639999993604761],
              [-38.553333332866998, -8.639166659313844],
              [-38.552499998576025, -8.639166659313844],
              [-38.552499998576025, -8.63833332592219],
              [-38.551666666083747, -8.63833332592219],
              [-38.551666666083747, -8.636666659138939],
              [-38.550833332692093, -8.636666659138939],
              [-38.550833330893454, -8.634999994154384],
              [-38.548333331617926, -8.634999993255065],
              [-38.548333333416565, -8.635833326646662],
              [-38.547500000024911, -8.635833327545981],
              [-38.547499998226272, -8.636666658239619],
              [-38.546666665733994, -8.636666659138939],
              [-38.546666664834675, -8.635833326646662],
              [-38.544166665559146, -8.635833326646662],
              [-38.544166665559146, -8.634999993255065],
              [-38.541666666283561, -8.634999993255065],
              [-38.541666666283561, -8.635833326646662],
              [-38.540833331093324, -8.635833326646662],
              [-38.540833331992644, -8.63833332592219],
              [-38.54000000039963, -8.63833332592219],
              [-38.53999999950031, -8.637499994329232],
              [-38.537499998426142, -8.637499993429913],
              [-38.537499998426142, -8.636666658239619],
              [-38.535833333441531, -8.636666659138939],
              [-38.535833333441531, -8.635833326646662],
              [-38.534999998251294, -8.635833326646662],
              [-38.535000000949253, -8.634999993255065],
              [-38.53416666665828, -8.634999993255065],
              [-38.53416666575896, -8.635833326646662],
              [-38.533333332367363, -8.635833325747342],
              [-38.533333331468043, -8.639166660213164],
              [-38.532500000774348, -8.639166659313844],
              [-38.532499998975709, -8.639999993604761],
              [-38.530833333991154, -8.639999993604761],
              [-38.530833331293138, -8.640833326996415],
              [-38.529166666308583, -8.640833326097095],
              [-38.529166665409264, -8.643333326271943],
              [-38.52833333201761, -8.643333326271943],
              [-38.52833333201761, -8.64416665966354],
              [-38.526666667032998, -8.64416665966354],
              [-38.526666667032998, -8.644999993055194],
              [-38.523333333466496, -8.644999993055194],
              [-38.523333331667857, -8.645833326446791],
              [-38.522500000074899, -8.645833327346111],
              [-38.522500000074899, -8.646666658039805],
              [-38.521666665783982, -8.646666658939125],
              [-38.521666666683302, -8.647499993230042],
              [-38.520833331493009, -8.647499993230042],
              [-38.520833332392328, -8.650833325897224],
              [-38.520000000799371, -8.650833325897224],
              [-38.520000000799371, -8.654166659463726],
              [-38.519166666508397, -8.654166660363046],
              [-38.519166666508397, -8.654999991956004],
              [-38.51833333221748, -8.654999993754643],
              [-38.51833333401612, -8.656666659638574],
              [-38.517500000624466, -8.656666660537894],
              [-38.517499998825826, -8.657499993030228],
              [-38.515833330243936, -8.657499993030228],
              [-38.515833330243936, -8.658333326421825],
              [-38.514999999550298, -8.658333326421825],
              [-38.515000000449618, -8.659166660712799],
              [-38.514166667058021, -8.659166658014783],
              [-38.514166667058021, -8.659999993205076],
              [-38.513333333666367, -8.659999993205076],
              [-38.513333333666367, -8.659166658914103],
              [-38.510000000999185, -8.659166658914103],
              [-38.509999998301225, -8.658333326421825],
              [-38.509166665808948, -8.658333326421825],
              [-38.509166665808948, -8.659166658914103],
              [-38.508333331517974, -8.659166658014783],
              [-38.508333330618655, -8.659999993205076],
              [-38.507499999925017, -8.659999994104396],
              [-38.507499999025697, -8.660833326596673],
              [-38.505833332242446, -8.660833326596673],
              [-38.505833332242446, -8.659999992305757],
              [-38.504166666358515, -8.659999993205076],
              [-38.504166665459195, -8.659166658914103],
              [-38.501666667982306, -8.659166660712799],
              [-38.501666667982306, -8.659999993205076],
              [-38.500833332792013, -8.659999993205076],
              [-38.500833331892693, -8.660833326596673],
              [-38.497499998326191, -8.660833326596673],
              [-38.497499998326191, -8.659999992305757],
              [-38.496666666733233, -8.659999993205076],
              [-38.496666664934594, -8.658333326421825],
              [-38.494166667457705, -8.658333326421825],
              [-38.492499999775134, -8.658333326421825],
              [-38.492499999775134, -8.659999993205076],
              [-38.4916666672828, -8.659999993205076],
              [-38.49166666638348, -8.659166658014783],
              [-38.489166666208632, -8.659166658914103],
              [-38.489166665309313, -8.658333326421825],
              [-38.486666666033784, -8.658333327321145],
              [-38.486666667832424, -8.657499993929548],
              [-38.48416666495956, -8.657499993030228],
              [-38.484166666758199, -8.656666658739255],
              [-38.483333333366602, -8.656666659638574],
              [-38.483333331567962, -8.657499993030228],
              [-38.482499999075628, -8.657499993030228],
              [-38.482499999974948, -8.658333327321145],
              [-38.481666666583351, -8.658333326421825],
              [-38.481666665684031, -8.657499993929548],
              [-38.480833331393114, -8.657499993929548],
              [-38.480833332292434, -8.654999993754643],
              [-38.479166666408503, -8.654999994653963],
              [-38.479166665509183, -8.654166659463726],
              [-38.478333331218209, -8.654166659463726],
              [-38.478333331218209, -8.653333326072072],
              [-38.475833332842001, -8.653333325172753],
              [-38.475833333741321, -8.652499993579795],
              [-38.475000000349667, -8.652499994479115],
              [-38.474999999450347, -8.650833325897224],
              [-38.47416666695807, -8.650833327695864],
              [-38.47416666605875, -8.649999993404947],
              [-38.471666667682541, -8.649999992505627],
              [-38.471666666783221, -8.649166660013293],
              [-38.470833332492248, -8.649166658214654],
              [-38.470833333391568, -8.648333327521016],
              [-38.47, -8.648333326621696],
              [-38.469999999100651, -8.647499994129362],
              [-38.469166665708997, -8.647499993230042],
              [-38.469166665708997, -8.648333326621696],
              [-38.466666665534149, -8.648333327521016],
              [-38.466666666433468, -8.649166659113973],
              [-38.465833331243175, -8.649166658214654],
              [-38.465833333041871, -8.649999993404947],
              [-38.464166665359301, -8.649999993404947],
              [-38.46416666625862, -8.650833327695864],
              [-38.462499999475369, -8.650833325897224],
              [-38.46249999857605, -8.651666659288821],
              [-38.459166667707507, -8.651666659288821],
              [-38.459166666808187, -8.652499993579795],
              [-38.457500000024936, -8.652499993579795],
              [-38.457500000024936, -8.651666659288821],
              [-38.456666665734019, -8.651666659288821],
              [-38.456666666633339, -8.652499994479115],
              [-38.455833331443046, -8.652499993579795],
              [-38.455833331443046, -8.653333326072072],
              [-38.451666668082225, -8.653333326072072],
              [-38.451666668082225, -8.652499993579795],
              [-38.450833333791252, -8.652499993579795],
              [-38.450833332891932, -8.651666658389502],
              [-38.449166667008058, -8.651666659288821],
              [-38.449166664310042, -8.652499993579795],
              [-38.446666665034513, -8.652499993579795],
              [-38.445833332542236, -8.652499993579795],
              [-38.445833332542236, -8.651666659288821],
              [-38.441666666483457, -8.651666659288821],
              [-38.44166666558408, -8.650833325897224],
              [-38.440833331293163, -8.650833325897224],
              [-38.440833333091803, -8.649999993404947],
              [-38.440000000599525, -8.649999994304267],
              [-38.439999999700206, -8.648333326621696],
              [-38.439166666308552, -8.648333326621696],
              [-38.439166665409232, -8.642499992880346],
              [-38.438333332916955, -8.642499992880346],
              [-38.438333332916955, -8.641666658589372],
              [-38.43583333184273, -8.641666659488692],
              [-38.43583333184273, -8.63833332592219],
              [-38.434166665958799, -8.63833332592219],
              [-38.434166666858118, -8.637499992530593],
              [-38.433333332567202, -8.637499993429913],
              [-38.433333332567202, -8.635833327545981],
              [-38.432500000074924, -8.635833327545981],
              [-38.432500000974244, -8.634999993255065],
              [-38.43166666668327, -8.634999994154384],
              [-38.43166666758259, -8.634166658964091],
              [-38.430833331493034, -8.63416666076273],
              [-38.430833331493034, -8.633333326471814],
              [-38.430000000799339, -8.633333326471814],
              [-38.4299999990007, -8.63249999308016],
              [-38.429166665609102, -8.63249999308016],
              [-38.429166667407742, -8.631666659688563],
              [-38.427500000624491, -8.631666660587882],
              [-38.427500000624491, -8.63249999308016],
              [-38.426666666333517, -8.63249999308016],
              [-38.426666665434198, -8.630833327196228],
              [-38.428333334016088, -8.630833326296909],
              [-38.428333334016088, -8.629999992905312],
              [-38.429166665609102, -8.629999992005992],
              [-38.429166666508422, -8.629166659513658],
              [-38.429999999900019, -8.629166659513658],
              [-38.4299999990007, -8.626666662936088],
              [-38.429166666508422, -8.626666659338809],
              [-38.429166666508422, -8.625833325947212],
              [-38.427499998825851, -8.625833325947212],
              [-38.427499998825851, -8.624999993454878],
              [-38.426666665434198, -8.624999993454878],
              [-38.426666669031533, -8.624166659163961],
              [-38.424999999550323, -8.624166660063281],
              [-38.424999998650947, -8.623333326671627],
              [-38.423333332767072, -8.623333326671627],
              [-38.423333332767072, -8.62249999328003],
              [-38.422499999375418, -8.62249999328003],
              [-38.422500000274738, -8.619999992205805],
              [-38.421666665983821, -8.619999993105125],
              [-38.421666667782461, -8.619166659713528],
              [-38.420833333491487, -8.619166660612848],
              [-38.419166665808916, -8.619166659713528],
              [-38.419166665808916, -8.618333326321931],
              [-38.416666666533388, -8.618333326321931],
              [-38.416666665634068, -8.617499992930277],
              [-38.414999999750137, -8.617499992930277],
              [-38.414999999750137, -8.61666665953868],
              [-38.413333332067566, -8.61666665953868],
              [-38.413333333866206, -8.617499992930277],
              [-38.412499999575289, -8.617499992930277],
              [-38.412499998675969, -8.618333326321931],
              [-38.411666665284315, -8.618333326321931],
              [-38.411666664384995, -8.619166657914889],
              [-38.410833333691357, -8.619166659713528],
              [-38.410833334590677, -8.619999993105125],
              [-38.410000000299704, -8.619999994004445],
              [-38.410000000299704, -8.621666658989056],
              [-38.409166666908106, -8.621666658989056],
              [-38.409166668706746, -8.62249999417935],
              [-38.408333334415829, -8.62249999328003],
              [-38.408333333516509, -8.624166659163961],
              [-38.406666667632578, -8.624166659163961],
              [-38.406666666733258, -8.624999993454878],
              [-38.405833333341604, -8.624999993454878],
              [-38.405833331542965, -8.625833325947212],
              [-38.404999999950007, -8.625833325947212],
              [-38.404999999950007, -8.626666659338809],
              [-38.405833331542965, -8.626666659338809],
              [-38.405833331542965, -8.627499993629783],
              [-38.405000000849327, -8.627499993629783],
              [-38.405000000849327, -8.62833332612206],
              [-38.403333331368117, -8.62833332612206],
              [-38.403333332267437, -8.629166659513658],
              [-38.402500000674422, -8.629166659513658],
              [-38.402499998875783, -8.629999992905312],
              [-38.400833332991908, -8.629999992905312],
              [-38.400833333891228, -8.629166659513658],
              [-38.400000000499574, -8.629166659513658],
              [-38.399999999600254, -8.628333325222741],
              [-38.398333333716323, -8.62833332612206],
              [-38.397499998526087, -8.62833332612206],
              [-38.397499998526087, -8.629166659513658],
              [-38.396666666933072, -8.629166658614338],
              [-38.396666666933072, -8.62833332612206],
              [-38.394999999250501, -8.628333325222741],
              [-38.394999998351182, -8.627499992730407],
              [-38.39333333246725, -8.627499993629783],
              [-38.39333333246725, -8.62833332612206],
              [-38.392499999075653, -8.62833332612206],
              [-38.392499999974973, -8.627499993629783],
              [-38.391666666583376, -8.627499993629783],
              [-38.391666665684056, -8.625833325947212],
              [-38.390000000699445, -8.625833325947212],
              [-38.389999998900805, -8.624999993454878],
              [-38.388333333016874, -8.624999994354198],
              [-38.388333333916194, -8.617499992930277],
              [-38.386666668032262, -8.617499992030957],
              [-38.384166664260079, -8.617499992930277],
              [-38.384166666958095, -8.618333326321931],
              [-38.383333333566441, -8.618333326321931],
              [-38.383333332667121, -8.619166659713528],
              [-38.38166666678319, -8.619166659713528],
              [-38.38166666678319, -8.619999992205805],
              [-38.380833333391593, -8.619999992205805],
              [-38.380833331592953, -8.623333325772307],
              [-38.382499999275524, -8.623333326671627],
              [-38.382500001074163, -8.624999992555558],
              [-38.38166666588387, -8.624999993454878],
              [-38.38166666678319, -8.626666659338809],
              [-38.380833332492273, -8.626666659338809],
              [-38.380833331592953, -8.627499993629783],
              [-38.38, -8.627499993629783],
              [-38.379999999100619, -8.62833332612206],
              [-38.378333332317368, -8.62833332612206],
              [-38.378333333216688, -8.629166659513658],
              [-38.37750000072441, -8.629166659513658],
              [-38.377499999825091, -8.629999992905312],
              [-38.376666665534117, -8.629999992905312],
              [-38.376666665534117, -8.630833327196228],
              [-38.374999998750923, -8.630833325397589],
              [-38.374999997851603, -8.631666659688563],
              [-38.374166665359269, -8.631666659688563],
              [-38.374166666258589, -8.630833326296909],
              [-38.373333331967672, -8.630833326296909],
              [-38.373333332866991, -8.629166658614338],
              [-38.374166666258589, -8.629166659513658],
              [-38.374166665359269, -8.627499993629783],
              [-38.369999999300489, -8.627499993629783],
              [-38.370000001099129, -8.62833332702138],
              [-38.364166665559139, -8.62833332612206],
              [-38.364166666458459, -8.627499993629783],
              [-38.362499998775888, -8.627499993629783],
              [-38.362499999675208, -8.62833332612206],
              [-38.360833331093318, -8.62833332612206],
              [-38.360833332891957, -8.627499993629783],
              [-38.35999999950036, -8.627499993629783],
              [-38.359999998600983, -8.62833332702138],
              [-38.358333331817789, -8.62833332612206],
              [-38.358333333616429, -8.627499993629783],
              [-38.356666666833178, -8.627499993629783],
              [-38.356666665933858, -8.62833332612206],
              [-38.355833331642884, -8.62833332612206],
              [-38.355833333441524, -8.627499993629783],
              [-38.354166665758953, -8.627499993629783],
              [-38.354166667557593, -8.628333325222741],
              [-38.353333332367356, -8.62833332612206],
              [-38.353333331468036, -8.627499992730407],
              [-38.351666666483425, -8.627499993629783],
              [-38.351666667382744, -8.626666659338809],
              [-38.349999998800854, -8.626666659338809],
              [-38.349999997901534, -8.625833325947212],
              [-38.342500000974212, -8.625833325947212],
              [-38.342500000074892, -8.624999993454878],
              [-38.340833332392322, -8.624999993454878],
              [-38.340833332392322, -8.624166659163961],
              [-38.339999999000725, -8.624166659163961],
              [-38.339999999900044, -8.623333325772307],
              [-38.336666666333542, -8.623333326671627],
              [-38.336666665434223, -8.625833325047893],
              [-38.334999998650972, -8.625833325947212],
              [-38.334999999550291, -8.626666660238129],
              [-38.330000000099858, -8.62666665843949],
              [-38.329999998301219, -8.627499993629783],
              [-38.328333331517968, -8.627499993629783],
              [-38.328333332417287, -8.629166658614338],
              [-38.32749999992501, -8.629166659513658],
              [-38.32750000082433, -8.629999992905312],
              [-38.323333333866231, -8.629999992905312],
              [-38.323333333866231, -8.631666659688563],
              [-38.320833333691326, -8.631666659688563],
              [-38.320833331892686, -8.63249999308016],
              [-38.319999998501089, -8.63249999308016],
              [-38.319999999400409, -8.635833326646662],
              [-38.319166666908131, -8.635833326646662],
              [-38.319166667807451, -8.634999993255065],
              [-38.317499998326241, -8.634999993255065],
              [-38.317499999225561, -8.634166658964091],
              [-38.316666665833907, -8.634166658964091],
              [-38.316666665833907, -8.633333326471814],
              [-38.310833332092557, -8.633333327371133],
              [-38.310833333891196, -8.63249999308016],
              [-38.310000001398919, -8.63249999308016],
              [-38.30999999870096, -8.631666659688563],
              [-38.310833332991876, -8.631666659688563],
              [-38.310833334790516, -8.630833326296909],
              [-38.30999999870096, -8.630833326296909],
              [-38.30999999870096, -8.629999992005992],
              [-38.308333334615668, -8.629999992905312],
              [-38.308333331917709, -8.630833327196228],
              [-38.299166665509176, -8.630833326296909],
              [-38.299166667307816, -8.631666659688563],
              [-38.296666668032287, -8.631666659688563],
              [-38.296666668032287, -8.632499993979479],
              [-38.295833331942674, -8.63249999308016],
              [-38.295833331942674, -8.633333325572494],
              [-38.294166666058743, -8.633333326471814],
              [-38.294166666058743, -8.63416666076273],
              [-38.293333331767826, -8.634166658964091],
              [-38.293333331767826, -8.635833326646662],
              [-38.292499999275492, -8.635833326646662],
              [-38.292500001074131, -8.634999993255065],
              [-38.291666667682534, -8.634999993255065],
              [-38.291666665883895, -8.634166658064771],
              [-38.290833332492241, -8.634166658964091],
              [-38.290833333391561, -8.631666659688563],
              [-38.289999999100644, -8.631666659688563],
              [-38.29, -8.629999992905312],
              [-38.285833333941184, -8.629999992905312],
              [-38.285833333041865, -8.630833326296909],
              [-38.28333333196764, -8.630833325397589],
              [-38.28333333196764, -8.631666659688563],
              [-38.280833331792792, -8.631666657889923],
              [-38.280833332692112, -8.63249999308016],
              [-38.279166665009541, -8.63249999308016],
              [-38.279166665908861, -8.633333326471814],
              [-38.277500000024929, -8.633333326471814],
              [-38.277500000924249, -8.63249999308016],
              [-38.275833332342359, -8.63249999308016],
              [-38.275833330543719, -8.631666657889923],
              [-38.272499999675176, -8.631666659688563],
              [-38.272499999675176, -8.63249999218084],
              [-38.271666666283579, -8.63249999308016],
              [-38.271666665384259, -8.633333326471814],
              [-38.269166665209411, -8.633333326471814],
              [-38.269166665209411, -8.63249999308016],
              [-38.268333332717077, -8.63249999218084],
              [-38.268333331817757, -8.631666659688563],
              [-38.266666666833146, -8.631666659688563],
              [-38.266666667732466, -8.630833325397589],
              [-38.26749999932548, -8.630833327196228],
              [-38.26749999842616, -8.629999992905312],
              [-38.268333332717077, -8.629999992905312],
              [-38.268333331817757, -8.629166658614338],
              [-38.269166667907371, -8.629166659513658],
              [-38.269166667008051, -8.626666659338809],
              [-38.270000000399648, -8.626666659338809],
              [-38.270000000399648, -8.625833325947212],
              [-38.269166665209411, -8.625833325947212],
              [-38.269166665209411, -8.624166659163961],
              [-38.269999999500328, -8.624166659163961],
              [-38.269999999500328, -8.623333326671627],
              [-38.270833332891982, -8.623333326671627],
              [-38.270833331992662, -8.620833326496779],
              [-38.274166665559108, -8.620833327396099],
              [-38.274166666458427, -8.619166659713528],
              [-38.274999998950761, -8.619166657914889],
              [-38.274999998950761, -8.617499992930277],
              [-38.275833332342359, -8.617499992930277],
              [-38.275833331443039, -8.614999993654749],
              [-38.276666667532652, -8.614999993654749],
              [-38.276666666633332, -8.614166659363775],
              [-38.2791666677075, -8.614166659363775],
              [-38.279166665908861, -8.611666659188927],
              [-38.280833332692112, -8.611666659188927],
              [-38.280833332692112, -8.609166659014079],
              [-38.281666666983028, -8.609166659014079],
              [-38.281666667882348, -8.608333325622425],
              [-38.282499997676723, -8.608333326521745],
              [-38.282499998576043, -8.607499993130148],
              [-38.281666666083709, -8.607499993130148],
              [-38.281666666083709, -8.604166659563646],
              [-38.282499998576043, -8.604166659563646],
              [-38.282500000374682, -8.599999993504866],
              [-38.28333333196764, -8.599999993504866],
              [-38.28333333286696, -8.598333324922976],
              [-38.284166665359294, -8.598333326721615],
              [-38.284166665359294, -8.596666659039045],
              [-38.284999999650211, -8.596666659039045],
              [-38.284999998750891, -8.59583332654671],
              [-38.286666665534142, -8.59583332654671],
              [-38.286666666433462, -8.594166659763516],
              [-38.287500000724435, -8.594166659763516],
              [-38.287499998925739, -8.593333327271182],
              [-38.286666666433462, -8.593333326371862],
              [-38.286666665534142, -8.588333326022109],
              [-38.285833331243225, -8.588333326921429],
              [-38.285833333041865, -8.587499993529832],
              [-38.286666666433462, -8.587499993529832],
              [-38.286666666433462, -8.585833326746581],
              [-38.287499999825116, -8.585833326746581],
              [-38.287499999825116, -8.58416665906401],
              [-38.278333331617944, -8.58416665906401],
              [-38.278333331617944, -8.584999994254304],
              [-38.273333331268191, -8.584999994254304],
              [-38.27333333306683, -8.58416665906401],
              [-38.269166667907371, -8.58416665906401],
              [-38.269166667008051, -8.584999994254304],
              [-38.2675000002248, -8.584999993354984],
              [-38.26749999932548, -8.58416665996333],
              [-38.266666665933826, -8.58416665906401],
              [-38.266666666833146, -8.57833332622198],
              [-38.264166666658298, -8.57833332622198],
              [-38.264166665758978, -8.577499994629022],
              [-38.263333331468004, -8.577499993729703],
              [-38.263333332367324, -8.57833332532266],
              [-38.259166665409225, -8.57833332622198],
              [-38.259166665409225, -8.577499993729703],
              [-38.259999999700199, -8.577499994629022],
              [-38.260000000599518, -8.574999993554798],
              [-38.260833333091796, -8.574999993554798],
              [-38.260833333991116, -8.573333326771547],
              [-38.26166666648345, -8.573333326771547],
              [-38.261666667382769, -8.57249999337995],
              [-38.259166667207865, -8.57249999337995],
              [-38.259166666308545, -8.570833326596698],
              [-38.256666667033016, -8.570833327496018],
              [-38.256666667932336, -8.569999993205101],
              [-38.254166667757488, -8.569999993205101],
              [-38.254166665958849, -8.569166658914128],
              [-38.253333332567195, -8.569166658914128],
              [-38.253333333466514, -8.570833326596698],
              [-38.241666665983814, -8.570833326596698],
              [-38.241666666883134, -8.571666659088976],
              [-38.236666666533381, -8.571666659088976],
              [-38.236666666533381, -8.570833326596698],
              [-38.235833334041104, -8.570833326596698],
              [-38.23499999885081, -8.570833327496018],
              [-38.23499999975013, -8.571666659988296],
              [-38.234166666358533, -8.571666658189656],
              [-38.234166666358533, -8.573333326771547],
              [-38.233333332067559, -8.573333326771547],
              [-38.233333332067559, -8.574166659263881],
              [-38.225833333341598, -8.574166660163201],
              [-38.225833332442278, -8.57833332622198],
              [-38.21749999762676, -8.57833332622198],
              [-38.21749999852608, -8.579166659613577],
              [-38.215833332642148, -8.579166658714257],
              [-38.215833332642148, -8.579999993005231],
              [-38.214166665858897, -8.579999993005231],
              [-38.214166666758217, -8.580833326396828],
              [-38.213333331567981, -8.580833326396828],
              [-38.21333333336662, -8.581666658889162],
              [-38.212499999075646, -8.581666658889162],
              [-38.212500000874286, -8.582499993180079],
              [-38.21166666478473, -8.582499994079399],
              [-38.211666665684049, -8.583333326571733],
              [-38.210833332292395, -8.583333326571733],
              [-38.210833334091035, -8.58416665906401],
              [-38.210000000699438, -8.58416665996333],
              [-38.210000000699438, -8.584999993354984],
              [-38.208333333016867, -8.584999993354984],
              [-38.208333331218228, -8.58416665906401],
              [-38.204999999450365, -8.58416665906401],
              [-38.204999998551045, -8.583333326571733],
              [-38.203333332667114, -8.583333326571733],
              [-38.203333332667114, -8.582499993180079],
              [-38.200833332492266, -8.582499993180079],
              [-38.200833332492266, -8.58416665906401],
              [-38.196666665534167, -8.58416665906401],
              [-38.196666665534167, -8.583333326571733],
              [-38.195833331243193, -8.583333326571733],
              [-38.195833333041833, -8.58416665906401],
              [-38.194999999650236, -8.58416665996333],
              [-38.194999998750916, -8.583333326571733],
              [-38.194166665359262, -8.583333326571733],
              [-38.194166665359262, -8.581666658889162],
              [-38.193333331967665, -8.581666658889162],
              [-38.193333332866985, -8.5783333271213],
              [-38.191666666983053, -8.57833332622198],
              [-38.191666667882373, -8.576666660338049],
              [-38.19083333269208, -8.576666659438729],
              [-38.19083333179276, -8.575833326047132],
              [-38.189999999300483, -8.575833326047132],
              [-38.190000001099122, -8.571666659988296],
              [-38.19083333179276, -8.571666659088976],
              [-38.19083333269208, -8.570833326596698],
              [-38.191666666983053, -8.570833326596698],
              [-38.191666666083734, -8.569999993205101],
              [-38.192499999475331, -8.569999993205101],
              [-38.192499999475331, -8.56833332642185],
              [-38.191666666983053, -8.56833332642185],
              [-38.191666666983053, -8.565833325347626],
              [-38.19083333179276, -8.565833325347626],
              [-38.190833333591456, -8.559999993404915],
              [-38.191666666983053, -8.559999993404915],
              [-38.191666666083734, -8.559166658214679],
              [-38.19250000037465, -8.559166659113998],
              [-38.192499998576011, -8.558333326621664],
              [-38.191666666983053, -8.558333326621664],
              [-38.191666667882373, -8.556666658939093],
              [-38.190000001099122, -8.556666658939093],
              [-38.189999999300483, -8.555833326446816],
              [-38.189166665009566, -8.555833326446816],
              [-38.189166667707525, -8.554999993055162],
              [-38.187500000024954, -8.554999993055162],
              [-38.187500000924274, -8.554166659663565],
              [-38.186666664834661, -8.554166659663565],
              [-38.186666665733981, -8.553333326271968],
              [-38.183333333966175, -8.553333326271968],
              [-38.183333333066798, -8.554999993055162],
              [-38.182499999675201, -8.554999992155842],
              [-38.182499998775882, -8.554166659663565],
              [-38.180833331093311, -8.554166659663565],
              [-38.18083333379127, -8.553333325372648],
              [-38.179166667907339, -8.553333326271968],
              [-38.179166667907339, -8.552499992880314],
              [-38.178333331817782, -8.552499992880314],
              [-38.178333333616422, -8.551666659488717],
              [-38.177500000224768, -8.551666659488717],
              [-38.177499999325448, -8.550833326097063],
              [-38.175833333441517, -8.550833326097063],
              [-38.175833331642878, -8.551666660388037],
              [-38.17500000004992, -8.551666658589397],
              [-38.174166667557643, -8.551666659488717],
              [-38.174166666658323, -8.552499992880314],
              [-38.171666666483418, -8.552499991980994],
              [-38.171666667382738, -8.551666659488717],
              [-38.168333332916916, -8.551666659488717],
              [-38.168333333816236, -8.552499992880314],
              [-38.167500000424639, -8.552499992880314],
              [-38.167499998625999, -8.553333326271968],
              [-38.165833331842748, -8.553333326271968],
              [-38.165833331842748, -8.554999993954539],
              [-38.164999998451151, -8.554999993055162],
              [-38.164999999350471, -8.555833326446816],
              [-38.164166667757456, -8.555833326446816],
              [-38.164166666858137, -8.556666658939093],
              [-38.16333333076858, -8.556666660737733],
              [-38.1633333316679, -8.557499992330747],
              [-38.161666667582608, -8.557499993230067],
              [-38.161666666683288, -8.558333326621664],
              [-38.160833331492995, -8.558333326621664],
              [-38.160833333291635, -8.559166658214679],
              [-38.159999999900037, -8.559166659113998],
              [-38.159999999900037, -8.559999993404915],
              [-38.159166665609064, -8.559999993404915],
              [-38.159166665609064, -8.561666659288846],
              [-38.154166665259368, -8.561666659288846],
              [-38.154166665259368, -8.56249999357982],
              [-38.150833333491505, -8.56249999357982],
              [-38.150833333491505, -8.563333326072097],
              [-38.150000000999228, -8.563333326072097],
              [-38.150000000099908, -8.564166659463694],
              [-38.149166667607574, -8.564166659463694],
              [-38.149166665808934, -8.567499993030196],
              [-38.147499999925003, -8.567499992130877],
              [-38.147499999925003, -8.566666659638599],
              [-38.143333332067584, -8.566666659638599],
              [-38.143333333866224, -8.565833326246945],
              [-38.14249999957525, -8.565833326246945],
              [-38.14249999867593, -8.566666659638599],
              [-38.140833332792056, -8.566666659638599],
              [-38.140833332792056, -8.565833326246945],
              [-38.140000000299722, -8.565833326246945],
              [-38.139999999400402, -8.561666659288846],
              [-38.140833331892736, -8.561666658389527],
              [-38.140833331892736, -8.560833325897249],
              [-38.139999999400402, -8.560833325897249],
              [-38.139999999400402, -8.559999994304235],
              [-38.140833331892736, -8.559999994304235],
              [-38.140833332792056, -8.559166659113998],
              [-38.140000000299722, -8.559166659113998],
              [-38.139999998501082, -8.556666658039774],
              [-38.139166666908125, -8.556666658939093],
              [-38.139166666908125, -8.555833326446816],
              [-38.137499999225554, -8.555833326446816],
              [-38.137499999225554, -8.554999993055162],
              [-38.13666666673322, -8.554999993055162],
              [-38.1366666658339, -8.554166659663565],
              [-38.135833334240942, -8.554166659663565],
              [-38.135833332442303, -8.553333326271968],
              [-38.134999999949969, -8.553333326271968],
              [-38.134999999050649, -8.552499991980994],
              [-38.134166665659052, -8.552499992880314],
              [-38.134166665659052, -8.550833326097063],
              [-38.133333331368078, -8.550833325197743],
              [-38.133333330468759, -8.549999994504105],
              [-38.132499998875801, -8.549999993604786],
              [-38.132499999775121, -8.549166659313812],
              [-38.131666665484204, -8.549166659313812],
              [-38.131666667282843, -8.548333325922215],
              [-38.13083333209255, -8.548333325022895],
              [-38.13083333209255, -8.547499993429881],
              [-38.129999999600273, -8.547499992530561],
              [-38.127500000324687, -8.547499993429881],
              [-38.127499999425368, -8.548333325922215],
              [-38.126666666033771, -8.548333327720854],
              [-38.126666666033771, -8.547499993429881],
              [-38.1249999983512, -8.547499993429881],
              [-38.1249999983512, -8.548333325922215],
              [-38.12083333409106, -8.548333325922215],
              [-38.12083333229242, -8.549166659313812],
              [-38.119999999800086, -8.549166659313812],
              [-38.120000000699406, -8.549999993604786],
              [-38.118333331218196, -8.549999993604786],
              [-38.118333331218196, -8.548333325922215],
              [-38.119166665509169, -8.548333325922215],
              [-38.119166666408489, -8.547499993429881],
              [-38.118333333016835, -8.547499993429881],
              [-38.118333333016835, -8.544999993255033],
              [-38.117499999625238, -8.544999993255033],
              [-38.117499999625238, -8.544166658964116],
              [-38.116666665334321, -8.544166658964116],
              [-38.116666666233641, -8.540833326296934],
              [-38.117499999625238, -8.540833325397614],
              [-38.117499998725918, -8.537499993629751],
              [-38.115833331942667, -8.537499993629751],
              [-38.115833332841987, -8.536666659338835],
              [-38.114166666058736, -8.536666659338835],
              [-38.114166666058736, -8.534999993454903],
              [-38.113333331767819, -8.534999993454903],
              [-38.113333331767819, -8.532499993279998],
              [-38.113333331767819, -8.531666658989081],
              [-38.114166666958056, -8.531666658989081],
              [-38.114166667857376, -8.529166660612873],
              [-38.11499999945039, -8.529166659713553],
              [-38.11500000034971, -8.526666659538648],
              [-38.114166664260097, -8.526666659538648],
              [-38.114166666058736, -8.525833326147051],
              [-38.112499998376165, -8.525833326147051],
              [-38.112500001074125, -8.5241666593638],
              [-38.111666666783208, -8.5241666593638],
              [-38.111666666783208, -8.523333325972146],
              [-38.11, -8.523333325972146],
              [-38.11, -8.522499992580549],
              [-38.109166667507679, -8.522499993479869],
              [-38.109166667507679, -8.521666659188952],
              [-38.108333331418066, -8.521666659188952],
              [-38.108333335015345, -8.520833324897978],
              [-38.107499999825109, -8.520833326696618],
              [-38.107499998925789, -8.519166659014047],
              [-38.106666665534135, -8.519166659014047],
              [-38.106666665534135, -8.51833332742109],
              [-38.103333333766273, -8.51833332652177],
              [-38.103333331967633, -8.516666659738519],
              [-38.102500000374675, -8.516666657939879],
              [-38.102499997676716, -8.515833325447545],
              [-38.101666666983078, -8.515833326346865],
              [-38.101666667882398, -8.514999992955268],
              [-38.093333331268184, -8.514999992955268],
              [-38.093333331268184, -8.515833325447545],
              [-38.091666665384253, -8.515833326346865],
              [-38.091666668082212, -8.516666659738519],
              [-38.08833333271707, -8.516666659738519],
              [-38.08833333451571, -8.517499994029436],
              [-38.087499999325473, -8.517499993130116],
              [-38.087499999325473, -8.516666659738519],
              [-38.085000001848584, -8.516666659738519],
              [-38.084999998251249, -8.515833326346865],
              [-38.079999999700192, -8.515833325447545],
              [-38.079999999700192, -8.516666659738519],
              [-38.079166667207858, -8.516666659738519],
              [-38.079166665409218, -8.514166659563614],
              [-38.078333330218982, -8.514166658664294],
              [-38.078333333816261, -8.512499993679739],
              [-38.077499999525287, -8.512499993679739],
              [-38.077499998625967, -8.510833325097849],
              [-38.07666666703301, -8.510833325997169],
              [-38.07666666523437, -8.509166659213918],
              [-38.075833332742093, -8.509166659213918],
              [-38.075833333641413, -8.506666659039013],
              [-38.074999998451119, -8.506666659039013],
              [-38.075000000249759, -8.503333327271207],
              [-38.074166665958842, -8.503333325472568],
              [-38.074166666858162, -8.499166658514412],
              [-38.073333333466508, -8.499166659413731],
              [-38.073333332567188, -8.495833326746606],
              [-38.072499998276271, -8.495833326746606],
              [-38.072499998276271, -8.493333326571701],
              [-38.071666665783937, -8.493333326571701],
              [-38.071666667582576, -8.494166659064035],
              [-38.07083333239234, -8.494166659064035],
              [-38.07083333329166, -8.494999993354952],
              [-38.069166667407728, -8.494999993354952],
              [-38.069166666508409, -8.495833324947967],
              [-38.067499998825838, -8.495833326746606],
              [-38.067500000624477, -8.496666659238883],
              [-38.065000000449629, -8.496666659238883],
              [-38.06499999865099, -8.497499993529857],
              [-38.063333332767058, -8.497499993529857],
              [-38.063333333666378, -8.498333326022134],
              [-38.061666666883127, -8.498333326022134],
              [-38.061666666883127, -8.499166660313051],
              [-38.059166666708279, -8.499166659413731],
              [-38.059166665808959, -8.499999993704705],
              [-38.058333331517986, -8.499999993704705],
              [-38.058333332417305, -8.500833325297663],
              [-38.056666667432694, -8.500833326196982],
              [-38.056666667432694, -8.501666659588636],
              [-38.054999998850803, -8.501666658689317],
              [-38.054999999750123, -8.502499992080914],
              [-38.054166666358526, -8.502499992980233],
              [-38.054166667257846, -8.504166660662804],
              [-38.052500000474595, -8.504166661562124],
              [-38.051666667082998, -8.504166659763484],
              [-38.051666665284358, -8.503333325472568],
              [-38.050833331892704, -8.503333326371887],
              [-38.050833331892704, -8.502499992980233],
              [-38.049166666008773, -8.502499992980233],
              [-38.049166666908093, -8.501666658689317],
              [-38.048333332617176, -8.501666659588636],
              [-38.048333331717856, -8.500833325297663],
              [-38.047499998326202, -8.500833326196982],
              [-38.047499998326202, -8.501666659588636],
              [-38.044999999050674, -8.501666659588636],
              [-38.044999999050674, -8.502499992980233],
              [-38.044166665659077, -8.502499992980233],
              [-38.044166665659077, -8.503333326371887],
              [-38.042500000674465, -8.503333325472568],
              [-38.042499998875826, -8.504166659763484],
              [-38.041666665484172, -8.504166659763484],
              [-38.041666667282811, -8.504999993155138],
              [-38.039999999600241, -8.504999993155138],
              [-38.039999999600241, -8.504166657964845],
              [-38.039166665309324, -8.504166659763484],
              [-38.039166665309324, -8.504999993155138],
              [-38.035833331742822, -8.504999992255819],
              [-38.035833332642142, -8.504166659763484],
              [-38.034999998351225, -8.504166657964845],
              [-38.035000001049184, -8.503333327271207],
              [-38.032499999974959, -8.503333326371887],
              [-38.032499999974959, -8.504166659763484],
              [-38.028333330318901, -8.504166659763484],
              [-38.028333330318901, -8.504999993155138],
              [-38.026666668032249, -8.504999992255819],
              [-38.02666666533429, -8.506666659039013],
              [-38.025833332842012, -8.506666659039013],
              [-38.025833331942692, -8.505833326546735],
              [-38.024166666058761, -8.505833327446055],
              [-38.024166664260122, -8.506666659039013],
              [-38.023333334465747, -8.506666659039013],
              [-38.023333331767788, -8.507499993329986],
              [-38.02, -8.507499993329986],
              [-38.019999999100662, -8.508333326721583],
              [-38.018333333216731, -8.508333326721583],
              [-38.018333332317411, -8.509166659213918],
              [-38.017499999825077, -8.509166659213918],
              [-38.017499998925757, -8.508333326721583],
              [-38.01666666553416, -8.508333324922944],
              [-38.01666666643348, -8.509166659213918],
              [-38.015000000549549, -8.509166660113237],
              [-38.014999999650229, -8.509999993504834],
              [-38.013333332866978, -8.509999993504834],
              [-38.013333332866978, -8.509166658314598],
              [-38.011666666083727, -8.509166659213918],
              [-38.011666666983047, -8.508333326721583],
              [-38.007499998226308, -8.508333326721583],
              [-38.007500000924267, -8.509166659213918],
              [-38.006666665733974, -8.509166659213918],
              [-38.006666665733974, -8.507499993329986],
              [-38.005833332342377, -8.507499993329986],
              [-38.005833332342377, -8.506666659039013],
              [-38.005000000749419, -8.506666659039013],
              [-38.004999999850043, -8.505833326546735],
              [-38.004166667357765, -8.505833326546735],
              [-38.004166667357765, -8.504999993155138],
              [-38.003333331268152, -8.504999993155138],
              [-38.003333331268152, -8.504166659763484],
              [-38.001666665384278, -8.504166659763484],
              [-38.001666666283597, -8.503333326371887],
              [-38.000833332891943, -8.503333326371887],
              [-38.000833331992624, -8.504166659763484],
              [-38.000000000399666, -8.504166660662804],
              [-37.999999999500346, -8.504999993155138],
              [-37.998333331817776, -8.504999993155138],
              [-37.998333332717095, -8.505833326546735],
              [-37.997499998426122, -8.505833326546735],
              [-37.997499998426122, -8.506666659039013],
              [-37.996666665933844, -8.506666659039013],
              [-37.996666667732484, -8.509166659213918],
              [-37.997500000224761, -8.509166659213918],
              [-37.997499998426122, -8.510833325097849],
              [-37.996666666833164, -8.510833325997169],
              [-37.996666667732484, -8.514999992955268],
              [-37.995000000049913, -8.514999992955268],
              [-37.995000000949233, -8.515833326346865],
              [-37.991666667382731, -8.515833326346865],
              [-37.991666667382731, -8.516666659738519],
              [-37.990833333091814, -8.516666659738519],
              [-37.990833331293175, -8.517499993130116],
              [-37.98999999970016, -8.517499993130116],
              [-37.98999999880084, -8.51833332652177],
              [-37.989166665409243, -8.51833332652177],
              [-37.989166666308563, -8.521666659188952],
              [-37.988333332017589, -8.521666659188952],
              [-37.988333332017589, -8.522499993479869],
              [-37.983333332567213, -8.522499993479869],
              [-37.983333332567213, -8.523333325972146],
              [-37.982499998276239, -8.523333327770786],
              [-37.982499998276239, -8.5241666593638],
              [-37.981666667582601, -8.5241666593638],
              [-37.981666666683282, -8.524999993654717],
              [-37.978333334016099, -8.524999993654717],
              [-37.978333334016099, -8.525833326147051],
              [-37.977499998825863, -8.525833326147051],
              [-37.977499999725183, -8.526666659538648],
              [-37.971666666883152, -8.526666659538648],
              [-37.970000000099901, -8.526666659538648],
              [-37.969999998301262, -8.525833326147051],
              [-37.967499999025677, -8.525833327046371],
              [-37.967499999924996, -8.521666660088272],
              [-37.966666665634079, -8.521666659188952],
              [-37.966666666533399, -8.519999993305021],
              [-37.964999998850828, -8.519999993305021],
              [-37.964999998850828, -8.51833332652177],
              [-37.964166665459175, -8.51833332652177],
              [-37.964166667257871, -8.517499993130116],
              [-37.963333333866217, -8.517499994029436],
              [-37.963333332067577, -8.516666659738519],
              [-37.961666665284326, -8.516666659738519],
              [-37.961666667082966, -8.515833326346865],
              [-37.958333331717824, -8.515833326346865],
              [-37.958333333516464, -8.516666659738519],
              [-37.958333334415784, -8.517499993130116],
              [-37.959166666908118, -8.517499994029436],
              [-37.959166666908118, -8.51833332652177],
              [-37.958333331717824, -8.51833332652177],
              [-37.958333331717824, -8.519166659014047],
              [-37.957500001024187, -8.519166659014047],
              [-37.957499998326227, -8.519999993305021],
              [-37.956666665833893, -8.519999993305021],
              [-37.956666667632589, -8.520833324897978],
              [-37.955833333341616, -8.520833326696618],
              [-37.955833333341616, -8.521666659188952],
              [-37.954999999050699, -8.521666659188952],
              [-37.954999999950019, -8.525833326147051],
              [-37.954166665659045, -8.525833326147051],
              [-37.954166667457685, -8.528333326321899],
              [-37.954999999050699, -8.528333326321899],
              [-37.955000000849338, -8.52999999310515],
              [-37.954166667457685, -8.52999999310515],
              [-37.954166665659045, -8.530833326496747],
              [-37.953333331368128, -8.530833326496747],
              [-37.953333331368128, -8.531666658989081],
              [-37.951666664584877, -8.531666658989081],
              [-37.951666666383517, -8.534999993454903],
              [-37.950833332092543, -8.534999994354223],
              [-37.949166665309292, -8.534999993454903],
              [-37.949166666208612, -8.53416665916393],
              [-37.946666666933083, -8.53416665916393],
              [-37.946666666033764, -8.533333326671652],
              [-37.944166666758235, -8.533333325772333],
              [-37.944166665858916, -8.535833325947181],
              [-37.944166665858916, -8.537499993629751],
              [-37.943333332467262, -8.537499993629751],
              [-37.943333333366581, -8.538333326122029],
              [-37.942499999075665, -8.538333326122029],
              [-37.942499999974984, -8.544166658964116],
              [-37.94166666658333, -8.544166658964116],
              [-37.941666665684011, -8.544999993255033],
              [-37.940833332292414, -8.544999994154352],
              [-37.940833334091053, -8.545833327546006],
              [-37.940000000699456, -8.545833326646687],
              [-37.939999999800079, -8.546666659138964],
              [-37.939166666408482, -8.546666659138964],
              [-37.939166667307802, -8.5474999943292],
              [-37.938333331218246, -8.547499993429881],
              [-37.938333331218246, -8.548333325922215],
              [-37.937499998725912, -8.548333325922215],
              [-37.937499998725912, -8.549166659313812],
              [-37.936666665334315, -8.549166659313812],
              [-37.936666665334315, -8.557499993230067],
              [-37.9358333337413, -8.557499992330747],
              [-37.93583333284198, -8.558333326621664],
              [-37.935000000349703, -8.558333326621664],
              [-37.935000000349703, -8.559166659113998],
              [-37.934166666058729, -8.559166659113998],
              [-37.934166667857369, -8.559999993404915],
              [-37.934999998551064, -8.559999993404915],
              [-37.934999999450383, -8.560833325897249],
              [-37.934166666058729, -8.560833325897249],
              [-37.934166666958049, -8.561666659288846],
              [-37.932499999275478, -8.561666660188166],
              [-37.932499999275478, -8.56249999357982],
              [-37.931666665883881, -8.56249999357982],
              [-37.931666666783201, -8.564999993754668],
              [-37.930833333391604, -8.564999993754668],
              [-37.930833331592908, -8.566666659638599],
              [-37.93, -8.566666659638599],
              [-37.92999999910063, -8.569166658914128],
              [-37.929166665709033, -8.569166658914128],
              [-37.929166667507673, -8.569999993205101],
              [-37.92833333051874, -8.569999993205101],
              [-37.928333332317379, -8.570833325697379],
              [-37.927500001623741, -8.570833326596698],
              [-37.927500000724422, -8.571666659088976],
              [-37.926666668232087, -8.571666659088976],
              [-37.926666666433448, -8.57249999248063],
              [-37.925833331243211, -8.57249999248063],
              [-37.925833333041851, -8.573333326771547],
              [-37.924999999650197, -8.573333326771547],
              [-37.924999999650197, -8.574166659263881],
              [-37.924166668057239, -8.574166659263881],
              [-37.9241666662586, -8.574999993554798],
              [-37.923333333766323, -8.574999993554798],
              [-37.923333331967626, -8.57833332622198],
              [-37.922499998576029, -8.57833332622198],
              [-37.922499999475349, -8.579166660512954],
              [-37.921666666083752, -8.579166660512954],
              [-37.921666666083752, -8.579999993005231],
              [-37.920833333591418, -8.579999992105911],
              [-37.920833333591418, -8.580833326396828],
              [-37.919166667707486, -8.580833326396828],
              [-37.919166666808167, -8.581666658889162],
              [-37.91833333161793, -8.581666658889162],
              [-37.91833333341657, -8.582499993180079],
              [-37.917499998226276, -8.582499993180079],
              [-37.917499998226276, -8.583333326571733],
              [-37.916666665733999, -8.583333325672413],
              [-37.916666666633319, -8.58416665906401],
              [-37.913333333966136, -8.58416665906401],
              [-37.913333331268177, -8.585833326746581],
              [-37.912500000574539, -8.585833326746581],
              [-37.912500000574539, -8.587499993529832],
              [-37.911666668082205, -8.587499993529832],
              [-37.911666668082205, -8.588333326022109],
              [-37.9124999987759, -8.588333326022109],
              [-37.912500000574539, -8.591666659588611],
              [-37.913333331268177, -8.591666660487931],
              [-37.913333333066817, -8.59583332744603],
              [-37.912499999675219, -8.59583332654671],
              [-37.9124999987759, -8.597499993329961],
              [-37.911666668082205, -8.597499993329961],
              [-37.911666665384246, -8.598333324922976],
              [-37.910833333791288, -8.598333324922976],
              [-37.910833332891968, -8.601666659388798],
              [-37.909999999500315, -8.601666659388798],
              [-37.909999998600995, -8.602499993679714],
              [-37.909166667008037, -8.602499993679714],
              [-37.909166667907357, -8.604999992955243],
              [-37.908333333616383, -8.604999992955243],
              [-37.908333332717064, -8.605833326346897],
              [-37.905833332542215, -8.605833326346897],
              [-37.905833333441535, -8.606666659738494],
              [-37.905833331642896, -8.608333327421064],
              [-37.906666666833189, -8.608333326521745],
              [-37.906666665933869, -8.609166659014079],
              [-37.907499998426147, -8.609166659014079],
              [-37.907499999325466, -8.611666659188927],
              [-37.909166667008037, -8.611666660088247],
              [-37.909166665209398, -8.614999993654749],
              [-37.910000000399634, -8.614999993654749],
              [-37.909999998600995, -8.615833326147026],
              [-37.910833332891968, -8.615833326147026],
              [-37.910833332891968, -8.629166658614338],
              [-37.911666666283566, -8.629166659513658],
              [-37.911666665384246, -8.631666659688563],
              [-37.912500000574539, -8.631666659688563],
              [-37.9124999987759, -8.636666659138939],
              [-37.911666668082205, -8.636666659138939],
              [-37.911666666283566, -8.64416665966354],
              [-37.912500000574539, -8.64416665966354],
              [-37.9124999987759, -8.645833326446791],
              [-37.913333331268177, -8.645833326446791],
              [-37.913333333066817, -8.646666658939125],
              [-37.913333333066817, -8.647499993230042],
              [-37.912499999675219, -8.647499993230042],
              [-37.912499999675219, -8.649999992505627],
              [-37.911666666283566, -8.649999993404947],
              [-37.911666666283566, -8.650833325897224],
              [-37.910833331992649, -8.650833325897224],
              [-37.910833331992649, -8.651666660188198],
              [-37.909999998600995, -8.651666658389502],
              [-37.910000000399634, -8.652499993579795],
              [-37.909166667008037, -8.652499993579795],
              [-37.909166665209398, -8.654166659463726],
              [-37.907499998426147, -8.654166659463726],
              [-37.907500000224786, -8.654999993754643],
              [-37.906666666833189, -8.654999993754643],
              [-37.906666665933869, -8.655833326246977],
              [-37.905833331642896, -8.655833326246977],
              [-37.905833331642896, -8.656666659638574],
              [-37.904166667557604, -8.656666659638574],
              [-37.904166666658284, -8.659999993205076],
              [-37.904999998251299, -8.659999993205076],
              [-37.905000000949258, -8.662499993379924],
              [-37.905833333441535, -8.662499994279244],
              [-37.905833333441535, -8.663333324972939],
              [-37.905000000949258, -8.663333326771578],
              [-37.904999998251299, -8.664166659263856],
              [-37.904166666658284, -8.664166659263856],
              [-37.904166666658284, -8.665833325147787],
              [-37.900833333091782, -8.665833326946426],
              [-37.900833333091782, -8.66666666033808],
              [-37.899999999700185, -8.666666659438761],
              [-37.899999998800865, -8.665833326047107],
              [-37.894999999350432, -8.665833325147787],
              [-37.895000000249752, -8.664999993554829],
              [-37.894166666858155, -8.66499999265551],
              [-37.894166666858155, -8.665833326047107],
              [-37.893333332567181, -8.665833326047107],
              [-37.893333331667861, -8.666666659438761],
              [-37.892499998276264, -8.666666659438761],
              [-37.892499998276264, -8.665833326047107],
              [-37.889999999900056, -8.665833325147787],
              [-37.889999999900056, -8.666666659438761],
              [-37.889166665609082, -8.666666659438761],
              [-37.889166665609082, -8.668333326221955],
              [-37.887499999725151, -8.668333326221955],
              [-37.887499999725151, -8.669166659613609],
              [-37.886666669031513, -8.669166659613609],
              [-37.886666665434234, -8.669999992105886],
              [-37.885833333841219, -8.669999993005206],
              [-37.8858333329419, -8.67083332639686],
              [-37.885000000449622, -8.67083332639686],
              [-37.884999998650983, -8.674166659064042],
              [-37.88583333204258, -8.674166659064042],
              [-37.885833330243941, -8.674999992455639],
              [-37.885000000449622, -8.674999993354959],
              [-37.884999999550303, -8.679166659413738],
              [-37.884166667957345, -8.679166659413738],
              [-37.884166667057968, -8.679999993704712],
              [-37.883333331867732, -8.679999993704712],
              [-37.883333331867732, -8.684999993155088],
              [-37.882499999375455, -8.684999994054408],
              [-37.882499998476078, -8.68666665903902],
              [-37.88166666688312, -8.68666665903902],
              [-37.881666665983801, -8.687499993329993],
              [-37.880833331692884, -8.687499993329993],
              [-37.880833333491523, -8.689166659213925],
              [-37.88166666688312, -8.689166658314605],
              [-37.88166666688312, -8.694166659563621],
              [-37.880833332592204, -8.694166659563621],
              [-37.880833331692884, -8.695833326346872],
              [-37.87999999830123, -8.695833326346872],
              [-37.87999999740191, -8.696666659738526],
              [-37.879166667607592, -8.696666659738526],
              [-37.879166666708272, -8.698333326521777],
              [-37.87999999830123, -8.698333326521777],
              [-37.880000000099869, -8.700833326696625],
              [-37.879166666708272, -8.700833326696625],
              [-37.879166667607592, -8.701666660088222],
              [-37.878333332417299, -8.701666659188902],
              [-37.878333334215938, -8.702499993479876],
              [-37.879166666708272, -8.702499992580556],
              [-37.879166665808953, -8.704166659363807],
              [-37.877499999925021, -8.704166659363807],
              [-37.877500000824341, -8.704999993654724],
              [-37.876666667432687, -8.704999993654724],
              [-37.876666667432687, -8.705833326147058],
              [-37.875833331343131, -8.705833326147058],
              [-37.87583333404109, -8.706666659538655],
              [-37.874166666358519, -8.706666658639335],
              [-37.874166669056478, -8.709166660612823],
              [-37.873333332966922, -8.709166659713503],
              [-37.873333332966922, -8.709999993105157],
              [-37.872499998675949, -8.709999993105157],
              [-37.872499999575268, -8.710833326496754],
              [-37.876666665634048, -8.710833326496754],
              [-37.876666665634048, -8.711666658089769],
              [-37.878333332417299, -8.711666658989088],
              [-37.878333332417299, -8.712499993280005],
              [-37.879166665808953, -8.712499994179325],
              [-37.879166665808953, -8.714166658264617],
              [-37.880833332592204, -8.714166659163936],
              [-37.880833332592204, -8.71499999345491],
              [-37.88166666688312, -8.71499999345491],
              [-37.881666665983801, -8.716666659338784],
              [-37.883333331867732, -8.716666659338784],
              [-37.883333333666371, -8.717499993629758],
              [-37.884166667957345, -8.717499994529078],
              [-37.884166665259329, -8.718333325222716],
              [-37.884999999550303, -8.718333326122035],
              [-37.884999999550303, -8.71916665861437],
              [-37.886666665434234, -8.719166659513689],
              [-37.886666666333554, -8.719999992905286],
              [-37.887499999725151, -8.719999992905286],
              [-37.887499999725151, -8.721666659688537],
              [-37.888333334016124, -8.721666659688537],
              [-37.888333334016124, -8.722499993080191],
              [-37.890000000799375, -8.722499993080191],
              [-37.890000000799375, -8.725833326646637],
              [-37.890833333291653, -8.725833326646637],
              [-37.890833332392333, -8.727499993429888],
              [-37.89166666668325, -8.727499993429888],
              [-37.89166666668325, -8.728333325922222],
              [-37.890833333291653, -8.728333325022902],
              [-37.890833332392333, -8.729999993604793],
              [-37.892499998276264, -8.729999992705473],
              [-37.892500000974223, -8.73083332609707],
              [-37.893333331667861, -8.73083332609707],
              [-37.893333332567181, -8.731666659488724],
              [-37.894166666858155, -8.731666658589404],
              [-37.894166665958835, -8.732499992880321],
              [-37.895000000249752, -8.732499992880321],
              [-37.895000002048391, -8.733333326271918],
              [-37.895833331842766, -8.733333326271918],
              [-37.895833331842766, -8.734166659663572],
              [-37.897499998626017, -8.734166658764252],
              [-37.897499999525337, -8.735833326446823],
              [-37.898333332916934, -8.735833326446823],
              [-37.898333332916934, -8.737499993230074],
              [-37.899166666308588, -8.737499993230074],
              [-37.899166665409211, -8.738333326621671],
              [-37.898333332017614, -8.738333326621671],
              [-37.898333332017614, -8.739166659114005],
              [-37.899166665409211, -8.739166659114005],
              [-37.899166665409211, -8.740833325897199],
              [-37.899999997901546, -8.74083332499788],
              [-37.899999999700185, -8.74249999357977],
              [-37.900833331293143, -8.74249999357977],
              [-37.900833333091782, -8.743333326072104],
              [-37.901666666483436, -8.743333326072104],
              [-37.901666666483436, -8.744999994653995],
              [-37.902499999875033, -8.744999993754675],
              [-37.902499998975713, -8.746666658739287],
              [-37.903333333266687, -8.746666660537926],
              [-37.903333331468048, -8.749166658914135],
              [-37.904166667557604, -8.749166658914135],
              [-37.904166665758964, -8.752499993379956],
              [-37.904999998251299, -8.752499993379956],
              [-37.904999998251299, -8.754166659263888],
              [-37.904166666658284, -8.754166659263888],
              [-37.904166666658284, -8.754999994454124],
              [-37.904999998251299, -8.754999993554804],
              [-37.904999998251299, -8.755833326047139],
              [-37.904166666658284, -8.755833326047139],
              [-37.904166665758964, -8.761666658889169],
              [-37.903333331468048, -8.761666658889169],
              [-37.903333332367367, -8.764999993354991],
              [-37.904166665758964, -8.764999993354991],
              [-37.904166665758964, -8.766666660138185],
              [-37.903333333266687, -8.766666659238865],
              [-37.903333332367367, -8.767499993529839],
              [-37.904166665758964, -8.767499992630519],
              [-37.904166665758964, -8.768333326022116],
              [-37.903333331468048, -8.768333326022116],
              [-37.903333332367367, -8.770833326197021],
              [-37.902499998975713, -8.770833326197021],
              [-37.902500000774353, -8.773333325472549],
              [-37.902499998975713, -8.774166659763466],
              [-37.903333331468048, -8.774166657964827],
              [-37.903333333266687, -8.77499999315512],
              [-37.904166666658284, -8.77499999315512],
              [-37.904166666658284, -8.775833326546717],
              [-37.905833332542215, -8.775833326546717],
              [-37.905833331642896, -8.776666658139732],
              [-37.907500000224786, -8.776666659039051],
              [-37.907499999325466, -8.777499993329968],
              [-37.908333333616383, -8.777499993329968],
              [-37.908333332717064, -8.778333326721622],
              [-37.909166667907357, -8.778333327620942],
              [-37.909166667907357, -8.779166659213899],
              [-37.910833331992649, -8.779166659213899],
              [-37.910833331992649, -8.78083332599715],
              [-37.9124999987759, -8.78083332599715],
              [-37.9124999987759, -8.782499993679721],
              [-37.91416666735779, -8.782499993679721],
              [-37.914166665559151, -8.786666659738501],
              [-37.914999998051428, -8.786666659738501],
              [-37.914999998950748, -8.788333326521752],
              [-37.914999998950748, -8.789166659014029],
              [-37.915833333241665, -8.789166659014029],
              [-37.915833331443025, -8.789999992405683],
              [-37.917500000024916, -8.789999993305003],
              [-37.917499998226276, -8.7908333266966],
              [-37.92000000109914, -8.7908333266966],
              [-37.919999999300501, -8.791666659188934],
              [-37.920833332692098, -8.791666659188934],
              [-37.920833331792778, -8.792499993479851],
              [-37.921666667882391, -8.792499993479851],
              [-37.921666667882391, -8.793333327770824],
              [-37.922499999475349, -8.793333325972185],
              [-37.922499999475349, -8.794166660263102],
              [-37.923333332867003, -8.794166659363782],
              [-37.923333331967626, -8.794999994554075],
              [-37.923333333766323, -8.79666665863931],
              [-37.92416666445996, -8.796666659538687],
              [-37.92416666535928, -8.797499992930284],
              [-37.925000000549517, -8.797499992930284],
              [-37.924999999650197, -8.798333325422561],
              [-37.925833333041851, -8.798333325422561],
              [-37.926666667332768, -8.798333326321881],
              [-37.926666665534128, -8.799166657914895],
              [-37.927500000724422, -8.799166659713535],
              [-37.927499999825102, -8.799999992205812],
              [-37.934999998551064, -8.799999993105132],
              [-37.934999998551064, -8.800833326496786],
              [-37.937499999625231, -8.800833326496786],
              [-37.937499998725912, -8.799999993105132],
              [-37.940833331393094, -8.799999993105132],
              [-37.940833332292414, -8.799166659713535],
              [-37.942499999075665, -8.799166659713535],
              [-37.942499999974984, -8.799999993105132],
              [-37.943333333366581, -8.799999993105132],
              [-37.943333331567942, -8.800833326496786],
              [-37.944166667657555, -8.800833326496786],
              [-37.944166665858916, -8.801666658989063],
              [-37.945000001049152, -8.801666658989063],
              [-37.944999998351193, -8.804166659163968],
              [-37.945000001049152, -8.804999993454885],
              [-37.944166666758235, -8.804999993454885],
              [-37.944166667657555, -8.805833325047843],
              [-37.943333332467262, -8.805833325947162],
              [-37.943333332467262, -8.806666659338816],
              [-37.942499999075665, -8.806666659338816],
              [-37.942499999974984, -8.807499993629733],
              [-37.94166666748265, -8.807499993629733],
              [-37.94166666658333, -8.810833326296915],
              [-37.940833331393094, -8.810833326296915],
              [-37.940833332292414, -8.812499993080166],
              [-37.939999999800079, -8.812499993080166],
              [-37.939999999800079, -8.815833326646668],
              [-37.939166665509163, -8.815833326646668],
              [-37.939166666408482, -8.820833326996421],
              [-37.938333334815525, -8.820833326097102],
              [-37.938333330318869, -8.821666659488699],
              [-37.937499998725912, -8.821666659488699],
              [-37.937499998725912, -8.822499994678992],
              [-37.938333331218246, -8.822499992880296],
              [-37.938333331218246, -8.82333332627195],
              [-37.939166667307802, -8.82333332627195],
              [-37.939166665509163, -8.824166659663547],
              [-37.938333333916205, -8.824166658764227],
              [-37.938333333016885, -8.827499993230049],
              [-37.939166665509163, -8.827499993230049],
              [-37.939166665509163, -8.829999993404897],
              [-37.938333334815525, -8.829999993404897],
              [-37.938333333016885, -8.830833325897231],
              [-37.937499998725912, -8.830833325897231],
              [-37.937499997826592, -8.831666658389508],
              [-37.936666665334315, -8.831666659288828],
              [-37.936666668032274, -8.832499993579802],
              [-37.937499999625231, -8.832499993579802],
              [-37.937499998725912, -8.83499999195601],
              [-37.938333330318869, -8.83499999375465],
              [-37.938333331218246, -8.838333326421832],
              [-37.937499998725912, -8.838333326421832],
              [-37.937499998725912, -8.84083332659668],
              [-37.938333331218246, -8.84083332659668],
              [-37.938333333016885, -8.843333327670905],
              [-37.939166665509163, -8.843333326771585],
              [-37.939166666408482, -8.845833326047114],
              [-37.939999999800079, -8.845833326047114],
              [-37.939999999800079, -8.851666660687783],
              [-37.940833331393094, -8.851666658889144],
              [-37.940833331393094, -8.852499993180118],
              [-37.94166666658333, -8.852499993180118],
              [-37.941666665684011, -8.853333326571715],
              [-37.942500000874304, -8.853333326571715],
              [-37.942499999974984, -8.854166659963312],
              [-37.943333332467262, -8.854166659063992],
              [-37.943333332467262, -8.854999993354966],
              [-37.944166665858916, -8.854999993354966],
              [-37.944166665858916, -8.856666659238897],
              [-37.944999999250513, -8.856666660138217],
              [-37.945000001049152, -8.857499993529814],
              [-37.946666667832403, -8.857499993529814],
              [-37.946666666933083, -8.858333326921468],
              [-37.947499998526041, -8.858333326022148],
              [-37.947499999425418, -8.859166659413745],
              [-37.948333332817015, -8.859166660313065],
              [-37.948333332817015, -8.859999993704719],
              [-37.949166665309292, -8.859999993704719],
              [-37.949166666208612, -8.860833325297676],
              [-37.951666665484197, -8.860833326196996],
              [-37.951666664584877, -8.86166666048797],
              [-37.953333334066087, -8.86166665958865],
              [-37.953333334066087, -8.862499992980247],
              [-37.954166665659045, -8.862499992980247],
              [-37.954166666558365, -8.863333326371844],
              [-37.953333334066087, -8.863333326371844],
              [-37.953333331368128, -8.866666659039026],
              [-37.954166666558365, -8.866666659039026],
              [-37.954166664759725, -8.871666659388779],
              [-37.954999999050699, -8.871666659388779],
              [-37.954999999950019, -8.872499993679696],
              [-37.956666665833893, -8.872499993679696],
              [-37.956666665833893, -8.87333332617203],
              [-37.959166664210159, -8.873333325272711],
              [-37.959166666908118, -8.874166659563627],
              [-37.963333332966897, -8.874166658664308],
              [-37.963333332966897, -8.874999992955281],
              [-37.96416666905651, -8.874999992955281],
              [-37.964166666358494, -8.875833326346878],
              [-37.966666667432719, -8.875833327246198],
              [-37.966666665634079, -8.874166659563627],
              [-37.969166665808928, -8.874166660462947],
              [-37.969166665808928, -8.874999992955281],
              [-37.970833331692859, -8.874999994753921],
              [-37.970833333491498, -8.874166659563627],
              [-37.97416666885664, -8.874166659563627],
              [-37.97416666795732, -8.87333332617203],
              [-37.974999999550278, -8.87333332617203],
              [-37.975000000449597, -8.872499993679696],
              [-37.97833333221746, -8.872499993679696],
              [-37.978333334016099, -8.87333332617203],
              [-37.982500000974198, -8.87333332617203],
              [-37.982499998276239, -8.874166659563627],
              [-37.983333331667893, -8.874166659563627],
              [-37.983333333466533, -8.874999992955281],
              [-37.984999999350464, -8.874999992955281],
              [-37.984999998451144, -8.876666657939893],
              [-37.986666665234395, -8.876666659738532],
              [-37.986666665234395, -8.879166659014061],
              [-37.985833331842741, -8.879166659014061],
              [-37.985833331842741, -8.884166659363814],
              [-37.984999998451144, -8.884166659363814],
              [-37.984999998451144, -8.886666659538662],
              [-37.983333331667893, -8.886666659538662],
              [-37.983333333466533, -8.887499992030939],
              [-37.97833333221746, -8.887499992930259],
              [-37.97833333221746, -8.888333326321913],
              [-37.975833332042612, -8.888333326321913],
              [-37.975833332042612, -8.887499994728955],
              [-37.974999998650958, -8.887499992030939],
              [-37.974999998650958, -8.888333325422593],
              [-37.974166665259361, -8.888333326321913],
              [-37.97416666795732, -8.887499992930259],
              [-37.971666666883152, -8.887499992930259],
              [-37.971666666883152, -8.886666659538662],
              [-37.969166667607567, -8.886666658639342],
              [-37.969166665808928, -8.885833325247688],
              [-37.965000000649468, -8.885833327046385],
              [-37.964999998850828, -8.884999993654731],
              [-37.96250000047462, -8.884999993654731],
              [-37.96249999867598, -8.885833326147065],
              [-37.961666665284326, -8.885833326147065],
              [-37.961666665284326, -8.884999993654731],
              [-37.954999999050699, -8.884999993654731],
              [-37.954999999050699, -8.884166659363814],
              [-37.950833332092543, -8.884166659363814],
              [-37.950833333891183, -8.884999993654731],
              [-37.946666667832403, -8.884999993654731],
              [-37.946666666033764, -8.885833326147065],
              [-37.945833333541486, -8.885833326147065],
              [-37.945833331742847, -8.886666659538662],
              [-37.942499999075665, -8.886666659538662],
              [-37.942499999974984, -8.885833325247688],
              [-37.939999999800079, -8.885833326147065],
              [-37.939999999800079, -8.884999993654731],
              [-37.939166666408482, -8.884999993654731],
              [-37.938333333016885, -8.884999993654731],
              [-37.938333333016885, -8.886666658639342],
              [-37.937499998725912, -8.886666659538662],
              [-37.937499998725912, -8.887499992930259],
              [-37.936666665334315, -8.887499994728955],
              [-37.936666666233634, -8.88916665971351],
              [-37.937500000524551, -8.88916665971351],
              [-37.937499998725912, -8.893333325772346],
              [-37.938333333016885, -8.893333326671666],
              [-37.938333333916205, -8.894166658264623],
              [-37.939166666408482, -8.894166660063263],
              [-37.939166666408482, -8.895833325947194],
              [-37.939999999800079, -8.895833325947194],
              [-37.93999999890076, -8.897499993629765],
              [-37.940833331393094, -8.897499993629765],
              [-37.940833331393094, -8.899166659513696],
              [-37.941666665684011, -8.899166659513696],
              [-37.941666665684011, -8.899999994703933],
              [-37.942499999974984, -8.899999992905293],
              [-37.942500000874304, -8.900833327196267],
              [-37.944166665858916, -8.900833326296947],
              [-37.944166667657555, -8.901666659688544],
              [-37.945833330843527, -8.901666659688544],
              [-37.945833331742847, -8.902499993080198],
              [-37.947500000324737, -8.902499993080198],
              [-37.947499997626721, -8.903333326471795],
              [-37.950833332092543, -8.903333326471795],
              [-37.950833332991863, -8.904166658964073],
              [-37.952499998875794, -8.904166658064753],
              [-37.952499999775114, -8.904999993255046],
              [-37.953333331368128, -8.904999993255046],
              [-37.953333331368128, -8.905833326646643],
              [-37.955000000849338, -8.905833326646643],
              [-37.954999999050699, -8.906666659138978],
              [-37.957499999225547, -8.906666659138978],
              [-37.957499998326227, -8.907499993429894],
              [-37.958333333516464, -8.907499992530575],
              [-37.958333332617144, -8.908333325022909],
              [-37.960833332792049, -8.908333325922229],
              [-37.960833331892729, -8.907499992530575],
              [-37.96250000047462, -8.907499993429894],
              [-37.9624999995753, -8.908333325922229],
              [-37.963333332067577, -8.908333325922229],
              [-37.963333333866217, -8.909999993604799],
              [-37.965833334041065, -8.909999993604799],
              [-37.965833332242426, -8.910833326097077],
              [-37.966666666533399, -8.910833331492995],
              [-37.973333333666346, -8.910833326097077],
              [-37.973333333666346, -8.911666659488674],
              [-37.975833333841251, -8.911666659488674],
              [-37.975833333841251, -8.912499992880328],
              [-37.981666666683282, -8.912499999175566],
              [-37.982500000074879, -8.912499992880328],
              [-37.982499998276239, -8.911666659488674],
              [-37.983333332567213, -8.911666659488674],
              [-37.983333332567213, -8.910833325197757],
              [-37.988333332017589, -8.910833326097077],
              [-37.988333332017589, -8.911666659488674],
              [-37.989166666308563, -8.911666658589354],
              [-37.989166666308563, -8.912499992880328],
              [-37.995000000949233, -8.912499991981008],
              [-37.995000000049913, -8.913333326271925],
              [-37.995833331642871, -8.913333326271925],
              [-37.995833333441567, -8.914999993055176],
              [-37.999166667008012, -8.914999993954495],
              [-37.999166667008012, -8.91583332644683],
              [-37.999999998601027, -8.91583332644683],
              [-37.999999999500346, -8.916666658039787],
              [-38.001666666283597, -8.916666658939107],
              [-38.001666666283597, -8.917499993230081],
              [-38.002499999675194, -8.917499993230081],
              [-38.002499999675194, -8.918333326621678],
              [-38.003333333966168, -8.918333326621678],
              [-38.003333331268152, -8.919166659113955],
              [-38.004166667357765, -8.919166659113955],
              [-38.004166665559126, -8.919999993404929],
              [-38.005000000749419, -8.919999994304249],
              [-38.004999999850043, -8.920833327695846],
              [-38.005833332342377, -8.920833325897206],
              [-38.005833332342377, -8.92166665928886],
              [-38.006666667532613, -8.92166665928886],
              [-38.006666665733974, -8.922499992680457],
              [-38.007500000024947, -8.922499993579777],
              [-38.007499998226308, -8.923333325172791],
              [-38.008333331617905, -8.923333326072111],
              [-38.008333333416545, -8.924166659463708],
              [-38.009166666808198, -8.924166660363028],
              [-38.009166667707518, -8.924999993754682],
              [-38.009999999300476, -8.924999993754682],
              [-38.009999999300476, -8.93333332677156],
              [-38.01083333269213, -8.93333332767088],
              [-38.010833333591449, -8.934999993554811],
              [-38.010000001099115, -8.934999993554811],
              [-38.010000001099115, -8.935833326047089],
              [-38.009166667707518, -8.935833326047089],
              [-38.009166666808198, -8.936666659438742],
              [-38.008333331617905, -8.936666659438742],
              [-38.008333331617905, -8.940833326396842],
              [-38.009166666808198, -8.940833326396842],
              [-38.009166667707518, -8.941666658889176],
              [-38.010000001099115, -8.941666658889176],
              [-38.009999998401156, -8.946666659238872],
              [-38.007500000024947, -8.946666658339552],
              [-38.007499998226308, -8.948333326022123],
              [-38.006666666633294, -8.948333326022123],
              [-38.006666667532613, -8.949999993704694],
              [-38.005833332342377, -8.949999991906054],
              [-38.005833333241696, -8.950833326197028],
              [-38.004999999850043, -8.950833326197028],
              [-38.004999999850043, -8.951666659588625],
              [-38.006666664834654, -8.951666659588625],
              [-38.006666666633294, -8.953333325472556],
              [-38.005833332342377, -8.953333326371876],
              [-38.005833332342377, -8.954166659763473],
              [-38.004999998950723, -8.954166659763473],
              [-38.004999998950723, -8.954999993155127],
              [-38.005833332342377, -8.954999993155127],
              [-38.005833332342377, -8.955833326546724],
              [-38.004166665559126, -8.955833326546724],
              [-38.004166666458445, -8.958333326721629],
              [-38.003333333066848, -8.958333326721629],
              [-38.003333333966168, -8.959999993504823],
              [-38.002499998775875, -8.959999993504823],
              [-38.002500000574514, -8.962499993679728],
              [-38.001666668082237, -8.962499993679728],
              [-38.001666665384278, -8.964166660462979],
              [-38.000833331093304, -8.96416665866434],
              [-38.000833331992624, -8.96583332634691],
              [-37.999999998601027, -8.96583332724623],
              [-37.999999998601027, -8.967499992230785],
              [-37.999166665209373, -8.967499993130104],
              [-37.999166667008012, -8.968333326521758],
              [-37.998333333616415, -8.968333326521758],
              [-37.998333332717095, -8.969999993305009],
              [-37.997500000224761, -8.969999993305009],
              [-37.997500000224761, -8.971666659188941],
              [-37.996666665933844, -8.97166666008826],
              [-37.996666665933844, -8.974166659363789],
              [-37.997499998426122, -8.974166659363789],
              [-37.997500000224761, -8.974999993654762],
              [-37.996666665933844, -8.974999993654762],
              [-37.996666666833164, -8.976666659538637],
              [-37.995833331642871, -8.976666659538637],
              [-37.995833333441567, -8.978333326321888],
              [-37.994999998251274, -8.978333325422568],
              [-37.994999998251274, -8.979166659713542],
              [-37.994166665758996, -8.979166659713542],
              [-37.994166665758996, -8.979999993105139],
              [-37.993333331468023, -8.979999993105139],
              [-37.993333331468023, -8.980833325597473],
              [-37.992499998076426, -8.980833326496793],
              [-37.992499998975745, -8.983333326671641],
              [-37.991666665584091, -8.983333326671641],
              [-37.991666666483411, -8.98749999362974],
              [-37.990833331293175, -8.98749999362974],
              [-37.990833333091814, -8.99333332647177],
              [-37.98999999880084, -8.993333325572451],
              [-37.98999999880084, -8.994166658964104],
              [-37.989166667207883, -8.994166658964104],
              [-37.989166666308563, -8.994999993255021],
              [-37.988333332916909, -8.994999993255021],
              [-37.988333332017589, -8.995833326646675],
              [-37.987499998625992, -8.995833326646675],
              [-37.987500000424632, -8.997499993429926],
              [-37.986666667033035, -8.997499993429926],
              [-37.986666667033035, -8.998333325922204],
              [-37.985833331842741, -8.998333325922204],
              [-37.985833332742061, -8.999999993604774],
              [-37.984999998451144, -8.999999993604774],
              [-37.984999999350464, -9.000833326097052],
              [-37.98416666685813, -9.000833326097052],
              [-37.98416666685813, -9.001666659488706],
              [-37.983333331667893, -9.001666659488706],
              [-37.983333331667893, -9.002499994678942],
              [-37.983333331667893, -9.003333325372637],
              [-37.982500000074879, -9.003333326271957],
              [-37.98249999737692, -9.004166659663554],
              [-37.981666667582601, -9.004166659663554],
              [-37.981666666683282, -9.004999993055208],
              [-37.980833333291628, -9.004999993055208],
              [-37.980833331492988, -9.005833326446805],
              [-37.979999999900031, -9.005833326446805],
              [-37.979999999000711, -9.006666660737778],
              [-37.979166665609114, -9.006666658939139],
              [-37.979166667407753, -9.007499993230056],
              [-37.97833333131814, -9.007499993230056],
              [-37.978333334016099, -9.008333326621653],
              [-37.977500000624502, -9.008333326621653],
              [-37.977500000624502, -9.009166659113987],
              [-37.976666665434209, -9.009166658214667],
              [-37.976666665434209, -9.009999993404904],
              [-37.975833333841251, -9.009999993404904],
              [-37.975833332941932, -9.011666658389515],
              [-37.974999998650958, -9.011666658389515],
              [-37.974166667058, -9.011666659288835],
              [-37.974166667058, -9.010833325897238],
              [-37.973333331867707, -9.010833325897238],
              [-37.973333331867707, -9.009999993404904],
              [-37.971666665084456, -9.009999993404904],
              [-37.971666666883152, -9.009166659113987],
              [-37.969999998301262, -9.009166659113987],
              [-37.969999998301262, -9.008333326621653],
              [-37.967499999025677, -9.008333328420349],
              [-37.966666666533399, -9.008333326621653],
              [-37.966666665634079, -9.007499993230056],
              [-37.965833331343106, -9.007499993230056],
              [-37.965833334041065, -9.006666658939139],
              [-37.965000000649468, -9.006666658039762],
              [-37.965000000649468, -9.005833326446805],
              [-37.964166666358494, -9.005833326446805],
              [-37.964166665459175, -9.004999993954527],
              [-37.963333332067577, -9.004999992155888],
              [-37.963333332067577, -9.004166660562873],
              [-37.9624999995753, -9.004166658764234],
              [-37.96249999867598, -9.003333326271957],
              [-37.961666667082966, -9.003333325372637],
              [-37.961666667982286, -9.001666659488706],
              [-37.960833332792049, -9.001666659488706],
              [-37.960833332792049, -9.000833326097052],
              [-37.958333332617144, -9.000833326097052],
              [-37.958333331717824, -8.999999993604774],
              [-37.957499999225547, -8.999999993604774],
              [-37.957499999225547, -8.999166660213177],
              [-37.955833331542976, -8.999166658414481],
              [-37.955833332442296, -8.998333325922204],
              [-37.953333334066087, -8.998333325922204],
              [-37.953333331368128, -8.997499993429926],
              [-37.950000000499585, -8.997499993429926],
              [-37.949999998700946, -8.996666660038272],
              [-37.947499999425418, -8.996666659138953],
              [-37.947499998526041, -8.995833325747356],
              [-37.946666666933083, -8.995833326646675],
              [-37.946666666033764, -8.996666659138953],
              [-37.942499999974984, -8.996666659138953],
              [-37.942499999075665, -8.995833326646675],
              [-37.940833332292414, -8.995833326646675],
              [-37.940833332292414, -8.994999993255021],
              [-37.939166666408482, -8.994999994154341],
              [-37.939166665509163, -8.994166658964104],
              [-37.937499999625231, -8.994166658964104],
              [-37.937499999625231, -8.99333332737109],
              [-37.931666666783201, -8.99333332647177],
              [-37.931666666783201, -8.994166658964104],
              [-37.930833330693588, -8.994166658964104],
              [-37.930833331592908, -8.994999993255021],
              [-37.93, -8.994999992355702],
              [-37.92999999910063, -8.995833326646675],
              [-37.92833333141806, -8.995833326646675],
              [-37.92833333141806, -8.994999993255021],
              [-37.927499998925782, -8.994999993255021],
              [-37.927499998925782, -8.994166658964104],
              [-37.926666666433448, -8.994166658964104],
              [-37.926666665534128, -8.99333332647177],
              [-37.925000000549517, -8.99333332737109],
              [-37.924999998750877, -8.991666659688519],
              [-37.9241666662586, -8.991666659688519],
              [-37.924166668057239, -8.990833326296922],
              [-37.923333331068307, -8.990833325397602],
              [-37.923333332867003, -8.989999992905325],
              [-37.922500000374669, -8.989999992905325],
              [-37.922499998576029, -8.988333327021394],
              [-37.921666666983072, -8.988333326122074],
              [-37.921666666083752, -8.98749999362974],
              [-37.920833333591418, -8.98749999273042],
              [-37.920833332692098, -8.986666659338823],
              [-37.919999999300501, -8.986666658439503],
              [-37.919999999300501, -8.984999993454892],
              [-37.91833333071861, -8.984999992555572],
              [-37.91833333251725, -8.984166659163918],
              [-37.917500000924235, -8.984166659163918],
              [-37.917500000924235, -8.983333326671641],
              [-37.916666665733999, -8.983333326671641],
              [-37.916666667532638, -8.982499994179364],
              [-37.914166665559151, -8.982499993280044],
              [-37.914166665559151, -8.98166665898907],
              [-37.913333331268177, -8.98166665808975],
              [-37.913333333966136, -8.980833325597473],
              [-37.911666666283566, -8.980833326496793],
              [-37.911666664484926, -8.979999993105139],
              [-37.910000000399634, -8.979999993105139],
              [-37.909999998600995, -8.979166659713542],
              [-37.908333332717064, -8.979166659713542],
              [-37.908333332717064, -8.978333326321888],
              [-37.907499999325466, -8.978333326321888],
              [-37.907500000224786, -8.977499992930291],
              [-37.906666667732509, -8.977499992930291],
              [-37.906666667732509, -8.97583332614704],
              [-37.905833331642896, -8.97583332614704],
              [-37.905833331642896, -8.974999993654762],
              [-37.905000000949258, -8.974999993654762],
              [-37.905000000949258, -8.974166659363789],
              [-37.904166667557604, -8.974166659363789],
              [-37.904166666658284, -8.972499993479857],
              [-37.902499998975713, -8.972499993479857],
              [-37.902499999875033, -8.971666658289621],
              [-37.901666665584116, -8.971666659188941],
              [-37.901666667382756, -8.970833326696606],
              [-37.900833331293143, -8.970833326696606],
              [-37.900833333091782, -8.969999993305009],
              [-37.899999998800865, -8.969999993305009],
              [-37.900000000599505, -8.969166659014036],
              [-37.899166665409211, -8.969166659014036],
              [-37.899166667207908, -8.968333326521758],
              [-37.898333332916934, -8.968333326521758],
              [-37.898333332916934, -8.967499993130104],
              [-37.897500000424657, -8.967499992230785],
              [-37.897500000424657, -8.96583332724623],
              [-37.896666665234363, -8.96583332634691],
              [-37.896666667932323, -8.964166659563659],
              [-37.895833332742086, -8.964166659563659],
              [-37.895833332742086, -8.963333326172005],
              [-37.894166665958835, -8.963333326172005],
              [-37.894166665958835, -8.962499993679728],
              [-37.891666667582626, -8.962499993679728],
              [-37.891666667582626, -8.961666659388754],
              [-37.890833331493013, -8.961666659388754],
              [-37.890833332392333, -8.960833325097838],
              [-37.889166665609082, -8.960833325997157],
              [-37.889166667407721, -8.959999993504823],
              [-37.888333332217485, -8.959999993504823],
              [-37.888333331318165, -8.959166658314587],
              [-37.887499999725151, -8.959166659213906],
              [-37.88750000062447, -8.958333326721629],
              [-37.886666665434234, -8.958333326721629],
              [-37.886666667232873, -8.957499993329975],
              [-37.884999999550303, -8.957499993329975],
              [-37.884999999550303, -8.956666659039058],
              [-37.882500000274774, -8.956666659039058],
              [-37.882499999375455, -8.955833326546724],
              [-37.879166666708272, -8.955833329244683],
              [-37.878333331517979, -8.955833326546724],
              [-37.878333330618659, -8.954999993155127],
              [-37.875833331343131, -8.954999993155127],
              [-37.875833331343131, -8.954166659763473],
              [-37.8741666654592, -8.954166657964834],
              [-37.874166667257839, -8.953333327271196],
              [-37.873333332067602, -8.953333326371876],
              [-37.871666667082991, -8.953333326371876],
              [-37.871666665284351, -8.954166659763473],
              [-37.86999999940042, -8.954166660662793],
              [-37.8699999985011, -8.954999993155127],
              [-37.869166667807406, -8.954999993155127],
              [-37.869166667807406, -8.955833326546724],
              [-37.868333331717849, -8.955833326546724],
              [-37.868333331717849, -8.956666659039058],
              [-37.866666666733238, -8.956666659039058],
              [-37.866666666733238, -8.957499993329975],
              [-37.860833333891208, -8.957499993329975],
              [-37.860833332991888, -8.956666659039058],
              [-37.858333333716359, -8.956666664434977],
              [-37.856666666933108, -8.956666659039058],
              [-37.856666666033789, -8.954999993155127],
              [-37.857499998526066, -8.954999992255807],
              [-37.857499999425386, -8.953333327271196],
              [-37.85833333281704, -8.953333326371876],
              [-37.85833333281704, -8.952499992980222],
              [-37.859166668007276, -8.952499992980222],
              [-37.859166665309317, -8.951666659588625],
              [-37.859999998700914, -8.951666659588625],
              [-37.859999998700914, -8.950833326197028],
              [-37.860833332092568, -8.950833326197028],
              [-37.860833332092568, -8.947499993529846],
              [-37.861666667282805, -8.947499993529846],
              [-37.861666665484165, -8.945833326746595],
              [-37.862499998875819, -8.945833326746595],
              [-37.862499999775139, -8.944166659064024],
              [-37.861666665484165, -8.944166658164704],
              [-37.861666665484165, -8.94333332657169],
              [-37.859999999600234, -8.94333332657169],
              [-37.859999998700914, -8.942499993180093],
              [-37.858333331917663, -8.942499993180093],
              [-37.858333333716359, -8.941666658889176],
              [-37.856666667832428, -8.941666658889176],
              [-37.856666666933108, -8.940833326396842],
              [-37.855833332642135, -8.940833327296161],
              [-37.855833331742815, -8.939999993005245],
              [-37.854166665858884, -8.939999993904564],
              [-37.854166666758204, -8.939166659613591],
              [-37.853333332467287, -8.93916666141223],
              [-37.852500000874272, -8.939166659613591],
              [-37.852499999075633, -8.938333326221994],
              [-37.851666665684036, -8.938333325322674],
              [-37.851666666583355, -8.937499993729659],
              [-37.850833334091078, -8.937499993729659],
              [-37.850833332292382, -8.936666659438742],
              [-37.849999999800104, -8.936666659438742],
              [-37.849999999800104, -8.934999993554811],
              [-37.849166665509188, -8.934999993554811],
              [-37.849166667307827, -8.934166659263894],
              [-37.848333331218214, -8.934166659263894],
              [-37.848333330318894, -8.929166658914141],
              [-37.848333331218214, -8.923333326971431],
              [-37.847499999625256, -8.923333326072111],
              [-37.847500000524576, -8.919999993404929],
              [-37.846666665334283, -8.919999993404929],
              [-37.846666666233602, -8.919166659113955],
              [-37.847500000524576, -8.919166659113955],
              [-37.847499999625256, -8.917499993230081],
              [-37.846666665334283, -8.917499993230081],
              [-37.846666665334283, -8.911666659488674],
              [-37.847500001423896, -8.911666659488674],
              [-37.847499999625256, -8.909999993604799],
              [-37.848333331218214, -8.909999993604799],
              [-37.848333333016853, -8.909166658414506],
              [-37.849166665509188, -8.909166659313826],
              [-37.849166666408507, -8.908333325922229],
              [-37.850833331393062, -8.908333325922229],
              [-37.850833332292382, -8.906666658239658],
              [-37.850000000699424, -8.906666659138978],
              [-37.849999999800104, -8.899999992905293],
              [-37.847499999625256, -8.899999992005974],
              [-37.847499998725937, -8.899166660413016],
              [-37.846666665334283, -8.899166658614377],
              [-37.846666666233602, -8.893333326671666],
              [-37.847500000524576, -8.893333326671666],
              [-37.847499998725937, -8.889999993105164],
              [-37.848333333016853, -8.889999993105164],
              [-37.848333331218214, -8.88916665971351],
              [-37.847499999625256, -8.88916665791487],
              [-37.847499999625256, -8.887499994728955],
              [-37.849999998001465, -8.887499992030939],
              [-37.849999998900785, -8.884999993654731],
              [-37.850833332292382, -8.884999993654731],
              [-37.850833332292382, -8.88333332597216],
              [-37.851666667482675, -8.883333327770799],
              [-37.851666667482675, -8.880833326696632],
              [-37.854166664959564, -8.880833326696632],
              [-37.854166665858884, -8.879166658114741],
              [-37.855000001049177, -8.879166658114741],
              [-37.854999998351218, -8.878333326521727],
              [-37.857500000324706, -8.878333326521727],
              [-37.857499999425386, -8.877499993130129],
              [-37.858333333716359, -8.877499994029449],
              [-37.85833333281704, -8.876666659738532],
              [-37.859166665309317, -8.876666659738532],
              [-37.859166666208637, -8.875833326346878],
              [-37.860833332092568, -8.875833326346878],
              [-37.860833332991888, -8.874999992955281],
              [-37.861666666383485, -8.874999992955281],
              [-37.861666666383485, -8.872499993679696],
              [-37.860833332092568, -8.872499993679696],
              [-37.860833332092568, -8.870833325997125],
              [-37.861666665484165, -8.870833325997125],
              [-37.861666667282805, -8.869999993504848],
              [-37.862500000674459, -8.869999993504848],
              [-37.862499997976499, -8.869166659213931],
              [-37.859999998700914, -8.869166658314555],
              [-37.859999998700914, -8.869999993504848],
              [-37.859166665309317, -8.869999993504848],
              [-37.859166666208637, -8.870833325997125],
              [-37.858333333716359, -8.870833325997125],
              [-37.85833333281704, -8.871666659388779],
              [-37.857500000324706, -8.871666659388779],
              [-37.857499998526066, -8.872499993679696],
              [-37.856666666933108, -8.872499993679696],
              [-37.856666666033789, -8.87333332617203],
              [-37.854166665858884, -8.87333332617203],
              [-37.854166666758204, -8.874166659563627],
              [-37.853333333366606, -8.874166659563627],
              [-37.853333332467287, -8.875833326346878],
              [-37.851666665684036, -8.875833326346878],
              [-37.851666665684036, -8.876666657939893],
              [-37.850833331393062, -8.876666659738532],
              [-37.850833332292382, -8.877499993130129],
              [-37.849999998001465, -8.877499993130129],
              [-37.849999998900785, -8.878333326521727],
              [-37.849166666408507, -8.878333325622407],
              [-37.849166665509188, -8.879166659014061],
              [-37.848333333016853, -8.879166659014061],
              [-37.848333333016853, -8.879999993304978],
              [-37.846666666233602, -8.879999993304978],
              [-37.846666665334283, -8.880833326696632],
              [-37.845833331942686, -8.880833326696632],
              [-37.845833333741325, -8.881666659188909],
              [-37.844999999450351, -8.881666660088229],
              [-37.844999999450351, -8.882499993479883],
              [-37.842499998376184, -8.882499993479883],
              [-37.842499999275503, -8.881666659188909],
              [-37.839166666608321, -8.881666659188909],
              [-37.839166665709001, -8.882499992580563],
              [-37.83749999892575, -8.882499994379202],
              [-37.83749999982507, -8.884166659363814],
              [-37.830833331792803, -8.884166659363814],
              [-37.830833333591443, -8.884999993654731],
              [-37.829999999300469, -8.884999993654731],
              [-37.829999998401149, -8.885833326147065],
              [-37.829166665908872, -8.885833326147065],
              [-37.829166666808192, -8.886666660437982],
              [-37.829166667707511, -8.888333326321913],
              [-37.828333333416538, -8.888333326321913],
              [-37.828333331617898, -8.88916665971351],
              [-37.82750000092426, -8.88916665971351],
              [-37.827499998226301, -8.890833326496761],
              [-37.82583333144305, -8.890833326496761],
              [-37.82583333234237, -8.891666658989095],
              [-37.825000000749412, -8.891666658089775],
              [-37.824999999850093, -8.892499993280012],
              [-37.82449233988293, -8.892499993280012],
              [-37.824066276999901, -8.893979738999972],
            ],
            [
              [-38.034037995357153, -8.666662962325802],
              [-38.033668028656564, -8.666625753775463],
              [-38.033374208452926, -8.666606714228408],
              [-38.032840221598917, -8.666569476899781],
              [-38.03249294209553, -8.66655039958124],
              [-38.031977975902805, -8.666521841609665],
              [-38.031608037980504, -8.666448490205596],
              [-38.031249403637048, -8.666330134028442],
              [-38.030871794497671, -8.666093591645904],
              [-38.030539817858255, -8.665839975634299],
              [-38.030265263830813, -8.665676890775842],
              [-38.029933385217532, -8.665476695393124],
              [-38.029426034483834, -8.665213408072304],
              [-38.02896827236782, -8.664886274282082],
              [-38.028819451656318, -8.664777552541977],
              [-38.02869357624752, -8.66472318402765],
              [-38.028567810555955, -8.664578251085345],
              [-38.028132931689413, -8.664251113697901],
              [-38.02780471781341, -8.66393351541933],
              [-38.027427160834748, -8.663643632447645],
              [-38.027270697684514, -8.66346250988687],
              [-38.027129563478525, -8.663416651657087],
              [-38.026564998775655, -8.663261186754312],
              [-38.026011848268126, -8.662961770069614],
              [-38.025199315296106, -8.662435319534325],
              [-38.024749224397169, -8.66219887967452],
              [-38.024352512758242, -8.661889833049315],
              [-38.023940475772292, -8.661626591593858],
              [-38.023444577005591, -8.661408229905817],
              [-38.022830415591159, -8.661163159252339],
              [-38.022357467523079, -8.660999079743704],
              [-38.021918681102193, -8.660853164741695],
              [-38.021720251088652, -8.660834094617712],
              [-38.021445688967333, -8.660652942379329],
              [-38.021083294558366, -8.660272371772464],
              [-38.020846649653095, -8.660045403572497],
              [-38.020560605887226, -8.659737437033073],
              [-38.02048438384719, -8.659592484305676],
              [-38.02038137909733, -8.659157558674337],
              [-38.020232699579367, -8.658659760539479],
              [-38.019980942466475, -8.658089375424311],
              [-38.019805348038346, -8.657636475043262],
              [-38.019481098373944, -8.65711194456054],
              [-38.019084403822148, -8.656350868997379],
              [-38.018779228778328, -8.655897978508847],
              [-38.018351942887818, -8.655426775125534],
              [-38.017917145859542, -8.655073058274979],
              [-38.017707331328211, -8.655045284512198],
              [-38.017463263418847, -8.655016689668457],
              [-38.016967277417848, -8.654888900502328],
              [-38.016353133989924, -8.654698194765842],
              [-38.015601553468855, -8.654478817743211],
              [-38.015197131942045, -8.65438729373858],
              [-38.014525875268873, -8.654141200555898],
              [-38.01411384188026, -8.653905651924276],
              [-38.013713398054847, -8.653614777000314],
              [-38.013301312505519, -8.653305793327661],
              [-38.012935154433762, -8.652708777488044],
              [-38.012557381617796, -8.652409364400626],
              [-38.012328637256758, -8.652218600208187],
              [-38.01218364405986, -8.652019309543448],
              [-38.012073026548876, -8.651838119533522],
              [-38.011847922643483, -8.65145760828193],
              [-38.011638218728763, -8.651077052064238],
              [-38.011352018480807, -8.650488658025097],
              [-38.011119309008848, -8.650080493519908],
              [-38.010966757010181, -8.650116711916667],
              [-38.010646231438386, -8.649808655445042],
              [-38.010318172245832, -8.649473017665571],
              [-38.009955905540551, -8.64913735560441],
              [-38.009841325616662, -8.649055267286712],
              [-38.009406606829373, -8.648729102066341],
              [-38.009280629797161, -8.648638525047829],
              [-38.008937372961327, -8.648465927160771],
              [-38.008719862031967, -8.64831140474746],
              [-38.008479593758182, -8.648138822148894],
              [-38.008189597471926, -8.647984326715232],
              [-38.007995115482174, -8.647910897969496],
              [-38.007663150533915, -8.647701974666347],
              [-38.007571675092663, -8.647657183931869],
              [-38.007445836556087, -8.647575141479535],
              [-38.007083425059989, -8.647112632741766],
              [-38.007075748446994, -8.646995352153738],
              [-38.007117701820448, -8.6467607469109],
              [-38.007274157776124, -8.64651665202183],
              [-38.0073847456095, -8.646417404639465],
              [-38.007575477426315, -8.64627343936769],
              [-38.007777682793801, -8.646129508270121],
              [-38.008185830211858, -8.645759451637332],
              [-38.008372758795758, -8.645605812358326],
              [-38.008578822254776, -8.645407444398018],
              [-38.008792365375314, -8.645209116007834],
              [-38.008975434068986, -8.645020360900901],
              [-38.009013659752554, -8.644857178915629],
              [-38.009158514453816, -8.644631182882733],
              [-38.009437125323018, -8.644351765321687],
              [-38.009639265939313, -8.644153404555937],
              [-38.009913900006438, -8.643946412996513],
              [-38.010009256921649, -8.643900700456925],
              [-38.0103258704425, -8.643829160287453],
              [-38.010756904707364, -8.643813029147907],
              [-38.011199470978738, -8.643868272702605],
              [-38.011802184922828, -8.643969372688332],
              [-38.012237024219246, -8.644331816560054],
              [-38.012603261431309, -8.644522475531801],
              [-38.012965647746398, -8.644623593503979],
              [-38.013511083869389, -8.64469698537755],
              [-38.014029931536072, -8.644824751161309],
              [-38.014426569430611, -8.644916354306247],
              [-38.015109532578833, -8.645045085062577],
              [-38.015567150803349, -8.645316885365901],
              [-38.015826678958035, -8.645299675039951],
              [-38.016307338712693, -8.645129057459997],
              [-38.01680325096919, -8.645012667200774],
              [-38.017211286871373, -8.644977376904365],
              [-38.01743648970222, -8.644923926597869],
              [-38.017871349682991, -8.644717030366621],
              [-38.018310036279104, -8.644491978621886],
              [-38.018584680238746, -8.644230621246152],
              [-38.018775359894903, -8.644104854655268],
              [-38.019004216671192, -8.643833893419412],
              [-38.019088133310674, -8.643743402735822],
              [-38.019305652333969, -8.643526836993885],
              [-38.019507816332634, -8.643346605661293],
              [-38.019629957756308, -8.643238855188997],
              [-38.020057211271251, -8.643004224765093],
              [-38.020236447054344, -8.642842064409706],
              [-38.02041576737372, -8.64270665169056],
              [-38.020881052318657, -8.642608420542103],
              [-38.021293089304606, -8.642618939912097],
              [-38.021441742742184, -8.642600863538973],
              [-38.021735622301094, -8.642592202168373],
              [-38.022002636412481, -8.642620852770108],
              [-38.022242991920507, -8.642747678762305],
              [-38.0225786710688, -8.642884078937072],
              [-38.022765650014719, -8.643246435574497],
              [-38.022975378211072, -8.643681350413942],
              [-38.023097454883612, -8.643898859544663],
              [-38.023269146254052, -8.644061917423528],
              [-38.023536247599679, -8.644559672390926],
              [-38.023913780296652, -8.645167127560853],
              [-38.024100785322958, -8.645582974974275],
              [-38.02432564101548, -8.645800415756526],
              [-38.024512696403747, -8.645918612753746],
              [-38.024951436059894, -8.646074110032089],
              [-38.025584560578977, -8.646256261417307],
              [-38.025958486994512, -8.646347869058843],
              [-38.026736664065766, -8.646440331056397],
              [-38.026935057207083, -8.646449918728706],
              [-38.027366109458342, -8.64645082974198],
              [-38.027633143354819, -8.646460396729879],
              [-38.02801841291938, -8.646443248457103],
              [-38.028437948452506, -8.646453735451473],
              [-38.028891949603747, -8.646463169339768],
              [-38.029162878464035, -8.646419309403541],
              [-38.029349818739092, -8.64640117727248],
              [-38.029666429562042, -8.646411729018041],
              [-38.030177519676727, -8.646421322985645],
              [-38.030494127801717, -8.646440331056397],
              [-38.030715405091826, -8.646449909735509],
              [-38.031005315043217, -8.646478478498921],
              [-38.031306685954746, -8.646524212622296],
              [-38.031585214985625, -8.646560484079032],
              [-38.031688239520577, -8.646624391702289],
              [-38.031794972859643, -8.646633924515982],
              [-38.032012377669048, -8.646679655042021],
              [-38.032218397061342, -8.64679792758227],
              [-38.032309934555769, -8.646924758071066],
              [-38.032489224298217, -8.647187037251911],
              [-38.03256933500677, -8.64729576618663],
              [-38.03278675420529, -8.647766885033661],
              [-38.032904926021502, -8.648174083666959],
              [-38.033011800554107, -8.648482152729116],
              [-38.033217774980301, -8.64870819912403],
              [-38.033347555245427, -8.649034334666737],
              [-38.033477241981075, -8.649342324588588],
              [-38.03360302565909, -8.649424365242226],
              [-38.033923529647154, -8.649678069387335],
              [-38.034301043458356, -8.649904970138209],
              [-38.034694174896231, -8.650077618387343],
              [-38.034980031603141, -8.650331358505355],
              [-38.035232041425502, -8.650495311209625],
              [-38.035437873758838, -8.65052308047575],
              [-38.035758386740099, -8.650776641628681],
              [-38.036208597248901, -8.650922633073037],
              [-38.036502378781734, -8.650932109229416],
              [-38.03680362378816, -8.650914951963443],
              [-38.037108824012989, -8.650907328410426],
              [-38.037307205463094, -8.650898768663183],
              [-38.037585724601456, -8.650916825251215],
              [-38.037841241779859, -8.650998927958085],
              [-38.038096810219599, -8.651117104270895],
              [-38.03816166482892, -8.651280244887346],
              [-38.03836394753813, -8.65155199752661],
              [-38.038451635934223, -8.651733201925708],
              [-38.038562178801442, -8.651950613929671],
              [-38.038714786558103, -8.652303494410717],
              [-38.03897029923985, -8.652792708516643],
              [-38.039092524300486, -8.652929131174403],
              [-38.039309914720718, -8.653100784773358],
              [-38.039618801266613, -8.653319179736286],
              [-38.039920248620547, -8.653427878094078],
              [-38.040114763885185, -8.653501302343159],
              [-38.040274972711757, -8.653646263164489],
              [-38.040583849365134, -8.653873332987871],
              [-38.040717402286248, -8.654018243447126],
              [-38.041041779654392, -8.654163152107742],
              [-38.041365980755359, -8.65425470938726],
              [-38.04157201093949, -8.654372986424164],
              [-38.042022186374766, -8.654591410165381],
              [-38.042380774852859, -8.654646707679376],
              [-38.042731660637571, -8.654656198224927],
              [-38.042964409679712, -8.654647594410903],
              [-38.043212320392229, -8.654612405737907],
              [-38.043551791981599, -8.654540830494909],
              [-38.043845561823161, -8.65447787345505],
              [-38.044215542013603, -8.654207981513082],
              [-38.04441783731329, -8.654018260534258],
              [-38.044619898789279, -8.653910430921599],
              [-38.044787741960704, -8.653702535543516],
              [-38.044951831361857, -8.653612914504379],
              [-38.045237983046434, -8.653541379730825],
              [-38.045493350038043, -8.653496540432968],
              [-38.045668948962771, -8.653460302251119],
              [-38.045852060823904, -8.653588125591455],
              [-38.04606184747621, -8.653588124692135],
              [-38.046260333247744, -8.653651997241809],
              [-38.046557846067685, -8.653842749743035],
              [-38.046756162766656, -8.654241379635891],
              [-38.046931601612073, -8.654675365475669],
              [-38.046992673672889, -8.655009992417149],
              [-38.046977433761526, -8.655435457180545],
              [-38.047015533539991, -8.655743414726771],
              [-38.0470766208893, -8.656150618755987],
              [-38.047091939941026, -8.656457717449655],
              [-38.046981129075789, -8.656602666579772],
              [-38.046859212482616, -8.656728583357392],
              [-38.046763813299322, -8.656854400310294],
              [-38.046737049475212, -8.657045257132836],
              [-38.046733208470755, -8.657180663556744],
              [-38.046859068591118, -8.657569715669808],
              [-38.046977431063567, -8.657932052522142],
              [-38.047156662350062, -8.65819434429352],
              [-38.047255868363607, -8.658474697149529],
              [-38.047336011447726, -8.658547217580008],
              [-38.047583941046014, -8.658801830040375],
              [-38.047843387362377, -8.659190934314097],
              [-38.048087454372478, -8.659508522700094],
              [-38.048186575849741, -8.659797549517236],
              [-38.048263021820958, -8.659915772594786],
              [-38.048293474664092, -8.660096891558226],
              [-38.048301200739786, -8.660250553320338],
              [-38.048167595657958, -8.660511822562512],
              [-38.048064518962349, -8.660801757694912],
              [-38.047927330981508, -8.661145022624623],
              [-38.047862464680975, -8.661334782274309],
              [-38.047690763417961, -8.661632326570555],
              [-38.047511524037589, -8.661812575889599],
              [-38.047404708860199, -8.661903157404765],
              [-38.047175819708286, -8.66217407817112],
              [-38.047042291068863, -8.662670860971389],
              [-38.04701558210337, -8.662878779731841],
              [-38.047019384437021, -8.663132495568163],
              [-38.047057462631756, -8.663403296724709],
              [-38.047194867349219, -8.663548273733795],
              [-38.047458048550084, -8.66370279344909],
              [-38.047709824548747, -8.663857353633944],
              [-38.048041699564749, -8.66410236043555],
              [-38.048201915585878, -8.664247329350701],
              [-38.048362110922653, -8.664374153544316],
              [-38.048495762769221, -8.664537218617681],
              [-38.04867872983948, -8.664619214305276],
              [-38.048755120952023, -8.664845363222867],
              [-38.048865680007111, -8.665054112956909],
              [-38.048900029612639, -8.665542448425185],
              [-38.048846602688513, -8.665776988017512],
              [-38.048560473487044, -8.665939165460088],
              [-38.047980647289023, -8.666190895593331],
              [-38.047606814402911, -8.666334936408134],
              [-38.047240695901337, -8.666370160154713],
              [-38.046985052817888, -8.666378762170098],
              [-38.046340386399663, -8.666114647472966],
              [-38.045993204023091, -8.665996334463216],
              [-38.04566899572751, -8.665896236322226],
              [-38.04535995629692, -8.665723578180518],
              [-38.044726640222223, -8.66535070127378],
              [-38.044223197042868, -8.665051268401271],
              [-38.043708256031152, -8.664733656632905],
              [-38.043525110895132, -8.664606835137306],
              [-38.043231423791156, -8.664216764092316],
              [-38.042887965507134, -8.663935430075981],
              [-38.042514229747837, -8.663591174093369],
              [-38.042102266506333, -8.663264105953658],
              [-38.041766536996022, -8.663236403237363],
              [-38.041438440031925, -8.663289852644539],
              [-38.041251474575859, -8.663316508549997],
              [-38.04097305706091, -8.66328890206114],
              [-38.040763309978729, -8.663279352160316],
              [-38.040481029875593, -8.663314646054005],
              [-38.040221655504979, -8.66336800732762],
              [-38.03991643189778, -8.663530164985104],
              [-38.039653109503377, -8.663665577704194],
              [-38.039523471331108, -8.663764833180437],
              [-38.039191670958814, -8.663962143538072],
              [-38.038909401647516, -8.664034663069174],
              [-38.038520269494825, -8.664205343601679],
              [-38.038211240856015, -8.664303683568107],
              [-38.038081565811581, -8.664366505709609],
              [-38.037387383620796, -8.664663128200743],
              [-38.036925737332865, -8.664861444899714],
              [-38.03653664205234, -8.664987376965826],
              [-38.036277300057293, -8.665085695348523],
              [-38.036113173783917, -8.665176167146342],
              [-38.035952930783083, -8.665392765263846],
              [-38.035773588879977, -8.66556338104516],
              [-38.035701260005112, -8.665716946579778],
              [-38.035594383673811, -8.666150867668364],
              [-38.035552421307159, -8.666350152037865],
              [-38.035399770383037, -8.666792683235713],
              [-38.035281577882472, -8.666847007683202],
              [-38.035022174733513, -8.666936644910209],
              [-38.034720727379636, -8.666917561296373],
              [-38.034385037439506, -8.666799348111397],
              [-38.034037995357153, -8.666662962325802],
            ],
          ],
          [
            [
              [-38.1153695799999, -9.19836020499997],
              [-38.115833331942667, -9.198669178733518],
              [-38.115833331942667, -9.198333325522526],
              [-38.115361471972676, -9.198333325777138],
              [-38.1153695799999, -9.19836020499997],
            ],
          ],
          [
            [
              [-38.762552859, -8.825967217999962],
              [-38.765605187999903, -8.821696540999945],
              [-38.770078885672376, -8.818333325922197],
              [-38.765000000849341, -8.818333325922197],
              [-38.764999999950021, -8.819166658414531],
              [-38.761666665484199, -8.819166659313851],
              [-38.761666665484199, -8.818333327720836],
              [-38.760833332991865, -8.818333325922197],
              [-38.759999998700948, -8.818333325922197],
              [-38.759999998700948, -8.821666659488699],
              [-38.759166666208614, -8.821666659488699],
              [-38.759166665309294, -8.82333332717127],
              [-38.759999998700948, -8.82333332627195],
              [-38.760000000499588, -8.827499993230049],
              [-38.760833332991865, -8.827499993230049],
              [-38.760833332999717, -8.827521818454414],
              [-38.762552859, -8.825967217999962],
            ],
          ],
          [
            [
              [-38.821073903999903, -8.786066155999951],
              [-38.821403694, -8.786058216999949],
              [-38.821623955999897, -8.786052913999987],
              [-38.822274605, -8.786037250999936],
              [-38.8228635729999, -8.786023071999979],
              [-38.823126046, -8.786016752999961],
              [-38.823323812, -8.786011991999942],
              [-38.823523345, -8.786007188999969],
              [-38.830216431999901, -8.786383129999926],
              [-38.836976692999897, -8.785428437999947],
              [-38.84083333164655, -8.78523487621545],
              [-38.840833331393071, -8.78499999295525],
              [-38.839999999800114, -8.78499999295525],
              [-38.839999998900794, -8.784166659563653],
              [-38.83916666550914, -8.784166659563653],
              [-38.83916666550914, -8.783333326171999],
              [-38.838333333916182, -8.783333326171999],
              [-38.838333333916182, -8.782499993679721],
              [-38.837500000524585, -8.782499993679721],
              [-38.837499999625265, -8.781666659388748],
              [-38.835833332842014, -8.781666659388748],
              [-38.835833333741334, -8.78083332599715],
              [-38.834999998551041, -8.78083332599715],
              [-38.834999998551041, -8.779999992605553],
              [-38.834166667857403, -8.779999993504873],
              [-38.834166666058763, -8.779166659213899],
              [-38.832500001074152, -8.779166660113219],
              [-38.832500001074152, -8.778333326721622],
              [-38.831666666783178, -8.778333326721622],
              [-38.831666667682498, -8.777499993329968],
              [-38.830833332492261, -8.777499993329968],
              [-38.830833330693622, -8.776666658139732],
              [-38.830000000899304, -8.776666659039051],
              [-38.829999999100608, -8.777499993329968],
              [-38.82916666570901, -8.777499993329968],
              [-38.82916666660833, -8.778333324922983],
              [-38.828333332317413, -8.778333326721622],
              [-38.828333332317413, -8.779166659213899],
              [-38.827499998925759, -8.779166659213899],
              [-38.827500000724399, -8.779999993504873],
              [-38.826666665534162, -8.779999993504873],
              [-38.826666666433482, -8.78083332599715],
              [-38.825833333041828, -8.78083332599715],
              [-38.825833333941148, -8.782499994579041],
              [-38.824166665359257, -8.782499993679721],
              [-38.824166666258577, -8.783333326171999],
              [-38.82333333196766, -8.783333326171999],
              [-38.8233333337663, -8.784166659563653],
              [-38.821666667882369, -8.784166659563653],
              [-38.821666666983049, -8.78499999295525],
              [-38.820833332692132, -8.78499999295525],
              [-38.820833331792812, -8.785833326346904],
              [-38.818333331617907, -8.785833326346904],
              [-38.818333331940373, -8.786132131568507],
              [-38.821073903999903, -8.786066155999951],
            ],
          ],
          [
            [
              [-38.8522272659999, -8.784663022999958],
              [-38.85477586, -8.784535110999968],
              [-38.854895014, -8.784529130999948],
              [-38.854933414, -8.784527204999955],
              [-38.854998716999901, -8.784523927999937],
              [-38.855065001999897, -8.78452059199998],
              [-38.855381286999901, -8.784504698999971],
              [-38.856050206, -8.784471086999924],
              [-38.856994911, -8.784423616999957],
              [-38.867552968, -8.783893092999959],
              [-38.86810442, -8.783865383999974],
              [-38.868333332545475, -8.783929130208501],
              [-38.868333333316627, -8.782499993679721],
              [-38.86750000082435, -8.782499994579041],
              [-38.866666665634057, -8.782499993679721],
              [-38.866666666533376, -8.781666659388748],
              [-38.865000000649445, -8.781666659388748],
              [-38.864999999750125, -8.780833325097831],
              [-38.864166666358528, -8.78083332599715],
              [-38.864166666358528, -8.779999993504873],
              [-38.863333334765571, -8.779999993504873],
              [-38.863333332067555, -8.77916665831458],
              [-38.861666667083, -8.779166659213899],
              [-38.86166666798232, -8.777499992430648],
              [-38.860833331892707, -8.777499992430648],
              [-38.860833333691346, -8.776666659039051],
              [-38.859999999400429, -8.776666659039051],
              [-38.860000000299749, -8.775833326546717],
              [-38.859166666008775, -8.775833325647397],
              [-38.859166666008775, -8.776666659039051],
              [-38.858333331717859, -8.776666659039051],
              [-38.858333331717859, -8.777499993329968],
              [-38.857499999225524, -8.777499993329968],
              [-38.857499999225524, -8.778333326721622],
              [-38.856666665833927, -8.778333326721622],
              [-38.856666667632567, -8.779166659213899],
              [-38.854166665659022, -8.77916665831458],
              [-38.854166664759703, -8.779999993504873],
              [-38.853333331368106, -8.779999993504873],
              [-38.853333331368106, -8.78083332599715],
              [-38.852499999775148, -8.78083332599715],
              [-38.852499999775148, -8.781666659388748],
              [-38.851666667282814, -8.781666659388748],
              [-38.851666666383494, -8.782499993679721],
              [-38.850833333891217, -8.782499993679721],
              [-38.850833332092577, -8.783333326171999],
              [-38.849999998700923, -8.783333327071318],
              [-38.85000000025655, -8.78477480797101],
              [-38.8522272659999, -8.784663022999958],
            ],
          ],
          [
            [
              [-38.870000000999198, -8.784393254158431],
              [-38.870000000999198, -8.784166659563653],
              [-38.869186300126927, -8.784166659563653],
              [-38.870000000999198, -8.784393254158431],
            ],
          ],
          [
            [
              [-38.899166667087364, -8.790980316807534],
              [-38.899166667008046, -8.7908333266966],
              [-38.898333331817753, -8.7908333266966],
              [-38.898333333616392, -8.789999993305003],
              [-38.897499999325476, -8.789999994204322],
              [-38.897499999325476, -8.789166659014029],
              [-38.896666665933822, -8.789166659014029],
              [-38.896666668631781, -8.787499993130155],
              [-38.895833331642905, -8.787499993130155],
              [-38.895000000049947, -8.787499993130155],
              [-38.894999998251251, -8.786666659738501],
              [-38.892499999875042, -8.786666659738501],
              [-38.892500000774362, -8.787499993130155],
              [-38.891666665584125, -8.787499993130155],
              [-38.891666665584125, -8.788333326521752],
              [-38.890833331293152, -8.788333326521752],
              [-38.890833331293152, -8.789166659014029],
              [-38.890000000599514, -8.789166658114709],
              [-38.889999999700194, -8.789999993305003],
              [-38.889166665409221, -8.789999993305003],
              [-38.889166665409221, -8.79002497819604],
              [-38.899166667087364, -8.790980316807534],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID_1: '273',
        OBJECTID: 133.0,
        Nome_area: 'Araripe',
        COD_area: 'CA139',
        Import_bio: 'Muito Alta',
        Prior_acao: 'Muito Alta',
        Acao_princ: 'Criação de UC de categoria indefinida',
        Area_ha: 668429.948,
        Shape_Leng: 10.24602,
        Shape_Area: 0.54726,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.926344968, -7.340998905999926],
              [-39.915442313, -7.338089806999952],
              [-39.910385313999903, -7.337210297999953],
              [-39.906666666889201, -7.337933555299724],
              [-39.906666667332786, -7.339166659213902],
              [-39.909166666608314, -7.339166660113221],
              [-39.909166665708995, -7.339999993504875],
              [-39.912499998376177, -7.339999993504875],
              [-39.912500001074136, -7.340833326896472],
              [-39.91333333266715, -7.340833325997153],
              [-39.913333331767831, -7.34166666028807],
              [-39.914166667857387, -7.34166665938875],
              [-39.914166667857387, -7.342499993679724],
              [-39.915000000349721, -7.342499993679724],
              [-39.914999997651705, -7.344166659563655],
              [-39.915833333741318, -7.344166660462975],
              [-39.915833333741318, -7.345833326346906],
              [-39.916666666233596, -7.345833325447586],
              [-39.916666665334276, -7.346666659738503],
              [-39.919999998900778, -7.346666659738503],
              [-39.920000000699417, -7.347499993130157],
              [-39.922499999975003, -7.347499992230837],
              [-39.922499999075626, -7.348333326521754],
              [-39.9233333333666, -7.348333326521754],
              [-39.92333333246728, -7.349166659014031],
              [-39.922500000874322, -7.349166659014031],
              [-39.922499999975003, -7.350833326696602],
              [-39.92333333246728, -7.350833326696602],
              [-39.92333333156796, -7.352499993479853],
              [-39.926666667832421, -7.352499993479853],
              [-39.926666668731741, -7.354166659363784],
              [-39.927499999425379, -7.354166658464464],
              [-39.927499998526059, -7.354999993654758],
              [-39.928333333716353, -7.354999993654758],
              [-39.928333331917713, -7.355833326147035],
              [-39.92916666620863, -7.355833326147035],
              [-39.930000000499604, -7.355833326147035],
              [-39.929999997801588, -7.354999993654758],
              [-39.929999998700964, -7.354166659363784],
              [-39.930833332991881, -7.354166659363784],
              [-39.930833333891201, -7.351666660088256],
              [-39.929999998700964, -7.351666659188936],
              [-39.930000000499604, -7.349999993305005],
              [-39.928333332817033, -7.349999994204325],
              [-39.928333332817033, -7.349166659014031],
              [-39.92916666620863, -7.349166659014031],
              [-39.92916666530931, -7.347499993130157],
              [-39.928333332817033, -7.347499993130157],
              [-39.928333332817033, -7.346666659738503],
              [-39.927500000324699, -7.346666657939863],
              [-39.927500000324699, -7.344999992955252],
              [-39.929999997801588, -7.344999992955252],
              [-39.929999999600284, -7.343333326172001],
              [-39.929166668906589, -7.343333325272681],
              [-39.92916666530931, -7.342499993679724],
              [-39.928333333716353, -7.342499993679724],
              [-39.928333332486964, -7.34193040047852],
              [-39.926344968, -7.340998905999926],
            ],
          ],
          [
            [
              [-39.866611007, -7.329152820999975],
              [-39.863953226, -7.327692083999986],
              [-39.857696806, -7.325428691999945],
              [-39.851448074, -7.325209904999946],
              [-39.848139064, -7.325093969999951],
              [-39.848110372, -7.325107932999968],
              [-39.839834851, -7.32913523299993],
              [-39.839804786999899, -7.329143963999968],
              [-39.828695769, -7.332370194999948],
              [-39.824090997, -7.331831521999959],
              [-39.818334136, -7.329144125999954],
              [-39.815367519999903, -7.328694211999969],
              [-39.815333949, -7.328708814999969],
              [-39.81202041400001, -7.330150218999965],
              [-39.812010638999901, -7.330150661999944],
              [-39.806462259999897, -7.330402300999943],
              [-39.803723106999897, -7.330119956999972],
              [-39.800805219, -7.33043353699998],
              [-39.8007685749999, -7.330446759999966],
              [-39.797008656999907, -7.331803556999941],
              [-39.797005397, -7.331803874999934],
              [-39.794136242, -7.332083341999972],
              [-39.78922697, -7.331995101999957],
              [-39.784999999302656, -7.330921845865332],
              [-39.784999998451099, -7.332499992080898],
              [-39.784166666858141, -7.332499992080898],
              [-39.784166665958821, -7.333333326371871],
              [-39.78250000187353, -7.333333326371871],
              [-39.78250000007489, -7.334999993155122],
              [-39.780833332392319, -7.334999993155122],
              [-39.780833332392319, -7.335833326546719],
              [-39.779166666508388, -7.3358333256474],
              [-39.779166666508388, -7.336666659039054],
              [-39.778333331318152, -7.336666659039054],
              [-39.778333331318152, -7.33749999332997],
              [-39.77666666633354, -7.33749999332997],
              [-39.77666666633354, -7.338333326721624],
              [-39.769999998301216, -7.338333326721624],
              [-39.770000000099856, -7.339166659213902],
              [-39.768333331517965, -7.339166659213902],
              [-39.768333332417342, -7.339999993504875],
              [-39.765833334041076, -7.339999994404195],
              [-39.765833332242437, -7.340833325997153],
              [-39.763333333866228, -7.340833325997153],
              [-39.763333332966909, -7.34166665938875],
              [-39.761666667082977, -7.34166665938875],
              [-39.761666667082977, -7.342499993679724],
              [-39.760833331892684, -7.342499993679724],
              [-39.760833332792004, -7.343333326172001],
              [-39.760000000299726, -7.343333326172001],
              [-39.759999998501087, -7.344166658664335],
              [-39.759166666008809, -7.344166658664335],
              [-39.759166666008809, -7.344999992955252],
              [-39.758333331717836, -7.344999992955252],
              [-39.758333333516475, -7.345833326346906],
              [-39.757499999225558, -7.345833326346906],
              [-39.757499999225558, -7.346666659738503],
              [-39.756666665833905, -7.346666659738503],
              [-39.756666665833905, -7.347499993130157],
              [-39.753333334965419, -7.347499992230837],
              [-39.753333331368083, -7.349166659014031],
              [-39.754166667457696, -7.349166659014031],
              [-39.754166666558376, -7.349999993305005],
              [-39.755833331542988, -7.349999993305005],
              [-39.755833333341627, -7.352499994379173],
              [-39.756666667632544, -7.352499993479853],
              [-39.756666665833905, -7.353333327770827],
              [-39.755833333341627, -7.353333325072867],
              [-39.755833331542988, -7.354999993654758],
              [-39.753333334066099, -7.354999992755438],
              [-39.753333334066099, -7.355833326147035],
              [-39.752499998875805, -7.355833326147035],
              [-39.752499998875805, -7.357499992930286],
              [-39.751666666383528, -7.357499992030966],
              [-39.751666667282848, -7.358333326321883],
              [-39.750000000499597, -7.358333326321883],
              [-39.749999998700957, -7.359166659713537],
              [-39.749166668007263, -7.359166660612857],
              [-39.749166666208623, -7.359999992205815],
              [-39.746666666033775, -7.359999993105134],
              [-39.746666666033775, -7.360833326496788],
              [-39.745833331742801, -7.360833326496788],
              [-39.745833331742801, -7.361666658989066],
              [-39.744999998351204, -7.361666658989066],
              [-39.744999998351204, -7.364166659163971],
              [-39.743333332467273, -7.364166659163971],
              [-39.743333332467273, -7.36833332612207],
              [-39.742499999075676, -7.36833332612207],
              [-39.742499999075676, -7.367499993629735],
              [-39.740833332292425, -7.367499993629735],
              [-39.740833332292425, -7.368333327021389],
              [-39.739166665509174, -7.36833332612207],
              [-39.739166666408494, -7.367499993629735],
              [-39.735833331942672, -7.367499993629735],
              [-39.735833332841992, -7.366666659338819],
              [-39.734999998551075, -7.366666660238138],
              [-39.734999998551075, -7.364999993454887],
              [-39.733333331767824, -7.364999993454887],
              [-39.733333332667144, -7.364166659163971],
              [-39.729999999100642, -7.364166658264594],
              [-39.729999999100642, -7.364999992555568],
              [-39.729166666608307, -7.364999993454887],
              [-39.729166666608307, -7.367499993629735],
              [-39.728333331418071, -7.367499993629735],
              [-39.728333331418071, -7.370833326296918],
              [-39.726666667332779, -7.370833326296918],
              [-39.726666666433459, -7.371666659688572],
              [-39.724999998750889, -7.371666660587891],
              [-39.724999998750889, -7.373333326471766],
              [-39.724166668057251, -7.373333326471766],
              [-39.724166665359292, -7.375833326646671],
              [-39.723333331967638, -7.375833326646671],
              [-39.723333332866957, -7.377499993429922],
              [-39.72250000037468, -7.377499994329241],
              [-39.72250000037468, -7.378333325922199],
              [-39.721666666083706, -7.378333325922199],
              [-39.721666666083706, -7.37999999360477],
              [-39.722499998576041, -7.37999999360477],
              [-39.722499998576041, -7.381666660388021],
              [-39.723333332866957, -7.381666660388021],
              [-39.723333333766277, -7.383333326271952],
              [-39.722499998576041, -7.383333325372632],
              [-39.722499998576041, -7.384999993055203],
              [-39.721666666983026, -7.384999993055203],
              [-39.721666667882346, -7.3858333264468],
              [-39.719166666808178, -7.3858333264468],
              [-39.719166665908858, -7.384999992155883],
              [-39.715833333241676, -7.384999993055203],
              [-39.715833331443037, -7.384166659663549],
              [-39.714999999850079, -7.384166658764229],
              [-39.714999999850079, -7.383333326271952],
              [-39.714166666458425, -7.383333325372632],
              [-39.714166665559105, -7.380833326097047],
              [-39.711666665384257, -7.380833326097047],
              [-39.711666668082216, -7.37999999360477],
              [-39.7108333337913, -7.37999999360477],
              [-39.7108333337913, -7.377499993429922],
              [-39.710000000399646, -7.377499993429922],
              [-39.709999999500326, -7.369166659513667],
              [-39.709166667008049, -7.369166659513667],
              [-39.709166665209409, -7.366666659338819],
              [-39.708333332717075, -7.366666659338819],
              [-39.708333331817755, -7.363333325772317],
              [-39.707500000224798, -7.363333326671636],
              [-39.707499999325478, -7.362499993280039],
              [-39.706666666833144, -7.362499993280039],
              [-39.706666667732463, -7.361666658989066],
              [-39.705000000949269, -7.361666658989066],
              [-39.705000000049893, -7.360833326496788],
              [-39.704166665758976, -7.360833326496788],
              [-39.704166668456935, -7.359999993105134],
              [-39.703333332367379, -7.359999993105134],
              [-39.703333332367379, -7.356666659538632],
              [-39.704166665758976, -7.356666659538632],
              [-39.704166665758976, -7.355833326147035],
              [-39.704999998251253, -7.355833326147035],
              [-39.704999998251253, -7.353333325072867],
              [-39.703333331468002, -7.353333325972187],
              [-39.703333331468002, -7.351666659188936],
              [-39.704166666658296, -7.351666658289616],
              [-39.704166665758976, -7.350833327595922],
              [-39.703333335065338, -7.350833326696602],
              [-39.703333333266698, -7.348333326521754],
              [-39.702499999875045, -7.348333326521754],
              [-39.701666668282087, -7.348333327421074],
              [-39.701666667382767, -7.347499993130157],
              [-39.700833333091794, -7.347499993130157],
              [-39.700833333091794, -7.345833326346906],
              [-39.699999998800877, -7.345833326346906],
              [-39.699999998800877, -7.344166659563655],
              [-39.700833333091794, -7.344166659563655],
              [-39.700833333091794, -7.342499993679724],
              [-39.699999998800877, -7.342499993679724],
              [-39.700000000599516, -7.34166665938875],
              [-39.699166667207862, -7.34166665938875],
              [-39.699166667085223, -7.34132572631129],
              [-39.697512026, -7.342165638999975],
              [-39.684870020999902, -7.350438038999927],
              [-39.671348436, -7.358537169999982],
              [-39.65308842, -7.372262173999959],
              [-39.645718304, -7.380878057999952],
              [-39.643894761, -7.385341664999934],
              [-39.63961928, -7.395804765999975],
              [-39.639422795, -7.396201754999939],
              [-39.639296999, -7.396455918999955],
              [-39.638113788999902, -7.398846538999988],
              [-39.634154969999898, -7.406845141999948],
              [-39.631353929999896, -7.415271521999951],
              [-39.630132412, -7.421941635999933],
              [-39.628907180999903, -7.425628510999957],
              [-39.626624579, -7.427035212999956],
              [-39.626608041999901, -7.427034367999965],
              [-39.623284016, -7.426864507999936],
              [-39.615547089, -7.425646759999942],
              [-39.608688195999903, -7.423726642999946],
              [-39.605524159, -7.424081643999951],
              [-39.60550949, -7.424092249999945],
              [-39.599201885, -7.42865257099993],
              [-39.59307791, -7.43235128899994],
              [-39.587977608999907, -7.432357335999949],
              [-39.583414699999899, -7.429970561999943],
              [-39.576533888, -7.427895018999942],
              [-39.575451524999899, -7.427818812999931],
              [-39.573057553999902, -7.428671421999982],
              [-39.571590504, -7.429831282999932],
              [-39.57127453, -7.430745850999936],
              [-39.57028157, -7.433616185999933],
              [-39.569057302, -7.438009182999958],
              [-39.5660177829999, -7.44891577899996],
              [-39.564696168999902, -7.453658079999976],
              [-39.562540666, -7.460378035999953],
              [-39.557454839999899, -7.472544857999944],
              [-39.552827912999902, -7.480656838999945],
              [-39.548425348, -7.483133297999929],
              [-39.548001537999902, -7.483266482999976],
              [-39.545852808999904, -7.483941731999948],
              [-39.543678035, -7.484625649999938],
              [-39.543249069, -7.484760549999986],
              [-39.536443944, -7.48253032799994],
              [-39.5305657279999, -7.478831667999933],
              [-39.521615852, -7.47261841299996],
              [-39.512102667, -7.467147861999929],
              [-39.507695278, -7.465377450999943],
              [-39.502360546, -7.464148441999953],
              [-39.499271248999896, -7.465850452999962],
              [-39.498730043, -7.466390941999978],
              [-39.497534206, -7.468013731999975],
              [-39.492436604, -7.471262496999934],
              [-39.489732515, -7.472423251999936],
              [-39.487337114, -7.472735355999939],
              [-39.475511884, -7.472670423999944],
              [-39.472109020999902, -7.470898551999943],
              [-39.4678565849999, -7.469590608999967],
              [-39.460747612, -7.471218998999971],
              [-39.458492818, -7.474210407999982],
              [-39.458277418, -7.474495487999951],
              [-39.457968442, -7.475688714999925],
              [-39.456737511, -7.480442416999956],
              [-39.455677481, -7.50186092199993],
              [-39.457305801, -7.506182958999932],
              [-39.469688420999901, -7.520568636999944],
              [-39.476652831, -7.527046713999934],
              [-39.481447941999903, -7.529280635999942],
              [-39.482530504, -7.530051096999964],
              [-39.484698022, -7.532828493999944],
              [-39.485164214999898, -7.534449021999938],
              [-39.485013976999902, -7.538386600999956],
              [-39.484243858, -7.541089773999942],
              [-39.483318389, -7.542789179999946],
              [-39.481233233, -7.545370191999953],
              [-39.476526798999899, -7.553327690999942],
              [-39.47652657, -7.553329816999954],
              [-39.475808976801503, -7.559999993631062],
              [-39.475833333541459, -7.559999993604777],
              [-39.47583333174282, -7.560833326996374],
              [-39.480833332991892, -7.560833326097054],
              [-39.480833332991892, -7.561666659488708],
              [-39.48166666548417, -7.561666660388028],
              [-39.481666666383489, -7.562499991980985],
              [-39.482499998875824, -7.562499992880305],
              [-39.482499999775143, -7.563333326271959],
              [-39.483333331368101, -7.563333325372639],
              [-39.483333331368101, -7.564166658764236],
              [-39.485833331542949, -7.564166659663556],
              [-39.485833333341645, -7.56499999305521],
              [-39.486666666733242, -7.56499999395453],
              [-39.486666666733242, -7.565833327346127],
              [-39.4874999983262, -7.565833326446807],
              [-39.48749999922552, -7.566666658039765],
              [-39.488333331717854, -7.566666658939084],
              [-39.488333332617174, -7.567499993230058],
              [-39.489166666908091, -7.567499993230058],
              [-39.48916666780741, -7.568333326621655],
              [-39.490000000299744, -7.568333327520975],
              [-39.489999999400425, -7.572499993579811],
              [-39.490833332792022, -7.572499994479131],
              [-39.490833333691342, -7.573333325172769],
              [-39.491666665284356, -7.573333326072088],
              [-39.491666667082995, -7.574166659463742],
              [-39.492499999575273, -7.574166659463742],
              [-39.492499999575273, -7.57499999195602],
              [-39.493333334765566, -7.574999993754659],
              [-39.493333332966927, -7.575833325347617],
              [-39.494166666358524, -7.575833326246936],
              [-39.494166665459204, -7.576666658739271],
              [-39.495833331343135, -7.57666665963859],
              [-39.495833331343135, -7.578333325522522],
              [-39.496666665634052, -7.578333325522522],
              [-39.496666667432692, -7.579166658914119],
              [-39.497499999025706, -7.579166658914119],
              [-39.497499999925026, -7.581666659089024],
              [-39.498333331517983, -7.581666659089024],
              [-39.498333332417303, -7.58249999427926],
              [-39.499166664909637, -7.58249999337994],
              [-39.499166666708277, -7.583333326771594],
              [-39.499999998301234, -7.583333326771594],
              [-39.500000000099874, -7.584999993554788],
              [-39.500833330793512, -7.584999994454108],
              [-39.500833332592208, -7.58666665943872],
              [-39.501666666883125, -7.58666665943872],
              [-39.501666665983805, -7.585833326047123],
              [-39.508333334016129, -7.585833326047123],
              [-39.508333332217489, -7.584999993554788],
              [-39.509166665609087, -7.584999992655469],
              [-39.509166667407726, -7.584166659263872],
              [-39.509999998101364, -7.584166659263872],
              [-39.50999999990006, -7.583333326771594],
              [-39.510833332392338, -7.583333326771594],
              [-39.510833331493018, -7.58249999337994],
              [-39.511666667582574, -7.582499992480621],
              [-39.511666665783935, -7.581666659089024],
              [-39.512500000974228, -7.581666659089024],
              [-39.512500000974228, -7.580833326596689],
              [-39.513333331667866, -7.580833326596689],
              [-39.513333333466505, -7.58249999337994],
              [-39.512500000074908, -7.58249999427926],
              [-39.512500000074908, -7.583333326771594],
              [-39.511666666683254, -7.583333326771594],
              [-39.511666665783935, -7.585833326047123],
              [-39.512500000974228, -7.585833326047123],
              [-39.512500000974228, -7.587499993729693],
              [-39.513333331667866, -7.587499994629013],
              [-39.513333332567186, -7.590833327296195],
              [-39.514999999350437, -7.590833326396876],
              [-39.515000000249756, -7.591666658889153],
              [-39.517499999525342, -7.591666658889153],
              [-39.517500000424661, -7.59249999318007],
              [-39.519166666308536, -7.59249999318007],
              [-39.519166667207855, -7.592593719624404],
              [-39.519166665409216, -7.593333325672404],
              [-39.520833331293147, -7.593333326571724],
              [-39.520833331293147, -7.594166659064001],
              [-39.523333331468052, -7.594166659064001],
              [-39.523333332367372, -7.594999993354975],
              [-39.524166665758969, -7.594999993354975],
              [-39.524166665758969, -7.595833326746572],
              [-39.524999998251303, -7.595833326746572],
              [-39.524999998251303, -7.596666659238906],
              [-39.527499999325471, -7.596666658339586],
              [-39.527499999325471, -7.597499993529823],
              [-39.529166667008042, -7.597499992630503],
              [-39.529166667008042, -7.596666658339586],
              [-39.532499997876585, -7.596666659238906],
              [-39.532499999675224, -7.597499993529823],
              [-39.533333333066821, -7.597499993529823],
              [-39.533333333966141, -7.598333326022157],
              [-39.534166665559098, -7.598333326022157],
              [-39.534166665559098, -7.599166660313074],
              [-39.534999999850072, -7.599166659413754],
              [-39.535000000749392, -7.599999993704671],
              [-39.535833333241669, -7.599999993704671],
              [-39.535833332342349, -7.601666660487922],
              [-39.536666665734003, -7.601666659588602],
              [-39.536666666633323, -7.602499992980256],
              [-39.53750000092424, -7.602499992980256],
              [-39.537499998226281, -7.604166659763507],
              [-39.538333332517254, -7.604166659763507],
              [-39.538333331617935, -7.609166659213884],
              [-39.537499998226281, -7.609166659213884],
              [-39.537499998226281, -7.609999993504857],
              [-39.536666665734003, -7.609999994404177],
              [-39.536666668431963, -7.614166659563637],
              [-39.536666667532643, -7.618333327421055],
              [-39.53583333144303, -7.618333326521736],
              [-39.535833332342349, -7.61916665901407],
              [-39.536666665734003, -7.61916665811475],
              [-39.536666666633323, -7.620833326696641],
              [-39.53750000002492, -7.620833326696641],
              [-39.53750000092424, -7.626666660437991],
              [-39.537499998226281, -7.629166659713519],
              [-39.539999997501866, -7.629166659713519],
              [-39.540000001099145, -7.628333326321922],
              [-39.542499998576034, -7.628333326321922],
              [-39.542499999475353, -7.627499992930268],
              [-39.54333333376627, -7.627499992930268],
              [-39.543333331967631, -7.626666659538671],
              [-39.544999998750882, -7.626666659538671],
              [-39.545000000549521, -7.625833326147017],
              [-39.545833331243216, -7.625833326147017],
              [-39.545833331243216, -7.62499999365474],
              [-39.546666665534133, -7.62499999365474],
              [-39.546666667332772, -7.624166659363766],
              [-39.547500000724426, -7.624166659363766],
              [-39.547499999825106, -7.623333325072849],
              [-39.548333333216704, -7.623333325972169],
              [-39.548333332317384, -7.622499993479892],
              [-39.549166665709038, -7.622499993479892],
              [-39.549166667507677, -7.619999994204306],
              [-39.550833331592912, -7.619999993304987],
              [-39.550833332492232, -7.618333325622416],
              [-39.552499999275483, -7.618333326521736],
              [-39.552499999275483, -7.615833325447568],
              [-39.551666667682525, -7.615833325447568],
              [-39.551666667682525, -7.614999992955291],
              [-39.550833332492232, -7.614999992955291],
              [-39.550833332492232, -7.614166658664317],
              [-39.550000000899274, -7.614166659563637],
              [-39.55, -7.61333332527272],
              [-39.549166666608357, -7.61333332617204],
              [-39.549166667507677, -7.605833326546758],
              [-39.548333332317384, -7.605833326546758],
              [-39.548333331418064, -7.601666659588602],
              [-39.547500000724426, -7.601666659588602],
              [-39.547499999825106, -7.600833326197005],
              [-39.546666666433453, -7.600833326197005],
              [-39.546666667332772, -7.598333326022157],
              [-39.545833333041855, -7.598333325122837],
              [-39.545833331243216, -7.589999993005222],
              [-39.545000001448841, -7.589999992105902],
              [-39.544999999650202, -7.588333326221971],
              [-39.544166665359285, -7.588333326221971],
              [-39.544166665359285, -7.587499993729693],
              [-39.544999999650202, -7.587499993729693],
              [-39.544999999650202, -7.585833325147803],
              [-39.544166668057244, -7.585833326047123],
              [-39.544166666258604, -7.584166660163191],
              [-39.545000000549521, -7.584166659263872],
              [-39.544999998750882, -7.583333326771594],
              [-39.545833333941175, -7.583333326771594],
              [-39.545833333041855, -7.582499992480621],
              [-39.546666667332772, -7.58249999337994],
              [-39.546666666433453, -7.580833326596689],
              [-39.545833333041855, -7.580833326596689],
              [-39.545833333941175, -7.579166658914119],
              [-39.544999998750882, -7.579166658014799],
              [-39.545000000549521, -7.577499992130868],
              [-39.544166666258604, -7.577499993030187],
              [-39.544166666258604, -7.57666665963859],
              [-39.543333332866951, -7.576666658739271],
              [-39.54333333376627, -7.575833326246936],
              [-39.542499999475353, -7.575833326246936],
              [-39.542499999475353, -7.574999993754659],
              [-39.541666666983076, -7.574999993754659],
              [-39.541666667882396, -7.573333326072088],
              [-39.540833331792783, -7.573333326072088],
              [-39.540833333591422, -7.572499994479131],
              [-39.539166665908851, -7.572499993579811],
              [-39.539166665908851, -7.571666659288837],
              [-39.53583333054371, -7.571666659288837],
              [-39.535833335040309, -7.57083332589724],
              [-39.535000000749392, -7.57083332589724],
              [-39.534999999850072, -7.569999993404906],
              [-39.533333334865461, -7.569999993404906],
              [-39.533333333066821, -7.569166659113989],
              [-39.532499999675224, -7.569166659113989],
              [-39.532499999675224, -7.568333326621655],
              [-39.53166666808221, -7.568333326621655],
              [-39.531666668981529, -7.567499993230058],
              [-39.530833332891973, -7.567499993230058],
              [-39.530833332891973, -7.566666658039765],
              [-39.53166666538425, -7.566666658939084],
              [-39.53166666538425, -7.562499994678944],
              [-39.532499998775904, -7.562499994678944],
              [-39.532499998775904, -7.559999992705457],
              [-39.53166666808221, -7.559999993604777],
              [-39.53166666628357, -7.554999993255024],
              [-39.530833332891973, -7.554999993255024],
              [-39.530833332891973, -7.554166658964107],
              [-39.528333331817748, -7.554166658964107],
              [-39.528333332717068, -7.552499993080175],
              [-39.529166667008042, -7.552499993080175],
              [-39.529166667008042, -7.547499993629742],
              [-39.528333331817748, -7.547499994529062],
              [-39.528333332717068, -7.546666660238145],
              [-39.529166667907361, -7.546666659338825],
              [-39.529166667008042, -7.545833325947171],
              [-39.530408385238729, -7.545833328645131],
              [-39.53166666808221, -7.545833325947171],
              [-39.53166666538425, -7.544999993454894],
              [-39.532500000574544, -7.544999992555574],
              [-39.532499998775904, -7.545833325947171],
              [-39.533106928741745, -7.545833325947171],
              [-39.533333333966141, -7.545833325947171],
              [-39.534166665559098, -7.545833325947171],
              [-39.534166666458475, -7.546666659338825],
              [-39.534999998950752, -7.546666659338825],
              [-39.534999998950752, -7.547499993629742],
              [-39.536666666633323, -7.547499993629742],
              [-39.536666667532643, -7.548333326122076],
              [-39.53750000002492, -7.548333326122076],
              [-39.537499998226281, -7.549166659513673],
              [-39.538333332517254, -7.549166659513673],
              [-39.538333331617935, -7.550833326296924],
              [-39.539166666808171, -7.550833326296924],
              [-39.539166666808171, -7.551666659688522],
              [-39.542499998576034, -7.551666659688522],
              [-39.542499998576034, -7.550833326296924],
              [-39.545833333041855, -7.550833326296924],
              [-39.545833331243216, -7.551666659688522],
              [-39.546666665534133, -7.551666659688522],
              [-39.546666667332772, -7.554166660762746],
              [-39.548333332317384, -7.554166658964107],
              [-39.548333333216704, -7.553333326471773],
              [-39.550000000899274, -7.553333326471773],
              [-39.55, -7.554166658964107],
              [-39.552499997476843, -7.554166658964107],
              [-39.552499999275483, -7.554999993255024],
              [-39.554166668756693, -7.554999993255024],
              [-39.554166666058734, -7.554166658964107],
              [-39.555000001249027, -7.554166658964107],
              [-39.554999998551068, -7.553333327371092],
              [-39.555833332841985, -7.553333327371092],
              [-39.559999999800084, -7.553333326471773],
              [-39.559999999800084, -7.552499993080175],
              [-39.562499999974989, -7.552499993080175],
              [-39.562500000874309, -7.551666660587841],
              [-39.563333332467266, -7.551666659688522],
              [-39.563333331567947, -7.549166658614354],
              [-39.5641666649596, -7.549166659513673],
              [-39.56416666765756, -7.548333326122076],
              [-39.564999999250517, -7.548333326122076],
              [-39.564999999250517, -7.547499993629742],
              [-39.565833331742795, -7.547499993629742],
              [-39.565833332642171, -7.546666658439506],
              [-39.566666667832408, -7.546666659338825],
              [-39.566666666033768, -7.545833325947171],
              [-39.567499998526046, -7.545833325947171],
              [-39.567499998526046, -7.544999992555574],
              [-39.568333332817019, -7.544999992555574],
              [-39.568333332817019, -7.54416666006324],
              [-39.571666667282841, -7.54416665916392],
              [-39.572500000674438, -7.544166658264601],
              [-39.572500000674438, -7.544999993454894],
              [-39.573333334066092, -7.544999993454894],
              [-39.573333334066092, -7.545833325047852],
              [-39.574166667457689, -7.545833325047852],
              [-39.574166668357009, -7.546666659338825],
              [-39.574999999949966, -7.546666659338825],
              [-39.574999999949966, -7.548333326122076],
              [-39.575833331542981, -7.548333326122076],
              [-39.575833331542981, -7.549166659513673],
              [-39.576666666733217, -7.549166659513673],
              [-39.576666665833898, -7.550833327196244],
              [-39.577499999225552, -7.550833326296924],
              [-39.577500001024191, -7.554999993255024],
              [-39.578333332617149, -7.554999993255024],
              [-39.578333330818509, -7.555833326646678],
              [-39.579166667807442, -7.555833326646678],
              [-39.579166666908122, -7.556666659138955],
              [-39.580000000299719, -7.556666659138955],
              [-39.5799999994004, -7.557499993429929],
              [-39.580833331892734, -7.557499994329248],
              [-39.580833333691373, -7.558333327720845],
              [-39.581666665284331, -7.558333325922206],
              [-39.58166666798229, -7.561666659488708],
              [-39.582499998675928, -7.561666659488708],
              [-39.582499998675928, -7.563333326271959],
              [-39.583333332067582, -7.563333326271959],
              [-39.583333332067582, -7.564166660562876],
              [-39.584166667257819, -7.564166659663556],
              [-39.584166666358499, -7.56499999305521],
              [-39.58583333134311, -7.56499999305521],
              [-39.58583333224243, -7.565833327346127],
              [-39.586666666533404, -7.565833326446807],
              [-39.586666667432723, -7.566666658939084],
              [-39.587500000824321, -7.566666658939084],
              [-39.587499997227042, -7.565833326446807],
              [-39.590000000099906, -7.565833327346127],
              [-39.589999998301209, -7.56499999305521],
              [-39.590833333491503, -7.56499999305521],
              [-39.590833333491503, -7.565833326446807],
              [-39.592499999375434, -7.565833325547487],
              [-39.592500000274754, -7.571666660188157],
              [-39.593333330968392, -7.571666659288837],
              [-39.593333333666351, -7.575833326246936],
              [-39.594166665259365, -7.575833326246936],
              [-39.594166667957325, -7.577499993030187],
              [-39.594999998650962, -7.577499993929507],
              [-39.594999999550282, -7.578333326421841],
              [-39.595833332042616, -7.578333327321161],
              [-39.595833332941936, -7.579166658914119],
              [-39.598333334016104, -7.579166658914119],
              [-39.598333331318145, -7.579999993205092],
              [-39.599999999900035, -7.579999993205092],
              [-39.599999999000715, -7.580833326596689],
              [-39.600833331492993, -7.580833326596689],
              [-39.600833331492993, -7.583333326771594],
              [-39.601666666683286, -7.583333327670914],
              [-39.601666667582606, -7.584166659263872],
              [-39.602500000974203, -7.584166659263872],
              [-39.602499998276244, -7.584999992655469],
              [-39.603333332567217, -7.584999993554788],
              [-39.603333333466537, -7.585833326047123],
              [-39.606666665234343, -7.585833326047123],
              [-39.606666665234343, -7.58666665943872],
              [-39.608333332916914, -7.58666665943872],
              [-39.608333332017594, -7.587499993729693],
              [-39.609166666308568, -7.587499993729693],
              [-39.609166666308568, -7.588333326221971],
              [-39.609999999700165, -7.588333326221971],
              [-39.609999998800845, -7.589999993005222],
              [-39.610833331293179, -7.589999993005222],
              [-39.610833331293179, -7.590833326396876],
              [-39.613333332367347, -7.590833326396876],
              [-39.613333332367347, -7.591666658889153],
              [-39.614166665759001, -7.591666658889153],
              [-39.614166666658321, -7.59249999407939],
              [-39.614999998251278, -7.59249999318007],
              [-39.615000000949237, -7.593333326571724],
              [-39.615833331642875, -7.593333326571724],
              [-39.615833333441515, -7.594999992455655],
              [-39.616666667732488, -7.594999994254295],
              [-39.616666666833169, -7.595833324947932],
              [-39.61833333181778, -7.595833326746572],
              [-39.61833333181778, -7.596666660138226],
              [-39.619166665209377, -7.596666659238906],
              [-39.619166667907336, -7.597499994429143],
              [-39.620833332891948, -7.597499993529823],
              [-39.620833331992628, -7.599166659413754],
              [-39.621666665384282, -7.599166659413754],
              [-39.621666665384282, -7.599999991906031],
              [-39.622500000574519, -7.599999993704671],
              [-39.622499999675199, -7.599166659413754],
              [-39.625000000749367, -7.599166659413754],
              [-39.624999998950727, -7.599999993704671],
              [-39.625833332342381, -7.599999993704671],
              [-39.625833332342381, -7.600833326197005],
              [-39.626666666633298, -7.600833326197005],
              [-39.626666665733978, -7.599999991906031],
              [-39.627500000924272, -7.599999994604048],
              [-39.627499998226313, -7.599166659413754],
              [-39.632499999475328, -7.599166659413754],
              [-39.633333332866982, -7.599166659413754],
              [-39.633333332866982, -7.599999993704671],
              [-39.634999999650233, -7.599999993704671],
              [-39.634999998750914, -7.600833326197005],
              [-39.639166665709013, -7.600833326197005],
              [-39.639166666608332, -7.599999993704671],
              [-39.640833331592944, -7.599999993704671],
              [-39.640833332492264, -7.599166658514434],
              [-39.641666665883861, -7.599166659413754],
              [-39.641666666783181, -7.598333326022157],
              [-39.642500001074154, -7.598333326022157],
              [-39.642500001074154, -7.597499993529823],
              [-39.643333332667112, -7.597499993529823],
              [-39.643333332667112, -7.594999993354975],
              [-39.644166666058766, -7.594999993354975],
              [-39.644166667857405, -7.594166659064001],
              [-39.644999998551043, -7.594166659963321],
              [-39.644999998551043, -7.59249999318007],
              [-39.646666668032253, -7.59249999318007],
              [-39.646666668032253, -7.591666658889153],
              [-39.649999998900796, -7.591666657989833],
              [-39.650000000699436, -7.590833326396876],
              [-39.654166666758215, -7.590833326396876],
              [-39.654166666758215, -7.589166660512944],
              [-39.656666666033743, -7.589166659613625],
              [-39.656666666933063, -7.588333326221971],
              [-39.657499998526077, -7.58833332712129],
              [-39.657499999425397, -7.584166659263872],
              [-39.658333333716314, -7.584166660163191],
              [-39.658333331917675, -7.583333326771594],
              [-39.659166665309328, -7.583333326771594],
              [-39.659999998700926, -7.583333326771594],
              [-39.659999998700926, -7.584166659263872],
              [-39.660833332991899, -7.584166659263872],
              [-39.660833332092579, -7.584999993554788],
              [-39.661666665484177, -7.584999992655469],
              [-39.661666665484177, -7.58666665943872],
              [-39.663333332267428, -7.58666665943872],
              [-39.663333331368108, -7.587499993729693],
              [-39.664166666558344, -7.587499993729693],
              [-39.664166666558344, -7.588333326221971],
              [-39.668333332617181, -7.58833332712129],
              [-39.6683333335165, -7.591666658889153],
              [-39.669166666908097, -7.591666657989833],
              [-39.669166667807417, -7.59249999318007],
              [-39.669999999400432, -7.59249999318007],
              [-39.669999999400432, -7.593333326571724],
              [-39.671666665284306, -7.593333325672404],
              [-39.671666665284306, -7.594166659064001],
              [-39.673333332067557, -7.594166659963321],
              [-39.673333332067557, -7.591666658889153],
              [-39.674166666358531, -7.591666657989833],
              [-39.674166665459211, -7.590833324598179],
              [-39.675000001548767, -7.590833325497499],
              [-39.674999998850808, -7.589999993005222],
              [-39.679166667607603, -7.589999993005222],
              [-39.679166665808964, -7.588333326221971],
              [-39.680833332592158, -7.588333325322651],
              [-39.680833331692838, -7.589166658714305],
              [-39.686666667232885, -7.589166659613625],
              [-39.686666666333565, -7.587499994629013],
              [-39.687499999725162, -7.587499991931054],
              [-39.687499998825842, -7.58666665943872],
              [-39.688333332217439, -7.5866666585394],
              [-39.688333332217439, -7.584999993554788],
              [-39.690833331493025, -7.584999992655469],
              [-39.690833331493025, -7.584166659263872],
              [-39.689166666508413, -7.584166658364552],
              [-39.689166666508413, -7.583333326771594],
              [-39.689166666508413, -7.582499992480621],
              [-39.68999999990001, -7.58249999337994],
              [-39.68999999990001, -7.578333326421841],
              [-39.690833333291664, -7.578333326421841],
              [-39.690833332392344, -7.577499993030187],
              [-39.691666665783941, -7.577499992130868],
              [-39.691666665783941, -7.57666665963859],
              [-39.690833331493025, -7.576666658739271],
              [-39.690833332392344, -7.573333326072088],
              [-39.69000000079933, -7.573333326072088],
              [-39.68999999900069, -7.57083332769588],
              [-39.690833331493025, -7.57083332769588],
              [-39.690833331493025, -7.569999993404906],
              [-39.691666666683261, -7.569999993404906],
              [-39.691666667582581, -7.568333327520975],
              [-39.692500000074915, -7.568333327520975],
              [-39.692500000074915, -7.565833326446807],
              [-39.693333332567192, -7.565833325547487],
              [-39.693333333466512, -7.559999993604777],
              [-39.694166665958846, -7.559999993604777],
              [-39.694166666858166, -7.556666659138955],
              [-39.693333332567192, -7.556666659138955],
              [-39.693333332567192, -7.553333327371092],
              [-39.694166666858166, -7.553333326471773],
              [-39.694166665958846, -7.549999992905327],
              [-39.694999999350443, -7.549999992905327],
              [-39.694999998451124, -7.548333326122076],
              [-39.694166665958846, -7.548333326122076],
              [-39.694166666858166, -7.545833325947171],
              [-39.694999999350443, -7.545833325947171],
              [-39.694999999350443, -7.544999993454894],
              [-39.694166666858166, -7.544999993454894],
              [-39.694166667757486, -7.54416665916392],
              [-39.694999999350443, -7.54416665916392],
              [-39.694999998451124, -7.539166659713544],
              [-39.694166665958846, -7.539166657914905],
              [-39.694166665958846, -7.53833332632189],
              [-39.694999998451124, -7.53833332632189],
              [-39.695000000249763, -7.537499992930293],
              [-39.695833331842721, -7.537499992930293],
              [-39.695833331842721, -7.535833327046362],
              [-39.696666665234375, -7.535833326147042],
              [-39.696666667033014, -7.534166659363791],
              [-39.697499998625972, -7.534166659363791],
              [-39.697499999525292, -7.533333325072874],
              [-39.697499999525292, -7.531666659188943],
              [-39.698333333816265, -7.531666659188943],
              [-39.698333332916945, -7.530833326696609],
              [-39.699166665409223, -7.530833324897969],
              [-39.699166669006502, -7.529999994204331],
              [-39.699166665409223, -7.529166659014038],
              [-39.700000000599516, -7.529166659014038],
              [-39.699999999700196, -7.528333326521761],
              [-39.702499999875045, -7.528333326521761],
              [-39.702499999875045, -7.527499993130107],
              [-39.703333331468002, -7.527499992230787],
              [-39.703333332367379, -7.524999992955259],
              [-39.702500001673684, -7.524999994753898],
              [-39.702500000774364, -7.52083332599716],
              [-39.703333331468002, -7.52083332599716],
              [-39.703333332367379, -7.519166659213909],
              [-39.704166665758976, -7.519166659213909],
              [-39.704166665758976, -7.518333326721631],
              [-39.703333333266698, -7.518333324922935],
              [-39.703333332367379, -7.517499993329977],
              [-39.702499999875045, -7.517499993329977],
              [-39.702499999875045, -7.516666659039061],
              [-39.703333332367379, -7.51666665993838],
              [-39.703333331468002, -7.515833326546726],
              [-39.705000000949269, -7.515833325647407],
              [-39.705000000049893, -7.516666659039061],
              [-39.705833332542227, -7.516666659039061],
              [-39.705833333441547, -7.517499993329977],
              [-39.707499999325478, -7.517499993329977],
              [-39.707499999325478, -7.51666665993838],
              [-39.71083333289198, -7.516666659039061],
              [-39.71083333199266, -7.512499992980224],
              [-39.711666666283577, -7.512499992980224],
              [-39.711666665384257, -7.511666659588627],
              [-39.712499999675174, -7.511666659588627],
              [-39.712499998775854, -7.509166659413779],
              [-39.713333333966148, -7.509166660313099],
              [-39.713333330368869, -7.508333326022125],
              [-39.714166665559105, -7.508333325122805],
              [-39.714166665559105, -7.506666660138194],
              [-39.713333333966148, -7.506666659238874],
              [-39.713333331268188, -7.504999993354943],
              [-39.714166666458425, -7.504999993354943],
              [-39.714166665559105, -7.504166659064026],
              [-39.714999998051439, -7.504166659064026],
              [-39.714999998950759, -7.502499993180095],
              [-39.715833333241676, -7.502499993180095],
              [-39.715833331443037, -7.494999993554813],
              [-39.714999999850079, -7.494999993554813],
              [-39.715000000749399, -7.492499993379965],
              [-39.714166665559105, -7.492499993379965],
              [-39.714166664659786, -7.491666659088992],
              [-39.713333333066828, -7.491666659088992],
              [-39.713333333966148, -7.490833325697395],
              [-39.712499999675174, -7.490833326596714],
              [-39.712499999675174, -7.489166660712783],
              [-39.711666666283577, -7.489166658914144],
              [-39.711666665384257, -7.487499993030212],
              [-39.71083333199266, -7.487499993030212],
              [-39.71083333199266, -7.486666659638558],
              [-39.709999998601006, -7.486666659638558],
              [-39.709999998601006, -7.485833326246961],
              [-39.707499999325478, -7.485833325347642],
              [-39.707499999325478, -7.484999993754684],
              [-39.706666666833144, -7.484999991956045],
              [-39.706666666833144, -7.48416666036303],
              [-39.705000000049893, -7.48416665946371],
              [-39.705000000949269, -7.483333326971433],
              [-39.704166667557615, -7.483333326072113],
              [-39.704166667557615, -7.482499993579779],
              [-39.703333333266698, -7.482499993579779],
              [-39.703333335065338, -7.482330318489346],
              [-39.703333333266698, -7.481666659288862],
              [-39.701666664684808, -7.481666659288862],
              [-39.701666665584128, -7.480833327695848],
              [-39.700205748396513, -7.480833327695848],
              [-39.699999998800877, -7.480833325897208],
              [-39.699999998800877, -7.48065976033962],
              [-39.699999999700196, -7.479999992505611],
              [-39.699166665409223, -7.479999992505611],
              [-39.699166666308543, -7.479166659113957],
              [-39.699166667207862, -7.477499993230083],
              [-39.698333332017626, -7.477499993230083],
              [-39.698333333816265, -7.476666658939109],
              [-39.695000002048403, -7.476666658939109],
              [-39.694999998451124, -7.475833326446832],
              [-39.693333333466512, -7.475833326446832],
              [-39.693333334365832, -7.474166659663581],
              [-39.692499998276276, -7.474166659663581],
              [-39.692499998276276, -7.473333326271927],
              [-39.691666665783941, -7.473333326271927],
              [-39.691666667582581, -7.470833326097079],
              [-39.68999999900069, -7.470833326097079],
              [-39.69000000079933, -7.469166659313828],
              [-39.689166665609093, -7.469166659313828],
              [-39.689166664709774, -7.467499992530577],
              [-39.688333334016136, -7.467499993429897],
              [-39.68833333131812, -7.46666665913898],
              [-39.687499998825842, -7.46666665913898],
              [-39.687499999725162, -7.465833326646646],
              [-39.686666666333565, -7.465833326646646],
              [-39.686666667232885, -7.464999993255049],
              [-39.685833332042591, -7.464999992355729],
              [-39.685833332941911, -7.462499993080144],
              [-39.684999999550314, -7.462499993080144],
              [-39.684999999550314, -7.46083332629695],
              [-39.68416666525934, -7.46083332629695],
              [-39.68416666525934, -7.459166658614379],
              [-39.683333332767063, -7.459166659513699],
              [-39.683333331867743, -7.458333325222725],
              [-39.682500000274729, -7.458333326122045],
              [-39.682499998476089, -7.457499993629767],
              [-39.681666665983812, -7.457499993629767],
              [-39.681666667782451, -7.456666659338794],
              [-39.680000000099881, -7.456666659338794],
              [-39.680000000099881, -7.455833325947196],
              [-39.67833333241731, -7.455833325947196],
              [-39.67833333151799, -7.456666659338794],
              [-39.677499999925033, -7.456666660238113],
              [-39.677499999025713, -7.457499992730448],
              [-39.675833331343142, -7.457499993629767],
              [-39.675833332242462, -7.458333326122045],
              [-39.67249999867596, -7.458333326122045],
              [-39.67249999867596, -7.457499993629767],
              [-39.671666667982322, -7.457499993629767],
              [-39.671666665284306, -7.456666659338794],
              [-39.671666667982322, -7.454999993454862],
              [-39.67249999957528, -7.454999993454862],
              [-39.672500000474599, -7.454166658264626],
              [-39.674999998850808, -7.454166659163945],
              [-39.674999998850808, -7.454999994354182],
              [-39.676666665634059, -7.454999993454862],
              [-39.676666665634059, -7.453333326671668],
              [-39.677499999025713, -7.453333326671668],
              [-39.677499999025713, -7.452499993280014],
              [-39.67833333241731, -7.452499993280014],
              [-39.67833333331663, -7.451666658989097],
              [-39.683333333666383, -7.451666658989097],
              [-39.683333332767063, -7.450833325597443],
              [-39.685833333841231, -7.450833326496763],
              [-39.685833333841231, -7.451666659888417],
              [-39.692500000074915, -7.451666658989097],
              [-39.692499998276276, -7.450833325597443],
              [-39.694999998451124, -7.450833326496763],
              [-39.694999999350443, -7.449999993105166],
              [-39.696666665234375, -7.449999993105166],
              [-39.696666665234375, -7.450833326496763],
              [-39.697500000424611, -7.450833326496763],
              [-39.697499998625972, -7.449999992205846],
              [-39.699166667207862, -7.449999993105166],
              [-39.699166666308543, -7.450833326496763],
              [-39.699999999700196, -7.450833327396083],
              [-39.699999999700196, -7.454999993454862],
              [-39.700833333091794, -7.454999993454862],
              [-39.700833333091794, -7.456666659338794],
              [-39.701666665584128, -7.456666659338794],
              [-39.701666666483447, -7.457499993629767],
              [-39.702499999875045, -7.457499993629767],
              [-39.702500001673684, -7.458333326122045],
              [-39.703333332367379, -7.458333325222725],
              [-39.703333333266698, -7.459166659513699],
              [-39.705000000949269, -7.459166659513699],
              [-39.705000000049893, -7.458333326122045],
              [-39.705833331642907, -7.458333326122045],
              [-39.705833331642907, -7.457499992730448],
              [-39.708333331817755, -7.457499993629767],
              [-39.708333332717075, -7.458333326122045],
              [-39.709166665209409, -7.458333326122045],
              [-39.709166665209409, -7.459166658614379],
              [-39.709999999500326, -7.459166659513699],
              [-39.709999999500326, -7.46083332629695],
              [-39.71083333199266, -7.46083332629695],
              [-39.71083333289198, -7.461666659688547],
              [-39.711666665384257, -7.461666657889907],
              [-39.711666668082216, -7.462499993080144],
              [-39.714166666458425, -7.462499993080144],
              [-39.714166666458425, -7.461666659688547],
              [-39.717500000024927, -7.461666659688547],
              [-39.717500000024927, -7.462216950351944],
              [-39.717500000024927, -7.462499993080144],
              [-39.718333332517261, -7.462499992180824],
              [-39.718333332517261, -7.463333326471798],
              [-39.719166665908858, -7.463333326471798],
              [-39.719166665908858, -7.464166658964075],
              [-39.719999999300512, -7.464166658964075],
              [-39.719999999300512, -7.465833326646646],
              [-39.720833332692109, -7.465833326646646],
              [-39.72083333179279, -7.46666665913898],
              [-39.721666668781722, -7.46666665913898],
              [-39.721666667882346, -7.467499993429897],
              [-39.72249999947536, -7.467499993429897],
              [-39.72249999947536, -7.468333325922231],
              [-39.723333332866957, -7.468333325922231],
              [-39.723333333766277, -7.469166659313828],
              [-39.724166666258611, -7.469166659313828],
              [-39.724166668057251, -7.468333325922231],
              [-39.725000000549528, -7.468333325022911],
              [-39.725000000549528, -7.467499993429897],
              [-39.725833333041862, -7.467499993429897],
              [-39.725833330343903, -7.461777034382578],
              [-39.725833333041862, -7.461666659688547],
              [-39.725701220834651, -7.461666659688547],
              [-39.724999998750889, -7.461666659688547],
              [-39.724999998750889, -7.461080815227376],
              [-39.725000000549528, -7.459999992905296],
              [-39.724166665359292, -7.459999992905296],
              [-39.724166668057251, -7.457499993629767],
              [-39.723333331967638, -7.457499994529087],
              [-39.723333333766277, -7.452499994179334],
              [-39.724166668057251, -7.452499993280014],
              [-39.724166668057251, -7.451666658989097],
              [-39.729166665708988, -7.451666658989097],
              [-39.731666665883893, -7.451666658989097],
              [-39.731666666783212, -7.452499994179334],
              [-39.734999999450395, -7.452499993280014],
              [-39.735000000349714, -7.453333326671668],
              [-39.737499999625243, -7.453333326671668],
              [-39.737500000524562, -7.453902656882065],
              [-39.737499999625243, -7.454166659163945],
              [-39.739166666408494, -7.454166660063265],
              [-39.739166667307813, -7.454999993454862],
              [-39.743333331567953, -7.454999992555543],
              [-39.743333334265913, -7.455833325947196],
              [-39.744166665858927, -7.455833325947196],
              [-39.744166665858927, -7.456666659338794],
              [-39.747500000324692, -7.456666660238113],
              [-39.748333331917706, -7.456666659338794],
              [-39.748333332817026, -7.457499993629767],
              [-39.749166665309303, -7.457499993629767],
              [-39.749166668007263, -7.456666659338794],
              [-39.749999998700957, -7.456666658439474],
              [-39.749999999600277, -7.455833325947196],
              [-39.750833332991874, -7.455833325947196],
              [-39.750833333891194, -7.458333326122045],
              [-39.749999998700957, -7.458333326122045],
              [-39.749999999600277, -7.459166659513699],
              [-39.749999998700957, -7.461666659688547],
              [-39.754166667457696, -7.461666660587866],
              [-39.754166666558376, -7.46083332629695],
              [-39.755833331542988, -7.46083332629695],
              [-39.755833333341627, -7.461666659688547],
              [-39.756666665833905, -7.461666659688547],
              [-39.756666667632544, -7.459166659513699],
              [-39.757499998326239, -7.459166659513699],
              [-39.757500001024198, -7.458333326122045],
              [-39.759166666908129, -7.458333326122045],
              [-39.759166666908129, -7.457499993629767],
              [-39.761666665284338, -7.457499993629767],
              [-39.761666665284338, -7.458333326122045],
              [-39.762499999575255, -7.458333326122045],
              [-39.762499999575255, -7.457499994529087],
              [-39.76499999885084, -7.457499993629767],
              [-39.76499999975016, -7.456666659338794],
              [-39.765833332242437, -7.456666659338794],
              [-39.765833334041076, -7.455833325947196],
              [-39.766666665634091, -7.455833325947196],
              [-39.766666666533411, -7.454999993454862],
              [-39.77083333259219, -7.454999993454862],
              [-39.77083333349151, -7.454166659163945],
              [-39.771666667782426, -7.454166659163945],
              [-39.771666667782426, -7.453333326671668],
              [-39.772499997576801, -7.453333327570988],
              [-39.772500000274761, -7.450833326496763],
              [-39.773333332767038, -7.450833325597443],
              [-39.773333331867718, -7.449999993105166],
              [-39.774166667957331, -7.449999993105166],
              [-39.774166665259372, -7.449166659713512],
              [-39.774999998650969, -7.449166659713512],
              [-39.77499999775165, -7.444999993654733],
              [-39.775462517281312, -7.444999993654733],
              [-39.775833332042623, -7.444999993654733],
              [-39.775833332042623, -7.442499993479885],
              [-39.77666666723286, -7.442499993479885],
              [-39.77666666543422, -7.440833326696634],
              [-39.777499998825817, -7.440833326696634],
              [-39.777499998825817, -7.43999999330498],
              [-39.778333331318152, -7.43999999330498],
              [-39.778333334016111, -7.439166659014063],
              [-39.779166665609068, -7.439166659014063],
              [-39.779166665609068, -7.437499993130132],
              [-39.779999999000722, -7.437499993130132],
              [-39.780000000799362, -7.43416665956363],
              [-39.780833332392319, -7.43416665956363],
              [-39.780833335090279, -7.433333326172033],
              [-39.781666664884654, -7.433333326172033],
              [-39.781666667582613, -7.432499993679698],
              [-39.782499998276251, -7.432499993679698],
              [-39.782499998276251, -7.431666659388782],
              [-39.783333333466544, -7.431666659388782],
              [-39.783333331667905, -7.42999999440417],
              [-39.784166665958821, -7.42999999350485],
              [-39.784166666858141, -7.430833325997128],
              [-39.785833332742072, -7.430833325997128],
              [-39.785833333641392, -7.433333326172033],
              [-39.78666666523435, -7.433333326172033],
              [-39.786666667032989, -7.43416665956363],
              [-39.785833331842753, -7.43416665956363],
              [-39.785833332742072, -7.434999992955284],
              [-39.785000000249795, -7.434999992955284],
              [-39.785000000249795, -7.436666657939838],
              [-39.786666667032989, -7.436666659738535],
              [-39.786666667932309, -7.437499993130132],
              [-39.785833332742072, -7.437499993130132],
              [-39.785833331842753, -7.438333326521729],
              [-39.785833333641392, -7.439166659014063],
              [-39.78666666523435, -7.439166659014063],
              [-39.78666666523435, -7.440833326696634],
              [-39.787499999525323, -7.440833327595954],
              [-39.787500001323963, -7.442499993479885],
              [-39.788333332017601, -7.442499993479885],
              [-39.78833333381624, -7.445833327046387],
              [-39.789166666308574, -7.44583332614701],
              [-39.789166667207894, -7.448333326321915],
              [-39.790000000599491, -7.448333326321915],
              [-39.790000000599491, -7.44964287232068],
              [-39.789999998800852, -7.449999993105166],
              [-39.789166665409255, -7.449999993105166],
              [-39.789166665409255, -7.456666659338794],
              [-39.789999999700171, -7.456666659338794],
              [-39.789999999700171, -7.458333326122045],
              [-39.789166667207894, -7.458333326122045],
              [-39.789166669006534, -7.459166659513699],
              [-39.789999998800852, -7.459166659513699],
              [-39.789999998800852, -7.46083332629695],
              [-39.790833333091825, -7.46083332629695],
              [-39.790833334890465, -7.461666659688547],
              [-39.791666666483422, -7.461666659688547],
              [-39.791666666483422, -7.463333326471798],
              [-39.792499998975757, -7.463333326471798],
              [-39.792499999875076, -7.464166658964075],
              [-39.795833332542202, -7.464166660762714],
              [-39.795833333441522, -7.467499993429897],
              [-39.796666665933856, -7.467499993429897],
              [-39.796666665034536, -7.468333325922231],
              [-39.797499999325453, -7.46833332772087],
              [-39.797500000224773, -7.469166659313828],
              [-39.798333332717107, -7.469166659313828],
              [-39.798333333616426, -7.469999994504121],
              [-39.799166664310064, -7.469999993604802],
              [-39.799166667008024, -7.471666658589356],
              [-39.799999999500358, -7.471666659488676],
              [-39.800000000399677, -7.473333326271927],
              [-39.800833331992635, -7.473333326271927],
              [-39.800833333791275, -7.474166659663581],
              [-39.802499998775886, -7.474166658764261],
              [-39.802499998775886, -7.477499994129403],
              [-39.803333331268163, -7.477499994129403],
              [-39.803333331268163, -7.478333327521],
              [-39.806666666633305, -7.47833332662168],
              [-39.806666666633305, -7.477499992330706],
              [-39.808333331617916, -7.477499993230083],
              [-39.808333333416556, -7.478333327521],
              [-39.812499998576016, -7.47833332572236],
              [-39.812499999475335, -7.477499993230083],
              [-39.811666666083738, -7.477499992330706],
              [-39.811666666983058, -7.476666658939109],
              [-39.810833332692084, -7.47666665803979],
              [-39.810833331792765, -7.475833326446832],
              [-39.80916666590889, -7.475833326446832],
              [-39.80916666680821, -7.474999993055178],
              [-39.808333331617916, -7.474999993055178],
              [-39.808333333416556, -7.473333326271927],
              [-39.80916666590889, -7.473333326271927],
              [-39.80916666770753, -7.47249999288033],
              [-39.808333332517236, -7.47249999288033],
              [-39.808333333416556, -7.468333325922231],
              [-39.807499998226319, -7.468333325922231],
              [-39.807499998226319, -7.462499993080144],
              [-39.808333331617916, -7.462499993080144],
              [-39.808333330718597, -7.459166658614379],
              [-39.80916666770753, -7.459166659513699],
              [-39.80916666590889, -7.457499993629767],
              [-39.810000001099127, -7.457499993629767],
              [-39.809999998401167, -7.456666659338794],
              [-39.810833331792765, -7.456666658439474],
              [-39.810833330893445, -7.455833325947196],
              [-39.811666666083738, -7.455833327745836],
              [-39.811666667882378, -7.452499993280014],
              [-39.812499998576016, -7.452499993280014],
              [-39.812499998576016, -7.451666658989097],
              [-39.813333332866989, -7.451666658989097],
              [-39.81333333106835, -7.450833326496763],
              [-39.814166666258586, -7.450833326496763],
              [-39.814166665359267, -7.449999994004486],
              [-39.81499999965024, -7.449999993105166],
              [-39.814999997851601, -7.438333326521729],
              [-39.815704045605173, -7.438333326521729],
              [-39.815833331243198, -7.438333326521729],
              [-39.815833331243198, -7.437499993130132],
              [-39.815626337885135, -7.437499993130132],
              [-39.81499999875092, -7.437499993130132],
              [-39.81500000054956, -7.436828718470508],
              [-39.81499999875092, -7.434999992955284],
              [-39.815833334840477, -7.434999992955284],
              [-39.815833333041837, -7.429166658314557],
              [-39.816666666433491, -7.429166659213877],
              [-39.816666667332811, -7.428333326721599],
              [-39.817499998925769, -7.428333326721599],
              [-39.817500000724408, -7.425833327446071],
              [-39.818333335015382, -7.425833326546751],
              [-39.818333333216685, -7.423333326371846],
              [-39.817499999825088, -7.423333326371846],
              [-39.817500000724408, -7.42249999208093],
              [-39.818333331418046, -7.422499993879569],
              [-39.818333332317366, -7.419999993704721],
              [-39.81916666570902, -7.419999991906082],
              [-39.819166666608339, -7.41833332602215],
              [-39.818333332317366, -7.41833332602215],
              [-39.818333331418046, -7.417499993529816],
              [-39.817499999825088, -7.417499993529816],
              [-39.817500000724408, -7.416666659238899],
              [-39.816666666433491, -7.416666660138219],
              [-39.816666665534171, -7.415833326746565],
              [-39.815833331243198, -7.415833326746565],
              [-39.815833333041837, -7.414999993354968],
              [-39.81499999965024, -7.414999993354968],
              [-39.81499999965024, -7.414166659063994],
              [-39.814166665359267, -7.414166659063994],
              [-39.814166666258586, -7.410833326396869],
              [-39.813333332866989, -7.410833326396869],
              [-39.813333332866989, -7.409999993005215],
              [-39.814166666258586, -7.409999993005215],
              [-39.814166665359267, -7.409166659613618],
              [-39.813333333766309, -7.409166659613618],
              [-39.813333333766309, -7.408333326221964],
              [-39.811666667882378, -7.408333326221964],
              [-39.811666666983058, -7.405833325147796],
              [-39.810833333591404, -7.405833326047116],
              [-39.810833330893445, -7.404166660163185],
              [-39.811666666083738, -7.404166659263865],
              [-39.811666666083738, -7.401666668981534],
              [-39.819166667507659, -7.401666659089017],
              [-39.81916666570902, -7.402499993379934],
              [-39.820833331592951, -7.402499993379934],
              [-39.820833331592951, -7.403333326771588],
              [-39.821666667682507, -7.403333326771588],
              [-39.821666667682507, -7.406666659438713],
              [-39.823333331767799, -7.406666660338033],
              [-39.823333332667119, -7.407499993729687],
              [-39.82499999945037, -7.407499994629006],
              [-39.82499999945037, -7.408333326221964],
              [-39.827499999625218, -7.408333326221964],
              [-39.827499998725898, -7.409166660512938],
              [-39.829166667307788, -7.409166659613618],
              [-39.829166667307788, -7.409999993904535],
              [-39.831666665684054, -7.409999993005215],
              [-39.831666665684054, -7.410833326396869],
              [-39.832499999974971, -7.410833326396869],
              [-39.832499999974971, -7.411666658889146],
              [-39.834166666758222, -7.411666658889146],
              [-39.834166667657541, -7.4124999922808],
              [-39.834999999250499, -7.4124999922808],
              [-39.834999998351179, -7.414166659063994],
              [-39.835833331742833, -7.414166658164675],
              [-39.835833333541473, -7.414999993354968],
              [-39.83666666693307, -7.414999993354968],
              [-39.83666666783239, -7.419166659413747],
              [-39.837499998526084, -7.419166659413747],
              [-39.837499999425404, -7.419999993704721],
              [-39.838333331917681, -7.419999993704721],
              [-39.838333331917681, -7.4241666597635],
              [-39.837499999425404, -7.4241666597635],
              [-39.837499999425404, -7.425833326546751],
              [-39.83666666693307, -7.425833325647432],
              [-39.83666666603375, -7.426666659039029],
              [-39.835833333541473, -7.426666659039029],
              [-39.835833331742833, -7.427499993330002],
              [-39.834999999250499, -7.427499993330002],
              [-39.834999998351179, -7.429166658314557],
              [-39.835833331742833, -7.429166659213877],
              [-39.835833332642153, -7.42999999350485],
              [-39.83666666693307, -7.42999999350485],
              [-39.83666666783239, -7.430833325997128],
              [-39.835833332642153, -7.430833325997128],
              [-39.835833332642153, -7.431747279113608],
              [-39.835833331742833, -7.43416665956363],
              [-39.83666666693307, -7.43416665956363],
              [-39.83666666603375, -7.434999992955284],
              [-39.837499998526084, -7.434999992955284],
              [-39.839166668007294, -7.434999992955284],
              [-39.839166665309335, -7.435833325447561],
              [-39.840342277973718, -7.435833331742799],
              [-39.8424999997751, -7.435833326346881],
              [-39.8424999997751, -7.436666660637854],
              [-39.843333332267434, -7.436666659738535],
              [-39.843333331368115, -7.437499993130132],
              [-39.844166667457671, -7.437499993130132],
              [-39.844166666558351, -7.438333326521729],
              [-39.844999999050685, -7.438333326521729],
              [-39.844999999950005, -7.439166659014063],
              [-39.845833331542963, -7.439166659014063],
              [-39.845833333341602, -7.43999999330498],
              [-39.846666665833936, -7.43999999330498],
              [-39.846666667632576, -7.440833326696634],
              [-39.847500001024173, -7.440833326696634],
              [-39.847499999225533, -7.441666659188911],
              [-39.848333333516507, -7.441666659188911],
              [-39.848333331717868, -7.442499994379205],
              [-39.849166667807424, -7.442499993479885],
              [-39.849166666008784, -7.443333325072842],
              [-39.850000002098398, -7.443333327770802],
              [-39.849999997601742, -7.444166659363816],
              [-39.850833332792035, -7.444166659363816],
              [-39.850833332792035, -7.444999992755413],
              [-39.851666667982272, -7.444999994554053],
              [-39.851666665284313, -7.44583332614701],
              [-39.853333332966884, -7.44583332614701],
              [-39.853333333866203, -7.446666659538664],
              [-39.854166666358537, -7.446666660437984],
              [-39.854166665459218, -7.447499992930261],
              [-39.854999999750135, -7.447499992930261],
              [-39.854999998850815, -7.448333326321915],
              [-39.855833331343149, -7.448333326321915],
              [-39.855833334041108, -7.449166659713512],
              [-39.856666665634066, -7.449166659713512],
              [-39.856666667432705, -7.449999993105166],
              [-39.85749999902572, -7.449999993105166],
              [-39.857499999925039, -7.450833326496763],
              [-39.860833332592165, -7.450833326496763],
              [-39.860833331692845, -7.451666658989097],
              [-39.861666665983819, -7.451666658989097],
              [-39.861666666883139, -7.452499993280014],
              [-39.862500000274736, -7.452499994179334],
              [-39.862499997576776, -7.454166658264626],
              [-39.864166665259347, -7.454166659163945],
              [-39.864166664360027, -7.456666659338794],
              [-39.864166667957306, -7.458333327021364],
              [-39.866666666333515, -7.458333326122045],
              [-39.866666665434195, -7.457499992730448],
              [-39.868333332217446, -7.457499993629767],
              [-39.868333331318127, -7.456666659338794],
              [-39.870000000799337, -7.456666659338794],
              [-39.869999999000697, -7.454999993454862],
              [-39.87333333166788, -7.454999993454862],
              [-39.87333333076856, -7.455833325947196],
              [-39.874166665958796, -7.455833325047877],
              [-39.874166667757493, -7.456666659338794],
              [-39.87499999935045, -7.456666659338794],
              [-39.87500000024977, -7.455833325947196],
              [-39.876666665234382, -7.455833325947196],
              [-39.876666667932341, -7.456666659338794],
              [-39.877499999525298, -7.456666659338794],
              [-39.877499998625979, -7.457499994529087],
              [-39.879166667207869, -7.457499993629767],
              [-39.879166667207869, -7.458333326122045],
              [-39.882499998975732, -7.458333326122045],
              [-39.882500000774371, -7.459166659513699],
              [-39.884166665758983, -7.459166658614379],
              [-39.884166667557622, -7.459999992005976],
              [-39.885000000049899, -7.459999992005976],
              [-39.885000000049899, -7.46083332629695],
              [-39.885833333441553, -7.46083332539763],
              [-39.885833332542234, -7.459166660413018],
              [-39.88499999825126, -7.459166659513699],
              [-39.885000000949219, -7.458333325222725],
              [-39.885833332542234, -7.458333326122045],
              [-39.885833333441553, -7.457499992730448],
              [-39.886666665933831, -7.457499992730448],
              [-39.88666666683315, -7.454999993454862],
              [-39.885833332542234, -7.454999992555543],
              [-39.885833331642914, -7.454166658264626],
              [-39.885000000049899, -7.454166659163945],
              [-39.88499999825126, -7.453333325772292],
              [-39.884166665758983, -7.453333326671668],
              [-39.884166666658302, -7.452499992380694],
              [-39.883333332367329, -7.452499992380694],
              [-39.883333332367329, -7.44583332614701],
              [-39.882499999875051, -7.445833327046387],
              [-39.882499999875051, -7.444999992755413],
              [-39.883333331468009, -7.444999992755413],
              [-39.883333332367329, -7.444166659363816],
              [-39.884166665758983, -7.444166658464496],
              [-39.884166666658302, -7.443333325072842],
              [-39.886666665034511, -7.443333325972162],
              [-39.88666666683315, -7.442499993479885],
              [-39.887499999325485, -7.442499992580565],
              [-39.887499999325485, -7.441666660088231],
              [-39.888333332717082, -7.441666659188911],
              [-39.888333332717082, -7.439166658114743],
              [-39.889166665209416, -7.439166659014063],
              [-39.889166665209416, -7.438333326521729],
              [-39.888333332717082, -7.438333326521729],
              [-39.888333332717082, -7.437499992230812],
              [-39.889166667907375, -7.437499992230812],
              [-39.889166667907375, -7.430833325997128],
              [-39.889999998601013, -7.430833325997128],
              [-39.889999998601013, -7.42833332492296],
              [-39.888333332717082, -7.428333326721599],
              [-39.888333333616401, -7.427499992430683],
              [-39.885000000949219, -7.427499992430683],
              [-39.88499999825126, -7.424166657964861],
              [-39.883333332367329, -7.4241666597635],
              [-39.883333332367329, -7.423333326371846],
              [-39.882500000774371, -7.423333325472527],
              [-39.882499998975732, -7.420833327096318],
              [-39.880833331293161, -7.420833326196998],
              [-39.880833331293161, -7.419166659413747],
              [-39.879999998800884, -7.419166659413747],
              [-39.879999999700203, -7.416666659238899],
              [-39.879166667207869, -7.416666659238899],
              [-39.87916666540923, -7.415833324947926],
              [-39.878333332017633, -7.415833326746565],
              [-39.878333332916952, -7.414999992455648],
              [-39.877499998625979, -7.414999992455648],
              [-39.877499999525298, -7.414166657265355],
              [-39.874166667757493, -7.414166658164675],
              [-39.874166666858173, -7.41249999318012],
              [-39.873333333466519, -7.41249999318012],
              [-39.873333333466519, -7.410833325497549],
              [-39.872500000074922, -7.410833324598229],
              [-39.872500000074922, -7.409999993005215],
              [-39.874166666858173, -7.409999993005215],
              [-39.874166667757493, -7.410833327296189],
              [-39.874999998451131, -7.410833325497549],
              [-39.875000002048409, -7.411666657989826],
              [-39.875833333641367, -7.411666657989826],
              [-39.875833332742047, -7.41249999407944],
              [-39.877500000424618, -7.4124999922808],
              [-39.877499999525298, -7.411666658889146],
              [-39.878333332017633, -7.411666658889146],
              [-39.878333332916952, -7.410833326396869],
              [-39.878333333816272, -7.409999992105895],
              [-39.877500000424618, -7.409999992105895],
              [-39.877499998625979, -7.409166659613618],
              [-39.87499999935045, -7.409166659613618],
              [-39.874999998451131, -7.408333326221964],
              [-39.870833332392351, -7.408333326221964],
              [-39.870833333291671, -7.407499991931047],
              [-39.869999999900017, -7.407499993729687],
              [-39.869999999000697, -7.406666659438713],
              [-39.8691666656091, -7.406666659438713],
              [-39.86916666650842, -7.405833325147796],
              [-39.869999999000697, -7.405833326047116],
              [-39.869999998101378, -7.404999992655462],
              [-39.870833333291671, -7.404999992655462],
              [-39.870833331493031, -7.399999993205086],
              [-39.869999999000697, -7.399999993205086],
              [-39.869999999900017, -7.398333326421834],
              [-39.8691666656091, -7.398333327321154],
              [-39.8691666656091, -7.397499992130861],
              [-39.868333334016086, -7.397499992130861],
              [-39.868333334016086, -7.396666659638583],
              [-39.867499999725169, -7.396666659638583],
              [-39.867499998825849, -7.394999993754652],
              [-39.866666666333515, -7.394999991956013],
              [-39.866666667232892, -7.393333326971401],
              [-39.864999998651001, -7.393333325172762],
              [-39.865000000449641, -7.392499992680484],
              [-39.862499997576776, -7.392499992680484],
              [-39.862499999375416, -7.391666658389511],
              [-39.861666665983819, -7.39166665928883],
              [-39.861666665983819, -7.389999993404899],
              [-39.860000000099888, -7.389999992505579],
              [-39.859999998301248, -7.388333323923689],
              [-39.859166666708234, -7.388333326621705],
              [-39.859166666708234, -7.387499993230051],
              [-39.857500000824359, -7.387499993230051],
              [-39.85749999902572, -7.386666658039815],
              [-39.856666667432705, -7.386666658939134],
              [-39.856666667432705, -7.384166659663549],
              [-39.857499999925039, -7.384166659663549],
              [-39.857499999925039, -7.380833326097047],
              [-39.852500000474606, -7.380833325197727],
              [-39.852500000474606, -7.379166659313853],
              [-39.851666667082952, -7.379166659313853],
              [-39.851666667082952, -7.378333325922199],
              [-39.850833333691355, -7.378333325922199],
              [-39.850833331892716, -7.377499992530602],
              [-39.850000000299758, -7.377499992530602],
              [-39.850000000299758, -7.376666658239628],
              [-39.849166666008784, -7.376666658239628],
              [-39.849166666008784, -7.3741666589641],
              [-39.847500001024173, -7.3741666589641],
              [-39.847499998326214, -7.373333326471766],
              [-39.846666664934617, -7.373333326471766],
              [-39.846666667632576, -7.367499992730416],
              [-39.845833332442282, -7.367499993629735],
              [-39.845833331542963, -7.366666659338819],
              [-39.845833333341602, -7.364166658264594],
              [-39.846666666733256, -7.364166659163971],
              [-39.846666665833936, -7.363333326671636],
              [-39.845833334240922, -7.363333326671636],
              [-39.845833332442282, -7.362499994179359],
              [-39.846666668531896, -7.36249999238072],
              [-39.846666665833936, -7.360833326496788],
              [-39.849999998501062, -7.360833326496788],
              [-39.850000000299758, -7.361666658989066],
              [-39.850833332792035, -7.361666658089746],
              [-39.850833331892716, -7.362499993280039],
              [-39.851666665284313, -7.362499993280039],
              [-39.851666667982272, -7.363333326671636],
              [-39.853333332067564, -7.363333326671636],
              [-39.853333332067564, -7.362499994179359],
              [-39.859166665808914, -7.362499993280039],
              [-39.859166666708234, -7.363333327570956],
              [-39.860000000999207, -7.363333326671636],
              [-39.860000000099888, -7.364166658264594],
              [-39.860833332592165, -7.364166659163971],
              [-39.860833331692845, -7.364999993454887],
              [-39.860000000099888, -7.364999992555568],
              [-39.860000000099888, -7.367499994529055],
              [-39.862500000274736, -7.367499993629735],
              [-39.862500000274736, -7.36833332612207],
              [-39.86333333186775, -7.36833332612207],
              [-39.86333333276707, -7.369166659513667],
              [-39.864999998651001, -7.369166659513667],
              [-39.864999999550321, -7.370833326296918],
              [-39.865833332941918, -7.370833326296918],
              [-39.865833332941918, -7.371666659688572],
              [-39.867500000624489, -7.371666659688572],
              [-39.867499998825849, -7.372499993080169],
              [-39.868333331318127, -7.372499993080169],
              [-39.868333334016086, -7.373333326471766],
              [-39.870000000799337, -7.373333326471766],
              [-39.869999999900017, -7.3741666589641],
              [-39.871666667582588, -7.3741666589641],
              [-39.871666666683268, -7.373333327371142],
              [-39.873333332567199, -7.373333326471766],
              [-39.87333333166788, -7.3741666589641],
              [-39.874166665958796, -7.3741666589641],
              [-39.874166667757493, -7.377499993429922],
              [-39.874999998451131, -7.377499993429922],
              [-39.87499999935045, -7.378333325922199],
              [-39.875833332742047, -7.378333325922199],
              [-39.875833331842728, -7.379166659313853],
              [-39.877499999525298, -7.379166659313853],
              [-39.877499998625979, -7.37999999360477],
              [-39.878333332017633, -7.37999999360477],
              [-39.878333332017633, -7.381666659488701],
              [-39.880000000599523, -7.381666659488701],
              [-39.879999998800884, -7.383333327171272],
              [-39.880833331293161, -7.383333326271952],
              [-39.88083333399112, -7.384166659663549],
              [-39.881666666483454, -7.384166659663549],
              [-39.881666667382774, -7.3858333264468],
              [-39.882499998975732, -7.3858333264468],
              [-39.882499998975732, -7.386666658939134],
              [-39.88666666683315, -7.386666658939134],
              [-39.88666666773247, -7.387499993230051],
              [-39.889166667907375, -7.38749999772665],
              [-39.889166667008055, -7.3858333264468],
              [-39.889166667008055, -7.384999993055203],
              [-39.888333331817762, -7.384999993055203],
              [-39.888333332717082, -7.383333326271952],
              [-39.887499999325485, -7.383333326271952],
              [-39.887499998426165, -7.382499992880298],
              [-39.885000000049899, -7.382499992880298],
              [-39.885000000949219, -7.381666659488701],
              [-39.884166665758983, -7.381666659488701],
              [-39.884166667557622, -7.380833326097047],
              [-39.883333333266648, -7.380833326097047],
              [-39.883333331468009, -7.37999999360477],
              [-39.881666665584135, -7.37999999360477],
              [-39.881666665584135, -7.377499992530602],
              [-39.880000001498843, -7.377499993429922],
              [-39.879999998800884, -7.376666659138948],
              [-39.879166666308549, -7.376666659138948],
              [-39.879166666308549, -7.375833326646671],
              [-39.878333332017633, -7.375833326646671],
              [-39.878333333816272, -7.374999993255017],
              [-39.876666665234382, -7.374999992355697],
              [-39.876666667932341, -7.372499993080169],
              [-39.875833331842728, -7.372499993979488],
              [-39.875833331842728, -7.371666660587891],
              [-39.874999998451131, -7.371666659688572],
              [-39.874999998451131, -7.369999992905321],
              [-39.874166667757493, -7.369999992905321],
              [-39.874166665958796, -7.369166659513667],
              [-39.87333333166788, -7.369166659513667],
              [-39.87333333166788, -7.36833332522275],
              [-39.872499998276282, -7.36833332612207],
              [-39.872500000974242, -7.367499993629735],
              [-39.871666667582588, -7.367499993629735],
              [-39.871666665783948, -7.366666659338819],
              [-39.870833332392351, -7.366666659338819],
              [-39.870833333291671, -7.365833325947165],
              [-39.871666667582588, -7.365833325947165],
              [-39.871666665783948, -7.364166659163971],
              [-39.872499998276282, -7.364166659163971],
              [-39.872500000974242, -7.362499993280039],
              [-39.87333333166788, -7.362499994179359],
              [-39.873333332567199, -7.361666659888385],
              [-39.869999999000697, -7.361666658989066],
              [-39.869999999900017, -7.360833325597469],
              [-39.86916666740774, -7.360833326496788],
              [-39.86916666650842, -7.359999993105134],
              [-39.867499998825849, -7.359999993105134],
              [-39.867499999725169, -7.361666658989066],
              [-39.864999998651001, -7.361666658989066],
              [-39.864999998651001, -7.360833325597469],
              [-39.86333333366639, -7.360833326496788],
              [-39.86333333366639, -7.359166657914898],
              [-39.862500000274736, -7.359166659713537],
              [-39.862500000274736, -7.357499994728926],
              [-39.860833333491485, -7.357499992930286],
              [-39.860833331692845, -7.355833326147035],
              [-39.859166666708234, -7.355833326147035],
              [-39.859166666708234, -7.356666658639313],
              [-39.858333331517997, -7.356666659538632],
              [-39.858333331517997, -7.357499992930286],
              [-39.854166667257857, -7.357499992930286],
              [-39.854166666358537, -7.358333326321883],
              [-39.849999998501062, -7.358333326321883],
              [-39.849999998501062, -7.356666658639313],
              [-39.849166666008784, -7.356666658639313],
              [-39.849166666908104, -7.355833326147035],
              [-39.848333331717868, -7.355833325247715],
              [-39.848333331717868, -7.354166659363784],
              [-39.847499999225533, -7.354166658464464],
              [-39.847500001024173, -7.353333325972187],
              [-39.845833331542963, -7.353333325972187],
              [-39.845833331542963, -7.352499993479853],
              [-39.846666665833936, -7.352499993479853],
              [-39.846666667632576, -7.351666659188936],
              [-39.847499999225533, -7.351666659188936],
              [-39.847499998326214, -7.350833326696602],
              [-39.848333331717868, -7.350833326696602],
              [-39.848333333516507, -7.349166659014031],
              [-39.849999999400382, -7.349166659014031],
              [-39.849999998501062, -7.347499993130157],
              [-39.850833332792035, -7.347499994029477],
              [-39.850833332792035, -7.345833325447586],
              [-39.849999999400382, -7.345833326346906],
              [-39.849999998501062, -7.344999992055932],
              [-39.848333333516507, -7.344999992955252],
              [-39.848333333516507, -7.342499993679724],
              [-39.849166668706744, -7.342499993679724],
              [-39.849166666908104, -7.34166665938875],
              [-39.848333331717868, -7.34166665938875],
              [-39.848333333516507, -7.340833325997153],
              [-39.849166666008784, -7.340833325997153],
              [-39.849166666908104, -7.339166660113221],
              [-39.847500001024173, -7.339166659213902],
              [-39.847499998326214, -7.338333324922985],
              [-39.845833333341602, -7.338333326721624],
              [-39.845833332442282, -7.337499992430651],
              [-39.846666667632576, -7.33749999332997],
              [-39.846666665833936, -7.336666659039054],
              [-39.845833331542963, -7.336666659039054],
              [-39.845833332442282, -7.335833326546719],
              [-39.846666667632576, -7.335833326546719],
              [-39.846666665833936, -7.334999993155122],
              [-39.849999998501062, -7.334999993155122],
              [-39.849999999400382, -7.333333326371871],
              [-39.849166667807424, -7.333333326371871],
              [-39.849166666008784, -7.33166665958862],
              [-39.849999998501062, -7.33166666048794],
              [-39.850000000299758, -7.329999993704689],
              [-39.849166666908104, -7.329999993704689],
              [-39.849166666908104, -7.328333329619397],
              [-39.852499998675967, -7.328333326022118],
              [-39.852499998675967, -7.327499993529841],
              [-39.853333332067564, -7.327499993529841],
              [-39.853333333866203, -7.326666659238867],
              [-39.854166667257857, -7.326666660138187],
              [-39.854166666358537, -7.32583332674659],
              [-39.858333332417317, -7.32583332674659],
              [-39.858333331517997, -7.326666659238867],
              [-39.859166665808914, -7.326666659238867],
              [-39.859166667607553, -7.328333326022118],
              [-39.861666665983819, -7.328333326022118],
              [-39.861666665983819, -7.329166659413772],
              [-39.862499999375416, -7.329166659413772],
              [-39.862500000274736, -7.330833326197023],
              [-39.865833332941918, -7.330833326197023],
              [-39.865833332042598, -7.329999993704689],
              [-39.866666667232892, -7.329999993704689],
              [-39.86666666678785, -7.329175277252364],
              [-39.866611007, -7.329152820999975],
            ],
          ],
          [
            [
              [-39.474810555726343, -7.566666658939084],
              [-39.474999998351223, -7.566666658939084],
              [-39.475000001948501, -7.565833326446807],
              [-39.474969588694357, -7.565833326446807],
              [-39.474810555726343, -7.566666658939084],
            ],
          ],
          [
            [
              [-39.423272819, -7.586805806999953],
              [-39.423223782505168, -7.587499993729693],
              [-39.423333331667891, -7.587499993729693],
              [-39.423333331941414, -7.58673963032909],
              [-39.423272819, -7.586805806999953],
            ],
          ],
          [
            [
              [-39.399285428, -7.606423734999958],
              [-39.398452814480208, -7.606666659039035],
              [-39.399999998501073, -7.606666659039035],
              [-39.399999998501073, -7.606315964006789],
              [-39.399285428, -7.606423734999958],
            ],
          ],
          [
            [
              [-39.39559659012275, -7.607499993522477],
              [-39.396666665833891, -7.607499993330009],
              [-39.396666665833891, -7.607187787309524],
              [-39.39559659012275, -7.607499993522477],
            ],
          ],
          [
            [
              [-39.36989618911312, -7.618333325734447],
              [-39.37, -7.618333325622416],
              [-39.37, -7.618012791195055],
              [-39.36989618911312, -7.618333325734447],
            ],
          ],
          [
            [
              [-39.36222030062104, -7.625833326448864],
              [-39.362499999475347, -7.625833326147017],
              [-39.362499999576087, -7.625739979385507],
              [-39.36222030062104, -7.625833326448864],
            ],
          ],
          [
            [
              [-39.359291173, -7.626810896999937],
              [-39.358450986725366, -7.627499992930268],
              [-39.359999998401179, -7.627499992930268],
              [-39.359999998401179, -7.626666658639351],
              [-39.361666667882389, -7.626666659538671],
              [-39.361666667882389, -7.626018096500372],
              [-39.359291173, -7.626810896999937],
            ],
          ],
          [
            [
              [-39.357434936646349, -7.628333327151029],
              [-39.357499998226274, -7.628333327221242],
              [-39.358333331617928, -7.628333325422602],
              [-39.358333331617928, -7.627596490169235],
              [-39.357434936646349, -7.628333327151029],
            ],
          ],
          [
            [
              [-39.355427792999897, -7.629979526999937],
              [-39.355341103418596, -7.629999993105173],
              [-39.356666665733997, -7.629999993105173],
              [-39.356666667532636, -7.629166659713519],
              [-39.356666667094011, -7.628963439112977],
              [-39.355427792999897, -7.629979526999937],
            ],
          ],
          [
            [
              [-39.351806354065296, -7.63083332649677],
              [-39.352500000574537, -7.63083332649677],
              [-39.352499998775897, -7.631666658089728],
              [-39.354999998950746, -7.631666658989047],
              [-39.355000000749385, -7.63083332649677],
              [-39.354166666458468, -7.63083332649677],
              [-39.3541666676592, -7.630277013053799],
              [-39.351806354065296, -7.63083332649677],
            ],
          ],
          [
            [
              [-39.349424798951816, -7.631666658989047],
              [-39.349999999500312, -7.631666658989047],
              [-39.349999999127583, -7.631321275890755],
              [-39.349424798951816, -7.631666658989047],
            ],
          ],
        ],
      },
    },
  ],
};
