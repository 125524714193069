export var jsonUFVExpansoPlanejada19 = {
  type: 'FeatureCollection',
  name: 'UFV__Expanso_Planejada_19',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        nome: 'Boa Hora 4',
        leilao: '33º LEN',
        ano_prev: 2024.0,
        CEG: 'UFV.RS.PE.037814-3.01',
        potencia: 23100.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.238812749999965, -8.334467899999936],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Boa Hora 5',
        leilao: '33º LEN',
        ano_prev: 2024.0,
        CEG: 'UFV.RS.PE.037815-1.01',
        potencia: 23100.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.238335789999951, -8.345541819999937],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Boa Hora 6',
        leilao: '33º LEN',
        ano_prev: 2024.0,
        CEG: 'UFV.RS.PE.037816-0.01',
        potencia: 23100.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.237279829999977, -8.35250063999996],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Bom Jardim I',
        leilao: '35º LEN',
        ano_prev: 2026.0,
        CEG: 'UFV.RS.CE.051608-2.01',
        potencia: 48118.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.852861109999935, -6.332861109999953],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Bom Jardim III',
        leilao: '35º LEN',
        ano_prev: 2026.0,
        CEG: 'UFV.RS.CE.051610-4.01',
        potencia: 48118.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.867638889999967, -6.338555549999967],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Gameleira 1',
        leilao: '30º LEN',
        ano_prev: 2025.0,
        CEG: 'UFV.RS.CE.040708-9.01',
        potencia: 33000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.982406949999984, -7.276651939999965],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Gameleira 2',
        leilao: '30º LEN',
        ano_prev: 2025.0,
        CEG: 'UFV.RS.CE.040709-7.01',
        potencia: 33000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.972197479999977, -7.274859989999925],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Gameleira 3',
        leilao: '30º LEN',
        ano_prev: 2025.0,
        CEG: 'UFV.RS.CE.040710-0.01',
        potencia: 33000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.976582009999959, -7.270355949999953],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Gameleira 4',
        leilao: '30º LEN',
        ano_prev: 2025.0,
        CEG: 'UFV.RS.CE.040711-9.01',
        potencia: 33000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.975259679999965, -7.269486979999954],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Graviola 1',
        leilao: '30º LEN',
        ano_prev: 2025.0,
        CEG: 'UFV.RS.PI.036925-0.01',
        potencia: 75000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-42.356489729999964, -8.293821109999953],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Graviola 2',
        leilao: '30º LEN',
        ano_prev: 2025.0,
        CEG: 'UFV.RS.PI.036926-8.01',
        potencia: 75000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-42.356580279999946, -8.263600839999981],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Graviola 3',
        leilao: '30º LEN',
        ano_prev: 2025.0,
        CEG: 'UFV.RS.PI.037745-7.01',
        potencia: 75000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-42.349368889999937, -8.273442489999979],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Graviola 4',
        leilao: '30º LEN',
        ano_prev: 2025.0,
        CEG: 'UFV.RS.PI.037746-5.01',
        potencia: 75000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-42.327644719999967, -8.266520829999934],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Luiz Gonzaga II',
        leilao: '30º LEN',
        ano_prev: 2025.0,
        CEG: 'UFV.RS.PE.045057-0.01',
        potencia: 30000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.33875194999996, -8.091799719999926],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Luzia 2',
        leilao: 'ANEEL',
        ano_prev: 2023.0,
        CEG: 'UFV.RS.PB.044469-3.01',
        potencia: 59868.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.928880559999982, -6.831925],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Luzia 3',
        leilao: 'ANEEL',
        ano_prev: 2023.0,
        CEG: 'UFV.RS.PB.044470-7.01',
        potencia: 59868.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.929913889999966, -6.845705549999934],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Milagres I',
        leilao: '29º LEN',
        ano_prev: 2024.0,
        CEG: 'UFV.RS.CE.044573-8.01',
        potencia: 32740.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.998550279999961, -7.381828329999962],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Milagres II',
        leilao: '29º LEN',
        ano_prev: 2024.0,
        CEG: 'UFV.RS.CE.044574-6.01',
        potencia: 32740.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.988193019999983, -7.38591675999993],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Milagres III',
        leilao: '29º LEN',
        ano_prev: 2024.0,
        CEG: 'UFV.RS.CE.044575-4.01',
        potencia: 32740.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.990267939999967, -7.38904660999998],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Milagres IV',
        leilao: '29º LEN',
        ano_prev: 2024.0,
        CEG: 'UFV.RS.CE.044579-7.01',
        potencia: 32740.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: { type: 'Point', coordinates: [-38.97683, -7.384179539999934] },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Milagres V',
        leilao: '29º LEN',
        ano_prev: 2024.0,
        CEG: 'UFV.RS.CE.044576-2.01',
        potencia: 32740.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.98107951999998, -7.390593219999971],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Rio do Peixe I',
        leilao: 'ANEEL',
        ano_prev: 2023.0,
        CEG: 'UFV.RS.PB.043210-5.01',
        potencia: 32740.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.483974789999934, -6.792958519999956],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Rio do Peixe II',
        leilao: 'ANEEL',
        ano_prev: 2023.0,
        CEG: 'UFV.RS.PB.043215-6.01',
        potencia: 36014.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.476736109999933, -6.801474989999974],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Santa Luzia IX',
        leilao: '34º LEN',
        ano_prev: 2025.0,
        CEG: 'UFV.RS.PB.049693-6.01',
        potencia: 50000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.97068325999993, -6.917679079999971],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Santa Luzia V',
        leilao: '33º LEN',
        ano_prev: 2024.0,
        CEG: 'UFV.RS.PB.049689-8.01',
        potencia: 50000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.965181079999979, -6.901183939999953],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Santa Luzia VII',
        leilao: '33º LEN',
        ano_prev: 2024.0,
        CEG: 'UFV.RS.PB.049691-0.01',
        potencia: 50000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.973033899999962, -6.905294629999958],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'São Pedro e Paulo I',
        leilao: '27º LEN',
        ano_prev: 2023.0,
        CEG: 'UFV.RS.PE.031870-1.01',
        potencia: 25000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.982777779999935, -7.878355549999981],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Xaxado 1',
        leilao: 'ANEEL',
        ano_prev: 2023.0,
        CEG: 'UFV.RS.PE.034405-2.01',
        potencia: 32000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: { type: 'Point', coordinates: [-38.6225, -7.979722219999928] },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Xaxado 2',
        leilao: 'ANEEL',
        ano_prev: 2023.0,
        CEG: 'UFV.RS.PE.034406-0.01',
        potencia: 32000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.619166659999962, -7.971666669999934],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Belmonte 1-1',
        leilao: '36º LEN',
        ano_prev: 2026.0,
        CEG: 'UFV.RS.PE.040725-9.01',
        potencia: 50000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.827817499999981, -7.855856939999967],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Belmonte 1-4',
        leilao: '36º LEN',
        ano_prev: 2026.0,
        CEG: 'UFV.RS.PE.040728-3.01',
        potencia: 6061.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.822280279999973, -7.864815],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Belmonte 2-1',
        leilao: '36º LEN',
        ano_prev: 2026.0,
        CEG: 'UFV.RS.PE.040735-6.01',
        potencia: 50000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.83301943999993, -7.844311109999978],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Luiz Gonzaga I',
        leilao: '36º LEN',
        ano_prev: 2026.0,
        CEG: 'UFV.RS.PE.045056-1.01',
        potencia: 30000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.337130559999935, -8.094305559999952],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Luiz Gonzaga III',
        leilao: '36º LEN',
        ano_prev: 2026.0,
        CEG: 'UFV.RS.PE.045058-8.01',
        potencia: 30000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.340666659999954, -8.104388889999939],
      },
    },
  ],
};
