import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';

interface GraphIrradiationProps {
  monthlyIrradiation: number[];
}

export const GraphIrradiation: React.FC<GraphIrradiationProps> = ({
  monthlyIrradiation,
}) => {
  const options = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Radiação solar global horizontal',
      style: {
        fontSize: '12px',
        color: '#015C53',
      },
    },
    xAxis: {
      categories: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ],
      labels: {
        style: {
          fontSize: '12px',
        },
      },
      title: {
        text: undefined,
      },
    },
    yAxis: {
      min: 0,
      labels: {
        style: {
          fontSize: '12px',
        },
      },
      title: {
        text: undefined,
      },
    },
    series: [
      {
        data: monthlyIrradiation,
        color: '#DCCA28',
        name: 'Radiação',
        showInLegend: false,
      },
    ],
    plotOptions: {
      series: {
        dataLabels: {
          style: {
            fontSize: '14px',
          },
        },
      },
    },
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
