import styled from 'styled-components';
import { Box, Typography } from '@mui/material';

export const WindBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

export const SmallWindBox = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 140px;
  height: 100px;
`;

export const LargeWindBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #015c53;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 300px;
  height: 100px;
  gap: 8px;
`;

export const SliderWindBox = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  gap: 10px;
  width: 300px;
  height: 100px;
`;

export const WindTypographyTitle = styled(Typography)`
  color: #015c53;
  font-weight: bold !important;
  font-size: 12px !important;
  margin: 0;
`;

export const WindTypographyData = styled(Typography)`
  color: #000000;
  font-weight: bold;
  font-size: 12px !important;
  margin: 0;
`;

export const WindPowerText = styled.div`
  color: white;
  font-size: 14px;
  margin-left: 4px;
`;

export const ChangeText = styled.div`
  color: #cce3e3;
  font-size: 12px;
`;

export const WindIconContainer = styled.div`
  color: #d3b0ff;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;
