export var jsonCGHBaseExistente21 = {
  type: 'FeatureCollection',
  name: 'CGH__Base_Existente_21',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        NOME: 'Humaytá',
        propriet: '100% para Vale Verde Empreendimentos Agrícolas Ltda (REG)',
        RIO: 'Rio Una',
        potencia: 1000.0,
        ini_oper: '1925-01-01',
        ceg: 'CGH.PH.PE.028359-2.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.597730419999948, -8.509108519999927],
      },
    },
    {
      type: 'Feature',
      properties: {
        NOME: 'Pé de Serra',
        propriet: '100% para CENTRAL ENERGÉTICA UNIÃO S/A (REG)',
        RIO: 'Açude de Pé de Serra',
        potencia: 144.0,
        ini_oper: '1989-06-01',
        ceg: 'CGH.PH.PE.028693-1.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.342400779999934, -8.347631989999968],
      },
    },
    {
      type: 'Feature',
      properties: {
        NOME: 'Mariquita',
        propriet: '100% para CENTRAL ENERGÉTICA UNIÃO S/A (REG)',
        RIO: 'Rio Aurora',
        potencia: 880.0,
        ini_oper: '1925-03-01',
        ceg: 'CGH.PH.PE.028695-8.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.402714379999964, -8.353185959999962],
      },
    },
    {
      type: 'Feature',
      properties: {
        NOME: 'Poço da Cruz',
        propriet:
          '100% para Central Geradora Hidrelétrica Poço da Cruz Ltda (REG)',
        RIO: 'Rio Moxotó',
        potencia: 999.0,
        ini_oper: '2012-02-01',
        ceg: 'CGH.PH.PE.030539-1.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.705555559999937, -8.508333329999971],
      },
    },
    {
      type: 'Feature',
      properties: {
        NOME: 'Gustavo Paiva',
        propriet: '100% para Cia. Alagoana de Empreendimentos (REG)',
        RIO: 'Rio Mundaí',
        potencia: 840.0,
        ini_oper: '2009-06-22',
        ceg: 'CGH.PH.AL.030112-4.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.848888889999955, -9.491944439999941],
      },
    },
  ],
};
