export var json_estacoes_solarimetricas_ifpe_16 = {
  type: 'FeatureCollection',
  name: 'estacoes_solarimetricas_ifpe_16',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        fid: 1.0,
        'Esta��': 'AL-INMET-PALMEIRAS DOS INDIOS',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'PALMEIRAS DOS INDIOS',
        Estado: 'AL',
        Latitude: -9.42028,
        Longitude: -36.62028,
        Altitude: '278',
      },
      geometry: { type: 'Point', coordinates: [-36.6202778, -9.4202778] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 2.0,
        'Esta��': 'AL-INMET-PAO DE ACUCAR',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'PAO DE ACUCAR',
        Estado: 'AL',
        Latitude: -9.74922,
        Longitude: -37.43077,
        Altitude: '20',
      },
      geometry: { type: 'Point', coordinates: [-37.430765, -9.74922] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 3.0,
        'Esta��': 'AL-INMET-PIRANHAS',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'PIRANHAS',
        Estado: 'AL',
        Latitude: -9.62222,
        Longitude: -37.76722,
        Altitude: '187',
      },
      geometry: { type: 'Point', coordinates: [-37.7672222, -9.6222222] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 4.0,
        'Esta��': 'AL-INMET-SAO LUIZ DO QUINTUDE',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'SAO LUIZ DO QUINTUDE',
        Estado: 'AL',
        Latitude: -9.28748,
        Longitude: -35.56588,
        Altitude: '13',
      },
      geometry: { type: 'Point', coordinates: [-35.565878, -9.287481] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 5.0,
        'Esta��': 'BA-INMET-CURACA',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'CURACA',
        Estado: 'BA',
        Latitude: -9.00139,
        Longitude: -39.9125,
        Altitude: '370',
      },
      geometry: { type: 'Point', coordinates: [-39.9125, -9.0013889] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 6.0,
        'Esta��': 'CE-INMET-BARBALHA',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'BARBALHA',
        Estado: 'CE',
        Latitude: -7.30083,
        Longitude: -39.27111,
        Altitude: '409',
      },
      geometry: { type: 'Point', coordinates: [-39.2711111, -7.3008333] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 7.0,
        'Esta��': 'CE-INMET-CAMPOS SALES',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'CAMPOS SALES',
        Estado: 'CE',
        Latitude: -7.07778,
        Longitude: -40.36278,
        Altitude: '578',
      },
      geometry: { type: 'Point', coordinates: [-40.3627778, -7.0777778] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 8.0,
        'Esta��': 'PB-INMET-CABACEIRAS',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'CABACEIRAS',
        Estado: 'PB',
        Latitude: -7.48321,
        Longitude: -36.28647,
        Altitude: '391',
      },
      geometry: { type: 'Point', coordinates: [-36.286469, -7.483206] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 9.0,
        'Esta��': 'PB-INMET-ITAPORANGA',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'ITAPORANGA',
        Estado: 'PB',
        Latitude: -7.31847,
        Longitude: -38.14083,
        Altitude: '292',
      },
      geometry: { type: 'Point', coordinates: [-38.140833, -7.318472] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 10.0,
        'Esta��': 'PB-INMET-MONTEIRO',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'MONTEIRO',
        Estado: 'PB',
        Latitude: -7.89444,
        Longitude: -37.12472,
        Altitude: '606',
      },
      geometry: { type: 'Point', coordinates: [-37.1247222, -7.8944444] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 11.0,
        'Esta��': 'PB-INMET-PATOS',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'PATOS',
        Estado: 'PB',
        Latitude: 7.07972,
        Longitude: -37.27278,
        Altitude: '263',
      },
      geometry: { type: 'Point', coordinates: [-37.2727778, 7.0797222] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 12.0,
        'Esta��': 'PE-APAC-AMARAJI',
        'Institui��': 'APAC',
        'Endere�o': null,
        Cidade: 'AMARAJI',
        Estado: 'PE',
        Latitude: -8.36756,
        Longitude: -35.4598,
        Altitude: '261',
      },
      geometry: { type: 'Point', coordinates: [-35.4598, -8.36756] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 13.0,
        'Esta��': 'PE-APAC-BELEM DO SAO FRANCISCO',
        'Institui��': 'APAC',
        'Endere�o': null,
        Cidade: 'BELEM DO SAO FRANCISCO',
        Estado: 'PE',
        Latitude: -8.7578,
        Longitude: -38.9822,
        Altitude: '320',
      },
      geometry: { type: 'Point', coordinates: [-38.9822, -8.7578] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 14.0,
        'Esta��': 'PE-APAC-BREJO DA MADRE DE DEUS',
        'Institui��': 'APAC',
        'Endere�o': null,
        Cidade: 'BREJO DA MADRE DE DEUS',
        Estado: 'PE',
        Latitude: -8.1385,
        Longitude: -36.3589,
        Altitude: '644',
      },
      geometry: { type: 'Point', coordinates: [-36.3589, -8.1385] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 15.0,
        'Esta��': 'PE-APAC-BUIQUE',
        'Institui��': 'APAC',
        'Endere�o': null,
        Cidade: 'BUIQUE',
        Estado: 'PE',
        Latitude: -8.60921,
        Longitude: -37.2426,
        Altitude: '837',
      },
      geometry: { type: 'Point', coordinates: [-37.2426, -8.60921] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 16.0,
        'Esta��': 'PE-APAC-CARUARU',
        'Institui��': 'APAC',
        'Endere�o': null,
        Cidade: 'CARUARU',
        Estado: 'PE',
        Latitude: -8.2487,
        Longitude: -35.9166,
        Altitude: '560',
      },
      geometry: { type: 'Point', coordinates: [-35.9166, -8.2487] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 17.0,
        'Esta��': 'PE-APAC-CUSTODIA',
        'Institui��': 'APAC',
        'Endere�o': null,
        Cidade: 'CUSTODIA',
        Estado: 'PE',
        Latitude: -8.08796,
        Longitude: -37.62659,
        Altitude: '542',
      },
      geometry: { type: 'Point', coordinates: [-37.62659, -8.08796] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 18.0,
        'Esta��': 'PE-APAC-FERNANDO DE NORONHA',
        'Institui��': 'APAC',
        'Endere�o': null,
        Cidade: 'FERNANDO DE NORONHA',
        Estado: 'PE',
        Latitude: -3.8489,
        Longitude: -32.431,
        Altitude: '5',
      },
      geometry: { type: 'Point', coordinates: [-32.431, -3.8489] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 19.0,
        'Esta��': 'PE-APAC-FLORESTA',
        'Institui��': 'APAC',
        'Endere�o': null,
        Cidade: 'FLORESTA',
        Estado: 'PE',
        Latitude: -8.53925,
        Longitude: -38.19356,
        Altitude: '327',
      },
      geometry: { type: 'Point', coordinates: [-38.19356, -8.53925] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 20.0,
        'Esta��': 'PE-APAC-GRAVATA',
        'Institui��': 'APAC',
        'Endere�o': null,
        Cidade: 'GRAVATA',
        Estado: 'PE',
        Latitude: -8.20511,
        Longitude: -35.58026,
        Altitude: '487',
      },
      geometry: { type: 'Point', coordinates: [-35.58026, -8.20511] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 21.0,
        'Esta��': 'PE-APAC-MIRANDIBA',
        'Institui��': 'APAC',
        'Endere�o': null,
        Cidade: 'MIRANDIBA',
        Estado: 'PE',
        Latitude: -8.12184,
        Longitude: -38.72064,
        Altitude: '457',
      },
      geometry: { type: 'Point', coordinates: [-38.72064, -8.12184] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 22.0,
        'Esta��': 'PE-APAC-OLINDA',
        'Institui��': 'APAC',
        'Endere�o': null,
        Cidade: 'OLINDA',
        Estado: 'PE',
        Latitude: -8.03619,
        Longitude: -34.86922,
        Altitude: '10',
      },
      geometry: { type: 'Point', coordinates: [-34.86922, -8.03619] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 23.0,
        'Esta��': 'PE-APAC-PARNAMIRIM',
        'Institui��': 'APAC',
        'Endere�o': null,
        Cidade: 'PARNAMIRIM',
        Estado: 'PE',
        Latitude: -8.08856,
        Longitude: -39.57285,
        Altitude: '383',
      },
      geometry: { type: 'Point', coordinates: [-39.57285, -8.08856] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 24.0,
        'Esta��': 'PE-APAC-PETROLINA',
        'Institui��': 'APAC',
        'Endere�o': null,
        Cidade: 'PETROLINA',
        Estado: 'PE',
        Latitude: -8.67545,
        Longitude: -40.56657,
        Altitude: '386',
      },
      geometry: { type: 'Point', coordinates: [-40.56657, -8.67545] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 25.0,
        'Esta��': 'PE-APAC-SANTA MARIA DA BOA VISTA',
        'Institui��': 'APAC',
        'Endere�o': null,
        Cidade: 'SANTA MARIA DA BOA VISTA',
        Estado: 'PE',
        Latitude: -8.7875,
        Longitude: -39.8317,
        Altitude: '372',
      },
      geometry: { type: 'Point', coordinates: [-39.8317, -8.7875] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 26.0,
        'Esta��': 'PE-APAC-SAO JOSE DO EGITO',
        'Institui��': 'APAC',
        'Endere�o': null,
        Cidade: 'SAO JOSE DO EGITO',
        Estado: 'PE',
        Latitude: -7.474,
        Longitude: -37.275,
        Altitude: '591',
      },
      geometry: { type: 'Point', coordinates: [-37.275, -7.474] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 27.0,
        'Esta��': 'PE-APAC-SERTANIA',
        'Institui��': 'APAC',
        'Endere�o': null,
        Cidade: 'SERTANIA',
        Estado: 'PE',
        Latitude: -8.06331,
        Longitude: -37.22446,
        Altitude: '570',
      },
      geometry: { type: 'Point', coordinates: [-37.22446, -8.06331] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 28.0,
        'Esta��': 'PE-APAC-TRIUNFO',
        'Institui��': 'APAC',
        'Endere�o': null,
        Cidade: 'TRIUNFO',
        Estado: 'PE',
        Latitude: -7.82972,
        Longitude: -38.1221,
        Altitude: '1019',
      },
      geometry: { type: 'Point', coordinates: [-38.1221, -7.82972] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 29.0,
        'Esta��': 'PE-APAC-VENTUROSA',
        'Institui��': 'APAC',
        'Endere�o': null,
        Cidade: 'VENTUROSA',
        Estado: 'PE',
        Latitude: -8.57086,
        Longitude: -36.89276,
        Altitude: '525',
      },
      geometry: { type: 'Point', coordinates: [-36.89276, -8.57086] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 30.0,
        'Esta��': 'PE-APAC-VERTENTES',
        'Institui��': 'APAC',
        'Endere�o': null,
        Cidade: 'VERTENTES',
        Estado: 'PE',
        Latitude: -7.9046,
        Longitude: -35.9739,
        Altitude: '417',
      },
      geometry: { type: 'Point', coordinates: [-35.9739, -7.9046] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 31.0,
        'Esta��': 'PE-APAC-VICENCIA',
        'Institui��': 'APAC',
        'Endere�o': null,
        Cidade: 'VICENCIA',
        Estado: 'PE',
        Latitude: -7.66081,
        Longitude: -35.364,
        Altitude: '117',
      },
      geometry: { type: 'Point', coordinates: [-35.364, -7.66081] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 32.0,
        'Esta��': 'PE-IFPE-AFOGADOS',
        'Institui��': 'IFPE',
        'Endere�o': null,
        Cidade: 'AFOGADOS DA INGAZEIRA',
        Estado: 'PE',
        Latitude: -7.76489,
        Longitude: -37.63114,
        Altitude: '542',
      },
      geometry: { type: 'Point', coordinates: [-37.631142, -7.764887] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 33.0,
        'Esta��': 'PE-IFPE-AFR�NIO',
        'Institui��': 'IFPE',
        'Endere�o': null,
        Cidade: 'AFR�NIO',
        Estado: 'PE',
        Latitude: -8.51444,
        Longitude: -41.00797,
        Altitude: '509',
      },
      geometry: { type: 'Point', coordinates: [-41.007967, -8.514439] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 34.0,
        'Esta��': 'PE-IFPE-�GUAS BELAS',
        'Institui��': 'IFPE',
        'Endere�o': null,
        Cidade: '�GUAS BELAS',
        Estado: 'PE',
        Latitude: -9.11251,
        Longitude: -37.11734,
        Altitude: '327',
      },
      geometry: { type: 'Point', coordinates: [-37.117339, -9.112511] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 35.0,
        'Esta��': 'PE-IFPE-ARARIPINA',
        'Institui��': 'IFPE',
        'Endere�o': null,
        Cidade: 'ARARIPINA',
        Estado: 'PE',
        Latitude: -7.57423,
        Longitude: -40.51442,
        Altitude: '633',
      },
      geometry: { type: 'Point', coordinates: [-40.514417, -7.5742333] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 36.0,
        'Esta��': 'PE-IFPE-ARENA PE',
        'Institui��': 'IFPE',
        'Endere�o': null,
        Cidade: 'S�O LOUREN�O DA MATA',
        Estado: 'PE',
        Latitude: -8.04074,
        Longitude: -35.0125,
        Altitude: '5',
      },
      geometry: { type: 'Point', coordinates: [-35.012498, -8.040741] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 37.0,
        'Esta��': 'PE-IFPE-DORMENTES',
        'Institui��': 'IFPE',
        'Endere�o': null,
        Cidade: 'DORMENTES',
        Estado: 'PE',
        Latitude: -8.51307,
        Longitude: -40.41635,
        Altitude: '442',
      },
      geometry: { type: 'Point', coordinates: [-40.416348, -8.513068] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 38.0,
        'Esta��': 'PE-IFPE-EXU',
        'Institui��': 'IFPE',
        'Endere�o': null,
        Cidade: 'EXU',
        Estado: 'PE',
        Latitude: -7.51222,
        Longitude: -39.72121,
        Altitude: '523',
      },
      geometry: { type: 'Point', coordinates: [-39.721213, -7.512222] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 39.0,
        'Esta��': 'PE-IFPE-PESQUEIRA',
        'Institui��': 'IFPE',
        'Endere�o': 'BR 232, KM 208 - Loteamento Portal',
        Cidade: 'PESQUEIRA',
        Estado: 'PE',
        Latitude: -8.36663,
        Longitude: -36.68065,
        Altitude: '650',
      },
      geometry: { type: 'Point', coordinates: [-36.68065, -8.3666333] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 40.0,
        'Esta��': 'PE-IFPE-SANTA FILOMENA',
        'Institui��': 'IFPE',
        'Endere�o': null,
        Cidade: 'SANTA FILOMENA',
        Estado: 'PE',
        Latitude: -8.15973,
        Longitude: -40.60599,
        Altitude: '277',
      },
      geometry: { type: 'Point', coordinates: [-40.605994, -8.159728] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 41.0,
        'Esta��': 'PE-IFPE-S�O JOS� DO BELMONTE',
        'Institui��': 'IFPE',
        'Endere�o': null,
        Cidade: 'S�O JOS� DO BELMONTE',
        Estado: 'PE',
        Latitude: -7.8893,
        Longitude: -38.74251,
        Altitude: '486',
      },
      geometry: { type: 'Point', coordinates: [-38.742507, -7.889298] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 42.0,
        'Esta��': 'PE-IFPE-TIMBA�BA',
        'Institui��': 'IFPE',
        'Endere�o': null,
        Cidade: 'TIMBA�BA',
        Estado: 'PE',
        Latitude: -7.51586,
        Longitude: -35.31066,
        Altitude: '102',
      },
      geometry: { type: 'Point', coordinates: [-35.31066, -7.515864] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 43.0,
        'Esta��': 'PE-IFPE-TUPANATINGA',
        'Institui��': 'IFPE',
        'Endere�o': null,
        Cidade: 'TUPANATINGA',
        Estado: 'PE',
        Latitude: -8.75889,
        Longitude: -37.33981,
        Altitude: '720',
      },
      geometry: { type: 'Point', coordinates: [-37.339807, -8.758892] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 44.0,
        'Esta��': 'PE-IFPE-URIMAM�',
        'Institui��': 'IFPE',
        'Endere�o': null,
        Cidade: 'SANTA MARIA DA BOA VISTA',
        Estado: 'PE',
        Latitude: -8.42528,
        Longitude: -39.95286,
        Altitude: '406',
      },
      geometry: { type: 'Point', coordinates: [-39.952857, -8.425284] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 45.0,
        'Esta��': 'PE-IFPE-VIT�RIA',
        'Institui��': 'IFPE',
        'Endere�o': null,
        Cidade: 'VIT�RIA DE SANTO ANT�O',
        Estado: 'PE',
        Latitude: -8.09698,
        Longitude: -35.28951,
        Altitude: '168',
      },
      geometry: { type: 'Point', coordinates: [-35.289514, -8.0969778] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 46.0,
        'Esta��': 'PE-INMET-ARCOVERDE',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'ARCOVERDE',
        Estado: 'PE',
        Latitude: -8.43361,
        Longitude: -37.05556,
        Altitude: '384',
      },
      geometry: { type: 'Point', coordinates: [-37.0555556, -8.4336111] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 47.0,
        'Esta��': 'PE-INMET-CABROBO',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'CABROBO',
        Estado: 'PE',
        Latitude: -8.504,
        Longitude: -39.31528,
        Altitude: '342',
      },
      geometry: { type: 'Point', coordinates: [-39.31528, -8.504] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 48.0,
        'Esta��': 'PE-INMET-CARUARU',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'CARUARU',
        Estado: 'PE',
        Latitude: -8.36528,
        Longitude: -36.02833,
        Altitude: '852',
      },
      geometry: { type: 'Point', coordinates: [-36.0283333, -8.3652778] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 49.0,
        'Esta��': 'PE-INMET-FLORESTA',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'FLORESTA',
        Estado: 'PE',
        Latitude: -8.59879,
        Longitude: -38.58406,
        Altitude: '327',
      },
      geometry: { type: 'Point', coordinates: [-38.584062, -8.598785] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 50.0,
        'Esta��': 'PE-INMET-GARANHUNS',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'GARANHUNS',
        Estado: 'PE',
        Latitude: -8.91095,
        Longitude: -36.49338,
        Altitude: '827',
      },
      geometry: { type: 'Point', coordinates: [-36.493381, -8.91095] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 51.0,
        'Esta��': 'PE-INMET-IBIMIRIM',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'IBIMIRIM',
        Estado: 'PE',
        Latitude: -8.50944,
        Longitude: -37.71167,
        Altitude: '434',
      },
      geometry: { type: 'Point', coordinates: [-37.7116667, -8.5094444] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 52.0,
        'Esta��': 'PE-INMET-OURICURI',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'OURICURI',
        Estado: 'PE',
        Latitude: -7.88583,
        Longitude: -40.10278,
        Altitude: '457',
      },
      geometry: { type: 'Point', coordinates: [-40.1027778, -7.8858333] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 53.0,
        'Esta��': 'PE-INMET-PALMARES',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'PALMARES',
        Estado: 'PE',
        Latitude: -8.66667,
        Longitude: -35.56806,
        Altitude: '164',
      },
      geometry: { type: 'Point', coordinates: [-35.5680555, -8.6666667] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 54.0,
        'Esta��': 'PE-INMET-PETROLINA',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'PETROLINA',
        Estado: 'PE',
        Latitude: -9.38832,
        Longitude: -40.52326,
        Altitude: '372',
      },
      geometry: { type: 'Point', coordinates: [-40.523262, -9.388323] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 55.0,
        'Esta��': 'PE-INMET-RECIFE',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'RECIFE',
        Estado: 'PE',
        Latitude: -8.05928,
        Longitude: -34.95924,
        Altitude: '11',
      },
      geometry: { type: 'Point', coordinates: [-34.959239, -8.05928] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 56.0,
        'Esta��': 'PE-INMET-SALGUEIRO',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'SALGUEIRO',
        Estado: 'PE',
        Latitude: -8.05806,
        Longitude: -39.09611,
        Altitude: '447',
      },
      geometry: { type: 'Point', coordinates: [-39.0961111, -8.0580556] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 57.0,
        'Esta��': 'PE-INMET-SERRA TALHADA',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'SERRA TALHADA',
        Estado: 'PE',
        Latitude: -7.95428,
        Longitude: -38.29508,
        Altitude: '499',
      },
      geometry: { type: 'Point', coordinates: [-38.295082, -7.954277] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 58.0,
        'Esta��': 'PE-INMET-SURUBIM',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'SURUBIM',
        Estado: 'PE',
        Latitude: -7.83972,
        Longitude: -35.80111,
        Altitude: '421',
      },
      geometry: { type: 'Point', coordinates: [-35.8011111, -7.8397222] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 59.0,
        'Esta��': 'PI-INMET-PAULISTANA',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'PAULISTANA',
        Estado: 'PI',
        Latitude: -8.13229,
        Longitude: -41.14294,
        Altitude: '376',
      },
      geometry: { type: 'Point', coordinates: [-41.142945, -8.132288] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 60.0,
        'Esta��': 'PI-INMET-PICOS',
        'Institui��': 'INMET',
        'Endere�o': null,
        Cidade: 'PICOS',
        Estado: 'PI',
        Latitude: -7.07111,
        Longitude: -41.40417,
        Altitude: '232',
      },
      geometry: { type: 'Point', coordinates: [-41.4041667, -7.0711111] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 61.0,
        'Esta��': 'PE_IFPE_BET�NIA',
        'Institui��': 'IFPE',
        'Endere�o': null,
        Cidade: 'BET�NIA',
        Estado: 'PE',
        Latitude: -8.27391,
        Longitude: -38.0352,
        Altitude: '432',
      },
      geometry: { type: 'Point', coordinates: [-38.0352, -8.273912] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 62.0,
        'Esta��': 'PE_IFPE_BONITO',
        'Institui��': 'IFPE',
        'Endere�o': null,
        Cidade: 'BONITO',
        Estado: 'PE',
        Latitude: -8.47306,
        Longitude: -35.73964,
        Altitude: '427',
      },
      geometry: { type: 'Point', coordinates: [-35.739636, -8.473063] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 63.0,
        'Esta��': 'PE-IFPE-PASSIRA',
        'Institui��': 'IFPE',
        'Endere�o': null,
        Cidade: 'PASSIRA',
        Estado: 'PE',
        Latitude: -7.97357,
        Longitude: -35.5804,
        Altitude: '176',
      },
      geometry: { type: 'Point', coordinates: [-35.580395, -7.973572] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 64.0,
        'Esta��': 'PE-IFPE-PETROLANDIA',
        'Institui��': 'IFPE',
        'Endere�o': null,
        Cidade: 'PETROLANDIA',
        Estado: 'PE',
        Latitude: -8.97761,
        Longitude: -38.21876,
        Altitude: '313',
      },
      geometry: { type: 'Point', coordinates: [-38.218757, -8.977605] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 65.0,
        'Esta��': 'PE-IFPE-QUIPAPA',
        'Institui��': 'IFPE',
        'Endere�o': null,
        Cidade: 'QUIPAPA',
        Estado: 'PE',
        Latitude: -8.81452,
        Longitude: -36.01022,
        Altitude: '537',
      },
      geometry: { type: 'Point', coordinates: [-36.010219, -8.814522] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 66.0,
        'Esta��': 'PE-INPE-PETROLINA',
        'Institui��': 'IFPE',
        'Endere�o': null,
        Cidade: 'PETROLINA',
        Estado: 'PE',
        Latitude: -9.06889,
        Longitude: -40.31972,
        Altitude: '387',
      },
      geometry: { type: 'Point', coordinates: [-40.319722, -9.068888] },
    },
    {
      type: 'Feature',
      properties: {
        fid: 67.0,
        'Esta��': 'PE_IFPE_CEDRO',
        'Institui��': 'IFPE',
        'Endere�o': null,
        Cidade: 'CEDRO',
        Estado: 'PE',
        Latitude: -7.72014,
        Longitude: -39.23636,
        Altitude: '537',
      },
      geometry: { type: 'Point', coordinates: [-39.236362, -7.72014] },
    },
  ],
};
