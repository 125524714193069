export var jsonLinhasDeTranmissaoExpansaoPlanejada17 = {
  type: 'FeatureCollection',
  name: 'Linhas_de_Transmisso__Expanso_Planejada_17',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Suape II - Recife II, C2',
        Ano_Planej: 0.0,
        Tensao: 500.0,
        Extensao: 45.15464,
        Concession: null,
        Shape_Leng: 0.39754,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.035698469999943, -8.117068099999926],
            [-35.03603159499994, -8.118281624999952],
            [-35.041685193999967, -8.125739172999943],
            [-35.068380723999951, -8.145886068999971],
            [-35.073888778999958, -8.149730484999964],
            [-35.08523650799998, -8.160967648999929],
            [-35.089774853999984, -8.183232855999961],
            [-35.091087602999949, -8.19478018999996],
            [-35.093954654999948, -8.197670045999928],
            [-35.127590417999954, -8.207214806999957],
            [-35.142481382999961, -8.216419454999937],
            [-35.15569790099994, -8.229347264999944],
            [-35.157470109999963, -8.245550167999966],
            [-35.161783278999962, -8.251472646999957],
            [-35.166541248999977, -8.264136042999951],
            [-35.157092766999938, -8.29699033199995],
            [-35.138114185999939, -8.323472066999955],
            [-35.126673736999976, -8.333518151999954],
            [-35.12647128499998, -8.339780297999937],
            [-35.101212353999983, -8.364484718999961],
            [-35.09095687699994, -8.375838911999949],
            [-35.090215516999933, -8.379594890999954],
            [-35.08005254099993, -8.383086154999944],
            [-35.058629757999938, -8.389733040999943],
            [-35.05026337299995, -8.389567659999955],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Lagoa do Barro 01 - São João do Piauí C1',
        Ano_Planej: 0.0,
        Tensao: 230.0,
        Extensao: 86.52647,
        Concession: null,
        Shape_Leng: 0.77931,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-42.22789064799997, -8.355847578999942],
            [-42.227643608999983, -8.354971291999959],
            [-42.226198085999954, -8.354477552999981],
            [-42.225228455999968, -8.355054572999961],
            [-42.222080131999974, -8.363190841999938],
            [-42.217538850999972, -8.370219769999949],
            [-42.149732391999976, -8.454876282999976],
            [-42.131218994999983, -8.460516795999979],
            [-42.117143689999978, -8.477852754999958],
            [-42.116463282999973, -8.49641790499993],
            [-42.078173710999977, -8.544128239999964],
            [-42.03264375599997, -8.566012142999966],
            [-42.016917500999966, -8.572955409999963],
            [-42.00082996499998, -8.581295420999936],
            [-41.867504716999974, -8.645356087999971],
            [-41.859186715999954, -8.648462474999974],
            [-41.854746641999952, -8.651460594999946],
            [-41.786647776999985, -8.684161646999939],
            [-41.658516180999982, -8.715374226999927],
            [-41.64849329599997, -8.717013476999966],
            [-41.644590979999975, -8.716739838999956],
            [-41.626031183999942, -8.691701960999978],
            [-41.619647536999935, -8.689371476999952],
            [-41.615133579999963, -8.686715700999969],
            [-41.605746050999983, -8.673665467999967],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 500 kV Milagres - Luiz Gonzaga, C1, na SE Milagres II',
        Ano_Planej: 2024.0,
        Tensao: 500.0,
        Extensao: 0.43445,
        Concession: null,
        Shape_Leng: 0.00382,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.925872633999973, -7.342972575999966],
            [-38.927569189999929, -7.339546151999969],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 500 kV Milagres - Luiz Gonzaga, C1, na SE Milagres II',
        Ano_Planej: 2024.0,
        Tensao: 500.0,
        Extensao: 0.53878,
        Concession: null,
        Shape_Leng: 0.00474,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.927833309999983, -7.338810898999952],
            [-38.925620410999954, -7.343008267999949],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 500 kV Sobradinho - Luiz Gonzaga, C2, na SE Juazeiro III',
        Ano_Planej: 2023.0,
        Tensao: 500.0,
        Extensao: 0.21981,
        Concession: null,
        Shape_Leng: 0.00194,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.520664493999959, -9.484457733999932],
            [-40.520219904999976, -9.486344155999973],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 500 kV Sobradinho - Luiz Gonzaga, C2, na SE Juazeiro III',
        Ano_Planej: 2023.0,
        Tensao: 500.0,
        Extensao: 0.23107,
        Concession: null,
        Shape_Leng: 0.00205,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.521742337999967, -9.484608929999979],
            [-40.520805251999946, -9.486429815999941],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Coletora UFSJP - São João do Piauí, C1',
        Ano_Planej: 0.0,
        Tensao: 500.0,
        Extensao: 17.10732,
        Concession: null,
        Shape_Leng: 0.15274,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-42.154486050999935, -8.262737925999943],
            [-42.155059713999947, -8.263417845999982],
            [-42.155154891999985, -8.268664557999955],
            [-42.196561082999949, -8.309655531999965],
            [-42.214183846999958, -8.333380661999968],
            [-42.213459300999943, -8.368255826999928],
            [-42.217914841999971, -8.373209864999978],
            [-42.219256857999937, -8.373409739999943],
            [-42.224172823999936, -8.366601625999976],
            [-42.227827675999947, -8.360905195999976],
            [-42.228522475999966, -8.360747176999951],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV São João do Piauí - Elevadora Lagoa dos Ventos, C1',
        Ano_Planej: 0.0,
        Tensao: 500.0,
        Extensao: 96.38962,
        Concession: null,
        Shape_Leng: 0.86866,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-42.228275039999971, -8.35990593799994],
            [-42.226124006999953, -8.360438936999969],
            [-42.224563080999985, -8.363998610999943],
            [-42.219632837999939, -8.37161288599998],
            [-42.148039439999934, -8.459845987999927],
            [-42.139639941999974, -8.471410167999977],
            [-42.131021533999956, -8.481075141999952],
            [-42.079349634999971, -8.545514656999956],
            [-41.854962730999944, -8.653376642999945],
            [-41.823996424999962, -8.678335998999955],
            [-41.771850536999978, -8.693271875999926],
            [-41.612563795999961, -8.769723155999941],
            [-41.599238814999978, -8.762465799999973],
            [-41.551713363999966, -8.750341335999963],
            [-41.537455633999969, -8.749313408999967],
            [-41.520931069999961, -8.742079847999946],
            [-41.512626750999971, -8.731205710999973],
            [-41.512542676999942, -8.731118463999962],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Messias - Rio Largo II, C4',
        Ano_Planej: 2023.0,
        Tensao: 230.0,
        Extensao: 14.36511,
        Concession: null,
        Shape_Leng: 0.12599,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.83926196699997, -9.503742448999958],
            [-35.841397486999938, -9.503689712999972],
            [-35.847520850999956, -9.49931900699994],
            [-35.848207247999937, -9.497923770999932],
            [-35.851316561999965, -9.495719722999979],
            [-35.86889761499998, -9.486921406999954],
            [-35.872434969999972, -9.476023685999962],
            [-35.869637755999975, -9.45502122299996],
            [-35.847807534999959, -9.423851126999978],
            [-35.845838473999947, -9.420410911999966],
            [-35.845127653999953, -9.415172285999972],
            [-35.842802692999953, -9.404054979999955],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 230 kV Milagres - Bom Nome, C1, na SE Mauriti',
        Ano_Planej: 0.0,
        Tensao: 230.0,
        Extensao: 15.34963,
        Concession: null,
        Shape_Leng: 0.1378,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.896436675999951, -7.416151508999974],
            [-38.896259546999943, -7.416096107999977],
            [-38.896000613999945, -7.416031425999961],
            [-38.895716505999985, -7.415966738999941],
            [-38.895551076999936, -7.415923618999955],
            [-38.895486347999963, -7.415887703999942],
            [-38.895216626999968, -7.415815838999947],
            [-38.894961291999948, -7.415747567999972],
            [-38.894777884999939, -7.415686493999942],
            [-38.892779522999945, -7.415303076999976],
            [-38.883300132999977, -7.413431948999971],
            [-38.87542112999995, -7.411889530999929],
            [-38.869107059999976, -7.410654176999969],
            [-38.86903033699997, -7.410649366999962],
            [-38.867994616999965, -7.410448017999954],
            [-38.866647852999961, -7.409428042999934],
            [-38.864742148999937, -7.40807521499994],
            [-38.862476885999968, -7.406494898999938],
            [-38.860822911999946, -7.40528573499995],
            [-38.854832043999977, -7.405478949999974],
            [-38.853858671999944, -7.405116799999973],
            [-38.852454401999978, -7.405116327999963],
            [-38.851749680999944, -7.405477915999938],
            [-38.850824044999968, -7.405477606999966],
            [-38.846836863999954, -7.405316246999973],
            [-38.844434472999978, -7.405286693999926],
            [-38.842916969999976, -7.404776373999937],
            [-38.841874150999956, -7.404388279999978],
            [-38.840860084999974, -7.404057633999969],
            [-38.840342280999948, -7.403842045999966],
            [-38.84003299099993, -7.403841934999946],
            [-38.838076549999982, -7.403848407999931],
            [-38.833063185999947, -7.403817836999963],
            [-38.831768477999958, -7.403835299999969],
            [-38.830801057999963, -7.403809803999934],
            [-38.830484629999944, -7.403666080999926],
            [-38.828766529999939, -7.402691923999953],
            [-38.824919239999929, -7.400559137999949],
            [-38.82420966899997, -7.400276441999949],
            [-38.818973599999936, -7.398224728999935],
            [-38.818638370999963, -7.397171512999932],
            [-38.818264856999974, -7.395938778999948],
            [-38.817149994999966, -7.395938322999939],
            [-38.816574572999968, -7.395962020999946],
            [-38.816226915999948, -7.395991794999929],
            [-38.815429734999952, -7.395979497999974],
            [-38.814866304999953, -7.395991231999972],
            [-38.814500683999938, -7.395979112999953],
            [-38.813985212999967, -7.395972914999959],
            [-38.813781430999938, -7.395948895999936],
            [-38.81281073599996, -7.39520654699993],
            [-38.809896956999978, -7.392776948999938],
            [-38.804915558999937, -7.391237461999935],
            [-38.802536103999955, -7.391038962999971],
            [-38.79795061599998, -7.390654171999927],
            [-38.797720713999979, -7.390083250999965],
            [-38.79736866199994, -7.38924302199996],
            [-38.796948348999933, -7.388255568999966],
            [-38.796571143999984, -7.387375836999979],
            [-38.796384347999947, -7.386919816999978],
            [-38.796132892999935, -7.386302214999944],
            [-38.79521086799997, -7.384104686999933],
            [-38.794024212999943, -7.381298722999929],
            [-38.79308225699998, -7.380245208999952],
            [-38.791644504999965, -7.37869483999998],
            [-38.790739925999958, -7.377713138999979],
            [-38.787181586999964, -7.373792329999958],
            [-38.783733529999949, -7.369994804999976],
            [-38.783963594999932, -7.368643542999962],
            [-38.783920541999976, -7.365018299999974],
            [-38.782045421999953, -7.365026607999937],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Serrote - Arapiraca III, C1',
        Ano_Planej: 0.0,
        Tensao: 230.0,
        Extensao: 21.64008,
        Concession: null,
        Shape_Leng: 0.19142,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.649188039999956, -9.787611427999934],
            [-36.649184859999934, -9.788063336999926],
            [-36.647587100999942, -9.789013520999958],
            [-36.647513767999953, -9.794974984999953],
            [-36.653277014999958, -9.79898792299997],
            [-36.656524702999945, -9.799282968999933],
            [-36.661894179999933, -9.791882118999979],
            [-36.689827973999968, -9.781646793999926],
            [-36.712060158999975, -9.764897623999957],
            [-36.711702388999981, -9.751361814999939],
            [-36.725529497999958, -9.727436591999947],
            [-36.740208396999947, -9.71024429199997],
            [-36.741028787999937, -9.707595338999965],
            [-36.735443715999963, -9.698006722999935],
            [-36.735199578999982, -9.684103586999981],
            [-36.743709193999962, -9.677137508999976],
            [-36.74395298099995, -9.674915171999942],
            [-36.74319520499995, -9.673804201999928],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 230 kV Milagres - Tauá II, C1 (CD), na SE Crato II',
        Ano_Planej: 2023.0,
        Tensao: 230.0,
        Extensao: 30.36698,
        Concession: null,
        Shape_Leng: 0.27182,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-39.380676105999953, -7.190293634999932],
            [-39.38031611599996, -7.189659697999957],
            [-39.366877345999967, -7.162400667999975],
            [-39.34620265999996, -7.13987065799995],
            [-39.28865697599997, -7.117453215999944],
            [-39.266914351999958, -7.105771312999934],
            [-39.237114462999955, -7.084692798999981],
            [-39.222871087999977, -7.074000590999958],
            [-39.171930560999954, -7.050163101999942],
            [-39.163978739999948, -7.041112518999967],
            [-39.163202275999936, -7.040366047999953],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Bom Lugar Norte - Icó, C1',
        Ano_Planej: 0.0,
        Tensao: 230.0,
        Extensao: 9.33805,
        Concession: null,
        Shape_Leng: 0.08389,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.88098255999995, -6.409072936999962],
            [-38.880194481999979, -6.409200475999967],
            [-38.88003458299994, -6.408745522999936],
            [-38.882067593999977, -6.407037307999929],
            [-38.885171648999972, -6.407887441999947],
            [-38.887455930999977, -6.407030835999933],
            [-38.892312410999978, -6.402798725999958],
            [-38.904168788999982, -6.390582335999966],
            [-38.954373929999974, -6.38264980799994],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 500 kV Milagres - Curral Novo do Piauí II, C1, na SE Milagres II',
        Ano_Planej: 2023.0,
        Tensao: 500.0,
        Extensao: 2.89653,
        Concession: null,
        Shape_Leng: 0.02544,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.925213579999934, -7.343903385999965],
            [-38.925520529999972, -7.343053918999942],
            [-38.926726916999939, -7.337607331999948],
            [-38.928218838999953, -7.332303513999932],
            [-38.932823095999936, -7.320989176999944],
            [-38.93398665199993, -7.320568011999967],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 500 kV Milagres - Curral Novo do Piauí II, C1, na SE Milagres II',
        Ano_Planej: 2023.0,
        Tensao: 500.0,
        Extensao: 2.96888,
        Concession: null,
        Shape_Leng: 0.0261,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.933900991999963, -7.319989802999942],
            [-38.932401930999958, -7.320489489999943],
            [-38.92769773699996, -7.332103638999968],
            [-38.926591287999941, -7.337593055999946],
            [-38.925911249999956, -7.339700515999937],
            [-38.924642508999966, -7.342861181999979],
            [-38.924328419999938, -7.343560743999944],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV SE Elevadora UFV Graviola - São João do Piauí, C1',
        Ano_Planej: 0.0,
        Tensao: 230.0,
        Extensao: 16.52785,
        Concession: null,
        Shape_Leng: 0.14921,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-42.227198536999936, -8.354493802999968],
            [-42.223298599999964, -8.343215156999975],
            [-42.222984510999936, -8.340940392999926],
            [-42.225059400999953, -8.337751914999956],
            [-42.227800539999976, -8.335643712999968],
            [-42.248623202999966, -8.325912906999974],
            [-42.249974736999945, -8.324666068999932],
            [-42.270015509999951, -8.311340928999925],
            [-42.345246927999938, -8.294582384999956],
            [-42.346912550999946, -8.294049385999926],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Canudos I - Jeremoabo, C1',
        Ano_Planej: 0.0,
        Tensao: 500.0,
        Extensao: 30.33926,
        Concession: null,
        Shape_Leng: 0.27566,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.30156380699998, -10.171606231999931],
            [-38.303234187999976, -10.178030775999957],
            [-38.318082024999967, -10.185697399999981],
            [-38.413093902999947, -10.172288660999982],
            [-38.491288389999966, -10.151099872999964],
            [-38.494464969999967, -10.150278958999934],
            [-38.503709175999973, -10.147732935999954],
            [-38.509657714999946, -10.139111434999961],
            [-38.561506169999973, -10.143113687999971],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Messias - Rio Largo II, C4',
        Ano_Planej: 2023.0,
        Tensao: 230.0,
        Extensao: 15.24466,
        Concession: null,
        Shape_Leng: 0.13393,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.837754504999964, -9.504100207999954],
            [-35.837784247999934, -9.504944915999943],
            [-35.838843108999981, -9.505617113999961],
            [-35.839973352999948, -9.505545729999938],
            [-35.846415742999966, -9.500245480999979],
            [-35.846820251999929, -9.498276476999933],
            [-35.851257945999976, -9.495676915999979],
            [-35.86889761499998, -9.486921406999954],
            [-35.872434969999972, -9.476023685999962],
            [-35.869578843999932, -9.455092352999941],
            [-35.853383536999957, -9.43181277399998],
            [-35.84189078199995, -9.420897237999952],
            [-35.845406633999971, -9.405726983999955],
            [-35.843346019999956, -9.403357040999936],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Queimada Nova II - Lagoa dos Ventos III, C1',
        Ano_Planej: 2023.0,
        Tensao: 500.0,
        Extensao: 24.12979,
        Concession: null,
        Shape_Leng: 0.21501,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-41.426830367999969, -8.589673928999957],
            [-41.426709014999972, -8.590509119999979],
            [-41.425459797999963, -8.591572738999957],
            [-41.426153327999941, -8.602276442999937],
            [-41.421363472999985, -8.611350913999956],
            [-41.435675930999935, -8.632809686999963],
            [-41.475679526999954, -8.702569689999962],
            [-41.472348280999938, -8.716584714999954],
            [-41.46948103099993, -8.72578133199994],
            [-41.472598124999934, -8.731289783999955],
            [-41.47445410399996, -8.734406877999959],
            [-41.487575443999958, -8.754812738999931],
            [-41.513310206999961, -8.763227107999967],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Taboleiro do Meio - SE Coremas, C1',
        Ano_Planej: 0.0,
        Tensao: 230.0,
        Extensao: 6.3944,
        Concession: null,
        Shape_Leng: 0.05649,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-37.955996601999971, -7.016601852999941],
            [-37.956611488999954, -7.016970847999971],
            [-37.959426872999984, -7.017580140999939],
            [-37.961970552999958, -7.01758577399994],
            [-37.96514643699993, -7.01765021999995],
            [-37.971245616999965, -7.018281031999948],
            [-37.971938802999944, -7.016746220999948],
            [-37.974667324999984, -7.007378913999958],
            [-37.979909645999953, -6.992673302999947],
            [-37.982088460999933, -6.988572981999937],
            [-37.982734813999969, -6.984817970999927],
            [-37.985796893999975, -6.981495152999969],
            [-37.986731813999938, -6.981095128999925],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Bom Nome - Serra Talhada, C1',
        Ano_Planej: 0.0,
        Tensao: 230.0,
        Extensao: 5.89921,
        Concession: null,
        Shape_Leng: 0.05327,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.62872471299994, -7.996706151999945],
            [-38.629035232999968, -7.996923872999957],
            [-38.630580691999967, -7.996674029999951],
            [-38.630646365999951, -8.000976332999926],
            [-38.628143172999955, -8.001823420999926],
            [-38.625963585999955, -8.00280375899996],
            [-38.623612678999962, -8.003803132999963],
            [-38.617644990999963, -8.004060114999959],
            [-38.612686193999934, -8.004259988999934],
            [-38.607603664999942, -8.004336131999935],
            [-38.603520509999953, -8.004393238999967],
            [-38.598970980999979, -8.004421792999949],
            [-38.59442145099996, -8.00451697099993],
            [-38.589890956999966, -8.004545524999969],
            [-38.585530965999965, -8.004689271999951],
            [-38.584452237999983, -8.003887589999977],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 500 kV Angelim II - Recife II, C2 (CD), na SE Suape II',
        Ano_Planej: 2023.0,
        Tensao: 500.0,
        Extensao: 25.4435,
        Concession: null,
        Shape_Leng: 0.2233,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.156428477999953, -8.228815213999951],
            [-35.155791257999965, -8.229478380999979],
            [-35.155856578999931, -8.230797485999972],
            [-35.157470109999963, -8.245550167999966],
            [-35.16196817499997, -8.251334292999957],
            [-35.166541248999977, -8.264136042999951],
            [-35.157092766999938, -8.29699033199995],
            [-35.138114185999939, -8.323472066999955],
            [-35.126673736999976, -8.333518151999954],
            [-35.12647128499998, -8.339780297999937],
            [-35.101212353999983, -8.364484718999961],
            [-35.090215516999933, -8.379594890999954],
            [-35.08005254099993, -8.383086154999944],
            [-35.058629757999938, -8.389733040999943],
            [-35.048792815999946, -8.389381998999966],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Chateau Fort - São João do Piauí, C1',
        Ano_Planej: 0.0,
        Tensao: 500.0,
        Extensao: 8.21844,
        Concession: null,
        Shape_Leng: 0.07408,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-42.228439694999963, -8.360326517999965],
            [-42.227686812999934, -8.360554062999938],
            [-42.227308573999949, -8.360774183999979],
            [-42.227282830999968, -8.360909419999928],
            [-42.227386155999966, -8.362647435999975],
            [-42.22735857999993, -8.362763861999952],
            [-42.219592808999948, -8.374149356999965],
            [-42.216206788999955, -8.377016215999959],
            [-42.216061645999957, -8.377047139999945],
            [-42.187591104999967, -8.37085708099994],
            [-42.187409943999967, -8.370886939999934],
            [-42.167654548999963, -8.383001775999958],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 230 kV Milagres - Bom Nome, C2, na SE Elevadora Abaiara',
        Ano_Planej: 0.0,
        Tensao: 230.0,
        Extensao: 6.93989,
        Concession: null,
        Shape_Leng: 0.06268,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.914709144999961, -7.377781505999963],
            [-38.914709481999978, -7.377781729999981],
            [-38.915959716999964, -7.378136008999945],
            [-38.923278756999935, -7.380583353999953],
            [-38.955534026999942, -7.391263278999929],
            [-38.967912729999966, -7.391454387999943],
            [-38.968818639999938, -7.391468360999966],
            [-38.970178444999931, -7.391489332999981],
            [-38.970828790999974, -7.391507738999962],
            [-38.97147752199993, -7.391548771999965],
          ],
          [
            [-38.914709144999961, -7.377781505999963],
            [-38.913447298999984, -7.377786715999946],
            [-38.91278727599996, -7.377876047999962],
          ],
          [
            [-38.914711737999937, -7.377781494999965],
            [-38.914709144999961, -7.377781505999963],
          ],
          [
            [-38.913336055999935, -7.376632851999943],
            [-38.913720721999937, -7.377123401999938],
            [-38.914709144999961, -7.377781505999963],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Abaiara -  Milagres C1',
        Ano_Planej: 0.0,
        Tensao: 230.0,
        Extensao: 11.36716,
        Concession: null,
        Shape_Leng: 0.10041,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.970765452999956, -7.391363885999965],
            [-38.974414803999935, -7.383133580999981],
            [-38.976029490999963, -7.380030925999961],
            [-38.97579075699997, -7.379065888999946],
            [-38.971159729999954, -7.372705698999937],
            [-38.964831118999939, -7.365135523999982],
            [-38.960411328999953, -7.35965939099998],
            [-38.95532714999996, -7.353407713999957],
            [-38.955016317999934, -7.352445874999944],
            [-38.953560306999975, -7.350637954999968],
            [-38.947528104999947, -7.342923084999939],
            [-38.942896680999979, -7.336843973999976],
            [-38.940852972999949, -7.333946969999943],
            [-38.936233871999946, -7.325156918999937],
            [-38.934851805999983, -7.321821227999976],
            [-38.937981504999982, -7.314451798999926],
            [-38.938898587999972, -7.312358511999946],
            [-38.939415870999937, -7.311582943999952],
            [-38.940814271999955, -7.309494897999969],
            [-38.941667220999932, -7.308470330999967],
            [-38.941589421999936, -7.307308347999935],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 230 kV Milagres - Crato II C1 na SE Abaiara',
        Ano_Planej: 0.0,
        Tensao: 230.0,
        Extensao: 14.39715,
        Concession: null,
        Shape_Leng: 0.12668,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.970833846999938, -7.39132243499995],
            [-38.971743387999936, -7.389706301999979],
            [-38.976200805999952, -7.378893845999926],
            [-38.976621469999941, -7.377667591999966],
            [-38.978946502999975, -7.370840706999957],
            [-38.980618827999933, -7.365757127999927],
            [-38.980435459999967, -7.361919523999973],
            [-38.978796544999966, -7.330420978999939],
            [-38.978674871999942, -7.321851219999928],
            [-38.97609936899994, -7.303877911999962],
            [-38.975275909999937, -7.301776031999964],
            [-38.973026781999977, -7.298096879999946],
            [-38.949930348999942, -7.278034900999955],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 230 kV Milagres - Banabuiú C2 na SE Icó',
        Ano_Planej: 0.0,
        Tensao: 230.0,
        Extensao: 1.64446,
        Concession: null,
        Shape_Leng: 0.01484,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.867011715999979, -6.412412309999979],
            [-38.881508020999945, -6.409236542999963],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 230 kV Milagres - Banabuiú C2 na SE Gameleira',
        Ano_Planej: 0.0,
        Tensao: 230.0,
        Extensao: 0.20592,
        Concession: null,
        Shape_Leng: 0.00186,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.927757027999974, -7.268635592999942],
            [-38.929545, -7.268125027999929],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 230 kV Floresta II - Paulo Afonso III C1 na SE Tacaratu',
        Ano_Planej: 2029.0,
        Tensao: 230.0,
        Extensao: 6.70561,
        Concession: null,
        Shape_Leng: 0.06026,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.183176505999938, -9.027871996999977],
            [-38.131658131999984, -9.059123990999979],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 230 kV Bom Nome - Paulo Afonso III C1 na SE Tacaratu',
        Ano_Planej: 2029.0,
        Tensao: 230.0,
        Extensao: 6.70561,
        Concession: null,
        Shape_Leng: 0.06026,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.183176505999938, -9.027871996999977],
            [-38.131658131999984, -9.059123990999979],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 230 kV Bom Nome - Paulo Afonso III C1 na SE Floresta II',
        Ano_Planej: 2029.0,
        Tensao: 230.0,
        Extensao: 0.32434,
        Concession: null,
        Shape_Leng: 0.00294,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.342367574999969, -8.749016270999959],
            [-38.339585973999931, -8.74807902799995],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Santa Luzia II - Bom Nome II C1',
        Ano_Planej: 2030.0,
        Tensao: 500.0,
        Extensao: 226.88864,
        Concession: null,
        Shape_Leng: 2.02846,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.64636775699995, -8.043815110999958],
            [-38.581913708999934, -8.020463356999926],
            [-38.439532111999938, -7.897977688999958],
            [-38.28958700499993, -7.805899645999943],
            [-38.102217965999955, -7.720078672999932],
            [-37.893660077999982, -7.59174408399997],
            [-37.80222985499995, -7.488734057999977],
            [-37.725291846999937, -7.426319865999972],
            [-37.534522960999936, -7.307351908999976],
            [-37.461768359999951, -7.237662365999938],
            [-37.408336041999974, -7.204821179999954],
            [-37.280751385999963, -7.125259017999952],
            [-37.249285688999976, -7.07603166499996],
            [-37.160634366999943, -7.029374873999927],
            [-37.038679740999953, -6.949020988999962],
            [-36.984681647999935, -6.912729858999967],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Recife II - Bongi, C1 e C2 (CD)',
        Ano_Planej: 2028.0,
        Tensao: 230.0,
        Extensao: 19.6203,
        Concession: null,
        Shape_Leng: 0.17373,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.033635875999948, -8.113288298999976],
            [-35.033378121999931, -8.113149786999941],
            [-35.033665062999944, -8.110004970999967],
            [-35.03180459999993, -8.105378644999973],
            [-35.033176268999966, -8.097668786999975],
            [-35.033799683999973, -8.089120430999969],
            [-35.033929858999954, -8.087525228999937],
            [-35.032853113999977, -8.084414451999976],
            [-35.031744345999982, -8.083710392999933],
            [-35.031432431999974, -8.082894230999955],
            [-35.031751246999931, -8.081746821999957],
            [-35.031729004999931, -8.081284899999957],
            [-35.030214299999955, -8.077324048999969],
            [-35.029806367999981, -8.077026142999955],
            [-35.029705035999939, -8.076292467999963],
            [-35.030007240999964, -8.07520947699993],
            [-35.030904366999948, -8.073664372999929],
            [-35.032978777999972, -8.071379577999949],
            [-35.032980389999977, -8.070311152999977],
            [-35.03160337199995, -8.066942283999936],
            [-35.027214962999949, -8.059380265999948],
            [-35.024174019999975, -8.057048843999951],
            [-35.021743142999981, -8.056771840999943],
            [-35.019640289999984, -8.054267705999962],
            [-35.01594844899995, -8.05346302199996],
            [-35.008751443999984, -8.05312259599998],
            [-35.000775085999976, -8.054116806999957],
            [-34.993966803999967, -8.057339218999971],
            [-34.991178278999939, -8.058345361999955],
            [-34.973489705999953, -8.055585848999954],
            [-34.973426454999981, -8.055508604999943],
            [-34.973339492999969, -8.055526934999932],
            [-34.973078605999945, -8.055610126999966],
            [-34.97263914499996, -8.055955552999933],
            [-34.972282815999961, -8.056094304999931],
            [-34.972074371999952, -8.056141135999951],
            [-34.970741160999978, -8.055846484999961],
            [-34.969714417999967, -8.055628869999964],
            [-34.96716362199993, -8.05355932599997],
            [-34.962392276999935, -8.04967682399996],
            [-34.962207659999933, -8.049540935999971],
            [-34.962105118999943, -8.04946444199993],
            [-34.961993588999974, -8.049480756999969],
            [-34.960947110999939, -8.050090677999947],
            [-34.959816069999931, -8.050779161999969],
            [-34.959755947999952, -8.050849458999949],
            [-34.95973842099994, -8.050894219999975],
            [-34.959803395999984, -8.051159100999939],
            [-34.95979161899993, -8.052160383999933],
            [-34.959790877999978, -8.05258884899996],
            [-34.959658367999964, -8.053545524999947],
            [-34.959530971999982, -8.053911963999951],
            [-34.959176904999936, -8.055311945999961],
            [-34.959073695999962, -8.055715128999964],
            [-34.958279177999941, -8.055921747999946],
            [-34.95276899299995, -8.057594878999964],
            [-34.947206398999981, -8.059307769999975],
            [-34.94613604999995, -8.059595324999975],
            [-34.945763557999953, -8.060945825999966],
            [-34.945314792999966, -8.061098849999951],
            [-34.944474118999949, -8.062685643999941],
            [-34.943674166999983, -8.063404025999944],
            [-34.943365175999929, -8.063587358999939],
            [-34.943182847999935, -8.063662229999977],
            [-34.941195157999971, -8.064367201999971],
            [-34.939049092999937, -8.06501071699995],
            [-34.938645350999934, -8.065060512999935],
            [-34.937832390999972, -8.065025683999977],
            [-34.931471482999939, -8.062352932999943],
            [-34.929405603999953, -8.061520773999973],
            [-34.928634317999979, -8.061192511999934],
            [-34.928386174999957, -8.061074245999976],
            [-34.928273073999947, -8.061113519999935],
            [-34.928258661999962, -8.061143006999941],
            [-34.928279782999937, -8.061203810999928],
            [-34.92864439899995, -8.062224131999926],
            [-34.928030429999978, -8.062417499999981],
            [-34.928077937999944, -8.062569925999981],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Xingó – Camaçari II, C1 e C2 (CD)',
        Ano_Planej: 2028.0,
        Tensao: 500.0,
        Extensao: 366.84342,
        Concession: null,
        Shape_Leng: 3.21853,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.046722826940318, -10.310613660371555],
            [-38.028369554999983, -10.281458549999968],
            [-38.017869876999953, -10.258869440999945],
            [-38.009342003999961, -10.231087482999953],
            [-37.999577040999952, -10.053409665999936],
            [-37.998846292999929, -9.966133272999969],
            [-37.904525781999951, -9.758798377999938],
            [-37.837875059999931, -9.669090548999975],
            [-37.816539813999952, -9.654423412999961],
            [-37.800289158999931, -9.639282384999944],
            [-37.798027880999939, -9.635196896999958],
            [-37.797324748999984, -9.634644933999937],
            [-37.797328968999977, -9.634286911999936],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 230 kV Pirapama II - Recife II, C2, na SE Jaboatão II',
        Ano_Planej: 2023.0,
        Tensao: 230.0,
        Extensao: 11.48184,
        Concession: null,
        Shape_Leng: 0.10218,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.068738774999929, -8.142850112999952],
            [-35.060221345999935, -8.148077745999956],
            [-35.051645153999971, -8.158775124999977],
            [-35.027364330999944, -8.183850087999929],
            [-35.018487409999977, -8.186711644999946],
            [-34.996032812999943, -8.185800076999953],
            [-34.986517557999946, -8.178878285999929],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Juazeiro III - Campo Formoso II, C1',
        Ano_Planej: 2028.0,
        Tensao: 500.0,
        Extensao: 102.80825,
        Concession: null,
        Shape_Leng: 0.91426,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.519029799999942, -9.485584388999939],
            [-40.51547777299993, -9.484594589999972],
            [-40.51170564399996, -9.48393680199996],
            [-40.510984254999983, -9.484890180999969],
            [-40.511936405999961, -9.490536766999981],
            [-40.512131158999978, -9.49169171799997],
            [-40.52398453099994, -9.498541981999949],
            [-40.540983215999972, -9.594249790999982],
            [-40.568194597999934, -9.831046206999929],
            [-40.611178694999978, -9.953274570999952],
            [-40.612111962999961, -9.954280538999967],
            [-40.733833739999966, -10.085484188999942],
            [-40.84732891699997, -10.191472555999951],
            [-40.908511483999973, -10.242445377999957],
            [-40.913010955999937, -10.243201878999969],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Coletora Zebu - Zebu II, C1',
        Ano_Planej: 0.0,
        Tensao: 230.0,
        Extensao: 2.44579,
        Concession: null,
        Shape_Leng: 0.02199,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.18240031299996, -9.360411579999948],
            [-38.18539094199997, -9.360417535999943],
            [-38.191148127999952, -9.358312316999957],
            [-38.194226971999967, -9.355462074999934],
            [-38.198087923999935, -9.356323050999947],
            [-38.198844056999974, -9.358691216999944],
            [-38.199740156999951, -9.359158921999949],
            [-38.200550183999951, -9.358239082999944],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Araripe IV - Curral Novo do Piauí II, C1',
        Ano_Planej: 0.0,
        Tensao: 500.0,
        Extensao: 50.82534,
        Concession: null,
        Shape_Leng: 0.44977,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.637542142999962, -8.035583587999952],
            [-40.636923778999972, -8.035474417999978],
            [-40.633614518999934, -8.03680888699995],
            [-40.632633146999979, -8.036223827999947],
            [-40.632590272999948, -8.03371034099996],
            [-40.633283690999974, -8.028441609999959],
            [-40.616695819999961, -7.993057696999927],
            [-40.595677337999973, -7.922378253999966],
            [-40.595744251999974, -7.913682007999967],
            [-40.598353381999971, -7.908440574999929],
            [-40.601345382999966, -7.886338870999964],
            [-40.60965250299995, -7.84300432699996],
            [-40.612193976999947, -7.833340129999954],
            [-40.641133062999984, -7.819370527999979],
            [-40.664900690999957, -7.808098878999942],
            [-40.668279672999972, -7.802176774999964],
            [-40.673291975999973, -7.776500969999972],
            [-40.667181407999976, -7.759645488999979],
            [-40.671843268999964, -7.736088270999971],
            [-40.664629632999947, -7.675132451999957],
            [-40.658187820999956, -7.655252031999964],
            [-40.656774560999963, -7.639227765999976],
            [-40.656796423999936, -7.638142866999942],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV UFV Nelore - Zebu II, C1',
        Ano_Planej: 0.0,
        Tensao: 230.0,
        Extensao: 7.94215,
        Concession: null,
        Shape_Leng: 0.07083,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.153490595999983, -9.38953648699993],
            [-38.154651807999983, -9.38683862399995],
            [-38.156435645999977, -9.384969350999938],
            [-38.164601102999939, -9.387263286999939],
            [-38.167892732999974, -9.38744649399996],
            [-38.169780697999954, -9.388195018999966],
            [-38.171536150999941, -9.387622662999945],
            [-38.172885016999942, -9.384223372999941],
            [-38.173498484999982, -9.382285472999968],
            [-38.175768483999946, -9.369533141999966],
            [-38.179138168999941, -9.363951662999966],
            [-38.180601984999953, -9.362576520999937],
            [-38.182654190999983, -9.361848129999942],
            [-38.18440964499996, -9.361209902999974],
            [-38.185906201999956, -9.360663495999972],
            [-38.191366717999983, -9.358664583999939],
            [-38.19429256199993, -9.355942372999948],
            [-38.19961756999993, -9.358661364999932],
            [-38.200533572999973, -9.358149612999966],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV UFV Bom Jardim - Icó, C1',
        Ano_Planej: 0.0,
        Tensao: 230.0,
        Extensao: 8.66085,
        Concession: null,
        Shape_Leng: 0.07673,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.880833394999968, -6.409565554999972],
            [-38.880193160999966, -6.409681848999981],
            [-38.879897837999977, -6.410376282999948],
            [-38.880433925999967, -6.411191511999959],
            [-38.884208608999984, -6.411433582999962],
            [-38.885767703999932, -6.410785175999933],
            [-38.88801799099997, -6.405084096999929],
            [-38.890188635999948, -6.402219221999928],
            [-38.889898438999978, -6.398476330999927],
            [-38.878509737999934, -6.391079598999966],
            [-38.876906375999965, -6.385830938999959],
            [-38.875285324999936, -6.385102776999929],
            [-38.87252940999997, -6.384424233999937],
            [-38.861232019999932, -6.364176766999947],
            [-38.861145386999965, -6.361716871999931],
            [-38.86498689299998, -6.359322477999967],
            [-38.864970667999955, -6.357779594999954],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Serra da Palmeira - Campina Grande III, C1',
        Ano_Planej: 0.0,
        Tensao: 500.0,
        Extensao: 83.58586,
        Concession: null,
        Shape_Leng: 0.73164,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.978736228999935, -7.25138143199996],
            [-35.978945652999982, -7.249973181999962],
            [-35.979742755999951, -7.248711514999968],
            [-36.052989598999943, -7.238274246999936],
            [-36.115460432999953, -7.169610845999955],
            [-36.137475905999963, -7.126208988999963],
            [-36.153049152999984, -7.089334827999949],
            [-36.173969002999968, -7.04269078699997],
            [-36.220144951999941, -6.937683976999949],
            [-36.264876409999943, -6.883210060999943],
            [-36.286122884999941, -6.847747882999954],
            [-36.323519216999955, -6.807967514999973],
            [-36.322416240999985, -6.754073132999963],
            [-36.326578127999937, -6.705006090999973],
            [-36.33581322799995, -6.668345363999947],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Bonito - Queimada Nova II, C1',
        Ano_Planej: 0.0,
        Tensao: 500.0,
        Extensao: 34.10488,
        Concession: null,
        Shape_Leng: 0.30812,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-41.699226609999982, -8.685736522999946],
            [-41.697661929999981, -8.685317282999961],
            [-41.661393433999933, -8.675855080999952],
            [-41.593344695999974, -8.609099979999939],
            [-41.58430440199993, -8.605144174999964],
            [-41.563304614999936, -8.590434264999942],
            [-41.54707112899996, -8.588373842999943],
            [-41.543999123999981, -8.588805388999958],
            [-41.508449694999968, -8.588933319999967],
            [-41.436493004999932, -8.591208870999935],
            [-41.428852199999938, -8.592567494999969],
            [-41.427568888999986, -8.590241804999948],
            [-41.426838810999982, -8.590284663999967],
            [-41.426811801999975, -8.590291433999937],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 230 kV Campina Grande III - Extremoz II, C2, na SE Elevadora Umari',
        Ano_Planej: 0.0,
        Tensao: 230.0,
        Extensao: 24.95484,
        Concession: null,
        Shape_Leng: 0.21947,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.74475555999993, -6.406054468999969],
            [-35.733229148999953, -6.406940764999945],
            [-35.725370774999931, -6.41827882299998],
            [-35.721534210999948, -6.438189653999927],
            [-35.721457455999939, -6.459065391999957],
            [-35.71994945199998, -6.49745941499998],
            [-35.713611589999971, -6.519058102999963],
            [-35.709955908999973, -6.522167862999936],
            [-35.70300087499993, -6.524678669999957],
            [-35.692441085999974, -6.525997875999963],
            [-35.666421731999947, -6.514220959999932],
            [-35.650099931999932, -6.519347483999979],
            [-35.634041185999934, -6.51630096599996],
            [-35.633286645999931, -6.516705811999941],
            [-35.632716758999948, -6.517109692999952],
            [-35.632065520999959, -6.517459942999949],
            [-35.630839894999951, -6.519078973999967],
            [-35.629245177999962, -6.519880097999931],
            [-35.628607443999954, -6.52023027499996],
            [-35.628315843999928, -6.520400503999952],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Frei Damião - Campina Grande III, C1',
        Ano_Planej: 0.0,
        Tensao: 230.0,
        Extensao: 78.23347,
        Concession: null,
        Shape_Leng: 0.67766,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.919574161999947, -6.628804785999932],
            [-35.931037424999943, -6.629512963999957],
            [-35.950574288999974, -6.63229703899998],
            [-35.953224018999947, -6.637070988999938],
            [-35.951430597999945, -6.650304520999953],
            [-35.949391395999953, -6.664675079999938],
            [-35.945898539999973, -6.688096068999926],
            [-35.939975825999966, -6.726410069999929],
            [-35.936210203999963, -6.750891539999941],
            [-35.932855463999942, -6.772945704999927],
            [-35.927325695999969, -6.80918922799998],
            [-35.924387691999982, -6.828433539999935],
            [-35.916105433999974, -6.882209493999937],
            [-35.907615920999945, -6.94076783099996],
            [-35.906184752999934, -6.95063825099993],
            [-35.901797379999948, -6.983068816999946],
            [-35.900057283999956, -6.995946407999952],
            [-35.896427148999976, -7.023429301999954],
            [-35.899224060999984, -7.033376045999944],
            [-35.908683875999941, -7.056229818999952],
            [-35.912566360999961, -7.070776395999928],
            [-35.91381673799998, -7.073038312999927],
            [-35.913867499999981, -7.07565104899993],
            [-35.921439222999936, -7.104019451999932],
            [-35.92931215699997, -7.12294792299997],
            [-35.936087622999935, -7.141372360999981],
            [-35.936603509999941, -7.143193735999944],
            [-35.939226279999957, -7.147932762999972],
            [-35.939170084999944, -7.152254677999963],
            [-35.940044435999937, -7.155341531999966],
            [-35.946778972999937, -7.16851165199995],
            [-35.949219248999952, -7.17352849699995],
            [-35.952125038999952, -7.176870353999959],
            [-35.954823825999938, -7.183824915999935],
            [-35.955747361999954, -7.187514201999932],
            [-35.957805658999973, -7.193163785999957],
            [-35.960070864999977, -7.194762004999973],
            [-35.960652606999929, -7.198402151999971],
            [-35.967219131999968, -7.207639570999959],
            [-35.972777515999951, -7.22826711099998],
            [-35.974177389999966, -7.231215795999958],
            [-35.974544798999943, -7.234824914999933],
            [-35.977706719999958, -7.241812430999971],
            [-35.976937717999931, -7.251845442999979],
            [-35.978425013999981, -7.255090805999941],
            [-35.979391377999946, -7.255183747999979],
            [-35.979435653999928, -7.254777019999949],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV UFV Sky São Mamede - SE Santa Luzia II, C1',
        Ano_Planej: 0.0,
        Tensao: 500.0,
        Extensao: 13.15018,
        Concession: null,
        Shape_Leng: 0.11769,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.985937134999972, -6.912580605999949],
            [-36.986836990999961, -6.912673537999979],
            [-36.990750871999978, -6.912483988999952],
            [-37.023750230999951, -6.916096756999934],
            [-37.057249270999932, -6.94277713799994],
            [-37.067972854999937, -6.957164844999966],
            [-37.086338602999945, -6.961627949999979],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Bom Nome II - Campo Formoso II, C1',
        Ano_Planej: 2029.0,
        Tensao: 500.0,
        Extensao: 374.64527,
        Concession: null,
        Shape_Leng: 3.34919,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.65415201199994, -8.032278522999945],
            [-38.655956636999974, -8.034196012999928],
            [-38.659465270999931, -8.035595561999969],
            [-38.69558670999993, -8.074946301999944],
            [-38.713713755999947, -8.088764814999934],
            [-38.802697074999969, -8.137781891999964],
            [-38.957919047999951, -8.179323979999936],
            [-38.999883586999943, -8.202483965999932],
            [-39.024881608999976, -8.225705478999942],
            [-39.037690484999985, -8.261524829999928],
            [-39.056193335999978, -8.341120858999943],
            [-39.060429188999933, -8.416663815999925],
            [-39.117365789999951, -8.497796170999948],
            [-39.12924097299998, -8.596599836999928],
            [-39.147592343999975, -8.683220769999934],
            [-39.165101971999945, -8.706767043999946],
            [-39.171181919999981, -8.72315049599996],
            [-39.160172604999957, -8.796043957999927],
            [-39.186326103999932, -8.852709395999966],
            [-39.233774850999964, -8.911551940999971],
            [-39.467683788999977, -9.191238646999977],
            [-39.536783109999931, -9.298991712999964],
            [-39.599586496999962, -9.366072998999925],
            [-39.632568953999964, -9.408065591999957],
            [-39.698508862999972, -9.46646802999993],
            [-39.758328694999932, -9.520764765999957],
            [-39.778598869999939, -9.543903453999974],
            [-39.837920818999976, -9.612243800999977],
            [-39.93151861299998, -9.693129164999959],
            [-40.021772053999939, -9.759568725999941],
            [-40.067397848999974, -9.790092258999948],
            [-40.220593399999984, -9.882461314999944],
            [-40.25202444599995, -9.896480604999965],
            [-40.310789887999931, -9.934667451999928],
            [-40.346556997999983, -9.953821022999932],
            [-40.466811735999954, -10.015204901999937],
            [-40.725787459999935, -10.144132795999951],
            [-40.858552969999948, -10.201545019999969],
            [-40.908245548999957, -10.24296676199998],
            [-40.912982954999961, -10.243735173999937],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Zebu III - Olindina, C1',
        Ano_Planej: 2029.0,
        Tensao: 500.0,
        Extensao: 234.84586,
        Concession: null,
        Shape_Leng: 2.05393,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.145737799999949, -9.34153342299993],
            [-38.144601792999936, -9.340662425999938],
            [-38.141778876999979, -9.340709175999962],
            [-38.128801257999953, -9.355173443999945],
            [-38.115238605999934, -9.398035000999926],
            [-38.116824771999973, -9.408931827999936],
            [-38.145721608999963, -9.435065752999947],
            [-38.189919826999983, -9.456914065999968],
            [-38.199028566999971, -9.475033114999974],
            [-38.211578005999968, -9.622852833999957],
            [-38.210558340999967, -9.654455978999977],
            [-38.22225154299997, -9.702677784999935],
            [-38.226426240999956, -9.728509402999975],
            [-38.224801009999965, -9.745909730999927],
            [-38.229531630999929, -9.770019054999977],
            [-38.234472733999951, -9.77992173399997],
            [-38.275312835999955, -10.040377184999954],
            [-38.288680880999948, -10.122806840999942],
            [-38.30741166599995, -10.237129550999953],
            [-38.310739958999932, -10.258115294999982],
            [-38.313739977999944, -10.285723528999938],
            [-38.30928294499995, -10.29788496599997],
            [-38.309759782790451, -10.310613660371555],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 500 kV Milagres II - Surubim, C1 na SE Bom Nome II',
        Ano_Planej: 2029.0,
        Tensao: 500.0,
        Extensao: 2.81739,
        Concession: null,
        Shape_Leng: 0.02545,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.652078315999972, -8.033987594999928],
            [-38.652798272999974, -8.034843688999956],
            [-38.651892658999941, -8.036442887999954],
            [-38.650074939999968, -8.037338757999976],
            [-38.62969069299993, -8.039230834999955],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 500 kV Milagres II - Surubim, C1 na SE Bom Nome II',
        Ano_Planej: 2029.0,
        Tensao: 500.0,
        Extensao: 2.62933,
        Concession: null,
        Shape_Leng: 0.02378,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.651521923999951, -8.034452450999936],
            [-38.65191886599996, -8.034924451999927],
            [-38.651370356999962, -8.035893044999966],
            [-38.64987369499994, -8.036630679999973],
            [-38.629574838999929, -8.038514828999951],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV João Pessoa II - Pau Ferro, C1',
        Ano_Planej: 2029.0,
        Tensao: 500.0,
        Extensao: 90.53411,
        Concession: null,
        Shape_Leng: 0.787,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.023114266999983, -7.857088578999935],
            [-35.025358969999957, -7.862785109999948],
            [-35.02385560099998, -7.864948959999936],
            [-35.019075749999956, -7.866903182999977],
            [-35.015604690999965, -7.865790299999958],
            [-35.011811267999974, -7.855877688999954],
            [-35.010902967999982, -7.839460998999925],
            [-35.050430767999956, -7.781781921999936],
            [-35.061861182999962, -7.75107941899995],
            [-35.096571929999982, -7.725589063999962],
            [-35.120693700999936, -7.647187158999941],
            [-35.124226179999937, -7.630099162999954],
            [-35.131934361999981, -7.609325902999956],
            [-35.131996609999931, -7.599224981999953],
            [-35.119419586999982, -7.572404091999942],
            [-35.115009584999939, -7.561334648999946],
            [-35.112856436999948, -7.55711747099997],
            [-35.111547347999931, -7.551314667999975],
            [-35.100420773999929, -7.517394950999972],
            [-35.074668120999945, -7.434753918999945],
            [-35.069903827999951, -7.403364521999947],
            [-35.061773997999978, -7.397195464999982],
            [-35.055618425999967, -7.377109234999978],
            [-35.048374467999963, -7.332654964999961],
            [-35.025372497999967, -7.282059904999926],
            [-34.997585306999952, -7.233504289999928],
            [-34.966582986999981, -7.227637098999935],
            [-34.929551639999943, -7.218421362999948],
            [-34.928394839999953, -7.218623233999949],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Garanhuns II - Messias, C1',
        Ano_Planej: 2029.0,
        Tensao: 500.0,
        Extensao: 88.08632,
        Concession: null,
        Shape_Leng: 0.78095,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.401762287999929, -8.881264817999977],
            [-36.40168336399995, -8.880746519999946],
            [-36.39896420499997, -8.880015757999956],
            [-36.393519325999932, -8.881105455999943],
            [-36.382987445999959, -8.896242842999925],
            [-36.35637608899998, -8.928682084999934],
            [-36.31259337399996, -8.966616417999944],
            [-36.296886714999971, -8.97790899599994],
            [-36.267719099999965, -9.02523985199997],
            [-36.246526463999942, -9.052638197999954],
            [-36.225116752999952, -9.072297276999961],
            [-36.206891919999975, -9.103121661999978],
            [-36.191260417999956, -9.117215624999972],
            [-36.16943890899995, -9.13977046399998],
            [-36.132056336999938, -9.173845764999953],
            [-36.109970798999939, -9.192680805999942],
            [-36.108186005999983, -9.195534884999972],
            [-36.09121818999995, -9.216625489999956],
            [-36.070539939999946, -9.242194340999959],
            [-36.031110059999946, -9.263906611999971],
            [-35.974567906999937, -9.329066558999955],
            [-35.912901112999975, -9.378281146999939],
            [-35.863207804999945, -9.392213077999941],
            [-35.856230032999974, -9.393280890999961],
            [-35.851754024999934, -9.395234887999948],
            [-35.847302052999964, -9.39979950399993],
            [-35.843925049999939, -9.400408779999964],
            [-35.843555837999929, -9.400465880999946],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Ceara Mirim II - João Pessoa II, C1',
        Ano_Planej: 2029.0,
        Tensao: 500.0,
        Extensao: 206.40342,
        Concession: null,
        Shape_Leng: 1.78972,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.928290305999951, -7.21845680499996],
            [-34.929517851999947, -7.218207032999942],
            [-34.934493566999947, -7.219165820999933],
            [-34.95436383599997, -7.21410098399997],
            [-35.006290046999936, -7.23171214599995],
            [-35.013647329999969, -7.228452061999974],
            [-35.061350150999942, -7.188696877999973],
            [-35.110348002999956, -7.16168986699995],
            [-35.118144183999959, -7.141259273999935],
            [-35.121120141999938, -7.12896234699997],
            [-35.133676786999956, -7.105065938999928],
            [-35.135846563999962, -7.098423729999979],
            [-35.154401053999948, -7.06963985799996],
            [-35.167470882999964, -7.050427386999957],
            [-35.178219704999947, -6.977440901999955],
            [-35.18825478399998, -6.961235560999967],
            [-35.282332375999943, -6.843297337999957],
            [-35.301453494999976, -6.806975213999976],
            [-35.313460353999972, -6.788166222999962],
            [-35.336923526999954, -6.701863003999961],
            [-35.346790884999962, -6.685915067999929],
            [-35.359814097999958, -6.667170624999926],
            [-35.364771356999938, -6.654906780999966],
            [-35.365478536999944, -6.634829256999978],
            [-35.368285559999947, -6.597315734999938],
            [-35.369853052999929, -6.567668958999946],
            [-35.369794413999955, -6.551404944999945],
            [-35.374442385999942, -6.534755239999981],
            [-35.392967968999983, -6.475626577999947],
            [-35.39898188799998, -6.449172657999952],
            [-35.402464164999969, -6.387165337999932],
            [-35.404381108999985, -6.361448091999932],
            [-35.411886212999946, -6.331479694999928],
            [-35.416210323999962, -6.304684209999948],
            [-35.41467688266416, -6.289108792304777],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Bom Nome II - Zebu III, C1',
        Ano_Planej: 2029.0,
        Tensao: 500.0,
        Extensao: 188.26328,
        Concession: null,
        Shape_Leng: 1.66383,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.653554302999964, -8.032833303999951],
            [-38.655348083999968, -8.034874643999956],
            [-38.65824067899996, -8.042762313999958],
            [-38.653264929999978, -8.056389722999938],
            [-38.570697824999968, -8.107675232999952],
            [-38.559029098999929, -8.122339982999961],
            [-38.550402348999967, -8.152025403999971],
            [-38.553127562999975, -8.175739668999938],
            [-38.556078225999954, -8.18366141599995],
            [-38.547512169999948, -8.253230315999929],
            [-38.547781272999941, -8.254832357999931],
            [-38.544382907999932, -8.28682419699993],
            [-38.563504734999981, -8.36268046899994],
            [-38.531792697999947, -8.437539752999953],
            [-38.521625213999982, -8.461397717999944],
            [-38.516284199999973, -8.474127586999941],
            [-38.477481562999969, -8.496206100999927],
            [-38.471859459999962, -8.512330648999978],
            [-38.468995335999978, -8.516784062999932],
            [-38.45479090799995, -8.536307676999968],
            [-38.464215970999931, -8.575912823999943],
            [-38.42649802699998, -8.631852955999932],
            [-38.415454764999936, -8.644292195999981],
            [-38.401426182999955, -8.663338382999939],
            [-38.387636099999952, -8.683186617999979],
            [-38.368954516999963, -8.710121802999936],
            [-38.367318892999947, -8.714885596999977],
            [-38.363892576999945, -8.717253851999942],
            [-38.347374768999941, -8.740524193999931],
            [-38.346395374999929, -8.742905146999931],
            [-38.345646182999985, -8.748493567999958],
            [-38.342763323999975, -8.752289119999944],
            [-38.339195095999969, -8.753131247999931],
            [-38.334794453999962, -8.757553206999944],
            [-38.260221746999946, -8.863422817999947],
            [-38.15116302399997, -8.876996135999946],
            [-38.128962549999983, -8.894454233999966],
            [-38.023912576999976, -9.007361362999973],
            [-38.020624647999966, -9.012707009999929],
            [-38.016864908999935, -9.033498668999925],
            [-38.014114896999956, -9.043079145999968],
            [-38.011585142999934, -9.06269645499998],
            [-38.037179827999978, -9.100201205999952],
            [-38.055400788999975, -9.146485666999979],
            [-38.072657318999973, -9.174087464999957],
            [-38.077643466999973, -9.182873933999929],
            [-38.101227, -9.22419],
            [-38.10203944899996, -9.246296948999941],
            [-38.104376621999961, -9.27180938999993],
            [-38.109565, -9.31001],
            [-38.147181299999943, -9.339812479999978],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Floresta II - Zebu III, C1',
        Ano_Planej: 2030.0,
        Tensao: 230.0,
        Extensao: 93.98627,
        Concession: null,
        Shape_Leng: 0.83312,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.150165492999975, -9.343461830999956],
            [-38.154454052999938, -9.346536636999929],
            [-38.157684051999979, -9.344840006999959],
            [-38.157661, -9.341449],
            [-38.108554780999953, -9.307087630999945],
            [-38.104013967999947, -9.27184217699994],
            [-38.101676225999938, -9.246324687999959],
            [-38.100866584999949, -9.22429350799996],
            [-38.087930286999949, -9.201737549999962],
            [-38.07234809299996, -9.174278375999961],
            [-38.05508671299998, -9.146668549999958],
            [-38.036855268999943, -9.10037006999994],
            [-38.011206639999955, -9.062787253999943],
            [-38.013757536999947, -9.043006144999936],
            [-38.016510083999947, -9.033416872999965],
            [-38.020276154999976, -9.012576843999966],
            [-38.023634456999957, -9.007128094999928],
            [-38.12871480299998, -8.894188007999958],
            [-38.151017149999973, -8.876649800999928],
            [-38.260016528999984, -8.863083904999939],
            [-38.334514418999959, -8.757320566999965],
            [-38.33900996899996, -8.75280324199997],
            [-38.342552155999954, -8.751967260999947],
            [-38.343378867999945, -8.750878824999972],
            [-38.343452093999929, -8.750140839999972],
            [-38.342798888999937, -8.749679915999934],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 230 kV Extremoz II - Campina Grande III, C2 (CD), na SE Pilões III',
        Ano_Planej: 2029.0,
        Tensao: 230.0,
        Extensao: 20.20315,
        Concession: null,
        Shape_Leng: 0.18029,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.772179816999937, -6.795191294999938],
            [-35.744338336999931, -6.80514846899996],
            [-35.704281892999973, -6.825857188999976],
            [-35.692667786999948, -6.832000490999974],
            [-35.686767931999952, -6.834555748999946],
            [-35.678791799999942, -6.838392204999934],
            [-35.660919622999984, -6.848974884999961],
            [-35.647378063999952, -6.866181356999959],
            [-35.63787906999994, -6.877293368999972],
            [-35.629056617999936, -6.880524528999956],
            [-35.623205991999953, -6.880173765999928],
            [-35.621297173999949, -6.88006932899998],
            [-35.619376109999962, -6.879587839999942],
            [-35.618611525999938, -6.87948235999994],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 230 kV Floresta II - Paulo Afonso III, C1, na SE Zebu III',
        Ano_Planej: 2029.0,
        Tensao: 230.0,
        Extensao: 3.3353,
        Concession: null,
        Shape_Leng: 0.03032,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.150352042999941, -9.343205179999927],
            [-38.151403130999938, -9.343958799999939],
            [-38.176523488999976, -9.343657736999944],
            [-38.180125493999981, -9.345155826999928],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 230 kV Bom Nome - Paulo Afonso III, C1, na SE Zebu III',
        Ano_Planej: 2029.0,
        Tensao: 230.0,
        Extensao: 3.28023,
        Concession: null,
        Shape_Leng: 0.02982,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.150538584999936, -9.342948529999944],
            [-38.151504426999963, -9.343641028999969],
            [-38.176585671999931, -9.343340425999941],
            [-38.179861269999947, -9.344702764999965],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Zebu II - Zebu III C2',
        Ano_Planej: 2029.0,
        Tensao: 230.0,
        Extensao: 6.61175,
        Concession: null,
        Shape_Leng: 0.0596,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.15072512699993, -9.342691878999972],
            [-38.151605721999942, -9.343323256999952],
            [-38.178064028999984, -9.343006088999971],
            [-38.189930579999952, -9.344544775999964],
            [-38.194319988999951, -9.348506464999957],
            [-38.196093058999963, -9.356815036999933],
            [-38.198730325999975, -9.35906371599998],
            [-38.199927392999939, -9.35902665499998],
            [-38.20067325399998, -9.35833420299997],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Zebu II - Zebu III C1',
        Ano_Planej: 2029.0,
        Tensao: 230.0,
        Extensao: 6.56051,
        Concession: null,
        Shape_Leng: 0.05914,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.150911667999935, -9.342435227999943],
            [-38.151707016999978, -9.343005484999935],
            [-38.178082825999979, -9.342689299999961],
            [-38.190070946999981, -9.34424374799994],
            [-38.194610448999981, -9.348340900999972],
            [-38.196382491999941, -9.356644629999948],
            [-38.198844061999978, -9.358743498999957],
            [-38.199797454999953, -9.358713981999927],
            [-38.200455604999945, -9.358102960999929],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Bom Nome - Bom Nome II, C2',
        Ano_Planej: 2029.0,
        Tensao: 230.0,
        Extensao: 4.33092,
        Concession: null,
        Shape_Leng: 0.03833,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.650045874999932, -8.028354266999941],
            [-38.64916106499993, -8.027355554999929],
            [-38.643944768999972, -8.017890671999965],
            [-38.63175653899998, -8.003878286999964],
            [-38.630000970999959, -8.001810718999934],
            [-38.628202486999953, -8.000309840999932],
            [-38.627712363999933, -7.998661593999941],
            [-38.627735707999932, -7.997815097999933],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Bom Nome - Bom Nome II, C1',
        Ano_Planej: 2029.0,
        Tensao: 230.0,
        Extensao: 4.64932,
        Concession: null,
        Shape_Leng: 0.0411,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.650317966999978, -8.02811477299997],
            [-38.649459820999937, -8.027146158999926],
            [-38.644244320999974, -8.017682731999969],
            [-38.632117321999942, -8.003740759999971],
            [-38.632034543999964, -7.99865585799995],
            [-38.63068244599998, -7.996379540999953],
            [-38.629879639999956, -7.996028979999949],
            [-38.628884985999946, -7.996358006999969],
            [-38.628731941999945, -7.997212055999967],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 500 kV São João do Piauí – Ribeiro Gonçalves C1 na SE São João do Piauí II',
        Ano_Planej: 2029.0,
        Tensao: 500.0,
        Extensao: 1.75251,
        Concession: null,
        Shape_Leng: 0.0156,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-42.499597596999934, -8.26089834499993],
            [-42.502749665999943, -8.274255851999953],
            [-42.50455735099996, -8.273774110999966],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 500 kV São João do Piauí – Ribeiro Gonçalves C1 na SE São João do Piauí II',
        Ano_Planej: 2029.0,
        Tensao: 500.0,
        Extensao: 1.69709,
        Concession: null,
        Shape_Leng: 0.0151,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-42.507842517999961, -8.272893026999952],
            [-42.50937874899995, -8.272480241999972],
            [-42.506276379999974, -8.25933435099995],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 500 kV São João do Piauí – Ribeiro Gonçalves C2 na SE São João do Piauí II',
        Ano_Planej: 2029.0,
        Tensao: 500.0,
        Extensao: 1.77338,
        Concession: null,
        Shape_Leng: 0.01579,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-42.504702108999936, -8.274326705999954],
            [-42.50248574699998, -8.274914599999931],
            [-42.499386115999982, -8.261779282999953],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 500 kV São João do Piauí – Ribeiro Gonçalves C2 na SE São João do Piauí II',
        Ano_Planej: 2029.0,
        Tensao: 500.0,
        Extensao: 1.70459,
        Concession: null,
        Shape_Leng: 0.01517,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-42.507986089999974, -8.273441076999973],
            [-42.509911472999931, -8.272929503999933],
            [-42.506877146999955, -8.260103035999975],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Curral Novo do Piauí II - São João do Piauí II, C1',
        Ano_Planej: 2029.0,
        Tensao: 500.0,
        Extensao: 220.21477,
        Concession: null,
        Shape_Leng: 1.99284,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-42.504830209999966, -8.274800585999969],
            [-42.501731510999946, -8.275666319999971],
            [-42.45526698599997, -8.34547519299997],
            [-42.245056120999948, -8.432507244999954],
            [-42.166724612999985, -8.424226377999958],
            [-42.052253280999935, -8.387344022999969],
            [-42.006617659999961, -8.366358470999955],
            [-41.889085282999929, -8.302009329999976],
            [-41.831440961999931, -8.294181336999941],
            [-41.77350000499996, -8.272329210999942],
            [-41.541456409999967, -8.25897729299993],
            [-41.528841642999964, -8.258015720999936],
            [-41.444813023999984, -8.236790507999956],
            [-41.435862783999937, -8.235017452999955],
            [-41.381882619999942, -8.217491283999948],
            [-41.330453860999967, -8.19147305599995],
            [-41.311473502999945, -8.183787249999966],
            [-41.234209947999943, -8.160799199999929],
            [-41.155996977999962, -8.119616477999955],
            [-41.129486718999942, -8.116683295999962],
            [-41.121172754999975, -8.117501850999929],
            [-41.099249356999962, -8.11463759399993],
            [-41.091244567999979, -8.11245394499997],
            [-41.073150476999956, -8.109514727999965],
            [-41.027392836999979, -8.103158576999931],
            [-40.995969333999938, -8.096890085999974],
            [-40.869114513999932, -8.069549519999953],
            [-40.716445869999973, -8.061435003999975],
            [-40.660990341999934, -8.045313108999949],
            [-40.654757194999945, -8.046499324999957],
            [-40.649570552999933, -8.045246817999953],
            [-40.64067599599997, -8.041061327999955],
            [-40.640037776999975, -8.039511274999938],
            [-40.64055512799996, -8.035932056999968],
            [-40.639902366999934, -8.035257779999938],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV São João do Piauí II - Ribeiro Gonçalves, C3',
        Ano_Planej: 2029.0,
        Tensao: 500.0,
        Extensao: 309.28586,
        Concession: null,
        Shape_Leng: 2.80195,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-43.213321391609071, -8.177273426699012],
            [-43.120604797999931, -8.177556910999954],
            [-43.071140880999963, -8.19162648899993],
            [-43.014913659999934, -8.211272258999941],
            [-42.905434961999958, -8.225766832999966],
            [-42.830185325999935, -8.229511055999978],
            [-42.824605633999965, -8.23482189899994],
            [-42.597158658999945, -8.255242004999957],
            [-42.570802114999935, -8.253779622999957],
            [-42.510038035999969, -8.273425247999967],
            [-42.508107929999937, -8.273913982999943],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Araticum (Mauriti) - Milagres, C2',
        Ano_Planej: 2029.0,
        Tensao: 230.0,
        Extensao: 19.35978,
        Concession: null,
        Shape_Leng: 0.17437,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.782038458999978, -7.364828683999974],
            [-38.783943097999952, -7.364713035999955],
            [-38.78893715199996, -7.362372813999968],
            [-38.81793795699997, -7.352528310999958],
            [-38.838372964999962, -7.344160365999926],
            [-38.844622967999953, -7.339897511999936],
            [-38.86102488299997, -7.332590653999944],
            [-38.863424551999969, -7.33170407199998],
            [-38.868216793999977, -7.332101101999967],
            [-38.874790963999942, -7.331748674999972],
            [-38.910196096999982, -7.319536171999971],
            [-38.915203687999963, -7.319575915999962],
            [-38.93720634899995, -7.316302872999927],
            [-38.938895126999967, -7.312298662999979],
            [-38.940794234999942, -7.309537493999926],
            [-38.940658422999945, -7.307172719999926],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Campo Formoso II - Barra II, C1',
        Ano_Planej: 2028.0,
        Tensao: 500.0,
        Extensao: 312.70174,
        Concession: null,
        Shape_Leng: 2.83994,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-41.029844320084159, -10.310613660371555],
            [-41.003766556999949, -10.292581238999958],
            [-40.921052409999959, -10.244527602999938],
            [-40.916552900999932, -10.243771214999981],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV UFV Humaita - Juazeiro II, C1',
        Ano_Planej: 0.0,
        Tensao: 230.0,
        Extensao: 8.44381,
        Concession: null,
        Shape_Leng: 0.07539,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.42861539699993, -9.535870121999949],
            [-40.428633522999974, -9.535224401999926],
            [-40.428522427999951, -9.533535850999954],
            [-40.436894233999965, -9.52348104899994],
            [-40.449444081999957, -9.500982332999968],
            [-40.462834913999984, -9.491954988999964],
            [-40.464469410999982, -9.491862203999972],
            [-40.472405807999962, -9.482116898999948],
            [-40.475509774999978, -9.481518735999941],
            [-40.476124634999962, -9.48184175199998],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 500 kV Eólico Oeste Seridó - Santa Luzia II, C1',
        Ano_Planej: 0.0,
        Tensao: 500.0,
        Extensao: 42.26477,
        Concession: null,
        Shape_Leng: 0.37738,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.669590837999976, -6.785789572999931],
            [-36.669719118999978, -6.785328152999966],
            [-36.680533350999951, -6.777692501999979],
            [-36.698702566999941, -6.765472601999932],
            [-36.727707543999941, -6.760665575999951],
            [-36.757565819999968, -6.757695248999937],
            [-36.781622808999941, -6.770274332999975],
            [-36.797603319999951, -6.786159032999933],
            [-36.814850887999967, -6.794559457999981],
            [-36.835867436999933, -6.819288699999959],
            [-36.848051150999936, -6.82772153999997],
            [-36.899999851999951, -6.873991309999951],
            [-36.91081573699995, -6.888300484999945],
            [-36.913922588999981, -6.889389153999957],
            [-36.920482524999954, -6.889381904999937],
            [-36.927424557999984, -6.888125997999964],
            [-36.930984425999952, -6.889853399999936],
            [-36.936377372999971, -6.891072713999961],
            [-36.97052572299998, -6.907834083999944],
            [-36.979872766999961, -6.910759034999955],
            [-36.982057728999962, -6.912453131999939],
            [-36.982711220999931, -6.91254584099994],
            [-36.983779836999929, -6.912634800999967],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Serra da Borborema - Campina Grande III, C1\r\n',
        Ano_Planej: 0.0,
        Tensao: 230.0,
        Extensao: 26.30764,
        Concession: null,
        Shape_Leng: 0.22792,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.98038340599993, -7.25484410699994],
            [-35.980211068999949, -7.256220250999945],
            [-35.981745513999954, -7.257593415999963],
            [-35.985690301999966, -7.256872564999981],
            [-35.993291832999944, -7.240548128999933],
            [-35.995108190999929, -7.231875514999956],
            [-36.004796835999969, -7.21441213099996],
            [-36.006102702999954, -7.199107137999931],
            [-36.007742471999961, -7.185816836999948],
            [-36.012657484999977, -7.169656206999946],
            [-36.011247125999944, -7.157930578999981],
            [-36.011042392999968, -7.150853918999928],
            [-36.012185224999939, -7.136861517999932],
            [-36.010548895999932, -7.12771569399996],
            [-36.005842891999976, -7.116667371999938],
            [-36.002066139999954, -7.07134978299996],
            [-35.997373014999937, -7.064024012999937],
            [-35.992506954999953, -7.050812954999969],
            [-35.989185021999958, -7.046427673999972],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV Bom Nome - UFV Belmonte, C1',
        Ano_Planej: 0.0,
        Tensao: 230.0,
        Extensao: 27.49712,
        Concession: null,
        Shape_Leng: 0.24646,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-38.628340713999933, -7.996932843999957],
            [-38.628641350999942, -7.996314414999972],
            [-38.630673141999978, -7.995369170999936],
            [-38.632721742999934, -7.994191310999952],
            [-38.634494695999933, -7.993156754999973],
            [-38.635673765999968, -7.99102312399998],
            [-38.65945992099995, -7.973458830999959],
            [-38.662827362999963, -7.971023399999979],
            [-38.673910830999944, -7.970220058999928],
            [-38.674832181999932, -7.969022363999954],
            [-38.693899605999945, -7.954610638999952],
            [-38.809436671999947, -7.87113024599995],
            [-38.812038358999985, -7.871248454999943],
            [-38.828793504999965, -7.870475392999936],
            [-38.833299728999975, -7.87044396999994],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SECC LT 230 kV Paulo Afonso III - Itabaiana, C2 (CD), na SE Nossa Senhora da Glória II',
        Ano_Planej: 2025.0,
        Tensao: 230.0,
        Extensao: 15.8999,
        Concession: null,
        Shape_Leng: 0.14282,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-37.47891485655903, -10.310613660371555],
            [-37.468592233999971, -10.302601472999982],
            [-37.467233927999985, -10.30173532799995],
            [-37.450428654999939, -10.286809342999959],
            [-37.447131749999983, -10.285006562999968],
            [-37.436732924999944, -10.281631926999978],
            [-37.436427102999971, -10.280613660999961],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV UFV Humaita - Juazeiro III, C1',
        Ano_Planej: 0.0,
        Tensao: 230.0,
        Extensao: 18.14477,
        Concession: null,
        Shape_Leng: 0.16308,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-40.517764383999975, -9.486379316999944],
            [-40.517049655999983, -9.489565757999969],
            [-40.522258361999945, -9.491056893999939],
            [-40.524355716999935, -9.49377474299996],
            [-40.529711376999956, -9.52437802999998],
            [-40.523263265999958, -9.533001187999957],
            [-40.518391697999959, -9.546847305999961],
            [-40.482026419999954, -9.549070833999963],
            [-40.436318415999949, -9.544169887999942],
            [-40.431526315999974, -9.536465122999971],
            [-40.428560508999965, -9.536308302999942],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'LT 230 kV UFV Seridó - Campina Grande 3, C1',
        Ano_Planej: 0.0,
        Tensao: 230.0,
        Extensao: 75.78105,
        Concession: null,
        Shape_Leng: 0.67617,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.980308076999961, -7.254844519999949],
            [-35.98027916999996, -7.256167025999957],
            [-35.980772768999941, -7.261118641999929],
            [-35.982797173999984, -7.263977913999952],
            [-35.992920183999956, -7.266505381999934],
            [-36.001264268999932, -7.262708614999951],
            [-36.060211694999964, -7.227649284999927],
            [-36.103991356999984, -7.208720873999937],
            [-36.167413115999977, -7.184530227999971],
            [-36.255683028999954, -7.145570898999949],
            [-36.329562751999958, -7.101679128999933],
            [-36.37583916899996, -7.07548023499993],
            [-36.387601719999964, -7.06360060399993],
            [-36.40281213999998, -7.039923488999932],
            [-36.45902641899994, -6.997927058999949],
            [-36.487188763999939, -6.978744773999949],
            [-36.501464342999952, -6.953401821999933],
            [-36.518252909999944, -6.939644701999953],
            [-36.539368004999972, -6.931287628999939],
            [-36.541255962999969, -6.92848829999997],
            [-36.541276899999957, -6.927460492999955],
          ],
        ],
      },
    },
  ],
};
