export var jsonCnaeEmpresasTotal1 = {
  type: 'FeatureCollection',
  name: 'CNAE_EMPRESAS_total_1',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Abreu e Lima',
        area_ha: 12638.45,
        Cons_Eng_T: '119826',
        Cons_Eng_R: '48697',
        Cons_Eng_I: '41809',
        Cons_Eng_C: '19028',
        Cons_Eng_1: '414',
        Cons_Eng_P: '3030',
        Cons_Eng_2: '3841',
        Cons_Eng_O: '3007',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.01914463022333, -7.879660790537477],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Afogados da Ingazeira',
        area_ha: 37769.48,
        Cons_Eng_T: '36711',
        Cons_Eng_R: '22569',
        Cons_Eng_I: '238',
        Cons_Eng_C: '4565',
        Cons_Eng_1: '1570',
        Cons_Eng_P: '3149',
        Cons_Eng_2: '2234',
        Cons_Eng_O: '2386',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.62000379868249, -7.737103509425371],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'AfrÃ¢nio',
        area_ha: 149059.19,
        Cons_Eng_T: '12142',
        Cons_Eng_R: '6483',
        Cons_Eng_I: '401',
        Cons_Eng_C: '1526',
        Cons_Eng_1: '1540',
        Cons_Eng_P: '623',
        Cons_Eng_2: '1368',
        Cons_Eng_O: '201',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.005395299216609, -8.61892909945351],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Agrestina',
        area_ha: 20036.95,
        Cons_Eng_T: '52358',
        Cons_Eng_R: '12181',
        Cons_Eng_I: '1451',
        Cons_Eng_C: '1640',
        Cons_Eng_1: '2190',
        Cons_Eng_P: '616',
        Cons_Eng_2: '1759',
        Cons_Eng_O: '32521',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.945245013341918, -8.456613768163754],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Ãgua Preta',
        area_ha: 53193.56,
        Cons_Eng_T: '15822',
        Cons_Eng_R: '9511',
        Cons_Eng_I: '117',
        Cons_Eng_C: '1301',
        Cons_Eng_1: '429',
        Cons_Eng_P: '779',
        Cons_Eng_2: '2659',
        Cons_Eng_O: '1026',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.481849713847474, -8.729272848937384],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Ãguas Belas',
        area_ha: 88598.76,
        Cons_Eng_T: '28242',
        Cons_Eng_R: '16528',
        Cons_Eng_I: '305',
        Cons_Eng_C: '2113',
        Cons_Eng_1: '6415',
        Cons_Eng_P: '898',
        Cons_Eng_2: '1875',
        Cons_Eng_O: '108',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.038648046810515, -9.087044537697057],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Alagoinha',
        area_ha: 21426.71,
        Cons_Eng_T: '8482',
        Cons_Eng_R: '6374',
        Cons_Eng_I: '4',
        Cons_Eng_C: '737',
        Cons_Eng_1: '400',
        Cons_Eng_P: '344',
        Cons_Eng_2: '617',
        Cons_Eng_O: '6',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.755514805624003, -8.491934678566718],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'AlianÃ§a',
        area_ha: 27277.26,
        Cons_Eng_T: '25532',
        Cons_Eng_R: '16400',
        Cons_Eng_I: '104',
        Cons_Eng_C: '2320',
        Cons_Eng_1: '1805',
        Cons_Eng_P: '977',
        Cons_Eng_2: '3106',
        Cons_Eng_O: '820',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.193038954560556, -7.602575946653328],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Altinho',
        area_ha: 45017.78,
        Cons_Eng_T: '15157',
        Cons_Eng_R: '9265',
        Cons_Eng_I: '662',
        Cons_Eng_C: '827',
        Cons_Eng_1: '871',
        Cons_Eng_P: '521',
        Cons_Eng_2: '1519',
        Cons_Eng_O: '1492',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.098971573031584, -8.490502612703036],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Amaraji',
        area_ha: 23323.88,
        Cons_Eng_T: '16538',
        Cons_Eng_R: '7440',
        Cons_Eng_I: '326',
        Cons_Eng_C: '1174',
        Cons_Eng_1: '857',
        Cons_Eng_P: '551',
        Cons_Eng_2: '788',
        Cons_Eng_O: '5402',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.465459483386709, -8.365021185545805],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Angelim',
        area_ha: 11808.8,
        Cons_Eng_T: '6817',
        Cons_Eng_R: '3878',
        Cons_Eng_I: '854',
        Cons_Eng_C: '782',
        Cons_Eng_1: '640',
        Cons_Eng_P: '286',
        Cons_Eng_2: '361',
        Cons_Eng_O: '16',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.276768839273359, -8.891502833701455],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'AraÃ§oiaba',
        area_ha: 9636.01,
        Cons_Eng_T: '10230',
        Cons_Eng_R: '7560',
        Cons_Eng_I: '7',
        Cons_Eng_C: '949',
        Cons_Eng_1: '41',
        Cons_Eng_P: '478',
        Cons_Eng_2: '771',
        Cons_Eng_O: '424',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.076124186328158, -7.793234130311211],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Araripina',
        area_ha: 203739.51,
        Cons_Eng_T: '100970',
        Cons_Eng_R: '36833',
        Cons_Eng_I: '38120',
        Cons_Eng_C: '10449',
        Cons_Eng_1: '7076',
        Cons_Eng_P: '2126',
        Cons_Eng_2: '3720',
        Cons_Eng_O: '2646',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.484804096082598, -7.607948419524962],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Arcoverde',
        area_ha: 34392.2,
        Cons_Eng_T: '88201',
        Cons_Eng_R: '39346',
        Cons_Eng_I: '6826',
        Cons_Eng_C: '17635',
        Cons_Eng_1: '2167',
        Cons_Eng_P: '5009',
        Cons_Eng_2: '4462',
        Cons_Eng_O: '12756',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.00554783302838, -8.383889070515929],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Barra de Guabiraba',
        area_ha: 11863.94,
        Cons_Eng_T: '10999',
        Cons_Eng_R: '3989',
        Cons_Eng_I: '283',
        Cons_Eng_C: '634',
        Cons_Eng_1: '2501',
        Cons_Eng_P: '210',
        Cons_Eng_2: '570',
        Cons_Eng_O: '2812',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.63380449305518, -8.41817744531278],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Barreiros',
        area_ha: 23343.28,
        Cons_Eng_T: '29154',
        Cons_Eng_R: '15841',
        Cons_Eng_I: '217',
        Cons_Eng_C: '6028',
        Cons_Eng_1: '861',
        Cons_Eng_P: '2060',
        Cons_Eng_2: '2831',
        Cons_Eng_O: '1316',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.258599693309094, -8.820186157572692],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'BelÃ©m de Maria',
        area_ha: 7314.48,
        Cons_Eng_T: '5528',
        Cons_Eng_R: '3893',
        Cons_Eng_I: '5',
        Cons_Eng_C: '425',
        Cons_Eng_1: '147',
        Cons_Eng_P: '321',
        Cons_Eng_2: '474',
        Cons_Eng_O: '263',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.815594276705831, -8.605070005088095],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'BelÃ©m do SÃ£o Francisco',
        area_ha: 183079.54,
        Cons_Eng_T: '21403',
        Cons_Eng_R: '8234',
        Cons_Eng_I: '27',
        Cons_Eng_C: '3084',
        Cons_Eng_1: '6797',
        Cons_Eng_P: '1166',
        Cons_Eng_2: '1210',
        Cons_Eng_O: '885',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.981939847298989, -8.583564104941431],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Belo Jardim',
        area_ha: 64744.53,
        Cons_Eng_T: '246550',
        Cons_Eng_R: '41591',
        Cons_Eng_I: '170062',
        Cons_Eng_C: '12424',
        Cons_Eng_1: '5854',
        Cons_Eng_P: '2393',
        Cons_Eng_2: '6235',
        Cons_Eng_O: '7991',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.405117311247245, -8.258927781780615],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'BetÃ¢nia',
        area_ha: 124407.42,
        Cons_Eng_T: '6842',
        Cons_Eng_R: '4360',
        Cons_Eng_I: '0',
        Cons_Eng_C: '395',
        Cons_Eng_1: '755',
        Cons_Eng_P: '491',
        Cons_Eng_2: '552',
        Cons_Eng_O: '289',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.987652946273428, -8.265292407177153],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Bezerros',
        area_ha: 49263.22,
        Cons_Eng_T: '65904',
        Cons_Eng_R: '35196',
        Cons_Eng_I: '5047',
        Cons_Eng_C: '8727',
        Cons_Eng_1: '9095',
        Cons_Eng_P: '1894',
        Cons_Eng_2: '4797',
        Cons_Eng_O: '1148',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.782154138284987, -8.218033238354382],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'BodocÃ³',
        area_ha: 162178.61,
        Cons_Eng_T: '19376',
        Cons_Eng_R: '11528',
        Cons_Eng_I: '350',
        Cons_Eng_C: '1636',
        Cons_Eng_1: '1208',
        Cons_Eng_P: '785',
        Cons_Eng_2: '2022',
        Cons_Eng_O: '1847',
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.965124849137453, -7.656367910525572],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Bom Conselho',
        area_ha: 79218.53,
        Cons_Eng_T: '30413',
        Cons_Eng_R: '17746',
        Cons_Eng_I: '724',
        Cons_Eng_C: '2439',
        Cons_Eng_1: '3163',
        Cons_Eng_P: '902',
        Cons_Eng_2: '1438',
        Cons_Eng_O: '4001',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.684038062602426, -9.202697516216753],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Bom Jardim',
        area_ha: 22410.85,
        Cons_Eng_T: '31163',
        Cons_Eng_R: '15581',
        Cons_Eng_I: '1963',
        Cons_Eng_C: '1954',
        Cons_Eng_1: '1963',
        Cons_Eng_P: '1026',
        Cons_Eng_2: '4107',
        Cons_Eng_O: '4569',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.588522335924758, -7.78678509827825],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Bonito',
        area_ha: 39319.08,
        Cons_Eng_T: '53561',
        Cons_Eng_R: '16387',
        Cons_Eng_I: '7290',
        Cons_Eng_C: '2635',
        Cons_Eng_1: '5081',
        Cons_Eng_P: '1161',
        Cons_Eng_2: '2035',
        Cons_Eng_O: '18972',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.708542205082956, -8.4949542316739],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'BrejÃ£o',
        area_ha: 15978.63,
        Cons_Eng_T: '14287',
        Cons_Eng_R: '3084',
        Cons_Eng_I: '88',
        Cons_Eng_C: '366',
        Cons_Eng_1: '4096',
        Cons_Eng_P: '233',
        Cons_Eng_2: '154',
        Cons_Eng_O: '6266',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.529869829213901, -9.02674818248838],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Brejinho',
        area_ha: 10603.94,
        Cons_Eng_T: '4491',
        Cons_Eng_R: '3051',
        Cons_Eng_I: '0',
        Cons_Eng_C: '211',
        Cons_Eng_1: '286',
        Cons_Eng_P: '371',
        Cons_Eng_2: '457',
        Cons_Eng_O: '115',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.320873359099458, -7.336668041201886],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Brejo da Madre de Deus',
        area_ha: 76234.49,
        Cons_Eng_T: '38478',
        Cons_Eng_R: '27085',
        Cons_Eng_I: '179',
        Cons_Eng_C: '2525',
        Cons_Eng_1: '2776',
        Cons_Eng_P: '1141',
        Cons_Eng_2: '4029',
        Cons_Eng_O: '743',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.281412947807574, -8.075643258224554],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Buenos Aires',
        area_ha: 9318.7,
        Cons_Eng_T: '8572',
        Cons_Eng_R: '6228',
        Cons_Eng_I: '3',
        Cons_Eng_C: '627',
        Cons_Eng_1: '659',
        Cons_Eng_P: '378',
        Cons_Eng_2: '621',
        Cons_Eng_O: '56',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.347239325766765, -7.737919264396975],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'BuÃ­que',
        area_ha: 133654.52,
        Cons_Eng_T: '25547',
        Cons_Eng_R: '16006',
        Cons_Eng_I: '531',
        Cons_Eng_C: '2192',
        Cons_Eng_1: '3367',
        Cons_Eng_P: '988',
        Cons_Eng_2: '1118',
        Cons_Eng_O: '1345',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.122667902344112, -8.649993286872817],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Cabo de Santo Agostinho',
        area_ha: 44538.58,
        Cons_Eng_T: '911369',
        Cons_Eng_R: '124223',
        Cons_Eng_I: '561868',
        Cons_Eng_C: '139113',
        Cons_Eng_1: '3671',
        Cons_Eng_P: '16545',
        Cons_Eng_2: '16153',
        Cons_Eng_O: '49796',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.085919163414054, -8.26922015438854],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'CabrobÃ³',
        area_ha: 165861.77,
        Cons_Eng_T: '44452',
        Cons_Eng_R: '14025',
        Cons_Eng_I: '316',
        Cons_Eng_C: '2753',
        Cons_Eng_1: '7256',
        Cons_Eng_P: '1572',
        Cons_Eng_2: '1784',
        Cons_Eng_O: '16746',
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.329428045362107, -8.386470358907346],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Cachoeirinha',
        area_ha: 17926.1,
        Cons_Eng_T: '15256',
        Cons_Eng_R: '11439',
        Cons_Eng_I: '130',
        Cons_Eng_C: '1414',
        Cons_Eng_1: '596',
        Cons_Eng_P: '620',
        Cons_Eng_2: '1049',
        Cons_Eng_O: '8',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.290944697508451, -8.484928149536366],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'CaetÃ©s',
        area_ha: 29494.64,
        Cons_Eng_T: '13024',
        Cons_Eng_R: '7684',
        Cons_Eng_I: '1241',
        Cons_Eng_C: '524',
        Cons_Eng_1: '1998',
        Cons_Eng_P: '620',
        Cons_Eng_2: '910',
        Cons_Eng_O: '47',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.659187141606189, -8.803950780392851],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'CalÃ§ado',
        area_ha: 12194.47,
        Cons_Eng_T: '6094',
        Cons_Eng_R: '3402',
        Cons_Eng_I: '425',
        Cons_Eng_C: '249',
        Cons_Eng_1: '756',
        Cons_Eng_P: '267',
        Cons_Eng_2: '538',
        Cons_Eng_O: '457',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.321753954223844, -8.750575293786063],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Calumbi',
        area_ha: 17931.31,
        Cons_Eng_T: '4381',
        Cons_Eng_R: '2332',
        Cons_Eng_I: '0',
        Cons_Eng_C: '329',
        Cons_Eng_1: '968',
        Cons_Eng_P: '221',
        Cons_Eng_2: '244',
        Cons_Eng_O: '287',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.078682869730471, -8.003915855359599],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Camaragibe',
        area_ha: 5132.14,
        Cons_Eng_T: '169011',
        Cons_Eng_R: '100441',
        Cons_Eng_I: '2745',
        Cons_Eng_C: '40899',
        Cons_Eng_1: '3222',
        Cons_Eng_P: '4985',
        Cons_Eng_2: '10524',
        Cons_Eng_O: '6195',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.995811778083727, -7.986775521708561],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Camocim de SÃ£o FÃ©lix',
        area_ha: 7201.01,
        Cons_Eng_T: '11735',
        Cons_Eng_R: '7619',
        Cons_Eng_I: '30',
        Cons_Eng_C: '1043',
        Cons_Eng_1: '1634',
        Cons_Eng_P: '282',
        Cons_Eng_2: '1062',
        Cons_Eng_O: '65',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.772065865877536, -8.349998001204909],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Camutanga',
        area_ha: 3911.59,
        Cons_Eng_T: '9084',
        Cons_Eng_R: '3816',
        Cons_Eng_I: '1968',
        Cons_Eng_C: '429',
        Cons_Eng_1: '1410',
        Cons_Eng_P: '366',
        Cons_Eng_2: '520',
        Cons_Eng_O: '575',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.299186717563423, -7.43391106173669],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Canhotinho',
        area_ha: 42316.82,
        Cons_Eng_T: '19701',
        Cons_Eng_R: '8622',
        Cons_Eng_I: '2717',
        Cons_Eng_C: '1035',
        Cons_Eng_1: '1462',
        Cons_Eng_P: '1183',
        Cons_Eng_2: '1114',
        Cons_Eng_O: '3568',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.176904137884925, -8.893225854781861],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Capoeiras',
        area_ha: 33712.97,
        Cons_Eng_T: '10382',
        Cons_Eng_R: '6037',
        Cons_Eng_I: '16',
        Cons_Eng_C: '856',
        Cons_Eng_1: '2125',
        Cons_Eng_P: '387',
        Cons_Eng_2: '476',
        Cons_Eng_O: '485',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.599171602317682, -8.686659075577507],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'CarnaÃ­ba',
        area_ha: 42780.19,
        Cons_Eng_T: '14083',
        Cons_Eng_R: '8604',
        Cons_Eng_I: '69',
        Cons_Eng_C: '916',
        Cons_Eng_1: '865',
        Cons_Eng_P: '1070',
        Cons_Eng_2: '852',
        Cons_Eng_O: '1707',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.727160278646011, -7.796230227352321],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Carnaubeira da Penha',
        area_ha: 100466.53,
        Cons_Eng_T: '6451',
        Cons_Eng_R: '3655',
        Cons_Eng_I: '0',
        Cons_Eng_C: '319',
        Cons_Eng_1: '1099',
        Cons_Eng_P: '561',
        Cons_Eng_2: '682',
        Cons_Eng_O: '135',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.721531896678727, -8.409193736289723],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Carpina',
        area_ha: 14701.75,
        Cons_Eng_T: '114246',
        Cons_Eng_R: '50226',
        Cons_Eng_I: '24515',
        Cons_Eng_C: '25445',
        Cons_Eng_1: '2767',
        Cons_Eng_P: '3430',
        Cons_Eng_2: '6626',
        Cons_Eng_O: '1237',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.305916074030357, -7.819468556117516],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Caruaru',
        area_ha: 92314.97,
        Cons_Eng_T: '513779',
        Cons_Eng_R: '254935',
        Cons_Eng_I: '55902',
        Cons_Eng_C: '133511',
        Cons_Eng_1: '7046',
        Cons_Eng_P: '26291',
        Cons_Eng_2: '26350',
        Cons_Eng_O: '9744',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.016634840104246, -8.180522959922238],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Casinhas',
        area_ha: 11586.76,
        Cons_Eng_T: '7040',
        Cons_Eng_R: '4587',
        Cons_Eng_I: '4',
        Cons_Eng_C: '367',
        Cons_Eng_1: '451',
        Cons_Eng_P: '297',
        Cons_Eng_2: '1320',
        Cons_Eng_O: '14',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.721382720748551, -7.756380605815804],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Catende',
        area_ha: 20859.32,
        Cons_Eng_T: '22933',
        Cons_Eng_R: '14833',
        Cons_Eng_I: '1621',
        Cons_Eng_C: '2846',
        Cons_Eng_1: '88',
        Cons_Eng_P: '1015',
        Cons_Eng_2: '1576',
        Cons_Eng_O: '954',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.730936278335768, -8.675535251462563],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Cedro',
        area_ha: 14874.56,
        Cons_Eng_T: '8238',
        Cons_Eng_R: '4372',
        Cons_Eng_I: '10',
        Cons_Eng_C: '652',
        Cons_Eng_1: '1476',
        Cons_Eng_P: '371',
        Cons_Eng_2: '784',
        Cons_Eng_O: '573',
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.203737983693365, -7.743823254562917],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'ChÃ£ de Alegria',
        area_ha: 4932.66,
        Cons_Eng_T: '8088',
        Cons_Eng_R: '5098',
        Cons_Eng_I: '322',
        Cons_Eng_C: '609',
        Cons_Eng_1: '741',
        Cons_Eng_P: '502',
        Cons_Eng_2: '610',
        Cons_Eng_O: '206',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.222206645229548, -7.999399764393124],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'ChÃ£ Grande',
        area_ha: 8478.75,
        Cons_Eng_T: '23892',
        Cons_Eng_R: '9149',
        Cons_Eng_I: '139',
        Cons_Eng_C: '1639',
        Cons_Eng_1: '4187',
        Cons_Eng_P: '593',
        Cons_Eng_2: '1225',
        Cons_Eng_O: '6960',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.473996128996689, -8.229565294338279],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Condado',
        area_ha: 8964.44,
        Cons_Eng_T: '17537',
        Cons_Eng_R: '12124',
        Cons_Eng_I: '1352',
        Cons_Eng_C: '1469',
        Cons_Eng_1: '156',
        Cons_Eng_P: '649',
        Cons_Eng_2: '1647',
        Cons_Eng_O: '140',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.098677442214829, -7.596312953705797],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Correntes',
        area_ha: 31779.35,
        Cons_Eng_T: '9505',
        Cons_Eng_R: '5474',
        Cons_Eng_I: '353',
        Cons_Eng_C: '587',
        Cons_Eng_1: '1844',
        Cons_Eng_P: '325',
        Cons_Eng_2: '423',
        Cons_Eng_O: '499',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.338679259297514, -9.114711894811144],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'CortÃªs',
        area_ha: 10285.18,
        Cons_Eng_T: '6550',
        Cons_Eng_R: '4480',
        Cons_Eng_I: '25',
        Cons_Eng_C: '490',
        Cons_Eng_1: '252',
        Cons_Eng_P: '534',
        Cons_Eng_2: '442',
        Cons_Eng_O: '327',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.530119658676931, -8.433059490519904],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Cumaru',
        area_ha: 29223.1,
        Cons_Eng_T: '27887',
        Cons_Eng_R: '6055',
        Cons_Eng_I: '1',
        Cons_Eng_C: '402',
        Cons_Eng_1: '208',
        Cons_Eng_P: '409',
        Cons_Eng_2: '1619',
        Cons_Eng_O: '19193',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.720772078080337, -8.039952669972278],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Cupira',
        area_ha: 9515.52,
        Cons_Eng_T: '20478',
        Cons_Eng_R: '13197',
        Cons_Eng_I: '1070',
        Cons_Eng_C: '2213',
        Cons_Eng_1: '441',
        Cons_Eng_P: '855',
        Cons_Eng_2: '2480',
        Cons_Eng_O: '222',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.931376020649189, -8.583010169567084],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'CustÃ³dia',
        area_ha: 140412.47,
        Cons_Eng_T: '31749',
        Cons_Eng_R: '18572',
        Cons_Eng_I: '4221',
        Cons_Eng_C: '3628',
        Cons_Eng_1: '1618',
        Cons_Eng_P: '1304',
        Cons_Eng_2: '1997',
        Cons_Eng_O: '409',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.681554560315355, -8.173842179879738],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Dormentes',
        area_ha: 153904.56,
        Cons_Eng_T: '13326',
        Cons_Eng_R: '6413',
        Cons_Eng_I: '2720',
        Cons_Eng_C: '1164',
        Cons_Eng_1: '1410',
        Cons_Eng_P: '466',
        Cons_Eng_2: '910',
        Cons_Eng_O: '243',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.620072628973944, -8.496155343615142],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Escada',
        area_ha: 34258.41,
        Cons_Eng_T: '62423',
        Cons_Eng_R: '25918',
        Cons_Eng_I: '20132',
        Cons_Eng_C: '7512',
        Cons_Eng_1: '1181',
        Cons_Eng_P: '850',
        Cons_Eng_2: '4265',
        Cons_Eng_O: '2565',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.26457176398592, -8.369739785909902],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Exu',
        area_ha: 133678.56,
        Cons_Eng_T: '17196',
        Cons_Eng_R: '12358',
        Cons_Eng_I: '49',
        Cons_Eng_C: '1497',
        Cons_Eng_1: '853',
        Cons_Eng_P: '791',
        Cons_Eng_2: '1441',
        Cons_Eng_O: '207',
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.748231862443255, -7.516023000053353],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Feira Nova',
        area_ha: 10772.56,
        Cons_Eng_T: '19495',
        Cons_Eng_R: '11211',
        Cons_Eng_I: '1108',
        Cons_Eng_C: '1250',
        Cons_Eng_1: '1487',
        Cons_Eng_P: '655',
        Cons_Eng_2: '1629',
        Cons_Eng_O: '2155',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.383240715673573, -7.952893393452635],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Fernando de Noronha',
        area_ha: 1860.95,
        Cons_Eng_T: '26730',
        Cons_Eng_R: '10052',
        Cons_Eng_I: '331',
        Cons_Eng_C: '11087',
        Cons_Eng_1: '92',
        Cons_Eng_P: '2165',
        Cons_Eng_2: '95',
        Cons_Eng_O: '2908',
      },
      geometry: {
        type: 'Point',
        coordinates: [-32.424090097048222, -3.854335138883456],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Ferreiros',
        area_ha: 9251.67,
        Cons_Eng_T: '8129',
        Cons_Eng_R: '5465',
        Cons_Eng_I: '20',
        Cons_Eng_C: '587',
        Cons_Eng_1: '491',
        Cons_Eng_P: '403',
        Cons_Eng_2: '812',
        Cons_Eng_O: '351',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.244588374813823, -7.478456677397446],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Flores',
        area_ha: 99556.01,
        Cons_Eng_T: '16520',
        Cons_Eng_R: '7830',
        Cons_Eng_I: '1191',
        Cons_Eng_C: '1218',
        Cons_Eng_1: '2409',
        Cons_Eng_P: '712',
        Cons_Eng_2: '888',
        Cons_Eng_O: '2272',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.894764090057095, -7.939923175779557],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Floresta',
        area_ha: 360494.51,
        Cons_Eng_T: '38517',
        Cons_Eng_R: '15704',
        Cons_Eng_I: '1662',
        Cons_Eng_C: '4760',
        Cons_Eng_1: '3625',
        Cons_Eng_P: '1588',
        Cons_Eng_2: '1902',
        Cons_Eng_O: '9276',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.310844308782237, -8.566892830149582],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Frei Miguelinho',
        area_ha: 21270.72,
        Cons_Eng_T: '10774',
        Cons_Eng_R: '7106',
        Cons_Eng_I: '366',
        Cons_Eng_C: '746',
        Cons_Eng_1: '384',
        Cons_Eng_P: '277',
        Cons_Eng_2: '1886',
        Cons_Eng_O: '9',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.884996247442437, -7.935086561329168],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Gameleira',
        area_ha: 25778.11,
        Cons_Eng_T: '14761',
        Cons_Eng_R: '7824',
        Cons_Eng_I: '2490',
        Cons_Eng_C: '1338',
        Cons_Eng_1: '346',
        Cons_Eng_P: '498',
        Cons_Eng_2: '1402',
        Cons_Eng_O: '863',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.393084667345271, -8.61247271008118],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Garanhuns',
        area_ha: 45855.16,
        Cons_Eng_T: '166795',
        Cons_Eng_R: '77277',
        Cons_Eng_I: '15371',
        Cons_Eng_C: '33065',
        Cons_Eng_1: '8640',
        Cons_Eng_P: '6258',
        Cons_Eng_2: '6433',
        Cons_Eng_O: '19751',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.501135580492573, -8.929088284628023],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'GlÃ³ria do GoitÃ¡',
        area_ha: 23470.85,
        Cons_Eng_T: '33625',
        Cons_Eng_R: '11410',
        Cons_Eng_I: '16169',
        Cons_Eng_C: '1726',
        Cons_Eng_1: '1907',
        Cons_Eng_P: '652',
        Cons_Eng_2: '1635',
        Cons_Eng_O: '126',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.338237455361586, -8.012684819131993],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Goiana',
        area_ha: 44540.37,
        Cons_Eng_T: '319805',
        Cons_Eng_R: '49625',
        Cons_Eng_I: '209323',
        Cons_Eng_C: '25032',
        Cons_Eng_1: '5064',
        Cons_Eng_P: '14784',
        Cons_Eng_2: '6881',
        Cons_Eng_O: '9096',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.942495160764089, -7.59687576539248],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Granito',
        area_ha: 52168.79,
        Cons_Eng_T: '4792',
        Cons_Eng_R: '2997',
        Cons_Eng_I: '305',
        Cons_Eng_C: '326',
        Cons_Eng_1: '286',
        Cons_Eng_P: '398',
        Cons_Eng_2: '439',
        Cons_Eng_O: '41',
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.694337504918039, -7.78846361688103],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'GravatÃ¡',
        area_ha: 50736.05,
        Cons_Eng_T: '116557',
        Cons_Eng_R: '62332',
        Cons_Eng_I: '10810',
        Cons_Eng_C: '24273',
        Cons_Eng_1: '7453',
        Cons_Eng_P: '2678',
        Cons_Eng_2: '7119',
        Cons_Eng_O: '1892',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.563617976492907, -8.199401598573575],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Iati',
        area_ha: 63513.75,
        Cons_Eng_T: '10127',
        Cons_Eng_R: '6625',
        Cons_Eng_I: '0',
        Cons_Eng_C: '647',
        Cons_Eng_1: '1459',
        Cons_Eng_P: '423',
        Cons_Eng_2: '692',
        Cons_Eng_O: '281',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.901056531124247, -9.143470859198107],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Ibimirim',
        area_ha: 188250.28,
        Cons_Eng_T: '24024',
        Cons_Eng_R: '12013',
        Cons_Eng_I: '3',
        Cons_Eng_C: '2237',
        Cons_Eng_1: '5849',
        Cons_Eng_P: '1194',
        Cons_Eng_2: '1303',
        Cons_Eng_O: '1425',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.610654558298748, -8.58586970187295],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Ibirajuba',
        area_ha: 18959.58,
        Cons_Eng_T: '3957',
        Cons_Eng_R: '2731',
        Cons_Eng_I: '0',
        Cons_Eng_C: '168',
        Cons_Eng_1: '354',
        Cons_Eng_P: '171',
        Cons_Eng_2: '321',
        Cons_Eng_O: '212',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.201420209776721, -8.613398171450418],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Igarassu',
        area_ha: 30687.89,
        Cons_Eng_T: '607886',
        Cons_Eng_R: '60821',
        Cons_Eng_I: '467344',
        Cons_Eng_C: '26018',
        Cons_Eng_1: '7292',
        Cons_Eng_P: '6848',
        Cons_Eng_2: '5744',
        Cons_Eng_O: '33819',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.960320202427049, -7.81750964606075],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Iguaracy',
        area_ha: 83813.19,
        Cons_Eng_T: '6861',
        Cons_Eng_R: '4312',
        Cons_Eng_I: '0',
        Cons_Eng_C: '443',
        Cons_Eng_1: '954',
        Cons_Eng_P: '396',
        Cons_Eng_2: '460',
        Cons_Eng_O: '296',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.403137096012422, -7.845560777317941],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'InajÃ¡',
        area_ha: 123136.21,
        Cons_Eng_T: '17948',
        Cons_Eng_R: '9554',
        Cons_Eng_I: '1174',
        Cons_Eng_C: '1484',
        Cons_Eng_1: '4037',
        Cons_Eng_P: '462',
        Cons_Eng_2: '600',
        Cons_Eng_O: '637',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.837430357617777, -8.805512824512792],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Ingazeira',
        area_ha: 24358.57,
        Cons_Eng_T: '2812',
        Cons_Eng_R: '1999',
        Cons_Eng_I: '0',
        Cons_Eng_C: '158',
        Cons_Eng_1: '149',
        Cons_Eng_P: '248',
        Cons_Eng_2: '232',
        Cons_Eng_O: '26',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.416136521674744, -7.71388685921927],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Ipojuca',
        area_ha: 52180.09,
        Cons_Eng_T: '800540',
        Cons_Eng_R: '85067',
        Cons_Eng_I: '585810',
        Cons_Eng_C: '106384',
        Cons_Eng_1: '2926',
        Cons_Eng_P: '9074',
        Cons_Eng_2: '7725',
        Cons_Eng_O: '3554',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.088929311165643, -8.437176274271527],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Ipubi',
        area_ha: 69391.38,
        Cons_Eng_T: '26444',
        Cons_Eng_R: '9748',
        Cons_Eng_I: '10190',
        Cons_Eng_C: '3033',
        Cons_Eng_1: '1247',
        Cons_Eng_P: '826',
        Cons_Eng_2: '1165',
        Cons_Eng_O: '235',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.217397624639489, -7.497860269788513],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Itacuruba',
        area_ha: 43003.94,
        Cons_Eng_T: '4872',
        Cons_Eng_R: '2388',
        Cons_Eng_I: '1',
        Cons_Eng_C: '439',
        Cons_Eng_1: '957',
        Cons_Eng_P: '413',
        Cons_Eng_2: '298',
        Cons_Eng_O: '376',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.72856271834214, -8.749276948713385],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'ItaÃ­ba',
        area_ha: 106169.41,
        Cons_Eng_T: '14717',
        Cons_Eng_R: '10152',
        Cons_Eng_I: '0',
        Cons_Eng_C: '898',
        Cons_Eng_1: '2007',
        Cons_Eng_P: '589',
        Cons_Eng_2: '1011',
        Cons_Eng_O: '60',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.295818592113356, -8.984327687567008],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Ilha de ItamaracÃ¡',
        area_ha: 6614.64,
        Cons_Eng_T: '35099',
        Cons_Eng_R: '19724',
        Cons_Eng_I: '111',
        Cons_Eng_C: '3087',
        Cons_Eng_1: '312',
        Cons_Eng_P: '5370',
        Cons_Eng_2: '3329',
        Cons_Eng_O: '3166',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.854412092571636, -7.759545470826247],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'ItambÃ©',
        area_ha: 30400.49,
        Cons_Eng_T: '25195',
        Cons_Eng_R: '15623',
        Cons_Eng_I: '345',
        Cons_Eng_C: '3494',
        Cons_Eng_1: '922',
        Cons_Eng_P: '1793',
        Cons_Eng_2: '2018',
        Cons_Eng_O: '1000',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.134266540550001, -7.449903329388187],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Itapetim',
        area_ha: 41190.1,
        Cons_Eng_T: '9545',
        Cons_Eng_R: '6422',
        Cons_Eng_I: '68',
        Cons_Eng_C: '687',
        Cons_Eng_1: '865',
        Cons_Eng_P: '497',
        Cons_Eng_2: '901',
        Cons_Eng_O: '105',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.143052267938835, -7.398693500651708],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Itapissuma',
        area_ha: 7396.84,
        Cons_Eng_T: '60689',
        Cons_Eng_R: '8542',
        Cons_Eng_I: '43155',
        Cons_Eng_C: '3552',
        Cons_Eng_1: '1770',
        Cons_Eng_P: '1135',
        Cons_Eng_2: '1427',
        Cons_Eng_O: '1108',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.906798148640583, -7.745149803286439],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Itaquitinga',
        area_ha: 16274.07,
        Cons_Eng_T: '13447',
        Cons_Eng_R: '6467',
        Cons_Eng_I: '77',
        Cons_Eng_C: '813',
        Cons_Eng_1: '3150',
        Cons_Eng_P: '1756',
        Cons_Eng_2: '840',
        Cons_Eng_O: '344',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.032561483014234, -7.686384286938314],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'JaboatÃ£o dos Guararapes',
        area_ha: 25872.37,
        Cons_Eng_T: '964957',
        Cons_Eng_R: '414578',
        Cons_Eng_I: '197173',
        Cons_Eng_C: '239772',
        Cons_Eng_1: '713',
        Cons_Eng_P: '37099',
        Cons_Eng_2: '41187',
        Cons_Eng_O: '34435',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.003349881825734, -8.152158991182898],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Jaqueira',
        area_ha: 8720.84,
        Cons_Eng_T: '6487',
        Cons_Eng_R: '4114',
        Cons_Eng_I: '11',
        Cons_Eng_C: '407',
        Cons_Eng_1: '278',
        Cons_Eng_P: '548',
        Cons_Eng_2: '1080',
        Cons_Eng_O: '49',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.807829029013917, -8.74331025357171],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'JataÃºba',
        area_ha: 71460.13,
        Cons_Eng_T: '9986',
        Cons_Eng_R: '7109',
        Cons_Eng_I: '1',
        Cons_Eng_C: '638',
        Cons_Eng_1: '469',
        Cons_Eng_P: '437',
        Cons_Eng_2: '1194',
        Cons_Eng_O: '138',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.533863043233829, -8.05520910524409],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'JatobÃ¡',
        area_ha: 27786.23,
        Cons_Eng_T: '11879',
        Cons_Eng_R: '5819',
        Cons_Eng_I: '124',
        Cons_Eng_C: '750',
        Cons_Eng_1: '774',
        Cons_Eng_P: '770',
        Cons_Eng_2: '1076',
        Cons_Eng_O: '2566',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.213788179952033, -9.20775488074206],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'JoÃ£o Alfredo',
        area_ha: 13414.72,
        Cons_Eng_T: '18452',
        Cons_Eng_R: '12102',
        Cons_Eng_I: '496',
        Cons_Eng_C: '1691',
        Cons_Eng_1: '238',
        Cons_Eng_P: '712',
        Cons_Eng_2: '2948',
        Cons_Eng_O: '265',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.592385214928946, -7.86098648674127],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Joaquim Nabuco',
        area_ha: 12259.15,
        Cons_Eng_T: '9543',
        Cons_Eng_R: '4817',
        Cons_Eng_I: '663',
        Cons_Eng_C: '674',
        Cons_Eng_1: '1124',
        Cons_Eng_P: '640',
        Cons_Eng_2: '750',
        Cons_Eng_O: '875',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.545049794614421, -8.562436426413869],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Jucati',
        area_ha: 12052.75,
        Cons_Eng_T: '5684',
        Cons_Eng_R: '4131',
        Cons_Eng_I: '0',
        Cons_Eng_C: '214',
        Cons_Eng_1: '761',
        Cons_Eng_P: '263',
        Cons_Eng_2: '294',
        Cons_Eng_O: '21',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.459591994399652, -8.744044597275627],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Jupi',
        area_ha: 10483.47,
        Cons_Eng_T: '11310',
        Cons_Eng_R: '7079',
        Cons_Eng_I: '1278',
        Cons_Eng_C: '954',
        Cons_Eng_1: '1184',
        Cons_Eng_P: '325',
        Cons_Eng_2: '416',
        Cons_Eng_O: '74',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.394019178647071, -8.715359494763737],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Jurema',
        area_ha: 14825.38,
        Cons_Eng_T: '7342',
        Cons_Eng_R: '5281',
        Cons_Eng_I: '2',
        Cons_Eng_C: '353',
        Cons_Eng_1: '393',
        Cons_Eng_P: '226',
        Cons_Eng_2: '661',
        Cons_Eng_O: '426',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.1427466761888, -8.755428686079149],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Lagoa do Carro',
        area_ha: 6966.55,
        Cons_Eng_T: '12969',
        Cons_Eng_R: '7828',
        Cons_Eng_I: '195',
        Cons_Eng_C: '869',
        Cons_Eng_1: '545',
        Cons_Eng_P: '646',
        Cons_Eng_2: '1114',
        Cons_Eng_O: '1772',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.336724160035892, -7.852588449605914],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Lagoa de Itaenga',
        area_ha: 5613.08,
        Cons_Eng_T: '22105',
        Cons_Eng_R: '10291',
        Cons_Eng_I: '3506',
        Cons_Eng_C: '1105',
        Cons_Eng_1: '3467',
        Cons_Eng_P: '628',
        Cons_Eng_2: '1466',
        Cons_Eng_O: '1642',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.294844275059937, -7.90924093629305],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Lagoa do Ouro',
        area_ha: 19876.17,
        Cons_Eng_T: '7832',
        Cons_Eng_R: '4367',
        Cons_Eng_I: '11',
        Cons_Eng_C: '386',
        Cons_Eng_1: '1177',
        Cons_Eng_P: '533',
        Cons_Eng_2: '611',
        Cons_Eng_O: '747',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.465161691572177, -9.162191790597184],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Lagoa dos Gatos',
        area_ha: 22494.75,
        Cons_Eng_T: '13253',
        Cons_Eng_R: '5613',
        Cons_Eng_I: '12',
        Cons_Eng_C: '344',
        Cons_Eng_1: '137',
        Cons_Eng_P: '291',
        Cons_Eng_2: '1196',
        Cons_Eng_O: '5660',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.895583593990082, -8.682365726820141],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Lagoa Grande',
        area_ha: 185006.81,
        Cons_Eng_T: '22732',
        Cons_Eng_R: '9864',
        Cons_Eng_I: '1',
        Cons_Eng_C: '3888',
        Cons_Eng_1: '5733',
        Cons_Eng_P: '1119',
        Cons_Eng_2: '1011',
        Cons_Eng_O: '1116',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.223030873025976, -8.777204707108496],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Lajedo',
        area_ha: 18909.62,
        Cons_Eng_T: '40824',
        Cons_Eng_R: '21678',
        Cons_Eng_I: '6017',
        Cons_Eng_C: '4855',
        Cons_Eng_1: '5061',
        Cons_Eng_P: '1138',
        Cons_Eng_2: '1925',
        Cons_Eng_O: '150',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.286293936499405, -8.677954099100452],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Limoeiro',
        area_ha: 27373.31,
        Cons_Eng_T: '63670',
        Cons_Eng_R: '35214',
        Cons_Eng_I: '4191',
        Cons_Eng_C: '10663',
        Cons_Eng_1: '1753',
        Cons_Eng_P: '4669',
        Cons_Eng_2: '5533',
        Cons_Eng_O: '1647',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.447869911665251, -7.847740358094518],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Macaparana',
        area_ha: 10804.85,
        Cons_Eng_T: '16638',
        Cons_Eng_R: '9509',
        Cons_Eng_I: '42',
        Cons_Eng_C: '2115',
        Cons_Eng_1: '1599',
        Cons_Eng_P: '1112',
        Cons_Eng_2: '1314',
        Cons_Eng_O: '947',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.450210545657328, -7.52766545849298],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Machados',
        area_ha: 6161.97,
        Cons_Eng_T: '9650',
        Cons_Eng_R: '4860',
        Cons_Eng_I: '10',
        Cons_Eng_C: '1123',
        Cons_Eng_1: '1663',
        Cons_Eng_P: '378',
        Cons_Eng_2: '988',
        Cons_Eng_O: '628',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.50028789738306, -7.703376950353477],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Manari',
        area_ha: 34468.48,
        Cons_Eng_T: '6910',
        Cons_Eng_R: '4211',
        Cons_Eng_I: '0',
        Cons_Eng_C: '395',
        Cons_Eng_1: '454',
        Cons_Eng_P: '239',
        Cons_Eng_2: '426',
        Cons_Eng_O: '1185',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.554230884311451, -8.892936676361474],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Maraial',
        area_ha: 19986.45,
        Cons_Eng_T: '5286',
        Cons_Eng_R: '3740',
        Cons_Eng_I: '4',
        Cons_Eng_C: '459',
        Cons_Eng_1: '129',
        Cons_Eng_P: '340',
        Cons_Eng_2: '579',
        Cons_Eng_O: '35',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.761710555706166, -8.834209064716763],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Mirandiba',
        area_ha: 82167.76,
        Cons_Eng_T: '9961',
        Cons_Eng_R: '5991',
        Cons_Eng_I: '1',
        Cons_Eng_C: '844',
        Cons_Eng_1: '1450',
        Cons_Eng_P: '359',
        Cons_Eng_2: '592',
        Cons_Eng_O: '724',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.736063250007938, -8.157508352928222],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Moreno',
        area_ha: 19419.66,
        Cons_Eng_T: '64605',
        Cons_Eng_R: '24291',
        Cons_Eng_I: '15549',
        Cons_Eng_C: '11351',
        Cons_Eng_1: '1635',
        Cons_Eng_P: '1436',
        Cons_Eng_2: '4590',
        Cons_Eng_O: '5753',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.146295327379669, -8.14404152515087],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'NazarÃ© da Mata',
        area_ha: 13057.22,
        Cons_Eng_T: '71159',
        Cons_Eng_R: '16765',
        Cons_Eng_I: '42089',
        Cons_Eng_C: '4346',
        Cons_Eng_1: '2578',
        Cons_Eng_P: '2271',
        Cons_Eng_2: '2145',
        Cons_Eng_O: '965',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.233168599465316, -7.737195643275303],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Olinda',
        area_ha: 4130.01,
        Cons_Eng_T: '436765',
        Cons_Eng_R: '245824',
        Cons_Eng_I: '20634',
        Cons_Eng_C: '120348',
        Cons_Eng_1: '1675',
        Cons_Eng_P: '14740',
        Cons_Eng_2: '21211',
        Cons_Eng_O: '12333',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.866365182920134, -7.993100441040093],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'OrobÃ³',
        area_ha: 13852.09,
        Cons_Eng_T: '14764',
        Cons_Eng_R: '8360',
        Cons_Eng_I: '6',
        Cons_Eng_C: '1229',
        Cons_Eng_1: '1230',
        Cons_Eng_P: '627',
        Cons_Eng_2: '3158',
        Cons_Eng_O: '154',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.598687835409088, -7.705931604104489],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'OrocÃ³',
        area_ha: 55476.49,
        Cons_Eng_T: '32064',
        Cons_Eng_R: '3266',
        Cons_Eng_I: '32',
        Cons_Eng_C: '508',
        Cons_Eng_1: '9691',
        Cons_Eng_P: '514',
        Cons_Eng_2: '523',
        Cons_Eng_O: '17530',
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.59107317797308, -8.472115125603132],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Ouricuri',
        area_ha: 238156.91,
        Cons_Eng_T: '59446',
        Cons_Eng_R: '28539',
        Cons_Eng_I: '5348',
        Cons_Eng_C: '5934',
        Cons_Eng_1: '1662',
        Cons_Eng_P: '2805',
        Cons_Eng_2: '3996',
        Cons_Eng_O: '11162',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.16447098271135, -7.965979232583071],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Palmares',
        area_ha: 33940.16,
        Cons_Eng_T: '65901',
        Cons_Eng_R: '29279',
        Cons_Eng_I: '1526',
        Cons_Eng_C: '18965',
        Cons_Eng_1: '872',
        Cons_Eng_P: '6501',
        Cons_Eng_2: '5197',
        Cons_Eng_O: '3561',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.630434140022665, -8.656488461820466],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Palmeirina',
        area_ha: 16879.6,
        Cons_Eng_T: '3686',
        Cons_Eng_R: '2799',
        Cons_Eng_I: '0',
        Cons_Eng_C: '176',
        Cons_Eng_1: '220',
        Cons_Eng_P: '165',
        Cons_Eng_2: '267',
        Cons_Eng_O: '59',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.277852147977832, -9.011413370287229],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Panelas',
        area_ha: 38042.8,
        Cons_Eng_T: '13025',
        Cons_Eng_R: '8521',
        Cons_Eng_I: '17',
        Cons_Eng_C: '927',
        Cons_Eng_1: '321',
        Cons_Eng_P: '768',
        Cons_Eng_2: '2023',
        Cons_Eng_O: '448',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.049141159253999, -8.665102276029021],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Paranatama',
        area_ha: 18536.9,
        Cons_Eng_T: '7161',
        Cons_Eng_R: '4421',
        Cons_Eng_I: '87',
        Cons_Eng_C: '305',
        Cons_Eng_1: '1738',
        Cons_Eng_P: '325',
        Cons_Eng_2: '239',
        Cons_Eng_O: '46',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.707112065773366, -8.893941481341892],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Parnamirim',
        area_ha: 260954.61,
        Cons_Eng_T: '23273',
        Cons_Eng_R: '7544',
        Cons_Eng_I: '3',
        Cons_Eng_C: '1360',
        Cons_Eng_1: '757',
        Cons_Eng_P: '721',
        Cons_Eng_2: '2035',
        Cons_Eng_O: '10853',
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.770026301874232, -8.160028906789735],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Passira',
        area_ha: 32721.01,
        Cons_Eng_T: '19595',
        Cons_Eng_R: '13569',
        Cons_Eng_I: '362',
        Cons_Eng_C: '1623',
        Cons_Eng_1: '784',
        Cons_Eng_P: '1071',
        Cons_Eng_2: '2040',
        Cons_Eng_O: '146',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.546302048453825, -8.012462648678206],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Paudalho',
        area_ha: 26965.1,
        Cons_Eng_T: '64494',
        Cons_Eng_R: '28689',
        Cons_Eng_I: '9114',
        Cons_Eng_C: '6005',
        Cons_Eng_1: '7987',
        Cons_Eng_P: '2982',
        Cons_Eng_2: '3392',
        Cons_Eng_O: '6325',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.155072406494575, -7.905582823260062],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Paulista',
        area_ha: 9693.24,
        Cons_Eng_T: '456132',
        Cons_Eng_R: '227088',
        Cons_Eng_I: '79656',
        Cons_Eng_C: '97080',
        Cons_Eng_1: '733',
        Cons_Eng_P: '14637',
        Cons_Eng_2: '18063',
        Cons_Eng_O: '18875',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.883410200317655, -7.928155126171228],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Pedra',
        area_ha: 92260.2,
        Cons_Eng_T: '32175',
        Cons_Eng_R: '7881',
        Cons_Eng_I: '20001',
        Cons_Eng_C: '677',
        Cons_Eng_1: '1707',
        Cons_Eng_P: '589',
        Cons_Eng_2: '695',
        Cons_Eng_O: '625',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.899896005967435, -8.697077348745426],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Pesqueira',
        area_ha: 96004.12,
        Cons_Eng_T: '55375',
        Cons_Eng_R: '27983',
        Cons_Eng_I: '12031',
        Cons_Eng_C: '7917',
        Cons_Eng_1: '1677',
        Cons_Eng_P: '2089',
        Cons_Eng_2: '3025',
        Cons_Eng_O: '653',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.735292392705695, -8.391893680327907],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'PetrolÃ¢ndia',
        area_ha: 105658.4,
        Cons_Eng_T: '60078',
        Cons_Eng_R: '18917',
        Cons_Eng_I: '1016',
        Cons_Eng_C: '6379',
        Cons_Eng_1: '28672',
        Cons_Eng_P: '1853',
        Cons_Eng_2: '1682',
        Cons_Eng_O: '1559',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.307893895742957, -8.899330210853872],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Petrolina',
        area_ha: 456187.83,
        Cons_Eng_T: '580837',
        Cons_Eng_R: '256042',
        Cons_Eng_I: '43855',
        Cons_Eng_C: '129740',
        Cons_Eng_1: '85245',
        Cons_Eng_P: '21783',
        Cons_Eng_2: '22364',
        Cons_Eng_O: '21808',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.572359064496645, -9.019794468638651],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'PoÃ§Ã£o',
        area_ha: 20511.85,
        Cons_Eng_T: '7288',
        Cons_Eng_R: '5079',
        Cons_Eng_I: '30',
        Cons_Eng_C: '676',
        Cons_Eng_1: '373',
        Cons_Eng_P: '321',
        Cons_Eng_2: '410',
        Cons_Eng_O: '399',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.71130188782692, -8.224733921759851],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Pombos',
        area_ha: 23983.15,
        Cons_Eng_T: '28474',
        Cons_Eng_R: '11620',
        Cons_Eng_I: '6837',
        Cons_Eng_C: '2562',
        Cons_Eng_1: '4605',
        Cons_Eng_P: '772',
        Cons_Eng_2: '2029',
        Cons_Eng_O: '49',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.387278042718584, -8.181695153452667],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Primavera',
        area_ha: 11313.48,
        Cons_Eng_T: '7363',
        Cons_Eng_R: '4531',
        Cons_Eng_I: '581',
        Cons_Eng_C: '558',
        Cons_Eng_1: '234',
        Cons_Eng_P: '361',
        Cons_Eng_2: '948',
        Cons_Eng_O: '150',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.390928651371446, -8.326938996143289],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'QuipapÃ¡',
        area_ha: 23061.74,
        Cons_Eng_T: '12638',
        Cons_Eng_R: '7026',
        Cons_Eng_I: '526',
        Cons_Eng_C: '777',
        Cons_Eng_1: '772',
        Cons_Eng_P: '653',
        Cons_Eng_2: '1109',
        Cons_Eng_O: '1775',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.028679559105413, -8.836064519454103],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Quixaba',
        area_ha: 21070.45,
        Cons_Eng_T: '4378',
        Cons_Eng_R: '3285',
        Cons_Eng_I: '0',
        Cons_Eng_C: '278',
        Cons_Eng_1: '153',
        Cons_Eng_P: '359',
        Cons_Eng_2: '235',
        Cons_Eng_O: '68',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.849073878972071, -7.71760989635462],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Recife',
        area_ha: 21884.3,
        Cons_Eng_T: '3173321',
        Cons_Eng_R: '1268947',
        Cons_Eng_I: '198478',
        Cons_Eng_C: '1333551',
        Cons_Eng_1: '1647',
        Cons_Eng_P: '267155',
        Cons_Eng_2: '32602',
        Cons_Eng_O: '70941',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.933086268987658, -8.039344937294405],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Riacho das Almas',
        area_ha: 31400.28,
        Cons_Eng_T: '33488',
        Cons_Eng_R: '12043',
        Cons_Eng_I: '207',
        Cons_Eng_C: '1469',
        Cons_Eng_1: '2445',
        Cons_Eng_P: '454',
        Cons_Eng_2: '2571',
        Cons_Eng_O: '14299',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.882899620149004, -8.081950814635098],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'RibeirÃ£o',
        area_ha: 28956.59,
        Cons_Eng_T: '38845',
        Cons_Eng_R: '15240',
        Cons_Eng_I: '4946',
        Cons_Eng_C: '8991',
        Cons_Eng_1: '2730',
        Cons_Eng_P: '1149',
        Cons_Eng_2: '3428',
        Cons_Eng_O: '2361',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.366460262740667, -8.504556722027116],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Rio Formoso',
        area_ha: 22745.78,
        Cons_Eng_T: '16229',
        Cons_Eng_R: '6728',
        Cons_Eng_I: '1648',
        Cons_Eng_C: '2427',
        Cons_Eng_1: '1017',
        Cons_Eng_P: '1087',
        Cons_Eng_2: '1453',
        Cons_Eng_O: '1869',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.21815016976165, -8.675068960004872],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'SairÃ©',
        area_ha: 19045.45,
        Cons_Eng_T: '11871',
        Cons_Eng_R: '4717',
        Cons_Eng_I: '1',
        Cons_Eng_C: '1126',
        Cons_Eng_1: '1494',
        Cons_Eng_P: '201',
        Cons_Eng_2: '733',
        Cons_Eng_O: '3599',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.684474461856681, -8.302739442759934],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Salgadinho',
        area_ha: 8721.73,
        Cons_Eng_T: '3526',
        Cons_Eng_R: '2195',
        Cons_Eng_I: '0',
        Cons_Eng_C: '331',
        Cons_Eng_1: '89',
        Cons_Eng_P: '362',
        Cons_Eng_2: '542',
        Cons_Eng_O: '7',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.593818440758355, -7.91614494643366],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Salgueiro',
        area_ha: 167856.31,
        Cons_Eng_T: '68617',
        Cons_Eng_R: '36322',
        Cons_Eng_I: '423',
        Cons_Eng_C: '16393',
        Cons_Eng_1: '3920',
        Cons_Eng_P: '4591',
        Cons_Eng_2: '3675',
        Cons_Eng_O: '3293',
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.089814928599296, -8.129180804564882],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'SaloÃ¡',
        area_ha: 25155.12,
        Cons_Eng_T: '8954',
        Cons_Eng_R: '4927',
        Cons_Eng_I: '1',
        Cons_Eng_C: '863',
        Cons_Eng_1: '1829',
        Cons_Eng_P: '408',
        Cons_Eng_2: '626',
        Cons_Eng_O: '300',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.7389457437544, -8.991600000651262],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'SanharÃ³',
        area_ha: 26806.46,
        Cons_Eng_T: '12467',
        Cons_Eng_R: '7545',
        Cons_Eng_I: '31',
        Cons_Eng_C: '940',
        Cons_Eng_1: '761',
        Cons_Eng_P: '389',
        Cons_Eng_2: '1616',
        Cons_Eng_O: '1185',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.549482579712411, -8.364786503328972],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Santa Cruz',
        area_ha: 124598.39,
        Cons_Eng_T: '5914',
        Cons_Eng_R: '4370',
        Cons_Eng_I: '0',
        Cons_Eng_C: '364',
        Cons_Eng_1: '331',
        Cons_Eng_P: '301',
        Cons_Eng_2: '264',
        Cons_Eng_O: '284',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.302303522671515, -8.291218561475814],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Santa Cruz da Baixa Verde',
        area_ha: 11493.15,
        Cons_Eng_T: '7009',
        Cons_Eng_R: '4211',
        Cons_Eng_I: '109',
        Cons_Eng_C: '610',
        Cons_Eng_1: '1100',
        Cons_Eng_P: '237',
        Cons_Eng_2: '732',
        Cons_Eng_O: '10',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.175711934026189, -7.849491554129547],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Santa Cruz do Capibaribe',
        area_ha: 33530.89,
        Cons_Eng_T: '110242',
        Cons_Eng_R: '73422',
        Cons_Eng_I: '2305',
        Cons_Eng_C: '19380',
        Cons_Eng_1: '407',
        Cons_Eng_P: '3318',
        Cons_Eng_2: '7863',
        Cons_Eng_O: '3547',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.325294700788326, -7.889567564809837],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Santa Filomena',
        area_ha: 100534.08,
        Cons_Eng_T: '6109',
        Cons_Eng_R: '4043',
        Cons_Eng_I: '464',
        Cons_Eng_C: '421',
        Cons_Eng_1: '338',
        Cons_Eng_P: '300',
        Cons_Eng_2: '420',
        Cons_Eng_O: '123',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.571477675010698, -8.223650606650219],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Santa Maria da Boa Vista',
        area_ha: 300077.54,
        Cons_Eng_T: '63865',
        Cons_Eng_R: '13095',
        Cons_Eng_I: '719',
        Cons_Eng_C: '3942',
        Cons_Eng_1: '40914',
        Cons_Eng_P: '1337',
        Cons_Eng_2: '2413',
        Cons_Eng_O: '1445',
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.914317845561953, -8.629740254263844],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Santa Maria do CambucÃ¡',
        area_ha: 9214.79,
        Cons_Eng_T: '9664',
        Cons_Eng_R: '5919',
        Cons_Eng_I: '798',
        Cons_Eng_C: '475',
        Cons_Eng_1: '143',
        Cons_Eng_P: '382',
        Cons_Eng_2: '1934',
        Cons_Eng_O: '13',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.882896953927805, -7.824747913676803],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Santa Terezinha',
        area_ha: 20032.63,
        Cons_Eng_T: '6273',
        Cons_Eng_R: '3848',
        Cons_Eng_I: '0',
        Cons_Eng_C: '462',
        Cons_Eng_1: '332',
        Cons_Eng_P: '366',
        Cons_Eng_2: '476',
        Cons_Eng_O: '789',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.436516703434044, -7.430442167340974],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'SÃ£o Benedito do Sul',
        area_ha: 16047.71,
        Cons_Eng_T: '5316',
        Cons_Eng_R: '3440',
        Cons_Eng_I: '19',
        Cons_Eng_C: '281',
        Cons_Eng_1: '344',
        Cons_Eng_P: '365',
        Cons_Eng_2: '603',
        Cons_Eng_O: '264',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.906707540471508, -8.809267052999031],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'SÃ£o Bento do Una',
        area_ha: 71914.87,
        Cons_Eng_T: '36411',
        Cons_Eng_R: '20193',
        Cons_Eng_I: '1719',
        Cons_Eng_C: '2949',
        Cons_Eng_1: '7304',
        Cons_Eng_P: '1473',
        Cons_Eng_2: '2723',
        Cons_Eng_O: '50',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.450637972475192, -8.535920024726391],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'SÃ£o Caitano',
        area_ha: 38248.24,
        Cons_Eng_T: '30450',
        Cons_Eng_R: '16131',
        Cons_Eng_I: '4977',
        Cons_Eng_C: '2696',
        Cons_Eng_1: '837',
        Cons_Eng_P: '677',
        Cons_Eng_2: '2844',
        Cons_Eng_O: '2288',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.148003705732975, -8.328833698603987],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'SÃ£o JoÃ£o',
        area_ha: 25851.86,
        Cons_Eng_T: '12398',
        Cons_Eng_R: '7289',
        Cons_Eng_I: '83',
        Cons_Eng_C: '1411',
        Cons_Eng_1: '2147',
        Cons_Eng_P: '627',
        Cons_Eng_2: '796',
        Cons_Eng_O: '45',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.377589367655268, -8.879888484775785],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'SÃ£o Joaquim do Monte',
        area_ha: 22738.47,
        Cons_Eng_T: '11243',
        Cons_Eng_R: '7321',
        Cons_Eng_I: '9',
        Cons_Eng_C: '741',
        Cons_Eng_1: '1269',
        Cons_Eng_P: '748',
        Cons_Eng_2: '993',
        Cons_Eng_O: '162',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.843949457139495, -8.455023762904917],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'SÃ£o JosÃ© da Coroa Grande',
        area_ha: 6918.39,
        Cons_Eng_T: '19141',
        Cons_Eng_R: '11469',
        Cons_Eng_I: '-32',
        Cons_Eng_C: '3418',
        Cons_Eng_1: '77',
        Cons_Eng_P: '1074',
        Cons_Eng_2: '2325',
        Cons_Eng_O: '810',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.185189212273869, -8.86549853448768],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'SÃ£o JosÃ© do Belmonte',
        area_ha: 147408.51,
        Cons_Eng_T: '28052',
        Cons_Eng_R: '15817',
        Cons_Eng_I: '217',
        Cons_Eng_C: '2510',
        Cons_Eng_1: '4338',
        Cons_Eng_P: '1532',
        Cons_Eng_2: '2061',
        Cons_Eng_O: '1577',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.745667015667799, -7.865761522286036],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'SÃ£o JosÃ© do Egito',
        area_ha: 77403.77,
        Cons_Eng_T: '56502',
        Cons_Eng_R: '14208',
        Cons_Eng_I: '29851',
        Cons_Eng_C: '2978',
        Cons_Eng_1: '4761',
        Cons_Eng_P: '1480',
        Cons_Eng_2: '1372',
        Cons_Eng_O: '1852',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.274168985539184, -7.526504580076616],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'SÃ£o LourenÃ§o da Mata',
        area_ha: 26368.69,
        Cons_Eng_T: '121298',
        Cons_Eng_R: '56596',
        Cons_Eng_I: '9390',
        Cons_Eng_C: '27378',
        Cons_Eng_1: '734',
        Cons_Eng_P: '3257',
        Cons_Eng_2: '8761',
        Cons_Eng_O: '15182',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.103874012281686, -8.023095646043084],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'SÃ£o Vicente FÃ©rrer',
        area_ha: 11255.41,
        Cons_Eng_T: '13969',
        Cons_Eng_R: '6542',
        Cons_Eng_I: '96',
        Cons_Eng_C: '919',
        Cons_Eng_1: '2771',
        Cons_Eng_P: '681',
        Cons_Eng_2: '701',
        Cons_Eng_O: '2259',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.489830112098993, -7.605439253621046],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Serra Talhada',
        area_ha: 298000.63,
        Cons_Eng_T: '106817',
        Cons_Eng_R: '53529',
        Cons_Eng_I: '9059',
        Cons_Eng_C: '24055',
        Cons_Eng_1: '7774',
        Cons_Eng_P: '6163',
        Cons_Eng_2: '3059',
        Cons_Eng_O: '3178',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.369728098920845, -8.036099954804273],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Serrita',
        area_ha: 153518.81,
        Cons_Eng_T: '11067',
        Cons_Eng_R: '6368',
        Cons_Eng_I: '110',
        Cons_Eng_C: '754',
        Cons_Eng_1: '1146',
        Cons_Eng_P: '624',
        Cons_Eng_2: '1646',
        Cons_Eng_O: '419',
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.38156934347284, -7.850019545503118],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'SertÃ¢nia',
        area_ha: 242152.7,
        Cons_Eng_T: '52745',
        Cons_Eng_R: '15026',
        Cons_Eng_I: '1023',
        Cons_Eng_C: '2623',
        Cons_Eng_1: '2578',
        Cons_Eng_P: '1625',
        Cons_Eng_2: '2250',
        Cons_Eng_O: '27620',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.337082436202579, -8.210559735673758],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'SirinhaÃ©m',
        area_ha: 37432.09,
        Cons_Eng_T: '25724',
        Cons_Eng_R: '12762',
        Cons_Eng_I: '223',
        Cons_Eng_C: '4735',
        Cons_Eng_1: '2317',
        Cons_Eng_P: '1107',
        Cons_Eng_2: '2699',
        Cons_Eng_O: '1881',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.172082974718805, -8.571893575973407],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'MoreilÃ¢ndia',
        area_ha: 40428.69,
        Cons_Eng_T: '5862',
        Cons_Eng_R: '4233',
        Cons_Eng_I: '0',
        Cons_Eng_C: '364',
        Cons_Eng_1: '167',
        Cons_Eng_P: '424',
        Cons_Eng_2: '631',
        Cons_Eng_O: '43',
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.522550942419173, -7.618140826294053],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'SolidÃ£o',
        area_ha: 13839.96,
        Cons_Eng_T: '3059',
        Cons_Eng_R: '2028',
        Cons_Eng_I: '0',
        Cons_Eng_C: '177',
        Cons_Eng_1: '146',
        Cons_Eng_P: '256',
        Cons_Eng_2: '348',
        Cons_Eng_O: '104',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.651329108967495, -7.584851002286741],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Surubim',
        area_ha: 25289.62,
        Cons_Eng_T: '77616',
        Cons_Eng_R: '35991',
        Cons_Eng_I: '4860',
        Cons_Eng_C: '9839',
        Cons_Eng_1: '820',
        Cons_Eng_P: '1841',
        Cons_Eng_2: '5948',
        Cons_Eng_O: '18317',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.754514814407706, -7.891853867794054],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Tabira',
        area_ha: 39043.3,
        Cons_Eng_T: '24561',
        Cons_Eng_R: '14347',
        Cons_Eng_I: '1721',
        Cons_Eng_C: '2930',
        Cons_Eng_1: '611',
        Cons_Eng_P: '1270',
        Cons_Eng_2: '2164',
        Cons_Eng_O: '1518',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.513160206012834, -7.577637435410369],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'TacaimbÃ³',
        area_ha: 22760.05,
        Cons_Eng_T: '9065',
        Cons_Eng_R: '5079',
        Cons_Eng_I: '542',
        Cons_Eng_C: '785',
        Cons_Eng_1: '489',
        Cons_Eng_P: '663',
        Cons_Eng_2: '1011',
        Cons_Eng_O: '496',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.270157161667029, -8.347996112340173],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Tacaratu',
        area_ha: 126453.7,
        Cons_Eng_T: '16682',
        Cons_Eng_R: '8795',
        Cons_Eng_I: '260',
        Cons_Eng_C: '849',
        Cons_Eng_1: '3806',
        Cons_Eng_P: '829',
        Cons_Eng_2: '1399',
        Cons_Eng_O: '744',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.076491495136885, -8.956799059056362],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'TamandarÃ©',
        area_ha: 21375.02,
        Cons_Eng_T: '37535',
        Cons_Eng_R: '22072',
        Cons_Eng_I: '599',
        Cons_Eng_C: '8279',
        Cons_Eng_1: '2192',
        Cons_Eng_P: '1350',
        Cons_Eng_2: '2081',
        Cons_Eng_O: '962',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.230028458469342, -8.745744511288601],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Taquaritinga do Norte',
        area_ha: 47518.31,
        Cons_Eng_T: '26189',
        Cons_Eng_R: '15337',
        Cons_Eng_I: '1785',
        Cons_Eng_C: '2255',
        Cons_Eng_1: '1730',
        Cons_Eng_P: '472',
        Cons_Eng_2: '4453',
        Cons_Eng_O: '157',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.130455113567393, -7.869437855637777],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Terezinha',
        area_ha: 15144.98,
        Cons_Eng_T: '4004',
        Cons_Eng_R: '2056',
        Cons_Eng_I: '335',
        Cons_Eng_C: '278',
        Cons_Eng_1: '667',
        Cons_Eng_P: '196',
        Cons_Eng_2: '180',
        Cons_Eng_O: '292',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.630840868867921, -9.07755561849242],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Terra Nova',
        area_ha: 31870.93,
        Cons_Eng_T: '5988',
        Cons_Eng_R: '4040',
        Cons_Eng_I: '6',
        Cons_Eng_C: '350',
        Cons_Eng_1: '502',
        Cons_Eng_P: '358',
        Cons_Eng_2: '558',
        Cons_Eng_O: '174',
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.397462178285309, -8.157690423010296],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'TimbaÃºba',
        area_ha: 28768.26,
        Cons_Eng_T: '48656',
        Cons_Eng_R: '26339',
        Cons_Eng_I: '4280',
        Cons_Eng_C: '6426',
        Cons_Eng_1: '1194',
        Cons_Eng_P: '2513',
        Cons_Eng_2: '5477',
        Cons_Eng_O: '2427',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.341539334510315, -7.534549842711365],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Toritama',
        area_ha: 2570.41,
        Cons_Eng_T: '53173',
        Cons_Eng_R: '32133',
        Cons_Eng_I: '4146',
        Cons_Eng_C: '11781',
        Cons_Eng_1: '340',
        Cons_Eng_P: '1675',
        Cons_Eng_2: '3009',
        Cons_Eng_O: '89',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.055725391064605, -7.999021594344428],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'TracunhaÃ©m',
        area_ha: 13732.09,
        Cons_Eng_T: '10698',
        Cons_Eng_R: '6343',
        Cons_Eng_I: '195',
        Cons_Eng_C: '1870',
        Cons_Eng_1: '705',
        Cons_Eng_P: '483',
        Cons_Eng_2: '1082',
        Cons_Eng_O: '20',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.161112097730616, -7.759842206019159],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Trindade',
        area_ha: 29576.53,
        Cons_Eng_T: '35618',
        Cons_Eng_R: '15580',
        Cons_Eng_I: '11751',
        Cons_Eng_C: '4053',
        Cons_Eng_1: '742',
        Cons_Eng_P: '767',
        Cons_Eng_2: '1449',
        Cons_Eng_O: '1276',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.295004688364429, -7.72779917395403],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Triunfo',
        area_ha: 19151.83,
        Cons_Eng_T: '12221',
        Cons_Eng_R: '5896',
        Cons_Eng_I: '13',
        Cons_Eng_C: '1733',
        Cons_Eng_1: '2740',
        Cons_Eng_P: '474',
        Cons_Eng_2: '805',
        Cons_Eng_O: '560',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.066628628127987, -7.849853701755825],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Tupanatinga',
        area_ha: 93480.08,
        Cons_Eng_T: '14095',
        Cons_Eng_R: '6976',
        Cons_Eng_I: '0',
        Cons_Eng_C: '737',
        Cons_Eng_1: '1584',
        Cons_Eng_P: '494',
        Cons_Eng_2: '950',
        Cons_Eng_O: '3354',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.280379738814958, -8.683093649482442],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Tuparetama',
        area_ha: 18950.93,
        Cons_Eng_T: '7355',
        Cons_Eng_R: '4788',
        Cons_Eng_I: '2',
        Cons_Eng_C: '689',
        Cons_Eng_1: '814',
        Cons_Eng_P: '511',
        Cons_Eng_2: '493',
        Cons_Eng_O: '58',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.263390961827248, -7.686344148694407],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Venturosa',
        area_ha: 33610.65,
        Cons_Eng_T: '15413',
        Cons_Eng_R: '9137',
        Cons_Eng_I: '276',
        Cons_Eng_C: '1047',
        Cons_Eng_1: '3208',
        Cons_Eng_P: '586',
        Cons_Eng_2: '501',
        Cons_Eng_O: '658',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.805205942159709, -8.614844147172642],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Verdejante',
        area_ha: 47603.88,
        Cons_Eng_T: '6094',
        Cons_Eng_R: '3712',
        Cons_Eng_I: '31',
        Cons_Eng_C: '251',
        Cons_Eng_1: '838',
        Cons_Eng_P: '424',
        Cons_Eng_2: '667',
        Cons_Eng_O: '171',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.989085902415738, -7.975860461637696],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Vertente do LÃ©rio',
        area_ha: 7363.08,
        Cons_Eng_T: '7707',
        Cons_Eng_R: '3210',
        Cons_Eng_I: '3174',
        Cons_Eng_C: '242',
        Cons_Eng_1: '64',
        Cons_Eng_P: '159',
        Cons_Eng_2: '765',
        Cons_Eng_O: '93',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.815468262353548, -7.77764909749361],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'Vertentes',
        area_ha: 19632.47,
        Cons_Eng_T: '17949',
        Cons_Eng_R: '11007',
        Cons_Eng_I: '101',
        Cons_Eng_C: '2072',
        Cons_Eng_1: '539',
        Cons_Eng_P: '426',
        Cons_Eng_2: '3700',
        Cons_Eng_O: '104',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.982299283698453, -7.890059280592335],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'VicÃªncia',
        area_ha: 22801.7,
        Cons_Eng_T: '24767',
        Cons_Eng_R: '11903',
        Cons_Eng_I: '1454',
        Cons_Eng_C: '1331',
        Cons_Eng_1: '1742',
        Cons_Eng_P: '953',
        Cons_Eng_2: '2358',
        Cons_Eng_O: '5026',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.357223752856093, -7.659525991995754],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'VitÃ³ria de Santo AntÃ£o',
        area_ha: 33657.28,
        Cons_Eng_T: '311492',
        Cons_Eng_R: '80825',
        Cons_Eng_I: '164354',
        Cons_Eng_C: '34510',
        Cons_Eng_1: '8778',
        Cons_Eng_P: '6328',
        Cons_Eng_2: '10545',
        Cons_Eng_O: '6152',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.28694875680533, -8.149781091701566],
      },
    },
    {
      type: 'Feature',
      properties: {
        NM_MUN: 'XexÃ©u',
        area_ha: 11081.5,
        Cons_Eng_T: '8754',
        Cons_Eng_R: '4978',
        Cons_Eng_I: '0',
        Cons_Eng_C: '654',
        Cons_Eng_1: '226',
        Cons_Eng_P: '680',
        Cons_Eng_2: '1317',
        Cons_Eng_O: '899',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.653305362806272, -8.836205673654002],
      },
    },
  ],
};
