import React, { useState } from 'react';
import WindPowerIcon from '@mui/icons-material/WindPowerOutlined';
import { Box, Slider } from '@mui/material';
import {
  WindBox,
  LargeWindBox,
  SmallWindBox,
  WindTypographyTitle,
  WindTypographyData,
  WindPowerText,
  WindIconContainer,
  ChangeText,
  SliderWindBox,
} from './styles';
import { Energy } from '../EnergyComponent';
import { WindDirectionChart } from '../WindGraphComponent';

interface WindProps {
  latitude: string | null;
  longitude: string | null;
  address: string | null;
  globalIrradiation: string | null;
  difuseIrradiation: string | null;
  directIrradiation: string | null;
  monthlyIrradiation: number[];
}

export const WindComponent: React.FC<WindProps> = ({
  latitude,
  longitude,
  address,
  globalIrradiation,
  difuseIrradiation,
  directIrradiation,
  monthlyIrradiation,
}) => {
  const [activeLayout, setActiveLayout] = useState<'solar' | 'eolico'>(
    'eolico'
  );
  const [altitude, setAltitude] = useState<number>(150);

  const handleWindBoxClick = () => {
    setActiveLayout('solar');
  };

  const handleAltitudeChange = (event: Event, newValue: number | number[]) => {
    setAltitude(newValue as number);
  };

  const renderWindLayout = () => (
    <div>
      <WindBox>
        <Box sx={{ textAlign: 'center' }}>
          <WindTypographyTitle>Latitude</WindTypographyTitle>
          <WindTypographyData>{latitude || '-'}</WindTypographyData>
        </Box>
        <Box sx={{ textAlign: 'center', marginX: '24px' }}>
          <WindTypographyTitle>Longitude</WindTypographyTitle>
          <WindTypographyData>{longitude || '-'}</WindTypographyData>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <WindTypographyTitle>Endereço</WindTypographyTitle>
          <WindTypographyData>{address || '-'}</WindTypographyData>
        </Box>
      </WindBox>

      <Box sx={{ display: 'flex', marginTop: '10px', gap: '10px' }}>
        <LargeWindBox onClick={handleWindBoxClick} sx={{ cursor: 'pointer' }}>
          <WindIconContainer>
            <WindPowerIcon fontSize="large" />
            <WindPowerText>Eólico</WindPowerText>
          </WindIconContainer>
          <ChangeText>Clique para alterar</ChangeText>
        </LargeWindBox>

        <SmallWindBox>
          <WindTypographyTitle>Velocidade do vento</WindTypographyTitle>
          <WindTypographyData>6.4 M/S</WindTypographyData>
        </SmallWindBox>

        <SmallWindBox>
          <WindTypographyTitle>Turbulência média</WindTypographyTitle>
          <WindTypographyData>43.6%</WindTypographyData>
        </SmallWindBox>
      </Box>
      <Box sx={{ display: 'flex', marginTop: '10px', gap: '10px' }}>
        <SliderWindBox>
          <WindTypographyTitle>Altitudes</WindTypographyTitle>
          <Slider
            value={altitude}
            onChange={handleAltitudeChange}
            min={10}
            max={250}
            step={null}
            marks={[
              { value: 10, label: '10m' },
              { value: 50, label: '50m' },
              { value: 100, label: '100m' },
              { value: 150, label: '150m' },
              { value: 200, label: '200m' },
              { value: 250, label: '250m' },
            ]}
            valueLabelDisplay="auto"
            color="secondary"
            sx={{ color: '#9FC131' }}
          />
        </SliderWindBox>
        <SmallWindBox>
          <WindTypographyTitle>Shear médio</WindTypographyTitle>
          <WindTypographyData>1423.6</WindTypographyData>
        </SmallWindBox>

        <SmallWindBox>
          <WindTypographyTitle>Densidade de potência</WindTypographyTitle>
          <WindTypographyData>20.653</WindTypographyData>
        </SmallWindBox>
      </Box>

      <div style={{ marginTop: '20px' }}>
        <WindDirectionChart windData={[10, 20, 30, 40]} />
      </div>
    </div>
  );

  return (
    <>
      {activeLayout === 'eolico' ? (
        renderWindLayout()
      ) : (
        <Energy
          latitude={latitude}
          longitude={longitude}
          address={address}
          globalIrradiation={globalIrradiation}
          difuseIrradiation={difuseIrradiation}
          directIrradiation={directIrradiation}
          monthlyIrradiation={monthlyIrradiation}
        />
      )}
    </>
  );
};
